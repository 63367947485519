import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from '@material-ui/icons/Close';
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import axios from "axios";
import InventoryContext from "context/inventory/inventoryContext";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import history from "../../../history";
import Buttons from "components/buttons/Pressed";
//import InputBase from "@material-ui/core/InputBase";
import TextField from '@material-ui/core/TextField';
import { useStyles } from "components/layout/CommonStyles";
import { GetGeneralPermissionForAction } from "utils/permissions/GetGeneralPermissionForAction";
import { Screen, Allow } from "global/constants";
import Cookies from "js-cookie";

const BookTableAction = props => {
    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }
    const classes = useStyles();
    const [t] = useTranslation();
    const [openDelete, setOpenDelete] = useState(false);
    const inventoryContext = useContext(InventoryContext);
    const {
        deleteBook,
        SetDeleteSoftware,
        setDeleteLicense
    } = inventoryContext;
    const [changeContext, setchangeContex] = useState(0);
    const [password, setPassword] = useState("");
    const [passRight, setPassRight] = useState(false);
    const { item, isLicense } = props;
    const [justification, setJustification] = useState("");
    const [validJustification, setValidJustification] = useState(false);
    const blankField = "GENERAL.requiredField";
    const [validPasswordrequiredField, setPasswordRequiredField] = useState(false);

    const handleOpenDelete = () => {
        setOpenDelete(true);
    };
    const handleCloseDelete = () => {
        setOpenDelete(false);
        setValidJustification(false);
        setPasswordRequiredField(false);
        setPassRight(false);
        setchangeContex(0);
        setJustification("");
        setPassword("");
    };

    var pass = true;

    const handleDelete = () => {
        if (justification.length > 0) setchangeContex(changeContext + 1)
        else setValidJustification(true)
        if (changeContext > 0) {
            if (password.length > 0) sendRequest();
            else setPasswordRequiredField(true);
            if (pass) setPassword("")
            else setOpenDelete(false);
        }
    };

    const verifyEdit = () => {
        if (isLicense) {
            // axios.get(process.env.REACT_APP_SERVER + `api/BookExemplar/${item.id}`)
            // .then(res => {
            //   if (res.statusText === "OK") {
            history.push(`/Software/License/${item.id}/edit`);
            //     } else {
            //         deleteBookCategory(item.id);
            //      }
            // })
            //  .catch(error => {
            //      deleteBookCategory(item.id);
            //  });
        } else {
            axios.get(process.env.REACT_APP_SERVER + `api/Software/${item.id}`, {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
                }
            })
                .then(res => {
                    if (res.statusText === "OK") {
                        history.push(`/Software/${item.id}/edit`);
                    } else {
                        deleteBook(item.id);
                    }
                })
                .catch(error => {
                    deleteBook(item.id);
                });
        }
    }

    const verifyView = () => {
        if (isLicense) {
            // axios.get(process.env.REACT_APP_SERVER + `api/BookExemplar/${item.id}`)
            // .then(res => {
            //   if (res.statusText === "OK") {
            history.push(`/Software/LicenseView/${item.id}`);
            //     } else {
            //         deleteBookCategory(item.id);
            //      }
            // })
            //  .catch(error => {
            //      deleteBookCategory(item.id);
            //  });
        } else {
            axios.get(process.env.REACT_APP_SERVER + `api/Software/${item.id}`, {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
                }
            })
                .then(res => {
                    if (res.statusText === "OK") {
                        history.push(`/Software/view/${item.id}`);
                    } else {
                        deleteBook(item.id, false);
                    }
                })
                .catch(error => {
                    deleteBook(item.id, false);
                });
        }
    }

    function sendRequest() {
        const requestInfo = {
            method: "POST",
            body: JSON.stringify({
                email: auth.userName,
                password: password
            }),
            headers: new Headers({
                "Content-type": "application/json"
            })
        };

        fetch(process.env.REACT_APP_SERVER + "api/auth/login", requestInfo)
            .then(response => {
                if (response.ok) {
                    setPassRight(false);
                    let formData = new FormData();
                    if (isLicense) {
                        formData.append('justification', justification)
                        setDeleteLicense(item.id, formData);
                        return false;
                    } else {
                        formData.append('id', item.id);
                        formData.append('name', item.name ? item.name : "");
                        formData.append('manufacturer', item.manufacturer ? item.manufacturer : "");
                        formData.append('version', item.version ? item.version : 0);
                        formData.append('softwareCategoryId', item.softwareCategoryId ? item.softwareCategoryId : "");
                        formData.append('type', item.type ? item.type : "");
                        formData.append('url', item.url ? item.url : "");
                        formData.append('versionName', item.versionName ? item.versionName : "");
                        formData.append('hasDeleted', true);
                        formData.append('justification', justification)
                        SetDeleteSoftware(item.id, formData)
                        return false
                    }
                } else {
                    setPassRight(true);
                    return true;
                }
            })
            .catch(error => {
                setPassRight(true);
                return true;
            });
    }

    const getPermissionInventory = () => {
        if (localStorage.getItem("auth-token") !== null) {
            var auth = JSON.parse(localStorage.getItem("auth-token"));
            const permission = auth.permissionsByModule;

            // Get the user profile rule for the people management module
            const peopleManagement = permission.filter(el => el.systemId == 6905701);
            // Get the user profile rule for the inventory module
            const inventory = permission.filter(el => el.systemId == 6910301);

            // Checks whether the user is an administrative manager in the people management module and an administrator in the assets module 
            if (inventory[0].roleId === 6905151) {
                return true;
            } else {
                return false;
            }
        }
    }

    return (
        <React.Fragment>
            {GetGeneralPermissionForAction(Screen.SOFTWARES, Allow.READ) &&
                <Tooltip title={t("GENERAL.view")} aria-label="details" placement="top">
                    <IconButton className={classes.iconsTableActions} onClick={verifyView} >
                        <Visibility />
                    </IconButton>
                </Tooltip>
            }
            {GetGeneralPermissionForAction(Screen.SOFTWARES, Allow.EDIT) &&
                <Tooltip title="Editar" aria-label="edit" placement="top">
                    <IconButton className={classes.iconsTableActions} onClick={verifyEdit} >
                        <Edit />
                    </IconButton>
                </Tooltip>}
            {GetGeneralPermissionForAction(Screen.SOFTWARES, Allow.DELETE) &&
                <Tooltip title="Deletar" aria-label="delete" placement="top">
                    <IconButton className={classes.iconsTableActions} onClick={handleOpenDelete}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            }
            <Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="form-dialog-title"
                msg="mensagem"
            >
                <DialogTitle className={classes.Title}>
                    {t("GENERAL.delete")}
                    <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleCloseDelete} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {changeContext == 0 ?
                        <DialogContentText>
                            <p>{(isLicense ? t("INVENTORY.deleteMessageLicense") : t("INVENTORY.deleteMessageSoftware"))} <b>{(isLicense ? item.key : item.name)}</b>?*</p>
                            <TextField
                                error={validJustification}
                                multiline
                                fullWidth
                                variant="outlined"
                                rows="3"
                                value={justification}
                                label={t("INVENTORY.justification")}
                                margin="normal"
                                inputProps={{ maxLength: 100 }}
                                helperText={validJustification ? t(blankField) : `${justification.length}/100`}
                                onChange={(e) => {
                                    setValidJustification(false);
                                    setJustification(e.target.value)
                                }}
                            />
                        </DialogContentText>
                        :
                        <DialogContentText>
                            <p>{(isLicense ? t("INVENTORY.deletePasswordLicense") : t("INVENTORY.deletePasswordSoftware"))}</p>
                            <p><b>{(isLicense ? props.nameSoftware : item.name)}</b></p>
                            <p><b style={{ color: "rgba(33, 33, 33, 0.3)" }}>{(isLicense ? props.manufacturer : item.manufacturer)}</b></p>
                            <p>{t("INVENTORY.deleteActionCannotBeUndone")}</p>
                            <TextField
                                error={validPasswordrequiredField || passRight}
                                label={t("INVENTORY.password")}
                                value={password}
                                onChange={(e) => {
                                    setPasswordRequiredField(false)
                                    setPassRight(false);
                                    setPassword(e.target.value)
                                }}
                                type="password"
                                variant="filled"
                                helperText={validPasswordrequiredField ? t(blankField) : passRight ? "Senha inválida." : ""}
                                style={{ width: "90%", marginLeft: 30, marginRight: 30 }}
                            />
                        </DialogContentText>
                    }
                </DialogContent>
                <DialogActions>
                    <Buttons
                        onClick={handleCloseDelete}
                        tipo="BN-noback"
                        conteudo={t("GENERAL.cancel").toUpperCase()}
                    />
                    <Buttons
                        onClick={handleDelete}
                        tipo="BN-blue"
                        conteudo={changeContext == 0 ? t("GENERAL.next").toUpperCase() : t("GENERAL.delete").toUpperCase()}
                    />
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
};

export default BookTableAction;