import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Delete from "@material-ui/icons/Delete";
import Visibility from "@material-ui/icons/Visibility";
import InventoryContext from "context/inventory/inventoryContext";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import MaintenanceDialog from "./MaintenanceDialog";

const useStyles = makeStyles(theme => ({
  icons: {
    opacity: "0"
  },
  root: {
    flexGrow: 1,
  },
}));

const MaintenanceTableActions = props => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [open, setOpen] = useState(false);
  const { item, deleteMaintenance } = props;

  const [openMaintenance, setOpenMaintenance] = React.useState(false);
  const [type, setType] = React.useState("view");

  const handleClickOpen = () => {
    setType("view")
    setOpenMaintenance(true);
  };

  const handleDelete = () => {
    if (deleteMaintenance) {
      deleteMaintenance(item);
    }
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Tooltip title={t("GENERAL.view")} aria-label="details" placement="top">
        <IconButton className={classes.icons} onClick={handleClickOpen} >
          <Visibility />
        </IconButton>
      </Tooltip>
      {props.context === 'edit' &&
        <Tooltip title="Deletar" aria-label="delete" placement="top">
          <IconButton className={classes.icons} onClick={handleDelete} >
            <Delete />
          </IconButton>
        </Tooltip>
      }
      <MaintenanceDialog open={openMaintenance} setOpen={setOpenMaintenance} valuesMaintenance={item} type={type} />
    </React.Fragment>
  );
};

export default MaintenanceTableActions;