import React from "react";
import history from "history.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from '@material-ui/icons/Close';
import Buttons from "components/buttons/Pressed";
import Delete from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Grid from '@material-ui/core/Grid';
import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";



const DialogDeleteAttachment = props => {
    const {open, setOpen , file, onRemoveFile  } = props;
    
    const classes = useStyles();
    const [t] = useTranslation();

    return(
        <Dialog
            open={open}
            aria-labelledby="form-dialog-title"
            msg="mensagem"
            >
            <DialogTitle className={classes.Title}>
                {t("GENERAL.deleteAttachment")}
                <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={() => {setOpen(false)}} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Grid container>
                        {t("GENERAL.deleteMessageM")}&nbsp;
                        <b>{file ? file.fileName ? file.fileName : file.name : ""}</b>
                        ?
                    </Grid>
                    <br/>
                    <em>{t("HR.toApplyNeedToSave")}</em>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Buttons
                    onClick={() => {setOpen(false)}}
                    tipo="BN-noback"
                    conteudo={t("GENERAL.cancel").toUpperCase()}
                />
                <Buttons
                    onClick={() => {onRemoveFile(file); setOpen(false);}}
                    tipo="BN-blue"
                    conteudo={ t("GENERAL.delete").toUpperCase()}
                />
            </DialogActions>
        </Dialog>
    )
};

export default DialogDeleteAttachment;
