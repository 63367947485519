import React from "react";

import Paper from "@material-ui/core/Paper";

import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";
import Breadcrumb from "components/layout/breadcrumb/Breadcrumb";
import Footer from "components/layout/Footer";
import JobForm from "../JobForm";




const JobView = props => {
    const classes = useStyles();
    const [t] = useTranslation();

    const { userId, jobId, ...others } = props;


    return (
        <React.Fragment>
            <div className="headerNewDesign">
                <Breadcrumb
                    newDesign={true}
                    routes={[
                        { label: t("HR.humanResource") , to: "/HumanResources"},
                        { label: t("ROUTES.config") },
                        { label: t("HR.jobs"), to: "/Jobs" },
                        { label: t("GENERAL.view") + " " + t("HR.jobs") }
                    ]}
                />
                <div className="container-headerNewDesign">
                    <h1 className={classes.titleNewDesign}>{t("GENERAL.view") + " " + t("HR.jobs")}</h1>
                </div>
            </div>

            <div className="containerNewDesign">
                <Paper>
                    <div className="cabecalho">
                        {t("HR.jobsData").toUpperCase()}
                    </div>

                    <JobForm jobId={jobId} context={"view"} />
                </Paper>
            </div>
            <Footer />
        </React.Fragment>
    )
};

export default JobView;

