import axios from 'axios';
import React, { useReducer } from 'react';
import { DEFAULT, LIBRARY } from '../../types';
import queueContext from './queueContext';
import queueReducer from './queueReducer';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

const QueueState = props => {
    const initialState = {
        message: '',
        response: '',
        responseType: '',
        hasChanged: 0,
        successiveActions: 0,
        loading: false,
        myQueue: []
    };

    const [state, dispatch] = useReducer(queueReducer, initialState);
    const [t] = useTranslation();
    const setLoading = () => dispatch({ type: DEFAULT.SET_LOADING });
    const setLoadingFalse = () => dispatch({ type: DEFAULT.SET_LOADING_FALSE });
    const resetMessage = () => dispatch({ type: DEFAULT.RESET_MESSAGE });
    const setHasChanged = () => dispatch({ type: DEFAULT.SET_HAS_CHANGED });

    const getMyQueue = async userId => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/Library/Queue/User/${userId}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: LIBRARY.GET_MY_QUEUE,
            payload: res.data
        });
    };

    return (
        <queueContext.Provider
            value={{
                loading: state.loading,
                message: state.message,
                hasChanged: state.hasChanged,
                response: state.response,
                responseType: state.responseType,
                successiveActions: state.successiveActions,
                setLoading,
                setLoadingFalse,
                resetMessage,
                setHasChanged,
                getMyQueue,
                myQueue: state.myQueue
            }}
        >
            {props.children}
        </queueContext.Provider>
    );
};

export default QueueState;
