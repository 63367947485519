import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import AddCircle from "@material-ui/icons/AddCircle";
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Buttons from "components/buttons/Pressed";
import { useStyles } from "components/layout/CommonStyles";
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";

const tooltipTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#2c3e51"
        }
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                color: "white",
                fontSize: "1em",
                backgroundColor: "red",
                opacity: "0.7"
            }
        }
    }
});

const DialogBatchAdd = (props) => {
    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }
    const [t] = useTranslation();
    const classes = useStyles();
    const { open, setOpen, amountEquipments, callBackGetEquipment, values, externalOrigin, nextCodes, allSerialNumbers, allFcpcCodes, allLsbdCodes, allUfcCodes, allExternCodes } = props;

    const [arrayEquipment, setArrayEquipment] = useState(() => {
        const obj = [];

        for (let i = 0; i < amountEquipments; i++) {
            obj.push({

                idTypeCode: [{ value: "", type: "", category: "internalCode", partner: "", codeAlreadyTaken: false }],
                serialNumber: "",
                snIsRequired: false,
                typesIsRequired: false,
                snAlreadyTaken: false,
            });
        }

        return obj;
    });

    const handleClose = () => {
        setOpen(false);
        setArrayEquipment([]);
    };

    const handleChangeSerialNumber = indexEquip => event => {
        if (event.target.value.length <= 50) {
            const newValues = [...arrayEquipment];
            const equip = newValues[indexEquip];
            if (equip) {
                equip.typesIsRequired = false;
                equip.snIsRequired = false;
                equip.snAlreadyTaken = false;

                const data_SN = allSerialNumbers.filter(item => item && item.trim() === event.target.value.trim());
                const data_SN_Between_Equips = newValues.filter((item, idx) =>
                    idx !== indexEquip &&
                    item.serialNumber &&
                    item.serialNumber.trim() === event.target.value.trim()
                );
                if (data_SN.length !== 0 || data_SN_Between_Equips.length !== 0) {
                    equip.snAlreadyTaken = true;
                }

                equip[event.target.name] = event.target.value;
                setArrayEquipment(newValues);
            }
        }
    };

    const handleChangeCategory = (indexEquip, indexField) => event => {
        const newValues = [...arrayEquipment];
        const equip = newValues[indexEquip];
        if (equip) {
            const field = equip.idTypeCode[indexField];
            field.category = event.target.value;
            field.partner = "";

            if (event.target.value === "externalCode") {
                field.type = "externalCode";
            } else {
                field.type = "";
            }
        }
        setArrayEquipment(newValues);
    };

    const handleChangeSelector = (indexEquip, indexField) => event => {
        const newValues = [...arrayEquipment];
        const equip = newValues[indexEquip];
        if (equip) {
            equip.typesIsRequired = false;
            equip.snIsRequired = false;
            const field = equip.idTypeCode[indexField];
            field.type = event.target.value;

            let next = nextCodes[event.target.value];
            if (next) {
                while (
                    newValues.some(eq => eq.idTypeCode.some(x =>
                        x.type === event.target.value &&
                        x.value === next.toString()
                    ))
                ) {
                    next++;
                }

                field.value = next.toString();
            }
        }
        setArrayEquipment(newValues);
    };

    const handleChangePartner = (indexEquip, indexField, newValue) => {
        const newValues = [...arrayEquipment];
        const equip = newValues[indexEquip];
        if (equip) {
            equip.typesIsRequired = false;
            equip.snIsRequired = false;
            const field = equip.idTypeCode[indexField];
            field.partner = newValue ? newValue : "";

            if (newValue) {
                let next = nextCodes[newValue.toLowerCase()];
                if (next) {
                    while (
                        newValues.some((eq, iEquip) =>
                            iEquip !== indexEquip &&
                            eq.idTypeCode.some(x =>
                                x.partner === newValue &&
                                x.value === next.toString()
                            ))
                    ) {
                        next++;
                    }

                    field.value = next.toString();
                }
            }
        }
        setArrayEquipment(newValues);
    };

    const handleChangeField = (indexEquip, indexField) => event => {
        const newValues = [...arrayEquipment];
        const equip = newValues[indexEquip];
        if (equip.idTypeCode[indexField].type === "ufcCode") {
            const dataUFCCodes = allUfcCodes.filter(item => item && item.trim() === event.target.value.trim());

            if (dataUFCCodes.length !== 0) {
                equip.idTypeCode[indexField].codeAlreadyTaken = true;
            } else {
                equip.idTypeCode[indexField].codeAlreadyTaken = false;
            }
        }
        if (equip.idTypeCode[indexField].type === "lsbdCode") {
            const dataLsbdCodes = allLsbdCodes.filter(item => item && item.trim() === "00" + event.target.value.trim());


            if (dataLsbdCodes.length !== 0) {
                equip.idTypeCode[indexField].codeAlreadyTaken = true;
            } else {
                equip.idTypeCode[indexField].codeAlreadyTaken = false;
            }
        }

        if (equip.idTypeCode[indexField].type === "fcpcCode") {
            const dataUfcpCodes = allFcpcCodes.filter(item => item && item.trim() === event.target.value.trim());

            if (dataUfcpCodes.length !== 0) {
                equip.idTypeCode[indexField].codeAlreadyTaken = true;
            } else {
                equip.idTypeCode[indexField].codeAlreadyTaken = false;
            }
        }
        if (equip.idTypeCode[indexField].type === "externalCode") {
            const dataExternCodes = allExternCodes.filter(item => item && item.trim() === event.target.value.trim());
            if (dataExternCodes.length !== 0) {
                equip.idTypeCode[indexField].codeAlreadyTaken = true;
            } else {
                equip.idTypeCode[indexField].codeAlreadyTaken = false;
            }
        }
        if (!isNaN(event.target.value) && equip && event.target.value.length <= 50) {

            equip.typesIsRequired = false;
            equip.snIsRequired = false;
            const field = equip.idTypeCode[indexField];
            field.value = event.target.value;
        }
        setArrayEquipment(newValues);
    };

    const addStage = (indexEquip) => () => {
        const newValues = [...arrayEquipment];
        const equip = newValues[indexEquip];
        const category = equip.idTypeCode.filter(x => x.category === "internalCode").length < 3 ? "internalCode" : "externalCode";
        if (equip && equip.idTypeCode.length < 4) {
            equip.idTypeCode.push({
                value: "",
                type: category === "externalCode" ? "externalCode" : "",
                category: category,
                partner: "",
                codeAlreadyTaken: false,
            });
            setArrayEquipment(newValues);
        }
    }

    const removeStage = (indexEquip, indexField) => () => {
        const newValues = [...arrayEquipment];
        const equip = newValues[indexEquip];
        if (equip && equip.idTypeCode.length > 1) {
            equip.idTypeCode.splice(indexField, 1);
            setArrayEquipment(newValues);
        }
    }

    const formatDate = (date) => {
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getUTCHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    }

    const formatCodeLSBD = (code) => {
        let res = "";
        if (code) {
            const value = ("00000" + code);
            return "LSBD-" + value.substr(value.length - 5);
        }
        return res;
    }

    const handleSubmitBatch = () => {
        const canSubmit = verifyCanSubmit();
        if (canSubmit) {
            let formData = new FormData();

            arrayEquipment.forEach((equipment, index) => {
                const fcpcCode = equipment.idTypeCode.find(x => x.type === 'fcpcCode');
                const ufcCode = equipment.idTypeCode.find(x => x.type === 'ufcCode');
                const lsbdCode = equipment.idTypeCode.find(x => x.type === 'lsbdCode');
                const externalCode = equipment.idTypeCode.find(x => x.type === 'externalCode');

                formData.append(`items[${index}].name`, values.name);
                formData.append(`items[${index}].brand`, values.brand);
                formData.append(`items[${index}].categoryId`, values.categoryId);
                formData.append(`items[${index}].fcpcCode`, fcpcCode ? fcpcCode.value : "");
                formData.append(`items[${index}].externCode`, externalCode ? `${externalCode.partner.toUpperCase()}-${externalCode.value}` : "");
                formData.append(`items[${index}].ufcCode`, ufcCode ? ufcCode.value : "");
                formData.append(`items[${index}].lsbdCode`, lsbdCode ? formatCodeLSBD(lsbdCode.value) : "");
                formData.append(`items[${index}].projectId`, values.projectId);
                formData.append(`items[${index}].serialNumber`, equipment.serialNumber);
                formData.append(`items[${index}].acquisitionDate`, formatDate(values.acquisitionDate));
                formData.append(`items[${index}].invoice`, values.invoice);
                formData.append(`items[${index}].value`, values.value);
                formData.append(`items[${index}].itemStatusId`, values.statusId);
                formData.append(`items[${index}].placeId`, values.placeId);
                formData.append(`items[${index}].responsibleId`, values.responsibleId);
                formData.append(`items[${index}].responsibleExternalId`, values.responsibleExternalId);
                formData.append(`items[${index}].description`, values.observation);
                formData.append(`items[${index}].detailedDescription`, values.description);
                formData.append(`items[${index}].warrantyData`, values.warrantyDate ? formatDate(values.warrantyDate) : "");
                formData.append(`items[${index}].externPartner`, externalCode ? externalCode.partner.toUpperCase() : "");
                formData.append(`items[${index}].class`, "inventory.Item");
                formData.append(`items[${index}].inventoryItemHistory[0].title`, "Primeiro Status"); // "Primeiro Status" is the database default value for creating a status record.
                formData.append(`items[${index}].inventoryItemHistory[0].itemStatusId`, values.statusId);
                formData.append(`items[${index}].inventoryItemHistory[0].date`, formatDate(new Date()));
                formData.append(`items[${index}].inventoryItemHistory[0].description`, values.statusDescription);
                formData.append(`items[${index}].inventoryItemHistory[0].executorId`, auth.id);
            });

            callBackGetEquipment(formData);
            handleClose();
        }
    }

    const internalOrigins = [
        {
            label: 'FCPC',
            key: 'fcpcCode',
        },
        {
            label: 'UFC',
            key: 'ufcCode',
        },
        {
            label: 'LSBD',
            key: 'lsbdCode',
        },
    ];

    const categories = [
        {
            label: 'INTERNO',
            key: 'internalCode',
        },
        {
            label: 'EXTERNO',
            key: 'externalCode',
        }
    ];

    const getInternalOriginsFiltered = (indexEquip, indexField) => {
        const newValues = [...arrayEquipment];
        const equip = newValues[indexEquip];

        if (equip) {
            const field = equip.idTypeCode[indexField];
            return internalOrigins.filter(cat => cat.key === field.type || !equip.idTypeCode.some(x => x.type === cat.key));

        }

        return [];
    }

    const getCategoriesFiltered = (indexEquip, indexField) => {
        const newValues = [...arrayEquipment];
        const equip = newValues[indexEquip];

        if (equip) {
            const field = equip.idTypeCode[indexField];

            return categories.filter(cat =>
                (
                    cat.key !== "internalCode" ||
                    cat.key === field.category ||
                    equip.idTypeCode.filter(x => x.category === "internalCode").length < 3
                ) &&
                (
                    cat.key !== "externalCode" ||
                    cat.key === field.category ||
                    !equip.idTypeCode.some(x => x.category === "externalCode")
                )
            );
        }

        return [];
    }

    const verifyCanSubmit = () => {
        let res = true;

        const newValue = arrayEquipment.map(equipment => {
            let snIsRequired = equipment.snIsRequired;
            let typesIsRequired = equipment.typesIsRequired;

            if (equipment.serialNumber === '') {
                if (equipment.idTypeCode.every(x => x.type === '' || x.value === '')) {
                    res = false;
                    snIsRequired = true;
                }
                if (equipment.idTypeCode.some(x => x.type === '' || x.value === '')) {
                    res = false;
                    typesIsRequired = true;
                }
                if (equipment.idTypeCode.some(x => x.category === 'externalCode' && x.partner === '')) {
                    res = false;
                    typesIsRequired = true;
                }
            }
            if (equipment.snAlreadyTaken) {
                res = false;
            }
            if (equipment.idTypeCode.some(x => x.codeAlreadyTaken === true)) {
                res = false;
            }


            return { ...equipment, snIsRequired: snIsRequired, typesIsRequired: typesIsRequired };
        });

        if (!res) {
            setArrayEquipment(newValue);
        }

        return res;
    }

    const generateFields = () => {
        return (
            arrayEquipment.map((equipment, index) => (
                <div
                    id={"divStage" + index}
                    key={index}
                    className="stageEquipment"
                >
                    <Grid container style={{ paddingTop: "20px", marginBottom: 10 }}>
                        <Grid item xs={3} sm={3}>
                            <Typography className={classes.titleNewEquipment}>
                                Equipamento {index + 1}
                            </Typography>
                        </Grid>
                        <Grid item xs={9} sm={9} container spacing={3}>
                            {equipment.idTypeCode.map((type, i) =>
                                <React.Fragment key={i}>
                                    <Grid item xs={3} sm={3}>
                                        <TextField
                                            style={{ marginTop: "-26px" }}
                                            select
                                            label={t("GENERAL.identification") + "*"}
                                            margin="normal"
                                            value={type.category}
                                            onChange={handleChangeCategory(index, i)}
                                            className={classes.textField}
                                            classes={{
                                                root: classes.inputRoot,
                                                input: classes.inputInput
                                            }}
                                            inputProps={{ "aria-label": "search" }}
                                        >
                                            {getCategoriesFiltered(index, i).map(code => (
                                                <MenuItem key={code.key} value={code.key}>
                                                    {code.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={4} sm={4}>
                                        <MuiThemeProvider theme={tooltipTheme}>
                                            {type.category === "internalCode" ?
                                                <Tooltip
                                                    TransitionComponent={Zoom}
                                                    open={equipment.typesIsRequired && !type.type}
                                                    title={t("GENERAL.requiredField")}
                                                >
                                                    <TextField
                                                        style={{ marginTop: "-26px" }}
                                                        select
                                                        id="idTypeCode"
                                                        label={t("GENERAL.origin") + "*"}
                                                        name={"idTypeCode"}
                                                        margin="normal"
                                                        onChange={handleChangeSelector(index, i)}
                                                        className={classes.textField}
                                                        classes={{
                                                            root: classes.inputRoot,
                                                            input: classes.inputInput
                                                        }}
                                                        inputProps={{ "aria-label": "search" }}
                                                    >
                                                        {getInternalOriginsFiltered(index, i).map(code => (
                                                            <MenuItem key={code.key} value={code.key}>
                                                                {code.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Tooltip>
                                                :
                                                <Tooltip
                                                    TransitionComponent={Zoom}
                                                    open={equipment.typesIsRequired && !type.partner}
                                                    title={t("GENERAL.requiredField")}
                                                >
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        freeSolo
                                                        options={externalOrigin}
                                                        value={type.partner}
                                                        inputValue={type.partner}
                                                        onChange={(event, newInputValue) => {
                                                            handleChangePartner(index, i, newInputValue);
                                                        }}
                                                        onInputChange={(event, newInputValue) => {
                                                            handleChangePartner(index, i, newInputValue);
                                                        }}
                                                        renderInput={(params) =>
                                                            <TextField
                                                                {...params}
                                                                style={{ marginTop: "-26px" }}
                                                                label={t("GENERAL.origin")}
                                                                margin="normal"
                                                                className={classes.textField}
                                                                classes={{
                                                                    root: classes.inputRoot,
                                                                    input: classes.inputInput
                                                                }}
                                                            />
                                                        }
                                                    />
                                                </Tooltip>
                                            }
                                        </MuiThemeProvider>
                                    </Grid>

                                    <Grid item xs={4} sm={4}>
                                        <MuiThemeProvider theme={tooltipTheme}>
                                            <Tooltip
                                                TransitionComponent={Zoom}
                                                open={(equipment.typesIsRequired && !type.value) || (equipment.idTypeCode[i].codeAlreadyTaken)}
                                                title={equipment.idTypeCode[i].codeAlreadyTaken ? t("INVENTORY.codeAlreadyTaken") : t("GENERAL.requiredField")}
                                            >
                                                <TextField
                                                    style={{ marginTop: "-26px" }}
                                                    id={"idCode" + i}
                                                    label={t("GENERAL.code") + "*"}
                                                    name={"idCode" + i}
                                                    margin="normal"
                                                    classes={{
                                                        root: classes.inputRoot,
                                                        input: classes.inputInput
                                                    }}
                                                    value={type.value}
                                                    onChange={handleChangeField(index, i)}
                                                    className={classes.textField}
                                                    inputProps={{ "aria-label": "search" }}
                                                />
                                            </Tooltip>
                                        </MuiThemeProvider>
                                    </Grid>
                                    {(equipment.idTypeCode.length > 1) &&
                                        <Grid item xs={1} sm={1}>
                                            <Tooltip
                                                title="Remover código"
                                                aria-label="remover"
                                                placement="top"
                                            >
                                                <IconButton
                                                    style={{ float: "right", marginTop: "-20px" }}
                                                    onClick={removeStage(index, i)}
                                                    className={classes.icons}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    }
                                </React.Fragment>
                            )}
                            {(equipment.idTypeCode.length < 4) &&
                                <Grid item xs={12} sm={12}>
                                    <div onClick={addStage(index)} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', width: 'max-content', marginTop: "-20px" }}>
                                        <Tooltip
                                            title="Adicionar outro código"
                                            aria-label="adicionar"
                                            placement="top"
                                        >
                                            <IconButton
                                                style={{ float: "left", }}
                                                className={classes.icons}
                                            >
                                                <AddCircle />
                                            </IconButton>
                                        </Tooltip>
                                        <Typography>{t("GENERAL.add")}</Typography>
                                    </div>
                                </Grid>
                            }
                            <Grid item xs={7} sm={7}>
                                <MuiThemeProvider theme={tooltipTheme}>
                                    <Tooltip
                                        TransitionComponent={Zoom}
                                        open={equipment.snIsRequired || equipment.snAlreadyTaken}
                                        title={equipment.snIsRequired ? t("GENERAL.requiredField") : t("INVENTORY.serialNumberAlreadyTaken")}
                                    >
                                        <TextField
                                            style={{ marginTop: "-26px" }}
                                            label={t("INVENTORY.serialNumber2") + "*"}
                                            name={"serialNumber"}
                                            margin="normal"
                                            classes={{
                                                root: classes.inputRoot,
                                                input: classes.inputInput
                                            }}
                                            value={equipment.serialNumber}
                                            onChange={handleChangeSerialNumber(index)}
                                            className={classes.textField}
                                            inputProps={{ "aria-label": "search" }}
                                        />
                                    </Tooltip>
                                </MuiThemeProvider>
                            </Grid>
                        </Grid>
                    </Grid>
                </div >
            ))
        );
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            aria-labelledby="max-width-dialog-title"
            aria-labelledby="form-dialog-title"
            msg="mensagem"
            maxWidth="md"
        >
            <DialogTitle className={classes.actionsTitle} style={{ width: 'auto' }}>
                <span style={{ fontWeight: 'bold' }}>
                    {t("INVENTORY.equipmentIdentification").toLocaleUpperCase()}
                </span>
                <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {generateFields()}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Buttons
                    onClick={handleClose}
                    tipo="BN-noback"
                    conteudo={t("GENERAL.cancel").toLocaleUpperCase()}
                />
                <Buttons
                    onClick={handleSubmitBatch}
                    tipo="BN-blue"
                    conteudo={t("GENERAL.confirm").toLocaleUpperCase()}
                />
            </DialogActions>
        </Dialog>
    );
}

export default DialogBatchAdd;