import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Transition from 'components/layout/transition/Transition';
import FilterContainer from 'components/containers/FilterContainer';
import InputFilter from 'components/inputs/InputFilter';
import Dissatisfied from '@material-ui/icons/SentimentVeryDissatisfied';
import { useStyles } from 'components/layout/CommonStyles';
import { useQueryLSBDCashFlowTransactions } from 'hooks/DataFetching/Quotas/Dashboard/useQueryLSBDCashFlowTransactions';
import { TransactionsTypes } from '../../ScreenUtils';
import { screenStyles } from './Styles';
import Chart from 'react-apexcharts';
import { valueToMoneyFloat } from 'utils/general/valueToMoneyFloat';
import { periodArray, periodConstants } from 'utils/arrays/periodUntilOneYear';
import Typography from 'components/typography/Typography';
import { CardWithIcon } from 'components/Card/CardWithIcon';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Grid from '@material-ui/core/Grid';
import { moneyMask } from 'utils/masks/moneyMask';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { useQueryLSBDCashFlowBills } from 'hooks/DataFetching/Quotas/Dashboard/useQueryLSBDCashFlowBills';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import SwitchOutlinedButton from 'components/buttons/SwitchOutlinedButton';
// import { ReactComponent as PlusGreenIcon } from 'assets/icons/plusGreen.svg';
// import { ReactComponent as MinusRedIcon } from 'assets/icons/minusRed.svg';
// import PlusGreenIcon from 'assets/icons/plusGreen.svg';
// import MinusRedIcon from 'assets/icons/minusRed.svg';
import BarChartIcon from '@material-ui/icons/BarChart';
import TimelineIcon from '@material-ui/icons/Timeline';

const CashFlowAnalysisForm = () => {
    const classes = useStyles();
    const styles = screenStyles();
    const [t] = useTranslation();

    const [filterPeriod, setFilterPeriod] = useState(
        periodConstants.LAST_MONTH
    );
    const [filterYear, setFilterYear] = useState('');
    const [yearsArray, setYearsArray] = useState([]);

    const { isLoading, error, data } = useQueryLSBDCashFlowTransactions();
    const {
        isLoading: isLoadingBills,
        error: errorBills,
        data: dataBills
    } = useQueryLSBDCashFlowBills();

    const [creditData, setCreditData] = useState([]);
    const [debitData, setDebitData] = useState([]);
    const [billsData, setBillsData] = useState([]);
    const [creditDataFilter, setCreditDataFilter] = useState([]);
    const [debitDataFilter, setDebitDataFilter] = useState([]);
    const [billsDataFilter, setBillsDataFilter] = useState([]);

    const [isBar, setIsBar] = useState(false);

    const applyFilter = data => {
        let dataFilter = [];

        dataFilter = [...data];

        if (filterYear) {
            dataFilter = dataFilter.filter(
                item => item.label.split('/')[1] === String(filterYear)
            );
        }

        if (filterPeriod) {
            if (filterPeriod === periodConstants.LAST_MONTH) {
                dataFilter = dataFilter.slice(-1);
            }
            if (filterPeriod === periodConstants.LAST_THREE_MONTHS) {
                dataFilter = dataFilter.slice(-3);
            }
            if (filterPeriod === periodConstants.LAST_SIX_MONTHS) {
                dataFilter = dataFilter.slice(-6);
            }
            if (filterPeriod === periodConstants.LAST_TWELVE_MONTHS) {
                dataFilter = dataFilter.slice(-12);
            }
        }

        return dataFilter;
    };

    var options = {
        chart: {
            id: 'chart',
            toolbar: {
                show: true,
                tools: {
                    download: false,
                    selection: {
                        enabled: true
                    },
                    pan: false
                }
            }
        },
        xaxis: {
            categories: [
                ...new Set([
                    ...creditDataFilter.map(item => item.label),
                    ...debitDataFilter.map(item => item.label)
                ])
            ]
        },
        colors: ['#29CC6A', '#FC5555'],
        markers: {
            size: 5,
            shape: 'circle'
        },
        legend: {
            horizontalAlign: 'left',
            fontFamily: 'Roboto, Helvetica',
            markers: {
                radius: 4
            },
            itemMargin: {
                horizontal: 12
            }
        }
    };

    var series = [
        {
            name: 'Créditos',
            data: creditDataFilter.map(item => item.value)
        },
        {
            name: 'Despesas',
            data: debitDataFilter.map(item => item.value)
        }
    ];

    const handlePeriodText = monthsLength => {
        switch (monthsLength) {
            case 1:
                return '1 mês';
            case 12:
                return '1 ano';
            default:
                return `${monthsLength} meses`;
        }
    };

    const sumValues = (arr, attr) => {
        return arr.reduce((partialSum, a) => partialSum + a[attr], 0);
    };

    function get_date_parts(iso_string) {
        const [year, month, day, hr, min, sec] = iso_string.split(/\D/g);

        return { year, month, day, hr, min, sec };
    }

    function group_by_month(arr) {
        return Object.values(
            arr.reduce((a, { date: date_string, value: _value }) => {
                const { year, month } = get_date_parts(date_string);
                const key = `${month}/${year}`;

                if (a[key] === undefined) {
                    a[key] = { value: 0, label: key };
                }

                a[key].value += _value;

                return a;
            }, {})
        );
    }

    const makeYearsArray = (initialDate, finalDate) => {
        const initialYear = new Date(initialDate).getFullYear();
        const lastYear = new Date(finalDate).getFullYear();

        let years = [];
        for (let i = 0; i < lastYear - initialYear + 1; i++) {
            years.push({
                label: String(initialYear + i),
                value: initialYear + i
            });
        }

        return years;
    };

    const sortArrays = (monthA, monthB, yearA, yearB) => {
        if (yearA > yearB) {
            return 1;
        }
        if (yearA < yearB) {
            return -1;
        }
        if (monthA > monthB) {
            return 1;
        }
        if (monthA < monthB) {
            return -1;
        }
        return 0;
    };

    const addDiff = (x, y) => {
        let arrX = [...x];
        let arrY = [...y];

        arrY.forEach(item => {
            const xHasItem = arrX.find(x => x.label === item.label);

            if (!xHasItem) {
                arrX.push({ value: 0, label: item.label });
            }
        });

        arrX = arrX.sort((a, b) =>
            sortArrays(
                parseInt(a.label.split('/')[0]),
                parseInt(b.label.split('/')[0]),
                parseInt(a.label.split('/')[1]),
                parseInt(b.label.split('/')[1])
            )
        );

        return arrX;
    };

    const makeDataFilledWithZeros = yearsArr => {
        let dataWithZeros = [];
        const now = new Date();

        yearsArr.forEach(item => {
            if (item.value === now.getFullYear()) {
                for (let i = 0; i < now.getMonth() + 1; i++) {
                    dataWithZeros.push({
                        label: `${i < 9 ? '0' + (i + 1) : i + 1}/${item.label}`,
                        value: 0
                    });
                }
            } else {
                for (let i = 0; i < 12; i++) {
                    dataWithZeros.push({
                        label: `${i < 9 ? '0' + (i + 1) : i + 1}/${item.label}`,
                        value: 0
                    });
                }
            }
        });

        return dataWithZeros;
    };

    useEffect(() => {
        if (data && dataBills) {
            const yearsArr = makeYearsArray(
                data[0].date,
                data.slice(-1)[0].date
            );
            setYearsArray(yearsArr);
            setFilterYear(yearsArr.slice(-1)[0].value);

            const completedDataFilledWithZeros =
                makeDataFilledWithZeros(yearsArr);

            const creditByMonth = group_by_month(
                data.filter(item => item.type === TransactionsTypes.CREDIT)
            ).map(item => ({
                value: Number(valueToMoneyFloat(Math.abs(item.value))),
                label: item.label
            }));

            const debitByMonth = group_by_month(
                data.filter(item => item.type === 'EXPENSE')
            ).map(item => ({
                value: Number(valueToMoneyFloat(Math.abs(item.value))),
                label: item.label
            }));

            setCreditData(addDiff(creditByMonth, completedDataFilledWithZeros));
            setDebitData(addDiff(debitByMonth, completedDataFilledWithZeros));
            setBillsData(
                dataBills.map(item => {
                    const date = new Date(item.date);
                    const month = date.getMonth() + 1;
                    return {
                        label: `${
                            month < 9 ? '0' + month : month
                        }/${date.getFullYear()}`,
                        count: item.count,
                        expectedSum: item.expectedSum,
                        paidSum: item.paidSum,
                        pendingSum: item.pendingSum
                    };
                })
            );
        }
    }, [data, dataBills]);

    useEffect(() => {
        setCreditDataFilter(applyFilter(creditData));
        setDebitDataFilter(applyFilter(debitData));
        setBillsDataFilter(applyFilter(billsData));
    }, [filterPeriod, filterYear]);

    useEffect(() => {
        if (creditData.length && debitData.length && billsData.length) {
            setCreditDataFilter(applyFilter(creditData));
            setDebitDataFilter(applyFilter(debitData));
            setBillsDataFilter(applyFilter(billsData));
        }
    }, [creditData, debitData, billsData]);

    const realBalance =
        sumValues(creditDataFilter, 'value') -
        sumValues(debitDataFilter, 'value');

    const expectedBalance = sumValues(billsDataFilter, 'expectedSum');
    const pendingBalance = sumValues(billsDataFilter, 'pendingSum');

    return (
        <React.Fragment>
            <Transition loading={isLoading || isLoadingBills} newDesign={true}>
                <FilterContainer>
                    <InputFilter
                        filterValue={filterPeriod}
                        setFilter={setFilterPeriod}
                        label={'GENERAL.period'}
                        isSelect
                        arraySelected={periodArray}
                    />
                    <InputFilter
                        filterValue={filterYear}
                        setFilter={setFilterYear}
                        label={'GENERAL.year'}
                        isSelect
                        arraySelected={yearsArray}
                    />
                </FilterContainer>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '90%',
                        margin: '0 auto',
                        padding: '32px 0'
                    }}
                >
                    <div style={{ padding: '0 8px' }}>
                        <Typography
                            variant={'RobotoBold'}
                            fontSize={24}
                            textColor={'primary'}
                        >
                            {t('QUOTAS.cashFlowAnalysis').toUpperCase()}
                        </Typography>
                        <Typography
                            variant={'RobotoRegular'}
                            fontSize={14}
                            textColor={'secondary'}
                            margin={'8px 0 0'}
                        >
                            {t('QUOTAS.cashFlowAnalysisInfo')}
                        </Typography>
                        <div className={styles.periodParagraph}>
                            <Typography
                                variant={'RobotoBold'}
                                fontSize={14}
                                textColor={'primary'}
                            >
                                Exibindo o período:
                            </Typography>
                            <Typography
                                variant={'RobotoRegular'}
                                fontSize={14}
                                textColor={'secondary'}
                            >
                                &nbsp;{' '}
                                {handlePeriodText(creditDataFilter.length)}
                            </Typography>
                        </div>
                    </div>

                    <Grid
                        style={{ margin: '20px 0 16px', width: '100%' }}
                        container
                        spacing={2}
                    >
                        <Grid item xs={6}>
                            <CardWithIcon
                                head={t('QUOTAS.totalCredit')}
                                content={`R$ ${moneyMask(
                                    valueToMoneyFloat(
                                        sumValues(creditDataFilter, 'value')
                                    )
                                )}`}
                                contentColor={'#5B9B5D'}
                                icon={
                                    <AttachMoneyIcon
                                        style={{ color: '#ffffff' }}
                                        fontSize={'large'}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CardWithIcon
                                head={t('QUOTAS.totalExpenses')}
                                content={`R$ ${moneyMask(
                                    valueToMoneyFloat(
                                        sumValues(debitDataFilter, 'value')
                                    )
                                )}`}
                                contentColor={'#F3222F'}
                                icon={
                                    <AccountBalanceWalletIcon
                                        style={{ color: '#ffffff' }}
                                        fontSize={'large'}
                                    />
                                }
                            />
                        </Grid>

                        <Grid item xs>
                            <CardWithIcon
                                head={t('QUOTAS.realBalance')}
                                content={`${
                                    realBalance < 0 ? '-' : ''
                                } R$ ${moneyMask(
                                    valueToMoneyFloat(realBalance)
                                )}`}
                                contentColor={'#2D9CDB'}
                                icon={
                                    <AccountBalanceIcon
                                        style={{ color: '#ffffff' }}
                                        fontSize={'large'}
                                    />
                                }
                                toolTipContent={t('QUOTAS.realBalanceTip')}
                            />
                        </Grid>
                        <Grid item xs>
                            <CardWithIcon
                                head={t('QUOTAS.expectedBalance')}
                                content={`${
                                    expectedBalance < 0 ? '-' : ''
                                } R$ ${moneyMask(
                                    valueToMoneyFloat(expectedBalance)
                                )}`}
                                contentColor={'#2C3E51'}
                                icon={
                                    <MonetizationOnIcon
                                        style={{ color: '#ffffff' }}
                                        fontSize={'large'}
                                    />
                                }
                                toolTipContent={t('QUOTAS.expectedBalanceTip')}
                            />
                        </Grid>
                        <Grid item xs>
                            <CardWithIcon
                                head={t('QUOTAS.pendingCredit')}
                                content={`R$ ${moneyMask(
                                    valueToMoneyFloat(pendingBalance)
                                )}`}
                                contentColor={'#FD8024'}
                                icon={
                                    <AccessTimeIcon
                                        style={{ color: '#ffffff' }}
                                        fontSize={'large'}
                                    />
                                }
                                toolTipContent={t('QUOTAS.pendingCreditTip')}
                            />
                        </Grid>
                    </Grid>

                    <div className={styles.cashFlowContainer}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Typography
                                variant={'RobotoBold'}
                                fontSize={24}
                                textColor={'#757575'}
                                margin={'0 0 8px'}
                            >
                                Fluxo de Caixa
                            </Typography>
                            <SwitchOutlinedButton
                                icon1={
                                    <BarChartIcon
                                        style={{ color: '#2C3E51' }}
                                        fontSize={'medium'}
                                    />
                                }
                                icon2={
                                    <TimelineIcon
                                        style={{ color: '#2C3E51' }}
                                        fontSize={'medium'}
                                    />
                                }
                                isFirstSelected={isBar}
                                setIsFirstSelected={setIsBar}
                            />
                        </div>
                        <Typography
                            variant={'RobotoRegular'}
                            fontSize={14}
                            textColor={'rgba(109, 111, 113, 0.7)'}
                        >
                            {`Informações sobre todas as transações realizadas no
                            módulo de cotas no ano de ${filterYear}.`}
                        </Typography>
                        <div className={styles.graphContainer}>
                            <Chart
                                options={options}
                                series={series}
                                type={isBar ? 'bar' : 'line'}
                                width={'100%'}
                                height={'100%'}
                            />
                        </div>
                    </div>
                </div>

                <React.Fragment>
                    {error ||
                        (errorBills && (
                            <div className={classes.noParticipantsMessage}>
                                <div>
                                    <Dissatisfied
                                        className={classes.notFoundIconStyle}
                                    />
                                </div>
                                <h2>
                                    {'Houve um erro de conexão com o Servidor'}
                                </h2>
                            </div>
                        ))}
                </React.Fragment>
            </Transition>
        </React.Fragment>
    );
};

export default CashFlowAnalysisForm;
