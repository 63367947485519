import { DisponibleExemplarsAmount } from 'utils/library/DisponibleExemplarsAmount';
import { LibraryExemplarStatusValues } from 'global/constants';

export function handleStatusCollection(exemplars, requisitions) {
    if (requisitions.length) {
        return requisitions[0].status;
    }
    if (DisponibleExemplarsAmount(exemplars) > 0) {
        return LibraryExemplarStatusValues.DISPONIBLE;
    }
    return LibraryExemplarStatusValues.INDISPONIBLE;
}
