import React from "react";
import "../../../../App.css";
import DashboardNavBar from "components/layout/DashboardNavBar";
import SideBar from "components/layout/sidebar/Sidebar";
import HistoryEquipment from "./HistoryEquimentHeader";

const CallHistoryEquipment = ({ match }) => {
  return (
    <div>
      <DashboardNavBar />
      <SideBar />
      <HistoryEquipment idItem={match.params.id} />
    </div>
  );
}

export default CallHistoryEquipment;