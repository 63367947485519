import React from "react";
import "../../../App.css";
import SideBar from "components/layout/sidebar/Sidebar";
import DashboardNavBar from "components/layout/DashboardNavBar";
import MyItens from "./MyItens";

const CallMyItens = ({ match }) => {
  
  return (
    <div>
      <DashboardNavBar />
      <SideBar />
      <MyItens />
    </div>
  );
}

export default CallMyItens;