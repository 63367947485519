import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Visibility from '@material-ui/icons/Visibility';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';
import { Screen, Allow } from 'global/constants';
import { CreditSolicitationStatus } from 'pages/quotasModule/dashboard/ScreenUtils';
import DialogViewCreditSolicitations from '../../Dialogs/CreditSolicitations/DialogViewCreditSolicitations';
import DialogApproveCreditSolicitations from '../../Dialogs/CreditSolicitations/DialogApproveCreditSolicitations';
import DialogRefuseCreditSolicitations from '../../Dialogs/CreditSolicitations/DialogRefuseCreditSolicitations';
import DialogCancelCreditSolicitations from '../../Dialogs/CreditSolicitations/DialogCancelCreditSolicitations';

const CreditSolicitationTableActions = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const { item, isLSBD } = props;

    const [openView, setOpenView] = useState(false);
    const [openApprove, setOpenApprove] = useState(false);
    const [openRefuse, setOpenRefuse] = useState(false);
    const [openCancel, setOpenCancel] = useState(false);

    const [context, setContext] = useState('');

    const handleOpenDialog = context => {
        setContext(context);

        switch (context) {
            case 'view':
                setOpenView(true);
                break;
            case 'approve':
                setOpenApprove(true);
                break;
            case 'refuse':
                setOpenRefuse(true);
                break;
            case 'edit':
                setOpenCancel(true);
                break;
            default:
                break;
        }
    };

    return (
        <React.Fragment>
            <Tooltip
                title={t('GENERAL.view')}
                aria-label="details"
                placement="top"
            >
                <IconButton
                    className={classes.iconsTableActions}
                    onClick={() => handleOpenDialog('view')}
                >
                    <Visibility />
                </IconButton>
            </Tooltip>

            {GetGeneralPermissionForAction(
                Screen.QUOTAS_DASHBOARD_PERSONAL_TAB_SOLICITATIONS,
                Allow.CREATE
            ) && isLSBD ? (
                <Tooltip
                    title={t('GENERAL.approve')}
                    aria-label="details"
                    placement="top"
                >
                    <IconButton
                        className={classes.iconsTableActions}
                        onClick={() => handleOpenDialog('approve')}
                        disabled={
                            item.status !== CreditSolicitationStatus.PENDING
                        }
                    >
                        <CheckCircleIcon />
                    </IconButton>
                </Tooltip>
            ) : null}

            {isLSBD &&
            GetGeneralPermissionForAction(
                Screen.QUOTAS_DASHBOARD_PERSONAL_TAB_SOLICITATIONS,
                Allow.DELETE
            ) ? (
                <Tooltip
                    title={t('GENERAL.refuse')}
                    aria-label="details"
                    placement="top"
                >
                    <IconButton
                        className={classes.iconsTableActions}
                        onClick={() => handleOpenDialog('refuse')}
                        disabled={
                            item.status !== CreditSolicitationStatus.PENDING
                        }
                    >
                        <CancelIcon />
                    </IconButton>
                </Tooltip>
            ) : null}

            {!isLSBD &&
            GetGeneralPermissionForAction(
                Screen.QUOTAS_DASHBOARD_PERSONAL_TAB_SOLICITATIONS,
                Allow.DELETE
            ) ? (
                <Tooltip
                    title={t('GENERAL.cancel')}
                    aria-label="details"
                    placement="top"
                >
                    <IconButton
                        className={classes.iconsTableActions}
                        onClick={() => handleOpenDialog('edit')}
                        disabled={
                            item.status !== CreditSolicitationStatus.PENDING
                        }
                    >
                        <CancelIcon />
                    </IconButton>
                </Tooltip>
            ) : null}

            {openView && (
                <DialogViewCreditSolicitations
                    open={openView}
                    setOpen={setOpenView}
                    viewValues={item}
                    context={context}
                    isLSBD={isLSBD}
                />
            )}
            {openApprove && (
                <DialogApproveCreditSolicitations
                    open={openApprove}
                    setOpen={setOpenApprove}
                    viewValues={item}
                    context={context}
                    isLSBD={isLSBD}
                />
            )}
            {openRefuse && (
                <DialogRefuseCreditSolicitations
                    open={openRefuse}
                    setOpen={setOpenRefuse}
                    viewValues={item}
                    context={context}
                    isLSBD={isLSBD}
                />
            )}
            {openCancel && (
                <DialogCancelCreditSolicitations
                    open={openCancel}
                    setOpen={setOpenCancel}
                    viewValues={item}
                    context={context}
                    isLSBD={isLSBD}
                />
            )}
        </React.Fragment>
    );
};

export default CreditSolicitationTableActions;
