import * as React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import ErrorIcon from '@material-ui/icons/Error';
import { tooltipStyles } from './Styles';

const DefaultTooltip = ({ text, color, fontSize, ...props }) => {
    const styles = tooltipStyles();

    return (
        <Tooltip {...props} title={text} placement="top-start">
            <ErrorIcon
                fontSize={fontSize ? 'inherit' : 'medium'}
                styles={{ color: color, fontSize: fontSize }}
            />
        </Tooltip>
    );
};

export default DefaultTooltip;
