/* eslint-disable */
import Paper from "@material-ui/core/Paper";
import Box from '@material-ui/core/Box';
import CollaboratorContext from "context/hrModule/collaborator/collaboratorContext";
import React, { useContext, useEffect, useState } from "react";
import history from "history.js";
import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";
import Grid from "@material-ui/core/Grid";
import Input from "components/inputs/Input";
import Typography from "@material-ui/core/Typography";
import Transition from "components/layout/transition/Transition";
import Buttons from "components/buttons/Pressed";
import DocumentsTable from "./DocumentTable"
import HistoryTable from "./HistoryDocumentTable"
import InputFile from "components/inputs/InputModal";
import Button from "@material-ui/core/Button";
import { FormatDateToDataBase } from "utils/dates/FormatDateToDataBase";
import DialogActionDocument from "./DialogActionsDocument";
import DialogCancelOperation from "components/dialogs/DialogCancelOperation";

const FormationForm = (props) => {
    const classes = useStyles();
    const [t] = useTranslation();
    const collaboratorContext = useContext(CollaboratorContext);
    const { id, userId, context, ...others } = props;
    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }

    const [idQualification, setIdQualification] = useState("");
    const [documentData, setDocumentData] = useState("");
    const [openCancelDialog, setOpenCancelDialog] = useState(false);
    const [hasChange, setHasChange] = useState(false);

    const {
        loading,
        setLoading,
        setLoadingFalse,
        addDocumentFile,
        documents,
        getCollaboratoresDocuments,
        deleteDoc,
        addHistory,
        docHistory,
        getDocumentsHistory,
        updateDocument
    } = collaboratorContext

    const [values, setValues] = useState({
        id: "",
        description: "",
        documentFilePath: "",
        documentFileName: "Anexar documento",
        documentsData: [],
        historyData: []
    });

    const [valuesDoc, setValuesDoc] = useState({
        id: "",
        description: "",
        documentFilePath: "",
        documentFileName: "Anexar documento",
    });

    const [changeState, setChangeState] = useState([1]);
    const [contextForm, setContextForm] = useState("new");
    const [collaboratorFile, setCollaboratorFile] = useState([]);

    const clearValues = () => {
        setValues({
            documentsData: [],
            historyData: []
        });
        setValuesDoc({
            id: "",
            description: "",
            documentFilePath: "",
            documentFileName: "Anexar documento",
        });
    }

    async function fetchData(userId) {
        await setLoading();
        clearValues();
        await getCollaboratoresDocuments(userId);
        await getDocumentsHistory(userId);
        await setLoadingFalse();
    }

    const [openActionForm, setOpenActionForm] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData(userId);
    }, []);

    const handleChange = (name, value) => {
        setHasChange(true);
        let val = value;
        setValuesDoc({ ...valuesDoc, [name]: val })
    }

    const [documentFile, setDocumentFile] = useState([]);

    const onDrop = acceptedFiles => {
        if (acceptedFiles[0] !== undefined) {
            handleChange("documentFileName", acceptedFiles[0].name)
            setDocumentFile(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    };

    const handleCancel = () => {
        if (hasChange) {
            setOpenCancelDialog(true);
        } else {
            history.push("/Collaborators");
        }
    }


    const verifyCanSubmit = () => {
        let res = true;

        if (valuesDoc.documentFile) {
            res = false;
            //setRequiredName(true);
        }

        return res;
    };

    useEffect(() => {
        setValues({
            documentsData: documents ? documents : [],
            historyData: docHistory ? docHistory : []
        })
    }, [documents.length, documents, docHistory.length, docHistory])

    const handleApply = () => {

        const canSubmit = verifyCanSubmit();
        if (canSubmit) {
            let formData = new FormData();

            formData.append(`document.description`, valuesDoc.description ? valuesDoc.description : "");
            formData.append(`document.userId`, userId);
            formData.append(`document.executorId`, auth.id);

            formData.append("files", documentFile ? documentFile[0] : null);
            formData.append("history", null);

            addDocumentFile(formData, () => { fetchData(userId) });
            setChangeState(changeState => [...changeState, 1]);

        }
    }

    const setOpenActionView = (doc, index) => {
        setOpenActionForm(true);
        setContextForm("view");
        setDocumentData(doc);
    }
    const setOpenActionEdit = (doc, index) => {
        setOpenActionForm(true);
        setContextForm("edit");
        setDocumentData(doc);
    }

    const deleteDocument = (doc, justification) => {
        deleteDoc(doc.id)

        let formData = new FormData();

        formData.append(`history.justification`, justification ? justification : "");
        formData.append(`history.documentName`, doc.documentName ? doc.documentName : "");
        formData.append(`history.operation`, "Deletar");
        formData.append(`history.dateDeletion`, FormatDateToDataBase(new Date()));
        formData.append(`history.userId`, userId);
        formData.append(`history.path`, doc.path);
        formData.append(`history.executorId`, auth.id);
        formData.append(`history.description`, doc.description ? doc.description : "");

        addHistory(formData)
        setChangeState(changeState => [...changeState, 1]);
        fetchData(userId);
    }
    const setUpdateDocument = (valuesFile, newFile, justification) => {
        let path = String(valuesFile.filePath);
        const index = path.lastIndexOf("/");
        if (index >= 0) {
            path = path.substring(0, index);
        }
        let formData = new FormData();
        formData.append(`document.id`, valuesFile.id);
        formData.append(`document.description`, valuesFile.description ? valuesFile.description : "");
        formData.append(`document.userId`, userId);
        formData.append(`document.executorId`, auth.id);
        formData.append("files", newFile ? newFile[0] : null);
        formData.append(`history.justification`, justification ? justification : "");
        formData.append(`history.documentName`, valuesFile.newFileName ? valuesFile.newFileName : "");
        formData.append(`history.operation`, "Atualizar");
        formData.append(`history.dateDeletion`, FormatDateToDataBase(new Date()));
        formData.append(`history.userId`, userId);
        formData.append(`history.executorId`, auth.id);
        formData.append(`history.description`, valuesFile.description ? valuesFile.description : "");
        formData.append(`history.path`, path + "/" + valuesFile.newFileName);

        addHistory(formData)
        updateDocument(formData)
        setChangeState(changeState => [...changeState, 1]);
        fetchData(userId);
    }

    const buttonIsEnabled = () => {
        if (documentFile.length > 0) {
            return (
                <Button
                    className={classes.blueButton}
                    variant="contained"
                    color="primary"
                    onClick={handleApply}
                >
                    {t("GENERAL.save")}
                </Button>
            )
        } else {
            return (
                <Button
                    disabled
                    className={classes.blueButton}
                    variant="contained"
                    color="primary"
                >
                    {t("GENERAL.save")}
                </Button>
            )
        }
    }

    return (
        <React.Fragment>
            <Transition loading={loading} newDesign={true}>
                <Paper>
                    <React.Fragment>
                        <div className={classes.familyDataForm}>
                            {context !== "view" && <React.Fragment>
                                <Typography className={classes.headerColor}><b>Anexar documento</b></Typography>
                                <Grid container item spacing={3} md={12} lg={12}>
                                    <Grid item xs={12} sm={6}>
                                        <InputFile
                                            onDrop={onDrop}
                                            files={collaboratorFile}
                                            label={"HR.document"}
                                            value={valuesDoc.documentFileName}
                                        // error={requiredContract}
                                        // errorMessage={contractHelperMessage}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Input
                                        context={context}
                                        rows={6}
                                        label={"INVENTORY.description"}
                                        value={valuesDoc.description}
                                        valueName={"description"}
                                        handleChange={handleChange}
                                        maxLength={500}
                                    />
                                </Grid>
                                <div style={{ paddingLeft: "10px", paddingBottom: "40px", paddingTop: "40px" }}>
                                    <Buttons
                                        onClick={handleCancel}
                                        tipo="BN-noback"
                                        conteudo={t("GENERAL.cancel")}
                                    />
                                    {buttonIsEnabled()}
                                </div>
                            </React.Fragment>}
                            <Grid container item spacing={3} md={12} lg={12}>
                                <Grid item xs={12} sm={12}>
                                    <Typography className={classes.headerColor}><b>Todos os documentos</b></Typography>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <React.Fragment>
                                        <div className="cabecalho">
                                            {t("HR.documents").toUpperCase()}
                                        </div>
                                        <DocumentsTable
                                            rows={values.documentsData}
                                            headers={["id", "documentName", "description", "dateCreated", "executorName", "path"]}
                                            visibleHeaders={["Nome do arquivo", "descrição", "Usuário", "data", ""]}
                                            sortedFields={["documentName", "description", "executorName", "dateCreated"]}
                                            mainField="documentName"
                                            deleteDocument={deleteDocument}
                                            setUpdateDocument={setUpdateDocument}
                                            setOpenActionView={setOpenActionView}
                                            setOpenActionEdit={setOpenActionEdit}
                                            contextForm={contextForm}
                                            context={context}
                                        />
                                        {values.documentsData && values.documentsData.length === 0 &&
                                            <div className={classes.noParticipantsMessage}>
                                                <h2>{t("HR.noDocumentsAdded")}</h2>
                                                <h4>{t("HR.noDocumentsSubMessage")}</h4>
                                            </div>
                                        }
                                    </React.Fragment>
                                </Grid>
                                <br></br>
                                <Grid item xs={12} sm={12}>
                                    {values.historyData && values.historyData.length > 0 &&
                                        <React.Fragment>
                                            <div className="cabecalho">
                                                {t("HR.history").toUpperCase()}
                                            </div>
                                            <HistoryTable
                                                rows={values.historyData}
                                                headers={["id", "documentName", "justification", "dateDeletion", "executorName", "operation", "userName", "path", "description"]}
                                                visibleHeaders={["Operação", "Nome do arquivo", "Justificativa", "Usuário", "Data", ""]}
                                                sortedFields={["operation", "documentName", "justification", "executorName", "dateDeletion"]}
                                                mainField="dateDeletion"
                                                setOpenActionViewHistory={setOpenActionView}
                                            />
                                        </React.Fragment>
                                    }
                                </Grid>
                            </Grid>
                            <br></br>
                            <br></br>
                        </div>
                    </React.Fragment>
                </Paper>
                <DialogCancelOperation
                    open={openCancelDialog}
                    setOpen={setOpenCancelDialog}
                    path={"/Collaborators"}
                    dialogTitle={t("GENERAL.cancelEdit")}
                    dialogText={t("GENERAL.cancelEditText")}
                />
            </Transition>
            {openActionForm && <DialogActionDocument document={documentData} setUpdateDocument={setUpdateDocument} qualifications={values.documentsData} idQualification={idQualification} open={openActionForm} setOpen={setOpenActionForm} context={context} contextForm={contextForm} />}
        </React.Fragment >
    );
};

export default FormationForm;