import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import Add from "@material-ui/icons/Add";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Footer from "components/layout/Footer";
import MySnackbarContentWrapper from "components/layout/Message";
import Spinner from "components/layout/spinners/Index";
import AllowancesContext from "context/allowance/allowanceContext";
import PartnersContext from "context/partners/partnersContext";
import SearchContext from "context/search/searchContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { animated, useTransition } from "react-spring";
//import { useStyles } from "../profiles/ProfileStyles";
import PartnersTable from "./PartnersTable";
import { useStyles } from "../layout/CommonStyles";
import Dissatisfied from "@material-ui/icons/SentimentVeryDissatisfied";
import Paper from "@material-ui/core/Paper";

const Partners = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const searchContext = useContext(SearchContext);
  const partnersContext = useContext(PartnersContext);
  const allowanceContext = useContext(AllowancesContext);

  const {
    getPartners,
    partners,
    loading,
    hasChanged,
    successiveActions,
    setHasChanged,
    response,
    responseType
  } = partnersContext;

  const {
    allowanceResponse,
    allowanceResponseType,
    resetAllowanceMessage,
    messageHasChanged,
    verifyPermission,
    permission
  } = allowanceContext;
  var { filter } = searchContext;

  const [snackBar, setSnackBar] = useState({
    open: false,
    kind: "",
    content: ""
  });
  let dataFilter = [];

  useEffect(() => {
    getPartners();
    verifyPermission("PARTNER")
    if (allowanceResponseType === "ERROR" && messageHasChanged) {
      handleSnack({ kind: "error", content: allowanceResponse });
    }
    if (responseType === "SUCCESS" && hasChanged > successiveActions) {
      handleSnack({ kind: "success", content: response });
    }
    if (responseType === "ERROR" && hasChanged > successiveActions) {
      handleSnack({ kind: "error", content: response });
    }
    //eslint-disable-next-line
  }, [
    responseType,
    response,
    successiveActions,
    allowanceResponseType,
    allowanceResponse,
    permission.length
  ]);

  //Passa os parametros de ativo ou inativo para a tabela
  dataFilter = partners;
  function isActive(item) {
    if (item.active === true || item.active === "Ativo") {
      item.active = "Ativo";
    } else {
      item.active = "Inativo";
    }
    return item;
  }

  function type(item) {
    if (item.type === "CUSTOMER" || item.type === "Cliente") {
      item.type = "Cliente";
    } else {
      item.type = "Fundação de Apoio";
    }
    return item;
  }
  dataFilter.map(isActive);
  dataFilter.map(type);

  //Filtra os dado da pesquisa
  if (filter) {
    dataFilter = dataFilter.filter(
      item =>
        item.socialreason.toLowerCase().startsWith(filter.toLowerCase()) ||
        item.cnpj.toLowerCase().startsWith(filter.toLowerCase()) ||
        item.type.toLowerCase().startsWith(filter.toLowerCase()) ||
        item.active.toLowerCase().startsWith(filter.toLowerCase())
    );
  }

  const handleSnack = () => {
    setSnackBar({ ...snackBar, open: true });
    setHasChanged();
  };
  const handleSnackClose = () => {
    setSnackBar({ ...snackBar, open: false });
    resetAllowanceMessage();
  };

  const transitions = useTransition(loading, null, {
    from: { opacity: 0 },
    enter: {
      opacity: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "calc(100vh - 70px)"
    },
    leave: { opacity: 0 }
  });

  return transitions.map(({ item, key, props }) =>
    item ? (
      <animated.div key={key} style={props}>
        <Spinner />;
      </animated.div>
    ) : (
        <animated.div key={key} style={props}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={snackBar.open}
            onClose={handleSnackClose}
            TransitionComponent={Slide}
            transitionDuration={{ enter: 500, exit: 500 }}
            ContentProps={{
              "aria-describedby": "message-id"
            }}
            autoHideDuration={2000}
          >
            <MySnackbarContentWrapper
              onClose={handleSnackClose}
              variant={
                responseType !== ""
                  ? responseType.toLowerCase()
                  : allowanceResponseType.toLowerCase()
              }
              message={
                <span id="message-id">
                  {response !== "" ? response : allowanceResponse}
                </span>
              }
            />
          </Snackbar>

          <div className="container">
            <Breadcrumbs
              className={classes.breadCrumbs}
              aria-label="breadcrumb"
              separator={<NavigateNextIcon fontSize="small" />}
            >
              <Typography>{t("GENERAL.planning")}</Typography>
              <Typography>{t("GENERAL.configurations")}</Typography>
              <Typography>{t("PARTNERS.partners")}</Typography>
            </Breadcrumbs>

            <div className="container-header">
              <div className="title">
                <h1 className={classes.title}>{t("PARTNERS.partners")}</h1>
              </div>
              {permission["allowCreate"] && 
              <Link to={"/partners/new"}>
                <Button
                  className={classes.blueButton}
                  variant="contained"
                  color="primary"
                >
                  <div className={classes.buttonIcon}>
                    <Add />
                  </div>
                  {t("GENERAL.add") + " " + t("PARTNERS.partner")}
                </Button>
              </Link>}
            </div>
            
            <PartnersTable
              rows={dataFilter}
              headers={["id", "cnpj", "socialreason", "type", "active"]}
              visibleHeaders={["CNPJ", "NOME", "TIPO", "STATUS"]}
              sortedFields={["cnpj", "socialreason", "type", "active"]}
              mainField="socialreason"
              permission={permission}

            />
            <Paper>
            {(dataFilter.length === 0) &&
              <div className={classes.noParticipantsMessage}>
                <div >
                  <Dissatisfied className={classes.notFoundIconStyle} />
                </div>
                <h2>{t("GENERAL.noResultsFound")}</h2>
                <h5>{`Sua busca por "${filter}" não retornou resultados.`}</h5>
              </div>
              }
            </Paper>
          </div>
          <Footer />
        </animated.div>
      )
  );
};

export default Partners;
