import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import { useStyles } from "components/layout/CommonStyles";
import ProjectsContext from "context/projects/projectsContext";
import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const tooltipTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#2c3e51"
        }
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                color: "white",
                fontSize: "1em",
                backgroundColor: "red",
                opacity: "0.7"
            }
        }
    }
});


const SubsidyHeading = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const requiredField = "GENERAL.requiredField";
    const { index, headingName, headingValue,  updateFields } = props;
    const [value, setValue] = useState(headingValue);
    const projectsContext = useContext(ProjectsContext);
    const { showRequiredFieldMessage, setValueRequiredMessage } = projectsContext;

    useEffect(() => {
        updateFields();
      });

    const converTotBrCurrency = number => {
        var temp = "";
        var len = number.length;
        var count = 0;

        for (var i = 0; i < len; i++) {
            if (i === 1) {
                var aux = "," + number[i] + temp;
                temp = aux;
            } else {
                if (count === 3) {
                    count = 1;
                    var aux = number[i] + "." + temp;
                    temp = aux;
                } else {
                    if (i > 1) {
                        count += 1;
                    }
                    var aux = number[i] + temp;
                    temp = aux;
                }
            }
        }

        return temp;
    };

    const handleValueChange = event => {
        if (event.target.value !== " " && !event.target.value.includes(" ")) {
            var str = event.target.value.split("");
            var aux = str.filter(el => el !== "." && el !== ",").join("");
            var value = event.target.value;

            if (event.target.value) {
                value = converTotBrCurrency(
                    aux
                        .split("")
                        .reverse()
                        .join("")
                );
            }

            aux = value.split("").filter(el => el !== ".").join("").replace(",", ".")
            if (!isNaN(aux)) {
                setValue(value);
            }
        }
    };

    const clearRequiredMessage = () => {
        setValueRequiredMessage(false);
    };

    return (
        <div className={classes.bodyForm}>
            <Grid container spacing={2}>
                <Grid id={"headingName" + index} item xs={4}>
                    {headingName}
                </Grid>
                <Grid item xs={4}>
                    <MuiThemeProvider theme={tooltipTheme}>
                        <Tooltip
                            TransitionComponent={Zoom}
                            open={value.length === 0 && showRequiredFieldMessage}
                            title={t(requiredField)}
                        >
                            <TextField
                                id={"headingValue" + index}
                                className={classes.textField}
                                margin="normal"
                                name="headingValue"
                                value={value}
                                onChange={handleValueChange}
                                onFocus={clearRequiredMessage}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">R$</InputAdornment>
                                    )
                                }}
                            />
                        </Tooltip>
                    </MuiThemeProvider>
                </Grid>
                <div className="lineBreak"></div>
            </Grid>
        </div>
    );
};

export default SubsidyHeading;