/* eslint-disable */
import Breadcrumb from "components/layout/breadcrumb/Breadcrumb";
import Paper from "@material-ui/core/Paper";
import Box from '@material-ui/core/Box';
import Spinner from "components/layout/spinners/Index";
import CollaboratorContext from "context/hrModule/collaborator/collaboratorContext";
import React, { useContext, useEffect, useState } from "react";
import history from "history.js";
import { useTranslation } from "react-i18next";
import { animated, useTransition } from "react-spring";
import { useStyles } from "components/layout/CommonStyles";
import Grid from "@material-ui/core/Grid";
import Input from "components/inputs/Input";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from "@material-ui/core/Typography";
// import DialogReqApprove from "./DialogReqApprove";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { dateToBR } from "utils/dates/DateToBRr";
import { FormatDateToDataBase } from "utils/dates/FormatDateToDataBase";
import { phoneMask } from "utils/masks/phoneMask";
import Transition from "components/layout/transition/Transition";
import DialogCancelOperation from "components/dialogs/DialogCancelOperation";




import Buttons from "components/buttons/Pressed";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CheckIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from '@material-ui/icons/Delete';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TabContext } from "@material-ui/lab";

const FamilyDataForm = (props) => {
    const defaultImage = window.location.origin;
    const classes = useStyles();
    const [t] = useTranslation();
    const collaboratorContext = useContext(CollaboratorContext);
    const { id, userId, context, ...others } = props;
    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }

    const [openCancelDialog, setOpenCancelDialog] = useState(false);
    const [hasChange, setHasChange] = useState(false);

    const {
        loading,
        informationFamilyData,
        getInformationFamilyData,
        setLoading,
        setLoadingFalse,
        updateInformationFamilyData,
        addInformationFamilyData
    } = collaboratorContext

    const [values, setValues] = useState({
        id: "",
        nameFather: "",
        nameMother: "",
        nameSpouse: "",
        professionFather: "",
        professionMother: "",
        phoneSpouse: "",
        maritalStatus: "",
        dependents: [],
    });

    const [dependentsDelete, setDependentsDelete] = useState([]);
    const [changeState, setChangeState] = useState([]);

    const maritalStatusArray = [
        {
            value: 1,
            label: t("HR.single")
        },
        {
            value: 2,
            label: t("HR.married")
        },
        {
            value: 3,
            label: t("HR.widower")
        },
        {
            value: 4,
            label: t("HR.divorced")
        },

    ]

    const clearValues = () => {
        setValues({
            id: "",
            nameFather: "",
            nameMother: "",
            nameSpouse: "",
            professionFather: "",
            professionMother: "",
            phoneSpouse: "",
            maritalStatus: "",
            dependents: [],
        });
    }

    async function fetchData(userId) {
        await setLoading();

        setChangeState(changeState => [...changeState, 1]);
        setDependentsDelete([]);
        clearValues();
        await getInformationFamilyData(userId);
        setLoadingFalse();
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData(userId);
    }, []);

    useEffect(() => {
        if (informationFamilyData) {
            setValues({
                id: informationFamilyData.id ? informationFamilyData.id : null,
                nameFather: informationFamilyData.nameFather ? informationFamilyData.nameFather : "",
                nameMother: informationFamilyData.nameMother ? informationFamilyData.nameMother : "",
                nameSpouse: informationFamilyData.nameSpouse ? informationFamilyData.nameSpouse : "",
                professionFather: informationFamilyData.professionFather ? informationFamilyData.professionFather : "",
                professionMother: informationFamilyData.professionMother ? informationFamilyData.professionMother : "",
                phoneSpouse: informationFamilyData.phoneSpouse ? informationFamilyData.phoneSpouse : "",
                maritalStatus: informationFamilyData.maritalStatus ? informationFamilyData.maritalStatus : "",
                dependents: informationFamilyData.portalSecUserDependent ? informationFamilyData.portalSecUserDependent : []
            })
        }
    }, [informationFamilyData.length, informationFamilyData])

    const handleChange = (name, value) => {
        setHasChange(true);
        let val = value;
        if (name === "phoneSpouse") {
            val = phoneMask(val)
        }
        setValues({ ...values, [name]: val })


    }

    const handleChangeDependent = (indexField) => (name, value) => {
        setHasChange(true);
        const rowsField = [...values.dependents];
        const field = rowsField[indexField];
        if (field) {
            if (name === "dependentName") {
                field.dependentName = value;
            } else if (name === "phoneNumber") {
                field.phoneNumber = phoneMask(value);
            }
            setValues({ ...values, dependents: rowsField });
        }
    }

    const addDependent = () => {
        setValues({
            ...values,
            dependents: [...values.dependents, { dependentName: "", phoneNumber: "" }]
        });
    }


    const removeDependent = (indexField) => () => {
        const rowsField = [...values.dependents];
        if (rowsField[indexField].id) {
            setDependentsDelete(dependentsDelete => [...dependentsDelete, rowsField[indexField]]);
        }
        if (rowsField.length) {
            rowsField.splice(indexField, 1);
            setValues({
                ...values,
                dependents: rowsField
            });
        }
    }


    const handleCancel = () => {
        setOpenCancelDialog(true);
        if (hasChange) {
            setOpenCancelDialog(true);
        } else {
            history.push("/Collaborators");
        }
    }

    const handleApply = () => {
        let formData = new FormData();

        formData.append(`familyData.nameFather`, values.nameFather ? values.nameFather : "");
        formData.append(`familyData.nameMother`, values.nameMother ? values.nameMother : "");
        formData.append(`familyData.nameSpouse`, values.nameSpouse ? values.nameSpouse : "");
        formData.append(`familyData.professionFather`, values.professionFather ? values.professionFather : "");
        formData.append(`familyData.professionMother`, values.professionMother ? values.professionMother : "");
        formData.append(`familyData.phoneSpouse`, values.phoneSpouse ? values.phoneSpouse : "");
        formData.append(`familyData.maritalStatus`, values.maritalStatus ? values.maritalStatus : 0);
        formData.append(`familyData.version`, 0);
        formData.append(`familyData.userId`, userId);

        for (let i in values.dependents) {
            const dependent = values.dependents[i];
            formData.append(`add[${i}].id`, dependent.id ? dependent.id : 0);
            formData.append(`add[${i}].dependentName`, dependent.dependentName ? dependent.dependentName : "");
            formData.append(`add[${i}].phoneNumber`, dependent.phoneNumber ? dependent.phoneNumber : "");
            formData.append(`add[${i}].UserFamilyDataId`, values.id);
        }
        for (let i in dependentsDelete) {
            const dependent = dependentsDelete[i];
            formData.append(`dependentsDelete[${i}].id`, dependent.id);
            formData.append(`dependentsDelete[${i}].dependentName`, dependent.dependentName);
            formData.append(`dependentsDelete[${i}].phoneNumber`, dependent.phoneNumber);
        }

        let familyData;

        if (!values.id) {
            familyData = addInformationFamilyData(formData, fetchData, userId);
        } else {
            familyData = updateInformationFamilyData(values.id, formData, fetchData, userId);
        }

        // setChangeState(changeState => [...changeState, 1]);
        // setDependentsDelete([]);
        // setValues({
        //     ...values,
        //     dependents: []
        // });
    }


    return (
        <React.Fragment>

            <Transition loading={loading} newDesign={true}>
                <Paper>

                    <React.Fragment>
                        <div className={classes.familyDataForm}>
                            <Typography className={classes.headerColor}><b>Filiação</b></Typography>
                            <Grid container item spacing={3} md={12} lg={12} style={{ paddingRight: "50px" }}>
                                <Grid item xs={12} sm={6}>
                                    <Input
                                        context={context}
                                        label={"HR.fatherName"}
                                        value={values.nameFather}
                                        valueName={"nameFather"}
                                        maxLength={50}
                                        handleChange={handleChange}

                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Input
                                        context={context}
                                        label={"HR.fatherProfession"}
                                        value={values.professionFather}
                                        valueName={"professionFather"}
                                        maxLength={30}
                                        handleChange={handleChange}

                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Input
                                        context={context}
                                        label={"HR.motherName"}
                                        value={values.nameMother}
                                        valueName={"nameMother"}
                                        maxLength={50}
                                        handleChange={handleChange}

                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Input
                                        context={context}
                                        label={"HR.motherProfession"}
                                        value={values.professionMother}
                                        valueName={"professionMother"}
                                        maxLength={30}
                                        handleChange={handleChange}

                                    />
                                </Grid>
                            </Grid>
                            <Typography className={classes.headerColor}><b>Dados Familiares</b></Typography>
                            <Grid container item spacing={2} md={12} lg={12} style={{ paddingRight: "50px" }}>
                                <Grid item xs={6} sm={5}>
                                    <Input
                                        context={context}
                                        isSelect
                                        arraySelected={maritalStatusArray}
                                        label={"HR.maritalStatus"}
                                        value={values.maritalStatus}
                                        valueName={"maritalStatus"} handleChange={handleChange}

                                    />

                                </Grid>
                                <Box width="100%" />
                                <Grid item xs={12} sm={6}>
                                    <Input
                                        context={context}
                                        label={"HR.spouseName"}
                                        value={values.nameSpouse}
                                        valueName={"nameSpouse"}
                                        maxLength={50}
                                        handleChange={handleChange}

                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Input
                                        context={context}
                                        label={"HR.spousePhone"}
                                        value={values.phoneSpouse}
                                        valueName={"phoneSpouse"}
                                        handleChange={handleChange}

                                    />
                                </Grid>
                            </Grid>
                            {context !== "view" &&
                                <Grid item xs={12} sm={12}>
                                    <div onClick={addDependent} className={classes.link} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', width: 'max-content', }}>
                                        <Typography >Adicionar dependente</Typography>
                                    </div>
                                </Grid>}
                            {values.dependents && values.dependents.map((dependent, index) =>
                                <Grid container item spacing={3} md={12} lg={12}  >
                                    <Grid item xs={6} sm={6}>
                                        <Input
                                            context={context}
                                            label={"HR.dependentName"}
                                            value={dependent.dependentName}
                                            valueName={"dependentName"}
                                            maxLength={50}
                                            handleChange={handleChangeDependent(index)}
                                        />
                                    </Grid>
                                    <Grid item xs={5} sm={5}>
                                        <Input
                                            context={context}
                                            label={"HR.dependentPhone"}
                                            value={dependent.phoneNumber}
                                            valueName={"phoneNumber"}
                                            handleChange={handleChangeDependent(index)}
                                        />
                                    </Grid>
                                    {context !== "view" &&
                                        <Grid item xs={1} sm={1}>
                                            <Tooltip
                                                title="Remover dependente"
                                                aria-label="remover"
                                                placement="top"
                                            >
                                                <IconButton
                                                    style={{ float: "left", marginTop: 26 }}
                                                    onClick={removeDependent(index)}
                                                    className={classes.icons}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>}
                                </Grid>
                            )}


                        </div>
                        {context !== "view" &&
                            <div style={{ paddingLeft: "10px", paddingBottom: "40px", paddingTop: "40px" }}>
                                <Buttons
                                    onClick={handleCancel}
                                    tipo="BN-noback"
                                    conteudo={t("GENERAL.cancel")}
                                />
                                <Buttons
                                    onClick={handleApply}
                                    tipo="BN-blue"
                                    conteudo={t("GENERAL.apply")}
                                />
                            </div>}
                    </React.Fragment>
                </Paper>

                <DialogCancelOperation
                    open={openCancelDialog}
                    setOpen={setOpenCancelDialog}
                    path={"/Collaborators"}
                    dialogTitle={t("GENERAL.cancelEdit")}
                    dialogText={t("GENERAL.cancelEditText")}
                />
            </Transition>
        </React.Fragment >
    );
};

export default FamilyDataForm;