import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    header: {
        backgroundColor: "#EEF2F6",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 15,
        paddingBottom: 25,
        marginBottom: 16,
    },
    createdAtDate: {
        fontSize: 8,
        color: "#2C3E51",
        display: "flex",
        flexDirection: "row",
        marginBottom: 15,
    },
    logo: {
        width: 68,
        height: 40,
    },
    verticalLine: {
        backgroundColor: "#2C3E51",
        height: 40,
        width: 3,
        marginRight: 10,
        marginLeft: 10
    },
    titles: {
        display: "flex",
        flexDirection: "column",
        height: "40",
        justifyContent: "space-between",
    },
    headerSubTitle: {
        fontSize: 10,
        color: "#2C3E51",
        fontWeight: 300,
        marginBottom: 10
    },
    headerMainTitle: {
        fontSize: 18,
        color: "#2C3E51",
        fontWeight: 700
    },
})

export default styles
