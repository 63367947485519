/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Footer from 'components/layout/Footer';
import HeaderContainer from 'components/containers/HeaderContainer';
import ContentContainer from 'components/containers/ContentContainer';
import { useTranslation } from 'react-i18next';
import { screenStyles } from './Styles';
import Typography from 'components/typography/Typography';
import {
    hrData,
    patrimonyData,
    libraryData,
    quotasData,
    adminData
} from './HomeData';
import { HomeCard } from 'components/Card/index';

const HomeContent = props => {
    const { context } = props;
    const [t] = useTranslation();
    const styles = screenStyles();

    const user = JSON.parse(localStorage.getItem('auth-token'));

    const [moduleData, setModuleData] = useState([]);
    const [title, setTitle] = useState('');
    const [permissions, setPermissions] = useState([]);

    const checkCardPermission = (permissionList, card) => {
        var hasAnyPermission = false;

        const aux = permissionList.find(s => s.screen === card.screen);
        if (aux && aux.allowRead && card.context === 'view') {
            hasAnyPermission = true;
        }
        if (aux && aux.allowEdit && card.context === 'edit') {
            hasAnyPermission = true;
        }

        return hasAnyPermission;
    };

    const checkToolListPermission = (permissionList, toolList) => {
        var hasAnyPermission = false;

        for (let i = 0; i < toolList.length; i++) {
            const aux = permissionList.find(
                s => s.screen === toolList[i].screen
            );
            if (aux && aux.allowRead) {
                hasAnyPermission = true;
                break;
            }
        }

        return hasAnyPermission;
    };

    const checkContext = context => {
        if (context === 'patrimony') {
            setModuleData(patrimonyData);
            setTitle(t('INVENTORY.inventory'));
        }
        if (context === 'hr') {
            setModuleData(hrData);
            setTitle(t('HR.humanResource'));
        }
        if (context === 'library') {
            setModuleData(libraryData);
            setTitle(t('INVENTORY.library'));
        }
        if (context === 'quotas') {
            setModuleData(quotasData);
            setTitle(t('QUOTAS.quotas'));
        }
        if (context === 'admin') {
            setModuleData(adminData);
            setTitle(t('ADMIN.Administrative'));
        }
    };

    useEffect(() => {
        checkContext(context);

        if (user) {
            setPermissions(user.generalPermissions);
        }
    }, [context]);

    return (
        <React.Fragment>
            <HeaderContainer>
                <div className="container-headerNewDesign">
                    <div className="container-module-title">
                        <Typography
                            className={styles.module}
                            variant={'Roboto'}
                            fontSize={14}
                            textColor={'secondary'}
                        >
                            {t('HR.module')}
                        </Typography>
                        <Typography
                            className={styles.title}
                            variant={'RobotoSemiBold'}
                            fontSize={35}
                            textColor={'secondary'}
                        >
                            {title}
                        </Typography>
                    </div>
                </div>
            </HeaderContainer>
            <ContentContainer>
                <Typography
                    className={styles.title}
                    variant={'RobotoSemiBold'}
                    fontSize={16}
                    textColor={'secondary'}
                >
                    {'FERRAMENTAS DO MÓDULO'}
                </Typography>
                <div className={styles.listContainer}>
                    {moduleData.map((data, index) => {
                        return (
                            <div
                                className={styles.toolsListContainer}
                                key={index}
                            >
                                {checkToolListPermission(
                                    permissions,
                                    data.slice(1)
                                ) && (
                                    <React.Fragment>
                                        <Typography
                                            className={styles.title}
                                            variant={'Roboto'}
                                            fontSize={16}
                                            textColor={'secondary'}
                                        >
                                            {data[0]}
                                        </Typography>
                                        <div
                                            className={styles.toolsListWrapper}
                                        >
                                            {data.slice(1).map(dataCard => {
                                                return (
                                                    <React.Fragment>
                                                        {checkCardPermission(
                                                            permissions,
                                                            dataCard
                                                        ) && (
                                                            <HomeCard
                                                                key={
                                                                    dataCard.key
                                                                }
                                                                title={
                                                                    dataCard.title
                                                                }
                                                                description={
                                                                    dataCard.description
                                                                }
                                                                url={
                                                                    dataCard.url
                                                                }
                                                            />
                                                        )}
                                                    </React.Fragment>
                                                );
                                            })}
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        );
                    })}
                </div>
            </ContentContainer>
            <Footer />
        </React.Fragment>
    );
};

export default HomeContent;
