import { ALLOWANCE, DEFAULT } from "../types";

export default (state, action) => {
  switch (action.type) {
    case ALLOWANCE.GET_ALLOWANCES:
      return {
        ...state,
        permission: action.payload,
        //hasChangedAllowances: action.hasChangedAllowances
      };
    case ALLOWANCE.GET_ALLALLOWANCES:
      return {
        ...state,
        allAllowances: action.payload,
      };
    case ALLOWANCE.UPDATE_SHOULD_UPDATE_ALLOWANCES:
      return {
        ...state,
        hasChangedAllowances: action.payload
      };
    case DEFAULT.RESET_ALLOWANCE_MESSAGE:
      return {
        ...state,
        messageHasChanged: false
      };
    case DEFAULT.SET_ALLOWANCE_MESSAGE:
      return {
        ...state,
        allowanceResponse: action.payload,
        allowanceResponseType: action.allowanceResponseType,
        messageHasChanged: action.messageHasChanged,
        loading: false
      };
    default:
      return state;
  }
};
