import axios from 'axios';
import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT, HR } from '../../types';
import clockRecordContext from './clockRecordContext';
import clockRecordReducer from './clockRecordReducer';
import history from '../../../history';
import { duration } from 'moment';
import { Redirect } from 'react-router';
import NotFound from 'components/404';
import Cookies from 'js-cookie';
import { headers } from 'global/variables';

const ClockRecordState = props => {
    const initialState = {
        message: '',
        response: '',
        responseType: '',
        hasChanged: 0,
        successiveActions: 0,
        loading: false,
        serverTime: null,
        myClockRecord: null,
        myActivityRecord: null,
        collaboratorsClockRecords: [],
        viewCollaboratorClockRecord: null,
        collaboratorClockRecordDetails: null,
        myJustifications: null,
        myJustificationsForm: null,
        collaboratorsJustifications: null,
        collaboratorsJustificationsForm: null
    };

    const [t] = useTranslation();
    const [state, dispatch] = useReducer(clockRecordReducer, initialState);
    const setLoading = () => dispatch({ type: DEFAULT.SET_LOADING });
    const setLoadingFalse = () => dispatch({ type: DEFAULT.SET_LOADING_FALSE });

    const resetMessage = () => dispatch({ type: DEFAULT.RESET_MESSAGE });
    const setHasChanged = () => dispatch({ type: DEFAULT.SET_HAS_CHANGED });

    const getServerTime = async () => {
        const res = await axios
            .get(
                process.env.REACT_APP_SERVER + `api/MyClockRecord/ServerTime`,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                dispatch({
                    type: HR.GET_SERVER_TIME,
                    payload: response.data
                });
            });
    };

    const getMyClockRecord = async id => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/MyClockRecord/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: HR.GET_MY_CLOCK_RECORD,
            payload: res.data
        });
    };

    const clockIn = async (obj, fetchData, clockType) => {
        axios
            .put(process.env.REACT_APP_SERVER + 'api/MyClockRecord', obj, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: HR.CLOCK_IN,
                        payload:
                            clockType === 'ENTRY_TIME'
                                ? t('HR.recordEntrySuccess')
                                : clockType === 'LEAVE_FOR_BREAK'
                                ? t('HR.recordLeaveForBreakSuccess')
                                : clockType === 'RETURN_FROM_BREAK'
                                ? t('HR.recordReturnFromBreakSuccess')
                                : t('HR.recordLeaveSuccess'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: HR.CLOCK_IN,
                        payload:
                            clockType === 'ENTRY_TIME'
                                ? t('HR.recordEntryError')
                                : clockType === 'LEAVE_FOR_BREAK'
                                ? t('HR.recordLeaveForBreakError')
                                : clockType === 'RETURN_FROM_BREAK'
                                ? t('HR.recordReturnFromBreakError')
                                : t('HR.recordLeaveError'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: HR.CLOCK_IN,
                    payload:
                        clockType === 'ENTRY_TIME'
                            ? t('HR.recordEntryError')
                            : clockType === 'LEAVE_FOR_BREAK'
                            ? t('HR.recordLeaveForBreakError')
                            : clockType === 'RETURN_FROM_BREAK'
                            ? t('HR.recordReturnFromBreakError')
                            : t('HR.recordLeaveError'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                fetchData();
            });
    };

    const getMyActivityRecord = async id => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER +
                `api/MyClockRecord/MyActivities/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: HR.GET_MY_ACTIVITY_RECORD,
            payload: res.data
        });
    };

    const addActivity = async (obj, setMustRedirect) => {
        axios
            .post(
                process.env.REACT_APP_SERVER + 'api/MyClockRecord/MyActivities',
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: HR.ADD_ACTIVITY,
                        payload: t('HR.recordLeaveSuccess'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: HR.ADD_ACTIVITY,
                        payload: t('HR.recordLeaveError'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: HR.ADD_ACTIVITY,
                    payload: t('HR.recordLeaveError'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                setMustRedirect(true);
            });
    };
    const updateActivity = async (obj, setMustRedirect) => {
        axios
            .put(
                process.env.REACT_APP_SERVER + 'api/MyClockRecord/MyActivities',
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: HR.UPDATE_ACTIVITY,
                        payload: t('HR.activityRecordUpdated'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: HR.UPDATE_ACTIVITY,
                        payload: t('HR.activityRecordUpdatedError'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: HR.UPDATE_ACTIVITY,
                    payload: t('HR.activityRecordUpdatedError'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                setMustRedirect(true);
            });
    };

    const getCollaboratorsClockRecords = async id => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER +
                `api/CollaboratorsClockRecords/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: HR.GET_COLLABORATORS_CLOCK_RECORDS,
            payload: res.data
        });

    };

    const getViewCollaboratorClockRecord = async (id) => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER +
                `api/CollaboratorsClockRecords/ViewCollaborator/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: HR.GET_VIEW_COLLABORATOR_CLOCK_RECORD,
            payload: res.data
        });
    };
    const getCollaboratorClockRecordDetails = async id => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER +
                `api/CollaboratorsClockRecords/CollaboratorDetails/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: HR.GET_COLLABORATOR_CLOCK_RECORD_DETAILS,
            payload: res.data
        });
    };

    const getMyJustifications = async (id, setLoading) => {
        axios
            .get(process.env.REACT_APP_SERVER + `api/MyJustifications/${id}`, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(res => {
                dispatch({
                    type: HR.GET_MY_JUSTIFICATIONS,
                    payload: res.data
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getMyJustificationsByUser = async (id, setLoading) => {
        axios
            .get(
                process.env.REACT_APP_SERVER +
                    `api/MyJustifications/user/${id}`,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(res => {
                dispatch({
                    type: HR.GET_MY_JUSTIFICATIONS_FORM,
                    payload: res.data
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getMyJustificationsBySolicitation = async (id, setLoading) => {
        axios
            .get(
                process.env.REACT_APP_SERVER +
                    `api/MyJustifications/solicitation/${id}`,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(res => {
                dispatch({
                    type: HR.GET_MY_JUSTIFICATIONS_FORM,
                    payload: res.data
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const createMyJustifications = async (obj,setMustRedirect, emailData, userId) => {
        axios.post(process.env.REACT_APP_SERVER + "api/MyJustifications", obj, {
            headers: {
                "Content-type": "application/json",
                "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
            }
        })
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: HR.CREATE_MY_JUSTIFICATIONS,
                        payload: t('HR.myJustificationsSuccess'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                    emailData.solicitationId = response.data.id;
                    getApproverAndSendEmailMyJustifications(emailData , userId , "CREATE");
                } else {
                    dispatch({
                        type: HR.CREATE_MY_JUSTIFICATIONS,
                        payload: t('HR.myJustificationsError'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: HR.CREATE_MY_JUSTIFICATIONS,
                    payload: t('HR.myJustificationsError'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                setMustRedirect(true);
            });
    };

    const updateMyJustifications = async (obj,setMustRedirect,emailData, userId) => {
        axios.put(process.env.REACT_APP_SERVER + "api/MyJustifications", obj, {
            headers: {
                "Content-type": "application/json",
                "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
            }
        })
            .then(response => {
                if (response.status === 200) {
                    dispatch({
                        type: HR.UPDATE_MY_JUSTIFICATIONS,
                        payload: t('HR.myJustificationUpdateSuccess'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });

                    getApproverAndSendEmailMyJustifications(emailData , userId , "UPDATED");
                } else {
                    dispatch({
                        type: HR.UPDATE_MY_JUSTIFICATIONS,
                        payload: t('HR.myJustificationUpdateError'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: HR.UPDATE_MY_JUSTIFICATIONS,
                    payload: t('HR.myJustificationUpdateError'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                setMustRedirect(true);
            });
    };
    const disableMyJustifications = async (id, fetchData) => {
        const obj = {};
        axios
            .put(
                process.env.REACT_APP_SERVER +
                    'api/MyJustifications/Disable/' +
                    id,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                if (response.status === 204) {
                    dispatch({
                        type: HR.UPDATE_MY_JUSTIFICATIONS,
                        payload: t('HR.myJustificationDeleteSuccess'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: HR.UPDATE_MY_JUSTIFICATIONS,
                        payload: t('HR.myJustificationDeleteError'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: HR.UPDATE_MY_JUSTIFICATIONS,
                    payload: t('HR.myJustificationDeleteError'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                fetchData();
            });
    };

    const getCollaboratorsJustifications = async (id, setLoading) => {
        axios
            .get(
                process.env.REACT_APP_SERVER +
                    `api/CollaboratorsJustifications/${id}`,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(res => {
                dispatch({
                    type: HR.GET_COLLABORATORS_JUSTIFICATIONS,
                    payload: res.data
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getCollaboratorsJustificationsBySolicitation = async (id, setLoading) => {
        axios.get(
            process.env.REACT_APP_SERVER + `api/CollaboratorsJustifications/solicitation/${id}`, {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
                }
            }
        ).then(res => {
            dispatch({
                type: HR.GET_COLLABORATORS_JUSTIFICATIONS_FORM,
                payload: res.data
            })
        }).finally(() => {
            setLoading(false)
        })
    }

    const approveCollaboratorsJustifications = async (obj,setMustRedirect, emailData, userId, operatorId) => {
        axios.put(process.env.REACT_APP_SERVER + "api/CollaboratorsJustifications/Approve", obj, {
            headers: {
                "Content-type": "application/json",
                "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
            }
        })
            .then(response => {
                if (response.status === 204) {
                    dispatch({
                        type: HR.APPROVE_COLLABORATORS_JUSTIFICATIONS,
                        payload: t(
                            'HR.CollaboratorsJustificationsApprovedSuccess'
                        ),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });

                    getApproverAndSendEmailCollaboratorsJustifications(emailData, userId, "Approve", operatorId);
                } else {
                    dispatch({
                        type: HR.APPROVE_COLLABORATORS_JUSTIFICATIONS,
                        payload: t(
                            'HR.CollaboratorsJustificationsApprovedError'
                        ),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: HR.APPROVE_COLLABORATORS_JUSTIFICATIONS,
                    payload: t('HR.CollaboratorsJustificationsApprovedError'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                setMustRedirect(true);
            })
    }

    const refuseCollaboratorsJustifications = async (obj,setMustRedirect,emailData, userId, operatorId) => {
        axios.put(process.env.REACT_APP_SERVER + "api/CollaboratorsJustifications/Refuse", obj, {
            headers: {
                "Content-type": "application/json",
                "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
            }
        })
            .then(response => {
                if (response.status === 204) {
                    dispatch({
                        type: HR.REFUSE_COLLABORATORS_JUSTIFICATIONS,
                        payload: t(
                            'HR.CollaboratorsJustificationsRefusedSuccess'
                        ),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                    getApproverAndSendEmailCollaboratorsJustifications(emailData, userId, "Refuse", operatorId);
                } else {
                    dispatch({
                        type: HR.REFUSE_COLLABORATORS_JUSTIFICATIONS,
                        payload: t(
                            'HR.CollaboratorsJustificationsRefusedError'
                        ),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: HR.REFUSE_COLLABORATORS_JUSTIFICATIONS,
                    payload: t('HR.CollaboratorsJustificationsRefusedError'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                setMustRedirect(true);
            })
    }

    const getApproverAndSendEmailCollaboratorsJustifications = (emailData, id, type, operatorId) => {
        axios
            .get(
                process.env.REACT_APP_SERVER +
                    'api/users/approverAndSurrogate/' +
                    id,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                emailData.users = [ response.data[0], response.data[1] && response.data[1].id == operatorId ? response.data[1] : null ];
                if (type === "Approve"){
                    sendEmailsApprovedJustification(emailData);
                }else{
                    sendEmailsRefusedJustification(emailData)
                }
            })
            .catch(error => {
                console.log(error);
            });
        
    }

    const getApproverAndSendEmailMyJustifications = (emailData, id, type) => {
        axios
            .get(
                process.env.REACT_APP_SERVER +
                    'api/users/approverAndSurrogate/' +
                    id,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                emailData.users = [ response.data[0] ];
                if (type === "CREATE"){
                    postEmailNewJustification(emailData);
                }else{
                    postEmailUpdatedJustification(emailData)
                }
            })
            .catch(error => {
                console.log(error);
            });
        
    }

    const postEmailNewJustification = emailData => {
        return axios
        .post(
            process.env.REACT_APP_EMAIL + 'api/HumanResource/newJustification',
            emailData,
            {
                headers: {
                    'Content-type': 'application/json'
                }
            }
        )
        .then(
            res => console.log('ok'),
            err => Promise.reject('error')
        );
    }

    const postEmailUpdatedJustification = emailData => {
        return axios
        .post(
            process.env.REACT_APP_EMAIL + 'api/HumanResource/updatedJustification',
            emailData,
            {
                headers: {
                    'Content-type': 'application/json'
                }
            }
        )
        .then(
            res => console.log('ok'),
            err => Promise.reject('error')
        );
    }

    const sendEmailsApprovedJustification = emailData => {
        return axios
        .post(
            process.env.REACT_APP_EMAIL + 'api/HumanResource/approvedJustification',
            emailData,
            {
                headers: {
                    'Content-type': 'application/json'
                }
            }
        )
        .then(
            res => console.log('ok'),
            err => Promise.reject('error')
        );
    }

    const sendEmailsRefusedJustification = emailData => {
        return axios
        .post(
            process.env.REACT_APP_EMAIL + 'api/HumanResource/refusedJustification',
            emailData,
            {
                headers: {
                    'Content-type': 'application/json'
                }
            }
        )
        .then(
            res => console.log('ok'),
            err => Promise.reject('error')
        );
    }

    return (
        <clockRecordContext.Provider
            value={{
                loading: state.loading,
                message: state.message,
                hasChanged: state.hasChanged,
                response: state.response,
                responseType: state.responseType,
                successiveActions: state.successiveActions,
                serverTime: state.serverTime,
                myClockRecord: state.myClockRecord,
                myActivityRecord: state.myActivityRecord,
                collaboratorsClockRecords: state.collaboratorsClockRecords,
                viewCollaboratorClockRecord: state.viewCollaboratorClockRecord,
                collaboratorClockRecordDetails:
                    state.collaboratorClockRecordDetails,
                myJustifications: state.myJustifications,
                myJustificationsForm: state.myJustificationsForm,
                collaboratorsJustifications: state.collaboratorsJustifications,
                collaboratorsJustificationsForm:
                    state.collaboratorsJustificationsForm,
                setLoading,
                setLoadingFalse,
                resetMessage,
                setHasChanged,
                getServerTime,
                getMyClockRecord,
                clockIn,
                getMyActivityRecord,
                addActivity,
                updateActivity,
                getCollaboratorsClockRecords,
                getViewCollaboratorClockRecord,
                getCollaboratorClockRecordDetails,
                getMyJustifications,
                getMyJustificationsByUser,
                getMyJustificationsBySolicitation,
                createMyJustifications,
                updateMyJustifications,
                disableMyJustifications,
                getCollaboratorsJustifications,
                getCollaboratorsJustificationsBySolicitation,
                approveCollaboratorsJustifications,
                refuseCollaboratorsJustifications
            }}
        >
            {props.children}
        </clockRecordContext.Provider>
    );
};

export default ClockRecordState;
