/* eslint-disable */
import Paper from "@material-ui/core/Paper";
import Box from '@material-ui/core/Box';
import CollaboratorContext from "context/hrModule/collaborator/collaboratorContext";
import React, { useContext, useEffect, useState } from "react";
import history from "history.js";
import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";
import Grid from "@material-ui/core/Grid";
import Input from "components/inputs/Input";
import MessagePaper from "components/messagePaper/MessagePaper";
import Typography from "@material-ui/core/Typography";
import axios from "axios";

import { dateToBR } from "utils/dates/DateToBRr";
import { FormatDateToDataBase } from "utils/dates/FormatDateToDataBase";
import { FormatDateToFront } from "utils/dates/FormatDateToFront";
import Transition from "components/layout/transition/Transition";
import TextField from "@material-ui/core/TextField";
import Edit from "@material-ui/icons/Edit";
import InputAdornment from '@material-ui/core/InputAdornment';
import AdmissionTable from "./AdmissionTable";
import DialogAdmitted from "./DialogAdmitted";
import DialogDemission from "./DialogDemission";
import DialogPosition from "./DialogPosition";
import DialogSalary from "./DialogSalary";
import DialogContract from "./DialogContract";
import DialogAdmissionExam from "./DialogAdmissionExam";
import DialogDemissionExam from "./DialogDemissionExam";
import DialogCraft from "./DialogCraft";
import DialogDateVacation from "./DialogDateVacation";
import DialogDemissionDate from "./DialogDemissionDate";
import DialogAdmissionDate from "./DialogAdmissionDate";
import DialogCancelOperation from "components/dialogs/DialogCancelOperation"

import Buttons from "components/buttons/Pressed";
import IconButton from "@material-ui/core/IconButton";
import Download from "@material-ui/icons/GetApp";

import Cookies from "js-cookie";

const AdmissionForm = (props) => {
    const defaultImage = window.location.origin;
    const fileDownload = require("js-file-download");

    const classes = useStyles();
    const [t] = useTranslation();
    const collaboratorContext = useContext(CollaboratorContext);
    const { id, userId, context, ...others } = props;
    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }

    const [hasChange, setHasChange] = useState(false);

    const {
        loading,
        admission,
        getAdmission,
        companies,
        getAdmissionCompanies,
        jobs,
        getAdmissionJobs,
        rulesets,
        getAdmissionRuleSets,
        setLoading,
        setLoadingFalse,
        admissionSave,
        informationIdentification,
        getInformationIdentification,
    } = collaboratorContext

    const [values, setValues] = useState({
        id: "",
        contractPeriodIdx: 0,
        companyId: 0,
        job: "",
        oldJob: "",
        jobId: 0,
        jobJustification: "",
        ruleset: "",
        rulesetId: 0,
        salary: "",
        oldSalary: "",
        salaryJustification: "",
        admissionExamFilePath: "",
        admissionExamFileName: "",
        admissionExamFilePathJustification: "",
        demissionCraftFilePath: "",
        demissionCraftFileName: "",
        demissionCraftFilePathJustification: "",
        demissionExamFilePath: "",
        demissionExamFileName: "",
        demissionExamFilePathJustification: "",
        contractFilePath: "",
        contractFileName: "",
        contractFilePathJustification: "",
        demissionJustification: "",
        history: []
    });

    const [newContract, setNewContract] = useState([]);
    const [newAdmissionExam, setNewAdmissionExam] = useState([]);
    const [newDemissionExam, setNewDemissionExam] = useState([]);
    const [newCraft, setNewCraft] = useState([]);
    const [openCancelDialog, setOpenCancelDialog] = useState(false);



    const [dates, setDates] = useState({
        admissionDate: null,
        oldAdmissionDate: null,
        admissionDateJustification: "",
        holidayDate: null,
        oldHolidayDate: null,
        holidayDateJustification: "",
        demissionDate: null,
        oldDemissionDate: null,
        demissionDateJustification: ""
    });


    const [canClick, setCanClick] = useState(false);
    const [contractPeriod, setContractPeriod] = useState([]);
    const [companiesArray, setCompaniesArray] = useState([]);
    const [rulesetsArray, setRulesetsArray] = useState([]);
    const [jobsArray, setJobsArray] = useState([]);
    const [idxToContractActive, setIdxToContractActive] = useState(-1);

    const [openDialogAdmitted, setOpenDialogAdmitted] = useState(false);
    const [openDialogDemission, setOpenDialogDemission] = useState(false);
    const [openDialogPosition, setOpenDialogPosition] = useState(false);
    const [openDialogSalary, setOpenDialogSalary] = useState(false);
    const [openDialogContract, setOpenDialogContract] = useState(false);
    const [openDialogAdmissionExam, setOpenDialogAdmissionExam] = useState(false);
    const [openDialogDemissionExam, setOpenDialogDemissionExam] = useState(false);
    const [openDialogCraft, setOpenDialogCraft] = useState(false);

    const [openDialogHolidayDate, setOpenDialogHolidayDate] = useState(false);
    const [openDialogAdmissionDate, setOpenDialogAdmissionDate] = useState(false);
    const [openDialogDemissionDate, setOpenDialogDemissionDate] = useState(false);


    const clearValues = () => {
        setContractPeriod([]);
        setValues({
            id: "",
            contractPeriodIdx: 0,
            companyId: 0,
            job: "",
            oldJob: "",
            jobId: 0,
            jobJustification: "",
            ruleset: "",
            rulesetId: 0,
            salary: "",
            oldSalary: "",
            salaryJustification: "",
            admissionExamFilePath: "",
            admissionExamFileName: "",
            admissionExamFilePathJustification: "",
            demissionCraftFilePath: "",
            demissionCraftFileName: "",
            demissionCraftFilePathJustification: "",
            demissionExamFilePath: "",
            demissionExamFileName: "",
            demissionExamFilePathJustification: "",
            contractFilePath: "",
            contractFileName: "",
            contractFilePathJustification: "",
            demissionJustification: "",
            history: []
        });
    }

    async function fetchData(userId) {
        clearValues();
        await setLoading();
        await getAdmission(userId);
        await getAdmissionCompanies();
        await getAdmissionJobs();
        await getAdmissionRuleSets();
        await getInformationIdentification(userId);
        setLoadingFalse();
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData(userId);
    }, []);

    useEffect(() => {
        if (admission && admission.length > 0) {
            let aux = [];
            let idxLastContract = -1;
            admission.forEach((contract, idx) => {
                var commingAdmissionDate = FormatDateToFront(contract.admissionDate);
                if (contract.demissionDate) {
                    var commingDemissionDate = FormatDateToFront(contract.demissionDate);
                }
                aux.push({ label: (dateToBR(commingAdmissionDate) + " - " + (contract.demissionDate ? dateToBR(commingDemissionDate) : t("GENERAL.today"))), value: idx });
                idxLastContract = idx;
                if (!contract.demissionDate) {
                    setIdxToContractActive(idx);
                }
            });

            setContractPeriod(aux);

            setValues({
                id: admission[idxLastContract].id ? admission[idxLastContract].id : null,
                contractPeriodIdx: idxLastContract !== -1 ? idxLastContract : 0,
                job: admission[idxLastContract].job ? admission[idxLastContract].job : "",
                oldJob: admission[idxLastContract].job ? admission[idxLastContract].job : "",
                jobId: admission[idxLastContract].jobId ? admission[idxLastContract].jobId : 0,
                ruleset: admission[idxLastContract].ruleset ? admission[idxLastContract].ruleset : "",
                rulesetId: admission[idxLastContract].rulesetId ? admission[idxLastContract].rulesetId : 0,
                salary: admission[idxLastContract].salary ? admission[idxLastContract].salary : "",
                oldSalary: admission[idxLastContract].salary ? admission[idxLastContract].salary : "",
                companyId: admission[idxLastContract].companyId ? admission[idxLastContract].companyId : 0,
                admissionExamFilePath: admission[idxLastContract].admissionExamFilePath ? admission[idxLastContract].admissionExamFilePath : "",
                admissionExamFileName: admission[idxLastContract].admissionExamFilePath ? admission[idxLastContract].admissionExamFilePath.split("/").pop() : "",
                oldAdmissionExamFileName: admission[idxLastContract].admissionExamFilePath ? admission[idxLastContract].admissionExamFilePath.split("/").pop() : "",
                contractFilePath: admission[idxLastContract].contractFilePath ? admission[idxLastContract].contractFilePath : "",
                contractFileName: admission[idxLastContract].contractFilePath ? admission[idxLastContract].contractFilePath.split("/").pop() : "",
                contractOldFileName: admission[idxLastContract].contractFilePath ? admission[idxLastContract].contractFilePath.split("/").pop() : "",
                demissionCraftFilePath: admission[idxLastContract].demissionCraftFilePath ? admission[idxLastContract].demissionCraftFilePath : "",
                demissionCraftFileName: admission[idxLastContract].demissionCraftFilePath ? admission[idxLastContract].demissionCraftFilePath.split("/").pop() : "",
                oldDemissionCraftFileName: admission[idxLastContract].demissionCraftFilePath ? admission[idxLastContract].demissionCraftFilePath.split("/").pop() : "",
                demissionExamFilePath: admission[idxLastContract].demissionExamFilePath ? admission[idxLastContract].demissionExamFilePath : "",
                demissionExamFileName: admission[idxLastContract].demissionExamFilePath ? admission[idxLastContract].demissionExamFilePath.split("/").pop() : "",
                oldDemissionExamFileName: admission[idxLastContract].demissionExamFilePath ? admission[idxLastContract].demissionExamFilePath.split("/").pop() : "",
                demissionJustification: admission[idxLastContract].demissionJustification ? admission[idxLastContract].demissionJustification : "",
                history: admission[idxLastContract].history ? admission[idxLastContract].history : []
            });

            var commingAdmissionDate = FormatDateToFront(admission[idxLastContract].admissionDate);
            if (admission[idxLastContract].demissionDate) {
                var commingDemissionDate = FormatDateToFront(admission[idxLastContract].demissionDate);
            }
            if (admission[idxLastContract].holidayDate) {
                var commingHolidayDate = FormatDateToFront(admission[idxLastContract].holidayDate);
            }
            setDates({
                admissionDate: admission[idxLastContract].admissionDate ? commingAdmissionDate : null,
                oldAdmissionDate: admission[idxLastContract].admissionDate ? commingAdmissionDate : null,
                holidayDate: admission[idxLastContract].holidayDate ? commingHolidayDate : null,
                oldHolidayDate: admission[idxLastContract].holidayDate ? commingHolidayDate : null,
                demissionDate: admission[idxLastContract].demissionDate ? commingDemissionDate : null,
                oldDemissionDate: admission[idxLastContract].demissionDate ? commingDemissionDate : null,
            })

        }
    }, [admission.length, admission])

    useEffect(() => {
        if (companies) {
            let aux = [];
            companies.forEach((company) => {
                aux.push({ label: company.tradename, value: company.id })
            })
            setCompaniesArray(aux);
        }
    }, [companies, companies.length])

    useEffect(() => {
        if (rulesets) {
            let aux = [];
            rulesets.forEach((ruleset) => {
                aux.push({ label: ruleset.name, value: ruleset.id })
            })
            setRulesetsArray(aux);
        }
    }, [rulesets, rulesets.length])

    useEffect(() => {
        if (jobs) {
            let aux = [];
            jobs.forEach((job) => {
                if (!job.hasDeleted) {
                    aux.push({ label: job.name, value: job.id })
                }
            })
            setJobsArray(aux);
        }
    }, [jobs, jobs.length])


    const handleOpenDialog = (type) => {
        if (type === "ADMISSION") {
            setOpenDialogAdmitted(true);
        } else if (type === "DEMISSION") {
            setOpenDialogDemission(true);
        } else if (type === "REMUNERATION") {
            setOpenDialogSalary(true);
        } else if (type === "JOB") {
            setOpenDialogPosition(true);
        } else if (type === "CONTRACT") {
            setOpenDialogContract(true);
        } else if (type === "ADMISSION_EXAM") {
            setOpenDialogAdmissionExam(true);
        } else if (type === "DEMISSION_EXAM") {
            setOpenDialogDemissionExam(true);
        } else if (type === "CRAFT") {
            setOpenDialogCraft(true);
        } else if (type === "ADMISSION_DATE") {
            setOpenDialogAdmissionDate(true);
        } else if (type === "DEMISSION_DATE") {
            setOpenDialogDemissionDate(true);
        } else if (type === "HOLIDAY_DATE") {
            setOpenDialogHolidayDate(true);
        }

    }

    const handleChange = (name, value) => {
        setHasChange(true);
        let val = value;
        setValues({ ...values, [name]: val })
    }

    const handleCancel = () => {
        if (hasChange) {
            setOpenCancelDialog(true);
        } else {
            history.push("/Collaborators");
        }
    }

    const handleApply = () => {
        let formData = new FormData();


        formData.append("contract.id", values.id);
        formData.append("contract.admissionDate", dates.admissionDate ? FormatDateToDataBase(new Date(dates.admissionDate)) : null);
        formData.append("contract.admissionDateJustification", dates.admissionDateJustification ? dates.admissionDateJustification : "");
        formData.append("contract.holidayDate", dates.holidayDate ? FormatDateToDataBase(new Date(dates.holidayDate)) : "");
        formData.append("contract.holidayDateJustification", dates.holidayDateJustification ? dates.holidayDateJustification : "");
        formData.append("contract.jobId", values.jobId ? values.jobId : "");
        formData.append("contract.job", values.job ? values.job : "");
        formData.append("contract.jobJustification", values.jobJustification ? values.jobJustification : "");
        formData.append("contract.rulesetId", values.rulesetId ? values.rulesetId : "");
        formData.append("contract.salary", values.salary ? values.salary : "");
        formData.append("contract.salaryJustification", values.salaryJustification ? values.salaryJustification : "");
        formData.append("contract.contractId", values.id ? values.id : 0);
        formData.append("contract.companyId", values.companyId ? values.companyId : "");
        formData.append("contract.userId", auth.id);
        formData.append("contract.demissionDate", dates.demissionDate ? FormatDateToDataBase(new Date(dates.demissionDate)) : "");
        formData.append("contract.demissionDateJustification", dates.demissionDateJustification ? dates.demissionDateJustification : "");

        formData.append("contract.demissionJustification", values.demissionJustification ? values.demissionJustification : "");

        formData.append("contractFile", newContract ? newContract[0] : null);
        formData.append("contract.contractFilePathJustification", values.contractFilePathJustification ? values.contractFilePathJustification : "");

        formData.append("admissionExamFile", newAdmissionExam ? newAdmissionExam[0] : null);
        formData.append("contract.admissionExamFilePathJustification", values.admissionExamFilePathJustification ? values.admissionExamFilePathJustification : "");

        formData.append("demissionCraftFile", newCraft ? newCraft[0] : null);
        formData.append("contract.demissionCraftFilePathJustification", values.demissionCraftFilePathJustification ? values.demissionCraftFilePathJustification : "");

        formData.append("demissionExamFile", newDemissionExam ? newDemissionExam[0] : null);
        formData.append("contract.demissionExamFilePathJustification", values.demissionExamFilePathJustification ? values.demissionExamFilePathJustification : "");

        admissionSave(formData, fetchData, userId);
        setNewContract([]);

    }

    const handleContractContext = (name, value) => {
        setValues({
            id: admission[value].id ? admission[value].id : null,
            contractPeriodIdx: value,
            job: admission[value].job ? admission[value].job : "",
            oldJob: admission[value].job ? admission[value].job : "",
            jobId: admission[value].jobId ? admission[value].jobId : 0,
            ruleset: admission[value].ruleset ? admission[value].ruleset : "",
            rulesetId: admission[value].rulesetId ? admission[value].rulesetId : 0,
            salary: admission[value].salary ? admission[value].salary : "",
            oldSalary: admission[value].salary ? admission[value].salary : "",
            companyId: admission[value].companyId ? admission[value].companyId : 0,
            admissionExamFilePath: admission[value].admissionExamFilePath ? admission[value].admissionExamFilePath : "",
            admissionExamFileName: admission[value].admissionExamFilePath ? admission[value].admissionExamFilePath.split("/").pop() : "",
            oldAdmissionExamFileName: admission[value].admissionExamFilePath ? admission[value].admissionExamFilePath.split("/").pop() : "",
            contractFilePath: admission[value].contractFilePath ? admission[value].contractFilePath : "",
            contractFileName: admission[value].contractFilePath ? admission[value].contractFilePath.split("/").pop() : "",
            contractOldFileName: admission[value].contractFilePath ? admission[value].contractFilePath.split("/").pop() : "",
            demissionCraftFilePath: admission[value].demissionCraftFilePath ? admission[value].demissionCraftFilePath : "",
            demissionCraftFileName: admission[value].demissionCraftFilePath ? admission[value].demissionCraftFilePath.split("/").pop() : "",
            oldDemissionCraftFileName: admission[value].demissionCraftFilePath ? admission[value].demissionCraftFilePath.split("/").pop() : "",
            demissionExamFilePath: admission[value].demissionExamFilePath ? admission[value].demissionExamFilePath : "",
            demissionExamFileName: admission[value].demissionExamFilePath ? admission[value].demissionExamFilePath.split("/").pop() : "",
            oldDemissionExamFileName: admission[value].demissionExamFilePath ? admission[value].demissionExamFilePath.split("/").pop() : "",
            demissionJustification: admission[value].demissionJustification ? admission[value].demissionJustification : "",
            history: admission[value].history ? admission[value].history : []
        });

        var commingAdmissionDate = FormatDateToFront(admission[value].admissionDate);
        if (admission[value].demissionDate) {
            var commingDemissionDate = FormatDateToFront(admission[value].demissionDate);
        }
        if (admission[value].holidayDate) {
            var commingHolidayDate = FormatDateToFront(admission[value].holidayDate);
        }

        setDates({
            admissionDate: admission[value].admissionDate ? commingAdmissionDate : null,
            oldAdmissionDate: admission[value].admissionDate ? commingAdmissionDate : null,
            admissionDateJustification: "",
            holidayDate: admission[value].holidayDate ? commingHolidayDate : null,
            oldHolidayDate: admission[value].holidayDate ? commingHolidayDate : null,
            demissionDate: admission[value].demissionDate ? commingDemissionDate : null,
            oldDemissionDate: admission[value].demissionDate ? commingDemissionDate : null,
            demissionDateJustification: "",
        })

    }

    const onClickDownload = async (slashPath, newFile) => {
        if (newFile.length !== 0) {
            fileDownload(newFile[0], newFile[0].name);
        } else {
            if (slashPath) {
                const noSlashPath = slashPath.replace(/\//g, ";");
                const url = process.env.REACT_APP_SERVER + "api/file/;portal_lsbd;hr_files" + noSlashPath;
                axios.get(url, {
                    headers: {
                        "Content-type": "application/json",
                        "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
                    },
                    responseType: "blob"
                }).then(function (res) {
                    const blob = new Blob([res.data], {});
                    const name = slashPath.split("/").pop()
                    fileDownload(blob, name);
                });
            }
        }
    };


    return (
        <React.Fragment>

            <Transition loading={loading} newDesign={true}>
                <Paper>
                    {!informationIdentification.id ?
                        <div className={classes.familyDataForm}>
                            <Grid container item spacing={3} md={12} lg={12} style={{ padding: "30px" }}>
                                <MessagePaper
                                    headerText={t("HR.collaboratorInProcessOfRegistration")}
                                    subText={t("HR.toAdmitCollaborator")}
                                />
                            </Grid>
                        </div>
                        :
                        <React.Fragment>
                            <div className={classes.familyDataForm}>
                                <div style={{ display: "flex", justifyContent: "space-between", paddingRight: "80px", marginTop: "20px" }}>
                                    <Typography className={classes.admissionSituation}>{t("HR.situation") + " "}
                                        <Typography className={classes.admissionSituationText}>
                                            {admission.length === 0 ? <b>{t("HR.inHiringProcess")}</b> :
                                                admission.some((contract) => {
                                                    return contract.demissionDate === null;
                                                }) ?
                                                    <b>{t("HR.admitted")}</b>
                                                    :
                                                    <b>{t("HR.fired")}</b>
                                            }
                                        </Typography>
                                    </Typography>
                                    {context !== "view" &&
                                        (admission.every((contract) => {
                                            return contract.demissionDate !== null;
                                        })
                                            ?
                                            <Buttons
                                                onClick={() => handleOpenDialog("ADMISSION")}
                                                tipo="BN-noback"
                                                conteudo={t("HR.admit")}
                                                style={{ border: "1px solid" }}
                                            />
                                            :
                                            <Buttons
                                                onClick={() => handleOpenDialog("DEMISSION")}
                                                tipo="BN-noback"
                                                conteudo={t("HR.fire")}
                                                style={{ border: "1px solid" }}
                                            />
                                        )}
                                </div>

                                <Grid container item spacing={2} md={12} lg={12} style={{ paddingRight: "50px" }}>
                                    {admission.length === 0 ?
                                        <Grid item xs={12} sm={4}>
                                            <Input
                                                disabled
                                                label={"HR.contract"}
                                                value={t("HR.noContracts")}
                                            />
                                        </Grid>

                                        :
                                        <Grid item xs={12} sm={4}>
                                            <Input
                                                label={"HR.contract"}
                                                isSelect
                                                arraySelected={contractPeriod}
                                                value={values.contractPeriodIdx}
                                                valueName={"contractPeriodIdx"}
                                                handleChange={handleContractContext}
                                                inputProps={{ autocomplete: 'off' }}
                                            />
                                        </Grid>
                                    }
                                </Grid>



                                <Typography className={classes.headerColor}><b>{t("HR.admissionData")}</b></Typography>
                                {admission.length === 0 ?
                                    <Grid container item spacing={2} md={12} lg={12} style={{ paddingRight: "50px" }}>
                                        <MessagePaper
                                            headerText={t("HR.noInformationAdmissionData")}
                                            subText={t("HR.toAddInformationAdmissionData")}
                                        />
                                    </Grid> :

                                    <Grid container item spacing={2} md={12} lg={12} style={{ paddingRight: "50px" }}>
                                        <Grid item xs={12} sm={4}>
                                            <Input
                                                context={context}
                                                label={"HR.company"}
                                                isSelect
                                                arraySelected={companiesArray}
                                                value={values.companyId}
                                                valueName={"companyId"}
                                                handleChange={handleChange}
                                                inputProps={{ autocomplete: 'off' }}
                                            />
                                        </Grid>
                                        <Box width="100%" />

                                        <Grid item xs={12} sm={4}>

                                            <TextField
                                                disabled
                                                id="client2"
                                                variant="outlined"
                                                label={t("HR.admissionDate")}
                                                name="responsible"
                                                className={classes.textField}
                                                value={dateToBR(new Date(dates.admissionDate))}
                                                margin="normal"
                                                classes={{
                                                    root: classes.inputRoot,
                                                    input: classes.inputInput
                                                }}
                                                inputProps={{ "aria-label": "search", autocomplete: "off" }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {context !== "view" && <IconButton onClick={() => handleOpenDialog("ADMISSION_DATE")}>
                                                                <Edit />
                                                            </IconButton>}
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={4}>

                                            <TextField
                                                disabled
                                                id="client2"
                                                variant="outlined"
                                                label={t("HR.baseDateForVacation")}
                                                name="responsible"
                                                className={classes.textField}
                                                value={dates.holidayDate ? dateToBR(new Date(dates.holidayDate)) : ""}
                                                margin="normal"
                                                classes={{
                                                    root: classes.inputRoot,
                                                    input: classes.inputInput
                                                }}

                                                inputProps={{ "aria-label": "search", autocomplete: "off" }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {context !== "view" && <IconButton onClick={() => handleOpenDialog("HOLIDAY_DATE")}>
                                                                <Edit />
                                                            </IconButton>}
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Grid>

                                        <Box width="100%" />
                                        <Grid item xs={12} sm={4}>
                                            <Input
                                                disabled
                                                label={"HR.regime"}
                                                value={values.ruleset}
                                                valueName={"regime"}
                                                inputProps={{ autocomplete: 'off' }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={4}>

                                            <TextField
                                                disabled
                                                id="client1"
                                                variant="outlined"
                                                label={t("HR.admissionExam")}

                                                name="responsible"
                                                className={classes.textField}
                                                value={values.admissionExamFileName}
                                                margin="normal"
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ autocomplete: 'off' }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton disabled={!values.admissionExamFileName} onClick={() => onClickDownload(values.admissionExamFilePath, newAdmissionExam)}>
                                                                <Download />
                                                            </IconButton>
                                                            {context !== "view" && <IconButton onClick={() => handleOpenDialog("ADMISSION_EXAM")}>
                                                                <Edit />
                                                            </IconButton>}
                                                        </InputAdornment>
                                                    ),
                                                    readOnly: true,
                                                }}
                                            />
                                        </Grid>
                                        <Box width="100%" />


                                        <Grid item xs={12} sm={4}>

                                            <TextField
                                                disabled
                                                id="client2"
                                                variant="outlined"
                                                label={t("HR.position")}
                                                name="responsible"
                                                className={classes.textField}
                                                value={values.job}
                                                margin="normal"
                                                classes={{
                                                    root: classes.inputRoot,
                                                    input: classes.inputInput
                                                }}
                                                inputProps={{ "aria-label": "search", autocomplete: "off" }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {context !== "view" && <IconButton onClick={() => handleOpenDialog("JOB")}>
                                                                <Edit />
                                                            </IconButton>}
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={4}>

                                            <TextField
                                                disabled
                                                id="client3"
                                                variant="outlined"
                                                label={t("HR.contract")}
                                                name="responsible"
                                                className={classes.textField}
                                                value={values.contractFileName}
                                                margin="normal"
                                                classes={{
                                                    root: classes.inputRoot,
                                                    input: classes.inputInput
                                                }}
                                                inputProps={{ "aria-label": "search", autocomplete: "off" }}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton disabled={!values.contractFileName} onClick={() => onClickDownload(values.contractFilePath, newContract)}>
                                                                <Download />
                                                            </IconButton>
                                                            {context !== "view" && <IconButton onClick={() => handleOpenDialog("CONTRACT")}>
                                                                <Edit />
                                                            </IconButton>}
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Grid>
                                        <Box width="100%" />
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                disabled
                                                id="client4"
                                                variant="outlined"
                                                label={t("HR.remuneration")}
                                                name="responsible"
                                                className={classes.textField}
                                                value={values.salary}
                                                margin="normal"
                                                classes={{
                                                    root: classes.inputRoot,
                                                    input: classes.inputInput
                                                }}
                                                inputProps={{ "aria-label": "search", autocomplete: "off" }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {context !== "view" && <IconButton onClick={() => handleOpenDialog("REMUNERATION")}>
                                                                <Edit />
                                                            </IconButton>}
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Grid>

                                        <Box width="100%" />
                                    </Grid>
                                }

                                {
                                    admission.length === 0 ? null : dates.demissionDate ?

                                        <React.Fragment>
                                            <Typography className={classes.headerColor}><b>{t("HR.dismissalData")}</b></Typography>
                                            <Grid container item spacing={2} md={12} lg={12} style={{ paddingRight: "50px" }}>

                                                <Grid item xs={12} sm={4}>

                                                    <TextField
                                                        disabled
                                                        id="client2"
                                                        variant="outlined"
                                                        label={t("HR.dismissalDate")}
                                                        name="responsible"
                                                        className={classes.textField}
                                                        value={dateToBR(new Date(dates.demissionDate))}
                                                        margin="normal"
                                                        classes={{
                                                            root: classes.inputRoot,
                                                            input: classes.inputInput
                                                        }}
                                                        inputProps={{ "aria-label": "search", autocomplete: 'off' }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    {context !== "view" && <IconButton onClick={() => handleOpenDialog("DEMISSION_DATE")}>
                                                                        <Edit />
                                                                    </IconButton>}
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </Grid>


                                                <Grid item xs={12} sm={4}>
                                                    <TextField
                                                        disabled
                                                        id="client1"
                                                        variant="outlined"
                                                        label={t("HR.dismissalExam")}
                                                        name="responsible"
                                                        autoComplete="off"
                                                        className={classes.textField}
                                                        value={values.demissionExamFileName}
                                                        margin="normal"
                                                        InputLabelProps={{ shrink: true }}
                                                        inputProps={{ autocomplete: 'off' }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton disabled={!values.demissionExamFileName} onClick={() => onClickDownload(values.demissionExamFilePath, newDemissionExam)}>
                                                                        <Download />
                                                                    </IconButton>
                                                                    {context !== "view" && <IconButton onClick={() => handleOpenDialog("DEMISSION_EXAM")}>
                                                                        <Edit />
                                                                    </IconButton>}
                                                                </InputAdornment>
                                                            ),
                                                            readOnly: true,
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={4}>

                                                    <TextField
                                                        disabled
                                                        id="client1"
                                                        variant="outlined"
                                                        label={t("HR.craft")}

                                                        name="responsible"
                                                        autoComplete="off"
                                                        className={classes.textField}
                                                        value={values.demissionCraftFileName}
                                                        margin="normal"
                                                        InputLabelProps={{ shrink: true }}
                                                        inputProps={{ autocomplete: 'off' }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton disabled={!values.demissionCraftFileName} onClick={() => onClickDownload(values.demissionCraftFilePath, newCraft)}>
                                                                        <Download />
                                                                    </IconButton>
                                                                    {context !== "view" && <IconButton onClick={() => handleOpenDialog("CRAFT")}>
                                                                        <Edit />
                                                                    </IconButton>}
                                                                </InputAdornment>
                                                            ),
                                                            readOnly: true,
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={12}>

                                                    <Input
                                                        context={context}
                                                        label={"GENERAL.justification"}
                                                        value={values.demissionJustification}
                                                        valueName={"demissionJustification"}
                                                        rows={5}
                                                        maxLength={200}
                                                        inputProps={{ autocomplete: 'off' }}
                                                        handleChange={handleChange}
                                                    />
                                                </Grid>

                                            </Grid>
                                        </React.Fragment>


                                        :
                                        <React.Fragment>
                                            <Typography className={classes.headerColor}><b>{t("HR.dismissalData")}</b></Typography>
                                            <Grid container item spacing={2} md={12} lg={12} style={{ paddingRight: "50px" }}>
                                                <MessagePaper
                                                    headerText={t("HR.noInformationFireData")}
                                                    subText={t("HR.toAddInformationFireData")}
                                                />
                                            </Grid>
                                        </React.Fragment>

                                }


                                <Paper style={{ marginRight: "50px", marginTop: "30px" }}>
                                    <div className="cabecalho">
                                        {t("HR.history").toUpperCase()}
                                    </div>

                                    <AdmissionTable
                                        rows={values.history}
                                        toFirstPage={canClick}
                                        headers={["id", "date", "type", "user", "admissionDate", "admissionExamFilePath", "admissionExamFilePathName", "company",
                                            "contractFilePath", "contractFilePathName", "demissionCraftFilePath", "demissionCraftFilePathName", "demissionDate", "demissionExamFilePath",
                                            "demissionExamFilePathName", "holidayDate", "job", "justification", "newValue", "oldValue", "ruleset", "salary"
                                        ]}
                                        visibleHeaders={["Data", "Operação", "Usuário", ""]}
                                        sortedFields={["date", "type", "user"]}
                                        mainField="date"
                                        noHistoric={admission.length === 0}
                                        jobsArray={jobsArray}

                                    />

                                </Paper>

                                <DialogAdmitted
                                    open={openDialogAdmitted}
                                    setOpen={setOpenDialogAdmitted}
                                    companiesArray={companiesArray}
                                    rulesetsArray={rulesetsArray}
                                    jobsArray={jobsArray}
                                    fetchData={fetchData}
                                    viewValues={{ pis: informationIdentification.pis }}
                                    userId={userId}
                                    authId={auth.id}
                                />
                                <DialogDemission
                                    open={openDialogDemission}
                                    setOpen={setOpenDialogDemission}
                                    userId={userId}
                                    admission={admission[idxToContractActive]}
                                    fetchData={fetchData}
                                    authId={auth.id}
                                />

                                <DialogPosition
                                    open={openDialogPosition}
                                    setOpen={setOpenDialogPosition}
                                    oldJob={values.oldJob}
                                    jobsArray={jobsArray}
                                    jobId={values.jobId}
                                    userId={userId}
                                    setParentValues={setValues}
                                    setHasChange={setHasChange}
                                />

                                <DialogSalary
                                    open={openDialogSalary}
                                    setOpen={setOpenDialogSalary}
                                    salary={values.salary}
                                    oldSalary={values.oldSalary}
                                    userId={userId}
                                    setParentValues={setValues}
                                    setHasChange={setHasChange}
                                />

                                <DialogAdmissionDate
                                    open={openDialogAdmissionDate}
                                    setOpen={setOpenDialogAdmissionDate}
                                    admissionDate={dates.admissionDate}
                                    oldAdmissionDate={dates.oldAdmissionDate}
                                    userId={userId}
                                    setParentValues={setDates}
                                    setHasChange={setHasChange}
                                />
                                <DialogDemissionDate
                                    open={openDialogDemissionDate}
                                    setOpen={setOpenDialogDemissionDate}
                                    demissionDate={dates.demissionDate}
                                    oldDemissionDate={dates.oldDemissionDate}
                                    userId={userId}
                                    setParentValues={setDates}
                                    setHasChange={setHasChange}
                                />
                                <DialogDateVacation
                                    open={openDialogHolidayDate}
                                    setOpen={setOpenDialogHolidayDate}
                                    holidayDate={dates.holidayDate}
                                    oldHolidayDate={dates.oldHolidayDate}
                                    userId={userId}
                                    rulesetId={values.rulesetId}
                                    setParentValues={setDates}
                                    setHasChange={setHasChange}
                                />

                                <DialogContract
                                    open={openDialogContract}
                                    setOpen={setOpenDialogContract}
                                    contractName={values.contractFileName}
                                    oldContractName={values.contractOldFileName}
                                    oldContractPath={values.contractFilePath}
                                    userId={userId}
                                    setParentValues={setValues}
                                    newContract={newContract}
                                    setNewContract={setNewContract}
                                    justification={values.contractFilePathJustification}
                                    setHasChange={setHasChange}
                                />

                                <DialogAdmissionExam
                                    open={openDialogAdmissionExam}
                                    setOpen={setOpenDialogAdmissionExam}
                                    contractName={values.admissionExamFileName}
                                    oldContractName={values.oldAdmissionExamFileName}
                                    oldContractPath={values.admissionExamFilePath}
                                    userId={userId}
                                    setParentValues={setValues}
                                    newContract={newAdmissionExam}
                                    setNewContract={setNewAdmissionExam}
                                    justification={values.admissionExamFilePathJustification}
                                    setHasChange={setHasChange}
                                />

                                <DialogDemissionExam
                                    open={openDialogDemissionExam}
                                    setOpen={setOpenDialogDemissionExam}
                                    contractName={values.demissionExamFileName}
                                    oldContractName={values.oldDemissionExamFileName}
                                    oldContractPath={values.demissionExamFilePath}
                                    userId={userId}
                                    setParentValues={setValues}
                                    newContract={newDemissionExam}
                                    setNewContract={setNewDemissionExam}
                                    justification={values.demissionExamFilePathJustification}
                                    setHasChange={setHasChange}
                                />

                                <DialogCraft
                                    open={openDialogCraft}
                                    setOpen={setOpenDialogCraft}
                                    contractName={values.demissionCraftFileName}
                                    oldContractName={values.oldDemissionCraftFileName}
                                    oldContractPath={values.demissionCraftFilePath}
                                    userId={userId}
                                    setParentValues={setValues}
                                    newContract={newCraft}
                                    setNewContract={setNewCraft}
                                    justification={values.demissionCraftFilePathJustification}
                                    setHasChange={setHasChange}
                                />

                                <DialogCancelOperation
                                    open={openCancelDialog}
                                    setOpen={setOpenCancelDialog}
                                    path={"/Collaborators"}
                                    dialogTitle={t("GENERAL.cancelEdit")}
                                    dialogText={t("GENERAL.cancelEditText")}
                                />

                            </div>
                            {context !== "view" ? <div style={{ paddingLeft: "10px", paddingBottom: "40px", paddingTop: "40px" }}>
                                <Buttons
                                    onClick={handleCancel}
                                    tipo="BN-noback"
                                    conteudo={t("GENERAL.cancel")}
                                />
                                <Buttons
                                    onClick={handleApply}
                                    tipo="BN-blue"
                                    conteudo={t("GENERAL.apply")}
                                />
                            </div> :
                                <Box width="100%" style={{ marginBottom: "2rem" }} />
                            }
                        </React.Fragment>
                    }
                </Paper>
            </Transition>
        </React.Fragment >
    );
};

export default AdmissionForm;