import i18n from 'i18n';

export const SystemStatus = {
    ACTIVE: true,
    INACTIVE: false
};

export const moduleProfile = {
    YES: true,
    NO: false
};

export const BillStatus = {
    PAID: 'PAID',
    LATE: 'LATE',
    PENDING: 'PENDING',
    CANCELLED: 'CANCELLED',
    NO_BILL: 'NO_BILL'
};

export const SystemStatusArray = [
    {
        value: 'ALL',
        label: i18n.t('GENERAL.allMasculine')
    },
    {
        value: SystemStatus.ACTIVE,
        label: i18n.t('GENERAL.active')
    },
    {
        value: SystemStatus.INACTIVE,
        label: i18n.t('GENERAL.inactive')
    }
];

export const ModuleProfileArray = [
    {
        value: 'ALL',
        label: i18n.t('GENERAL.allMasculine')
    },
    {
        value: moduleProfile.YES,
        label: i18n.t('GENERAL.active')
    },
    {
        value: moduleProfile.NO,
        label: i18n.t('GENERAL.inactive')
    }
];

export const BillsStatusArray = [
    {
        value: 'ALL',
        label: i18n.t('GENERAL.allMasculine')
    },
    {
        value: BillStatus.LATE,
        label: i18n.t('GENERAL.lateF')
    },
    {
        value: BillStatus.CANCELLED,
        label: i18n.t('QUOTAS.cancelledBill')
    },
    {
        value: BillStatus.PAID,
        label: i18n.t('GENERAL.paidF')
    },
    {
        value: BillStatus.PENDING,
        label: i18n.t('GENERAL.pending')
    },
    {
        value: BillStatus.NO_BILL,
        label: i18n.t('QUOTAS.noBill')
    }
];

export function translateSystemStatus(status) {
    switch (status) {
        case SystemStatus.ACTIVE:
            return i18n.t('GENERAL.active');
        case SystemStatus.INACTIVE:
            return i18n.t('GENERAL.inactive');
        default:
            return '';
    }
}

export function translateModuleProfile(status) {
    switch (status) {
        case moduleProfile.YES:
            return i18n.t('GENERAL.active');
        case moduleProfile.NO:
            return i18n.t('GENERAL.inactive');
        default:
            return '';
    }
}

export function translateBillStatus(status) {
    switch (status) {
        case BillStatus.PAID:
            return i18n.t('GENERAL.paidF');
        case BillStatus.LATE:
            return i18n.t('GENERAL.lateF');
        case BillStatus.CANCELLED:
            return i18n.t('QUOTAS.cancelledBill');
        case BillStatus.PENDING:
            return i18n.t('GENERAL.pending');
        case BillStatus.NO_BILL:
            return i18n.t('QUOTAS.noBill');
        default:
            return '';
    }
}
