/* eslint-disable */
import Breadcrumb from 'components/layout/breadcrumb/Breadcrumb';
import Transition from 'components/layout/transition/Transition';
import FilterContainer from 'components/containers/FilterContainer';
import HeaderContainer from 'components/containers/HeaderContainer';
import ContentContainer from 'components/containers/ContentContainer';
import InputFilter from 'components/inputs/InputFilter';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import GetApp from '@material-ui/icons/GetApp';
import Dissatisfied from '@material-ui/icons/SentimentVeryDissatisfied';
import Footer from 'components/layout/Footer';
import InventoryContext from 'context/inventory/inventoryContext';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import AdvancedSearchDialog from './AdvancedSearchDialog';
import BooksTable from './BooksTable';
import { Link } from 'react-router-dom';
import DialogExport from './DialogExport';
import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';
import { Screen, Allow } from 'global/constants';
import { PadToNDigits } from 'utils/general/PadToNDigits';
import { LibraryExemplarStatusValues } from 'global/constants';
import { AllAuthorsName } from 'utils/library/AllAuthorsName';
import { translateStatusExemplar } from 'utils/library/translateStatus';

const Books = () => {
    const classes = useStyles();
    const [t] = useTranslation();
    const inventoryContext = useContext(InventoryContext);
    const [openAdvancedSearch, setOpenAdvancedSearch] = useState(false);
    const [openExport, setOpenExport] = useState(false);
    const [categoryArray, setCategoryArray] = useState([]);
    const [loading, setLoading] = useState(true);

    const statusArray = [
        { label: t('GENERAL.allMasculine'), value: 'ALL' },
        {
            label: t('GENERAL.disponible'),
            value: LibraryExemplarStatusValues.DISPONIBLE
        },
        {
            label: t('LIBRARY.donated'),
            value: LibraryExemplarStatusValues.DONATED
        },
        {
            label: t('GENERAL.indisponible'),
            value: LibraryExemplarStatusValues.INDISPONIBLE
        },
        {
            label: t('LIBRARY.LOAN'),
            value: LibraryExemplarStatusValues.LOANED
        },
        {
            label: t('LIBRARY.inLoanProcess'),
            value: LibraryExemplarStatusValues.IN_LOAN_PROCESS
        }
    ];

    const [findText, setFindText] = useState('');
    const [filterTitle, setFilterTitle] = useState('');
    const [filterCategory, setFilterCategory] = useState('');
    const [filterStatus, setFilterStatus] = useState('');
    const [filterIsbd, setFilterIsbd] = useState('');
    const [filterAuthor, setFilterAuthor] = useState('');
    const [filterExemplarCode, setFilterExemplarCode] = useState('');

    const [canClick, setCanClick] = useState(false);

    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const { getBookCategories, bookCategories, getBooks, books } =
        inventoryContext;

    const [dataFilter, setDataFilter] = useState([]);

    const hasItens = books.length > 0;
    const [hasNoResult, setHasNoResult] = useState(
        hasItens && dataFilter.length === 0
    );

    const setCategoryFilterArray = categories => {
        var auxList = [];
        auxList.push({ label: 'Todos', value: 'ALL' });

        categories.forEach(item => {
            const auxObj = { label: item.name, value: item.name };

            if (!item.deleted) {
                auxList.push(auxObj);
            }
        });

        return auxList;
    };

    async function fetchBooks() {
        await getBooks();
        await getBookCategories();
    }

    useEffect(() => {
        fetchBooks();
    }, []);

    useEffect(() => {
        if (bookCategories && books && bookCategories.length && books.length) {
            setDataFilter(books);
            setHasNoResult(false);
            setCategoryArray(setCategoryFilterArray(bookCategories));
            setLoading(false);
        }
    }, [books, bookCategories]);

    const advancedSearch = (title, category, code, isbn, status, author) => {
        setFilterTitle(title);
        setFilterExemplarCode(code);
        setFilterCategory(category);
        setFilterStatus(status);
        setFilterIsbd(isbn);
        setFilterAuthor(author);
    };

    useEffect(() => {
        let newDataFilter = books;
        let textNoFound = '';

        if (filterTitle) {
            newDataFilter = newDataFilter.filter(item =>
                item.title.toLowerCase().includes(filterTitle.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterTitle;
        }

        if (filterExemplarCode) {
            let filter = filterExemplarCode.toLocaleUpperCase();
            var filterExemplarCodeReplace = filterExemplarCode.replace(
                /[^0-9]/g,
                ''
            );
            if (
                !(
                    filter === 'B' ||
                    filter === 'BI' ||
                    filter === 'BIB' ||
                    filter === 'BIB-'
                )
            ) {
                newDataFilter = newDataFilter.filter(book =>
                    book.exemplars.some(
                        exemplar =>
                            exemplar.code &&
                            PadToNDigits(exemplar.code.toString(), 4).includes(
                                filterExemplarCodeReplace
                            )
                    )
                );
                newDataFilter = newDataFilter.map(book => ({
                    ...book,
                    exemplars: book.exemplars.filter(
                        exemplar =>
                            exemplar.code &&
                            PadToNDigits(exemplar.code.toString(), 4).includes(
                                filterExemplarCodeReplace
                            )
                    )
                }));
            }

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterExemplarCode;
        }

        if (filterCategory && filterCategory !== 'ALL') {
            newDataFilter = newDataFilter.filter(
                item =>
                    item.category.name.toLowerCase() ===
                    filterCategory.toLowerCase()
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterCategory;
        }

        if (filterStatus && filterStatus !== 'ALL') {
            newDataFilter = newDataFilter.filter(book =>
                book.exemplars.some(
                    exemplar =>
                        exemplar.status && exemplar.status === filterStatus
                )
            );

            newDataFilter = newDataFilter.map(book => ({
                ...book,
                exemplars: book.exemplars.filter(
                    exemplar =>
                        exemplar.status && exemplar.status === filterStatus
                )
            }));

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = translateStatusExemplar(filterStatus);
        }

        if (filterAuthor) {
            newDataFilter = newDataFilter.filter(item =>
                AllAuthorsName(item.authors)
                    .toLowerCase()
                    .includes(filterAuthor.toLowerCase())
            );
            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterAuthor;
        }

        if (filterIsbd) {
            newDataFilter = newDataFilter.filter(
                item =>
                    item.isbn &&
                    item.isbn.toLowerCase().includes(filterIsbd.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterIsbd;
        }

        if (newDataFilter.length === 0) {
            setHasNoResult(true);
            setFindText(textNoFound);
        } else {
            setHasNoResult(false);
            setFindText('');
        }

        setDataFilter(newDataFilter);
    }, [
        filterExemplarCode,
        filterTitle,
        filterCategory,
        filterStatus,
        filterAuthor,
        filterIsbd,
        books
    ]);

    useEffect(() => {
        if (
            filterExemplarCode ||
            filterTitle ||
            filterCategory ||
            filterStatus ||
            filterAuthor ||
            filterIsbd
        ) {
            setCanClick(true);
        } else {
            setCanClick(false);
        }
    }, [
        filterExemplarCode,
        filterTitle,
        filterCategory,
        filterStatus,
        filterAuthor,
        filterIsbd
    ]);

    return (
        <React.Fragment>
            <Transition loading={loading} newDesign={true}>
                <HeaderContainer>
                    <Breadcrumb
                        newDesign={true}
                        routes={[
                            {
                                label: t('INVENTORY.inventory'),
                                to: '/Patrimony'
                            },
                            { label: t('INVENTORY.books') }
                        ]}
                    />
                    <div className="container-headerNewDesign">
                        <h1 className={classes.titleNewDesign}>
                            {t('INVENTORY.books')}
                        </h1>
                        {GetGeneralPermissionForAction(
                            Screen.BOOKS,
                            Allow.CREATE
                        ) && (
                            <Link to={'/Books/new'}>
                                <Button
                                    className={classes.blueButton}
                                    variant="contained"
                                    color="primary"
                                    style={{ marginBottom: '10px' }}
                                >
                                    {t('INVENTORY.addBook')}
                                </Button>
                            </Link>
                        )}
                    </div>
                </HeaderContainer>
                <ContentContainer>
                    <Paper>
                        <div className="cabecalho">
                            {t('INVENTORY.titleAndExamplary').toUpperCase()}
                            <span style={{ float: 'right', marginRight: 10 }}>
                                <span
                                    style={{ float: 'right', marginRight: 10 }}
                                >
                                    {t('INVENTORY.export').toUpperCase()}
                                    <IconButton
                                        className="btn"
                                        style={{ float: 'right' }}
                                        edge="end"
                                        color="inherit"
                                        onClick={() => setOpenExport(true)}
                                    >
                                        <GetApp />
                                    </IconButton>
                                </span>
                            </span>
                        </div>
                        <FilterContainer>
                            <InputFilter
                                filterValue={filterTitle}
                                setFilter={setFilterTitle}
                                label={'INVENTORY.title'}
                                maxLength={70}
                            />
                            <InputFilter
                                filterValue={filterExemplarCode}
                                setFilter={setFilterExemplarCode}
                                label={'INVENTORY.copyCode'}
                                maxLength={70}
                            />
                            <InputFilter
                                filterValue={filterCategory}
                                setFilter={setFilterCategory}
                                label={'INVENTORY.category'}
                                isSelect
                                arraySelected={categoryArray}
                            />
                            <InputFilter
                                filterValue={filterStatus}
                                setFilter={setFilterStatus}
                                label={'INVENTORY.statusBook'}
                                isSelect
                                arraySelected={statusArray}
                            />
                            <Button onClick={() => setOpenAdvancedSearch(true)}>
                                {t('INVENTORY.advancedSearch')}
                            </Button>
                        </FilterContainer>
                        <React.Fragment>
                            <BooksTable
                                rows={dataFilter}
                                toFirstPage={canClick}
                                headers={[
                                    'id',
                                    'title',
                                    'authors',
                                    'category',
                                    'libraryExemplar',
                                    'isbn',
                                    'exemplars'
                                ]}
                                visibleHeaders={[
                                    ' ',
                                    'Título',
                                    'Autor',
                                    'Categoria',
                                    'Exemplares',
                                    ' '
                                ]}
                                sortedFields={[
                                    ' ',
                                    'title',
                                    'authors',
                                    'category',
                                    'exemplaryCount'
                                ]}
                                mainField="title"
                                // permission={permission}
                            />

                            {!hasItens && (
                                <div className={classes.noParticipantsMessage}>
                                    <h2>{t('INVENTORY.noItensMessage')}</h2>
                                    <h4>{t('INVENTORY.noItensSubMessage')}</h4>
                                </div>
                            )}
                            {hasNoResult && (
                                <div className={classes.noParticipantsMessage}>
                                    <div>
                                        <Dissatisfied
                                            className={
                                                classes.notFoundIconStyle
                                            }
                                        />
                                    </div>
                                    <h2>{t('GENERAL.noResultsFound')}</h2>
                                    <h5>{`Sua busca por "${findText}" não retornou resultados.`}</h5>
                                </div>
                            )}
                        </React.Fragment>
                    </Paper>
                </ContentContainer>
                <Footer />
                <DialogExport
                    title={'Exportar Livros'}
                    maxFieldsColumn={4}
                    open={openExport}
                    setOpen={setOpenExport}
                    data={dataFilter}
                />
                {openAdvancedSearch && (
                    <AdvancedSearchDialog
                        open={openAdvancedSearch}
                        setOpen={setOpenAdvancedSearch}
                        apply={advancedSearch}
                        categoryArray={categoryArray}
                        statusArray={statusArray}
                        values={{
                            title: filterTitle,
                            category: filterCategory,
                            status: filterStatus,
                            isbn: filterIsbd,
                            author: filterAuthor,
                            code: filterExemplarCode
                        }}
                    />
                )}
            </Transition>
        </React.Fragment>
    );
};

export default Books;
