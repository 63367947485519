import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import Visibility from '@material-ui/icons/Visibility';
import axios from 'axios';
import ExternalUserContext from 'context/externalUsers/externalUsersContext';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import history from '../../history';
import Buttons from '../buttons/Pressed';
import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';
import { Screen, Allow } from 'global/constants';
import Cookies from 'js-cookie';

const useStyles = makeStyles(theme => ({
    icons: {
        opacity: '0'
    },
    root: {
        flexGrow: 1
    },
    iconReq: {
        marginLeft: '10px',
        opacity: '0',
        backgroundColor: '#6D6F71',
        color: '#FFFFFF;'
    },
    Title: {
        fontFamily: 'Roboto',
        fontSize: '30px',
        textAlign: 'left',
        color: '#000000',
        fontWeight: 'bold'
    },
    image: {
        width: 180,
        height: 180
    },
    img: {
        margin: 'auto',
        maxWidth: '100%',
        maxHeight: '100%'
    },
    backgroundImage: {},
    title: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '16px',
        color: '#000000',
        opacity: '0.54'
    },
    codeId: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '16px',
        color: '##6D6F71'
    },
    btn: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '16px',
        color: '#2D9CDB'
    },
    textField: {
        display: 'flex'
    },
    textFieldCustum: {
        display: 'flex',
        marginLeft: '10px'
    }
}));

const ExternalUsersTableActions = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const externalUserContext = useContext(ExternalUserContext);
    const { deleteExternalUser, response, responseType } = externalUserContext;
    const [open, setOpen] = useState(false);
    const { item, permission } = props;

    let formData = new FormData();
    formData.append('external.id', item.id);
    formData.append('external.name', item.name ? item.name : '');
    formData.append('external.phoneNumber', item.phone ? item.phone : '');
    formData.append('external.email', item.email ? item.email : '');
    // formData.append('external.placesToDelete', toDeletePlaceId);
    formData.append('external.imagePath', item.imagePath ? item.imagePath : '');
    formData.append(
        'external.description',
        item.description ? item.description : ''
    );
    formData.append('external.hasDeleted', true);

    const handleDelete = () => {
        deleteExternalUser(item.id, formData);
        setOpen(false);
    };

    useEffect(() => {
        return () => {};
    }, [response, responseType]);

    const verifyEdit = () => {
        axios
            .get(
                process.env.REACT_APP_SERVER + `api/ExternalUsers/${item.id}`,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(res => {
                if (res.statusText === 'OK') {
                    history.push(`/ExternalUsers/${item.id}/edit`);
                } else {
                    // deletePartner(item.id);
                }
            })
            .catch(error => {
                // deletePartner(item.id);
            });
    };

    const verifyView = () => {
        history.push(`/ExternalUsers/${item.id}`);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            {GetGeneralPermissionForAction(
                Screen.EXTERNAL_COLLABORATORS,
                Allow.READ
            ) && (
                <Tooltip
                    title={t('GENERAL.view')}
                    aria-label="details"
                    placement="top"
                >
                    <IconButton className={classes.icons} onClick={verifyView}>
                        <Visibility />
                    </IconButton>
                </Tooltip>
            )}
            {GetGeneralPermissionForAction(
                Screen.EXTERNAL_COLLABORATORS,
                Allow.EDIT
            ) && (
                <Tooltip title="Editar" aria-label="edit" placement="top">
                    <IconButton className={classes.icons} onClick={verifyEdit}>
                        <Edit />
                    </IconButton>
                </Tooltip>
            )}
            {GetGeneralPermissionForAction(
                Screen.EXTERNAL_COLLABORATORS,
                Allow.DELETE
            ) && (
                <Tooltip
                    title={t('GENERAL.delete')}
                    aria-label="delete"
                    placement="top"
                >
                    <IconButton
                        className={classes.icons}
                        onClick={handleClickOpen}
                    >
                        <Delete />
                    </IconButton>
                </Tooltip>
            )}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                msg="mensagem"
            >
                <DialogTitle className={classes.Title}>
                    {t('GENERAL.delete')}
                    <IconButton
                        style={{
                            float: 'right',
                            marginTop: -10,
                            marginRight: -17
                        }}
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('GENERAL.deleteMessageM')} {t('PROFILES.profile')} "
                        {item.name}" ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Buttons
                        onClick={handleClose}
                        tipo="BN-noback"
                        conteudo={t('GENERAL.cancel').toUpperCase()}
                    />
                    <Buttons
                        onClick={handleDelete}
                        tipo="BN-blue"
                        conteudo={t('GENERAL.delete').toUpperCase()}
                    />
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default ExternalUsersTableActions;
