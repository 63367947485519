import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Edit from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  icons: {
    opacity: "0"
  },
  Title: {
    width: "103px",
    height: "26px",
    fontFamily: "Roboto",
    fontSize: "20px",
    lineHeight: "1.3",
    textAlign: "left",
    color: "#000000",
    fontWeight: "bold"
  }
}));

const Actions = props => {
  const classes = useStyles();
  const { item, permission } = props;
  useEffect(() => {});

  return (
    <div>
      {permission["allowRead"] &&
      <Link to={`/users/${item.id}`}>
        <Tooltip title="Visualizar" aria-label="details" placement="top">
          <IconButton className={classes.icons}>
            <Visibility />
          </IconButton>
        </Tooltip>
      </Link>
      }{permission["allowUpdate"] &&
      <Link to={`/users/${item.id}/edit`}>
        <Tooltip title="Editar" aria-label="edit" placement="top">
          <IconButton className={classes.icons}>
            <Edit />
          </IconButton>
        </Tooltip>
      </Link>}
    </div>
  );
};

export default Actions;
