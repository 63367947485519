
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Edit from "@material-ui/icons/Edit";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import history from "../../../../history";
import { useStyles } from "components/layout/CommonStyles";
import { GetGeneralPermissionForAction } from "utils/permissions/GetGeneralPermissionForAction";
import { Screen , Allow } from "global/constants";


const CollaboratorsVacationsTableAction = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const { item } = props;

    return (
        <React.Fragment>
            {GetGeneralPermissionForAction(Screen.COLLABORATORS_VACATIONS, Allow.EDIT) &&
            <Link to={`/VacationCalendar/${item.id}/edit`}>
                <Tooltip title={t("GENERAL.edit")} aria-label="edit" placement="top">
                    <IconButton className={classes.iconsTableActions}  >
                        <Edit />
                    </IconButton>
                </Tooltip>
            </Link>
            }
        </React.Fragment >
    )

}

export default CollaboratorsVacationsTableAction;