import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation, Trans } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import Buttons from 'components/buttons/Pressed';
import Typography from 'components/typography/Typography';
import Input from 'components/inputs/Input';
import { useMutationVerifyPassword } from 'hooks/DataFetching/Auth/useMutationVerifyPassword';
import { useEffect } from 'react';

const DialogPasswordConfirm = props => {
    const [t] = useTranslation();

    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const {
        setOpen,
        handleSubmit,
        title,
        text,
        buttonText,
        closeButtonText,
        mutation,
        trans,
        transValues
    } = props;

    const [requiredPassword, setRequiredPassword] = useState(false);
    const [invalidPassword, setInvalidPassword] = useState(false);

    const mutationVerifyPassword = useMutationVerifyPassword(
        () => {
            handleSubmit(mutation);
        },
        setOpen,
        setInvalidPassword
    );

    const handleClose = () => {
        setOpen(false);
    };

    const [values, setValues] = useState({
        password: ''
    });

    const handleChange = (name, value) => {
        let val = value;
        setValues({ ...values, [name]: val });
    };

    const verifySubmit = () => {
        let res = true;
        setRequiredPassword(false);
        setInvalidPassword(false);

        if (!values.password.trim()) {
            res = false;
            setRequiredPassword(true);
        }

        return res;
    };

    const handleSubmitPassword = async () => {
        const canSubmit = verifySubmit();
        if (canSubmit) {
            let formData = new FormData();

            formData.append('userId', auth.id);
            formData.append('password', values.password);
            mutationVerifyPassword.mutate(formData);
        }
    };

    useEffect(() => {
        setInvalidPassword(false);
    }, [values.password]);

    return (
        <>
            <DialogTitle>
                <Grid container item spacing={2} xs={12}>
                    <Grid item xs={11}>
                        <Typography
                            variant={'RobotoBold'}
                            fontSize={24}
                            textColor={'secondary'}
                        >
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            color="#6D6F71"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent>
                <Grid
                    container
                    spacing={1}
                    xs={12}
                    style={{ margin: '0 auto' }}
                >
                    <Grid style={{ margin: '0 auto' }} item xs={11}>
                        <Typography
                            variant={'RobotoRegular'}
                            fontSize={16}
                            textColor={'secondary'}
                        >
                            {trans ? (
                                <Trans
                                    i18nKey={trans}
                                    values={{
                                        ...transValues
                                    }}
                                    components={[<b />]}
                                />
                            ) : (
                                text
                            )}
                        </Typography>
                    </Grid>

                    <Grid style={{ margin: '0 auto' }} item xs={11}>
                        <Input
                            required
                            fieldType={'password'}
                            label={'INVENTORY.password'}
                            value={values.password}
                            valueName={'password'}
                            maxLength={50}
                            handleChange={handleChange}
                            error={requiredPassword || invalidPassword}
                            helperText={
                                requiredPassword
                                    ? t('GENERAL.requiredField')
                                    : invalidPassword
                                    ? t('GENERAL.invalidPassword')
                                    : ''
                            }
                            setRequired={setRequiredPassword}
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions style={{ padding: '24px 30px' }}>
                <Buttons
                    onClick={handleClose}
                    tipo={'BN-noback'}
                    conteudo={
                        closeButtonText
                            ? closeButtonText
                            : t('GENERAL.close').toUpperCase()
                    }
                />
                <Buttons
                    isLoading={
                        mutation.isLoading || mutationVerifyPassword.isLoading
                    }
                    onClick={handleSubmitPassword}
                    tipo="BN-blue"
                    conteudo={buttonText}
                />
            </DialogActions>
        </>
    );
};

export default DialogPasswordConfirm;
