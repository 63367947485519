import axios from "axios";
import React, { useEffect, useReducer } from "react";
// import { useTranslation } from "react-i18next";
import { ALLOWANCE, DEFAULT } from "../types";
import AllowanceContext from "./allowanceContext";
import allowanceReducer from "./allowanceReducer";
import Cookies from "js-cookie";

const AllowanceState = (props) => {
  const initialState = {
    allowances: {},
    allowanceResponse: "",
    allowanceResponseType: "",
    formattedAllowances: [],
    standardAuthorizationOptions: {},
    currentLoggedProfileId: null,
    messageHasChanged: false,
    messageSuccessiveActions: 0,
    hasChangedAllowances: true,
    permission: {},
    allAllowances: [],
  };

  useEffect(() => {
    return () => {};
  }, []);

  // const [t] = useTranslation();
  const [state, dispatch] = useReducer(allowanceReducer, initialState);

  const setLoading = () => dispatch({ type: DEFAULT.SET_LOADING });
  const resetAllowanceMessage = () => {
    dispatch({ type: DEFAULT.RESET_ALLOWANCE_MESSAGE });
  };
  const setAllowanceMessage = (_response, _responseType) => {
    setLoading();
    dispatch({
      type: DEFAULT.SET_ALLOWANCE_MESSAGE,
      payload: _response,
      allowanceResponseType: _responseType,
      messageHasChanged: true,
    });
  };

  const getStandardAuthorizationOptions = () => {
    axios
      .get(process.env.REACT_APP_SERVER + "api/StandardAuthorizationOption", {
        headers: {
            "Content-type": "application/json",
            "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
        }
    })
      .then(res => {
        state.standardAuthorizationOptions = res.data;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const selectStandardAuthorizationOption = (id) => {
    for (var option in state.standardAuthorizationOptions) {
      if (state.standardAuthorizationOptions[option].id === id) {
        return state.standardAuthorizationOptions[option];
      }
    }
    return null;
  };

  const getCurrentLoggedProfile = (
    shouldUpdateAllowances,
    currentAllowances
  ) => {
    if (shouldUpdateAllowances) {
      getStandardAuthorizationOptions();
      let auth = {};
      if (localStorage.getItem("auth-token") !== null) {
        auth = JSON.parse(localStorage.getItem("auth-token"));
        state.currentLoggedProfileId = auth.profileId;
      }
      // if current logged user has a profile id
      if (state.currentLoggedProfileId) {
        getAllowances(state.currentLoggedProfileId);
      }
    } else {
      dispatch({
        type: ALLOWANCE.GET_ALLOWANCES,
        payload: currentAllowances,
        hasChangedAllowances: false,
      });
    }
  };

  const beautifyAllowances = (allawances) => {
    let formattedAllawance = {};
    let formattedAllawances = [];
    let sao = {};
    if (Object.keys(state.standardAuthorizationOptions).length > 0) {
      for (var allawance in allawances) {
        sao = selectStandardAuthorizationOption(
          allawances[allawance].standardauthorizationoptionId
        );
        if (sao) {
          formattedAllawance.resource = allawances[allawance].resource;
          formattedAllawance.allowRead = sao.allowRead;
          formattedAllawance.allowCreate = sao.allowCreate;
          formattedAllawance.allowUpdate = sao.allowUpdate;
          formattedAllawance.allowDelete = sao.allowDelete;
          formattedAllawances.push(formattedAllawance);
        }
        formattedAllawance = {};
      }
      state.formattedAllowances = formattedAllawances;
    }
  };

  const getAllowances = (id) => {
    setLoading();
    axios
      .get(process.env.REACT_APP_SERVER + `api/Profiles/${id}`, {
        headers: {
            "Content-type": "application/json",
            "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
        }
    })
      .then(res => {
        dispatch({
          type: ALLOWANCE.GET_ALLOWANCES,
          payload: res.data.sgppdPermissionItem,
          hasChangedAllowances: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const verifyPermission = (scree) => {
    let auth = {};
    if (localStorage.getItem("auth-token") !== null) {
      auth = JSON.parse(localStorage.getItem("auth-token"));
      state.currentLoggedProfileId = auth.profileId;
    }
    // if current logged user has a profile id
    if (state.currentLoggedProfileId) {
      axios
        .get(process.env.REACT_APP_SERVER + `api/Profiles/${state.currentLoggedProfileId}`, {
          headers: {
              "Content-type": "application/json",
              "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
          }
      })
        .then(res => {
          for (var item in res.data.sgppdPermissionItem) {
            if (res.data.sgppdPermissionItem[item]["resource"] === scree) {
              dispatch({
                type: ALLOWANCE.GET_ALLOWANCES,
                payload:
                  res.data.sgppdPermissionItem[item][
                    "standardauthorizationoption"
                  ],
              });
              break;
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const verifyRoutes = (resource, allowance) => {
    if (state.permission) {
      if (state.permission[allowance]) {
        return true;
      } else return false;
    }
  };

  const getAllPermission = () => {
    let auth = {};
    if (localStorage.getItem("auth-token") !== null) {
      auth = JSON.parse(localStorage.getItem("auth-token"));
      state.currentLoggedProfileId = auth.profileId;
    }
    // if current logged user has a profile id
    if (state.currentLoggedProfileId) {
      axios
        .get(process.env.REACT_APP_SERVER + `api/Profiles/${state.currentLoggedProfileId}`, {
          headers: {
              "Content-type": "application/json",
              "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
          }
      })
        .then(res => {
          dispatch({
            type: ALLOWANCE.GET_ALLALLOWANCES,
            payload: res.data.sgppdPermissionItem,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const verifyRights = (permission) => {
    // admin has access to all pages and actions
    //getAllowances(profileId)
    // verifyPermission(resource);
    if (permission === "list") {
      return true;
    }
    if (state.permission[permission]) {
      return state.permission[permission];
    } else {
      return false;
    }
  };

  return (
    <AllowanceContext.Provider
      value={{
        formattedAllowances: state.formattedAllowances,
        hasChangedAllowances: state.hasChangedAllowances,
        allowanceResponse: state.allowanceResponse,
        allowanceResponseType: state.allowanceResponseType,
        messageSuccessiveActions: state.messageSuccessiveActions,
        messageHasChanged: state.messageHasChanged,
        permission: state.permission,
        allAllowances: state.allAllowances,
        resetAllowanceMessage,
        setAllowanceMessage,
        setLoading,
        getAllowances,
        getCurrentLoggedProfile,
        verifyRights,
        verifyPermission,
        getAllPermission,
        verifyRoutes,
      }}
    >
      {props.children}
    </AllowanceContext.Provider>
  );
};

export default AllowanceState;
