import { DEFAULT, LIBRARY } from '../../types';

export default (state, action) => {
    switch (action.type) {
        case DEFAULT.SET_LOADING:
            return {
                ...state,
                loading: true
            };
        case DEFAULT.SET_LOADING_FALSE:
            return {
                ...state,
                loading: false
            };
        case DEFAULT.RESET_MESSAGE:
            return {
                ...state,
                response: '',
                responseType: ''
            };
        case DEFAULT.SET_HAS_CHANGED:
            return {
                ...state,
                hasChanged: 0
            };
        case LIBRARY.GET_BOOKS:
            return {
                ...state,
                books: action.payload
            };
        case LIBRARY.GET_CATEGORIES:
            return {
                ...state,
                categories: action.payload
            };
        case LIBRARY.GET_EXEMPLARS:
            return {
                ...state,
                exemplars: action.payload
            };
        case LIBRARY.GET_RESERVATIONS_USER:
            return {
                ...state,
                reservations_user: action.payload
            };
        case LIBRARY.CREATE_REQUISITION:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case LIBRARY.CREATE_QUEUE_REQUISITION:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        default:
            return state;
    }
};
