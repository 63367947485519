import {convertMsToHM} from "utils/dates/ConvertMsToHM";

export const GetWorkedHoursRealTime = (entryTime, leaveForBreak, returnFromBreak, leaveTime) => {
    if (entryTime === null) {
        return "00:00";
    }

    let firstInterval = 0;
    let secondInterval = 0;
    let totalWorkedTime = 0;

    if (entryTime && leaveForBreak && returnFromBreak){
        firstInterval = Math.abs(leaveForBreak - entryTime);
        secondInterval = Math.abs(leaveTime - returnFromBreak)

        totalWorkedTime = firstInterval + secondInterval;
        return convertMsToHM(totalWorkedTime);
    }

    if (entryTime && leaveForBreak){
        firstInterval = Math.abs(leaveForBreak - entryTime);

        totalWorkedTime = firstInterval;
        return convertMsToHM(totalWorkedTime);
    }

    totalWorkedTime = Math.abs(leaveTime - entryTime);
    return convertMsToHM(totalWorkedTime);
}
