import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import Input from 'components/inputs/Input';
import InputFile from 'components/inputs/InputFile';
import Buttons from 'components/buttons/Pressed';
import Typography from 'components/typography/Typography';
import { moneyMaskToFloat } from 'utils/general/moneyMaskToFloat';
import { moneyMask } from 'utils/masks/moneyMask';
import { useMutationCreateCreditRequest } from 'hooks/DataFetching/Quotas/Dashboard/useMutationCreateCreditRequest';
import { screenStyles } from '../Styles';
import InputDate from 'components/inputs/InputDate';
import { dateValidation } from 'utils/validation/DateValidation';
import { FormatDateToDataBase } from 'utils/dates/FormatDateToDataBase';

const DialogApplyForCredit = props => {
    const styles = screenStyles();
    const [t] = useTranslation();

    const { open, setOpen } = props;

    const mutation = useMutationCreateCreditRequest(setOpen);

    const [requiredValue, setRequiredValue] = useState(false);
    const [requiredDate, setRequiredDate] = useState(false);
    const [dateErrorMessage, setDateErrorMessage] = useState('');

    const [file, setFile] = useState([]);

    const [values, setValues] = useState({
        value: '0,00',
        observation: '',
        date: new Date(new Date().setHours(0, 0, 0, 0))
    });

    const handleChange = (name, value) => {
        let val = value;
        if (name === 'value') {
            setValues({ ...values, [name]: moneyMask(val) });
        } else {
            setValues({ ...values, [name]: val });
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const verifySubmit = () => {
        let res = true;

        if (values.value === '0,00') {
            res = false;
            setRequiredValue(true);
        }

        if (!dateValidation(values.date)) {
            res = false;
            setRequiredDate(true);
            setDateErrorMessage(t('GENERAL.invalidDate'));
        }

        if (new Date(values.date) > new Date()) {
            res = false;
            setRequiredDate(true);
            setDateErrorMessage(t('GENERAL.dateInTheFutureError'));
        }

        if (new Date(values.date) < new Date(2013, 0, 1)) {
            res = false;
            setRequiredDate(true);
            setDateErrorMessage(t('QUOTAS.dateBeforeQuotasYear'));
        }

        return res;
    };

    const handleSubmit = () => {
        const canSubmit = verifySubmit();
        if (canSubmit) {
            let formData = new FormData();

            formData.append(
                'value',
                values.value ? moneyMaskToFloat(values.value) : ''
            );
            formData.append(
                'description',
                values.observation ? values.observation : ''
            );

            formData.append('receipt', file.length ? file[0] : null);
            formData.append(
                'date',
                values.date ? FormatDateToDataBase(values.date) : ''
            );

            mutation.mutate(formData);
        }
    };

    useEffect(() => {
        setRequiredDate(false);
    }, [values.date]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            maxWidth={'sm'}
            fullWidth
        >
            <DialogTitle>
                <Grid container item spacing={2} xs={12}>
                    <Grid item xs={11}>
                        <Typography
                            variant={'RobotoBold'}
                            fontSize={24}
                            textColor={'secondary'}
                        >
                            {t('QUOTAS.applyForCredit')}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            color="#6D6F71"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent>
                <Grid container item spacing={1} xs={12}>
                    <Grid item xs={12}>
                        <Input
                            required
                            label={'GENERAL.value'}
                            value={`R$ ${values.value}`}
                            valueName={'value'}
                            handleChange={handleChange}
                            maxLength={16}
                            error={requiredValue}
                            helperText={
                                requiredValue && t('GENERAL.requiredField')
                            }
                            setRequired={setRequiredValue}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <InputDate
                            required
                            label={'GENERAL.dateOfPay'}
                            value={values.date}
                            valueName={'date'}
                            handleChange={handleChange}
                            maxDate={new Date().setDate(new Date().getDate())}
                            error={requiredDate}
                            helperText={requiredDate && dateErrorMessage}
                            setRequired={setRequiredDate}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <div style={{ marginTop: '12px' }}></div>
                        <InputFile
                            context={'new'}
                            file={file}
                            setFile={setFile}
                            label={t('GENERAL.proof')}
                            placeholder={t('GENERAL.receiptFilePlaceholder')}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Input
                            placeholder={t('LIBRARY.observationPlaceholder')}
                            label={'GENERAL.observation'}
                            value={values.observation}
                            valueName={'observation'}
                            rows={3}
                            maxLength={200}
                            handleChange={handleChange}
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Buttons
                    onClick={handleClose}
                    tipo="BN-noback"
                    conteudo={t('GENERAL.cancel').toUpperCase()}
                />
                <Buttons
                    isLoading={mutation.isLoading}
                    onClick={handleSubmit}
                    tipo="BN-blue"
                    conteudo={t('GENERAL.request').toUpperCase()}
                />
            </DialogActions>
        </Dialog>
    );
};

export default DialogApplyForCredit;
