import { FormatDateToFront } from 'utils/dates/FormatDateToFront';
import { getYearsBetween } from 'utils/dates/getYearsBetween';

/**
 * Populate the yearsArray with the years between the minimum year found in the data and the current year
 * @param {*} collaboratorDataClockRecords
 * @returns
 */
export const getCollaboratorClockRecordYears = collaboratorDataClockRecords => {
    const years = collaboratorDataClockRecords.map(item =>
        FormatDateToFront(item.date).getFullYear()
    );
    return getYearsBetween(Math.min(...years), new Date().getFullYear())
        .reverse()
        .map(year => {
            return { value: year, label: year };
        });
};
