export const convertStrToBrCurrency = value => {
    const onlyNumbers = value.replace(/\D/g, '');

    if (onlyNumbers) {
        const number = onlyNumbers.split("").reverse();
        const len = number.length;
        let count = 0;
        let valueFormatted = "";

        for (var i = 0; i < len; i++) {
            if (i === 1) {
                valueFormatted = "," + number[i] + valueFormatted;
            } else {
                if (count === 3) {
                    count = 1;
                    valueFormatted = number[i] + "." + valueFormatted;
                } else {
                    if (i > 1) {
                        count += 1;
                    }
                    valueFormatted = number[i] + valueFormatted;
                }
            }
        }

        return valueFormatted;
    }

    return "";
};
