export const SortElementSelect = (elemnts, attribute) => {
    elemnts.sort(function (a, b) {
        if (a[attribute] === null) a[attribute] = "";
        if (b[attribute] === null) b[attribute] = "";

        if (a[attribute].normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() >
            b[attribute].normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim()) {
            return 1;
        }
        if (a[attribute].normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() <
            b[attribute].normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim()) {
            return -1;
        }
        // a must be equal to b
        return 0;
    });
}