import React, { useContext, useEffect, useState } from "react";
import "../../../../App.css";
import SideBar from "components/layout/sidebar/Sidebar";
import DashboardNavBar from "components/layout/DashboardNavBar";
import CollaboratorClockRecordDetailsForm from "./CollaboratorClockRecord/CollaboratorClockRecordDetailsForm";

const CallMyActivities = ({ match }) => {

    return (
        <div>
            <DashboardNavBar />
            <SideBar />
            <CollaboratorClockRecordDetailsForm id={match.params.id}/>
        </div>
    );
}

export default CallMyActivities;