import React from "react";
import "../../../App.css";
import SideBar from "../../../components/layout/sidebar/Sidebar";
import DashboardNavBar from "../../../components/layout/DashboardNavBar";
import CollaboratorsRequisitions from "./CollaboratorsRequisitions";

const CallCollaboratorsRequisitions = ({ match }) => {

    return (
        <div>
            <DashboardNavBar />
            <SideBar />
            <CollaboratorsRequisitions />
        </div>
    );
}

export default CallCollaboratorsRequisitions;