import React from "react";
import "../../../../App.css";
import SideBar from "components/layout/sidebar/Sidebar";
import DashboardNavBar from "components/layout/DashboardNavBar";
import MyActivitiesForm from "./MyActivitiesForm";

const CallMyActivities = ({ match }) => {

    return (
        <div>
            <DashboardNavBar />
            <SideBar />
            <MyActivitiesForm id={match.params.id} context={"new"} />
        </div>
    );
}

export default CallMyActivities;