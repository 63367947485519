import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import Buttons from 'components/buttons/Pressed';
import Typography from 'components/typography/Typography';
import { screenStyles } from '../Styles';
import Input from 'components/inputs/Input';
import Grid from '@material-ui/core/Grid';
import { LibraryReservationStatusValues } from 'global/constants';
import { AllAuthorsName } from 'utils/library/AllAuthorsName';
import { formatExemplarCode } from 'utils/library/formatExemplarCode';

const DialogCancelRequisition = props => {
    const styles = screenStyles();
    const [t] = useTranslation();
    const { open, setOpen, viewValues, submit, context } = props;

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (name, value) => {
        let val = value;
        setValues({ ...values, [name]: val });
    };

    const [values, setValues] = useState({
        exemplarId: '',
        justification: '',
        queueId: ''
    });

    const handleSubmit = () => {
        if (context !== 'queuedBooks') {
            const emailData = {
                colaboratorEmail: viewValues.user.username,
                justification: values.justification,
                bookLibrary: viewValues.library,
                screenContext:
                    viewValues.status ===
                    LibraryReservationStatusValues.AWAITING_APPROVAL
                        ? 'approved'
                        : 'withdraw',
                context: 'CANCEL',
                endpoint: 'cancelRequisition',
                bookTitle: viewValues.book.title,
                bookAuthors: AllAuthorsName(viewValues.book.authors),
                bookCategory: viewValues.book.category.name,
                exemplarCode: formatExemplarCode('' + viewValues.exemplar.code),
                reservationId: viewValues.id
            };

            submit(viewValues.id, values.justification, emailData);
        } else {
            const emailData = {
                collaboratorEmail: viewValues.user.username,
                collaboratorName: viewValues.user.shortname,
                justification: values.justification,
                bookTitle: viewValues.book.title,
                bookAuthors: AllAuthorsName(viewValues.book.authors),
                bookCategory: viewValues.book.category.name
            };
            submit(viewValues.id, values.justification, emailData);
        }

        setOpen(false);
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth={'sm'}
                fullWidth={true}
            >
                <DialogTitle>
                    <div className={styles.dialogTitle}>
                        <Typography
                            variant={'RobotoBold'}
                            fontSize={24}
                            textColor={'secondary'}
                        >
                            {context !== 'queuedBooks'
                                ? t('LIBRARY.dialogCancelRequisition')
                                : t('LIBRARY.dialogCancelQueue')}
                        </Typography>
                        <IconButton
                            color="#6D6F71"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <Typography
                        variant={'RobotoRegular'}
                        fontSize={16}
                        textColor={'secondary'}
                    >
                        Você realmente deseja cancelar a{' '}
                        {context !== 'queuedBooks'
                            ? 'requisição de empréstimo do livro'
                            : 'reserva do livro'}{' '}
                        <b>{viewValues.book.title}</b>?
                    </Typography>

                    <div
                        style={{ marginTop: '20px' }}
                        className={styles.dialogBookRequisitionContainer}
                    >
                        <Typography
                            variant={'RobotoRegular'}
                            fontSize={16}
                            textColor={'secondary'}
                        >
                            {context !== 'queuedBooks'
                                ? t(
                                      'LIBRARY.dialogMyLoansCancelRequisitionExplanation'
                                  )
                                : t(
                                      'LIBRARY.dialogMyLoansCancelQueueExplanation'
                                  )}
                        </Typography>
                    </div>

                    <Grid container item spacing={2} md={12} lg={12}>
                        <Grid item xs={12} sm={12}>
                            <Input
                                required
                                placeholder={
                                    context !== 'queuedBooks'
                                        ? t('LIBRARY.justification')
                                        : t('LIBRARY.queuedJustification')
                                }
                                label={'GENERAL.reason'}
                                value={values.justification}
                                valueName={'justification'}
                                rows={4}
                                maxLength={200}
                                handleChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions className={styles.dialogButtonsWrapper}>
                    <Buttons
                        onClick={handleClose}
                        tipo="BN-noback"
                        conteudo={t('GENERAL.close').toUpperCase()}
                    />

                    <Buttons
                        disabled={!values.justification.length}
                        className={styles.dialogButton}
                        tipo="BN-blue"
                        conteudo={
                            context !== 'queuedBooks'
                                ? 'CANCELAR REQUISIÇÃO'
                                : 'CANCELAR RESERVA'
                        }
                        onClick={handleSubmit}
                    />
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DialogCancelRequisition;
