import jwtDecode from "jwt-decode";
import history from "../../history";
import Cookies from "js-cookie";

class Auth {
  constructor() {
    this.authenticated = false;
    this.isLogged = false;
    this.permissionLevel = -1; // not even can access 0 level pages
    this.storage = null;
    this.currentUserId = null;
    this.currentUserProfileId = null;
  }
  /**
   *  WITH STORAGE.ID WHICH IS USERS ID, WE CAN
   *  GET HIS PROFILE, AND ASSOCIATE WITH PERMISSION LEVEL
   *  IF THE PERMISSION LEVEL IS HIGH ENOUTH HE CAN GET THE
   *  ROUTE THAT HE WANTS, IF NOT WE REDIRECT HIM
   */
  setStorage() {
    if (!localStorage.getItem("auth-token")) {
      history.push("/login");
    } else {
      this.storage = localStorage.getItem("auth-token");
    }
  }

  setAuthenticated(value) {
    this.authenticated = value;
    if (!this.storage) {
      history.push("/login");
    }
  }

  setPermissionLevel(value) {
    this.permissionLevel = value;
    if (!this.storage) {
      history.push("/login");
    }
  }

  // if logged, permissionlevel at least gets 0 value
  async getLogged() {
    if (localStorage.getItem("auth-token")) {
      this.setStorage();
      this.isLogged = this.storage !== null;

      if (Cookies.get('auth-token') &&
        jwtDecode(Cookies.get('auth-token')).exp < Date.now() / 1000) {
        localStorage.removeItem("auth-token");
        this.isLogged = false;
        history.push("/login");
      }

      if (this.isLogged) {
        this.setPermissionLevel(0);
        let storageJson = JSON.parse(this.storage);
        this.currentUserId = storageJson.id;
        this.currentUserProfileId = storageJson.profileId;
      }
    }
    return this.isLogged;
  }

  isAuthenticated(canAccess) {
    this.setAuthenticated(canAccess);
    return this.authenticated;
  }
}

export default new Auth();