import IconButton from "@material-ui/core/IconButton";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/ReportProblem";
import clsx from "clsx";
import { useStyles } from "components/layout/CommonStyles";
import  useStyles2  from "components/layout/snackBars/SnackBarStyles"
import React from "react";

const variantIcon = {
  success: CheckCircleIcon,
  error: ErrorIcon
};

const MySnackbarContentWrapper = React.forwardRef(
  function MySnackbarContentWrapper(props, ref) {
    const classes = useStyles();
    const classes2 = useStyles2();
    const { className, message, onClose, variant, ...other } = props;
    const Icon = variantIcon[variant];

    return (
      <SnackbarContent
        ref={ref}
        className={clsx(classes2[variant], className)}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        ]}
        {...other}
      />
    );
  }
);

export default MySnackbarContentWrapper;
