import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Dissatisfied from "@material-ui/icons/SentimentVeryDissatisfied";
import { useStyles } from "components/layout/CommonStyles";
import Spinner from "components/layout/spinners/Index";
import ProjectsContext from "context/projects/projectsContext";
import SearchContext from "context/search/searchContext";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { animated, useTransition } from "react-spring";
import SearchParticipant from "../../SearchExecution";
import TableActions from "./ParticipantsTableActions";

const ActivityParticipantsTable = props => {
    const { participants, activityId } = props;
    const projectsContext = useContext(ProjectsContext);
    const searchContext = useContext(SearchContext);
    const { filter } = searchContext;
    const { loading } = projectsContext;
    let dataFilter = participants;
    const [t] = useTranslation();
    const classes = useStyles();

    if (filter) {
        dataFilter = dataFilter.filter(
            item =>
                item.participantName.toLowerCase().includes(filter.toLowerCase()) ||
                item.participantfunction.toLowerCase().includes(filter.toLowerCase()) ||
                item.participantEducation.toLowerCase().includes(filter.toLowerCase())
        );
    }

    const transitions = useTransition(loading, null, {
        from: { opacity: 0 },
        enter: {
            opacity: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "calc(100vh - 70px)"
        },
        leave: { opacity: 0 }
    });

    return transitions.map(({ item, key, props }) =>
        item ? (
            <animated.div key={key} style={props}>
                <Spinner />
            </animated.div>
        ) : (
                <Paper className={classes.root}>
                    <div className={classes.header}>
                        <Grid container item spacing={2} md={12}>
                            <Grid item xs={1}>
                                {t("PROFILES.form.participants").toUpperCase()}
                            </Grid>
                        </Grid>
                    </div>
                    <Toolbar>
                        <SearchParticipant label="Procurar por colaborador" />
                    </Toolbar>
                    <TableActions
                        rows={dataFilter}
                        headers={["id", "imagePath", "participantName", "participantEducation", "participantfunction", ""]}
                        sortedFields={["", "", "name", "function"]}
                        visibleHeaders={["", "#", "NOME", "GRAU DE FORMAÇÃO", "FUNÇÃO"]}
                        mainField="imagePath"
                        activityId={activityId}
                    />
                    {participants.length > 0 && filter && dataFilter.length === 0 && (
                        <div className={classes.noParticipantsMessage}>
                            <div>
                                <Dissatisfied className={classes.notFoundIconStyle} />
                            </div>
                            <h2>{t("GENERAL.noResultsFound")}</h2>
                            <h5>{`Sua busca por "${filter}" não retornou resultados.`}</h5>
                        </div>
                    )}
                </Paper>
            )
    );
};

export default ActivityParticipantsTable;