import { makeStyles, withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";


const errorPageStyles = makeStyles(theme => ({
  /* 
   * breadCrumbPlaceHolder 
   */
  breadCrumbsPlaceHolder: {
    marginTop: "15px",
    flexWrap: "wrap",
    marginBottom: "5px",
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "16.41px",
    color: "#6D6F71"
  },
  containerHeaderWhite: {
    display: "flex",
    marginBottom: "20px",
    flexDirection: 'row',
    justifyContent: "space-between",
    alignItems: "center",
  },
  errorCode: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: "64px",
    margin: "0px 0px",
    border: "0px",
    lineHeight: "75px",
    color: "#6D6F71",
  },

  errorMessageBox: {
    fontFamily: "Roboto",
    textAlign: "center",
    fontStyle: "normal",
    fontSize: "18px",
    lineHeight: "21px",
    color: "#000000",
    paddingBottom: "20%",
    paddingTop: "10%",
    paddingLeft: "20%",
    paddingRight: "20%",
    margin: "0px 0px"

    
  },
  errorText :{
    color: "#6D6F71",
    textWeight: "normal",
    fontSize: "24px",
    textWeight: "normal",
    fontFamily: "Roboto",
    fontWeight: "500",
    lineHeight: "24px",
    letterSpacing: "0.0357em",
    textAlign: "center",
    margin: "0px 0px 60px 0px",

  },

  errorInfo :{
    color: "#6D6F71",
    textWeight: "normal",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0.0357em",
    textAlign: "center",





  },

  
  errorPaper : {
    marginLeft: "min(124px, 5%)",
    marginRight: "min(124px, 5%)",
    minWidth: "300px",
    background: "#ffffff"
  },

  newBlueButton: {
    backgroundColor: "#2C3E51",
    padding: "8px 16px",
    fontWeight: "normal",
    fontSize: "14px"
    
  },

  blueSquareButton: {
    backgroundColor: "#ffffff",
    padding: "5px 13px",
    fontWeight: "normal",
    fontSize: "14px",
    border: "3px solid #2C3E51",
    color: "#2C3E51"
  },

  buttonText: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "24px",
    letterSpacing: "0.0357em",
    textAlign: "center",
    margin: "0px"


  },
  
}));

export { errorPageStyles };
