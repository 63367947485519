import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Visibility from '@material-ui/icons/Visibility';
import Edit from '@material-ui/icons/Edit';
import Link from 'react-router-dom/Link';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';
import { Screen, Allow } from 'global/constants';
import { screenStyles } from './Styles';

const UsersTableActions = props => {
    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }
    const styles = screenStyles();
    const classes = useStyles();
    const [t] = useTranslation();
    const { item, isLSBD } = props;

    const [open, setOpen] = useState(false);

    return (
        <React.Fragment>
            <Link to={`/Quotas/Users/View/${item.id}`}>
                <Tooltip
                    title={t('GENERAL.view')}
                    aria-label="details"
                    placement="top"
                >
                    <IconButton className={classes.iconsTableActions}>
                        <Visibility />
                    </IconButton>
                </Tooltip>
            </Link>
            {GetGeneralPermissionForAction(Screen.QUOTAS_USERS, Allow.EDIT) && (
                <Link to={`/Quotas/Users/Edit/${item.id}`}>
                    <Tooltip
                        title={t('GENERAL.edit')}
                        aria-label="details"
                        placement="top"
                    >
                        <IconButton className={classes.iconsTableActions}>
                            <Edit />
                        </IconButton>
                    </Tooltip>
                </Link>
            )}
        </React.Fragment>
    );
};

export default UsersTableActions;
