import React from "react";
import "../../../../App.css";
import SideBar from "components/layout/sidebar/Sidebar";
import DashboardNavBar from "components/layout/DashboardNavBar";
import VacationCalendar from "./VacationCalendar";

const CallVacationCalendar = ({ match }) => {

    return (
        <div>
            <DashboardNavBar />
            <SideBar />
            <VacationCalendar />
        </div>
    );
}

export default CallVacationCalendar;