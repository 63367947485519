import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogUnit from 'components/dialogs/DialogUnit';
import DialogUnitWithIcon from 'components/dialogs/DialogUnitWithIcon';
import { Grid, Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import Buttons from 'components/buttons/Pressed';
import Typography from 'components/typography/Typography';
import Avatar from '@material-ui/core/Avatar';
import Input from 'components/inputs/Input';
import InputFile from 'components/inputs/InputFile';

import { FormatDateToFront } from 'utils/dates/FormatDateToFront';
import { dateToBR } from 'utils/dates/DateToBRr';
import { valueToMoneyFloat } from 'utils/general/valueToMoneyFloat';
import { moneyMask } from 'utils/masks/moneyMask';

import {
    translatePersonalTransactionsTypes,
    TransactionsTypes,
    translateLSBDTransactionsTypes,
    SourceTypes
} from '../ScreenUtils';
import { screenStyles } from '../Styles';
import DialogObservationUnit from 'components/dialogs/DialogObservationUnit';

const DialogViewTransactions = props => {
    const styles = screenStyles();
    const [t] = useTranslation();

    const { open, setOpen, viewValues, isLSBD, isExpense } = props;

    const handleClose = () => {
        setOpen(false);
    };

    const isDebit = viewValues.type === TransactionsTypes.DEBIT;
    const isDebitCancelled =
        viewValues.type === TransactionsTypes.CANCELLED_DEBIT;
    const isCredit = viewValues.type === TransactionsTypes.CREDIT;
    const isCreditCancelled =
        viewValues.type === TransactionsTypes.CANCELLED_CREDIT;
    const hasExternanlCollaborator =
        viewValues.externalContributorUser !== null;

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            maxWidth={'sm'}
            fullWidth
        >
            <DialogTitle>
                <Grid container item spacing={2} xs={12}>
                    <Grid item xs={11}>
                        <Typography
                            variant={'RobotoBold'}
                            fontSize={24}
                            textColor={'secondary'}
                        >
                            {`${t('GENERAL.view')} ${
                                isExpense
                                    ? t('GENERAL.expense')
                                    : t('QUOTAS.movement')
                            }`}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            color="#6D6F71"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent>
                <Grid container item spacing={1} xs={12}>
                    <Grid item xs={6}>
                        <DialogUnitWithIcon
                            head={
                                (isDebit || isDebitCancelled) && isLSBD
                                    ? t('GENERAL.operator').toUpperCase()
                                    : viewValues.source === SourceTypes.EXTERNAL
                                    ? t(
                                          'GENERAL.collaboratorExternal'
                                      ).toUpperCase()
                                    : t('GENERAL.collaborator').toUpperCase()
                            }
                            Icon={
                                <Avatar
                                    className={styles.dialogViewBillAvatar}
                                    alt={
                                        (isDebit ||
                                            isDebitCancelled ||
                                            isExpense) &&
                                        viewValues.operator
                                            ? viewValues.operator.shortname
                                            : hasExternanlCollaborator
                                            ? viewValues.externalContributorUser
                                            : viewValues.user
                                            ? viewValues.user.shortname
                                            : ''
                                    }
                                    src={
                                        process.env.REACT_APP_IMGSERVER +
                                        ((isDebit ||
                                            isDebitCancelled ||
                                            isExpense) &&
                                        viewValues.operator
                                            ? viewValues.operator.imageName
                                            : viewValues.user
                                            ? viewValues.user.imageName
                                            : '-')
                                    }
                                />
                            }
                            content={
                                (isDebit || isDebitCancelled || isExpense) &&
                                viewValues.operator
                                    ? viewValues.operator.name
                                    : hasExternanlCollaborator
                                    ? viewValues.externalContributorUser
                                    : viewValues.user
                                    ? viewValues.user.name
                                    : '-'
                            }
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <div style={{ marginBottom: '16px' }}>
                            <DialogUnit
                                head={t('GENERAL.date').toUpperCase()}
                                content={dateToBR(
                                    FormatDateToFront(viewValues.date)
                                )}
                                marginTop={'16px'}
                            />
                        </div>
                    </Grid>

                    <Grid item xs={6}>
                        <DialogUnit
                            head={t('GENERAL.value').toUpperCase()}
                            content={
                                <Box
                                    color={
                                        viewValues.type ===
                                            TransactionsTypes.CREDIT ||
                                        viewValues.type ===
                                            TransactionsTypes.CANCELLED_DEBIT
                                            ? '#27AE60'
                                            : viewValues.type ===
                                                  TransactionsTypes.DEBIT ||
                                              viewValues.type ===
                                                  TransactionsTypes.CANCELLED_CREDIT
                                            ? '#F3222F'
                                            : null
                                    }
                                    fontWeight="700"
                                    height={'100%'}
                                >
                                    {`${
                                        viewValues.type ===
                                            TransactionsTypes.CREDIT ||
                                        viewValues.type ===
                                            TransactionsTypes.CANCELLED_DEBIT
                                            ? '+'
                                            : '-'
                                    } R$
                                    ${moneyMask(
                                        valueToMoneyFloat(
                                            Math.abs(viewValues.value)
                                        )
                                    )}
                                    `}
                                </Box>
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <div style={{ marginBottom: '12px' }}>
                            <DialogUnit
                                head={t('GENERAL.type').toUpperCase()}
                                content={
                                    isLSBD || isExpense
                                        ? translateLSBDTransactionsTypes(
                                              viewValues.type,
                                              viewValues.source
                                          )
                                        : translatePersonalTransactionsTypes(
                                              viewValues.type
                                          )
                                }
                            />
                        </div>
                    </Grid>

                    {viewValues.operator &&
                    !isExpense &&
                    !isDebit &&
                    !isDebitCancelled ? (
                        <>
                            <Grid item xs={6}>
                                <DialogUnitWithIcon
                                    head={
                                        isCredit
                                            ? t('GENERAL.addedBy').toUpperCase()
                                            : t(
                                                  'GENERAL.canceledBy'
                                              ).toUpperCase()
                                    }
                                    Icon={
                                        <Avatar
                                            className={
                                                styles.dialogViewBillAvatar
                                            }
                                            alt={viewValues.operator.shortname}
                                            src={
                                                process.env
                                                    .REACT_APP_IMGSERVER +
                                                viewValues.operator.imageName
                                            }
                                        />
                                    }
                                    content={viewValues.operator.name}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <div style={{ marginBottom: '16px' }}>
                                    <DialogUnit
                                        head={t(
                                            'GENERAL.operationDate'
                                        ).toUpperCase()}
                                        content={dateToBR(
                                            FormatDateToFront(
                                                viewValues.createdAt
                                            )
                                        )}
                                        marginTop={'16px'}
                                    />
                                </div>
                            </Grid>
                        </>
                    ) : null}

                    {viewValues.receiptFilename && viewValues.receiptPath && (
                        <Grid item xs={12}>
                            <div style={{ marginBottom: '8px' }}>
                                <InputFile
                                    context={'view'}
                                    fileName={viewValues.receiptFilename}
                                    filePath={viewValues.receiptPath}
                                    label={t('GENERAL.receipt')}
                                    placeholder={t('GENERAL.noFile')}
                                />
                            </div>
                        </Grid>
                    )}

                    {viewValues.description &&
                    !(
                        isLSBD &&
                        viewValues.type === TransactionsTypes.CREDIT &&
                        viewValues.source === SourceTypes.USER
                    ) ? (
                        <Grid item xs={12}>
                            <DialogObservationUnit
                                head={
                                    isDebitCancelled || isCreditCancelled
                                        ? t('GENERAL.reason')
                                        : !isLSBD && !isExpense
                                        ? t('GENERAL.observation')
                                        : t('GENERAL.description')
                                }
                                content={viewValues.description}
                            />
                        </Grid>
                    ) : null}
                </Grid>
            </DialogContent>

            <DialogActions style={{ padding: '24px 30px' }}>
                <Buttons
                    onClick={handleClose}
                    tipo="BN-blue"
                    conteudo={t('GENERAL.close').toUpperCase()}
                />
            </DialogActions>
        </Dialog>
    );
};

export default DialogViewTransactions;
