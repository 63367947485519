/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Footer from 'components/layout/Footer';
import ClockRecordContext from 'context/hrModule/clockRecord/clockRecordContext';
import SettingsContext from 'context/hrModule/settings/settingsContext';
import Transition from 'components/layout/transition/Transition';
import Breadcrumb from 'components/layout/breadcrumb/Breadcrumb';
import HeaderContainer from 'components/containers/HeaderContainer';
import ContentContainer from 'components/containers/ContentContainer';
import Typography from 'components/typography/Typography';
import Buttons from 'components/buttons/Pressed';
import Input from 'components/inputs/Input';
import InputDate from 'components/inputs/InputDate';
import InputMultiDropzone from 'components/inputs/InputMultiDropzone';
import DefaultTooltip from 'components/infoTooltip/DefaultTooltip';
import BlueInfoText from 'components/Infos/BlueInfoText';

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { Screen, Allow } from 'global/constants';

import { FormatDateToFront } from 'utils/dates/FormatDateToFront';
import { GetHoursAndMinutes } from 'utils/dates/GetHoursAndMinutes';
import { ConvertMilliInHourMin } from 'utils/dates/ConvertMilliInHourMin1';
import { dateToBR } from 'utils/dates/DateToBRr';
import { SortElementSelect } from 'utils/sort/SortElementSelect';
import { dateValidation } from 'utils/validation/DateValidation';
import { GetInputArray } from 'utils/general/GetInputArray';

import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';

import { screenStyles } from './Styles';
import {
    JustificationsTypeArray,
    GetJustificationStatus,
    GetJustificationType,
    CollaboratorsJustificationsActions,
    JustificationType
} from './ScreenUtils';
import { FormatDateToDataBase } from 'utils/dates/FormatDateToDataBase';
import DialogCollaboratorsJustifications from './DialogCollaboratorsJustifications';

const CollaboratorsJustificationsForm = props => {
    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const classes = useStyles();
    const styles = screenStyles();
    const [t] = useTranslation();
    const clockRecordContext = useContext(ClockRecordContext);
    const settingsContext = useContext(SettingsContext);

    const { id, context } = props;

    const [loading, setLoading] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContext, setDialogContext] = useState('');
    const [allowanceReasonsArray, setAllowanceReasonsArray] = useState([]);
    const [requiredJustificationType, setRequiredJustificationType] =
        useState(false);
    const [requiredJustifiedDate, setRequiredJustifiedDate] = useState(false);
    const [justifiedDateErrorMessage, setJustifiedDateErrorMessage] =
        useState('');
    const [requiredObservation, setRequiredObservation] = useState(false);
    const [mustRedirect, setMustRedirect] = useState(false);

    const [values, setValues] = useState({
        dateCreated: new Date(new Date().setHours(0, 0, 0, 0)),
        justificationType: '',
        observation: '',
        workingHours: '',
        status: '',
        allowanceReasonId: ''
    });

    const [files, setFiles] = useState({
        files: [],
        rejectedFiles: [],
        deletedFiles: []
    });

    const [dates, setDates] = useState({
        justifiedDate: null
    });

    const handleOpenDialog = context => {
        setOpenDialog(true);
        setDialogContext(context);
    };

    const {
        collaboratorsJustificationsForm,
        getCollaboratorsJustificationsBySolicitation,
        approveCollaboratorsJustifications,
        refuseCollaboratorsJustifications
    } = clockRecordContext;

    const { getAllAllowanceReasons, allowanceReasons } = settingsContext;

    useEffect(() => {
        if (allowanceReasons && allowanceReasons.length > 0) {
            const AllowanceReasonsTemp = [...allowanceReasons];
            SortElementSelect(AllowanceReasonsTemp, 'name');
            setAllowanceReasonsArray(
                GetInputArray(AllowanceReasonsTemp, 'id', 'name')
            );
        }
    }, [allowanceReasons]);

    async function fetchData() {
        getAllAllowanceReasons();
        getCollaboratorsJustificationsBySolicitation(id, setLoading);
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (collaboratorsJustificationsForm) {
            setValues({
                id:
                    collaboratorsJustificationsForm.solicitation &&
                    collaboratorsJustificationsForm.solicitation.id
                        ? collaboratorsJustificationsForm.solicitation.id
                        : 0,
                approvedBy:
                    collaboratorsJustificationsForm.solicitation &&
                    collaboratorsJustificationsForm.solicitation.approverName
                        ? collaboratorsJustificationsForm.solicitation
                              .approverName
                        : '',
                approverResponse:
                    collaboratorsJustificationsForm.solicitation &&
                    collaboratorsJustificationsForm.solicitation
                        .approverResponse
                        ? collaboratorsJustificationsForm.solicitation
                              .approverResponse
                        : '',
                approverResponseDate:
                    collaboratorsJustificationsForm.solicitation &&
                    collaboratorsJustificationsForm.solicitation
                        .approverResponseDate
                        ? FormatDateToFront(
                              collaboratorsJustificationsForm.solicitation
                                  .approverResponseDate
                          )
                        : null,
                requester:
                    collaboratorsJustificationsForm.solicitation &&
                    collaboratorsJustificationsForm.solicitation.requesterName
                        ? collaboratorsJustificationsForm.solicitation
                              .requesterName
                        : '',
                dateCreated:
                    collaboratorsJustificationsForm.solicitation &&
                    collaboratorsJustificationsForm.solicitation.dateCreated
                        ? FormatDateToFront(
                              collaboratorsJustificationsForm.solicitation
                                  .dateCreated
                          )
                        : new Date(new Date().setHours(0, 0, 0, 0)),
                totalHours:
                    collaboratorsJustificationsForm.justifiedClockRecord &&
                    collaboratorsJustificationsForm.justifiedClockRecord
                        .totalHours
                        ? collaboratorsJustificationsForm.justifiedClockRecord
                              .totalHours
                        : '-',
                justificationType:
                    collaboratorsJustificationsForm.solicitation &&
                    collaboratorsJustificationsForm.solicitation.class
                        ? collaboratorsJustificationsForm.solicitation.class
                        : '',
                status:
                    collaboratorsJustificationsForm.solicitation &&
                    collaboratorsJustificationsForm.solicitation.status
                        ? collaboratorsJustificationsForm.solicitation.status
                        : '',
                observation:
                    collaboratorsJustificationsForm.solicitation &&
                    collaboratorsJustificationsForm.solicitation.message
                        ? collaboratorsJustificationsForm.solicitation.message
                        : '',
                workingHours: collaboratorsJustificationsForm.workingHours
                    ? ConvertMilliInHourMin(
                          collaboratorsJustificationsForm.workingHours
                      )
                    : '-',
                entry: collaboratorsJustificationsForm.justifiedClockRecord
                    ? collaboratorsJustificationsForm.justifiedClockRecord
                          .entryTime
                        ? new Date(
                              FormatDateToFront(
                                  collaboratorsJustificationsForm
                                      .justifiedClockRecord.entryTime
                              )
                          )
                        : collaboratorsJustificationsForm.solicitation.time1
                        ? new Date(
                              FormatDateToFront(
                                  collaboratorsJustificationsForm.solicitation
                                      .time1
                              )
                          )
                        : ''
                    : '',
                leaveForBreak:
                    collaboratorsJustificationsForm.justifiedClockRecord
                        ? collaboratorsJustificationsForm.justifiedClockRecord
                              .leaveForBreak
                            ? new Date(
                                  FormatDateToFront(
                                      collaboratorsJustificationsForm
                                          .justifiedClockRecord.leaveForBreak
                                  )
                              )
                            : collaboratorsJustificationsForm.solicitation.time2
                            ? new Date(
                                  FormatDateToFront(
                                      collaboratorsJustificationsForm
                                          .solicitation.time2
                                  )
                              )
                            : ''
                        : '',
                returnFromBreak:
                    collaboratorsJustificationsForm.justifiedClockRecord
                        ? collaboratorsJustificationsForm.justifiedClockRecord
                              .returnFromBreak
                            ? new Date(
                                  FormatDateToFront(
                                      collaboratorsJustificationsForm
                                          .justifiedClockRecord.returnFromBreak
                                  )
                              )
                            : collaboratorsJustificationsForm.solicitation.time3
                            ? new Date(
                                  FormatDateToFront(
                                      collaboratorsJustificationsForm
                                          .solicitation.time3
                                  )
                              )
                            : ''
                        : '',
                leave: collaboratorsJustificationsForm.justifiedClockRecord
                    ? collaboratorsJustificationsForm.justifiedClockRecord
                          .leaveTime
                        ? new Date(
                              FormatDateToFront(
                                  collaboratorsJustificationsForm
                                      .justifiedClockRecord.leaveTime
                              )
                          )
                        : collaboratorsJustificationsForm.solicitation.time4
                        ? new Date(
                              FormatDateToFront(
                                  collaboratorsJustificationsForm.solicitation
                                      .time4
                              )
                          )
                        : ''
                    : '',
                allowanceReasonId:
                    collaboratorsJustificationsForm.solicitation &&
                    collaboratorsJustificationsForm.solicitation.allowanceId
                        ? collaboratorsJustificationsForm.solicitation
                              .allowanceId
                        : ''
            });

            setDates({
                justifiedDate:
                    collaboratorsJustificationsForm.solicitation &&
                    collaboratorsJustificationsForm.solicitation.requestedDate
                        ? FormatDateToFront(
                              collaboratorsJustificationsForm.solicitation
                                  .requestedDate
                          )
                        : null,
                initialAllowanceHour:
                    collaboratorsJustificationsForm.solicitation &&
                    collaboratorsJustificationsForm.solicitation.requestedDate
                        ? FormatDateToFront(
                              collaboratorsJustificationsForm.solicitation
                                  .requestedDate
                          )
                        : null,
                finalAllowanceHour:
                    collaboratorsJustificationsForm.solicitation &&
                    collaboratorsJustificationsForm.solicitation.endDate
                        ? FormatDateToFront(
                              collaboratorsJustificationsForm.solicitation
                                  .endDate
                          )
                        : null,
                initialDate:
                    collaboratorsJustificationsForm.solicitation &&
                    collaboratorsJustificationsForm.solicitation.requestedDate
                        ? FormatDateToFront(
                              collaboratorsJustificationsForm.solicitation
                                  .requestedDate
                          )
                        : null,
                finalDate:
                    collaboratorsJustificationsForm.solicitation &&
                    collaboratorsJustificationsForm.solicitation.endDate
                        ? FormatDateToFront(
                              collaboratorsJustificationsForm.solicitation
                                  .endDate
                          )
                        : null
            });

            setFiles({
                files:
                    collaboratorsJustificationsForm.solicitation &&
                    collaboratorsJustificationsForm.files
                        ? collaboratorsJustificationsForm.files
                        : [],
                rejectedFiles: [],
                deletedFiles: []
            });
        }
    }, [collaboratorsJustificationsForm]);

    const redirect = go => {
        if (go) return <Redirect to={`/CollaboratorsJustifications`} />;
    };

    const verifySubmit = () => {
        let res = true;

        // if (!values.observation.trim()) {
        //     res = false;
        //     setRequiredObservation(true);
        // }

        return res;
    };

    const handleSubmit = type => {
        const canSubmit = verifySubmit();
        if (canSubmit) {
            let formData = new FormData();
            formData.append('id', values.id ? values.id : 0);
            formData.append('approver', auth.id ? auth.id : '');
            if (type === CollaboratorsJustificationsActions.REFUSE) {
                formData.append(
                    'justification',
                    values.approverResponse ? values.approverResponse : ''
                );
            }

            const emailData = {};

            emailData.solicitationId = values.id;
            emailData.approverName = auth.name;
            emailData.approvedDate = new Date();
            emailData.approverResponse = values.approverResponse;
            emailData.requesterName = values.requester;
            emailData.email =
                collaboratorsJustificationsForm.solicitation.requesterEmail;
            emailData.justificationType = GetJustificationType(
                values.justificationType
            );
            emailData.justifiedDate =
                values.justificationType === JustificationType.TOTAL_PAYMENT
                    ? dates.initialDate
                    : dates.justifiedDate;
            emailData.justifiedEndDate = dates.finalDate;
            emailData.solicitationDate = values.dateCreated;
            emailData.observation = values.observation;
            

            if (type === CollaboratorsJustificationsActions.APPROVE) {
                approveCollaboratorsJustifications(
                    formData,
                    setMustRedirect,
                    emailData,
                    collaboratorsJustificationsForm.solicitation.requesterId,
                    auth.id
                );
            } else {
                refuseCollaboratorsJustifications(
                    formData,
                    setMustRedirect,
                    emailData,
                    collaboratorsJustificationsForm.solicitation.requesterId,
                    auth.id
                );
            }
        }
    };

    const handleChange = (name, value) => {
        let val = value;
        setValues({ ...values, [name]: val });
    };

    const handleCancel = () => {
        if (context === 'view') {
            setMustRedirect(true);
        } else {
            handleOpenDialog(CollaboratorsJustificationsActions.REFUSE);
        }
    };

    return (
        <React.Fragment>
            {redirect(mustRedirect)}
            <Transition loading={loading} newDesign={true}>
                <HeaderContainer>
                    <Breadcrumb
                        newDesign={true}
                        routes={[
                            {
                                label: t('HR.humanResource'),
                                to: '/HumanResources'
                            },
                            { label: t('HR.clock') },
                            {
                                label: t('HR.collaboratorsJustifications'),
                                to: '/CollaboratorsJustifications'
                            },
                            {
                                label:
                                    context === 'view'
                                        ? `${t('GENERAL.view')} ${t(
                                              'HR.justification'
                                          )}`
                                        : `${t('HR.evaluateJustification')}`
                            }
                        ]}
                    />
                    <div className="container-headerNewDesign">
                        <h1 className={classes.titleNewDesign}>
                            {context === 'view'
                                ? `${t('GENERAL.view')} ${t(
                                      'HR.justification'
                                  )}`
                                : `${t('HR.evaluateJustification')}`}
                        </h1>
                    </div>
                </HeaderContainer>
                <ContentContainer>
                    <Paper className={styles.space}>
                        <div className="cabecalho">
                            {context === 'view'
                                ? `${t('GENERAL.view').toUpperCase()} ${t(
                                      'HR.justification'
                                  ).toUpperCase()}`
                                : `${t('HR.justificationData').toUpperCase()}`}
                        </div>
                        <Grid
                            container
                            item
                            spacing={2}
                            md={12}
                            lg={12}
                            style={{ padding: '32px' }}
                        >
                            <Grid item xs={12} sm={3}>
                                <Input
                                    context={'view'}
                                    label={'GENERAL.requester'}
                                    value={values.requester}
                                    valueName={'requester'}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Input
                                    context={'view'}
                                    label={'GENERAL.solicitationDate'}
                                    value={dateToBR(values.dateCreated)}
                                    valueName={'justificationDate'}
                                />
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={12} sm={3}>
                                <Input
                                    context={'view'}
                                    label={'HR.justificationType'}
                                    isSelect
                                    arraySelected={JustificationsTypeArray}
                                    value={values.justificationType}
                                    valueName={'justificationType'}
                                />
                            </Grid>
                            {values.justificationType !==
                                JustificationType.INCONSISTENCY_JUSTIFICATION && (
                                <Grid item xs={12} sm={3}>
                                    <Input
                                        required
                                        context={'view'}
                                        isSelect
                                        arraySelected={allowanceReasonsArray}
                                        label={'HR.typeOfReason'}
                                        value={values.allowanceReasonId}
                                        valueName={'allowanceReasonId'}
                                        handleChange={handleChange}
                                    />
                                </Grid>
                            )}
                            <Box width="100%" />
                            {values.justificationType ===
                                JustificationType.PARTIAL_PAYMENT && (
                                <>
                                    <Grid item xs={12} sm={2}>
                                        <InputDate
                                            type={'time'}
                                            context={'view'}
                                            label={'HR.initialAllowanceHour'}
                                            value={dates.initialAllowanceHour}
                                            valueName={'initialAllowanceHour'}
                                            keyboardIcon={<AccessTimeIcon />}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={2}>
                                        <InputDate
                                            type={'time'}
                                            context={'view'}
                                            label={'HR.finalAllowanceHour'}
                                            value={dates.finalAllowanceHour}
                                            valueName={'finalAllowanceHour'}
                                            keyboardIcon={<AccessTimeIcon />}
                                        />
                                    </Grid>
                                    <Box width="100%" />
                                </>
                            )}
                            {values.justificationType ===
                                JustificationType.TOTAL_PAYMENT && (
                                <>
                                    <Grid item xs={12} sm={2}>
                                        <InputDate
                                            required
                                            context={'view'}
                                            label={'GENERAL.initialDate'}
                                            value={dates.initialDate}
                                            valueName={'initialDate'}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={2}>
                                        <InputDate
                                            required
                                            context={'view'}
                                            label={'GENERAL.finalDate'}
                                            value={dates.finalDate}
                                            valueName={'finalDate'}
                                        />
                                    </Grid>
                                    <Box width="100%" />
                                </>
                            )}
                            {values.status !== 'PENDING' && (
                                <>
                                    <Grid item xs={12} sm={3}>
                                        <Input
                                            context={'view'}
                                            label={
                                                values.status === 'ALLOWED'
                                                    ? 'GENERAL.approvedBy'
                                                    : 'GENERAL.refusedBy'
                                            }
                                            value={values.approvedBy}
                                            valueName={'requester'}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={3}>
                                        <Input
                                            context={'view'}
                                            label={
                                                values.status === 'ALLOWED'
                                                    ? 'GENERAL.approvalDate'
                                                    : 'GENERAL.refusalDate'
                                            }
                                            value={dateToBR(
                                                values.approverResponseDate
                                            )}
                                            valueName={'justificationDate'}
                                        />
                                    </Grid>
                                    <Box width="100%" />
                                </>
                            )}
                            {values.status === 'DENIED' && (
                                <>
                                    <Grid item xs={12} sm={8}>
                                        <Input
                                            context={'view'}
                                            label={'GENERAL.reason'}
                                            value={values.approverResponse}
                                            valueName={'approverResponse'}
                                            rows={4}
                                            maxLength={200}
                                        />
                                    </Grid>

                                    <Box width="100%" />
                                </>
                            )}
                            {values.justificationType ===
                                JustificationType.TOTAL_PAYMENT &&
                                values.allowanceReasonId && (
                                    <Grid item xs={12} sm={12}>
                                        <BlueInfoText
                                            fontSize={15}
                                            text={`${t(
                                                'HR.forThisTypeOfAllowance'
                                            )}
                                                        ${
                                                            values.allowanceReasonId
                                                                ? allowanceReasons.find(
                                                                      x =>
                                                                          x.id ===
                                                                          values.allowanceReasonId
                                                                  )
                                                                      .daysAmount ||
                                                                  0
                                                                : 0
                                                        }
                                                        ${t(
                                                            'GENERAL.dayOrDays'
                                                        )}.`}
                                        />
                                    </Grid>
                                )}
                            <Box width="100%" />
                            <Grid item xs={12} sm={12}>
                                <Typography
                                    className={styles.myActivitiesTextsPadding}
                                    variant={'RobotoBold'}
                                    fontSize={16}
                                    textColor={'secondary'}
                                >
                                    {t(
                                        'HR.checkBelowMoreInformationAboutJustification'
                                    )}
                                </Typography>
                            </Grid>
                            {values.justificationType !==
                                JustificationType.TOTAL_PAYMENT && (
                                <Grid item xs={12} sm={2}>
                                    <Typography
                                        className={styles.textMargin}
                                        variant={'RobotoBold'}
                                        fontSize={15}
                                        textColor={'primary'}
                                    >
                                        {t('HR.justifiedDate')}
                                        <DefaultTooltip
                                            fontSize={'12'}
                                            className={styles.iconsRight}
                                            color={'#6D6F71'}
                                            text={t(
                                                'HR.solicitationDateTooltip'
                                            )}
                                        />
                                    </Typography>
                                    <Typography
                                        className={styles.textMargin}
                                        variant={'RobotoRegular'}
                                        fontSize={13}
                                        textColor={'secondary'}
                                    >
                                        {dateToBR(dates.justifiedDate)}
                                    </Typography>
                                </Grid>
                            )}

                            <Grid item xs={12} sm={2}>
                                <Typography
                                    className={styles.textMargin}
                                    variant={'RobotoBold'}
                                    fontSize={15}
                                    textColor={'primary'}
                                >
                                    {t('HR.workload')}
                                    <DefaultTooltip
                                        fontSize={'12'}
                                        className={styles.iconsRight}
                                        color={'#6D6F71'}
                                        text={t('HR.workloadTooltip')}
                                    />
                                </Typography>
                                <Typography
                                    className={styles.textMargin}
                                    variant={'RobotoRegular'}
                                    fontSize={13}
                                    textColor={'secondary'}
                                >
                                    {values.workingHours}
                                </Typography>
                            </Grid>
                            {values.justificationType !==
                                JustificationType.TOTAL_PAYMENT && (
                                <Grid item xs={12} sm={2}>
                                    <Typography
                                        className={styles.textMargin}
                                        variant={'RobotoBold'}
                                        fontSize={15}
                                        textColor={'primary'}
                                    >
                                        {t('HR.workedHours')}
                                        <DefaultTooltip
                                            fontSize={'12'}
                                            className={styles.iconsRight}
                                            color={'#6D6F71'}
                                            text={t(
                                                'HR.collaboratorsJustificationsWorkedHoursTooltip'
                                            )}
                                        />
                                    </Typography>
                                    <Typography
                                        className={styles.textMargin}
                                        variant={'RobotoRegular'}
                                        fontSize={13}
                                        textColor={'secondary'}
                                    >
                                        {values.totalHours}
                                    </Typography>
                                </Grid>
                            )}
                            <Grid item xs={12} sm={4}>
                                <Typography
                                    className={styles.textMargin}
                                    variant={'RobotoBold'}
                                    fontSize={15}
                                    textColor={'primary'}
                                >
                                    {t('GENERAL.status')}
                                    <DefaultTooltip
                                        fontSize={'12'}
                                        className={styles.iconsRight}
                                        color={'#6D6F71'}
                                        text={t(
                                            'HR.collaboratorsJustificationsStatusTooltip'
                                        )}
                                    />
                                </Typography>
                                <Grid
                                    container
                                    wrap="nowrap"
                                    alignItems="center"
                                >
                                    <Grid item>
                                        {values.status ? (
                                            values.status === 'ALLOWED' ? (
                                                <CheckCircleIcon
                                                    style={{
                                                        marginRight: '0.3rem',
                                                        color: '#7FE46B'
                                                    }}
                                                />
                                            ) : values.status === 'DENIED' ? (
                                                <ErrorIcon
                                                    style={{
                                                        marginRight: '0.3rem',
                                                        color: '#F3222F'
                                                    }}
                                                />
                                            ) : (
                                                <AccessTimeIcon
                                                    style={{
                                                        marginRight: '0.3rem',
                                                        color: '#2C3E51'
                                                    }}
                                                />
                                            )
                                        ) : null}
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            className={styles.textMargin}
                                            variant={'RobotoRegular'}
                                            fontSize={13}
                                            textColor={'secondary'}
                                        >
                                            {GetJustificationStatus(
                                                values.status
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Box width="100%" />
                            {values.justificationType !==
                                JustificationType.TOTAL_PAYMENT && (
                                <>
                                    <Grid item xs={12} sm={2}>
                                        <Typography
                                            className={styles.textMargin}
                                            variant={'RobotoBold'}
                                            fontSize={14}
                                            textColor={'primary'}
                                        >
                                            {t('HR.entry')}
                                        </Typography>
                                        <Typography
                                            className={styles.textMargin}
                                            variant={'RobotoRegular'}
                                            fontSize={12}
                                            textColor={'secondary'}
                                        >
                                            {values.entry
                                                ? GetHoursAndMinutes(
                                                      values.entry
                                                  )
                                                : '-'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <Typography
                                            className={styles.textMargin}
                                            variant={'RobotoBold'}
                                            fontSize={14}
                                            textColor={'primary'}
                                        >
                                            {t('HR.leaveForBreak')}
                                        </Typography>
                                        <Typography
                                            className={styles.textMargin}
                                            variant={'RobotoRegular'}
                                            fontSize={12}
                                            textColor={'secondary'}
                                        >
                                            {values.leaveForBreak
                                                ? GetHoursAndMinutes(
                                                      values.leaveForBreak
                                                  )
                                                : '-'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <Typography
                                            className={styles.textMargin}
                                            variant={'RobotoBold'}
                                            fontSize={14}
                                            textColor={'primary'}
                                        >
                                            {t('HR.returnFromBreak')}
                                        </Typography>
                                        <Typography
                                            className={styles.textMargin}
                                            variant={'RobotoRegular'}
                                            fontSize={12}
                                            textColor={'secondary'}
                                        >
                                            {values.returnFromBreak
                                                ? GetHoursAndMinutes(
                                                      values.returnFromBreak
                                                  )
                                                : '-'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <Typography
                                            className={styles.textMargin}
                                            variant={'RobotoBold'}
                                            fontSize={14}
                                            textColor={'primary'}
                                        >
                                            {t('HR.leave')}
                                        </Typography>
                                        <Typography
                                            className={styles.textMargin}
                                            variant={'RobotoRegular'}
                                            fontSize={12}
                                            textColor={'secondary'}
                                        >
                                            {values.leave
                                                ? GetHoursAndMinutes(
                                                      values.leave
                                                  )
                                                : '-'}
                                        </Typography>
                                    </Grid>
                                </>
                            )}
                            <Box width="100%" />
                            <Grid item xs={12} sm={8}>
                                <Input
                                    context={'view'}
                                    label={'GENERAL.observation'}
                                    value={values.observation}
                                    valueName={'observation'}
                                    rows={4}
                                    maxLength={200}
                                    handleChange={handleChange}
                                />
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={12} sm={8}>
                                <InputMultiDropzone
                                    context={'view'}
                                    files={files}
                                    setFiles={setFiles}
                                />
                            </Grid>
                            <Box width="100%" />
                            <div className={styles.buttonsWrapper}>
                                <Buttons
                                    onClick={handleCancel}
                                    tipo="BN-noback"
                                    conteudo={
                                        context !== 'view'
                                            ? t('GENERAL.refuse')
                                            : t('GENERAL.comeBack')
                                    }
                                />
                                {context !== 'view' &&
                                    values.status === 'PENDING' && (
                                        <Buttons
                                            onClick={() => {
                                                handleOpenDialog(
                                                    CollaboratorsJustificationsActions.APPROVE
                                                );
                                            }}
                                            tipo="BN-blue"
                                            conteudo={t(
                                                'HR.approveJustification'
                                            )}
                                        />
                                    )}
                            </div>
                        </Grid>
                    </Paper>
                    <DialogCollaboratorsJustifications
                        open={openDialog}
                        setOpen={setOpenDialog}
                        values={values}
                        dates={dates}
                        setValues={setValues}
                        dialogContext={dialogContext}
                        submit={handleSubmit}
                    />
                </ContentContainer>
                <Footer />
            </Transition>
        </React.Fragment>
    );
};

export default CollaboratorsJustificationsForm;
