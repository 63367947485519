import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import Footer from "components/layout/Footer";
import AllowancesContext from "context/allowance/allowanceContext";
import SearchContext from "context/search/searchContext";
import UsersContext from "context/users/usersContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { animated, useTransition } from "react-spring";
import Spinner from "../../layout/spinners/Index";
import MySnackbarContentWrapper from "../../layout/Message";
import { useStyles } from "components/layout/CommonStyles";
import TableActions from "./UsersToParticipateHandleActions";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import SearchExecution from "../SearchExecution";
import Toolbar from "@material-ui/core/Toolbar";
import Dissatisfied from "@material-ui/icons/SentimentVeryDissatisfied";

const UsersTable = props => {
  const [t] = useTranslation();
  const classes = useStyles();
  const { participants } = props;
  const usersContext = useContext(UsersContext);
  const allowanceContext = useContext(AllowancesContext);
  const searchContext = useContext(SearchContext);
  const {
    getAllUsers,
    allusers,
    loading,
    response,
    responseType,
    successiveActions,
    hasChanged,
    setHasChanged
  } = usersContext;
  const {
    allowanceResponse,
    allowanceResponseType,
    messageHasChanged,
    resetAllowanceMessage
  } = allowanceContext;

  const { filter } = searchContext;
  const [snackBar, setSnackBar] = useState({
    open: false,
    kind: "",
    content: ""
  });

  let dataFilter = [];

  useEffect(() => {
    getAllUsers();
    if (allowanceResponseType === "ERROR" && messageHasChanged) {
      handleSnack({ kind: "error", content: allowanceResponse });
    }
    if (responseType === "SUCCESS" && hasChanged > successiveActions) {
      handleSnack({ kind: "success", content: response });
    }
    if (responseType === "ERROR" && hasChanged > successiveActions) {
      handleSnack({ kind: "error", content: response });
    }
    return () => { };
  }, [
    responseType,
    response,
    successiveActions,
    allowanceResponseType,
    allowanceResponse
  ]);

  dataFilter = allusers;
  for (var i in participants) {
    dataFilter = dataFilter.filter(
      item => item.id !== participants[i].participantId
    );
  }

  if (filter) {
    dataFilter = dataFilter.filter(
      item =>
        item.name.toLowerCase().startsWith(filter.toLowerCase()) ||
        (item.function !== null &&
          item.function.toLowerCase().startsWith(filter.toLowerCase()))
    );
  }

  const handleSnack = () => {
    setSnackBar({ ...snackBar, open: true });
  };

  const handleSnackClose = () => {
    setSnackBar({ ...snackBar, open: false });
    setHasChanged();
    resetAllowanceMessage();
  };

  const transitions = useTransition(loading, null, {
    from: { opacity: 0 },
    enter: {
      opacity: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "calc(100vh - 70px)"
    },
    leave: { opacity: 0 }
  });

  return transitions.map(({ item, key, props }) =>
    item ? (
      <animated.div key={key} style={props}>
        <Spinner />;
      </animated.div>
    ) : (
        <animated.div key={key} style={props}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={snackBar.open}
            onClose={handleSnackClose}
            TransitionComponent={Slide}
            transitionDuration={{ enter: 500, exit: 500 }}
            ContentProps={{
              "aria-describedby": "message-id"
            }}
            autoHideDuration={2000}
          >
            <MySnackbarContentWrapper
              onClose={handleSnackClose}
              variant={
                responseType !== ""
                  ? responseType.toLowerCase()
                  : allowanceResponseType.toLowerCase()
              }
              message={
                <span id="message-id">
                  {response !== "" ? response : allowanceResponse}
                </span>
              }
            />
          </Snackbar>
          <div className="container">
            <Paper className={classes.root}>
              <div className={classes.header}>
                <Grid container item spacing={2} md={12}>
                  <Grid item xs={1}>
                    {t("Participantes").toUpperCase()}
                  </Grid>
                  <Grid item xs={11} >
                  </Grid>
                </Grid>
              </div>
              <Toolbar>
                <SearchExecution label="Procurar por colaborador" />
              </Toolbar>
              <TableActions
                rows={dataFilter}
                headers={["id", "imagePath", "name", "function", ""]}
                sortedFields={["", "", "name", "function"]}
                visibleHeaders={["", "#", "NOME", "FUNÇÃO"]}
                mainField="imagePath"
              />
              {allusers.length > 0 && filter && dataFilter.length === 0 && (
                <div className={classes.noParticipantsMessage}>
                  <div>
                    <Dissatisfied className={classes.notFoundIconStyle} />
                  </div>
                  <h2>{t("GENERAL.noResultsFound")}</h2>
                  <h5>{`Sua busca por "${filter}" não retornou resultados.`}</h5>
                </div>
              )}
            </Paper>
          </div>
          <Footer />
        </animated.div>
      )
  );
};

export default UsersTable;