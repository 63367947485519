import React from "react";
import "../../App.css";
import SideBar from "../layout/sidebar/Sidebar";
import DashboardNavBar from "../layout/DashboardNavBar";
import NewExternalUser from "./NewExternalUser";

const CallNewExternalUser = ({ match }) => {
  return (
    <div>
      <DashboardNavBar />
      <SideBar />
      <NewExternalUser id={match.params.id}/>
    </div>
  );
}

export default CallNewExternalUser;