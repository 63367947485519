import React from 'react';
import { buttonsStyles } from './Styles';

const SwitchOutlinedButton = ({
    icon1,
    icon2,
    isFirstSelected,
    setIsFirstSelected,
    width,
    height
}) => {
    const styles = buttonsStyles();
    return (
        <div
            className={styles.SwitchOutlinedButtonContainer}
            style={{
                width: width ? width : '70px',
                height: height ? height : '30px'
            }}
        >
            <button
                onClick={() => setIsFirstSelected(true)}
                style={{
                    background: isFirstSelected ? '#C3CCD1' : 'none',
                    border: 'none'
                }}
                className={styles.SwitchOutlinedButtonIconContainer}
            >
                {icon1}
            </button>
            <hr style={{ height: '75%' }} />
            <button
                onClick={() => setIsFirstSelected(false)}
                style={{
                    background: !isFirstSelected ? '#C3CCD1' : 'none',
                    border: 'none'
                }}
                className={styles.SwitchOutlinedButtonIconContainer}
            >
                {icon2}
            </button>
        </div>
    );
};

export default SwitchOutlinedButton;
