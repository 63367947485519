import { MenuItem, Paper } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import GetApp from "@material-ui/icons/GetApp";
import { useStyles } from "components/layout/CommonStyles";
import MySnackbarContentWrapper from "components/layout/Message";
import AllowancesContext from "context/allowance/allowanceContext";
import ProjectsContext from "context/projects/projectsContext";
import {
  AlignmentType,
  Document,
  HeadingLevel,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TextRun,
  VerticalAlign,
  WidthType
} from "docx";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { animated, useTransition } from "react-spring";

var fileDownload = require("js-file-download");

const tooltipTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#2c3e51"
    }
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: "white",
        fontSize: "1em",
        backgroundColor: "red",
        opacity: "0.7"
      }
    }
  }
});

const ViewGoals = props => {
  const classes = useStyles();
  const [t] = useTranslation();
  const { projectId } = props;

  const [snackBar, setSnackBar] = useState({
    open: false,
    kind: "",
    content: ""
  });
  const projectsContext = useContext(ProjectsContext);
  const allowanceContext = useContext(AllowancesContext);
  const [title, setTitle] = useState("GENERAL.requiredField");
  const [openTitle, setOpenTitle] = useState(false);
  const [seasonsArray, setSeasonsArray] = useState([]);
  const [gpf, setGpf] = useState("");
  const [projectName, setProjectName] = useState("");
  const [delay, setDelay] = useState(true);
  const goalRef = useRef(null);
  const [disabledItem, setDisabledItem] = useState(false);
  const [season, setSeason] = useState("");
  const [seasonId, setSeasonId] = useState(0);
  const [description, setDescription] = useState("");
  const [hasCooperationTerm, setHasCooperationTerm] = useState(false);

  const getId = season => {
    var id = 0;

    for (var i = 0; i < seasonsArray.length; i++) {
      if (seasonsArray[i].label === season) {
        id = seasonsArray[i].value;
        break;
      }
    }
    return id;
  };

  const createFile = () => {
    // Create document
    var descricao = document.getElementById("goal");
    const doc = new Document({
      styles: {
        paragraphStyles: [
          {
            id: "Heading1",
            name: "Heading 1",
            //basedOn: "Normal",
            //next: "Normal",
            //quickFormat: true,
            run: {
              size: 35,
              bold: true,
              color: "2c3e51"
            }
          },
          {
            id: "desc",
            name: "descGoals",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              size: 20
            },
            paragraph: {
              spacing: {
                before: 240,
                after: 120
              }
            }
          },
          {
            id: "Heading6",
            name: "Heading 6",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              size: 20,
              color: "2c3e51"
            },
            paragraph: {
              spacing: {
                before: 240,
                after: 120
              }
            }
          }
        ]
      }
    });

    const table = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  text: "  GPF: \n",
                  heading: HeadingLevel.HEADING_6
                }),
                new Paragraph({
                  text: "  " + gpf
                  //alignment: AlignmentType.CENTER,
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: "  Nome do projeto: \n",
                  heading: HeadingLevel.HEADING_6
                }),
                new Paragraph("  " + projectName)
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: "  Data de Início: \n",
                  heading: HeadingLevel.HEADING_6
                }),
                new Paragraph("  " + season.split("-")[0])
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: "  Data de Término: \n",
                  heading: HeadingLevel.HEADING_6
                }),
                new Paragraph("  " + season.split("-")[1])
              ]
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  text: "  Objetivos: \n",
                  heading: HeadingLevel.HEADING_6,
                  alignment: AlignmentType.CENTER
                }),
                new TextRun({
                  text: "     Objetivos: ",
                  bold: true
                })
              ],
              verticalAlign: VerticalAlign.CENTER
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: "    " + descricao.value,
                  style: "desc"
                })
              ],
              columnSpan: 3
            })
          ]
        })
      ],
      width: {
        size: 6000,
        type: WidthType.DXA
      },
      height: {
        size: 19000
      },
      columnWidths: [1500, 4000, 1500, 1700]
    });

    doc.addSection({
      children: [
        new Paragraph({
          text: "UNIVERSIDADE FEDERAL DO CEARÁ - UFC ",
          heading: HeadingLevel.HEADING_1,
          alignment: AlignmentType.CENTER
        }),
        new Paragraph({
          text: "LABORATÓRIO DE SISTEMAS E BANCO DE DADOS \n",
          heading: HeadingLevel.HEADING_1,
          alignment: AlignmentType.CENTER
        }),
        table
        //table2
      ]
    });

    Packer.toBuffer(doc).then(buffer => {
      fileDownload(buffer, projectName + "_objetivos.doc");
      //fs.writeFileSync("My Document.docx", buffer);
    });
  };

  const {
    allowanceResponse,
    allowanceResponseType,
    resetAllowanceMessage
  } = allowanceContext;

  const {
    project,
    loading,
    response,
    getProject,
    updateGoal,
    responseType,
    resetMessage,
    successiveActions
  } = projectsContext;

  function bootstrap() {
    var seasons = [];
    var aux = {};
    var general = {};
    for (var i in project.sgppdAddendum) {
      var item = project.sgppdAddendum[i];
      var startDate = new Date(item.startdate);
      startDate.setMinutes(startDate.getMinutes() + startDate.getTimezoneOffset())
      var endDate = new Date(item.enddate);
      endDate.setMinutes(endDate.getMinutes() + endDate.getTimezoneOffset())
      aux.value = item.goalsId;
      aux.label = startDate.toLocaleDateString() + " - " + endDate.toLocaleDateString();

      if (item.number === "GENERAL") {
        var startDateGeneral = new Date(project.information.startdate);
        startDateGeneral.setMinutes(startDateGeneral.getMinutes() + startDateGeneral.getTimezoneOffset())
        var endDateGeneral = new Date(project.information.enddate);
        endDateGeneral.setMinutes(endDateGeneral.getMinutes() + endDateGeneral.getTimezoneOffset())
        aux.value = item.goalsId;
        aux.label = startDateGeneral.toLocaleDateString() + " - " + endDateGeneral.toLocaleDateString();
        general = aux;
        aux.label = aux.label + " (GERAL)";
      } else {
        seasons.push(aux);
      }
      aux = {};
    }
    seasons.push(general);
    setSeasonsArray(seasons);
  }

  const handleChange = event => {
    setOpenTitle(false);
    setDescription(event.target.value);
  };
  const verifyCanSubmit = () => {
    if (description === null || description.length === 0) {
      setTitle("GENERAL.requiredField");
      setOpenTitle(true);
      return false;
    } else {
      setOpenTitle(false);
      return true;
    }
  };

  const onClickSubmit = async () => {
    var canSubmit = verifyCanSubmit();
    if (canSubmit) {
      var aux = [];
      aux.id = seasonId;
      aux.goalsDescription = description;
      updateGoal(aux);
    }
  };

  const handleChangeSeason = event => {
    var id = getId(event.target.value);
    var addendum = getAddendumById(id, project.sgppdAddendum);
    setSeason(event.target.value);
    setSeasonId(id);
    if (addendum.goals !== null) {
      setDescription(
        addendum.goals.goalsDescription !== null
          ? addendum.goals.goalsDescription
          : ""
      );
    } else {
      setDescription("");
    }
  };

  const getAddendumById = (id, addendums) => {
    for (var i = 0; i < addendums.length; i++) {
      if (id === addendums[i].goalsId) {
        return addendums[i];
      }
    }
    return null;
  };

  const handleSnack = () => {
    setSnackBar({ ...snackBar, open: true });
  };

  const handleSnackClose = () => {
    setSnackBar({ ...snackBar, open: false });
    resetAllowanceMessage();
  };

  useEffect(() => {
    getProject(projectId);

    const timer = setTimeout(() => {
      if (responseType === "SUCCESS") {
        handleSnack({ kind: "success", content: response });
      } else {
      }
      if (responseType === "ERROR") {
        handleSnack({ kind: "error", content: response });
      }
      setDelay(false);
    }, 700);

    return () => {
      clearTimeout(timer);
    };
  }, [responseType, response, successiveActions]);

  useEffect(() => {
    var addendum = null;
    getProject(projectId);
    if (Object.keys(project).length > 0) {
      bootstrap();
      setProjectName(project.information.name);
      if (project.sgppdAddendum.length > 0) {
        setHasCooperationTerm(true)
        for (var i = 0; i < project.sgppdAddendum.length; i++) {
          if (project.sgppdAddendum[i].gpf) {
            setGpf(project.sgppdAddendum[i].gpf);
          }
        }
      }
    }

    if (
      seasonsArray !== null &&
      seasonsArray !== undefined &&
      seasonsArray.length !== 0
    ) {
      if (seasonsArray.length === 1) {
        setSeason(seasonsArray[0].label);
        setSeasonId(seasonsArray[0].value);
        // not working if get from state
        addendum = getAddendumById(
          seasonsArray[0].value,
          project.sgppdAddendum
        );
      } else {
        var length = seasonsArray.length - 2;
        setSeason(seasonsArray[length].label);
        setSeasonId(seasonsArray[length].value);
        // not working if get from state
        addendum = getAddendumById(
          seasonsArray[length].value,
          project.sgppdAddendum
        );
      }

      if (addendum !== null) {
        if (addendum.goals !== null) {
          setDescription(
            addendum.goals.goalsDescription
              ? addendum.goals.goalsDescription
              : ""
          );
        } else {
          setDescription("");
        }
      }
    }
    return () => { };
  }, [Object.keys(seasonsArray).length, JSON.stringify(project)]);

  // SOLUÇÃO FINAL PARA EVITAR RENDERIZAR MENSAGENS AO TROCAR DE COMPONENT
  useEffect(() => {
    return () => {
      resetMessage();
    };
  }, []);

  const transitions = useTransition(loading || delay, null, {
    from: { opacity: 0 },
    enter: {
      opacity: 1
    },
    leave: { opacity: 0 }
  });

  return transitions.map(({ item, key, props }) =>
    item ? (
      <animated.div key={key} style={props}>
        <></>
      </animated.div>
    ) : (
        <animated.div key={key} style={props}>
          <Paper className={classes.root}>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={snackBar.open}
              onClose={handleSnackClose}
              TransitionComponent={Slide}
              transitionDuration={{ enter: 500, exit: 500 }}
              ContentProps={{
                "aria-describedby": "message-id"
              }}
              autoHideDuration={2000}
            >
              <MySnackbarContentWrapper
                onClose={handleSnackClose}
                variant={
                  responseType !== ""
                    ? responseType.toLowerCase()
                    : allowanceResponseType.toLowerCase()
                }
                message={
                  <span id="message-id">
                    {response !== "" ? response : allowanceResponse}
                  </span>
                }
              />
            </Snackbar>
            <div className={classes.header}>
              {t("GOALS.goal").toUpperCase()}
              {hasCooperationTerm &&
              <Tooltip
                title={t("GENERAL.downloadDocument")}
                aria-label="details"
                placement="top"
                onClick={createFile}
              >
                <IconButton>
                  <GetApp />
                </IconButton>
              </Tooltip>}
            </div>
            <div></div>
            {hasCooperationTerm ?
              <div className={classes.bodyForm}>
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <TextField
                      disabled
                      className={classes.textField}
                      label="GPF"
                      value={gpf}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      disabled
                      className={classes.textField}
                      label={t("PROJECTS.projectName")}
                      value={projectName}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      select
                      className={classes.textField}
                      margin="normal"
                      disabled={disabledItem}
                      id="seasonText"
                      label={t("PROJECTS.season")}
                      name={season}
                      value={season}
                      onChange={handleChangeSeason}
                    >
                      {seasonsArray.map(option => (
                        <MenuItem key={option.value} value={option.label}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <MuiThemeProvider theme={tooltipTheme}>
                      <Tooltip
                        TransitionComponent={Zoom}
                        open={openTitle}
                        title={t(title)}
                      >
                        <TextField
                          disabled
                          inputRef={goalRef}
                          multiline
                          fullWidth
                          variant="outlined"
                          rows="6"
                          id="goal"
                          value={description}
                          error={openTitle}
                          label={t("GOALS.goals")}
                          margin="normal"
                          onChange={handleChange}
                        />
                      </Tooltip>
                    </MuiThemeProvider>
                  </Grid>
                </Grid>
              </div>
              :
              <p className={classes.Hint}>
                {t("PHASES.hintNoAddendum")}
              </p>
            }
          </Paper>
        </animated.div>
      )
  );
};

export default ViewGoals;
