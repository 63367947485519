import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import {
    StyledTableCell,
    StyledTableRow,
    useStyles
  } from "components/profiles/ProfileStyles";

const MainStatus = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const { qtdDis, qtdIndis } = props;
    var amountAvailable;
    if (qtdDis) {
        amountAvailable = qtdDis.filter(
            item =>
                item.disableItem === false
        );
    }
    
    var amountUnavailable = 0;
    for(var i = 0; i < qtdIndis.length; i++ ){
        amountUnavailable += qtdIndis[i].itensQtd
    }

    const [rows, setRows] = useState([{status: "Indisponivel", description: "-", qtd: amountUnavailable},
    {status: "Disponivel", description: "-", qtd: amountAvailable[0].itensQtd}]);
    const [headers, setHeaders] = useState(["status", "description", "qtd"]);
    const [visibleHeaders, setVisibleHeaders] = useState(["Status", "Descrição", "Quantidade de Itens"]);

    function createContent(rows, headers, order, orderBy) {
        const rowsCopy = [];
        var obj = {};
        for (var i = 0; i < rows.length; i++) {
          for (var j = 0; j < headers.length; j++) {
            obj[headers[j]] = rows[i][headers[j]];
          }
          rowsCopy.push(obj);
          obj = {};
        }
        return rowsCopy;
      }

    return (
        <React.Fragment>
            <div className="cabecalho">
                {t("INVENTORY.mainStatus").toUpperCase()}
            </div>
            <Paper className={classes.root}>
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} size="small">
                    <TableHead>
                        <TableRow>
                        {visibleHeaders.map((header, index) => (
                            <StyledTableCell
                            key={index}
                            size="small"
                            align="left"
                            >
                            <TableSortLabel
                            >
                                {header.toUpperCase()}
                            </TableSortLabel>
                            </StyledTableCell>
                        ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {createContent(rows, headers)
                        //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map(row => (
                            <StyledTableRow key={row.id}>
                                <TableCell size="medium" >
                                    {row["status"]}
                                </TableCell>
                                <TableCell size="smamediumll" >
                                    {row["description"] ? row["description"] :  "-"}
                                </TableCell>
                                <TableCell size="medium" >
                                    {row["qtd"] ? row["qtd"] :  "0"}
                                </TableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                    </Table>
                </div>
            </Paper>
        </React.Fragment>
    )
};

export default MainStatus;