/* eslint-disable */
import { makeStyles, withStyles } from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Step from "@material-ui/core/Step";
import StepConnector from "@material-ui/core/StepConnector";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import Typography from "@material-ui/core/Typography";
import Next from "@material-ui/icons/ArrowForwardIosOutlined";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import clsx from "clsx";
import { useStyles } from "components/layout/CommonStyles";
import DashboardNavBar from "components/layout/DashboardNavBar";
import Footer from "components/layout/Footer";
import SideBar from "components/layout/sidebar/Sidebar";
import Spinner from "components/layout/spinners/Index";
import ViewBudget from "components/Projects/ViewProject/ViewBudget";
import ViewGoals from "components/Projects/ViewProject/ViewGoals";
import ViewScope from "components/Projects/ViewProject/ViewScope";
import ViewHeading from "components/Projects/ViewProject/ViewHeadings";
import ViewSchedule from "components/Projects/ViewProject/ViewSchedule";
import ViewCooperationTerm from "components/Projects/ViewProject/ViewCooperationTerm";
import AllowancesContext from "context/allowance/allowanceContext";
import ProjectsContext from "context/projects/projectsContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import ViewGeneralInformations from "./ViewGeneralInformation";

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 30 // metade do tamanho da bola pra fazer a linha ficar no centro
  },
  active: {
    "& $line": {
      // backgroundColor: "#3fe2d3"
      backgroundColor: "#2c3e51"
    }
  },
  completed: {
    "& $line": {
      // backgroundColor: "#3fe2d3"
      backgroundColor: "#2c3e51"
    }
  },

  line: {
    height: 5,
    border: 0,
    backgroundColor: "#dddddd",
    borderRadius: 1
  }
})(StepConnector);

const customStepsStyles = makeStyles({
  root: {
    //backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 60,
    height: 60,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#2c3e51",
    "&:hover": {
      //backgroundColor: "#2c3e51",
    }
  },
  active: {
    backgroundColor: "#3fe2d3",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)"
  },
  completed: {
    backgroundColor: "#2c3e51"
  },
  disabled: {
    backgroundColor: "#dddddd"
  }
});

function getDiff(select, notSelect) {
  let r1 = select;
  let r2 = notSelect;       
  let r3 = r2.filter( a => !r1.includes(a));

  return r3;
}

function getDiff2(select, notSelect) {
  let r1 = select;
  let r2 = notSelect;       
  let r3 = r2.filter( a => r1.includes(a));

  return r3;
}

var cont = 0
var countScreen = 7
function customStep(props) {
  const classes = customStepsStyles();
  const { active, completed, hasCooperationTerm, icon, steepsPermission } = props;
  // podemos fazer um switch em props.icons para selecionar
  // icones relacionados ao passos
  const checkIfIconShouldBeDisabled = () => {
    if(steepsPermission.length === cont){
      cont = 0
    }
    if (steepsPermission.length > 0){
      if (hasCooperationTerm){
        countScreen = 7
        if (icon === steepsPermission[cont]){
          cont = cont +1;
          return false;
        }else{
          return true;
        } 
    }else{
      var steeps = [1,2,3,4,5,6,7];
      var diff = getDiff(steepsPermission,steeps);
      if(icon === diff[0] || icon === diff[1] ){
        return true;
      }else{
        if (icon > 2) {
          countScreen = 2;
          return true;
        }else return false;
      }
    }
  } 
};

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.disabled]: checkIfIconShouldBeDisabled(),
        [classes.completed]: completed
      })}
    >
      {props.icon}
    </div>
  );
}
var position = 1;

function ViewProject({ match }) {
  const projectsContext = useContext(ProjectsContext);
  const allowanceContext = useContext(AllowancesContext);

  const { project, loading, getProject, resetMessage } = projectsContext;

  const {
    allowanceResponse,
    allowanceResponseType,
    resetAllowanceMessage,
    allAllowances,
    getAllPermission
  } = allowanceContext;

  var steepsPermission = []
  const getPermission = (screen, allowance) => {
    for (let i = 0; i < allAllowances.length; i++) {
      if (allAllowances[i].resource === screen) {
        return allAllowances[i]["standardauthorizationoption"][allowance]
      }
    }
  }

  const setPassosRigth =()=>{
    if(allAllowances.length > 0 && getPermission("GENERALINFORMATION","allowRead")){
      steepsPermission.push(1)
    }
    if(allAllowances.length > 0 && getPermission("COOPERATIONTERM","allowRead")){
      steepsPermission.push(2)
    }
    if(allAllowances.length > 0 && getPermission("GOAL","allowRead")){
      steepsPermission.push(3)
    }
    if(allAllowances.length > 0 && getPermission("SCOPE","allowRead")){
      steepsPermission.push(4)
    }
    if(allAllowances.length > 0 && getPermission("HEADINGS2","allowRead")){
      steepsPermission.push(5)
    }
    if(allAllowances.length > 0 && getPermission("SCHEDULE","allowRead")){
      steepsPermission.push(6)
    }
    if(allAllowances.length > 0 && getPermission("BUBGETPLAN","allowRead")){
      steepsPermission.push(7)
    }
  }
  setPassosRigth()
  
  const [activeStep, setActiveStep] = useState(0);
  const [t] = useTranslation();
  const classes = useStyles();
  const [tela, setTela] = useState("/");
  const [here, setHere] = useState(true);
  const [hasCooperationTerm, setHasCooperationTerm] = useState(false);

  const steps = [
    t("PROJECTS.step.generalInformations"),
    t("PROJECTS.step.cooperationTerm"),
    t("PROJECTS.step.goal"),
    t("PROJECTS.step.scope"),
    t("PROJECTS.step.headings"),
    t("PROJECTS.step.schedule"),
    t("PROJECTS.step.budget")
  ];

  useEffect(() => {
    getProject(match.params.id);
    if (project.sgppdAddendum) {
      project.sgppdAddendum.length > 0
        ? setHasCooperationTerm(true)
        : setHasCooperationTerm(false);
    }

    return () => { };
  }, [JSON.stringify(project)]);

  React.useEffect(() => {
    getAllPermission()
    if(steepsPermission.length > 0){
      setActiveStep(steepsPermission[0]-1)
      setTela(steepsPermission[0])
    }
  }, [allAllowances.length, JSON.stringify(allAllowances), JSON.stringify(steepsPermission).length]);

  const transitions = useTransition(false, null, {
    from: { opacity: 1 },
    enter: {
      opacity: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "calc(100vh - 70px)"
    },
    leave: { opacity: 1 }
  });

  const handleNext = () => {
    resetMessage();
    position = position + 1;
    
    if(steepsPermission.length > 0){
      if(!steepsPermission.includes(position)){
        while(!steepsPermission.includes(position)){
          position = position + 1;
          if(position > 7){
            break
          }
        }
      }
    }

    var steeps = [1,2,3,4,5,6,7];
    var diff = getDiff2(steepsPermission,steeps);
   
    countScreen = diff[diff.length-1]
    if (position < countScreen) {
      setTela(position);
      setHere(false);
      setActiveStep(position-1);
    } else if ((position === countScreen)) {
      setTela(position);
      setHere(false);
      setActiveStep(position-1);
      document.getElementById("next").style.display = "none";
    } else {
      document.getElementById("next").style.display = "none";
    }
  };
  // RENDER THE COMPONENTS - NOTE: GENERAL INFORMATIONS IS THE SCREEN INITIAL FOR EDITION, IF THE "OR" IS USED.
  const renderRedirect = stepNumber => {
    if (stepNumber === 1 || here === true && (allAllowances.length > 0 && getPermission("GENERALINFORMATION","allowRead")))  {
      return (
        <ViewGeneralInformations
          projectId={match.params.id}
          project={project}
        />
      );
    }
    if (stepNumber === 2 && !hasCooperationTerm && (allAllowances.length > 0 && getPermission("COOPERATIONTERM","allowRead"))) {
      document.getElementById("next").style.display = "none";
      return <ViewCooperationTerm projectId={match.params.id} />;
    }
    if (stepNumber === 2 && hasCooperationTerm && (allAllowances.length > 0 && getPermission("COOPERATIONTERM","allowRead"))) {
      return <ViewCooperationTerm projectId={match.params.id} />;
    } 
    else if (stepNumber === 3 && (allAllowances.length > 0 && getPermission("GOAL","allowRead"))) {
      return <ViewGoals projectId={match.params.id} />;
    } else if (stepNumber === 4 && (allAllowances.length > 0 && getPermission("SCOPE","allowRead"))) {
      return <ViewScope projectId={match.params.id} />;
    } else if (stepNumber === 5 && (allAllowances.length > 0 && getPermission("HEADINGS2","allowRead"))) {
      return <ViewHeading projectId={match.params.id} />;
    } else if (stepNumber === 6 && (allAllowances.length > 0 && getPermission("SCHEDULE","allowRead"))) {
      return <ViewSchedule projectId={match.params.id} />;
    } else if (stepNumber === 7 && (allAllowances.length > 0 && getPermission("BUBGETPLAN","allowRead"))) {
      return <ViewBudget projectId={match.params.id} />;
    }
  };

  //SEE  WHICH SCREEN WAS CLICKED AND SET STEP NUMBER
  const Screen = label => {
    var steeps = [1,2,3,4,5,6,7];
    var diff = getDiff2(steepsPermission,steeps);
    countScreen = diff[diff.length-1]
    if (label === t("PROJECTS.step.generalInformations") && !loading
    && (allAllowances.length > 0 && getPermission("GENERALINFORMATION","allowRead"))
    ) {
      setTela(1);
      position = 1;
      setActiveStep(0);
      document.getElementById("next").style.display = "flex";
      setHere(true);
    } else if (label === t("PROJECTS.step.cooperationTerm") && !loading
    && (allAllowances.length > 0 && getPermission("COOPERATIONTERM","allowRead"))) {
      setTela(2);
      if(countScreen !== 2){
        document.getElementById("next").style.display = "flex";
      }
      position = 2;
      setActiveStep(1);
      setHere(false);
    } else if (
      label === t("PROJECTS.step.goal") &&
      !loading &&
      hasCooperationTerm
      && (allAllowances.length > 0 && getPermission("GOAL","allowRead"))
    ) {
      setTela(3);
      position = 3;
      setActiveStep(2);
      if((position === countScreen)){
        document.getElementById("next").style.display = "none";
      }else{
        document.getElementById("next").style.display = "flex";
      }
      setHere(false);
    } else if (
      label === t("PROJECTS.step.scope") &&
      !loading &&
      hasCooperationTerm
      && (allAllowances.length > 0 && getPermission("SCOPE","allowRead"))
    ) {
      setTela(4);
      //resetMessage();
      position = 4;
      setActiveStep(3);
      if((position === countScreen)){
        document.getElementById("next").style.display = "none";
      }else{
        document.getElementById("next").style.display = "flex";
      }
      setHere(false);
    } else if (
      label === t("PROJECTS.step.headings") &&
      !loading &&
      hasCooperationTerm
      && (allAllowances.length > 0 && getPermission("HEADINGS2","allowRead"))
    ) {
      setTela(5);
      //resetMessage();
      position = 5;
      setActiveStep(4);
      if((position === countScreen)){
        document.getElementById("next").style.display = "none";
      }else{
        document.getElementById("next").style.display = "flex";
      }
      setHere(false);
    } else if (
      label === t("PROJECTS.step.schedule") &&
      !loading &&
      hasCooperationTerm
      && (allAllowances.length > 0 && getPermission("SCHEDULE","allowRead"))
    ) {
      setTela(6);
      //resetMessage();
      position = 6;
      setActiveStep(5);
      if((position === countScreen)){
        document.getElementById("next").style.display = "none";
      }else{
        document.getElementById("next").style.display = "flex";
      }
      setHere(false);
    } else if (
      label === t("PROJECTS.step.budget") &&
      !loading &&
      hasCooperationTerm
      && (allAllowances.length > 0 && getPermission("BUBGETPLAN","allowRead"))
    ) {
      setTela(7);
      //resetMessage();
      position = 7;
      setActiveStep(6);
      document.getElementById("next").style.display = "none";
      setHere(false);
    }

  };

  return transitions.map(({ item, key, props }) =>
    item ? (
      <animated.div key={key} style={props}>
        <Spinner />
      </animated.div>
    ) : (
        <div key={key}>
          <DashboardNavBar />
          <SideBar />
          <animated.div style={props}>
            <div className="container">
              <Breadcrumbs
                className={classes.breadCrumbs}
                aria-label="breadcrumb"
                separator={<NavigateNextIcon fontSize="small" />}
              >
                <Typography>{t("GENERAL.planning")}</Typography>
                <Typography>{t("PROFILES.form.workPlan")}</Typography>
                <Link className={classes.link} to="/projects">
                  {t("PROJECTS.projects")}
                </Link>
                <Typography>{t("GENERAL.view")}</Typography>
              </Breadcrumbs>
              <div className="container-header">
                <div className="title">
                  <h1 className={classes.title}>{t("PROJECTS.viewProject")}</h1>
                </div>
                <Button
                  className={classes.blueButton}
                  onClick={handleNext}
                  variant="contained"
                  color="primary"
                  id="next"
                >
                  <div className={classes.buttonIcon}>
                    <Next />
                  </div>
                  {t("GENERAL.nextButton").toUpperCase()}
                </Button>
              </div>
              <div style={{ paddingBottom: "35px" }}>
                <Paper className={classes.root}>
                  <div className={classes.header}>
                    {t("GENERAL.progress").toUpperCase()}
                  </div>
                  <div>
                    <Stepper
                      activeStep={activeStep}
                      alternativeLabel
                      connector={<ColorlibConnector />}
                    >
                     
                      {steps.map(label => (
                        <Step key={label} className={classes.stepNumber}>
                          <StepLabel
                            style={{
                              cursor: loading ? "not-allowed" : "pointer"
                            }}
                            StepIconComponent={customStep}
                            StepIconProps={{
                              hasCooperationTerm,
                              steepsPermission
                            }}
                            onClick={() => {
                              Screen(label);
                            }}
                          >
                            {label}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </div>
                </Paper>
              </div>
              {renderRedirect(tela)}
            </div>
            <Footer />
          </animated.div>
        </div>
      )
  );
}

export default ViewProject;
