import React, { useState, useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import Buttons from "components/buttons/Pressed";
import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const DialogStatus = (props) => {
    const [t] = useTranslation();
    const classes = useStyles();
    const { open, setOpen, currentValue = "-", allStatus, value, nameValue, description, handleUpdate, type = "Add" } = props;
    const [statusUnavailable, setStatusUnavailable] = useState([]);
    const [available, setAvailable] = useState({ value: "", label: "" });
    const [radioValue, setRadioValue] = useState("Disponível");
    const [values, setValues] = useState({ statusId: "", statusName: "", statusDescription: "" });

    const handleClose = () => {
        setValues({ statusId: value, statusName: nameValue, statusDescription: description });
        if (value === available.value) {
            setRadioValue("Disponível");
        } else {
            setRadioValue("Indisponível");
        }
        setOpen(false);
    };

    const handleSubmit = () => {
        handleUpdate(values.statusId, values.statusName, values.statusDescription);
        setOpen(false);
    }

    useEffect(() => {
        if (available && available.value) {
            if (type === "Add") {
                handleUpdate(available.value, available.label, description);
            }
            setValues({ ...values, statusId: available.value, statusName: available.label });
        }
    }, [available]);

    useEffect(() => {
        if (allStatus && allStatus.length > 0) {
            const status = [...allStatus];
            sortElement(status);

            const _statusU = status.filter(x => x.disableItem).map(s => {
                return { value: s.id, label: s.name }
            });

            setStatusUnavailable(_statusU);

            const _statusA = status.find(x => !x.disableItem);

            if (_statusA) {
                setAvailable({ value: _statusA.id, label: _statusA.name });
            }
        }
    }, [allStatus]);

    const sortElement = (elements) => {
        elements.sort(function (a, b) {
            if (a.name === null) a.name = "";
            if (b.name === null) b.name = "";

            if (a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() >
                b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim()) {
                return 1;
            }
            if (a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() <
                b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim()) {
                return -1;
            }
            // a must be equal to b
            return 0;
        });
    }

    const handleChangeRadio = (event) => {
        if (event.target.value === "Disponível") {
            handleChange("statusId", available.value);
        } else {
            let unavailable = "";
            const valDefault = statusUnavailable.find(x => x.label === "Indisponível");

            if (valDefault) {
                unavailable = valDefault.value;
            } else if (statusUnavailable[0]) {
                unavailable = statusUnavailable[0].value;
            }

            handleChange("statusId", unavailable);
        }

        setRadioValue(event.target.value);
    };

    const handleChange = (name, value) => {
        if (name === "statusId") {
            const status = allStatus.find(x => x.id === value);
            if (status) {
                setValues({ ...values, statusId: value, statusName: status.name });
            }
        } else {
            setValues({ ...values, [name]: value });
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            aria-labelledby="max-width-dialog-title"
            aria-labelledby="form-dialog-title"
            msg="mensagem"
            maxWidth="sm"
        >
            <DialogTitle className={classes.actionsTitle} style={{ width: 'auto' }}>
                <span style={{ fontWeight: 'bold' }}>
                    {t("INVENTORY.changeStatus")}
                </span>
                <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Grid container style={{ paddingTop: "20px" }}>
                        <Grid item xs={7} sm={7}>
                            <TextField
                                style={{ marginTop: "-16px" }}
                                label={t("INVENTORY.currentStatus")}
                                margin="normal"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput
                                }}
                                value={currentValue}
                                className={classes.textField}
                                inputProps={{ "aria-label": "currentValue" }}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <FormControl component="fieldset" style={{ marginTop: 16 }}>
                                <FormLabel component="legend">{t("INVENTORY.newStatus")}</FormLabel>
                                <RadioGroup row aria-label="position"
                                    name="position"
                                    defaultValue="top"
                                    value={radioValue}
                                    onChange={handleChangeRadio}
                                >
                                    <FormControlLabel
                                        value="Disponível"
                                        control={<Radio color="primary" />}
                                        label="Disponível"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="Indisponível"
                                        control={<Radio color="primary" />}
                                        label="Indisponível"
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            {radioValue === "Indisponível" &&
                                <TextField
                                    select
                                    label={t("INVENTORY.unavailableStatus")}
                                    name="statusId"
                                    className={classes.textField}
                                    value={values.statusId}
                                    onChange={(ev) => {
                                        handleChange("statusId", ev.target.value)
                                    }}
                                    margin="normal"
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput
                                    }}
                                    inputProps={{ "aria-label": "search" }}
                                >
                                    {statusUnavailable.map(status => (
                                        <MenuItem key={status.value} value={status.value}>
                                            {status.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            }
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                multiline
                                fullWidth
                                variant="outlined"
                                rows="4"
                                value={values.statusDescription}
                                onChange={(ev) => { if (ev.target.value.length <= 200) handleChange("statusDescription", ev.target.value) }}
                                label={t("INVENTORY.descriptionBookCategory")}
                                margin="normal"
                            />
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Buttons
                    onClick={handleClose}
                    tipo="BN-noback"
                    conteudo={t("GENERAL.cancel").toLocaleUpperCase()}
                />
                <Buttons
                    onClick={handleSubmit}
                    tipo="BN-blue"
                    conteudo={t("GENERAL.confirm").toLocaleUpperCase()}
                />
            </DialogActions>
        </Dialog>
    );
}

export default DialogStatus;