import axios from 'axios';

export const getQuotaManagersAndSendEmails = async (
    emailData,
    endpoint,
    notificationPermission
) => {
    axios
        .get(
            process.env.REACT_APP_SERVER +
                'api/EmailUsers/' +
                notificationPermission,
            {
                headers: {
                    'Content-type': 'application/json'
                    // "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
                }
            }
        )
        .then(response => {
            emailData.quotaManagers = response.data;
            sendEmails(emailData, endpoint);
        })
        .catch(error => {
            console.log(error);
        });
};

export const sendEmails = async (emailData, endpoint) => {
    return axios
        .post(process.env.REACT_APP_EMAIL + endpoint, emailData, {
            headers: {
                'Content-type': 'application/json'
            }
        })
        .then(
            res => console.log('ok'),
            err => Promise.reject('error')
        );
};
