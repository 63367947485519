import DateFnsUtils from "@date-io/date-fns";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Buttons from "components/buttons/Pressed";
import { useStyles } from "components/layout/CommonStyles";
import Footer from "components/layout/Footer";
import MySnackbarContentWrapper from "components/layout/Message";
import Spinner from "components/layout/spinners/Index";
import ProjectsContext from "context/projects/projectsContext";
import moment from "moment";
import "moment/locale/pt-br";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import "../profiles/css/FormStyle.css";
import ProjectTabPanel from "./ProjectTabPanel";
import AllowancesContext from "context/allowance/allowanceContext";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";

const ExecutionEditProject = props => {
  const { id, mode } = props;
  const classes = useStyles();
  const [t] = useTranslation();
  const [seasonId, setSeasonId] = useState(0);
  const [open, setOpen] = useState(false);
  const [openNotice, setOpenWithNotice] = useState(false);
  const [seasonsArray, setSeasonsArray] = useState([]);
  const [seasonsArrayReports, setSeasonsArrayReports] = useState([]);
  const [seasonsGeneral, setSeasonsGeneral] = useState([]);
  const [projectSubsides, setProjectSubsides] = useState([]);
  const [periodFilter, setPeriodFilter] = useState([]);
  const [rubricFilter, setRubricFilter] = useState([
    { value: "ALL", label: "Todas" },
    { value: "DIRECT_HR", label: "RH Direto" },
    { value: "INDIRECT_HR", label: "RH Indireto" },
    { value: "EQUIPAMENTS", label: "Equipamentos" },
    { value: "CONSUMABLES", label: "Material de Consumo" },
    { value: "BOOKS_AND_PERIODICALS", label: "Livros e Periódicos" },
    { value: "TRIPS", label: "Viagens" },
    { value: "INCURRED_COSTS", label: "Custos Incorridos" }
  ]);
  const [dataReports, setDataReports] = useState([]);
  const projectsContext = useContext(ProjectsContext);
  const allowanceContext = useContext(AllowancesContext);

  const {
    loading,
    project,
    getProject,
    participants,
    getParticipants,
    phases,
    getPhases,
    resetMessage,
    resetDeleteMessage,
    responseDeleteType,
    responseType,
    response,
    responseDelete,
    successiveActions,
    resetProject,
    setActionMode,
    getExpenses,
    expenses,
    FinishProject,
    hasChanged,
    setHasChanged,
    costByProject,
    getCostbyGpf,
    setSubmission,
  } = projectsContext;

  const {
    allowanceResponse,
    allowanceResponseType,
    resetAllowanceMessage,
    allAllowances,
    getAllPermission
  } = allowanceContext;

  const [state, setState] = useState({
    name: "",
    gpf: "",
    startDate: "",
    endDate: ""
  });

  const [snackBar, setSnackBar] = useState({
    open: false,
    kind: "",
    content: ""
  });

  const handleSnack = () => {
    setSnackBar({ ...snackBar, open: true });
  };

  const handleSnackClose = () => {
    setSnackBar({ ...snackBar, open: false });
  };

  const [snackBarFinish, setSnackBarFinish] = useState({
    open: false,
    kind: "",
    content: ""
  });

  const handleSnackFinish = () => {
    setSnackBarFinish({ ...snackBarFinish, open: true });
  };

  const handleSnackCloseFinish = () => {
    setSnackBarFinish({ ...snackBarFinish, open: false });
    setHasChanged();
    //resetAllowanceMessage();
  };

  useEffect(() => {
    resetProject();
    if (responseDelete !== t("PROJECTS.cantBeDeleted")) {
      if (responseDeleteType === "SUCCESS") {
        handleSnack({ kind: "success", content: responseDelete });
      }
      if (responseDeleteType === "ERROR") {
        handleSnack({ kind: "error", content: responseDelete });
      }
    }
    return () => { };
  }, [responseDeleteType, responseDelete, successiveActions]);

  useEffect(() => {
    if (responseType === "SUCCESS" && hasChanged > successiveActions) {
      handleSnackFinish({ kind: "success", content: response });
    }
    if (responseType === "ERROR" && hasChanged > successiveActions) {
      handleSnackFinish({ kind: "error", content: response });
    }
    return () => { };
  }, [responseType, response, successiveActions]);

  React.useEffect(() => {
    getAllPermission()
  }, [allAllowances.length, JSON.stringify(allAllowances)]);

  const transitions = useTransition(loading, null, {
    from: { opacity: 0 },
    enter: {
      opacity: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "calc(100vh - 80px)"
    },
    leave: { opacity: 0 }
  });

  useEffect(() => {
    setSubmission(false);
    setActionMode(mode);
    getProject(id);
    getParticipants(id);
    getPhases(id);
    getExpenses(id);
    getCostbyGpf(id);

    if (project.information) {
      var _seasonsArray = [];
      var _seasonsArrayReports = [];
      var _seasonGeneral = []
      var general = {};
      var aux = {};
      var auxSeasonGeneral = {};
      var auxReports = {};

      for (var i = 0; i < project.sgppdAddendum.length; i++) {
        var item = project.sgppdAddendum[i];
        var startDate = new Date(item.startdate);
        startDate.setMinutes(startDate.getMinutes() + startDate.getTimezoneOffset())
        var endDate = new Date(item.enddate);
        endDate.setMinutes(endDate.getMinutes() + endDate.getTimezoneOffset())
        var startDateFormated = startDate.getDate() + "/" + (startDate.getMonth() + 1) + "/" + startDate.getFullYear();
        var endDateFormated = endDate.getDate() + "/" + (endDate.getMonth() + 1) + "/" + endDate.getFullYear();
        //var startDate = new Date(item.startdate).toLocaleDateString();
        //var endDate = new Date(item.enddate).toLocaleDateString();

        aux.value = item.id;
        aux.label = startDateFormated + " - " + endDateFormated;
        aux.startDate = item.startdate;
        aux.endDate = item.enddate;


        //var startdate = new Date(item.startdate)
        var dayStart = startDate.getDate()
        var monthStart = (startDate.getMonth() + 1)
        if (startDate.getDate() < 10) {
          dayStart = "0" + startDate.getDate()
        }
        if ((startDate.getMonth() + 1) < 10) {
          monthStart = "0" + (startDate.getMonth() + 1)
        }
        var startDateFormated = dayStart + "/" + monthStart + "/" + startDate.getFullYear()

        auxReports.value = item.id
        auxReports.label = startDateFormated
        auxReports.startDate = startDate;
        auxReports.endDate = endDate;

        if (item.number === "GENERAL") {
          general = aux;
          aux.label = aux.label + " (GERAL)";
        } else {
          _seasonsArray.push(aux);
          _seasonsArrayReports.push(auxReports)

        }
        auxReports = {};
        aux = {};
      }

      _seasonsArray.push(general);
      setSeasonId(_seasonsArray[_seasonsArray.length - 1].value);
      setSeasonsArray(_seasonsArray);
      setSeasonsArrayReports(_seasonsArrayReports);

      var startDateGeneral = new Date(project.information.startdate);
      startDateGeneral.setMinutes(startDateGeneral.getMinutes() + startDateGeneral.getTimezoneOffset())
      var endDateGeneral = new Date(project.information.enddate);
      endDateGeneral.setMinutes(endDateGeneral.getMinutes() + endDateGeneral.getTimezoneOffset())
      setState({
        name: project.information.name,
        gpf: project.information.gpf,
        startDate: startDateGeneral,
        endDate: endDateGeneral
      });
      auxSeasonGeneral.startDateGeneral = startDateGeneral;
      auxSeasonGeneral.startEndGeneral = endDateGeneral;
      _seasonGeneral.push(auxSeasonGeneral)
      setSeasonsGeneral(_seasonGeneral)
    }

    var _filter = [
      { value: "ALL", label: "Todas" },
      { value: "DIRECT_HR", label: "RH Direto" },
      { value: "INDIRECT_HR", label: "RH Indireto" },
      { value: "EQUIPAMENTS", label: "Equipamentos" },
      { value: "CONSUMABLES", label: "Material de Consumo" },
      { value: "BOOKS_AND_PERIODICALS", label: "Livros e Periódicos" },
      { value: "TRIPS", label: "Viagens" },
      { value: "INCURRED_COSTS", label: "Custos Incorridos" }
    ];

    if (project.sgppdAddendum && project.sgppdAddendum.length > 0) {
      let subsidies = [];
      let periods = [{ "value": 0, "label": "Todos" }];
      let aux = {};

      for (let i in project.sgppdAddendum) {
        if (project.sgppdAddendum[i].number !== "GENERAL") {
          if (project.sgppdAddendum[i].projectSubsidy.length > 0) {
            for (let k in project.sgppdAddendum[i].projectSubsidy) {
              subsidies.push(project.sgppdAddendum[i].projectSubsidy[k]);
            }
          }

          let item = project.sgppdAddendum[i];
          let startDate = item.startdate.slice(0, 10);
          let endDate = item.enddate.slice(0, 10);

          let formattedStart = startDate.slice(8, 10) + "/" + startDate.slice(5, 7) + "/" + startDate.slice(0, 4);
          let formattedEnd = endDate.slice(8, 10) + "/" + endDate.slice(5, 7) + "/" + endDate.slice(0, 4);

          aux.value = item.id;
          aux.label = formattedStart + " - " + formattedEnd;
          periods.push(aux);
          aux = {};
        }

        if (project.sgppdAddendum[i].sgppdBudgetItem.length > 7) {
          for (
            let j = 7;
            j < project.sgppdAddendum[i].sgppdBudgetItem.length;
            j++
          ) {
            let obj = {};

            var notIncludes = _filter.filter(
              el => el.label.toLocaleLowerCase() === project.sgppdAddendum[i].sgppdBudgetItem[j].rubric.toLocaleLowerCase()).length === 0;

            if (notIncludes) {
              obj.value = project.sgppdAddendum[i].sgppdBudgetItem[j].rubric;
              obj.label = project.sgppdAddendum[i].sgppdBudgetItem[j].rubric;
              _filter.push(obj);
            }
          }
        }
      }

      setProjectSubsides(subsidies);
      setPeriodFilter(periods);
    }

    setRubricFilter(_filter);

    if (project.information) {
      var _arrayDataReports = [];
      var auxDataReports = {};
      auxDataReports.information = project.information
      if (project.sgppdAddendum.length > 0) {
        for (var i = 0; i < project.sgppdAddendum.length; i++) {
          var item = project.sgppdAddendum[i];
          auxDataReports.id = item.id
          auxDataReports.startdate = item.startdate
          auxDataReports.enddate = item.enddate
          auxDataReports.number = item.number
          auxDataReports.gpf = item.gpf
          auxDataReports.sgppdBudgetItem = item.sgppdBudgetItem
          auxDataReports.projectBudget = item.projectBudget
          auxDataReports.projectSubsidy = item.projectSubsidy
          _arrayDataReports.push(auxDataReports)
          auxDataReports = {};
        }
      } else {
        _arrayDataReports.push(auxDataReports)
        auxDataReports = {};
      }
      setDataReports(_arrayDataReports)
    }
  },
    [
      JSON.stringify(project),
      Object.keys(participants).length,
      JSON.stringify(participants),
      Object.keys(phases).length,
      JSON.stringify(phases),
      Object.keys(expenses).length,
      Object.keys(costByProject).length,
      JSON.stringify(costByProject)
    ]);

  useEffect(() => {
    return () => {
      resetMessage();
      resetDeleteMessage();
    };
  }, []);

  const handleCloseWithNotice = () => {
    setOpenWithNotice(false);
  };

  const handleOpenWithNotice = () => {
    setOpenWithNotice(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const FinishProjectSubmit = () => {
    var obj = {
      id: project.id,
      informationId: project.informationId,
      textId: project.textId,
      status: "TERMINATED",
      projectStatus: project.status
    };
    FinishProject(obj);
    setOpen(false);
    setOpenWithNotice(false);
  };

  return transitions.map(({ item, key, props }) =>
    item ? (
      <animated.div key={key} style={props}>
        <Spinner />
      </animated.div>
    ) : (
        <animated.div key={key} style={props}>
          {/* <DashboardNavBar />
          <SideBar /> */}
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={snackBar.open}
            onClose={handleSnackClose}
            TransitionComponent={Slide}
            transitionDuration={{ enter: 500, exit: 500 }}
            ContentProps={{
              "aria-describedby": "message-id"
            }}
            autoHideDuration={5000}
          >
            <MySnackbarContentWrapper
              onClose={handleSnackClose}
              variant={responseDeleteType.toLowerCase()}
              message={<span id="message-id">{responseDelete}</span>}
            />
          </Snackbar>
          {/* finish project */}
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={snackBarFinish.open}
            onClose={handleSnackCloseFinish}
            TransitionComponent={Slide}
            transitionDuration={{ enter: 500, exit: 500 }}
            ContentProps={{
              "aria-describedby": "message-id"
            }}
            autoHideDuration={2000}
          >
            <MySnackbarContentWrapper
              onClose={handleSnackCloseFinish}
              variant={responseType.toLowerCase()}
              message={<span id="message-id">{response}</span>}
            />
          </Snackbar>
          <div className="container">
            <Breadcrumbs
              className={classes.breadCrumbs}
              aria-label="breadcrumb"
              separator={<NavigateNextIcon fontSize="small" />}
            >
              <Typography>{t("PARTICIPANTS.labor")}</Typography>
              <Link className={classes.link} to="/execution/projects/">
                {t("PROJECTS.projects")}
              </Link>
              <Typography>{t("PROJECTS.project")}</Typography>
            </Breadcrumbs>
            <div className="container-header">
              <div className="title">
                <h1 className={classes.title}>{t("PROJECTS.project")}</h1>
              </div>
              {mode !== "view" & project.status !== "TERMINATED" ? (
                <Button
                  className={classes.finishProject}
                  onClick={
                    new Date() < new Date(state.endDate)
                      ? handleOpenWithNotice
                      : handleOpen
                  }
                >
                  {t("PROJECTS.finish")}
                </Button>
              ) : (
                  <></>
                )}
            </div>
            <form>
              <Paper className={classes.root}>
                <div className={classes.header}>
                  {t("GENERAL.projectInformation").toUpperCase()}
                </div>
                <div className={classes.bodyForm}>
                  <Grid container>
                    <Grid container item spacing={2} md={12} lg={12}>
                      <Grid item xs={3}>
                        <TextField
                          disabled
                          className={classes.textField}
                          margin="normal"
                          id="GPF"
                          label={t("PROJECTS.gpfUpperCase")}
                          name="GPF"
                          value={state.gpf}
                          autoFocus
                          InputProps={{
                            readOnly: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          disabled
                          className={classes.textField}
                          margin="normal"
                          id="name"
                          label={t("PERSONAL.name")}
                          name="name"
                          value={state.name}
                          autoFocus
                          InputProps={{
                            readOnly: true
                          }}
                        />
                      </Grid>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid item xs={2}>
                          <KeyboardDatePicker
                            disabled
                            className={classes.textField}
                            disableToolbar
                            autoOk={true}
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="startDate"
                            label={t("GENERAL.startDate")}
                            value={state.startDate}
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <KeyboardDatePicker
                            disabled
                            className={classes.textField}
                            disableToolbar
                            autoOk={true}
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="endDate"
                            label={t("GENERAL.endDate")}
                            value={state.endDate}
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </form>

            {project && (
              <div style={{ paddingTop: "50px" }}>
                <ProjectTabPanel
                  projectId={id}
                  participants={participants}
                  phases={phases}
                  expenses={expenses}
                  seasonId={seasonId}
                  seasonsArray={seasonsArray}
                  seasonsArrayReports={seasonsArrayReports}
                  mode={mode}
                  rubricFilter={rubricFilter}
                  projectSubsides={projectSubsides}
                  periodFilter={periodFilter}
                  allAllowances={allAllowances}
                  dataReports={dataReports}
                  costByProject={costByProject}
                />
              </div>
            )}
          </div>
          <Footer />
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            msg="mensagem"
          >
            <DialogTitle className={classes.actionsTitle}>
              {t("PROJECTS.finish")}
              <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t("PROJECTS.finishProjectAsk")}
              </DialogContentText>
              <DialogContentText>
                <b>{state.name}</b>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Buttons
                onClick={handleClose}
                tipo="BN-noback"
                conteudo={t("GENERAL.cancel")}
              />
              <Buttons
                onClick={FinishProjectSubmit}
                tipo="BN-blue"
                conteudo={t("GENERAL.finish")}
              />
            </DialogActions>
          </Dialog>

          {/* with notice */}

          <Dialog
            open={openNotice}
            onClose={handleCloseWithNotice}
            aria-labelledby="form-dialog-title"
            msg="mensagem"
          >
            <DialogTitle className={classes.actionsTitle}>
              {t("PROJECTS.finish")}
              <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleCloseWithNotice} aria-label="close">
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <span className={classes.hintFinishProject}>
                  {t("PROJECTS.dateFinish")}
                  {moment(state.endDate).format("DD/MM/YYYY")}.
              </span>
              </DialogContentText>
              <DialogContentText>
                {t("PROJECTS.finishProjectAsk")}
              </DialogContentText>
              <DialogContentText>
                <b>{state.name}</b>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Buttons
                onClick={handleCloseWithNotice}
                tipo="BN-noback"
                conteudo={t("GENERAL.cancel")}
              />
              <Buttons
                onClick={FinishProjectSubmit}
                tipo="BN-blue"
                conteudo={t("GENERAL.finish")}
              />
            </DialogActions>
          </Dialog>
        </animated.div>
      )
  );
};

export default ExecutionEditProject;