/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';

import Paper from '@material-ui/core/Paper';
import Dissatisfied from '@material-ui/icons/SentimentVeryDissatisfied';
import Footer from 'components/layout/Footer';
import ClockRecordContext from 'context/hrModule/clockRecord/clockRecordContext';
import Transition from 'components/layout/transition/Transition';
import Breadcrumb from 'components/layout/breadcrumb/Breadcrumb';
import CollaboratorClockRecordTable from './CollaboratorClockRecordTable';
import InputFilter from 'components/inputs/InputFilter';
import FilterContainer from 'components/containers/FilterContainer';
import HeaderContainer from 'components/containers/HeaderContainer';
import ContentContainer from 'components/containers/ContentContainer';
import Typography from 'components/typography/Typography';

import { ConvertMilliInHourMin } from 'utils/dates/ConvertMilliInHourMin1';

import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import { Months } from 'global/constants';
import { screenStyles } from '../Styles';
import { useQueryReportData } from 'hooks/DataFetching/HR/ClockRecord/useQueryReportData';

import { useSnackbar } from 'hooks/UseSnackbar';
import { getCollaboratorClockRecordYears } from 'utils/hrModule/ClockRecord/getCollaboratorClockRecordYears';
import ExportButton from 'components/buttons/ExportButton/ExportButton';
import ClockRecordReportTemplate from './ClockRecordReport/Template';
import DateUtils from 'utils/dates/DateUtils';
import { refetchAndGeneratePdf } from 'components/Pdf/generatePdf';

const ProfileManagement = props => {
    const classes = useStyles();
    const styles = screenStyles();
    const [t] = useTranslation();
    const clockRecordContext = useContext(ClockRecordContext);
    const toast = useSnackbar();

    const { id } = props;

    const currentDate = new Date();

    const [findText, setFindText] = useState('');
    const [filterYear, setFilterYear] = useState(currentDate.getFullYear());
    const [filterMonth, setFilterMonth] = useState(-1);
    const [filterActivity, setFilterActivity] = useState('ALL');
    const [filterStatus, setFilterStatus] = useState('ALL');
    const [dataFilter, setDataFilter] = useState([]);
    const [canClick, setCanClick] = useState(false);

    const [yearsArray, setYearsArray] = useState([
        { value: currentDate.getFullYear(), label: currentDate.getFullYear() }
    ]);

    const monthsArray = [
        { value: -1, label: t('GENERAL.allMasculine') },
        ...Months
    ];

    const statusArray = [
        {
            value: 'ALL',
            label: t('GENERAL.allMasculine')
        },
        {
            value: 'IN_PROGRESS',
            label: t('GENERAL.inProgress')
        },
        {
            value: 'INCONSISTENCY',
            label: t('GENERAL.inconsistency')
        },
        {
            value: 'JUSTIFED',
            label: t('HR.justified')
        },
        {
            value: 'REGULAR',
            label: t('GENERAL.regular')
        },
        {
            value: 'HOLIDAY',
            label: t('GENERAL.holiday')
        },
        {
            value: 'RECESS',
            label: t('GENERAL.recess')
        },
        {
            value: 'WEEKEND',
            label: t('GENERAL.weekend')
        }
    ];

    const activityArray = [
        {
            value: 'ALL',
            label: t('GENERAL.allMasculine')
        },
        {
            value: true,
            label: t('GENERAL.yesCapital')
        },
        {
            value: false,
            label: t('GENERAL.noCapital')
        }
    ];

    const [values, setValues] = useState({
        name: '',
        job: '',
        ruleset: '',
        totalWorkingHours: '',
        registersActivity: false
    });

    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const {
        loading,
        setLoading,
        setLoadingFalse,
        getViewCollaboratorClockRecord,
        viewCollaboratorClockRecord
    } = clockRecordContext;

    async function fetchData() {
        await setLoading();
        await getViewCollaboratorClockRecord(id);
        await setLoadingFalse();
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (
            viewCollaboratorClockRecord &&
            viewCollaboratorClockRecord.clockRecord.length > 0
        ) {
            setYearsArray(
                getCollaboratorClockRecordYears(
                    viewCollaboratorClockRecord.clockRecord
                )
            );
        }
    }, [viewCollaboratorClockRecord]);

    // Setup the collaborator card data
    useEffect(() => {
        if (viewCollaboratorClockRecord !== null) {
            setValues({
                name: viewCollaboratorClockRecord.name
                    ? viewCollaboratorClockRecord.name
                    : '-',
                imageName: viewCollaboratorClockRecord.imageName
                    ? viewCollaboratorClockRecord.imageName
                    : '-',
                job: viewCollaboratorClockRecord.job
                    ? viewCollaboratorClockRecord.job
                    : '-',
                ruleset: viewCollaboratorClockRecord.ruleset
                    ? viewCollaboratorClockRecord.ruleset
                    : '-',
                totalWorkingHours: viewCollaboratorClockRecord.totalWorkingHours
                    ? viewCollaboratorClockRecord.totalWorkingHours
                    : '-'
            });
        }
    }, [viewCollaboratorClockRecord]);

    useEffect(() => {
        if (filterYear || filterMonth || filterActivity || filterStatus) {
            setCanClick(true);
        } else {
            setCanClick(false);
        }
    }, [filterMonth, filterActivity, filterStatus]);

    useEffect(() => {
        if (
            viewCollaboratorClockRecord &&
            viewCollaboratorClockRecord.clockRecord.length
        ) {
            const { newDataFilter, textNoFound } = applyFilter(
                viewCollaboratorClockRecord.clockRecord
            );

            if (newDataFilter.length === 0) {
                setDataFilter([]);
                setHasNoResult(true);
                setFindText(textNoFound);
            } else {
                setDataFilter(newDataFilter);
                setHasNoResult(false);
                setFindText('');
            }
        }
    }, [
        viewCollaboratorClockRecord,
        filterYear,
        filterMonth,
        filterActivity,
        filterStatus
    ]);

    const applyFilter = data => {
        let newDataFilter = [];
        let textNoFound = '';

        newDataFilter = data;

        if (filterYear != undefined && filterYear !== -1) {
            newDataFilter = newDataFilter.filter(
                item =>
                    new Date(item.date).getFullYear() === filterYear
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterYear;
        }
        if (filterMonth != undefined && filterMonth !== -1) {
            newDataFilter = newDataFilter.filter(
                item => new Date(item.date).getMonth() === filterMonth
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = Months[filterMonth].label;
        }
        if (filterActivity !== undefined && filterActivity !== 'ALL') {
            newDataFilter = newDataFilter.filter(
                item => item.activityRecord === filterActivity
            );
            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = activityArray.find(
                    x => x.value === filterActivity
                ).label;
        }

        if (filterStatus != undefined && filterStatus !== 'ALL') {
            newDataFilter = newDataFilter.filter(
                item => item.status && item.status === filterStatus
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = statusArray.find(
                    x => x.value === filterStatus
                ).label;
        }

        return { newDataFilter, textNoFound };
    };

    const hasItens =
        viewCollaboratorClockRecord &&
        viewCollaboratorClockRecord.clockRecord.length > 0;
    const [hasNoResult, setHasNoResult] = useState(
        hasItens && dataFilter.length === 0
    );

    // useQueryReportData parameters
    const [rangeStart, setRangeStart] = useState(
        DateUtils.format(new Date(filterYear, 0, 1), 'YYYY-MM-DD')
    );
    const [rangeEnd, setRangeEnd] = useState(
        DateUtils.format(new Date(filterYear, 12, 0), 'YYYY-MM-DD')
    );

    // Update the rangeStart and rangeEnd when the filterYear or filterMonth changes, to use in useQueryReportData
    useEffect(() => {
        const startMonth = filterMonth === -1 ? 0 : filterMonth;
        const endMonth = filterMonth === -1 ? 12 : filterMonth + 1;
        setRangeStart(
            DateUtils.format(new Date(filterYear, startMonth, 1), 'YYYY-MM-DD')
        );
        setRangeEnd(DateUtils.format(new Date(filterYear, endMonth, 0), 'YYYY-MM-DD'));
    }, [filterYear, filterMonth]);

    const queryReportData = useQueryReportData(
        { enabled: false },
        id,
        rangeStart,
        rangeEnd,
        filterActivity,
        filterStatus
    );

    const [reportLoading, setReportLoading] = useState(false);
    async function handleGenerateReport() {
        return refetchAndGeneratePdf(
            response => {
                return <ClockRecordReportTemplate data={response.data} />;
            },
            queryReportData,
            setReportLoading,
            blob => {
                const url = window.URL.createObjectURL(blob);
                window.open(url);
            },
            error => {
                console.error(error);
                toast.error(t("HR.reportGenerationError"));
            }
        )
    }

    return (
        <React.Fragment>
            <Transition loading={loading} newDesign={true}>
                <HeaderContainer>
                    <Breadcrumb
                        newDesign={true}
                        routes={[
                            {
                                label: t('HR.humanResource'),
                                to: '/HumanResources'
                            },
                            { label: t('HR.clock') },
                            {
                                label: t('HR.collaboratorsClockRecords'),
                                to: '/CollaboratorsClockRecords'
                            },
                            { label: t('HR.viewCollaboratorClockRecord') }
                        ]}
                    />
                    <div className="container-headerNewDesign">
                        <h1 className={classes.titleNewDesign}>
                            {t('HR.viewCollaboratorClockRecord')}
                        </h1>
                    </div>
                </HeaderContainer>
                <ContentContainer>
                    <Paper className={styles.space}>
                        <div className={styles.collaboratorInfoWrapper}>
                            <div
                                className={
                                    styles.collaboratorInfoCardImgWrapper
                                }
                            >
                                <img
                                    src={
                                        process.env.REACT_APP_IMGSERVER +
                                        values.imageName
                                    }
                                    className={styles.collaboratorInfoCardImg}
                                />
                            </div>
                            <div
                                className={
                                    styles.collaboratorInfoCardPersonalInfo
                                }
                            >
                                <div
                                    className={
                                        styles.collaboratorInfoCardPersonalInfoFirstContent
                                    }
                                >
                                    <Typography
                                        className={styles.textMargin}
                                        variant={'RobotoBold'}
                                        fontSize={10}
                                        textColor={'white'}
                                    >
                                        {'COLABORADOR'}
                                    </Typography>
                                    <Typography
                                        className={styles.textMargin}
                                        variant={'RobotoRegular'}
                                        fontSize={14}
                                        textColor={'white'}
                                    >
                                        {values.name}
                                    </Typography>
                                    <Typography
                                        className={styles.textMargin}
                                        variant={'RobotoLight'}
                                        fontSize={12}
                                        textColor={'white'}
                                    >
                                        {values.job}
                                    </Typography>
                                </div>
                                <div
                                    className={
                                        styles.collaboratorInfoCardPersonalInfoSecondContent
                                    }
                                >
                                    <Typography
                                        className={styles.textMargin}
                                        variant={'RobotoBold'}
                                        fontSize={10}
                                        textColor={'white'}
                                    >
                                        {'REGIME'}
                                    </Typography>
                                    <Typography
                                        className={styles.textMargin}
                                        variant={'RobotoRegular'}
                                        fontSize={14}
                                        textColor={'white'}
                                    >{`${values.ruleset
                                        } • ${ConvertMilliInHourMin(
                                            values.totalWorkingHours
                                        )} Diárias`}</Typography>
                                </div>
                            </div>
                        </div>
                    </Paper>

                    {/* Table */}
                    <Paper>
                        <div className="cabecalho">
                            {t('HR.viewCollaboratorClockRecord').toUpperCase()}
                            <ExportButton
                                isLoading={reportLoading}
                                onClick={handleGenerateReport}
                                title={t('HR.generateReport').toUpperCase()}
                            />
                        </div>
                        <React.Fragment>
                            <FilterContainer>
                                <InputFilter
                                    filterValue={filterYear}
                                    setFilter={setFilterYear}
                                    label={'GENERAL.year'}
                                    isSelect
                                    arraySelected={yearsArray}
                                />
                                <InputFilter
                                    filterValue={filterMonth}
                                    setFilter={setFilterMonth}
                                    label={'GENERAL.month'}
                                    isSelect
                                    arraySelected={monthsArray}
                                />
                                <InputFilter
                                    filterValue={filterActivity}
                                    setFilter={setFilterActivity}
                                    label={'GENERAL.activities'}
                                    isSelect
                                    arraySelected={activityArray}
                                />
                                <InputFilter
                                    filterValue={filterStatus}
                                    setFilter={setFilterStatus}
                                    label={'GENERAL.status'}
                                    isSelect
                                    arraySelected={statusArray}
                                />
                            </FilterContainer>
                            <CollaboratorClockRecordTable
                                rows={hasItens ? dataFilter : []}
                                toFirstPage={canClick}
                                headers={[
                                    'id',
                                    'date',
                                    'entryTime',
                                    'leaveForBreak',
                                    'returnFromBreak',
                                    'leaveTime',
                                    'totalHours',
                                    'activityRecord',
                                    'status'
                                ]}
                                visibleHeaders={[
                                    'Data',
                                    'Entrada',
                                    'Saída Intervalo',
                                    'Retorno Intervalo',
                                    'Saída',
                                    'Horas Trabalhadas',
                                    'Registro de Atividades',
                                    'Status',
                                    ''
                                ]}
                                sortedFields={[
                                    'date',
                                    'entryTime',
                                    'leaveForBreak',
                                    'returnFromBreak',
                                    'leaveTime',
                                    'totalHours',
                                    'activityRegister',
                                    'status'
                                ]}
                                mainField="date"
                                fetchData={fetchData}
                            />
                            {!hasItens && (
                                <div className={classes.noParticipantsMessage}>
                                    <h2>{t('HR.noClockRecordMessage')}</h2>
                                    <h4>{t('HR.noClockRecordSubMessage')}</h4>
                                </div>
                            )}
                            {hasNoResult && (
                                <div className={classes.noParticipantsMessage}>
                                    <div>
                                        <Dissatisfied
                                            className={
                                                classes.notFoundIconStyle
                                            }
                                        />
                                    </div>
                                    <h2>{t('GENERAL.noResultsFound')}</h2>
                                    <h5>{`Sua busca por "${findText}" não retornou resultados.`}</h5>
                                </div>
                            )}
                        </React.Fragment>
                    </Paper>
                </ContentContainer>
                <Footer />
            </Transition>
        </React.Fragment>
    );
};

export default ProfileManagement;
