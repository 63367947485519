import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useStyles } from "components/layout/CommonStyles";
import ProjectsContext from "context/projects/projectsContext";
import "moment/locale/pt-br";
import PropTypes from 'prop-types';
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import "../profiles/css/FormStyle.css";
import Expenses from "./Expenses/Index";
import Participants from "./Participants/Index";
import Phases from "./Phases/Index";
import Subsidies from "./Subsidies/Index";
import Reports from "./Reports/Index";

function TabPanel(props) {
    const { children, value, index } = props;

    return (
        <div>
            {value === index && children}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const ProjectTabPanel = props => {
    const projectsContext = useContext(ProjectsContext);
    const { panelTab, setValuePanelTab } = projectsContext;
    const classes = useStyles();
    const [t] = useTranslation();
    const { projectId, participants, phases, seasonId, seasonsArray, seasonsArrayReports, mode, expenses, rubricFilter, projectSubsides, periodFilter, allAllowances, dataReports, costByProject } = props;
    const [value, setValue] = React.useState(panelTab);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setValuePanelTab(newValue);
    };

    const getPermission = (screen) => {
       var allowance;
        if (mode === "edit"){
            allowance = "allowUpdate"
        }else{
            allowance = "allowRead"
        }
        for (let i = 0; i < allAllowances.length; i++) {
          if (allAllowances[i].resource === screen) {
            return allAllowances[i]["standardauthorizationoption"][allowance]
          }
        }
      }
    var possition = 0;
    return (
        <div className={classes.root}>
            <AppBar position="static" style={{ boxShadow: "none", transform: "none" }}>
                <Tabs style={{ backgroundColor: "#f5f6fa", color: "black" }} indicatorColor="primary" value={value} onChange={handleChange} variant="fullWidth">
                    {allAllowances.length > 0 && getPermission("PARTICIPANTS") && 
                    <Tab style={{fontWeight: "bold"}} label={t("PROFILES.form.participants").toUpperCase()} {...a11yProps(0)} />
                    }{allAllowances.length > 0 && (getPermission("PHASES"))  && 
                    <Tab style={{fontWeight: "bold"}} label={t("PROFILES.form.phases").toUpperCase()} {...a11yProps(1)} />
                    } {allAllowances.length > 0 && getPermission("EXPENSES") && 
                    <Tab style={{fontWeight: "bold"}} label={t("GENERAL.expenses").toUpperCase()} {...a11yProps(2)} />
                    }
                    {allAllowances.length > 0 && getPermission("BUDGETEXECUTION") && 
                    <Tab style={{ fontWeight: "bold" }} label={t("PROJECTS.subsidies").toUpperCase()} {...a11yProps(3)} />
                    }
                    {allAllowances.length > 0 && (getPermission("GENERALFIANREPORT") || getPermission("TEMPFIANCIALSREPORT")) && 
                    <Tab style={{fontWeight: "bold"}} label={t("PROFILES.form.reports").toUpperCase()} {...a11yProps(4)} />
                    }
                    {/* <Tab style={{fontWeight: "bold"}} label={t("PROJECTS.step.headings").toUpperCase()} {...a11yProps(3)} />
                    <Tab style={{fontWeight: "bold"}} label={t("PROFILES.form.budgetExecution").toUpperCase()} {...a11yProps(4)} />
                     */}
                </Tabs>
            </AppBar>
            {allAllowances.length > 0 && getPermission("PARTICIPANTS") && 
            <TabPanel value={value} index={0}>
                <Participants participants={participants} projectId={projectId} mode={mode} />
            </TabPanel>
            }
            {allAllowances.length > 0 && getPermission("PHASES")  && 
            <TabPanel value={value} index={allAllowances.length > 0 && !getPermission("PARTICIPANTS") ? 0 : 1}>
                <Phases phases={phases} projectId={projectId} seasonId={seasonId} seasonsArray={seasonsArray} mode={mode} allAllowances={allAllowances} />
            </TabPanel>
            }
            {allAllowances.length > 0 && getPermission("EXPENSES") && 
            <TabPanel value={value} index={allAllowances.length > 0 && (getPermission("PARTICIPANTS") && getPermission("PHASES")) ? 2 : (!getPermission("PARTICIPANTS") && !getPermission("PHASES")) ? 0 : 1 }>
                <Expenses expenses={expenses} projectId={projectId} rubricFilter={rubricFilter} mode={mode} allAllowances={allAllowances} />
            </TabPanel>
            }
            {allAllowances.length > 0 && getPermission("BUDGETEXECUTION") && 
            <TabPanel value={value} index={allAllowances.length > 0 && (getPermission("PARTICIPANTS") && getPermission("PHASES") && getPermission("EXPENSES")) ? 3 : (getPermission("PARTICIPANTS") && getPermission("PHASES")) ? 2 : (getPermission("PARTICIPANTS") && getPermission("EXPENSES")) ? 2 : (getPermission("EXPENSES") && getPermission("PHASES")) ? 2 : getPermission("PARTICIPANTS") ? 1 : getPermission("PHASES") ? 1 : getPermission("EXPENSES") ? 1 : 0}>
                <Subsidies projectId={projectId} mode={mode} projectSubsides={projectSubsides} periodFilter={periodFilter} allAllowances={allAllowances} />
            </TabPanel>
            }
            {allAllowances.length > 0 && (getPermission("GENERALFIANREPORT") || getPermission("TEMPFIANCIALSREPORT")) && 
            <TabPanel value={value} index={allAllowances.length > 0 && (getPermission("PARTICIPANTS") && getPermission("PHASES") && getPermission("EXPENSES") && getPermission("BUDGETEXECUTION")) ? 4 : (getPermission("PARTICIPANTS") && getPermission("PHASES") && getPermission("BUDGETEXECUTION")) ? 3 : (getPermission("PARTICIPANTS") && getPermission("PHASES") && getPermission("EXPENSES")) ? 3 : (getPermission("PARTICIPANTS") && getPermission("PHASES")) ? 2 : (getPermission("PARTICIPANTS") && getPermission("EXPENSES")) ? 2 : (getPermission("PARTICIPANTS") && getPermission("BUDGETEXECUTION")) ? 2 : (getPermission("PHASES") && getPermission("BUDGETEXECUTION")) ? 2 : (getPermission("EXPENSES") && getPermission("BUDGETEXECUTION")) ? 2 : (getPermission("PARTICIPANTS")) ? 1 : getPermission("PHASES") ? 1 : getPermission("EXPENSES") ? 1 : getPermission("BUDGETEXECUTION") ? 1 : 0 }>
                <Reports projectId={projectId} mode={mode} projectSubsides={projectSubsides} periodFilter={periodFilter} allAllowances={allAllowances} seasonId={seasonId} seasonsArray={seasonsArray} seasonsArrayReports={seasonsArrayReports} dataReports={dataReports} expenses={expenses} costByProject={costByProject}/>
            </TabPanel>
            }
        </div>
    );
}

export default ProjectTabPanel;