import axios from 'axios';
import React, { useReducer } from 'react';
import { DEFAULT, LIBRARY } from '../../types';
import myLoansContext from './myLoansContext';
import myLoansReducer from './myLoansReducer';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

const MyLoansState = props => {
    const initialState = {
        message: '',
        response: '',
        responseType: '',
        hasChanged: 0,
        successiveActions: 0,
        loading: false,
        myLoans: []
    };

    const [state, dispatch] = useReducer(myLoansReducer, initialState);
    const [t] = useTranslation();
    const setLoading = () => dispatch({ type: DEFAULT.SET_LOADING });
    const setLoadingFalse = () => dispatch({ type: DEFAULT.SET_LOADING_FALSE });

    const resetMessage = () => dispatch({ type: DEFAULT.RESET_MESSAGE });
    const setHasChanged = () => dispatch({ type: DEFAULT.SET_HAS_CHANGED });

    const getMyLoans = async id => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER +
                `api/Library/Reservations/User/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: LIBRARY.GET_MY_LOANS,
            payload: res.data
        });
    };

    return (
        <myLoansContext.Provider
            value={{
                loading: state.loading,
                message: state.message,
                hasChanged: state.hasChanged,
                response: state.response,
                responseType: state.responseType,
                successiveActions: state.successiveActions,
                setLoading,
                setLoadingFalse,
                resetMessage,
                setHasChanged,
                myLoans: state.myLoans,
                getMyLoans
            }}
        >
            {props.children}
        </myLoansContext.Provider>
    );
};

export default MyLoansState;
