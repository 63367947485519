import axios from "axios";
import React, { useReducer } from "react";
import { DEFAULT, LIBRARY } from "../../types";
import settingsContext from "./settingsContext";
import settingsReducer from "./settingsReducer";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

const SettingsState = props => {
    const initialState = {
        message: "",
        response: "",
        responseType: "",
        hasChanged: 0,
        successiveActions: 0,
        loading: false,
        config: []
    }

    const [state, dispatch] = useReducer(settingsReducer, initialState);
    const [t] = useTranslation();
    const setLoading = () => dispatch({ type: DEFAULT.SET_LOADING });
    const setLoadingFalse = () => dispatch({ type: DEFAULT.SET_LOADING_FALSE });

    const resetMessage = () => dispatch({ type: DEFAULT.RESET_MESSAGE });
    const setHasChanged = () => dispatch({ type: DEFAULT.SET_HAS_CHANGED });


    const getConfig = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/Library/Config`, {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
                }
            }
        );
        dispatch({
            type: LIBRARY.GET_CONFIG,
            payload: res.data
        });
    }

    const editConfig = async (config, fetchData) => {
        axios.put(process.env.REACT_APP_SERVER + "api/Library/Config", config, {
            headers: {
                "Content-type": "application/json",
                "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
            }
        })
            .then(response => {
                if (response.status === 204) {
                    dispatch({
                        type: LIBRARY.EDIT_CONFIG,
                        payload: t("LIBRARY.configUpdated"),
                        responseType: "SUCCESS",
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: LIBRARY.EDIT_CONFIG,
                    payload: t("LIBRARY.configUpdatedError"),
                    responseType: "ERROR",
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            }).finally(() => {
                fetchData();
            })
    }
    
    return (
        <settingsContext.Provider
            value={{
                loading: state.loading,
                message: state.message,
                hasChanged: state.hasChanged,
                response: state.response,
                responseType: state.responseType,
                successiveActions: state.successiveActions,
                setLoading,
                setLoadingFalse,
                resetMessage,
                setHasChanged,
                config: state.config,
                getConfig,
                editConfig
            }}
        >
            {props.children}
        </settingsContext.Provider>
    );
};

export default SettingsState;

