import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Edit from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
//import InputBase from "@material-ui/core/InputBase";
import { useStyles } from "components/layout/CommonStyles";
import Delete from "@material-ui/icons/Delete";
import DialogDeleteSimple from "components/dialogs/DialogDeleteSimple.js";



const QualificationTableAction = props => {
    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }
    const classes = useStyles();
    const [t] = useTranslation();
    const { name, item, index, removeQualification, setOpenQualificationView, setOpenQualificationEdit, context } = props;
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenDelete = () => {
        setOpen(true);
    };

    const handleDelete = (itemRef) => {
        setOpen(false)
        removeQualification(itemRef);
    }


    return (
        <React.Fragment>
            <Tooltip title={t("GENERAL.view")} aria-label="details" placement="top">
                <IconButton className={classes.iconsTableActions} onClick={() => setOpenQualificationView(item.generalId, index)} >
                    <Visibility />
                </IconButton>
            </Tooltip>
            {context !== "view" &&
                <React.Fragment>
                    <Tooltip title="Editar" aria-label="edit" placement="top">
                        <IconButton className={classes.iconsTableActions} onClick={() => setOpenQualificationEdit(item.generalId, index)} >
                            <Edit />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Deletar" aria-label="delete" placement="top">
                        <IconButton className={classes.iconsTableActions} onClick={handleOpenDelete} >
                            <Delete />
                        </IconButton>
                    </Tooltip>

                    <DialogDeleteSimple
                        open={open}
                        setOpen={setOpen}
                        dialogTitle={t("GENERAL.delete") + " " + t("HR.qualification")}
                        dialogText={t("HR.deleteQualificationMessage")}
                        dialogInfo={t("HR.toApplyNeedToSave")}
                        itemName={item.qualificationName}
                        itemRef={item.generalId}
                        handleDelete={handleDelete}
                        handleClose={handleClose}
                    >
                    </DialogDeleteSimple>
                </React.Fragment>}
        </React.Fragment>
    )
};


export default QualificationTableAction;