import { DEFAULT, EXTERNALUSERS, HRCOSTS, USERS } from "../types";

export default (state, action) => {
  switch (action.type) {
    case DEFAULT.SET_LOADING:
      return {
        ...state,
        loading: true
      };
    case HRCOSTS.GET_USER_COSTS:
      return {
        ...state,
        userCosts: action.payload,
        loading: false
      };
    case HRCOSTS.GET_USERS_COSTS:
      return {
        ...state,
        usersCosts: action.payload,
        loading: false
      };
    case HRCOSTS.DELETE_USER_COST:
      return {
        ...state,
        message: action.payload,
        loading: false
      };
    case HRCOSTS.UPDATE_USER_COST:
      return {
        ...state,
        response: action.payload,
        responseType: action.responseType,
        successiveActions: action.successiveActions,
        hasChanged: action.hasChanged,
        loading: false
      };
    case EXTERNALUSERS.DELETE_EXTERNALUSER:
      return {
        ...state,
        response: action.payload,
        responseType: action.responseType,
        successiveActions: action.successiveActions,
        hasChanged: action.hasChanged,
        loading: false
      };
    case USERS.GET_USER:
      return {
        ...state,
        user: action.payload,
        loading: false
      };
    case USERS.GET_USERS:
      return {
        ...state,
        users: action.payload,
        loading: false
      };
      case HRCOSTS.SUBMIT_COSTS:
      return {
        ...state,
        response: action.payload,
        responseType: action.responseType,
        hasChanged: action.hasChanged,
        successiveActions: action.successiveActions,
        loading: false
      };
      case HRCOSTS.GET_USER_CONTRACT:
      return {
        ...state,
        userContract: action.payload,
        loading: false
      };
    case DEFAULT.RESET_MESSAGE:
      return {
        ...state,
        response: "",
        hasChanged: 0,
        responseType: ""
      };
    case DEFAULT.SET_HAS_CHANGED:
      return {
        ...state,
        hasChanged: 0
      };
    case HRCOSTS.RESET_USER_COSTS:
      return {
        ...state,
        userCosts: []
      }
    default:
      return state;
  }
};