import React from "react";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";

const TooltipError = ({children, open, title }) => {

    const tooltipTheme = createMuiTheme({
        palette: {
            primary: {
                main: "#2c3e51"
            }
        },
        overrides: {
            MuiTooltip: {
                tooltip: {
                    color: "white",
                    fontSize: "1em",
                    backgroundColor: "red",
                    opacity: "0.7"
                }
            }
        }
    });

    return (
        <MuiThemeProvider theme={tooltipTheme}>
            <Tooltip
                TransitionComponent={Zoom}
                open={open}
                title={title}
            >
                {children}
            </Tooltip>
        </MuiThemeProvider>
    )
}

export default TooltipError;