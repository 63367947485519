import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Edit from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";
import { GetGeneralPermissionForAction } from "utils/permissions/GetGeneralPermissionForAction";
import { Screen, Allow } from "global/constants";


const CollaboratorsTableAction = props => {    
    const classes = useStyles();
    const [t] = useTranslation();    
    const { item } = props;    

    return (
        <React.Fragment>
            {GetGeneralPermissionForAction(Screen.COLLABORATORS, Allow.READ) &&
            <Link to={`/Collaborators/${item.id}/view`}>
                <Tooltip title={t("GENERAL.view")} aria-label="details" placement="top">
                    <IconButton className={classes.iconsTableActions}  >
                        <Visibility />
                    </IconButton>
                </Tooltip>
            </Link>
            }
            {GetGeneralPermissionForAction(Screen.COLLABORATORS, Allow.EDIT) &&
            <Link to={`/Collaborators/${item.id}/edit`}>
                <Tooltip title="Editar" aria-label="edit" placement="top">
                    <IconButton className={classes.iconsTableActions}  >
                        <Edit />
                    </IconButton>
                </Tooltip>
            </Link>
            }            
        </React.Fragment>
    )
};

export default CollaboratorsTableAction;