import React from 'react';
import '../../App.css';
import SideBar from 'components/layout/sidebar/Sidebar';
import DashboardNavBar from 'components/layout/DashboardNavBar';
import HomeContent from './HomeContent';

const HomeQuotas = ({ match }) => {
    return (
        <div>
            <DashboardNavBar />
            <SideBar />
            <HomeContent context={'quotas'} />
        </div>
    );
};

export default HomeQuotas;
