import React, { useContext, useEffect, useState } from "react";
import InventoryContext from "context/inventory/inventoryContext";
import axios from "axios";
import Cookies from "js-cookie";

const PopulateAuthors = () => {
    const inventoryContext = useContext(InventoryContext);
    const {
        getBooks,
        books,
        addAuthor,
    } = inventoryContext;
    const [countUpdate, setCountUpdate] = useState(0);
    const [countRun, setCountRun] = useState(0);
    const [countAuthors, setCountAuthors] = useState(0);
    const [countTotal, setCountTotal] = useState(0);
    const [hasFinish, setHasFinish] = useState(false);

    const sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    useEffect(() => {
        getBooks();
    }, []);

    useEffect(() => {
        if (books && books.length) {
            async function UpdateBookAuthors() {
                const authorsBook = [];
                setCountTotal(books.filter(book => book.libraryBookAuthor && book.libraryBookAuthor.length === 0).length);

                for (const book of books) {
                    if (book.libraryBookAuthor && book.libraryBookAuthor.length === 0) {
                        let authors = [];

                        if (book.isbn) {
                            await axios.get(`https://www.googleapis.com/books/v1/volumes?q=isbn:${book.isbn}`, {
                                headers: {
                                    "Content-type": "application/json",
                                    "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
                                }
                            }).then(res => {
                                const response = (res && res.data && res.data.items && res.data.items.length > 0) ? res.data.items[0] : "";
                                if (response && response.volumeInfo && response.volumeInfo.authors && response.volumeInfo.authors.length > 0) {
                                    authors = response.volumeInfo.authors;
                                    setCountUpdate((old) => old + 1);
                                }

                            });
                            await sleep(600);
                        }

                        if (authors.length === 0) {
                            if (book.id === 11110501) {
                                authors = [book.author];
                            } else {
                                authors = book.author
                                    .replaceAll(' e ', ',')
                                    .replaceAll(' and ', ',')
                                    .replaceAll(' with ', ',')
                                    .replaceAll(' - ', ',')
                                    .replaceAll(' & ', ',')
                                    .replaceAll(';', ',')
                                    .split(',')
                                    .map(x => x.trim());
                            }
                        }
                        for (let i in authors) {
                            const aut = authorsBook.find(x => x.name === authors[i]);
                            if (aut) {
                                aut.libraryBookAuthor.push({ bookId: book.id });
                            } else {
                                authorsBook.push({
                                    name: authors[i],
                                    libraryBookAuthor: [{ bookId: book.id }]
                                });
                            }
                        }
                        setCountRun((old) => old + 1);
                    }
                }

                for (const obj of authorsBook) {
                    addAuthor(obj);
                }

                setCountAuthors(authorsBook.length);
                setHasFinish(true);
            }

            UpdateBookAuthors();
        }
    }, [books]);

    return (
        <div>
            {hasFinish ?
                <>
                    <span>Quantidade total de livros: {countTotal}</span>
                    <br />
                    <span>Quantidade de livros atualizados com novos autores: {countUpdate}</span>
                    <br />
                    <span>Quantidade de livros atualizados com autores antigos: {countTotal === 0 ? 0 : countTotal - countUpdate}</span>
                    <br />
                    <span>Quantidade de autores cadastrados: {countAuthors}</span>
                </>
                :
                <span>Atualizando: {countTotal === 0 ? 0 : Math.trunc((countRun * 100) / countTotal)}%</span>
            }
        </div>
    );
}

export default PopulateAuthors;