import axios from 'axios';
import { useMutation } from 'react-query';
import Cookies from 'js-cookie';
import { useSnackbar } from 'hooks/UseSnackbar';
import { useTranslation } from 'react-i18next';

export const useMutationRemoveFingerInREP = (setOpen, fetchData, userId) => {
    const toast = useSnackbar();
    const [t] = useTranslation();

    const mutation = useMutation({
        mutationFn: async obj =>
            await axios.delete(
                process.env.REACT_APP_SERVER + `api/REP/Employees`,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    },
                    data: obj
                }
            ),
        onSuccess: res => {
            toast.success(t('HR.removeFromRepSucess'));
        },
        onError: err => {
            if (
                err.response.data.message &&
                err.response.data.errorCode !== 1000
            ) {
                toast.error(err.response.data.message);
            } else {
                toast.error(t('HR.removeFromRepError'));
            }
        },
        onSettled: () => {
            setOpen(false);
            fetchData(userId);
        }
    });

    return mutation;
};
