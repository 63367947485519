import React from 'react';
import Typography from 'components/typography/Typography';

export const BoxBorderUnit = props => {
    const { title, content, margin, width, height } = props;

    return (
        <div style={{ margin: margin ? margin : '0' }}>
            <Typography
                variant={'RobotoBold'}
                fontSize={14}
                textColor={'secondary'}
            >
                {title.toUpperCase()}
            </Typography>
            <div
                style={{
                    width: width ? width : '',
                    height: height ? height : '64px',
                    padding: '8px',
                    marginTop: '4px',
                    border: '1px solid #B3B3B3',
                    borderRadius: '4px',
                    overflowY: 'auto',
                    lineHeight: '17px',
                    overflowWrap: 'break-word'
                }}
            >
                <Typography
                    variant={'RobotoRegular'}
                    fontSize={12}
                    textColor={'blackOpac'}
                >
                    {content}
                </Typography>
            </div>
        </div>
    );
};
