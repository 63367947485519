import {StyleSheet} from "@react-pdf/renderer";

export default StyleSheet.create({
    table: {},
    header: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        fontSize: 9,
        color: "#5c6b7a",
        fontWeight: 400,
        backgroundColor: "#EEF2F6",
    },
    headerCell: {
        padding: 10,
        textAlign: "center",
        justifyContent: "center",
    },
    body: {
        fontSize: 9,
        fontWeight: 300,
        color: "#2C3E51",
    },
})
