import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import Buttons from 'components/buttons/Pressed';
import Typography from 'components/typography/Typography';
import { screenStyles } from '../Styles';
import Input from 'components/inputs/Input';
import Grid from '@material-ui/core/Grid';
import { moneyMask } from 'utils/masks/moneyMask';
import { moneyMaskToFloat } from 'utils/general/moneyMaskToFloat';
import InputAdornment from '@material-ui/core/InputAdornment';
import { LibraryFinesTypes } from 'global/constants';
import InputFile from 'components/inputs/InputModal';
import { valueToMoneyFloat } from 'utils/general/valueToMoneyFloat';
import { FormatDateToFront } from 'utils/dates/FormatDateToFront';
import { dateToBR } from 'utils/dates/DateToBRr';
import TextField from '@material-ui/core/TextField';
import { useStyles } from 'components/layout/CommonStyles';
import Download from '@material-ui/icons/GetApp';
import axios from 'axios';
import Cookies from 'js-cookie';

const DialogDebitCredit = props => {
    const classes = useStyles();
    const styles = screenStyles();
    const [t] = useTranslation();

    const { open, setOpen, context, type, submit, viewValues } = props;

    const [valueRequired, setValueRequired] = useState(false);
    const [file, setFile] = useState([]);

    const [values, setValues] = useState({
        observation: viewValues ? viewValues.observation : '',
        value: viewValues
            ? moneyMask(valueToMoneyFloat(viewValues.price))
            : '0,00',
        fileName:
            type === LibraryFinesTypes.DEBIT
                ? 'Anexe o comprovante do débito'
                : 'Anexe o comprovante do crédito',
        operatorName: viewValues ? viewValues.user.shortname : '',
        date: viewValues ? dateToBR(FormatDateToFront(viewValues.date)) : ''
    });

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (name, value) => {
        let val = value;
        setValues({ ...values, [name]: val });
    };

    const handleChangeMoney = (name, value) => {
        setValues({
            ...values,
            [name]: moneyMask(value)
        });
    };

    const handleSubmit = () => {
        if (values.value === '0,00') {
            setValueRequired(true);
        } else {
            submit(
                moneyMaskToFloat(values.value).toFixed(2),
                values.observation,
                type,
                file.length > 0 ? file[0] : null
            );
            handleClose();
        }
    };

    const onDropFile = acceptedFiles => {
        if (acceptedFiles[0] !== undefined) {
            handleChange('fileName', acceptedFiles[0].name);
            setFile(
                acceptedFiles.map(file =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })
                )
            );
        }
    };

    useEffect(() => {
        setValueRequired(false);
    }, [values.value]);

    const handleDialogTitle = type => {
        if (context === 'add') {
            if (type === LibraryFinesTypes.DEBIT) {
                return t('LIBRARY.addDebit');
            } else {
                return t('LIBRARY.addCredit');
            }
        } else {
            switch (type) {
                case LibraryFinesTypes.DEBIT:
                    return t('LIBRARY.viewDebit');
                case LibraryFinesTypes.BOOK_PURCHASE:
                    return t('LIBRARY.viewDebit');
                default:
                    return t('LIBRARY.viewCredit');
            }
        }
    };

    const fileDownload = require('js-file-download');

    const onClickDownload = async file => {
        let noSlashPath = file.path.replaceAll('/', ';');
        noSlashPath = noSlashPath.replaceAll('\\', ';');
        const url = process.env.REACT_APP_FILE + noSlashPath;
        axios
            .get(url, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                },
                responseType: 'blob'
            })
            .then(function (res) {
                const blob = new Blob([res.data], {});
                const name = file.fileName;
                fileDownload(blob, name);
            });
    };

    const hasObservation = viewValues && viewValues.observation;

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth={'sm'}
                fullWidth={true}
            >
                <DialogTitle>
                    <div className={styles.dialogTitle}>
                        <Typography
                            variant={'RobotoBold'}
                            fontSize={24}
                            textColor={'secondary'}
                        >
                            {handleDialogTitle(type)}
                        </Typography>
                        <IconButton
                            color="#6D6F71"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <Grid container item spacing={1} md={12} lg={12}>
                        <Grid item xs={12} sm={12}>
                            <Input
                                required={viewValues ? false : true}
                                disabled={context === 'view'}
                                context={'edit'}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            R$
                                        </InputAdornment>
                                    )
                                }}
                                maxLength={20}
                                label={'GENERAL.value'}
                                value={values.value}
                                valueName={'value'}
                                handleChange={handleChangeMoney}
                                error={valueRequired}
                                helperText={
                                    valueRequired && t('GENERAL.requiredField')
                                }
                                setRequired={setValueRequired}
                            />
                        </Grid>

                        {context === 'view' ? (
                            <>
                                <Grid item sm={6}>
                                    <Input
                                        disabled
                                        label={'INVENTORY.operator'}
                                        value={values.operatorName}
                                        valueName={'operatorName'}
                                        handleChange={handleChange}
                                    />
                                </Grid>
                                <Grid item sm={6}>
                                    <Input
                                        disabled
                                        label={'GENERAL.date'}
                                        value={values.date}
                                        valueName={'date'}
                                        handleChange={handleChange}
                                    />
                                </Grid>
                            </>
                        ) : null}

                        {context !== 'view' ? (
                            <Grid item xs={12} sm={12}>
                                <InputFile
                                    onDrop={onDropFile}
                                    files={file}
                                    label={'GENERAL.voucher'}
                                    value={values.fileName}
                                />
                            </Grid>
                        ) : null}

                        {context === 'view' && viewValues.file.path ? (
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    disabled
                                    variant="outlined"
                                    label={t('GENERAL.voucher')}
                                    name="oldContract"
                                    autoComplete="off"
                                    className={classes.textField}
                                    value={viewValues.file.fileName}
                                    margin="normal"
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput
                                    }}
                                    inputProps={{ 'aria-label': 'search' }}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() =>
                                                        onClickDownload(
                                                            viewValues.file
                                                        )
                                                    }
                                                >
                                                    <Download />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                        ) : null}

                        {context === 'add' || hasObservation ? (
                            <Grid item xs={12} sm={12}>
                                <Input
                                    context={context}
                                    placeholder={
                                        type === LibraryFinesTypes.DEBIT
                                            ? t('LIBRARY.debitObservation')
                                            : t('LIBRARY.creditObservation')
                                    }
                                    label={'GENERAL.observation'}
                                    value={values.observation}
                                    valueName={'observation'}
                                    rows={3}
                                    maxLength={200}
                                    handleChange={handleChange}
                                />
                            </Grid>
                        ) : null}
                    </Grid>
                </DialogContent>

                <DialogActions className={styles.dialogButtonsWrapper}>
                    {context === 'add' ? (
                        <>
                            <Buttons
                                onClick={handleClose}
                                tipo="BN-noback"
                                conteudo={t('GENERAL.cancel').toUpperCase()}
                            />
                            <Buttons
                                className={styles.dialogButton}
                                tipo="BN-blue"
                                conteudo={t('GENERAL.completed').toUpperCase()}
                                onClick={handleSubmit}
                            />
                        </>
                    ) : (
                        <Buttons
                            className={styles.dialogButton}
                            tipo="BN-blue"
                            conteudo={t('GENERAL.close').toUpperCase()}
                            onClick={handleClose}
                        />
                    )}
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DialogDebitCredit;
