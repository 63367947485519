export const phoneMask = value => {
    if (!value) return value;

    // Remove all non-digit characters
    value = value.replace(/\D/g, '');
    const pure_number = value;

    // Add the first parenthesis
    value = value.replace(/(\d{2})(\d)/, '($1) $2');

    // Use the XXXX-XXXX format if there are 10 or fewer digits, otherwise use XXXXX-XXXX
    if (pure_number.length < 11) {
        value = value.replace(/(\d{4})(\d)/, '$1-$2');
    } else {
        value = value.replace(/(\d{5})(\d)/, '$1-$2');
    }

    // trim number to 4 digits after the dash
    value = value.replace(/(-\d{4})\d+?$/, '$1');

    return value;
};
