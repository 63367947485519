import React from "react";
import "../../../App.css";
import SideBar from "components/layout/sidebar/Sidebar";
import DashboardNavBar from "components/layout/DashboardNavBar";
import Places from "./Places";

const AllPlaces = ({ match }) => {
  return (
    <div>
      <DashboardNavBar />
      <SideBar />
      <Places />
    </div>
  );
}
export default AllPlaces;