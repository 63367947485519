import React from 'react';
import '../../../App.css';
import SideBar from 'components/layout/sidebar/Sidebar';
import DashboardNavBar from 'components/layout/DashboardNavBar';
import Dashboard from './Dashboard';

const CallDashboard = () => {
    return (
        <div>
            <DashboardNavBar />
            <SideBar />
            <Dashboard />
        </div>
    );
};
export default CallDashboard;
