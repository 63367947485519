import React from 'react';
import { Document, Page, Text, View, Font } from '@react-pdf/renderer';
import styles from './Template.styled';
import { getTimeStringFromTimeSpan } from 'utils/dates/getTimeStringFromTimeSpan';
import {
    clockRecordStatus,
    clockRecordStatusi18n
} from 'utils/hrModule/ClockRecord/clockRecordStatus';
import { useTranslation } from 'react-i18next';
import Header from 'components/Pdf/Header/Header';
import Footer from 'components/Pdf/Footer/Footer';
import DateUtils from 'utils/dates/DateUtils';
import Table from 'components/Pdf/Table/Table';
import Row from 'components/Pdf/Table/Row';
import Cell from 'components/Pdf/Table/Cell';
import SectionHeader from 'components/Pdf/Section/SectionHeader';
import Label from 'components/Pdf/Label/Label';


const ClockRecordReportTemplate = ({ data }) => {
    const [t] = useTranslation()

    Font.register({
        family: 'Roboto', fonts: [
            { src: window.location.origin + "/fonts/Roboto-Light.ttf", fontWeight: 300, fontStyle: "normal" },
            { src: window.location.origin + "/fonts/Roboto-Regular.ttf", fontWeight: 400, fontStyle: "normal" },
            { src: window.location.origin + "/fonts/Roboto-Bold.ttf", fontWeight: 700, fontStyle: "normal" },
        ]
    });

    const getWeekFirstAndLastDay = days => {
        const firstDay = DateUtils.formatString(days[0].date, 'DD/MM/YYYY');
        const lastDay = DateUtils.formatString(days[days.length - 1].date, 'DD/MM/YYYY');
        return `${firstDay} - ${lastDay}`;
    };

    const getCellText = date => {
        return date ? DateUtils.formatString(date, 'hh:mm') : '-';
    };

    const getTotalHoursCellText = day => {
        return day.totalHours === 0 ? '-' : getTimeStringFromTimeSpan(day.totalHours);
    }

    const getCellColor = status => status === clockRecordStatus.INCONSISTENCY
        ? '#B22222'
        : status === clockRecordStatus.REGULAR
            ? '#228B22'
            : status === clockRecordStatus.JUSTIFIED
                ? '#228B22'
                : '#2C3E51';

    const user = data.user;
    const months = data.months;

    return (
        <Document title="Relatório de Pontos do Colaborador" author="LSBD">
            <Page wrap style={styles.page} orientation="landscape">
                <Header mainTitle={'Pontos do Colaborador'}
                    subTitle={'Relatório de Pontos'} />

                <View style={{ display: "flex", flexDirection: "column", marginTop: 20, marginBottom: 36 }}>
                    <View style={{ display: 'flex', flexDirection: 'column', marginBottom: 5, color: '#2C3E51', fontSize: 16, fontWeight: 900 }}>
                        <Text>{user.name}</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', fontSize: 14, fontWeight: 'light', color: '#525252' }}>
                        <View style={{ display: 'flex', flexDirection: 'column', marginRight: 10, color: '#878787' }}>
                            <Text>E-mail</Text>
                            <Text>Cargo</Text>
                            <Text>Regime</Text>
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'column' }}>
                            <Text>{user.username}</Text>
                            <Text>{user.job}</Text>
                            <Text>{user.ruleset}</Text>
                        </View>
                    </View>
                </View>

                {months.map((month, i) => (
                    <View key={i} style={{ marginBottom: 16 }}>
                        <SectionHeader style={{ marginBottom: 10 }}>
                            <Text style={{ fontSize: 16, fontWeight: "bold", marginRight: 8 }}>{DateUtils.formatString(month.month, 'MMMM - MM/YYYY')}</Text>
                            <Label text={`${getTimeStringFromTimeSpan(month.totalHours, 'hh')} horas`} />
                        </SectionHeader>

                        {month.weeks.map((week, j) => (
                            <View key={j} style={{ marginBottom: 24 }}>
                                <View style={{ display: 'flex', flexDirection: 'row', alignItems: "center", marginBottom: 10, color: '#2C3E51' }}>
                                    <Text style={{ marginRight: 8 }}>{`${getWeekFirstAndLastDay(week.days)}`}</Text>
                                    <Label text={`${getTimeStringFromTimeSpan(week.totalHours, 'hh')} horas`} />
                                </View>
                                <Table columns={[
                                    'Data',
                                    'Entrada',
                                    'Saída p/ Intervalo',
                                    'Retorno do Intervalo',
                                    'Saída',
                                    'Total',
                                    'Status'
                                ]} wrap={false}>
                                    {week.days && week.days.map((day, index) => (
                                        <Row key={index} wrap={false} style={{ backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#F8F9FB" }}>
                                            <Cell style={{ width: `${100 / 7}%`, textAlign: 'left' }} text={DateUtils.formatString(day.date, "DD/MM/YYYY")} />
                                            <Cell style={{ width: `${100 / 7}%`, textAlign: 'center' }} text={getCellText(day.entryTime)} />
                                            <Cell style={{ width: `${100 / 7}%`, textAlign: 'center' }} text={getCellText(day.leaveForBreak)} />
                                            <Cell style={{ width: `${100 / 7}%`, textAlign: 'center' }} text={getCellText(day.returnFromBreak)} />
                                            <Cell style={{ width: `${100 / 7}%`, textAlign: 'center' }} text={getCellText(day.leaveTime)} />
                                            <Cell style={{ width: `${100 / 7}%`, textAlign: 'center' }} text={getTotalHoursCellText(day)} />
                                            <Cell style={{ width: `${100 / 7}%`, textAlign: 'center', color: getCellColor(day.status) }} text={`${t(clockRecordStatusi18n[day.status])}`} />
                                        </Row>
                                    ))}
                                </Table>
                            </View>
                        ))}
                    </View>
                ))}
                <Footer wrap={false} />
            </Page>
        </Document>
    );
};

export default ClockRecordReportTemplate;
