export const newsNotifications = [
    {
        event: 'NEWS_APPROVED_REFUSED_EMAIL',
        allowSend: true,
        automatic: true
    },
    {
        event: 'NEWS_PUBLISHED_EMAIL',
        allowSend: true,
        automatic: true
    },
    {
        event: 'NEWS_REVIEW_PUBLISH_EMAIL',
        allowSend: false,
        automatic: false
    }
];
