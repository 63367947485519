import React from "react";
import "../../../../App.css";
import SideBar from "components/layout/sidebar/Sidebar";
import DashboardNavBar from "components/layout/DashboardNavBar";
import Jobs from "./Jobs";

const CallJobs = ({ match }) => {

    return (
        <div>
            <DashboardNavBar />
            <SideBar />
            <Jobs />
        </div>
    );
}

export default CallJobs;