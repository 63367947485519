/* eslint-disable */
import React from "react";
import "../../App.css";
import Search from "../layout/searchIndex";
import SideBar from "../layout/sidebar/Sidebar";
import Profiles from "../profiles/Profiles";

function ProfilesReturn() {
  return (
    <div>
      <Search busca={"Procurar por Nome ou Descrição"} />
      <SideBar />
      <Profiles />
    </div>
  );
}

export default ProfilesReturn;
