import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogUnit from 'components/dialogs/DialogUnit';
import DialogUnitWithIcon from 'components/dialogs/DialogUnitWithIcon';
import DialogPasswordConfirm from 'components/dialogs/DialogPasswordConfirm';
import { Grid, Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation, Trans } from 'react-i18next';
import Input from 'components/inputs/Input';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import Buttons from 'components/buttons/Pressed';
import Typography from 'components/typography/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import Avatar from '@material-ui/core/Avatar';

import { useMutationDisabledUser } from 'hooks/DataFetching/Admin/Users/useMutationDisabledUser';

import { FormatDateToFront } from 'utils/dates/FormatDateToFront';
import { GetMonthAndYearInWords } from 'utils/dates/GetMonthAnsYearInWords';
import { dateToBRWithHours } from 'utils/dates/DateToBrWithHours';
import { dateToBR } from 'utils/dates/DateToBRr';
import { valueToMoneyFloat } from 'utils/general/valueToMoneyFloat';
import { moneyMask } from 'utils/masks/moneyMask';

import { translateUserRegistrationStatus } from '../ScreenUtils';
import { screenStyles } from '../Styles';

const DialogDisableUser = props => {
    const styles = screenStyles();
    const [t] = useTranslation();

    const { open, setOpen, viewValues } = props;

    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const [nextStep, setNextStep] = useState(false);

    const mutation = useMutationDisabledUser(setOpen);

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        if (nextStep) {
            let formData = new FormData();

            formData.append('id', viewValues.id);

            mutation.mutate(formData);
        } else {
            setNextStep(true);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            maxWidth={'sm'}
            fullWidth
        >
            {nextStep ? (
                <DialogPasswordConfirm
                    setOpen={setOpen}
                    handleSubmit={handleSubmit}
                    title={t('GENERAL.confirmAction')}
                    buttonText={t('GENERAL.disableUser').toUpperCase()}
                    closeButtonText={t('GENERAL.cancel').toUpperCase()}
                    mutation={mutation}
                    trans={'ADMIN.disableUserText'}
                    transValues={{ name: viewValues.name }}
                />
            ) : (
                <>
                    <DialogTitle>
                        <Grid container item spacing={2} xs={12}>
                            <Grid item xs={11}>
                                <Typography
                                    variant={'RobotoBold'}
                                    fontSize={24}
                                    textColor={'secondary'}
                                >
                                    {t('GENERAL.disableUser')}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton
                                    color="#6D6F71"
                                    onClick={handleClose}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogTitle>

                    <DialogContent>
                        <Grid container item spacing={2} xs={12}>
                            <Grid item xs={6}>
                                <DialogUnitWithIcon
                                    head={t(
                                        'GENERAL.collaborator'
                                    ).toUpperCase()}
                                    Icon={
                                        <Avatar
                                            className={
                                                styles.dialogViewBillAvatar
                                            }
                                            alt={viewValues.shortname}
                                            src={
                                                process.env
                                                    .REACT_APP_IMGSERVER +
                                                viewValues.imageName
                                            }
                                        />
                                    }
                                    content={viewValues.name}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <DialogUnit
                                    marginTop={'20px'}
                                    head={t('GENERAL.socialName').toUpperCase()}
                                    content={viewValues.shortname}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <DialogUnit
                                    head={t('GENERAL.situation')}
                                    content={translateUserRegistrationStatus(
                                        viewValues.userRegistrationStatus
                                    )}
                                    tooltipContent={t(
                                        'ADMIN.adminTooltipSituation'
                                    )}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <DialogUnitWithIcon
                                    head={t('GENERAL.systemAccess')}
                                    content={
                                        viewValues.enabled
                                            ? t('GENERAL.enabled')
                                            : t('GENERAL.disabled')
                                    }
                                    marginTop={'0px'}
                                    Icon={
                                        viewValues.enabled ? (
                                            <CheckCircleIcon
                                                style={{
                                                    color: '#7FE46B'
                                                }}
                                                fontSize="md"
                                            />
                                        ) : (
                                            <ErrorIcon
                                                style={{
                                                    color: '#F3222F'
                                                }}
                                                fontSize="md"
                                            />
                                        )
                                    }
                                    tooltipContent={t(
                                        'ADMIN.adminTooltipSystemAccess'
                                    )}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <DialogUnit
                                    head={t('PERSONAL.CPF').toUpperCase()}
                                    content={viewValues.cpf}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <DialogUnit
                                    head={t('PERSONAL.email').toUpperCase()}
                                    content={viewValues.username}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <DialogUnit
                                    head={t('PERSONAL.birthday').toUpperCase()}
                                    content={dateToBR(
                                        FormatDateToFront(
                                            viewValues.dateOfBirth
                                        )
                                    )}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <DialogUnit
                                    head={t(
                                        'ADMIN.createAtUserAdmin'
                                    ).toUpperCase()}
                                    content={
                                        viewValues.createdAt
                                            ? dateToBR(
                                                  FormatDateToFront(
                                                      viewValues.createdAt
                                                  )
                                              )
                                            : ''
                                    }
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <Buttons
                            onClick={handleClose}
                            tipo={'BN-noback'}
                            conteudo={t('GENERAL.cancel').toUpperCase()}
                        />

                        <Buttons
                            isLoading={mutation.isLoading}
                            onClick={handleSubmit}
                            tipo="BN-blue"
                            conteudo={t('GENERAL.next').toUpperCase()}
                        />
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default DialogDisableUser;
