import { DEFAULT, LIBRARY } from '../../types';

export default (state, action) => {
    switch (action.type) {
        case DEFAULT.SET_LOADING:
            return {
                ...state,
                loading: true
            };
        case DEFAULT.SET_LOADING_FALSE:
            return {
                ...state,
                loading: false
            };
        case DEFAULT.RESET_MESSAGE:
            return {
                ...state,
                response: '',
                responseType: ''
            };
        case DEFAULT.SET_HAS_CHANGED:
            return {
                ...state,
                hasChanged: 0
            };
        case LIBRARY.GET_MY_QUEUE:
            return {
                ...state,
                myQueue: action.payload
            };
        default:
            return state;
    }
};
