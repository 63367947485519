import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DialogAwaitingListManagementCancel from 'pages/libraryModule/dialogs/Requisitions/AwaitingList/DialogAwaitingListManagementCancel';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import Delete from '@material-ui/icons/Delete';

const AwaitingListTableActions = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const { item, submit, bookData } = props;
    const [openDialog, setOpenDialog] = useState(false);

    return (
        <React.Fragment>
            <Tooltip
                title={t('GENERAL.delete')}
                aria-label="details"
                placement="top"
            >
                <IconButton
                    className={classes.iconsTableActions}
                    onClick={() => setOpenDialog(true)}
                >
                    <Delete />
                </IconButton>
            </Tooltip>
            {openDialog && (
                <DialogAwaitingListManagementCancel
                    open={openDialog}
                    setOpen={setOpenDialog}
                    item={item}
                    bookData={bookData}
                    submit={submit}
                />
            )}
        </React.Fragment>
    );
};

export default AwaitingListTableActions;
