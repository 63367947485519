import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Visibility from "@material-ui/icons/Visibility";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DetaislHistoryEquipment from "./DetailsHistory";

const useStyles = makeStyles(theme => ({
  icons: {
    opacity: "0"
  },
  root: {
    flexGrow: 1,
  }
}));

const HistoryTableActions = props => {
  const classes = useStyles();
  const [t] = useTranslation();
  const { historyItem } = props;
  const [openDetails, setOpenDetails] = useState(false);

  return (
    <React.Fragment>
      <Tooltip title={t("GENERAL.view")} aria-label="details" placement="top">
        <IconButton className={classes.icons} onClick={() => setOpenDetails(true)} >
          <Visibility />
        </IconButton>
      </Tooltip>
      <DetaislHistoryEquipment openDetails={openDetails} setOpenDetails={setOpenDetails} historyItem={historyItem} />
    </React.Fragment>
  );
};

export default HistoryTableActions;