import React, { useEffect, useState } from 'react';
import { useStyles } from 'components/layout/CommonStyles';
import { inputStyles } from './Styles';
import { useTranslation } from 'react-i18next';
import Typography from 'components/typography/Typography';
import Dropzone from 'react-dropzone';
import Skeleton from '@material-ui/lab/Skeleton';
import FormControl from '@material-ui/core/FormControl';

const InputImage = ({ file, setFile, imageUrl, context, ...props }) => {
    const classes = useStyles();
    const styles = inputStyles();
    const [t] = useTranslation();
    const [hasUpdatedFile, setHasUpdatedFile] = useState(
        imageUrl ? true : false
    );

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(false);
    }, [imageUrl]);

    const onDrop = acceptedFiles => {
        if (acceptedFiles[0] !== undefined) {
            setHasUpdatedFile(true);
            setFile(
                acceptedFiles.map(file =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })
                )
            );
        }
    };

    return (
        <div>
            <Dropzone
                disabled={context === 'view' ? true : false}
                component="div"
                onDrop={onDrop}
                maxFiles={1}
                accept="image/jpeg,
            image/png,
            image/bmp"
            >
                {({ getRootProps, getInputProps, isDragActive }) => (
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {!isDragActive && (
                            <FormControl
                                className={
                                    context !== 'view'
                                        ? styles.inputImageContainer
                                        : ''
                                }
                            >
                                <div className={styles.inputImageWrapper}>
                                    <div className={styles.inputImageDivImage}>
                                        <img
                                            className={styles.inputImageImage}
                                            style={
                                                !loaded
                                                    ? { display: 'none' }
                                                    : {}
                                            }
                                            height="240"
                                            width="150"
                                            alt="add-image"
                                            src={
                                                !imageUrl && file.length === 0
                                                    ? window.location.origin +
                                                      '/img/no-book-cover.png'
                                                    : file.length > 0
                                                    ? file[0].preview
                                                    : imageUrl
                                            }
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src =
                                                    window.location.origin +
                                                    '/img/no-book-cover.png';
                                                setLoaded(true);
                                            }}
                                            onLoad={() => {
                                                setLoaded(true);
                                            }}
                                        />
                                        {!loaded && (
                                            <Skeleton
                                                variant="rect"
                                                width={214}
                                                height={307}
                                            />
                                        )}
                                    </div>
                                    {context !== 'view' && (
                                        <Typography
                                            className={styles.inputImageText}
                                            variant={'RobotoBold'}
                                            fontSize={14}
                                            textColor={'secondary'}
                                        >
                                            {hasUpdatedFile
                                                ? t('Alterar Imagem')
                                                : t('Carregar Imagem')}
                                        </Typography>
                                    )}
                                </div>
                            </FormControl>
                        )}
                    </div>
                )}
            </Dropzone>
        </div>
    );
};

export default InputImage;
