import axios from 'axios';
import React, { useReducer } from 'react';
import { DEFAULT, LIBRARY } from '../../types';
import requisitionsContext from './requisitionsContext';
import requisitionsReducer from './requisitionsReducer';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

const RequisitionsState = props => {
    const initialState = {
        message: '',
        response: '',
        responseType: '',
        hasChanged: 0,
        successiveActions: 0,
        loading: false,
        requisitions: [],
        loans: [],
        awaitingList: [],
        book: {}
    };

    const [state, dispatch] = useReducer(requisitionsReducer, initialState);
    const [t] = useTranslation();
    const setLoading = () => dispatch({ type: DEFAULT.SET_LOADING });
    const setLoadingFalse = () => dispatch({ type: DEFAULT.SET_LOADING_FALSE });

    const resetMessage = () => dispatch({ type: DEFAULT.RESET_MESSAGE });
    const setHasChanged = () => dispatch({ type: DEFAULT.SET_HAS_CHANGED });

    const getRequisitions = async library => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER +
                `api/Library/Reservations?library=${library}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: LIBRARY.GET_REQUISITIONS,
            payload: res.data
        });
    };

    const getLoans = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/Library/Reservations/Loans`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: LIBRARY.GET_LOANS,
            payload: res.data
        });
    };

    const updateErrorMessage = (context, statusCode) => {
        switch (context) {
            case 'approve':
                return statusCode === 409
                    ? t('LIBRARY.approveRequisitionConflictError')
                    : t('LIBRARY.approveRequisitionError');
            case 'withdraw':
                return statusCode === 409
                    ? t('LIBRARY.withdrawRequisitionConflictError')
                    : t('LIBRARY.withdrawRequisitionError');
            case 'cancel':
                return statusCode === 409
                    ? t('LIBRARY.cancelRequisitionConflictError')
                    : t('LIBRARY.cancelRequisitionError');
            case 'devolution':
                return statusCode === 409
                    ? t('LIBRARY.devolutionRequisitionConflictError')
                    : t('LIBRARY.devolutionRequisitionError');
            default:
                return '';
        }
    };

    const updateRequisition = async (
        id,
        context,
        obj,
        emailData,
        fetchData,
        fromScreen
    ) => {
        axios
            .put(
                process.env.REACT_APP_SERVER +
                    'api/Library/Reservations/UpdateStatus/' +
                    id +
                    '?fromScreen=' +
                    fromScreen,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                dispatch({
                    type: LIBRARY.UPDATE_REQUISITION,
                    payload:
                        context === 'approve'
                            ? t('LIBRARY.approveRequisitionSuccess')
                            : context === 'withdraw'
                            ? t('LIBRARY.withdrawRequisitionSuccess')
                            : context === 'devolution'
                            ? t('LIBRARY.devolutionRequisitionSuccess')
                            : t('LIBRARY.cancelRequisitionSuccess'),
                    responseType: 'SUCCESS',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });

                if (context === 'withdraw') {
                    emailData.devolutionDate = response.data.returnDate;
                }

                getAllEmailsAndSend(
                    emailData,
                    emailData.context +
                        '_REQUISITION_EMAIL_' +
                        emailData.bookLibrary
                );

                if (
                    (context === 'devolution' || context === 'cancel') &&
                    response.data.nextUserFromQueue
                ) {
                    let emailDataCopy = { ...emailData };
                    emailDataCopy.endpoint = 'BookQueue/reserveToRequisition';
                    emailDataCopy.nextCollaboratorName =
                        response.data.nextUserFromQueue.name;
                    emailDataCopy.nextCollaboratorEmail =
                        response.data.nextUserFromQueue.email;

                    getAllEmailsAndSend(
                        emailDataCopy,
                        'NEW_RESERVE_TO_REQUISITION_EMAIL'
                    );
                }
            })
            .catch(error => {
                const statusCode = error.response.status;
                dispatch({
                    type: LIBRARY.UPDATE_REQUISITION,
                    payload: updateErrorMessage(context, statusCode),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                fetchData();
            });
    };

    const getAllEmailsAndSend = async (emailData, endpoint) => {
        axios
            .get(process.env.REACT_APP_SERVER + 'api/EmailUsers/' + endpoint, {
                headers: {
                    'Content-type': 'application/json'
                    // "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
                }
            })
            .then(response => {
                emailData.libraryManagers = response.data;
                sendEmailsRequisition(emailData);
            })
            .catch(error => {
                console.log(error);
            });
    };

    const sendEmailsRequisition = async emailData => {
        return axios
            .post(
                process.env.REACT_APP_EMAIL +
                    'api/Library/' +
                    emailData.endpoint,
                emailData,
                {
                    headers: {
                        'Content-type': 'application/json'
                    }
                }
            )
            .then(
                res => console.log('ok'),
                err => Promise.reject('error')
            );
    };

    const cancelQueue = async (id, obj, fetchData, emailData) => {
        axios
            .put(
                process.env.REACT_APP_SERVER + 'api/Library/Queue/Cancel/' + id,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                dispatch({
                    type: LIBRARY.CANCEL_QUEUE,
                    payload: t('LIBRARY.cancelQueueSuccess'),
                    responseType: 'SUCCESS',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
                getAllEmailsAndSend(
                    emailData,
                    'CANCELED_REQUISITION_QUEUE_EMAIL'
                );
            })
            .catch(error => {
                dispatch({
                    type: LIBRARY.CANCEL_QUEUE,
                    payload: t('LIBRARY.cancelQueueError'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                fetchData();
            });
    };

    const getAwaitingList = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/Library/Books/Queue`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: LIBRARY.GET_AWAITING_LIST,
            payload: res.data
        });
    };

    const getBookById = async id => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/Library/Books/Queue/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: LIBRARY.GET_BOOK_BY_ID,
            payload: res.data
        });
    };

    return (
        <requisitionsContext.Provider
            value={{
                loading: state.loading,
                message: state.message,
                hasChanged: state.hasChanged,
                response: state.response,
                responseType: state.responseType,
                successiveActions: state.successiveActions,
                setLoading,
                setLoadingFalse,
                resetMessage,
                setHasChanged,
                requisitions: state.requisitions,
                getRequisitions,
                updateRequisition,
                getLoans,
                loans: state.loans,
                cancelQueue,
                getAwaitingList,
                awaitingList: state.awaitingList,
                getBookById,
                book: state.book
            }}
        >
            {props.children}
        </requisitionsContext.Provider>
    );
};

export default RequisitionsState;
