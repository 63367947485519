import React from "react";
import "../../../../App.css";
import SideBar from "components/layout/sidebar/Sidebar";
import DashboardNavBar from "components/layout/DashboardNavBar";
import Birthdays from "./Birthdays";

const CallBirthdays = ({ match }) => {

    return (
        <div>
            <DashboardNavBar />
            <SideBar />
            <Birthdays />
        </div>
    );
}

export default CallBirthdays;