import axios from 'axios';
import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT, INVENTORY } from '../types';
import InventoryContext from './inventoryContext';
import InventoryReducer from './inventoryReducer';
import history from '../../history';
import { headers } from 'global/variables';
import Cookies from 'js-cookie';

const InventoryState = props => {
    const initialState = {
        place: {},
        bookCategory: {},
        itemCategory: {},
        itemStatus: {},
        equipmentItem: {},
        maintenance: {},
        book: {},
        exemplar: {},
        inventoryImage: {},
        myItens: [],
        mySoftwares: [],
        places: [],
        subitens: [],
        arraySubItens: [],
        bookCategories: [],
        itensCategories: [],
        allStatus: [],
        equipment: [],
        maintenances: [],
        historyEquipment: [],
        externalUserPlaces: [],
        externalUserItens: [],
        idEquipment: 0,
        response: '',
        responseType: '',
        linkEquipment: '',
        searchLinkEquipment: '',
        searchLinkSoftware: '',
        searchLinkBook: '',
        hasChanged: 0,
        successiveActions: 0,
        loading: false,
        loadingBooks: false,
        loadingSoftware: false,
        loadingEquipment: false,
        loadingRequisition: false,
        externalPartners: [],
        nextCodes: {},
        externalUserHistory: [],
        lsbdCodesHistory: [],
        books: [],
        libraryAdms: [],
        isbnAndTitle: [],
        admsPeopleManagement: [],
        historyExemplar: [],
        softwareCategories: [],
        authors: [],
        softwares: [],
        platforms: [],
        software: {},
        license: {},
        itensByUser: [],
        myRequests: [],
        collaboratorsRequests: [],
        collaboratorsManaged: [],
        licensesByUser: [],
        itensCategoriesByItensAvaliable: [],
        softwareCategoriesByLicensesAvaliable: [],
        softwaresForRequisition: [],
        requisition: {},
        licensesAll: [],
        approver: {},
        patrimonyManager: [],
        allRequisitions: [],
        itensAvailable: [],
        availableLicenses: [],
        usersEmail: null
    };

    const [t] = useTranslation();
    const [state, dispatch] = useReducer(InventoryReducer, initialState);
    const setLoading = () => dispatch({ type: DEFAULT.SET_LOADING });
    const setloadingBooks = () => dispatch({ type: DEFAULT.SET_LOADING_BOOKS });
    const setloadingSoftware = () =>
        dispatch({ type: DEFAULT.SET_LOADING_SOFTWARE });
    const setLoadingEquipment = () =>
        dispatch({ type: DEFAULT.SET_LOADING_EQUIPMENT });
    const setLoadingEquipmentFalse = () =>
        dispatch({ type: DEFAULT.SET_LOADING_EQUIPMENT_FALSE });
    const setLoadingRequisition = () =>
        dispatch({ type: INVENTORY.SET_LOADING_REQUISITION });
    const setLoadingRequisitionFalse = () =>
        dispatch({ type: INVENTORY.SET_LOADING_REQUISITION_FALSE });

    const resetMessage = () => dispatch({ type: DEFAULT.RESET_MESSAGE });
    const setHasChanged = () => dispatch({ type: DEFAULT.SET_HAS_CHANGED });

    // Union of Calls
    const getInfoMyItems = id => {
        setLoading();
        axios
            .all([
                axios.get(
                    process.env.REACT_APP_SERVER + `api/InventoryItem/${id}`,
                    {
                        headers: {
                            'Content-type': 'application/json',
                            Authorization:
                                'bearer ' +
                                (Cookies.get('auth-token')
                                    ? Cookies.get('auth-token')
                                    : '')
                        }
                    }
                ),
                axios.get(
                    process.env.REACT_APP_SERVER + `api/InventoryPlaces`,
                    {
                        headers: {
                            'Content-type': 'application/json',
                            Authorization:
                                'bearer ' +
                                (Cookies.get('auth-token')
                                    ? Cookies.get('auth-token')
                                    : '')
                        }
                    }
                ),
                axios.get(
                    process.env.REACT_APP_SERVER +
                        `api/Software/LicenseByUser/${id}`,
                    {
                        headers: {
                            'Content-type': 'application/json',
                            Authorization:
                                'bearer ' +
                                (Cookies.get('auth-token')
                                    ? Cookies.get('auth-token')
                                    : '')
                        }
                    }
                )
            ])
            .then(
                axios.spread((myItemsRes, placesRes, mySoftwaresRes) => {
                    dispatch({
                        type: INVENTORY.GET_MYITENS,
                        payload: myItemsRes.data
                    });
                    dispatch({
                        type: INVENTORY.GET_PLACES,
                        payload: placesRes.data
                    });
                    dispatch({
                        type: INVENTORY.GET_MYSOFTWARES,
                        payload: mySoftwaresRes.data
                    });
                })
            );
    };

    //Search
    function searchTermChanged(searchTerm) {
        dispatch({
            type: INVENTORY.SEARCH,
            filter: searchTerm
        });
    }

    const getItensByUser = async id => {
        setLoading();
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/InventoryItem/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_MYITENS,
            payload: res.data
        });
    };

    // PLACES

    const getPlaces = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/InventoryPlaces`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_PLACES,
            payload: res.data
        });
    };

    const getPlace = async id => {
        setLoading();
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/InventoryPlaces/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_PLACE,
            payload: res.data
        });
    };

    const addPlace = obj => {
        setLoading();
        axios
            .post(process.env.REACT_APP_SERVER + 'api/InventoryPlaces/', obj, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: INVENTORY.ADD_PLACE,
                        payload: t('INVENTORY.successfullyCreated'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: INVENTORY.ADD_PLACE,
                        payload: t('INVENTORY.unsuccessfullyCreated'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(() => {
                dispatch({
                    type: INVENTORY.ADD_PLACE,
                    payload: t('INVENTORY.nameAlreadyTaken'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            });
    };

    const updatePlace = obj => {
        setLoading();
        axios
            .put(
                process.env.REACT_APP_SERVER + `api/InventoryPlaces/${obj.id}`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(res => {
                if (res.status === 204) {
                    dispatch({
                        type: INVENTORY.UPDATE_PLACE,
                        payload: t('INVENTORY.successfullyEdited'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: INVENTORY.UPDATE_PLACE,
                        payload: 'An Error has Occurred',
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: INVENTORY.UPDATE_PLACE,
                    payload: error,
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions,
                    hasChanged: state.successiveActions + 2
                });
            });
    };

    const deletePlace = (id, active) => {
        setLoading();
        axios
            .delete(
                process.env.REACT_APP_SERVER + `api/InventoryPlaces/${id}`,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(res => {
                dispatch({
                    type: INVENTORY.DELETE_PLACE,
                    payload: t('INVENTORY.successfullyDeleted'),
                    responseType: 'SUCCESS',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .catch(error => {
                dispatch({
                    type: INVENTORY.DELETE_PLACE,
                    payload:
                        error.response.status === 404
                            ? t('INVENTORY.cantDeleteNotFound')
                            : t('INVENTORY.cantDeleteAssociated'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            });
    };

    // END PLACES

    // STARTED BOOK CATEGORY

    const getBookCategories = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/Library/Categories`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_BOOKCATEGORIES,
            payload: res.data
        });
    };

    const getBookCategory = async id => {
        setLoading();
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/Library/Categories/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_BOOKCATEGORY,
            payload: res.data
        });
    };

    const addBookCategory = (obj, fetchData) => {
        setLoading();
        axios
            .post(
                process.env.REACT_APP_SERVER + 'api/Library/Categories',
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: INVENTORY.ADD_BOOKCATEGORY,
                        payload: t('INVENTORY.BCsuccessfullyCreated'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: INVENTORY.ADD_BOOKCATEGORY,
                        payload: t('INVENTORY.BCunsuccessfullyCreated'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(() => {
                dispatch({
                    type: INVENTORY.ADD_BOOKCATEGORY,
                    payload: t('INVENTORY.BCnameAlreadyTaken'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                fetchData();
            });
    };

    const updateBookCategory = (obj, fetchData, id) => {
        setLoading();
        axios
            .put(
                process.env.REACT_APP_SERVER + `api/Library/Categories/${id}`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(res => {
                if (res.status === 200) {
                    dispatch({
                        type: INVENTORY.UPDATE_BOOKCATEGORY,
                        payload: t('INVENTORY.BCsuccessfullyEdited'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: INVENTORY.UPDATE_BOOKCATEGORY,
                        payload: 'An Error has Occurred',
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: INVENTORY.UPDATE_BOOKCATEGORY,
                    payload: t('INVENTORY.BCcantDeleteNotFound'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                fetchData();
            });
    };

    const deleteBookCategory = (id, obj, fetchData, handleCloseDelete) => {
        setLoading();
        axios
            .put(
                process.env.REACT_APP_SERVER +
                    `api/Library/Categories/delete/${id}`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(res => {
                dispatch({
                    type: INVENTORY.DELETE_BOOKCATEGORY,
                    payload: t('INVENTORY.BCsuccessfullyDeleted'),
                    responseType: 'SUCCESS',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .catch(error => {
                dispatch({
                    type: INVENTORY.DELETE_BOOKCATEGORY,
                    payload:
                        error.response.status === 404
                            ? t('INVENTORY.BCcantDeleteNotFound')
                            : t('INVENTORY.BCcantDeleteAssociated'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                fetchData();
                handleCloseDelete();
            });
    };

    // END BOOK CATEGORY

    // STARTED ITEM CATEGORY

    const getItensCategories = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/InventoryCategory`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_ITENSCATEGORIES,
            payload: res.data
        });
    };
    const getItensCategoriesByItensAvaliable = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/InventoryCategory/avaiable`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_ITENS_CATEGORIES_BY_ITENS_AVALIABLE,
            payload: res.data
        });
    };

    const getItemCategory = async id => {
        setLoading();
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/InventoryCategory/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_ITEMCATEGORY,
            payload: res.data
        });
    };

    const addItemCategory = obj => {
        setLoading();
        axios
            .post(
                process.env.REACT_APP_SERVER + 'api/InventoryCategory/',
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: INVENTORY.ADD_ITEMCATEGORY,
                        payload: t('INVENTORY.ICsuccessfullyCreated'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: INVENTORY.ADD_ITEMCATEGORY,
                        payload: t('INVENTORY.ICunsuccessfullyCreated'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(() => {
                dispatch({
                    type: INVENTORY.ADD_ITEMCATEGORY,
                    payload: t('INVENTORY.ICnameAlreadyTaken'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            });
    };

    const updateItemCategory = obj => {
        setLoading();
        axios
            .put(
                process.env.REACT_APP_SERVER +
                    `api/InventoryCategory/${obj.id}`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(res => {
                if (res.status === 204) {
                    dispatch({
                        type: INVENTORY.UPDATE_ITEMCATEGORY,
                        payload: t('INVENTORY.ICsuccessfullyEdited'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: INVENTORY.UPDATE_ITEMCATEGORY,
                        payload: 'An Error has Occurred',
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: INVENTORY.UPDATE_ITEMCATEGORY,
                    payload: error,
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions,
                    hasChanged: state.successiveActions + 2
                });
            });
    };

    const deleteItemCategory = (id, active) => {
        setLoading();
        let obj = {};
        axios
            .put(
                process.env.REACT_APP_SERVER +
                    `api/InventoryCategory/disable/${id}`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(res => {
                dispatch({
                    type: INVENTORY.DELETE_ITEMCATEGORY,
                    payload: t('INVENTORY.ICsuccessfullyDeleted'),
                    responseType: 'SUCCESS',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .catch(error => {
                dispatch({
                    type: INVENTORY.DELETE_ITEMCATEGORY,
                    payload:
                        error.response.status === 404
                            ? t('INVENTORY.ICcantDeleteNotFound')
                            : t('INVENTORY.ICcantDeleteAssociated'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            });
    };

    // END ITEM CATEGORY

    // STARTED ITEM STATUS

    const getAllStatus = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/InventoryItemStatus`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_ALLSTATUS,
            payload: res.data
        });
    };

    const getItemStatus = async id => {
        setLoading();
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/InventoryItemStatus/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_ITEMSTATUS,
            payload: res.data
        });
    };

    const addItemStatus = obj => {
        setLoading();
        axios
            .post(
                process.env.REACT_APP_SERVER + 'api/InventoryItemStatus/',
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: INVENTORY.ADD_ITEMSTATUS,
                        payload: t('INVENTORY.statusSuccessfullyCreated'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: INVENTORY.ADD_ITEMSTATUS,
                        payload: t('INVENTORY.statusUnsuccessfullyCreated'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(() => {
                dispatch({
                    type: INVENTORY.ADD_ITEMSTATUS,
                    payload: t('INVENTORY.statsuNameAlreadyTaken'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            });
    };

    const updateItemStatus = obj => {
        setLoading();
        axios
            .put(
                process.env.REACT_APP_SERVER +
                    `api/InventoryItemStatus/${obj.id}`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(res => {
                if (res.status === 204) {
                    dispatch({
                        type: INVENTORY.UPDATE_ITEMSTATUS,
                        payload: t('INVENTORY.statusSuccessfullyEdited'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: INVENTORY.UPDATE_ITEMSTATUS,
                        payload: 'An Error has Occurred',
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: INVENTORY.UPDATE_ITEMSTATUS,
                    payload: error,
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions,
                    hasChanged: state.successiveActions + 2
                });
            });
    };

    const deleteItemStatus = (id, active) => {
        setLoading();
        let obj = {};
        axios
            .put(
                process.env.REACT_APP_SERVER +
                    `api/InventoryItemStatus/disable/${id}`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(res => {
                dispatch({
                    type: INVENTORY.DELETE_ITEMSTATUS,
                    payload: t('INVENTORY.statusSuccessfullyDeleted'),
                    responseType: 'SUCCESS',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .catch(error => {
                dispatch({
                    type: INVENTORY.DELETE_ITEMSTATUS,
                    payload:
                        error.response.status === 404
                            ? t('INVENTORY.statuscantDeleteNotFound')
                            : t('INVENTORY.statusDeleteAssociated'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            });
    };

    // END ITEM STATUS

    // STARTED EQUIPMENT

    const getAllEquipment = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/InventoryItem/Equipament`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_ALLEQUIPMENT,
            payload: res.data
        });
    };

    const getEquipment = async id => {
        setLoading();
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/InventoryItem/edit/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_EQUIPMENT,
            payload: res.data
        });
    };

    const cleanArraySubItem = () => {
        while (state.arraySubItens.length) {
            state.arraySubItens.pop();
        }
    };

    const addEquipment = (obj, subitens, emailDatas, emailDataAssignment) => {
        setLoading();
        axios
            .post(process.env.REACT_APP_SERVER + 'api/InventoryItem/', obj, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(response => {
                var idItem = {
                    url:
                        process.env.REACT_APP_FRONT +
                        `InventoryEquipment/${response.data.items[0].id}`
                };
                var objEmail = Object.assign({}, emailDatas, idItem);
                sendEmailNewEquipment(objEmail);

                if (state.arraySubItens.length > 0) {
                    const newArraySubItens = subitens.map(function (obj) {
                        obj.parentId = response.data.items[0].id;
                        obj.placeId = response.data.items[0].placeId;
                        obj.responsibleId =
                            response.data.items[0].responsibleId;
                        obj.responsibleExternalId =
                            response.data.items[0].responsibleExternalId;
                        obj.category = null;
                        obj.itemStatus = null;
                        obj.place = null;
                        obj.project = null;
                        obj.responsible = null;
                        return obj;
                    });
                    updateSubItem(newArraySubItens);
                    cleanArraySubItem();
                }

                if (response.status === 201) {
                    if (Object.keys(response.data).length !== 0) {
                        response.data.items.forEach(element => {
                            if (emailDataAssignment.length > 0) {
                                let batchEmailData = [
                                    ...JSON.parse(
                                        JSON.stringify(emailDataAssignment)
                                    )
                                ];
                                batchEmailData[0].itens[0][2] = element.ufcCode
                                    ? 'UFC-' + element.ufcCode
                                    : element.fcpcCode
                                    ? 'FCPC-' + element.fcpcCode
                                    : element.lsbdCode
                                    ? 'LSBD-' + element.lsbdCode
                                    : element.externCode
                                    ? element.externPartner +
                                      '-' +
                                      element.externCode
                                    : '-';
                                batchEmailData[0].itens[0][3] =
                                    element.serialNumber
                                        ? element.serialNumber
                                        : '-';
                                sendEmailAssignment(batchEmailData);
                            }
                        });
                    }

                    dispatch({
                        type: INVENTORY.ADD_EQUIPMENT,
                        payload: t('INVENTORY.addedEquipment'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: INVENTORY.ADD_EQUIPMENT,
                        payload: t('INVENTORY.equipmentUnsuccessfullyCreated'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: INVENTORY.ADD_EQUIPMENT,
                    payload: t('INVENTORY.equipmentUnsuccessfullyCreated'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            });
    };

    const formatDate = date => {
        return `${date.getFullYear()}-${
            date.getMonth() + 1
        }-${date.getDate()} ${date.getUTCHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    };

    const updateEquipment = (
        obj,
        subitens,
        arrayMaintenances = [],
        maintenancesToDelete = [],
        emailData
    ) => {
        setLoading();
        axios
            .put(process.env.REACT_APP_SERVER + 'api/InventoryItem/put/', obj, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(res => {
                if (res.status === 201) {
                    if (emailData.length > 0) {
                        sendEmailAssignment(emailData);
                    }
                    if (arrayMaintenances.length > 0) {
                        let formData = new FormData();
                        arrayMaintenances.forEach((maintenance, index) => {
                            formData.append(
                                `maintenance[${index}].itemId`,
                                maintenance.itemId
                            );
                            formData.append(
                                `maintenance[${index}].startDate`,
                                maintenance.startDate
                                    ? formatDate(maintenance.startDate)
                                    : formatDate(new Date())
                            );
                            formData.append(
                                `maintenance[${index}].endDate`,
                                maintenance.endDate
                                    ? formatDate(maintenance.endDate)
                                    : formatDate(new Date())
                            );
                            formData.append(
                                `maintenance[${index}].description`,
                                maintenance.description
                            );
                            formData.append(
                                `maintenance[${index}].company`,
                                maintenance ? maintenance.company : ''
                            );
                            formData.append(
                                `maintenance[${index}].value`,
                                maintenance ? maintenance.value : ''
                            );
                            formData.append(
                                `maintenance[${index}].numberPhone`,
                                maintenance ? maintenance.numberPhone : ''
                            );
                            formData.append(
                                `maintenance[${index}].invoice`,
                                maintenance ? maintenance.invoice : ''
                            );

                            for (let i in maintenance.files) {
                                formData.append('files', maintenance.files[i]);
                            }
                            addMaintenance(formData);
                            formData = new FormData();
                        });
                    }
                    if (maintenancesToDelete.length > 0) {
                        for (let i in maintenancesToDelete) {
                            deleteMaintenance(maintenancesToDelete[i]);
                        }
                    }

                    if (subitens.length > 0) {
                        const newArraySubItens = subitens.map(function (obj) {
                            obj.parentId = res.data.items[0].id;
                            obj.placeId = res.data.items[0].placeId;
                            obj.responsibleId = res.data.items[0].responsibleId;
                            obj.responsibleExternalId =
                                res.data.items[0].responsibleExternalId;
                            obj.category = null;
                            obj.itemStatus = null;
                            obj.place = null;
                            obj.project = null;
                            obj.responsible = null;
                            return obj;
                        });
                        updateSubItem(newArraySubItens);
                        cleanArraySubItem();
                    }
                    dispatch({
                        type: INVENTORY.UPDATE_EQUIPMENT,
                        payload: t('INVENTORY.equipmentSuccessfullyEdited'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: INVENTORY.UPDATE_EQUIPMENT,
                        payload: 'An Error has Occurred',
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: INVENTORY.UPDATE_EQUIPMENT,
                    payload: error,
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions,
                    hasChanged: state.successiveActions + 2
                });
            });
    };

    const updateSubItem = obj => {
        setLoading();
        axios
            .put(process.env.REACT_APP_SERVER + `api/InventoryItem/`, obj, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(res => {
                if (res.status === 204) {
                    // dispatch({
                    //   type: INVENTORY.UPDATE_EQUIPMENT,
                    //   // payload: t("INVENTORY.statusSuccessfullyEdited"),
                    //   responseType: "SUCCESS",
                    //   successiveActions: state.successiveActions + 1,
                    //   hasChanged: state.successiveActions + 2
                    // });
                } else {
                    dispatch({
                        type: INVENTORY.UPDATE_EQUIPMENT,
                        payload: 'An Error has Occurred',
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: INVENTORY.UPDATE_EQUIPMENT,
                    payload: error,
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions,
                    hasChanged: state.successiveActions + 2
                });
            });
    };

    const deleteEquipment = (id, active) => {
        setLoading();
        axios
            .delete(process.env.REACT_APP_SERVER + `api/InventoryItem/${id}`, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(res => {
                dispatch({
                    type: INVENTORY.DELETE_ITEMSTATUS,
                    payload: t('INVENTORY.deleteEquipmentSuccsses'),
                    responseType: 'SUCCESS',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .catch(error => {
                dispatch({
                    type: INVENTORY.DELETE_ITEMSTATUS,
                    payload:
                        error.response.status === 304
                            ? t('INVENTORY.equipmentcantDeleteExistsCodes')
                            : error.response.status === 405
                            ? t('INVENTORY.equipmentcantNotIsConsumable')
                            : error.response.status === 403
                            ? t(
                                  'INVENTORY.equipmentcantNotIsInvalidOrDiscarded'
                              )
                            : error.response.status === 404
                            ? t('INVENTORY.equipmentNotFound')
                            : error.response.status === 409
                            ? t('INVENTORY.equipmentDeleteAssociated')
                            : t('INVENTORY.deleteEquipmentError'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            });
    };

    // END EQUIPMENT

    // START SUBITEM

    const addSubItensArray = obj => {
        dispatch({
            type: INVENTORY.ADD_SUBITENS,
            payload: obj
        });
    };

    const deleteSubItensArray = obj => {
        dispatch({
            type: INVENTORY.DELETE_SUBITENS,
            payload: obj
        });
    };

    // END SUBITEM

    const getExternalPartners = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/InventoryItem/externalPartners`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_EXTERNAL_PARTNERS,
            payload: res.data
        });
    };

    const getNextCodes = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/InventoryItem/nextCodes`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_NEXT_CODES,
            payload: res.data
        });
    };

    // STARTED MAINTENANCE

    const getMaintenanceForItem = async id => {
        setLoading();
        const res = await axios.get(
            process.env.REACT_APP_SERVER +
                `api/EquipmentMaintenance/item/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_ALLMAINTENANCES,
            payload: res.data
        });
    };

    const getMaintenance = async id => {
        setLoading();
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/EquipmentMaintenance/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_MAINTENANCE,
            payload: res.data
        });
    };

    const addMaintenance = obj => {
        setLoading();
        axios
            .post(
                process.env.REACT_APP_SERVER + 'api/EquipmentMaintenance/',
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: INVENTORY.ADD_MAINTENANCE
                        // payload: t("INVENTORY.statusSuccessfullyCreated"),
                        // responseType: "SUCCESS",
                        // successiveActions: state.successiveActions + 1,
                        // hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: INVENTORY.ADD_MAINTENANCE
                        // payload: t("INVENTORY.statusUnsuccessfullyCreated"),
                        // responseType: "ERROR",
                        // successiveActions: state.successiveActions + 1,
                        // hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(() => {
                dispatch({
                    type: INVENTORY.ADD_MAINTENANCE
                    // payload: t("INVENTORY.statsuNameAlreadyTaken"),
                    // responseType: "ERROR",
                    // successiveActions: state.successiveActions + 1,
                    // hasChanged: state.successiveActions + 2
                });
            });
    };

    const updateMaintenance = obj => {
        setLoading();
        axios
            .put(
                process.env.REACT_APP_SERVER +
                    `api/EquipmentMaintenance/${obj.id}`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(res => {
                if (res.status === 204) {
                    dispatch({
                        type: INVENTORY.UPDATE_MAINTENANCE,
                        payload: t('INVENTORY.statusSuccessfullyEdited'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: INVENTORY.UPDATE_MAINTENANCE,
                        payload: 'An Error has Occurred',
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: INVENTORY.UPDATE_MAINTENANCE,
                    payload: error,
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions,
                    hasChanged: state.successiveActions + 2
                });
            });
    };

    const deleteMaintenance = id => {
        setLoading();
        axios
            .delete(
                process.env.REACT_APP_SERVER + `api/EquipmentMaintenance/${id}`,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(res => {})
            .catch(error => {
                dispatch({
                    type: INVENTORY.DELETE_MAINTENANCE
                    // payload:
                    //   error.response.status === 404
                    //     ? t("INVENTORY.maintenancecantDeleteNotFound")
                    //     : t("INVENTORY.maintenanceDeleteAssociated"),
                    // responseType: "ERROR",
                    // successiveActions: state.successiveActions + 1,
                    // hasChanged: state.successiveActions + 2
                });
            });
    };
    // END MAINTENANCE

    // START HISTORY EQUIPMENT AND BOOK
    const getHistoryEquipment = async id => {
        setLoading();
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/HistoryEquipment/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_HISTORYEQUIPMENT,
            payload: res.data
        });
    };

    // Old
    // const getHistoryExemplar = async id => {
    //     setLoading();
    //     const res = await axios.get(
    //         process.env.REACT_APP_SERVER +
    //             `api/HistoryEquipment/exemplar/${id}`,
    //         {
    //             headers: {
    //                 'Content-type': 'application/json',
    //                 Authorization:
    //                     'bearer ' +
    //                     (Cookies.get('auth-token')
    //                         ? Cookies.get('auth-token')
    //                         : '')
    //             }
    //         }
    //     );
    //     dispatch({
    //         type: INVENTORY.GET_HISTORYEXEMPLAR,
    //         payload: res.data
    //     });
    // };

    // Refactor
    const getHistoryExemplar = async id => {
        setLoading();
        const res = await axios.get(
            process.env.REACT_APP_SERVER +
                `api/LibraryItemsHistory/Exemplar/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_HISTORYEXEMPLAR,
            payload: res.data
        });
    };

    // END  HISTORY EQUIPMENT AND BOOK

    // START EXTERNAL USERS FUNCTIONS
    const getExternalPlaces = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/ExternalUserPlaces`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_EXTERNAL_USER_PLACES,
            payload: res.data
        });
    };

    const getEquipmentByExternalUser = async id => {
        setLoading();
        const res = await axios.get(
            process.env.REACT_APP_SERVER +
                `api/InventoryItem/externalUser/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_EQUIPMENTBYEXTERNALUSER,
            payload: res.data
        });
    };
    // END  EXTERNAL USERS FUNCTIONS

    const getExternalHistory = async id => {
        setLoading();
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/HistoryEquipment`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        const Equipments = await axios.get(
            process.env.REACT_APP_SERVER +
                `api/InventoryItem/externalUser/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );

        var idsPlacesWithEquipment = Equipments.data.map(function (elem) {
            return elem.id;
        });

        var dataFilter = res.data.filter(item =>
            idsPlacesWithEquipment.includes(item.itemId)
        );

        dispatch({
            type: INVENTORY.GET_EXTERNALUSERSHISTORY,
            payload: dataFilter
        });
    };

    const getLsbdCodesHistory = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/InventoryItem/deletedCodes`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_LSBD_CODES_HISTORY,
            payload: res.data
        });
    };

    // BOOKS

    const setSearchLinkBook = async search => {
        dispatch({
            type: INVENTORY.SET_SEARCH_BOOK,
            payload: search
        });
    };

    const getBooks = async () => {
        setloadingBooks();
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/Library/Books`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_BOOKS,
            payload: res.data
        });
    };

    const addBook = (obj, emailDatas, emailDataAssignment, setMustRedirect) => {
        setLoading();
        axios
            .post(process.env.REACT_APP_SERVER + 'api/Library/Books/', obj, {
                headers: {
                    'Content-type': 'multipart/form-data',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(response => {
                sendEmailNewBook(emailDatas);
                if (response.status === 201) {
                    if (response.data.length > 0) {
                        response.data[0].libraryExemplar.forEach(element => {
                            if (emailDataAssignment.length > 0) {
                                let batchEmailData = [
                                    ...JSON.parse(
                                        JSON.stringify(emailDataAssignment)
                                    )
                                ];
                                batchEmailData[0].itens[0][2] =
                                    'BIB-' + element.exemplarCode;
                                sendEmailAssignment(batchEmailData);
                            }
                        });
                    }
                    dispatch({
                        type: INVENTORY.ADD_EQUIPMENT,
                        payload: t('INVENTORY.addedBook'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: INVENTORY.ADD_EQUIPMENT,
                        payload: t('INVENTORY.bookUnsuccessfullyCreated'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: INVENTORY.ADD_EQUIPMENT,
                    payload:
                        error.status === 400
                            ? t(
                                  'INVENTORY.bookUnsuccessfullyCreatedIsbnAlreadyExists'
                              )
                            : t('INVENTORY.bookUnsuccessfullyCreated'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                setMustRedirect(true);
            });
    };

    const addExemplar = (
        obj,
        emailDatas,
        emailDataAssignment,
        setMustRedirect
    ) => {
        setLoading();
        axios
            .post(
                process.env.REACT_APP_SERVER + 'api/Library/Exemplars/',
                obj,
                {
                    headers: {
                        'Content-type': 'multipart/form-data',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                sendEmailNewBook(emailDatas);
                if (response.status === 200) {
                    if (response.data) {
                        if (emailDataAssignment.length > 0) {
                            let batchEmailData = [
                                ...JSON.parse(
                                    JSON.stringify(emailDataAssignment)
                                )
                            ];
                            batchEmailData[0].itens[0][2] =
                                'BIB-' + response.data.code;
                            sendEmailAssignment(batchEmailData);
                        }
                    }
                    dispatch({
                        type: INVENTORY.ADD_EQUIPMENT,
                        payload: t('INVENTORY.addedExemplar'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: INVENTORY.ADD_EQUIPMENT,
                        payload: t('INVENTORY.exemplarUnsuccessfullyCreated'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: INVENTORY.ADD_EQUIPMENT,
                    payload:
                        error.status === 400
                            ? t(
                                  'INVENTORY.bookUnsuccessfullyCreatedIsbnAlreadyExists'
                              )
                            : t('INVENTORY.exemplarUnsuccessfullyCreated'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                setMustRedirect(true);
            });
    };

    const getLibraryAdms = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/InventoryItem/adms`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_LIBRARY_ADMS,
            payload: res.data
        });
    };

    const getIsbnAndTitles = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/Library/Books`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_ISBN_AND_TITLES,
            payload: res.data
        });
    };

    const cleanBook = () => {
        dispatch({
            type: INVENTORY.CLEAN_BOOK
        });
    };

    const getBook = async (id, setMustRedirect) => {
        setLoading();
        const res = await axios
            .get(process.env.REACT_APP_SERVER + `api/Library/Books/${id}`, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(res => {
                dispatch({
                    type: INVENTORY.GET_BOOK,
                    payload: res.data
                });
            })
            .catch(error => {
                dispatch({
                    type: INVENTORY.DELETE_BOOK,
                    payload:
                        error.response.status === 404
                            ? t('INVENTORY.bookCantDeleteNotFound')
                            : t('INVENTORY.BookCantDeleteAssociated'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
                if (error.response.status === 404) {
                    setMustRedirect(true);
                }
            });
    };

    const getInventoryImage = async id => {
        setLoading();
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/inventoryImage/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_INVENTORY_IMAGE,
            payload: res.data
        });
    };

    const UpdateBook = (obj, id, setMustRedirect) => {
        setLoading();
        axios
            .put(
                process.env.REACT_APP_SERVER + `api/Library/Books/${id}`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                if (response.status === 200) {
                    dispatch({
                        type: INVENTORY.ADD_EQUIPMENT,
                        payload: t('INVENTORY.updatedBook'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: INVENTORY.ADD_EQUIPMENT,
                        payload: t('INVENTORY.bookUnsuccessfullyUpdated'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: INVENTORY.ADD_EQUIPMENT,
                    payload: t('INVENTORY.bookUnsuccessfullyUpdated'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                setMustRedirect(true);
            });
    };

    const cleanExemplar = () => {
        dispatch({
            type: INVENTORY.CLEAN_EXEMPLAR
        });
    };

    const getExemplar = async id => {
        setLoading();
        const res = await axios
            .get(process.env.REACT_APP_SERVER + `api/Library/Exemplars/${id}`, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(res => {
                dispatch({
                    type: INVENTORY.GET_EXEMPLAR,
                    payload: res.data
                });
            })
            .catch(error => {
                dispatch({
                    type: INVENTORY.DELETE_BOOK,
                    payload:
                        error.response.status === 404
                            ? t('INVENTORY.exemplarCantDeleteNotFound')
                            : t('INVENTORY.BookCantDeleteAssociated'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            });
    };

    const UpdateExemplar = (obj, id, setMustRedirect, emailDataAssignment) => {
        setLoading();
        axios
            .put(
                process.env.REACT_APP_SERVER + `api/Library/Exemplars/${id}`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                if (response.status === 200) {
                    if (emailDataAssignment && emailDataAssignment.length > 0) {
                        sendEmailAssignment(emailDataAssignment);
                    }
                    dispatch({
                        type: INVENTORY.UPDATE_EXEMPLAR,
                        payload: t('INVENTORY.updatedExemplar'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: INVENTORY.UPDATE_EXEMPLAR,
                        payload: t('INVENTORY.exemplarUnsuccessfullyUpdated'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: INVENTORY.UPDATE_EXEMPLAR,
                    payload: t('INVENTORY.exemplarUnsuccessfullyUpdated'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                setMustRedirect(true);
            });
    };

    const sendEmailNewEquipment = async emailData => {
        return axios
            .post(
                process.env.REACT_APP_EMAIL + 'api/newEquipment/sendEmail',
                emailData,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(
                res => console.log('ok'),
                err => Promise.reject('error')
            );
    };

    const geAdmsPeopleManagement = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/InventoryItem/admsRh`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_ADMS_PEOPLE_MANAGEMENT,
            payload: res.data
        });
    };

    const saveLinkEquipment = async link => {
        dispatch({
            type: INVENTORY.SAVE_LINK_EQUIPMENT,
            payload: link
        });
    };

    const setSearchLinkEquipment = async search => {
        dispatch({
            type: INVENTORY.SET_SEARCH_EQUIPMENT,
            payload: search
        });
    };

    const sendEmailNewBook = async emailData => {
        return axios
            .post(
                process.env.REACT_APP_EMAIL + 'api/newBook/sendEmail',
                emailData,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(
                res => console.log('ok'),
                err => console.log(err) && Promise.reject('error')
            );
    };

    const deleteBook = (id, hasRented, fetchData) => {
        if (hasRented) {
            dispatch({
                type: INVENTORY.DELETE_BOOK,
                payload: t('INVENTORY.bookCantDeleteHasRented'),
                responseType: 'ERROR',
                successiveActions: state.successiveActions + 1,
                hasChanged: state.successiveActions + 2
            });
        } else {
            setLoading();
            axios
                .delete(
                    process.env.REACT_APP_SERVER + `api/Library/Books/${id}`,
                    {
                        headers: {
                            'Content-type': 'application/json',
                            Authorization:
                                'bearer ' +
                                (Cookies.get('auth-token')
                                    ? Cookies.get('auth-token')
                                    : '')
                        }
                    }
                )
                .then(res => {
                    dispatch({
                        type: INVENTORY.DELETE_BOOK,
                        payload: t('INVENTORY.bookSuccessfullyDeleted'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                })
                .catch(error => {
                    dispatch({
                        type: INVENTORY.DELETE_BOOK,
                        payload:
                            error.response.status === 404
                                ? t('INVENTORY.bookCantDeleteNotFound')
                                : t('INVENTORY.BookCantDeleteAssociated'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                })
                .finally(() => {
                    fetchData();
                });
        }
    };

    const deleteExemplar = (id, hasRented, fetchData) => {
        if (hasRented) {
            dispatch({
                type: INVENTORY.DELETE_BOOK,
                payload: t('INVENTORY.exemplarCantDeleteHasRented'),
                responseType: 'ERROR',
                successiveActions: state.successiveActions + 1,
                hasChanged: state.successiveActions + 2
            });
        } else {
            setLoading();
            axios
                .delete(
                    process.env.REACT_APP_SERVER +
                        `api/Library/Exemplars/${id}`,
                    {
                        headers: {
                            'Content-type': 'application/json',
                            Authorization:
                                'bearer ' +
                                (Cookies.get('auth-token')
                                    ? Cookies.get('auth-token')
                                    : '')
                        }
                    }
                )
                .then(res => {
                    dispatch({
                        type: INVENTORY.DELETE_BOOK,
                        payload: t('INVENTORY.exemplarSuccessfullyDeleted'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                })
                .catch(error => {
                    dispatch({
                        type: INVENTORY.DELETE_BOOK,
                        payload:
                            error.response.status === 404
                                ? t('INVENTORY.exemplarCantDeleteNotFound')
                                : t('INVENTORY.exemplarcantDeleteAssociated'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                })
                .finally(() => {
                    fetchData();
                });
        }
    };

    const getSoftwareCategories = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/SoftwareCategories`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_SOFTWARE_CATEGORIES,
            payload: res.data
        });
    };

    const addSoftwareCategory = obj => {
        setLoading();
        axios
            .post(
                process.env.REACT_APP_SERVER + 'api/SoftwareCategories/',
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: INVENTORY.ADD_SOFTWARE_CATEGORY,
                        payload: t('INVENTORY.SCSuccessfullyCreated'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: INVENTORY.ADD_SOFTWARE_CATEGORY,
                        payload: t('INVENTORY.SCUnsuccessfullyCreated'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(() => {
                dispatch({
                    type: INVENTORY.ADD_SOFTWARE_CATEGORY,
                    payload: t('INVENTORY.nameSoftwareCategoryAlreadyTaken'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            });
    };

    const updateSoftwareCategory = obj => {
        setLoading();
        axios
            .put(
                process.env.REACT_APP_SERVER +
                    `api/SoftwareCategories/${obj.id}`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(res => {
                if (res.status === 204) {
                    dispatch({
                        type: INVENTORY.UPDATE_SOFTWARE_CATEGORY,
                        payload: t('INVENTORY.SCSuccessfullyEdited'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: INVENTORY.UPDATE_SOFTWARE_CATEGORY,
                        payload: 'An Error has Occurred',
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: INVENTORY.UPDATE_SOFTWARE_CATEGORY,
                    payload: error,
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions,
                    hasChanged: state.successiveActions + 2
                });
            });
    };

    const deleteSoftwareCategory = id => {
        setLoading();
        let obj = {};
        axios
            .put(
                process.env.REACT_APP_SERVER +
                    `api/SoftwareCategories/disable/${id}`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(res => {
                dispatch({
                    type: INVENTORY.DELETE_SOFTWARE_CATEGORY,
                    payload: t('INVENTORY.SCSuccessfullyDeleted'),
                    responseType: 'SUCCESS',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .catch(error => {
                dispatch({
                    type: INVENTORY.DELETE_SOFTWARE_CATEGORY,
                    payload:
                        error.response.status === 404
                            ? t('INVENTORY.SCCantDeleteNotFound')
                            : t('INVENTORY.SCCantDeleteAssociated'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            });
    };

    const addAuthor = obj => {
        axios.post(process.env.REACT_APP_SERVER + 'api/LibraryAuthor', obj, {
            headers: {
                'Content-type': 'application/json',
                Authorization:
                    'bearer ' +
                    (Cookies.get('auth-token') ? Cookies.get('auth-token') : '')
            }
        });
    };

    const getAuthors = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/Library/Authors`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_AUTHORS,
            payload: res.data
        });
    };

    // SOFTWARE

    const getSoftwares = async () => {
        setloadingSoftware();
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/Software`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_SOFTWARES,
            payload: res.data
        });
    };

    const getSoftwaresForRequisition = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/Software/only`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_SOFTWARES_ONLY,
            payload: res.data
        });
    };

    const getPlartforms = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/InventoryPlatform`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_PLATFORMS,
            payload: res.data
        });
    };

    const getSoftware = async id => {
        setLoading();

        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/Software/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_SOFTWARE,
            payload: res.data
        });
    };

    const SetDeleteSoftware = (id, obj) => {
        setLoading();
        axios
            .put(process.env.REACT_APP_SERVER + 'api/Software/disable/', obj, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: INVENTORY.UPDATE_DELETE_SOFTWARE,
                        payload: t('INVENTORY.DeleteSoftware'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: INVENTORY.UPDATE_DELETE_SOFTWARE,
                        payload: t('INVENTORY.softwareUnsuccessfullyDeleted'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                if (error.response.status === 409) {
                    dispatch({
                        type: INVENTORY.UPDATE_DELETE_LICENSE,
                        payload: t('INVENTORY.SoftwareNotFound'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: INVENTORY.UPDATE_DELETE_LICENSE,
                        payload: t('INVENTORY.softwareUnsuccessfullyDeleted'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            });
    };

    const setDeleteLicense = (id, obj) => {
        setLoading();
        axios
            .put(
                process.env.REACT_APP_SERVER + `api/License/${id}/disable/`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: INVENTORY.UPDATE_DELETE_LICENSE,
                        payload: t('INVENTORY.DeleteLicense'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: INVENTORY.UPDATE_DELETE_LICENSE,
                        payload: t('INVENTORY.licenseUnsuccessfullyDeleted'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                if (error.response.status === 409) {
                    dispatch({
                        type: INVENTORY.UPDATE_DELETE_LICENSE,
                        payload: t('INVENTORY.LicenseNotFound'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: INVENTORY.UPDATE_DELETE_LICENSE,
                        payload: t('INVENTORY.licenseUnsuccessfullyDeleted'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            });
    };

    const sendEmailSoftwareAndLicense = async emailData => {
        return axios
            .post(
                process.env.REACT_APP_EMAIL +
                    'api/newSoftwareLicense/sendEmail',
                emailData,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(
                res => console.log('ok'),
                err => Promise.reject('error')
            );
    };

    const setSearchLinkSoftware = async search => {
        dispatch({
            type: INVENTORY.SET_SEARCH_SOFTWARE,
            payload: search
        });
    };

    const addSoftwareAndLicense = (
        obj,
        emailDates,
        context,
        path,
        emailDataAssignment
    ) => {
        setLoading();
        axios
            .post(process.env.REACT_APP_SERVER + 'api/Software/', obj, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(response => {
                if (response.status === 201) {
                    var idItem = {
                        url:
                            process.env.REACT_APP_FRONT +
                            `Software/LicenseView/${response.data.inventoryLicense[0].id}`
                    };
                    var objEmail = Object.assign({}, emailDates, idItem);
                    sendEmailSoftwareAndLicense(objEmail);
                    if (emailDataAssignment.length > 0) {
                        sendEmailAssignment(emailDataAssignment);
                    }
                    dispatch({
                        type: INVENTORY.ADD_EQUIPMENT,
                        payload:
                            context === 'newLicenseOnly'
                                ? t('INVENTORY.updateLicense')
                                : t('INVENTORY.addedSoftware'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: INVENTORY.ADD_EQUIPMENT,
                        payload:
                            context === 'newLicenseOnly'
                                ? t('INVENTORY.licenseUnsuccessfullyCreated')
                                : t('INVENTORY.softwareUnsuccessfullyCreated'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                let msg = t(
                    'INVENTORY.bookUnsuccessfullyCreatedIsbnAlreadyExists'
                );
                if (error.status !== 400) {
                    if (context === 'newLicenseOnly') {
                        msg = t('INVENTORY.licenseUnsuccessfullyCreated');
                    } else {
                        msg = t('INVENTORY.softwareUnsuccessfullyCreated');
                    }
                }
                dispatch({
                    type: INVENTORY.ADD_EQUIPMENT,
                    payload: msg,
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                if (path) history.push(path);
            });
    };

    const UpdateSoftware = (obj, path) => {
        setLoading();
        axios
            .put(process.env.REACT_APP_SERVER + 'api/Software/', obj, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: INVENTORY.UPDATE_SOFTWARE,
                        payload: t('INVENTORY.updateSoftware'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: INVENTORY.UPDATE_SOFTWARE,
                        payload: t('INVENTORY.softwareUnsuccessfullyUpdated'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: INVENTORY.UPDATE_SOFTWARE,
                    payload: t('INVENTORY.softwareUnsuccessfullyUpdated'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                if (path) history.push(path);
            });
    };

    const getLicense = async id => {
        setLoading();
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/Software/License/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_LICENSE,
            payload: res.data
        });
    };

    const getLicensesAll = async () => {
        setLoading();
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/Software/allLicense/`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_LICENSES_ALL,
            payload: res.data
        });
    };

    const getLicensesAvailable = async () => {
        setLoading();
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/Software/available`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_LICENSES_AVAILABLE,
            payload: res.data
        });
    };

    const getItensbyUsersLicense = async (id, type) => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER +
                `api/Software/itensUsers/${id}/${type}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_ITENS_BY_USERS_LICENSE,
            payload: res.data
        });
    };

    const UpdateLicense = (obj, path, emailData) => {
        setLoading();
        axios
            .put(process.env.REACT_APP_SERVER + 'api/Software/', obj, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(response => {
                if (response.status === 201) {
                    if (emailData.length > 0) {
                        sendEmailAssignment(emailData);
                    }
                    dispatch({
                        type: INVENTORY.UPDATE_SOFTWARE,
                        payload: t('INVENTORY.updateSoftware'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: INVENTORY.UPDATE_SOFTWARE,
                        payload: t('INVENTORY.softwareUnsuccessfullyUpdated'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: INVENTORY.UPDATE_SOFTWARE,
                    payload: t('INVENTORY.softwareUnsuccessfullyUpdated'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                if (path) history.push(path);
            });
    };

    const getMyRequests = async id => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/InventoryRequisition/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_MY_REQUESTS,
            payload: res.data
        });
    };

    const getCollaboratorsRequests = async id => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER +
                `api/InventoryRequisition/CollaboratorsRequisiton/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_COLLABORATORS_REQUESTS,
            payload: res.data
        });
    };

    const getAllCollaboratorsRequests = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER +
                `api/InventoryRequisition/CollaboratorsRequisiton`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_COLLABORATORS_REQUESTS,
            payload: res.data
        });
    };

    const getCollaboratorsManaged = async id => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER +
                `api/InventoryRequisition/CollaboratorsRequisiton/managed/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_COLLABORATORS_MANAGED,
            payload: res.data
        });
    };

    const getLicensesByUser = async id => {
        setLoading();
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/Software/LicenseByUser/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_LICENSE_BY_USERS,
            payload: res.data
        });
    };

    const addRequisitions = (obj, path, emailDates, context) => {
        setLoading();
        axios
            .post(
                process.env.REACT_APP_SERVER + 'api/InventoryRequisition',
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                if (response.status === 201) {
                    if (context === 'new') {
                        var idItem = {
                            url:
                                process.env.REACT_APP_FRONT +
                                `CollaboratorRequisition/${response.data[0].id}/edit`
                        };
                        var objEmail = Object.assign({}, emailDates, idItem);
                        sendEmailNewRequisition(objEmail);
                    }
                    dispatch({
                        type: INVENTORY.ADD_EQUIPMENT,
                        payload: t('INVENTORY.addedRequests'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: INVENTORY.ADD_EQUIPMENT,
                        payload: t('INVENTORY.requestsUnsuccessfullyCreated'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: INVENTORY.ADD_EQUIPMENT,
                    payload:
                        error.status === 400
                            ? t('INVENTORY.requestsUnsuccessfullyCreated')
                            : t('INVENTORY.requestsUnsuccessfullyCreated'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                if (path) history.push(path);
            });
    };

    const deleteRequisition = id => {
        setLoading();
        axios
            .delete(
                process.env.REACT_APP_SERVER + `api/InventoryRequisition/${id}`,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(res => {
                dispatch({
                    type: INVENTORY.DELETE_REQUISITION,
                    payload: t('INVENTORY.deleteRequesition'),
                    responseType: 'SUCCESS',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .catch(error => {
                dispatch({
                    type: INVENTORY.DELETE_REQUISITION,
                    payload:
                        error.response.status === 404
                            ? t('INVENTORY.requesitionNotFound')
                            : t('INVENTORY.requesitionCannotBeDeleted'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            });
    };

    const getSoftwareCategoriesByLicensesAvaliable = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/SoftwareCategories/avaiable`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_ITENS_SOFTWARE_CATEGORIES_BY_LECENSES_AVALIABLE,
            payload: res.data
        });
    };

    const getRequisition = async id => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER +
                `api/InventoryRequisition/EditAndView/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_REQUISITION,
            payload: res.data
        });
    };

    const getApprover = async id => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER +
                `api/InventoryRequisition/approver/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_APPROVER,
            payload: res.data
        });
    };

    const updateRequisition = (obj, path) => {
        setLoading();
        axios
            .put(
                process.env.REACT_APP_SERVER + 'api/InventoryRequisition/',
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: INVENTORY.UPDATE_SOFTWARE,
                        payload: t('INVENTORY.updateRequisitionMessage'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: INVENTORY.UPDATE_SOFTWARE,
                        payload: t(
                            'INVENTORY.requisitionUnsuccessfullyUpdated'
                        ),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: INVENTORY.UPDATE_SOFTWARE,
                    payload: t('INVENTORY.requisitionUnsuccessfullyUpdated'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                if (path) history.push(path);
            });
    };

    const sendEmailNewRequisition = async emailData => {
        return axios
            .post(
                process.env.REACT_APP_EMAIL + 'api/Requisition/newRequest',
                emailData,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(
                res => console.log('ok'),
                err => Promise.reject('error')
            );
    };

    const sendEmailRequisitionApproveOrRefuse = async emailData => {
        return axios
            .post(
                process.env.REACT_APP_EMAIL +
                    'api/Requisition/ProjectManagerApproveOrRefuse',
                emailData,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(
                res => console.log('ok'),
                err => Promise.reject('error')
            );
    };

    const sendEmailRequisitionPatrimonyApproveOrRefuseToProjectManager =
        async emailData => {
            return axios
                .post(
                    process.env.REACT_APP_EMAIL +
                        'api/Requisition/PatrimonyManagerApproveOrRefuseToProject',
                    emailData,
                    {
                        headers: {
                            'Content-type': 'application/json',
                            Authorization:
                                'bearer ' +
                                (Cookies.get('auth-token')
                                    ? Cookies.get('auth-token')
                                    : '')
                        }
                    }
                )
                .then(
                    res => console.log('ok'),
                    err => Promise.reject('error')
                );
        };

    const sendEmailRequisitionPatrimonyApproveOrRefuseToCollaborator =
        async emailData => {
            return axios
                .post(
                    process.env.REACT_APP_EMAIL +
                        'api/Requisition/PatrimonyManagerApproveOrRefuseToCollaborator',
                    emailData,
                    {
                        headers: {
                            'Content-type': 'application/json',
                            Authorization:
                                'bearer ' +
                                (Cookies.get('auth-token')
                                    ? Cookies.get('auth-token')
                                    : '')
                        }
                    }
                )
                .then(
                    res => console.log('ok'),
                    err => Promise.reject('error')
                );
        };

    const managerApproveRequisition = (id, obj, emailData) => {
        setLoading();
        axios
            .put(
                process.env.REACT_APP_SERVER +
                    `api/InventoryRequisition/CollaboratorsRequisiton/ManagerApproveOrRefuse/${id}`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(res => {
                sendEmailRequisitionApproveOrRefuse(emailData);
                dispatch({
                    type: INVENTORY.MANAGER_APPROVE_OR_REFUSE,
                    payload:
                        res.status === 204
                            ? t('INVENTORY.requisitionRefused')
                            : t('INVENTORY.requisitionApproved'),
                    responseType: 'SUCCESS',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .catch(error => {
                dispatch({
                    type: INVENTORY.MANAGER_APPROVE_OR_REFUSE,
                    payload: t('INVENTORY.requisitionApprovedError'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            });
    };

    const getAllRequests = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER +
                `api/InventoryRequisition/RequisitionsToExecution`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_ALL_REQUESTS,
            payload: res.data
        });
    };

    const AdmExecutedRequisitions = (
        id,
        obj,
        emailDatasManager,
        emailDatasCollaborator,
        path
    ) => {
        setLoading();
        axios
            .put(
                process.env.REACT_APP_SERVER +
                    `api/InventoryRequisition/AdmRequisiton/ManagerApproveOrRefuse/${id}`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(res => {
                sendEmailRequisitionPatrimonyApproveOrRefuseToProjectManager(
                    emailDatasManager
                );
                sendEmailRequisitionPatrimonyApproveOrRefuseToCollaborator(
                    emailDatasCollaborator
                );
                dispatch({
                    type: INVENTORY.MANAGER_APPROVE_OR_REFUSE,
                    payload:
                        res.status === 204
                            ? t('INVENTORY.requisitionRefused')
                            : t('INVENTORY.requestExecuted'),
                    responseType: 'SUCCESS',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .catch(error => {
                dispatch({
                    type: INVENTORY.MANAGER_APPROVE_OR_REFUSE,
                    payload: t('INVENTORY.requisitionApprovedError'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                if (path) history.push(path);
            });
    };

    const getPatrimonyManager = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER +
                `api/InventoryRequisition/PatrimonyManager`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_PATRIMONY_MANAGER,
            payload: res.data
        });
    };

    const getItensAvailableByCategory = async id => {
        setLoading();
        const res = await axios.get(
            process.env.REACT_APP_SERVER +
                `api/InventoryItem/avaiableByCategory/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: INVENTORY.GET_ITENS_AVAILABLE_BY_CATEGORY,
            payload: res.data
        });
    };

    const sendEmailAssignment = async emailData => {
        return axios
            .post(
                process.env.REACT_APP_EMAIL + 'api/AssignmentItem/SendEmail',
                emailData,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(
                res => console.log('ok'),
                err => Promise.reject('error')
            );
    };

    const getUsersEmailData = async event => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/EmailUsers/${event}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: DEFAULT.GET_USERS_EMAIL,
            payload: res.data
        });
    };

    return (
        <InventoryContext.Provider
            value={{
                myItens: state.myItens,
                mySoftwares: state.mySoftwares,
                loading: state.loading,
                loadingBooks: state.loadingBooks,
                loadingEquipment: state.loadingEquipment,
                loadingRequisition: state.loadingRequisition,
                message: state.message,
                successiveActions: state.successiveActions,
                filter: state.filter,
                hasChanged: state.hasChanged,
                response: state.response,
                responseType: state.responseType,
                places: state.places,
                place: state.place,
                bookCategories: state.bookCategories,
                bookCategory: state.bookCategory,
                itensCategories: state.itensCategories,
                itemCategory: state.itemCategory,
                allStatus: state.allStatus,
                equipment: state.equipment,
                subitens: state.subitens,
                arraySubItens: state.arraySubItens,
                externalPartners: state.externalPartners,
                equipmentItem: state.equipmentItem,
                maintenance: state.maintenance,
                maintenances: state.maintenances,
                nextCodes: state.nextCodes,
                historyEquipment: state.historyEquipment,
                externalUserItens: state.externalUserItens,
                externalUserHistory: state.externalUserHistory,
                lsbdCodesHistory: state.lsbdCodesHistory,
                books: state.books,
                libraryAdms: state.libraryAdms,
                isbnAndTitle: state.isbnAndTitle,
                inventoryImage: state.inventoryImage,
                book: state.book,
                exemplar: state.exemplar,
                admsPeopleManagement: state.admsPeopleManagement,
                linkEquipment: state.linkEquipment,
                searchLinkEquipment: state.searchLinkEquipment,
                searchLinkSoftware: state.searchLinkSoftware,
                searchLinkBook: state.searchLinkBook,
                historyExemplar: state.historyExemplar,
                softwareCategories: state.softwareCategories,
                authors: state.authors,
                softwares: state.softwares,
                loadingSoftware: state.loadingSoftware,
                platforms: state.platforms,
                software: state.software,
                license: state.license,
                itensByUser: state.itensByUser,
                myRequests: state.myRequests,
                collaboratorsRequests: state.collaboratorsRequests,
                collaboratorsManaged: state.collaboratorsManaged,
                licensesByUser: state.licensesByUser,
                itensCategoriesByItensAvaliable:
                    state.itensCategoriesByItensAvaliable,
                softwareCategoriesByLicensesAvaliable:
                    state.softwareCategoriesByLicensesAvaliable,
                softwaresForRequisition: state.softwaresForRequisition,
                requisition: state.requisition,
                licensesAll: state.licensesAll,
                approver: state.approver,
                allRequisitions: state.allRequisitions,
                patrimonyManager: state.patrimonyManager,
                itensAvailable: state.itensAvailable,
                availableLicenses: state.availableLicenses,
                usersEmail: state.usersEmail,
                setHasChanged,
                searchTermChanged,
                getItensByUser,
                resetMessage,
                getPlaces,
                getPlace,
                addPlace,
                updatePlace,
                deletePlace,
                addBookCategory,
                updateBookCategory,
                getBookCategories,
                getBookCategory,
                deleteBookCategory,
                addItemCategory,
                updateItemCategory,
                getItensCategories,
                getItemCategory,
                deleteItemCategory,
                addItemStatus,
                updateItemStatus,
                getAllStatus,
                getItemStatus,
                deleteItemStatus,
                getInfoMyItems,
                getAllEquipment,
                addEquipment,
                updateSubItem,
                deleteEquipment,
                addSubItensArray,
                getExternalPartners,
                deleteSubItensArray,
                updateEquipment,
                cleanArraySubItem,
                getEquipment,
                getMaintenanceForItem,
                getMaintenance,
                addMaintenance,
                updateMaintenance,
                deleteMaintenance,
                getNextCodes,
                getHistoryEquipment,
                getExternalPlaces,
                getEquipmentByExternalUser,
                getExternalHistory,
                getLsbdCodesHistory,
                getBooks,
                addBook,
                addExemplar,
                setSearchLinkBook,
                getLibraryAdms,
                getIsbnAndTitles,
                getBook,
                getInventoryImage,
                UpdateBook,
                getExemplar,
                sendEmailNewEquipment,
                geAdmsPeopleManagement,
                saveLinkEquipment,
                UpdateExemplar,
                setSearchLinkEquipment,
                sendEmailNewBook,
                getHistoryExemplar,
                deleteBook,
                deleteExemplar,
                getSoftwareCategories,
                addSoftwareCategory,
                updateSoftwareCategory,
                deleteSoftwareCategory,
                addAuthor,
                getAuthors,
                getSoftwares,
                SetDeleteSoftware,
                getPlartforms,
                getSoftware,
                addSoftwareAndLicense,
                UpdateSoftware,
                getLicense,
                setSearchLinkSoftware,
                getItensbyUsersLicense,
                UpdateLicense,
                setDeleteLicense,
                getMyRequests,
                getCollaboratorsRequests,
                getCollaboratorsManaged,
                getLicensesByUser,
                addRequisitions,
                getItensCategoriesByItensAvaliable,
                getSoftwareCategoriesByLicensesAvaliable,
                deleteRequisition,
                getSoftwaresForRequisition,
                getRequisition,
                getLicensesAll,
                updateRequisition,
                managerApproveRequisition,
                getApprover,
                sendEmailNewRequisition,
                getAllRequests,
                AdmExecutedRequisitions,
                getPatrimonyManager,
                sendEmailRequisitionApproveOrRefuse,
                getItensAvailableByCategory,
                getLicensesAvailable,
                sendEmailRequisitionPatrimonyApproveOrRefuseToProjectManager,
                sendEmailRequisitionPatrimonyApproveOrRefuseToCollaborator,
                setLoadingEquipment,
                setLoadingEquipmentFalse,
                setLoadingRequisition,
                setLoadingRequisitionFalse,
                sendEmailAssignment,
                getUsersEmailData,
                cleanExemplar,
                cleanBook,
                getAllCollaboratorsRequests
            }}
        >
            {props.children}
        </InventoryContext.Provider>
    );
};

export default InventoryState;
