import styled, {css} from "styled-components";
import MagnifyingGlassIcon from "../../Icons/MagnifyingGlassIcon/MagnifyingGlassIcon";
import CrossIcon from "../../Icons/CrossIcon/CrossIcon";

const iconsSharedStyles = css`
    fill: rgba(0, 0, 0, 0.54);
`

export const InputFilterWrapper = styled.div`
    cursor: text;
    display: flex;
    align-items: center;
    background-color: #F5F6FA;
    border-radius: 4px;
    padding: 8px 8px;

    &:hover {
        background-color: rgb(224, 224, 224);
    }
`

export const Input = styled.input`
    width: 100%;
    height: 100%;
    font-size: 14px;
    
    // reset
    border: none;
    background: none;
    &:focus-visible {
        outline: none;
    }
`

export const Button = styled.button`
    cursor: pointer;
    
    // reset
    border: none;
    background: none;
    margin: unset;
    padding: unset;
`

export const MagnifyingGlassIconStyled = styled(MagnifyingGlassIcon)`
    margin-right: 8px;
    width: 24px;
    height: 24px;
    svg {
        width: 17px;
        height: 17px;
        ${iconsSharedStyles};
    }
`

export const CrossIconStyled = styled(CrossIcon)`
    width: 24px;
    height: 24px;
    svg {
        width: 14px;
        height: 14px;
        ${iconsSharedStyles};
    }
`