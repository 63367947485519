import { Paper } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Delete from "@material-ui/icons/Delete";
import { useStyles } from "components/layout/CommonStyles";
import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import ProjectsContext from "context/projects/projectsContext";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Zoom from "@material-ui/core/Zoom";

const tooltipTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#2c3e51"
    }
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: "white",
        fontSize: "1em",
        backgroundColor: "red",
        opacity: "0.7"
      }
    }
  }
});

const ScopeStage = props => {
  const classes = useStyles();
  const [t] = useTranslation();
  const { disabled, lastStage, deleteStage, stageDescription } = props;
  const [description, setDescription] = useState(stageDescription);
  const projectsContext = useContext(ProjectsContext);
  const { showRequiredFieldMessage, setValueRequiredMessage } = projectsContext;

  useEffect(() => {
    setDescription(stageDescription);
  }, [stageDescription]);

  const handleChange = event => {
    setDescription(event.target.value);
  };

  const handleClick = () => {
    deleteStage(lastStage);
  };

  const clearRiqueredMessage = () => {
    setValueRequiredMessage(false);
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.bodyForm}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Tooltip title="Deletar" aria-label="delete" placement="top">
              <IconButton
                disabled={disabled}
                onClick={handleClick}
                style={{ float: "right" }}
                className={classes.icons}
              >
                <Delete />
              </IconButton>
            </Tooltip>
            <MuiThemeProvider theme={tooltipTheme}>
              <Tooltip
                TransitionComponent={Zoom}
                open={!description && showRequiredFieldMessage}
                title={t("GENERAL.requiredField")}
              >
                <TextField
                  id={"stageValue" + lastStage}
                  multiline
                  fullWidth
                  disabled={disabled}
                  variant="outlined"
                  rows="6"
                  label={t("SCOPE.stage") + " " + lastStage}
                  margin="normal"
                  value={description}
                  onChange={handleChange}
                  onFocus={clearRiqueredMessage}
                />
              </Tooltip>
            </MuiThemeProvider>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};

export default ScopeStage;
