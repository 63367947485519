import {ArrowheadIconWrapper} from "./ArrowheadIcon.styled";
import React from "react";

const ArrowheadIcon = ({className}) => {
    return (
        <ArrowheadIconWrapper className={className}>
            <svg viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0L5 5L10 0H0Z"/>
            </svg>
        </ArrowheadIconWrapper>
    )
}

export default ArrowheadIcon;