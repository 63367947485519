import { Image, Text, View } from "@react-pdf/renderer";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./Header.styled";
import DateUtils from "utils/dates/DateUtils";

export default function Header({ mainTitle, subTitle }) {
    const [t] = useTranslation();
    return (
        <View style={styles.header}>
            <View style={styles.createdAtDate}>
                <Text style={{ fontWeight: 700 }}>{t("GENERAL.GeneratedAt").toUpperCase()}: </Text>
                <Text style={{ fontWeight: 300 }}>{DateUtils.format(new Date(), 'DD/MM/YYYY hh:mm:ss')}</Text>
            </View>
            <View style={{ display: "flex", flexDirection: "row" }}>
                <Image src={window.location.origin + '/img/lsbd-logo.png'} alt="LSBD" style={styles.logo} />
                <View style={styles.verticalLine} />
                <View style={styles.titles}>
                    <Text style={styles.headerSubTitle}>
                        {subTitle.toUpperCase()}
                    </Text>
                    <Text style={styles.headerMainTitle}>
                        {mainTitle.toUpperCase()}
                    </Text>
                </View>
            </View>
        </View>
    )
}
