import React from "react";
import "../../../../App.css";
import SideBar from "components/layout/sidebar/Sidebar";
import DashboardNavBar from "components/layout/DashboardNavBar";
import CollaboratorsJustificationsForm from "./CollaboratorsJustificationsForm";

const CallEvaluateCollaboratorsJustifications = ({ match }) => {

    return (
        <div>
            <DashboardNavBar />
            <SideBar />
            <CollaboratorsJustificationsForm id={match.params.id} context={"new"} />
        </div>
    );
}

export default CallEvaluateCollaboratorsJustifications;