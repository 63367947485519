import styled from "styled-components";
import DownloadIcon from "../../Icons/DownloadIcon/DownloadIcon";

export const ButtonWrapper = styled.span`
    display: flex;
    align-items: center;
`

export const ButtonText = styled.span`
    float: right;
    margin-right: 10px;
`

export const Button = styled.button`
    cursor: pointer;
    flex: 0 0 auto;
    color: rgba(0, 0, 0, 0.54);
    padding: 12px;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    border-radius: 50%;
    background-color: unset;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    -ms-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    
    &:hover {
        background-color: rgba(0, 0, 0, 0.04);
    }
`

export const IconWrapper = styled.span`
    width: 100%;
    display: flex;
    align-items: inherit;
    justify-content: inherit;
`

export const DownloadIconStyled = styled(DownloadIcon)`
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
`
