import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { useStyles } from "components/layout/CommonStyles";
import { useTranslation } from "react-i18next";
import CloseIcon from '@material-ui/icons/Close';
import Buttons from "components/buttons/Pressed";
import Grid from "@material-ui/core/Grid";
import Input from "components/inputs/Input";
import { convertStrToBrCurrency } from "utils/masks/convertStrToBrCurrency";

const DialogSalary = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const { open, setOpen, context, salary, oldSalary, justification, userId, setParentValues,setHasChange } = props;

    const [values, setValues] = useState({
        salary: "",
        justification: "",
    });

    const [requiredSalary, setRequiredSalary] = useState(false);

    useEffect(() => {
        setValues({
            ...values,
            salary: salary !== oldSalary ? salary : "",
            justification: justification ? justification : "",
        });
    }, [salary, oldSalary, justification]);

    const clearDialog = () => {
        setRequiredSalary(false);
        setValues({
            ...values,
            salary: salary !== oldSalary ? salary : "",
            justification: justification ? justification : "",
        });
    }

    const handleClose = () => {
        clearDialog();
        setOpen(false);
    }

    const handleChange = (name, value) => {
        setHasChange(true);
        let val = value;
        if (name === "salary") {
            val = convertStrToBrCurrency(value);
        }
        setValues({ ...values, [name]: val })
    }

    const verifySubmit = () => {
        let res = true;
        if (!values.salary.trim()) {
            res = false;
            setRequiredSalary(true);
        }

        return res;
    }

    const handleSubmit = () => {
        const canSubmit = verifySubmit();
        if (canSubmit) {
            setParentValues(old => ({
                ...old,
                salary: values.salary.trim(),
                salaryJustification: values.justification.trim()

            }));
            setOpen(false);
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle className={classes.Title}>
                <b>{context === "view" ? t("GENERAL.view") + " " + t("HR.changeOfSalary") : t("HR.editRemuneration")}</b>
                <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container item spacing={2} md={12} lg={12}>
                    <Grid item xs={12} sm={12}>
                        <Input
                            disabled
                            label={"HR.oldRemuneration"}
                            value={oldSalary}
                            valueName={"oldSalary"}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Input
                            context={context}
                            required
                            label={"HR.newRemuneration"}
                            value={values.salary}
                            valueName={"salary"}
                            handleChange={handleChange}
                            error={requiredSalary}
                            helperText={requiredSalary && t("GENERAL.requiredField")}
                            setRequired={setRequiredSalary}
                            maxLength={13}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Input
                            context={context}
                            label={"GENERAL.justification"}
                            value={values.justification}
                            valueName={"justification"}
                            rows={5}
                            maxLength={200}
                            handleChange={handleChange}
                        />
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Buttons
                    onClick={handleClose}
                    tipo="BN-noback"
                    conteudo={context === "view" ? t("GENERAL.close").toUpperCase() : t("GENERAL.cancel").toUpperCase()}
                />
                {context === "view" ? null :
                    <Buttons
                        onClick={handleSubmit}
                        tipo="BN-blue"
                        conteudo={t("GENERAL.update").toUpperCase()}
                    />}
            </DialogActions>
        </Dialog>
    )
}

export default DialogSalary;