

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({

    knowledgeRadioTable: {
        display: "inline-flex",
        paddingTop: "20px",
        paddingLeft: "4px",
        paddingRight: "4px",
        color: "#2C3E51",
        paddingBottom: "10px",
        width: "96px",
        textAlign: "center",
        alignContent: "center",
        justifyContent: "center",
    },
    knowledgeRadio: {
        display: "inline-flex",
        paddingLeft: "4px",
        paddingRight: "4px",
        color: "#2C3E51",
        width: "96px",
        textAlign: "center",
        alignContent: "center",
        justifyContent: "center",
    },
    gridContainer: {
        paddingRight: "50px",
        marginRight: "0px",
    },
    knowledgeRadioColumns: {
        display: "flex",
        justifyContent: "space-around",

    },


}));

export { useStyles };