/* eslint-disable */
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";
import InventoryContext from "context/inventory/inventoryContext"
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import Buttons from "components/buttons/Pressed";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";

const tooltipTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#2c3e51"
        }
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                color: "white",
                fontSize: "1em",
                backgroundColor: "red",
                opacity: "0.7"
            }
        }
    }
});

const SoftwareCategory = (props) => {
    const { open, setOpen } = props;
    const classes = useStyles();
    const [t] = useTranslation();
    const inventoryContext = useContext(InventoryContext);
    const [openName, setOpenName] = useState(false);
    const [titleName, setTitle] = useState(t("GENERAL.requiredField"));

    const clearRequiredMessage = () => {
        setOpenName(false);
    };

    const showRequiredFieldsMessage = () => {
        setOpenName(true);
    };

    const [values, setValues] = React.useState({
        id: null,
        name: "",
        description: "",
    });

    const {
        addSoftwareCategory,
        softwareCategories,
    } = inventoryContext;

    const handleChangeName = name => event => {
        if (event.target.value.length <= 50) {
            clearRequiredMessage();
            const data = softwareCategories.filter(item => item.name.trim().toLocaleLowerCase() === event.target.value.trim().toLocaleLowerCase());
            if (data.length) {
                setTitle(t("INVENTORY.nameSoftwareCategoryAlreadyTaken"));
                setOpenName(true);
            } else {
                setTitle(t("GENERAL.requiredField"));
            }
            setValues({ ...values, [name]: event.target.value });
        }
    };

    const handleChange = name => event => {
        if (event.target.value.length <= 250) {
            setValues({ ...values, [name]: event.target.value });
        }
    };

    const handleClose = () => {
        clearRequiredMessage();
        setOpen(false);
    };

    const handleSubmit = e => {
        if (!openName) {
            if (values.name.trim()) {
                const obj = {
                    name: values.name,
                    description: values.description.trim() ? values.description.trim() : null,
                };
                clearRequiredMessage();
                addSoftwareCategory(obj);
                setOpen(false);
                e.preventDefault();
            } else {
                showRequiredFieldsMessage();
            }
        } else showRequiredFieldsMessage();
    };

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth={"sm"}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            msg="mensagem"
        >
            <DialogTitle className={classes.TitleDialog}>
                {t("INVENTORY.addItemCategoryTitle")}
                <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <div className={classes.root}>
                        <Grid container>
                            <Grid item xs={12} sm={12}>
                                <MuiThemeProvider theme={tooltipTheme}>
                                    <Tooltip
                                        TransitionComponent={Zoom}
                                        open={openName}
                                        title={titleName}
                                    >
                                        <TextField
                                            className={classes.textField}
                                            label={t("INVENTORY.nameItemCategory") + "*"}
                                            value={values.name}
                                            onChange={handleChangeName("name")}
                                            margin="normal"
                                            inputProps={{
                                                maxLength: 50
                                            }}
                                        />
                                    </Tooltip>
                                </MuiThemeProvider>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                className={classes.textField}
                                multiline
                                label={t("INVENTORY.descriptionItemCategory")}
                                value={values.description}
                                margin="normal"
                                onChange={handleChange("description")}
                                inputProps={{
                                    maxLength: 250
                                }}
                            />
                        </Grid>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Buttons
                    onClick={handleClose}
                    tipo="BN-noback"
                    conteudo={t("INVENTORY.cancel").toUpperCase()}
                />
                <Buttons
                    onClick={handleSubmit}
                    tipo="BN-blue"
                    conteudo={t("INVENTORY.add").toUpperCase()}
                />
            </DialogActions>
        </Dialog>
    );
};

export default SoftwareCategory;