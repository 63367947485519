/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Box from '@material-ui/core/Box';

import Paper from "@material-ui/core/Paper";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Footer from "components/layout/Footer";
import ClockRecordContext from "context/hrModule/clockRecord/clockRecordContext";
import SettingsContext from "context/hrModule/settings/settingsContext";
import Transition from "components/layout/transition/Transition";
import Breadcrumb from "components/layout/breadcrumb/Breadcrumb";
import MyActivitiesTable from "./MyActivitiesTable";
import HeaderContainer from "components/containers/HeaderContainer";
import ContentContainer from "components/containers/ContentContainer";
import Typography from "components/typography/Typography";
import Buttons from "components/buttons/Pressed";

import { FormatDateToFront } from "utils/dates/FormatDateToFront";
import { GetHoursAndMinutes } from "utils/dates/GetHoursAndMinutes";
import { ConvertMilliInHourMin } from "utils/dates/ConvertMilliInHourMin1";
import { dateToBR } from "utils/dates/DateToBRr";

import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";
import { screenStyles } from "../Styles"

const ProfileManagement = (props) => {
    const classes = useStyles();
    const styles = screenStyles();
    const [t] = useTranslation();
    const clockRecordContext = useContext(ClockRecordContext);
    const settingsContext = useContext(SettingsContext);

    const { id } = props
    const [activityCategoryArray, setActivityCategoryArray] = useState(null);

    const [values, setValues] = useState({
        name: "",
        job: "",
        ruleset: "",
        totalWorkingHours: "",
        registersActivity: false,
        activityRecords: []
    });

    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }

    const {
        loading,
        setLoading,
        setLoadingFalse,
        getServerTime,
        serverTime,
        getCollaboratorClockRecordDetails,
        collaboratorClockRecordDetails,
    } = clockRecordContext;

    const {
        getAllActivityCategories,
        activityCategories
    } = settingsContext

    async function fetchData() {
        await setLoading();
        await getAllActivityCategories();
        await getServerTime();
        await getCollaboratorClockRecordDetails(id);
        await setLoadingFalse();
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (activityCategories && activityCategories.length > 0) {
            let aux = activityCategories.filter(activity => !activity.deleted).map((activity) => {
                return {
                    label: activity.name,
                    value: activity.id
                }
            })
            setActivityCategoryArray(aux);
        }
    }, [activityCategories]);

    useEffect(() => {
        if ( collaboratorClockRecordDetails !== null){
            setValues({
                id: collaboratorClockRecordDetails && collaboratorClockRecordDetails.id ? collaboratorClockRecordDetails.id : "",
                date: collaboratorClockRecordDetails.clockRecord && collaboratorClockRecordDetails.clockRecord.date? dateToBR(FormatDateToFront(collaboratorClockRecordDetails.clockRecord.date)) : "-",
                entryTime: collaboratorClockRecordDetails.clockRecord && collaboratorClockRecordDetails.clockRecord.entryTime ?  GetHoursAndMinutes(FormatDateToFront(collaboratorClockRecordDetails.clockRecord.entryTime)) :"-",
                leaveForBreak: collaboratorClockRecordDetails.clockRecord && collaboratorClockRecordDetails.clockRecord.leaveForBreak ?  GetHoursAndMinutes(FormatDateToFront(collaboratorClockRecordDetails.clockRecord.leaveForBreak)) :"-",
                returnFromBreak: collaboratorClockRecordDetails.clockRecord && collaboratorClockRecordDetails.clockRecord.returnFromBreak ?  GetHoursAndMinutes(FormatDateToFront(collaboratorClockRecordDetails.clockRecord.returnFromBreak)) :"-",
                leaveTime: collaboratorClockRecordDetails.clockRecord && collaboratorClockRecordDetails.clockRecord.leaveTime ?  GetHoursAndMinutes(FormatDateToFront(collaboratorClockRecordDetails.clockRecord.leaveTime)) :"-",
                status:collaboratorClockRecordDetails.clockRecord && collaboratorClockRecordDetails.clockRecord.status ?  collaboratorClockRecordDetails.clockRecord.status:"-",
                totalWorkingHours: collaboratorClockRecordDetails.clockRecord && collaboratorClockRecordDetails.clockRecord.totalHours ? collaboratorClockRecordDetails.clockRecord.totalHours :
                GetWorkedHours(
                    FormatDateToFront(collaboratorClockRecordDetails.clockRecord.entryTime),
                    FormatDateToFront(collaboratorClockRecordDetails.clockRecord.leaveForBreak),
                    FormatDateToFront(collaboratorClockRecordDetails.clockRecord.returnFromBreak),
                    FormatDateToFront(serverTime)),
                activityRecords: collaboratorClockRecordDetails.activityRecord ? collaboratorClockRecordDetails.activityRecord : [],
                name : collaboratorClockRecordDetails.name ? collaboratorClockRecordDetails.name : "-" ,
                imageName : collaboratorClockRecordDetails.imageName ? collaboratorClockRecordDetails.imageName : "-",
                job: collaboratorClockRecordDetails.job ? collaboratorClockRecordDetails.job : "-",
                ruleset: collaboratorClockRecordDetails.ruleset ? collaboratorClockRecordDetails.ruleset : "-",
                totalWorkingHoursContract: collaboratorClockRecordDetails.totalWorkingHours ? collaboratorClockRecordDetails.totalWorkingHours : "",
            })

        }
    }, [collaboratorClockRecordDetails]);

    const getType = (value) => {
        switch (value) {
            case "IN_PROGRESS":
                return t("GENERAL.inProgress")
            case "INCONSISTENCY":
                return t("GENERAL.inconsistency")
            case "REGULAR":
                return t("GENERAL.regular")
            case "VACATION":
                return t("GENERAL.vacation")
            case "HOLIDAY":
                return t("GENERAL.holiday")
            case "RECESS":
                return t("GENERAL.recess")
            case "WEEKEND":
                return t("GENERAL.weekend")
            case "JUSTIFIED":
                return t("HR.justified")
            default:
                break;
        }
    }

    return (
        <React.Fragment>
            <Transition loading={loading} newDesign={true}>
                <HeaderContainer>
                    <Breadcrumb
                        newDesign={true}
                        routes={[
                            { label: t("HR.humanResource") , to:"/HumanResources"},
                            { label: t("HR.clock") },
                            { label: t("HR.collaboratorsClockRecords") , to: "/CollaboratorsClockRecords" },
                            { label: t("HR.viewCollaboratorClockRecord"), to: `/CollaboratorClockRecord/${values.id}`},
                            { label: t("HR.collaboratorClockRecordDetails")}
                        ]}
                    />
                    <div className="container-headerNewDesign">
                        <h1 className={classes.titleNewDesign}>{t("HR.collaboratorClockRecordDetails")}</h1>
                    </div>

                </HeaderContainer>
                <ContentContainer>
                    <Paper className={styles.space}>
                        <div className={styles.collaboratorInfoWrapper} >
                                <div className={styles.collaboratorInfoCardImgWrapper}>
                                    <img src={process.env.REACT_APP_IMGSERVER + values.imageName} className={styles.collaboratorInfoCardImg}/>
                                </div>
                                <div className={styles.collaboratorInfoCardPersonalInfo}>
                                    <div className={styles.collaboratorInfoCardPersonalInfoFirstContent}>
                                        <Typography className={styles.textMargin} variant={'RobotoBold'} fontSize={10} textColor={'white'}>{'COLABORADOR'}</Typography>
                                        <Typography className={styles.textMargin} variant={'RobotoRegular'} fontSize={14} textColor={'white'}>{values.name}</Typography>
                                        <Typography className={styles.textMargin} variant={'RobotoLight'} fontSize={12} textColor={'white'}>{values.job}</Typography>
                                    </div>
                                    <div className={styles.collaboratorInfoCardPersonalInfoSecondContent}>
                                        <Typography className={styles.textMargin} variant={'RobotoBold'} fontSize={10} textColor={'white'}>{'REGIME'}</Typography>
                                        <Typography className={styles.textMargin} variant={'RobotoRegular'} fontSize={14} textColor={'white'}>{`${values.ruleset} • ${ values.totalWorkingHoursContract ? ConvertMilliInHourMin(values.totalWorkingHoursContract) : "00:00"} Diárias`}</Typography>
                                    </div>
                                </div>

                        </div>
                    </Paper>


                    <Paper className={styles.space}>
                        <div className="cabecalho">
                            {t("HR.collaboratorClockRecordDetails").toUpperCase()}
                        </div>
                        <Grid container item spacing={2} md={12} lg={12} style={{ padding: "32px" }}>

                            <Grid item xs={12} sm={2}>
                                <Typography className={styles.textMargin} variant={'RobotoBold'} fontSize={15} textColor={'primary'}>
                                    {'Data de Registro'}
                                </Typography>
                                <Typography className={styles.textMargin} variant={'RobotoRegular'} fontSize={13} textColor={'secondary'}>
                                    {values.date}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={2}>
                                <Typography className={styles.textMargin} variant={'RobotoBold'} fontSize={15} textColor={'primary'}>
                                    {'Horas Trabalhadas'}
                                    {/* <DefaultTooltip fontSize={"11"} className={styles.iconsRight} color={"#6D6F71"} text={t("HR.systemHourMessage")}/> */}
                                </Typography>
                                <Typography className={styles.textMargin} variant={'RobotoRegular'} fontSize={13} textColor={'secondary'}>
                                    {values.totalWorkingHours}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={2}>
                                <Typography className={styles.textMargin} variant={'RobotoBold'} fontSize={15} textColor={'primary'}>
                                    {t("GENERAL.status")}
                                    {/* <DefaultTooltip fontSize={"11"} className={styles.iconsRight} color={"#6D6F71"} text={t("HR.systemHourMessage")}/> */}
                                </Typography>
                                <Typography className={styles.textMargin} variant={'RobotoRegular'} fontSize={13} textColor={'secondary'}>
                                    <div style={{display: "flex"}}>
                                        {getType(values.status)}
                                        {values.status === "IN_PROGRESS" ?
                                                <AccessTimeIcon style={{ marginLeft: "0.30rem", fontSize: "13px"}} fontSize={"inherit"}/>
                                            : values.status === "INCONSISTENCY" ?
                                                <ErrorIcon style={{ marginLeft: "0.30rem", color: "#F3222F", fontSize: "13px" }} fontSize={"inherit"}/>
                                            : values.status === "REGULAR" ?
                                                <CheckCircleIcon style={{ marginLeft: "0.30rem", color: "#7FE46B" , fontSize: "13px"}} fontSize={"inherit"}/>
                                            : values.status === "JUSTIFIED" ?
                                                <CheckCircleIcon style={{ marginLeft: "0.30rem", color: "#2D9CDB" , fontSize: "13px"}} fontSize={"inherit"}/>
                                            : null}
                                    </div>
                                </Typography>
                            </Grid>
                            <Box width="100%" />

                            <Grid item xs={12} sm={2}>
                                <Typography className={styles.textMargin} variant={'RobotoBold'} fontSize={15} textColor={'primary'}>
                                    {t("HR.entry")}
                                </Typography>
                                <Typography className={styles.textMargin} variant={'RobotoRegular'} fontSize={13} textColor={'secondary'}>
                                    {values.entryTime}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={2}>
                                <Typography className={styles.textMargin} variant={'RobotoBold'} fontSize={15} textColor={'primary'}>
                                    {t("HR.leaveForBreak")}
                                </Typography>
                                <Typography className={styles.textMargin} variant={'RobotoRegular'} fontSize={13} textColor={'secondary'}>
                                    {values.leaveForBreak}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={2}>
                                <Typography className={styles.textMargin} variant={'RobotoBold'} fontSize={15} textColor={'primary'}>
                                    {t("HR.returnFromBreak")}
                                </Typography>
                                <Typography className={styles.textMargin} variant={'RobotoRegular'} fontSize={13} textColor={'secondary'}>
                                    {values.returnFromBreak}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={2}>
                                <Typography className={styles.textMargin} variant={'RobotoBold'} fontSize={15} textColor={'primary'}>
                                    {t("HR.leave")}
                                </Typography>
                                <Typography className={styles.textMargin} variant={'RobotoRegular'} fontSize={13} textColor={'secondary'}>
                                    {values.leaveTime}
                                </Typography>
                            </Grid>
                            <Box width="100%" />
                            <div style={{width: "100%", marginTop: "32px"}}>
                                <div className="cabecalho">
                                    {t("GENERAL.activities").toUpperCase()}
                                </div>
                                <MyActivitiesTable
                                    rows={values.activityRecords}
                                    headers={["id", "activityCategory", "name", "description","rhActivityCategoriesId", "activityCategoriesId"]}
                                    visibleHeaders={["Categoria de Atividade", "Nome da Atividade", "Descrição", ""]}
                                    sortedFields={["activityCategory", "name", "description"]}
                                    mainField="activityCategory"
                                    context={"view"}
                                    activityCategoryArray={activityCategoryArray}
                                />
                                {values.activityRecords.length === 0 ?
                                    <div className={classes.messagePaper}>
                                        <div style={{ padding: "30px 0px 10px 0px", fontSize: "22px", fontWeight: "bold" }} >{t("HR.noActivitiesRecordMessage")}</div>
                                        <div style={{ padding: "0px 0px 30px 0px", fontSize: "15px" }} dangerouslySetInnerHTML={{ __html: t("HR.noActivitiesRecordSubMessage") }} />
                                    </div> : null
                                }
                            </div>
                            <Box width="100%" />
                            <div className={styles.buttonsWrapper}>
                                <Link to={`/CollaboratorClockRecord/${values.id}`}>
                                    <Buttons
                                        tipo="BN-noback"
                                        conteudo={t("GENERAL.comeBack")}
                                    />
                                </Link>
                            </div>
                        </Grid>
                </Paper>
                </ContentContainer>
                <Footer />
            </Transition>
        </React.Fragment >

    );
};

export default ProfileManagement;