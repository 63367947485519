import axios from 'axios';
import { useMutation } from 'react-query';
import Cookies from 'js-cookie';
import { useSnackbar } from 'hooks/UseSnackbar';
import { useTranslation } from 'react-i18next';
import { getManagersAndSendEmails } from 'services/Emails/General/generalEmailService';

export const useMutationFireCollaborator = (
    handleClose,
    checked,
    userId,
    fetchData
) => {
    const toast = useSnackbar();
    const [t] = useTranslation();

    const mutation = useMutation({
        mutationFn: async obj =>
            await axios.put(
                process.env.REACT_APP_SERVER +
                    `api/Collaborator/Collaborators/Admission/Demission`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            ),
        onSuccess: res => {
            toast.success(t('HR.collaboratorFired'));
            fetchData(userId);
            handleClose();

            const data = res.data;

            if (checked) {
                const emailData = {
                    collaboratorName: data.shortname,
                    collaboratorEmail: data.username,
                    collaboratorCpf: data.cpf,
                    collaboratorId: data.id,
                    collaboratorRegime: data.ruleset,
                    collaboratorJob: data.job,
                    operatorName: data.operator.shortname,
                    enabled: data.enabled
                };

                getManagersAndSendEmails(
                    emailData,
                    'api/Admin/Collaborator/Fired',
                    'ADMIN_COLLABORATOR_FIRED_EMAIL_OPTIONAL',
                    'adminManagers'
                );
            }
        },
        onError: err => {
            if (err.response.data.message && err.response.data.code !== 1000) {
                toast.error(err.response.data.message);
            } else {
                toast.error(
                    err.response.data.message === 'ITENS'
                        ? t('HR.errorHasItensAssociated')
                        : err.response.data.message &&
                          err.response.data.message === 'VACATION'
                        ? t('HR.errorHasVacationPending')
                        : t('HR.collaboratorUpdatedError')
                );
            }
        },
        onSettled: () => {
            fetchData(userId);
            handleClose();
        }
    });

    return mutation;
};
