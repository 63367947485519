import axios from "axios";
import React, { useReducer } from "react";
import { useTranslation } from "react-i18next";
import { DEFAULT, HRCOSTS, USERS } from "../types";
import HRCostsContext from "./hrCostsContext";
import HRCostsReducer from "./hrCostsReducer";
import Cookies from "js-cookie";

const HRCostState = props => {
  const initialState = {
    userCosts: [],
    usersCosts: [],
    user: {},
    users: [],
    message: "",
    response: "",
    responseType: "",
    hasChanged: 0,
    successiveActions: 0,
    loading: false,
    userContract: {}
  };

  const [t] = useTranslation();
  const [state, dispatch] = useReducer(HRCostsReducer, initialState);

  const setLoading = () => dispatch({ type: DEFAULT.SET_LOADING });
  const resetMessage = () => dispatch({ type: DEFAULT.RESET_MESSAGE });
  const setHasChanged = () => dispatch({ type: DEFAULT.SET_HAS_CHANGED });
  const resetUserCosts = () => dispatch({ type: HRCOSTS.RESET_USER_COSTS });

  const getUsersCosts = async () => {
    setLoading();
    const res = await axios.get(process.env.REACT_APP_SERVER + "api/usercosts", {
      headers: {
        "Content-type": "application/json",
        "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
      }
    });
    dispatch({
      type: HRCOSTS.GET_USERS_COSTS,
      payload: res.data
    });
  };

  const getUserCosts = async id => {
    // setLoading();
    const res = await axios.get(
      process.env.REACT_APP_SERVER + `api/usercosts/${id}`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
      }
    }
    );
    dispatch({
      type: HRCOSTS.GET_USER_COSTS,
      payload: res.data
    });
  };

  const getUsers = async () => {
    setLoading();
    const res = await axios.get(process.env.REACT_APP_SERVER + "api/users/all", {
      headers: {
        "Content-type": "application/json",
        "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
      }
    });
    dispatch({
      type: USERS.GET_USERS,
      payload: res.data
    });
  };

  const getUser = async id => {
    setLoading();
    getUserCosts(id)
    const res = await axios.get(
      process.env.REACT_APP_SERVER + `api/users/${id}`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
      }
    }
    );
    dispatch({
      type: USERS.GET_USER,
      payload: res.data
    });
  };

  const getUserContract = async id => {
    //setLoading();
    const res = await axios.get(
      process.env.REACT_APP_SERVER + `api/ruleSet/${id}`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
      }
    }
    );
    dispatch({
      type: HRCOSTS.GET_USER_CONTRACT,
      payload: res.data
    });
  };

  const submitCosts = obj => {
    setLoading();
    axios
      .post(process.env.REACT_APP_SERVER + "api/usercosts/", obj, {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "bearer " +
            (Cookies.get("auth-token") ? Cookies.get("auth-token") : ""),
        },
      })
      .then((res) => {
        if (res.statusText === "OK") {
          dispatch({
            type: HRCOSTS.SUBMIT_COSTS,
            payload: t("HRCOSTS.successesUpdate"),
            responseType: "SUCCESS",
            successiveActions: state.successiveActions + 1,
            hasChanged: state.successiveActions + 2,
          });
        } else {
          dispatch({
            type: HRCOSTS.SUBMIT_COSTS,
            payload: t("HRCOSTS.cantUpdate"),
            responseType: "ERROR",
            successiveActions: state.successiveActions + 1,
            hasChanged: state.successiveActions + 2,
          });
        }
      })
      .catch(() => {
        dispatch({
          type: HRCOSTS.SUBMIT_COSTS,
          payload: t("HRCOSTS.cantUpdate"),
          responseType: "ERROR",
          successiveActions: state.successiveActions + 1,
          hasChanged: state.successiveActions + 2,
        });
      });
  };

  return (
    <HRCostsContext.Provider
      value={{
        userCosts: state.userCosts,
        usersCosts: state.usersCosts,
        user: state.user,
        users: state.users,
        loading: state.loading,
        message: state.message,
        hasChanged: state.hasChanged,
        successiveActions: state.successiveActions,
        userContract: state.userContract,
        getUserCosts,
        resetUserCosts,
        getUsersCosts,
        submitCosts,
        getUser,
        getUsers,
        setHasChanged,
        resetMessage,
        response: state.response,
        responseType: state.responseType,
        getUserContract
      }}
    >
      {props.children}
    </HRCostsContext.Provider>
  );
};

export default HRCostState;