export const libraryNotifications = [
    {
        event: 'NEW_REQUISITION_EMAIL_GENERAL',
        allowSend: false,
        automatic: false
    },
    {
        event: 'NEW_REQUISITION_EMAIL_COORDINATION',
        allowSend: false,
        automatic: false
    },
    {
        event: 'APPROVED_REQUISITION_EMAIL_GENERAL',
        allowSend: false,
        automatic: false
    },
    {
        event: 'APPROVED_REQUISITION_EMAIL_COORDINATION',
        allowSend: false,
        automatic: false
    },
    {
        event: 'WITHDRAW_REQUISITION_EMAIL_GENERAL',
        allowSend: false,
        automatic: false
    },
    {
        event: 'WITHDRAW_REQUISITION_EMAIL_COORDINATION',
        allowSend: false,
        automatic: false
    },
    {
        event: 'CANCEL_REQUISITION_EMAIL_GENERAL',
        allowSend: false,
        automatic: false
    },
    {
        event: 'CANCEL_REQUISITION_EMAIL_COORDINATION',
        allowSend: false,
        automatic: false
    },
    {
        event: 'DEVOLUTION_REQUISITION_EMAIL_GENERAL',
        allowSend: false,
        automatic: false
    },
    {
        event: 'DEVOLUTION_REQUISITION_EMAIL_COORDINATION',
        allowSend: false,
        automatic: false
    },
    {
        event: 'DEVOLUTION_REMINDER_REQUISITION_EMAIL_GENERAL',
        allowSend: false,
        automatic: false
    },
    {
        event: 'DEVOLUTION_REMINDER_REQUISITION_EMAIL_COORDINATION',
        allowSend: false,
        automatic: false
    },
    {
        event: 'DEVOLUTION_REMINDER_LATE_REQUISITION_EMAIL_GENERAL',
        allowSend: false,
        automatic: false
    },
    {
        event: 'DEVOLUTION_REMINDER_LATE_REQUISITION_EMAIL_COORDINATION',
        allowSend: false,
        automatic: false
    },
    {
        event: 'WEEKLY_REPORT_LATE_LOANS_EMAIL',
        allowSend: false,
        automatic: false
    },
    {
        event: 'AUTOMATIC_RENOVATION_EXEMPLAR_EMAIL',
        allowSend: false,
        automatic: false
    },
    {
        event: 'NEW_REQUISITION_QUEUE_EMAIL',
        allowSend: false,
        automatic: false
    },
    {
        event: 'CANCELED_REQUISITION_QUEUE_EMAIL',
        allowSend: false,
        automatic: false
    },
    {
        event: 'NEW_RESERVE_TO_REQUISITION_EMAIL',
        allowSend: false,
        automatic: false
    }
];
