import ProjectsContext from "context/projects/projectsContext";
import SearchContext from "context/search/searchContext";
import React, { useContext, useEffect, useState } from "react";
import ParticipantsTable from "./ParticipantsTable";
import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";
import Add from "@material-ui/icons/Add";
import Dissatisfied from "@material-ui/icons/SentimentVeryDissatisfied";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import AllowancesContext from "context/allowance/allowanceContext";
import Snackbar from "@material-ui/core/Snackbar";
import MySnackbarContentWrapper from "../../layout/Message";
import Slide from "@material-ui/core/Slide";
import Toolbar from "@material-ui/core/Toolbar";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";

const ParticipantsIndex = props => {
  const { projectId, participants, mode } = props;
  const searchContext = useContext(SearchContext);
  const { filter, searchTermChanged } = searchContext;
  const [t] = useTranslation();
  const classes = useStyles();
  let dataFilter = [];
  const hasParticipant = participants.length > 0;
  dataFilter = participants;

  const projectContext = useContext(ProjectsContext);
  const allowanceContext = useContext(AllowancesContext);
  const {
    response,
    responseType,
    successiveActions,
    hasChanged,
    setHasChanged } = projectContext;
  const {
    allowanceResponse,
    allowanceResponseType,
    messageHasChanged,
    resetAllowanceMessage,
    verifyPermission,
    permission
  } = allowanceContext;

  const [snackBar, setSnackBar] = useState({
    open: false,
    kind: "",
    content: ""
  });

  if (filter) {
    dataFilter = dataFilter.filter(
      item =>
        item.participantName.toLowerCase().includes(filter.toLowerCase()) ||
        item.participantCpf.toLowerCase().includes(filter.toLowerCase()) ||
        item.participantfunction.toLowerCase().includes(filter.toLowerCase()) ||
        item.participantEducation.toLowerCase().includes(filter.toLowerCase())
    );
  }

  useEffect(() => {
    return () => {
      searchTermChanged("");
    };
  }, []);

  useEffect(() => {
    verifyPermission("PARTICIPANTS")
    if (allowanceResponseType === "ERROR" && messageHasChanged) {
      handleSnack({ kind: "error", content: allowanceResponse });
    }
    if (responseType === "SUCCESS" && hasChanged > successiveActions) {
      handleSnack({ kind: "success", content: response });
    }
    if (responseType === "ERROR" && hasChanged > successiveActions) {
      handleSnack({ kind: "error", content: response });
    }
    return () => { };
  }, [
    responseType,
    response,
    successiveActions,
    allowanceResponseType,
    allowanceResponse,
    permission.length
  ]);

  const handleSnack = () => {
    setSnackBar({ ...snackBar, open: true });
  };

  const handleSnackClose = () => {
    setSnackBar({ ...snackBar, open: false });
    setHasChanged();
    resetAllowanceMessage();
  };

  const cleanPageProjectParticipants = () => {
    if(hasParticipant){
      document.getElementById("cleanPageProjectParticipants").click();
    }
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackBar.open}
        onClose={handleSnackClose}
        TransitionComponent={Slide}
        transitionDuration={{ enter: 500, exit: 500 }}
        ContentProps={{
          "aria-describedby": "message-id"
        }}
        autoHideDuration={2000}
      >
        <MySnackbarContentWrapper
          onClose={handleSnackClose}
          variant={
            responseType !== ""
              ? responseType.toLowerCase()
              : allowanceResponseType.toLowerCase()
          }
          message={
            <span id="message-id">
              {response !== "" ? response : allowanceResponse}
            </span>
          }
        />
      </Snackbar>
      <Paper>
        <div className={classes.divAddParticipant}>
          <Toolbar>
              {hasParticipant ? 
              <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder={t("PARTICIPANTS.participantsSearchLabel")}
                value={filter}
                onFocus={cleanPageProjectParticipants}
                onChange={e => searchTermChanged(e.target.value)}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput
                }}
                inputProps={{ "aria-label": "search" }}
              />
              </div>
              :<></> }
          </Toolbar>
          {mode === t("GENERAL.editMode") && permission["allowCreate"] &&
            <Link to={`/execution/addparticipant/${projectId}`}>
              <Button
                renderAs={Link}
                style={{ margin: "22px", padding:"15px" }}
              >
                <div className={classes.buttonIcon}>
                  <Add />
                </div>
                {t("PARTICIPANTS.addParticipant")}
              </Button>
            </Link>
          }
        </div>
        <div className="lineBreak"></div>
        {hasParticipant ?
          <ParticipantsTable
            rows={dataFilter}
            headers={["id", "imagePath", "participantCpf", "participantName", "participantLattes", "participantEducation", "participantfunction", "participantId"]}
            sortedFields={["", "", "participantName", "", "participantEducation", "participantfunction"]}
            visibleHeaders={[
              "#",
              "CPF",
              "NOME",
              "CURRÍCULO LATTES",
              "GRAU DE FORMAÇÃO",
              "FUNÇÃO"
            ]}
            mainField="imagePath"
            projectId={projectId}
            mode={mode}
            permission={permission}
          /> :
          <div className={classes.noParticipantsMessage}>
            <h2>{t("PARTICIPANTS.noParticipantsMessage")}</h2>
            <h4>{t("PARTICIPANTS.noParticipantsSubMessage")}</h4>
          </div>
        }
        {(hasParticipant && dataFilter.length === 0) &&
          <div className={classes.noParticipantsMessage}>
            <div >
              <Dissatisfied className={classes.notFoundIconStyle} />
            </div>
            <h2>{t("GENERAL.noResultsFound")}</h2>
            <h5>{`Sua busca por "${filter}" não retornou resultados.`}</h5>
          </div>
        }
      </Paper>
    </div>
  );
};

export default ParticipantsIndex;