/* eslint-disable */
import DateFnsUtils from "@date-io/date-fns";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Dissatisfied from "@material-ui/icons/SentimentVeryDissatisfied";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Footer from "components/layout/Footer";
import Spinner from "components/layout/spinners/Index";
import AllowancesContext from "context/allowance/allowanceContext";
import InventoryContext from "context/inventory/inventoryContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { animated, useTransition } from "react-spring";
import { useStyles } from "../../../components/layout/CommonStyles";
import MySnackbarContentWrapper from "../../../components/layout/Message";
import AdmRequestsTable from "./RequestsTable";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import InputAdornment from '@material-ui/core/InputAdornment';

const CollaboratorsRequisitions = (props) => {
    const classes = useStyles();
    const [t] = useTranslation();
    const inventoryContext = useContext(InventoryContext);
    const allowanceContext = useContext(AllowancesContext);
    const [openAdvancedSearch, setOpenAdvancedSearch] = useState(false);
    const [openExport, setOpenExport] = useState(false);
    const [requesterArray, setRequesterArray] = useState([]);
    const [open, setOpen] = React.useState(false);

    const [snackBar, setSnackBar] = useState({
        open: false,
        kind: "",
        content: ""
    });

    const [findText, setFindText] = useState("");
    const [filterRequisitionType, setFilterRequisitionType] = useState("");
    const [filterStatus, setFilterStatus] = useState("");
    const [filterRequester, setFilterRequester] = useState("");

    const [filterDateCreated, setFilterDateCreated] = useState(null);
    const [dataFilter, setDataFilter] = useState([]);

    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);


    const [canClick, setCanClick] = useState(false);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }

    const status = ["Todas", "Aguardando Execução", "Executada", "Recusada"];
    const requestType = ["Todas", "Alteração de Local", "Devolução de Equipamento", "Devolução de Software", "Utilização de Equipamento", "Utilização de Software"];

    const {
        loadingSoftware,
        response,
        hasChanged,
        setHasChanged,
        responseType,
        successiveActions,
        getAllRequests,
        allRequisitions,
        getCollaboratorsManaged,
        collaboratorsManaged,
    } = inventoryContext;

    const {
        allowanceResponse,
        allowanceResponseType,
        messageHasChanged,
        resetAllowanceMessage,
        permission
    } = allowanceContext;

    useEffect(() => {
        if (allRequisitions && allRequisitions.length) {
            const { newDataFilter, textNoFound } = applyFilter(allRequisitions)

            if (newDataFilter.length === 0) {
                setDataFilter([]);
                setHasNoResult(true);
                setFindText(textNoFound);
            } else {
                setDataFilter(newDataFilter);
                setHasNoResult(false);
                setFindText("");
            }
        }
    }, [allRequisitions, filterRequester, filterRequisitionType, filterStatus, filterDateCreated]);

    const applyFilter = (Requests) => {
        let newDataFilter = [];
        let textNoFound = "";

        newDataFilter = Requests;

        if (filterRequisitionType && filterRequisitionType.toLowerCase() !== "todas") {
            newDataFilter = newDataFilter.filter(item =>
                item.class && item.class.toLowerCase() === (filterRequisitionType.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0) textNoFound = filterRequisitionType;
        }

        if (filterStatus && filterStatus.toLowerCase() !== "todas") {
            newDataFilter = newDataFilter.filter(item =>
                item.status && item.status.toLowerCase() === (filterStatus.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0) textNoFound = filterStatus;
        }

        if (filterRequester) {
            newDataFilter = newDataFilter.filter(item =>
                item.requester && item.requester.toLowerCase().includes(filterRequester.toLowerCase())
            );
            if (!textNoFound && newDataFilter.length === 0) textNoFound = filterRequester;
        }

        if (filterDateCreated && !isNaN(filterDateCreated)) {
            let filterDateBR = dateToBR(filterDateCreated)
            newDataFilter = newDataFilter.filter(
                item =>
                    item.dateCreated === filterDateBR
            );

            if (!textNoFound && newDataFilter.length === 0) textNoFound = filterDateBR;
        }

        return { newDataFilter, textNoFound };
    }

    useEffect(() => {
        if (filterRequester ||
            filterRequisitionType ||
            filterStatus ||
            filterDateCreated
        ) { setCanClick(true) }
        else {
            setCanClick(false)
        }
    }, [
        filterStatus,
        filterRequester,
        filterRequisitionType,
        filterDateCreated,
    ])

    const hasItens = allRequisitions.length > 0;
    const [hasNoResult, setHasNoResult] = useState(hasItens && dataFilter.length === 0);

    const transitions = useTransition(loadingSoftware, null, {
        from: { opacity: 0 },
        enter: {
            opacity: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "calc(100vh - 70px)"
        },
        leave: { opacity: 0 }
    });

    useEffect(() => {
        if (collaboratorsManaged) {
            const requesters = ["Todos", ...collaboratorsManaged.map(m => m.name).sort()];
            setRequesterArray(requesters)
        }

    }, [collaboratorsManaged.length])

    useEffect(() => {
        getAllRequests();
        getCollaboratorsManaged(auth.id)


        if (allowanceResponseType === "ERROR" && messageHasChanged) {
            handleSnack({ kind: "error", content: allowanceResponse });
        }
        if (responseType === "SUCCESS" && hasChanged > successiveActions) {
            handleSnack({ kind: "success", content: response });
        }
        if (responseType === "ERROR" && hasChanged > successiveActions) {
            handleSnack({ kind: "error", content: response });
        }

        return () => { };
    }, [
        responseType,
        response,
        successiveActions,
        allowanceResponseType,
        allowanceResponse,
        permission.length,
        allRequisitions.length
    ]);

    const handleSnack = () => {
        setSnackBar({ ...snackBar, open: true });
        setHasChanged();
    };
    const handleSnackClose = () => {
        setSnackBar({ ...snackBar, open: false });
        resetAllowanceMessage();
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    function dateToBR(date) {
        var data = new Date(date),
            dia = data.getDate().toString(),
            diaF = (dia.length == 1) ? '0' + dia : dia,
            mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
            mesF = (mes.length == 1) ? '0' + mes : mes,
            anoF = data.getFullYear();
        return diaF + "/" + mesF + "/" + anoF;
    }

    const clearField = () => {
        setFilterRequester("")
    }

    return transitions.map(({ item, key, props }) =>
        <React.Fragment>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={snackBar.open}
                onClose={handleSnackClose}
                TransitionComponent={Slide}
                transitionDuration={{ enter: 500, exit: 500 }}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                autoHideDuration={2000}
            >
                <MySnackbarContentWrapper
                    onClose={handleSnackClose}
                    variant={
                        responseType !== ""
                            ? responseType.toLowerCase()
                            : allowanceResponseType.toLowerCase()
                    }
                    message={
                        <span id="message-id">
                            {response !== "" ? response : allowanceResponse}
                        </span>
                    }
                />
            </Snackbar>
            <div className="container">
                <Breadcrumbs
                    className={classes.breadCrumbs}
                    aria-label="breadcrumb"
                    separator={<NavigateNextIcon fontSize="small" />}
                >
                    <Typography>{t("INVENTORY.inventory")}</Typography>
                    <Typography>{t("INVENTORY.requisitions")}</Typography>
                </Breadcrumbs>
                <div className={classes.containerHeader}>
                    <div className="title">
                        <h1 className={classes.title}>{t("INVENTORY.requisitions")}</h1>
                    </div>
                </div>
                <Paper >
                    <div className="cabecalho">
                        {t("INVENTORY.manageRequests").toUpperCase()}
                    </div>
                    {item ? (
                        <animated.div key={key} style={props}>
                            <Spinner />;
                        </animated.div>
                    ) : (
                        <React.Fragment>
                            <div className={classes.divAddParticipantLeft}>
                                <Toolbar className={classes.searchToolbarInventory} >
                                    <div style={{ display: !hasItens ? "none" : "" }} className={classes.searchInventory} >
                                        <TextField
                                            id="searchcategory"
                                            label={t("INVENTORY.requester")}
                                            name="searchcategory"
                                            style={{ minWidth: "180px", marginTop: 0, marginBottom: 0, marginLeft: 5, width: "100%" }}
                                            value={filterRequester}
                                            className={classes.textField}
                                            onChange={(ev) => setFilterRequester(ev.target.value)}
                                            margin="normal"
                                            classes={{
                                                root: classes.inputRoot,
                                                input: classes.inputSearch
                                            }}
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment position="start">
                                                        <IconButton
                                                            edge="start"
                                                            className={classes.menuButton}
                                                            color="inherit"
                                                            aria-label="Close"
                                                            onClick={clearField}
                                                        >
                                                            <Close />
                                                        </IconButton>
                                                    </InputAdornment>,
                                            }}
                                        >
                                        </TextField>
                                    </div>
                                </Toolbar>
                                <Toolbar className={classes.searchToolbarInventory} >
                                    <div style={{ display: !hasItens ? "none" : "" }} className={classes.searchInventory} >
                                        <TextField
                                            select
                                            id="searchcategory"
                                            label={t("INVENTORY.statusRequisition")}
                                            name="searchcategory"
                                            style={{ minWidth: "180px", marginTop: 0, marginBottom: 0, marginLeft: 5 }}
                                            value={filterStatus}
                                            className={classes.textField}
                                            onChange={(ev) => setFilterStatus(ev.target.value)}
                                            margin="normal"
                                            classes={{
                                                root: classes.inputRoot,
                                                input: classes.inputSearch
                                            }}
                                            inputProps={{ "aria-label": "search" }}
                                        >
                                            {status.map(status => (
                                                <MenuItem key={status} value={status}>
                                                    {status}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                </Toolbar>
                                <Toolbar className={classes.searchToolbarInventory}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <div style={{ display: !hasItens ? "none" : "" }} className={classes.searchInventory}>
                                            <KeyboardDatePicker
                                                className={classes.textField}
                                                disableToolbar
                                                autoOk={true}
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                id="dateAcquisition"
                                                invalidDateMessage=""
                                                label={t("INVENTORY.dateRequisition")}
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date"
                                                }}
                                                value={filterDateCreated}
                                                onChange={(ev) => setFilterDateCreated(ev)}
                                            />
                                        </div>
                                    </MuiPickersUtilsProvider>
                                </Toolbar>
                                <Toolbar className={classes.searchToolbarInventory} >
                                    <div style={{ display: !hasItens ? "none" : "" }} className={classes.searchInventory} >
                                        <TextField
                                            select
                                            id="searchcategory"
                                            label={t("INVENTORY.requestType")}
                                            name="searchcategory"
                                            style={{ minWidth: "180px", marginTop: 0, marginBottom: 0, marginLeft: 5 }}
                                            value={filterRequisitionType}
                                            className={classes.textField}
                                            onChange={(ev) => setFilterRequisitionType(ev.target.value)}
                                            margin="normal"
                                            classes={{
                                                root: classes.inputRoot,
                                                input: classes.inputSearch
                                            }}
                                            inputProps={{ "aria-label": "search" }}
                                        >
                                            {requestType.map(type => (
                                                <MenuItem key={type} value={type}>
                                                    {type}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                </Toolbar>
                            </div>
                            <AdmRequestsTable
                                rows={dataFilter}
                                toFirstPage={canClick}
                                headers={["id", "dateCreated", "requester", "class", "status"]}
                                visibleHeaders={["Data da requisição", "Solicitante", "Tipo de Requisição", "Status da requisição", ""]}
                                sortedFields={["dateCreated", "requester", "class", "status"]}
                                mainField="dateCreated"
                                permission={permission}
                            />
                            {!hasItens &&
                                <div className={classes.noParticipantsMessage}>
                                    <h2>{t("INVENTORY.noItensMessage")}</h2>
                                    <h4>{t("INVENTORY.noItensSubMessage")}</h4>
                                </div>
                            }
                            {hasNoResult &&
                                <div className={classes.noParticipantsMessage}>
                                    <div >
                                        <Dissatisfied className={classes.notFoundIconStyle} />
                                    </div>
                                    <h2>{t("GENERAL.noResultsFound")}</h2>
                                    <h5>{`Sua busca por "${findText}" não retornou resultados.`}</h5>
                                </div>
                            }
                        </React.Fragment>
                    )}
                </Paper>
            </div>
            <Footer />
        </React.Fragment>
    );
};

export default CollaboratorsRequisitions;