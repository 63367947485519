import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import AddIcon from '@material-ui/icons/Add';
import { default as KeyboardArrowLeft } from '@material-ui/icons/KeyboardArrowLeft';
import { default as KeyboardArrowRight } from '@material-ui/icons/KeyboardArrowRight';
import RemoveIcon from '@material-ui/icons/Remove';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    StyledTableCell,
    StyledTableRow,
    useStyles
} from 'components/profiles/ProfileStyles';
import Actions from '../books/BooksTableAction';
import { AllAuthorsName } from 'utils/library/AllAuthorsName';
import { PadToNDigits } from 'utils/general/PadToNDigits';
import { translateStatusExemplar } from 'utils/library/translateStatus';
import { LibraryPlaceValues } from 'global/constants';

const useStyles1 = makeStyles(theme => ({
    root: {
        flexShrink: 0,
        flexGrow: 1,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2.5)
    },
    paginationTop: {
        float: 'right'
    }
}));

function desc(a, b, orderBy) {
    // .normalize("NFD").replace(/[\u0300-\u036f]/g, "") : desconsidera
    // acentos

    if (a[orderBy] === null) a[orderBy] = '';
    if (b[orderBy] === null) b[orderBy] = '';

    if (orderBy == 'exemplaryCount') {
        var cost1 = a['exemplars'] && a['exemplars'].length;
        var cost2 = b['exemplars'] && b['exemplars'].length;
        if (cost2 < cost1) return -1;
        if (cost2 > cost1) return 1;
    } else if (orderBy == 'authors') {
        var authors1 = a['authors'] && a['authors'].map(x => x.name).join(', ');
        var authors2 = b['authors'] && b['authors'].map(x => x.name).join(', ');
        if (
            authors2
                .toString()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .trim()
                .toLowerCase() <
            authors1
                .toString()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .trim()
                .toLowerCase()
        ) {
            return -1;
        }
        if (
            authors2
                .toString()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .trim()
                .toLowerCase() >
            authors1
                .toString()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .trim()
                .toLowerCase()
        ) {
            return 1;
        }
    } else if (orderBy === 'category') {
        if (
            b[orderBy].name
                .toString()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .trim()
                .toLowerCase() <
            a[orderBy].name
                .toString()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .trim()
                .toLowerCase()
        ) {
            return -1;
        }
        if (
            b[orderBy].name
                .toString()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .trim()
                .toLowerCase() >
            a[orderBy].name
                .toString()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .trim()
                .toLowerCase()
        ) {
            return 1;
        }
    } else {
        if (
            b[orderBy]
                .toString()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .trim()
                .toLowerCase() <
            a[orderBy]
                .toString()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .trim()
                .toLowerCase()
        ) {
            return -1;
        }
        if (
            b[orderBy]
                .toString()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .trim()
                .toLowerCase() >
            a[orderBy]
                .toString()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .trim()
                .toLowerCase()
        ) {
            return 1;
        }
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc'
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
}

function createContent(rows, headers, order, orderBy) {
    const rowsCopy = [];
    var obj = {};
    for (var i = 0; i < rows.length; i++) {
        for (var j = 0; j < headers.length; j++) {
            obj[headers[j]] = rows[i][headers[j]];
        }
        rowsCopy.push(obj);
        obj = {};
    }
    return stableSort(rowsCopy, getSorting(order, orderBy));
}

const BookCategoryTable = props => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [rows, setRows] = useState({});
    const [rowsLength, setRowsLength] = useState(0);
    const [headers, setHeaders] = useState([]);
    const [mainField, setMainField] = useState('');
    const [visibleHeaders, setVisibleHeaders] = useState([]);
    const [sortedFields, setSortedFields] = useState([]);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('title');

    React.useEffect(() => {
        setHeaders(props.headers);
        setRows(props.rows);
        setRowsLength(props.rows.length);
        setMainField(props.mainField);
        setVisibleHeaders(props.visibleHeaders);
        setSortedFields(props.sortedFields);
        adjustRowsPerpage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.headers, props.mainField, props.rows, props.visibleHeaders]);

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }
    const adjustRowsPerpage = () => {
        if (props.rows.length >= 10) {
            setRowsPerPage(10);
        } else {
            setRowsPerPage(props.rows.length);
        }
    };
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === sortedFields[property] && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(sortedFields[property]);
    };

    useEffect(() => {
        if (props.toFirstPage) {
            document.getElementById('toFirstPage').click();
        }
    }, [props.rows, props.toFirstPage]);

    const toFirstPage = () => {
        setPage(0);
    };

    const UsersTableHead = props => {
        const { order, orderBy, onRequestSort } = props;
        const [t] = useTranslation();
        const paginationTop = action => e => {
            var selector = "[aria-label^='" + action + "']";
            document.querySelector(selector).click();
            e.preventDefault();
        };
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    {visibleHeaders.map((header, index) => (
                        <StyledTableCell
                            key={index}
                            size="small"
                            align="left"
                            sortDirection={
                                orderBy === sortedFields[index] ? order : false
                            }
                        >
                            <TableSortLabel
                                disabled={index == 0 || index == 5}
                                active={orderBy === sortedFields[index]}
                                direction={order}
                                onClick={createSortHandler(index)}
                            >
                                {header.toUpperCase()}
                                {orderBy === sortedFields[index] ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc'
                                            ? 'sorted descending'
                                            : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    };

    UsersTableHead.propTypes = {
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired
    };

    function Row(props) {
        const [open, setOpen] = React.useState(false);
        const { row } = props;
        return (
            <React.Fragment>
                <StyledTableRow key={row.id}>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <RemoveIcon /> : <AddIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row.title ? row.title : '-'}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row.authors ? AllAuthorsName(row.authors) : '-'}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row.category.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row['exemplars'] ? row['exemplars'].length : '-'}
                    </TableCell>
                    <TableCell size="small" align="right">
                        <div
                            style={{
                                marginTop: 'auto',
                                marginBottom: 'auto',
                                display: 'flex',
                                flexWrap: 'nowrap ',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Actions
                                isExemplar={false}
                                name={row.socialreason}
                                item={row}
                                permission={props.permission}
                            />
                        </div>
                    </TableCell>
                </StyledTableRow>
                <TableRow>
                    {row['exemplars'] ? (
                        <TableCell
                            style={{
                                paddingBottom: 0,
                                paddingTop: 0,
                                paddingLeft: 0,
                                paddingRight: 0
                            }}
                            colSpan={6}
                        >
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <Box
                                    margin={1}
                                    style={{ marginLeft: 0, marginRight: 0 }}
                                >
                                    <Table size="small" aria-label="purchases">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell
                                                    className={
                                                        classes.subTitleTable
                                                    }
                                                >
                                                    CÓDIGO DO EXEMPLAR
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.subTitleTable
                                                    }
                                                >
                                                    STATUS
                                                </TableCell>
                                                <TableCell
                                                    colSpan={2}
                                                    className={
                                                        classes.subTitleTable
                                                    }
                                                >
                                                    RESPONSÁVEL ADMINISTRATIVO
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {row['exemplars'].map(exemplar => (
                                                <StyledTableRow
                                                    key={exemplar.id}
                                                >
                                                    <TableCell></TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {exemplar.code
                                                            ? 'BIB-' +
                                                              PadToNDigits(
                                                                  exemplar.code,
                                                                  4
                                                              )
                                                            : '-'}
                                                    </TableCell>
                                                    <TableCell>
                                                        {exemplar.status
                                                            ? translateStatusExemplar(
                                                                  exemplar.status
                                                              )
                                                            : '-'}
                                                    </TableCell>
                                                    <TableCell>
                                                        {exemplar.library === LibraryPlaceValues.EXTERNAL
                                                            ? exemplar.itemResponsibleAdministrativeExternal 
                                                            : exemplar.responsibleAdministrative ? exemplar.responsibleAdministrative.shortname  :   '-'}
                                                    </TableCell>
                                                    <TableCell
                                                        size="small"
                                                        align="right"
                                                    >
                                                        <div
                                                            style={{
                                                                marginTop:
                                                                    'auto',
                                                                marginBottom:
                                                                    'auto',
                                                                display: 'flex',
                                                                flexWrap:
                                                                    'nowrap ',
                                                                justifyContent:
                                                                    'flex-end'
                                                            }}
                                                        >
                                                            <Actions
                                                                isExemplar={
                                                                    true
                                                                }
                                                                name={
                                                                    exemplar.socialreason
                                                                }
                                                                item={exemplar}
                                                                title={row}
                                                                permission={
                                                                    props.permission
                                                                }
                                                            />
                                                        </div>
                                                    </TableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Box>
                            </Collapse>
                        </TableCell>
                    ) : null}
                </TableRow>
            </React.Fragment>
        );
    }

    return (
        <Paper className={classes.root}>
            <div className={classes.tableWrapper}>
                <Table className={classes.table} size="small">
                    {rows.length > 0 && (
                        <UsersTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                    )}
                    <TableBody>
                        {createContent(rows, headers, order, orderBy)
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            .map(row => (
                                <Row key={row.name} row={row} />
                            ))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 48 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <button hidden id="toFirstPage" onClick={toFirstPage}></button>
                {rows.length > 0 && (
                    <TableFooter style={{ float: 'right' }}>
                        <TablePagination
                            labelRowsPerPage={'Resultados por página'}
                            count={rowsLength}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            component="div"
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </TableFooter>
                )}
            </div>
        </Paper>
    );
};

BookCategoryTable.propTypes = {
    rows: PropTypes.array.isRequired,
    headers: PropTypes.array.isRequired,
    visibleHeaders: PropTypes.array.isRequired,
    mainField: PropTypes.string.isRequired
};

export default BookCategoryTable;
