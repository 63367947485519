import React, { useContext, useEffect, useState } from "react";

import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import MySnackbarContentWrapper from "components/layout/Message";
import Box from "@material-ui/core/Box";
import Breadcrumb from "components/layout/breadcrumb/Breadcrumb";
import VacationsContext from "context/hrModule/vacations/vacationsContext";
import AllowancesContext from "context/allowance/allowanceContext";
import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";
import Grid from "@material-ui/core/Grid";
import Input from "components/inputs/Input";
import IconButton from "@material-ui/core/IconButton";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import { Redirect } from "react-router-dom";

import Transition from "components/layout/transition/Transition";
import Footer from "components/layout/Footer";
import MessagePaper from "components/messagePaper/MessagePaper";
import CollaboratorsVacationsPeriodsTable from "./CollaboratorsVacationsPeriodsTable";
import DialogCollaboratorVacationPeriod from "./DialogCollaboratorVacationPeriod";

import DateUtils from "utils/dates/DateUtils";

const CollaboratorsVacationsForm = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const vacationsContext = useContext(VacationsContext);
    const allowanceContext = useContext(AllowancesContext);
    const { id, userId, context, myVacations, vacationCalendar, ...others } =
        props;
    const [mustRedirect, setMustRedirect] = useState(false);

    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }

    const {
        loading,
        setLoading,
        setLoadingFalse,
        getCollaboratorVacation,
        collaboratorVacation,
        hasChanged,
        setHasChanged,
        responseType,
        response,
        successiveActions,
    } = vacationsContext;

    const [snackBar, setSnackBar] = useState({
        open: false,
        kind: "",
        content: "",
    });

    const {
        allowanceResponse,
        allowanceResponseType,
        messageHasChanged,
        resetAllowanceMessage,
        permission,
    } = allowanceContext;

    useEffect(() => {
        if (allowanceResponseType === "ERROR" && messageHasChanged) {
            handleSnack({ kind: "error", content: allowanceResponse });
        }

        if (responseType === "SUCCESS" && hasChanged > successiveActions) {
            handleSnack({ kind: "success", content: response });
        }
        if (responseType === "ERROR" && hasChanged > successiveActions) {
            handleSnack({ kind: "error", content: response });
        }

        return () => {};
    }, [
        responseType,
        response,
        successiveActions,
        allowanceResponseType,
        allowanceResponse,
    ]);

    const handleSnack = () => {
        setSnackBar({ ...snackBar, open: true });
        setHasChanged();
    };
    const handleSnackClose = () => {
        setSnackBar({ ...snackBar, open: false });
        resetAllowanceMessage();
    };

    const statusArray = [
        {
            label: t("GENERAL.allMasculine"),
            value: "ALL",
        },
        {
            label: t("HR.enjoyed"),
            value: "ENJOYED",
        },
        {
            label: t("HR.paid"),
            value: "PAID",
        },
        {
            label: t("HR.pending"),
            value: "PENDING",
        },
        {
            label: t("HR.underAcquisition"),
            value: "UNDERACQUISITION",
        },
    ];

    const getStatusFilterBool = (vacation, status) => {
        if (status === "ENJOYED") {
            if (vacation.enjoyedPeriodStatus === 1) {
                return true;
            }
        } else if (status === "PAID") {
            if (vacation.paidPeriodStatus === 2) {
                return true;
            }
        } else if (status === "PENDING") {
            if (
                vacation.enjoyedPeriodStatus === 3 ||
                vacation.paidPeriodStatus === 4
            ) {
                return true;
            }
        } else if (status === "UNDERACQUISITION") {
            if (
                vacation.enjoyedPeriodStatus === 2 ||
                vacation.paidPeriodStatus === 3
            ) {
                return true;
            }
        } else if (status === "ALL") {
            return true;
        } else {
            return false;
        }
    };

    const [values, setValues] = useState({
        id: "",
        name: "",
        ruleset: "",
        contractPeriodIdx: 0,
        remunerationBalance: "",
        enjoymentBalance: "",
        status: "ALL",
        periods: [],
        contracts: [],
    });

    const [dates, setDates] = useState({
        admissionDate: null,
        holidayDate: null,
        demissionDate: null,
    });

    const [canClick, setCanClick] = useState(false);
    const [viewMore, setViewMore] = useState([]);
    const [contractPeriod, setContractPeriod] = useState([]);
    const [openRemuneration, setOpenRemuneration] = useState(false);
    const [openEnjoyment, setOpenEnjoyment] = useState(false);
    const [vacationIdForDialog, setVacationIdForDialog] = useState(0);
    const [dialogType, setDialogType] = useState("");
    const [periodValues, setPeriodValues] = useState({});
    const [requiredName, setRequiredName] = useState(false);
    const [requiredBaseYear, setRequiredBaseYear] = useState(false);
    const [holidayDateErrorMessage, setHolidayDateErrorMessage] = useState("");
    const [startDateErrorMessage, setStartDateErrorMessage] = useState("");

    async function fetchData() {
        await setLoading();
        await getCollaboratorVacation(userId);
        await setLoadingFalse();
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData();
    }, []);

    const handleViewMore = idx => {
        const aux = [...viewMore];
        aux[idx] = !aux[idx];
        setViewMore(aux);
    };

    const handleOpenDialog = (dialog, period, idx) => {
        setPeriodValues({
            acquisitionPeriod:
                values.ruleset !== "CLT"
                    ? GeneratePeriodText(period)
                    : `${period.year}/${period.year + 1}`,
            daysToBePaid: period.daysToBePaid,
            daysToEnjoy: period.daysToEnjoy,
        });
        setVacationIdForDialog(period.id);
        setDialogType(dialog);
        setOpenRemuneration(true);
    };

    const translateStatusEnjoyed = value => {
        switch (value) {
            case 1:
                return "ENJOYED";
            case 2:
                return "UNDER_ACQUISITION";
            case 3:
                return "PENDING";
            default:
                break;
        }
    };

    const translateStatusPaid = value => {
        switch (value) {
            case 1:
                return "UNAVAILABLE";
            case 2:
                return "PAID";
            case 3:
                return "UNDER_ACQUISITION";
            case 4:
                return "PENDING";
            default:
                break;
        }
    };

    useEffect(() => {
        if (
            collaboratorVacation &&
            Object.keys(collaboratorVacation).length !== 0
        ) {
            setValues({});
            let aux = [];
            let idxLastContract = -1;
            collaboratorVacation.contracts.forEach((contract, idx) => {
                var comingAdmissionDate = DateUtils.parse(
                    contract.admissionDate
                );
                if (contract.demissionDate) {
                    var comingDemissionDate = DateUtils.parse(
                        contract.demissionDate
                    );
                }
                aux.push({
                    label:
                        DateUtils.format(comingAdmissionDate, "DD/MM/YYYY") +
                        " - " +
                        (contract.demissionDate
                            ? DateUtils.format(
                                  comingDemissionDate,
                                  "DD/MM/YYYY"
                              )
                            : t("GENERAL.currently")),
                    value: idx,
                });
                idxLastContract = idx;
            });

            setContractPeriod(aux);

            const contracts = collaboratorVacation.contracts;
            if (contracts.length > 0) {
                setValues({
                    ...values,
                    id: contracts[idxLastContract].id
                        ? contracts[idxLastContract].id
                        : null,
                    name: collaboratorVacation.name
                        ? collaboratorVacation.name
                        : "-",
                    contractPeriodIdx:
                        idxLastContract !== -1 ? idxLastContract : 0,
                    ruleset: contracts[idxLastContract].ruleset
                        ? contracts[idxLastContract].ruleset
                        : "",
                    remunerationBalance: contracts[idxLastContract]
                        .remunerationBalance
                        ? contracts[idxLastContract].remunerationBalance
                        : 0,
                    enjoymentBalance: contracts[idxLastContract]
                        .enjoymentBalance
                        ? contracts[idxLastContract].enjoymentBalance
                        : 0,
                    periods: contracts[idxLastContract].vacationsPeriods
                        ? contracts[idxLastContract].vacationsPeriods
                        : "",
                    contracts: [...contracts],
                    status: "ALL",
                });

                setViewMore(
                    Array(
                        contracts[idxLastContract].vacationsPeriods.length
                    ).fill(true)
                );

                var comingAdmissionDate = DateUtils.parse(
                    contracts[idxLastContract].admissionDate
                );
                if (contracts[idxLastContract].demissionDate) {
                    var comingDemissionDate = DateUtils.parse(
                        contracts[idxLastContract].demissionDate
                    );
                }
                if (contracts[idxLastContract].holidayDate) {
                    var comingHolidayDate = DateUtils.parse(
                        contracts[idxLastContract].holidayDate
                    );
                }
                setDates({
                    admissionDate: contracts[idxLastContract].admissionDate
                        ? comingAdmissionDate
                        : null,
                    holidayDate: contracts[idxLastContract].holidayDate
                        ? comingHolidayDate
                        : null,
                    demissionDate: contracts[idxLastContract].demissionDate
                        ? comingDemissionDate
                        : null,
                });
            }
        }
    }, [collaboratorVacation, collaboratorVacation.length]);

    const redirect = go => {
        if (go) return <Redirect to={`/HolidaysAndBreaks`} />;
    };

    const handleChange = (name, value) => {
        let val = value;
        setValues({ ...values, [name]: val });
    };

    const handleChangeContractContext = (name, value) => {
        setValues({
            ...values,
            id: values.contracts[value].id ? values.contracts[value].id : null,
            contractPeriodIdx: value,
            ruleset: values.contracts[value].ruleset
                ? values.contracts[value].ruleset
                : "",
            remunerationBalance: values.contracts[value].remunerationBalance
                ? values.contracts[value].remunerationBalance
                : 0,
            enjoymentBalance: values.contracts[value].enjoymentBalance
                ? values.contracts[value].enjoymentBalance
                : 0,
            periods: values.contracts[value].vacationsPeriods
                ? values.contracts[value].vacationsPeriods
                : "",
            contracts: values.contracts,
        });

        setViewMore(
            Array(values.contracts[value].vacationsPeriods.length).fill(true)
        );

        var comingAdmissionDate = DateUtils.parse(
            values.contracts[value].admissionDate
        );
        if (values.contracts[value].demissionDate) {
            var comingDemissionDate = DateUtils.parse(
                values.contracts[value].demissionDate
            );
        }
        if (values.contracts[value].holidayDate) {
            var comingHolidayDate = DateUtils.parse(
                values.contracts[value].holidayDate
            );
        }
        setDates({
            admissionDate: values.contracts[value].admissionDate
                ? comingAdmissionDate
                : null,
            holidayDate: values.contracts[value].holidayDate
                ? comingHolidayDate
                : null,
            demissionDate: values.contracts[value].demissionDate
                ? comingDemissionDate
                : null,
        });
    };

    const GeneratePeriodText = vacationPeriod => {
        return (
            DateUtils.formatString(
                vacationPeriod.acquisitivePeriodStart,
                "DD/MM/YYYY"
            ) +
            " - " +
            DateUtils.formatString(
                vacationPeriod.acquisitivePeriodEnd,
                "DD/MM/YYYY"
            )
        );
    };

    return (
        <React.Fragment>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={snackBar.open}
                onClose={handleSnackClose}
                TransitionComponent={Slide}
                transitionDuration={{ enter: 500, exit: 500 }}
                ContentProps={{
                    "aria-describedby": "message-id",
                }}
                autoHideDuration={2000}
            >
                <MySnackbarContentWrapper
                    onClose={handleSnackClose}
                    variant={
                        responseType !== ""
                            ? responseType.toLowerCase()
                            : allowanceResponseType.toLowerCase()
                    }
                    message={
                        <span id="message-id">
                            {response !== "" ? response : allowanceResponse}
                        </span>
                    }
                />
            </Snackbar>

            {redirect(mustRedirect)}

            <div className="headerNewDesign">
                <Breadcrumb
                    newDesign={true}
                    routes={
                        myVacations
                            ? [
                                  {
                                      label: t("HR.humanResource"),
                                      to: "/HumanResources",
                                  },
                                  { label: t("HR.vacations") },
                                  { label: t("HR.myVacations") },
                                  { label: t("HR.viewMyVacations") },
                              ]
                            : vacationCalendar
                            ? [
                                  {
                                      label: t("HR.humanResource"),
                                      to: "/HumanResources",
                                  },
                                  { label: t("HR.vacations") },
                                  {
                                      label: t("HR.vacationCalendar"),
                                      to: "/VacationCalendar",
                                  },
                                  {
                                      label:
                                          context === "edit"
                                              ? t("GENERAL.edit") +
                                                " " +
                                                t("HR.collaboratorsVacation")
                                              : t("GENERAL.view") +
                                                " " +
                                                t("HR.collaboratorsVacation"),
                                  },
                              ]
                            : [
                                  {
                                      label: t("HR.humanResource"),
                                      to: "/HumanResources",
                                  },
                                  { label: t("HR.vacations") },
                                  {
                                      label: t("HR.collaboratorsVacations"),
                                      to: "/CollaboratorsVacations",
                                  },
                                  {
                                      label:
                                          context === "edit"
                                              ? t("GENERAL.edit") +
                                                " " +
                                                t("HR.collaboratorsVacation")
                                              : t("GENERAL.view") +
                                                " " +
                                                t("HR.collaboratorsVacation"),
                                  },
                              ]
                    }
                />
                <div className="container-headerNewDesign">
                    <h1 className={classes.titleNewDesign}>
                        {myVacations
                            ? t("HR.myVacations")
                            : context === "edit"
                            ? t("GENERAL.edit") +
                              " " +
                              t("HR.collaboratorsVacation")
                            : t("GENERAL.view") +
                              " " +
                              t("HR.collaboratorsVacation")}
                    </h1>
                </div>
            </div>

            <div
                style={{
                    opacity: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "spaceBetween",
                    minHeight: "max(calc(75vh - 70px)",
                }}
            >
                <div className="containerNewDesign">
                    <Paper>
                        <div className="cabecalho">
                            {myVacations
                                ? t("HR.myVacations")
                                : context === "edit"
                                ? t("GENERAL.edit").toUpperCase() +
                                  " " +
                                  t("HR.collaboratorsVacation").toUpperCase()
                                : t("GENERAL.view").toUpperCase() +
                                  " " +
                                  t("HR.collaboratorsVacation").toUpperCase()}
                        </div>
                        <Transition loading={loading} newDesign={true}>
                            <Paper>
                                {values.contracts &&
                                values.contracts.length > 0 ? (
                                    <React.Fragment>
                                        <div className={classes.familyDataForm}>
                                            <Grid
                                                container
                                                item
                                                spacing={2}
                                                md={12}
                                                lg={12}
                                            >
                                                <Grid item xs={12} sm={6}>
                                                    <Input
                                                        disabled
                                                        label={
                                                            "HR.collaborator"
                                                        }
                                                        value={values.name}
                                                        valueName={
                                                            "collaborator"
                                                        }
                                                        handleChange={
                                                            handleChange
                                                        }
                                                    />
                                                </Grid>

                                                <Box width="100%" />

                                                <Grid item xs={12} sm={3}>
                                                    <Input
                                                        label={"HR.contract"}
                                                        isSelect
                                                        arraySelected={
                                                            contractPeriod
                                                        }
                                                        value={parseInt(
                                                            values.contractPeriodIdx
                                                        )}
                                                        valueName={
                                                            "contractPeriodIdx"
                                                        }
                                                        handleChange={
                                                            handleChangeContractContext
                                                        }
                                                        inputProps={{
                                                            autocomplete: "off",
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={3}>
                                                    <Input
                                                        disabled
                                                        label={"HR.regime"}
                                                        value={values.ruleset}
                                                        valueName={"ruleset"}
                                                        handleChange={
                                                            handleChange
                                                        }
                                                    />
                                                </Grid>

                                                <Box width="100%" />

                                                {/* <Grid item xs={12} sm={3}>
                                                    <Input
                                                        disabled
                                                        label={
                                                            'HR.remunerationBalancePending'
                                                        }
                                                        value={
                                                            values.remunerationBalance
                                                        }
                                                        valueName={
                                                            'remunerationBalance'
                                                        }
                                                        handleChange={
                                                            handleChange
                                                        }
                                                    />
                                                </Grid> */}

                                                <Grid item xs={12} sm={6}>
                                                    <Input
                                                        disabled
                                                        label={
                                                            "HR.enjoymentBalancePending"
                                                        }
                                                        value={
                                                            values.enjoymentBalance
                                                        }
                                                        valueName={
                                                            "enjoymentBalance"
                                                        }
                                                        handleChange={
                                                            handleChange
                                                        }
                                                    />
                                                </Grid>

                                                <Box width="100%" />

                                                <Grid item xs={12} sm={3}>
                                                    <Input
                                                        label={"GENERAL.status"}
                                                        isSelect
                                                        arraySelected={
                                                            statusArray
                                                        }
                                                        value={values.status}
                                                        valueName={"status"}
                                                        handleChange={
                                                            handleChange
                                                        }
                                                        inputProps={{
                                                            autocomplete: "off",
                                                        }}
                                                    />
                                                </Grid>

                                                {values.periods
                                                    ? values.periods
                                                          .map(
                                                              (period, idx) => (
                                                                  <React.Fragment
                                                                      key={idx}
                                                                  >
                                                                      {getStatusFilterBool(
                                                                          period,
                                                                          values.status
                                                                      ) && (
                                                                          <Grid
                                                                              item
                                                                              xs={
                                                                                  12
                                                                              }
                                                                              sm={
                                                                                  12
                                                                              }
                                                                          >
                                                                              <Paper
                                                                                  style={{
                                                                                      marginTop:
                                                                                          "30px",
                                                                                      marginBottom:
                                                                                          "20px",
                                                                                  }}
                                                                              >
                                                                                  <div
                                                                                      className={
                                                                                          classes.headerCollaboratorsVacations
                                                                                      }
                                                                                  >
                                                                                      PERÍODO
                                                                                      {" " +
                                                                                          (idx +
                                                                                              1) +
                                                                                          " (" +
                                                                                          GeneratePeriodText(
                                                                                              period
                                                                                          )}

                                                                                      )
                                                                                      <div
                                                                                          style={{
                                                                                              display:
                                                                                                  "flex",
                                                                                              justifyContent:
                                                                                                  "center",
                                                                                              alignItems:
                                                                                                  "center",
                                                                                          }}
                                                                                      >
                                                                                          {translateStatusEnjoyed(
                                                                                              period.enjoyedPeriodStatus
                                                                                          ) ===
                                                                                          "ENJOYED" ? (
                                                                                              <CheckCircleIcon
                                                                                                  style={{
                                                                                                      marginRight:
                                                                                                          "0.15rem",
                                                                                                      color: "#7FE46B",
                                                                                                  }}
                                                                                                  fontSize="small"
                                                                                              />
                                                                                          ) : translateStatusEnjoyed(
                                                                                                period.enjoyedPeriodStatus
                                                                                            ) ===
                                                                                            "PENDING" ? (
                                                                                              <ErrorIcon
                                                                                                  style={{
                                                                                                      marginRight:
                                                                                                          "0.15rem",
                                                                                                      color: "#F3222F",
                                                                                                  }}
                                                                                                  fontSize="small"
                                                                                              />
                                                                                          ) : (
                                                                                              <ErrorIcon
                                                                                                  style={{
                                                                                                      marginRight:
                                                                                                          "0.15rem",
                                                                                                      color: "#FFD200",
                                                                                                  }}
                                                                                                  fontSize="small"
                                                                                              />
                                                                                          )}
                                                                                          <span>
                                                                                              {" "}
                                                                                              <b>
                                                                                                  {t(
                                                                                                      "HR.enjoyment"
                                                                                                  )}
                                                                                              </b>

                                                                                              :
                                                                                              {` ${
                                                                                                  period.daysToEnjoy
                                                                                              } ${t(
                                                                                                  "GENERAL.days"
                                                                                              )}
                                                                    (${
                                                                        translateStatusEnjoyed(
                                                                            period.enjoyedPeriodStatus
                                                                        ) ===
                                                                        "ENJOYED"
                                                                            ? t(
                                                                                  "HR.enjoyed"
                                                                              )
                                                                            : translateStatusEnjoyed(
                                                                                  period.enjoyedPeriodStatus
                                                                              ) ===
                                                                              "PENDING"
                                                                            ? t(
                                                                                  "HR.pending"
                                                                              )
                                                                            : t(
                                                                                  "HR.underAcquisition"
                                                                              )
                                                                    })`}
                                                                                          </span>
                                                                                          {context !==
                                                                                          "view" ? (
                                                                                              <IconButton
                                                                                                  disabled={
                                                                                                      period.enjoyedPeriodStatus ===
                                                                                                          2 ||
                                                                                                      period.enjoyedPeriodStatus ===
                                                                                                          1
                                                                                                  }
                                                                                                  onClick={() =>
                                                                                                      handleOpenDialog(
                                                                                                          "enjoyment",
                                                                                                          period,
                                                                                                          idx
                                                                                                      )
                                                                                                  }
                                                                                              >
                                                                                                  <AddCircleIcon />
                                                                                              </IconButton>
                                                                                          ) : (
                                                                                              <div
                                                                                                  style={{
                                                                                                      marginRight:
                                                                                                          "1rem",
                                                                                                  }}
                                                                                              ></div>
                                                                                          )}

                                                                                          {/* {values.ruleset ===
                                                                                              'CLT' && (
                                                                                              <React.Fragment>
                                                                                                  {translateStatusPaid(
                                                                                                      period.paidPeriodStatus
                                                                                                  ) ===
                                                                                                  'PAID' ? (
                                                                                                      <CheckCircleIcon
                                                                                                          style={{
                                                                                                              marginRight:
                                                                                                                  '0.15rem',
                                                                                                              color: '#7FE46B'
                                                                                                          }}
                                                                                                          fontSize="small"
                                                                                                      />
                                                                                                  ) : translateStatusPaid(
                                                                                                        period.paidPeriodStatus
                                                                                                    ) ===
                                                                                                    'UNDER_ACQUISITION' ? (
                                                                                                      <ErrorIcon
                                                                                                          style={{
                                                                                                              marginRight:
                                                                                                                  '0.15rem',
                                                                                                              color: '#FFD200'
                                                                                                          }}
                                                                                                          fontSize="small"
                                                                                                      />
                                                                                                  ) : (
                                                                                                      <ErrorIcon
                                                                                                          style={{
                                                                                                              marginRight:
                                                                                                                  '0.15rem',
                                                                                                              color: '#F3222F'
                                                                                                          }}
                                                                                                          fontSize="small"
                                                                                                      />
                                                                                                  )}
                                                                                                  <span>
                                                                                                      {' '}
                                                                                                      <b>
                                                                                                          {t(
                                                                                                              'HR.remuneration'
                                                                                                          )}
                                                                                                      </b>

                                                                                                      :
                                                                                                      {` ${
                                                                                                          period.daysToBePaid
                                                                                                      } ${t(
                                                                                                          'GENERAL.days'
                                                                                                      )}
                                                                        (${
                                                                            translateStatusPaid(
                                                                                period.paidPeriodStatus
                                                                            ) ===
                                                                            'PAID'
                                                                                ? t(
                                                                                      'HR.paid'
                                                                                  )
                                                                                : translateStatusPaid(
                                                                                      period.paidPeriodStatus
                                                                                  ) ===
                                                                                  'PENDING'
                                                                                ? t(
                                                                                      'HR.pending'
                                                                                  )
                                                                                : translateStatusPaid(
                                                                                      period.paidPeriodStatus
                                                                                  ) ===
                                                                                  'UNDER_ACQUISITION'
                                                                                ? t(
                                                                                      'HR.underAcquisition'
                                                                                  )
                                                                                : t(
                                                                                      'GENERAL.unavailable'
                                                                                  )
                                                                        })`}
                                                                                                  </span>
                                                                                                  {context !==
                                                                                                      'view' && (
                                                                                                      <IconButton
                                                                                                          disabled={
                                                                                                              period.paidPeriodStatus ===
                                                                                                                  3 ||
                                                                                                              period.paidPeriodStatus ===
                                                                                                                  2
                                                                                                          }
                                                                                                          onClick={() =>
                                                                                                              handleOpenDialog(
                                                                                                                  'remuneration',
                                                                                                                  period,
                                                                                                                  idx
                                                                                                              )
                                                                                                          }
                                                                                                      >
                                                                                                          <AddCircleIcon />
                                                                                                      </IconButton>
                                                                                                  )}
                                                                                              </React.Fragment>
                                                                                          )} */}

                                                                                          <IconButton
                                                                                              style={{
                                                                                                  float: "right",
                                                                                              }}
                                                                                              onClick={() =>
                                                                                                  handleViewMore(
                                                                                                      idx
                                                                                                  )
                                                                                              }
                                                                                          >
                                                                                              {viewMore[
                                                                                                  idx
                                                                                              ] ? (
                                                                                                  <ExpandLessIcon />
                                                                                              ) : (
                                                                                                  <ExpandMoreIcon />
                                                                                              )}
                                                                                          </IconButton>
                                                                                      </div>
                                                                                  </div>

                                                                                  {viewMore[
                                                                                      idx
                                                                                  ] && (
                                                                                      <React.Fragment>
                                                                                          <CollaboratorsVacationsPeriodsTable
                                                                                              rows={
                                                                                                  period.givenPeriods
                                                                                              }
                                                                                              toFirstPage={
                                                                                                  canClick
                                                                                              }
                                                                                              headers={[
                                                                                                  "id",
                                                                                                  "class",
                                                                                                  "startDate",
                                                                                                  "endDate",
                                                                                                  "date",
                                                                                                  "registredBy",
                                                                                                  "vacationId",
                                                                                                  "observation",
                                                                                                  "documentFilePath",
                                                                                              ]}
                                                                                              visibleHeaders={[
                                                                                                  "Tipo",
                                                                                                  "Período",
                                                                                                  "Data de Lançamento",
                                                                                                  "Cadastrado Por",
                                                                                                  "",
                                                                                              ]}
                                                                                              sortedFields={[
                                                                                                  "class",
                                                                                                  "startDate",
                                                                                                  "date",
                                                                                                  "registredBy",
                                                                                              ]}
                                                                                              mainField="type"
                                                                                              fetchData={
                                                                                                  fetchData
                                                                                              }
                                                                                              periodValues={{
                                                                                                  acquisitionPeriod:
                                                                                                      values.ruleset ===
                                                                                                      "CLT"
                                                                                                          ? `${
                                                                                                                period.year
                                                                                                            }/${
                                                                                                                period.year +
                                                                                                                1
                                                                                                            }`
                                                                                                          : GeneratePeriodText(
                                                                                                                period
                                                                                                            ),
                                                                                                  daysToBePaid:
                                                                                                      period.daysToBePaid,
                                                                                                  daysToEnjoy:
                                                                                                      period.daysToEnjoy,
                                                                                                  archived:
                                                                                                      period.archived,
                                                                                              }}
                                                                                              vacationsPeriods={
                                                                                                  values.periods
                                                                                              }
                                                                                              context={
                                                                                                  context
                                                                                              }
                                                                                              ruleset={
                                                                                                  values.ruleset
                                                                                              }
                                                                                          />
                                                                                          {period
                                                                                              .givenPeriods
                                                                                              .length ===
                                                                                              0 && (
                                                                                              <div
                                                                                                  className={
                                                                                                      classes.noParticipantsMessage
                                                                                                  }
                                                                                              >
                                                                                                  {/* <h2>{t("HR.noEnjoymentOrRemunerationPeriodsMessage")}</h2> */}
                                                                                                  <h3
                                                                                                      style={{
                                                                                                          paddingTop:
                                                                                                              "30px",
                                                                                                      }}
                                                                                                  >
                                                                                                      {t(
                                                                                                          "HR.noEnjoymentOrRemunerationPeriodsMessage"
                                                                                                      )}
                                                                                                  </h3>
                                                                                              </div>
                                                                                          )}
                                                                                      </React.Fragment>
                                                                                  )}
                                                                              </Paper>
                                                                          </Grid>
                                                                      )}
                                                                  </React.Fragment>
                                                              )
                                                          )
                                                          .reverse()
                                                    : null}
                                                <div
                                                    style={{
                                                        marginBottom: "50px",
                                                    }}
                                                ></div>
                                            </Grid>
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    <Grid
                                        container
                                        item
                                        spacing={2}
                                        md={12}
                                        lg={12}
                                        style={{ padding: "50px" }}
                                    >
                                        <MessagePaper
                                            headerText={t(
                                                "HR.vacationsNotAvailable"
                                            )}
                                            subText={"..."}
                                        />
                                    </Grid>
                                )}
                            </Paper>
                            {openRemuneration && (
                                <DialogCollaboratorVacationPeriod
                                    open={openRemuneration}
                                    setOpen={setOpenRemuneration}
                                    context={"new"}
                                    type={dialogType}
                                    vacationId={vacationIdForDialog}
                                    periodValues={periodValues}
                                    vacationsPeriods={values.periods}
                                    fetchData={fetchData}
                                    userName={auth.name}
                                    ruleset={values.ruleset}
                                />
                            )}
                        </Transition>
                    </Paper>
                </div>
            </div>

            <Footer style={{ display: "flex" }} />
        </React.Fragment>
    );
};

export default CollaboratorsVacationsForm;
