import axios from 'axios';
import { useQuery } from 'react-query';
import Cookies from 'js-cookie';

export const useQueryPersonalTransactions = (options, id) => {
    return useQuery(
        ['PersonalTransactions'],
        async () => {
            const { data } = await axios.get(
                process.env.REACT_APP_SERVER +
                    `api/Quota/Transactions/User/${id}`,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            );
            return data;
        },
        options ? options : {}
    );
};
