import {convertMsToHM} from "utils/dates/ConvertMsToHM";

export const GetWorkedHours = (entryTime, leaveForBreak, returnFromBreak, leaveTime) => {
    let firstInterval = 0;
    let secondInterval = 0;
    let totalWorkedTime = 0;

    if (entryTime && leaveForBreak) {
        firstInterval = Math.abs(leaveForBreak - entryTime);
    }
    if (returnFromBreak && leaveTime) {
        secondInterval = Math.abs(leaveTime - returnFromBreak)
    }

    if (leaveForBreak === null && returnFromBreak === null) {
        totalWorkedTime = Math.abs(leaveTime - entryTime);
    } else {
        totalWorkedTime = firstInterval + secondInterval;
    }

    return convertMsToHM(totalWorkedTime);
}