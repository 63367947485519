/* eslint-disable react-hooks/exhaustive-deps */
import Paper from '@material-ui/core/Paper';
import Dissatisfied from '@material-ui/icons/SentimentVeryDissatisfied';
import FilterContainer from 'components/containers/FilterContainer';
import InputFilter from 'components/inputs/InputFilter';
import { useStyles } from 'components/layout/CommonStyles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { screenStyles } from 'pages/libraryModule/MyLoans/Styles';
import BookCardMyLoans from 'pages/libraryModule/cards/BookCardMyLoans';
import { AllAuthorsName } from 'utils/library/AllAuthorsName';
import InputFilterDate from 'components/inputs/InputFilterDate';
import { dateToBR } from 'utils/dates/DateToBRr';
import { FormatDateToFront } from 'utils/dates/FormatDateToFront';
import { translateStatus } from 'utils/library/translateStatus';
import { LibraryReservationStatusValues } from 'global/constants';
import DialogBooksLoanMoreDetails from 'pages/libraryModule/dialogs/MyLoans/DialogBooksLoanMoreDetails';
import { Link as LinkRouter } from 'react-router-dom';
import { QueryGetValue } from 'utils/general/QueryString';
import { formatImagePath } from 'utils/library/formatImagePath';

const BooksLoanForm = props => {
    const { BooksLoanData } = props;
    const [t] = useTranslation();
    const styles = screenStyles();
    const classes = useStyles();

    const [dialogData, setDialogData] = useState({});
    const [openBooksLoanMoreDetailsDialog, setOpenBooksLoanMoreDetailsDialog] =
        useState(false);

    const [filterTitle, setFilterTitle] = useState('');
    const [filterAuthor, setFilterAuthor] = useState('');
    const [filterDate, setFilterDate] = useState(null);
    const [filterStatusType, setFilterStatusType] = useState('');

    const [findText, setFindText] = useState('');
    const [dataFilter, setDataFilter] = useState([]);
    const hasItens = BooksLoanData.length > 0;
    const [hasNoResult, setHasNoResult] = useState(false);

    const statusTypes = [
        { label: 'Todos', value: 'Todos' },
        {
            label: translateStatus(LibraryReservationStatusValues.LOAN),
            value: LibraryReservationStatusValues.LOAN
        },
        {
            label: translateStatus(LibraryReservationStatusValues.LATE),
            value: LibraryReservationStatusValues.LATE
        }
    ];

    const handleOpenBooksLoanMoreDetailsDialog = index => {
        setDialogData(dataFilter[index]);
        setOpenBooksLoanMoreDetailsDialog(true);
    };

    const applyFilter = filterArray => {
        let newDataFilter = [];
        let textNoFound = '';

        newDataFilter = filterArray;

        if (filterTitle) {
            newDataFilter = newDataFilter.filter(item =>
                item.book.title
                    .toLowerCase()
                    .includes(filterTitle.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterTitle;
        }

        if (filterAuthor) {
            newDataFilter = newDataFilter.filter(item =>
                AllAuthorsName(item.book.authors)
                    .toLowerCase()
                    .includes(filterAuthor.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterAuthor;
        }

        if (filterDate && !isNaN(filterDate)) {
            let filterDateBR = dateToBR(filterDate);
            newDataFilter = newDataFilter.filter(
                item =>
                    item.returnDate &&
                    dateToBR(FormatDateToFront(item.returnDate)) ===
                        filterDateBR
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterDateBR;
        }

        if (
            filterStatusType &&
            filterStatusType.toLowerCase() !==
                t('GENERAL.allMasculine').toLowerCase()
        ) {
            newDataFilter = newDataFilter.filter(
                item => item.status === filterStatusType
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = translateStatus(filterStatusType);
        }

        return { newDataFilter, textNoFound };
    };

    useEffect(() => {
        if (BooksLoanData && BooksLoanData.length) {
            const { newDataFilter, textNoFound } = applyFilter(BooksLoanData);

            if (newDataFilter.length === 0) {
                setDataFilter([]);
                setHasNoResult(true);
                setFindText(textNoFound);
            } else {
                setDataFilter(newDataFilter);
                setHasNoResult(false);
                setFindText('');
            }
        }
    }, [
        BooksLoanData,
        filterTitle,
        filterAuthor,
        filterDate,
        filterStatusType
    ]);

    useEffect(() => {
        if (QueryGetValue('panel') === 'BOOKSLOAN') {
            setFilterTitle(QueryGetValue('title') || '');
            setFilterDate(
                QueryGetValue('devolutionDate')
                    ? new Date(QueryGetValue('devolutionDate'))
                    : null
            );
        }
    }, []);

    return (
        <React.Fragment>
            <FilterContainer>
                <InputFilter
                    filterValue={filterTitle}
                    setFilter={setFilterTitle}
                    label={'INVENTORY.title'}
                    maxLength={70}
                />
                <InputFilter
                    filterValue={filterAuthor}
                    setFilter={setFilterAuthor}
                    label={'INVENTORY.BookAuthor'}
                    maxLength={70}
                />
                <InputFilterDate
                    filterValue={filterDate}
                    setFilter={setFilterDate}
                    label={'LIBRARY.dateDevolution'}
                />
                <InputFilter
                    filterValue={filterStatusType}
                    setFilter={setFilterStatusType}
                    label={'GENERAL.status'}
                    isSelect
                    arraySelected={statusTypes}
                />
            </FilterContainer>

            <Paper style={{ maxHeight: 700, overflow: 'auto' }}>
                <div className={styles.bookList}>
                    {dataFilter.map((data, index) => {
                        return (
                            <BookCardMyLoans
                                context={'booksLoan'}
                                image={formatImagePath(
                                    data.book.imagePath,
                                    data.book.isImageUrl
                                )}
                                title={data.book.title}
                                author={AllAuthorsName(data.book.authors)}
                                exemplarCode={data.exemplar.code}
                                status={data.status}
                                renovationCount={data.renovationCount}
                                returnDate={dateToBR(
                                    FormatDateToFront(data.returnDate)
                                )}
                                onClickMoreDetails={() => {
                                    handleOpenBooksLoanMoreDetailsDialog(index);
                                }}
                            />
                        );
                    })}
                </div>
                {!hasItens && (
                    <div className={classes.noParticipantsMessage}>
                        <h2>{t('LIBRARY.noMyBooksLoanMessage')}</h2>
                        <p style={{ fontWeight: '400' }}>
                            Para solicitar um empréstimo visite a página de{' '}
                            <LinkRouter
                                className={styles.Link}
                                to="/BooksCollection"
                            >
                                Acervo
                            </LinkRouter>{' '}
                            e escolha um livro.
                        </p>
                    </div>
                )}
                {hasNoResult && (
                    <div className={classes.noParticipantsMessage}>
                        <div>
                            <Dissatisfied
                                className={classes.notFoundIconStyle}
                            />
                        </div>
                        <h2>{t('GENERAL.noResultsFound')}</h2>
                        <h5>{`Sua busca por "${findText}" não retornou resultados.`}</h5>
                    </div>
                )}
            </Paper>
            {openBooksLoanMoreDetailsDialog && (
                <DialogBooksLoanMoreDetails
                    open={openBooksLoanMoreDetailsDialog}
                    setOpen={setOpenBooksLoanMoreDetailsDialog}
                    viewValues={dialogData}
                />
            )}
        </React.Fragment>
    );
};

export default BooksLoanForm;
