import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import Buttons from 'components/buttons/Pressed';
import Typography from 'components/typography/Typography';
import { screenStyles } from '../Styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { AllAuthorsName } from 'utils/library/AllAuthorsName';
import { DisponibleExemplarsAmount } from 'utils/library/DisponibleExemplarsAmount';
import {
    LibraryExemplarStatusValues,
    LibraryPlaceValues
} from 'global/constants';
import { formatImagePath } from 'utils/library/formatImagePath';
import Skeleton from '@material-ui/lab/Skeleton';

const DialogBookRequisition = props => {
    const styles = screenStyles();
    const [t] = useTranslation();
    const [libraryPlace, setLibraryPlace] = useState(
        LibraryPlaceValues.GENERAL
    );
    const [existsInGeneralPlace, setExistsInGeneralPlace] = useState(true);
    const [existsInCoordinationPlace, setExistsInCoordinationPlace] =
        useState(true);
    const [exemplarsTotalCount, setExemplarsTotalCount] = useState(0);
    const [exemplarsDisponibleCount, setExemplarsDisponibleCount] = useState(0);
    const [loaded, setLoaded] = useState(false);

    const { open, setOpen, viewValues, submit, context, submitQueue } = props;

    const handleLibraryPlace = value => {
        setLibraryPlace(value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        submit(viewValues.exemplars, libraryPlace);
        setOpen(false);
    };

    const handleSubmitQueue = () => {
        submitQueue(viewValues.id);
        setOpen(false);
    };

    const checkLibrary = () => {
        setExemplarsTotalCount(viewValues.exemplars.length);
        var general = false;
        var coordination = false;

        viewValues.exemplars.forEach(item => {
            if (
                item.status === LibraryExemplarStatusValues.DISPONIBLE &&
                item.library === LibraryPlaceValues.GENERAL
            ) {
                general = true;
                setExemplarsDisponibleCount(exemplarsDisponibleCount + 1);
            }
            if (
                item.status === LibraryExemplarStatusValues.DISPONIBLE &&
                item.library === LibraryPlaceValues.COORDINATION
            ) {
                coordination = true;
                setExemplarsDisponibleCount(exemplarsDisponibleCount + 1);
            }
        });

        if (!general) {
            setExistsInGeneralPlace(false);
        }
        if (!coordination) {
            setExistsInCoordinationPlace(false);
        }
        if (!general && coordination) {
            setLibraryPlace(LibraryPlaceValues.COORDINATION);
        }
    };

    useEffect(() => {
        checkLibrary();
    }, []);

    useEffect(() => {
        setLoaded(false);
    }, [viewValues.imagePath]);

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth={'sm'}
                fullWidth={true}
            >
                <DialogTitle>
                    <div className={styles.dialogTitle}>
                        <Typography
                            variant={'RobotoBold'}
                            fontSize={24}
                            textColor={'secondary'}
                        >
                            {context === 'queue'
                                ? t('LIBRARY.dialogBookQueueTitle')
                                : t('LIBRARY.dialogBookRequisitionTitle')}
                        </Typography>
                        <IconButton
                            color="#6D6F71"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    {context === 'queue' ? (
                        <>
                            <div
                                style={{
                                    display: 'flex',
                                    marginBottom: '24px'
                                }}
                            >
                                <ErrorIcon
                                    style={{
                                        marginRight: '0.5rem',
                                        color: '#2C3E51'
                                    }}
                                    fontSize="small"
                                />
                                <Typography
                                    variant={'RobotoBold'}
                                    fontSize={14}
                                    textColor={'primary'}
                                >
                                    {t('LIBRARY.dialogReservationQueue')}
                                </Typography>
                            </div>
                            <div style={{ marginBottom: '24px' }}>
                                <Typography
                                    variant={'RobotoRegular'}
                                    fontSize={16}
                                    textColor={'secondary'}
                                >
                                    {t('LIBRARY.dialogBookQueue')}
                                </Typography>
                            </div>
                        </>
                    ) : (
                        <Typography
                            variant={'RobotoRegular'}
                            fontSize={16}
                            textColor={'secondary'}
                        >
                            {t('LIBRARY.dialogBookRequisition')}
                        </Typography>
                    )}

                    {context !== 'queue' ? (
                        <div className={styles.dialogDisponible}>
                            {exemplarsDisponibleCount > 0 ? (
                                <CheckCircleIcon
                                    style={{
                                        marginRight: '0.2rem',
                                        color: '#7FE46B'
                                    }}
                                    fontSize="small"
                                />
                            ) : (
                                <ErrorIcon
                                    style={{
                                        marginRight: '0.2rem',
                                        color: '#FFD200'
                                    }}
                                    fontSize="small"
                                />
                            )}
                            <Typography
                                variant={'RobotoBold'}
                                fontSize={14}
                                textColor={'primary'}
                            >
                                {DisponibleExemplarsAmount(
                                    viewValues.exemplars
                                )}{' '}
                                de {exemplarsTotalCount} exemplares disponíveis
                            </Typography>
                        </div>
                    ) : null}

                    <div className={styles.dialogBookRequisitionContainer}>
                        <div className={styles.dialogContent}>
                            {context !== 'queue' ? (
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">
                                        <Typography
                                            variant={'RobotoBold'}
                                            fontSize={12}
                                            textColor={'secondary'}
                                        >
                                            {t(
                                                'INVENTORY.place'
                                            ).toLocaleUpperCase()}
                                        </Typography>
                                    </FormLabel>
                                    <RadioGroup
                                        row
                                        aria-label="position"
                                        name="position"
                                        defaultValue="top"
                                        value={libraryPlace}
                                        onChange={ev =>
                                            handleLibraryPlace(ev.target.value)
                                        }
                                    >
                                        <FormControlLabel
                                            disabled={!existsInGeneralPlace}
                                            value={LibraryPlaceValues.GENERAL}
                                            control={<Radio color="primary" />}
                                            label={t('LIBRARY.generalPlace')}
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            disabled={
                                                !existsInCoordinationPlace
                                            }
                                            value={
                                                LibraryPlaceValues.COORDINATION
                                            }
                                            control={<Radio color="primary" />}
                                            label={t(
                                                'LIBRARY.coordinationPlace'
                                            )}
                                            labelPlacement="end"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            ) : null}

                            <Typography
                                variant={'RobotoBold'}
                                fontSize={14}
                                textColor={'secondary'}
                            >
                                {t('INVENTORY.title').toLocaleUpperCase()}
                            </Typography>
                            <Typography
                                className={styles.dialogTopic}
                                variant={'RobotoRegular'}
                                fontSize={14}
                                textColor={'secondary'}
                            >
                                {viewValues.title}
                            </Typography>
                            <Typography
                                variant={'RobotoBold'}
                                fontSize={14}
                                textColor={'secondary'}
                            >
                                {t('INVENTORY.BookAuthor').toLocaleUpperCase()}
                            </Typography>
                            <Typography
                                className={styles.dialogTopic}
                                variant={'RobotoRegular'}
                                fontSize={14}
                                textColor={'secondary'}
                            >
                                {AllAuthorsName(viewValues.authors)}
                            </Typography>
                            {context === 'queue' ? (
                                <>
                                    <Typography
                                        variant={'RobotoBold'}
                                        fontSize={14}
                                        textColor={'secondary'}
                                    >
                                        {t(
                                            'INVENTORY.category'
                                        ).toLocaleUpperCase()}
                                    </Typography>
                                    <Typography
                                        className={styles.dialogTopic}
                                        variant={'RobotoRegular'}
                                        fontSize={14}
                                        textColor={'secondary'}
                                    >
                                        {viewValues.category.name}
                                    </Typography>
                                </>
                            ) : null}
                        </div>
                        <img
                            className={styles.dialogImage}
                            src={formatImagePath(
                                viewValues.imagePath,
                                viewValues.isImageUrl
                            )}
                            alt={viewValues.title}
                            style={!loaded ? { display: 'none' } : {}}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src =
                                    window.location.origin +
                                    '/img/no-book-cover.png';
                                setLoaded(true);
                            }}
                            onLoad={() => {
                                setLoaded(true);
                            }}
                        />
                        {!loaded && (
                            <Skeleton
                                className={styles.dialogMoreDetailsImage}
                                variant="rect"
                                width={110}
                                height={150}
                            />
                        )}
                    </div>
                </DialogContent>

                <DialogActions className={styles.dialogButtonsWrapper}>
                    <Buttons
                        onClick={handleClose}
                        tipo="BN-noback"
                        conteudo={
                            context === 'queue'
                                ? t('GENERAL.cancel').toUpperCase()
                                : t('GENERAL.close').toUpperCase()
                        }
                    />

                    {context === 'queue' ? (
                        <Buttons
                            className={styles.dialogButton}
                            tipo="BN-blue"
                            conteudo={t(
                                'LIBRARY.dialogBookQueueTitle'
                            ).toUpperCase()}
                            onClick={handleSubmitQueue}
                        />
                    ) : (
                        <Buttons
                            className={styles.dialogButton}
                            tipo="BN-blue"
                            conteudo={t(
                                'LIBRARY.dialogBookRequisitionTitle'
                            ).toUpperCase()}
                            onClick={handleSubmit}
                        />
                    )}
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DialogBookRequisition;
