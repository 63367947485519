import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as GridIcon } from 'assets/icons/Grid.svg';
import TocIcon from '@material-ui/icons/Toc';
import InfoRounded from '@material-ui/icons/InfoRounded';
import Paper from '@material-ui/core/Paper';
import Dissatisfied from '@material-ui/icons/SentimentVeryDissatisfied';
import FilterContainer from 'components/containers/FilterContainer';
import HeaderContainer from 'components/containers/HeaderContainer';
import InputFilter from 'components/inputs/InputFilter';
import Breadcrumb from 'components/layout/breadcrumb/Breadcrumb';
import { tooltipThemeAlert, useStyles } from 'components/layout/CommonStyles';
import Footer from 'components/layout/Footer';
import Transition from 'components/layout/transition/Transition';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BookCardCollection from '../cards/BookCardCollection';
import DialogBookMoreDetails from '../dialogs/BooksCollection/DialogBookMoreDetails';
import DialogBookRequisition from '../dialogs/BooksCollection/DialogBookRequisition';
import DialogWarningMaxRequisitions from '../dialogs/BooksCollection/DialogWarningMaxRequisitions';
import BooksCollectionTable from './BooksCollectionTable';
import { screenStyles } from './Styles';
import CollectionContext from 'context/libraryModule/collection/collectionContext';
import SettingsContext from 'context/libraryModule/settings/settingsContext';
import MyLoansContext from 'context/libraryModule/myLoans/myLoansContext';
import {
    LibraryExemplarStatusValues,
    LibraryReservationStatusValues
} from 'global/constants';
import { AllAuthorsName } from 'utils/library/AllAuthorsName';
import { handleStatusCollection } from 'utils/library/handleStatusCollection';
import Pagination from '@material-ui/lab/Pagination';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { formatImagePath } from 'utils/library/formatImagePath';
import QueueContext from 'context/libraryModule/queue/queueContext';

const BooksCollectionContent = () => {
    const { width } = useWindowDimensions();
    const [t] = useTranslation();
    const styles = screenStyles();
    const classes = useStyles();
    const collectionContext = useContext(CollectionContext);
    const settingsContext = useContext(SettingsContext);
    const myLoansContext = useContext(MyLoansContext);
    const queueContext = useContext(QueueContext);

    const [visualization, setVisualization] = useState('Grid');
    const [canClick, setCanClick] = useState(false);

    const [filterTitle, setFilterTitle] = useState('');
    const [filterAuthor, setFilterAuthor] = useState('');

    const [findText, setFindText] = useState('');
    const [dataFilter, setDataFilter] = useState([]);
    const [filterCategoryType, setFilterCategoryType] = useState('Todos');
    const [filterStatusType, setFilterStatusType] = useState('Todos');
    const [filterOrderByType, setFilterOrderByType] = useState('lastRecent');

    const [openBookRequisitionDialog, setOpenBookRequisitionDialog] =
        useState(false);
    const [openBookQueueDialog, setOpenBookQueueDialog] = useState(false);
    const [openBookMoreDetailsDialog, setOpenBookMoreDetailsDialog] =
        useState(false);
    const [openWarningReservationDialog, setOpenWarningReservationDialog] =
        useState(false);

    const [dialogData, setDialogData] = useState({});

    const [categoryTypes, setCategoryTypes] = useState([]);
    const [statusTypes, setStatusTypes] = useState([]);
    const [orderByTypes, setOrderByTypes] = useState([]);
    const [BooksData, setBooksData] = useState([]);
    const [ConfigData, setConfigData] = useState([]);
    const [totalUserReservations, setTotalUserReservations] = useState([]);
    const [totalUserQueuedReservations, setTotalUserQueuedReservations] =
        useState([]);

    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(1);
    const handleChange = (e, p) => {
        setPage(p);
    };

    const hasItens = BooksData.length > 0;
    const [hasNoResult, setHasNoResult] = useState(
        hasItens && dataFilter.length === 0
    );

    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const setStatusToTypes = books => {
        var auxList = [];
        auxList.push({ label: 'Todos', value: 'Todos' });
        auxList.push({
            label: 'Disponível',
            value: LibraryExemplarStatusValues.DISPONIBLE
        });
        auxList.push({
            label: 'Indisponível',
            value: LibraryExemplarStatusValues.INDISPONIBLE
        });
        if (
            books.filter(
                book =>
                    book.reservations.length &&
                    book.reservations[0].status ===
                        LibraryReservationStatusValues.AWAITING_APPROVAL
            ).length > 0
        ) {
            auxList.push({
                label: 'Aguardando aprovação',
                value: LibraryReservationStatusValues.AWAITING_APPROVAL
            });
        }
        if (
            books.filter(
                book =>
                    book.reservations.length &&
                    book.reservations[0].status ===
                        LibraryReservationStatusValues.AWAITING_WITHDRAWAL
            ).length > 0
        ) {
            auxList.push({
                label: 'Aguardando retirada',
                value: LibraryReservationStatusValues.AWAITING_WITHDRAWAL
            });
        }
        if (books.filter(book => book.queue.length).length > 0) {
            auxList.push({
                label: 'Fila de espera',
                value: 'queued'
            });
        }

        return auxList;
    };

    const setCategoryToTypes = categories => {
        var auxList = [];
        auxList.push({ label: 'Todos', value: 'Todos' });

        categories.forEach(item => {
            const auxObj = { label: item.name, value: item.name };

            if (!item.deleted) {
                auxList.push(auxObj);
            }
        });

        return auxList;
    };

    const handleOpenBookRequisitionDialog = index => {
        const book = dataFilter[index];
        setDialogData(book);
        setOpenBookRequisitionDialog(true);
    };

    const handleOpenWarningRequisitionDialog = () => {
        setOpenWarningReservationDialog(true);
    };

    const handleOpenBookMoreDetailsDialog = index => {
        const book = dataFilter[index];
        setDialogData(book);
        setOpenBookMoreDetailsDialog(true);
    };

    const handleOpenBookQueueDialog = index => {
        const book = dataFilter[index];
        setDialogData(book);
        setOpenBookQueueDialog(true);
    };

    const applyFilter = filterArray => {
        let newDataFilter = [];
        let textNoFound = '';

        newDataFilter = filterArray;

        if (filterTitle) {
            newDataFilter = newDataFilter.filter(item =>
                item.title.toLowerCase().includes(filterTitle.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterTitle;
        }

        if (filterAuthor) {
            newDataFilter = newDataFilter.filter(item =>
                AllAuthorsName(item.authors)
                    .toLowerCase()
                    .includes(filterAuthor.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterAuthor;
        }

        if (
            filterCategoryType &&
            filterCategoryType.toLowerCase() !==
                t('GENERAL.allMasculine').toLowerCase()
        ) {
            newDataFilter = newDataFilter.filter(
                item =>
                    item.category.name &&
                    item.category.name.toLowerCase() ===
                        filterCategoryType.toLowerCase()
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterCategoryType;
        }

        if (
            filterStatusType &&
            filterStatusType.toLowerCase() !==
                t('GENERAL.allMasculine').toLowerCase()
        ) {
            if (filterStatusType === 'queued') {
                newDataFilter = newDataFilter.filter(item => item.queue.length);
            } else {
                newDataFilter = newDataFilter.filter(
                    item =>
                        handleStatusCollection(
                            item.exemplars,
                            item.reservations
                        ) === filterStatusType && !item.queue.length
                );
            }

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterStatusType;
        }

        if (filterOrderByType && filterOrderByType === 'lastRecent') {
            newDataFilter = newDataFilter.sort((objA, objB) =>
                Number(
                    Number(getRecentExemplarDate(objB.exemplars)) -
                        Number(getRecentExemplarDate(objA.exemplars))
                )
            );
        }

        return { newDataFilter, textNoFound };
    };

    const getRecentExemplarDate = exemplars => {
        let lastAcquisitionDate = null;

        exemplars.forEach(exemplar => {
            const date = new Date(exemplar.acquisitionDate);
            if (!lastAcquisitionDate || date > lastAcquisitionDate) {
                lastAcquisitionDate = date;
            }
        });

        return lastAcquisitionDate;
    };

    const {
        loading,
        setLoading,
        setLoadingFalse,
        getBooks,
        getCategories,
        books,
        categories,
        createRequisition,
        createQueueRequisition
    } = collectionContext;

    async function fetchData() {
        await setLoading();
        await getBooks(auth.id);
        await getCategories();
        await setLoadingFalse();
    }

    const { getConfig, config } = settingsContext;

    async function fetchSettingsData() {
        await getConfig();
    }

    const { getMyLoans, myLoans } = myLoansContext;

    async function fetchReservationsData() {
        await getMyLoans(auth.id);
    }

    const handleItemsPerPage = width => {
        switch (width) {
            case 1920:
                return 14;
            case 1600:
                return 12;
            case 1366:
                return 15;
            case 1280:
                return 12;
            default:
                return 10;
        }
    };

    useEffect(() => {
        fetchData();
        fetchSettingsData();
        fetchReservationsData();
    }, []);

    useEffect(() => {
        setItemsPerPage(handleItemsPerPage(width));
    }, [width]);

    useEffect(() => {
        setConfigData(config);
        setTotalUserReservations(
            myLoans.filter(
                item =>
                    item.status !==
                        LibraryReservationStatusValues.CANCELED_COLLABORATOR &&
                    item.status !==
                        LibraryReservationStatusValues.CANCELED_MANAGEMENT &&
                    item.status !== LibraryReservationStatusValues.FINISHED
            )
        );
        if (categories && books && books.length > 0 && categories.length > 0) {
            setBooksData(books);
            setTotalUserQueuedReservations(
                books.filter(book => book.queue.length > 0)
            );
            setCategoryTypes(setCategoryToTypes(categories));
            setStatusTypes(setStatusToTypes(books));
            setOrderByTypes([
                { label: 'Adicionados Recentemente', value: 'lastRecent' }
            ]);
        }
    }, [config, categories, books, myLoans]);

    useEffect(() => {
        if (BooksData && BooksData.length) {
            const { newDataFilter, textNoFound } = applyFilter(BooksData);

            if (newDataFilter.length === 0) {
                setDataFilter([]);
                setHasNoResult(true);
                setFindText(textNoFound);
            } else {
                setDataFilter(newDataFilter);
                setHasNoResult(false);
                setFindText('');
            }

            setPage(1);
        }
    }, [
        BooksData,
        filterTitle,
        filterAuthor,
        filterCategoryType,
        filterStatusType,
        filterOrderByType
    ]);

    useEffect(() => {
        if (
            filterTitle ||
            filterAuthor ||
            filterCategoryType ||
            filterStatusType ||
            filterOrderByType
        ) {
            setCanClick(true);
        } else {
            setCanClick(false);
        }
    }, [
        filterTitle,
        filterAuthor,
        filterCategoryType,
        filterStatusType,
        filterOrderByType
    ]);

    const getSettingValues = (config, name) => {
        let value;
        config.forEach(c => {
            if (c.name === name) value = c.value;
        });
        return value;
    };

    const maxReservationOnSettings = getSettingValues(
        config,
        'maxBooksPerCollaborator'
    );

    const submitRequisition = (exemplars, library) => {
        let formData = new FormData();

        formData.append('userId', auth.id);
        formData.append(
            'exemplarId',
            exemplars.find(
                exemplar =>
                    exemplar.library === library &&
                    exemplar.status === LibraryExemplarStatusValues.DISPONIBLE
            ).id
        );

        const emailData = {
            library: library,
            name: auth.name,
            email: auth.userName
        };
        createRequisition(
            formData,
            fetchData,
            fetchReservationsData,
            emailData
        );
    };

    const submitQueue = bookId => {
        let formData = new FormData();

        formData.append('userId', auth.id);
        formData.append('bookId', bookId);

        const book = books.find(b => b.id === bookId);

        const emailData = {
            collaboratorName: auth.name,
            collaboratorEmail: auth.userName,
            bookTitle: book.title,
            bookAuthors: AllAuthorsName(book.authors),
            bookCategory: book.category.name
        };

        createQueueRequisition(
            formData,
            emailData,
            fetchData,
            fetchReservationsData
        );
    };

    const userReservationsAmount =
        totalUserReservations.length + totalUserQueuedReservations.length;

    return (
        <React.Fragment>
            <Transition loading={loading} newDesign={true}>
                <HeaderContainer>
                    <Breadcrumb
                        newDesign={true}
                        routes={[
                            { label: t('INVENTORY.library'), to: '/Library' },
                            { label: t('LIBRARY.collection') }
                        ]}
                    />
                    <div className="container-headerNewDesign">
                        <h1 className={classes.titleNewDesign}>
                            {t('LIBRARY.collection')}
                        </h1>
                    </div>
                </HeaderContainer>

                <div className="containerNewDesign">
                    <Paper>
                        <div
                            className={`cabecalho ${styles.bookCollectionHeader}`}
                        >
                            {t('LIBRARY.booksCollection').toUpperCase()}
                            <div className={styles.iconsWrapper}>
                                <IconButton
                                    onClick={() => setVisualization('Grid')}
                                >
                                    <GridIcon
                                        style={{
                                            fill:
                                                visualization === 'Grid'
                                                    ? '#2C3E51'
                                                    : '#00000054'
                                        }}
                                    />
                                </IconButton>
                                <IconButton
                                    onClick={() => setVisualization('List')}
                                >
                                    <TocIcon
                                        style={{
                                            transform: 'scaleX(-1)',
                                            color:
                                                visualization === 'List'
                                                    ? '#2C3E51'
                                                    : ''
                                        }}
                                    />
                                </IconButton>
                            </div>
                        </div>
                        <FilterContainer>
                            <InputFilter
                                filterValue={filterTitle}
                                setFilter={setFilterTitle}
                                label={'INVENTORY.title'}
                                maxLength={70}
                            />
                            <InputFilter
                                filterValue={filterAuthor}
                                setFilter={setFilterAuthor}
                                label={'INVENTORY.BookAuthor'}
                                maxLength={70}
                            />
                            <InputFilter
                                filterValue={filterCategoryType}
                                setFilter={setFilterCategoryType}
                                label={'INVENTORY.category'}
                                isSelect
                                arraySelected={categoryTypes}
                            />
                            <InputFilter
                                filterValue={filterStatusType}
                                setFilter={setFilterStatusType}
                                label={'GENERAL.status'}
                                isSelect
                                arraySelected={statusTypes}
                            />
                            <InputFilter
                                filterValue={filterOrderByType}
                                setFilter={setFilterOrderByType}
                                label={'GENERAL.orderby'}
                                isSelect
                                arraySelected={orderByTypes}
                            />
                        </FilterContainer>

                        {userReservationsAmount >=
                        Number(maxReservationOnSettings) ? (
                            <>
                                <div
                                    style={{
                                        backgroundColor: '#2c3e51',
                                        padding: '3px 20px ',
                                        marginTop: '15px'
                                    }}
                                >
                                    <p
                                        style={{
                                            color: '#ffffff',
                                            fontSize: '12px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontFamily: 'Roboto'
                                        }}
                                    >
                                        <InfoRounded
                                            style={{ paddingRight: '10px' }}
                                        />{' '}
                                        Você já está em posse de&nbsp;
                                        <span
                                            style={{
                                                fontWeight: 'bold',
                                                textDecoration: 'underline'
                                            }}
                                        >
                                            {userReservationsAmount} livros
                                        </span>
                                        . Acesse "Meus Empréstimos" e realize
                                        uma devolução para requisitar outros
                                        livros do acervo.
                                    </p>
                                </div>
                            </>
                        ) : (
                            ''
                        )}
                        {visualization === 'Grid' ? (
                            <>
                                {dataFilter.length > 0 && (
                                    <div className={styles.bookList}>
                                        {dataFilter
                                            .slice(
                                                (page - 1) * itemsPerPage,
                                                (page - 1) * itemsPerPage +
                                                    itemsPerPage
                                            )
                                            .map((data, index) => {
                                                function AllowedToPutOnQueue(
                                                    exemplars
                                                ) {
                                                    return (
                                                        exemplars.filter(
                                                            exemplar =>
                                                                exemplar.status ===
                                                                    LibraryExemplarStatusValues.INDISPONIBLE ||
                                                                exemplar.status ===
                                                                    LibraryExemplarStatusValues.DISPONIBLE ||
                                                                exemplar.status ===
                                                                    LibraryExemplarStatusValues.LOANED
                                                        ).length > 0
                                                    );
                                                }
                                                if (
                                                    AllowedToPutOnQueue(
                                                        data.exemplars
                                                    )
                                                ) {
                                                    return (
                                                        <BookCardCollection
                                                            image={formatImagePath(
                                                                data.imagePath,
                                                                data.isImageUrl
                                                            )}
                                                            title={data.title}
                                                            author={AllAuthorsName(
                                                                data.authors
                                                            )}
                                                            category={
                                                                data.category
                                                                    .name
                                                            }
                                                            status={handleStatusCollection(
                                                                data.exemplars,
                                                                data.reservations
                                                            )}
                                                            isAlreadyQueued={
                                                                data.queue
                                                                    .length > 0
                                                            }
                                                            onClick={() => {
                                                                userReservationsAmount <
                                                                Number(
                                                                    maxReservationOnSettings
                                                                )
                                                                    ? handleOpenBookRequisitionDialog(
                                                                          (page -
                                                                              1) *
                                                                              itemsPerPage +
                                                                              index
                                                                      )
                                                                    : handleOpenWarningRequisitionDialog();
                                                            }}
                                                            onClickMoreDetails={() => {
                                                                handleOpenBookMoreDetailsDialog(
                                                                    (page - 1) *
                                                                        itemsPerPage +
                                                                        index
                                                                );
                                                            }}
                                                            onClickQueue={() => {
                                                                handleOpenBookQueueDialog(
                                                                    (page - 1) *
                                                                        itemsPerPage +
                                                                        index
                                                                );
                                                            }}
                                                            isMaxOfBooks={
                                                                userReservationsAmount >=
                                                                Number(
                                                                    maxReservationOnSettings
                                                                )
                                                            }
                                                        />
                                                    );
                                                }
                                            })}
                                    </div>
                                )}
                                {dataFilter.length > 0 && (
                                    <Paper
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            padding: '16px 0'
                                        }}
                                    >
                                        <Pagination
                                            count={Math.ceil(
                                                dataFilter.length / itemsPerPage
                                            )}
                                            page={page}
                                            variant="outlined"
                                            shape="rounded"
                                            onChange={handleChange}
                                        />
                                    </Paper>
                                )}
                            </>
                        ) : (
                            <BooksCollectionTable
                                rows={dataFilter}
                                toFirstPage={canClick}
                                headers={[
                                    'id',
                                    'title',
                                    'authors',
                                    'category',
                                    'exemplars',
                                    'reservations',
                                    'imagePath',
                                    'isImageUrl',
                                    'subtitle',
                                    'edition',
                                    'language',
                                    'publisher',
                                    'isbn',
                                    'synopsis',
                                    'queue'
                                ]}
                                visibleHeaders={[
                                    'Título',
                                    'Autor',
                                    'Categoria',
                                    'Exemplares',
                                    'Status',
                                    '',
                                    ''
                                ]}
                                sortedFields={[
                                    'title',
                                    'authors',
                                    'category',
                                    'exemplars',
                                    'reservations'
                                ]}
                                mainField="date"
                                fetchData={fetchData}
                                submit={submitRequisition}
                                submitQueue={submitQueue}
                                isMaxOfBooks={
                                    userReservationsAmount >=
                                    Number(maxReservationOnSettings)
                                }
                                userReservationsAmount={userReservationsAmount}
                            />
                        )}
                        {!hasItens && (
                            <div className={classes.noParticipantsMessage}>
                                <h2>{t('LIBRARY.noBooksMessage')}</h2>
                                <h4>{t('LIBRARY.noBooksSubMessage')}</h4>
                            </div>
                        )}
                        {hasNoResult && (
                            <div className={classes.noParticipantsMessage}>
                                <div>
                                    <Dissatisfied
                                        className={classes.notFoundIconStyle}
                                    />
                                </div>
                                <h2>{t('GENERAL.noResultsFound')}</h2>
                                <h5>{`Sua busca por "${findText}" não retornou resultados.`}</h5>
                            </div>
                        )}
                    </Paper>
                </div>
                {openBookRequisitionDialog && (
                    <DialogBookRequisition
                        open={openBookRequisitionDialog}
                        setOpen={setOpenBookRequisitionDialog}
                        viewValues={dialogData}
                        submit={submitRequisition}
                    />
                )}
                {openBookQueueDialog && (
                    <DialogBookRequisition
                        open={openBookQueueDialog}
                        setOpen={setOpenBookQueueDialog}
                        viewValues={dialogData}
                        context={'queue'}
                        submitQueue={submitQueue}
                    />
                )}
                {openBookMoreDetailsDialog && (
                    <DialogBookMoreDetails
                        open={openBookMoreDetailsDialog}
                        setOpen={setOpenBookMoreDetailsDialog}
                        viewValues={dialogData}
                        submit={submitRequisition}
                        submitQueue={submitQueue}
                        isMaxOfBooks={
                            userReservationsAmount >=
                            Number(maxReservationOnSettings)
                        }
                        userReservationsAmount={userReservationsAmount}
                    />
                )}
                {openWarningReservationDialog && (
                    <DialogWarningMaxRequisitions
                        open={openWarningReservationDialog}
                        setOpen={setOpenWarningReservationDialog}
                        viewValues={userReservationsAmount}
                    />
                )}
                <Footer />
            </Transition>
        </React.Fragment>
    );
};

export default BooksCollectionContent;
