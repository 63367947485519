/* eslint-disable */
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { StyledTableCell, StyledTableRow, useStyles } from "../../../components/profiles/ProfileStyles";

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    flexGrow: 1,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5)
  }
}));

var id = [];
var idObject = [];
id.pop();
idObject.pop();

function desc(a, b, orderBy) {
  // .normalize("NFD").replace(/[\u0300-\u036f]/g, "") : desconsidera
  // acentos
  if (a[orderBy] === null) a[orderBy] = "";
  if (b[orderBy] === null) b[orderBy] = "";

  if (
    b[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "") <
    a[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "")
  ) {
    return -1;
  }
  if (
    b[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
    a[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "")
  ) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

function createContent(rows, headers, order, orderBy) {
  const rowsCopy = [];
  var obj = {};
  for (var i = 0; i < rows.length; i++) {
    for (var j = 0; j < headers.length; j++) {
      obj[headers[j]] = rows[i][headers[j]];
    }
    rowsCopy.push(obj);
    obj = {};
  }
  return stableSort(rowsCopy, getSorting(order, orderBy));
}

const CustomPaginationActionsTable = props => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState({});
  const [rowsLength, setRowsLength] = useState(0);
  const [headers, setHeaders] = useState([]);
  const [mainField, setMainField] = useState("");
  const [visibleHeaders, setVisibleHeaders] = useState([]);
  const [sortedFields, setSortedFields] = useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("software");
  const [checked, setchecked] = useState(0);
  const [context, setContext] = useState('');


  React.useEffect(() => {
    setHeaders(props.headers);
    setRows(props.rows);
    setRowsLength(props.rows.length);
    setMainField(props.mainField);
    setVisibleHeaders(props.visibleHeaders);
    setSortedFields(props.sortedFields);
    adjustRowsPerpage();
    setContext(props.context)
  }, [props.headers, props.mainField, props.rows, props.visibleHeaders]);

  React.useEffect(() => {
    submit();
  }, [props.type]);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  const adjustRowsPerpage = () => {
    if (props.rows.length >= 10) {
      setRowsPerPage(10);
    } else {
      setRowsPerPage(props.rows.length);
    }
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === sortedFields[property] && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(sortedFields[property]);
  };

  const UsersTableHead = props => {
    const { order, orderBy, onRequestSort } = props;
    const [t] = useTranslation();
    const paginationTop = action => e => {
      var selector = "[aria-label^='" + action + "']";
      document.querySelector(selector).click();
      e.preventDefault();
    };
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
        {context !== "view" &&
          <StyledTableCell>
            <Checkbox
              className="projectParticipant"
              //id={"divParticipant" + index}
              color="primary"
            //key={row.id}
            //value={row.id}
            ///onClick={change}
            // checked={verify}
            //onChange={handleSwitch}
            />
          </StyledTableCell>}
          {visibleHeaders.map((header, index) => (
            <StyledTableCell
              key={index}
              size="small"
              align="left"
              sortDirection={orderBy === sortedFields[index] ? order : false}
            >
              <TableSortLabel
                active={orderBy === sortedFields[index]}
                direction={order}
                onClick={createSortHandler(index)}
              >
                {header.toUpperCase()}
                {orderBy === sortedFields[index] ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  var idCurrentOfPage = [];
  var listIds = [];

  UsersTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired
  };

  React.useEffect(() => {
    if(context === "edit"){
      props.equipmentsEdit.forEach(element => {
        !id.includes(String(element.id)) && id.push(String(element.id));
      });
      
      props.equipmentsEdit.forEach(element => {
        // if(!idObject.some(a => a.id === element.id)){
        //   idObject.push(element)
        // }
        !idObject.includes(props.equipmentsEdit) && idObject.push(element);
      });
    }
  }, [props.equipmentsEdit]);

  var verify = false;
  function verifyIsChecked(idVerify) {
    idCurrentOfPage.push(idVerify);
    for (var i in idCurrentOfPage) {
      for (var j in id) {
        if (idCurrentOfPage[i] == id[j]) {
          listIds.push(idCurrentOfPage[i]);
        }
      }
    }
    verify = listIds.includes(idVerify);
  }

  let userschecked = new Object();

  function handleSwitch(e) {
    var contains = id.includes(e.target.value);
    if (contains) {
      id = id.filter(el => el !== e.target.value);
      idObject = idObject.filter(el => el.id != e.target.value);
      props.setSoftwaresOrEquipmentSelected(idObject)
    } else {
      id.push(e.target.value);
      userschecked = rows.filter(el => el.id == e.target.value);
      !idObject.includes(userschecked[0]) && idObject.push(userschecked[0]);
      props.setSoftwaresOrEquipmentSelected(userschecked)
    }
  }

  React.useEffect(() => {
    props.setSoftwaresOrEquipmentSelected(idObject)
  }, [idObject.length, id.length, listIds.length, idCurrentOfPage.length, JSON.stringify(idObject)]);


  function handleSwitchAll(e) {
  }

  const submit = () => {
    if (id.length !== 0) {
      //Limpar os arrays
      while (listIds.length) {
        listIds.pop();
      }
      while (idObject.length) {
        idObject.pop();
      }
      while (id.length) {
        id.pop();
      }
      while (idCurrentOfPage.length) {
        idCurrentOfPage.pop()
      }
      props.setSoftwaresOrEquipmentSelected([])
    }
  };

  function change() {
    setchecked(Math.random() * 1000);
  }

  return (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table className={classes.table} size="small">
          {rows.length > 0 &&
            <UsersTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
          }
          <TableBody>
            {createContent(rows, headers, order, orderBy)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <StyledTableRow key={row.id + checked}>
                  {props.context !== "view" &&
                  <TableCell
                    size="small"
                    align="left"
                    onChange={verifyIsChecked(row.id)}
                  >
                    <Checkbox
                      className="projectParticipant"
                      id={"divParticipant" + index}
                      color="primary"
                      key={row.id}
                      value={row.id}
                      onClick={change}
                      checked={verify}
                      onChange={handleSwitch}
                    />
                  </TableCell>}
                  <TableCell size="small" >
                    {row["software"]}
                  </TableCell>
                  <TableCell size="small" >
                    {row["category"]}
                  </TableCell>
                  <TableCell size="small" >
                    {row["key"] ? row["key"] : row["email"] ? row["email"] : "-"}
                  </TableCell>
                  <TableCell size="small" >
                    {row["machine"]}
                  </TableCell>
                </StyledTableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 48 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        {rows.length > 0 &&
          <TableFooter style={{ float: "right" }} >
            <TablePagination
              labelRowsPerPage={"Resultados por página"}
              count={rowsLength}
              rowsPerPage={rowsPerPage}
              page={page}
              component="div"
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              //ActionsComponent={TablePaginationActions}
            />
          </TableFooter>
        }
      </div>
    </Paper>
  );
};

CustomPaginationActionsTable.propTypes = {
  rows: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
  visibleHeaders: PropTypes.array.isRequired,
  mainField: PropTypes.string.isRequired
};

export default CustomPaginationActionsTable;