/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Breadcrumb from 'components/layout/breadcrumb/Breadcrumb';
import Transition from 'components/layout/transition/Transition';
import HeaderContainer from 'components/containers/HeaderContainer';
import ContentContainer from 'components/containers/ContentContainer';
import BlueHeaderContainer from 'components/containers/BlueHeaderContainer';
import Typography from 'components/typography/Typography';
import InfoTooltip from 'components/infoTooltip/InfoTooltip';
import OutlinedWithIconButton from 'components/buttons/OutlinedWithIconButton';
import { ReactComponent as PlusGreenIcon } from 'assets/icons/plusGreen.svg';
import ActiveInactiveButton from 'components/buttons/ActiveInactiveButton';
import Footer from 'components/layout/Footer';
import CollaboratorCard from 'components/Card/Collaborator';
import QuotasLSBD from 'components/Header/QuotasLSBD';
import DialogApplyForCredit from './Dialogs/DialogApplyForCredit';
import { useStyles } from 'components/layout/CommonStyles';
import DashboardTabPanel from './DashboardTabPanel';
import { valueToMoneyFloat } from 'utils/general/valueToMoneyFloat';
import { moneyMask } from 'utils/masks/moneyMask';
import { useQueryPersonalDashboard } from 'hooks/DataFetching/Quotas/Dashboard/useQueryPersonalDashboard';
import { useQueryLSBDDashboard } from 'hooks/DataFetching/Quotas/Dashboard/useQueryLSBDDashboard';
import { screenStyles } from './Styles';
import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';
import { Allow, Screen } from 'global/constants';
import DialogAddCredit from './Dialogs/DialogAddCredit';
import DialogAddDebit from './Dialogs/DialogAddDebit';

const Dashboard = () => {
    const classes = useStyles();
    const styles = screenStyles();
    const [t] = useTranslation();

    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const [isPersonalActive, setPersonalActive] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [openCreditDialog, setOpenCreditDialog] = useState(false);
    const [openDebitDialog, setOpenDebitDialog] = useState(false);
    const [isTabClick, setIsTabClick] = useState(false);

    const queryPersonalDashboard = useQueryPersonalDashboard({}, auth.id);
    const queryLSBDDashboard = useQueryLSBDDashboard({});

    const onContextButtonClick = context => {
        setIsTabClick(true);
        if (context === 'Personal') {
            setPersonalActive(true);
            queryPersonalDashboard.refetch();
        } else {
            setPersonalActive(false);
            queryLSBDDashboard.refetch();
        }
    };

    const haveDebitCreditPermission = GetGeneralPermissionForAction(
        Screen.QUOTAS_DASHBOARD_LSBD,
        Allow.CREATE
    );

    return (
        <React.Fragment>
            <Transition
                loading={
                    queryLSBDDashboard.isLoading &&
                    queryLSBDDashboard.isFetching &&
                    queryPersonalDashboard.isLoading &&
                    queryPersonalDashboard.isFetching
                }
                newDesign={true}
            >
                <HeaderContainer>
                    <Breadcrumb
                        newDesign={true}
                        routes={[
                            {
                                label: t('QUOTAS.quotas'),
                                to: '/Quotas'
                            },
                            { label: 'Início' }
                        ]}
                    />
                    <div className="container-headerNewDesign">
                        <h1 className={classes.titleNewDesign}>{'Início'}</h1>
                    </div>
                </HeaderContainer>
                <ContentContainer>
                    <BlueHeaderContainer>
                        {isPersonalActive ? (
                            <>
                                <CollaboratorCard
                                    values={{
                                        name: queryPersonalDashboard.data
                                            ? queryPersonalDashboard.data.name
                                            : '-',
                                        job: queryPersonalDashboard.data
                                            ? queryPersonalDashboard.data.job
                                            : '-',
                                        ruleset: queryPersonalDashboard.data
                                            ? queryPersonalDashboard.data
                                                  .ruleset
                                            : '-',
                                        imageName: queryPersonalDashboard.data
                                            ? queryPersonalDashboard.data
                                                  .imageName
                                            : ''
                                    }}
                                />
                                <div className={styles.myCredit}>
                                    <div
                                        className={
                                            styles.myCreditTooltipWrapper
                                        }
                                    >
                                        <Typography
                                            variant={'RobotoRegular'}
                                            fontSize={16}
                                            textColor={'white'}
                                        >
                                            {t('QUOTAS.yourCredits')}
                                        </Typography>
                                        <InfoTooltip
                                            color={'#fff'}
                                            text={
                                                <Typography
                                                    variant={'RobotoRegular'}
                                                    fontSize={12}
                                                    textColor={'white'}
                                                >
                                                    {t(
                                                        'QUOTAS.yourCreditsInfo'
                                                    )}
                                                </Typography>
                                            }
                                        />
                                    </div>
                                    <Typography
                                        variant={'RobotoBold'}
                                        fontSize={40}
                                        textColor={'white'}
                                    >
                                        {`R$
                                ${
                                    queryPersonalDashboard.data
                                        ? moneyMask(
                                              valueToMoneyFloat(
                                                  queryPersonalDashboard.data
                                                      ? queryPersonalDashboard
                                                            .data.accountBalance
                                                      : 0.0
                                              )
                                          )
                                        : '0,00'
                                }
                                `}
                                    </Typography>
                                    {GetGeneralPermissionForAction(
                                        Screen.QUOTAS_DASHBOARD_PERSONAL_TAB_SOLICITATIONS,
                                        Allow.CREATE
                                    ) ? (
                                        <OutlinedWithIconButton
                                            text={t('QUOTAS.applyForCredit')}
                                            Icon={PlusGreenIcon}
                                            onClick={() => setOpenDialog(true)}
                                        />
                                    ) : null}
                                </div>
                            </>
                        ) : (
                            <QuotasLSBD
                                accountBalance={
                                    queryLSBDDashboard.data
                                        ? queryLSBDDashboard.data.accountBalance
                                        : 0.0
                                }
                                paidBills={
                                    queryLSBDDashboard.data
                                        ? queryLSBDDashboard.data.paidBills
                                        : 0
                                }
                                pendingBills={
                                    queryLSBDDashboard.data
                                        ? queryLSBDDashboard.data.pendingBills
                                        : 0
                                }
                                receivedCredit={
                                    queryLSBDDashboard.data
                                        ? queryLSBDDashboard.data.receivedCredit
                                        : 0.0
                                }
                                missingCredit={
                                    queryLSBDDashboard.data
                                        ? queryLSBDDashboard.data.missingCredit
                                        : 0.0
                                }
                                setOpenCreditDialog={setOpenCreditDialog}
                                setOpenDebitDialog={setOpenDebitDialog}
                                isManager={haveDebitCreditPermission}
                            />
                        )}
                    </BlueHeaderContainer>
                    <div className={styles.dashboardbuttonsWrapper}>
                        <ActiveInactiveButton
                            isActive={isPersonalActive}
                            text={'Pessoal'}
                            onClick={() => onContextButtonClick('Personal')}
                        />
                        <ActiveInactiveButton
                            isActive={!isPersonalActive}
                            text={'LSBD'}
                            onClick={() => onContextButtonClick('LSBD')}
                        />
                    </div>
                    <DashboardTabPanel
                        isPersonalActive={isPersonalActive}
                        setPersonalActive={setPersonalActive}
                        isTabClick={isTabClick}
                        setIsTabClick={setIsTabClick}
                    />
                    {openDialog && (
                        <DialogApplyForCredit
                            open={openDialog}
                            setOpen={setOpenDialog}
                        />
                    )}
                    {openCreditDialog && (
                        <DialogAddCredit
                            open={openCreditDialog}
                            setOpen={setOpenCreditDialog}
                        />
                    )}
                    {openDebitDialog && (
                        <DialogAddDebit
                            open={openDebitDialog}
                            setOpen={setOpenDebitDialog}
                        />
                    )}
                </ContentContainer>
                <Footer />
            </Transition>
        </React.Fragment>
    );
};

export default Dashboard;
