import React from "react";
import "../../../../App.css";
import SideBar from "components/layout/sidebar/Sidebar";
import DashboardNavBar from "components/layout/DashboardNavBar";
import CollaboratorsVacationsForm from "../collaboratorsVacations/CollaboratorsVacationsForm";



const CallViewMyVacations = ({ match }) => {
    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }

    return (
        <div>
            <DashboardNavBar />
            <SideBar />
            <CollaboratorsVacationsForm userId={auth.id} context={"view"} myVacations={true} />

        </div>
    );
}

export default CallViewMyVacations;