import React from "react";
import "../../../App.css";
import SideBar from "../../../components/layout/sidebar/Sidebar";
import DashboardNavBar from "../../../components/layout/DashboardNavBar";
import FormRequests from "./FormRequests";

const CallNewRequests = ({ match }) => {
  return (
    <div>
      <DashboardNavBar />
      <SideBar />
      <FormRequests id={match.params.id} context={"new"} />
    </div>
  );
}

export default CallNewRequests;