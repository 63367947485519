import axios from 'axios';
import { useMutation } from 'react-query';
import Cookies from 'js-cookie';
import { useQueryPersonalBills } from '../Dashboard/useQueryPersonalBills';
import { useSnackbar } from 'hooks/UseSnackbar';
import { useTranslation } from 'react-i18next';
import { sendEmails } from 'services/Emails/Quota/quotaEmailService';

export const useMutationUserCancelBill = (setOpen, id) => {
    const toast = useSnackbar();
    const [t] = useTranslation();

    const queryPersonalBills = useQueryPersonalBills({ enabled: false }, id);

    const mutation = useMutation({
        mutationFn: async obj =>
            await axios.put(
                process.env.REACT_APP_SERVER +
                    `api/Quota/Bills/${obj.get('id')}/Cancel`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            ),
        onSuccess: res => {
            toast.success(t('QUOTAS.billCancelSuccess'));
            sendEmails(res.data, 'api/Quota/Bills/Cancel');
        },
        onError: () => {
            toast.error(t('QUOTAS.billCancelError'));
        },
        onSettled: () => {
            setOpen(false);
            queryPersonalBills.refetch();
        }
    });

    return mutation;
};
