import React from "react";
import "../../../../App.css";
import SideBar from "components/layout/sidebar/Sidebar";
import DashboardNavBar from "components/layout/DashboardNavBar";
import CollaboratorsVacationsForm from "../collaboratorsVacations/CollaboratorsVacationsForm";

const CallEditVacationCalendar= ({ match }) => {

    return (
        <div>
            <DashboardNavBar />
            <SideBar />
            <CollaboratorsVacationsForm userId={match.params.id} context={"edit"}  vacationCalendar={true}/>
        </div>
    );
}

export default CallEditVacationCalendar;