import { Image, Text, View } from "@react-pdf/renderer";
import React from "react";
import styles from "./Footer.styled";
import { useTranslation } from "react-i18next";

export default function Footer({ style, ...props }) {
    const [t] = useTranslation();
    return (
        <View style={[styles.footer, style]} {...props}>
            <Image src={window.location.origin + '/img/lsbd-logo-greyscale.png'} alt="LSBD" style={styles.footerLogo} />
            <View style={styles.footerText}>
                <Text style={{ lineHeight: 1.5 }}>{t("GENERAL.LSBDFullName")}</Text>
                <Text>{t("GENERAL.LSBDDepartment")}</Text>
                <Text>{t("GENERAL.LSBDAddress")}</Text>
            </View>
        </View>
    )
}
