/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";

import Paper from "@material-ui/core/Paper";
import Box from '@material-ui/core/Box';
import Breadcrumb from "components/layout/breadcrumb/Breadcrumb";
import SettingsContext from "context/hrModule/settings/settingsContext";
import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";
import Grid from "@material-ui/core/Grid";
import Input from "components/inputs/Input";
import Typography from "@material-ui/core/Typography";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { dateValidation } from "utils/validation/DateValidation";
import { FormatDateToFront } from "utils/dates/FormatDateToFront";
import { FormatDateToDataBase } from "utils/dates/FormatDateToDataBase";
import { HourMinMask } from "utils/masks/HourMinMask";
import { HourMinValidation } from "utils/validation/HourMinValidation";
import { ConvertHourMinInMilli } from "utils/dates/ConvertHourMinInMilli1";
import { ConvertMilliInHourMin } from "utils/dates/ConvertMilliInHourMin1";
import { randomString } from "utils/general/RandomString";
import { dateToBR } from "utils/dates/DateToBRr";
import { GetNumberOfDays } from "utils/dates/GetNumberOfDays";


import { Redirect } from "react-router-dom";


import Transition from "components/layout/transition/Transition";


import Buttons from "components/buttons/Pressed";


const HolidaysAndBreaksForm = (props) => {

    const classes = useStyles();
    const [t] = useTranslation();
    const settingsContext = useContext(SettingsContext);
    const { id, holidayOrBreakId, context, ...others } = props;
    const [mustRedirect, setMustRedirect] = useState(false);


    const type = [
        {
            label: t("HR.holiday"),
            value: "HOLIDAY"
        },
        {
            label: t("HR.break"),
            value: "BREAK"
        }
    ];

    const holidayType = [
        {
            label: t("HR.totalHoliday"),
            value: "TOTAL"
        },
        {
            label: t("HR.partialHoliday"),
            value: "PARTIAL"
        }
    ];

    const partialHolidayType = [
        {
            label: t("HR.partialHolidayFourHours"),
            value: "FOUR"
        },
        {
            label: t("HR.partialHolidaySixHours"),
            value: "SIX"
        }
    ];

    const getYears = () => {
        let startYear = 2000;
        let currentYear = new Date().getFullYear();
        const years = [];
        while (startYear <= currentYear) {
            years.push({
                label: currentYear,
                value: currentYear
            });
            currentYear--;
        }
        return years;
    }


    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }

    const {
        loading,
        setLoading,
        setLoadingFalse,
        holidaysAndBreaks,
        holidayOrBreak,
        getHolidaysAndBreaks,
        addHolidaysAndBreaks,
        getHolidayOrBreak,
        updateHolidaysAndBreaks
    } = settingsContext

    const [values, setValues] = useState({
        id: "",
        name: "",
        type: "HOLIDAY",
        classification: "TOTAL",
        partialHolidayType: "",
        entryTime: "",
        leaveTime: "",
        baseYear: "",
        numberOfDays: "-",
        observation: "",
    });

    const [dates, setDates] = useState({
        holidayDate: null,
        breakStartDate: null,
        breakEndDate: null,
    })

    const baseYearsArray = getYears();

    const [canClick, setCanClick] = useState(false);
    const [requiredName, setRequiredName] = useState(false);
    const [requiredType, setRequiredType] = useState(false);
    const [requiredHolidayDate, setRequiredHolidayDate] = useState(false);
    const [requiredStartDate, setRequiredStartDate] = useState(false);
    const [requiredEndDate, setRequiredEndDate] = useState(false);
    const [requiredObservation, setRequiredObservation] = useState(false);
    const [requiredClassification, setRequiredClassification] = useState(false);
    const [requiredHolidayType, setRequiredHolidayType] = useState(false);
    const [requiredEntryTime, setRequiredEntryTime] = useState(false);
    const [requiredLeaveTime, setRequiredLeaveTime] = useState(false);
    const [requiredBaseYear, setRequiredBaseYear] = useState(false);
    const [holidayDateErrorMessage, setHolidayDateErrorMessage] = useState("");
    const [startDateErrorMessage, setStartDateErrorMessage] = useState("");
    const [endDateErrorMessage, setEndDateErrorMessage] = useState("");
    const [entryTimeErrorMessage, setEntryTimeErrorMessage] = useState("");
    const [leaveTimeErrorMessage, setLeaveTimeErrorMessage] = useState("");


    async function fetchData(holidayOrBreakId) {
        await setLoading();
        getHolidaysAndBreaks();
        context !== "new" && await getHolidayOrBreak(holidayOrBreakId);
        await setLoadingFalse();
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData(holidayOrBreakId);
    }, []);


    context !== "new" && useEffect(() => {
        if (holidayOrBreak) {
            const tenMinutesTolerance = 600000;
            setValues({
                id: holidayOrBreak.id ? holidayOrBreak.id : null,
                name: holidayOrBreak.name ? holidayOrBreak.name : "",
                type: holidayOrBreak.type ? holidayOrBreak.type : "",
                classification: holidayOrBreak.classification ? holidayOrBreak.classification : "",
                partialHolidayType: holidayOrBreak.partialHolidayType ? holidayOrBreak.partialHolidayType : "",
                entryTime: holidayOrBreak.scheduleContract ? ConvertMilliInHourMin(holidayOrBreak.scheduleContract.minEntryTimeInMilliseconds + tenMinutesTolerance) : "",
                leaveTime: holidayOrBreak.scheduleContract ? ConvertMilliInHourMin(holidayOrBreak.scheduleContract.minOutputTimeInMilliseconds + tenMinutesTolerance) : "",
                baseYear: holidayOrBreak.baseYear ? holidayOrBreak.baseYear : "",
                numberOfDays: holidayOrBreak.startDate ? GetNumberOfDays(FormatDateToFront(holidayOrBreak.startDate), FormatDateToFront(holidayOrBreak.endDate)) : "-",
                observation: holidayOrBreak.observation ? holidayOrBreak.observation : "",
            })
            setDates({
                holidayDate: holidayOrBreak.date ? FormatDateToFront(holidayOrBreak.date) : null,
                breakStartDate: holidayOrBreak.startDate ? FormatDateToFront(holidayOrBreak.startDate) : null,
                breakEndDate: holidayOrBreak.endDate ? FormatDateToFront(holidayOrBreak.endDate) : null,
            })
        }
    }, [holidayOrBreak, holidayOrBreak.length])
    const redirect = go => {
        if (go) return <Redirect to={`/HolidaysAndBreaks`} />;
    };

    const handleChange = (name, value) => {
        let val = value;
        if (name === "entryTime" || name === "leaveTime") {
            val = HourMinMask(value);
        }
        setValues({ ...values, [name]: val })
    }

    useEffect(() => {
        setValues({
            ...values, numberOfDays: dateValidation(dates.breakStartDate) && dateValidation(dates.breakEndDate) ?
                GetNumberOfDays(dates.breakStartDate, dates.breakEndDate)
                : "-"
        })
    }, [dates.breakStartDate, dates.breakEndDate])

    const handleDates = name => e => {
        setDates({
            ...dates,
            [name]: e
        });
        if (dateValidation(e)) {
            setDates({
                ...dates,
                [name]: new Date(new Date(e).setHours(0, 0, 0, 0))
            });
        }

        if (name === "holidayDate") {
            setRequiredHolidayDate(false);
        } else if (name === "breakStartDate") {
            setRequiredStartDate(false);
        } else {
            setRequiredEndDate(false);
        }
    };

    const handleCancel = () => {
        setMustRedirect(true);
    }

    const verifySubmit = () => {
        let res = true;

        if (!values.name.trim()) {
            res = false;
            setRequiredName(true);
        }

        if (!values.observation.trim()) {
            res = false;
            setRequiredObservation(true);
        }

        if (values.type === "HOLIDAY") {
            if (dates.holidayDate && holidaysAndBreaks.map(x => x.date && x.id !== values.id ? dateToBR(FormatDateToFront(x.date)) : null).includes(dateToBR(dates.holidayDate))) {
                res = false;
                setRequiredHolidayDate(true)
                setHolidayDateErrorMessage(t("HR.holidayDateAlreadyRegistered"))
            }
            if (!dateValidation(dates.holidayDate)) {
                res = false;
                setRequiredHolidayDate(true);
                setHolidayDateErrorMessage(t("GENERAL.invalidDate"));
            }
            if (dates.holidayDate === null) {
                res = false;
                setRequiredHolidayDate(true);
                setHolidayDateErrorMessage(t("GENERAL.requiredField"));
            }
            if (!values.classification) {
                res = false;
                setRequiredClassification(true);
            }
            if (values.classification === "PARTIAL") {
                if (!values.partialHolidayType) {
                    res = false;
                    setRequiredHolidayType(true);
                }

                if (!HourMinValidation(values.entryTime)) {
                    res = false;
                    setRequiredEntryTime(true);
                    setEntryTimeErrorMessage(t("GENERAL.invalidTime"));
                }
                if (!HourMinValidation(values.leaveTime)) {
                    res = false;
                    setRequiredLeaveTime(true);
                    setLeaveTimeErrorMessage(t("GENERAL.invalidTime"));
                }
                if (!values.entryTime) {
                    res = false;
                    setRequiredEntryTime(true);
                    setEntryTimeErrorMessage(t("GENERAL.requiredField"));
                }
                if (!values.leaveTime) {
                    res = false;
                    setRequiredLeaveTime(true);
                    setLeaveTimeErrorMessage(t("GENERAL.requiredField"));
                }
                if (ConvertHourMinInMilli(values.entryTime) > ConvertHourMinInMilli(values.leaveTime)) {
                    res = false;
                    setRequiredEntryTime(true);
                    setEntryTimeErrorMessage(t("GENERAL.invalidTime"));
                }
                if (values.partialHolidayType === "FOUR" && ConvertHourMinInMilli(values.leaveTime) - ConvertHourMinInMilli(values.entryTime) !== 14400000 || values.partialHolidayType === "SIX" && ConvertHourMinInMilli(values.leaveTime) - ConvertHourMinInMilli(values.entryTime) !== 21600000) {
                    res = false;
                    setRequiredLeaveTime(true);
                    setLeaveTimeErrorMessage(t("GENERAL.invalidTime"));
                }
            }
        } else {
            if (!values.baseYear) {
                res = false;
                setRequiredBaseYear(true);
            }
            if (dates.breakEndDate && dates.breakStartDate && holidaysAndBreaks.filter(x => x.endDate ? FormatDateToFront(x.startDate).getTime() >= dates.breakStartDate.getTime() && FormatDateToFront(x.endDate).getTime() <= dates.breakEndDate.getTime() && x.id !== values.id : false).length > 0) {
                res = false;
                setRequiredEndDate(true);
                setEndDateErrorMessage(t("HR.breakPeriodAlreadyRegistered"));
                setRequiredStartDate(true);
                setStartDateErrorMessage(t("HR.breakPeriodAlreadyRegistered"));
            }
            if (!dateValidation(dates.breakStartDate)) {
                res = false;
                setRequiredStartDate(true);
                setStartDateErrorMessage(t("GENERAL.invalidDate"));
            }
            if (dates.breakStartDate === null) {
                res = false;
                setRequiredStartDate(true);
                setStartDateErrorMessage(t("GENERAL.requiredField"));
            }
            if (dates.breakStartDate && holidaysAndBreaks.filter(x => x.startDate ? FormatDateToFront(x.startDate).getTime() <= dates.breakStartDate.getTime() && dates.breakStartDate.getTime() <= FormatDateToFront(x.endDate).getTime() && x.id !== values.id : false).length > 0) {
                res = false;
                setRequiredStartDate(true);
                setStartDateErrorMessage(t("HR.breakPeriodAlreadyRegistered"));
            }
            if (!dateValidation(dates.breakEndDate)) {
                res = false;
                setRequiredEndDate(true);
                setEndDateErrorMessage(t("GENERAL.invalidDate"));
            }
            if (dates.breakEndDate === null) {
                res = false;
                setRequiredEndDate(true);
                setEndDateErrorMessage(t("GENERAL.requiredField"));
            }
            if (dates.breakEndDate && holidaysAndBreaks.filter(x => x.endDate ? FormatDateToFront(x.startDate).getTime() <= dates.breakEndDate.getTime() && dates.breakEndDate.getTime() <= FormatDateToFront(x.endDate).getTime() && x.id !== values.id : false).length > 0) {
                res = false;
                setRequiredEndDate(true);
                setEndDateErrorMessage(t("HR.breakPeriodAlreadyRegistered"));
            }
            if (dates.breakEndDate && dates.breakStartDate && dates.breakStartDate.getTime() > dates.breakEndDate.getTime()) {
                res = false;
                setRequiredStartDate(true);
                setStartDateErrorMessage(t("HR.breakPeriodInvalid"));
            }
        }
        return res;
    }

    const handleSubmit = () => {
        const canSubmit = verifySubmit();
        if (canSubmit) {
            let formData = new FormData();
            formData.append("holidayAndBreak.id", values.id ? values.id : 0);
            formData.append("holidayAndBreak.name", values.name ? values.name : "");
            formData.append("holidayAndBreak.version", 0);
            formData.append("holidayAndBreak.type", values.type ? values.type : "");
            formData.append("holidayAndBreak.observation", values.observation ? values.observation : "");
            if (values.type === "HOLIDAY") {
                formData.append("holidayAndBreak.classification", values.classification ? values.classification : "");
                formData.append("holidayAndBreak.date", dates.holidayDate ? FormatDateToDataBase(dates.holidayDate) : "");
                if (values.classification === "PARTIAL") {
                    const tenMinutesTolerance = 600000;
                    formData.append("schedule.identifier", values.name ? values.name + "_" + randomString(5, "a") : "");
                    formData.append("schedule.version", 0);
                    formData.append("schedule.maxEntryTimeInMilliseconds", values.entryTime ? ConvertHourMinInMilli(values.entryTime) + tenMinutesTolerance : "");
                    formData.append("schedule.maxOutputTimeInMilliseconds", values.leaveTime ? ConvertHourMinInMilli(values.leaveTime) + tenMinutesTolerance : "");
                    formData.append("schedule.minEntryTimeInMilliseconds", values.entryTime ? ConvertHourMinInMilli(values.entryTime) - tenMinutesTolerance : "");
                    formData.append("schedule.minOutputTimeInMilliseconds", values.leaveTime ? ConvertHourMinInMilli(values.leaveTime) - tenMinutesTolerance : "");
                    formData.append("schedule.workingHoursInMilliseconds", values.leaveTime ? ConvertHourMinInMilli(values.leaveTime) - ConvertHourMinInMilli(values.entryTime) : "");
                }
            } else {
                formData.append("holidayAndBreak.classification", "TOTAL");
                formData.append("holidayAndBreak.baseYear", values.baseYear ? values.baseYear : "");
                formData.append("holidayAndBreak.startDate", dates.breakStartDate ? FormatDateToDataBase(dates.breakStartDate) : "");
                formData.append("holidayAndBreak.endDate", dates.breakEndDate ? FormatDateToDataBase(dates.breakEndDate) : "");
            }
            if (context === "new") {
                addHolidaysAndBreaks(formData, setMustRedirect);
            } else {
                updateHolidaysAndBreaks(formData, setMustRedirect);
            }
        }
    }


    return (
        <React.Fragment>
            {redirect(mustRedirect)}

            <div className="headerNewDesign">
                <Breadcrumb
                    newDesign={true}
                    routes={[
                        { label: t("HR.humanResource") , to: "/HumanResources"},
                        { label: t("ROUTES.config") },
                        { label: t("HR.holidaysAndBreaks"), to: "/HolidaysAndBreaks" },
                        { label: context === "new" ? t("HR.newHolidayAndBreak") : context === "edit" ? t("HR.editHolidayAndBreak") : t("HR.viewHolidayAndBreak") }
                    ]}
                />
                <div className="container-headerNewDesign">
                    <h1 className={classes.titleNewDesign}>{context === "new" ? t("HR.newHolidayAndBreak") : context === "edit" ? t("HR.editHolidayAndBreak") : t("HR.viewHolidayAndBreak")}</h1>
                </div>
            </div>

            <div className="containerNewDesign">
                <Paper>
                    <div className="cabecalho">
                        {context === "new" ? t("HR.newHolidayAndBreak").toUpperCase() : context === "edit" ? t("HR.editHolidayAndBreak").toUpperCase() : t("HR.viewHolidayAndBreak").toUpperCase()}
                    </div>
                    <Transition loading={loading} newDesign={true}>
                        <Paper>
                            <React.Fragment>
                                <div className={classes.familyDataForm}>

                                    <Grid container item spacing={2} md={12} lg={12} style={{ paddingRight: "50px" }}>

                                        <Grid item xs={12} sm={3}>
                                            <Input
                                                required
                                                context={context}
                                                label={"GENERAL.type"}
                                                isSelect
                                                arraySelected={type}
                                                value={values.type}
                                                valueName={"type"}
                                                handleChange={handleChange}
                                                inputProps={{ autocomplete: 'off' }}
                                                error={requiredType}
                                                helperText={requiredName && t("GENERAL.requiredField")}
                                                setRequired={setRequiredType}
                                            />
                                        </Grid>
                                        <Box width="100%" />

                                        <Grid item xs={12} sm={8}>
                                            <Input
                                                required
                                                context={context}
                                                label={"PERSONAL.name"}
                                                value={values.name}
                                                valueName={"name"}
                                                maxLength={60}
                                                handleChange={handleChange}
                                                error={requiredName}
                                                helperText={requiredName && t("GENERAL.requiredField")}
                                                setRequired={setRequiredName}
                                            />
                                        </Grid>

                                        <Box width="100%" />

                                        {values.type === "HOLIDAY" &&
                                            <React.Fragment>
                                                <Grid item xs={12} sm={12} style={{ marginTop: "15px" }}>
                                                    <Typography className={classes.titleNewEquipment}>{t("HR.holiday")}</Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={4}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            disabled={context === "view"}
                                                            error={requiredHolidayDate}
                                                            helperText={requiredHolidayDate && holidayDateErrorMessage}
                                                            className={classes.identificationDatePicker}
                                                            disableToolbar
                                                            autoOk={true}
                                                            variant="inline"
                                                            format="dd/MM/yyyy"
                                                            id="validity"
                                                            margin="normal"
                                                            invalidDateMessage=""
                                                            label={t("HR.holidayDate") + "*"}
                                                            KeyboardButtonProps={{
                                                                "aria-label": "change date"
                                                            }}
                                                            value={dates.holidayDate}
                                                            onChange={handleDates("holidayDate")}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>

                                                <Grid item xs={12} sm={4}>
                                                    <Input
                                                        required
                                                        context={context}
                                                        label={"HR.classification"}
                                                        isSelect
                                                        arraySelected={holidayType}
                                                        value={values.classification}
                                                        valueName={"classification"}
                                                        handleChange={handleChange}
                                                        inputProps={{ autocomplete: 'off' }}
                                                        error={requiredClassification}
                                                        helperText={requiredClassification && t("GENERAL.requiredField")}
                                                        setRequired={setRequiredClassification}
                                                    />
                                                </Grid>
                                                <Box width="100%" />


                                                {values.classification === "PARTIAL" &&
                                                    <React.Fragment>
                                                        <Grid item xs={12} sm={4}>
                                                            <Input
                                                                required
                                                                context={context}
                                                                label={"HR.partialHolidayType"}
                                                                isSelect
                                                                arraySelected={partialHolidayType}
                                                                value={values.partialHolidayType}
                                                                valueName={"partialHolidayType"}
                                                                handleChange={handleChange}
                                                                inputProps={{ autocomplete: 'off' }}
                                                                error={requiredHolidayType}
                                                                helperText={requiredHolidayType && t("GENERAL.requiredField")}
                                                                setRequired={setRequiredHolidayType}
                                                            />
                                                        </Grid>
                                                        <Box width="100%" />

                                                        <Grid item xs={12} sm={4}>
                                                            <Input
                                                                required
                                                                context={context}
                                                                label={"HR.entryTime"}
                                                                value={values.entryTime}
                                                                valueName={"entryTime"}
                                                                maxLength={5}
                                                                handleChange={handleChange}
                                                                error={requiredEntryTime}
                                                                helperText={requiredEntryTime && entryTimeErrorMessage}
                                                                setRequired={setRequiredEntryTime}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Input
                                                                required
                                                                context={context}
                                                                label={"HR.leaveTime"}
                                                                value={values.leaveTime}
                                                                valueName={"leaveTime"}
                                                                maxLength={5}
                                                                handleChange={handleChange}
                                                                error={requiredLeaveTime}
                                                                helperText={requiredLeaveTime && leaveTimeErrorMessage}
                                                                setRequired={setRequiredLeaveTime}
                                                            />
                                                        </Grid>
                                                        <Box width="100%" />
                                                    </React.Fragment>
                                                }
                                            </React.Fragment>
                                        }

                                        {values.type === "BREAK" &&
                                            <React.Fragment>
                                                <Grid item xs={12} sm={12} style={{ marginTop: "15px" }}>
                                                    <Typography className={classes.titleNewEquipment}>{t("HR.break")}</Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={4}>
                                                    <Input
                                                        required
                                                        context={context}
                                                        label={"HR.baseYear"}
                                                        isSelect
                                                        arraySelected={baseYearsArray}
                                                        value={values.baseYear}
                                                        valueName={"baseYear"}
                                                        handleChange={handleChange}
                                                        inputProps={{ autocomplete: 'off' }}
                                                        error={requiredBaseYear}
                                                        helperText={requiredBaseYear && t("GENERAL.requiredField")}
                                                        setRequired={setRequiredBaseYear}
                                                    />
                                                </Grid>
                                                <Box width="100%" />

                                                <Grid item xs={12} sm={12} style={{ marginTop: "15px" }}>
                                                    <Typography className={classes.titleNewEquipment}>{t("HR.breakPeriod")}</Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={4}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            disabled={context === "view"}
                                                            error={requiredStartDate}
                                                            helperText={requiredStartDate && startDateErrorMessage}
                                                            className={classes.identificationDatePicker}
                                                            disableToolbar
                                                            autoOk={true}
                                                            variant="inline"
                                                            format="dd/MM/yyyy"
                                                            id="validity"
                                                            margin="normal"
                                                            invalidDateMessage=""
                                                            label={t("GENERAL.initialDate") + "*"}
                                                            KeyboardButtonProps={{
                                                                "aria-label": "change date"
                                                            }}
                                                            value={dates.breakStartDate}
                                                            onChange={handleDates("breakStartDate")}
                                                            inputProps={{ autocomplete: 'off' }}

                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>

                                                <Grid item xs={12} sm={4}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            disabled={context === "view"}
                                                            error={requiredEndDate}
                                                            helperText={requiredEndDate && endDateErrorMessage}
                                                            className={classes.identificationDatePicker}
                                                            disableToolbar
                                                            autoOk={true}
                                                            variant="inline"
                                                            format="dd/MM/yyyy"
                                                            id="validity"
                                                            margin="normal"
                                                            invalidDateMessage=""
                                                            label={t("GENERAL.finalDate") + "*"}
                                                            KeyboardButtonProps={{
                                                                "aria-label": "change date"
                                                            }}
                                                            value={dates.breakEndDate}
                                                            onChange={handleDates("breakEndDate")}
                                                            inputProps={{ autocomplete: 'off' }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>

                                                <Box width="100%" />

                                                <Grid item xs={12} sm={4}>
                                                    <Input
                                                        context={"view"}
                                                        label={"HR.numberOfDays"}
                                                        value={values.numberOfDays}
                                                    />
                                                </Grid>
                                                <Box width="100%" />

                                            </React.Fragment>
                                        }

                                        <Grid item xs={12} sm={8}>
                                            <Input
                                                required
                                                context={context}
                                                label={"GENERAL.observation"}
                                                value={values.observation}
                                                valueName={"observation"}
                                                rows={5}
                                                maxLength={200}
                                                handleChange={handleChange}
                                                error={requiredObservation}
                                                helperText={requiredObservation && t("GENERAL.requiredField")}
                                                setRequired={setRequiredObservation}
                                            />
                                        </Grid>


                                        <Box width="100%" />
                                        {context === "view" ? <div style={{ marginBottom: "30px" }}>
                                        </div>
                                            : null
                                        }

                                    </Grid>

                                    {context !== "view" ?
                                        <div style={{ paddingLeft: "10px", paddingBottom: "40px", paddingTop: "40px" }}>
                                            <Buttons
                                                onClick={handleCancel}
                                                tipo="BN-noback"
                                                conteudo={t("GENERAL.cancel")}
                                                style={{ marginRight: "30px" }}
                                            />
                                            <Buttons
                                                onClick={handleSubmit}
                                                tipo="BN-blue"
                                                conteudo={context === "new" ? t("GENERAL.add") : t("GENERAL.update")}
                                            />
                                        </div>
                                        : null
                                    }


                                </div>

                            </React.Fragment>
                        </Paper>
                    </Transition>

                </Paper>
            </div>
        </React.Fragment >
    );
};

export default HolidaysAndBreaksForm;