import React, { useState, useEffect, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Buttons from "components/buttons/Pressed";
import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";
import Input from "components/inputs/Input";
import { dateToBR } from "utils/dates/DateToBRr";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import { Button } from "@material-ui/core";

const DialogReqApprove = (props) => {
    const [t] = useTranslation();
    const classes = useStyles();
    const {
        open,
        setOpen,
        items,
        usersArray,
        externalUsersArray,
        placesArray,
        statusArray,
        setItemsToBeReturn
    } = props;

    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }

    const [requiredFieldJustification, setRequiredFieldJustification] = useState(false);
    const [requiredFieldResponsible, setRequiredFieldResponsible] = useState(false);


    const [values, setValues] = useState({
        alterResponsible: "",
        newResponsibleName: "",
        newPlace: "",
        newPlaceName: "",
        returned: "",
        radioNewStatus: "AVAILABLE",
        radioResponsibleType: "INTERNAL",
        radioReturn: "YES",
        newStatusId: "",
        unavaliableStatusId: "",
        unavaliableStatusName: ""
    });

    const clear = () => {
        setValues({
            ...values,
            alterResponsible: "",
            newResponsibleName: "",
            newPlace: "",
            newPlaceName: "",
            returned: "",
            radioNewStatus: "AVAILABLE",
            radioResponsibleType: "INTERNAL",
            radioReturn: "YES",
            newStatusId: "",
            unavaliableStatusId: "",
            unavaliableStatusName: ""
        });
    }

    const handleClose = () => {
        setOpen(false);
        clear();
        setItemsToBeReturn([])
    };

    const handleSubmit = () => {
        const canSubmit = verifyCanSubmit();
        if (canSubmit) {
            props.setReturnInforEquipment(values)
            setOpen(false);
            clear();
            setItemsToBeReturn([])
        }
    };

    const verifyCanSubmit = () => {
        let res = true;
        if (!values.alterResponsible) {
            res = false;
            setRequiredFieldResponsible(true);
        }
        return res;
    };

    const handleChange = (name, value) => {
        if (name === "alterResponsible" && values.radioResponsibleType === "INTERNAL") {
            setValues({ ...values, [name]: value, newResponsibleName: usersArray.filter(ele => String(ele.value) === String(value))[0].label });
            setRequiredFieldResponsible(false);
        } else if (name === "newPlace") {
            setValues({ ...values, [name]: value, newPlaceName: placesArray.filter(ele => String(ele.value) === String(value))[0].label });
        }
        else if (name === "alterResponsible" && values.radioResponsibleType === "EXTERNAL") {
            setValues({ ...values, [name]: value, newResponsibleName: externalUsersArray.filter(ele => String(ele.value) === String(value))[0].label });
        } else if (name === "unavaliableStatusId") {
            setValues({ ...values, [name]: value, unavaliableStatusName: statusArray.filter(ele => String(ele.value) === String(value))[0].label });
        }
        else {
            setValues({ ...values, [name]: value });
        }
    };

    function getCodeList(row) {
        const response = [];
        if (row["lsbdCodeF"])
            response.push(row["lsbdCodeF"]);
        if (row["fcpcCodeF"])
            response.push(row["fcpcCodeF"]);
        if (row["ufcCodeF"])
            response.push(row["ufcCodeF"]);

        if (response.length === 0) {
            if (row.libraryExemplar && row.libraryExemplar.length > 0 && row.libraryExemplar[0].exemplarCode !== null) {
                response.push("BIB-00" + row.libraryExemplar[0].exemplarCode);
            } else {
                response.push('-');
            }
        }
        return response;
    }

    const [state, setState] = React.useState({
        checkedA: false,
        checkedB: false,
        checkedC: false,

    });

    const handleChangeCheckboxes = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={"md"}
                aria-labelledby="form-dialog-title"
                msg="mensagem"
            >
                <DialogTitle className={classes.actionsTitle}>
                    <span style={{ fontWeight: "bold" }}>
                        {t("INVENTORY.alterItem")}
                    </span>
                    <IconButton
                        style={{ float: "right", marginTop: -10, marginRight: -17 }}
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Grid container >
                            {t("INVENTORY.itemsSelected")}
                        </Grid>
                        <Grid container >
                            <Grid item xs={12} sm={5}>
                                <b>{t("INVENTORY.item").toUpperCase()}</b>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <b>{t("INVENTORY.indentificationCode").toUpperCase()}</b>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <b>{t("INVENTORY.serialNumber2").toUpperCase()}</b>
                            </Grid>
                        </Grid>
                        {items.map(item => (
                            <Grid container >
                                <Grid item xs={12} sm={5}>
                                    {item.name}
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    {getCodeList(item)}
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    {item.serialNumber}
                                </Grid>
                            </Grid>
                        ))}
                        <Grid container style={{ paddingTop: "20px" }}>
                            <Grid item xs={12} sm={12}>
                                <b>{t("INVENTORY.alterFields").toUpperCase()}</b>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={state.checkedA}
                                                onChange={handleChangeCheckboxes}
                                                name="checkedA"
                                                color="primary"
                                            />}
                                        label="Novo Responsável"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={state.checkedB}
                                                onChange={handleChangeCheckboxes}
                                                name="checkedB"
                                                color="primary"
                                            />}
                                        label="Novo Local"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={state.checkedC}
                                                onChange={handleChangeCheckboxes}
                                                name="checkedC"
                                                color="primary"
                                            />}
                                        label="Novo Status"
                                    />
                                </FormGroup>
                            </Grid>
                        </Grid>
                        {state.checkedA &&
                            <React.Fragment>
                                <Grid item xs={12} sm={12} style={{ paddingTop: "20px" }}>
                                    <b>{t("INVENTORY.newResponsible").toUpperCase()}</b>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} sm={12}>
                                        {t("INVENTORY.responsibleType")}
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <RadioGroup row aria-label="position"
                                            name="position"
                                            defaultValue="top"
                                            value={values.radioResponsibleType}
                                            onChange={(ev) => setValues({ ...values, radioResponsibleType: ev.target.value })}
                                        >
                                            <FormControlLabel
                                                value="INTERNAL"
                                                control={<Radio color="primary" />}
                                                label="Interno"
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                value="EXTERNAL"
                                                control={<Radio color="primary" />}
                                                label="Externo"
                                                labelPlacement="end"
                                            />
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                                <Input
                                    isSelect={true}
                                    required={values.radioReturn === "YES" ? true : false}
                                    error={values.radioReturn === "YES" && requiredFieldResponsible}
                                    helperText={t("GENERAL.requiredField")}
                                    value={values.alterResponsible}
                                    valueName={"alterResponsible"}
                                    label={t("INVENTORY.alterResponsible")}
                                    margin="normal"
                                    maxLength={200}
                                    handleChange={handleChange}
                                    arraySelected={values.radioResponsibleType === "INTERNAL" ? usersArray : externalUsersArray}
                                />
                                <Grid item xs={12} sm={12} >
                                    <Button style={{ color: "#2D9CDB" }} onClick={() => setValues({ ...values, alterResponsible: auth.id, newResponsibleName: usersArray.filter(ele => String(ele.value) === String(auth.id))[0].label })}>
                                        {t("INVENTORY.assignToMe")}
                                    </Button>
                                </Grid>
                            </React.Fragment>
                        }
                        {state.checkedB &&
                            <React.Fragment>
                                <Grid item xs={12} sm={12} style={{ paddingTop: "20px" }}>
                                    <b>{t("INVENTORY.newPlace").toUpperCase()}</b>
                                </Grid>
                                <Input
                                    isSelect={true}
                                    error={requiredFieldJustification}
                                    value={values.newPlace}
                                    valueName={"newPlace"}
                                    label={t("INVENTORY.alterNewPlace") + "*"}
                                    margin="normal"
                                    maxLength={200}
                                    handleChange={handleChange}
                                    arraySelected={placesArray}
                                />
                                <Grid item xs={12} sm={12} >
                                    <Button style={{ color: "#2D9CDB" }} onClick={() => setValues({ ...values, newPlace: 489, newPlaceName: placesArray.filter(ele => String(ele.value) === String(489))[0].label })}>
                                        {t("INVENTORY.assignToSecretary")}
                                    </Button>
                                </Grid>
                            </React.Fragment>
                        }
                        {state.checkedC &&
                            <React.Fragment>
                                <Grid item xs={12} sm={12} style={{ paddingTop: "20px" }}>
                                    <b>{t("INVENTORY.newStatus").toUpperCase()}</b>
                                </Grid>
                                <Grid container style={{ paddingTop: "20px" }}>
                                    <Grid item xs={12} sm={12}>
                                        {t("INVENTORY.requisitionStatus")}
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <RadioGroup row aria-label="position"
                                            name="position"
                                            defaultValue="top"
                                            value={values.radioNewStatus}
                                            onChange={(ev) => setValues({ ...values, radioNewStatus: ev.target.value })}
                                        >
                                            <FormControlLabel
                                                value="AVAILABLE"
                                                control={<Radio color="primary" />}
                                                label="Disponível"
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                value="UNAVAILABLE"
                                                control={<Radio color="primary" />}
                                                label="Indisponível"
                                                labelPlacement="end"
                                            />
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        }
                        {values.radioNewStatus === "UNAVAILABLE" &&
                            <Input
                                isSelect={true}
                                error={requiredFieldJustification}
                                value={values.unavaliableStatusId}
                                valueName={"unavaliableStatusId"}
                                label={t("INVENTORY.unavailabilityStatus") + "*"}
                                margin="normal"
                                maxLength={200}
                                handleChange={handleChange}
                                arraySelected={statusArray}
                            />}
                        <Grid container style={{ paddingTop: "20px" }}>
                            <Grid item xs={12} sm={12}>
                                <b>{t("INVENTORY.returned").toUpperCase()}</b>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <RadioGroup row aria-label="position"
                                    name="position"
                                    defaultValue="top"
                                    value={values.radioReturn}
                                    onChange={(ev) => setValues({ ...values, radioReturn: ev.target.value })}
                                >
                                    <FormControlLabel
                                        value="YES"
                                        control={<Radio color="primary" />}
                                        label="Sim"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="NO"
                                        control={<Radio color="primary" />}
                                        label="Não"
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Buttons
                        onClick={handleClose}
                        tipo="BN-noback"
                        conteudo={t("GENERAL.cancel").toLocaleUpperCase()}
                    />
                    <Buttons
                        onClick={handleSubmit}
                        tipo="BN-blue"
                        conteudo={t("GENERAL.alter").toLocaleUpperCase()}
                    />
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default DialogReqApprove;
