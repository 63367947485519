import DateFnsUtils from "@date-io/date-fns";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import Save from "@material-ui/icons/Save";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { useStyles } from "components/layout/CommonStyles";
// import Spinner from "components/layout/spinners/SmallSpinner";
import AllowancesContext from "context/allowance/allowanceContext";
import ProjectsContext from "context/projects/projectsContext";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { animated, useTransition } from "react-spring";
import MySnackbarContentWrapper from "../layout/Message";

const tooltipTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#2c3e51"
    }
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: "white",
        fontSize: "1em",
        backgroundColor: "red",
        opacity: "0.7"
      }
    }
  }
});

var verifyGPF = false;
var verifyName = false;
var verifyCodeName = false;
var verifyDate = false;
const EditGeneralInformations = props => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [delay, setDelay] = useState(true);
  const [title, setTitle] = useState("GENERAL.requiredField");
  const [titleGpf, setTitleGpf] = useState("GENERAL.requiredField");
  const [titleName, setTitleName] = useState("GENERAL.requiredField");
  const [titleCodeName, setTitleCodeName] = useState("GENERAL.requiredField");
  const [titleBaseYear, setTitleBaseYear] = useState("GENERAL.requiredField");
  const [titleDate, setTitleDate] = useState(
    "Data de Término não pode ser menor que a de Início"
  );

  const projectsContext = useContext(ProjectsContext);
  const allowanceContext = useContext(AllowancesContext);
  const [openName, setOpenName] = useState(false);
  const [openGpf, setOpenGpf] = useState(false);
  const [openCodeName, setOpenCodeName] = useState(false);

  const [dates, setDates] = useState({
    startDate: new Date(),
    endDate: new Date()
  });
  const [openEstimatedCost, setOpenEstimatedCost] = useState(false);
  const [openClient, setOpenClient] = useState(false);
  const [openBase, setOpenBase] = useState(false);
  const [openDate, setOpenDate] = useState(false);
  const [hasAddendum, setHasAddendum] = useState(false);

  const nameRef = useRef(null);
  const codenameRef = useRef(null);
  const gpfRef = useRef(null);
  const baseyearRef = useRef(null);
  const estimatedCostRef = useRef(null);
  const clientRef = useRef(null);
  const dateRef = useRef(null);

  const [snackBar, setSnackBar] = useState({
    open: false,
    kind: "",
    content: ""
  });

  const [state, setState] = useState({
    gpf: "",
    name: "",
    client: "",
    baseYear: "",
    codeName: "",
    coordinator: "",
    estimatedCost: "",
    supportFoundation: "",
    isActive: false,
    clientId: 0,
    coordinatorId: 0,
    supportFoundationId: 0,
    status: ""
  });

  const {
    users,
    loading,
    partners,
    stepFlow,
    getUsers,
    response,
    projects,
    newProject,
    getProjects,
    getPartners,
    resetMessage,
    newProjectId,
    responseType,
    changeStepFlow,
    successiveActions,
    updateGeneralInformation
  } = projectsContext;

  const { project, projectId } = props;

  const {
    allowanceResponse,
    allowanceResponseType,
    resetAllowanceMessage
  } = allowanceContext;

  var coordinadorID = {};
  var clientID = {};
  var fundationID = {};

  useEffect(() => {
    const timer = setTimeout(() => {
      if (responseType === "SUCCESS") {
        handleSnack({ kind: "success", content: response });
      }
      if (responseType === "ERROR") {
        handleSnack({ kind: "error", content: response });
      }
      setDelay(false);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [responseType, response, successiveActions]);

  useEffect(() => {
    getPartners(partners);
    getProjects();
    getUsers(users);
    if (project.information) {
      var commingEndDate = new Date(project.information.enddate);
      commingEndDate.setMinutes(commingEndDate.getMinutes() + commingEndDate.getTimezoneOffset())
      var commingStartDate = new Date(project.information.startdate);
      commingStartDate.setMinutes(commingStartDate.getMinutes() + commingStartDate.getTimezoneOffset())

      var idU = project.information.executorId;
      var idC = project.information.clientId;
      var idF = project.information.executinginstitutionId;
      coordinadorID = users.filter(item => item.value === Number(idU));
      clientID = partners.filter(item => item.value === Number(idC));
      fundationID = partners.filter(item => item.value === Number(idF));

      var cost = project.information.estimatedcost.toString().split("");

      if (
        (cost[cost.length - 1] === "0" && cost[cost.length - 2] === "0") ||
        cost[cost.length - 3] !== "."
      ) {
        if (cost[cost.length - 2] === ".") {
          cost.push("0");
        } else {
          cost.push("0");
          cost.push("0");
        }
      }

      var estimatedCost = cost
        .filter(el => el !== "." && el !== ",")
        .join("")
        .split("")
        .reverse()
        .join("");
        
      setState({
        ...state,
        client: clientID[0] ? clientID[0]["label"] : "",
        supportFoundation: fundationID[0] ? fundationID[0]["label"] : "",
        coordinator: coordinadorID[0] ? coordinadorID[0]["label"] : "",
        gpf: project.information.gpf,
        name: project.information.name,
        codeName: project.information.identification,
        estimatedCost: converTotBrCurrency(estimatedCost),
        baseYear: project.information.baseyear,
        isActive: project.information.isActive,
        coordinatorId: project.information.executorId,
        clientId: project.information.clientId,
        supportFoundationId: project.information.executinginstitutionId ? project.information.executinginstitutionId : 0,
        status: project.statusProject
      });

      setDates({
        ...dates,
        startDate: commingStartDate,
        endDate: commingEndDate
      });
    }

    if (project.sgppdAddendum && project.sgppdAddendum.length > 0) {
      setHasAddendum(true);
    }

    return () => {};
  }, [
    Object.values(partners).length,
    JSON.stringify(partners).length,
    Object.values(project).length,
    Object.values(users).length,
    stepFlow,
    newProjectId,
    newProject
  ]);
  // SOLUÇÃO FINAL PARA EVITAR RENDERIZAR MENSAGENS AO TROCAR DE COMPONENT
  useEffect(() => {
    return () => {
      resetMessage();
    };
  }, []);

  const handleSnack = () => {
    setSnackBar({ ...snackBar, open: true });
  };

  const handleSnackClose = () => {
    setSnackBar({ ...snackBar, open: false });
    resetAllowanceMessage();
  };

  var clients = partners.filter(function(item) {
    return item.type === "CUSTOMER";
  });

  var supportFoundations = partners.filter(function(item) {
    return item.type === "EXECUTING_INSTITUTION";
  });

  const handleActiveButton = e => {
    e.preventDefault();
    setState({ ...state, isActive: e.target.value });
  };

  const cleanUpRequiredMessage = ref => {
    switch (ref) {
      case "gpf":
        setOpenGpf(false);
        break;
      case "name":
        setOpenName(false);
        break;
      case "estimatedCost":
        setOpenEstimatedCost(false);
        break;
      case "client":
        setOpenClient(false);
        break;
      case "baseYear":
        setOpenBase(false);
        break;
    }
  };

  const getId = (name, eventName) => {
    var id = 0;
    var array = {};
    switch (name) {
      case "client":
        array = clients;
        break;
      case "supportFoundation":
        array = supportFoundations;
        break;
      case "coordinator":
        array = users;
        break;
      default:
        break;
    }
    for (var i = 0; i < array.length; i++) {
      if (array[i].label === eventName) {
        id = array[i].value;
        break;
      }
    }
    return id;
  };

  const handleFieldChangeCost = name => event => {
    cleanUpRequiredMessage(name);
    if (event.target.value !== " " && !event.target.value.includes(" ")) {
      var str = event.target.value.split("");
      var aux = str.filter(el => el !== "." && el !== ",").join("");
      var value = event.target.value;
      if (event.target.value) {
        value = converTotBrCurrency(
          aux
            .split("")
            .reverse()
            .join("")
        );
      }
      aux = value
        .split("")
        .filter(el => el !== ".")
        .join("")
        .replace(",", ".");
      if (!isNaN(aux)) {
        setState({ ...state, [name]: value });
      }
    }
  };

  const handleChange = name => event => {
    cleanUpRequiredMessage(name);
    var id = getId(name, event.target.value);
    var field = name + "Id";
    setState({
      ...state,
      [name]: event.target.value,
      [`${field}`]: id
    });
  };

  const handleFieldChange = name => event => {
    cleanUpRequiredMessage(name);
    setState({ ...state, [name]: event.target.value });
  };

  let data,
    data2,
    data3 = [];

  data = projects;
  data2 = projects;
  data3 = projects;

  const converTotBrCurrency = number => {
    var temp = "";
    var len = number.length;
    var count = 0;

    for (var i = 0; i < len; i++) {
      if (i === 1) {
        var aux = "," + number[i] + temp;
        temp = aux;
      } else {
        if (count === 3) {
          count = 1;
          var aux = number[i] + "." + temp;
          temp = aux;
        } else {
          if (i > 1) {
            count += 1;
          }
          var aux = number[i] + temp;
          temp = aux;
        }
      }
    }

    return temp;
  };

  // const handleFieldChangeGPF = name => event => {
  //   cleanUpRequiredMessage(name);
  //   setState({ ...state, [name]: event.target.value });

  //   data = data.filter(
  //     item => item.gpf === event.target.value && item.id !== project.id
  //   );
  //   if (data.length === 1) {
  //     verifyGPF = true;
  //     setTitleGpf("PROJECTS.gpfAlreadyTaken");
  //     setOpenGpf(true);
  //   } else {
  //     verifyGPF = false;
  //     setOpenGpf(false);
  //   }
  // };

  const handleFieldChangeName = name => event => {
    cleanUpRequiredMessage(name);
    setState({ ...state, [name]: event.target.value });
    data2 = data2.filter(
      item => item.name === event.target.value && item.id !== project.id
    );
    if (name === "name" && data2.length === 1) {
      verifyName = true;
      setTitleName("PROJECTS.nameAlreadyTaken");
      setOpenName(true);
    } else {
      verifyName = false;
      setOpenName(false);
    }
  };

  const handleFieldChangeCodeName = name => event => {
    cleanUpRequiredMessage(name);
    setState({ ...state, [name]: event.target.value });
    data3 = data3.filter(
      item => item.codeName === event.target.value && item.id !== project.id
    );
    if (name === "codeName" && data3.length === 1) {
      verifyCodeName = true;
      setTitleCodeName("PROJECTS.codenameAlreadyTaken");
      setOpenCodeName(true);
    } else {
      verifyCodeName = false;
      setOpenCodeName(false);
    }
  };

  const handleDates = name => e => {
    setDates({
      ...dates,
      [name]: e
    });
  };

  // #TODO refactor this, apply inputRef style instead of using getById
  const verifyCanSubmit = () => {
    var nameEmpty = state.name.length === 0;
    // var gpfEmpty = state.gpf.length === 0;
    var estimatedCostEmpty = state.estimatedCost.length === 0;
    var clientEmpty = state.client.length === 0;
    var baseYear = state.baseYear.length === 0;

    if (state.baseYear.length < 4 && state.baseYear.length !== 0) {
      setTitleBaseYear("No mínimo 4 dígitos");
      setOpenBase(true);
      return false;
    }
    // if (verifyGPF) {
    //   setOpenGpf(true);
    //   changeStepFlow(0);
    //   return false;
    // }
    if (verifyName) {
      setOpenName(true);
      changeStepFlow(0);
      return false;
    }
    if (verifyCodeName) {
      setOpenCodeName(true);
      changeStepFlow(0);
      return false;
    }
    if (verifyDate) {
      setOpenDate(true);
      changeStepFlow(0);
      return false;
    }
    if (
      nameEmpty ||
      estimatedCostEmpty ||
      clientEmpty ||
      baseYear
    ) {
      // if (gpfEmpty) setOpenGpf(true);
      if (nameEmpty) setOpenName(true);
      if (estimatedCostEmpty) setOpenEstimatedCost(true);
      if (clientEmpty) setOpenClient(true);
      if (baseYear) {
        setTitleBaseYear("GENERAL.requiredField");
        setOpenBase(true);
      }
      setTitle("GENERAL.requiredField");
      changeStepFlow(0);
      return false;
    } else {
      return true;
    }
  };

  const submit = () => {
    var canSubmit = verifyCanSubmit();

    if (canSubmit) {
      var aux = [];
      aux.id = projectId;
      aux.gpf = state.gpf;
      aux.name = state.name;
      aux.codeName = state.codeName;
      aux.estimatedCost = state.estimatedCost
        .split("")
        .filter(el => el !== ".")
        .join("")
        .replace(",", ".");
      aux.startDate = document.getElementById("startDate").value;
      aux.endDate = document.getElementById("endDate").value;
      aux.baseYear = state.baseYear;
      aux.clientId = state.clientId;
      aux.supportFoundationId = state.supportFoundationId;
      aux.coordinatorId = state.coordinatorId;
      updateGeneralInformation(aux);
    }
  };

  const transitions = useTransition(loading || delay, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  });

  return transitions.map(({ item, props, key }) =>
    item ? (
      <animated.div key={key} style={props}>
        {/* <Spinner /> */}
        <> </>
      </animated.div>
    ) : (
      <animated.div key={key} style={props}>
        <Paper className={classes.root}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={snackBar.open}
            onClose={handleSnackClose}
            TransitionComponent={Slide}
            transitionDuration={{ enter: 500, exit: 500 }}
            ContentProps={{
              "aria-describedby": "message-id"
            }}
            autoHideDuration={2000}
          >
            <MySnackbarContentWrapper
              onClose={handleSnackClose}
              variant={
                responseType !== ""
                  ? responseType.toLowerCase()
                  : allowanceResponseType.toLowerCase()
              }
              message={
                <span id="message-id">
                  {response !== "" ? response : allowanceResponse}
                </span>
              }
            />
          </Snackbar>
          <div className={classes.header}>
            {t("GENERAL.generalInformation").toUpperCase()}
          </div>
          <div className={classes.bodyForm}>
            <Grid container item spacing={2} md={12} lg={12}>
              {/* <Grid item xs={1}>
                <MuiThemeProvider theme={tooltipTheme}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    open={openGpf}
                    title={t(titleGpf)}
                  >
                    <TextField
                      inputRef={gpfRef}
                      className={classes.textField}
                      margin="normal"
                      id="gpf"
                      label="GPF"
                      name="GPF"
                      value={state.gpf}
                      error={openGpf}
                      onChange={handleFieldChangeGPF("gpf")}
                      inputProps={{
                        maxLength: 10
                      }}
                    />
                  </Tooltip>
                </MuiThemeProvider>
              </Grid> */}
              <Grid item xs={5}>
                <MuiThemeProvider theme={tooltipTheme}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    open={openName}
                    title={t(titleName)}
                  >
                    <TextField
                      inputRef={nameRef}
                      className={classes.textField}
                      margin="normal"
                      id="name"
                      label={t("PERSONAL.name") + "*"}
                      name="name"
                      error={openName}
                      value={state.name}
                      onChange={handleFieldChangeName("name")}
                      inputProps={{
                        maxLength: 120
                      }}
                    />
                  </Tooltip>
                </MuiThemeProvider>
              </Grid>
              <Grid item xs={4}>
                <MuiThemeProvider theme={tooltipTheme}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    open={openCodeName}
                    title={t(titleCodeName)}
                  >
                    <TextField
                      inputRef={codenameRef}
                      className={classes.textField}
                      error={openCodeName}
                      margin="normal"
                      id="codename"
                      label={t("PROJECTS.codename")}
                      name="codename"
                      value={state.codeName}
                      onChange={handleFieldChangeCodeName("codeName")}
                      inputProps={{
                        maxLength: 120
                      }}
                    />
                  </Tooltip>
                </MuiThemeProvider>
              </Grid>
              <Grid item xs={3}>
                <MuiThemeProvider theme={tooltipTheme}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    open={openEstimatedCost}
                    title={t(titleName)}
                  >
                    <TextField
                      inputRef={estimatedCostRef}
                      className={classes.textField}
                      margin="normal"
                      label={t("PROJECTS.estimatedCost") + "*"}
                      name="estimatedCost"
                      value={state.estimatedCost}
                      // disabled={hasAddendum}
                      error={openEstimatedCost}
                      onChange={handleFieldChangeCost("estimatedCost")}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">R$</InputAdornment>
                        )
                      }}
                    />
                  </Tooltip>
                </MuiThemeProvider>
              </Grid>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={4}>
                  <KeyboardDatePicker
                    className={classes.textField}
                    disableToolbar
                    required
                    maxDate={dates.EndDate}
                    autoOk={true}
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="startDate"
                    label={t("GENERAL.startDate")}
                    value={dates.startDate}
                    minDateMessage={
                      "A data não deve ser anterior à data mínima"
                    }
                    onChange={handleDates("startDate")}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <KeyboardDatePicker
                    className={classes.textField}
                    disableToolbar
                    required
                    autoOk={true}
                    minDate={dates.startDate}
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="endDate"
                    label={t("GENERAL.endDate")}
                    value={dates.endDate}
                    onChange={handleDates("endDate")}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    className={classes.textField}
                    margin="normal"
                    label={t("ADDRESS.status") + "*"}
                    name="status"
                    id="status"
                    value={state.status}
                  />
                </Grid>
                <Grid item xs={1}>
                  <MuiThemeProvider theme={tooltipTheme}>
                    <Tooltip
                      TransitionComponent={Zoom}
                      open={openBase}
                      title={t(titleBaseYear)}
                    >
                      <TextField
                        inputRef={baseyearRef}
                        className={classes.textField}
                        margin="normal"
                        label={t("PROJECTS.baseYear") + "*"}
                        name="baseYear"
                        id="baseYear"
                        error={openBase}
                        value={state.baseYear}
                        onChange={handleFieldChange("baseYear")}
                        inputProps={{
                          minLength: 4,
                          maxLength: 4
                        }}
                      />
                    </Tooltip>
                  </MuiThemeProvider>
                </Grid>
              </MuiPickersUtilsProvider>
              <Grid item xs={4}>
                <MuiThemeProvider theme={tooltipTheme}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    open={openClient}
                    title={t(title)}
                  >
                    <TextField
                      select
                      inputRef={clientRef}
                      id="client"
                      label={t("PROJECTS.client") + "*"}
                      name="client"
                      value={state.client}
                      className={classes.textField}
                      value={state.client}
                      onChange={handleChange("client")}
                      margin="normal"
                    >
                      {clients.map(client => (
                        <MenuItem key={client.value} value={client.label}>
                          {client.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Tooltip>
                </MuiThemeProvider>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  select
                  id="supportFoundation"
                  label={t("PROJECTS.supportFoundation")}
                  name="supportFoundation"
                  value={state.supportFoundation}
                  className={classes.textField}
                  value={state.supportFoundation}
                  onChange={handleChange("supportFoundation")}
                  margin="normal"
                >
                  {supportFoundations.map(supportFoundation => (
                    <MenuItem
                      key={supportFoundation.value}
                      value={supportFoundation.label}
                    >
                      {supportFoundation.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  select
                  id="coordinator"
                  label={t("PROJECTS.coordinator")}
                  name="coordinator"
                  value={state.coordinator}
                  className={classes.textField}
                  onChange={handleChange("coordinator")}
                  margin="normal"
                >
                  {users.map(user => (
                    <MenuItem key={user.value} value={user.label}>
                      {user.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </div>
        </Paper>
        <Fab
          color="primary"
          aria-label="update"
          onClick={submit}
          className={classes.fab}
        >
          <Save />
        </Fab>
      </animated.div>
    )
  );
};
export default EditGeneralInformations;
