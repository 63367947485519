import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Breadcrumb from 'components/layout/breadcrumb/Breadcrumb';
import Transition from 'components/layout/transition/Transition';
import FilterContainer from 'components/containers/FilterContainer';
import HeaderContainer from 'components/containers/HeaderContainer';
import ContentContainer from 'components/containers/ContentContainer';
import InputFilter from 'components/inputs/InputFilter';
import InputFilterDate from 'components/inputs/InputFilterDate';
import UsersTable from './UsersTable';
import ManagementContext from 'context/quotaModule/management/managementContext';
import Paper from '@material-ui/core/Paper';
import Dissatisfied from '@material-ui/icons/SentimentVeryDissatisfied';
import Footer from 'components/layout/Footer';
import { useStyles } from 'components/layout/CommonStyles';
import {
    SystemStatus,
    SystemStatusArray,
    ModuleProfileArray,
    translateSystemStatus,
    translateModuleProfile
} from './ScreenUtils';
import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';
import { Screen, Allow } from 'global/constants';
import { useQueryUsers } from 'hooks/DataFetching/Quotas/Users/useQueryUsersQuotas';
import { screenStyles } from './Styles';

const Users = () => {
    const classes = useStyles();
    const styles = screenStyles();
    const [t] = useTranslation();
    const [loading, setLoading] = useState(true);

    const [findText, setFindText] = useState('');
    const [filterName, setFilterName] = useState('');
    const [filterSystemStatus, setFilterSystemStatus] = useState(
        SystemStatus.ACTIVE
    );
    const [filterModuleProfile, setModuleProfile] = useState('ALL');
    const [dataFilter, setDataFilter] = useState([]);

    const [canClick, setCanClick] = useState(false);

    const { isLoading, isFetching, error, data } = useQueryUsers();

    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const hasItens = data && data.length > 0;
    const [hasNoResult, setHasNoResult] = useState(
        hasItens && dataFilter && dataFilter.length === 0
    );

    useEffect(() => {
        if (data && data.length > 0) {
            const { newDataFilter, textNoFound } = applyFilter(
                data.filter(x => !x.hasDeleted)
            );

            if (newDataFilter.length === 0) {
                setDataFilter([]);
                setHasNoResult(true);
                setFindText(textNoFound);
            } else {
                setDataFilter(newDataFilter);
                setHasNoResult(false);
                setFindText('');
            }

            setDataFilter(newDataFilter);
        }
    }, [filterName, filterSystemStatus, filterModuleProfile, data]);

    const applyFilter = data => {
        let newDataFilter = [];
        let textNoFound = '';

        newDataFilter = data;

        if (filterName) {
            newDataFilter = newDataFilter.filter(
                item =>
                    item.name &&
                    item.name.toLowerCase().includes(filterName.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterName;
        }

        if (
            (filterSystemStatus || filterSystemStatus == false) &&
            filterSystemStatus !== 'ALL'
        ) {
            newDataFilter = newDataFilter.filter(
                item => item.isActive === filterSystemStatus
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = translateSystemStatus(filterSystemStatus);
        }

        if (
            (filterModuleProfile || filterModuleProfile == false) &&
            filterModuleProfile !== 'ALL'
        ) {
            newDataFilter = newDataFilter.filter(
                item => item.isActiveInQuota === filterModuleProfile
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = translateModuleProfile(filterModuleProfile);
        }

        return { newDataFilter, textNoFound };
    };

    useEffect(() => {
        if (filterName || filterSystemStatus || filterModuleProfile) {
            setCanClick(true);
        } else {
            setCanClick(false);
        }
    }, [filterName, filterSystemStatus, filterModuleProfile]);

    return (
        <React.Fragment>
            <Transition loading={isLoading && isFetching} newDesign={true}>
                <HeaderContainer>
                    <Breadcrumb
                        newDesign={true}
                        routes={[
                            {
                                label: t('QUOTAS.quotas'),
                                to: '/Quotas'
                            },
                            { label: t('USERS.users') }
                        ]}
                    />
                    <div className="container-headerNewDesign">
                        <h1 className={classes.titleNewDesign}>
                            {t('USERS.users')}
                        </h1>
                    </div>
                </HeaderContainer>
                <ContentContainer>
                    <Paper>
                        <div className="cabecalho">
                            {t('QUOTAS.listOfUsers').toUpperCase()}
                        </div>
                        <FilterContainer>
                            <InputFilter
                                filterValue={filterName}
                                setFilter={setFilterName}
                                label={'GENERAL.name'}
                            />

                            <InputFilter
                                filterValue={filterSystemStatus}
                                setFilter={setFilterSystemStatus}
                                label={'GENERAL.systemStatus'}
                                isSelect
                                arraySelected={SystemStatusArray}
                            />

                            <InputFilter
                                filterValue={filterModuleProfile}
                                setFilter={setModuleProfile}
                                label={'GENERAL.moduleProfile'}
                                isSelect
                                arraySelected={ModuleProfileArray}
                            />
                        </FilterContainer>
                        <React.Fragment>
                            <UsersTable
                                rows={dataFilter}
                                toFirstPage={canClick}
                                headers={[
                                    'id',
                                    'name',
                                    'job',
                                    'isActive',
                                    'isActiveInQuota',
                                    'isQuotaPayee'
                                ]}
                                visibleHeaders={[
                                    'Nome',
                                    'Cargo',
                                    'Status no Sistema',
                                    'Perfil no Módulo',
                                    'Gera Fatura?',
                                    ''
                                ]}
                                sortedFields={[
                                    'name',
                                    'job',
                                    'isActive',
                                    'isActiveInQuota',
                                    'isQuotaPayee'
                                ]}
                                mainField="name"
                            />

                            {!hasItens && !error && (
                                <div className={styles.noJustificationsWrapper}>
                                    <h2>{t('HR.noJobsMessage')}</h2>
                                    <h4>{t('HR.noJobsSubMessage')}</h4>
                                </div>
                            )}
                            {hasNoResult && !error && (
                                <div className={classes.noParticipantsMessage}>
                                    <div>
                                        <Dissatisfied
                                            className={
                                                classes.notFoundIconStyle
                                            }
                                        />
                                    </div>
                                    <h2>{t('GENERAL.noResultsFound')}</h2>
                                    <h5>{`Sua busca por "${findText}" não retornou resultados.`}</h5>
                                </div>
                            )}
                            {error && (
                                <div className={classes.noParticipantsMessage}>
                                    <div>
                                        <Dissatisfied
                                            className={
                                                classes.notFoundIconStyle
                                            }
                                        />
                                    </div>
                                    <h2>
                                        {
                                            'Houve um erro de conexão com o Servidor'
                                        }
                                    </h2>
                                </div>
                            )}
                        </React.Fragment>
                    </Paper>
                </ContentContainer>
                <Footer />
            </Transition>
        </React.Fragment>
    );
};

export default Users;
