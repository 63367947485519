import * as React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import Zoom from '@material-ui/core/Zoom';
import { tooltipStyles } from './Styles';

const InfoTooltip = ({ text, color, fontSize, ...props }) => {
    const styles = tooltipStyles();

    return (
        <Tooltip TransitionComponent={Zoom} {...props} title={text} placement="top">
            <InfoIcon
                fontSize={fontSize ? 'inherit' : 'medium'}
                color="action"
                style={{ color: color, fontSize: fontSize }}
            />
        </Tooltip>
    );
};

export default InfoTooltip;
