import React from 'react';

const DownloadIcon = ({className}) => {
    return (
        <svg className={className} viewBox="0 0 24 24" aria-hidden="true">
            <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path>
        </svg>
    )
}

export default DownloadIcon