import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import Breadcrumb from 'components/layout/breadcrumb/Breadcrumb';
import Transition from 'components/layout/transition/Transition';
import FilterContainer from 'components/containers/FilterContainer';
import HeaderContainer from 'components/containers/HeaderContainer';
import ContentContainer from 'components/containers/ContentContainer';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import InputFilter from 'components/inputs/InputFilter';
import InputFilterDate from 'components/inputs/InputFilterDate';
import ManagementContext from 'context/quotaModule/management/managementContext';
import Paper from '@material-ui/core/Paper';
import { Box, Button, IconButton } from '@material-ui/core';
import Dissatisfied from '@material-ui/icons/SentimentVeryDissatisfied';
import Footer from 'components/layout/Footer';

import FormUsers from './FormUsers';
import FormPermissions from './FormPermissions';

import { useStyles } from 'components/layout/CommonStyles';
import { verifyUsers, verifyUsersPermissions } from '../ScreenUtils';
import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';
import { Screen, Allow, Modules } from 'global/constants';
import { useQueryCreateUser } from 'hooks/DataFetching/Admin/Users/useQueryCreateUser';
import { useMutationCreateUser } from 'hooks/DataFetching/Admin/Users/useMutationCreateUser';
import { screenStyles } from '../Styles';
import { FormatDateToFront } from 'utils/dates/FormatDateToFront';
import { FormatDateToDataBase } from 'utils/dates/FormatDateToDataBase';

function TabPanel(props) {
    const { children, value, index } = props;
    return <div>{value === index && children}</div>;
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

const Form = props => {
    const classes = useStyles();
    const styles = screenStyles();
    const [t] = useTranslation();

    const [tabValue, setTabValue] = useState(0);
    const [canChangeTab, setCanChangeTab] = useState(false);
    const [mustRedirect, setMustRedirect] = useState(false);

    const redirect = go => {
        if (go) return <Redirect to={`/Admin/Users`} />;
    };

    const { context, id } = props;

    const createUserQuery = useQueryCreateUser({});

    const mutation = useMutationCreateUser(setMustRedirect);

    const [userValues, setUserValues] = useState({
        name: '',
        shortname: '',
        username: '',
        birthday: null,
        cpf: '',
        image: ''
    });

    const [usersRequired, setUsersRequired] = useState({});
    const [imageFile, setImageFile] = useState([]);

    const [permissionsValues, setPermissionsValues] = useState({
        libraryId: 0,
        patrimonyId: 0,
        humanResourcesId: 0,
        humanResourcesOldId: 0,
        newsId: 0,
        administrativeId: 0,
        quotasId: 0,
        surrogate: '',
        approver: ''
    });

    const changeTab = () => {
        setTabValue(tabValue === 0 ? 1 : 0);
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const tabContext = () => {
        if (tabValue === 0) {
            return (
                <FormUsers
                    values={userValues}
                    setValues={setUserValues}
                    changeTab={changeTab}
                    setCanChangeTab={setCanChangeTab}
                    data={createUserQuery.data}
                    context={context}
                    usersRequired={usersRequired}
                    imageFile={imageFile}
                    setImageFile={setImageFile}
                    id={id}
                />
            );
        } else {
            return (
                <FormPermissions
                    values={permissionsValues}
                    userValues={userValues}
                    setValues={setPermissionsValues}
                    changeTab={changeTab}
                    data={createUserQuery.data}
                    context={context}
                    handleSubmit={handleSubmit}
                    mutationIsLoading={mutation.isLoading}
                    id={id}
                />
            );
        }
    };

    const handleSubmit = () => {
        if (context === 'new') {
            const validUsers = verifyUsers(
                userValues,
                createUserQuery.data,
                setUsersRequired
            );
            const validPermissions = verifyUsersPermissions(permissionsValues);
            if (!validUsers) {
                setTabValue(0);
            }

            if (validUsers && validPermissions) {
                let formData = new FormData();

                formData.append('user_acc_details.name', userValues.name);
                formData.append(
                    'user_acc_details.shortname',
                    userValues.shortname
                );
                formData.append(
                    'user_acc_details.username',
                    userValues.username + process.env.REACT_APP_DEFAULT_EMAIL
                );
                formData.append(
                    'user_acc_details.dateOfBirth',
                    FormatDateToDataBase(userValues.birthday)
                );
                formData.append('user_acc_details.cpf', userValues.cpf);

                formData.append(
                    'user_acc_details.image',
                    imageFile.length > 0 ? imageFile[0] : null
                );

                formData.append(
                    'user_permissions.approverId',
                    permissionsValues.approver
                        ? permissionsValues.approver.value
                        : null
                );

                formData.append(
                    'user_permissions.surrogateId',
                    permissionsValues.surrogate
                        ? permissionsValues.surrogate.value
                        : null
                );
                formData.append(
                    'user_permissions.systemRoles[0].systemId',
                    Modules.LIBRARY.id
                );
                formData.append(
                    'user_permissions.systemRoles[0].roleId',
                    permissionsValues.libraryId
                );
                formData.append(
                    'user_permissions.systemRoles[0].isOldSystem',
                    false
                );

                formData.append(
                    'user_permissions.systemRoles[1].systemId',
                    Modules.PATRIMONY.id
                );
                formData.append(
                    'user_permissions.systemRoles[1].roleId',
                    permissionsValues.patrimonyId
                );
                formData.append(
                    'user_permissions.systemRoles[1].isOldSystem',
                    false
                );

                formData.append(
                    'user_permissions.systemRoles[2].systemId',
                    Modules.QUOTAS.id
                );
                formData.append(
                    'user_permissions.systemRoles[2].roleId',
                    permissionsValues.quotasId
                );
                formData.append(
                    'user_permissions.systemRoles[2].isOldSystem',
                    false
                );

                formData.append(
                    'user_permissions.systemRoles[3].systemId',
                    Modules.HR.id
                );
                formData.append(
                    'user_permissions.systemRoles[3].roleId',
                    permissionsValues.humanResourcesId
                );
                formData.append(
                    'user_permissions.systemRoles[3].isOldSystem',
                    false
                );

                formData.append(
                    'user_permissions.systemRoles[4].systemId',
                    Modules.HR_OLD.id
                );
                formData.append(
                    'user_permissions.systemRoles[4].roleId',
                    permissionsValues.humanResourcesOldId
                );
                formData.append(
                    'user_permissions.systemRoles[4].isOldSystem',
                    true
                );

                formData.append(
                    'user_permissions.systemRoles[5].systemId',
                    Modules.NEWS.id
                );
                formData.append(
                    'user_permissions.systemRoles[5].roleId',
                    permissionsValues.newsId
                );
                formData.append(
                    'user_permissions.systemRoles[5].isOldSystem',
                    false
                );

                formData.append(
                    'user_permissions.systemRoles[6].systemId',
                    Modules.ADMINISTRATIVE.id
                );
                formData.append(
                    'user_permissions.systemRoles[6].roleId',
                    permissionsValues.administrativeId
                );
                formData.append(
                    'user_permissions.systemRoles[6].isOldSystem',
                    false
                );

                mutation.mutate(formData);
            }
        }
    };

    const contextText =
        context === 'new'
            ? t('GENERAL.add')
            : context === 'view'
            ? t('GENERAL.view')
            : t('GENERAL.edit');

    return (
        <React.Fragment>
            {redirect(mustRedirect)}
            <Transition
                loading={
                    createUserQuery.isLoading && createUserQuery.isFetching
                }
                newDesign={true}
            >
                <HeaderContainer>
                    <Breadcrumb
                        newDesign={true}
                        routes={[
                            {
                                label: t('ADMIN.Administrative'),
                                to: '/Admin'
                            },
                            { label: t('USERS.users'), to: '/Admin/Users' },
                            {
                                label: `${contextText} ${t('GENERAL.user')}`
                            }
                        ]}
                    />
                    <div className="container-headerNewDesign">
                        <h1 className={classes.titleNewDesign}>
                            {`${contextText} ${t('GENERAL.user')}`}
                        </h1>
                    </div>
                </HeaderContainer>
                <ContentContainer>
                    <Paper>
                        <div className="headerContent">
                            <Box
                                display={'flex'}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                            >
                                <Box>
                                    {`${contextText} ${t(
                                        'GENERAL.user'
                                    )}`.toUpperCase()}
                                </Box>
                                {tabValue === 1 && context === 'new' ? (
                                    <Box
                                        display={'flex'}
                                        alignItems={'center'}
                                        onClick={() => changeTab()}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <IconButton>
                                            <ArrowBackIcon />
                                        </IconButton>
                                        {t('GENERAL.comeBack').toUpperCase()}
                                    </Box>
                                ) : null}
                            </Box>
                        </div>

                        <React.Fragment>
                            <AppBar
                                position="static"
                                style={{
                                    boxShadow: 'none',
                                    transform: 'none'
                                }}
                            >
                                <Tabs
                                    selectionFollowsFocus
                                    style={{
                                        backgroundColor: '#f5f6fa',
                                        color: '#6D6F71'
                                    }}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    value={tabValue}
                                    onChange={handleTabChange}
                                >
                                    <Tab
                                        style={{ fontWeight: 'bold' }}
                                        label={t(
                                            'ADMIN.dataOfUser'
                                        ).toUpperCase()}
                                    />

                                    <Tab
                                        disabled={
                                            context === 'new' && !canChangeTab
                                        }
                                        style={{ fontWeight: 'bold' }}
                                        label={t(
                                            'GENERAL.permissions'
                                        ).toUpperCase()}
                                    />
                                </Tabs>
                            </AppBar>

                            {tabContext()}
                        </React.Fragment>
                    </Paper>
                </ContentContainer>
                <Footer />
            </Transition>
        </React.Fragment>
    );
};

export default Form;
