import { makeStyles, withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({

  buttonContainer: {
    paddingTop: "40px",
    width: "17.5rem",
    display: "flex",
  },

  leftButtonContainer: {
    flex: 1,
  },

  rightButtonContainer: {
    flex: 1,
  },

  deleteDialog_p: {
    fontSize: "12",
    marginTop: 4,
  },
  deleteDialog_pb: {
    fontSize: "14",
    marginBottom: 0,
  },
  dialogTextField: {
    
  }

}));

export { useStyles };

