import { makeStyles } from "@material-ui/core/styles";

export const screenStyles = makeStyles(theme => ({
    space: {
      marginBottom: "25px",
    },
    blueBorder: {
      border: "1px solid"
    },
    buttonsWrapper: {
      marginTop: "20px"
    },
    textMargin: {
      margin: "0px 0px 5px",
    },
    textCenter: {
      textAlign: "center"
    },
    textLineHeight: {
      lineHeight: "1.8"
    },
    icons: {
      verticalAlign: "top",
      color: "#6D6F71",
    },
    iconsRight: {
      color: "#6D6F71",
      marginLeft: "5px"
    },
    collaboratorInfoWrapper: {
      display: "flex",
      padding: "2rem" ,
      justifyContent: "space-between"
    },    
    collaboratorInfoCard: {    
      padding: "1rem",
      height: "270px",
      width: "228px",
      backgroundColor: "#2C3E51",
      display: 'flex',
      flexDirection: 'column',

    },
    collaboratorInfoCardImgWrapper: {
      display: "flex",
      justifyContent: "center"
    },
    collaboratorInfoCardImg: {
      objectFit: "cover",
      height: "128px",
      width: "128px",
      borderRadius: '100%'
    },
    collaboratorInfoCardPersonalInfo: {
      padding: "0rem 1.15rem",
      
    },
    collaboratorInfoCardPersonalInfoFirstContent: {
      marginTop: "0.5rem",
      marginBottom: "1rem",
      
    },
    collaboratorInfoDailyClock: {
      padding: " 0.5rem 2rem 0rem",
      width: "50%"
    },
    collaboratorInfoDailyClockTimes: {
      display: "flex",
      flexDirection: "column",
      margin: "0.5rem 0rem"
    },
    collaboratorInfoDailyClockTextWrapper: {
      display: "inline-flex",
      gap: "5px",
      lineHeight: "1.6"
    },
    collaboratorInfoDailyClockWorkedHours: {
      display: "inline-flex",
      gap: "5px",
    },
    collaboratorInfoClockIn: {
      paddingTop: "0.5rem",
      width: "30%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between"
    },
    collaboratorInfoClockInSystemHour: {
      display: "flex"
    },
    collaboratorInfoClockInRealTimeButton: {
      width: "100%"
    },
    collaboratorInfoClockInRealTimeWeekday: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    myActivitiesTextsPadding:{
      padding: "12px 0px"
    },
    
  })
)  