import axios from 'axios';
import { useMutation } from 'react-query';
import Cookies from 'js-cookie';
import { useSnackbar } from 'hooks/UseSnackbar';
import { useTranslation } from 'react-i18next';
import { getManagersAndSendEmails } from 'services/Emails/General/generalEmailService';

export const useMutationCreateUser = setMustRedirect => {
    const toast = useSnackbar();
    const [t] = useTranslation();

    const mutation = useMutation({
        mutationFn: async obj =>
            await axios.post(
                process.env.REACT_APP_SERVER + 'api/Admin/Users/Create',
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            ),
        onSuccess: res => {
            toast.success(t('ADMIN.userCreatedSuccess'));
            setMustRedirect(true);
            const data = res.data;

            const emailData = {
                collaboratorName: data.shortname,
                collaboratorEmail: data.username,
                collaboratorCpf: data.cpf,
                collaboratorId: data.id,
                createdAt: data.createdAt,
                operatorName: data.registeredBy.shortname,
                approverName: data.approver.shortname,
                surrogateName: data.surrogate.shortname,
                modulesPermissions: data.systemRoles.map(module => {
                    return {
                        moduleName: module.name,
                        permissionName: module.role.name
                    };
                })
            };

            getManagersAndSendEmails(
                emailData,
                'api/Admin/Collaborator/Registered',
                'ADMIN_NEW_COLLABORATOR_REGISTERED_EMAIL_OPTIONAL',
                'adminManagers'
            );
        },
        onError: err => {
            if (err.response.data.message && err.response.data.code !== 1000) {
                toast.error(err.response.data.message);
            } else {
                toast.error(t('ADMIN.userCreatedError'));
            }
        },
        onSettled: () => {}
    });

    return mutation;
};
