/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import Breadcrumb from 'components/layout/breadcrumb/Breadcrumb';
import Transition from 'components/layout/transition/Transition';
import FilterContainer from 'components/containers/FilterContainer';
import HeaderContainer from 'components/containers/HeaderContainer';
import ContentContainer from 'components/containers/ContentContainer';
import InputFilter from 'components/inputs/InputFilter';
import Button from '@material-ui/core/Button';
import Footer from 'components/layout/Footer';
import BookCategoryTable from './BookCategoryTable';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import Dissatisfied from '@material-ui/icons/SentimentVeryDissatisfied';
import Paper from '@material-ui/core/Paper';
import InventoryContext from 'context/inventory/inventoryContext';
import DialogBookCategory from './DialogBookCategory';

import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';
import { Screen, Allow } from 'global/constants';

const BookCategory = () => {
    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }
    const classes = useStyles();
    const [t] = useTranslation();
    const inventoryContext = useContext(InventoryContext);
    const [findText, setFindText] = useState('');
    const [filter, setFilter] = useState('');
    const [dataFilter, setDataFilter] = useState([]);
    const [canClick, setCanClick] = useState(false);
    const [loading, setLoading] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);

    const { getBookCategories, bookCategories } = inventoryContext;

    async function fetchData() {
        await getBookCategories();
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (filter) {
            setCanClick(true);
        } else {
            setCanClick(false);
        }
    }, [filter]);

    useEffect(() => {
        if (bookCategories && bookCategories.length) {
            const { newDataFilter, textNoFound } = applyFilter(
                bookCategories.filter(category => !category.deleted)
            );

            if (newDataFilter.length === 0) {
                setDataFilter([]);
                setHasNoResult(true);
                setFindText(textNoFound);
            } else {
                setDataFilter(newDataFilter);
                setHasNoResult(false);
                setFindText('');
            }

            if (loading) {
                setLoading(false);
            }
        }
    }, [bookCategories, filter]);

    const applyFilter = filterArray => {
        let newDataFilter = [];
        let textNoFound = '';

        newDataFilter = filterArray;

        if (filter) {
            newDataFilter = newDataFilter.filter(item =>
                item.name.toLowerCase().includes(filter.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filter;
        }

        return { newDataFilter, textNoFound };
    };

    const hasItens = bookCategories.length > 0;
    const [hasNoResult, setHasNoResult] = useState(
        hasItens && dataFilter.length === 0
    );

    return (
        <>
            <Transition loading={loading} newDesign={true}>
                <HeaderContainer>
                    <Breadcrumb
                        newDesign={true}
                        routes={[
                            {
                                label: t('INVENTORY.inventory'),
                                to: '/Patrimony'
                            },
                            { label: t('ROUTES.config') },
                            { label: t('INVENTORY.bookCategories') }
                        ]}
                    />
                    <div className="container-headerNewDesign">
                        <h1 className={classes.titleNewDesign}>
                            {t('INVENTORY.bookCategories')}
                        </h1>
                        {GetGeneralPermissionForAction(
                            Screen.BOOKS,
                            Allow.CREATE
                        ) && (
                            <Button
                                className={classes.blueButton}
                                variant="contained"
                                color="primary"
                                style={{ marginBottom: '10px' }}
                                onClick={() => setOpenDialog(true)}
                            >
                                {t('INVENTORY.addBookCategory')}
                            </Button>
                        )}
                    </div>
                </HeaderContainer>
                <ContentContainer>
                    <Paper>
                        <div className="cabecalho">
                            {t('INVENTORY.bookCategories').toUpperCase()}
                        </div>
                        <FilterContainer>
                            <InputFilter
                                filterValue={filter}
                                setFilter={setFilter}
                                label={'INVENTORY.name'}
                                maxLength={70}
                            />
                        </FilterContainer>

                        <BookCategoryTable
                            rows={dataFilter}
                            toFirstPage={canClick}
                            headers={['id', 'name', 'description', 'books']}
                            visibleHeaders={[
                                'nome da categoria',
                                'Descrição',
                                'Quantidade de livros',
                                ''
                            ]}
                            sortedFields={['name', 'description', 'count']}
                            mainField="name"
                            fetchData={fetchData}
                            bookCategories={bookCategories}
                        />

                        {!hasItens && (
                            <div className={classes.noParticipantsMessage}>
                                <h2>{t('INVENTORY.noItensMessage')}</h2>
                                <h4>{t('INVENTORY.noItensSubMessage')}</h4>
                            </div>
                        )}
                        {hasNoResult && (
                            <div className={classes.noParticipantsMessage}>
                                <div>
                                    <Dissatisfied
                                        className={classes.notFoundIconStyle}
                                    />
                                </div>
                                <h2>{t('GENERAL.noResultsFound')}</h2>
                                <h5>{`Sua busca por "${findText}" não retornou resultados.`}</h5>
                            </div>
                        )}
                    </Paper>
                    {openDialog && (
                        <DialogBookCategory
                            open={openDialog}
                            setOpen={setOpenDialog}
                            bookCategories={bookCategories}
                            context={'new'}
                            fetchData={fetchData}
                        />
                    )}
                </ContentContainer>
                <Footer />
            </Transition>
        </>
    );
};

export default BookCategory;
