import React, { PureComponent } from 'react';
import { Page, Text, View, Image, StyleSheet, Font } from '@react-pdf/renderer';
import font from "./fonts/Roboto-Bold.ttf"
import robotoRegular from "./fonts/Roboto-Regular.ttf"
// Create styles
Font.register({ family: 'Roboto', src: font });
Font.register({ family: 'RobotoR', src: robotoRegular });

const styles = StyleSheet.create({
  page: {
    paddingBottom: 60,
    paddingTop: 20
  },
  section: {
    marginLeft: 10,
    marginRight: 10,
    paddingLeft: 10,
    paddingRight: 10,
    flexGrow: 1,
  },
  subTitle: {
    marginTop: -10,
    fontSize: 12,
    color: "#6D6F71",
  },
  season: {
    fontSize: 10,
    color: "#6D6F71",
    fontFamily: "RobotoR",
    fontStyle: "normal",
    fontWeight: "normal",
  },
  logo: {
    alignItems: "rigth",
    marginTop: -23,
  },
  image: {
    width: 55
  },
  pageNumber: {
    fontFamily: "RobotoR",
    fontStyle: "normal",
    fontWeight: "normal",
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    paddingRight: 20,
    alignItems: "rigth",
    textAlign: 'right',
    color: '#333333',
  },
  div1: {
    fontFamily: "RobotoR",
    fontStyle: "normal",
    fontWeight: "normal",
    position: "relative",
    backgroundColor: "#333333",
    color: "#fff",
    fontSize: 10,
    paddingLeft: 5,
    paddingBottom: 3,
    paddingTop: 3
  },
  div2: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    position: "relative",
    height: 70,
    color: "#6A6A6A",
    fontSize: 11,
    borderStyle: "solid",
    borderWidth: 5,
    borderColor: "#EDEDED",
    flexDirection: 'row',
    alignItems: 'center'
  },
  section2: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 24,
    lineHeight: "28px",
    color: "#333333",
  },
  section3: {
    marginTop: 10,
  },
  rubrics: {
    height: 44,
    alignItems: "center",
    borderLeft: "1 solid #EDEDED",
    paddingLeft: 22,
    paddingRight: 13
  },
  rubricsTotal: {
    height: 65,
    width: 115,
    backgroundColor: "#EDEDED",
  },
  rubricsTotalBack: {
    height: 28.5,
    width: 109,
    backgroundColor: "#fff",
    marginTop: 2.5,
    marginLeft: 5,
    paddingLeft: 5,
    paddingTop: 3
  },
  labelRubrics: {
    fontSize: 9,
    color: "#6A6A6A",
  },
  labelValues: {
    fontSize: 11,
    color: "#000000",
  },
  labelValuesPercent: {
    fontSize: 9,
    color: "#a4a4a4",
  }
});

export default class TestPrint extends PureComponent {
  // constructor(props){
  //   super(props);
  //   this.state = {
  //     data1: this.props.endDate,
  //     startDate2: this.props.startDate,
  //     Projetc2: this.props.projectInfor,
  //     endDate2: this.props.endDate,
  //     reportData2: this.props.budget,
  //     terms2:  this.props.terms,
  //   }
  // }
  // shouldComponentUpdate(){

  render() {
    const Projetc = this.props.projectInfor;
    const startDate = this.props.startDate;
    const endDate = this.props.endDate;
    const reportData = this.props.budget;
    const terms = this.props.terms;
    var endDateFormated;
    var dayEndGeneralReports = endDate.getDate()
    var monthEndGeneralReports = (endDate.getMonth() + 1)
    if (endDate.getDate() < 10) {
      dayEndGeneralReports = "0" + endDate.getDate()
    }
    if ((endDate.getMonth() + 1) < 10) {
      monthEndGeneralReports = "0" + (endDate.getMonth() + 1)
    }
    endDateFormated = dayEndGeneralReports + "/" + monthEndGeneralReports + "/" + endDate.getFullYear()

    function formattedMoney(value) {
      var valuerFormatted = value ? value.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : 0
      if (valuerFormatted !== "NaN") {
        return valuerFormatted;
      } else {
        return "0";
      }
    }

    return (
      reportData && Projetc && terms && startDate && endDateFormated && (
        <Page size="A4" style={styles.page} wrap>
          <View style={styles.section} >
            <View fixed style={styles.section2}>
              <Text>Relatório Financeiro Geral</Text>
              <View style={styles.logo}>
                <Image
                  style={styles.image}
                  source={window.location.origin + '/img/lsbd-logo.png'}
                />
              </View>
              <View style={styles.subTitle}>
                <Text>{Projetc}</Text>
                <Text style={styles.season}> Referente a {startDate} - {endDateFormated} {terms >= 2 && `(${terms} termos)`}</Text>
              </View>
            </View>
            {reportData
              ? reportData.map((a, index) => {
                return (
                  <View key={index} style={styles.section3} wrap={false}>
                    <Text style={styles.div1}>{a.rubric.toUpperCase()}</Text>
                    <View style={styles.div2}>
                      <View style={[styles.rubrics, { borderLeft: 'none' }]}>
                        <Text>Orçado (R$)</Text>
                        <Text style={styles.labelValues}>{formattedMoney(a.valueRubrics)}</Text>
                      </View>
                      <View style={styles.rubrics}>
                        <Text > Recebido (R$) </Text>
                        <Text style={styles.labelValues}>{formattedMoney(a.headingValue)}</Text>
                        <Text style={styles.labelValuesPercent}>({parseInt(((a.headingValue * 100) / a.valueRubrics)) > 0 ? ((a.headingValue * 100) / a.valueRubrics).toFixed(2) : 0}% do total)</Text>
                      </View>
                      <View style={styles.rubrics}>
                        <Text> A Receber (R$) </Text>
                        <Text style={styles.labelValues}>{formattedMoney(a.valueRubrics - a.headingValue)}</Text>
                        <Text style={styles.labelValuesPercent}>({((a.valueRubrics - a.headingValue) * 100 / a.valueRubrics) > 0 ? ((a.valueRubrics - a.headingValue) * 100 / a.valueRubrics).toFixed(2) : 0}% do total)</Text>
                        {/* <Text style={styles.labelValuesPercent}>({((a.ExpenseValue * 100) / a.valueRubrics).toFixed(2)}% do total)</Text> */}
                      </View>
                      <View style={styles.rubrics}>
                        <Text> Previsão (R$) </Text>
                        {/* <Text style={styles.labelValues}>{a.totRhcost ? moneyField((Math.round(a.valueRubrics - a.headingValue)) + (Math.round(a.headingValue - (a.ExpenseValue + a.totRhcost )))) : moneyField((Math.round(a.valueRubrics - a.headingValue)) + (Math.round(a.headingValue - a.ExpenseValue)))}</Text>  */}
                        <Text style={styles.labelValues}>{a.totRhcost > 0 ? formattedMoney((a.valueRubrics - a.headingValue) + (a.headingValue - (a.ExpenseValue + a.totRhcost))) : formattedMoney((a.valueRubrics - a.headingValue) + (a.headingValue - a.ExpenseValue))}</Text>
                      </View>
                      <View style={styles.rubricsTotal}>
                        <View style={styles.rubricsTotalBack}>
                          <Text style={styles.labelRubrics}>Gasto (R$)</Text>
                          <Text style={styles.labelValues}>{a.totRhcost > 0 ? formattedMoney(a.ExpenseValue ? (a.ExpenseValue + a.totRhcost) : a.totRhcost) : formattedMoney(a.ExpenseValue)}</Text>
                        </View>
                        <View style={styles.rubricsTotalBack}>
                          <Text style={styles.labelRubrics}> Saldo (R$)</Text>
                          <Text style={styles.labelValues}>{a.totRhcost > 0 ? formattedMoney(a.headingValue - (a.ExpenseValue + a.totRhcost)) : formattedMoney(a.headingValue - a.ExpenseValue)}</Text>
                        </View>
                      </View>
                    </View>
                  </View>
                );
              })
              : ""}
          </View>
          <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `página ${pageNumber} / ${totalPages}`)} fixed />
        </Page>
      )
    )
  }
}