import React from "react";
import "App.css";
import SideBar from "components/layout/sidebar/Sidebar";
import DashboardNavBar from "components/layout/DashboardNavBar";
import LicenseForm from './newSoftware/LicenseForm'
import Paper from "@material-ui/core/Paper";
import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";
import Breadcrumb from "components/layout/breadcrumb/Breadcrumb";

const CallEditLicense = ({ match }) => {
    const [t] = useTranslation();
    const classes = useStyles();
    return (
        <div>
            <DashboardNavBar />
            <SideBar />
            <div className="container">
            <Breadcrumb
                    routes={[
                        { label: t("INVENTORY.inventory") },
                        { label: t("INVENTORY.softwares"), to: "/Software" },
                        { label:  t("INVENTORY.editLicense")  },
                    ]}
                />
                <div className="container-header">
                    <div className="title">
                        <h1 className={classes.title}>{t("INVENTORY.editLicense")}</h1>
                </div>
                </div>
                <Paper>
                    <div className="cabecalho">
                        {t("INVENTORY.dataSoftware")}
                    </div>
                    <LicenseForm licenseId={match.params.id} context={"edit"} />
                </Paper>
            </div>
        </div>
    );
}
export default CallEditLicense;