import axios from 'axios';
import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT, HR } from '../../types';
import managementContext from './managementContext';
import managementReducer from './managementReducer';
import Cookies from 'js-cookie';

import { useMutation } from 'react-query';
import { useQuery } from 'react-query';

const ManagementState = props => {
    const initialState = {};

    const [t] = useTranslation();
    const [state, dispatch] = useReducer(managementReducer, initialState);

    return (
        <managementContext.Provider
            value={
                {
                    // useQueryJobs,
                    // useMutationJob
                }
            }
        >
            {props.children}
        </managementContext.Provider>
    );
};

export default ManagementState;
