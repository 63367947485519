import React, { useState, useEffect, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Buttons from "components/buttons/Pressed";
import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";
import Input from "components/inputs/Input";
import InventoryContext from "context/inventory/inventoryContext";
import { Redirect } from "react-router-dom";
import { dateToBR } from "utils/dates/DateToBRr";

const redirect = go => {
    if (go) return <Redirect to={`/CollaboratorsRequests`} />;
};

const DialogReqApprove = (props) => {
    var FormatDateToDataBase = require("./../../../utils/dates/FormatDateToDataBase").FormatDateToDataBase;
    const [t] = useTranslation();
    const classes = useStyles();
    const {
        open,
        setOpen,
        type,
        requisitionId,
        approverId,
        dateRequisition,
        approverName,
        context,
        requisition,
        patrimonyManager
    } = props;

    const inventoryContext = useContext(InventoryContext);
    const [values, setValues] = useState({
        observation: "",
        justification: ""

    });
    const [requiredFieldJustification, setRequiredFieldJustification] = useState(false);
    const [mustRedirect, setMustRedirect] = useState(false);


    const verifyCanSubmit = () => {
        let res = true;
        if (context === "refuse") {
            if (!values.justification) {
                res = false;
                setRequiredFieldJustification(true);
            }
        }
        return res;
    };
    const { managerApproveRequisition } = inventoryContext;

    const clear = () => {
        setValues({
            ...values,
            observation: "",
            justification: "",
        });
    }

    const handleClose = () => {
        setOpen(false);
        setRequiredFieldJustification(false);
        clear();
    };

    const handleSubmit = () => {
        const canSubmit = verifyCanSubmit();
        if (canSubmit) {
            let formData = new FormData();

            var emailDatas = []
            var auxEmail = {}

            let approveUsers = patrimonyManager.map(user => {
                return {
                    name: user.name,
                    email: user.email,
                    url: process.env.REACT_APP_FRONT
                }
            })
            approveUsers.push({
                name: requisition.requester,
                email: requisition.requesterEmail,
                url: process.env.REACT_APP_FRONT + `Requisition/${requisition.id}`
            })

            auxEmail.user = context === "approve" ? approveUsers
                : [{ name: requisition.requester, email: requisition.requesterEmail, url: process.env.REACT_APP_FRONT + `Requisition/${requisition.id}` }];
            auxEmail.type = type;
            auxEmail.date = new Date();
            auxEmail.name = requisition.requesterShortName;
            auxEmail.managerName = approverName;
            auxEmail.approve = context === "approve" ? true : false;
            auxEmail.motivationBool = context === "approve" ? values.observation.length > 0 : values.justification.length > 0;
            auxEmail.motivationData = context === "approve" ? values.observation : values.justification;
            auxEmail.motivationName = context === "approve" ? "Observação" : "Motivo";

            emailDatas.push(auxEmail)

            var auxEmail = {}

            formData.append(`approverId`, approverId)
            formData.append(`dateCreated`, FormatDateToDataBase(new Date()));
            formData.append(`approverResponse`, context === "approve" ? values.observation : values.justification);
            formData.append(`approved`, context === "approve" ? true : false)

            managerApproveRequisition(requisitionId, formData, emailDatas)
            setOpen(false);
            clear();
            setMustRedirect(true);
        }
    };

    const handleChange = (name, value) => {
        setRequiredFieldJustification(false);
        setValues({ ...values, [name]: value });
    };

    return (
        <React.Fragment>
            {redirect(mustRedirect)}
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                aria-labelledby="form-dialog-title"
                msg="mensagem"
            >
                <DialogTitle className={classes.actionsTitle}>
                    <span style={{ fontWeight: "bold" }}>
                        {context === "approve"
                            ? t("INVENTORY.approveRequisition")
                            : t("INVENTORY.refuseRequisition")
                        }
                    </span>
                    <IconButton
                        style={{ float: "right", marginTop: -10, marginRight: -17 }}
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Grid container >
                            {context === "approve" ? t("INVENTORY.approveRequisitionMessage") : t("INVENTORY.refuseRequisitionMessage")}
                        </Grid>
                        <Grid container style={{ paddingTop: "20px" }}>
                            <Grid item xs={12} sm={4}>
                                <b>{t("GENERAL.date").toUpperCase()}</b>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <b>{t("INVENTORY.requestType").toUpperCase()}</b>
                            </Grid>
                        </Grid>
                        <Grid container style={{ paddingBottom: "10px" }}>
                            <Grid item xs={12} sm={4}>
                                {dateToBR(dateRequisition)}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {type}
                            </Grid>
                        </Grid>
                        <Input
                            error={requiredFieldJustification}
                            multiline
                            fullWidth
                            variant="outlined"
                            rows="4"
                            value={context === "approve" ? values.observation : values.justification}
                            valueName={context === "approve" ? "observation" : "justification"}
                            label={context === "approve" ? t("INVENTORY.observation") : t("INVENTORY.justification") + "*"}
                            margin="normal"
                            maxLength={200}
                            helperText={requiredFieldJustification ? t("GENERAL.requiredField") : context === "approve" ? `${values.observation.length > 0 ? values.observation.length : 0}/200` : `${values.justification.length > 0 ? values.justification.length : 0}/200`}
                            handleChange={handleChange}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Buttons
                        onClick={handleClose}
                        tipo="BN-noback"
                        conteudo={t("GENERAL.cancel").toLocaleUpperCase()}
                    />
                    <Buttons
                        onClick={handleSubmit}
                        tipo="BN-blue"
                        conteudo={context === "approve" ? t("GENERAL.approve").toLocaleUpperCase() : t("GENERAL.refuse").toLocaleUpperCase()}
                    />
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default DialogReqApprove;