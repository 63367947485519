import axios from 'axios';
import { useMutation } from 'react-query';
import Cookies from 'js-cookie';

export const useMutationVerifyPassword = (
    handleSubmit,
    setOpen,
    setInvalidPassword
) => {
    const mutation = useMutation({
        mutationFn: async obj =>
            await axios.post(
                process.env.REACT_APP_SERVER + `api/auth/VerifyPassword`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            ),
        onSuccess: res => {
            handleSubmit();
            setOpen(false);
        },
        onError: () => {
            setInvalidPassword(true);
        }
    });

    return mutation;
};
