/* eslint-disable */
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Dissatisfied from '@material-ui/icons/SentimentVeryDissatisfied';
import Footer from 'components/layout/Footer';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import CollaboratorsTable from './CollaboratorsTable';
import CollaboratorsContext from 'context/hrModule/collaborator/collaboratorContext';
import Transition from 'components/layout/transition/Transition';
import Breadcrumb from 'components/layout/breadcrumb/Breadcrumb';
import InputFilter from 'components/inputs/InputFilter';
import FilterContainer from 'components/containers/FilterContainer';
import AdvancedSearchDialog from './AdvancedSearchDialog';
import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';
import { Screen, Allow } from 'global/constants';

const Collaborators = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const collaboratorsContext = useContext(CollaboratorsContext);
    const [findText, setFindText] = useState('');
    const [filterName, setFilterName] = useState('');
    const [filterContractType, setFilterContractType] = useState('');
    const [filterStatus, setFilterStatus] = useState('Ativo');
    const [filterCpf, setFilterCpf] = useState('');
    const [filterEmail, setFilterEmail] = useState('');
    const [openAdvancedSearch, setOpenAdvancedSearch] = useState(false);
    const [dataFilter, setDataFilter] = useState([]);
    const [canClick, setCanClick] = useState(false);

    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const advancedSearch = (name, status, email, contractType, cpf) => {
        setFilterName(name);
        setFilterStatus(status);
        setFilterEmail(email);
        setFilterContractType(contractType);
        setFilterCpf(cpf);
    };

    const status = [
        {
            value: 'ALL',
            label: 'Todos'
        },
        {
            value: 'Ativo',
            label: 'Habilitado'
        },
        {
            value: 'Inativo',
            label: 'Desabilitado'
        }
    ];

    const contractType = [
        {
            value: 'ALL',
            label: 'Todos'
        },
        {
            value: 'Bolsista',
            label: 'Bolsista'
        },
        {
            value: 'CLT',
            label: 'CLT'
        },
        {
            value: 'Estágio',
            label: 'Estágio'
        },
        {
            value: 'Pessoa Jurídica',
            label: 'Pessoa Jurídica'
        },
        {
            value: 'Pesquisador',
            label: 'Pesquisador'
        },
        {
            value: 'Professor',
            label: 'Professor'
        },
        {
            value: 'Residente',
            label: 'Residente'
        },
        {
            value: 'Serviços Prestados',
            label: 'Serviços Prestados'
        }
    ];

    const {
        loading,
        getCollaborators,
        getCollaboratorsOfApprover,
        collaborators
    } = collaboratorsContext;

    async function fetchData() {
        if (
            GetGeneralPermissionForAction(
                Screen.COLLABORATORS,
                Allow.IS_MANAGER
            )
        ) {
            getCollaboratorsOfApprover(auth.id);
        } else {
            getCollaborators();
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (
            filterName ||
            filterStatus ||
            filterContractType ||
            filterCpf ||
            filterEmail
        ) {
            setCanClick(true);
        } else {
            setCanClick(false);
        }
    }, [filterName, filterStatus, filterContractType, filterCpf, filterEmail]);

    useEffect(() => {
        if (collaborators && collaborators.length) {
            const { newDataFilter, textNoFound } = applyFilter(collaborators);

            if (newDataFilter.length === 0) {
                setDataFilter([]);
                setHasNoResult(true);
                setFindText(textNoFound);
            } else {
                setDataFilter(newDataFilter);
                setHasNoResult(false);
                setFindText('');
            }
        }
    }, [
        collaborators,
        filterName,
        filterStatus,
        filterContractType,
        filterCpf,
        filterEmail
    ]);

    const applyFilter = collabs => {
        let newDataFilter = [];
        let textNoFound = '';
        newDataFilter = collabs;

        if (filterName) {
            newDataFilter = newDataFilter.filter(item =>
                item.name.toLowerCase().includes(filterName.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterName;
        }

        if (filterStatus && filterStatus !== 'ALL') {
            let filter = true;
            if (filterStatus === 'Ativo') {
                filter = true;
            } else if (filterStatus === 'Inativo') {
                filter = false;
            }
            newDataFilter = newDataFilter.filter(
                item => item.isEnabled === filter
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterStatus;
        }

        if (filterContractType && filterContractType !== 'ALL') {
            let filter = '';
            if (filterContractType === 'Estágio') {
                filter = 'Estagiário';
            } else {
                filter = filterContractType;
            }

            newDataFilter = newDataFilter.filter(
                item => item.ruleSet && item.ruleSet.startsWith(filter)
            );

            if (
                (!textNoFound && newDataFilter.length === 0) ||
                newDataFilter.length > 1
            )
                textNoFound = filter;
        }
        if (filterCpf) {
            newDataFilter = newDataFilter.filter(item =>
                item.cpf
                    .replace(/[^a-zA-Z0-9 ]/g, '')
                    .toLowerCase()
                    .startsWith(filterCpf.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterCpf;
        }

        if (filterEmail) {
            newDataFilter = newDataFilter.filter(item =>
                item.email.toLowerCase().startsWith(filterEmail.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterEmail;
        }
        return { newDataFilter, textNoFound };
    };

    const hasItens = collaborators.length > 0;
    const [hasNoResult, setHasNoResult] = useState(
        hasItens && dataFilter.length === 0
    );

    return (
        <Transition loading={loading} newDesign={true}>
            <div className="headerNewDesign">
                <Breadcrumb
                    routes={[
                        {
                            label: t('HR.peopleManagement'),
                            to: '/HumanResources'
                        },
                        { label: t('HR.collaborators') }
                    ]}
                />
                <div className="container-headerNewDesign">
                    <h1 className={classes.titleNewDesign}>
                        {t('HR.collaborators')}
                    </h1>
                </div>
            </div>

            <div className="containerNewDesign">
                <Paper>
                    <div className="cabecalho">
                        {t('HR.collaborators').toUpperCase()}
                    </div>
                    <FilterContainer>
                        <InputFilter
                            filterValue={filterName}
                            setFilter={setFilterName}
                            label={'GENERAL.name'}
                            maxLength={60}
                        />

                        <InputFilter
                            filterValue={filterContractType}
                            setFilter={setFilterContractType}
                            label={'HR.contractType'}
                            isSelect
                            arraySelected={contractType}
                        />

                        <InputFilter
                            filterValue={filterStatus}
                            setFilter={setFilterStatus}
                            label={'GENERAL.systemAccess'}
                            isSelect
                            arraySelected={status}
                        />

                        <Button onClick={() => setOpenAdvancedSearch(true)}>
                            {t('INVENTORY.advancedSearch')}
                        </Button>
                    </FilterContainer>

                    <React.Fragment>
                        <CollaboratorsTable
                            rows={dataFilter}
                            toFirstPage={canClick}
                            headers={[
                                'id',
                                'name',
                                'cpf',
                                'email',
                                'ruleSet',
                                'isEnabled',
                                'profileName',
                                'imagePath'
                            ]}
                            visibleHeaders={[
                                '#',
                                'Nome',
                                'CPF',
                                'Email',
                                'Regime',
                                'Acesso ao Sistema',
                                ''
                            ]}
                            sortedFields={[
                                '',
                                'name',
                                'cpf',
                                'email',
                                'ruleSet',
                                'isEnabled'
                            ]}
                            mainField="name"
                        />
                        {!hasItens && (
                            <div className={classes.noParticipantsMessage}>
                                <h2>{t('HR.noCollaboratorsToBeShown')}</h2>
                                <h4>
                                    {t('HR.collaboratorsShouldBeListedHere')}
                                </h4>
                            </div>
                        )}
                        {hasNoResult && (
                            <div className={classes.noParticipantsMessage}>
                                <div>
                                    <Dissatisfied
                                        className={classes.notFoundIconStyle}
                                    />
                                </div>
                                <h2>{t('GENERAL.noResultsFound')}</h2>
                                <h5>{`Sua busca por "${findText}" não retornou resultados.`}</h5>
                            </div>
                        )}
                    </React.Fragment>
                </Paper>
            </div>
            <Footer />
            <AdvancedSearchDialog
                open={openAdvancedSearch}
                setOpen={setOpenAdvancedSearch}
                apply={advancedSearch}
            />
        </Transition>
    );
};

export default Collaborators;
