import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Spinner from "components/layout/spinners/Index";
import HRCostsContext from "context/hrcosts/hrCostsContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import "components/profiles/css/FormStyle.css";
import { useStyles } from "components/layout/CommonStyles";
import Add from "@material-ui/icons/Add";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import MySnackbarContentWrapper from "components/layout/Message";
import AllowancesContext from "context/allowance/allowanceContext";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import "moment/locale/pt-br";
import UsersParticipantsTable from "./UsersToParticipateTable";
import SideBar from "../../layout/sidebar/Sidebar";
import Search from "components/layout/searchIndex";
import ProjectsContext from "context/projects/projectsContext";
import DateFnsUtils from "@date-io/date-fns";
import DashboardNavBar from "../../layout/DashboardNavBar";

function NewParticipant({ match }) {
  const classes = useStyles();
  const [t] = useTranslation();
  const hrCostsContext = useContext(HRCostsContext);
  const { response, responseType } = hrCostsContext;
  const allowanceContext = useContext(AllowancesContext);

  const projectsContext = useContext(ProjectsContext);
  const {
    loading,
    project,
    getProject,
    participants,
    getParticipants
  } = projectsContext;

  const [state, setState] = useState({
    name: "",
    gpf: "",
    baseYear: "",
    startDate: "",
    endDate: ""
  });

  const {
    allowanceResponse,
    allowanceResponseType,
    resetAllowanceMessage
  } = allowanceContext;

  const [snackBar, setSnackBar] = useState({
    open: false,
    kind: "",
    content: ""
  });

  const handleSnackClose = () => {
    setSnackBar({ ...snackBar, open: false });
    resetAllowanceMessage();
  };

  const transitions = useTransition(loading, null, {
    from: { opacity: 0 },
    enter: {
      opacity: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "calc(100vh - 80px)"
    },
    leave: { opacity: 0 }
  });

  const handleDates = name => e => {
    setState({
      ...state,
      [name]: e
    });
  };

  useEffect(() => {
    getProject(match.params.id);
    getParticipants(match.params.id);

    if (project.information) {
      setState({
        name: project.information.name,
        gpf: project.information.gpf,
        baseYear: project.information.baseyear,
        startDate: project.information.startdate,
        endDate: project.information.enddate
      });
    }
  }, [JSON.stringify(project), Object.keys(participants).length]);


  const handleSubmit = () => {
    document.getElementById("submitButton").click();
  }

  return transitions.map(({ item, key, props }) =>
    item ? (
      <animated.div key={key} style={props}>
        <Spinner />
      </animated.div>
    ) : (
        <animated.div key={key} style={props}>
          <DashboardNavBar/> 
          <SideBar />
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={snackBar.open}
            onClose={handleSnackClose}
            TransitionComponent={Slide}
            transitionDuration={{ enter: 500, exit: 500 }}
            ContentProps={{
              "aria-describedby": "message-id"
            }}
            autoHideDuration={2000}
          >
            <MySnackbarContentWrapper
              onClose={handleSnackClose}
              variant={
                responseType !== ""
                  ? responseType.toLowerCase()
                  : allowanceResponseType.toLowerCase()
              }
              message={
                <span id="message-id">
                  {response !== "" ? response : allowanceResponse}
                </span>
              }
            />
          </Snackbar>
          <div className="container">
            <Breadcrumbs
              className={classes.breadCrumbs}
              aria-label="breadcrumb"
              separator={<NavigateNextIcon fontSize="small" />}
            >
              <Typography>{t("PARTICIPANTS.execution")}</Typography>
              <Typography>{t("PARTICIPANTS.labor")}</Typography>
              <Link
                className={classes.link}
                to={`/execution/projects/${match.params.id}/edit`}
              >
                {t("PROJECTS.project")}
              </Link>
              <Typography>{t("PARTICIPANTS.addParticipant")}</Typography>
            </Breadcrumbs>
            <div className="container-header">
              <div className="title">
                <h1 className={classes.title}>
                  {t("PARTICIPANTS.addParticipant")}
                </h1>
              </div>
              <Button
                className={classes.blueButton}
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                <div className={classes.buttonIcon}>
                  <Add />
                </div>
                {t("Adicionar").toUpperCase()}
              </Button>
            </div>
            <form id="formUsers">
              <Paper className={classes.root}>
                <div className={classes.header}>
                  {t("informações do projeto").toUpperCase()}
                </div>
                <div className={classes.bodyForm}>
                  <Grid container>
                    <Grid container item spacing={2} md={12}>
                      <Grid item xs={2}>
                        <TextField
                          disabled
                          className={classes.textField}
                          margin="normal"
                          id="GPF"
                          label={t("GPF")}
                          name="GPF"
                          value={state.gpf}
                          autoFocus
                          InputProps={{
                            readOnly: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          disabled
                          className={classes.textField}
                          margin="normal"
                          id="name"
                          label="Nome do Projeto"
                          name="name"
                          value={state.name}
                          autoFocus
                          InputProps={{
                            readOnly: true
                          }}
                        />
                      </Grid>
                     
                        <Grid item xs={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disabled
                            disableToolbar
                            autoOk={true}
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="startDate"
                            label={t("GENERAL.startDate")}
                            value={state.startDate}
                            onChange={handleDates("startDate")}
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                          />
                          </MuiPickersUtilsProvider>

                        </Grid>
                        <Grid item xs={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disabled
                            disableToolbar
                            autoOk={true}
                            minDate={state.startDate}
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="endDate"
                            label={t("GENERAL.endDate")}
                            value={state.endDate}
                            minDateMessage={
                              "A data não deve ser anterior à data mínima"
                            }
                            onChange={handleDates("endDate")}
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                          />
                           </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </form>
            <br />
            <br />
          </div>
          <UsersParticipantsTable participants={participants} />
        </animated.div>
      )
  );
}

export default NewParticipant;
