import DateFnsUtils from "@date-io/date-fns";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from '@material-ui/icons/Close';
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Buttons from "components/buttons/Pressed";
import { useStyles } from "components/layout/CommonStyles";
import AllowancesContext from "context/allowance/allowanceContext";
import ProjectsContext from "context/projects/projectsContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";


const TableActionsActivity = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const { activityId, id, participant, mode, permission } = props;
    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [dialogMode, setDialogMode] = useState(t("GENERAL.viewMode"));
    const projectsContext = useContext(ProjectsContext);
    const allowanceContext = useContext(AllowancesContext);
    const { deleteParticipantActivity, updateParticipantActivity } = projectsContext;
    const {
        getAllPermission,
        allAllowances
    } = allowanceContext;
    const [state, setState] = useState({
        imagePath: "",
        name: "",
        startDate: "",
        endDate: "",
        hours: "",
        performance: "",
        activityStartDate: new Date(),
        activityEndDate: new Date(),
    });

    React.useEffect(() => {
        getAllPermission()
    }, [allAllowances.length, JSON.stringify(allAllowances)]);

    useEffect(() => {
        if (participant) {

            var startdatewithtimezone = participant.startDate ? (new Date(participant.startDate)) : new Date(participant.activityStartDate);
            startdatewithtimezone.setMinutes(startdatewithtimezone.getMinutes() + startdatewithtimezone.getTimezoneOffset())

            var enddatewithtimezone = participant.endDate ? (new Date(participant.endDate)) : new Date(participant.activityEndDate);
            enddatewithtimezone.setMinutes(enddatewithtimezone.getMinutes() + enddatewithtimezone.getTimezoneOffset())

            var activityStartdatetimezone = new Date(participant.activityStartDate);
            activityStartdatetimezone.setMinutes(activityStartdatetimezone.getMinutes() + activityStartdatetimezone.getTimezoneOffset())

            var activityEnddatetimezone = new Date(participant.activityEndDate);
            activityEnddatetimezone.setMinutes(activityEnddatetimezone.getMinutes() + activityEnddatetimezone.getTimezoneOffset())

            setState({
                imagePath: participant.imagePath,
                name: participant.participantName,
                startDate: startdatewithtimezone,
                endDate: enddatewithtimezone,
                hours: participant.hours,
                performance: participant.performance,
                activityStartDate: activityStartdatetimezone,
                activityEndDate: activityEnddatetimezone,
                participantId: participant.participantId,
            });
        }
    }, [participant]);

    const handleClickOpen = (type) => {
        setDialogMode(type);
        setOpen(true);
    };

    const handleClickOpenDelete = () => {
        setOpenDelete(true);
    };

    const handleClose = () => {
        setOpen(false);
        setOpenDelete(false);
    };

    const handleEdit = () => {
        const participant = {
            id: id,
            hours: state.hours ? parseInt(state.hours) : 0,
            startDate: state.startDate ? state.startDate : null,
            endDate: state.endDate ? state.endDate : null,
            activityId: activityId,
            participantId: state.participantId,
            description: state.performance,
        };
        updateParticipantActivity(participant, activityId);
        setOpen(false);
    };

    const handleDelete = () => {
        deleteParticipantActivity(id, activityId);
        setOpenDelete(false);
    };

    const handleFieldChange = (field) => event => {
        if (event && event.target) {
            setState({ ...state, [field]: event.target.value });
        } else if (event) {
            setState({ ...state, [field]: event });
        } else {
            setState({ ...state, [field]: "" });
        }
    };

    const handleFieldHoursChange = (field) => event => {
        if (event.target && !event.target.value.includes(" ") && !isNaN(event.target.value)) {
            setState({ ...state, [field]: event.target.value });
        }
    }

    const getPermission = (screen, allowance) => {
        for (let i = 0; i < allAllowances.length; i++) {
            if (allAllowances[i].resource === screen) {
                return allAllowances[i]["standardauthorizationoption"][allowance]
            }
        }
    }

    return (
        <div>
            {
                true && allAllowances.length > 0 && getPermission("PARTICIPANTS", "allowRead") &&
                <Tooltip title="Visualizar" aria-label="details" placement="top">
                    <IconButton className={classes.actionsIcons} onClick={() => handleClickOpen(t("GENERAL.viewMode"))}>
                        <Visibility />
                    </IconButton>
                </Tooltip>
            }
            {mode === t("GENERAL.editMode") && allAllowances.length > 0 && getPermission("PARTICIPANTS", "allowUpdate") &&
                <Tooltip title="Editar" aria-label="edit" placement="top">
                    <IconButton className={classes.actionsIcons} onClick={() => handleClickOpen(t("GENERAL.editMode"))}>
                        <Edit />
                    </IconButton>
                </Tooltip>
            }
            {mode === t("GENERAL.editMode") && allAllowances.length > 0 && getPermission("PARTICIPANTS", "allowDelete") &&
                <Tooltip title="Deletar" aria-label="delete" placement="top">
                    <IconButton className={classes.actionsIcons} onClick={handleClickOpenDelete}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            }
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                msg="mensagem"
            >
                <DialogTitle className={classes.actionsTitle} style={{ width: 'auto' }}>
                    {dialogMode === t("GENERAL.editMode") ?
                        t("ACTIVITIES.allocateEdit").toLocaleUpperCase() :
                        t("ACTIVITIES.allocateView").toLocaleUpperCase()
                    }
                    <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Grid container>
                            <Grid container item spacing={2} md={3} lg={3}>
                                <div className={classes.avatarParentDialog}>
                                    <div
                                        className="avatar"
                                        style={{
                                            backgroundImage: `url(${process.env.REACT_APP_IMGSERVER + state.imagePath})`,
                                            borderWidth: '7px',
                                        }}
                                    ></div>
                                </div>
                            </Grid>
                            <Grid container item spacing={2} md={9} lg={9}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="ActionParticipantName"
                                        value={state.name}
                                        label={t("PROFILES.nameLabel")}
                                        margin="normal"
                                        disabled
                                    />
                                </Grid>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid item xs={5}>
                                        <KeyboardDatePicker
                                            className={classes.textField}
                                            disableToolbar
                                            autoOk={true}
                                            minDate={state.activityStartDate}
                                            maxDate={state.activityEndDate}
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            margin="normal"
                                            label={"Inicio"}
                                            value={state.startDate}
                                            onChange={handleFieldChange("startDate")}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date"
                                            }}
                                            disabled={dialogMode === t("GENERAL.viewMode")}
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <KeyboardDatePicker
                                            className={classes.textField}
                                            disableToolbar
                                            autoOk={true}
                                            minDate={state.startDate}
                                            maxDate={state.activityEndDate}
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            margin="normal"
                                            label={"Fim"}
                                            value={state.endDate}
                                            onChange={handleFieldChange("endDate")}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date"
                                            }}
                                            minDateMessage={t("GENERAL.minDateMessage")}
                                            disabled={dialogMode === t("GENERAL.viewMode")}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                                <Grid item xs={2}>
                                    <TextField
                                        value={state.hours}
                                        label={"Horas"}
                                        margin="normal"
                                        onChange={handleFieldHoursChange("hours")}
                                        disabled={dialogMode === t("GENERAL.viewMode")}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        multiline
                                        fullWidth
                                        variant="outlined"
                                        rows="3"
                                        value={state.performance}
                                        label={t("ACTIVITIES.performanceParticipant")}
                                        margin="normal"
                                        onChange={handleFieldChange("performance")}
                                        disabled={dialogMode === t("GENERAL.viewMode")}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Buttons
                        onClick={handleClose}
                        tipo="BN-noback"
                        conteudo={
                            dialogMode === t("GENERAL.editMode") ?
                                t("GENERAL.cancel").toLocaleUpperCase() :
                                t("GENERAL.close").toLocaleUpperCase()
                        }
                    />
                    {dialogMode === t("GENERAL.editMode") &&
                        <Buttons
                            onClick={handleEdit}
                            tipo="BN-blue"
                            conteudo={t("GENERAL.update").toLocaleUpperCase()}
                        />
                    }
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDelete}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                msg="mensagem"
            >
                <DialogTitle className={classes.actionsTitle}>
                    {t("GENERAL.notification")}
                    <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {`Você deseja realmente deletar o participante ${state.name} da atividade?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Buttons
                        onClick={handleClose}
                        tipo="BN-noback"
                        conteudo={t("GENERAL.cancel")}
                    />
                    <Buttons
                        onClick={handleDelete}
                        tipo="BN-blue"
                        conteudo={t("GENERAL.delete")}
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default TableActionsActivity;