/* eslint-disable */
import React, { useContext, useEffect, useState, useRef } from "react";
import ReactToPrint from "react-to-print";
import Buttons from "components/buttons/Pressed";
import CollaboratorsContext from "context/hrModule/collaborator/collaboratorContext";
import BirthdaysToPrint from "./BirthdaysToPrint";
import { FormatDateToFront } from "utils/dates/FormatDateToFront";
import { ChunkArray } from "utils/general/ChunkArray";

import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";

import { screenStyles } from "./Styles"

const Birthdays = (props) => {
    const classes = useStyles();
    const styles = screenStyles();
    const [t] = useTranslation();
    const collaboratorsContext = useContext(CollaboratorsContext);
    const printRef = useRef();
    const [monthBirthdays, setMonthBirthdays] = useState(null);
    
    const { month } = props;

    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }

    const {
        setLoading,
        setLoadingFalse,
        getBirthdays,
        birthdays
    } = collaboratorsContext;


    async function fetchData() {
        await setLoading();
        await getBirthdays();
        await setLoadingFalse();
    }

    useEffect(() => {
        fetchData();
    }, []);
    
    useEffect(() => {
        if (birthdays ) {
            let monthToPrint = (month === "-1" ? new Date().getMonth() : month);
            let birthdaysArray = birthdays.filter(x => parseInt(FormatDateToFront(x.dateOfBirth).getMonth()) === parseInt(monthToPrint) )
            .sort(
                (a,b) => {
                    let aDate = FormatDateToFront(a["dateOfBirth"]);
                    let bDate = FormatDateToFront(b["dateOfBirth"]);
                    let sort = new Date( new Date().getFullYear(),bDate.getMonth(), bDate.getDate()) - new Date(new Date().getFullYear(),aDate.getMonth(),aDate.getDate());
                    
                    if (sort > 0){
                        return -1;
                    }
                    if (sort < 0){
                        return 1;   
                    }
                        return 0;
                    }
                )
            birthdaysArray = ChunkArray(birthdaysArray, 13)
            setMonthBirthdays(birthdaysArray);
        }
    }, [birthdays]);
    // item.dateOfBirth && FormatDateToFront(item.dateOfBirth).getMonth() === filterMonth

    return (
        <>
            <div className={styles.container}>
                <div className={styles.buttonWrapper}>
                    <Buttons
                        onClick={() => window.close()}
                        tipo="BN-noback"
                        conteudo={t("GENERAL.close").toUpperCase()}
                    />
                    <ReactToPrint
                        trigger={() => <Buttons
                            tipo="BN-blue"
                            conteudo={t("GENERAL.print").toUpperCase()}
                        />}
                        content={() => printRef.current}
                        bodyClass={styles.printableWrapper}
                    />

                </div>
                    <BirthdaysToPrint birthdays={monthBirthdays} month={month} ref={printRef}/>
            </div>

        </>

    );
};

export default Birthdays;