export const patrimonyNotifications = [
    {
        event: "NEW_EQUIPMENT_EMAIL",
        allowSend: false,
        automatic: false
    },
    {
        event: "NEW_BOOK_EMAIL",
        allowSend: false,
        automatic: false
    },
    {
        event: "NEW_SOFTWARE_EMAIL",
        allowSend: false,
        automatic: false
    },
    {
        event: "EQUIPMENT_ON_NEW_RESPONSABILITY",
        allowSend: true,
        automatic: true
    },
    {
        event: "BOOK_ON_NEW_ADMIN_RESPONSABILITY",
        allowSend: true,
        automatic: true
        
    },
    {
        event: "SOFTWARE_ON_NEW_RESPONSABILITY",
        allowSend: true,
        automatic: true
    },
    {
        event: "LICENSE_TO_EXPIRE",
        allowSend: false,
        automatic: false
    },
]