import i18n from 'i18n';

export const AllowanceReasonTypeArray = [
    {
        value: 'ALL',
        label: i18n.t('GENERAL.allMasculine')
    },
    {
        value: 'Total',
        label: i18n.t('GENERAL.total')
    },
    {
        value: 'Parcial',
        label: i18n.t('GENERAL.parcial')
    }
];
