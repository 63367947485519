/* eslint-disable */
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CollaboratorContext from 'context/hrModule/collaborator/collaboratorContext';
import React, { useContext, useEffect, useState } from 'react';
import history from 'history.js';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import Grid from '@material-ui/core/Grid';
import GreyContainer from 'components/containers/GreyContainer';
import Input from 'components/inputs/Input';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import DefaultInfo from 'components/Infos/DefaultInfo';
import Transition from 'components/layout/transition/Transition';
import Buttons from 'components/buttons/Pressed';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import DialogConfirmation from 'components/dialogs/DialogConfirmation';
import { ConvertMilliInHourMin } from 'utils/dates/ConvertMilliInHourMin1';
import DialogConfirmClockRecord from './DialogConfirmClockRecord';
import DialogCancelOperation from 'components/dialogs/DialogCancelOperation';
import { useStyles as useClockRecordStyles } from './styles.js';
import { useMutationRegisterInREP } from 'hooks/DataFetching/HR/Collaborator/useMutationRegisterInREP';
import { useMutationRemoveFingerInREP } from 'hooks/DataFetching/HR/Collaborator/useMutationRemoveFingerInREP';
import CheckedInfo from 'components/Infos/CheckedInfo';

const ClockRecordForm = props => {
    const classes = useStyles();
    const styles = useClockRecordStyles();
    const [t] = useTranslation();
    const collaboratorContext = useContext(CollaboratorContext);
    const { id, userId, context, ...others } = props;
    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const [workingHoursContractsArray, setWorkingHoursContractsArray] =
        useState([]);
    const [open, setOpen] = useState(false);
    const [openConfirmationREP, setOpenConfirmationREP] = useState(false);
    const [requiredObservation, setRequiredObservation] = useState(false);
    const [requiredWorkingHoursContract, setRequiredWorkingHoursContract] =
        useState(false);
    const [requiredClockRecordType, setRequiredClockRecordType] =
        useState(false);
    const [openCancelDialog, setOpenCancelDialog] = useState(false);
    const [hasChange, setHasChange] = useState(false);

    const {
        loading,
        setLoading,
        setLoadingFalse,
        rhWorkingHoursContracts,
        getWorkingHoursContracts,
        clockInInfo,
        getClockInByUser,
        updateClockRecord
    } = collaboratorContext;

    const [values, setValues] = useState({
        id: '',
        name: '',
        workingHoursContract: {},
        canClockIn: false,
        canRegisterActivity: false,
        clockRecordType: '',
        registeredOnRep: false,
        hasFingerId: false,
        isCLT: false,
        workingHoursContractId: 0,
        dailyWorkload: '-',
        minIntervalLunch: '-',
        maxIntervalLunch: '-',
        minEntryHour: '-',
        minLunchHour: '-',
        minLeaveHour: '-',
        maxEntryHour: '-',
        maxLunchHour: '-',
        maxLeaveHour: '-',
        observation: ''
    });

    const clockRecordTypeArray = [
        {
            label: t('HR.remote'),
            value: 'REMOTE'
        },
        {
            label: t('HR.physical'),
            value: 'PHYSICAL'
        }
    ];

    async function fetchData(userId) {
        await setLoading();
        await getWorkingHoursContracts();
        await getClockInByUser(userId);
        setLoadingFalse();
    }

    const mutationRep = useMutationRegisterInREP(
        setOpenConfirmationREP,
        fetchData,
        userId
    );

    const mutationRemoveFingerRep = useMutationRemoveFingerInREP(
        setOpenConfirmationREP,
        fetchData,
        userId
    );

    const cleanFields = () => {
        setValues({
            ...values,
            id: '',
            name: '',
            workingHoursContract: {},
            canClockIn: false,
            canRegisterActivity: false,
            workingHoursContractId: 0,
            registeredOnRep: false,
            hasFingerId: false,
            isCLT: false,
            dailyWorkload: '-',
            minIntervalLunch: '-',
            maxIntervalLunch: '-',
            minEntryHour: '-',
            minLunchHour: '-',
            minLeaveHour: '-',
            maxEntryHour: '-',
            maxLunchHour: '-',
            maxLeaveHour: '-',
            observation: ''
        });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        cleanFields();
        fetchData(userId);
    }, []);

    useEffect(() => {
        let aux = [];
        rhWorkingHoursContracts.forEach(workingHoursContract => {
            aux.push({
                label: workingHoursContract.name,
                value: workingHoursContract.id
            });
        });
        setWorkingHoursContractsArray(aux);
    }, [rhWorkingHoursContracts, rhWorkingHoursContracts.length > 0]);

    useEffect(() => {
        if (
            clockInInfo &&
            Object.keys(clockInInfo).length !== 0 &&
            rhWorkingHoursContracts.length > 0
        ) {
            let workingHoursContract = rhWorkingHoursContracts.find(
                x => x.id === clockInInfo.workingHoursContractId
            );
            if (clockInInfo.workingHoursContractId !== null) {
                setValues({
                    // ...values,
                    name: clockInInfo.name ? clockInInfo.name : '',
                    canClockIn:
                        clockInInfo.clocksIn != null
                            ? clockInInfo.clocksIn
                            : false,
                    canRegisterActivity:
                        clockInInfo.registersActivity != null
                            ? clockInInfo.registersActivity
                            : false,
                    clockRecordType: clockInInfo.clockRecordType
                        ? clockInInfo.clockRecordType
                        : '',
                    registeredOnRep:
                        clockInInfo.registeredOnRep != null
                            ? clockInInfo.registeredOnRep
                            : false,
                    hasFingerId:
                        clockInInfo.hasFingerId != null
                            ? clockInInfo.hasFingerId
                            : false,
                    isCLT:
                        clockInInfo.isCLT != null ? clockInInfo.isCLT : false,
                    workingHoursContractId: clockInInfo.workingHoursContractId
                        ? clockInInfo.workingHoursContractId
                        : 0,
                    observation: clockInInfo.observation
                        ? clockInInfo.observation
                        : '',
                    workingHoursContract: workingHoursContract,
                    dailyWorkload: workingHoursContract.workingHours
                        ? ConvertMilliInHourMin(
                              workingHoursContract.workingHours
                          )
                        : '-',
                    minIntervalLunch: workingHoursContract.minLunchBreakTime
                        ? ConvertMilliInHourMin(
                              workingHoursContract.minLunchBreakTime
                          )
                        : '-',
                    maxIntervalLunch: workingHoursContract.maxLunchBreakTime
                        ? ConvertMilliInHourMin(
                              workingHoursContract.maxLunchBreakTime
                          )
                        : '-',
                    minEntryHour: workingHoursContract.minStartTime
                        ? ConvertMilliInHourMin(
                              workingHoursContract.minStartTime
                          )
                        : '-',
                    maxEntryHour: workingHoursContract.maxStartTime
                        ? ConvertMilliInHourMin(
                              workingHoursContract.maxStartTime
                          )
                        : '-',
                    minLeaveHour: workingHoursContract.minEndTime
                        ? ConvertMilliInHourMin(workingHoursContract.minEndTime)
                        : '-',
                    maxLeaveHour: workingHoursContract.maxEndTime
                        ? ConvertMilliInHourMin(workingHoursContract.maxEndTime)
                        : '-',
                    minLunchHour: workingHoursContract.minLunchBreakStart
                        ? ConvertMilliInHourMin(
                              workingHoursContract.minLunchBreakStart
                          )
                        : '-',
                    maxLunchHour: workingHoursContract.maxLunchBreakEnd
                        ? ConvertMilliInHourMin(
                              workingHoursContract.maxLunchBreakEnd
                          )
                        : '-'
                });
            } else {
                setValues({
                    ...values,
                    name: clockInInfo.name ? clockInInfo.name : '',
                    canClockIn:
                        clockInInfo.clocksIn != null
                            ? clockInInfo.clocksIn
                            : false,
                    clockRecordType: clockInInfo.clockRecordType
                        ? clockInInfo.clockRecordType
                        : '',
                    registeredOnRep:
                        clockInInfo.registeredOnRep != null
                            ? clockInInfo.registeredOnRep
                            : false,
                    hasFingerId:
                        clockInInfo.hasFingerId != null
                            ? clockInInfo.hasFingerId
                            : false,
                    isCLT:
                        clockInInfo.isCLT != null ? clockInInfo.isCLT : false,
                    canRegisterActivity:
                        clockInInfo.registersActivity != null
                            ? clockInInfo.registersActivity
                            : false,
                    workingHoursContractId: clockInInfo.workingHoursContractId
                        ? clockInInfo.workingHoursContractId
                        : 0,
                    observation: clockInInfo.observation
                        ? clockInInfo.observation
                        : ''
                });
            }
        }
    }, [clockInInfo, rhWorkingHoursContracts]);

    const handleChange = (name, value) => {
        setHasChange(true);
        let val = value;
        if (name === 'canClockIn' || name === 'canRegisterActivity') {
            if (value === 'true') {
                val = true;
            } else {
                val = false;
            }
            setRequiredClockRecordType(false);
            setRequiredObservation(false);
        }
        setValues({ ...values, [name]: val });
    };

    const handleChangeWorkingHoursContract = (name, value) => {
        setHasChange(true);
        let val = value;
        let workingHoursContract = rhWorkingHoursContracts.find(
            x => x.id === value
        );
        setValues({
            ...values,
            [name]: val,
            workingHoursContract: workingHoursContract,
            workingHoursContractId: value,
            dailyWorkload:
                workingHoursContract.workingHours /*workingHoursContract.name.includes("Semanais") */ // -- for showing semanal working hours, uncomment this and remove the false boolean bellow
                    ? false
                        ? workingHoursContract.workingHours / (1000 * 60 * 60)
                        : ConvertMilliInHourMin(
                              workingHoursContract.workingHours
                          )
                    : '-',
            minIntervalLunch: workingHoursContract.minLunchBreakTime
                ? ConvertMilliInHourMin(workingHoursContract.minLunchBreakTime)
                : '-',
            maxIntervalLunch: workingHoursContract.maxLunchBreakTime
                ? ConvertMilliInHourMin(workingHoursContract.maxLunchBreakTime)
                : '-',
            minEntryHour: workingHoursContract.minStartTime
                ? ConvertMilliInHourMin(workingHoursContract.minStartTime)
                : '-',
            maxEntryHour: workingHoursContract.maxStartTime
                ? ConvertMilliInHourMin(workingHoursContract.maxStartTime)
                : '-',
            minLeaveHour: workingHoursContract.minEndTime
                ? ConvertMilliInHourMin(workingHoursContract.minEndTime)
                : '-',
            maxLeaveHour: workingHoursContract.maxEndTime
                ? ConvertMilliInHourMin(workingHoursContract.maxEndTime)
                : '-',
            minLunchHour: workingHoursContract.minLunchBreakStart
                ? ConvertMilliInHourMin(workingHoursContract.minLunchBreakStart)
                : '-',
            maxLunchHour: workingHoursContract.maxLunchBreakEnd
                ? ConvertMilliInHourMin(workingHoursContract.maxLunchBreakEnd)
                : '-'
        });
    };

    const handleCancel = () => {
        if (hasChange) {
            setOpenCancelDialog(true);
        } else {
            cleanFields();
            history.push('/Collaborators');
        }
    };

    const verifyApply = () => {
        let res = true;

        if (!values.canClockIn && !values.observation.trim()) {
            res = false;
            setRequiredObservation(true);
        }
        if (values.workingHoursContractId === 0) {
            res = false;
            setRequiredWorkingHoursContract(true);
        }
        if (!values.clockRecordType && values.canClockIn) {
            res = false;
            setRequiredClockRecordType(true);
        }

        return res;
    };

    const handleApply = () => {
        let canApply = verifyApply();
        if (canApply) {
            setOpen(true);
        }
    };

    return (
        <React.Fragment>
            <Transition loading={loading} newDesign={true}>
                <Paper>
                    <React.Fragment>
                        <div className={styles.clockRecordForm}>
                            {values.isCLT ? (
                                <GreyContainer>
                                    {values.registeredOnRep ? (
                                        <>
                                            <CheckedInfo
                                                textTranslation={
                                                    context === 'view'
                                                        ? 'HR.clockRecordRegisteredOnREP'
                                                        : 'HR.clockRecordRegisteredOnREPWithButton'
                                                }
                                            />
                                            {context !== 'view' ? (
                                                <Buttons
                                                    onClick={() => {
                                                        setOpenConfirmationREP(
                                                            true
                                                        );
                                                    }}
                                                    tipo="borderBackButton"
                                                    conteudo={t(
                                                        'HR.removeFingerRep'
                                                    ).toUpperCase()}
                                                />
                                            ) : null}
                                        </>
                                    ) : (
                                        <>
                                            <DefaultInfo
                                                iconColor={'#FFD200'}
                                                textTranslation={
                                                    context === 'view'
                                                        ? 'HR.clockRecordNotRegisteredOnREP'
                                                        : 'HR.clockRecordNotRegisteredOnREPWithButton'
                                                }
                                            />
                                            {context !== 'view' ? (
                                                <Buttons
                                                    onClick={() => {
                                                        setOpenConfirmationREP(
                                                            true
                                                        );
                                                    }}
                                                    tipo="borderBackButton"
                                                    conteudo={t(
                                                        'HR.registerRep'
                                                    ).toUpperCase()}
                                                />
                                            ) : null}
                                        </>
                                    )}
                                </GreyContainer>
                            ) : null}
                            <Grid
                                container
                                item
                                spacing={2}
                                md={12}
                                lg={12}
                                style={{ paddingRight: '50px' }}
                            >
                                <Grid item xs={12} sm={6}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">
                                            {t('HR.theEmployeeClockIn') +
                                                (context != 'view' ? ' *' : '')}
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            aria-label="position"
                                            disabled={context === 'view'}
                                            name="position"
                                            defaultValue="top"
                                            value={values.canClockIn}
                                            className={
                                                styles.radioButtonTopPadding
                                            }
                                            onChange={ev =>
                                                handleChange(
                                                    'canClockIn',
                                                    ev.target.value
                                                )
                                            }
                                        >
                                            <FormControlLabel
                                                disabled={context === 'view'}
                                                value={true}
                                                control={
                                                    <Radio color="primary" />
                                                }
                                                label={t('GENERAL.yesCapital')}
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                disabled={context === 'view'}
                                                value={false}
                                                control={
                                                    <Radio color="primary" />
                                                }
                                                label={t('GENERAL.noCapital')}
                                                labelPlacement="end"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Box width="100%" />

                                <Grid item xs={12} sm={6}>
                                    <Input
                                        context={
                                            !values.canClockIn
                                                ? 'view'
                                                : context
                                        }
                                        required
                                        label={'HR.clockRecordType'}
                                        isSelect
                                        arraySelected={clockRecordTypeArray}
                                        value={values.clockRecordType}
                                        valueName={'clockRecordType'}
                                        handleChange={handleChange}
                                        inputProps={{ autocomplete: 'off' }}
                                        style={{ margin: 0 }}
                                        error={requiredClockRecordType}
                                        helperText={
                                            requiredClockRecordType &&
                                            t('GENERAL.requiredField')
                                        }
                                        setRequired={setRequiredClockRecordType}
                                    />
                                </Grid>

                                <Box width="100%" />

                                <Grid item xs={12} sm={6}>
                                    <FormControl
                                        component="fieldset"
                                        disabled={!values.canClockIn}
                                    >
                                        <FormLabel component="legend">
                                            {t(
                                                'HR.theEmployeeRegistersActivity'
                                            ) +
                                                (context != 'view' &&
                                                values.canClockIn
                                                    ? ' *'
                                                    : '')}
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            aria-label="registersActivity"
                                            disabled={
                                                context === 'view' ||
                                                !values.canClockIn
                                            }
                                            name="registersActivity"
                                            defaultValue="top"
                                            value={values.canRegisterActivity}
                                            className={
                                                styles.radioButtonTopPadding
                                            }
                                            onChange={ev =>
                                                handleChange(
                                                    'canRegisterActivity',
                                                    ev.target.value
                                                )
                                            }
                                        >
                                            <FormControlLabel
                                                disabled={
                                                    context === 'view' ||
                                                    !values.canClockIn
                                                }
                                                value={true}
                                                control={
                                                    <Radio color="primary" />
                                                }
                                                label={t('GENERAL.yesCapital')}
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                disabled={
                                                    context === 'view' ||
                                                    !values.canClockIn
                                                }
                                                value={false}
                                                control={
                                                    <Radio color="primary" />
                                                }
                                                label={t('GENERAL.noCapital')}
                                                labelPlacement="end"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Box width="100%" />

                                <Grid item xs={12} sm={6}>
                                    <Input
                                        context={context}
                                        required
                                        label={'HR.workingHours'}
                                        isSelect
                                        arraySelected={
                                            workingHoursContractsArray
                                        }
                                        value={
                                            values.workingHoursContractId
                                                ? parseInt(
                                                      values.workingHoursContractId
                                                  )
                                                : ''
                                        }
                                        valueName={'workingHoursContractId'}
                                        handleChange={
                                            handleChangeWorkingHoursContract
                                        }
                                        inputProps={{ autocomplete: 'off' }}
                                        style={{ margin: 0 }}
                                        error={requiredWorkingHoursContract}
                                        helperText={
                                            requiredWorkingHoursContract &&
                                            t('GENERAL.requiredField')
                                        }
                                        setRequired={
                                            setRequiredWorkingHoursContract
                                        }
                                    />
                                </Grid>

                                <Box width="100%" />

                                {!values.canClockIn && (
                                    <>
                                        <FormControl component="fieldset">
                                            <FormLabel
                                                style={{ padding: 8 }}
                                                component="legend"
                                            >
                                                {t(
                                                    'HR.theEmployeeNotClockInObservation'
                                                ) +
                                                    (context != 'view'
                                                        ? ' *'
                                                        : '')}
                                            </FormLabel>
                                        </FormControl>
                                        <Box width="100%" />
                                        <Grid item xs={12} sm={6}>
                                            <Input
                                                required
                                                context={context}
                                                label={'GENERAL.observation'}
                                                value={values.observation}
                                                valueName={'observation'}
                                                rows={5}
                                                maxLength={200}
                                                handleChange={handleChange}
                                                error={requiredObservation}
                                                helperText={
                                                    requiredObservation &&
                                                    t('GENERAL.requiredField')
                                                }
                                                setRequired={
                                                    setRequiredObservation
                                                }
                                            />
                                        </Grid>
                                    </>
                                )}

                                <Box width="100%" />

                                <Grid item xs={12} sm={2}>
                                    {
                                        /*values != null && values.name != null && values.name.includes("Semanais") // -- for showing semanal working hours, uncomment this code snippet
                                        ?
                                            <div>
                                                <Typography className={classes.headerColor}><b>{t("HR.weeklyWorkload")}</b></Typography>
                                                <Typography className={classes.subtextGrey}>{values.dailyWorkload !== "-" ? `${values.dailyWorkload} ${t("HR.weeklyHours")}` : "-"}</Typography>
                                            </div>
                                        :*/
                                        <div>
                                            <Typography
                                                className={classes.headerColor}
                                            >
                                                <b>{t('HR.dailyWorkload')}</b>
                                            </Typography>
                                            <Typography
                                                className={classes.subtextGrey}
                                            >
                                                {values.dailyWorkload !== '-'
                                                    ? `${
                                                          values.dailyWorkload
                                                      } ${t('HR.dailyHours')}`
                                                    : '-'}
                                            </Typography>
                                        </div>
                                    }
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <Typography className={classes.headerColor}>
                                        <b>{t('HR.minimumLunchBreak')}</b>
                                    </Typography>
                                    <Typography className={classes.subtextGrey}>
                                        {values.minIntervalLunch !== '-'
                                            ? `${values.minIntervalLunch} ${t(
                                                  'HR.dailyHours'
                                              )}`
                                            : '-'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <Typography className={classes.headerColor}>
                                        <b>{t('HR.maximumLunchBreak')}</b>
                                    </Typography>
                                    <Typography className={classes.subtextGrey}>
                                        {values.maxIntervalLunch !== '-'
                                            ? `${values.maxIntervalLunch} ${t(
                                                  'HR.dailyHours'
                                              )}`
                                            : '-'}
                                    </Typography>
                                </Grid>

                                <Box width="100%" />

                                <Grid item xs={12} sm={2}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Typography
                                            className={classes.headerColor}
                                        >
                                            <b>{t('HR.entryHour')}</b>
                                        </Typography>
                                        <Tooltip
                                            title={
                                                <Typography>
                                                    {t('HR.clockRecordInfo')}
                                                </Typography>
                                            }
                                            aria-label="details"
                                            placement="top"
                                        >
                                            <InfoIcon
                                                style={{
                                                    fontSize: '22px',
                                                    paddingTop: '10px',
                                                    marginLeft: '5px',
                                                    color: '#6D6F71'
                                                }}
                                            />
                                        </Tooltip>
                                    </div>
                                    <Typography className={classes.subtextGrey}>
                                        {values.minEntryHour !== '-' ||
                                        values.maxEntryHour !== '-'
                                            ? `${values.minEntryHour} ${t(
                                                  'GENERAL.at'
                                              )} ${values.maxEntryHour} ${t(
                                                  'GENERAL.hours'
                                              )}`
                                            : '-'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Typography
                                            className={classes.headerColor}
                                        >
                                            <b>{t('HR.lunchHour')}</b>
                                        </Typography>
                                        <Tooltip
                                            title={
                                                <Typography>
                                                    {t('HR.clockRecordInfo')}
                                                </Typography>
                                            }
                                            aria-label="details"
                                            placement="top"
                                        >
                                            <InfoIcon
                                                style={{
                                                    fontSize: '22px',
                                                    paddingTop: '10px',
                                                    marginLeft: '5px',
                                                    color: '#6D6F71'
                                                }}
                                            />
                                        </Tooltip>
                                    </div>
                                    <Typography className={classes.subtextGrey}>
                                        {values.minLunchHour !== '-' ||
                                        values.maxLunchHour !== '-'
                                            ? `${values.minLunchHour} ${t(
                                                  'GENERAL.at'
                                              )} ${values.maxLunchHour} ${t(
                                                  'GENERAL.hours'
                                              )}`
                                            : '-'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Typography
                                            className={classes.headerColor}
                                        >
                                            <b>{t('HR.leaveHour')}</b>
                                        </Typography>
                                        <Tooltip
                                            title={
                                                <Typography>
                                                    {t('HR.clockRecordInfo')}
                                                </Typography>
                                            }
                                            aria-label="details"
                                            placement="top"
                                        >
                                            <InfoIcon
                                                style={{
                                                    fontSize: '22px',
                                                    paddingTop: '10px',
                                                    marginLeft: '5px',
                                                    color: '#6D6F71'
                                                }}
                                            />
                                        </Tooltip>
                                    </div>
                                    <Typography className={classes.subtextGrey}>
                                        {values.minLeaveHour !== '-' ||
                                        values.maxLeaveHour !== '-'
                                            ? `${values.minLeaveHour} ${t(
                                                  'GENERAL.at'
                                              )} ${values.maxLeaveHour} ${t(
                                                  'GENERAL.hours'
                                              )}`
                                            : '-'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                        {context !== 'view' ? (
                            <div className={styles.buttonContainer}>
                                <Buttons
                                    onClick={handleCancel}
                                    tipo="BN-noback"
                                    conteudo={t('GENERAL.cancel')}
                                />
                                <Buttons
                                    onClick={handleApply}
                                    tipo="BN-blue"
                                    conteudo={t('GENERAL.apply')}
                                />
                            </div>
                        ) : (
                            <Box
                                width="100%"
                                style={{ marginBottom: '2rem' }}
                            />
                        )}
                    </React.Fragment>
                </Paper>
                {open && (
                    <DialogConfirmClockRecord
                        open={open}
                        setOpen={setOpen}
                        observation={values.observation}
                        name={values.name}
                        clocksIn={values.canClockIn}
                        registersActivity={values.canRegisterActivity}
                        workingHoursContract={values.workingHoursContract}
                        workingHoursContractId={values.workingHoursContractId}
                        clockRecordType={values.clockRecordType}
                        userId={userId}
                        fetchData={fetchData}
                        updateClockRecord={updateClockRecord}
                    />
                )}

                {openConfirmationREP ? (
                    <DialogConfirmation
                        open={openConfirmationREP}
                        setOpen={setOpenConfirmationREP}
                        title={
                            values.registeredOnRep
                                ? t('HR.confirmRemoveInREP')
                                : t('HR.confirmRegisterInREP')
                        }
                        infoText={
                            values.registeredOnRep
                                ? 'HR.confirmationRemoveREPInfo'
                                : 'HR.confirmationREPInfo'
                        }
                        principalText={
                            values.registeredOnRep
                                ? 'HR.confirmationRemoveREPPrincipal'
                                : 'HR.confirmationREPPrincipal'
                        }
                        principalValues={{ name: values.name }}
                        buttonText={
                            values.registeredOnRep
                                ? t('HR.removeFinger').toUpperCase()
                                : `${t('GENERAL.register').toUpperCase()} ${t(
                                      'GENERAL.collaborator'
                                  ).toUpperCase()}`
                        }
                        isLoading={
                            values.registeredOnRep
                                ? mutationRemoveFingerRep.isLoading
                                : mutationRep.isLoading
                        }
                        mutate={() => {
                            let formData = new FormData();
                            formData.append(`userId`, userId);

                            if (values.registeredOnRep) {
                                mutationRemoveFingerRep.mutate(formData);
                            } else {
                                mutationRep.mutate(formData);
                            }
                        }}
                    />
                ) : null}
                <DialogCancelOperation
                    open={openCancelDialog}
                    setOpen={setOpenCancelDialog}
                    path={'/Collaborators'}
                    dialogTitle={t('GENERAL.cancelEdit')}
                    dialogText={t('GENERAL.cancelEditText')}
                />
            </Transition>
        </React.Fragment>
    );
};

export default ClockRecordForm;
