import React, { useState, useEffect, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Buttons from "components/buttons/Pressed";
import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";
import Input from "components/inputs/Input";
import InventoryContext from "context/inventory/inventoryContext";

const DialogReqUse = (props) => {
  var FormatDateToDataBase = require("./../../../utils/dates/FormatDateToDataBase").FormatDateToDataBase;
  const [t] = useTranslation();
  const classes = useStyles();
  const {
    open,
    setOpen,
    type,
    data,
    requestId,
    requestName,
    message,
    placesArray,
    itensCategoriesArray,
    setUseSoftwaresOrEquipment,
    useSoftwaresOrEquipment,
    softwareCategoriesArray,
    myItensArray,
    softwaresArray,
    setChangeOfLocalArray,
    context,
    approver,
    arrayType,
    deleteArrayEditRequisition,
    requisitionId
  } = props;

  const inventoryContext = useContext(InventoryContext);
  const [values, setValues] = useState({
    placeId: "",
    categoryId: "",
    description: "",
    place: "",
    category: "",
    machineId: "",
    itemId: "",
    machine: "",
    softwareId: "",
    software: "",
    categorySoftwareId: "",
    newPlaceId: "",
    newPlace: ""

  });
  const [requiredFieldPlace, setRequiredFieldPlace] = useState(false);
  const [requiredFieldCategory, setRequiredFieldCategory] = useState(false);
  const [requiredFieldCategorySoftware, setRequiredFieldCategorySoftware] = useState(false);
  const [requiredFieldSoftware, setRequiredFieldSoftware] = useState(false);
  const [requiredFieldMachine, setRequiredFieldMachine] = useState(false);
  const [softwaresbyCategoryArray, setSoftwareByCategoryArray] = useState([]);
  const [requisitionsToDeleteIds, setRequisitionsToDeleteIds] = useState([]);

  useEffect(() => {
    var softwares = softwaresArray.filter(x => x.categoryId === values.categorySoftwareId && !x.hasdeleted);
    setSoftwareByCategoryArray(softwares);
  }, [values.categorySoftwareId]);

  const verifyCanSubmit = () => {
    let res = true;
    if (type === "change_of_local") {
      if (!values.newPlaceId) {
        res = false;
        setRequiredFieldPlace(true);
      }
    } else if (type === "use_of_equipment") {
      if (!values.placeId) {
        res = false;
        setRequiredFieldPlace(true);
      }
      if (!values.categoryId) {
        res = false;
        setRequiredFieldCategory(true);
      }
    } else if (type === "use_of_software") {
      if (!values.categorySoftwareId) {
        res = false;
        setRequiredFieldCategorySoftware(true);
      }
      if (!values.itemId) {
        res = false;
        setRequiredFieldMachine(true);
      }
      if (!values.softwareId) {
        res = false;
        setRequiredFieldSoftware(true);
      }
    }
    return res;
  };
  const { addRequisitions, updateRequisition } = inventoryContext;

  const clear = () => {
    setValues({
      ...values,
      placeId: "",
      categoryId: "",
      description: "",
      place: "",
      category: "",
      machineId: "",
      itemId: "",
      machine: "",
      softwareId: "",
      software: "",
      categorySoftwareId: "",
      categorySoftware: "",
      newPlaceId: "",
      newPlace: ""
    });
  }

  const handleClose = () => {
    setOpen(false);
    clear();
  };

  const handleSubmit = () => {
    const canSubmit = verifyCanSubmit();
    if (canSubmit) {
      let formData = new FormData();
      if (context === "new") {

        var emailDatas = []
        var auxEmail = {}

        auxEmail.user = [{ name: approver.name, email: approver.email }];
        auxEmail.type = arrayType.find(ele => ele.value === type).label;
        auxEmail.name = requestName;
        auxEmail.date = new Date();

        emailDatas.push(auxEmail)
        var auxEmail = {}

        formData.append(`requisition[0].dateCreated`, FormatDateToDataBase(new Date()));
        formData.append(`requisition[0].version`, 0);
        formData.append(`requisition[0].status`, "AWAITING_APPROVAL");
        formData.append(`requisition[0].class`, type);
        formData.append(`requisition[0].message`, message ? message : "");
        formData.append(`requisition[0].requesterId`, requestId);
        formData.append(`requisition[0].responsibleId`, requestId);
        for (let index = 0; index < data.length; index++) {
          var item = data[index];

          if (type === "return_of_equipment") {
            formData.append(`requisition[0].inventoryItemOfEquipmentRequisition[${index}].version`, 0);
            formData.append(`requisition[0].inventoryItemOfEquipmentRequisition[${index}].categoryId`, item.categoryId);
            formData.append(`requisition[0].inventoryItemOfEquipmentRequisition[${index}].description`, message ? message : "");
            formData.append(`requisition[0].inventoryItemOfEquipmentRequisition[${index}].itemId`, item.id);
            formData.append(`requisition[0].inventoryItemOfEquipmentRequisition[${index}].placeId`, type === "return_of_equipment" ? item.placeId : item.placeId);
            formData.append(`requisition[0].inventoryItemOfEquipmentRequisition[${index}].itemsOfEquipmentRequisitionIdx`, 0);
          }
          if (type === "return_of_software") {
            formData.append(`requisition[0].inventoryItemOfSoftwareRequisition[${index}].version`, 0);
            formData.append(`requisition[0].inventoryItemOfSoftwareRequisition[${index}].categoryId`, item.categoryId);
            formData.append(`requisition[0].inventoryItemOfSoftwareRequisition[${index}].description`, message);
            formData.append(`requisition[0].inventoryItemOfSoftwareRequisition[${index}].itemId`, item.itemId ? item.itemId : "");
            formData.append(`requisition[0].inventoryItemOfSoftwareRequisition[${index}].licenseId`, item.id);
            formData.append(`requisition[0].inventoryItemOfSoftwareRequisition[${index}].softwareId`, item.softwareId);
            formData.append(`requisition[0].inventoryItemOfSoftwareRequisition[${index}].itemsOfSoftwareRequisitionIdx`, 0);
          }
        }
      } else if (context === "edit") {
        formData.append(`requisition[0].id`, requisitionId);
        formData.append(`requisition[0].dateCreated`, FormatDateToDataBase(new Date()));
        formData.append(`requisition[0].version`, 0);
        formData.append(`requisition[0].status`, "AWAITING_APPROVAL");
        formData.append(`requisition[0].class`, type);
        formData.append(`requisition[0].message`, message ? message : "");
        formData.append(`requisition[0].requesterId`, requestId);
        formData.append(`requisition[0].responsibleId`, requestId);

        if (type === "return_of_equipment") {
          if (deleteArrayEditRequisition.length > 0) {
            for (let i in deleteArrayEditRequisition) {
              const req = deleteArrayEditRequisition[i];
              formData.append(`requisitionsEquipmentToDelete[${i}].id`, req.idReq);
            }
          }
        }
        if (type === "return_of_software") {
          if (deleteArrayEditRequisition.length > 0) {
            for (let i in deleteArrayEditRequisition) {
              const req = deleteArrayEditRequisition[i];
              formData.append(`requisitionsSoftwareToDelete[${i}].id`, req.idReq);
            }
          }
        }
        for (let index = 0; index < data.length; index++) {
          var item = data[index];
          if (type === "return_of_equipment") {
            formData.append(`inventoryItemOfEquipmentRequisition[${index}].id`, item.id ? item.id : "");
            formData.append(`inventoryItemOfEquipmentRequisition[${index}].requisitionId`, requisitionId);
            formData.append(`inventoryItemOfEquipmentRequisition[${index}].version`, 0);
            formData.append(`inventoryItemOfEquipmentRequisition[${index}].categoryId`, item.categoryId);
            formData.append(`inventoryItemOfEquipmentRequisition[${index}].description`, message ? message : "");
            formData.append(`inventoryItemOfEquipmentRequisition[${index}].itemId`, item.id);
            formData.append(`inventoryItemOfEquipmentRequisition[${index}].placeId`, type === "return_of_equipment" ? item.placeId : item.placeId);
            formData.append(`inventoryItemOfEquipmentRequisition[${index}].itemsOfEquipmentRequisitionIdx`, 0);
          }
          if (type === "return_of_software") {
            formData.append(`requisition[0].inventoryItemOfSoftwareRequisition[${index}].version`, 0);
            formData.append(`requisition[0].inventoryItemOfSoftwareRequisition[${index}].categoryId`, item.categoryId);
            formData.append(`requisition[0].inventoryItemOfSoftwareRequisition[${index}].description`, message);
            formData.append(`requisition[0].inventoryItemOfSoftwareRequisition[${index}].itemId`, item.itemId ? item.itemId : "");
            formData.append(`requisition[0].inventoryItemOfSoftwareRequisition[${index}].licenseId`, item.id);
            formData.append(`requisition[0].inventoryItemOfSoftwareRequisition[${index}].softwareId`, item.softwareId);
            formData.append(`requisition[0].inventoryItemOfSoftwareRequisition[${index}].itemsOfSoftwareRequisitionIdx`, 0);
          }
        }
      }
      if ((type === "return_of_equipment" || type === "return_of_software") && context === "new") {
        addRequisitions(formData, "/MyRequests", emailDatas, context);
      }
      else if ((type === "return_of_equipment" || type === "return_of_software") && context === "edit") {
        updateRequisition(formData, "/MyRequests");
      }
      else if (type === "use_of_equipment" || type === "use_of_software") {
        setUseSoftwaresOrEquipment([...useSoftwaresOrEquipment, values]);
      } else if (type === "change_of_local") {
        setChangeOfLocalArray([values]);
      }
      setOpen(false);
      clear();
    }
  };
  const handleSubmitUseEquipment = () => {
    setOpen(false);
  };

  const handleChange = (name, value) => {
    if (type === "use_of_equipment") {
      if (name === "placeId") {
        var places = placesArray.filter(el => el.value === value);
        setValues({ ...values, [name]: value, place: places[0].label });
        setRequiredFieldPlace(false);
      } else if (name === "categoryId") {
        var category = itensCategoriesArray.filter(el => el.value === value);
        setValues({ ...values, [name]: value, category: category[0].label });
      } else {
        setValues({ ...values, [name]: value });
      }
    } else if (type === "use_of_software") {
      if (name === "itemId") {
        var machine = myItensArray.filter(el => el.value === value);
        setValues({ ...values, [name]: value, machine: machine[0].label });
        setRequiredFieldMachine(false)
      } else if (name === "categorySoftwareId") {
        var softwarecategory = softwareCategoriesArray.filter(el => el.value === value);
        setValues({ ...values, [name]: value, category: softwarecategory[0].label });
        setRequiredFieldCategorySoftware(false)
      } else if (name === "softwareId") {
        var softwares = softwaresbyCategoryArray.filter(el => el.value === value);
        setValues({ ...values, [name]: value, software: softwares[0].label });
        setRequiredFieldSoftware(false)
      } else {
        setValues({ ...values, [name]: value });
      }
      // else if (name == "softwareId") {
      //   var software = softwareCategoriesArray.filter(el => el.value === value);
      //   setValues({ ...values, [name]: value, software: software[0].label });
      //setRequiredFieldSoftware(false)
      // }
    } else if (type === "change_of_local") {
      if (name === "newPlaceId") {
        var NewPlaces = placesArray.filter(el => el.value === value);
        setValues({ ...values, [name]: value, newPlace: NewPlaces[0].label });
        setRequiredFieldPlace(false);
      }
    }
    else {
      setValues({ ...values, [name]: value });
    }
  };

  function getCodeList(row) {
    const response = [];
    if (row["lsbdCodeF"])
      response.push(row["lsbdCodeF"]);
    if (row["fcpcCodeF"])
      response.push(row["fcpcCodeF"]);
    if (row["ufcCodeF"])
      response.push(row["ufcCodeF"]);

    if (response.length === 0) {
      if (row.libraryExemplar && row.libraryExemplar.length > 0 && row.libraryExemplar[0].exemplarCode !== null) {
        response.push("BIB-00" + row.libraryExemplar[0].exemplarCode);
      } else {
        response.push('-');
      }
    }
    return response;
  }


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      aria-labelledby="max-width-dialog-title"
      aria-labelledby="form-dialog-title"
      msg="mensagem"
      maxWidth="md"
    >
      <DialogTitle className={classes.actionsTitle} style={{ width: "auto" }}>
        <span style={{ fontWeight: "bold" }}>
          {type === "return_of_equipment"
            ? t("INVENTORY.returnOfEquipment")
            : type === "return_of_software"
              ? t("INVENTORY.returnOfSoftware")
              : type === "use_of_equipment"
                ? t("INVENTORY.useOfEquipments")
                : type === "use_of_software"
                  ? t("INVENTORY.useOfSoftware")
                  : type === "change_of_local"
                    ? t("INVENTORY.changePlace")
                    : ""}
        </span>
        <IconButton
          style={{ float: "right", marginTop: -10, marginRight: -17 }}
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid container style={{ paddingTop: "20px" }}>
            {type === "return_of_equipment" || type === "change_of_local" ? (
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <b>ITEM</b>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <b>LOCAL</b>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <b>CÓD. IDENTIFICAÇÃO</b>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <b>NÚMERO DE SÉRIE</b>
                </Grid>
                {data.map((option) => (
                  <Grid container style={{ marginTop: "10px" }}>
                    <Grid item xs={12} sm={4}>
                      {option.name}
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      {option.place}
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      {getCodeList(option)[0]}
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      {option.serialNumber ? option.serialNumber : "-"}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            ) :
              type === "return_of_software" && (
                <Grid container>
                  <Grid item xs={12} sm={3}>
                    <b>SOFTWARE</b>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <b>CATEGORIA</b>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <b>CHAVE/EMAIL DE ATIVAÇÃO</b>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <b>MÁQUINA</b>
                  </Grid>
                  {data.map((option) => (
                    <Grid container style={{ marginTop: "10px" }}>
                      <Grid item xs={12} sm={3}>
                        {option.software}
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        {option.category}
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        {option.key}
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        {option.machine}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              )
            }
            {type === "use_of_equipment" &&
              <Grid container item spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Input
                    required={true}
                    helperText={t("GENERAL.requiredField")}
                    error={requiredFieldCategory}
                    context={"new"}
                    label={"INVENTORY.category"}
                    value={values.categoryId}
                    arraySelected={itensCategoriesArray}
                    valueName={"categoryId"}
                    handleChange={handleChange}
                    isSelect={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Input
                    required={true}
                    helperText={t("GENERAL.requiredField")}
                    error={requiredFieldPlace}
                    context={"new"}
                    label={"INVENTORY.place"}
                    value={values.placeId}
                    arraySelected={placesArray}
                    valueName={"placeId"}
                    handleChange={handleChange}
                    isSelect={true}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Input
                    context={"new"}
                    rows={6}
                    label={"INVENTORY.description"}
                    value={values.description}
                    valueName={"description"}
                    handleChange={handleChange}
                    maxLength={500}
                  />
                </Grid>
              </Grid>}
            {type === "use_of_software" &&
              <Grid container item spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Input
                    required={true}
                    helperText={t("GENERAL.requiredField")}
                    error={requiredFieldCategorySoftware}
                    context={"new"}
                    label={"INVENTORY.category"}
                    value={values.categorySoftwareId}
                    arraySelected={softwareCategoriesArray}
                    valueName={"categorySoftwareId"}
                    handleChange={handleChange}
                    isSelect={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Input
                    required={true}
                    helperText={t("GENERAL.requiredField")}
                    error={requiredFieldSoftware}
                    context={softwaresbyCategoryArray.length > 0 ? "new" : "view"}
                    label={"INVENTORY.softwares"}
                    value={values.softwareId}
                    arraySelected={softwaresbyCategoryArray}
                    valueName={"softwareId"}
                    handleChange={handleChange}
                    isSelect={true}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Input
                    required={true}
                    helperText={t("GENERAL.requiredField")}
                    error={requiredFieldMachine}
                    context={"new"}
                    label={"INVENTORY.machineForInstallation"}
                    value={values.itemId}
                    valueName={"itemId"}
                    handleChange={handleChange}
                    arraySelected={myItensArray}
                    isSelect={true}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Input
                    context={"new"}
                    rows={6}
                    label={"INVENTORY.description"}
                    value={values.description}
                    valueName={"description"}
                    handleChange={handleChange}
                    maxLength={500}
                  />
                </Grid>
              </Grid>}
            {type === "change_of_local" &&
              <Grid item xs={12} sm={6}>
                <Input
                  required={true}
                  helperText={t("GENERAL.requiredField")}
                  error={requiredFieldPlace}
                  context={"new"}
                  label={"INVENTORY.newPlace"}
                  value={values.newPlaceId}
                  arraySelected={placesArray}
                  valueName={"newPlaceId"}
                  handleChange={handleChange}
                  isSelect={true}
                />
              </Grid>}
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Buttons
          onClick={type === "use_of_equipment" ? handleSubmitUseEquipment : handleClose}
          tipo="BN-noback"
          conteudo={t("GENERAL.cancel").toLocaleUpperCase()}
        />
        <Buttons
          onClick={handleSubmit}
          tipo="BN-blue"
          conteudo={t("GENERAL.confirm").toLocaleUpperCase()}
        />
      </DialogActions>
    </Dialog>
  );
};

export default DialogReqUse;
