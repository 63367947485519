/* eslint-disable */
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import React, { useEffect, useState, useContext } from "react";
import AllowancesContext from "context/allowance/allowanceContext";
import SidebarListItem from "./SidebarListItem";


const SideBarConfigMenu = () => {
  const [displayMenu, setDisplayMenu] = useState(false);
  const [displayWorkingMenu, setDisplayWorkingMenu] = useState(false);
  const [displayWorkPlanMenu, setDisplayWorkPlanMenu] = useState(false);
  const [arrowConfig, setArrowConfig] = useState(false);
  const [arrowPlan, setArrowPlan] = useState(false);
  const [arrowEventWorking, setArrowEventWorking] = useState(false);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const styleBar = "rgba(255, 255, 255, 0.09)";
  var contextBar = window.location.href.split("/")[3];
  const allowanceContext = useContext(AllowancesContext);
  const [tamanho, setTamanho] = useState(10);

  const {
    verifyPermission,
    permission,
    allAllowances,
    getAllPermission
  } = allowanceContext;

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  React.useEffect(() => {
    getAllPermission()
  }, [allAllowances.length, JSON.stringify(allAllowances)]);


  const handleMouseEvent = () => {
    setDisplayMenu(!displayMenu);
    setArrowConfig(!arrowConfig);
    setDisplayWorkPlanMenu(false);
    setDisplayWorkingMenu(false);
    setArrowPlan(false);
    setArrowEventWorking(false);
  };

  const handleMouseEventWorkPlan = () => {
    setDisplayMenu(false);
    setArrowConfig(false);
    setDisplayWorkPlanMenu(!displayWorkPlanMenu);
    setDisplayWorkingMenu(false);
    setArrowPlan(!arrowPlan);
    setArrowEventWorking(false);
  };

  const handleMouseEventWorking = () => {
    setDisplayMenu(false);
    setArrowConfig(false);
    setDisplayWorkPlanMenu(false);
    setDisplayWorkingMenu(!displayWorkingMenu);
    setArrowPlan(false);
    setArrowEventWorking(!arrowEventWorking);
  };

  useEffect(() => {
    handleMouseEventWorking();
    handleMouseEventWorkPlan();
    handleMouseEvent();
    return () => {
      setDisplayMenu(false);
      setDisplayWorkingMenu(false);
      setDisplayWorkPlanMenu(false);
      setArrowConfig(false);
      setArrowEventWorking(false);
    };
  }, []);

  const getPermission = (screen, allowance) => {
    if (localStorage.getItem("auth-token") !== null) {
      var auth = JSON.parse(localStorage.getItem("auth-token"));
      var array = []
      for (var i = 0; i < auth.permissions.length; i++) {
        var permission = auth.permissions[i].standardauthorizationoption;
        var resource = auth.permissions[i].resource;
        if (screen === "list") {
          return true
        } else {
          if (resource === screen) {
            return permission[allowance]
          }
        }
      }
    }
  }

  return (
    <div>
      {allAllowances.length > 0 &&
        (getPermission("PROFILE", "allowCreate") ||
          getPermission("PROFILE", "allowRead") ||
          getPermission("PROFILE", "allowUpdate") ||
          getPermission("PROFILE", "allowDelete") ||
          getPermission("USER", "allowCreate") ||
          getPermission("USER", "allowRead") ||
          getPermission("USER", "allowUpdate") ||
          getPermission("USER", "allowDelete") ||
          getPermission("PARTNER", "allowCreate") ||
          getPermission("PARTNER", "allowRead") ||
          getPermission("PARTNER", "allowUpdate") ||
          getPermission("PARTNER", "allowDelete"))
        &&
        <div className="dropdown">
          <div className="divConfig" onClick={handleMouseEvent}>
            Configurações
            {!arrowConfig && <KeyboardArrowRight />}
            {arrowConfig && <KeyboardArrowDown />}
          </div>
          {displayMenu && (
            <ul style={{ display: "table-cell", marginLeft: "5px" }}>
              {allAllowances.length > 0 &&
                (getPermission("PROFILE", "allowCreate") ||
                  getPermission("PROFILE", "allowRead") ||
                  getPermission("PROFILE", "allowUpdate") ||
                  getPermission("PROFILE", "allowDelete")) && (<SidebarListItem text="Perfis" to="/profiles" />)}
              {allAllowances.length > 0 &&
                (getPermission("USER", "allowCreate") ||
                  getPermission("USER", "allowRead") ||
                  getPermission("USER", "allowUpdate") ||
                  getPermission("USER", "allowDelete")) && (<SidebarListItem text="Usuários" to="/users" />)}
              {allAllowances.length > 0 &&
                (getPermission("PARTNER", "allowCreate") ||
                  getPermission("PARTNER", "allowRead") ||
                  getPermission("PARTNER", "allowUpdate") ||
                  getPermission("PARTNER", "allowDelete")) && (<SidebarListItem text="Parceiros" to="/partners" />)}
            </ul>
          )}
        </div>
      }
      {
        allAllowances.length > 0 &&
        (getPermission("PROJECTSPLAN", "allowCreate") ||
          getPermission("PROJECTSPLAN", "allowRead") ||
          getPermission("PROJECTSPLAN", "allowUpdate") ||
          getPermission("PROJECTSPLAN", "allowDelete")) &&
        <div className="dropdown">
          <div className="divConfig" onClick={handleMouseEventWorkPlan}>
            Plano de Trabalho
            {!arrowPlan && <KeyboardArrowRight />}
            {arrowPlan && <KeyboardArrowDown />}
          </div>
          {displayWorkPlanMenu && (
            <ul style={{ display: "table-cell", padding: "0px" }}>
              <SidebarListItem text="Projetos" to="/projects" />
            </ul>
          )}
        </div>
      }
      {
        allAllowances.length > 0 &&
        (getPermission("PROJECTSEXEC", "allowCreate") ||
          getPermission("PROJECTSEXEC", "allowRead") ||
          getPermission("PROJECTSEXEC", "allowUpdate") ||
          getPermission("PROJECTSEXEC", "allowDelete")) &&
        <>
          <div style={{ paddingTop: "15px" }} className="planning">
            Execução
          </div>
          <div className="dropdown">
            <div className="divConfig" onClick={handleMouseEventWorking}>
              Laboração
              {!arrowEventWorking && <KeyboardArrowRight />}
              {arrowEventWorking && <KeyboardArrowDown />}
            </div>
            {displayWorkingMenu && (
              <ul style={{ paddingBottom: "60px" }}>
                {allAllowances.length > 0 &&
                  (getPermission("PROJECTSEXEC", "allowCreate") ||
                    getPermission("PROJECTSEXEC", "allowRead") ||
                    getPermission("PROJECTSEXEC", "allowUpdate") ||
                    getPermission("PROJECTSEXEC", "allowDelete")) && (<SidebarListItem text="Projetos" to="/execution/projects" />)}
                {(getPermission("USERCOSTS", "allowCreate") ||
                  getPermission("USERCOSTS", "allowRead") ||
                  getPermission("USERCOSTS", "allowUpdate") ||
                  getPermission("USERCOSTS", "allowDelete")) && (<SidebarListItem text="Custos de Colaboradores" to="/userscosts" />)}
              </ul>
            )}
          </div>
        </>
      }
    </div >
  );
};

export default SideBarConfigMenu;
