import { MenuItem, Paper } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import AddCircle from "@material-ui/icons/AddCircle";
import GetApp from "@material-ui/icons/GetApp";
import Save from "@material-ui/icons/Save";
import { useStyles } from "components/layout/CommonStyles";
import MySnackbarContentWrapper from "components/layout/Message";
import AllowancesContext from "context/allowance/allowanceContext";
import ProjectsContext from "context/projects/projectsContext";
import {
  AlignmentType,
  Document,
  HeadingLevel,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  WidthType
} from "docx";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { animated, useTransition } from "react-spring";
import ScopeStage from "./ScopeStage";
import Zoom from "@material-ui/core/Zoom";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

var fileDownload = require("js-file-download");

const tooltipTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#2c3e51"
    }
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: "white",
        fontSize: "1em",
        backgroundColor: "red",
        opacity: "0.7"
      }
    }
  }
});

const Scope = props => {
  const classes = useStyles();
  const [t] = useTranslation();

  const projectsContext = useContext(ProjectsContext);
  const allowanceContext = useContext(AllowancesContext);
  const [gpf, setGpf] = useState("");
  const [projectName, setProjectName] = useState("");
  const [seasonsArray, setSeasonsArray] = useState([]);
  const [scopes, setScopes] = useState([]);
  const {
    project,
    loading,
    response,
    getProject,
    submitScope,
    resetMessage,
    responseType,
    successiveActions,
    resetProject,
    hasUpdate,
    setValueRequiredMessage,
    showRequiredFieldMessage
  } = projectsContext;
  const { projectId } = props;
  const {
    allowanceResponse,
    allowanceResponseType,
    resetAllowanceMessage
  } = allowanceContext;
  const [delay, setDelay] = useState(true);
  const [stages, setStages] = useState([]);
  const [toDelete, setToDelete] = useState([]);
  const [season, setSeason] = useState("");
  const [seasonId, setSeasonId] = useState(0);
  const [stagesCount, setStagesCount] = useState(1);
  const [stageOne, setStageOne] = useState("");
  const [generalDescription, setDescription] = useState("");
  const [hasCooperationTerm, setHasCooperationTerm] = useState(false);

  const [snackBar, setSnackBar] = useState({
    open: false,
    kind: "",
    content: ""
  });

  const handleSnack = () => {
    setSnackBar({ ...snackBar, open: true });
  };

  const handleSnackClose = () => {
    setSnackBar({ ...snackBar, open: false });
    resetAllowanceMessage();
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (responseType === "SUCCESS") {
        handleSnack({ kind: "success", content: response });
      } else {
      }
      if (responseType === "ERROR") {
        handleSnack({ kind: "error", content: response });
      }
      setDelay(false);
    }, 800);

    return () => {
      clearTimeout(timer);
    };
  }, [responseType, response, successiveActions]);

  useEffect(() => {
    getProject(projectId);
    if (project && project.information) {
      setProjectName(project.information.name);
      if (project.sgppdAddendum.length > 0) {
        setHasCooperationTerm(true)
        for (var i = 0; i < project.sgppdAddendum.length; i++) {
          if (project.sgppdAddendum[i].gpf) {
            setGpf(project.sgppdAddendum[i].gpf);
          }
        }

        var aux = {};
        var general = {};
        var generalScope = {};
        var _seasonsArray = [];
        var _scopes = [];

        for (var i = 0; i < project.sgppdAddendum.length; i++) {
          var item = project.sgppdAddendum[i];
          var startDate = new Date(item.startdate);
          startDate.setMinutes(startDate.getMinutes() + startDate.getTimezoneOffset())
          var endDate = new Date(item.enddate);
          endDate.setMinutes(endDate.getMinutes() + endDate.getTimezoneOffset())

          aux.value = item.id;
          aux.label = startDate.toLocaleDateString() + " - " + endDate.toLocaleDateString();

          if (item.number === "GENERAL") {
            var startDateGeneral = new Date(project.information.startdate);
            startDateGeneral.setMinutes(startDateGeneral.getMinutes() + startDateGeneral.getTimezoneOffset())
            var endDateGeneral = new Date(project.information.enddate);
            endDateGeneral.setMinutes(endDateGeneral.getMinutes() + endDateGeneral.getTimezoneOffset())
            aux.value = item.id;
            aux.label = startDateGeneral.toLocaleDateString() + " - " + endDateGeneral.toLocaleDateString();
            general = aux;
            aux.label = aux.label + " (GERAL)";
          } else {
            _seasonsArray.push(aux);
          }

          aux = {};
          aux.addendumId = item.id;
          aux.scope = item.scope;

          if (item.number === "GENERAL") {
            generalScope = aux;
          } else {
            _scopes.push(aux);
          }

          aux = {};
        }
        _seasonsArray.push(general);
        _scopes.push(generalScope);

        setSeasonsArray(_seasonsArray);
        setScopes(_scopes);

        setSeason(_seasonsArray[_seasonsArray.length - 2].label);
        setSeasonId(_seasonsArray[_seasonsArray.length - 2].value);
      }
      if (scopes.length > 0) {
        let scopeDescription = scopes[scopes.length - 2].scope.scope ? scopes[scopes.length - 2].scope.scope : "";
        let stageOneDesc = scopes[scopes.length - 2].scope.sgppdScopeStage[0] ? scopes[scopes.length - 2].scope.sgppdScopeStage[0].description : "";
        setDescription(scopeDescription);
        setStageOne(stageOneDesc);

        let scopeStages = scopes[scopes.length - 2].scope.sgppdScopeStage;

        for (let i = 1; i < scopeStages.length; i++) {
          let label = scopeStages[i].description;
          let stageId = scopeStages[i].id;
          addStage(label, true, stageId);
        }
      }
    }

    return () => {
      setSeason("");
      setScopes([]);
      setSeasonId(0);
      setSeasonsArray([]);
    };
  }, [
    Object.keys(seasonsArray).length,
    JSON.stringify(scopes),
    JSON.stringify(project)
  ]);

  useEffect(() => {
    if (toDelete.length > 0) {
      if (stages.length > 0) {
        var aux = [];
        var len = stages.length;

        for (var i = 0; i < len; i++) {
          if (stages[i]) {
            var key = stages[i].key;

            var stageClass = document.getElementsByClassName("stageScope")[i + 1]
            var textarea = stageClass.getElementsByTagName("textarea");

            var element = {
              description: textarea[0].textContent,
              id: parseInt(
                document.getElementById("divStage" + key).dataset.stageId
              )
            };

            aux.push(element);
          }
        }

        stages.splice(0, len);
        setStagesCount(0);

        for (var i = 0; i < aux.length; i++) {
          if (aux[i].description.length > 1) {
            addStage(aux[i].description, true, aux[i].id);
          } else {
            addStage();
          }
        }
      }
    }
  }, [toDelete.length]);

  useEffect(() => {
    if (hasUpdate) {
      setStages([]);
      setSeason("");
      setScopes([]);
      setSeasonId(0);
      setSeasonsArray([]);
      resetProject();
    }
    return () => { };
  }, [hasUpdate]);

  useEffect(() => {
    return () => {
      resetMessage();
    };
  }, []);

  const createFile = () => {
    // Create document
    var descricao = document.getElementById("generalDesc").value;
    var stagesList = document.getElementsByClassName("stageScope");
    const doc = new Document({
      styles: {
        paragraphStyles: [
          {
            id: "Heading1",
            name: "Heading 1",
            //basedOn: "Normal",
            //next: "Normal",
            //quickFormat: true,
            run: {
              size: 35,
              bold: true,
              color: "2c3e51"
            }
          },
          {
            id: "desc",
            name: "descGoals",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              size: 20
            },
            paragraph: {
              spacing: {
                before: 240,
                after: 120
              }
            }
          },
          {
            id: "Heading6",
            name: "Heading 6",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              size: 20,
              color: "2c3e51"
            },
            paragraph: {
              spacing: {
                before: 240,
                after: 120
              }
            }
          }
        ]
      }
    });

    const table = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  text: "  GPF: \n",
                  heading: HeadingLevel.HEADING_6
                }),
                new Paragraph({
                  text: "  " + gpf
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: "  Nome do projeto: \n",
                  heading: HeadingLevel.HEADING_6
                }),
                new Paragraph("  " + projectName)
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: "  Data de Início: \n",
                  heading: HeadingLevel.HEADING_6,
                  bold: true
                }),
                new Paragraph("  " + season.split(" - ")[0])
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: "  Data de Término: \n",
                  heading: HeadingLevel.HEADING_6,
                  bold: true
                }),
                new Paragraph("  " + season.split(" - ")[1])
              ]
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  text: "  Descrição Geral: " + descricao,
                  heading: HeadingLevel.HEADING_6
                })
              ],
              columnSpan: 4
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  text: "  Etapa 1: " + stageOne,
                  heading: HeadingLevel.HEADING_6
                })
              ],
              columnSpan: 4
            })
          ]
        })
      ],
      width: {
        size: 6000,
        type: WidthType.DXA
      },
      height: {
        size: 19000
      },
      columnWidths: [1500, 4000, 1500, 1700]
    });

    if (stagesList.length > 1) {
      for (var i = 1; i < stagesList.length; i++) {
        var text = "";
        var stageClass = document.getElementsByClassName("stageScope")[i]
        var textarea = stageClass.getElementsByTagName("textarea");
        text = `  Etapa ${i + 1}: ` + textarea[0].textContent;


        table.addChildElement(
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    text: text,
                    heading: HeadingLevel.HEADING_6
                  })
                ],
                columnSpan: 4
              })
            ]
          })
        );
      }
    }

    doc.addSection({
      children: [
        new Paragraph({
          text: "UNIVERSIDADE FEDERAL DO CEARÁ - UFC ",
          heading: HeadingLevel.HEADING_1,
          alignment: AlignmentType.CENTER
        }),
        new Paragraph({
          text: "LABORATÓRIO DE SISTEMAS E BANCO DE DADOS \n",
          heading: HeadingLevel.HEADING_1,
          alignment: AlignmentType.CENTER
        }),
        table
      ]
    });

    Packer.toBuffer(doc).then(buffer => {
      fileDownload(buffer, projectName + "_escopo.doc");
    });
  };

  const getId = season => {
    var id = 0;

    for (var i = 0; i < seasonsArray.length; i++) {
      if (seasonsArray[i].label === season) {
        id = seasonsArray[i].value;
        break;
      }
    }
    return id;
  };

  const handleChangeOne = name => event => {
    setStageOne(event.target.value);
  };

  const handleChangeSeason = event => {
    var len = toDelete.length;
    toDelete.splice(0, len);

    var id = getId(event.target.value);
    var el = scopes.find(function (element) {
      return element.addendumId == id;
    });

    var scopeStages = el.scope.sgppdScopeStage;
    setStageOne(scopeStages[0] ? scopeStages[0].description : "");
    setDescription(el.scope.scope ? el.scope.scope : "");

    var len = stages.length;
    stages.splice(0, len);
    setStagesCount(1);
    setSeason(event.target.value);
    setSeasonId(id);

    var period = scopes.filter(item => item.addendumId === id)[0];
    var scopeStages =
      period.scope && period.scope.sgppdScopeStage.length > 0
        ? period.scope.sgppdScopeStage
        : [];
    var scopesLength = scopeStages.length;
    if (scopesLength > 0) {
      for (var i = 1; i <= scopesLength - 1; i++) {
        var label = scopeStages[i].description;
        var stageId = scopeStages[i].id;

        if (label.length > 1) {
          addStage(label, true, stageId);
        } else {
          addStage();
        }
      }
    }
  };

  const handleChangeDescription = name => event => {
    setDescription(event.target.value);
  };

  const deleteStage = stageForDelete => {
    var toExclude = parseInt(
      document.querySelector("#divStage" + stageForDelete).dataset.stageId
    );
    var index = 0;

    for (var i = 0; i < stages.length; i++) {
      var key = parseInt(stages[i].key);

      if (key === stageForDelete) {
        index = i;
        break;
      }
    }

    var auxList = stages;
    var element = auxList.splice(index, 1);

    setStages(stages.filter(item => item !== element));

    setStagesCount(stagesCount - 1);
    toDelete.push(toExclude);
  };

  const addStage = (stageDescription, loading, stageId) => {
    setValueRequiredMessage(false);
    var value = loading === true && stageDescription ? stageDescription : "";
    var id = stageId !== 0 && stageId !== undefined ? stageId : 0;

    stages.push(
      <div
        id={"divStage" + (stages.length + 2)}
        key={stages.length + 2}
        data-stage-id={id}
        className="stageScope"
      >
        <ScopeStage
          opacity={1}
          deleteStage={deleteStage}
          lastStage={stages.length + 2}
          stageDescription={value}
        />
      </div>
    );
    setStagesCount(stagesCount + 1);
  };

  const getAllStages = () => {
    var period = scopes.filter(item => item.addendumId == seasonId)[0];
    var oldStages =
      period.scope && period.scope.sgppdScopeStage.length > 0
        ? period.scope.sgppdScopeStage
        : [];
    var scopeStages = [];
    var aux = {};
    var allStages = document.getElementsByClassName("stageScope");

    for (var i = 0; i < allStages.length; i++) {
      aux.number = i + 1;
      let stageNumber = allStages[i].id.slice(8);
      aux.description = document.getElementById("stageValue" + stageNumber).value;

      if (aux.number === 1)
        aux.stageId = oldStages[0] ? oldStages[0].id : 0;
      else {
        aux.stageId = parseInt(
          document.querySelector("#divStage" + stageNumber).dataset.stageId
        );
      }

      scopeStages.push(aux);
      aux = {};
    }

    return scopeStages;
  };

  const verifyRequiredFieldIsEmpty = () => {
    var hasEmptyField = false;
    var len = document.getElementsByClassName('stageScope').length;

    for (var i = 0; i < len; i++) {
      var hasNoDescription = document.getElementById("stageValue" + (i + 1)).value === "";

      if (hasNoDescription) {
        hasEmptyField = true;
        break;
      }
    }

    return hasEmptyField;
  };

  const handleSubmit = () => {
    var hasEmptyField = verifyRequiredFieldIsEmpty();

    if (!hasEmptyField) {
      setValueRequiredMessage(false);
      var obj = {
        generalDescription: "",
        addendumId: "",
        stages: [],
        toDelete: []
      };

      obj["generalDescription"] = generalDescription;
      obj["addendumId"] = seasonId;
      obj["stages"] = getAllStages();
      obj["toDelete"] = toDelete.filter(el => el !== 0);

      submitScope(obj);
    } else setValueRequiredMessage(true);
  };

  const clearRiqueredMessage = () => {
    setValueRequiredMessage(false);
  };

  const transitions = useTransition(loading || delay, null, {
    from: { opacity: 0 },
    enter: {
      opacity: 1
    },
    leave: { opacity: 0 }
  });

  return transitions.map(({ item, key, props }) =>
    item ? (
      <animated.div key={key} style={props}>
        <></>
      </animated.div>
    ) : (
        <animated.div key={key} style={props}>
            <div style={{ paddingBottom: "150px" }}>
              <Paper className={classes.root}>
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  open={snackBar.open}
                  onClose={handleSnackClose}
                  TransitionComponent={Slide}
                  transitionDuration={{ enter: 500, exit: 500 }}
                  ContentProps={{
                    "aria-describedby": "message-id"
                  }}
                  autoHideDuration={2000}
                >
                  <MySnackbarContentWrapper
                    onClose={handleSnackClose}
                    variant={
                      responseType !== ""
                        ? responseType.toLowerCase()
                        : allowanceResponseType.toLowerCase()
                    }
                    message={
                      <span id="message-id">
                        {response !== "" ? response : allowanceResponse}
                      </span>
                    }
                  />
                </Snackbar>
                <div className={classes.header}>
                  {t("PROJECTS.scope").toUpperCase()}
                  {hasCooperationTerm &&
                  <Tooltip
                    title={t("GENERAL.downloadDocument")}
                    aria-label="details"
                    placement="top"
                    onClick={createFile}
                  >
                    <IconButton>
                      <GetApp />
                    </IconButton>
                  </Tooltip>}
                </div>
                {hasCooperationTerm ?
                <div>
                <div className={classes.bodyForm}>
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <TextField
                        disabled
                        className={classes.textField}
                        label={t("PROJECTS.gpfUpperCase")}
                        value={gpf}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        disabled
                        className={classes.textField}
                        label={t("PROJECTS.projectName")}
                        value={projectName}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        select
                        id="season"
                        label={t("PROJECTS.season")}
                        className={classes.textField}
                        name={season}
                        margin="normal"
                        value={season}
                        onChange={handleChangeSeason}
                      >
                        {seasonsArray.map(option => (
                          <MenuItem key={option.value} value={option.label}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        id="generalDesc"
                        multiline
                        fullWidth
                        variant="outlined"
                        rows="6"
                        label={t("SCOPE.generalDescription")}
                        margin="normal"
                        value={generalDescription}
                        onChange={handleChangeDescription("generalDescription")}
                      />
                    </Grid>
                  </Grid>
                </div>
              <div id="divStage1" className="stageScope">
                <Paper className={classes.root}>
                  <div className={classes.bodyForm}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Tooltip
                          title="Adicionar Etapa"
                          aria-label="adicionar"
                          placement="top"
                        >
                          <IconButton
                            style={{ float: "right" }}
                            onClick={addStage}
                            className={classes.icons}
                          >
                            <AddCircle />
                          </IconButton>
                        </Tooltip>
                        <MuiThemeProvider theme={tooltipTheme}>
                          <Tooltip
                            TransitionComponent={Zoom}
                            open={!stageOne && showRequiredFieldMessage}
                            title={t("GENERAL.requiredField")}
                          >
                            <TextField
                              id="stageValue1"
                              multiline
                              fullWidth
                              variant="outlined"
                              rows="6"
                              label={t("SCOPE.stageOne")}
                              margin="normal"
                              value={stageOne}
                              onChange={handleChangeOne("stageOne")}
                              onFocus={clearRiqueredMessage}
                            />
                          </Tooltip>
                        </MuiThemeProvider>
                      </Grid>
                    </Grid>
                  </div>
                </Paper>
              </div>
              <div>{stages}</div>
              <Fab
                color="primary"
                aria-label="update"
                onClick={handleSubmit}
                className={classes.fab}
              >
                <Save />
              </Fab>
            </div>
            :
              <p className={classes.Hint}>
                {t("PHASES.hintNoAddendum")}
              </p>
          }
          </Paper>
          </div>
        </animated.div>
      )
  );
};

export default Scope;