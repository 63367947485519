/* eslint-disable */
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ArrowBack from "@material-ui/icons/ArrowBack";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Footer from "components/layout/Footer";
import Spinner from "components/layout/spinners/Index";
import ProfilesContext from "context/profiles/profilesContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import DashboardNavBar from "../layout/DashboardNavBar";
import SideBar from "../layout/sidebar/Sidebar";
import { useStyles } from "./ProfileStyles";

const CustomSwitch = withStyles({
  switchBase: {
    "&$checked": {
      color: "#2c3e51"
    },
    "&$checked + $track": {
      backgroundColor: "#2c3e51"
    }
  },
  checked: {},
  track: {}
})(Switch);

// const renderRedirect = go => {
//   if (go) return <Redirect to={"/profiles"} />;
// };

const ViewProfile = props => {
  const { id } = props;
  const classes = useStyles();
  const [t] = useTranslation();
  const profilesContext = useContext(ProfilesContext);
  const {
    loading,
    profile,
    getProfile,
    permissions,
    resetMessage
  } = profilesContext;
  const [state, setState] = useState({
    id: null,
    name: "",
    description: "",
    PROFILE: {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    USER: {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    PARTNER: {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    PROJECTSEXEC:{
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    PROJECTSPLAN:{
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    GENERALINFORMATION:{
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    COOPERATIONTERM: {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    SCOPE: {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    GOAL: {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    SCHEDULE: {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    BUBGETPLAN: {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    EXPENSES: {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    HEADINGS2: {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    ACTIVITIES: {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    PARTICIPANTS: {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    PHASES: {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    USERCOSTS: {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    TEMPFIANCIALSREPORT:{
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    GENERALFIANREPORT:
    {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    MANAGEMENTREPORT:{
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    BUDGETEXECUTION: {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    }
  });

  useEffect(() => {
    resetMessage();
    getProfile(id);
    var obj = {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    };
    setState({
      ...state,
      id: id,
      name: profile.name,
      description: profile.description,
      PROFILE: permissions.PROFILE ? permissions.PROFILE : obj,
      USER: permissions.USER ? permissions.USER : obj,
      PARTNER: permissions.PARTNER ? permissions.PARTNER : obj,
      PROJECTSPLAN: permissions.PROJECTSPLAN ? permissions.PROJECTSPLAN : obj,
      GENERALINFORMATION: permissions.GENERALINFORMATION ? permissions.GENERALINFORMATION : obj,
      COOPERATIONTERM: permissions.COOPERATIONTERM
        ? permissions.COOPERATIONTERM
        : obj,
      SCOPE: permissions.SCOPE ? permissions.SCOPE : obj,
      GOAL: permissions.GOAL ? permissions.GOAL : obj,
      SCHEDULE: permissions.SCHEDULE ? permissions.SCHEDULE : obj,
      BUBGETPLAN: permissions.BUBGETPLAN ? permissions.BUBGETPLAN : obj,
      PROJECTSEXEC: permissions.PROJECTSEXEC ? permissions.PROJECTSEXEC : obj,
      PLANNEDACTIVITIES: permissions.PLANNEDACTIVITIES
        ? permissions.PLANNEDACTIVITIES
        : obj,
      HEADINGS2: permissions.HEADINGS2 ? permissions.HEADINGS2 : obj,
      PARTICIPANTS: permissions.PARTICIPANTS ? permissions.PARTICIPANTS : obj,
      PHASES: permissions.PHASES ? permissions.PHASES : obj,
      ACTIVITIES: permissions.ACTIVITIES ? permissions.ACTIVITIES : obj,
      EXPENSES: permissions.EXPENSES ? permissions.EXPENSES : obj,
      TEMPFIANCIALSREPORT: permissions.TEMPFIANCIALSREPORT ? permissions.TEMPFIANCIALSREPORT : obj,
      GENERALFIANREPORT: permissions.GENERALFIANREPORT ? permissions.GENERALFIANREPORT : obj,
      BUDGETEXECUTION: permissions.BUDGETEXECUTION
        ? permissions.BUDGETEXECUTION
        : obj,
      MANAGEMENTREPORT: permissions.MANAGEMENTREPORT ? permissions.MANAGEMENTREPORT : obj,
      USERCOSTS: permissions.USERCOSTS ? permissions.USERCOSTS : obj

    });
    return () => { };
  }, [profile.name, profile.description, JSON.stringify(permissions)]);

  const transitions = useTransition(true, null, {
    from: { opacity: 0 },
    enter: {
      opacity: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "calc(100vh - 70px)"
    },
    leave: { opacity: 0 }
  });

  return transitions.map(({ key, props }) =>
    loading ? (
      <animated.div key={key} style={props}>
        <Spinner />;
      </animated.div>
    ) : (
        <animated.div key={key} style={props}>
          <div className="container">
            <Breadcrumbs
              className={classes.breadCrumbs}
              aria-label="breadcrumb"
              separator={<NavigateNextIcon fontSize="small" />}
            >
              <Typography>{t("GENERAL.planning")}</Typography>
              <Typography>{t("ROUTES.config")}</Typography>
              <Link className={classes.link} to="/profiles">
                {t("PROFILES.profiles")}
              </Link>
              <Typography>{t("GENERAL.view")}</Typography>
            </Breadcrumbs>
            <div className="container-header">
              <div className="title">
                <h1 className={classes.title}>
                  {t("GENERAL.view") + " " + t("PROFILES.profile")}
                </h1>
              </div>
              <Link to={"/profiles"}>
                <Button
                  className={classes.blueButton}
                  variant="contained"
                  color="primary"
                >
                  <div className={classes.buttonIcon}>
                    <ArrowBack />
                  </div>

                  {t("GENERAL.back").toUpperCase()}
                </Button>
              </Link>
            </div>
            <form id="formProfiles">
              <Paper className={classes.rootForm}>
                <div className="cabecalho">
                  {t("PROFILES.profile").toUpperCase()}
                </div>
                <Grid container item>
                  <React.Fragment>
                    <Grid item xs={12}>
                      {/* <p className={classes.newProfileHint}>
                        {t("PROFILES.addHint")}
                      </p> */}
                    </Grid>
                    <Grid item xs={5} className={classes.inputBox}>
                      <TextField
                        required
                        className={classes.textField}
                        margin="normal"
                        label={t("PROFILES.nameLabel")}
                        name="name"
                        InputProps={{
                          readOnly: true,
                          maxLength: 50
                        }}
                        value={state.name}
                      />
                      <div className={classes.hintsDivFather}>
                        <span className={classes.newProfileRequired}>
                          {t("PROFILES.required")}
                        </span>
                        <span className={classes.textFieldLimit}>
                          {state.name ? state.name.length : "?"}/50
                      </span>
                      </div>
                    </Grid>
                  </React.Fragment>
                  <Grid item xs={7} className={classes.inputBox}>
                    <TextField
                      className={classes.textField}
                      label={t("PROFILES.descriptionLabel")}
                      margin="normal"
                      name="description"
                      value={state.description}
                      InputProps={{
                        readOnly: true
                      }}
                    />
                    <div className={classes.hintsDivFatherRight}>
                      <span className={classes.textFieldLimit}>
                        {state.description ? state.description.length : "?"}/110
                    </span>
                    </div>
                  </Grid>
                </Grid>
              </Paper>

              <Paper className={classes.rootForm}>
                <div className="cabecalho">{t("PROFILES.form.title")}</div>
                <table className="table">
                  <thead>
                    <tr>
                      <th>{t("PROFILES.form.module")}</th>
                      <th>{t("PROFILES.form.section")}</th>
                      <th>{t("PROFILES.form.add")}</th>
                      <th>{t("PROFILES.form.view")}</th>
                      <th>{t("PROFILES.form.edit")}</th>
                      <th>{t("PROFILES.form.del")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td rowSpan="4" className="secao">
                        {t("PROFILES.form.config")}
                      </td>
                    </tr>
                    <tr>
                      <td className="secao">{t("PROFILES.profile")}</td>
                      <td>
                        <CustomSwitch
                          name="PROFILE"
                          checked={state.PROFILE.allowCreate}
                          disabled
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="PROFILE"
                          checked={state.PROFILE.allowView}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="PROFILE"
                          checked={state.PROFILE.allowEdit}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="PROFILE"
                          checked={state.PROFILE.allowDelete}
                          color="primary"
                          disabled
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="secao">{t("USERS.users")}</td>
                      <td>
                        <CustomSwitch
                          name="USER"
                          checked={state.USER.allowCreate}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="USER"
                          checked={state.USER.allowView}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="USER"
                          checked={state.USER.allowEdit}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="USER"
                          checked={state.USER.allowDelete}
                          color="primary"
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="secao">{t("PARTNERS.partners")}</td>
                      <td>
                        <CustomSwitch
                          name="PARTNER"
                          checked={state.PARTNER.allowCreate}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="PARTNER"
                          checked={state.PARTNER.allowView}
                          color="primary"
                          disabled
                        />
                      </td>

                      <td>
                        <CustomSwitch
                          name="PARTNER"
                          checked={state.PARTNER.allowEdit}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="PARTNER"
                          checked={state.PARTNER.allowDelete}
                          color="primary"
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan="9" className="secao">
                        {t("PROFILES.form.workPlan")}
                      </td>
                    </tr>

                    <tr>
                      <td className="secao">
                        {t("PROFILES.form.projects")}
                      </td>
                      <td>
                        <CustomSwitch
                          name="PROJECTSPLAN"
                          checked={state.PROJECTSPLAN.allowCreate}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="PROJECTSPLAN"
                          checked={state.PROJECTSPLAN.allowView}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="PROJECTSPLAN"
                          checked={state.PROJECTSPLAN.allowEdit}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="PROJECTSPLAN"
                          checked={state.PROJECTSPLAN.allowDelete}
                          color="primary"
                          disabled
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="secao">
                        {t("PROFILES.form.generalInformation")}
                      </td>
                      <td>
                        <CustomSwitch
                          name="GENERALINFORMATION"
                          checked={state.GENERALINFORMATION.allowCreate}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="GENERALINFORMATION"
                          checked={state.GENERALINFORMATION.allowView}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="GENERALINFORMATION"
                          checked={state.GENERALINFORMATION.allowEdit}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="GENERALINFORMATION"
                          checked={state.GENERALINFORMATION.allowDelete}
                          color="primary"
                          disabled
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="secao">
                        {t("PROFILES.form.cooperationTerm")}
                      </td>
                      <td>
                        <CustomSwitch
                          name="COOPERATIONTERM"
                          checked={state.COOPERATIONTERM.allowCreate}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="COOPERATIONTERM"
                          checked={state.COOPERATIONTERM.allowView}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="COOPERATIONTERM"
                          checked={state.COOPERATIONTERM.allowEdit}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="COOPERATIONTERM"
                          checked={state.COOPERATIONTERM.allowDelete}
                          color="primary"
                          disabled
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="secao">{t("PROFILES.form.goal")}</td>
                      <td>
                        <CustomSwitch
                          name="GOAL"
                          checked={state.GOAL.allowCreate}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="GOAL"
                          checked={state.GOAL.allowView}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="GOAL"
                          checked={state.GOAL.allowEdit}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="GOAL"
                          checked={state.GOAL.allowDelete}
                          color="primary"
                          disabled
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="secao">{t("PROFILES.form.scope")}</td>
                      <td>
                        <CustomSwitch
                          name="SCOPE"
                          checked={state.SCOPE.allowCreate}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="SCOPE"
                          checked={state.SCOPE.allowView}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="SCOPE"
                          checked={state.SCOPE.allowEdit}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="SCOPE"
                          checked={state.SCOPE.allowDelete}
                          color="primary"
                          disabled
                        />
                      </td>
                    </tr>
                    
                    <tr>
                      <td className="secao">{t("PROFILES.form.headings")}</td>
                      <td>
                        <CustomSwitch
                          name="HEADINGS2"
                          checked={state.HEADINGS2.allowCreate}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="HEADINGS2"
                          checked={state.HEADINGS2.allowView}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="HEADINGS2"
                          checked={state.HEADINGS2.allowEdit}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="HEADINGS2"
                          checked={state.HEADINGS2.allowDelete}
                          color="primary"
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="secao">{t("PROFILES.form.schedule")}</td>
                      <td>
                        <CustomSwitch
                          name="SCHEDULE"
                          checked={state.SCHEDULE.allowCreate}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="SCHEDULE"
                          checked={state.SCHEDULE.allowView}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="SCHEDULE"
                          checked={state.SCHEDULE.allowEdit}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="SCHEDULE"
                          checked={state.SCHEDULE.allowDelete}
                          color="primary"
                          disabled
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="secao">
                        {t("PROFILES.form.budget")}
                      </td>
                      <td>
                        <CustomSwitch
                          name="BUBGETPLAN"
                          checked={state.BUBGETPLAN.allowCreate}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="BUBGETPLAN"
                          checked={state.BUBGETPLAN.allowView}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="BUBGETPLAN"
                          checked={state.BUBGETPLAN.allowEdit}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="BUBGETPLAN"
                          checked={state.BUBGETPLAN.allowDelete}
                          color="primary"
                          disabled
                        />
                      </td>
                    </tr>

                    <tr>
                      <td rowSpan="11" className="secao">
                        {t("PROFILES.form.labor")}
                      </td>
                    </tr>

                    <tr>
                      <td className="secao">
                        {t("PROFILES.form.projects")}
                      </td>
                      <td>
                        <CustomSwitch
                          name="PROJECTSEXEC"
                          checked={state.PROJECTSEXEC.allowCreate}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="PROJECTSEXEC"
                          checked={state.PROJECTSEXEC.allowView}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="PROJECTSEXEC"
                          checked={state.PROJECTSEXEC.allowEdit}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="PROJECTSEXEC"
                          checked={state.PROJECTSEXEC.allowDelete}
                          color="primary"
                          disabled
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="secao">
                        {t("PROFILES.form.participants")}
                      </td>
                      <td>
                        <CustomSwitch
                          name="PARTICIPANTS"
                          checked={state.PARTICIPANTS.allowCreate}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="PARTICIPANTS"
                          checked={state.PARTICIPANTS.allowView}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="PARTICIPANTS"
                          checked={state.PARTICIPANTS.allowEdit}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="PARTICIPANTS"
                          checked={state.PARTICIPANTS.allowDelete}
                          color="primary"
                          disabled
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="secao">{t("PROFILES.form.phases")}</td>
                      <td>
                        <CustomSwitch
                          name="PHASES"
                          checked={state.PHASES.allowCreate}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="PHASES"
                          checked={state.PHASES.allowView}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="PHASES"
                          checked={state.PHASES.allowEdit}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="PHASES"
                          checked={state.PHASES.allowDelete}
                          color="primary"
                          disabled
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="secao">{t("PROFILES.form.activities")}</td>
                      <td>
                        <CustomSwitch
                          name="ACTIVITIES"
                          checked={state.ACTIVITIES.allowCreate}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="ACTIVITIES"
                          checked={state.ACTIVITIES.allowView}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="ACTIVITIES"
                          checked={state.ACTIVITIES.allowEdit}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="ACTIVITIES"
                          checked={state.ACTIVITIES.allowDelete}
                          color="primary"
                          disabled
                        />
                      </td>
                    </tr>


                    <tr>
                      <td className="secao">{t("PROFILES.form.expenses")}</td>
                      <td>
                        <CustomSwitch
                          name="EXPENSES"
                          checked={state.EXPENSES.allowCreate}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="EXPENSES"
                          checked={state.EXPENSES.allowView}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="EXPENSES"
                          checked={state.EXPENSES.allowEdit}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="EXPENSES"
                          checked={state.EXPENSES.allowDelete}
                          color="primary"
                          disabled
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="secao">
                        {t("PROFILES.form.budgetExecution")}
                      </td>
                      <td>
                        <CustomSwitch
                          name="BUDGETEXECUTION"
                          checked={state.BUDGETEXECUTION.allowCreate}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="BUDGETEXECUTION"
                          checked={state.BUDGETEXECUTION.allowView}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="BUDGETEXECUTION"
                          checked={state.BUDGETEXECUTION.allowEdit}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="BUDGETEXECUTION"
                          checked={state.BUDGETEXECUTION.allowDelete}
                          color="primary"
                          disabled
                        />
                      </td>
                    </tr>
                    
                    <tr>
                      <td className="secao">{t("PROFILES.form.tempFinancialReports")}</td>
                      <td>
                        <CustomSwitch
                          name="TEMPFIANCIALSREPORT"
                          checked={state.TEMPFIANCIALSREPORT.allowCreate}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="TEMPFIANCIALSREPORT"
                          checked={state.TEMPFIANCIALSREPORT.allowView}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="TEMPFIANCIALSREPORT"
                          checked={state.TEMPFIANCIALSREPORT.allowEdit}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="TEMPFIANCIALSREPORT"
                          checked={state.TEMPFIANCIALSREPORT.allowDelete}
                          color="primary"
                          disabled
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="secao">{t("PROFILES.form.genetalFinanReports")}</td>
                      <td>
                        <CustomSwitch
                          name="GENERALFIANREPORT"
                          checked={state.GENERALFIANREPORT.allowCreate}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="GENERALFIANREPORT"
                          checked={state.GENERALFIANREPORT.allowView}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="GENERALFIANREPORT"
                          checked={state.GENERALFIANREPORT.allowEdit}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="GENERALFIANREPORT"
                          checked={state.GENERALFIANREPORT.allowDelete}
                          color="primary"
                          disabled
                        />
                      </td>
                    </tr>

                    {/* <tr>
                      <td className="secao">{t("PROFILES.form.manegementReports")}</td>
                      <td>
                        <CustomSwitch
                          name="MANAGEMENTREPORT"
                          checked={state.MANAGEMENTREPORT.allowCreate}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="MANAGEMENTREPORT"
                          checked={state.MANAGEMENTREPORT.allowView}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="MANAGEMENTREPORT"
                          checked={state.MANAGEMENTREPORT.allowEdit}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="MANAGEMENTREPORT"
                          checked={state.MANAGEMENTREPORT.allowDelete}
                          color="primary"
                          disabled
                        />
                      </td>
                    </tr> */}

                    <tr>
                      <td className="secao">{t("PROFILES.form.userCosts")}</td>
                      <td>
                        <CustomSwitch
                          name="USERCOSTS"
                          checked={state.USERCOSTS.allowCreate}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="USERCOSTS"
                          checked={state.USERCOSTS.allowView}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="USERCOSTS"
                          checked={state.USERCOSTS.allowEdit}
                          color="primary"
                          disabled
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="USERCOSTS"
                          checked={state.USERCOSTS.allowDelete}
                          color="primary"
                          disabled
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Paper>
            </form>
          </div>
          <Footer />
        </animated.div>
      )
  );
};

export default ViewProfile;
