import MomentUtils from "@date-io/moment";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import Delete from "@material-ui/icons/Delete";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useStyles } from "components/layout/CommonStyles";
import ProjectsContext from "context/projects/projectsContext";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

const tooltipTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#2c3e51"
        }
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                color: "white",
                fontSize: "1em",
                backgroundColor: "red",
                opacity: "0.7"
            }
        }
    }
});

const NewQuota = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const requiredField = "GENERAL.requiredField";
    const {
        deleteQuota,
        lastQuota,
        minDate,
        maxDate,
        arrayNumbers,
        quotaNumber,
        quotavalue,
        quotaDate
    } = props;
    const [value, setValue] = useState(quotavalue);
    const [number, setNumber] = useState(quotaNumber);
    const [selectedDate, setSelectedDate] = useState(quotaDate);
    const projectsContext = useContext(ProjectsContext);
    const { showRequiredFieldMessage, setValueRequiredMessage } = projectsContext;

    const converTotBrCurrency = number => {
        var temp = "";
        var len = number.length;
        var count = 0;

        for (var i = 0; i < len; i++) {
            if (i === 1) {
                var aux = "," + number[i] + temp;
                temp = aux;
            } else {
                if (count === 3) {
                    count = 1;
                    var aux = number[i] + "." + temp;
                    temp = aux;
                } else {
                    if (i > 1) {
                        count += 1;
                    }
                    var aux = number[i] + temp;
                    temp = aux;
                }
            }
        }

        return temp;
    };

    const handleValueChange = event => {
        if (event.target.value !== " " && !event.target.value.includes(" ")) {
            var str = event.target.value.split("");
            var aux = str.filter(el => el !== "." && el !== ",").join("");
            var value = event.target.value;

            if (event.target.value) {
                value = converTotBrCurrency(
                    aux
                        .split("")
                        .reverse()
                        .join("")
                );
            }

            aux = value.split("").filter(el => el !== ".").join("").replace(",", ".")
            if (!isNaN(aux)) {
                setValue(value);
            }
        }
    };

    const handleChangeNumber = event => {
        setNumber(event.target.value);
    };

    const handleDateChange = event => {
        setSelectedDate(event);
    };

    const handleClick = () => {
        deleteQuota(lastQuota);
    };

    const clearRequiredMessage = () => {
        setValueRequiredMessage(false);
    };

    return (
        <div className={classes.bodyForm}>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <MuiThemeProvider theme={tooltipTheme}>
                        <Tooltip
                            TransitionComponent={Zoom}
                            open={(number.length === 0 || number === 0) && showRequiredFieldMessage}
                            title={t(requiredField)}
                        >
                            <TextField
                                select
                                id={"numberOfQuota" + lastQuota}
                                className={classes.textField}
                                name={number}
                                margin="normal"
                                value={number}
                                onChange={handleChangeNumber}
                                onFocus={clearRequiredMessage}
                            >
                                {arrayNumbers.map(option => (
                                    <MenuItem key={option.value} value={option.label}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Tooltip>
                    </MuiThemeProvider>
                </Grid>
                <Grid item xs={4}>
                    <MuiThemeProvider theme={tooltipTheme}>
                        <Tooltip
                            TransitionComponent={Zoom}
                            open={value.length === 0 && showRequiredFieldMessage}
                            title={t(requiredField)}
                        >
                            <TextField
                                id={"quotaValue" + lastQuota}
                                className={classes.textField}
                                margin="normal"
                                name="quotaValue"
                                value={value}
                                onChange={handleValueChange}
                                onFocus={clearRequiredMessage}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">R$</InputAdornment>
                                    )
                                }}
                            />
                        </Tooltip>
                    </MuiThemeProvider>
                </Grid>
                <Grid item xs={3}>
                    <MuiThemeProvider theme={tooltipTheme}>
                        <Tooltip
                            TransitionComponent={Zoom}
                            open={selectedDate.length === 0 && showRequiredFieldMessage}
                            title={t(requiredField)}
                        >
                            <MuiPickersUtilsProvider locale="pt" utils={MomentUtils}>
                                <DatePicker
                                    fullWidth
                                    id={"quotaDate" + lastQuota}
                                    variant="inline"
                                    openTo="year"
                                    name="yearBase"
                                    views={["year", "month"]}
                                    margin="normal"
                                    autoOk={true}
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    onFocus={clearRequiredMessage}
                                    minDate={minDate}
                                    maxDate={maxDate}
                                />
                            </MuiPickersUtilsProvider>
                        </Tooltip>
                    </MuiThemeProvider>
                </Grid>
                <Grid item xs={1} style={{ marginTop: "8px" }}>
                    <Tooltip title="Deletar" aria-label="delete" placement="top">
                        <IconButton
                            onClick={handleClick}
                            style={{ float: "right" }}
                            className={classes.icons}
                        >
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <div className="lineBreak"></div>
            </Grid>
        </div>
    );
};

export default NewQuota;