/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";

import Paper from "@material-ui/core/Paper";
import Box from '@material-ui/core/Box';
import SettingsContext from "context/hrModule/settings/settingsContext";
import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";
import Grid from "@material-ui/core/Grid";
import Input from "components/inputs/Input";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Redirect } from "react-router-dom";


import Transition from "components/layout/transition/Transition";
import JobCollaboratorsTable from "./JobCollaboratorsTable";
import JobHistoryTable from "./History/HistoryTable";


import Buttons from "components/buttons/Pressed";

const JobForm = (props) => {

    const classes = useStyles();
    const [t] = useTranslation();
    const settingsContext = useContext(SettingsContext);
    const { id, jobId, context, ...others } = props;
    const [mustRedirect, setMustRedirect] = useState(false);

    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }

    const {
        loading,
        setLoading,
        setLoadingFalse,
        getJobs,
        jobs,
        job,
        getJob,
        updateJob
    } = settingsContext

    const [values, setValues] = useState({
        id: "",
        name: "",
        collaboratorsNumber: "",
        description: "",
        collaborators: [],
        history: []
    });

    const [canClick, setCanClick] = useState(false);
    const [requiredName, setRequiredName] = useState(false);
    const [nameErrorMessage, setNameErrorMessage] = useState("");
    const [viewMore, setViewMore] = useState(false);

    async function fetchData(jobId) {
        await setLoading();
        await getJob(jobId);
        getJobs();
        await setLoadingFalse();
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData(jobId);
    }, []);

    const redirect = go => {
        if (go) return <Redirect to={`/Jobs`} />;
    };

    useEffect(() => {
        if (job) {
            setValues({
                id: job.id ? job.id : null,
                name: job.name ? job.name : "",
                description: job.description ? job.description : "",
                collaboratorsNumber: job.collaboratorsNumber ? job.collaboratorsNumber : 0,
                collaborators: job.collaborators ? job.collaborators : [],
                history: job.history ? job.history : []
            });
        }
    }, [job])


    const handleChange = (name, value) => {
        let val = value;
        setValues({ ...values, [name]: val })
    }

    const handleCancel = () => {
        setMustRedirect(true);
    }

    const verifyApply = () => {
        let res = true;
        if (jobs.map(x => x.name !== job.name && !x.hasDeleted ? x.name.toLowerCase().trim() : null).includes(values.name.toLowerCase().trim())) {
            res = false;
            setRequiredName(true);
            setNameErrorMessage(t("HR.jobNameAlreadyInUse"))
        }

        if (!values.name.trim()) {
            res = false;
            setRequiredName(true);
            setNameErrorMessage(t("GENERAL.requiredField"))
        }
        return res;
    }

    const handleApply = () => {
        const canApply = verifyApply();
        if (canApply) {
            let formData = new FormData();

            formData.append("job.id", values.id ? values.id : 0);
            formData.append("job.name", values.name ? values.name : "");
            formData.append("job.description", values.description ? values.description : "");
            formData.append("user", auth.id ? auth.id : 0);


            updateJob(formData, setMustRedirect);
        }
    }


    return (
        <React.Fragment>
            {redirect(mustRedirect)}

            <Transition loading={loading} newDesign={true}>
                <Paper>
                    <React.Fragment>
                        <div className={classes.familyDataForm}>

                            <Grid container item spacing={2} md={12} lg={12} style={{ paddingRight: "50px" }}>
                                <Grid item xs={12} sm={5}>
                                    <Input
                                        required
                                        context={context}
                                        label={"HR.jobName"}
                                        value={values.name}
                                        valueName={"name"}
                                        maxLength={50}
                                        handleChange={handleChange}
                                        error={requiredName}
                                        helperText={requiredName && nameErrorMessage}
                                        setRequired={setRequiredName}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <Input
                                        disabled
                                        label={"HR.jobCollaboratorsNumber"}
                                        value={values.collaboratorsNumber}
                                    />
                                </Grid>
                                <Box width="100%" />

                                <Grid item xs={12} sm={7}>
                                    <Input
                                        disabled={context === "view" ? true : false}
                                        label={"GENERAL.description"}
                                        value={values.description}
                                        valueName={"description"}
                                        rows={5}
                                        maxLength={500}
                                        handleChange={handleChange}
                                    />
                                </Grid>


                                <Box width="100%" />


                            </Grid>

                            {context === "edit" ?

                                <div style={{ paddingLeft: "10px", paddingBottom: "40px", paddingTop: "40px" }}>
                                    <Buttons
                                        onClick={handleCancel}
                                        tipo="BN-noback"
                                        conteudo={t("GENERAL.cancel")}
                                        style={{ marginRight: "30px" }}
                                    />
                                    <Buttons
                                        onClick={handleApply}
                                        tipo="BN-blue"
                                        conteudo={t("GENERAL.update")}
                                    />
                                </div>
                                : null}

                            <Typography className={classes.headerColor}><b>{t("HR.allCollaboratorsWithPosition")}</b></Typography>

                            <Paper style={{ marginRight: "50px", marginTop: "30px", marginBottom: "20px" }}>
                                <div className="cabecalho">
                                    {t("HR.collaborators").toUpperCase()}
                                </div>

                                <JobCollaboratorsTable
                                    rows={values.collaborators}
                                    toFirstPage={canClick}
                                    headers={["id", "name", "status", "admissionDate"]}
                                    visibleHeaders={["Nome do Colaborador", "Status", "Data de Admissão"]}
                                    sortedFields={["name", "status", "admissionDate"]}
                                    mainField="name"
                                />
                                {values.collaborators.length === 0 &&
                                    <div className={classes.noParticipantsMessage}>
                                        <h2>{t("HR.noCollaboratorsMessage")}</h2>
                                        <h4>{t("HR.noCollaboratorsSubMessage")}</h4>
                                    </div>
                                }

                            </Paper>

                            <Paper style={{ marginRight: "50px", marginTop: "30px", marginBottom: "20px" }}>
                                <div className="cabecalho">
                                    <Button style={{ display: "flex", justifyContent: "space-between", width: "100%" }} onClick={() => setViewMore(!viewMore)}>
                                        {t("HR.history").toUpperCase()}

                                        <IconButton
                                            style={{ float: "right" }}
                                            className={classes.removerHover}

                                        >
                                            {viewMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </IconButton>
                                    </Button>
                                </div>

                                {viewMore &&
                                    <React.Fragment>
                                        <JobHistoryTable
                                            rows={values.history}
                                            toFirstPage={canClick}
                                            headers={["id", "type", "user", "date", "newValueName", "oldValueName", "newValueDescription", "oldValueDescription"]}
                                            visibleHeaders={["Operação", "Usuário", "Data", ""]}
                                            sortedFields={["type", "user", "date"]}
                                            mainField="type"
                                        />
                                        {values.history.length === 0 &&
                                            <div className={classes.noParticipantsMessage}>
                                                <h2>{t("HR.noHistoryMessage")}</h2>
                                                <h4>{t("HR.noHistorySubMessage")}</h4>
                                            </div>
                                        }
                                    </React.Fragment>
                                }

                            </Paper>

                        </div>

                    </React.Fragment>
                </Paper>
            </Transition>
        </React.Fragment >
    );
};

export default JobForm;