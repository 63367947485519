/**
 * The clock record statuses.
 */
export const clockRecordStatus = Object.freeze({
    IN_PROGRESS: 'IN_PROGRESS',
    INCONSISTENCY: 'INCONSISTENCY',
    REGULAR: 'REGULAR',
    VACATION: 'VACATION',
    HOLIDAY: 'HOLIDAY',
    RECESS: 'RECESS',
    WEEKEND: 'WEEKEND',
    JUSTIFIED: 'JUSTIFIED'
});

/**
 * The clock record statuses translation keys.
 */
export const clockRecordStatusi18n = Object.freeze({
    IN_PROGRESS: 'GENERAL.inProgress',
    INCONSISTENCY: 'GENERAL.inconsistency',
    REGULAR: 'GENERAL.regular',
    VACATION: 'GENERAL.vacation',
    HOLIDAY: 'GENERAL.holiday',
    RECESS: 'GENERAL.recess',
    WEEKEND: 'GENERAL.weekend',
    JUSTIFIED: 'HR.justified'
});
