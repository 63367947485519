/* eslint-disable */
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import EventIcon from '@material-ui/icons/Event';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Dissatisfied from '@material-ui/icons/SentimentVeryDissatisfied';
import Footer from 'components/layout/Footer';
import React, { useContext, useEffect, useState, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import { DateUtils } from 'react-day-picker';
import MySnackbarContentWrapper from 'components/layout/Message';
import { Link } from 'react-router-dom';
import SettingsContext from 'context/hrModule/settings/settingsContext';
import AllowancesContext from 'context/allowance/allowanceContext';
import Transition from 'components/layout/transition/Transition';
import Breadcrumb from 'components/layout/breadcrumb/Breadcrumb';
import InputFilter from 'components/inputs/InputFilter';
import InputFilterDate from 'components/inputs/InputFilterDate';
import FilterContainer from 'components/containers/FilterContainer';
import Close from '@material-ui/icons/Close';
import InputAdornment from '@material-ui/core/InputAdornment';
import HolidaysAndBreaksTable from './HolidaysAndBreaksTable';
import DialogBreakPeriod from './DialogBreakPeriod';
import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';
import { Screen, Allow } from 'global/constants';

import { screenStyles } from './Styles';

import { dateToBR } from 'utils/dates/DateToBRr';
import { FormatDateToFront } from 'utils/dates/FormatDateToFront';

const HolidaysAndBreaks = props => {
    const classes = useStyles();
    const styles = screenStyles();
    const [t] = useTranslation();
    const settingsContext = useContext(SettingsContext);
    const allowanceContext = useContext(AllowancesContext);
    const [findText, setFindText] = useState('');

    const [filterName, setFilterName] = useState('');
    const [filterType, setFilterType] = useState('');
    const [filterDate, setFilterDate] = useState(null);

    const [breakPeriodText, setBreakPeriodText] = useState('');

    const [breakPeriod, setBreakPeriod] = useState({
        start: null,
        end: null
    });
    const [openDialogBreakPeriod, setOpenDialogBreakPeriod] = useState(false);
    const [dataFilter, setDataFilter] = useState([]);
    const [canClick, setCanClick] = useState(false);
    const [showDatePicker, setShowDatePicker] = useState(false);

    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }
    const [snackBar, setSnackBar] = useState({
        open: false,
        kind: '',
        content: ''
    });

    const {
        loading,
        hasChanged,
        responseType,
        response,
        successiveActions,
        setHasChanged,
        setLoading,
        setLoadingFalse,
        holidaysAndBreaks,
        getHolidaysAndBreaks
    } = settingsContext;

    const {
        allowanceResponse,
        allowanceResponseType,
        resetAllowanceMessage,
        messageHasChanged
    } = allowanceContext;

    // const handleClickOpen = () => {
    //     setOpen(true);
    // }

    const handleSnack = () => {
        setSnackBar({ ...snackBar, open: true });
        setHasChanged();
    };
    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBar({ ...snackBar, open: false });
    };

    const handleDayClick = day => {
        const range = DateUtils.addDayToRange(day, state);
        setState(range);
    };

    const handleShowDatePicker = () => {
        setShowDatePicker(true);
    };

    const handleHideDatePicker = () => {
        setShowDatePicker(false);
    };

    const [state, setState] = useState({
        from: undefined,
        to: undefined
    });
    const { from, to } = state;
    const modifiers = { start: from, end: to };

    const types = [
        {
            label: t('GENERAL.allMasculine'),
            value: 'Todos'
        },
        {
            label: t('HR.holiday'),
            value: t('HR.holiday')
        },
        {
            label: t('HR.break'),
            value: t('HR.break')
        }
    ];

    const getType = type => {
        switch (type) {
            case 'HOLIDAY':
                return t('HR.holiday');
            case 'BREAK':
                return t('HR.break');
            default:
                break;
        }
    };

    useEffect(() => {
        if (responseType === 'SUCCESS' && hasChanged > successiveActions) {
            handleSnack({ kind: 'success', content: response });
        }
        if (responseType === 'ERROR' && hasChanged > successiveActions) {
            handleSnack({ kind: 'error', content: response });
        }

        return () => {};
    }, [
        responseType,
        response,
        successiveActions,
        allowanceResponseType,
        allowanceResponse
    ]);

    async function fetchData() {
        await setLoading();
        await getHolidaysAndBreaks();
        await setLoadingFalse();
    }
    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (filterName || filterDate || filterType || breakPeriod) {
            setCanClick(true);
        } else {
            setCanClick(false);
        }
    }, [filterName, filterDate, filterType, breakPeriod]);

    useEffect(() => {
        if (holidaysAndBreaks && holidaysAndBreaks.length) {
            const { newDataFilter, textNoFound } =
                applyFilter(holidaysAndBreaks);

            if (newDataFilter.length === 0) {
                setDataFilter([]);
                setHasNoResult(true);
                setFindText(textNoFound);
            } else {
                setDataFilter(newDataFilter);
                setHasNoResult(false);
                setFindText('');
            }
        }
    }, [holidaysAndBreaks, filterName, filterDate, filterType, breakPeriod]);

    const applyFilter = holidaysAndBreaks => {
        let newDataFilter = [];
        let textNoFound = '';

        newDataFilter = holidaysAndBreaks.map(holiday => {
            return {
                ...holiday,
                typeFromDatabase: holiday.type,
                type: getType(holiday.type)
            };
        });

        if (filterName) {
            newDataFilter = newDataFilter.filter(item =>
                item.name.toLowerCase().includes(filterName.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterName;
        }

        if (
            filterType &&
            filterType.toLowerCase() !== t('GENERAL.allMasculine').toLowerCase()
        ) {
            newDataFilter = newDataFilter.filter(
                item =>
                    item.type &&
                    item.type.toLowerCase() === filterType.toLowerCase()
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterType;
        }

        if (breakPeriod && breakPeriod.start && breakPeriod.end) {
            let fromDate = new Date(
                breakPeriod.start.getFullYear() +
                    '/' +
                    (breakPeriod.start.getMonth() + 1) +
                    '/' +
                    breakPeriod.start.getDate()
            );
            let toDate = new Date(
                breakPeriod.end.getFullYear() +
                    '/' +
                    (breakPeriod.end.getMonth() + 1) +
                    '/' +
                    breakPeriod.end.getDate()
            );

            newDataFilter = newDataFilter.filter(
                item =>
                    item.startDate &&
                    FormatDateToFront(item.startDate).getTime() >=
                        fromDate.getTime() &&
                    FormatDateToFront(item.endDate).getTime() <=
                        toDate.getTime()
            );
            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = dateToBR(fromDate) + '-' + dateToBR(toDate);
            handleHideDatePicker();
        }

        if (filterDate && !isNaN(filterDate)) {
            let filterDateBR = dateToBR(filterDate);
            newDataFilter = newDataFilter.filter(
                item =>
                    item.date &&
                    dateToBR(FormatDateToFront(item.date)) === filterDateBR
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterDateBR;
        }

        return { newDataFilter, textNoFound };
    };

    const hasItens = holidaysAndBreaks.length > 0;
    const [hasNoResult, setHasNoResult] = useState(
        hasItens && dataFilter.length === 0
    );

    const clearField = value => {
        if (value === 'name') {
            setFilterName('');
        } else {
            setBreakPeriodText('');
            setBreakPeriod({
                start: null,
                end: null
            });
        }
    };

    return (
        <React.Fragment>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={snackBar.open}
                onClose={handleSnackClose}
                TransitionComponent={Slide}
                transitionDuration={{ enter: 500, exit: 500 }}
                ContentProps={{
                    'aria-describedby': 'message-id'
                }}
                autoHideDuration={2000}
            >
                <MySnackbarContentWrapper
                    onClose={handleSnackClose}
                    variant={
                        responseType !== ''
                            ? responseType.toLowerCase()
                            : allowanceResponseType.toLowerCase()
                    }
                    message={
                        <span id="message-id">
                            {response !== '' ? response : allowanceResponse}
                        </span>
                    }
                />
            </Snackbar>

            <Transition loading={loading} newDesign={true}>
                <div className="headerNewDesign">
                    <Breadcrumb
                        newDesign={true}
                        routes={[
                            {
                                label: t('HR.humanResource'),
                                to: '/HumanResources'
                            },
                            { label: t('ROUTES.config') },
                            { label: t('HR.holidaysAndBreaks') }
                        ]}
                    />
                    <div className="container-headerNewDesign">
                        <h1 className={classes.titleNewDesign}>
                            {t('HR.holidaysAndBreaks')}
                        </h1>
                        {GetGeneralPermissionForAction(
                            Screen.HOLIDAYS_AND_BREAKS,
                            Allow.CREATE
                        ) && (
                            <Link to={'/HolidaysAndBreaks/new'}>
                                <Button
                                    className={classes.blueButton}
                                    variant="contained"
                                    color="primary"
                                    style={{ marginBottom: '10px' }}
                                >
                                    {t('GENERAL.add')}
                                </Button>
                            </Link>
                        )}
                    </div>
                </div>
                <div className="containerNewDesign">
                    <Paper>
                        <div className="cabecalho">
                            {t('HR.holidaysAndBreaks').toUpperCase()}
                        </div>
                        <React.Fragment>
                            <FilterContainer>
                                <InputFilter
                                    filterValue={filterName}
                                    setFilter={setFilterName}
                                    label={'GENERAL.name'}
                                    maxLength={60}
                                />

                                <InputFilter
                                    filterValue={filterType}
                                    setFilter={setFilterType}
                                    label={'GENERAL.type'}
                                    isSelect
                                    arraySelected={types}
                                />

                                <InputFilterDate
                                    filterValue={filterDate}
                                    setFilter={setFilterDate}
                                    label={'HR.holidayDate'}
                                />

                                <div className={styles.filterSearchInput}>
                                    <TextField
                                        disabled
                                        id="client2"
                                        label={t('HR.breakPeriod')}
                                        name="breakPeriod"
                                        value={breakPeriodText}
                                        style={{
                                            minWidth: '250px',
                                            marginTop: 0,
                                            marginBottom: 0,
                                            width: '100%'
                                        }}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputSearch
                                        }}
                                        color={'black'}
                                        inputProps={{
                                            'aria-label': 'search',
                                            autocomplete: 'off'
                                        }}
                                        variant="standard"
                                        onClick={() =>
                                            !breakPeriodText
                                                ? setOpenDialogBreakPeriod(true)
                                                : null
                                        }
                                        InputProps={{
                                            endAdornment: breakPeriodText ? (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => {
                                                            clearField(
                                                                'breakPeriod'
                                                            );
                                                        }}
                                                    >
                                                        <Close />
                                                    </IconButton>
                                                </InputAdornment>
                                            ) : (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() =>
                                                            setOpenDialogBreakPeriod(
                                                                true
                                                            )
                                                        }
                                                    >
                                                        <EventIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </div>
                            </FilterContainer>

                            <HolidaysAndBreaksTable
                                rows={dataFilter}
                                toFirstPage={canClick}
                                headers={[
                                    'id',
                                    'name',
                                    'type',
                                    'date',
                                    'startDate',
                                    'endDate',
                                    'typeFromDatabase'
                                ]}
                                visibleHeaders={[
                                    'Nome',
                                    'Tipo',
                                    'Data do Feriado',
                                    'Período do Recesso',
                                    ''
                                ]}
                                sortedFields={[
                                    'name',
                                    'type',
                                    'date',
                                    'startDate'
                                ]}
                                mainField="name"
                                fetchData={fetchData}
                            />
                            {!hasItens && (
                                <div className={classes.noParticipantsMessage}>
                                    <h2>{t('INVENTORY.noItensMessage')}</h2>
                                    <h4>{t('INVENTORY.noItensSubMessage')}</h4>
                                </div>
                            )}
                            {hasNoResult && (
                                <div className={classes.noParticipantsMessage}>
                                    <div>
                                        <Dissatisfied
                                            className={
                                                classes.notFoundIconStyle
                                            }
                                        />
                                    </div>
                                    <h2>{t('GENERAL.noResultsFound')}</h2>
                                    <h5>{`Sua busca por "${findText}" não retornou resultados.`}</h5>
                                </div>
                            )}
                        </React.Fragment>
                        <DialogBreakPeriod
                            open={openDialogBreakPeriod}
                            setOpen={setOpenDialogBreakPeriod}
                            breakPeriod={breakPeriod}
                            setParentValues={setBreakPeriod}
                            setBreakPeriodText={setBreakPeriodText}
                        />
                    </Paper>
                </div>
                <Footer />
            </Transition>
        </React.Fragment>
    );
};

export default HolidaysAndBreaks;
