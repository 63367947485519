import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Check from "@material-ui/icons/Check";
import HowToReg from "@material-ui/icons/HowToReg";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Buttons from "components/buttons/Pressed";
import PhaseActivity from "components/execution/Phases/Activities/PhaseActivity";
import PhaseInformation from "components/execution/Phases/Activities/PhaseComponent";
import ProjectInformation from "components/execution/ProjectInformationComponent";
import { useStyles } from "components/layout/CommonStyles";
import Footer from "components/layout/Footer";
import SideBar from "components/layout/sidebar/Sidebar";
import Spinner from "components/layout/spinners/Index";
import "components/profiles/css/FormStyle.css";
import ProjectsContext from "context/projects/projectsContext";
import "moment/locale/pt-br";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import history from "../../../../history";
import DashboardNavBar from "../../../layout/DashboardNavBar";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";

const NewActivity = ({ match }) => {
    const [t] = useTranslation();

    const classes = useStyles();
    const projectsContext = useContext(ProjectsContext);
    const { loading, project, phase, getProject, getPhase, addActivity, newActivityId, openModal, setOpenModal } = projectsContext;
    const [projectId, setProjectId] = useState(0);
    const [openMessage, setOpenMessage] = useState(false);
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const [state, setState] = useState({
        projectName: "",
        gpf: "",
        projectStartDate: "",
        projectEndDate: "",
        phaseName: "",
        phaseStatus: "",
        phaseStart: "",
        phaseEnd: "",
        activityName: "",
        activityDescription: "",
        expectedResults: "",
        obtainedResults: ""
    });

    const [stateActivity, setStateActivity] = useState({
        file: null,
        activityName: "",
        activityDescription: "",
        expectedResults: "",
        obtainedResults: "",
        activityStartDate: new Date(),
        activityEndDate: new Date(),
    });

    const transitions = useTransition(loading, null, {
        from: { opacity: 0 },
        enter: {
            opacity: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "calc(100vh - 80px)"
        },
        leave: { opacity: 0 }
    });

    useEffect(() => {
        getPhase(match.params.id);
    }, []);

    useEffect(() => {
        if (phase && phase.projectId) {
            getProject(phase.projectId);
            setProjectId(phase.projectId);
        }
    }, [phase]);

    useEffect(() => {
        if (project.information) {
            var startdatewithtimezone = new Date(phase.startdate)
            startdatewithtimezone.setMinutes(startdatewithtimezone.getMinutes() + startdatewithtimezone.getTimezoneOffset())
            var enddatewithtimezone = new Date(phase.enddate)
            enddatewithtimezone.setMinutes(enddatewithtimezone.getMinutes() + enddatewithtimezone.getTimezoneOffset())
            setState({
                ...state,
                gpf: project.information.gpf,
                projectName: project.information.name,
                projectStartDate: project.information.startdate,
                projectEndDate: project.information.enddate,
                phaseName: phase.name,
                phaseStatus: "",
                phaseStart: startdatewithtimezone,
                phaseEnd: enddatewithtimezone
            });
        }
    }, [project]);

    const modifyActivity = (field, value) => {
        setStateActivity({ ...stateActivity, [field]: value })
    }

    const handleSubmit = () => {
        setOpenMessage(false);
        if (stateActivity.activityName) {
            const formData = new FormData();

            let startDateFormated = stateActivity.activityStartDate.getDate() + "/" + (stateActivity.activityStartDate.getMonth() + 1) + "/" + stateActivity.activityStartDate.getFullYear();
            let endDateFormated = stateActivity.activityEndDate.getDate() + "/" + (stateActivity.activityEndDate.getMonth() + 1) + "/" + stateActivity.activityEndDate.getFullYear();

            formData.append("file", stateActivity.file);
            formData.append("name", stateActivity.activityName);
            formData.append("description", stateActivity.activityDescription);
            formData.append("expectedResults", stateActivity.expectedResults);
            formData.append("obtainedResults", stateActivity.obtainedResults);
            formData.append("startDate", startDateFormated);
            formData.append("endDate", endDateFormated);
            formData.append("phaseId", phase.id);

            addActivity(formData);
        } else setOpenMessage(true);
    };

    const redirectToAllocate = () => {
        history.push(`/execution/phaseActivity/${newActivityId}/edit/addParticipants`);
        setOpenModal(false);
    };

    const redirectToEdit = () => {
        history.push(`/execution/project/edit/projectPhase/${match.params.id}/edit`);
        setOpenModal(false);
    };

    return transitions.map(({ item, key, props }) =>
        item ? (
            <animated.div key={key} style={props}>
                <Spinner />
            </animated.div>
        ) : (
                <>
                    <DashboardNavBar />
                    <SideBar />
                    <animated.div key={key} style={props}>
                        <div className="container">
                            <Breadcrumbs
                                className={classes.breadCrumbs}
                                aria-label="breadcrumb"
                                separator={<NavigateNextIcon fontSize="small" />}
                            >
                                <Typography>{t("PARTICIPANTS.labor")}</Typography>
                                <Link className={classes.link} to="/execution/projects/">
                                    {t("PROJECTS.projects")}
                                </Link>
                                <Link
                                    className={classes.link}
                                    to={`/execution/projects/${projectId}/edit`}
                                >
                                    {t("PROJECTS.project")}
                                </Link>
                                <Link
                                    className={classes.link}
                                    to={`/execution/project/edit/projectPhase/${match.params.id}/edit`}
                                >
                                    {t("SCOPE.stage")}
                                </Link>
                                <Typography>{t("ACTIVITIES.addActivities")}</Typography>
                            </Breadcrumbs>
                            <div className="container-header">
                                <div className="title">
                                    <h1 className={classes.title}>{t("ACTIVITIES.addActivities")}</h1>
                                </div>
                                <Button
                                    className={classes.blueButton}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmit}
                                >
                                    <div className={classes.buttonIcon}>
                                        <Check />
                                    </div>
                                    {t("GENERAL.add").toUpperCase()}
                                </Button>
                            </div>
                            <ProjectInformation
                                projectName={state.projectName}
                                gpf={state.gpf}
                                projectStartDate={state.projectStartDate}
                                projectEndDate={state.projectEndDate}
                            />
                            <PhaseInformation
                                phaseName={state.phaseName}
                                phaseStart={state.phaseStart}
                                phaseEnd={state.phaseEnd}
                            />
                            <PhaseActivity
                                setNewActivity={modifyActivity}
                                requiredFields={openMessage}
                                setRequiredFields={setOpenMessage}
                            />
                            <Dialog
                                open={openModal}
                                onClose={handleClose}
                                aria-labelledby="form-dialog-title"
                                msg="mensagem"
                            >
                                <DialogTitle className={classes.actionsTitle}>
                                    {t("ACTIVITIES.allocateParticipants")}
                                    <IconButton style={{float:"right", marginTop: -10, marginRight: -17}} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent className={classes.modalAllocate}>
                                    <HowToReg style={{ color: "#2C3E51", fontSize: "100px" }} />
                                    <DialogContentText>
                                        <span>A atividade </span>
                                        <span style={{ fontWeight: "bold" }}>"{stateActivity.activityName}"</span>
                                        <span> foi criada com sucesso. Deseja alocar participantes à atividade?</span>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Buttons
                                        onClick={redirectToEdit}
                                        tipo="BN-noback"
                                        conteudo={t("GENERAL.notNow").toUpperCase()}
                                    />
                                    <Buttons
                                        onClick={redirectToAllocate}
                                        tipo="BN-blue"
                                        conteudo={t("GENERAL.toAllocate").toUpperCase()}
                                    />
                                </DialogActions>
                            </Dialog>
                        </div>
                        <Footer />
                    </animated.div>
                </>
            )
    );
};

export default NewActivity;