export function moneyMask(value) {
    if (!value.match(/([0-9])*,[0-9]{2}/g) && !value.match(/([0-9])*.[0-9]{2}/g)) value = "0,00";
    value = value.replace('.', '').replace(',', '').replace(/\D/g, '');

    const options = { minimumFractionDigits: 2 };
    const result = new Intl.NumberFormat('pt-BR', options).format(
        parseFloat(value) / 100
    );

    return `${result}`;
}
