import i18n from 'i18n';
import { dateValidation } from 'utils/validation/DateValidation';
import { emailValidation } from 'utils/validation/EmailValidation';
import { CPFValidation } from 'utils/validation/CPFValidation';
import { Modules } from 'global/constants';

export const SystemAccessStatus = {
    ABLE: 'true',
    DISABLED: 'false'
};

export const UserRegistrationStatus = {
    AdmissionPending: 'AdmissionPending',
    Admitted: 'Admitted',
    Discharged: 'Discharged'
};

export const SystemAccessStatusArray = [
    {
        value: 'ALL',
        label: i18n.t('GENERAL.allMasculine')
    },
    {
        value: SystemAccessStatus.ABLE,
        label: i18n.t('GENERAL.enabled')
    },
    {
        value: SystemAccessStatus.DISABLED,
        label: i18n.t('GENERAL.disabled')
    }
];

export function translateSystemAccessStatus(status) {
    switch (status) {
        case SystemAccessStatus.ABLE:
            return i18n.t('GENERAL.enabled');
        case SystemAccessStatus.DISABLED:
            return i18n.t('GENERAL.disabled');
        default:
            return '';
    }
}

export function translateUserRegistrationStatus(status) {
    switch (status) {
        case UserRegistrationStatus.AdmissionPending:
            return i18n.t('GENERAL.admissionPending');
        case UserRegistrationStatus.Admitted:
            return i18n.t('GENERAL.admitted');
        case UserRegistrationStatus.Discharged:
            return i18n.t('GENERAL.discharged');
        default:
            return '';
    }
}

export function verifyUsers(values, data, setRequired) {
    let res = true;

    const requiredValues = {};

    if (!values.name.trim()) {
        res = false;
        requiredValues.name = true;
    }

    if (!values.shortname.trim()) {
        res = false;
        requiredValues.shortname = true;
    }

    if (!values.username.trim()) {
        res = false;
        requiredValues.username = true;
    }

    if (
        values.username.trim() &&
        !emailValidation(values.username + process.env.REACT_APP_DEFAULT_EMAIL)
    ) {
        res = false;
        requiredValues.usernameInvalid = true;
    }

    const checkSameUsername = obj =>
        obj.username === values.username + process.env.REACT_APP_DEFAULT_EMAIL;

    if (data && data.users.some(checkSameUsername)) {
        res = false;
        requiredValues.usernameAlreadyExists = true;
    }

    if (!values.cpf.trim()) {
        res = false;
        requiredValues.cpf = true;
    }

    if (!CPFValidation(values.cpf)) {
        res = false;
        requiredValues.cpfInvalid = true;
    }

    const checkSameCPF = obj => obj.cpf === values.cpf;

    if (data && data.users.some(checkSameCPF)) {
        res = false;
        requiredValues.cpfAlreadyExists = true;
    }

    if (!dateValidation(values.birthday)) {
        res = false;
        requiredValues.birthday = true;
    }

    setRequired(requiredValues);

    return res;
}

export function verifyUsersPermissions(values) {
    let res = true;

    if (!values.approver) {
        res = false;
    }

    if (!values.surrogate) {
        res = false;
    }

    return res;
}

export function GetPermissionsNames(values, systemRoles) {
    return [
        {
            name: Modules.ADMINISTRATIVE.name,
            permission: systemRoles
                ? systemRoles
                      .find(x => x.id === Modules.ADMINISTRATIVE.id)
                      .roles.find(y => y.id === values.administrativeId).name
                : '-'
        },
        {
            name: Modules.LIBRARY.name,
            permission: systemRoles
                ? systemRoles
                      .find(x => x.id === Modules.LIBRARY.id)
                      .roles.find(y => y.id === values.libraryId).name
                : '-'
        },
        {
            name: Modules.QUOTAS.name,
            permission: systemRoles
                ? systemRoles
                      .find(x => x.id === Modules.QUOTAS.id)
                      .roles.find(y => y.id === values.quotasId).name
                : '-'
        },
        {
            name: Modules.HR.name,
            permission: systemRoles
                ? systemRoles
                      .find(x => x.id === Modules.HR.id)
                      .roles.find(y => y.id === values.humanResourcesId).name
                : '-'
        },
        {
            name: Modules.HR_OLD.name,
            permission: systemRoles
                ? systemRoles
                      .find(x => x.id === Modules.HR_OLD.id && x.isOldSystem)
                      .roles.find(y => y.id === values.humanResourcesOldId).name
                : '-'
        },
        {
            name: Modules.NEWS.name,
            permission: systemRoles
                ? systemRoles
                      .find(x => x.id === Modules.NEWS.id)
                      .roles.find(y => y.id === values.newsId).name
                : '-'
        },
        {
            name: Modules.PATRIMONY.name,
            permission: systemRoles
                ? systemRoles
                      .find(x => x.id === Modules.PATRIMONY.id)
                      .roles.find(y => y.id === values.patrimonyId).name
                : '-'
        }
    ];
}
