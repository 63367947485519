import React from 'react';
import { createContext } from 'react';
import DashboardNavBar from '../components/layout/searchIndex';
import SideBar from '../components/layout/sidebar/Sidebar';
import { useStyles } from 'components/layout/CommonStyles';
import { useTranslation } from 'react-i18next';

import Transition from 'components/layout/transition/Transition';

import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { errorPageStyles } from 'components/layout/ErrorPages';
import Footer from 'components/layout/Footer';

import ErrorIcon from '@material-ui/icons/Error';

import history from '../history';

const NotFound = () => {
    const classes = useStyles();
    const [t] = useTranslation();
    const pageClasses = errorPageStyles();
    const context = createContext();
    const { loading } = context;

    return (
        <React.Fragment>
            <div>
                <DashboardNavBar />
                <SideBar />
                <Transition loading={loading} newDesign={true}>
                    <div className="headerNewDesign">
                        <div className={pageClasses.breadCrumbsPlaceHolder}>
                            Error 404
                        </div>

                        <div className="container-headerNewDesign">
                            <h1 className={classes.titleNewDesign}>
                                {t('GENERAL.lsbdSystems')}
                            </h1>
                        </div>
                    </div>

                    <div className="containerNewDesign">
                        <div className={pageClasses.errorPaper}>
                            <div className={pageClasses.errorMessageBox}>
                                <ErrorIcon
                                    style={{
                                        width: '26.67',
                                        height: '26.67',
                                        fill: '#6D6F71'
                                    }}
                                />
                                <h1 className={pageClasses.errorCode}>404</h1>

                                <p className={pageClasses.errorText}>
                                    {t('ERR.pageNotFound')}
                                </p>
                                <p className={pageClasses.errorInfo}>
                                    {t(
                                        'ERR.accessedPageIsUnavailableOrDontExists'
                                    )}
                                </p>

                                <div
                                    style={{
                                        paddingLeft: '10px',
                                        paddingBottom: '40px',
                                        paddingTop: '40px'
                                    }}
                                >
                                    <Button
                                        className={pageClasses.newBlueButton}
                                        onClick={() => history.goBack()}
                                        style={{ marginRight: '8px' }}
                                        variant="contained"
                                        color="primary"
                                    >
                                        <p className={pageClasses.buttonText}>
                                            {t('ERR.goBackToPreviousPage')}
                                        </p>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer />
                </Transition>
            </div>
        </React.Fragment>
    );
};

export default NotFound;
