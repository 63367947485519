import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import Buttons from "components/buttons/Pressed";
import { useStyles } from "components/layout/CommonStyles";
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Input from "components/inputs/Input";
import InputDragDropDocs from "components/layout/inputDragDrop/InputDragDropDocs";
import { register } from "serviceWorker";

const QualificationFormDialog = (props) => {
    const [t] = useTranslation();

    const classes = useStyles();

    const { open, setOpen, setQualifications, contextForm, qualifications, idQualification } = props;

    // const [toDeleteFile, setToDeleteFile] = useState([]);

    const [dates, setDates] = useState({
        dateConclusion: null,
    });

    const [values, setValues] = useState({
        qualificationName: "",
        qualificationType: "",
        qualificationInstitution: "",
    });

    const [dataState, setDataState] = useState({
        files: [],
        rejectedFiles: []
    });

    const [requiredQualificationName, setRequiredQualificationName] = useState(false);
    const [requiredQualificationType, setRequiredQualificationType] = useState(false);

    var qualification = idQualification !== "" && qualifications && qualifications.filter(x => x.generalId === idQualification)

    useEffect(() => {
        if (contextForm !== "new" && qualification) {
            setValues({
                qualificationName: qualification[0] && qualification[0].qualificationName,
                qualificationType: qualification[0] && qualification[0].qualificationType,
                qualificationInstitution: qualification[0] && qualification[0].institution ? qualification[0].institution : qualification[0] && qualification[0].qualificationInstitution ? qualification[0].qualificationInstitution : ""
            })
            setDates({
                dateConclusion: qualification[0] && qualification[0].dateConclusion
            });
            // setDataState({
            //     files: [],
            //     rejectedFiles: []
            // })
        }
        else {
            cleanFields();
        }
    }, [qualification && qualification.length, JSON.stringify(qualification), contextForm, open]);


    const cleanFields = () => {
        setValues({
            ...values,
            qualificationName: "",
            qualificationType: "",
            qualificationInstitution: "",
        });
        setDates({
            dateConclusion: null
        });
        // setDataState({
        //     files: [],
        //     rejectedFiles: []
        // })
    }

    // const onDropFiles = acceptedFiles => {
    //     let newFiles = [];
    //     let existentFiles = [];
    //     for (let i = 0; i < acceptedFiles.length; i++) {
    //         let hasTheSameName = dataState.files.filter(el => (el.fileName ? el.fileName.toLowerCase() : el.name.toLowerCase()) ===
    //             acceptedFiles[i].name.toLowerCase()).length > 0;
    //         if (hasTheSameName) {
    //             existentFiles.push(`Arquivo já existente - ${acceptedFiles[i].name}`);
    //         }
    //         else {
    //             newFiles.push(acceptedFiles[i])
    //         }
    //     }
    //     setDataState({
    //         ...dataState,
    //         files: dataState.files.concat(newFiles),
    //         rejectedFiles: dataState.rejectedFiles.concat(existentFiles)
    //     });
    // };

    // const deleteRejectedFile = rejectedFile => {
    //     let updatedList = dataState.rejectedFiles.filter(el => el !== rejectedFile);
    //     setDataState({ ...dataState, rejectedFiles: updatedList });
    // };

    // const onRemoveFileDocs = (data) => {
    //     if (data) {
    //         let remainingFiles = [];
    //         let updatedList = [];
    //         for (const file of dataState.files) {
    //             if ((file.path === data.path) && file.lastModified === data.lastModified && file.size === data.size) {
    //                 if (file.id) {
    //                     setToDeleteFile([...toDeleteFile, file.id]);
    //                     updatedList = dataState.rejectedFiles.filter(el => el !== `Arquivo já existente - ${data.fileName}`);
    //                 }
    //                 continue;
    //             }
    //             remainingFiles.push(file)
    //         }
    //         setDataState({ ...dataState, files: remainingFiles, rejectedFiles: updatedList })
    //     }
    // };

    const handleClose = () => {
        setOpen(false);
        cleanFields();
    };

    const verifySubmit = () => {
        let res = true;
        if (!values.qualificationName) {
            res = false;
            setRequiredQualificationName(true);
        }
        if (!values.qualificationType) {
            res = false;
            setRequiredQualificationType(true);
        }
        return res;
    }

    const handleSubmit = () => {
        if (verifySubmit()) {
            setQualifications(values, dates, dataState, contextForm, idQualification)
            setOpen(false);
            cleanFields();
            setRequiredQualificationName(false);
            setRequiredQualificationType(false);
        }
    };

    const handleChange = (name, value) => {
        setValues({ ...values, [name]: value })
    }

    const handleDates = name => e => {
        setDates({
            ...dates,
            [name]: e
        });
    };

    useEffect(() => {
        setRequiredQualificationType(false);
    },[values.qualificationType]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            msg="mensagem"
            maxWidth="md"
        >
            <DialogTitle className={classes.actionsTitle} style={{ width: 'auto' }}>
                <span style={{ fontWeight: 'bold' }}>
                    {contextForm === "view" ? t("HR.viewqualification").toLocaleUpperCase() : contextForm === "edit" ? t("HR.editqualification").toLocaleUpperCase() : t("HR.addqualification").toLocaleUpperCase()}
                </span>
                <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <FormControl component="fieldset">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl component="fieldset" style={{ marginTop: "18px" }}>
                                    <FormLabel
                                        component="legend"
                                        required
                                        error={requiredQualificationType}
                                        setRequired={setRequiredQualificationType}
                                        helperText={requiredQualificationType && t("GENERAL.requiredField")}
                                    >{t("HR.qualificationType")}</FormLabel>
                                    <RadioGroup row aria-label="position"
                                        disabled={contextForm === "view"}
                                        name="position"
                                        defaultValue="top"
                                        value={values.qualificationType}
                                        onChange={(ev) => handleChange("qualificationType", ev.target.value)}
                                    >
                                        <FormControlLabel
                                            disabled={contextForm === "view"}
                                            value="certification"
                                            control={<Radio color="primary" />}
                                            label={t("HR.certification")}
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            disabled={contextForm === "view"}
                                            value="specialization"
                                            control={<Radio color="primary" />}
                                            label={t("HR.specialization")}
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            disabled={contextForm === "view"}
                                            value="mastersDegree"
                                            control={<Radio color="primary" />}
                                            label={t("HR.mastersDegree")}
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            disabled={contextForm === "view"}
                                            value="doctorate"
                                            control={<Radio color="primary" />}
                                            label={t("HR.doctorate")}
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            disabled={contextForm === "view"}
                                            value="postDoctoral"
                                            control={<Radio color="primary" />}
                                            label={t("HR.postDoctoral")}
                                            labelPlacement="end"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <Input
                                    context={contextForm}
                                    required
                                    error={requiredQualificationName}
                                    setRequired={setRequiredQualificationName}
                                    helperText={requiredQualificationName && t("GENERAL.requiredField")}
                                    label={"HR.qualificationName"}
                                    value={values.qualificationName}
                                    valueName={"qualificationName"}
                                    maxLength={50}
                                    handleChange={handleChange}
                                />

                            </Grid>
                            <Grid item xs={4}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disabled={contextForm === "view"}
                                        className={classes.identificationDatePicker}
                                        disableToolbar
                                        autoOk={true}
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        id="validity"
                                        margin="normal"
                                        invalidDateMessage=""
                                        label={t("HR.DateConclusion")}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date"
                                        }}
                                        value={dates.dateConclusion}
                                        onChange={handleDates("dateConclusion")}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12}>
                                <Input
                                    context={contextForm}
                                    label={"HR.institution"}
                                    value={values.qualificationInstitution}
                                    valueName={"qualificationInstitution"}
                                    maxLength={50}
                                    handleChange={handleChange}
                                />
                            </Grid>
                            {/* <Grid item xs={12}>
                                <InputDragDropDocs
                                    files={dataState}
                                    onDrop={onDropFiles}
                                    deleteRejectedFile={deleteRejectedFile}
                                    onRemoveFileDocs={onRemoveFileDocs}
                                    context={contextForm}
                                />
                            </Grid> */}
                        </Grid>
                    </FormControl>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Buttons
                    onClick={handleClose}
                    tipo="BN-noback"
                    conteudo={contextForm === "view" ? t("GENERAL.close").toLocaleUpperCase() : t("GENERAL.cancel").toLocaleUpperCase()}
                />
                {contextForm !== "view" &&
                    <Buttons
                        onClick={handleSubmit}
                        tipo="BN-blue"
                        conteudo={contextForm === "new" ? t("GENERAL.add").toLocaleUpperCase() : t("GENERAL.update").toLocaleUpperCase()}
                    />}
            </DialogActions>
        </Dialog>
    );
}
export default QualificationFormDialog;