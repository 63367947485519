import Paper from '@material-ui/core/Paper';
import Dissatisfied from '@material-ui/icons/SentimentVeryDissatisfied';
import FilterContainer from 'components/containers/FilterContainer';
import InputFilter from 'components/inputs/InputFilter';
import { useStyles } from 'components/layout/CommonStyles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { screenStyles } from 'pages/libraryModule/MyLoans/Styles';
import BookCardMyLoans from 'pages/libraryModule/cards/BookCardMyLoans';
import { AllAuthorsName } from 'utils/library/AllAuthorsName';
import { LibraryReservationStatusValues } from 'global/constants';
import DialogHistoryMoreDetails from 'pages/libraryModule/dialogs/MyLoans/DialogHistoryMoreDetails';
import { Link as LinkRouter } from 'react-router-dom';
import { formatImagePath } from 'utils/library/formatImagePath';
import useWindowDimensions from 'hooks/useWindowDimensions';
import Pagination from '@material-ui/lab/Pagination';
import LibraryLoanHistoryTable from './LibraryLoanHistoryTable';
import InputFilterDate from 'components/inputs/InputFilterDate';
import { dateToBR } from 'utils/dates/DateToBRr';
import { FormatDateToFront } from 'utils/dates/FormatDateToFront';
import { translateLoanStatus } from 'utils/library/translateStatus';
import { QueryGetValue } from 'utils/general/QueryString';

const LibraryLoanHistoryForm = props => {
    const { HistoryData } = props;

    const [t] = useTranslation();
    const styles = screenStyles();
    const classes = useStyles();

    const [dialogData, setDialogData] = useState({});
    const [openHistoryMoreDetailsDialog, setOpenHistoryMoreDetailsDialog] =
        useState(false);

    const [filterTitle, setFilterTitle] = useState('');
    const [filterAuthor, setFilterAuthor] = useState('');
    const [filterColaborator, setFilterColaborator] = useState('');
    const [filterDate, setFilterDate] = useState(null);
    const [filterStatusType, setFilterStatusType] = useState('');

    const [findText, setFindText] = useState('');
    const [canClick, setCanClick] = useState(false);

    const [dataFilter, setDataFilter] = useState([]);
    const hasItens = HistoryData.length > 0;
    const [hasNoResult, setHasNoResult] = useState(
        hasItens && dataFilter.length === 0
    );

    useEffect(() => {
        if (QueryGetValue('panel') === 'HISTORY') {
            setFilterTitle(QueryGetValue('title') || '');
            setFilterAuthor(QueryGetValue('author') || '');
            setFilterColaborator(QueryGetValue('collaborator') || '');
        }
    }, []);


    const statusTypes = [
        { label: 'Todos', value: 'Todos' },
        {
            label: translateLoanStatus(LibraryReservationStatusValues.FINISHED),
            value: LibraryReservationStatusValues.FINISHED
        },
        {
            label: translateLoanStatus(
                LibraryReservationStatusValues.CANCELED_COLLABORATOR
            ),
            value: LibraryReservationStatusValues.CANCELED_COLLABORATOR
        },
        {
            label: translateLoanStatus(
                LibraryReservationStatusValues.CANCELED_MANAGEMENT
            ),
            value: LibraryReservationStatusValues.CANCELED_MANAGEMENT
        }
    ];

    const handleOpenHistoryMoreDetailsDialog = index => {
        setDialogData(dataFilter[index]);
        setOpenHistoryMoreDetailsDialog(true);
    };

    const applyFilter = filterArray => {
        let newDataFilter = [];
        let textNoFound = '';

        newDataFilter = filterArray;

        if (filterTitle) {
            newDataFilter = newDataFilter.filter(item =>
                item.book.title
                    .toLowerCase()
                    .includes(filterTitle.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterTitle;
        }

        if (filterAuthor) {
            newDataFilter = newDataFilter.filter(item =>
                AllAuthorsName(item.book.authors)
                    .toLowerCase()
                    .includes(filterAuthor.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterAuthor;
        }

        if (filterColaborator) {
            newDataFilter = newDataFilter.filter(item =>
                item.user.name
                    .toLowerCase()
                    .includes(filterColaborator.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterColaborator;
        }

        if (filterDate && !isNaN(filterDate)) {
            let filterDateBR = dateToBR(filterDate);
            newDataFilter = newDataFilter.filter(
                item =>
                    item.devolutionDate &&
                    dateToBR(FormatDateToFront(item.devolutionDate)) ===
                        filterDateBR
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterDateBR;
        }

        if (
            filterStatusType &&
            filterStatusType.toLowerCase() !==
                t('GENERAL.allMasculine').toLowerCase()
        ) {
            newDataFilter = newDataFilter.filter(
                item => item.status === filterStatusType
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = translateLoanStatus(filterStatusType);
        }

        return { newDataFilter, textNoFound };
    };

    useEffect(() => {
        if (HistoryData && HistoryData.length) {
            const { newDataFilter, textNoFound } = applyFilter(HistoryData);

            if (newDataFilter.length === 0) {
                setDataFilter([]);
                setHasNoResult(true);
                setFindText(textNoFound);
            } else {
                setDataFilter(newDataFilter);
                setHasNoResult(false);
                setFindText('');
            }
        }
    }, [
        HistoryData,
        filterTitle,
        filterAuthor,
        filterColaborator,
        filterDate,
        filterStatusType
    ]);

    useEffect(() => {
        if (
            filterTitle ||
            filterAuthor ||
            filterColaborator ||
            filterDate ||
            filterStatusType
        ) {
            setCanClick(true);
        } else {
            setCanClick(false);
        }
    }, [
        filterTitle,
        filterAuthor,
        filterStatusType,
        filterColaborator,
        filterDate
    ]);

    return (
        <React.Fragment>
            <Paper>
                <FilterContainer>
                    <InputFilter
                        filterValue={filterTitle}
                        setFilter={setFilterTitle}
                        label={'INVENTORY.title'}
                        maxLength={70}
                    />
                    <InputFilter
                        filterValue={filterAuthor}
                        setFilter={setFilterAuthor}
                        label={'INVENTORY.BookAuthor'}
                        maxLength={70}
                    />
                    <InputFilter
                        filterValue={filterColaborator}
                        setFilter={setFilterColaborator}
                        label={'HR.collaborator'}
                        maxLength={70}
                    />
                    <InputFilterDate
                        filterValue={filterDate}
                        setFilter={setFilterDate}
                        label={'LIBRARY.dateDevolution'}
                    />
                    <InputFilter
                        filterValue={filterStatusType}
                        setFilter={setFilterStatusType}
                        label={'GENERAL.status'}
                        isSelect
                        arraySelected={statusTypes}
                    />
                </FilterContainer>

                <React.Fragment>
                    <LibraryLoanHistoryTable
                        rows={dataFilter}
                        toFirstPage={canClick}
                        headers={[
                            'user',
                            'approver',
                            'receiver',
                            'canceller',
                            'book',
                            'library',
                            'exemplar',
                            'status',
                            'justification',
                            'devolutionDate',
                            'fine',
                            'fineChanged'
                        ]}
                        visibleHeaders={[
                            'Colaborador',
                            'Título',
                            'Autor',
                            'Categoria',
                            'Exemplar',
                            'Status',
                            'Data para Devolução',
                            ' '
                        ]}
                        disableHeaderIndex={7}
                        sortedFields={[
                            'user',
                            'title',
                            'authors',
                            'category',
                            'exemplar',
                            'status',
                            'devolutionDate'
                        ]}
                    />

                    {!hasItens && (
                        <div className={classes.noParticipantsMessage}>
                            <h2>{t('LIBRARY.noHistoryMessage')}</h2>
                            <h4>{t('LIBRARY.noHistorySubMessage')}</h4>
                        </div>
                    )}
                    {hasNoResult && (
                        <div className={classes.noParticipantsMessage}>
                            <div>
                                <Dissatisfied
                                    className={classes.notFoundIconStyle}
                                />
                            </div>
                            <h2>{t('GENERAL.noResultsFound')}</h2>
                            <h5>{`Sua busca por "${findText}" não retornou resultados.`}</h5>
                        </div>
                    )}
                </React.Fragment>
            </Paper>
        </React.Fragment>
    );
};

export default LibraryLoanHistoryForm;
