/* eslint-disable */
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import FormControl from "@material-ui/core/FormControl";
import Grid from '@material-ui/core/Grid';
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import Attachment from "@material-ui/icons/Attachment";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Footer from "components/layout/Footer";
import Spinner from "components/layout/spinners/Index";
import AllowancesContext from "context/allowance/allowanceContext";
import InventoryContext from "context/inventory/inventoryContext";
import React, { useContext, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import { useStyles } from "components/layout/CommonStyles";
import MySnackbarContentWrapper from "components/layout/Message";
import HistoryTabPanel from "./HistoryTabPanel";

const tooltipTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#2c3e51"
        }
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                color: "white",
                fontSize: "1em",
                backgroundColor: "red",
                opacity: "0.7"
            }
        }
    }
});

const ViewHistoryEquipment = props => {
    const { idItem } = props;
    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }
    const classes = useStyles();
    const [t] = useTranslation();
    const inventoryContext = useContext(InventoryContext);
    const allowanceContext = useContext(AllowancesContext);
    const [categoriesArray, setCategoriesArray] = useState([]);
    const [mustRedirect, setMustRedirect] = useState(false);

    const [snackBar, setSnackBar] = useState({
        open: false,
        kind: "",
        content: ""
    });

    const [dataState, setDataState] = useState({
        files: [],
        rejectedFiles: []
    });

    const [values, setValues] = useState({
        id: 0,
        equipmentImage: "",
        acquisitionDate: new Date(),
        brand: "",
        categoryId: "",
        description: "",
        fcpcCode: "",
        invoice: "",
        formatDate: "",
        name: "",
        placeId: "",
        projectId: "",
        responsibleId: "",
        serialNumber: "",
        ufcCode: "",
        value: "",
        externCode: "",
        lsbdCode: "",
        warrantyDate: new Date(),
        statusDescription: "",
        statusName: "",
        class: "Inventory.Item"
    });
    const [requiredName, setRequiredName] = useState(false);
    const [requiredCode, setRequiredCode] = useState(false);
    const [requiredSN, setRequiredSN] = useState(false);
    const [requiredCategory, setRequiredCategory] = useState(false);

    const {
        getAllEquipment,
        loading,
        response,
        hasChanged,
        setHasChanged,
        responseType,
        successiveActions,
        getPlaces,
        getItensCategories,
        itensCategories,
        getAllStatus,
        getEquipment,
        equipmentItem,
        maintenances,
        getMaintenanceForItem,
        cleanArraySubItem,
        getHistoryEquipment,
        historyEquipment
    } = inventoryContext;

    const {
        allowanceResponse,
        allowanceResponseType,
        messageHasChanged,
        resetAllowanceMessage,
    } = allowanceContext;

    useEffect(() => {
        getAllEquipment();
        getPlaces();
        getItensCategories();
        getAllStatus();
    }, []);

    useEffect(() => {
        getEquipment(idItem);
        getHistoryEquipment(idItem);
        cleanArraySubItem();
        setValues({
            ...values,
            id: parseInt(idItem),
            equipmentImage: equipmentItem.image ? equipmentItem.cnpj : "",
            acquisitionDate: equipmentItem.acquisitionDate ? formatDate(equipmentItem.acquisitionDate) : formatDate(new Date()),
            brand: equipmentItem.brand ? equipmentItem.brand : "",
            categoryId: equipmentItem.categoryId ? equipmentItem.categoryId : "",
            description: equipmentItem.description ? equipmentItem.description : "",
            fcpcCode: equipmentItem.fcpcCode ? equipmentItem.fcpcCode : "",
            invoice: equipmentItem.invoice ? equipmentItem.invoice : "",
            itemStatusId: equipmentItem.itemStatusId ? equipmentItem.itemStatusId : "",
            name: equipmentItem.name ? equipmentItem.name : "",
            placeId: equipmentItem.placeId ? equipmentItem.placeId : "",
            projectId: equipmentItem.projectId ? equipmentItem.projectId : "",
            responsible: equipmentItem.responsible ? equipmentItem.responsible : "",
            responsibleId: equipmentItem.responsibleId ? equipmentItem.responsibleId : "",
            responsibleExternalId: equipmentItem.responsibleExternalId ? equipmentItem.responsibleExternalId : "",
            serialNumber: equipmentItem.serialNumber ? equipmentItem.serialNumber : "",
            ufcCode: equipmentItem.ufcCode ? equipmentItem.ufcCode : "",
            value: equipmentItem.value ? equipmentItem.value : "",
            externCode: equipmentItem.externCode ? equipmentItem.externCode : "",
            lsbdCode: equipmentItem.lsbdCode ? equipmentItem.lsbdCode : "",
            warrantyDate: equipmentItem.warrantyDate ? formatDate(equipmentItem.warrantyDate) : formatDate(new Date()),
            statusDescription: equipmentItem.statusDescription ? equipmentItem.statusDescription : "",
            statusName: equipmentItem.statusName ? equipmentItem.statusName : "",
        });

        setDataState({
            ...dataState,
            files: equipmentItem.equipmentFiles ? equipmentItem.equipmentFiles : "",
            //  rejectedFiles: dataState.rejectedFiles.concat(existentFiles)
        });


        return () => { };
    }, [JSON.stringify(equipmentItem).length, JSON.stringify(historyEquipment).length]);

    useEffect(() => {
        getMaintenanceForItem(idItem)
    }, [Object.keys(maintenances).length, JSON.stringify(maintenances)]);


    const transitions = useTransition(loading, null, {
        from: { opacity: 0 },
        enter: {
            opacity: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "calc(100vh - 70px)"
        },
        leave: { opacity: 0 }
    });

    const sortElement = (elemnts) => {
        elemnts.sort(function (a, b) {
            if (a.name === null) a.name = "";
            if (b.name === null) b.name = "";

            if (a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() >
                b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim()) {
                return 1;
            }
            if (a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() <
                b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim()) {
                return -1;
            }
            // a must be equal to b
            return 0;
        });
    }

    useEffect(() => {
        const categories = [...itensCategories];
        sortElement(categories);

        var aux = {};
        var _categories = [];
        for (var i = 0; i < categories.length; i++) {
            var item = categories[i];
            aux.value = item.id;
            aux.label = item.name;
            _categories.push(aux);
            aux = {};
        }
        setCategoriesArray(_categories);
    }, [itensCategories]);

    useEffect(() => {
        if (allowanceResponseType === "ERROR" && messageHasChanged) {
            handleSnack({ kind: "error", content: allowanceResponse });
        }
        if (responseType === "SUCCESS" && hasChanged > successiveActions) {
            handleSnack({ kind: "success", content: response });
        }
        if (responseType === "ERROR" && hasChanged > successiveActions) {
            handleSnack({ kind: "error", content: response });
        }
        return () => { };
    }, [
        responseType,
        response,
        successiveActions,
        allowanceResponseType,
        allowanceResponse,
    ]);

    const handleSnack = () => {
        setSnackBar({ ...snackBar, open: true });
        setHasChanged();
    };
    const handleSnackClose = () => {
        setSnackBar({ ...snackBar, open: false });
        resetAllowanceMessage();
    };

    const onDrop = acceptedFiles => {
        if (acceptedFiles[0] !== undefined) {
            setHasUpdatedFile(true);
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    };

    const handleChange = (name, value) => {
        setValues({ ...values, [name]: value });
    };

    const formatDate = (date) => {
        var formatedDate = new Date(date);
        return `${formatedDate.getFullYear()}-${formatedDate.getMonth() + 1}-${formatedDate.getDate()} ${formatedDate.getUTCHours()}:${formatedDate.getMinutes()}:${formatedDate.getSeconds()}`;
    }

    const redirect = go => {
        if (go) return <Redirect to={`/InventoryEquipment`} />;
    };

    return transitions.map(({ item, key, props }) =>
        item ? (
            <animated.div key={key} style={props}>
                <Spinner />;
            </animated.div>
        ) : (
            <animated.div key={key} style={props}>
                {redirect(mustRedirect)}
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={snackBar.open}
                    onClose={handleSnackClose}
                    TransitionComponent={Slide}
                    transitionDuration={{ enter: 500, exit: 500 }}
                    ContentProps={{
                        "aria-describedby": "message-id"
                    }}
                    autoHideDuration={2000}
                >
                    <MySnackbarContentWrapper
                        onClose={handleSnackClose}
                        variant={
                            responseType !== ""
                                ? responseType.toLowerCase()
                                : allowanceResponseType.toLowerCase()
                        }
                        message={
                            <span id="message-id">
                                {response !== "" ? response : allowanceResponse}
                            </span>
                        }
                    />
                </Snackbar>
                <div className="container">
                    <Breadcrumbs
                        className={classes.breadCrumbs}
                        aria-label="breadcrumb"
                        separator={<NavigateNextIcon fontSize="small" />}
                    >
                        <Typography>{t("INVENTORY.inventory")}</Typography>
                        <Link className={classes.link} to="/InventoryEquipment">
                            <Typography>{t("INVENTORY.equipments")}</Typography>
                        </Link>
                        <Link className={classes.link} to={`/InventoryEquipment/${idItem}`}>
                            <Typography>{t("INVENTORY.viewEquipment")}</Typography>
                        </Link>
                        <Typography>{t("INVENTORY.historyEquipment")}</Typography>
                    </Breadcrumbs>
                    <div className="container-header">
                        <div className="title">
                            <h1 className={classes.title}>{t("INVENTORY.historyEquipment")}</h1>
                        </div>
                    </div>
                    <Paper >
                        <div className="cabecalho">
                            {t("INVENTORY.equipmentDatas").toUpperCase()}
                        </div>
                        <div className={classes.bodyNewEquipment}>
                            <Grid container spacing={3}>
                                <Grid item xs={2} className={classes.backgroundImage}>
                                    <Grid item xs={2}>
                                        <Dropzone
                                            disabled
                                            variant="outlined"
                                            component="span"
                                            className={classes.textField}
                                            onDrop={onDrop}
                                            accept="image/jpeg, 
                                                        image/png, 
                                                        image/bmp"
                                        >
                                            {({
                                                getRootProps,
                                                getInputProps,
                                                isDragActive,
                                                isDragReject
                                            }) => (
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    {!isDragActive && (
                                                        <FormControl
                                                            className={classes.customAttachedDocumentTextField}
                                                        >

                                                            <Grid item xs={2}>
                                                                <div className={classes.thumb} key={"upload-equipament-image.jpg"}>
                                                                    <div className={classes.thumbInner}>
                                                                        <img className={classes.imgNewEquipment} alt="complex" src={window.location.origin + '/img/upload-equipament-image.png'} />
                                                                    </div>
                                                                </div>

                                                            </Grid>
                                                        </FormControl>
                                                    )}
                                                    {isDragReject && (
                                                        <FormControl
                                                            className={classes.customAttachedDocumentTextField}
                                                        >
                                                            <Input
                                                                id="custom-attached-input-cooperation"
                                                                readOnly={true}
                                                                endAdornment={
                                                                    <InputAdornment
                                                                        style={{ transform: "rotate(270deg)" }}
                                                                        position="end"
                                                                    >
                                                                        <Attachment />
                                                                    </InputAdornment>
                                                                }
                                                                aria-describedby="weight-helper-text"
                                                                inputProps={{
                                                                    "aria-label": "weight"
                                                                }}
                                                                value={t(title)}
                                                            />
                                                        </FormControl>
                                                    )}
                                                </div>
                                            )}
                                        </Dropzone>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={7}>
                                    <Typography className={classes.titleNewEquipment}>
                                        Definição
                                    </Typography>
                                    <MuiThemeProvider theme={tooltipTheme}>
                                        <Tooltip
                                            TransitionComponent={Zoom}
                                            open={requiredName}
                                            title={t("GENERAL.requiredField")}
                                        >
                                            <TextField
                                                disabled
                                                className={classes.textField}
                                                label={t("INVENTORY.nameOfItem")}
                                                value={values.name}
                                                onChange={(ev) => {
                                                    if (ev.target.value.length <= 200) {
                                                        setRequiredName(false)
                                                        handleChange("name", ev.target.value);
                                                    }
                                                }}
                                                margin="normal"
                                            />
                                        </Tooltip>
                                    </MuiThemeProvider>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                disabled
                                                className={classes.textField}
                                                label={t("INVENTORY.brand")}
                                                value={values.brand}
                                                onChange={(ev) => { if (ev.target.value.length <= 50) handleChange("brand", ev.target.value) }}
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <MuiThemeProvider theme={tooltipTheme}>
                                                <Tooltip
                                                    TransitionComponent={Zoom}
                                                    open={requiredCategory}
                                                    title={t("GENERAL.requiredField")}
                                                >
                                                    <TextField
                                                        disabled
                                                        select
                                                        id="client"
                                                        label={t("INVENTORY.category")}
                                                        name="category"
                                                        value={values.categoryId}
                                                        onChange={(ev) => {
                                                            setRequiredCategory(false)
                                                            handleChange("categoryId", ev.target.value)
                                                        }}
                                                        className={classes.textField}
                                                        margin="normal"
                                                        classes={{
                                                            root: classes.inputRoot,
                                                            input: classes.inputInput
                                                        }}
                                                        inputProps={{ "aria-label": "search" }}
                                                    >
                                                        {categoriesArray.map(category => (
                                                            <MenuItem key={category.value} value={category.value}>
                                                                {category.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Tooltip>
                                            </MuiThemeProvider>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <React.Fragment>
                                    <Grid item xs={12} sm={12} style={{ paddingBottom: 0 }}>
                                        <Typography className={classes.titleNewEquipment}>
                                            Identificação
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <MuiThemeProvider theme={tooltipTheme}>
                                            <Tooltip
                                                TransitionComponent={Zoom}
                                                open={requiredCode}
                                                title={t("GENERAL.requiredField")}
                                            >
                                                <TextField
                                                    disabled
                                                    className={classes.textField}
                                                    label={t("INVENTORY.fcpcCode")}
                                                    value={values.fcpcCode}
                                                    onChange={(ev) => {
                                                        if (ev.target.value.length <= 50) {
                                                            setRequiredCode(false);
                                                            setRequiredSN(false);
                                                            handleChange("fcpcCode", ev.target.value)
                                                        }
                                                    }}
                                                    margin="normal"
                                                />
                                            </Tooltip>
                                        </MuiThemeProvider>
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <MuiThemeProvider theme={tooltipTheme}>
                                            <Tooltip
                                                TransitionComponent={Zoom}
                                                open={requiredCode}
                                                title={t("GENERAL.requiredField")}
                                            >
                                                <TextField
                                                    disabled
                                                    className={classes.textField}
                                                    label={t("INVENTORY.externalCode")}
                                                    value={values.externCode}
                                                    onChange={(ev) => {
                                                        if (ev.target.value.length <= 50) {
                                                            setRequiredCode(false);
                                                            setRequiredSN(false);
                                                            handleChange("externCode", ev.target.value)
                                                        }
                                                    }}
                                                    margin="normal"
                                                />
                                            </Tooltip>
                                        </MuiThemeProvider>
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <MuiThemeProvider theme={tooltipTheme}>
                                            <Tooltip
                                                TransitionComponent={Zoom}
                                                open={requiredCode}
                                                title={t("GENERAL.requiredField")}
                                            >
                                                <TextField
                                                    disabled
                                                    className={classes.textField}
                                                    label={t("INVENTORY.ufcCode")}
                                                    value={values.ufcCode}
                                                    onChange={(ev) => {
                                                        if (ev.target.value.length <= 50) {
                                                            setRequiredCode(false);
                                                            setRequiredSN(false);
                                                            handleChange("ufcCode", ev.target.value)
                                                        }
                                                    }}
                                                    margin="normal"
                                                />
                                            </Tooltip>
                                        </MuiThemeProvider>
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <MuiThemeProvider theme={tooltipTheme}>
                                            <Tooltip
                                                TransitionComponent={Zoom}
                                                open={requiredCode}
                                                title={t("GENERAL.requiredField")}
                                            >
                                                <TextField
                                                    disabled
                                                    className={classes.textField}
                                                    label={t("INVENTORY.lsbdCode")}
                                                    value={values.lsbdCode}
                                                    onChange={(ev) => {
                                                        if (ev.target.value.length <= 50) {
                                                            setRequiredCode(false);
                                                            setRequiredSN(false);
                                                            handleChange("lsbdCode", ev.target.value)
                                                        }
                                                    }}
                                                    margin="normal"
                                                />
                                            </Tooltip>
                                        </MuiThemeProvider>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <MuiThemeProvider theme={tooltipTheme}>
                                            <Tooltip
                                                TransitionComponent={Zoom}
                                                open={requiredSN}
                                                title={t("GENERAL.requiredField")}
                                            >
                                                <TextField
                                                    disabled
                                                    className={classes.textField}
                                                    label={t("INVENTORY.serialNumber")}
                                                    value={values.serialNumber}
                                                    onChange={(ev) => {
                                                        if (ev.target.value.length <= 50) {
                                                            setRequiredSN(false);
                                                            setRequiredCode(false);
                                                            handleChange("serialNumber", ev.target.value)
                                                        }
                                                    }}
                                                    margin="normal"
                                                />
                                            </Tooltip>
                                        </MuiThemeProvider>
                                    </Grid>
                                </React.Fragment>
                            </Grid>
                        </div>
                    </Paper>
                    <br></br>
                    <Paper >
                        <HistoryTabPanel historyEquipment={historyEquipment} />
                    </Paper>
                </div>
                <Footer />
            </animated.div >
        )
    );
};

export default ViewHistoryEquipment;