import React, { useState, useEffect } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { Paper } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { useStyles } from "components/layout/CommonStyles";
import { useTranslation } from "react-i18next";
import Circle from "./Circle";
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import InputAdornment from "@material-ui/core/InputAdornment";

const UserInformation = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const { name, cpf, updateCosts } = props;
    const [selectedDate, handleDateChange] = useState(new Date());

    useEffect(() => {
        updateCosts(selectedDate.getFullYear());
    }, [selectedDate]);

    return (
        <div style={{ paddingBottom: "50px" }}>
            <Paper className={classes.root}>
                <div className={classes.header}>
                    {t("HRCOSTS.colaboratorInformation").toUpperCase()}
                </div>
                <div className={classes.bodyForm}>
                    <Grid container spacing={0} xs={12}>
                        <Grid item container xs={6} spacing={1} >
                            <Grid item xs={6}>
                                <TextField
                                    className={classes.textField}
                                    label={t("PROFILES.nameLabel")}
                                    value={name}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    className={classes.textField}
                                    label={t("PERSONAL.cpf").toUpperCase()}
                                    value={cpf}
                                    margin="normal"
                                />
                            </Grid>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid item xs={2}>
                                    <DatePicker
                                        id="baseYear"
                                        views={["year"]}
                                        value={selectedDate}
                                        label={t("PROJECTS.baseYear")}
                                        onChange={handleDateChange}
                                        margin="normal"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <InsertInvitationIcon color="action" />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item container xs={6} spacing={0} >
                            <span className="colorKey">Legenda</span>
                            <Grid item xs={3} style={{ padding: "4px" }}>
                                <div className="divCircle">
                                    <Circle i={1} />
                                    <span className="spanColorKey">{t("HRCOSTS.clt")}</span>
                                </div>
                            </Grid>
                            <Grid item xs={3} style={{ padding: "4px" }}>
                                <div className="divCircle">
                                    <Circle i={0} />
                                    <span className="spanColorKey">{t("HRCOSTS.rpa")}</span>
                                </div>
                            </Grid>
                            <Grid item xs={3} style={{ padding: "4px" }}>
                                <div className="divCircle">
                                    <Circle i={2} />
                                    <span className="spanColorKey">{t("HRCOSTS.intern")}</span>
                                </div>
                            </Grid>
                            <Grid item xs={3} style={{ padding: "4px" }}>
                                <div className="divCircle">
                                    <Circle i={3} />
                                    <span className="spanColorKey">{t("HRCOSTS.scholarshipHolder")}</span>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Paper>
        </div>
    );
};
export default UserInformation;