import React from "react";
import "../../../App.css";
import DashboardNavBar from "../../layout/DashboardNavBar";
import SideBar from "../../layout/sidebar/Sidebar";
import EditExternalUsers from "./EditExternalUsers";

const CallEditExternalUsers = ({ match }) => {
  return (
    <div>
      <DashboardNavBar />
      <SideBar />
      <EditExternalUsers id={match.params.id} />
    </div>
  );
}
export default CallEditExternalUsers;