import DateFnsUtils from "@date-io/date-fns";
import Fab from "@material-ui/core/Fab";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import Add from "@material-ui/icons/AddCircle";
import Attachment from "@material-ui/icons/Attachment";
import Down from "@material-ui/icons/GetApp";
import Save from "@material-ui/icons/Save";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import axios from "axios";
import { useStyles } from "components/layout/CommonStyles";
import MySnackbarContentWrapper from "components/layout/Message";
import AllowancesContext from "context/allowance/allowanceContext";
import ProjectsContext from "context/projects/projectsContext";
import React, { useContext, useEffect, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import { animated, useTransition } from "react-spring";
import Addendum from "./Addendum";
import Cookies from "js-cookie";

const tooltipTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#2c3e51"
    }
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: "white",
        fontSize: "1em",
        backgroundColor: "red",
        opacity: "0.7"
      }
    }
  }
});
const CooperationTerm = props => {
  let cooperationTerm = {};
  let arrayOfAddendums = [];
  const titleName = "GENERAL.requiredField";
  var fileDownload = require("js-file-download");

  const projectsContext = useContext(ProjectsContext);
  const allowanceContext = useContext(AllowancesContext);
  const {
    loading,
    project,
    response,
    hasUpdate,
    getProject,
    setMessage,
    resetMessage,
    responseType,
    resetProject,
    resetAddendums,
    successiveActions,
    resetDeleteMessage,
    triggerUpdateCreate,
    updateCreateAddendums,
    setIdealNumberOfChildUpdates,
    setValueRequiredMessage
  } = projectsContext;

  const { projectId } = props;

  const [dates, setDates] = useState({
    startDate: new Date(),
    endDate: new Date()
  });

  const [snackBar, setSnackBar] = useState({
    open: false,
    kind: "",
    content: ""
  });

  const classes = useStyles();
  const [t] = useTranslation();
  const numberRef = useRef(null);
  const gpfRef = useRef(null);
  const [count, setCount] = useState(0);
  const [array, setArray] = useState([]);
  const [file, setFile] = useState(null);
  const [number, setNumber] = useState("");
  const [gpf, setgpf] = useState("");
  const [delay, setDelay] = useState(true);
  const [fullPath, setFullPath] = useState("");
  const [addendums, setAddendums] = useState(0);
  const [validNumber, setValidNumber] = useState(false);
  const [validGpf, setValidGpf] = useState(false);
  const [estimatedCost, setEstimatedCost] = useState("");
  const [fileUploadedId, setFileUploadedId] = useState(0);
  const [isDownloadable, setIsDownloadable] = useState(true);
  const [hasUpdatedFile, setHasUpdatedFile] = useState(false);
  const [title, setTitle] = useState(t("GENERAL.addDocument"));
  const [cooperationTermId, setCooperationTermId] = useState(0);
  const [cooperationTermCost, setCooperationTermCost] = useState("");

  const {
    allowanceResponse,
    allowanceResponseType,
    resetAllowanceMessage
  } = allowanceContext;

  const handleSnack = () => {
    setSnackBar({ ...snackBar, open: true });
  };

  const handleSnackClose = () => {
    setSnackBar({ ...snackBar, open: false });
    resetAllowanceMessage();
  };

  const formatMoneyField = estimatedcost => {
    var cost = estimatedcost.toString().split("");
    if (
      (cost[cost.length - 1] === "0" && cost[cost.length - 2] === "0") ||
      cost[cost.length - 3] !== "."
    ) {
      if (cost[cost.length - 2] === ".") {
        cost.push("0");
      } else {
        cost.push("0");
        cost.push("0");
      }
    }

    var estimatedCost = cost
      .filter(el => el !== "." && el !== ",")
      .join("")
      .split("")
      .reverse()
      .join("");
    return estimatedCost;
  };

  const converTotBrCurrency = number => {
    var temp = "";
    var len = number.length;
    var count = 0;

    for (var i = 0; i < len; i++) {
      if (i === 1) {
        let aux = "," + number[i] + temp;
        temp = aux;
      } else {
        if (count === 3) {
          count = 1;
          let aux = number[i] + "." + temp;
          temp = aux;
        } else {
          if (i > 1) {
            count += 1;
          }
          let aux = number[i] + temp;
          temp = aux;
        }
      }
    }
    return temp;
  };

  useEffect(() => {
    // reset State addendums
    resetAddendums();
    const timer = setTimeout(() => {
      if (responseType === "SUCCESS") {
        handleSnack({ kind: "success", content: response });
      } else {
      }
      if (responseType === "ERROR") {
        handleSnack({ kind: "error", content: response });
      }
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [responseType, response, successiveActions]);

  useEffect(() => {
    getProject(projectId);
    const timer = setTimeout(() => {
      if (project && Object.values(project).length > 0) {
        setEstimatedCost(project.estimatedCostAmount);
        if (project.sgppdAddendum && project.sgppdAddendum.length > 0) {
          cooperationTerm = getCooperationTerm(project.sgppdAddendum);
          setAddendums(project.sgppdAddendum.length);
          setCount(project.sgppdAddendum.length);
          if (cooperationTerm) {
            setCooperationTermId(cooperationTerm.id);
            if (cooperationTerm.fileId) {
              getFile(cooperationTerm.fileId);
            }
            setNumber(cooperationTerm.number);
            if (cooperationTerm.gpf) {
              setgpf(cooperationTerm.gpf)
            }
          }
        }
      }

      var strDate;
      var endDate;


      if (cooperationTerm && Object.entries(cooperationTerm).length === 0 &&
        cooperationTerm.constructor === Object) {
        //converter time zone
        var startdatewithtimezone = project.information ? new Date(project.information.startdate) : new Date();
        startdatewithtimezone.setMinutes(startdatewithtimezone.getMinutes() + startdatewithtimezone.getTimezoneOffset())
        var enddatewithtimezone = project.information ? new Date(project.information.enddate) : new Date();
        enddatewithtimezone.setMinutes(enddatewithtimezone.getMinutes() + enddatewithtimezone.getTimezoneOffset())
        strDate = startdatewithtimezone;
        endDate = enddatewithtimezone;
        var estimatedCostProject = project.information ? project.information.estimatedcost : 0;
        setCooperationTermCost(converTotBrCurrency(formatMoneyField(estimatedCostProject)));
      } else {
        var startdatewithtimezone = new Date(cooperationTerm.startdate)
        startdatewithtimezone.setMinutes(startdatewithtimezone.getMinutes() + startdatewithtimezone.getTimezoneOffset())
        var enddatewithtimezone = new Date(cooperationTerm.enddate)
        enddatewithtimezone.setMinutes(enddatewithtimezone.getMinutes() + enddatewithtimezone.getTimezoneOffset())
        strDate = startdatewithtimezone;
        endDate = enddatewithtimezone;
        setCooperationTermCost(converTotBrCurrency(formatMoneyField(cooperationTerm.estimatedcost)));
      }

      setDates({
        ...dates,
        startDate: new Date(strDate),
        endDate: new Date(endDate)
      });
      setDelay(false);
    }, 300);
    return () => {
      arrayOfAddendums = [];
      clearTimeout(timer);
      setArray([]);
    };
  }, [
    Object.values(cooperationTerm).length,
    JSON.stringify(project),
    JSON.stringify(arrayOfAddendums)
  ]);

  useEffect(() => {
    if (hasUpdate) {
      resetProject();
    }
    return () => { };
  }, [hasUpdate]);

  useEffect(() => {
    return () => {
      resetMessage();
      resetDeleteMessage();
    };
  }, []);

  // Return the cooperationTerm, assuming that it is not always the first
  const getCooperationTerm = sgppdAddendums => {
    for (var i = 0; i < sgppdAddendums.length; i++) {
      if (sgppdAddendums[i].cooperationTerm) return sgppdAddendums[i];
    }
  };

  const getFile = id => {
    axios
      .get(process.env.REACT_APP_SERVER + `api/ProjectFiles/${id}`)
      .then(res => {
        setFile(res.data);
        var array = res.data.path.split("/");
        setFullPath(res.data.path);
        setIsDownloadable(false);
        setTitle(array[array.length - 1]);
        setFileUploadedId(res.data.id);
      });
  };

  const callbackFromAddendum = id => {
    // if array.length === 0, signinfica q nenhum termo aditivo foi criado
    if (array.length !== 0) {
      id = id - 1;
      if (id === array.length) {
        array.pop();
        setCount(addendums);
      } else {
        if (id === 0) {
          array.shift();
          setCount(addendums);
        } else {
          array.splice(array[id], 1);
          setCount(addendums);
        }
      }
    }
  };

  const returnAddendum = () => {
    let iterator = 1;
    arrayOfAddendums = [];
    if (project.sgppdAddendum && project.sgppdAddendum.length > 1) {
      for (var i = 0; i < addendums; i++) {
        if (project.sgppdAddendum[i]) {
          if (
            !project.sgppdAddendum[i].cooperationTerm &&
            project.sgppdAddendum[i].number !== "GENERAL"
          ) {
            arrayOfAddendums.push(
              <Addendum
                key={i}
                opacity={1}
                delay={delay}
                previousAddendum={project.sgppdAddendum[i - 1]}
                projectId={projectId}
                addendumId={project.sgppdAddendum[i]["id"]}
                addendum={project.sgppdAddendum[i]}
                increment={iterator}
                callbackFromCooperationTerm={callbackFromAddendum}
                cooperationTermCost={
                  getCooperationTerm(project.sgppdAddendum).estimatedcost
                }
              />
            );
            iterator++;
          }
        }
      }
      return arrayOfAddendums;
    } else {
      return <></>;
    }
  };

  const newAddendum = () => {
    setValueRequiredMessage(false);
    if (addendums === count) {
      setCount(count + 1);
      array.push(
        <div key={count}>
          <Addendum
            opacity={1}
            delay={delay}
            projectId={projectId}
            previousAddendum={project.sgppdAddendum[addendums - 2]}
            addendumId={null}
            addendum={{}}
            increment={addendums - 1}
            callbackFromCooperationTerm={callbackFromAddendum}
          />
        </div>
      );
    }
  };

  const onDrop = acceptedFiles => {
    setFile(acceptedFiles[0]);
    if (acceptedFiles[0] !== undefined) {
      setTitle(acceptedFiles[0].name);
      setHasUpdatedFile(true);
    } else {
      setTitle(t("GENERAL.invalidFormat"));
    }
  };

  const verifyRequiredFieldIsEmpty = () => {
    var hasEmptyField = false;
    var len = document.getElementsByClassName('Addendum').length;

    for (var i = 0; i < len; i++) {
      var gpfHasValue = document.getElementById("gpf" + (i + 1)).value !== "";
      var costHasValue = document.getElementById("addendumCost" + (i + 1)).value !== "";
      var startHasValue = document.getElementById("addendumStartDate" + (i + 1)).value !== "";
      var endHasValue = document.getElementById("addendumEndDate" + (i + 1)).value !== "";

      if (!(costHasValue && startHasValue && endHasValue && gpfHasValue)) {
        hasEmptyField = true;
        break;
      }
    }

    return hasEmptyField;
  };

  const onClickUpload = () => {
    var anyRequiredFieldEmpty = verifyRequiredFieldIsEmpty();


    if (number.length === 0 || gpf.length === 0 || anyRequiredFieldEmpty) {
      setValueRequiredMessage(true);

      if (number.length === 0)
        setValidNumber(true);

      if (gpf.length === 0) {
        setValidGpf(true)
      }

    } else {
      setValueRequiredMessage(false);
      var totalCost = getCooperationTerm(project.sgppdAddendum)
        ? getCooperationTerm(project.sgppdAddendum).estimatedcost
        : 0;
      var len = document.getElementsByName("estimatedCost").length;

      for (var i = 1; i < len; i++) {
        var costValue = parseFloat(
          document
            .getElementById("addendumCost" + (i + 1))
            .value.split("")
            .filter(el => el !== ".")
            .join("")
            .replace(",", ".")
        );
        totalCost += costValue;
      }

      const formData = new FormData();
      var startDateFormated = dates.startDate.getDate() + "/" + (dates.startDate.getMonth() + 1) + "/" + dates.startDate.getFullYear();
      var endDateFormated = dates.endDate.getDate() + "/" + (dates.endDate.getMonth() + 1) + "/" + dates.endDate.getFullYear();
      // se não ha dados na possição zero, não há termo de cooperação adicionado, portanto, adicionar:
      if (addendums === 0) {
        // Criando termo decooperação e termo geral de uma vez.

        formData.append("file", file);
        formData.append("number", number);
        formData.append("gpf", gpf);
        formData.append("projectId", projectId);
        formData.append("id", cooperationTermId);
        formData.append("cooperationTerm", true);
        formData.append("fileId", fileUploadedId);
        formData.append("estimatedCost", estimatedCost);
        formData.append("endDate", endDateFormated);
        formData.append("startDate", startDateFormated);
        formData.append("generalTotalCost", estimatedCost);


        const generalAddendum = new FormData();

        generalAddendum.append("id", null);
        generalAddendum.append("file", null);
        generalAddendum.append("fileId", null);
        generalAddendum.append("number", "GENERAL");
        generalAddendum.append("gpf", gpf);
        generalAddendum.append("projectId", projectId);
        generalAddendum.append("cooperationTerm", false);
        generalAddendum.append("estimatedCost", estimatedCost);
        generalAddendum.append("endDate", endDateFormated);
        generalAddendum.append(
          "startDate",
          startDateFormated
        );

        axios
          .all([
            axios.post(
              process.env.REACT_APP_SERVER + `api/Addendums`,
              formData, {
              headers: {
                "Content-type": "application/json",
                "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
              }
            }
            ),
            axios.post(
              process.env.REACT_APP_SERVER + `api/Addendums`,
              generalAddendum, {
              headers: {
                "Content-type": "application/json",
                "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
              }
            }
            )
          ])
          .then(
            axios.spread(() => {
              setMessage({
                response: t(
                  "PROJECTS.step.cooperationTerm.successfullyCreated"
                ),
                responseType: "SUCCESS"
              });
            })
          )
          .catch(() => {
            setMessage({
              response: t(
                "PROJECTS.step.cooperationTerm.unsuccessfullyCreated"
              ),
              responseType: "ERROR"
            });
          })
          .finally(() => {
            // do something
          });
      } else {
        triggerUpdateCreate(true);
        setIdealNumberOfChildUpdates(count);
        formData.append("file", file);
        formData.append("number", number);
        formData.append("gpf", gpf);
        formData.append("projectId", projectId);
        formData.append("id", cooperationTermId);
        formData.append("cooperationTerm", true);
        formData.append("fileId", fileUploadedId);
        // Caso o requisito volte atrás da proposta de calculo automatico, alterar a variavel abaixo:
        formData.append("estimatedCost", cooperationTermCost
          .split("")
          .filter(el => el !== ".")
          .join("")
          .replace(",", "."));
        formData.append("endDate", endDateFormated);
        formData.append("startDate", startDateFormated);
        formData.append("generalTotalCost", totalCost);
        updateCreateAddendums(formData);
        setDelay(true);
      }
    }
  };

  const onClickDownload = async () => {
    if (title !== t("GENERAL.addDocument")) {
      var noSlashPath = fullPath.replace(/\//g, ";");

      const url = process.env.REACT_APP_SERVER + "api/file/" + noSlashPath;
      axios
        .get(url, {
          headers: {
            "Content-Type": "application/json"
          },
          responseType: "blob"
        })
        .then(function (res) {
          var blob = new Blob([res.data], {});
          fileDownload(blob, title);
        })
        .catch(() => {
          setMessage({
            response: t("PROJECTS.step.cooperationTerm.downloadError"),
            responseType: "ERROR"
          });
        });
    } else {
      console.log("treat erro when download has no file");
    }
  };

  const transitions = useTransition(loading || delay, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  });

  const clearRiqueredMessage = () => {
    setValidNumber(false);
    setValidGpf(false);
    setValueRequiredMessage(false);
  };

  const handleDateChange = name => e => {
    setDates({
      ...dates,
      [name]: e
    });
  };

  const handleFieldChangeCost = name => event => {
    if (event.target.value !== " " && !event.target.value.includes(" ")) {
      var str = event.target.value.split("");
      var aux = str.filter(el => el !== "." && el !== ",").join("");
      var value = event.target.value;

      if (event.target.value) {
        value = converTotBrCurrency(
          aux
            .split("")
            .reverse()
            .join("")
        );
      }

      aux = value
        .split("")
        .filter(el => el !== ".")
        .join("")
        .replace(",", ".");
      if (!isNaN(aux)) {
        setCooperationTermCost(value);
      }
    }

  };

  return transitions.map(({ item, props, key }) =>
    item ? (
      <animated.div key={key} style={props}>
        {/* <Spinner /> */}
        <></>
      </animated.div>
    ) : (
      <animated.div key={key} style={props}>
        <Paper className={classes.root}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={snackBar.open}
            onClose={handleSnackClose}
            TransitionComponent={Slide}
            transitionDuration={{ enter: 500, exit: 500 }}
            ContentProps={{
              "aria-describedby": "message-id"
            }}
            autoHideDuration={2000}
          >
            <MySnackbarContentWrapper
              onClose={handleSnackClose}
              variant={
                responseType !== ""
                  ? responseType.toLowerCase()
                  : allowanceResponseType.toLowerCase()
              }
              message={
                <span id="message-id">
                  {response !== "" ? response : allowanceResponse}
                </span>
              }
            />
          </Snackbar>
          <div className={classes.cooperationTermHeader}>
            <Grid container item spacing={1}>
              <Grid
                item
                xs={6}
                className={classes.addendumIntermIconsMarginLeft}
              >
                {t("PROJECTS.coopertationTerm").toUpperCase()}
              </Grid>
              <Grid item xs={6}>
                <div className={classes.addendumIntermIcons}>
                  <div className={classes.addendumIntermIconsMarginRight}>
                    <Tooltip
                      title={t("PROJECTS.step.cooperationTerm.download")}
                      aria-label="download"
                      placement="top"
                    >
                      <div>
                        <IconButton
                          disabled={isDownloadable}
                          onClick={onClickDownload}
                        >
                          <Down />
                        </IconButton>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={classes.bodyForm}>
            <Grid container item spacing={2}>
              <Grid item xs={2}>
                <MuiThemeProvider theme={tooltipTheme}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    open={validNumber}
                    title={t(titleName)}
                  >
                    <TextField
                      id="coopertationTermNumber"
                      name="coopertationTermNumber"
                      label={t("PROJECTS.coopertationTermNumber") + "*"}
                      inputRef={numberRef}
                      error={validNumber}
                      className={classes.textField}
                      value={number}
                      onChange={e => {
                        setNumber(e.target.value);
                      }}
                      onFocus={clearRiqueredMessage}
                      margin="normal"
                    />
                  </Tooltip>
                </MuiThemeProvider>
              </Grid>
              <Grid item xs={2}>
                <MuiThemeProvider theme={tooltipTheme}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    open={validGpf}
                    title={t(titleName)}
                  >
                    <TextField
                      id="gpf"
                      name="gpf"
                      label={t("PROJECTS.gpfUpperCase") + "*"}
                      inputRef={gpfRef}
                      error={validGpf}
                      className={classes.textField}
                      value={gpf}
                      onChange={e => {
                        setgpf(e.target.value);
                      }}
                      onFocus={clearRiqueredMessage}
                      margin="normal"
                    />
                  </Tooltip>
                </MuiThemeProvider>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  className={classes.textField}
                  //disabled
                  margin="normal"
                  id="cooperationTermCost"
                  label={t("PROJECTS.estimatedCost")}
                  name="cooperationTermCost"
                  value={cooperationTermCost}
                  onChange={handleFieldChangeCost("cooperationTermCost")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    )
                  }}
                />
              </Grid>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={2}>
                  <KeyboardDatePicker
                    className={classes.textField}
                    disableToolbar
                    //disabled
                    autoOk={true}
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="startDate"
                    label={t("GENERAL.startDate")}
                    onChange={handleDateChange("startDate")}
                    value={dates.startDate}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <KeyboardDatePicker
                    className={classes.textField}
                    disableToolbar
                    //disabled
                    minDate={dates.startDate}
                    minDateMessage={"A data não deve ser anterior à data mínima"}
                    autoOk={true}
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="endDate"
                    label={t("GENERAL.endDate")}
                    value={dates.endDate}
                    onChange={handleDateChange("endDate")}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
              <Grid item xs={2}>
                <Dropzone
                  variant="outlined"
                  component="span"
                  className={classes.textField}
                  onDrop={onDrop}
                  accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                >
                  {({
                    getRootProps,
                    getInputProps,
                    isDragActive,
                    isDragReject
                  }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      {!isDragActive && (
                        <FormControl
                          className={classes.customAttachedDocumentTextField}
                        >
                          <InputLabel>
                            {!isDownloadable || hasUpdatedFile
                              ? t("PROJECTS.hasAttachedFile")
                              : ""}
                          </InputLabel>
                          <Input
                            id="custom-attached-input-cooperation"
                            readOnly={true}
                            endAdornment={
                              <InputAdornment
                                style={{ transform: "rotate(270deg)" }}
                                position="end"
                              >
                                <Attachment />
                              </InputAdornment>
                            }
                            aria-describedby="weight-helper-text"
                            inputProps={{
                              "aria-label": "weight"
                            }}
                            value={t(title)}
                          />
                        </FormControl>
                      )}
                      {isDragReject && (
                        <FormControl
                          className={classes.customAttachedDocumentTextField}
                        >
                          <InputLabel>
                            {!isDownloadable || hasUpdatedFile
                              ? t("PROJECTS.hasAttachedFile")
                              : ""}
                          </InputLabel>
                          <Input
                            id="custom-attached-input-cooperation"
                            readOnly={true}
                            endAdornment={
                              <InputAdornment
                                style={{ transform: "rotate(270deg)" }}
                                position="end"
                              >
                                <Attachment />
                              </InputAdornment>
                            }
                            aria-describedby="weight-helper-text"
                            inputProps={{
                              "aria-label": "weight"
                            }}
                            value={t(title)}
                          />
                        </FormControl>
                      )}
                    </div>
                  )}
                </Dropzone>
              </Grid>
            </Grid>
          </div>
        </Paper>

        <div style={{ paddingTop: "30px" }}>
          <Paper>
            <div className={classes.header}>
              {t("PROJECTS.step.addendum").toUpperCase()}
              <Tooltip
                title={t("PROJECTS.step.cooperationTerm.newAddendum")}
                aria-label="new Addendum"
                placement="top"
              >
                <div>
                  <IconButton
                    disabled={addendums === 0 ? true : false}
                    onClick={newAddendum}
                  >
                    <Add />
                  </IconButton>
                </div>
              </Tooltip>
            </div>
            {returnAddendum()}
            <div>{array}</div>
          </Paper>
        </div>

        <Fab
          type="submit"
          form="formAddendums"
          color="primary"
          aria-label="save"
          className={classes.fab}
          onClick={onClickUpload}
        >
          <Save />
        </Fab>
      </animated.div>
    )
  );
};

export default CooperationTerm;
