import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { useStyles } from 'components/layout/CommonStyles';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import Buttons from 'components/buttons/Pressed';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Download from '@material-ui/icons/GetApp';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import Input from 'components/inputs/Input';
import { FormatDateToDataBase } from '../../../../../utils/dates/FormatDateToDataBase';
import { dateValidation } from 'utils/validation/DateValidation';
import axios from 'axios';
import InputFile from 'components/inputs/InputModal';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Cookies from 'js-cookie';
import DefaultInfo from 'components/Infos/DefaultInfo';
import Typography from 'components/typography/Typography';
import { useMutationFireCollaborator } from 'hooks/DataFetching/HR/Collaborator/useMutationFireCollaborator';
import { Rulesets } from 'global/constants';

const DialogDemission = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const fileDownload = require('js-file-download');

    const [values, setValues] = useState({
        id: '',
        contractPeriodIdx: 0,
        demissionCraftFilePath: '',
        demissionCraftFileName: 'Anexar ofício',
        demissionExamFilePath: '',
        demissionExamFileName: 'Anexar exame',
        fireEmailUserDisabled: false,
        deleteFingerPrint: false,
        demissionJustification: ''
    });

    const [dates, setDates] = useState({
        demissionDate: null
    });

    const [demissionCraftFile, setDemissionCraftFile] = useState([]);
    const [demissionExamFile, setDemissionExamFile] = useState([]);

    const [requiredDemissionDate, setRequiredDemissionDate] = useState(false);
    const [invalidDemissionDate, setInvalidDemissionDate] = useState(false);

    const [requiredDemissionJustification, setRequiredDemissionJustification] =
        useState(false);

    const {
        open,
        setOpen,
        context,
        userId,
        admission,
        fetchData,
        authId,
        viewValues
    } = props;

    const clearDialog = () => {
        setValues({
            id: '',
            contractPeriodIdx: 0,
            demissionCraftFilePath: '',
            demissionCraftFileName: 'Anexar exame',
            demissionExamFilePath: '',
            demissionExamFileName: 'Anexar Contrato',
            demissionJustification: ''
        });
        setDates({
            demissionDate: null
        });
        setRequiredDemissionDate(false);
        setInvalidDemissionDate(false);
        setRequiredDemissionJustification(false);
    };

    const setView = () => {
        setValues({
            demissionJustification: viewValues.justification
                ? viewValues.justification
                : '',
            demissionCraftFilePath: viewValues.demissionCraftFilePath
                ? viewValues.demissionCraftFilePath
                : '',
            demissionCraftFileName:
                viewValues.demissionCraftFilePathName != null
                    ? viewValues.demissionCraftFilePathName
                    : '',
            demissionExamFilePath: viewValues.demissionExamFilePath
                ? viewValues.demissionExamFilePath
                : '',
            demissionExamFileName:
                viewValues.demissionExamFilePathName != null
                    ? viewValues.demissionExamFilePathName
                    : ''
            // justification: ""
        });
        setDates({
            demissionDate: viewValues.demissionDate
                ? viewValues.demissionDate
                : null
        });
    };
    useEffect(() => {
        if (viewValues) {
            setView();
        }
    }, [context === 'view']);

    const handleClose = () => {
        clearDialog();
        setOpen(false);
    };
    const handleChange = (name, value) => {
        let val = value;
        setValues({ ...values, [name]: val });
    };
    const handleDates = name => e => {
        setDates({
            ...dates,
            [name]: e
        });
        if (name === 'demissionDate') {
            setRequiredDemissionDate(false);
            setInvalidDemissionDate(false);
        }
    };
    const onClickDownload = async slashPath => {
        if (slashPath) {
            const noSlashPath = slashPath.replace(/\//g, ';');
            const url =
                process.env.REACT_APP_SERVER +
                'api/file/;portal_lsbd;hr_files' +
                noSlashPath;
            axios
                .get(url, {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    },
                    responseType: 'blob'
                })
                .then(function (res) {
                    const blob = new Blob([res.data], {});
                    const name = slashPath.split('/').pop();
                    fileDownload(blob, name);
                });
        }
    };

    const onDropCraft = acceptedFiles => {
        if (acceptedFiles[0] !== undefined) {
            // if (acceptedFiles.length > 1) {
            //     // setContractHelperMessage(t("GENERAL.requiredField"));
            // } else {
            handleChange('demissionCraftFileName', acceptedFiles[0].name);
            setDemissionCraftFile(
                acceptedFiles.map(file =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })
                )
            );
        }
        // }
    };

    const onDropExame = acceptedFiles => {
        if (acceptedFiles[0] !== undefined) {
            handleChange('demissionExamFileName', acceptedFiles[0].name);
            setDemissionExamFile(
                acceptedFiles.map(file =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })
                )
            );
        }
    };

    const mutation = useMutationFireCollaborator(
        handleClose,
        values.fireEmailUserDisabled,
        userId,
        fetchData
    );

    const verifySubmit = () => {
        let res = true;
        if (dates.demissionDate === null) {
            res = false;
            setRequiredDemissionDate(true);
        }
        if (!dateValidation(dates.demissionDate)) {
            res = false;
            setInvalidDemissionDate(true);
        }

        if (!values.demissionJustification) {
            res = false;
            setRequiredDemissionJustification(true);
        }

        return res;
    };

    const handleSubmit = () => {
        const canSubmit = verifySubmit();
        if (canSubmit) {
            let formData = new FormData();

            formData.append(
                'contract.demissionDate',
                dates.demissionDate
                    ? FormatDateToDataBase(new Date(dates.demissionDate))
                    : null
            );
            formData.append(
                'contract.demissionJustification',
                values.demissionJustification
                    ? values.demissionJustification
                    : ''
            );
            formData.append('contract.userId', userId);
            formData.append('contract.id', admission.id);

            formData.append(
                'demissionCraftFile',
                demissionCraftFile ? demissionCraftFile[0] : null
            );
            formData.append(
                'demissionExamFile',
                demissionExamFile ? demissionExamFile[0] : null
            );
            formData.append('authId', authId);
            formData.append('deleteFingerPrint', values.deleteFingerPrint);

            mutation.mutate(formData);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle className={classes.Title}>
                <b>
                    {context === 'view'
                        ? t('GENERAL.view') + ' ' + t('HR.demission')
                        : t('HR.firedCollaborator')}
                </b>
                <IconButton
                    style={{ float: 'right', marginTop: -10, marginRight: -17 }}
                    edge="end"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container item spacing={2} md={12} lg={12}>
                    <Grid item xs={12} sm={12}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disabled={context === 'view'}
                                className={classes.identificationDatePicker}
                                error={
                                    requiredDemissionDate ||
                                    invalidDemissionDate
                                }
                                helperText={
                                    requiredDemissionDate
                                        ? t('GENERAL.requiredField')
                                        : invalidDemissionDate
                                        ? t('GENERAL.invalidDate')
                                        : ''
                                }
                                disableToolbar
                                autoOk={true}
                                variant="inline"
                                format="dd/MM/yyyy"
                                id="validity1"
                                margin="normal"
                                invalidDateMessage=""
                                label={t('HR.demissionDate') + '*'}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date'
                                }}
                                value={dates.demissionDate}
                                onChange={handleDates('demissionDate')}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    {context === 'view' ? (
                        <React.Fragment>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    id="client1"
                                    variant="outlined"
                                    label={t('HR.dismissalExam')}
                                    name="responsible"
                                    autoComplete="off"
                                    className={classes.textField}
                                    value={values.demissionExamFileName}
                                    margin="normal"
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    disabled={
                                                        !values.demissionExamFilePath
                                                    }
                                                    onClick={() =>
                                                        onClickDownload(
                                                            values.demissionExamFilePath
                                                        )
                                                    }
                                                >
                                                    <Download />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        readOnly: true
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <TextField
                                    id="client1"
                                    variant="outlined"
                                    label={t('HR.craft')}
                                    name="responsible"
                                    autoComplete="off"
                                    className={classes.textField}
                                    value={values.demissionCraftFileName}
                                    margin="normal"
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    disabled={
                                                        !values.demissionCraftFilePath
                                                    }
                                                    onClick={() =>
                                                        onClickDownload(
                                                            values.demissionCraftFilePath
                                                        )
                                                    }
                                                >
                                                    <Download />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Grid item xs={12} sm={12}>
                                <InputFile
                                    onDrop={onDropExame}
                                    files={demissionExamFile}
                                    label={'HR.dismissalExam'}
                                    value={values.demissionExamFileName}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <InputFile
                                    onDrop={onDropCraft}
                                    files={demissionCraftFile}
                                    label={'HR.craft'}
                                    value={values.demissionCraftFileName}
                                />
                            </Grid>
                        </React.Fragment>
                    )}

                    {admission &&
                    (admission.rulesetId === Rulesets.INTERN.id ||
                        admission.rulesetId === Rulesets.RESIDENT.id ||
                        admission.rulesetId === Rulesets.SCHOLARSHIP.id) &&
                    admission.hasFingerprint ? (
                        <Grid item xs={12} sm={12}>
                            <div>
                                <Typography
                                    variant={'RobotoSemiBold'}
                                    fontSize={16}
                                    textColor={'secondary'}
                                    margin={'0 0 12px'}
                                >
                                    {t('HR.fingerPrintRegistered')}
                                </Typography>
                                <DefaultInfo
                                    textTranslation={
                                        'HR.collaboratorHasFingerPrintInfo'
                                    }
                                    fontSize={14}
                                />
                                <FormControlLabel
                                    disabled={context === 'view'}
                                    control={
                                        <Checkbox
                                            checked={values.deleteFingerPrint}
                                            onChange={ev =>
                                                handleChange(
                                                    'deleteFingerPrint',
                                                    ev.target.checked
                                                )
                                            }
                                            color="primary"
                                        />
                                    }
                                    label={t(
                                        'HR.removeCollaboratorFingerPrint'
                                    )}
                                />
                            </div>
                        </Grid>
                    ) : null}

                    <Grid item xs={12} sm={12}>
                        <div>
                            <Typography
                                variant={'RobotoSemiBold'}
                                fontSize={16}
                                textColor={'secondary'}
                                margin={'0 0 12px'}
                            >
                                {t('HR.emailDisableUser')}
                            </Typography>
                            <DefaultInfo
                                textTranslation={'HR.disableUserInfo'}
                                fontSize={14}
                            />
                            <FormControlLabel
                                disabled={context === 'view'}
                                control={
                                    <Checkbox
                                        checked={values.fireEmailUserDisabled}
                                        onChange={ev =>
                                            handleChange(
                                                'fireEmailUserDisabled',
                                                ev.target.checked
                                            )
                                        }
                                        color="primary"
                                    />
                                }
                                label={t('HR.fireEmailDisableUser')}
                            />
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Input
                            required
                            context={context}
                            label={'GENERAL.justification'}
                            value={values.demissionJustification}
                            valueName={'demissionJustification'}
                            rows={5}
                            maxLength={200}
                            handleChange={handleChange}
                            error={requiredDemissionJustification}
                            helperText={
                                requiredDemissionJustification &&
                                t('GENERAL.requiredField')
                            }
                            setRequired={setRequiredDemissionJustification}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Buttons
                    onClick={handleClose}
                    tipo="BN-noback"
                    conteudo={
                        context === 'view'
                            ? t('GENERAL.close').toUpperCase()
                            : t('GENERAL.cancel').toUpperCase()
                    }
                />
                {context === 'view' ? null : (
                    <Buttons
                        isLoading={mutation.isLoading}
                        onClick={handleSubmit}
                        tipo="BN-blue"
                        conteudo={t('HR.fire').toUpperCase()}
                    />
                )}
            </DialogActions>
        </Dialog>
    );
};

export default DialogDemission;
