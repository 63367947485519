import i18n from 'i18n';

// To Do - Fix Values in BillsStatusArray

export const BillStatus = {
    PAID: 'PAID',
    LATE: 'LATE',
    PENDING: 'PENDING',
    CANCELLED: 'CANCELLED',
    NO_BILL: 'NO_BILL'
};

export const CreditSolicitationStatus = {
    PENDING: 'PENDING',
    APPROVED: 'APPROVED',
    REFUSED: 'REFUSED',
    CANCELLED: 'CANCELLED'
};

export const TransactionsTypes = {
    DEBIT: 'DEBIT',
    CREDIT: 'CREDIT',
    CANCELLED_DEBIT: 'CANCELLED_DEBIT',
    CANCELLED_CREDIT: 'CANCELLED_CREDIT'
};

export const SourceTypes = {
    USER: 'USER',
    EXTERNAL: 'EXTERNAL'
};

export const BillsStatusArray = [
    {
        value: 'ALL',
        label: i18n.t('GENERAL.allMasculine')
    },
    {
        value: BillStatus.LATE,
        label: i18n.t('GENERAL.lateF')
    },
    {
        value: BillStatus.CANCELLED,
        label: i18n.t('QUOTAS.cancelledBill')
    },
    {
        value: BillStatus.PAID,
        label: i18n.t('GENERAL.paidF')
    },
    {
        value: BillStatus.PENDING,
        label: i18n.t('GENERAL.pending')
    },
    {
        value: BillStatus.NO_BILL,
        label: i18n.t('QUOTAS.noBill')
    }
];

export const CreditSolicitationsStatusArray = [
    {
        value: 'ALL',
        label: i18n.t('GENERAL.allMasculine')
    },
    {
        value: CreditSolicitationStatus.PENDING,
        label: i18n.t('GENERAL.awaitingApproval')
    },
    {
        value: CreditSolicitationStatus.APPROVED,
        label: i18n.t('QUOTAS.solicitationApproved')
    },
    {
        value: CreditSolicitationStatus.CANCELLED,
        label: i18n.t('QUOTAS.solicitationCancelled')
    },
    {
        value: CreditSolicitationStatus.REFUSED,
        label: i18n.t('QUOTAS.solicitationRefused')
    }
];

export const TransactionsLSBDTypeArray = [
    {
        value: 'ALL',
        label: i18n.t('GENERAL.allMasculine')
    },
    {
        value: i18n.t('QUOTAS.lsbdTransactionsDonation'),
        label: i18n.t('QUOTAS.lsbdTransactionsDonation')
    },
    {
        value: i18n.t('QUOTAS.lsbdTransactionsCredit'),
        label: i18n.t('QUOTAS.lsbdTransactionsCredit')
    },
    {
        value: i18n.t('QUOTAS.transactionsCancelledCredit'),
        label: i18n.t('QUOTAS.transactionsCancelledCredit')
    },
    {
        value: i18n.t('QUOTAS.lsbdTransactionsDebit'),
        label: i18n.t('QUOTAS.lsbdTransactionsDebit')
    },
    {
        value: i18n.t('QUOTAS.transactionsCancelledDebit'),
        label: i18n.t('QUOTAS.transactionsCancelledDebit')
    }
];

export function translateBillStatus(status) {
    switch (status) {
        case BillStatus.PAID:
            return i18n.t('GENERAL.paidF');
        case BillStatus.LATE:
            return i18n.t('GENERAL.lateF');
        case BillStatus.CANCELLED:
            return i18n.t('QUOTAS.cancelledBill');
        case BillStatus.PENDING:
            return i18n.t('GENERAL.pending');
        case BillStatus.NO_BILL:
            return i18n.t('QUOTAS.noBill');
        default:
            return '';
    }
}

export function translatePersonalTransactionsTypes(type) {
    switch (type) {
        case TransactionsTypes.CREDIT:
            return i18n.t('QUOTAS.transactionsCredit');
        case TransactionsTypes.DEBIT:
            return i18n.t('QUOTAS.transactionsDebit');
        case TransactionsTypes.CANCELLED_CREDIT:
            return i18n.t('QUOTAS.transactionsCancelledCredit');
        case TransactionsTypes.CANCELLED_DEBIT:
            return i18n.t('QUOTAS.transactionsCancelledDebit');
        default:
            return '';
    }
}

export function translateCreditSolicitationsStatus(status) {
    switch (status) {
        case CreditSolicitationStatus.APPROVED:
            return i18n.t('QUOTAS.solicitationApproved');
        case CreditSolicitationStatus.REFUSED:
            return i18n.t('QUOTAS.solicitationRefused');
        case CreditSolicitationStatus.CANCELLED:
            return i18n.t('QUOTAS.solicitationCancelled');
        case CreditSolicitationStatus.PENDING:
            return i18n.t('GENERAL.awaitingApproval');
        default:
            return '';
    }
}

export function translateLSBDTransactionsTypes(type, source) {
    if (type === TransactionsTypes.CREDIT && source === SourceTypes.EXTERNAL)
        return i18n.t('QUOTAS.lsbdTransactionsDonation');
    else if (type === TransactionsTypes.CREDIT && source === SourceTypes.USER) {
        return i18n.t('QUOTAS.lsbdTransactionsCredit');
    } else if (type === TransactionsTypes.DEBIT) {
        return i18n.t('QUOTAS.lsbdTransactionsDebit');
    } else if (type === TransactionsTypes.CANCELLED_CREDIT) {
        return i18n.t('QUOTAS.transactionsCancelledCredit');
    } else if (type === TransactionsTypes.CANCELLED_DEBIT) {
        return i18n.t('QUOTAS.transactionsCancelledDebit');
    } else {
        return '';
    }
}
