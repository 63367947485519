/* eslint-disable */
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Add from "@material-ui/icons/Add";
import Search from "@material-ui/icons/Search";
import Dissatisfied from "@material-ui/icons/SentimentVeryDissatisfied";
import { useStyles } from "components/layout/CommonStyles";
import Footer from "components/layout/Footer";
import MySnackbarContentWrapper from "components/layout/Message";
import Spinner from "components/layout/spinners/Index";
import AllowancesContext from "context/allowance/allowanceContext";
import ProjectsContext from "context/projects/projectsContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import ActivitiesTableActions from "./ActivitiesTableHandleActions";

const ActivitiesTable = props => {
  const { phase, mode, projectMode, allAllowances } = props;
  const [t] = useTranslation();
  const classes = useStyles();
  var dataFilter = phase.projectActivityPhase ? phase.projectActivityPhase : [];
  const hasActivities = phase.projectActivityPhase ? phase.projectActivityPhase.length > 0 : false;
  const projectContext = useContext(ProjectsContext);
  const allowanceContext = useContext(AllowancesContext);
  const [filter, setFilter] = useState("");
  const {
    loading,
    response,
    responseType,
    setHasChanged,
    resetMessage,
  } = projectContext;
  const {
    allowanceResponseType,
    resetAllowanceMessage
  } = allowanceContext;
  const [hasNoResult, setHasNoResult] = useState(hasActivities && dataFilter.length === 0);
  const [snackBar, setSnackBar] = useState({
    open: false,
    kind: "",
    content: ""
  });
  const renderTableActions = data => {
    return (
      <ActivitiesTableActions
        phaseId={phase.id}
        rows={data}
        headers={["id", "name", "description", "startDate", "endDate"]}
        sortedFields={["name", "description", "startDate", "endDate"]}
        visibleHeaders={[
          "NOME",
          "DESCRIÇÃO",
          "DATA DE INÍCIO",
          "DATA DE TÉRMINO"
        ]}
        mainField="name"
        mode={mode}
        projectMode={projectMode}
        allAllowances={allAllowances}
      />
    );
  };
  const [tableActions, setTableActions] = useState(
    renderTableActions(dataFilter)
  );
  useEffect(() => {
    if (responseType) {
      handleSnack();
    }
    return () => { };
  }, [responseType, response]);

  const handleSnack = () => {
    setSnackBar({ ...snackBar, open: true });
  };

  const handleSnackClose = () => {
    setSnackBar({ ...snackBar, open: false });
    setHasChanged();
    resetAllowanceMessage();
    resetMessage();
  };

  const handleSearch = event => {
    setFilter(event.target.value);

    dataFilter = dataFilter.filter(
      item =>
        item.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
        item.description.toLowerCase().includes(event.target.value.toLowerCase())
    );

    if (dataFilter.length === 0)
      setHasNoResult(true);
    else
      setHasNoResult(false);

    setTableActions(renderTableActions(dataFilter));
  };

  const transitions = useTransition(loading, null, {
    from: { opacity: 0 },
    enter: {
      opacity: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "calc(100vh - 70px)"
    },
    leave: { opacity: 0 }
  });

  return transitions.map(({ item, key, props }) =>
    item ? (
      <animated.div key={key} style={props}>
        <Spinner />
      </animated.div>
    ) : (
        <animated.div key={key} style={props}>
          {(responseType || allowanceResponseType) &&
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={snackBar.open}
              onClose={handleSnackClose}
              TransitionComponent={Slide}
              transitionDuration={{ enter: 500, exit: 500 }}
              ContentProps={{
                "aria-describedby": "message-id"
              }}
              autoHideDuration={2000}
            >
              <MySnackbarContentWrapper
                onClose={handleSnackClose}
                variant={
                  responseType
                    ? responseType.toLowerCase()
                    : allowanceResponseType.toLowerCase()
                }
                message={
                  <span id="message-id">
                    {response !== "" ? response : 'allowanceResponse'}
                  </span>
                }
              />
            </Snackbar>}
          <div className="container">
            <Paper className={classes.root}>
              <div className={classes.header}>
                <Grid container item spacing={2} md={12}>
                  <Grid item xs={1}>
                    {t("ACTIVITIES.activities").toUpperCase()}
                  </Grid>
                </Grid>
              </div>
              <div className={classes.divAddParticipant}>
                <div style={{ display: !hasActivities ? "none" : "" }} className={classes.participantSearch}>
                  <Toolbar>
                    <Search className={classes.searchLeftIcon} />
                    <TextField
                      fullWidth
                      id="search-list-activities"
                      className={classes.textField}
                      label={t("ACTIVITIES.activitiesSearchLabel")}
                      margin="normal"
                      variant="filled"
                      autoFocus
                      value={filter}
                      onChange={handleSearch}
                    />
                  </Toolbar>
                </div>
                {mode === t("GENERAL.editMode") &&
                  <Link to={`/execution/projectPhase/${phase.id}/edit/addActivity`}>
                    <Button className={classes.addParticipant} variant="contained">
                      <div className={classes.buttonIcon}>
                        <Add />
                      </div>
                      {t("ACTIVITIES.addActivities")}
                    </Button>
                  </Link>
                }
              </div>
              <div className="lineBreak"></div>
              {hasActivities ? tableActions :
                <div className={classes.noParticipantsMessage}>
                  <h2>{t("ACTIVITIES.noActivitiesMessage")}</h2>
                  <h4>{t("ACTIVITIES.noActivitiesSubMessage")}</h4>
                </div>
              }
              {hasNoResult && filter && (
                <div className={classes.noParticipantsMessage}>
                  <div>
                    <Dissatisfied className={classes.notFoundIconStyle} />
                  </div>
                  <h2>{t("GENERAL.noResultsFound")}</h2>
                  <h5>{`Sua busca por "${filter}" não retornou resultados.`}</h5>
                </div>
              )}
            </Paper>
          </div>
          <Footer />
        </animated.div >
      )
  );
};

export default ActivitiesTable;
