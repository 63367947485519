import { Months, Weekdays } from "../../global/constants";

/**
 * Class to format a date object to a string
 */
export default class DateTimeFormatter {
    static validDayFormats = {
        DDDD: date => Weekdays[date.getDay()].label,
        DDD: date => Weekdays[date.getDay()].label.slice(0, 3),
        DD: date => date.getDate().toString().padStart(2, "0"),
        D: date => date.getDate(),
    };
    static validMonthFormats = {
        MMMM: date => Months[date.getMonth()].label,
        MMM: date => Months[date.getMonth()].label.slice(0, 3),
        MM: date => (date.getMonth() + 1).toString().padStart(2, "0"),
        M: date => date.getMonth() + 1,
    };
    static validYearFormats = {
        YYYY: date => date.getFullYear(),
        YY: date => date.getFullYear().toString().slice(-2),
    };
    static validHoursFormats = {
        hh: date => date.getHours().toString().padStart(2, "0"),
        h: date => date.getHours(),
    };
    static validMinutesFormats = {
        mm: date => date.getMinutes().toString().padStart(2, "0"),
        m: date => date.getMinutes(),
    };
    static validMillisFormats = {
        sss: date => date.getMilliseconds().toString().padStart(3, "0"),
    };
    static validSecondsFormats = {
        ss: date => date.getSeconds().toString().padStart(2, "0"),
        s: date => date.getSeconds(),
    };

    static validSeparators = ["-", " ", ".", "/", ":"];

    date;

    constructor(date) {
        if (!(date instanceof Date))
            throw new Error("date is not an instance of Date");
        // if (!this.isTemplateValid(template)) throw new Error("Invalid template");
        this.date = date;
    }

    static getAllMappings() {
        return {
            ...DateTimeFormatter.validDayFormats,
            ...DateTimeFormatter.validMonthFormats,
            ...DateTimeFormatter.validYearFormats,
            ...DateTimeFormatter.validHoursFormats,
            ...DateTimeFormatter.validMinutesFormats,
            ...DateTimeFormatter.validMillisFormats,
            ...DateTimeFormatter.validSecondsFormats,
        };
    }

    static getValidFormats() {
        return Object.keys(DateTimeFormatter.getAllMappings());
    }

    /**
     * Check if the template is valid
     * @param {string} template the template to format the date
     */
    isTemplateValid(template) {
        const validItems = DateTimeFormatter.getValidFormats().join("|");
        const regex = new RegExp(`(${validItems})([./ -](${validItems})){0,2}`);
        return regex.test(template);
    }

    /**
     * Format a date object to a string
     * @param {string} template the template to format the date
     * @returns a formatted date string
     */
    toString(template) {
        let formattedTemplate = template;
        const map = DateTimeFormatter.getAllMappings();
        const date = this.date;

        var regex = new RegExp(Object.keys(map).join("|"), "g");
        formattedTemplate = formattedTemplate.replace(
            regex,
            function (matched) {
                return map[matched] ? map[matched](date) : matched;
            }
        );

        return formattedTemplate;
    }
}
