import React from "react";
import "../../../../App.css";
import SideBar from "components/layout/sidebar/Sidebar";
import DashboardNavBar from "components/layout/DashboardNavBar";
import CollaboratorsJustificationsForm from "./CollaboratorsJustificationsForm";

const CallViewCollaboratorsJustifications = ({ match }) => {

    return (
        <div>
            <DashboardNavBar />
            <SideBar />
            <CollaboratorsJustificationsForm id={match.params.id} context={"view"} />
        </div>
    );
}

export default CallViewCollaboratorsJustifications;