import React from "react";
import "../../../App.css";
import SideBar from "components/layout/sidebar/Sidebar";
import DashboardNavBar from "components/layout/DashboardNavBar";
import ProfileManagementForm from "./ProfileManagementForm";

const CallNewProfileManagement = ({ match }) => {

    return (
        <div>
            <DashboardNavBar />
            <SideBar />
            <ProfileManagementForm context={"new"} />
        </div>
    );
}

export default CallNewProfileManagement;