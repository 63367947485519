import React, { useEffect, useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Edit from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import ProjectsContext from "context/projects/projectsContext";
import { useTranslation } from "react-i18next";
import Delete from "@material-ui/icons/Delete";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Buttons from "../buttons/Pressed";
import AllowancesContext from "context/allowance/allowanceContext";
import { Redirect, Route } from "react-router-dom";
import history from "../../history";
import axios from "axios";
import CloseIcon from '@material-ui/icons/Close';
import Cookies from "js-cookie";

const useStyles = makeStyles(theme => ({
  icons: {
    opacity: "0"
  },
  Title: {
    fontFamily: "Roboto",
    fontSize: "20px",
    lineHeight: "1.3",
    color: "#000000",
    fontWeight: "bold"
  }
}));

const Actions = props => {
  const classes = useStyles();
  useEffect(() => { });
  const allowanceContext = useContext(AllowancesContext);
  const {
    verifyRights,
    formattedAllowances,
    setAllowanceMessage
  } = allowanceContext;

  const [t] = useTranslation();
  const projectsContext = useContext(ProjectsContext);
  const { deleteProject, response, responseType } = projectsContext;
  const [open, setOpen] = useState(false);
  const { item, name, permission } = props;

  const handleClickOpen = () => {
    if (permission["allowDelete"]) {
      setOpen(true);
    } else {
      setAllowanceMessage(t("PROFILES.route.accessDenied"), "ERROR");
      return (
        <Redirect
          to={{ pathname: "/projects" }}
        />
      );
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    deleteProject(item.id);
    setOpen(false);
  };

  const verifyEdit = () => {
    axios.get(process.env.REACT_APP_SERVER + `api/projects/${item.id}`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
      }
    })
      .then(res => {
        if (res.statusText === "OK") {
          history.push(`/projects/${item.id}/edit`);
        } else {
          deleteProject(item.id);
        }
      })
      .catch(error => {
        deleteProject(item.id);
      });
  }

  const verifyView = () => {
    axios.get(process.env.REACT_APP_SERVER + `api/projects/${item.id}`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
      }
    })
      .then(res => {
        if (res.statusText === "OK") {
          history.push(`/projects/${item.id}`);
        } else {
          deleteProject(item.id);
        }
      })
      .catch(error => {
        deleteProject(item.id);
      });
  }

  useEffect(() => {
    return () => { };
  }, [response, responseType]);

  return (
    <React.Fragment>
      {permission["allowRead"] &&
        <Tooltip title='Visualizar' aria-label='details' placement='top'>
          <IconButton className={classes.icons} onClick={verifyView}>
            <Visibility />
          </IconButton>
        </Tooltip>
      }
      {permission["allowUpdate"] &&
        <Tooltip title='Editar' aria-label='edit' placement='top'>
          <IconButton className={classes.icons} onClick={verifyEdit}>
            <Edit />
          </IconButton>
        </Tooltip>
      }
      {permission["allowDelete"] &&
        <Tooltip title="Deletar" aria-label="delete" placement="top">
          <IconButton className={classes.icons} onClick={handleClickOpen}>
            <Delete />
          </IconButton>
        </Tooltip>
      }

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        msg="mensagem"
      >
        <DialogTitle className={classes.Title}>
          {t("GENERAL.delete")}
          <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("GENERAL.deleteMessageM")} {t("PROJECTS.project")} "{name}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Buttons
            onClick={handleClose}
            tipo="BN-noback"
            conteudo={t("GENERAL.no")}
          />
          <Buttons
            onClick={handleDelete}
            tipo="BN-blue"
            conteudo={t("GENERAL.yes")}
          />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default Actions;