import { makeStyles } from '@material-ui/core/styles';

export const screenStyles = makeStyles(theme => ({
    finesInfoWrapper: {
        display: 'flex',
        textAlign: 'center',
        width: '100%',
        justifyContent: 'center'
    },
    finesInfoContentFirst: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRight: '1.5px solid ',
        padding: '0.5rem 1.25rem 0.5rem 0',
        width: '25%'
    },
    finesInfoContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRight: '1.5px solid ',
        padding: '0.5rem 1.25rem',
        width: '18%'
    },
    finesInfoContentLast: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0.5rem 1.25rem',
        width: '18%'
    },
    finesInfoContainer: {
        boxSizing: 'border-box',
        display: 'flex',
        padding: '1.2rem 0',
        backgroundColor: '#2C3E51',
        color: '#fff',
        justifyContent: 'space-between',
        width: '100%'
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    }
}));
