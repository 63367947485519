import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { StyledTableCell2, StyledTableRow, useStyles } from "../../../profiles/ProfileStyles";
import TableActions from "../TableActivitiesActions";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";

function desc(a, b, orderBy) {
  if (a[orderBy] === null) a[orderBy] = "";
  if (b[orderBy] === null) b[orderBy] = "";

  if (orderBy === "startDate" || orderBy === "endDate") {
    var split = a[orderBy].split("/");
    var newDate = split[1] + "/" + split[0] + "/" + split[2];
    var date1 = new Date(newDate);

    split = b[orderBy].split("/");
    newDate = split[1] + "/" + split[0] + "/" + split[2];
    var date2 = new Date(newDate);

    if (date2 < date1) return -1;
    if (date2 > date1) return 1;
  }

  if (
    b[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "") <
    a[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "")
  ) {
    return -1;
  }
  if (
    b[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
    a[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "")
  ) {
    return 1;
  }

  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
            <KeyboardArrowLeft />
          )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
            <KeyboardArrowRight />
          )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

function createContent(rows, headers, order, orderBy) {
  const rowsCopy = [];
  var obj = {};
  for (var i = 0; i < rows.length; i++) {
    for (var j = 0; j < headers.length; j++) {
      if (headers[j] === "startDate" || headers[j] === "endDate") {
        var date = rows[i][headers[j]].slice(0, 10);
        obj[headers[j]] = date.slice(8, 10) + "/" + date.slice(5, 7) + "/" + date.slice(0, 4);
      } else
        obj[headers[j]] = rows[i][headers[j]];
    }
    rowsCopy.push(obj);
    obj = {};
  }
  return stableSort(rowsCopy, getSorting(order, orderBy));
}

const CustomPaginationActionsTable = props => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState({});
  const [rowsLength, setRowsLength] = useState(0);
  const [headers, setHeaders] = useState([]);
  const [mainField, setMainField] = useState("");
  const [visibleHeaders, setVisibleHeaders] = useState([]);
  const [sortedFields, setSortedFields] = useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const { phaseId, mode, projectMode } = props;

  React.useEffect(() => {
    setHeaders(props.headers);
    setRows(props.rows);
    setRowsLength(props.rows.length);
    setMainField(props.mainField);
    setVisibleHeaders(props.visibleHeaders);
    setSortedFields(props.sortedFields);
  }, [props.headers, props.mainField, props.rows, props.visibleHeaders]);

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === sortedFields[property] && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(sortedFields[property]);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleChangePage(event, newPage) {
    setPage(newPage);
  };

  const getRowProperties = row => {
    return (
      <React.Fragment>
        {headers.map((prop, index) =>
          prop !== "id" ? (
            prop === mainField ? (
              <TableCell size="small" key={index} component="th" scope="row">
                {row[prop]}
              </TableCell>
            ) : (
                <TableCell size="small" key={index}>
                  {row[prop]}
                </TableCell>
              )
          ) : null
        )}
      </React.Fragment>
    );
  };

  const ProjectsTableHead = props => {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = property => event => {
      onRequestSort(event, property);
    };
    const paginationTop = action => e => {
      var selector = "[aria-label^='" + action + "']";
      if (document.querySelector(selector))
        document.querySelector(selector).click();
      e.preventDefault();
    };

    return (
      <TableHead>
        <TableRow>
          {visibleHeaders.map((header, index) => (
            <StyledTableCell2
              key={index}
              size="small"
              align="left"
              sortDirection={orderBy === sortedFields[index] ? order : false}
            >
              <TableSortLabel
                active={orderBy === sortedFields[index]}
                direction={order}
                onClick={createSortHandler(index)}
              >
                {header.toUpperCase()}
                {orderBy === sortedFields[index] ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </StyledTableCell2>
          ))}
          <StyledTableCell2>
            <div className={classes.nextPageDiv}>
              <Tooltip title={t("GENERAL.previousPage")} placement="top">
                <Button
                  name="previous-page"
                  onClick={paginationTop("previous page")}
                >
                  <KeyboardArrowLeft />
                </Button>
              </Tooltip>
              <Tooltip title={t("GENERAL.nextPage")} placement="top">
                <Button name="next-page" onClick={paginationTop("next page")}>
                  <KeyboardArrowRight />
                </Button>
              </Tooltip>
            </div>
          </StyledTableCell2>
        </TableRow>
      </TableHead>
    );
  };

  ProjectsTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired
  };

  return (
    <div className={classes.tableWrapper}>
      <Table className={classes.table} size="small">
        <ProjectsTableHead
          classes={classes}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {createContent(rows, headers, order, orderBy)
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(row => (
              <StyledTableRow key={row.id}>
                {getRowProperties(row)}
                <TableCell size="small" align="right">
                  <TableActions activityId={row.id} phaseId={phaseId} name={row.name} mode={mode} projectMode={projectMode} allAllowances={props.allAllowances} />
                </TableCell>
              </StyledTableRow>
            ))}
        </TableBody>
        <TableFooter>
            <TableRow style={{ display: "none" }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={3}
                count={rowsLength}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
      </Table>
    </div>
  );
};

CustomPaginationActionsTable.propTypes = {
  rows: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
  visibleHeaders: PropTypes.array.isRequired,
  mainField: PropTypes.string.isRequired
};

export default CustomPaginationActionsTable;