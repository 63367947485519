export const sortByDate = (array, labelAttribute, isDec) => {
    return isDec
        ? array.sort(
            (a, b) =>
                new Date(a[labelAttribute]) - new Date(b[labelAttribute])
        )
        : array.sort(
            (a, b) =>
                new Date(b[labelAttribute]) - new Date(a[labelAttribute])
        );
};

/**
 * Sorts two dates by the day and month component
 * @param {Date} a The first date
 * @param {Date} b The second date
 * @returns 1 if b is after a, -1 otherwise
 */
export const sortByDayAndMonth = (a, b) => {
    if (b.getMonth() > a.getMonth() ||
        (b.getMonth() === a.getMonth() && b.getDate() > a.getDate())) {
        return 1;
    } else {
        return -1;
    }
}
