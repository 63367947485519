import React from "react";
import { Document, Page, Text, View, Font } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import styles from "./VacationsBalancePDFReport.styled";
import Header from "../../../../../components/Pdf/Header/Header";
import Footer from "../../../../../components/Pdf/Footer/Footer";
import DateUtils from "utils/dates/DateUtils";
import Cell from "../../../../../components/Pdf/Table/Cell";
import Row from "components/Pdf/Table/Row";
import Table from "components/Pdf/Table/Table";

const VacationsBalancePDFReport = ({ data = null }) => {
    const [t] = useTranslation()

    Font.register({
        family: 'Roboto', fonts: [
            { src: window.location.origin + "/fonts/Roboto-Light.ttf", fontWeight: 300, fontStyle: "normal" },
            { src: window.location.origin + "/fonts/Roboto-Regular.ttf", fontWeight: 400, fontStyle: "normal" },
            { src: window.location.origin + "/fonts/Roboto-Bold.ttf", fontWeight: 700, fontStyle: "normal" },
        ]
    });

    function getTextColorLimitDate(vacation) {
        const enjoymentDateLimit = DateUtils.parse(vacation.enjoymentDateLimit);
        const now = new Date();
        if (now > enjoymentDateLimit && vacation.rhVacationPeriod.length === 0) return "#CF1520";
        else if (now <= enjoymentDateLimit && vacation.rhVacationPeriod.length === 0) return "#2C3E51"
        else {
            return "#32AB1A";
        }
    }

    function getTextLimitDate(vacation) {
        const enjoymentDateLimit = DateUtils.parse(vacation.enjoymentDateLimit);
        const now = new Date();
        if (now > enjoymentDateLimit && vacation.rhVacationPeriod.length === 0) return t("HR.immediate")
        else if (now <= enjoymentDateLimit && vacation.rhVacationPeriod.length === 0) return DateUtils.format(enjoymentDateLimit, 'DD/MM/YYYY');
        else {
            return t("HR.programmed")
        }
    }

    function getTextSoldVacations(vacationsPeriods) {
        if (vacationsPeriods.length === 0) return "-"
        else {
            let hasUtilizationWithSoldVacationDays = vacationsPeriods.find((vacationPeriod) => vacationPeriod.class === "newrhmodule.vacation.UtilizationWithSoldVacationDays")
            if (!!hasUtilizationWithSoldVacationDays) return t("GENERAL.yesCapital")
            return t("GENERAL.noCapital")
        }
    }

    function getVacationPeriodAsString(vacationsPeriods) {
        let periodsOfInterest = vacationsPeriods.filter(vacationPeriod => vacationPeriod.class === "newrhmodule.vacation.Utilization" || vacationPeriod.class === "newrhmodule.vacation.UtilizationWithSoldVacationDays")
        if (periodsOfInterest.length === 0) return "-"
        else if (periodsOfInterest.length === 1) {
            return ''.concat(`${DateUtils.formatString(periodsOfInterest[0].startDate, 'DD/MM/YYYY')}-${DateUtils.formatString(periodsOfInterest[0].endDate, 'DD/MM/YYYY')}`)
        }
        else {
            let periodsString = ''
            periodsOfInterest.forEach((periodOfInterest) => {
                periodsString.concat(`${DateUtils.formatString(periodOfInterest.startDate, 'DD/MM/YYYY')}-${DateUtils.formatString(periodOfInterest.endDate, 'DD/MM/YYYY')}`)
            })
            return periodsString
        }
    }

    return (
        <Document title="Relatório de Férias" author="LSBD">
            <Page wrap style={styles.page} orientation="landscape">
                <Header mainTitle={t("HR.PendingVacations")}
                    subTitle={t("HR.VacationsReport")} />

                <View style={styles.main}>
                    <View style={{ marginBottom: 4 }}>
                        <Text style={styles.mainTitle}>
                            {t("GENERAL.CollaboratorsList")}
                        </Text>
                    </View>

                    <Table columns={[
                        t("HR.collaborator"),
                        t("GENERAL.ruleset"),
                        t("HR.acquisitionPeriod"),
                        t("HR.vacationsDays"),
                        t("HR.limitDate"),
                        t("HR.vacationPeriod"),
                        t("HR.bonus")
                    ]} fixed={true}>
                        {data && data.map((item, index) => {
                            return (
                                <Row key={index} style={{ backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#F8F9FB" }}>
                                    <Cell style={{ width: `${100 / 7}%`, textAlign: 'left' }} text={item.contract.user.shortname} />
                                    <Cell style={{ width: `${100 / 7}%`, textAlign: 'center' }} text={item.contract.ruleset.name} />
                                    <View style={{
                                        width: `${100 - ((100 / 7) * 2)}%`,
                                        display: "flex",
                                        flexDirection: "column",
                                        flexWrap: "wrap"
                                    }}>
                                        {item.vacations && item.vacations.map((vacation, index) => (
                                            <View key={index} style={{ display: 'flex', flexDirection: 'row' }}>
                                                <Cell style={{ width: `${100 / 5}%`, textAlign: 'center' }} text={`${DateUtils.formatString(vacation.acquisitivePeriodStart, 'DD/MM/YYYY')} - ${DateUtils.formatString(vacation.acquisitivePeriodEnd, 'DD/MM/YYYY')}`} />
                                                <Cell style={{ width: `${100 / 5}%`, textAlign: 'center' }} text={`${vacation.daysToEnjoy} ${t("GENERAL.days")}`} />
                                                <Cell style={{ width: `${100 / 5}%`, textAlign: 'center', color: `${getTextColorLimitDate(vacation)}` }} text={getTextLimitDate(vacation)} />
                                                <Cell style={{ width: `${100 / 5}%`, textAlign: 'center' }} text={getVacationPeriodAsString(vacation.rhVacationPeriod)} />
                                                <Cell style={{ width: `${100 / 5}%`, textAlign: 'center' }} text={getTextSoldVacations(vacation.rhVacationPeriod)} />
                                            </View>
                                        ))}
                                    </View>
                                </Row>
                            )
                        })}
                    </Table>
                </View>
                <Footer />
            </Page>
        </Document>
    )
}

export default VacationsBalancePDFReport
