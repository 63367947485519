import React from 'react';
import { useStyles } from 'components/layout/CommonStyles';
import { inputStyles } from './Styles';
import {
    KeyboardDatePicker,
    KeyboardTimePicker,
    MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptBRLocale from 'date-fns/locale/pt-BR';
import { useTranslation } from 'react-i18next';

const InputDate = ({
    context,
    value,
    valueName,
    label,
    handleChange,
    required,
    helperText,
    error,
    setRequired,
    fieldType,
    type,
    margin,
    monthAndYear,
    ...props
}) => {
    const classes = useStyles();
    const styles = inputStyles();
    const [t] = useTranslation();

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
            {type && type === 'time' ? (
                <KeyboardTimePicker
                    disabled={context === 'view'}
                    error={error}
                    helperText={error && t(helperText)}
                    className={classes.identificationDatePicker}
                    ampm={false}
                    variant="inline"
                    margin={margin ? 'normal' : margin}
                    invalidDateMessage=""
                    label={t(label) + (required ? '*' : '')}
                    value={value}
                    KeyboardButtonProps={{
                        'aria-label': 'change date'
                    }}
                    onChange={ev => handleChange(valueName, ev)}
                    {...props}
                />
            ) : monthAndYear ? (
                <KeyboardDatePicker
                    disableToolbar
                    disabled={context === 'view'}
                    error={error}
                    helperText={error && t(helperText)}
                    className={classes.identificationDatePicker}
                    autoOk
                    views={['year', 'month']}
                    format="MM/yyyy"
                    margin={margin ? 'normal' : margin}
                    label={t(label) + (required ? '*' : '')}
                    value={value}
                    invalidDateMessage=""
                    maxDateMessage={t('GENERAL.maxDateErrorMessage')}
                    minDateMessage={t('GENERAL.minDateErrorMessage')}
                    onChange={ev => handleChange(valueName, ev)}
                    cancelLabel={'Cancelar'}
                    inputProps={{ style: { textTransform: 'capitalize' } }}
                    {...props}
                />
            ) : (
                <KeyboardDatePicker
                    disabled={context === 'view'}
                    error={error}
                    helperText={error && t(helperText)}
                    className={classes.identificationDatePicker}
                    disableToolbar
                    autoOk={true}
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin={margin ? 'normal' : margin}
                    // invalidDateMessage=""
                    label={t(label) + (required ? '*' : '')}
                    KeyboardButtonProps={{
                        'aria-label': 'change date'
                    }}
                    value={value}
                    onChange={ev => {
                        handleChange(valueName, ev);
                        setRequired(false);
                    }}
                    {...props}
                />
            )}
        </MuiPickersUtilsProvider>
    );
};

export default InputDate;
