import * as React from 'react';
import InfoIcon from '@material-ui/icons/Info';
import Typography from 'components/typography/Typography';
import { Box } from '@material-ui/core';
import { infoStyles } from './Styles';
import { useTranslation, Trans } from 'react-i18next';

const DefaultInfo = ({
    textTranslation,
    iconColor,
    valuesTranslation,
    fontSize,
    ...props
}) => {
    const styles = infoStyles();
    const [t] = useTranslation();

    return (
        <Box display={'flex'} alignItems={'center'} minHeight="100%">
            <InfoIcon
                color="primary"
                style={{ color: iconColor, marginRight: '8px' }}
            />

            <Typography
                variant={'RobotoRegular'}
                fontSize={fontSize ? fontSize : 16}
                textColor={'primary'}
            >
                <Trans
                    i18nKey={textTranslation}
                    components={[<b />]}
                    values={valuesTranslation}
                />
            </Typography>
        </Box>
    );
};

export default DefaultInfo;
