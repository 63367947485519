import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { useStyles } from "components/layout/CommonStyles";
import { useTranslation } from "react-i18next";
import CloseIcon from '@material-ui/icons/Close';
import Buttons from "components/buttons/Pressed";
import Grid from "@material-ui/core/Grid";
import Input from "components/inputs/Input";

const DialogPosition = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const { open, setOpen, context, jobsArray, jobId, justification, oldJob, newJob, userId, setParentValues,setHasChange } = props;

    const [values, setValues] = useState({
        jobId: 0,
        justification: "",
    });

    const [requiredJob, setRequiredJob] = useState(false);

    useEffect(() => {
        setValues({
            ...values,
            jobId: 0,
            justification: justification ? justification : "",
        });
    }, [jobId, justification]);

    const clearDialog = () => {
        setRequiredJob(false);
        setValues({
            ...values,
            jobId: jobId ? jobId : 0,
            justification: justification ? justification : "",
        });
    }

    const handleClose = () => {
        clearDialog();
        setOpen(false);
    }

    const handleChange = (name, value) => {
        setHasChange(true);
        setValues({ ...values, [name]: value })
    }

    const verifySubmit = () => {
        let res = true;
        if (!values.jobId) {
            res = false;
            setRequiredJob(true);
        }

        return res;
    }

    const handleSubmit = () => {
        const canSubmit = verifySubmit();
        if (canSubmit) {
            const job = jobsArray.find(x => x.value === values.jobId);
            setParentValues(old => ({
                ...old,
                jobId: values.jobId,
                job: job ? job.label : old.job,
                jobJustification: values.justification
            }));
            setOpen(false);
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle className={classes.Title}>
                <b>{context === "view" ? t("GENERAL.view") + " " + t("HR.position") : t("HR.editJob")}</b>
                <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container item spacing={2} md={12} lg={12}>
                    <Grid item xs={12} sm={12}>
                        <Input
                            disabled
                            label={"HR.oldPosition"}
                            value={oldJob}
                            valueName={"oldJob"}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        {context === "view" ?
                            <Input
                                disabled
                                label={"HR.newPosition"}
                                value={newJob}
                                valueName={"newJob"}
                            />
                            :
                            <Input
                                context={context}
                                required
                                label={"HR.newPosition"}
                                value={values.jobId}
                                valueName={"jobId"}
                                handleChange={handleChange}
                                error={requiredJob}
                                helperText={requiredJob && t("GENERAL.requiredField")}
                                setRequired={setRequiredJob}
                                isSelect
                                arraySelected={jobsArray}
                            />
                        }
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Input
                            context={context}
                            label={"GENERAL.justification"}
                            value={values.justification}
                            valueName={"justification"}
                            rows={5}
                            maxLength={200}
                            handleChange={handleChange}
                        />
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Buttons
                    onClick={handleClose}
                    tipo="BN-noback"
                    conteudo={context === "view" ? t("GENERAL.close").toUpperCase() : t("GENERAL.cancel").toUpperCase()}
                />
                {context === "view" ? null :
                    <Buttons
                        onClick={handleSubmit}
                        tipo="BN-blue"
                        conteudo={t("GENERAL.update").toUpperCase()}
                    />
                }
            </DialogActions>
        </Dialog>
    )
}

export default DialogPosition;
