/* eslint-disable */
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SearchIcon from '@material-ui/icons/Search';
import Dissatisfied from '@material-ui/icons/SentimentVeryDissatisfied';
import Footer from 'components/layout/Footer';
import Spinner from 'components/layout/spinners/Index';
import AllowancesContext from 'context/allowance/allowanceContext';
import ExternalUsersContext from 'context/externalUsers/externalUsersContext';
import InventoryContext from 'context/inventory/inventoryContext';
import SearchContext from 'context/search/searchContext';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { animated, useTransition } from 'react-spring';
import { useStyles } from '../layout/CommonStyles';
import MySnackbarContentWrapper from '../layout/Message';
import ExternalUsersTable from './ExternalUsersTable';
import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';
import { Screen, Allow } from 'global/constants';

const ExternalUsers = () => {
    const classes = useStyles();
    const [t] = useTranslation();
    const inventoryContext = useContext(InventoryContext);
    const allowanceContext = useContext(AllowancesContext);
    const externalUsersContext = useContext(ExternalUsersContext);
    const searchContext = useContext(SearchContext);
    const [placesArray, setPlacesArray] = useState([]);
    let dataFilter = [];
    //const { filter } = searchContext;
    const [snackBar, setSnackBar] = useState({
        open: false,
        kind: '',
        content: ''
    });
    const [filter, setFilter] = useState('');
    const [filter2, setFilter2] = useState('');
    const [filter3, setFilter3] = useState('');

    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const { places, getPlaces } = inventoryContext;

    const {
        loading,
        response,
        hasChanged,
        setHasChanged,
        responseType,
        successiveActions,
        getExternalUsers,
        externalUsers
    } = externalUsersContext;

    const {
        allowanceResponse,
        allowanceResponseType,
        messageHasChanged,
        resetAllowanceMessage
    } = allowanceContext;

    const clearFilterLocationType = () => {
        dataFilter = externalUsers;
        dataFilter = dataFilter.filter(item =>
            item.name.toLowerCase().startsWith(filter.toLowerCase())
        );
    };

    dataFilter = externalUsers;
    dataFilter = dataFilter.filter(item => item.hasdeleted == false);

    if (filter) {
        dataFilter = dataFilter.filter(item =>
            item.name.toLowerCase().startsWith(filter.toLowerCase())
        );
    }

    if (filter3) {
        dataFilter = dataFilter.filter(item =>
            filter3.toLowerCase() !== 'todos os locais'.toLowerCase()
                ? item.userExternalPlaces.length > 0 &&
                  item.userExternalPlaces[0].place.name
                      .toLowerCase()
                      .startsWith(filter3.toLowerCase())
                : item
        );
    }
    const hasItens = externalUsers.length > 0;
    const [hasNoResult, setHasNoResult] = useState(
        hasItens && dataFilter.length === 0
    );
    const [itensTable, setItensTable] =
        useState();
        // renderTable(dataFilter)
    const transitions = useTransition(loading, null, {
        from: { opacity: 0 },
        enter: {
            opacity: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: 'calc(100vh - 70px)'
        },
        leave: { opacity: 0 }
    });

    useEffect(() => {
        getPlaces();
    }, []);

    useEffect(() => {
        const myPlaces = [...places];
        myPlaces.sort(function (a, b) {
            if (a.name === null) a.name = '';
            if (b.name === null) b.name = '';

            if (
                a.name
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .trim() >
                b.name
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .trim()
            ) {
                return 1;
            }
            if (
                a.name
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .trim() <
                b.name
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .trim()
            ) {
                return -1;
            }
            // a must be equal to b
            return 0;
        });

        var aux = {};
        var allPlaces = {};
        var _places = [];
        allPlaces.value = 'all';
        allPlaces.label = 'Todos os locais';

        _places.push(allPlaces);
        for (var i = 0; i < myPlaces.length; i++) {
            var item = myPlaces[i];
            aux.value = item.id;
            aux.label = item.name;
            _places.push(aux);
            aux = {};
        }
        setPlacesArray(_places);
    }, [places]);

    useEffect(() => {
        getExternalUsers();

        if (allowanceResponseType === 'ERROR' && messageHasChanged) {
            handleSnack({ kind: 'error', content: allowanceResponse });
        }
        if (responseType === 'SUCCESS' && hasChanged > successiveActions) {
            handleSnack({ kind: 'success', content: response });
        }
        if (responseType === 'ERROR' && hasChanged > successiveActions) {
            handleSnack({ kind: 'error', content: response });
        }

        return () => {};
    }, [
        responseType,
        response,
        successiveActions,
        allowanceResponseType,
        allowanceResponse
    ]);

    const handleSnack = () => {
        setSnackBar({ ...snackBar, open: true });
        setHasChanged();
    };
    const handleSnackClose = () => {
        setSnackBar({ ...snackBar, open: false });
        resetAllowanceMessage();
    };

    const handleSearch = event => {
        setFilter(event.target.value);
        clearFilterLocationType();

        dataFilter = dataFilter.filter(item =>
            item.name.toLowerCase().includes(event.target.value.toLowerCase())
        );

        if (dataFilter.length === 0) setHasNoResult(true);
        else setHasNoResult(false);
        //setItensTable(renderTable(dataFilter));
    };

    const handleSearch3 = event => {
        setFilter3(event.target.value);

        if (filter) {
            dataFilter = dataFilter.filter(item =>
                item.name.toLowerCase().startsWith(filter.toLowerCase())
            );
        }

        if (
            event.target.value.toLowerCase() !== 'todos os locais'.toLowerCase()
        ) {
            if (dataFilter.length === 0) {
                setHasNoResult(true);
            } else {
                setHasNoResult(false);
            }
        }
        //setItensTable(renderTable(dataFilter));
    };

    useEffect(() => {
        if (filter) {
            dataFilter = dataFilter.filter(item =>
                item.name.toLowerCase().startsWith(filter.toLowerCase())
            );
        }

        if (filter3.toLowerCase() !== 'todos os locais'.toLowerCase()) {
            if (dataFilter.length === 0) {
                setHasNoResult(true);
            } else {
                setHasNoResult(false);
            }
        }
        //setPlacesTable(renderTable(dataFilter));
    }, [dataFilter.length]);

    return transitions.map(({ item, key, props }) =>
        item ? (
            <animated.div key={key} style={props}>
                <Spinner />;
            </animated.div>
        ) : (
            <animated.div key={key} style={props}>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={snackBar.open}
                    onClose={handleSnackClose}
                    TransitionComponent={Slide}
                    transitionDuration={{ enter: 500, exit: 500 }}
                    ContentProps={{
                        'aria-describedby': 'message-id'
                    }}
                    autoHideDuration={2000}
                >
                    <MySnackbarContentWrapper
                        onClose={handleSnackClose}
                        variant={
                            responseType !== ''
                                ? responseType.toLowerCase()
                                : allowanceResponseType.toLowerCase()
                        }
                        message={
                            <span id="message-id">
                                {response !== '' ? response : allowanceResponse}
                            </span>
                        }
                    />
                </Snackbar>
                <div className="container">
                    <Breadcrumbs
                        className={classes.breadCrumbs}
                        aria-label="breadcrumb"
                        separator={<NavigateNextIcon fontSize="small" />}
                    >
                        <Typography>{t('INVENTORY.inventory')}</Typography>
                        <Typography>
                            {t('EXTERNALUSERS.externalUsers')}
                        </Typography>
                    </Breadcrumbs>

                    <div className="container-header">
                        <div className="title">
                            <h1 className={classes.title}>
                                {t('EXTERNALUSERS.externalUsers')}
                            </h1>
                        </div>
                        {GetGeneralPermissionForAction(
                            Screen.EXTERNAL_COLLABORATORS,
                            Allow.CREATE
                        ) && (
                            <Link to={'/ExternalUsers/new'}>
                                <Button
                                    className={classes.blueButton}
                                    variant="contained"
                                    color="primary"
                                >
                                    {t('INVENTORY.add')}
                                </Button>
                            </Link>
                        )}
                    </div>
                    <Paper>
                        <div className="cabecalho">
                            {t('EXTERNALUSERS.contributors').toUpperCase()}
                        </div>
                        <div className={classes.divAddParticipantLeft}>
                            <Toolbar className={classes.searchToolbarInventory}>
                                <div
                                    style={{ display: !hasItens ? 'none' : '' }}
                                    className={classes.searchInventory}
                                >
                                    <div className={classes.searchIcon}>
                                        <SearchIcon />
                                    </div>
                                    <InputBase
                                        placeholder={t('INVENTORY.name')}
                                        value={filter}
                                        onChange={handleSearch}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputInput
                                        }}
                                        inputProps={{ 'aria-label': 'search' }}
                                    />
                                </div>
                            </Toolbar>
                            <Toolbar className={classes.searchToolbarInventory}>
                                <div
                                    style={{ display: !hasItens ? 'none' : '' }}
                                    className={classes.searchInventory}
                                >
                                    <TextField
                                        select
                                        id="client"
                                        label={t('INVENTORY.place')}
                                        name="Place"
                                        style={{
                                            minWidth: '250px',
                                            marginTop: 0,
                                            marginBottom: 0,
                                            marginLeft: 5
                                        }}
                                        value={filter3}
                                        className={classes.textField}
                                        onChange={handleSearch3}
                                        margin="normal"
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputInput
                                        }}
                                        inputProps={{ 'aria-label': 'search' }}
                                    >
                                        {placesArray.map(places => (
                                            <MenuItem
                                                key={places.value}
                                                value={places.label}
                                            >
                                                {places.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            </Toolbar>
                        </div>
                        <ExternalUsersTable
                            rows={dataFilter}
                            headers={[
                                'id',
                                'name',
                                'phoneNumber',
                                'email',
                                'userExternalPlaces',
                                'imagePath'
                            ]}
                            visibleHeaders={[
                                '#',
                                'nome',
                                'telefone',
                                'email',
                                'local',
                                ''
                            ]}
                            sortedFields={[
                                '#',
                                'name',
                                'phoneNumber',
                                'email',
                                'userExternalPlaces'
                            ]}
                            mainField="name"
                        />

                        {hasItens ? (
                            itensTable
                        ) : (
                            <div className={classes.noParticipantsMessage}>
                                <h2>{t('INVENTORY.noItensMessage')}</h2>
                                <h4>{t('INVENTORY.noItensSubMessage')}</h4>
                            </div>
                        )}
                        {hasNoResult && filter ? (
                            <div className={classes.noParticipantsMessage}>
                                <div>
                                    <Dissatisfied
                                        className={classes.notFoundIconStyle}
                                    />
                                </div>
                                <h2>{t('GENERAL.noResultsFound')}</h2>
                                <h5>{`Sua busca por "${filter}" não retornou resultados.`}</h5>
                            </div>
                        ) : hasNoResult && filter2 ? (
                            <div className={classes.noParticipantsMessage}>
                                <div>
                                    <Dissatisfied
                                        className={classes.notFoundIconStyle}
                                    />
                                </div>
                                <h2>{t('GENERAL.noResultsFound')}</h2>
                                <h5>{`Sua busca por "${filter2}" não retornou resultados.`}</h5>
                            </div>
                        ) : (
                            hasNoResult &&
                            filter3 &&
                            filter3.toLowerCase() !== 'todos os locais' && (
                                <div className={classes.noParticipantsMessage}>
                                    <div>
                                        <Dissatisfied
                                            className={
                                                classes.notFoundIconStyle
                                            }
                                        />
                                    </div>
                                    <h2>{t('GENERAL.noResultsFound')}</h2>
                                    <h5>{`Sua busca por "${filter3}" não retornou resultados.`}</h5>
                                </div>
                            )
                        )}
                    </Paper>
                </div>
                <Footer />
            </animated.div>
        )
    );
};

export default ExternalUsers;
