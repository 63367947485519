import React from "react";
import "../../../../App.css";
import SideBar from "components/layout/sidebar/Sidebar";
import DashboardNavBar from "components/layout/DashboardNavBar";
import AllowanceReasonForm from "./AllowanceReasonForm";

const CallViewAllowanceReason = ({ match }) => {

    return (
        <div>
            <DashboardNavBar />
            <SideBar />
            <AllowanceReasonForm reasonId={match.params.id} context={"view"} />
        </div>
    );
}

export default CallViewAllowanceReason;