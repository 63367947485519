import { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

class Logout extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    componentWillMount() {
        localStorage.removeItem('auth-token');

        const { cookies } = this.props;
        cookies.remove('auth-token');
        window.location.href = process.env.REACT_APP_SITE + '?logout=true';
    }

    render() {
        return null;
    }
}

export default withCookies(Logout);
