import React, { useState, useContext, useEffect } from 'react';
import '../../../App.css';
import InventoryContext from 'context/inventory/inventoryContext';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContentWrapper from 'components/layout/Message';
import SideBar from 'components/layout/sidebar/Sidebar';
import DashboardNavBar from 'components/layout/DashboardNavBar';
import BookCategory from "./BookCategory";

const CallBookCategory = ({ match }) => {
  const inventoryContext = useContext(InventoryContext);

  const {
      response,
      hasChanged,
      setHasChanged,
      responseType,
      successiveActions
  } = inventoryContext;

  const [snackBar, setSnackBar] = useState({
      open: false,
      kind: '',
      content: ''
  });

  const handleSnack = () => {
      setSnackBar({ ...snackBar, open: true });
      setHasChanged();
  };

  const handleSnackClose = (event, reason) => {
      if (reason === 'clickaway') {
          return;
      }
      setSnackBar({ ...snackBar, open: false });
  };

  useEffect(() => {
      if (responseType === 'SUCCESS' && hasChanged > successiveActions) {
          handleSnack({ kind: 'success', content: response });
      }
      if (responseType === 'ERROR' && hasChanged > successiveActions) {
          handleSnack({ kind: 'error', content: response });
      }

      return () => {};
  }, [responseType, response, successiveActions]);

  return (
    <div>
      <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={snackBar.open}
          onClose={handleSnackClose}
          TransitionComponent={Slide}
          transitionDuration={{ enter: 500, exit: 500 }}
          ContentProps={{
              'aria-describedby': 'message-id'
          }}
          autoHideDuration={2000}
      >
          <MySnackbarContentWrapper
              onClose={handleSnackClose}
              variant={
                  responseType !== '' ? responseType.toLowerCase() : null
              }
              message={
                  <span id="message-id">
                      {response !== '' ? response : null}
                  </span>
              }
          />
      </Snackbar>
      <DashboardNavBar />
      <SideBar />
      <BookCategory />
    </div>
  );
}
export default CallBookCategory;