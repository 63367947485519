/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Transition from 'components/layout/transition/Transition';
import FilterContainer from 'components/containers/FilterContainer';
import InputFilter from 'components/inputs/InputFilter';
import InputFilterDate from 'components/inputs/InputFilterDate';
import InputFilterDatePeriod from 'components/inputs/inputFilterDatePeriod';
import ExpensesTable from './ExpensesTable';
import Dissatisfied from '@material-ui/icons/SentimentVeryDissatisfied';
import { useStyles } from 'components/layout/CommonStyles';
import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';

import { Screen, Allow } from 'global/constants';
import { useQueryLSBDExpenses } from 'hooks/DataFetching/Quotas/Dashboard/useQueryLSBDExpenses';

import { isSameDate } from 'utils/dates/IsSameDate';
import { FormatDateToFront } from 'utils/dates/FormatDateToFront';
import { dateToBR } from 'utils/dates/DateToBRr';

import { screenStyles } from '../../Styles';

const ExpensesForm = () => {
    const classes = useStyles();
    const styles = screenStyles();
    const [t] = useTranslation();

    const [findText, setFindText] = useState('');
    const [filterName, setFilterName] = useState('');
    const [filterPeriod, setFilterPeriod] = useState({
        start: null,
        end: null
    });

    const [dataFilter, setDataFilter] = useState([]);

    const [canClick, setCanClick] = useState(false);

    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const { isLoading, isFetching, error, data } = useQueryLSBDExpenses();

    const hasItens = data && data.length > 0;
    const [hasNoResult, setHasNoResult] = useState(
        hasItens && dataFilter && dataFilter.length === 0
    );

    useEffect(() => {
        if (data) {
            setDataFilter(data.filter(x => !x.hasDeleted));
            setHasNoResult(false);
        }
    }, [data]);

    useEffect(() => {
        if (data && data.length > 0) {
            const { newDataFilter, textNotFound } = applyFilter(
                data.filter(x => !x.hasDeleted)
            );

            if (newDataFilter.length === 0) {
                setDataFilter([]);
                setHasNoResult(true);
                setFindText(textNotFound);
            } else {
                setDataFilter(newDataFilter);
                setHasNoResult(false);
                setFindText('');
            }

            setDataFilter(newDataFilter);
        }
    }, [filterName, filterPeriod]);

    const applyFilter = data => {
        let newDataFilter = [];
        let textNotFound = '';

        newDataFilter = data;

        if (filterName) {
            newDataFilter = newDataFilter.filter(
                item =>
                    (item.user &&
                        item.user.name
                            .toLowerCase()
                            .includes(filterName.toLowerCase())) ||
                    (item.operator &&
                        item.operator.name
                            .toLowerCase()
                            .includes(filterName.toLowerCase()))
            );

            if (!textNotFound && newDataFilter.length === 0)
                textNotFound = filterName;
        }

        if (filterPeriod && filterPeriod.start && filterPeriod.end) {
            let fromDate = new Date(
                filterPeriod.start.getFullYear() +
                    '/' +
                    (filterPeriod.start.getMonth() + 1) +
                    '/' +
                    filterPeriod.start.getDate()
            );
            let toDate = new Date(
                filterPeriod.end.getFullYear() +
                    '/' +
                    (filterPeriod.end.getMonth() + 1) +
                    '/' +
                    filterPeriod.end.getDate()
            );

            newDataFilter = newDataFilter.filter(
                item =>
                    item.date &&
                    FormatDateToFront(item.date).getTime() >=
                        fromDate.getTime() &&
                    FormatDateToFront(item.date).getTime() <= toDate.getTime()
            );
            if (!textNotFound && newDataFilter.length === 0)
                textNotFound = dateToBR(fromDate) + ' - ' + dateToBR(toDate);
        }

        return { newDataFilter, textNotFound };
    };

    useEffect(() => {
        if (filterName || filterPeriod) {
            setCanClick(true);
        } else {
            setCanClick(false);
        }
    }, [filterName, filterPeriod]);

    return (
        <React.Fragment>
            <Transition loading={isLoading && isFetching} newDesign={true}>
                <FilterContainer>
                    <InputFilter
                        filterValue={filterName}
                        setFilter={setFilterName}
                        label={'GENERAL.collaborator'}
                    />
                    <InputFilterDatePeriod
                        filterValue={filterPeriod}
                        setFilter={setFilterPeriod}
                        label={'GENERAL.period'}
                    />
                </FilterContainer>
                <React.Fragment>
                    <ExpensesTable
                        rows={dataFilter}
                        toFirstPage={canClick}
                        headers={[
                            'id',
                            'date',
                            'user',
                            'type',
                            'value',
                            'operator',
                            'source',
                            'receiptFilename',
                            'receiptPath',
                            'description',
                            'createdAt'
                        ]}
                        visibleHeaders={[
                            'Data',
                            'Colaborador',
                            'Tipo',
                            'Valor',
                            'Recibos',
                            ''
                        ]}
                        sortedFields={['date', 'user', 'type', 'value']}
                    />

                    {!hasItens && !error && (
                        <div className={classes.noParticipantsMessage}>
                            <h2>{t('QUOTAS.noUserTransactionsMessage')}</h2>
                            <h4>{t('QUOTAS.noUserTransactionsSubMessage')}</h4>
                        </div>
                    )}
                    {hasNoResult && !error && (
                        <div className={classes.noParticipantsMessage}>
                            <div>
                                <Dissatisfied
                                    className={classes.notFoundIconStyle}
                                />
                            </div>
                            <h2>{t('GENERAL.noResultsFound')}</h2>
                            <h5>{`Sua busca por "${findText}" não retornou resultados.`}</h5>
                        </div>
                    )}
                    {error && (
                        <div className={classes.noParticipantsMessage}>
                            <div>
                                <Dissatisfied
                                    className={classes.notFoundIconStyle}
                                />
                            </div>
                            <h2>{'Houve um erro de conexão com o Servidor'}</h2>
                        </div>
                    )}
                </React.Fragment>
            </Transition>
        </React.Fragment>
    );
};

export default ExpensesForm;
