import { StyleSheet } from "@react-pdf/renderer";

export default StyleSheet.create({
    row: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "100%",
        backgroundColor: "#FFFFFF"
    },
})
