import React from "react";

import { containersStyles } from "./Styles";

import { DialogContentText } from "@material-ui/core";

const DialogContainer = ({children}) => {
    const styles = containersStyles();
    return(
        <div className={styles.dialogContainer}>
                {children}
        </div>
    )
}

export default DialogContainer;