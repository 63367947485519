/* eslint-disable */
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Footer from "components/layout/Footer";
import AllowancesContext from "context/allowance/allowanceContext";
import SearchContext from "context/search/searchContext";
import UsersContext from "context/users/usersContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { animated, useTransition } from "react-spring";
import Spinner from "../../components/layout/spinners/Index";
import MySnackbarContentWrapper from "../layout/Message";
//import { useStyles } from "../profiles/ProfileStyles";
import TableActions from "./UsersHandleTableActions";
import { useStyles } from "../layout/CommonStyles";
import Dissatisfied from "@material-ui/icons/SentimentVeryDissatisfied";
import Paper from "@material-ui/core/Paper";

const UsersTable = () => {
  const [t] = useTranslation();
  const classes = useStyles();
  const usersContext = useContext(UsersContext);
  const allowanceContext = useContext(AllowancesContext);
  const searchContext = useContext(SearchContext);
  const {
    getUsers,
    users,
    loading,
    response,
    responseType,
    successiveActions,
    hasChanged,
    setHasChanged
  } = usersContext;
  const {
    allowanceResponse,
    allowanceResponseType,
    messageHasChanged,
    resetAllowanceMessage,
    verifyPermission,
    permission
  } = allowanceContext;

  const { filter } = searchContext;
  const [snackBar, setSnackBar] = useState({
    open: false,
    kind: "",
    content: ""
  });
  let dataFilter = [];

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    verifyPermission("USER")
    if (allowanceResponseType === "ERROR" && messageHasChanged) {
      handleSnack({ kind: "error", content: allowanceResponse });
    }
    if (responseType === "SUCCESS" && hasChanged > successiveActions) {
      handleSnack({ kind: "success", content: response });
    }
    if (responseType === "ERROR" && hasChanged > successiveActions) {
      handleSnack({ kind: "error", content: response });
    }
    return () => { };
  }, [
    responseType,
    response,
    successiveActions,
    allowanceResponseType,
    allowanceResponse,
    permission.length,
    JSON.stringify(users)
  ]);

  dataFilter = users;
  if (filter) {
    dataFilter = dataFilter.filter(
      item =>
        item.cpf.toLowerCase().startsWith(filter.toLowerCase()) ||
        item.name.toLowerCase().startsWith(filter.toLowerCase()) ||
        item.profileName.toLowerCase().startsWith(filter.toLowerCase())
    );
  }

  const handleSnack = () => {
    setSnackBar({ ...snackBar, open: true });
    setHasChanged();
  };

  const handleSnackClose = () => {
    setSnackBar({ ...snackBar, open: false });
    resetAllowanceMessage();
  };

  const transitions = useTransition(loading, null, {
    from: { opacity: 0 },
    enter: {
      opacity: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "calc(100vh - 70px)"
    },
    leave: { opacity: 0 }
  });

  return transitions.map(({ item, key, props }) =>
    item ? (
      <animated.div key={key} style={props}>
        <Spinner />;
      </animated.div>
    ) : (
        <animated.div key={key} style={props}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={snackBar.open}
            onClose={handleSnackClose}
            TransitionComponent={Slide}
            transitionDuration={{ enter: 500, exit: 500 }}
            ContentProps={{
              "aria-describedby": "message-id"
            }}
            autoHideDuration={2000}
          >
            <MySnackbarContentWrapper
              onClose={handleSnackClose}
              variant={
                responseType !== ""
                  ? responseType.toLowerCase()
                  : allowanceResponseType.toLowerCase()
              }
              message={
                <span id="message-id">
                  {response !== "" ? response : allowanceResponse}
                </span>
              }
            />
          </Snackbar>

          <div className="container">
            <Breadcrumbs
              className={classes.breadCrumbs}
              aria-label="breadcrumb"
              separator={<NavigateNextIcon fontSize="small" />}
            >
              <Typography>{t("ROUTES.planning")}</Typography>
              <Typography>{t("ROUTES.config")}</Typography>
              <Typography>{t("USERS.users")}</Typography>
            </Breadcrumbs>
            <div className="container-header">
              <div className="title">
                <h1 className={classes.title}>Usuários</h1>
              </div>
            </div>
            <TableActions
              rows={dataFilter}
              headers={["id", "imagePath", "cpf", "name", "email", "profileName"]}
              sortedFields={["imagePath", "cpf", "name", "email", "profileName"]}
              visibleHeaders={["#", "CPF", "NOME", "E-MAIL", "PERFIL"]}
              mainField="imagePath"
              permission={permission}
            />
            <Paper>
              {(dataFilter.length === 0) &&
                <div className={classes.noParticipantsMessage}>
                  <div >
                    <Dissatisfied className={classes.notFoundIconStyle} />
                  </div>
                  <h2>{t("GENERAL.noResultsFound")}</h2>
                  <h5>{`Sua busca por "${filter}" não retornou resultados.`}</h5>
                </div>
              }
            </Paper>
          </div>
          <Footer />
        </animated.div>
      )
  );
};

export default UsersTable;
