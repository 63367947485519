import axios from 'axios';

function addZero(i) {
    if (i < 10) {
        i = '0' + i;
    }
    return i;
}

const convertDatebyCoin = date => {
    var year = date.getFullYear();
    var month = addZero(date.getMonth() + 1);
    var day = addZero(date.getDate());
    return `${month}-${day}-${year}`;
};

export async function GetQuoteApi(coin, date) {
    var dateFormated = convertDatebyCoin(new Date(date));
    const res = await axios
        .get(
            `https://olinda.bcb.gov.br/olinda/servico/PTAX/versao/v1/odata/CotacaoMoedaDia(moeda=@moeda,dataCotacao=@dataCotacao)?@moeda='${coin}'&@dataCotacao='${dateFormated}'&$top=1&$format=json`,
            {
                headers: {
                    'Content-type': 'application/json'
                }
            }
        )
        .then(res => {
            if (res.data.value.length) {
                var coinValueFormated = parseFloat(
                    res.data.value[0].cotacaoCompra
                ).toFixed(2);
                return coinValueFormated;
            } else {
                return 1;
            }
        });

    return res;
}

// export async function GetQuoteApi(coin, date) {
//     var dateFormated = convertDatebyCoin(new Date(date));
//     const res = await axios
//         .get(
//             `https://economia.awesomeapi.com.br/${coin}?start_date=${dateFormated}&end_date=${dateFormated}`,
//             {
//                 headers: {
//                     'Content-type': 'application/json'
//                 }
//             }
//         )
//         .then(res => {
//             if (res.data.length) {
//                 var coinValueFormated = parseFloat(res.data[0].bid).toFixed(2);
//                 return coinValueFormated;
//             } else {
//                 return 1;
//             }
//         });

//     return res;
// }
