import Paper from '@material-ui/core/Paper';
import HeaderContainer from 'components/containers/HeaderContainer';
import Breadcrumb from 'components/layout/breadcrumb/Breadcrumb';
import { useStyles } from 'components/layout/CommonStyles';
import Footer from 'components/layout/Footer';
import Transition from 'components/layout/transition/Transition';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { screenStyles } from 'pages/libraryModule/Requisitions/Styles';
import RequisitionsContext from 'context/libraryModule/requisitions/requisitionsContext';
import { AllAuthorsName } from 'utils/library/AllAuthorsName';
import { formatImagePath } from 'utils/library/formatImagePath';
import Typography from 'components/typography/Typography';
import {
    DialogUnit,
    DialogImage,
    DialogWarningTip
} from 'pages/libraryModule/dialogs/DialogUtils';
import { screenStyles as screenStylesDialog } from 'pages/libraryModule/dialogs/Styles';
import AwaitingListTable from 'pages/libraryModule/Requisitions/AwaitingListTable';
import { sortByDate } from 'utils/sort/sortByDate';
import Link from 'react-router-dom/Link';
import Buttons from 'components/buttons/Pressed';
import { Screen } from 'global/constants';
import {
    LibraryQueueStatusValues,
    LibraryReservationStatusValues
} from 'global/constants';

const AwaitingListManagement = props => {
    const { BookId } = props;
    const [t] = useTranslation();
    const styles = screenStyles();
    const dialogStyles = screenStylesDialog();
    const classes = useStyles();
    const [bookData, setBookData] = useState({});
    const [loading, setLoading] = useState(true);
    const [link, setLink] = useState('');

    const requisitionsContext = useContext(RequisitionsContext);
    const { getBookById, book, cancelQueue } = requisitionsContext;

    async function fetchData() {
        setLoading(true);
        await getBookById(BookId);
        setLoading(false);
    }

    useEffect(() => {
        fetchData();
        setLink(getLinkToGoBack);
    }, []);

    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const getLinkToGoBack = () => {
        let link = '/Library';

        if (
            auth.generalPermissions.find(
                p => p.screen === Screen.LIBRARY_REQUISITIONS_GENERAL
            ).allowRead
        ) {
            link = '/LibraryRequisitions/general?panel=AWAITING_LIST';
        }
        if (
            auth.generalPermissions.find(
                p => p.screen === Screen.LIBRARY_REQUISITIONS_ALL
            ).allowRead
        ) {
            link = '/LibraryRequisitions/all?panel=AWAITING_LIST';
        }

        return link;
    };

    useEffect(() => {
        if (book) {
            setBookData(book);
        }
    }, [book]);

    const submit = (id, justification, emailData) => {
        let formData = new FormData();

        formData.append(
            'status',
            LibraryReservationStatusValues.CANCELED_MANAGEMENT
        );
        formData.append('justification', justification);
        formData.append('canceledBy', auth.id);

        emailData.operator = auth.name;
        emailData.endpoint = 'BookQueue/canceledRequisition';

        cancelQueue(id, formData, fetchData, emailData);
    };

    return (
        <React.Fragment>
            <Transition loading={loading} newDesign={true}>
                <HeaderContainer>
                    <Breadcrumb
                        newDesign={true}
                        routes={[
                            { label: t('INVENTORY.library'), to: '/Library' },
                            { label: t('LIBRARY.requisitions'), to: link },
                            { label: t('LIBRARY.awaitingListManagement') }
                        ]}
                    />
                    <div className="container-headerNewDesign">
                        <h1 className={classes.titleNewDesign}>
                            {t('LIBRARY.awaitingListManagement')}
                        </h1>
                    </div>
                </HeaderContainer>

                {loading ? (
                    <></>
                ) : (
                    <div className="containerNewDesign">
                        <Paper>
                            <div
                                className={`cabecalho ${styles.bookCollectionHeader}`}
                            >
                                {t('LIBRARY.queueManagement').toUpperCase()}
                            </div>

                            <div className={styles.AwaitingListContainer}>
                                <DialogWarningTip
                                    text={t(`LIBRARY.dialogAwaitingListTip`)}
                                    margin={'0 0 32px'}
                                />

                                <div
                                    style={{ marginBottom: '24px' }}
                                    className={
                                        dialogStyles.dialogBookRequisitionContainer
                                    }
                                >
                                    <div className={dialogStyles.dialogContent}>
                                        <DialogUnit
                                            head={t(
                                                'INVENTORY.title'
                                            ).toLocaleUpperCase()}
                                            content={bookData.title}
                                        />
                                        <DialogUnit
                                            head={t(
                                                'INVENTORY.BookAuthor'
                                            ).toLocaleUpperCase()}
                                            content={AllAuthorsName(
                                                bookData.authors
                                            )}
                                        />
                                        <DialogUnit
                                            head={t(
                                                'INVENTORY.category'
                                            ).toLocaleUpperCase()}
                                            content={bookData.category.name}
                                        />
                                    </div>

                                    <DialogImage
                                        image={formatImagePath(
                                            bookData.imagePath,
                                            bookData.isImageUrl
                                        )}
                                        alt={bookData.title}
                                    />
                                </div>

                                <Typography
                                    variant={'RobotoBold'}
                                    fontSize={14}
                                    textColor={'secondary'}
                                >
                                    {t(
                                        'LIBRARY.collaboratorsWithInterestInBook'
                                    )}
                                </Typography>

                                <Paper
                                    style={{
                                        marginTop: '24px'
                                    }}
                                >
                                    <div
                                        className={`cabecalho ${styles.bookCollectionHeader}`}
                                    >
                                        {t('LIBRARY.queueOrder').toUpperCase()}
                                    </div>
                                    <AwaitingListTable
                                        rows={sortByDate(
                                            bookData.queue,
                                            'requestDate',
                                            true
                                        )}
                                        toFirstPage={true}
                                        headers={[
                                            'id',
                                            'user',
                                            'requestDate',
                                            'title',
                                            'authors',
                                            'isImageUrl',
                                            'imagePath'
                                        ]}
                                        visibleHeaders={[
                                            'Colaborador',
                                            'Data da reserva'
                                        ]}
                                        sortedFields={['user', 'requestDate']}
                                        bookData={bookData}
                                        submit={submit}
                                    />
                                </Paper>

                                <Link
                                    style={{ width: '80px', marginTop: '48px' }}
                                    to={link}
                                >
                                    <Buttons
                                        style={{ width: '100%' }}
                                        className={styles.dialogButton}
                                        tipo="BN-blue"
                                        conteudo={'VOLTAR'}
                                    />
                                </Link>
                            </div>
                        </Paper>
                    </div>
                )}

                <Footer />
            </Transition>
        </React.Fragment>
    );
};

export default AwaitingListManagement;
