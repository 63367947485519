import React, { Component } from 'react';
import { Page, Text, View, Image, Document, StyleSheet, Font } from '@react-pdf/renderer';
import font from "./fonts/Roboto-Bold.ttf"
import robotoRegular from "./fonts/Roboto-Regular.ttf"

// Create styles
Font.register({ family: 'Roboto', src: font });
Font.register({ family: 'RobotoR', src: robotoRegular });

const styles = StyleSheet.create({
  page: {
    paddingBottom: 60,
    paddingTop: 20
  },
  section: {
    marginLeft: 10,
    marginRight: 10,
    paddingLeft: 10,
    paddingRight: 10,
    flexGrow: 1,
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 24,
    lineHeight: "28px",
    color: "#333333",
  },
  subTitle:{
    marginTop: -10,
    fontSize: 12,
    color: "#6D6F71",
  },
  season:{
    fontSize: 10,
    color: "#6D6F71",
    fontFamily: "RobotoR",
    fontStyle: "normal",
    fontWeight: "normal",
  },
  logo: {
    alignItems: "rigth",
    marginTop: -23,

  },
  image: {
    width: 55
  },
  graph:{
    width: 520,
    height: 240,
  },
  pageNumber: {
    fontFamily: "RobotoR",
    fontStyle: "normal",
    fontWeight: "normal",
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    paddingRight: 20,
    alignItems: "rigth",
    textAlign: 'right',
    color: '#333333',
  },
  div1:{
    fontFamily: "RobotoR",
    fontStyle: "normal",
    fontWeight: "normal",
    position: "relative",
    backgroundColor: "#333333",
    color: "#fff",
    fontSize: 10,
    paddingLeft: 5,
    paddingBottom: 3,
    paddingTop: 3
  },
  div2:{
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    position: "relative",
    height: 70,
    color: "#6A6A6A",
    fontSize: 11,
    borderStyle: "solid",
    borderWidth: 5,
    borderColor: "#EDEDED",
    flexDirection: 'row',
    alignItems: 'center'
  },
  section2: {
    marginTop: 10,
  },
  rubrics: {
    height: 44,
    alignItems: "center",
    borderLeft: "1 solid #EDEDED",
    paddingLeft: 22,
    paddingRight: 13
  },
  rubricsTotal: {
    height: 65,
    width: 115,
    backgroundColor: "#EDEDED",
  },
  rubricsTotalBack: {
    height: 28.5,
    width: 109,
    backgroundColor: "#fff",
    marginTop: 2.5,
    marginLeft: 5,
    paddingLeft: 5,
    paddingTop: 3
  },
  labelRubrics: {
    fontSize: 9,
    color: "#6A6A6A",
  },
  labelValues: {
    fontSize: 11,
    color: "#000000",
  },
  labelValuesPercent: {
    fontSize: 9,
    color: "#a4a4a4",
  },
  divGraph: {
    position: "relative",
    color: "#6A6A6A",
    borderStyle: "solid",
    borderWidth: 5,
    borderColor: "#EDEDED",
    borderTop: "none",
    alignItems: 'center'
  },
  rodape: {
    marginLeft: 30,
    paddingLeft: 10,
    fontSize: 10,
    fontFamily: "RobotoR",
    fontStyle: "normal",
  },
  rodape1: {
    marginLeft: 30,
    paddingLeft: 10,
    fontSize: 10,
    fontFamily: "Roboto",
    fontStyle: "normal",
  },
  rodape2: {
    marginLeft: 30,
    paddingLeft: 10,
    marginTop: 10,
    fontSize: 10,
    fontFamily: "RobotoR",
    fontStyle: "normal",
  },
  inst: {
    fontSize: 10,
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold"
  },
  line: {
    marginLeft: 20,
    marginBottom: 10,
    height: 1.5,
    width: 551,
    backgroundColor: "#EDEDED",
    color: "#EDEDED",
  },
  costsHeader: {
    position: "relative",
    height: 15,
    backgroundColor: "#EDEDED",
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 10,
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
  },
  rhFooter: {
    position: "relative",
    height: 15,
    backgroundColor: "#EDEDED",
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 10,
    fontFamily: "RobotoR",
    fontStyle: "normal",
  },
  column: {
    // color: "black",
    //borderLeft: "1 solid #EDEDED"
    // paddingRight: 10
  },
  tableHeader: {
    backgroundColor: "blue"
  },
  row: {
    // height: 44,
    //alignItems: "center",
    // borderLeft: "1 solid #EDEDED",
    //paddingLeft: 22,
    // paddingRight: 13
  },
  costsRow: {
    fontFamily: "RobotoR",
    fontStyle: "normal",
    fontWeight: "bold",
    position: "relative",
    height: 15,
    color: "#6A6A6A",
    fontSize: 10,
    borderBottom: "1 solid #EDEDED",
    borderLeft: "1 solid #EDEDED",
    borderRight: "1 solid #EDEDED",
    flexDirection: 'row',
    //alignItems: 'center'
  },
  rowText: {
    paddingLeft: 5
  },
  ReportHeader: {
    paddingBottom: 10,
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 24,
    lineHeight: "28px",
    color: "#333333",
  },
});

export default class TempFinancialReport extends Component {
  render() {
    const costsAnalytical = this.props.costsAnalytical;
    const costsChronological = this.props.costsChronological;
    const costsByColabAnalytFian = this.props.costsByColabAnalytFian;
    const Projetc = this.props.projectInfor;
    const startDate = this.props.startDate;
    const endDate = this.props.endDate;
    const terms = this.props.terms
    const generalView = this.props.generalViewFiancial;
    const url = this.props.url;
    const type = this.props.type;
    const totRhTable = this.props.totRhTable ? this.props.totRhTable : 0.00;
    const headerRH = [1];
    const graphHeader = [1];
    const rhFooter = [1]
    const costsRH = [1, 2, 3, 4, 5]
    var headerAnalyticalPosition = 66;
    var headerChronologicalPosition = 83;

    var endDateFormated;
    var dayEndGeneralReports = endDate && endDate.getDate()
    var monthEndGeneralReports = endDate && (endDate.getMonth() + 1)
    if (endDate.getDate() < 10) {
      dayEndGeneralReports = "0" + endDate.getDate()
    }
    if ((endDate.getMonth() + 1) < 10) {
      monthEndGeneralReports = "0" + (endDate.getMonth() + 1)
    }
    endDateFormated = dayEndGeneralReports + "/" + monthEndGeneralReports + "/" + endDate.getFullYear()

    function formattedMoney(value) {
      var valuerFormatted = value.toLocaleString('pt-BR', { minimumFractionDigits: 2 })
      if (valuerFormatted !== "NaN") {
        return valuerFormatted;
      } else {
        return "";
      }
    }

    function formattedMoneyWithSymbolo(value) {
      var valuerFormatted = value.toLocaleString('pt-BR', { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' })
      if (valuerFormatted !== "NaN") {
        return valuerFormatted;
      } else {
        return "";
      }
    }

    return (
      Projetc && terms && startDate && endDateFormated && (
        <Page size="A4" style={styles.page} wrap>
          <View style={styles.section}>
            <View fixed style={styles.ReportHeader}>
              <Text>Relatório Financeiro Temporal</Text>
              <View style={styles.logo}>
                <Image
                  style={styles.image}
                  source={window.location.origin + '/img/lsbd-logo.png'}
                />
              </View>
              <View style={styles.subTitle}>
                <Text>{Projetc && Projetc}</Text>
                <Text style={styles.season}> Referente a {startDate ? startDate : ""} - {endDateFormated ? endDateFormated : ""} {terms >= 2 && `(${terms} termos)`}</Text>
              </View>
            </View>
            <View wrap={false}>
              <Text style={styles.div1}>{"Visão geral".toUpperCase()}</Text>
              <View style={styles.div2}>
                <View style={[styles.rubrics, { borderLeft: 'none' }]}>
                  <Text>Orçado (R$)</Text>
                  <Text style={styles.labelValues}>{generalView[0] ? formattedMoney(generalView[0].totBudget) : "0,00"}</Text>
                </View>
                <View style={styles.rubrics}>
                  <Text > Recebido (R$) </Text>
                  <Text style={styles.labelValues}>{generalView[0] ? formattedMoney(generalView[0].totReceived) : "0,00"}</Text>
                  <Text style={styles.labelValuesPercent}>({generalView[0] && ((generalView[0].totReceived * 100) / generalView[0].totBudget) > 0 ? ((generalView[0].totReceived * 100) / generalView[0].totBudget).toFixed(2) : 0}% do total)</Text>
                </View>
                <View style={styles.rubrics}>
                  <Text> A Receber (R$) </Text>
                  <Text style={styles.labelValues}>{generalView[0] ? generalView[0].totBudget ? formattedMoney(generalView[0].totBudget - generalView[0].totReceived) : formattedMoney(generalView[0].totReceived) : "0,00"}</Text>
                  <Text style={styles.labelValuesPercent}>({generalView[0] && ((generalView[0].totBudget - generalView[0].totReceived) * 100 / generalView[0].totBudget) > 0 ? ((generalView[0].totBudget - generalView[0].totReceived) * 100 / generalView[0].totBudget).toFixed(2) : 0}% do total)</Text>
                </View>
                <View style={styles.rubrics}>
                  <Text> Previsão (R$) </Text>
                  {/* Valor Orçado - Valor Recebido + Saldo; */}
                  <Text style={styles.labelValues}>{generalView[0] ? formattedMoney(generalView[0].totBudget - generalView[0].totReceived + (generalView[0].totReceived - generalView[0].totSpent)) : "0,00"}</Text>
                </View>
                <View style={styles.rubricsTotal}>
                  <View style={styles.rubricsTotalBack}>
                    <Text style={styles.labelRubrics}>Gasto (R$)</Text>
                    <Text style={styles.labelValues}>{generalView[0] ? formattedMoney(generalView[0].totSpent + totRhTable) : "0,00"}</Text>
                  </View>
                  <View style={styles.rubricsTotalBack}>
                    <Text style={styles.labelRubrics}> Saldo (R$)</Text>
                    {/* Valor Recebido - Valor Gasto */}
                    <Text style={styles.labelValues}>{generalView[0] ? formattedMoney(generalView[0].totReceived - generalView[0].totSpent) : "0,00"}</Text>
                  </View>
                </View>
              </View>
            </View>

            {/* grafico */}
            {graphHeader
              ? graphHeader.map((a, index) => {
                return type == 0 && (
                  <View style={styles.divGraph}>
                    <Image
                      style={styles.graph}
                      src={url ? url : "https://export.highcharts.com/charts/chart.15331b689d1941a3980903b6f1aae551.png"}
                    />
                  </View>
                )
              })
              : ""}

            <View style={styles.section2} wrap={false}>
              <Text style={styles.div1}>{"Gastos".toUpperCase()}</Text>
            </View>
            <View style={styles.costsHeader} wrap={false}>
              <View style={styles.column, { paddingLeft: 6, paddingRight: 31.5 }}>
                <Text>{"DATA".toUpperCase()}</Text>
              </View>
              <View style={styles.column, { paddingRight: 91 }}>
                <Text>{"NOME".toUpperCase()}</Text>
              </View>
              <View style={styles.column, { paddingRight: 179 }}>
                <Text>{"DESCRIÇÃO".toUpperCase()}</Text>
              </View>
              <View style={styles.column, { paddingRight: 31 }}>
                <Text>{"OFICÍO".toUpperCase()}</Text>
              </View>
              <View style={styles.column}>
                <Text>{"VALOR".toUpperCase()}</Text>
              </View>
            </View>

            {costsAnalytical
              ? costsAnalytical.map((a, index) => {
                if (index === headerAnalyticalPosition) {
                  headerAnalyticalPosition = headerAnalyticalPosition + 46
                  return type == 0 && (
                    <View style={styles.costsHeader} wrap={false}>
                      <View style={styles.column, { paddingLeft: 6, paddingRight: 31.5 }}>
                        <Text>{"DATA".toUpperCase()}</Text>
                      </View>
                      <View style={styles.column, { paddingRight: 91 }}>
                        <Text>{"NOME".toUpperCase()}</Text>
                      </View>
                      <View style={styles.column, { paddingRight: 179 }}>
                        <Text>{"DESCRIÇÃO".toUpperCase()}</Text>
                      </View>
                      <View style={styles.column, { paddingRight: 31 }}>
                        <Text>{"OFICÍO".toUpperCase()}</Text>
                      </View>
                      <View style={styles.column}>
                        <Text>{"VALOR".toUpperCase()}</Text>
                      </View>
                    </View>
                  )
                }
                return type == 0 && (
                  <View>
                    {index === 21 && (
                      <View style={styles.costsHeader} wrap={false}>
                        <View style={styles.column, { paddingLeft: 6, paddingRight: 31.5 }}>
                          <Text>{"DATA".toUpperCase()}</Text>
                        </View>
                        <View style={styles.column, { paddingRight: 91 }}>
                          <Text>{"NOME".toUpperCase()}</Text>
                        </View>
                        <View style={styles.column, { paddingRight: 179 }}>
                          <Text>{"DESCRIÇÃO".toUpperCase()}</Text>
                        </View>
                        <View style={styles.column, { paddingRight: 31 }}>
                          <Text>{"OFICÍO".toUpperCase()}</Text>
                        </View>
                        <View style={styles.column}>
                          <Text>{"VALOR".toUpperCase()}</Text>
                        </View>
                      </View>
                    )}

                    <View style={styles.costsRow} wrap={false}>
                      <View style={styles.row, { width: 55 }}>
                        <Text style={styles.rowText}>{a.expenseDate}</Text>
                      </View>
                      <View style={styles.row, { width: 120, borderLeft: "1 solid #EDEDED", }}>
                        <Text style={styles.rowText}>{a.typeOrName}</Text>
                      </View>
                      <View style={styles.row, { width: 233, borderLeft: "1 solid #EDEDED" }}>
                        <Text style={styles.rowText}>{a.description}</Text>
                      </View>
                      <View style={styles.row, { width: 63, borderLeft: "1 solid #EDEDED" }}>
                        <Text style={styles.rowText}>{a.craftNumber}</Text>
                      </View>
                      <View style={styles.row, { borderLeft: "1 solid #EDEDED" }}>
                        <Text style={styles.rowText}>{formattedMoneyWithSymbolo(a.cost)}</Text>
                      </View>
                    </View>
                  </View>
                )
              })
              : ""}

            {headerRH
              ? headerRH.map((a, index) => {
                return type == 0 && (
                  <View>
                    <View style={styles.section2} wrap={false}>
                      <Text style={styles.div1}>{"RH".toUpperCase()}</Text>
                    </View>
                    <View style={styles.costsHeader} fixed wrap={false}>
                      <View style={styles.column, { paddingLeft: 6, paddingRight: 31.5 }}>
                        <Text>{"DATA".toUpperCase()}</Text>
                      </View>
                      <View style={styles.column, { paddingRight: 97 }}>
                        <Text>{"TIPO".toUpperCase()}</Text>
                      </View>
                      <View style={styles.column, { paddingRight: 163 }}>
                        <Text>{"COLABORADOR".toUpperCase()}</Text>
                      </View>
                      <View style={styles.column, { paddingRight: 31 }}>
                        <Text>{"OFICÍO".toUpperCase()}</Text>
                      </View>
                      <View style={styles.column}>
                        <Text>{"VALOR".toUpperCase()}</Text>
                      </View>
                    </View>
                  </View>
                )
              })
              : ""}

            {costsByColabAnalytFian
              ? costsByColabAnalytFian.map((a, index) => {
                return type == 0 && (
                  <View>
                    <View style={styles.costsRow} wrap={false}>
                      <View style={styles.row, { width: 55 }}>
                        <Text style={styles.rowText}>{a.expenseDate}</Text>
                      </View>
                      <View style={styles.row, { width: 121, borderLeft: "1 solid #EDEDED", }}>
                        <Text style={styles.rowText}>{a.typeOrName}</Text>
                      </View>
                      <View style={styles.row, { width: 233, borderLeft: "1 solid #EDEDED" }}>
                        <Text style={styles.rowText}>{a.description}</Text>
                      </View>
                      <View style={styles.row, { width: 63, borderLeft: "1 solid #EDEDED" }}>
                        <Text style={styles.rowText}>{a.craftNumber}</Text>
                      </View>
                      <View style={styles.row, { borderLeft: "1 solid #EDEDED" }}>
                        <Text style={styles.rowText}>{formattedMoneyWithSymbolo(a.cost)}</Text>
                      </View>
                    </View>
                  </View>
                )
              })
              : ""}
            {rhFooter
              ? rhFooter.map((a, index) => {
                return type == 0 && (
                  <View style={styles.rhFooter} wrap={false}>
                    <View style={styles.column, { paddingLeft: 6, paddingRight: 450 }}>
                      <Text>{"Total"}</Text>
                    </View>
                    <View style={styles.column}>
                      <Text>{formattedMoneyWithSymbolo(totRhTable)}</Text>
                    </View>
                  </View>
                )
              })
              : ""}

            {costsChronological
              ? costsChronological.map((a, index) => {
                if (index === headerChronologicalPosition) {
                  headerChronologicalPosition = headerChronologicalPosition + 46
                  return type == 1 && (
                    <View style={styles.costsHeader} wrap={false}>
                      <View style={styles.column, { paddingLeft: 6, paddingRight: 31.5 }}>
                        <Text>{"DATA".toUpperCase()}</Text>
                      </View>
                      <View style={styles.column, { paddingRight: 91 }}>
                        <Text>{"NOME".toUpperCase()}</Text>
                      </View>
                      <View style={styles.column, { paddingRight: 179 }}>
                        <Text>{"DESCRIÇÃO".toUpperCase()}</Text>
                      </View>
                      <View style={styles.column, { paddingRight: 31 }}>
                        <Text>{"OFICÍO".toUpperCase()}</Text>
                      </View>
                      <View style={styles.column}>
                        <Text>{"VALOR".toUpperCase()}</Text>
                      </View>
                    </View>
                  )
                }
                return type == 1 && (
                  <View>
                    {index === 38 && (
                      <View style={styles.costsHeader} wrap={false}>
                        <View style={styles.column, { paddingLeft: 6, paddingRight: 31.5 }}>
                          <Text>{"DATA".toUpperCase()}</Text>
                        </View>
                        <View style={styles.column, { paddingRight: 91 }}>
                          <Text>{"NOME".toUpperCase()}</Text>
                        </View>
                        <View style={styles.column, { paddingRight: 179 }}>
                          <Text>{"DESCRIÇÃO".toUpperCase()}</Text>
                        </View>
                        <View style={styles.column, { paddingRight: 31 }}>
                          <Text>{"OFICÍO".toUpperCase()}</Text>
                        </View>
                        <View style={styles.column}>
                          <Text>{"VALOR".toUpperCase()}</Text>
                        </View>
                      </View>
                    )}
                    <View style={styles.costsRow} wrap={false}>
                      <View style={styles.row, { width: 55 }}>
                        <Text style={styles.rowText}>{a.expenseDate.split("|")[0]}</Text>
                      </View>
                      <View style={styles.row, { width: 120, borderLeft: "1 solid #EDEDED", }}>
                        <Text style={styles.rowText}>{a.typeOrName}</Text>
                      </View>
                      <View style={styles.row, { width: 233, borderLeft: "1 solid #EDEDED" }}>
                        <Text style={styles.rowText}>{a.description}</Text>
                      </View>
                      <View style={styles.row, { width: 63, borderLeft: "1 solid #EDEDED" }}>
                        <Text style={styles.rowText}>{a.craftNumber}</Text>
                      </View>
                      <View style={styles.row, { borderLeft: "1 solid #EDEDED" }}>
                        <Text style={styles.rowText}>{formattedMoneyWithSymbolo(a.cost)}</Text>
                      </View>
                    </View>
                  </View>
                )
              })
              : ""}
          </View>

          {/* <View style={styles.line} render={({ pageNumber, totalPages }) => (
            pageNumber === totalPages &&
            <View style={styles.line}></View>)} fixed /> */}
          <Text style={styles.rodape1} render={({ pageNumber, totalPages }) => (
            pageNumber === totalPages &&
            "Instruções para cálculo")} fixed />
          <Text style={styles.rodape} render={({ pageNumber, totalPages }) => (
            pageNumber === totalPages &&
            " Previsão: Valor Orçado - Valor Recebido + Saldo;")} fixed />
          <Text style={styles.rodape2} render={({ pageNumber, totalPages }) => (
            pageNumber === totalPages &&
            "Saldo: Valor Recebido - Valor Gasto")} fixed />

          <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `página ${pageNumber} / ${totalPages}`)} fixed />
        </Page>
      ))
  }
}