import { DEFAULT, HR } from "../../types"

export default (state, action) => {
    switch (action.type) {
        case DEFAULT.SET_LOADING:
            return {
                ...state,
                loading: true
            };
        case DEFAULT.SET_LOADING_FALSE:
            return {
                ...state,
                loading: false
            };
        case DEFAULT.RESET_MESSAGE:
            return {
                ...state,
                response: "",
                responseType: ""
            };
        case DEFAULT.SET_HAS_CHANGED:
            return {
                ...state,
                hasChanged: 0
            };
        case HR.GET_COLLABORATORS:
            return {
                ...state,
                collaborators: action.payload,
                loading: false
            };
        case HR.GET_DOCUMENTS_HISTORY:
            return {
                ...state,
                docHistory: action.payload,
                loading: false
            };
        case HR.GET_INFORMATION_IDENTIFICATION:
            return {
                ...state,
                informationIdentification: action.payload
            }
        case HR.UPDATE_INFORMATION_IDENTIFICATION:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case HR.CREATE_INFORMATION_IDENTIFICATION:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case HR.GET_INFORMATION_FAMILY_DATA:
            return {
                ...state,
                informationFamilyData: action.payload
            }
        case HR.UPDATE_INFORMATION_FAMILY_DATA:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case HR.CREATE_INFORMATION_FAMILY_DATA:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case HR.GET_INFORMATION_CONTACT:
            return {
                ...state,
                informationContact: action.payload
            }
        case HR.UPDATE_INFORMATION_CONTACT:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case HR.CREATE_INFORMATION_CONTACT:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case HR.GET_BANK_DATA_CONTACT:
            return {
                ...state,
                bankDataContact: action.payload
            }
        case HR.UPDATE_BANK_DATA_CONTACT:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case HR.GET_INFORMATION_FORMATION:
            return {
                ...state,
                informationFormation: action.payload
            };
        case HR.GET_ADMISSION:
            return {
                ...state,
                admission: action.payload
            }
        case HR.GET_COLLABORATORS_DOCUMENTS:
            return {
                ...state,
                documents: action.payload
            }
        case HR.GET_ADMISSION_COMPANIES:
            return {
                ...state,
                companies: action.payload
            }
        case HR.GET_ADMISSION_RULESETS:
            return {
                ...state,
                rulesets: action.payload
            }
        case HR.GET_ADMISSION_JOBS:
            return {
                ...state,
                jobs: action.payload
            }
        case HR.CREATE_ADMISSION:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case HR.ADMISSION_FIRE:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case HR.UPDATE_ADMISSION:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case HR.DELETE_DOCUMENT:
                return {
                  ...state,
                  response: action.payload,
                  responseType: action.responseType,
                  successiveActions: action.successiveActions,
                  hasChanged: action.hasChanged,
                  loading: false
                };
        case HR.GET_PROFILE_BY_USER:
            return {
                 ...state,
                profiles: action.payload
            }
        case HR.GET_WORKING_HOURS_CONTRACT: 
            return {
                ...state,
                rhWorkingHoursContracts : action.payload
            }
        case HR.GET_CLOCK_IN_BY_USER: 
            return {
                ...state,
                clockInInfo : action.payload
            }
        case HR.GET_MODULES_ROLES: 
        return {
            ...state,
            modulesAndRoles : action.payload
        }
        case HR.UPDATE_CLOCK_RECORD:
                return {
                    ...state,
                    response: action.payload,
                    responseType: action.responseType,
                    successiveActions: action.successiveActions,
                    hasChanged: action.hasChanged
                };
        case HR.GET_BIRTHDAYS:
            return{
                ...state,
                birthdays: action.payload
            }
        default:
            return state;
    }
}