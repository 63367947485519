import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import EventIcon from '@material-ui/icons/Event';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
    DatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptBRLocale from 'date-fns/locale/pt-BR';
import DialogPeriod from 'components/dialogs/DialogPeriod';

import { inputStyles } from './Styles';

const InputFilterDatePeriod = ({
    filterValue,
    label,
    setFilter,
    monthAndYear,
    ...props
}) => {
    const classes = useStyles();
    const styles = inputStyles();
    const [t] = useTranslation();

    const [breakPeriodText, setBreakPeriodText] = useState('');

    const [openDialogBreakPeriod, setOpenDialogBreakPeriod] = useState(false);


    const clearField = () => {
        setFilter({
            start: null,
            end: null
        });
        setBreakPeriodText('');            
    };

    return (
        <>
        <div className={styles.filterSearchInput}>
            <TextField
                disabled
                label={t(label)}
                value={breakPeriodText}
                style={{
                    minWidth: '250px',
                    marginTop: 0,
                    marginBottom: 0,
                    width: '100%'
                }}
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputSearch
                }}
                color={'black'}
                inputProps={{
                    'aria-label': 'search',
                    autocomplete: 'off'
                }}
                variant="standard"
                onClick={() =>
                    !breakPeriodText
                        ? setOpenDialogBreakPeriod(true)
                        : null
                }
                InputProps={{
                    endAdornment: breakPeriodText ? (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => {
                                    clearField(
                                        'breakPeriod'
                                    );
                                }}
                            >
                                <Close />
                            </IconButton>
                        </InputAdornment>
                    ) : (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() =>
                                    setOpenDialogBreakPeriod(
                                        true
                                    )
                                }
                            >
                                <EventIcon />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
        </div>
        <DialogPeriod
            open={openDialogBreakPeriod}
            setOpen={setOpenDialogBreakPeriod}
            breakPeriod={filterValue}
            setParentValues={setFilter}
            setBreakPeriodText={setBreakPeriodText}
            label={label}
        />
        </>
    );
};

export default InputFilterDatePeriod;



