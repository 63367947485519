import React from "react";
import "../../App.css";
import SideBar from "../layout/sidebar/Sidebar";
import DashboardNavBar from "../layout/DashboardNavBar";
import View from "./View";

const ViewUserCosts = ({ match }) => {
  return (
    <div>
      <DashboardNavBar />
      <SideBar />
      <View id={match.params.id}/>
    </div>
  );
}

export default ViewUserCosts;