import axios from 'axios';
import { useMutation } from 'react-query';
import Cookies from 'js-cookie';
import { useSnackbar } from 'hooks/UseSnackbar';
import { useTranslation } from 'react-i18next';
import { useQueryUserPermissions } from 'hooks/DataFetching/Admin/Users/useQueryUserPermissions';
import { getManagersAndSendEmails } from 'services/Emails/General/generalEmailService';

export const useMutationEnableUser = (setOpen, checked, id) => {
    const toast = useSnackbar();
    const [t] = useTranslation();

    const queryUsersPermissions = useQueryUserPermissions(
        { enabled: false },
        id
    );

    const mutation = useMutation({
        mutationFn: async obj =>
            await axios.put(
                process.env.REACT_APP_SERVER +
                    `api/Admin/Users/${obj.get('id')}/EnableLogin`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            ),
        onSuccess: res => {
            toast.success(t('HR.accessEnabledSuccess'));
            queryUsersPermissions.refetch();
            setOpen(false);

            const data = res.data;

            const emailData = {
                collaboratorName: data.user.shortname,
                collaboratorEmail: data.user.username,
                collaboratorCpf: data.user.cpf,
                collaboratorId: data.user.id,
                collaboratorCreatedAt: data.user.createdAt,
                operatorName: data.operator.shortname,
                tempPassword: data.user.password,
                checked: checked,
                collaboratorRegistrationStatus:
                    data.user.userRegistrationStatus,
                modulesPermissions: data.user.systemRoles.map(module => {
                    return {
                        moduleName: module.name,
                        permissionName: module.role.name
                    };
                })
            };

            getManagersAndSendEmails(
                emailData,
                'api/Admin/Collaborator/Enabled',
                'ADMIN_NEW_COLLABORATOR_ENABLED_EMAIL_OPTIONAL',
                'adminManagers'
            );
        },
        onError: err => {
            if (err.response.data.message && err.response.data.code !== 1000) {
                toast.error(err.response.data.message);
            } else {
                toast.error(t('HR.accessEnabledError'));
            }
        },
        onSettled: () => {}
    });

    return mutation;
};
