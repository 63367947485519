import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    StyledTableCell,
    StyledTableRow,
    useStyles
} from 'components/profiles/ProfileStyles';
import LibraryLoanHistoryTableActions from './LibraryLoanHistoryTableActions';
import { dateToBR } from 'utils/dates/DateToBRr';
import { FormatDateToFront } from 'utils/dates/FormatDateToFront';
import { LibraryReservationStatusValues } from 'global/constants';
import Typography from 'components/typography/Typography';
import { AllAuthorsName } from 'utils/library/AllAuthorsName';
import { formatExemplarCode } from 'utils/library/formatExemplarCode';
import Error from '@material-ui/icons/Error';
import { CheckboxMarkedCircle } from 'mdi-material-ui';
import { translateLoanStatus } from 'utils/library/translateStatus';
import { QuestionAnswer } from '@material-ui/icons';

function compareString(a, b) {
    if (
        b
            .toString()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .trim()
            .toLowerCase() <
        a
            .toString()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .trim()
            .toLowerCase()
    ) {
        return -1;
    }
    if (
        b
            .toString()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .trim()
            .toLowerCase() >
        a
            .toString()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .trim()
            .toLowerCase()
    ) {
        return 1;
    }
    return 0;
}

function desc(a, b, orderBy) {
    if (a[orderBy] === null) a[orderBy] = '';
    if (b[orderBy] === null) b[orderBy] = '';

    if (a[orderBy] === undefined) a[orderBy] = '';
    if (b[orderBy] === undefined) b[orderBy] = '';

    if (orderBy === 'user') {
        return compareString(a['user'].name, b['user'].name);
    }

    if (orderBy === 'title') {
        return compareString(a['book'].title, b['book'].title);
    }

    if (orderBy === 'authors') {
        return compareString(
            AllAuthorsName(a['book'].authors),
            AllAuthorsName(b['book'].authors)
        );
    }

    if (orderBy === 'category') {
        return compareString(a['book'].category.name, b['book'].category.name);
    }

    if (orderBy === 'exemplar') {
        return compareString(
            formatExemplarCode('' + a['exemplar'].code),
            formatExemplarCode('' + b['exemplar'].code)
        );
    }

    if (orderBy === 'status') {
        return compareString(
            translateLoanStatus(a['status']),
            translateLoanStatus(b['status'])
        );
    }

    if (orderBy === 'devolutionDate') {
        return compareString(a['devolutionDate'], b['devolutionDate']);
    }

    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc'
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
}

function createContent(rows, headers, order, orderBy) {
    const rowsCopy = [];
    var obj = {};
    for (var i = 0; i < rows.length; i++) {
        for (var j = 0; j < headers.length; j++) {
            obj[headers[j]] = rows[i][headers[j]];
        }
        rowsCopy.push(obj);
        obj = {};
    }
    return stableSort(rowsCopy, getSorting(order, orderBy));
}

const LibraryLoanHistoryTable = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [rows, setRows] = useState({});
    const [rowsLength, setRowsLength] = useState(0);
    const [headers, setHeaders] = useState([]);
    const [visibleHeaders, setVisibleHeaders] = useState([]);
    const [sortedFields, setSortedFields] = useState([]);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');

    React.useEffect(() => {
        setHeaders(props.headers);
        setRows(props.rows);
        setRowsLength(props.rows.length);
        setVisibleHeaders(props.visibleHeaders);
        setSortedFields(props.sortedFields);
        adjustRowsPerpage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.headers, props.rows, props.visibleHeaders]);

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }
    const adjustRowsPerpage = () => {
        if (props.rows.length >= 10) {
            setRowsPerPage(10);
        } else {
            setRowsPerPage(props.rows.length);
        }
    };
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === sortedFields[property] && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(sortedFields[property]);
    };

    const getReservationStatusLabel = reservationStatus => {
        let icon = <></>;

        switch (reservationStatus) {
            case LibraryReservationStatusValues.CANCELED_COLLABORATOR:
            case LibraryReservationStatusValues.CANCELED_MANAGEMENT:
                icon = (
                    <Error style={{ color: '#F3222F', marginRight: '5px' }} />
                );
                break;
            case LibraryReservationStatusValues.FINISHED:
                icon = (
                    <CheckboxMarkedCircle
                        style={{ color: '#90ED7D', marginRight: '5px' }}
                    />
                );
                break;
            default:
                icon = (
                    <QuestionAnswer
                        style={{ color: 'gray', marginRight: '5px' }}
                    />
                );
                break;
        }

        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {icon}{' '}
                <Typography>
                    {translateLoanStatus(reservationStatus)}
                </Typography>
            </div>
        );
    };

    const LibraryLoanHistoryTableHead = props => {
        const { order, orderBy, onRequestSort, disableHeaderIndex } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    {visibleHeaders.map((header, index) => (
                        <StyledTableCell
                            key={index}
                            size="small"
                            align="left"
                            sortDirection={
                                orderBy === sortedFields[index] ? order : false
                            }
                        >
                            {index !== disableHeaderIndex ? (
                                <TableSortLabel
                                    active={orderBy === sortedFields[index]}
                                    direction={order}
                                    onClick={createSortHandler(index)}
                                    disabled={index === disableHeaderIndex}
                                >
                                    {header.toUpperCase()}
                                    {orderBy === sortedFields[index] ? (
                                        <span
                                            className={classes.visuallyHidden}
                                        >
                                            {order === 'desc'
                                                ? 'sorted descending'
                                                : 'sorted ascending'}
                                        </span>
                                    ) : null}
                                </TableSortLabel>
                            ) : null}
                        </StyledTableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    };

    LibraryLoanHistoryTableHead.propTypes = {
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired
    };

    React.useEffect(() => {
        if (props.toFirstPage) {
            document.getElementById('toFirstPage').click();
        }
    }, [props.rows, props.toFirstPage]);

    const toFirstPage = () => {
        setPage(0);
    };

    return (
        <Paper className={classes.root}>
            <div className={classes.tableWrapper}>
                <Table className={classes.table} size="small">
                    {rows.length > 0 && (
                        <LibraryLoanHistoryTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                    )}
                    <TableBody>
                        {createContent(rows, headers, order, orderBy)
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            .map(row => (
                                <StyledTableRow
                                    style={{ height: '60px' }}
                                    key={row.id}
                                >
                                    <TableCell size="small">
                                        {row['user'].name}
                                    </TableCell>

                                    <TableCell size="small">
                                        {row['book'].title}
                                    </TableCell>

                                    <TableCell size="small">
                                        {AllAuthorsName(row['book'].authors)}
                                    </TableCell>

                                    <TableCell size="small">
                                        {row['book'].category.name}
                                    </TableCell>

                                    <TableCell size="small">
                                        {formatExemplarCode(
                                            '' + row['exemplar'].code
                                        )}
                                    </TableCell>

                                    <TableCell size="small">
                                        {getReservationStatusLabel(
                                            row['status']
                                        )}
                                    </TableCell>

                                    <TableCell size="small">
                                        {row['devolutionDate']
                                            ? `${dateToBR(
                                                  FormatDateToFront(
                                                      row['devolutionDate']
                                                  )
                                              )}`
                                            : '-'}
                                    </TableCell>

                                    <TableCell size="small" align="right">
                                        <div
                                            style={{
                                                marginTop: 'auto',
                                                marginBottom: 'auto',
                                                display: 'flex',
                                                flexWrap: 'nowrap ',
                                                justifyContent: 'flex-end'
                                            }}
                                        >
                                            <LibraryLoanHistoryTableActions
                                                item={row}
                                            />
                                        </div>
                                    </TableCell>
                                </StyledTableRow>
                            ))}

                        {emptyRows > 0 && (
                            <TableRow style={{ height: 48 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <button hidden id="toFirstPage" onClick={toFirstPage}></button>
                {rows.length > 0 && (
                    <TableFooter style={{ float: 'right' }}>
                        <TablePagination
                            labelRowsPerPage={'Resultados por página'}
                            count={rowsLength}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            component="div"
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </TableFooter>
                )}
            </div>
        </Paper>
    );
};

LibraryLoanHistoryTable.propTypes = {
    rows: PropTypes.array.isRequired,
    headers: PropTypes.array.isRequired,
    visibleHeaders: PropTypes.array.isRequired
};

export default LibraryLoanHistoryTable;
