import React, { useState, useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Buttons from "components/buttons/Pressed";
import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";
import ExcelJS from 'exceljs';

const DialogExport = (props) => {
    const [t] = useTranslation();
    const classes = useStyles();
    const { title, maxFieldsColumn, open, setOpen, data } = props;
    const fileDownload = require("js-file-download");
    const [radioValue, setRadioValue] = useState("SOFTWARE");
    const [buttonExport, setButtonExport] = useState(false);
    const [fields, setFields] = useState([
        { label: "Nome do Software", checked: true, section: "SOFTWARE", property: "name" },
        { label: "Categoria", checked: true, section: "SOFTWARE", property: "category" },
        { label: "Desenvolvido por", checked: false, section: "SOFTWARE", property: "manufacturer" },
        { label: "Link de Acesso", checked: false, section: "SOFTWARE", property: "url" },
        { label: "Plataforma", checked: false, section: "SOFTWARE", property: "platforms" },
        //Licenses:
        { label: "Código", checked: true, section: "LICENSE", property: "codigo" },
        { label: "Chave", checked: true, section: "LICENSE", property: "key" },
        { label: "Versão", checked: false, section: "LICENSE", property: "version" },
        { label: "Serial", checked: false, section: "LICENSE", property: "serial" },
        { label: "Status", checked: true, section: "LICENSE", property: "status" },
        { label: "Capacidade", checked: false, section: "LICENSE", property: "capacity" },
        { label: "Data de Aquisição", checked: true, section: "LICENSE", property: "acquisitionDate" },
        { label: "Data de Ativação", checked: true, section: "LICENSE", property: "activationDate" },
        { label: "Tipo de Licença", checked: true, section: "LICENSE", property: "licenseType" },
        { label: "Validade", checked: false, section: "LICENSE", property: "expirationDate" },
        { label: "Email", checked: false, section: "LICENSE", property: "email" },
        { label: "Invoice/NF", checked: false, section: "LICENSE", property: "invoice" },
        { label: "Método de Aquisição", checked: false, section: "LICENSE", property: "informationAcquisitionMethod" },
        { label: "Valor", checked: false, section: "LICENSE", property: "price" },
        { label: "Responsável", checked: false, section: "LICENSE", property: "responsible" },
        { label: "Máquina", checked: false, section: "LICENSE", property: "machine" },
    ]);

    let qtyColumn = Math.ceil(fields.filter(field => field.section === radioValue).length / maxFieldsColumn);

    function dateToEN(date) {
        const dateAcquisition = new Date(date);
        return dateAcquisition.getDate() + "/" + (dateAcquisition.getMonth() + 1) + "/" + dateAcquisition.getFullYear();
    }

    const valueByColumn = (column, property) => {
        let response = [];
        switch (column) {
            case "Nome do Software":
                if (radioValue === "SOFTWARE") {
                    return data.map(obj => obj.name);
                } else {
                    data.forEach(obj => {
                        if (obj.inventoryLicense) {
                            obj.inventoryLicense.forEach(exemplar => {
                                if (obj.name) {
                                    response.push(obj.name);
                                } else {
                                    response.push("");
                                }
                            });
                        }
                    });
                    return response;
                }
            case "Data de Aquisição":
                data.forEach(obj => {
                    if (obj.inventoryLicense) {
                        obj.inventoryLicense.forEach(license => {
                            if (license.acquisitionDate) {
                                response.push(dateToEN(license.acquisitionDate));
                            } else {
                                response.push("");
                            }
                        });
                    }
                });
                return response;
            case "Data de Ativação":
                data.forEach(obj => {
                    if (obj.inventoryLicense) {
                        obj.inventoryLicense.forEach(license => {
                            if (license.activationDate) {
                                response.push(dateToEN(license.activationDate));
                            } else {
                                response.push("");
                            }
                        });
                    }
                });
                return response;
            case "Validade":
                data.forEach(obj => {
                    if (obj.inventoryLicense) {
                        obj.inventoryLicense.forEach(license => {
                            if (license.expirationDate) {
                                response.push(dateToEN(license.expirationDate));
                            } else {
                                response.push("");
                            }
                        });
                    }
                });
                return response;
            case "Tipo de Licença":
                data.forEach(obj => {
                    if (obj.inventoryLicense) {
                        obj.inventoryLicense.forEach(license => {
                            if (license.licenseType) {
                                response.push(license.licenseType === "ACQUIRED" ? "Adquirida " : "Renovável");
                            } else {
                                response.push("");
                            }
                        });
                    }
                });
                return response;
            case "Status":
                data.forEach(obj => {
                    if (obj.inventoryLicense) {
                        obj.inventoryLicense.forEach(license => {
                            if (license.status) {
                                response.push(license.status === "AVAILABLE" ? "Disponível" : "Indisponível");
                            } else {
                                response.push("");
                            }
                        });
                    }
                });
                return response;
            case "Capacidade":
                data.forEach(obj => {
                    if (obj.inventoryLicense) {
                        obj.inventoryLicense.forEach(license => {
                            if (license.capacity) {
                                response.push(license.capacity === "LIMITED" ? "Limitado" : "Ilimitado");
                            } else {
                                response.push("");
                            }
                        });
                    }
                });
                return response;
            case "Plataforma":
                data.forEach(obj => {
                    if (obj.platforms && obj.platforms.length > 0) {
                        response.push(obj.platforms.join(', '));
                    } else {
                        response.push("");
                    }
                });
                return response;
            default:
                if (radioValue === "SOFTWARE") {
                    return data.map(obj => {
                        if (obj[property]) return obj[property];
                        return "";
                    });
                } else {
                    data.forEach(obj => {
                        if (obj.inventoryLicense) {
                            obj.inventoryLicense.forEach(license => {
                                if (license[property]) {
                                    response.push(license[property]);
                                } else {
                                    response.push("");
                                }
                            });
                        }
                    });
                    return response;
                }
        }
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleExport = () => {
        setOpen(false);
        const filename = `Exportar Softwares - ${radioValue.toLocaleLowerCase()}.xlsx`;

        const workbook = new ExcelJS.Workbook();
        const ws = workbook.addWorksheet("Report");

        // Style header
        ws.getRow(1).font = { name: "Calibri", size: 14, bold: true };

        // Create columns
        ws.columns = fields.filter(field => field.section === radioValue && field.checked).map(field => {
            return { header: field.label, key: field.property }
        });

        // Add columns values
        ws.columns.forEach(col => {
            //Add column data
            const res = valueByColumn(col.header, col.key);
            //Add column name
            res.unshift(t(col.header));

            col.values = res;
            col.width = col.header.length + 10;
        });

        // Download file
        workbook.xlsx.writeBuffer().then(function (data) {
            const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            fileDownload(blob, filename);
        });
    };

    const handleChange = (event) => {
        if (event && event.target && event.target.name) {
            const newFields = [...fields];
            const field = newFields.find(x => x.label === event.target.name && x.section === radioValue);
            if (field) {
                field.checked = !field.checked;
                setFields(newFields);
            }
        }
    };

    useEffect(() => {
        let buttonIsEnabled = fields.filter(x => x.checked === true && x.section === radioValue)
        buttonIsEnabled.length === 0 ? setButtonExport(true) : setButtonExport(false)
    }, [radioValue, fields]);

    const handleChangeRadio = (event) => {
        qtyColumn = Math.ceil(fields.filter(field => field.section === (event.target.value).length / maxFieldsColumn));
        setRadioValue(event.target.value);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            msg="mensagem"
            maxWidth="md"
        >
            <DialogTitle className={classes.actionsTitle} style={{ width: 'auto' }}>
                <span style={{ fontWeight: 'bold' }}>
                    {t(title).toLocaleUpperCase()}
                </span>
                <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <FormControl component="fieldset">
                        <Grid container>
                            <Grid container item spacing={1} md={12} lg={12}>
                                <Grid item xs={12}>
                                    <FormLabel component="legend">Tipo de exportação</FormLabel>
                                </Grid>
                                <Grid item xs={12}>
                                    <RadioGroup row aria-label="position"
                                        name="position"
                                        defaultValue="top"
                                        value={radioValue}
                                        onChange={handleChangeRadio}
                                    >
                                        <FormControlLabel
                                            value="SOFTWARE"
                                            control={<Radio color="primary" />}
                                            label="Por software"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="LICENSE"
                                            control={<Radio color="primary" />}
                                            label="Por licença"
                                            labelPlacement="end"
                                        />
                                    </RadioGroup>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormLabel component="legend">Selecione abaixo o(s) campo(s) que deseja exportar:</FormLabel>
                                </Grid>
                                {Array.from(Array(qtyColumn).keys()).map((column, index) =>
                                    <Grid item xs={4} style={{ display: 'flex', flexDirection: 'column', }} key={index}>
                                        {fields
                                            .filter(field => field.section === radioValue)
                                            .slice(column * maxFieldsColumn, (column + 1) * maxFieldsColumn)
                                            .map((field, idx) =>
                                                <FormControlLabel
                                                    key={field.label + column + idx}
                                                    label={t(field.label)}
                                                    control={
                                                        <Checkbox color="primary" checked={field.checked} name={field.label} onChange={handleChange} />
                                                    }
                                                />
                                            )}
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </FormControl>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Buttons
                    onClick={handleClose}
                    tipo="BN-noback"
                    conteudo={t("GENERAL.cancel").toLocaleUpperCase()}
                />
                <Buttons
                    disabled={buttonExport}
                    onClick={handleExport}
                    tipo="BN-blue"
                    conteudo={t("INVENTORY.export").toLocaleUpperCase()}
                />
            </DialogActions>
        </Dialog>
    );
}

export default DialogExport;