import React, { Component } from "react";
import NavLogin from "../layout/NavMenu";
import LoginForm from "./Index";
import { instanceOf } from "prop-types";
import { Cookies, withCookies } from 'react-cookie';

class Home extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  render() {
    return (
      // <CookiesProvider>
      <div>
        <NavLogin />
        <LoginForm cookies={this.props.cookies} />
      </div>
      // </CookiesProvider>
    );
  }
}

export default withCookies(Home);
