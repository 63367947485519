import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogUnit from 'components/dialogs/DialogUnit';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import DefaultInfo from 'components/Infos/DefaultInfo';
import Buttons from 'components/buttons/Pressed';
import Typography from 'components/typography/Typography';

import { translateUserRegistrationStatus } from '../ScreenUtils';
import { screenStyles } from '../Styles';

const DialogConfirmationUser = props => {
    const styles = screenStyles();
    const [t] = useTranslation();

    const { open, setOpen, viewValues, mutationIsLoading, submit, context } =
        props;

    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const [nextStep, setNextStep] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        submit();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            maxWidth={'sm'}
            fullWidth
        >
            <>
                <DialogTitle>
                    <Grid container item spacing={2} xs={12}>
                        <Grid item xs={11}>
                            <Typography
                                variant={'RobotoBold'}
                                fontSize={24}
                                textColor={'secondary'}
                            >
                                {context === 'edit'
                                    ? t('GENERAL.confirmUpdateUser')
                                    : t('GENERAL.confirmUserRegistration')}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton
                                color="#6D6F71"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>

                <DialogContent>
                    <Grid container item spacing={2} xs={12}>
                        <Grid item xs={12}>
                            <DefaultInfo
                                textTranslation={
                                    context === 'edit'
                                        ? 'GENERAL.beforeCompletingTheUpdate'
                                        : 'GENERAL.beforeCompletingTheRegistration'
                                }
                                iconColor={'#FFD200'}
                                fontSize={14}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <DialogUnit
                                head={t(
                                    'GENERAL.collaboratorName'
                                ).toUpperCase()}
                                content={viewValues.name}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <DialogUnit
                                head={t('GENERAL.socialName').toUpperCase()}
                                content={viewValues.shortname}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <DialogUnit
                                head={t('PERSONAL.CPF').toUpperCase()}
                                content={viewValues.cpf}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <DialogUnit
                                head={t('PERSONAL.email').toUpperCase()}
                                content={
                                    viewValues.username +
                                    process.env.REACT_APP_DEFAULT_EMAIL
                                }
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <DialogUnit
                                head={t('HR.approver').toUpperCase()}
                                content={viewValues.approver}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <DialogUnit
                                head={t('HR.surrogate').toUpperCase()}
                                content={viewValues.surrogate}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <div className="headerContent">
                                    {'Permissões do Usuário'.toUpperCase()}
                                </div>
                                <Table size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <Typography
                                                    variant={'RobotoBold'}
                                                    fontSize={14}
                                                    textColor={'secondary'}
                                                >
                                                    MÓDULO
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography
                                                    variant={'RobotoBold'}
                                                    fontSize={14}
                                                    textColor={'secondary'}
                                                >
                                                    NÍVEL DE PERMISSÃO
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {viewValues.permissions.map(row => (
                                            <TableRow key={row.name}>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {row.name}
                                                </TableCell>
                                                <TableCell>
                                                    {row.permission}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Buttons
                        onClick={handleClose}
                        tipo={'BN-noback'}
                        conteudo={t('GENERAL.comeBack').toUpperCase()}
                    />

                    <Buttons
                        isLoading={mutationIsLoading}
                        onClick={handleSubmit}
                        tipo="BN-blue"
                        conteudo={
                            context === 'edit'
                                ? t('GENERAL.saveChanges').toUpperCase()
                                : `${t('GENERAL.register').toUpperCase()} ${t(
                                      'GENERAL.collaborator'
                                  ).toUpperCase()}`
                        }
                    />
                </DialogActions>
            </>
        </Dialog>
    );
};

export default DialogConfirmationUser;
