import React, { useState, useEffect } from 'react';
import Typography from 'components/typography/Typography';
import { screenStyles } from 'pages/libraryModule/cards/Styles';
import Skeleton from '@material-ui/lab/Skeleton';

export const CardImage = props => {
    const { image, alt } = props;
    const styles = screenStyles();

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(false);
    }, [image]);

    return (
        <>
            <img
                className={styles.cardImage}
                style={!loaded ? { display: 'none' } : {}}
                src={image}
                alt={alt}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src =
                        window.location.origin + '/img/no-book-cover.png';
                    setLoaded(true);
                }}
                onLoad={() => {
                    setLoaded(true);
                }}
            />
            {!loaded && (
                <Skeleton
                    className={styles.cardImage}
                    variant="rect"
                    width={160}
                    height={220}
                />
            )}
        </>
    );
};

export const CardVerticalComponent = props => {
    const { head, content } = props;
    const styles = screenStyles();

    return (
        <>
            <Typography
                className={styles.cardTopic}
                variant={'RobotoBold'}
                fontSize={12}
                textColor={'secondary'}
            >
                {head}
            </Typography>
            <Typography
                className={styles.cardAuthor}
                variant={'RobotoRegular'}
                fontSize={12}
                textColor={'secondary'}
            >
                {content}
            </Typography>
        </>
    );
};

export const CardHorizontalComponent = props => {
    const { head, content } = props;
    const styles = screenStyles();

    return (
        <div className={styles.cardAmount}>
            <Typography
                className={styles.cardTopic}
                variant={'RobotoRegular'}
                fontSize={12}
                textColor={'secondary'}
            >
                <b>{head + ': '}</b>
                {content}
            </Typography>
        </div>
    );
};

export const CardTitle = props => {
    const { title } = props;
    const styles = screenStyles();

    return (
        <Typography
            className={styles.cardTitle}
            variant={'RobotoBold'}
            fontSize={12}
            textColor={'secondary'}
        >
            {title}
        </Typography>
    );
};

export const CardDetails = props => {
    const { onClick, title } = props;
    const styles = screenStyles();

    return (
        <Typography
            onClick={onClick}
            className={styles.cardMoreDetails}
            variant={'RobotoSemiBold'}
            fontSize={14}
            textColor={'primary'}
        >
            {title}
        </Typography>
    );
};
