import React from "react";
import "../../../../App.css";
import SideBar from "components/layout/sidebar/Sidebar";
import DashboardNavBar from "components/layout/DashboardNavBar";
import MyJustificationsForm from "./MyJustificationsForm";

const CallEditMyJustifications = ({ match }) => {

    return (
        <div>
            <DashboardNavBar />
            <SideBar />
            <MyJustificationsForm id={match.params.id} context={"edit"} />
        </div>
    );
}

export default CallEditMyJustifications;