import React, { useEffect } from "react";
import { Paper } from "@material-ui/core";
import { useStyles } from "components/layout/CommonStyles";
import { useTranslation } from "react-i18next";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const UserMonthlyCosts = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const { currentCosts } = props;
    var groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };
    const groupedCosts = groupBy(currentCosts, "costName");
    const groupedByMonth = groupBy(currentCosts, "costMonth");
    const otherCosts = currentCosts.filter(el => el.costName === "OtherCost").sort(function (a, b) { return a - b });

    const monthNames = ["JAN", "FEV", "MAR", "ABR", "MAI", "JUN",
        "JUL", "AGO", "SET", "OUT", "NOV", "DEZ"
    ];

    const informations = ["contractType", "gpf", "numberCraft","rhType", "Salary", "FGTS", "IRRF", "PIS",
        "INSS", "ISS", "SumOfDebts", "HealthCare", "DentalPlan", "MealAllowance",
        "TransportationVoucher", "AdministrativeFee"
    ];

    const dictCosts = {
        "contractType": "TIPO DE REGIME",
        "gpf": "PROJETO DEBITADO (GPF)",
        "numberCraft": "NÚMERO DE OFÍCIO",
        "rhType": "Tipo de RH",
        "Salary": "REMUNERAÇÃO",
        "FGTS": "FGTS",
        "IRRF": "IRRF",
        "PIS": "PIS",
        "INSS": "INSS",
        "ISS": "ISS",
        "SumOfDebts": "PASSIVO TRABALHISTA",
        "HealthCare": "PLANO DE SAÚDE",
        "DentalPlan": "PLANO ODONTOLÓGICO",
        "MealAllowance": "VALE ALIMENTAÇÃO",
        "TransportationVoucher": "VALE TRANSPORTE",
        "AdministrativeFee": "TAXA DE ADIMINISTRAÇÃO",
        "total": "CUSTO TOTAL"
    };

    const dictContractType = {
        "Intern": { "name": "Estagiário", "color": "#fffef3" },
        "CLT": { "name": "CLT", "color": "#f5fefd" },
        "RPA": { "name": "RPA", "color": "#ffa9c7" },
        "ScholarshipHolder": { "name": "Bolsista", "color": "#f7f8ff" }
    };

    useEffect(() => {
        createTableBody();
        setTotalValues();
    }, [currentCosts]);

    const converTotBrCurrency = number => {
        var temp = "";
        var len = number.length;
        var count = 0;

        for (var i = 0; i < len; i++) {
            if (i === 1) {
                var aux = "," + number[i] + temp;
                temp = aux;
            } else {
                if (count === 3) {
                    count = 1;
                    var aux = number[i] + "." + temp;
                    temp = aux;
                } else {
                    if (i > 1) {
                        count += 1;
                    }
                    var aux = number[i] + temp;
                    temp = aux;
                }
            }
        }

        return temp;
    };

    const setBrazilCurrency = cost => {
        cost = cost.toString().split("");

        if (
            (cost[cost.length - 1] === "0" && cost[cost.length - 2] === "0") ||
            cost[cost.length - 3] !== "."
        ) {
            if (cost[cost.length - 2] === ".") {
                cost.push("0");
            } else {
                cost.push("0");
                cost.push("0");
            }
        }

        cost = cost
            .filter(el => el !== "." && el !== ",")
            .join("")
            .split("")
            .reverse()
            .join("");

        return ("R$ " + converTotBrCurrency(cost));
    }

    const convertTotalToBrCurrency = total => {
        var aux = total
            .toString()
            .split("")
            .filter(el => el !== "." && el !== ",")
            .join("");
        var value = converTotBrCurrency(
            aux
                .split("")
                .reverse()
                .join("")
        );

        return ("R$ " + value);
    };

    const setColumnColor = (month, color) => {
        var len = 16 + otherCosts.length;
        for (var i = 0; i < len; i++) {
            if (document.getElementsByClassName(month)[i])
                document.getElementsByClassName(month)[i].style.background = color;
        }
    }

    const setTotalValues = () => {
        return <TableBody>
            <TableRow>
                <TableCell style={{ fontWeight: "bold", backgroundColor: "#f4f5f6" }} component="th" scope="row">{dictCosts["total"]}</TableCell>
                {
                    monthNames.map(function (month, index) {
                        var total = 0;
                        var allTotalCosts = groupedCosts["TotalCost"];

                        if(allTotalCosts && allTotalCosts.length > 0){
                            var totalMonth = allTotalCosts.filter(el => el.costMonth === (index + 1))[0];
                            
                            if(totalMonth)
                                total = totalMonth.cost;
                        }

                        var monthHasAnyValue = currentCosts.filter(el => el.costMonth === (index + 1)).length > 0;
                        var color = monthHasAnyValue ? dictContractType[groupedByMonth[index + 1][0].contractType].color : "none";
                        setColumnColor(month, color);

                        var totalFormatted = total
                            .toFixed(2)
                            .split("")
                            .filter(el => el !== "." && el !== ",")
                            .join("");

                        return <TableCell align="center" component="th" scope="row" style={{ fontWeight: "bold", backgroundColor: "#f4f5f6" }}>{convertTotalToBrCurrency(totalFormatted)}</TableCell>
                    })
                }
            </TableRow>
        </TableBody>
    }

    const createTableBody = () => {
        return <TableBody>
            {
                informations.map(function (iname) {
                    return <TableRow >
                        <TableCell style={{ fontWeight: "bold" }} component="th" scope="row" key={iname}>{dictCosts[iname]}</TableCell>
                        {
                            monthNames.map(function (month, index) {
                                var value = "R$ ";

                                if (iname === "contractType" || iname === "gpf" || iname === "numberCraft" || iname === "rhType") {
                                    if (iname === "contractType")
                                        value = groupedByMonth[index + 1] ? dictContractType[groupedByMonth[index + 1][0].contractType].name : "";
                                    else if (iname === "numberCraft")
                                        value = groupedByMonth[index + 1] ? groupedByMonth[index + 1][0].numberCraft : "";
                                    else if (iname === "rhType")
                                        value = groupedByMonth[index + 1] ? groupedByMonth[index + 1][0].rhType === "0" ? "Rh Direto" : "Rh Indireto" : "";
                                    else
                                        value = groupedByMonth[index + 1] ? groupedByMonth[index + 1][0].gpf : "";
                                } else {
                                    var elem = groupedCosts[iname] ? groupedCosts[iname].filter(el => el.costMonth === (index + 1))[0] : "";

                                    if (elem)
                                        value = setBrazilCurrency(elem.cost);
                                }

                                return <TableCell align="center" className={month} key={iname + month + index}>{value}</TableCell>
                            })
                        }
                    </TableRow>
                })
            }
        </TableBody>
    }

    const addOtherCosts = () => {
        return <TableBody>
            {
                otherCosts.map(function (cost) {
                    return <TableRow >
                        <TableCell style={{ fontWeight: "bold" }} component="th" scope="row" key={cost.othercostname}>{cost.othercostname}</TableCell>
                        {
                            monthNames.map(function (month, index) {
                                var value = "R$ ";
                                var elem = cost && cost.costMonth === (index + 1) ? cost.cost : "";

                                if (elem)
                                    value = setBrazilCurrency(elem);

                                return <TableCell align="center" className={month} key={cost.othercostname + month + index}>{value}</TableCell>
                            })
                        }
                    </TableRow>
                })
            }
        </TableBody>
    }

    return (
        <div>
            <Paper className={classes.root}>
                <div className={classes.header}>
                    {t("HRCOSTS.monthlyCosts").toUpperCase()}
                </div>
                <div className={classes.bodyForm}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow style={{ fontWeight: "bold" }}>
                                <TableCell style={{ fontWeight: "bold" }}>{t("HRCOSTS.referenceMonth").toUpperCase()}</TableCell>
                                {
                                    monthNames.map(function (month) {
                                        return <TableCell style={{ fontWeight: "bold" }} data-cost={0} className={month} align="center" key={month}>{month}</TableCell>
                                    })
                                }
                            </TableRow>
                        </TableHead>
                        {createTableBody()}
                        {otherCosts.length > 0 &&
                            addOtherCosts()
                        }
                        {setTotalValues()}
                    </Table>
                </div>
            </Paper>
        </div>
    );
};
export default UserMonthlyCosts;