import React from "react";
import {Text, View} from "@react-pdf/renderer";
import styles from "./Cell.styled";

const Cell = ({text, style, ...props}) => {
    return (
        <View style={[styles.cell, style]} {...props}>
            <Text>{text}</Text>
        </View>
    )
}

export default Cell;
