import React, { createContext, useEffect, useState } from "react";
import InputFilterText from "../inputs/InputFilter/InputFilterText";
import useFilter from "../../hooks/useFilter";
import InputFilterSimpleSelect from "../inputs/InputFilterSimpleSelect/InputFilterSimpleSelect";
import { MultiFilterContainer } from "./MultiFilter.styled";

export const MultiFilterContext = createContext();

export class FilterType {
    static TEXT = "text";
    static SIMPLE_SELECT = "simple-select";
    static DATE = "date";
}

/**
 * MultiFilter component that allows filtering data by multiple columns
 */
const MultiFilter = ({
    columns = [{}],
    rowsData,
    setRowsData,
    setNotFound, // Temp way to give external component access to filter values
    setFiltersUsed, // Temp way to give external component access to filter values
}) => {
    const [filters, setFilters] = useState({});
    const filteredData = useFilter(rowsData, filters);
    const handleFilterChange = event => {
        const { name, value } = event.target;
        setFilters(prevFilters => ({
            ...prevFilters,
            [name]: value,
        }));
    };
    const cleanFilter = filterToClean => {
        const { name } = filterToClean.current;
        setFilters(prevFilters => ({
            ...prevFilters,
            [name]: "",
        }));
    };

    useEffect(() => {
        if (filteredData.length === 0 && Object.keys(filters).length !== 0) {
            let notFoundString = "";
            let columnToFilter;
            Object.keys(filters).forEach((key, _index) => {
                columnToFilter = columns.find(col => col.column === key);
                switch (columnToFilter.filterType) {
                    case FilterType.TEXT:
                        notFoundString = notFoundString.concat(
                            `${filters[key]},`
                        );
                        break;
                    case FilterType.SIMPLE_SELECT:
                        let selectedOption = columnToFilter.options.find(
                            option => option.value.toString() === filters[key]
                        );
                        notFoundString = notFoundString.concat(
                            `${selectedOption.label},`
                        );
                        break;
                    default:
                        throw new Error("Not implemented");
                }
            });
            notFoundString = notFoundString.replace(/,\s*$/, "");
            setNotFound(notFoundString);
        }
    }, [columns, filters, setNotFound, filteredData]);

    useEffect(() => {
        setFiltersUsed(filters);
        setRowsData(filteredData);
    }, [filters, filteredData, setFiltersUsed, setRowsData]);

    return (
        <MultiFilterContainer $numFilters={columns.length}>
            <MultiFilterContext.Provider
                value={{ filters, handleFilterChange, cleanFilter }}
            >
                {columns?.map((column, index) => {
                    switch (column.filterType) {
                        case FilterType.TEXT:
                            return (
                                <InputFilterText
                                    key={index}
                                    column={column.column}
                                    placeholder={column.placeholder}
                                    isMultiFilter={true}
                                />
                            );
                        case FilterType.SIMPLE_SELECT:
                            return (
                                <InputFilterSimpleSelect
                                    key={index}
                                    column={column.column}
                                    placeholder={column.placeholder}
                                    options={column.options}
                                    isMultiFilter={true}
                                />
                            );
                        case FilterType.DATE:
                            return null; // TODO: Implement date filter
                        default:
                            throw new Error("Not implemented");
                    }
                })}
            </MultiFilterContext.Provider>
        </MultiFilterContainer>
    );
};

export default MultiFilter;
