import { Screen } from 'global/constants';

export const hrData = [
    [
        'Colaborador',
        {
            title: 'Minhas Informações',
            description: 'Visualizar suas informações',
            screen: Screen.MY_INFORMATIONS,
            context: 'view',
            url: '/myInformations'
        },
        {
            title: 'Colaboradores',
            description:
                'Visualize, edite, adicione e delete informações dos colaboradores.',
            screen: Screen.COLLABORATORS,
            context: 'view',
            url: '/Collaborators'
        },
        {
            title: 'Aniversariantes',
            description:
                'Visualize os aniversariantes do mês e impressão de banners comemorativos.',
            screen: Screen.BIRTHDAYS,
            context: 'view',
            url: '/birthdays'
        }
    ],
    [
        'Pontos',
        {
            title: 'Meus Pontos',
            description: 'Visualizar e Registrar meus pontos',
            screen: Screen.MY_CLOCK_RECORD,
            context: 'view',
            url: '/MyClockRecord'
        },
        {
            title: 'Minhas Justificativas',
            description: 'Gerenciar suas justificativas de pontos',
            screen: Screen.MY_JUSTIFICATIONS,
            context: 'view',
            url: '/MyJustifications'
        },
        {
            title: 'Pontos dos Colaboradores',
            description: 'Visualizar pontos dos colaboradores',
            screen: Screen.COLLABORATORS_CLOCK_RECORDS,
            context: 'view',
            url: '/CollaboratorsClockRecords'
        },
        {
            title: 'Justificativas dos Colaboradores',
            description: 'Visualizar as justificativas dos seus colaboradores',
            screen: Screen.COLLABORATORS_JUSTIFICATIONS,
            context: 'view',
            url: '/CollaboratorsJustifications'
        }
    ],
    [
        'Férias e Abonos',
        {
            title: 'Minhas Férias',
            description: 'Visualizar suas férias',
            screen: Screen.MY_VACATIONS,
            context: 'view',
            url: '/myVacations'
        },
        {
            title: 'Férias dos Colaboradores',
            description: 'Gerenciar férias dos colaboradores.',
            screen: Screen.COLLABORATORS_VACATIONS,
            context: 'view',
            url: '/CollaboratorsVacations'
        },
        {
            title: 'Calendário de Férias',
            description: 'Visualizar calendário de férias',
            screen: Screen.VACATION_CALENDAR,
            context: 'view',
            url: '/VacationCalendar'
        }
    ],
    [
        'Configurações',
        {
            title: 'Categorias de Atividades',
            description: 'Gerenciar categorias de atividades',
            screen: Screen.ACTIVITY_CATEGORIES,
            context: 'view',
            url: '/ActivityCategories'
        },
        {
            title: 'Cargos',
            description: 'Gerenciar cargos',
            screen: Screen.JOBS,
            context: 'view',
            url: '/Jobs'
        },
        {
            title: 'Feriados e recessos',
            description: 'Gerenciar feriados e recessos',
            screen: Screen.HOLIDAYS_AND_BREAKS,
            context: 'view',
            url: '/HolidaysAndBreaks'
        },
        {
            title: 'Motivos de Abono',
            description:
                'Visualizar e atribuir abonos na jornada de trabalho dos colaboradores.',
            screen: Screen.ALLOWANCE_REASON,
            context: 'view',
            url: '/allowanceReason'
        }
    ]
];

export const patrimonyData = [
    [
        'Itens',
        {
            title: 'Meus itens',
            description: 'Visualizar seus itens',
            screen: Screen.MY_ITENS,
            context: 'view',
            url: '/myItens'
        },
        {
            title: 'Equipamentos',
            description:
                'Visualize, edite, adicione e delete informações dos equipamentos.',
            screen: Screen.EQUIPMENTS,
            context: 'view',
            url: '/InventoryEquipment'
        },
        {
            title: 'Livros',
            description:
                'Visualize, edite, adicione e delete informações dos livros.',
            screen: Screen.BOOKS,
            context: 'view',
            url: '/Books'
        },
        {
            title: 'Softwares',
            description:
                'Visualize, edite, adicione e delete informações dos softwares.',
            screen: Screen.SOFTWARES,
            context: 'view',
            url: '/Software'
        }
    ],
    [
        'Requisições',
        {
            title: 'Minhas requisições',
            description:
                'Visualize, edite, adicione e delete informações de suas requisições.',
            screen: Screen.MY_REQUISITIONS,
            context: 'view',
            url: '/MyRequests'
        },
        {
            title: 'Requisições dos Colaboradores',
            description: 'Gerenciar requisições dos colaboradores.',
            screen: Screen.COLLABORATORS_REQUISITIONS,
            context: 'view',
            url: '/CollaboratorsRequests'
        },
        {
            title: 'Requisições',
            description: 'Gerenciar requisições',
            screen: Screen.REQUISITIONS,
            context: 'view',
            url: '/RequisitionsAdm'
        }
    ],
    [
        'Configurações',
        {
            title: 'Categorias de livros',
            description: 'Gerenciar livros',
            screen: Screen.BOOKS_CATEGORIES,
            context: 'view',
            url: '/bookCategory'
        },
        {
            title: 'Categorias de itens',
            description: 'Gerenciar itens',
            screen: Screen.ITENS_CATEGORIES,
            context: 'view',
            url: '/itemCategory'
        },
        {
            title: 'Categorias de softwares',
            description: 'Gerenciar softwares',
            screen: Screen.SOFTWARES_CATEGORIES,
            context: 'view',
            url: '/SoftwareCategories'
        },
        {
            title: 'Colaboradores externos',
            description: 'Gerenciar colaboradores externos',
            screen: Screen.EXTERNAL_COLLABORATORS,
            context: 'view',
            url: '/ExternalUsers'
        },
        {
            title: 'Locais',
            description: 'Gerenciar locais',
            screen: Screen.PLACES,
            context: 'view',
            url: '/places'
        },
        {
            title: 'Status do item',
            description: 'Gerenciar status do item',
            screen: Screen.ITENS_STATUS,
            context: 'view',
            url: '/InventoryStatus'
        }
    ]
];

export const libraryData = [
    [
        'Livros',
        {
            title: 'Acervo',
            description: 'Visualizar acervo de livros',
            screen: Screen.BOOKS_COLLECTION,
            context: 'view',
            url: '/BooksCollection'
        },
        {
            title: 'Requisições de Biblioteca (Geral)',
            description: 'Gerenciar requisições de livros da biblioteca geral',
            screen: Screen.LIBRARY_REQUISITIONS_GENERAL,
            context: 'view',
            url: '/LibraryRequisitions/general'
        },
        {
            title: 'Requisições de Biblioteca (Geral e Coordenação)',
            description:
                'Gerenciar requisições de livros da biblioteca geral e da coordenação',
            screen: Screen.LIBRARY_REQUISITIONS_ALL,
            context: 'view',
            url: '/LibraryRequisitions/all'
        },
        {
            title: 'Meus Empréstimos',
            description: 'Visualizar empréstimos',
            screen: Screen.LIBRARY_MY_LOANS,
            context: 'view',
            url: '/Library/MyLoans'
        },
        {
            title: 'Empréstimos da Biblioteca',
            description: 'Visualizar empréstimos vigentes',
            screen: Screen.LIBRARY_LOANS,
            context: 'view',
            url: '/Library/Loans'
        },
        {
            title: 'Multas',
            description: 'Visualizar multas',
            screen: Screen.FINES,
            context: 'view',
            url: '/Library/Fines'
        }
    ],
    [
        'Configurações',
        {
            title: 'Biblioteca',
            description:
                'Configurações gerais dos parâmetros do módulo de biblioteca',
            screen: Screen.LIBRARY_CONFIGURATION,
            context: 'view',
            url: '/LibraryConfiguration'
        }
    ]
];

export const quotasData = [
    [
        'Informações',
        {
            title: 'Início',
            description: 'Informações gerais sobre as cotas',
            screen: Screen.QUOTAS_DASHBOARD_PERSONAL,
            context: 'view',
            url: '/Quotas/Dashboard'
        }
    ],
    [
        'Configurações',
        {
            title: 'Cargos',
            description: 'Editar valor pago por cargo',
            screen: Screen.QUOTAS_JOBS,
            context: 'view',
            url: '/Quotas/Jobs'
        },
        {
            title: 'Usuários',
            description: 'Informações sobre os usuários no módulo de cotas',
            screen: Screen.QUOTAS_USERS,
            context: 'view',
            url: '/Quotas/Users'
        },
        {
            title: 'Ferramentas',
            description: 'Ferramentas do módulo',
            screen: Screen.QUOTAS_TOOLS,
            context: 'view',
            url: '/Quotas/Tools'
        }
    ]
];

export const adminData = [
    [
        'Informações',
        {
            title: 'Usuários',
            description:
                'Visualizar, Adicionar, Editar e Desativar Usuários do Sistemas',
            screen: Screen.ADMIN_USERS,
            context: 'view',
            url: '/Admin/Users'
        },
        {
            title: 'Perfis',
            description: 'Gerenciar perfis',
            screen: Screen.ADMIN_PROFILES,
            context: 'view',
            url: '/Admin/ProfileManagement'
        }
    ]
];
