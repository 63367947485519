import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Represents a sidebar list item component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.text - The text to display in the list item.
 * @param {string} props.to - The URL to navigate to when the list item is clicked.
 * @param {boolean} [props.exact=true] - Specifies whether the URL must match exactly for the list item to be active.
 * @returns {JSX.Element} The rendered sidebar list item.
 */
const SidebarListItem = ({ text, to, exact = true }) => {
    const routeIsActive = () => {
        return exact ? window.location.pathname === to : window.location.pathname.includes(to);
    }

    return (
        <li className="barList" style={routeIsActive() ? { backgroundColor: "rgba(255, 255, 255, 0.09)" } : {}}>
            <span
                className="menuSelectStyle"
                style={routeIsActive() ? { display: "block" } : {}}
            ></span>
            <Link to={to}>
                <span className="alinha">
                    &bull;&nbsp; {text}
                </span>
            </Link>
        </li>
    );
}

export default SidebarListItem;
