import React, { useEffect, useState, useContext } from 'react';
import '../../../App.css';
import SideBar from 'components/layout/sidebar/Sidebar';
import DashboardNavBar from 'components/layout/DashboardNavBar';
import AwaitingListManagement from './AwaitingListManagement';
import RequisitionsContext from 'context/libraryModule/requisitions/requisitionsContext';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContentWrapper from 'components/layout/Message';

const CallLibraryAwaitingListManagement = ({ match }) => {
    const requisitionsContext = useContext(RequisitionsContext);

    const {
        hasChanged,
        responseType,
        response,
        successiveActions,
        setHasChanged
    } = requisitionsContext;

    const [snackBar, setSnackBar] = useState({
        open: false,
        kind: '',
        content: ''
    });

    const handleSnack = () => {
        setSnackBar({ ...snackBar, open: true });
        setHasChanged();
    };
    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBar({ ...snackBar, open: false });
    };

    useEffect(() => {
        if (responseType === 'SUCCESS' && hasChanged > successiveActions) {
            handleSnack({ kind: 'success', content: response });
        }
        if (responseType === 'ERROR' && hasChanged > successiveActions) {
            handleSnack({ kind: 'error', content: response });
        }

        return () => {};
    }, [responseType, response, successiveActions]);

    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={snackBar.open}
                onClose={handleSnackClose}
                TransitionComponent={Slide}
                transitionDuration={{ enter: 500, exit: 500 }}
                ContentProps={{
                    'aria-describedby': 'message-id'
                }}
                autoHideDuration={2000}
            >
                <MySnackbarContentWrapper
                    onClose={handleSnackClose}
                    variant={
                        responseType !== '' ? responseType.toLowerCase() : null
                    }
                    message={
                        <span id="message-id">
                            {response !== '' ? response : null}
                        </span>
                    }
                />
            </Snackbar>
            <DashboardNavBar />
            <SideBar />
            <AwaitingListManagement BookId={match.params.id} />
        </div>
    );
};

export default CallLibraryAwaitingListManagement;
