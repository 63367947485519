/* eslint-disable */
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from '@material-ui/core/Grid';
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import Attachment from "@material-ui/icons/Attachment";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import RefreshIcon from '@material-ui/icons/Refresh';
import axios from "axios";
import Footer from "components/layout/Footer";
import Spinner from "components/layout/spinners/Index";
import InventoryContext from "context/inventory/inventoryContext";
import React, { useContext, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import { useStyles } from "components/layout/CommonStyles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import Cookies from "js-cookie";

const tooltipTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#2c3e51"
        }
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                color: "white",
                fontSize: "1em",
                backgroundColor: "red",
                opacity: "0.7"
            }
        }
    }
});

const ViewBook = props => {
    const { BookId } = props;
    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }
    const classes = useStyles();
    const [t] = useTranslation();
    const inventoryContext = useContext(InventoryContext);
    const [hasUpdatedFile, setHasUpdatedFile] = useState(false);
    const [mustRedirect, setMustRedirect] = useState(false);
    const [titleName, setTitle] = useState("GENERAL.requiredField");
    const [categoriesArray, setCategoriesArray] = useState([]);
    const [isbn, setIsbn] = React.useState("");
    const [requiredTitle, setRequiredTitle] = useState(false);
    const [requiredCategory, setRequiredCategory] = useState(false);
    const [requiredAuthor, setRequiredAuthor] = useState(false);
    const [requiredEdition, setRequiredEdition] = useState(false);
    const [validIsbn, setValidIsbn] = useState(false);
    const [validIsbnChange, setValidIsbnChange] = useState(true);
    const [isbnNotFound, setIsbnNotFound] = useState(false);
    const [createOnlyExemplay, setCreateOnlyExemplay] = useState(false);
    const [files, setFiles] = useState([]);
    const [titleIsbn, setTitleIsbn] = useState("GENERAL.requiredField");

    const [values, setValues] = useState({
        id: "",
        image: "",
        isbn: "",
        title: "",
        subTitle: "",
        authors: [""],
        edition: "",
        publisher: "",
        language: "",
        category: "",
        synthesis: "",
        bookCategoryId: ""
    });

    const {
        bookCategories,
        getBookCategories,
        getIsbnAndTitles,
        isbnAndTitle,
        getBook,
        book,
        inventoryImage,
        getInventoryImage,
        UpdateBook,
        loading,
        response,
        getAuthors,
        authors,
    } = inventoryContext;

    const [toDeleteFile, setToDeleteFile] = useState([]);

    useEffect(() => {
        getBookCategories();
        getIsbnAndTitles();
        getAuthors();
    }, []);

    useEffect(() => {
        getBook(BookId);
        getInventoryImage(BookId);
        setIsbn(book.isbn);

        setValues({
            ...values,
            image: inventoryImage[0] ? inventoryImage[0].imagePath + "/" + inventoryImage[0].imageName : "",
            isbn: book.isbn,
            title: book.title,
            subTitle: book.subtitle ? book.subtitle : "-",
            publisher: book.publisher,
            language: book.language ? book.language : "-",
            edition: book.edition,
            bookCategoryId: book.categoryId,
            synthesis: book.synopsis ? book.synopsis : "-",
            authors: book.booksAuthors && book.booksAuthors.length > 0 ? book.booksAuthors.map(x => x.authors.abbreviations) : [""],
            edition: book.edition,
        });
    }, [JSON.stringify(inventoryImage).length, JSON.stringify(book).length]);

    const transitions = useTransition(loading, null, {
        from: { opacity: 0 },
        enter: {
            opacity: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "calc(100vh - 70px)"
        },
        leave: { opacity: 0 }
    });

    const sortElement = (elemnts) => {
        elemnts.sort(function (a, b) {
            if (a.name === null) a.name = "";
            if (b.name === null) b.name = "";

            if (a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() >
                b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim()) {
                return 1;
            }
            if (a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() <
                b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim()) {
                return -1;
            }
            // a must be equal to b
            return 0;
        });
    }

    useEffect(() => {
        const categories = [...bookCategories];
        sortElement(categories);

        var aux = {};
        var _categories = [];
        for (var i = 0; i < categories.length; i++) {
            var item = categories[i];
            aux.value = item.id;
            aux.label = item.name;
            _categories.push(aux);
            aux = {};
        }
        setCategoriesArray(_categories);
    }, [bookCategories]);

    function clean_values() {
        setValues({
            ...values,
            image: "",
            title: "",
            subTitle: "",
            authors: [""],
            edition: "",
            publisher: "",
            language: "",
            category: "",
            synthesis: "",
        });
    }

    function runIsbn(valor) {
        let res = true;
        var isbn = valor.replace(/\D/g, "");
        if (isbn !== "") {
            var validaisbn = /^(?:ISBN(?:-1[03])?:? )?(?=[0-9X]{10}$|(?=(?:[0-9]+[- ]){3})[- 0-9X]{13}$|97[89][0-9]{10}$|(?=(?:[0-9]+[- ]){4})[- 0-9]{17}$)(?:97[89][- ]?)?[0-9]{1,5}[- ]?[0-9]+[- ]?[0-9]+[- ]?[0-9X]$/;
            if (validaisbn.test(isbn)) {
                // Remove non ISBN digits, then split into an array
                var chars = valor.replace(/[- ]|^ISBN(?:-1[03])?:?/g, "").split("");
                // Remove the final ISBN digit from `chars`, and assign it to `last`
                var last = chars.pop();
                var sum = 0;
                var check, i;

                if (chars.length == 9) {
                    // Compute the ISBN-10 check digit
                    chars.reverse();
                    for (i = 0; i < chars.length; i++) {
                        sum += (i + 2) * parseInt(chars[i], 10);
                    }
                    check = 11 - (sum % 11);
                    if (check == 10) {
                        check = "X";
                    } else if (check == 11) {
                        check = "0";
                    }
                } else {
                    // Compute the ISBN-13 check digit
                    for (i = 0; i < chars.length; i++) {
                        sum += (i % 2 * 2 + 1) * parseInt(chars[i], 10);
                    }
                    check = 10 - (sum % 10);
                    if (check == 10) {
                        check = "0";
                    }
                }
                if (check == last) {
                    //alert("Valid ISBN");
                    axios.get(`https://www.googleapis.com/books/v1/volumes?q=isbn:${isbn}`, {
                        headers: {
                            "Content-type": "application/json",
                            "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
                        }
                    }).then(res => {
                        var response = res.data.items ? res.data.items[0] : "";
                        if (res.data.totalItems !== 0) {
                            var language = response.volumeInfo && response.volumeInfo.language
                            var formatedLanguage = ""
                            if (language === "en") {
                                formatedLanguage = "English"
                            } else if (language === "pt") {
                                formatedLanguage = "Português"
                            } else {
                                formatedLanguage = language
                            }
                            setValues({
                                ...values,
                                image: response.volumeInfo.imageLinks && response.volumeInfo.imageLinks.smallThumbnail ? response.volumeInfo.imageLinks.smallThumbnail : values.image,
                                title: response.volumeInfo && response.volumeInfo.title,
                                subTitle: response.volumeInfo && response.volumeInfo.subtitle,
                                authors: (response.volumeInfo && response.volumeInfo.authors && response.volumeInfo.authors.length) ? response.volumeInfo.authors : [""],
                                publisher: response.volumeInfo && response.volumeInfo.publisher,
                                language: formatedLanguage,
                                synthesis: response.volumeInfo && response.volumeInfo.description,
                            });

                            response.volumeInfo && response.volumeInfo.authors && setRequiredAuthor(false);
                            response.volumeInfo && response.volumeInfo.title && setRequiredTitle(false);
                            setIsbnNotFound(false);
                        } else {
                            setIsbnNotFound(true);
                        }

                        setHasUpdatedFile(false);
                        setValidIsbn(false);
                        res = true
                    });
                } else {
                    setValidIsbn(true);
                    setTitleIsbn("ISBN Invalido")
                    setIsbnNotFound(false);
                    clean_values();
                    res = false
                }
            } else {
                setValidIsbn(true);
                setTitleIsbn("ISBN Invalido")
                setIsbnNotFound(false);
                clean_values();
                res = false
            }
        }
        return res
    }

    const image = files.map(file => (
        <div className={classes.thumb} key={file.name}>
            <div className={classes.thumbInner}>
                <img
                    src={file.preview}
                    className={classes.imgNewEquipment}
                />
            </div>
        </div>
    ));

    const handleChange = (name, value) => {
        if (name === "edition") {
            if (!isNaN(value)) {
                setValues({ ...values, [name]: value });
            }
        } else {
            setValues({ ...values, [name]: value });
        }
    };

    const handleChangeAuthors = (indexField, newValue) => {
        const author = newValue ? newValue : "";
        if (author.length < 50) {
            const newAuthors = [...values.authors];
            newAuthors[indexField] = author;
            setValues({ ...values, authors: newAuthors });
        }
        setRequiredAuthor(false);
    }

    const addRowAuthor = () => {
        const newAuthors = [...values.authors];
        newAuthors.push("");
        setValues({ ...values, authors: newAuthors });
    }

    const removeRowAuthor = (indexField) => () => {
        const newAuthors = [...values.authors];
        const newAuthorsFilter = newAuthors.filter((x, i) => i !== indexField);
        setValues({ ...values, authors: newAuthorsFilter });
    }

    const redirect = go => {
        if (go) return <Redirect to={`/Books`} />;
    };

    const handleChangeFindIsbn = () => {
        validIsbnChange && runIsbn(isbn);
    };

    let data = [];
    data = isbnAndTitle;
    const handleChangeIsbn = (value) => {
        setIsbn(value);
        if (!isNaN(value)) {
            data = data.filter(item => (item.isbn && item.isbn.trim().toLocaleLowerCase()) === value.trim().toLocaleLowerCase()
                && item.id != BookId);
        } else {
            data = data.filter(item => (item.title && item.title.trim().toLocaleLowerCase()) === value.trim().toLocaleLowerCase());
        }
        if (data.length !== 0) {
            setValidIsbn(true);
            setValidIsbnChange(false);
            setTitleIsbn(t("INVENTORY.bookUnsuccessfullyCreatedIsbnAlreadyExists"));
        } else {
            setValidIsbnChange(true);
            setValidIsbn(false);
        }
    };

    const onDrop = acceptedFiles => {
        if (acceptedFiles[0] !== undefined) {
            setHasUpdatedFile(true);
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    };

    const verifyCanSubmit = () => {
        let res = true;

        if (!values.title) {
            res = false;
            setRequiredTitle(true);
        }
        if (!isbn) {
            res = false;
            setValidIsbn(true);
            setTitleIsbn("GENERAL.requiredField");
        }

        if (!validIsbnChange) {
            res = false;
            //setValidIsbn(true);
            // setTitleIsbn("INVENTORY.bookUnsuccessfullyCreatedIsbnAlreadyExists");
        }

        if (validIsbnChange && !runIsbn(isbn)) {
            res = false;
            //setValidIsbn(true);
        }
        if (values.authors.some(x => !x)) {
            res = false;
            setRequiredAuthor(true);
        }
        if (!values.edition) {
            res = false;
            setRequiredEdition(true);
        }
        if (!values.bookCategoryId) {
            res = false;
            setRequiredCategory(true);
        }
        return res;
    };

    const submit = () => {
        const canSubmit = verifyCanSubmit();
        if (canSubmit) {

            let formData = new FormData();
            // Adding equipment image
            if (files[0]) {
                formData.append("image", files[0]);
            } else {
                if (values.image) {
                    formData.append("imageUrl", values.image);
                }
            }

            formData.append(`book.id`, BookId);
            formData.append(`book.title`, values.title);
            formData.append(`book.subTitle`, values.subTitle);
            formData.append(`book.categoryId`, values.bookCategoryId);
            formData.append(`book.isbn`, isbn);
            formData.append(`book.edition`, values.edition);
            formData.append(`book.publisher`, values.publisher);
            formData.append(`book.language`, values.language);
            formData.append(`book.synopsis`, values.synthesis);

            // Adding authors
            const authorsAdd = values.authors.filter(name => name && !(book.booksAuthors && book.booksAuthors.length > 0 && book.booksAuthors.some(x => x.authorName === name)));
            for (let idx in authorsAdd) {
                const authorName = authorsAdd[idx];
                if (authorName) {
                    const registeredAuthor = authors.find(x => x.name === authorName);
                    if (registeredAuthor) {
                        formData.append(`authorsAdd[${idx}].id`, registeredAuthor.id);
                    }
                    formData.append(`authorsAdd[${idx}].fullName`, authorName);
                    formData.append(`authorsAdd[${idx}].abbreviations`, authorName);
                    
                }
            }
            // Delete authors
            if (book.booksAuthors && book.booksAuthors.length > 0) {
                const booksAuthorsDelete = book.booksAuthors.filter(item => !values.authors.includes(item.authors.fullName));
                for (let idx in booksAuthorsDelete) {
                    const bookAuthor = booksAuthorsDelete[idx];
                    formData.append(`booksAuthorsDelete[${idx}].id`, bookAuthor.id);
                    formData.append(`booksAuthorsDelete[${idx}].authorId`, bookAuthor.authorId);
                    formData.append(`booksAuthorsDelete[${idx}].bookId`, bookAuthor.bookId);
                }
            }


            UpdateBook(formData, BookId);
            setMustRedirect(true);
        }
    };

    return transitions.map(({ item, key, props }) =>
        item ? (
            <animated.div key={key} style={props}>
                <Spinner />;
            </animated.div>
        ) : (
            <animated.div key={key} style={props}>
                {redirect(mustRedirect)}
                <div className="container">
                    <Breadcrumbs
                        className={classes.breadCrumbs}
                        aria-label="breadcrumb"
                        separator={<NavigateNextIcon fontSize="small" />}
                    >
                        <Typography>{t("INVENTORY.inventory")}</Typography>
                        <Link className={classes.link} to="/Books">
                            <Typography>{t("INVENTORY.books")}</Typography>
                        </Link>
                        <Typography>{t("INVENTORY.editBook")}</Typography>
                    </Breadcrumbs>
                    <div className="container-header">
                        <div className="title">
                            <h1 className={classes.title}>{t("INVENTORY.editBook")}</h1>
                        </div>
                        <Button
                            className={classes.blueButton}
                            variant="contained"
                            color="primary"
                            onClick={submit}
                        >
                            {t("GENERAL.update")}
                        </Button>
                    </div>
                    <Paper >
                        <div className="cabecalho">
                            {t("INVENTORY.BookUData").toUpperCase()}
                        </div>
                        <div className={classes.bodyNewEquipment}>
                            <Grid container spacing={3}>
                                <Grid item xs={2} className={classes.backgroundImage}>
                                    <Grid item xs={2}>
                                        <Dropzone
                                            variant="outlined"
                                            component="span"
                                            className={classes.textField}
                                            onDrop={onDrop}
                                            accept="image/jpeg, 
                                                        image/png, 
                                                        image/bmp"
                                        >
                                            {({
                                                getRootProps,
                                                getInputProps,
                                                isDragActive,
                                                isDragReject
                                            }) => (
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    {!isDragActive && (
                                                        <FormControl
                                                            className={classes.customAttachedDocumentTextField}
                                                        >
                                                            {!hasUpdatedFile && values.image === "" ?
                                                                <Grid item xs={2}>
                                                                    <div className={classes.thumb} key={"upload-equipament-image.jpg"}>
                                                                        <div className={classes.thumbInner}>
                                                                            <img className={classes.imgNewEquipment} alt="add-image" src={window.location.origin + '/img/upload-equipament-image.png'} />
                                                                        </div>
                                                                    </div>
                                                                    <div className={classes.attachImgBook}>{t("Carregar imagem")}</div>
                                                                </Grid>
                                                                :
                                                                !hasUpdatedFile && values.image !== "" ?
                                                                    <Grid item xs={2}>
                                                                        <img className={classes.imgNewEquipment} alt="add-image" src={values.image} />
                                                                        <div className={classes.attachImgBook}>{t("Alterar imagem")}</div>
                                                                    </Grid>
                                                                    :
                                                                    <Grid item xs={2}>
                                                                        {image}
                                                                        <div className={classes.attachImgBook}>{t("Alterar imagem")}</div>
                                                                    </Grid>
                                                            }
                                                        </FormControl>
                                                    )}
                                                    {isDragReject && (
                                                        <FormControl
                                                            className={classes.customAttachedDocumentTextField}
                                                        >
                                                            <Input
                                                                id="custom-attached-input-cooperation"
                                                                readOnly={true}
                                                                endAdornment={
                                                                    <InputAdornment
                                                                        style={{ transform: "rotate(270deg)" }}
                                                                        position="end"
                                                                    >
                                                                        <Attachment />
                                                                    </InputAdornment>
                                                                }
                                                                aria-describedby="weight-helper-text"
                                                                inputProps={{
                                                                    "aria-label": "weight"
                                                                }}
                                                                value={t(title)}
                                                            />
                                                        </FormControl>
                                                    )}
                                                </div>
                                            )}
                                        </Dropzone>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={7}>
                                    <div style={{ display: !createOnlyExemplay ? "none" : "" }} className={classes.tipAlreadyTitleCreate} >
                                        <Typography>{t("INVENTORY.titleAlreadyCreate")}</Typography>
                                    </div>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={5}>
                                            <MuiThemeProvider theme={tooltipTheme}>
                                                <Tooltip
                                                    TransitionComponent={Zoom}
                                                    open={validIsbn}
                                                    title={t(titleIsbn)}
                                                >
                                                    <TextField
                                                        className={classes.textField}
                                                        label={t("INVENTORY.isbn")}
                                                        value={isbn}
                                                        onChange={(ev) => { handleChangeIsbn(ev.target.value) }}
                                                        margin="normal"
                                                    />
                                                </Tooltip>
                                            </MuiThemeProvider>
                                            <div style={{ display: !isbnNotFound ? "none" : "" }}>
                                                <Typography>{t("INVENTORY.isbnNotFound")}</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={5}>
                                            <Button
                                                className={classes.grayButton}
                                                variant="contained"
                                                onClick={handleChangeFindIsbn}
                                            >
                                                <div className={classes.buttonIcon}>
                                                    <RefreshIcon />
                                                </div>
                                                {t("GENERAL.update")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <MuiThemeProvider theme={tooltipTheme}>
                                            <Tooltip
                                                TransitionComponent={Zoom}
                                                open={requiredTitle}
                                                title={t(titleName)}
                                            >
                                                <TextField
                                                    className={classes.textField}
                                                    label={t("INVENTORY.title") + "*"}
                                                    value={values.title}
                                                    onChange={(ev) => { if (ev.target.value.length <= 50) handleChange("title", ev.target.value), setRequiredTitle(false) }}
                                                    margin="normal"
                                                />
                                            </Tooltip>
                                        </MuiThemeProvider>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={10}>
                                            <TextField
                                                className={classes.textField}
                                                label={t("INVENTORY.subTitle")}
                                                value={values.subTitle}
                                                onChange={(ev) => { if (ev.target.value.length <= 50) handleChange("subTitle", ev.target.value) }}
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <MuiThemeProvider theme={tooltipTheme}>
                                                <Tooltip
                                                    TransitionComponent={Zoom}
                                                    open={requiredEdition}
                                                    title={t(titleName)}
                                                >
                                                    <TextField
                                                        className={classes.textField}
                                                        label={t("INVENTORY.edition") + "*"}
                                                        value={values.edition}
                                                        onChange={(ev) => { if (ev.target.value.length <= 50) handleChange("edition", ev.target.value), setRequiredEdition(false) }}
                                                        margin="normal"
                                                    />
                                                </Tooltip>
                                            </MuiThemeProvider>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        {values.authors.map((aut, idx) =>
                                            <>
                                                <Grid item xs={11} sm={11}>
                                                    <MuiThemeProvider theme={tooltipTheme}>
                                                        <Tooltip
                                                            key={idx}
                                                            TransitionComponent={Zoom}
                                                            open={requiredAuthor && !aut}
                                                            title={t(titleName)}
                                                        >
                                                            <Autocomplete
                                                                id="combo-box-demo"
                                                                freeSolo
                                                                options={authors.map(opt => opt.name).filter(opt => opt.includes(aut))}
                                                                value={aut}
                                                                onChange={(event, newInputValue) => {
                                                                    handleChangeAuthors(idx, newInputValue);
                                                                }}
                                                                onInputChange={(event, newInputValue) => {
                                                                    handleChangeAuthors(idx, newInputValue);
                                                                }}
                                                                renderInput={(params) =>
                                                                    <TextField
                                                                        {...params}
                                                                        label={t("INVENTORY.BookAuthor") + "*"}
                                                                        margin="normal"
                                                                        className={classes.textField}
                                                                        classes={{
                                                                            root: classes.inputRoot,
                                                                            input: classes.inputInput
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        </Tooltip>
                                                    </MuiThemeProvider>
                                                </Grid>
                                                <Grid item xs={1} sm={1}>
                                                    {(values.authors.length > 1) &&
                                                        <Tooltip
                                                            title="Remover autor"
                                                            aria-label="remover"
                                                            placement="top"
                                                        >
                                                            <IconButton
                                                                style={{ float: "right", marginTop: "20px" }}
                                                                onClick={removeRowAuthor(idx)}
                                                                className={classes.icons}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    }
                                                </Grid>
                                            </>
                                        )}
                                        <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Typography
                                                className={classes.link}
                                                onClick={addRowAuthor}
                                                style={{ cursor: 'pointer', fontSize: 14 }}
                                            >
                                                Adicionar novo Autor
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            className={classes.textField}
                                            label={t("INVENTORY.publisher")}
                                            value={values.publisher}
                                            onChange={(ev) => { if (ev.target.value.length <= 50) handleChange("publisher", ev.target.value) }}
                                            margin="normal"
                                        />

                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            className={classes.textField}
                                            label={t("INVENTORY.language")}
                                            value={values.language}
                                            onChange={(ev) => { if (ev.target.value.length <= 50) handleChange("language", ev.target.value) }}
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <MuiThemeProvider theme={tooltipTheme}>
                                            <Tooltip
                                                TransitionComponent={Zoom}
                                                open={requiredCategory}
                                                title={t("GENERAL.requiredField")}
                                            >
                                                <TextField
                                                    select
                                                    id="client"
                                                    label={t("INVENTORY.category") + "*"}
                                                    name="category"
                                                    value={values.bookCategoryId}
                                                    onChange={(ev) => {
                                                        setRequiredCategory(false)
                                                        handleChange("bookCategoryId", ev.target.value)
                                                    }}
                                                    className={classes.textField}
                                                    margin="normal"
                                                    classes={{
                                                        root: classes.inputRoot,
                                                        input: classes.inputInput
                                                    }}
                                                    inputProps={{ "aria-label": "search" }}
                                                >
                                                    {categoriesArray.map(category => (
                                                        <MenuItem key={category.value} value={category.value}>
                                                            {category.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Tooltip>
                                        </MuiThemeProvider>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            multiline
                                            fullWidth
                                            rows="6"
                                            variant="outlined"
                                            className={classes.textField}
                                            label={t("INVENTORY.synthesis")}
                                            value={values.synthesis}
                                            margin="normal"
                                            onChange={(ev) => handleChange("synthesis", ev.target.value)}
                                            inputProps={{
                                                maxLength: 4096
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Paper>
                </div>
                <Footer />
            </animated.div >
        )
    );
};

export default ViewBook;