import React from "react";
import "../../../../App.css";
import SideBar from "components/layout/sidebar/Sidebar";
import DashboardNavBar from "components/layout/DashboardNavBar";
import NewSoftwareAndLicense from "./NewSoftwareAndLicense";

const CallNewSoftwareAndLicense = ({ match }) => {
  return (
    <div>
      <DashboardNavBar />
      <SideBar />
      <NewSoftwareAndLicense id={match.params.id}/>
    </div>
  );
}

export default CallNewSoftwareAndLicense;