/* eslint-disable */
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import SearchIcon from "@material-ui/icons/Search";
import Dissatisfied from "@material-ui/icons/SentimentVeryDissatisfied";
import Footer from "components/layout/Footer";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";
import MySnackbarContentWrapper from "components/layout/Message";
import SettingsContext from "context/hrModule/settings/settingsContext";
import AllowancesContext from "context/allowance/allowanceContext";
import Transition from "components/layout/transition/Transition";
import Breadcrumb from "components/layout/breadcrumb/Breadcrumb";
import InputFilter from "components/inputs/InputFilter";
import FilterContainer from "components/containers/FilterContainer";
import Close from "@material-ui/icons/Close";
import InputAdornment from '@material-ui/core/InputAdornment';
import JobsTable from "./JobsTable";
import DialogAddJob from "./DialogAddJob";
import { GetGeneralPermissionForAction } from "utils/permissions/GetGeneralPermissionForAction";
import { Screen, Allow } from "global/constants";



const Jobs = (props) => {
    const classes = useStyles();
    const [t] = useTranslation();
    const settingsContext = useContext(SettingsContext);
    const allowanceContext = useContext(AllowancesContext);
    const [findText, setFindText] = useState("");
    const [filterName, setFilterName] = useState("");
    const [dataFilter, setDataFilter] = useState([]);
    const [open, setOpen] = useState(false);
    const [canClick, setCanClick] = useState(false);

    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }
    const [snackBar, setSnackBar] = useState({
        open: false,
        kind: "",
        content: ""
    });

    const {
        loading,
        hasChanged,
        responseType,
        response,
        successiveActions,
        setHasChanged,
        jobs,
        setLoading,
        setLoadingFalse,
        getJobs
    } = settingsContext;

    const {
        allowanceResponse,
        allowanceResponseType,
        resetAllowanceMessage,
        messageHasChanged,
    } = allowanceContext;

    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleSnack = () => {
        setSnackBar({ ...snackBar, open: true });
        setHasChanged();
    };
    const handleSnackClose = () => {
        setSnackBar({ ...snackBar, open: false });
        resetAllowanceMessage();
    };


    useEffect(() => {

        if (allowanceResponseType === "ERROR" && messageHasChanged) {
            handleSnack({ kind: "error", content: allowanceResponse });
        }

        if (responseType === "SUCCESS" && hasChanged > successiveActions) {
            handleSnack({ kind: "success", content: response });
        }
        if (responseType === "ERROR" && hasChanged > successiveActions) {
            handleSnack({ kind: "error", content: response });
        }

        return () => { };
    }, [
        responseType,
        response,
        successiveActions,
        allowanceResponseType,
        allowanceResponse,
    ]);

    async function fetchData(userId) {
        await setLoading();
        await getJobs(userId);
        await setLoadingFalse();
    }
    useEffect(() => {
        fetchData();
    }, [
    ]);

    useEffect(() => {
        if (filterName
        ) { setCanClick(true) }
        else {
            setCanClick(false)
        }
    }, [
        filterName,
    ])

    useEffect(() => {
        if (jobs && jobs.length) {
            const { newDataFilter, textNoFound } = applyFilter(jobs.filter(job => !job.hasDeleted))

            if (newDataFilter.length === 0) {
                setDataFilter([]);
                setHasNoResult(true);
                setFindText(textNoFound);
            } else {
                setDataFilter(newDataFilter);
                setHasNoResult(false);
                setFindText("");
            }
        }
    }, [jobs, filterName]);

    const applyFilter = (jobs) => {
        let newDataFilter = [];
        let textNoFound = "";

        newDataFilter = jobs.filter(job => !job.hasDeleted);

        if (filterName) {
            newDataFilter = newDataFilter.filter(item =>
                item.name.toLowerCase().includes(filterName.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0) textNoFound = filterName;
        }
        return { newDataFilter, textNoFound };
    }

    const hasItens = jobs.length > 0;
    const [hasNoResult, setHasNoResult] = useState(hasItens && dataFilter.length === 0);

    const clearField = () => {
        setFilterName("")
    }


    return (
        <React.Fragment>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={snackBar.open}
                onClose={handleSnackClose}
                TransitionComponent={Slide}
                transitionDuration={{ enter: 500, exit: 500 }}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                autoHideDuration={2000}
            >
                <MySnackbarContentWrapper
                    onClose={handleSnackClose}
                    variant={
                        responseType !== ""
                            ? responseType.toLowerCase()
                            : allowanceResponseType.toLowerCase()
                    }
                    message={
                        <span id="message-id">
                            {response !== "" ? response : allowanceResponse}
                        </span>
                    }
                />
            </Snackbar>
            <Transition loading={loading} newDesign={true}>

                <div className="headerNewDesign">
                    <Breadcrumb
                        newDesign={true}
                        routes={[
                            { label: t("HR.humanResource") , to: "/HumanResources"},
                            { label: t("ROUTES.config") },
                            { label: t("HR.jobs") },
                        ]}
                    />
                    <div className="container-headerNewDesign">
                        <h1 className={classes.titleNewDesign}>{t("HR.jobs")}</h1>
                        {GetGeneralPermissionForAction(Screen.JOBS, Allow.CREATE) && <Button
                            className={classes.blueButton}
                            variant="contained"
                            color="primary"
                            style={{ marginBottom: "10px" }}
                            onClick={handleClickOpen}
                        >
                            {t("HR.addJob")}
                        </Button>}
                    </div>

                </div>
                <div className="containerNewDesign">
                    <Paper >
                        <div className="cabecalho">
                            {t("HR.jobs").toUpperCase()}
                        </div>
                        <React.Fragment>

                        <FilterContainer>
                                <InputFilter
                                    filterValue={filterName}
                                    setFilter={setFilterName}
                                    label={"GENERAL.name"}
                                    maxLength={60}
                                />                                            
                        </FilterContainer>

                            <JobsTable
                                rows={dataFilter}
                                toFirstPage={canClick}
                                headers={["id", "name", "description", "collaboratorsNumber", "canDelete"]}
                                visibleHeaders={["Nome do Cargo", "Descrição", "N° de Colaboradores", ""]}
                                sortedFields={["name", "description", "collaboratorsNumber"]}
                                mainField="name"
                                fetchData={fetchData}
                            />
                            {!hasItens &&
                                <div className={classes.noParticipantsMessage}>
                                    <h2>{t("INVENTORY.noItensMessage")}</h2>
                                    <h4>{t("INVENTORY.noItensSubMessage")}</h4>
                                </div>
                            }
                            {hasNoResult &&
                                <div className={classes.noParticipantsMessage}>
                                    <div >
                                        <Dissatisfied className={classes.notFoundIconStyle} />
                                    </div>
                                    <h2>{t("GENERAL.noResultsFound")}</h2>
                                    <h5>{`Sua busca por "${findText}" não retornou resultados.`}</h5>
                                </div>
                            }
                        </React.Fragment>

                        <DialogAddJob
                            open={open}
                            setOpen={setOpen}
                            jobs={jobs}
                            fetchData={fetchData}
                        />
                    </Paper>
                </div>
                <Footer />
            </Transition>


        </React.Fragment >

    );
};

export default Jobs;