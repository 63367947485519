import React from "react";
import "../../../App.css";
import SideBar from "components/layout/sidebar/Sidebar";
import DashboardNavBar from "components/layout/DashboardNavBar";
import ProfileManagementForm from "./ProfileManagementForm";

const CallViewProfileManagement = ({ match }) => {

    return (
        <div>
            <DashboardNavBar />
            <SideBar />
            <ProfileManagementForm profileId={match.params.id} context={"view"} />
        </div>
    );
}

export default CallViewProfileManagement;