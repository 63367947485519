/***
 *
 *
 *  BEGIN ROUTER TYPES
 *
 *
 */
export const PROFILES = {
    SET_PROFILE: 'SET_PROFILE',
    ADD_PROFILE: 'ADD_PROFILE',
    GET_PROFILE: 'GET_PROFILE',
    GET_PROFILES: 'GET_PROFILES',
    EDIT_PROFILE: 'EDIT_PROFILE',
    SHOW_PROFILE: 'SHOW_PROFILE',
    LIST_PROFILES: 'LIST_PROFILES',
    CREATE_PROFILE: 'CREATE_PROFILE',
    UPDATE_PROFILE: 'UPDATE_PROFILE',
    DELETE_PROFILE: 'DELETE_PROFILE',
    GET_PERMISSIONS: 'GET_PERMISSIONS'
};

export const DEFAULT = {
    CREATE_UPDATE_DELETE: 'CREATE_UPDATE_DELETE',
    SEND_SNACK_NOTIFICATION: 'SEND_SNACK_NOTIFICATION',
    SET_LOADING: 'SET_LOADING',
    SET_LOADING_FALSE: 'SET_LOADING_FALSE',
    SET_LOADING_BOOKS: 'SET_LOADING_BOOKS',
    SET_MESSAGE: 'SET_MESSAGE',
    DASHBOARD: 'DEFAULT_DASHBOARD',
    RESET_MESSAGE: 'RESET_MESSAGE',
    SET_HAS_CHANGED: 'SET_HAS_CHANGED',
    RESET_DELETE_MESSAGE: 'RESET_DELETE_MESSAGE',
    SET_ALLOWANCE_MESSAGE: 'SET_ALLOWANCE_MESSAGE',
    RESET_ALLOWANCE_MESSAGE: 'RESET_ALLOWANCE_MESSAGE',
    SET_LOADING_SOFTWARE: 'SET_LOADING_SOFTWARE',
    SET_LOADING_EQUIPMENT: 'SET_LOADING_EQUIPMENT',
    SET_LOADING_EQUIPMENT_FALSE: 'SET_LOADING_EQUIPMENT_FALSE',
    GET_USERS_EMAIL: 'GET_USERS_EMAIL'
};

export const USERS = {
    ADD_USER: 'ADD_USER',
    GET_USER: 'GET_USER',
    SET_USERS: 'SET_USERS',
    GET_USERS: 'GET_USERS',
    SHOW_USER: 'SHOW_USER',
    EDIT_USER: 'EDIT_USER',
    LIST_USERS: 'LIST_USERS',
    CREATE_USER: 'CREATE_USER',
    UPDATE_USER: 'UPDATE_USER',
    DELETE_USER: 'DELETE_USER',
    GET_ALL_USERS: 'GET_ALL_USERS',
    GET_USERS_BY_PERMISSIONS: 'GET_USERS_BY_PERMISSIONS',
    GET_LIBRARY_MANAGER_USERS: 'GET_LIBRARY_MANAGER_USERS',
    GET_LIBRARY_COORDINATION_USERS: 'GET_LIBRARY_COORDINATION_USERS'
};

export const PARTNERS = {
    ADD_PARTNER: 'ADD_PARTNER',
    GET_PARTNER: 'GET_PARTNER',
    GET_PARTNERS: 'GET_PARTNERS',
    SHOW_PARTNER: 'SHOW_PARTNER',
    SET_PARTNERS: 'SET_PARTNERS',
    EDIT_PARTNER: 'EDIT_PARTNER',
    LIST_PARTNERS: 'LIST_PARTNERS',
    CREATE_PARTNER: 'CREATE_PARTNER',
    UPDATE_PARTNER: 'UPDATE_PARTNER',
    DELETE_PARTNER: 'DELETE_PARTNER',
    GET_ADDRESS_BY_GIVEN_CEP: 'GET_ADDRESS_BY_GIVEN_CEP'
};

export const PROJECTS = {
    SEARCH: 'SEARCH',
    GET_GOAL: 'GET_GOAL',
    GET_FILE: 'GET_FILE',
    CHANGE_STEP: 'CHANGE_STEP',
    UPDATE_GOAL: 'UPDATE_GOAL',
    ADD_PROJECT: 'ADD_PROJECT',
    GET_PROJECT: 'GET_PROJECT',
    SHOW_PROJECT: 'SHOW_PROJECT',
    GET_ADDENDUM: 'GET_ADDENDUM',
    SET_PROJECTS: 'SET_PROJECTS',
    EDIT_PROJECT: 'EDIT_PROJECT',
    GET_PROJECTS: 'GET_PROJECTS',
    GET_ALL_PROJECTS: 'GET_PROJECTS',
    LIST_PROJECTS: 'LIST_PROJECTS',
    RESET_PROJECT: 'RESET_PROJECT',
    CREATE_PROJECT: 'CREATE_PROJECT',
    DELETE_PROJECT: 'DELETE_PROJECT',
    DELETE_ADDENDUM: 'DELETE_ADDENDUM',
    SET_TOGGLE_EDIT: 'SET_TOGGLE_EDIT',
    RESET_ADDENDUMS: 'RESET_ADDENDUMS',
    RESET_CRUD_ADDENDUM: 'RESET_CRUD_ADDENDUM',
    GENERAL_INFORMATION: 'GENERAL_INFORMATION',
    SET_PROJECT_CREATED: 'SET_PROJECT_CREATED',
    HANDLE_SUBMIT_SCOPE: 'HANDLE_SUBMIT_SCOPE',
    REDIRECT_TO_STEP_ONE: 'REDIRECT_TO_STEP_ONE',
    TRIGGER_UPDATE_CREATE: 'TRIGGER_UPDATE_CREATE',
    HANDLE_SUBMIT_SCHEDULE: 'HANDLE_SUBMIT_SCHEDULE',
    UPDATE_CREATE_ADDENDUM: 'UPDATE_CREATE_ADDENDUM',
    HANDLE_SUBMIT_HEADINGS: 'HANDLE_SUBMIT_HEADINGS',
    HANDLE_SUBMIT_BUDGET: 'HANDLE_SUBMIT_BUDGET',
    UPDATE_GENERALINFORMATION: 'UPDATE_GENERALINFORMATION',
    RESET_REDIRECT_TO_STEP_ONE: 'RESET_REDIRECT_TO_STEP_ONE',
    SET_IDEAL_NUMBER_OF_CHILDS_UPDATES: 'SET_IDEAL_NUMBER_OF_CHILDS_UPDATES',
    GET_PROJECT_PARTICIPANTS: 'GET_PROJECT_PARTICIPANTS',
    DELETE_PARTICIPANTS: 'DELETE_PARTICIPANTS',
    ADD_PARTICIPANTS: 'ADD_PARTICIPANTS',
    GET_PROJECT_PHASES: 'GET_PROJECT_PHASES',
    DELETE_PHASE: 'DELETE_PHASE',
    ADD_PHASE: 'ADD_PHASE',
    GET_PROJECT_PHASE: 'GET_PROJECT_PHASE',
    UPDATE_PHASE: 'PROJECTS.UPDATE_PHASE',
    ADD_ACTIVITY: 'ADD_ACTIVITY',
    DELETE_ACTIVITY: 'DELETE_ACTIVITY',
    GET_ACTIVITY: 'GET_ACTIVITY',
    UPDATE_ACTIVITY: 'UPDATE_ACTIVITY',
    SET_PANEL_TAB: 'SET_PANEL_TAB',
    SET_VALUE_REQUIRED_MESSAGE: 'SET_VALUE_REQUIRED_MESSAGE',
    SET_VALUE_PANEL_TAB: 'PROJECTS.SET_VALUE_PANEL_TAB',
    SET_ACTION_MODE: 'SET_ACTION_MODE',
    ADD_PARTICIPANTS_ACTIVITY: 'ADD_PARTICIPANTS_ACTIVITY',
    OPEN_MODAL_ALLOCATE: 'OPEN_MODAL_ALLOCATE',
    SET_NEW_ACTIVITY_ID: 'SET_NEW_ACTIVITY_ID',
    SET_OPEN_MODAL: 'SET_OPEN_MODAL',
    GET_EXPENSES: 'GET_EXPENSES',
    SET_DIALOG: 'SET_DIALOG',
    DELETE_PARTICIPANTS_ACTIVITY: 'DELETE_PARTICIPANTS_ACTIVITY',
    UPDATE_PARTICIPANT_ACTIVITY: 'UPDATE_PARTICIPANT_ACTIVITY',
    CREATE_EXPENSE: 'CREATE_EXPENSE',
    DELETE_EXPENSE: 'DELETE_EXPENSE',
    UPDATE_PROJECT_FINISH: 'UPDATE_PROJECT_FINISH',
    HANDLE_SUBMIT_PROJECT_BUDGET: 'HANDLE_SUBMIT_PROJECT_BUDGET',
    HANDLE_SUBMIT_PROJECT_SUBSIDY: 'HANDLE_SUBMIT_PROJECT_SUBSIDY',
    GET_SUBSIDY: 'GET_SUBSIDY',
    DELETE_SUBSIDY: 'DELETE_SUBSIDY',
    UPDATE_SUBSIDY: 'UPDATE_SUBSIDY',
    GET_COST_GPF: 'GET_COST_GPF',
    GET_PARTICIPANT_FEEDBACKS: 'GET_PARTICIPANT_FEEDBACKS',
    DELETE_PARTICIPANT_FEEDBACK: 'DELETE_PARTICIPANT_FEEDBACK',
    ADD_PARTICIPANT_FEEDBACK: 'ADD_PARTICIPANT_FEEDBACK',
    GET_PARTICIPANT_SOLICITATION: 'GET_PARTICIPANT_SOLICITATION',
    GET_PARTICIPANT_PONTOS: 'GET_PARTICIPANT_PONTOS',
    UPDATE_PARTICIPANT_FEEDBACK: 'UPDATE_PARTICIPANT_FEEDBACK',
    COMPLETE_SUBMISSION: 'COMPLETE_SUBMISSION'
};

export const SEARCH = {
    SEARCH: 'SEARCH'
};

export const LOGIN = {
    USER_NAME: 'USER_NAME'
};

export const ALLOWANCE = {
    GET_ALLOWANCES: 'GET_ALLOWANCES',
    GET_ALLALLOWANCES: 'GET_ALLALLOWANCES',
    GET_FORMATTED_ALLOWANCES: 'GET_FORMATTED_ALLOWANCES',
    UPDATE_SHOULD_UPDATE_ALLOWANCES: 'UPDATE_SHOULD_UPDATE_ALLOWANCES'
};

export const HRCOSTS = {
    ADD_USER_COST: 'ADD_USER_COST',
    EDIT_USER_COST: 'EDIT_USER_COST',
    GET_USER_COSTS: 'GET_USER_COSTS',
    GET_USERS_COSTS: 'GET_USERS_COSTS',
    LIST_USERS_COSTS: 'LIST_USERS_COSTS',
    CREATE_USER_COST: 'CREATE_USER_COST',
    UPDATE_USER_COST: 'UPDATE_USER_COST',
    DELETE_USER_COST: 'DELETE_USER_COST',
    SUBMIT_COSTS: 'SUBMIT_COSTS',
    RESET_USER_COSTS: 'RESET_USER_COSTS',
    GET_USER_CONTRACT: 'GET_USER_CONTRACT'
};

export const INVENTORY = {
    GET_MYITENS: 'GET_MYITENS',
    GET_MYSOFTWARES: 'GET_MYSOFTWARES',
    GET_PERMISSIONS: 'GET_PERMISSIONS',
    GET_PLACES: 'GET_PLACES',
    GET_PLACE: 'GET_PLACE',
    ADD_PLACE: 'ADD_PLACE',
    UPDATE_PLACE: 'UPDATE_PLACE',
    DELETE_PLACE: 'DELETE_PLACE',
    GET_BOOKCATEGORIES: 'GET_BOOKCATEGORIES',
    GET_BOOKCATEGORY: 'GET_BOOKCATEGORY',
    ADD_BOOKCATEGORY: 'ADD_BOOKCATEGORY',
    UPDATE_BOOKCATEGORY: 'UPDATE_BOOKCATEGORY',
    DELETE_BOOKCATEGORY: 'DELETE_BOOKCATEGORY',
    GET_ITENSCATEGORIES: 'GET_ITENSCATEGORIES',
    GET_ITEMCATEGORY: 'GET_ITEMCATEGORY',
    ADD_ITEMCATEGORY: 'ADD_ITEMCATEGORY',
    UPDATE_ITEMCATEGORY: 'UPDATE_ITEMCATEGORY',
    DELETE_ITEMCATEGORY: 'DELETE_ITEMCATEGORY',
    GET_ALLSTATUS: 'GET_ALLSTATUS',
    GET_ITEMSTATUS: 'GET_ITEMSTATUS',
    ADD_ITEMSTATUS: 'ADD_ITEMSTATUS',
    UPDATE_ITEMSTATUS: 'UPDATE_ITEMSTATUS',
    DELETE_ITEMSTATUS: 'DELETE_ITEMSTATUS',
    GET_ALLEQUIPMENT: 'GET_ALLEQUIPMENT',
    GET_SUBITENS: 'GET_SUBITENS',
    ADD_SUBITENS: 'ADD_SUBITENS',
    DELETE_SUBITENS: 'DELETE_SUBITENS',
    GET_EXTERNAL_PARTNERS: 'GET_EXTERNAL_PARTNERS',
    ADD_MAINTENANCE: 'ADD_MAINTENANCE',
    GET_MAINTENANCE: 'GET_MAINTENANCE',
    UPDATE_MAINTENANCE: 'UPDATE_MAINTENANCE',
    DELETE_MAINTENANCE: 'DELETE_MAINTENANCE',
    GET_ALLMAINTENANCES: 'GET_ALLMAINTENANCES',
    GET_NEXT_CODES: 'GET_NEXT_CODES',
    GET_HISTORYEQUIPMENT: 'GET_HISTORYEQUIPMENT',
    UPDATE_EQUIPMENT: 'UPDATE_EQUIPMENT',
    ADD_EQUIPMENT: 'ADD_EQUIPMENT',
    GET_EXTERNAL_USER_PLACES: 'GET_EXTERNAL_USER_PLACES',
    GET_EQUIPMENTBYEXTERNALUSER: 'GET_EQUIPMENTBYEXTERNALUSER',
    GET_EXTERNALUSERSHISTORY: 'GET_EXTERNALUSERSHISTORY',
    GET_LSBD_CODES_HISTORY: 'GET_LSBD_CODES_HISTORY',
    GET_BOOKS: 'GET_BOOKS',
    GET_LIBRARY_ADMS: 'GET_LIBRARY_ADMS',
    GET_ISBN_AND_TITLES: 'GET_ISBN_AND_TITLES',
    SET_SEARCH_BOOK: 'SET_SEARCH_BOOK',
    CLEAN_BOOK: 'CLEAN_BOOK',
    GET_BOOK: 'GET_BOOK',
    GET_INVENTORY_IMAGE: 'GET_INVENTORY_IMAGE',
    GET_EXEMPLAR: 'GET_EXEMPLAR',
    CLEAN_EXEMPLAR: 'CLEAN_EXEMPLAR',
    GET_ADMS_PEOPLE_MANAGEMENT: 'GET_ADMS_PEOPLE_MANAGEMENT',
    SAVE_LINK_EQUIPMENT: 'SAVE_LINK_EQUIPMENT',
    SET_SEARCH_EQUIPMENT: 'SET_SEARCH_EQUIPMENT',
    UPDATE_EXEMPLAR: 'UPDATE_EXEMPLAR',
    GET_HISTORYEXEMPLAR: 'GET_HISTORYEXEMPLAR',
    DELETE_BOOK: 'DELETE_BOOK',
    GET_SOFTWARE_CATEGORIES: 'GET_SOFTWARE_CATEGORIES',
    ADD_SOFTWARE_CATEGORY: 'ADD_SOFTWARE_CATEGORY',
    UPDATE_SOFTWARE_CATEGORY: 'UPDATE_SOFTWARE_CATEGORY',
    DELETE_SOFTWARE_CATEGORY: 'DELETE_SOFTWARE_CATEGORY',
    GET_AUTHORS: 'GET_AUTHORS',
    GET_SOFTWARES: 'GET_SOFTWARES',
    UPDATE_DELETE_SOFTWARE: 'UPDATE_DELETE_SOFTWARE',
    UPDATE_DELETE_LICENSE: 'UPDATE_DELETE_LICENSE',
    GET_PLATFORMS: 'GET_PLATFORMS',
    GET_SOFTWARE: 'GET_SOFTWARE',
    UPDATE_SOFTWARE: 'UPDATE_SOFTWARE',
    GET_LICENSE: 'GET_LICENSE',
    SET_SEARCH_SOFTWARE: 'SET_SEARCH_SOFTWARE',
    GET_ITENS_BY_USERS_LICENSE: 'GET_ITENS_BY_USERS_LICENSE',
    GET_MY_REQUESTS: 'GET_MY_REQUESTS',
    GET_LICENSE_BY_USERS: 'GET_LICENSE_BY_USERS',
    GET_ITENS_CATEGORIES_BY_ITENS_AVALIABLE:
        'GET_ITENS_CATEGORIES_BY_ITENS_AVALIABLE',
    GET_ITENS_SOFTWARE_CATEGORIES_BY_LECENSES_AVALIABLE:
        'GET_ITENS_SOFTWARE_CATEGORIES_BY_LECENSES_AVALIABLE',
    DELETE_REQUISITION: 'DELETE_REQUISITION',
    GET_SOFTWARES_ONLY: 'GET_SOFTWARES_ONLY',
    GET_COLLABORATORS_REQUESTS: 'GET_COLLABORATORS_REQUESTS',
    GET_COLLABORATORS_MANAGED: 'GET_COLLABORATORS_MANAGED',
    GET_REQUISITION_BY_ID: 'GET_REQUISITION_BY_ID',
    MANAGER_APPROVE_OR_REFUSE: 'MANAGER_APPROVE_OR_REFUSE',
    GET_REQUISITION: 'GET_REQUISITION',
    GET_LICENSES_ALL: 'GET_LICENSES_ALL',
    GET_APPROVER: 'GET_APPROVER',
    GET_PATRIMONY_MANAGER: 'GET_PATRIMONY_MANAGER',
    GET_ALL_REQUESTS: 'GET_ALL_REQUESTS',
    GET_ITENS_AVAILABLE_BY_CATEGORY: 'GET_ITENS_AVAILABLE_BY_CATEGORY',
    GET_LICENSES_AVAILABLE: 'GET_LICENSES_AVAILABLE',
    SET_LOADING_REQUISITION: 'SET_LOADING_REQUISITION',
    SET_LOADING_REQUISITION_FALSE: 'SET_LOADING_REQUISITION_FALSE'
};

export const EXTERNALUSERS = {
    SET_EXTERNALUSER: 'SET_EXTERNALUSER',
    ADD_EXTERNALUSER: 'ADD_EXTERNALUSER',
    GET_EXTERNALUSER: 'GET_EXTERNALUSER',
    GET_EXTERNALUSERS: 'GET_EXTERNALUSERS',
    EDIT_EXTERNALUSER: 'EDIT_EXTERNALUSER',
    SHOW_EXTERNALUSER: 'SHOW_EXTERNALUSER',
    LIST_EXTERNALUSERS: 'LIST_EXTERNALUSERS',
    CREATE_EXTERNALUSER: 'CREATE_EXTERNALUSER',
    UPDATE_EXTERNALUSER: 'UPDATE_EXTERNALUSER',
    DELETE_EXTERNALUSER: 'DELETE_EXTERNALUSER',
    GET_PERMISSIONS: 'GET_PERMISSIONS',
    GET_PLACESEXTERNALUSER: 'GET_PLACESEXTERNALUSER',
    GET_EXTERNALUSERSHISTORY: 'GET_EXTERNALUSERSHISTORY'
};

export const HR = {
    GET_COLLABORATORS: 'GET_COLLABORATORS',
    GET_INFORMATION_IDENTIFICATION: 'GET_INFORMATION_IDENTIFICATION',
    UPDATE_INFORMATION_IDENTIFICATION: 'UPDATE_INFORMATION_IDENTIFICATION',
    CREATE_INFORMATION_IDENTIFICATION: 'CREATE_INFORMATION_IDENTIFICATION',
    GET_INFORMATION_FAMILY_DATA: 'GET_INFORMATION_FAMILY_DATA',
    UPDATE_INFORMATION_FAMILY_DATA: 'UPDATE_INFORMATION_FAMILY_DATA',
    CREATE_INFORMATION_FAMILY_DATA: 'CREATE_INFORMATION_FAMILY_DATA',
    GET_INFORMATION_CONTACT: 'GET_INFORMATION_CONTACT',
    UPDATE_INFORMATION_CONTACT: 'UPDATE_INFORMATION_CONTACT',
    CREATE_INFORMATION_CONTACT: 'CREATE_INFORMATION_CONTACT',
    GET_BANK_DATA_CONTACT: 'GET_BANK_DATA_CONTACT',
    UPDATE_BANK_DATA_CONTACT: 'UPDATE_BANK_DATA_CONTACT',
    GET_ADMISSION: 'GET_ADMISSION',
    GET_ADMISSION_COMPANIES: 'GET_ADMISSION_COMPANIES',
    GET_ADMISSION_JOBS: 'GET_ADMISSION_JOBS',
    GET_ADMISSION_RULESETS: 'GET_ADMISSION_RULESETS',
    CREATE_ADMISSION: 'CREATE_ADMISSION',
    ADMISSION_FIRE: 'ADMISSION_FIRE',
    UPDATE_ADMISSION: 'UPDATE_ADMISSION',
    GET_COLLABORATORS_DOCUMENTS: 'GET_COLLABORATORS_DOCUMENTS',
    DELETE_DOCUMENT: 'DELETE_DOCUMENT',
    GET_INFORMATION_FORMATION: 'GET_INFORMATION_FORMATION',
    CREATE_JOB: 'CREATE_JOB',
    GET_JOB: 'GET_JOB',
    UPDATE_JOB: 'UPDATE_JOB',
    DELETE_JOB: 'DELETE_JOB',
    GET_PROFILE_BY_USER: 'GET_PROFILE_BY_USER',
    GET_HOLIDAYS_AND_BREAKS: 'GET_HOLIDAYS_AND_BREAKS',
    CREATE_HOLIDAYS_AND_BREAKS: 'CREATE_HOLIDAYS_AND_BREAKS',
    GET_HOLIDAY_OR_BREAK: 'GET_HOLIDAY_OR_BREAK',
    UPDATE_HOLIDAY_OR_BREAK: 'UPDATE_HOLIDAY_OR_BREAK',
    DELETE_HOLIDAYS_AND_BREAKS: 'DELETE_HOLIDAYS_AND_BREAKS',
    GET_COLLABORATORS_VACATIONS: 'GET_COLLABORATORS_VACATIONS',
    GET_COLLABORATOR_VACATION: 'GET_COLLABORATOR_VACATION',
    CREATE_COLLABORATOR_VACATION_PERIOD: 'CREATE_COLLABORATOR_VACATION_PERIOD',
    UPDATE_COLLABORATOR_VACATION_PERIOD: 'UPDATE_COLLABORATOR_VACATION_PERIOD',
    DELETE_COLLABORATOR_VACATION_PERIOD: 'DELETE_COLLABORATOR_VACATION_PERIOD',
    GET_WORKING_HOURS_CONTRACT: 'GET_WORKING_HOURS_CONTRACT',
    GET_CLOCK_IN_BY_USER: 'GET_CLOCK_IN_BY_USER',
    UPDATE_CLOCK_RECORD: 'UPDATE_CLOCK_RECORD',
    CREATE_PROFILE: 'CREATE_PROFILE',
    GET_ROLE_MODULES: 'GET_ROLE_MODULES',
    GET_PROFILE: 'GET_PROFILE',
    UPDATE_PROFILE: 'UPDATE_PROFILE',
    GET_ALLOWANCE_REASON: 'GET_ALLOWANCE_REASON',
    GET_ALL_ALLOWANCE_REASONS: 'GET_ALL_ALLOWANCE_REASONS',
    GET_MODULES_ROLES: 'GET_MODULES_ROLES',
    UPDATE_ALLOWANCE_REASON: 'UPDATE_ALLOWANCE_REASON',
    DELETE_ALLOWANCE_REASON: 'DELETE_ALLOWANCE_REASON',
    DELETE_PROFILE_MANAGEMENT: 'DELETE_PROFILE_MANAGEMENT',
    GET_BIRTHDAYS: 'GET_BIRTHDAYS',
    GET_ALL_ACTIVITY_CATEGORIES: 'GET_ALL_ACTIVITY_CATEGORIES',
    CREATE_ACTIVITY_CATEGORIES: 'CREATE_ACTIVITY_CATEGORIES',
    UPDATE_ACTIVITY_CATEGORIES: 'UPDATE_ACTIVITY_CATEGORIES',
    DELETE_ACTIVITY_CATEGORIES: 'DELETE_ACTIVITY_CATEGORIES',
    GET_SERVER_TIME: 'GET_SERVER_TIME',
    GET_MY_CLOCK_RECORD: 'GET_MY_CLOCK_RECORD',
    CLOCK_IN: 'CLOCK_IN',
    GET_MY_ACTIVITY_RECORD: 'GET_MY_ACTIVITY_RECORD',
    ADD_ACTIVITY: 'ADD_ACTIVITY',
    UPDATE_ACTIVITY: 'UPDATE_ACTIVITY',
    GET_COLLABORATORS_CLOCK_RECORDS: 'GET_COLLABORATORS_CLOCK_RECORDS',
    GET_VIEW_COLLABORATOR_CLOCK_RECORD: 'GET_VIEW_COLLABORATOR_CLOCK_RECORD',
    GET_COLLABORATOR_CLOCK_RECORD_DETAILS:
        'GET_COLLABORATOR_CLOCK_RECORD_DETAILS',
    GET_MY_JUSTIFICATIONS: 'GET_MY_JUSTIFICATIONS',
    GET_MY_JUSTIFICATIONS_FORM: 'GET_MY_JUSTIFICATIONS_FORM',
    CREATE_MY_JUSTIFICATIONS: 'CREATE_MY_JUSTIFICATIONS',
    UPDATE_MY_JUSTIFICATIONS: 'UPDATE_MY_JUSTIFICATIONS',
    DELETE_MY_JUSTIFICATIONS: 'DELETE_MY_JUSTIFICATIONS',
    GET_COLLABORATORS_JUSTIFICATIONS: 'GET_COLLABORATORS_JUSTIFICATIONS',
    GET_COLLABORATORS_JUSTIFICATIONS_FORM:
        'GET_COLLABORATORS_JUSTIFICATIONS_FORM',
    APPROVE_COLLABORATORS_JUSTIFICATIONS:
        'APPROVE_COLLABORATORS_JUSTIFICATIONS',
    REFUSE_COLLABORATORS_JUSTIFICATIONS: 'REFUSE_COLLABORATORS_JUSTIFICATIONS'
};

export const LIBRARY = {
    GET_CONFIG: 'GET_CONFIG',
    EDIT_CONFIG: 'EDIT_CONFIG',
    GET_BOOKS: 'GET_BOOKS',
    GET_CATEGORIES: 'GET_CATEGORIES',
    GET_EXEMPLARS: 'GET_EXEMPLARS',
    GET_RESERVATIONS_USER: 'GET_RESERVATIONS_USER',
    CREATE_REQUISITION: 'CREATE_REQUISITION',
    GET_REQUISITIONS: 'GET_REQUISITIONS',
    UPDATE_REQUISITION: 'UPDATE_REQUISITION',
    GET_MY_LOANS: 'GET_MY_LOANS',
    GET_LOANS: 'GET_LOANS',
    GET_FINES: 'GET_FINES',
    GET_FINES_TRANSACTIONS_DATA: 'GET_FINES_TRANSACTIONS_DATA',
    CREATE_TRANSACTION: 'CREATE_TRANSACTION',
    GET_TRANSACTIONS: 'GET_TRANSACTIONS',
    CREATE_QUEUE_REQUISITION: 'CREATE_QUEUE_REQUISITION',
    GET_MY_QUEUE: 'GET_MY_QUEUE',
    CANCEL_QUEUE: 'CANCEL_QUEUE',
    GET_AWAITING_LIST: 'GET_AWAITING_LIST',
    GET_BOOK_BY_ID: 'GET_BOOK_BY_ID'
};

export const QUOTAS = {};
