/* eslint-disable */
import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { useStyles } from "components/layout/CommonStyles";
import Spinner from "components/layout/spinners/Index";
import ProjectsContext from "context/projects/projectsContext";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { animated, useTransition } from "react-spring";

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
      prefix="R$ "
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};
const ViewGeneralInformation = props => {
  const classes = useStyles();
  const [t] = useTranslation();
  const projectsContext = useContext(ProjectsContext);
  const [state, setState] = useState({
    gpf: "",
    name: "",
    client: "",
    status: "",
    baseYear: "",
    codeName: "",
    coordinator: "",
    estimatedCost: "",
    supportFoundation: "",
    clientId: 0,
    isActive: false,
    coordinatorId: 0,
    supportFoundationId: 0,
    endDate: new Date(),
    startDate: new Date()
  });

  const {
    users,
    loading,
    partners,
    getUsers,
    projects,
    getPartners,
    getProjects
  } = projectsContext;

  const { project } = props;

  var coordinadorID = {};
  var clientID = {};
  var fundationID = {};
  useEffect(() => {
    getPartners(partners);
    getProjects();
    getUsers(users);
    if (project.information) {
      var idC = project.information.clientId;
      var idU = project.information.executorId;
      var idF = project.information.executinginstitutionId;
      clientID = partners.filter(item => item.value === Number(idC));
      coordinadorID = users.filter(item => item.value === Number(idU));
      fundationID = partners.filter(item => item.value === Number(idF));
      
      var cost = project.information.estimatedcost.toString().split("");

      if (
        (cost[cost.length - 1] === "0" && cost[cost.length - 2] === "0") ||
        cost[cost.length - 3] !== "."
      ) {
        if (cost[cost.length - 2] === ".") {
          cost.push("0");
        } else {
          cost.push("0");
          cost.push("0");
        }
      }

      var estimatedCost = cost
        .filter(el => el !== "." && el !== ",")
        .join("")
        .split("")
        .reverse()
        .join("");
      var startDateGeneral = new Date(project.information.startdate);
      startDateGeneral.setMinutes(startDateGeneral.getMinutes() + startDateGeneral.getTimezoneOffset())
      var endDateGeneral = new Date(project.information.enddate);
      endDateGeneral.setMinutes(endDateGeneral.getMinutes() + endDateGeneral.getTimezoneOffset())

      setState({
        ...state,
        gpf: project.information.gpf,
        status: project.statusProject,
        name: project.information.name,
        endDate: endDateGeneral,
        baseYear: project.information.baseyear,
        isActive: project.information.isActive,
        clientId: project.information.clientId,
        startDate: startDateGeneral,
        codeName: project.information.identification,
        coordinatorId: project.information.executorId,
        client: clientID[0] ? clientID[0]["label"] : "",
        estimatedCost: converTotBrCurrency(estimatedCost),
        coordinator: coordinadorID[0] ? coordinadorID[0]["label"] : "",
        supportFoundationId: project.information.executinginstitutionId,
        supportFoundation: fundationID[0] ? fundationID[0]["label"] : ""
      });
    }
  }, [
    Object.values(project).length,
    Object.values(partners).length,
    Object.values(users).length
  ]);

  var clients = partners.filter(function(item) {
    return item.type === "CUSTOMER";
  });

  var supportFoundations = partners.filter(function(item) {
    return item.type === "EXECUTING_INSTITUTION";
  });

  const converTotBrCurrency = number => {
    var temp = "";
    var len = number.length;
    var count = 0;

    for (var i = 0; i < len; i++) {
      if (i === 1) {
        var aux = "," + number[i] + temp;
        temp = aux;
      } else {
        if (count === 3) {
          count = 1;
          var aux = number[i] + "." + temp;
          temp = aux;
        } else {
          if (i > 1) {
            count += 1;
          }
          var aux = number[i] + temp;
          temp = aux;
        }
      }
    }

    return temp;
  };

  const transitions = useTransition(loading, null, {
    from: { opacity: 0 },
    enter: {
      opacity: 1
    },
    leave: { opacity: 0 }
  });

  return transitions.map(({ item, key, props }) =>
    item ? (
      <animated.div key={key} style={props}>
        <Spinner />;{/* <h1>Loading...</h1> */}
      </animated.div>
    ) : (
      <animated.div key={key} style={props}>
        <form id="generalInformation">
          <Paper className={classes.root}>
            <div className={classes.header}>
              {t("GENERAL.generalInformation").toUpperCase()}
            </div>
            <div className={classes.bodyForm}>
              <Grid container item spacing={2} md={12} lg={12}>
                <Grid item xs={1}>
                  <TextField
                    disabled
                    className={classes.textField}
                    margin="normal"
                    id="gpf"
                    label="GPF"
                    name="GPF"
                    value={state.gpf}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    disabled
                    className={classes.textField}
                    margin="normal"
                    id="name"
                    label={t("PERSONAL.name") + "*"}
                    name="name"
                    value={state.name}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    disabled
                    className={classes.textField}
                    margin="normal"
                    id="codename"
                    label={t("PROJECTS.codename")}
                    name="codename"
                    value={state.codeName}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    className={classes.textField}
                    margin="normal"
                    label={t("PROJECTS.estimatedCost") + "*"}
                    name="estimatedCost"
                    value={state.estimatedCost}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">R$</InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid item xs={4}>
                    <KeyboardDatePicker
                      disabled
                      className={classes.textField}
                      disableToolbar
                      required
                      autoOk={true}
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="startDate"
                      label={t("GENERAL.startDate")}
                      value={state.startDate}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <KeyboardDatePicker
                      disabled
                      className={classes.textField}
                      disableToolbar
                      required
                      autoOk={true}
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="endDate"
                      label={t("GENERAL.endDate")}
                      value={state.endDate}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      disabled
                      className={classes.textField}
                      margin="normal"
                      label={t("ADDRESS.status") + "*"}
                      name="status"
                      id="status"
                      value={state.status}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <TextField
                      disabled
                      className={classes.textField}
                      margin="normal"
                      label={t("PROJECTS.baseYear") + "*"}
                      name="baseYear"
                      id="baseYear"
                      value={state.baseYear}
                      inputProps={{
                        minLength: 4,
                        maxLength: 4
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
                <Grid item xs={4}>
                  <TextField
                    disabled
                    id="client"
                    label={t("PROJECTS.client") + "*"}
                    name="client"
                    value={state.client}
                    className={classes.textField}
                    value={state.client}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    disabled
                    id="supportFoundation"
                    label={t("PROJECTS.supportFoundation")}
                    name="supportFoundation"
                    value={state.supportFoundation}
                    className={classes.textField}
                    value={state.supportFoundation}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    disabled
                    id="coordinator"
                    label={t("PROJECTS.coordinator")}
                    name="coordinator"
                    value={state.coordinator}
                    className={classes.textField}
                    margin="normal"
                  />
                </Grid>
              </Grid>
            </div>
          </Paper>
        </form>
      </animated.div>
    )
  );
};

export default ViewGeneralInformation;
