/* eslint-disable */
import { Slide } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Close from "@material-ui/icons/Close";
import Notifications from "@material-ui/icons/Notifications";
import Search from "@material-ui/icons/Search";
import SearchContext from "context/search/searchContext";
import React, { useContext, useEffect, useState } from "react";
import DashboardMenu from "./DashboardMenu";
import SiteButton from "components/buttons/SiteButton";
import TypographyText from "components/typography/Typography";


const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#ffffff",
    borderBottom: "0.5px solid #E5E5E5",
    height: "70px",
    //zIndex: 1,
  },
  menuButton: {
    marginRight: "spacing(1)"
  },
  gravatar: {},
  searchBar: {
    flexGrow: 1
  },
  text: {
    fontFamily: "Roboto",
    fontSize: "12px"
  },
  searchLeftIcon: {
    marginLeft: "100px",
    marginRight: "20px"
  },
  textField: {
    flexGrow: 1,
    marginRight: "50px",
    marginTop: "10px"
  },
  button: {
    display: "block",
    marginTop: "10px"
  }
}));

const DashboardNavBar = label => {
  const classes = useStyles();
  const searchContext = useContext(SearchContext);
  const { searchTermChanged, filter } = searchContext;
  let name = JSON.parse(localStorage.getItem("auth-token"));
  const shortname = name && name["name"];
  const imagePath = name && name["imagePath"]
  const [open, setOpen] = useState(true);
  const [open2, setOpen2] = useState(false);

  useEffect(() => {
    return () => {
      searchTermChanged("");
    };
  }, []);

  const handleClickOpen = () => {
    setOpen(false);
    setOpen2(true);
  };

  const handleClose = () => {
    setOpen2(false);
    setOpen(true);
    searchTermChanged("")
  };

  const openwindow = () => {
    if (open) {
      return renderClosedSearchBox();
    } else {
      return renderOpenSearchBox();
    }
  };

  const renderOpenSearchBox = () => {
    return (
      <div>
        <AppBar className={classes.root} style={{ position: "static", transform: "none" }} color="default" elevation={0}>
          <Slide direction="left" in={open2} mountOnEnter unmountOnExit>
            <Toolbar>
              <Search className={classes.searchLeftIcon} />
              <TextField
                fullWidth
                id="filled-email-input"
                className={classes.textField}
                label={label.busca}
                margin="normal"
                variant="filled"
                autoFocus
                value={filter}
                onChange={e => searchTermChanged(e.target.value)}
              />
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="Close"
                onClick={handleClose}
              >
                <Close />
              </IconButton>
            </Toolbar>
          </Slide>
        </AppBar>
      </div>
    );
  };

  const renderClosedSearchBox = () => {
    return (
      <div>
        <AppBar className={classes.root} style={{ position: "static", transform: "none" }} color="default" elevation={0}>
          <Slide direction="left" in={open} mountOnEnter unmountOnExit>
            <Toolbar>
              <Typography
                className={classes.root}
                variant="h6"
                color="inherit"
              />
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="Search"
                onClick={handleClickOpen}
              >
                <Search />
              </IconButton>

              <a href={process.env.REACT_APP_SITE}>
                <SiteButton>
                  <TypographyText variant={'RobotoBold'} fontSize={15} textColor={'yellowSite'}>
                    IR PARA SITE LSBD
                  </TypographyText>              
                </SiteButton>
              </a>

              <IconButton
                edge="start"
                className={`classes.menuButton classes.gravatar`}
                color="inherit"
                aria-label="Gravatar"
              >
                <div
                  id="avatarBar"
                  style={{
                    backgroundImage: `url(${process.env.REACT_APP_IMGSERVER + imagePath})`
                  }}
                ></div>
              </IconButton>
              <TypographyText  variant={'RobotoRegular'} fontSize={15} textColor={'secondary'}>
                {shortname}
              </TypographyText>
              <DashboardMenu className={classes.menuButton} />
            </Toolbar>
          </Slide>
        </AppBar>
      </div>
    );
  };

  return <div>{openwindow()}</div>;
};

export default DashboardNavBar;
