import axios from 'axios';
import Cookies from 'js-cookie';

export const getPisExists = (id, pis) => {
    return axios.get(
        process.env.REACT_APP_SERVER +
            `api/Collaborator/Collaborators/Information/PisExists/${id}/${pis}`,
        {
            headers: {
                'Content-type': 'application/json',
                Authorization:
                    'bearer ' +
                    (Cookies.get('auth-token') ? Cookies.get('auth-token') : '')
            }
        }
    );
};
