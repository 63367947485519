import React from 'react';
import '../../../App.css';
import SideBar from 'components/layout/sidebar/Sidebar';
import DashboardNavBar from 'components/layout/DashboardNavBar';
import UsersForm from './UsersForm';

const CallEditUsers = ({ match }) => {
    return (
        <div>
            <DashboardNavBar />
            <SideBar />
            <UsersForm id={match.params.id} context={'edit'} />
        </div>
    );
};
export default CallEditUsers;
