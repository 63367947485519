import React from "react";
import "../../../App.css";
import SideBar from "components/layout/sidebar/Sidebar";
import DashboardNavBar from "components/layout/DashboardNavBar";
import Equipment from "./Equipment";

const CallEquipment = ({ match }) => {
  
  return (
    <div>
      <DashboardNavBar />
      <SideBar />
      <Equipment />
    </div>
  );
}

export default CallEquipment;