import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Visibility from "@material-ui/icons/Visibility";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import history from "../../../../../history";
import Buttons from "components/buttons/Pressed";
//import InputBase from "@material-ui/core/InputBase";
import TextField from '@material-ui/core/TextField';
import { useStyles } from "components/layout/CommonStyles";
import DialogJobHistoryForm from "./HistoryDialogForm";
import { dateToBR } from "utils/dates/DateToBRr";
import { FormatDateToFront } from "utils/dates/FormatDateToFront";



const BookTableAction = props => {
    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }
    const classes = useStyles();
    const [t] = useTranslation();
    const { item, getOperation } = props;

    const [open, setOpen] = useState(false);

    const verifyView = () => {
        setOpen(true);
    }

    const getPermissionInventory = () => {
        if (localStorage.getItem("auth-token") !== null) {
            var auth = JSON.parse(localStorage.getItem("auth-token"));
            const permission = auth.permissionsByModule;

            // Get the user profile rule for the people management module
            const peopleManagement = permission.filter(el => el.systemId == 6905701);
            // Get the user profile rule for the inventory module
            const inventory = permission.filter(el => el.systemId == 6910301);

            // Checks whether the user is an administrative manager in the people management module and an administrator in the assets module 
            if (inventory[0].roleId === 6905151) {
                return true;
            } else {
                return false;
            }
        }
    }

    return (
        <React.Fragment>
            <Tooltip title={t("GENERAL.view")} aria-label="details" placement="top">
                <IconButton className={classes.iconsTableActions} onClick={verifyView} >
                    <Visibility />
                </IconButton>
            </Tooltip>

            {open &&
                <DialogJobHistoryForm
                    open={open}
                    setOpen={setOpen}
                    oldName={item.oldValueName}
                    newName={item.newValueName}
                    oldDescription={item.oldValueDescription}
                    newDescription={item.newValueDescription}
                    type={getOperation(item.type)}
                    user={item.user}
                    date={dateToBR(FormatDateToFront(item.date))}
                />
            }

        </React.Fragment>
    )
};

export default BookTableAction;