import { makeStyles } from '@material-ui/core/styles';

export const buttonsStyles = makeStyles(theme => ({
    siteButton: {
        color: '#FEC074',
        border: '1px solid',
        padding: '5px 60px',
        marginRight: '16px',
        borderRadius: '50px 50px 50px 50px'
    },
    outlinedWithIconButton: {
        color: 'white',
        border: '1px solid',
        padding: '10px 16px',
        width: '236px'
    },
    containedWithIconButton: {
        backgroundColor: '#2c3e51',
        padding: '10px 16px',
        width: '150px'
    },
    activeInactiveButton: {
        width: '236px',
        borderRadius: '25px'
    },
    centralize: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '100%'
    },
    SwitchOutlinedButtonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '4px 6px',
        border: '1px solid rgba(109, 111, 113, 0.4)',
        borderRadius: '6px'
    },
    SwitchOutlinedButtonIconContainer: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '8px',
        padding: '1px'
    }
}));
