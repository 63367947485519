import React from 'react';
import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';
import { Screen, Allow } from 'global/constants';
import SidebarListItem from './SidebarListItem';

const AdminSideBarConfigMenu = () => {
    return (
        <div style={{ marginTop: '10px' }}>
            {
                <div className="dropdown">
                    <ul style={{ display: 'table-cell', marginLeft: '5px' }}>
                        {GetGeneralPermissionForAction(Screen.ADMIN_USERS, Allow.READ) && (<SidebarListItem to={'/Admin/Users'} text="Usuários" />)}
                        {GetGeneralPermissionForAction(Screen.ADMIN_PROFILES, Allow.READ) && (<SidebarListItem to={'/Admin/ProfileManagement'} text="Perfis" />)}
                    </ul>
                </div>
            }
        </div>
    );
};

export default AdminSideBarConfigMenu;
