/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Transition from 'components/layout/transition/Transition';
import FilterContainer from 'components/containers/FilterContainer';
import InputFilter from 'components/inputs/InputFilter';
import BillsTable from './BillsTable';
import Dissatisfied from '@material-ui/icons/SentimentVeryDissatisfied';
import { useStyles } from 'components/layout/CommonStyles';
import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';

import { Screen, Allow } from 'global/constants';
import { useQueryPersonalBills } from 'hooks/DataFetching/Quotas/Dashboard/useQueryPersonalBills';

import { BillsStatusArray, translateBillStatus } from '../../ScreenUtils';

import { screenStyles } from '../../Styles';

const BillsForm = () => {
    const classes = useStyles();
    const styles = screenStyles();
    const [t] = useTranslation();

    const [findText, setFindText] = useState('');
    const [filterStatus, setFilterStatus] = useState('ALL');
    const [dataFilter, setDataFilter] = useState([]);

    const [canClick, setCanClick] = useState(false);

    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const { isLoading, isFetching, error, data } = useQueryPersonalBills(
        {},
        auth.id
    );

    const hasItens = data && data.length > 0;
    const [hasNoResult, setHasNoResult] = useState(
        hasItens && dataFilter && dataFilter.length === 0
    );

    useEffect(() => {
        if (data) {
            setDataFilter(data.filter(x => !x.hasDeleted));
            setHasNoResult(false);
        }
    }, [data]);

    useEffect(() => {
        if (data && data.length > 0) {
            const { newDataFilter, textNotFound } = applyFilter(
                data.filter(x => !x.hasDeleted)
            );

            if (newDataFilter.length === 0) {
                setDataFilter([]);
                setHasNoResult(true);
                setFindText(textNotFound);
            } else {
                setDataFilter(newDataFilter);
                setHasNoResult(false);
                setFindText('');
            }

            setDataFilter(newDataFilter);
        }
    }, [filterStatus]);

    const applyFilter = data => {
        let newDataFilter = [];
        let textNotFound = '';

        newDataFilter = data;

        if (filterStatus && filterStatus !== 'ALL') {
            newDataFilter = newDataFilter.filter(
                item => item.status && item.status === filterStatus
            );

            if (!textNotFound && newDataFilter.length === 0)
                textNotFound = translateBillStatus(filterStatus);
        }

        return { newDataFilter, textNotFound };
    };

    useEffect(() => {
        if (filterStatus) {
            setCanClick(true);
        } else {
            setCanClick(false);
        }
    }, [filterStatus]);

    return (
        <React.Fragment>
            <Transition loading={isLoading && isFetching} newDesign={true}>
                <FilterContainer>
                    <InputFilter
                        filterValue={filterStatus}
                        setFilter={setFilterStatus}
                        label={'GENERAL.status'}
                        isSelect
                        arraySelected={BillsStatusArray}
                    />
                </FilterContainer>
                <React.Fragment>
                    <BillsTable
                        rows={dataFilter}
                        toFirstPage={canClick}
                        headers={[
                            'id',
                            'date',
                            'status',
                            'value',
                            'user',
                            'verificationCode',
                            'operator',
                            'closedAt',
                            'justification'
                        ]}
                        visibleHeaders={['Data', 'Status', 'Valor', '']}
                        sortedFields={['date', 'status', 'value']}
                    />

                    {!hasItens && !error && (
                        <div className={classes.noDataWrapper}>
                            <h2>{t('QUOTAS.noUserBillsMessage')}</h2>
                            <h4>{t('QUOTAS.noUserBillsSubMessage')}</h4>
                        </div>
                    )}
                    {hasNoResult && !error && (
                        <div className={classes.noParticipantsMessage}>
                            <div>
                                <Dissatisfied
                                    className={classes.notFoundIconStyle}
                                />
                            </div>
                            <h2>{t('GENERAL.noResultsFound')}</h2>
                            <h5>{`Sua busca por "${findText}" não retornou resultados.`}</h5>
                        </div>
                    )}
                    {error && (
                        <div className={classes.noParticipantsMessage}>
                            <div>
                                <Dissatisfied
                                    className={classes.notFoundIconStyle}
                                />
                            </div>
                            <h2>{'Houve um erro de conexão com o Servidor'}</h2>
                        </div>
                    )}
                </React.Fragment>
            </Transition>
        </React.Fragment>
    );
};

export default BillsForm;
