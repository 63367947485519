/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Dissatisfied from '@material-ui/icons/SentimentVeryDissatisfied';
import Footer from 'components/layout/Footer';
import SettingsContext from 'context/hrModule/settings/settingsContext';
import Transition from 'components/layout/transition/Transition';
import Breadcrumb from 'components/layout/breadcrumb/Breadcrumb';
import ProfileManagementTable from './ProfileManagementTable';
import InputFilter from 'components/inputs/InputFilter';
import FilterContainer from 'components/containers/FilterContainer';
import HeaderContainer from 'components/containers/HeaderContainer';
import ContentContainer from 'components/containers/ContentContainer';

import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';
import { Screen, Allow, Modules } from 'global/constants';

import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';

import { screenStyles } from './Styles';

const ProfileManagement = props => {
    const classes = useStyles();
    const styles = screenStyles();
    const [t] = useTranslation();
    const settingsContext = useContext(SettingsContext);
    const [findText, setFindText] = useState('');
    const [filterName, setFilterName] = useState('');
    const [filterModule, setFilterModule] = useState('');
    const [dataFilter, setDataFilter] = useState([]);
    const [canClick, setCanClick] = useState(false);

    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const modules = [
        {
            label: t('GENERAL.allMasculine'),
            value: -1
        },
        {
            value: 6910301,
            label: 'Patrimônio'
        },
        {
            value: 6905701,
            label: 'Gestão de Pessoas'
        },
        {
            value: 6910401,
            label: 'Notícias'
        },
        {
            value: 6910201,
            label: 'Biblioteca'
        },
        {
            value: Modules.QUOTAS.id,
            label: 'Cotas'
        },
        {
            value: Modules.ADMINISTRATIVE.id,
            label: 'Administrativo'
        }
    ];

    const {
        loading,
        setLoading,
        setLoadingFalse,
        getAllRoleModules,
        roleModules
    } = settingsContext;

    async function fetchData() {
        await setLoading();
        await getAllRoleModules();
        await setLoadingFalse();
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (filterName || filterModule) {
            setCanClick(true);
        } else {
            setCanClick(false);
        }
    }, [filterName, filterModule]);

    useEffect(() => {
        if (roleModules && roleModules.length) {
            const { newDataFilter, textNoFound } = applyFilter(
                roleModules.filter(role => !role.hasDeleted)
            );

            if (newDataFilter.length === 0) {
                setDataFilter([]);
                setHasNoResult(true);
                setFindText(textNoFound);
            } else {
                setDataFilter(newDataFilter);
                setHasNoResult(false);
                setFindText('');
            }
        }
    }, [roleModules, filterName, filterModule]);

    const applyFilter = roleModules => {
        let newDataFilter = [];
        let textNoFound = '';

        newDataFilter = roleModules.filter(role => !role.hasDeleted);

        if (filterName) {
            newDataFilter = newDataFilter.filter(item =>
                item.name.toLowerCase().includes(filterName.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterName;
        }
        if (filterModule && filterModule !== -1) {
            newDataFilter = newDataFilter.filter(
                item => item.systemId && item.systemId === filterModule
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = modules.find(x => x.value === filterModule).label;
        }

        return { newDataFilter, textNoFound };
    };

    const hasItens = roleModules.length > 0;
    const [hasNoResult, setHasNoResult] = useState(
        hasItens && dataFilter.length === 0
    );

    return (
        <React.Fragment>
            <Transition loading={loading} newDesign={true}>
                <HeaderContainer>
                    <Breadcrumb
                        newDesign={true}
                        routes={[
                            {
                                label: t('ADMIN.Administrative'),
                                to: '/Admin'
                            },
                            { label: t('HR.profiles') }
                        ]}
                    />
                    <div className="container-headerNewDesign">
                        <h1 className={classes.titleNewDesign}>
                            {t('HR.profiles')}
                        </h1>
                        {GetGeneralPermissionForAction(
                            Screen.ADMIN_PROFILES,
                            Allow.CREATE
                        ) && (
                            <Link to={'/Admin/ProfileManagement/new'}>
                                <Button
                                    className={classes.blueButton}
                                    variant="contained"
                                    color="primary"
                                    style={{ marginBottom: '10px' }}
                                >
                                    {t('HR.addProfile')}
                                </Button>
                            </Link>
                        )}
                    </div>
                </HeaderContainer>
                <ContentContainer>
                    <Paper>
                        <div className="cabecalho">
                            {t('HR.profiles').toUpperCase()}
                        </div>
                        <React.Fragment>
                            <FilterContainer>
                                <InputFilter
                                    filterValue={filterName}
                                    setFilter={setFilterName}
                                    label={'GENERAL.name'}
                                    maxLength={60}
                                />

                                <InputFilter
                                    filterValue={filterModule}
                                    setFilter={setFilterModule}
                                    label={'HR.correspondingModule'}
                                    isSelect
                                    arraySelected={modules}
                                />
                            </FilterContainer>

                            <ProfileManagementTable
                                rows={dataFilter}
                                toFirstPage={canClick}
                                headers={[
                                    'id',
                                    'name',
                                    'moduleName',
                                    'description'
                                ]}
                                visibleHeaders={[
                                    'Nome',
                                    'Módulo Correspondente',
                                    'Descrição',
                                    ''
                                ]}
                                sortedFields={[
                                    'name',
                                    'moduleName',
                                    'description'
                                ]}
                                mainField="name"
                                fetchData={fetchData}
                            />
                            {!hasItens && (
                                <div className={classes.noParticipantsMessage}>
                                    <h2>{t('HR.noProfilesMessage')}</h2>
                                    <h4>{t('HR.noProfilesSubMessage')}</h4>
                                </div>
                            )}
                            {hasNoResult && (
                                <div className={classes.noParticipantsMessage}>
                                    <div>
                                        <Dissatisfied
                                            className={
                                                classes.notFoundIconStyle
                                            }
                                        />
                                    </div>
                                    <h2>{t('GENERAL.noResultsFound')}</h2>
                                    <h5>{`Sua busca por "${findText}" não retornou resultados.`}</h5>
                                </div>
                            )}
                        </React.Fragment>
                    </Paper>
                </ContentContainer>
                <Footer />
            </Transition>
        </React.Fragment>
    );
};

export default ProfileManagement;
