/* eslint-disable */
import Breadcrumb from "components/layout/breadcrumb/Breadcrumb";
import Paper from "@material-ui/core/Paper";
import Footer from "components/layout/Footer";
import Spinner from "components/layout/spinners/Index";
import InventoryContext from "context/inventory/inventoryContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { animated, useTransition } from "react-spring";
import { useStyles } from "../../../components/layout/CommonStyles";
import Grid from "@material-ui/core/Grid";
import Input from "components/inputs/Input";
import DialogReqApprove from "./DialogReqApprove";
import Buttons from "components/buttons/Pressed";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ItensReqTable from "./ItensReqTable";
import SotwareReqTable from "./LicensesReqTable";
import CheckIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from '@material-ui/icons/Delete';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

var placesSelectedPush = [];
const FormRequisition = (props) => {
  var SortElementSelect = require("../../../utils/sort/SortElementSelect").SortElementSelect;
  var dateToBRWithHours = require("../../../utils/dates/DateToBrWithHours").dateToBRWithHours;
  const classes = useStyles();
  const [t] = useTranslation();
  const inventoryContext = useContext(InventoryContext);
  const [open, setOpen] = React.useState(false);
  const [dialogContext, setDialogContext] = React.useState("");
  const [headerTitle, setHeaderTitle] = useState("INVENTORY.itens");
  const [softwaresOrEquipmentsSelected, setSoftwaresOrEquipmentSelected] = useState([]);
  const [useSoftwaresOrEquipment, setUseSoftwaresOrEquipment] = useState([]);
  const [changeOfLocalArray, setChangeOfLocalArray] = useState([]);
  const [changeContext, setchangeContex] = useState(0);

  const handleOpenApprove = () => {
    setDialogContext("approve");
    setOpen(true);
  }

  const handleOpenRefuse = () => {
    setDialogContext("refuse");
    setOpen(true);
  }

  const { id, context, ...others } = props;
  if (localStorage.getItem("auth-token") !== null) {
    var auth = JSON.parse(localStorage.getItem("auth-token"));
  }
  const [values, setValues] = useState({
    id: "",
    type: "",
    typeName: "",
    description: "",
    requester: "",
    dateRequisition: "",
    approver: "",
    approverResponse: "",
    approverResponseDate: "",
    executor: "",
    executerResponse: [],
    executorResponseDate: "",
    itemEquipmentRequisition: [],
    itemSoftwareRequisition: [],
    itemChangeOfLocalRequisition: [],
  });

  const arrayType = [
    {
      value: "change_of_local",
      label: "Alteração de Local",
    },
    {
      value: "use_of_equipment",
      label: "Utilização de Equipamento",
    },
    {
      value: "use_of_software",
      label: "Utilização de Software",
    },
    {
      value: "return_of_equipment",
      label: "Devolução de Equipamento",
    },
    {
      value: "return_of_software",
      label: "Devolução de Software",
    },
  ];

  const {
    loadingSoftware,
    getItensByUser,
    myItens,
    getLicensesByUser,
    licensesByUser,
    getPlaces,
    places,
    getSoftwareCategories,
    itensCategoriesByItensAvaliable,
    getItensCategoriesByItensAvaliable,
    getSoftwareCategoriesByLicensesAvaliable,
    softwareCategoriesByLicensesAvaliable,
    addRequisitions,
    softwaresForRequisition,
    getSoftwaresForRequisition,
    requisition,
    getRequisition,
    patrimonyManager,
    getPatrimonyManager,
  } = inventoryContext;

  useEffect(() => {
    getRequisition(id);
    getPatrimonyManager()
  }, []);

  const getType = (type) => {
  }

  useEffect(() => {
    if (requisition) {
      setValues({
        id: requisition.id ? requisition.id : "",
        status: requisition.status ? requisition.status : "",
        type: requisition.class ? requisition.class : "-",
        typeName: requisition.class ? arrayType.find(ele => ele.value === requisition.class).label : "-",
        description: requisition.message ? requisition.message : "-",
        requester: requisition.requester ? requisition.requester : "-",
        requesterShortName: requisition.requesterShortName ? requisition.requesterShortName : "-",
        requesterEmail: requisition.requesterEmail ? requisition.requesterEmail : "",
        dateRequisition: requisition.dateCreated ? requisition.dateCreated : "",
        approver: requisition.approver ? requisition.approver : "",
        approverResponse: requisition.approverResponse ? requisition.approverResponse : "",
        approverResponseDate: requisition.approverResponseDate ? dateToBRWithHours(new Date(requisition.approverResponseDate)) : "",
        executor: requisition.executor ? requisition.executor : "",
        executerResponse: requisition.executorResponse ? requisition.executorResponse : "",
        executorResponseDate: requisition.executorResponseDate ? dateToBRWithHours(new Date(requisition.executorResponseDate)) : "",
        itemEquipmentRequisition: requisition.inventoryItemOfEquipmentRequisition ? requisition.inventoryItemOfEquipmentRequisition.map(function (num) {
          if (num.ufcCode) num.ufcCodeF = `UFC-${num.ufcCode}`;
          if (num.fcpcCode) num.fcpcCodeF = `FCPC-${num.fcpcCode}`;
          if (num.lsbdCode) num.lsbdCodeF = `LSBD-${num.lsbdCode}`;
          if (num.externCode)
            num.externalCodeF = `${num.externPartner}-${num.externCode}`;
          return num;
        }) : [],
        itemSoftwareRequisition: requisition.inventoryItemOfSoftwareRequisition ? requisition.inventoryItemOfSoftwareRequisition : [],
        itemChangeOfLocalRequisition: requisition.inventoryItemOfChangeOfLocalRequisition ? requisition.inventoryItemOfChangeOfLocalRequisition.map(function (num) {
          if (num.ufcCode) num.ufcCodeF = `UFC-${num.ufcCode}`;
          if (num.fcpcCode) num.fcpcCodeF = `FCPC-${num.fcpcCode}`;
          if (num.lsbdCode) num.lsbdCodeF = `LSBD-${num.lsbdCode}`;
          if (num.externCode)
            num.externalCodeF = `${num.externPartner}-${num.externCode}`;
          return num;
        }) : [],
      })

    }
  }, [requisition.length, requisition])

  const transitions = useTransition(loadingSoftware, null, {
    from: { opacity: 0 },
    enter: {
      opacity: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "calc(100vh - 70px)",
    },
    leave: { opacity: 0 },
  });

  return transitions.map(({ item, key, props }) => (
    <React.Fragment>
      <div className="container">
        <Breadcrumb
          routes={[
            { label: t("INVENTORY.inventory") },
            { label: t("INVENTORY.collaboratorsRequisitions"), to: "/CollaboratorsRequests" },
            { label: context === "edit" ? t("GENERAL.edit") : t("GENERAL.view") },
          ]}
        />
        <div className={classes.containerHeader}>
          <div className="title">
            <h1 className={classes.title}>{context === "edit" ? t("GENERAL.edit") : t("GENERAL.view") + " " + t("INVENTORY.collaboratorRequisition")}</h1>
          </div>
        </div>
        <Paper>
          <div className="cabecalho">
            {context === "edit" ? t("GENERAL.edit").toUpperCase() : t("GENERAL.view").toUpperCase()}
          </div>
          {item ? (
            <animated.div key={key} style={props}>
              <Spinner />;
            </animated.div>
          ) : (
            <React.Fragment>
              <div className={classes.bodyNewEquipment}>
                <Grid container item spacing={3} md={12} lg={12}>
                  <Grid item xs={12} sm={7}>
                    <Input
                      context={"view"}
                      label={"INVENTORY.requester"}
                      value={values.requester}
                      valueName={"requester"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <Input
                      context={"view"}
                      label={"INVENTORY.requestType"}
                      value={values.typeName}
                      valueName={"type"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <Input
                      context={"view"}
                      rows={4}
                      label={"INVENTORY.description"}
                      value={values.description}
                      valueName={"description"}
                      maxLength={500}
                    />
                  </Grid>
                  {values.approver &&
                    <React.Fragment>
                      <Grid item xs={12} sm={7} style={{ padding: "0px" }}>
                        {values.status === "DENIED" && values.executor === "" ? <div style={{ display: "flex", justifyContent: "space-between", paddingLeft: "12px" }}> <div>Recusado <CancelIcon fontSize="small" style={{ color: "red" }} /> </div> {values.approverResponseDate} </div>
                          : <div style={{ display: "flex", justifyContent: "space-between", paddingLeft: "12px" }}> <div>Aprovado <CheckIcon fontSize="small" style={{ color: "green" }} /> </div> {values.approverResponseDate} </div>}
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <Input
                          context={"view"}
                          value={values.approver}
                          valueName={"requester"}
                        />
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <Input
                          context={"view"}
                          rows={4}
                          label={values.status === "DENIED" && values.executor === "" ? "INVENTORY.justificationLabel" : "INVENTORY.observation"}
                          value={values.approverResponse}
                          valueName={"description"}
                          maxLength={200}
                        />
                      </Grid>
                    </React.Fragment>
                  }
                  {values.executor &&
                    <React.Fragment>
                      <Grid item xs={12} sm={7} style={{ padding: "0px" }}>
                        {values.status === "DENIED" && values.executor !== "" ? <div style={{ display: "flex", justifyContent: "space-between", paddingLeft: "12px" }}> <div>Recusado <CancelIcon fontSize="small" style={{ color: "red" }} /> </div> {values.executorResponseDate} </div>
                          : <div style={{ display: "flex", justifyContent: "space-between", paddingLeft: "12px" }}> <div>Aprovado <CheckIcon fontSize="small" style={{ color: "green" }} /> </div> {values.executorResponseDate} </div>}
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <Input
                          context={"view"}
                          label={"INVENTORY.executor"}
                          value={values.executor}
                          valueName={"executor"}
                        />
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <Input
                          context={"view"}
                          rows={4}
                          label={values.status === "DENIED" && values.executor !== "" ? "INVENTORY.justificationLabel" : "INVENTORY.observation"}
                          value={values.executerResponse}
                          valueName={"executorResponse"}
                          maxLength={200}
                        />
                      </Grid>
                    </React.Fragment>
                  }
                  {values.type !== "" &&
                    <Grid item xs={12} sm={12}>
                      <div className="cabecalho">
                        {t(headerTitle).toUpperCase()}
                      </div>
                      {values.type === "return_of_software" ?
                        <SotwareReqTable
                          rows={values.itemSoftwareRequisition}
                          headers={["id", "software", "category", "key", "email", "machine", "newMachine", "ufcCodeF", "lsbdCodeF",
                            "externalCodeF", "serialNumber", "place", "returned"
                          ]}
                          visibleHeaders={["software", "Categoria", "Chave/Email de Ativação", "Máquina", "Devolvido"]}
                          sortedFields={["software", "category", "key", "machine", "returned"]}
                          mainField="software"
                          setSoftwaresOrEquipmentSelected={
                            setSoftwaresOrEquipmentSelected
                          }
                          type={values.type}
                          context={"view"}
                        />
                        :
                        values.type === "use_of_software" ?
                          <SotwareReqTable
                            rows={values.itemSoftwareRequisition}
                            headers={["id", "software", "category", "key", "email", "machine", "newMachine", "ufcCodeF", "lsbdCodeF",
                              "externalCodeF", "serialNumber", "place", "returned"
                            ]}
                            visibleHeaders={["software", "Categoria", "Chave/Email de Ativação", "Máquina", "Liberado"]}
                            sortedFields={["software", "category", "key", "machine", "returned"]}
                            mainField="software"
                            setSoftwaresOrEquipmentSelected={
                              setSoftwaresOrEquipmentSelected
                            }
                            type={values.type}
                            context={"view"}
                          />
                          :
                          values.type === "return_of_equipment" ?
                            < ItensReqTable
                              rows={values.itemEquipmentRequisition}
                              headers={["id", "name", , "category", "fcpcCodeF", "ufcCodeF", "lsbdCodeF", "externalCodeF", "serialNumber", "place", "returned"]}
                              visibleHeaders={["Item", "Categoria", "Código de identificação", "Número de série", "Local de Origem", "Devolvido"]}
                              sortedFields={["name", "category", "ufcCodeF", "serialNumber", "place", "returned"]}
                              mainField="category"
                              type={values.type}
                              changeOfLocalArray={changeOfLocalArray}
                              setchangeContex={setchangeContex}
                              setSoftwaresOrEquipmentSelected={
                                setSoftwaresOrEquipmentSelected
                              }
                              context={"view"}
                            />
                            :
                            values.type === "use_of_equipment" ?
                              <ItensReqTable
                                rows={values.itemEquipmentRequisition}
                                headers={["id", "category", "description", "name", "fcpcCodeF", "ufcCodeF", "lsbdCodeF", "externalCodeF", "serialNumber", "place", "returned"]}
                                visibleHeaders={["Categoria", "Descrição", "Local", "Item", "Código de identificação", "Número de série", "Liberado"]}
                                sortedFields={["category", "description", "name", "serialNumber", "ufcCodeF", "place", "returned"]}
                                mainField="category"
                                type={values.type}
                                changeOfLocalArray={changeOfLocalArray}
                                setchangeContex={setchangeContex}
                                setSoftwaresOrEquipmentSelected={
                                  setSoftwaresOrEquipmentSelected
                                }
                                context={"view"}
                              />
                              :
                              values.type == "change_of_local" &&
                              <ItensReqTable
                                rows={values.itemChangeOfLocalRequisition}
                                headers={["id", "name", "category", "fcpcCodeF", "ufcCodeF", "lsbdCodeF", "externalCodeF", "serialNumber", "oldPlace", "newPlace"]}
                                visibleHeaders={["Item", "Categoria", "Código de identificação", "Número de série", "Local atual", "Novo local"]}
                                sortedFields={["name", "category", "ufcCodeF", "serialNumber", "oldPlace", "newPlace"]}
                                mainField="name"
                                type={values.type}
                                changeOfLocalArray={changeOfLocalArray}
                                context={"view"}
                              />}
                    </Grid>}
                  {context === "edit" && <Grid item xs={12} sm={12}>
                    <div style={{ display: "flex", marginTop: "40px" }}>
                      <Buttons
                        onClick={handleOpenRefuse}
                        tipo="BN-noback"
                        conteudo={t("GENERAL.refuse").toLocaleUpperCase()}
                      />
                      <Button
                        color="primary"
                        className={classes.blueButton}
                        variant="contained"
                        style={{
                          float: "right",
                          marginLeft: "10px",
                        }}
                        onClick={() => handleOpenApprove()}
                      >
                        {t("GENERAL.approve")}
                      </Button>
                    </div>
                  </Grid>}
                </Grid>
              </div>
            </React.Fragment>
          )}
        </Paper>
      </div>
      <DialogReqApprove
        open={open}
        setOpen={setOpen}
        type={values.typeName}
        dateRequisition={values.dateRequisition}
        requisitionId={values.id}
        approverId={auth.id}
        approverName={auth.name}
        context={dialogContext}
        requisition={values}
        patrimonyManager={patrimonyManager}
      />
      <Footer />
    </React.Fragment >
  ));
};

export default FormRequisition;