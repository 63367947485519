import { makeStyles } from "@material-ui/core/styles";

export const screenStyles = makeStyles(theme => ({
    noJustificationsWrapper: {
      paddingTop: '20px',
      backgroundColor: '#F5F6FA',
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "18px",
      lineHeight: "21px",
      color: "#000000",
      opacity: "0.54",
      textAlign: "center",
      paddingBottom: "22px"
    },
  textMargin: {
    margin: "0px 0px 5px",
  },
  iconsRight: {
    color: "#6D6F71",
    marginLeft: "5px"
  },
})
)