import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    clockRecordForm: {
        padding: "48px 32px 6px 32px",
    },
    buttonContainer: {
        padding: "24px 24px 24px 24px",
    },
    radioTable: {
        display: "inline-flex",
        paddingTop: "20px",
        paddingLeft: "4px",
        paddingRight: "4px",
        color: "#2C3E51",
        paddingBottom: "10px",
        width: "96px",
        textAlign: "center",
        alignContent: "center",
        justifyContent: "center",
    },
    radio: {
        display: "inline-flex",
        paddingLeft: "4px",
        paddingRight: "4px",
        color: "#2C3E51",
        width: "96px",
        textAlign: "center",
        alignContent: "center",
        justifyContent: "center",
    },
    gridContainer: {
        paddingRight: "50px",
        paddingTop: "",
        marginRight: "0px",
    },
    knowledgeRadioColumns: {
        display: "flex",
        justifyContent: "space-around",

    },

    radioButtonTopPadding:{
        paddingTop: "10px"
    }
  })
)  