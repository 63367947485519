import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Visibility from "@material-ui/icons/Visibility";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Buttons from "components/buttons/Pressed";
import axios from "axios";
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Zoom from "@material-ui/core/Zoom";
import InventoryContext from "context/inventory/inventoryContext";
import { GetGeneralPermissionForAction } from "utils/permissions/GetGeneralPermissionForAction";
import { Screen, Allow } from "global/constants";
import Cookies from "js-cookie";

const tooltipTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#2c3e51"
        }
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                color: "white",
                fontSize: "1em",
                backgroundColor: "red",
                opacity: "0.7"
            }
        }
    }
});

const useStyles = makeStyles(theme => ({
    icons: {
        opacity: "0"
    },
    root: {
        flexGrow: 1,
    },
    iconReq: {
        marginLeft: "10px",
        opacity: "0",
        backgroundColor: "#6D6F71",
        color: "#FFFFFF;"
    },
    textField: {
        display: "flex",
    },
    textFieldCustum: {
        display: "flex",
        marginLeft: "10px"
    },
}));

const GenericTableActions = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const [openDelete, setOpenDelete] = useState(false);
    const [open, setOpen] = useState(false);
    const [openName, setOpenName] = useState(false);
    const [isModeView, setIsModeView] = useState(false);
    const [titleName, setTitle] = useState(t("GENERAL.requiredField"));
    const inventoryContext = useContext(InventoryContext);
    const {
        softwareCategories,
        updateSoftwareCategory,
        deleteSoftwareCategory
    } = inventoryContext;

    const { item } = props;

    const [values, setValues] = React.useState({
        id: null,
        name: "",
        description: "",
        itensQtd: item.itensQtd,
    });

    const handleOpenDelete = () => {
        setOpenDelete(true);
    };
    const handleCloseDelete = () => {
        setOpenDelete(false);
    };
    const handleDelete = () => {
        deleteSoftwareCategory(item.id);
        setOpenDelete(false);
    };

    //open dialog for edit or view 
    const handleClose = () => {
        clearRequiredMessage()
        setOpen(false);
    };

    const clearRequiredMessage = () => {
        setOpenName(false);
    };

    const showRequiredFieldsMessage = () => {
        setOpenName(true);
    };

    const verifyEdit = () => {
        axios.get(process.env.REACT_APP_SERVER + `api/SoftwareCategories/${item.id}`, {
            headers: {
                "Content-type": "application/json",
                "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
            }
        })
            .then(res => {
                const data = res.data;
                setValues({
                    ...values,
                    id: data.id,
                    name: data.name ? data.name : "",
                    description: data.description ? data.description : "",
                });
                if (res.statusText === "OK") {
                    setOpen(true)
                    setIsModeView(false)
                } else {
                    deleteSoftwareCategory(item.id);
                }
            })
            .catch(error => {
                deleteSoftwareCategory(item.id);
            });
    }

    const verifyView = () => {
        axios.get(process.env.REACT_APP_SERVER + `api/SoftwareCategories/${item.id}`, {
            headers: {
                "Content-type": "application/json",
                "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
            }
        })
            .then(res => {
                const data = res.data;
                setValues({
                    ...values,
                    id: data.id,
                    name: data.name ? data.name : "",
                    description: data.description ? data.description : "",
                });
                if (res.statusText === "OK") {
                    setOpen(true)
                    setIsModeView(true)
                } else {
                    deleteSoftwareCategory(item.id);
                }
            })
            .catch(error => {
                deleteSoftwareCategory(item.id);
            });
    }

    const handleChangeName = name => event => {
        if (event.target.value.length <= 50) {
            clearRequiredMessage();
            const data = softwareCategories.filter(item => item.name.trim().toLocaleLowerCase() === event.target.value.trim().toLocaleLowerCase());
            if (data.length) {
                setTitle(t("INVENTORY.nameSoftwareCategoryAlreadyTaken"));
                setOpenName(true);
            } else {
                setTitle(t("GENERAL.requiredField"));
            }
            setValues({ ...values, [name]: event.target.value });
        }
    };

    const handleChange = name => event => {
        if (event.target.value.length <= 250) {
            setValues({ ...values, [name]: event.target.value });
        }
    };

    const handleSubmit = e => {
        if (!openName) {
            if (values.name.trim()) {
                const obj = {
                    id: values.id,
                    name: values.name,
                    description: values.description.trim() ? values.description.trim() : null,
                };
                clearRequiredMessage();
                updateSoftwareCategory(obj);
                setOpen(false);
                e.preventDefault();
            } else {
                showRequiredFieldsMessage();
            }
        } else showRequiredFieldsMessage();
    };

    return (
        <React.Fragment>
            {GetGeneralPermissionForAction(Screen.SOFTWARES_CATEGORIES, Allow.READ) &&
                <Tooltip title={t("GENERAL.view")} aria-label="details" placement="top">
                    <IconButton className={classes.icons} onClick={verifyView} >
                        <Visibility />
                    </IconButton>
                </Tooltip>
            }
            {GetGeneralPermissionForAction(Screen.SOFTWARES_CATEGORIES, Allow.EDIT) &&
                <Tooltip title="Editar" aria-label="edit" placement="top">
                    <IconButton className={classes.icons} onClick={verifyEdit} >
                        <Edit />
                    </IconButton>
                </Tooltip>
            }
            {GetGeneralPermissionForAction(Screen.SOFTWARES_CATEGORIES, Allow.DELETE) &&
                <Tooltip title="Deletar" aria-label="delete" placement="top">
                    <IconButton className={classes.icons} onClick={handleOpenDelete}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            }
            <Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="form-dialog-title"
                msg="mensagem"
            >
                <DialogTitle className={classes.Title}>
                    {t("GENERAL.delete")} {t("INVENTORY.category")}
                    <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleCloseDelete} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t("INVENTORY.SCDeleteMessage")}
                        <br />
                        <b>{item.name}</b>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Buttons
                        onClick={handleCloseDelete}
                        tipo="BN-noback"
                        conteudo={t("GENERAL.cancel").toUpperCase()}
                    />
                    <Buttons
                        onClick={handleDelete}
                        tipo="BN-blue"
                        conteudo={t("GENERAL.delete").toUpperCase()}
                    />
                </DialogActions>
            </Dialog>

            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={"sm"}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                msg="mensagem"
            >
                <DialogTitle className={classes.TitleDialog}>
                    {isModeView ? t("INVENTORY.ViewItemCategory") : t("INVENTORY.updateItemCategory")}
                    <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div className={classes.root}>
                            <Grid container>
                                <Grid item xs={12} sm={12}>
                                    <MuiThemeProvider theme={tooltipTheme}>
                                        <Tooltip
                                            TransitionComponent={Zoom}
                                            open={openName}
                                            title={titleName}
                                        >
                                            <TextField
                                                disabled={isModeView}
                                                className={classes.textField}
                                                label={t("INVENTORY.nameItemCategory") + "*"}
                                                value={values.name}
                                                onChange={handleChangeName("name")}
                                                margin="normal"
                                                inputProps={{
                                                    maxLength: 50
                                                }}
                                            />
                                        </Tooltip>
                                    </MuiThemeProvider>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    multiline
                                    disabled={isModeView}
                                    className={classes.textField}
                                    label={t("INVENTORY.descriptionItemCategory")}
                                    value={values.description}
                                    margin="normal"
                                    onChange={handleChange("description")}
                                    inputProps={{
                                        maxLength: 250
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    disabled
                                    className={classes.textField}
                                    label={t("INVENTORY.qtdSoftwareCategory")}
                                    value={values.itensQtd}
                                    margin="normal"
                                />
                            </Grid>
                        </div>
                    </DialogContentText>
                </DialogContent>
                {!isModeView ?
                    <DialogActions>
                        <Buttons
                            onClick={handleClose}
                            tipo="BN-noback"
                            conteudo={t("INVENTORY.cancel").toUpperCase()}
                        />
                        <Buttons
                            onClick={handleSubmit}
                            tipo="BN-blue"
                            conteudo={t("GENERAL.update").toUpperCase()}
                        />
                    </DialogActions>
                    :
                    <DialogActions>
                        <Buttons
                            onClick={handleClose}
                            tipo="BN-noback"
                            conteudo={t("INVENTORY.close").toUpperCase()}
                        />
                    </DialogActions>
                }
            </Dialog>
        </React.Fragment>
    )
};

export default GenericTableActions;