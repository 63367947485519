import { makeStyles } from '@material-ui/core/styles';

export const screenStyles = makeStyles(theme => ({
    smallMargin: {
        margin: '0px 8px',
        lineHeight: '22px'
    },
    myCredit: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        marginRight: '16px',
        '& > :first-child': {
            marginBottom: '8px'
        }
    },
    dialogViewBillAvatar: {
        border: '2px solid #2c3e51',
        width: '32px',
        height: '32px'
    }
}));
