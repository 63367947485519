import React from "react";
import { screenStyles } from "./Styles"
import Typography from "components/typography/Typography";
import { Link as LinkRouter } from 'react-router-dom'
import GrayCircle from "assets/icons/GrayCircle.svg"

export const HomeCard = (props) => {
  const { title, description, url } = props;
  const styles = screenStyles();

  return (
    <React.Fragment>
      <LinkRouter className={styles.CardContainer} to={url}>
        <div className={styles.CardTitleWrapper}>
          <img className={styles.GrayCircle} src={GrayCircle} />
          <Typography variant={'RobotoSemiBold'} fontSize={18} textColor={'secondary'}>{title}</Typography>
        </div>
        <Typography variant={'Roboto'} fontSize={13} textColor={'secondary'}>{description}</Typography>
      </LinkRouter>
    </React.Fragment>
  )
}
