import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import axios from 'axios';
import Footer from 'components/layout/Footer';
import Spinner from 'components/layout/spinners/Index';
import ExternalUserContext from 'context/externalUsers/externalUsersContext';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { animated, useTransition } from 'react-spring';
import history from '../../../history';
import { useStyles } from '../../layout/CommonStyles';
import HistoryTabPanel from './HistoryTabPanel';
import Cookies from 'js-cookie';

const ViewUser = props => {
    const { idUser } = props;
    const classes = useStyles();
    const [t] = useTranslation();
    const externalUsersContext = useContext(ExternalUserContext);

    const {
        loading,
        getExternalUser,
        externalUser,
        getPlacesExternalUser,
        placesExternalUsers,
        getExternalHistory,
        externalUserHistory
    } = externalUsersContext;

    const [snackBar, setSnackBar] = useState({
        open: false,
        kind: '',
        content: ''
    });

    useEffect(() => {
        getExternalUser(idUser);
    }, []);

    const [selectedPlacesArray, setSelectedPlacesArray] = useState([]);

    const [dataStates, setDataStates] = useState({
        places: []
    });

    useEffect(() => {
        getPlacesExternalUser(idUser);

        while (dataStates.places.length) {
            dataStates.places.pop();
        }

        var _places = [];
        var _placesIds = [];
        let aux = {};
        for (var i = 0; i < placesExternalUsers.length; i++) {
            var item = placesExternalUsers[i];
            aux.value = item.id;
            aux.placeId = item.placeId;
            aux.name = item.place.name;
            _places.push(aux);
            _placesIds.push(aux.placeId);
            aux = {};
        }
        setDataStates({
            ...dataStates,
            places: dataStates.places.concat(_places)
        });
        setSelectedPlacesArray(selectedPlacesArray.concat(_placesIds));

        return () => {};
    }, [JSON.stringify(placesExternalUsers).length]);

    const transitions = useTransition(loading, null, {
        from: { opacity: 0 },
        enter: {
            opacity: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: 'calc(100vh - 70px)'
        },
        leave: { opacity: 0 }
    });

    const verifyEdit = () => {
        axios
            .get(process.env.REACT_APP_SERVER + `api/ExternalUsers/${idUser}`, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(res => {
                if (res.statusText === 'OK') {
                    history.push(`/ExternalUsers/${idUser}/edit`);
                } else {
                    // deletePartner(item.id);
                }
            })
            .catch(error => {
                // deletePartner(item.id);
            });
    };

    return transitions.map(({ item, key, props }) =>
        item ? (
            <animated.div key={key} style={props}>
                <Spinner />;
            </animated.div>
        ) : (
            <animated.div key={key} style={props}>
                <div className="container">
                    <Breadcrumbs
                        className={classes.breadCrumbs}
                        aria-label="breadcrumb"
                        separator={<NavigateNextIcon fontSize="small" />}
                    >
                        <Typography>{t('INVENTORY.inventory')}</Typography>
                        <Link className={classes.link} to="/ExternalUsers">
                            <Typography>
                                {t('EXTERNALUSERS.externalUsers')}
                            </Typography>
                        </Link>
                        <Typography>
                            {t('EXTERNALUSERS.viewExternalUser')}
                        </Typography>
                    </Breadcrumbs>
                    <div className="container-header">
                        <div className="title">
                            <h1 className={classes.title}>
                                {t('EXTERNALUSERS.viewExternalUser')}
                            </h1>
                        </div>
                    </div>
                    <Paper>
                        <div className="cabecalho">
                            {t('EXTERNALUSERS.externalUserData').toUpperCase()}
                        </div>
                        <div className={classes.bodyNewEquipment}>
                            <Grid container spacing={3}>
                                <Grid item xs={2}>
                                    <div
                                        className={classes.thumb}
                                        key={'upload-equipament-image.jpg'}
                                    >
                                        <div className={classes.thumbInner}>
                                            <img
                                                className={
                                                    classes.imgNewEquipment
                                                }
                                                alt="complex"
                                                src={
                                                    externalUser.imagePath
                                                        ? window.location
                                                              .origin +
                                                          externalUser.imagePath
                                                        : window.location
                                                              .origin +
                                                          '/img/upload-equipament-image.png'
                                                }
                                            />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={9}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={5}>
                                            <TextField
                                                className={classes.textField}
                                                label={t('EXTERNALUSERS.name')}
                                                value={externalUser.name}
                                                margin="normal"
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                className={classes.textField}
                                                label={t('EXTERNALUSERS.phone')}
                                                value={externalUser.phoneNumber}
                                                margin="normal"
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                className={classes.textField}
                                                label={t('EXTERNALUSERS.email')}
                                                value={externalUser.email}
                                                margin="normal"
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        style={{ paddingBottom: 6 }}
                                    >
                                        <Typography
                                            className={
                                                classes.titleNewEquipment
                                            }
                                        >
                                            Locais Relacionados
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        {dataStates.places && (
                                            <div>
                                                {dataStates.places.map(
                                                    externPlace => (
                                                        <div
                                                            className={
                                                                classes.dialogFile
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    classes.textFile
                                                                }
                                                            >
                                                                <Typography
                                                                    className={
                                                                        classes.nameFile
                                                                    }
                                                                >
                                                                    {
                                                                        externPlace.name
                                                                    }
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        disabled
                                        multiline
                                        fullWidth
                                        variant="outlined"
                                        rows="6"
                                        id="description"
                                        value={externalUser.description}
                                        label={t(
                                            'INVENTORY.descriptionBookCategory'
                                        )}
                                        margin="normal"
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </Paper>
                    <br></br>
                    <Paper>
                        <div className="cabecalho">
                            {t(
                                'EXTERNALUSERS.externalUserhistory'
                            ).toUpperCase()}
                        </div>
                        <HistoryTabPanel idUser={idUser} />
                    </Paper>
                </div>
                <Footer />
            </animated.div>
        )
    );
};

export default ViewUser;
