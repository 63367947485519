import React, { useContext, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Add from "@material-ui/icons/Add";
import Search from "@material-ui/icons/Search";
import Dissatisfied from "@material-ui/icons/SentimentVeryDissatisfied";
import { useStyles } from "components/layout/CommonStyles";
import MySnackbarContentWrapper from "components/layout/Message";
import Spinner from "components/layout/spinners/Index";
import AllowancesContext from "context/allowance/allowanceContext";
import ProjectsContext from "context/projects/projectsContext";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import FeedbackTableHandleActions from "./FeedbackTableHandleActions";

const FeedbackTable = props => {
  const { feedback, userId, mode, participationId, projectId, participantMode, project } = props;
  const [t] = useTranslation();
  const classes = useStyles();
  const [dataFilter, setDataFilter] = useState(feedback);
  const hasFeedback = feedback.length > 0;
  const projectContext = useContext(ProjectsContext);
  const allowanceContext = useContext(AllowancesContext);
  const [filter, setFilter] = useState("");
  const [period, setPeriod] = useState([]);
  const [periodLabel, setPeriodLabel] = useState('');
  const {
    loading,
    response,
    responseType,
    setHasChanged,
    resetMessage,
  } = projectContext;
  const {
    allowanceResponseType,
    resetAllowanceMessage
  } = allowanceContext;
  const [hasNoResult, setHasNoResult] = useState(hasFeedback && dataFilter.length === 0);
  const [snackBar, setSnackBar] = useState({
    open: false,
    kind: "",
    content: ""
  });
  const [hideButton, setHiderButton] = useState(false);

  const monthDict = {
    1: "Jan",
    2: "Fev",
    3: "Mar",
    4: "Abr",
    5: "Mai",
    6: "Jun",
    7: "Jul",
    8: "Ago",
    9: "Set",
    10: "Out",
    11: "Nov",
    12: "Dez"
  };

  useEffect(() => {
    if (project && project.information && project.information.startdate && project.information.enddate) {

      let startDateGeneral = new Date(project.information.startdate);
      startDateGeneral.setMinutes(startDateGeneral.getMinutes() + startDateGeneral.getTimezoneOffset());
      let endDateGeneral = new Date(project.information.enddate);
      endDateGeneral.setMinutes(endDateGeneral.getMinutes() + endDateGeneral.getTimezoneOffset());

      const periodList = [];
      const dateCurrent = new Date();
      const dateAux = new Date(startDateGeneral.getFullYear(), startDateGeneral.getMonth());

      while (dateAux <= dateCurrent) {
        const periodInit = new Date(dateAux.toString());
        dateAux.setMonth(dateAux.getMonth() + 3);
        if (dateAux > endDateGeneral || dateAux > dateCurrent) {
          break;
        } else {
          periodList.push(`${monthDict[periodInit.getMonth() + 1]}/${periodInit.getFullYear().toString().substr(-2)} - ${monthDict[dateAux.getMonth() + 1]}/${dateAux.getFullYear().toString().substr(-2)}`);
        }

        dateAux.setMonth(dateAux.getMonth() + 1);
      }

      const feedbackPeriod = feedback.map(x => x.period);
      setHiderButton(periodList.every(x => feedbackPeriod.includes(x)));
      feedbackPeriod.unshift("Todos");
      setPeriod(feedbackPeriod);
      if (feedbackPeriod[0]) setPeriodLabel(feedbackPeriod[0]);
    }
  }, [project, feedback]);

  const renderTableFeedback = data => {
    return (
      <FeedbackTableHandleActions
        rows={data}
        headers={["id", "dateCreate", "period", "feedback", "evaluator"]}
        sortedFields={["dateCreate", "period", "feedback", "evaluator"]}
        visibleHeaders={[
          "DATA",
          "PERÍODO AVALIADO",
          "FEEDBACK",
          "AVALIADOR"
        ]}
        mainField="dateCreate"
        mode={mode}
        participationId={participationId}
        projectId={projectId}
        userId={userId}
        participantMode={participantMode}
      />
    );
  };

  const [tableFeedback, setTableFeedback] = useState(
    renderTableFeedback(dataFilter)
  );

  useEffect(() => {
    if (responseType) {
      handleSnack();
    }
    return () => { };
  }, [responseType, response]);

  const handleSnack = () => {
    setSnackBar({ ...snackBar, open: true });
  };

  const handleSnackClose = () => {
    setSnackBar({ ...snackBar, open: false });
    setHasChanged();
    resetAllowanceMessage();
    resetMessage();
  };

  const handleChangePeriod = event => {
    if (event && event.target) {
      setPeriodLabel(event.target.value);
    }
  }

  const handleSearch = event => {
    setFilter(event.target.value);
  };

  useEffect(() => {
    const res = feedback.filter(item =>
      item.evaluator.toLowerCase().includes(filter.toLowerCase()) &&
      (periodLabel === "Todos" || item.period === periodLabel)
    );

    setHasNoResult(res.length === 0);
    setDataFilter(res);
  }, [feedback, filter, periodLabel]);

  useEffect(() => {
    setTableFeedback(renderTableFeedback(dataFilter));
  }, [dataFilter]);

  const transitions = useTransition(loading, null, {
    from: { opacity: 0 },
    enter: {
      opacity: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    leave: { opacity: 0 }
  });

  return transitions.map(({ item, key, props }) =>
    item ? (
      <animated.div key={key} style={props}>
        <Spinner />
      </animated.div>
    ) : (
        <animated.div key={key} style={props}>
          {(responseType || allowanceResponseType) &&
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={snackBar.open}
              onClose={handleSnackClose}
              TransitionComponent={Slide}
              transitionDuration={{ enter: 500, exit: 500 }}
              ContentProps={{
                "aria-describedby": "message-id"
              }}
              autoHideDuration={2000}
            >
              <MySnackbarContentWrapper
                onClose={handleSnackClose}
                variant={
                  responseType
                    ? responseType.toLowerCase()
                    : allowanceResponseType.toLowerCase()
                }
                message={
                  <span id="message-id">
                    {response !== "" ? response : 'allowanceResponse'}
                  </span>
                }
              />
            </Snackbar>}
          <Paper className={classes.root}>
            <div className={classes.header}>
              {t("PARTICIPANTS.participantFeedback").toUpperCase()}
            </div>
            <div className={classes.divAddParticipant}>
              <div className={classes.divAddParticipantLeft}>
                <div style={{ display: !hasFeedback ? "none" : "" }} className={classes.participantSearch}>
                  <Toolbar>
                    <Search className={classes.searchLeftIcon} />
                    <TextField
                      fullWidth
                      id="search-list-feedback"
                      className={classes.textField}
                      label={t("PARTICIPANTS.participantsFeedbackSearchLabel")}
                      margin="normal"
                      variant="filled"
                      name="list-feedback"
                      autoFocus
                      value={filter}
                      onChange={handleSearch}
                    />
                  </Toolbar>
                </div>
                <div style={{ display: !hasFeedback ? "none" : "" }}>
                  <TextField
                    select
                    id="period"
                    label={t("GENERAL.evaluatedPeriod")}
                    className={classes.textField, classes.fieldInputSearch}
                    required
                    name="periods"
                    margin="normal"
                    value={periodLabel}
                    onChange={handleChangePeriod}
                  >
                    {period.map(option => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>
              {(participantMode === t("GENERAL.editMode") && !hideButton) &&
                <Link to={`/execution/participant/edit/${participationId}/${userId}/${projectId}/edit/addFeedback`}>
                  <Button className={classes.addParticipant} variant="contained">
                    <div className={classes.buttonIcon}>
                      <Add />
                    </div>
                    {t("PARTICIPANTS.addFeedback")}
                  </Button>
                </Link>
              }
            </div>
            <div className="lineBreak"></div>
            {hasFeedback ? tableFeedback :
              <div className={classes.noParticipantsMessage}>
                <h2>{t("PARTICIPANTS.noFeedbackMessage")}</h2>
                <h4>{t("PARTICIPANTS.noFeedbackSubMessage")}</h4>
              </div>
            }
            {hasNoResult && filter && (
              <div className={classes.noParticipantsMessage}>
                <div>
                  <Dissatisfied className={classes.notFoundIconStyle} />
                </div>
                <h2>{t("GENERAL.noResultsFound")}</h2>
                <h5>{`Sua busca por "${filter}" não retornou resultados.`}</h5>
              </div>
            )}
          </Paper>
        </animated.div >
      )
  );
};

export default FeedbackTable;
