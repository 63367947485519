import i18n from '../i18n';

export const Modules = {
    PATRIMONY: { id: 6910301, name: 'Patrimônio' },
    HR: { id: 6905701, name: 'Gestão de Pessoas (Novo)' },
    HR_OLD: { id: 6905701, name: 'Gestão de Pessoas (Antigo)' },
    NEWS: { id: 6910401, name: 'Notícias' },
    LIBRARY: { id: 6910201, name: 'Biblioteca' },
    QUOTAS: { id: 6909701, name: 'Cotas' },
    ADMINISTRATIVE: { id: 6905301, name: 'Administrativo' }
};

export const Rulesets = {
    CLT: { id: 6908851 },
    INTERN: { id: 6909001 },
    RESEARCHER: { id: 6909151 },
    SCHOLARSHIP: { id: 6908951 },
    RESIDENT: { id: 6909201 }
};

export const Screen = {
    MY_ITENS: 'MY_ITENS',
    EQUIPMENTS: 'EQUIPMENTS',
    BOOKS: 'BOOKS',
    SOFTWARES: 'SOFTWARES',
    MY_REQUISITIONS: 'MY_REQUISITIONS',
    COLLABORATORS_REQUISITIONS: 'COLLABORATORS_REQUISITIONS',
    REQUISITIONS: 'REQUISITIONS',
    BOOKS_CATEGORIES: 'BOOKS_CATEGORIES',
    ITENS_CATEGORIES: 'ITENS_CATEGORIES',
    SOFTWARES_CATEGORIES: 'SOFTWARES_CATEGORIES',
    PLACES: 'PLACES',
    ITENS_STATUS: 'ITENS_STATUS',
    EXTERNAL_COLLABORATORS: 'EXTERNAL_COLLABORATORS',
    COLLABORATORS: 'COLLABORATORS',
    PROFILE_MANAGEMENT: 'PROFILE_MANAGEMENT',
    HOLIDAYS_AND_BREAKS: 'HOLIDAYS_AND_BREAKS',
    JOBS: 'JOBS',
    COLLABORATORS_VACATIONS: 'COLLABORATORS_VACATIONS',
    MY_VACATIONS: 'MY_VACATIONS',
    MY_INFORMATIONS: 'MY_INFORMATIONS',
    VACATION_CALENDAR: 'VACATION_CALENDAR',
    ALLOWANCE_REASON: 'ALLOWANCE_REASON',
    BIRTHDAYS: 'BIRTHDAYS',
    MY_CLOCK_RECORD: 'MY_CLOCK_RECORD',
    ACTIVITY_CATEGORIES: 'ACTIVITY_CATEGORIES',
    COLLABORATORS_CLOCK_RECORDS: 'COLLABORATORS_CLOCK_RECORDS',
    MY_JUSTIFICATIONS: 'MY_JUSTIFICATIONS',
    COLLABORATORS_JUSTIFICATIONS: 'COLLABORATORS_JUSTIFICATIONS',
    BOOKS_COLLECTION: 'BOOKS_COLLECTION',
    LIBRARY_CONFIGURATION: 'LIBRARY_CONFIGURATION',
    LIBRARY_REQUISITIONS_GENERAL: 'LIBRARY_REQUISITIONS_GENERAL',
    LIBRARY_REQUISITIONS_ALL: 'LIBRARY_REQUISITIONS_ALL',
    LIBRARY_MY_LOANS: 'LIBRARY_MY_LOANS',
    LIBRARY_LOANS: 'LIBRARY_LOANS',
    FINES: 'FINES',
    QUOTAS_DASHBOARD_PERSONAL: 'QUOTAS_DASHBOARD_PERSONAL',
    QUOTAS_DASHBOARD_PERSONAL_TAB_BILLS: 'QUOTAS_DASHBOARD_PERSONAL_TAB_BILLS',
    QUOTAS_DASHBOARD_PERSONAL_TAB_SOLICITATIONS:
        'QUOTAS_DASHBOARD_PERSONAL_TAB_SOLICITATIONS',
    QUOTAS_DASHBOARD_PERSONAL_TAB_TRANSACTIONS:
        'QUOTAS_DASHBOARD_PERSONAL_TAB_TRANSACTIONS',
    QUOTAS_DASHBOARD_LSBD: 'QUOTAS_DASHBOARD_LSBD',
    QUOTAS_DASHBOARD_LSBD_TAB_SOLICITATIONS:
        'QUOTAS_DASHBOARD_LSBD_TAB_SOLICITATIONS',
    QUOTAS_DASHBOARD_LSBD_TAB_BILLINGSHEET:
        'QUOTAS_DASHBOARD_LSBD_TAB_BILLINGSHEET',
    QUOTAS_DASHBOARD_LSBD_TAB_LATE: 'QUOTAS_DASHBOARD_LSBD_TAB_LATE',
    QUOTAS_DASHBOARD_LSBD_TAB_TRANSACTIONS:
        'QUOTAS_DASHBOARD_LSBD_TAB_TRANSACTIONS',
    QUOTAS_DASHBOARD_LSBD_TAB_EXPENSES: 'QUOTAS_DASHBOARD_LSBD_TAB_EXPENSES',
    QUOTAS_DASHBOARD_LSBD_TAB_CASHFLOW: 'QUOTAS_DASHBOARD_LSBD_TAB_CASHFLOW',
    QUOTAS_JOBS: 'QUOTAS_JOBS',
    QUOTAS_USERS: 'QUOTAS_USERS',
    QUOTAS_TOOLS: 'QUOTAS_TOOLS',
    ADMIN_USERS: 'ADMIN_USERS',
    ADMIN_PROFILES: 'ADMIN_PROFILES'
};

export const Allow = {
    READ: 'allowRead',
    EDIT: 'allowEdit',
    CREATE: 'allowCreate',
    DELETE: 'allowDelete',
    IS_MANAGER: 'isManager'
};

export const Events = {
    NEW_EQUIPMENT_EMAIL: 'NEW_EQUIPMENT_EMAIL',
    NEW_BOOK_EMAIL: 'NEW_BOOK_EMAIL',
    NEW_SOFTWARE_EMAIL: 'NEW_SOFTWARE_EMAIL',
    LICENSE_TO_EXPIRE: 'LICENSE_TO_EXPIRE'
};

export const Months = [
    {
        value: 0,
        label: i18n.t('GENERAL.january')
    },
    {
        value: 1,
        label: i18n.t('GENERAL.february')
    },
    {
        value: 2,
        label: i18n.t('GENERAL.march')
    },
    {
        value: 3,
        label: i18n.t('GENERAL.april')
    },
    {
        value: 4,
        label: i18n.t('GENERAL.may')
    },
    {
        value: 5,
        label: i18n.t('GENERAL.june')
    },
    {
        value: 6,
        label: i18n.t('GENERAL.july')
    },
    {
        value: 7,
        label: i18n.t('GENERAL.august')
    },
    {
        value: 8,
        label: i18n.t('GENERAL.september')
    },
    {
        value: 9,
        label: i18n.t('GENERAL.october')
    },
    {
        value: 10,
        label: i18n.t('GENERAL.november')
    },
    {
        value: 11,
        label: i18n.t('GENERAL.december')
    }
];

export const Weekdays = [
    {
        value: 0,
        label: i18n.t('GENERAL.sunday')
    },
    {
        value: 1,
        label: i18n.t('GENERAL.monday')
    },
    {
        value: 2,
        label: i18n.t('GENERAL.tuesday')
    },
    {
        value: 3,
        label: i18n.t('GENERAL.wednesday')
    },
    {
        value: 4,
        label: i18n.t('GENERAL.thursday')
    },
    {
        value: 5,
        label: i18n.t('GENERAL.friday')
    },
    {
        value: 6,
        label: i18n.t('GENERAL.saturday')
    }
];

export const LibraryPlaceValues = {
    GENERAL: 'GENERAL',
    COORDINATION: 'COORDINATION',
    EXTERNAL: 'EXTERNAL'
};

export const LibraryFinesStatus = {
    PAID: 'PAID',
    PENDING: 'PENDING',
    NO_CHARGE: 'NO_CHARGE'
};

export const LibraryFinesTypes = {
    FINE_PAYMENT: 'FINE_PAYMENT',
    BOOK_PURCHASE: 'BOOK_PURCHASE',
    CREDIT: 'CREDIT',
    DEBIT: 'DEBIT',
    UPDATE_EXEMPLAR_CREDIT: 'UPDATE_EXEMPLAR_CREDIT',
    UPDATE_EXEMPLAR_DEBIT: 'UPDATE_EXEMPLAR_DEBIT'
};

export const LibraryExemplarStatusValues = {
    DISPONIBLE: 'DISPONIBLE',
    INDISPONIBLE: 'INDISPONIBLE',
    LOANED: 'LOANED',
    DONATED: 'DONATED',
    IN_LOAN_PROCESS: 'IN_LOAN_PROCESS'
};

export const LibraryReservationStatusValues = {
    AWAITING_APPROVAL: 'AWAITING_APPROVAL',
    AWAITING_WITHDRAWAL: 'AWAITING_WITHDRAWAL',
    CANCELED_MANAGEMENT: 'CANCELED_MANAGEMENT',
    CANCELED_COLLABORATOR: 'CANCELED_COLLABORATOR',
    FINISHED: 'FINISHED',
    LOAN: 'LOAN',
    LATE: 'LATE'
};

export const ExemplarAcquisitionMethods = {
    PROJECT: 'PROJECT',
    FINE: 'FINE',
    OTHERS: 'OTHERS'
};

export const LibraryHistoryExemplarsOperations = {
    ADMINISTRATIVE_RESPONSIBLE: 'ADMINISTRATIVE_RESPONSIBLE',
    DIRECT_RESPONSIBLE: 'DIRECT_RESPONSIBLE'
};

export const LibraryQueueStatusValues = {
    IN_QUEUE: 'IN_QUEUE'
};

export const QuotaTransactionSources = {
    USER: 'USER',
    EXTERNAL: 'EXTERNAL'
};
