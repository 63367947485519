import { makeStyles } from '@material-ui/core/styles';

export const screenStyles = makeStyles(theme => ({
    filterSearchInput: {
        height: 48,
        width: '100%',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#F5F6FA',
        '&:hover': {
            backgroundColor: 'rgb(224, 224, 224)'
        },
        marginRight: 16,
        marginLeft: 0
    }
}));
