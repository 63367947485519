import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: "#eef2f6"
    }
  },
  paper: {
    width: "572px",
    height: "459px",
    boxShadow: "0 20px 40px 0 rgba(44, 62, 81, 0.2)",
    backgroundColor: "white",
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: "20px",
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),

    //responsividade
    [theme.breakpoints.down("500")]: {
      width: "365px",
      marginTop: theme.spacing(10),
      marginLeft: "none",
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5)
    },
    [theme.breakpoints.down("380")]: {
      width: "325px",
      marginTop: theme.spacing(10),
      marginLeft: "none",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    [theme.breakpoints.down("325")]: {
      width: "290px",
      marginTop: theme.spacing(2),
      marginLeft: "none",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },

  form: {
    width: "100%" // Fix IE 11 issue.
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#2c3e51",
    float: "left",
    marginRight: 20,
    "&:hover": {
      textDecoration: "none",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "transparent"
      },
      "&$disabled": {
        backgroundColor: "transparent"
      }
    },
    "&$disabled": {
      color: theme.palette.action.disabled
    }
  },

  forgot: {
    width: "150px",
    height: "36px",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "normal",
    float: "left",
    color: "#2c3e51",
    marginTop: 25,
    fontWeight: "bold"
  },
  textField: {
    borderBottom: "3px solid #2c3e51"
  },
  alert: {
    Color: "#ff6e6e",
    textAlign: "right",
    marginLeft: "245px"
  },
  alert2: {
    marginLeft: "214px"
  },
  TypographyLogin: {
    fontWeight: "bold",
    fontFamily: "Roboto",
    fontSize: "37px",
    textAlign: "left",
    color: "#2c3e51",
    marginTop: "20px"
  },
  co: {
    color: "#2c3e51",
    "&:hover": {
      backgroundColor: "#7ea3b7",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "transparent"
      },
      "&$disabled": {
        backgroundColor: "transparent"
      }
    },
    "&$checked": {
      color: "green"
    }
  },
  main: {
    height: "calc(100vh - 70px)",
    display: "flex",
    alignItems: "center"
  }
}));

export default useStyles;
