import React, { useState, useEffect } from 'react';
import { screenStyles } from './Styles';
import Typography from 'components/typography/Typography';
import Buttons from 'components/buttons/Pressed';
import { useTranslation } from 'react-i18next';
import { LibraryReservationStatusValues } from 'global/constants';
import { formatExemplarCode } from 'utils/library/formatExemplarCode';
import Skeleton from '@material-ui/lab/Skeleton';

const BookCardWithdrawRequisition = props => {
    const [t] = useTranslation();
    const {
        image,
        title,
        author,
        exemplarCode,
        applicant,
        status,
        approvedDate,
        onClickCancel,
        onClickApprove
    } = props;
    const styles = screenStyles();

    const handleStatus = status => {
        switch (status) {
            case LibraryReservationStatusValues.AWAITING_WITHDRAWAL:
                return 'Aguardando Retirada';
            default:
                return '';
        }
    };

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(false);
    }, [image]);

    return (
        <React.Fragment>
            <div className={styles.cardContainer}>
                <img
                    className={styles.cardImage}
                    style={!loaded ? { display: 'none' } : {}}
                    src={image}
                    alt={title}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src =
                            window.location.origin + '/img/no-book-cover.png';
                        setLoaded(true);
                    }}
                    onLoad={() => {
                        setLoaded(true);
                    }}
                />
                {!loaded && (
                    <Skeleton
                        className={styles.cardImage}
                        variant="rect"
                        width={160}
                        height={220}
                    />
                )}
                <Typography
                    className={styles.cardTitle}
                    variant={'RobotoBold'}
                    fontSize={12}
                    textColor={'secondary'}
                >
                    {title}
                </Typography>

                <div className={styles.cardAmount}>
                    <Typography
                        className={styles.cardTopic}
                        variant={'RobotoRegular'}
                        fontSize={12}
                        textColor={'secondary'}
                    >
                        <b>Exemplar: </b>
                        {formatExemplarCode('' + exemplarCode)}
                    </Typography>
                </div>

                <Typography
                    className={styles.cardTopic}
                    variant={'RobotoBold'}
                    fontSize={12}
                    textColor={'secondary'}
                >
                    Autor
                </Typography>
                <Typography
                    className={styles.cardAuthor}
                    variant={'RobotoRegular'}
                    fontSize={12}
                    textColor={'secondary'}
                >
                    {author}
                </Typography>

                <Typography
                    className={styles.cardTopic}
                    variant={'RobotoBold'}
                    fontSize={12}
                    textColor={'secondary'}
                >
                    Solicitante
                </Typography>
                <Typography
                    className={styles.cardAuthor}
                    variant={'RobotoRegular'}
                    fontSize={12}
                    textColor={'secondary'}
                >
                    {applicant}
                </Typography>
                <div className={styles.cardAmount}>
                    <Typography
                        className={styles.cardTopic}
                        variant={'RobotoRegular'}
                        fontSize={12}
                        textColor={'secondary'}
                    >
                        <b>Status: </b>
                        {handleStatus(status)}
                    </Typography>
                </div>
                <div className={styles.cardAmount}>
                    <Typography
                        className={styles.cardTopic}
                        variant={'RobotoRegular'}
                        fontSize={12}
                        textColor={'secondary'}
                    >
                        <b>Data de aprovação: </b>
                        {approvedDate}
                    </Typography>
                </div>
                <Buttons
                    className={styles.cardButtonCancelRequisition}
                    tipo="borderBackButton"
                    conteudo="CANCELAR REQUISIÇÃO"
                    onClick={onClickCancel}
                />
                <Buttons
                    className={styles.cardButton}
                    tipo="BN-blue"
                    conteudo="CONFIRMAR RETIRADA"
                    onClick={onClickApprove}
                />
            </div>
        </React.Fragment>
    );
};

export default BookCardWithdrawRequisition;
