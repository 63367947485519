import React from "react";
import "App.css";
import SideBar from "components/layout/sidebar/Sidebar";
import DashboardNavBar from "components/layout/DashboardNavBar";
import EditSoftware from './EditSoftware/EditSoftware'

const CallViewSoftware = ({ match }) => {
  return (
    <div>
      <DashboardNavBar />
      <SideBar />
      <EditSoftware softwareId={match.params.id} context={"view"} />
    </div>
  );
}
export default CallViewSoftware;