/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";

import Paper from "@material-ui/core/Paper";
import Dissatisfied from "@material-ui/icons/SentimentVeryDissatisfied";
import Footer from "components/layout/Footer";
import ClockRecordContext from "context/hrModule/clockRecord/clockRecordContext";
import SettingsContext from "context/hrModule/settings/settingsContext";
import Transition from "components/layout/transition/Transition";
import Breadcrumb from "components/layout/breadcrumb/Breadcrumb";
import CollaboratorsClockRecordsTable from "./CollaboratorsClockRecordsTable";
import InputFilter from "components/inputs/InputFilter";
import FilterContainer from "components/containers/FilterContainer";
import HeaderContainer from "components/containers/HeaderContainer";
import ContentContainer from "components/containers/ContentContainer";

import { SortLabel } from "utils/sort/SortLabel";

import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";

import { screenStyles } from "./Styles"

const CollaboratorsClockRecords = (props) => {
    const classes = useStyles();
    const styles = screenStyles();
    const [t] = useTranslation();
    const clockRecordContext = useContext(ClockRecordContext);
    const settingsContext = useContext(SettingsContext);
    const [findText, setFindText] = useState("");
    const [filterName, setFilterName] = useState("");
    const [filterRuleset, setFilterRuleset] = useState("");
    const [filterJob, setFilterJob] = useState("");
    const [filterStatus, setFilterStatus] = useState("");
    const [dataFilter, setDataFilter] = useState([]);
    const [canClick, setCanClick] = useState(false);
    const [jobsArray, setJobsArray] = useState([
        {
            label: t("GENERAL.allMasculine"),
            value: -1
        }
    ]);


    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }
    const statusArray = [
        {
            label: t("GENERAL.allMasculine"),
            value: -1
        },        
        {
            value: "REGULAR",
            label: t("GENERAL.regular")
        },
        {
            value: "INCONSISTENCY",
            label: t("GENERAL.inconsistency")
        },
    ]

    const ruleset = [
        {
            label: t("GENERAL.allMasculine"),
            value: -1
        },
        {
            value: "Bolsista",
            label: "Bolsista"
        },
        {
            value: "CLT",
            label: "CLT"
        },
        {
            value: "Estagiário",
            label: "Estagiário"
        },
        {
            value: "Residente",
            label: "Residente"
        }    
    ]
    
    const {
        loading,
        setLoading,
        setLoadingFalse,
        getCollaboratorsClockRecords,
        collaboratorsClockRecords
    } = clockRecordContext;

    const {        
        getJobs,
        jobs
    } = settingsContext;

    
    useEffect(() => {
        if (jobs && jobs.length > 0) {
            let aux = jobs.filter(job => !job.deleted).map((job) => {
                return {
                    label: job.name,
                    value: job.name
                }
            })
            setJobsArray([{
                label: t("GENERAL.allMasculine"),
                value: -1
            },...aux.sort(SortLabel)]);
        }
    }, [jobs]); 

    async function fetchData() {
        await setLoading();
        await getJobs();
        await getCollaboratorsClockRecords(auth.id);
        await setLoadingFalse();
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (filterName ||
            filterRuleset ||
            filterJob ||
            filterStatus
        ) { setCanClick(true) }
        else {
            setCanClick(false)
        }
    }, [
        filterName,
        filterRuleset,
        filterJob,
        filterStatus
    ])    

    useEffect(() => {
        if (collaboratorsClockRecords && collaboratorsClockRecords.length) {
            const { newDataFilter, textNoFound } = applyFilter(collaboratorsClockRecords)
            if (newDataFilter.length === 0) {
                setDataFilter([]);
                setHasNoResult(true);
                setFindText(textNoFound);
            } else {
                setDataFilter(newDataFilter);
                setHasNoResult(false);
                setFindText("");
            }
        }
    }, [collaboratorsClockRecords, filterName, filterRuleset, filterJob,filterStatus]);

    const applyFilter = (array) => {
        let newDataFilter = [];
        let textNoFound = "";

        newDataFilter = array;

        if (filterName) {
            newDataFilter = newDataFilter.filter(item =>
                item.name.toLowerCase().includes(filterName.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0) textNoFound = filterName;
        }
        if (filterRuleset && filterRuleset !== -1) {
            newDataFilter = newDataFilter.filter(item =>
                item.ruleset && item.ruleset === filterRuleset
            )

            if (!textNoFound && newDataFilter.length === 0) textNoFound = ruleset.find(x => x.value === filterRuleset).label;
        }

        if (filterJob && filterJob !== -1) {
            newDataFilter = newDataFilter.filter(item =>
                item.job && item.job === filterJob
            )

            if (!textNoFound && newDataFilter.length === 0) textNoFound = jobsArray.find(x => x.value === filterJob).label;
        }

        if (filterStatus && filterStatus !== -1) {
            newDataFilter = newDataFilter.filter(item =>
                item.status && item.status === filterStatus
            )

            if (!textNoFound && newDataFilter.length === 0) textNoFound = statusArray.find(x => x.value === filterStatus).label;
        }

        return { newDataFilter, textNoFound };
    }

    const hasItens = collaboratorsClockRecords.length > 0;
    const [hasNoResult, setHasNoResult] = useState(hasItens && dataFilter.length === 0);

    return (
        <React.Fragment>            
            <Transition loading={loading} newDesign={true}>
                <HeaderContainer>
                    <Breadcrumb
                        newDesign={true}
                        routes={[
                            { label: t("HR.humanResource") , to: "/HumanResources"},
                            { label: t("HR.clock") },
                            { label: t("HR.collaboratorsClockRecords") },
                        ]}
                    />
                    <div className="container-headerNewDesign">
                        <h1 className={classes.titleNewDesign}>{t("HR.collaboratorsClockRecords")}</h1>                        
                    </div>

                </HeaderContainer>
                <ContentContainer>
                    <Paper >
                        <div className="cabecalho">
                            {t("HR.collaboratorsClockRecords").toUpperCase()}
                        </div>
                        <React.Fragment>
                            <FilterContainer>
                                <InputFilter
                                    filterValue={filterName}
                                    setFilter={setFilterName}
                                    label={"GENERAL.name"}
                                    maxLength={60}
                                />

                                <InputFilter
                                    filterValue={filterJob}
                                    setFilter={setFilterJob}
                                    label={"HR.jobs"}
                                    isSelect
                                    arraySelected={jobsArray}
                                />

                                <InputFilter
                                    filterValue={filterRuleset}
                                    setFilter={setFilterRuleset}
                                    label={"HR.contractType"}
                                    isSelect
                                    arraySelected={ruleset}
                                />
                                 <InputFilter
                                    filterValue={filterStatus}
                                    setFilter={setFilterStatus}
                                    label={"GENERAL.status"}
                                    isSelect
                                    arraySelected={statusArray}
                                />
                            </FilterContainer>

                            <CollaboratorsClockRecordsTable
                                rows={dataFilter}
                                toFirstPage={canClick}
                                headers={["id", "name", "job", "ruleset", "status", "inconsistencyQnt", "imageName"]}
                                visibleHeaders={["Nome", "Cargo", "Regime", "Status do Ponto", ""]}
                                sortedFields={["name", "job", "ruleset", "status"]}
                                mainField="name"
                                fetchData={fetchData}
                            />
                            {!hasItens &&
                                <div className={classes.noParticipantsMessage}>
                                    <h2>{t("HR.noCollaboratorClockRecordsMessage")}</h2>
                                    <h4>{t("HR.noCollaboratorClockRecordsSubMessage")}</h4>
                                </div>
                            }
                            {hasNoResult &&
                                <div className={classes.noParticipantsMessage}>
                                    <div >
                                        <Dissatisfied className={classes.notFoundIconStyle} />
                                    </div>
                                    <h2>{t("GENERAL.noResultsFound")}</h2>
                                    <h5>{`Sua busca por "${findText}" não retornou resultados.`}</h5>
                                </div>
                            }
                        </React.Fragment>
                    </Paper>
                </ContentContainer>
                <Footer />
            </Transition>
        </React.Fragment >

    );
};

export default CollaboratorsClockRecords;