/* eslint-disable */
import MomentUtils from "@date-io/moment";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AddCircle from "@material-ui/icons/AddCircle";
import Check from "@material-ui/icons/Check";
import InsertInvitationIcon from "@material-ui/icons/InsertInvitation";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useStyles } from "components/layout/CommonStyles";
import Footer from "components/layout/Footer";
import MySnackbarContentWrapper from "components/layout/Message";
import Spinner from "components/layout/spinners/Index";
import AllowancesContext from "context/allowance/allowanceContext";
import HRCostsContext from "context/hrcosts/hrCostsContext";
import moment from "moment";
import "moment/locale/pt-br";
import React, { useContext, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { animated, useTransition } from "react-spring";
import Buttons from "../buttons/Pressed";
import "../profiles/css/FormStyle.css";
import NewFieldCost from "./NewFieldCost";
import ProjectsContext from "context/projects/projectsContext";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles
} from "@material-ui/core/styles";
import Zoom from "@material-ui/core/Zoom";


const tooltipTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#2c3e51"
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: "white",
        fontSize: "1em",
        backgroundColor: "red",
        opacity: "0.7"
      }
    }
  }
});

const EditForm = props => {
  const defaultImage = window.location.origin + "/img/user.jpg";
  const classes = useStyles();
  const { id } = props;
  const [delay, setDelay] = useState(true);
  const [open, setOpen] = useState(false);
  const [createField, setcreateField] = React.useState(false);
  const [costField, setcostField] = useState([]);
  const [baseDate, setbaseDate] = useState(new Date());
  const [totalStr, setTotalStr] = useState("0");
  const [costs, setCosts] = useState([]);
  const [costsCount, setCostsCount] = useState(3);
  const [fieldDescription, setFieldDescription] = useState("");
  const [salary, setSalary] = useState("");
  const [count, setCount] = useState(0);
  const [field, setfield] = useState("");
  const [verifySubmit, setVerifySubmit] = useState(false);
  const [selectedDate, handleDateChange] = useState(new Date());
  const [contrType, setContrType] = useState(1);
  const [RhType, setRhType] = useState(0);
  const [currentContractwithdate, setcurrentContractwithdate] = useState("")
  const [gpfArray, setGpfArray] = useState([]);
  const [validName, setValidName] = useState(false);
  const [valiTotal, setValidTotal] = useState(false);
  const nameRef = useRef(null);
  const totalRef = useRef(null);


  const handleClickOpenField = () => {
    setcreateField(true);
  };

  const handleCloseField = () => {
    setcreateField(false);
  };

  const closeVerify = () => {
    setVerifySubmit(false);
  };

  const [t] = useTranslation();
  const [currentCosts, setCurrentCosts] = useState([]);
  const [update, setUp] = useState(false);
  const hrCostsContext = useContext(HRCostsContext);
  const {
    user,
    userCosts,
    getUser,
    getUserCosts,
    loading,
    resetMessage,
    submitCosts,
    hasChanged,
    setHasChanged,
    response,
    responseType,
    resetUserCosts,
    successiveActions,
    userContract,
    getUserContract
  } = hrCostsContext;
  const allowanceContext = useContext(AllowancesContext);

  const {
    allowanceResponse,
    allowanceResponseType,
    resetAllowanceMessage
  } = allowanceContext;

  const projectsContext = useContext(ProjectsContext);
  const { projects, getProjects } = projectsContext;

  const [state, setState] = useState({
    id: 0,
    cpf: "",
    name: "",
    contractType: "",
    gpf: "",
    transportationVoucher: "",
    imagePath: "",
    salary: "",
    fgts: "",
    administrativeFee: "",
    irrf: "",
    pis: "",
    inss: "",
    sumOfDebts: "",
    healthCare: "",
    mealAllowance: "",
    dentalPlan: "",
    iss: "",
    total: "",
    RhType: "0",
    numbercraft: "",
    projectId: 0
  });

  const [snackBar, setSnackBar] = useState({
    open: false,
    kind: "",
    content: ""
  });

  const handleSnack = () => {
    setSnackBar({ ...snackBar, open: true });
  };

  const handleSnackClose = () => {
    setSnackBar({ ...snackBar, open: false });
    setHasChanged();
    resetAllowanceMessage();
  };

  const internFields = [
    "REMUNERAÇÃO",
    "TAXA ADMINISTRATIVA",
    "VALE TRANSPORTE"
  ];
  const cltFields = [
    "REMUNERAÇÃO",
    "FGTS",
    "IRRF",
    "PIS",
    "INSS",
    "PASSIVO TRABALHISTA",
    "PLANO DE SAÚDE",
    "VALE ALIMENTAÇÃO",
    "PLANO ODONTOLÓGICO"
  ];
  const rpaFields = ["REMUNERAÇÃO", "INSS", "ISS", "IRF"];
  const scholarshipHolderFields = ["REMUNERAÇÃO"];

  const contractTypesDict = {
    Intern: {
      name: "Estagiário",
      value: "0",
      fields: internFields,
      costsCount: 3
    },
    CLT: { name: "CLT", value: "1", fields: cltFields, costsCount: 9 },
    RPA: { name: "RPA", value: "2", fields: rpaFields, costsCount: 4 },
    ScholarshipHolder: {
      name: "Bolsista",
      value: "3",
      fields: scholarshipHolderFields,
      costsCount: 1
    }
  };

  const contractValues = {
    0: "Intern",
    1: "CLT",
    2: "RPA",
    3: "ScholarshipHolder"
  };

  const RhValues = {
    0: "Direto",
    1: "Indireto",
  };

  const Rhoptions = [
    {
      value: 0,
      label: "Direto"
    },
    {
      value: 1,
      label: "Indireto"
    }
  ];

  const contractTypes = [
    {
      value: contractTypesDict["Intern"].value,
      label: contractTypesDict["Intern"].name
    },
    {
      value: contractTypesDict["CLT"].value,
      label: contractTypesDict["CLT"].name
    },
    {
      value: contractTypesDict["RPA"].value,
      label: contractTypesDict["RPA"].name
    },
    {
      value: contractTypesDict["ScholarshipHolder"].value,
      label: contractTypesDict["ScholarshipHolder"].name
    }
  ];

  const userContractDict = {
    Estagiário: 0,
    CLT: 1,
    "Serviços Prestados": 2,
    "Pessoa Jurídica": 2,
    Bolsista: 3,
    Professor: 3
  };

  const addField = (costName, cost, isLoading, name) => {
    var nameOfClass = name
      ? contractValues[name]
      : contractValues[state.contractType];

    var number = isLoading
      ? currentCosts.length + 1 + costs.length
      : contractTypesDict[nameOfClass].fields.length + 1;
    var costVal = isLoading ? cost : "";
    var description =
      isLoading !== true ? fieldDescription.toUpperCase() : costName;
    setCount(count + 1);

    var len = contractTypesDict[nameOfClass].costsCount;

    costs.push(
      <NewFieldCost
        key={number + costs.length}
        opacity={1}
        fieldDescription={description}
        nameOfClass={nameOfClass}
        id={"fieldValue" + (costs.length + len + 1)}
        updateTotalField={updateTotalField}
        costValue={costVal}
      />
    );

    setcreateField(false);
    setFieldDescription("");
  };

  useEffect(() => {
    if (hasChanged) {
      resetUserCosts();
    }
    return () => {};
  }, [hasChanged]);

  var date = new Date();
  useEffect(() => {
    const timer = setTimeout(() => {
      if (responseType === "SUCCESS" && hasChanged > successiveActions) {
        var len = costs.length;
        costs.splice(0, len);
        setCostsCount(0);
        //  getUserCosts(id);
        handleSnack({ kind: "success", content: response });
        for (var i in userCosts) {
          if (new Date(userCosts[i].creationDate) > date ) {
            date = new Date(userCosts[i].creationDate);
          }
        }
        handleDateChange(new Date(date));
      }
      if (responseType === "ERROR" && hasChanged > successiveActions) {
        handleSnack({ kind: "error", content: response });
      }
      setDelay(false);
    }, 600);

    return () => {
      clearTimeout(timer);
    };
  }, [responseType, response, successiveActions, userCosts]);

  useEffect(() => {
    var monthCosts = [];
    getUser(id);
    getProjects();

    const timer = setTimeout(() => {
      var currentContract = userCosts.filter(
        el => el.costMonth === new Date().getMonth() + 1
      )[0]
        ? userCosts.filter(el => el.costMonth === new Date().getMonth() + 1)[0]
            .contractType
        : "";

        for (var i in userCosts) {
          if (new Date(userCosts[i].creationDate) > date ) {
            date = new Date(userCosts[i].creationDate);
            
          }
        }
        
        handleDateChange(new Date(date));

      if (currentContract)
        monthCosts = userCosts.filter(
          el =>
            el.costMonth === new Date(date).getMonth() + 1 &&
            el.contractType === currentContract
        );

      setCurrentCosts(monthCosts);

      var total = 0;
      monthCosts.map(function(el) {
        if (el.costName !== "TotalCost") {
          total += el.cost;
        }
      });

      if (user.ruleSetId) {
        getUserContract(user.ruleSetId);
      }

      
      // var currentContract = userCosts.filter(
      //   el => el.costMonth === new Date().getMonth() + 1
      // )[0]
      //   ? userCosts.filter(el => el.costMonth === new Date().getMonth() + 1)[0]
      //       .contractType
      //   : "";
      setState({
        ...state,
        id: id,
        cpf: user.cpf ? user.cpf : "",
        name: user.name ? user.name : "",
        gpf: monthCosts.length > 0 ? monthCosts[0].gpf : "",
        projectId: monthCosts.length > 0 ? monthCosts[0].projectId : 0,
        numbercraft: monthCosts.length  ? monthCosts[0].numberCraft : "",
        RhType: monthCosts.length ? monthCosts[0].rhType : "0",
        imagePath: user.imagePath ? user.imagePath : "",
        salary: monthCosts.filter(el => el.costName === "Salary")[0]
          ? setBrazilCurrency(
              monthCosts.filter(el => el.costName === "Salary")[0].cost
            )
          : "",
        fgts: monthCosts.filter(el => el.costName === "FGTS")[0]
          ? setBrazilCurrency(
              monthCosts.filter(el => el.costName === "FGTS")[0].cost
            )
          : "",
        irrf: monthCosts.filter(el => el.costName === "IRRF")[0]
          ? setBrazilCurrency(
              monthCosts.filter(el => el.costName === "IRRF")[0].cost
            )
          : "",
        pis: monthCosts.filter(el => el.costName === "PIS")[0]
          ? setBrazilCurrency(
              monthCosts.filter(el => el.costName === "PIS")[0].cost
            )
          : "",
        inss: monthCosts.filter(el => el.costName === "INSS")[0]
          ? setBrazilCurrency(
              monthCosts.filter(el => el.costName === "INSS")[0].cost
            )
          : "",
        iss: monthCosts.filter(el => el.costName === "ISS")[0]
          ? setBrazilCurrency(
              monthCosts.filter(el => el.costName === "ISS")[0].cost
            )
          : "",
        sumOfDebts: monthCosts.filter(el => el.costName === "SumOfDebts")[0]
          ? setBrazilCurrency(
              monthCosts.filter(el => el.costName === "SumOfDebts")[0].cost
            )
          : "",
        healthCare: monthCosts.filter(el => el.costName === "HealthCare")[0]
          ? setBrazilCurrency(
              monthCosts.filter(el => el.costName === "HealthCare")[0].cost
            )
          : "",
        dentalPlan: monthCosts.filter(el => el.costName === "DentalPlan")[0]
          ? setBrazilCurrency(
              monthCosts.filter(el => el.costName === "DentalPlan")[0].cost
            )
          : "",
        mealAllowance: monthCosts.filter(
          el => el.costName === "MealAllowance"
        )[0]
          ? setBrazilCurrency(
              monthCosts.filter(el => el.costName === "MealAllowance")[0].cost
            )
          : "",
        transportationVoucher: monthCosts.filter(
          el => el.costName === "TransportationVoucher"
        )[0]
          ? setBrazilCurrency(
              monthCosts.filter(
                el => el.costName === "TransportationVoucher"
              )[0].cost
            )
          : "",
        administrativeFee: monthCosts.filter(
          el => el.costName === "AdministrativeFee"
        )[0]
          ? setBrazilCurrency(
              monthCosts.filter(el => el.costName === "AdministrativeFee")[0]
                .cost
            )
          : "",
        total: monthCosts.filter(el => el.costName === "TotalCost")[0]
          ? setBrazilCurrency(
              monthCosts.filter(el => el.costName === "TotalCost")[0].cost
            )
          : "",
        contractType: contractTypesDict[currentContract]
          ? parseInt(contractTypesDict[currentContract].value)
          : userContract.name
          ? userContractDict[userContract.name]
          : 1
      });

      setContrType(
        contractTypesDict[currentContract]
          ? parseInt(contractTypesDict[currentContract].value)
          : 1
      );

      var len = costs.length;
      costs.splice(0, len);
      setCostsCount(0);

      var otherCosts = monthCosts.filter(el => el.costName === "OtherCost");

      if (otherCosts.length > 0) {
        var len = otherCosts.length;

        for (var i = 0; i < len; i++) {
          var name = contractTypesDict[currentContract]
            ? parseInt(contractTypesDict[currentContract].value)
            : "CLT";
          addField(
            otherCosts[i].othercostname,
            setBrazilCurrency(otherCosts[i].cost),
            true,
            name
          );
        }
      }

      if (total !== 0) {
        var totalFormatted = total
          .toFixed(2)
          .split("")
          .filter(el => el !== "." && el !== ",")
          .join("");

        convertTotalToBrCurrency(totalFormatted);
      } else {
        setTotalStr("");
      }
    }, 100);

    if (Object.keys(projects).length > 0) {
      getProjectsgpf();
    }

    return () => {
      clearTimeout(timer);
    };
  }, [
    id,
    user.imagePath,
    Object.keys(projects).length,
    Object.keys(userCosts).length,
    JSON.stringify(userCosts),
    userContract.id,
    update,
    handleDateChange
  ]);

  const getProjectsgpf = () => {
    var gpfProjects = [];
    var aux = {};
    for (var i in projects) {
      var item = projects[i];
      aux.value = item.id;
      aux.gpf = item.gpf;
      aux.label = item.gpf + " - " + item.name;
      gpfProjects.push(aux);
      aux = {};
    }
    setGpfArray(gpfProjects)
  }

  const updateState = type => {
    var monthCosts = [];
    setCurrentCosts([]);

    var auxDate = selectedDate._d ? selectedDate._d : selectedDate;
    monthCosts = userCosts.filter(
      el =>
        el.costMonth === auxDate.getMonth() + 1 &&
        el.costYear === auxDate.getFullYear() &&
        el.contractType === type
    );

    setCurrentCosts(monthCosts);

    var total = 0;
    monthCosts.map(function(el) {
      if (el.costName !== "TotalCost") total += el.cost;
    });

    setState({
      ...state,
      gpf: monthCosts.length > 0 ? monthCosts[0].gpf : "",
      numbercraft: monthCosts.length > 0 ? monthCosts[0].numberCraft : "",
      RhType: monthCosts.length > 0 ? monthCosts[0].rhType : "0",
      projectId: monthCosts.length > 0 ? monthCosts[0].projectId : 0,
      salary: monthCosts.filter(el => el.costName === "Salary")[0]
        ? setBrazilCurrency(
            monthCosts.filter(el => el.costName === "Salary")[0].cost
          )
        : "",
      fgts: monthCosts.filter(el => el.costName === "FGTS")[0]
        ? setBrazilCurrency(
            monthCosts.filter(el => el.costName === "FGTS")[0].cost
          )
        : "",
      irrf: monthCosts.filter(el => el.costName === "IRRF")[0]
        ? setBrazilCurrency(
            monthCosts.filter(el => el.costName === "IRRF")[0].cost
          )
        : "",
      pis: monthCosts.filter(el => el.costName === "PIS")[0]
        ? setBrazilCurrency(
            monthCosts.filter(el => el.costName === "PIS")[0].cost
          )
        : "",
      inss: monthCosts.filter(el => el.costName === "INSS")[0]
        ? setBrazilCurrency(
            monthCosts.filter(el => el.costName === "INSS")[0].cost
          )
        : "",
      iss: monthCosts.filter(el => el.costName === "ISS")[0]
        ? setBrazilCurrency(
            monthCosts.filter(el => el.costName === "ISS")[0].cost
          )
        : "",
      sumOfDebts: monthCosts.filter(el => el.costName === "SumOfDebts")[0]
        ? setBrazilCurrency(
            monthCosts.filter(el => el.costName === "SumOfDebts")[0].cost
          )
        : "",
      healthCare: monthCosts.filter(el => el.costName === "HealthCare")[0]
        ? setBrazilCurrency(
            monthCosts.filter(el => el.costName === "HealthCare")[0].cost
          )
        : "",
      dentalPlan: monthCosts.filter(el => el.costName === "DentalPlan")[0]
        ? setBrazilCurrency(
            monthCosts.filter(el => el.costName === "DentalPlan")[0].cost
          )
        : "",
      mealAllowance: monthCosts.filter(el => el.costName === "MealAllowance")[0]
        ? setBrazilCurrency(
            monthCosts.filter(el => el.costName === "MealAllowance")[0].cost
          )
        : "",
      transportationVoucher: monthCosts.filter(
        el => el.costName === "TransportationVoucher"
      )[0]
        ? setBrazilCurrency(
            monthCosts.filter(el => el.costName === "TransportationVoucher")[0]
              .cost
          )
        : "",
      administrativeFee: monthCosts.filter(
        el => el.costName === "AdministrativeFee"
      )[0]
        ? setBrazilCurrency(
            monthCosts.filter(el => el.costName === "AdministrativeFee")[0].cost
          )
        : "",
        total: monthCosts.filter(el => el.costName === "TotalCost")[0] ? setBrazilCurrency(monthCosts.filter(el => el.costName === "TotalCost")[0].cost): "",

    });
    var otherCosts = monthCosts.filter(el => el.costName === "OtherCost");
    if (otherCosts.length > 0) {
      var len = otherCosts.length;

      for (var i = 0; i < len; i++) {
        addField(
          otherCosts[i].othercostname,
          setBrazilCurrency(otherCosts[i].cost),
          true
        );
      }
    }

    if (total !== 0) {
      var totalFormatted = total
        .toFixed(2)
        .split("")
        .filter(el => el !== "." && el !== ",")
        .join("");

      convertTotalToBrCurrency(totalFormatted);
    } else {
      setTotalStr("");
    }
  };

  useEffect(() => {
    if (state.contractType === 0) {
      setfield(0);
    } else if (state.contractType === 1) {
      setfield(1);
    }
  }, [costs.length, count]);

  useEffect(() => {
    switch (state.contractType) {
      case 0:
        updateFields("Intern");
        break;
      case 1:
        updateFields("CLT");
        break;
      case 2:
        updateFields("RPA");
        break;
      case 3:
        updateFields("ScholarshipHolder");
        break;
      default:
        break;
    }

    if(selectedDate._d){
      var currentContractwithdate1 = userCosts.filter(
        el => moment(el.creationDate).format("L") == moment(selectedDate._d).format("L")
      );
    }
  }, [selectedDate, contrType, currentContractwithdate]);

  const regime = ()=>{
    return (
    <TextField
    select
    id="contractTypes"
    label={t("HRCOSTS.contractType")}
    name="contractTypes"
    value={state.contractType}
    className={classes.textField}
    onChange={handleChange("contractType")}
    margin="normal"
  >
    {contractTypes.map(option => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))}
  </TextField>
    )
  }

  useEffect(() => {
    return () => {
      resetMessage();
    };
  }, []);

  const addFieldCall = () => {
    var costs2 = [];
    for (var i in costs) {
      costs2.push(costs[i]);
    }
    return costs2;
  };

  const updateFields = type => {
    var len = costs.length;
    costs.splice(0, len);
    setCostsCount(0);
    updateState(type);
  };

  const handleMessageOpen = () => {
    setOpen(false);
  };

  const handleChangeGPF = name => event => {
    setValidName(false);
    setValidTotal(false);
    var gpf = gpfArray.filter(el => el.value === event.target.value)
    setState({ ...state, [name]: gpf[0].gpf,
                        projectId: event.target.value});
  };

  const converTotBrCurrency = number => {
    var temp = "";
    var len = number.length;
    var count = 0;

    for (var i = 0; i < len; i++) {
      if (i === 1) {
        var aux = "," + number[i] + temp;
        temp = aux;
      } else {
        if (count === 3) {
          count = 1;
          var aux = number[i] + "." + temp;
          temp = aux;
        } else {
          if (i > 1) {
            count += 1;
          }
          var aux = number[i] + temp;
          temp = aux;
        }
      }
    }
    return temp;
  };

  const setBrazilCurrency = cost => {
    cost = cost.toString().split("");

    if (
      (cost[cost.length - 1] === "0" && cost[cost.length - 2] === "0") ||
      cost[cost.length - 3] !== "."
    ) {
      if (cost[cost.length - 2] === ".") {
        cost.push("0");
      } else {
        cost.push("0");
        cost.push("0");
      }
    }

    cost = cost
      .filter(el => el !== "." && el !== ",")
      .join("")
      .split("")
      .reverse()
      .join("");

    return converTotBrCurrency(cost);
  };

  const convertTotalToBrCurrency = total => {
    var aux = total
      .toString()
      .split("")
      .filter(el => el !== "." && el !== ",")
      .join("");

    var value = converTotBrCurrency(
      aux
        .split("")
        .reverse()
        .join("")
    );

    setTotalStr(value);
  };

  const handleFieldChange = (name, contract) => event => {
    if (event.target.value !== " " && !event.target.value.includes(" ")) {
      var str = event.target.value.split("");
      var aux = str.filter(el => el !== "." && el !== ",").join("");
      var value = event.target.value;

      if (event.target.value) {
        value = converTotBrCurrency(
          aux
            .split("")
            .reverse()
            .join("")
        );
      }

      aux = value
        .split("")
        .filter(el => el !== ".")
        .join("")
        .replace(",", ".");
      if (!isNaN(aux)) {
        setState({ ...state, [name]: value });
      }
    }

    if(name === "total"){
      setValidTotal(false)
    }

    if (name !== "total")
      setTimeout(function() {
        updateTotalField(contract);
      }, 1);
  };

  const updateTotalField = classename => {
    var name = isNaN(classename) ? classename : contractValues[classename];
    var len = contractTypesDict[name].costsCount + costs.length;
    var total = 0;

    for (var i = 0; i < len; i++) {
      var value = document.getElementById("fieldValue" + (i + 1))
        ? document.getElementById("fieldValue" + (i + 1)).value
        : null;

      if (value) {
        var currencyStr = value
          .split("")
          .filter(el => el !== ".")
          .join("")
          .replace(",", ".");
        total += parseFloat(currencyStr);
      }
    }

    convertTotalToBrCurrency(total.toFixed(2));
  };

  const handleChangeDescription = event => {
    setFieldDescription(event.target.value);
  };

  const totalCostIsDifferent = () => {
    var totalCost =
      state.total !== ""
        ? parseFloat(
            state.total
              .split("")
              .filter(el => el !== ".")
              .join("")
              .replace(",", ".")
          )
        : 0;
    var calculatedCost = parseFloat(
      totalStr
        .split("")
        .filter(el => el !== ".")
        .join("")
        .replace(",", ".")
    );

    return !isNaN(calculatedCost) && totalCost !== calculatedCost;
  };

  const handleVerifySubmit = () => {
    var month = moment(selectedDate)._d.getMonth() + 1;
    var year = moment(selectedDate)._d.getFullYear();
    var hasProject = false;
    var hasTotal = false;

    var canSubmit =
      userCosts.filter(
        el =>
          new Date(el.creationDate).getMonth() + 1 === month &&
          new Date(el.creationDate).getFullYear() === year &&
          el.contractType !== contractValues[state.contractType]
      ).length === 0;

    if (state.gpf){
      hasProject = true;
    }

    if(state.total){
      hasTotal = true
    }

    if(hasProject){
      if(hasTotal){
        if (canSubmit) submit();
        else setVerifySubmit(true);
      }else{
        setValidTotal(true);
      }  
    }else{
      //nameRef.current.focus();
      setValidName(true);
    }
  };

  const submit = () => {
    setVerifySubmit(false);
    var className = contractValues[state.contractType];
    var len = document.getElementsByClassName(className).length;
    var allCosts = [];

    for (var i = 0; i < len; i++) {
      var aux = {};
      var costId = 0;
      var el = document.getElementById("fieldValue" + (i + 1));
      aux.costName = el.name;

      if (
        i + 1 >
        contractTypesDict[contractValues[state.contractType]].costsCount
      )
        costId = currentCosts.filter(el => el.othercostname === aux.costName)[0]
          ? currentCosts.filter(el => el.othercostname === aux.costName)[0].id
          : 0;
      else
        costId = currentCosts.filter(el => el.costName === aux.costName)[0]
          ? currentCosts.filter(el => el.costName === aux.costName)[0].id
          : 0;

      aux.id = costId;
      aux.cost = el.value
        .split("")
        .filter(el => el !== ".")
        .join("")
        .replace(",", ".");
      allCosts.push(aux);
    }

    var aux = {};
    aux.costName = "TotalCost";
    aux.id = currentCosts.filter(el => el.costName === "TotalCost")[0]
      ? currentCosts.filter(el => el.costName === "TotalCost")[0].id
      : 0;
    aux.cost = state.total
      .split("")
      .filter(el => el !== ".")
      .join("")
      .replace(",", ".");
    allCosts.push(aux);

    var toSubmit = {
      gpf: "",
      RhType: 0,
      projectId: 0,
      numberCraft: "",
      contractType: "",
      costDate: "",
      userId: "",
      allCosts: []
    };

    toSubmit["gpf"] = state.gpf;
    toSubmit["costDate"] = moment(selectedDate).format("L");
    toSubmit["contractType"] = state.contractType;
    toSubmit["userId"] = id;
    toSubmit["allCosts"] = allCosts;
    toSubmit["RhType"] = state.RhType;
    toSubmit["projectId"] = state.projectId;
    toSubmit["numberCraft"] = state.numbercraft;

    
    if(state.total !== ""){
      submitCosts(toSubmit);
      setDelay(true);
      setUp(true);
    }else{
      console.log("")
    }
    
  };

  const changeContractType = () => {
    return state.contractType === 0 ? (
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <div className="Intern">
            <div style={{ marginTop: "25px", fontWeight: "bold" }}>
              REMUNERAÇÃO
            </div>
          </div>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id={"fieldValue1"}
            //  inputRef={estimatedCostRef}
            className={classes.textField}
            margin="normal"
            name="Salary"
            value={state.salary}
            onChange={handleFieldChange("salary", "Intern")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <div className="Intern">
            <div className={classes.CostNames}>VALE TRANSPORTE</div>
          </div>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id={"fieldValue2"}
            //  inputRef={estimatedCostRef}
            className={classes.textField}
            margin="normal"
            name="TransportationVoucher"
            value={state.transportationVoucher}
            onChange={handleFieldChange("transportationVoucher", "Intern")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <div className="Intern">
            <div className={classes.CostNames}>TAXA ADMINISTRATIVA</div>
          </div>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id={"fieldValue3"}
            //  inputRef={estimatedCostRef}
            className={classes.textField}
            margin="normal"
            name="AdministrativeFee"
            value={state.administrativeFee}
            onChange={handleFieldChange("administrativeFee", "Intern")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              )
            }}
          />
        </Grid>
        {addFieldCall()}
      </Grid>
    ) : state.contractType === 1 ? (
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <div className="CLT">
            <div style={{ marginTop: "25px", fontWeight: "bold" }}>
              REMUNERAÇÃO
            </div>
          </div>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id={"fieldValue1"}
            className={classes.textField}
            margin="normal"
            name="Salary"
            value={state.salary}
            onChange={handleFieldChange("salary", "CLT")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <div className="CLT">
            <div className={classes.CostNames}>FGTS</div>
          </div>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id={"fieldValue2"}
            //  inputRef={estimatedCostRef}
            className={classes.textField}
            margin="normal"
            name="FGTS"
            value={state.fgts}
            onChange={handleFieldChange("fgts", "CLT")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <div className="CLT">
            <div className={classes.CostNames}>IRRF</div>
          </div>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id={"fieldValue3"}
            //  inputRef={estimatedCostRef}
            className={classes.textField}
            margin="normal"
            name="IRRF"
            value={state.irrf}
            onChange={handleFieldChange("irrf", "CLT")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <div className="CLT">
            <div className={classes.CostNames}>PIS</div>
          </div>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id={"fieldValue4"}
            //  inputRef={estimatedCostRef}
            className={classes.textField}
            margin="normal"
            name="PIS"
            value={state.pis}
            onChange={handleFieldChange("pis", "CLT")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <div className="CLT">
            <div className={classes.CostNames}>INSS</div>
          </div>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id={"fieldValue5"}
            //  inputRef={estimatedCostRef}
            className={classes.textField}
            margin="normal"
            name="INSS"
            value={state.inss}
            onChange={handleFieldChange("inss", "CLT")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <div className="CLT">
            <div className={classes.CostNames}>PASSIVO TRABALHISTA</div>
          </div>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id={"fieldValue6"}
            //  inputRef={estimatedCostRef}
            className={classes.textField}
            margin="normal"
            name="SumOfDebts"
            value={state.sumOfDebts}
            onChange={handleFieldChange("sumOfDebts", "CLT")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <div className="CLT">
            <div className={classes.CostNames}>PLANO DE SAÚDE</div>
          </div>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id={"fieldValue7"}
            //  inputRef={estimatedCostRef}
            className={classes.textField}
            margin="normal"
            name="HealthCare"
            value={state.healthCare}
            onChange={handleFieldChange("healthCare", "CLT")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <div className="CLT">
            <div className={classes.CostNames}>VALE ALIMENTAÇÃO</div>
          </div>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id={"fieldValue8"}
            //  inputRef={estimatedCostRef}
            className={classes.textField}
            margin="normal"
            name="MealAllowance"
            value={state.mealAllowance}
            onChange={handleFieldChange("mealAllowance", "CLT")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <div className="CLT">
            <div className={classes.CostNames}>PLANO ODONTOLÓGICO</div>
          </div>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id={"fieldValue9"}
            //  inputRef={estimatedCostRef}
            className={classes.textField}
            margin="normal"
            name="DentalPlan"
            value={state.dentalPlan}
            onChange={handleFieldChange("dentalPlan", "CLT")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              )
            }}
          />
        </Grid>
        {addFieldCall()}
      </Grid>
    ) : state.contractType === 2 ? (
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <div className="RPA">
            <div style={{ marginTop: "25px", fontWeight: "bold" }}>
              REMUNERAÇÃO
            </div>
          </div>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id={"fieldValue1"}
            //  inputRef={estimatedCostRef}
            className={classes.textField}
            margin="normal"
            name="Salary"
            value={state.salary}
            onChange={handleFieldChange("salary", "RPA")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <div className="RPA">
            <div className={classes.CostNames}>INSS</div>
          </div>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id={"fieldValue2"}
            //  inputRef={estimatedCostRef}
            className={classes.textField}
            margin="normal"
            name="INSS"
            value={state.inss}
            onChange={handleFieldChange("inss", "RPA")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <div className="RPA">
            <div className={classes.CostNames}>ISS</div>
          </div>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id={"fieldValue3"}
            //  inputRef={estimatedCostRef}
            className={classes.textField}
            margin="normal"
            name="ISS"
            value={state.iss}
            onChange={handleFieldChange("iss", "RPA")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <div className="RPA">
            <div className={classes.CostNames}>IRF</div>
          </div>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id={"fieldValue4"}
            //  inputRef={estimatedCostRef}
            className={classes.textField}
            margin="normal"
            name="IRRF"
            value={state.irrf}
            onChange={handleFieldChange("irrf", "RPA")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              )
            }}
          />
        </Grid>
        {addFieldCall()}
      </Grid>
    ) : state.contractType === 3 ? (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <div className="ScholarshipHolder">
              <div style={{ marginTop: "25px", fontWeight: "bold" }}>
                REMUNERAÇÃO
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <TextField
              id={"fieldValue1"}
              //  inputRef={estimatedCostRef}
              className={classes.textField}
              margin="normal"
              name="Salary"
              value={state.salary}
              onChange={handleFieldChange("salary", "ScholarshipHolder")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                )
              }}
            />
          </Grid>
          {addFieldCall()}
        </Grid>
      </div>
    ) : (
      <></>
    );
  };

  const handleChange = name => event => {
    var n = parseInt(event.target.value);
    if (name === "contractType") setContrType(n);
    if (name === "RhType") {
      if(n === 0 ){
        setRhType("direct");
      }else{
        setRhType("indirect");
      }
    }
    setState({ ...state, [name]: n });
  };

  const handleChangeCraft = name => event => {
      setState({
        ...state,
        [name]: event.target.value
    });
  };



  const transitions = useTransition(loading, null, {
    from: { opacity: 0 },
    enter: {
      opacity: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "calc(100vh - 80px)"
    },
    leave: { opacity: 0 }
  });

  const handleClickOpen = e => {
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return transitions.map(({ item, key, props }) =>
    item ? (
      <animated.div key={key} style={props}>
        <Spinner />
      </animated.div>
    ) : (
      <animated.div key={key} style={props}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={snackBar.open}
          onClose={handleSnackClose}
          TransitionComponent={Slide}
          transitionDuration={{ enter: 500, exit: 500 }}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          autoHideDuration={2000}
        >
          <MySnackbarContentWrapper
            onClose={handleSnackClose}
            variant={
              responseType !== ""
                ? responseType.toLowerCase()
                : allowanceResponseType.toLowerCase()
            }
            message={
              <span id="message-id">
                {response !== "" ? response : allowanceResponse}
              </span>
            }
          />
        </Snackbar>
        <div className="container">
          <Breadcrumbs
            className={classes.breadCrumbs}
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Typography>{t("GENERAL.workPlan")}</Typography>
            <Link className={classes.link} to="/userscosts">
              {t("HRCOSTS.hrCosts")}
            </Link>
            <Typography>{t("HRCOSTS.edit")}</Typography>
          </Breadcrumbs>
          <div className="container-header">
            <div className="title">
              <h1 className={classes.title}>{t("HRCOSTS.edit")}</h1>
            </div>
            <Button
              className={classes.blueButton}
              variant="contained"
              color="primary"
              onClick={handleVerifySubmit}
            >
              <div className={classes.buttonIcon}>
                <Check />
              </div>
              {t("HRCOSTS.updateCosts").toUpperCase()}
            </Button>
          </div>
          <form id="formUsers">
            <Paper className={classes.root}>
              <div className={classes.header}>
                {t("informações do colaborador").toUpperCase()}
              </div>
              <div className={classes.bodyForm}>
                <Grid container>
                  <Hidden mdDown>
                    <Grid item lg={2}>
                      <div className={classes.avatarParent}>
                        <div
                          className="avatar"
                          style={{
                            backgroundImage: `url(${
                              defaultImage + state.imagePath !== ""
                                ? state.imagePath
                                : "/img/icon-default.png"
                            })`
                          }}
                        ></div>
                      </div>
                    </Grid>
                  </Hidden>
                  <Grid container item spacing={2} md={12} lg={10}>
                    <Grid item xs={4}>
                      <TextField
                        disabled
                        className={classes.textField}
                        margin="normal"
                        id="name"
                        label={t("Nome do Colaborador")}
                        name="name"
                        value={state.name}
                        autoFocus
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        disabled
                        className={classes.textField}
                        margin="normal"
                        id="cpf"
                        label="CPF"
                        name="CPF"
                        value={state.cpf}
                        autoFocus
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                     {regime()}
                    </Grid>
                    <Grid item xs={3}>
                      <MuiPickersUtilsProvider locale="pt" utils={MomentUtils}>
                        <DatePicker
                          id="yearBase"
                          variant="inline"
                          openTo="year"
                          name="yearBase"
                          views={["year", "month"]}
                          label={t("HRCOSTS.yearBase")}
                          margin="normal"
                          //disableToolbar
                          autoOk={true}
                          value={selectedDate}
                          onChange={handleDateChange}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <InsertInvitationIcon color="action" />
                              </InputAdornment>
                            )
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </form>
          <br />
          <br />
          <Paper className={classes.root}>
            <div className={classes.header}>
              {t("HRCOSTS.contractTypeInfo").toUpperCase()}
              <div className={classes.addendumIntermIcons}>
                <div className={classes.addendumIntermIconsMarginRight}>
                  <Tooltip title={t("HRCOSTS.addInfor")} placement="top">
                    <div>
                      <IconButton onClick={handleClickOpenField}>
                        <AddCircle />
                      </IconButton>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div></div>
            <Grid item xs={12}>
              <p className={classes.Hint}>{t("HRCOSTS.hint")}</p>
            </Grid>
            <div className={classes.bodyForm}>
            <Grid container item spacing={2} md={12} lg={10}>
              <Grid item xs={4} >
              <MuiThemeProvider theme={tooltipTheme}>
                <Tooltip
                  TransitionComponent={Zoom}
                  open={validName}
                  title={t("Campo obrigatório")}
                >
              <TextField
                inputRef={nameRef}
                error={validName}
                select
                id={"gpf"}
                //inputRef={estimatedCostRef}
                label={t("HRCOSTS.GpfProject")}
                className={classes.textField}
                margin="normal"
                name="gpf"
                value={state.projectId}
                readOnly={true}
                onChange={handleChangeGPF("gpf")}
                
              >
                {gpfArray.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              </Tooltip>
              </MuiThemeProvider>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  select
                  id="RhType"
                  label={t("HRCOSTS.HrType")+"*"}
                  name="RhType"
                  value={state.RhType}
                  className={classes.textField}
                  onChange={handleChange("RhType")}
                  margin="normal"
                  >
                  {Rhoptions.map(option => (
                      <MenuItem  key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  className={classes.textField}
                  margin="normal"
                  id="numbercraft"
                  label={t("Numero do Ofício")}
                  name="numbercraft"
                  value={state.numbercraft}
                  onChange={handleChangeCraft("numbercraft")}
                  inputProps={{
                    maxLength: 50
                  }}
                />
              </Grid>
            </Grid>
              <div className="lineBreak"></div>
              {changeContractType()}
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <div
                      style={{
                        marginTop: "16px",
                        padding: "5px",
                        fontWeight: "bold",
                        backgroundColor: "rgba(44, 62, 81, 0.05)"
                      }}
                    >
                      CUSTO TOTAL *
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                  <MuiThemeProvider theme={tooltipTheme}>
                    <Tooltip
                       TransitionComponent={Zoom}
                       open={valiTotal}
                       title={t("Campo obrigatório")}
                    >
                    <TextField
                      id={"total"}
                      inputRef={totalRef}
                      error={valiTotal}
                      //  inputRef={estimatedCostRef}
                      style={{
                        fontWeight: "bold",
                        backgroundColor: totalCostIsDifferent()
                          ? "#f16b68"
                          : "rgba(44, 62, 81, 0.05)"
                      }}
                      className={classes.textField}
                      margin="normal"
                      name="total"
                      value={state.total}
                      onChange={handleFieldChange("total")}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <strong>R$</strong>
                          </InputAdornment>
                        )
                      }}
                    />
                    </Tooltip>
                  </MuiThemeProvider>
                  </Grid>
                  <Grid item xs={3}>
                    <div
                      style={{
                        marginTop: "16px",
                        padding: "5px",
                        fontWeight: "bold",
                        backgroundColor: "rgba(44, 62, 81, 0.05)"
                      }}
                    >
                      CUSTO CALCULADO
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      id={"headingValue1"}
                      //  inputRef={estimatedCostRef}
                      style={{
                        fontWeight: "bold",
                        backgroundColor: "rgba(44, 62, 81, 0.05)"
                      }}
                      //disabled
                      className={classes.textField}
                      margin="normal"
                      name="headingValue"
                      value={totalStr}
                      // onChange={handleFieldChange("directHrCosts")}
                      InputProps={{
                        readOnly: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <strong>R$</strong>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className="lineBreak"></div>
            </div>
          </Paper>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle className={classes.Title}>
              Deleção de campo
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Selecione abaixo o campo que você deseja apagar:
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Buttons
                onClick={handleClose}
                tipo="BN-noback"
                conteudo={t("Voltar").toUpperCase()}
              />
              <Buttons
                // onClick={handleSubmit}
                form="formUsers"
                type="submit"
                tipo="BN-blue"
                conteudo={t("Apagar").toUpperCase()}
              />
            </DialogActions>
          </Dialog>
        </div>

        <div>
          <Dialog
            open={createField}
            onClose={handleCloseField}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Adição de campo</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Digite abaixo o campo que você deseja incluir:
              </DialogContentText>
              <TextField
                //autoFocus
                variant="outlined"
                margin="dense"
                id="name"
                value={fieldDescription}
                onChange={handleChangeDescription}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Buttons
                onClick={handleCloseField}
                tipo="BN-noback"
                conteudo={t("Voltar").toUpperCase()}
              />
              <Buttons
                onClick={addField}
                form="formUsers"
                tipo="BN-blue"
                conteudo={t("GENERAL.add").toUpperCase()}
              />
            </DialogActions>
          </Dialog>
        </div>

        <div>
          <Dialog
            open={verifySubmit}
            onClose={closeVerify}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">ATENÇÃO</DialogTitle>
            <DialogContent>
              <DialogContentText>
                O usuário já possui custos cadastrados para este período. Deseja
                mesmo assim atualizar os custos?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Buttons
                onClick={closeVerify}
                tipo="BN-noback"
                conteudo={t("Cancelar").toUpperCase()}
              />
              <Buttons
                onClick={submit}
                form="formUsers"
                tipo="BN-blue"
                conteudo={t("Atualizar").toUpperCase()}
              />
            </DialogActions>
          </Dialog>
        </div>
        <Footer />
      </animated.div>
    )
  );
};

export default EditForm;
