import React, { useState } from 'react';
import history from 'history.js';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import Grid from '@material-ui/core/Grid';
import Input from 'components/inputs/Input';
import Box from '@material-ui/core/Box';
import Typography from 'components/typography/Typography';
import { Redirect } from 'react-router-dom';
import Transition from 'components/layout/transition/Transition';
import Buttons from 'components/buttons/Pressed';
import DialogCancelOperation from 'components/dialogs/DialogCancelOperation';
import InputImage from 'components/inputs/InputImage';
import InputMultiAutoComplete from 'components/inputs/InputMultiAutoComplete';
import { screenStyles } from '../Styles';
import ErrorIcon from '@material-ui/icons/Error';
import { SubmitTypes } from './ScreenUtils';

const BookForm = props => {
    const defaultImage = window.location.origin;
    const classes = useStyles();
    const styles = screenStyles();
    const [t] = useTranslation();
    const {
        id,
        imageFile,
        setImageFile,
        authors,
        bookAuthors,
        setBookAuthors,
        bookCategories,
        values,
        setValues,
        context,
        isNewContext,
        setTabValue,
        submit,
        ...others
    } = props;

    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const [titleRequired, setTitleRequired] = useState(false);
    const [editionRequired, setEditionRequired] = useState(false);
    const [categoryRequired, setCategoryRequired] = useState(false);
    const [authorsRequired, setAuthorsRequired] = useState(false);
    const [isbnRequired, setIsbnRequired] = useState(false);

    const [hasChange, setHasChange] = useState(false);
    const [openCancelDialog, setOpenCancelDialog] = useState(false);

    const newAuthor = element => !authors.find(item => item.name === element);

    const handleChange = (name, value) => {
        setHasChange(true);
        setValues({ ...values, [name]: value });
    };

    const handleCancel = () => {
        if (hasChange) {
            setOpenCancelDialog(true);
        } else {
            history.push('/Books');
        }
    };

    const handleNext = () => {
        const canApply = verifyNext();
        if (canApply) {
            if (context === 'edit') {
                submit(SubmitTypes.UPDATE_BOOK);
            } else {
                setTabValue(1);
            }
        }
    };

    const verifyNext = () => {
        let res = true;
        if (!values.title) {
            res = false;
            setTitleRequired(true);
        }
        if (!values.edition) {
            res = false;
            setEditionRequired(true);
        }
        if (!values.isbn) {
            res = false;
            setIsbnRequired(true);
        }
        if (!values.categoryId) {
            res = false;
            setCategoryRequired(true);
        }
        if (!bookAuthors.length) {
            res = false;
            setAuthorsRequired(true);
        }

        return res;
    };

    return (
        <React.Fragment>
            <Transition loading={false} newDesign={true}>
                <div className={styles.bookFormWrapper}>
                    <InputImage
                        context={context}
                        file={imageFile}
                        setFile={setImageFile}
                        imageUrl={values.image ? values.image : null}
                    />
                    <div className={styles.bookForm}>
                        <Grid container spacing={1}>
                            <Grid item sm={8}>
                                <Input
                                    required
                                    context={context}
                                    label={'INVENTORY.title'}
                                    value={values.title}
                                    valueName={'title'}
                                    maxLength={200}
                                    handleChange={handleChange}
                                    style={{ marginTop: '0px' }}
                                    error={titleRequired}
                                    helperText={
                                        titleRequired &&
                                        t('GENERAL.requiredField')
                                    }
                                    setRequired={setTitleRequired}
                                />
                            </Grid>

                            <Box width="100%" />

                            <Grid item sm={6}>
                                <Input
                                    context={context}
                                    label={'INVENTORY.subTitle'}
                                    value={values.subTitle}
                                    valueName={'subTitle'}
                                    maxLength={200}
                                    handleChange={handleChange}
                                    style={{ marginTop: '0px' }}
                                />
                            </Grid>

                            <Grid item sm={2}>
                                <Input
                                    required
                                    type={'number'}
                                    context={context}
                                    label={'INVENTORY.edition'}
                                    value={values.edition}
                                    valueName={'edition'}
                                    maxLength={2}
                                    handleChange={handleChange}
                                    style={{ marginTop: '0px' }}
                                    error={editionRequired}
                                    helperText={
                                        editionRequired &&
                                        t('GENERAL.requiredField')
                                    }
                                    setRequired={setEditionRequired}
                                />
                            </Grid>

                            <Box width="100%" />

                            <Grid item sm={12}>
                                <Typography
                                    variant={'RobotoBold'}
                                    fontSize={16}
                                    textColor={'secondary'}
                                >
                                    {t('INVENTORY.authors')}
                                </Typography>
                            </Grid>

                            <Grid item sm={8}>
                                <InputMultiAutoComplete
                                    required
                                    context={context}
                                    value={bookAuthors}
                                    setValue={setBookAuthors}
                                    arraySelected={authors}
                                    label={'INVENTORY.BookAuthor'}
                                    placeholder={t('LIBRARY.addAuthor')}
                                    attributeLabel="name"
                                    error={authorsRequired}
                                    helperText={
                                        authorsRequired &&
                                        t('GENERAL.requiredField')
                                    }
                                    setRequired={setAuthorsRequired}
                                />
                            </Grid>

                            <Box width="100%" />

                            <Grid item sm={8}>
                                {bookAuthors.some(newAuthor) ? (
                                    <div
                                        style={{
                                            display: 'flex',
                                            padding: '16px',
                                            background: '#6C829A33',
                                            borderRadius: '10px'
                                        }}
                                    >
                                        <ErrorIcon
                                            style={{
                                                marginRight: '8px',
                                                color: '#FFD200',
                                                background:
                                                    'rgba(0, 0, 0, 0.87)',
                                                borderRadius: '24px'
                                            }}
                                            fontSize="small"
                                        />
                                        <Typography
                                            variant={'RobotoRegular'}
                                            fontSize={12}
                                            textColor={'blackShadow'}
                                        >
                                            Os autores circulados em{' '}
                                            <b>amarelo</b> não estão cadastrados
                                            no banco de dados da bliblioteca.
                                            Recomendamos que seja realizada uma
                                            revisão dos campos afim de evitar
                                            inconsistências no nosso acervo.
                                        </Typography>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </Grid>

                            <Grid item sm={8}>
                                <Input
                                    context={context}
                                    label={'INVENTORY.publisher'}
                                    value={values.publisher}
                                    valueName={'publisher'}
                                    maxLength={200}
                                    handleChange={handleChange}
                                    style={{ marginTop: '0px' }}
                                />
                            </Grid>

                            <Box width="100%" />

                            <Grid item sm={8}>
                                <Input
                                    required
                                    context={context}
                                    label={'INVENTORY.isbn'}
                                    value={values.isbn}
                                    valueName={'isbn'}
                                    maxLength={14}
                                    handleChange={handleChange}
                                    style={{ marginTop: '0px' }}
                                    error={isbnRequired}
                                    helperText={
                                        isbnRequired &&
                                        t('GENERAL.requiredField')
                                    }
                                    setRequired={setIsbnRequired}
                                />
                            </Grid>

                            <Box width="100%" />

                            <Grid item sm={8}>
                                <Input
                                    context={context}
                                    label={'INVENTORY.language'}
                                    value={values.language}
                                    valueName={'language'}
                                    maxLength={50}
                                    handleChange={handleChange}
                                    style={{ marginTop: '0px' }}
                                />
                            </Grid>

                            <Box width="100%" />

                            <Grid item sm={8}>
                                <Input
                                    required
                                    context={context}
                                    label={'INVENTORY.category'}
                                    isSelect
                                    arraySelected={bookCategories}
                                    value={values.categoryId}
                                    valueName={'categoryId'}
                                    handleChange={handleChange}
                                    error={categoryRequired}
                                    helperText={
                                        categoryRequired &&
                                        t('GENERAL.requiredField')
                                    }
                                    setRequired={setCategoryRequired}
                                />
                            </Grid>

                            <Box width="100%" />
                            <Grid item sm={8}>
                                <Input
                                    context={context}
                                    label={'GENERAL.description'}
                                    value={values.synthesis}
                                    valueName={'synthesis'}
                                    rows={5}
                                    maxLength={4096}
                                    handleChange={handleChange}
                                />
                            </Grid>

                            <Box width="100%" />

                            <Buttons
                                onClick={handleCancel}
                                tipo="BN-noback"
                                conteudo={
                                    context !== 'view'
                                        ? t('GENERAL.cancel')
                                        : t('GENERAL.close')
                                }
                            />
                            {(context !== 'view' || isNewContext) && (
                                <Buttons
                                    onClick={handleNext}
                                    tipo="BN-blue"
                                    conteudo={
                                        context === 'edit'
                                            ? t('GENERAL.update')
                                            : t('GENERAL.nextButton')
                                    }
                                />
                            )}
                        </Grid>
                    </div>
                </div>

                <DialogCancelOperation
                    open={openCancelDialog}
                    setOpen={setOpenCancelDialog}
                    path={'/Books'}
                    dialogTitle={t('GENERAL.cancelEdit')}
                    dialogText={t('GENERAL.cancelEditText')}
                />
            </Transition>
        </React.Fragment>
    );
};

export default BookForm;
