import "App.css";
import LsbdImageIcon from "components/layout/sidebar/LsbdImageIcon";
import React, { useState } from "react";
import SideBarMenu from "./SideBarMenu";

const SideBar = () => {
  const [isHoverOn, setIsHoverOn] = useState(false);
  const handleMouseEvent = () => {
    setIsHoverOn(true);
  };
  const handleMouseEvent2 = () => {
    setIsHoverOn(false);
  };

  return (
    <React.Fragment>
      <nav
        id="main-menu"
        onMouseLeave={handleMouseEvent2}
        onMouseOverCapture={handleMouseEvent}
      >
        <div className="menu-header-logo">
          <LsbdImageIcon isHover={isHoverOn} />
        </div>
        <div>
          <SideBarMenu isHover={isHoverOn} />
        </div>
      </nav>
    </React.Fragment>
  );
};

export default SideBar;
