import React from "react";
import "../../App.css";
import SideBar from "../layout/sidebar/Sidebar";
import DashboardNavBar from "../layout/DashboardNavBar";
import Edit from "./EditProfile";

const EditProfileCall = ({ match }) => {
  
  return (
    <div>
      <DashboardNavBar />
      <SideBar />
      <Edit idProfile={match.params.id}/>
    </div>
  );
}

export default EditProfileCall;