export const adminNotifications = [
    {
        event: 'ADMIN_NEW_COLLABORATOR_REGISTERED_EMAIL_OPTIONAL',
        allowSend: false,
        automatic: false
    },
    {
        event: 'ADMIN_COLLABORATOR_DISABLED_EMAIL_OPTIONAL',
        allowSend: false,
        automatic: false
    },
    {
        event: 'ADMIN_APPROVERS_UPDATED_EMAIL_OPTIONAL',
        allowSend: false,
        automatic: false
    },
    {
        event: 'ADMIN_PERMISSIONS_UPDATED_EMAIL_OPTIONAL',
        allowSend: false,
        automatic: false
    }
];
