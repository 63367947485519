import Search from "components/layout/searchIndex";
import React from "react";
import "../../App.css";
import SideBar from "../layout/sidebar/Sidebar";
import ProjectsTable from "./ProjectsTable";

function ProjectsIndex() {
  return (
    <div>
      <Search busca={"Procurar por GPF, Nome ou Status"} />
      <SideBar />
      <ProjectsTable context="planning" />
    </div>
  );
}

export default ProjectsIndex;
