/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import Transition from 'components/layout/transition/Transition';
import DefaultInfo from 'components/Infos/DefaultInfo';
import Typography from 'components/typography/Typography';
import InputAutoComplete from 'components/inputs/InputAutoComplete';
import Buttons from 'components/buttons/Pressed';
import DialogUnit from 'components/dialogs/DialogUnit';
import GreyContainer from 'components/containers/GreyContainer';
import MessagePaper from 'components/messagePaper/MessagePaper';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { useStyles } from 'components/layout/CommonStyles';
import { useQueryUserPermissions } from 'hooks/DataFetching/Admin/Users/useQueryUserPermissions';
import { useQueryCreateUser } from 'hooks/DataFetching/Admin/Users/useQueryCreateUser';
// import { screenStyles } from '../Styles';
import { translateUserRegistrationStatus } from 'pages/adminModule/Users/ScreenUtils';
import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';
import { Allow, Screen, Modules } from 'global/constants';
import { Paper, Box, Grid } from '@material-ui/core';
import { GetInputArray } from 'utils/general/GetInputArray';

import Input from 'components/inputs/Input';
import DialogUnitWithIcon from 'components/dialogs/DialogUnitWithIcon';
import DialogEnableAccess from './DialogEnableAccess';
import { useMutationEditUserPermissions } from 'hooks/DataFetching/Admin/Users/useMutationEditUserPermissions';

const ProfileForm = ({ userId, context, myInformations }) => {
    const classes = useStyles();
    // const styles = screenStyles();
    const [t] = useTranslation();

    const [open, setOpen] = useState(false);

    const { data, isLoading } = useQueryCreateUser({});

    const userPermissionsQuery = useQueryUserPermissions(
        context === 'new' ? { enabled: false } : {},
        userId
    );

    const [values, setValues] = useState({
        libraryId: 0,
        patrimonyId: 0,
        humanResourcesId: 0,
        humanResourcesOldId: 0,
        newsId: 0,
        administrativeId: 0,
        quotasId: 0,
        surrogate: '',
        approver: '',
        name: ''
    });

    useEffect(() => {
        if (userPermissionsQuery.data) {
            const data = userPermissionsQuery.data;
            setValues({
                libraryId: data.systemRoles
                    ? data.systemRoles.find(x => x.id === Modules.LIBRARY.id)
                          .role.id
                    : 0,
                patrimonyId: data.systemRoles
                    ? data.systemRoles.find(x => x.id === Modules.PATRIMONY.id)
                          .role.id
                    : 0,
                humanResourcesId: data.systemRoles
                    ? data.systemRoles.find(x => x.id === Modules.HR.id).role.id
                    : 0,
                humanResourcesOldId: data.systemRoles
                    ? data.systemRoles.find(
                          x => x.id === Modules.HR_OLD.id && x.isOldSystem
                      ).role.id
                    : 0,
                newsId: data.systemRoles
                    ? data.systemRoles.find(x => x.id === Modules.NEWS.id).role
                          .id
                    : 0,
                administrativeId: data.systemRoles
                    ? data.systemRoles.find(
                          x => x.id === Modules.ADMINISTRATIVE.id
                      ).role.id
                    : 0,
                quotasId: data.systemRoles
                    ? data.systemRoles.find(x => x.id === Modules.QUOTAS.id)
                          .role.id
                    : 0,
                surrogate: data.surrogate
                    ? { label: data.surrogate.name, value: data.surrogate.id }
                    : {},
                approver: data.approver
                    ? { label: data.approver.name, value: data.approver.id }
                    : {},
                userLoginEnabled: data.userLoginEnabled,
                userRegistrationStatus: data.userRegistrationStatus,
                name: data.user.name
            });
        }
    }, [userPermissionsQuery.data]);

    const library =
        data && data.systemRoles
            ? data.systemRoles.find(x => x.id === Modules.LIBRARY.id)
            : {};
    const patrimony =
        data && data.systemRoles
            ? data.systemRoles.find(x => x.id === Modules.PATRIMONY.id)
            : {};
    const hr =
        data && data.systemRoles
            ? data.systemRoles.find(x => x.id === Modules.HR.id)
            : {};
    const hr_old =
        data && data.systemRoles
            ? data.systemRoles.find(
                  x => x.id === Modules.HR_OLD.id && x.isOldSystem
              )
            : {};
    const administrative =
        data && data.systemRoles
            ? data.systemRoles.find(x => x.id === Modules.ADMINISTRATIVE.id)
            : {};
    const quotas =
        data && data.systemRoles
            ? data.systemRoles.find(x => x.id === Modules.QUOTAS.id)
            : {};
    const news =
        data && data.systemRoles
            ? data.systemRoles.find(x => x.id === Modules.NEWS.id)
            : {};
    const collaboratorsArray =
        data && data.users ? GetInputArray(data.users, 'id', 'name') : [];

    return (
        <React.Fragment>
            <Transition
                loading={
                    context !== 'new' ? userPermissionsQuery.isLoading : false
                }
                newDesign={true}
            >
                {values.userRegistrationStatus !== 'Admitted' ? (
                    <div className={classes.familyDataForm}>
                        <Grid
                            container
                            item
                            spacing={3}
                            md={12}
                            lg={12}
                            style={{ padding: '30px' }}
                        >
                            <MessagePaper
                                headerText={t(
                                    'HR.collaboratorIsFiredOrInProcess'
                                )}
                                subText={t('HR.toViewPermissions')}
                            />
                        </Grid>
                    </div>
                ) : (
                    <Paper square style={{ padding: '32px 16px' }}>
                        <Grid container xs={12} spacing={1}>
                            {!myInformations ? (
                                <Grid item xs={12}>
                                    <GreyContainer>
                                        <>
                                            <DefaultInfo
                                                iconColor={
                                                    !values.userLoginEnabled
                                                        ? '#FFD200'
                                                        : null
                                                }
                                                textTranslation={
                                                    values.userLoginEnabled
                                                        ? 'HR.toDisableCollaborator'
                                                        : context === 'view'
                                                        ? 'HR.collaboratorIsNotEnabledInSystemView'
                                                        : 'HR.collaboratorIsNotEnabledInSystemEdit'
                                                }
                                            />
                                            {context !== 'view' &&
                                            !values.userLoginEnabled ? (
                                                <Buttons
                                                    onClick={() => {
                                                        setOpen(true);
                                                    }}
                                                    tipo="borderBackButton"
                                                    conteudo={t(
                                                        'HR.enableSystemAccess'
                                                    ).toUpperCase()}
                                                />
                                            ) : null}
                                        </>
                                    </GreyContainer>
                                </Grid>
                            ) : null}

                            <Grid item xs={4}>
                                <Box display={'flex'}>
                                    <DialogUnitWithIcon
                                        head={t('GENERAL.systemAccess')}
                                        content={
                                            values.userLoginEnabled
                                                ? t('GENERAL.enabled')
                                                : t('GENERAL.disabled')
                                        }
                                        marginTop={'0px'}
                                        Icon={
                                            values.userLoginEnabled ? (
                                                <CheckCircleIcon
                                                    style={{
                                                        color: '#7FE46B'
                                                    }}
                                                    fontSize="md"
                                                />
                                            ) : (
                                                <ErrorIcon
                                                    style={{
                                                        color: '#F3222F'
                                                    }}
                                                    fontSize="md"
                                                />
                                            )
                                        }
                                        tooltipContent={t(
                                            'ADMIN.adminTooltipSystemAccess'
                                        )}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container xs={12} spacing={1}>
                            <Grid item xs={12} style={{ marginTop: '24px' }}>
                                <Typography
                                    variant={'RobotoBold'}
                                    fontSize={14}
                                    textColor={'primary'}
                                >
                                    {t('GENERAL.permissionByModule')}
                                </Typography>
                            </Grid>
                            <>
                                <Grid item xs={3}>
                                    <Input
                                        context={'view'}
                                        label={'GENERAL.module'}
                                        value={Modules.PATRIMONY.name}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <Input
                                        context={'view'}
                                        label={'GENERAL.permissionLevel'}
                                        value={values.patrimonyId}
                                        valueName={'patrimonyId'}
                                        isSelect
                                        arraySelected={
                                            patrimony
                                                ? GetInputArray(
                                                      patrimony.roles,
                                                      'id',
                                                      'name'
                                                  )
                                                : []
                                        }
                                    />
                                </Grid>
                            </>
                            <Box width={'100%'} />

                            <>
                                <Grid item xs={3}>
                                    <Input
                                        context={'view'}
                                        label={'GENERAL.module'}
                                        value={Modules.HR.name}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <Input
                                        context={'view'}
                                        label={'GENERAL.permissionLevel'}
                                        value={values.humanResourcesId}
                                        valueName={'humanResourcesId'}
                                        isSelect
                                        arraySelected={
                                            hr
                                                ? GetInputArray(
                                                      hr.roles,
                                                      'id',
                                                      'name'
                                                  )
                                                : []
                                        }
                                    />
                                </Grid>
                            </>
                            <Box width={'100%'} />

                            <>
                                <Grid item xs={3}>
                                    <Input
                                        context={'view'}
                                        label={'GENERAL.module'}
                                        value={Modules.HR_OLD.name}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <Input
                                        context={'view'}
                                        label={'GENERAL.permissionLevel'}
                                        value={values.humanResourcesOldId}
                                        valueName={'humanResourcesOldId'}
                                        isSelect
                                        arraySelected={
                                            hr_old
                                                ? GetInputArray(
                                                      hr_old.roles,
                                                      'id',
                                                      'name'
                                                  )
                                                : []
                                        }
                                    />
                                </Grid>
                            </>
                            <Box width={'100%'} />

                            <>
                                <Grid item xs={3}>
                                    <Input
                                        context={'view'}
                                        label={'GENERAL.module'}
                                        value={Modules.LIBRARY.name}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <Input
                                        context={'view'}
                                        label={'GENERAL.permissionLevel'}
                                        value={values.libraryId}
                                        valueName={'libraryId'}
                                        isSelect
                                        arraySelected={
                                            library
                                                ? GetInputArray(
                                                      library.roles,
                                                      'id',
                                                      'name'
                                                  )
                                                : []
                                        }
                                    />
                                </Grid>
                            </>
                            <Box width={'100%'} />

                            <>
                                <Grid item xs={3}>
                                    <Input
                                        context={'view'}
                                        label={'GENERAL.module'}
                                        value={Modules.QUOTAS.name}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <Input
                                        context={'view'}
                                        label={'GENERAL.permissionLevel'}
                                        value={values.quotasId}
                                        valueName={'quotasId'}
                                        isSelect
                                        arraySelected={
                                            quotas
                                                ? GetInputArray(
                                                      quotas.roles,
                                                      'id',
                                                      'name'
                                                  )
                                                : []
                                        }
                                    />
                                </Grid>
                            </>
                            <Box width={'100%'} />
                            <>
                                <Grid item xs={3}>
                                    <Input
                                        context={'view'}
                                        label={'GENERAL.module'}
                                        value={Modules.NEWS.name}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <Input
                                        context={'view'}
                                        label={'GENERAL.permissionLevel'}
                                        value={values.newsId}
                                        valueName={'newsId'}
                                        isSelect
                                        arraySelected={
                                            news
                                                ? GetInputArray(
                                                      news.roles,
                                                      'id',
                                                      'name'
                                                  )
                                                : []
                                        }
                                    />
                                </Grid>
                            </>
                            <Box width={'100%'} />
                            {values.administrativeId !== 0 ? (
                                <>
                                    <Grid item xs={3}>
                                        <Input
                                            context={'view'}
                                            label={'GENERAL.module'}
                                            value={Modules.ADMINISTRATIVE.name}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Input
                                            context={'view'}
                                            label={'GENERAL.permissionLevel'}
                                            value={values.administrativeId}
                                            valueName={'administrativeId'}
                                            isSelect
                                            arraySelected={
                                                administrative
                                                    ? GetInputArray(
                                                          administrative.roles,
                                                          'id',
                                                          'name'
                                                      )
                                                    : []
                                            }
                                        />
                                    </Grid>
                                </>
                            ) : null}
                            <Box width={'100%'} />

                            <Grid item xs={6}>
                                <InputAutoComplete
                                    context={'view'}
                                    label={'HR.approver'}
                                    value={values.approver}
                                    valueName={'approver'}
                                    arraySelected={collaboratorsArray}
                                    noOptionsText={
                                        'GENERAL.noOptionsCollaborator'
                                    }
                                />
                            </Grid>

                            <Box width={'100%'} />

                            <Grid item xs={6}>
                                <InputAutoComplete
                                    context={'view'}
                                    label={'HR.surrogate'}
                                    value={values.surrogate}
                                    valueName={'surrogate'}
                                    arraySelected={collaboratorsArray}
                                    noOptionsText={
                                        'GENERAL.noOptionsCollaborator'
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                )}
                {open ? (
                    <DialogEnableAccess
                        open={open}
                        setOpen={setOpen}
                        name={values.name}
                        id={userId}
                    />
                ) : null}
            </Transition>
        </React.Fragment>
    );
};

export default ProfileForm;
