import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

const InputAutoComplete = ({
    value,
    valueName,
    handleChange,
    label,
    arraySelected,
    error,
    setError,
    helperText,
    noOptionsText,
    required,
    context,
    ...props
}) => {
    const classes = useStyles();
    const [t] = useTranslation();

    return (
        <Autocomplete
            disabled={context === 'view'}
            options={arraySelected}
            getOptionLabel={option => option.label}
            value={value}
            onChange={(event, newValue) => {
                handleChange(valueName, newValue);
                setError(false);
            }}
            noOptionsText={t(noOptionsText)}
            renderInput={params => (
                <TextField
                    {...params}
                    label={required ? t(label) + '*' : t(label)}
                    error={error}
                    helperText={error ? t(helperText) : ''}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment:
                            context !== 'view' ? (
                                <InputAdornment
                                    position="start"
                                    className={classes.SearchIcon}
                                >
                                    <SearchIcon color="primary" />
                                </InputAdornment>
                            ) : null
                    }}
                />
            )}
            {...props}
        />
    );
};

export default InputAutoComplete;
