/* eslint-disable */
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Footer from "components/layout/Footer";
import Spinner from "components/layout/spinners/Index";
import Table from "./SoftwareCategoryTable";
import AllowancesContext from "context/allowance/allowanceContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { animated, useTransition } from "react-spring";
import MySnackbarContentWrapper from "components/layout/Message";
import { useStyles } from "components/layout/CommonStyles";
import Dissatisfied from "@material-ui/icons/SentimentVeryDissatisfied";
import Paper from "@material-ui/core/Paper";
import InventoryContext from "context/inventory/inventoryContext"
import Toolbar from "@material-ui/core/Toolbar";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import DialogCRUD from "./DialogGRUD";
import { GetGeneralPermissionForAction } from "utils/permissions/GetGeneralPermissionForAction";
import { Screen, Allow } from "global/constants";

const SoftwareCategory = () => {
    const classes = useStyles();
    const [t] = useTranslation();
    const inventoryContext = useContext(InventoryContext);
    const allowanceContext = useContext(AllowancesContext);
    const [open, setOpen] = useState(false);
    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }
    const {
        loading,
        response,
        hasChanged,
        setHasChanged,
        responseType,
        successiveActions,
        itensCategories,
        getSoftwareCategories,
        softwareCategories
    } = inventoryContext;

    const [snackBar, setSnackBar] = useState({
        open: false,
        kind: "",
        content: ""
    });
    const [values, setValues] = React.useState({
        id: null,
        name: "",
        description: "",
        isConsumable: false
    });
    let dataFilter = [];
    const [filter, setFilter] = useState("");
    const hasItens = softwareCategories.length > 0;
    const [hasNoResult, setHasNoResult] = useState(hasItens && dataFilter.length === 0);

    const {
        allowanceResponse,
        allowanceResponseType,
        messageHasChanged,
        resetAllowanceMessage,

    } = allowanceContext;

    dataFilter = softwareCategories;

    if (filter) {
        dataFilter = dataFilter.filter(
            item =>
                item.name.toLowerCase().includes(filter.toLowerCase())
        );
    }

    let data = [];
    data = itensCategories;

    const transitions = useTransition(loading, null, {
        from: { opacity: 0 },
        enter: {
            opacity: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "calc(100vh - 70px)"
        },
        leave: { opacity: 0 }
    });

    useEffect(() => {
        getSoftwareCategories()
        if (allowanceResponseType === "ERROR" && messageHasChanged) {
            handleSnack({ kind: "error", content: allowanceResponse });
        }
        if (responseType === "SUCCESS" && hasChanged > successiveActions) {
            handleSnack({ kind: "success", content: response });
        }
        if (responseType === "ERROR" && hasChanged > successiveActions) {
            handleSnack({ kind: "error", content: response });
        }
        return () => { };
    }, [
        responseType,
        response,
        successiveActions,
        allowanceResponseType,
        allowanceResponse,
        itensCategories.length
    ]);

    const handleSnack = () => {
        setSnackBar({ ...snackBar, open: true });
        setHasChanged();
    };
    const handleSnackClose = () => {
        setSnackBar({ ...snackBar, open: false });
        resetAllowanceMessage();
    };

    const handleClickOpen = () => {
        setValues({
            ...values,
            name: "",
            description: "",
            isConsumable: false,
        });
        setOpen(true);
    };

    const handleSearch = event => {
        setFilter(event.target.value);
        dataFilter = softwareCategories;
        if (filter) {
            dataFilter = dataFilter.filter(
                item =>
                    item.name.toLowerCase().includes(event.target.value.toLowerCase())
            );
        }

        if (dataFilter.length === 0)
            setHasNoResult(true);
        else
            setHasNoResult(false);
    };

    return transitions.map(({ item, key, props }) =>
        item ? (
            <animated.div key={key} style={props}>
                <Spinner />;
            </animated.div>
        ) : (
            <animated.div key={key} style={props}>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={snackBar.open}
                    onClose={handleSnackClose}
                    TransitionComponent={Slide}
                    transitionDuration={{ enter: 500, exit: 500 }}
                    ContentProps={{
                        "aria-describedby": "message-id"
                    }}
                    autoHideDuration={2000}
                >
                    <MySnackbarContentWrapper
                        onClose={handleSnackClose}
                        variant={
                            responseType !== ""
                                ? responseType.toLowerCase()
                                : allowanceResponseType.toLowerCase()
                        }
                        message={
                            <span id="message-id">
                                {response !== "" ? response : allowanceResponse}
                            </span>
                        }
                    />
                </Snackbar>
                <div className="container">
                    <Breadcrumbs
                        className={classes.breadCrumbs}
                        aria-label="breadcrumb"
                        separator={<NavigateNextIcon fontSize="small" />}
                    >
                        <Typography>{t("INVENTORY.inventory")}</Typography>
                        <Typography>{t("INVENTORY.softwareCategories")}</Typography>
                    </Breadcrumbs>

                    <div className="container-header">
                        <div className="title">
                            <h1 className={classes.title}>{t("INVENTORY.softwareCategories")}</h1>
                        </div>
                        {GetGeneralPermissionForAction(Screen.SOFTWARES_CATEGORIES, Allow.CREATE) &&
                            <Button
                                className={classes.blueButton}
                                variant="contained"
                                color="primary"
                                onClick={handleClickOpen}
                            >
                                {t("INVENTORY.addItemCategory")}
                            </Button>
                        }
                    </div>
                    <Paper >
                        <div className="cabecalho">
                            {t("INVENTORY.registeredCategories").toUpperCase()}
                        </div>
                        <div className={classes.divAddParticipantLeft}>
                            <Toolbar className={classes.searchToolbarInventory}>
                                <div style={{ display: !hasItens ? "none" : "" }} className={classes.searchInventory}>
                                    <div className={classes.searchIcon}>
                                        <SearchIcon />
                                    </div>
                                    <InputBase
                                        placeholder={t("INVENTORY.name")}
                                        value={filter}
                                        onChange={handleSearch}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputInput
                                        }}
                                        inputProps={{ "aria-label": "search" }}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                            </Toolbar>
                        </div>
                        <Table
                            rows={dataFilter}
                            headers={["id", "name", "description", "itensQtd"]}
                            visibleHeaders={["nome da categoria", "Descrição", "Quantidade de softwares", ""]}
                            sortedFields={["name", "description", "itensQtd"]}
                            mainField="name"
                        />
                        {!hasItens &&
                            <div className={classes.noParticipantsMessage}>
                                <h2>{t("INVENTORY.noItensMessage")}</h2>
                                <h4>{t("INVENTORY.noItensSubMessage")}</h4>
                            </div>
                        }
                        {hasNoResult && filter &&
                            <div className={classes.noParticipantsMessage}>
                                <div >
                                    <Dissatisfied className={classes.notFoundIconStyle} />
                                </div>
                                <h2>{t("GENERAL.noResultsFound")}</h2>
                                <h5>{`Sua busca por "${filter}" não retornou resultados.`}</h5>
                            </div>
                        }
                    </Paper>
                </div>
                <Footer />
                <DialogCRUD open={open} setOpen={setOpen} />
            </animated.div>
        )
    );
};

export default SoftwareCategory;