import React, { useContext, useEffect, useRef } from "react";
import {
    Button,
    CrossIconStyled,
    Input,
    InputFilterWrapper,
    MagnifyingGlassIconStyled,
} from "./InputFilterText.styled";
import { MultiFilterContext } from "../../MultiFilter/MultiFilter";

const InputFilterText = ({ column, isMultiFilter = false, ...props }) => {
    const { filters, handleFilterChange, cleanFilter } =
        useContext(MultiFilterContext);
    const inputRef = useRef(null);
    const crossIconBtnRef = useRef(null);
    const [value, setValue] = React.useState("");

    useEffect(() => {
        crossIconBtnRef.current.style.display = "none";
    }, [crossIconBtnRef]);

    const setFocusOnInput = () => inputRef.current.focus();

    const cleanInput = filterToClean => {
        isMultiFilter ? cleanFilter(filterToClean) : setValue("");
        showCrossIconBtnAfterInputHasContent(false);
    };

    const showCrossIconBtnAfterInputHasContent = value => {
        if (!!value) crossIconBtnRef.current.style.display = "block";
        else crossIconBtnRef.current.style.display = "none";
    };

    return (
        <InputFilterWrapper onClick={setFocusOnInput}>
            <MagnifyingGlassIconStyled />
            <Input
                autoComplete={""}
                ref={inputRef}
                type={"text"}
                placeholder={props.placeholder}
                name={column}
                onChange={event => {
                    showCrossIconBtnAfterInputHasContent(event.target.value);
                    isMultiFilter
                        ? handleFilterChange(event)
                        : setValue(event.target.value);
                }}
                value={isMultiFilter ? filters[column] || "" : value}
            />
            <Button ref={crossIconBtnRef} onClick={() => cleanInput(inputRef)}>
                <CrossIconStyled />
            </Button>
        </InputFilterWrapper>
    );
};

export default InputFilterText;
