import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import InputBase from '@material-ui/core/InputBase'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Buttons from 'components/buttons/Pressed'
import { useStyles } from 'components/layout/CommonStyles'
import InventoryContext from 'context/inventory/inventoryContext'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import Close from '@material-ui/icons/Close'
import InputAdornment from '@material-ui/core/InputAdornment'
import { numberRegex } from 'utils/regex/numberRegex'

const AdvancedSearchDialog = props => {
    const [t] = useTranslation()
    const classes = useStyles()
    const { open, setOpen, apply } = props

    const inventoryContext = useContext(InventoryContext)
    const [categoriesArray, setCategoriesArray] = useState([])

    const { getSoftwareCategories, softwareCategories } = inventoryContext
    const [cpf, setCpf] = useState('')

    const sortElement = elemnts => {
        elemnts.sort(function (a, b) {
            if (a.name === null) a.name = ''
            if (b.name === null) b.name = ''

            if (
                a.name
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .trim() >
                b.name
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .trim()
            ) {
                return 1
            }
            if (
                a.name
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .trim() <
                b.name
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .trim()
            ) {
                return -1
            }
            // a must be equal to b
            return 0
        })
    }

    useEffect(() => {
        getSoftwareCategories()
    }, [])

    useEffect(() => {
        const categories = [...softwareCategories]
        sortElement(categories)

        var aux = {}
        var _categories = []
        for (var i = 0; i < categories.length; i++) {
            var item = categories[i]
            aux.value = item.id
            aux.label = item.name
            _categories.push(aux)
            aux = {}
        }
        setCategoriesArray(_categories)
    }, [softwareCategories])

    const [values, setValues] = useState({
        name: "",
        contractType: "",
        status: "",
        email: "",
        cpf: "",
    });

    const cleanFields = () => {
        setValues({
            ...values,
            name: "",
            contractType: "",
            status: "",
            email: "",
            cpf: "",
        });
    }

    const handleClose = () => {
        setOpen(false)
        cleanFields()
    }

    const handleClean = () => {
        cleanFields()
        apply('', '', '', '', '', '')
        setCpf('')
    }

    const handleSubmit = () => {
        apply(
            values.name,
            values.status,
            values.email,
            values.contractType,
            cpf.replace(/[^\d]/g, '')
        )
        setOpen(false)
        cleanFields()
    }

    const maskCpf = event => {
        var cpfMask = event.target.value

        cpfMask = cpfMask.replace(/\D/g, '')
        cpfMask = cpfMask.replace(/(\d{3})(\d)/, '$1.$2')
        cpfMask = cpfMask.replace(/(\d{3})(\d)/, '$1.$2')
        cpfMask = cpfMask.replace(/(\d{3})(\d{1,2})$/, '$1-$2')

        setCpf(cpfMask)
    }

    const handleChangeCpf = event => {
        maskCpf(event)
    }

    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value })
    }

    const clearField = name => {
        setValues({ ...values, [name]: '' })
    }

    const status = [
        {
            value: 'ALL',
            label: 'Todos'
        },
        {
            value: 'ACTIVE',
            label: 'Ativo'
        },
        {
            value: "INACTIVE",
            label: "Inativo"
        },
    ];
    
    const contractType = [
        {
            value: 'All',
            label: 'Todos'
        },
        {
            value: 'newLicense',
            label: 'Bolsista'
        },
        {
            value: 'newLicense',
            label: 'Estágio'
        },
        {
            value: 'newLicense',
            label: 'Pessoa Jurídica'
        },
        {
            value: 'newLicense',
            label: 'Serviços Prestados'
        }
    ]

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            msg="mensagem"
            maxWidth="md"
        >
            <DialogTitle
                className={classes.actionsTitle}
                style={{ width: 'auto' }}
            >
                <span style={{ fontWeight: 'bold' }}>
                    {t('INVENTORY.advancedSearch').toLocaleUpperCase()}
                </span>
                <IconButton
                    style={{ float: 'right', marginTop: -10, marginRight: -17 }}
                    edge="end"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <FormControl component="fieldset">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormLabel component="legend">
                                    {t('HR.textAdvancedSearch')}
                                </FormLabel>
                            </Grid>
                            <Grid item xs={4}>
                                <div className={classes.searchInventory}>
                                    <InputBase
                                        placeholder={t('INVENTORY.name')}
                                        value={values.name}
                                        onChange={handleChange('name')}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputSearch
                                        }}
                                        endAdornment={
                                            <InputAdornment position="start">
                                                <IconButton
                                                    edge="start"
                                                    className={
                                                        classes.menuButton
                                                    }
                                                    color="inherit"
                                                    aria-label="Close"
                                                    onClick={() =>
                                                        clearField('name')
                                                    }
                                                >
                                                    <Close />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        inputProps={{ 'aria-label': 'search' }}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className={classes.searchInventory}>
                                    <TextField
                                        select
                                        label={t('HR.contractType')}
                                        value={values.contractType}
                                        onChange={handleChange('contractType')}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputSearch
                                        }}
                                        inputProps={{ 'aria-label': 'search' }}
                                        style={{ width: '100%' }}
                                    >
                                        {contractType.map(category => (
                                            <MenuItem
                                                key={category.value}
                                                value={category.label}
                                            >
                                                {category.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            </Grid>
                            
                            <Grid item xs={12}>
                                <div
                                    style={{
                                        height: '1px',
                                        backgroundColor: '#F5F6FA'
                                    }}
                                ></div>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    className={classes.titleNewEquipment}
                                >
                                    {t('INVENTORY.advanced')}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <div className={classes.searchInventory}>
                                    <InputBase
                                        placeholder={t(
                                            'PERSONAL.cpf'
                                        ).toLocaleUpperCase()}
                                        value={cpf}
                                        onChange={handleChangeCpf}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputSearch
                                        }}
                                        endAdornment={
                                            <InputAdornment position="start">
                                                <IconButton
                                                    edge="start"
                                                    className={
                                                        classes.menuButton
                                                    }
                                                    color="inherit"
                                                    aria-label="Close"
                                                    onClick={() => setCpf('')}
                                                >
                                                    <Close />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        inputProps={{
                                            'aria-label': 'search',
                                            maxLength: 14
                                        }}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className={classes.searchInventory}>
                                    <InputBase
                                        placeholder={t('HR.email')}
                                        value={values.email}
                                        onChange={handleChange('email')}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputSearch
                                        }}
                                        endAdornment={
                                            <InputAdornment position="start">
                                                <IconButton
                                                    edge="start"
                                                    className={
                                                        classes.menuButton
                                                    }
                                                    color="inherit"
                                                    aria-label="Close"
                                                    onClick={() =>
                                                        clearField('email')
                                                    }
                                                >
                                                    <Close />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        inputProps={{ 'aria-label': 'search' }}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className={classes.searchInventory}>
                                    <TextField
                                        select
                                        label={t('GENERAL.status')}
                                        value={values.status}
                                        onChange={handleChange('status')}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputSearch
                                        }}
                                        inputProps={{ 'aria-label': 'search' }}
                                        style={{ width: '100%' }}
                                    >
                                        {status.map(category => (
                                            <MenuItem
                                                key={category.value}
                                                value={category.label}
                                            >
                                                {category.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            </Grid>
                        </Grid>
                    </FormControl>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Buttons
                    onClick={handleClose}
                    tipo="BN-noback"
                    conteudo={t('GENERAL.cancel').toLocaleUpperCase()}
                />
                <Buttons
                    onClick={handleClean}
                    tipo="BN-noback"
                    conteudo={t('GENERAL.clear').toLocaleUpperCase()}
                />
                <Buttons
                    onClick={handleSubmit}
                    tipo="BN-blue"
                    conteudo={t('GENERAL.apply').toLocaleUpperCase()}
                />
            </DialogActions>
        </Dialog>
    )
}
export default AdvancedSearchDialog
