import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";
import InventoryContext from "context/inventory/inventoryContext";
import Footer from "components/layout/Footer";
import Transition from "components/layout/transition/Transition";
import Breadcrumb from "components/layout/breadcrumb/Breadcrumb";
import TooltipError from "components/layout/tooltipError/TooltipError";
import InputDragDrop from "components/layout/inputDragDrop/InputDragDrop";
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import Cookies from "js-cookie";

const EditSoftware = props => {
    const { softwareId, context } = props;
    const classes = useStyles();
    const [t] = useTranslation();
    const inventoryContext = useContext(InventoryContext);

    const {
        loading,
        getSoftwareCategories,
        softwareCategories,
        getSoftwares,
        softwares,
        getSoftware,
        software,
        getInventoryImage,
        inventoryImage,
        UpdateSoftware,
        getPlartforms,
        platforms,
    } = inventoryContext;

    const [requiredName, setRequiredName] = useState({ value: false, msg: "GENERAL.requiredField" });
    const [requiredCategory, setRequiredCategory] = useState({ value: false, msg: "GENERAL.requiredField" });
    const [values, setValues] = useState({
        imageId: 0,
        imagePath: "",
        name: "",
        developed: "",
        newPlatforms: [""],
        url: "",
        description: "",
        softwareCategoryId: "",
    });

    const platformText = {
        color: "rgba(0, 0, 0, 0.38)",
        fontSize: "0.75rem",
        fontFamily: "Roboto",
        fontWeight: "400",
        letterSpacing: "0.00938em",
        cursor: "default",
        marginBottom: "4px"
    };

    const platformsFieldsText = {
        color: "rgba(0, 0, 0, 0.38)",
        fontSize: "1rem",
        fontFamily: "Roboto",
        fontWeight: "400",
        letterSpacing: "0.00938em",
        cursor: "default"
    }

    const [fieldsPlatform, setFieldsPlatform] = useState([]);
    const [initialPlatforms, setInitialPlatforms] = useState([]);

    const [hasUpdatedFile, setHasUpdatedFile] = useState(false);
    const [files, setFiles] = useState([]);
    const [othersSelected, setOthersSelected] = useState(false);
    const formDisabled = context === "view" ? true : false;

    const onDrop = acceptedFiles => {
        if (acceptedFiles[0] !== undefined) {
            setHasUpdatedFile(true);
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    };

    const handleChange = (name, value) => {
        setValues({ ...values, [name]: value });
    };

    useEffect(() => {
        getSoftwares();
        getSoftwareCategories();
        getSoftware(softwareId);
        getInventoryImage(softwareId);
        getPlartforms();
    }, []);

    useEffect(() => {
        if (platforms) {
            const Platforms = [...platforms];
            var aux = {};
            var _platforms = [];
            for (var i = 0; i < Platforms.length; i++) {
                var item = Platforms[i];
                aux.checked = false;
                aux.id = item.id;
                aux.label = item.name;
                _platforms.push(aux);
                aux = {};
            }
            // var others = {};
            // others.checked = false;
            // others.id = null;
            // others.label = "Outros";
            // _platforms.push(others);
            setFieldsPlatform(_platforms)
        }
    }, [platforms]);

    useEffect(() => {
        if (software) {
            if (formDisabled) {
                setValues(old => ({
                    ...old,
                    imageId: inventoryImage[0] ? inventoryImage[0].id : 0,
                    imagePath: inventoryImage[0] ? inventoryImage[0].imagePath : "",
                    name: software.name ? software.name : "-",
                    developed: software.manufacturer ? software.manufacturer : "-",
                    url: software.url ? software.url : "-",
                    description: software.description ? software.description : "-",
                    softwareCategoryId: software.softwareCategoryId ? software.softwareCategoryId : "",
                }));
            } else {
                setValues(old => ({
                    ...old,
                    imageId: inventoryImage[0] ? inventoryImage[0].id : 0,
                    imagePath: inventoryImage[0] ? inventoryImage[0].imagePath : "",
                    name: software.name ? software.name : "",
                    developed: software.manufacturer ? software.manufacturer : "",
                    url: software.url ? software.url : "",
                    description: software.description ? software.description : "",
                    softwareCategoryId: software.softwareCategoryId ? software.softwareCategoryId : "",
                }));
            }
        }
    }, [software, JSON.stringify(inventoryImage).length]);


    useEffect(() => {
        if (Object.keys(software).length !== 0 && fieldsPlatform.length !== 0) {
            let newInitialPlatform = [];
            software.inventorySoftwarePlatform.forEach(softwarePlatform => {
                let pos = fieldsPlatform.map(field => field.label).indexOf(softwarePlatform.platform.name);
                if (pos !== -1) {
                    let newFieldsPlatform = [...fieldsPlatform];
                    newFieldsPlatform[pos].checked = true;
                    setFieldsPlatform(newFieldsPlatform);
                    newInitialPlatform.push({ label: newFieldsPlatform[pos].label, idConnection: softwarePlatform.id });
                }
            })
            setInitialPlatforms(newInitialPlatform);
        }
    }, [software, fieldsPlatform.length])

    const verifyCanSubmit = async () => {
        let res = true;

        if (!values.name) {
            res = false;
            setRequiredName({ value: true, msg: "GENERAL.requiredField" });
        } else if (!values.name.trim()) {
            res = false;
            setRequiredName({ value: true, msg: "GENERAL.invalidValue" });
        } else if (softwares.some(soft => soft.id !== parseInt(softwareId) && soft.name.toLowerCase() === values.name.toLowerCase())) {
            res = false;
            setRequiredName({ value: true, msg: "Nome do software já em uso" });
        }

        if (!values.softwareCategoryId) {
            res = false;
            setRequiredCategory({ value: true, msg: "GENERAL.requiredField" });
        } else {
            await axios.get(process.env.REACT_APP_SERVER + `api/SoftwareCategories/${values.softwareCategoryId}`, {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
                }
            })
                .catch(error => {
                    res = false;
                    setRequiredCategory({ value: true, msg: "Categoria não encontrada" });
                });
        }

        return res;
    };

    const submit = async (event) => {
        event.preventDefault()
        if (await verifyCanSubmit()) {
            const formData = new FormData();

            // Adding Image
            formData.append('imageId', values.imageId);
            if (files[0]) {
                formData.append("image", files[0]);
            }

            // Adding platforms
            const registeredPlatform = fieldsPlatform.filter(x => x.checked === true && !initialPlatforms.find(y => y.label === x.label));
            for (let i in registeredPlatform) {
                const platform = registeredPlatform[i];
                formData.append(`platformAdd[${i}].id`, platform.id);
                formData.append(`platformAdd[${i}].name`, platform.label);
            }

            if (values.newPlatforms.length > 0) {
                var count = registeredPlatform.length > 0 ? registeredPlatform.length : 0
                for (let i in values.newPlatforms.filter(x => x)) {
                    const platform = values.newPlatforms[i];
                    formData.append(`platformAdd[${count}].name`, platform);
                }
            }

            // Removing platforms
            let unregistrededPlatform = fieldsPlatform.filter(x => x.checked === false && initialPlatforms.find(y => y.label === x.label))
            unregistrededPlatform = unregistrededPlatform.map(platform => {
                const initalPlatform = initialPlatforms.find(y => y.label === platform.label);
                if (!initalPlatform) { return platform; }
                const id = initalPlatform.idConnection;
                platform.id = id;

                return platform
            })

            for (let i in unregistrededPlatform) {
                const platform = unregistrededPlatform[i];
                formData.append(`platformDelete[${i}].id`, platform.id);
            }

            formData.append('softwares.id', softwareId);
            formData.append('softwares.name', values.name.trim());
            formData.append('softwares.manufacturer', values.developed.trim());
            formData.append('softwares.softwareCategoryId', values.softwareCategoryId);
            formData.append('softwares.url', values.url.trim());
            formData.append('softwares.description', values.description.trim());
            formData.append('softwares.version', software.version);
            formData.append('softwares.type', software.type);
            formData.append('softwares.versionName', software.versionName);
            UpdateSoftware(formData, '/Software');
        }
    }

    const handleChangePlatforms = (event) => {
        if (event && event.target && event.target.name) {
            const newFields = [...fieldsPlatform];
            const field = newFields.find(x => x.label === event.target.name);
            if (field.label === "Outros" && field.checked === false) setOthersSelected(true);
            else setOthersSelected(false);
            if (field) {
                field.checked = !field.checked;
                setFieldsPlatform(newFields);
            }
        }

    };

    const handleChangeNewPlatforms = (indexField, newValue) => {
        const platform = newValue ? newValue : "";
        if (platform.length < 50) {
            const newPlatform = [...values.newPlatforms];
            newPlatform[indexField] = platform;
            setValues({ ...values, newPlatforms: newPlatform });
        }
    }

    const addRowPlatform = () => {
        const newPlatform = [...values.newPlatforms];
        newPlatform.push("");
        setValues({ ...values, newPlatforms: newPlatform })
    }

    const removeRowPlatform = (indexField) => () => {
        const newPlatform = [...values.newPlatforms];
        const newPlatformFilter = newPlatform.filter((x, i) => i !== indexField);
        setValues({ ...values, newPlatforms: newPlatformFilter });
    }

    return (
        <Transition loading={loading}>
            <div className="container">
                <Breadcrumb
                    routes={[
                        { label: t("INVENTORY.inventory") },
                        { label: t("INVENTORY.softwares"), to: "/Software" },
                        { label: formDisabled ? t("INVENTORY.viewSoftware") : t("INVENTORY.editSoftware") },
                    ]}
                />
                <div className="container-header">
                    <div className="title">
                        <h1 className={classes.title}>{formDisabled ? t("INVENTORY.viewSoftware") : t("INVENTORY.editSoftware")}</h1>
                    </div>
                    {!formDisabled &&
                        <Button
                            className={classes.blueButton}
                            variant="contained"
                            color="primary"
                            onClick={submit}
                        >
                            {t("GENERAL.update")}
                        </Button>}
                </div>
                <Paper>
                    <div className="cabecalho">
                        {t("INVENTORY.dataSoftware")}
                    </div>
                    <div className={classes.bodyNewEquipment}>
                        <Grid container spacing={3}>
                            <Grid item xs={2} className={classes.backgroundImage}>
                                <Grid item xs={2}>
                                    <InputDragDrop
                                        disabled={formDisabled}
                                        files={files}
                                        onDrop={onDrop}
                                        hasUpdatedFile={hasUpdatedFile}
                                        imagePath={values.imagePath}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <Grid item xs={12} sm={12}>
                                    <TooltipError open={requiredName.value} title={t(requiredName.msg)}>
                                        <TextField
                                            disabled={formDisabled}
                                            className={classes.textField}
                                            label={"Nome do Software*"}
                                            value={values.name}
                                            onChange={(ev) => {
                                                if (ev.target.value.length <= 200) {
                                                    setRequiredName({ ...requiredName, value: false });
                                                    handleChange("name", ev.target.value);
                                                }
                                            }}
                                            margin="normal"
                                        />
                                    </TooltipError>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TooltipError open={requiredCategory.value} title={t(requiredCategory.msg)}>
                                        <TextField
                                            select
                                            disabled={formDisabled}
                                            label={t("INVENTORY.category") + "*"}
                                            name="category"
                                            value={values.softwareCategoryId}
                                            onChange={(ev) => {
                                                setRequiredCategory({ ...requiredCategory, value: false });
                                                handleChange("softwareCategoryId", ev.target.value)
                                            }}
                                            className={classes.textField}
                                            margin="normal"
                                            classes={{
                                                root: classes.inputRoot,
                                                input: classes.inputInput
                                            }}
                                            inputProps={{ "aria-label": "search" }}
                                        >
                                            {softwareCategories.map(category => (
                                                <MenuItem key={category.id} value={category.id}>
                                                    {category.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </TooltipError>
                                </Grid>
                                {formDisabled ?
                                    <React.Fragment>
                                        <Grid style={platformText}>{t("INVENTORY.platform")}</Grid>
                                        {initialPlatforms.map(platform => (
                                            <Grid style={platformsFieldsText} spacing={3} item xs={12} sm={12} key={platform.idConnection}>{platform.label}</Grid>
                                        ))}
                                    </React.Fragment>
                                    :
                                    <Grid item xs={12} sm={12}>
                                        <FormControl component="fieldset">
                                            <Grid container>
                                                <Grid container item spacing={1} md={12} lg={12}>
                                                    <Grid item xs={12}>
                                                        <FormLabel component="legend">Plataforma *</FormLabel>
                                                    </Grid>
                                                    <Grid item xs={4} style={{ display: 'flex', flexDirection: 'column' }}>
                                                        {fieldsPlatform.map(
                                                            (field, idx) =>
                                                                <FormControlLabel
                                                                    key={field.label + idx}
                                                                    label={t(field.label)}
                                                                    control={
                                                                        <Checkbox color="primary" checked={field.checked} name={field.label} onChange={handleChangePlatforms} />
                                                                    }
                                                                />
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </FormControl>
                                    </Grid>
                                }
                                {/* {othersSelected &&
                                    <Grid container>
                                        {values.newPlatforms.map((aut, idx) =>
                                            <>
                                                <Grid key={aut + idx} item xs={11} sm={11}>
                                                    <TextField
                                                        label={t("INVENTORY.addPlatform")}
                                                        margin="normal"
                                                        value={aut}
                                                        className={classes.textField}
                                                        classes={{
                                                            root: classes.inputRoot,
                                                            input: classes.inputInput
                                                        }}
                                                        onChange={(event) => {
                                                            handleChangeNewPlatforms(idx, event.target.value);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={1} sm={1}>
                                                    {(values.newPlatforms.length > 1) &&
                                                        <Tooltip
                                                            title="Remover plataforma"
                                                            aria-label="Remover"
                                                            placement="top"
                                                        >
                                                            <IconButton
                                                                style={{ float: "right", marginTop: "20px" }}
                                                                onClick={removeRowPlatform(idx)}
                                                                className={classes.icons}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    }
                                                </Grid>
                                            </>
                                        )}
                                        <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Typography
                                                className={classes.link}
                                                onClick={addRowPlatform}
                                                style={{ cursor: 'pointer', fontSize: 14 }}
                                            >
                                                {t("INVENTORY.newPlatforms")}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                } */}
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        disabled={formDisabled}
                                        className={classes.textField}
                                        label={"Desenvolvido por"}
                                        value={values.developed}
                                        onChange={(ev) => { if (ev.target.value.length <= 60) handleChange("developed", ev.target.value) }}
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        disabled={formDisabled}
                                        className={classes.textField}
                                        label={"Link de acesso"}
                                        value={values.url}
                                        onChange={(ev) => handleChange("url", ev.target.value)}
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        disabled={formDisabled}
                                        multiline
                                        fullWidth
                                        rows="6"
                                        variant="outlined"
                                        className={classes.textField}
                                        label={t("INVENTORY.description")}
                                        value={values.description}
                                        margin="normal"
                                        onChange={(ev) => { if (ev.target.value.length <= 250) handleChange("description", ev.target.value) }}
                                        inputProps={{
                                            maxLength: 250
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Paper>
            </div>
            <Footer />
        </Transition>
    )
}

export default EditSoftware;