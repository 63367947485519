/* eslint-disable */
import DateFnsUtils from "@date-io/date-fns";
import AppBar from '@material-ui/core/AppBar';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Attachment from "@material-ui/icons/Attachment";
import Clear from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import axios from "axios";
import Footer from "components/layout/Footer";
import Spinner from "components/layout/spinners/Index";
import InventoryContext from "context/inventory/inventoryContext";
import ProjectsContext from "context/projects/projectsContext";
import UsersContext from "context/users/usersContext";
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import { useStyles } from "components/layout/CommonStyles";
import { dateToBR } from "utils/dates/DateToBRr";
import Cookies from "js-cookie";

const tooltipTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#2c3e51"
        }
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                color: "white",
                fontSize: "1em",
                backgroundColor: "red",
                opacity: "0.7"
            }
        }
    }
});

function TabPanel(props) {
    const { children, value, index } = props;
    return (
        <div>
            {value === index && children}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const EditExemplar = props => {
    const fileDownload = require("js-file-download");

    const { ExemplarId } = props;
    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }

    const classes = useStyles();
    const [t] = useTranslation();
    const inventoryContext = useContext(InventoryContext);
    const projectsContext = useContext(ProjectsContext);
    const { getProjects, projects } = projectsContext;
    const usersContext = useContext(UsersContext);
    const [hasUpdatedFile, setHasUpdatedFile] = useState(false);
    const [mustRedirect, setMustRedirect] = useState(false);
    const [titleName, setTitle] = useState("GENERAL.requiredField");
    const [categoriesArray, setCategoriesArray] = useState([]);
    const [projectsArray, setProjectsArray] = useState([]);
    const [placesArray, setPlacesArray] = useState([]);
    const [usersArray, setUsersArray] = useState([]);
    const [usersLibraryAdmArray, setUsersLibraryAdmArray] = useState([]);
    const [statusArray, setStatusArray] = useState([]);
    const [files, setFiles] = useState([]);
    const [toDeleteFile, setToDeleteFile] = useState([]);
    const [requiredTitle, setRequiredTitle] = useState(false);
    const [requiredCategory, setRequiredCategory] = useState(false);
    const [requiredDate, setRequiredDate] = useState(false);
    const [requiredAuthor, setRequiredAuthor] = useState(false);
    const [requiredEdition, setRequiredEdition] = useState(false);
    const [requiredCoin, setRequiredCoin] = useState(false);
    const [requiredPlace, setRequiredPlace] = useState(false);
    const [requiredPurchasePrice, setRequiredPurchasePrice] = useState(false);
    const [requiredAdministrativeResponsible, setRequiredAdministrativeResponsible] = useState(false);
    const [requiredLibrary, setRequiredLibrary] = useState(false);
    const [requiredProject, setRequiredProject] = useState(false);
    const [requiredDescription, setRequiredDescription] = useState(false);
    const [value, setValue] = React.useState(1);
    const [loading, setLoading] = useState(true);


    const handleChangePanel = (event, newValue) => {
        setValue(newValue);
    };

    const [values, setValues] = useState({
        id: "",
        itemId: "",
        bookTitleId: "",
        imagePath: "",
        imageName: "",
        imageIsUrl: "",
        isbn: "",
        title: "",
        subTitle: "",
        author: "",
        authors: [""],
        edition: "",
        publisher: "",
        language: "",
        category: "",
        synthesis: "",
        coin: "",
        coinValues: "",
        acquisitionMethod: "",
        projectId: "",
        freight: "",
        purchasePrice: "",
        totalPurchase: "",
        invoice: "",
        administrativeResponsibleId: "",
        directResponsibleId: "",
        library: "",
        statusId: "",
        status: "",
        placeId: "",
        bookCategoryId: "",
        exemplarCode: "",
        iof: "",
        observation: "",
        descriptionAcquisitionMethod: ""
    });

    const {
        getPlaces,
        places,
        bookCategories,
        getBookCategories,
        getAllStatus,
        allStatus,
        getLibraryAdms,
        libraryAdms,
        getInventoryImage,
        inventoryImage,
        getExemplar,
        exemplar,
        UpdateExemplar
    } = inventoryContext;

    const {
        getUsers,
        users
    } = usersContext;

    const coins = [
        {
            value: "BRL",
            label: "BRL (Real Brasileiro)"
        },
        {
            value: "USD",
            label: "USD (Dólar Comercial)"
        },
        {
            value: "USDT",
            label: "USDT (Dólar Turismo)"
        },
        {
            value: "CAD",
            label: "CAD (Dólar Canadense)"
        },
        {
            value: "AUD",
            label: "AUD (Dólar Australiano)"
        },
        {
            value: "EUR",
            label: "EUR (Euro)"
        },
        {
            value: "GBP",
            label: "GBP (Libra Esterlina)"
        },
        {
            value: "ARS",
            label: "ARS (Peso Argentino)"
        },
        {
            value: "JPY",
            label: "JPY (Iene Japonês)"
        },
        {
            value: "CHF",
            label: "CHF (Franco Suíço)"
        },
        {
            value: "CNY",
            label: "CNY (Yuan Chinês)"
        },
        {
            value: "BTC",
            label: "BTC (Bitcoin)"
        },
        {
            value: "LTC",
            label: "LTC (Litecoin)"
        },
        {
            value: "ETH",
            label: "ETH (Ethereum)"
        },
        {
            value: "XRP",
            label: "XRP (Ripple)"
        },
    ];

    const status = [
        {
            value: "FREE",
            label: "Disponível"
        },
        {
            value: "INACTIVE",
            label: "Indisponível"
        },
        {
            value: "RENTED",
            label: "Emprestado"
        },
        {
            value: "DONATED",
            label: "Doado"
        },
    ];

    const library = [
        {
            value: "GENERAL",
            label: "Geral"
        },
        {
            value: "COORDINATION",
            label: "Coordenação"
        }
    ];

    useEffect(() => {
        getPlaces();
        getBookCategories();
        getProjects();
        getUsers();
        getAllStatus();
        getLibraryAdms();
    }, []);

    useEffect(() => {
        getExemplar(ExemplarId);
        getInventoryImage(exemplar.bookTitleId);
        setValues({
            ...values,
            id: exemplar.id && exemplar.id,
            itemId: exemplar.item && exemplar.item.id,
            imagePath: inventoryImage[0] ? inventoryImage[0].imagePath : "",
            imageName: inventoryImage[0] ? inventoryImage[0].imageName : "",
            imageIsUrl: inventoryImage[0] && inventoryImage[0].isUrl,
            exemplarCode: exemplar ? exemplar.exemplarCode : exemplarCode,
            isbn: exemplar.bookTitle ? exemplar.bookTitle.isbn : "",
            title: exemplar.bookTitle ? exemplar.bookTitle.title : "",
            subtitle: exemplar.bookTitle && exemplar.bookTitle.subTitle ? exemplar.bookTitle.subTitle : "-",
            publisher: exemplar.bookTitle ? exemplar.bookTitle.publisher : "",
            language: exemplar.bookTitle && exemplar.bookTitle.language ? exemplar.bookTitle.language : "-",
            edition: exemplar.bookTitle ? exemplar.bookTitle.edition : "",
            bookCategoryId: exemplar.bookTitle ? exemplar.bookTitle.categoryId : "",
            synthesis: exemplar.bookTitle && exemplar.bookTitle.synopsis ? exemplar.bookTitle.synopsis : "-",
            authors: exemplar.bookTitle && exemplar.bookTitle.libraryBookAuthor && exemplar.bookTitle.libraryBookAuthor.length > 0 ? exemplar.bookTitle.libraryBookAuthor.map(x => x.authorName) : [""],
            projectId: exemplar.item ? exemplar.item.projectId : "",
            statusId: exemplar.item ? exemplar.item.statusId : "",
            status: exemplar.status ? exemplar.status : "",
            placeId: exemplar.item ? exemplar.item.placeId : "",
            library: exemplar.library ? exemplar.library : "",
            administrativeResponsibleId: exemplar.administrativeResponsibleId ? exemplar.administrativeResponsibleId : "",
            directResponsibleId: exemplar.item && exemplar.item.responsibleId ? exemplar.item.responsibleId : "",
            invoice: exemplar.item && exemplar.item.invoice ? exemplar.item.invoice : "",
            bookTitleId: exemplar ? exemplar.bookTitleId : "",
            freight: exemplar.freight ? exemplar.freight : "",
            totalPurchase: exemplar.totalpurchase ? exemplar.totalpurchase : "",
            purchasePrice: exemplar.purchaseprice ? exemplar.purchaseprice : "",
            coin: exemplar.coin ? exemplar.coin : "BRL",
            coinValues: exemplar.currencyquote ? exemplar.currencyquote : "1",
            iof: exemplar.iof ? exemplar.iof : "",
            observation: exemplar.observation ? exemplar.observation : "",
            descriptionAcquisitionMethod: exemplar.descriptionAcquisitionMethod ? exemplar.descriptionAcquisitionMethod : "",
            acquisitionMethod: exemplar.acquisitionMethod ? exemplar.acquisitionMethod : (exemplar.item && exemplar.item.projectId ? "Projects" : "")
        });

        setDates({
            ...dates,
            dateAcquisition: exemplar.item ? exemplar.item.acquisitionDate : ""
        });

        setDataState({
            ...dataState,
            files: exemplar.item ? exemplar.item.equipmentFiles : "",
            //  rejectedFiles: dataState.rejectedFiles.concat(existentFiles)
        });

    }, [JSON.stringify(exemplar).length, JSON.stringify(inventoryImage).length]);

    useEffect(() => {
        !inventoryImage && inventoryImage.length === 0 || libraryAdms.length === 0 || bookCategories.length === 0 || places.length === 0 || allStatus.length === 0 || projects.length === 0 || exemplar.length === 0 ?
            setLoading(true)
            :
            setLoading(false)
    }, [JSON.stringify(exemplar).length,
    JSON.stringify(inventoryImage).length,
    JSON.stringify(libraryAdms).length,
    JSON.stringify(bookCategories).length,
    JSON.stringify(places).length,
    JSON.stringify(allStatus).length,
    JSON.stringify(projects).length,
    ]);

    const transitions = useTransition(loading, null, {
        from: { opacity: 0 },
        enter: {
            opacity: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "calc(100vh - 70px)"
        },
        leave: { opacity: 0 }
    });

    const [dataState, setDataState] = useState({
        files: [],
        rejectedFiles: []
    });

    const [dates, setDates] = useState({
        dateAcquisition: null,
    });

    const handleDates = name => e => {
        setDates({
            ...dates,
            [name]: e
        });
        runCoin(values.coin)
    };

    const sortElement = (elemnts) => {
        elemnts.sort(function (a, b) {
            if (a.category === null) a.category = "";
            if (b.category === null) b.category = "";

            if (a.category.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() >
                b.category.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim()) {
                return 1;
            }
            if (a.category.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() <
                b.category.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim()) {
                return -1;
            }
            // a must be equal to b
            return 0;
        });
    }

    const sortElementName = (elemnts) => {
        elemnts.sort(function (a, b) {
            if (a.name === null) a.name = "";
            if (b.name === null) b.name = "";

            if (a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() >
                b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim()) {
                return 1;
            }
            if (a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() <
                b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim()) {
                return -1;
            }
            // a must be equal to b
            return 0;
        });
    }

    useEffect(() => {
        if (allStatus) {
            const status = [...allStatus];
            sortElementName(status);

            var aux = {};
            var _status = [];
            for (var i = 0; i < status.length; i++) {
                var item = status[i];
                aux.value = item.id;
                aux.label = item.name;
                _status.push(aux);
                aux = {};
            }
            setStatusArray(_status)
        }
    }, [allStatus]);

    useEffect(() => {
        const myPlaces = [...places];
        sortElementName(myPlaces);

        var aux = {};
        var _places = [];
        for (var i = 0; i < myPlaces.length; i++) {
            var item = myPlaces[i];
            aux.value = item.id;
            aux.label = item.name;
            _places.push(aux);
            aux = {};
        }
        setPlacesArray(_places);
    }, [places]);

    useEffect(() => {
        const allUsers = [...users];
        const LibraryPermission = [...libraryAdms]
        sortElementName(allUsers);

        var aux = {};
        var _users = [];
        for (var i = 0; i < allUsers.length; i++) {
            var item = allUsers[i];
            aux.value = item.id;
            aux.label = item.name;
            _users.push(aux);
            aux = {};
        }

        const permissionFilter = LibraryPermission.map(function (elem) {
            return elem.userId
        });

        var _libraryUsers = _users.filter(x => permissionFilter.includes(x.value))
        setUsersArray(_users);
        setUsersLibraryAdmArray(_libraryUsers)
    }, [users, libraryAdms]);

    useEffect(() => {
        const allProjects = [...projects];
        sortElementName(allProjects);

        var aux = {};
        var _projects = [];
        for (var i = 0; i < allProjects.length; i++) {
            var item = allProjects[i];
            aux.value = item.idInformation;
            aux.label = item.name;
            _projects.push(aux);
            aux = {};
        }
        setProjectsArray(_projects);
    }, [projects]);

    useEffect(() => {
        const categories = [...bookCategories];
        sortElement(categories);

        var aux = {};
        var _categories = [];
        for (var i = 0; i < categories.length; i++) {
            var item = categories[i];
            aux.value = item.id;
            aux.label = item.category;
            _categories.push(aux);
            aux = {};
        }
        setCategoriesArray(_categories);
    }, [bookCategories]);

    const onDrop = acceptedFiles => {
        if (acceptedFiles[0] !== undefined) {
            setHasUpdatedFile(true);
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    };

    useEffect(() => {
        runCoin(values.coin)
    }, [JSON.stringify(dates.dateAcquisition)]);

    function addZero(i) {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    }

    const convertDatebyCoin = date => {
        var year = date.getFullYear();
        var month = addZero(date.getMonth() + 1);
        var day = addZero(date.getDate());
        return `${year}${month}${day}`;
    };

    function runCoin(valor) {
        var coin = valor;
        if (coin !== "") {
            var date = convertDatebyCoin(new Date(dates.dateAcquisition));
            axios.get(`https://economia.awesomeapi.com.br/${coin}?start_date=${date}&end_date=${date}`, {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
                }
            }).then(res => {
                if (res.data && res.data.length) {
                    var response = res.data && res.data[0];
                    var coinValueFormated = response && String(parseFloat(response.bid).toFixed(2));
                    setValues({
                        ...values,
                        coinValues: coinValueFormated ? coinValueFormated : "",
                        coin: coin
                    });
                } else {
                }
            });
        }
    }

    const onRemoveFileDocs = (data) => {
        if (data) {
            let remainingFiles = [];
            let updatedList = [];
            for (const file of dataState.files) {
                if ((file.path === data.path) && file.lastModified === data.lastModified && file.size === data.size) {
                    if (file.id) {
                        setToDeleteFile([...toDeleteFile, file.id]);
                        updatedList = dataState.rejectedFiles.filter(el => el !== `Arquivo já existente - ${data.fileName}`);
                    }
                    continue;
                }
                remainingFiles.push(file)
            }
            setDataState({ ...dataState, files: remainingFiles, rejectedFiles: updatedList })
        }
    };

    const image = files.map(file => (
        <div className={classes.thumb} key={file.name}>
            <div className={classes.thumbInner}>
                <img
                    src={file.preview}
                    className={classes.imgNewEquipment}
                />
            </div>
        </div>
    ));

    const handleChange = (name, value) => {
        if (name === "coin") {
            if (value === "BRL") {
                setValues({ ...values, [name]: value, coinValues: 1 });
            } else {
                setValues({ ...values, [name]: runCoin(value), totalPurchase: String((parseFloat(values.coinValues) * parseFloat(values.freight)) + (parseFloat(values.coinValues) * parseFloat(values.purchasePrice))) });
            }
        }
        else if (name === "coinValues" || name === "invoice" || name === "purchasePrice" || name === "freight" || name === "iof") {
            if (!isNaN(value)) {
                setValues({ ...values, [name]: value });
            }
        }
        else if (name === "edition") {
            if (!isNaN(value)) {
                setValues({ ...values, [name]: value });
            }
        }
        else {
            setValues({ ...values, [name]: value });
        }
    };

    const formatDate = (data) => {
        var date = new Date(data)
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getUTCHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    }

    const redirect = go => {
        if (go) return <Redirect to={`/Books`} />;
    };

    const onDropFiles = acceptedFiles => {
        let newFiles = [];
        let existentFiles = [];

        for (let i = 0; i < acceptedFiles.length; i++) {
            let hasTheSameName = dataState.files.filter(el => (el.fileName ? el.fileName.toLowerCase() : el.name.toLowerCase()) ===
                acceptedFiles[i].name.toLowerCase()).length > 0;
            if (hasTheSameName) {
                existentFiles.push(`Arquivo já existente - ${acceptedFiles[i].name}`);
            }
            else {
                newFiles.push(acceptedFiles[i])
            }
        }

        setDataState({
            ...dataState,
            files: dataState.files.concat(newFiles),
            rejectedFiles: dataState.rejectedFiles.concat(existentFiles)
        });
    };

    function humanFileSize(size) {
        let i = Math.floor(Math.log(size) / Math.log(1024));
        return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    };

    const onClickDownload = async (file) => {
        if (dataState && dataState.files.length > 0) {
            if (file.id) {
                const noSlashPath = file.path.replace(/\//g, ";");
                const url = process.env.REACT_APP_SERVER + "api/file/" + noSlashPath;
                axios.get(url, {
                    headers: {
                        "Content-type": "application/json",
                        "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
                    },
                    responseType: "blob"
                }).then(function (res) {
                    const blob = new Blob([res.data], {});
                    const name = file.fileName ? file.fileName : file.name;
                    fileDownload(blob, name);
                });
            } else {
                fileDownload(file, file.name);
            }
        }
    };

    const deleteRejectedFile = rejectedFile => {
        let updatedList = dataState.rejectedFiles.filter(el => el !== rejectedFile);
        setDataState({ ...dataState, rejectedFiles: updatedList });
    };

    const verifyCanSubmit = () => {
        let res = true;
        if (dates.dateAcquisition === null) {
            res = false;
            setRequiredDate(true);
        }
        if (!values.placeId) {
            res = false;
            setRequiredPlace(true);
        }
        if (!values.purchasePrice) {
            res = false;
            setRequiredPurchasePrice(true);
        }
        if (!values.administrativeResponsibleId) {
            res = false;
            setRequiredAdministrativeResponsible(true);
        }
        if (!values.library) {
            res = false;
            setRequiredLibrary(true);
        }
        if (values.acquisitionMethod == "Projects") {
            if (!values.projectId) {
                res = false;
                setRequiredProject(true);
            }
        } else {
            if (!values.descriptionAcquisitionMethod) {
                res = false;
                setRequiredDescription(true);
            }
        }
        return res;
    };

    const submit = () => {
        const canSubmit = verifyCanSubmit();
        if (canSubmit) {
            let formData = new FormData();

            // Adding equipment image
            if (files[0]) {
                formData.append("image", files[0]);
            } else {
                if (values.imagePath) {
                    formData.append("imageUrl", values.imagePath);
                }
            }

            // Adding documents
            for (let i in dataState.files) {
                formData.append("files", dataState.files[i]);
            }

            formData.append(`exemplar[0].id`, values.id);
            formData.append(`exemplar[0].status`, values.status);
            formData.append(`exemplar[0].coin`, values.coin);
            formData.append(`exemplar[0].exemplarCode`, values.exemplarCode);
            formData.append(`exemplar[0].currencyquote`, values.coinValues);
            formData.append(`exemplar[0].freight`, values.freight);
            formData.append(`exemplar[0].purchasePrice`, values.purchasePrice);
            formData.append(`exemplar[0].iof`, values.iof);
            formData.append(`exemplar[0].totalPurchase`, String((parseFloat(values.coinValues) * parseFloat(values.freight ? values.freight : 0)) + (parseFloat(values.coinValues) * parseFloat(values.purchasePrice))));
            formData.append(`exemplar[0].bookTitleId`, values.bookTitleId);
            formData.append(`exemplar[0].observation`, values.observation);
            formData.append(`exemplar[0].descriptionAcquisitionMethod`, values.descriptionAcquisitionMethod ? values.descriptionAcquisitionMethod : "");
            formData.append(`exemplar[0].acquisitionMethod`, values.acquisitionMethod);
            // formData.append(`exemplar[0].purchasedForFine`, values.acquisitionMethod === "Fines" ? true : false);
            formData.append(`exemplar[0].administrativeResponsibleId`, values.administrativeResponsibleId);
            formData.append(`exemplar[0].library`, values.library);

            formData.append(`exemplar[0].item.id`, values.itemId && values.itemId);
            formData.append(`exemplar[0].item.acquisitionDate`, dates.dateAcquisition && formatDate(dates.dateAcquisition));
            formData.append(`exemplar[0].item.categoryId`, 5);
            formData.append(`exemplar[0].item.invoice`, values.invoice);
            formData.append(`exemplar[0].item.itemStatusId`, 7732);
            formData.append(`exemplar[0].item.responsibleId`, values.directResponsibleId);
            formData.append(`exemplar[0].item.name`, values.title);
            formData.append(`exemplar[0].item.placeId`, values.placeId);
            formData.append(`exemplar[0].item.projectId`, values.acquisitionMethod === "Projects" ? values.projectId : values.acquisitionMethod === "Fines" ? 643 : 227);
            formData.append(`exemplar[0].item.class`, "library.Book");
            formData.append(`exemplar[0].item.bookCategoryId`, values.bookCategoryId);
            formData.append(`exemplar[0].item.value`, String((parseFloat(values.coinValues) * parseFloat(values.freight)) + (parseFloat(values.coinValues) * parseFloat(values.purchasePrice))));

            if (values.administrativeResponsibleId !== exemplar.administrativeResponsibleId) {
                formData.append(`exemplar[0].item.inventoryResponsibleHistory[0].operation`, "Responsável administrativo");
                formData.append(`exemplar[0].item.inventoryResponsibleHistory[0].userId`, values.administrativeResponsibleId);
                formData.append(`exemplar[0].item.inventoryResponsibleHistory[0].executorId`, auth.id);
                formData.append(`exemplar[0].item.inventoryResponsibleHistory[0].date`, formatDate(new Date()));


                // Email Change of Owner
                var emailData = []
                var auxEmail = {}
                var user = users.filter(user =>
                    values.administrativeResponsibleId === user.id
                )
                auxEmail.type = "BOOK";
                auxEmail.date = dateToBR(new Date());
                auxEmail.operatorName = auth.name;
                auxEmail.user = user.map(user => {
                    return {
                        email: user.email,
                        name: user.name
                    }
                })
                auxEmail.name = auxEmail.user[0].name;
                let oldPlace = placesArray.filter(place => exemplar.item.placeId === place.value);
                let newPlace = placesArray.filter(place => values.placeId === place.value);
                let category = categoriesArray.filter(category => values.bookCategoryId === category.value)

                auxEmail.local = [
                    [
                        oldPlace[0].label,
                        newPlace[0].label
                    ]
                ]
                auxEmail.itens = [[
                    values.title,
                    category[0].label,
                    values.exemplarCode ? "BIB-" + values.exemplarCode : "-",
                ]]
                if (Object.keys(auxEmail).length !== 0) {
                    emailData.push(auxEmail)
                    var auxEmail = {}
                }
            }

            UpdateExemplar(formData, emailData);
            setMustRedirect(true);
        }
    };


    const totalPurchaseOfBooks = () => {
        if (values.purchasePrice || values.freight) {
            const bookPrice = values.purchasePrice ? parseFloat(values.purchasePrice) : 0;
            const freightPrice = values.freight ? parseFloat(values.freight) : 0;

            const coinValue = values.coinValues ? parseFloat(values.coinValues) : 1;

            return (bookPrice * coinValue + freightPrice * coinValue).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        }
        return '';
    }

    return transitions.map(({ item, key, props }) =>
        <React.Fragment>
            {redirect(mustRedirect)}
            <div className="container">
                <Breadcrumbs
                    className={classes.breadCrumbs}
                    aria-label="breadcrumb"
                    separator={<NavigateNextIcon fontSize="small" />}
                >
                    <Typography>{t("INVENTORY.inventory")}</Typography>
                    <Link className={classes.link} to="/Books">
                        <Typography>{t("INVENTORY.books")}</Typography>
                    </Link>
                    <Typography>{t("INVENTORY.editBook")}</Typography>
                </Breadcrumbs>
                <div className="container-header">
                    <div className="title">
                        <h1 className={classes.title}>{t("INVENTORY.editBook")}</h1>
                    </div>
                    <Button
                        className={classes.blueButton}
                        variant="contained"
                        color="primary"
                        onClick={submit}
                    >
                        {t("GENERAL.update")}
                    </Button>
                </div>
                {item ? (
                    <animated.div key={key} style={props}>
                        <Spinner />;
                    </animated.div>
                ) : (
                    <animated.div key={key} style={props}>
                        <React.Fragment>
                            <Paper >
                                <div className="cabecalho">
                                    {t("INVENTORY.equipmentDatas").toUpperCase()}
                                </div>
                                <div className={classes.bodyNewEquipment}>
                                    <AppBar position="static" style={{ boxShadow: "none", transform: "none" }}>
                                        <Tabs style={{ backgroundColor: "#ffffff", color: "black" }} indicatorColor="primary" value={value} onChange={handleChangePanel} >
                                            <Tab style={{ fontWeight: "bold" }} label={t("INVENTORY.dataTitle").toUpperCase()} {...a11yProps(0)} />
                                            <Tab style={{ fontWeight: "bold" }} label={t("INVENTORY.dataExemplary").toUpperCase()} {...a11yProps(1)} />
                                        </Tabs>
                                    </AppBar>
                                    <Grid container spacing={3}>
                                        <Grid item xs={2} className={classes.backgroundImage}>
                                            <Grid item xs={2}>
                                                <Dropzone
                                                    disabled
                                                    variant="outlined"
                                                    component="span"
                                                    className={classes.textField}
                                                    onDrop={onDrop}
                                                    accept="image/jpeg, 
                                                        image/png, 
                                                        image/bmp"
                                                >
                                                    {({
                                                        getRootProps,
                                                        getInputProps,
                                                        isDragActive,
                                                        isDragReject
                                                    }) => (
                                                        <div {...getRootProps()}>
                                                            <input {...getInputProps()} />
                                                            {!isDragActive && (
                                                                <FormControl
                                                                    className={classes.customAttachedDocumentTextField}
                                                                >
                                                                    {!values.imagePath ?
                                                                        <Grid item xs={2}>
                                                                            <div className={classes.thumb} key={"upload-equipament-image.jpg"}>
                                                                                <div className={classes.thumbInner}>
                                                                                    <img className={classes.imgNewEquipment} alt="add-image1" src={window.location.origin + '/img/upload-equipament-image.png'} />
                                                                                </div>
                                                                            </div>
                                                                        </Grid>
                                                                        :
                                                                        <Grid item xs={2}>
                                                                            <img className={classes.imgNewEquipment} alt="add-image2" src={values.imagePath + "/" + values.imageName} />
                                                                        </Grid>


                                                                    }
                                                                </FormControl>
                                                            )}
                                                            {isDragReject && (
                                                                <FormControl
                                                                    className={classes.customAttachedDocumentTextField}
                                                                >
                                                                    <Input
                                                                        id="custom-attached-input-cooperation"
                                                                        readOnly={true}
                                                                        endAdornment={
                                                                            <InputAdornment
                                                                                style={{ transform: "rotate(270deg)" }}
                                                                                position="end"
                                                                            >
                                                                                <Attachment />
                                                                            </InputAdornment>
                                                                        }
                                                                        aria-describedby="weight-helper-text"
                                                                        inputProps={{
                                                                            "aria-label": "weight"
                                                                        }}
                                                                        value={t(title)}
                                                                    />
                                                                </FormControl>
                                                            )}
                                                        </div>
                                                    )}
                                                </Dropzone>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={7}>
                                            <TabPanel value={value} index={0}>
                                                <Grid item xs={12} sm={5}>
                                                    <TextField
                                                        className={classes.textField}
                                                        disabled
                                                        label={t("INVENTORY.isbn")}
                                                        value={values.isbn}
                                                        margin="normal"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <MuiThemeProvider theme={tooltipTheme}>
                                                        <Tooltip
                                                            TransitionComponent={Zoom}
                                                            open={requiredTitle}
                                                            title={t(titleName)}
                                                        >
                                                            <TextField
                                                                disabled
                                                                className={classes.textField}
                                                                label={t("INVENTORY.title") + "*"}
                                                                value={values.title}
                                                                onChange={(ev) => { if (ev.target.value.length <= 50) handleChange("title", ev.target.value), setRequiredTitle(false) }}
                                                                margin="normal"
                                                            />
                                                        </Tooltip>
                                                    </MuiThemeProvider>
                                                </Grid>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} sm={10}>
                                                        <TextField
                                                            disabled
                                                            className={classes.textField}
                                                            label={t("INVENTORY.subTitle")}
                                                            value={values.subTitle}
                                                            onChange={(ev) => { if (ev.target.value.length <= 50) handleChange("subTitle", ev.target.value) }}
                                                            margin="normal"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={2}>
                                                        <MuiThemeProvider theme={tooltipTheme}>
                                                            <Tooltip
                                                                TransitionComponent={Zoom}
                                                                open={requiredEdition}
                                                                title={t(titleName)}
                                                            >
                                                                <TextField
                                                                    disabled
                                                                    className={classes.textField}
                                                                    label={t("INVENTORY.edition") + "*"}
                                                                    value={values.edition}
                                                                    onChange={(ev) => { if (ev.target.value.length <= 50) handleChange("edition", ev.target.value), setRequiredEdition(false) }}
                                                                    margin="normal"
                                                                />
                                                            </Tooltip>
                                                        </MuiThemeProvider>
                                                    </Grid>
                                                </Grid>
                                                {values.authors.map((aut, idx) =>
                                                    <Grid item xs={12} sm={12} key={idx}>
                                                        <MuiThemeProvider theme={tooltipTheme}>
                                                            <Tooltip
                                                                TransitionComponent={Zoom}
                                                                open={requiredAuthor}
                                                                title={t(titleName)}
                                                            >
                                                                <TextField
                                                                    disabled
                                                                    className={classes.textField}
                                                                    label={t("INVENTORY.BookAuthor") + "*"}
                                                                    value={aut}
                                                                    onChange={(ev) => { if (ev.target.value.length <= 50) handleChange("author", ev.target.value), setRequiredAuthor(false) }}
                                                                    margin="normal"
                                                                />
                                                            </Tooltip>
                                                        </MuiThemeProvider>
                                                    </Grid>
                                                )}
                                                <Grid item xs={12} sm={12}>
                                                    <TextField
                                                        disabled
                                                        className={classes.textField}
                                                        label={t("INVENTORY.publisher")}
                                                        value={values.publisher}
                                                        onChange={(ev) => { if (ev.target.value.length <= 50) handleChange("publisher", ev.target.value) }}
                                                        margin="normal"
                                                    />

                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <TextField
                                                        disabled
                                                        className={classes.textField}
                                                        label={t("INVENTORY.language")}
                                                        value={values.language}
                                                        onChange={(ev) => { if (ev.target.value.length <= 50) handleChange("language", ev.target.value) }}
                                                        margin="normal"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <MuiThemeProvider theme={tooltipTheme}>
                                                        <Tooltip
                                                            TransitionComponent={Zoom}
                                                            open={requiredCategory}
                                                            title={t("GENERAL.requiredField")}
                                                        >
                                                            <TextField
                                                                disabled
                                                                select
                                                                id="client"
                                                                label={t("INVENTORY.category") + "*"}
                                                                name="category"
                                                                value={values.bookCategoryId}
                                                                onChange={(ev) => {
                                                                    setRequiredCategory(false)
                                                                    handleChange("bookCategoryId", ev.target.value)
                                                                }}
                                                                className={classes.textField}
                                                                margin="normal"
                                                                classes={{
                                                                    root: classes.inputRoot,
                                                                    input: classes.inputInput
                                                                }}
                                                                inputProps={{ "aria-label": "search" }}
                                                            >
                                                                {categoriesArray.map(category => (
                                                                    <MenuItem key={category.value} value={category.value}>
                                                                        {category.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Tooltip>
                                                    </MuiThemeProvider>
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <TextField
                                                        disabled
                                                        multiline
                                                        fullWidth
                                                        rows="6"
                                                        variant="outlined"
                                                        className={classes.textField}
                                                        label={t("INVENTORY.synthesis")}
                                                        value={values.synthesis}
                                                        margin="normal"
                                                        onChange={(ev) => handleChange("synthesis", ev.target.value)}
                                                        inputProps={{
                                                            maxLength: 250
                                                        }}
                                                    />
                                                </Grid>
                                            </TabPanel>
                                            <TabPanel value={value} index={1}>
                                                <Grid container spacing={3}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <Grid item xs={12} xs={5}>
                                                            <MuiThemeProvider theme={tooltipTheme}>
                                                                <Tooltip
                                                                    TransitionComponent={Zoom}
                                                                    open={requiredDate}
                                                                    title={t(titleName)}>
                                                                    <KeyboardDatePicker
                                                                        className={classes.textField}
                                                                        disableToolbar
                                                                        autoOk={true}
                                                                        variant="inline"
                                                                        format="dd/MM/yyyy"
                                                                        id="dateAcquisition"
                                                                        margin="normal"
                                                                        maxDate={new Date()}
                                                                        invalidDateMessage=""
                                                                        label={t("INVENTORY.dateAcquisition") + "*"}
                                                                        //onFocus={clearRequiredMessage}
                                                                        KeyboardButtonProps={{
                                                                            "aria-label": "change date"
                                                                        }}
                                                                        value={dates.dateAcquisition}
                                                                        onChange={handleDates("dateAcquisition")}
                                                                    />
                                                                </Tooltip>
                                                            </MuiThemeProvider>
                                                        </Grid>
                                                    </MuiPickersUtilsProvider>

                                                </Grid>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} sm={6}>
                                                        <MuiThemeProvider theme={tooltipTheme}>
                                                            <Tooltip
                                                                TransitionComponent={Zoom}
                                                                open={requiredCoin}
                                                                title={t("INVENTORY.coin")}
                                                            >
                                                                <TextField
                                                                    select
                                                                    id="client"
                                                                    label={t("INVENTORY.coin") + "*"}
                                                                    name="category"
                                                                    value={values.coin}
                                                                    onChange={(ev) => {
                                                                        handleChange("coin", ev.target.value)
                                                                    }}
                                                                    className={classes.textField}
                                                                    margin="normal"
                                                                    classes={{
                                                                        root: classes.inputRoot,
                                                                        input: classes.inputInput
                                                                    }}
                                                                    inputProps={{ "aria-label": "search" }}
                                                                >
                                                                    {coins.map(coin => (
                                                                        <MenuItem key={coin.value} value={coin.value}>
                                                                            {coin.label}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Tooltip>
                                                        </MuiThemeProvider>
                                                    </Grid>
                                                    {values.coin !== "BRL" &&
                                                        <Grid item xs={12} sm={6}>
                                                            <TextField
                                                                className={classes.textField}
                                                                label={t("INVENTORY.currencyQuote")}
                                                                value={values.coinValues}
                                                                onChange={(ev) => { if (ev.target.value.length <= 50) handleChange("coinValues", ev.target.value) }}
                                                                margin="normal"
                                                            />

                                                        </Grid>}
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    {values.coin !== "BRL" && <Typography style={{ opacity: 0.54 }}>{t("INVENTORY.suggestionCoin")}</Typography>}
                                                </Grid>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} sm={3}>
                                                        <MuiThemeProvider theme={tooltipTheme}>
                                                            <Tooltip
                                                                TransitionComponent={Zoom}
                                                                open={requiredPurchasePrice}
                                                                title={t(titleName)}
                                                            >
                                                                <TextField
                                                                    className={classes.textField}
                                                                    label={t("INVENTORY.purchasePrice") + "(" + values.coin + ")*"}
                                                                    value={values.purchasePrice}
                                                                    onChange={(ev) => {
                                                                        if (ev.target.value.length <= 13) handleChange("purchasePrice", ev.target.value)
                                                                        setRequiredPurchasePrice(false)
                                                                    }}
                                                                    margin="normal"
                                                                />
                                                            </Tooltip>
                                                        </MuiThemeProvider>
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        <MuiThemeProvider theme={tooltipTheme}>
                                                            <TextField
                                                                className={classes.textField}
                                                                label={t("INVENTORY.freight") + "(" + values.coin + ")*"}
                                                                value={values.freight}
                                                                onChange={(ev) => { if (ev.target.value.length <= 13) handleChange("freight", ev.target.value) }}
                                                                margin="normal"
                                                            />
                                                        </MuiThemeProvider>
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        <TextField
                                                            className={classes.textField}
                                                            label={t("INVENTORY.iof")}
                                                            value={values.iof}
                                                            onChange={(ev) => { if (ev.target.value.length <= 13) handleChange("iof", ev.target.value) }}
                                                            margin="normal"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        <TextField
                                                            className={classes.textField}
                                                            label={t("INVENTORY.totalPurchase") + "*"}
                                                            value={
                                                                totalPurchaseOfBooks()
                                                            }
                                                            margin="normal"
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <Typography style={{ opacity: 0.54 }}>{t("INVENTORY.hintCalcCoin")}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <FormControl component="fieldset" style={{ marginTop: "18px" }}>
                                                        <FormLabel component="legend">{t("INVENTORY.acquisitionMethod")}</FormLabel>
                                                        <RadioGroup row aria-label="position"
                                                            name="position"
                                                            defaultValue="top"
                                                            value={values.acquisitionMethod}
                                                            onChange={(ev) => handleChange("acquisitionMethod", ev.target.value)}
                                                        >
                                                            <FormControlLabel
                                                                value="Projects"
                                                                control={<Radio color="primary" />}
                                                                label="Projetos"
                                                                labelPlacement="end"
                                                            />
                                                            <FormControlLabel
                                                                value="OwnResources"
                                                                control={<Radio color="primary" />}
                                                                label="Recursos Próprios"
                                                                labelPlacement="end"
                                                            />
                                                            <FormControlLabel
                                                                value="Others"
                                                                control={<Radio color="primary" />}
                                                                label="Outros"
                                                                labelPlacement="end"
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                                {values.acquisitionMethod == "Projects" ?
                                                    <Grid item xs={12} sm={12}>
                                                        <MuiThemeProvider theme={tooltipTheme}>
                                                            <Tooltip
                                                                TransitionComponent={Zoom}
                                                                open={requiredProject}
                                                                title={t(titleName)}
                                                            >
                                                                <TextField
                                                                    select
                                                                    label={t("INVENTORY.originProject") + "*"}
                                                                    name="projects"
                                                                    value={values.projectId}
                                                                    onChange={(ev) => {
                                                                        handleChange("projectId", ev.target.value)
                                                                    }}
                                                                    className={classes.textField}
                                                                    margin="normal"
                                                                    classes={{
                                                                        root: classes.inputRoot,
                                                                        input: classes.inputInput
                                                                    }}
                                                                    inputProps={{ "aria-label": "search" }}
                                                                >
                                                                    {projectsArray.map(project => (
                                                                        <MenuItem key={project.value} value={project.value}>
                                                                            {project.label}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Tooltip>
                                                        </MuiThemeProvider>
                                                    </Grid>
                                                    :
                                                    <Grid item xs={12} sm={12}>
                                                        <MuiThemeProvider theme={tooltipTheme}>
                                                            <Tooltip
                                                                TransitionComponent={Zoom}
                                                                open={requiredDescription}
                                                                title={t(titleName)}
                                                            >
                                                                <TextField
                                                                    className={classes.textField}
                                                                    label={t("INVENTORY.description") + "*"}
                                                                    value={values.descriptionAcquisitionMethod}
                                                                    onChange={(ev) => { if (ev.target.value.length <= 100) handleChange("descriptionAcquisitionMethod", ev.target.value), setRequiredDescription(false) }}
                                                                    margin="normal"
                                                                />
                                                            </Tooltip>
                                                        </MuiThemeProvider>
                                                    </Grid>
                                                }
                                                <Grid item xs={12} sm={4}>
                                                    <TextField
                                                        className={classes.textField}
                                                        label={t("INVENTORY.invoice")}
                                                        value={values.invoice || ""}
                                                        onChange={(ev) => { if (ev.target.value.length <= 50) handleChange("invoice", ev.target.value), setRequiredEdition(false) }}
                                                        margin="normal"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <MuiThemeProvider theme={tooltipTheme}>
                                                        <Tooltip
                                                            TransitionComponent={Zoom}
                                                            open={requiredAdministrativeResponsible}
                                                            title={t(titleName)}
                                                        >
                                                            <TextField
                                                                select
                                                                label={t("INVENTORY.administrativeResponsible") + "*"}
                                                                value={values.administrativeResponsibleId}
                                                                onChange={(ev) => {
                                                                    handleChange("administrativeResponsibleId", ev.target.value)
                                                                }}
                                                                className={classes.textField}
                                                                margin="normal"
                                                                classes={{
                                                                    root: classes.inputRoot,
                                                                    input: classes.inputInput
                                                                }}
                                                                inputProps={{ "aria-label": "search" }}
                                                            >
                                                                {usersLibraryAdmArray.map(userAdm => (
                                                                    <MenuItem key={userAdm.value} value={userAdm.value}>
                                                                        {userAdm.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Tooltip>
                                                    </MuiThemeProvider>
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <TextField
                                                        disabled
                                                        select
                                                        id="users"
                                                        label={t("INVENTORY.directResponsible") + "*"}
                                                        name="users"
                                                        value={values.directResponsibleId}
                                                        className={classes.textField}
                                                        margin="normal"
                                                        classes={{
                                                            root: classes.inputRoot,
                                                            input: classes.inputInput
                                                        }}
                                                        inputProps={{ "aria-label": "search" }}
                                                    >
                                                        {usersArray.map(user => (
                                                            <MenuItem key={user.value} value={user.value}>
                                                                {user.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <MuiThemeProvider theme={tooltipTheme}>
                                                        <Tooltip
                                                            TransitionComponent={Zoom}
                                                            open={requiredLibrary}
                                                            title={t(titleName)}
                                                        >
                                                            <TextField
                                                                select
                                                                label={t("INVENTORY.library") + "*"}
                                                                value={values.library}
                                                                onChange={(ev) => {
                                                                    handleChange("library", ev.target.value)
                                                                }}
                                                                className={classes.textField}
                                                                margin="normal"
                                                                classes={{
                                                                    root: classes.inputRoot,
                                                                    input: classes.inputInput
                                                                }}
                                                                inputProps={{ "aria-label": "search" }}
                                                            >
                                                                {library.map(libraryType => (
                                                                    <MenuItem key={libraryType.value} value={libraryType.value}>
                                                                        {libraryType.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Tooltip>
                                                    </MuiThemeProvider>
                                                </Grid>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} sm={6}>
                                                        <MuiThemeProvider theme={tooltipTheme}>
                                                            <Tooltip
                                                                TransitionComponent={Zoom}
                                                                open={requiredPlace}
                                                                title={t("INVENTORY.coin")}
                                                            >
                                                                <TextField
                                                                    select
                                                                    label={t("INVENTORY.statusExemplar") + "*"}
                                                                    value={values.status}
                                                                    onChange={(ev) => {
                                                                        handleChange("status", ev.target.value)
                                                                    }}
                                                                    className={classes.textField}
                                                                    margin="normal"
                                                                    classes={{
                                                                        root: classes.inputRoot,
                                                                        input: classes.inputInput
                                                                    }}
                                                                    inputProps={{ "aria-label": "search" }}
                                                                >
                                                                    {status.map(status => (
                                                                        <MenuItem key={status.value} value={status.value}>
                                                                            {status.label}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Tooltip>
                                                        </MuiThemeProvider>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <MuiThemeProvider theme={tooltipTheme}>
                                                            <Tooltip
                                                                TransitionComponent={Zoom}
                                                                open={requiredCoin}
                                                                title={t("INVENTORY.coin")}
                                                            >
                                                                <TextField

                                                                    select
                                                                    id="client"
                                                                    label={t("INVENTORY.place") + "*"}
                                                                    name="projects"
                                                                    value={values.placeId}
                                                                    onChange={(ev) => {
                                                                        handleChange("placeId", ev.target.value)
                                                                    }}
                                                                    className={classes.textField}
                                                                    margin="normal"
                                                                    classes={{
                                                                        root: classes.inputRoot,
                                                                        input: classes.inputInput
                                                                    }}
                                                                    inputProps={{ "aria-label": "search" }}
                                                                >
                                                                    {placesArray.map(place => (
                                                                        <MenuItem key={place.value} value={place.value}>
                                                                            {place.label}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Tooltip>
                                                        </MuiThemeProvider>
                                                    </Grid>
                                                </Grid>

                                                <Grid container item spacing={1} md={12} lg={12}>
                                                    <Grid item xs={12}>
                                                        <Dropzone onDrop={onDropFiles}
                                                            accept="application/pdf, 
                                                application/msword, 
                                                application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                                image/jpeg, 
                                                image/png"
                                                        >
                                                            {({ getRootProps, getInputProps }) => (
                                                                <section>
                                                                    <div {...getRootProps({ className: classes.dropzoneDialog })}>
                                                                        <input {...getInputProps()} />
                                                                        <AttachFileIcon />
                                                                        <Typography>Arraste e solte os documentos para anexá-los ou <u>clique aqui</u></Typography>
                                                                    </div>
                                                                </section>
                                                            )}
                                                        </Dropzone>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant={"body1"} style={{ fontWeight: "bold" }}>Documentos</Typography>
                                                        {dataState.rejectedFiles.length > 0 &&
                                                            <div style={{ paddingBottom: 8 }}>
                                                                {dataState.rejectedFiles.map(message => (
                                                                    <div className={classes.dialogFile}>
                                                                        <div className={classes.textFile}>
                                                                            <Typography style={{ color: "red" }} className={classes.nameFile}>{message}</Typography>
                                                                        </div>
                                                                        <div className={classes.iconsFile}>
                                                                            <IconButton onClick={() => deleteRejectedFile(message)}>
                                                                                <Clear />
                                                                            </IconButton>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        }
                                                        {dataState.files.length > 0 ?
                                                            <div className={classes.dropzoneDialogFiles}>
                                                                {dataState.files.map(file => (
                                                                    <div className={classes.dialogFile}>
                                                                        <div className={classes.textFile}>
                                                                            <Typography className={classes.nameFile}>{file.fileName ? file.fileName : file.name}</Typography>
                                                                            <Typography>{`(${humanFileSize(file.size)})`}</Typography>
                                                                        </div>
                                                                        <div className={classes.iconsFile}>
                                                                            <IconButton onClick={() => onClickDownload(file)}>
                                                                                <GetAppIcon />
                                                                            </IconButton>
                                                                            <IconButton onClick={() => onRemoveFileDocs(file)}>
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div> :
                                                            <div className={classes.noFilesMessage}>
                                                                <Typography variant={"h6"} style={{ fontWeight: "bold" }}>{t("EXPENSES.noFilesMessage")}</Typography>
                                                                <Typography variant={"caption"}>{t("EXPENSES.noFilesSubMessage")}</Typography>
                                                            </div>
                                                        }
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12} sm={12}>
                                                    <TextField
                                                        multiline
                                                        fullWidth
                                                        rows="6"
                                                        variant="outlined"
                                                        className={classes.textField}
                                                        label={t("INVENTORY.observation")}
                                                        value={values.observation}
                                                        margin="normal"
                                                        onChange={(ev) => handleChange("observation", ev.target.value)}
                                                        inputProps={{
                                                            maxLength: 250
                                                        }}
                                                    />
                                                </Grid>
                                            </TabPanel>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Paper>
                            <Footer />
                        </React.Fragment>
                    </animated.div >
                )}
            </div>
        </React.Fragment>
    );
};

export default EditExemplar;
