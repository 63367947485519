import axios from 'axios';
import { useMutation } from 'react-query';
import Cookies from 'js-cookie';
import { useQueryLSBDDashboard } from './useQueryLSBDDashboard';
import { useSnackbar } from 'hooks/UseSnackbar';
import { useTranslation } from 'react-i18next';
import { useQueryLSBDTransactions } from './useQueryLSBDTransactions';
import { SourceTypes } from 'pages/quotasModule/dashboard/ScreenUtils';
import { valueToMoneyFloat } from 'utils/general/valueToMoneyFloat';
import { moneyMask } from 'utils/masks/moneyMask';
import { getQuotaManagersAndSendEmails } from 'services/Emails/Quota/quotaEmailService';
import { useQueryLSBDCashFlowTransactions } from './useQueryLSBDCashFlowTransactions';

export const useMutationCreateCredit = setOpen => {
    const toast = useSnackbar();
    const [t] = useTranslation();

    const queryDashboard = useQueryLSBDDashboard({ enabled: false });
    const queryTransactions = useQueryLSBDTransactions({ enabled: false });
    const queryCashFlow = useQueryLSBDCashFlowTransactions({ enabled: false });

    const mutation = useMutation({
        mutationFn: async obj =>
            await axios.post(
                process.env.REACT_APP_SERVER + `api/Quota/Transactions/Credit`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            ),
        onSuccess: res => {
            toast.success(t('QUOTAS.addCreditSuccess'));
            const data = res.data;

            if (data.source === SourceTypes.USER) {
                const emailData = {
                    collaboratorName: data.user.shortname,
                    collaboratorEmail: data.user.username,
                    value: `R$ ${moneyMask(valueToMoneyFloat(data.value))}`,
                    solicitationDate: data.createdAt,
                    operatorName: data.operator.shortname,
                    newBalance: `R$ ${moneyMask(
                        valueToMoneyFloat(data.newAccountBalance)
                    )}`
                };

                getQuotaManagersAndSendEmails(
                    emailData,
                    'api/Quota/Credit/Added',
                    'QUOTAS_ADDED_CREDIT_EMAIL_OPTIONAL'
                );
            }
        },
        onError: () => {
            toast.error(t('QUOTAS.addCreditError'));
        },
        onSettled: () => {
            setOpen(false);
            queryDashboard.refetch();
            queryTransactions.refetch();
            queryCashFlow.refetch();
        }
    });

    return mutation;
};
