import React, { useContext, useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { useStyles } from 'components/layout/CommonStyles';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import Buttons from 'components/buttons/Pressed';
import Download from '@material-ui/icons/GetApp';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import Input from 'components/inputs/Input';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { SortLabel } from 'utils/sort/SortLabel';
import { FormatDateToDataBase } from 'utils/dates/FormatDateToDataBase';
import { convertStrToBrCurrency } from 'utils/masks/convertStrToBrCurrency';
import { dateValidation } from 'utils/validation/DateValidation';
import { GetNumberOfDays } from 'utils/dates/GetNumberOfDays';

import axios from 'axios';

import InputFile from 'components/inputs/InputModal';
import VacationsContext from 'context/hrModule/vacations/vacationsContext';

import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import DateUtils from 'utils/dates/DateUtils';

const DialogCollaboratorVacationPeriod = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const fileDownload = require('js-file-download');

    const vacationsContext = useContext(VacationsContext);

    const { addCollaboratorVacation, updateCollaboratorVacation } =
        vacationsContext;

    const {
        open,
        setOpen,
        context,
        userId,
        fetchData,
        authId,
        vacationId,
        userName,
        periodValues,
        vacationsPeriods,
        item,
        type,
        ruleset
    } = props;

    const [values, setValues] = useState({
        id: '',
        acquisitionPeriod: '',
        daysToBePaid: '',
        class: 'newrhmodule.vacation.UtilizationWithoutSoldVacationDays',
        craftFilePath: '',
        craftFileName: 'Anexar Documento',
        observation: '',
        numberOfDays: '-',
        demissionDialog: false
    });

    const [dates, setDates] = useState({
        startDate: null,
        endDate: null
    });

    const createEnjoymentTypeArray = ruleset => {
        const rs = [
            {
                value: 'newrhmodule.vacation.UtilizationWithoutSoldVacationDays',
                label: t('HR.utilizationWithoutSoldVacationDays')
            }
        ];
        if (ruleset === 'CLT') {
            rs.push({
                value: 'newrhmodule.vacation.UtilizationWithSoldVacationDays',
                label: t('HR.utilizationWithSoldVacationDays')
            });
        }
        return rs;
    };

    const enjoymentTypeArray = createEnjoymentTypeArray(ruleset);

    const [documentFile, setDocumentFile] = useState([]);
    const [requiredAdmissionDate, setRequiredAdmissionDate] = useState(false);
    const [requiredHolidayDate, setRequiredHolidayDate] = useState(false);
    const [requiredCompanyId, setRequiredCompanyId] = useState(false);
    const [
        numberOfDaysBiggerThanRemaining,
        setNumberOfDaysBiggerThanRemaining
    ] = useState(false);
    const [numberOfDaysErrorMessage, setNumberOfDaysErrorMessage] =
        useState('');
    const [requiredStartDate, setRequiredStartDate] = useState(false);
    const [requiredEndDate, setRequiredEndDate] = useState(false);
    const [requiredEnjoymentType, setRequiredEnjoymentType] = useState(false);
    const [enjoymentTypeErrorMessage, setEnjoymentTypeErrorMessage] =
        useState(false);
    const [requiredSalary, setRequiredSalary] = useState(false);
    const [requiredCraft, setRequiredCraft] = useState(false);
    const [craftHelperMessage, setCraftHelperMessage] = useState(false);
    const [startDateErrorMessage, setStartDateErrorMessage] = useState('');
    const [endDateErrorMessage, setEndDateErrorMessage] = useState('');
    const [invalidDateAdmission, setInvalidDateAdmission] = useState(false);
    const [invalidDateHoliday, setInvalidDateHoliday] = useState(false);

    const setData = () => {
        setValues({
            ...values,
            id: item.id ? item.id : '',
            vacationId: item.vacationId ? item.vacationId : '',
            class: item.class ? item.class : '',
            craftFilePath: item.documentFilePath ? item.documentFilePath : '',
            craftFileName:
                item.documentFilePath != null
                    ? item.documentFilePath.split('/').pop()
                    : '',
            observation: item.observation ? item.observation : '',
            numberOfDays:
                dateValidation(DateUtils.parse(item.startDate)) &&
                    dateValidation(DateUtils.parse(item.endDate))
                    ? `${GetNumberOfDays(
                        DateUtils.parse(item.startDate),
                        DateUtils.parse(item.endDate)
                    )}`
                    : '-',
            numberOfDaysOriginal:
                dateValidation(DateUtils.parse(item.startDate)) &&
                    dateValidation(DateUtils.parse(item.endDate))
                    ? `${GetNumberOfDays(
                        DateUtils.parse(item.startDate),
                        DateUtils.parse(item.endDate)
                    )}`
                    : '-',
            demissionDialog:
                item.class ===
                    'newrhmodule.vacation.DemissionPeriodRemuneration' ||
                    item.class === 'newrhmodule.vacation.DemissionPeriodEnjoyment'
                    ? true
                    : false
        });
        setDates({
            startDate: item.startDate
                ? DateUtils.parse(item.startDate)
                : null,
            endDate: item.endDate ? DateUtils.parse(item.endDate) : null
        });
    };

    useEffect(() => {
        setValues({
            ...values,
            class:
                type === 'remuneration' && values.demissionDialog
                    ? 'newrhmodule.vacation.DemissionPeriodRemuneration'
                    : type === 'remuneration' && !values.demissionDialog
                        ? 'newrhmodule.vacation.PaidPeriod'
                        : values.demissionDialog
                            ? 'newrhmodule.vacation.DemissionPeriodEnjoyment'
                            : ''
        });
        if (item && Object.keys(item).length !== 0) {
            setData();
        }
    }, []);

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (name, value) => {
        let val = value;

        if (name === 'ruleset' && value !== 6908851 && value !== 6909001) {
            setRequiredHolidayDate(false);
            setInvalidDateHoliday(false);
        }
        if (name === 'salary') {
            val = convertStrToBrCurrency(value);
        }
        setValues({ ...values, [name]: val });
    };

    const handleDates = name => e => {
        setDates({
            ...dates,
            [name]: e
        });
        if (name === 'startDate') {
            setRequiredStartDate(false);
        } else {
            setRequiredEndDate(false);
        }
        setNumberOfDaysBiggerThanRemaining(false);
    };

    useEffect(() => {
        if (dateValidation(dates.startDate) && dateValidation(dates.endDate)) {
            setValues({
                ...values,
                numberOfDays:
                    dateValidation(dates.startDate) &&
                        dateValidation(dates.endDate)
                        ? GetNumberOfDays(dates.startDate, dates.endDate)
                        : '-'
            });
        }
    }, [dates]);

    const onClickDownload = async slashPath => {
        if (slashPath) {
            const noSlashPath = slashPath.replace(/\//g, ';');
            const url =
                process.env.REACT_APP_SERVER +
                'api/file/;portal_lsbd;hr_files' +
                noSlashPath;
            axios
                .get(url, {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    responseType: 'blob'
                })
                .then(function (res) {
                    const blob = new Blob([res.data], {});
                    const name = slashPath.split('/').pop();
                    fileDownload(blob, name);
                });
        }
    };

    const onDropCraft = acceptedFiles => {
        if (acceptedFiles[0] !== undefined) {
            setRequiredCraft(false);
            setCraftHelperMessage('');
            handleChange('craftFileName', acceptedFiles[0].name);
            setDocumentFile(
                acceptedFiles.map(file =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })
                )
            );
        }
    };

    const verifySubmit = () => {
        // my god what is this code

        let res = true;
        setRequiredEndDate(false);
        setEndDateErrorMessage('');
        setRequiredStartDate(false);
        setStartDateErrorMessage('');

        if (
            (context !== 'edit' && documentFile.length === 0) ||
            (context === 'edit' && !values.craftFilePath)
        ) {
            res = false;
            setCraftHelperMessage(t('GENERAL.requiredField'));
            setRequiredCraft(true);
        }
        if (!values.demissionDialog) {
            if (!dateValidation(dates.startDate)) {
                res = false;
                setRequiredStartDate(true);
                setStartDateErrorMessage(t('GENERAL.invalidDate'));
            }
            if (dates.startDate === null) {
                res = false;
                setRequiredStartDate(true);
                setStartDateErrorMessage(t('GENERAL.requiredField'));
            }
            if (!dateValidation(dates.endDate)) {
                res = false;
                setRequiredEndDate(true);
                setEndDateErrorMessage(t('GENERAL.invalidDate'));
            }
            if (dates.endDate === null) {
                res = false;
                setRequiredEndDate(true);
                setEndDateErrorMessage(t('GENERAL.requiredField'));
            }

            if (
                dates.endDate &&
                dates.startDate &&
                dates.startDate.getTime() > dates.endDate.getTime()
            ) {
                res = false;
                setRequiredStartDate(true);
                setStartDateErrorMessage(
                    t('GENERAL.initialDateBiggerthanEndDate')
                );
            }
            vacationsPeriods.forEach(element => {
                if (
                    dates.endDate &&
                    dates.startDate &&
                    element.givenPeriods.filter(x =>
                        x.endDate
                            ? values.class === x.class &&
                            DateUtils.parse(x.startDate).getTime() >=
                            dates.startDate.getTime() &&
                            DateUtils.parse(x.endDate).getTime() <=
                            dates.endDate.getTime() &&
                            x.id !== values.id
                            : false
                    ).length > 0
                ) {
                    res = false;
                    setRequiredEndDate(true);
                    setEndDateErrorMessage(
                        t('HR.vacationPeriodAlreadyRegistered')
                    );
                    setRequiredStartDate(true);
                    setStartDateErrorMessage(
                        t('HR.vacationPeriodAlreadyRegistered')
                    );
                }

                if (
                    dates.endDate &&
                    element.givenPeriods.filter(x =>
                        x.endDate
                            ? DateUtils.parse(x.startDate).getTime() <=
                            dates.endDate.getTime() &&
                            dates.endDate.getTime() <=
                            DateUtils.parse(x.endDate).getTime() &&
                            x.id !== values.id
                            : false
                    ).length > 0
                ) {
                    res = false;
                    setRequiredEndDate(true);
                    setEndDateErrorMessage(
                        t('HR.vacationPeriodAlreadyRegistered')
                    );
                }

                if (
                    dates.startDate &&
                    element.givenPeriods.filter(x =>
                        x.startDate
                            ? DateUtils.parse(x.startDate).getTime() <=
                            dates.startDate.getTime() &&
                            dates.startDate.getTime() <=
                            DateUtils.parse(x.endDate).getTime() &&
                            x.id !== values.id
                            : false
                    ).length > 0
                ) {
                    res = false;
                    setRequiredStartDate(true);
                    setStartDateErrorMessage(
                        t('HR.vacationPeriodAlreadyRegistered')
                    );
                }
            });

            if (
                values.class ===
                'newrhmodule.vacation.UtilizationWithSoldVacationDays'
            ) {
                if (
                    values.numberOfDays !== 20
                ) {
                    res = false;
                    setNumberOfDaysBiggerThanRemaining(true);
                    setNumberOfDaysErrorMessage(
                        t('HR.mustBe20DaysForSoldVacation')
                    );
                }
            }

            if (context === 'new') {
                if (
                    values.numberOfDays >
                    (type === 'remuneration'
                        ? periodValues.daysToBePaid
                        : periodValues.daysToEnjoy)
                ) {
                    res = false;
                    setNumberOfDaysBiggerThanRemaining(true);
                    setNumberOfDaysErrorMessage(
                        t('HR.numberOfDaysBiggerthanRemainingDays')
                    );
                }
            } else {
                if (
                    values.numberOfDays - values.numberOfDaysOriginal >
                    (type === 'remuneration'
                        ? periodValues.daysToBePaid
                        : periodValues.daysToEnjoy)
                ) {
                    res = false;
                    setNumberOfDaysBiggerThanRemaining(true);
                    setNumberOfDaysErrorMessage(
                        t('HR.numberOfDaysBiggerthanRemainingDays')
                    );
                }
            }
            if (type !== 'remuneration' && !values.class) {
                res = false;
                setRequiredEnjoymentType(true);
                setEnjoymentTypeErrorMessage(t('GENERAL.requiredField'));
            }
            if (
                values.class === 'newrhmodule.vacation.VacationPurchase' &&
                values.numberOfDays > 10
            ) {
                res = false;
                setNumberOfDaysBiggerThanRemaining(true);
                setNumberOfDaysErrorMessage(
                    t('HR.daysPurchasedCannotBeLongerThanTen')
                );
            }
            if (values.numberOfDays < 1) {
                res = false;
                setNumberOfDaysBiggerThanRemaining(true);
                setNumberOfDaysErrorMessage(
                    t('HR.periodOfVacationCantBeLessThanOne')
                );
            }
        }
        return res;
    };

    const handleSubmit = () => {
        const canSubmit = verifySubmit();
        if (canSubmit) {
            let formData = new FormData();
            const now = new Date();

            formData.append('vacationPeriod.id', values.id ? values.id : 0);

            formData.append(
                'vacationPeriod.startDate',
                dates.startDate
                    ? FormatDateToDataBase(new Date(dates.startDate))
                    : FormatDateToDataBase(now)
            );
            formData.append(
                'vacationPeriod.endDate',
                dates.endDate
                    ? FormatDateToDataBase(new Date(dates.endDate))
                    : type === 'remuneration'
                        ? FormatDateToDataBase(
                            new Date(
                                now.setDate(
                                    now.getDate() + periodValues.daysToBePaid - 1
                                )
                            )
                        )
                        : FormatDateToDataBase(
                            new Date(
                                now.setDate(
                                    now.getDate() + periodValues.daysToEnjoy - 1
                                )
                            )
                        )
            );
            formData.append(
                'vacationPeriod.class',
                values.demissionDialog
                    ? type === 'enjoyment'
                        ? 'newrhmodule.vacation.DemissionPeriodEnjoyment'
                        : 'newrhmodule.vacation.DemissionPeriodRemuneration'
                    : values.class
                        ? values.class
                        : ''
            );
            formData.append(
                'vacationPeriod.date',
                FormatDateToDataBase(new Date())
            );
            formData.append(
                'vacationPeriod.observation',
                values.observation ? values.observation : ''
            );

            if (context === 'new') {
                formData.append(
                    'vacationPeriod.vacationId',
                    vacationId ? vacationId : 0
                );
            } else {
                formData.append(
                    'vacationPeriod.vacationId',
                    values.vacationId ? values.vacationId : 0
                );
            }
            formData.append(
                'vacationPeriod.registredBy',
                userName ? userName : ''
            );
            formData.append(
                'documentFile',
                documentFile ? documentFile[0] : null
            );
            formData.append('authId', authId);
            if (context === 'new') {
                addCollaboratorVacation(formData, fetchData);
            } else {
                updateCollaboratorVacation(formData, fetchData);
            }
            handleClose();
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle className={classes.Title}>
                <b>
                    {`${context === 'view'
                        ? t('GENERAL.view')
                        : context === 'edit'
                            ? t('GENERAL.edit')
                            : t('GENERAL.add')
                        } ${type === 'remuneration'
                            ? t('HR.remuneration')
                            : t('HR.enjoymentOfVacation')
                        }`}
                </b>
                <IconButton
                    style={{ float: 'right', marginTop: -10, marginRight: -17 }}
                    edge="end"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container item spacing={2} md={12} lg={12}>
                    <Grid item xs={12} sm={12}>
                        <Input
                            disabled
                            context={context}
                            label={'HR.acquisitionPeriod'}
                            value={periodValues.acquisitionPeriod}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Input
                            disabled
                            context={context}
                            label={'HR.daysRemaining'}
                            value={` ${type === 'remuneration'
                                ? periodValues.daysToBePaid
                                : periodValues.daysToEnjoy
                                } ${t('GENERAL.days')}`}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControlLabel
                            disabled={context === 'view'}
                            control={
                                <Checkbox
                                    checked={values.demissionDialog}
                                    onChange={ev =>
                                        handleChange(
                                            'demissionDialog',
                                            ev.target.checked
                                        )
                                    }
                                    color="primary"
                                />
                            }
                            label={
                                type === 'remuneration'
                                    ? t(
                                        'HR.demissionVacationRemunerationMessage'
                                    )
                                    : t('HR.demissionVacationEnjoymentMessage')
                            }
                        />
                    </Grid>

                    {!values.demissionDialog && (
                        <React.Fragment>
                            {type !== 'remuneration' && (
                                <Grid item xs={12} sm={12}>
                                    <Input
                                        required
                                        // disabled={context !== 'new'}
                                        context={context}
                                        label={'HR.typeOfEnjoyment'}
                                        isSelect
                                        arraySelected={enjoymentTypeArray.sort(
                                            SortLabel
                                        )}
                                        value={values.class}
                                        valueName={'class'}
                                        handleChange={handleChange}
                                        error={requiredEnjoymentType}
                                        helperText={
                                            requiredEnjoymentType &&
                                            t('GENERAL.requiredField')
                                        }
                                        setRequired={setRequiredEnjoymentType}
                                    />
                                </Grid>
                            )}

                            <Grid
                                item
                                xs={12}
                                sm={12}
                                style={{
                                    marginBottom: '0px',
                                    paddingBottom: '0px'
                                }}
                            >
                                <Typography>
                                    {values.class ===
                                        'newrhmodule.vacation.VacationPurchase'
                                        ? t('HR.buyPeriod')
                                        : t('HR.vacationPeriod')}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                style={{ marginTop: '0px', paddingTop: '0px' }}
                            >
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disabled={context === 'view'}
                                        error={requiredStartDate}
                                        helperText={
                                            requiredStartDate &&
                                            startDateErrorMessage
                                        }
                                        className={
                                            classes.identificationDatePicker
                                        }
                                        disableToolbar
                                        autoOk={true}
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        id="validity"
                                        margin="normal"
                                        invalidDateMessage=""
                                        label={t('GENERAL.initialDate') + '*'}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date'
                                        }}
                                        value={dates.startDate}
                                        onChange={handleDates('startDate')}
                                        inputProps={{ autocomplete: 'off' }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                style={{ marginTop: '0px', paddingTop: '0px' }}
                            >
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disabled={context === 'view'}
                                        error={requiredEndDate}
                                        helperText={
                                            requiredEndDate &&
                                            endDateErrorMessage
                                        }
                                        className={
                                            classes.identificationDatePicker
                                        }
                                        disableToolbar
                                        autoOk={true}
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        id="validity"
                                        margin="normal"
                                        invalidDateMessage=""
                                        label={t('GENERAL.finalDate') + '*'}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date'
                                        }}
                                        value={dates.endDate}
                                        onChange={handleDates('endDate')}
                                        inputProps={{ autocomplete: 'off' }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Input
                                    disabled
                                    context={context}
                                    label={
                                        values.class ===
                                            'newrhmodule.vacation.VacationPurchase'
                                            ? 'HR.daysPurchased'
                                            : 'HR.vacationDays'
                                    }
                                    value={
                                        values.numberOfDays +
                                        (values.numberOfDays !== '-'
                                            ? ' ' +
                                            (values.numberOfDays > 1
                                                ? t('GENERAL.days')
                                                : t('GENERAL.day'))
                                            : '')
                                    }
                                    error={numberOfDaysBiggerThanRemaining}
                                    helperText={
                                        numberOfDaysBiggerThanRemaining &&
                                        numberOfDaysErrorMessage
                                    }
                                    setRequired={
                                        setNumberOfDaysBiggerThanRemaining
                                    }
                                />
                            </Grid>
                        </React.Fragment>
                    )}

                    {context === 'view' ? (
                        <React.Fragment>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    id="client3"
                                    variant="outlined"
                                    label={
                                        values.class ===
                                            'newrhmodule.vacation.UtilizationWithoutSoldVacationDays'
                                            ? t('HR.vacationDeclaration')
                                            : t('HR.craft')
                                    }
                                    name="responsible"
                                    autoComplete="off"
                                    className={classes.textField}
                                    value={values.craftFileName}
                                    margin="normal"
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput
                                    }}
                                    inputProps={{ 'aria-label': 'search' }}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    disabled={
                                                        !values.craftFilePath
                                                    }
                                                    onClick={() =>
                                                        onClickDownload(
                                                            values.craftFilePath
                                                        )
                                                    }
                                                >
                                                    <Download />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Grid item xs={12} sm={12}>
                                <InputFile
                                    required={
                                        values.ruleset === 6909151
                                            ? false
                                            : true
                                    }
                                    onDrop={onDropCraft}
                                    files={documentFile}
                                    label={
                                        values.class ===
                                            'newrhmodule.vacation.UtilizationWithoutSoldVacationDays' &&
                                            !values.demissionDialog
                                            ? 'HR.vacationDeclaration'
                                            : 'HR.craft'
                                    }
                                    value={values.craftFileName}
                                    error={requiredCraft}
                                    errorMessage={craftHelperMessage}
                                />
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Input
                        context={context}
                        label={'GENERAL.observation'}
                        value={values.observation}
                        valueName={'observation'}
                        rows={5}
                        maxLength={250}
                        handleChange={handleChange}
                    />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Buttons
                    onClick={handleClose}
                    tipo="BN-noback"
                    conteudo={
                        context === 'view'
                            ? t('GENERAL.close').toUpperCase()
                            : t('GENERAL.cancel').toUpperCase()
                    }
                />
                {context === 'view' ? null : (
                    <Buttons
                        onClick={handleSubmit}
                        tipo="BN-blue"
                        conteudo={
                            context === 'edit'
                                ? t('GENERAL.save').toUpperCase()
                                : t('GENERAL.add').toUpperCase()
                        }
                    />
                )}
            </DialogActions>
        </Dialog>
    );
};

export default DialogCollaboratorVacationPeriod;
