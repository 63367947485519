/* eslint-disable */
import React, { useContext, useEffect, useState, useRef } from 'react';
import Paper from '@material-ui/core/Paper';
import Dissatisfied from '@material-ui/icons/SentimentVeryDissatisfied';
import ErrorIcon from '@material-ui/icons/Error';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { ReactComponent as EntryIcon } from 'assets/icons/Entry.svg';
import { ReactComponent as BackFromBreakIcon } from 'assets/icons/BackFromBreak.svg';
import { ReactComponent as LeaveForBreakIcon } from 'assets/icons/LeaveForBreak.svg';
import { ReactComponent as LeaveIcon } from 'assets/icons/Leave.svg';
import Footer from 'components/layout/Footer';
import ClockRecordContext from 'context/hrModule/clockRecord/clockRecordContext';
import Transition from 'components/layout/transition/Transition';
import Breadcrumb from 'components/layout/breadcrumb/Breadcrumb';
import MyClockRecordTable from './MyClockRecordTable';
import InputFilter from 'components/inputs/InputFilter';
import FilterContainer from 'components/containers/FilterContainer';
import HeaderContainer from 'components/containers/HeaderContainer';
import ContentContainer from 'components/containers/ContentContainer';
import Typography from 'components/typography/Typography';
import Buttons from 'components/buttons/Pressed';
import DefaultTooltip from 'components/infoTooltip/DefaultTooltip';
import DialogMyClockRecord from './DialogMyClockRecord';

import { ConvertMilliInHourMin } from 'utils/dates/ConvertMilliInHourMin1';
import { GetWorkedHoursRealTime } from 'utils/dates/GetWorkedHoursRealTime';

import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import { Months } from 'global/constants';
import { screenStyles } from './Styles';
import { useQueryReportData } from 'hooks/DataFetching/HR/ClockRecord/useQueryReportData';
import ClockRecordReportTemplate from '../collaboratorsClockRecord/CollaboratorClockRecord/ClockRecordReport/Template';
import { getCollaboratorClockRecordYears } from 'utils/hrModule/ClockRecord/getCollaboratorClockRecordYears';
import DateUtils from 'utils/dates/DateUtils';
import { refetchAndGeneratePdf } from 'components/Pdf/generatePdf';
import ExportButton from 'components/buttons/ExportButton/ExportButton';

const MyClockRecord = props => {
    const classes = useStyles();
    const styles = screenStyles();
    const [t] = useTranslation();
    const clockRecordContext = useContext(ClockRecordContext);

    const [openDialog, setOpenDialog] = useState(false);
    const [clockType, setClockType] = useState('');

    const [realTimeState, setRealTimeState] = useState(new Date());
    const intervalId = useRef(null);
    const [workedHoursRealTime, setWorkedHoursRealTime] = useState('00:00');
    const workedHoursIntervalId = useRef(null);

    const { id } = props;
    const currentDate = new Date();

    const [findText, setFindText] = useState('');
    const [filterYear, setFilterYear] = useState(currentDate.getFullYear());
    const [filterMonth, setFilterMonth] = useState(-1);
    const [filterActivity, setFilterActivity] = useState('ALL');
    const [filterStatus, setFilterStatus] = useState('ALL');
    const [dataFilter, setDataFilter] = useState([]);
    const [canClick, setCanClick] = useState(false);

    const [yearsArray, setYearsArray] = useState([
        { value: currentDate.getFullYear(), label: currentDate.getFullYear() }
    ]);

    const monthsArray = [
        { value: -1, label: t('GENERAL.allMasculine') },
        ...Months
    ];

    const statusArray = [
        {
            value: 'ALL',
            label: t('GENERAL.allMasculine')
        },
        {
            value: 'IN_PROGRESS',
            label: t('GENERAL.inProgress')
        },
        {
            value: 'INCONSISTENCY',
            label: t('GENERAL.inconsistency')
        },
        {
            value: 'JUSTIFIED',
            label: t('HR.justified')
        },
        {
            value: 'REGULAR',
            label: t('GENERAL.regular')
        },
        {
            value: 'HOLIDAY',
            label: t('GENERAL.holiday')
        },
        {
            value: 'RECESS',
            label: t('GENERAL.recess')
        },
        {
            value: 'WEEKEND',
            label: t('GENERAL.weekend')
        }
    ];

    const activityArray = [
        {
            value: 'ALL',
            label: t('GENERAL.allMasculine')
        },
        {
            value: true,
            label: t('GENERAL.yesCapital')
        },
        {
            value: false,
            label: t('GENERAL.noCapital')
        }
    ];

    const [values, setValues] = useState({
        name: '',
        job: '',
        ruleset: '',
        totalWorkingHours: '',
        registersActivity: false,
        currentClockRecord: null
    });

    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const {
        loading,
        setLoading,
        setLoadingFalse,
        getServerTime,
        serverTime,
        getMyClockRecord,
        myClockRecord,
        clockIn
    } = clockRecordContext;

    async function fetchData() {
        await setLoading();
        await getServerTime();
        await getMyClockRecord(auth.id);
        await setLoadingFalse();
    }

    useEffect(() => {
        fetchData();
        return () => {
            clearTimeout(intervalId.current);
            clearTimeout(workedHoursIntervalId.current);
        };
    }, []);

    useEffect(() => {
        if (myClockRecord && myClockRecord.clockRecord && myClockRecord.clockRecord.length > 0) {
            setYearsArray(
                getCollaboratorClockRecordYears(myClockRecord.clockRecord)
            );
        }
    }, [myClockRecord]);

    useEffect(() => {
        if (serverTime !== null && myClockRecord !== null) {
            if (intervalId.current === null) {
                intervalId.current = setInterval(() => {
                    setRealTimeState(new Date());
                }, 1000);
            }

            setValues({
                name: myClockRecord.name ? myClockRecord.name : '-',
                imageName: myClockRecord.imageName
                    ? myClockRecord.imageName
                    : '-',
                job: myClockRecord.job ? myClockRecord.job : '-',
                ruleset: myClockRecord.ruleset ? myClockRecord.ruleset : '-',
                totalWorkingHours: myClockRecord.totalWorkingHours
                    ? myClockRecord.totalWorkingHours
                    : '-',
                clocksIn: myClockRecord.clocksIn
                    ? myClockRecord.clocksIn
                    : false,
                registersActivity: myClockRecord.registersActivity
                    ? myClockRecord.registersActivity
                    : false,
                clockRecordType: myClockRecord.clockRecordType
                    ? myClockRecord.clockRecordType
                    : 'REMOTE',
                currentClockRecord: myClockRecord.clockRecord
                    ? myClockRecord.clockRecord[0]
                    : null
            });
        }
    }, [serverTime, myClockRecord]);

    const setWorkedHoursRealTimeInterval = () => {
        workedHoursIntervalId.current = setInterval(() => {
            setWorkedHoursRealTime(
                GetWorkedHoursRealTime(
                    values.currentClockRecord.entryTime
                        ? DateUtils.parse(
                            values.currentClockRecord.entryTime
                        ).getTime()
                        : null,
                    values.currentClockRecord.leaveForBreak
                        ? DateUtils.parse(
                            values.currentClockRecord.leaveForBreak
                        ).getTime()
                        : null,
                    values.currentClockRecord.returnFromBreak
                        ? DateUtils.parse(
                            values.currentClockRecord.returnFromBreak
                        ).getTime()
                        : null,
                    new Date().getTime()
                )
            );
        }, 1000);
    };

    useEffect(() => {
        if (
            workedHoursIntervalId.current === null &&
            values.currentClockRecord
        ) {
            setWorkedHoursRealTimeInterval();
        }
    }, [values.currentClockRecord]);

    useEffect(() => {
        if (filterMonth || filterActivity || filterStatus) {
            setCanClick(true);
        } else {
            setCanClick(false);
        }
    }, [filterMonth, filterActivity, filterStatus]);

    const handleOpenDialog = clockType => {
        setClockType(clockType);
        setOpenDialog(true);
    };

    const handleSubmit = () => {
        let formData = new FormData();

        formData.append(
            'id',
            values.currentClockRecord ? values.currentClockRecord.id : 0
        );
        formData.append('clockType', clockType ? clockType : '-');

        clockIn(
            formData,
            async () => {
                await getMyClockRecord(auth.id);
            },
            clockType
        );
        clearInterval(workedHoursIntervalId.current);
        workedHoursIntervalId.current = null;
    };

    useEffect(() => {
        if (
            myClockRecord &&
            myClockRecord.clockRecord &&
            myClockRecord.clockRecord.length
        ) {
            const { newDataFilter, textNoFound } = applyFilter(
                myClockRecord.clockRecord
            );

            if (newDataFilter.length === 0) {
                setDataFilter([]);
                setHasNoResult(true);
                setFindText(textNoFound);
            } else {
                setDataFilter(newDataFilter);
                setHasNoResult(false);
                setFindText('');
            }
        }
    }, [myClockRecord, filterYear, filterMonth, filterActivity, filterStatus]);

    const applyFilter = data => {
        let newDataFilter = [];
        let textNoFound = '';

        newDataFilter = data;

        if (filterYear != undefined && filterYear !== -1) {
            newDataFilter = newDataFilter.filter(
                item =>
                    new Date(item.date).getFullYear() === filterYear
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterYear;
        }

        if (filterMonth != undefined && filterMonth !== -1) {
            newDataFilter = newDataFilter.filter(
                item => new Date(item.date).getMonth() === filterMonth
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = Months[filterMonth].label;
        }
        if (filterActivity != undefined && filterActivity !== 'ALL') {
            newDataFilter = newDataFilter.filter(
                item => item.activityRecord === filterActivity
            );
            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = activityArray.find(
                    x => x.value === filterActivity
                ).label;
        }

        if (filterStatus != undefined && filterStatus !== 'ALL') {
            newDataFilter = newDataFilter.filter(
                item => item.status && item.status === filterStatus
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = statusArray.find(
                    x => x.value === filterStatus
                ).label;
        }

        return { newDataFilter, textNoFound };
    };

    const hasItens =
        myClockRecord &&
        myClockRecord.clockRecord &&
        myClockRecord.clockRecord.length > 0;
    const [hasNoResult, setHasNoResult] = useState(
        hasItens && dataFilter.length === 0
    );

    //CONDITIONAL TESTS
    const isPhysicalClockIn = () => {
        return values.clockRecordType === 'PHYSICAL';
    };

    const isCurrentClockRecordInProgressOrWeekend = () => {
        return (
            values.currentClockRecord &&
            (values.currentClockRecord.status === 'IN_PROGRESS' ||
                values.currentClockRecord.status === 'WEEKEND')
        );
    };

    const hasAtLeastOneClockRecord = () => {
        return values.currentClockRecord != null;
    };

    const theCollaboratorClocksIn = () => {
        return values.clocksIn;
    };

    const collaboratorAlreadyCompletedTodayClockRecord = () => {
        return (
            values.currentClockRecord &&
            values.currentClockRecord.leaveTime !== null
        );
    };

    const todayClockRecordEntryTimeIsNull = () => {
        return (
            values.currentClockRecord &&
            values.currentClockRecord.entryTime === null
        );
    };

    const todayClockRecordLeaveForBreakIsNull = () => {
        return (
            values.currentClockRecord &&
            values.currentClockRecord.leaveForBreak === null
        );
    };

    const todayClockRecordReturnFromBreakIsNull = () => {
        return (
            values.currentClockRecord &&
            values.currentClockRecord.returnFromBreak === null
        );
    };

    // useQueryReportData parameters
    const [rangeStart, setRangeStart] = useState(
        DateUtils.format(new Date(filterYear, 0, 1), 'YYYY-MM-DD')
    );
    const [rangeEnd, setRangeEnd] = useState(
        DateUtils.format(new Date(filterYear, 12, 0), 'YYYY-MM-DD')
    );

    // Update the rangeStart and rangeEnd when the filterYear or filterMonth changes, to use in useQueryReportData
    useEffect(() => {
        const startMonth = filterMonth === -1 ? 0 : filterMonth;
        const endMonth = filterMonth === -1 ? 12 : filterMonth + 1;

        setRangeStart(
            DateUtils.format(new Date(filterYear, startMonth, 1), 'YYYY-MM-DD')
        );
        setRangeEnd(DateUtils.format(new Date(filterYear, endMonth, 0), 'YYYY-MM-DD'));
    }, [filterYear, filterMonth]);

    const queryReportData = useQueryReportData(
        { enabled: false },
        auth.id,
        rangeStart,
        rangeEnd,
        filterActivity,
        filterStatus
    );

    const [reportLoading, setReportLoading] = useState(false);
    async function handleGenerateReport() {
        return refetchAndGeneratePdf(
            response => {
                return <ClockRecordReportTemplate data={response.data} />;
            },
            queryReportData,
            setReportLoading,
            blob => {
                const url = window.URL.createObjectURL(blob);
                window.open(url);
            },
            error => {
                console.error(error);
                toast.error(t("HR.reportGenerationError"));
            }
        )
    }

    function returnClockInTimeString(datetime) {
        if (!theCollaboratorClocksIn())
            return '-';
        return datetime ? DateUtils.formatString(datetime, 'hh:mm') : t('HR.notRegistred')
    }

    return (
        <React.Fragment>
            <Transition loading={loading} newDesign={true}>
                <HeaderContainer>
                    <Breadcrumb
                        newDesign={true}
                        routes={[
                            {
                                label: t('HR.humanResource'),
                                to: '/HumanResources'
                            },
                            { label: t('HR.clock') },
                            { label: t('HR.myClockRecord') }
                        ]}
                    />
                    <div className="container-headerNewDesign">
                        <h1 className={classes.titleNewDesign}>
                            {t('HR.myClockRecord')}
                        </h1>
                    </div>
                </HeaderContainer>
                <ContentContainer>
                    <Paper className={styles.space}>
                        <div className="cabecalho">
                            {t('HR.collaboratorInformation').toUpperCase()}
                        </div>
                        <div className={styles.collaboratorInfoWrapper}>
                            <div className={styles.collaboratorInfoCard}>
                                <div
                                    className={
                                        styles.collaboratorInfoCardImgWrapper
                                    }
                                >
                                    <img
                                        src={
                                            process.env.REACT_APP_IMGSERVER +
                                            values.imageName
                                        }
                                        className={
                                            styles.collaboratorInfoCardImg
                                        }
                                    />
                                </div>
                                <div
                                    className={
                                        styles.collaboratorInfoCardPersonalInfo
                                    }
                                >
                                    <div
                                        className={
                                            styles.collaboratorInfoCardPersonalInfoFirstContent
                                        }
                                    >
                                        <Typography
                                            className={styles.textMargin}
                                            variant={'RobotoBold'}
                                            fontSize={10}
                                            textColor={'white'}
                                        >
                                            {t('HR.collaborator').toUpperCase()}
                                        </Typography>
                                        <Typography
                                            className={styles.textMargin}
                                            variant={'RobotoRegular'}
                                            fontSize={14}
                                            textColor={'white'}
                                        >
                                            {values.name}
                                        </Typography>
                                        <Typography
                                            className={styles.textMargin}
                                            variant={'RobotoLight'}
                                            fontSize={12}
                                            textColor={'white'}
                                        >
                                            {values.job}
                                        </Typography>
                                    </div>
                                    <div
                                        className={
                                            styles.collaboratorInfoCardPersonalInfoSecondContent
                                        }
                                    >
                                        <Typography
                                            className={styles.textMargin}
                                            variant={'RobotoBold'}
                                            fontSize={10}
                                            textColor={'white'}
                                        >
                                            {t(
                                                'HR.workingRelation'
                                            ).toUpperCase()}
                                        </Typography>
                                        <Typography
                                            className={styles.textMargin}
                                            variant={'RobotoRegular'}
                                            fontSize={14}
                                            textColor={'white'}
                                        >
                                            {theCollaboratorClocksIn()
                                                ? `${values.ruleset
                                                } • ${ConvertMilliInHourMin(
                                                    values.totalWorkingHours
                                                )} Diárias`
                                                : `${values.ruleset} • ${t(
                                                    'HR.openRuleset'
                                                )}`}
                                        </Typography>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.collaboratorInfoDailyClock}>
                                <Typography
                                    className={styles.textMargin}
                                    variant={'RobotoBold'}
                                    fontSize={16}
                                    textColor={'primary'}
                                >
                                    {t('HR.todayClockRecord').toUpperCase()}
                                </Typography>

                                <div
                                    className={
                                        styles.collaboratorInfoDailyClockTimes
                                    }
                                >
                                    <div
                                        className={
                                            styles.collaboratorInfoDailyClockTextWrapper
                                        }
                                    >
                                        <EntryIcon />
                                        <Typography
                                            className={styles.textMargin}
                                            variant={'RobotoSemiBold'}
                                            fontSize={13}
                                            textColor={'secondary'}
                                        >
                                            {t('HR.entry').toUpperCase()}:
                                        </Typography>
                                        <Typography
                                            className={styles.textMargin}
                                            variant={'RobotoRegular'}
                                            fontSize={13}
                                            textColor={'secondary'}
                                        >
                                            {returnClockInTimeString(values.currentClockRecord?.entryTime)}
                                        </Typography>
                                    </div>

                                    <div
                                        className={
                                            styles.collaboratorInfoDailyClockTextWrapper
                                        }
                                    >
                                        <LeaveForBreakIcon />
                                        <Typography
                                            className={styles.textMargin}
                                            variant={'RobotoSemiBold'}
                                            fontSize={13}
                                            textColor={'secondary'}
                                        >
                                            {t(
                                                'HR.leaveForBreak'
                                            ).toUpperCase()}
                                            :
                                        </Typography>
                                        <Typography
                                            className={styles.textMargin}
                                            variant={'RobotoRegular'}
                                            fontSize={13}
                                            textColor={'secondary'}
                                        >
                                            {returnClockInTimeString(values.currentClockRecord?.leaveForBreak)}
                                        </Typography>
                                    </div>

                                    <div
                                        className={
                                            styles.collaboratorInfoDailyClockTextWrapper
                                        }
                                    >
                                        <BackFromBreakIcon />
                                        <Typography
                                            className={styles.textMargin}
                                            variant={'RobotoSemiBold'}
                                            fontSize={13}
                                            textColor={'secondary'}
                                        >
                                            {t(
                                                'HR.returnFromBreak'
                                            ).toUpperCase()}
                                            :
                                        </Typography>
                                        <Typography
                                            className={styles.textMargin}
                                            variant={'RobotoRegular'}
                                            fontSize={13}
                                            textColor={'secondary'}
                                        >
                                            {returnClockInTimeString(values.currentClockRecord?.returnFromBreak)}
                                        </Typography>
                                    </div>

                                    <div
                                        className={
                                            styles.collaboratorInfoDailyClockTextWrapper
                                        }
                                    >
                                        <LeaveIcon />
                                        <Typography
                                            className={styles.textMargin}
                                            variant={'RobotoSemiBold'}
                                            fontSize={13}
                                            textColor={'secondary'}
                                        >
                                            {t('HR.leave').toUpperCase()}:
                                        </Typography>
                                        <Typography
                                            className={styles.textMargin}
                                            variant={'RobotoRegular'}
                                            fontSize={13}
                                            textColor={'secondary'}
                                        >
                                            {returnClockInTimeString(values.currentClockRecord?.leaveTime)}
                                        </Typography>
                                    </div>
                                </div>

                                <div
                                    className={
                                        styles.collaboratorInfoDailyClockWorkedHours
                                    }
                                >
                                    <AccessTimeIcon
                                        fontSize="small"
                                        style={{ color: '#2C3E51' }}
                                        className={styles.icons}
                                    />
                                    <Typography
                                        className={styles.textMargin}
                                        variant={'RobotoSemiBold'}
                                        fontSize={16}
                                        textColor={'primary'}
                                    >
                                        {t('HR.workedHours').toUpperCase()}:
                                    </Typography>
                                    <Typography
                                        className={`${styles.textMargin} ${styles.textLineHeight}`}
                                        variant={'RobotoRegular'}
                                        fontSize={13}
                                        textColor={'secondary'}
                                    >
                                        {theCollaboratorClocksIn() &&
                                            values.currentClockRecord
                                            ? values.currentClockRecord
                                                .leaveTime === null
                                                ? workedHoursRealTime
                                                : values.currentClockRecord
                                                    .totalHours
                                            : '-'}
                                    </Typography>
                                </div>
                            </div>

                            <div className={styles.collaboratorInfoClockIn}>
                                <div
                                    className={
                                        styles.collaboratorInfoClockInSystemHour
                                    }
                                >
                                    <Typography
                                        className={`${styles.textMargin} ${styles.textLineHeight}`}
                                        variant={'RobotoBold'}
                                        fontSize={16}
                                        textColor={'primary'}
                                    >
                                        {t('HR.systemHour').toUpperCase()}
                                    </Typography>
                                    <DefaultTooltip
                                        className={styles.iconsRight}
                                        color={'#6D6F71'}
                                        text={t('HR.systemHourMessage')}
                                    />
                                </div>

                                <div
                                    className={
                                        styles.collaboratorInfoClockInRealTimeWeekday
                                    }
                                >
                                    <Typography
                                        className={styles.textMargin}
                                        variant={'RobotoRegular'}
                                        fontSize={14}
                                        textColor={'secondary'}
                                    >
                                        {DateUtils.format(realTimeState, 'DDDD • DD de MMMM de YYYY')}
                                    </Typography>

                                    <Typography
                                        className={styles.textMargin}
                                        variant={'RobotoBold'}
                                        fontSize={36}
                                        textColor={'primary'}
                                    >
                                        {DateUtils.format(realTimeState, 'hh:mm:ss')}
                                    </Typography>
                                </div>

                                {theCollaboratorClocksIn() ? (
                                    !isPhysicalClockIn() ? (
                                        values.currentClockRecord &&
                                            values.currentClockRecord.leaveTime ===
                                            null ? (
                                            <div
                                                className={
                                                    styles.collaboratorInfoClockInSystemHour
                                                }
                                            >
                                                {values.ruleset === 'CLT' ||
                                                    values.ruleset ===
                                                    'Residente' ? (
                                                    <Typography
                                                        className={`${styles.textMargin} ${styles.textLineHeight} ${styles.textCenter}`}
                                                        variant={
                                                            'RobotoRegular'
                                                        }
                                                        fontSize={14}
                                                        textColor={'secondary'}
                                                    >
                                                        {values.currentClockRecord
                                                            ? values
                                                                .currentClockRecord
                                                                .entryTime ===
                                                                null
                                                                ? t(
                                                                    'HR.recordEntryMessage'
                                                                )
                                                                : values
                                                                    .currentClockRecord
                                                                    .leaveForBreak ===
                                                                    null
                                                                    ? t(
                                                                        'HR.recordLeaveForBreakMessage'
                                                                    )
                                                                    : values
                                                                        .currentClockRecord
                                                                        .returnFromBreak ===
                                                                        null
                                                                        ? t(
                                                                            'HR.recordReturnFromBreakMessage'
                                                                        )
                                                                        : t(
                                                                            'HR.recordLeaveMessage'
                                                                        )
                                                            : '-'}
                                                    </Typography>
                                                ) : (
                                                    <Typography
                                                        className={`${styles.textMargin} ${styles.textLineHeight} ${styles.textCenter}`}
                                                        variant={
                                                            'RobotoRegular'
                                                        }
                                                        fontSize={14}
                                                        textColor={'secondary'}
                                                    >
                                                        {values.currentClockRecord
                                                            ? values
                                                                .currentClockRecord
                                                                .entryTime ===
                                                                null
                                                                ? t(
                                                                    'HR.recordEntryMessage'
                                                                )
                                                                : values
                                                                    .currentClockRecord
                                                                    .leaveForBreak ===
                                                                    null
                                                                    ? t(
                                                                        'HR.recordLeaveForBreakNecessaryMessage'
                                                                    )
                                                                    : t(
                                                                        'HR.recordLeaveMessage'
                                                                    )
                                                            : '-'}
                                                    </Typography>
                                                )}
                                                {todayClockRecordEntryTimeIsNull() ? (
                                                    <ErrorIcon
                                                        fontSize="small"
                                                        style={{
                                                            color: '#FFD200'
                                                        }}
                                                        className={
                                                            styles.iconsRight
                                                        }
                                                    />
                                                ) : null}
                                            </div>
                                        ) : null
                                    ) : (
                                        <Typography
                                            className={`${styles.textMargin} ${styles.textLineHeight} ${styles.textCenter}`}
                                            variant={'RobotoBold'}
                                            fontSize={15}
                                            textColor={'primary'}
                                        >
                                            {t(
                                                'HR.theCollaboratorClocksInPhysicalMachine'
                                            )}
                                        </Typography>
                                    )
                                ) : (
                                    <Typography
                                        className={`${styles.textMargin} ${styles.textLineHeight} ${styles.textCenter}`}
                                        variant={'RobotoBold'}
                                        fontSize={15}
                                        textColor={'primary'}
                                    >
                                        {t('HR.collaboratorNotClocksIn')}
                                    </Typography>
                                )}

                                {!isPhysicalClockIn() ? (
                                    values.ruleset === 'CLT' ||
                                        values.ruleset === 'Residente' ? (
                                        <Buttons
                                            disabled={
                                                collaboratorAlreadyCompletedTodayClockRecord() ||
                                                !theCollaboratorClocksIn() ||
                                                !hasAtLeastOneClockRecord() ||
                                                !isCurrentClockRecordInProgressOrWeekend()
                                            }
                                            onClick={() =>
                                                handleOpenDialog(
                                                    todayClockRecordEntryTimeIsNull()
                                                        ? 'ENTRY_TIME'
                                                        : values
                                                            .currentClockRecord
                                                            .leaveForBreak ===
                                                            null
                                                            ? 'LEAVE_FOR_BREAK'
                                                            : values
                                                                .currentClockRecord
                                                                .returnFromBreak ===
                                                                null
                                                                ? 'RETURN_FROM_BREAK'
                                                                : 'LEAVE_TIME'
                                                )
                                            }
                                            tipo="BN-blue"
                                            conteudo={
                                                values.currentClockRecord
                                                    ? values.currentClockRecord
                                                        .entryTime === null
                                                        ? t('HR.recordEntry')
                                                        : values
                                                            .currentClockRecord
                                                            .leaveForBreak ===
                                                            null
                                                            ? t('HR.leaveForBreak')
                                                            : values
                                                                .currentClockRecord
                                                                .returnFromBreak ===
                                                                null
                                                                ? t(
                                                                    'HR.returnFromBreak'
                                                                )
                                                                : t('HR.recordLeave')
                                                    : '-'
                                            }
                                            className={
                                                styles.collaboratorInfoClockInRealTimeButton
                                            }
                                        />
                                    ) : (
                                        <div
                                            className={
                                                styles.collaboratorInfoClockInRealTimeButton
                                            }
                                        >
                                            <Buttons
                                                disabled={
                                                    collaboratorAlreadyCompletedTodayClockRecord() ||
                                                    (values.currentClockRecord &&
                                                        values
                                                            .currentClockRecord
                                                            .leaveForBreak &&
                                                        !values
                                                            .currentClockRecord
                                                            .returnFromBreak) ||
                                                    !theCollaboratorClocksIn() ||
                                                    !isCurrentClockRecordInProgressOrWeekend()
                                                }
                                                onClick={() =>
                                                    handleOpenDialog(
                                                        todayClockRecordEntryTimeIsNull()
                                                            ? 'ENTRY_TIME'
                                                            : 'LEAVE_TIME'
                                                    )
                                                }
                                                tipo="BN-blue"
                                                conteudo={
                                                    values.currentClockRecord
                                                        ? values
                                                            .currentClockRecord
                                                            .entryTime ===
                                                            null
                                                            ? t(
                                                                'HR.recordEntry'
                                                            )
                                                            : t(
                                                                'HR.recordLeave'
                                                            )
                                                        : '-'
                                                }
                                                className={`${styles.collaboratorInfoClockInRealTimeButton} ${styles.space}`}
                                            />
                                            {values.currentClockRecord &&
                                                values.currentClockRecord
                                                    .entryTime &&
                                                !values.currentClockRecord
                                                    .returnFromBreak &&
                                                !values.currentClockRecord
                                                    .leaveTime ? (
                                                <Buttons
                                                    disabled={
                                                        collaboratorAlreadyCompletedTodayClockRecord() ||
                                                        !theCollaboratorClocksIn() ||
                                                        !hasAtLeastOneClockRecord()
                                                    }
                                                    onClick={() =>
                                                        handleOpenDialog(
                                                            todayClockRecordLeaveForBreakIsNull()
                                                                ? 'LEAVE_FOR_BREAK'
                                                                : 'RETURN_FROM_BREAK'
                                                        )
                                                    }
                                                    tipo="BN-noback"
                                                    conteudo={
                                                        values.currentClockRecord
                                                            ? values
                                                                .currentClockRecord
                                                                .leaveForBreak ===
                                                                null
                                                                ? t(
                                                                    'HR.leaveForBreak'
                                                                )
                                                                : t(
                                                                    'HR.returnFromBreak'
                                                                )
                                                            : '-'
                                                    }
                                                    className={`${styles.collaboratorInfoClockInRealTimeButton} ${styles.blueBorder}`}
                                                />
                                            ) : null}
                                        </div>
                                    )
                                ) : null}
                            </div>
                        </div>
                    </Paper>

                    {/* Table */}
                    <Paper>
                        <div className="cabecalho">
                            {t('HR.myClockRecords').toUpperCase()}
                            <ExportButton
                                isLoading={reportLoading}
                                onClick={handleGenerateReport}
                                title={t('HR.generateReport').toUpperCase()}
                            />
                        </div>
                        <React.Fragment>
                            <FilterContainer>
                                <InputFilter
                                    filterValue={filterYear}
                                    setFilter={setFilterYear}
                                    label={'GENERAL.year'}
                                    isSelect
                                    arraySelected={yearsArray}
                                />
                                <InputFilter
                                    filterValue={filterMonth}
                                    setFilter={setFilterMonth}
                                    label={'GENERAL.month'}
                                    isSelect
                                    arraySelected={monthsArray}
                                />
                                <InputFilter
                                    filterValue={filterActivity}
                                    setFilter={setFilterActivity}
                                    label={'GENERAL.activities'}
                                    isSelect
                                    arraySelected={activityArray}
                                />
                                <InputFilter
                                    filterValue={filterStatus}
                                    setFilter={setFilterStatus}
                                    label={'GENERAL.status'}
                                    isSelect
                                    arraySelected={statusArray}
                                />
                            </FilterContainer>

                            <MyClockRecordTable
                                rows={dataFilter}
                                toFirstPage={canClick}
                                headers={[
                                    'id',
                                    'date',
                                    'entryTime',
                                    'leaveForBreak',
                                    'returnFromBreak',
                                    'leaveTime',
                                    'activityRecord',
                                    'status'
                                ]}
                                visibleHeaders={[
                                    'Data',
                                    'Entrada',
                                    'Saída Intervalo',
                                    'Retorno Intervalo',
                                    'Saída',
                                    'Registro de Atividades',
                                    'Status',
                                    ''
                                ]}
                                sortedFields={[
                                    'date',
                                    'entryTime',
                                    'leaveForBreak',
                                    'returnFromBreak',
                                    'leaveTime',
                                    'activityRegister',
                                    'status'
                                ]}
                                mainField="date"
                                fetchData={fetchData}
                                registersActivity={values.registersActivity}
                            />
                            {!hasItens && (
                                <div className={classes.noParticipantsMessage}>
                                    <h2>{t('HR.noClockRecordMessage')}</h2>
                                    <h4>
                                        {theCollaboratorClocksIn()
                                            ? t('HR.noClockRecordSubMessage')
                                            : t(
                                                'HR.collaboratorNotClocksInSubMessage'
                                            )}
                                    </h4>
                                </div>
                            )}
                            {hasNoResult && (
                                <div className={classes.noParticipantsMessage}>
                                    <div>
                                        <Dissatisfied
                                            className={
                                                classes.notFoundIconStyle
                                            }
                                        />
                                    </div>
                                    <h2>{t('GENERAL.noResultsFound')}</h2>
                                    <h5>{`Sua busca por "${findText}" não retornou resultados.`}</h5>
                                </div>
                            )}
                        </React.Fragment>
                    </Paper>
                    {openDialog && (
                        <DialogMyClockRecord
                            open={openDialog}
                            setOpen={setOpenDialog}
                            clockType={clockType}
                            currentClockRecord={values.currentClockRecord}
                            workerdHoursRealTime={workedHoursRealTime}
                            fetchData={fetchData}
                            handleSubmit={handleSubmit}
                            registersActivity={values.registersActivity}
                        />
                    )}
                </ContentContainer>
                <Footer />
            </Transition>
        </React.Fragment>
    );
};

export default MyClockRecord;
