import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    breadCrumbs: {
        marginTop: '30px',
        flexWrap: 'wrap',
        marginBottom: '1px'
    },
    breadCrumbsNewDesign: {
        paddingTop: '30px',
        flexWrap: 'wrap',
        marginBottom: '1px'
    },
    title: {
        color: '#717272',
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: '37px',
        fontStyle: 'normal'
    },
    titleNewDesign: {
        marginTop: '0px',
        marginBottom: '5px',
        color: '#6D6F71',
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: '37px',
        fontStyle: 'normal'
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: '#f5f6fa',
        height: '45px',
        opacity: '0.54',
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '3.5',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#000000',
        paddingLeft: '18px',
        paddingRight: '10px'
    },
    bodyForm: {
        paddingLeft: '18px',
        paddingRight: '18px',
        paddingBottom: '30px'
    },
    cooperationTermHeader: {
        paddingLeft: '18px',
        paddingRight: '18px',
        paddingBottom: '30px',
        color: '#000000',
        backgroundColor: '#f5f6fa',
        fontFamily: 'Roboto',
        height: '15px',
        fontSize: '14px',
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontStretch: 'normal'
    },
    // ================================== MESSAGES
    message: {
        display: 'flex',
        alignItems: 'center'
    },
    link: {
        color: '#73b3ff'
    },
    success: {
        color: theme.palette.grey.darkest,
        backgroundColor: theme.palette.teal.light,
        boxShadow: 'none',
        '& svg': {
            color: theme.palette.teal.darkest
        }
    },
    error: {
        color: theme.palette.grey.darkest,
        backgroundColor: theme.palette.red.light,
        boxShadow: 'none',
        '& svg': {
            color: theme.palette.red.darkest
        }
    },
    icon: {
        fontSize: 20
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1)
    },
    // ==================================
    textField: {
        display: 'flex'
    },
    stepNumber: {
        fontSize: '23px',
        fontWeight: 'bold'
    },
    blueButton: {
        backgroundColor: '#2c3e51',
        padding: '14px 24px',
        fontWeight: 'normal',
        fontSize: '14px'
    },
    reportButton: {
        backgroundColor: '#2c3e51',
        padding: '3px ',
        fontWeight: 'normal',
        fontSize: '14px'
    },
    grayButton: {
        backgroundColor: '#E0E0E0',
        padding: '14px 24px',
        fontWeight: 'normal',
        fontSize: '14px'
    },
    addParticipant: {
        padding: '40px 24px',
        fontWeight: 'normal',
        fontSize: '14px',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        color: 'black',
        pointerEvents: 'none'
    },
    finishProject: {
        //padding: "40px 24px",
        fontWeight: 'normal',
        fontSize: '14px',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        color: 'black'
        // '&:hover': {
        //   backgroundColor: "#eef2f6",
        // },
    },
    hintFinishProject: {
        padding: '10px 14px',
        backgroundColor: '#F2EFEF',
        color: 'red',
        borderRadius: '4px'
    },
    addParticipantNoAddendum: {
        padding: '40px 24px',
        fontWeight: 'normal',
        fontSize: '14px',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        color: 'black'
    },
    addPhaseNoAddendum: {
        padding: '40px 24px',
        fontWeight: 'normal',
        fontSize: '14px',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        color: 'black',
        pointerEvents: 'none',
        alignItems: 'right',
        textAlign: 'right'
    },
    buttonIcon: {
        display: 'flex',
        marginRight: '15px'
    },
    fab: {
        float: 'right',
        marginTop: '15px',
        marginBottom: '15px'
    },
    attachedFileButton: {
        display: 'flex',
        color: theme.palette.teal.dark,
        fontWeight: 'bold',
        '& svg': {
            color: theme.palette.teal.dark
        }
    },
    noAttachedFileButton: {
        display: 'flex',
        color: theme.palette.grey.darkest,
        fontWeight: 'normal'
    },
    errorWhenAttachFileButton: {
        display: 'flex',
        color: theme.palette.red.main,
        fontWeight: 'bold',
        '& svg': {
            color: theme.palette.red.main
        }
    },
    customAttachedDocumentTextField: {
        display: 'flex',
        cursor: 'pointer',
        marginTop: '16px'
    },
    customAttachedDocumentTextFieldDisabled: {
        display: 'flex',
        marginTop: '16px'
    },
    addendumHeader: {
        paddingTop: '0px'
    },
    addendumIntermIcons: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    addendumTextField: {
        display: 'flex',
        marginTop: '-8px',
        marginBottom: '0px'
    },
    customAddendumAttachedDocumentTextField: {
        display: 'flex',
        cursor: 'pointer',
        marginTop: '-8px',
        marginBottom: '0px'
    },
    addendumIntermIconsMarginRight: {
        display: 'flex',
        marginRight: '-10px'
    },
    addendumIntermIconsMarginLeft: {
        marginTop: '10px',
        fontWeight: 'bold',
        opacity: '0.6'
    },
    participantSearch: {
        width: '45%',
        display: 'inline-block',
        paddingTop: '16px'
    },
    activitySearch: {
        width: '65%',
        display: 'inline-block',
        paddingTop: '16px'
    },
    divAddParticipant: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    divAddParticipantLeft: {
        display: 'flex',
        flexGrow: 1,
        flexFlow: 'row wrap'
    },
    fieldInputSearch: {
        display: 'inline-block',
        paddingTop: '16px',
        marginRight: '25px',
        '& .MuiInputBase-root': {
            width: '210px'
        }
    },
    tableRoot: {
        width: '100%',
        overflowX: 'auto'
    },
    table: {
        minWidth: 650
    },
    Hint: {
        fontSize: '14px',
        padding: '5px 15px',
        opacity: '0.54',
        fontFamily: 'Roboto',
        alignItems: 'left',
        textAlign: 'left'
    },
    CostNames: {
        marginTop: '25px',
        fontWeight: 'bold'
    },
    actionsIcons: {
        opacity: '0'
    },
    actionsTitle: {
        fontFamily: 'Roboto',
        fontSize: '20px',
        textAlign: 'left',
        color: '#000000',
        fontWeight: 'bold'
    },
    noParticipantsMessage: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '21px',
        color: '#000000',
        opacity: '0.54',
        textAlign: 'center',
        paddingBottom: '22px'
    },
    notFoundIconStyle: {
        paddingTop: '50px',
        fontSize: 70
    },
    projectInformation: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        color: '#000000',
        lineHeight: '21px',
        opacity: '0.54'
    },
    projectInformation2: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        color: '#000000',
        opacity: '0.54'
    },
    // ========SEARCH=======
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#F5F6FA',
        '&:hover': {
            backgroundColor: 'rgb(224, 224, 224)'
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1)
            // width: 'auto',
        }
    },
    searchInventory: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#F5F6FA',
        '&:hover': {
            backgroundColor: 'rgb(224, 224, 224)'
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        maxWidth: '220px',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1)
            // width: 'auto',
        }
    },

    tipAlreadyTitleCreate: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#F5F6FA',
        '&:hover': {
            backgroundColor: 'rgb(224, 224, 224)'
        },
        marginTop: 10,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            //  marginLeft: theme.spacing(1),
            // width: 'auto',
        }
    },

    searchToolbar: {
        flexGrow: '1',
        maxWidth: '550px'
    },

    searchToolbarInventory: {
        // flexGrow: "1",
        maxWidth: '550px'
    },

    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },

    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            // width: 500,
            height: 32
        }
        // '& ::-webkit-input-placeholder':{
        //   textOverflow:'ellipsis',
        // },
    },
    inputSearch: {
        padding: theme.spacing(1, 1, 1, 1),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            // width: 500,
            height: 32
        }
        // '& ::-webkit-input-placeholder':{
        //   textOverflow:'ellipsis',
        // },
    },
    modalAllocate: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatarParentDialog: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        minWidth: '130px',
        marginTop: '10px'
    },
    dropzoneDialog: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100px',
        borderWidth: '1px',
        borderRadius: '2px',
        borderColor: '#000000',
        borderStyle: 'dashed',
        backgroundColor: '#eeeeee',
        color: '#000000',
        outline: 'none',
        transition: 'border .24s ease-in-out',
        marginTop: '30px',
        marginBottom: '8px',
        cursor: 'pointer',

        '& svg': {
            marginBottom: '10px'
        }
    },
    dropzoneDialogFiles: {
        height: '148px',
        overflowY: 'auto'
    },
    dialogFile: {
        height: '44px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#eeeeee',
        padding: '5px 10px',
        marginTop: '8px',
        '& svg': {
            fontSize: '16px'
        },
        '&:first-child': {
            marginTop: 0
        }
    },
    textFile: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '90%'
    },
    nameFile: {
        fontWeight: 'bold',
        marginRight: '10px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '320px'
    },
    nameFileError: {
        fontWeight: 'bold',
        marginRight: '10px',
        wordWrap: 'break-word',
        width: '100%'
    },
    itemActiveOnExternalUser: {
        opacity: '0.54',
        marginRight: '10px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '320px',
        fontFamily: 'Roboto'
    },
    iconsFile: {
        display: 'flex'
    },
    noFilesMessage: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '21px',
        color: '#000000',
        opacity: '0.54',
        height: '148px'
    },
    reports: {
        //display: "flex",
        width: '1000px',
        height: '166px',
        paddingRight: '15px'
    },
    paper: {
        padding: theme.spacing(3),
        //textAlign: "center",
        borderColor: '#BDBDBD',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: '5px'
    },
    paper2: {
        padding: theme.spacing(3)
        //textAlign: "center",
    },
    questionSectionTitle: {
        fontSize: 18,
        fontWeight: 'bold'
    },
    questionSectionSubTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        marginTop: 30
    },
    questionFeedback: {
        marginTop: 15
    },
    question: {
        fontSize: 14
    },
    questionRating: {
        display: 'flex',
        justifyItems: 'center',
        alignItems: 'center',
        marginTop: 15
    },
    questionRatingText: {
        fontSize: 12,
        opacity: 0.6
    },
    questionRatingButton: {
        marginRight: 4,
        marginLeft: 4,
        minWidth: 'auto !important'
    },
    questionRatingSelected: {
        marginRight: 4,
        marginLeft: 4,
        minWidth: 'auto !important',
        backgroundColor: '#2D9CDB',
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#2D9CDB'
        }
    },
    btnActive: {
        float: 'right',
        backgroundColor: '#666'
    },
    btn: {
        float: 'right',
        outline: 'none',
        padding: '12px 16px',
        backgroundColor: '#f1f1f1',
        cursor: 'pointer'
    },

    // INEVNTORY DIALOGS

    iconReqDialog: {
        marginLeft: '10px',
        opacity: '0',
        backgroundColor: '#6D6F71',
        color: '#FFFFFF;'
    },
    TitleDialog: {
        fontFamily: 'Roboto',
        fontSize: '30px',
        textAlign: 'left',
        color: '#000000',
        fontWeight: 'bold'
    },
    imageDialog: {
        width: 180,
        height: 180
    },
    imgDialog: {
        margin: 'auto',
        //display: 'block',
        maxWidth: '100%',
        maxHeight: '100%'
    },
    titleDialog: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '16px',
        color: '#000000',
        opacity: '0.54'
    },
    codeIdDialog: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '16px',
        color: '##6D6F71'
    },
    btnDialog: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '16px',
        color: '#2D9CDB'
    },
    textFieldCustumDialog: {
        display: 'flex',
        marginLeft: '10px'
    },
    formControl: {
        //margin: theme.spacing(1),
        minWidth: 120
    },
    titleNewEquipment: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '16px',
        color: '#000000',
        opacity: '0.54',
        padding: 0
    },
    bodyNewEquipment: {
        paddingTop: '18px',
        paddingLeft: '18px',
        paddingRight: '18px',
        paddingBottom: '30px'
    },
    imgNewEquipment: {
        display: 'block',
        width: '170px',
        height: '170px'
    },
    thumbsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16
    },
    thumb: {
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        width: 160,
        height: 160,
        padding: 4,
        boxSizing: 'border-box'
    },
    thumbInner: {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden'
    },
    attachEquipment: {
        width: '168px',
        //textAlign: "center",
        paddingBottom: '10px',
        marginTop: '-15px',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '16px',
        color: '#000000',
        opacity: '0.54'
    },
    attachImgBook: {
        width: '168px',
        //textAlign: "center",
        paddingBottom: '10px',
        marginLeft: '21px',
        marginTop: '3px',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '16px',
        color: '#000000',
        opacity: '0.54'
    },
    subItemForm: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column'
    },
    label: {
        fontSize: 12,
        marginTop: 8
    },
    noSubitem: {
        display: 'flex',
        height: 50,
        borderRadius: 4,
        justifyContent: 'center',
        alignItems: 'center',
        background: '#F5F6FA'
    },
    removerHover: {
        '&:hover': {
            backgroundColor: 'transparent'
        },
        pointerEvents: 'none'
    },
    // TABLE ACTIONS
    iconsTableActions: {
        opacity: '0'
    },
    inputPasswordDelete: {
        padding: theme.spacing(1, 1, 1, 1),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            height: 32
        }
    },
    containerHeader: {
        display: 'flex',
        marginBottom: '20px',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    containerTabsNewSoftware: {
        display: 'flex',
        marginBottom: '20px',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    bodyNewLicense: {
        paddingTop: '18px',
        paddingLeft: '150px',
        paddingRight: '150px',
        paddingBottom: '30px'
    },
    deleteRequestTable: {
        width: '100%',
        textAlign: 'left'
    },
    // ------------------------ HumanResourceStyles---------------------
    identificationFormHeader: {
        display: 'flex',
        paddingTop: '30px',
        paddingLeft: '15px'
    },
    identificationForm: {
        display: 'flex',
        paddingTop: '10px',
        paddingLeft: '15px'
    },
    identificationFrame: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '30px'
    },
    identificationDatePicker: {
        width: '100%'
    },
    headerColor: {
        paddingTop: '20px',
        color: '#2C3E51',
        paddingBottom: '10px'
    },
    familyDataForm: {
        paddingTop: '10px',
        paddingLeft: '15px'
    },
    admissionSituation: {
        color: '#6D6F71',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    admissionSituationText: {
        color: '#2C3E51',
        marginLeft: '5px'
    },
    messagePaper: {
        width: '100%',
        fontFamily: 'Roboto',
        color: '#000000',
        opacity: '0.54',
        textAlign: 'center',
        backgroundColor: '#F5F6FA'
    },
    inputFile: {
        display: 'flex',
        marginTop: '20px',
        marginBottom: '8px'
    },
    inputFileButton: {
        backgroundColor: '#2C3E51',
        display: 'flex',
        flexDirection: 'column',
        color: 'white',
        borderRadius: '0px 5px 5px 0px',
        alignItems: 'center',
        padding: '8px',
        fontSize: '13px',
        height: '80%'
    },

    headerCollaboratorsVacations: {
        backgroundColor: '#f5f6fa',
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '3.5',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#000000',
        paddingLeft: '1.25rem',
        paddingRight: '1.25rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subtextGrey: {
        fontFamily: 'Roboto',
        fontSize: '12px',
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#696969'
    },
    vacationCalendarTab: {
        minWidth: 50,
        fontWeight: 'bold',
        display: 'flex'
    },
    noDataWrapper: {
        paddingTop: '20px',
        backgroundColor: '#F5F6FA',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '21px',
        color: '#000000',
        opacity: '0.54',
        textAlign: 'center',
        paddingBottom: '22px'
    }
}));

const StyledTableCell2 = withStyles(theme => ({
    head: {
        color: theme.palette.common.black,
        opacity: '0.54',
        textAlign: 'left',
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 'bold'
    },
    body: {
        fontSize: 14
    }
}))(TableCell);

const tooltipThemeAlert = createMuiTheme({
    palette: {
        primary: {
            main: '#2c3e51'
        }
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                color: 'white',
                fontSize: '1em',
                backgroundColor: 'red',
                opacity: '0.7'
            }
        }
    }
});

export { useStyles, StyledTableCell2, tooltipThemeAlert };
