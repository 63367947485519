import React from "react";
import "../../../../App.css";
import SideBar from "components/layout/sidebar/Sidebar";
import DashboardNavBar from "components/layout/DashboardNavBar";
import BooksExemplarsPage from "./BooksExemplarsPage";

const CallEditBook = ({ match }) => {
  return (
    <div>
      <DashboardNavBar />
      <SideBar />
      <BooksExemplarsPage id={match.params.id} context={"edit"} type={"BOOK"}/>      
    </div>
  );
}

export default CallEditBook;