import React from "react";
import "../../../App.css";
import SideBar from "components/layout/sidebar/Sidebar";
import DashboardNavBar from "components/layout/DashboardNavBar";
import InventoryStatus from "./InventoryStatus";

const AllInventoryStatus = ({ match }) => {
  return (
    <div>
      <DashboardNavBar />
      <SideBar />
      <InventoryStatus />
    </div>
  );
}
export default AllInventoryStatus;