/**
 * Class to parse a string date or timestamp into a Date object
 */
export default class DateParser {
    static parse(dateString) {
        const datePattern = /^\d{4}-\d{2}-\d{2}$/;
        const dateTimePattern =
            /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?$/;
        if (
            !datePattern.test(dateString) &&
            !dateTimePattern.test(dateString)
        ) {
            throw new Error(
                "Invalid date string format. Expected 'YYYY-MM-DD', 'YYYY-MM-DDTHH:mm:ss' or 'YYYY-MM-DDTHH:mm:ss.sss'"
            );
        }

        const [date, timePart] = dateString.split("T");
        const [year, month, day] = date
            .split("-")
            .map(num => parseInt(num, 10));
        let hours = 0,
            minutes = 0,
            seconds = 0,
            milliseconds = 0;
        if (timePart) {
            const [time, msPart] = timePart.split(".");
            [hours, minutes, seconds] = time
                .split(":")
                .map(num => parseInt(num, 10));
            milliseconds = msPart
                ? Math.round(parseFloat("0." + msPart) * 1000)
                : 0;
        }
        return new Date(
            year,
            month - 1,
            day,
            hours,
            minutes,
            seconds,
            milliseconds
        );
    }
}
