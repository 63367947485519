/* eslint-disable */
import DateFnsUtils from "@date-io/date-fns";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Radio from '@material-ui/core/Radio';
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Switch from '@material-ui/core/Switch';
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import AddCircle from "@material-ui/icons/AddCircle";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Attachment from "@material-ui/icons/Attachment";
import Clear from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import Edit from "@material-ui/icons/Edit";
import GetAppIcon from '@material-ui/icons/GetApp';
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Footer from "components/layout/Footer";
import Spinner from "components/layout/spinners/Index";
import AllowancesContext from "context/allowance/allowanceContext";
import ExternalUsersContext from "context/externalUsers/externalUsersContext";
import InventoryContext from "context/inventory/inventoryContext";
import ProjectsContext from "context/projects/projectsContext";
import UsersContext from "context/users/usersContext";
import React, { useContext, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import { useStyles } from "components/layout/CommonStyles";
import MySnackbarContentWrapper from "components/layout/Message";
import DialogSubItem from "./AddSubItemDialog";
import DialogBatchAdd from "./DialogBatchAdd";
import DialogStatus from "./DialogStatus";
import DialogResponsible from "./EditEquipment/EditResponsibleDialog";
import SubItensTable from "./SubItensTable";
import { dateToBR } from "utils/dates/DateToBRr";
import { Events } from "global/constants";
const tooltipTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#2c3e51"
        }
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                color: "white",
                fontSize: "1em",
                backgroundColor: "red",
                opacity: "0.7"
            }
        }
    }
});

const Equipment = () => {
    const fileDownload = require("js-file-download");
    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }
    const classes = useStyles();
    const [t] = useTranslation();
    const inventoryContext = useContext(InventoryContext);
    const allowanceContext = useContext(AllowancesContext);
    const externalUsersContext = useContext(ExternalUsersContext);
    const projectsContext = useContext(ProjectsContext);
    const usersContext = useContext(UsersContext);
    const [placesArray, setPlacesArray] = useState([]);
    const [categoriesArray, setCategoriesArray] = useState([]);
    const [projectsArray, setProjectsArray] = useState([]);
    const [usersArray, setUsersArray] = useState([]);
    const [equipmentArray, setEquipmentArray] = useState([]);
    const [externalUsersArray, setExternalUsersArray] = useState([]);
    const [checkedC, setCheckedC] = useState(true);
    const [hasUpdatedFile, setHasUpdatedFile] = useState(false);
    const [selectedValue, setSelectedValue] = React.useState('a');
    const [openBatchAdd, setOpenBatchAdd] = React.useState(false);
    const [openStatus, setOpenStatus] = React.useState(false);
    const [openSubItem, setOpenSubItem] = React.useState(false);
    const [amountEquipments, setAmountEquipments] = React.useState(2);
    const [mustRedirect, setMustRedirect] = useState(false);
    const [openResponsible, setOpenResponsible] = React.useState(false);
    const [admsRh, setAdmsRh] = useState([]);

    const [snackBar, setSnackBar] = useState({
        open: false,
        kind: "",
        content: ""
    });

    const [dataState, setDataState] = useState({
        files: [],
        rejectedFiles: []
    });

    const [values, setValues] = useState({
        equipmentImage: "",
        acquisitionDate: new Date(),
        brand: "",
        categoryId: "",
        observation: "",
        description: "",
        observation: "",
        invoice: "",
        statusId: "",
        name: "",
        placeId: "",
        projectId: "",
        responsibleId: "",
        serialNumber: "",
        value: "",
        warrantyDate: null,
        statusDescription: "",
        statusName: "",
        responsible: "",
        responsibleDescription: "",
        currentTypeUser: "",
        currentResponsible: "",
    });
    const [requiredName, setRequiredName] = useState(false);
    const [requiredProject, setRequiredProject] = useState(false);
    const [requiredDateAcquisition, setRequiredDateAcquisition] = useState(false);
    const [requiredDateWarranty, setRequiredDateWarranty] = useState(false);
    const [requiredCode, setRequiredCode] = useState(false);
    const [requiredSN, setRequiredSN] = useState(false);
    const [requiredPlace, setRequiredPlace] = useState(false);
    const [requiredResponsible, setRequiredResponsible] = useState(false);
    const [requiredStatus, setRequiredStatus] = useState(false);
    const [requiredCategory, setRequiredCategory] = useState(false);
    const [serialAlreadyTaken, setSerialAlreadyTaken] = useState(false);

    //Serial number
    const [allSerialNumbers, setAllSerialNumbers] = useState([]);

    const [allLsbdCodes, setAllLsbdCodes] = useState([]);
    const [allUfcCodes, setAllUfcCodes] = useState([]);
    const [allFcpcCodes, setAllFcpcCodes] = useState([]);
    const [allExternCodes, setAllExternCodes] = useState([]);

    const [titleName, setTitle] = useState(t("GENERAL.requiredField"));
    const [titleCodes, setTitleCodes] = useState(t("GENERAL.requiredField"));


    const [files, setFiles] = useState([]);

    const [codes, setCodes] = useState([
        { code: "", type: "", category: "internalCode", partner: "" },
    ]);

    const internalOrigins = [
        {
            label: 'FCPC',
            key: 'fcpcCode',
        },
        {
            label: 'UFC',
            key: 'ufcCode',
        },
        {
            label: 'LSBD',
            key: 'lsbdCode',
        },
    ];

    const categoriesCode = [
        {
            label: 'INTERNO',
            key: 'internalCode',
        },
        {
            label: 'EXTERNO',
            key: 'externalCode',
        }
    ];

    const {
        getAllEquipment,
        equipment,
        loading,
        response,
        hasChanged,
        setHasChanged,
        responseType,
        successiveActions,
        getPlaces,
        places,
        getItensCategories,
        itensCategories,
        getAllStatus,
        allStatus,
        addEquipment,
        arraySubItens,
        addSubItensArray,
        getExternalPartners,
        externalPartners,
        cleanArraySubItem,
        getNextCodes,
        nextCodes,
        externalUserPlaces,
        getExternalPlaces,
        lsbdCodesHistory,
        getLsbdCodesHistory,
        admsPeopleManagement,
        geAdmsPeopleManagement,
        getUsersEmailData,
        usersEmail
    } = inventoryContext;

    const {
        allowanceResponse,
        allowanceResponseType,
        messageHasChanged,
        resetAllowanceMessage,
    } = allowanceContext;

    const {
        getProjects,
        projects
    } = projectsContext;

    const {
        getUsers,
        users
    } = usersContext;

    const {
        getExternalUsers,
        externalUsers
    } = externalUsersContext;

    useEffect(() => {
        getAllEquipment();
        getPlaces();
        getItensCategories();
        getProjects();
        getUsers();
        getAllStatus();
        getExternalPartners();
        getNextCodes();
        getExternalPlaces();
        getExternalUsers();
        getUsersEmailData(Events.NEW_EQUIPMENT_EMAIL)
        getLsbdCodesHistory();
        geAdmsPeopleManagement();
    }, []);

    const handleChangeRadio = (event) => {
        setSelectedValue(event.target.value);
        if (event.target.value = "b") {
            cleanArraySubItem()
        }
    };

    const transitions = useTransition(loading, null, {
        from: { opacity: 0 },
        enter: {
            opacity: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "calc(100vh - 70px)"
        },
        leave: { opacity: 0 }
    });

    var subitensFilter = [];

    const sortElement = (elemnts) => {
        elemnts.sort(function (a, b) {
            if (a.name === null) a.name = "";
            if (b.name === null) b.name = "";

            if (a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() >
                b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim()) {
                return 1;
            }
            if (a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() <
                b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim()) {
                return -1;
            }
            // a must be equal to b
            return 0;
        });
    }

    useEffect(() => {
        const myPlaces = [...places];
        sortElement(myPlaces);

        var aux = {};
        var _places = [];
        for (var i = 0; i < myPlaces.length; i++) {
            var item = myPlaces[i];
            aux.value = item.id;
            aux.label = item.name;
            _places.push(aux);
            aux = {};
        }
        setPlacesArray(_places);
    }, [places]);

    useEffect(() => {
        const categories = [...itensCategories];
        sortElement(categories);

        var aux = {};
        var _categories = [];
        for (var i = 0; i < categories.length; i++) {
            var item = categories[i];
            aux.value = item.id;
            aux.label = item.name;
            _categories.push(aux);
            aux = {};
        }
        setCategoriesArray(_categories);
    }, [itensCategories]);

    useEffect(() => {
        const allProjects = [...projects];
        sortElement(allProjects);

        var aux = {};
        var _projects = [];
        for (var i = 0; i < allProjects.length; i++) {
            var item = allProjects[i];
            aux.value = item.idInformation;
            aux.label = item.name;
            _projects.push(aux);
            aux = {};
        }
        setProjectsArray(_projects);
    }, [projects]);

    useEffect(() => {
        const allUsers = [...users];
        const RhPermission = [...admsPeopleManagement]
        sortElement(allUsers);

        var aux = {};
        var _users = [];
        for (var i = 0; i < allUsers.length; i++) {
            var item = allUsers[i];
            aux.value = item.id;
            aux.label = item.name;
            _users.push(aux);
            aux = {};
        }

        var _auxAdm = [];
        var _usersAdms = [];
        for (var i = 0; i < allUsers.length; i++) {
            var item = allUsers[i];
            var nameFormated = item.name.split(" ")
            _auxAdm.id = item.id;
            _auxAdm.admName = nameFormated[0] + " " + nameFormated[nameFormated.length - 1];
            _auxAdm.email = item.email;
            _usersAdms.push(_auxAdm);
            _auxAdm = {};
        }

        const permissionFilter = RhPermission.map(function (elem) {
            return elem.userId
        });
        var _libraryUsers = _usersAdms.filter(x => permissionFilter.includes(x.id))

        setUsersArray(_users);
        setAdmsRh(_libraryUsers);
    }, [users]);

    function getCodeList(row) {
        const response = [];

        if (row["lsbdCode"])
            response.push(row["lsbdCode"]);
        if (row["fcpcCode"])
            response.push("FCPC-" + row["fcpcCode"]);
        if (row["ufcCode"])
            response.push("UFC-" + row["ufcCode"]);
        if (row["serialNumber"])
            response.push("Serial-" + row["serialNumber"]);

        return response;
    }

    useEffect(() => {
        const equipments = [...equipment];
        sortElement(equipments);

        let aux = {};
        let auxSerialNumber = [];
        let auxLsbdCodes = [];
        let auxUfcCodes = [];
        let auxFcpcCodes = [];
        let auxExternCodes = [];
        let _equipment = [];

        for (let index = 0; index < lsbdCodesHistory.length; index++) {
            const element = lsbdCodesHistory[index];
            auxLsbdCodes.push(element.lsbdCode)
        }

        for (let i = 0; i < equipments.length; i++) {
            let item = equipments[i];
            aux.value = item.id;
            aux.categoryId = item.categoryId;
            aux.fcpcCode = item.fcpcCode;
            aux.lsbdCode = item.lsbdCode;
            aux.ufcCode = item.ufcCode;
            aux.place = item.place;
            aux.responsible = item.responsible;
            aux.label = item.name + (getCodeList(item)[0] ? " - Código: " + getCodeList(item)[0] : " - Sem código")//" - Código: "+ item.fcpcCode;

            // Adiciona no array os códigos existentes filtrando os duplicados já pre-existentes
            if (item.serialNumber) auxSerialNumber.push(item.serialNumber);
            if (item.lsbdCode) auxLsbdCodes.push(item.lsbdCode.slice(-1) === "D" ? item.lsbdCode.slice(0, -1) : item.lsbdCode);
            if (item.ufcCode) auxUfcCodes.push(item.ufcCode.slice(-1) === "D" ? item.ufcCode.slice(0, -1) : item.ufcCode);
            if (item.fcpcCode) auxFcpcCodes.push(item.fcpcCode.slice(-1) === "D" ? item.fcpcCode.slice(0, -1) : item.fcpcCode);
            if (item.externalCode) auxExternCodes.push(item.externalCode.slice(-1) === "D" ? item.externalCode.slice(0, -1) : item.externalCode);
            _equipment.push(aux);
            aux = {};
        }
        setEquipmentArray(_equipment);
        setAllSerialNumbers(auxSerialNumber);
        setAllLsbdCodes(auxLsbdCodes);
        setAllUfcCodes(auxUfcCodes);
        setAllFcpcCodes(auxFcpcCodes);
        setAllExternCodes(auxExternCodes);
    }, [equipment]);

    useEffect(() => {
        const allExternalUsers = [...externalUsers];
        sortElement(allExternalUsers);

        var aux = {};
        var _externalUsers = [];
        for (var i = 0; i < allExternalUsers.length; i++) {
            var item = allExternalUsers[i];
            aux.value = item.id;
            aux.label = item.name;
            _externalUsers.push(aux);
            aux = {};
        }
        setExternalUsersArray(_externalUsers);
    }, [externalUsers]);

    useEffect(() => {
        if (allowanceResponseType === "ERROR" && messageHasChanged) {
            handleSnack({ kind: "error", content: allowanceResponse });
        }
        if (responseType === "SUCCESS" && hasChanged > successiveActions) {
            handleSnack({ kind: "success", content: response });
        }
        if (responseType === "ERROR" && hasChanged > successiveActions) {
            handleSnack({ kind: "error", content: response });
        }
        return () => { };
    }, [
        responseType,
        response,
        successiveActions,
        allowanceResponseType,
        allowanceResponse,
    ]);

    const handleSnack = () => {
        setSnackBar({ ...snackBar, open: true });
        setHasChanged();
    };
    const handleSnackClose = () => {
        setSnackBar({ ...snackBar, open: false });
        resetAllowanceMessage();
    };

    const onDrop = acceptedFiles => {
        if (acceptedFiles[0] !== undefined) {
            setHasUpdatedFile(true);
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    };

    const onDropFiles = acceptedFiles => {
        let newFiles = [];
        let existentFiles = [];

        for (let i = 0; i < acceptedFiles.length; i++) {
            let hasTheSameName = dataState.files.filter(el => (el.fileName ? el.fileName.toLowerCase() : el.name.toLowerCase()) ===
                acceptedFiles[i].name.toLowerCase()).length > 0;

            if (hasTheSameName) {
                existentFiles.push(`Arquivo já existente - ${acceptedFiles[i].name}`);
            }
            else {
                newFiles.push(acceptedFiles[i])
            }
        }

        setDataState({
            ...dataState,
            files: dataState.files.concat(newFiles),
            rejectedFiles: dataState.rejectedFiles.concat(existentFiles)
        });
    };

    function humanFileSize(size) {
        let i = Math.floor(Math.log(size) / Math.log(1024));
        return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    };

    const onClickDownload = async (file) => {
        if (dataState && dataState.files.length > 0) {
            fileDownload(file, file.name);
        }
    };

    const onRemoveFile = (data) => {
        if (data) {
            let remainingFiles = [];
            let updatedList = [];
            for (const file of dataState.files) {
                if ((file.path === data.path) && file.lastModified === data.lastModified && file.size === data.size) {
                    if (file.id) {
                        setToDeleteFile([...toDeleteFile, file.id]);
                        updatedList = dataState.rejectedFiles.filter(el => el !== `Arquivo já existente - ${data.fileName}`);
                    }
                    continue;
                }
                remainingFiles.push(file)
            }

            setDataState({ ...dataState, files: remainingFiles, rejectedFiles: updatedList })
        }
    };

    const deleteRejectedFile = rejectedFile => {
        let updatedList = dataState.rejectedFiles.filter(el => el !== rejectedFile);
        setDataState({ ...dataState, rejectedFiles: updatedList });
    };

    const image = files.map(file => (
        <div className={classes.thumb} key={file.name}>
            <div className={classes.thumbInner}>
                <img
                    src={file.preview}
                    className={classes.imgNewEquipment}
                />
            </div>
        </div>
    ));


    const handleChange = (name, value) => {
        setValues({ ...values, [name]: value });
    };

    const handleChangeSerialNumber = event => {
        if (event.target.value.length <= 50) {
            setRequiredSN(false);
            //setRequiredCode(false);
            setSerialAlreadyTaken(false);
            const data_SN = allSerialNumbers.filter(item => item && item.trim() === event.target.value.trim());
            if (data_SN.length !== 0) {
                setTitle(t("INVENTORY.serialNumberAlreadyTaken"));
                setSerialAlreadyTaken(true);
            } else {
                setTitle(t("GENERAL.requiredField"));
            }
            setValues({ ...values, serialNumber: event.target.value });
        }
    };

    const handleNumber = (name, value) => {
        if (!isNaN(value)) {
            setValues({ ...values, [name]: value });
        }
    };

    const addSubItem = () => {
        setOpenSubItem(true);
    }

    const verifyCanSubmit = () => {
        let res = true;

        if (!values.name) {
            res = false;
            setRequiredName(true);
        }
        if (!values.projectId) {
            res = false;
            setRequiredProject(true);
        }
        if (!values.acquisitionDate) {
            res = false;
            setRequiredDateAcquisition(true);
        }
        if (!values.placeId) {
            res = false;
            setRequiredPlace(true);
        }
        if (!values.responsibleId && !values.responsibleExternalId) {
            res = false;
            setRequiredResponsible(true);
        }
        if (!values.statusId) {
            res = false;
            setRequiredStatus(true);
        }
        if (selectedValue === "a" && !values.serialNumber && (codes.every(x => x.code === "" || x.type === "") || codes.some(x => x.category === 'externalCode' && x.partner === ''))) {
            res = false;
            setRequiredCode(true);
            setRequiredSN(true);
        }
        if (!values.categoryId) {
            res = false;
            setRequiredCategory(true);
        }
        if (serialAlreadyTaken && selectedValue === "a") {
            res = false;
        }
        if (requiredCode && selectedValue === "a") {
            res = false;
        }

        return res;
    };

    const getAllBatchEquipment = (formData) => {
        if (formData) {
            // Adding documents
            for (let i in dataState.files) {
                formData.append("files", dataState.files[i]);
            }

            // Adding equipment image
            if (files[0]) {
                formData.append("image", files[0]);
            }

            // Email
            var emailDatas = []
            var auxEmail = {}

            var project = projectsArray.filter(project =>
                values.projectId == project.value
            )

            var category = categoriesArray.filter(category =>
                values.categoryId == category.value
            )

            auxEmail.user = usersEmail
            auxEmail.nameEquipment = values.name
            auxEmail.project = project[0].label
            auxEmail.category = category[0].label
            auxEmail.brand = values.brand ? values.brand : "-"
            auxEmail.date = convertDate(new Date());
            auxEmail.operator = auth.name
            auxEmail.amountEquipments = amountEquipments
            auxEmail.urlBatch = process.env.REACT_APP_FRONT + "InventoryEquipment" + "?&" + values.name + "&" + project[0].label + "&" + new Date()
            emailDatas.push(auxEmail)
            var auxEmail = {}


            // Email Change of Owner
            var emailDataAssignment = []
            var auxEmailAssignment = {}

            var user = users.filter(user =>
                values.responsibleId === user.id
            )
            auxEmailAssignment.type = "EQUIPMENT";
            auxEmailAssignment.date = dateToBR(new Date());
            auxEmailAssignment.operatorName = auth.name;
            auxEmailAssignment.name = values.responsible
            auxEmailAssignment.user = user.map(user => {
                return {
                    email: user.email,
                    name: user.name
                }
            })
            let newPlace = placesArray.filter(place => values.placeId === place.value);
            auxEmailAssignment.local = [
                [
                    "-",
                    newPlace[0].label
                ]
            ]
            auxEmailAssignment.itens = [[
                values.name,
                category[0].label,
                "-",
                values.serialNumber ? values.serialNumber : "-"
            ]]
            emailDataAssignment.push(auxEmailAssignment)
            var auxEmailAssignment = {}

            addEquipment(formData, [], emailDatas, emailDataAssignment);
            setMustRedirect(true);
        }
    };

    const formatDate = (date) => {
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getUTCHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    }

    const formatCodeLSBD = (code) => {
        let res = "";
        if (code) {
            const value = ("00000" + code);
            return value.substr(value.length - 5);
        }
        return res;
    }

    const submit = () => {
        const canSubmit = verifyCanSubmit();
        if (canSubmit) {
            if (selectedValue === "b" && amountEquipments >= 2) {
                setOpenBatchAdd(true);
            } else {
                const fcpcCode = codes.find(x => x.type === 'fcpcCode');
                const ufcCode = codes.find(x => x.type === 'ufcCode');
                const lsbdCode = codes.find(x => x.type === 'lsbdCode');
                const externalCode = codes.find(x => x.type === 'externalCode');

                let formData = new FormData();
                formData.append('items[0].name', values.name);
                formData.append('items[0].brand', values.brand);
                formData.append('items[0].categoryId', values.categoryId);
                formData.append('items[0].fcpcCode', fcpcCode ? fcpcCode.code : "");
                formData.append('items[0].externCode', externalCode ? externalCode.code : "");
                formData.append('items[0].ufcCode', ufcCode ? ufcCode.code : "");
                formData.append('items[0].lsbdCode', lsbdCode ? formatCodeLSBD(lsbdCode.code) : "");
                formData.append('items[0].projectId', values.projectId);
                formData.append('items[0].serialNumber', values.serialNumber);
                formData.append('items[0].acquisitionDate', formatDate(values.acquisitionDate));
                formData.append('items[0].invoice', values.invoice);
                formData.append('items[0].value', values.value);
                formData.append('items[0].itemStatusId', values.statusId);
                formData.append('items[0].placeId', values.placeId);
                formData.append('items[0].responsibleId', values.responsibleId);
                formData.append('items[0].description', values.observation);
                formData.append('items[0].detailedDescription', values.description);
                formData.append('items[0].responsibleExternalId', values.responsibleExternalId);
                formData.append('items[0].responsibleDescription', values.responsibleDescription);
                formData.append('items[0].warrantyData', values.warrantyDate ? formatDate(values.warrantyDate) : "");
                formData.append('items[0].externPartner', externalCode ? externalCode.partner.toUpperCase() : "");
                formData.append('items[0].class', "inventory.Item");
                formData.append('items[0].inventoryItemHistory[0].title', "Primeiro Status"); // "Primeiro Status" is the database default value for creating a status record.
                formData.append('items[0].inventoryItemHistory[0].itemStatusId', values.statusId);
                formData.append('items[0].inventoryItemHistory[0].date', formatDate(new Date()));
                formData.append('items[0].inventoryItemHistory[0].description', values.statusDescription);
                formData.append('items[0].inventoryItemHistory[0].operation', "Primeiro Status");
                formData.append('items[0].inventoryItemHistory[0].executorId', auth.id);
                formData.append('items[0].inventoryPlaceHistory[0].operation', "Primeiro Local"); // "Primeiro Status" is the database default value for creating a status record.
                formData.append('items[0].inventoryPlaceHistory[0].placeId', values.placeId);
                formData.append('items[0].inventoryPlaceHistory[0].executorId', auth.id);
                formData.append('items[0].inventoryPlaceHistory[0].date', formatDate(new Date()));
                formData.append('items[0].inventoryResponsibleHistory[0].operation', "Primeiro Responsável"); // "Primeiro Status" is the database default value for creating a status record.
                formData.append('items[0].inventoryResponsibleHistory[0].userId', values.responsibleId);
                formData.append('items[0].inventoryResponsibleHistory[0].userExternalId', values.responsibleExternalId);
                formData.append('items[0].inventoryResponsibleHistory[0].executorId', auth.id);
                formData.append('items[0].inventoryResponsibleHistory[0].date', formatDate(new Date()));

                // Adding documents
                for (let i in dataState.files) {
                    formData.append("files", dataState.files[i]);
                }

                // Adding equipment image
                if (files[0]) {
                    formData.append("image", files[0]);
                }

                // Email
                var emailDates = []
                var auxEmail = {}

                var project = projectsArray.filter(project =>
                    values.projectId == project.value
                )

                var category = categoriesArray.filter(category =>
                    values.categoryId == category.value
                )
                auxEmail.user = usersEmail
                auxEmail.nameEquipment = values.name
                auxEmail.project = project[0].label
                auxEmail.category = category[0].label
                auxEmail.amountEquipments = 1
                auxEmail.brand = values.brand ? values.brand : "-"
                auxEmail.date = convertDate(new Date());
                auxEmail.operator = auth.name
                emailDates.push(auxEmail)
                var auxEmail = {}


                // Email Change of Owner
                var emailDataAssignment = []
                var auxEmailAssignment = {}

                var user = users.filter(user =>
                    values.responsibleId === user.id
                )
                auxEmailAssignment.type = "EQUIPMENT";
                auxEmailAssignment.date = dateToBR(new Date());
                auxEmailAssignment.operatorName = auth.name;
                auxEmailAssignment.name = values.responsible
                auxEmailAssignment.user = user.map(user => {
                    return {
                        email: user.email,
                        name: user.name
                    }
                })
                let newPlace = placesArray.filter(place => values.placeId === place.value);
                auxEmailAssignment.local = [
                    [
                        "-",
                        newPlace[0].label
                    ]
                ]
                auxEmailAssignment.itens = [[
                    values.name,
                    category[0].label,
                    ufcCode ? "UFC-" + ufcCode.code : fcpcCode ? "FCPC-" + fcpcCode.code : lsbdCode ? "LSBD-" + formatCodeLSBD(lsbdCode.code) : externalCode ? externalCode.partner + "-" + externalCode.code : "-",
                    values.serialNumber ? values.serialNumber : "-"
                ]]
                emailDataAssignment.push(auxEmailAssignment)
                var auxEmailAssignment = {}

                addEquipment(formData, subitensFilter, emailDates, emailDataAssignment);
                setMustRedirect(true);
            }
        }
    };

    {
        subitensFilter = equipment.filter(
            item =>
                arraySubItens && arraySubItens.includes(item.id))
    }

    const redirect = go => {
        if (go) return <Redirect to={`/InventoryEquipment`} />;
    };

    const handleFieldChangeValue = (name, value) => {
        if (value !== " " && !value.includes(" ")) {
            const str = value.split("");
            let aux = str.filter(el => el !== "." && el !== ",").join("");
            let valueFormatted = value;
            if (value) {
                valueFormatted = converTotBrCurrency(
                    aux
                        .split("")
                        .reverse()
                        .join("")
                );
            }
            aux = valueFormatted
                .split("")
                .filter(el => el !== ".")
                .join("")
                .replace(",", ".");
            if (!isNaN(aux)) {
                setValues({ ...values, [name]: valueFormatted });
            }
        }
    };

    const converTotBrCurrency = number => {
        var temp = "";
        var len = number.length;
        var count = 0;

        for (var i = 0; i < len; i++) {
            if (i === 1) {
                var aux = "," + number[i] + temp;
                temp = aux;
            } else {
                if (count === 3) {
                    count = 1;
                    var aux = number[i] + "." + temp;
                    temp = aux;
                } else {
                    if (i > 1) {
                        count += 1;
                    }
                    var aux = number[i] + temp;
                    temp = aux;
                }
            }
        }

        return temp;
    };

    const getCategoriesFiltered = (indexField) => {
        const rowsField = [...codes];
        const field = rowsField[indexField];

        if (field) {
            return categoriesCode.filter(cat =>
                (
                    cat.key !== "internalCode" ||
                    cat.key === field.category ||
                    rowsField.filter(x => x.category === "internalCode").length < 3
                ) &&
                (
                    cat.key !== "externalCode" ||
                    cat.key === field.category ||
                    !rowsField.some(x => x.category === "externalCode")
                )
            );
        }

        return [];
    };

    const getInternalOriginsFiltered = (indexField) => {
        const rowsField = [...codes];
        const field = rowsField[indexField];

        if (field) {
            return internalOrigins.filter(cat => cat.key === field.type || !rowsField.some(x => x.type === cat.key));
        }

        return [];
    };

    const handleChangeSelector = (indexField) => event => {
        const rowsField = [...codes];
        const field = rowsField[indexField];
        if (field) {
            setRequiredCode(false);
            setRequiredSN(false);
            field.type = event.target.value;

            const next = nextCodes[event.target.value];
            if (next) {
                field.code = next.toString();
            }
            setCodes(rowsField);
        }
    };

    const handleChangePartner = (indexField, newValue) => {
        const rowsField = [...codes];
        const field = rowsField[indexField];
        if (field) {
            setRequiredCode(false);
            setRequiredSN(false);
            field.partner = newValue ? newValue : "";

            if (newValue) {
                const next = nextCodes[newValue.toLowerCase()];
                if (next) {
                    field.code = next.toString();
                }
            }

            setCodes(rowsField);
        }
    };

    const handleChangeCategory = (indexField) => event => {
        const rowsField = [...codes];
        const field = rowsField[indexField];
        if (field) {
            field.category = event.target.value;
            field.partner = "";

            if (event.target.value === "externalCode") {
                field.type = "externalCode";
            } else {
                field.type = "";
            }
            setCodes(rowsField);
        }
    };

    const handleChangeField = (indexField) => event => {
        const rowsField = [...codes];
        const field = rowsField[indexField];
        if (field.type === "ufcCode") {
            const dataUFCCodes = allUfcCodes.filter(item => item && item.trim() === event.target.value.trim());

            if (dataUFCCodes.length !== 0) {
                setRequiredCode(true);
                setTitleCodes(t("INVENTORY.codeAlreadyTaken"));
            } else {
                setRequiredCode(false);
                setTitleCodes(t("GENERAL.requiredField"));
            }
        }
        if (field.type === "lsbdCode") {
            const dataLsbdCodes = allLsbdCodes.filter(item => item && item.trim() === "00" + event.target.value.trim());


            if (dataLsbdCodes.length !== 0) {
                setRequiredCode(true);
                setTitleCodes(t("INVENTORY.codeAlreadyTaken"));
            } else {
                setRequiredCode(false);
                setTitleCodes(t("GENERAL.requiredField"));
            }
        }
        if (field.type === "fcpcCode") {
            const dataUfcpCodes = allFcpcCodes.filter(item => item && item.trim() === event.target.value.trim());

            if (dataUfcpCodes.length !== 0) {
                setRequiredCode(true);
                setTitleCodes(t("INVENTORY.codeAlreadyTaken"));
            } else {
                setRequiredCode(false);
                setTitleCodes(t("GENERAL.requiredField"));
            }
        }
        if (field.type === "externalCode") {
            const dataExternCodes = allExternCodes.filter(item => item && item.trim() === event.target.value.trim());
            if (dataExternCodes.length !== 0) {
                setRequiredCode(true);
                setTitleCodes(t("INVENTORY.codeAlreadyTaken"));
            } else {
                setRequiredCode(false);
                setTitleCodes(t("GENERAL.requiredField"));
            }
        }

        if (!isNaN(event.target.value) && field && event.target.value.length <= 50) {
            setRequiredSN(false);
            field.code = event.target.value;
            setCodes(rowsField);
        }
    };

    const addStage = () => {
        const rowsField = [...codes];
        const category = rowsField.filter(x => x.category === "internalCode").length < 3 ? "internalCode" : "externalCode";
        if (rowsField.length < 4) {
            rowsField.push({
                code: "",
                type: category === "externalCode" ? "externalCode" : "",
                category: category,
                partner: "",
            });
        }
        setCodes(rowsField);
    }

    const removeStage = (indexField) => () => {
        const rowsField = [...codes];
        if (rowsField.length > 1) {
            rowsField.splice(indexField, 1);
        }
        setCodes(rowsField);
    }

    function addZero(i) {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    }

    const convertDate = date => {
        var year = date.getFullYear();
        var month = date.getMonth();
        var day = date.getDate();
        var hour = addZero(date.getHours());
        var minute = addZero(date.getMinutes());

        return ` ${day}/${month}/${year} - ${hour}:${minute}`;
    };

    const handleAmountInput = (event) => {
        setAmountEquipments((Number(event.target.value.replace(/[^0-9]/g, ""))));
    }

    return transitions.map(({ item, key, props }) =>
        item ? (
            <animated.div key={key} style={props}>
                <Spinner />;
            </animated.div>
        ) : (
            <animated.div key={key} style={props}>
                {redirect(mustRedirect)}
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={snackBar.open}
                    onClose={handleSnackClose}
                    TransitionComponent={Slide}
                    transitionDuration={{ enter: 500, exit: 500 }}
                    ContentProps={{
                        "aria-describedby": "message-id"
                    }}
                    autoHideDuration={2000}
                >
                    <MySnackbarContentWrapper
                        onClose={handleSnackClose}
                        variant={
                            responseType !== ""
                                ? responseType.toLowerCase()
                                : allowanceResponseType.toLowerCase()
                        }
                        message={
                            <span id="message-id">
                                {response !== "" ? response : allowanceResponse}
                            </span>
                        }
                    />
                </Snackbar>
                <div className="container">
                    <Breadcrumbs
                        className={classes.breadCrumbs}
                        aria-label="breadcrumb"
                        separator={<NavigateNextIcon fontSize="small" />}
                    >
                        <Typography>{t("INVENTORY.inventory")}</Typography>
                        <Link className={classes.link} to="/InventoryEquipment">
                            <Typography>{t("INVENTORY.equipments")}</Typography>
                        </Link>
                        <Typography>{t("INVENTORY.addEquipment")}</Typography>
                    </Breadcrumbs>
                    <div className="container-header">
                        <div className="title">
                            <h1 className={classes.title}>{t("INVENTORY.addEquipment")}</h1>
                        </div>
                        <Button
                            className={classes.blueButton}
                            variant="contained"
                            color="primary"
                            onClick={submit}
                        >
                            {t("INVENTORY.add")}
                        </Button>
                    </div>

                    <Paper >
                        <div className="cabecalho">
                            {t("INVENTORY.typeOfRegistration").toUpperCase()}
                        </div>
                        <div style={{ marginLeft: "5px", marginTop: "5px" }}>
                            <FormLabel component="legend" style={{ marginLeft: "12px" }}>{t("INVENTORY.typeOfRegistration")}</FormLabel>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={2}>
                                    <Radio
                                        checked={selectedValue === 'a'}
                                        color="primary"
                                        onChange={handleChangeRadio}
                                        value="a"
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': 'A' }}
                                        label="End"
                                    /> <span className={classes.projectInformation2}>Único Equipamento</span>
                                </Grid>
                                <Grid item xs={12} sm={1}>
                                    <Radio
                                        checked={selectedValue === 'b'}
                                        color="primary"
                                        onChange={handleChangeRadio}
                                        value="b"
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': 'B' }}
                                        label="Top"
                                        labelPlacement="top"
                                    /> <span className={classes.projectInformation2}>Lote</span>
                                </Grid>
                                {selectedValue === "b" &&
                                    <Grid item xs={12} sm={1}>
                                        <TextField
                                            inputProps={{ type: 'text', min: "2" }}
                                            style={{ marginTop: "-15px" }}
                                            className={classes.textField}
                                            label={t("INVENTORY.qtdEquipment")}
                                            value={amountEquipments > 0 ? amountEquipments : ""}
                                            onChange={(event) => handleAmountInput(event)}
                                            margin="normal"
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </div>
                    </Paper>
                    <br></br>
                    <Paper >
                        <div className="cabecalho">
                            {t("INVENTORY.equipmentDatas").toUpperCase()}
                        </div>
                        <div className={classes.bodyNewEquipment}>
                            <Grid container spacing={3}>
                                <Grid item xs={2} className={classes.backgroundImage}>
                                    <Grid item xs={2}>
                                        <Dropzone
                                            variant="outlined"
                                            component="span"
                                            className={classes.textField}
                                            onDrop={onDrop}
                                            multiple={false}
                                            accept="image/jpeg, 
                                                        image/png, 
                                                        image/bmp"
                                        >
                                            {({
                                                getRootProps,
                                                getInputProps,
                                                isDragActive,
                                                isDragReject
                                            }) => (
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    {!isDragActive && (
                                                        <FormControl
                                                            className={classes.customAttachedDocumentTextField}
                                                        >
                                                            {!hasUpdatedFile ?
                                                                <Grid item xs={2}>
                                                                    <div className={classes.attachEquipment}>{t("Carregar imagem")}</div>
                                                                    <div className={classes.thumb} key={"upload-equipament-image.jpg"}>
                                                                        <div className={classes.thumbInner}>
                                                                            <img className={classes.imgNewEquipment} alt="add-image" src={window.location.origin + '/img/upload-equipament-image.png'} />
                                                                        </div>
                                                                    </div>

                                                                </Grid>
                                                                :
                                                                <Grid item xs={2}>
                                                                    <div className={classes.attachEquipment}>{t("Alterar imagem")}</div>
                                                                    {image}
                                                                </Grid>
                                                            }
                                                        </FormControl>
                                                    )}
                                                    {isDragReject && (
                                                        <FormControl
                                                            className={classes.customAttachedDocumentTextField}
                                                        >
                                                            <Input
                                                                id="custom-attached-input-cooperation"
                                                                readOnly={true}
                                                                endAdornment={
                                                                    <InputAdornment
                                                                        style={{ transform: "rotate(270deg)" }}
                                                                        position="end"
                                                                    >
                                                                        <Attachment />
                                                                    </InputAdornment>
                                                                }
                                                                aria-describedby="weight-helper-text"
                                                                inputProps={{
                                                                    "aria-label": "weight"
                                                                }}
                                                                value={t(title)}
                                                            />
                                                        </FormControl>
                                                    )}
                                                </div>
                                            )}
                                        </Dropzone>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={7}>
                                    <Typography className={classes.titleNewEquipment}>
                                        Definição
                                    </Typography>
                                    <MuiThemeProvider theme={tooltipTheme}>
                                        <Tooltip
                                            TransitionComponent={Zoom}
                                            open={requiredName}
                                            title={t("GENERAL.requiredField")}
                                        >
                                            <TextField
                                                className={classes.textField}
                                                label={t("INVENTORY.nameOfItem") + "*"}
                                                value={values.name}
                                                onChange={(ev) => {
                                                    if (ev.target.value.length <= 200) {
                                                        setRequiredName(false)
                                                        handleChange("name", ev.target.value);
                                                    }
                                                }}
                                                margin="normal"
                                            />
                                        </Tooltip>
                                    </MuiThemeProvider>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                className={classes.textField}
                                                label={t("INVENTORY.brand")}
                                                value={values.brand}
                                                onChange={(ev) => { if (ev.target.value.length <= 50) handleChange("brand", ev.target.value) }}
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <MuiThemeProvider theme={tooltipTheme}>
                                                <Tooltip
                                                    TransitionComponent={Zoom}
                                                    open={requiredCategory}
                                                    title={t("GENERAL.requiredField")}
                                                >
                                                    <TextField
                                                        select
                                                        id="client"
                                                        label={t("INVENTORY.category") + "*"}
                                                        name="category"
                                                        value={values.categoryId}
                                                        onChange={(ev) => {
                                                            setRequiredCategory(false)
                                                            handleChange("categoryId", ev.target.value)
                                                        }}
                                                        className={classes.textField}
                                                        margin="normal"
                                                        classes={{
                                                            root: classes.inputRoot,
                                                            input: classes.inputInput
                                                        }}
                                                        inputProps={{ "aria-label": "search" }}
                                                    >
                                                        {categoriesArray.map(category => (
                                                            <MenuItem key={category.value} value={category.value}>
                                                                {category.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Tooltip>
                                            </MuiThemeProvider>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {selectedValue !== "b" &&
                                    <Grid item xs={12} sm={3}>
                                        Customização
                                        <Grid component="label" container alignItems="center" spacing={1}>
                                            <Grid item>
                                                <Switch
                                                    color="primary"
                                                    checked={checkedC}
                                                    onChange={() => setCheckedC(!checkedC)}
                                                    name="checkedC"
                                                />
                                            </Grid>
                                            <Grid item>Customizável</Grid>
                                        </Grid>
                                    </Grid>
                                }
                                {selectedValue !== "b" &&
                                    <React.Fragment>
                                        <Grid item xs={12} sm={12} style={{ paddingBottom: 0 }}>
                                            <Typography className={classes.titleNewEquipment}>
                                                Identificação
                                            </Typography>
                                        </Grid>

                                        {codes.map((field, index) =>
                                            <React.Fragment key={"identification" + index}>
                                                <Grid item xs={12} sm={3}>
                                                    <TextField
                                                        className={classes.textField}
                                                        select
                                                        label={t("GENERAL.identification") + "*"}
                                                        value={field.category}
                                                        onChange={handleChangeCategory(index)}
                                                        margin="normal"
                                                    >
                                                        {getCategoriesFiltered(index).map(code => (
                                                            <MenuItem key={code.key} value={code.key}>
                                                                {code.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>
                                                {
                                                    <Grid item xs={4} sm={4}>
                                                        <MuiThemeProvider theme={tooltipTheme}>
                                                            {field.category === "internalCode" ?
                                                                <Tooltip
                                                                    TransitionComponent={Zoom}
                                                                    open={requiredCode && !field.type}
                                                                    title={t("GENERAL.requiredField")}
                                                                >
                                                                    <TextField
                                                                        select
                                                                        label={t("GENERAL.origin") + "*"}
                                                                        margin="normal"
                                                                        onChange={handleChangeSelector(index)}
                                                                        className={classes.textField}
                                                                        classes={{
                                                                            root: classes.inputRoot,
                                                                            input: classes.inputInput
                                                                        }}
                                                                        inputProps={{ "aria-label": "search" }}
                                                                    >
                                                                        {getInternalOriginsFiltered(index).map(code => (
                                                                            <MenuItem key={code.key} value={code.key}>
                                                                                {code.label}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>
                                                                </Tooltip>
                                                                :
                                                                <Tooltip
                                                                    TransitionComponent={Zoom}
                                                                    open={requiredCode && !field.partner}
                                                                    title={t("GENERAL.requiredField")}
                                                                >
                                                                    <Autocomplete
                                                                        id="combo-box-demo"
                                                                        freeSolo
                                                                        options={externalPartners}
                                                                        value={field.partner}
                                                                        inputValue={field.partner}
                                                                        onChange={(event, newInputValue) => {
                                                                            handleChangePartner(index, newInputValue);
                                                                        }}
                                                                        onInputChange={(event, newInputValue) => {
                                                                            handleChangePartner(index, newInputValue);
                                                                        }}
                                                                        renderInput={(params) =>
                                                                            <TextField
                                                                                {...params}
                                                                                label={t("GENERAL.origin")}
                                                                                margin="normal"
                                                                                className={classes.textField}
                                                                                classes={{
                                                                                    root: classes.inputRoot,
                                                                                    input: classes.inputInput
                                                                                }}
                                                                            />
                                                                        }
                                                                    />
                                                                </Tooltip>
                                                            }
                                                        </MuiThemeProvider>
                                                    </Grid>
                                                }
                                                <Grid item xs={4} sm={4}>
                                                    <MuiThemeProvider theme={tooltipTheme}>
                                                        <Tooltip
                                                            TransitionComponent={Zoom}
                                                            open={requiredCode}
                                                            title={titleCodes}
                                                        >
                                                            <TextField
                                                                label={t("GENERAL.code") + "*"}
                                                                margin="normal"
                                                                classes={{
                                                                    root: classes.inputRoot,
                                                                    input: classes.inputInput
                                                                }}
                                                                value={field.code}
                                                                onChange={handleChangeField(index)}
                                                                className={classes.textField}
                                                                inputProps={{ "aria-label": "search" }}
                                                            />
                                                        </Tooltip>
                                                    </MuiThemeProvider>
                                                </Grid>
                                                {(codes.length > 1) &&
                                                    <Grid item xs={1} sm={1}>
                                                        <Tooltip
                                                            title="Remover código"
                                                            aria-label="remover"
                                                            placement="top"
                                                        >
                                                            <IconButton
                                                                style={{ float: "left", marginTop: 26 }}
                                                                onClick={removeStage(index)}
                                                                className={classes.icons}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                }
                                            </React.Fragment>
                                        )}
                                        {(codes.length < 4) &&
                                            <Grid item xs={12} sm={12}>
                                                <div onClick={addStage} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', width: 'max-content', }}>
                                                    <Tooltip
                                                        title="Adicionar outro código"
                                                        aria-label="adicionar"
                                                        placement="top"
                                                    >
                                                        <IconButton
                                                            style={{ float: "left" }}
                                                            className={classes.icons}
                                                        >
                                                            <AddCircle />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Typography>{t("GENERAL.add")}</Typography>
                                                </div>
                                            </Grid>
                                        }
                                        <Grid item xs={4} sm={4}>
                                            <MuiThemeProvider theme={tooltipTheme}>
                                                <Tooltip
                                                    TransitionComponent={Zoom}
                                                    open={requiredSN || serialAlreadyTaken}
                                                    title={titleName}
                                                >
                                                    <TextField
                                                        className={classes.textField}
                                                        label={t("INVENTORY.serialNumber") + "*"}
                                                        value={values.serialNumber}
                                                        onChange={handleChangeSerialNumber}
                                                        margin="normal"
                                                    />
                                                </Tooltip>
                                            </MuiThemeProvider>
                                        </Grid>
                                    </React.Fragment>
                                }
                                <Grid item xs={12} sm={12}>
                                    <Typography className={classes.titleNewEquipment}>
                                        Administração
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <MuiThemeProvider theme={tooltipTheme}>
                                        <Tooltip
                                            TransitionComponent={Zoom}
                                            open={requiredProject}
                                            title={t("GENERAL.requiredField")}
                                        >
                                            <TextField
                                                select
                                                id="client"
                                                label={t("INVENTORY.project") + "*"}
                                                name="projectId"
                                                className={classes.textField}
                                                value={values.projectId}
                                                onChange={(ev) => {
                                                    setRequiredProject(false);
                                                    handleChange("projectId", ev.target.value);
                                                }}
                                                margin="normal"
                                                classes={{
                                                    root: classes.inputRoot,
                                                    input: classes.inputInput
                                                }}
                                                inputProps={{ "aria-label": "search" }}
                                            >
                                                {projectsArray.map(project => (
                                                    <MenuItem key={project.value} value={project.value}>
                                                        {project.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Tooltip>
                                    </MuiThemeProvider>
                                </Grid>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid item xs={3}>
                                        <MuiThemeProvider theme={tooltipTheme}>
                                            <Tooltip
                                                TransitionComponent={Zoom}
                                                open={requiredDateAcquisition}
                                                title={t("GENERAL.requiredField")}
                                            >
                                                <KeyboardDatePicker
                                                    className={classes.textField}
                                                    disableToolbar
                                                    autoOk={true}
                                                    variant="inline"
                                                    format="dd/MM/yyyy"
                                                    margin="normal"
                                                    id="acquisitionDate"
                                                    label={t("INVENTORY.dateAcquisition") + "*"}
                                                    value={values.acquisitionDate}
                                                    onChange={(ev) => {
                                                        setRequiredDateAcquisition(false);
                                                        handleChange("acquisitionDate", ev);
                                                    }}
                                                    minDateMessage={
                                                        "A data não deve ser anterior à data mínima"
                                                    }
                                                    KeyboardButtonProps={{
                                                        "aria-label": "change date"
                                                    }}
                                                />
                                            </Tooltip>
                                        </MuiThemeProvider>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <MuiThemeProvider theme={tooltipTheme}>
                                            <KeyboardDatePicker
                                                className={classes.textField}
                                                disableToolbar
                                                autoOk={true}
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id="warrantyDate"
                                                label={t("INVENTORY.warrantyData")}
                                                value={values.warrantyDate}
                                                onChange={(ev) => {
                                                    setRequiredDateWarranty(false);
                                                    handleChange("warrantyDate", ev);
                                                }}
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date"
                                                }}
                                                minDateMessage={
                                                    "A data não deve ser anterior à data mínima"
                                                }
                                            />
                                        </MuiThemeProvider>
                                    </Grid>
                                </MuiPickersUtilsProvider>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        className={classes.textField}
                                        label={t("INVENTORY.invoice")}
                                        value={values.invoice}
                                        onChange={(ev) => { if (ev.target.value.length <= 50) handleNumber("invoice", ev.target.value) }}
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        className={classes.textField}
                                        label={t("INVENTORY.value")}
                                        value={values.value}
                                        onChange={(ev) => { if (ev.target.value.length <= 20) handleFieldChangeValue("value", ev.target.value) }}
                                        margin="normal"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    R$
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Typography className={classes.titleNewEquipment}>
                                        Alocação
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <MuiThemeProvider theme={tooltipTheme}>
                                        <Tooltip
                                            TransitionComponent={Zoom}
                                            open={requiredPlace}
                                            title={t("GENERAL.requiredField")}
                                        >
                                            <TextField
                                                select
                                                id="client"
                                                label={t("INVENTORY.place") + "*"}
                                                name="Place"
                                                className={classes.textField}
                                                value={values.placeId}
                                                onChange={(ev) => {
                                                    setRequiredPlace(false);
                                                    handleChange("placeId", ev.target.value)
                                                }}
                                                margin="normal"
                                                classes={{
                                                    root: classes.inputRoot,
                                                    input: classes.inputInput
                                                }}
                                                inputProps={{ "aria-label": "search" }}
                                            >
                                                {placesArray.map(places => (
                                                    <MenuItem key={places.value} value={places.value}>
                                                        {places.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Tooltip>
                                    </MuiThemeProvider>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <MuiThemeProvider theme={tooltipTheme}>
                                        <Tooltip
                                            TransitionComponent={Zoom}
                                            open={requiredResponsible}
                                            title={t("GENERAL.requiredField")}
                                        >
                                            <TextField
                                                id="client"
                                                variant="outlined"
                                                label={t("INVENTORY.responsible") + "*"}
                                                name="responsible"
                                                autoComplete="off"
                                                className={classes.textField}
                                                value={values.responsible}
                                                onClick={(ev) => {
                                                    ev.target.blur();
                                                    setOpenResponsible(true);
                                                    setRequiredResponsible(false)
                                                }}
                                                margin="normal"
                                                classes={{
                                                    root: classes.inputRoot,
                                                    input: classes.inputInput
                                                }}
                                                inputProps={{ "aria-label": "search" }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="end">
                                                            <Edit />
                                                        </InputAdornment>
                                                    )
                                                }}
                                            >

                                            </TextField>
                                        </Tooltip>
                                    </MuiThemeProvider>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <MuiThemeProvider theme={tooltipTheme}>
                                        <Tooltip
                                            TransitionComponent={Zoom}
                                            open={requiredStatus}
                                            title={t("GENERAL.requiredField")}
                                        >
                                            <TextField
                                                variant="outlined"
                                                id="statusId"
                                                label={t("INVENTORY.status")}
                                                name="statusId"
                                                className={classes.textField}
                                                value={values.statusName}
                                                margin="normal"
                                                classes={{
                                                    root: classes.inputRoot,
                                                    input: classes.inputInput
                                                }}
                                                onClick={(ev) => {
                                                    ev.target.blur();
                                                    setOpenStatus(true);
                                                }}
                                                inputProps={{ "aria-label": "search" }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="end">
                                                            <Edit />
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Tooltip>
                                    </MuiThemeProvider>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        multiline
                                        fullWidth
                                        variant="outlined"
                                        rows="6"
                                        id="description"
                                        value={values.description}
                                        onChange={(ev) => { if (ev.target.value.length <= 500) handleChange("description", ev.target.value) }}
                                        label={t("INVENTORY.descriptionBookCategory")}
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        multiline
                                        fullWidth
                                        variant="outlined"
                                        rows="6"
                                        id="description"
                                        value={values.observation}
                                        onChange={(ev) => { if (ev.target.value.length <= 500) handleChange("observation", ev.target.value) }}
                                        label={t("INVENTORY.observation")}
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid container item spacing={1} md={12} lg={12}>
                                    <Grid item xs={12}>
                                        <Dropzone onDrop={onDropFiles}
                                            accept="application/pdf, 
                                                application/msword, 
                                                application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                                image/jpeg, 
                                                image/png"
                                        >
                                            {({ getRootProps, getInputProps }) => (
                                                <section>
                                                    <div {...getRootProps({ className: classes.dropzoneDialog })}>
                                                        <input {...getInputProps()} />
                                                        <AttachFileIcon />
                                                        <Typography>Arraste e solte os documentos para anexá-los ou <u>clique aqui</u></Typography>
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant={"body1"} style={{ fontWeight: "bold" }}>Documentos</Typography>
                                        {dataState.rejectedFiles.length > 0 &&
                                            <div style={{ paddingBottom: 8 }}>
                                                {dataState.rejectedFiles.map(message => (
                                                    <div className={classes.dialogFile}>
                                                        <div className={classes.textFile}>
                                                            <Typography style={{ color: "red" }} className={classes.nameFile}>{message}</Typography>
                                                        </div>
                                                        <div className={classes.iconsFile}>
                                                            <IconButton onClick={() => deleteRejectedFile(message)}>
                                                                <Clear />
                                                            </IconButton>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                        {dataState.files.length > 0 ?
                                            <div className={classes.dropzoneDialogFiles}>
                                                {dataState.files.map(file => (
                                                    <div className={classes.dialogFile}>
                                                        <div className={classes.textFile}>
                                                            <Typography className={classes.nameFile}>{file.fileName ? file.fileName : file.name}</Typography>
                                                            <Typography>{`(${humanFileSize(file.size)})`}</Typography>
                                                        </div>
                                                        <div className={classes.iconsFile}>
                                                            <IconButton onClick={() => onClickDownload(file)}>
                                                                <GetAppIcon />
                                                            </IconButton>
                                                            {/* {dialogMode !== t("GENERAL.viewMode") && */}
                                                            <IconButton onClick={() => onRemoveFile(file)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                            {/* } */}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div> :
                                            <div className={classes.noFilesMessage}>
                                                <Typography variant={"h6"} style={{ fontWeight: "bold" }}>{t("EXPENSES.noFilesMessage")}</Typography>
                                                <Typography variant={"caption"}>{t("EXPENSES.noFilesSubMessage")}</Typography>
                                            </div>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Paper>
                    <br></br>
                    {checkedC && selectedValue !== "b" &&
                        <Paper >
                            <div className="cabecalho">
                                {t("INVENTORY.subItens").toUpperCase()}
                                <Tooltip
                                    title="Adicionar subitem"
                                    aria-label="adicionar"
                                    placement="top"
                                >
                                    <IconButton
                                        style={{ float: "right" }}
                                        onClick={addSubItem}
                                        className={classes.icons}
                                    >
                                        <AddCircle />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            {subitensFilter.length > 0 ?
                                <SubItensTable
                                    rows={subitensFilter}
                                    headers={["id", "name", "responsible", "lsbdCode", "fcpcCode", "ufcCode", "place", "brand", "externalCode", "serialNumber", "class", "acquisitionDate", "category", "fileUserExternal", "description", "lastMaintenance", "warrantyData", "libraryExemplar", "project", "itemStatus"]}
                                    visibleHeaders={["nome", "localização", "responsável", "código de identificação", "projeto"]}
                                    sortedFields={["name", "place", "responsible", "ufcCode", "project"]}
                                    mainField="name"
                                />
                                :
                                <div className={classes.noFilesMessage}>
                                    <Typography variant={"h6"} style={{ fontWeight: "bold" }}>{t("INVENTORY.noSubitensMessage")}</Typography>
                                    <Typography variant={"caption"}>{t("INVENTORY.noSubitensSubMessage")}</Typography>
                                </div>
                            }
                        </Paper>
                    }
                </div>
                {openBatchAdd &&
                    <DialogBatchAdd open={openBatchAdd} setOpen={setOpenBatchAdd} amountEquipments={amountEquipments} values={values} callBackGetEquipment={getAllBatchEquipment} externalOrigin={externalPartners} nextCodes={nextCodes} allSerialNumbers={allSerialNumbers} allLsbdCodes={allLsbdCodes} allUfcCodes={allUfcCodes} allFcpcCodes={allFcpcCodes} allExternCodes={allExternCodes} />
                }
                <DialogStatus
                    open={openStatus}
                    setOpen={setOpenStatus}
                    currentValue={"-"}
                    allStatus={allStatus}
                    nameValue={values.statusName}
                    value={values.statusId}
                    description={values.statusDescription}
                    handleUpdate={(id, name, description) => {
                        setValues({ ...values, statusId: id, statusName: name, statusDescription: description })
                    }}
                />
                <DialogResponsible
                    type={"new"}
                    open={openResponsible}
                    setOpen={setOpenResponsible}
                    currentResponsible={values.currentResponsible}
                    currentType={values.currentTypeUser}
                    allResponsible={usersArray}
                    allExternalResponsible={externalUsersArray}
                    places={placesArray}
                    externalPlaces={externalUserPlaces}
                    respName={values.responsible}
                    valueExtern={values.responsibleExternalId}
                    description={values.responsibleDescription}
                    handleUpdate={(responsibleId, responsibleExternalId, responsibleDescription, reponsiblePlace, responsibleName) => {
                        setValues({
                            ...values,
                            responsibleId: responsibleId,
                            responsibleExternalId: responsibleExternalId,
                            responsibleDescription: responsibleDescription,
                            responsible: responsibleName,
                            placeId: responsibleExternalId ? reponsiblePlace : values.placeId,
                        })
                    }}
                />
                <DialogSubItem open={openSubItem} setOpen={setOpenSubItem} amountEquipments={amountEquipments} values={values} categoriesArray={categoriesArray} equipmentArray={equipmentArray} addSubItensArray={addSubItensArray} />
                <Footer />
            </animated.div >
        )
    );
};

export default Equipment;