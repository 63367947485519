import React from "react";
import ReactLoading from "react-loading";
import { animated, useTransition } from "react-spring";
import "../styles.modules.css";
import { Section } from "./Generics";

const Index = () => {
  // Source: https://codesandbox.io/s/react-loading-demo-g5ukf
  // styles: http://tachyons.io/docs/layout/spacing/ https://www.npmjs.com/package/tachyons-components
  // animações: https://css-tricks.com/building-a-complex-ui-animation-in-react-simply/

  const transitions = useTransition(true, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  });

  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <animated.div key={key} style={props}>
          <div className="spinner">
            <Section>
              <ReactLoading
                type={"spin"}
                color="#2c3e51"
                height={90}
                width={90}
              />
            </Section>
          </div>
        </animated.div>
      )
  );
};

export default Index;
