import { makeStyles } from '@material-ui/core/styles';

export const screenStyles = makeStyles(theme => ({
    formWrapper: {
        padding: '24px'
    },
    bookFormWrapper: {
        display: 'flex',
        padding: '32px 0px'
    },
    bookFormWrapperViewAndEdit: {
        display: 'flex',
    },
    bookForm: {
        width: '100%',
        marginLeft: '16px'
    }
}));
