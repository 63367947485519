import { makeStyles } from '@material-ui/core/styles';

export const typographyStyles = makeStyles(theme => ({
    RobotoLight: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 300,
        margin: '0px'
        // lineHeight: "14px",
    },
    RobotoRegular: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        margin: '0px'
        // lineHeight: "14px",
    },
    RobotoSemiBold: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 600,
        margin: '0px'
        // lineHeight: "14px",
    },
    RobotoBold: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 700,
        margin: '0px'
        // lineHeight: "14px",
    },
    link: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        margin: '0px',
        textDecoration: 'underline',
        '&:hover': {
            cursor: 'pointer'
        }

        // lineHeight: "14px",
    }
}));
