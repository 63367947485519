import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import history from "../../../history";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import Buttons from "components/buttons/Pressed";
import { getOpenRequestPermission } from "../../../utils/permissions/OpenRequestPermission";
import { GetGeneralPermissionForAction } from "utils/permissions/GetGeneralPermissionForAction";
import { Screen, Allow } from "global/constants";

var item = []
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  cardContent: {
    height: "100%"
  },
  information: {
    marginTop: 16,
  },
  paper: {
    height: 216,
    marginLeft: "10px",
    textAlign: 'center',
    color: theme.palette.text.secondary,
    overflow: "hidden"
  },
  paperContent: {
    display: "flex",
    height: "100%"
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  backgroundImage: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  codeId: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    color: "##6D6F71",
  },
  btn: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#2D9CDB"
  },
  iconReq: {
    marginLeft: "10px",
    opacity: "0",
    backgroundColor: "#6D6F71",
    color: "#FFFFFF;"
  },
  Title: {
    fontFamily: "Roboto",
    fontSize: "30px",
    textAlign: "left",
    color: "#000000",
    fontWeight: "bold"
  },
  imageView: {
    width: 180,
    height: 180,
  },
  imgView: {
    margin: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
  },

  title: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#000000",
    opacity: "0.54",
  },
  codeId: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    color: "##6D6F71",
  },
  textField: {
    display: "flex",
  },
  textFieldCustum: {
    display: "flex",
    marginLeft: "10px"
  },
  boxRoot: {
    textAlign: 'left',
  },
  textRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 16
  }
}));

const AutoGrid = props => {
  const classes = useStyles();
  const [itensGrid, setItensGrid] = useState([]);
  const [gridLength, setGridLength] = useState(0);
  const [open, setOpen] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState({});
  const [headers, setHeaders] = useState([]);
  const [mainField, setMainField] = useState("");
  const [visibleHeaders, setVisibleHeaders] = useState([]);
  const [sortedFields, setSortedFields] = useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [t] = useTranslation();

  React.useEffect(() => {
    setItensGrid(props.data);
    setGridLength(props.data.length);
    setHeaders(props.headers);
    setMainField(props.mainField);
    setVisibleHeaders(props.visibleHeaders);
    setSortedFields(props.sortedFields);
  }, [props.headers, props.mainField, props.data, props.visibleHeaders]);


  itensGrid.sort(function (a, b) {
    if (a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase() < b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase()) { return -1; }
    if (a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase() > b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase()) { return 1; }
    return 0;
  })

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = () => {
    setOpen(false);
  };

  const handleOpenRequisitionClick = () => {
    setOpen(false);
    history.push("Requisition/new");

  };

  const getPermissionOpenRequisition = () => {
    if (localStorage.getItem("auth-token") !== null) {
      var auth = JSON.parse(localStorage.getItem("auth-token"));
      const permission = auth.permissionsByModule;

      // Get the user profile rule for the people management module
      const peopleManagement = permission.filter(el => el.systemId == 6905701);
      // Get the user profile rule for the inventory module
      const inventory = permission.filter(el => el.systemId == 6910301);

      // Checks whether the user is an administrative manager in the people management module and an administrator in the assets module 
      if (inventory[0].roleId === 6905151) {
        return true;
      } else {
        return false;
      }
    }
  }

  function dateToEN(date) {
    var dateAcquisition = new Date(date);
    dateAcquisition.setMinutes(dateAcquisition.getMinutes() + dateAcquisition.getTimezoneOffset())
    return dateAcquisition.getDate() + "/" + (dateAcquisition.getMonth() + 1) + "/" + dateAcquisition.getFullYear();
  }

  function doTruncarStr(str, size) {
    if (
      str == undefined ||
      str == "undefined" ||
      str == "" ||
      size == undefined ||
      size == "undefined" ||
      size == ""
    ) {
      return str;
    }
    var shortText = str;

    if (str.length >= size + 3) {
      shortText = str.substring(0, size).concat("...");
    }
    return shortText;
  }

  function openModal(e) {
    const index = e.currentTarget
    const id = index.getElementsByTagName("img")[0].id
    for (var i = 0; i < itensGrid.length; i++) {
      if (itensGrid[i].id === parseInt(id)) {
        item = itensGrid[i]
      }
    }
    setOpen(true);
  }

  function desc(a, b, orderBy) {
    // .normalize("NFD").replace(/[\u0300-\u036f]/g, "") : desconsidera
    // acentos
    if (a[orderBy] === null) a[orderBy] = "";
    if (b[orderBy] === null) b[orderBy] = "";

    if (
      orderBy === "place"
    ) {
      if (
        b[orderBy].name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase() <
        a[orderBy].name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase()
      ) {
        return -1;
      }
      if (
        b[orderBy].name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase() >
        a[orderBy].name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase()
      ) {
        return 1;
      }
    } else {
      if (
        b[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase() <
        a[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase()
      ) {
        return -1;
      }
      if (
        b[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase() >
        a[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase()
      ) {
        return 1;
      }
    }
    return 0;
  }

  function getSorting(order, orderBy) {
    return order === "desc"
      ? (a, b) => desc(a, b, orderBy)
      : (a, b) => -desc(a, b, orderBy);
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function createContent(rows, headers, order, orderBy) {
    const rowsCopy = [];
    var obj = {};
    for (var i = 0; i < rows.length; i++) {
      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = rows[i][headers[j]];
      }
      rowsCopy.push(obj);
      obj = {};
    }
    return stableSort(rowsCopy, getSorting(order, orderBy));
  }

  function getCodeList(row) {
    const response = [];

    if (row["lsbdCodeF"])
      response.push(row["lsbdCodeF"]);
    if (row["fcpcCodeF"])
      response.push(row["fcpcCodeF"]);
    if (row["ufcCodeF"])
      response.push(row["ufcCodeF"]);

    if (response.length === 0) {
      if (row.libraryExemplar && row.libraryExemplar.length > 0 && row.libraryExemplar[0].exemplarCode !== null) {
        response.push("BIB-00" + row.libraryExemplar[0].exemplarCode);
      } else {
        response.push('-');
      }
    }

    return response;
  }

  const getPermissionInventory = () => {
    if (localStorage.getItem("auth-token") !== null) {
      var auth = JSON.parse(localStorage.getItem("auth-token"));
      const permission = auth.profile;
      if (permission === "Colaborador" || permission === "Pesquisador" || permission === "Discente/Associado" || permission === "Usuario") {
        return false;
      } else {
        return true;
      }
    }
  }

  useEffect(() => {
    if (props.canClick) {
      document.getElementById("buttonFirstPage").click();
    }
  }, [props.canClick, props.data])


  const changeToFirstPage = () => {
    setPage(0);
  }


  return (
    <div className={classes.root}>
      <Grid container spacing={2} md={12} lg={12}>
        {createContent(itensGrid, headers, order, orderBy)
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map(row => (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
              <Paper className={classes.paper}>
                <Box className={classes.paperContent}>
                  <Grid xs={6} item className={classes.backgroundImage}>
                    <ButtonBase onClick={openModal}>
                      <img id={row["id"]} className={classes.img} alt="item" src={row["fileUserExternal"] ? row["fileUserExternal"] : window.location.origin + '/img/noImageItem.png'} />
                    </ButtonBase>
                  </Grid>
                  <Grid xs={6} item xs container direction="column" className={classes.boxRoot}>
                    <Grid item xs className={classes.textRoot}>
                      <Typography className={classes.title} variant="subtitle1">
                        {doTruncarStr(row["name"], 30)}
                      </Typography>
                      <Box className={classes.informationContent}>
                        <Box>
                          <Typography className={classes["codeId"]}>
                            {row.software ? "Chave/Email de ativação" : "Código de identificação"}
                          </Typography>
                          {row.software ?
                            <Typography variant="body2" color="textSecondary">
                              {row["key"] ? row["key"] : row["email"] ? row["email"] : "-"}
                            </Typography> :
                            <Typography variant="body2" color="textSecondary">
                              {getCodeList(row)[0]}
                              <Tooltip title={
                                getCodeList(row).length > 1 ? getCodeList(row).splice(1).join(' e ') : ""
                              } aria-label="details" placement="top">
                                <span style={{ fontWeight: "bold", color: "#6D6F71" }}>
                                  {getCodeList(row).length > 1 ? ` +${getCodeList(row).length - 1}` : ""}
                                </span>
                              </Tooltip>
                            </Typography>
                          }
                        </Box>
                        <Box className={classes.information}>
                          <Typography className={classes.codeId}>
                            {row.software ? "Máquina" : "Local"}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {row.software ? row["machine"] : row["place"] ? row["place"] : "-"}
                          </Typography>
                        </Box>
                        {GetGeneralPermissionForAction(Screen.MY_REQUISITIONS, Allow.CREATE) && row["class"] !== "library.Book" &&
                          <Grid item className={classes.information}>
                            <Button className={classes.btn} onClick={handleOpenRequisitionClick} color="primary">{t("INVENTORY.openRequest")}</Button>
                          </Grid>
                        }
                      </Box>

                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          ))}
        <button
          hidden
          onClick={changeToFirstPage}
          id="buttonFirstPage"
        >
        </button>
      </Grid>
      <TablePagination
        component="div"
        count={gridLength}
        page={page}
        onChangePage={handleChangePage}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage={"Resultados por página"}
      />

      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        msg="mensagem"
      >
        <DialogTitle className={classes.Title}>
          {t("INVENTORY.itemDetails")}
          <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div className={classes.root}>
              <Grid container spacing={3}>
                <Grid item xs={3} className={classes.backgroundImage}>
                  <ButtonBase className={classes.imageView}>
                    <img className={classes.imgView} alt="item" src={window.location.origin + '/img/noImageItem.png'} />
                  </ButtonBase>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Typography className={classes.title}>
                    Definição
                  </Typography>
                  <TextField
                    disabled
                    className={classes.textField}
                    label={t("INVENTORY.nameOfItem")}
                    value={item.name ? item.name : "-"}
                    margin="normal"
                  />
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        disabled
                        className={classes.textField}
                        label={t("INVENTORY.brand")}
                        value={item.brand ? item.brand : "-"}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        disabled
                        className={classes.textFieldCustum}
                        label={t("INVENTORY.category")}
                        value={item.category ? item.category : "-"}
                        margin="normal"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography className={classes.title}>
                    Identificação
                  </Typography>
                </Grid>
                {item.class === "library.Book" || item.category && item.category === "Livro" ?
                  <Grid container>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        disabled
                        className={classes.textFieldCustum}
                        label={t("INVENTORY.copyCode")}
                        value={
                          (item.libraryExemplar.length > 0 && item.libraryExemplar[0].exemplarCode !== null) ?
                            "BIB-00" + item.libraryExemplar[0].exemplarCode :
                            "-"
                        }
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        disabled
                        className={classes.textFieldCustum}
                        label={t("INVENTORY.isbn")}
                        value={item.isbn ? item.isbn : "-"}
                        margin="normal"
                      />
                    </Grid>
                  </Grid>
                  : item.software ?
                    <Grid container>
                      {getPermissionInventory() && <Grid item xs={12} sm={4}>
                        <TextField
                          disabled
                          className={classes.textFieldCustum}
                          label={t("INVENTORY.code")}
                          value={
                            item.codigo ?
                              item.codigo :
                              "-"
                          }
                          margin="normal"
                        />
                      </Grid>}
                      <Grid item xs={12} sm={4}>
                        <TextField
                          disabled
                          className={classes.textFieldCustum}
                          label={t("INVENTORY.keyEmail")}
                          value={item.key ? item.key : item.email ? item.email : "-"}
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    :
                    <Grid container>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          disabled
                          className={classes.textFieldCustum}
                          label={t("INVENTORY.ufcCode")}
                          value={item.ufcCode ? item.ufcCode : "-"}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          disabled
                          className={classes.textFieldCustum}
                          label={t("INVENTORY.fcpcCode")}
                          value={item.fcpcCode ? item.fcpcCode : "-"}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          disabled
                          className={classes.textFieldCustum}
                          label={t("INVENTORY.lsbdCode")}
                          value={item.lsbdCode ? item.lsbdCode : "-"}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          disabled
                          className={classes.textFieldCustum}
                          label={t("INVENTORY.externalCode")}
                          value={item.externCode ? item.externCode : "-"}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          disabled
                          className={classes.textFieldCustum}
                          label={t("INVENTORY.serialNumber")}
                          value={item.serialNumber ? item.serialNumber : "-"}
                          margin="normal"
                        />
                      </Grid>
                    </Grid>
                }

                <Grid item xs={12} sm={12}>
                  <Typography className={classes.title}>
                    Alocação
                  </Typography>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      disabled
                      className={classes.textFieldCustum}
                      label={item.software ? t("INVENTORY.machine") : t("INVENTORY.place")}
                      value={item.software ? item.machine : item.place ? item.place : "-"}
                      margin="normal"
                    />
                  </Grid>
                  {
                    item.software && getPermissionInventory() ?
                      <Grid item xs={12} sm={2}>
                        <TextField
                          disabled
                          className={classes.textFieldCustum}
                          label={t("INVENTORY.status")}
                          value={item.software ? item.status : item.itemStatus ? item.itemStatus : "-"}
                          margin="normal"
                        />
                      </Grid>
                      : !item.software ?
                        <Grid item xs={12} sm={2}>
                          <TextField
                            disabled
                            className={classes.textFieldCustum}
                            label={t("INVENTORY.status")}
                            value={item.itemStatus ? item.itemStatus : "-"}
                            margin="normal"
                          />
                        </Grid> : null
                  }
                  {getPermissionInventory() &&
                    <Grid item xs={12} sm={2}>
                      <TextField
                        disabled
                        className={classes.textFieldCustum}
                        label={t("INVENTORY.parentId")}
                        value={"-"}
                        margin="normal"
                      />
                    </Grid>}
                </Grid>
                {getPermissionInventory() &&
                  <React.Fragment>
                    <Grid item xs={12} sm={12}>
                      <Typography className={classes.title}>
                        Administração
                      </Typography>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          disabled
                          className={classes.textFieldCustum}
                          label={t("INVENTORY.value")}
                          value={item.value ? item.value : item.price ? item.price : "-"}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          disabled
                          className={classes.textFieldCustum}
                          label={t("INVENTORY.invoice")}
                          value={item.invoice ? item.invoice : "-"}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          disabled
                          className={classes.textFieldCustum}
                          label={t("INVENTORY.project")}
                          value={item.project ? item.project : "-"}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          disabled
                          className={classes.textFieldCustum}
                          label={t("INVENTORY.dateAcquisition")}
                          value={item.acquisitionDate ? dateToEN(item.acquisitionDate) : "-"}
                          margin="normal"
                        />
                      </Grid>
                      {
                        (item.class !== "library.Book" && (item.category && item.category !== "Livro")) &&
                        <React.Fragment>
                          <Grid item xs={12} sm={2}>
                            <TextField
                              disabled
                              className={classes.textFieldCustum}
                              label={t("INVENTORY.warrantyData")}
                              value={item.warrantyData ? dateToEN(item.warrantyData) : "-"}
                              margin="normal"
                            />
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <TextField
                              disabled
                              className={classes.textFieldCustum}
                              label={t("INVENTORY.lastMaintenance")}
                              value={item.lastMaintenance ? dateToEN(item.lastMaintenance) : "-"}
                              margin="normal"
                            />
                          </Grid>
                        </React.Fragment>
                      }
                    </Grid>
                  </React.Fragment>
                }
                <Grid item xs={12} sm={12}>
                  <Typography className={classes.title}>
                    Observação
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  {item.description ? item.description : "-"}
                </Grid>
                {getPermissionInventory() &&
                  <React.Fragment>
                    <Grid item xs={12} sm={12}>
                      <Typography className={classes.title}>
                        Última auditoria
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      {item.externCode ? item.externCode : "-"}
                    </Grid>
                  </React.Fragment>}
              </Grid>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Buttons
            onClick={handleClose}
            tipo="BN-noback"
            conteudo={t("INVENTORY.close").toUpperCase()}
          />
          {GetGeneralPermissionForAction(Screen.MY_REQUISITIONS, Allow.CREATE) && item.class !== "library.Book" &&
            <Buttons
              onClick={handleOpenRequisitionClick}
              tipo="BN-blue"
              conteudo={t("INVENTORY.openRequest").toUpperCase()}
            />}
        </DialogActions>
      </Dialog>
    </div >
  );
}

AutoGrid.propTypes = {
  rows: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
  visibleHeaders: PropTypes.array.isRequired,
  mainField: PropTypes.string.isRequired
};

export default AutoGrid;