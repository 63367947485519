import MomentUtils from "@date-io/moment";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Delete from "@material-ui/icons/Delete";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useStyles } from "components/layout/CommonStyles";
import React, { useEffect, useState, useContext} from "react";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Zoom from "@material-ui/core/Zoom";
import { useTranslation } from "react-i18next";
import ProjectsContext from "context/projects/projectsContext";

const tooltipTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#2c3e51"
    }
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: "white",
        fontSize: "1em",
        backgroundColor: "red",
        opacity: "0.7"
      }
    }
  }
});

const NewBubget = props => {
  const classes = useStyles();
  const {
    deleteQuota,
    lastQuota,
    budgetDate,
    quotaValue,
    estimatedCost,
    updateFields,
    disabled,
    minDate,
    maxDate
  } = props;
  const [selectedDate, handleDateChange] = useState(budgetDate);
  const [value, setValue] = useState(quotaValue ? quotaValue : "");
  const requiredField = "GENERAL.requiredField";
  const [t] = useTranslation();
  const projectsContext = useContext(ProjectsContext);

  const {
    showRequiredFieldMessage,
    setValueRequiredMessage
  } = projectsContext;


  useEffect(() => {
    handleDateChange(budgetDate);
  }, [budgetDate]);

  useEffect(() => {
    setValue(quotaValue);
  }, [quotaValue]);

  useEffect(() => {
    updateFields();
  });

  const clearRiqueredMessage = () => {
    setValueRequiredMessage(false);
  };

  const converTotBrCurrency = number => {
    var temp = "";
    var len = number.length;
    var count = 0;

    for (var i = 0; i < len; i++) {
      if (i === 1) {
        var aux = "," + number[i] + temp;
        temp = aux;
      } else {
        if (count === 3) {
          count = 1;
          var aux = number[i] + "." + temp;
          temp = aux;
        } else {
          if (i > 1) {
            count += 1;
          }
          var aux = number[i] + temp;
          temp = aux;
        }
      }
    }

    return temp;
  };

  const handleValueChange = event => {
    if (event.target.value !== " " && !event.target.value.includes(" ")) {
      var str = event.target.value.split("");
      var aux = str.filter(el => el !== "." && el !== ",").join("");
      var value = event.target.value;

      if (event.target.value) {
        value = converTotBrCurrency(
          aux
            .split("")
            .reverse()
            .join("")
        );
      }

      aux = value.split("").filter(el => el !== ".").join("").replace(",", ".")
      if (!isNaN(aux)) {
        setValue(value);
      }
    }
  };

  const handleClick = () => {
    deleteQuota(lastQuota);
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <MuiPickersUtilsProvider locale="pt" utils={MomentUtils}>
            <DatePicker
              fullWidth
              id={"quotaDate" + lastQuota}
              variant="inline"
              openTo="year"
              name="yearBase"
              views={["year", "month"]}
              margin="normal"
              autoOk={true}
              value={selectedDate}
              onChange={handleDateChange}
              minDate={minDate}
              maxDate={maxDate}
              maxDateMessage={
                "A data não deve ser maior do que a data de término do termo"
              }
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={4}>
        <MuiThemeProvider theme={tooltipTheme}>
            <Tooltip
              TransitionComponent={Zoom}
              open={value.length === 0 && showRequiredFieldMessage}
              title={t(requiredField)}
            >
          <TextField
            id={"quotaValue" + lastQuota}
            disabled={disabled}
            className={classes.textField}
            margin="normal"
            name="quotaValue"
            value={value}
            onChange={handleValueChange}
            onFocus={clearRiqueredMessage}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              )
            }}
          />
          </Tooltip>
          </MuiThemeProvider>
        </Grid>
        <Grid item xs={2}>
          <TextField
            id={"percentage" + lastQuota}
            disabled={true}
            className={classes.textField, "percentageChildren"}
            value={
              value && estimatedCost > 0
                ? (
                  (parseFloat(
                    value
                      .split("")
                      .filter(el => el !== ".")
                      .join("")
                      .replace(",", ".")
                  ) /
                    estimatedCost) *
                  100
                ).toFixed(2)
                : "0.00"
            }
            margin="normal"
          />
        </Grid>
        <Grid item xs={2} style={{ marginTop: "8px" }}>
          <Tooltip title="Deletar" aria-label="delete" placement="top">
            <IconButton
              disabled={disabled}
              onClick={handleClick}
              style={{ float: "right" }}
              className={classes.icons}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </Grid>
        <div className="lineBreak"></div>
      </Grid>
    </div>
  );
};

export default NewBubget;
