export const periodConstants = {
    LAST_MONTH: 'LAST_MONTH',
    LAST_THREE_MONTHS: 'LAST_THREE_MONTHS',
    LAST_SIX_MONTHS: 'LAST_SIX_MONTHS',
    LAST_TWELVE_MONTHS: 'LAST_TWELVE_MONTHS'
};

export const periodArray = [
    {
        label: 'Último mês',
        value: periodConstants.LAST_MONTH
    },
    {
        label: 'Últimos 3 meses',
        value: periodConstants.LAST_THREE_MONTHS
    },
    {
        label: 'Últimos 6 meses',
        value: periodConstants.LAST_SIX_MONTHS
    },
    {
        label: 'Último 12 meses',
        value: periodConstants.LAST_TWELVE_MONTHS
    }
];
