import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import Buttons from 'components/buttons/Pressed';
import Typography from 'components/typography/Typography';
import { screenStyles } from '../Styles';
import TextField from '@material-ui/core/TextField';
import ErrorIcon from '@material-ui/icons/Error';
import Input from 'components/inputs/Input';
import Grid from '@material-ui/core/Grid';
import { LibraryReservationStatusValues } from 'global/constants';
import { AllAuthorsName } from 'utils/library/AllAuthorsName';
import { dateToBR } from 'utils/dates/DateToBRr';
import { formatExemplarCode } from 'utils/library/formatExemplarCode';
import Skeleton from '@material-ui/lab/Skeleton';
import { formatImagePath } from 'utils/library/formatImagePath';

const DialogWithdrawRequisition = props => {
    const styles = screenStyles();
    const [t] = useTranslation();
    const { open, setOpen, viewValues, context, submit } = props;
    const [disabled, setDisabled] = useState(false);
    const [requiredJustification, setRequiredJustification] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [password, setPassword] = useState('');
    const [passRight, setPassRight] = useState(false);
    const blankField = 'GENERAL.requiredField';
    const [validPasswordrequiredField, setPasswordRequiredField] =
        useState(false);

    var pass = true;

    const handleSubmit = () => {
        if (context === 'withdraw') {
            if (password.length > 0) sendRequest();
            else setPasswordRequiredField(true);
            if (pass) setPassword('');
        } else {
            if (!values.justification) {
                setRequiredJustification(true);
            } else {
                const emailData = {
                    colaboratorEmail: viewValues.user.username,
                    colaboratorName: viewValues.user.shortname,
                    justification: values.justification,
                    bookLibrary: viewValues.library,
                    screenContext: 'withdraw',
                    context: 'CANCEL',
                    endpoint: 'cancelRequisition',
                    bookTitle: viewValues.book.title,
                    bookAuthors: AllAuthorsName(viewValues.book.authors),
                    bookCategory: viewValues.book.category.name,
                    exemplarCode: formatExemplarCode(
                        '' + viewValues.exemplar.code
                    ),
                    reservationId: viewValues.id
                };
                submit(
                    viewValues.id,
                    context,
                    LibraryReservationStatusValues.CANCELED_MANAGEMENT,
                    viewValues.exemplarId,
                    values.justification,
                    emailData,
                    'WITHDRAWAL_CONFIRMATION'
                );
                handleClose();
            }
        }
    };

    const handleChange = (name, value) => {
        let val = value;
        setValues({ ...values, [name]: val });
        setDisabled(false);
    };

    const [values, setValues] = useState({
        justification: ''
    });

    const handleClose = () => {
        setOpen(false);
    };

    function sendRequest() {
        const requestInfo = {
            method: 'POST',
            body: JSON.stringify({
                email: viewValues.user.username,
                password: password
            }),
            headers: new Headers({
                'Content-type': 'application/json'
            })
        };

        fetch(process.env.REACT_APP_SERVER + 'api/auth/login', requestInfo)
            .then(response => {
                if (response.ok) {
                    setDisabled(true);
                    setPassRight(false);

                    const emailData = {
                        colaboratorName: viewValues.user.shortname,
                        colaboratorEmail: viewValues.user.username,
                        bookTitle: viewValues.book.title,
                        bookAuthor: AllAuthorsName(viewValues.book.authors),
                        bookCode: viewValues.book.exemplars.find(
                            x => x.id === viewValues.exemplarId
                        ).code,
                        bookLibrary: viewValues.library,
                        context: 'WITHDRAW',
                        endpoint: 'withdrawRequisition'
                    };

                    submit(
                        viewValues.id,
                        context,
                        LibraryReservationStatusValues.LOAN,
                        viewValues.exemplarId,
                        viewValues.justification,
                        emailData,
                        'WITHDRAWAL_CONFIRMATION'
                    );
                    handleClose();
                } else {
                    setPassRight(true);
                    return true;
                }
            })
            .catch(error => {
                setPassRight(true);
                return true;
            });
    }

    useEffect(() => {
        setLoaded(false);
    }, [viewValues.book.imagePath]);

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth={'sm'}
                fullWidth={true}
            >
                <DialogTitle>
                    <div className={styles.dialogTitle}>
                        <Typography
                            variant={'RobotoBold'}
                            fontSize={24}
                            textColor={'secondary'}
                        >
                            {context === 'withdraw'
                                ? t('LIBRARY.dialogWithdrawRequisition')
                                : t('LIBRARY.dialogCancelRequisition')}
                        </Typography>
                        <IconButton
                            color="#6D6F71"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className={styles.dialogBookRequisitionContainer}>
                        <div className={styles.dialogContent}>
                            <Typography
                                variant={'RobotoBold'}
                                fontSize={14}
                                textColor={'secondary'}
                            >
                                {t('INVENTORY.title').toLocaleUpperCase()}
                            </Typography>
                            <Typography
                                className={styles.dialogTopic}
                                variant={'RobotoRegular'}
                                fontSize={14}
                                textColor={'secondary'}
                            >
                                {viewValues.book.title}
                            </Typography>
                            <Typography
                                variant={'RobotoBold'}
                                fontSize={14}
                                textColor={'secondary'}
                            >
                                {t('INVENTORY.BookAuthor').toLocaleUpperCase()}
                            </Typography>
                            <Typography
                                className={styles.dialogTopic}
                                variant={'RobotoRegular'}
                                fontSize={14}
                                textColor={'secondary'}
                            >
                                {AllAuthorsName(viewValues.book.authors)}
                            </Typography>
                            <Typography
                                variant={'RobotoBold'}
                                fontSize={14}
                                textColor={'secondary'}
                            >
                                {t(
                                    'LIBRARY.RequisitionApplicant'
                                ).toLocaleUpperCase()}
                            </Typography>
                            <Typography
                                className={styles.dialogTopic}
                                variant={'RobotoRegular'}
                                fontSize={14}
                                textColor={'secondary'}
                            >
                                {viewValues.user.name}
                            </Typography>
                            <Typography
                                variant={'RobotoBold'}
                                fontSize={14}
                                textColor={'secondary'}
                            >
                                {t('GENERAL.code').toLocaleUpperCase()}
                            </Typography>
                            <Typography
                                className={styles.dialogTopic}
                                variant={'RobotoRegular'}
                                fontSize={14}
                                textColor={'secondary'}
                            >
                                {formatExemplarCode(
                                    '' +
                                        viewValues.book.exemplars.find(
                                            x => x.id === viewValues.exemplarId
                                        ).code
                                )}
                            </Typography>

                            {context === 'cancel' && (
                                <>
                                    <Typography
                                        variant={'RobotoBold'}
                                        fontSize={14}
                                        textColor={'secondary'}
                                    >
                                        {t(
                                            'LIBRARY.dateRequisition'
                                        ).toLocaleUpperCase()}
                                    </Typography>
                                    <Typography
                                        className={styles.dialogTopic}
                                        variant={'RobotoRegular'}
                                        fontSize={14}
                                        textColor={'secondary'}
                                    >
                                        {dateToBR(viewValues.requestDate)}
                                    </Typography>
                                </>
                            )}
                        </div>
                        <img
                            className={styles.dialogImage}
                            src={formatImagePath(
                                viewValues.book.imagePath,
                                viewValues.book.isImageUrl
                            )}
                            alt={viewValues.book.title}
                            style={!loaded ? { display: 'none' } : {}}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src =
                                    window.location.origin +
                                    '/img/no-book-cover.png';
                                setLoaded(true);
                            }}
                            onLoad={() => {
                                setLoaded(true);
                            }}
                        />
                        {!loaded && (
                            <Skeleton
                                className={styles.dialogImage}
                                variant="rect"
                                width={110}
                                height={150}
                            />
                        )}
                    </div>

                    {context === 'cancel' && (
                        <>
                            <Grid container item spacing={1} md={12} lg={12}>
                                <Grid item xs={12} sm={12}>
                                    <Input
                                        required
                                        placeholder={t('LIBRARY.justification')}
                                        label={'GENERAL.reason'}
                                        value={values.justification}
                                        valueName={'justification'}
                                        rows={4}
                                        maxLength={200}
                                        handleChange={handleChange}
                                        error={requiredJustification}
                                        helperText={
                                            requiredJustification &&
                                            t('GENERAL.requiredField')
                                        }
                                        setRequired={setRequiredJustification}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    )}

                    {context === 'withdraw' && (
                        <div
                            style={{
                                marginTop: '24px',
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <Typography
                                variant={'RobotoBold'}
                                fontSize={14}
                                textColor={'secondary'}
                            >
                                {t(
                                    'LIBRARY.solicitantConfirmation'
                                ).toLocaleUpperCase()}
                            </Typography>
                            <div
                                style={{ marginTop: '8px' }}
                                className={styles.dialogMoreDetailsDisponible}
                            >
                                <ErrorIcon
                                    style={{
                                        marginRight: '0.5rem',
                                        color: '#2C3E51'
                                    }}
                                    fontSize="small"
                                />
                                <Typography
                                    variant={'RobotoBold'}
                                    fontSize={14}
                                    textColor={'primary'}
                                >
                                    {t(`LIBRARY.confirmWithdrawMessage`)}
                                </Typography>
                            </div>
                            <TextField
                                error={validPasswordrequiredField || passRight}
                                label={t('INVENTORY.password') + '*'}
                                value={password}
                                onChange={e => {
                                    setPasswordRequiredField(false);
                                    setPassRight(false);
                                    setPassword(e.target.value);
                                }}
                                autoComplete="new-password"
                                type="password"
                                variant="filled"
                                helperText={
                                    validPasswordrequiredField
                                        ? t(blankField)
                                        : passRight
                                        ? 'Senha inválida.'
                                        : ''
                                }
                                style={{ width: '60%', margin: '0 auto' }}
                            />
                        </div>
                    )}
                </DialogContent>

                <DialogActions className={styles.dialogButtonsWrapper}>
                    <Buttons
                        onClick={handleClose}
                        tipo="BN-noback"
                        conteudo={
                            context === 'withdraw'
                                ? t('GENERAL.cancel').toUpperCase()
                                : t('GENERAL.close').toUpperCase()
                        }
                    />
                    {context === 'withdraw' ? (
                        <Buttons
                            disabled={disabled || !password}
                            className={styles.dialogButton}
                            tipo="BN-blue"
                            conteudo={t(
                                'LIBRARY.confirmWithdraw'
                            ).toUpperCase()}
                            onClick={handleSubmit}
                        />
                    ) : (
                        <Buttons
                            disabled={!values.justification}
                            className={styles.dialogButton}
                            tipo="BN-blue"
                            conteudo={t(
                                'LIBRARY.dialogCancelRequisition'
                            ).toUpperCase()}
                            onClick={handleSubmit}
                        />
                    )}
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DialogWithdrawRequisition;
