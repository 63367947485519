import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import InputFilter from 'components/inputs/InputFilter';
import Buttons from 'components/buttons/Pressed';
import { useStyles } from 'components/layout/CommonStyles';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const AdvancedSearchDialog = props => {
    const [t] = useTranslation();
    const classes = useStyles();
    const { open, setOpen, apply, categoryArray, statusArray, values } = props;

    const [filterTitle, setFilterTitle] = useState('');
    const [filterCategory, setFilterCategory] = useState('');
    const [filterStatus, setFilterStatus] = useState('');
    const [filterIsbn, setFilterIsbn] = useState('');
    const [filterAuthor, setFilterAuthor] = useState('');
    const [filterExemplarCode, setFilterExemplarCode] = useState('');

    const cleanFields = () => {
        setFilterTitle('');
        setFilterCategory('');
        setFilterStatus('');
        setFilterIsbn('');
        setFilterAuthor('');
        setFilterExemplarCode('');
    };

    useEffect(() => {
        setFilterTitle(values.title);
        setFilterCategory(values.category);
        setFilterStatus(values.status);
        setFilterIsbn(values.isbn);
        setFilterAuthor(values.author);
        setFilterExemplarCode(values.code);
    }, []);

    const handleClose = () => {
        setOpen(false);
    };

    const handleClean = () => {
        cleanFields();
        apply('', '', '', '', '', '', '', '', '', '', '');
    };

    const handleApply = () => {
        apply(
            filterTitle,
            filterCategory,
            filterExemplarCode,
            filterIsbn,
            filterStatus,
            filterAuthor
        );
        setOpen(false);
        cleanFields();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            msg="mensagem"
            maxWidth="md"
        >
            <DialogTitle
                className={classes.actionsTitle}
                style={{ width: 'auto' }}
            >
                <span style={{ fontWeight: 'bold' }}>
                    {t('INVENTORY.advancedSearch').toLocaleUpperCase()}
                </span>
                <IconButton
                    style={{ float: 'right', marginTop: -10, marginRight: -17 }}
                    edge="end"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <FormControl component="fieldset">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <FormLabel component="legend">
                                    {t('INVENTORY.textAdvancedSearch')}
                                </FormLabel>
                            </Grid>
                            <Grid item xs={4}>
                                <InputFilter
                                    filterValue={filterTitle}
                                    setFilter={setFilterTitle}
                                    label={'INVENTORY.title'}
                                    maxLength={70}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <InputFilter
                                    filterValue={filterExemplarCode}
                                    setFilter={setFilterExemplarCode}
                                    label={'INVENTORY.copyCode'}
                                    maxLength={70}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <InputFilter
                                    filterValue={filterCategory}
                                    setFilter={setFilterCategory}
                                    label={'INVENTORY.category'}
                                    isSelect
                                    arraySelected={categoryArray}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <InputFilter
                                    filterValue={filterStatus}
                                    setFilter={setFilterStatus}
                                    label={'INVENTORY.statusBook'}
                                    isSelect
                                    arraySelected={statusArray}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <div
                                    style={{
                                        height: '1px',
                                        backgroundColor: '#F5F6FA'
                                    }}
                                ></div>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    className={classes.titleNewEquipment}
                                >
                                    {t('INVENTORY.advanced')}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <InputFilter
                                    filterValue={filterAuthor}
                                    setFilter={setFilterAuthor}
                                    label={'INVENTORY.BookAuthor'}
                                    maxLength={70}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <InputFilter
                                    filterValue={filterIsbn}
                                    setFilter={setFilterIsbn}
                                    label={'INVENTORY.isbn'}
                                    maxLength={70}
                                />
                            </Grid>
                        </Grid>
                    </FormControl>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Buttons
                    onClick={handleClose}
                    tipo="BN-noback"
                    conteudo={t('GENERAL.cancel').toLocaleUpperCase()}
                />
                <Buttons
                    onClick={handleClean}
                    tipo="BN-noback"
                    conteudo={t('GENERAL.clear').toLocaleUpperCase()}
                />
                <Buttons
                    onClick={handleApply}
                    tipo="BN-blue"
                    conteudo={t('GENERAL.apply').toLocaleUpperCase()}
                />
            </DialogActions>
        </Dialog>
    );
};
export default AdvancedSearchDialog;
