import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Edit from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useStyles } from "components/layout/CommonStyles";

const Actions = props => {
  const classes = useStyles();
  const { item, permission } = props;
  useEffect(() => { });

  return (
    <div>
      {permission["allowRead"] &&
        <Link to={`/userscosts/${item.id}`}>
          <Tooltip title="Visualizar" aria-label="details" placement="top">
            <IconButton className={classes.actionsIcons}>
              <Visibility />
            </IconButton>
          </Tooltip>
        </Link>
      }
      {permission["allowUpdate"] &&
        <Link to={`/userscosts/${item.id}/edit`}>
          <Tooltip title="Editar" aria-label="edit" placement="top">
            <IconButton className={classes.actionsIcons}>
              <Edit />
            </IconButton>
          </Tooltip>
        </Link>
      }
    </div>
  );
};

export default Actions;