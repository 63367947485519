import React, { useEffect, useState } from 'react';
import { useStyles } from 'components/layout/CommonStyles';
import { inputStyles } from './Styles';
import { useTranslation } from 'react-i18next';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import Typography from 'components/typography/Typography';
import Dropzone from 'react-dropzone';
import Skeleton from '@material-ui/lab/Skeleton';
import FormControl from '@material-ui/core/FormControl';
import ContainedWithIconButton from 'components/buttons/ContainedWithIconButton';
import Box from '@material-ui/core/Box';

const InputImageUser = ({ file, setFile, imageUrl, context, ...props }) => {
    const classes = useStyles();
    const styles = inputStyles();
    const [t] = useTranslation();
    const [hasUpdatedFile, setHasUpdatedFile] = useState(
        imageUrl ? true : false
    );

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(false);
    }, [imageUrl]);

    const onDrop = acceptedFiles => {
        if (acceptedFiles[0] !== undefined) {
            setHasUpdatedFile(true);
            setFile(
                acceptedFiles.map(file =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })
                )
            );
        }
    };

    return (
        <Dropzone
            disabled={context === 'view' ? true : false}
            component="div"
            onDrop={onDrop}
            maxFiles={1}
            accept="image/jpeg,
            image/png,
            image/bmp"
        >
            {({ getRootProps, getInputProps, isDragActive }) => (
                <div
                    {...getRootProps()}
                    style={{
                        width: '200px'
                    }}
                >
                    <input {...getInputProps()} />
                    {!isDragActive && (
                        <FormControl
                            className={
                                context !== 'view'
                                    ? styles.inputImageContainer
                                    : ''
                            }
                        >
                            <div className={styles.inputImageWrapper}>
                                <div className={styles.inputImageDivImage}>
                                    <img
                                        className={styles.inputImageImageUser}
                                        style={
                                            !loaded ? { display: 'none' } : {}
                                        }
                                        alt="add-image"
                                        src={
                                            !imageUrl && file.length === 0
                                                ? window.location.origin +
                                                  '/img/noImageItem.png'
                                                : file.length > 0
                                                ? file[0].preview
                                                : imageUrl
                                        }
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src =
                                                window.location.origin +
                                                '/img/noImageItem.png';
                                            setLoaded(true);
                                        }}
                                        onLoad={() => {
                                            setLoaded(true);
                                        }}
                                    />
                                    {!loaded && (
                                        <Skeleton
                                            variant="rect"
                                            width={196}
                                            height={196}
                                        />
                                    )}
                                </div>
                                {context !== 'view' && (
                                    <ContainedWithIconButton
                                        text={
                                            context === 'edit'
                                                ? 'Editar Foto'
                                                : 'Adicionar Foto'
                                        }
                                        Icon={AddAPhotoIcon}
                                        style={{
                                            opacity: '1',
                                            color: 'white',
                                            width: '100%',
                                            height: '32px'
                                        }}
                                        onClick={() => {}}
                                    />
                                )}
                            </div>
                        </FormControl>
                    )}
                </div>
            )}
        </Dropzone>
    );
};

export default InputImageUser;
