import React, { Component } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

export default class Charts extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.link =  "" ;
      }

      componentDidMount(){
        var that = this;
        this.link = "data:image/svg+xml;base64," + btoa(this.myRef.current.chart.getSVG());
        this.svgString2Image(this.link, 800, 600, 'png', /* callback that gets png data URL passed to it */function (pngData) {
          that.props.callbackLinkGraph(pngData)
      });  
    }

    svgString2Image(svgString, width, height, format, callback) {
        // set default for format parameter
        format = format ? format : 'png';
        // SVG data URL from SVG string
        var svgData =  unescape(encodeURIComponent(svgString));
        // create canvas in memory(not in DOM)
        var canvas = document.createElement('canvas');
        // get canvas context for drawing on canvas
        var context = canvas.getContext('2d');
        // set canvas size
        canvas.width = width;
        canvas.height = height;
        // create image in memory(not in DOM)
        var image = new Image();
        // later when image loads run this
    
        image.onload = function () { // async (happens later)
            // clear canvas
            context.clearRect(0, 0, width, height);
            // draw image with SVG data to canvas
            context.drawImage(image, 0, 0, width, height);
            // snapshot canvas as png
            var pngData = canvas.toDataURL('image/' + format);
            // pass png data URL to callback
            callback(pngData);
        }; // end async
        // start loading SVG data into in memory image
        image.src = svgData;  
    }

    componentDidUpdate(prevProps) {
        // Uso típico, (não esqueça de comparar as props):
        var that = this;
        if (this.props !== prevProps || this.link === "") {
          this.link = "data:image/svg+xml;base64," + btoa(this.myRef.current.chart.getSVG());
          this.svgString2Image(this.link, 800, 600, 'png', /* callback that gets png data URL passed to it */function (pngData) {
            that.props.callbackLinkGraph(pngData)
        });  
        }
      }

    render() {
        const allCosts = this.props.testeallcost;
        const allaPorts = this.props.testeallaports;
        const categories = this.props.categories;

        var seasonStart = this.props.seasonStartFinancial
        var month = seasonStart.getMonth()
        var year = seasonStart.getFullYear() 
        var dateTime = Date.UTC(year, month, 1)

        var options = {
            plotOptions: {
                
                series: {
                    connectNulls: true,
                    //pointStart: dateTime,
                    //pointIntervalUnit: 'month'
                }
            },
            title: {
                text: ''
            },
            xAxis: {
               // type: 'datetime'
                categories: categories,
            },
            yAxis: {
                title: {
                    text: 'Valores'
                },
            },
            series: [{
                data: allCosts,
                name: 'Registro de gasto'
            },{
                data: allaPorts,
                step: 'left',
                name: 'Aporte'
            }]
        }

        return(
            <div  style={{display: "none"}}>   
            <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={this.myRef}
            />
            </div>
        )
    }
}
