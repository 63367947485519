import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { useStyles } from "components/layout/CommonStyles";
import { useTranslation } from "react-i18next";
import CloseIcon from '@material-ui/icons/Close';
import Buttons from "components/buttons/Pressed";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from '@material-ui/core/InputAdornment';
import Download from "@material-ui/icons/GetApp";
import Input from "components/inputs/Input";
import InputFile from "components/inputs/InputModal";
import axios from "axios";

const HistoryDialogForm = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const { open, setOpen, oldName, newName, oldDescription, newDescription, type, user, date } = props;

    const [values, setValues] = useState({
        oldName: "",
        newName: "",
        oldDescription: "",
        newDescription: "",
        type: "",
        user: "",
        date: ""
    });


    useEffect(() => {
        setValues({
            ...values,
            oldName: oldName ? oldName : "",
            newName: newName ? newName : "",
            oldDescription: oldDescription ? oldDescription : "",
            newDescription: newDescription ? newDescription : "",
            type: type ? type : "",
            user: user ? user : "",
            date: date ? date : ""
        });
    }, [oldName, newName, oldDescription, newDescription, type, user, date]);

    const clearDialog = () => {
        setValues({
            ...values,
            oldName: "",
            newName: "",
            oldDescription: "",
            newDescription: "",
            type: "",
            user: "",
            date: ""
        });
    }

    const handleClose = () => {
        clearDialog();
        setOpen(false);
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle className={classes.Title}>
                <b>{t("GENERAL.viewHistory")}</b>
                <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container item spacing={2} md={12} lg={12}>

                    <Grid item xs={12} sm={6}>
                        <Input
                            disabled
                            label={"GENERAL.HistoryOperation"}
                            value={values.type}
                            valueName={"type"}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Input
                            disabled
                            label={"GENERAL.date"}
                            value={values.date}
                            valueName={"date"}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Input
                            disabled
                            label={"GENERAL.user"}
                            value={values.user}
                            valueName={"user"}
                        />
                    </Grid>

                    {newName ?
                        <React.Fragment>
                            <Grid item xs={12} sm={12}>
                                <Input
                                    disabled
                                    label={"HR.oldJobName"}
                                    value={values.oldName}
                                    valueName={"oldName"}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Input
                                    disabled
                                    label={"HR.newJobName"}
                                    value={values.newName}
                                    valueName={"newName"}
                                />
                            </Grid>
                        </React.Fragment>
                        : null}

                    {newDescription ?
                        <React.Fragment>

                            <Grid item xs={12} sm={12}>
                                <Input
                                    disabled
                                    label={"HR.oldJobDescription"}
                                    value={values.oldDescription}
                                    valueName={"oldDescription"}
                                    rows={5}
                                    maxLength={500}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <Input
                                    disabled
                                    label={"HR.newJobDescription"}
                                    value={values.newDescription}
                                    valueName={"newDescription"}
                                    rows={5}
                                    maxLength={500}
                                />
                            </Grid>
                        </React.Fragment>
                        : null}

                </Grid>
            </DialogContent>
            <DialogActions>
                <Buttons
                    onClick={handleClose}
                    tipo="BN-blue"
                    conteudo={t("GENERAL.close").toUpperCase()}
                />

            </DialogActions>
        </Dialog>
    )
}

export default HistoryDialogForm;
