import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from '@material-ui/icons/Close';
import Buttons from "components/buttons/Pressed";
import Delete from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";



const DialogDeleteSimple = props => {
    const { open, dialogTitle, dialogText, dialogInfo, itemName, itemRef, handleDelete, handleClose } = props;


    const classes = useStyles();
    const [t] = useTranslation();

    const thereIsInfo = () => {
        return (dialogInfo != null);
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            msg="mensagem"
        >
            <DialogTitle className={classes.Title}>
                {dialogTitle}
                <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {dialogText} <br />
                    <text style={{ fontWeight: "bold" }}>
                        {itemName != "" ? itemName : '- '}
                    </text>
                    ?
                    {thereIsInfo() &&
                        <text>
                            <br />
                            <br />
                            {dialogInfo}
                        </text>
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Buttons
                    onClick={handleClose}
                    tipo="BN-noback"
                    conteudo={t("GENERAL.cancel").toUpperCase()}
                />
                <Buttons
                    onClick={() => handleDelete(itemRef)}
                    tipo="BN-blue"
                    conteudo={t("GENERAL.delete").toUpperCase()}
                />
            </DialogActions>
        </Dialog>
    )
};

export default DialogDeleteSimple;