import axios from 'axios';
import { useMutation } from 'react-query';
import Cookies from 'js-cookie';
import { useSnackbar } from 'hooks/UseSnackbar';
import { useTranslation } from 'react-i18next';
import { valueToMoneyFloat } from 'utils/general/valueToMoneyFloat';
import { moneyMask } from 'utils/masks/moneyMask';
import { getQuotaManagersAndSendEmails } from 'services/Emails/Quota/quotaEmailService';

export const useMutationConfigurePayee = (setDisableButton, isNewPayee) => {
    const toast = useSnackbar();
    const [t] = useTranslation();

    const mutation = useMutation({
        mutationFn: async obj =>
            await axios.put(
                process.env.REACT_APP_SERVER +
                    `api/Quota/Payees/ConfigurePayee`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            ),
        onSuccess: res => {
            toast.success(t('QUOTAS.userQuotaConfigSuccess'));
            setDisableButton(true);
            if (isNewPayee) {
                const data = res.data;

                const emailData = {
                    collaboratorName: data.user.shortname,
                    collaboratorEmail: data.user.username,
                    value: `R$ ${moneyMask(
                        valueToMoneyFloat(data.user.jobPrice)
                    )}`,
                    collaboratorJob: data.user.job,
                    collaboratorId: data.user.id
                };

                getQuotaManagersAndSendEmails(
                    emailData,
                    'api/Quota/Participation',
                    'QUOTAS_PARTICIPATION_EMAIL_OPTIONAL'
                );
            }
        },
        onError: err => {
            toast.error(t('QUOTAS.userQuotaConfigError'));
        },
        onSettled: () => {}
    });

    return mutation;
};
