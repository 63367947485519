import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Visibility from '@material-ui/icons/Visibility';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import DialogDebitCredit from 'pages/libraryModule/dialogs/Fines/DialogDebitCredit';
import { LibraryFinesTypes } from 'global/constants';
import DialogHistoryMoreDetails from 'pages/libraryModule/dialogs/MyLoans/DialogHistoryMoreDetails';

const LibraryLoanHistoryTableActions = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const { item } = props;
    const [openReservationDetailsDialog, setOpenReservationDetailsDialog] =
        useState(false);

    return (
        <React.Fragment>
            <Tooltip
                title={t('GENERAL.view')}
                aria-label="details"
                placement="top"
            >
                <IconButton
                    className={classes.iconsTableActions}
                    onClick={() => setOpenReservationDetailsDialog(true)}
                >
                    <Visibility />
                </IconButton>
            </Tooltip>

            {openReservationDetailsDialog && (
                <DialogHistoryMoreDetails
                    open={openReservationDetailsDialog}
                    setOpen={setOpenReservationDetailsDialog}
                    viewValues={item}
                    showCollaborator={true}
                />
            )}
        </React.Fragment>
    );
};

export default LibraryLoanHistoryTableActions;
