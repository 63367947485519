import i18n from '../../../../i18n';


export const patrimonyData = [
    {
        screen: "MY_ITENS",
        allowRead: false,
    },
    {
        screen: "EQUIPMENTS",
        allowRead: false,
        allowCreate: false,
        allowEdit: false,
        allowDelete: false,
    },
    {
        screen: "BOOKS",
        allowRead: false,
        allowCreate: false,
        allowEdit: false,
        allowDelete: false,
    },
    {
        screen: "SOFTWARES",
        allowRead: false,
        allowCreate: false,
        allowEdit: false,
        allowDelete: false,
    },
    {
        screen: "MY_REQUISITIONS",
        allowRead: false,
        allowCreate: false,
        allowEdit: false,
        allowDelete: false,
    },
    {
        screen: "COLLABORATORS_REQUISITIONS",
        allowRead: false,
        allowEdit: false,
        isManager: false
    },
    {
        screen: "REQUISITIONS",
        allowRead: false,
        allowEdit: false,
    },
    {
        screen: "BOOKS_CATEGORIES",
        allowRead: false,
        allowCreate: false,
        allowEdit: false,
        allowDelete: false,
    },
    {
        screen: "ITENS_CATEGORIES",
        allowRead: false,
        allowCreate: false,
        allowEdit: false,
        allowDelete: false,
    },
    {
        screen: "SOFTWARES_CATEGORIES",
        allowRead: false,
        allowCreate: false,
        allowEdit: false,
        allowDelete: false,
    },
    {
        screen: "PLACES",
        allowRead: false,
        allowCreate: false,
        allowEdit: false,
        allowDelete: false,
    },
    {
        screen: "EXTERNAL_COLLABORATORS",
        allowRead: false,
        allowCreate: false,
        allowEdit: false,
        allowDelete: false,
    },
    {
        screen: "ITENS_STATUS",
        allowRead: false,
        allowCreate: false,
        allowEdit: false,
        allowDelete: false,
    },
]