import axios from 'axios';
import { useMutation } from 'react-query';
import Cookies from 'js-cookie';
import { useQueryPersonalCreditSolicitations } from './useQueryPersonalCreditSolicitations';
import { useSnackbar } from 'hooks/UseSnackbar';
import { useTranslation } from 'react-i18next';
import { valueToMoneyFloat } from 'utils/general/valueToMoneyFloat';
import { moneyMask } from 'utils/masks/moneyMask';
import { getQuotaManagersAndSendEmails } from 'services/Emails/Quota/quotaEmailService';

export const useMutationCancelCreditSolicitation = setOpen => {
    const toast = useSnackbar();
    const [t] = useTranslation();

    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const queryPersonalCreditSolicitations =
        useQueryPersonalCreditSolicitations({ enabled: false }, auth.id);

    const mutation = useMutation({
        mutationFn: async obj =>
            await axios.put(
                process.env.REACT_APP_SERVER +
                    `api/Quota/CreditSolicitations/${obj.get('id')}/Cancel`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            ),
        onSuccess: res => {
            toast.success(t('QUOTAS.creditSolicitiationCancelled'));
            const data = res.data;

            const emailData = {
                collaboratorName: data.user.shortname,
                collaboratorEmail: data.user.username,
                value: `R$ ${moneyMask(valueToMoneyFloat(data.value))}`,
                solicitationDate: data.createdAt,
                operatorName: auth.name,
                observation: data.justification,
                context: 'collaborator'
            };

            getQuotaManagersAndSendEmails(
                emailData,
                'api/Quota/CreditSolicitation/Canceled',
                'QUOTAS_CREDIT_SOLICITATION_CANCELED_EMAIL_OPTIONAL'
            );
        },
        onError: () => {
            toast.error(t('QUOTAS.creditSolicitiationCancelledError'));
        },
        onSettled: () => {
            setOpen(false);
            queryPersonalCreditSolicitations.refetch();
        }
    });

    return mutation;
};
