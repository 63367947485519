import React from 'react';
import SettingsContextState from './settings/settingsState';
import CollectionContextState from './collection/collectionState';
import RequisitionsContextState from './requisitions/requisitionsState';
import MyLoansContextState from './myLoans/myLoansState';
import FinesContextState from './fines/finesState';
import QueueContextState from './queue/queueState';

const libraryModuleState = props => {
    return (
        <QueueContextState>
            <MyLoansContextState>
                <RequisitionsContextState>
                    <SettingsContextState>
                        <CollectionContextState>
                            <FinesContextState>
                                {props.children}
                            </FinesContextState>
                        </CollectionContextState>
                    </SettingsContextState>
                </RequisitionsContextState>
            </MyLoansContextState>
        </QueueContextState>
    );
};

export default libraryModuleState;
