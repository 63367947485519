import { DEFAULT, HR } from "../../types"

export default (state, action) => {
    switch (action.type) {
        case DEFAULT.SET_LOADING:
            return {
                ...state,
                loading: true
            };
        case DEFAULT.SET_LOADING_FALSE:
            return {
                ...state,
                loading: false
            };
        case DEFAULT.RESET_MESSAGE:
            return {
                ...state,
                response: "",
                responseType: ""
            };
        case DEFAULT.SET_HAS_CHANGED:
            return {
                ...state,
                hasChanged: 0
            };
        case HR.GET_SERVER_TIME:
            return {
                ...state,
                serverTime: action.payload
            }
        case HR.GET_MY_CLOCK_RECORD:
            return {
                ...state,
                myClockRecord: action.payload
            }
        case HR.CLOCK_IN:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case HR.GET_MY_ACTIVITY_RECORD:
            return {
                ...state,
                myActivityRecord: action.payload
            }
        case HR.ADD_ACTIVITY:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case HR.UPDATE_ACTIVITY:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case HR.GET_COLLABORATORS_CLOCK_RECORDS:
            return {
                ...state,
                collaboratorsClockRecords: action.payload
            }
        case HR.GET_VIEW_COLLABORATOR_CLOCK_RECORD:
            return {
                ...state,
                viewCollaboratorClockRecord: action.payload
            }
        case HR.GET_COLLABORATOR_CLOCK_RECORD_DETAILS:
            return {
                ...state,
                collaboratorClockRecordDetails: action.payload
            }
        case HR.GET_MY_JUSTIFICATIONS:
            return {
                ...state,
                myJustifications: action.payload
            }
        case HR.GET_MY_JUSTIFICATIONS_FORM:
            return {
                ...state,
                myJustificationsForm: action.payload
            }
        case HR.CREATE_MY_JUSTIFICATIONS:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case HR.UPDATE_MY_JUSTIFICATIONS:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case HR.DELETE_MY_JUSTIFICATIONS:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case HR.GET_COLLABORATORS_JUSTIFICATIONS:
            return {
                ...state,
                collaboratorsJustifications: action.payload
            }
        case HR.GET_COLLABORATORS_JUSTIFICATIONS_FORM:
            return {
                ...state,
                collaboratorsJustificationsForm: action.payload
            }
        case HR.APPROVE_COLLABORATORS_JUSTIFICATIONS:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case HR.REFUSE_COLLABORATORS_JUSTIFICATIONS:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        default:
            return state;
    }
}