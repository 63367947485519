/* eslint-disable */
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Check from "@material-ui/icons/Check";
import CloseIcon from '@material-ui/icons/Close';
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Footer from "components/layout/Footer";
import Spinner from "components/layout/spinners/Index";
import UsersContext from "context/users/usersContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import Buttons from "../buttons/Pressed";
import "../profiles/css/FormStyle.css";
import { useStyles } from "./UsersStyles";


const renderRedirect = go => {
  if (go) return <Redirect to={"/users"} />;
};

const EditForm = props => {
  const defaultImage = window.location.origin + "/img/user.jpg";
  const classes = useStyles();
  const { id } = props;
  const [open, setOpen] = useState(false);
  const [t] = useTranslation();
  const [mustRedirect, setMustRedirect] = useState(false);
  const usersContext = useContext(UsersContext);
  const {
    user,
    getUser,
    updateUser,
    getProfiles,
    profiles,
    loading,
    resetMessage,
    responseType
  } = usersContext;

  const [state, setState] = useState({
    id: 0,
    cpf: "",
    name: "",
    email: "",
    profile: "",
    lattesUrl: "",
    isActive: false,
    profileId: 0
  });

  useEffect(() => {
    resetMessage();
    getUser(id);
    setState({
      ...state,
      id: id,
      cpf: user.cpf ? user.cpf : "",
      name: user.name ? user.name : "",
      email: user.email ? user.email : "",
      profile: user.profileName ? user.profileName : "",
      lattesUrl: user.lattesUrl ? user.lattesUrl : "",
      imagePath: user.imagePath ? user.imagePath : "",
      isActive: user.isActive ? user.isActive : false,
      profileId: user.profileId ? user.profileId : 0
    });
    getProfiles();
  }, [user.id, user.profileId, user.isActive]);

  const handleMessageOpen = () => {
    setOpen(false);
  };

  const handleSubmit = e => {
    updateUser(state);
    handleMessageOpen();
    setMustRedirect(true);
    e.preventDefault();
  };

  const handleActiveButton = e => {
    e.preventDefault();
    var active = e.target.value === "true" ? true : false;
    setState({ ...state, isActive: active });
  };

  const setProfileLabel = (array, n) => {
    var i;
    for (i = 0; i < array.length; i++) {
      if (array[i].value === n) {
        document.getElementById("select-profile").innerHTML = array[i].label;
        break;
      }
      if (array[i].value === null) {
        document.getElementById("select-profile").innerHTML = "&nbsp;";
        break;
      }
    }
  };

  const handleChange = name => event => {
    var n = parseInt(event.target.value);
    setProfileLabel(profiles, n);
    setState({ ...state, [name]: n });
  };

  const transitions = useTransition(loading, null, {
    from: { opacity: 0 },
    enter: {
      opacity: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "calc(100vh - 80px)",
    },
    leave: { opacity: 0 }
  });

  const handleClickOpen = e => {
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickLattes = () => {
    if (state.lattesUrl) window.open(state.lattesUrl);
  };

  const options = [
    {
      value: true,
      label: "Ativo"
    },
    {
      value: false,
      label: "Inativo"
    }
  ];

  return transitions.map(({ item, key, props }) =>
    item ? (
      <animated.div key={key} style={props}>
        <Spinner />;
      </animated.div>
    ) : (
        <animated.div key={key} style={props}>
          {renderRedirect(mustRedirect && responseType)}
          <div className="container">
            <Breadcrumbs
              className={classes.breadCrumbs}
              aria-label="breadcrumb"
              separator={<NavigateNextIcon fontSize="small" />}
            >
              <Typography>{t("ROUTES.planning")}</Typography>
              <Typography>{t("ROUTES.config")}</Typography>
              <Link className={classes.link} to="/users">
                {t("USERS.users")}
              </Link>
              <Typography>{t("GENERAL.edit")}</Typography>
            </Breadcrumbs>
            <div className="container-header">
              <div className="title">
                <h1 className={classes.title}>
                  {t("GENERAL.edit") + " " + t("USERS.user").toLowerCase()}
                </h1>
              </div>
              <Button
                className={classes.blueButton}
                variant="contained"
                color="primary"
                form="formUsers"
                type="submit"
              >
                <div className={classes.buttonIcon}>
                  <Check />
                </div>
                {t("GENERAL.update") + " " + t("USERS.user").toLowerCase()}
              </Button>
            </div>
            <form id="formUsers" onSubmit={handleClickOpen}>
              <Paper className={classes.root}>
                <div className={classes.cabecalho}>
                  {t("USERS.user").toUpperCase()}
                </div>
                <div className={classes.bodyForm}>
                  <Grid container>
                    <Hidden mdDown>
                      <Grid item lg={2}>
                        <div className={classes.avatarParent}>
                          <div
                            className="avatar"
                            style={{
                              backgroundImage: `url(${defaultImage + state.imagePath !== ""
                                  ? process.env.REACT_APP_IMGSERVER + state.imagePath
                                  : "/img/icon-default.png"
                                })`
                            }}
                          ></div>
                        </div>
                      </Grid>
                    </Hidden>
                    <Grid container item spacing={2} md={12} lg={10}>
                      <Grid item xs={2}>
                        <TextField
                          className={classes.textField}
                          margin="normal"
                          id="cpf"
                          label="CPF"
                          name="CPF"
                          value={state.cpf}
                          autoFocus
                          InputProps={{
                            readOnly: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          className={classes.textField}
                          margin="normal"
                          id="name"
                          label={t("PERSONAL.name")}
                          name="name"
                          value={state.name}
                          autoFocus
                          InputProps={{
                            readOnly: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          className={classes.textField}
                          margin="normal"
                          id="email"
                          label={t("ADDRESS.email")}
                          name="email"
                          value={state.email}
                          InputProps={{
                            readOnly: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          select
                          id="select-profile"
                          label={t("PROFILES.profile")}
                          name="profile"
                          value={state.profileId}
                          className={classes.textField}
                          onChange={handleChange("profile")}
                          margin="normal"
                        >
                          {profiles.map(profile => (
                            <MenuItem key={profile.value} value={profile.value}>
                              {profile.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={11}>
                        <TextField
                          className={classes.textField}
                          margin="normal"
                          id="lattes"
                          label={t("PERSONAL.lattes")}
                          name="lattes"
                          value={state.lattesUrl}
                          InputProps={{
                            readOnly: true
                          }}
                          onClick={handleClickLattes}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <TextField
                          select
                          label="Status"
                          value={state.isActive}
                          className={classes.textField}
                          onChange={handleActiveButton}
                          SelectProps={{
                            native: true,
                            MenuProps: {
                              className: classes.menu
                            }
                          }}
                          margin="normal"
                        >
                          {options.map(option => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </form>

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle className={classes.Title}>
                {t("GENERAL.update") + " " + t("USERS.user")}
                <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {t("USERS.updateUserQuestion")}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Buttons
                  onClick={handleClose}
                  tipo="BN-noback"
                  conteudo={t("GENERAL.cancel").toUpperCase()}
                />
                <Buttons
                  onClick={handleSubmit}
                  form="formUsers"
                  type="submit"
                  tipo="BN-blue"
                  conteudo={t("GENERAL.update").toUpperCase()}
                />
              </DialogActions>
            </Dialog>
          </div>
          <Footer />
        </animated.div>
      )
  );
};

export default EditForm;
