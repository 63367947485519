import * as React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import { tooltipStyles } from './Styles'



const DefaultTooltip = ({text, ...props}) => {
  const styles = tooltipStyles();

  return (
      <Tooltip {...props} title={text} placement="top-start">
          <HelpIcon/>
      </Tooltip>
  );
}


export default DefaultTooltip;