import AllowancesContext from 'context/allowance/allowanceContext';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route } from 'react-router-dom';
import history from '../../history';
import { getAuthenticationData, authorized, isLogged } from './Authentication';
import auth from './auth';
import { ImageAutoAdjust } from 'mdi-material-ui';
import Cookies from 'js-cookie';

export const ProtectedRouteGeneral = props => {
    const {
        component: Component,
        screen,
        allowType,
        canBeAcessedBySameId,
        ...rest
    } = props;
    let canAccess = false;
    const [t] = useTranslation();
    const allowanceContext = useContext(AllowancesContext);
    const { setAllowanceMessage } = allowanceContext;

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            if (isLogged()) {
                if (
                    localStorage.getItem('auth-token') !== null &&
                    Cookies.get('auth-token') ===
                        JSON.parse(localStorage.getItem('auth-token')).token
                ) {
                    setIsAuthenticated(true);
                } else {
                    var result = await getAuthenticationData();
                    setIsAuthenticated(result);
                }
            } else {
                setIsAuthenticated(false);
            }

            setLoading(false);
        };
        fetchData();
    }, []);

    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated ? (
                    authorized(screen, canBeAcessedBySameId, allowType) ? (
                        <Component {...props} />
                    ) : (
                        // <React.Fragment>{window.location.href = process.env.REACT_APP_SITE}</React.Fragment>
                        <Redirect
                            to={{
                                pathname: '/forbidden',
                                state: {
                                    from: props.location
                                }
                            }}
                        />
                    )
                ) : loading ? (
                    <div>loading...</div>
                ) : (
                    <React.Fragment>
                        {(window.location.href = process.env.REACT_APP_SITE)}
                    </React.Fragment>
                    // <Redirect
                    //     to={{
                    //         pathname: "/forbidden",
                    //         state: {
                    //             from: props.location
                    //         }
                    //     }}
                    // />
                )
            }
        />
    );
};
