import React, { useContext, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { useStyles } from "components/layout/CommonStyles";
import { useTranslation } from "react-i18next";
import CloseIcon from '@material-ui/icons/Close';
import Buttons from "components/buttons/Pressed";
import Grid from "@material-ui/core/Grid";
import Input from "components/inputs/Input";
import SettingsContext from "context/hrModule/settings/settingsContext";

const DialogAddJob = props => {
    const classes = useStyles();
    const [t] = useTranslation();

    const settingsContext = useContext(SettingsContext);

    const {
        addJob
    } = settingsContext


    const [values, setValues] = useState({
        name: "",
        description: "",
    });


    const [requiredName, setRequiredName] = useState(false);
    const [nameErrorMessage, setNameErrorMessage] = useState("");

    const { open, setOpen, jobs, fetchData } = props;

    const clearDialog = () => {
        setValues({
            name: "",
            description: ""
        });

        setRequiredName(false);
    }


    const handleClose = () => {
        clearDialog();
        setOpen(false);
    }
    const handleChange = (name, value) => {
        let val = value;
        setValues({ ...values, [name]: val })
    }

    const verifySubmit = () => {
        let res = true;

        if (jobs.map(x => !x.hasDeleted && x.name.toLowerCase().trim()).includes(values.name.toLowerCase().trim())) {
            res = false;
            setRequiredName(true);
            setNameErrorMessage(t("HR.jobNameAlreadyInUse"))
        }

        if (!values.name.trim()) {
            res = false;
            setRequiredName(true);
            setNameErrorMessage(t("GENERAL.requiredField"))
        }
        return res;
    }


    const handleSubmit = () => {
        const canSubmit = verifySubmit();
        if (canSubmit) {
            let formData = new FormData();

            formData.append("job.name", values.name ? values.name : "");
            formData.append("job.description", values.description ? values.description : "");
            formData.append("job.version", 0)


            addJob(formData, fetchData);
            handleClose();

        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle className={classes.Title}>
                <b>{t("HR.addJob")}</b>
                <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent

            >
                <Grid container item spacing={2} md={12} lg={12}>
                    <Grid item xs={12} sm={12}>
                        <Input
                            required
                            label={"HR.jobName"}
                            value={values.name}
                            valueName={"name"}
                            handleChange={handleChange}
                            maxLength={50}
                            error={requiredName}
                            helperText={requiredName && nameErrorMessage}
                            setRequired={setRequiredName}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Input
                            label={"GENERAL.description"}
                            value={values.description}
                            valueName={"description"}
                            rows={5}
                            maxLength={500}
                            handleChange={handleChange}
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Buttons
                    onClick={handleClose}
                    tipo="BN-noback"
                    conteudo={t("GENERAL.cancel").toUpperCase()}
                />
                <Buttons
                    onClick={handleSubmit}
                    tipo="BN-blue"
                    conteudo={t("GENERAL.add").toUpperCase()}
                />
            </DialogActions>

        </Dialog >
    )
}

export default DialogAddJob;