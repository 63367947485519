import React from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";

import Dropzone from "react-dropzone";
import Grid from '@material-ui/core/Grid';
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Attachment from "@material-ui/icons/Attachment";

const InputDragDrop = ({ disabled = false, files, onDrop, hasUpdatedFile, imagePath }) => {
    const classes = useStyles();
    const [t] = useTranslation();

    const imageFile = files.map(file => (
        <div className={classes.thumb} key={file.name}>
            <div className={classes.thumbInner}>
                <img
                    src={file.preview}
                    className={classes.imgNewEquipment}
                />
            </div>
        </div>
    ));

    return (
        <Dropzone
            disabled={disabled}
            variant="outlined"
            component="span"
            className={classes.textField}
            onDrop={onDrop}
            multiple={false}
            accept="image/jpeg, image/png,  image/bmp"
        >
            {({
                getRootProps,
                getInputProps,
                isDragActive,
                isDragReject
            }) => (
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {!isDragActive && (
                        <FormControl
                            className={!disabled ? classes.customAttachedDocumentTextField : classes.customAttachedDocumentTextFieldDisabled}
                        >
                            {
                                hasUpdatedFile ?
                                    <Grid item xs={2}>
                                        {imageFile}
                                        <div className={classes.attachImgBook}>{t("Alterar imagem")}</div>
                                    </Grid>
                                    :
                                    imagePath === "" ?
                                        <Grid item xs={2}>
                                            <div className={classes.thumb} key={"upload-equipament-image.jpg"}>
                                                <div className={classes.thumbInner}>
                                                    <img className={classes.imgNewEquipment} alt="add-image" src={window.location.origin + '/img/upload-equipament-image.png'} />
                                                </div>
                                            </div>
                                            {!disabled &&
                                                <div className={classes.attachImgBook}>{t("Carregar imagem")}</div>}
                                        </Grid>
                                        :
                                        <Grid item xs={2}>
                                            <div className={classes.thumb}>
                                                <div className={classes.thumbInner}>
                                                    <img className={classes.imgNewEquipment} alt="edit-image" src={imagePath} />
                                                </div>
                                            </div>
                                            {!disabled &&
                                                <div className={classes.attachImgBook}>{t("Alterar imagem")}</div>}
                                        </Grid>
                            }
                        </FormControl>
                    )}
                    {isDragReject && (
                        <FormControl
                            className={classes.customAttachedDocumentTextField}
                        >
                            <Input
                                id="custom-attached-input-cooperation"
                                readOnly={true}
                                endAdornment={
                                    <InputAdornment
                                        style={{ transform: "rotate(270deg)" }}
                                        position="end"
                                    >
                                        <Attachment />
                                    </InputAdornment>
                                }
                                aria-describedby="weight-helper-text"
                                inputProps={{
                                    "aria-label": "weight"
                                }}
                            />
                        </FormControl>
                    )}
                </div>
            )}
        </Dropzone>
    )
}

export default InputDragDrop;