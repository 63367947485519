import React from 'react';
import { screenStyles } from './Styles';
import Typography from 'components/typography/Typography';

const DialogObservationUnit = props => {
    const { head, content } = props;
    const styles = screenStyles();

    return (
        <>
            <Typography
                variant={'RobotoBold'}
                fontSize={15}
                textColor={'secondary'}
            >
                {head.toUpperCase()}
            </Typography>
            <Typography
                className={styles.dialogObservation}
                variant={'RobotoRegular'}
                fontSize={12}
                textColor={'secondary'}
            >
                {content}
            </Typography>
        </>
    );
};

export default DialogObservationUnit;
