import React, { useContext, useEffect, useState } from "react";
import "../../../../App.css";
import SideBar from "components/layout/sidebar/Sidebar";
import DashboardNavBar from "components/layout/DashboardNavBar";
import CollaboratorsClockRecords from "./CollaboratorsClockRecords";

const CallMyActivities = ({ match }) => {

    return (
        <div>
            <DashboardNavBar />
            <SideBar />
            <CollaboratorsClockRecords/>
        </div>
    );
}

export default CallMyActivities;