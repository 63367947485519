import styled, {css} from "styled-components";
import ArrowheadIcon from "../../Icons/ArrowheadIcon/ArrowheadIcon";

const heightOptionItem = 36

const maxHeightOptionWrapper = css`
    max-height: ${props => props.$optionQuantity > 10 ? (props.$optionQuantity / 2) * heightOptionItem + "px" : props.$optionQuantity * heightOptionItem + "px"};
`

export const SelectContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    position: relative;
`

export const SelectWrapper = styled.div`
    height: 100%;
    background-color: #F5F6FA;
    display: flex;
    border-radius: 4px;
    padding: 8px 8px;
    cursor: pointer;

    &:hover {
        background-color: rgb(224, 224, 224);
    }
`

export const Select = styled.button`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-self: center;
    font-size: 14px;
    color: rgba(0, 0, 0, .60);
    background: none;
    cursor: pointer;
`

export const OptionsWrapper = styled.ul`
    list-style: none;
    background-color: #F5F6FA;
    position: absolute;
    width: 100%;
    z-index: 1;
    padding: 0;
    margin: 0;
    overflow-y: auto;
    border-radius: 4px;
    transform: translateY(44px);
    -ms-transform: translateY(44px);
    -webkit-transform: translateY(44px);
    animation: ${props => props.$isMounted ? "growHeight" : "shrinkHeight"} 250ms cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    ${maxHeightOptionWrapper};

    @keyframes growHeight {
        from {
            max-height: 0;
        }
        1% {
            opacity: 0;
        }
        to {
            ${maxHeightOptionWrapper};
        }
    }

    @keyframes shrinkHeight {
        from {
            ${maxHeightOptionWrapper};
        }
        99% {
            opacity: 0;
        }
        to {
            max-height: 0;
        }
    }
`

export const Option = styled.li`
    display: flex;
    align-items: center;
    height: ${heightOptionItem}px;
    padding: 0 10px;
    cursor: pointer;
    color: rgba(0, 0, 0, .84);
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:hover {
        background-color: rgb(224, 224, 224);
    }
`

export const ArrowheadIconStyled = styled(ArrowheadIcon)`
    width: 10px;
    height: 5px;

    svg {
        width: 10px;
        height: 5px;
        fill: rgba(0, 0, 0, 0.54);
    }
`
