import axios from 'axios';
import { useMutation } from 'react-query';
import Cookies from 'js-cookie';
import { useQueryLSBDDashboard } from './useQueryLSBDDashboard';
import { useSnackbar } from 'hooks/UseSnackbar';
import { useTranslation } from 'react-i18next';
import { useQueryLSBDExpenses } from './useQueryLSBDExpenses';
import { useQueryLSBDTransactions } from './useQueryLSBDTransactions';
import { useQueryLSBDCashFlowTransactions } from './useQueryLSBDCashFlowTransactions';

export const useMutationCreateDebit = setOpen => {
    const toast = useSnackbar();
    const [t] = useTranslation();

    const queryDashboard = useQueryLSBDDashboard({ enabled: false });
    const queryExpenses = useQueryLSBDExpenses({ enabled: false });
    const queryTransactions = useQueryLSBDTransactions({ enabled: false });
    const queryCashFlow = useQueryLSBDCashFlowTransactions({ enabled: false });

    const mutation = useMutation({
        mutationFn: async obj =>
            await axios.post(
                process.env.REACT_APP_SERVER + `api/Quota/Transactions/Debit`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            ),
        onSuccess: res => {
            toast.success(t('QUOTAS.addDebitSuccess'));
        },
        onError: () => {
            toast.error(t('QUOTAS.addDebitError'));
        },
        onSettled: () => {
            setOpen(false);
            queryDashboard.refetch();
            queryExpenses.refetch();
            queryTransactions.refetch();
            queryCashFlow.refetch();
        }
    });

    return mutation;
};
