import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
// import MaterialIcon from "material-icons-react/";
import SettingsApplications from '@material-ui/icons/SettingsApplications';
import React, { useEffect, useState, useContext } from 'react';
import SideBarConfigMenu from './SideBarConfigMenu';
import InventorySideBarConfigMenu from './InventorySideBarConfigMenu';
import HrSideBarConfigMenu from './HrSideBarConfigMenu';
import LibrarySideBarConfigMenu from './LibrarySideBarConfigMenu';
import QuotasSideBarConfigMenu from './QuotasSideBarConfigMenu';
import AdminSideBarConfigMenu from './AdminSideBarConfigMenu';
import AllowancesContext from 'context/allowance/allowanceContext';
import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';
import { Screen, Allow } from 'global/constants';

const useStyles = makeStyles(() => ({
    iconTitle: {
        width: '150px',
        height: '17px',
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 2,
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#ffffff',
        marginLeft: '10px'
    },
    planning: {
        width: '55px',
        height: '11px',
        opacity: '0.54',
        fontFamily: 'Roboto',
        fontSize: '9px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '3.11',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#ffffff',
        marginLeft: '44px'
    }
}));

const SideBarMenu = props => {
    const classes = useStyles();
    const allowanceContext = useContext(AllowancesContext);
    const { allAllowances, getAllPermission } = allowanceContext;
    const [isHovered, setIsHovered] = useState(false);
    const authToken = localStorage.getItem('auth-token');
    const allAllowancesString = JSON.stringify(allAllowances);

    useEffect(() => {
        getAllPermission();
    }, [allAllowances.length, allAllowancesString]);

    const [hasAdminPermission, setHasAdminPermission] = useState(false);
    useEffect(() => {
        setHasAdminPermission(
            GetGeneralPermissionForAction(Screen.ADMIN_USERS, Allow.READ)
        );
    }, [])

    const hasPermission = (auth, screen, allowance) => {
        if (auth === null)
            return false;

        for (var i = 0; i < auth.permissions.length; i++) {
            var permission = auth.permissions[i].standardauthorizationoption;
            var resource = auth.permissions[i].resource;
            if (resource === screen) {
                return permission[allowance];
            }
        }
        return false;
    }

    const hasAnyPermission = (auth, permissions) => {
        if (auth === null)
            return false;

        for (var i = 0; i < auth.permissions.length; i++) {
            var permission = auth.permissions[i].standardauthorizationoption;
            var resource = auth.permissions[i].resource;
            for (var j = 0; j < permissions.length; j++) {
                if (resource === permissions[j].screen) {
                    return permission[permissions[j].allowance];
                }
            }
        }
        return false;
    }

    const [hasAnyProjectsModulePermission, setHasAnyProjectsModulePermission] = useState(false);
    useEffect(() => {
        if (authToken === null)
            return false;

        setHasAnyProjectsModulePermission(allAllowances.length > 0 &&
            hasAnyPermission(JSON.parse(authToken), [
                { screen: 'PROFILE', allowance: 'allowCreate' },
                { screen: 'PROFILE', allowance: 'allowRead' },
                { screen: 'PROFILE', allowance: 'allowUpdate' },
                { screen: 'PROFILE', allowance: 'allowDelete' },
                { screen: 'USER', allowance: 'allowCreate' },
                { screen: 'USER', allowance: 'allowRead' },
                { screen: 'USER', allowance: 'allowUpdate' },
                { screen: 'USER', allowance: 'allowDelete' },
                { screen: 'PARTNER', allowance: 'allowCreate' },
                { screen: 'PARTNER', allowance: 'allowRead' },
                { screen: 'PARTNER', allowance: 'allowUpdate' },
                { screen: 'PARTNER', allowance: 'allowDelete' }
            ]));
    }, [allAllowances.length, authToken]);

    useEffect(() => {
        setIsHovered(props.isHover);
        return () => {
            setIsHovered(false);
        };
    }, [props.isHover]);

    return isHovered ? (
        <>
            {hasAdminPermission && (
                <>
                    <div className={'menu-header-logo-left'} style={{ paddingTop: '20px' }}>
                        <SettingsApplications color="secondary" />
                        <Typography className={classes.iconTitle}>
                            Administrativo
                        </Typography>
                    </div>
                    <AdminSideBarConfigMenu />
                </>
            )}

            {hasAnyProjectsModulePermission && (
                <>
                    <div className={'menu-header-logo-left'} style={{ paddingTop: '20px' }}>
                        <SettingsApplications color="secondary" />
                        <Typography className={classes.iconTitle}>
                            Projetos
                        </Typography>
                    </div>
                    <div style={{ paddingTop: '18px', marginBottom: '10px' }} className={classes.planning}>
                        Planejamento
                    </div>
                </>
            )}
            <SideBarConfigMenu />
            <div className={'menu-header-logo-left'} style={{ paddingTop: '20px' }}>
                <SettingsApplications color="secondary" />
                <Typography className={classes.iconTitle}>
                    Patrimônio
                </Typography>
            </div>
            <InventorySideBarConfigMenu />
            <div className={'menu-header-logo-left'} style={{ paddingTop: '20px' }}>
                <SettingsApplications color="secondary" />
                <Typography className={classes.iconTitle}>
                    Gestão de Pessoas
                </Typography>
            </div>
            <HrSideBarConfigMenu />
            <div className={'menu-header-logo-left'} style={{ paddingTop: '20px' }}>
                <SettingsApplications color="secondary" />
                <Typography className={classes.iconTitle}>
                    Biblioteca
                </Typography>
            </div>
            <LibrarySideBarConfigMenu />
            <div className={'menu-header-logo-left'} style={{ paddingTop: '20px' }}>
                <SettingsApplications color="secondary" />
                <Typography className={classes.iconTitle}>Cotas</Typography>
            </div>
            <QuotasSideBarConfigMenu />
            <div style={{ marginBottom: '28px' }}></div>
        </>
    ) : (
        <>
            {hasAdminPermission && (
                <div className={'menu-header-logo'} style={{ paddingTop: '39px' }}>
                    <SettingsApplications color="secondary" />
                </div>
            )}

            {hasAnyProjectsModulePermission && (
                <div className={'menu-header-logo'} style={{ paddingTop: '39px' }}>
                    <SettingsApplications color="secondary" />
                </div>
            )}

            <div className={'menu-header-logo'} style={{ paddingTop: '39px' }}>
                <SettingsApplications color="secondary" />
            </div>

            <div className={'menu-header-logo'} style={{ paddingTop: '39px' }}>
                <SettingsApplications color="secondary" />
            </div>

            <div className={'menu-header-logo'} style={{ paddingTop: '39px' }}>
                <SettingsApplications color="secondary" />
            </div>

            <div className="menu-header-logo" style={{ paddingTop: '22px' }}>
                <MoreHoriz color="secondary" />
            </div>
        </>
    );
};

export default SideBarMenu;
