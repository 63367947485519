/* eslint-disable */
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Footer from "components/layout/Footer";
import InventoryContext from "context/inventory/inventoryContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { green } from '@material-ui/core/colors';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import LicenseForm from './LicenseForm';
import Transition from "components/layout/transition/Transition";
import Breadcrumb from "components/layout/breadcrumb/Breadcrumb";
import NewLicenseTable from "./NewLicenseTable"
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import Toolbar from "@material-ui/core/Toolbar";

const SoftwaresList = () => {
    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }
    const classes = useStyles();
    const [t] = useTranslation();
    const inventoryContext = useContext(InventoryContext);
    const [files, setFiles] = useState([]);

    //soft
    const [softwareDatasFilled, setSoftwareDatasFilled] = useState(false);
    const [licenseDatasFilled, setLicenseDatasFilled] = useState(false);
    const [titleHeader, setTitleHeader] = useState("INVENTORY.softwareSelection")
    const [contextNewLicense, setContextNewLicense] = useState(false);
    const [dataFilter, setDataFilter] = useState([]);
    const [softwareId, setSoftwareId] = useState("");
    const [filterName, setFilterName] = useState("");
    const [softwareName, setSoftwareName] = useState("");
    const [softwareCategory, setSoftwareCategory] = useState("");

    const [values, setValues] = useState({
        image: "",
        name: "",
        manufacturer: "",
        newPlatforms: [""],
        category: "",
        description: "",
        softwareCategoryId: "",
        accessLink: ""
    });

    const {
        loading,
        platforms,
        addSoftwareAndLicense,
        softwares,
        getSoftwares
    } = inventoryContext;

    useEffect(() => {
        getSoftwares()
    }, []);

    const hasItens = softwares.length > 0;
    const [hasNoResult, setHasNoResult] = useState(hasItens && dataFilter.length === 0);

    useEffect(() => {
        if (softwares && softwares.length) {
            let data = softwares.filter(
                item =>
                    item.hasdeleted == false
            );
            setDataFilter(data);
            setHasNoResult(false);
        }
    }, [softwares]);

    useEffect(() => {
        let newDataFilter = [];
        let textNoFound = "";

        newDataFilter = softwares.filter(
            item =>
                item.hasdeleted == false
        );
        if (filterName) {
            newDataFilter = newDataFilter.filter(item =>
                item.name.toLowerCase().startsWith(filterName.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0) textNoFound = filterName;
        }
        setDataFilter(newDataFilter);

    }, [filterName]);


    const changeTabSoftware = () => {
        if (softwareDatasFilled) return <CheckCircleIcon style={{ marginLeft: 5, color: green[500] }} />
        else return <RadioButtonUncheckedIcon style={{ marginLeft: 5 }} />
    }

    const changeTabLicense = () => {
        if (licenseDatasFilled) return <CheckCircleIcon style={{ marginLeft: 5, color: green[500] }} />
        else return <RadioButtonUncheckedIcon style={{ marginLeft: 5 }} />
    }

    const handleChangeNewPlatforms = (indexField, newValue) => {
        const platform = newValue ? newValue : "";
        if (platform.length < 50) {
            const newPlatform = [...values.newPlatforms];
            newPlatform[indexField] = platform;
            setValues({ ...values, newPlatforms: newPlatform });
        }
    }

    const changeContext = (bool, title, softwareId, softwareName, softwareCategory) => {
        setTitleHeader(title)
        setContextNewLicense(bool)
        setSoftwareId(softwareId)
        setSoftwareName(softwareName)
        setSoftwareCategory(softwareCategory)
    }

    return (
        <Transition loading={loading}>
            <div className="container">
                <Breadcrumb
                    routes={[
                        { label: t("INVENTORY.inventory") },
                        { label: t("INVENTORY.softwares"), to: "/Software" },
                        { label: t("INVENTORY.addSoftware") },
                    ]}
                />
                <div className="container-header">
                    <div className="title">
                        <h1 className={classes.title}>{t("INVENTORY.addSoftware")}</h1>
                    </div>
                </div>
                <div className={classes.containerTabsNewSoftware}>
                    <span style={{ display: "flex" }} onClick={(ev) => changeContext(false, "INVENTORY.dataSoftware")}>
                        <Typography>{t("INVENTORY.dataSoftware")}</Typography>
                        {changeTabSoftware()}
                    </span>
                    <ChevronRightIcon />
                    <span style={{ display: "flex" }}>
                        <Typography>{t("INVENTORY.dataLicense")}</Typography>
                        {changeTabLicense()}
                    </span>
                </div>
                <Paper >
                    <div className="cabecalho">
                        {t(titleHeader).toUpperCase()}
                        {contextNewLicense && values.name}
                    </div>
                    {!contextNewLicense ?
                        <React.Fragment>
                            <Toolbar className={classes.searchToolbarInventory}>
                                <div style={{ display: !hasItens ? "none" : "" }} className={classes.searchInventory}>
                                    <div className={classes.searchIcon}>
                                        <SearchIcon />
                                    </div>
                                    <InputBase
                                        placeholder={t("INVENTORY.name")}
                                        value={filterName}
                                        onChange={(ev) => setFilterName(ev.target.value)}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputInput
                                        }}
                                        inputProps={{ "aria-label": "search" }}
                                    />
                                </div>
                            </Toolbar>
                            <NewLicenseTable
                                rows={dataFilter}
                                headers={["id", "name", "category"]}
                                visibleHeaders={["nome",]}
                                sortedFields={["name",]}
                                mainField="name"
                                changeContext={changeContext}
                            />
                        </React.Fragment>
                        :
                        <LicenseForm
                            datas={values}
                            files={files}
                            changeContext={changeContext}
                            platforms={platforms}
                            addSoftwareAndLicense={addSoftwareAndLicense}
                            context={"newLicenseOnly"}
                            softwareId={softwareId}
                            softwareCategory={softwareCategory}
                            softwareName={softwareName}
                        />
                    }
                </Paper>
            </div>
            <Footer />
        </Transition>
    )
};

export default SoftwaresList;