/* eslint-disable */
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Footer from "components/layout/Footer";
import AllowancesContext from "context/allowance/allowanceContext";
import SearchContext from "context/search/searchContext";
import HRCostsContext from "context/hrcosts/hrCostsContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { animated, useTransition } from "react-spring";
import Spinner from "../layout/spinners/Index";
import { useStyles } from "../profiles/ProfileStyles";
import TableActions from "./HRCostsTableActions";

const HRCostsTable = () => {
  const [t] = useTranslation();
  const classes = useStyles();
  const hrCostsContext = useContext(HRCostsContext);
  const allowanceContext = useContext(AllowancesContext);
  const searchContext = useContext(SearchContext);
  const {
    getUsers,
    users,
    loading,
  } = hrCostsContext;
  const {
    verifyPermission,
    permission
  } = allowanceContext;

  const { filter } = searchContext;
  const [snackBar, setSnackBar] = useState({
    open: false,
    kind: "",
    content: ""
  });
  let dataFilter = [];

  useEffect(() => {
    getUsers();
    verifyPermission("USERCOSTS")
    return () => {};
  }, [permission.length]);

  dataFilter = users;
  
  if (filter) {
    dataFilter = dataFilter.filter(
      item =>
        item.cpf.toLowerCase().startsWith(filter.toLowerCase()) ||
        item.name.toLowerCase().startsWith(filter.toLowerCase())
    );
  }

  const transitions = useTransition(loading, null, {
    from: { opacity: 0 },
    enter: {
      opacity: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "calc(100vh - 70px)"
    },
    leave: { opacity: 0 }
  });

  return transitions.map(({ item, key, props }) =>
    item ? (
      <animated.div key={key} style={props}>
        <Spinner />;
      </animated.div>
    ) : (
      <animated.div key={key} style={props}>
        <div className="container">
          <Breadcrumbs
            className={classes.breadCrumbs}
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Typography>{t("GENERAL.workPlan")}</Typography>
            <Typography>{t("HRCOSTS.hrCosts")}</Typography>
          </Breadcrumbs>
          <div className="container-header">
            <div className="title">
              <h1 className={classes.title}>{t("HRCOSTS.hrCosts")}</h1>
            </div>
          </div>
          <TableActions
            rows={dataFilter}
            headers={["id", "imagePath", "cpf", "name", "email", "userCost", "isActiveInHR"]}
            sortedFields={["imagePath", "cpf", "name", "email", "userCost", "isActiveInHR"]}
            visibleHeaders={["#", "CPF", "NOME", "E-MAIL", "CUSTO ANUAL", "STATUS"]}
            mainField="imagePath"
            permission={permission}
          />
        </div>
        <Footer />
      </animated.div>
    )
  );
};

export default HRCostsTable;