/**
 * Converts a date string to the format dd/mm/yyyy
 * @param {*} date
 * @returns {Date} date in the format dd/mm/yyyy
 */
export const dateToBR = date => {
    if (isNaN(Date.parse(date))) {
        return date;
    }

    const dateObject = new Date(date);
    const day = dateObject.getDate().toString().padStart(2, '0');
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // +1 because January is 0.
    const year = dateObject.getFullYear();
    return `${day}/${month}/${year}`;
};
