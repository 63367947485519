import React from "react";
import "../../../../App.css";
import SideBar from "components/layout/sidebar/Sidebar";
import DashboardNavBar from "components/layout/DashboardNavBar";
import View from "./View";

const CallView = ({ match }) => {
  return (
    <div>
      <DashboardNavBar />
      <SideBar />
      <View idItem={match.params.id}/>
    </div>
  );
}

export default CallView;