export const HourMinMask = value => {
    const onlyNumbers = value.replace(/\D/g, '');
    const firstHourValue = new RegExp('^[0-2]$');
    const zeroToThree = new RegExp('^[0-3]$');
    const firstMinuteValue = new RegExp('^[0-5]$');
    const zeroToNine = new RegExp('^[0-9]$');
    let valueFormatted = "";
    for (let i = 0; i < onlyNumbers.length; i++) {
        if (i === 0) {
            if (firstHourValue.test(onlyNumbers[i])) {
                valueFormatted += onlyNumbers[i];
            }
        } else if (i === 1) {
            if (onlyNumbers[0] === "2") {
                if (zeroToThree.test(onlyNumbers[i])) {
                    valueFormatted += onlyNumbers[i];
                }
            } else {
                if (zeroToNine.test(onlyNumbers[i])) {
                    valueFormatted += onlyNumbers[i];
                }
            }
        }
        else if (i === 2) {
            if (firstMinuteValue.test(onlyNumbers[i])) {
                valueFormatted += ":" + onlyNumbers[i];
            }
        } else {
            if (zeroToNine.test(onlyNumbers[i])) {
                valueFormatted += onlyNumbers[i];
            }
        }

    }





    return valueFormatted

    // .replace(/(\d{2})(\d) /, "$1:$2")
}
// /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/