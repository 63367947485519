import { Months } from 'global/constants';

export const GetMonthAndYearInWords = (date, option) => {
    let string = '';

    if (option === 2) {
        string = `${Months[date.getMonth()].label} de ${date.getFullYear()}`;
    } else {
        string = `${Months[date.getMonth()].label}/${date.getFullYear()}`;
    }

    return string;
};
