import React, { useState, useEffect, useContext } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import Breadcrumb from 'components/layout/breadcrumb/Breadcrumb';
import Footer from 'components/layout/Footer';
import BooksLoanForm from 'pages/libraryModule/MyLoans/forms/BooksLoanForm';
import HistoryForm from 'pages/libraryModule/MyLoans/forms/HistoryForm';
import PendingRequisitionsForm from 'pages/libraryModule/MyLoans/forms/PendingRequisitionsForm';
import HeaderContainer from 'components/containers/HeaderContainer';
import { screenStyles } from 'pages/libraryModule/MyLoans/Styles';
import Transition from 'components/layout/transition/Transition';
import {
    LibraryQueueStatusValues,
    LibraryReservationStatusValues
} from 'global/constants';
import MyLoansContext from 'context/libraryModule/myLoans/myLoansContext';
import { QueryGetValue } from 'utils/general/QueryString';
import RequisitionsContext from 'context/libraryModule/requisitions/requisitionsContext';
import QueuedBooksForm from 'pages/libraryModule/MyLoans/forms/QueuedBooksForm';
import QueueContext from 'context/libraryModule/queue/queueContext';

const MyLoansTabPanel = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const [value, setValue] = useState(0);
    const styles = screenStyles();
    const [panelQuery, setPanelQuery] = useState('');

    const [booksLoanData, setBooksLoanData] = useState([]);
    const [pendingRequisitionsData, setPendingRequisitionsData] = useState([]);
    const [historyData, setHistoryData] = useState([]);
    const [queuedBooksData, setQueuedBooksData] = useState([]);

    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const myLoansContext = useContext(MyLoansContext);
    const queueContext = useContext(QueueContext);
    const requisitionsContext = useContext(RequisitionsContext);
    const { updateRequisition, cancelQueue } = requisitionsContext;
    const { loading, setLoading, setLoadingFalse, getMyLoans, myLoans } =
        myLoansContext;

    const { getMyQueue, myQueue } = queueContext;

    async function fetchData() {
        await setLoading();
        await getMyLoans(auth.id);
        await getMyQueue(auth.id);
        await setLoadingFalse();
    }

    const submitRequisition = (id, justification, emailData) => {
        let formData = new FormData();

        formData.append(
            'status',
            LibraryReservationStatusValues.CANCELED_COLLABORATOR
        );
        formData.append('justification', justification);
        formData.append('canceledBy', auth.id);

        emailData.name = auth.name;
        emailData.colaboratorName = auth.name;

        updateRequisition(
            id,
            'cancel',
            formData,
            emailData,
            fetchData,
            'MY_LOANS'
        );
    };

    const submitQueued = (id, justification, emailData) => {
        let formData = new FormData();

        formData.append(
            'status',
            LibraryReservationStatusValues.CANCELED_COLLABORATOR
        );
        formData.append('justification', justification);
        formData.append('canceledBy', auth.id);

        emailData.operator = auth.name;
        emailData.endpoint = 'BookQueue/canceledRequisition';
        emailData.context = 'collaborator';

        cancelQueue(id, formData, fetchData, emailData);
    };

    useEffect(() => {
        fetchData();
        setPanelQuery(QueryGetValue('panel'));
    }, []);

    useEffect(() => {
        if (panelQuery === 'BOOKSLOAN') {
            setValue(0);
        }
        if (panelQuery === 'PENDINGREQUISITIONS') {
            setValue(1);
        }
        if (panelQuery === 'HISTORY') {
            setValue(2);
        }
        if (panelQuery === 'QUEUEDBOOKS') {
            setValue(3);
        }
    }, [panelQuery]);

    useEffect(() => {
        if (myQueue) {
            setQueuedBooksData(
                myQueue.filter(
                    item => item.status === LibraryQueueStatusValues.IN_QUEUE
                )
            );
        }
        if (myLoans) {
            setBooksLoanData(
                myLoans.filter(
                    r =>
                        r.status === LibraryReservationStatusValues.LOAN ||
                        r.status === LibraryReservationStatusValues.LATE
                )
            );

            setPendingRequisitionsData(
                myLoans.filter(
                    r =>
                        r.status ===
                            LibraryReservationStatusValues.AWAITING_APPROVAL ||
                        r.status ===
                            LibraryReservationStatusValues.AWAITING_WITHDRAWAL ||
                        r.status ===
                            LibraryReservationStatusValues.AWAITING_DEVOLUTION
                )
            );
            setHistoryData(
                myLoans.filter(
                    r =>
                        r.status ===
                            LibraryReservationStatusValues.CANCELED_MANAGEMENT ||
                        r.status ===
                            LibraryReservationStatusValues.CANCELED_COLLABORATOR ||
                        r.status === LibraryReservationStatusValues.FINISHED
                )
            );
        }
    }, [myLoans, myQueue]);

    function TabPanel(props) {
        const { children, value, index } = props;

        return <div>{value === index && children}</div>;
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`
        };
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const tabContext = () => {
        if (value === 0) {
            return <BooksLoanForm BooksLoanData={booksLoanData} />;
        }
        if (value === 1) {
            return (
                <PendingRequisitionsForm
                    PendingRequisitionsData={pendingRequisitionsData}
                    submit={submitRequisition}
                />
            );
        }
        if (value === 2) {
            return <HistoryForm HistoryData={historyData} />;
        }
        if (value === 3) {
            return (
                <QueuedBooksForm
                    QueuedBooksData={queuedBooksData}
                    submitQueued={submitQueued}
                />
            );
        }
    };

    return (
        <React.Fragment>
            <Transition loading={loading} newDesign={true}>
                <HeaderContainer>
                    <Breadcrumb
                        newDesign={true}
                        routes={[
                            { label: t('INVENTORY.library'), to: '/Library' },
                            { label: t('LIBRARY.myLoans') }
                        ]}
                    />
                    <div className="container-headerNewDesign">
                        <h1 className={classes.titleNewDesign}>
                            {t('LIBRARY.myLoans')}
                        </h1>
                    </div>
                </HeaderContainer>

                <div className="containerNewDesign">
                    <Paper>
                        <AppBar
                            position="static"
                            style={{ boxShadow: 'none', transform: 'none' }}
                        >
                            <Tabs
                                selectionFollowsFocus
                                style={{
                                    backgroundColor: '#f5f6fa',
                                    color: 'black'
                                }}
                                indicatorColor="primary"
                                value={value}
                                onChange={handleChange}
                            >
                                <Tab
                                    classes={{
                                        wrapper: styles.iconLabelWrapper2,
                                        labelContainer: styles.labelContainer
                                    }}
                                    style={{ fontWeight: 'bold' }}
                                    label={t('LIBRARY.booksLoan').toUpperCase()}
                                    {...a11yProps(0)}
                                />
                                <Tab
                                    classes={{
                                        wrapper: styles.iconLabelWrapper2,
                                        labelContainer: styles.labelContainer
                                    }}
                                    style={{ fontWeight: 'bold' }}
                                    label={t(
                                        'LIBRARY.pendingRequisitions'
                                    ).toUpperCase()}
                                    {...a11yProps(1)}
                                />
                                <Tab
                                    classes={{
                                        wrapper: styles.iconLabelWrapper2,
                                        labelContainer: styles.labelContainer
                                    }}
                                    style={{ fontWeight: 'bold' }}
                                    label={t(
                                        'LIBRARY.historyLoans'
                                    ).toUpperCase()}
                                    {...a11yProps(2)}
                                />
                                <Tab
                                    classes={{
                                        wrapper: styles.iconLabelWrapper2,
                                        labelContainer: styles.labelContainer
                                    }}
                                    style={{ fontWeight: 'bold' }}
                                    label={t(
                                        'LIBRARY.queuedBooks'
                                    ).toUpperCase()}
                                    {...a11yProps(3)}
                                />
                            </Tabs>
                        </AppBar>

                        {tabContext()}
                    </Paper>
                </div>
                <Footer />
            </Transition>
        </React.Fragment>
    );
};

export default MyLoansTabPanel;
