/* eslint-disable */
import Breadcrumb from 'components/layout/breadcrumb/Breadcrumb';
import Transition from 'components/layout/transition/Transition';
import HeaderContainer from 'components/containers/HeaderContainer';
import ContentContainer from 'components/containers/ContentContainer';
import Typography from 'components/typography/Typography';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';
import Buttons from 'components/buttons/Pressed';
import Box from '@material-ui/core/Box';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import InfoIcon from '@material-ui/icons/Info';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Footer from 'components/layout/Footer';
import InventoryContext from 'context/inventory/inventoryContext';
import ProjectsContext from 'context/projects/projectsContext';
import UsersContext from 'context/users/usersContext';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { useStyles } from 'components/layout/CommonStyles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import BookForm from './BookForm';
import ExemplarForm from './ExemplarForm';
import { screenStyles } from '../Styles';
import { calcTotalPurchase, SubmitTypes } from './ScreenUtils';
import {
    Events,
    ExemplarAcquisitionMethods,
    LibraryPlaceValues
} from 'global/constants';

import { valueToMoneyFloat } from 'utils/general/valueToMoneyFloat';
import { moneyMask } from 'utils/masks/moneyMask';
import { ISBNValidation } from 'utils/validation/ISBNValidation';
import { GetGoogleBookApi } from 'utils/externalApi/GetGoogleBookApi';
import { GetInputArray } from 'utils/general/GetInputArray';
import { SortElementSelect } from 'utils/sort/SortElementSelect';
import { IsEmptyObject } from 'utils/general/IsEmptyObject';
import { FormatDateToFront } from 'utils/dates/FormatDateToFront';
import { FormatDateToDataBase } from 'utils/dates/FormatDateToDataBase';
import { moneyMaskToFloat } from 'utils/general/moneyMaskToFloat';
import { PadToNDigits } from 'utils/general/PadToNDigits';
import { dateToBRWithHours } from 'utils/dates/DateToBrWithHours';
import ErrorIcon from '@material-ui/icons/Error';
import { formatImagePath } from 'utils/library/formatImagePath';
import ViewHistoryExemplar from "../ViewBooks/ViewHistoryExemplar";

function TabPanel(props) {
    const { children, value, index } = props;
    return <div>{value === index && children}</div>;
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

const BooksExemplarsPage = props => {
    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const { id, context, type } = props;

    const classes = useStyles();
    const styles = screenStyles();

    const [t] = useTranslation();
    const [loading, setLoading] = useState(true);
    const [searchLoading, setSearchLoading] = useState(false);
    const inventoryContext = useContext(InventoryContext);
    const projectsContext = useContext(ProjectsContext);
    const usersContext = useContext(UsersContext);
    const { getAllProjects, projects } = projectsContext;

    const [mustRedirect, setMustRedirect] = useState(false);
    const [usersLibraryAdmArray, setUsersLibraryAdmArray] = useState([]);
    const {
        getLibraryCoordinationUser,
        getLibraryManagersUsers,
        libraryManagersUsers,
        libraryCoordinationUsers
    } = usersContext;
    const {
        getIsbnAndTitles,
        isbnAndTitle,
        getPlaces,
        places,
        book,
        getBook,
        addBook,
        UpdateBook,
        addExemplar,
        UpdateExemplar,
        getExemplar,
        exemplar,
        getAuthors,
        authors,
        bookCategories,
        getBookCategories,
        cleanExemplar,
        cleanBook,
        getUsersEmailData,
        usersEmail,
        getHistoryExemplar,
        historyExemplar
    } = inventoryContext;

    const [bookValues, setBookValues] = useState({
        id: '',
        image: '',
        isbn: '',
        title: '',
        subTitle: '',
        authors: [''],
        edition: '',
        publisher: '',
        language: '',
        categoryId: '',
        synthesis: '',
        imagePath: '',
        isImageUrl: false
    });

    const [exemplarValues, setExemplarValues] = useState({
        amount: 1,
        acquisitionDate: null,
        coin: 'BRL',
        currencyQuote: '1,00',
        purchasePrice: '0,00',
        freight: '0,00',
        iof: '0,00',
        totalPurchase: '0,00',
        acquisitionMethod: ExemplarAcquisitionMethods.PROJECT,
        projectId: '',
        acquisitionDescription: '',
        invoice: '',
        responsibleId: '',
        responsible: '',
		directResponsible: '',
        library: '',
        status: '',
        placeId: '',
        observation: ''
    });

    const clearBookValues = () => {
        setBookValues({
            id: '',
            image: '',
            isbn: '',
            title: '',
            subTitle: '',
            authors: [''],
            edition: '',
            publisher: '',
            language: '',
            categoryId: '',
            synthesis: '',
            imagePath: '',
            isImageUrl: false
        });
        setImageFile([]);
        setBookAuthors([]);
    };

    const clearExemplarValues = () => {
        setExemplarValues({
            amount: 1,
            acquisitionDate: null,
            coin: 'BRL',
            currencyQuote: '1,00',
            purchasePrice: '0,00',
            freight: '0,00',
            iof: '0,00',
            totalPurchase: '0,00',
            acquisitionMethod: ExemplarAcquisitionMethods.PROJECT,
            projectId: '',
            acquisitionDescription: '',
            invoice: '',
            responsibleId: '',
            responsible: '',
			directResponsible: '',
            library: '',
            status: '',
            placeId: '',
            observation: ''
        });
        setFiles({
            files: [],
            rejectedFiles: [],
            deletedFiles: []
        });
    };

    const [files, setFiles] = useState({
        files: [],
        rejectedFiles: [],
        deletedFiles: []
    });

    const [autoCompleteBook, setAutoCompleteBook] = useState(null);
    const [bookCategoriesArray, setBookCategoriesArray] = useState([]);
    const [libraryManagersArray, setLibraryManagersArray] = useState([]);
    const [libraryCoordinationArray, setLibraryCoordinationArray] = useState(
        []
    );
    const [tabValue, setTabValue] = useState(0);
    const [imageFile, setImageFile] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [bookAuthors, setBookAuthors] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [bookAlreadyRegistered, setBookAlreadyRegistered] = useState(false);
    const [disableSearchButton, setDisableSearchButton] = useState(false);
    const [searchError, setSearchError] = useState(false);
    const [searchErrorMessage, setSearchErrorMessage] = useState('');

    const [manualMode, setManualMode] = useState(false);
    const [successGetApi, setSuccessGetApi] = useState(false);

    const redirect = go => {
        if (go) return <Redirect to={`/Books`} />;
    };

    const canExemplarTab =
        bookValues.title &&
        bookValues.edition &&
        bookValues.categoryId &&
        bookAuthors.length
            ? true
            : false;

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const tabContext = () => {
        if (tabValue === 0) {
            return (
                <>
                    {showForm && (
                        <BookForm
                            context={
                                bookAlreadyRegistered || context === 'view'
                                    ? 'view'
                                    : context
                            }
                            imageFile={imageFile}
                            setImageFile={setImageFile}
                            authors={authors}
                            bookAuthors={bookAuthors}
                            setBookAuthors={setBookAuthors}
                            bookCategories={bookCategoriesArray}
                            values={bookValues}
                            setValues={setBookValues}
                            setTabValue={setTabValue}
                            isNewContext={context === 'new' ? true : false}
                            submit={submit}
                        />
                    )}
                </>
            );
        } else {
            return (
                <ExemplarForm
                    context={context}
                    imageFile={imageFile}
                    image={bookValues.image}
                    imagePath={bookValues.imagePath}
                    isImageUrl={bookValues.isImageUrl}
                    setImageFile={setImageFile}
                    values={exemplarValues}
                    setValues={setExemplarValues}
                    files={files}
                    setFiles={setFiles}
                    bookAlreadyRegistered={bookAlreadyRegistered}
                    submit={submit}
                    places={places}
                    projects={projects}
                    libraryManagersUsers={libraryManagersArray}
                    libraryCoordinationUser={libraryCoordinationArray}
                />
            );
        }
    };

    useEffect(() => {
        // getUsersEmailData(Events.NEW_BOOK_EMAIL);
        getLibraryManagersUsers();
        getLibraryCoordinationUser();
        getPlaces();
        getAllProjects();
        getIsbnAndTitles();
        getAuthors();
        getBookCategories();
        if (context !== 'new' && type === 'BOOK') {
            getBook(id, setMustRedirect);
        }
        if (context !== 'new' && type === 'EXEMPLAR') {
            getHistoryExemplar(id);
            getExemplar(id);
        }
        return () => {
            cleanExemplar();
            cleanBook();
        };
    }, [JSON.stringify(historyExemplar).length]);

    useEffect(() => {
        if (bookCategories && bookCategories.length > 0) {
            const categories = [...bookCategories];
            SortElementSelect(categories, 'name');
            setBookCategoriesArray(
                GetInputArray(
                    categories.filter(category => !category.deleted),
                    'id',
                    'name'
                )
            );
        }
    }, [bookCategories]);

    useEffect(() => {
        if (libraryManagersUsers && libraryManagersUsers.length > 0) {
            const libraryManagers = [...libraryManagersUsers];
            SortElementSelect(libraryManagers, 'name');
            setLibraryManagersArray(
                GetInputArray(libraryManagers, 'id', 'name')
            );
        }
    }, [libraryManagersUsers]);

    useEffect(() => {
        if (libraryCoordinationUsers && libraryCoordinationUsers.length > 0) {
            const libraryCoordenation = [...libraryCoordinationUsers];
            SortElementSelect(libraryCoordenation, 'name');
            setLibraryCoordinationArray(
                GetInputArray(libraryCoordenation, 'id', 'name')
            );
        }
    }, [libraryCoordinationUsers]);

    useEffect(() => {
        if (
            book &&
            !IsEmptyObject(book) &&
            context !== 'new' &&
            type === 'BOOK'
        ) {
            setTabValue(0);
            setExistingBookValues(book);
            setInputValue(book.isbn);
            setShowForm(true);
            setLoading(false);
        }
    }, [book]);

    useEffect(() => {
        if (
            exemplar &&
            !IsEmptyObject(exemplar) &&
            context !== 'new' &&
            type === 'EXEMPLAR'
        ) {
            setExistingBookValues(exemplar.book);
            setExistingExemplarValues(exemplar);
            setBookAlreadyRegistered(true);
            setTabValue(1);
            setShowForm(true);
            setLoading(false);
        }
    }, [exemplar]);

    useEffect(() => {
        if (
            bookCategories.length &&
            authors.length &&
            isbnAndTitle.length &&
            context === 'new'
        ) {
            setLoading(false);
        }
    }, [authors, bookCategories, isbnAndTitle]);

    const setExistingBookValues = book => {
        setBookValues({
            ...bookValues,
            id: book.id,
            edition: book.edition,
            title: book.title,
            isbn: book.isbn,
            subTitle: book.subtitle,
            publisher: book.publisher,
            language: book.language,
            synthesis: book.synopsis,
            categoryId: book.category.id,
            image: formatImagePath(book.imagePath, book.isImageUrl),
            isImageUrl: book.isImageUrl
        });

        setBookAuthors(book.authors.map(bookAuthor => bookAuthor.name));
    };

    const setExistingExemplarValues = exemplar => {
        setExemplarValues({
            ...exemplarValues,
            id: exemplar.id,
            code: exemplar.code ? 'BIB-' + PadToNDigits(exemplar.code, 4) : '-',
            acquisitionDate: exemplar.acquisitionDate
                ? FormatDateToFront(exemplar.acquisitionDate)
                : '',
            coin: exemplar.itemCoin,
            currencyQuote: exemplar.itemCurrencyQuote
                ? moneyMask(valueToMoneyFloat(exemplar.itemCurrencyQuote))
                : '1,00',
            purchasePrice: exemplar.itemPurchasePrice
                ? moneyMask(valueToMoneyFloat(exemplar.itemPurchasePrice))
                : '0,00',
            freight: exemplar.itemFreight
                ? moneyMask(valueToMoneyFloat(exemplar.itemFreight))
                : '0,00',
            iof: exemplar.itemIof
                ? moneyMask(valueToMoneyFloat(exemplar.itemIof))
                : '0,00',
            totalPurchase: exemplar.itemTotalPurchase
                ? moneyMask(valueToMoneyFloat(exemplar.itemTotalPurchase))
                : '0,00',
            acquisitionMethod: exemplar.itemAcquisitionMethod,
            projectId: exemplar.itemProjectId,
            acquisitionDescription: exemplar.itemAcquisitionMethodDescription
                ? exemplar.itemAcquisitionMethodDescription
                : '',
            invoice: exemplar.itemInvoice,
            responsibleId: exemplar.itemResponsibleAdministrativeId,
            responsible: exemplar.itemResponsibleAdministrativeExternal,
			directResponsible: exemplar.itemDirectResponsible,
            library: exemplar.library,
            status: exemplar.status,
            placeId: exemplar.itemPlaceId,
            observation: exemplar.itemObservation
        });

        setFiles({
            ...files,
            files: exemplar.exemplarFiles
        });
    };

    const handleSearch = async () => {
        setManualMode(false);
        setDisableSearchButton(true);
        setSearchLoading(true);
        setShowForm(false);
        setBookAlreadyRegistered(false);
        setTabValue(0);
        setImageFile([]);
        if (autoCompleteBook && !IsEmptyObject(autoCompleteBook)) {
            setExistingBookValues(autoCompleteBook);
            setBookAlreadyRegistered(true);
            setShowForm(true);
        } else {
            if (ISBNValidation(inputValue)) {
                const bookAlreadyRegisteredWithSameIsbn = isbnAndTitle.find(
                    book => book.isbn === inputValue
                );
                if (bookAlreadyRegisteredWithSameIsbn && context === 'new') {
                    setExistingBookValues(bookAlreadyRegisteredWithSameIsbn);
                    setAutoCompleteBook(bookAlreadyRegisteredWithSameIsbn);
                    setBookAlreadyRegistered(true);
                    setShowForm(true);
                } else {
                    const googleBookInfo = await GetGoogleBookApi(inputValue);
                    if (IsEmptyObject(googleBookInfo)) {
                        setSearchError(true);
                        setSearchErrorMessage(t('INVENTORY.isbnNotFound'));
                        if (context === 'edit') {
                            setShowForm(true);
                        }
                    } else {
                        const existsBook = isbnAndTitle.find(
                            book =>
                                book.title.toLowerCase() ===
                                googleBookInfo.volumeInfo.title.toLowerCase()
                        );

                        if (existsBook && context === 'new') {
                            setAutoCompleteBook(existsBook);
                            setExistingBookValues(existsBook);
                        } else {
                            setSuccessGetApi(true);
                            setBookValues({
                                ...bookValues,
                                image: googleBookInfo.volumeInfo.imageLinks
                                    ? googleBookInfo.volumeInfo.imageLinks
                                          .thumbnail
                                        ? googleBookInfo.volumeInfo.imageLinks
                                              .thumbnail
                                        : googleBookInfo.volumeInfo.imageLinks
                                              .smallThumbnail
                                        ? googleBookInfo.volumeInfo.imageLinks
                                              .smallThumbnail
                                        : ''
                                    : '',
                                title:
                                    googleBookInfo.volumeInfo &&
                                    googleBookInfo.volumeInfo.title,
                                subTitle:
                                    googleBookInfo.volumeInfo &&
                                    googleBookInfo.volumeInfo.subtitle,
                                publisher:
                                    googleBookInfo.volumeInfo &&
                                    googleBookInfo.volumeInfo.publisher,
                                language:
                                    googleBookInfo.volumeInfo &&
                                    googleBookInfo.volumeInfo.language,
                                synthesis:
                                    googleBookInfo.volumeInfo &&
                                    googleBookInfo.volumeInfo.description,
                                isbn: inputValue ? inputValue : '',
                                categoryId: ''
                            });

                            setBookAuthors(
                                googleBookInfo.volumeInfo &&
                                    googleBookInfo.volumeInfo.authors
                                    ? googleBookInfo.volumeInfo.authors
                                    : ['']
                            );
                        }

                        setShowForm(true);
                    }
                }
            } else {
                setSearchError(true);
                setSearchErrorMessage(t('INVENTORY.isbnNotFound'));
                if (context === 'edit') {
                    setShowForm(true);
                }
            }
        }

        setSearchLoading(false);
        setDisableSearchButton(false);
    };

    const submit = submitType => {
        let formData = new FormData();

        let emailDatas = [];
        let auxEmail = {};

        // New Book Email
        if (context === 'new') {
            // Pegar o label apartir do id do projeto.
            const projectLabel = projects
                .filter(
                    project =>
                        project.idInformation === exemplarValues.projectId
                )
                .map(filteredProject => filteredProject.name);

            auxEmail.user = usersEmail;
            auxEmail.title = bookValues.title;
            auxEmail.acquisitionMethod =
                exemplarValues.acquisitionMethod ===
                ExemplarAcquisitionMethods.PROJECT
                    ? 'Projetos' + '/' + projectLabel
                    : (exemplarValues.acquisitionMethod ===
                      ExemplarAcquisitionMethods.FINE
                          ? 'Multas'
                          : 'Outros') +
                      '/' +
                      exemplarValues.descriptionAcquisitionMethod;
            auxEmail.edition = bookValues.edition ? bookValues.edition : '-';
            auxEmail.publisher = bookValues.publisher
                ? bookValues.publisher
                : '-';
            auxEmail.date = dateToBRWithHours(new Date());
            auxEmail.operator = auth.name;
            auxEmail.amountTitles = exemplarValues.amount;
            auxEmail.urlBatch =
                process.env.REACT_APP_FRONT +
                'Books/' +
                '?&' +
                bookValues.title +
                '&' +
                auxEmail.project;
            emailDatas.push(auxEmail);

            auxEmail = {};
        }
        const emailDataAssignment = [];

        if (
            context === 'new' ||
            (exemplarValues.responsibleId &&
                book.itemResponsibleAdministrativeId !==
                    exemplarValues.responsibleId)
        ) {
            let user;
            if (exemplarValues.library === LibraryPlaceValues.COORDINATION) {
                user = libraryCoordinationUsers.filter(
                    user => exemplarValues.responsibleId === user.id
                );
            }

            if (exemplarValues.library === LibraryPlaceValues.GENERAL) {
                user = libraryManagersUsers.filter(
                    user => exemplarValues.responsibleId === user.id
                );
            }

            if (user && user.length > 0) {
                auxEmail.type = 'BOOK';
                auxEmail.date = dateToBRWithHours(new Date());
                auxEmail.operatorName = auth.name;
                auxEmail.user = user.map(user => {
                    return {
                        email: user.email,
                        name: user.name
                    };
                });
                auxEmail.name = auxEmail.user[0].name;
                let oldPlace = places.filter(
                    place => exemplar.itemPlaceId === place.id
                );
                let newPlace = places.filter(
                    place => exemplarValues.placeId === place.id
                );
                let category = bookCategories.filter(
                    category => bookValues.categoryId === category.id
                );

                auxEmail.local = [
                    [
                        context === 'edit' ? oldPlace[0].name : '-',
                        newPlace[0].name
                    ]
                ];
                auxEmail.itens = [
                    [
                        bookValues.title,
                        category[0].name,
                        exemplarValues.code ? exemplarValues.code : '-'
                    ]
                ];
            }

            if (Object.keys(auxEmail).length !== 0) {
                emailDataAssignment.push(auxEmail);
                auxEmail = {};
            }
        }

        // START BOOK FORMDATA
        if (!bookAlreadyRegistered) {
            formData.append('title', bookValues.title);
            formData.append(
                'subtitle',
                bookValues.subTitle ? bookValues.subTitle : ''
            );
            formData.append(
                'edition',
                bookValues.edition ? bookValues.edition : ''
            );

            if (context === 'edit' && type === 'BOOK') {
                formData.append('id', bookValues.id);

                let count = 0;

                bookAuthors.forEach((author, idx) => {
                    let isOldAuthor = book.authors.find(
                        bookAuthor => author === bookAuthor.name
                    );
                    if (!isOldAuthor) {
                        let databaseAuthor = authors.find(
                            x => x.name === author
                        );
                        if (databaseAuthor) {
                            formData.append(
                                `authors[${count}].id`,
                                databaseAuthor.id
                            );
                            formData.append(
                                `authors[${count}].name`,
                                databaseAuthor.name
                            );
                        } else {
                            formData.append(`authors[${count}].name`, author);
                        }
                        count++;
                    }
                });

                count = 0;
                book.authors.forEach(author => {
                    let possibleDeletedAuthor = bookAuthors.find(
                        x => author.name === x
                    );
                    if (!possibleDeletedAuthor) {
                        formData.append(`deletedAuthors[${count}]`, author.id);
                        count++;
                    }
                });
            } else {
                bookAuthors.forEach((author, idx) => {
                    let databaseAuthor = authors.find(x => x.name === author);
                    if (databaseAuthor) {
                        formData.append(
                            `authors[${idx}].id`,
                            databaseAuthor.id
                        );
                        formData.append(
                            `authors[${idx}].name`,
                            databaseAuthor.name
                        );
                    } else {
                        formData.append(`authors[${idx}].name`, author);
                    }
                });
            }

            formData.append(
                'publisher',
                bookValues.publisher ? bookValues.publisher : ''
            );
            formData.append('isbn', bookValues.isbn);
            formData.append(
                'language',
                bookValues.language ? bookValues.language : ''
            );
            formData.append('categoryId', bookValues.categoryId);
            formData.append(
                'imagePath',
                imageFile.length === 0 ? bookValues.image : ''
            );
            formData.append(
                'isImageUrl',
                imageFile.length === 0 ? true : false
            );
            formData.append(
                'imageFile',
                imageFile.length > 0 ? imageFile[0] : null
            );

            formData.append(
                'synopsis',
                bookValues.synthesis ? bookValues.synthesis : ''
            );
        }
        // END BOOK FORM_DATA

        // Start Exemplar data
        if (bookAlreadyRegistered && context === 'new') {
            formData.append('bookId', bookValues.id ? bookValues.id : '');
        }

        formData.append(
            bookAlreadyRegistered ? 'amount' : 'exemplar.amount',
            exemplarValues.amount ? exemplarValues.amount : ''
        );
        formData.append(
            bookAlreadyRegistered
                ? 'acquisitionDate'
                : 'exemplar.acquisitionDate',
            exemplarValues.acquisitionDate
                ? FormatDateToDataBase(exemplarValues.acquisitionDate)
                : ''
        );
        formData.append(
            bookAlreadyRegistered ? 'coin' : 'exemplar.coin',
            exemplarValues.coin ? exemplarValues.coin : ''
        );
        formData.append(
            bookAlreadyRegistered ? 'currencyQuote' : 'exemplar.currencyQuote',
            exemplarValues.currencyQuote
                ? moneyMaskToFloat(exemplarValues.currencyQuote).toFixed(2)
                : ''
        );
        formData.append(
            bookAlreadyRegistered ? 'purchasePrice' : 'exemplar.purchasePrice',
            exemplarValues.purchasePrice
                ? moneyMaskToFloat(exemplarValues.purchasePrice).toFixed(2)
                : ''
        );

        formData.append(
            bookAlreadyRegistered ? 'freight' : 'exemplar.freight',
            exemplarValues.freight
                ? moneyMaskToFloat(exemplarValues.freight).toFixed(2)
                : ''
        );
        formData.append(
            bookAlreadyRegistered ? 'iof' : 'exemplar.iof',
            exemplarValues.iof
                ? moneyMaskToFloat(exemplarValues.iof).toFixed(2)
                : ''
        );
        formData.append(
            bookAlreadyRegistered ? 'totalPurchase' : 'exemplar.totalPurchase',
            exemplarValues.totalPurchase === '0,00'
                ? (
                      moneyMaskToFloat(calcTotalPurchase(exemplarValues)) /
                      exemplarValues.amount
                  ).toFixed(2)
                : (
                      moneyMaskToFloat(exemplarValues.totalPurchase) /
                      exemplarValues.amount
                  ).toFixed(2)
        );
        formData.append(
            bookAlreadyRegistered
                ? 'acquisitionMethod'
                : 'exemplar.acquisitionMethod',
            exemplarValues.acquisitionMethod
                ? exemplarValues.acquisitionMethod
                : ''
        );
        if (
            exemplarValues.acquisitionMethod ===
            ExemplarAcquisitionMethods.PROJECT
        ) {
            formData.append(
                bookAlreadyRegistered ? 'projectId' : 'exemplar.projectId',
                exemplarValues.projectId ? exemplarValues.projectId : ''
            );
        } else {
            formData.append(
                bookAlreadyRegistered
                    ? 'AcquisitionMethodDescription'
                    : 'exemplar.AcquisitionMethodDescription',
                exemplarValues.acquisitionDescription
                    ? exemplarValues.acquisitionDescription
                    : ''
            );
        }
        formData.append(
            bookAlreadyRegistered ? 'invoice' : 'exemplar.invoice',
            exemplarValues.invoice ? exemplarValues.invoice : ''
        );

        if (exemplarValues.responsibleId) {
            formData.append(
                bookAlreadyRegistered
                    ? 'ResponsibleAdministrativeId'
                    : 'exemplar.ResponsibleAdministrativeId',
                exemplarValues.responsibleId
                    ? exemplarValues.responsibleId
                    : null
            );
        }
        formData.append(
            bookAlreadyRegistered
                ? 'ResponsibleAdministrativeExternal'
                : 'exemplar.ResponsibleAdministrativeExternal',
            exemplarValues.responsible ? exemplarValues.responsible : ''
        );
        formData.append(
            bookAlreadyRegistered ? 'library' : 'exemplar.library',
            exemplarValues.library ? exemplarValues.library : ''
        );
        formData.append(
            bookAlreadyRegistered ? 'status' : 'exemplar.status',
            exemplarValues.status ? exemplarValues.status : ''
        );
        formData.append(
            bookAlreadyRegistered ? 'placeId' : 'exemplar.placeId',
            exemplarValues.placeId ? exemplarValues.placeId : ''
        );
        for (let i in files.files) {
            formData.append(
                bookAlreadyRegistered
                    ? `exemplarFiles`
                    : `exemplar.exemplarFiles`,
                files.files[i]
            );
        }

        if (context === 'edit') {
            if (files.deletedFiles.length) {
                for (let i in files.deletedFiles)
                    formData.append(
                        `deletedExemplarFiles[${i}]`,
                        files.deletedFiles[i]
                    );
            }
            if (exemplar.responsibleId) {
                formData.append(
                    bookAlreadyRegistered
                        ? 'responsibleId'
                        : 'exemplar.responsibleId',
                    exemplar.responsibleId ? exemplar.responsibleId : null
                );
            }
        }

        formData.append(
            bookAlreadyRegistered ? 'observation' : 'exemplar.observation',
            exemplarValues.observation ? exemplarValues.observation : ''
        );
        formData.append('operatorUserId', auth.id);
        // END EXEMPLAR formData

        if (submitType === SubmitTypes.CREATE_BOOK_AND_EXEMPLAR) {
            addBook(formData, emailDatas, emailDataAssignment, setMustRedirect);
        }
        if (submitType === SubmitTypes.CREATE_EXEMPLAR) {
            addExemplar(
                formData,
                emailDatas,
                emailDataAssignment,
                setMustRedirect
            );
        }
        if (submitType === SubmitTypes.UPDATE_BOOK) {
            UpdateBook(formData, book.id, setMustRedirect);
        }
        if (submitType === SubmitTypes.UPDATE_EXEMPLAR) {
            UpdateExemplar(
                formData,
                exemplar.id,
                setMustRedirect,
                emailDataAssignment
            );
        }
    };

    return (
        <React.Fragment>
            {redirect(mustRedirect)}
            <Transition loading={loading} newDesign={true}>
                <HeaderContainer>
                    <Breadcrumb
                        newDesign={true}
                        routes={[
                            {
                                label: t('INVENTORY.inventory'),
                                to: '/Patrimony'
                            },
                            { label: t('INVENTORY.books'), to: '/Books' },
                            {
                                label:
                                    context === 'new'
                                        ? t('INVENTORY.addBook')
                                        : type === 'BOOK'
                                        ? context === 'edit'
                                            ? t('INVENTORY.editBook')
                                            : t('INVENTORY.viewBook')
                                        : context === 'edit'
                                        ? t('INVENTORY.editExemplar')
                                        : t('INVENTORY.viewExemplar')
                            }
                        ]}
                    />
                    <div className="container-headerNewDesign">
                        <h1 className={classes.titleNewDesign}>
                            {context === 'new'
                                ? t('INVENTORY.addBook')
                                : type === 'BOOK'
                                ? context === 'edit'
                                    ? t('INVENTORY.editBook')
                                    : t('INVENTORY.viewBook')
                                : context === 'edit'
                                ? t('INVENTORY.editExemplar')
                                : t('INVENTORY.viewExemplar')}
                        </h1>
                    </div>
                </HeaderContainer>
                <ContentContainer>
                    <Paper>
                        <div className="cabecalho">
                            {t('INVENTORY.equipmentDatas').toUpperCase()}
                        </div>
                        <div className={styles.formWrapper}>
                            <Grid container spacing={3}>
                                {context !== 'view' && type !== 'EXEMPLAR' && (
                                    <>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant={'RobotoBold'}
                                                fontSize={14}
                                                textColor={'secondary'}
                                            >
                                                {t('LIBRARY.addBookInfo')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Autocomplete
                                                id="free-solo-dialog-demo"
                                                freeSolo
                                                selectOnFocus
                                                disabled={context === 'edit'}
                                                options={isbnAndTitle}
                                                getOptionLabel={option =>
                                                    option.title
                                                }
                                                value={autoCompleteBook}
                                                onChange={(event, newValue) => {
                                                    setSearchError(false);
                                                    setSearchErrorMessage('');
                                                    setAutoCompleteBook(
                                                        newValue
                                                    );
                                                }}
                                                inputValue={inputValue}
                                                onInputChange={(
                                                    event,
                                                    newValue
                                                ) => {
                                                    if (context !== 'edit') {
                                                        setSearchError(false);
                                                        setSearchErrorMessage(
                                                            ''
                                                        );
                                                        setInputValue(newValue);
                                                    }
                                                }}
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        label={t(
                                                            'INVENTORY.searchForIsbn'
                                                        )}
                                                        error={searchError}
                                                        helperText={
                                                            searchErrorMessage
                                                        }
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            startAdornment: (
                                                                <InputAdornment
                                                                    position="start"
                                                                    className={
                                                                        classes.SearchIcon
                                                                    }
                                                                >
                                                                    <SearchIcon />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Buttons
                                                style={{ width: '128px' }}
                                                tipo="BN-blue"
                                                disabled={disableSearchButton}
                                                conteudo={
                                                    searchLoading ? (
                                                        <CircularProgress
                                                            color="secondary"
                                                            size={24}
                                                        />
                                                    ) : (
                                                        'Pesquisar'
                                                    )
                                                }
                                                onClick={handleSearch}
                                            />
                                        </Grid>

                                        <Box width="100%" />

                                        {context !== 'edit' && (
                                            <Grid item xs={12}>
                                                {bookAlreadyRegistered ? (
                                                    <Chip
                                                        label={t(
                                                            'LIBRARY.bookAlreadyExists'
                                                        )}
                                                        color="primary"
                                                        icon={<InfoIcon />}
                                                        style={{
                                                            width: '100%',
                                                            justifyContent:
                                                                'start'
                                                        }}
                                                    />
                                                ) : !successGetApi ? (
                                                    <div
                                                        style={{
                                                            display: 'flex'
                                                        }}
                                                    >
                                                        <Typography
                                                            variant={
                                                                'RobotoRegular'
                                                            }
                                                            fontSize={12}
                                                            textColor={
                                                                'secondary'
                                                            }
                                                        >
                                                            {t(
                                                                'LIBRARY.addBookManuallyText'
                                                            )}
                                                            &nbsp;
                                                        </Typography>
                                                        <Typography
                                                            variant={'link'}
                                                            fontSize={12}
                                                            textColor={
                                                                'linkBlue'
                                                            }
                                                            onClick={() => {
                                                                setShowForm(
                                                                    true
                                                                );
                                                                setManualMode(
                                                                    true
                                                                );
                                                                setSuccessGetApi(
                                                                    true
                                                                );
                                                                clearBookValues();
                                                                clearExemplarValues();
                                                            }}
                                                        >
                                                            {t(
                                                                'LIBRARY.addBookManuallyLink'
                                                            )}
                                                        </Typography>
                                                    </div>
                                                ) : manualMode ? (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <ErrorIcon
                                                            style={{
                                                                marginRight:
                                                                    '8px',
                                                                color: '#2D9CDB'
                                                            }}
                                                            fontSize="small"
                                                        />
                                                        <Typography
                                                            variant={
                                                                'RobotoBold'
                                                            }
                                                            fontSize={12}
                                                            textColor={
                                                                'secondary'
                                                            }
                                                        >
                                                            {t(
                                                                'LIBRARY.addBookManualMode'
                                                            )}
                                                        </Typography>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}
                                            </Grid>
                                        )}
                                    </>
                                )}
                                {showForm && (
                                    <Grid item xs={12}>
                                        <AppBar
                                            position="static"
                                            style={{
                                                boxShadow: 'none',
                                                transform: 'none'
                                            }}
                                        >
                                            <Tabs
                                                selectionFollowsFocus
                                                style={{
                                                    backgroundColor: '#fff',
                                                    color: 'black'
                                                }}
                                                indicatorColor="primary"
                                                value={tabValue}
                                                onChange={handleTabChange}
                                            >
                                                <Tab
                                                    style={{
                                                        fontWeight: 'bold'
                                                    }}
                                                    label={t(
                                                        'INVENTORY.dataTitle'
                                                    ).toUpperCase()}
                                                    {...a11yProps(0)}
                                                />
                                                {type !== 'BOOK' && (
                                                    <Tab
                                                        disabled={
                                                            !canExemplarTab &&
                                                            context === 'new'
                                                        }
                                                        style={{
                                                            fontWeight: 'bold'
                                                        }}
                                                        label={
                                                            context === 'new'
                                                                ? t(
                                                                      'INVENTORY.newExemplary'
                                                                  ).toUpperCase()
                                                                : t(
                                                                      'INVENTORY.dataExemplary'
                                                                  ).toUpperCase()
                                                        }
                                                        {...a11yProps(1)}
                                                    />
                                                )}
                                            </Tabs>
                                        </AppBar>
                                        {tabContext()}
                                    </Grid>
                                )}
                            </Grid>
                        </div>
                    </Paper>
                    {(context === "view") ? <ViewHistoryExemplar historyExemplar={historyExemplar} />: ""}
                </ContentContainer>
                <Footer />
            </Transition>
        </React.Fragment>
    );
};

export default BooksExemplarsPage;
