import React, { useState, useEffect } from 'react';
import { screenStyles } from 'pages/libraryModule/dialogs/Styles';
import Typography from 'components/typography/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import ErrorIcon from '@material-ui/icons/Error';

export const DialogUnitComponent = props => {
    const { head, content } = props;
    const styles = screenStyles();

    return (
        <>
            <div className={styles.dialogUnitComponent}>
                <Typography
                    variant={'RobotoBold'}
                    fontSize={12}
                    textColor={'secondary'}
                >
                    {head}
                </Typography>
                <Typography
                    className={styles.dialogMoreDetailsText}
                    variant={'RobotoRegular'}
                    fontSize={14}
                    textColor={'secondary'}
                >
                    {content}
                </Typography>
            </div>
        </>
    );
};

export const DialogDoubleComponent = props => {
    const { headLeft, contentLeft, headRight, contentRight } = props;
    const styles = screenStyles();

    return (
        <div className={styles.dialogMoreDetailsContainerBottom}>
            <div className={styles.dialogMoreDetailsAuthorEditionWrapper}>
                <div style={{ width: '48%' }}>
                    <DialogUnitComponent
                        head={headLeft}
                        content={contentLeft}
                    />
                </div>
                <div style={{ width: '48%' }}>
                    <DialogUnitComponent
                        head={headRight}
                        content={contentRight}
                    />
                </div>
            </div>
        </div>
    );
};

export const DialogUnit = props => {
    const { head, content, margin } = props;
    const styles = screenStyles();

    return (
        <div style={margin ? { margin: margin } : {}}>
            <Typography
                variant={'RobotoBold'}
                fontSize={14}
                textColor={'secondary'}
            >
                {head}
            </Typography>
            <Typography
                className={styles.dialogTopic}
                variant={'RobotoRegular'}
                fontSize={14}
                textColor={'secondary'}
            >
                {content}
            </Typography>
        </div>
    );
};

export const DialogImage = props => {
    const { image, alt } = props;
    const styles = screenStyles();

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(false);
    }, [image]);

    return (
        <>
            <img
                className={styles.dialogImage}
                style={!loaded ? { display: 'none' } : {}}
                src={image}
                alt={alt}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src =
                        window.location.origin + '/img/no-book-cover.png';
                    setLoaded(true);
                }}
                onLoad={() => {
                    setLoaded(true);
                }}
            />
            {!loaded && (
                <Skeleton
                    className={styles.dialogImage}
                    variant="rect"
                    width={110}
                    height={150}
                />
            )}
        </>
    );
};

export const DialogWarningTip = props => {
    const { text, margin } = props;
    const styles = screenStyles();

    return (
        <div
            style={margin ? { margin: margin } : {}}
            className={styles.dialogMoreDetailsDisponible}
        >
            <ErrorIcon
                style={{
                    marginRight: '0.5rem',
                    color: '#2C3E51'
                }}
                fontSize="small"
            />
            <Typography
                variant={'RobotoRegular'}
                fontSize={14}
                textColor={'primary'}
            >
                {text}
            </Typography>
        </div>
    );
};
