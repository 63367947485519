/* eslint-disable */
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    rootForm: {
        marginBottom: '50px'
    },
    breadCrumbs: {
        marginTop: '30px',
        flexWrap: 'wrap',
        marginBottom: '40px'
    },
    title: {
        color: '#717272',
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: '37px',
        fontStyle: 'normal'
    },
    cabecalho: {
        backgroundColor: '#f5f6fa',
        height: '45px',
        opacity: '0.54',
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '3.5',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#000000',
        paddingLeft: '18px'
    },
    bodyForm: {
        paddingLeft: '18px',
        paddingRight: '18px',
        paddingBottom: '30px'
    },
    link: {
        color: '#73b3ff'
    },
    notClicableBreadScrumbs: {
        color: '#828b91',
        cursor: 'not-allowed'
    },
    table: {
        minWidth: 650
    },
    tableWrapper: {
        overflowX: 'auto'
    },
    button: {
        marginTop: theme.spacing(2),
        marginBotton: theme.spacing(2)
    },
    inputBox: {
        padding: '5px 15px'
    },
    switchBase: {
        '&$checked': {
            color: '#2c3e51'
        },
        '&$checked + $track': {
            backgroundColor: '#2c3e51'
        }
    },
    checked: {},
    track: {},
    textField: {
        display: 'flex'
    },
    avatarParent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '60px'
    },
    newProfileHint: {
        fontSize: '14px',
        padding: '5px 15px',
        opacity: '0.54',
        fontFamily: 'Roboto'
    },
    newProfileRequired: {
        fontSize: '12px',
        opacity: '0.54',
        fontFamily: 'Roboto'
    },
    textFieldLimit: {
        fontSize: '12px',
        opacity: '0.54',
        fontFamily: 'Roboto'
    },
    hintsDivFather: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '10px'
    },
    hintsDivFatherRight: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: '10px'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    info: {
        backgroundColor: 'theme.palette.primary.main'
    },
    warning: {
        backgroundColor: 'amber[700]'
    },
    blueButton: {
        backgroundColor: '#2c3e51',
        padding: '14px 24px',
        fontWeight: 'normal',
        fontSize: '14px'
    },
    buttonIcon: {
        display: 'flex',
        marginRight: '15px'
    },
    stepNumber: {
        fontSize: '23px',
        fontWeight: 'bold'
    },
    fab: {
        marginTop: '10px',
        float: 'rigth',
        display: 'inline-flex',
        '&:hover': {
            width: '200px',
            borderRadius: '30px'
        }
    },
    downButton: {
        float: 'right',
        padding: '10px'
    },
    buttonIcon: {
        display: 'flex',
        marginRight: '15px'
    },
    nextPageDiv: {
        float: 'right',
        whiteSpace: 'nowrap'
    },
    subTitleTable: {
        color: 'rgba(0, 0, 0, 0.5)',
        fontWeight: 'bold'
    }
}));

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#f5f6fa',
        color: theme.palette.common.black,
        opacity: '0.54',
        textAlign: 'left',
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 'bold'
    },
    body: {
        fontSize: 14
    }
}))(TableCell);

const StyledTableCell2 = withStyles(theme => ({
    head: {
        color: theme.palette.common.black,
        opacity: '0.54',
        textAlign: 'left',
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 'bold',
        backgroundColor: 'white'
    },
    body: {
        fontSize: 14
    }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        // "&:nth-of-type(odd)": {
        //   backgroundColor: theme.palette.background.default
        // }, // estripped example
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        '&:hover': {
            backgroundColor: '#EEEEEE',
            '& button': {
                opacity: '0.2',
                '&:hover': {
                    opacity: '1',
                    color: theme.palette.common.black
                }
            }
        }
    }
}))(TableRow);

const StyledTableCellWhite = withStyles(theme => ({
    head: {
        backgroundColor: '#fff',
        color: theme.palette.common.black,
        opacity: '0.54',
        textAlign: 'left',
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 'bold',
        height: '40px'
    },
    body: {
        fontSize: 14
    }
}))(TableCell);

export {
    StyledTableCell,
    StyledTableRow,
    useStyles,
    StyledTableCell2,
    StyledTableCellWhite
};
