import { makeStyles } from "@material-ui/core/styles";

export const screenStyles = makeStyles(theme => ({
  module: {
    margin: "15px 0 2px"
  },
  title: {
    margin: "0 0 20px"
  },
  listContainer: {
  },
  toolsListContainer: {
    marginTop: "1rem",
    display:"flex",
    flexDirection: "column"
  },
  toolsListWrapper: {
    display: "flex",
    flexWrap: "wrap",
    margin: "-0.5rem",
    "& > *": {
      margin: "0.5rem"
    }
  }
})
)  