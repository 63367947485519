export const quotasData = [
    {
        screen: 'QUOTAS_DASHBOARD_PERSONAL',
        allowRead: false
    },
    {
        screen: 'QUOTAS_DASHBOARD_PERSONAL_TAB_BILLS',
        allowRead: false
    },
    {
        screen: 'QUOTAS_DASHBOARD_PERSONAL_TAB_SOLICITATIONS',
        allowRead: false,
        allowCreate: false,
        allowDelete: false
    },
    {
        screen: 'QUOTAS_DASHBOARD_PERSONAL_TAB_TRANSACTIONS',
        allowRead: false
    },
    {
        screen: 'QUOTAS_DASHBOARD_LSBD',
        allowRead: false,
        allowCreate: false
    },
    {
        screen: 'QUOTAS_DASHBOARD_LSBD_TAB_SOLICITATIONS',
        allowRead: false,
        allowCreate: false,
        allowDelete: false
    },
    {
        screen: 'QUOTAS_DASHBOARD_LSBD_TAB_BILLINGSHEET',
        allowRead: false
    },
    {
        screen: 'QUOTAS_DASHBOARD_LSBD_TAB_LATE',
        allowRead: false
    },
    {
        screen: 'QUOTAS_DASHBOARD_LSBD_TAB_TRANSACTIONS',
        allowRead: false,
        allowEdit: false,
        allowDelete: false
    },
    {
        screen: 'QUOTAS_DASHBOARD_LSBD_TAB_EXPENSES',
        allowRead: false
    },
    {
        screen: 'QUOTAS_DASHBOARD_LSBD_TAB_CASHFLOW',
        allowRead: false
    },
    {
        screen: 'QUOTAS_JOBS',
        allowRead: false,
        allowEdit: false
    },
    {
        screen: 'QUOTAS_USERS',
        allowRead: false,
        allowCreate: false,
        allowEdit: false,
        allowDelete: false
    },
    {
        screen: 'QUOTAS_TOOLS',
        allowRead: false
    }
];
