import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Buttons from 'components/buttons/Pressed';
import Tooltip from '@material-ui/core/Tooltip';
import Visibility from '@material-ui/icons/Visibility';
import DialogBookMoreDetails from '../dialogs/BooksCollection/DialogBookMoreDetails';
import DialogBookRequisition from '../dialogs/BooksCollection/DialogBookRequisition';
import Link from 'react-router-dom/Link';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';
import { Screen, Allow } from 'global/constants';
import { DisponibleExemplarsAmount } from 'utils/library/DisponibleExemplarsAmount';
import { handleStatusCollection } from 'utils/library/handleStatusCollection';
import DialogWarningMaxRequisitions from '../dialogs/BooksCollection/DialogWarningMaxRequisitions';

const BooksCollectionTableActions = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const { item, submit, submitQueue, isMaxOfBooks, userReservationsAmount } =
        props;
    const [statusData, setStatusData] = useState('');
    const [openBookRequisitionDialog, setOpenBookRequisitionDialog] =
        useState(false);
    const [openBookMoreDetailsDialog, setOpenBookMoreDetailsDialog] =
        useState(false);
    const [openWarningReservationDialog, setOpenWarningReservationDialog] =
        useState(false);

    const handleOpenBookMoreDetailsDialog = () => {
        setStatusData(
            handleStatusCollection(item.exemplars, item.reservations)
        );
        setOpenBookMoreDetailsDialog(true);
    };

    const isIndisponible = DisponibleExemplarsAmount(item.exemplars) === 0;

    return (
        <React.Fragment>
            <div style={{ padding: '8px' }}>
                {item.reservations.length ? (
                    <Link to={`/Library/MyLoans?panel=PENDINGREQUISITIONS`}>
                        <Buttons
                            tipo="borderBackButton"
                            conteudo="IR PARA REQUISIÇÕES"
                            style={{
                                width: '200px',
                                height: '40px',
                                opacity: '1',
                                color: '#2c3e51'
                            }}
                        />
                    </Link>
                ) : item.queue.length ? (
                    <Link to={`/Library/MyLoans?panel=QUEUEDBOOKS`}>
                        <Buttons
                            tipo="borderBackButton"
                            conteudo="IR PARA RESERVAS"
                            style={{
                                width: '200px',
                                height: '40px',
                                opacity: '1',
                                color: '#2c3e51'
                            }}
                        />
                    </Link>
                ) : isIndisponible ? (
                    <Buttons
                        tipo="borderBackButton"
                        conteudo="FAZER RESERVA"
                        style={{
                            width: '200px',
                            height: '40px',
                            opacity: '1',
                            color: '#2c3e51'
                        }}
                        onClick={() =>
                            isMaxOfBooks
                                ? setOpenWarningReservationDialog(true)
                                : setOpenBookRequisitionDialog(true)
                        }
                    />
                ) : (
                    <Buttons
                        disabled={
                            DisponibleExemplarsAmount(item.exemplars) === 0
                        }
                        tipo="BN-blue"
                        conteudo="REQUISITAR"
                        style={{
                            width: '200px',
                            height: '40px',
                            opacity: '1',
                            color: 'white'
                        }}
                        onClick={() =>
                            isMaxOfBooks
                                ? setOpenWarningReservationDialog(true)
                                : setOpenBookRequisitionDialog(true)
                        }
                    />
                )}
            </div>

            {GetGeneralPermissionForAction(
                Screen.BOOKS_COLLECTION,
                Allow.READ
            ) && (
                <Tooltip
                    title={t('GENERAL.view')}
                    aria-label="details"
                    placement="top"
                >
                    <IconButton
                        className={classes.iconsTableActions}
                        onClick={() => handleOpenBookMoreDetailsDialog()}
                    >
                        <Visibility />
                    </IconButton>
                </Tooltip>
            )}

            {openBookRequisitionDialog && (
                <DialogBookRequisition
                    open={openBookRequisitionDialog}
                    setOpen={setOpenBookRequisitionDialog}
                    viewValues={item}
                    exemplarValues={item.exemplars}
                    submit={submit}
                    submitQueue={submitQueue}
                    context={isIndisponible ? 'queue' : ''}
                />
            )}
            {openBookMoreDetailsDialog && (
                <DialogBookMoreDetails
                    open={openBookMoreDetailsDialog}
                    setOpen={setOpenBookMoreDetailsDialog}
                    viewValues={item}
                    exemplarValues={item.exemplars}
                    status={statusData}
                    submit={submit}
                    submitQueue={submitQueue}
                    isMaxOfBooks={isMaxOfBooks}
                    userReservationsAmount={userReservationsAmount}
                />
            )}
            {openWarningReservationDialog && (
                <DialogWarningMaxRequisitions
                    open={openWarningReservationDialog}
                    setOpen={setOpenWarningReservationDialog}
                    viewValues={userReservationsAmount}
                />
            )}
        </React.Fragment>
    );
};

export default BooksCollectionTableActions;
