import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Edit from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
//import InputBase from "@material-ui/core/InputBase";
import { useStyles } from "components/layout/CommonStyles";
import Delete from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Buttons from "components/buttons/Pressed";
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';

const QualificationTableAction = props => {
    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }
    const classes = useStyles();
    const [t] = useTranslation();
    const { item, index, setOpenActionView, setOpenActionEdit, document, deleteDocument, context } = props;
    const [openDelete, setOpenDelete] = useState(false);
    const [changeContext, setchangeContex] = useState(0);
    const [password, setPassword] = useState("");
    const [passRight, setPassRight] = useState(false);
    const [justification, setJustification] = useState("");
    const [validJustification, setValidJustification] = useState(false);
    const blankField = "GENERAL.requiredField";
    const [validPasswordrequiredField, setPasswordRequiredField] = useState(false);
    const handleOpenDelete = () => {
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
        setValidJustification(false);
        setPasswordRequiredField(false);
        setPassRight(false);
        setchangeContex(0);
        setJustification("");
        setPassword("");
    };

    function sendRequest() {
        const requestInfo = {
            method: "POST",
            body: JSON.stringify({
                email: auth.userName,
                password: password
            }),
            headers: new Headers({
                "Content-type": "application/json"
            })
        };

        fetch(process.env.REACT_APP_SERVER + "api/auth/login", requestInfo)
            .then(response => {
                if (response.ok) {
                    setPassRight(false);
                    let formData = new FormData();
                    deleteDocument(document, justification)
                } else {
                    setPassRight(true);
                    return true;
                }
            })
            .catch(error => {
                setPassRight(true);
                return true;
            });
    }
    var pass = true;
    const handleDelete = () => {
        if (justification.length > 0) setchangeContex(changeContext + 1)
        else setValidJustification(true)
        if (changeContext > 0) {
            if (password.length > 0) sendRequest();
            else setPasswordRequiredField(true);
            if (pass) setPassword("")
            else setOpenDelete(false);
        }
    };

    return (
        <React.Fragment>
            <Tooltip title={t("GENERAL.view")} aria-label="details" placement="top">
                <IconButton className={classes.iconsTableActions} onClick={() => setOpenActionView(document, index)} >
                    <Visibility />
                </IconButton>
            </Tooltip>
            {context !== "view" && <Tooltip title="Editar" aria-label="edit" placement="top">
                <IconButton className={classes.iconsTableActions} onClick={() => setOpenActionEdit(document, index)} >
                    <Edit />
                </IconButton>
            </Tooltip>}
            {context !== "view" && <Tooltip title="Deletar" aria-label="delete" placement="top">
                <IconButton className={classes.iconsTableActions} onClick={handleOpenDelete} >
                    <Delete />
                </IconButton>
            </Tooltip>}
            <Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="form-dialog-title"
                msg="mensagem"
            >
                <DialogTitle className={classes.Title}>
                    {t("GENERAL.delete") + " " + t("HR.document")}
                    <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleCloseDelete} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {changeContext === 0 ?
                        <DialogContentText>
                            <p>{t("HR.deleteMessageDocument")} <b>{(item.documentName)}</b>?*</p>
                            <TextField
                                error={validJustification}
                                multiline
                                fullWidth
                                variant="outlined"
                                rows="3"
                                value={justification}
                                label={t("INVENTORY.justification") + "*"}
                                margin="normal"
                                inputProps={{ maxLength: 100 }}
                                helperText={validJustification ? t(blankField) : `${justification.length}/100`}
                                onChange={(e) => {
                                    setValidJustification(false);
                                    setJustification(e.target.value)
                                }}
                            />
                        </DialogContentText>
                        :
                        <DialogContentText>
                            <p>{t("HR.deletePassworDoc")}</p>
                            <p><b>{(item.documentName)}</b></p>
                            <p>{t("INVENTORY.deleteActionCannotBeUndone")}</p>
                            <TextField
                                error={validPasswordrequiredField || passRight}
                                label={t("INVENTORY.password")}
                                value={password}
                                onChange={(e) => {
                                    setPasswordRequiredField(false)
                                    setPassRight(false);
                                    setPassword(e.target.value)
                                }}
                                type="password"
                                variant="filled"
                                helperText={validPasswordrequiredField ? t(blankField) : passRight ? "Senha inválida." : ""}
                                style={{ width: "90%", marginLeft: 30, marginRight: 30 }}
                            />
                        </DialogContentText>
                    }
                </DialogContent>
                <DialogActions>
                    <Buttons
                        onClick={handleCloseDelete}
                        tipo="BN-noback"
                        conteudo={t("GENERAL.cancel").toUpperCase()}
                    />
                    <Buttons
                        onClick={handleDelete}
                        tipo="BN-blue"
                        conteudo={changeContext === 0 ? t("GENERAL.next").toUpperCase() : t("GENERAL.delete").toUpperCase()}
                    />
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
};

export default QualificationTableAction;