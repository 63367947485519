import React, { useState, useEffect, useContext } from 'react';
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Buttons from "components/buttons/Pressed";
import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";
import InputBase from "@material-ui/core/InputBase";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InventoryContext from "context/inventory/inventoryContext"
import ProjectsContext from "context/projects/projectsContext"
import UsersContext from "context/users/usersContext"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const AdvancedSearchDialog = (props) => {
    const [t] = useTranslation();
    const classes = useStyles();
    const { open, setOpen, apply, places } = props;

    const inventoryContext = useContext(InventoryContext);
    const projectsContext = useContext(ProjectsContext);
    const usersContext = useContext(UsersContext);

    const [statusArray, setStatusArray] = useState([]);
    const [projectsArray, setProjectsArray] = useState([]);
    const [categoriesArray, setCategoriesArray] = useState([]);
    const [usersArray, setUsersArray] = useState([]);

    const {
        getItensCategories,
        itensCategories,
        getAllStatus,
        allStatus,
    } = inventoryContext;

    const {
        getProjects,
        projects
    } = projectsContext;

    const {
        getUsers,
        users
    } = usersContext;

    const sortElement = (elemnts) => {
        elemnts.sort(function (a, b) {
            if (a.name === null) a.name = "";
            if (b.name === null) b.name = "";

            if (a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() >
                b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim()) {
                return 1;
            }
            if (a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() <
                b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim()) {
                return -1;
            }
            // a must be equal to b
            return 0;
        });
    }

    useEffect(() => {
        getItensCategories();
        getProjects();
        getAllStatus();
        getUsers();
    }, []);

    useEffect(() => {
        const status = [...allStatus];
        sortElement(status);

        var aux = {};
        var _status = [];
        for (var i = 0; i < status.length; i++) {
            var item = status[i];
            aux.value = item.id;
            aux.label = item.name;
            _status.push(aux);
            aux = {};
        }
        setStatusArray(_status);
    }, [allStatus]);

    useEffect(() => {
        const allProjects = [...projects];
        sortElement(allProjects);

        var aux = {};
        var _projects = [];
        for (var i = 0; i < allProjects.length; i++) {
            var item = allProjects[i];
            aux.value = item.idInformation;
            aux.label = item.name;
            _projects.push(aux);
            aux = {};
        }
        setProjectsArray(_projects);
    }, [projects]);

    useEffect(() => {
        const categories = [...itensCategories];
        sortElement(categories);

        var aux = {};
        var _categories = [];
        for (var i = 0; i < categories.length; i++) {
            var item = categories[i];
            aux.value = item.id;
            aux.label = item.name;
            _categories.push(aux);
            aux = {};
        }
        setCategoriesArray(_categories);
    }, [itensCategories]);

    useEffect(() => {
        const allUsers = [...users];
        sortElement(allUsers);

        var aux = {};
        var _users = [];
        for (var i = 0; i < allUsers.length; i++) {
            var item = allUsers[i];
            aux.value = item.id;
            aux.label = item.name;
            _users.push(aux);
            aux = {};
        }
        setUsersArray(_users);
    }, [users]);

    const [values, setValues] = useState({
        name: "",
        place: "",
        responsible: "",
        idCode: "",
        serialNumber: "",
        status: "",
        project: "",
        invoice: "",
        category: "",
    });

    const [dates, setDates] = useState({
        dateAcquisition: null,
        warrantyDate: null
    });

    const cleanFields = () => {
        setValues({
            ...values,
            name: "",
            place: "",
            responsible: "",
            idCode: "",
            serialNumber: "",
            status: "",
            project: "",
            invoice: "",
            category: "",
        });
        setDates({
            ...dates,
            dateAcquisition: null,
            warrantyDate: null
        });
    }

    const handleClose = () => {
        setOpen(false);
        cleanFields();
    };

    const handleClean = () => {
        cleanFields();
        apply("", "", "", "", "", "", "", "", "", "", "")
        setOpen(false);
    };

    const handleExport = () => {
        apply(values.name, values.place, values.responsible, values.idCode, values.serialNumber, values.status, values.project, values.invoice, values.category, dates.dateAcquisition, dates.warrantyDate)
        setOpen(false);
        cleanFields();
    };

    const handleChange = (name, value) => {
        setValues({ ...values, [name]: value });
    };

    const handleDates = name => e => {
        setDates({
            ...dates,
            [name]: e
        });
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            msg="mensagem"
            maxWidth="md"
        >
            <DialogTitle className={classes.actionsTitle} style={{ width: 'auto' }}>
                <span style={{ fontWeight: 'bold' }}>
                    {t("INVENTORY.advancedSearch").toLocaleUpperCase()}
                </span>
                <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <FormControl component="fieldset">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormLabel component="legend">
                                    {t("INVENTORY.textAdvancedSearch")}
                                </FormLabel>
                            </Grid>
                            <Grid item xs={4}>
                                <div className={classes.searchInventory}>
                                    <InputBase
                                        placeholder={t("INVENTORY.name")}
                                        value={values.name}
                                        onChange={(ev) => { if (ev.target.value.length <= 50) handleChange("name", ev.target.value) }}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputSearch
                                        }}
                                        inputProps={{ "aria-label": "search" }}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className={classes.searchInventory}>
                                    <TextField
                                        select
                                        id="searchPlace"
                                        label={t("INVENTORY.place")}
                                        value={values.place}
                                        style={{ minWidth: "250px", marginTop: 0, marginBottom: 0, marginLeft: 5, height: "48px" }}
                                        onChange={(ev) => { handleChange("place", ev.target.value) }}
                                        className={classes.textField}
                                        margin="normal"
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputSearch
                                        }}
                                        inputProps={{ "aria-label": "search" }}
                                    >
                                        {places.map(places => (
                                            <MenuItem key={places.value} value={places.label}>
                                                {places.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className={classes.searchInventory}>
                                    <TextField
                                        select
                                        id="responsible"
                                        label={t("INVENTORY.responsible")}
                                        value={values.responsible}
                                        onChange={(ev) => handleChange("responsible", ev.target.value)}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputSearch
                                        }}
                                        inputProps={{ "aria-label": "search" }}
                                        style={{ width: "100%" }}
                                    >
                                        {usersArray.map(responsable => (
                                            <MenuItem key={responsable.value} value={responsable.label}>
                                                {responsable.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className={classes.searchInventory}>
                                    <InputBase
                                        placeholder={t("INVENTORY.indentificationCode")}
                                        value={values.idCode}
                                        onChange={(ev) => { if (ev.target.value.length <= 50) handleChange("idCode", ev.target.value) }}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputSearch
                                        }}
                                        inputProps={{ "aria-label": "search" }}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div style={{ height: "1px", backgroundColor: "#F5F6FA" }}></div>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.titleNewEquipment}>
                                    {t("INVENTORY.advanced")}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <div className={classes.searchInventory}>
                                    <InputBase
                                        placeholder={t("INVENTORY.serialNumber2")}
                                        value={values.serialNumber}
                                        onChange={(ev) => { if (ev.target.value.length <= 50) handleChange("serialNumber", ev.target.value) }}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputSearch
                                        }}
                                        inputProps={{ "aria-label": "search" }}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className={classes.searchInventory}>
                                    <TextField
                                        select
                                        label={t("INVENTORY.status")}
                                        value={values.status}
                                        onChange={(ev) => { handleChange("status", ev.target.value) }}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputSearch
                                        }}
                                        inputProps={{ "aria-label": "search" }}
                                        style={{ width: "100%" }}
                                    >
                                        {statusArray.map(status => (
                                            <MenuItem key={status.value} value={status.label}>
                                                {status.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className={classes.searchInventory}>
                                    <TextField
                                        select
                                        label={t("INVENTORY.project")}
                                        value={values.project}
                                        onChange={(ev) => { handleChange("project", ev.target.value) }}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputSearch
                                        }}
                                        inputProps={{ "aria-label": "search" }}
                                        style={{ width: "100%" }}
                                    >
                                        {projectsArray.map(project => (
                                            <MenuItem key={project.value} value={project.label}>
                                                {project.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className={classes.searchInventory}>
                                    <InputBase
                                        placeholder={t("INVENTORY.invoice")}
                                        value={values.invoice}
                                        onChange={(ev) => { if (ev.target.value.length <= 50) handleChange("invoice", ev.target.value) }}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputSearch
                                        }}
                                        inputProps={{ "aria-label": "search" }}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className={classes.searchInventory}>
                                    <TextField
                                        select
                                        label={t("INVENTORY.category")}
                                        value={values.category}
                                        onChange={(ev) => handleChange("category", ev.target.value)}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputSearch
                                        }}
                                        inputProps={{ "aria-label": "search" }}
                                        style={{ width: "100%" }}
                                    >
                                        {categoriesArray.map(category => (
                                            <MenuItem key={category.value} value={category.label}>
                                                {category.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            </Grid>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid item xs={4}>
                                    <div className={classes.searchInventory}>
                                        <KeyboardDatePicker
                                            className={classes.textField}
                                            disableToolbar
                                            autoOk={true}
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            id="dateAcquisition"
                                            invalidDateMessage=""
                                            label={t("INVENTORY.dateAcquisition")}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date"
                                            }}
                                            value={dates.dateAcquisition}
                                            onChange={handleDates("dateAcquisition")}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className={classes.searchInventory}>
                                        <KeyboardDatePicker
                                            className={classes.textField}
                                            disableToolbar
                                            autoOk={true}
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            id="warrantyDate"
                                            invalidDateMessage=""
                                            label={t("INVENTORY.warrantyData")}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date"
                                            }}
                                            value={dates.warrantyDate}
                                            onChange={handleDates("warrantyDate")}
                                        />
                                    </div>
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </FormControl>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Buttons
                    onClick={handleClose}
                    tipo="BN-noback"
                    conteudo={t("GENERAL.cancel").toLocaleUpperCase()}
                />
                <Buttons
                    onClick={handleClean}
                    tipo="BN-noback"
                    conteudo={t("GENERAL.clear").toLocaleUpperCase()}
                />
                <Buttons
                    onClick={handleExport}
                    tipo="BN-blue"
                    conteudo={t("GENERAL.apply").toLocaleUpperCase()}
                />
            </DialogActions>
        </Dialog>
    );
}
export default AdvancedSearchDialog;