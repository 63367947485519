/* eslint-disable */
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ArrowBack from "@material-ui/icons/ArrowBack";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useStyles } from "components/layout/CommonStyles";
import Footer from "components/layout/Footer";
import Spinner from "components/layout/spinners/Index";
import UserCostsContext from "context/hrcosts/hrCostsContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import "../profiles/css/FormStyle.css";
import UserInformation from "./UserInformation";
import UserMonthlyCosts from "./UserMonthlyCosts";

const ViewForm = props => {
  const classes = useStyles();
  const { id } = props;
  const [t] = useTranslation();
  const userCostsContext = useContext(UserCostsContext);
  const [currentCosts, setCurrentCosts] = useState([]);
  const {
    user,
    userCosts,
    getUser,
    getUserCosts,
    loading,
    resetMessage
  } = userCostsContext;

  useEffect(() => {
    resetMessage();
    getUser(id);
    getUserCosts(id);
    setCurrentCosts(userCosts.filter(el => el.costYear === new Date().getFullYear()));
  }, [id, Object.values(userCosts).length]);

  const transitions = useTransition(loading, null, {
    from: { opacity: 0 },
    enter: {
      opacity: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "calc(100vh - 80px)"
    },
    leave: { opacity: 0 }
  });

  const updateCosts = year => {
    setCurrentCosts(userCosts.filter(el => el.costYear === year));
  }

  return transitions.map(({ item, key, props }) =>
    item ? (
      <animated.div key={key} style={props}>
        <Spinner />;
      </animated.div>
    ) : (
        <animated.div key={key} style={props}>
          <div className="container">
            <Breadcrumbs
              className={classes.breadCrumbs}
              aria-label="breadcrumb"
              separator={<NavigateNextIcon fontSize="small" />}
            >
              <Typography>{t("ROUTES.planning")}</Typography>
              <Typography>{t("ROUTES.config")}</Typography>
              <Link className={classes.link} to="/userscosts">
                {t("HRCOSTS.hrCosts")}
              </Link>
              <Typography>{t("GENERAL.view")}</Typography>
            </Breadcrumbs>
            <div className="container-header">
              <div className="title">
                <h1 className={classes.title}>
                  {t("HRCOSTS.viewUserCosts")}
                </h1>
              </div>
              <Link to={"/userscosts"}>
                <Button
                  className={classes.blueButton}
                  variant="contained"
                  color="primary"
                >
                  <div className={classes.buttonIcon}>
                    <ArrowBack />
                  </div>
                  {t("GENERAL.back").toUpperCase()}
                </Button>
              </Link>
            </div>
            <UserInformation updateCosts={updateCosts} name={user.name} cpf={user.cpf} />
            <UserMonthlyCosts currentCosts={currentCosts} />
          </div>
          <Footer />
        </animated.div>
      )
  );
};

export default ViewForm;