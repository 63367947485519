/* eslint-disable */
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';
import Attachment from '@material-ui/icons/Attachment';
import CloseIcon from '@material-ui/icons/Close';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Footer from 'components/layout/Footer';
import Spinner from 'components/layout/spinners/Index';
import AllowancesContext from 'context/allowance/allowanceContext';
import ExternalUserContext from 'context/externalUsers/externalUsersContext';
import InventoryContext from 'context/inventory/inventoryContext';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import Link from 'react-router-dom/Link';
import { animated, useTransition } from 'react-spring';
import { useStyles } from '../../layout/CommonStyles';
import MySnackbarContentWrapper from '../../layout/Message';
import { phoneMask } from 'utils/masks/phoneMask';

const tooltipTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#2c3e51'
        }
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                color: 'white',
                fontSize: '1em',
                backgroundColor: 'red',
                opacity: '0.7'
            }
        }
    }
});

const EditExternalUser = props => {
    const { id } = props;
    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }
    const classes = useStyles();
    const [t] = useTranslation();
    const inventoryContext = useContext(InventoryContext);
    const allowanceContext = useContext(AllowancesContext);
    const externalUsersContext = useContext(ExternalUserContext);
    const [placesArray, setPlacesArray] = useState([]);
    const [hasUpdatedFile, setHasUpdatedFile] = useState(false);
    const [selectedPlacesArray, setSelectedPlacesArray] = useState([]);
    const [itemSelected, setItemSelected] = useState('');
    const [titleName, setTitle] = useState('GENERAL.requiredField');

    const [snackBar, setSnackBar] = useState({
        open: false,
        kind: '',
        content: ''
    });

    const [requiredName, setRequiredName] = useState(false);

    const [files, setFiles] = useState([]);

    const { getPlaces, places, getEquipmentByExternalUser, externalUserItens } =
        inventoryContext;

    const {
        loading,
        response,
        hasChanged,
        setHasChanged,
        responseType,
        successiveActions,
        getExternalUser,
        externalUser,
        getPlacesExternalUser,
        placesExternalUsers,
        updateExternalUser,
        getExternalUsers,
        externalUsers
    } = externalUsersContext;

    const {
        allowanceResponse,
        allowanceResponseType,
        messageHasChanged,
        resetAllowanceMessage
    } = allowanceContext;

    useEffect(() => {
        getPlaces();
        getExternalUsers();
        getEquipmentByExternalUser(id);
    }, []);

    const transitions = useTransition(loading, null, {
        from: { opacity: 0 },
        enter: {
            opacity: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: 'calc(100vh - 70px)'
        },
        leave: { opacity: 0 }
    });

    const [dataStates, setDataStates] = useState({
        places: []
    });

    const sortElement = elemnts => {
        elemnts.sort(function (a, b) {
            if (a.name === null) a.name = '';
            if (b.name === null) b.name = '';

            if (
                a.name
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .trim() >
                b.name
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .trim()
            ) {
                return 1;
            }
            if (
                a.name
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .trim() <
                b.name
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .trim()
            ) {
                return -1;
            }
            // a must be equal to b
            return 0;
        });
    };

    useEffect(() => {
        const myPlaces = [...places];
        sortElement(myPlaces);

        var aux = {};
        var _places = [];
        for (var i = 0; i < myPlaces.length; i++) {
            var item = myPlaces[i];
            aux.value = item.id;
            aux.placeId = item.id;
            aux.name = item.name;
            _places.push(aux);
            aux = {};
        }
        setPlacesArray(_places);
    }, [places]);

    var placeFilterSelected = placesArray.filter(
        x => !selectedPlacesArray.includes(x.value)
    );

    useEffect(() => {
        if (allowanceResponseType === 'ERROR' && messageHasChanged) {
            handleSnack({ kind: 'error', content: allowanceResponse });
        }
        if (responseType === 'SUCCESS' && hasChanged > successiveActions) {
            handleSnack({ kind: 'success', content: response });
        }
        if (responseType === 'ERROR' && hasChanged > successiveActions) {
            handleSnack({ kind: 'error', content: response });
        }
        return () => {};
    }, [
        responseType,
        response,
        successiveActions,
        allowanceResponseType,
        allowanceResponse
    ]);

    const [values, setValues] = useState({
        id: '',
        name: '',
        phone: '',
        email: '',
        description: '',
        imagePath: '',
        userExternalPlaces: []
    });

    useEffect(() => {
        getExternalUser(id);
        setValues({
            ...values,
            id: parseInt(id),
            name: externalUser.name ? externalUser.name : '',
            phone: externalUser.phoneNumber ? externalUser.phoneNumber : '',
            email: externalUser.email ? externalUser.email : '',
            description: externalUser.description
                ? externalUser.description
                : '',
            imagePath: externalUser.imagePath ? externalUser.imagePath : '',
            userExternalPlaces: externalUser.userExternalPlaces
                ? externalUser.userExternalPlaces
                : []
        });

        return () => {};
    }, [JSON.stringify(externalUser)]);

    useEffect(() => {
        getPlacesExternalUser(id);

        while (dataStates.places.length) {
            dataStates.places.pop();
        }

        var _places = [];
        var _placesIds = [];
        let aux = {};
        for (var i = 0; i < placesExternalUsers.length; i++) {
            var item = placesExternalUsers[i];
            aux.value = item.id;
            aux.placeId = item.placeId;
            aux.name = item.place.name;
            _places.push(aux);
            _placesIds.push(aux.placeId);
            aux = {};
        }
        setDataStates({
            ...dataStates,
            places: dataStates.places.concat(_places)
        });
        setSelectedPlacesArray(selectedPlacesArray.concat(_placesIds));

        return () => {};
    }, [JSON.stringify(placesExternalUsers).length]);

    const handleSnack = () => {
        setSnackBar({ ...snackBar, open: true });
        setHasChanged();
    };
    const handleSnackClose = () => {
        setSnackBar({ ...snackBar, open: false });
        resetAllowanceMessage();
    };

    const onDrop = acceptedFiles => {
        if (acceptedFiles[0] !== undefined) {
            setHasUpdatedFile(true);
            setFiles(
                acceptedFiles.map(file =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })
                )
            );
        }
    };

    const addPlaces = place => {
        if (place) {
            setDataStates({
                ...dataStates,
                places: dataStates.places.concat(place)
            });
            setItemSelected('');
            place && selectedPlacesArray.push(place.value);
        }
    };

    const onRemovePlace = data => {
        if (data) {
            let remainingPlaces = [];
            for (const place of dataStates.places) {
                if (place.id === data.id && place.name === data.name) {
                    continue;
                }
                remainingPlaces.push(place);
            }
            setDataStates({ ...dataStates, places: remainingPlaces });
        }
    };

    const image = files.map(file => (
        <React.Fragment>
            <div className={classes.attachEquipment}>{t('Alterar imagem')}</div>
            <div className={classes.thumb} key={file.name}>
                <div className={classes.thumbInner}>
                    <img
                        src={file.preview}
                        className={classes.imgNewEquipment}
                    />
                </div>
            </div>
        </React.Fragment>
    ));

    const handleChange = (name, value) => {
        if (name === 'phone') {
            setValues({ ...values, [name]: phoneMask(value) });
        } else if (name === 'name') {
            setValues({ ...values, [name]: value });
            var existingNames = externalUsers.filter(
                item =>
                    item.name.toLowerCase() === value.toLowerCase() &&
                    item.id != id
            );
            if (existingNames.length !== 0) {
                setTitle('EXTERNALUSERS.nameAlreadyTaken');
                setRequiredName(true);
            } else {
                setRequiredName(false);
            }
        } else {
            setValues({ ...values, [name]: value });
        }
    };

    const verifyCanSubmit = () => {
        let res = true;
        if (!values.name) {
            res = false;
            setRequiredName(true);
        }
        return res;
    };

    const submit = () => {
        const canSubmit = verifyCanSubmit();
        if (canSubmit) {
            let formData = new FormData();
            formData.append('external.id', values.id);
            formData.append('external.name', values.name);
            formData.append('external.phoneNumber', values.phone);
            formData.append('external.email', values.email);
            formData.append(
                'external.imagePath',
                values.imagePath ? values.imagePath : ''
            );
            formData.append('external.description', values.description);

            const placeToAdd = dataStates.places.filter(
                x =>
                    !values.userExternalPlaces.some(
                        original => original.placeId === x.placeId
                    )
            );
            const placeToDelete = values.userExternalPlaces.filter(
                original =>
                    !dataStates.places.some(x => x.placeId === original.placeId)
            );

            // Adding new places
            for (let i = 0; i < placeToAdd.length; i++) {
                formData.append(`placesAdd[${i}].userExternalId`, values.id);
                formData.append(
                    `placesAdd[${i}].placeId`,
                    placeToAdd[i].placeId
                );
            }

            // Deleting places
            for (let i = 0; i < placeToDelete.length; i++) {
                formData.append(`placesDelete[${i}].id`, placeToDelete[i].id);
                formData.append(
                    `placesDelete[${i}].userExternalId`,
                    placeToDelete[i].userExternalId
                );
                formData.append(
                    `placesDelete[${i}].placeId`,
                    placeToDelete[i].placeId
                );
            }

            // Adding equipment image
            if (files[0]) {
                formData.append('image', files[0]);
            }

            // Updates the external user and redirects to the ExternalUsers screen
            updateExternalUser(formData);
        }
    };

    var idsPlacesWithEquipment = externalUserItens.map(function (elem) {
        return elem.placeId;
    });

    return transitions.map(({ item, key, props }) =>
        item ? (
            <animated.div key={key} style={props}>
                <Spinner />;
            </animated.div>
        ) : (
            <animated.div key={key} style={props}>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={snackBar.open}
                    onClose={handleSnackClose}
                    TransitionComponent={Slide}
                    transitionDuration={{ enter: 500, exit: 500 }}
                    ContentProps={{
                        'aria-describedby': 'message-id'
                    }}
                    autoHideDuration={2000}
                >
                    <MySnackbarContentWrapper
                        onClose={handleSnackClose}
                        variant={
                            responseType !== ''
                                ? responseType.toLowerCase()
                                : allowanceResponseType.toLowerCase()
                        }
                        message={
                            <span id="message-id">
                                {response !== '' ? response : allowanceResponse}
                            </span>
                        }
                    />
                </Snackbar>
                <div className="container">
                    <Breadcrumbs
                        className={classes.breadCrumbs}
                        aria-label="breadcrumb"
                        separator={<NavigateNextIcon fontSize="small" />}
                    >
                        <Typography>{t('INVENTORY.inventory')}</Typography>
                        <Link className={classes.link} to="/ExternalUsers">
                            <Typography>
                                {t('EXTERNALUSERS.externalUsers')}
                            </Typography>
                        </Link>
                        <Typography>
                            {t('EXTERNALUSERS.editExternalUser')}
                        </Typography>
                    </Breadcrumbs>
                    <div className="container-header">
                        <div className="title">
                            <h1 className={classes.title}>
                                {t('EXTERNALUSERS.editExternalUser')}
                            </h1>
                        </div>
                        <Button
                            className={classes.blueButton}
                            variant="contained"
                            color="primary"
                            onClick={submit}
                        >
                            {t('GENERAL.update')}
                        </Button>
                    </div>
                    <Paper>
                        <div className="cabecalho">
                            {t('EXTERNALUSERS.externalUserData').toUpperCase()}
                        </div>
                        <div className={classes.bodyNewEquipment}>
                            <Grid container spacing={3}>
                                <Grid
                                    item
                                    xs={2}
                                    className={classes.backgroundImage}
                                >
                                    <Grid item xs={2}>
                                        <Dropzone
                                            variant="outlined"
                                            component="span"
                                            className={classes.textField}
                                            onDrop={onDrop}
                                            accept="image/jpeg,
                                                        image/png,
                                                        image/bmp"
                                        >
                                            {({
                                                getRootProps,
                                                getInputProps,
                                                isDragActive,
                                                isDragReject
                                            }) => (
                                                <div {...getRootProps()}>
                                                    <input
                                                        {...getInputProps()}
                                                    />
                                                    {!isDragActive && (
                                                        <FormControl
                                                            className={
                                                                classes.customAttachedDocumentTextField
                                                            }
                                                        >
                                                            {values.imagePath
                                                                .length == 0 &&
                                                            !hasUpdatedFile ? (
                                                                <Grid
                                                                    item
                                                                    xs={2}
                                                                >
                                                                    <div
                                                                        className={
                                                                            classes.attachEquipment
                                                                        }
                                                                    >
                                                                        {t(
                                                                            'Carregar imagem'
                                                                        )}
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            classes.thumb
                                                                        }
                                                                        key={
                                                                            'addimage'
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={
                                                                                classes.thumbInner
                                                                            }
                                                                        >
                                                                            <img
                                                                                className={
                                                                                    classes.imgNewEquipment
                                                                                }
                                                                                alt="add-image"
                                                                                src={
                                                                                    window
                                                                                        .location
                                                                                        .origin +
                                                                                    '/img/upload-equipament-image.png'
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Grid>
                                                            ) : values.imagePath
                                                                  .length > 0 &&
                                                              !hasUpdatedFile ? (
                                                                <Grid
                                                                    item
                                                                    xs={2}
                                                                >
                                                                    <div
                                                                        className={
                                                                            classes.attachEquipment
                                                                        }
                                                                    >
                                                                        {t(
                                                                            'Alterar imagem'
                                                                        )}
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            classes.thumb
                                                                        }
                                                                        key={
                                                                            'addimage'
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={
                                                                                classes.thumbInner
                                                                            }
                                                                        >
                                                                            <img
                                                                                className={
                                                                                    classes.imgNewEquipment
                                                                                }
                                                                                alt="add-image"
                                                                                src={
                                                                                    values.imagePath
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Grid>
                                                            ) : (
                                                                image
                                                            )}
                                                        </FormControl>
                                                    )}
                                                    {isDragReject && (
                                                        <FormControl
                                                            className={
                                                                classes.customAttachedDocumentTextField
                                                            }
                                                        >
                                                            <Input
                                                                id="custom-attached-input-cooperation"
                                                                readOnly={true}
                                                                endAdornment={
                                                                    <InputAdornment
                                                                        style={{
                                                                            transform:
                                                                                'rotate(270deg)'
                                                                        }}
                                                                        position="end"
                                                                    >
                                                                        <Attachment />
                                                                    </InputAdornment>
                                                                }
                                                                aria-describedby="weight-helper-text"
                                                                inputProps={{
                                                                    'aria-label':
                                                                        'weight'
                                                                }}
                                                                value={t(title)}
                                                                //disabled={mode === t("GENERAL.viewMode")}
                                                            />
                                                        </FormControl>
                                                    )}
                                                </div>
                                            )}
                                        </Dropzone>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={9}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={5}>
                                            <MuiThemeProvider
                                                theme={tooltipTheme}
                                            >
                                                <Tooltip
                                                    TransitionComponent={Zoom}
                                                    open={requiredName}
                                                    title={t(titleName)}
                                                >
                                                    <TextField
                                                        className={
                                                            classes.textField
                                                        }
                                                        label={
                                                            t(
                                                                'EXTERNALUSERS.name'
                                                            ) + '*'
                                                        }
                                                        value={values.name}
                                                        onChange={ev => {
                                                            if (
                                                                ev.target.value
                                                                    .length <=
                                                                50
                                                            )
                                                                handleChange(
                                                                    'name',
                                                                    ev.target
                                                                        .value
                                                                );
                                                        }}
                                                        margin="normal"
                                                    />
                                                </Tooltip>
                                            </MuiThemeProvider>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                className={classes.textField}
                                                label={t('EXTERNALUSERS.phone')}
                                                value={values.phone}
                                                onChange={ev => {
                                                    if (
                                                        ev.target.value
                                                            .length <= 50
                                                    )
                                                        handleChange(
                                                            'phone',
                                                            ev.target.value
                                                        );
                                                }}
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                className={classes.textField}
                                                label={t('EXTERNALUSERS.email')}
                                                value={values.email}
                                                onChange={ev => {
                                                    if (
                                                        ev.target.value
                                                            .length <= 50
                                                    )
                                                        handleChange(
                                                            'email',
                                                            ev.target.value
                                                        );
                                                }}
                                                margin="normal"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <React.Fragment>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        style={{ paddingBottom: 0 }}
                                    >
                                        <Typography
                                            className={
                                                classes.titleNewEquipment
                                            }
                                        >
                                            Locais Relacionados
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        style={{
                                            border: '1px solid rgba(0, 0, 0, 0.28)',
                                            borderRadius: 4,
                                            margin: 10
                                        }}
                                    >
                                        <Grid item xs={12}>
                                            {dataStates.places.length > 0 && (
                                                <div>
                                                    {dataStates.places.map(
                                                        place =>
                                                            idsPlacesWithEquipment.includes(
                                                                place.placeId
                                                            ) ? (
                                                                <div
                                                                    className={
                                                                        classes.dialogFile
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            classes.textFile
                                                                        }
                                                                    >
                                                                        <Typography
                                                                            className={
                                                                                classes.nameFile
                                                                            }
                                                                        >
                                                                            {place.name
                                                                                ? place.name
                                                                                : place.name}
                                                                        </Typography>
                                                                        <Typography
                                                                            className={
                                                                                classes.itemActiveOnExternalUser
                                                                            }
                                                                        >
                                                                            (Item
                                                                            ativo)
                                                                        </Typography>
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            classes.iconsFile
                                                                        }
                                                                    >
                                                                        <IconButton
                                                                            disabled
                                                                        >
                                                                            <CloseIcon />
                                                                        </IconButton>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    className={
                                                                        classes.dialogFile
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            classes.textFile
                                                                        }
                                                                    >
                                                                        <Typography
                                                                            className={
                                                                                classes.nameFile
                                                                            }
                                                                        >
                                                                            {place
                                                                                ? place.name
                                                                                : ''}
                                                                        </Typography>
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            classes.iconsFile
                                                                        }
                                                                    >
                                                                        <IconButton
                                                                            onClick={() =>
                                                                                onRemovePlace(
                                                                                    place
                                                                                )
                                                                            }
                                                                        >
                                                                            <CloseIcon />
                                                                        </IconButton>
                                                                    </div>
                                                                </div>
                                                            )
                                                    )}
                                                </div>
                                            )}
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={12}>
                                                <Autocomplete
                                                    style={{ margin: 10 }}
                                                    inputValue={itemSelected}
                                                    onChange={(
                                                        event,
                                                        newValue
                                                    ) => {
                                                        addPlaces(newValue);
                                                    }}
                                                    id="combo-box-demo"
                                                    options={
                                                        placeFilterSelected
                                                    }
                                                    getOptionLabel={option =>
                                                        option.name
                                                    }
                                                    onInputChange={(
                                                        event,
                                                        newValue
                                                    ) => {
                                                        if (event) {
                                                            setItemSelected(
                                                                event.target
                                                                    .value
                                                            );
                                                        }
                                                    }}
                                                    closeIcon={true}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            label="Locais"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        multiline
                                        fullWidth
                                        variant="outlined"
                                        rows="6"
                                        id="description"
                                        value={values.description}
                                        onChange={ev => {
                                            if (ev.target.value.length <= 500)
                                                handleChange(
                                                    'description',
                                                    ev.target.value
                                                );
                                        }}
                                        label={t(
                                            'INVENTORY.descriptionBookCategory'
                                        )}
                                        margin="normal"
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </Paper>
                </div>
                <Footer />
            </animated.div>
        )
    );
};

export default EditExternalUser;
