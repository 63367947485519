import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    ArrowheadIconStyled,
    SelectWrapper, Option,
    OptionsWrapper,
    Select, SelectContainer
} from "./InputFilterSimpleSelect.styled";
import {MultiFilterContext} from "../../MultiFilter/MultiFilter";

const InputFilterSimpleSelect = ({
                                     column,
                                     placeholder,
                                     options,
                                     isMultiFilter = false
                                 }) => {
    const {handleFilterChange} = useContext(MultiFilterContext);
    const selectRef = useRef(null)
    const selectWrapperRef = useRef(null)
    const optionsRef = useRef(null)
    const [showOptions, setShowOptions] = useState(false);
    const [isMounted, setIsMounted] = useState(false)
    const [label, setLabel] = useState(null)

    useEffect(() => {
        if (isMultiFilter) {
            selectRef.current.addEventListener("change", (event) => {
                handleFilterChange(event)
            });
        }
    });

    const handleSelectionOfOption = (option) => {
        setLabel(option.label)
        selectRef.current.value = option.value
        const onChangeEvent = new CustomEvent("change");
        selectRef.current.dispatchEvent(onChangeEvent)
        setNewColorToFontAfterSelectAnOption(option)
        setIsMounted(false)
    }

    const setNewColorToFontAfterSelectAnOption = (item) => {
        if (item?.label !== placeholder) {
            selectRef.current.style.color = "#000"
        }
    }

    useEffect(() => {
        const handler = (e) => {
            if ((selectWrapperRef.current && !selectWrapperRef.current.contains(e.target)) && (optionsRef.current && !optionsRef.current.contains(e.target))) {
                setIsMounted(false)
            }
        };
        window.addEventListener("click", handler);
        return () => {
            window.removeEventListener("click", handler);
        };
    });

    return (
        <SelectContainer>
            <SelectWrapper ref={selectWrapperRef} onClick={() => {
                setIsMounted(!isMounted)
                if (!showOptions) setShowOptions(true);
            }}>
                <Select
                    name={column}
                    ref={selectRef}
                >
                    {label ?? placeholder}
                    <ArrowheadIconStyled/>
                </Select>
            </SelectWrapper>
            {showOptions && (
                <OptionsWrapper
                    $optionQuantity={options.length}
                    $isMounted={isMounted}
                    onAnimationEnd={() => {
                        if (!isMounted) setShowOptions(false)
                    }}
                >
                    {options?.map((option, index) => (
                        <Option
                            key={index}
                            ref={optionsRef}
                            value={option.value}
                            onClick={(event) => handleSelectionOfOption(option)}
                        >
                            {option.label}
                        </Option>
                    ))}
                </OptionsWrapper>
            )}
        </SelectContainer>
    );
};

export default InputFilterSimpleSelect;
