import Snackbar from 'components/snackbar/Snackbar';
import React, { createContext, useContext, useState } from 'react';

export const snackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
    const [messages, setMessages] = useState([]);

    const removeMessage = key => {
        setMessages(arr => arr.filter(m => m.key !== key));
    };

    const addMessage = message => {
        setMessages(arr => [...arr, message]);
    };

    return (
        <snackbarContext.Provider
            value={{
                addMessage
            }}
        >
            {children}
            {messages.map(m => (
                <Snackbar
                    key={m.key}
                    message={m}
                    isOpen={true}
                    onExited={() => removeMessage(m.key)}
                />
            ))}
        </snackbarContext.Provider>
    );
};

export const useSnackbar = () => {
    const { addMessage } = useContext(snackbarContext);

    const show = (message, kind) => {
        addMessage({ message, kind, key: new Date().getTime() });
    };

    return {
        show,
        info(message) {
            show(message, 'info');
        },
        success(message) {
            show(message, 'success');
        },
        warning(message) {
            show(message, 'warning');
        },
        error(message) {
            show(message, 'error');
        }
    };
};
