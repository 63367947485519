import React, {useState} from 'react';
import { screenStyles } from "./Styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Button from "@material-ui/core/Button";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import DefaultTooltip from "components/infoTooltip/DefaultTooltip";
import HelpTooltip from "components/infoTooltip/HelpTooltip";

import { useTranslation } from "react-i18next";
import { Checkbox } from '@material-ui/core';



const ScreensTable = (props) => {
    const styles = screenStyles();
    const [t] = useTranslation();

    const {rows, setRows, moduleName, isManager, context , tab } = props

    const [viewMore, setViewMore] = useState(true);

    const headers = [
        t("HR.screen"),
        t("GENERAL.view"),
        t("GENERAL.add"),
        t("GENERAL.edit"),
        t("GENERAL.delete")
    ]

    const handleChange = (allowType , value , idx) => {
        let rowsChanged = [...rows];
        let screenChanged = rowsChanged[idx];

        if(screenChanged.allowRead != undefined && allowType === "allowRead"){
            screenChanged.allowRead = value;
            if(value === false){
                if(screenChanged.allowCreate != undefined ){
                    screenChanged.allowCreate = false;
                }
                if(screenChanged.allowEdit != undefined ){
                    screenChanged.allowEdit = false;
                }
                if(screenChanged.allowDelete != undefined ){
                    screenChanged.allowDelete = false;
                }
            }
        }
        if(screenChanged.allowCreate != undefined && allowType === "allowCreate"){
            screenChanged.allowCreate = value;
        }
        if(screenChanged.allowEdit != undefined && allowType === "allowEdit"){
            screenChanged.allowEdit = value;
        }
        if(screenChanged.allowDelete != undefined && allowType === "allowDelete"){
            screenChanged.allowDelete = value;
        }

        rowsChanged[idx] = screenChanged;

        setRows(rowsChanged)
    }


    return (
            <React.Fragment>
            <div className={styles.headerViewMore} onClick={() => setViewMore(!viewMore)}>
                <Button className={styles.headerViewMoreButton} >
                    {moduleName ? moduleName.toUpperCase() : ""}
                    {viewMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Button>
            </div>

            {viewMore &&
                <React.Fragment>
                    <Table className={styles.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {headers.map((header, idx) => (
                                    <TableCell className={styles.header} align={idx === 0 ? "left": "center"}>{header}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            { tab ?
                            rows.map((row, idx) => {
                                return row.tab === tab &&
                                <TableRow key={row.screen}>
                                    <TableCell component="th" scope="row">
                                        <div className={styles.wrapper}>
                                            {t(row.screen)}
                                            {row.isManager != undefined && isManager ? <DefaultTooltip className={styles.errorIcon} text={t("HR.profileCanOnlySeeApproversOrSurrogates")} /> : null}
                                            {row.helpTooltip != undefined && row.helpTooltip ? <HelpTooltip className={styles.errorIcon} text={t(row.helpTooltip)} /> : null}
                                        </div>
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.allowRead != undefined ?
                                        <Checkbox
                                            disabled={context === "view"}
                                            color="primary"
                                            checked={row.allowRead}
                                            onChange={(ev) => handleChange("allowRead", ev.target.checked, idx)}
                                        /> : "-"}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.allowCreate != undefined ?
                                        <Checkbox
                                            color="primary"
                                            disabled={!row.allowRead || context === "view"}
                                            checked={row.allowCreate}
                                            onChange={(ev) => handleChange("allowCreate", ev.target.checked, idx)}
                                        /> : "-"}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.allowEdit != undefined ?
                                        <Checkbox
                                            color="primary"
                                            disabled={!row.allowRead || context === "view"}
                                            checked={row.allowEdit}
                                            onChange={(ev) => handleChange("allowEdit", ev.target.checked, idx)}
                                        /> : "-"}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.allowDelete != undefined ?
                                        <Checkbox
                                            color="primary"
                                            disabled={!row.allowRead || context === "view"}
                                            checked={row.allowDelete}
                                            onChange={(ev) => handleChange("allowDelete", ev.target.checked, idx)}
                                        /> : "-"}
                                    </TableCell>
                                </TableRow>
                                })
                                :
                            rows.map((row, idx) =>
                            {
                                return !row.tab &&
                            <TableRow key={row.screen}>
                                <TableCell  component="th" scope="row">
                                    <div className={styles.wrapper}>
                                        {t(row.screen)}
                                        {row.isManager != undefined && isManager ? <DefaultTooltip className={styles.errorIcon} text={t("HR.profileCanOnlySeeApproversOrSurrogates")} /> : null}
                                    </div>
                                </TableCell>
                                <TableCell align="center">
                                    {row.allowRead != undefined ?
                                    <Checkbox
                                        disabled={context === "view"}
                                        color="primary"
                                        checked={row.allowRead}
                                        onChange={(ev) => handleChange("allowRead", ev.target.checked, idx)}
                                    /> : "-"}
                                </TableCell>
                                <TableCell align="center">
                                    {row.allowCreate != undefined ?
                                    <Checkbox
                                        color="primary"
                                        disabled={!row.allowRead || context === "view"}
                                        checked={row.allowCreate}
                                        onChange={(ev) => handleChange("allowCreate", ev.target.checked, idx)}
                                    /> : "-"}
                                </TableCell>
                                <TableCell align="center">
                                    {row.allowEdit != undefined ?
                                    <Checkbox
                                        color="primary"
                                        disabled={!row.allowRead || context === "view"}
                                        checked={row.allowEdit}
                                        onChange={(ev) => handleChange("allowEdit", ev.target.checked, idx)}
                                    /> : "-"}
                                </TableCell>
                                <TableCell align="center">
                                    {row.allowDelete != undefined ?
                                    <Checkbox
                                        color="primary"
                                        disabled={!row.allowRead || context === "view"}
                                        checked={row.allowDelete}
                                        onChange={(ev) => handleChange("allowDelete", ev.target.checked, idx)}
                                    /> : "-"}
                                </TableCell>
                            </TableRow>
                            })}
                        </TableBody>
                        </Table>
            </React.Fragment>
            }
            </React.Fragment>
    );
}

export default ScreensTable;
