
import React, { } from "react";
import { useStyles } from "components/layout/CommonStyles";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

const ProjectInformation = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const {
        projectName,
        gpf,
        projectStartDate,
        projectEndDate
    } = props;

    var startD = new Date(projectStartDate);
    var endD = new Date(projectEndDate)
    var startDate = projectStartDate ? (startD.setMinutes(startD.getMinutes() + startD.getTimezoneOffset())) : projectStartDate;
    var endDate = projectEndDate ? (endD.setMinutes(endD.getMinutes() + endD.getTimezoneOffset())) : projectEndDate
    
    return (
        <React.Fragment>
            <Paper className={classes.root}>
                <div className={classes.header}>
                    {t("GENERAL.projectInformation").toUpperCase()}
                </div>
                <div className={classes.bodyForm}>
                    <Grid container>
                        <Grid container item spacing={2} md={12} lg={12}>
                            <Grid item xs={3}>
                                <TextField
                                    disabled
                                    className={classes.textField}
                                    margin="normal"
                                    id="gpf"
                                    label={t("PROJECTS.gpfUpperCase")}
                                    name="GPF"
                                    value={gpf}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <TextField
                                    disabled
                                    className={classes.textField}
                                    margin="normal"
                                    id="name"
                                    label={t("PROJECTS.projectName")}
                                    name="name"
                                    value={projectName}
                                />
                            </Grid>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid item xs={2}>
                                    <KeyboardDatePicker
                                        className={classes.textField}
                                        disabled
                                        disableToolbar
                                        autoOk={true}
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="projectStartDate"
                                        label={t("GENERAL.startDate")}
                                        value={startDate}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <KeyboardDatePicker
                                        className={classes.textField}
                                        disabled
                                        disableToolbar
                                        autoOk={true}
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="projectEndDate"
                                        label={t("GENERAL.endDate")}
                                        value={endDate}
                                    />
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                </div>
            </Paper>
            <br />
            <br />
        </React.Fragment>
    )
}

export default ProjectInformation;