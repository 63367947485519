/* eslint-disable */
import axios from 'axios';
import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT, PROFILES, USERS } from '../types';
import UsersContext from './usersContext';
import UsersReducer from './usersReducer';
import Cookies from 'js-cookie';

const UsersState = props => {
    const initialState = {
        user: {},
        users: [],
        allusers: [],
        message: '',
        response: '',
        responseType: '',
        hasChanged: 0,
        successiveActions: 0,
        loading: false,
        profiles: [],
        usersByPermissions: [],
        libraryManagersUsers: [],
        libraryCoordinationUsers: []
    };
    const [t] = useTranslation();
    const [state, dispatch] = useReducer(UsersReducer, initialState);

    const setLoading = () => dispatch({ type: DEFAULT.SET_LOADING });
    const resetMessage = () => dispatch({ type: DEFAULT.RESET_MESSAGE });
    const setHasChanged = () => dispatch({ type: DEFAULT.SET_HAS_CHANGED });

    const getUsers = async () => {
        setLoading();
        const res = await axios.get(
            process.env.REACT_APP_SERVER + 'api/users',
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: USERS.GET_USERS,
            payload: res.data
        });
    };

    const getUsersByPermission = async screen => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER +
                'api/EmailUsers/Permissions/' +
                screen,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: USERS.GET_USERS_BY_PERMISSIONS,
            payload: res.data
        });
    };

    const getLibraryManagersUsers = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER +
                'api/PermissionsUsers/LibraryManagers/',
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: USERS.GET_LIBRARY_MANAGER_USERS,
            payload: res.data
        });
    };

    const getLibraryCoordinationUser = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER +
                'api/PermissionsUsers/LibraryCoordination/',
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: USERS.GET_LIBRARY_COORDINATION_USERS,
            payload: res.data
        });
    };

    const getAllUsers = async () => {
        setLoading();
        const res = await axios.get(
            process.env.REACT_APP_SERVER + 'api/users/all',
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: USERS.GET_ALL_USERS,
            payload: res.data
        });
    };

    const getUser = async id => {
        setLoading();
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/users/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: USERS.GET_USER,
            payload: res.data
        });
    };

    const deleteUser = id => {
        setLoading();
        const res = axios
            .delete(process.env.REACT_APP_SERVER + `api/users/${id}`, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .catch(error => {});

        dispatch({
            type: USERS.DELETE_USER,
            payload: 'Usuário deletado com sucesso!'
        });
    };

    const getProfiles = async () => {
        setLoading();
        let aux = {};
        let cleanProfiles = [];
        const res = await axios.get(
            process.env.REACT_APP_SERVER + 'api/Profiles',
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        for (var item in res.data) {
            aux.value = res.data[item].id;
            aux.label = res.data[item].name;
            cleanProfiles.push(aux);
            aux = {};
        }
        aux.value = null;
        aux.label = t('GENERAL.none');
        cleanProfiles.push(aux);
        aux = {};
        dispatch({
            type: PROFILES.GET_PROFILES,
            payload: cleanProfiles
        });
    };

    const updateUser = obj => {
        setLoading();
        let data = JSON.stringify({
            id: obj.id,
            cpf: obj.cpf,
            name: obj.name,
            email: obj.email,
            profileName: '',
            lattes: obj.lattes,
            isActive: obj.isActive,
            profileId:
                typeof obj.profile !== 'string' ? obj.profile : obj.profileId
        });
        axios
            .put(process.env.REACT_APP_SERVER + `api/users/${obj.id}`, data, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(res => {
                if (res.status === 200) {
                    getUsers().then(() => {
                        dispatch({
                            type: USERS.UPDATE_USER,
                            payload: t('USERS.successfullyEdited'),
                            responseType: 'SUCCESS',
                            successiveActions: state.successiveActions + 1,
                            hasChanged: state.successiveActions + 2
                        });
                    });
                } else {
                    getUsers().then(() => {
                        dispatch({
                            type: USERS.UPDATE_USER,
                            payload: t('USERS.unsuccessfullyUpdated'),
                            responseType: 'ERROR',
                            successiveActions: state.successiveActions + 1,
                            hasChanged: state.successiveActions + 2
                        });
                    });
                }
            })
            .catch(() => {
                getUsers().then(() => {
                    dispatch({
                        type: USERS.UPDATE_USER,
                        payload: t('USERS.unsuccessfullyUpdated'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                });
            });
    };

    return (
        <UsersContext.Provider
            value={{
                user: state.user,
                users: state.users,
                usersByPermissions: state.usersByPermissions,
                allusers: state.allusers,
                loading: state.loading,
                message: state.message,
                hasChanged: state.hasChanged,
                successiveActions: state.successiveActions,
                libraryManagersUsers: state.libraryManagersUsers,
                libraryCoordinationUsers: state.libraryCoordinationUsers,
                getUsersByPermission,
                getUser,
                getUsers,
                getAllUsers,
                deleteUser,
                getProfiles,
                profiles: state.profiles,
                updateUser,
                setHasChanged,
                resetMessage,
                response: state.response,
                responseType: state.responseType,
                getLibraryCoordinationUser,
                getLibraryManagersUsers
            }}
        >
            {props.children}
        </UsersContext.Provider>
    );
};

export default UsersState;
