import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Add from "@material-ui/icons/Add";
import Dissatisfied from "@material-ui/icons/SentimentVeryDissatisfied";
import ParticipantsTable from "components/execution/Participants/ParticipantsTable";
import SearchList from "components/execution/SearchListParticipants";
import { useStyles } from "components/layout/CommonStyles";
import SearchContext from "context/search/searchContext";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ActivityParticipants = props => {
    const { projectId, participants, mode = 'edit', activityId } = props;
    const searchContext = useContext(SearchContext);
    const { filter } = searchContext;
    const [t] = useTranslation();
    const classes = useStyles();
    let dataFilter = [];
    const hasParticipant = participants.length > 0;
    dataFilter = participants;

    if (filter) {
        dataFilter = dataFilter.filter(
            item =>
                item.participantName.toLowerCase().includes(filter.toLowerCase()) ||
                item.participantfunction.toLowerCase().includes(filter.toLowerCase()) ||
                item.participantEducation.toLowerCase().includes(filter.toLowerCase())
        );
    }

    return (
        <Paper className={classes.root}>
            <div className={classes.header}>
                {t("PROFILES.form.participants").toUpperCase()}
            </div>
            <div className={classes.divAddParticipant}>
                <div style={{ visibility: !hasParticipant ? "hidden" : "" }} className={classes.participantSearch}>
                    <SearchList label={t("ACTIVITIES.participantsSearchLabel")} />
                </div>
                {mode === t("GENERAL.editMode") &&
                    <Link to={`/execution/phaseActivity/${activityId}/edit/addParticipants`}>
                        <Button
                            renderAs={Link}
                            className={classes.addParticipant}
                            variant="contained"
                        >
                            <div className={classes.buttonIcon}>
                                <Add />
                            </div>
                            {t("ACTIVITIES.allocateParticipants")}
                        </Button>
                    </Link>
                }
            </div>
            <div className="lineBreak"></div>
            {hasParticipant ?
                <ParticipantsTable
                    rows={dataFilter}
                    headers={["id", "imagePath", "participantName", "participantEducation", "participantfunction", "period", "hoursFormated"]}
                    sortedFields={["", "participantName", "participantEducation", "participantfunction", "period", "hoursFormated"]}
                    visibleHeaders={[
                        "#",
                        "NOME",
                        "GRAU DE FORMAÇÃO",
                        "CARGO",
                        "PERÍODO NA ATIVIDADE",
                        "HORAS TRABALHADAS"
                    ]}
                    mainField="imagePath"
                    projectId={projectId}
                    activityId={activityId}
                    mode={mode}
                    isActivity={true}
                /> :
                <div className={classes.noParticipantsMessage}>
                    <h2>{t("PARTICIPANTS.noParticipantsMessage")}</h2>
                    <h4>{t("PARTICIPANTS.noParticipantsSubMessage")}</h4>
                </div>
            }
            {(hasParticipant && dataFilter.length === 0) &&
                <div className={classes.noParticipantsMessage}>
                    <div >
                        <Dissatisfied className={classes.notFoundIconStyle} />
                    </div>
                    <h2>{t("GENERAL.noResultsFound")}</h2>
                    <h5>{`Sua busca por "${filter}" não retornou resultados.`}</h5>
                </div>
            }
        </Paper>
    );
};

export default ActivityParticipants;