import React from 'react';
import '../../../App.css';
import SideBar from 'components/layout/sidebar/Sidebar';
import DashboardNavBar from 'components/layout/DashboardNavBar';
import Users from './Users';

const CallUsers = () => {
    return (
        <div>
            <DashboardNavBar />
            <SideBar />
            <Users />
        </div>
    );
};
export default CallUsers;
