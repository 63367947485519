import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Paper from "@material-ui/core/Paper";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ErrorIcon from '@material-ui/icons/Error';

import { useStyles } from "components/layout/CommonStyles";
import Footer from "components/layout/Footer";
import Breadcrumb from "components/layout/breadcrumb/Breadcrumb";
import Transition from "components/layout/transition/Transition";
import VacationsContext from "context/hrModule/vacations/vacationsContext";
import VacationCalendarTabPanel from "./VacationCalendarTabPanel";
/*
 * 
 * This screen gets a vacation calendar from the API
 * It receives an object structured as follows
 * 
 *      vacationCalendar{
 *          numerOfYears: int       length of years[]
 *          years: []               array of years with pendencies
 *      
 * }
 * 
 * each element in vacationCalendar.years is
 *      years[pos x] is {
 *          year: int               The year (ex: 2020)
 * 
 *          Status: string          The year status, being "UNDER_ACQUISITON"
 *                                  if all collaborators status are "UNDER_ACQUISTION",
 *                                  and "PENDING", otherwise.
 * 
 *          monthStatus: [12]       A array of strings that sets a status for each month,
 *                                  that can be "NO_ENTRIES", if no collaborator has right
 *                                   to enjoy vacation or be paid in this month;
 *                                  "NO_PENDENCIES" if all collaborators that have right
 *                                  to enjoy vacation this month already received; otherwise
 *                                  the status is "PENDING". This is used to show the 
 *                                  correct message  if a month table is empty.
 *                                                           
 *          rows: []                A list of rows, each rows refers to a vacation period
 *                                  of a refered user
 *      }
 *
 * each row in rows contain:
 * 
 *      id: long int
 *      name: string                Collaborator name
 *      userId: 34176451            Collaborator id
 * 
 *      dayToObtain: Date           Date in which the collaborator receives right to go on
 *                                  vacation (and receive his payment)
 *      
 *      daysToEnjoy: int            Number of days remaining for enjoying vacation
 *                                  max of 30 for CLT and 15 for intern and scholarship 
 *                                  students 
 * 
 *      daysToBePaid: int           Number of days remaining to be paid for a vacation period,
 *                                  max of 30 for CLT and none for intern and scholarship 
 *                                  students
 * 
 *      enjoyedPeriodStatus:        Can be "PENDING", "UNDER_ACQUISITION" or "ENJOYED"
 * 
 *      paidPeriodStatus:           Can be "PENDING", "UNDER_ACQUISITION" or "PAID" for CLT,
 *                                  "UNAVAILABE" for intern and scholarship students 
 *      ruleset: string             "CLT", "Estagiário" or "Bolsista" 
 * 
*/

const VacationCalendar = () => {
    const classes = useStyles();
    const [t] = useTranslation();

    const vacationsContext = useContext(VacationsContext);
    const [value, setValue] = useState(1);
    const [years, setYears] = useState([]);
    const [yearsStatus, setYearsStatus] = useState([]);
    const [tabYear, setTabYear] = useState(new Date().getFullYear());


    const {
        getVacationCalendar,
        vacationCalendar,
        loading,
        setLoading,
        setLoadingFalse,
    } = vacationsContext;

    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }

    async function fetchData() {
        await setLoading();
        await getVacationCalendar();
        await setLoadingFalse();
    }



    const handleChange = (event, newValue) => {
        setValue(newValue);
        setTabYear(years[newValue]);

    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const getYearsTabs = () => {

        var yearsList = [];
        var yearsStatusList = [];
        for (let i = 0; i < vacationCalendar.numberOfYears; i++) {
            yearsList.push(vacationCalendar.years[i].year);
            yearsStatusList.push(vacationCalendar.years[i].status);
        }

        setYears(yearsList);
        setYearsStatus(yearsStatusList);

    }

    useEffect(() => {
        if (Object.keys(vacationCalendar).length !== 0) {

            getYearsTabs();

            let thisYear = new Date().getFullYear();
            var tabIdx = -1;
            vacationCalendar.years.forEach((y, idx) => {
                if (y.year === thisYear) {
                    tabIdx = idx
                }
            });

            setValue(tabIdx);
        }

    }, [vacationCalendar])


    useEffect(() => {

        fetchData();
    }, []);







    return (
        <React.Fragment>
            <Transition loading={loading} newDesign={true}>
                <div style={{ opacity: 1, display: 'flex', flexDirection: 'column', justifyContent: 'spaceBetween', minHeight: 'calc(100vh - 70px)' }}>
                    <div className="headerNewDesign">
                        <Breadcrumb
                            newDesign={true}
                            routes={[
                                { label: t("HR.humanResource") , to: "/HumanResources"},
                                { label: t("HR.vacations") },
                                { label: t("HR.vacationCalendar") },
                            ]}
                        />
                        <div className="container-headerNewDesign">
                            <h1 className={classes.titleNewDesign}>{t("HR.vacationCalendar")}</h1>
                        </div>

                    </div>
                    <div className="containerNewDesign">
                        <Paper style={{ padding: "8px" }}>
                            <AppBar position="static" style={{ boxShadow: "none", transform: "none" }}>
                                <Tabs selectionFollowsFocus style={{ backgroundColor: "#ffffff", color: "black", padding: "10px 10px 20px 10px" }} indicatorColor="primary" value={value} onChange={handleChange} >
                                    {years.map((year, idx) =>
                                        <Tab
                                            className={classes.vacationCalendarTab}
                                            style={{ display: 'flex', }}
                                            iconPosition="end"
                                            label={
                                                <div style={{ display: "flex", }}>
                                                    <b>{year}</b>
                                                    {yearsStatus[idx] == "PENDING" ?
                                                        <ErrorIcon style={{ marginLeft: 5, color: "#FFD200" }} /> :
                                                        <AccessTimeIcon style={{ marginLeft: 5 }} />

                                                    }
                                                </div>
                                            }
                                            {...a11yProps(idx)}
                                        />

                                    )}
                                </Tabs>
                            </AppBar>
                            {Object.keys(vacationCalendar).length !== 0 &&
                                <VacationCalendarTabPanel
                                    vacationCalendar={vacationCalendar}
                                    value={value}
                                    tabYear={tabYear}
                                    year={tabYear}
                                    setYearsStatus={setYearsStatus}
                                    yearIdx={value}
                                />
                            }
                        </Paper>
                    </div>
                </div>

                <Footer />
            </Transition>

        </React.Fragment >
    )


}

export default VacationCalendar;