import React from "react";
import "../../App.css";
import SideBar from "../layout/sidebar/Sidebar";
import DashboardNavBar from "../layout/DashboardNavBar";
import Edit from "./Edit";

const EditUser = ({ match }) => {
  return (
    <div>
      <DashboardNavBar />
      <SideBar />
      <Edit id={match.params.id}/>
    </div>
  );
}

export default EditUser;