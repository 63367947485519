export const libraryData = [
    {
        screen: 'BOOKS_COLLECTION',
        allowRead: false
    },
    {
        screen: 'LIBRARY_CONFIGURATION',
        allowRead: false,
        allowEdit: false
    },
    {
        screen: 'LIBRARY_REQUISITIONS_GENERAL',
        allowRead: false
    },
    {
        screen: 'LIBRARY_REQUISITIONS_ALL',
        allowRead: false
    },
    {
        screen: 'LIBRARY_MY_LOANS',
        allowRead: false
    },
    {
        screen: 'LIBRARY_LOANS',
        allowRead: false,
        allowEdit: false
    },
    {
        screen: 'FINES',
        allowRead: false
    }
];
