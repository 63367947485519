/* eslint-disable */
import DateFnsUtils from "@date-io/date-fns";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Switch from '@material-ui/core/Switch';
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GetAppIcon from '@material-ui/icons/GetApp';
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import axios from "axios";
import Footer from "components/layout/Footer";
import Spinner from "components/layout/spinners/Index";
import AllowancesContext from "context/allowance/allowanceContext";
import InventoryContext from "context/inventory/inventoryContext";
import ProjectsContext from "context/projects/projectsContext";
import UsersContext from "context/users/usersContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import history from "../../../../history";
import { useStyles } from "components/layout/CommonStyles";
import MySnackbarContentWrapper from "components/layout/Message";
import DialogStatus from "./../DialogStatus";
import Maintenance from "./../EditEquipment/Maintenance";
import MaintenanceTable from "./../EditEquipment/MaintenanceTable";
import SubItensTable from "./../SubItensTable";
import InputDragDrop from "components/layout/inputDragDrop/InputDragDrop";
import Cookies from "js-cookie";

const tooltipTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#2c3e51"
        }
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                color: "white",
                fontSize: "1em",
                //backgroundColor: "red",
                opacity: "0.7"
            }
        }
    }
});

const ViewEquipment = props => {
    const fileDownload = require("js-file-download");
    const { idItem } = props;
    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }
    const classes = useStyles();
    const [t] = useTranslation();
    const inventoryContext = useContext(InventoryContext);
    const allowanceContext = useContext(AllowancesContext);
    const projectsContext = useContext(ProjectsContext);
    const usersContext = useContext(UsersContext);
    const [placesArray, setPlacesArray] = useState([]);
    const [categoriesArray, setCategoriesArray] = useState([]);
    const [projectsArray, setProjectsArray] = useState([]);
    const [usersArray, setUsersArray] = useState([]);
    const [equipmentArray, setEquipmentArray] = useState([]);
    const [checkedC, setCheckedC] = useState(true);
    const [hasUpdatedFile, setHasUpdatedFile] = useState(false);
    const [selectedValue, setSelectedValue] = React.useState('a');
    const [openStatus, setOpenStatus] = React.useState(false);
    const [openSubItem, setOpenSubItem] = React.useState(false);
    const [openMaintenance, setOpenMaintenance] = React.useState(false);
    const [amountEquipments, setAmountEquipments] = React.useState(2);
    const [mustRedirect, setMustRedirect] = useState(false);
    const [viewMore, setViewMore] = React.useState(false);

    const [snackBar, setSnackBar] = useState({
        open: false,
        kind: "",
        content: ""
    });

    const [dataState, setDataState] = useState({
        files: [],
        rejectedFiles: []
    });

    const [values, setValues] = useState({
        id: 0,
        equipmentImageId: 0,
        equipmentImage: "",
        acquisitionDate: new Date(),
        brand: "",
        categoryId: "",
        observation: "",
        description: "",
        observation: "",
        fcpcCode: "",
        invoice: "",
        formatDate: "",
        name: "",
        placeId: "",
        projectId: "",
        responsibleId: "",
        responsibleExternalId: "",
        serialNumber: "",
        ufcCode: "",
        value: "",
        externCode: "",
        lsbdCode: "",
        warrantyDate: new Date(),
        statusDescription: "",
        statusName: "",
        class: "Inventory.Item",
        currentStatus: "-",
    });
    const [requiredName, setRequiredName] = useState(false);
    const [requiredProject, setRequiredProject] = useState(false);
    const [requiredDateAcquisition, setRequiredDateAcquisition] = useState(false);
    const [requiredDateWarranty, setRequiredDateWarranty] = useState(false);
    const [fcpcDuplicateCode, setFcpcDuplicateCode] = useState(false);
    const [ufcDuplicateCode, setUfcDuplicateCode] = useState(false);
    const [lsbdDuplicateCode, setLsbdDuplicateCode] = useState(false);
    const [externDuplicateCode, setExternDuplicateCode] = useState(false);

    const [requiredSN, setRequiredSN] = useState(false);
    const [requiredPlace, setRequiredPlace] = useState(false);
    const [requiredResponsible, setRequiredResponsible] = useState(false);
    const [requiredStatus, setRequiredStatus] = useState(false);
    const [requiredCategory, setRequiredCategory] = useState(false);
    const [files, setFiles] = useState([]);

    const {
        getAllEquipment,
        equipment,
        loading,
        loadingEquipment,
        setLoadingEquipment,
        setLoadingEquipmentFalse,
        response,
        responseType,
        getPlaces,
        places,
        getItensCategories,
        itensCategories,
        getAllStatus,
        allStatus,
        arraySubItens,
        addSubItensArray,
        getEquipment,
        equipmentItem,
        maintenances,
        getMaintenanceForItem,
        cleanArraySubItem,
        getInventoryImage,
        inventoryImage
    } = inventoryContext;

    const {
        allowanceResponse,
        allowanceResponseType,
        resetAllowanceMessage,
    } = allowanceContext;

    const {
        getProjects,
        projects
    } = projectsContext;

    const {
        getUsers,
        users
    } = usersContext;

    async function fetchData(idItem) {
        await setLoadingEquipment();
        getAllEquipment();
        getPlaces();
        getItensCategories();
        getProjects();
        getUsers();
        getAllStatus();
        await getInventoryImage(idItem);
        await getMaintenanceForItem(idItem);
        await getEquipment(idItem);
        await setLoadingEquipmentFalse();
    }

    async function fetchEquipment(idItem) {
        getEquipment(idItem);
        cleanArraySubItem();
    }


    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData(idItem);
    }, []);

    useEffect(() => {

        let acquisitionDateFormated = equipmentItem.acquisitionDate ? new Date(equipmentItem.acquisitionDate) : null;
        acquisitionDateFormated && acquisitionDateFormated.setMinutes(acquisitionDateFormated.getMinutes() + acquisitionDateFormated.getTimezoneOffset());
        let warrantyDateFormated = equipmentItem.warrantyData ? new Date(equipmentItem.warrantyData) : null;
        warrantyDateFormated && warrantyDateFormated.setMinutes(warrantyDateFormated.getMinutes() + warrantyDateFormated.getTimezoneOffset());
        setValues({
            ...values,
            id: parseInt(idItem),
            acquisitionDate: acquisitionDateFormated,
            brand: equipmentItem.brand ? equipmentItem.brand : "",
            categoryId: equipmentItem.categoryId ? equipmentItem.categoryId : "",
            description: equipmentItem.detailedDescription ? equipmentItem.detailedDescription : "-",
            observation: equipmentItem.description ? equipmentItem.description : "-",
            fcpcCode: equipmentItem.fcpcCode ? "FCPC-" + equipmentItem.fcpcCode : "-",
            invoice: equipmentItem.invoice ? equipmentItem.invoice : "",
            itemStatusId: equipmentItem.itemStatusId ? equipmentItem.itemStatusId : "",
            name: equipmentItem.name ? equipmentItem.name : "",
            placeId: equipmentItem.placeId ? equipmentItem.placeId : "",
            projectId: equipmentItem.projectId ? equipmentItem.projectId : "",
            responsible: equipmentItem.responsible ? equipmentItem.responsible : "",
            responsibleId: equipmentItem.responsibleId ? equipmentItem.responsibleId : "",
            responsibleExternalId: equipmentItem.responsibleExternalId ? equipmentItem.responsibleExternalId : "",
            serialNumber: equipmentItem.serialNumber ? equipmentItem.serialNumber : "-",
            ufcCode: equipmentItem.ufcCode ? "UFC-" + equipmentItem.ufcCode : "-",
            value: equipmentItem.value ? equipmentItem.value : "",
            externCode: equipmentItem.externCode ? equipmentItem.externPartner + "-" + equipmentItem.externCode : "-",
            lsbdCode: equipmentItem.lsbdCode ? "LSBD-" + equipmentItem.lsbdCode : "-",
            warrantyDate: warrantyDateFormated,
            statusDescription: equipmentItem.statusDescription ? equipmentItem.statusDescription : "",
            statusName: equipmentItem.itemStatus ? equipmentItem.itemStatus : "",
            currentStatus: equipmentItem.itemStatus ? equipmentItem.itemStatus : "-",
        });

        setDataState({
            ...dataState,
            files: equipmentItem.equipmentFiles ? equipmentItem.equipmentFiles : "",
            //  rejectedFiles: dataState.rejectedFiles.concat(existentFiles)
        });

        // return () => { };
    }, [equipmentItem, equipmentItem.length]);

    useEffect(() => {
        setValues({
            ...values,
            equipmentImageId: inventoryImage[0] ? inventoryImage[0].id : 0,
            equipmentImage: inventoryImage[0] ? inventoryImage[0].imagePath + "/" + inventoryImage[0].imageName : "",
        });
    }, [inventoryImage]);

    const transitions = useTransition(loadingEquipment, null, {
        from: { opacity: 0 },
        enter: {
            opacity: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "calc(100vh - 70px)"
        },
        leave: { opacity: 0 }
    });

    var subitensFilter = [];

    const sortElement = (elemnts) => {
        elemnts.sort(function (a, b) {
            if (a.name === null) a.name = "";
            if (b.name === null) b.name = "";

            if (a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() >
                b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim()) {
                return 1;
            }
            if (a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() <
                b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim()) {
                return -1;
            }
            // a must be equal to b
            return 0;
        });
    }

    useEffect(() => {
        const myPlaces = [...places];
        sortElement(myPlaces);

        var aux = {};
        var _places = [];
        for (var i = 0; i < myPlaces.length; i++) {
            var item = myPlaces[i];
            aux.value = item.id;
            aux.label = item.name;
            _places.push(aux);
            aux = {};
        }
        setPlacesArray(_places);
    }, [places]);

    useEffect(() => {
        const categories = [...itensCategories];
        sortElement(categories);

        var aux = {};
        var _categories = [];
        for (var i = 0; i < categories.length; i++) {
            var item = categories[i];
            aux.value = item.id;
            aux.label = item.name;
            _categories.push(aux);
            aux = {};
        }
        setCategoriesArray(_categories);
    }, [itensCategories]);

    useEffect(() => {
        const allProjects = [...projects];
        sortElement(allProjects);

        var aux = {};
        var _projects = [];
        for (var i = 0; i < allProjects.length; i++) {
            var item = allProjects[i];
            aux.value = item.idInformation;
            aux.label = item.name;
            _projects.push(aux);
            aux = {};
        }
        setProjectsArray(_projects);
    }, [projects]);

    useEffect(() => {
        const allUsers = [...users];
        sortElement(allUsers);

        var aux = {};
        var _users = [];
        for (var i = 0; i < allUsers.length; i++) {
            var item = allUsers[i];
            aux.value = item.id;
            aux.label = item.name;
            _users.push(aux);
            aux = {};
        }
        setUsersArray(_users);
    }, [users]);


    function getCodeList(row) {
        const response = [];

        if (row["lsbdCode"])
            response.push(row["lsbdCode"]);
        if (row["fcpcCode"])
            response.push("FCPC-" + row["fcpcCode"]);
        if (row["ufcCode"])
            response.push("UFC-" + row["ufcCode"]);
        if (row["serialNumber"])
            response.push("Serial-" + row["serialNumber"]);

        return response;
    }

    useEffect(() => {
        const equipments = [...equipment];
        sortElement(equipments);

        var aux = {};
        var _equipment = [];
        for (var i = 0; i < equipments.length; i++) {
            var item = equipments[i];
            aux.value = item.id;
            aux.categoryId = item.categoryId;
            aux.fcpcCode = item.fcpcCode;
            aux.lsbdCode = item.lsbdCode;
            aux.ufcCode = item.ufcCode;
            aux.place = item.place;
            aux.responsible = item.responsible;
            aux.label = item.name + (getCodeList(item)[0] ? " - Código: " + getCodeList(item)[0] : " - Sem código")//" - Código: "+ item.fcpcCode;
            _equipment.push(aux);
            aux = {};
        }
        setEquipmentArray(_equipment);
    }, [equipment]);

    const handleSnackClose = () => {
        setSnackBar({ ...snackBar, open: false });
        resetAllowanceMessage();
    };

    const onDrop = acceptedFiles => {
        if (acceptedFiles[0] !== undefined) {
            setHasUpdatedFile(true);
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    };

    function humanFileSize(size) {
        let i = Math.floor(Math.log(size) / Math.log(1024));
        return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    };

    const image = files.map(file => (
        <div className={classes.thumb} key={file.name}>
            <div className={classes.thumbInner}>
                <img
                    src={file.preview}
                    className={classes.imgNewEquipment}
                />
            </div>
        </div>
    ));

    const handleChange = (name, value) => {
        setValues({ ...values, [name]: value });
    };

    const formatDate = (date) => {
        var formatedDate = new Date(date);
        return `${formatedDate.getFullYear()}-${formatedDate.getMonth() + 1}-${formatedDate.getDate()} ${formatedDate.getUTCHours()}:${formatedDate.getMinutes()}:${formatedDate.getSeconds()}`;
    }

    {
        subitensFilter = equipment.filter(
            item =>
                arraySubItens && arraySubItens.includes(item.id) || item.parentId && item.parentId == idItem)
    }

    const redirect = go => {
        if (go) return <Redirect to={`/InventoryEquipment`} />;
    };

    const handleFieldChangeValue = (name, value) => {
        if (value !== " " && !value.includes(" ")) {
            const str = value.split("");
            let aux = str.filter(el => el !== "." && el !== ",").join("");
            let valueFormatted = value;
            if (value) {
                valueFormatted = converTotBrCurrency(
                    aux
                        .split("")
                        .reverse()
                        .join("")
                );
            }
            aux = valueFormatted
                .split("")
                .filter(el => el !== ".")
                .join("")
                .replace(",", ".");
            if (!isNaN(aux)) {
                setValues({ ...values, [name]: valueFormatted });
            }
        }
    };

    const converTotBrCurrency = number => {
        var temp = "";
        var len = number.length;
        var count = 0;

        for (var i = 0; i < len; i++) {
            if (i === 1) {
                var aux = "," + number[i] + temp;
                temp = aux;
            } else {
                if (count === 3) {
                    count = 1;
                    var aux = number[i] + "." + temp;
                    temp = aux;
                } else {
                    if (i > 1) {
                        count += 1;
                    }
                    var aux = number[i] + temp;
                    temp = aux;
                }
            }
        }

        return temp;
    };

    const onClickDownload = async (file) => {
        if (dataState && dataState.files.length > 0) {
            const noSlashPath = file.path.replace(/\//g, ";");
            const url = process.env.REACT_APP_SERVER + "api/file/" + noSlashPath;
            axios.get(url, {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
                },
                responseType: "blob"
            })
                .then(function (res) {
                    const blob = new Blob([res.data], {});
                    const name = file.fileName ? file.fileName : file.name;
                    fileDownload(blob, name);
                });
        }
    };

    const ViewMoreFunction = () => {
        return (
            <Button style={{ flex: "auto" }} onClick={() => setViewMore(!viewMore)}>{viewMore ? "Mostrar Menos" : "Mostrar Mais"}
                <IconButton
                    style={{ float: "right" }}
                    className={classes.removerHover}

                >
                    {viewMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </Button>
        )
    }

    const CallHistoryEquipment = () => {
        axios.get(process.env.REACT_APP_SERVER + `api/InventoryItem/${idItem}`, {
            headers: {
                "Content-type": "application/json",
                "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
            }
        })
            .then(res => {
                if (res.statusText === "OK") {
                    history.push(`/InventoryEquipment/History/${idItem}`);
                } else {
                    history.push(`/InventoryEquipment`);
                }
            })
            .catch(error => {
                history.push(`/InventoryEquipment`);
            });
    }

    return transitions.map(({ item, key, props }) =>
        item ? (
            <animated.div key={key} style={props}>
                <Spinner />;
            </animated.div>
        ) : (
            <animated.div key={key} style={props}>
                {redirect(mustRedirect)}
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={snackBar.open}
                    onClose={handleSnackClose}
                    TransitionComponent={Slide}
                    transitionDuration={{ enter: 500, exit: 500 }}
                    ContentProps={{
                        "aria-describedby": "message-id"
                    }}
                    autoHideDuration={2000}
                >
                    <MySnackbarContentWrapper
                        onClose={handleSnackClose}
                        variant={
                            responseType !== ""
                                ? responseType.toLowerCase()
                                : allowanceResponseType.toLowerCase()
                        }
                        message={
                            <span id="message-id">
                                {response !== "" ? response : allowanceResponse}
                            </span>
                        }
                    />
                </Snackbar>
                <div className="container">
                    <Breadcrumbs
                        className={classes.breadCrumbs}
                        aria-label="breadcrumb"
                        separator={<NavigateNextIcon fontSize="small" />}
                    >
                        <Typography>{t("INVENTORY.inventory")}</Typography>
                        <Link className={classes.link} to="/InventoryEquipment">
                            <Typography>{t("INVENTORY.equipments")}</Typography>
                        </Link>
                        <Typography>{t("INVENTORY.viewEquipment")}</Typography>
                    </Breadcrumbs>
                    <div className="container-header">
                        <div className="title">
                            <h1 className={classes.title}>{t("INVENTORY.viewEquipment")}</h1>
                        </div>
                        <Button
                            className={classes.blueButton}
                            variant="contained"
                            color="primary"
                            onClick={CallHistoryEquipment}
                        >
                            {t("INVENTORY.viewHistoryItem")}
                        </Button>
                    </div>
                    <Paper >
                        <div className="cabecalho">
                            {t("INVENTORY.equipmentDatas").toUpperCase()}
                        </div>
                        <div className={classes.bodyNewEquipment}>
                            <Grid container spacing={3}>
                                <Grid item xs={2} className={classes.backgroundImage}>
                                    <Grid item xs={2}>
                                        <InputDragDrop
                                            disabled={true}
                                            files={files}
                                            onDrop={onDrop}
                                            hasUpdatedFile={hasUpdatedFile}
                                            imagePath={values.equipmentImage}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={7}>
                                    <Typography className={classes.titleNewEquipment}>
                                        Definição
                                    </Typography>
                                    <MuiThemeProvider theme={tooltipTheme}>
                                        <Tooltip
                                            TransitionComponent={Zoom}
                                            open={requiredName}
                                            title={t("GENERAL.requiredField")}
                                        >
                                            <TextField
                                                disabled
                                                className={classes.textField}
                                                label={t("INVENTORY.nameOfItem") + "*"}
                                                value={values.name || ""}
                                                onChange={(ev) => {
                                                    if (ev.target.value.length <= 200) {
                                                        setRequiredName(false)
                                                        handleChange("name", ev.target.value);
                                                    }
                                                }}
                                                margin="normal"
                                            />
                                        </Tooltip>
                                    </MuiThemeProvider>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                disabled
                                                className={classes.textField}
                                                label={t("INVENTORY.brand")}
                                                value={values.brand || ""}
                                                onChange={(ev) => { if (ev.target.value.length <= 50) handleChange("brand", ev.target.value) }}
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <MuiThemeProvider theme={tooltipTheme}>
                                                <Tooltip
                                                    TransitionComponent={Zoom}
                                                    open={requiredCategory}
                                                    title={t("GENERAL.requiredField")}
                                                >
                                                    <TextField
                                                        disabled
                                                        select
                                                        id="client"
                                                        label={t("INVENTORY.category")}
                                                        name="category"
                                                        value={values.categoryId || ""}
                                                        onChange={(ev) => {
                                                            setRequiredCategory(false)
                                                            handleChange("categoryId", ev.target.value)
                                                        }}
                                                        className={classes.textField}
                                                        margin="normal"
                                                        classes={{
                                                            root: classes.inputRoot,
                                                            input: classes.inputInput
                                                        }}
                                                        inputProps={{ "aria-label": "search" }}
                                                    >
                                                        {categoriesArray.map(category => (
                                                            <MenuItem key={category.value} value={category.value || ""}>
                                                                {category.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Tooltip>
                                            </MuiThemeProvider>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {selectedValue !== "b" &&
                                    <Grid item xs={12} sm={3}>
                                        Customização
                                        <Grid component="label" container alignItems="center" spacing={1}>
                                            <Grid item>
                                                <Switch
                                                    disabled
                                                    color="primary"
                                                    checked={checkedC}
                                                    onChange={() => setCheckedC(!checkedC)}
                                                    name="checkedC"
                                                />
                                            </Grid>
                                            <Grid item>Customizável</Grid>
                                        </Grid>
                                    </Grid>
                                }
                                {!viewMore && ViewMoreFunction()}
                                {viewMore &&
                                    <React.Fragment>
                                        {selectedValue !== "b" &&
                                            <React.Fragment>
                                                <Grid item xs={12} sm={12} style={{ paddingBottom: 0 }}>
                                                    <Typography className={classes.titleNewEquipment}>
                                                        Identificação
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={2}>
                                                    <MuiThemeProvider theme={tooltipTheme}>
                                                        <Tooltip
                                                            TransitionComponent={Zoom}
                                                            open={fcpcDuplicateCode}
                                                            title={t("INVENTORY.duplicateCode")}
                                                        >
                                                            <TextField
                                                                disabled
                                                                className={classes.textField}
                                                                label={t("INVENTORY.fcpcCode")}
                                                                value={values.fcpcCode.slice(-1) === "D" ? values.fcpcCode.slice(0, -1) || "" : values.fcpcCode || ""}
                                                                onMouseEnter={(ev) => {
                                                                    if (values.fcpcCode.slice(-1) === "D") {
                                                                        setFcpcDuplicateCode(true);
                                                                    }
                                                                }}
                                                                onMouseOut={(ev) => {
                                                                    setFcpcDuplicateCode(false)
                                                                }}
                                                                margin="normal"
                                                            />
                                                        </Tooltip>
                                                    </MuiThemeProvider>
                                                </Grid>
                                                <Grid item xs={12} sm={2}>
                                                    <MuiThemeProvider theme={tooltipTheme}>
                                                        <Tooltip
                                                            TransitionComponent={Zoom}
                                                            open={externDuplicateCode}
                                                            title={t("INVENTORY.duplicateCode")}
                                                        >
                                                            <TextField
                                                                disabled
                                                                className={classes.textField}
                                                                label={t("INVENTORY.externalCode")}
                                                                value={values.externCode.slice(-1) === "D" ? values.externCode.slice(0, -1) || "" : values.externCode || ""}
                                                                onMouseEnter={(ev) => {
                                                                    if (values.externCode.slice(-1) === "D") {
                                                                        setExternDuplicateCode(true);
                                                                    }
                                                                }}
                                                                onMouseOut={(ev) => {
                                                                    setExternDuplicateCode(false)
                                                                }}
                                                                margin="normal"
                                                            />
                                                        </Tooltip>
                                                    </MuiThemeProvider>
                                                </Grid>
                                                <Grid item xs={12} sm={2}>
                                                    <MuiThemeProvider theme={tooltipTheme}>
                                                        <Tooltip
                                                            TransitionComponent={Zoom}
                                                            open={ufcDuplicateCode}
                                                            title={t("INVENTORY.duplicateCode")}
                                                        >
                                                            <TextField
                                                                disabled
                                                                className={classes.textField}
                                                                label={t("INVENTORY.ufcCode")}
                                                                value={values.ufcCode.slice(-1) === "D" ? values.ufcCode.slice(0, -1) || "" : values.ufcCode || ""}
                                                                onMouseEnter={(ev) => {
                                                                    if (values.ufcCode.slice(-1) === "D") {
                                                                        setUfcDuplicateCode(true);
                                                                    }
                                                                }}
                                                                onMouseOut={(ev) => {
                                                                    setUfcDuplicateCode(false)
                                                                }}
                                                                margin="normal"
                                                            />
                                                        </Tooltip>
                                                    </MuiThemeProvider>
                                                </Grid>
                                                <Grid item xs={12} sm={2}>
                                                    <MuiThemeProvider theme={tooltipTheme}>
                                                        <Tooltip
                                                            TransitionComponent={Zoom}
                                                            open={lsbdDuplicateCode}
                                                            title={t("INVENTORY.duplicateCode")}
                                                        >
                                                            <TextField
                                                                disabled
                                                                className={classes.textField}
                                                                label={t("INVENTORY.lsbdCode")}
                                                                value={values.lsbdCode.slice(-1) === "D" ? values.lsbdCode.slice(0, -1) || "" : values.lsbdCode || ""}
                                                                onMouseLeave={(ev) => {
                                                                    if (values.lsbdCode.slice(-1) === "D") {
                                                                        setLsbdDuplicateCode(true);
                                                                    }
                                                                }}
                                                                onMouseOut={(ev) => {
                                                                    setLsbdDuplicateCode(false)
                                                                }}
                                                                margin="normal"
                                                            />
                                                        </Tooltip>
                                                    </MuiThemeProvider>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <MuiThemeProvider theme={tooltipTheme}>
                                                        <Tooltip
                                                            TransitionComponent={Zoom}
                                                            open={requiredSN}
                                                            title={t("GENERAL.requiredField")}
                                                        >
                                                            <TextField
                                                                disabled
                                                                className={classes.textField}
                                                                label={t("INVENTORY.serialNumber")}
                                                                value={values.serialNumber || ""}
                                                                margin="normal"
                                                            />
                                                        </Tooltip>
                                                    </MuiThemeProvider>
                                                </Grid>
                                            </React.Fragment>
                                        }
                                        <Grid item xs={12} sm={12}>
                                            <Typography className={classes.titleNewEquipment}>
                                                Administração
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <MuiThemeProvider theme={tooltipTheme}>
                                                <Tooltip
                                                    TransitionComponent={Zoom}
                                                    open={requiredProject}
                                                    title={t("GENERAL.requiredField")}
                                                >
                                                    <TextField
                                                        disabled
                                                        select
                                                        id="client"
                                                        label={t("INVENTORY.project") + "*"}
                                                        name="projectId"
                                                        className={classes.textField}
                                                        value={values.projectId || ""}
                                                        onChange={(ev) => {
                                                            setRequiredProject(false);
                                                            handleChange("projectId", ev.target.value);
                                                        }}
                                                        margin="normal"
                                                        classes={{
                                                            root: classes.inputRoot,
                                                            input: classes.inputInput
                                                        }}
                                                        inputProps={{ "aria-label": "search" }}
                                                    >
                                                        {projectsArray.map(project => (
                                                            <MenuItem key={project.value} value={project.value || ""}>
                                                                {project.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Tooltip>
                                            </MuiThemeProvider>
                                        </Grid>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <Grid item xs={3}>
                                                <MuiThemeProvider theme={tooltipTheme}>
                                                    <Tooltip
                                                        TransitionComponent={Zoom}
                                                        open={requiredDateAcquisition}
                                                        title={t("GENERAL.requiredField")}
                                                    >
                                                        <KeyboardDatePicker
                                                            disabled
                                                            className={classes.textField}
                                                            disableToolbar
                                                            //maxDate={dates.EndDate}
                                                            autoOk={true}
                                                            variant="inline"
                                                            format="dd/MM/yyyy"
                                                            margin="normal"
                                                            id="acquisitionDate"
                                                            label={t("INVENTORY.dateAcquisition") + "*"}
                                                            value={values.acquisitionDate}
                                                            onChange={(ev) => {
                                                                setRequiredDateAcquisition(false);
                                                                handleChange("acquisitionDate", ev);
                                                            }}
                                                            // onFocus={clearRequiredMessage}
                                                            minDateMessage={
                                                                "A data não deve ser anterior à data mínima"
                                                            }
                                                            // onChange={handleDates("phaseStartDate")}
                                                            KeyboardButtonProps={{
                                                                "aria-label": "change date"
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </MuiThemeProvider>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <MuiThemeProvider theme={tooltipTheme}>
                                                    <Tooltip
                                                        TransitionComponent={Zoom}
                                                        open={requiredDateWarranty}
                                                        title={t("GENERAL.requiredField")}
                                                    >
                                                        <KeyboardDatePicker
                                                            disabled
                                                            className={classes.textField}
                                                            disableToolbar
                                                            autoOk={true}
                                                            // minDate={dates.phaseStartDate}
                                                            variant="inline"
                                                            format="dd/MM/yyyy"
                                                            margin="normal"
                                                            id="warrantyDate"
                                                            label={t("INVENTORY.warrantyData")}
                                                            value={values.warrantyDate}
                                                            //onFocus={clearRequiredMessage}
                                                            onChange={(ev) => {
                                                                setRequiredDateWarranty(false);
                                                                handleChange("warrantyDate", ev);
                                                            }}
                                                            KeyboardButtonProps={{
                                                                "aria-label": "change date"
                                                            }}
                                                            minDateMessage={
                                                                "A data não deve ser anterior à data mínima"
                                                            }
                                                        />
                                                    </Tooltip>
                                                </MuiThemeProvider>
                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                disabled
                                                className={classes.textField}
                                                label={t("INVENTORY.invoice")}
                                                value={values.invoice || ""}
                                                onChange={(ev) => { if (ev.target.value.length <= 50) handleChange("invoice", ev.target.value) }}
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                disabled
                                                className={classes.textField}
                                                label={t("INVENTORY.value")}
                                                value={values.value || ""}
                                                onChange={(ev) => { if (ev.target.value.length <= 20) handleFieldChangeValue("value", ev.target.value) }}
                                                margin="normal"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            R$
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Typography className={classes.titleNewEquipment}>
                                                Alocação
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <MuiThemeProvider theme={tooltipTheme}>
                                                <Tooltip
                                                    TransitionComponent={Zoom}
                                                    open={requiredPlace}
                                                    title={t("GENERAL.requiredField")}
                                                >
                                                    <TextField
                                                        disabled
                                                        select
                                                        id="client"
                                                        label={t("INVENTORY.place") + "*"}
                                                        name="Place"
                                                        className={classes.textField}
                                                        value={values.placeId || ""}
                                                        onChange={(ev) => {
                                                            setRequiredPlace(false);
                                                            handleChange("placeId", ev.target.value)
                                                        }}
                                                        margin="normal"
                                                        classes={{
                                                            root: classes.inputRoot,
                                                            input: classes.inputInput
                                                        }}
                                                        inputProps={{ "aria-label": "search" }}
                                                    >
                                                        {placesArray.map(places => (
                                                            <MenuItem key={places.value} value={places.value || ""}>
                                                                {places.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Tooltip>
                                            </MuiThemeProvider>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <MuiThemeProvider theme={tooltipTheme}>

                                                <TextField
                                                    disabled
                                                    id="clientView"
                                                    variant="outlined"
                                                    label={t("INVENTORY.responsible")}
                                                    name="responsible"
                                                    className={classes.textField}
                                                    value={values.responsible || ""}
                                                    margin="normal"
                                                    classes={{
                                                        root: classes.inputRoot,
                                                        input: classes.inputInput
                                                    }}
                                                    inputProps={{ "aria-label": "search" }}
                                                >

                                                </TextField>
                                            </MuiThemeProvider>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <MuiThemeProvider theme={tooltipTheme}>
                                                <Tooltip
                                                    TransitionComponent={Zoom}
                                                    open={requiredStatus}
                                                    title={t("GENERAL.requiredField")}
                                                >
                                                    <TextField
                                                        disabled
                                                        variant="outlined"
                                                        id="statusId"
                                                        label={t("INVENTORY.status")}
                                                        name="statusId"
                                                        className={classes.textField}
                                                        value={values.statusName || ""}
                                                        margin="normal"
                                                        classes={{
                                                            root: classes.inputRoot,
                                                            input: classes.inputInput
                                                        }}
                                                        inputProps={{ "aria-label": "search" }}
                                                    />
                                                </Tooltip>
                                            </MuiThemeProvider>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                disabled
                                                multiline
                                                fullWidth
                                                variant="outlined"
                                                rows="6"
                                                id="description"
                                                value={values.description || ""}
                                                onChange={(ev) => { if (ev.target.value.length <= 500) handleChange("description", ev.target.value) }}
                                                label={t("INVENTORY.descriptionBookCategory")}
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                disabled
                                                multiline
                                                fullWidth
                                                variant="outlined"
                                                rows="6"
                                                id="description"
                                                value={values.observation || ""}
                                                onChange={(ev) => { if (ev.target.value.length <= 500) handleChange("observation", ev.target.value) }}
                                                label={t("INVENTORY.observation")}
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid container item spacing={1} md={12} lg={12}>
                                            <Grid item xs={12}>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant={"body1"} style={{ fontWeight: "bold" }}>Documentos</Typography>
                                                {dataState.rejectedFiles.length > 0 &&
                                                    <div style={{ paddingBottom: 8 }}>
                                                        {dataState.rejectedFiles.map(message => (
                                                            <div className={classes.dialogFile}>
                                                                <div className={classes.textFile}>
                                                                    <Typography style={{ color: "red" }} className={classes.nameFile}>{message}</Typography>
                                                                </div>
                                                                <div className={classes.iconsFile}>
                                                                    <IconButton onClick={() => deleteRejectedFile(message)}>
                                                                        <Clear />
                                                                    </IconButton>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                }
                                                {dataState.files.length > 0 ?
                                                    <div className={classes.dropzoneDialogFiles}>
                                                        {dataState.files.map(file => (
                                                            <div className={classes.dialogFile}>
                                                                <div className={classes.textFile}>
                                                                    <Typography className={classes.nameFile}>{file.fileName ? file.fileName : file.name}</Typography>
                                                                    <Typography>{`(${humanFileSize(file.size)})`}</Typography>
                                                                </div>
                                                                <div className={classes.iconsFile}>
                                                                    {file.fileName && <IconButton onClick={() => onClickDownload(file)}>
                                                                        <GetAppIcon />
                                                                    </IconButton>}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div> :
                                                    <div className={classes.noFilesMessage}>
                                                        <Typography variant={"h6"} style={{ fontWeight: "bold" }}>{t("EXPENSES.noFilesMessage")}</Typography>
                                                        <Typography variant={"caption"}>{t("EXPENSES.noFilesSubMessage")}</Typography>
                                                    </div>
                                                }
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>}
                                {viewMore && ViewMoreFunction()}
                            </Grid>
                        </div>
                    </Paper>
                    <br></br>
                    <Paper >
                        <div className="cabecalho">
                            {t("INVENTORY.maintenances").toUpperCase()}
                        </div>
                        {maintenances && maintenances.length > 0 ?
                            <MaintenanceTable
                                rows={maintenances}
                                headers={["startDate", "endDate", "description", "company", "value", "numberPhone", "invoice", "id"]}
                                visibleHeaders={["Data de Início", "Data de Término", "Descrição", "Empresa", "valor", ""]}
                                sortedFields={["startDate", "endDate", "description", "company", "value"]}
                                mainField="description"
                            />
                            :
                            <div className={classes.noFilesMessage}>
                                <Typography variant={"h6"} style={{ fontWeight: "bold" }}>{t("INVENTORY.noMaintenancesMessage")}</Typography>
                                <Typography variant={"caption"}>{t("INVENTORY.noMaintenancesSubMessage")}</Typography>
                            </div>
                        }
                    </Paper>
                    <br></br>
                    {checkedC && selectedValue !== "b" &&
                        <Paper >
                            <div className="cabecalho">
                                {t("INVENTORY.subItens").toUpperCase()}
                            </div>
                            {subitensFilter.length > 0 ?
                                <SubItensTable
                                    rows={subitensFilter}
                                    mode={"view"}
                                    headers={["id", "name", "responsible", "lsbdCode", "fcpcCode", "ufcCode", "place", "brand", "externalCode", "serialNumber", "parentId", "class", "acquisitionDate", "category", "fileUserExternal", "description", "value", "lastMaintenance", "warrantyData", "libraryExemplar", "project", "itemStatus"]}
                                    visibleHeaders={["nome", "localização", "responsável", "código de identificação", "projeto"]}
                                    sortedFields={["name", "place", "responsible", "ufcCode", "project"]}
                                    mainField="name"
                                    parentName={values.name}
                                />
                                :
                                <div className={classes.noFilesMessage}>
                                    <Typography variant={"h6"} style={{ fontWeight: "bold" }}>{t("INVENTORY.noSubitensMessage")}</Typography>
                                    <Typography variant={"caption"}>{t("INVENTORY.noSubitensSubMessage")}</Typography>
                                </div>
                            }
                        </Paper>
                    }
                </div>
                <DialogStatus
                    type={"View"}
                    open={openStatus}
                    setOpen={setOpenStatus}
                    currentValue={values.currentStatus}
                    allStatus={allStatus}
                    nameValue={values.statusName}
                    value={values.itemStatusId}
                    description={values.statusDescription}
                    handleUpdate={(id, name, description) => {
                        setValues({ ...values, itemStatusId: id, statusName: name, statusDescription: description })
                    }}
                />
                {/* <Maintenance open={openMaintenance} setOpen={setOpenMaintenance} addMaintenancesToArray={addMaintenancesToArray} itemId={idItem} /> */}
                <Footer />
            </animated.div >
        )
    );
};

export default ViewEquipment;