import React from 'react'
import { useTranslation } from "react-i18next";
import { DateToDayAndMonth } from "utils/dates/DateToDayAndMonth";
import { FormatDateToFront } from "utils/dates/FormatDateToFront";
import { Months } from 'global/constants'; 

import BirthdaysImg from "assets/Aniversariantes.png"

import {screenStyles} from "./Styles"

const BirthdaysToPrint = React.forwardRef((props, ref) => {
    const [t] = useTranslation();
    const styles = screenStyles();
    const { birthdays, month } = props

    return(
        <div ref={ref} className={styles.printableWrapper}>
            {birthdays && birthdays.length > 0 ? 
                birthdays.map(birthdayPerson => 
                    <div className={styles.printContainer}>
                        <img src={BirthdaysImg} alt={''} className={styles.printableImg}/>
                        <div className={styles.printableTextWrapper}>
                            <p className={styles.printableTitle}>{t("HR.birthdays")} <br/>{`${t("GENERAL.of")} ${Months[ month === "-1" ? new Date().getMonth() : parseInt(month)].label}`} </p>
                            { birthdayPerson && birthdayPerson.length > 0 ? 
                                birthdayPerson.map(birthday => 
                                    <p className={styles.printableText}><b>{`${DateToDayAndMonth(FormatDateToFront(birthday.dateOfBirth))} `}</b>{`${birthday.name}`}</p>
                                )
                            : null}
                        </div>
                    </div>     
                )        
            :  <img src={BirthdaysImg} alt={''} className={styles.printableImg}/>}            
        </div>
    )
})

export default BirthdaysToPrint