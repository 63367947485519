import React from "react";

import { containersStyles } from "./Styles";

const BlueHeaderContainer = ({children}) => {
    const styles = containersStyles();
    return(
        <div className={styles.blueHeaderContainer}>
            {children}
        </div>
    )
}

export default BlueHeaderContainer