import { StyleSheet } from "@react-pdf/renderer";

export default StyleSheet.create({
    label: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "3 5",
        backgroundColor: "#EEF2F6",
        color: "#2C3E51",
        fontSize: 10,
        borderRadius: 3,
    }
})
