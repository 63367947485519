import Button from "@material-ui/core/Button";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Add from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import Dissatisfied from "@material-ui/icons/SentimentVeryDissatisfied";
import { useStyles } from "components/layout/CommonStyles";
import AllowancesContext from "context/allowance/allowanceContext";
import ProjectsContext from "context/projects/projectsContext";
import 'moment/locale/pt-br';
import React, { useContext, useEffect, useState } from "react";
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils from 'react-day-picker/moment';
import { useTranslation } from "react-i18next";
import MySnackbarContentWrapper from "../../layout/Message";
import './DatePicker.css';
import DialogExpense from "./DialogExpense";
import ExpensesTable from "./ExpensesTable";

const ExpensesIndex = props => {
    const { projectId, expenses, mode, rubricFilter } = props;
    const [filter, setFilter] = useState("");
    const [t] = useTranslation();
    const classes = useStyles();
    let dataFilter = [];
    const hasExpenses = expenses.length > 0;
    dataFilter = expenses;

    const [rubricLabel, setRubricLabel] = useState(rubricFilter[0].label);
    const projectContext = useContext(ProjectsContext);
    const [openDialog, setOpenDialog] = useState(false);
    const allowanceContext = useContext(AllowancesContext);
    const {
        response,
        responseType,
        successiveActions,
        hasChanged,
        setHasChanged,
        setDialogData } = projectContext;
    const {
        allowanceResponse,
        allowanceResponseType,
        messageHasChanged,
        resetAllowanceMessage,
        verifyPermission,
        permission,
        getAllPermission,
        allAllowances
    } = allowanceContext;

    React.useEffect(() => {
        getAllPermission()
    }, [allAllowances.length, JSON.stringify(allAllowances)]);

    const [snackBar, setSnackBar] = useState({
        open: false,
        kind: "",
        content: ""
    });

    const renderTable = data => {
        return (
            <ExpensesTable
                rows={data}
                headers={["id", "craftNumber", "name", "rubric", "cost", "expenseDate"]}
                sortedFields={["craftNumber", "name", "rubric", "cost", "expenseDate"]}
                visibleHeaders={[
                    "Nº DO OFÍCIO",
                    "NOME DA DESPESA",
                    "RUBRICA",
                    "VALOR (R$)",
                    "DATA DA DESPESA"
                ]}
                projectId={projectId}
                mode={mode}
                allAllowances={allAllowances}
            />
        );
    };

    const [expensesTable, setExpensesTable] = useState(
        renderTable(dataFilter)
    );
    const [hasNoResult, setHasNoResult] = useState(hasExpenses && dataFilter.length === 0);

    useEffect(() => {
        verifyPermission("EXPENSES")
        if (allowanceResponseType === "ERROR" && messageHasChanged) {
            handleSnack({ kind: "error", content: allowanceResponse });
        }
        if (responseType === "SUCCESS" && hasChanged > successiveActions) {
            handleSnack({ kind: "success", content: response });
        }
        if (responseType === "ERROR" && hasChanged > successiveActions) {
            handleSnack({ kind: "error", content: response });
        }
        return () => { };
    }, [
        responseType,
        response,
        successiveActions,
        allowanceResponseType,
        allowanceResponse
    ]);

    const handleSnack = () => {
        setSnackBar({ ...snackBar, open: true });
    };

    const handleSnackClose = () => {
        setSnackBar({ ...snackBar, open: false });
        setHasChanged();
        resetAllowanceMessage();
    };

    const handleChangeHeading = event => {
        if (event.target.value === "Todas")
            dataFilter = expenses;
        else
            dataFilter = expenses.filter(el => el.rubric === event.target.value);

        if (state && state.from && state.to) {
            const fromDate = new Date(state.from.getFullYear() + "/" + (state.from.getMonth() + 1) + "/" + state.from.getDate());
            const toDate = new Date(state.to.setHours(23, 59, 59));

            dataFilter = dataFilter.filter(el => new Date(el.expenseDate) >= fromDate && new Date(el.expenseDate) <= toDate);
        }

        if (filter) {
            dataFilter = dataFilter.filter(
                item =>
                    item.craftNumber.toLowerCase().includes(filter.toLowerCase()) ||
                    item.name.toLowerCase().includes(filter.toLowerCase())
            );
        }

        if (dataFilter.length === 0)
            setHasNoResult(true);
        else
            setHasNoResult(false);

        setRubricLabel(event.target.value);
        setExpensesTable(renderTable(dataFilter));
    };

    const handleSearch = event => {
        setFilter(event.target.value);

        if (rubricLabel === "Todas")
            dataFilter = expenses;
        else
            dataFilter = expenses.filter(el => el.rubric === rubricLabel);

        if (state && state.from && state.to) {
            const fromDate = new Date(state.from.getFullYear() + "/" + (state.from.getMonth() + 1) + "/" + state.from.getDate());
            const toDate = new Date(state.to.setHours(23, 59, 59));

            dataFilter = dataFilter.filter(el => new Date(el.expenseDate) >= fromDate && new Date(el.expenseDate) <= toDate);
        }

        dataFilter = dataFilter.filter(
            item =>
                item.craftNumber.toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.name.toLowerCase().includes(event.target.value.toLowerCase())
        );

        if (dataFilter.length === 0)
            setHasNoResult(true);
        else
            setHasNoResult(false);

        setExpensesTable(renderTable(dataFilter));
    };

    const [state, setState] = useState({
        from: undefined,
        to: undefined
    });

    const [showDatePicker, setShowDatePicker] = useState(false);

    const { from, to } = state;
    const modifiers = { start: from, end: to };

    const handleDayClick = day => {
        const range = DateUtils.addDayToRange(day, state);
        setState(range);
    };

    const handleShowDatePicker = () => {
        setShowDatePicker(!showDatePicker);
    };

    const handleHideDatePicker = () => {
        setShowDatePicker(!showDatePicker);
    };

    const handleShowDialog = () => {
        setOpenDialog(true);
        setExpensesTable(renderTable(dataFilter));
    }

    useEffect(
        () => {
            const mouseTarget = document.querySelectorAll(".Selectable");

            if (mouseTarget[0]) {
                mouseTarget[0].addEventListener('mouseleave', e => {
                    setShowDatePicker(!showDatePicker);
                });
            }

        }, [showDatePicker]
    )

    useEffect(() => {
        if (state && state.from && state.to) {
            const fromDate = new Date(state.from.getFullYear() + "/" + (state.from.getMonth() + 1) + "/" + state.from.getDate());
            const toDate = new Date(state.to.setHours(0, 0, 0));

            if (rubricLabel === "Todas")
                dataFilter = expenses;
            else
                dataFilter = expenses.filter(el => el.rubric === rubricLabel);

            dataFilter = dataFilter.filter(el => new Date(el.expenseDate) >= fromDate && new Date(el.expenseDate) <= toDate);

            if (filter) {
                dataFilter = dataFilter.filter(
                    item =>
                        item.craftNumber.toLowerCase().includes(filter.toLowerCase()) ||
                        item.name.toLowerCase().includes(filter.toLowerCase())
                );
            }

            if (dataFilter.length === 0)
                setHasNoResult(true);
            else
                setHasNoResult(false);

            setExpensesTable(renderTable(dataFilter));
        }
    }, [state]);

    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={snackBar.open}
                onClose={handleSnackClose}
                TransitionComponent={Slide}
                transitionDuration={{ enter: 500, exit: 500 }}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                autoHideDuration={2000}
            >
                <MySnackbarContentWrapper
                    onClose={handleSnackClose}
                    variant={
                        responseType !== ""
                            ? responseType.toLowerCase()
                            : allowanceResponseType.toLowerCase()
                    }
                    message={
                        <span id="message-id">
                            {response !== "" ? response : allowanceResponse}
                        </span>
                    }
                />
            </Snackbar>
            <Paper>
                <div className={classes.divAddParticipant}>
                    <div className={classes.divAddParticipantLeft}>
                        <Toolbar className={classes.searchToolbar}>
                            <div style={{ display: !hasExpenses ? "none" : "" }} className={classes.search}>
                                <div className={classes.searchIcon}>
                                    <SearchIcon />
                                </div>
                                <InputBase
                                    placeholder={t("EXPENSES.expensesSearch")}
                                    value={filter}
                                    onChange={handleSearch}
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput
                                    }}
                                    inputProps={{ "aria-label": "search" }}
                                    style={{ width: "100%" }}
                                />
                            </div>
                        </Toolbar>
                        <div>
                            <TextField
                                style={{ display: !hasExpenses ? "none" : "" }}
                                select
                                id="headings"
                                label={t("PROFILES.form.headings")}
                                className={classes.textField, classes.fieldInputSearch}
                                name="headings"
                                margin="normal"
                                value={rubricLabel}
                                onChange={handleChangeHeading}
                            >
                                {rubricFilter.map(option => (
                                    <MenuItem key={option.value} value={option.label}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <div>
                            {hasExpenses &&
                                <TextField
                                    className={classes.textField, classes.fieldInputSearch}
                                    margin="normal"
                                    id="expensesPeriod"
                                    label={t("EXPENSES.expensesPeriod")}
                                    name="expensesPeriod"
                                    value={`De ${state.from ? state.from.getDate() + "/" + (state.from.getMonth() + 1) + "/" + state.from.getFullYear() : "-"} até ${state.to ? state.to.getDate() + "/" + (state.to.getMonth() + 1) + "/" + state.to.getFullYear() : "-"}`}
                                    onClick={handleShowDatePicker}
                                />
                            }
                            {showDatePicker &&
                                <DayPicker
                                    localeUtils={MomentLocaleUtils}
                                    locale="pt-br"
                                    className="Selectable"
                                    numberOfMonths={1}
                                    selectedDays={[from, { from, to }]}
                                    modifiers={modifiers}
                                    onDayClick={handleDayClick}
                                    enableOutsideDaysClick={true}
                                />
                            }
                        </div>
                    </div>
                    {mode === t("GENERAL.editMode") && permission["allowCreate"] &&
                        <Button style={{ margin: "22px", padding: "15px" }} onClick={() => setDialogData(null, t("GENERAL.addMode"), true)}>
                            <div className={classes.buttonIcon}>
                                <Add />
                            </div>
                            {t("EXPENSES.addExpense")}
                        </Button>
                    }
                </div>
                <div className="lineBreak"></div>
                {hasExpenses ? expensesTable :
                    <div className={classes.noParticipantsMessage}>
                        <h2>{t("EXPENSES.noExpensesMessage")}</h2>
                        <h4>{t("EXPENSES.noExpensesSubMessage")}</h4>
                    </div>
                }
                {hasNoResult &&
                    <div className={classes.noParticipantsMessage}>
                        <div >
                            <Dissatisfied className={classes.notFoundIconStyle} />
                        </div>
                        <h2>{t("GENERAL.noResultsFound")}</h2>
                        {filter ?
                            <h5>{`Sua busca por "${filter}" não retornou resultados.`}</h5> :
                            <h5>{`Sua busca não retornou resultados.`}</h5>
                        }
                    </div>
                }
            </Paper>
            <DialogExpense projectId={projectId} rubrics={rubricFilter} />
        </div>
    );
};

export default ExpensesIndex;