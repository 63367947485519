import React from 'react';
import { animated, useTransition } from 'react-spring';
import Spinner from 'components/layout/spinners/Index';

const Transition = ({
    children,
    loading,
    withoutContainer = false,
    newDesign
}) => {
    const transitions = useTransition(loading, null, {
        from: { opacity: 0 },
        enter: {
            opacity: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: newDesign ? 'auto' : 'calc(100vh - 70px)'
        },
        leave: { opacity: 0 }
    });

    return transitions.map(({ item, key, props }) =>
        item ? (
            <animated.div key={key} style={props}>
                <Spinner />
            </animated.div>
        ) : !withoutContainer ? (
            <animated.div key={key} style={props}>
                {children}
            </animated.div>
        ) : (
            <React.Fragment>{children}</React.Fragment>
        )
    );
};

export default Transition;
