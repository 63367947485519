import React from 'react';
import '../../../App.css';
import SideBar from 'components/layout/sidebar/Sidebar';
import DashboardNavBar from 'components/layout/DashboardNavBar';
import Form from './Form/Form';

const CallEditUser = ({ match }) => {
    return (
        <div>
            <DashboardNavBar />
            <SideBar />
            <Form context={'edit'} id={match.params.id} />
        </div>
    );
};
export default CallEditUser;
