import { useCallback, useEffect, useState, useMemo } from "react";

/**
 * Custom hook to filter data by multiple columns
 * @param {Array} dataToFilter The data to filter
 * @param {Object} filters The filters to apply
 */
const useFilter = (dataToFilter = [{}], filters) => {
    const [filteredData, setFilteredData] = useState(dataToFilter);

    const filterData = useMemo(() => {
        if (dataToFilter.length === 0 || Object.keys(filters).length === 0) {
            return dataToFilter;
        }

        const normalizedFilters = Object.keys(filters).reduce((acc, key) => {
            acc[key] = filters[key]
                .toString()
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "");
            return acc;
        }, {});

        return dataToFilter.filter(data => {
            return Object.keys(normalizedFilters).every(key => {
                if (!data.hasOwnProperty(key)) {
                    return false;
                }
                const dataValue = data[key]
                    .toString()
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "");
                const filterValue = normalizedFilters[key];
                return filterValue !== "all"
                    ? dataValue.includes(filterValue)
                    : true;
            });
        });
    }, [dataToFilter, filters]);

    useEffect(() => {
        setFilteredData(filterData);
    }, [filterData]);

    return filteredData;
};

export default useFilter;
