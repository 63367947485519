import AllowancesContext from "context/allowance/allowanceContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route } from "react-router-dom";
import history from "../../history";
import auth from "./auth";
import { getAuthenticationData, authorized, isLogged } from "./Authentication";
import Cookies from "js-cookie";


export const ProtectedRoute = ({ component: Component, ...rest }) => {
  let canAccess = false;
  const [t] = useTranslation();
  const allowanceContext = useContext(AllowancesContext);
  const {
    verifyRights,
    formattedAllowances,
    setAllowanceMessage,
  } = allowanceContext;

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);


  const getPermission = (resourceProps, screen) => {
    if (localStorage.getItem("auth-token") !== null) {
      var auth = JSON.parse(localStorage.getItem("auth-token"));
      var array = []
      for (var i = 0; i < auth.permissions.length; i++) {
        var permission = auth.permissions[i].standardauthorizationoption;
        var resource = auth.permissions[i].resource;
        if (screen === "list") {
          return true
        } else {
          if (resource === resourceProps) {
            return permission[screen]
          }
        }
      }
    }
  }

  useEffect(() => {
    const fetchData = async () => {

        if (isLogged()) {
            if (localStorage.getItem('auth-token') !== null &&
                Cookies.get('auth-token') === JSON.parse(localStorage.getItem('auth-token')).token){

                setIsAuthenticated(true);
            }
            else {
                var result = await getAuthenticationData();
                setIsAuthenticated(result);
            }
        }
        else {
            setIsAuthenticated(false);
        }

        setLoading(false)
    }
    fetchData();
}, [])

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          auth.getLogged() &&
          auth.isAuthenticated(
            getPermission(rest.resource, rest.permission)
          ) ? (
            <Component {...props} />
          ) : (
            <React.Fragment>
              {setAllowanceMessage(t("PROFILES.route.accessDenied"), "ERROR")}
              <Redirect
                to={{
                  pathname: history.goBack(),
                  state: {
                    from: props.location,
                  },
                }}
              />
            </React.Fragment>
          )
        ) : loading ? (
          <div>loading...</div>
        ) : (
          <React.Fragment>
            {(window.location.href = process.env.REACT_APP_SITE)}
          </React.Fragment>
          // <Redirect
          //   to={{
          //     pathname: "/",
          //     state: {
          //       from: props.location
          //     }
          //   }}
          // />
        )
      }
    />
  );
};

