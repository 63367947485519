import React, { useState, useEffect, useContext } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import CollaboratorContext from 'context/hrModule/collaborator/collaboratorContext';
import AllowancesContext from 'context/allowance/allowanceContext';
import Breadcrumb from 'components/layout/breadcrumb/Breadcrumb';
import MySnackbarContentWrapper from 'components/layout/Message';
import IdentificationForm from './Informations/IdentificationForm';
import ContactForm from './Informations/ContactForm';
import FamilyDataForm from './Informations/FamilyDataForm';
import FormationForm from './Informations/FormationForm';
import BankDataForm from 'pages/hrModule/collaborator/collaborators/BankData/BankDataForm';
import AdmisionForm from './Admission/AdmissionForm';
import Footer from 'components/layout/Footer';
import DocumentForm from './DocumentStorage/DocumentForm';
import ProfileForm from './Profile/ProfileForm';
import ClockRecordForm from './ClockRecord/ClockRecordForm';
import { QueryGetValue } from 'utils/general/QueryString';

const CollaboratorsTabPanel = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const collaboratorsContext = useContext(CollaboratorContext);
    const allowanceContext = useContext(AllowancesContext);
    const [value, setValue] = useState(0);
    const [valueNested, setValueNested] = useState(0);

    const { userId, context, myInformations, ...others } = props;

    const [tabQuery, setTabQuery] = useState('');

    const [snackBar, setSnackBar] = useState({
        open: false,
        kind: '',
        content: ''
    });

    const {
        hasChanged,
        setHasChanged,
        responseType,
        response,
        successiveActions
    } = collaboratorsContext;

    const {
        allowanceResponse,
        allowanceResponseType,
        messageHasChanged,
        resetAllowanceMessage,
        permission
    } = allowanceContext;

    useEffect(() => {
        if (allowanceResponseType === 'ERROR' && messageHasChanged) {
            handleSnack({ kind: 'error', content: allowanceResponse });
        }

        if (responseType === 'SUCCESS' && hasChanged > successiveActions) {
            handleSnack({ kind: 'success', content: response });
        }
        if (responseType === 'ERROR' && hasChanged > successiveActions) {
            handleSnack({ kind: 'error', content: response });
        }

        return () => {};
    }, [
        responseType,
        response,
        successiveActions,
        allowanceResponseType,
        allowanceResponse
    ]);

    const handleSnack = () => {
        setSnackBar({ ...snackBar, open: true });
        setHasChanged();
    };
    const handleSnackClose = () => {
        setSnackBar({ ...snackBar, open: false });
        resetAllowanceMessage();
    };

    function TabPanel(props) {
        const { children, value, index } = props;

        return <div>{value === index && children}</div>;
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`
        };
    }

    function a11yPropsNested(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`
        };
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeNested = (event, newValue) => {
        setValueNested(newValue);
    };

    const tabContext = () => {
        if (value === 0) {
            if (valueNested === 0) {
                return (
                    <IdentificationForm
                        userId={userId}
                        context={context}
                        myInformations={myInformations}
                    />
                );
            } else if (valueNested === 1) {
                return <ContactForm userId={userId} context={context} />;
            } else if (valueNested === 2) {
                return <FamilyDataForm userId={userId} context={context} />;
            } else if (valueNested === 3) {
                return <FormationForm userId={userId} context={context} />;
            }
        } else if (value === 1) {
            return <BankDataForm userId={userId} context={context} />;
        } else if (value === 2) {
            return <AdmisionForm userId={userId} context={context} />;
        } else if (value === 3) {
            return <DocumentForm userId={userId} context={context} />;
        } else if (value === 4) {
            return (
                <ProfileForm
                    userId={userId}
                    context={context}
                    myInformations={myInformations}
                />
            );
        } else if (value === 5) {
            return <ClockRecordForm userId={userId} context={context} />;
        }
    };

    useEffect(() => {
        setTabQuery(QueryGetValue('tab'));
    }, []);

    useEffect(() => {
        if (tabQuery === 'PERMISSIONS') {
            setValue(4);
        }
    }, [tabQuery]);

    return (
        <React.Fragment>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={snackBar.open}
                onClose={handleSnackClose}
                TransitionComponent={Slide}
                transitionDuration={{ enter: 500, exit: 500 }}
                ContentProps={{
                    'aria-describedby': 'message-id'
                }}
                autoHideDuration={2000}
            >
                <MySnackbarContentWrapper
                    onClose={handleSnackClose}
                    variant={
                        responseType !== ''
                            ? responseType.toLowerCase()
                            : allowanceResponseType.toLowerCase()
                    }
                    message={
                        <span id="message-id">
                            {response !== '' ? response : allowanceResponse}
                        </span>
                    }
                />
            </Snackbar>
            <div className="headerNewDesign">
                <Breadcrumb
                    newDesign={true}
                    routes={
                        myInformations
                            ? [
                                  {
                                      label: t('HR.humanResource'),
                                      to: '/HumanResources'
                                  },
                                  { label: t('HR.myInformations') }
                              ]
                            : [
                                  {
                                      label: t('HR.humanResource'),
                                      to: '/HumanResources'
                                  },
                                  {
                                      label: t('HR.collaborators'),
                                      to: '/Collaborators'
                                  },
                                  {
                                      label:
                                          context === 'view'
                                              ? t('HR.viewCollaborator')
                                              : t('HR.editCollaborator')
                                  }
                              ]
                    }
                />
                <div className="container-headerNewDesign">
                    <h1 className={classes.titleNewDesign}>
                        {myInformations
                            ? t('HR.myInformations')
                            : context === 'view'
                            ? t('HR.viewCollaborator')
                            : t('HR.editCollaborator')}
                    </h1>
                </div>
            </div>

            <div className="containerNewDesign">
                <Paper>
                    <div className="cabecalho">{t('HR.collaboratorData')}</div>
                    <AppBar
                        position="static"
                        style={{ boxShadow: 'none', transform: 'none' }}
                    >
                        <Tabs
                            selectionFollowsFocus
                            style={{
                                backgroundColor: '#f5f6fa',
                                color: 'black'
                            }}
                            indicatorColor="primary"
                            value={value}
                            onChange={handleChange}
                            variant="fullWidth"
                        >
                            <Tab
                                style={{ fontWeight: 'bold' }}
                                label={t('HR.information').toUpperCase()}
                                {...a11yProps(0)}
                            />
                            <Tab
                                style={{ fontWeight: 'bold' }}
                                label={t('HR.bankData').toUpperCase()}
                                {...a11yProps(1)}
                            />
                            <Tab
                                style={{ fontWeight: 'bold' }}
                                label={t('HR.admission').toUpperCase()}
                                {...a11yProps(2)}
                            />
                            <Tab
                                style={{ fontWeight: 'bold' }}
                                label={t('HR.documents').toUpperCase()}
                                {...a11yProps(3)}
                            />
                            <Tab
                                style={{ fontWeight: 'bold' }}
                                label={t(
                                    'HR.permissionsAndAccess'
                                ).toUpperCase()}
                                {...a11yProps(4)}
                            />
                            <Tab
                                style={{ fontWeight: 'bold' }}
                                label={t('HR.clockRecord').toUpperCase()}
                                {...a11yProps(5)}
                            />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                        <AppBar
                            position="static"
                            style={{ boxShadow: 'none', transform: 'none' }}
                        >
                            <Tabs
                                selectionFollowsFocus
                                style={{
                                    backgroundColor: '#ffffff',
                                    color: 'black',
                                    paddingTop: '10px',
                                    paddingLeft: '10px'
                                }}
                                indicatorColor="primary"
                                value={valueNested}
                                onChange={handleChangeNested}
                            >
                                <Tab
                                    style={{ fontWeight: 'bold' }}
                                    label={t('HR.identification').toUpperCase()}
                                    {...a11yPropsNested(0)}
                                />
                                <Tab
                                    style={{ fontWeight: 'bold' }}
                                    label={t('HR.contact').toUpperCase()}
                                    {...a11yPropsNested(1)}
                                />
                                <Tab
                                    style={{ fontWeight: 'bold' }}
                                    label={t('HR.familyData').toUpperCase()}
                                    {...a11yPropsNested(2)}
                                />
                                <Tab
                                    style={{ fontWeight: 'bold' }}
                                    label={t('HR.formation').toUpperCase()}
                                    {...a11yPropsNested(3)}
                                />
                            </Tabs>
                        </AppBar>
                    </TabPanel>

                    {tabContext()}
                </Paper>
            </div>
            <Footer />
        </React.Fragment>
    );
};

export default CollaboratorsTabPanel;

// 6944451
