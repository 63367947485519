import DateFnsUtils from "@date-io/date-fns";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import Dissatisfied from "@material-ui/icons/SentimentVeryDissatisfied";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useStyles } from "components/layout/CommonStyles";
import InventoryContext from "context/inventory/inventoryContext";
import "moment/locale/pt-br";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../../profiles/css/FormStyle.css";
import HistoryEquipmentTable from "./HistoryTable";
import Transition from "components/layout/transition/Transition";

const HistoryExternalUserTabPanel = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const { idUser } = props;
    const inventoryContext = useContext(InventoryContext);

    const {
        loading,
        getExternalHistory,
        externalUserHistory,
    } = inventoryContext;

    useEffect(() => {
        getExternalHistory(idUser);
    }, []);

    useEffect(() => {
        if (externalUserHistory && externalUserHistory.length > 0) {
            setDataFilter(externalUserHistory);
        }
    }, [externalUserHistory]);

    const [dataFilter, setDataFilter] = useState(externalUserHistory);
    const [findText, setFindText] = useState("");

    const hasItens = externalUserHistory.length > 0;

    const operations = [
        "Alteração de Local",
        "Alteração de Status",
        "Primeiro Local",
        "Primeiro Status"
    ];

    const [filterDate, setFilterDate] = useState(null);
    const [inputDate, setInputDate] = useState(null);
    const [filterOperation, setFilterOperation] = useState("");
    const [filterName, setFilterName] = useState("");
    const [filterDestination, setFilterDestination] = useState("");

    const onDateChange = (date, value) => {
        setFilterDate(date);
        setInputDate(value);
    };

    useEffect(() => {
        let newDataFilter = externalUserHistory;
        let textNoFound = "";

        if (filterDate) {
            newDataFilter = newDataFilter.filter(item => {
                const dateItem = new Date(item.date);
                return (
                    dateItem.getDate() === filterDate.getDate()
                    && dateItem.getMonth() === filterDate.getMonth()
                    && dateItem.getFullYear() === filterDate.getFullYear()
                )
            });
            if (!textNoFound && newDataFilter.length === 0) textNoFound = inputDate;
        }

        if (filterOperation) {
            newDataFilter = newDataFilter.filter(item => item.operation && item.operation.toLowerCase() === filterOperation.toLowerCase());
            if (!textNoFound && newDataFilter.length === 0) textNoFound = filterOperation;
        }

        if (filterName) {
            newDataFilter = newDataFilter.filter(item =>
                item.name.toLowerCase().includes(filterName.toLowerCase())
            );
            if (!textNoFound && newDataFilter.length === 0) textNoFound = filterName;
        }

        if (filterDestination) {
            newDataFilter = newDataFilter.filter(item =>
                item.newValue.toLowerCase().includes(filterDestination.toLowerCase())
            );
            if (!textNoFound && newDataFilter.length === 0) textNoFound = filterDestination;
        }

        if (newDataFilter.length === 0) {
            setFindText(textNoFound);
        } else {
            setFindText("");
        }

        setDataFilter(newDataFilter);

    }, [filterDate, filterOperation, filterName, filterDestination]);

    return (
        <Transition loading={loading} withoutContainer={true}>
            <div className={classes.divAddParticipantLeft} style={{ display: !hasItens ? "none" : "" }}>
                <Toolbar className={classes.searchToolbarInventory}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div style={{ display: !hasItens ? "none" : "" }} className={classes.searchInventory}>
                            <KeyboardDatePicker
                                className={classes.textField}
                                disableToolbar
                                autoOk={true}
                                variant="inline"
                                format="dd/MM/yyyy"
                                id="date"
                                invalidDateMessage=""
                                label={t("INVENTORY.historyDate")}
                                KeyboardButtonProps={{
                                    "aria-label": "change date"
                                }}
                                value={filterDate}
                                onChange={onDateChange}
                            />
                        </div>
                    </MuiPickersUtilsProvider>
                </Toolbar>
                <Toolbar className={classes.searchToolbarInventory} >
                    <div style={{ display: !hasItens ? "none" : "" }} className={classes.searchInventory} >
                        <TextField
                            select
                            id="searchOperation"
                            label={t("INVENTORY.HistoryOperation")}
                            floatingLabelFixed={true}
                            name="searchoperation"
                            style={{ minWidth: "250px", marginTop: 0, marginBottom: 0, marginLeft: 0 }}
                            value={filterOperation}
                            className={classes.textField}
                            onChange={(event) => setFilterOperation(event.target.value)}
                            margin="normal"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput
                            }}
                            inputProps={{ "aria-label": "search" }}
                        >
                            {operations.map(operation => (
                                <MenuItem key={operation} value={operation}>
                                    {operation}
                                </MenuItem>
                            ))}
                        </TextField>

                    </div>
                </Toolbar>
                <Toolbar className={classes.searchToolbarInventory} >
                    <div style={{ display: !hasItens ? "none" : "" }} className={classes.searchInventory}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder={t("INVENTORY.item")}
                            value={filterName}
                            onChange={(event) => setFilterName(event.target.value)}
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput
                            }}
                            inputProps={{ "aria-label": "search" }}
                            style={{ width: "100%" }}
                        />
                    </div>
                </Toolbar>
                <Toolbar className={classes.searchToolbarInventory} >
                    <div style={{ display: !hasItens ? "none" : "" }} className={classes.searchInventory}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder={t("INVENTORY.historydestination")}
                            value={filterDestination}
                            onChange={(event) => setFilterDestination(event.target.value)}
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput
                            }}
                            inputProps={{ "aria-label": "search" }}
                            style={{ width: "100%" }}
                        />
                    </div>
                </Toolbar>
            </div>
            {hasItens ?
                dataFilter.length > 0 ?
                    <HistoryEquipmentTable
                        rows={dataFilter}
                        mode={"view"}
                        headers={["id", "date", "operation", "description", "place", "newValue", "executor", "itemStatus", "placeId", "responsibleId", "itemStatusId", "name", "serialNumber", "codeId"]}
                        visibleHeaders={["Data", "Operação", "Item", "Destino"]}
                        sortedFields={["date", "operation", "item", "newValue"]}
                        mainField="date"
                    />
                    :
                    <div className={classes.noParticipantsMessage}>
                        <div >
                            <Dissatisfied className={classes.notFoundIconStyle} />
                        </div>
                        <h2>{t("GENERAL.noResultsFound")}</h2>
                        <h5>{`Sua busca por "${findText}" não retornou resultados.`}</h5>
                    </div>
                :
                <div className={classes.noFilesMessage}>
                    <Typography variant={"h6"} style={{ fontWeight: "bold" }}>{t("INVENTORY.noHistoryMessage")}</Typography>
                    <Typography variant={"caption"}>{t("INVENTORY.noHistorySubMessage")}</Typography>
                </div>
            }
        </Transition>
    );
}

export default HistoryExternalUserTabPanel;