import React from "react";

const Circle = props => {
    var colors = ["#fd367b", "#3fe2d3", "#ffe01b", "#5e78fe"];
    const { i } = props;

    var circleStyle = {
        padding: 10,
        margin: 20,
        display: "flex",
        backgroundColor: colors[i],
        borderRadius: "50%",
        width: 5,
        height: 5,
    };

    return (
        <div style={circleStyle}></div>
    );
};
export default Circle;