import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from '@material-ui/icons/Close';
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import Buttons from "../../../components/buttons/Pressed";
import axios from "axios";
import InventoryContext from "context/inventory/inventoryContext";
import ProfilesContext from "context/profiles/profilesContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import history from "../../../history";
import { GetGeneralPermissionForAction } from "utils/permissions/GetGeneralPermissionForAction";
import { Screen, Allow } from "global/constants";
import Cookies from "js-cookie";


const useStyles = makeStyles(theme => ({
  icons: {
    opacity: "0"
  },
  root: {
    flexGrow: 1,
  }
}));

const MyItensTableActions = props => {
  const classes = useStyles();
  const [t] = useTranslation();
  const profilesContext = useContext(ProfilesContext);
  const { deleteProfile, response, responseType } = profilesContext;
  const [open, setOpen] = useState(false);
  const { item, name, permission } = props;
  const inventoryContext = useContext(InventoryContext);

  const {
    deleteRequisition
  } = inventoryContext;

  useEffect(() => {
    return () => { };
  }, [response, responseType]);

  const getPermissionInventory = () => {
    if (localStorage.getItem("auth-token") !== null) {
      var auth = JSON.parse(localStorage.getItem("auth-token"));
      const permission = auth.permissionsByModule;

      // Get the user profile rule for the people management module
      const peopleManagement = permission.filter(el => el.systemId == 6905701);
      // Get the user profile rule for the inventory module
      const inventory = permission.filter(el => el.systemId == 6910301);

      // Checks whether the user is an administrative manager in the people management module and an administrator in the assets module 
      if (inventory[0].roleId === 6905151) {
        return true;
      } else {
        return false;
      }
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const verifyEdit = () => {
    axios.get(process.env.REACT_APP_SERVER + `api/InventoryRequisition/${item.id}`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
      }
    })
      .then(res => {
        if (res.statusText === "OK") {
          history.push(`/Requisition/${item.id}/edit`);
        } else {
          // deleteProject(item.id);
        }
      })
      .catch(error => {
        //deleteProject(item.id);
      });
  }

  const verifyView = () => {
    axios.get(process.env.REACT_APP_SERVER + `api/InventoryRequisition/${item.id}`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
      }
    })
      .then(res => {
        if (res.statusText === "OK") {
          history.push(`/Requisition/${item.id}`);
        } else {
          // deleteProfile(item.id);
        }
      })
      .catch(error => {
        // deleteProfile(item.id);
      });
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    // deleteEquipment(item.id, item.active);
    deleteRequisition(item.id)
    setOpen(false);
  };

  return (
    <React.Fragment>
      {GetGeneralPermissionForAction(Screen.MY_REQUISITIONS, Allow.READ) &&
        <Tooltip title={t("GENERAL.view")} aria-label="details" placement="top">
          <IconButton className={classes.icons} onClick={verifyView} >
            <Visibility />
          </IconButton>
        </Tooltip>
      }
      {GetGeneralPermissionForAction(Screen.MY_REQUISITIONS, Allow.EDIT) && item.status === "Aguardando Aprovação" && <Tooltip title="Editar" aria-label="edit" placement="top">
        <IconButton className={classes.icons} onClick={verifyEdit}>
          <Edit />
        </IconButton>
      </Tooltip>}
      {GetGeneralPermissionForAction(Screen.MY_REQUISITIONS, Allow.DELETE) && item.status === "Aguardando Aprovação" &&
        <Tooltip title="Deletar" aria-label="delete" placement="top">
          <IconButton className={classes.icons} onClick={handleClickOpen}>
            <Delete />
          </IconButton>
        </Tooltip>
      }
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        msg="mensagem"
      >
        <DialogTitle className={classes.Title}>
          {t("GENERAL.delete")}
          <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("GENERAL.deleteMessagePF")} {t("INVENTORY.request")}?
            <table style={{ width: "100%", textAlign: "left" }}>
              <thead>
                <tr>
                  <th>{t("GENERAL.date")}</th>
                  <th>{t("INVENTORY.requestType")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{item.dateCreated}</td>
                  <td>{item.class}</td>
                </tr>
              </tbody>
            </table>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Buttons
            onClick={handleClose}
            tipo="BN-noback"
            conteudo={t("GENERAL.cancel").toUpperCase()}
          />
          <Buttons
            onClick={handleDelete}
            tipo="BN-blue"
            conteudo={t("GENERAL.delete").toUpperCase()}
          />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default MyItensTableActions;