import React from 'react';
import { screenStyles } from './Styles';
import Buttons from 'components/buttons/Pressed';
import {
    CardVerticalComponent,
    CardImage,
    CardHorizontalComponent,
    CardTitle,
    CardDetails
} from 'pages/libraryModule/cards/CardsUtils';
import Link from 'react-router-dom/Link';

const BookCardAwaitingList = props => {
    const {
        id,
        image,
        title,
        author,
        category,
        queueLength,
        onClickViewList,
        onClickManageList
    } = props;
    const styles = screenStyles();

    return (
        <React.Fragment>
            <div className={styles.cardContainer}>
                <CardImage image={image} alt={title} />
                <CardTitle title={title} />
                <CardVerticalComponent head={'Autor'} content={author} />
                <CardVerticalComponent head={'Categoria'} content={category} />
                <CardHorizontalComponent
                    head={'Colaboradores na fila'}
                    content={queueLength}
                />
                <CardDetails
                    onClick={onClickViewList}
                    title={'VISUALIZAR LISTA'}
                />
                <Link to={`/Library/AwaitingList/${id}`}>
                    <Buttons
                        style={{ width: '100%' }}
                        onClick={onClickManageList}
                        className={styles.cardButton}
                        tipo="BN-blue"
                        conteudo={'GERENCIAR LISTA'}
                    />
                </Link>
            </div>
        </React.Fragment>
    );
};

export default BookCardAwaitingList;
