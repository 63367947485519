import React from "react";
import "../../../../App.css";
import SideBar from "components/layout/sidebar/Sidebar";
import DashboardNavBar from "components/layout/DashboardNavBar";
import HolidaysAndBreaks from "./HolidaysAndBreaks";

const CallHolidaysAndBreaks = ({ match }) => {

    return (
        <div>
            <DashboardNavBar />
            <SideBar />
            <HolidaysAndBreaks />
        </div>
    );
}

export default CallHolidaysAndBreaks;