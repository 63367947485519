import React from "react";
import { useStyles } from "components/layout/CommonStyles";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Dropzone from "react-dropzone";

import Edit from "@material-ui/icons/Edit";
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from "@material-ui/core/IconButton";
import Button from '@material-ui/core/Button'




const InputFile = ({ files, onDrop, label, value, required, error, errorMessage, ...props }) => {
    const classes = useStyles();
    const [t] = useTranslation();


    return (
        <React.Fragment>
            <Dropzone
                maxFiles={1}
                onDrop={onDrop}
                multiple={false}
                accept="application/pdf, 
            application/msword, 
            application/vnd.openxmlformats-officedocument.wordprocessingml.document,
            image/jpeg, 
            image/png,
            application/vnd.ms-excel,
            application/vnd.ms-excel.sheet.macroEnabled.12,
            application/msexcel,
            application/x-msexcel,
            application/x-ms-excel,
            application/x-excel,
            application/x-dos_ms_excel,
            application/xls,
            application/x-xls,
            application/x-msi,
            application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"

            >
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps({ className: classes.inputFile })}>
                        <input {...getInputProps()} />
                        <TextField
                            id="client3"
                            variant="outlined"
                            label={t(label) + (required ? "*" : "")}
                            autoComplete="off"
                            style={{ width: "100%" }}
                            error={error}
                            helperText={errorMessage}
                            value={value}
                            // onClick={(ev) => {
                            //     ev.target.blur();
                            //     setOpenResponsible(true);
                            //     setRequiredResponsible(false)
                            // }}
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput
                            }}
                            inputProps={{ "aria-label": "search" }}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                readOnly: true
                            }}


                        />
                        {/* <div>
                            <h1>{t("HR.contract")}</h1>
                        </div> */}
                        <div className={classes.inputFileButton}>
                            <AttachFileIcon style={{ fontSize: 24 }} />
                            <span>Anexar</span>
                        </div>
                    </div>
                )}
            </Dropzone>
        </React.Fragment>

    )
}

export default InputFile;
