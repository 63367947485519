import axios from 'axios';
import React, { useReducer } from 'react';
import { DEFAULT, LIBRARY } from '../../types';
import collectionContext from './collectionContext';
import collectionReducer from './collectionReducer';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

const CollectionState = props => {
    const initialState = {
        message: '',
        response: '',
        responseType: '',
        hasChanged: 0,
        successiveActions: 0,
        loading: false,
        books: [],
        categories: [],
        exemplars: [],
        reservations_user: []
    };

    const [state, dispatch] = useReducer(collectionReducer, initialState);
    const [t] = useTranslation();
    const setLoading = () => dispatch({ type: DEFAULT.SET_LOADING });
    const setLoadingFalse = () => dispatch({ type: DEFAULT.SET_LOADING_FALSE });

    const resetMessage = () => dispatch({ type: DEFAULT.RESET_MESSAGE });
    const setHasChanged = () => dispatch({ type: DEFAULT.SET_HAS_CHANGED });

    const getBooks = async userId => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER +
                'api/Library/Books/UsersReservation/' +
                userId,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: LIBRARY.GET_BOOKS,
            payload: res.data
        });
    };

    const getCategories = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/Library/Categories`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: LIBRARY.GET_CATEGORIES,
            payload: res.data
        });
    };

    const getExemplars = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/Library/Exemplars`,
            {
                headers: {
                    'Content-type': 'application/json'
                    // "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
                }
            }
        );
        dispatch({
            type: LIBRARY.GET_EXEMPLARS,
            payload: res.data
        });
    };

    const getReservationsUser = async id => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/Library/Reservations/${id}`,
            {
                headers: {
                    'Content-type': 'application/json'
                    // "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
                }
            }
        );
        dispatch({
            type: LIBRARY.GET_RESERVATIONS_USER,
            payload: res.data
        });
    };

    const createRequisition = async (
        obj,
        fetchData,
        fetchReservationsData,
        emailData
    ) => {
        axios
            .post(
                process.env.REACT_APP_SERVER + 'api/Library/Reservations',
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: LIBRARY.CREATE_REQUISITION,
                        payload: t('LIBRARY.createRequisitionSuccess'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                    getAllEmailsAndSend(
                        emailData,
                        'NEW_REQUISITION_EMAIL_' + emailData.library,
                        'users',
                        'api/Library/newRequisition'
                    );
                } else {
                    dispatch({
                        type: LIBRARY.CREATE_REQUISITION,
                        payload: t('LIBRARY.createRequisitionError'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: LIBRARY.CREATE_REQUISITION,
                    payload: t('LIBRARY.createRequisitionError'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                fetchData();
                fetchReservationsData();
            });
    };

    const getAllEmailsAndSend = async (
        emailData,
        notificationPermission,
        variableName,
        emailRoute
    ) => {
        axios
            .get(
                process.env.REACT_APP_SERVER +
                    'api/EmailUsers/' +
                    notificationPermission,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                emailData[variableName] = response.data;
                sendEmailsNewRequisition(emailData, emailRoute);
            })
            .catch(error => {
                console.log(error);
            });
    };

    const sendEmailsNewRequisition = async (emailData, route) => {
        return axios
            .post(process.env.REACT_APP_EMAIL + route, emailData, {
                headers: {
                    'Content-type': 'application/json'
                }
            })
            .then(
                res => console.log('ok'),
                err => Promise.reject('error')
            );
    };

    const createQueueRequisition = async (
        obj,
        emailData,
        fetchData,
        fetchReservationsData
    ) => {
        axios
            .post(process.env.REACT_APP_SERVER + 'api/Library/Queue', obj, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(response => {
                dispatch({
                    type: LIBRARY.CREATE_QUEUE_REQUISITION,
                    payload: t('LIBRARY.createQueueRequisitionSuccess'),
                    responseType: 'SUCCESS',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
                getAllEmailsAndSend(
                    emailData,
                    'NEW_REQUISITION_QUEUE_EMAIL',
                    'libraryManagers',
                    'api/Library/BookQueue/newRequisition'
                );
            })
            .catch(error => {
                dispatch({
                    type: LIBRARY.CREATE_QUEUE_REQUISITION,
                    payload: t('LIBRARY.createQueueRequisitionError'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                fetchData();
                fetchReservationsData();
            });
    };

    return (
        <collectionContext.Provider
            value={{
                loading: state.loading,
                message: state.message,
                hasChanged: state.hasChanged,
                response: state.response,
                responseType: state.responseType,
                successiveActions: state.successiveActions,
                setLoading,
                setLoadingFalse,
                resetMessage,
                setHasChanged,
                books: state.books,
                categories: state.categories,
                exemplars: state.exemplars,
                reservations_user: state.reservations_user,
                getBooks,
                getCategories,
                getExemplars,
                getReservationsUser,
                createRequisition,
                createQueueRequisition
            }}
        >
            {props.children}
        </collectionContext.Provider>
    );
};

export default CollectionState;
