import React, { useState, useEffect, useContext } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import Breadcrumb from 'components/layout/breadcrumb/Breadcrumb';
import Footer from 'components/layout/Footer';
import HeaderContainer from 'components/containers/HeaderContainer';
import Transition from 'components/layout/transition/Transition';
import FinesContext from 'context/libraryModule/fines/finesContext';
import FinesListForm from './forms/finesList/FinesListForm';
import CashTransactionsForm from './forms/cashTransactions/CashTransactionsForm';
import { moneyMask } from 'utils/masks/moneyMask';
import MinusRed from 'assets/icons/minusRed.svg';
import PlusGreen from 'assets/icons/plusGreen.svg';
import { LibraryFinesTypes } from 'global/constants';
import DialogDebitCredit from 'pages/libraryModule/dialogs/Fines/DialogDebitCredit';
import { valueToMoneyFloat } from 'utils/general/valueToMoneyFloat';
import { screenStyles } from './Styles';
import { Months } from 'global/constants';
import Typography from 'components/typography/Typography';

const FinesTabPanel = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const [value, setValue] = useState(0);
    const styles = screenStyles();

    const [openDebitDialog, setOpenDebitDialog] = useState(false);
    const [openCreditDialog, setOpenCreditDialog] = useState(false);

    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const finesContext = useContext(FinesContext);

    const {
        loading,
        setLoading,
        setLoadingFalse,
        getFines,
        fines,
        getFinesTransactionsData,
        finesTransactionsData,
        createTransaction,
        getTransactions,
        transactions
    } = finesContext;

    async function fetchData() {
        await setLoading();
        await getFines();
        await getFinesTransactionsData();
        await getTransactions();
        await setLoadingFalse();
    }

    const submit = (value, observation, type, file) => {
        let formData = new FormData();

        formData.append('userId', auth.id);
        formData.append('price', value);
        formData.append('type', type);
        formData.append('observation', observation);
        formData.append('File', file);

        createTransaction(formData, fetchData, type);
    };

    useEffect(() => {
        fetchData();
    }, []);

    function TabPanel(props) {
        const { children, value, index } = props;

        return <div>{value === index && children}</div>;
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`
        };
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const tabContext = () => {
        if (value === 0) {
            return (
                <FinesListForm
                    fines={fines}
                    finesTransactionsData={finesTransactionsData}
                />
            );
        }
        if (value === 1) {
            return <CashTransactionsForm transactions={transactions} />;
        }
    };

    const isCashBalanceNegative =
        finesTransactionsData &&
        finesTransactionsData.cashBalance &&
        finesTransactionsData.cashBalance < 0;

    return (
        <React.Fragment>
            <Transition loading={loading} newDesign={true}>
                <HeaderContainer>
                    <Breadcrumb
                        newDesign={true}
                        routes={[
                            {
                                label: t('INVENTORY.library'),
                                to: '/Library'
                            },
                            { label: t('GENERAL.fines') }
                        ]}
                    />
                    <div className="container-headerNewDesign">
                        <h1 className={classes.titleNewDesign}>
                            {t('GENERAL.fines')}
                        </h1>
                    </div>
                </HeaderContainer>

                <div className="containerNewDesign">
                    <Paper className={styles.space}>
                        <div className={styles.finesInfoContainer}>
                            <div className={styles.finesInfoWrapper}>
                                <div className={styles.finesInfoContent}>
                                    <Typography
                                        variant={'RobotoRegular'}
                                        fontSize={10}
                                        textColor={'white'}
                                    >
                                        {t('LIBRARY.cashBalance')}
                                    </Typography>
                                    <Typography
                                        variant={'RobotoBold'}
                                        fontSize={36}
                                        textColor={
                                            isCashBalanceNegative
                                                ? 'red'
                                                : 'white'
                                        }
                                    >
                                        {`${
                                            isCashBalanceNegative ? '-' : ''
                                        } R$ ${
                                            finesTransactionsData &&
                                            finesTransactionsData.cashBalance
                                                ? moneyMask(
                                                      valueToMoneyFloat(
                                                          finesTransactionsData.cashBalance
                                                      )
                                                  )
                                                : '0,00'
                                        }`}
                                    </Typography>
                                </div>
                                <div className={styles.finesInfoContent}>
                                    <Typography
                                        variant={'RobotoRegular'}
                                        fontSize={10}
                                        textColor={'white'}
                                    >
                                        {`${t(
                                            'LIBRARY.pendingMonthPayments'
                                        )} ${
                                            Months[new Date().getMonth()].label
                                        }`}
                                    </Typography>
                                    <Typography
                                        variant={'RobotoBold'}
                                        fontSize={18}
                                        textColor={'white'}
                                    >
                                        {`R$ ${
                                            finesTransactionsData &&
                                            finesTransactionsData.pendingMonthPayments
                                                ? moneyMask(
                                                      valueToMoneyFloat(
                                                          finesTransactionsData.pendingMonthPayments
                                                      )
                                                  )
                                                : '0,00'
                                        }`}
                                    </Typography>
                                </div>
                                <div className={styles.finesInfoContentLast}>
                                    <Typography
                                        variant={'RobotoRegular'}
                                        fontSize={10}
                                        textColor={'white'}
                                    >
                                        {t('LIBRARY.pendingPayments')}
                                    </Typography>
                                    <Typography
                                        variant={'RobotoBold'}
                                        fontSize={18}
                                        textColor={'white'}
                                    >
                                        {`R$ ${
                                            finesTransactionsData &&
                                            finesTransactionsData.pendingPayments
                                                ? moneyMask(
                                                      valueToMoneyFloat(
                                                          finesTransactionsData.pendingPayments
                                                      )
                                                  )
                                                : '0,00'
                                        }`}
                                    </Typography>
                                </div>
                            </div>

                            <div className={styles.creditDebitWrapper}>
                                <div
                                    className={styles.creditContent}
                                    onClick={() => setOpenCreditDialog(true)}
                                >
                                    <img
                                        src={PlusGreen}
                                        alt=""
                                        className={styles.plusMinusImage}
                                    />
                                    <Typography
                                        variant={'RobotoBold'}
                                        fontSize={12}
                                        textColor={'white'}
                                    >
                                        {t('LIBRARY.cashCredit').toUpperCase()}
                                    </Typography>
                                </div>

                                <div
                                    className={styles.creditContent}
                                    onClick={() => setOpenDebitDialog(true)}
                                >
                                    <img
                                        src={MinusRed}
                                        alt=""
                                        className={styles.plusMinusImage}
                                    />
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            width: '100%'
                                        }}
                                    >
                                        <Typography
                                            variant={'RobotoBold'}
                                            fontSize={12}
                                            textColor={'white'}
                                        >
                                            {t(
                                                'LIBRARY.cashDebit'
                                            ).toUpperCase()}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Paper>
                    <Paper>
                        <AppBar
                            position="static"
                            style={{ boxShadow: 'none', transform: 'none' }}
                        >
                            <Tabs
                                selectionFollowsFocus
                                style={{
                                    backgroundColor: '#f5f6fa',
                                    color: 'black'
                                }}
                                indicatorColor="primary"
                                value={value}
                                onChange={handleChange}
                            >
                                <Tab
                                    classes={{
                                        wrapper: styles.iconLabelWrapper2,
                                        labelContainer: styles.labelContainer
                                    }}
                                    style={{ fontWeight: 'bold' }}
                                    label={t(
                                        'LIBRARY.listOfFines'
                                    ).toUpperCase()}
                                    {...a11yProps(0)}
                                />
                                <Tab
                                    classes={{
                                        wrapper: styles.iconLabelWrapper2,
                                        labelContainer: styles.labelContainer
                                    }}
                                    style={{ fontWeight: 'bold' }}
                                    label={t(
                                        'LIBRARY.cashTransactions'
                                    ).toUpperCase()}
                                    {...a11yProps(1)}
                                />
                            </Tabs>
                        </AppBar>

                        {tabContext()}
                    </Paper>
                </div>
                {openDebitDialog && (
                    <DialogDebitCredit
                        open={openDebitDialog}
                        setOpen={setOpenDebitDialog}
                        context={'add'}
                        type={LibraryFinesTypes.DEBIT}
                        submit={submit}
                    />
                )}
                {openCreditDialog && (
                    <DialogDebitCredit
                        open={openCreditDialog}
                        setOpen={setOpenCreditDialog}
                        context={'add'}
                        type={LibraryFinesTypes.CREDIT}
                        submit={submit}
                    />
                )}
                <Footer />
            </Transition>
        </React.Fragment>
    );
};

export default FinesTabPanel;
