import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { useStyles } from "components/layout/CommonStyles";
import React, { useEffect, useState } from "react";

const NewFieldCost = props => {
  const classes = useStyles();
  const { fieldDescription, nameOfClass, id, updateTotalField, costValue } = props;
  const [description, setDescription] = useState(fieldDescription);
  const [value, setValue] = useState(costValue);

  useEffect(() => {
    setDescription(fieldDescription);
  }, [fieldDescription]);

  useEffect(() => {
    setValue(costValue);
  }, [costValue]);

  const contractValues = {
    0: "Intern",
    1: "CLT",
    2: "RPA",
    3: "ScholarshipHolder"
  };

  var name = isNaN(nameOfClass) ? nameOfClass : contractValues[nameOfClass];

  const converTotBrCurrency = number => {
    var temp = "";
    var len = number.length;
    var count = 0;

    for (var i = 0; i < len; i++) {
      if (i === 1) {
        var aux = "," + number[i] + temp;
        temp = aux;
      } else {
        if (count === 3) {
          count = 1;
          var aux = number[i] + "." + temp;
          temp = aux;
        } else {
          if (i > 1) {
            count += 1;
          }
          var aux = number[i] + temp;
          temp = aux;
        }
      }
    }

    return temp;
  };

  const handleFieldChange = (name, contract) => event => {
    if (event.target.value !== " " && !event.target.value.includes(" ")) {
      var str = event.target.value.split("");
      var aux = str.filter(el => el !== "." && el !== ",").join("");
      var value = event.target.value;

      if (event.target.value) {
        value = converTotBrCurrency(
          aux
            .split("")
            .reverse()
            .join("")
        );
      }

      aux = value
        .split("")
        .filter(el => el !== ".")
        .join("")
        .replace(",", ".");
      if (!isNaN(aux)) {
        setValue(value);
      }
    }

    if (name !== "total")
      setTimeout(function() {
        updateTotalField(contract);
      }, 1);
  };

  return (
    <React.Fragment>
      <Grid item xs={3}>
        <div className={name}>
          <div style={{ marginTop: "25px", fontWeight: "bold" }}>
            {description}
          </div>
        </div>
      </Grid>
      <Grid item xs={3}>
        <TextField
          id={id}
          className={classes.textField}
          margin="normal"
          name={description}
          value={value}
          onChange={handleFieldChange(description, nameOfClass)}
          InputProps={{
            startAdornment: <InputAdornment position="start">R$</InputAdornment>
          }}
        />
      </Grid>
    </React.Fragment>
  );
};

export default NewFieldCost;