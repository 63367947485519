import React from "react";
import history from "history.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from '@material-ui/icons/Close';
import Buttons from "components/buttons/Pressed";
import Delete from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";



const DialogCancelOperation = props => {
    const {open, setOpen,  path , exitCallback , dialogTitle , dialogText, dialogConfirmText } = props;
    
    const classes = useStyles();
    const [t] = useTranslation();

    return(
        <Dialog
            open={open}
            aria-labelledby="form-dialog-title"
            msg="mensagem"
            >
            <DialogTitle className={classes.Title}>
                {dialogTitle}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {dialogText}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Buttons
                    onClick={() => { path != null ? history.push(path) : exitCallback()}}
                    tipo="BN-noback"
                    conteudo={t("GENERAL.exitWithoutSaving").toUpperCase()}
                />
                <Buttons
                    onClick={() => {setOpen(false)}}
                    tipo="BN-blue"
                    conteudo={ dialogConfirmText ? dialogConfirmText.toUpperCase() : t("GENERAL.comeBack").toUpperCase()}
                />
            </DialogActions>
        </Dialog>
    )
};

export default DialogCancelOperation;