import * as React from 'react';
import InfoIcon from '@material-ui/icons/Info';
import Typography from 'components/typography/Typography';
import { infoStyles } from './Styles';

const BlueInfoText = ({ text, fontSize, ...props }) => {
    const styles = infoStyles();

    return (
        <div className={styles.infoContainer}>
            <InfoIcon
                style={{
                    marginRight: '0.2rem',
                    color: '#2C3E51'
                }}
                fontSize="md"
            />

            <Typography
                variant={'RobotoBold'}
                fontSize={fontSize}
                textColor={'primary'}
            >
                {text}
            </Typography>
        </div>
    );
};

export default BlueInfoText;
