import React from "react";
import "../../../../App.css";
import SideBar from "components/layout/sidebar/Sidebar";
import DashboardNavBar from "components/layout/DashboardNavBar";
import CollaboratorsTabPanel from "./CollaboratorsTabPanel";


const CallViewCollaborators = ({ match }) => {
    return (
        <div>
            <DashboardNavBar />
            <SideBar />
            <CollaboratorsTabPanel userId={match.params.id} context={"view"} />

        </div>
    );
}

export default CallViewCollaborators;