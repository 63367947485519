export const humanResourceNotifications = [
    {
        event: 'CLT_PENDINGS_EMAIL',
        allowSend: false,
        isManager: false,
        automatic: false
    },
    {
        event: 'CREATED_FINGERPRINT',
        allowSend: true,
        automatic: true
    },
    {
        event: 'DELETE_FINGERPRINT',
        allowSend: true,
        automatic: true
    },
    {
        event: 'ADMIN_NEW_COLLABORATOR_ENABLED_EMAIL_OPTIONAL',
        allowSend: false,
        automatic: false
    },
    {
        event: 'ADMIN_COLLABORATOR_FIRED_EMAIL_OPTIONAL',
        allowSend: false,
        automatic: false
    },
    {
        event: 'HR_COLLABORATOR_WELCOME_AUTOMATIC',
        allowSend: true,
        automatic: true
    },
    {
        event: 'HR_COLLABORATOR_TEMPORARY_PASSWORD_AUTOMATIC',
        allowSend: true,
        automatic: true
    },
    {
        event: 'HR_CLOCK_RECORD_INCONSISTENCY_TOAPPROVE_AUTOMATIC',
        allowSend: true,
        automatic: true
    },
    {
        event: 'HR_CLOCK_RECORD_INCONSISTENCY_APPROVED_AUTOMATIC',
        allowSend: true,
        automatic: true
    },
    {
        event: 'HR_CLOCK_RECORD_INCONSISTENCY_REFUSED_AUTOMATIC',
        allowSend: true,
        automatic: true
    },
    {
        event: 'HR_CLOCK_RECORD_INCONSISTENCY_UPDATED_AUTOMATIC',
        allowSend: true,
        automatic: true
    },
    {
        event: 'HR_CLOCK_RECORD_INCONSISTENCY_PARTIAL_TOAPPROVE_AUTOMATIC',
        allowSend: true,
        automatic: true
    },
    {
        event: 'HR_CLOCK_RECORD_INCONSISTENCY_PARTIAL_APPROVED_AUTOMATIC',
        allowSend: true,
        automatic: true
    },
    {
        event: 'HR_CLOCK_RECORD_INCONSISTENCY_PARTIAL_REFUSED_AUTOMATIC',
        allowSend: true,
        automatic: true
    },
    {
        event: 'HR_CLOCK_RECORD_INCONSISTENCY_PARTIAL_UPDATED_AUTOMATIC',
        allowSend: true,
        automatic: true
    },
    {
        event: 'HR_CLOCK_RECORD_INCONSISTENCY_TOTAL_TOAPPROVE_AUTOMATIC',
        allowSend: true,
        automatic: true
    },
    {
        event: 'HR_CLOCK_RECORD_INCONSISTENCY_TOTAL_APPROVED_AUTOMATIC',
        allowSend: true,
        automatic: true
    },
    {
        event: 'HR_CLOCK_RECORD_INCONSISTENCY_TOTAL_REFUSED_AUTOMATIC',
        allowSend: true,
        automatic: true
    },
    {
        event: 'HR_CLOCK_RECORD_INCONSISTENCY_TOTAL_UPDATED_AUTOMATIC',
        allowSend: true,
        automatic: true
    }
];
