import React, { useState, useEffect } from 'react';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContentWrapper from 'components/layout/Message';
import { snackbarStyles } from './Styles';
import { KeyChange } from 'mdi-material-ui';

const Toast = ({ message, key, onExited }) => {
    const styles = snackbarStyles();

    const [open, setOpen] = useState(false);

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        setOpen(true);
    }, []);

    return (
        <Snackbar
            key={key}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={open}
            onClose={handleSnackClose}
            TransitionComponent={Slide}
            transitionDuration={{ enter: 500, exit: 500 }}
            TransitionProps={{ onExited }}
            ContentProps={{
                'aria-describedby': 'message-id'
            }}
            autoHideDuration={3500}
        >
            <MySnackbarContentWrapper
                onClose={handleSnackClose}
                variant={message.kind}
                message={message.message}
            />
        </Snackbar>
    );
};

export default Toast;
