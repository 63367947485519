import React, { useState, useEffect, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Buttons from "components/buttons/Pressed";
import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";
import Input from "components/inputs/Input";
import { Redirect } from "react-router-dom";
import { dateToBR } from "utils/dates/DateToBRr";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Button } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

const DialogAlterUseEquipment = (props) => {
    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }
    const [itemArray, setItemArray] = useState([]);
    const [requiredFieldMachine, setRequiredFieldMachine] = useState(false);
    const [requiredFieldLicense, setRequiredFieldLicense] = useState(false);

    const [t] = useTranslation();
    const classes = useStyles();
    const {
        open,
        setOpen,
        item,
        usersArray,
        externalUsersArray,
        placesArray,
        statusArray,
        itensArray,
        requisitions,
        availableLicensesArray
    } = props;

    const [values, setValues] = useState({
        alterResponsible: "",
        newResponsibleName: "",
        newPlace: "",
        newPlaceName: "",
        radioNewStatus: "AVAILABLE",
        radioResponsibleType: "INTERNAL",
        radioReturn: "YES",
        unavaliableStatusId: "",
        unavaliableStatusName: "",
        itemId: "",
        itemName: "",
        code: "",
        serialNumber: "",
        licenseId: "",
        machineId: "",
        machineName: "",
        keyOrEmail: ""
    });

    const clear = () => {
        setValues({
            ...values,
            alterResponsible: "",
            newResponsibleName: "",
            newPlace: "",
            newPlaceName: "",
            radioNewStatus: "AVAILABLE",
            radioResponsibleType: "INTERNAL",
            radioReturn: "YES",
            unavaliableStatusId: "",
            unavaliableStatusName: "",
            itemId: "",
            itemName: "",
            code: "",
            serialNumber: "",
            licenseId: "",
            machineId: "",
            machineName: "",
            keyOrEmail: ""
        });
    }

    const handleClose = () => {
        setOpen({ open: false, obj: [] });
        clear();
    };

    const verifyCanSubmit = () => {
        let res = true;
        if (!values.machineId) {
            res = false;
            setRequiredFieldMachine(true);
        }
        if (!values.licenseId) {
            res = false;
            setRequiredFieldLicense(true);
        }
        return res;
    }
    const handleSubmit = () => {
        const canSubmit = verifyCanSubmit();
        if (canSubmit) {
            props.setUseSoftware(values)
            setOpen({ open: false, obj: [] });
            clear();
        }
    };

    useEffect(() => {
        const itens = [...itensArray];
        //sortElement(itens);

        var aux = {};
        var _itens = [];
        for (var i = 0; i < itens.length; i++) {
            var item = itens[i];
            aux.value = item.id;
            aux.label = item.name;
            aux.code = getCodeListUse(item);
            aux.serialNumber = item.serialNumber;
            aux.originPlace = item.place;
            _itens.push(aux);
            aux = {};
        }

        setItemArray(_itens);
    }, [itensArray]);

    const handleChange = (name, value) => {
        if (name === "alterResponsible") {
            setValues({ ...values, [name]: value, newResponsibleName: usersArray.filter(ele => String(ele.value) === String(value))[0].label });
        }
        else if (name === "itemId") {
            let itemObj = itemArray.filter(ele => String(ele.value) === String(value))[0]
            setValues({ ...values, [name]: value, itemName: itemObj.label, code: itemObj.code, serialNumber: itemObj.serialNumber });
        }
        else if (name === "machineId") {
            setValues({ ...values, [name]: value, machineName: itemArray.filter(ele => String(ele.value) === String(value))[0].label });
            setRequiredFieldMachine(false);
        }
        else if (name === "licenseId") {
            var itemObj = availableLicensesArray.filter(ele => String(ele.value) === String(value))[0]
            setValues({ ...values, [name]: value, code: itemObj.code, keyOrEmail: itemObj.keyOrEmail });
            setRequiredFieldLicense(false);
        }
        else if (name === "alterResponsible" && values.radioResponsibleType === "EXTERNAL") {
            setValues({ ...values, [name]: value, newResponsibleName: externalUsersArray.filter(ele => String(ele.value) === String(value))[0].label });
        }
        else if (name === "unavaliableStatusId") {
            setValues({ ...values, [name]: value, unavaliableStatusName: statusArray.filter(ele => String(ele.value) === String(value))[0].label });
        }
        else {
            setValues({ ...values, [name]: value });
        }
    };

    function getCodeListUse(row) {
        const response = [];
        if (row["lsbdCode"])
            response.push(row["lsbdCode"]);
        if (row["fcpcCode"])
            response.push(row["fcpcCode"]);

        return response;
    }

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                aria-labelledby="form-dialog-title"
                msg="mensagem"
            >
                <DialogTitle className={classes.actionsTitle}>
                    <span style={{ fontWeight: "bold" }}>
                        {t("INVENTORY.assignSoftware")}
                    </span>
                    <IconButton
                        style={{ float: "right", marginTop: -10, marginRight: -17 }}
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Grid container >
                            <Grid item xs={12} sm={12}>
                                <b>{t("INVENTORY.software").toUpperCase()}</b>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                {item && item.software}
                            </Grid>
                        </Grid>
                        <Grid container style={{ paddingTop: "20px" }}>
                            <Grid item xs={12} sm={12}>
                                <b>{t("INVENTORY.category").toUpperCase()}</b>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                {item && item.category}
                            </Grid>
                        </Grid>
                        <Grid container style={{ paddingTop: "20px" }}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    select
                                    value={values.licenseId}
                                    error={requiredFieldLicense}
                                    helperText={requiredFieldLicense && t("GENERAL.requiredField")}
                                    className={classes.textField}
                                    label={t("INVENTORY.keyOrEmail")}
                                    onChange={(ev) => { handleChange("licenseId", ev.target.value) }}
                                    margin="normal"
                                >
                                    {availableLicensesArray && availableLicensesArray.map(item => (
                                        <MenuItem key={item.value} value={item.value}>
                                            Código:{item.code}
                                            <br />
                                            Chave ou email de ativação da licença:{item.keyOrEmail}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                        <Input
                            isSelect={true}
                            error={requiredFieldMachine}
                            helperText={t("GENERAL.requiredField")}
                            value={values.machineId}
                            valueName={"machineId"}
                            label={t("INVENTORY.machineToInstalation") + "*"}
                            margin="normal"
                            maxLength={200}
                            handleChange={handleChange}
                            arraySelected={itemArray}
                        />
                        <Grid container style={{ paddingTop: "20px" }}>
                            <Grid item xs={12} sm={12}>
                                <b>{t("INVENTORY.newStatus").toUpperCase()}</b>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <RadioGroup row aria-label="position"
                                    name="position"
                                    defaultValue="top"
                                    value={values.radioNewStatus}
                                    onChange={(ev) => setValues({ ...values, radioNewStatus: ev.target.value })}                                    >
                                    <FormControlLabel
                                        value="AVAILABLE"
                                        control={<Radio color="primary" />}
                                        label="Disponível"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="UNAVAILABLE"
                                        control={<Radio color="primary" />}
                                        label="Indisponível"
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Buttons
                        onClick={handleClose}
                        tipo="BN-noback"
                        conteudo={t("GENERAL.cancel").toLocaleUpperCase()}
                    />
                    <Buttons
                        onClick={handleSubmit}
                        tipo="BN-blue"
                        conteudo={t("GENERAL.alter").toLocaleUpperCase()}
                    />
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default DialogAlterUseEquipment;