import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogUnit from 'components/dialogs/DialogUnit';
import DialogUnitWithIcon from 'components/dialogs/DialogUnitWithIcon';
import { Grid, Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation, Trans } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import Buttons from 'components/buttons/Pressed';
import Typography from 'components/typography/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import Avatar from '@material-ui/core/Avatar';

import { useMutationPayBills } from 'hooks/DataFetching/Quotas/Dashboard/useMutationPayBills';

import { FormatDateToFront } from 'utils/dates/FormatDateToFront';
import { GetMonthAndYearInWords } from 'utils/dates/GetMonthAnsYearInWords';
import { dateToBRWithHours } from 'utils/dates/DateToBrWithHours';
import { valueToMoneyFloat } from 'utils/general/valueToMoneyFloat';
import { moneyMask } from 'utils/masks/moneyMask';

import { translateBillStatus, BillStatus } from '../ScreenUtils';
import { screenStyles } from '../Styles';

const DialogViewBill = props => {
    const styles = screenStyles();
    const [t] = useTranslation();

    const { open, setOpen, viewValues } = props;

    const mutation = useMutationPayBills(setOpen);

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        mutation.mutate(viewValues.id);
    };

    const isOperatorDifferentFromUser =
        viewValues.user &&
        viewValues.operator &&
        viewValues.user.id !== viewValues.operator.id;

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            maxWidth={'sm'}
            fullWidth
        >
            <DialogTitle>
                <Grid container item spacing={2} xs={12}>
                    <Grid item xs={11}>
                        <Typography
                            variant={'RobotoBold'}
                            fontSize={24}
                            textColor={'secondary'}
                        >
                            {`${t('GENERAL.view')} ${t('QUOTAS.bill')}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            color="#6D6F71"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent>
                <Grid container item spacing={1} xs={12}>
                    <Grid item xs={12}>
                        <Box
                            display={'flex'}
                            alignItems={'center'}
                            minHeight="100%"
                        >
                            <InfoIcon
                                color="primary"
                                style={{ marginRight: '8px' }}
                            />

                            <Typography
                                variant={'RobotoRegular'}
                                fontSize={14}
                                textColor={'primary'}
                            >
                                <Trans
                                    i18nKey="QUOTAS.viewBillDialogPendingHeaderTextFirstPart"
                                    values={{
                                        date: GetMonthAndYearInWords(
                                            FormatDateToFront(viewValues.date),
                                            2
                                        )
                                    }}
                                    components={[<b />]}
                                />
                                {`${
                                    viewValues.status === BillStatus.PENDING ||
                                    viewValues.status === BillStatus.LATE
                                        ? t(
                                              'QUOTAS.viewBillDialogPendingHeaderTextSecondPart'
                                          )
                                        : ''
                                }`}
                            </Typography>
                        </Box>
                    </Grid>

                    <Box margin={'12px'}></Box>

                    <Grid item xs={12}>
                        <DialogUnitWithIcon
                            head={t('GENERAL.collaborator').toUpperCase()}
                            Icon={
                                <Avatar
                                    className={styles.dialogViewBillAvatar}
                                    alt={viewValues.user.shortname}
                                    src={
                                        process.env.REACT_APP_IMGSERVER +
                                        viewValues.user.imageName
                                    }
                                />
                            }
                            content={viewValues.user.name}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <DialogUnit
                            head={t('QUOTAS.referenceMonth').toUpperCase()}
                            content={GetMonthAndYearInWords(
                                FormatDateToFront(viewValues.date)
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DialogUnit
                            head={t('GENERAL.id').toUpperCase()}
                            content={viewValues.id}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <DialogUnit
                            head={t('GENERAL.value').toUpperCase()}
                            content={
                                <Box
                                    color={
                                        viewValues.status === BillStatus.PAID
                                            ? '#27AE60'
                                            : viewValues.status ===
                                              BillStatus.LATE
                                            ? '#F3222F'
                                            : null
                                    }
                                    fontWeight="700"
                                >
                                    {`R$
                                ${moneyMask(
                                    valueToMoneyFloat(viewValues.value)
                                )}
                                `}
                                </Box>
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DialogUnitWithIcon
                            head={t('GENERAL.status').toUpperCase()}
                            Icon={
                                viewValues.status === BillStatus.PAID ? (
                                    <CheckCircleIcon
                                        style={{
                                            color: '#7FE46B'
                                        }}
                                        fontSize="md"
                                    />
                                ) : viewValues.status === BillStatus.PENDING ? (
                                    <ErrorIcon
                                        style={{
                                            color: '#FFD200'
                                        }}
                                        fontSize="md"
                                    />
                                ) : viewValues.status === BillStatus.LATE ? (
                                    <ErrorIcon
                                        style={{
                                            color: '#F3222F'
                                        }}
                                        fontSize="md"
                                    />
                                ) : (
                                    <InfoIcon
                                        style={{
                                            color: '#6D6F71'
                                        }}
                                        fontSize="md"
                                    />
                                )
                            }
                            content={translateBillStatus(viewValues.status)}
                        />
                    </Grid>
                    {viewValues.status === BillStatus.PAID && (
                        <Grid item xs={12}>
                            <DialogUnit
                                head={t(
                                    'QUOTAS.verificationCode'
                                ).toUpperCase()}
                                content={viewValues.verificationCode}
                            />
                        </Grid>
                    )}

                    {isOperatorDifferentFromUser ? (
                        <>
                            <Grid item xs={6}>
                                <DialogUnitWithIcon
                                    head={t('GENERAL.operator').toUpperCase()}
                                    Icon={
                                        <Avatar
                                            className={
                                                styles.dialogViewBillAvatar
                                            }
                                            alt={viewValues.operator.shortname}
                                            src={
                                                process.env
                                                    .REACT_APP_IMGSERVER +
                                                viewValues.operator.imageName
                                            }
                                        />
                                    }
                                    content={viewValues.operator.name}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <DialogUnit
                                    head={t(
                                        'GENERAL.operationDate'
                                    ).toUpperCase()}
                                    content={dateToBRWithHours(
                                        FormatDateToFront(viewValues.closedAt)
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <DialogUnit
                                    head={t('GENERAL.reason').toUpperCase()}
                                    content={viewValues.justification}
                                />
                            </Grid>
                        </>
                    ) : null}
                </Grid>
            </DialogContent>

            <DialogActions>
                <Buttons
                    onClick={handleClose}
                    tipo={
                        viewValues.status === BillStatus.PENDING ||
                        viewValues.status === BillStatus.LATE
                            ? 'BN-noback'
                            : 'BN-blue'
                    }
                    conteudo={t('GENERAL.close').toUpperCase()}
                />
                {(viewValues.status === BillStatus.PENDING ||
                    viewValues.status === BillStatus.LATE) && (
                    <Buttons
                        isLoading={mutation.isLoading}
                        onClick={handleSubmit}
                        tipo="BN-blue"
                        conteudo={t('GENERAL.pay').toUpperCase()}
                    />
                )}
            </DialogActions>
        </Dialog>
    );
};

export default DialogViewBill;
