import { makeStyles } from "@material-ui/core/styles";

import BirthdaysImg from "assets/Aniversariantes.png"
// 'Concert One', 'JetBrains Mono'

export const screenStyles = makeStyles(theme => ({
    container: {
      position: "relative",
      display: 'flex'
    },
    printContainer: {
      margin: 0,
      border: 0,
      padding: 0,
      position: 'relative',
      width: "100vw",
      height: "100vh"
    },
    buttonWrapper: {
      zIndex: 1,
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },
    printableWrapper:{
      width: "100%",
    },
    printableImg:{
      position: "absolute",
      zIndex: -1,
      width: '100%',
      height: 'auto',
      border: 0,
      padding: 0,
      maxHeight: '100vh',
      backgroundImage: `url(${BirthdaysImg})`,
      backgroundPosition: 'center center',
      backgroundSize: "contain"
    },
    printableTextWrapper:{
      zIndex: -1,
      position: 'absolute',
      top: '4%',
      left: '55%',
      margin: 0
    },
    printableTitle: {
      margin: 0,
      fontFamily: "Concert One",
      whiteSpace: 'nowrap',
      fontSize: '40px',
      color: 'white',
    },
    printableText: {
      fontFamily: "JetBrains Mono",
      whiteSpace: 'nowrap',
      fontSize: '16px',
      color: 'white',
    }
  })
)  