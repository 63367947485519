import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import EventIcon from '@material-ui/icons/Event';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useStyles } from "components/layout/CommonStyles";
import { useTranslation } from "react-i18next";
import CloseIcon from '@material-ui/icons/Close';
import Buttons from "components/buttons/Pressed";
import Grid from "@material-ui/core/Grid";
import Input from "components/inputs/Input";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { dateValidation } from "../../../../../utils/validation/DateValidation";
import { dateToBR } from "utils/dates/DateToBRr";


const DialogAdmissionDate = props => {
    const classes = useStyles();
    const [t] = useTranslation();

    const { open, setOpen, context, admissionDate, justification, oldAdmissionDate, newAdmissionDate, userId, setParentValues,setHasChange } = props;

    const [values, setValues] = useState({
        admissionDate: null,
        justification: "",
    });

    const [requiredAdmissionDate, setRequiredAdmissionDate] = useState(false);
    const [invalidAdmissionDate, setInvalidAdmissionDate] = useState(false);


    useEffect(() => {
        setValues({
            ...values,
            admissionDate: admissionDate ? admissionDate : null,
            justification: justification ? justification : "",
        });
    }, [admissionDate, justification]);

    const clearDialog = () => {
        setRequiredAdmissionDate(false);
        setInvalidAdmissionDate(false);
        setValues({
            ...values,
            admissionDate: admissionDate ? admissionDate : null,
            justification: justification ? justification : "",
        });
    }

    const handleClose = () => {
        clearDialog();
        setOpen(false);
    }

    const handleChange = name => e => {
        setHasChange(true);
        setRequiredAdmissionDate(false);
        setInvalidAdmissionDate(false);
        setValues({ ...values, [name]: e })
    }

    const handleJustification = (name, value) => {
        setValues({ ...values, [name]: value })
    }

    const verifySubmit = () => {
        let res = true;
        if (values.admissionDate === null) {
            res = false;
            setRequiredAdmissionDate(true);
        }
        if (!dateValidation(values.admissionDate)) {
            res = false;
            setInvalidAdmissionDate(true)
        }
        return res;
    }

    const handleSubmit = () => {
        const canSubmit = verifySubmit();
        if (canSubmit) {
            setParentValues(old => ({
                ...old,
                admissionDate: values.admissionDate,
                admissionDateJustification: values.justification
            }));
            setOpen(false);
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle className={classes.Title}>
                <b>{context === "view" ? t("GENERAL.view") + " " + t("HR.admissionDate") : t("HR.editAdmissionDate")}</b>
                <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container item spacing={2} md={12} lg={12}>
                    <Grid item xs={12} sm={12}>
                        <Input
                            disabled
                            label={"HR.oldAdmissionDate"}
                            value={oldAdmissionDate ? dateToBR(new Date(oldAdmissionDate)) : ""}
                            valueName={"oldAdmissionDate"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton disabled>
                                            <EventIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        {context === "view" ?
                            <Input
                                disabled
                                required
                                label={"HR.newAdmissionDate"}
                                value={newAdmissionDate ? dateToBR(new Date(newAdmissionDate)) : ""}
                                valueName={"newAdmissionDate"}
                            /> :
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disabled={context === "view"}
                                    className={classes.identificationDatePicker}
                                    error={requiredAdmissionDate || invalidAdmissionDate}
                                    helperText={requiredAdmissionDate ? t("GENERAL.requiredField") : invalidAdmissionDate ? t("GENERAL.invalidDate") : ""}
                                    disableToolbar
                                    autoOk={true}
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    id="validity"
                                    margin="normal"
                                    invalidDateMessage=""
                                    label={t("HR.newAdmissionDate") + "*"}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date"
                                    }}
                                    value={values.admissionDate}
                                    onChange={handleChange("admissionDate")}
                                />
                            </MuiPickersUtilsProvider>
                        }
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Input
                            context={context}
                            label={"GENERAL.justification"}
                            value={values.justification}
                            valueName={"justification"}
                            rows={5}
                            maxLength={200}
                            handleChange={handleJustification}
                        />
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Buttons
                    onClick={handleClose}
                    tipo="BN-noback"
                    conteudo={context === "view" ? t("GENERAL.close").toUpperCase() :t("GENERAL.cancel").toUpperCase()}
                />
                {context !== "view" ?
                    <Buttons
                        onClick={handleSubmit}
                        tipo="BN-blue"
                        conteudo={t("GENERAL.update").toUpperCase()}
                    /> : null}
            </DialogActions>
        </Dialog>
    )
}

export default DialogAdmissionDate;
