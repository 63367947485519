import React from 'react';
import { screenStyles } from './Styles';
import Typography from 'components/typography/Typography';
import InfoTooltip from 'components/infoTooltip/InfoTooltip';

const DialogUnit = props => {
    const { head, content, marginTop, tooltipContent } = props;
    const styles = screenStyles();

    return (
        <>
            <div className={styles.dialogUnitComponent}>
                <div className={styles.tooltipWrapper}>
                    <Typography
                        variant={'RobotoBold'}
                        fontSize={14}
                        textColor={'secondary'}
                    >
                        {head}
                    </Typography>
                    {tooltipContent ? (
                        <InfoTooltip
                            text={
                                <Typography
                                    variant={'RobotoRegular'}
                                    fontSize={12}
                                    textColor={'white'}
                                >
                                    {tooltipContent}
                                </Typography>
                            }
                            fontSize={18}
                        />
                    ) : null}
                </div>
                <div style={{ marginTop: marginTop ? marginTop : '8px' }}>
                    <Typography
                        // className={styles.dialogMoreDetailsText}
                        variant={'RobotoRegular'}
                        fontSize={14}
                        textColor={'secondary'}
                    >
                        {content}
                    </Typography>
                </div>
            </div>
        </>
    );
};

export default DialogUnit;
