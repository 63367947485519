import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Spinner from "components/layout/spinners/Index";
import HRCostsContext from "context/hrcosts/hrCostsContext";
import ProjectsContext from "context/projects/projectsContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import "components/profiles/css/FormStyle.css";
import { useStyles } from "components/layout/CommonStyles";
import Footer from "components/layout/Footer";
import InputAdornment from "@material-ui/core/InputAdornment";
import ArrowBack from "@material-ui/icons/ArrowBack";
import {
  DatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import InsertInvitationIcon from "@material-ui/icons/InsertInvitation";
import MomentUtils from "@date-io/moment";
import "moment/locale/pt-br";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line
} from "recharts";
import domtoimage from "dom-to-image";
import fileDownload from "js-file-download";
import FeedbackTable from "components/execution/Participants/FeedbackTable";

const ViewParticipant = props => {
  const [selectedDate, handleDateChange] = useState(new Date());
  const classes = useStyles();
  const [t] = useTranslation();
  const { id, projectId, mode, participationId, participantMode } = props;
  const hrCostsContext = useContext(HRCostsContext);
  const projectsContext = useContext(ProjectsContext);
  const {
    user,
    userCosts,
    getUser,
    getUserCosts,
    loading,
  } = hrCostsContext;
  const {
    project,
    getProject,
    participantFeedback,
    getParticipantFeedback,
  } = projectsContext;
  var data = [];
  const [chart, setChart] = useState("");
  const [state, setState] = useState({
    name: "",
    cpf: "",
    annualCost: "",
    imagePath: ""
  });

  const monthDict = {
    1: "Jan",
    2: "Fev",
    3: "Mar",
    4: "Abr",
    5: "Mai",
    6: "Jun",
    7: "Jul",
    8: "Ago",
    9: "Set",
    10: "Out",
    11: "Nov",
    12: "Dez"
  };

  const contractDict = {
    "Intern": "Estagiário",
    "CLT": "CLT",
    "RPA": "RPA",
    "ScholarshipHolder": "Bolsista"
  };

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    if (projectId && id && participationId) {
      getProject(projectId);
    }
  }, []);

  useEffect(() => {
    getUser(id);
    getUserCosts(id);
    getParticipantFeedback(participationId);
    var year = 0;

    if (selectedDate._isAMomentObject)
      year = selectedDate._d.getFullYear();
    else
      year = selectedDate.getFullYear();

    var costs = userCosts.filter(el => el.costYear === year && el.costName !== "TotalCost");
    var annualCost = costs.reduce(function (total, el) { return total + el.cost; }, 0);

    for (var i = 0; i < 12; i++) {
      var monthCost = 0;
      var contractType = "-";
      var costsMonth = costs.filter(el => el.costMonth === (i + 1));

      if (costsMonth.length > 0) {
        monthCost = costsMonth.reduce(function (total, el) { return total + el.cost; }, 0);
        contractType = contractDict[costsMonth[0].contractType];
      }

      var aux = {};
      aux.name = monthDict[(i + 1)];
      aux.k = monthCost;
      aux.contractType = contractType;
      data.push(aux);
    }

    setState({
      ...state,
      name: user.name,
      cpf: user.cpf,
      annualCost: setBrazilCurrency(annualCost),
      imagePath: user.imagePath
    });

    createChart();

  }, [user.imagePath, Object.keys(userCosts).length, selectedDate, windowDimensions.width, windowDimensions.height]);

  const converTotBrCurrency = number => {
    var temp = "";
    var len = number.length;
    var count = 0;

    for (var i = 0; i < len; i++) {
      if (i === 1) {
        let aux = "," + number[i] + temp;
        temp = aux;
      } else {
        if (count === 3) {
          count = 1;
          let aux = number[i] + "." + temp;
          temp = aux;
        } else {
          if (i > 1) {
            count += 1;
          }
          let aux = number[i] + temp;
          temp = aux;
        }
      }
    }

    return temp;
  };

  const setBrazilCurrency = cost => {
    cost = cost.toString().split("");

    if (
      (cost[cost.length - 1] === "0" && cost[cost.length - 2] === "0") ||
      cost[cost.length - 3] !== "."
    ) {
      if (cost[cost.length - 2] === ".") {
        cost.push("0");
      } else {
        cost.push("0");
        cost.push("0");
      }
    }

    cost = cost
      .filter(el => el !== "." && el !== ",")
      .join("")
      .split("")
      .reverse()
      .join("");

    return (converTotBrCurrency(cost));
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label}/${selectedDate._isAMomentObject ? selectedDate._d.getFullYear() : selectedDate.getFullYear()}`}</p>
          <p className="intro">{`Tipo de Regime: ${payload[0].payload.contractType}`}</p>
          <p className="desc">{`Custo Mensal: R$ ${setBrazilCurrency(payload[0].value)}`}</p>
        </div>
      );
    }

    return null;
  };

  const transitions = useTransition(loading, null, {
    from: { opacity: 0 },
    enter: {
      opacity: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "calc(100vh - 80px)"
    },
    leave: { opacity: 0 }
  });


  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  };

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  const exportGraphic = () => {
    domtoimage
      .toBlob(document.getElementById("node-to-convert"))
      .then(function (blob) {
        fileDownload(blob, "resumo-de-custos.png");
      });
  };

  const createChart = () => {
    setChart(
      <Paper className={classes.root}>
        <div className={classes.header}>
          {t("PARTICIPANTS.titleResume").toUpperCase()}
          <div className={classes.addendumIntermIcons}>
            <div className={classes.addendumIntermIconsMarginRight}>
              {/* <Tooltip2
                title={t("PARTICIPANTS.exportChart")}
                placement="top"
              >
                <div>
                  <IconButton onClick={exportGraphic}>
                    <GetApp />
                  </IconButton>
                </div>
              </Tooltip2> */}
            </div>
          </div>
        </div>
        <div></div>
        <Grid item xs={12}>
          <br />
          <div id="node-to-convert">
            <LineChart
              width={windowDimensions.width - 250}
              height={550}
              data={data}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Line
                type="monotone"
                dataKey="k"
                stroke="#73b3ff"
                strokeWidth={2}
              />
            </LineChart>
          </div>
        </Grid>
        <div className={classes.bodyForm}></div>
      </Paper>
    );
  };

  return transitions.map(({ item, key, props }) =>
    item ? (
      <animated.div key={key} style={props}>
        <Spinner />
      </animated.div>
    ) : (
        <animated.div key={key} style={props}>
          <div className="container">
            <Breadcrumbs
              className={classes.breadCrumbs}
              aria-label="breadcrumb"
              separator={<NavigateNextIcon fontSize="small" />}
            >
              <Typography>{t("PARTICIPANTS.labor")}</Typography>
              <Link className={classes.link} to="/execution/projects/">
                {t("PROJECTS.projects")}
              </Link>
              <Link className={classes.link} to={`/execution/projects/${projectId}/${mode}`}>
                {t("PROJECTS.project")}
              </Link>
              <Typography>{t("PARTICIPANTS.participant")}</Typography>
            </Breadcrumbs>
            <div className="container-header">
              <div className="title">
                <h1 className={classes.title}>{t("PARTICIPANTS.participant")}</h1>
              </div>
              <Link to={`/execution/projects/${projectId}/${mode}`}>
                <Button
                  className={classes.blueButton}
                  variant="contained"
                  color="primary"
                >
                  <div className={classes.buttonIcon}>
                    <ArrowBack />
                  </div>
                  {t("GENERAL.back").toUpperCase()}
                </Button>
              </Link>
            </div>
            <form id="formUsers">
              <Paper className={classes.root}>
                <div className={classes.header}>
                  {t("PARTICIPANTS.panticipantInformation").toUpperCase()}
                </div>
                <div className={classes.bodyForm}>
                  <Grid container>
                    <Hidden mdDown>
                      <Grid item lg={2}>
                        <div className={classes.avatarParent}>
                          <div
                            className="avatar"
                            style={{
                              backgroundImage: `url(${
                                state.imagePath ?
                                  state.imagePath
                                  : window.location.origin + "/images/users/icon-default.png"
                                })`
                            }}
                          ></div>
                        </div>
                      </Grid>
                    </Hidden>
                    <Grid container item spacing={2} md={12} lg={10}>
                      <Grid item xs={4}>
                        <TextField
                          disabled
                          className={classes.textField}
                          margin="normal"
                          id="name"
                          label={t("PARTICIPANTS.participantName")}
                          name="name"
                          value={state.name}
                          autoFocus
                          InputProps={{
                            readOnly: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          disabled
                          className={classes.textField}
                          margin="normal"
                          id="cpf"
                          label="CPF"
                          name="CPF"
                          value={state.cpf}
                          autoFocus
                          InputProps={{
                            readOnly: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          disabled
                          className={classes.textField}
                          margin="normal"
                          id="annualCost"
                          label={t("PARTICIPANTS.annualCost")}
                          name="annualCost"
                          value={"R$ " + state.annualCost}
                          autoFocus
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <MuiPickersUtilsProvider locale="pt" utils={MomentUtils}>
                          <DatePicker
                            id="yearBase"
                            variant="inline"
                            openTo="year"
                            name="yearBase"
                            views={["year"]}
                            label={t("PROJECTS.baseYear")}
                            margin="normal"
                            autoOk={true}
                            value={selectedDate}
                            onChange={handleDateChange}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <InsertInvitationIcon color="action" />
                                </InputAdornment>
                              )
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </form>
            <br />
            <br />
            <FeedbackTable
              feedback={participantFeedback}
              mode={mode}
              participationId={participationId}
              userId={id}
              projectId={projectId}
              participantMode={participantMode}
              project={project}
            />
            <br />
            <br />
            {chart}
          </div>
          <Footer />
        </animated.div>
      )
  );
};

export default ViewParticipant;
