export function toCamelCase(str) {
    let newStr = '';
    if (str) {
		const toLower = str.toLowerCase();
        let wordArr = toLower.split(/[-_]/g);
        for (let i in wordArr) {
            if (i > 0) {
                newStr +=
                    wordArr[i].charAt(0).toUpperCase() + wordArr[i].slice(1);
            } else {
                newStr += wordArr[i];
            }
        }
    } else {
        return newStr;
    }
    return newStr;
}
