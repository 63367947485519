/* eslint-disable */
import Breadcrumb from "components/layout/breadcrumb/Breadcrumb";
import Paper from "@material-ui/core/Paper";
import Footer from "components/layout/Footer";
import Spinner from "components/layout/spinners/Index";
import InventoryContext from "context/inventory/inventoryContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { animated, useTransition } from "react-spring";
import { useStyles } from "../../../components/layout/CommonStyles";
import Grid from "@material-ui/core/Grid";
import Input from "components/inputs/Input";
import DialogReqApprove from "./DialogReqExecute";
import DialogAlterReturnEquipment from "./DialogAlterOneReturnEquipment";
import DialogAlterReturnManyEquipment from "./DialogAlterManyReturnEquipment";
import DialogAlterUseEquipment from "./DialogAlterUseEquipment";
import DialogAlterReturnSoftware from "./DialogAlterReturnSoftware";
import DialogAlterUseSoftware from "./DialogAlterUseSoftware";
import Buttons from "components/buttons/Pressed";
import ItensReqTable from "./ItensReqTable";
import SotwareReqTable from "./LicensesReqTable";
import CheckIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import Button from "@material-ui/core/Button";
import UsersContext from "context/users/usersContext";
import ExternalUsersContext from "context/externalUsers/externalUsersContext";
import Edit from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";


const FormRequisition = (props) => {
  var SortElementSelect = require("../../../utils/sort/SortElementSelect").SortElementSelect;
  var dateToBRWithHours = require("../../../utils/dates/DateToBrWithHours").dateToBRWithHours;
  var dateToBR = require("../../../utils/dates/DateToBRr").dateToBR;
  const classes = useStyles();
  const [t] = useTranslation();
  const inventoryContext = useContext(InventoryContext);
  const externalUsersContext = useContext(ExternalUsersContext);
  const usersContext = useContext(UsersContext);
  const [usersArray, setUsersArray] = useState([]);
  const [itensArray, setItensArray] = useState([]);
  const [availableLicensesArray, setAvailableLicensesArray] = useState([]);
  const [statusArray, setStatusArray] = useState([]);
  const [externalUsersArray, setExternalUsersArray] = useState([]);
  const [placesArray, setPlacesArray] = useState([]);

  const [open, setOpen] = React.useState(false);
  const [openReturn, setOpenReturn] = React.useState(false);
  const [dialogContext, setDialogContext] = React.useState("");
  const [headerTitle, setHeaderTitle] = useState("INVENTORY.itens");
  const [changeOfLocalArray, setChangeOfLocalArray] = useState([]);
  const [changeContext, setchangeContex] = useState(0);
  const [itemsToBeMoved, setItemsToBeMoved] = useState([]);
  const [itemsToReturn, setItemsToBeReturn] = useState([]);
  const [removeIdUseEquipment, setRemoveIdUseEquipment] = useState('');
  const [removeIdReturnEquipment, setRemoveIdReturnEquipment] = useState('');
  const [removeIdReturnSoftware, setRemoveIdReturnSoftware] = useState('');
  const [removeIdUseSoftware, setRemoveIdUseSoftware] = useState('');
  const [enabledButton, setEnabledButton] = useState(true);

  const [editReturnOneEquipment, setEditReturnOneEquipment] = useState(
    {
      open: false,
      obj: [],
    });

  const [editUseEquipment, setEditUseEquipment] = useState(
    {
      open: false,
      obj: [],
    });

  const [editUseSoftware, setEditUseSoftware] = useState(
    {
      open: false,
      obj: [],
    });

  const [editReturnSoftware, setEditReturnSoftware] = useState(
    {
      open: false,
      obj: [],
    });

  const handleOpenApprove = () => {
    setDialogContext("approve");
    setOpen(true);
  }

  const handleOpenRefuse = () => {
    setDialogContext("refuse");
    setOpen(true);
  }

  const setReturnInforEquipment = (value) => {
    var teste = values.itemEquipmentRequisition.map(function (num) {
      //num.change = false;
      itemsToReturn.forEach(element => {
        if (element.id === num.id) {
          num.change = true;
          num.newResponsible = value.newResponsibleName;
          num.newResponsibleId = value.radioResponsibleType === "INTERNAL" ? value.alterResponsible : 0;
          num.newExternalResponsibleId = value.radioResponsibleType === "EXTERNAL" ? value.alterResponsible : 0;
          num.newPlace = value.newPlaceName;
          num.newPlaceId = value.newPlace;
          num.newStatus = value.radioNewStatus === "AVAILABLE" ? "Disponível" : "Indisponível"
          num.returned = value.radioReturn === "YES" ? true : false
          num.newStatusId = value.radioNewStatus === "AVAILABLE" ? 7732 : value.newStatusId

        }
      });
      if (editReturnOneEquipment && editReturnOneEquipment.obj.id === num.id) {
        num.change = true;
        num.newResponsible = value.newResponsibleName;
        num.newResponsibleId = value.radioResponsibleType === "INTERNAL" ? value.alterResponsible : 0;
        num.newExternalResponsibleId = value.radioResponsibleType === "EXTERNAL" ? value.alterResponsible : 0;
        num.newPlace = value.newPlaceName;
        num.newPlaceId = value.newPlace;
        num.newStatus = value.radioNewStatus === "AVAILABLE" ? "Disponível" : value.unavaliableStatusName
        num.returned = value.radioReturn === "YES" ? true : false
        num.newStatusId = value.radioNewStatus === "AVAILABLE" ? 7732 : value.unavaliableStatusId
      }

      if (num.ufcCode) num.ufcCodeF = `UFC-${num.ufcCode}`;
      if (num.fcpcCode) num.fcpcCodeF = `FCPC-${num.fcpcCode}`;
      if (num.lsbdCode) num.lsbdCodeF = `LSBD-${num.lsbdCode}`;
      if (num.externCode)
        num.externalCodeF = `${num.externPartner}-${num.externCode}`;
      return num;
    })
  }

  const setUseEquipment = (value) => {
    var teste = values.itemEquipmentRequisition.map(function (num) {
      if (editUseEquipment && editUseEquipment.obj.id === num.id) {
        num.change = true;
        num.newResponsibleId = values.responsibleId;
        num.itemName = value.itemName;
        num.itemId = value.itemId;
        num.code = value.code;
        num.serialNumber = value.serialNumber;
        num.place = value.newPlaceName;
        num.newPlaceId = value.newPlace;
        num.newStatus = value.radioNewStatus === "AVAILABLE" ? "Disponível" : value.unavaliableStatusName
        num.newStatusId = value.radioNewStatus === "AVAILABLE" ? 7732 : value.unavaliableStatusId
      }

      if (num.ufcCode) num.ufcCodeF = `UFC-${num.ufcCode}`;
      if (num.fcpcCode) num.fcpcCodeF = `FCPC-${num.fcpcCode}`;
      if (num.lsbdCode) num.lsbdCodeF = `LSBD-${num.lsbdCode}`;
      if (num.externCode)
        num.externalCodeF = `${num.externPartner}-${num.externCode}`;
      return num;
    })
  }

  const setReturnSoftware = (value) => {
    var teste = values.itemSoftwareRequisition.map(function (num) {
      if (editReturnSoftware && editReturnSoftware.obj.id === num.id) {
        num.change = true;
        num.newResponsibleId = value.radioResponsibleType === "INTERNAL" ? value.alterResponsible : 0;
        num.newExternalResponsibleId = value.radioResponsibleType === "EXTERNAL" ? value.alterResponsible : 0;
        num.newResponsible = value.newResponsibleName;
        num.newMachine = value.newMachineName;
        num.newMachineId = value.newMachineId;
        num.returned = value.radioReturn === "YES" ? true : false
        num.newStatus = value.radioNewStatus === "AVAILABLE" ? "Disponível" : "Indisponível"
      }
      return num;
    })
  }

  const setUseSoftware = (value) => {
    var teste = values.itemSoftwareRequisition.map(function (num) {
      if (editUseSoftware && editUseSoftware.obj.id === num.id) {
        setEnabledButton(false);
        num.change = true;
        num.newResponsibleId = values.responsibleId;
        num.keyOrEmail = value.keyOrEmail;
        num.licenseId = value.licenseId;
        num.codigo = value.code;
        num.machineId = value.machineId;
        num.machine = value.machineName;
        num.newMachineId = value.machineId;
        num.newStatus = value.radioNewStatus === "AVAILABLE" ? "Disponível" : "Indisponível"
        num.status = value.radioNewStatus
      }
      return num;
    })
  }

  const { id, context, ...others } = props;
  if (localStorage.getItem("auth-token") !== null) {
    var auth = JSON.parse(localStorage.getItem("auth-token"));
  }
  const [values, setValues] = useState({
    id: "",
    type: "",
    typeName: "",
    description: "",
    requester: "",
    dateRequisition: "",
    approver: "",
    approverResponse: "",
    approverResponseDate: "",
    executor: "",
    executerResponse: [],
    executorResponseDate: "",
    responsibleId: "",
    itemEquipmentRequisition: [],
    itemSoftwareRequisition: [],
    itemChangeOfLocalRequisition: [],
  });

  const arrayType = [
    {
      value: "change_of_local",
      label: "Alteração de Local",
    },
    {
      value: "use_of_equipment",
      label: "Utilização de Equipamento",
    },
    {
      value: "use_of_software",
      label: "Utilização de Software",
    },
    {
      value: "return_of_equipment",
      label: "Devolução de Equipamento",
    },
    {
      value: "return_of_software",
      label: "Devolução de Software",
    },
  ];

  const {
    loadingSoftware,
    getPlaces,
    places,
    getAllStatus,
    allStatus,
    requisition,
    getRequisition,
    getItensAvailableByCategory,
    itensAvailable,
    equipment,
    getAllEquipment,
    getInfoMyItems,
    myItens,
    getLicensesAvailable,
    availableLicenses,
  } = inventoryContext;

  useEffect(() => {
    values.responsibleId && getInfoMyItems(values.responsibleId);
  }, [values.responsibleId]);

  const {
    getUsers,
    users
  } = usersContext;

  const {
    getExternalUsers,
    externalUsers
  } = externalUsersContext;

  useEffect(() => {
    getRequisition(id)
  }, []);

  useEffect(() => {
    getUsers();
    getExternalUsers();
    getPlaces();
    getAllStatus();
    getAllEquipment();
    getLicensesAvailable();
  }, []);

  useEffect(() => {
    if (editUseEquipment.obj && (values.type === "return_of_equipment" || values.type === "use_of_equipment")) getItensAvailableByCategory(editUseEquipment.obj.categoryId);
  }, [editUseEquipment.obj, itensAvailable.length]);

  useEffect(() => {
    values.itemEquipmentRequisition = values.itemEquipmentRequisition.map(function (num) {
      if (num.id === removeIdUseEquipment) {
        num.newPlaceId = null;
        num.newPlace = null;
        num.newResponsibleId = null;
        num.itemName = "";
        num.itemId = null;
        num.code = "";
        num.serialNumber = "";
        num.place = "";
        num.newStatus = "";
        num.newStatusId = null;
      }
      return num;
    });

  }, [removeIdUseEquipment])

  useEffect(() => {
    values.itemEquipmentRequisition = values.itemEquipmentRequisition.map(function (num) {
      if (num.id === removeIdReturnEquipment) {
        num.newPlaceId = null;
        num.newPlace = null;
        num.newResponsibleId = null;
        num.itemName = "";
        num.itemName = "";
        num.itemId = null;
        num.code = "";
        num.serialNumber = "";
        num.newStatus = "";
        num.newStatusId = null;
        num.returned = "";
      }
      return num;
    });

  }, [removeIdReturnEquipment])

  useEffect(() => {
    values.itemSoftwareRequisition.some(element => {
      if (element.change === true) {
        setEnabledButton(false);
        return true
      } else {
        setEnabledButton(true);
      }
    });
  }, [JSON.stringify(values.itemSoftwareRequisition)])

  useEffect(() => {
    values.itemSoftwareRequisition = values.itemSoftwareRequisition.map(function (num) {
      if (num.id === removeIdReturnSoftware) {
        num.newPlaceId = null;
        num.newPlace = null;
        num.newResponsibleId = null;
        num.itemName = "";
        num.itemId = null;
        num.code = "";
        num.codigo = "";
        num.serialNumber = "";
        num.place = "";
        num.newStatus = "";
        num.newStatusId = null;
        num.newMachine = "";
        num.newResponsible = "";
        num.returned = "";
        num.keyOrEmail = "";
        num.change = false;
      }
      return num;
    });

  }, [removeIdReturnSoftware])

  useEffect(() => {
    values.itemSoftwareRequisition = values.itemSoftwareRequisition.map(function (num) {
      if (num.id === removeIdUseSoftware) {
        setEnabledButton(true)
        //setEditUseSoftware([{obj: [], open: false}])
        num.newPlaceId = null;
        num.newPlace = null;
        num.newResponsibleId = null;
        num.itemName = "";
        num.itemId = null;
        num.code = "";
        num.codigo = "";
        num.serialNumber = "";
        num.place = "";
        num.newStatus = "";
        num.newStatusId = null
        num.machine = "";
        num.newMachineId = "";
        num.keyOrEmail = ""
        num.change = false;
      }
      return num;
    });

  }, [JSON.stringify(removeIdUseSoftware)])

  const sortElement = (elemnts) => {
    elemnts.sort(function (a, b) {
      if (a.name === null) a.name = "";
      if (b.name === null) b.name = "";

      if (a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() >
        b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim()) {
        return 1;
      }
      if (a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() <
        b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim()) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
  }

  useEffect(() => {
    const allExternalUsers = [...externalUsers];
    sortElement(allExternalUsers);

    var aux = {};
    var _externalUsers = [];
    for (var i = 0; i < allExternalUsers.length; i++) {
      var item = allExternalUsers[i];
      aux.value = item.id;
      aux.label = item.name;
      _externalUsers.push(aux);
      aux = {};
    }
    setExternalUsersArray(_externalUsers);
  }, [externalUsers]);

  useEffect(() => {
    const allItens = [...equipment];
    sortElement(allItens);

    var aux = {};
    var _itens = [];
    var noneAux = {};
    noneAux.value = 0;
    noneAux.label = "Nenhuma"
    _itens.push(noneAux);
    for (var i = 0; i < allItens.length; i++) {
      var item = allItens[i];
      aux.value = item.id;
      aux.label = item.name;
      _itens.push(aux);
      aux = {};
    }

    setItensArray(_itens);
  }, [equipment]);

  useEffect(() => {
    const allItens = [...availableLicenses];
    //sortElement(allItens);

    var aux = {};
    var _itens = [];
    for (var i = 0; i < allItens.length; i++) {
      var item = allItens[i];
      aux.value = item.id;
      aux.label = item.name;
      aux.code = item.codigo;
      aux.keyOrEmail = item.key ? item.key : item.email ? item.email : "-";
      _itens.push(aux);
      aux = {};
    }

    setAvailableLicensesArray(_itens);
  }, [availableLicenses]);

  useEffect(() => {
    const allUsers = [...users];
    sortElement(allUsers);

    var aux = {};
    var _users = [];
    for (var i = 0; i < allUsers.length; i++) {
      var item = allUsers[i];
      aux.value = item.id;
      aux.label = item.name;
      _users.push(aux);
      aux = {};
    }

    setUsersArray(_users);
  }, [users]);

  useEffect(() => {
    const status = [...allStatus];
    sortElement(status);

    var aux = {};
    var _status = [];
    for (var i = 0; i < status.length; i++) {
      var item = status[i];
      aux.value = item.id;
      aux.label = item.name;
      _status.push(aux);
      aux = {};
    }

    setStatusArray(_status);
  }, [allStatus]);

  useEffect(() => {
    const myPlaces = [...places];
    SortElementSelect(myPlaces, "name");

    var aux = {};
    var _places = [];
    for (var i = 0; i < myPlaces.length; i++) {
      var item = myPlaces[i];
      aux.value = item.id;
      aux.label = item.name;
      _places.push(aux);
      aux = {};
    }
    setPlacesArray(_places);
  }, [places]);

  useEffect(() => {
    if (requisition) {
      setValues({
        id: requisition.id ? requisition.id : "",
        status: requisition.status ? requisition.status : "",
        requesterShortName: requisition.requesterShortName ? requisition.requesterShortName : "",
        type: requisition.class ? requisition.class : "-",
        typeName: requisition.class ? arrayType.find(ele => ele.value === requisition.class).label : "-",
        description: requisition.message ? requisition.message : "-",
        requester: requisition.requester ? requisition.requester : "-",
        responsibleId: requisition.responsibleId ? requisition.responsibleId : "-",
        dateRequisition: requisition.dateCreated ? requisition.dateCreated : "",
        approver: requisition.approver ? requisition.approver : "",
        requesterEmail: requisition.requesterEmail ? requisition.requesterEmail : "",
        approverEmail: requisition.approverEmail ? requisition.approverEmail : "",
        approverResponse: requisition.approverResponse ? requisition.approverResponse : "",
        approverResponseDate: requisition.approverResponseDate ? dateToBRWithHours(new Date(requisition.approverResponseDate)) : "",
        executor: requisition.executor ? requisition.executor : "",
        executerResponse: requisition.executorResponse ? requisition.executorResponse : "",
        executorResponseDate: requisition.executorResponseDate ? dateToBRWithHours(new Date(requisition.executorResponseDate)) : "",
        itemEquipmentRequisition: requisition.inventoryItemOfEquipmentRequisition ? requisition.inventoryItemOfEquipmentRequisition.map(function (num) {
          num.change = false;
          if (context === "view") {
            num.itemName = num.name
          }
          if (num.ufcCode) num.ufcCodeF = `UFC-${num.ufcCode}`;
          if (num.fcpcCode) num.fcpcCodeF = `FCPC-${num.fcpcCode}`;
          if (num.lsbdCode) num.lsbdCodeF = `LSBD-${num.lsbdCode}`;
          if (context === "edit") num.newStatus = "";
          if (num.externCode)
            num.externalCodeF = `${num.externPartner}-${num.externCode}`;
          return num;
        }) : [],
        itemSoftwareRequisition: requisition.inventoryItemOfSoftwareRequisition ? requisition.inventoryItemOfSoftwareRequisition.map(function (num) {
          num.change = false;
          if (context === "edit") {
            num.newStatus = "";
            num.newResponsible = "";
            num.newMachine = "";
          }
          return num
        }) : [],
        itemChangeOfLocalRequisition: requisition.inventoryItemOfChangeOfLocalRequisition ? requisition.inventoryItemOfChangeOfLocalRequisition.map(function (num) {
          num.change = false;
          if (num.ufcCode) num.ufcCodeF = `UFC-${num.ufcCode}`;
          if (num.fcpcCode) num.fcpcCodeF = `FCPC-${num.fcpcCode}`;
          if (num.lsbdCode) num.lsbdCodeF = `LSBD-${num.lsbdCode}`;
          if (context === "edit") num.newStatus = "";
          if (num.externCode)
            num.externalCodeF = `${num.externPartner}-${num.externCode}`;
          return num;
        }) : [],
      })

    }
  }, [requisition.length, requisition])
  const transitions = useTransition(loadingSoftware, null, {
    from: { opacity: 0 },
    enter: {
      opacity: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "calc(100vh - 70px)",
    },
    leave: { opacity: 0 },
  });

  return transitions.map(({ item, key, props }) => (
    <React.Fragment>
      <div className="container">
        <Breadcrumb
          routes={[
            { label: t("INVENTORY.inventory") },
            { label: t("INVENTORY.requisitions"), to: "/RequisitionsAdm" },
            { label: context === "edit" ? t("INVENTORY.editRequisition") : t("GENERAL.view") },
          ]}
        />
        <div className={classes.containerHeader}>
          <div className="title">
            <h1 className={classes.title}>{context === "edit" ? t("INVENTORY.editRequisition") : t("GENERAL.view") + " " + t("INVENTORY.requisitions")}</h1>
          </div>
        </div>
        <Paper>
          <div className="cabecalho">
            {context === "edit" ? t("INVENTORY.editRequisition").toUpperCase() : t("GENERAL.view").toUpperCase()}
          </div>
          {item ? (
            <animated.div key={key} style={props}>
              <Spinner />;
            </animated.div>
          ) : (
            <React.Fragment>
              <div className={classes.bodyNewEquipment}>
                <Grid container item spacing={3} md={12} lg={12}>
                  <Grid item xs={12} sm={7}>
                    <Input
                      context={"view"}
                      label={"INVENTORY.requester"}
                      value={values.requester}
                      valueName={"requester"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <Input
                      context={"view"}
                      label={"INVENTORY.requestType"}
                      value={values.typeName}
                      valueName={"type"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <Input
                      context={"view"}
                      label={"INVENTORY.dateRequisition"}
                      value={dateToBRWithHours(new Date(values.dateRequisition))}
                      valueName={"dateRequisition"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <Input
                      context={"view"}
                      rows={4}
                      label={"INVENTORY.description"}
                      value={values.description}
                      valueName={"description"}
                      maxLength={500}
                    />
                  </Grid>
                  {values.approver &&
                    <React.Fragment>
                      <Grid item xs={12} sm={7} style={{ padding: "0px" }}>
                        {values.status === "DENIED" && values.executor === "" ? <div style={{ display: "flex", justifyContent: "space-between", paddingLeft: "12px" }}> <div>Recusado <CancelIcon fontSize="small" style={{ color: "red" }} /> </div> {values.approverResponseDate} </div>
                          : <div style={{ display: "flex", justifyContent: "space-between", paddingLeft: "12px" }}> <div>Aprovado <CheckIcon fontSize="small" style={{ color: "green" }} /> </div> {values.approverResponseDate} </div>}
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <Input
                          context={"view"}
                          value={values.approver}
                          valueName={"requester"}
                        />
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <Input
                          context={"view"}
                          rows={4}
                          label={values.status === "DENIED" && values.executor === "" ? "INVENTORY.justificationLabel" : "INVENTORY.observation"}
                          value={values.approverResponse}
                          valueName={"description"}
                          maxLength={200}
                        />
                      </Grid>
                    </React.Fragment>
                  }
                  {values.executor &&
                    <React.Fragment>
                      <Grid item xs={12} sm={7} style={{ padding: "0px" }}>
                        {values.status === "DENIED" && values.executor !== "" ? <div style={{ display: "flex", justifyContent: "space-between", paddingLeft: "12px" }}> <div>Recusado <CancelIcon fontSize="small" style={{ color: "red" }} /> </div> {values.executorResponseDate} </div>
                          : <div style={{ display: "flex", justifyContent: "space-between", paddingLeft: "12px" }}> <div>Executado <CheckIcon fontSize="small" style={{ color: "green" }} /> </div> {values.executorResponseDate} </div>}
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <Input
                          context={"view"}
                          label={"INVENTORY.executor"}
                          value={values.executor}
                          valueName={"executor"}
                        />
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <Input
                          context={"view"}
                          rows={4}
                          label={values.status === "DENIED" && values.executor !== "" ? "INVENTORY.justificationLabel" : "INVENTORY.observation"}
                          value={values.executerResponse}
                          valueName={"executorResponse"}
                          maxLength={200}
                        />
                      </Grid>
                    </React.Fragment>
                  }
                  {values.type !== "" &&
                    <Grid item xs={12} sm={12}>
                      <div className="cabecalho">
                        {t(headerTitle).toUpperCase()}
                        {values.type === "return_of_equipment" && context === "edit" &&
                          <span style={{ float: "right", marginRight: 10 }}>
                            <Button style={{ float: "right", marginRight: 10 }} onClick={() => setOpenReturn(true)} disabled={itemsToReturn.length === 0}>
                              <Edit style={{ float: "right", marginRight: 10 }} />
                              {t("INVENTORY.editSelected").toUpperCase()}
                            </Button>
                          </span>
                        }
                      </div>
                      {values.type === "return_of_software" ?
                        <SotwareReqTable
                          rows={values.itemSoftwareRequisition}
                          headers={["id", "software", "category", "key", "email", "machine", "serialNumber", "newResponsible", "place", "newStatus", "newMachine", "returned"]}
                          visibleHeaders={
                            context === "edit" ?
                              ["software", "Categoria", "Chave/Email de Ativação", "Máquina", "Novo Responsável", "Novo Status", "Novo máquina", "Devolvido", "", ""]
                              : ["software", "Categoria", "Chave/Email de Ativação", "Máquina", "Novo Responsável", "Novo Status", "Novo máquina", "Devolvido"]
                          }
                          sortedFields={["software", "category", "key", "machine", "newResponsible", "newStatus", "newMachine", "returned"]}
                          mainField="software"
                          type={values.type}
                          context={context}
                          setEditReturnSoftware={setEditReturnSoftware}
                          setRemoveIdReturnSoftware={setRemoveIdReturnSoftware}
                        />
                        :
                        values.type === "use_of_software" ?
                          <SotwareReqTable
                            rows={values.itemSoftwareRequisition}
                            headers={["id", "software", "category", "key", "keyOrEmail", "email", "machine", "ufcCodeF", "lsbdCodeF", "externalCodeF", "serialNumber", "codigo", "place", "newStatus", "returned", "newMachine"]}
                            visibleHeaders={
                              context === "edit" ?
                                ["software", "Categoria", "Máquina", "Código", "Chave/Email de Ativação", "Novo Status", "", ""]
                                : ["software", "Categoria", "Máquina", "Código", "Chave/Email de Ativação", "Novo Status"]
                            }
                            sortedFields={["software", "category", "machine", "codigo", "keyOrEmail", "newStatus"]}
                            mainField="software"
                            type={values.type}
                            context={context}
                            setEditUseSoftware={setEditUseSoftware}
                            setRemoveIdUseSoftware={setRemoveIdUseSoftware}
                          />
                          :
                          values.type === "return_of_equipment" ?
                            < ItensReqTable
                              rows={values.itemEquipmentRequisition}
                              headers={["id", "name", , "category", "fcpcCodeF", "ufcCodeF", "lsbdCodeF", "externalCodeF", "serialNumber", "newResponsible", "place", "newStatus", "newPlace", "returned"]}
                              visibleHeaders={
                                context === "edit" ?
                                  ["Item", "Código de identificação", "Número de série", "Local atual", "Novo Responsável", "Novo Local", "Novo status", "Devolvido", "", ""]
                                  : ["Item", "Código de identificação", "Número de série", "Local atual", "Novo Responsável", "Novo Local", "Novo status", "Devolvido"]
                              }
                              sortedFields={["name", "ufcCodeF", "serialNumber", "place", "newResponsible", "newPlace", "newStatus", "returned"]}
                              mainField="category"
                              type={values.type}
                              changeOfLocalArray={changeOfLocalArray}
                              setchangeContex={setchangeContex}
                              context={context}
                              setItemsToBeReturn={setItemsToBeReturn}
                              itemsToReturn={itemsToReturn}
                              setEditReturnOneEquipment={setEditReturnOneEquipment}
                              setRemoveIdReturnEquipment={setRemoveIdReturnEquipment}
                            />
                            :
                            values.type === "use_of_equipment" ?
                              <ItensReqTable
                                rows={values.itemEquipmentRequisition}
                                headers={["id", "category", "description", "name", "fcpcCodeF", "ufcCodeF", "lsbdCodeF", "externalCodeF", "serialNumber", "place", "returned", "categoryId", "itemName", "code", "newStatus"]}
                                visibleHeaders={
                                  context === "edit" ?
                                    ["Categoria", "Descrição", "Local", "Item atribuído", "Código de identificação", "Número de série", "Novo status", "", ""]
                                    : ["Categoria", "Descrição", "Local", "Item atribuído", "Código de identificação", "Número de série", "Novo status"]
                                }
                                sortedFields={["category", "description", "place", "itemName", "ufcCodeF", "serialNumber", "newStatus"]}
                                mainField="category"
                                type={values.type}
                                changeOfLocalArray={changeOfLocalArray}
                                setchangeContex={setchangeContex}
                                context={context}
                                setEditUseEquipment={setEditUseEquipment}
                                setRemoveIdUseEquipment={setRemoveIdUseEquipment}
                              />
                              :
                              values.type === "change_of_local" &&
                              <ItensReqTable
                                rows={values.itemChangeOfLocalRequisition}
                                headers={["id", "name", "category", "fcpcCodeF", "ufcCodeF", "lsbdCodeF", "externalCodeF", "serialNumber", "oldPlace", "newPlace", "moved"]}
                                visibleHeaders={["Item", "Categoria", "Código de identificação", "Número de série", "Local atual", "Novo local", "Item movido"]}
                                sortedFields={["name", "category", "ufcCodeF", "serialNumber", "oldPlace", "newPlace"]}
                                mainField="name"
                                type={values.type}
                                changeOfLocalArray={changeOfLocalArray}
                                context={context}
                                setItemsToBeMoved={setItemsToBeMoved}
                              />}
                    </Grid>}
                  {context === "edit" && <Grid item xs={12} sm={12}>
                    <div style={{ display: "flex", marginTop: "40px" }}>
                      <Buttons
                        onClick={handleOpenRefuse}
                        tipo="BN-noback"
                        conteudo={t("GENERAL.refuse").toLocaleUpperCase()}
                      />
                      <Tooltip title={values.type === "use_of_software" && enabledButton ? "Aloque pelo menos um software para executar" : ""} aria-label="details" placement="top" >
                        <span>
                          <Button
                            color="primary"
                            disabled={values.type === "use_of_software" && enabledButton}
                            className={classes.blueButton}
                            variant="contained"
                            style={{
                              float: "right",
                              marginLeft: "10px",
                            }}
                            onClick={() => handleOpenApprove()}
                          >
                            {t("GENERAL.execute")}
                          </Button>
                        </span>
                      </Tooltip>
                    </div>
                  </Grid>}
                </Grid>
              </div>
            </React.Fragment>
          )}
        </Paper>
      </div>
      <DialogReqApprove
        open={open}
        setOpen={setOpen}
        typeName={values.typeName}
        dateRequisition={values.dateRequisition}
        requisitionId={values.id}
        approverId={auth.id}
        context={dialogContext}
        type={values.type}
        itemsToBeMoved={itemsToBeMoved}
        itemsChangeOfLocal={values.itemChangeOfLocalRequisition}
        itemsToBeReturn={values.itemEquipmentRequisition}
        softwaresRequisitions={values.itemSoftwareRequisition}
        requisition={values}
        executeName={auth.name}
      />
      <DialogAlterReturnEquipment
        open={editReturnOneEquipment.open}
        setOpen={setEditReturnOneEquipment}
        item={editReturnOneEquipment.obj}
        typeName={values.typeName}
        dateRequisition={values.dateRequisition}
        requisitionId={values.id}
        approverId={auth.id}
        context={dialogContext}
        type={values.type}
        itemsToBeMoved={itemsToBeMoved}
        itemsChangeOfLocal={values.itemChangeOfLocalRequisition}
        usersArray={usersArray}
        externalUsersArray={externalUsersArray}
        statusArray={statusArray}
        placesArray={placesArray}
        setReturnInforEquipment={setReturnInforEquipment}
      />
      <DialogAlterReturnManyEquipment
        open={openReturn}
        setOpen={setOpenReturn}
        items={itemsToReturn}
        typeName={values.typeName}
        dateRequisition={values.dateRequisition}
        requisitionId={values.id}
        approverId={auth.id}
        context={dialogContext}
        type={values.type}
        itemsChangeOfLocal={values.itemChangeOfLocalRequisition}
        usersArray={usersArray}
        externalUsersArray={externalUsersArray}
        statusArray={statusArray}
        placesArray={placesArray}
        setReturnInforEquipment={setReturnInforEquipment}
        setItemsToBeReturn={setItemsToBeReturn}
      />
      <DialogAlterUseEquipment
        open={editUseEquipment.open}
        setOpen={setEditUseEquipment}
        item={editUseEquipment.obj}
        typeName={values.typeName}
        dateRequisition={values.dateRequisition}
        requisitionId={values.id}
        approverId={auth.id}
        context={dialogContext}
        type={values.type}
        itemsChangeOfLocal={values.itemChangeOfLocalRequisition}
        requisitions={values.itemEquipmentRequisition}
        usersArray={usersArray}
        externalUsersArray={externalUsersArray}
        statusArray={statusArray}
        placesArray={placesArray}
        setUseEquipment={setUseEquipment}
        setItemsToBeReturn={setItemsToBeReturn}
        itensArray={itensAvailable}
      />
      <DialogAlterReturnSoftware
        open={editReturnSoftware.open}
        setOpen={setEditReturnSoftware}
        item={editReturnSoftware.obj}
        typeName={values.typeName}
        dateRequisition={values.dateRequisition}
        requisitionId={values.id}
        approverId={auth.id}
        context={dialogContext}
        type={values.type}
        itemsToBeMoved={itemsToBeMoved}
        itemsChangeOfLocal={values.itemSoftwareRequisition}
        usersArray={usersArray}
        externalUsersArray={externalUsersArray}
        statusArray={statusArray}
        placesArray={placesArray}
        setReturnInforEquipment={setReturnInforEquipment}
        itensArray={itensArray}
        setReturnSoftware={setReturnSoftware}
      />
      <DialogAlterUseSoftware
        open={editUseSoftware.open}
        setOpen={setEditUseSoftware}
        item={editUseSoftware.obj}
        typeName={values.typeName}
        dateRequisition={values.dateRequisition}
        requisitionId={values.id}
        approverId={auth.id}
        context={dialogContext}
        type={values.type}
        itemsChangeOfLocal={values.itemChangeOfLocalRequisition}
        requisitions={values.itemSoftwareRequisition}
        itensArray={myItens}
        setUseSoftware={setUseSoftware}
        availableLicensesArray={availableLicensesArray}
      />
      <Footer />
    </React.Fragment >
  ));
};

export default FormRequisition;