/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Transition from 'components/layout/transition/Transition';
import FilterContainer from 'components/containers/FilterContainer';
import InputFilter from 'components/inputs/InputFilter';
import InputFilterDate from 'components/inputs/InputFilterDate';
import BillingSheetTable from './BillingSheetTable';
import Dissatisfied from '@material-ui/icons/SentimentVeryDissatisfied';
import { useStyles } from 'components/layout/CommonStyles';
import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';
import { isSameMonthAndYear } from 'utils/dates/isSameMontAndYear';
import { FormatDateToFront } from 'utils/dates/FormatDateToFront';
import { GetMonthAndYearInWords } from 'utils/dates/GetMonthAnsYearInWords';

import { Screen, Allow } from 'global/constants';
import { useQueryLSBDBillingSheetMonth } from 'hooks/DataFetching/Quotas/Dashboard/useQueryLSBDBillingSheet';

import { BillsStatusArray, translateBillStatus } from '../../ScreenUtils';

import { screenStyles } from '../../Styles';
import { instanceOf } from 'prop-types';

const BillingSheetForm = () => {
    const classes = useStyles();
    const styles = screenStyles();
    const [t] = useTranslation();

    const [findText, setFindText] = useState('');
    const [filterName, setFilterName] = useState('');
    const [filterStatus, setFilterStatus] = useState('ALL');
    const [filterDate, setFilterDate] = useState(new Date());
    const [dataFilter, setDataFilter] = useState([]);
    const [canClick, setCanClick] = useState(false);

    const { isLoading, isFetching, isRefetching, error, data, refetch } =
        useQueryLSBDBillingSheetMonth({}, filterDate);

    const hasItems = data && data.length > 0;
    const [hasNoResult, setHasNoResult] = useState(
        hasItems && dataFilter && dataFilter.length === 0
    );

    const minDate = new Date(2013, 11, 1);
    const maxDate = new Date();
    const isInvalidBillDate =
        !isNaN(filterDate) &&
        filterDate instanceof Date &&
        (filterDate.getTime() < minDate.getTime() ||
            filterDate.getTime() > maxDate.getTime());

    useEffect(() => {
        if (data && data.length > 0 && dataFilter.length === 0) {
            setDataFilter(data);
            setHasNoResult(false);
        }
    }, [data]);

    useEffect(() => {
        if (data && data.length > 0) {
            const { newDataFilter, textNotFound } = applyFilter(
                data.filter(x => !x.hasDeleted)
            );

            if (newDataFilter.length === 0) {
                setHasNoResult(true);
                setFindText(textNotFound);
            } else {
                setDataFilter(newDataFilter);
                setHasNoResult(false);
                setFindText('');
            }

            setDataFilter(newDataFilter);
        }
    }, [filterName, filterStatus]);

    const applyFilter = data => {
        let newDataFilter = [];
        let textNotFound = '';

        newDataFilter = data;

        if (filterName) {
            newDataFilter = newDataFilter.filter(item =>
                item.user.name.toLowerCase().includes(filterName.toLowerCase())
            );

            if (!textNotFound && newDataFilter.length === 0)
                textNotFound = filterName;
        }

        if (filterStatus && filterStatus !== 'ALL') {
            newDataFilter = newDataFilter.filter(
                item => item.status && item.status === filterStatus
            );

            if (!textNotFound && newDataFilter.length === 0)
                textNotFound = translateBillStatus(filterStatus);
        }
        return { newDataFilter, textNotFound };
    };

    useEffect(() => {
        if (filterName || filterDate || filterStatus) {
            setCanClick(true);
        } else {
            setCanClick(false);
        }
    }, [filterName, filterDate, filterStatus]);

    useEffect(() => {
        // Re-fetch data
        if (isInvalidBillDate || isNaN(filterDate) || filterDate === null) {
            setDataFilter([]);
            setHasNoResult(true);
        } else {
            if (!isFetching && filterDate && !isNaN(filterDate)) {
                refetch().then(res => {
                    if (res.data && res.data.length > 0) {
                        setDataFilter([]);

                        const { newDataFilter, textNotFound } = applyFilter(
                            res.data.filter(x => !x.hasDeleted)
                        );

                        if (newDataFilter.length === 0) {
                            setDataFilter([]);
                            setHasNoResult(true);
                            setFindText(textNotFound);
                        } else {
                            setDataFilter(newDataFilter);
                            setHasNoResult(false);
                            setFindText('');
                        }
                    }
                });
            }
        }
    }, [filterDate, data]);

    return (
        <React.Fragment>
            <Transition
                loading={isLoading && isFetching && isRefetching}
                newDesign={true}
            >
                <FilterContainer>
                    <InputFilter
                        filterValue={filterName}
                        setFilter={setFilterName}
                        label={t('GENERAL.collaborator')}
                    />
                    <InputFilterDate
                        filterValue={filterDate}
                        setFilter={setFilterDate}
                        label={'GENERAL.date'}
                        monthAndYear
                        minDate={minDate}
                        maxDate={maxDate}
                    />
                    <InputFilter
                        filterValue={filterStatus}
                        setFilter={setFilterStatus}
                        label={'GENERAL.status'}
                        isSelect
                        arraySelected={BillsStatusArray}
                    />
                </FilterContainer>
                <React.Fragment>
                    <BillingSheetTable
                        rows={dataFilter}
                        toFirstPage={canClick}
                        headers={['user', 'date', 'status', 'value']}
                        visibleHeaders={[
                            'Colaborador',
                            'Data',
                            'Status',
                            'Valor',
                            ''
                        ]}
                        sortedFields={['user', 'date', 'status', 'value']}
                        mainField="user"
                    />
                    {!hasItems && !error && (
                        <div className={classes.noDataWrapper}>
                            <h2>{t('QUOTAS.noUserBillsMessage')}</h2>
                            <h4>{t('QUOTAS.noUserBillsSubMessage')}</h4>
                        </div>
                    )}
                    {hasNoResult && !error && (
                        <div className={classes.noParticipantsMessage}>
                            <div>
                                <Dissatisfied
                                    className={classes.notFoundIconStyle}
                                />
                            </div>
                            <h2>{t('GENERAL.noResultsFound')}</h2>
                            {!isInvalidBillDate &&
                            !isNaN(filterDate) &&
                            filterDate !== null ? (
                                <h5>{`Sua busca por "${findText}" não retornou resultados.`}</h5>
                            ) : null}
                        </div>
                    )}
                    {error && (
                        <div className={classes.noParticipantsMessage}>
                            <div>
                                <Dissatisfied
                                    className={classes.notFoundIconStyle}
                                />
                            </div>
                            <h2>{'Houve um erro de conexão com o Servidor'}</h2>
                        </div>
                    )}
                </React.Fragment>
            </Transition>
        </React.Fragment>
    );
};

export default BillingSheetForm;
