import axios from 'axios';
import { useMutation } from 'react-query';
import Cookies from 'js-cookie';
import { useSnackbar } from 'hooks/UseSnackbar';
import { useTranslation } from 'react-i18next';
import { useQueryUsersAdmin } from './useQueryUsersAdmin';
import { getManagersAndSendEmails } from 'services/Emails/General/generalEmailService';

export const useMutationDisabledUser = setOpen => {
    const toast = useSnackbar();
    const [t] = useTranslation();

    const queryUsersAdmin = useQueryUsersAdmin({ enabled: false });

    const mutation = useMutation({
        mutationFn: async obj =>
            await axios.put(
                process.env.REACT_APP_SERVER +
                    `api/Admin/Users/${obj.get('id')}/DisableLogin`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            ),
        onSuccess: res => {
            toast.success(t('ADMIN.userDisabledSuccess'));
            queryUsersAdmin.refetch();
            setOpen(false);
            const data = res.data;

            const emailData = {
                collaboratorName: data.user.shortname,
                collaboratorEmail: data.user.username,
                collaboratorCpf: data.user.cpf,
                collaboratorId: data.user.id,
                collaboratorCreatedAt: data.user.createdAt,
                collaboratorStatus:
                    data.user.userRegistrationStatus === 'Admitted'
                        ? 'Admitido'
                        : 'Demitido',
                operatorName: data.operator.shortname
            };

            getManagersAndSendEmails(
                emailData,
                'api/Admin/Collaborator/Disabled',
                'ADMIN_COLLABORATOR_DISABLED_EMAIL_OPTIONAL',
                'adminManagers'
            );
        },
        onError: err => {
            if (err.response.data.message && err.response.data.code !== 1000) {
                toast.error(err.response.data.message);
            } else {
                toast.error(t('ADMIN.userDisabledError'));
            }
        },
        onSettled: () => {}
    });

    return mutation;
};
