import React from "react";
import "../../../App.css";
import SideBar from "../../../components/layout/sidebar/Sidebar";
import DashboardNavBar from "../../../components/layout/DashboardNavBar";
import Requisitions from "./Requisitions";

const CallRequisitions = ({ match }) => {

    return (
        <div>
            <DashboardNavBar />
            <SideBar />
            <Requisitions />
        </div>
    );
}

export default CallRequisitions;