/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import Transition from 'components/layout/transition/Transition';
import Typography from 'components/typography/Typography';
import Input from 'components/inputs/Input';
import InputDate from 'components/inputs/InputDate';
import InputImageUser from 'components/inputs/InputImageUser';
import Buttons from 'components/buttons/Pressed';
import { Paper, Box, Grid, InputAdornment } from '@material-ui/core';

import { useQueryUserData } from 'hooks/DataFetching/Admin/Users/useQueryUserData';
import { useMutationEditUserData } from 'hooks/DataFetching/Admin/Users/useMutationEditUserData';

import { dateValidation } from 'utils/validation/DateValidation';
import { emailValidation } from 'utils/validation/EmailValidation';
import { CPFValidation } from 'utils/validation/CPFValidation';
import { FormatDateToFront } from 'utils/dates/FormatDateToFront';
import { CPFMask } from 'utils/masks/CPFMask';
import { FormatDateToDataBase } from 'utils/dates/FormatDateToDataBase';

import { useStyles } from 'components/layout/CommonStyles';
import { screenStyles } from '../Styles';

const FormUsers = ({
    id,
    context,
    values,
    setValues,
    changeTab,
    setCanChangeTab,
    data,
    usersRequired,
    imageFile,
    setImageFile
}) => {
    const classes = useStyles();
    const styles = screenStyles();
    const [t] = useTranslation();

    const userDataQuery = useQueryUserData(
        context === 'new' ? { enabled: false } : {},
        id
    );

    const [mustRedirect, setMustRedirect] = useState(false);

    const mutation = useMutationEditUserData(setMustRedirect);

    const redirect = go => {
        if (go) return <Redirect to={`/Admin/Users`} />;
    };

    const [requiredName, setRequiredName] = useState(usersRequired.name);
    const [requiredShortname, setRequiredShortname] = useState(
        usersRequired.shortname
    );
    const [requiredUsername, setRequiredUsername] = useState(
        usersRequired.username ||
            usersRequired.usernameInvalid ||
            usersRequired.usernameAlreadyExists
    );
    const [usernameErrorMessage, setUsernameErrorMessage] = useState(
        usersRequired.username
            ? t('GENERAL.requiredField')
            : usersRequired.usernameInvalid
            ? t('GENERAL.invalidEmail')
            : usersRequired.usernameAlreadyExists
            ? t('GENERAL.usernameAlreadyRegistered')
            : ''
    );
    const [requiredCPF, setRequiredCPF] = useState(
        usersRequired.cpf ||
            usersRequired.cpfInvalid ||
            usersRequired.cpfAlreadyExists
    );
    const [CPFErrorMessage, setCPFErrorMessage] = useState(
        usersRequired.cpf
            ? t('GENERAL.requiredField')
            : usersRequired.cpfInvalid
            ? t('GENERAL.invalidCPF')
            : usersRequired.cpfAlreadyExists
            ? t('GENERAL.usernameAlreadyRegistered')
            : ''
    );
    const [requiredBirthday, setRequiredBirthday] = useState(
        usersRequired.birthday
    );
    const [birthdayErrorMessage, setBirthdayErrorMessage] = useState('');

    useEffect(() => {
        if (userDataQuery.data && context !== 'new') {
            const data = userDataQuery.data;
            setValues({
                name: data.name ? data.name : '',
                shortname: data.shortname ? data.shortname : '',
                username:
                    data.username && context === 'view'
                        ? data.username
                        : context === 'edit'
                        ? data.username.substring(0, data.username.indexOf('@'))
                        : '',
                birthday: data.dateOfBirth
                    ? FormatDateToFront(data.dateOfBirth)
                    : null,
                cpf: data.cpf ? data.cpf : '',
                imageName: data.imageName ? data.imageName : '',
                createdAt: data.createdAt
                    ? FormatDateToFront(data.createdAt)
                    : null
            });
        }
    }, [userDataQuery.data]);

    const handleChange = (name, value) => {
        if (name === 'cpf') {
            setValues({ ...values, [name]: CPFMask(value) });
        } else {
            setValues({ ...values, [name]: value });
        }
    };

    const verifyAdvance = () => {
        let res = true;

        if (!values.name.trim()) {
            res = false;
            setRequiredName(true);
        }

        if (!values.shortname.trim()) {
            res = false;
            setRequiredShortname(true);
        }

        if (!values.username.trim()) {
            res = false;
            setRequiredUsername(true);
            setUsernameErrorMessage(t('GENERAL.requiredField'));
        }

        if (
            values.username.trim() &&
            !emailValidation(
                values.username + process.env.REACT_APP_DEFAULT_EMAIL
            )
        ) {
            res = false;
            setRequiredUsername(true);
            setUsernameErrorMessage(t('GENERAL.invalidEmail'));
        }

        const checkSameUsername = obj =>
            obj.username.toLowerCase() ===
                values.username.toLowerCase() +
                    process.env.REACT_APP_DEFAULT_EMAIL &&
            obj.id.toString() !== id;

        if (data && data.users.some(checkSameUsername)) {
            res = false;
            setRequiredUsername(true);
            setUsernameErrorMessage(t('GENERAL.usernameAlreadyRegistered'));
        }

        if (!values.cpf.trim()) {
            res = false;
            setRequiredCPF(true);
            setCPFErrorMessage(t('GENERAL.requiredField'));
        }

        if (values.cpf.trim() && !CPFValidation(values.cpf)) {
            res = false;
            setRequiredCPF(true);
            setCPFErrorMessage(t('GENERAL.invalidCPF'));
        }
        const checkSameCPF = obj =>
            obj.cpf === values.cpf && obj.id.toString() !== id;

        if (data && data.users.some(checkSameCPF)) {
            res = false;
            setRequiredCPF(true);
            setCPFErrorMessage(t('GENERAL.cpfAlreadyRegistered'));
        }

        if (!dateValidation(values.birthday)) {
            res = false;
            setRequiredBirthday(true);
            setBirthdayErrorMessage(t('GENERAL.invalidDate'));
        }

        if (
            values.birthday &&
            values.birthday.getTime() > new Date().getTime()
        ) {
            res = false;
            setRequiredBirthday(true);
            setBirthdayErrorMessage(t('GENERAL.maxDateErrorMessage'));
        }

        if (values.birthday === null) {
            res = false;
            setRequiredBirthday(true);
            setBirthdayErrorMessage(t('GENERAL.requiredField'));
        }

        return res;
    };

    const handleAdvance = () => {
        const canSubmit = verifyAdvance();
        if (canSubmit) {
            if (context === 'new') {
                setCanChangeTab(true);
                changeTab();
            } else {
                let formData = new FormData();

                formData.append('id', id);

                formData.append('name', values.name);
                formData.append('shortname', values.shortname);
                formData.append(
                    'username',
                    values.username + process.env.REACT_APP_DEFAULT_EMAIL
                );
                formData.append(
                    'dateOfBirth',
                    FormatDateToDataBase(values.birthday)
                );
                formData.append('cpf', values.cpf);
                formData.append(
                    'deleteImage',
                    imageFile.length > 0 ? true : false
                );
                formData.append(
                    'image',
                    imageFile.length > 0 ? imageFile[0] : null
                );

                mutation.mutate(formData);
            }
        }
    };

    return (
        <React.Fragment>
            {redirect(mustRedirect)}
            <Transition
                loading={context !== 'new' ? userDataQuery.isLoading : false}
                newDesign={true}
            >
                <Paper square style={{ padding: '32px 16px' }}>
                    <Grid container xs={12} spacing={1}>
                        {context === 'new' ? (
                            <>
                                <Grid item xs={12}>
                                    <Typography
                                        variant={'RobotoBold'}
                                        fontSize={14}
                                        textColor={'primary'}
                                    >
                                        {t('ADMIN.dataOfUser')}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography
                                        variant={'RobotoRegular'}
                                        fontSize={13}
                                        textColor={'secondary'}
                                    >
                                        {t('ADMIN.createUserHelperText')}
                                    </Typography>
                                </Grid>
                            </>
                        ) : null}
                    </Grid>
                    <Box display={'flex'} marginTop={'16px'}>
                        <Box height={'100%'}>
                            <InputImageUser
                                context={context}
                                file={imageFile}
                                setFile={setImageFile}
                                imageUrl={
                                    values.imageName
                                        ? process.env.REACT_APP_IMGSERVER +
                                          values.imageName
                                        : null
                                }
                            />
                        </Box>

                        <Grid
                            container
                            xs={12}
                            spacing={1}
                            style={{ marginLeft: '32px' }}
                        >
                            <Grid item xs={8}>
                                <Input
                                    style={{ marginTop: '0px' }}
                                    required={context !== 'view'}
                                    context={context}
                                    label={'GENERAL.collaboratorName'}
                                    value={values.name}
                                    valueName={'name'}
                                    maxLength={300}
                                    handleChange={handleChange}
                                    error={requiredName}
                                    helperText={
                                        requiredName &&
                                        t('GENERAL.requiredField')
                                    }
                                    setRequired={setRequiredName}
                                />
                            </Grid>

                            <Box width={'100%'} />

                            <Grid item xs={8}>
                                <Input
                                    style={{ marginTop: '0px' }}
                                    required={context !== 'view'}
                                    context={context}
                                    label={'GENERAL.socialName'}
                                    value={values.shortname}
                                    valueName={'shortname'}
                                    maxLength={200}
                                    handleChange={handleChange}
                                    error={requiredShortname}
                                    helperText={
                                        requiredShortname &&
                                        t('GENERAL.requiredField')
                                    }
                                    setRequired={setRequiredShortname}
                                />
                            </Grid>
                            <Box width={'100%'} />

                            <Grid item xs={8}>
                                <Input
                                    style={{ marginTop: '0px' }}
                                    required={context !== 'view'}
                                    context={context}
                                    label={'PERSONAL.email'}
                                    value={values.username}
                                    valueName={'username'}
                                    maxLength={256}
                                    handleChange={handleChange}
                                    error={requiredUsername}
                                    helperText={
                                        requiredUsername && usernameErrorMessage
                                    }
                                    setRequired={setRequiredUsername}
                                    endAdornment={
                                        context !== 'view' ? (
                                            <InputAdornment position="end">
                                                {
                                                    process.env
                                                        .REACT_APP_DEFAULT_EMAIL
                                                }
                                            </InputAdornment>
                                        ) : null
                                    }
                                />
                            </Grid>

                            <Box width={'100%'} />

                            <Grid item xs={4}>
                                <Input
                                    style={{ marginTop: '0px' }}
                                    required={context !== 'view'}
                                    context={context}
                                    label={'PERSONAL.CPF'}
                                    value={values.cpf}
                                    valueName={'cpf'}
                                    maxLength={14}
                                    handleChange={handleChange}
                                    error={requiredCPF}
                                    helperText={requiredCPF && CPFErrorMessage}
                                    setRequired={setRequiredCPF}
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <InputDate
                                    required={context !== 'view'}
                                    context={context}
                                    label={'PERSONAL.birthday'}
                                    value={values.birthday}
                                    valueName={'birthday'}
                                    maxDate={new Date()}
                                    handleChange={handleChange}
                                    error={requiredBirthday}
                                    helperText={
                                        requiredBirthday && birthdayErrorMessage
                                    }
                                    setRequired={setRequiredBirthday}
                                />
                            </Grid>

                            <Box width={'100%'} />

                            {context !== 'new' ? (
                                <Grid item xs={4}>
                                    <InputDate
                                        context={'view'}
                                        label={'ADMIN.createAtUserAdmin'}
                                        value={values.createdAt}
                                        valueName={'birthday'}
                                    />
                                </Grid>
                            ) : null}

                            <Box width={'100%'} />

                            <Grid item xs={8}>
                                <Buttons
                                    onClick={() => {
                                        setMustRedirect(true);
                                    }}
                                    tipo={
                                        context === 'view'
                                            ? 'BN-blue'
                                            : 'BN-noback'
                                    }
                                    conteudo={
                                        context === 'view'
                                            ? t(
                                                  'GENERAL.comeBack'
                                              ).toUpperCase()
                                            : t('GENERAL.cancel').toUpperCase()
                                    }
                                />
                                {context !== 'view' ? (
                                    <Buttons
                                        isLoading={mutation.isLoading}
                                        onClick={handleAdvance}
                                        tipo="BN-blue"
                                        conteudo={`${
                                            context === 'edit'
                                                ? t(
                                                      'GENERAL.save'
                                                  ).toUpperCase()
                                                : t(
                                                      'GENERAL.next'
                                                  ).toUpperCase()
                                        }`}
                                    />
                                ) : null}
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Transition>
        </React.Fragment>
    );
};

export default FormUsers;
