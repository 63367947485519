import { DEFAULT, HR } from "../../types"

export default (state, action) => {
    switch (action.type) {
        case DEFAULT.SET_LOADING:
            return {
                ...state,
                loading: true
            };
        case DEFAULT.SET_LOADING_FALSE:
            return {
                ...state,
                loading: false
            };
        case DEFAULT.RESET_MESSAGE:
            return {
                ...state,
                response: "",
                responseType: ""
            };
        case DEFAULT.SET_HAS_CHANGED:
            return {
                ...state,
                hasChanged: 0
            };
        case HR.GET_COLLABORATORS_VACATIONS:
            return {
                ...state,
                collaboratorsVacations: action.payload
            };
        case HR.GET_COLLABORATOR_VACATION:
            return {
                ...state,
                collaboratorVacation: action.payload
            };
        case HR.GET_VACATION_CALENDAR:
            return {
                ...state,
                vacationCalendar: action.payload
            }
        case HR.CREATE_COLLABORATOR_VACATION_PERIOD:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            }
        case HR.DELETE_COLLABORATOR_VACATION_PERIOD:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            }
        case HR.UPDATE_COLLABORATOR_VACATION_PERIOD:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            }
        default:
            return state;
    }
}