import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { useStyles } from 'components/layout/CommonStyles';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import Buttons from 'components/buttons/Pressed';
import Grid from '@material-ui/core/Grid';
import Input from 'components/inputs/Input';

import { moneyMask } from 'utils/masks/moneyMask';
import { moneyMaskToFloat } from 'utils/general/moneyMaskToFloat';
import { valueToMoneyFloat } from 'utils/general/valueToMoneyFloat';

//Data Fetching
import { useMutationJob } from 'hooks/DataFetching/Quotas/Jobs/UseMutationJobs';

const DialogJob = props => {
    const classes = useStyles();
    const [t] = useTranslation();

    const { open, setOpen, job } = props;
    const mutation = useMutationJob(setOpen);

    const [requiredValue, setRequiredValue] = useState('');

    const [values, setValues] = useState({
        name: '',
        value: ''
    });

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (job) {
            setValues({
                ...values,
                id: job.id,
                name: job.name,
                value: job.price
                    ? moneyMask(valueToMoneyFloat(job.price.toString()))
                    : '0,00'
            });
        }
    }, []);

    const handleChange = (name, value) => {
        let val = value;
        setValues({ ...values, [name]: moneyMask(val) });
    };

    const verifySubmit = () => {
        let res = true;

        return res;
    };

    const handleSubmit = () => {
        const canSubmit = verifySubmit();
        if (canSubmit) {
            let formData = new FormData();

            formData.append(
                'job.price',
                values.value ? moneyMaskToFloat(values.value) : ''
            );
            formData.append('job.id', values.id ? values.id : '');

            mutation.mutate(formData);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle className={classes.Title}>
                <b>{t('HR.editJob')}</b>
                <IconButton
                    style={{ float: 'right', marginTop: -10, marginRight: -17 }}
                    edge="end"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container item spacing={2} md={12} lg={12}>
                    <Grid item xs={12} sm={12}>
                        <Input
                            context={'view'}
                            label={'HR.jobName'}
                            value={values.name}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Input
                            required
                            label={'QUOTAS.quoteValue'}
                            value={`R$ ${values.value}`}
                            valueName={'value'}
                            handleChange={handleChange}
                            maxLength={16}
                            error={requiredValue}
                            helperText={
                                requiredValue && t('GENERAL.requiredField')
                            }
                            setRequired={setRequiredValue}
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Buttons
                    onClick={handleClose}
                    tipo="BN-noback"
                    conteudo={t('GENERAL.cancel').toUpperCase()}
                />
                <Buttons
                    isLoading={mutation.isLoading}
                    onClick={handleSubmit}
                    tipo="BN-blue"
                    conteudo={t('GENERAL.save').toUpperCase()}
                />
            </DialogActions>
        </Dialog>
    );
};

export default DialogJob;
