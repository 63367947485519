import React from 'react';
import { useStyles } from 'components/layout/CommonStyles';
import { inputStyles } from './Styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

const Input = ({
    context,
    value,
    isSelect,
    valueName,
    label,
    maxLength,
    handleChange,
    required,
    helperText,
    arraySelected,
    rows,
    error,
    type,
    setRequired,
    count,
    fieldType,
    placeholder,
    endAdornment,
    ...props
}) => {
    const classes = useStyles();
    const styles = inputStyles();
    const [t] = useTranslation();
    const numberRegex = new RegExp('^[0-9]+$');
    const floatRegex = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');
    // const floatRegex = ^(([0-9]+(?:\.[0-9]+)?)|([0-9]*(?:\.[0-9]+)?))$

    const helperContent = () => {
        if (rows && context !== 'view' && value && maxLength) {
            return `${
                value.replace(/(\r\n|\n|\r)/g, '  ').length
            }/${maxLength}`;
        } else if (error) {
            return t(helperText);
        } else {
            return '';
        }
    };

    return (
        <TextField
            type={fieldType}
            name={valueName}
            multiline={rows && rows > 0}
            fullWidth={rows && rows > 0}
            rows={rows && rows}
            variant={rows && rows > 0 && 'outlined'}
            disabled={context === 'view'}
            select={isSelect}
            error={error}
            value={value}
            placeholder={placeholder}
            helperText={helperContent()}
            FormHelperTextProps={
                !error
                    ? {
                          className: styles.caractersHelperText
                      }
                    : null
            }
            InputProps={{
                endAdornment: endAdornment
            }}
            className={classes.textField}
            label={
                t(label) + (count ? ' ' + count : '') + (required ? '*' : '')
            }
            onChange={ev => {
                if (
                    isSelect ||
                    maxLength === undefined ||
                    (ev.target.value.length >= 0 &&
                        ev.target.value.replace(/(\r\n|\n|\r)/g, '  ').length <=
                            maxLength)
                ) {
                    if (type === 'number') {
                        if (
                            numberRegex.test(ev.target.value) ||
                            ev.target.value.length === 0
                        ) {
                            handleChange(valueName, ev.target.value);
                            if (typeof setRequired === 'function') {
                                setRequired(false);
                            }
                        }
                    } else if (type === 'float') {
                        if (
                            ev.target.value.match(floatRegex) ||
                            ev.target.value.length === 0
                        ) {
                            handleChange(valueName, ev.target.value);
                            if (typeof setRequired === 'function') {
                                setRequired(false);
                            }
                        }
                    } else {
                        handleChange(valueName, ev.target.value);
                        if (typeof setRequired === 'function') {
                            setRequired(false);
                        }
                    }
                }
            }}
            /*
             *  Code for restricting input string to numbers for when
             *  the solution in onChange it is not working properly.
             */

            // onKeyPress={(e) => {
            //     if (type === "number") {
            //         return !/[0-9]/.test(e.key) && e.preventDefault()
            //     } else {
            //         return e
            //     }
            // }}
            margin="normal"
            {...props}
        >
            {arraySelected &&
                arraySelected.map(item => (
                    <MenuItem
                        disabled={item.deleted}
                        key={item.value}
                        value={item.value}
                    >
                        {item.label}
                    </MenuItem>
                ))}
        </TextField>
    );
};

export default Input;
