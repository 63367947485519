/* eslint-disable */
import { makeStyles, withStyles } from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Fab from "@material-ui/core/Fab";
import Paper from "@material-ui/core/Paper";
import Step from "@material-ui/core/Step";
import StepConnector from "@material-ui/core/StepConnector";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import Typography from "@material-ui/core/Typography";
import Check from "@material-ui/icons/Check";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Save from "@material-ui/icons/Save";
import clsx from "clsx";
import { useStyles } from "components/layout/CommonStyles";
import DashboardNavBar from "components/layout/DashboardNavBar";
import SideBar from "components/layout/sidebar/Sidebar";
import ProjectsContext from "context/projects/projectsContext";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import GeneralInformations from "./ProjectSteps/GeneralInformations";
import { animated, useSpring, useTransition } from "react-spring";
import Footer from "components/layout/Footer";


const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 30 // metade do tamanho da bola pra fazer a linh aficar no centro
  },
  active: {
    "& $line": {
      backgroundColor: "#2c3e51"
    }
  },
  completed: {
    "& $line": {
      backgroundColor: "#3fe2d3"
    }
  },
  line: {
    height: 5,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1
  }
})(StepConnector);

const customStepsStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 60,
    height: 60,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center"
  },
  active: {
    backgroundColor: "#2c3e51",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)"
  },
  completed: {
    backgroundColor: "#3fe2d3"
  }
});

function customStep(props) {
  const classes = customStepsStyles();
  const { active, completed } = props;

  // podemos fazer um swtch em props.icons para seleccionar
  // icones relacionados ao passos
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
    >
      {completed ? <Check /> : props.icon}
    </div>
  );
}

function NewProject() {
  const activeStep = 0;
  const projectsContext = useContext(ProjectsContext);
  //const [activeStep, setActiveStep] = useState(0);
  const { changeStepFlow } = projectsContext;
  const [t] = useTranslation();
  const classes = useStyles();
  const steps = [
    t("PROJECTS.step.generalInformations"),
    t("PROJECTS.step.cooperationTerm"),
    t("PROJECTS.step.goal"),
    t("PROJECTS.step.scope"),
    t("PROJECTS.step.headings"),
    t("PROJECTS.step.schedule")
  ];

  const handleSubmit = () => {
    //setActiveStep(prevActiveStep => prevActiveStep + 1);
    changeStepFlow(1);
  };

  const transitions = useTransition(false, null, {
    from: { opacity: 0 },
    enter: {
      opacity: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "calc(100vh - 70px)"
    },
    leave: { opacity: 0 }
  });

  return transitions.map(({ item, key, props }) =>
  item ? (
    <animated.div key={key} style={props}>
      <Spinner />
    </animated.div>
  ) : (
    <div key={key}>
      <DashboardNavBar />
      <SideBar />
      <animated.div style={props}>
      <div className="container">
        <Breadcrumbs
          className={classes.breadCrumbs}
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Typography>{t("GENERAL.planning")}</Typography>
          <Typography>{t("PROFILES.form.workPlan")}</Typography>
          <Link className={classes.link} to="/projects">
            {t("PROJECTS.projects")}
          </Link>
          <Typography>{t("GENERAL.add")}</Typography>
        </Breadcrumbs>
        <div className="container-header">
          <div className="title">
            <h1 className={classes.title}>{t("PROJECTS.addProject")}</h1>
          </div>
        </div>
        <div style={{ paddingBottom: "35px" }}>
          <Paper className={classes.root}>
            <div className={classes.header}>
              {t("GENERAL.progress").toUpperCase()}
            </div>
            <div>
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                connector={<ColorlibConnector />}
              >
                {steps.map(label => (
                  <Step key={label} className={classes.stepNumber}>
                    <StepLabel StepIconComponent={customStep}>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
          </Paper>
        </div>
        <GeneralInformations />
        <Fab
          color="primary"
          aria-label="save"
          className={classes.fab}
          onClick={handleSubmit}
        >
          <Save />
        </Fab>
        </div>
      <Footer />
     </animated.div>
    </div>
   )
  );
}

export default NewProject;
