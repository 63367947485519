import React from "react";
import "../../../App.css";
import SideBar from "../../../components/layout/sidebar/Sidebar";
import DashboardNavBar from "../../../components/layout/DashboardNavBar";
import MyRequests from "./MyRequests";

const CallMyRequests = ({ match }) => {
  
  return (
    <div>
      <DashboardNavBar />
      <SideBar />
      <MyRequests/>
    </div>
  );
}

export default CallMyRequests;