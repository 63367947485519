export const humanResourceData = [
    {
        screen: 'MY_INFORMATIONS',
        allowRead: false
    },
    {
        screen: 'COLLABORATORS',
        allowRead: false,
        allowEdit: false,
        isManager: false
    },
    {
        screen: 'BIRTHDAYS',
        allowRead: false
    },
    {
        screen: 'MY_CLOCK_RECORD',
        allowRead: false,
        allowEdit: false
    },
    {
        screen: 'COLLABORATORS_CLOCK_RECORDS',
        allowRead: false,
        isManager: false
    },
    {
        screen: 'MY_JUSTIFICATIONS',
        allowRead: false,
        allowCreate: false,
        allowEdit: false,
        allowDelete: false
    },
    {
        screen: 'COLLABORATORS_JUSTIFICATIONS',
        allowRead: false,
        allowCreate: false,
        isManager: false
    },
    {
        screen: 'MY_VACATIONS',
        allowRead: false
    },
    {
        screen: 'COLLABORATORS_VACATIONS',
        allowRead: false,
        allowEdit: false,
        isManager: false
    },
    {
        screen: 'VACATION_CALENDAR',
        allowRead: false
    },
    {
        screen: 'JOBS',
        allowRead: false,
        allowCreate: false,
        allowEdit: false,
        allowDelete: false
    },
    {
        screen: 'ACTIVITY_CATEGORIES',
        allowRead: false,
        allowCreate: false,
        allowEdit: false,
        allowDelete: false
    },
    {
        screen: 'HOLIDAYS_AND_BREAKS',
        allowRead: false,
        allowCreate: false,
        allowEdit: false,
        allowDelete: false
    },
    {
        screen: 'ALLOWANCE_REASON',
        allowRead: false,
        allowCreate: false,
        allowEdit: false,
        allowDelete: false
    },
    {
        screen: 'COLLABORATORS_FINGERPRINT',
        allowRead: false,
        allowCreate: false,
        allowDelete: false,
        tab: 'PHYSICAL',
        helpTooltip: 'HR.helpTooltipCollaboratorsFingerprint'
    }
];
