import { DEFAULT, PROFILES, USERS } from '../types';

export default (state, action) => {
    switch (action.type) {
        case DEFAULT.SET_LOADING:
            return {
                ...state,
                loading: true
            };
        case USERS.GET_USER:
            return {
                ...state,
                user: action.payload,
                loading: false
            };
        case USERS.GET_USERS:
            return {
                ...state,
                users: action.payload,
                loading: false
            };
        case USERS.GET_USERS_BY_PERMISSIONS:
            return {
                ...state,
                usersByPermissions: action.payload,
                loading: false
            };
        case USERS.GET_LIBRARY_MANAGER_USERS:
            return {
                ...state,
                libraryManagersUsers: action.payload,
                loading: false
            };
        case USERS.GET_LIBRARY_COORDINATION_USERS:
            return {
                ...state,
                libraryCoordinationUsers: action.payload,
                loading: false
            };
        case USERS.GET_ALL_USERS:
            return {
                ...state,
                allusers: action.payload,
                loading: false
            };
        case USERS.DELETE_USER:
            return {
                ...state,
                message: action.payload,
                loading: false
            };
        case PROFILES.GET_PROFILES:
            return {
                ...state,
                profiles: action.payload,
                loading: false
            };
        case USERS.UPDATE_USER:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged,
                loading: false
            };
        case DEFAULT.RESET_MESSAGE:
            return {
                ...state,
                response: '',
                responseType: ''
            };
        case DEFAULT.SET_HAS_CHANGED:
            return {
                ...state,
                hasChanged: 0
            };
        default:
            return state;
    }
};
