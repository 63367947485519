import { makeStyles } from '@material-ui/core/styles';

export const screenStyles = makeStyles(theme => ({
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    dialogDisponible: {
        margin: '28px 0 18px',
        display: 'flex',
        alignItems: 'center'
    },
    dialogBookRequisitionContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '5px'
    },
    dialogTopic: {
        margin: '8px 0'
    },
    dialogImage: {
        width: '110px',
        height: '150px'
    },
    dialogButtonsWrapper: {
        padding: '32px 24px 16px'
    },
    dialogFinesDetailsButtonsWrapper: {
        padding: '0px 24px 16px'
    },
    dialogButton: {
        padding: '8px 16px'
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        wordBreak: 'break-word',
        marginRight: '32px'
    },
    dialogMoreDetailsDisponible: {
        margin: '0 0 16px',
        display: 'flex',
        alignItems: 'center'
    },
    dialogMoreDetailsImage: {
        width: '100%',
        height: '100%'
    },
    dialogMoreDetailsContainerUpper: {
        display: 'flex',
        marginBottom: '12px'
    },
    dialogMoreDetailsLeftWrapper: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '375px',
        padding: '0 24px 0 0'
    },
    dialogMoreDetailsText: {
        margin: '8px 0',
        borderBottom: '1px dashed #6d6f71',
        lineHeight: '22px',
        marginBottom: '16px'
    },
    dialogMoreDetailsAuthorEditionWrapper: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    dialogUnitComponent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        minHeight: '60px'
    },
    dialogMoreDetailsContainerBottom: {
        display: 'flex',
        flexDirection: 'column'
    },
    DialogBookMoreDetailsDescription: {
        padding: '8px',
        border: '1px solid #6d6f71',
        borderRadius: '4px',
        height: '75px',
        overflow: 'auto',
        marginTop: '8px',
        lineHeight: '18px'
    },
    libraryRadioButton: {
        display: 'flex',
        margin: '8px 0 16px'
    },
    dialogExemplarSelectContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '24px'
    },
    dialogLoanDisponible: {
        display: 'flex',
        alignItems: 'center'
    },
    dialogMoreDetailsPublisher: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        margin: '8px 0',
        borderBottom: '1px dashed #6d6f71',
        lineHeight: '22px',
        marginBottom: '16px'
    },
    collaboratorImage: {
        width: '32px',
        height: '32px',
        borderRadius: '36px',
        marginRight: '6px'
    },
    cardImage: {
        width: '160px',
        height: '220px',
        margin: '0 auto'
    },
    dialogFinesStatusWrapper: {
        display: 'flex',
        alignItems: 'center',
        height: '20px',
        '& > *': {
            marginRight: '10px'
        }
    }
}));
