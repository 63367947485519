import React from 'react';
import '../../../App.css';
import SideBar from 'components/layout/sidebar/Sidebar';
import DashboardNavBar from 'components/layout/DashboardNavBar';
import Form from './Form/Form';

const CallCreateUser = () => {
    return (
        <div>
            <DashboardNavBar />
            <SideBar />
            <Form context={'new'} />
        </div>
    );
};
export default CallCreateUser;
