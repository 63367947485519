import React from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useStyles } from 'components/layout/CommonStyles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import GetAppIcon from '@material-ui/icons/GetApp';
import CloseIcon from '@material-ui/icons/Close';
import Dropzone from 'react-dropzone';

import Edit from '@material-ui/icons/Edit';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

import { inputStyles } from './Styles';

const InputFile = ({
    context,
    file,
    setFile,
    fileName,
    filePath,
    label,
    placeholder,
    required,
    error,
    helperText,
    setRequired,
    setHasChanged,
    ...props
}) => {
    const fileDownload = require('js-file-download');
    const classes = useStyles();
    const styles = inputStyles();
    const [t] = useTranslation();

    const onDrop = acceptedFiles => {
        if (acceptedFiles[0] !== undefined) {
            if (setRequired) {
                setRequired(false);
            }
            setFile(
                acceptedFiles.map(file =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })
                )
            );
        }
    };

    const onRemoveFile = () => {
        setFile([]);
        if (setHasChanged) {
            setHasChanged(true);
        }
    };

    const onClickDownload = async slashPath => {
        if (slashPath) {
            const noSlashPath = slashPath.replace(/\//g, ';');
            const url = process.env.REACT_APP_FILE + noSlashPath;
            axios
                .get(url, {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    },
                    responseType: 'blob'
                })
                .then(function (res) {
                    const blob = new Blob([res.data], {});
                    const name = slashPath.split('/').pop();
                    fileDownload(blob, name);
                });
        }
    };

    return (
        <React.Fragment>
            <Dropzone
                maxFiles={1}
                onDrop={onDrop}
                multiple={false}
                noClick={context === 'view' || file.length}
                accept="application/pdf,
            application/msword,
            application/vnd.openxmlformats-officedocument.wordprocessingml.document,
            image/jpeg,
            image/png,
            application/vnd.ms-excel,
            application/vnd.ms-excel.sheet.macroEnabled.12,
            application/msexcel,
            application/x-msexcel,
            application/x-ms-excel,
            application/x-excel,
            application/x-dos_ms_excel,
            application/xls,
            application/x-xls,
            application/x-msi,
            application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            >
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps({ styles: classes.inputFile })}>
                        <input {...getInputProps()} />
                        <TextField
                            className={styles.inputFile}
                            variant="filled"
                            label={t(label) + (required ? '*' : '')}
                            autoComplete="off"
                            error={error}
                            helperText={helperText}
                            disabled={context === 'view'}
                            value={
                                context === 'view'
                                    ? fileName
                                    : file.length
                                    ? file[0].name
                                    : ''
                            }
                            placeholder={placeholder}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true
                            }}
                        />
                        <Button
                            className={
                                context === 'view' || !file.length
                                    ? styles.inputFileButton
                                    : styles.inputFileButtonRed
                            }
                            onClick={
                                context === 'view'
                                    ? () =>
                                          onClickDownload(
                                              filePath + '/' + fileName
                                          )
                                    : file.length
                                    ? () => onRemoveFile()
                                    : null
                            }
                        >
                            <div className={styles.inputFileButtonWrapper}>
                                {context === 'view' ? (
                                    <GetAppIcon style={{ fontSize: 24 }} />
                                ) : file.length ? (
                                    <CloseIcon style={{ fontSize: 24 }} />
                                ) : (
                                    <AttachFileIcon style={{ fontSize: 24 }} />
                                )}
                                <span>
                                    {context === 'view'
                                        ? t('GENERAL.download')
                                        : file.length
                                        ? t('GENERAL.exclude')
                                        : t('GENERAL.attach')}
                                </span>
                            </div>
                        </Button>
                    </div>
                )}
            </Dropzone>
        </React.Fragment>
    );
};

export default InputFile;
