import React, { useState, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import Badge from '@material-ui/core/Badge';
import CreditSolicitationForm from './Tabs/CreditSolicitation/CreditSolicitationForm';
import BillsForm from './Tabs/Bills/BillsForm';
import TransactionsForm from './Tabs/Transactions/TransactionsForm';
import BillingSheetForm from './Tabs/BillingSheet/BillingSheetForm';
import LateForm from './Tabs/Late/LateForm';
import ExpensesForm from './Tabs/Expenses/ExpensesForm';
import { screenStyles } from './Styles';
import { Screen, Allow } from 'global/constants';
import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';
import { QueryGetValue } from 'utils/general/QueryString';
import CashFlowAnalysisForm from './Tabs/CashFlowAnalysis/CashFlowAnalysisForm';

const DashboardTabPanel = props => {
    const { isPersonalActive, setPersonalActive, isTabClick, setIsTabClick } =
        props;
    const classes = useStyles();
    const [t] = useTranslation();
    const [value, setValue] = useState(0);
    const styles = screenStyles();

    const [sectionQuery, setSectionQuery] = useState('');
    const [tabQuery, setTabQuery] = useState('');

    const [badgeValue, setBadgeValue] = useState(0);

    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    useEffect(() => {
        setSectionQuery(QueryGetValue('section'));
        setTabQuery(QueryGetValue('tab'));
    }, []);

    // Reset Tab value
    useEffect(() => {
        if (isTabClick) {
            setValue(0);
        }
        setIsTabClick(false);
    }, [isPersonalActive]);

    function TabPanel(props) {
        const { children, value, index } = props;

        return <div>{value === index && children}</div>;
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const personalPermissions = [
        {
            tab: Screen.QUOTAS_DASHBOARD_PERSONAL_TAB_BILLS,
            hasPermission: GetGeneralPermissionForAction(
                Screen.QUOTAS_DASHBOARD_PERSONAL_TAB_BILLS,
                Allow.READ
            ),
            component: <BillsForm />
        },
        {
            tab: Screen.QUOTAS_DASHBOARD_PERSONAL_TAB_SOLICITATIONS,
            hasPermission: GetGeneralPermissionForAction(
                Screen.QUOTAS_DASHBOARD_PERSONAL_TAB_SOLICITATIONS,
                Allow.READ
            ),
            component: <CreditSolicitationForm isLSBD={false} />
        },
        {
            tab: Screen.QUOTAS_DASHBOARD_PERSONAL_TAB_TRANSACTIONS,
            hasPermission: GetGeneralPermissionForAction(
                Screen.QUOTAS_DASHBOARD_PERSONAL_TAB_TRANSACTIONS,
                Allow.READ
            ),
            component: <TransactionsForm isLSBD={false} />
        }
    ];

    const lsbdPermissions = [
        {
            tab: Screen.QUOTAS_DASHBOARD_LSBD_TAB_SOLICITATIONS,
            hasPermission: GetGeneralPermissionForAction(
                Screen.QUOTAS_DASHBOARD_LSBD_TAB_SOLICITATIONS,
                Allow.READ
            ),
            component: (
                <CreditSolicitationForm
                    isLSBD={true}
                    setBadgeValue={setBadgeValue}
                />
            )
        },
        {
            tab: Screen.QUOTAS_DASHBOARD_LSBD_TAB_BILLINGSHEET,
            hasPermission: GetGeneralPermissionForAction(
                Screen.QUOTAS_DASHBOARD_LSBD_TAB_BILLINGSHEET,
                Allow.READ
            ),
            component: <BillingSheetForm />
        },
        {
            tab: Screen.QUOTAS_DASHBOARD_LSBD_TAB_LATE,
            hasPermission: GetGeneralPermissionForAction(
                Screen.QUOTAS_DASHBOARD_LSBD_TAB_LATE,
                Allow.READ
            ),
            component: <LateForm />
        },
        {
            tab: Screen.QUOTAS_DASHBOARD_LSBD_TAB_TRANSACTIONS,
            hasPermission: GetGeneralPermissionForAction(
                Screen.QUOTAS_DASHBOARD_LSBD_TAB_TRANSACTIONS,
                Allow.READ
            ),
            component: <TransactionsForm isLSBD={true} />
        },
        {
            tab: Screen.QUOTAS_DASHBOARD_LSBD_TAB_EXPENSES,
            hasPermission: GetGeneralPermissionForAction(
                Screen.QUOTAS_DASHBOARD_LSBD_TAB_EXPENSES,
                Allow.READ
            ),
            component: <ExpensesForm />
        },
        {
            tab: Screen.QUOTAS_DASHBOARD_LSBD_TAB_CASHFLOW,
            hasPermission: GetGeneralPermissionForAction(
                Screen.QUOTAS_DASHBOARD_LSBD_TAB_CASHFLOW,
                Allow.READ
            ),
            component: <CashFlowAnalysisForm />
        }
    ];

    const renderTab = (tabsArr, value) => {
        const tabsArrWithPermissions = tabsArr.filter(a => a.hasPermission);

        if (
            tabsArrWithPermissions.length === 0 ||
            value > tabsArrWithPermissions.length - 1
        ) {
            return null;
        }

        return tabsArrWithPermissions[value].component;
    };

    const tabContext = () => {
        if (isPersonalActive) {
            return renderTab(personalPermissions, value);
        } else {
            return renderTab(lsbdPermissions, value);
        }
    };

    const getIndexByPermission = (permissionArr, permission) => {
        const p = permissionArr.find(item => item.tab === permission);

        if (!p || !p.hasPermission) {
            return 0;
        }
        return permissionArr.findIndex(item => item.tab === permission);
    };

    useEffect(() => {
        if (sectionQuery === 'PERSONAL') {
            setPersonalActive(true);
            switch (tabQuery) {
                case 'BILLS':
                    setValue(
                        getIndexByPermission(
                            personalPermissions,
                            Screen.QUOTAS_DASHBOARD_PERSONAL_TAB_BILLS
                        )
                    );
                    break;
                case 'SOLICITATIONS':
                    setValue(
                        getIndexByPermission(
                            personalPermissions,
                            Screen.QUOTAS_DASHBOARD_PERSONAL_TAB_SOLICITATIONS
                        )
                    );
                    break;
                case 'TRANSACTIONS':
                    setValue(
                        getIndexByPermission(
                            personalPermissions,
                            Screen.QUOTAS_DASHBOARD_PERSONAL_TAB_TRANSACTIONS
                        )
                    );
                    break;

                default:
                    setValue(0);
                    break;
            }
        }

        if (sectionQuery === 'LSBD') {
            setPersonalActive(false);
            switch (tabQuery) {
                case 'SOLICITATIONS':
                    setValue(
                        getIndexByPermission(
                            lsbdPermissions,
                            Screen.QUOTAS_DASHBOARD_LSBD_TAB_SOLICITATIONS
                        )
                    );
                    break;
                case 'BILLINGSHEET':
                    setValue(
                        getIndexByPermission(
                            lsbdPermissions,
                            Screen.QUOTAS_DASHBOARD_LSBD_TAB_BILLINGSHEET
                        )
                    );
                    break;
                case 'LATE':
                    setValue(
                        getIndexByPermission(
                            lsbdPermissions,
                            Screen.QUOTAS_DASHBOARD_LSBD_TAB_LATE
                        )
                    );
                    break;

                case 'TRANSACTIONS':
                    setValue(
                        getIndexByPermission(
                            lsbdPermissions,
                            Screen.QUOTAS_DASHBOARD_LSBD_TAB_TRANSACTIONS
                        )
                    );
                    break;
                case 'EXPENSES':
                    setValue(
                        getIndexByPermission(
                            lsbdPermissions,
                            Screen.QUOTAS_DASHBOARD_LSBD_TAB_EXPENSES
                        )
                    );
                    break;
                case 'CASHFLOW':
                    setValue(
                        getIndexByPermission(
                            lsbdPermissions,
                            Screen.QUOTAS_DASHBOARD_LSBD_TAB_CASHFLOW
                        )
                    );
                    break;

                default:
                    setValue(0);
                    break;
            }
        }
    }, [sectionQuery, tabQuery]);

    return (
        <React.Fragment>
            <Paper>
                <AppBar
                    position="static"
                    style={{ boxShadow: 'none', transform: 'none' }}
                >
                    {isPersonalActive ? (
                        // Personal
                        <Tabs
                            selectionFollowsFocus
                            style={{
                                backgroundColor: '#fff',
                                color: '#6D6F71'
                            }}
                            indicatorColor="primary"
                            textColor="primary"
                            value={value}
                            onChange={handleChange}
                        >
                            {GetGeneralPermissionForAction(
                                Screen.QUOTAS_DASHBOARD_PERSONAL_TAB_BILLS,
                                Allow.READ
                            ) ? (
                                <Tab
                                    style={{ fontWeight: 'bold' }}
                                    label={t('QUOTAS.bills').toUpperCase()}
                                />
                            ) : null}

                            {GetGeneralPermissionForAction(
                                Screen.QUOTAS_DASHBOARD_PERSONAL_TAB_SOLICITATIONS,
                                Allow.READ
                            ) ? (
                                <Tab
                                    style={{ fontWeight: 'bold' }}
                                    label={t(
                                        'QUOTAS.solicitations'
                                    ).toUpperCase()}
                                />
                            ) : null}

                            {GetGeneralPermissionForAction(
                                Screen.QUOTAS_DASHBOARD_PERSONAL_TAB_TRANSACTIONS,
                                Allow.READ
                            ) ? (
                                <Tab
                                    style={{ fontWeight: 'bold' }}
                                    label={t('QUOTAS.movements').toUpperCase()}
                                />
                            ) : null}
                        </Tabs>
                    ) : (
                        // LSBD
                        <Tabs
                            selectionFollowsFocus
                            style={{
                                backgroundColor: '#fff',
                                color: '#6D6F71'
                            }}
                            indicatorColor="primary"
                            textColor="primary"
                            // variant="fullWidth"
                            value={value}
                            onChange={handleChange}
                        >
                            {GetGeneralPermissionForAction(
                                Screen.QUOTAS_DASHBOARD_LSBD_TAB_SOLICITATIONS,
                                Allow.READ
                            ) && (
                                <Tab
                                    style={{ fontWeight: 'bold' }}
                                    label={
                                        <div className={styles.badgeWrapper}>
                                            {t(
                                                'QUOTAS.solicitations'
                                            ).toUpperCase()}
                                            <Badge
                                                badgeContent={badgeValue}
                                                color="error"
                                                className={styles.iconLabel}
                                            />
                                        </div>
                                    }
                                />
                            )}

                            {GetGeneralPermissionForAction(
                                Screen.QUOTAS_DASHBOARD_LSBD_TAB_BILLINGSHEET,
                                Allow.READ
                            ) ? (
                                <Tab
                                    style={{ fontWeight: 'bold' }}
                                    label={t('QUOTAS.payroll').toUpperCase()}
                                />
                            ) : null}

                            {GetGeneralPermissionForAction(
                                Screen.QUOTAS_DASHBOARD_LSBD_TAB_LATE,
                                Allow.READ
                            ) ? (
                                <Tab
                                    style={{ fontWeight: 'bold' }}
                                    label={t('QUOTAS.delays').toUpperCase()}
                                />
                            ) : null}

                            {GetGeneralPermissionForAction(
                                Screen.QUOTAS_DASHBOARD_LSBD_TAB_TRANSACTIONS,
                                Allow.READ
                            ) ? (
                                <Tab
                                    style={{ fontWeight: 'bold' }}
                                    label={t('QUOTAS.movements').toUpperCase()}
                                />
                            ) : null}

                            {GetGeneralPermissionForAction(
                                Screen.QUOTAS_DASHBOARD_LSBD_TAB_EXPENSES,
                                Allow.READ
                            ) ? (
                                <Tab
                                    style={{ fontWeight: 'bold' }}
                                    label={t('QUOTAS.expenses').toUpperCase()}
                                />
                            ) : null}

                            {GetGeneralPermissionForAction(
                                Screen.QUOTAS_DASHBOARD_LSBD_TAB_CASHFLOW,
                                Allow.READ
                            ) && (
                                <Tab
                                    style={{ fontWeight: 'bold' }}
                                    label={t(
                                        'QUOTAS.cashFlowAnalysis'
                                    ).toUpperCase()}
                                />
                            )}
                        </Tabs>
                    )}
                </AppBar>

                {tabContext()}
            </Paper>
        </React.Fragment>
    );
};

export default DashboardTabPanel;
