import React, { useState, useEffect } from "react";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

window.Highcharts = Highcharts;

const DonutChart = props => {
    const {
        approvedRequests,
        deniedRequests,
        waitingApproval,
        waitingOvertime,
        approvedOvertime,
        withoutInconsistency,
        pendingRequests,
        callbackLinkGraph
    } = props;

    const ref = React.createRef();

    const [browserData, setBrowserData] = useState([]);
    const [versionsData, setVersionsData] = useState([]);

    const [linkGraph, setLinkGraph] = useState("");

    const [valueDistance, setValueDistance] = useState(-30);

    function svgString2Image(svgString, width, height, format, callback) {
        // set default for format parameter
        format = format ? format : 'png';
        // SVG data URL from SVG string
        var svgData =  unescape(encodeURIComponent(svgString));
        // create canvas in memory(not in DOM)
        var canvas = document.createElement('canvas');
        // get canvas context for drawing on canvas
        var context = canvas.getContext('2d');
        // set canvas size
        canvas.width = width;
        canvas.height = height;
        // create image in memory(not in DOM)
        var image = new Image();
        // later when image loads run this
    
        image.onload = function () { // async (happens later)
            // clear canvas
            context.clearRect(0, 0, width, height);
            // draw image with SVG data to canvas
            context.drawImage(image, 0, 0, width, height);
            // snapshot canvas as png
            var pngData = canvas.toDataURL('image/' + format);
            // pass png data URL to callback
            callback(pngData);
        }; // end async
        // start loading SVG data into in memory image
        image.src = svgData;  
    }

    useEffect(() => {
        let total = approvedRequests + deniedRequests + waitingApproval + waitingOvertime + approvedOvertime + withoutInconsistency + pendingRequests;
        total = total === 0 ? 1 : total;
        const colors = Highcharts.getOptions().colors;
        const categories = [
            'Dias Regulares',
            'Dias Irregulares',
            'Hora Extra',
        ];
        const data = [
            {
                y: (approvedRequests + withoutInconsistency) * 100 / total,
                color: colors[2],
                drilldown: {
                    name: 'Dias Regulares',
                    categories: [
                        'Requisições Aprovadas',
                        'Sem inconsistências'
                    ],
                    data: [
                        (approvedRequests * 100) / total,
                        (withoutInconsistency * 100) / total,
                    ]
                }
            },
            {
                y: (pendingRequests + deniedRequests + waitingApproval) * 100 / total,
                color: colors[1],
                drilldown: {
                    name: 'Dias Irregulares',
                    categories: [
                        'Requisições Pendentes',
                        'Requisições Recusadas',
                        'Aguardando Aprovação'

                    ],
                    data: [
                        (pendingRequests * 100) / total,
                        (deniedRequests * 100) / total,
                        (waitingApproval * 100) / total,
                    ]
                }
            },
            {
                y: (approvedOvertime + waitingOvertime) * 100 / total,
                color: colors[0],
                drilldown: {
                    name: 'Hora Extra',
                    categories: [
                        'Aprovadas',
                        'Aguardando Aprovação'
                    ],
                    data: [
                        (approvedOvertime * 100) / total,
                        (waitingOvertime * 100) / total,
                    ]
                }
            }
        ];

        let i, j;
        const dataLen = data.length;
        let drillDataLen, brightness;
        const browser = [];
        const versions = [];

        // Build the data arrays
        for (i = 0; i < dataLen; i += 1) {

            // add browser data
            browser.push({
                name: categories[i],
                y: data[i].y,
                color: data[i].color,
                // dataLabels: {
                //     color: categories[i] === "Hora Extra" 
                //     ? colors[0] 
                //     : ( categories[i] === "Dias Irregulares" 
                //         ?  colors[1] : colors[2]
                //         ),
                //     style:{
                //         fontSize: '14px',
                //         //textOutline:'0px contrast',
                //     }
                // }  
            });

            // add version data
            drillDataLen = data[i].drilldown.data.length;
            for (j = 0; j < drillDataLen; j += 1) {
                brightness = 0.2 - (j / drillDataLen) / 5;
                versions.push({
                    name: data[i].drilldown.categories[j],
                    y: data[i].drilldown.data[j],
                    color: Highcharts.color(data[i].color).brighten(brightness).get(),
                     dataLabels: {
                        color: data[i].drilldown.categories[j] === "Aprovadas" ||
                        data[i].drilldown.categories[j] ==="Aguardando Aprovação" 
                        ? colors[0] 
                        : (
                            data[i].drilldown.categories[j] === "Requisições Pendentes" ||
                            data[i].drilldown.categories[j] === "Requisições Recusadas" ||
                            data[i].drilldown.categories[j] === "Aguardando Aprovação" 
                            ?  colors[1] : colors[2]
                        ),
                        style:{
                            fontSize: '12px',
                            textOutline:'0px contrast',
                        }
                    }  
                });
            }
        }
        
        setBrowserData(browser);
        setVersionsData(versions);
    }, [approvedRequests, deniedRequests, waitingApproval, approvedOvertime, waitingOvertime, withoutInconsistency, pendingRequests]);
    
    const colors = Highcharts.getOptions().colors;
    const options = {
        chart: {
            type: 'pie'
        },
        title: {
            text: null
        },
        plotOptions: {
            pie: {
                shadow: false,
                center: ['45%', '55%']
            }
        },
        tooltip: {
            valueSuffix: '%'
        },
        exporting: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        series: [{
            name: 'Browsers',
            data: browserData,
            size: '60%',   
            dataLabels: {
                formatter: function () {
                    return this.y > 0 ? this.point.name : null;
                },
                color: '#ffffff',
                distance: 58,
            }
        }, {
            name: 'Versions',
            data: versionsData,
            size: '57%',
            innerSize: '60%',
            dataLabels: {
                formatter: function () {
                    // display only if larger than 1
                    return this.y > 1 ? '<b>' + this.point.name + ':</b> ' +
                        this.y.toFixed(2) + '%' : null;
                },
                distance: 15,
                //color: colors[2],
            },
            id: 'versions'
        }],
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 450
                },
                chartOptions: {
                    series: [{
                    }, {
                        id: 'versions',
                        dataLabels: {
                            enabled: false
                        }
                    }]
                }
            }]
        }
    }

    useEffect(() => {
        //setTimeout(function(){ getGraph(); }, 1000);
        getGraph()
    },[props,
        approvedRequests.length, 
        deniedRequests, 
        waitingApproval, 
        approvedOvertime, 
        waitingOvertime, withoutInconsistency, pendingRequests])

    function getGraph(){
        setLinkGraph("data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(ref.current.chart.getSVG()))));
        svgString2Image(linkGraph, 750, 550, 'png', /* callback that gets png data URL passed to it */function (pngData) {
           callbackLinkGraph(pngData)
        }); 
    }

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={ref}
        />
    );
}

export default DonutChart;