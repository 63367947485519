import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogUnit from 'components/dialogs/DialogUnit';
import DialogUnitWithIcon from 'components/dialogs/DialogUnitWithIcon';
import DialogPasswordConfirm from 'components/dialogs/DialogPasswordConfirm';
import { Grid, Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import Input from 'components/inputs/Input';
import InputDate from 'components/inputs/InputDate';
import CloseIcon from '@material-ui/icons/Close';
import Buttons from 'components/buttons/Pressed';
import Typography from 'components/typography/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import Avatar from '@material-ui/core/Avatar';

import { useMutationAddBill } from 'hooks/DataFetching/Quotas/Users/useMutationAddBill';

import { dateValidation } from 'utils/validation/DateValidation';
import { valueToMoneyFloat } from 'utils/general/valueToMoneyFloat';
import { moneyMask } from 'utils/masks/moneyMask';
import { moneyMaskToFloat } from 'utils/general/moneyMaskToFloat';
import { FormatDateToDataBase } from 'utils/dates/FormatDateToDataBase';

import { translateBillStatus, BillStatus } from '../ScreenUtils';
import { screenStyles } from '../Styles';

const DialogAddBill = props => {
    const styles = screenStyles();
    const [t] = useTranslation();

    const { open, setOpen, viewValues } = props;

    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const [values, setValues] = useState({
        value: moneyMask(valueToMoneyFloat(viewValues.jobPrice)),
        date: new Date(new Date().setHours(0, 0, 0, 0))
    });

    const [requiredValue, setRequiredValue] = useState(false);
    const [requiredDate, setRequiredDate] = useState(false);
    const [errorDateMessage, setErrorDateMessage] = useState('');

    const handleChange = (name, value) => {
        let val = value;
        if (name === 'value') {
            setValues({ ...values, [name]: moneyMask(val) });
        } else {
            setRequiredDate(false);
            setValues({ ...values, [name]: val });
        }
    };

    const mutation = useMutationAddBill(setOpen, viewValues.id);

    const handleClose = () => {
        setOpen(false);
    };

    const minDate = new Date(2013, 11, 1);
    const maxDate = new Date(new Date().setDate(new Date().getDate()));
    const isInvalidMinDate =
        !isNaN(values.date) &&
        values.date instanceof Date &&
        values.date.getTime() < minDate.getTime();

    const isInvalidMaxDate =
        !isNaN(values.date) &&
        values.date instanceof Date &&
        values.date.getTime() > maxDate.getTime();

    const verifySubmit = () => {
        let res = true;

        if (values.value === '0,00') {
            res = false;
            setRequiredValue(true);
        }

        if (!dateValidation(values.date)) {
            res = false;
            setRequiredDate(true);
            setErrorDateMessage(t('GENERAL.requiredField'));
        }

        if (isInvalidMinDate) {
            res = false;
            setRequiredDate(true);
            setErrorDateMessage(t('GENERAL.minDateErrorMessage'));
        }

        if (isInvalidMaxDate) {
            res = false;
            setRequiredDate(true);
            setErrorDateMessage(t('GENERAL.maxDateErrorMessage'));
        }

        return res;
    };

    const handleSubmit = () => {
        const canSubmit = verifySubmit();
        if (canSubmit) {
            let formData = new FormData();

            formData.append('id', viewValues.id);
            formData.append(
                'value',
                values.value ? moneyMaskToFloat(values.value) : ''
            );
            formData.append(
                'date',
                values.date ? FormatDateToDataBase(values.date) : ''
            );

            mutation.mutate(formData);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            maxWidth={'sm'}
            fullWidth
        >
            <DialogTitle>
                <Grid container item spacing={2} xs={12}>
                    <Grid item xs={11}>
                        <Typography
                            variant={'RobotoBold'}
                            fontSize={24}
                            textColor={'secondary'}
                        >
                            {`${t('GENERAL.add')} ${t('QUOTAS.bill')}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            color="#6D6F71"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent>
                <Grid container item spacing={1} xs={12}>
                    <Grid item xs={12}>
                        <DialogUnitWithIcon
                            head={t('GENERAL.collaborator').toUpperCase()}
                            Icon={
                                <Avatar
                                    className={styles.dialogViewBillAvatar}
                                    alt={viewValues.shortname}
                                    src={
                                        process.env.REACT_APP_IMGSERVER +
                                        viewValues.imageName
                                    }
                                />
                            }
                            content={viewValues.name}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DialogUnit
                            head={t('QUOTAS.quoteValue').toUpperCase()}
                            content={`R$
                                ${moneyMask(
                                    valueToMoneyFloat(viewValues.jobPrice)
                                )}
                                `}
                            tooltipContent={t('QUOTAS.UserQuoteValueTooltip')}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DialogUnitWithIcon
                            head={t('GENERAL.moduleProfile').toUpperCase()}
                            Icon={
                                viewValues.isActiveInQuota ? (
                                    <CheckCircleIcon
                                        style={{
                                            color: '#7FE46B'
                                        }}
                                        fontSize="md"
                                    />
                                ) : (
                                    <ErrorIcon
                                        style={{
                                            color: '#F3222F'
                                        }}
                                        fontSize="md"
                                    />
                                )
                            }
                            content={
                                viewValues.isActiveInQuota
                                    ? t('GENERAL.active')
                                    : t('GENERAL.inactive')
                            }
                            tooltipContent={t('QUOTAS.UserProfileTooltip')}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <InputDate
                            required
                            label={'QUOTAS.referenceMonth'}
                            value={values.date}
                            valueName={'date'}
                            handleChange={handleChange}
                            maxDate={new Date().setDate(new Date().getDate())}
                            error={requiredDate}
                            helperText={requiredDate && errorDateMessage}
                            setRequired={setRequiredDate}
                            monthAndYear
                            minDate={new Date(2013, 11, 1)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Input
                            required
                            label={'GENERAL.value'}
                            value={`R$ ${values.value}`}
                            valueName={'value'}
                            handleChange={handleChange}
                            maxLength={16}
                            error={requiredValue}
                            helperText={
                                requiredValue && t('GENERAL.requiredField')
                            }
                            setRequired={setRequiredValue}
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Buttons
                    onClick={handleClose}
                    tipo={'BN-noback'}
                    conteudo={t('GENERAL.close').toUpperCase()}
                />
                <Buttons
                    isLoading={mutation.isLoading}
                    onClick={handleSubmit}
                    tipo="BN-blue"
                    conteudo={`${t('GENERAL.add').toUpperCase()} ${t(
                        'QUOTAS.bill'
                    ).toUpperCase()}`}
                />
            </DialogActions>
        </Dialog>
    );
};

export default DialogAddBill;
