/* eslint-disable */
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import PlanningActions from "components/Projects/ProjectsActions";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  StyledTableCell,
  StyledTableRow,
  useStyles
} from "../profiles/ProfileStyles";
import Visibility from "@material-ui/icons/Visibility";
import Edit from "@material-ui/icons/Edit";


const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    flexGrow: 1,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5)
  }
}));

function desc(a, b, orderBy) {
  // .normalize("NFD").replace(/[\u0300-\u036f]/g, "") : desconsidera
  // acentos
  if (a[orderBy] === null) a[orderBy] = "";
  if (b[orderBy] === null) b[orderBy] = "";

  if (orderBy === "startDate" || orderBy === "endDate") {
    var split = a[orderBy].split("/");
    var newDate = split[1] + "/" + split[0] + "/" + split[2];
    var date1 = new Date(newDate);

    split = b[orderBy].split("/");
    newDate = split[1] + "/" + split[0] + "/" + split[2];
    var date2 = new Date(newDate);

    if (date2 < date1) return -1;
    if (date2 > date1) return 1;
  }

  if (
    b[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "") <
    a[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "")
  ) {
    return -1;
  }
  if (
    b[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
    a[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "")
  ) {
    return 1;
  }

  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

function createContent(rows, headers, order, orderBy) {
  const rowsCopy = [];
  var obj = {};
  for (var i = 0; i < rows.length; i++) {
    for (var j = 0; j < headers.length; j++) {
      obj[headers[j]] = rows[i][headers[j]];
    }
    rowsCopy.push(obj);
    obj = {};
  }
  return stableSort(rowsCopy, getSorting(order, orderBy));
}

const CustomPaginationActionsTable = props => {
  const useStyles2 = makeStyles(theme => ({
    icons: {
      opacity: "0"
    },
    Title: {
      width: "103px",
      height: "26px",
      fontFamily: "Roboto",
      fontSize: "20px",
      lineHeight: "1.3",
      textAlign: "left",
      color: "#000000",
      fontWeight: "bold"
    }
  }));
  const classes2 = useStyles2();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState({});
  const [rowsLength, setRowsLength] = useState(0);
  const [headers, setHeaders] = useState([]);
  const [mainField, setMainField] = useState("");
  const [visibleHeaders, setVisibleHeaders] = useState([]);
  const [sortedFields, setSortedFields] = useState([]);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("startDate");
  const [t] = useTranslation();

  React.useEffect(() => {
    setHeaders(props.headers);
    setRows(props.rows);
    setRowsLength(props.rows.length);
    setMainField(props.mainField);
    setVisibleHeaders(props.visibleHeaders);
    setSortedFields(props.sortedFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.headers, props.mainField, props.rows, props.visibleHeaders]);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === sortedFields[property] && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(sortedFields[property]);
  };

  const getRowProperties = row => {
    return (
      <React.Fragment>
        {headers.map((prop, index) =>
          prop !== "id" ? (
            prop === mainField ? (
              <TableCell size="small" key={index} component="th" scope="row">
                {row[prop]}
              </TableCell>
            ) : (
              <TableCell size="small" key={index}>
                {row[prop]}
              </TableCell>
            )
          ) : null
        )}
      </React.Fragment>
    );
  };

  const ProjectsTableHead = props => {
    const [t] = useTranslation();
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = property => event => {
      onRequestSort(event, property);
    };
    const paginationTop = action => e => {
      var selector = "[aria-label^='" + action + "']";
      document.querySelector(selector).click();
      e.preventDefault();
    };

    return (
      <TableHead>
        <TableRow>
          {visibleHeaders.map((header, index) => (
            <StyledTableCell
              key={index}
              size="small"
              align="left"
              sortDirection={orderBy === sortedFields[index] ? order : false}
            >
              <TableSortLabel
                active={orderBy === sortedFields[index]}
                direction={order}
                onClick={createSortHandler(index)}
              >
                {header.toUpperCase()}
                {orderBy === sortedFields[index] ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </StyledTableCell>
          ))}
          <StyledTableCell>
            <div className={classes.nextPageDiv}>
              <Tooltip title={t("GENERAL.previousPage")} placement="top">
                <Button
                  name="previous-page"
                  onClick={paginationTop("previous page")}
                >
                  <KeyboardArrowLeft />
                </Button>
              </Tooltip>
              <Tooltip title={t("GENERAL.nextPage")} placement="top">
                <Button name="next-page" onClick={paginationTop("next page")}>
                  <KeyboardArrowRight />
                </Button>
              </Tooltip>
            </div>
          </StyledTableCell>
        </TableRow>
      </TableHead>
    );
  };

  ProjectsTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired
  };

  return (
      <div className={classes.tableWrapper}>
        <Table className={classes.table} size="small">
          <ProjectsTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {createContent(rows, headers, order, orderBy)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => (
                <StyledTableRow key={row.id}>
                  {getRowProperties(row)}
                  <TableCell size="small" align="right">
                    {props.context === "planning" ? (
                      <div style={{marginTop: "auto", marginBottom: "auto", display: "flex",  flexWrap: "nowrap ", justifyContent: "flex-end"}}>
                        <PlanningActions name={row.name} item={row} permission={props.permission} style={{marginTop: "auto", marginBottom: "auto", display: "flex",  flexWrap: "nowrap ", justifyContent: "flex-end"}}/>
                      </div>
                    ) : (
                      <div style={{marginTop: "auto", marginBottom: "auto", display: "flex",  flexWrap: "nowrap ", justifyContent: "flex-end"}}>
                      {props.permission["allowRead"] &&
                      <Link to={`/execution/projects/${row.id}/${t("GENERAL.viewMode")}`}>
                      <Tooltip
                        title="Visualizar"
                        aria-label="details"
                        placement="top"
                      >
                        <IconButton className={classes2.icons}>
                          <Visibility />
                        </IconButton>
                      </Tooltip>
                    </Link>
                      }
                      {props.permission["allowUpdate"] && 
                      <Link to={`/execution/projects/${row.id}/${t("GENERAL.editMode")}`}>
                      <Tooltip
                        title="Editar"
                        aria-label="details"
                        placement="top"
                      >
                        <IconButton className={classes2.icons}>
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    </Link>}
                    </div>
                    )}
                  </TableCell>
                </StyledTableRow>
              ))}

            {/* {emptyRows > 0 && (
              <TableRow style={{ height: 48 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )} */}
          </TableBody>
          <TableFooter>
            <TableRow style={{ display: "none" }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={3}
                count={rowsLength}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>

    
  );
};

CustomPaginationActionsTable.propTypes = {
  rows: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
  visibleHeaders: PropTypes.array.isRequired,
  mainField: PropTypes.string.isRequired
};

export default CustomPaginationActionsTable;
