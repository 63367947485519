import { makeStyles } from '@material-ui/core/styles';

export const screenStyles = makeStyles(theme => ({
    cardContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '200px'
    },
    cardImage: {
        width: '160px',
        height: '220px',
        margin: '0 auto'
    },
    cardTitle: {
        margin: '16px 0 12px 2px',
        height: '32px',
        overflow: 'hidden'
    },
    cardAuthor: {
        margin: '4px 0 8px 2px',
        height: '13px',
        overflow: 'hidden'
    },
    cardTopic: {
        margin: '2px 0 2px 2px'
    },
    cardMoreDetails: {
        margin: '16px auto',
        cursor: 'pointer',
        borderBottom: '1px solid transparent',
        transition: 'ease 0.5s',
        '&:hover': {
            borderBottom: '1px solid #2c3e51'
        }
    },
    cardButton: {
        padding: '6px 16px',
        borderRadius: '2px',
        fontSize: '12px'
    },
    cardAmount: {
        display: 'flex',
        alignItems: 'center',
        minHeight: '20px'
    },
    cardButtonCancelRequisition: {
        padding: '3px 16px',
        borderRadius: '2px',
        fontSize: '12px',
        margin: '6px 0'
    }
}));
