import {webApi} from "../../api/config";
import Cookies from "js-cookie";

const basePath = 'api/CollaboratorsVacations/Report'

export async function getVacationsBalanceReport(ruleSet) {
    return await webApi.get(`${basePath}${ruleSet ? '?rulesetId=' + ruleSet : ""}`, {
        headers: {
            'Content-type': 'application/json',
            Authorization:
                'bearer ' +
                (Cookies.get('auth-token')
                    ? Cookies.get('auth-token')
                    : '')
        }
    })
}