import React from "react";
import "../../App.css";
import Search from "../layout/searchIndex";
import SideBar from "../layout/sidebar/Sidebar";
import Partners from "../Partners/Partners";

function PartnersReturn() {
  return (
    <div>
      <Search
          busca={"Procurar por nome, cnpj, tipo, status"}
        />
        <SideBar />
      <Partners />
    </div>
  );
}

export default PartnersReturn;