import DateTimeFormatter from "./DateTimeFormatter";
import DateParser from "./DateParser";

export default class DateUtils {
    /**
     * Parse a date string into a date object
     * @param {string} dateString The date to parse
     * @returns a Date object
     */
    static parse(dateString) {
        return DateParser.parse(dateString);
    }

    /**
     * Format a date object to the given template
     * @param {Date} date The date to be formatted
     * @param {string} template The template to format the date
     * @returns a formatted date string
     */
    static format(date, template) {
        return new DateTimeFormatter(date).toString(template);
    }

    /**
     * Parse a string date or timestamp into a formatted date string
     * @param {string} dateString The date string to format
     * @param {string} template The template to format the date
     * @returns a formatted date string
     */
    static formatString(dateString, template) {
        const date = DateParser.parse(dateString);
        return new DateTimeFormatter(date).toString(template);
    }
}
