/* eslint-disable */
import Breadcrumb from "components/layout/breadcrumb/Breadcrumb";
import Paper from "@material-ui/core/Paper";
import Footer from "components/layout/Footer";
import Spinner from "components/layout/spinners/Index";
import InventoryContext from "context/inventory/inventoryContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { animated, useTransition } from "react-spring";
import { useStyles } from "../../../components/layout/CommonStyles";
import Grid from "@material-ui/core/Grid";
import Input from "components/inputs/Input";
import ItensReqTable from "./ItensReqTable";
import SotwareReqTable from "./LicensesReqTable";
import DialogReqUse from "./DialogReqUse";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CheckIcon from "@material-ui/icons/Check";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from '@material-ui/icons/Delete';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { green, red } from '@material-ui/core/colors';
import CancelIcon from '@material-ui/icons/Cancel';


var dataEquipmentView = [];
var dataSoftwareView = [];
var placesSelectedPush = [];

const FormRequests = (props) => {
  const { requestId, context } = props;
  var SortElementSelect = require("./../../../utils/sort/SortElementSelect").SortElementSelect;
  var FormatDateToDataBase = require("./../../../utils/dates/FormatDateToDataBase").FormatDateToDataBase;
  var dateToBRWithHours = require("../../../utils/dates/DateToBrWithHours").dateToBRWithHours;
  const classes = useStyles();
  const [t] = useTranslation();
  const inventoryContext = useContext(InventoryContext);
  const [open, setOpen] = React.useState(false);
  const [headerTitle, setHeaderTitle] = useState("INVENTORY.itens");
  const [softwaresOrEquipmentsSelected, setSoftwaresOrEquipmentSelected] = useState([]);
  const [useSoftwaresOrEquipment, setUseSoftwaresOrEquipment] = useState([]);
  const [changeOfLocalArray, setChangeOfLocalArray] = useState([]);
  const [changeContext, setchangeContex] = useState(0);
  const [changePlaceRemoveId, setChangePlaceRemoveId] = useState('');
  const [loading, setLoading] = useState(true);
  const [equipmentsEdit, setEquipmentsEdit] = useState([]);
  const [deleteArrayEditRequisition, setDeleteArrayEditRequisition] = useState([]);
  const [deleteArrayChangeofPlace, setDeleteArrayChangeofPlace] = useState([]);
  const [changeDescription, setchangeDescription] = useState(false);


  if (localStorage.getItem("auth-token") !== null) {
    var auth = JSON.parse(localStorage.getItem("auth-token"));
  }
  const [values, setValues] = useState({
    type: "",
    description: "",
    typeName: "",
    approver: "",
    executor: "",
    dateCreated: "",
    justificationExecutor: "",
    justificationApprover: ""
  });

  const arrayType = [
    {
      value: "change_of_local",
      label: "Alteração de Local",
    },
    {
      value: "use_of_equipment",
      label: "Utilização de Equipamento",
    },
    {
      value: "use_of_software",
      label: "Utilização de Software",
    },
    {
      value: "return_of_equipment",
      label: "Devolução de Equipamento",
    },
    {
      value: "return_of_software",
      label: "Devolução de Software",
    },
  ];

  const {
    loadingSoftware,
    getItensByUser,
    myItens,
    getLicensesByUser,
    licensesByUser,
    getPlaces,
    places,
    getSoftwareCategories,
    itensCategoriesByItensAvaliable,
    getItensCategoriesByItensAvaliable,
    getSoftwareCategoriesByLicensesAvaliable,
    softwareCategoriesByLicensesAvaliable,
    addRequisitions,
    softwaresForRequisition,
    getSoftwaresForRequisition,
    getRequisition,
    requisition,
    getItensCategories,
    updateRequisition,
    approver,
    getApprover,
    loadingRequisition,
    setLoadingRequisition,
    setLoadingRequisitionFalse
  } = inventoryContext;

  async function fetchData(id) {
    await setLoadingRequisition();
    await getItensByUser(auth.id);
    await getLicensesByUser(auth.id);
    await getApprover(auth.id);
    getPlaces();
    getSoftwareCategories();
    getItensCategoriesByItensAvaliable();
    getSoftwareCategoriesByLicensesAvaliable();
    getSoftwaresForRequisition();
    getItensCategories();
    await setLoadingRequisitionFalse();
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData(auth.id);
  }, []);

  var dataEquipment = myItens;

  context !== "new" &&
    useEffect(() => {
      getRequisition(requestId)
      setValues({
        ...values,
        id: "",
        type: requisition.class,
        description: requisition.description ? requisition.description : ""
      });
    }, []);

  context !== "new" &&
    useEffect(() => {
      setValues({
        ...values,
        id: requisition.id,
        type: requisition.class,
        typeName: requisition.class === "change_of_local" ? "Alteração de Local"
          : requisition.class === "return_of_equipment" ? "Devolução de Equipamento"
            : requisition.class === "return_of_software" ? "Devolução de Software"
              : requisition.class === "use_of_equipment" ? "Utilização de Equipamento"
                : requisition.class === "use_of_software" ? "Utilização de Software" : "",
        description: requisition.message ? requisition.message : context === "view" ? "-" : "",
        statusName: requisition.status === "AWAITING_EXECUTION" ? "Aguardando Execução"
          : requisition.status === "DENIED" ? "Recusada"
            : requisition.status === "AWAITING_APPROVAL" ? "Aguardando aprovação"
              : requisition.status === "EXECUTED" ? "Executada" : '',
        status: requisition.status,
        approver: requisition.approver,
        executor: requisition.executor,
        dateCreated: requisition.dateCreated,
        justificationApprover: requisition.approverResponse,
        justificationExecutor: requisition.executorResponse,
        approvalDate: requisition.approverResponseDate,
        executionDate: requisition.executorResponseDate,
      });

      if (context === "edit") {
        if (dataEquipment && requisition.inventoryItemOfChangeOfLocalRequisition) {
          dataEquipment = dataEquipment.map(function (num) {
            requisition.inventoryItemOfChangeOfLocalRequisition.forEach(element => {
              var namePlace = places.filter(x => x.id == element.newPlaceId);
              if (element.itemId === num.id) {
                num.newPlaceId = element.newPlaceId;
                num.newPlace = namePlace[0].name;
              }
            });
            return num;
          });
        }
      }
      if (context === "view" && requisition.inventoryItemOfChangeOfLocalRequisition) {
        dataEquipmentView = requisition.inventoryItemOfChangeOfLocalRequisition.map(function (num) {
          if (num.ufcCode) num.ufcCodeF = `UFC-${num.ufcCode}`;
          if (num.fcpcCode) num.fcpcCodeF = `FCPC-${num.fcpcCode}`;
          if (num.lsbdCode) num.lsbdCodeF = `LSBD-${num.lsbdCode}`;
          if (num.externCode)
            num.externalCodeF = `${num.externPartner}-${num.externCode}`;
          return num;
        });
      }
      if (context === "edit" && requisition.inventoryItemOfSoftwareRequisition) {
        if (values.type === "return_of_software") {
          dataSoftwareView = dataSoftwares.map(function (num) {
            requisition.inventoryItemOfSoftwareRequisition.forEach(element => {
              if (num.id === element.licenseId) {
                num.idReq = element.id;
                if (!equipmentsEdit.some(a => a.id === num.id)) {
                  setEquipmentsEdit(equipmentsEdit => [...equipmentsEdit, num]);
                }

              }
            });
            return num;
          });
        }
        if (values.type === "use_of_software") {
          var arraySoftwareUse = requisition.inventoryItemOfSoftwareRequisition;
          !useSoftwaresOrEquipment.includes(arraySoftwareUse) && setUseSoftwaresOrEquipment(arraySoftwareUse);
        }
      }
      if (context === "edit" && requisition.inventoryItemOfEquipmentRequisition) {
        if (values.type === "return_of_equipment") {
          dataEquipmentView = dataEquipment.map(function (num) {
            if (num.place) num.oldPlace = num.place;
            if (num.ufcCode) num.ufcCodeF = `UFC-${num.ufcCode}`;
            if (num.fcpcCode) num.fcpcCodeF = `FCPC-${num.fcpcCode}`;
            if (num.lsbdCode) num.lsbdCodeF = `LSBD-${num.lsbdCode}`;
            if (num.externCode)
              num.externalCodeF = `${num.externPartner}-${num.externCode}`;
            requisition.inventoryItemOfEquipmentRequisition.forEach(element => {
              if (num.id === element.itemId) {
                num.idReq = element.id;
                if (!equipmentsEdit.some(a => a.id === num.id)) {
                  setEquipmentsEdit(equipmentsEdit => [...equipmentsEdit, num]);
                }
              }
            });
            return num;
          });
        }
        if (values.type === "use_of_equipment") {
          var arrayEquipmentsUse = requisition.inventoryItemOfEquipmentRequisition
          !useSoftwaresOrEquipment.includes(arrayEquipmentsUse) && setUseSoftwaresOrEquipment(arrayEquipmentsUse);
        }
      }

      // view
      if (context === "view" && requisition.inventoryItemOfEquipmentRequisition) {
        if (values.type === "return_of_equipment") {
          dataEquipmentView = requisition.inventoryItemOfEquipmentRequisition.map(function (num) {
            if (num.ufcCode) num.ufcCodeF = `UFC-${num.ufcCode}`;
            if (num.fcpcCode) num.fcpcCodeF = `FCPC-${num.fcpcCode}`;
            if (num.lsbdCode) num.lsbdCodeF = `LSBD-${num.lsbdCode}`;
            if (num.externCode)
              num.externalCodeF = `${num.externPartner}-${num.externCode}`;
            return num;
          });
        }
        if (values.type === "use_of_equipment") {
          var arrayEquipmentsUse = requisition.inventoryItemOfEquipmentRequisition
          !useSoftwaresOrEquipment.includes(arrayEquipmentsUse) && setUseSoftwaresOrEquipment(arrayEquipmentsUse);
        }
      }
      if (context === "view" && requisition.inventoryItemOfSoftwareRequisition) {
        if (values.type === "return_of_software") {
          dataSoftwareView = requisition.inventoryItemOfSoftwareRequisition;
        }
        if (values.type === "use_of_software") {
          var arraySoftwareUse = requisition.inventoryItemOfSoftwareRequisition;
          !useSoftwaresOrEquipment.includes(arraySoftwareUse) && setUseSoftwaresOrEquipment(arraySoftwareUse);
        }
      }
    }, [requisition, dataEquipment]);

  useEffect(() => {
    dataEquipment = dataEquipment.map(function (num) {
      if (num.id === changePlaceRemoveId) {
        num.newPlaceId = null;
        num.newPlace = null;
      }
      return num;
    });

    var array = dataEquipment && dataEquipment.filter(a => a.id === changePlaceRemoveId);
    array[0] && !deleteArrayChangeofPlace.includes(array[0]) && setDeleteArrayChangeofPlace(deleteArrayChangeofPlace => [...deleteArrayChangeofPlace, array[0]]);

  }, [changePlaceRemoveId])

  useEffect(() => {
    var array = equipmentsEdit.filter(e => !softwaresOrEquipmentsSelected.includes(e))
    setDeleteArrayEditRequisition(array)
  }, [softwaresOrEquipmentsSelected])

  const [placesArray, setPlacesArray] = useState([]);
  const [softwaresArray, setSoftwaresArray] = useState([]);
  const [softwareCategoriesArray, setSoftwareCategoriesArray] = useState([]);
  const [itensCategoriesArray, setItensCategoriesArray] = useState([]);
  const [myItensArray, setMyItensArray] = useState([]);

  var placesSelected
  useEffect(() => {
    while (placesSelectedPush.length > 0) {
      placesSelectedPush.pop()
    }
  }, []);

  if (changeOfLocalArray.length > 0) {
    placesSelected = softwaresOrEquipmentsSelected.map(function (num) {
      num.newPlaceId = changeOfLocalArray[0].newPlaceId;
      num.newPlace = changeOfLocalArray[0].newPlace;
      return num;
    });
    placesSelected.forEach(element => {
      placesSelectedPush.push(element);
    });
    setChangeOfLocalArray([])
  }

  const handleSubmitUseResquests = () => {
    let formData = new FormData();

    if (context === "new") {
      var emailDatas = []
      var auxEmail = {}

      auxEmail.user = [{ name: approver.name, email: approver.email }];
      auxEmail.type = arrayType.find(ele => ele.value === values.type).label;
      auxEmail.name = auth.name;
      auxEmail.date = new Date();

      emailDatas.push(auxEmail)
      var auxEmail = {}

      formData.append(`requisition[0].dateCreated`, FormatDateToDataBase(new Date()));
      formData.append(`requisition[0].version`, 0);
      formData.append(`requisition[0].status`, "AWAITING_APPROVAL");
      formData.append(`requisition[0].class`, values.type);
      formData.append(`requisition[0].message`, values.description);
      formData.append(`requisition[0].requesterId`, auth.id);
      formData.append(`requisition[0].responsibleId`, auth.id);
      for (let index = 0; index < useSoftwaresOrEquipment.length; index++) {
        var item = useSoftwaresOrEquipment[index];
        if (values.type === "use_of_equipment") {
          formData.append(`requisition[0].inventoryItemOfEquipmentRequisition[${index}].version`, 0);
          formData.append(`requisition[0].inventoryItemOfEquipmentRequisition[${index}].categoryId`, item.categoryId);
          formData.append(`requisition[0].inventoryItemOfEquipmentRequisition[${index}].description`, item.description);
          formData.append(`requisition[0].inventoryItemOfEquipmentRequisition[${index}].itemId`, '');
          formData.append(`requisition[0].inventoryItemOfEquipmentRequisition[${index}].placeId`, item.placeId);
          formData.append(`requisition[0].inventoryItemOfEquipmentRequisition[${index}].itemsOfEquipmentRequisitionIdx`, 0);
        }
        if (values.type === "use_of_software") {
          formData.append(`requisition[0].inventoryItemOfSoftwareRequisition[${index}].version`, 0);
          formData.append(`requisition[0].inventoryItemOfSoftwareRequisition[${index}].categoryId`, item.categoryId);
          formData.append(`requisition[0].inventoryItemOfSoftwareRequisition[${index}].description`, item.description);
          formData.append(`requisition[0].inventoryItemOfSoftwareRequisition[${index}].itemId`, item.itemId);
          formData.append(`requisition[0].inventoryItemOfSoftwareRequisition[${index}].softwareId`, item.softwareId);
          formData.append(`requisition[0].inventoryItemOfSoftwareRequisition[${index}].itemsOfSoftwareRequisitionIdx`, 0);
        }
      }
      if (values.type === "change_of_local") {
        var idx = 0;
        for (let index = 0; index < dataEquipment.length; index++) {
          const place = dataEquipment[index];
          if (place.newPlaceId) {
            formData.append(`requisition[0].inventoryItemOfChangeOfLocalRequisition[${idx}].version`, 0);
            formData.append(`requisition[0].inventoryItemOfChangeOfLocalRequisition[${idx}].categoryId`, place.categoryId);
            formData.append(`requisition[0].inventoryItemOfChangeOfLocalRequisition[${idx}].description`, values.description);
            formData.append(`requisition[0].inventoryItemOfChangeOfLocalRequisition[${idx}].itemId`, place.id);
            formData.append(`requisition[0].inventoryItemOfChangeOfLocalRequisition[${idx}].oldPlaceId`, place.placeId);
            formData.append(`requisition[0].inventoryItemOfChangeOfLocalRequisition[${idx}].newPlaceId`, place.newPlaceId);
            ///formData.append(`requisition[0].inventoryItemOfChangeOfLocalRequisition[${idx}].moved`,type === "return_of_equipment" ? true : false);
            formData.append(`requisition[0].inventoryItemOfChangeOfLocalRequisition[${idx}].itemsOfEquipmentRequisitionIdx`, 0);
            idx = idx + 1;
          }
        }
      }
      addRequisitions(formData, "/MyRequests", emailDatas, context);
    } else if (context === "edit") {
      formData.append(`requisition[0].id`, values.id);
      formData.append(`requisition[0].dateCreated`, FormatDateToDataBase(new Date()));
      formData.append(`requisition[0].version`, 0);
      formData.append(`requisition[0].status`, "AWAITING_APPROVAL");
      formData.append(`requisition[0].class`, values.type);
      formData.append(`requisition[0].message`, values.description);
      formData.append(`requisition[0].requesterId`, auth.id);
      formData.append(`requisition[0].responsibleId`, auth.id);
      if (values.type === "use_of_equipment") { formData.append(`requisitionsUseEquipmentToDelete`, values.id); }
      if (values.type === "use_of_software") { formData.append(`requisitionsUseSoftwareToDelete`, values.id); }
      for (let index = 0; index < useSoftwaresOrEquipment.length; index++) {
        var item = useSoftwaresOrEquipment[index];
        if (values.type === "use_of_equipment") {
          formData.append(`inventoryItemOfUseEquipmentRequisition[${index}].requisitionId`, values.id);
          formData.append(`inventoryItemOfUseEquipmentRequisition[${index}].version`, 0);
          formData.append(`inventoryItemOfUseEquipmentRequisition[${index}].categoryId`, item.categoryId);
          formData.append(`inventoryItemOfUseEquipmentRequisition[${index}].description`, item.description);
          formData.append(`inventoryItemOfUseEquipmentRequisition[${index}].itemId`, '');
          formData.append(`inventoryItemOfUseEquipmentRequisition[${index}].placeId`, item.placeId);
          formData.append(`inventoryItemOfUseEquipmentRequisition[${index}].itemsOfEquipmentRequisitionIdx`, 0);
        }
        if (values.type === "use_of_software") {
          formData.append(`inventoryItemOfUseSoftwareRequisition[${index}].requisitionId`, values.id);
          formData.append(`inventoryItemOfUseSoftwareRequisition[${index}].version`, 0);
          formData.append(`inventoryItemOfUseSoftwareRequisition[${index}].categoryId`, item.categorySoftwareId);
          formData.append(`inventoryItemOfUseSoftwareRequisition[${index}].description`, item.description);
          formData.append(`inventoryItemOfUseSoftwareRequisition[${index}].itemId`, item.itemId);
          formData.append(`inventoryItemOfUseSoftwareRequisition[${index}].softwareId`, item.softwareId);
          formData.append(`inventoryItemOfUseSoftwareRequisition[${index}].itemsOfSoftwareRequisitionIdx`, 0);
        }
      }
      if (values.type === "change_of_local") {
        formData.append(`requisitionsChangeOfPlaceToDelete`, values.id);
        var idx = 0;
        for (let index = 0; index < dataEquipment.length; index++) {
          const place = dataEquipment[index];
          if (place.newPlaceId) {
            formData.append(`inventoryChangePlace[${idx}].version`, 0);
            formData.append(`inventoryChangePlace[${idx}].requisitionId`, values.id);
            formData.append(`inventoryChangePlace[${idx}].categoryId`, place.categoryId);
            formData.append(`inventoryChangePlace[${idx}].description`, values.description);
            formData.append(`inventoryChangePlace[${idx}].itemId`, place.id);
            formData.append(`inventoryChangePlace[${idx}].oldPlaceId`, place.placeId);
            formData.append(`inventoryChangePlace[${idx}].newPlaceId`, place.newPlaceId);
            ///formData.append(`inventoryChangePlace[${idx}].moved`,type === "return_of_equipment" ? true : false);
            formData.append(`inventoryChangePlace[${idx}].itemsOfEquipmentRequisitionIdx`, idx);
            idx = idx + 1;
          }
        }
      }
      updateRequisition(formData, "/MyRequests");
    }
  };

  useEffect(() => {
    const myPlaces = [...places];
    SortElementSelect(myPlaces, "name");

    var aux = {};
    var _places = [];
    for (var i = 0; i < myPlaces.length; i++) {
      var item = myPlaces[i];
      aux.value = item.id;
      aux.label = item.name;
      _places.push(aux);
      aux = {};
    }
    setPlacesArray(_places);
  }, [places]);

  useEffect(() => {
    const softwares = [...softwaresForRequisition];
    SortElementSelect(softwares, "name");

    var aux = {};
    var _softwares = [];
    for (var i = 0; i < softwares.length; i++) {
      var item = softwares[i];
      aux.value = item.id;
      aux.label = item.name;
      aux.categoryId = item.softwareCategoryId;
      _softwares.push(aux);
      aux = {};
    }
    setSoftwaresArray(_softwares);
  }, [softwaresForRequisition]);

  useEffect(() => {
    const category = [...softwareCategoriesByLicensesAvaliable];
    SortElementSelect(category, "name");
    var aux = {};
    var _categories = [];
    for (var i = 0; i < category.length; i++) {
      var item = category[i];
      aux.value = item.id;
      aux.label = item.name;
      _categories.push(aux);
      aux = {};
    }
    setSoftwareCategoriesArray(_categories);
  }, [softwareCategoriesByLicensesAvaliable]);

  useEffect(() => {
    const category = [...itensCategoriesByItensAvaliable];
    SortElementSelect(category, "name");

    var aux = {};
    var _categories = [];
    for (var i = 0; i < category.length; i++) {
      var item = category[i];
      aux.value = item.id;
      aux.label = item.name;
      _categories.push(aux);
      aux = {};
    }
    setItensCategoriesArray(_categories);
  }, [itensCategoriesByItensAvaliable]);

  useEffect(() => {
    const itens = [...myItens];
    SortElementSelect(itens, "name");

    var aux = {};
    var _itens = [];
    for (var i = 0; i < itens.length; i++) {
      var item = itens[i];
      aux.value = item.id;
      aux.label = item.name;
      _itens.push(aux);
      aux = {};
    }
    setMyItensArray(_itens);
  }, [myItens]);

  const hasItens = myItens.length > 0;
  const hasSoftware = licensesByUser.length > 0;
  const hasSoftwaresOrEquipmentForUse = useSoftwaresOrEquipment.length > 0;

  dataEquipment = dataEquipment.map(function (num) {
    if (num.place) num.oldPlace = num.place;
    if (num.ufcCode) num.ufcCodeF = `UFC-${num.ufcCode}`;
    if (num.fcpcCode) num.fcpcCodeF = `FCPC-${num.fcpcCode}`;
    if (num.lsbdCode) num.lsbdCodeF = `LSBD-${num.lsbdCode}`;
    if (num.externCode)
      num.externalCodeF = `${num.externPartner}-${num.externCode}`;
    return num;
  });

  if (changeOfLocalArray.length > 0) {
    dataEquipment = dataEquipment.map(function (num) {
      placesSelectedPush.forEach(element => {
        if (element.id === num.id) {
          num.newPlaceId = element.newPlaceId;
          num.newPlace = element.newPlace;
        }
      });
      return num;
    });
  }

  var dataSoftwares = licensesByUser;

  const transitions = useTransition(loadingRequisition, null, {
    from: { opacity: 0 },
    enter: {
      opacity: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "calc(100vh - 70px)",
    },
    leave: { opacity: 0 },
  });

  const handleChange = (name, value) => {
    if (name !== "description") {
      setUseSoftwaresOrEquipment([])
    }
    if (context === "edit" && name === "description") {
      if (requisition.message !== value) {
        setchangeDescription(true)
      } else {
        setchangeDescription(false)
      }
    }
    setValues({ ...values, [name]: value });
    if (name == "type") {
      if (value == "use_of_equipment") {
        setHeaderTitle("INVENTORY.useOfEquipment");
      } else if (value == "return_of_equipment" || value == "change_of_local") {
        setHeaderTitle("INVENTORY.yourItens");
      } else if (value == "use_of_software") {
        setHeaderTitle("INVENTORY.useOfSoftwares");
      } else if (value == "return_of_software") {
        setHeaderTitle("INVENTORY.yourSoftwares");
      }
    }
  };

  const handleOpenContextAction = () => {
    if (values.type === "change_of_local") {
      changeContext == 0 && setOpen(true);
      setchangeContex(changeContext + 1)
    } else {
      setOpen(true);
    }
  };

  const removeRowAuthor = (indexField) => () => {
    const newEquipmentOrSoftware = [...useSoftwaresOrEquipment];
    const newEquipmentOrSoftwareFilter = newEquipmentOrSoftware.filter((x, i) => i !== indexField);
    setUseSoftwaresOrEquipment(newEquipmentOrSoftwareFilter);
  }

  return transitions.map(({ item, key, props }) => (
    <React.Fragment>
      <div className="container">
        <Breadcrumb
          routes={[
            { label: t("INVENTORY.inventory") },
            { label: t("INVENTORY.myRequests"), to: "/MyRequests" },
            { label: context === "new" ? t("INVENTORY.addRequests") : context === "edit" ? t("INVENTORY.updateRequisition") : t("GENERAL.view") },
          ]}
        />
        <div className={classes.containerHeader}>
          <div className="title">
            <h1 className={classes.title}>{t("INVENTORY.myRequests")}</h1>
          </div>
        </div>
        <Paper>
          <div className="cabecalho">
            {context === "new" ? t("INVENTORY.addRequests").toUpperCase() : context === "edit" ? t("INVENTORY.updateRequisition").toUpperCase() : t("GENERAL.view").toUpperCase()}
          </div>
          {item ? (
            <animated.div key={key} style={props}>
              <Spinner />;
            </animated.div>
          ) : (
            <React.Fragment>
              <div className={classes.bodyNewEquipment}>
                <Grid container spacing={3} md={12} lg={12}>
                  <Grid item xs={12} sm={7}>
                    <Input
                      disabled={context == "edit" ? true : context == "view" ? true : false}
                      context={context === "edit" ? "view" : context}
                      required={true}
                      isSelect={true}
                      label={"GENERAL.type"}
                      value={context === "new" ? values.type : values.typeName}
                      valueName={"type"}
                      handleChange={handleChange}
                      arraySelected={arrayType}
                    />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <Input
                      context={context}
                      rows={6}
                      label={"INVENTORY.description"}
                      value={values.description}
                      valueName={"description"}
                      handleChange={handleChange}
                      maxLength={500}
                    />
                  </Grid>
                  {context === "view" &&


                    <React.Fragment>
                      <Grid item xs={12} sm={7}>
                        <Input
                          context={context}
                          label={"INVENTORY.requisitionDate"}
                          value={dateToBRWithHours(new Date(values.dateCreated))}
                          handleChange={handleChange}
                          valueName={"requisitionDate"}
                        />
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        {values.statusName &&
                          <Input
                            context={context}
                            label={"INVENTORY.requisitionStatus"}
                            value={values.statusName}
                          />}
                      </Grid>
                      {values.approver &&
                        <React.Fragment>
                          <Grid item xs={12} sm={7} style={{ padding: "0px" }}>
                            {values.status === "DENIED" && values.executor === null ? <div style={{ display: "flex", justifyContent: "space-between", paddingLeft: "12px" }}> <div>Recusado <CancelIcon fontSize="small" style={{ color: "red" }} /> </div> {dateToBRWithHours(new Date(values.approvalDate))} </div>
                              : <div style={{ display: "flex", justifyContent: "space-between", paddingLeft: "12px" }}> <div>Aprovado <CheckIcon fontSize="small" style={{ color: "green" }} /> </div> {dateToBRWithHours(new Date(values.approvalDate))} </div>}
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <Input
                              context={"view"}
                              value={values.approver}
                              valueName={"requester"}
                            />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <Input
                              context={"view"}
                              rows={4}
                              label={values.status === "DENIED" && values.executor === "" ? "INVENTORY.justificationLabel" : "INVENTORY.observation"}
                              value={values.justificationApprover ? values.justificationApprover : "-"}
                              valueName={"description"}
                              maxLength={200}
                            />
                          </Grid>
                        </React.Fragment>
                      }
                      {values.executor &&
                        <React.Fragment>
                          <Grid item xs={12} sm={7} style={{ padding: "0px" }}>
                            {values.status === "DENIED" && values.executor !== "" ? <div style={{ display: "flex", justifyContent: "space-between", paddingLeft: "12px" }}> <div>Recusado <CancelIcon fontSize="small" style={{ color: "red" }} /> </div> {dateToBRWithHours(new Date(values.executionDate))} </div>
                              : <div style={{ display: "flex", justifyContent: "space-between", paddingLeft: "12px" }}> <div>Aprovado <CheckIcon fontSize="small" style={{ color: "green" }} /> </div> {dateToBRWithHours(new Date(values.executionDate))} </div>}
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <Input
                              context={"view"}
                              label={"INVENTORY.executor"}
                              value={values.executor}
                              valueName={"executor"}
                            />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <Input
                              context={"view"}
                              rows={4}
                              label={values.status === "DENIED" && values.executor !== "" ? "INVENTORY.justificationLabel" : "INVENTORY.observation"}
                              value={values.justificationExecutor ? values.justificationExecutor : "-"}
                              valueName={"executorResponse"}
                              maxLength={200}
                            />
                          </Grid>
                        </React.Fragment>
                      }
                    </React.Fragment>
                  }

                  {values.type !== "" &&
                    <Grid item xs={12} sm={12}>
                      <div className="cabecalho">
                        {t(headerTitle).toUpperCase()}
                        <span style={{ float: "right", marginRight: 10 }}>
                          <span style={{ float: "right", marginRight: 10 }}>
                            {(values.type == "use_of_equipment" || values.type == "use_of_software") && context !== "view" && (
                              <Tooltip
                                title="Adicionar"
                                aria-label="adicionar"
                                placement="top"
                              >
                                <IconButton
                                  className="btn"
                                  style={{ float: "right" }}
                                  edge="end"
                                  color="inherit"
                                  onClick={() => setOpen(true)}
                                >
                                  <AddCircleIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                          </span>
                        </span>
                      </div>
                      {values.type === "use_of_equipment" &&
                        <React.Fragment>
                          {(useSoftwaresOrEquipment.length > 0) &&
                            <TableContainer component={Paper}>
                              <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell><b>CATEGORIA</b></TableCell>
                                    <TableCell><b>LOCAL</b></TableCell>
                                    <TableCell><b>DESCRIÇÃO</b></TableCell>
                                    <TableCell></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {useSoftwaresOrEquipment.map((row, idx) => (
                                    <TableRow key={row.category}>
                                      <TableCell component="th" scope="row">
                                        {row.category}
                                      </TableCell>
                                      <TableCell>{row.place}</TableCell>
                                      <TableCell>{row.description}</TableCell>
                                      <TableCell>
                                        {(useSoftwaresOrEquipment.length > 0) && context !== "view" &&
                                          <Tooltip
                                            title="Remover item"
                                            aria-label="remover"
                                            placement="top"
                                          >
                                            <IconButton
                                              style={{ float: "right", marginTop: "-2px" }}
                                              onClick={removeRowAuthor(idx)}
                                              className={classes.icons}
                                            >
                                              <DeleteIcon />
                                            </IconButton>
                                          </Tooltip>
                                        }
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>}
                        </React.Fragment>}
                      {values.type === "use_of_software" &&
                        <React.Fragment>
                          {(useSoftwaresOrEquipment.length > 0) &&
                            <TableContainer component={Paper}>
                              <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell><b>CATEGORIA</b></TableCell>
                                    <TableCell><b>SOFTWARE</b></TableCell>
                                    <TableCell><b>MÁQUINA PARA INSTALAÇÃO</b></TableCell>
                                    <TableCell></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {useSoftwaresOrEquipment.map((row, idx) => (
                                    <TableRow key={row.category}>
                                      <TableCell component="th" scope="row">
                                        {row.category}
                                      </TableCell>
                                      <TableCell>{row.software}</TableCell>
                                      <TableCell>{row.newMachine ? row.newMachine : row.machine}</TableCell>
                                      <TableCell>
                                        {(useSoftwaresOrEquipment.length > 0) && context !== "view" &&
                                          <Tooltip
                                            title="Remover item"
                                            aria-label="remover"
                                            placement="top"
                                          >
                                            <IconButton
                                              style={{ float: "right", marginTop: "-2px" }}
                                              onClick={removeRowAuthor(idx)}
                                              className={classes.icons}
                                            >
                                              <DeleteIcon />
                                            </IconButton>
                                          </Tooltip>
                                        }
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>}
                        </React.Fragment>}
                      {values.type == "return_of_software" ?
                        <SotwareReqTable
                          rows={context !== "new" ? dataSoftwareView : dataSoftwares}
                          headers={["id", "software", "category", "key", "email", "machine", "ufcCodeF", "lsbdCodeF", "externalCodeF", "serialNumber", "place"]}
                          visibleHeaders={["software", "Categoria", "Chave/Email de Ativação", "Máquina"]}
                          sortedFields={["software", "category", "key", "machine"]}
                          mainField="software"
                          setSoftwaresOrEquipmentSelected={
                            setSoftwaresOrEquipmentSelected
                          }
                          type={values.type}
                          context={context}
                          equipmentsEdit={equipmentsEdit}
                        />
                        :
                        values.type == "return_of_equipment" ?
                          <ItensReqTable
                            rows={context !== "new" ? dataEquipmentView : dataEquipment}
                            headers={["id", "name", "category", "fcpcCodeF", "ufcCodeF", "lsbdCodeF", "externalCodeF", "serialNumber", "place", "newPlace", "selected"]}
                            visibleHeaders={["Item", "Categoria", "Código de identificação", "Número de série", "local atual"]}
                            sortedFields={["name", "category", "ufcCodeF", "serialNumber", "oldPlace"]}
                            mainField="name"
                            type={values.type}
                            changeOfLocalArray={changeOfLocalArray}
                            setchangeContex={setchangeContex}
                            setSoftwaresOrEquipmentSelected={setSoftwaresOrEquipmentSelected}
                            context={context}
                            equipmentsEdit={equipmentsEdit}
                          />
                          :
                          values.type == "change_of_local" &&
                          <ItensReqTable
                            rows={context === "view" ? dataEquipmentView : dataEquipment}
                            headers={["id", "name", "category", "fcpcCodeF", "ufcCodeF", "lsbdCodeF", "externalCodeF", "serialNumber", "oldPlace", "newPlace"]}
                            visibleHeaders={
                              context === "edit" ?
                                ["Item", "Categoria", "Código de identificação", "Número de série", "local atual", "Novo local", ""] :
                                ["Item", "Categoria", "Código de identificação", "Número de série", "local atual", "Novo local"]
                            }
                            sortedFields={["name", "category", "ufcCodeF", "serialNumber", "oldPlace", "newPlace"]}
                            mainField="name"
                            type={values.type}
                            changeOfLocalArray={changeOfLocalArray}
                            setchangeContex={setchangeContex}
                            setSoftwaresOrEquipmentSelected={setSoftwaresOrEquipmentSelected}
                            setChangePlaceRemoveId={setChangePlaceRemoveId}
                            context={context}
                            equipmentsEdit={equipmentsEdit}
                          />}
                      {!hasItens &&
                        values.type == "return_of_equipment" &&
                        <div className={classes.noParticipantsMessage}>
                          <h2>{t("INVENTORY.noEquipmentMessage")}</h2>
                          <h4>{t("INVENTORY.noEquipmentsSubMessage")}</h4>
                        </div>
                      }
                      {!hasSoftware &&
                        values.type == "return_of_software" && context !== "view" &&
                        <div className={classes.noParticipantsMessage}>
                          <h2>{t("INVENTORY.noSoftwaresMessage")}</h2>
                          <h4>{t("INVENTORY.noSoftwaresSubMessage")}</h4>
                        </div>
                      }
                      {!hasSoftwaresOrEquipmentForUse && (values.type == "use_of_equipment" ||
                        values.type == "use_of_software") && context !== "view" &&
                        <div className={classes.noParticipantsMessage}>
                          {values.type == "use_of_software" ?
                            <>
                              <h2>{t("INVENTORY.noSoftwaresMessage")}</h2>
                              <h4>{t("INVENTORY.noSoftwaresSubMessage")}</h4>
                            </>
                            :
                            <>
                              <h2>{t("INVENTORY.noEquipmentMessage")}</h2>
                              <h4>{t("INVENTORY.noEquipmentsSubMessage")}</h4>
                            </>
                          }
                        </div>
                      }
                      <Grid item xs={12} sm={12}>
                        <div style={{ display: "flex", marginTop: "40px" }}>
                          {context !== "view" && values.type !== "use_of_equipment" && values.type !== "use_of_software" &&
                            <Button
                              color="primary"
                              className={classes.blueButton}
                              variant="contained"
                              style={{
                                padding: "15px",
                                float: "right",
                                marginLeft: "10px",
                              }}
                              disabled={softwaresOrEquipmentsSelected.length > 0 ? false : true}
                              onClick={handleOpenContextAction}
                            >
                              <div className={classes.buttonIcon}>
                                {values.type !== "change_of_local" && <CheckIcon />}
                              </div>
                              {values.type === "change_of_local" ? t("GENERAL.alter") : t("GENERAL.send")}
                            </Button>}
                          {context !== "view" && (values.type === "use_of_equipment" || values.type == "use_of_software") &&
                            <Button
                              color="primary"
                              className={classes.blueButton}
                              variant="contained"
                              style={{
                                padding: "15px",
                                float: "right",
                                marginLeft: "10px",
                              }}
                              disabled={useSoftwaresOrEquipment.length > 0 ? false : true}
                              onClick={handleSubmitUseResquests}
                            >
                              <div className={classes.buttonIcon}>
                                <CheckIcon />
                              </div>
                              {t("GENERAL.send")}
                            </Button>}
                          {context !== "view" && values.type === "change_of_local" &&
                            <Button
                              color="primary"
                              className={classes.blueButton}
                              variant="contained"
                              style={{
                                padding: "15px",
                                float: "right",
                                marginLeft: "10px",
                              }}
                              disabled={placesSelectedPush.length > 0 || changeDescription || changePlaceRemoveId.length !== 0 ? false : true}
                              onClick={handleSubmitUseResquests}
                            >
                              <div className={classes.buttonIcon}>
                                <CheckIcon />
                              </div>
                              {t("GENERAL.send")}
                            </Button>}
                        </div>
                      </Grid>
                    </Grid>}
                </Grid>
              </div>
            </React.Fragment>
          )}
        </Paper>
      </div >
      <DialogReqUse
        open={open}
        setOpen={setOpen}
        data={softwaresOrEquipmentsSelected}
        type={values.type}
        message={values.description}
        requestId={auth.id}
        requestName={auth.name}
        placesArray={placesArray}
        softwareCategoriesArray={softwareCategoriesArray}
        itensCategoriesArray={itensCategoriesArray}
        setUseSoftwaresOrEquipment={setUseSoftwaresOrEquipment}
        useSoftwaresOrEquipment={useSoftwaresOrEquipment}
        setChangeOfLocalArray={setChangeOfLocalArray}
        changeOfLocalArray={changeOfLocalArray}
        myItensArray={myItensArray}
        softwaresArray={softwaresArray}
        context={context}
        approver={approver}
        arrayType={arrayType}
        // to edit:
        deleteArrayEditRequisition={deleteArrayEditRequisition}
        requisitionId={values.id}
      />
      <Footer />
    </React.Fragment >
  ));
};

export default FormRequests;