import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import {
    TextField,
    MenuItem,
    Toolbar,
    InputBase,
    InputAdornment,
    IconButton
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';

import { inputStyles } from './Styles';

const InputFilter = ({
    filterValue,
    isSelect,
    label,
    maxLength,
    setFilter,
    arraySelected,
    type,
    mask,
    ...props
}) => {
    const classes = useStyles();
    const styles = inputStyles();
    const [t] = useTranslation();
    const numberRegex = new RegExp('^[0-9]+$');

    const clearField = () => {
        setFilter('');
    };

    return (
        <div className={styles.filterSearchInput}>
            {!isSelect ? (
                <InputBase
                    select={isSelect}
                    value={filterValue}
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput
                    }}
                    style={{ width: '100%' }}
                    placeholder={t(label)}
                    label={t(label)}
                    onChange={ev => {
                        if (
                            isSelect ||
                            maxLength === undefined ||
                            ev.target.value.length <= maxLength
                        ) {
                            if (type === 'number') {
                                if (
                                    numberRegex.test(
                                        ev.target.value.slice(-1)
                                    ) ||
                                    ev.target.value.length === 0
                                ) {
                                    setFilter(
                                        mask
                                            ? mask(ev.target.value)
                                            : ev.target.value
                                    );
                                }
                            } else {
                                setFilter(
                                    mask
                                        ? mask(ev.target.value)
                                        : ev.target.value
                                );
                            }
                        }
                    }}
                    startAdornment={
                        !isSelect ? (
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                        ) : null
                    }
                    endAdornment={
                        !isSelect && filterValue && filterValue.length > 0 ? (
                            <InputAdornment position="end">
                                <IconButton
                                    className={classes.menuButton}
                                    color="inherit"
                                    aria-label="Close"
                                    onClick={clearField}
                                >
                                    <Close />
                                </IconButton>
                            </InputAdornment>
                        ) : null
                    }
                    {...props}
                />
            ) : (
                <TextField
                    select
                    label={t(label)}
                    value={filterValue}
                    className={classes.textField}
                    onChange={ev => setFilter(ev.target.value)}
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputSearch
                    }}
                    // inputProps={{ "aria-label": "search" }}
                    SelectProps={{ disableScrollLock: true }}
                    disableScrollLock={true}
                >
                    {arraySelected &&
                        arraySelected.map(item => (
                            <MenuItem
                                disableScrollLock={true}
                                key={item.value}
                                value={item.value}
                            >
                                {item.label}
                            </MenuItem>
                        ))}
                </TextField>
            )}
        </div>
    );
};

export default InputFilter;
