import React from "react";
import "../../../../App.css";
import SideBar from "components/layout/sidebar/Sidebar";
import DashboardNavBar from "components/layout/DashboardNavBar";
import HolidaysAndBreaksForm from "./HolidaysAndBreaksForm";

const CallViewHolidaysAndBreaks = ({ match }) => {

    return (
        <div>
            <DashboardNavBar />
            <SideBar />
            <HolidaysAndBreaksForm holidayOrBreakId={match.params.id} context={"view"} />
        </div>
    );
}

export default CallViewHolidaysAndBreaks;