import DateFnsUtils from "@date-io/date-fns";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import Check from "@material-ui/icons/Check";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Buttons from "components/buttons/Pressed";
import { useStyles } from "components/layout/CommonStyles";
import Spinner from "components/layout/spinners/Index";
import ProjectsContext from "context/projects/projectsContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import DashboardNavBar from "../../layout/DashboardNavBar";
import SideBar from "../../layout/sidebar/Sidebar";
import ProjectInformation from "../ProjectInformationComponent";
import ActivitiesTable from "./Activities/ActivitiesTable";
import AllowancesContext from "context/allowance/allowanceContext";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";

const tooltipTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#2c3e51"
    }
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: "white",
        fontSize: "1em",
        backgroundColor: "red",
        opacity: "0.7"
      }
    }
  }
});

let projectid;

const ProjectPhase = ({ match }) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [mustRedirect, setMustRedirect] = useState(false);
  const projectsContext = useContext(ProjectsContext);
  const [openName, setOpenName] = useState(false);
  const [open, setOpen] = useState(false);
  const allowanceContext = useContext(AllowancesContext);

  const {
    getAllPermission,
    allAllowances
  } = allowanceContext;

  const {
    loading,
    project,
    getProject,
    getPhase,
    phase,
    UpdatePhase,
    setValuePanelTab,
    FinishPhase
  } = projectsContext;

  const [state, setState] = useState({
    projectName: "",
    gpf: "",
    projectStartDate: "",
    projectEndDate: "",
    seasonId: 0,
    phaseName: "",
    phaseStartDate: "",
    phaseEndDate: "",
    phaseDescription: "",
    phaseStatus: ""
  });

  const transitions = useTransition(loading, null, {
    from: { opacity: 0 },
    enter: {
      opacity: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "calc(100vh - 80px)"
    },
    leave: { opacity: 0 }
  });

  const [dates, setDates] = useState({
    phaseStartDate: new Date(),
    phaseEndDate: new Date()
  });

  useEffect(() => {
    setValuePanelTab(1);
    getPhase(match.params.id);
  }, []);

  useEffect(() => {
    projectid = phase.projectId;
    if (projectid) {
      getProject(projectid);
    }
  }, [phase]);

  React.useEffect(() => {
    getAllPermission()
  }, [allAllowances.length, JSON.stringify(allAllowances)]);

  useEffect(() => {
    if (project) {
      if (project.information) {
        setState({
          projectName: project.information.name,
          gpf: project.information.gpf,
          projectStartDate: project.information.startdate,
          projectEndDate: project.information.enddate,
          phaseStatus: phase.status === false ? (new Date(phase.startdate) > new Date() ?
            t("GENERAL.statusNotStarted") :
            t("PROJECTS.inProgress")) : "Concluída"
          ,
          phaseName: phase.name,
          phaseDescription: phase.description
        });
        var startdatewithtimezone = new Date(phase.startdate)
        startdatewithtimezone.setMinutes(startdatewithtimezone.getMinutes() + startdatewithtimezone.getTimezoneOffset())
        var enddatewithtimezone = new Date(phase.enddate)
        enddatewithtimezone.setMinutes(enddatewithtimezone.getMinutes() + enddatewithtimezone.getTimezoneOffset())
        setDates({
          phaseStartDate: startdatewithtimezone,
          phaseEndDate: enddatewithtimezone
        });
      }
    }
  }, [project]);

  const handleSubmit = () => {
    if (state.phaseName) {
      clearRequiredMessage();

      var obj = {
        id: match.params.id,
        name: state.phaseName,
        description: state.phaseDescription,
        startDate: dates.phaseStartDate,
        endDate: dates.phaseEndDate,
        projectId: projectid,
        status: false
      };

      UpdatePhase(obj);
      setMustRedirect(true);
    } else showRequiredFieldsMessage();
  };

  const FinishPhaseSubmit = () => {
    if (state.phaseName) {
      clearRequiredMessage();

      var obj = {
        id: match.params.id,
        name: state.phaseName,
        description: state.phaseDescription,
        startDate: dates.phaseStartDate,
        endDate: dates.phaseEndDate,
        projectId: projectid,
        status: true
      };

      FinishPhase(obj);
      setMustRedirect(true);
    } else showRequiredFieldsMessage();
  };

  const redirect = go => {
    if (go) return <Redirect to={`/execution/projects/${projectid}/edit`} />;
  };

  const handleFieldChange = name => event => {
    setState({ ...state, [name]: event.target.value });
  };

  const handleDates = name => e => {
    setDates({ ...dates, [name]: e });
  };

  const clearRequiredMessage = () => {
    setOpenName(false);
  };

  const showRequiredFieldsMessage = () => {
    if (!state.phaseName) setOpenName(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFinish = () => {
    //setOpenDelete(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const getPermission = (screen,allowance) => {
    for (let i = 0; i < allAllowances.length; i++) {
      if (allAllowances[i].resource === screen) {
        return allAllowances[i]["standardauthorizationoption"][allowance]
      }
    }
  }

  return transitions.map(({ item, key, props }) =>
    item ? (
      <animated.div key={key} style={props}>
        <Spinner />
      </animated.div>
    ) : (
        <animated.div key={key} style={props}>
          {redirect(mustRedirect)}
          <DashboardNavBar />
          <SideBar />
          <div className="container">
            <Breadcrumbs
              className={classes.breadCrumbs}
              aria-label="breadcrumb"
              separator={<NavigateNextIcon fontSize="small" />}
            >
              <Typography>{t("PARTICIPANTS.labor")}</Typography>
              <Link className={classes.link} to="/execution/projects/">
                {t("PROJECTS.projects")}
              </Link>
              <Link
                className={classes.link}
                to={`/execution/projects/${projectid}/${match.params.projectMode}`}
              >
                {t("PROJECTS.project")}
              </Link>
              <Typography>{t("SCOPE.stage")}</Typography>
            </Breadcrumbs>
            <div className="container-header">
              <div className="title">
                <h1 className={classes.title}>
                  {match.params.mode === t("GENERAL.editMode") ?
                    t("PHASES.editPhase") :
                    t("PHASES.viewPhase")
                  }
                </h1>
              </div>
              {match.params.mode === t("GENERAL.editMode") &&
                <div>
                  {/* permissão para finalizar? */}
                  <Button
                    //className={classes.grayButton}
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#27AE60",
                      padding: "14px 24px",
                      fontWeight: "normal",
                      fontSize: "14px",
                      marginRight: "15px"

                    }
                    }
                    onClick={handleClickOpen}
                  >
                    {t("PHASES.finishPhase").toUpperCase()}
                  </Button>
                  {allAllowances.length > 0 && getPermission("PHASES", "allowUpdate") &&
                  <Button
                    className={classes.blueButton}
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    <div className={classes.buttonIcon}>
                      <Check />
                    </div>
                    {t("PHASES.updatePhase").toUpperCase()}
                  </Button>}
                </div>
              }
            </div>
            <ProjectInformation
              projectName={state.projectName}
              gpf={state.gpf}
              projectStartDate={state.projectStartDate}
              projectEndDate={state.projectEndDate}
            />
            <Paper className={classes.root}>
              <div className={classes.header}>
                {t("SCOPE.stage").toUpperCase()}
              </div>
              <div className={classes.bodyForm}>
                <Grid container>
                  <Grid container item spacing={2} md={12} lg={12}>
                    <Grid item xs={4}>
                      <MuiThemeProvider theme={tooltipTheme}>
                        <Tooltip
                          TransitionComponent={Zoom}
                          open={openName}
                          title={t("GENERAL.requiredField")}
                        >
                          <TextField
                            required={match.params.mode === t("GENERAL.editMode")}
                            disabled={match.params.mode === t("GENERAL.viewMode")}
                            className={classes.textField}
                            margin="normal"
                            id="phaseName"
                            label={t("PHASES.phaseName")}
                            name="phaseName"
                            value={state.phaseName}
                            onChange={handleFieldChange("phaseName")}
                            onFocus={clearRequiredMessage}
                          />
                        </Tooltip>
                      </MuiThemeProvider>
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        disabled
                        id="status"
                        label={t("Status")}
                        className={classes.textField}
                        name="phaseStatus"
                        margin="normal"
                        value={state.phaseStatus}
                        onFocus={clearRequiredMessage}
                      >
                      </TextField>
                    </Grid>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid item xs={3}>
                        <KeyboardDatePicker
                          disabled={match.params.mode === t("GENERAL.viewMode")}
                          className={classes.textField}
                          disableToolbar
                          maxDate={dates.EndDate}
                          autoOk={true}
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          id="startDate"
                          label={t("GENERAL.startDate")}
                          value={dates.phaseStartDate}
                          onFocus={clearRequiredMessage}
                          minDateMessage={
                            "A data não deve ser anterior à data mínima"
                          }
                          onChange={handleDates("phaseStartDate")}
                          KeyboardButtonProps={{
                            "aria-label": "change date"
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <KeyboardDatePicker
                          disabled={match.params.mode === t("GENERAL.viewMode")}
                          className={classes.textField}
                          disableToolbar
                          autoOk={true}
                          minDate={dates.phaseStartDate}
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          id="endDate"
                          label={t("GENERAL.endDate")}
                          value={dates.phaseEndDate}
                          onFocus={clearRequiredMessage}
                          onChange={handleDates("phaseEndDate")}
                          KeyboardButtonProps={{
                            "aria-label": "change date"
                          }}
                          minDateMessage={
                            "A data não deve ser anterior à data mínima"
                          }
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid container item spacing={2} md={12} lg={12}>
                    <Grid item xs={12}>
                      <TextField
                        disabled={match.params.mode === t("GENERAL.viewMode")}
                        multiline
                        fullWidth
                        variant="outlined"
                        rows="6"
                        id="phaseDescription"
                        value={state.phaseDescription}
                        label={t("PROFILES.descriptionLabel")}
                        margin="normal"
                        onChange={handleFieldChange("phaseDescription")}
                        onFocus={clearRequiredMessage}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </div>
          <br></br>
          <br></br>
          <ActivitiesTable phase={phase} mode={match.params.mode} projectMode={match.params.projectMode} allAllowances={allAllowances} />
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            msg="mensagem"
          >
            <DialogTitle className={classes.actionsTitle}>
              {t("PHASES.finishPhase")}
              <IconButton style={{float:"right", marginTop: -10, marginRight: -17}} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t("PHASES.finishPhaseAsk")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Buttons
                onClick={handleClose}
                tipo="BN-noback"
                conteudo={t("GENERAL.cancel")}
              />
              <Buttons
                onClick={FinishPhaseSubmit}
                tipo="BN-blue"
                conteudo={t("GENERAL.finish")}
              />
            </DialogActions>
          </Dialog>
        </animated.div>
      )
  );
};

export default ProjectPhase;