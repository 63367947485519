import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Delete from "@material-ui/icons/Delete";
import { useStyles } from "components/layout/CommonStyles";
import React, { useEffect, useState } from "react";

const NewRubric = props => {
  const classes = useStyles();
  const {
    deleteRubric,
    lastHeading,
    headingDescription,
    headingValue,
    estimatedCost,
    updateFields,
    disabled
  } = props;
  const [description, setDescription] = useState(headingDescription);
  const [value, setValue] = useState(headingValue ? headingValue : "");

  useEffect(() => {
    setDescription(headingDescription);
  }, [headingDescription]);

  useEffect(() => {
    setValue(headingValue);
  }, [headingValue]);

  useEffect(() => {
    updateFields();
  });

  const handleChangeDescription = event => {
    setDescription(event.target.value);
  };

  const converTotBrCurrency = number => {
    var temp = "";
    var len = number.length;
    var count = 0;

    for (var i = 0; i < len; i++) {
      if (i === 1) {
        var aux = "," + number[i] + temp;
        temp = aux;
      } else {
        if (count === 3) {
          count = 1;
          var aux = number[i] + "." + temp;
          temp = aux;
        } else {
          if (i > 1) {
            count += 1;
          }
          var aux = number[i] + temp;
          temp = aux;
        }
      }
    }

    return temp;
  };

  const handleValueChange = event => {
    if (event.target.value !== " " && !event.target.value.includes(" ")) {
      var str = event.target.value.split("");
      var aux = str.filter(el => el !== "." && el !== ",").join("");
      var value = event.target.value;

      if (event.target.value) {
        value = converTotBrCurrency(
          aux
            .split("")
            .reverse()
            .join("")
        );
      }

      aux = value.split("").filter(el => el !== ".").join("").replace(",", ".")
      if (!isNaN(aux)) {
        setValue(value);
      }
    }
  };

  const handleClick = () => {
    deleteRubric(lastHeading);
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            id={"descHeading" + lastHeading}
            disabled={true}
            className={classes.textField}
            value={description}
            margin="normal"
            onChange={handleChangeDescription}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id={"headingValue" + lastHeading}
            disabled={true}
            className={classes.textField}
            margin="normal"
            name="headingValue"
            value={value}
            onChange={handleValueChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={1}>
          <TextField
            id={"percentage" + lastHeading}
            disabled={true}
            className={classes.textField}
            value={
              value
                ? (
                  (parseFloat(
                    value
                      .split("")
                      .filter(el => el !== ".")
                      .join("")
                      .replace(",", ".")
                  ) /
                    estimatedCost) *
                  100
                ).toFixed(2)
                : "0.00"
            }
            margin="normal"
          />
        </Grid>
        <Grid item xs={1} style={{ marginTop: "8px" }}>
        </Grid>
        <div className="lineBreak"></div>
      </Grid>
    </div>
  );
};

export default NewRubric;