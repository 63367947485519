import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { useStyles } from "components/layout/CommonStyles";
import { useTranslation } from "react-i18next";
import CloseIcon from '@material-ui/icons/Close';
import Buttons from "components/buttons/Pressed";
import Grid from "@material-ui/core/Grid";
import Input from "components/inputs/Input";
import Typography from "@material-ui/core/Typography";

const DialogConfirmClockRecord = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const { open, setOpen, fetchData ,observation, name , clocksIn , registersActivity, workingHoursContract , workingHoursContractId ,clockRecordType, updateClockRecord, userId } = props;

    const [values, setValues] = useState({
        name: "",
        clocksIn: "",
        registersActivity: "",
        workingHoursContractId: 0,
        clockRecordType: "",
        observation: "",
    });


    useEffect(() => {
        setValues({
            ...values,
            workingHoursContractId: workingHoursContractId ? workingHoursContractId : 0,
            name: name ? name : "",
            clocksIn : clocksIn ? clocksIn : false,
            registersActivity : registersActivity ? registersActivity : false,
            clockRecordType: clockRecordType ? clockRecordType : "",
            observation: observation ? observation : "",
        });
    }, []);

    const clearDialog = () => {
        setValues({
            ...values,
            name: "",
            clocksIn: "",
            registersActivity: "",
            workingHoursContractId: 0,
            clockRecordType: "",
            observation: "",
        });
    }

    const handleClose = () => {
        setOpen(false);
        clearDialog();
    }

    const handleChange = (name, value) => {
        setValues({ ...values, [name]: value })
    }

    

    const handleSubmit = () => {
        let formData = new FormData();
        formData.append(`clocksIn`, values.clocksIn);
        formData.append(`registersActivity`, values.registersActivity);
        formData.append(`observation`, !values.clocksIn ? values.observation : "");
        formData.append(`workingHoursContractId`, values.workingHoursContractId);
        formData.append(`clockRecordType`, values.clockRecordType);
        updateClockRecord( formData, fetchData , userId , setOpen);


        // fetchData(userId);
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle className={classes.Title}>
                <b>{`${t("GENERAL.confirm")} ${t("HR.clockRecord")}`}</b>
                <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container item spacing={2} md={12} lg={12}>
                    <Grid item xs={12} sm={12}>
                        <Typography>{t("HR.clockRecordFirstDialogMessage")} <b>{values.name}</b> {values.clocksIn ? t("HR.clockRecordSecondDialogMessageTrue") : t("HR.clockRecordSecondDialogMessageFalse")}</Typography>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Typography><b>{t("HR.workingHours")}</b>: {workingHoursContract.name}</Typography>
                    </Grid>

                    {!values.clocksIn &&
                        <Grid item xs={12} sm={12}>
                            <Input
                                disabled
                                label={"GENERAL.observation"}
                                value={values.observation}
                                valueName={"observation"}
                                rows={5}
                                maxLength={200}
                                handleChange={handleChange}
                            />
                        </Grid>
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Buttons
                    onClick={handleClose}
                    tipo="BN-noback"
                    conteudo={t("GENERAL.cancel").toUpperCase()}
                />
                <Buttons
                    onClick={handleSubmit}
                    tipo="BN-blue"
                    conteudo={t("GENERAL.confirm").toUpperCase()}
                />
            </DialogActions>
        </Dialog>
    )
}

export default DialogConfirmClockRecord;
