import { SEARCH, DEFAULT } from "../types";

export default (state, action) => {
  switch (action.type) {
    case DEFAULT.SET_LOADING:
      return {
        ...state,
        loading: true
      };
    case DEFAULT.RESET_MESSAGE:
      return {
        ...state,
        response: "",
        responseType: ""
      };
    case SEARCH.SEARCH:
      return {
        ...state,
        filter: action.filter,
        loading: false,
      };
    default:
      return state;
  }
};
