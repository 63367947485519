import React from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";

import Dropzone from "react-dropzone";
import Grid from '@material-ui/core/Grid';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Clear from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import axios from "axios";
import Cookies from "js-cookie";

const InputDragDrop = ({ files, onDrop, deleteRejectedFile, onRemoveFileDocs, hasUpdatedFile, imagePath, context }) => {
    const fileDownload = require("js-file-download");
    const classes = useStyles();
    const [t] = useTranslation();

    function humanFileSize(size) {
        let i = Math.floor(Math.log(size) / Math.log(1024));
        return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    };

    const onClickDownload = async (file) => {
        if (files && files.files.length > 0) {
            if (file.id) {
                const noSlashPath = file.path.replace(/\//g, ";");
                const url = process.env.REACT_APP_SERVER + "api/file/" + noSlashPath;
                axios.get(url, {
                    headers: {
                        "Content-type": "application/json",
                        "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
                    },
                    responseType: "blob"
                }).then(function (res) {
                    const blob = new Blob([res.data], {});
                    const name = file.fileName ? file.fileName : file.name;
                    fileDownload(blob, name);
                });
            } else {
                fileDownload(file, file.name);
            }
        }
    };

    return (
        <React.Fragment>
            {context !== "view" &&
                <Dropzone onDrop={onDrop}
                    accept="application/pdf, 
                    application/msword, 
                    application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                    image/jpeg, 
                    image/png"
                >
                    {({ getRootProps, getInputProps }) => (
                        <section>
                            <div {...getRootProps({ className: classes.dropzoneDialog })}>
                                <input {...getInputProps()} />
                                <AttachFileIcon />
                                <Typography>Arraste e solte os documentos para anexá-los ou <u>clique aqui</u></Typography>
                            </div>
                        </section>
                    )}
                </Dropzone>}
            <Grid item xs={12}>
                <Typography variant={"body1"} style={{ fontWeight: "bold" }}>Documentos</Typography>
                {files.rejectedFiles.length > 0 &&
                    <div style={{ paddingBottom: 8 }}>
                        {files.rejectedFiles.map(message => (
                            <div className={classes.dialogFile}>
                                <div className={classes.textFile}>
                                    <Typography style={{ color: "red" }} className={classes.nameFile}>{message}</Typography>
                                </div>
                                <div className={classes.iconsFile}>
                                    <IconButton onClick={() => deleteRejectedFile(message)}>
                                        <Clear />
                                    </IconButton>
                                </div>
                            </div>
                        ))}
                    </div>
                }
                {files.files.length > 0 ?
                    <div className={classes.dropzoneDialogFiles}>
                        {files.files.map(file => (
                            <div className={classes.dialogFile}>
                                <div className={classes.textFile}>
                                    <Typography className={classes.nameFile}>{file.fileName ? file.fileName : file.name}</Typography>
                                    <Typography>{`(${humanFileSize(file.size)})`}</Typography>
                                </div>
                                <div className={classes.iconsFile}>
                                    <IconButton onClick={() => onClickDownload(file)}>
                                        <GetAppIcon />
                                    </IconButton>
                                    <IconButton disabled={context === "view"} onClick={() => onRemoveFileDocs(file)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </div>
                            </div>
                        ))}
                    </div> :
                    <div className={classes.noFilesMessage}>
                        <Typography variant={"h6"} style={{ fontWeight: "bold" }}>{t("EXPENSES.noFilesMessage")}</Typography>
                        <Typography variant={"caption"}>{t("EXPENSES.noFilesSubMessage")}</Typography>
                    </div>
                }
            </Grid>
        </React.Fragment>
    )
}

export default InputDragDrop;