import {StyleSheet} from "@react-pdf/renderer";

const styles = StyleSheet.create({
    footer: {
        paddingTop: 10,
        paddingBottom: 20,
        display: "flex",
        alignItems: "center"
    },
    footerLogo: {
        marginBottom: 10,
        width: 50,
        height: 30
    },
    footerText: {
        color: "#5c6b7a",
        textAlign: "center",
        width: "100%",
        fontWeight: 300,
        fontSize: 9,
    }
})

export default styles