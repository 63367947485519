import React from 'react';

import Typography from 'components/typography/Typography';

const GreyDataInfo = ({ header, content, ...props }) => {
    return (
        <>
            <Typography
                variant={'RobotoBold'}
                fontSize={18}
                textColor={'secondary'}
            >
                {header}
            </Typography>
            <Typography
                variant={'RobotoRegular'}
                fontSize={16}
                textColor={'secondary'}
            >
                {content}
            </Typography>
        </>
    );
};

export default GreyDataInfo;
