
import React, { } from "react";
import { useStyles } from "components/layout/CommonStyles";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

const ActivityInformation = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const {
        activityName,
        activityStart,
        activityEnd,
        activityDescription
    } = props;

    return (
        <React.Fragment>
            <Paper className={classes.root}>
                <div className={classes.header}>
                    {t("ACTIVITIES.activityInformation").toUpperCase()}
                </div>
                <div className={classes.bodyForm}>
                    <Grid container>
                        <Grid container item spacing={2} md={12} lg={12}>
                            <Grid item xs={6}>
                                <TextField
                                    disabled
                                    className={classes.textField}
                                    margin="normal"
                                    id="activityName"
                                    label={t("ACTIVITIES.activityName")}
                                    name={t("ACTIVITIES.activityName")}
                                    value={activityName}
                                />
                            </Grid>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid item xs={3}>
                                    <KeyboardDatePicker
                                        className={classes.textField}
                                        disabled
                                        disableToolbar
                                        autoOk={true}
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="activityStartDate"
                                        label={t("GENERAL.startDate")}
                                        value={activityStart}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <KeyboardDatePicker
                                        className={classes.textField}
                                        disabled
                                        disableToolbar
                                        autoOk={true}
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="activityEndDate"
                                        label={t("GENERAL.endDate")}
                                        value={activityEnd}
                                    />
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid container item spacing={2} md={12} lg={12}>
                            <Grid item xs={12}>
                                <TextField
                                    disabled
                                    multiline
                                    fullWidth
                                    variant="outlined"
                                    rows="6"
                                    id="activityDescription"
                                    value={activityDescription}
                                    label={t("PROFILES.descriptionLabel")}
                                    margin="normal"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Paper>
            <br />
            <br />
        </React.Fragment>
    )
}

export default ActivityInformation;