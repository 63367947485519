import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import Buttons from 'components/buttons/Pressed';
import Typography from 'components/typography/Typography';
import { screenStyles } from '../Styles';
import InfoRounded from '@material-ui/icons/InfoRounded';

const DialogWarningMaxRequisitions = props => {
    const styles = screenStyles();
    const [t] = useTranslation();
    const { open, setOpen, viewValues } = props;

    const handleClose = () => {
        setOpen(false);
    };

    const routeChange = () => {
        window.location.href = '/Library/MyLoans';
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth={'xs'}
                fullWidth={true}
            >
                <DialogTitle>
                    <div className={styles.dialogTitle}>
                        <Typography
                            variant={'RobotoBold'}
                            fontSize={24}
                            textColor={'secondary'}
                        >
                            {t('LIBRARY.dialogBookRequisitionTitle')}
                        </Typography>
                        <IconButton
                            color="#6D6F71"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <p
                        style={{
                            color: '#2c3e51',
                            fontSize: '14px',
                            display: 'flex',
                            alignItems: 'center',
                            fontFamily: 'Roboto',
                            fontWeight: 'bold'
                        }}
                    >
                        <InfoRounded style={{ paddingRight: '10px' }} /> Você já
                        está em posse de&nbsp;{viewValues} livros.
                    </p>
                    <Typography
                        variant={'Roboto'}
                        fontSize={14}
                        textColor={'primary'}
                    >
                        Acesse "Meus Empréstimos" e realize uma devolução para
                        requisitar outros livros do acervo.
                    </Typography>
                </DialogContent>

                <DialogActions className={styles.dialogButtonsWrapper}>
                    <Buttons
                        onClick={handleClose}
                        tipo="BN-noback"
                        conteudo={t('GENERAL.close').toUpperCase()}
                    />

                    <Buttons
                        className={styles.dialogButton}
                        tipo="BN-blue"
                        conteudo={'IR PARA MEUS EMPRÉSTIMOS'}
                        onClick={routeChange}
                    />
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DialogWarningMaxRequisitions;
