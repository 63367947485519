import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptBRLocale from 'date-fns/locale/pt-BR';

import { inputStyles } from './Styles';

const InputFilterDate = ({
    filterValue,
    label,
    setFilter,
    monthAndYear,
    ...props
}) => {
    const classes = useStyles();
    const styles = inputStyles();
    const [t] = useTranslation();

    const clearField = () => {
        setFilter(null);
    };

    return (
        <div className={styles.filterSearchInput}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
                {monthAndYear ? (
                    <KeyboardDatePicker
                        disableToolbar
                        className={classes.textField}
                        autoOk
                        views={['year', 'month']}
                        format="MM/yyyy"
                        label={t(label)}
                        value={filterValue}
                        invalidDateMessage={t('GENERAL.invalidDate')}
                        maxDateMessage={t('GENERAL.maxDateErrorMessage')}
                        minDateMessage={t('GENERAL.minDateErrorMessage')}
                        onChange={ev => setFilter(ev)}
                        cancelLabel={'Cancelar'}
                        inputProps={{ style: { textTransform: 'capitalize' } }}
                        {...props}
                    />
                ) : (
                    <KeyboardDatePicker
                        disableToolbar
                        className={classes.textField}
                        autoOk={true}
                        variant="inline"
                        format="dd/MM/yyyy"
                        id="date"
                        invalidDateMessage={t('GENERAL.invalidDate')}
                        maxDateMessage={t('GENERAL.maxDateErrorMessage')}
                        minDateMessage={t('GENERAL.minDateErrorMessage')}
                        label={t(label)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date'
                        }}
                        value={filterValue}
                        onChange={ev => setFilter(ev)}
                        {...props}
                    />
                )}
            </MuiPickersUtilsProvider>
        </div>
    );
};

export default InputFilterDate;
