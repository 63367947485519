import { LibraryFinesStatus, LibraryFinesTypes } from 'global/constants';
import i18n from '../../i18n';

export function translateFineStatus(status) {
    switch (status) {
        case LibraryFinesStatus.PAID:
            return 'Pago';
        case LibraryFinesStatus.PENDING:
            return 'Pendente';
        case LibraryFinesStatus.NO_CHARGE:
            return 'Sem Cobrança';
        default:
            return '-';
    }
}

export function translateFinesTransactionsTypes(status) {
    switch (status) {
        case LibraryFinesTypes.BOOK_PURCHASE:
            return i18n.t('LIBRARY.bookPurchase');
        case LibraryFinesTypes.FINE_PAYMENT:
            return i18n.t('LIBRARY.payFine');
        case LibraryFinesTypes.CREDIT:
            return i18n.t('GENERAL.credit');
        case LibraryFinesTypes.DEBIT:
            return i18n.t('GENERAL.debit');
        case LibraryFinesTypes.UPDATE_EXEMPLAR_CREDIT:
            return i18n.t('LIBRARY.removeExemplarTransaction');
        case LibraryFinesTypes.UPDATE_EXEMPLAR_DEBIT:
            return i18n.t('LIBRARY.updateExemplar');
        default:
            return '-';
    }
}
