import React from 'react';
import '../../../App.css';
import SideBar from 'components/layout/sidebar/Sidebar';
import DashboardNavBar from 'components/layout/DashboardNavBar';
import Tools from './Tools';

const CallTools = ({ match }) => {
    return (
        <div>
            <DashboardNavBar />
            <SideBar />
            <Tools />
        </div>
    );
};
export default CallTools;
