import DateFnsUtils from "@date-io/date-fns";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import Attachment from "@material-ui/icons/Attachment";
import Delete from "@material-ui/icons/Delete";
import Down from "@material-ui/icons/GetApp";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import axios from "axios";
import Buttons from "components/buttons/Pressed";
import { useStyles } from "components/layout/CommonStyles";
import ProjectsContext from "context/projects/projectsContext";
import React, { useContext, useEffect, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { animated, useTransition } from "react-spring";
import Cookies from "js-cookie";

const tooltipTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#2c3e51"
    }
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: "white",
        fontSize: "1em",
        backgroundColor: "red",
        opacity: "0.7"
      }
    }
  }
});
function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      isNumericString
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      allowNegative={false}
      prefix="R$ "
    />
  );
}
// datepicker api https://material-ui-pickers.dev/api/KeyboardDatePicker
const Addendum = props => {
  let canGetFile = true;
  const classes = useStyles();
  const [t] = useTranslation();
  var fileDownload = require("js-file-download");
  const [gpf, setgpf] = useState("");


  const requiredField = "GENERAL.requiredField";
  const estimatedCostRef = useRef(null);
  const gpfRef = useRef(null);

  const {
    delay,
    addendum,
    increment,
    projectId,
    addendumId,
    previousAddendum,
    cooperationTermCost,
    callbackFromCooperationTerm
  } = props;
  const projectsContext = useContext(ProjectsContext);
  const formData = new FormData();
  const {
    loading,
    setMessage,
    resetAddendums,
    deleteAddendum,
    triggerUpdateCreate,
    updateCreateAddendums,
    idealNumberOfChildUpdates,
    triggerUpdateCreateAction,
    setIdealNumberOfChildUpdates,
    showRequiredFieldMessage,
    setValueRequiredMessage,
  } = projectsContext;

  // const [addendum, setAddendum] = useState({});
  const [state, setState] = useState({
    id: 0,
    fileId: 0,
    fileTitle: t("GENERAL.addDocument"),
    validEstimatedCost: false,
    validGpf: false,
    hasUpdatedFile: false,
    validDoc: false,
    number: "",
    stateOpacity: 1,
    projectId: null,
    estimatedCost: "",
    endDate: new Date(),
    isDownloadable: true,
    startDate: new Date(),
    cooperationTerm: false,
    openDeleteModal: false,
    fullPath: ""
  });

  const converTotBrCurrency = number => {
    var temp = "";
    var len = number.length;
    var count = 0;

    for (var i = 0; i < len; i++) {
      if (i === 1) {
        var aux = "," + number[i] + temp;
        temp = aux;
      } else {
        if (count === 3) {
          count = 1;
          var aux = number[i] + "." + temp;
          temp = aux;
        } else {
          if (i > 1) {
            count += 1;
          }
          var aux = number[i] + temp;
          temp = aux;
        }
      }
    }
    return temp;
  };

  const checkIfCurrentDateIsPastFromPreviousAddendumEndDate = now => {
    if (new Date(previousAddendum.enddate) > now) {
      const previousEndDate = new Date(previousAddendum.enddate);
      return new Date(previousEndDate.setDate(previousEndDate.getDate() + 1));
    } else {
      return new Date(now);
    }
  };

  const formatMoneyField = estimatedcost => {
    var cost = estimatedcost.toString().split("");
    if (
      (cost[cost.length - 1] === "0" && cost[cost.length - 2] === "0") ||
      cost[cost.length - 3] !== "."
    ) {
      if (cost[cost.length - 2] === ".") {
        cost.push("0");
      } else {
        cost.push("0");
        cost.push("0");
      }
    }

    var estimatedCost = cost
      .filter(el => el !== "." && el !== ",")
      .join("")
      .split("")
      .reverse()
      .join("");
    return estimatedCost;
  };

  useEffect(() => {
    // if (addendumId) {
    //   getAddendum(addendumId);
    // }
    setIdealNumberOfChildUpdates(idealNumberOfChildUpdates);
    var estimatedCost = null;
    if (addendum.estimatedcost) {
      var startdatewithtimezone = new Date(addendum.startdate)
      startdatewithtimezone.setMinutes(startdatewithtimezone.getMinutes() + startdatewithtimezone.getTimezoneOffset())
      var enddatewithtimezone = new Date(addendum.enddate)
      enddatewithtimezone.setMinutes(enddatewithtimezone.getMinutes() + enddatewithtimezone.getTimezoneOffset())
      estimatedCost = formatMoneyField(addendum.estimatedcost);
    }
    if (addendum.gpf) {
      setgpf(addendum.gpf)
    }

    setState({
      ...state,
      file: null,
      number: "ADDENDUM",
      projectId: projectId,
      fileId: addendum.fileId,
      id: addendum.id ? addendum.id : null,
      estimatedCost: estimatedCost ? converTotBrCurrency(estimatedCost) : "",
      isDownloadable: addendum.fileId ? false : true,

      startDate: addendum.startdate
        ? startdatewithtimezone
        : checkIfCurrentDateIsPastFromPreviousAddendumEndDate(new Date()),
      endDate: addendum.enddate
        ? enddatewithtimezone
        : checkIfCurrentDateIsPastFromPreviousAddendumEndDate(new Date())
    });
    // lidando com a busca do arquivo
    if (addendum.fileId) {
      getFile(addendum.fileId);
    }
    if (triggerUpdateCreateAction) {
      if (state.estimatedCost && state.estimatedCost.length === 0) {
        estimatedCostRef.current.focus();
        gpfRef.current.focus();
        setState({
          ...state,
          validEstimatedCost: true,
          validGpf: true
        });
        resetAddendums();
        triggerUpdateCreate(false);
      } else {
        var startDateFormated = state.startDate.getDate() + "/" + (state.startDate.getMonth() + 1) + "/" + state.startDate.getFullYear();
        var endDateFormated = state.endDate.getDate() + "/" + (state.endDate.getMonth() + 1) + "/" + state.endDate.getFullYear();

        var patternStimatedCost = state.estimatedCost
          .toString()
          .split("")
          .filter(el => el !== ".")
          .join("")
          .replace(",", ".");
        formData.append("id", state.id);
        formData.append("file", state.file);
        formData.append("number", state.number);
        formData.append("gpf", gpf);
        formData.append("projectId", projectId);
        formData.append("estimatedCost", patternStimatedCost);
        formData.append("cooperationTerm", state.cooperationTerm);
        formData.append("fileId", state.fileId ? state.fileId : null);
        formData.append("endDate", endDateFormated);
        formData.append("startDate", startDateFormated);
        updateCreateAddendums(formData);
      }
    }
    return () => {
      canGetFile = false;
    };
  }, [triggerUpdateCreateAction, JSON.stringify(addendum)]);

  const getAddendum = async id => {
    const res = await axios.get(
      process.env.REACT_APP_SERVER + `api/Addendums/${id}`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
      }
    }
    );
    // setAddendum(res.data);
  };

  const getFile = id => {
    axios
      .get(process.env.REACT_APP_SERVER + `api/ProjectFiles/${id}`)
      .then(res => {
        var array = res.data.path.split("/");
        if (canGetFile) {
          var estimatedCost = null;
          if (addendum.estimatedcost) {
            estimatedCost = formatMoneyField(addendum.estimatedcost);
          }
          setState({
            ...state,
            file: null, //res.data
            number: "ADDENDUM",
            projectId: projectId,
            fileId: addendum.fileId,
            fullPath: res.data.path,
            startDate: addendum.startdate
              ? new Date(addendum.startdate)
              : new Date(),
            fileTitle: array[array.length - 1],
            id: addendum.id ? addendum.id : null,
            estimatedCost: estimatedCost
              ? converTotBrCurrency(estimatedCost)
              : "",
            isDownloadable: addendum.fileId ? false : true,
            endDate: addendum.enddate ? new Date(addendum.enddate) : new Date()
          });
        }
      });
  };

  const handleFieldChangeCost = name => event => {
    if (event.target.value !== " " && !event.target.value.includes(" ")) {
      var str = event.target.value.split("");
      var aux = str.filter(el => el !== "." && el !== ",").join("");
      var value = event.target.value;

      if (event.target.value) {
        value = converTotBrCurrency(
          aux
            .split("")
            .reverse()
            .join("")
        );
      }

      aux = value
        .split("")
        .filter(el => el !== ".")
        .join("")
        .replace(",", ".");
      if (!isNaN(aux)) {
        setState({ ...state, [name]: value, validEstimatedCost: false });
      }
    }
  };

  const onClickDownload = async () => {
    if (state.fileTitle !== t("GENERAL.addDocument")) {
      var noSlashPath = state.fullPath.replace(/\//g, ";");

      const url = process.env.REACT_APP_SERVER + "api/file/" + noSlashPath;
      axios
        .get(url, {
          headers: {
            "Content-Type": "application/json"
          },
          responseType: "blob"
        })
        .then(function (res) {
          var blob = new Blob([res.data], {});
          fileDownload(blob, state.fileTitle);
        })
        .catch(() => {
          setMessage({
            response: t("PROJECTS.step.cooperationTerm.downloadError"),
            responseType: "ERROR"
          });
        });
    } else {
      console.log("treat erro when download has no file");
    }
  };

  const handleDates = name => e => {
    setState({
      ...state,
      [name]: e
    });
  };

  const handleDelete = () => {
    if (state.id) {
      setState({
        ...state,
        openDeleteModal: true
      });
    } else {
      callbackFromCooperationTerm(increment);
    }
  };

  const onDropAddendum = acceptedFiles => {
    if (acceptedFiles[0] !== undefined) {
      setState({
        ...state,
        file: acceptedFiles[0],
        fileTitle: acceptedFiles[0].name,
        hasUpdatedFile: true
      });
    } else {
      setState({
        ...state,
        fileTitle: t("GENERAL.invalidFormat")
      });
    }
  };

  const handleClose = () => {
    setState({
      ...state,
      openDeleteModal: false
    });
  };

  const handleDeleteAddendum = () => {
    var totalCost = cooperationTermCost;

    var addendumCost = parseFloat(
      state.estimatedCost
        .split("")
        .filter(el => el !== ".")
        .join("")
        .replace(",", ".")
    );

    deleteAddendum({
      id: state.id,
      //cost: totalCost - addendumCost,
      projectId: projectId
    });
  };

  const transitions = useTransition(loading || delay, null, {
    from: { opacity: 0 },
    enter: {
      opacity: 1
    },
    leave: { opacity: 0 }
  });

  const clearRequiredMessage = () => {
    setValueRequiredMessage(false);
  };

  return transitions.map(({ item, key, props }) =>
    item ? (
      <animated.div key={key} style={props}>
        <></>
      </animated.div>
    ) : (
      <animated.div key={key} style={props}>
        <div className={classes.addendumHeader, "Addendum"}>
          <Paper className={classes.root}>
            <div className={classes.bodyForm}>
              <Grid container item spacing={1}>
                <Grid
                  item
                  xs={6}
                  className={classes.addendumIntermIconsMarginLeft}
                >
                  {t("PROJECTS.step.addendum") + " " + increment}
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.addendumIntermIcons}>
                    <div className={classes.addendumIntermIconsMarginRight}>
                      <Tooltip
                        title={t("PROJECTS.step.addendum.download")}
                        aria-label="download Addendum"
                        placement="top"
                      >
                        <div>
                          <IconButton
                            disabled={state.isDownloadable}
                            onClick={onClickDownload}
                          >
                            <Down />
                          </IconButton>
                        </div>
                      </Tooltip>

                      <Tooltip
                        title={t("PROJECTS.step.addendum.delete")}
                        aria-label="delete Addendum"
                        placement="top"
                      >
                        <div>
                          <IconButton onClick={handleDelete}>
                            <Delete />
                          </IconButton>
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <MuiThemeProvider theme={tooltipTheme}>
                    <Tooltip
                      TransitionComponent={Zoom}
                      open={state.estimatedCost.length === 0 && showRequiredFieldMessage}
                      title={t(requiredField)}
                    >
                      <div>
                        <TextField
                          inputRef={estimatedCostRef}
                          className={classes.addendumTextField}
                          margin="normal"
                          id={"addendumCost" + increment}
                          label={t("PROJECTS.estimatedCost") + "*"}
                          name="estimatedCost"
                          error={state.validEstimatedCost}
                          value={state.estimatedCost}
                          onChange={handleFieldChangeCost("estimatedCost")}
                          onFocus={clearRequiredMessage}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                R$
                              </InputAdornment>
                            )
                          }}
                        />
                      </div>
                    </Tooltip>
                  </MuiThemeProvider>
                </Grid>
                <Grid item xs={2}>
                  <MuiThemeProvider theme={tooltipTheme}>
                    <Tooltip
                      TransitionComponent={Zoom}
                      open={gpf.length === 0 && showRequiredFieldMessage}
                      title={t(requiredField)}
                    >
                      <TextField
                        id={"gpf" + increment}
                        inputRef={gpfRef}
                        name="gpf"
                        label={t("PROJECTS.gpfUpperCase") + "*"}
                        className={classes.addendumTextField}
                        value={gpf}
                        onChange={e => {
                          setgpf(e.target.value);
                        }}
                        onFocus={clearRequiredMessage}
                        margin="normal"
                      />
                    </Tooltip>
                  </MuiThemeProvider>
                </Grid>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid item xs={2}>
                    <MuiThemeProvider theme={tooltipTheme}>
                      <Tooltip
                        TransitionComponent={Zoom}
                        open={!state.startDate && showRequiredFieldMessage}
                        title={t(requiredField)}
                      >
                        <KeyboardDatePicker
                          required
                          className={classes.addendumTextField}
                          disableToolbar
                          autoOk={true}
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          id={"addendumStartDate" + increment}
                          label={t("GENERAL.startDate")}
                          value={state.startDate}
                          onChange={handleDates("startDate")}
                          onFocus={clearRequiredMessage}
                          KeyboardButtonProps={{
                            "aria-label": "change date"
                          }}
                        />
                      </Tooltip>
                    </MuiThemeProvider>
                  </Grid>
                  <Grid item xs={2}>
                    <MuiThemeProvider theme={tooltipTheme}>
                      <Tooltip
                        TransitionComponent={Zoom}
                        open={!state.endDate && showRequiredFieldMessage}
                        title={t(requiredField)}
                      >
                        <KeyboardDatePicker
                          required
                          className={classes.addendumTextField}
                          disableToolbar
                          autoOk={true}
                          minDate={state.startDate}
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          id={"addendumEndDate" + increment}
                          label={t("GENERAL.endDate")}
                          value={state.endDate}
                          minDateMessage={
                            "A data não deve ser anterior à data mínima"
                          }
                          onChange={handleDates("endDate")}
                          onFocus={clearRequiredMessage}
                          KeyboardButtonProps={{
                            "aria-label": "change date"
                          }}
                        />
                      </Tooltip>
                    </MuiThemeProvider>
                  </Grid>
                </MuiPickersUtilsProvider>
                <Grid item xs={3}>
                  <Dropzone
                    variant="outlined"
                    component="span"
                    className={classes.addendumTextField}
                    onDrop={onDropAddendum}
                    accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  >
                    {({
                      getRootProps,
                      getInputProps,
                      isDragActive,
                      isDragReject
                    }) => (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {!isDragActive && (
                          <FormControl
                            className={
                              classes.customAddendumAttachedDocumentTextField
                            }
                          >
                            <InputLabel>
                              {state.hasUpdatedFile || !state.isDownloadable
                                ? t("PROJECTS.hasAttachedFile")
                                : ""}
                            </InputLabel>
                            <Input
                              id="custom-attached-input-drag"
                              readOnly={true}
                              endAdornment={
                                <InputAdornment
                                  style={{ transform: "rotate(270deg)" }}
                                  position="end"
                                >
                                  <Attachment />
                                </InputAdornment>
                              }
                              aria-describedby="weight-helper-text"
                              inputProps={{
                                "aria-label": "weight"
                              }}
                              value={t(state.fileTitle)}
                            />
                          </FormControl>
                        )}
                        {isDragReject && (
                          <FormControl
                            className={
                              classes.customAddendumAttachedDocumentTextField
                            }
                          >
                            <InputLabel>
                              {state.hasUpdatedFile || !state.isDownloadable
                                ? t("PROJECTS.hasAttachedFile")
                                : ""}
                            </InputLabel>
                            <Input
                              id="custom-attached-input"
                              readOnly={true}
                              endAdornment={
                                <InputAdornment
                                  style={{ transform: "rotate(270deg)" }}
                                  position="end"
                                >
                                  <Attachment />
                                </InputAdornment>
                              }
                              aria-describedby="weight-helper-text"
                              inputProps={{
                                "aria-label": "weight"
                              }}
                              value={t(state.fileTitle)}
                            />
                          </FormControl>
                        )}
                      </div>
                    )}
                  </Dropzone>
                </Grid>
              </Grid>
            </div>
          </Paper>
        </div>
        <Dialog
          open={state.openDeleteModal}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle className={classes.Title}>
            {t("GENERAL.delete")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("GENERAL.deleteMessageM")} {t("PROJECTS.step.addendum")}
              {" " + increment} ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Buttons
              onClick={handleClose}
              tipo="BN-noback"
              conteudo={t("GENERAL.cancel").toUpperCase()}
            />
            <Buttons
              onClick={handleDeleteAddendum}
              tipo="BN-blue"
              conteudo={t("GENERAL.delete").toUpperCase()}
            />
          </DialogActions>
        </Dialog>
      </animated.div>
    )
  );
};

export default Addendum;
