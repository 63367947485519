export const quotasNotifications = [
    {
        event: 'QUOTAS_PARTICIPATION_EMAIL_AUTOMATIC',
        allowSend: true,
        automatic: true
    },
    {
        event: 'QUOTAS_PARTICIPATION_EMAIL_OPTIONAL',
        allowSend: false,
        automatic: false
    },
    {
        event: 'QUOTAS_NEW_BILL_EMAIL_AUTOMATIC',
        allowSend: true,
        automatic: true
    },
    {
        event: 'QUOTAS_CREDIT_SOLICITATION_EMAIL_AUTOMATIC',
        allowSend: true,
        automatic: true
    },
    {
        event: 'QUOTAS_CREDIT_SOLICITATION_EMAIL_OPTIONAL',
        allowSend: false,
        automatic: false
    },
    {
        event: 'QUOTAS_CREDIT_SOLICITATION_APPROVED_EMAIL_AUTOMATIC',
        allowSend: true,
        automatic: true
    },
    {
        event: 'QUOTAS_CREDIT_SOLICITATION_APPROVED_EMAIL_OPTIONAL',
        allowSend: false,
        automatic: false
    },
    {
        event: 'QUOTAS_PAYED_BILL_EMAIL_AUTOMATIC',
        allowSend: true,
        automatic: true
    },
    {
        event: 'QUOTAS_CREDIT_SOLICITATION_CANCELED_EMAIL_AUTOMATIC',
        allowSend: true,
        automatic: true
    },
    {
        event: 'QUOTAS_CREDIT_SOLICITATION_CANCELED_EMAIL_OPTIONAL',
        allowSend: false,
        automatic: false
    },
    {
        event: 'QUOTAS_CREDIT_SOLICITATION_REFUSED_EMAIL_AUTOMATIC',
        allowSend: true,
        automatic: true
    },
    {
        event: 'QUOTAS_CREDIT_SOLICITATION_REFUSED_EMAIL_OPTIONAL',
        allowSend: false,
        automatic: false
    },
    {
        event: 'QUOTAS_LATE_BILL_EMAIL_AUTOMATIC',
        allowSend: true,
        automatic: true
    },
    {
        event: 'QUOTAS_PAYED_BILL_BY_MANAGER_EMAIL_AUTOMATIC',
        allowSend: true,
        automatic: true
    },
    {
        event: 'QUOTAS_CANCELED_BILL_EMAIL_AUTOMATIC',
        allowSend: true,
        automatic: true
    },
    {
        event: 'QUOTAS_ADDED_CREDIT_EMAIL_AUTOMATIC',
        allowSend: true,
        automatic: true
    },
    {
        event: 'QUOTAS_ADDED_CREDIT_EMAIL_OPTIONAL',
        allowSend: false,
        automatic: false
    }
];
