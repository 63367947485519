import { makeStyles } from '@material-ui/core/styles'

export const screenStyles = makeStyles(theme => ({
    bookList: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, 200px)',
        rowGap: '50px',
        columnGap: '20px',
        padding: '48px 24px'
    },
    labelContainer: {
        width: 'auto',
        padding: 0
    },
    iconLabelWrapper2: {
        flexDirection: 'row-reverse'
    },
    iconLabel: {
        marginLeft: '12px'
    },
    Link: {
        textDecoration: 'none',
        color: '#73b3ff',
        fontWeight: 700
    }
}))
