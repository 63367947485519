import React from 'react';
import { useTranslation } from 'react-i18next';
import { screenStyles } from './Styles';
import Typography from 'components/typography/Typography';
import { valueToMoneyFloat } from 'utils/general/valueToMoneyFloat';
import { moneyMask } from 'utils/masks/moneyMask';
import { Months } from 'global/constants';
import { ReactComponent as PlusGreenIcon } from 'assets/icons/plusGreen.svg';
import { ReactComponent as MinusRedIcon } from 'assets/icons/minusRed.svg';
import OutlinedWithIconButton from 'components/buttons/OutlinedWithIconButton';

export const QuotasLSBD = props => {
    const {
        accountBalance,
        paidBills,
        pendingBills,
        receivedCredit,
        missingCredit,
        setOpenCreditDialog,
        setOpenDebitDialog,
        isManager
    } = props;
    const [t] = useTranslation();

    const styles = screenStyles();

    return (
        <React.Fragment>
            <div className={styles.finesInfoContainer}>
                <div className={styles.finesInfoWrapper}>
                    <div className={styles.finesInfoContentFirst}>
                        <Typography
                            variant={'RobotoRegular'}
                            fontSize={16}
                            textColor={'white'}
                        >
                            {t('GENERAL.totalAccountBalance')}
                        </Typography>
                        <Typography
                            variant={'RobotoBold'}
                            fontSize={38}
                            margin={'8px 0px 0px 0px'}
                            textColor={accountBalance < 0 ? 'red' : 'white'}
                        >
                            {`${accountBalance < 0 ? '-' : ''} R$ ${moneyMask(
                                valueToMoneyFloat(accountBalance)
                            )}`}
                        </Typography>
                    </div>

                    {isManager ? (
                        <div className={styles.finesInfoContent}>
                            <Typography
                                variant={'RobotoRegular'}
                                fontSize={16}
                                textColor={'white'}
                            >
                                {`${t('GENERAL.payersIn')}
                            ${Months[new Date().getMonth()].label}`}
                            </Typography>
                            <Typography
                                variant={'RobotoBold'}
                                fontSize={24}
                                textColor={'white'}
                                margin={'8px 0px 0px 0px'}
                            >
                                {`${paidBills}/${pendingBills}`}
                            </Typography>
                        </div>
                    ) : (
                        <>
                            <div className={styles.finesInfoContent}>
                                <Typography
                                    variant={'RobotoRegular'}
                                    fontSize={16}
                                    textColor={'white'}
                                >
                                    {`${t('GENERAL.payersIn')}
                            ${Months[new Date().getMonth()].label}`}
                                </Typography>
                                <Typography
                                    variant={'RobotoBold'}
                                    fontSize={24}
                                    textColor={'white'}
                                    margin={'8px 0px 0px 0px'}
                                >
                                    {paidBills}
                                </Typography>
                            </div>
                            <div className={styles.finesInfoContent}>
                                <Typography
                                    variant={'RobotoRegular'}
                                    fontSize={16}
                                    textColor={'white'}
                                >
                                    {`${t('GENERAL.pendingIn')}
                            ${Months[new Date().getMonth()].label}`}
                                </Typography>
                                <Typography
                                    variant={'RobotoBold'}
                                    fontSize={24}
                                    textColor={'white'}
                                    margin={'8px 0px 0px 0px'}
                                >
                                    {pendingBills}
                                </Typography>
                            </div>
                        </>
                    )}

                    <div className={styles.finesInfoContent}>
                        <Typography
                            variant={'RobotoRegular'}
                            fontSize={16}
                            textColor={'white'}
                        >
                            {`${t('GENERAL.valueReceivedIn')}
                            ${Months[new Date().getMonth()].label}`}
                        </Typography>
                        <Typography
                            variant={'RobotoBold'}
                            fontSize={24}
                            textColor={'white'}
                            margin={'8px 0px 0px 0px'}
                        >
                            {`R$ ${moneyMask(
                                valueToMoneyFloat(receivedCredit)
                            )}`}
                        </Typography>
                    </div>

                    <div className={styles.finesInfoContentLast}>
                        <Typography
                            variant={'RobotoRegular'}
                            fontSize={16}
                            textColor={'white'}
                        >
                            {`${t('GENERAL.pendingValueIn')}
                            ${Months[new Date().getMonth()].label}`}
                        </Typography>
                        <Typography
                            variant={'RobotoBold'}
                            fontSize={24}
                            textColor={'white'}
                            margin={'8px 0px 0px 0px'}
                        >
                            {`R$ ${moneyMask(
                                valueToMoneyFloat(missingCredit)
                            )}`}
                        </Typography>
                    </div>

                    {isManager ? (
                        <div className={styles.buttonsContainer}>
                            <OutlinedWithIconButton
                                text={t('QUOTAS.addCredit')}
                                Icon={PlusGreenIcon}
                                onClick={() => setOpenCreditDialog(true)}
                            />
                            <OutlinedWithIconButton
                                text={t('QUOTAS.addDebit')}
                                Icon={MinusRedIcon}
                                onClick={() => setOpenDebitDialog(true)}
                            />
                        </div>
                    ) : null}
                </div>
            </div>
        </React.Fragment>
    );
};

export default QuotasLSBD;
