/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Input from 'components/inputs/Input';
import InputSwitch from 'components/inputs/InputSwitch';
import Typography from '@material-ui/core/Typography';
import Footer from 'components/layout/Footer';
import ProfileManagementTabPanel from './ProfileManagementTabPanel';
import Breadcrumb from 'components/layout/breadcrumb/Breadcrumb';
import HeaderContainer from 'components/containers/HeaderContainer';
import ContentContainer from 'components/containers/ContentContainer';
import Transition from 'components/layout/transition/Transition';
import Buttons from 'components/buttons/Pressed';

import ErrorIcon from '@material-ui/icons/Error';

import { patrimonyData } from './ScreenData/PatrimonyData';
import { patrimonyNotifications } from './ScreenData/PatrimonyNotifications';
import { humanResourceData } from './ScreenData/HumanResourceData';
import { humanResourceNotifications } from './ScreenData/HumanResourceNotifications';
import { newsData } from './ScreenData/NewsData';
import { newsNotifications } from './ScreenData/NewsNotifications';
import { libraryData } from './ScreenData/LibraryData';
import { libraryNotifications } from './ScreenData/LibraryNotifications';
import { quotasData } from './ScreenData/QuotasData';
import { quotasNotifications } from './ScreenData/QuotasNotifications';
import { adminData } from './ScreenData/AdminData';
import { adminNotifications } from './ScreenData/AdminNotifications';

import SettingsContext from 'context/hrModule/settings/settingsContext';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import { screenStyles } from './Styles';
import { MakeDeepCopy } from 'utils/general/MakeDeepCopy';

import { Modules } from 'global/constants';

const ProfileManagementForm = props => {
    const classes = useStyles();
    const styles = screenStyles();
    const [t] = useTranslation();
    const settingsContext = useContext(SettingsContext);
    const { id, profileId, context, ...others } = props;
    const [mustRedirect, setMustRedirect] = useState(false);

    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const {
        loading,
        setLoading,
        setLoadingFalse,
        addProfile,
        roleModules,
        getAllRoleModules,
        getProfile,
        profile,
        updateProfile
    } = settingsContext;

    const modules = [
        {
            value: Modules.PATRIMONY.id,
            label: 'Patrimônio'
        },
        {
            value: Modules.HR.id,
            label: 'Gestão de Pessoas'
        },
        {
            value: Modules.NEWS.id,
            label: 'Notícias'
        },
        {
            value: Modules.LIBRARY.id,
            label: 'Biblioteca'
        },
        {
            value: Modules.QUOTAS.id,
            label: 'Cotas'
        },
        {
            value: Modules.ADMINISTRATIVE.id,
            label: 'Administrativo'
        }
    ];

    const [values, setValues] = useState({
        id: '',
        name: '',
        moduleId: '',
        moduleName: '',
        description: ''
    });

    const [rows, setRows] = useState([]);
    const [rowsNotifications, setRowsNotifications] = useState([]);

    const clearFields = () => {
        setValues({
            id: '',
            name: '',
            moduleId: '',
            moduleName: '',
            description: '',
            isManager: false
        });
        setRows([]);
        setRowsNotifications([]);
    };

    const [requiredName, setRequiredName] = useState(false);
    const [nameErrorMessage, setNameErrorMessage] = useState('');
    const [requiredModule, setRequiredModule] = useState(false);

    async function fetchData(profileId) {
        clearFields();
        await setLoading();
        await getAllRoleModules();
        if (context !== 'new') {
            await getProfile(profileId);
        }
        await setLoadingFalse();
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData(profileId);
    }, []);

    const redirect = go => {
        if (go) return <Redirect to={`/Admin/ProfileManagement`} />;
    };

    useEffect(() => {
        if (profile && context !== 'new') {
            setValues({
                id: profile.id ? profile.id : '',
                name: profile.name ? profile.name : '',
                moduleId: profile.systemId ? profile.systemId : '',
                moduleName: modules.find(
                    module => module.value === profile.systemId
                ).label,
                description: profile.description ? profile.description : '',
                isManager: profile.isManager ? profile.isManager : false
            });
            let newValuesOfScreens = [];
            let newValuesOfNotifications = [];
            if (profile.systemId === Modules.HR.id) {
                humanResourceData.forEach(element => {
                    var ItemIndex = profile.generalPermissions.findIndex(
                        b => b.screen === element.screen
                    );
                    if (ItemIndex === -1) {
                        newValuesOfScreens.push(element);
                    } else {
                        if (element.tab && ItemIndex !== 1) {
                            profile.generalPermissions[ItemIndex].tab =
                                element.tab;
                        }
                    }
                });

                humanResourceNotifications.forEach(element => {
                    var ItemIndex =
                        profile.generalPermissionsNotifications.findIndex(
                            b => b.event === element.event
                        );

                    if (ItemIndex === -1) {
                        newValuesOfNotifications.push(element);
                    }
                });
            }
            if (profile.systemId === Modules.PATRIMONY.id) {
                patrimonyData.forEach(element => {
                    var ItemIndex = profile.generalPermissions.findIndex(
                        b => b.screen === element.screen
                    );
                    if (ItemIndex === -1) {
                        newValuesOfScreens.push(element);
                    }
                });

                patrimonyNotifications.forEach(element => {
                    var ItemIndex =
                        profile.generalPermissionsNotifications.findIndex(
                            b => b.event === element.event
                        );

                    if (ItemIndex === -1) {
                        newValuesOfNotifications.push(element);
                    }
                });
            }
            if (profile.systemId === Modules.NEWS.id) {
                newsData.forEach(element => {
                    var ItemIndex = profile.generalPermissions.findIndex(
                        b => b.screen === element.screen
                    );
                    if (ItemIndex === -1) {
                        newValuesOfScreens.push(element);
                    }
                });

                newsNotifications.forEach(element => {
                    var ItemIndex =
                        profile.generalPermissionsNotifications.findIndex(
                            b => b.event === element.event
                        );

                    if (ItemIndex === -1) {
                        newValuesOfNotifications.push(element);
                    }
                });
            }
            if (profile.systemId === Modules.LIBRARY.id) {
                libraryData.forEach(element => {
                    var ItemIndex = profile.generalPermissions.findIndex(
                        b => b.screen === element.screen
                    );
                    if (ItemIndex === -1) {
                        newValuesOfScreens.push(element);
                    }
                });
                libraryNotifications.forEach(element => {
                    var ItemIndex =
                        profile.generalPermissionsNotifications.findIndex(
                            b => b.event === element.event
                        );

                    if (ItemIndex === -1) {
                        newValuesOfNotifications.push(element);
                    }
                });
            }

            if (profile.systemId === Modules.QUOTAS.id) {
                quotasData.forEach(element => {
                    var ItemIndex = profile.generalPermissions.findIndex(
                        b => b.screen === element.screen
                    );
                    if (ItemIndex === -1) {
                        newValuesOfScreens.push(element);
                    }
                });
                quotasNotifications.forEach(element => {
                    var ItemIndex =
                        profile.generalPermissionsNotifications.findIndex(
                            b => b.event === element.event
                        );

                    if (ItemIndex === -1) {
                        newValuesOfNotifications.push(element);
                    }
                });
            }

            if (profile.systemId === Modules.ADMINISTRATIVE.id) {
                adminData.forEach(element => {
                    var ItemIndex = profile.generalPermissions.findIndex(
                        b => b.screen === element.screen
                    );
                    if (ItemIndex === -1) {
                        newValuesOfScreens.push(element);
                    }
                });
                adminNotifications.forEach(element => {
                    var ItemIndex =
                        profile.generalPermissionsNotifications.findIndex(
                            b => b.event === element.event
                        );

                    if (ItemIndex === -1) {
                        newValuesOfNotifications.push(element);
                    }
                });
            }

            setRows([...profile.generalPermissions, ...newValuesOfScreens]);
            setRowsNotifications([
                ...profile.generalPermissionsNotifications,
                ...newValuesOfNotifications
            ]);
        }
    }, [profile]);

    const handleChange = (name, value) => {
        let val = value;
        setValues({ ...values, [name]: val });
    };

    const handleChangeModule = (name, value) => {
        let currentModule = modules.find(module => module.value === value);
        setValues({
            ...values,
            [name]: value,
            moduleName: currentModule.label
        });
        if (currentModule.value === Modules.PATRIMONY.id) {
            setRows(MakeDeepCopy(patrimonyData));
            setRowsNotifications(MakeDeepCopy(patrimonyNotifications));
        }
        if (currentModule.value === Modules.HR.id) {
            setRows(MakeDeepCopy(humanResourceData));
            setRowsNotifications(MakeDeepCopy(humanResourceNotifications));
        }
        if (currentModule.value === Modules.NEWS.id) {
            setRows(MakeDeepCopy(newsData));
            setRowsNotifications(MakeDeepCopy(newsNotifications));
        }
        if (currentModule.value === Modules.LIBRARY.id) {
            setRows(MakeDeepCopy(libraryData));
            setRowsNotifications(MakeDeepCopy(libraryNotifications));
        }
        if (currentModule.value === Modules.QUOTAS.id) {
            setRows(MakeDeepCopy(quotasData));
            setRowsNotifications(MakeDeepCopy(quotasNotifications));
        }
        if (currentModule.value === Modules.ADMINISTRATIVE.id) {
            setRows(MakeDeepCopy(adminData));
            setRowsNotifications(MakeDeepCopy(adminNotifications));
        }
    };

    const handleCancel = () => {
        setMustRedirect(true);
    };

    const verifySubmit = () => {
        let res = true;
        // .map(x => x.name !== roleModules.name && !x.hasDeleted ? x.name.toLowerCase().trim() : null)
        if (
            roleModules
                .map(x =>
                    x.systemId === values.moduleId && x.id !== values.id
                        ? x.name.toLowerCase().trim()
                        : null
                )
                .includes(values.name.toLowerCase().trim())
        ) {
            res = false;
            setRequiredName(true);
            setNameErrorMessage(t('HR.profileAlreadyInUse'));
        }

        if (!values.name.trim()) {
            res = false;
            setRequiredName(true);
            setNameErrorMessage(t('GENERAL.requiredField'));
        }

        if (!values.moduleId) {
            res = false;
            setRequiredModule(true);
        }

        return res;
    };

    const handleSubmit = () => {
        const canSubmit = verifySubmit();
        if (canSubmit) {
            let formData = new FormData();

            formData.append('role.id', values.id ? values.id : 0);
            formData.append('role.name', values.name ? values.name : '');
            formData.append(
                'role.systemId',
                values.moduleId ? values.moduleId : ''
            );
            formData.append(
                'role.description',
                values.description ? values.description : ''
            );
            formData.append(
                'role.isManager',
                values.isManager ? values.isManager : ''
            );

            rows.forEach((row, idx) => {
                formData.append(
                    `generalPermissions[${idx}].id`,
                    row.id ? row.id : 0
                );
                formData.append(
                    `generalPermissions[${idx}].screen`,
                    row.screen
                );
                formData.append(
                    `generalPermissions[${idx}].allowRead`,
                    row.allowRead != undefined ? row.allowRead : ''
                );
                formData.append(
                    `generalPermissions[${idx}].allowCreate`,
                    row.allowCreate != undefined ? row.allowCreate : ''
                );
                formData.append(
                    `generalPermissions[${idx}].allowEdit`,
                    row.allowEdit != undefined ? row.allowEdit : ''
                );
                formData.append(
                    `generalPermissions[${idx}].allowDelete`,
                    row.allowDelete != undefined ? row.allowDelete : ''
                );
                formData.append(
                    `generalPermissions[${idx}].isManager`,
                    row.isManager != undefined ? values.isManager : ''
                );
                formData.append(
                    `generalPermissions[${idx}].roleModuleId`,
                    row.roleModuleId ? row.roleModuleId : 0
                );
            });

            rowsNotifications.forEach((rowNotifications, idx) => {
                formData.append(
                    `generalPermissionsNotifications[${idx}].id`,
                    rowNotifications.id ? rowNotifications.id : 0
                );
                formData.append(
                    `generalPermissionsNotifications[${idx}].event`,
                    rowNotifications.event
                );
                formData.append(
                    `generalPermissionsNotifications[${idx}].allowSend`,
                    rowNotifications.allowSend != undefined
                        ? rowNotifications.allowSend
                        : ''
                );
                formData.append(
                    `generalPermissionsNotifications[${idx}].automatic`,
                    rowNotifications.automatic != undefined
                        ? rowNotifications.automatic
                        : ''
                );
                formData.append(
                    `generalPermissionsNotifications[${idx}].isManager`,
                    rowNotifications.isManager != undefined
                        ? values.isManager
                        : ''
                );
                formData.append(
                    `generalPermissionsNotifications[${idx}].roleModuleId`,
                    rowNotifications.roleModuleId
                        ? rowNotifications.roleModuleId
                        : 0
                );
            });

            if (context === 'new') {
                addProfile(formData, setMustRedirect);
            } else {
                updateProfile(formData, setMustRedirect);
            }
        }
    };

    return (
        <React.Fragment>
            {redirect(mustRedirect)}

            <HeaderContainer>
                <Breadcrumb
                    newDesign={true}
                    routes={[
                        {
                            label: t('ADMIN.Administrative'),
                            to: '/Admin'
                        },
                        {
                            label: t('HR.profiles'),
                            to: '/Admin/ProfileManagement'
                        },
                        {
                            label:
                                context === 'new'
                                    ? t('HR.addProfile')
                                    : context === 'edit'
                                    ? t('HR.editProfile')
                                    : t('HR.viewProfile')
                        }
                    ]}
                />
                <div className="container-headerNewDesign">
                    <h1 className={classes.titleNewDesign}>
                        {context === 'new'
                            ? t('HR.addProfile')
                            : context === 'edit'
                            ? t('HR.editProfile')
                            : t('HR.viewProfile')}
                    </h1>
                </div>
            </HeaderContainer>
            <ContentContainer>
                <Paper>
                    <div className="cabecalho">
                        {context === 'new'
                            ? t('HR.addProfile').toUpperCase()
                            : context === 'edit'
                            ? t('HR.editProfile').toUpperCase()
                            : t('HR.viewProfile').toUpperCase()}
                    </div>
                    <Transition loading={loading} newDesign={true}>
                        <Paper>
                            <div className={styles.form}>
                                <Grid item xs={12} sm={5}>
                                    <Input
                                        required
                                        context={context}
                                        label={'PERSONAL.name'}
                                        value={values.name}
                                        valueName={'name'}
                                        maxLength={60}
                                        handleChange={handleChange}
                                        error={requiredName}
                                        helperText={
                                            requiredName && nameErrorMessage
                                        }
                                        setRequired={setRequiredName}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={5}>
                                    <Input
                                        required
                                        disabled={context !== 'new'}
                                        label={'HR.correspondingModule'}
                                        isSelect
                                        arraySelected={modules}
                                        value={values.moduleId}
                                        valueName={'moduleId'}
                                        handleChange={handleChangeModule}
                                        inputProps={{ autocomplete: 'off' }}
                                        error={requiredModule}
                                        helperText={
                                            requiredModule &&
                                            t('GENERAL.requiredField')
                                        }
                                        setRequired={setRequiredModule}
                                    />
                                </Grid>
                                <Box width="100%" />

                                <Grid item xs={12} sm={5}>
                                    <Input
                                        disabled={
                                            context === 'view' ? true : false
                                        }
                                        label={'GENERAL.description'}
                                        value={values.description}
                                        valueName={'description'}
                                        rows={5}
                                        maxLength={250}
                                        handleChange={handleChange}
                                    />
                                </Grid>
                            </div>
                        </Paper>
                    </Transition>
                </Paper>

                {values.moduleId && (
                    <Paper className={styles.space}>
                        <div className="cabecalho">
                            {t('HR.profileConfig').toUpperCase()}
                        </div>
                        <Transition loading={loading} newDesign={true}>
                            <Paper>
                                <div className={styles.form}>
                                    <Typography className={styles.title}>
                                        {values.moduleName}
                                    </Typography>
                                    <Typography className={styles.subtitle}>
                                        {t('HR.selectBelowTheOptions')}
                                    </Typography>
                                    {values.moduleId !== Modules.QUOTAS.id &&
                                    values.moduleId !==
                                        Modules.ADMINISTRATIVE.id ? (
                                        <>
                                            <div className={styles.wrapper}>
                                                <ErrorIcon
                                                    style={{
                                                        marginRight: '0.15rem',
                                                        color: '#FFD200'
                                                    }}
                                                    fontSize="small"
                                                />
                                                <Typography
                                                    className={styles.subtitle}
                                                >
                                                    {t(
                                                        'HR.thisModuleHasAManagerProfile'
                                                    )}
                                                </Typography>
                                            </div>
                                            <div className={styles.wrapper}>
                                                <Typography
                                                    className={styles.subtitle}
                                                >
                                                    {t(
                                                        'HR.thisProfileIsApproverOrSurrogate'
                                                    )}
                                                </Typography>
                                                <InputSwitch
                                                    context={context}
                                                    value={values.isManager}
                                                    valueName={'isManager'}
                                                    handleChange={handleChange}
                                                />
                                            </div>
                                        </>
                                    ) : null}
                                    <ProfileManagementTabPanel
                                        moduleName={values.moduleName}
                                        rows={rows}
                                        setRows={setRows}
                                        rowsNotifications={rowsNotifications}
                                        setRowsNotifications={
                                            setRowsNotifications
                                        }
                                        isManager={values.isManager}
                                        context={context}
                                    />
                                    {context === 'view' ? null : (
                                        <>
                                            <Buttons
                                                tipo="BN-noback"
                                                onClick={handleCancel}
                                                conteudo={t(
                                                    'GENERAL.cancel'
                                                ).toUpperCase()}
                                            />
                                            <Buttons
                                                onClick={handleSubmit}
                                                tipo="BN-blue"
                                                conteudo={
                                                    context === 'edit'
                                                        ? t(
                                                              'GENERAL.update'
                                                          ).toUpperCase()
                                                        : t(
                                                              'GENERAL.add'
                                                          ).toUpperCase()
                                                }
                                            />
                                        </>
                                    )}
                                </div>
                            </Paper>
                        </Transition>
                    </Paper>
                )}
            </ContentContainer>
            <Footer />
        </React.Fragment>
    );
};

export default ProfileManagementForm;
