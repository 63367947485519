/**
 * Format a date string to a Date object
 * @param {*} str date string
 * @returns {Date} date object
 */
export const FormatDateToFront = str => {
    if (isNaN(Date.parse(str))) {
        throw new Error('string is not a valid date');
    }

    const dateStr = str.split(/[-T.]/);

    if (dateStr.length < 3) {
        throw new Error('string is not a valid date');
    }

    const year = dateStr[0];
    const month = dateStr[1] - 1;
    const day = dateStr[2];
    let hour = 0;
    let minutes = 0;
    let seconds = 0;

    // If the date string has time
    if (dateStr.length > 3) {
        const hourStr = dateStr[3].split(':');
        hour = hourStr[0];
        minutes = hourStr[1];
        seconds = hourStr[2];
    }

    return new Date(year, month, day, hour, minutes, seconds);
};
