import React, { Component } from 'react';
import IdleTimer from 'react-idle-timer';
import history from './history';
import Cookies from 'js-cookie';
import axios from 'axios';

export default class YourApp extends Component {
    constructor(props) {
        super(props)
        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
    }

    active = false;

    render() {
        return (
            <IdleTimer
                ref={ref => { this.idleTimer = ref }}
                element={document}
                onIdle={this.onIdle}
                onAction={this.onAction}
                throttle={300000}   // tempo (em ms) para captura de evento onAction (refresh do token)
                timeout={7200000}   // tempo (em ms) de inatividade para deslogar
            />
        )
    }

    _onAction(e) {
        if(!this.active){
            this.active = true;
        } else{
            // console.log('user is active');
            // console.log("c: ", process.env.REACT_APP_SERVER + `api/auth/RefreshToken`)
            axios.get(process.env.REACT_APP_SERVER + `api/auth/RefreshToken`, {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
                }
            })
                .then(res => {
                    if (res.statusText === "OK") {
                        if(res.data !== null){
                            // console.log("old token: ", Cookies.get('auth-token'), "\n",
                            //             "new token: ", res.data);
                            Cookies.set("auth-token", res.data, {
                                // setCookie("auth-token", 'ola eu sou um cookie', {
                                path: "/",
                                domain: process.env.REACT_APP_DOMAIN
                                // maxAge: 900,
                                // secure: false,
                                // httpOnly: false
                            });

                            var authData = JSON.parse(localStorage.getItem('auth-token'));
                            //console.log("authData: ", authData);
                            authData.token = res.data;
                            localStorage.setItem('auth-token', JSON.stringify(authData)); 
                            
                        }
                    }
                })
                .catch(error => {
                    console.log(error)
                });

        }
    }

    _onActive(e) {
        // console.log('user is active', e)
        // console.log('time remaining', new Date(this.idleTimer.getRemainingTime()))
    }

    _onIdle(e) {
        // console.log('user is idle', e)
        // console.log('last active', new Date(this.idleTimer.getLastActiveTime()))
        this.active=false;
        localStorage.removeItem("auth-token");
        Cookies.remove("auth-token");
        // history.push("/login");
        window.location.href = process.env.REACT_APP_SITE;
    }
}