import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import Input from 'components/inputs/Input';
import InputFile from 'components/inputs/InputFile';
import Buttons from 'components/buttons/Pressed';
import Typography from 'components/typography/Typography';
import { moneyMaskToFloat } from 'utils/general/moneyMaskToFloat';
import { moneyMask } from 'utils/masks/moneyMask';
import { useMutationCreateCredit } from 'hooks/DataFetching/Quotas/Dashboard/useMutationCreateCredit';
import { QuotaTransactionSources } from 'global/constants';
import InputAutoComplete from 'components/inputs/InputAutoComplete';
import { useQueryUsersActiveInQuotaModuleWithCreditSolicitations } from 'hooks/DataFetching/Quotas/Dashboard/useQueryUsersActiveInQuotaModuleWithCreditSolicitations';
import NotificationTip from 'components/infoTooltip/NotificationTip';
import { GetInputArray } from 'utils/general/GetInputArray';
import InputDate from 'components/inputs/InputDate';
import { dateValidation } from 'utils/validation/DateValidation';
import { FormatDateToDataBase } from 'utils/dates/FormatDateToDataBase';

const DialogAddCredit = props => {
    const [t] = useTranslation();

    const { open, setOpen } = props;

    const mutation = useMutationCreateCredit(setOpen);

    const { data } = useQueryUsersActiveInQuotaModuleWithCreditSolicitations(
        {}
    );

    const [requiredValue, setRequiredValue] = useState(false);
    const [requiredExternal, setRequiredExternal] = useState(false);
    const [requiredCollaborator, setRequiredCollaborator] = useState(false);
    const [
        collaboratorAlreadyHasSolicitation,
        setCollaboratorAlreadyHasSolicitation
    ] = useState(false);
    const [requiredDate, setRequiredDate] = useState(false);
    const [dateErrorMessage, setDateErrorMessage] = useState('');

    const [file, setFile] = useState([]);

    const [values, setValues] = useState({
        value: '0,00',
        observation: '',
        source: QuotaTransactionSources.USER,
        externalContributorUser: '',
        collaborator: {},
        date: new Date(new Date().setHours(0, 0, 0, 0))
    });

    const [collaboratorsArray, setCollaboratorsArray] = useState([]);

    const handleChange = (name, value) => {
        let val = value;
        if (name === 'value') {
            setValues({ ...values, [name]: moneyMask(val) });
        } else {
            setValues({ ...values, [name]: val });
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const verifySubmit = () => {
        let res = true;

        if (values.value === '0,00') {
            res = false;
            setRequiredValue(true);
        }

        if (
            values.source === QuotaTransactionSources.EXTERNAL &&
            !values.externalContributorUser
        ) {
            res = false;
            setRequiredExternal(true);
        }

        if (
            values.source === QuotaTransactionSources.USER &&
            !values.collaborator.value
        ) {
            res = false;
            setRequiredCollaborator(true);
        }

        if (!dateValidation(values.date)) {
            res = false;
            setRequiredDate(true);
            setDateErrorMessage(t('GENERAL.invalidDate'));
        }

        if (new Date(values.date) > new Date()) {
            res = false;
            setRequiredDate(true);
            setDateErrorMessage(t('GENERAL.dateInTheFutureError'));
        }

        if (new Date(values.date) < new Date(2013, 0, 1)) {
            res = false;
            setRequiredDate(true);
            setDateErrorMessage(t('QUOTAS.dateBeforeQuotasYear'));
        }

        return res;
    };

    const handleSubmit = () => {
        const canSubmit = verifySubmit();
        if (canSubmit) {
            let formData = new FormData();

            formData.append(
                'value',
                values.value ? moneyMaskToFloat(values.value) : ''
            );
            formData.append(
                'description',
                values.observation ? values.observation : ''
            );
            formData.append('source', values.source ? values.source : '');
            formData.append(
                'externalContributorUser',
                values.externalContributorUser
                    ? values.externalContributorUser
                    : ''
            );
            formData.append(
                'userId',
                values.collaborator.value ? values.collaborator.value : ''
            );

            formData.append('receipt', file.length ? file[0] : null);
            formData.append(
                'date',
                values.date ? FormatDateToDataBase(values.date) : ''
            );

            mutation.mutate(formData);
        }
    };

    const sourceArray = [
        {
            label: 'Crédito Colaborador',
            value: QuotaTransactionSources.USER
        },
        {
            label: 'Contribuição Externa',
            value: QuotaTransactionSources.EXTERNAL
        }
    ];

    useEffect(() => {
        values.collaborator = {};
        setRequiredCollaborator(false);
        values.externalContributorUser = '';
        setRequiredExternal(false);
        setCollaboratorAlreadyHasSolicitation(false);
    }, [values.source]);

    useEffect(() => {
        setRequiredDate(false);
    }, [values.date]);

    useEffect(() => {
        if (data) {
            setCollaboratorsArray(
                GetInputArray(
                    data,
                    'id',
                    'name',
                    'quotaUserCreditSolicitations'
                )
            );
        }
    }, [data]);

    useEffect(() => {
        if (
            values.collaborator &&
            values.collaborator.type &&
            values.collaborator.type.length > 0
        ) {
            setCollaboratorAlreadyHasSolicitation(true);
        } else {
            setCollaboratorAlreadyHasSolicitation(false);
        }
    }, [values.collaborator]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            maxWidth={'sm'}
            fullWidth
        >
            <DialogTitle>
                <Grid container item spacing={2} xs={12}>
                    <Grid item xs={11}>
                        <Typography
                            variant={'RobotoBold'}
                            fontSize={24}
                            textColor={'secondary'}
                        >
                            {t('QUOTAS.addCredit')}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            color="#6D6F71"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent>
                <Grid container item spacing={1} xs={12}>
                    {collaboratorAlreadyHasSolicitation ? (
                        <Grid item xs={12}>
                            <NotificationTip
                                text={t('QUOTAS.collaboratorWithSolicitation')}
                                fontSize={15}
                                iconColor={'primary'}
                                textColor={'primary'}
                            />
                        </Grid>
                    ) : null}

                    <Grid item xs={12}>
                        <Input
                            required
                            label={'GENERAL.value'}
                            value={`R$ ${values.value}`}
                            valueName={'value'}
                            handleChange={handleChange}
                            maxLength={16}
                            error={requiredValue}
                            helperText={
                                requiredValue && t('GENERAL.requiredField')
                            }
                            setRequired={setRequiredValue}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Input
                            required
                            isSelect
                            label={'GENERAL.type'}
                            value={values.source}
                            valueName={'source'}
                            handleChange={handleChange}
                            arraySelected={sourceArray}
                        />
                    </Grid>

                    {values.source === QuotaTransactionSources.USER ? (
                        <Grid item xs={12}>
                            <div style={{ margin: '8px 0' }}>
                                <InputAutoComplete
                                    required
                                    label={'GENERAL.selectCollaborator'}
                                    value={values.collaborator}
                                    valueName={'collaborator'}
                                    handleChange={handleChange}
                                    arraySelected={collaboratorsArray}
                                    noOptionsText={
                                        'GENERAL.noOptionsCollaborator'
                                    }
                                    error={requiredCollaborator}
                                    setError={setRequiredCollaborator}
                                    helperText={'GENERAL.requiredField'}
                                />
                            </div>
                        </Grid>
                    ) : null}

                    {values.source === QuotaTransactionSources.EXTERNAL ? (
                        <Grid item xs={12}>
                            <Input
                                required
                                placeholder={t(
                                    'QUOTAS.externalDonatePlaceholder'
                                )}
                                label={'GENERAL.externalCollaboratorName'}
                                value={values.externalContributorUser}
                                valueName={'externalContributorUser'}
                                maxLength={50}
                                handleChange={handleChange}
                                error={requiredExternal}
                                helperText={
                                    requiredExternal &&
                                    t('GENERAL.requiredField')
                                }
                                setRequired={setRequiredExternal}
                            />
                        </Grid>
                    ) : null}

                    <Grid item xs={12}>
                        <InputDate
                            required
                            label={'GENERAL.dateOfPay'}
                            value={values.date}
                            valueName={'date'}
                            handleChange={handleChange}
                            maxDate={new Date().setDate(new Date().getDate())}
                            error={requiredDate}
                            helperText={requiredDate && dateErrorMessage}
                            setRequired={setRequiredDate}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <InputFile
                            context={'new'}
                            file={file}
                            setFile={setFile}
                            label={t('GENERAL.receipt')}
                            placeholder={t('GENERAL.attachmentReceipt')}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Input
                            placeholder={t('LIBRARY.observationPlaceholder')}
                            label={'GENERAL.observation'}
                            value={values.observation}
                            valueName={'observation'}
                            rows={3}
                            maxLength={300}
                            handleChange={handleChange}
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions style={{ padding: '30px' }}>
                <Buttons
                    onClick={handleClose}
                    tipo="BN-noback"
                    conteudo={t('GENERAL.cancel').toUpperCase()}
                />
                <Buttons
                    isLoading={mutation.isLoading}
                    onClick={handleSubmit}
                    tipo="BN-blue"
                    conteudo={t('GENERAL.add').toUpperCase()}
                />
            </DialogActions>
        </Dialog>
    );
};

export default DialogAddCredit;
