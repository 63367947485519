import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import Delete from "@material-ui/icons/Delete";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { useStyles } from "components/layout/CommonStyles";
import ProjectsContext from "context/projects/projectsContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const tooltipTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#2c3e51"
    }
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: "white",
        fontSize: "1em",
        backgroundColor: "red",
        opacity: "0.7"
      }
    }
  }
});

const ScopeStage = props => {
  const classes = useStyles();
  const {
    disabled,
    lastActivity,
    deleteActivity,
    activityDescription,
    startDate,
    endDate
  } = props;
  const [description, setDescription] = useState(activityDescription);
  const [start, setStart] = useState(startDate);
  const [end, setEnd] = useState(endDate);
  const [t] = useTranslation();
  const requiredField = "GENERAL.requiredField";
  const projectsContext = useContext(ProjectsContext);
  const {
    showRequiredFieldMessage,
    setValueRequiredMessage
  } = projectsContext;

  useEffect(() => {
    setDescription(activityDescription);
  }, [activityDescription]);

  useEffect(() => {
    var endValue = new Date(endDate);
    endValue.setMinutes(endValue.getMinutes() + endValue.getTimezoneOffset())
    setEnd(endValue);
  }, [endDate]);

  useEffect(() => {
    var startValue = new Date(startDate);
    startValue.setMinutes(startValue.getMinutes() + startValue.getTimezoneOffset())
    setStart(startValue);
  }, [startDate]);

  const handleChangeDescription = event => {
    setDescription(event.target.value);
  };

  const handleDateChange = name => value => {
    switch (name) {
      case "startDate":
        setStart(value);
        break;
      case "endDate":
        setEnd(value);
        break;
      default:
        break;
    }
  };

  const handleClick = () => {
    deleteActivity(lastActivity);
  };

  const clearRiqueredMessage = () => {
    setValueRequiredMessage(false);
  };

  return (
    <div className={classes.bodyForm}>
      <Grid container spacing={2}>
        <Grid item xs={1}>
          <TextField
            disabled
            className={classes.textField}
            value={lastActivity}
            margin="normal"
          />
        </Grid>
        <Grid item xs={4}>
          <MuiThemeProvider theme={tooltipTheme}>
            <Tooltip
              TransitionComponent={Zoom}
              open={description.length === 0 && showRequiredFieldMessage}
              title={t(requiredField)}
            >
              <TextField
                id={"descActivity" + lastActivity}
                className={classes.textField}
                disabled={disabled}
                value={description}
                margin="normal"
                onChange={handleChangeDescription}
                onFocus={clearRiqueredMessage}
              />
            </Tooltip>
          </MuiThemeProvider>
        </Grid>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item xs={3}>
            <MuiThemeProvider theme={tooltipTheme}>
              <Tooltip
                TransitionComponent={Zoom}
                open={!start && showRequiredFieldMessage}
                title={t(requiredField)}
              >
                <KeyboardDatePicker
                  className={classes.textField}
                  disableToolbar
                  disabled={disabled}
                  minDate={start}
                  required
                  autoOk={true}
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  value={start}
                  id={"startDate" + lastActivity}
                  onChange={handleDateChange("startDate")}
                  onFocus={clearRiqueredMessage}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </Tooltip>
            </MuiThemeProvider>
          </Grid>
          <Grid item xs={3}>
            <MuiThemeProvider theme={tooltipTheme}>
              <Tooltip
                TransitionComponent={Zoom}
                open={!end && showRequiredFieldMessage}
                title={t(requiredField)}
              >
                <KeyboardDatePicker
                  id={"endId" + lastActivity}
                  className={classes.textField}
                  disableToolbar
                  disabled={disabled}
                  required
                  autoOk={true}
                  minDate={start}
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  value={end}
                  minDateMessage={"A data não deve ser anterior à data mínima"}
                  id={"endDate" + lastActivity}
                  onChange={handleDateChange("endDate")}
                  onFocus={clearRiqueredMessage}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </Tooltip>
            </MuiThemeProvider>
          </Grid>
        </MuiPickersUtilsProvider>
        <Grid item xs={1} style={{ marginTop: "8px" }}>
          <Tooltip title="Deletar" aria-label="delete" placement="top">
            <IconButton
              onClick={handleClick}
              style={{ float: "right" }}
              className={classes.icons}
              disabled={disabled}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </Grid>
        <div className="lineBreak"></div>
      </Grid>
    </div>
  );
};

export default ScopeStage;
