/* eslint-disable react-hooks/exhaustive-deps */
import Paper from '@material-ui/core/Paper';
import HeaderContainer from 'components/containers/HeaderContainer';
import Breadcrumb from 'components/layout/breadcrumb/Breadcrumb';
import { useStyles } from 'components/layout/CommonStyles';
import Footer from 'components/layout/Footer';
import Transition from 'components/layout/transition/Transition';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { screenStyles } from './Styles';
import ContentContainer from 'components/containers/ContentContainer';
import Typography from 'components/typography/Typography';
import ErrorIcon from '@material-ui/icons/Error';
import Input from 'components/inputs/Input';
import Grid from '@material-ui/core/Grid';
import Buttons from 'components/buttons/Pressed';
import DialogActions from '@material-ui/core/DialogActions';
import ConfigContext from 'context/libraryModule/settings/settingsContext';
import { Link } from 'react-router-dom';
import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';
import { Screen, Allow } from 'global/constants';
import { moneyMask } from 'utils/masks/moneyMask';
import { moneyMaskToFloat } from 'utils/general/moneyMaskToFloat';
import InputAdornment from '@material-ui/core/InputAdornment';
import { valueToMoneyFloat } from 'utils/general/valueToMoneyFloat';

const LibraryConfiguration = props => {
    const context = GetGeneralPermissionForAction(
        Screen.LIBRARY_CONFIGURATION,
        Allow.EDIT
    )
        ? 'edit'
        : 'view';

    const [t] = useTranslation();
    const styles = screenStyles();
    const classes = useStyles();
    const configContext = useContext(ConfigContext);

    const updateData = async () => {
        if (
            values.tolerantDays.length > 0 &&
            values.borrowPeriod.length > 0 &&
            values.maxRenovation.length > 0 &&
            values.maxBooksPerCollaborator.length > 0
        ) {
            values.finePerDay = moneyMaskToFloat(values.finePerDay).toFixed(2);
            await editConfig(values, fetchData);
            setDisabled(true);
        }
    };

    const handleSubmit = () => {
        if (values.tolerantDays.length === 0) {
            setRequiredTolerantDays(true);
        }
        if (values.borrowPeriod.length === 0) {
            setRequiredBorrowPeriod(true);
        }
        if (values.maxRenovation.length === 0) {
            setRequiredMaxRenovation(true);
        }
        if (values.maxBooksPerCollaborator.length === 0) {
            setRequiredMaxBooksPerCollaborator(true);
        }
        updateData();
    };

    const handleChange = (name, value) => {
        let val = value;
        setValues({ ...values, [name]: val });
        setDisabled(false);
    };

    const handleChangeMoney = (name, value) => {
        setValues({
            ...values,
            [name]: moneyMask(value)
        });
        setDisabled(false);
    };

    const [requiredTolerantDays, setRequiredTolerantDays] = useState(false);
    const [requiredBorrowPeriod, setRequiredBorrowPeriod] = useState(false);
    const [requiredMaxRenovation, setRequiredMaxRenovation] = useState(false);
    const [
        requiredMaxBooksPerCollaborator,
        setRequiredMaxBooksPerCollaborator
    ] = useState(false);
    const [disabled, setDisabled] = useState(true);

    const [values, setValues] = useState({
        tolerantDays: '',
        borrowPeriod: '',
        finePerDay: '0,00',
        maxRenovation: '',
        maxBooksPerCollaborator: ''
    });

    const {
        loading,
        setLoading,
        setLoadingFalse,
        getConfig,
        config,
        editConfig
    } = configContext;

    async function fetchData() {
        await setLoading();
        await getConfig();
        await setLoadingFalse();
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (config && config.length > 0) {
            setValues({
                tolerantDays: config.find(e => e.name === 'tolerantDays').value,
                borrowPeriod: config.find(e => e.name === 'borrowPeriod').value,
                finePerDay: moneyMask(
                    valueToMoneyFloat(
                        config.find(e => e.name === 'finePerDay').value
                    )
                ),
                maxRenovation: config.find(e => e.name === 'maxRenovation')
                    .value,
                maxBooksPerCollaborator: config.find(
                    e => e.name === 'maxBooksPerCollaborator'
                ).value
            });
        }
    }, [config]);

    return (
        <React.Fragment>
            <Transition loading={loading} newDesign={true}>
                <HeaderContainer>
                    <Breadcrumb
                        newDesign={true}
                        routes={[
                            { label: t('INVENTORY.library'), to: '/Library' },
                            { label: t('LIBRARY.configuration') }
                        ]}
                    />
                    <div className="container-headerNewDesign">
                        <h1 className={classes.titleNewDesign}>
                            {t('LIBRARY.configuration')}
                        </h1>
                    </div>
                </HeaderContainer>

                <ContentContainer>
                    <Paper className={styles.Container}>
                        <div className="cabecalho">
                            {t('LIBRARY.configuration').toUpperCase()}
                        </div>

                        <div className={styles.TextWrapper}>
                            <Typography
                                variant={'RobotoRegular'}
                                fontSize={14}
                                textColor={'secondary'}
                            >
                                {context === 'edit'
                                    ? t('LIBRARY.configurationDescriptionEdit')
                                    : t('LIBRARY.configurationDescriptionView')}
                            </Typography>
                            {context === 'edit' && (
                                <div className={styles.TextWarning}>
                                    <ErrorIcon
                                        style={{
                                            width: '20',
                                            height: '20',
                                            fill: '#6D6F71',
                                            marginRight: '10px'
                                        }}
                                    />
                                    <Typography
                                        variant={'RobotoRegular'}
                                        fontSize={14}
                                        textColor={'secondary'}
                                    >
                                        {t('LIBRARY.updateWarning')}
                                    </Typography>
                                </div>
                            )}
                        </div>

                        <Grid
                            className={styles.InputsContainer}
                            container
                            item
                            spacing={2}
                            md={12}
                            lg={12}
                        >
                            <Grid item xs={12} sm={6}>
                                <Input
                                    required
                                    type={'number'}
                                    context={context}
                                    label={'LIBRARY.tolerantDays'}
                                    value={values.tolerantDays}
                                    valueName={'tolerantDays'}
                                    handleChange={handleChange}
                                    maxLength={3}
                                    error={requiredTolerantDays}
                                    helperText={
                                        requiredTolerantDays &&
                                        t('GENERAL.requiredField')
                                    }
                                    setRequired={setRequiredTolerantDays}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Input
                                    required
                                    type={'number'}
                                    context={context}
                                    label={'LIBRARY.borrowPeriod'}
                                    value={values.borrowPeriod}
                                    valueName={'borrowPeriod'}
                                    handleChange={handleChange}
                                    maxLength={3}
                                    error={requiredBorrowPeriod}
                                    helperText={
                                        requiredBorrowPeriod &&
                                        t('GENERAL.requiredField')
                                    }
                                    setRequired={setRequiredBorrowPeriod}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Input
                                    required
                                    context={context}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                R$
                                            </InputAdornment>
                                        )
                                    }}
                                    maxLength={6}
                                    label={'LIBRARY.finePerDay'}
                                    value={values.finePerDay}
                                    valueName={'finePerDay'}
                                    handleChange={handleChangeMoney}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Input
                                    required
                                    type={'number'}
                                    context={context}
                                    label={'LIBRARY.maxRenovation'}
                                    value={values.maxRenovation}
                                    valueName={'maxRenovation'}
                                    handleChange={handleChange}
                                    maxLength={3}
                                    error={requiredMaxRenovation}
                                    helperText={
                                        requiredMaxRenovation &&
                                        t('GENERAL.requiredField')
                                    }
                                    setRequired={setRequiredMaxRenovation}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Input
                                    required
                                    type={'number'}
                                    context={context}
                                    label={'LIBRARY.maxBooksPerCollaborator'}
                                    value={values.maxBooksPerCollaborator}
                                    valueName={'maxBooksPerCollaborator'}
                                    handleChange={handleChange}
                                    maxLength={3}
                                    error={requiredMaxBooksPerCollaborator}
                                    helperText={
                                        requiredMaxBooksPerCollaborator &&
                                        t('GENERAL.requiredField')
                                    }
                                    setRequired={
                                        setRequiredMaxBooksPerCollaborator
                                    }
                                />
                            </Grid>
                        </Grid>

                        <DialogActions className={styles.ButtonsWrapper}>
                            <Link to="/Library">
                                <Buttons
                                    style={{
                                        padding: '8px 16px',
                                        borderRadius: '0',
                                        marginRight: '8px'
                                    }}
                                    tipo="BN-gray"
                                    conteudo={t(
                                        'GENERAL.comeBack'
                                    ).toUpperCase()}
                                />
                            </Link>
                            {context === 'edit' && (
                                <Buttons
                                    style={{
                                        padding: '8px 16px',
                                        borderRadius: '0'
                                    }}
                                    tipo="BN-blue"
                                    conteudo={t('GENERAL.save').toUpperCase()}
                                    disabled={disabled}
                                    onClick={handleSubmit}
                                />
                            )}
                        </DialogActions>
                    </Paper>
                </ContentContainer>
                <Footer />
            </Transition>
        </React.Fragment>
    );
};

export default LibraryConfiguration;
