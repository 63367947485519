import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from '@material-ui/icons/Close';
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import Buttons from "components/buttons/Pressed";
import Link from "react-router-dom/Link";
import TextField from '@material-ui/core/TextField';
import DialogActivityCategories from "./DialogActivityCategories";
import SettingsContext from "context/hrModule/settings/settingsContext";
import { useStyles } from "components/layout/CommonStyles";
import { GetGeneralPermissionForAction } from "utils/permissions/GetGeneralPermissionForAction";
import { Screen, Allow } from "global/constants";


const ActivityCategoriesTableActions = props => {
    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }
    const classes = useStyles();
    const [t] = useTranslation();
    const settingsContext = useContext(SettingsContext);
    const [openDialog, setOpenDialog] = useState(false);
    const [context, setContext] = useState("");
    const [openDelete, setOpenDelete] = useState(false);
    const [changeContext, setchangeContex] = useState(0);
    const [password, setPassword] = useState("");
    const [passRight, setPassRight] = useState(false);
    const { item, fetchData, activityCategories } = props;
    const [justification, setJustification] = useState("");
    const [validJustification, setValidJustification] = useState(false);
    const blankField = "GENERAL.requiredField";
    const [validPasswordrequiredField, setPasswordRequiredField] = useState(false);

    const {
        deleteActivityCategories
    } = settingsContext

    const handleOpenDialog = (context) => {
        if (context === "view"){
            setContext("view");
        }else{
            setContext("edit");
        }
        setOpenDialog(true);
    }

    const handleOpenDelete = () => {
        setOpenDelete(true);
    };
    const handleCloseDelete = () => {
        setValidJustification(false);
        setPasswordRequiredField(false);
        setPassRight(false);
        setchangeContex(0);
        setJustification("");
        setPassword("");
        setOpenDelete(false);
    };

    var pass = true;

    const handleDelete = () => {

        if (justification.trim().length > 0) setchangeContex(changeContext + 1)
        else setValidJustification(true)
        if (changeContext > 0) {
            if (password.length > 0) sendRequest();
            else setPasswordRequiredField(true);
            // if (pass) setPassword("")
            // else setOpenDelete(false);
        }
    };

    function sendRequest() {
        const requestInfo = {
            method: "POST",
            body: JSON.stringify({
                email: auth.userName,
                password: password
            }),
            headers: new Headers({
                "Content-type": "application/json"
            })
        };


        fetch(process.env.REACT_APP_SERVER + "api/auth/login", requestInfo)
            .then(response => {
                if (response.ok) {
                    setPassRight(false);
                    let formData = new FormData();
                    formData.append('justification', justification)
                    deleteActivityCategories(item.id, formData, fetchData, handleCloseDelete);
                    return false;
                } else {
                    setPassRight(true);
                    return true;
                }
            })
            .catch(error => {
                setPassRight(true);
                return true;
            });
    }

    return (
        <React.Fragment>
            {GetGeneralPermissionForAction(Screen.ACTIVITY_CATEGORIES, Allow.READ) &&
                <Tooltip title={t("GENERAL.view")} aria-label="details" placement="top">
                        <IconButton className={classes.iconsTableActions} onClick={() => handleOpenDialog("view")}>
                            <Visibility />
                        </IconButton>
                </Tooltip>
            }
            {GetGeneralPermissionForAction(Screen.ACTIVITY_CATEGORIES, Allow.EDIT) && <Tooltip title={t("GENERAL.edit")} aria-label="edit" placement="top">
                    <IconButton className={classes.iconsTableActions} onClick={() => handleOpenDialog("edit")} >
                        <Edit />
                    </IconButton>
            </Tooltip>}
            {GetGeneralPermissionForAction(Screen.ACTIVITY_CATEGORIES, Allow.DELETE) && <Tooltip title={t("GENERAL.delete")} aria-label="delete" placement="top">
                <IconButton className={classes.iconsTableActions} onClick={handleOpenDelete}>
                    <Delete />
                </IconButton>
            </Tooltip>}
            <Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="form-dialog-title"
                msg="mensagem"
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle className={classes.Title}>
                    <b>{t("GENERAL.delete") + " " + t("HR.activityCategory")}</b>
                    <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleCloseDelete} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {changeContext === 0 ?
                        <DialogContentText>
                            <p>{t("HR.deleteActivityCategoryMessage")}</p>
                            <Grid container item spacing={0} md={12} lg={12}>
                                <Grid item xs={12} sm={12}>
                                    <b>{t("GENERAL.categoryName").toUpperCase()}</b>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    {item.name}
                                </Grid>
                            </Grid>
                            <TextField
                                error={validJustification}
                                multiline
                                fullWidth
                                variant="outlined"
                                rows="3"
                                value={justification}
                                label={t("INVENTORY.justification") + "*"}
                                margin="normal"
                                inputProps={{ maxLength: 100 }}
                                helperText={validJustification ? t(blankField) : `${justification.length}/100`}
                                onChange={(e) => {
                                    setValidJustification(false);
                                    setJustification(e.target.value)
                                }}
                            />
                        </DialogContentText>
                        :
                        <DialogContentText>
                            <p>{t("HR.deleteActivityCategoryPasswordMessage")}</p>
                            <p><b>{item.name}</b></p>
                            <p>{t("INVENTORY.deleteActionCannotBeUndone")}</p>
                            <TextField
                                error={validPasswordrequiredField || passRight}
                                label={t("INVENTORY.password") + "*"}
                                value={password}
                                onChange={(e) => {
                                    setPasswordRequiredField(false)
                                    setPassRight(false);
                                    setPassword(e.target.value)
                                }}
                                type="password"
                                variant="filled"
                                helperText={validPasswordrequiredField ? t(blankField) : passRight ? "Senha inválida." : ""}
                                style={{ width: "90%", marginLeft: 30, marginRight: 30 }}
                            />
                        </DialogContentText>
                    }
                </DialogContent>
                <DialogActions>
                    <Buttons
                        onClick={handleCloseDelete}
                        tipo="BN-noback"
                        conteudo={t("GENERAL.cancel").toUpperCase()}
                    />
                    <Buttons
                        onClick={handleDelete}
                        tipo="BN-blue"
                        conteudo={changeContext === 0 ? t("GENERAL.next").toUpperCase() : t("GENERAL.delete").toUpperCase()}
                    />
                </DialogActions>
            </Dialog>
            {openDialog &&
                <DialogActivityCategories
                    open={openDialog}
                    setOpen={setOpenDialog}
                    context={context}
                    viewValues={item}
                    fetchData={fetchData}
                    activityCategories={activityCategories}
                />
            }
        </React.Fragment>
    )
};

export default ActivityCategoriesTableActions;