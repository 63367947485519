import axios from 'axios';
import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT, HR } from '../../types';
import settingsContext from './settingsContext';
import settingsReducer from './settingsReducer';
import history from '../../../history';
import { duration } from 'moment';
import { Redirect } from 'react-router';
import NotFound from 'components/404';
import Cookies from 'js-cookie';
import { headers } from 'global/variables';

const SettingsState = props => {
    const initialState = {
        message: '',
        response: '',
        responseType: '',
        hasChanged: 0,
        successiveActions: 0,
        loading: false,
        job: {},
        jobs: [],
        holidaysAndBreaks: [],
        holidayOrBreak: {},
        roleModules: [],
        profile: null,
        allowanceReason: {},
        allowanceReasons: [],
        activityCategories: []
    };

    const [t] = useTranslation();
    const [state, dispatch] = useReducer(settingsReducer, initialState);
    const setLoading = () => dispatch({ type: DEFAULT.SET_LOADING });
    const setLoadingFalse = () => dispatch({ type: DEFAULT.SET_LOADING_FALSE });

    const resetMessage = () => dispatch({ type: DEFAULT.RESET_MESSAGE });
    const setHasChanged = () => dispatch({ type: DEFAULT.SET_HAS_CHANGED });

    const getJobs = async () => {
        const res = await axios.get(process.env.REACT_APP_SERVER + `api/job`, {
            headers: {
                'Content-type': 'application/json',
                Authorization:
                    'bearer ' +
                    (Cookies.get('auth-token') ? Cookies.get('auth-token') : '')
            }
        });
        dispatch({
            type: HR.GET_ADMISSION_JOBS,
            payload: res.data
        });
    };

    const getJob = async id => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/job/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: HR.GET_JOB,
            payload: res.data
        });
    };

    const addJob = async (obj, fetchData) => {
        axios
            .post(process.env.REACT_APP_SERVER + 'api/job', obj, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: HR.CREATE_JOB,
                        payload: t('HR.jobAdded'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: HR.CREATE_JOB,
                        payload: t('HR.jobAddedError'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: HR.CREATE_JOB,
                    payload: t('HR.jobAddedError'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                fetchData();
            });
    };

    const updateJob = (obj, mustRedirect) => {
        axios
            .put(process.env.REACT_APP_SERVER + 'api/job', obj, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: HR.UPDATE_JOB,
                        payload: t('HR.jobUpdated'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: HR.UPDATE_JOB,
                        payload: t('HR.jobUpdatedError'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: HR.UPDATE_JOB,
                    payload: t('HR.jobUpdatedError'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                mustRedirect(true);
            });
    };

    const deleteJob = (id, formData, fetchData, handleDelete) => {
        let obj = formData;
        axios
            .put(process.env.REACT_APP_SERVER + `api/job/disable/${id}`, obj, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(res => {
                dispatch({
                    type: HR.DELETE_JOB,
                    payload: t('HR.jobDeleted'),
                    responseType: 'SUCCESS',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .catch(error => {
                dispatch({
                    type: HR.DELETE_JOB,
                    payload:
                        error.response.status === 404
                            ? error.response.data === 'NotFound'
                                ? t('HR.jobNotFound')
                                : error.response.data === 'Active'
                                ? t('HR.jobWithActiveCollaborators')
                                : t('HR.jobWithInactiveCollaborators')
                            : t('HR.jobDeleteError'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                handleDelete();
                fetchData();
            });
    };

    const getHolidaysAndBreaks = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/holidaysAndBreaks`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: HR.GET_HOLIDAYS_AND_BREAKS,
            payload: res.data
        });
    };

    const getHolidayOrBreak = async id => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/holidaysAndBreaks/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: HR.GET_HOLIDAY_OR_BREAK,
            payload: res.data
        });
    };

    const addHolidaysAndBreaks = async (obj, setMustRedirect) => {
        axios
            .post(process.env.REACT_APP_SERVER + 'api/holidaysAndBreaks', obj, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: HR.CREATE_HOLIDAYS_AND_BREAKS,
                        payload:
                            response.data === 'HOLIDAY'
                                ? t('HR.holidaySuccess')
                                : t('HR.breakSuccess'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: HR.CREATE_HOLIDAYS_AND_BREAKS,
                        payload: t('HR.holidayAndBreakError'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: HR.CREATE_HOLIDAYS_AND_BREAKS,
                    payload: t('HR.holidayAndBreakError'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                setTimeout(() => {
                    setMustRedirect(true);
                }, 500);
            });
    };

    const updateHolidaysAndBreaks = (obj, mustRedirect) => {
        axios
            .put(process.env.REACT_APP_SERVER + 'api/holidaysAndBreaks', obj, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: HR.UPDATE_HOLIDAY_OR_BREAK,
                        payload:
                            response.data === 'HOLIDAY'
                                ? t('HR.holidayUpdated')
                                : t('HR.breakUpdated'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: HR.UPDATE_HOLIDAY_OR_BREAK,
                        payload: t('HR.HolidayOrBreakUpdateError'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: HR.UPDATE_HOLIDAY_OR_BREAK,
                    payload: t('HR.HolidayOrBreakUpdateError'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                mustRedirect(true);
            });
    };

    const deleteHolidaysAndBreaks = (id, formData, fetchData, handleDelete) => {
        let obj = formData;
        axios
            .put(
                process.env.REACT_APP_SERVER +
                    `api/holidaysAndBreaks/disable/${id}`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(res => {
                dispatch({
                    type: HR.DELETE_HOLIDAYS_AND_BREAKS,
                    payload:
                        res.data === 'HOLIDAY'
                            ? t('HR.holidayDeleted')
                            : t('HR.breakDeleted'),
                    responseType: 'SUCCESS',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .catch(error => {
                dispatch({
                    type: HR.DELETE_HOLIDAYS_AND_BREAKS,
                    payload:
                        error.response.status === 404
                            ? error.response.data === 'HOLIDAY'
                                ? t('HR.holidayNotFound')
                                : t('HR.breakNotFound')
                            : t('HR.jobDeleteError'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                handleDelete();
                fetchData();
            });
    };
    const addAllowanceReason = (obj, setMustRedirect) => {
        axios
            .post(process.env.REACT_APP_SERVER + 'api/AllowanceReason', obj, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(response => {
                var res = response.data;
                if (response.status === 201) {
                    dispatch({
                        type: DEFAULT.CREATE_UPDATE_DELETE,
                        payload: t('HR.allowanceReasonAdded'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else if (response.status === 304) {
                    dispatch({
                        type: DEFAULT.CREATE_UPDATE_DELETE,
                        payload: t('HR.allowanceReasonAlreadyExists'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: DEFAULT.CREATE_UPDATE_DELETE,
                        payload: t('HR.allowanceReasonError1'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: DEFAULT.CREATE_UPDATE_DELETE,
                    payload:
                        error.response.status === 304
                            ? t('HR.allowanceReasonAlreadyExists')
                            : t('HR.allowanceReasonError'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => setMustRedirect(true));
    };

    const getAllAllowanceReasons = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/AllowanceReason`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: HR.GET_ALL_ALLOWANCE_REASONS,
            payload: res.data
        });
    };

    const getAllowanceReason = async (id, mustRedirect) => {
        axios
            .get(process.env.REACT_APP_SERVER + `api/AllowanceReason/${id}`, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(response => {
                dispatch({
                    type: HR.GET_ALLOWANCE_REASON,
                    payload: response.data
                });
            })
            .catch(error => {
                dispatch({
                    type: HR.GET_ALLOWANCE_REASON,
                    payload:
                        error.response.status === 404 &&
                        error.response.data === 'NotFound'
                            ? t('HR.allowanceReasonNotFound')
                            : t('HR.allowanceReasonGetError'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
                mustRedirect(true);
            });
    };

    const updateAllowanceReason = (obj, mustRedirect) => {
        axios
            .put(process.env.REACT_APP_SERVER + 'api/AllowanceReason', obj, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: HR.UPDATE_ALLOWANCE_REASON,
                        payload: t('HR.allowanceReasonUpdated'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else if (response.status === 304) {
                    dispatch({
                        type: HR.UPDATE_ALLOWANCE_REASON,
                        payload: t('HR.allowanceReasonAlreadyExists'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: HR.UPDATE_ALLOWANCE_REASON,
                        payload: t('HR.allowanceReasonUpdateError'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                if (error.response.status === 304) {
                    dispatch({
                        type: HR.UPDATE_ALLOWANCE_REASON,
                        payload: t('HR.allowanceReasonAlreadyExists'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: HR.UPDATE_ALLOWANCE_REASON,
                        payload: t('HR.allowanceReasonUpdateError'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .finally(() => {
                mustRedirect(true);
            });
    };

    const deleteAllowanceReason = (id, formData, fetchData, handleDelete) => {
        let obj = formData;
        axios
            .put(
                process.env.REACT_APP_SERVER +
                    `api/AllowanceReason/disable/${id}`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(res => {
                dispatch({
                    type: HR.DELETE_ALLOWANCE_REASON,
                    payload: t('HR.allowanceReasonDeleted'),
                    responseType: 'SUCCESS',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .catch(error => {
                dispatch({
                    type: HR.DELETE_ALLOWANCE_REASON,
                    payload:
                        error.response.status === 404 &&
                        error.response.data === 'NotFound'
                            ? t('HR.allowanceReasonNotFound')
                            : t('HR.allowanceReasonDeleteError'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                handleDelete();
                fetchData();
            });
    };

    const addProfile = async (obj, setMustRedirect) => {
        axios
            .post(process.env.REACT_APP_SERVER + 'api/ProfileManagement', obj, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: HR.CREATE_PROFILE,
                        payload: t('HR.profileAdded'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: HR.CREATE_PROFILE,
                        payload: t('HR.profileAddedError'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: HR.CREATE_PROFILE,
                    payload: t('HR.profileAddedError'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                setMustRedirect(true);
            });
    };

    const getAllRoleModules = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER +
                `api/ProfileManagement/AllRoleModules`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: HR.GET_ROLE_MODULES,
            payload: res.data
        });
    };

    const getProfile = async id => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/ProfileManagement/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: HR.GET_PROFILE,
            payload: res.data
        });
    };

    const updateProfile = (obj, mustRedirect) => {
        axios
            .put(process.env.REACT_APP_SERVER + 'api/ProfileManagement', obj, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: HR.UPDATE_PROFILE,
                        payload: t('HR.profileUpdated'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: HR.UPDATE_PROFILE,
                        payload: t('HR.profileUpdatedError'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: HR.UPDATE_PROFILE,
                    payload: t('HR.profileUpdatedError'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                mustRedirect(true);
            });
    };

    const deleteProfileManagement = (id, formData, fetchData, handleDelete) => {
        let obj = formData;
        axios
            .put(
                process.env.REACT_APP_SERVER +
                    `api/ProfileManagement/disable/${id}`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(res => {
                dispatch({
                    type: HR.DELETE_PROFILE_MANAGEMENT,
                    payload: t('HR.profileDeleted'),
                    responseType: 'SUCCESS',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .catch(error => {
                dispatch({
                    type: HR.DELETE_PROFILE_MANAGEMENT,
                    payload:
                        error.response.status === 404
                            ? error.response.data === 'NotFound'
                                ? t('HR.profileNotFound')
                                : t(
                                      'HR.profileAssociatedWithActiveCollaborator'
                                  )
                            : t('HR.profileDeleteError'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                handleDelete();
                fetchData();
            });
    };

    const getAllActivityCategories = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/ActivityCategories`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: HR.GET_ALL_ACTIVITY_CATEGORIES,
            payload: res.data
        });
    };

    const addActivityCategories = async (obj, fetchData) => {
        axios
            .post(
                process.env.REACT_APP_SERVER + 'api/ActivityCategories',
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: HR.CREATE_ACTIVITY_CATEGORIES,
                        payload: t('HR.activityCategoriesAdded'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: HR.CREATE_ACTIVITY_CATEGORIES,
                        payload: t('HR.activityCategoriesAddedError'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: HR.CREATE_ACTIVITY_CATEGORIES,
                    payload: t('HR.activityCategoriesAddedError'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                fetchData();
            });
    };

    const updateActivityCategories = (obj, fetchData) => {
        axios
            .put(process.env.REACT_APP_SERVER + 'api/ActivityCategories', obj, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: HR.UPDATE_ACTIVITY_CATEGORIES,
                        payload: t('HR.activityCategoriesUpdated'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: HR.UPDATE_ACTIVITY_CATEGORIES,
                        payload: t('HR.activityCategoriesUpdatedError'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: HR.UPDATE_ACTIVITY_CATEGORIES,
                    payload: t('HR.activityCategoriesUpdatedError'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                fetchData();
            });
    };

    const deleteActivityCategories = (
        id,
        formData,
        fetchData,
        handleDelete
    ) => {
        let obj = formData;
        axios
            .put(
                process.env.REACT_APP_SERVER +
                    `api/ActivityCategories/disable/${id}`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(res => {
                dispatch({
                    type: HR.DELETE_ACTIVITY_CATEGORIES,
                    payload: t('HR.activityCategoriesDeleted'),
                    responseType: 'SUCCESS',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .catch(error => {
                dispatch({
                    type: HR.DELETE_ACTIVITY_CATEGORIES,
                    payload:
                        error.response.status === 404
                            ? t('HR.activityCategoriesNotFound')
                            : t('HR.activityCategoriesDeletedError'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                handleDelete();
                fetchData();
            });
    };

    return (
        <settingsContext.Provider
            value={{
                loading: state.loading,
                message: state.message,
                hasChanged: state.hasChanged,
                response: state.response,
                responseType: state.responseType,
                successiveActions: state.successiveActions,
                jobs: state.jobs,
                job: state.job,
                holidaysAndBreaks: state.holidaysAndBreaks,
                holidayOrBreak: state.holidayOrBreak,
                roleModules: state.roleModules,
                profile: state.profile,
                allowanceReason: state.allowanceReason,
                allowanceReasons: state.allowanceReasons,
                activityCategories: state.activityCategories,
                setLoading,
                setLoadingFalse,
                resetMessage,
                setHasChanged,
                getJobs,
                getJob,
                addJob,
                updateJob,
                deleteJob,
                getHolidaysAndBreaks,
                getHolidayOrBreak,
                addHolidaysAndBreaks,
                updateHolidaysAndBreaks,
                deleteHolidaysAndBreaks,
                addProfile,
                getAllRoleModules,
                getProfile,
                updateProfile,
                addAllowanceReason,
                getAllowanceReason,
                getAllAllowanceReasons,
                deleteAllowanceReason,
                updateAllowanceReason,
                deleteProfileManagement,
                getAllActivityCategories,
                addActivityCategories,
                updateActivityCategories,
                deleteActivityCategories
            }}
        >
            {props.children}
        </settingsContext.Provider>
    );
};

export default SettingsState;
