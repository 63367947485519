import { makeStyles } from "@material-ui/core/styles";

export const snackbarStyles = makeStyles(theme => ({
    filterContainer: {
        display: "flex",
        flexGrow: 1,
        flexFlow: "row wrap",
        padding: "8px 16px"
      },
    headerContainer: {
        paddingLeft: "154px", /* 104 = sidebar size */
        paddingRight: "50px",
        backgroundColor: "white"
      },
    contentContainer:  {
        marginTop: "20px",
        marginLeft: "154px", /* 104 = sidebar size */
        marginRight: "50px"
      },
      dialogContainer: {
        display: "flex",
        minWidth: "40rem",
        minHeight: "10rem",
      },
      dialogContentContainer: {
        width: "100%"
      },
  })
)  