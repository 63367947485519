import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { useStyles } from 'components/layout/CommonStyles';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import Buttons from 'components/buttons/Pressed';
import Grid from '@material-ui/core/Grid';
import Input from 'components/inputs/Input';
import Typography from 'components/typography/Typography';
import {
    GetJustificationType,
    CollaboratorsJustificationsActions,
    JustificationType
} from './ScreenUtils';
import { dateToBR } from 'utils/dates/DateToBRr';

const DialogCollaboratorsJustifications = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const { open, setOpen, values, dates, setValues, dialogContext, submit } =
        props;

    const [requiredReason, setRequiredReason] = useState(false);

    const clearDialog = () => {
        setValues({
            ...values,
            approverResponse: ''
        });
    };

    const handleClose = () => {
        clearDialog();
        setOpen(false);
    };

    const handleChange = (name, value) => {
        let val = value;
        setValues({ ...values, [name]: val });
    };

    const verifySubmit = () => {
        let res = true;

        if (!values.approverResponse.trim()) {
            res = false;
            setRequiredReason(true);
        }

        return res;
    };

    const handleSubmit = () => {
        if (dialogContext === CollaboratorsJustificationsActions.REFUSE) {
            const canSubmit = verifySubmit();
            if (canSubmit) {
                submit(CollaboratorsJustificationsActions.REFUSE);
                setOpen(false);
            }
        } else {
            submit(CollaboratorsJustificationsActions.APPROVE);
            setOpen(false);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle className={classes.Title}>
                <b>
                    {' '}
                    {`${
                        dialogContext ===
                        CollaboratorsJustificationsActions.APPROVE
                            ? t('GENERAL.approve')
                            : t('GENERAL.refuse')
                    } ${t('GENERAL.justification')}`}
                </b>
                <IconButton
                    style={{ float: 'right', marginTop: -10, marginRight: -17 }}
                    edge="end"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container item spacing={2} md={12} lg={12}>
                    <Grid item xs={12} sm={12}>
                        <Typography
                            variant={'RobotoRegular'}
                            fontSize={16}
                            textColor={'secondary'}
                        >
                            {dialogContext ===
                            CollaboratorsJustificationsActions.APPROVE
                                ? t('HR.DoYouReallyWantToApproveJustification')
                                : t('HR.DoYouReallyWantToRefuseJustification')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Typography
                            variant={'RobotoBold'}
                            fontSize={18}
                            textColor={'secondary'}
                        >
                            {t('GENERAL.requester')}
                        </Typography>
                        <Typography
                            variant={'RobotoRegular'}
                            fontSize={16}
                            textColor={'secondary'}
                        >
                            {values.requester}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Typography
                            variant={'RobotoBold'}
                            fontSize={18}
                            textColor={'secondary'}
                        >
                            {t('GENERAL.solicitationDate')}
                        </Typography>
                        <Typography
                            variant={'RobotoRegular'}
                            fontSize={16}
                            textColor={'secondary'}
                        >
                            {dateToBR(values.dateCreated)}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Typography
                            variant={'RobotoBold'}
                            fontSize={18}
                            textColor={'secondary'}
                        >
                            {t('HR.justificationType')}
                        </Typography>
                        <Typography
                            variant={'RobotoRegular'}
                            fontSize={16}
                            textColor={'secondary'}
                        >
                            {GetJustificationType(values.justificationType)}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Typography
                            variant={'RobotoBold'}
                            fontSize={18}
                            textColor={'secondary'}
                        >
                            {values.justificationType !==
                            JustificationType.TOTAL_PAYMENT
                                ? t('HR.justifiedDate')
                                : t('HR.justifiedPeriod')}
                        </Typography>
                        <Typography
                            variant={'RobotoRegular'}
                            fontSize={16}
                            textColor={'secondary'}
                        >
                            {values.justificationType !==
                            JustificationType.TOTAL_PAYMENT
                                ? dateToBR(dates.justifiedDate)
                                : `${dateToBR(dates.initialDate)} - ${dateToBR(
                                      dates.finalDate
                                  )}`}
                        </Typography>
                    </Grid>
                    {dialogContext ===
                    CollaboratorsJustificationsActions.REFUSE ? (
                        <Grid item xs={12} sm={12}>
                            <Input
                                required
                                context={'new'}
                                label={'GENERAL.reason'}
                                value={values.approverResponse}
                                valueName={'approverResponse'}
                                rows={5}
                                maxLength={200}
                                handleChange={handleChange}
                                error={requiredReason}
                                helperText={
                                    requiredReason && t('GENERAL.requiredField')
                                }
                                setRequired={setRequiredReason}
                            />
                        </Grid>
                    ) : null}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Buttons
                    onClick={handleClose}
                    tipo="BN-noback"
                    conteudo={t('GENERAL.cancel').toUpperCase()}
                />
                <Buttons
                    onClick={handleSubmit}
                    tipo="BN-blue"
                    conteudo={
                        dialogContext ===
                        CollaboratorsJustificationsActions.APPROVE
                            ? t('GENERAL.approve').toUpperCase()
                            : t('GENERAL.refuse').toUpperCase()
                    }
                />
            </DialogActions>
        </Dialog>
    );
};

export default DialogCollaboratorsJustifications;
