import Paper from '@material-ui/core/Paper';
import Dissatisfied from '@material-ui/icons/SentimentVeryDissatisfied';
import FilterContainer from 'components/containers/FilterContainer';
import InputFilter from 'components/inputs/InputFilter';
import { useStyles } from 'components/layout/CommonStyles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { screenStyles } from 'pages/libraryModule/MyLoans/Styles';
import BookCardMyLoans from 'pages/libraryModule/cards/BookCardMyLoans';
import { AllAuthorsName } from 'utils/library/AllAuthorsName';
import { LibraryReservationStatusValues } from 'global/constants';
import DialogHistoryMoreDetails from 'pages/libraryModule/dialogs/MyLoans/DialogHistoryMoreDetails';
import { Link as LinkRouter } from 'react-router-dom';
import { formatImagePath } from 'utils/library/formatImagePath';
import useWindowDimensions from 'hooks/useWindowDimensions';
import Pagination from '@material-ui/lab/Pagination';

const HistoryForm = props => {
    const { HistoryData } = props;
    const [t] = useTranslation();
    const styles = screenStyles();
    const classes = useStyles();

    const [dialogData, setDialogData] = useState({});
    const [openHistoryMoreDetailsDialog, setOpenHistoryMoreDetailsDialog] =
        useState(false);

    const [filterTitle, setFilterTitle] = useState('');
    const [filterAuthor, setFilterAuthor] = useState('');
    const [filterStatusType, setFilterStatusType] = useState('');

    const [findText, setFindText] = useState('');
    const [dataFilter, setDataFilter] = useState([]);
    const hasItens = HistoryData.length > 0;
    const [hasNoResult, setHasNoResult] = useState(false);

    const translateStatus = status => {
        switch (status) {
            case LibraryReservationStatusValues.CANCELED_MANAGEMENT:
                return 'Cancelado Gerência';
            case LibraryReservationStatusValues.CANCELED_COLLABORATOR:
                return 'Cancelado Colaborador';
            case LibraryReservationStatusValues.FINISHED:
                return 'Devolvido';
            default:
                return '';
        }
    };

    const statusTypes = [
        { label: 'Todos', value: 'Todos' },
        {
            label: translateStatus(LibraryReservationStatusValues.FINISHED),
            value: LibraryReservationStatusValues.FINISHED
        },
        {
            label: translateStatus(
                LibraryReservationStatusValues.CANCELED_MANAGEMENT
            ),
            value: LibraryReservationStatusValues.CANCELED_MANAGEMENT
        },
        {
            label: translateStatus(
                LibraryReservationStatusValues.CANCELED_COLLABORATOR
            ),
            value: LibraryReservationStatusValues.CANCELED_COLLABORATOR
        }
    ];

    const handleOpenHistoryMoreDetailsDialog = index => {
        setDialogData(dataFilter[index]);
        setOpenHistoryMoreDetailsDialog(true);
    };

    const applyFilter = filterArray => {
        let newDataFilter = [];
        let textNoFound = '';

        newDataFilter = filterArray;

        if (filterTitle) {
            newDataFilter = newDataFilter.filter(item =>
                item.book.title
                    .toLowerCase()
                    .includes(filterTitle.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterTitle;
        }

        if (filterAuthor) {
            newDataFilter = newDataFilter.filter(item =>
                AllAuthorsName(item.book.authors)
                    .toLowerCase()
                    .includes(filterAuthor.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterAuthor;
        }

        if (
            filterStatusType &&
            filterStatusType.toLowerCase() !==
                t('GENERAL.allMasculine').toLowerCase()
        ) {
            newDataFilter = newDataFilter.filter(
                item => item.status === filterStatusType
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = translateStatus(filterStatusType);
        }

        return { newDataFilter, textNoFound };
    };

    useEffect(() => {
        if (HistoryData && HistoryData.length) {
            const { newDataFilter, textNoFound } = applyFilter(HistoryData);

            if (newDataFilter.length === 0) {
                setDataFilter([]);
                setHasNoResult(true);
                setFindText(textNoFound);
            } else {
                setDataFilter(newDataFilter);
                setHasNoResult(false);
                setFindText('');
            }
        }
    }, [HistoryData, filterTitle, filterAuthor, filterStatusType]);

    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(1);
    const handleChange = (e, p) => {
        setPage(p);
    };
    const { width } = useWindowDimensions();

    const handleItemsPerPage = width => {
        switch (width) {
            case 1920:
                return 14;
            case 1600:
                return 12;
            case 1366:
                return 15;
            case 1280:
                return 12;
            default:
                return 10;
        }
    };

    useEffect(() => {
        setItemsPerPage(handleItemsPerPage(width));
    }, [width]);

    return (
        <React.Fragment>
            <FilterContainer>
                <InputFilter
                    filterValue={filterTitle}
                    setFilter={setFilterTitle}
                    label={'INVENTORY.title'}
                    maxLength={70}
                />
                <InputFilter
                    filterValue={filterAuthor}
                    setFilter={setFilterAuthor}
                    label={'INVENTORY.BookAuthor'}
                    maxLength={70}
                />
                <InputFilter
                    filterValue={filterStatusType}
                    setFilter={setFilterStatusType}
                    label={'GENERAL.status'}
                    isSelect
                    arraySelected={statusTypes}
                />
            </FilterContainer>

            <Paper style={{ maxHeight: 700, overflow: 'auto' }}>
                {dataFilter.length > 0 && (
                    <div className={styles.bookList}>
                        {dataFilter
                            .slice(
                                (page - 1) * itemsPerPage,
                                (page - 1) * itemsPerPage + itemsPerPage
                            )
                            .map((data, index) => {
                                return (
                                    <BookCardMyLoans
                                        context={'history'}
                                        image={formatImagePath(
                                            data.book.imagePath,
                                            data.book.isImageUrl
                                        )}
                                        title={data.book.title}
                                        author={AllAuthorsName(
                                            data.book.authors
                                        )}
                                        category={data.book.category.name}
                                        status={data.status}
                                        onClickMoreDetails={() => {
                                            handleOpenHistoryMoreDetailsDialog(
                                                (page - 1) * itemsPerPage +
                                                    index
                                            );
                                        }}
                                    />
                                );
                            })}
                    </div>
                )}

                {dataFilter.length > 0 && (
                    <Paper
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            padding: '16px 0'
                        }}
                    >
                        <Pagination
                            count={Math.ceil(dataFilter.length / itemsPerPage)}
                            page={page}
                            variant="outlined"
                            shape="rounded"
                            onChange={handleChange}
                        />
                    </Paper>
                )}
                {!hasItens && (
                    <div style={{ marginTop: '96px' }}>
                        <div className={classes.noParticipantsMessage}>
                            <h2>{t('LIBRARY.noHistoryMessage')}</h2>
                            <p style={{ fontWeight: '400' }}>
                                Você não possui histórico de empréstimos de
                                livro. Para solicitar um empréstimo visite a
                                página de{' '}
                                <LinkRouter
                                    className={styles.Link}
                                    to="/BooksCollection"
                                >
                                    Acervo
                                </LinkRouter>{' '}
                                e escolha um livro.
                            </p>
                        </div>
                    </div>
                )}
                {hasNoResult && (
                    <div className={classes.noParticipantsMessage}>
                        <div>
                            <Dissatisfied
                                className={classes.notFoundIconStyle}
                            />
                        </div>
                        <h2>{t('GENERAL.noResultsFound')}</h2>
                        <h5>{`Sua busca por "${findText}" não retornou resultados.`}</h5>
                    </div>
                )}
            </Paper>
            {openHistoryMoreDetailsDialog && (
                <DialogHistoryMoreDetails
                    open={openHistoryMoreDetailsDialog}
                    setOpen={setOpenHistoryMoreDetailsDialog}
                    viewValues={dialogData}
                />
            )}
        </React.Fragment>
    );
};

export default HistoryForm;
