import React, { useState } from 'react';
import './App.css';

// STYLES
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import teal from '@material-ui/core/colors/teal';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Cookie } from 'mdi-material-ui';

import { QueryClient, QueryClientProvider } from 'react-query';

import { ProtectedRoute } from 'components/auth/ProtectedRoute';
import { ProtectedRouteMode } from 'components/auth/ProtectedRouteMode';
import { ProtectedRouteGeneral } from 'components/auth/ProtectedRouteGeneral';
import { ProtectedRouteHome } from 'components/auth/ProtectedRouteHome';

import EditSubsidy from 'components/execution/Subsidies/EditSubsidy';
import ViewSubsidy from 'components/execution/Subsidies/ViewSubsidy';
import NewPartner from 'components/Partners/NewPartner';
import EditProjet from 'components/Projects/EditProject';
import Projects from 'components/Projects/Index';
import NewProject from 'components/Projects/NewProject';
import ViewProject from 'components/Projects/ViewProject/ViewProject';
import HRCostsState from 'context/hrcosts/hrCostsState';
import PartnersState from 'context/partners/partnersState';
import ProjectsState from 'context/projects/projectsState';
import SearchState from 'context/search/searchState';
import { Route, Router, Switch } from 'react-router-dom';
import { Screen, Allow } from 'global/constants';
import NotFound from './components/404';
import Dashboard from './components/Dashboard';
import ProjectExecution from './components/execution/CallEditProject';
import EditFeedback from './components/execution/Participants/EditFeedback';
import NewFeedback from './components/execution/Participants/NewFeedback';
import NewParticipant from './components/execution/Participants/NewParticipant';
import StartParticipants from './components/execution/Participants/StartParticipant';
import Activity from './components/execution/Phases/Activities/Activity';
import ActivityAddParticipants from './components/execution/Phases/Activities/AddParticipants';
import NewActivity from './components/execution/Phases/Activities/NewActivity';
import NewPhase from './components/execution/Phases/NewPhase';
import ProjectPhase from './components/execution/Phases/ProjectPhase';
import RunningProjects from './components/execution/RunningProjects';
import NewSubsidy from './components/execution/Subsidies/NewSubsidy';
import ExternalUsers from './components/ExternalUsers/CallExternalUsers';
import NewExternalUser from './components/ExternalUsers/CallNewExternalUser';
import UpdateExternalUser from './components/ExternalUsers/EditExternalUsers/CallEditExternalUser';
import ViewExternalUser from './components/ExternalUsers/ViewExternalUsers/CallView';
import Forbidden from './components/Forbidden';
import EditUserCosts from './components/HRCosts/EditUserCosts';
import UsersCosts from './components/HRCosts/Index';
import ViewUserCosts from './components/HRCosts/ViewUserCost';
import BookCategory from './pages/inventoryModule/bookCategory/CallBookCategory';
import Books from './pages/inventoryModule/books/CallBooks';
import NewBook from './pages/inventoryModule/books/CallNewBook';
import UpdateBooks from './pages/inventoryModule/books/EditBooks/CallEditBook';
import CallEditBook from './pages/inventoryModule/books/Refactoring/CallEditBook';
import PopulateAuthors from './pages/inventoryModule/books/PopulateAuthors';
import ViewBook from './pages/inventoryModule/books/ViewBooks/CallViewBook';
import CallViewBook from './pages/inventoryModule/books/Refactoring/CallViewBook';
import CallViewExemplar from './pages/inventoryModule/books/Refactoring/CallViewExemplar';
import CallEditExemplar from './pages/inventoryModule/books/Refactoring/CallEditExemplar';
import Equipment from './pages/inventoryModule/equipment/CallEquipment';
import NewEquipment from './pages/inventoryModule/equipment/CallNewEquipment';
import UpdateEquipment from './pages/inventoryModule/equipment/EditEquipment/Call';
import HistoryEquipment from './pages/inventoryModule/equipment/ViewEquipment/CallHistoryEquipment';
import ViewEquipment from './pages/inventoryModule/equipment/ViewEquipment/CallView';
import MyItens from './pages/inventoryModule/itens/CallMyItens';
import ItemCategory from './pages/inventoryModule/itensCategory/CallItemCategory';
import Places from './pages/inventoryModule/places/CallPlaces';
import InventoryStatus from './pages/inventoryModule/status/CallInventoryStatus';
import LoginScreen from './components/Login/LoginScreen';
import Logout from './components/Login/Logout';
import EditPartners from './components/Partners/EditPartners';
import Partners from './components/Partners/PartnersReturn';
import ViewPartners from './components/Partners/ViewPartners';
import EditProfile from './components/profiles/CallEditProfile';
import NewProfile from './components/profiles/CallNewProfile';
import ViewProfile from './components/profiles/CallViewProfile';
import Profiles from './components/profiles/ProfilesReturn';
import EditUser from './components/Users/EditUser';
import Users from './components/Users/Users';
import ViewUser from './components/Users/ViewUser';
import AllowanceState from './context/allowance/AllowanceState';
import ExternalUsersState from './context/externalUsers/externalUsersState';
import InventoryState from './context/inventory/inventoryState';
import ProfilesState from './context/profiles/ProfilesState';
import UsersState from './context/users/usersState';
import HrModuleState from './context/hrModule/hrModuleState';
import LibraryModuleState from './context/libraryModule/libraryModuleState';
import QuotasModuleState from './context/quotaModule/quotaModuleState';
import history from './history';
import './i18n';
import IdleTimer from './IdleTimer';
import './index.css';
import CallSoftwareCategoryCategory from './pages/inventoryModule/softwareCategory/CallSoftwareCategory';
import CallSoftwares from './pages/inventoryModule/software/CallSoftware';
import NewSoftwareAndLicense from './pages/inventoryModule/software/newSoftware/CallNewSoftwareAndLicense';
import NewLicense from './pages/inventoryModule/software/newSoftware/CallNewLicensaOnly';
import CallEditSoftware from './pages/inventoryModule/software/EditSoftware/CallEditSoftware';
import CallViewSoftware from './pages/inventoryModule/software/CallViewSoftaware';
import CallViewLicense from './pages/inventoryModule/software/CallViewLicense';
import CalEditLicense from './pages/inventoryModule/software/CallEditLicense';
import CallMyResquets from './pages/inventoryModule/InventoryRequests/CallMyRequestsPage';
import NewResquets from './pages/inventoryModule/InventoryRequests/CallNewRequests';
import CallEditRequest from './pages/inventoryModule/InventoryRequests/CallEditRequest';
import CallViewRequest from './pages/inventoryModule/InventoryRequests/CallViewRequest';
import CallCollaboratorsRequests from './pages/inventoryModule/InventoryCollaboratorsRequisitions/CallCollaboratorsRequisitions';
import CallEditCollaboratorsRequest from './pages/inventoryModule/InventoryCollaboratorsRequisitions/CallEditCollaboratorsRequisitions';
import CallViewCollaboratorsRequest from './pages/inventoryModule/InventoryCollaboratorsRequisitions/CallViewCollaboratorsRequisitions';
import CallRequests from './pages/inventoryModule/InventoryRequestsToAdm/CallRequisitions';
import CallEditRequestToAdm from './pages/inventoryModule/InventoryRequestsToAdm/CallEditRequisitions';
import CallViewRequestToAdm from './pages/inventoryModule/InventoryRequestsToAdm/CallViewRequisitions';
import CallCollaborators from './pages/hrModule/collaborator/collaborators/CallCollaborators';
import CallEditCollaborators from './pages/hrModule/collaborator/collaborators/CallEditCollaborators';
import CallViewCollaborators from './pages/hrModule/collaborator/collaborators/CallViewCollaborators';
import CallViewMyInformations from './pages/hrModule/collaborator/collaborators/CallViewMyInformations';
import CallJobs from './pages/hrModule/settings/jobs/CallJobs';
import CallViewJobs from './pages/hrModule/settings/jobs/CallViewJob';
import CallEditJobs from './pages/hrModule/settings/jobs/CallEditJob';
import CallHolidaysAndBreaks from './pages/hrModule/settings/holidaysAndBreaks/CallHolidaysAndBreaks';
import CallNewHolidaysAndBreaks from './pages/hrModule/settings/holidaysAndBreaks/CallNewHolidaysAndBreaks';
import CallViewHolidaysAndBreaks from './pages/hrModule/settings/holidaysAndBreaks/CallViewHolidaysAndBreaks';
import CallEditHolidaysAndBreaks from './pages/hrModule/settings/holidaysAndBreaks/CallEditHolidaysAndBreaks';
import CallCollaboratorsVacations from './pages/hrModule/vacation/collaboratorsVacations/CallCollaboratorsVacations';
import CallEditCollaboratorsVacations from './pages/hrModule/vacation/collaboratorsVacations/CallEditCollaboratorsVacations';
import CallViewCollaboratorsVacations from './pages/hrModule/vacation/collaboratorsVacations/CallViewCollaboratorsVacations';
import CallVacationCalendar from './pages/hrModule/vacation/vacationCalendar/CallVacationCalendar';
import CallEditVacationCalendar from './pages/hrModule/vacation/vacationCalendar/CallEditVacationCalendar';
import CallViewMyVacations from './pages/hrModule/vacation/myVacations/CallViewMyVacations';
import CallNewAllowanceReason from './pages/hrModule/settings/allowanceReason/CallNewAllowanceReason';
import CallEditAllowanceReason from './pages/hrModule/settings/allowanceReason/CallEditAllowanceReason';
import CallViewAllowanceReason from './pages/hrModule/settings/allowanceReason/CallViewAllowanceReason';
import CallAllowanceReason from './pages/hrModule/settings/allowanceReason/CallAllowanceReason';
import CallBirthdays from './pages/hrModule/collaborator/birthdays/CallBirthdays';
import CallBirthdaysPrint from './pages/hrModule/collaborator/birthdays/CallBirthdaysPrint';
import CallMyClockRecord from 'pages/hrModule/clockRecord/myClockRecord/CallMyClockRecord';
import CallActivityCategories from 'pages/hrModule/settings/activityCategories/CallActivityCategories';
import CallMyActivities from 'pages/hrModule/clockRecord/myClockRecord/CallMyActivities';
import CallViewMyActivities from 'pages/hrModule/clockRecord/myClockRecord/CallViewMyActivities';
import CallEditMyActivities from 'pages/hrModule/clockRecord/myClockRecord/CallEditMyActivities';
import CallCollaboratorsClockRecords from 'pages/hrModule/clockRecord/collaboratorsClockRecord/CallCollaboratorsClockRecords';
import CallCollaboratorClockRecord from 'pages/hrModule/clockRecord/collaboratorsClockRecord/CallCollaboratorClockRecord';
import CallCollaboratorClockRecordDetails from 'pages/hrModule/clockRecord/collaboratorsClockRecord/CallCollaboratorClockRecordDetails';
import CallMyJustifications from 'pages/hrModule/clockRecord/myJustifications/CallMyJustifications';
import CallNewMyJustifications from 'pages/hrModule/clockRecord/myJustifications/CallNewMyJustifications';
import CallViewMyJustifications from 'pages/hrModule/clockRecord/myJustifications/CallViewMyJustifications';
import CallEditMyJustifications from 'pages/hrModule/clockRecord/myJustifications/CallEditMyJustifications';
import CallCollaboratorsJustifications from 'pages/hrModule/clockRecord/collaboratorsJustifications/CallCollaboratorsJustifications';
import CallViewCollaboratorsJustifications from 'pages/hrModule/clockRecord/collaboratorsJustifications/CallViewCollaboratorsJustifications';
import CallEvaluateCollaboratorsJustifications from 'pages/hrModule/clockRecord/collaboratorsJustifications/CallEvaluateCollaboratorsJustifications';

//Pages do módulo de biblioteca
import CallBooksCollection from 'pages/libraryModule/BooksCollection/CallBooksCollection';
import CallLibraryConfiguration from 'pages/libraryModule/settings/CallLibraryConfiguration';
import CallViewLibraryRequisitionsGeneral from 'pages/libraryModule/Requisitions/CallViewLibraryRequisitionsGeneral';
import CallViewLibraryRequisitionsAll from 'pages/libraryModule/Requisitions/CallViewLibraryRequisitionsAll';
import CallViewLibraryMyLoans from 'pages/libraryModule/MyLoans/CallViewLibraryMyLoans';
import CallLibraryLoansPage from 'pages/libraryModule/LibraryLoans/CallLibraryLoansPage';
import CallFines from 'pages/libraryModule/Fines/CallFines';
import CallLibraryAwaitingListManagement from 'pages/libraryModule/Requisitions/CallLibraryAwaitingListManagement';

//Pages do módulo de Cotas
import CallJobsQuotas from 'pages/quotasModule/Jobs/CallJobs';
import CallQuotasDashboard from 'pages/quotasModule/dashboard/CallDashboard';
import CallUsersQuotas from 'pages/quotasModule/Users/CallUsers';
import CallViewUsersQuotas from 'pages/quotasModule/Users/CallViewUsers';
import CallEditUsersQuotas from 'pages/quotasModule/Users/CallEditUsers';
import CallToolsQuotas from 'pages/quotasModule/Tools/CallTools';

//Pages do módulo Administrativo
import CallUsersAdmin from 'pages/adminModule/Users/CallUsers';
import CallCreateUserAdmin from 'pages/adminModule/Users/CallCreateUser';
import CallViewUserAdmin from 'pages/adminModule/Users/CallViewUser';
import CallEditUserAdmin from 'pages/adminModule/Users/CallEditUser';
import CallProfileManagement from './pages/adminModule/profileManagement/CallProfileManagement';
import CallNewProfileManagement from './pages/adminModule/profileManagement/CallNewProfileManagement';
import CallEditProfileManagement from './pages/adminModule/profileManagement/CallEditProfileManagement';
import CallViewProfileManagement from './pages/adminModule/profileManagement/CallViewProfileManagement';

//Página inicial dos módulos
import HomePatrimony from 'pages/home/HomePatrimony';
import HomeHr from 'pages/home/HomeHr';
import HomeLibrary from 'pages/home/HomeLibrary';
import HomeQuotas from 'pages/home/HomeQuotas';
import HomeAdmin from 'pages/home/HomeAdmin';

// Snackbar Provider
import { SnackbarProvider } from 'hooks/UseSnackbar';
import DialogIncompatibility from 'components/dialogs/DialogIncompatibility';

import { isSafari, browserVersion } from 'react-device-detect';

//source: https://material-ui.com/pt/customization/color/
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#2c3e51'
        },
        secondary: {
            main: '#ffffff'
        },
        danger: {
            main: '#bb2124'
        },
        red: {
            light: red[100],
            main: red[300],
            dark: red[600],
            darkest: red[800]
        },
        teal: {
            light: teal[100],
            main: teal[300],
            dark: teal[600],
            darkest: teal[800]
        },
        grey: {
            light: grey[100],
            main: grey[300],
            dark: grey[600],
            darkest: grey[800]
        }
    }
});

function isLoggedIn() {
    return localStorage.getItem('auth-token') !== null;
}

function isLoggedInCookie() {
    return Cookie.get('auth-token') !== null;
}

function ifNotLoggedRenderLogin() {
    return isLoggedIn() ? <Dashboard /> : <LoginScreen />;
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnmount: false,
            refetchOnReconnect: false,
            retry: false
        }
    }
});

const App = () => {
    const [openDialogIncompability, setOpenDialogIncompatibility] = useState(
        localStorage.getItem('incompatibility-dialog') !== null ? false : true
    );

    return (
        <div>
            <IdleTimer />
            <MuiThemeProvider theme={theme}>
                {isSafari && parseInt(browserVersion) < 14 ? (
                    <DialogIncompatibility
                        open={openDialogIncompability}
                        setOpen={setOpenDialogIncompatibility}
                    />
                ) : null}
                {/* <CookiesProvider> */}
                <QueryClientProvider client={queryClient}>
                    <SnackbarProvider>
                        <AllowanceState>
                            <ProfilesState>
                                <UsersState>
                                    <PartnersState>
                                        <ProjectsState>
                                            <HRCostsState>
                                                <SearchState>
                                                    <InventoryState>
                                                        <ExternalUsersState>
                                                            <HrModuleState>
                                                                <LibraryModuleState>
                                                                    <QuotasModuleState>
                                                                        <Router
                                                                            history={
                                                                                history
                                                                            }
                                                                        >
                                                                            <Switch>
                                                                                <Route
                                                                                    exact
                                                                                    path="/dashboard"
                                                                                    render={
                                                                                        ifNotLoggedRenderLogin
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="/login"
                                                                                    render={
                                                                                        ifNotLoggedRenderLogin
                                                                                    }
                                                                                />
                                                                                <ProtectedRoute
                                                                                    exact
                                                                                    resource="USER"
                                                                                    permission="allowRead"
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/users/:id"
                                                                                    component={
                                                                                        ViewUser
                                                                                    }
                                                                                />
                                                                                <ProtectedRoute
                                                                                    exact
                                                                                    resource="USER"
                                                                                    permission="allowUpdate"
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/users/:id/edit"
                                                                                    component={
                                                                                        EditUser
                                                                                    }
                                                                                />
                                                                                <ProtectedRoute
                                                                                    exact
                                                                                    resource="USER"
                                                                                    permission="list"
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/users"
                                                                                    component={
                                                                                        Users
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    exact
                                                                                    path="/"
                                                                                    render={
                                                                                        ifNotLoggedRenderLogin
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    exact
                                                                                    path="/logout"
                                                                                    component={
                                                                                        Logout
                                                                                    }
                                                                                />
                                                                                <ProtectedRoute
                                                                                    exact
                                                                                    path="/profiles"
                                                                                    resource="PROFILE"
                                                                                    permission="list"
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    component={
                                                                                        Profiles
                                                                                    }
                                                                                />
                                                                                <ProtectedRoute
                                                                                    exact
                                                                                    resource="PROFILE"
                                                                                    permission="allowCreate"
                                                                                    path="/profiles/new"
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    component={
                                                                                        NewProfile
                                                                                    }
                                                                                />
                                                                                <ProtectedRoute
                                                                                    exact
                                                                                    resource="PROFILE"
                                                                                    permission="allowRead"
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/profiles/:id"
                                                                                    component={
                                                                                        ViewProfile
                                                                                    }
                                                                                />
                                                                                <ProtectedRoute
                                                                                    exact
                                                                                    resource="PROFILE"
                                                                                    permission="allowUpdate"
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/profiles/:id/edit"
                                                                                    component={
                                                                                        EditProfile
                                                                                    }
                                                                                />
                                                                                <ProtectedRoute
                                                                                    exact
                                                                                    resource="PARTNER"
                                                                                    permission="list"
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/partners/"
                                                                                    component={
                                                                                        Partners
                                                                                    }
                                                                                />
                                                                                <ProtectedRoute
                                                                                    exact
                                                                                    resource="PARTNER"
                                                                                    permission="allowCreate"
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/partners/new"
                                                                                    component={
                                                                                        NewPartner
                                                                                    }
                                                                                />
                                                                                <ProtectedRoute
                                                                                    exact
                                                                                    resource="PARTNER"
                                                                                    permission="allowUpdate"
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/partners/:id/edit"
                                                                                    component={
                                                                                        EditPartners
                                                                                    }
                                                                                />
                                                                                <ProtectedRoute
                                                                                    exact
                                                                                    resource="PARTNER"
                                                                                    permission="allowRead"
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/partners/:id"
                                                                                    component={
                                                                                        ViewPartners
                                                                                    }
                                                                                />
                                                                                <ProtectedRoute
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/projects/"
                                                                                    permission="list"
                                                                                    component={
                                                                                        Projects
                                                                                    }
                                                                                />
                                                                                <ProtectedRoute
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    permission="allowCreate"
                                                                                    resource="PROJECTSPLAN"
                                                                                    path="/projects/new"
                                                                                    component={
                                                                                        NewProject
                                                                                    }
                                                                                />
                                                                                <ProtectedRoute
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    resource="PROJECTSPLAN"
                                                                                    permission="allowRead"
                                                                                    path="/projects/:id"
                                                                                    component={
                                                                                        ViewProject
                                                                                    }
                                                                                />
                                                                                <ProtectedRoute
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    resource="PROJECTSPLAN"
                                                                                    permission="allowUpdate"
                                                                                    path="/projects/:id/edit"
                                                                                    component={
                                                                                        EditProjet
                                                                                    }
                                                                                />
                                                                                <ProtectedRoute
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    permission="list"
                                                                                    path="/userscosts/"
                                                                                    component={
                                                                                        UsersCosts
                                                                                    }
                                                                                />
                                                                                <ProtectedRoute
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    resource="USERCOSTS"
                                                                                    permission="allowRead"
                                                                                    path="/userscosts/:id"
                                                                                    component={
                                                                                        ViewUserCosts
                                                                                    }
                                                                                />
                                                                                <ProtectedRoute
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    resource="USERCOSTS"
                                                                                    permission="allowUpdate"
                                                                                    path="/userscosts/:id/edit"
                                                                                    component={
                                                                                        EditUserCosts
                                                                                    }
                                                                                />
                                                                                <ProtectedRoute
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    permission="list"
                                                                                    path="/execution/projects"
                                                                                    component={
                                                                                        RunningProjects
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteMode
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    resource="PARTICIPANTS"
                                                                                    path="/execution/participant/:mode/:participationId/:id/:projectId/:participantMode"
                                                                                    component={
                                                                                        StartParticipants
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteMode
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    resource="PARTICIPANTS"
                                                                                    permission="allowCreate"
                                                                                    path="/execution/participant/:mode/:participationId/:id/:projectId/:participantMode/addFeedback"
                                                                                    component={
                                                                                        NewFeedback
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteMode
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    resource="PARTICIPANTS"
                                                                                    path="/execution/participant/:mode/:participationId/:id/:projectId/:participantMode/:feedbackId/:feedbackMode"
                                                                                    component={
                                                                                        EditFeedback
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteMode
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    resource="PROJECTSEXEC"
                                                                                    path="/execution/projects/:id/:mode"
                                                                                    component={
                                                                                        ProjectExecution
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteMode
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    resource="PARTICIPANTS"
                                                                                    permission="allowCreate"
                                                                                    path="/execution/addparticipant/:id"
                                                                                    component={
                                                                                        NewParticipant
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteMode
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    resource="PHASES"
                                                                                    permission="allowCreate"
                                                                                    path="/execution/addPhase/:id"
                                                                                    component={
                                                                                        NewPhase
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteMode
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    resource="PHASES"
                                                                                    path="/execution/project/:projectMode/projectPhase/:id/:mode"
                                                                                    component={
                                                                                        ProjectPhase
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteMode
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    resource="ACTIVITIES"
                                                                                    path="/execution/projectPhase/:id/edit/addActivity"
                                                                                    permission="allowCreate"
                                                                                    component={
                                                                                        NewActivity
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteMode
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    resource="ACTIVITIES"
                                                                                    path="/execution/project/:projectMode/projectPhase/:phaseMode/phaseActivity/:id/:mode"
                                                                                    component={
                                                                                        Activity
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteMode
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    resource="ACTIVITIES"
                                                                                    path="/execution/phaseActivity/:id/edit/addParticipants"
                                                                                    permission="allowCreate"
                                                                                    component={
                                                                                        ActivityAddParticipants
                                                                                    }
                                                                                />
                                                                                <ProtectedRoute
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/execution/newSubsidy/:id"
                                                                                    resource="BUDGETEXECUTION"
                                                                                    permission="allowCreate"
                                                                                    component={
                                                                                        NewSubsidy
                                                                                    }
                                                                                />
                                                                                <ProtectedRoute
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/execution/editSubsidy/:id"
                                                                                    resource="BUDGETEXECUTION"
                                                                                    permission="allowUpdate"
                                                                                    component={
                                                                                        EditSubsidy
                                                                                    }
                                                                                />
                                                                                <ProtectedRoute
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    resource="BUDGETEXECUTION"
                                                                                    permission="allowRead"
                                                                                    path="/execution/viewSubsidy/:id"
                                                                                    component={
                                                                                        ViewSubsidy
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/forbidden"
                                                                                    permission="list"
                                                                                    component={
                                                                                        Forbidden
                                                                                    }
                                                                                />
                                                                                {/* Inventory */}
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/myItens"
                                                                                    screen={
                                                                                        Screen.MY_ITENS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        MyItens
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/places"
                                                                                    screen={
                                                                                        Screen.PLACES
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        Places
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/bookCategory"
                                                                                    screen={
                                                                                        Screen.BOOKS_CATEGORIES
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        BookCategory
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/itemCategory"
                                                                                    screen={
                                                                                        Screen.ITENS_CATEGORIES
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        ItemCategory
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/InventoryStatus"
                                                                                    screen={
                                                                                        Screen.ITENS_STATUS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        InventoryStatus
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/InventoryEquipment"
                                                                                    screen={
                                                                                        Screen.EQUIPMENTS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        Equipment
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/InventoryEquipment/new"
                                                                                    screen={
                                                                                        Screen.EQUIPMENTS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.CREATE
                                                                                    }
                                                                                    component={
                                                                                        NewEquipment
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/InventoryEquipment/:id/edit"
                                                                                    screen={
                                                                                        Screen.EQUIPMENTS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.EDIT
                                                                                    }
                                                                                    component={
                                                                                        UpdateEquipment
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/InventoryEquipment/:id"
                                                                                    screen={
                                                                                        Screen.EQUIPMENTS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        ViewEquipment
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/InventoryEquipment/History/:id"
                                                                                    screen={
                                                                                        Screen.EQUIPMENTS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        HistoryEquipment
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/ExternalUsers"
                                                                                    screen={
                                                                                        Screen.EXTERNAL_COLLABORATORS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        ExternalUsers
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/ExternalUsers/new"
                                                                                    screen={
                                                                                        Screen.EXTERNAL_COLLABORATORS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.CREATE
                                                                                    }
                                                                                    component={
                                                                                        NewExternalUser
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/ExternalUsers/:id/edit"
                                                                                    screen={
                                                                                        Screen.EXTERNAL_COLLABORATORS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.EDIT
                                                                                    }
                                                                                    component={
                                                                                        UpdateExternalUser
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/ExternalUsers/:id"
                                                                                    screen={
                                                                                        Screen.EXTERNAL_COLLABORATORS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        ViewExternalUser
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Books"
                                                                                    screen={
                                                                                        Screen.BOOKS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        Books
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Books/new"
                                                                                    screen={
                                                                                        Screen.BOOKS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.CREATE
                                                                                    }
                                                                                    component={
                                                                                        NewBook
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Books/populateAuthors"
                                                                                    screen={
                                                                                        Screen.BOOKS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.CREATE
                                                                                    }
                                                                                    component={
                                                                                        PopulateAuthors
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Books/:id"
                                                                                    screen={
                                                                                        Screen.BOOKS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallViewBook
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Books/Exemplar/:id"
                                                                                    screen={
                                                                                        Screen.BOOKS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallViewExemplar
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Books/:id/edit"
                                                                                    screen={
                                                                                        Screen.BOOKS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.EDIT
                                                                                    }
                                                                                    component={
                                                                                        CallEditBook
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Books/Exemplar/:id/edit"
                                                                                    screen={
                                                                                        Screen.BOOKS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.EDIT
                                                                                    }
                                                                                    component={
                                                                                        CallEditExemplar
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/SoftwareCategories"
                                                                                    screen={
                                                                                        Screen.SOFTWARES_CATEGORIES
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallSoftwareCategoryCategory
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Software"
                                                                                    screen={
                                                                                        Screen.SOFTWARES
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallSoftwares
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Software/newSoftware"
                                                                                    screen={
                                                                                        Screen.SOFTWARES
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.CREATE
                                                                                    }
                                                                                    component={
                                                                                        NewSoftwareAndLicense
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Software/:id/edit"
                                                                                    screen={
                                                                                        Screen.SOFTWARES
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.EDIT
                                                                                    }
                                                                                    component={
                                                                                        CallEditSoftware
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Software/view/:id"
                                                                                    screen={
                                                                                        Screen.SOFTWARES
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallViewSoftware
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Software/newLicense"
                                                                                    screen={
                                                                                        Screen.SOFTWARES
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.CREATE
                                                                                    }
                                                                                    component={
                                                                                        NewLicense
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Software/LicenseView/:id"
                                                                                    screen={
                                                                                        Screen.SOFTWARES
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallViewLicense
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Software/License/:id/edit"
                                                                                    screen={
                                                                                        Screen.SOFTWARES
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.EDIT
                                                                                    }
                                                                                    component={
                                                                                        CalEditLicense
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/MyRequests"
                                                                                    screen={
                                                                                        Screen.MY_REQUISITIONS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallMyResquets
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Requisition/new"
                                                                                    permission="allowCreate"
                                                                                    screen={
                                                                                        Screen.MY_REQUISITIONS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.CREATE
                                                                                    }
                                                                                    component={
                                                                                        NewResquets
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/CollaboratorsRequests"
                                                                                    screen={
                                                                                        Screen.COLLABORATORS_REQUISITIONS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallCollaboratorsRequests
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Requisition/:id/edit"
                                                                                    screen={
                                                                                        Screen.MY_REQUISITIONS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.EDIT
                                                                                    }
                                                                                    component={
                                                                                        CallEditRequest
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/CollaboratorRequisition/:id/edit"
                                                                                    screen={
                                                                                        Screen.COLLABORATORS_REQUISITIONS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.EDIT
                                                                                    }
                                                                                    component={
                                                                                        CallEditCollaboratorsRequest
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/CollaboratorRequisition/:id/view"
                                                                                    screen={
                                                                                        Screen.COLLABORATORS_REQUISITIONS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallViewCollaboratorsRequest
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Requisition/:id"
                                                                                    screen={
                                                                                        Screen.MY_REQUISITIONS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallViewRequest
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/RequisitionsAdm"
                                                                                    screen={
                                                                                        Screen.REQUISITIONS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallRequests
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/RequisitionsAdm/:id/edit"
                                                                                    screen={
                                                                                        Screen.REQUISITIONS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.EDIT
                                                                                    }
                                                                                    component={
                                                                                        CallEditRequestToAdm
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/RequisitionsAdm/:id/view"
                                                                                    screen={
                                                                                        Screen.REQUISITIONS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallViewRequestToAdm
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Collaborators"
                                                                                    screen={
                                                                                        Screen.COLLABORATORS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallCollaborators
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Collaborators/:id/edit"
                                                                                    screen={
                                                                                        Screen.COLLABORATORS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.EDIT
                                                                                    }
                                                                                    component={
                                                                                        CallEditCollaborators
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Collaborators/:id/view"
                                                                                    screen={
                                                                                        Screen.COLLABORATORS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallViewCollaborators
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/myInformations"
                                                                                    screen={
                                                                                        Screen.MY_INFORMATIONS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallViewMyInformations
                                                                                    }
                                                                                    // canBeAcessedBySameId={true}
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Jobs"
                                                                                    screen={
                                                                                        Screen.JOBS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallJobs
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Jobs/:id/view"
                                                                                    screen={
                                                                                        Screen.JOBS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallViewJobs
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Jobs/:id/edit"
                                                                                    screen={
                                                                                        Screen.JOBS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.EDIT
                                                                                    }
                                                                                    component={
                                                                                        CallEditJobs
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/HolidaysAndBreaks"
                                                                                    screen={
                                                                                        Screen.HOLIDAYS_AND_BREAKS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallHolidaysAndBreaks
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/HolidaysAndBreaks/:id/view"
                                                                                    screen={
                                                                                        Screen.HOLIDAYS_AND_BREAKS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallViewHolidaysAndBreaks
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/HolidaysAndBreaks/:id/edit"
                                                                                    screen={
                                                                                        Screen.HOLIDAYS_AND_BREAKS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.EDIT
                                                                                    }
                                                                                    component={
                                                                                        CallEditHolidaysAndBreaks
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/HolidaysAndBreaks/new"
                                                                                    screen={
                                                                                        Screen.HOLIDAYS_AND_BREAKS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.CREATE
                                                                                    }
                                                                                    component={
                                                                                        CallNewHolidaysAndBreaks
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/CollaboratorsVacations"
                                                                                    screen={
                                                                                        Screen.COLLABORATORS_VACATIONS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallCollaboratorsVacations
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/CollaboratorsVacations/:id/view"
                                                                                    screen={
                                                                                        Screen.COLLABORATORS_VACATIONS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallViewCollaboratorsVacations
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/CollaboratorsVacations/:id/edit"
                                                                                    screen={
                                                                                        Screen.COLLABORATORS_VACATIONS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.EDIT
                                                                                    }
                                                                                    component={
                                                                                        CallEditCollaboratorsVacations
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/VacationCalendar"
                                                                                    screen={
                                                                                        Screen.VACATION_CALENDAR
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallVacationCalendar
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/VacationCalendar/:id/edit"
                                                                                    screen={
                                                                                        Screen.COLLABORATORS_VACATIONS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.EDIT
                                                                                    }
                                                                                    component={
                                                                                        CallEditVacationCalendar
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/myVacations"
                                                                                    screen={
                                                                                        Screen.MY_VACATIONS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallViewMyVacations
                                                                                    }
                                                                                    // canBeAcessedBySameId={true}
                                                                                />

                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/AllowanceReason/new"
                                                                                    screen={
                                                                                        Screen.ALLOWANCE_REASON
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.CREATE
                                                                                    }
                                                                                    component={
                                                                                        CallNewAllowanceReason
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/AllowanceReason/:id/view"
                                                                                    screen={
                                                                                        Screen.ALLOWANCE_REASON
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallViewAllowanceReason
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/AllowanceReason/:id/edit"
                                                                                    screen={
                                                                                        Screen.ALLOWANCE_REASON
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.EDIT
                                                                                    }
                                                                                    component={
                                                                                        CallEditAllowanceReason
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/AllowanceReason"
                                                                                    screen={
                                                                                        Screen.ALLOWANCE_REASON
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallAllowanceReason
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Birthdays"
                                                                                    screen={
                                                                                        Screen.BIRTHDAYS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallBirthdays
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Birthdays/Print/:month"
                                                                                    screen={
                                                                                        Screen.BIRTHDAYS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallBirthdaysPrint
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/MyClockRecord"
                                                                                    screen={
                                                                                        Screen.MY_CLOCK_RECORD
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallMyClockRecord
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/MyActivities/:id"
                                                                                    screen={
                                                                                        Screen.MY_CLOCK_RECORD
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallMyActivities
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/MyActivities/:id/view"
                                                                                    screen={
                                                                                        Screen.MY_CLOCK_RECORD
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallViewMyActivities
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/MyActivities/:id/edit"
                                                                                    screen={
                                                                                        Screen.MY_CLOCK_RECORD
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.EDIT
                                                                                    }
                                                                                    component={
                                                                                        CallEditMyActivities
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/ActivityCategories"
                                                                                    screen={
                                                                                        Screen.ACTIVITY_CATEGORIES
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallActivityCategories
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/CollaboratorsClockRecords"
                                                                                    screen={
                                                                                        Screen.COLLABORATORS_CLOCK_RECORDS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallCollaboratorsClockRecords
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/CollaboratorClockRecord/:id"
                                                                                    screen={
                                                                                        Screen.COLLABORATORS_CLOCK_RECORDS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallCollaboratorClockRecord
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/CollaboratorClockRecordDetails/:id"
                                                                                    screen={
                                                                                        Screen.COLLABORATORS_CLOCK_RECORDS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallCollaboratorClockRecordDetails
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/MyJustifications"
                                                                                    screen={
                                                                                        Screen.MY_JUSTIFICATIONS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallMyJustifications
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/MyJustifications/new"
                                                                                    screen={
                                                                                        Screen.MY_JUSTIFICATIONS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.CREATE
                                                                                    }
                                                                                    component={
                                                                                        CallNewMyJustifications
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/MyJustifications/:id"
                                                                                    screen={
                                                                                        Screen.MY_JUSTIFICATIONS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallViewMyJustifications
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/MyJustifications/:id/edit"
                                                                                    screen={
                                                                                        Screen.MY_JUSTIFICATIONS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.EDIT
                                                                                    }
                                                                                    component={
                                                                                        CallEditMyJustifications
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/CollaboratorsJustifications"
                                                                                    screen={
                                                                                        Screen.COLLABORATORS_JUSTIFICATIONS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallCollaboratorsJustifications
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/CollaboratorsJustifications/:id"
                                                                                    screen={
                                                                                        Screen.COLLABORATORS_JUSTIFICATIONS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallViewCollaboratorsJustifications
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/CollaboratorsJustifications/:id/evaluate"
                                                                                    screen={
                                                                                        Screen.COLLABORATORS_JUSTIFICATIONS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.CREATE
                                                                                    }
                                                                                    component={
                                                                                        CallEvaluateCollaboratorsJustifications
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteHome
                                                                                    exact
                                                                                    path="/Patrimony"
                                                                                    component={
                                                                                        HomePatrimony
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteHome
                                                                                    exact
                                                                                    path="/HumanResources"
                                                                                    component={
                                                                                        HomeHr
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteHome
                                                                                    exact
                                                                                    path="/Library"
                                                                                    component={
                                                                                        HomeLibrary
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/BooksCollection"
                                                                                    screen={
                                                                                        Screen.BOOKS_COLLECTION
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallBooksCollection
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/LibraryConfiguration"
                                                                                    screen={
                                                                                        Screen.LIBRARY_CONFIGURATION
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallLibraryConfiguration
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/LibraryRequisitions/general"
                                                                                    screen={
                                                                                        Screen.LIBRARY_REQUISITIONS_GENERAL
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallViewLibraryRequisitionsGeneral
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/LibraryRequisitions/all"
                                                                                    screen={
                                                                                        Screen.LIBRARY_REQUISITIONS_ALL
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallViewLibraryRequisitionsAll
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Library/MyLoans"
                                                                                    screen={
                                                                                        Screen.LIBRARY_MY_LOANS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallViewLibraryMyLoans
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Library/Fines"
                                                                                    screen={
                                                                                        Screen.FINES
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallFines
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Library/Loans"
                                                                                    screen={
                                                                                        Screen.LIBRARY_LOANS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallLibraryLoansPage
                                                                                    }
                                                                                />

                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Library/AwaitingList/:id"
                                                                                    screen={
                                                                                        Screen.LIBRARY_LOANS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallLibraryAwaitingListManagement
                                                                                    }
                                                                                />

                                                                                <ProtectedRouteHome
                                                                                    exact
                                                                                    path="/Quotas"
                                                                                    component={
                                                                                        HomeQuotas
                                                                                    }
                                                                                />

                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Quotas/Dashboard"
                                                                                    screen={
                                                                                        Screen.QUOTAS_DASHBOARD_PERSONAL
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallQuotasDashboard
                                                                                    }
                                                                                />

                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Quotas/Jobs"
                                                                                    screen={
                                                                                        Screen.QUOTAS_JOBS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallJobsQuotas
                                                                                    }
                                                                                />

                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Quotas/Users"
                                                                                    screen={
                                                                                        Screen.QUOTAS_USERS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallUsersQuotas
                                                                                    }
                                                                                />

                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Quotas/Users/View/:id"
                                                                                    screen={
                                                                                        Screen.QUOTAS_USERS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallViewUsersQuotas
                                                                                    }
                                                                                />

                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Quotas/Users/Edit/:id"
                                                                                    screen={
                                                                                        Screen.QUOTAS_USERS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallEditUsersQuotas
                                                                                    }
                                                                                />

                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Quotas/Tools"
                                                                                    screen={
                                                                                        Screen.QUOTAS_TOOLS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallToolsQuotas
                                                                                    }
                                                                                />

                                                                                <ProtectedRouteHome
                                                                                    exact
                                                                                    path="/Admin"
                                                                                    component={
                                                                                        HomeAdmin
                                                                                    }
                                                                                />

                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Admin/Users"
                                                                                    screen={
                                                                                        Screen.ADMIN_USERS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallUsersAdmin
                                                                                    }
                                                                                />

                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Admin/Users/Create"
                                                                                    screen={
                                                                                        Screen.ADMIN_USERS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.CREATE
                                                                                    }
                                                                                    component={
                                                                                        CallCreateUserAdmin
                                                                                    }
                                                                                />

                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Admin/Users/View/:id"
                                                                                    screen={
                                                                                        Screen.ADMIN_USERS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallViewUserAdmin
                                                                                    }
                                                                                />

                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Admin/Users/Edit/:id"
                                                                                    screen={
                                                                                        Screen.ADMIN_USERS
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.EDIT
                                                                                    }
                                                                                    component={
                                                                                        CallEditUserAdmin
                                                                                    }
                                                                                />

                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Admin/ProfileManagement"
                                                                                    screen={
                                                                                        Screen.ADMIN_PROFILES
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallProfileManagement
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Admin/ProfileManagement/new"
                                                                                    screen={
                                                                                        Screen.ADMIN_PROFILES
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.CREATE
                                                                                    }
                                                                                    component={
                                                                                        CallNewProfileManagement
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Admin/ProfileManagement/:id/edit"
                                                                                    screen={
                                                                                        Screen.ADMIN_PROFILES
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.EDIT
                                                                                    }
                                                                                    component={
                                                                                        CallEditProfileManagement
                                                                                    }
                                                                                />
                                                                                <ProtectedRouteGeneral
                                                                                    exact
                                                                                    level={
                                                                                        0
                                                                                    }
                                                                                    path="/Admin/ProfileManagement/:id/view"
                                                                                    screen={
                                                                                        Screen.ADMIN_PROFILES
                                                                                    }
                                                                                    allowType={
                                                                                        Allow.READ
                                                                                    }
                                                                                    component={
                                                                                        CallViewProfileManagement
                                                                                    }
                                                                                />

                                                                                <Route
                                                                                    path="*"
                                                                                    component={
                                                                                        NotFound
                                                                                    }
                                                                                />
                                                                            </Switch>
                                                                        </Router>
                                                                    </QuotasModuleState>
                                                                </LibraryModuleState>
                                                            </HrModuleState>
                                                        </ExternalUsersState>
                                                    </InventoryState>
                                                </SearchState>
                                            </HRCostsState>
                                        </ProjectsState>
                                    </PartnersState>
                                </UsersState>
                            </ProfilesState>
                        </AllowanceState>
                        {/* </CookiesProvider> */}
                    </SnackbarProvider>
                </QueryClientProvider>
            </MuiThemeProvider>
        </div>
    );
};
export default App;
