import { StyleSheet } from "@react-pdf/renderer";

export default StyleSheet.create({
    page: {
        display: "flex",
        flexDirection: "column",
        padding: 20,
        color: "#000000",
        fontFamily: "Roboto",
    },
});
