/* eslint-disable */
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { GetGeneralPermissionForAction } from "utils/permissions/GetGeneralPermissionForAction";
import { Screen, Allow } from "global/constants";
import React, { useEffect, useState } from "react";
import SidebarListItem from "./SidebarListItem";

const InventorySideBarConfigMenu = () => {
  const [displayMenu, setDisplayMenu] = useState(true);
  const [arrowConfig, setArrowConfig] = useState(true);
  const [arrowEventWorking, setArrowEventWorking] = useState(false);

  const handleMouseEvent = () => {
    setDisplayMenu(!displayMenu);
    setArrowConfig(!arrowConfig);
    setArrowEventWorking(false);
  };

  const handleMouseEventWorkPlan = () => {
    setDisplayMenu(false);
    setArrowConfig(false);
    setArrowEventWorking(false);
  };

  const handleMouseEventWorking = () => {
    setDisplayMenu(false);
    setArrowConfig(false);
    setArrowEventWorking(!arrowEventWorking);
  };

  useEffect(() => {
    handleMouseEventWorking();
    handleMouseEventWorkPlan();
    handleMouseEvent();
    return () => {
      setDisplayMenu(false);
      setArrowConfig(false);
      setArrowEventWorking(false);
    };
  }, []);

  const getPermissionInventory = () => {
    if (localStorage.getItem("auth-token") !== null) {
      var auth = JSON.parse(localStorage.getItem("auth-token"));
      const permission = auth.permissionsByModule;

      // Get the user profile rule for the inventory module
      const inventory = permission.filter(el => el.systemId == 6910301);

      // Checks whether the user is an administrative manager in the inventory module
      if (inventory[0] && inventory[0].roleId === 6905151) {
        return true;
      } else {
        return false;
      }
    }
  }

  const getPermissionRHorInventory = () => {
    if (localStorage.getItem("auth-token") !== null) {
      var auth = JSON.parse(localStorage.getItem("auth-token"));
      const permission = auth.permissionsByModule;

      // Get the user profile rule for the people management module
      const peopleManagement = permission.filter(el => el.systemId == 6905701);
      // Get the user profile rule for the inventory module
      const inventory = permission.filter(el => el.systemId == 6910301);

      // Checks whether the user is an administrative manager in the people management module and an administrator in the assets module
      if ((peopleManagement[0] && peopleManagement[0].roleId === 6905901) || (inventory[0] && inventory[0].roleId === 6905151)) {
        return true;
      } else {
        return false;
      }
    }
  }

  const GetAtLeastOneConfigScreen = () => {
    if (localStorage.getItem("auth-token") !== null) {
      var auth = JSON.parse(localStorage.getItem("auth-token"));
      const configScreens = [Screen.PLACES, Screen.BOOKS_CATEGORIES, Screen.SOFTWARES_CATEGORIES, Screen.ITENS_STATUS, Screen.ITENS_CATEGORIES, Screen.EXTERNAL_COLLABORATORS]
      return GetGeneralPermissionForAction(Screen.PLACES, Allow.READ) ||
        GetGeneralPermissionForAction(Screen.BOOKS_CATEGORIES, Allow.READ) ||
        GetGeneralPermissionForAction(Screen.SOFTWARES_CATEGORIES, Allow.READ) ||
        GetGeneralPermissionForAction(Screen.ITENS_STATUS, Allow.READ) ||
        GetGeneralPermissionForAction(Screen.ITENS_CATEGORIES, Allow.READ) ||
        GetGeneralPermissionForAction(Screen.EXTERNAL_COLLABORATORS, Allow.READ);
    }
  }

  const getApproverPermission = () => {
    if (localStorage.getItem("auth-token") !== null) {
      var auth = JSON.parse(localStorage.getItem("auth-token"));
      return auth.approver;
    }
  }

  return (
    <div style={{ marginTop: "10px" }}>
      {
        <div className="dropdown" >
          <ul style={{ display: "table-cell", marginLeft: "5px" }}>
            {GetGeneralPermissionForAction(Screen.MY_ITENS, Allow.READ) && (<SidebarListItem text="Meus Itens" to="/myItens" />)}
            {GetGeneralPermissionForAction(Screen.EQUIPMENTS, Allow.READ) && (<SidebarListItem text="Equipamentos" to="/InventoryEquipment" />)}
            {GetGeneralPermissionForAction(Screen.BOOKS, Allow.READ) && (<SidebarListItem text="Livros" to="/Books" />)}
            {GetGeneralPermissionForAction(Screen.SOFTWARES, Allow.READ) && (<SidebarListItem text="Softwares" to="/Software" />)}
            {GetGeneralPermissionForAction(Screen.MY_REQUISITIONS, Allow.READ) && (<SidebarListItem text="Minhas Requisições" to="/MyRequests" />)}
            {GetGeneralPermissionForAction(Screen.COLLABORATORS_REQUISITIONS, Allow.READ) && (<SidebarListItem text="Requisições de Colaboradores" to="/CollaboratorsRequests" />)}
            {GetGeneralPermissionForAction(Screen.REQUISITIONS, Allow.READ) && (<SidebarListItem text="Requisições" to="/RequisitionsAdm" />)}
            <div className="dropdown">
              {GetAtLeastOneConfigScreen() &&
                <div className="divConfig" onClick={handleMouseEvent}>
                  Configurações
                  {!arrowConfig && <KeyboardArrowRight />}
                  {arrowConfig && <KeyboardArrowDown />}
                </div>}
              {displayMenu && (
                <ul style={{ display: "table-cell", marginLeft: "5px" }}>
                  {GetGeneralPermissionForAction(Screen.BOOKS_CATEGORIES, Allow.READ) && (<SidebarListItem text="Categorias de Livros" to="/bookCategory" />)}
                  {GetGeneralPermissionForAction(Screen.ITENS_CATEGORIES, Allow.READ) && (<SidebarListItem text="Categorias de Itens" to="/itemCategory" />)}
                  {GetGeneralPermissionForAction(Screen.SOFTWARES_CATEGORIES, Allow.READ) && (<SidebarListItem text="Categorias de Softwares" to="/SoftwareCategories" />)}
                  {GetGeneralPermissionForAction(Screen.EXTERNAL_COLLABORATORS, Allow.READ) && (<SidebarListItem text="Colaboradores Externos" to="/ExternalUsers" />)}
                  {GetGeneralPermissionForAction(Screen.PLACES, Allow.READ) && (<SidebarListItem text="Locais" to="/places" />)}
                  {GetGeneralPermissionForAction(Screen.ITENS_STATUS, Allow.READ) && (<SidebarListItem text="Status de Itens" to="/InventoryStatus" />)}
                </ul>
              )}
            </div>
          </ul>
        </div>
      }
    </div>
  );
};

export default InventorySideBarConfigMenu;
