import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import React, { useEffect, useState } from 'react';
import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';
import { Screen, Allow } from 'global/constants';
import SidebarListItem from './SidebarListItem';

const QuotasSideBarConfigMenu = () => {
    const [displayMenu, setDisplayMenu] = useState(false);
    const [arrowConfig, setArrowConfig] = useState(false);

    const handleMouseEvent = () => {
        setDisplayMenu(!displayMenu);
        setArrowConfig(!arrowConfig);
    };

    const GetAtLeastOneConfigurationScreen = () => {
        if (localStorage.getItem('auth-token') !== null) {
            return (
                GetGeneralPermissionForAction(Screen.QUOTAS_JOBS, Allow.READ) ||
                GetGeneralPermissionForAction(
                    Screen.QUOTAS_USERS,
                    Allow.READ
                ) ||
                GetGeneralPermissionForAction(Screen.QUOTAS_TOOLS, Allow.READ)
            );
        }
    };

    useEffect(() => {
        return () => {
            setDisplayMenu(false);
            setArrowConfig(false);
        };
    }, []);

    return (
        <div style={{ marginTop: '10px' }}>
            {
                <div className="dropdown">
                    <ul style={{ display: 'table-cell', marginLeft: '5px' }}>
                        {GetGeneralPermissionForAction(Screen.QUOTAS_DASHBOARD_PERSONAL, Allow.READ) && (<SidebarListItem text="Início" to="/Quotas/Dashboard" />)}
                    </ul>
                </div>
            }

            {GetAtLeastOneConfigurationScreen() && (
                <div className="dropdown">
                    <div className="divConfig" onClick={handleMouseEvent}>
                        Configurações
                        {!arrowConfig && <KeyboardArrowRight />}
                        {arrowConfig && <KeyboardArrowDown />}
                    </div>

                    {displayMenu && (
                        <ul style={{ display: 'table-cell', marginLeft: '5px' }}>
                            {GetGeneralPermissionForAction(Screen.QUOTAS_JOBS, Allow.READ) && (<SidebarListItem text="Cargos" to="/Quotas/Jobs" />)}
                            {GetGeneralPermissionForAction(Screen.QUOTAS_USERS, Allow.READ) && (<SidebarListItem text="Usuários" to="/Quotas/Users" />)}
                            {GetGeneralPermissionForAction(Screen.QUOTAS_TOOLS, Allow.READ) && (<SidebarListItem text="Ferramentas" to="/Quotas/Tools" />)}
                        </ul>
                    )}
                </div>
            )}
        </div>
    );
};

export default QuotasSideBarConfigMenu;
