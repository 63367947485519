import React, { useContext, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { useStyles } from "components/layout/CommonStyles";
import { useTranslation } from "react-i18next";
import CloseIcon from '@material-ui/icons/Close';
import Buttons from "components/buttons/Pressed";
import Download from "@material-ui/icons/GetApp";
import TextField from "@material-ui/core/TextField";
import Edit from "@material-ui/icons/Edit";
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from "@material-ui/core/Grid";
import Input from "components/inputs/Input";
import { SortLabel } from "../../../../../utils/sort/SortLabel";
import { FormatDateToDataBase } from "../../../../../utils/dates/FormatDateToDataBase";
import { convertStrToBrCurrency } from "utils/masks/convertStrToBrCurrency";
import { dateValidation } from "utils/validation/DateValidation";
import axios from "axios";
import { getPisExists } from "requests/getPisExists";


import Dropzone from "react-dropzone";
import InputFile from "components/inputs/InputModal";
import CollaboratorContext from "context/hrModule/collaborator/collaboratorContext";
import { Rulesets } from "global/constants";



import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import Cookies from "js-cookie";

const DialogAdmitted = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const fileDownload = require("js-file-download");

    const collaboratorContext = useContext(CollaboratorContext);

    const {
        addAdmission,
    } = collaboratorContext


    const [values, setValues] = useState({
        id: "",
        contractPeriodIdx: 0,
        companyId: "",
        ruleset: "",
        pis: "",
        job: "",
        salary: "",
        admissionExamFilePath: "",
        admissionExamFileName: "Anexar exame",
        contractFilePath: "",
        contractFileName: "Anexar Contrato",
        justification: "",
    });

    const [dates, setDates] = useState({
        admissionDate: null,
        holidayDate: null,
    });

    const [contractFile, setContractFile] = useState([]);
    const [admissionExamFile, setAdmissionExamFile] = useState([]);

    const [requiredAdmissionDate, setRequiredAdmissionDate] = useState(false);
    const [requiredHolidayDate, setRequiredHolidayDate] = useState(false);
    const [requiredCompanyId, setRequiredCompanyId] = useState(false);
    const [requiredRuleset, setRequiredRuleset] = useState(false);
    const [requiredPis, setRequiredPis] = useState(false);
    const [duplicatePis, setDuplicatePis] = useState(false);
    const [pisHelperMessage, setPisHelperMessage] = useState("");
    const [requiredJob, setRequiredJob] = useState(false);
    const [requiredSalary, setRequiredSalary] = useState(false);
    const [requiredContract, setRequiredContract] = useState(false);
    const [contractHelperMessage, setContractHelperMessage] = useState(false);
    const [invalidDateAdmission, setInvalidDateAdmission] = useState(false);
    const [invalidDateHoliday, setInvalidDateHoliday] = useState(false);



    const { open, setOpen, context, companiesArray, rulesetsArray, jobsArray, userId, fetchData, authId, viewValues } = props;

    const clearDialog = () => {
        setValues({
            id: "",
            contractPeriodIdx: 0,
            companyId: "",
            ruleset: "",
            pis: "",
            job: "",
            salary: "",
            admissionExamFilePath: "",
            admissionExamFileName: context !== "view" ? "Anexar exame" : "",
            contractFilePath: "",
            contractFileName: context !== "view" ? "Anexar Contrato" : "",
            justification: "",
        });
        setDates({
            admissionDate: null,
            holidayDate: null,
        });
        setRequiredAdmissionDate(false);
        setInvalidDateAdmission(false);
        setRequiredHolidayDate(false);
        setInvalidDateHoliday(false);
        setRequiredCompanyId(false);
        setRequiredRuleset(false);
        setRequiredPis(false);
        setDuplicatePis(false);
        setPisHelperMessage("");
        setRequiredJob(false);
        setRequiredSalary(false);
        setRequiredContract(false);
        setContractHelperMessage("");
    }

    useEffect(() => {
        if (viewValues) {
            setValues({
                company: viewValues.company ? viewValues.company : "",
                job: viewValues.job ? viewValues.job : "",
                ruleset: viewValues.ruleset ? viewValues.ruleset : "",
                pis: viewValues.pis ? viewValues.pis : "",
                salary: viewValues.salary ? viewValues.salary : "",
                admissionExamFilePath: viewValues.admissionExamFilePath ? viewValues.admissionExamFilePath : "",
                admissionExamFileName: viewValues.admissionExamFilePath != null ? viewValues.admissionExamFilePathName : "",
                contractFilePath: viewValues.contractFilePath ? viewValues.contractFilePath : "",
                contractFileName: viewValues.contractFilePath ? viewValues.contractFilePathName : "",
                // justification: ""
            });
            setDates({
                admissionDate: viewValues.admissionDate ? viewValues.admissionDate : null,
                holidayDate: viewValues.holidayDate ? viewValues.holidayDate : null,
            });
        }
    }, [setValues, viewValues]);

    const handleClose = () => {
        clearDialog();
        setOpen(false);
    }
    const handleChange = (name, value) => {
        let val = value;
        if (name === "ruleset") {
            if (value === Rulesets.RESEARCHER.id) {
                setRequiredContract(false);
                setContractHelperMessage("");
            }
            if (value !== Rulesets.CLT.id && value !== Rulesets.INTERN.id) {
                setRequiredHolidayDate(false);
                setInvalidDateHoliday(false);
            }
            if (value !== Rulesets.CLT.id) {
                setRequiredPis(false);
                setPisHelperMessage("");
            }
        }
        if (name === "salary") {
            val = convertStrToBrCurrency(value);
        }
        if (name === "pis") {
            setDuplicatePis(false);
            setPisHelperMessage("");
        }
        setValues({ ...values, [name]: val })
    }
    const handleDates = name => e => {
        setDates({
            ...dates,
            [name]: e
        });
        if (name === "admissionDate") {
            setRequiredAdmissionDate(false);
            setInvalidDateAdmission(false);
        } else {
            setRequiredHolidayDate(false);
            setInvalidDateHoliday(false);
        }
    };
    const onClickDownload = async (slashPath) => {
        if (slashPath) {
            const noSlashPath = slashPath.replace(/\//g, ";");
            const url = process.env.REACT_APP_SERVER + "api/file/;portal_lsbd;hr_files" + noSlashPath;
            axios.get(url, {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
                },
                responseType: "blob"
            }).then(function (res) {
                const blob = new Blob([res.data], {});
                const name = slashPath.split("/").pop()
                fileDownload(blob, name);
            });
        }
    };

    const onDropContract = acceptedFiles => {
        if (acceptedFiles[0] !== undefined) {
            // if (acceptedFiles.length > 1) {
            //     setContractHelperMessage(t("GENERAL.requiredField"));
            // } else {
            setRequiredContract(false);
            setContractHelperMessage("");
            handleChange("contractFileName", acceptedFiles[0].name)
            setContractFile(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));

        }
    };

    const onDropExame = acceptedFiles => {
        if (acceptedFiles[0] !== undefined) {
            handleChange("admissionExamFileName", acceptedFiles[0].name)
            setAdmissionExamFile(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    };

    const verifySubmit = async () => {
        let res = true;

        if (dates.admissionDate === null) {
            res = false;
            setRequiredAdmissionDate(true);
        }
        if (!dateValidation(dates.admissionDate)) {
            res = false;
            setInvalidDateAdmission(true)
        }
        if (dates.holidayDate === null && (values.ruleset === Rulesets.CLT.id || values.ruleset === Rulesets.INTERN.id)) {
            res = false;
            setRequiredHolidayDate(true);
        }
        if (!dateValidation(dates.holidayDate) && (values.ruleset === Rulesets.CLT.id || values.ruleset === Rulesets.INTERN.id)) {
            res = false;
            setInvalidDateHoliday(true);
        }
        if (!values.companyId) {
            res = false;
            setRequiredCompanyId(true);
        }
        if (!values.ruleset) {
            res = false;
            setRequiredRuleset(true);
        }
        if (!values.pis && (values.ruleset === Rulesets.CLT.id)) {
            res = false;
            setPisHelperMessage(t("GENERAL.requiredField"));
            setRequiredPis(true);
        }
        if (!values.job) {
            res = false;
            setRequiredJob(true);
        }
        if (!values.salary) {
            res = false;
            setRequiredSalary(true);
        }
        if (contractFile.length === 0 && values.ruleset !== Rulesets.RESEARCHER.id) {
            res = false;
            setContractHelperMessage(t("GENERAL.requiredField"));
            setRequiredContract(true);
        }
        if (values.pis) {
            await getPisExists(userId, values.pis).then(response => {
                const duplicatePisExists = response.data;
                if (duplicatePisExists) {
                    res = false;
                    setPisHelperMessage(t("HR.identificationErrorDuplicatePis"));
                    setDuplicatePis(duplicatePisExists);
                }
            });
        }

        return res;
    }


    const handleSubmit = async () => {
        const canSubmit = await verifySubmit();
        if (canSubmit) {
            let formData = new FormData();

            formData.append("contract.admissionDate", dates.admissionDate ? FormatDateToDataBase(new Date(dates.admissionDate)) : null);
            formData.append("contract.holidayDate", dates.holidayDate ? FormatDateToDataBase(new Date(dates.holidayDate)) : "");
            formData.append("contract.jobId", values.job ? values.job : "");
            formData.append("contract.rulesetId", values.ruleset ? values.ruleset : "");
            formData.append("contract.salary", values.salary ? values.salary : "");
            formData.append("contract.companyId", values.companyId ? values.companyId : "");
            formData.append("contract.userId", userId);

            formData.append("identification.pis", values.pis ? values.pis : "");

            formData.append("contractFile", contractFile ? contractFile[0] : null);
            formData.append("admissionExamFile", admissionExamFile ? admissionExamFile[0] : null);
            formData.append("authId", authId);

            addAdmission(formData, fetchData, userId);
            handleClose();

        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle className={classes.Title}>
                <b>{context === "view" ? t("GENERAL.view") + " " + t("HR.admission") : t("HR.admitCollaborator")}</b>
                <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container item spacing={2} md={12} lg={12}>
                    <Grid item xs={6} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disabled={context === "view"}
                                className={classes.identificationDatePicker}
                                error={requiredAdmissionDate || invalidDateAdmission}
                                helperText={requiredAdmissionDate ? t("GENERAL.requiredField") : invalidDateAdmission ? t("GENERAL.invalidDate") : ""}
                                disableToolbar
                                autoOk={true}
                                variant="inline"
                                format="dd/MM/yyyy"
                                id="validity1"
                                margin="normal"
                                invalidDateMessage="Data Inválida"
                                label={t("HR.admissionDate") + "*"}
                                KeyboardButtonProps={{
                                    "aria-label": "change date"
                                }}
                                value={dates.admissionDate}
                                onChange={handleDates("admissionDate")}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disabled={context === "view"}
                                className={classes.identificationDatePicker}
                                error={requiredHolidayDate || invalidDateHoliday}
                                helperText={requiredHolidayDate ? t("GENERAL.requiredField") : invalidDateHoliday ? t("GENERAL.invalidDate") : ""}
                                disableToolbar
                                autoOk={true}
                                variant="inline"
                                format="dd/MM/yyyy"
                                id="validity"
                                margin="normal"
                                invalidDateMessage=""
                                label={t("HR.baseDateForVacation") + (values.ruleset === Rulesets.CLT.id || values.ruleset === Rulesets.INTERN.id ? "*" : "")} // Checar se o regime é de CLT ou Estágiario
                                KeyboardButtonProps={{
                                    "aria-label": "change date"
                                }}
                                value={dates.holidayDate}
                                onChange={handleDates("holidayDate")}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        {context === "view" ?
                            <Input
                                required
                                context={context}
                                label={"HR.company"}
                                value={values.company}
                                valueName={"company"}

                            /> :
                            <Input
                                required
                                context={context}
                                label={"HR.company"}
                                isSelect
                                arraySelected={companiesArray}
                                value={values.companyId}
                                valueName={"companyId"}
                                handleChange={handleChange}
                                error={requiredCompanyId}
                                helperText={requiredCompanyId && t("GENERAL.requiredField")}
                                setRequired={setRequiredCompanyId}
                            />}
                    </Grid>

                    <Grid item xs={6} sm={6}>
                        {context === "view" ?
                            <Input
                                required
                                context={context}
                                label={"HR.regime"}
                                value={values.ruleset}
                                valueName={"ruleset"}

                            /> :
                            <Input
                                required
                                context={context}
                                label={"HR.regime"}
                                isSelect
                                arraySelected={rulesetsArray.sort(SortLabel)}
                                value={values.ruleset}
                                valueName={"ruleset"}
                                handleChange={handleChange}
                                error={requiredRuleset}
                                helperText={requiredRuleset && t("GENERAL.requiredField")}
                                setRequired={setRequiredRuleset}

                            />}
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <Input
                            context={context}
                            label={t("PERSONAL.pis") + (values.ruleset === Rulesets.CLT.id ? "*" : "")}
                            value={values.pis}
                            valueName={"pis"}
                            type="number"
                            handleChange={handleChange}
                            maxLength={15}
                            error={requiredPis || duplicatePis}
                            helperText={pisHelperMessage}
                            setRequired={setRequiredPis}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        {context === "view" ?
                            <Input
                                required
                                context={context}
                                label={"HR.position"}
                                value={values.job}
                                valueName={"job"}

                            /> :
                            <Input
                                required
                                context={context}
                                label={"HR.position"}
                                isSelect
                                arraySelected={jobsArray.sort(SortLabel)}
                                value={values.job}
                                valueName={"job"}
                                handleChange={handleChange}
                                error={requiredJob}
                                helperText={requiredJob && t("GENERAL.requiredField")}
                                setRequired={setRequiredJob}

                            />}
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Input
                            required
                            context={context}
                            label={"HR.remuneration"}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                            }}
                            value={values.salary}
                            valueName={"salary"}
                            handleChange={handleChange}
                            maxLength={13}
                            error={requiredSalary}
                            helperText={requiredSalary && t("GENERAL.requiredField")}
                            setRequired={setRequiredSalary}
                        />
                    </Grid>


                    {context === "view" ?
                        < React.Fragment >
                            <Grid item xs={12} sm={14}>

                                <TextField
                                    id="client3"
                                    variant="outlined"
                                    label={t("HR.contract")}
                                    name="responsible"
                                    autoComplete="off"
                                    className={classes.textField}
                                    value={values.contractFileName}
                                    margin="normal"
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput
                                    }}
                                    inputProps={{ "aria-label": "search" }}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton disabled={!values.contractFilePath} onClick={() => onClickDownload(values.contractFilePath)}>
                                                    <Download />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <TextField
                                    id="client1"
                                    variant="outlined"
                                    label={t("HR.admissionExam")}
                                    name="responsible"
                                    autoComplete="off"
                                    className={classes.textField}
                                    value={values.admissionExamFileName}
                                    margin="normal"
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton disabled={!values.admissionExamFilePath} onClick={() => onClickDownload(values.admissionExamFilePath)}>
                                                    <Download />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        readOnly: true,
                                    }}
                                />
                            </Grid>

                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Grid item xs={12} sm={12}>
                                <InputFile
                                    required={values.ruleset === Rulesets.RESEARCHER.id ? false : true}
                                    onDrop={onDropContract}
                                    files={contractFile}
                                    label={"HR.contract"}
                                    value={values.contractFileName}
                                    error={requiredContract}
                                    errorMessage={contractHelperMessage}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <InputFile
                                    onDrop={onDropExame}
                                    files={admissionExamFile}
                                    label={"HR.admissionExam"}
                                    value={values.admissionExamFileName}
                                />
                            </Grid>
                        </React.Fragment>
                    }


                    {/* <Grid item xs={12} sm={12}>
                        <Input
                            required
                            label={"GENERAL.justification"}
                            value={values.justification}
                            valueName={"justification"}
                            rows={5}
                            maxLength={200}
                            handleChange={handleChange}
                        // error={requiredNationality}
                        // helperText={requiredNationality && t("GENERAL.requiredField")}
                        // setRequired={setRequiredNationality}
                        />
                    </Grid> */}

                </Grid>
            </DialogContent>

            <DialogActions>
                <Buttons
                    onClick={handleClose}
                    tipo="BN-noback"
                    conteudo={context === "view" ? t("GENERAL.close").toUpperCase() : t("GENERAL.cancel").toUpperCase()}
                />
                {
                    context === "view" ? null :
                        <Buttons
                            onClick={handleSubmit}
                            tipo="BN-blue"
                            conteudo={t("HR.admit").toUpperCase()}
                        />
                }
            </DialogActions>

        </Dialog>
    )
}

export default DialogAdmitted;