import React from "react";
import { useStyles } from "components/layout/CommonStyles";


const MessagePaper = ({ headerText, subText, }) => {
    const classes = useStyles();

    return (
        <div className={classes.messagePaper}>
            {headerText && <div style={{ margin: "50px 0px 10px 0px", fontSize: "22px", fontWeight: "bold" }} >{headerText}</div>}
            {subText && <div style={{ margin: "0px 0px 50px 0px", fontSize: "15px" }} dangerouslySetInnerHTML={{ __html: subText }} />}
        </div>
    )
}

export default MessagePaper;