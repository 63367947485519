import React from "react";
import "App.css";
import SideBar from "components/layout/sidebar/Sidebar";
import DashboardNavBar from "components/layout/DashboardNavBar";
import FormRequests from './FormRequests'

const CallEditRequest = ({ match }) => {
  return (
    <div>
      <DashboardNavBar />
      <SideBar />
      <FormRequests requestId={match.params.id} context={"edit"}/>
    </div>
  );
}
export default CallEditRequest;