import { makeStyles } from '@material-ui/core/styles'

export const screenStyles = makeStyles(theme => ({
    Container: {
        width: '1000px',
        margin: '0 auto'
    },
    TextWrapper: {
        display: 'flex',
        flexDirection: 'column',
        padding: '16px',
        maxWidth: '600px'
    },
    TextWarning: {
        display: 'flex',
        marginTop: '24px',
        alignItems: 'center'
    },
    InputsContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: '16px'
    },
    ButtonsWrapper: {
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '48px 16px 16px'
    }
}))
