import axios from 'axios';
import { useMutation } from 'react-query';
import Cookies from 'js-cookie';
import { useSnackbar } from 'hooks/UseSnackbar';
import { useTranslation } from 'react-i18next';

export const useMutationVerifyPayment = (
    setValidBill,
    setVerifyPaymentError,
    setBillData
) => {
    const toast = useSnackbar();
    const [t] = useTranslation();

    const mutation = useMutation({
        mutationFn: async obj =>
            await axios.post(
                process.env.REACT_APP_SERVER +
                    'api/Quota/Bills/ValidateVerificationCode',
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            ),
        onSuccess: res => {
            setBillData(res.data);
            setValidBill(true);
        },
        onError: () => {
            setVerifyPaymentError(true);
        },
        onSettled: () => {}
    });

    return mutation;
};
