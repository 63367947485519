import { DEFAULT, EXTERNALUSERS } from "../types";

export default (state, action) => {
  switch (action.type) {
    case DEFAULT.SET_LOADING:
      return {
        ...state,
        loading: true
      };
    case EXTERNALUSERS.GET_EXTERNALUSER:
      return {
        ...state,
        externalUser: action.payload,
        loading: false
      };
    case EXTERNALUSERS.GET_EXTERNALUSERS:
      return {
        ...state,
        externalUsers: action.payload,
        loading: false
      };
    case EXTERNALUSERS.GET_PLACESEXTERNALUSER:
      return {
        ...state,
        placesExternalUsers: action.payload,
        loading: false
      };

    case EXTERNALUSERS.ADD_EXTERNALUSER:
      return {
        ...state,
        response: action.payload,
        responseType: action.responseType,
        successiveActions: action.successiveActions,
        hasChanged: action.hasChanged,
        loading: false
      };
    case EXTERNALUSERS.DELETE_EXTERNALUSER:
      return {
        ...state,
        response: action.payload,
        responseType: action.responseType,
        successiveActions: action.successiveActions,
        hasChanged: action.hasChanged,
        loading: false
      };
    case EXTERNALUSERS.UPDATE_EXTERNALUSER:
      return {
        ...state,
        response: action.payload,
        responseType: action.responseType,
        successiveActions: action.successiveActions,
        hasChanged: action.hasChanged,
        loading: false
      };
    case EXTERNALUSERS.SUBMIT_COSTS:
      return {
        ...state,
        response: action.payload,
        responseType: action.responseType,
        hasChanged: action.hasChanged,
        successiveActions: action.successiveActions,
        loading: false
      };
    case DEFAULT.RESET_MESSAGE:
      return {
        ...state,
        response: "",
        hasChanged: 0,
        responseType: ""
      };
    case DEFAULT.SET_HAS_CHANGED:
      return {
        ...state,
        hasChanged: 0
      };
    case EXTERNALUSERS.RESET_EXTERNALUSERS:
      return {
        ...state,
        userCosts: []
      }
    default:
      return state;
  }
};