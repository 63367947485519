import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#2c3e51",
    height: "70px",
  },
  icon: {
    width: "56px",
    height: "31px",
    objectFit: "contain",
  },
  titulo:{
    width: 37,
    height: 17,
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 2.5,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#ffffff',
  },
}));

const NavBar = () => {
  const classes = useStyles();
    return(
        <div>
        <AppBar position="static" title="LSBD" className={classes.root}>
            <Toolbar>
            <img src="img/logo-lsbd@2x.png"  alt="logo" className={classes.icon}></img>
                <Typography className={classes.titulo} >
                portal
                </Typography>
            </Toolbar>
        </AppBar>
        </div>
    )
}
export default NavBar;
