import React from 'react';
import '../../../App.css';
import SideBar from 'components/layout/sidebar/Sidebar';
import DashboardNavBar from 'components/layout/DashboardNavBar';
import Form from './Form/Form';

const CallViewUser = ({ match }) => {
    return (
        <div>
            <DashboardNavBar />
            <SideBar />
            <Form context={'view'} id={match.params.id} />
        </div>
    );
};
export default CallViewUser;
