import { DEFAULT, PROFILES } from "../types";

export default (state, action) => {
  switch (action.type) {
    case DEFAULT.SET_LOADING:
      return {
        ...state,
        loading: true
      };
    case PROFILES.GET_PROFILE:
      return {
        ...state,
        profile: action.payload,
        loading: false
      };
    case PROFILES.GET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
        loading: false
      };
    case PROFILES.ADD_PROFILE:
      return {
        ...state,
        response: action.payload,
        responseType: action.responseType,
        successiveActions: action.successiveActions,
        hasChanged: action.hasChanged,
        loading: false
      };
    case DEFAULT.RESET_MESSAGE:
      return {
        ...state,
        response: "",
        responseType: ""
      };
    case DEFAULT.SET_MESSAGE:
      return {
        ...state,
        response: action.payload,
        responseType: action.responseType,
        successiveActions: action.successiveActions,
        hasChanged: action.hasChanged,
        loading: false
      };
    case DEFAULT.SET_HAS_CHANGED:
      return {
        ...state,
        hasChanged: 0
      };
    case PROFILES.GET_PROFILES:
      return {
        ...state,
        profiles: action.payload,
        loading: false
      };
    case PROFILES.SEARCH:
      return {
        ...state,
        filter: action.filter,
        loading: false
      };
    case PROFILES.UPDATE_PROFILE:
      return {
        ...state,
        response: action.payload,
        responseType: action.responseType,
        successiveActions: action.successiveActions,
        hasChanged: action.hasChanged,
        loading: false
      };
    case PROFILES.DELETE_PROFILE:
      return {
        ...state,
        response: action.payload,
        responseType: action.responseType,
        successiveActions: action.successiveActions,
        hasChanged: action.hasChanged,
        loading: false
      };

    default:
      return state;
  }
};
