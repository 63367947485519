/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Breadcrumb from 'components/layout/breadcrumb/Breadcrumb';
import Transition from 'components/layout/transition/Transition';
import HeaderContainer from 'components/containers/HeaderContainer';
import ContentContainer from 'components/containers/ContentContainer';
import BlueHeaderContainer from 'components/containers/BlueHeaderContainer';
import FilterContainer from 'components/containers/FilterContainer';
import InputFilter from 'components/inputs/InputFilter';
import InputFilterDate from 'components/inputs/InputFilterDate';
import Dissatisfied from '@material-ui/icons/SentimentVeryDissatisfied';
import Typography from 'components/typography/Typography';
import DialogUnit from 'components/dialogs/DialogUnit';
import DialogUnitWithIcon from 'components/dialogs/DialogUnitWithIcon';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ErrorIcon from '@material-ui/icons/Error';
import Footer from 'components/layout/Footer';
import CollaboratorCard from 'components/Card/Collaborator';
import InputRadio from 'components/inputs/InputRadio';
import IconButton from '@material-ui/core/IconButton';
import Buttons from 'components/buttons/Pressed';
import UserBillsTable from './UserBillsTable';
import { useStyles } from 'components/layout/CommonStyles';
import { valueToMoneyFloat } from 'utils/general/valueToMoneyFloat';
import { moneyMask } from 'utils/masks/moneyMask';
import { useQueryPersonalDashboard } from 'hooks/DataFetching/Quotas/Dashboard/useQueryPersonalDashboard';
import { screenStyles } from './Styles';
import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';
import { Allow, Screen } from 'global/constants';
import { Paper, Box, Grid } from '@material-ui/core';
import { useQueryPersonalBills } from 'hooks/DataFetching/Quotas/Dashboard/useQueryPersonalBills';
import { useQueryViewUser } from 'hooks/DataFetching/Quotas/Users/useQueryViewUser';
import { useMutationConfigurePayee } from 'hooks/DataFetching/Quotas/Users/useMutationConfigurePayee';
import { isSameMonthAndYear } from 'utils/dates/isSameMontAndYear';
import { FormatDateToFront } from 'utils/dates/FormatDateToFront';
import { GetMonthAndYearInWords } from 'utils/dates/GetMonthAnsYearInWords';

import {
    translateSystemStatus,
    BillsStatusArray,
    translateBillStatus
} from './ScreenUtils';
import DialogAddBill from './Dialogs/DialogAddBill';

const UsersForm = ({ id, context }) => {
    const classes = useStyles();
    const styles = screenStyles();
    const [t] = useTranslation();

    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const [disableButton, setDisableButton] = useState(false);
    const [isAlreadyPayee, setIsAlreadyPayee] = useState(false);

    const [values, setValues] = useState({
        isPayee: null
    });

    const isNewPayee = !isAlreadyPayee && values.isPayee === 'YES';

    const mutation = useMutationConfigurePayee(setDisableButton, isNewPayee);

    const [findText, setFindText] = useState('');
    const [filterStatus, setFilterStatus] = useState('ALL');
    const [filterDate, setFilterDate] = useState(null);
    const [dataFilter, setDataFilter] = useState([]);

    const [canClick, setCanClick] = useState(false);

    const [openAddBill, setOpenAddBill] = useState(false);

    const [hasChanged, setHasChanged] = useState(false);

    const queryPersonalDashboard = useQueryPersonalDashboard({}, id);
    const queryViewUser = useQueryViewUser({}, id);
    const queryPersonalBills = useQueryPersonalBills({}, id);

    const hasItens =
        queryPersonalBills.data && queryPersonalBills.data.length > 0;
    const [hasNoResult, setHasNoResult] = useState(
        hasItens && dataFilter && dataFilter.length === 0
    );

    const handleChange = (name, value) => {
        setHasChanged(true);
        setDisableButton(false);
        setValues({ ...values, [name]: value });
    };

    const handleSubmit = () => {
        let isPayee = values.isPayee === 'YES' ? true : false;

        let formData = new FormData();

        formData.append('userId', id);
        formData.append('isPayee', isPayee);
        mutation.mutate(formData);
    };

    useEffect(() => {
        if (queryViewUser.data) {
            setValues({
                ...values,
                isPayee: queryViewUser.data.isQuotaPayee ? 'YES' : 'NO',
                isActiveInQuota: queryViewUser.data.isActiveInQuota
            });
            setIsAlreadyPayee(queryViewUser.data.isQuotaPayee);
        }
    }, [queryViewUser.data]);

    useEffect(() => {
        if (queryPersonalBills.data) {
            queryPersonalDashboard.refetch();
            setDataFilter(queryPersonalBills.data.filter(x => !x.hasDeleted));
            setHasNoResult(false);
        }
    }, [queryPersonalBills.data]);

    useEffect(() => {
        if (queryPersonalBills.data && queryPersonalBills.data.length > 0) {
            const { newDataFilter, textNotFound } = applyFilter(
                queryPersonalBills.data.filter(x => !x.hasDeleted)
            );

            if (newDataFilter.length === 0) {
                setDataFilter([]);
                setHasNoResult(true);
                setFindText(textNotFound);
            } else {
                setDataFilter(newDataFilter);
                setHasNoResult(false);
                setFindText('');
            }

            setDataFilter(newDataFilter);
        }
    }, [filterStatus, filterDate]);

    const applyFilter = data => {
        let newDataFilter = [];
        let textNotFound = '';

        newDataFilter = data;

        if (filterStatus && filterStatus !== 'ALL') {
            newDataFilter = newDataFilter.filter(
                item => item.status && item.status === filterStatus
            );

            if (!textNotFound && newDataFilter.length === 0)
                textNotFound = translateBillStatus(filterStatus);
        }

        if (filterDate && !isNaN(filterDate)) {
            newDataFilter = newDataFilter.filter(
                item =>
                    item.date &&
                    isSameMonthAndYear(FormatDateToFront(item.date), filterDate)
            );

            if (!textNotFound && newDataFilter.length === 0)
                textNotFound = GetMonthAndYearInWords(filterDate);
        }

        return { newDataFilter, textNotFound };
    };

    useEffect(() => {
        if (filterStatus || filterDate) {
            setCanClick(true);
        } else {
            setCanClick(false);
        }
    }, [filterStatus, filterDate]);

    return (
        <React.Fragment>
            <Transition
                loading={
                    queryPersonalDashboard.isLoading &&
                    queryPersonalDashboard.isFetching &&
                    queryViewUser.isLoading &&
                    queryViewUser.isFetching
                }
                newDesign={true}
            >
                <HeaderContainer>
                    <Breadcrumb
                        newDesign={true}
                        routes={[
                            {
                                label: t('QUOTAS.quotas'),
                                to: '/Quotas'
                            },
                            { label: t('USERS.users'), to: '/Quotas/Users' },
                            {
                                label: `${
                                    context === 'view'
                                        ? t('GENERAL.view')
                                        : t('GENERAL.manage')
                                } ${t('USERS.user')}`
                            }
                        ]}
                    />
                    <div className="container-headerNewDesign">
                        <h1 className={classes.titleNewDesign}>{`${
                            context === 'view'
                                ? t('GENERAL.view')
                                : t('GENERAL.manage')
                        } ${t('USERS.user')}`}</h1>
                    </div>
                </HeaderContainer>
                <ContentContainer>
                    <BlueHeaderContainer>
                        <>
                            <CollaboratorCard
                                values={{
                                    name: queryPersonalDashboard.data
                                        ? queryPersonalDashboard.data.name
                                        : '-',
                                    job: queryPersonalDashboard.data
                                        ? queryPersonalDashboard.data.job
                                        : '-',
                                    ruleset: queryPersonalDashboard.data
                                        ? queryPersonalDashboard.data.ruleset
                                        : '-',
                                    imageName: queryPersonalDashboard.data
                                        ? queryPersonalDashboard.data.imageName
                                        : ''
                                }}
                            />
                            <div className={styles.myCredit}>
                                <Typography
                                    variant={'RobotoRegular'}
                                    fontSize={16}
                                    textColor={'white'}
                                >
                                    {t('QUOTAS.creditBalance')}
                                </Typography>
                                <Typography
                                    variant={'RobotoBold'}
                                    fontSize={40}
                                    textColor={'white'}
                                >
                                    {`R$
                                ${
                                    queryPersonalDashboard.data
                                        ? moneyMask(
                                              valueToMoneyFloat(
                                                  queryPersonalDashboard.data
                                                      ? queryPersonalDashboard
                                                            .data.accountBalance
                                                      : 0.0
                                              )
                                          )
                                        : '0,00'
                                }
                                `}
                                </Typography>
                            </div>
                        </>
                    </BlueHeaderContainer>

                    <Box marginTop={'16px'}>
                        <Paper square style={{ padding: '24px 16px' }}>
                            <Grid container item spacing={2} xs={12}>
                                <Grid item xs={12}>
                                    <Typography
                                        variant={'RobotoBold'}
                                        fontSize={14}
                                        textColor={'primary'}
                                    >
                                        {t('QUOTAS.UserProfileInformation')}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <DialogUnitWithIcon
                                        head={t('GENERAL.moduleProfile')}
                                        Icon={
                                            queryViewUser.data &&
                                            queryViewUser.data
                                                .isActiveInQuota ? (
                                                <CheckCircleIcon
                                                    style={{
                                                        color: '#7FE46B'
                                                    }}
                                                    fontSize="md"
                                                />
                                            ) : (
                                                <ErrorIcon
                                                    style={{
                                                        color: '#F3222F'
                                                    }}
                                                    fontSize="md"
                                                />
                                            )
                                        }
                                        content={
                                            queryViewUser.data
                                                ? translateSystemStatus(
                                                      queryViewUser.data
                                                          .isActiveInQuota
                                                  )
                                                : '-'
                                        }
                                        tooltipContent={t(
                                            'QUOTAS.UserProfileTooltip'
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <DialogUnit
                                        head={t('QUOTAS.quoteValue')}
                                        content={`R$
                                        ${
                                            queryViewUser.data
                                                ? moneyMask(
                                                      valueToMoneyFloat(
                                                          queryViewUser.data
                                                              ? queryViewUser
                                                                    .data
                                                                    .jobPrice
                                                              : 0.0
                                                      )
                                                  )
                                                : '0,00'
                                        }
                                        `}
                                        tooltipContent={t(
                                            'QUOTAS.UserQuoteValueTooltip'
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputRadio
                                        context={
                                            !values.isActiveInQuota
                                                ? 'view'
                                                : context
                                        }
                                        label={t(
                                            'QUOTAS.generateBillToBeAPayee'
                                        )}
                                        value={values.isPayee}
                                        valueName={'isPayee'}
                                        options={[
                                            {
                                                value: 'YES',
                                                label: t('GENERAL.yesCapital')
                                            },
                                            {
                                                value: 'NO',
                                                label: t('GENERAL.noCapital')
                                            }
                                        ]}
                                        handleChange={handleChange}
                                    />
                                </Grid>

                                {hasChanged && values.isActiveInQuota ? (
                                    <Grid item xs={12}>
                                        <Buttons
                                            disabled={disableButton}
                                            isLoading={mutation.isLoading}
                                            onClick={handleSubmit}
                                            tipo="BN-blue"
                                            conteudo={t(
                                                'GENERAL.save'
                                            ).toUpperCase()}
                                        />
                                    </Grid>
                                ) : null}

                                <Grid item xs={12}>
                                    <Paper>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                backgroundColor: '#f5f6fa',
                                                paddingLeft: '16px',
                                                height: '48px'
                                            }}
                                        >
                                            <Typography
                                                variant={'RobotoBold'}
                                                fontSize={14}
                                                textColor="secondary"
                                            >
                                                {t(
                                                    'QUOTAS.listOfBills'
                                                ).toUpperCase()}
                                            </Typography>
                                            {context !== 'view' &&
                                            GetGeneralPermissionForAction(
                                                Screen.QUOTAS_USERS,
                                                Allow.CREATE
                                            ) ? (
                                                <div
                                                    className={
                                                        styles.addBillWrapper
                                                    }
                                                >
                                                    <Typography
                                                        variant={'RobotoBold'}
                                                        fontSize={14}
                                                        textColor="secondary"
                                                    >
                                                        {`${t(
                                                            'GENERAL.add'
                                                        ).toUpperCase()} ${t(
                                                            'QUOTAS.bill'
                                                        ).toUpperCase()}`}
                                                    </Typography>
                                                    <IconButton
                                                        onClick={() =>
                                                            setOpenAddBill(true)
                                                        }
                                                    >
                                                        <AddCircleIcon />
                                                    </IconButton>
                                                </div>
                                            ) : null}
                                        </div>

                                        <Transition
                                            loading={
                                                queryPersonalBills.isLoading &&
                                                queryPersonalBills.isFetching
                                            }
                                            newDesign={true}
                                        >
                                            <FilterContainer>
                                                <InputFilter
                                                    filterValue={filterStatus}
                                                    setFilter={setFilterStatus}
                                                    label={'GENERAL.status'}
                                                    isSelect
                                                    arraySelected={
                                                        BillsStatusArray
                                                    }
                                                />
                                                <InputFilterDate
                                                    filterValue={filterDate}
                                                    setFilter={setFilterDate}
                                                    label={'GENERAL.date'}
                                                    monthAndYear
                                                    minDate={
                                                        new Date(2013, 11, 1)
                                                    }
                                                    maxDate={new Date()}
                                                />
                                            </FilterContainer>
                                            <React.Fragment>
                                                <UserBillsTable
                                                    rows={dataFilter}
                                                    toFirstPage={canClick}
                                                    headers={[
                                                        'id',
                                                        'date',
                                                        'status',
                                                        'value',
                                                        'user',
                                                        'verificationCode',
                                                        'operator',
                                                        'justification',
                                                        'closedAt'
                                                    ]}
                                                    visibleHeaders={[
                                                        'Data',
                                                        'Status',
                                                        'Valor',
                                                        ''
                                                    ]}
                                                    sortedFields={[
                                                        'date',
                                                        'status',
                                                        'value'
                                                    ]}
                                                    context={context}
                                                />

                                                {!hasItens &&
                                                    !queryPersonalBills.error && (
                                                        <div
                                                            className={
                                                                classes.noDataWrapper
                                                            }
                                                        >
                                                            <h2>
                                                                {t(
                                                                    'QUOTAS.userNoBillsFound'
                                                                )}
                                                            </h2>
                                                        </div>
                                                    )}
                                                {hasNoResult &&
                                                    !queryPersonalBills.error && (
                                                        <div
                                                            className={
                                                                classes.noParticipantsMessage
                                                            }
                                                        >
                                                            <div>
                                                                <Dissatisfied
                                                                    className={
                                                                        classes.notFoundIconStyle
                                                                    }
                                                                />
                                                            </div>
                                                            <h2>
                                                                {t(
                                                                    'GENERAL.noResultsFound'
                                                                )}
                                                            </h2>
                                                            <h5>{`Sua busca por "${findText}" não retornou resultados.`}</h5>
                                                        </div>
                                                    )}
                                                {queryPersonalBills.error && (
                                                    <div
                                                        className={
                                                            classes.noParticipantsMessage
                                                        }
                                                    >
                                                        <div>
                                                            <Dissatisfied
                                                                className={
                                                                    classes.notFoundIconStyle
                                                                }
                                                            />
                                                        </div>
                                                        <h2>
                                                            {
                                                                'Houve um erro de conexão com o Servidor'
                                                            }
                                                        </h2>
                                                    </div>
                                                )}
                                            </React.Fragment>
                                            {openAddBill && (
                                                <DialogAddBill
                                                    open={openAddBill}
                                                    setOpen={setOpenAddBill}
                                                    viewValues={
                                                        queryViewUser.data
                                                    }
                                                />
                                            )}
                                        </Transition>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>
                </ContentContainer>
                <Footer />
            </Transition>
        </React.Fragment>
    );
};

export default UsersForm;
