import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import Buttons from 'components/buttons/Pressed';
import Typography from 'components/typography/Typography';
import { screenStyles } from '../../Styles';
import { AllAuthorsName } from 'utils/library/AllAuthorsName';
import { formatImagePath } from 'utils/library/formatImagePath';
import AwaitingListTable from './AwaitingListTable';
import { sortByDate } from 'utils/sort/sortByDate';
import Paper from '@material-ui/core/Paper';
import {
    DialogUnit,
    DialogImage,
    DialogWarningTip
} from 'pages/libraryModule/dialogs/DialogUtils';
import Link from 'react-router-dom/Link';
import { LibraryQueueStatusValues } from 'global/constants';

const DialogAwaitingList = props => {
    const styles = screenStyles();
    const [t] = useTranslation();
    const { open, setOpen, viewValues } = props;

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth={'sm'}
                fullWidth={true}
            >
                <DialogTitle>
                    <div className={styles.dialogTitle}>
                        <Typography
                            variant={'RobotoBold'}
                            fontSize={24}
                            textColor={'secondary'}
                        >
                            {t('LIBRARY.dialogAwaitingListTitle')}
                        </Typography>
                        <IconButton
                            color="#6D6F71"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <DialogWarningTip
                        text={t(`LIBRARY.dialogAwaitingListTip`)}
                        margin={'0 0 32px'}
                    />

                    <div className={styles.dialogBookRequisitionContainer}>
                        <div className={styles.dialogContent}>
                            <DialogUnit
                                head={t('INVENTORY.title').toLocaleUpperCase()}
                                content={viewValues.title}
                            />
                            <DialogUnit
                                head={t(
                                    'INVENTORY.BookAuthor'
                                ).toLocaleUpperCase()}
                                content={AllAuthorsName(viewValues.authors)}
                            />
                            <DialogUnit
                                head={t(
                                    'INVENTORY.category'
                                ).toLocaleUpperCase()}
                                content={viewValues.category.name}
                            />
                        </div>

                        <DialogImage
                            image={formatImagePath(
                                viewValues.imagePath,
                                viewValues.isImageUrl
                            )}
                            alt={viewValues.title}
                        />
                    </div>

                    <Paper
                        style={{
                            marginTop: '24px',
                            maxHeight: '220px',
                            overflow: 'auto'
                        }}
                    >
                        <div
                            className={`cabecalho ${styles.bookCollectionHeader}`}
                        >
                            {t('LIBRARY.dialogAwaitingListOrder').toUpperCase()}
                        </div>
                        <AwaitingListTable
                            rows={sortByDate(
                                viewValues.queue.filter(
                                    item =>
                                        item.status ===
                                        LibraryQueueStatusValues.IN_QUEUE
                                ),
                                'requestDate',
                                true
                            )}
                            toFirstPage={true}
                            headers={['id', 'user', 'requestDate']}
                            visibleHeaders={['Colaborador', 'Data da reserva']}
                            sortedFields={['user', 'requestDate']}
                        />
                    </Paper>
                </DialogContent>

                <DialogActions className={styles.dialogButtonsWrapper}>
                    <div style={{ marginRight: '16px' }}>
                        <Buttons
                            className={styles.dialogButton}
                            onClick={handleClose}
                            tipo="borderBackButton"
                            conteudo={t('GENERAL.close').toUpperCase()}
                        />
                    </div>

                    <Link to={`/Library/AwaitingList/${viewValues.id}`}>
                        <Buttons
                            style={{ width: '100%' }}
                            className={styles.dialogButton}
                            tipo="BN-blue"
                            conteudo={'GERENCIAR LISTA'}
                        />
                    </Link>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DialogAwaitingList;
