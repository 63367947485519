import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { useStyles } from 'components/layout/CommonStyles';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import Buttons from 'components/buttons/Pressed';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { dateValidation } from 'utils/validation/DateValidation';
import { dateToBR } from 'utils/dates/DateToBRr';
import { screenStyles } from './Styles';

const DialogDateVacation = props => {
    const classes = useStyles();
    const styles = screenStyles();
    const [t] = useTranslation();
    const { open, setOpen, breakPeriod, setParentValues, setBreakPeriodText } =
        props;

    const [values, setValues] = useState({
        start: null,
        end: null
    });

    const [requiredStartDate, setRequiredStartDate] = useState(false);
    const [invalidStartDate, setInvalidStartDate] = useState(false);

    const [requiredEndDate, setRequiredEndDate] = useState(false);
    const [invalidEndDate, setInvalidEndDate] = useState(false);

    useEffect(() => {
        setValues({
            ...values,
            start: breakPeriod ? breakPeriod.start : null,
            end: breakPeriod ? breakPeriod.end : null
        });
    }, [breakPeriod]);

    const clearDialog = () => {
        setRequiredStartDate(false);
        setInvalidStartDate(false);

        setRequiredEndDate(false);
        setInvalidEndDate(false);
        setValues({
            ...values,
            start: breakPeriod ? breakPeriod.start : null,
            end: breakPeriod ? breakPeriod.end : null
        });
    };

    const handleClose = () => {
        setOpen(false);
        clearDialog();
    };

    const handleChange = name => e => {
        setRequiredStartDate(false);
        setInvalidStartDate(false);

        setRequiredEndDate(false);
        setInvalidEndDate(false);
        setValues({ ...values, [name]: e });
    };

    const verifySubmit = () => {
        let res = true;
        if (values.start === null) {
            res = false;
            setRequiredStartDate(true);
        }
        if (!dateValidation(values.start)) {
            res = false;
            setInvalidStartDate(true);
        }
        if (values.end === null) {
            res = false;
            setRequiredEndDate(true);
        }
        if (!dateValidation(values.end)) {
            res = false;
            setInvalidEndDate(true);
        }

        if (
            values.start &&
            values.end &&
            values.start.getTime() > values.end.getTime()
        ) {
            res = false;
            setInvalidStartDate(true);
        }

        return res;
    };

    const handleSubmit = () => {
        const canSubmit = verifySubmit();
        if (canSubmit) {
            setParentValues(old => ({
                ...old,
                start: values.start,
                end: values.end
            }));
            setBreakPeriodText(
                dateToBR(values.start) + ' - ' + dateToBR(values.end)
            );
            setOpen(false);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle className={classes.Title}>
                <b>{t('HR.breakPeriod')}</b>
                <IconButton
                    style={{ float: 'right', marginTop: -10, marginRight: -17 }}
                    edge="end"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Typography>
                    <b>{t('HR.intervalFilter')}</b>
                </Typography>
                <Grid container item spacing={2} md={12} lg={12}>
                    <Grid item xs={12} sm={1}>
                        <div style={{ marginTop: '18px' }}>
                            <Typography className={classes.headerColor}>
                                <b>De</b>
                            </Typography>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={5}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                className={classes.identificationDatePicker}
                                error={requiredStartDate || invalidStartDate}
                                helperText={
                                    requiredStartDate
                                        ? t('GENERAL.requiredField')
                                        : invalidStartDate
                                        ? t('GENERAL.invalidDate')
                                        : ''
                                }
                                disableToolbar
                                autoOk={true}
                                variant="inline"
                                format="dd/MM/yyyy"
                                id="validity"
                                margin="normal"
                                invalidDateMessage=""
                                label={t('GENERAL.initialDate') + '*'}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date'
                                }}
                                value={values.start}
                                onChange={handleChange('start')}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} sm={1}>
                        <div className={styles.dialogBreakPeriodTextAlign}>
                            <Typography className={classes.headerColor}>
                                <b>A</b>
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                className={classes.identificationDatePicker}
                                error={requiredEndDate || invalidEndDate}
                                helperText={
                                    requiredEndDate
                                        ? t('GENERAL.requiredField')
                                        : invalidEndDate
                                        ? t('GENERAL.invalidDate')
                                        : ''
                                }
                                disableToolbar
                                autoOk={true}
                                variant="inline"
                                format="dd/MM/yyyy"
                                id="validity"
                                margin="normal"
                                invalidDateMessage=""
                                label={t('GENERAL.finalDate') + '*'}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date'
                                }}
                                value={values.end}
                                onChange={handleChange('end')}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Buttons
                    onClick={handleClose}
                    tipo="BN-noback"
                    conteudo={t('GENERAL.cancel').toUpperCase()}
                />
                <Buttons
                    onClick={handleSubmit}
                    tipo="BN-blue"
                    conteudo={t('GENERAL.apply').toUpperCase()}
                />
            </DialogActions>
        </Dialog>
    );
};

export default DialogDateVacation;
