import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Breadcrumb from 'components/layout/breadcrumb/Breadcrumb';
import Transition from 'components/layout/transition/Transition';
import FilterContainer from 'components/containers/FilterContainer';
import HeaderContainer from 'components/containers/HeaderContainer';
import ContentContainer from 'components/containers/ContentContainer';
import InputFilter from 'components/inputs/InputFilter';
import Link from 'react-router-dom/Link';
import UsersTable from './UsersTable';
import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core';
import Dissatisfied from '@material-ui/icons/SentimentVeryDissatisfied';
import Footer from 'components/layout/Footer';
import { useStyles } from 'components/layout/CommonStyles';
import {
    SystemAccessStatusArray,
    translateSystemAccessStatus
} from './ScreenUtils';
import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';
import { Screen, Allow } from 'global/constants';
import { useQueryUsersAdmin } from 'hooks/DataFetching/Admin/Users/useQueryUsersAdmin';
import { CPFMask } from 'utils/masks/CPFMask';
import { screenStyles } from './Styles';
import { QueryGetValue } from 'utils/general/QueryString';

const Users = () => {
    const classes = useStyles();
    const styles = screenStyles();
    const [t] = useTranslation();

    const [findText, setFindText] = useState('');
    const [filterCollaborator, setFilterCollaborator] = useState('');
    const [filterEmail, setFilterEmail] = useState('');
    const [filterCPF, setFilterCPF] = useState('');
    const [filterSystemAccess, setFilterSystemAccess] = useState('ALL');
    const [dataFilter, setDataFilter] = useState([]);

    const [canClick, setCanClick] = useState(false);

    const { isLoading, isFetching, error, data } = useQueryUsersAdmin();

    const hasItens = data && data.length > 0;
    const [hasNoResult, setHasNoResult] = useState(
        hasItens && dataFilter && dataFilter.length === 0
    );

    const [emailQuery, setEmailQuery] = useState('');

    useEffect(() => {
        if (data && data.length > 0) {
            const { newDataFilter, textNoFound } = applyFilter(
                data.filter(x => !x.hasDeleted)
            );

            if (newDataFilter.length === 0) {
                setDataFilter([]);
                setHasNoResult(true);
                setFindText(textNoFound);
            } else {
                setDataFilter(newDataFilter);
                setHasNoResult(false);
                setFindText('');
            }

            setDataFilter(newDataFilter);
        }
    }, [filterCollaborator, filterEmail, filterCPF, filterSystemAccess, data]);

    const applyFilter = data => {
        let newDataFilter = [];
        let textNoFound = '';

        newDataFilter = data;

        if (filterCollaborator) {
            newDataFilter = newDataFilter.filter(
                item =>
                    item.name &&
                    item.name
                        .toLowerCase()
                        .includes(filterCollaborator.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterCollaborator;
        }

        if (filterEmail) {
            newDataFilter = newDataFilter.filter(
                item =>
                    item.username &&
                    item.username
                        .toLowerCase()
                        .includes(filterEmail.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterEmail;
        }

        if (filterCPF) {
            newDataFilter = newDataFilter.filter(
                item =>
                    item.cpf &&
                    item.cpf.toLowerCase().includes(filterCPF.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterCPF;
        }

        if (
            (filterSystemAccess || filterSystemAccess == false) &&
            filterSystemAccess !== 'ALL'
        ) {
            newDataFilter = newDataFilter.filter(
                item => item.enabled.toString() === filterSystemAccess
            );
            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = translateSystemAccessStatus(filterSystemAccess);
        }

        return { newDataFilter, textNoFound };
    };

    useEffect(() => {
        if (
            filterCollaborator ||
            filterEmail ||
            filterCPF ||
            filterSystemAccess
        ) {
            setCanClick(true);
        } else {
            setCanClick(false);
        }
    }, [filterCollaborator, filterEmail, filterCPF, filterSystemAccess]);

    useEffect(() => {
        setEmailQuery(QueryGetValue('email'));
    }, []);

    useEffect(() => {
        if (emailQuery) {
            setFilterEmail(emailQuery);
        }
    }, [emailQuery]);

    return (
        <React.Fragment>
            <Transition loading={isLoading && isFetching} newDesign={true}>
                <HeaderContainer>
                    <Breadcrumb
                        newDesign={true}
                        routes={[
                            {
                                label: t('ADMIN.Administrative'),
                                to: '/Admin'
                            },
                            { label: t('USERS.users') }
                        ]}
                    />
                    <div className="container-headerNewDesign">
                        <h1 className={classes.titleNewDesign}>
                            {t('USERS.users')}
                        </h1>

                        {GetGeneralPermissionForAction(
                            Screen.ADMIN_USERS,
                            Allow.CREATE
                        ) && (
                            <Link to={`/Admin/Users/Create`}>
                                <Button
                                    className={classes.blueButton}
                                    variant="contained"
                                    color="primary"
                                    style={{ marginBottom: '10px' }}
                                >
                                    {`${t('GENERAL.add')} ${t('GENERAL.user')}`}
                                </Button>
                            </Link>
                        )}
                    </div>
                </HeaderContainer>
                <ContentContainer>
                    <Paper>
                        <div className="headerContent">
                            {t('QUOTAS.listOfUsers').toUpperCase()}
                        </div>
                        <FilterContainer>
                            <InputFilter
                                filterValue={filterCollaborator}
                                setFilter={setFilterCollaborator}
                                label={'GENERAL.collaborator'}
                            />

                            <InputFilter
                                filterValue={filterEmail}
                                setFilter={setFilterEmail}
                                label={'GENERAL.email'}
                            />

                            <InputFilter
                                filterValue={filterCPF}
                                setFilter={setFilterCPF}
                                label={'PERSONAL.CPF'}
                                type={'cpf'}
                                mask={CPFMask}
                                maxLength={14}
                            />

                            <InputFilter
                                filterValue={filterSystemAccess}
                                setFilter={setFilterSystemAccess}
                                label={'GENERAL.systemAccess'}
                                isSelect
                                arraySelected={SystemAccessStatusArray}
                            />
                        </FilterContainer>

                        <React.Fragment>
                            <UsersTable
                                rows={dataFilter}
                                toFirstPage={canClick}
                                headers={[
                                    'id',
                                    'name',
                                    'username',
                                    'cpf',
                                    'enabled',
                                    'createdAt',
                                    'shortname',
                                    'imageName',
                                    'dateOfBirth',
                                    'userRegistrationStatus'
                                ]}
                                visibleHeaders={[
                                    'Colaborador',
                                    'E-mail',
                                    'CPF',
                                    'Acesso ao Sistema',
                                    'Data de Inclusão no Sistema',
                                    ''
                                ]}
                                sortedFields={[
                                    'name',
                                    'username',
                                    'cpf',
                                    'enabled',
                                    'createdAt'
                                ]}
                                mainField="createdAt"
                            />

                            {!hasItens && !error && (
                                <div className={styles.noJustificationsWrapper}>
                                    <h2>{t('Admin.noUsersAdminMessage')}</h2>
                                    <h4>{t('Admin.noUsersAdminSubMessage')}</h4>
                                </div>
                            )}
                            {hasNoResult && !error && (
                                <div className={classes.noParticipantsMessage}>
                                    <div>
                                        <Dissatisfied
                                            className={
                                                classes.notFoundIconStyle
                                            }
                                        />
                                    </div>
                                    <h2>{t('GENERAL.noResultsFound')}</h2>
                                    <h5>{`Sua busca por "${findText}" não retornou resultados.`}</h5>
                                </div>
                            )}
                            {error && (
                                <div className={classes.noParticipantsMessage}>
                                    <div>
                                        <Dissatisfied
                                            className={
                                                classes.notFoundIconStyle
                                            }
                                        />
                                    </div>
                                    <h2>
                                        {
                                            'Houve um erro de conexão com o Servidor'
                                        }
                                    </h2>
                                </div>
                            )}
                        </React.Fragment>
                    </Paper>
                </ContentContainer>
                <Footer />
            </Transition>
        </React.Fragment>
    );
};

export default Users;
