import axios from 'axios';
import { useQuery } from 'react-query';
import Cookies from 'js-cookie';

export const useQueryLSBDBillingSheetMonth = (options, date) => {
    return useQuery(['LSBDBillingSheet'], async () => {
        const { data } = await axios.get(
            process.env.REACT_APP_SERVER +
                `api/Quota/Bills/Monthly/BillingSheet?year=${date.getFullYear()}&month=${
                    date.getMonth() + 1
                }`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        return data;
    });
};
