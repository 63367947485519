import React from "react";
import "../../App.css";
import DashboardNavBar from "../layout/DashboardNavBar";
import SideBar from "../layout/sidebar/Sidebar";
import ExternalUsers from "./ExternalUsers";

const CallExternalUsers = ({ match }) => {

  return (
    <div>
      <DashboardNavBar />
      <SideBar />
      <ExternalUsers />
    </div>
  );
}

export default CallExternalUsers;