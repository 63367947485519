import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogUnit from 'components/dialogs/DialogUnit';
import DialogUnitWithIcon from 'components/dialogs/DialogUnitWithIcon';
import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import Buttons from 'components/buttons/Pressed';
import Typography from 'components/typography/Typography';
import Avatar from '@material-ui/core/Avatar';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InputFile from 'components/inputs/InputFile';
import { FormatDateToFront } from 'utils/dates/FormatDateToFront';
import { dateToBRWithHours } from 'utils/dates/DateToBrWithHours';
import { valueToMoneyFloat } from 'utils/general/valueToMoneyFloat';
import { moneyMask } from 'utils/masks/moneyMask';
import {
    translateCreditSolicitationsStatus,
    CreditSolicitationStatus
} from 'pages/quotasModule/dashboard/ScreenUtils';
import { screenStyles } from 'pages/quotasModule/dashboard/Styles';
import { useMutationRefuseCreditSolicitation } from 'hooks/DataFetching/Quotas/Dashboard/useMutationRefuseCreditSolicitation';
import Input from 'components/inputs/Input';
import { dateToBR } from 'utils/dates/DateToBRr';

const DialogRefuseCreditSolicitations = props => {
    const styles = screenStyles();
    const [t] = useTranslation();

    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const { open, setOpen, viewValues } = props;
    const mutationRefuse = useMutationRefuseCreditSolicitation(setOpen);
    const [requiredJustification, setRequiredJustification] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const [values, setValues] = useState({
        justification: ''
    });

    const handleChange = (name, value) => {
        let val = value;
        setValues({ ...values, [name]: val });
    };

    const verifySubmit = () => {
        let res = true;
        // TODO: Make a middleware to check when user are not logged more
        if (!auth) {
            res = false;
            window.location.reload();
        }
        if (!values.justification.trim()) {
            res = false;
            setRequiredJustification(true);
        }

        return res;
    };

    const handleSubmit = () => {
        const canSubmit = verifySubmit();
        if (canSubmit) {
            let formData = new FormData();

            formData.append('id', viewValues.id);
            formData.append('operatorId', auth.id ? auth.id : '');
            formData.append(
                'justification',
                values.justification ? values.justification : ''
            );

            mutationRefuse.mutate(formData);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            maxWidth={'sm'}
            fullWidth
        >
            <DialogTitle>
                <Grid container item spacing={2} xs={12}>
                    <Grid item xs={11}>
                        <Typography
                            variant={'RobotoBold'}
                            fontSize={24}
                            textColor={'secondary'}
                        >
                            {`${t('GENERAL.refuse')} ${t(
                                'QUOTAS.creditSolicitation'
                            )}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            color="#6D6F71"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent>
                <Grid container item spacing={1} xs={12}>
                    <Grid item xs={6}>
                        <DialogUnitWithIcon
                            head={t('GENERAL.collaborator').toUpperCase()}
                            Icon={
                                <Avatar
                                    className={styles.dialogViewBillAvatar}
                                    alt={viewValues.user.shortname}
                                    src={
                                        process.env.REACT_APP_IMGSERVER +
                                        viewValues.user.imageName
                                    }
                                />
                            }
                            content={viewValues.user.name}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <DialogUnit
                            head={t('GENERAL.solicitationDate').toUpperCase()}
                            content={dateToBRWithHours(
                                FormatDateToFront(viewValues.createdAt)
                            )}
                            marginTop={'16px'}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <DialogUnit
                            head={t('GENERAL.value').toUpperCase()}
                            content={`R$
                                    ${moneyMask(
                                        valueToMoneyFloat(viewValues.value)
                                    )}
                                    `}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <DialogUnit
                            head={t('GENERAL.dateOfPay').toUpperCase()}
                            content={dateToBR(
                                FormatDateToFront(viewValues.date)
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <div style={{ margin: '12px 0 16px' }}>
                            <DialogUnitWithIcon
                                head={t('GENERAL.status').toUpperCase()}
                                Icon={
                                    viewValues.status ===
                                    CreditSolicitationStatus.APPROVED ? (
                                        <CheckCircleIcon
                                            style={{
                                                color: '#7FE46B'
                                            }}
                                            fontSize="md"
                                        />
                                    ) : viewValues.status ===
                                      CreditSolicitationStatus.PENDING ? (
                                        <AccessTimeIcon fontSize="md" />
                                    ) : viewValues.status ===
                                          CreditSolicitationStatus.REFUSED ||
                                      viewValues.status ===
                                          CreditSolicitationStatus.CANCELLED ? (
                                        <ErrorIcon
                                            style={{
                                                color: '#F3222F'
                                            }}
                                            fontSize="md"
                                        />
                                    ) : null
                                }
                                content={translateCreditSolicitationsStatus(
                                    viewValues.status
                                )}
                            />
                        </div>
                    </Grid>

                    {viewValues.receiptFilename && viewValues.receiptPath && (
                        <Grid item xs={12}>
                            <div style={{ marginBottom: '16px' }}>
                                <InputFile
                                    context={'view'}
                                    fileName={viewValues.receiptFilename}
                                    filePath={viewValues.receiptPath}
                                    label={t('GENERAL.proof')}
                                    placeholder={t('GENERAL.noFile')}
                                />
                            </div>
                        </Grid>
                    )}

                    {viewValues.description ? (
                        <Grid item xs={12}>
                            <DialogUnit
                                head={t('GENERAL.observation').toUpperCase()}
                                content={viewValues.description}
                            />
                        </Grid>
                    ) : null}

                    <Grid item xs={12}>
                        <Input
                            required
                            label={'GENERAL.reason'}
                            value={values.justification}
                            valueName={'justification'}
                            rows={6}
                            maxLength={200}
                            handleChange={handleChange}
                            error={requiredJustification}
                            helperText={
                                requiredJustification &&
                                t('GENERAL.requiredField')
                            }
                            setRequired={setRequiredJustification}
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions style={{ padding: '24px 30px' }}>
                <Buttons
                    onClick={handleClose}
                    tipo={'BN-noback'}
                    conteudo={t('GENERAL.cancel').toUpperCase()}
                />
                <Buttons
                    isLoading={mutationRefuse.isLoading}
                    onClick={handleSubmit}
                    tipo="BN-blue"
                    conteudo={`${t('GENERAL.refuse').toUpperCase()} ${t(
                        'GENERAL.solicitation'
                    ).toUpperCase()}`}
                />
            </DialogActions>
        </Dialog>
    );
};

export default DialogRefuseCreditSolicitations;
