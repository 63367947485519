import React from "react";
import "../../../App.css";
import SideBar from "components/layout/sidebar/Sidebar";
import DashboardNavBar from "components/layout/DashboardNavBar";
import SoftwareCategory from "./SoftwareCategory";

const CallSoftwareCategoryCategory = ({ match }) => {
  return (
    <div>
      <DashboardNavBar />
      <SideBar />
      <SoftwareCategory />
    </div>
  );
}
export default CallSoftwareCategoryCategory;