import DateFnsUtils from '@date-io/date-fns';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Clear from '@material-ui/icons/Clear';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from '@material-ui/pickers';
import axios from 'axios';
import Buttons from 'components/buttons/Pressed';
import { useStyles } from 'components/layout/CommonStyles';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { phoneMask } from 'utils/masks/phoneMask';

const MaintenanceDialog = props => {
    const fileDownload = require('js-file-download');
    const [t] = useTranslation();
    const classes = useStyles();
    const {
        open,
        setOpen,
        valuesMaintenance,
        type,
        addMaintenancesToArray,
        itemId
    } = props;

    const [requiredStartDate, setRequiredStartDate] = useState(false);

    const [values, setValues] = useState({
        itemId: itemId,
        startDate: new Date(),
        endDate: new Date(),
        value: valuesMaintenance.value ? valuesMaintenance.value : '-',
        numberPhone: valuesMaintenance.numberPhone
            ? valuesMaintenance.numberPhone
            : '-',
        description: valuesMaintenance.description
            ? valuesMaintenance.description
            : '-',
        company: valuesMaintenance.company ? valuesMaintenance.company : '-',
        invoice: valuesMaintenance.invoice ? valuesMaintenance.invoice : '-',
        files: valuesMaintenance.maintenanceFiles
            ? valuesMaintenance.maintenanceFiles
            : valuesMaintenance.files
            ? valuesMaintenance.files
            : []
    });

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        // if(addMaintenancesToArray){
        //     addMaintenancesToArray(values)
        // }
        // handleClose()
    };

    const [toDeleteFile, setToDeleteFile] = useState([]);

    const onDropFiles = acceptedFiles => {
        let newFiles = [];
        let existentFiles = [];

        for (let i = 0; i < acceptedFiles.length; i++) {
            let hasTheSameName =
                dataState.files.filter(
                    el =>
                        (el.fileName
                            ? el.fileName.toLowerCase()
                            : el.name.toLowerCase()) ===
                        acceptedFiles[i].name.toLowerCase()
                ).length > 0;

            if (hasTheSameName) {
                existentFiles.push(
                    `Arquivo já existente - ${acceptedFiles[i].name}`
                );
            } else {
                newFiles.push(acceptedFiles[i]);
            }
        }

        setDataState({
            ...dataState,
            files: dataState.files.concat(newFiles),
            rejectedFiles: dataState.rejectedFiles.concat(existentFiles)
        });
    };

    const onRemoveFile = data => {
        if (data) {
            let remainingFiles = [];
            let updatedList = [];
            for (const file of dataState.files) {
                if (
                    file.path === data.path &&
                    file.lastModified === data.lastModified &&
                    file.size === data.size
                ) {
                    if (file.id) {
                        setToDeleteFile([...toDeleteFile, file.id]);
                        updatedList = dataState.rejectedFiles.filter(
                            el =>
                                el !== `Arquivo já existente - ${data.fileName}`
                        );
                    }
                    continue;
                }
                remainingFiles.push(file);
            }
            setDataState({
                ...dataState,
                files: remainingFiles,
                rejectedFiles: updatedList
            });
        }
    };

    function humanFileSize(size) {
        let i = Math.floor(Math.log(size) / Math.log(1024));
        return (
            (size / Math.pow(1024, i)).toFixed(2) * 1 +
            ' ' +
            ['B', 'kB', 'MB', 'GB', 'TB'][i]
        );
    }

    const deleteRejectedFile = rejectedFile => {
        let updatedList = dataState.rejectedFiles.filter(
            el => el !== rejectedFile
        );
        setDataState({ ...dataState, rejectedFiles: updatedList });
    };

    const onClickDownload = async file => {
        if (dataState && dataState.files.length > 0) {
            if (file.name) {
                fileDownload(file, file.name);
            } else {
                const noSlashPath = file.path.replace(/\//g, ';');
                const url =
                    process.env.REACT_APP_SERVER + 'api/file/' + noSlashPath;
                axios
                    .get(url, {
                        headers: {
                            'Content-type': 'application/json',
                            Authorization:
                                'bearer ' +
                                (Cookies.get('auth-token')
                                    ? Cookies.get('auth-token')
                                    : '')
                        },
                        responseType: 'blob'
                    })
                    .then(function (res) {
                        const blob = new Blob([res.data], {});
                        const name = file.fileName ? file.fileName : file.name;
                        fileDownload(blob, name);
                    });
            }
        }
    };

    const handleFieldChangeValue = (name, value) => {
        if (value !== ' ' && !value.includes(' ')) {
            const str = value.split('');
            let aux = str.filter(el => el !== '.' && el !== ',').join('');
            let valueFormatted = value;
            if (value) {
                valueFormatted = converTotBrCurrency(
                    aux.split('').reverse().join('')
                );
            }
            aux = valueFormatted
                .split('')
                .filter(el => el !== '.')
                .join('')
                .replace(',', '.');
            if (!isNaN(aux)) {
                setValues({ ...values, [name]: valueFormatted });
            }
        }
    };

    const converTotBrCurrency = number => {
        var temp = '';
        var len = number.length;
        var count = 0;

        for (var i = 0; i < len; i++) {
            if (i === 1) {
                var aux = ',' + number[i] + temp;
                temp = aux;
            } else {
                if (count === 3) {
                    count = 1;
                    var aux = number[i] + '.' + temp;
                    temp = aux;
                } else {
                    if (i > 1) {
                        count += 1;
                    }
                    var aux = number[i] + temp;
                    temp = aux;
                }
            }
        }
        return temp;
    };

    const [dataState, setDataState] = useState({
        files: valuesMaintenance.maintenanceFiles
            ? valuesMaintenance.maintenanceFiles
            : valuesMaintenance.files
            ? valuesMaintenance.files
            : [],
        rejectedFiles: []
    });

    const handleChange = (name, value) => {
        if (name === 'numberPhone') {
            setValues({ ...values, [name]: phoneMask(value) });
        } else {
            setValues({ ...values, [name]: value });
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            aria-labelledby="max-width-dialog-title"
            aria-labelledby="form-dialog-title"
            msg="mensagem"
            maxWidth="sm"
        >
            <DialogTitle
                className={classes.actionsTitle}
                style={{ width: 'auto' }}
            >
                <span style={{ fontWeight: 'bold' }}>
                    {t('INVENTORY.maintenanceRecord').toLocaleUpperCase()}
                </span>
                <IconButton
                    style={{ float: 'right', marginTop: -10, marginRight: -17 }}
                    edge="end"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Grid container spacing={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid item xs={12} sm={6}>
                                <KeyboardDatePicker
                                    disabled={type === 'view'}
                                    className={classes.textField}
                                    disableToolbar
                                    autoOk={true}
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    id="startDate"
                                    invalidDateMessage=""
                                    label={t('GENERAL.startDate') + '*'}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date'
                                    }}
                                    value={values.startDate}
                                    onChange={ev => {
                                        setRequiredStartDate(false);
                                        handleChange('startDate', ev);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <KeyboardDatePicker
                                    disabled={type === 'view'}
                                    className={classes.textField}
                                    disableToolbar
                                    autoOk={true}
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    id="endDate"
                                    invalidDateMessage=""
                                    label={t('GENERAL.endDate')}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date'
                                    }}
                                    value={values.endDate}
                                    onChange={ev => {
                                        handleChange('endDate', new Date(ev));
                                    }}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                disabled={type === 'view'}
                                className={classes.textField}
                                label={t('INVENTORY.value')}
                                value={values.value}
                                onChange={ev => {
                                    if (ev.target.value.length <= 20)
                                        handleFieldChangeValue(
                                            'value',
                                            ev.target.value
                                        );
                                }}
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                disabled={type === 'view'}
                                className={classes.textField}
                                label={t('INVENTORY.invoiceName')}
                                value={values.invoice}
                                onChange={ev => {
                                    if (ev.target.value.length <= 50)
                                        handleChange(
                                            'invoice',
                                            ev.target.value
                                        );
                                }}
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                disabled={type === 'view'}
                                className={classes.textField}
                                label={t('INVENTORY.company')}
                                value={values.company}
                                onChange={ev => {
                                    if (ev.target.value.length <= 50)
                                        handleChange(
                                            'company',
                                            ev.target.value
                                        );
                                }}
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                disabled={type === 'view'}
                                className={classes.textField}
                                label={t('INVENTORY.numberPhone')}
                                value={phoneMask(values.numberPhone)}
                                onChange={ev => {
                                    if (ev.target.value.length <= 15)
                                        handleChange(
                                            'numberPhone',
                                            ev.target.value
                                        );
                                }}
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                disabled={type === 'view'}
                                multiline
                                fullWidth
                                variant="outlined"
                                rows="6"
                                id="goal"
                                value={values.description}
                                label={t('INVENTORY.description')}
                                margin="normal"
                                onChange={ev => {
                                    if (ev.target.value.length <= 200)
                                        handleChange(
                                            'description',
                                            ev.target.value
                                        );
                                }}
                            />
                        </Grid>
                        <Grid container item spacing={1} md={12} lg={12}>
                            {type === 'edit' && (
                                <Grid item xs={12}>
                                    <Dropzone
                                        onDrop={onDropFiles}
                                        disabled={type === 'view'}
                                        accept="application/pdf,
                          application/msword,
                          application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                          image/jpeg,
                          image/png"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section>
                                                <div
                                                    {...getRootProps({
                                                        className:
                                                            classes.dropzoneDialog
                                                    })}
                                                >
                                                    <input
                                                        {...getInputProps()}
                                                    />
                                                    <AttachFileIcon />
                                                    <Typography>
                                                        Arraste e solte os
                                                        documentos para
                                                        anexá-los ou{' '}
                                                        <u>clique aqui</u>
                                                    </Typography>
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Typography
                                    variant={'body1'}
                                    style={{ fontWeight: 'bold' }}
                                >
                                    Documentos
                                </Typography>
                                {dataState.rejectedFiles.length > 0 && (
                                    <div style={{ paddingBottom: 8 }}>
                                        {dataState.rejectedFiles.map(
                                            message => (
                                                <div
                                                    className={
                                                        classes.dialogFile
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            classes.textFile
                                                        }
                                                    >
                                                        <Typography
                                                            style={{
                                                                color: 'red'
                                                            }}
                                                            className={
                                                                classes.nameFile
                                                            }
                                                        >
                                                            {message}
                                                        </Typography>
                                                    </div>
                                                    <div
                                                        className={
                                                            classes.iconsFile
                                                        }
                                                    >
                                                        <IconButton
                                                            onClick={() =>
                                                                deleteRejectedFile(
                                                                    message
                                                                )
                                                            }
                                                        >
                                                            <Clear />
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                )}
                                {dataState.files.length > 0 ? (
                                    <div
                                        className={classes.dropzoneDialogFiles}
                                    >
                                        {dataState.files.map(file => (
                                            <div className={classes.dialogFile}>
                                                <div
                                                    className={classes.textFile}
                                                >
                                                    <Typography
                                                        className={
                                                            classes.nameFile
                                                        }
                                                    >
                                                        {file.fileName
                                                            ? file.fileName
                                                            : file.name}
                                                    </Typography>
                                                    <Typography>{`(${humanFileSize(
                                                        file.size
                                                    )})`}</Typography>
                                                </div>
                                                <div
                                                    className={
                                                        classes.iconsFile
                                                    }
                                                >
                                                    {(file.fileName ||
                                                        file.name) && (
                                                        <IconButton
                                                            onClick={() =>
                                                                onClickDownload(
                                                                    file
                                                                )
                                                            }
                                                        >
                                                            <GetAppIcon />
                                                        </IconButton>
                                                    )}
                                                    {type !== 'view' && (
                                                        <IconButton
                                                            onClick={() =>
                                                                onRemoveFile(
                                                                    file
                                                                )
                                                            }
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className={classes.noFilesMessage}>
                                        <Typography
                                            variant={'h6'}
                                            style={{ fontWeight: 'bold' }}
                                        >
                                            {t('EXPENSES.noFilesMessage')}
                                        </Typography>
                                        <Typography variant={'caption'}>
                                            {t('EXPENSES.noFilesSubMessage')}
                                        </Typography>
                                    </div>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>
            {type !== 'view' && (
                <DialogActions>
                    <Buttons
                        onClick={handleClose}
                        tipo="BN-noback"
                        conteudo={t('GENERAL.cancel').toLocaleUpperCase()}
                    />
                    <Buttons
                        onClick={handleSubmit}
                        tipo="BN-blue"
                        conteudo={t('GENERAL.confirm').toLocaleUpperCase()}
                    />
                </DialogActions>
            )}
        </Dialog>
    );
};

export default MaintenanceDialog;
