import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { Link } from "react-router-dom";

const useStyles = makeStyles(() => ({
  lsbdLogo: {
    width: "56px",
    height: "33px",
    objectFit: "contain",
  },
  logoTitle: {
    width: 37,
    height: 17,
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 2.5,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#ffffff',
  }
}));

const LsbdImageIcon = ({ isHover }) => {
  const classes = useStyles();
  return (
    // <Link to={"/profiles"}>
    <Toolbar>
      <div><img src={window.location.origin + '/img/logo-lsbd@2x.png'} alt="logo" className={classes.lsbdLogo}></img></div>
      {isHover &&
        <Typography className={classes.logoTitle} >
          portal
        </Typography>
      }
    </Toolbar>
    // </Link>
  )
}

export default LsbdImageIcon;