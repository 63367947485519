import React from "react";
import "../../../App.css";
import DashboardNavBar from "../../layout/DashboardNavBar";
import SideBar from "../../layout/sidebar/Sidebar";
import View from "./View";

const CallView = ({ match }) => {
    return (
        <div>
            <DashboardNavBar />
            <SideBar />
            <View idUser={match.params.id} />
        </div>
    );
}

export default CallView;
