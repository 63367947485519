import React from "react";
import { pdf } from "@react-pdf/renderer";
import { TableColumnPlusAfter } from "mdi-material-ui";

/**
 * Generate a PDF from a React component
 * @param {*} template React component for the PDF template
 * @param {*} setLoading optional function to set loading state
 * @param {*} onSuccess function to execute when the PDF is generated successfully.
 * @param {*} onError  function to execute when the PDF generation fails
 * @returns Promise that resolves when the PDF is generated
 */
export async function generatePdf(template, setLoading, onSuccess, onError) {
    if (setLoading)
        setLoading(true);
    return pdf(template)
        .toBlob()
        .then(onSuccess)
        .catch(onError)
        .finally(() => {
            if (setLoading)
                setLoading(false);
        });
}

/**
 * Refetch a query and generate a PDF from the response
 * @param {*} templateFn function to generate the PDF template from the query response
 * @param {*} query query to refetch
 * @param {*} setLoading optional function to set loading state
 * @param {*} onSuccess function to execute when the PDF is generated successfully.
 * @param {*} onError function to execute when the PDF generation fails
 * @returns Promise that resolves when the PDF is generated
 */
export async function refetchAndGeneratePdf(templateFn, query, setLoading, onSuccess, onError) {
    if (setLoading)
        setLoading(true);
    return query
        .refetch()
        .then(response => {
            generatePdf(
                templateFn(response),
                setLoading,
                onSuccess,
                onError
            );
        })
        .catch(onError)
        .finally(() => {
            if (setLoading)
                setLoading(false);
        });
}
