import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DefaultInfo from 'components/Infos/DefaultInfo';
import { Grid, Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation, Trans } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import Buttons from 'components/buttons/Pressed';
import Typography from 'components/typography/Typography';

const DialogConfirmation = props => {
    const [t] = useTranslation();

    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const {
        open,
        setOpen,
        title,
        infoText,
        infoValues,
        principalText,
        principalValues,
        buttonText,
        mutate,
        isLoading,
        closeButtonText
    } = props;

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        mutate();
    };

    const GridSpace = (
        <Grid item xs={1} style={{ margin: '0 auto' }}>
            <Box display={'flex'} alignItems={'center'} minHeight="100%"></Box>
        </Grid>
    );

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle>
                <Grid container item spacing={2} xs={12}>
                    <Grid item xs={11}>
                        <Typography
                            variant={'RobotoBold'}
                            fontSize={24}
                            textColor={'secondary'}
                        >
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            color="#6D6F71"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent>
                <Grid
                    container
                    spacing={1}
                    xs={12}
                    style={{ margin: '0 auto' }}
                >
                    <Grid item xs={12} style={{ margin: '0 auto' }}>
                        <DefaultInfo
                            textTranslation={infoText}
                            valuesTranslation={infoValues}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Box marginBottom={'24px'} width={'100%'} />
                    </Grid>

                    <Grid item xs={11}>
                        <Typography
                            variant={'RobotoRegular'}
                            fontSize={17}
                            textColor={'secondary'}
                        >
                            <Trans
                                i18nKey={principalText}
                                values={principalValues}
                                components={[<b />]}
                            />
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions style={{ padding: '24px 30px' }}>
                <Buttons
                    onClick={handleClose}
                    tipo={'BN-noback'}
                    conteudo={
                        closeButtonText
                            ? closeButtonText
                            : t('GENERAL.cancel').toUpperCase()
                    }
                />
                <Buttons
                    isLoading={isLoading}
                    onClick={handleSubmit}
                    tipo="BN-blue"
                    conteudo={buttonText}
                />
            </DialogActions>
        </Dialog>
    );
};

export default DialogConfirmation;
