import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Visibility from "@material-ui/icons/Visibility";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";
import DialogMyActivity from "./DialogMyActivity"

const MyActivitiesTableAction = props => {
    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }
    const classes = useStyles();
    const [t] = useTranslation();
    const {  item, index, activityCategoryArray } = props;
    const [dialogContext ,setDialogContext] = useState("");
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = (context) => {
        setDialogContext(context);
        setOpenDialog(true);
    } 


    return (
        <React.Fragment>
            <Tooltip title={t("GENERAL.view")} aria-label="details" placement="top">
                <IconButton className={classes.iconsTableActions} onClick={() => handleOpenDialog("view")} >
                    <Visibility />
                </IconButton>
            </Tooltip>        
            {openDialog && 
                <DialogMyActivity
                    index={index}
                    open={openDialog}
                    setOpen={setOpenDialog}
                    activityCategoryArray={activityCategoryArray}
                    itemValues={item}
                    context={dialogContext}
                />
            }
        </React.Fragment>
    )
};


export default MyActivitiesTableAction;