import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import PartnersContext from "context/partners/partnersContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Buttons from "../buttons/Pressed";
import axios from "axios";
import history from "../../history";
import CloseIcon from '@material-ui/icons/Close';
import Cookies from "js-cookie";

const useStyles = makeStyles(theme => ({
  icons: {
    opacity: "0"
  },
  Title: {
    fontFamily: "Roboto",
    fontSize: "20px",
    textAlign: "left",
    color: "#000000",
    fontWeight: "bold"
  }
}));

const ActionsPartners = props => {
  const classes = useStyles();
  const [t] = useTranslation();
  const partnersContext = useContext(PartnersContext);
  const { deletePartner, response, responseType } = partnersContext;
  const [open, setOpen] = useState(false);
  const { item, name, permission } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    deletePartner(item.id, item.active);
    setOpen(false);
  };

  useEffect(() => {
    return () => { };
  }, [response, responseType]);

  const verifyEdit = () => {
    axios.get(process.env.REACT_APP_SERVER + `api/partners/${item.id}`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
      }
    })
      .then(res => {
        if (res.statusText === "OK") {
          history.push(`/partners/${item.id}/edit`);
        } else {
          deletePartner(item.id);
        }
      })
      .catch(error => {
        deletePartner(item.id);
      });
  }

  const verifyView = () => {
    axios.get(process.env.REACT_APP_SERVER + `api/partners/${item.id}`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
      }
    })
      .then(res => {
        if (res.statusText === "OK") {
          history.push(`/partners/${item.id}`);
        } else {
          deletePartner(item.id);
        }
      })
      .catch(error => {
        deletePartner(item.id);
      });
  }

  return (
    <React.Fragment>
      {permission["allowRead"] &&
        <Tooltip title="Visualizar" aria-label="details" placement="top">
          <IconButton className={classes.icons} onClick={verifyView}>
            <Visibility />
          </IconButton>
        </Tooltip>
      }{permission["allowUpdate"] &&
        <Tooltip title="Editar" aria-label="edit" placement="top">
          <IconButton className={classes.icons} onClick={verifyEdit}>
            <Edit />
          </IconButton>
        </Tooltip>
      }{permission["allowDelete"] &&
        <Tooltip title="Deletar" aria-label="delete" placement="top">
          <IconButton className={classes.icons} onClick={handleClickOpen}>
            <Delete />
          </IconButton>
        </Tooltip>
      }
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        msg="mensagem"
      >
        <DialogTitle className={classes.Title}>
          {t("GENERAL.delete")}
          <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("GENERAL.deleteMessageM")} {t("PARTNERS.partner")} "{name}" ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Buttons
            onClick={handleClose}
            tipo="BN-noback"
            conteudo={t("GENERAL.cancel").toUpperCase()}
          />
          <Buttons
            onClick={handleDelete}
            tipo="BN-blue"
            conteudo={t("GENERAL.delete").toUpperCase()}
          />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ActionsPartners;
