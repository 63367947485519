/* eslint-disable */
import Paper from '@material-ui/core/Paper';
import CollaboratorContext from 'context/hrModule/collaborator/collaboratorContext';
import React, { useContext, useEffect, useState } from 'react';
import history from 'history.js';
import { useTranslation } from 'react-i18next';
import { animated, useTransition } from 'react-spring';
import { useStyles } from 'components/layout/CommonStyles';
import Grid from '@material-ui/core/Grid';
import Input from 'components/inputs/Input';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import InputImageUser from 'components/inputs/InputImageUser';
// import DialogReqApprove from "./DialogReqApprove";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { dateToBR } from 'utils/dates/DateToBRr';
import { FormatDateToDataBase } from 'utils/dates/FormatDateToDataBase';
import { FormatDateToFront } from 'utils/dates/FormatDateToFront';
import Transition from 'components/layout/transition/Transition';
import Buttons from 'components/buttons/Pressed';
import DialogCancelOperation from 'components/dialogs/DialogCancelOperation';
import { Rulesets } from 'global/constants';
import { getPisExists } from 'requests/getPisExists';
import { useMutationUpdateUserImage } from 'hooks/DataFetching/HR/Collaborator/useMutationUpdateUserImage';

const IdentificationForm = props => {
    const defaultImage = window.location.origin;
    const classes = useStyles();
    const [t] = useTranslation();
    const collaboratorContext = useContext(CollaboratorContext);
    const { id, userId, context, myInformations, ...others } = props;
    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const [hasChange, setHasChange] = useState(false);

    const {
        loading,
        informationIdentification,
        getInformationIdentification,
        setLoading,
        setLoadingFalse,
        updateInformationIdentification,
        addInformationIdentification
    } = collaboratorContext;

    const [imageFile, setImageFile] = useState([]);

    const [requiredNationality, setRequiredNationality] = useState(false);
    const [requiredPlaceOfBirth, setRequiredPlaceOfBirth] = useState(false);
    const [requiredRgExpeditionDate, setRequiredRgExpeditionDate] =
        useState(false);
    const [requiredRG, setRequiredRG] = useState(false);
    const [requiredissuingBody, setRequiredIssuingBody] = useState(false);
    const [duplicatePis, setDuplicatePis] = useState(false);
    const [openCancelDialog, setOpenCancelDialog] = useState(false);

    const hasImageChanged = imageFile.length > 0 ? true : false;

    const [values, setValues] = useState({
        id: '',
        imagePath: '',
        name: '',
        email: '',
        birthday: '',
        gender: '',
        nationality: '',
        placeOfBirth: '',
        rg: '',
        issuingBody: '',
        cpf: '',
        pis: '',
        workNumber: '',
        serialNumberWork: ''
    });

    const [dates, setDates] = useState({
        rgExpeditionDate: null,
        workExpeditionDate: null
    });

    async function fetchData(userId) {
        setLoading();
        await getInformationIdentification(userId);
        setLoadingFalse();
    }

    const mutation = useMutationUpdateUserImage(() => fetchData(userId));

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData(userId);
    }, []);

    useEffect(() => {
        if (informationIdentification) {
            setValues({
                id: informationIdentification.id
                    ? informationIdentification.id
                    : null,
                imagePath: informationIdentification.imagePath
                    ? informationIdentification.imagePath
                    : '',
                name: informationIdentification.name
                    ? informationIdentification.name
                    : '',
                email: informationIdentification.email
                    ? informationIdentification.email
                    : '',
                birthday: informationIdentification.dateOfBirth
                    ? dateToBR(
                          FormatDateToFront(
                              informationIdentification.dateOfBirth
                          )
                      )
                    : '',
                gender: informationIdentification.gender
                    ? informationIdentification.gender
                    : '',
                nationality: informationIdentification.nationalityCountry
                    ? informationIdentification.nationalityCountry
                    : '',
                placeOfBirth: informationIdentification.nationalityState
                    ? informationIdentification.nationalityState
                    : '',
                rg: informationIdentification.rg
                    ? informationIdentification.rg
                    : '',
                issuingBody: informationIdentification.emitterOrgan
                    ? informationIdentification.emitterOrgan
                    : '',
                cpf: informationIdentification.cpf
                    ? informationIdentification.cpf
                    : '',
                pis: informationIdentification.pis
                    ? informationIdentification.pis
                    : '',
                workNumber: informationIdentification.workPermitNumber
                    ? informationIdentification.workPermitNumber
                    : '',
                serialNumberWork:
                    informationIdentification.workPermitSerialNumber
                        ? informationIdentification.workPermitSerialNumber
                        : ''
            });
            setDates({
                rgExpeditionDate:
                    informationIdentification.expeditionDate &&
                    informationIdentification.expeditionDate !==
                        '0001-01-01T00:00:00'
                        ? FormatDateToFront(
                              informationIdentification.expeditionDate
                          )
                        : null,
                workExpeditionDate:
                    informationIdentification.workPermitExpeditionDate
                        ? FormatDateToFront(
                              informationIdentification.workPermitExpeditionDate
                          )
                        : null
            });
        }
    }, [informationIdentification.length, informationIdentification]);

    const handleChange = (name, value) => {
        setHasChange(true);
        if (name === 'pis') {
            setDuplicatePis(false);
        }
        setValues({ ...values, [name]: value });
    };

    const handleDates = name => e => {
        setHasChange(true);
        setDates({
            ...dates,
            [name]: e
        });
        setRequiredRgExpeditionDate(false);
    };

    const handleCancel = () => {
        if (hasChange) {
            setOpenCancelDialog(true);
        } else {
            if (myInformations) {
                history.push('/HumanResources');
            } else {
                history.push('/Collaborators');
            }
        }
    };

    const handleApply = async () => {
        if (myInformations) {
            let formData = new FormData();

            formData.append('id', userId);
            formData.append('Image', imageFile ? imageFile[0] : null);
            formData.append('DeleteImage', imageFile ? true : false);

            mutation.mutate(formData);
        } else {
            const canApply = await verifyApply();
            if (canApply) {
                let formData = new FormData();

                formData.append(
                    `identification[0].gender`,
                    values.id === null
                        ? values.gender === 'MALE'
                            ? true
                            : false
                        : values.gender
                        ? values.gender
                        : ''
                );
                if (values.id === null) {
                    formData.append(`identification[0].version`, 0);
                }
                formData.append(
                    `identification[0].nationalityCountry`,
                    values.nationality ? values.nationality : ''
                );
                formData.append(
                    `identification[0].nationalityState`,
                    values.placeOfBirth ? values.placeOfBirth : ''
                );
                formData.append(
                    `identification[0].rg`,
                    values.rg ? values.rg : ''
                );
                formData.append(
                    `identification[0].emitterOrgan`,
                    values.issuingBody ? values.issuingBody : ''
                );
                formData.append(
                    `identification[0].pis`,
                    values.pis ? values.pis : ''
                );
                formData.append(
                    `identification[0].workPermitNumber`,
                    values.workNumber ? values.workNumber : ''
                );
                formData.append(
                    `identification[0].workPermitSerialNumber`,
                    values.serialNumberWork ? values.serialNumberWork : ''
                );
                formData.append(`identification[0].userId`, userId);
                formData.append(
                    `identification[0].expeditionDate`,
                    FormatDateToDataBase(new Date(dates.rgExpeditionDate))
                );
                formData.append(
                    `identification[0].workPermitExpeditionDate`,
                    dates.workExpeditionDate
                        ? FormatDateToDataBase(
                              new Date(dates.workExpeditionDate)
                          )
                        : ''
                );
                formData.append(
                    'identification[0].Image',
                    imageFile ? imageFile[0] : null
                );
                formData.append(
                    'identification[0].DeleteImage',
                    imageFile.length > 0 ? true : false
                );

                if (values.id === null) {
                    addInformationIdentification(formData, fetchData, userId);
                } else {
                    updateInformationIdentification(
                        values.id,
                        formData,
                        fetchData,
                        userId
                    );
                }
            }
        }
    };

    const verifyApply = async () => {
        let res = true;
        if (dates.rgExpeditionDate === null) {
            res = false;
            setRequiredRgExpeditionDate(true);
        }
        if (!values.nationality) {
            res = false;
            setRequiredNationality(true);
        }
        if (!values.placeOfBirth) {
            res = false;
            setRequiredPlaceOfBirth(true);
        }
        if (!values.rg) {
            res = false;
            setRequiredRG(true);
        }
        if (!values.issuingBody) {
            res = false;
            setRequiredIssuingBody(true);
        }
        if (values.pis) {
            await getPisExists(userId, values.pis).then(response => {
                const duplicatePisExists = response.data;
                if (duplicatePisExists) {
                    res = false;
                    setDuplicatePis(duplicatePisExists);
                }
            });
        }

        return res;
    };

    return (
        <React.Fragment>
            <Transition loading={loading} newDesign={true}>
                <Paper>
                    <React.Fragment>
                        <div className={classes.identificationFormHeader}>
                            <div className={classes.identificationFrame}>
                                <InputImageUser
                                    context={myInformations ? 'edit' : context}
                                    file={imageFile}
                                    setFile={setImageFile}
                                    imageUrl={
                                        values.imagePath
                                            ? process.env.REACT_APP_IMGSERVER +
                                              values.imagePath
                                            : null
                                    }
                                />
                            </div>
                            <Grid container item spacing={0} md={12} lg={12}>
                                <Grid item xs={12} sm={10}>
                                    <Input
                                        context={'view'}
                                        label={'PERSONAL.name'}
                                        value={values.name}
                                        valueName={'name'}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={10}>
                                    <Input
                                        context={'view'}
                                        label={'PERSONAL.email'}
                                        value={values.email}
                                        valueName={'email'}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={7}>
                                    <Input
                                        context={'view'}
                                        label={'PERSONAL.birthday'}
                                        value={values.birthday}
                                        valueName={'birthday'}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <div className={classes.identificationForm}>
                            <Grid
                                container
                                item
                                spacing={3}
                                md={12}
                                lg={12}
                                style={{ paddingRight: '150px' }}
                            >
                                <Grid item xs={12} sm={12}>
                                    <FormControl
                                        component="fieldset"
                                        style={{ marginTop: '18px' }}
                                    >
                                        <FormLabel component="legend">
                                            {t('PERSONAL.sex')}
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            aria-label="position"
                                            disabled={context === 'view'}
                                            name="position"
                                            defaultValue="top"
                                            value={values.gender}
                                            onChange={ev =>
                                                handleChange(
                                                    'gender',
                                                    ev.target.value
                                                )
                                            }
                                        >
                                            <FormControlLabel
                                                disabled={context === 'view'}
                                                value="MALE"
                                                control={
                                                    <Radio color="primary" />
                                                }
                                                label={t('PERSONAL.male')}
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                disabled={context === 'view'}
                                                value="FEMALE"
                                                control={
                                                    <Radio color="primary" />
                                                }
                                                label={t('PERSONAL.female')}
                                                labelPlacement="end"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Input
                                        context={context}
                                        required
                                        label={'PERSONAL.nationality'}
                                        value={values.nationality}
                                        error={requiredNationality}
                                        helperText={
                                            requiredNationality &&
                                            t('GENERAL.requiredField')
                                        }
                                        setRequired={setRequiredNationality}
                                        valueName={'nationality'}
                                        maxLength={35}
                                        handleChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Input
                                        context={context}
                                        required
                                        error={requiredPlaceOfBirth}
                                        helperText={
                                            requiredPlaceOfBirth &&
                                            t('GENERAL.requiredField')
                                        }
                                        setRequired={setRequiredPlaceOfBirth}
                                        label={'PERSONAL.placeOfBirth'}
                                        value={values.placeOfBirth}
                                        valueName={'placeOfBirth'}
                                        maxLength={35}
                                        handleChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Input
                                        context={context}
                                        type={'number'}
                                        required
                                        error={requiredRG}
                                        helperText={
                                            requiredRG &&
                                            t('GENERAL.requiredField')
                                        }
                                        setRequired={setRequiredRG}
                                        label={'PERSONAL.rg'}
                                        value={values.rg}
                                        valueName={'rg'}
                                        maxLength={15}
                                        handleChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                    >
                                        <KeyboardDatePicker
                                            disabled={context === 'view'}
                                            error={requiredRgExpeditionDate}
                                            helperText={
                                                requiredRgExpeditionDate &&
                                                t('GENERAL.requiredField')
                                            }
                                            className={
                                                classes.identificationDatePicker
                                            }
                                            disableToolbar
                                            autoOk={true}
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            id="validity"
                                            margin="normal"
                                            invalidDateMessage=""
                                            label={
                                                t('PERSONAL.expeditionDate') +
                                                '*'
                                            }
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date'
                                            }}
                                            value={dates.rgExpeditionDate}
                                            onChange={handleDates(
                                                'rgExpeditionDate'
                                            )}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Input
                                        context={context}
                                        required
                                        error={requiredissuingBody}
                                        helperText={
                                            requiredissuingBody &&
                                            t('GENERAL.requiredField')
                                        }
                                        setRequired={setRequiredIssuingBody}
                                        label={'PERSONAL.issuer'}
                                        value={values.issuingBody}
                                        valueName={'issuingBody'}
                                        maxLength={10}
                                        handleChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <Input
                                        context={'view'}
                                        label={'PERSONAL.CPF'}
                                        value={values.cpf}
                                        valueName={'cpf'}
                                        handleChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Input
                                        context={context}
                                        label={t('PERSONAL.pis')}
                                        value={values.pis}
                                        valueName={'pis'}
                                        maxLength={15}
                                        type="number"
                                        error={duplicatePis}
                                        helperText={t(
                                            'HR.identificationErrorDuplicatePis'
                                        )}
                                        handleChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Input
                                        context={context}
                                        type={'number'}
                                        label={'PERSONAL.workNumber'}
                                        value={values.workNumber}
                                        valueName={'workNumber'}
                                        maxLength={20}
                                        handleChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                    >
                                        <KeyboardDatePicker
                                            disabled={context === 'view'}
                                            className={
                                                classes.identificationDatePicker
                                            }
                                            disableToolbar
                                            autoOk={true}
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            id="validity"
                                            margin="normal"
                                            invalidDateMessage=""
                                            label={t('PERSONAL.expeditionDate')}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date'
                                            }}
                                            value={dates.workExpeditionDate}
                                            onChange={handleDates(
                                                'workExpeditionDate'
                                            )}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <Input
                                        context={context}
                                        type={'number'}
                                        label={'PERSONAL.serialNumberWork'}
                                        value={values.serialNumberWork}
                                        valueName={'serialNumberWork'}
                                        maxLength={20}
                                        handleChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        {(context !== 'view' ||
                            (myInformations && hasImageChanged)) && (
                            <div
                                style={{
                                    paddingLeft: '10px',
                                    paddingBottom: '40px',
                                    paddingTop: '40px'
                                }}
                            >
                                <Buttons
                                    onClick={handleCancel}
                                    tipo="BN-noback"
                                    conteudo={t('GENERAL.cancel')}
                                />
                                <Buttons
                                    onClick={handleApply}
                                    tipo="BN-blue"
                                    conteudo={
                                        myInformations
                                            ? t('GENERAL.save')
                                            : t('GENERAL.apply')
                                    }
                                />
                            </div>
                        )}
                    </React.Fragment>
                </Paper>

                <DialogCancelOperation
                    open={openCancelDialog}
                    setOpen={setOpenCancelDialog}
                    path={'/Collaborators'}
                    dialogTitle={t('GENERAL.cancelEdit')}
                    dialogText={t('GENERAL.cancelEditText')}
                />
            </Transition>
        </React.Fragment>
    );
};

export default IdentificationForm;
