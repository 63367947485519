import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
    StyledTableCellWhite,
    StyledTableRow,
    useStyles
} from 'components/profiles/ProfileStyles';
import { dateToBR } from 'utils/dates/DateToBRr';
import { FormatDateToFront } from 'utils/dates/FormatDateToFront';
import Typography from 'components/typography/Typography';
import Actions from './AwaitingListTableActions';

function compareString(a, b) {
    if (
        b
            .toString()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .trim()
            .toLowerCase() <
        a
            .toString()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .trim()
            .toLowerCase()
    ) {
        return -1;
    }
    if (
        b
            .toString()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .trim()
            .toLowerCase() >
        a
            .toString()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .trim()
            .toLowerCase()
    ) {
        return 1;
    }
    return 0;
}

function desc(a, b, orderBy) {
    if (a[orderBy] === null) a[orderBy] = '';
    if (b[orderBy] === null) b[orderBy] = '';

    if (a[orderBy] === undefined) a[orderBy] = '';
    if (b[orderBy] === undefined) b[orderBy] = '';

    if (orderBy === 'requestDate') {
        return compareString(a['requestDate'], b['requestDate']);
    }

    if (orderBy === 'user') {
        return compareString(a['user'].shortname, b['user'].shortname);
    }

    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc'
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
}

function createContent(rows, headers, order, orderBy) {
    const rowsCopy = [];
    var obj = {};
    for (var i = 0; i < rows.length; i++) {
        for (var j = 0; j < headers.length; j++) {
            obj[headers[j]] = rows[i][headers[j]];
        }
        rowsCopy.push(obj);
        obj = {};
    }
    return stableSort(rowsCopy, getSorting(order, orderBy));
}

const AwaitingListTable = props => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [rows, setRows] = useState({});
    const [headers, setHeaders] = useState([]);
    const [visibleHeaders, setVisibleHeaders] = useState([]);
    const [sortedFields, setSortedFields] = useState([]);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');

    React.useEffect(() => {
        setHeaders(props.headers);
        setRows(props.rows);
        setVisibleHeaders(props.visibleHeaders);
        setSortedFields(props.sortedFields);
        adjustRowsPerpage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.headers, props.mainField, props.rows, props.visibleHeaders]);

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const adjustRowsPerpage = () => {
        if (props.rows.length >= 10) {
            setRowsPerPage(10);
        } else {
            setRowsPerPage(props.rows.length);
        }
    };

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === sortedFields[property] && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(sortedFields[property]);
    };

    const AwaitingListTableHead = props => {
        const { order, orderBy, onRequestSort } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    {visibleHeaders.map((header, index) => (
                        <StyledTableCellWhite
                            key={index}
                            size="small"
                            align="left"
                            sortDirection={
                                orderBy === sortedFields[index] ? order : false
                            }
                        >
                            <TableSortLabel
                                active={orderBy === sortedFields[index]}
                                direction={order}
                                onClick={createSortHandler(index)}
                            >
                                {header.toUpperCase()}
                                {orderBy === sortedFields[index] ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc'
                                            ? 'sorted descending'
                                            : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCellWhite>
                    ))}
                </TableRow>
            </TableHead>
        );
    };

    AwaitingListTableHead.propTypes = {
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired
    };

    return (
        <Paper className={classes.root}>
            <div className={classes.tableWrapper}>
                <Table
                    style={{
                        minWidth: '0'
                    }}
                    className={classes.table}
                    size="small"
                >
                    {rows.length > 0 && (
                        <AwaitingListTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                    )}
                    <TableBody>
                        {createContent(rows, headers, order, orderBy).map(
                            row => (
                                <StyledTableRow
                                    style={{ height: '55px' }}
                                    key={row.id}
                                >
                                    <TableCell size="small">
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginTop: '6px'
                                            }}
                                        >
                                            <img
                                                src={
                                                    process.env
                                                        .REACT_APP_IMGSERVER +
                                                    row['user'].imageName
                                                }
                                                style={{
                                                    width: '32px',
                                                    height: '32px',
                                                    borderRadius: '36px',
                                                    marginRight: '10px',
                                                    objectFit: 'cover',
                                                    objectPosition: 'center'
                                                }}
                                                alt={row['user'].shortname}
                                            />
                                            <Typography
                                                variant={'RobotoRegular'}
                                                fontSize={14}
                                                textColor={'secondary'}
                                            >
                                                {row['user'].shortname}
                                            </Typography>
                                        </div>
                                    </TableCell>

                                    <TableCell size="small">
                                        {`${dateToBR(
                                            FormatDateToFront(
                                                row['requestDate']
                                            )
                                        )}`}
                                    </TableCell>

                                    <TableCell size="small" align="right">
                                        <div
                                            style={{
                                                marginTop: 'auto',
                                                marginBottom: 'auto',
                                                display: 'flex',
                                                flexWrap: 'nowrap ',
                                                justifyContent: 'flex-end'
                                            }}
                                        >
                                            <Actions
                                                item={row}
                                                bookData={props.bookData}
                                                submit={props.submit}
                                            />
                                        </div>
                                    </TableCell>
                                </StyledTableRow>
                            )
                        )}

                        {emptyRows > 0 && (
                            <TableRow style={{ height: 48 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
        </Paper>
    );
};

AwaitingListTable.propTypes = {
    rows: PropTypes.array.isRequired,
    headers: PropTypes.array.isRequired,
    visibleHeaders: PropTypes.array.isRequired,
    mainField: PropTypes.string.isRequired
};

export default AwaitingListTable;
