import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Edit from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";
import Delete from "@material-ui/icons/Delete";
import DialogDeleteSimple from "components/dialogs/DialogDeleteSimple.js";
import DialogMyActivity from "./DialogMyActivity"



const MyActivitiesTableAction = props => {
    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }
    const classes = useStyles();
    const [t] = useTranslation();
    const { item, index, activityCategoryArray , handleEditActivity , handleDeleteActivity, context } = props;
    const [open, setOpen] = useState(false);
    const [dialogContext ,setDialogContext] = useState("");
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = (context) => {
        setDialogContext(context);
        setOpenDialog(true);
    } 

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenDelete = () => {
        setOpen(true);
    };

    const handleDelete = (itemRef) => {
        setOpen(false)
        handleDeleteActivity(itemRef);
    }

    return (
        <React.Fragment>
            <Tooltip title={t("GENERAL.view")} aria-label="details" placement="top">
                <IconButton className={classes.iconsTableActions} onClick={() => handleOpenDialog("view")} >
                    <Visibility />
                </IconButton>
            </Tooltip>
            {context !== "view" &&
                <React.Fragment>
                    <Tooltip title="Editar" aria-label="edit" placement="top">
                        <IconButton className={classes.iconsTableActions} onClick={() => handleOpenDialog("edit")} >
                            <Edit />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Deletar" aria-label="delete" placement="top">
                        <IconButton className={classes.iconsTableActions} onClick={handleOpenDelete} >
                            <Delete />
                        </IconButton>
                    </Tooltip>

                    <DialogDeleteSimple
                        open={open}
                        setOpen={setOpen}
                        dialogTitle={t("GENERAL.delete") + " " + t("GENERAL.activity")}
                        dialogText={t("HR.deleteActivityMessage")}
                        dialogInfo={t("HR.toApplyNeedToSave")}
                        itemName={item.name}
                        itemRef={index}
                        handleDelete={handleDelete}
                        handleClose={handleClose}
                    >
                    </DialogDeleteSimple>
                    
                </React.Fragment>}
                {openDialog && 
                    <DialogMyActivity
                        index={index}
                        open={openDialog}
                        setOpen={setOpenDialog}
                        activityCategoryArray={activityCategoryArray}
                        itemValues={item}
                        context={dialogContext}
                        editActivity={handleEditActivity}
                    />
                }
        </React.Fragment>
    )
};


export default MyActivitiesTableAction;