/* eslint-disable */
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import Add from "@material-ui/icons/Add";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Footer from "components/layout/Footer";
import Spinner from "components/layout/spinners/Index";
import CustomPaginationActionsTable from "components/profiles/CustomPaginationActionsTable";
import AllowancesContext from "context/allowance/allowanceContext";
import ProfilesContext from "context/profiles/profilesContext";
import SearchContext from "context/search/searchContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import MySnackbarContentWrapper from "../layout/Message";
//import { useStyles } from "./ProfileStyles";
import { useStyles } from "../layout/CommonStyles";
import Dissatisfied from "@material-ui/icons/SentimentVeryDissatisfied";
import Paper from "@material-ui/core/Paper";

const Profiles = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const profilesContext = useContext(ProfilesContext);
  const allowanceContext = useContext(AllowancesContext);
  const searchContext = useContext(SearchContext);
  let dataFilter = [];
  const { filter } = searchContext;
  const [snackBar, setSnackBar] = useState({
    open: false,
    kind: "",
    content: ""
  });
  const {
    getProfiles,
    profiles,
    loading,
    response,
    hasChanged,
    setHasChanged,
    responseType,
    successiveActions
  } = profilesContext;

  const {
    getCurrentLoggedProfile,
    hasChangedAllowances,
    allowanceResponse,
    allowanceResponseType,
    formattedAllowances,
    messageHasChanged,
    resetAllowanceMessage,
    verifyPermission,
    permission
  } = allowanceContext;

  dataFilter = profiles;
  if (filter) {
    dataFilter = dataFilter.filter(
      item =>
        item.name.toLowerCase().startsWith(filter.toLowerCase()) ||
        item.description.toLowerCase().startsWith(filter.toLowerCase())
    );
  }
  const transitions = useTransition(loading, null, {
    from: { opacity: 0 },
    enter: {
      opacity: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "calc(100vh - 70px)"
    },
    leave: { opacity: 0 }
  });

  useEffect(() => {
    getProfiles();
    verifyPermission("PROFILE")
    //getCurrentLoggedProfile(hasChangedAllowances, formattedAllowances);
    if (allowanceResponseType === "ERROR" && messageHasChanged) {
      handleSnack({ kind: "error", content: allowanceResponse });
    }
    if (responseType === "SUCCESS" && hasChanged > successiveActions) {
      handleSnack({ kind: "success", content: response });
    }
    if (responseType === "ERROR" && hasChanged > successiveActions) {
      handleSnack({ kind: "error", content: response });
    }

    return () => { };
  }, [
    responseType,
    response,
    successiveActions,
    allowanceResponseType,
    allowanceResponse,
    permission.length
  ]);

  const handleSnack = () => {
    setSnackBar({ ...snackBar, open: true });
    setHasChanged();
  };
  const handleSnackClose = () => {
    setSnackBar({ ...snackBar, open: false });
    resetAllowanceMessage();
  };

  return transitions.map(({ item, key, props }) =>
    item ? (
      <animated.div key={key} style={props}>
        <Spinner />;
      </animated.div>
    ) : (
        <animated.div key={key} style={props}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={snackBar.open}
            onClose={handleSnackClose}
            TransitionComponent={Slide}
            transitionDuration={{ enter: 500, exit: 500 }}
            ContentProps={{
              "aria-describedby": "message-id"
            }}
            autoHideDuration={2000}
          >
            <MySnackbarContentWrapper
              onClose={handleSnackClose}
              variant={
                responseType !== ""
                  ? responseType.toLowerCase()
                  : allowanceResponseType.toLowerCase()
              }
              message={
                <span id="message-id">
                  {response !== "" ? response : allowanceResponse}
                </span>
              }
            />
          </Snackbar>
          <div className="container">
            <Breadcrumbs
              className={classes.breadCrumbs}
              aria-label="breadcrumb"
              separator={<NavigateNextIcon fontSize="small" />}
            >
              <Typography>{t("GENERAL.planning")}</Typography>
              <Typography>{t("GENERAL.configurations")}</Typography>
              <Typography>{t("PROFILES.profiles")}</Typography>
            </Breadcrumbs>

            <div className="container-header">
              <div className="title">
                <h1 className={classes.title}>{t("PROFILES.profiles")}</h1>
              </div>
              {permission["allowCreate"] && 
              <Link to={"/profiles/new"}>
                <Button
                  className={classes.blueButton}
                  variant="contained"
                  color="primary"
                >
                  <div className={classes.buttonIcon}>
                    <Add />
                  </div>
                  {t("GENERAL.add") + " " + t("PROFILES.profile")}
                </Button>
              </Link>
              }
              
            </div>
              <CustomPaginationActionsTable
                rows={dataFilter}
                headers={["id", "name", "description"]}
                visibleHeaders={["nome", "descrição"]}
                sortedFields={["name", "description"]}
                mainField="name"
                permission={permission}
              />
              <Paper>
              {(dataFilter.length === 0) &&
              <div className={classes.noParticipantsMessage}>
                <div >
                  <Dissatisfied className={classes.notFoundIconStyle} />
                </div>
                <h2>{t("GENERAL.noResultsFound")}</h2>
                <h5>{`Sua busca por "${filter}" não retornou resultados.`}</h5>
              </div>
              }
            </Paper>
            </div>
          <Footer />
        </animated.div>
      )
  );
};

export default Profiles;
