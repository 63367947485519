import { Text, View } from '@react-pdf/renderer';
import styles from './SectionHeader.styled';
import React from 'react'

/**
 * SectionHeader component
 * @param {string} text
 */
const SectionHeader = ({ style, children, ...props }) => {
    return (
        <View style={[styles.header, style]} {...props}>
            {children}
        </View>
    );
}

export default SectionHeader;
