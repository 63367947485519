import React from "react";
import { Text, View } from "@react-pdf/renderer";
import styles from "./Table.styled";

const Table = ({ columns, children, style, fixed, ...props }) => {
    return (
        <View style={[styles.table, style]} {...props}>
            <View style={styles.header} fixed={fixed ?? false}>
                {columns.map((column, index) => (
                    <View key={index} style={[styles.headerCell, { width: `${100 / columns.length}%` }]}>
                        <Text>{column.toUpperCase()}</Text>
                    </View>
                ))}
            </View>
            <View style={styles.body}>
                {children}
            </View>
        </View>
    )
}

export default Table;
