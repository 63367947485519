import React from 'react';
import { screenStyles } from './Styles';
import Typography from 'components/typography/Typography';
import Buttons from 'components/buttons/Pressed';
import { formatExemplarCode } from 'utils/library/formatExemplarCode';
import { translateStatus } from 'utils/library/translateStatus';
import { LibraryReservationStatusValues } from 'global/constants';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {
    CardVerticalComponent,
    CardImage,
    CardHorizontalComponent,
    CardTitle
} from 'pages/libraryModule/cards/CardsUtils';
import { translateLibrary } from 'utils/library/translateLibrary';

const BookCardLibraryLoans = props => {
    const {
        image,
        title,
        author,
        exemplarCode,
        status,
        renovationCount,
        returnDate,
        onClick,
        colaboratorName,
        library
    } = props;
    const styles = screenStyles();

    return (
        <React.Fragment>
            <div className={styles.cardContainer}>
                <CardImage image={image} alt={title} />
                <CardTitle title={title} />
                <CardVerticalComponent head={'Autor'} content={author} />
                <CardVerticalComponent
                    head={'Colaborador'}
                    content={colaboratorName}
                />
                <CardHorizontalComponent
                    head={'Acervo'}
                    content={translateLibrary(library)}
                />
                <CardHorizontalComponent
                    head={'Exemplar'}
                    content={formatExemplarCode('' + exemplarCode)}
                />

                <div className={styles.cardAmount}>
                    <Typography
                        className={styles.cardTopic}
                        variant={'RobotoRegular'}
                        fontSize={12}
                        textColor={'secondary'}
                    >
                        <b>Status: </b>
                        {translateStatus(status)}
                    </Typography>
                    {status === LibraryReservationStatusValues.LATE && (
                        <ErrorIcon
                            style={{
                                marginLeft: '0.2rem',
                                color: '#F3222F',
                                width: '16px',
                                height: '16px'
                            }}
                            fontSize="small"
                        />
                    )}
                    {status === LibraryReservationStatusValues.LOAN && (
                        <CheckCircleIcon
                            style={{
                                marginLeft: '0.2rem',
                                color: '#7FE46B',
                                width: '16px',
                                height: '16px'
                            }}
                            fontSize="small"
                        />
                    )}
                </div>

                <CardHorizontalComponent
                    head={'Renovações'}
                    content={renovationCount}
                />
                <CardHorizontalComponent
                    head={'Devolver em'}
                    content={returnDate}
                />

                <Buttons
                    style={{ marginTop: '12px' }}
                    onClick={onClick}
                    className={styles.cardButton}
                    tipo="BN-blue"
                    conteudo="REALIZAR DEVOLUÇÃO"
                />
            </div>
        </React.Fragment>
    );
};

export default BookCardLibraryLoans;
