import {webApi} from "../../api/config";

const basePath = "api/ruleSet"

export async function getAllRuleSets() {
    return webApi.get(`${basePath}`)
}

export async function getRuleSet(name) {
    return await webApi.get(`${basePath}/${name}`)
}