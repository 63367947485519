import { makeStyles } from '@material-ui/core/styles';

export const screenStyles = makeStyles(theme => ({
    dialogMoreDetailsText: {
        margin: '8px 0',
        lineHeight: '22px',
        wordWrap: 'break-word'
    },
    dialogUnitComponent: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    dialogUnitWithIconsText: {
        marginLeft: '8px'
    },
    dialogObservation: {
        padding: '8px',
        border: '1px solid #6d6f71',
        borderRadius: '4px',
        height: '75px',
        overflow: 'auto',
        marginTop: '8px',
        lineHeight: '18px',
        wordBreak: 'break-word'
    },
    tooltipWrapper: {
        display: 'flex',
        alignItems: 'center',
        '& >:first-child': {
            marginRight: '11px'
        }
    }
}));
