import React, { useContext, useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { useStyles } from "components/layout/CommonStyles";
import { useTranslation } from "react-i18next";
import CloseIcon from '@material-ui/icons/Close';
import Buttons from "components/buttons/Pressed";
import Download from "@material-ui/icons/GetApp";
import TextField from "@material-ui/core/TextField";
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from "@material-ui/core/Grid";
import Input from "components/inputs/Input";
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import InputFile from "components/inputs/InputModal";
import CollaboratorContext from "context/hrModule/collaborator/collaboratorContext";
import MessagePaper from "components/messagePaper/MessagePaper";
import Cookies from "js-cookie";


const DialogActionDocument = props => {
    const { open, setOpen, userId, document, fetchData, contextForm, setUpdateDocument } = props;
    const classes = useStyles();
    const [t] = useTranslation();
    const fileDownload = require("js-file-download");
    const collaboratorContext = useContext(CollaboratorContext);
    const blankField = "GENERAL.requiredField";

    const [values, setValues] = useState({
        id: "",
        filePath: "",
        fileName: "Anexar ofício",
        description: ""
    });

    const [newFile, setnewFile] = useState([]);
    const [justification, setJustification] = useState("");
    const [validJustification, setValidJustification] = useState(false);

    const [docType, setDocType] = useState("");
    const [url, setUrl] = useState("");

    const clearDialog = () => {
        setValues({
            id: "",
            filePath: "",
            fileName: "Anexar exame",
            description: ""
        });
    }

    useEffect(() => {
        setValues({
            id: document && document.id,
            description: document && document.description,
            fileName: document && document.documentName,
            filePath: document && document.path
        })

    }, [document]);

    const handleClose = () => {
        clearDialog();
        setOpen(false);
    }
    const handleChange = (name, value) => {
        let val = value;
        setValues({ ...values, [name]: val })
    }

    const onDrop = acceptedFiles => {
        if (acceptedFiles[0] !== undefined) {
            handleChange("newFileName", acceptedFiles[0].name)
            setnewFile(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
        setRequiredContract(false);

    };
    const [requiredContract, setRequiredContract] = useState(false);


    const verifySubmit = () => {
        let res = true;

        if (!justification) {
            res = false;
            setValidJustification(true);
        }

        if (newFile.length === 0) {
            res = false;
            //setContractHelperMessage(t("GENERAL.requiredField"));
            setRequiredContract(true);

        }
        return res;
    }

    const handleSubmit = () => {
        const canSubmit = verifySubmit();
        if (canSubmit) {
            setUpdateDocument(values, newFile, justification)
            let formData = new FormData();
            handleClose();
        }
    }

    const onClickDownload = async (slashPath) => {
        // if (dataState && dataState.files.length > 0) {
        if (slashPath) {
            const noSlashPath = slashPath.replace(/\//g, ";");
            const url = process.env.REACT_APP_SERVER + "api/file/;portal_lsbd;hr_files" + noSlashPath;
            axios.get(url, {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
                },
                responseType: "blob"
            }).then(function (res) {
                const blob = new Blob([res.data], {});
                // const name = file.fileName ? file.fileName : file.name;
                const name = slashPath.split("/").pop()
                fileDownload(blob, name);
            });
        }
    };

    useEffect(() => {
        if (values.filePath) {
            const noSlashPath = values.filePath && values.filePath.replace(/\//g, ";");
            const url = process.env.REACT_APP_SERVER + "api/file/;portal_lsbd;hr_files" + noSlashPath;
            axios.get(url, {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
                },
                responseType: "blob"
            }).then(function (res) {
                const blob = new Blob([res.data], { type: res.data.type });
                setDocType(res.data.type);
                setUrl(URL.createObjectURL(blob))
                const fileURL = URL.createObjectURL(blob);
                //Open the URL on new Window
                //window.open(fileURL);
            });
        }
    }, [values.filePath])

    return (
        <Dialog
            open={open}
            fullWidth={true}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
        >
            <DialogTitle className={classes.Title}>
                <b>{contextForm === "view" ? t("HR.viewDoc") : t("HR.editDoc")}</b>
                <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container item spacing={2} md={12} lg={12}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            disabled={contextForm === "view"}
                            variant="outlined"
                            label={contextForm === "view" ? t("HR.document") : t("HR.beforeDocument")}
                            autoComplete="off"
                            className={classes.textField}
                            value={values.fileName}
                            margin="normal"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput
                            }}
                            inputProps={{ "aria-label": "search" }}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => onClickDownload(values.filePath)}>
                                            <Download />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    {contextForm !== "view" &&
                        <Grid item xs={12} sm={12}>
                            <InputFile
                                onDrop={onDrop}
                                files={newFile}
                                label={"HR.newDocument"}
                                value={values.newFileName}
                                required={true}
                                error={requiredContract}
                                errorMessage={t("GENERAL.requiredField")}
                            />
                        </Grid>}

                    <Grid item xs={12} sm={12}>
                        <Input
                            context={contextForm}
                            label={"HR.description"}
                            value={values.description}
                            valueName={"description"}
                            rows={5}
                            maxLength={200}
                            handleChange={handleChange}

                        />
                    </Grid>
                    {contextForm === "edit" &&
                        <React.Fragment>
                            <Grid item xs={12} sm={12}>
                                <Typography className={classes.headerColor}><b>{t("HR.justificationTip")}</b></Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    error={validJustification}
                                    multiline
                                    fullWidth
                                    variant="outlined"
                                    rows="3"
                                    value={justification}
                                    label={t("INVENTORY.justification") + "*"}
                                    margin="normal"
                                    inputProps={{ maxLength: 100 }}
                                    helperText={validJustification ? t(blankField) : `${justification.length}/100`}
                                    onChange={(e) => {
                                        setValidJustification(false);
                                        setJustification(e.target.value)
                                    }}
                                />
                            </Grid>
                        </React.Fragment>
                    }

                    {contextForm === "view" &&
                        <Grid item xs={12} sm={12}>
                            <Typography className={classes.headerColor}><b>Pré-visualização</b></Typography>
                            {docType === "image/png" || docType === "image/jpg" ?
                                <img src={url} width='100%' height='565px' />
                                : docType === "application/pdf" ?
                                    <iframe src={url} width='100%' height='565px' frameborder='0' scrolling="no"></iframe>
                                    : docType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || docType === "application/vnd.ms-word" ?
                                        <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=URLEncode(${url})`} width='100%' height='565px' frameborder='0'> </iframe>
                                        :
                                        <MessagePaper
                                            headerText={t("HR.notSupportedDocType")}
                                            subText={"..."}
                                        />
                            }
                        </Grid>}
                </Grid>
            </DialogContent>
            {contextForm === "edit" &&
                <DialogActions>
                    <Buttons
                        onClick={handleClose}
                        tipo="BN-noback"
                        conteudo={t("GENERAL.cancel").toUpperCase()}
                    />
                    <Buttons
                        onClick={handleSubmit}
                        tipo="BN-blue"
                        conteudo={t("GENERAL.update").toUpperCase()}
                    />
                </DialogActions>}
            {contextForm === "view" &&
                <DialogActions>
                    <Buttons
                        onClick={handleClose}
                        tipo="BN-noback"
                        conteudo={t("GENERAL.close").toUpperCase()}
                    />
                </DialogActions>}
        </Dialog>
    )
}

export default DialogActionDocument;