
import React, { } from "react";
import { useStyles } from "components/layout/CommonStyles";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

const PhaseInformation = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const {
        phaseName,
        phaseStart,
        phaseEnd,
        phaseDescription,
        toAllocate
    } = props;

    return (
        <React.Fragment>
            <Paper className={classes.root}>
                <div className={classes.header}>
                    {t("PHASES.phaseInformation").toUpperCase()}
                </div>
                <div className={classes.bodyForm}>
                    <Grid container>
                        <Grid container item spacing={2} md={12} lg={12}>
                            <Grid item xs={6}>
                                <TextField
                                    disabled
                                    className={classes.textField}
                                    margin="normal"
                                    id="phaseName"
                                    label={t("PHASES.phaseName")}
                                    name={t("PHASES.phaseName")}
                                    value={phaseName}
                                />
                            </Grid>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid item xs={3}>
                                    <KeyboardDatePicker
                                        className={classes.textField}
                                        disabled
                                        disableToolbar
                                        autoOk={true}
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="phaseStartDate"
                                        label={t("GENERAL.startDate")}
                                        value={phaseStart}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <KeyboardDatePicker
                                        className={classes.textField}
                                        disabled
                                        disableToolbar
                                        autoOk={true}
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="phaseEndDate"
                                        label={t("GENERAL.endDate")}
                                        value={phaseEnd}
                                    />
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </Grid>
                        {toAllocate &&
                            <Grid container item spacing={2} md={12} lg={12}>
                                <Grid item xs={12}>
                                    <TextField
                                        disabled
                                        multiline
                                        fullWidth
                                        variant="outlined"
                                        rows="6"
                                        id="phaseDescription"
                                        value={phaseDescription}
                                        label={t("PROFILES.descriptionLabel")}
                                        margin="normal"
                                    />
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </div>
            </Paper>
            <br />
            <br />
        </React.Fragment>
    )
}

export default PhaseInformation;