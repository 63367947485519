import Button from "@material-ui/core/Button";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Add from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import Dissatisfied from "@material-ui/icons/SentimentVeryDissatisfied";
import { useStyles } from "components/layout/CommonStyles";
import AllowancesContext from "context/allowance/allowanceContext";
import ProjectsContext from "context/projects/projectsContext";
import 'moment/locale/pt-br';
import React, { useContext, useEffect, useState } from "react";
import 'react-day-picker/lib/style.css';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import MySnackbarContentWrapper from "../../layout/Message";
import ProjectSubsidesTable from "./SubsisdiesTable";

const SubsidiesIndex = props => {
    const { projectId, projectSubsides, mode, periodFilter, allAllowances } = props;
    const [filter, setFilter] = useState("");
    const [t] = useTranslation();
    const classes = useStyles();
    let dataFilter = [];
    const hasSubsidies = projectSubsides.length > 0;
    const [periodLabel, setPeriodLabel] = useState(periodFilter[0] ? periodFilter[0].label : "Todos");
    //const [season, setSeason] = useState(seasonsArray[seasonsArray.length - 1] ? seasonsArray[seasonsArray.length - 1].label : "");
    dataFilter = projectSubsides;

    const projectContext = useContext(ProjectsContext);
    const allowanceContext = useContext(AllowancesContext);
    const {
        response,
        responseType,
        successiveActions,
        hasChanged,
        setHasChanged } = projectContext;
    const {
        allowanceResponse,
        allowanceResponseType,
        messageHasChanged,
        resetAllowanceMessage
    } = allowanceContext;

    const [snackBar, setSnackBar] = useState({
        open: false,
        kind: "",
        content: ""
    });

    const renderTable = data => {
        return (
            <ProjectSubsidesTable
                rows={data}
                headers={["id", "total", "subsidyDate", "description", "numberOfQuotas"]}
                sortedFields={["total", "subsidyDate", " ", "numberOfQuotas"]}
                visibleHeaders={[
                    "VALOR DO APORTE",
                    "DATA DO APORTE",
                    "DESCRIÇÃO",
                    "NÚMERO DE PARCELAS"
                ]}
                projectId={projectId}
                mode={mode}
            />
        );
    };

    const [hasNoResult, setHasNoResult] = useState(hasSubsidies && dataFilter.length === 0);
    const [subsidiesTable, setSubsidiesTable] = useState(
        renderTable(dataFilter)
    );

    useEffect(() => {
        if (allowanceResponseType === "ERROR" && messageHasChanged) {
            handleSnack({ kind: "error", content: allowanceResponse });
        }
        if (responseType === "SUCCESS" && hasChanged > successiveActions) {
            handleSnack({ kind: "success", content: response });
        }
        if (responseType === "ERROR" && hasChanged > successiveActions) {
            handleSnack({ kind: "error", content: response });
        }
        return () => { };
    }, [
        responseType,
        response,
        successiveActions,
        allowanceResponseType,
        allowanceResponse
    ]);

    const handleSnack = () => {
        setSnackBar({ ...snackBar, open: true });
    };

    const handleSnackClose = () => {
        setSnackBar({ ...snackBar, open: false });
        setHasChanged();
        resetAllowanceMessage();
    };

    const converTotBrCurrency = number => {
        var temp = "";
        var len = number.length;
        var count = 0;

        for (var i = 0; i < len; i++) {
            if (i === 1) {
                var aux = "," + number[i] + temp;
                temp = aux;
            } else {
                if (count === 3) {
                    count = 1;
                    var aux = number[i] + "." + temp;
                    temp = aux;
                } else {
                    if (i > 1) {
                        count += 1;
                    }
                    var aux = number[i] + temp;
                    temp = aux;
                }
            }
        }

        return temp;
    };

    const convertLongToSearch = numberToString => {
        numberToString = numberToString.includes(".") ? numberToString : numberToString + ".00";
        numberToString = numberToString.split(".")[1].length < 2 ? numberToString + "0" : numberToString;

        const userCost = numberToString
            .split("")
            .filter(el => el !== ".")
            .reverse()
            .join("");

        return converTotBrCurrency(userCost);
    };

    const handleChangePeriod = event => {
        if (event.target.value === "Todos")
            dataFilter = projectSubsides;
        else {
            let periodId = periodFilter.filter(el => el.label === event.target.value)[0].value;
            dataFilter = projectSubsides.filter(el => el.addendumId === periodId);
        }

        if (filter) {
            dataFilter = dataFilter.filter(
                item =>
                    convertLongToSearch(item.total.toString()).toLowerCase().includes(filter.toLowerCase()) ||
                    item.description.toLowerCase().includes(filter.toLowerCase()) ||
                    item.numberOfQuotas.toString().toLowerCase().includes(filter.toLowerCase())
            );
        }

        if (dataFilter.length === 0)
            setHasNoResult(true);
        else
            setHasNoResult(false);

        setPeriodLabel(event.target.value);
        setSubsidiesTable(renderTable(dataFilter));
    };

    const handleSearch = event => {
        setFilter(event.target.value);

        if (periodLabel === "Todos")
            dataFilter = projectSubsides;
        else {
            let periodId = periodFilter.filter(el => el.label === periodLabel)[0].value;
            dataFilter = projectSubsides.filter(el => el.addendumId === periodId);
        }

        dataFilter = dataFilter.filter(
            item =>
                convertLongToSearch(item.total.toString()).toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.description.toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.numberOfQuotas.toString().toLowerCase().includes(event.target.value.toLowerCase())
        );

        if (dataFilter.length === 0)
            setHasNoResult(true);
        else
            setHasNoResult(false);

        setSubsidiesTable(renderTable(dataFilter));
    };

    const getPermission = (screen, allowance) => {
        for (let i = 0; i < allAllowances.length; i++) {
            if (allAllowances[i].resource === screen) {
            return allAllowances[i]["standardauthorizationoption"][allowance]
           }
        }
    }

    const hasAddendum = () => {
        if (periodFilter.length > 0) {
            if (mode === t("GENERAL.editMode")) {
                return (
                    <React.Fragment>
                    {allAllowances.length > 0 && getPermission("PHASES","allowCreate") && 
                        <Link to={`/execution/newSubsidy/${projectId}`}>
                            <Button style={{ margin: "22px", padding: "15px" }}>
                                <div className={classes.buttonIcon}>
                                    <Add />
                                </div>
                                {t("SUBSIDY.addSubsidy")}
                            </Button>
                        </Link>}
                    </React.Fragment>
                )
            }
        } else {
            return (
                <p className={classes.Hint}>
                    {t("PHASES.hintNoAddendum")}
                </p>
            )
        }
    };

    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={snackBar.open}
                onClose={handleSnackClose}
                TransitionComponent={Slide}
                transitionDuration={{ enter: 500, exit: 500 }}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                autoHideDuration={2000}
            >
                <MySnackbarContentWrapper
                    onClose={handleSnackClose}
                    variant={
                        responseType !== ""
                            ? responseType.toLowerCase()
                            : allowanceResponseType.toLowerCase()
                    }
                    message={
                        <span id="message-id">
                            {response !== "" ? response : allowanceResponse}
                        </span>
                    }
                />
            </Snackbar>
            <Paper>
                <div className={classes.divAddParticipant}>
                    <div className={classes.divAddParticipantLeft}>
                        <Toolbar className={classes.searchToolbar}>
                            <div style={{ display: !hasSubsidies ? "none" : "" }} className={classes.search}>
                                <div className={classes.searchIcon}>
                                    <SearchIcon />
                                </div>
                                <InputBase
                                    placeholder={t("SUBSIDY.subsidySearch")}
                                    value={filter}
                                    onChange={handleSearch}
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput
                                    }}
                                    inputProps={{ "aria-label": "search" }}
                                    style={{ width: "100%"}}
                                />
                            </div>
                        </Toolbar>
                        <div>
                            <TextField
                                style={{ display: !hasSubsidies ? "none" : "" }}
                                select
                                id="periodFilter"
                                label={t("PROJECTS.season")}
                                className={classes.textField, classes.fieldInputSearch}
                                name="periodFilter"
                                margin="normal"
                                value={periodLabel}
                                onChange={handleChangePeriod}
                            >
                                {periodFilter.map(option => (
                                    <MenuItem key={option.value} value={option.label}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </div>
                    {hasAddendum()}
                </div>
                <div className="lineBreak"></div>
                
                {hasSubsidies ? subsidiesTable :
                    <div className={classes.noParticipantsMessage}>
                        <h2>{t("SUBSIDY.noSubsidiesMessage")}</h2>
                        <h4>{t("SUBSIDY.noSubsidiesSubMessage")}</h4>
                    </div>
                }
                {hasNoResult &&
                    <div className={classes.noParticipantsMessage}>
                        <div >
                            <Dissatisfied className={classes.notFoundIconStyle} />
                        </div>
                        <h2>{t("GENERAL.noResultsFound")}</h2>
                        {filter ?
                            <h5>{`Sua busca por "${filter}" não retornou resultados.`}</h5> :
                            <h5>{`Sua busca não retornou resultados.`}</h5>
                        }
                    </div>
                }
            </Paper>
        </div>
    );
};

export default SubsidiesIndex;