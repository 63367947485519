import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from 'components/typography/Typography';

import { buttonsStyles } from './Styles';

const ContainedWithIconButton = ({ text, Icon, ...props }) => {
    const styles = buttonsStyles();
    return (
        <Button
            variant="contained"
            className={styles.containedWithIconButton}
            color="primary"
            {...props}
        >
            <div className={styles.centralize}>
                <Icon />
                <Typography
                    variant={'RobotoBold'}
                    fontSize={12}
                    textColor={'white'}
                >
                    {text}
                </Typography>
            </div>
        </Button>
    );
};

export default ContainedWithIconButton;
