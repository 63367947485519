import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {default as KeyboardArrowLeft} from "@material-ui/icons/KeyboardArrowLeft";
import {default as KeyboardArrowRight} from "@material-ui/icons/KeyboardArrowRight";
import PropTypes from "prop-types";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    StyledTableCell,
    StyledTableRow,
    useStyles
} from "components/profiles/ProfileStyles";
import {dateToBR} from "../../../../utils/dates/DateToBRr";


const useStyles1 = makeStyles(theme => ({
    root: {
        flexShrink: 0,
        flexGrow: 1,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2.5)
    },
    paginationTop: {
        float: "right"
    }
}));

const formartDate = (date) => {
    var dateformated = new Date(date)
    return dateToBR(dateformated)
  }

function desc(a, b, orderBy) {
    // .normalize("NFD").replace(/[\u0300-\u036f]/g, "") : desconsidera
    // acentos
    if (a[orderBy] === null) a[orderBy] = "";
    if (b[orderBy] === null) b[orderBy] = "";

    if (orderBy === "createdAt") {
        if (
            new Date(b[orderBy]).getTime() <
            new Date(a[orderBy]).getTime()
        ) {
            return -1;
        }
        if (
            new Date(b[orderBy]).getTime() >
            new Date(a[orderBy]).getTime()
        ) {
            return 1;
        }
        return 0;
    }

    if (typeof a[orderBy] === "object" && typeof b[orderBy] === "object") {
        if (
            b[orderBy]["name"].normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase() <
            a[orderBy]["name"].normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase()
        ) {
            return -1;
        }
        if (
            b[orderBy]["name"].normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase() >
            a[orderBy]["name"].normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase()
        ) {
            return 1;
        }
    } else {
        if (typeof a[orderBy] === "object") {
            if (
                b[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase() <
                a[orderBy]["name"].normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase()
            ) {
                return -1;
            }
            if (
                b[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase() >
                a[orderBy]["name"].normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase()
            ) {
                return 1;
            }
        } else if (typeof b[orderBy] === "object") {
            if (
                b[orderBy]["name"].normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase() <
                a[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase()
            ) {
                return -1;
            }
            if (
                b[orderBy]["name"].normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase() >
                a[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase()
            ) {
                return 1;
            }
        } else {
            if (
                b[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase() <
                a[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase()
            ) {
                return -1;
            }
            if (
                b[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase() >
                a[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase()
            ) {
                return 1;
            }
        }
    }

    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === "desc"
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
}

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const {count, page, rowsPerPage, onChangePage} = props;

    const handleBackButtonClick = event => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onChangePage(event, page + 1);
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight/>
                ) : (
                    <KeyboardArrowLeft/>
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft/>
                ) : (
                    <KeyboardArrowRight/>
                )}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired
};

function createContent(rows, headers, order, orderBy) {
    const rowsCopy = [];
    var obj = {};
    for (var i = 0; i < rows.length; i++) {
        for (var j = 0; j < headers.length; j++) {
            obj[headers[j]] = rows[i][headers[j]];
        }
        rowsCopy.push(obj);
        obj = {};
    }
    return stableSort(rowsCopy, getSorting(order, orderBy));
}

const HistoryTable = props => {
    const [t] = useTranslation();
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [rows, setRows] = useState({});
    const [rowsLength, setRowsLength] = useState(0);
    const [headers, setHeaders] = useState([]);
    const [mainField, setMainField] = useState("");
    const [visibleHeaders, setVisibleHeaders] = useState([]);
    const [sortedFields, setSortedFields] = useState([]);
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("createdAt");

    React.useEffect(() => {
        setHeaders(props.headers);
        setRows(props.rows);
        setRowsLength(props.rows.length);
        setMainField(props.mainField);
        setVisibleHeaders(props.visibleHeaders);
        setSortedFields(props.sortedFields);
        adjustRowsPerpage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.headers, props.mainField, props.rows, props.visibleHeaders]);

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    const adjustRowsPerpage = () => {
        if (props.rows.length >= 10) {
            setRowsPerPage(10);
        } else {
            setRowsPerPage(props.rows.length);
        }
    };
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === sortedFields[property] && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(sortedFields[property]);
    };

    const UsersTableHead = props => {
        const {order, orderBy, onRequestSort} = props;
        const [t] = useTranslation();
        const paginationTop = action => e => {
            var selector = "[aria-label^='" + action + "']";
            document.querySelector(selector).click();
            e.preventDefault();
        };
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    {visibleHeaders.map((header, index) => (
                        <StyledTableCell
                            key={index}
                            size="small"
                            align="left"
                            sortDirection={orderBy === sortedFields[index] ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === sortedFields[index]}
                                direction={order}
                                onClick={createSortHandler(index)}
                            >
                                {header.toUpperCase()}
                                {orderBy === sortedFields[index] ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc"
                                            ? "sorted descending"
                                            : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    };

    UsersTableHead.propTypes = {
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.oneOf(["asc", "desc"]).isRequired,
        orderBy: PropTypes.string.isRequired
    };

    return (
        <Paper className={classes.root}>
            <div className={classes.tableWrapper}>
                <Table className={classes.table} size="small">
                    {rows.length > 0 &&
                        <UsersTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                    }
                    <TableBody>
                        {createContent(rows, headers, order, orderBy)
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => (
                                <StyledTableRow key={row.id}>
                                    <TableCell size="small">
                                        {formartDate(row["createdAt"])}
                                    </TableCell>
                                    <TableCell size="small">
                                        {row["executor"] ? row["executor"]["name"] : "-"}
                                    </TableCell>
                                    <TableCell size="small">
                                        {t("LIBRARY." + row["operation"])}
                                    </TableCell>
                                    <TableCell size="small">
                                        {row["lastResponsible"] ? row["lastResponsible"]["name"] : row["lastResponsibleExternal"] ? row["lastResponsibleExternal"] : "-"}
                                    </TableCell>
                                    <TableCell size="small">
                                        {row["actualResponsible"] ? row["actualResponsible"]["name"] : row["actualResponsibleExternal"] ? row["actualResponsibleExternal"] : "-"}
                                    </TableCell>
                                    {/* {getRowProperties(row)} */}
                                </StyledTableRow>
                            ))}
                        {emptyRows > 0 && (
                            <TableRow style={{height: 48 * emptyRows}}>
                                <TableCell colSpan={6}/>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                {rows.length > 0 &&
                    <TableFooter style={{float: "right"}}>
                        <TablePagination
                            labelRowsPerPage={"Resultados por página"}
                            count={rowsLength}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            component="div"
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableFooter>
                }
            </div>
        </Paper>
    );
};

HistoryTable.propTypes = {
    rows: PropTypes.array.isRequired,
    headers: PropTypes.array.isRequired,
    visibleHeaders: PropTypes.array.isRequired,
    mainField: PropTypes.string.isRequired
};

export default HistoryTable;