import { DEFAULT, PARTNERS } from "../types";

export default (state, action) => {
  switch (action.type) {
    case DEFAULT.SET_LOADING:
      return {
        ...state,
        loading: true
      };
    case PARTNERS.GET_PARTNER:
      return {
        ...state,
        partner: action.payload,
        loading: false
      };
    case PARTNERS.GET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
        loading: false
      };
    case PARTNERS.ADD_PARTNER:
      return {
        ...state,
        response: action.payload,
        responseType: action.responseType,
        successiveActions: action.successiveActions,
        hasChanged: action.hasChanged,
        loading: false
      };
    case DEFAULT.RESET_MESSAGE:
      return {
        ...state,
        response: "",
        responseType: ""
      };
    case PARTNERS.SEARCH:
      return {
        ...state,
        // partners: action.payload,
        filter: action.filter,
        loading: false
      };

    case PARTNERS.GET_PARTNERS:
      return {
        ...state,
        partners: action.payload,
        loading: false
      };
    case PARTNERS.UPDATE_PARTNER:
      return {
        ...state,
        response: action.payload,
        responseType: action.responseType,
        successiveActions: action.successiveActions,
        hasChanged: action.hasChanged,
        loading: false
      };
    case PARTNERS.DELETE_PARTNER:
      return {
        ...state,
        response: action.payload,
        responseType: action.responseType,
        successiveActions: action.successiveActions,
        hasChanged: action.hasChanged,

        loading: false
      };
    case PARTNERS.GET_ADDRESS_BY_GIVEN_CEP:
      return {
        ...state,
        addressByCep: action.payload
      };
    case DEFAULT.SET_HAS_CHANGED:
      return {
        ...state,
        hasChanged: 0
      };
    default:
      return state;
  }
};
