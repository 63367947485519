import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Buttons from "components/buttons/Pressed";
import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Zoom from "@material-ui/core/Zoom";
import Autocomplete from '@material-ui/lab/Autocomplete';
import ButtonBase from '@material-ui/core/ButtonBase';
import Box from '@material-ui/core/Box';

const tooltipTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#2c3e51",
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: "white",
        fontSize: "1em",
        backgroundColor: "red",
        opacity: "0.7",
      },
    },
  },
});

const DialogSubItem = (props) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const {
    open,
    setOpen,
    categoriesArray,
    equipmentArray,
    addSubItensArray,
  } = props;

  const [filterCategoryId, setFilterCategoryId] = useState(null);
  const [equipmentFilterArray, setEquipmentFilter] = useState([]);
  const [itemSelected, setitemSelected] = useState(null);
  const [requiredCategory, setRequiredCategory] = useState(false);
  const [requiredItem, setRequiredItem] = useState(false);

  const [hasSelected, sethasSelected] = useState(false);
  const [lengthGrid, setLengthGrid] = useState(12);
  let equipmentFilter = []
  equipmentFilter = equipmentArray;

  const handleClose = () => {
    setOpen(false);
    setitemSelected(null);
    sethasSelected(false);
    setLengthGrid(12);
  };

  const handleChange = (event) => {
    setFilterCategoryId(event.target.value);
    setRequiredCategory(false)
    equipmentFilter = equipmentFilter.filter(
      item =>
        item.categoryId == filterCategoryId
    );
  };

  useEffect(() => {
    equipmentFilter = equipmentFilter.filter(
      item =>
        item.categoryId == filterCategoryId
    );
    setEquipmentFilter(equipmentFilter);

  }, [filterCategoryId, equipmentFilter.length]);

  const verifyCanSubmit = () => {
    let res = true;

    if (!filterCategoryId) {
      res = false;
      setRequiredCategory(true);
    }
    if (!itemSelected) {
      res = false;
      setRequiredItem(true);
    }

    return res;
  };

  const handleSubmitSubItem = () => {
    const canSubmit = verifyCanSubmit();
    if (canSubmit) {
    addSubItensArray(itemSelected.value)
    handleClose()
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      aria-labelledby="max-width-dialog-title"
      aria-labelledby="form-dialog-title"
      msg="mensagem"
      maxWidth="sm"
    >
      <DialogTitle className={classes.actionsTitle} style={{ width: "auto" }}>
        <span style={{ fontWeight: "bold" }}>
          {t("INVENTORY.addSubItem").toLocaleUpperCase()}
        </span>
        <IconButton
          style={{ float: "right", marginTop: -10, marginRight: -17 }}
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} >
          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.subItemForm}>
            <MuiThemeProvider theme={tooltipTheme}>
              <Tooltip
                TransitionComponent={Zoom}
                open={requiredCategory}
                title={t("GENERAL.requiredField")}
              >
                <TextField
                  select
                  id="client"
                  label={t("INVENTORY.category") + "*"}
                  name="category"
                  value={filterCategoryId}
                  onChange={handleChange}
                  className={classes.textField}
                  margin="normal"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "search" }}
                >
                  {categoriesArray.map((category) => (
                    <MenuItem key={category.value} value={category.value}>
                      {category.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Tooltip>
            </MuiThemeProvider>
            <MuiThemeProvider theme={tooltipTheme}>
              <Tooltip
                TransitionComponent={Zoom}
                open={requiredItem}
                title={t("GENERAL.requiredField")}
              >
            <Autocomplete
              style={{ marginTop: 16, marginBottom: 8 }}
              disabled={equipmentFilterArray.length == 0}
              value={itemSelected}
              onChange={(event, newValue) => {
                setitemSelected(newValue);
                sethasSelected(true);
                setLengthGrid(6);
                setRequiredItem(false);
                if (newValue == null) {
                  sethasSelected(false);
                  setLengthGrid(12);
                }
              }}
              id="combo-box-demo"
              options={equipmentFilterArray}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => <TextField {...params} label="Subitem*" variant="outlined" />}
            />
              </Tooltip>
            </MuiThemeProvider>
          </Grid>
          {hasSelected ?
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid xs={6}>
                <Grid item xs={12} sm={12}>
                  <ButtonBase className={classes.image}>
                    <img className={classes.img} alt="subitem" src={itemSelected && itemSelected.fileUserExternal ? window.location.origin + itemSelected.fileUserExternal : window.location.origin + '/img/noImageItem.png'} />
                  </ButtonBase>
                </Grid>
              </Grid>
              <Grid xs={6}>
                <Grid item xs={12} sm={12}>
                  <Typography className={classes.titleNewEquipment}>
                    {itemSelected && itemSelected.label.split("- Código")[0]}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography>
                    {itemSelected && itemSelected.fcpcCode}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography className={classes.label}>
                    Responsável
                  </Typography>
                  <Typography>
                    {itemSelected && itemSelected.responsible ? itemSelected.responsible : "Sem responsável"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography className={classes.label}>
                    Local
                  </Typography>
                  <Typography>
                    {itemSelected && itemSelected.place}
                  </Typography>
                </Grid>
              </Grid>
            </Grid> :
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box className={classes.noSubitem}>
                <Typography>
                  Nenhum item selecionado
                </Typography>
              </Box>
            </Grid>
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <Buttons
          onClick={handleClose}
          tipo="BN-noback"
          conteudo={t("GENERAL.cancel").toLocaleUpperCase()}
        />
        <Buttons
          onClick={handleSubmitSubItem}
          tipo="BN-blue"
          conteudo={t("GENERAL.add").toLocaleUpperCase()}
        />
      </DialogActions>
    </Dialog>
  );
};

export default DialogSubItem;