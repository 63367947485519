import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  rootForm: {
    marginBottom: "50px"
  },
  breadCrumbs: {
    marginTop: "30px",
    flexWrap: "wrap",
    marginBottom: "40px"
  },
  bodyForm: {
    paddingLeft: "18px",
    paddingRight: "18px"
  },
  header: {
    backgroundColor: "#f5f6fa",
    height: "45px",
    opacity: "0.54",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "3.5",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000000",
    paddingLeft: "18px"
  },
  adjustReactTextMask: {
    marginTop: "16px",
    display: "flex"
  },
  title: {
    color: "#717272",
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: "37px",
    fontStyle: "normal"
  },
  link: {
    color: "#73b3ff"
  },
  notClicableBreadScrumbs: {
    color: "#828b91",
    cursor: "not-allowed"
  },
  inputBox: {
    padding: "5px 15px"
  },
  textField: {
    display: "flex"
  },
  paperDivision: {
    marginTop: "60px"
  },
  blueButton: {
    backgroundColor: "#2c3e51",
    padding: "14px 24px",
    fontSize: "14px",
    fontWeight: "500"
  },
  buttonIcon: {
    display: "flex",
    marginRight: "15px"
  }
}));

export { useStyles };
