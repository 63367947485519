import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import axios from "axios";
import Buttons from "components/buttons/Pressed";
import { useStyles } from "components/layout/CommonStyles";
import ProjectsContext from "context/projects/projectsContext";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import history from "../../../history";
import CloseIcon from '@material-ui/icons/Close';
import Cookies from "js-cookie";

const SubsidiesTableActions = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const { id, mode, subsidyDate } = props;
    const [openDelete, setOpenDelete] = useState(false);
    const projectsContext = useContext(ProjectsContext);
    const { deleteSubsidy } = projectsContext;

    const handleEdit = () => {
        axios.get(process.env.REACT_APP_SERVER + `api/projectSubsidy/${id}`, {
            headers: {
                "Content-type": "application/json",
                "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
            }
        })
            .then(res => {
                if (res.statusText === "OK") {
                    history.push(`/execution/editSubsidy/${id}`);
                } else {
                    deleteSubsidy(id);
                }
            })
            .catch(error => {
                deleteSubsidy(id);
            });
    };

    const handleView = () => {
        axios.get(process.env.REACT_APP_SERVER + `api/projectSubsidy/${id}`, {
            headers: {
                "Content-type": "application/json",
                "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
            }
        })
            .then(res => {
                if (res.statusText === "OK") {
                    history.push(`/execution/viewSubsidy/${id}`);
                } else {
                    deleteSubsidy(id);
                }
            })
            .catch(error => {
                deleteSubsidy(id);
            });
    };

    const handleClickOpenDelete = () => {
        setOpenDelete(true);
    };

    const handleClose = () => {
        setOpenDelete(false);
    };

    const handleDelete = () => {
        deleteSubsidy(id);
        setOpenDelete(false);
    };

    return (
        <div>
            <Tooltip title="Visualizar" aria-label="details" placement="top">
                <IconButton className={classes.actionsIcons} onClick={handleView}>
                    <Visibility />
                </IconButton>
            </Tooltip>
            {mode === "edit" &&
                <Tooltip title="Editar" aria-label="edit" placement="top">
                    <IconButton className={classes.actionsIcons} onClick={handleEdit}>
                        <Edit />
                    </IconButton>
                </Tooltip>
            }
            {mode === "edit" &&
                <Tooltip title="Deletar" aria-label="delete" placement="top">
                    <IconButton className={classes.actionsIcons} onClick={handleClickOpenDelete}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            }
            <Dialog
                open={openDelete}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                msg="mensagem"
            >
                <DialogTitle className={classes.actionsTitle}>
                    {t("GENERAL.notification")}
                    <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {`Você deseja realmente deletar o aporte "${subsidyDate}"?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Buttons
                        onClick={handleClose}
                        tipo="BN-noback"
                        conteudo={t("GENERAL.cancel")}
                    />
                    <Buttons
                        onClick={handleDelete}
                        tipo="BN-blue"
                        conteudo={t("GENERAL.delete")}
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default SubsidiesTableActions;