import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

import SettingsContext from 'context/hrModule/settings/settingsContext';
import AllowancesContext from 'context/allowance/allowanceContext';

import Paper from '@material-ui/core/Paper';

import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import { useStyles as useAllowanceReasonStyles } from './styles.js';

import Breadcrumb from 'components/layout/breadcrumb/Breadcrumb';
import MySnackbarContentWrapper from 'components/layout/Message';
import Snackbar from '@material-ui/core/Snackbar';

import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Dissatisfied from '@material-ui/icons/SentimentVeryDissatisfied';
import Footer from 'components/layout/Footer';
import Transition from 'components/layout/transition/Transition';
import InputFilter from 'components/inputs/InputFilter';
import FilterContainer from 'components/containers/FilterContainer';
import HeaderContainer from 'components/containers/HeaderContainer';
import ContentContainer from 'components/containers/ContentContainer';

import AllowanceReasonsTable from './allowanceReasonsTable.js';

import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';
import { Screen, Allow } from 'global/constants';
import { AllowanceReasonTypeArray } from './ScreenUtils.js';

const AllowanceReasons = props => {
    const classes = useStyles();
    const styles = useAllowanceReasonStyles();
    const [t] = useTranslation();
    const allowanceContext = useContext(AllowancesContext);
    const [findText, setFindText] = useState('');
    const [filterName, setFilterName] = useState('');
    const [filterType, setFilterType] = useState('ALL');
    const [dataFilter, setDataFilter] = useState([]);
    const [canClick, setCanClick] = useState(false);

    const context = props.context;

    const settingsContext = useContext(SettingsContext);

    const {
        loading,
        setLoading,
        setLoadingFalse,
        addAllowanceReason,
        getAllowanceReason,
        getAllAllowanceReasons,
        allowanceReason,
        allowanceReasons,
        successiveActions,
        hasChanged,
        setHasChanged,
        responseType,
        response
    } = settingsContext;

    const {
        allowanceResponse,
        allowanceResponseType,
        messageHasChanged,
        resetAllowanceMessage,
        permission
    } = allowanceContext;

    const [snackBar, setSnackBar] = useState({
        open: false,
        kind: '',
        content: ''
    });
    const handleSnack = () => {
        setSnackBar({ ...snackBar, open: true });
        setHasChanged();
    };
    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBar({ ...snackBar, open: false });
        resetAllowanceMessage();
    };

    useEffect(() => {
        if (responseType === 'SUCCESS' && hasChanged > successiveActions) {
            handleSnack({ kind: 'success', content: response });
        }
        if (responseType === 'ERROR' && hasChanged > successiveActions) {
            handleSnack({ kind: 'error', content: response });
        }

        return () => {};
    }, [responseType, response, successiveActions]);

    async function fetchData() {
        await setLoading();
        await getAllAllowanceReasons();
        await setLoadingFalse();
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (allowanceReasons && allowanceReasons.length) {
            const { newDataFilter, textNotFound } = applyFilter(
                allowanceReasons.filter(x => !x.deleted)
            );
            if (newDataFilter.length === 0 && hasItens) {
                setDataFilter([]);
                setHasNoResult(true);
                setFindText(textNotFound);
            } else {
                setDataFilter(newDataFilter);
                setHasNoResult(false);
                setFindText('');
            }
        }
    }, [allowanceReasons, filterName, filterType]);

    const applyFilter = allowanceReasons => {
        let newDataFilter = [];
        let textNotFound = '';

        newDataFilter = allowanceReasons.filter(x => !x.deleted);

        if (filterName) {
            newDataFilter = newDataFilter.filter(row =>
                row.name.toLowerCase().includes(filterName.toLowerCase())
            );
            if (!textNotFound && newDataFilter.length === 0)
                textNotFound = filterName;
        }

        if (filterType && filterType !== 'ALL') {
            newDataFilter = newDataFilter.filter(
                item => item.type && item.type === filterType
            );

            if (!textNotFound && newDataFilter.length === 0)
                textNotFound = filterType;
        }

        return { newDataFilter, textNotFound };
    };

    useEffect(() => {
        if (filterName || filterType) {
            setCanClick(true);
        } else {
            setCanClick(false);
        }
    }, [filterName, filterType]);

    const hasItens = allowanceReasons.filter(x => !x.deleted).length > 0;
    const [hasNoResult, setHasNoResult] = useState(
        hasItens && dataFilter.length === 0
    );

    return (
        <React.Fragment>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={snackBar.open}
                onClose={handleSnackClose}
                TransitionComponent={Slide}
                transitionDuration={{ enter: 500, exit: 500 }}
                ContentProps={{
                    'aria-describedby': 'message-id'
                }}
                autoHideDuration={2000}
            >
                <MySnackbarContentWrapper
                    onClose={handleSnackClose}
                    variant={
                        responseType !== '' ? responseType.toLowerCase() : null
                    }
                    message={
                        <span id="message-id">
                            {response !== '' ? response : allowanceResponse}
                        </span>
                    }
                />
            </Snackbar>

            <Transition loading={loading} newDesign={true}>
                <div
                    style={{
                        opacity: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'spaceBetween',
                        minHeight: 'calc(100vh - 70px)'
                    }}
                >
                    <HeaderContainer>
                        <Breadcrumb
                            newDesign={true}
                            routes={[
                                {
                                    label: t('HR.humanResource'),
                                    to: '/HumanResources'
                                },
                                { label: t('ROUTES.config') },
                                { label: t('HR.allowanceReasons') }
                            ]}
                        />
                        <div className="container-headerNewDesign">
                            <h1 className={classes.titleNewDesign}>
                                {t('HR.allowanceReasons')}
                            </h1>
                            {GetGeneralPermissionForAction(
                                Screen.ALLOWANCE_REASON,
                                Allow.CREATE
                            ) && (
                                <Link to={'/AllowanceReason/new'}>
                                    <Button
                                        className={classes.blueButton}
                                        variant="contained"
                                        color="primary"
                                        style={{ marginBottom: '10px' }}
                                    >
                                        {t('GENERAL.add')}
                                    </Button>
                                </Link>
                            )}
                        </div>
                    </HeaderContainer>

                    <ContentContainer>
                        <Paper>
                            <div className="cabecalho">
                                {t('HR.allowanceReasonsList').toUpperCase()}
                            </div>
                            <React.Fragment>
                                <FilterContainer>
                                    <InputFilter
                                        filterValue={filterName}
                                        setFilter={setFilterName}
                                        label={'GENERAL.name'}
                                        maxLength={60}
                                    />

                                    <InputFilter
                                        filterValue={filterType}
                                        setFilter={setFilterType}
                                        label={'GENERAL.type'}
                                        isSelect
                                        arraySelected={AllowanceReasonTypeArray}
                                    />
                                </FilterContainer>
                                <AllowanceReasonsTable
                                    rows={dataFilter}
                                    toFirstPage={canClick}
                                    headers={[
                                        'id',
                                        'name',
                                        'type',
                                        'description'
                                    ]}
                                    visibleHeaders={[
                                        'Motivo de Abono',
                                        'Tipo',
                                        'Descrição',
                                        ''
                                    ]}
                                    sortedFields={[
                                        'name',
                                        'type',
                                        'description'
                                    ]}
                                    mainField="name"
                                    fetchData={fetchData}
                                />
                                {!hasItens && (
                                    <div
                                        className={
                                            classes.noParticipantsMessage
                                        }
                                    >
                                        <h2>{t('INVENTORY.noItensMessage')}</h2>
                                        <h4>
                                            {t('INVENTORY.noItensSubMessage')}
                                        </h4>
                                    </div>
                                )}
                                {hasNoResult && (
                                    <div
                                        className={
                                            classes.noParticipantsMessage
                                        }
                                    >
                                        <div>
                                            <Dissatisfied
                                                className={
                                                    classes.notFoundIconStyle
                                                }
                                            />
                                        </div>
                                        <h2>{t('GENERAL.noResultsFound')}</h2>
                                        <h5>{`Sua busca por "${findText}" não retornou resultados.`}</h5>
                                    </div>
                                )}
                            </React.Fragment>
                        </Paper>
                    </ContentContainer>
                </div>
                <Footer />
            </Transition>
        </React.Fragment>
    );
};

export default AllowanceReasons;
