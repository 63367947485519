/* eslint-disable react-hooks/exhaustive-deps */
import Paper from '@material-ui/core/Paper';
import Dissatisfied from '@material-ui/icons/SentimentVeryDissatisfied';
import FilterContainer from 'components/containers/FilterContainer';
import InputFilter from 'components/inputs/InputFilter';
import { useStyles } from 'components/layout/CommonStyles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DialogWithdrawRequisition from 'pages/libraryModule/dialogs/Requisitions/DialogWithdrawRequisition';
import { screenStyles } from 'pages/libraryModule/Requisitions/Styles';
import BookCardWithdrawRequisition from 'pages/libraryModule/cards/BookCardWithdrawRequisition';
import { AllAuthorsName } from 'utils/library/AllAuthorsName';
import { QueryGetValue } from 'utils/general/QueryString';
import { dateToBR } from 'utils/dates/DateToBRr';
import { FormatDateToFront } from 'utils/dates/FormatDateToFront';
import InputFilterDate from 'components/inputs/InputFilterDate';
import { formatImagePath } from 'utils/library/formatImagePath';

const WithdrawRequisitionsForm = props => {
    const { RequisitionsData, submitRequisition } = props;
    const [t] = useTranslation();
    const styles = screenStyles();
    const classes = useStyles();

    const [filterTitle, setFilterTitle] = useState('');
    const [filterAuthor, setFilterAuthor] = useState('');
    const [filterCollaborator, setFilterCollaborator] = useState('');
    const [filterDate, setFilterDate] = useState(null);

    const [findText, setFindText] = useState('');
    const [dataFilter, setDataFilter] = useState([]);

    const hasItens = RequisitionsData.length > 0;
    const [hasNoResult, setHasNoResult] = useState(false);

    const [dialogData, setDialogData] = useState({});
    const [openRequisitionApproveDialog, setOpenRequisitionApproveDialog] =
        useState(false);
    const [openRequisitionCancelDialog, setOpenRequisitionCancelDialog] =
        useState(false);

    const handleOpenRequisitionApproveDialog = (index, place) => {
        const req = dataFilter[index];
        setDialogData(req);
        setOpenRequisitionApproveDialog(true);
    };

    const getExemplarCode = (exemplars, exemplarId) => {
        return exemplars.find(e => e.id === exemplarId).code;
    };

    const handleOpenRequisitionCancelDialog = index => {
        const req = dataFilter[index];
        setDialogData(req);
        setOpenRequisitionCancelDialog(true);
    };

    const applyFilter = filterArray => {
        let newDataFilter = [];
        let textNoFound = '';

        newDataFilter = filterArray;

        if (filterTitle) {
            newDataFilter = newDataFilter.filter(item =>
                item.book.title
                    .toLowerCase()
                    .includes(filterTitle.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterTitle;
        }

        if (filterAuthor) {
            newDataFilter = newDataFilter.filter(item =>
                AllAuthorsName(item.book.authors)
                    .toLowerCase()
                    .includes(filterAuthor.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterAuthor;
        }

        if (filterCollaborator) {
            newDataFilter = newDataFilter.filter(item =>
                item.user.shortname
                    .toLowerCase()
                    .includes(filterCollaborator.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterCollaborator;
        }

        if (filterDate && !isNaN(filterDate)) {
            let filterDateBR = dateToBR(filterDate);
            newDataFilter = newDataFilter.filter(
                item =>
                    item.approvedDate &&
                    dateToBR(FormatDateToFront(item.approvedDate)) ===
                        filterDateBR
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterDateBR;
        }

        return { newDataFilter, textNoFound };
    };

    useEffect(() => {
        if (RequisitionsData && RequisitionsData.length) {
            const { newDataFilter, textNoFound } =
                applyFilter(RequisitionsData);

            if (newDataFilter.length === 0) {
                setDataFilter([]);
                setHasNoResult(true);
                setFindText(textNoFound);
            } else {
                setDataFilter(newDataFilter);
                setHasNoResult(false);
                setFindText('');
            }
        }
    }, [
        RequisitionsData,
        filterTitle,
        filterAuthor,
        filterCollaborator,
        filterDate
    ]);

    useEffect(() => {
        if (QueryGetValue('panel') === 'WITHDRAW') {
            setFilterTitle(QueryGetValue('title') || '');
            setFilterAuthor(QueryGetValue('author') || '');
            setFilterCollaborator(QueryGetValue('collaborator') || '');
        }
    }, []);

    return (
        <React.Fragment>
            <FilterContainer>
                <InputFilter
                    filterValue={filterTitle}
                    setFilter={setFilterTitle}
                    label={'INVENTORY.title'}
                    maxLength={70}
                />
                <InputFilter
                    filterValue={filterAuthor}
                    setFilter={setFilterAuthor}
                    label={'INVENTORY.BookAuthor'}
                    maxLength={70}
                />
                <InputFilter
                    filterValue={filterCollaborator}
                    setFilter={setFilterCollaborator}
                    label={'LIBRARY.RequisitionApplicant'}
                    maxLength={70}
                />
                <InputFilterDate
                    filterValue={filterDate}
                    setFilter={setFilterDate}
                    label={'LIBRARY.dateApproved'}
                />
            </FilterContainer>

            <Paper style={{ maxHeight: 700, overflow: 'auto' }}>
                <div className={styles.bookList}>
                    {dataFilter.map((data, index) => {
                        return (
                            <BookCardWithdrawRequisition
                                image={formatImagePath(
                                    data.book.imagePath,
                                    data.book.isImageUrl
                                )}
                                title={data.book.title}
                                author={AllAuthorsName(data.book.authors)}
                                exemplarCode={getExemplarCode(
                                    data.book.exemplars,
                                    data.exemplarId
                                )}
                                applicant={data.user.shortname}
                                status={data.status}
                                approvedDate={dateToBR(
                                    FormatDateToFront(data.approvedDate)
                                )}
                                onClickCancel={() => {
                                    handleOpenRequisitionCancelDialog(index);
                                }}
                                onClickApprove={() => {
                                    handleOpenRequisitionApproveDialog(index);
                                }}
                            />
                        );
                    })}
                </div>
                {!hasItens && (
                    <div className={classes.noParticipantsMessage}>
                        <h2>{t('LIBRARY.noWithdrawRequisitionsMessage')}</h2>
                        <h4>{t('LIBRARY.noRequisitionsSubMessage')}</h4>
                    </div>
                )}
                {hasNoResult && (
                    <div className={classes.noParticipantsMessage}>
                        <div>
                            <Dissatisfied
                                className={classes.notFoundIconStyle}
                            />
                        </div>
                        <h2>{t('GENERAL.noResultsFound')}</h2>
                        <h5>{`Sua busca por "${findText}" não retornou resultados.`}</h5>
                    </div>
                )}
            </Paper>

            {openRequisitionApproveDialog && (
                <DialogWithdrawRequisition
                    open={openRequisitionApproveDialog}
                    setOpen={setOpenRequisitionApproveDialog}
                    viewValues={dialogData}
                    submit={submitRequisition}
                    context={'withdraw'}
                />
            )}
            {openRequisitionCancelDialog && (
                <DialogWithdrawRequisition
                    open={openRequisitionCancelDialog}
                    setOpen={setOpenRequisitionCancelDialog}
                    viewValues={dialogData}
                    submit={submitRequisition}
                    context={'cancel'}
                />
            )}
        </React.Fragment>
    );
};

export default WithdrawRequisitionsForm;
