import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Buttons from 'components/buttons/Pressed';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import ExcelJS from 'exceljs';
import { toCamelCase } from 'utils/general/ToCamelCase';
import { moneyMask } from 'utils/masks/moneyMask';

const DialogExport = props => {
    const [t] = useTranslation();
    const classes = useStyles();
    const { title, maxFieldsColumn, open, setOpen, data } = props;
    const fileDownload = require('js-file-download');
    const [radioValue, setRadioValue] = useState('TITULO');
    const [buttonExport, setButtonExport] = useState(false);
    const [fields, setFields] = useState([
        { label: 'Título', checked: true, section: 'TITULO', property: null },
        { label: 'ISBN', checked: true, section: 'TITULO', property: 'isbn' },
        {
            label: 'Edição',
            checked: true,
            section: 'TITULO',
            property: 'edition'
        },
        { label: 'Autor', checked: true, section: 'TITULO', property: null },
        {
            label: 'Editora',
            checked: false,
            section: 'TITULO',
            property: 'publisher'
        },
        {
            label: 'Categoria',
            checked: false,
            section: 'TITULO',
            property: 'category'
        },
        {
            label: 'Idioma',
            checked: false,
            section: 'TITULO',
            property: 'language'
        },
        {
            label: 'Quantidade',
            checked: true,
            section: 'TITULO',
            property: null
        },
        { label: 'Título', checked: true, section: 'EXEMPLAR', property: null },
        {
            label: 'Código do Exemplar',
            checked: true,
            section: 'EXEMPLAR',
            property: 'code'
        },
        {
            label: 'Data de Aquisição',
            checked: false,
            section: 'EXEMPLAR',
            property: null
        },
        {
            label: 'Método de Aquisição',
            checked: false,
            section: 'EXEMPLAR',
            property: 'itemAcquisitionMethod'
        },
        {
            label: 'Valor',
            checked: false,
            section: 'EXEMPLAR',
            property: 'itemTotalPurchase'
        },
        {
            label: 'NF/Invoice',
            checked: false,
            section: 'EXEMPLAR',
            property: 'itemInvoice'
        },
        {
            label: 'Status',
            checked: true,
            section: 'EXEMPLAR',
            property: 'status'
        },
        {
            label: 'Responsável Adm',
            checked: true,
            section: 'EXEMPLAR',
            property: 'responsibleAdministrative'
        },
        {
            label: 'Responsável Direto',
            checked: true,
            section: 'EXEMPLAR',
            property: 'directResponsible'
        },
        {
            label: 'Biblioteca',
            checked: true,
            section: 'EXEMPLAR',
            property: 'library'
        },
        {
            label: 'Local',
            checked: true,
            section: 'EXEMPLAR',
            property: 'place'
        }
    ]);

    let qtyColumn = Math.ceil(
        fields.filter(field => field.section === radioValue).length /
            maxFieldsColumn
    );

    function dateToEN(date) {
        const dateAcquisition = new Date(date);
        dateAcquisition.setMinutes(
            dateAcquisition.getMinutes() + dateAcquisition.getTimezoneOffset()
        );
        return (
            dateAcquisition.getDate() +
            '/' +
            (dateAcquisition.getMonth() + 1) +
            '/' +
            dateAcquisition.getFullYear()
        );
    }

    const valueByColumn = (column, property) => {
        let response = [];
        switch (column) {
            case 'Título':
                if (radioValue === 'TITULO') {
                    return data.map(obj => obj.title);
                } else {
                    data.forEach(obj => {
                        if (obj.exemplars) {
                            obj.exemplars.forEach(exemplar => {
                                if (obj.title) {
                                    response.push(obj.title);
                                } else {
                                    response.push('');
                                }
                            });
                        }
                    });
                    return response;
                }
            case 'Quantidade':
                return data.map(obj => {
                    if (obj.exemplars) return obj.exemplars.length;
                    return '';
                });
            case 'Data de Aquisição':
                data.forEach(obj => {
                    if (obj.exemplars) {
                        obj.exemplars.forEach(exemplar => {
                            if (exemplar.acquisitionDate) {
                                response.push(
                                    dateToEN(exemplar.acquisitionDate)
                                );
                            } else {
                                response.push('');
                            }
                        });
                    }
                });
                return response;
            case 'Autor':
                data.forEach(obj => {
                    if (obj.authors) {
                        response.push(
                            obj.authors.map(author => author.name).join(', ')
                        );
                    } else {
                        response.push('');
                    }
                });
                return response;
            case 'Categoria':
                data.forEach(obj => {
                    if (obj.category) {
                        response.push(obj.category.name);
                    } else {
                        response.push('');
                    }
                });
                return response;
            case 'Método de Aquisição':
                data.forEach(obj => {
                    if (obj.exemplars) {
                        obj.exemplars.forEach(exemplar => {
                            if (exemplar[property]) {
                                if (exemplar[property] == 'PROJECT') {
                                    response.push(
                                        t(
                                            'GENERAL.' +
                                                toCamelCase(exemplar[property])
                                        ) +
                                            '/' +
                                            exemplar['project'] ??
                                            t('GENERAL.unknown')
                                    );
                                } else {
                                    response.push(
                                        t(
                                            'GENERAL.' +
                                                toCamelCase(exemplar[property])
                                        ) +
                                            '/' +
                                            (exemplar[
                                                'itemAcquisitionMethodDescription'
                                            ] ?? t('GENERAL.unknown'))
                                    );
                                }
                            } else {
                                response.push('');
                            }
                        });
                    }
                });
                return response;
            case 'Biblioteca':
            case 'Status':
                data.forEach(obj => {
                    if (obj.exemplars) {
                        obj.exemplars.forEach(exemplar => {
                            if (exemplar[property]) {
                                response.push(
                                    t(
                                        'GENERAL.' +
                                            toCamelCase(exemplar[property])
                                    )
                                );
                            } else {
                                response.push('');
                            }
                        });
                    }
                });
                return response;
            case 'Valor':
                data.forEach(obj => {
                    if (obj.exemplars) {
                        obj.exemplars.forEach(exemplar => {
                            if (exemplar[property]) {
                                response.push(moneyMask(exemplar[property]));
                            } else {
                                response.push('');
                            }
                        });
                    }
                });
                return response;
            case 'Responsável Adm':
                data.forEach(obj => {
                    if (obj.exemplars) {
                        obj.exemplars.forEach(exemplar => {
                            if (exemplar[property]) {
                                response.push(exemplar[property].shortname);
                            } else {
                                response.push('');
                            }
                        });
                    }
                });
                return response;
            case 'Responsável Direto':
                data.forEach(obj => {
                    if (obj.exemplars) {
                        obj.exemplars.forEach(exemplar => {
                            if (exemplar[property]) {
                                response.push(exemplar[property]);
                            } else {
                                response.push('');
                            }
                        });
                    }
                });
                return response;
            default:
                if (radioValue === 'TITULO') {
                    return data.map(obj => {
                        if (obj[property]) return obj[property];
                        return '';
                    });
                } else {
                    data.forEach(obj => {
                        if (obj.exemplars) {
                            obj.exemplars.forEach(exemplar => {
                                if (exemplar[property]) {
                                    response.push(exemplar[property]);
                                } else {
                                    response.push('');
                                }
                            });
                        }
                    });
                    return response;
                }
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleExport = () => {
        setOpen(false);
        const filename = `Exportar livros - ${radioValue.toLocaleLowerCase()}.xlsx`;

        const workbook = new ExcelJS.Workbook();
        const ws = workbook.addWorksheet('Report');

        // Style header
        ws.getRow(1).font = { name: 'Calibri', size: 14, bold: true };

        // Create columns
        ws.columns = fields
            .filter(field => field.section === radioValue && field.checked)
            .map(field => {
                return { header: field.label, key: field.property };
            });

        // Add columns values
        ws.columns.forEach(col => {
            //Add column data
            const res = valueByColumn(col.header, col.key);
            //Add column name
            res.unshift(t(col.header));

            col.values = res;
            col.width = col.header.length + 10;
        });

        // Download file
        workbook.xlsx.writeBuffer().then(function (data) {
            const blob = new Blob([data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            fileDownload(blob, filename);
        });
    };

    const handleChange = event => {
        if (event && event.target && event.target.name) {
            const newFields = [...fields];
            const field = newFields.find(
                x => x.label === event.target.name && x.section === radioValue
            );
            if (field) {
                field.checked = !field.checked;
                setFields(newFields);
            }
        }
    };

    useEffect(() => {
        let buttonIsEnabled = fields.filter(
            x => x.checked === true && x.section === radioValue
        );
        buttonIsEnabled.length === 0
            ? setButtonExport(true)
            : setButtonExport(false);
    }, [radioValue, fields]);

    const handleChangeRadio = event => {
        qtyColumn = Math.ceil(
            fields.filter(
                field =>
                    field.section ===
                    event.target.value.length / maxFieldsColumn
            )
        );
        setRadioValue(event.target.value);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            msg="mensagem"
            maxWidth="md"
        >
            <DialogTitle
                className={classes.actionsTitle}
                style={{ width: 'auto' }}
            >
                <span style={{ fontWeight: 'bold' }}>
                    {t(title).toLocaleUpperCase()}
                </span>
                <IconButton
                    style={{ float: 'right', marginTop: -10, marginRight: -17 }}
                    edge="end"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <FormControl component="fieldset">
                        <Grid container>
                            <Grid container item spacing={1} md={12} lg={12}>
                                <Grid item xs={12}>
                                    <FormLabel component="legend">
                                        Tipo de exportação
                                    </FormLabel>
                                </Grid>
                                <Grid item xs={12}>
                                    <RadioGroup
                                        row
                                        aria-label="position"
                                        name="position"
                                        defaultValue="top"
                                        value={radioValue}
                                        onChange={handleChangeRadio}
                                    >
                                        <FormControlLabel
                                            value="TITULO"
                                            control={<Radio color="primary" />}
                                            label="Por título"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="EXEMPLAR"
                                            control={<Radio color="primary" />}
                                            label="Por exemplar"
                                            labelPlacement="end"
                                        />
                                    </RadioGroup>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormLabel component="legend">
                                        Selecione abaixo o(s) campo(s) que
                                        deseja exportar:
                                    </FormLabel>
                                </Grid>
                                {Array.from(Array(qtyColumn).keys()).map(
                                    (column, index) => (
                                        <Grid
                                            item
                                            xs={4}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column'
                                            }}
                                            key={index}
                                        >
                                            {fields
                                                .filter(
                                                    field =>
                                                        field.section ===
                                                        radioValue
                                                )
                                                .slice(
                                                    column * maxFieldsColumn,
                                                    (column + 1) *
                                                        maxFieldsColumn
                                                )
                                                .map((field, idx) => (
                                                    <FormControlLabel
                                                        key={
                                                            field.label +
                                                            column +
                                                            idx
                                                        }
                                                        label={t(field.label)}
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={
                                                                    field.checked
                                                                }
                                                                name={
                                                                    field.label
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                            />
                                                        }
                                                    />
                                                ))}
                                        </Grid>
                                    )
                                )}
                            </Grid>
                        </Grid>
                    </FormControl>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Buttons
                    onClick={handleClose}
                    tipo="BN-noback"
                    conteudo={t('GENERAL.cancel').toLocaleUpperCase()}
                />
                <Buttons
                    disabled={buttonExport}
                    onClick={handleExport}
                    tipo="BN-blue"
                    conteudo={t('INVENTORY.export').toLocaleUpperCase()}
                />
            </DialogActions>
        </Dialog>
    );
};

export default DialogExport;
