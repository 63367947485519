import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from 'components/typography/Typography';

import { buttonsStyles } from './Styles';

const OutlinedWithIconButton = ({ text, Icon, ...props }) => {
    const styles = buttonsStyles();
    return (
        <Button
            variant="outlined"
            className={styles.outlinedWithIconButton}
            {...props}
        >
            <div className={styles.centralize}>
                <Icon />
                <Typography
                    variant={'RobotoBold'}
                    fontSize={12}
                    textColor={'white'}
                >
                    {text}
                </Typography>
            </div>
        </Button>
    );
};

export default OutlinedWithIconButton;
