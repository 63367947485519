import MomentUtils from "@date-io/moment";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useStyles } from "components/layout/CommonStyles";
import React, { useEffect, useState } from "react";

const NewBudgetView = props => {
    const classes = useStyles();
    const {
        lastQuota,
        budgetDate,
        quotaValue,
        estimatedCost,
        updateFields,
        minDate,
        maxDate
    } = props;
    const [selectedDate, handleDateChange] = useState(budgetDate);
    const [value, setValue] = useState(quotaValue ? quotaValue : "");
    useEffect(() => {
        handleDateChange(budgetDate);
    }, [budgetDate]);

    useEffect(() => {
        setValue(quotaValue);
    }, [quotaValue]);

    useEffect(() => {
        updateFields();
    });

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <MuiPickersUtilsProvider locale="pt" utils={MomentUtils}>
                        <DatePicker
                            disabled
                            fullWidth
                            id={"quotaDate" + lastQuota}
                            variant="inline"
                            openTo="year"
                            name="yearBase"
                            views={["year", "month"]}
                            margin="normal"
                            autoOk={true}
                            value={selectedDate}
                            onChange={handleDateChange}
                            minDate={minDate}
                            maxDate={maxDate}
                            maxDateMessage={
                                "A data não deve ser maior do que a data de término do termo"
                            }
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        disabled
                        id={"quotaValue" + lastQuota}
                        className={classes.textField}
                        margin="normal"
                        name="quotaValue"
                        value={value}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">R$</InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        id={"percentage" + lastQuota}
                        disabled={true}
                        className={classes.textField}
                        value={
                            value && estimatedCost > 0
                                ? (
                                    (parseFloat(
                                        value
                                            .split("")
                                            .filter(el => el !== ".")
                                            .join("")
                                            .replace(",", ".")
                                    ) /
                                        estimatedCost) *
                                    100
                                ).toFixed(2)
                                : "0.00"
                        }
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={2} style={{ marginTop: "8px" }}></Grid>
                <div className="lineBreak"></div>
            </Grid>
        </div>
    );
};

export default NewBudgetView;