/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Redirect} from "react-router-dom";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from '@material-ui/core/Box';
import Footer from "components/layout/Footer";
import ClockRecordContext from "context/hrModule/clockRecord/clockRecordContext";
import SettingsContext from "context/hrModule/settings/settingsContext";
import Transition from "components/layout/transition/Transition";
import Breadcrumb from "components/layout/breadcrumb/Breadcrumb";
import MyActivitiesTable from "./MyActivitiesTable";
import HeaderContainer from "components/containers/HeaderContainer";
import ContentContainer from "components/containers/ContentContainer";
import Typography from "components/typography/Typography";
import Buttons from "components/buttons/Pressed";
import DefaultTooltip from "components/infoTooltip/DefaultTooltip";
import DialogMyActivity from "./DialogMyActivity";

import { Screen, Allow } from "global/constants";

import { FormatDateToFront } from "utils/dates/FormatDateToFront";
import { GetHoursAndMinutes } from "utils/dates/GetHoursAndMinutes";
import { ConvertMilliInHourMin } from "utils/dates/ConvertMilliInHourMin1";
import { dateToBR } from "utils/dates/DateToBRr";
import { GetWorkedHours} from "utils/dates/GetWorkedHours";

import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";

import { screenStyles } from "./Styles"

const MyActivitiesForm = (props) => {
    const classes = useStyles();
    const styles = screenStyles();
    const [t] = useTranslation();
    const clockRecordContext = useContext(ClockRecordContext);
    const settingsContext = useContext(SettingsContext);

    const { id , context} = props


    const [openDialog, setOpenDialog] = useState(false);
    const [activityCategoryArray, setActivityCategoryArray] = useState(null);
    const [mustRedirect, setMustRedirect] = useState(false);

    const [values, setValues] = useState({
        date: "",
        entryTime: "",
        leaveForBreak: "",
        returnFromBreak: "",
        leaveTime: "",
        totalWorkingHours: "",
        activityRecords: [],
        activityRecordsToDelete: []
    });

    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }

    const {
        loading,
        setLoading,
        setLoadingFalse,
        getServerTime,
        serverTime,
        myActivityRecord,
        getMyActivityRecord,
        addActivity,
        updateActivity
    } = clockRecordContext;

    const {
        getAllActivityCategories,
        activityCategories
    } = settingsContext

    async function fetchData() {
        await setLoading();
        await getAllActivityCategories();
        await getServerTime();
        await getMyActivityRecord(id)
        await setLoadingFalse();
    }

    useEffect(() => {
        fetchData();

    }, []);

    useEffect(() => {
        if (myActivityRecord && serverTime){
            setValues({
                date: myActivityRecord.clockRecord && myActivityRecord.clockRecord.date? dateToBR(FormatDateToFront(myActivityRecord.clockRecord.date)) : "-",
                entryTime: myActivityRecord.clockRecord && myActivityRecord.clockRecord.entryTime ?  GetHoursAndMinutes(FormatDateToFront(myActivityRecord.clockRecord.entryTime)) :"-",
                leaveForBreak: myActivityRecord.clockRecord && myActivityRecord.clockRecord.leaveForBreak ?  GetHoursAndMinutes(FormatDateToFront(myActivityRecord.clockRecord.leaveForBreak)) :"-",
                returnFromBreak: myActivityRecord.clockRecord && myActivityRecord.clockRecord.returnFromBreak ?  GetHoursAndMinutes(FormatDateToFront(myActivityRecord.clockRecord.returnFromBreak)) :"-",
                leaveTime: myActivityRecord.clockRecord && myActivityRecord.clockRecord.leaveTime ?  GetHoursAndMinutes(FormatDateToFront(myActivityRecord.clockRecord.leaveTime)) :"-",
                status: myActivityRecord.clockRecord && myActivityRecord.clockRecord.status ? myActivityRecord.clockRecord.status : "",
                totalHours: myActivityRecord.totalWorkingHours ? `${ConvertMilliInHourMin(myActivityRecord.totalWorkingHours)}` : "-",
                totalWorkingHours: context !== "new" && myActivityRecord.clockRecord && myActivityRecord.clockRecord.totalHours  ? myActivityRecord.clockRecord.totalHours :
                GetWorkedHours(
                    FormatDateToFront(myActivityRecord.clockRecord.entryTime),
                    myActivityRecord.clockRecord.leaveForBreak ? FormatDateToFront(myActivityRecord.clockRecord.leaveForBreak)  : null,
                    myActivityRecord.clockRecord.returnFromBreak ? FormatDateToFront(myActivityRecord.clockRecord.returnFromBreak)  : null,
                    FormatDateToFront(serverTime)),
                activityRecords: myActivityRecord.activityRecord ? myActivityRecord.activityRecord : [],
                activityRecordsToDelete: []
            })
        }
    }, [serverTime,myActivityRecord]);



    useEffect(() => {
        if (activityCategories && activityCategories.length > 0) {
            let aux = activityCategories.map((activity) => {
                return {
                    label: activity.name,
                    value: activity.id,
                    deleted: activity.deleted
                }
            })
            setActivityCategoryArray(aux);
        }
    }, [activityCategories]);

    const redirect = go => {
        if (go) return <Redirect to={`/MyClockRecord`} />;
    };

    const handleSubmit = () => {
        let formData = new FormData();
        formData.append("id" , id);
        values.activityRecords.forEach( (element,idx) => {
            formData.append(`activityRecords[${idx}].id`, element.id ? element.id : 0);
            formData.append(`activityRecords[${idx}].name`, element.name ? element.name : "" );
            formData.append(`activityRecords[${idx}].description`, element.description ? element.description : "" );
            formData.append(`activityRecords[${idx}].rhActivityCategoriesId`, element.activityCategoryId ? element.activityCategoryId : element.rhActivityCategoriesId ? element.rhActivityCategoriesId :  "" )
        });
        values.activityRecordsToDelete.forEach( (element,idx) => {
            formData.append(`activityRecordsToDelete[${idx}].id`, element.id ? element.id : "" );
            formData.append(`activityRecordsToDelete[${idx}].name`, element.name );
            formData.append(`activityRecordsToDelete[${idx}].description`, element.description );
            formData.append(`activityRecordsToDelete[${idx}].rhActivityCategoriesId`, element.rhActivityCategoriesId )
        });
        if(context === "new"){
            addActivity(formData, setMustRedirect);
        }else {
            updateActivity(formData , setMustRedirect);
        }
    }

    const handleAddActivity = (newActivity) => {
        setValues({
            ...values,
            activityRecords : [...values.activityRecords, newActivity]
        })
    }

    const handleEditActivity = (index, activity) => {
        let auxArray = values.activityRecords;
        auxArray[index] = activity;
        setValues({
            ...values,
            activityRecords: auxArray,
        })
    }

    const handleDeleteActivity = (index) => {
        let auxArray = values.activityRecords;
        let removedActivity = auxArray.splice(index, 1);
        setValues({
            ...values,
            activityRecords: auxArray,
            activityRecordsToDelete: removedActivity[0].id ? [...values.activityRecordsToDelete, removedActivity[0]] : values.activityRecordsToDelete
        })
    }

    const handleCancel = () => {
        setMustRedirect(true);
    }

    return (
        <React.Fragment>
            {redirect(mustRedirect)}
            <Transition loading={loading} newDesign={true}>
                <HeaderContainer>
                    <Breadcrumb
                        newDesign={true}
                        routes={[
                            { label: t("HR.humanResource") , to: "/HumanResources" },
                            { label: t("HR.clock") },
                            { label: t("HR.myClockRecord"), to:"/MyClockRecord" },
                            {label: context === "view" ? `${t("GENERAL.view")} ${t("HR.dot")}` : context === "edit" ? `${t("GENERAL.edit")} ${t("HR.dot")}` : t("HR.ActivitiesReport")}
                        ]}
                    />
                    <div className="container-headerNewDesign">
                        <h1 className={classes.titleNewDesign}>{context === "view" ? `${t("GENERAL.view")} ${t("HR.dot")}` : context === "edit" ? `${t("GENERAL.edit")} ${t("HR.dot")}` : t("HR.ActivitiesReport")}</h1>
                    </div>

                </HeaderContainer>
                <ContentContainer>
                    <Paper className={styles.space}>
                        <div className="cabecalho">
                            {t("HR.clockRecord").toUpperCase()}
                        </div>
                        <Grid container item spacing={2} md={12} lg={12} style={{ padding: "32px" }}>

                            <Grid item xs={12} sm={2}>
                                <Typography className={styles.textMargin} variant={'RobotoBold'} fontSize={15} textColor={'primary'}>
                                    {t("HR.workload")}
                                    {context === "new" &&
                                        <DefaultTooltip fontSize={"11"} className={styles.iconsRight} color={"#6D6F71"} text={t("HR.workloadTooltip")}/>
                                    }
                                </Typography>
                                <Typography className={styles.textMargin} variant={'RobotoRegular'} fontSize={13} textColor={'secondary'}>
                                    {values.totalHours + " Diárias"}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={2}>
                                <Typography className={styles.textMargin} variant={'RobotoBold'} fontSize={15} textColor={'primary'}>
                                    {t("HR.RegistrationDate")}
                                    {context === "new" &&
                                        <DefaultTooltip fontSize={"11"} className={styles.iconsRight} color={"#6D6F71"} text={t("HR.registrationDateTooltip")}/>
                                    }
                                </Typography>
                                <Typography className={styles.textMargin} variant={'RobotoRegular'} fontSize={13} textColor={'secondary'}>
                                    {values.date}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={2}>
                                <Typography className={styles.textMargin} variant={'RobotoBold'} fontSize={15} textColor={'primary'}>
                                    {t("HR.workedHours")}
                                    {context === "new" &&
                                        <DefaultTooltip fontSize={"11"} className={styles.iconsRight} color={"#6D6F71"} text={t("HR.workedHoursTooltip")}/>
                                    }
                                </Typography>
                                <Typography className={styles.textMargin} variant={'RobotoRegular'} fontSize={13} textColor={'secondary'}>
                                    {values.status !== "JUSTIFIED" ? values.totalWorkingHours : values.totalHours}
                                </Typography>
                            </Grid>
                            <Box width="100%" />

                            <Grid item xs={12} sm={2}>
                                <Typography className={styles.textMargin} variant={'RobotoBold'} fontSize={15} textColor={'primary'}>
                                    {t("HR.entry")}
                                </Typography>
                                <Typography className={styles.textMargin} variant={'RobotoRegular'} fontSize={13} textColor={'secondary'}>
                                    {values.entryTime}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={2}>
                                <Typography className={styles.textMargin} variant={'RobotoBold'} fontSize={15} textColor={'primary'}>
                                    {t("HR.leaveForBreak")}
                                </Typography>
                                <Typography className={styles.textMargin} variant={'RobotoRegular'} fontSize={13} textColor={'secondary'}>
                                    {values.leaveForBreak}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={2}>
                                <Typography className={styles.textMargin} variant={'RobotoBold'} fontSize={15} textColor={'primary'}>
                                    {t("HR.returnFromBreak")}
                                </Typography>
                                <Typography className={styles.textMargin} variant={'RobotoRegular'} fontSize={13} textColor={'secondary'}>
                                    {values.returnFromBreak}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={2}>
                                <Typography className={styles.textMargin} variant={'RobotoBold'} fontSize={15} textColor={'primary'}>
                                    {t("HR.leave")}
                                </Typography>
                                <Typography className={styles.textMargin} variant={'RobotoRegular'} fontSize={13} textColor={'secondary'}>
                                    {values.leaveTime}
                                </Typography>
                            </Grid>
                            <Box width="100%" />
                            { context !== "view" ?
                            <>
                                <Typography className={styles.myActivitiesTextsPadding} variant={'RobotoBold'} fontSize={20} textColor={'secondary'}>
                                        {t("HR.youNeedAtLeastOneActivity")}
                                </Typography>

                                <Box width="100%" />

                                <Typography className={styles.myActivitiesTextsPadding} variant={'RobotoRegular'} fontSize={13} textColor={'secondary'}>
                                        {t("HR.clickOnBellowButton")}
                                </Typography>

                                <Box width="100%" />

                                <Buttons
                                    onClick={() => {setOpenDialog(true)}}
                                    className={styles.blueBorder}
                                    tipo="BN-noback"
                                    conteudo={t("HR.addActivity")}
                                />

                                <Box width="100%" />
                                </> : null
                            }
                            <div style={{width: "100%", marginTop: "32px"}}>
                                <div className="cabecalho">
                                    {t("HR.myActivities").toUpperCase()}
                                </div>
                                <MyActivitiesTable
                                    rows={values.activityRecords}
                                    headers={["id", "activityCategory", "name", "description","rhActivityCategoriesId", "activityCategoryId"]}
                                    visibleHeaders={["Categoria de Atividade", "Nome da Atividade", "Descrição", ""]}
                                    sortedFields={["activityCategory", "name", "description"]}
                                    mainField="activityCategory"
                                    context={context}
                                    activityCategoryArray={activityCategoryArray}
                                    handleEditActivity={handleEditActivity}
                                    handleDeleteActivity={handleDeleteActivity}
                                />
                                {values.activityRecords.length === 0 ?
                                    <div className={classes.messagePaper}>
                                        <div style={{ padding: "30px 0px 10px 0px", fontSize: "22px", fontWeight: "bold" }} >{t("HR.noActivitiesRecordMessage")}</div>
                                        <div style={{ padding: "0px 0px 30px 0px", fontSize: "15px" }} dangerouslySetInnerHTML={{ __html: t("HR.noActivitiesRecordSubMessage") }} />
                                    </div> : null
                                }
                            </div>
                            <Box width="100%" />
                            <div className={styles.buttonsWrapper}>
                                <Buttons
                                    onClick={handleCancel}
                                    tipo="BN-noback"
                                    conteudo={t("GENERAL.comeBack")}
                                />
                                {context !== "view" &&
                                    <Buttons
                                        disabled={values.activityRecords.length === 0}
                                        onClick={handleSubmit}
                                        tipo="BN-blue"
                                        conteudo={context === "new" ? t("HR.saveAndCloseWorkday") : t("GENERAL.save")}
                                    />
                                }
                            </div>
                        </Grid>
                    </Paper>
                {openDialog &&
                    <DialogMyActivity
                        context={"new"}
                        open={openDialog}
                        setOpen={setOpenDialog}
                        activityCategoryArray={activityCategoryArray}
                        addActivity={handleAddActivity}
                    />
                }
                </ContentContainer>
                <Footer />
            </Transition>
        </React.Fragment >

    );
};

export default MyActivitiesForm;