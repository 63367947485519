import React, {useContext, useState, useEffect} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from '@material-ui/icons/Close';
import Buttons from "components/buttons/Pressed";
import IconButton from "@material-ui/core/IconButton";
import Typography from "components/typography/Typography";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";
import { screenStyles } from "./Styles"


import ClockRecordContext from "context/hrModule/clockRecord/clockRecordContext";
import Link from "react-router-dom/Link";

import { FormatDateToFront } from "utils/dates/FormatDateToFront";
import { GetWorkedHours } from "utils/dates/GetWorkedHours";


const DialogCancelOperation = props => {
    const {open, setOpen, currentClockRecord, clockType, handleSubmit, registersActivity, workerdHoursRealTime } = props;
    const clockRecordContext = useContext(ClockRecordContext); 
    const {
        getServerTime,
        serverTime,
    } = clockRecordContext;

    const classes = useStyles();
    const styles = screenStyles();
    const [t] = useTranslation();
    const [currentTime , setCurrentTime] = useState("-");

    useEffect(() => {
        getServerTime();
    },[])

    useEffect(() => {
        if (serverTime !== null && clockType === "LEAVE_TIME"){                        
            setCurrentTime(GetWorkedHours(
                FormatDateToFront(currentClockRecord.entryTime), 
                currentClockRecord.leaveForBreak ? FormatDateToFront(currentClockRecord.leaveForBreak) : null,
                currentClockRecord.leaveForBreak? FormatDateToFront(currentClockRecord.returnFromBreak): null,
                FormatDateToFront(serverTime)))
        }
    },[serverTime])

    return(
        <Dialog
            open={open}
            aria-labelledby="form-dialog-title"
            msg="mensagem"
            maxWidth="sm"
            >
            <DialogTitle className={classes.Title}>
                <b>
                    {   
                    clockType === "ENTRY_TIME" ? t("HR.recordEntry") :
                    clockType === "LEAVE_FOR_BREAK" ? t("HR.recordLeaveForBreakDialogTitle") :
                    clockType === "RETURN_FROM_BREAK"  ? t("HR.recordReturnFromBreakDialogTitle") : 
                    t("HR.recordLeaveDialogTitle")
                    }
                </b>
                <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={()=> setOpen(false)} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {
                        clockType === "ENTRY_TIME" ? t("HR.recordEntryDialogMessage") :
                        clockType === "LEAVE_FOR_BREAK" ? t("HR.recordLeaveForBreakDialogMessage") :
                        clockType === "RETURN_FROM_BREAK"  ? t("HR.recordReturnFromBreakDialogMessage") : 
                        t("HR.recordLeaveDialogMessage")
                    }
                </DialogContentText>
                {clockType === "LEAVE_TIME" ? 
                    <div className={styles.collaboratorInfoDailyClockWorkedHours}>
                        <AccessTimeIcon fontSize="small" style={{color: "#2C3E51"}} className={styles.icons} />
                        <Typography className={styles.textMargin} variant={'RobotoSemiBold'} fontSize={15} textColor={'primary'}>{t("HR.workedHours")}:</Typography>
                        <Typography className={`${styles.textMargin} ${styles.textLineHeight}`} variant={'RobotoRegular'} fontSize={13} textColor={'secondary'}>{workerdHoursRealTime}</Typography>
                    </div>
                : null }
            </DialogContent>
            <DialogActions>
                <Buttons
                    onClick={() => setOpen(false)}
                    tipo="BN-noback"
                    conteudo={t("GENERAL.cancel")}
                />
                {
                    clockType === "LEAVE_TIME" && registersActivity ?
                    <Link to={`/MyActivities/${currentClockRecord.id}`}>
                        <Buttons
                            tipo="BN-blue"
                            conteudo={
                                t("HR.goToActivitiesReport")
                            }
                        />                    
                    </Link>
                    :
                <Buttons
                    onClick={() => {handleSubmit(); setOpen(false)}}
                    tipo="BN-blue"
                    conteudo={
                        clockType === "ENTRY_TIME" ? t("HR.recordEntry") :
                        clockType === "LEAVE_FOR_BREAK" ? t("HR.leaveForBreak") :
                        clockType === "RETURN_FROM_BREAK" ? t("HR.returnFromBreak") :
                        t("HR.closeWorkday") 
                    }
                />
                }
            </DialogActions>
        </Dialog>
    )
};

export default DialogCancelOperation;                                    