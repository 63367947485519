import ProjectsContext from "context/projects/projectsContext";
import React, { useContext, useEffect, useState } from "react";
import { animated, useTransition } from "react-spring";
import Spinner from "../../layout/spinners/Index";
import PhasesTable from "./PhasesTable";
import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";
import Add from "@material-ui/icons/Add";
import Dissatisfied from "@material-ui/icons/SentimentVeryDissatisfied";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Search from "@material-ui/icons/Search";
import AllowancesContext from "context/allowance/allowanceContext";
import { Link } from "react-router-dom";
import MySnackbarContentWrapper from "../../layout/Message";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";

const PhasesIndex = props => {
    const { projectId, phases, seasonsArray, mode, allAllowances } = props;
    const [filter, setFilter] = useState("");
    // const { filter, searchTermChanged } = searchContext;
    const [t] = useTranslation();
    const classes = useStyles();
    const general = seasonsArray[seasonsArray.length - 1] ? seasonsArray[seasonsArray.length - 1].value : "";
    const [season, setSeason] = useState(seasonsArray[seasonsArray.length - 1] ? seasonsArray[seasonsArray.length - 1].label : "");

    
    var dataFilter = phases;
    const hasPhase = phases.length > 0;
    const renderTableActions = data => {
        return (
            <PhasesTable
                rows={data}
                headers={["id", "name", "startdate", "enddate", "phaseStatus"]}
                sortedFields={["name", "startdate", "enddate", "phaseStatus"]}
                visibleHeaders={[
                    "NOME",
                    "DATA DE INÍCIO",
                    "DATA DE TÉRMINO",
                    "STATUS"
                ]}
                mainField="name"
                projectId={projectId}
                mode={mode}
                allAllowances={allAllowances}
            />
        );
    };
    const [tableActions, setTableActions] = useState(
        renderTableActions(dataFilter)
    );
    const projectContext = useContext(ProjectsContext);
    const allowanceContext = useContext(AllowancesContext);
    const {
        loading,
        response,
        responseType,
        successiveActions,
        hasChanged,
        setHasChanged,
    } = projectContext;
    const {
        allowanceResponse,
        allowanceResponseType,
        messageHasChanged,
        resetAllowanceMessage
    } = allowanceContext;
    const [snackBar, setSnackBar] = useState({
        open: false,
        kind: "",
        content: ""
    });
    const [hasNoResult, setHasNoResult] = useState(hasPhase && dataFilter.length === 0);
    const transitions = useTransition(loading, null, {
        from: { opacity: 0 },
        enter: {
            opacity: 1,
        },
        leave: { opacity: 0 }
    });

    useEffect(() => {
        if (allowanceResponseType === "ERROR" && messageHasChanged) {
            handleSnack({ kind: "error", content: allowanceResponse });
        }
        if (responseType === "SUCCESS" && hasChanged > successiveActions) {
            handleSnack({ kind: "success", content: response });
        }
        if (responseType === "ERROR" && hasChanged > successiveActions) {
            handleSnack({ kind: "error", content: response });
        }
        return () => { };
    }, [
        responseType,
        response,
        successiveActions,
        allowanceResponseType,
        allowanceResponse
    ]);

    const getPermission = (screen, allowance) => {
        for (let i = 0; i < allAllowances.length; i++) {
            if (allAllowances[i].resource === screen) {
            return allAllowances[i]["standardauthorizationoption"][allowance]
           }
        }
    }

    const handleSnack = () => {
        setSnackBar({ ...snackBar, open: true });
    };

    const handleSnackClose = () => {
        setSnackBar({ ...snackBar, open: false });
        setHasChanged();
        resetAllowanceMessage();
    };

    const getId = season => {
        var id = 0;

        for (var i = 0; i < seasonsArray.length; i++) {
            if (seasonsArray[i].label === season) {
                id = seasonsArray[i].value;
                break;
            }
        }
        return id;
    };

    const handleChangeSeason = event => {
        var id = getId(event.target.value);
        let season = seasonsArray.filter(el => el.value === id)[0];

        if (id === general)
            dataFilter = phases;
        else {
            dataFilter = phases.filter(el =>
                (
                    new Date(el.startdate) >= new Date(season.startDate) &&
                    new Date(el.startdate) <= new Date(season.endDate)
                ) ||
                (
                    new Date(el.enddate) >= new Date(season.startdate) &&
                    new Date(el.enddate) <= new Date(season.endDate)
                ));
        }

        if (filter) {
            dataFilter = dataFilter.filter(
                item =>
                    item.name.toLowerCase().includes(filter.toLowerCase())
            );
        }

        setSeason(event.target.value);
        setTableActions(renderTableActions(dataFilter));
    };

    const handleSearch = event => {
        setFilter(event.target.value);
        var id = getId(season);
        let period = seasonsArray.filter(el => el.value === id)[0];

        if (id === general)
            dataFilter = phases;
        else {
            dataFilter = phases.filter(el =>
                (
                    new Date(el.startdate) >= new Date(period.startDate) &&
                    new Date(el.startdate) <= new Date(period.endDate)
                ) ||
                (
                    new Date(el.enddate) >= new Date(period.startdate) &&
                    new Date(el.enddate) <= new Date(period.endDate)
                ));
        }

        dataFilter = dataFilter.filter(
            item =>
                item.name.toLowerCase().includes(event.target.value.toLowerCase())
        );

        if (dataFilter.length === 0)
            setHasNoResult(true);
        else
            setHasNoResult(false);

        setTableActions(renderTableActions(dataFilter));
    };

    const hasAddendum = () => {
        if (season) {
            if (mode === t("GENERAL.editMode")) {
                return (
                    <React.Fragment>
                    {allAllowances.length > 0 && getPermission("PHASES","allowCreate") && 
                        <Link to={`/execution/addPhase/${projectId}`}>
                            <Button
                                style={{ margin: "22px", padding:"15px" }}  
                            >
                                <div className={classes.buttonIcon}>
                                    <Add />
                                </div>
                                {t("PHASES.addPhase")}
                            </Button>
                        </Link>}
                    </React.Fragment>
                )
            }
        } else {
            return (
                <p className={classes.Hint}>
                    {t("PHASES.hintNoAddendum")}
                </p>
            )
        }
    };

    const cleanPageProjectPhases = () => {
        if(hasPhase){
            document.getElementById("cleanPageProjectPhases").click();
        }
    };

    return transitions.map(({ item, key, props }) =>
        item ?
            <animated.div key={key} style={props}>
                <Spinner />;
            </animated.div>
            :
            <animated.div key={key} style={props}>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={snackBar.open}
                    onClose={handleSnackClose}
                    TransitionComponent={Slide}
                    transitionDuration={{ enter: 500, exit: 500 }}
                    ContentProps={{
                        "aria-describedby": "message-id"
                    }}
                    autoHideDuration={2000}
                >
                    <MySnackbarContentWrapper
                        onClose={handleSnackClose}
                        variant={
                            responseType !== ""
                                ? responseType.toLowerCase()
                                : allowanceResponseType.toLowerCase()
                        }
                        message={
                            <span id="message-id">
                                {response !== "" ? response : allowanceResponse}
                            </span>
                        }
                    />
                </Snackbar>
                <Paper>
                    <div className={classes.divAddParticipant}>
                        <div className={classes.divAddParticipantLeft}>
                            <Toolbar className={classes.searchToolbar}>
                                {hasPhase ? 
                                <div className={classes.search}>
                                <div className={classes.searchIcon}>
                                    <SearchIcon />
                                </div>
                                <InputBase
                                    placeholder={t("PHASES.phasesSearchLabel")}
                                    value={filter}
                                    onFocus={cleanPageProjectPhases}
                                    onChange={handleSearch}
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput
                                    }}
                                    inputProps={{ "aria-label": "search" }}
                                    style={{ width: "100%"}}
                                />
                                </div>
                                : <></>}
                            </Toolbar>
                            <div>
                                <TextField
                                    style={{ display: !hasPhase ? "none" : "" }}
                                    select
                                    id="phaseSeason"
                                    label={t("PROJECTS.season")}
                                    className={classes.textField, classes.participantSearch}
                                    name="phaseSeason"
                                    margin="normal"
                                    value={season}
                                    onChange={handleChangeSeason}
                                >
                                    {seasonsArray.map(option => (
                                        <MenuItem key={option.value} value={option.label}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </div>
                        {hasAddendum()}
                    </div>
                    <div className="lineBreak"></div>
                    {hasPhase ? tableActions :
                        <div className={classes.noParticipantsMessage}>
                            <h2>{t("PHASES.noPhasesMessage")}</h2>
                            <h4>{t("PHASES.noPhasesSubMessage")}</h4>
                        </div>
                    }
                    {hasNoResult && filter &&
                        <div className={classes.noParticipantsMessage}>
                            <div >
                                <Dissatisfied className={classes.notFoundIconStyle} />
                            </div>
                            <h2>{t("GENERAL.noResultsFound")}</h2>
                            <h5>{`Sua busca por "${filter}" não retornou resultados.`}</h5>
                        </div>
                    }
                </Paper>
            </animated.div>
    );
};

export default PhasesIndex;