import React, { useState, useEffect, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Buttons from "components/buttons/Pressed";
import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";
import Input from "components/inputs/Input";
import InventoryContext from "context/inventory/inventoryContext";
import { Redirect } from "react-router-dom";
import { dateToBR } from "utils/dates/DateToBRr";

const redirect = go => {
    if (go) return <Redirect to={`/CollaboratorsRequests`} />;
};

const DialogReqApprove = (props) => {
    var FormatDateToDataBase = require("../../../utils/dates/FormatDateToDataBase").FormatDateToDataBase;
    const [t] = useTranslation();
    const classes = useStyles();
    const {
        open,
        setOpen,
        typeName,
        requisitionId,
        approverId,
        dateRequisition,
        context,
        type,
        itemsToBeMoved,
        itemsToBeReturn,
        itemsChangeOfLocal,
        softwaresRequisitions,
        requisition,
        patrimonyManager,
        executeName
    } = props;

    const inventoryContext = useContext(InventoryContext);
    const [values, setValues] = useState({
        observation: "",
        justification: ""

    });
    const [requiredFieldJustification, setRequiredFieldJustification] = useState(false);
    const [mustRedirect, setMustRedirect] = useState(false);


    const verifyCanSubmit = () => {
        let res = true;
        if (context === "refuse") {
            if (!values.justification) {
                res = false;
                setRequiredFieldJustification(true);
            }
        }
        return res;
    };
    const { AdmExecutedRequisitions } = inventoryContext;

    const clear = () => {
        setValues({
            ...values,
            observation: "",
            justification: "",
        });
    }

    const handleClose = () => {
        setOpen(false);
        setRequiredFieldJustification(false);
        clear();
    };

    const handleSubmit = () => {
        const canSubmit = verifyCanSubmit();
        if (canSubmit) {
            let formData = new FormData();

            var emailDatasManager = []
            var auxEmail = {}

            auxEmail.user = [{ name: requisition.approver, email: requisition.approverEmail, url: process.env.REACT_APP_FRONT + `Requisition/${requisition.id}` }];
            auxEmail.type = typeName;
            auxEmail.date = new Date();
            auxEmail.name = requisition.requesterShortName;
            auxEmail.patrimonyName = executeName;
            auxEmail.approve = context === "approve" ? true : false;
            auxEmail.motivationBool = context === "approve" ? values.observation.length > 0 : values.justification.length > 0;
            auxEmail.motivationData = context === "approve" ? values.observation : values.justification;
            auxEmail.motivationName = context === "approve" ? "Observação" : "Motivo";
            auxEmail.itens = [];

            emailDatasManager.push(auxEmail)

            var auxEmail = {}

            var emailDatasCollaborator = []
            var auxEmailCollaborator = {}

            auxEmailCollaborator.user = [{ name: requisition.requester, email: requisition.requesterEmail, url: process.env.REACT_APP_FRONT + `Requisition/${requisition.id}` }];
            auxEmailCollaborator.type = typeName;
            auxEmailCollaborator.date = new Date();
            auxEmailCollaborator.name = requisition.requesterShortName;
            auxEmailCollaborator.patrimonyName = executeName;
            auxEmailCollaborator.approve = context === "approve" ? true : false;
            auxEmailCollaborator.motivationBool = context === "approve" ? values.observation.length > 0 : values.justification.length > 0;
            auxEmailCollaborator.motivationData = context === "approve" ? values.observation : values.justification;
            auxEmailCollaborator.motivationName = context === "approve" ? "Observação" : "Motivo";
            auxEmailCollaborator.itens = []
            // if(type ===  "use_of_equipment"){

            // }
            // if(type ===  "use_of_software"){}
            // if(type ===  "return_of_software"){}
            // if(type ===  "return_of_equipment"){
            //     var aux = []
            //     var itens = [] 
            //     if(itemsToBeReturn.length > 0){
            //         for (let i in itemsToBeReturn) {
            //             var element = itemsToBeReturn[i];
            //             aux.push(element.name, element.code, element.serialNumber, element.serialNumber, element.place, element.newResponsible, element.newPlace, element.newStatus, element.returned)
            //             itens.push(aux)
            //             console.log("aux",aux)

            //             }
            //             console.log("gg",itens)
            //         }
            //     auxEmailCollaborator.itens = itens
            // }
            // console.log(auxEmailCollaborator)
            // if(type ===  "change_of_local"){
            //     itemsToBeMoved.forEach(element => {
            //         var aux = []

            //         aux.push(element.name, element.code, element.machine)
            //         auxEmailCollaborator.itens = [];
            //     });
            // }

            emailDatasCollaborator.push(auxEmailCollaborator)

            var auxEmailCollaborator = {}

            formData.append(`executorId`, approverId)
            formData.append(`dateCreated`, FormatDateToDataBase(new Date()));
            formData.append(`executorResponse`, context === "approve" ? values.observation : values.justification);
            formData.append(`executed`, context === "approve" ? true : false)

            if (type === "change_of_local") {
                if (itemsToBeMoved.length > 0) {
                    for (let i in itemsToBeMoved) {
                        formData.append(`itemsToBeMoved[${i}].id`, itemsToBeMoved[i].id)
                        formData.append(`itemsToBeMoved[${i}].itemId`, itemsToBeMoved[i].itemId)
                        formData.append(`itemsToBeMoved[${i}].newPlaceId`, itemsToBeMoved[i].newPlaceId)
                    }
                }
            }

            if (type === "return_of_equipment") {
                if (itemsToBeReturn.length > 0) {
                    for (let i in itemsToBeReturn) {
                        formData.append(`itemsToBeReturn[${i}].id`, itemsToBeReturn[i].id)
                        formData.append(`itemsToBeReturn[${i}].change`, itemsToBeReturn[i].change ? itemsToBeReturn[i].change : false)
                        formData.append(`itemsToBeReturn[${i}].itemId`, itemsToBeReturn[i].itemId === 0 ? null : itemsToBeReturn[i].itemId)
                        formData.append(`itemsToBeReturn[${i}].newPlaceId`, itemsToBeReturn[i].newPlaceId ? itemsToBeReturn[i].newPlaceId : "")
                        formData.append(`itemsToBeReturn[${i}].newResponsibleId`, itemsToBeReturn[i].newResponsibleId ? itemsToBeReturn[i].newResponsibleId : "")
                        formData.append(`itemsToBeReturn[${i}].newExternalResponsibleId`, itemsToBeReturn[i].newExternalResponsibleId ? itemsToBeReturn[i].newExternalResponsibleId : "")
                        formData.append(`itemsToBeReturn[${i}].newStatusId`, itemsToBeReturn[i].newStatusId ? itemsToBeReturn[i].newStatusId : "")
                    }
                }
            }

            if (type === "return_of_software") {
                if (softwaresRequisitions.length > 0) {
                    for (let i in softwaresRequisitions) {
                        formData.append(`softwareToBeReturn[${i}].id`, softwaresRequisitions[i].id)
                        formData.append(`softwareToBeReturn[${i}].change`, softwaresRequisitions[i].change ? softwaresRequisitions[i].change : false)
                        formData.append(`softwareToBeReturn[${i}].itemId`, softwaresRequisitions[i].newMachineId ? softwaresRequisitions[i].newMachineId : "")
                        formData.append(`softwareToBeReturn[${i}].licenseId`, softwaresRequisitions[i].licenseId)
                        formData.append(`softwareToBeReturn[${i}].newMachineId`, softwaresRequisitions[i].NewMachineId ? softwaresRequisitions[i].newMachineId : "")
                        formData.append(`softwareToBeReturn[${i}].newResponsibleId`, softwaresRequisitions[i].newResponsibleId ? softwaresRequisitions[i].newResponsibleId : "")
                        formData.append(`softwareToBeReturn[${i}].newExternalResponsibleId`, softwaresRequisitions[i].newExternalResponsibleId ? softwaresRequisitions[i].newExternalResponsibleId : "")
                        formData.append(`softwareToBeReturn[${i}].newStatus`, softwaresRequisitions[i].newStatus === "Disponível" ? "AVAILABLE" : "UNAVAILABLE")
                    }
                }
            }

            if (type === "use_of_software") {
                if (softwaresRequisitions.length > 0) {
                    for (let i in softwaresRequisitions) {
                        formData.append(`softwareToBeUsed[${i}].id`, softwaresRequisitions[i].id)
                        formData.append(`softwareToBeUsed[${i}].change`, softwaresRequisitions[i].change ? softwaresRequisitions[i].change : false)
                        formData.append(`softwareToBeUsed[${i}].itemId`, softwaresRequisitions[i].newMachineId ? softwaresRequisitions[i].newMachineId : "")
                        formData.append(`softwareToBeUsed[${i}].licenseId`, softwaresRequisitions[i].licenseId ? softwaresRequisitions[i].licenseId : "")
                        formData.append(`softwareToBeUsed[${i}].newMachineId`, softwaresRequisitions[i].newMachineId ? softwaresRequisitions[i].newMachineId : "")
                        formData.append(`softwareToBeUsed[${i}].newResponsibleId`, softwaresRequisitions[i].newResponsibleId ? softwaresRequisitions[i].newResponsibleId : "")
                        formData.append(`softwareToBeUsed[${i}].newStatus`, softwaresRequisitions[i].status && softwaresRequisitions[i].status)
                    }
                }
            }

            if (type === "use_of_equipment") {
                if (itemsToBeReturn.length > 0) {
                    for (let i in itemsToBeReturn) {
                        formData.append(`itemsToBeUsed[${i}].id`, itemsToBeReturn[i].id)
                        formData.append(`itemsToBeUsed[${i}].change`, itemsToBeReturn[i].change ? itemsToBeReturn[i].change : false)
                        formData.append(`itemsToBeUsed[${i}].itemId`, itemsToBeReturn[i].itemId ? itemsToBeReturn[i].itemId : "")
                        formData.append(`itemsToBeUsed[${i}].newPlaceId`, itemsToBeReturn[i].newPlaceId ? itemsToBeReturn[i].newPlaceId : "")
                        formData.append(`itemsToBeUsed[${i}].newResponsibleId`, itemsToBeReturn[i].newResponsibleId ? itemsToBeReturn[i].newResponsibleId : "")
                        formData.append(`itemsToBeUsed[${i}].newStatusId`, itemsToBeReturn[i].newStatusId ? itemsToBeReturn[i].newStatusId : "")
                    }
                }
            }

            AdmExecutedRequisitions(requisitionId, formData, emailDatasManager, emailDatasCollaborator, "/RequisitionsAdm")
            setOpen(false);
            clear();
            //setMustRedirect(true);
        }
    };

    const handleChange = (name, value) => {
        setRequiredFieldJustification(false);
        setValues({ ...values, [name]: value });
    };

    return (
        <React.Fragment>
            {redirect(mustRedirect)}
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                aria-labelledby="form-dialog-title"
                msg="mensagem"
            >
                <DialogTitle className={classes.actionsTitle}>
                    <span style={{ fontWeight: "bold" }}>
                        {context === "approve"
                            ? t("INVENTORY.executeRequisition")
                            : t("INVENTORY.refuseRequisition")
                        }
                    </span>
                    <IconButton
                        style={{ float: "right", marginTop: -10, marginRight: -17 }}
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Grid container >
                            {context === "approve" ? t("INVENTORY.executeRequisitionMessage") : t("INVENTORY.refuseRequisitionMessage")}
                        </Grid>
                        <Grid container style={{ paddingTop: "20px" }}>
                            <Grid item xs={12} sm={4}>
                                <b>{t("GENERAL.date").toUpperCase()}</b>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <b>{t("INVENTORY.requestType").toUpperCase()}</b>
                            </Grid>
                        </Grid>
                        <Grid container style={{ paddingBottom: "10px" }}>
                            <Grid item xs={12} sm={4}>
                                {dateToBR(new Date(dateRequisition))}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {typeName}
                            </Grid>
                        </Grid>
                        <Input
                            error={requiredFieldJustification}
                            multiline
                            fullWidth
                            variant="outlined"
                            rows="4"
                            value={context === "approve" ? values.observation : values.justification}
                            valueName={context === "approve" ? "observation" : "justification"}
                            label={context === "approve" ? t("INVENTORY.observation") : t("INVENTORY.justification") + "*"}
                            margin="normal"
                            maxLength={200}
                            helperText={requiredFieldJustification ? t("GENERAL.requiredField") : context === "approve" ? `${values.observation.length > 0 ? values.observation.length : 0}/200` : `${values.justification.length > 0 ? values.justification.length : 0}/200`}
                            handleChange={handleChange}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Buttons
                        onClick={handleClose}
                        tipo="BN-noback"
                        conteudo={t("GENERAL.cancel").toLocaleUpperCase()}
                    />
                    <Buttons
                        onClick={handleSubmit}
                        tipo="BN-blue"
                        conteudo={context === "approve" ? t("GENERAL.execute").toLocaleUpperCase() : t("GENERAL.refuse").toLocaleUpperCase()}
                    />
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default DialogReqApprove;
