/* eslint-disable */
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import ProjectsContext from "context/projects/projectsContext";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { StyledTableCell2, StyledTableRow, useStyles } from "../../profiles/ProfileStyles";

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    flexGrow: 1,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5)
  }
}));

var id = [];

var idObject = [];
id.pop();
idObject.pop();

function desc(a, b, orderBy) {
  // .normalize("NFD").replace(/[\u0300-\u036f]/g, "") : desconsidera
  // acentos
  if (a[orderBy] === null) a[orderBy] = "";
  if (b[orderBy] === null) b[orderBy] = "";

  if (
    b[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "") <
    a[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "")
  ) {
    return -1;
  }
  if (
    b[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
    a[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "")
  ) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const handleChange = event => {
    setChecked(event.target.checked);
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
            <KeyboardArrowLeft />
          )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
            <KeyboardArrowRight />
          )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

function createContent(rows, headers, order, orderBy) {
  const rowsCopy = [];
  var obj = {};
  for (var i = 0; i < rows.length; i++) {
    for (var j = 0; j < headers.length; j++) {
      obj[headers[j]] = rows[i][headers[j]];
    }
    rowsCopy.push(obj);
    obj = {};
  }
  return stableSort(rowsCopy, getSorting(order, orderBy));
}

const CustomPaginationActionsTable = props => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState({});
  const [rowsLength, setRowsLength] = useState(0);
  const [headers, setHeaders] = useState([]);
  const [mainField, setMainField] = useState("");
  const [visibleHeaders, setVisibleHeaders] = useState([]);
  const [sortedFields, setSortedFields] = useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [checked, setchecked] = useState(0);
  const [mustRedirect, setMustRedirect] = useState(false);

  const projectsContext = useContext(ProjectsContext);
  const { submitParticipant, completeSubmission } = projectsContext;
  var idU = window.location.href;
  var iduser = idU.split("/");

  React.useEffect(() => {
    setHeaders(props.headers);
    setRows(props.rows);
    setRowsLength(props.rows.length);
    setMainField(props.mainField);
    setVisibleHeaders(props.visibleHeaders);
    setSortedFields(props.sortedFields);
    adjustRowsPerpage();
  }, [props.headers, props.mainField, props.rows, props.visibleHeaders]);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  const adjustRowsPerpage = () => {
    if (props.rows.length >= 10) {
      setRowsPerPage(10);
    } else {
      setRowsPerPage(props.rows.length);
    }
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === sortedFields[property] && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(sortedFields[property]);
  };

  const getRowProperties = row => {
    return (
      <React.Fragment>
        {headers.map((prop, index) =>
          prop !== "id" ? (
            prop === mainField ? (
              <TableCell size="small" key={index} component="th" scope="row">
                {/* {row[prop]} */}
                <div className={classes.avatarParent}>
                  <div
                    className="avatar"
                    style={{
                      backgroundImage: `url(${row.imagePath})`
                    }}
                  ></div>
                </div>
              </TableCell>
            ) : (
                <TableCell size="small" key={index}>
                  {row[prop]}
                </TableCell>
              )
          ) : null
        )}
      </React.Fragment>
    );
  };

  const UsersTableHead = props => {
    const { order, orderBy, onRequestSort } = props;
    const [t] = useTranslation();
    const paginationTop = action => e => {
      var selector = "[aria-label^='" + action + "']";
      document.querySelector(selector).click();
      e.preventDefault();
    };
    const createSortHandler = property => event => {
      if (property > 1) {
        onRequestSort(event, property);
      }
    };

    return (
      <TableHead>
        <TableRow>
          {visibleHeaders.map((header, index) => (
            <StyledTableCell2
              key={index}
              size="small"
              align="left"
              sortDirection={orderBy === sortedFields[index] ? order : false}
            >
              <TableSortLabel
                active={orderBy === sortedFields[index]}
                direction={order}
                disabled={index === 1}
                onClick={createSortHandler(index)}
                hideSortIcon
              >
                {header.toUpperCase()}
                {orderBy === sortedFields[index] ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </StyledTableCell2>
          ))}
          <StyledTableCell2>
            <div className={classes.nextPageDiv}>
              <Tooltip title={t("GENERAL.previousPage")} placement="top">
                <Button
                  name="previous-page"
                  onClick={paginationTop("previous page")}
                >
                  <KeyboardArrowLeft />
                </Button>
              </Tooltip>
              <Tooltip title={t("GENERAL.nextPage")} placement="top">
                <Button name="next-page" onClick={paginationTop("next page")}>
                  <KeyboardArrowRight />
                </Button>
              </Tooltip>
            </div>
          </StyledTableCell2>
        </TableRow>
      </TableHead>
    );
  };

  var idCurrentOfPage = [];
  var listIds = [];

  UsersTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired
  };

  var verify = false;
  function verifyIsChecked(idVerify) {
    idCurrentOfPage.push(idVerify);
    for (var i in idCurrentOfPage) {
      for (var j in id) {
        if (idCurrentOfPage[i] == id[j]) {
          listIds.push(idCurrentOfPage[i]);
        }
      }
    }
    verify = listIds.includes(idVerify);
  }

  let userschecked = new Object();

  function handleSwitchParticipants(e) {
    var contains = id.includes(e.target.value);
    if (contains) {
      id = id.filter(el => el !== e.target.value);
      idObject = idObject.filter(el => el.id != e.target.value);
    } else {
      id.push(e.target.value);
      userschecked = rows.filter(el => el.id == e.target.value);
      idObject.push(userschecked[0]);
    }
  }

  const submit = () => {
    if (id.length !== 0) {
      submitParticipant(idObject, iduser[5]);
      setMustRedirect(true);
      //Limpar os arrays
      while (listIds.length) {
        listIds.pop();
      }
      while (idObject.length) {
        idObject.pop();
      }
      while (id.length) {
        id.pop();
      }
      while (idCurrentOfPage.length) {
        idCurrentOfPage.pop()
      }
    }
  };

  function cleanPageToSearch() {
    setPage(0);
  }

  function change() {
    setchecked(Math.random() * 1000);
  }

  const renderRedirect = go => {
    if (go) return <Redirect to={`/execution/projects/${iduser[5]}/edit`} />;
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table className={classes.table} size="small">
          {renderRedirect(mustRedirect && completeSubmission)}
          <UsersTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {createContent(rows, headers, order, orderBy)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <StyledTableRow key={row.id + checked}>
                  <TableCell
                    size="small"
                    align="left"
                    onChange={verifyIsChecked(row.id)}
                  >
                    <Checkbox
                      className="projectParticipant"
                      id={"divParticipant" + index}
                      color="primary"
                      key={row.id}
                      value={row.id}
                      onClick={change}
                      checked={verify}
                      onChange={handleSwitchParticipants}
                    />
                  </TableCell>
                  {getRowProperties(row)}
                </StyledTableRow>
              ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 48 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow style={{ display: "none" }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={visibleHeaders.length + 1}
                count={rowsLength}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  "aria-label": "previous page"
                }}
                nextIconButtonProps={{
                  "aria-label": "next page"
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
        <button hidden id="submitButton" onClick={submit}></button>
        <button
          hidden
          id="cleanPageToSearch"
          onClick={cleanPageToSearch}
        ></button>
      </div>
    </Paper>
  );
};

CustomPaginationActionsTable.propTypes = {
  rows: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
  visibleHeaders: PropTypes.array.isRequired,
  mainField: PropTypes.string.isRequired
};

export default CustomPaginationActionsTable;
