/* eslint-disable */
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  breadCrumbs: {
    marginTop: "30px",
    marginLeft: "10px",
    flexWrap: "wrap"
  },
  title: {
    color: "#717272",
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontStyle: "normal"
  },
  link: {
    color: "#73b3ff"
  },
  notClicableBreadScrumbs: {
    color: "#828b91",
    cursor: "not-allowed"
  },
  table: {
    minWidth: 650
  },
  tableWrapper: {
    overflowX: "auto"
  },
  button: {
    marginTop: theme.spacing(2),
    marginBotton: theme.spacing(2)
  },
  textFieldNome: {
    marginLeft: "18px"
  },
  foto: {
    display: "flex",
    paddingBottom: "60px"
  },
  cabecalho: {
    backgroundColor: "#f5f6fa",
    height: "45px",
    opacity: "0.54",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "3.5",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000000",
    paddingLeft: "18px"
  },
  avatarParent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px"
  },

  bigAvatar: {
    margin: 10,
    borderStyle: "double",
    border: "solid 2px #f5f6fa"
  },
  textField: {
    display: "flex"
  },
  // foto: {
  //   display: "inline-table",
  //   paddingBottom: "60px"
  // },
  bodyForm: {
    paddingLeft: "18px",
    paddingRight: "18px",
    paddingBottom: "30px"
  },
  cabecalho: {
    backgroundColor: "#f5f6fa",
    height: "45px",
    opacity: "0.54",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "3.5",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000000",
    paddingLeft: "18px"
  },
  fab: {
    width: "70px",
    height: "31.1px",
    borderRadius: "16px",
    backgroundColor: "rgba(63, 226, 211, 0.54)"
  },
  menu: {
    width: 200
  },
  blueButton: {
    backgroundColor: "#2c3e51",
    padding: "14px 24px",
    fontWeight: "normal",
    fontSize: "14px",
    fontWeight: "500"
  },
  editButton: {
    float: "right",
    padding: "10px"
  },
  buttonIcon: {
    display: "flex",
    marginRight: "15px"
  }
}));

export { useStyles };
