import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import Buttons from 'components/buttons/Pressed';
import Typography from 'components/typography/Typography';
import { screenStyles } from '../Styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { AllAuthorsName } from 'utils/library/AllAuthorsName';
import {
    LibraryExemplarStatusValues,
    LibraryReservationStatusValues,
    LibraryPlaceValues
} from 'global/constants';
import { handleStatusCollection } from 'utils/library/handleStatusCollection';
import { DisponibleExemplarsAmount } from 'utils/library/DisponibleExemplarsAmount';
import { DialogUnitComponent } from 'pages/libraryModule/dialogs/DialogUtils';
import Link from 'react-router-dom/Link';
import Skeleton from '@material-ui/lab/Skeleton';
import { formatImagePath } from 'utils/library/formatImagePath';

const DialogBookMoreDetails = props => {
    const styles = screenStyles();
    const [t] = useTranslation();
    const [libraryPlace, setLibraryPlace] = useState(
        LibraryPlaceValues.GENERAL
    );
    const [existsInGeneralPlace, setExistsInGeneralPlace] = useState(true);
    const [existsInCoordinationPlace, setExistsInCoordinationPlace] =
        useState(true);
    const [exemplarsTotalCount, setExemplarsTotalCount] = useState(0);
    const [exemplarsDisponibleCount, setExemplarsDisponibleCount] = useState(0);
    const [status, setStatus] = useState('');
    const [loaded, setLoaded] = useState(false);

    const {
        open,
        setOpen,
        viewValues,
        submit,
        submitQueue,
        isMaxOfBooks,
        userReservationsAmount
    } = props;

    const handleLibraryPlace = value => {
        setLibraryPlace(value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        submit(viewValues.exemplars, libraryPlace);
        setOpen(false);
    };

    const handleSubmitQueue = () => {
        submitQueue(viewValues.id);
        setOpen(false);
    };

    const checkLibrary = () => {
        setExemplarsTotalCount(viewValues.exemplars.length);
        var general = false;
        var coordination = false;

        viewValues.exemplars.forEach(item => {
            if (
                item.status === LibraryExemplarStatusValues.DISPONIBLE &&
                item.library === LibraryPlaceValues.GENERAL
            ) {
                general = true;
                setExemplarsDisponibleCount(exemplarsDisponibleCount + 1);
            }
            if (
                item.status === LibraryExemplarStatusValues.DISPONIBLE &&
                item.library === LibraryPlaceValues.COORDINATION
            ) {
                coordination = true;
                setExemplarsDisponibleCount(exemplarsDisponibleCount + 1);
            }
        });

        if (!general) {
            setExistsInGeneralPlace(false);
        }
        if (!coordination) {
            setExistsInCoordinationPlace(false);
        }
        if (!general && coordination) {
            setLibraryPlace(LibraryPlaceValues.COORDINATION);
        }
    };

    useEffect(() => {
        checkLibrary();
        setStatus(
            handleStatusCollection(
                viewValues.exemplars,
                viewValues.reservations
            )
        );
    }, []);

    useEffect(() => {
        setLoaded(false);
    }, [viewValues.imagePath]);

    const isAlreadyQueued = viewValues.queue.length > 0;

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth={'sm'}
                fullWidth={true}
            >
                <DialogTitle>
                    <div className={styles.dialogTitle}>
                        <Typography
                            variant={'RobotoBold'}
                            fontSize={24}
                            textColor={'secondary'}
                        >
                            {t('LIBRARY.dialogBookMoreDetailsTitle')}
                        </Typography>
                        <IconButton
                            color="#6D6F71"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className={styles.dialogMoreDetailsDisponible}>
                        {status === LibraryExemplarStatusValues.DISPONIBLE &&
                        !isMaxOfBooks ? (
                            <CheckCircleIcon
                                style={{
                                    marginRight: '0.5rem',
                                    color: '#7FE46B'
                                }}
                                fontSize="small"
                            />
                        ) : (
                            <ErrorIcon
                                style={{
                                    marginRight: '0.5rem',
                                    color: '#2C3E51'
                                }}
                                fontSize="small"
                            />
                        )}
                        {status === LibraryExemplarStatusValues.DISPONIBLE &&
                        !isMaxOfBooks ? (
                            <Typography
                                variant={'RobotoBold'}
                                fontSize={14}
                                textColor={'primary'}
                            >
                                {DisponibleExemplarsAmount(
                                    viewValues.exemplars
                                )}{' '}
                                de {exemplarsTotalCount} disponíveis
                            </Typography>
                        ) : isAlreadyQueued ? (
                            <Typography
                                variant={'RobotoRegular'}
                                fontSize={14}
                                textColor={'primary'}
                            >
                                Você está na lista de espera para o empréstimo
                                deste livro. Para acompanhar os passos acesse a
                                aba <b>Reservas de Livro</b> na tela Meus
                                Empréstimos.
                            </Typography>
                        ) : isMaxOfBooks &&
                          status !==
                              LibraryReservationStatusValues.AWAITING_APPROVAL &&
                          status !==
                              LibraryReservationStatusValues.AWAITING_WITHDRAWAL ? (
                            <Typography
                                variant={'RobotoRegular'}
                                fontSize={14}
                                textColor={'primary'}
                            >
                                Você já está em posse ou solicitou{' '}
                                <b>{userReservationsAmount} livros</b> do
                                acervo. Acesse "<b>Meus empréstimos</b>" e
                                realize uma devolução para requisitar outros
                                livros.
                            </Typography>
                        ) : (
                            <Typography
                                variant={'RobotoBold'}
                                fontSize={14}
                                textColor={'primary'}
                            >
                                {t(`LIBRARY.dialogBookMoreDetails-${status}`)}
                            </Typography>
                        )}
                    </div>

                    {status !==
                        LibraryReservationStatusValues.AWAITING_APPROVAL &&
                    status !==
                        LibraryReservationStatusValues.AWAITING_WITHDRAWAL &&
                    status !== LibraryExemplarStatusValues.INDISPONIBLE &&
                    !isMaxOfBooks ? (
                        <div className={styles.libraryRadioButton}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">
                                    <Typography
                                        variant={'RobotoBold'}
                                        fontSize={12}
                                        textColor={'secondary'}
                                    >
                                        {t(
                                            'INVENTORY.place'
                                        ).toLocaleUpperCase()}
                                    </Typography>
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="position"
                                    name="position"
                                    defaultValue="top"
                                    value={libraryPlace}
                                    onChange={ev =>
                                        handleLibraryPlace(ev.target.value)
                                    }
                                >
                                    <FormControlLabel
                                        disabled={!existsInGeneralPlace}
                                        value={LibraryPlaceValues.GENERAL}
                                        control={<Radio color="primary" />}
                                        label={t('LIBRARY.generalPlace')}
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        disabled={!existsInCoordinationPlace}
                                        value={LibraryPlaceValues.COORDINATION}
                                        control={<Radio color="primary" />}
                                        label={t('LIBRARY.coordinationPlace')}
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    ) : null}
                    <div className={styles.dialogMoreDetailsContainerUpper}>
                        <div className={styles.dialogMoreDetailsLeftWrapper}>
                            <DialogUnitComponent
                                head={t('INVENTORY.title').toLocaleUpperCase()}
                                content={viewValues.title}
                            />
                            <DialogUnitComponent
                                head={t(
                                    'INVENTORY.subTitle'
                                ).toLocaleUpperCase()}
                                content={viewValues.subtitle}
                            />

                            <DialogUnitComponent
                                head={t(
                                    'INVENTORY.BookAuthor'
                                ).toLocaleUpperCase()}
                                content={AllAuthorsName(viewValues.authors)}
                            />
                        </div>
                        <div style={{ width: '140px', height: '210px' }}>
                            <img
                                className={styles.dialogMoreDetailsImage}
                                src={formatImagePath(
                                    viewValues.imagePath,
                                    viewValues.isImageUrl
                                )}
                                alt={viewValues.title}
                                style={!loaded ? { display: 'none' } : {}}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src =
                                        window.location.origin +
                                        '/img/no-book-cover.png';
                                    setLoaded(true);
                                }}
                                onLoad={() => {
                                    setLoaded(true);
                                }}
                            />
                            {!loaded && (
                                <Skeleton
                                    className={styles.dialogMoreDetailsImage}
                                    variant="rect"
                                    width={160}
                                    height={220}
                                />
                            )}
                        </div>
                    </div>

                    <div className={styles.dialogMoreDetailsContainerBottom}>
                        <div
                            className={
                                styles.dialogMoreDetailsAuthorEditionWrapper
                            }
                        >
                            <div style={{ width: '48%' }}>
                                <DialogUnitComponent
                                    head={t(
                                        'INVENTORY.category'
                                    ).toLocaleUpperCase()}
                                    content={viewValues.category.name}
                                />
                            </div>
                            <div style={{ width: '48%' }}>
                                <DialogUnitComponent
                                    head={t(
                                        'INVENTORY.language'
                                    ).toLocaleUpperCase()}
                                    content={viewValues.language}
                                />
                            </div>
                        </div>

                        <div
                            className={
                                styles.dialogMoreDetailsAuthorEditionWrapper
                            }
                        >
                            <div
                                style={{
                                    width: '48%'
                                }}
                            >
                                <div
                                    style={{ height: '23px' }}
                                    className={styles.dialogUnitComponent}
                                >
                                    <Typography
                                        variant={'RobotoBold'}
                                        fontSize={12}
                                        textColor={'secondary'}
                                    >
                                        {t(
                                            'LIBRARY.publisher'
                                        ).toLocaleUpperCase()}
                                    </Typography>
                                    <Typography
                                        className={
                                            styles.dialogMoreDetailsPublisher
                                        }
                                        variant={'RobotoRegular'}
                                        fontSize={14}
                                        textColor={'secondary'}
                                    >
                                        {viewValues.publisher}
                                    </Typography>
                                </div>
                            </div>
                            <div
                                style={{
                                    width: '48%',
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <div style={{ width: '48%' }}>
                                    <DialogUnitComponent
                                        head={t(
                                            'INVENTORY.edition'
                                        ).toLocaleUpperCase()}
                                        content={viewValues.edition}
                                    />
                                </div>
                                <div style={{ width: '48%' }}>
                                    <DialogUnitComponent
                                        head="ISBN"
                                        content={viewValues.isbn}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <Typography
                        variant={'RobotoBold'}
                        fontSize={14}
                        textColor={'secondary'}
                    >
                        {t('GENERAL.description')}
                    </Typography>
                    <Typography
                        className={styles.DialogBookMoreDetailsDescription}
                        variant={'RobotoRegular'}
                        fontSize={12}
                        textColor={'secondary'}
                    >
                        {viewValues.synopsis}
                    </Typography>
                </DialogContent>

                <DialogActions className={styles.dialogButtonsWrapper}>
                    <Buttons
                        onClick={handleClose}
                        tipo="BN-noback"
                        conteudo={t('GENERAL.close').toUpperCase()}
                    />

                    {status === LibraryExemplarStatusValues.DISPONIBLE &&
                    !isMaxOfBooks ? (
                        <Buttons
                            className={styles.dialogButton}
                            tipo="BN-blue"
                            conteudo={t(
                                'LIBRARY.dialogBookRequisitionTitle'
                            ).toUpperCase()}
                            onClick={handleSubmit}
                        />
                    ) : status ===
                          LibraryReservationStatusValues.AWAITING_APPROVAL ||
                      status ===
                          LibraryReservationStatusValues.AWAITING_WITHDRAWAL ? (
                        <Link to={`/Library/MyLoans?panel=PENDINGREQUISITIONS`}>
                            <Buttons
                                className={styles.dialogButton}
                                tipo="borderBackButton"
                                conteudo={t(
                                    'LIBRARY.goToRequisitions'
                                ).toUpperCase()}
                            />
                        </Link>
                    ) : isAlreadyQueued ? (
                        <Link to={`/Library/MyLoans?panel=QUEUEDBOOKS`}>
                            <Buttons
                                className={styles.dialogButton}
                                tipo="borderBackButton"
                                conteudo={'IR PARA RESERVAS DE LIVRO'}
                            />
                        </Link>
                    ) : isMaxOfBooks ? (
                        <Link to={`/Library/MyLoans`}>
                            <Buttons
                                className={styles.dialogButton}
                                tipo="BN-blue"
                                conteudo={'IR PARA MEUS EMPRÉSTIMOS'}
                            />
                        </Link>
                    ) : (
                        <Buttons
                            className={styles.dialogButton}
                            tipo="borderBackButton"
                            conteudo={'FAZER RESERVA'}
                            onClick={handleSubmitQueue}
                        />
                    )}
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DialogBookMoreDetails;
