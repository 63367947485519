import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from '@material-ui/icons/Close';
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import Buttons from "components/buttons/Pressed";
import Link from "react-router-dom/Link";
import TextField from '@material-ui/core/TextField';
import SettingsContext from "context/hrModule/settings/settingsContext";
import { useStyles } from "components/layout/CommonStyles";
import { useStyles as useAllowanceReasonStyles } from "./styles";
import { GetGeneralPermissionForAction } from "utils/permissions/GetGeneralPermissionForAction";
import { Screen, Allow } from "global/constants";
import DialogContainer from "components/containers/DialogContainer";
import DialogContentContainer from "components/containers/DialogContentContainer";
import Input from "components/inputs/Input";


const AllowanceReasonsTableActions = props => {
    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }
    const classes = useStyles();
    const styles = useAllowanceReasonStyles();
    const [t] = useTranslation();
    const settingsContext = useContext(SettingsContext);
    const [openDelete, setOpenDelete] = useState(false);
    const [changeContext, setchangeContex] = useState(0);
    const [password, setPassword] = useState("");
    const [passRight, setPassRight] = useState(false);
    const { item, fetchData } = props;
    const [justification, setJustification] = useState("");
    const [validJustification, setValidJustification] = useState(false);
    const blankField = "GENERAL.requiredField";
    const [validPasswordrequiredField, setPasswordRequiredField] = useState(false);

    const {
        deleteAllowanceReason
    } = settingsContext

    const handleOpenDelete = () => {
        setOpenDelete(true);
    };
    const handleCloseDelete = () => {
        setOpenDelete(false);
        setValidJustification(false);
        setPasswordRequiredField(false);
        setPassRight(false);
        setchangeContex(0);
        setJustification("");
        setPassword("");
    };

    var pass = true;

    const handleDelete = () => {
        if (justification.trim().length > 0) setchangeContex(changeContext + 1)
        else setValidJustification(true)
        if (changeContext > 0) {
            if (password.length > 0) sendRequest();
            else setPasswordRequiredField(true);
            if (pass) setPassword("")
            else setOpenDelete(false);
        }
    };

    function sendRequest() {
        const requestInfo = {
            method: "POST",
            body: JSON.stringify({
                email: auth.userName,
                password: password
            }),
            headers: new Headers({
                "Content-type": "application/json"
            })
        };



        fetch(process.env.REACT_APP_SERVER + "api/auth/login", requestInfo)
            .then(response => {
                if (response.ok) {
                    setPassRight(false);
                    let formData = new FormData();
                    formData.append('justification', justification)
                    deleteAllowanceReason(item.id, formData, fetchData, handleCloseDelete);
                    return false;
                } else {
                    setPassRight(true);
                    return true;
                }
            })
            .catch(error => {
                setPassRight(true);
                return true;
            });
    }

    const handleChange = (name, value) => {
        setValidJustification(false);
        setJustification(value);
    }

    const handlePasswordConfirm = (name, value) => {
        setPasswordRequiredField(false);
        setPassRight(false);
        setPassword(value);
    }

    return (
        <React.Fragment>
            {GetGeneralPermissionForAction(Screen.ALLOWANCE_REASON, Allow.READ) &&
                <Tooltip title={t("GENERAL.view")} aria-label="details" placement="top">
                    <Link to={`/AllowanceReason/${item.id}/view`}>
                        <IconButton className={classes.iconsTableActions} >
                            <Visibility />
                        </IconButton>
                    </Link>
                </Tooltip>
            }
            {GetGeneralPermissionForAction(Screen.ALLOWANCE_REASON, Allow.EDIT) && <Tooltip title={t("GENERAL.edit")} aria-label="edit" placement="top">
                <Link to={`/AllowanceReason/${item.id}/edit`}>
                    <IconButton className={classes.iconsTableActions} >
                        <Edit />
                    </IconButton>
                </Link>
            </Tooltip>}
            {GetGeneralPermissionForAction(Screen.ALLOWANCE_REASON, Allow.DELETE) && <Tooltip title={t("GENERAL.delete")} aria-label="delete" placement="top">
                <IconButton className={classes.iconsTableActions} onClick={handleOpenDelete}>
                    <Delete />
                </IconButton>
            </Tooltip>}
            <Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="form-dialog-title"
                msg="mensagem"
                maxWidth={"md"}
            >
                <DialogTitle className={classes.Title}>
                    <b>{t("GENERAL.delete") + " " + t("HR.allowanceReason")}</b>
                    <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleCloseDelete} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContainer>
                        {changeContext === 0 ?
                            <DialogContentContainer>
                                <p>{t("HR.deleteAllowanceReasonMessage")} </p>
                                <div>
                                    <p className={styles.deleteDialog_pb}><b>{t("GENERAL.name").toUpperCase()}</b></p>
                                    <p className={styles.deleteDialog_p}>{item.name}</p>
                                </div>
                                {/* <Input
                                error={validJustification}
                                multiline
                                fullWidth
                                variant="outlined"
                                rows="3"
                                value={justification}
                                label={t("INVENTORY.justification") + "*"}
                                margin="normal"
                                maxLength= {100 }
                                helperText={validJustification ? t(blankField) : `${justification.length}/100`}
                                onChange={(e) => {
                                    setValidJustification(false);
                                    setJustification(e.target.value)
                                }}
                                /> */}
                                <Input
                                    required
                                    error={validJustification}
                                    helperText={validJustification ? t(blankField) : `${justification.length}/100`}
                                    label={t("INVENTORY.justification")}
                                    value={justification}
                                    valueName={"justification"}
                                    rows={3}
                                    maxLength={100}
                                    handleChange={handleChange}
                                />


                            </DialogContentContainer>
                            :
                            <DialogContentText>
                                <p>{t("HR.deleteAllowanceReasonPasswordMessage")}</p>

                                {/* <Input
                                fullWidth
                                error={validPasswordrequiredField || passRight}
                                label={t("INVENTORY.password") + "*"}
                                value={password}
                                onChange={handlePasswordConfirm}
                                type="password"
                                variant="filled"
                                helperText={validPasswordrequiredField ? t(blankField) : passRight ? "Senha inválida." : ""}
                                style={{ width: "90%", marginLeft: 30, marginRight: 30 }}
                                /> */}
                                <Input
                                    required
                                    error={validPasswordrequiredField || passRight}
                                    helperText={validPasswordrequiredField ? t(blankField) : passRight ? "Senha inválida." : ""}
                                    label={t("INVENTORY.password")}
                                    fieldType="password"
                                    value={password}
                                    handleChange={handlePasswordConfirm}
                                />
                            </DialogContentText>
                        }
                    </DialogContainer>
                </DialogContent>
                <DialogActions>
                    <Buttons
                        onClick={handleCloseDelete}
                        tipo="BN-noback"
                        conteudo={t("GENERAL.cancel").toUpperCase()}
                    />
                    <Buttons
                        onClick={handleDelete}
                        tipo="BN-blue"
                        conteudo={changeContext === 0 ? t("GENERAL.next").toUpperCase() : t("GENERAL.delete").toUpperCase()}
                    />
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
};

export default AllowanceReasonsTableActions;