import Search from "components/layout/searchIndex";
import SideBar from "components/layout/sidebar/Sidebar";
import ProjectsTable from "components/Projects/ProjectsTable";
import React from "react";

const RunningProjects = () => {
  return (
    <div>
      <Search busca={"Procurar por GPF, Nome ou Status"} />
      <SideBar />
      <ProjectsTable context="execution" />
    </div>
  );
};

export default RunningProjects;
