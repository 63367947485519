import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogUnit from 'components/dialogs/DialogUnit';
import DialogUnitWithIcon from 'components/dialogs/DialogUnitWithIcon';
import Avatar from '@material-ui/core/Avatar';
import InfoIcon from '@material-ui/icons/Info';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Grid, Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import Buttons from 'components/buttons/Pressed';
import Typography from 'components/typography/Typography';
import { useMutationVerifyPayment } from 'hooks/DataFetching/Quotas/Tools/useMutationVerifyPayment';
import Input from 'components/inputs/Input';
import { screenStyles } from '../Styles';
import { useEffect } from 'react';

import { dateToBRWithHours } from 'utils/dates/DateToBrWithHours';
import { FormatDateToFront } from 'utils/dates/FormatDateToFront';
import { GetMonthAndYearInWords } from 'utils/dates/GetMonthAnsYearInWords';
import { valueToMoneyFloat } from 'utils/general/valueToMoneyFloat';
import { moneyMask } from 'utils/masks/moneyMask';
import { BillStatus, translateBillStatus } from '../ScreenUtils';

const DialogToolsVerifyPayment = props => {
    const [t] = useTranslation();
    const styles = screenStyles();

    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const { open, setOpen, title, text, buttonText } = props;

    const [validBill, setValidBill] = useState(false);
    const [verifyPaymentError, setVerifyPaymentError] = useState(false);
    const [billData, setBillData] = useState(null);

    const mutationVerifyPayment = useMutationVerifyPayment(
        setValidBill,
        setVerifyPaymentError,
        setBillData
    );

    const [values, setValues] = useState({
        billId: '',
        code: ''
    });

    const [requiredBillId, setRequiredBillId] = useState(false);
    const [requiredVerificationCode, setRequiredVerificationCode] =
        useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (name, value) => {
        let val = value;
        if (verifyPaymentError) {
            setVerifyPaymentError(false);
        }
        setValues({ ...values, [name]: val });
    };

    const verifySubmit = () => {
        let res = true;

        if (!values.code.trim()) {
            res = false;
            setRequiredVerificationCode(true);
        }
        if (!values.billId.trim()) {
            res = false;
            setRequiredBillId(true);
        }

        return res;
    };

    const handleSubmit = () => {
        const canSubmit = verifySubmit();
        if (canSubmit) {
            let formData = new FormData();

            formData.append('billId', values.billId ? values.billId : '');
            formData.append('verificationCode', values.code ? values.code : '');

            mutationVerifyPayment.mutate(formData);
        }
    };

    const isOperatorDifferentFromUser =
        billData &&
        billData.user &&
        billData.operator &&
        billData.user.id !== billData.operator.id;

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle>
                <Grid container item spacing={2} xs={12}>
                    <Grid item xs={11}>
                        <Typography
                            variant={'RobotoBold'}
                            fontSize={24}
                            textColor={'secondary'}
                        >
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            color="#6D6F71"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent>
                <Grid
                    container
                    spacing={1}
                    xs={12}
                    style={{ margin: '0 auto' }}
                >
                    {validBill ? (
                        <>
                            <Grid item xs={12}>
                                <DialogUnitWithIcon
                                    head={t(
                                        'GENERAL.collaborator'
                                    ).toUpperCase()}
                                    Icon={
                                        <Avatar
                                            className={
                                                styles.dialogViewBillAvatar
                                            }
                                            alt={billData.user.shortname}
                                            src={
                                                process.env
                                                    .REACT_APP_IMGSERVER +
                                                billData.user.imageName
                                            }
                                        />
                                    }
                                    content={billData.user.name}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <DialogUnit
                                    head={t(
                                        'QUOTAS.referenceMonth'
                                    ).toUpperCase()}
                                    content={GetMonthAndYearInWords(
                                        FormatDateToFront(billData.date)
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DialogUnit
                                    head={t('GENERAL.id').toUpperCase()}
                                    content={billData.id}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <DialogUnit
                                    head={t('GENERAL.value').toUpperCase()}
                                    content={
                                        <Box
                                            color={
                                                billData.status ===
                                                BillStatus.PAID
                                                    ? '#27AE60'
                                                    : billData.status ===
                                                      BillStatus.LATE
                                                    ? '#F3222F'
                                                    : null
                                            }
                                            fontWeight="700"
                                        >
                                            {`R$
                                ${moneyMask(valueToMoneyFloat(billData.value))}
                                `}
                                        </Box>
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DialogUnitWithIcon
                                    head={t('GENERAL.status').toUpperCase()}
                                    Icon={
                                        billData.status === BillStatus.PAID ? (
                                            <CheckCircleIcon
                                                style={{
                                                    color: '#7FE46B'
                                                }}
                                                fontSize="md"
                                            />
                                        ) : billData.status ===
                                          BillStatus.PENDING ? (
                                            <ErrorIcon
                                                style={{
                                                    color: '#FFD200'
                                                }}
                                                fontSize="md"
                                            />
                                        ) : billData.status ===
                                          BillStatus.LATE ? (
                                            <ErrorIcon
                                                style={{
                                                    color: '#F3222F'
                                                }}
                                                fontSize="md"
                                            />
                                        ) : (
                                            <InfoIcon
                                                style={{
                                                    color: '#6D6F71'
                                                }}
                                                fontSize="md"
                                            />
                                        )
                                    }
                                    content={translateBillStatus(
                                        billData.status
                                    )}
                                />
                            </Grid>
                            {billData.status === BillStatus.PAID && (
                                <Grid item xs={12}>
                                    <DialogUnit
                                        head={t(
                                            'QUOTAS.verificationCode'
                                        ).toUpperCase()}
                                        content={billData.verificationCode}
                                    />
                                </Grid>
                            )}

                            {isOperatorDifferentFromUser ? (
                                <>
                                    <Grid item xs={6}>
                                        <DialogUnitWithIcon
                                            head={t(
                                                'GENERAL.operator'
                                            ).toUpperCase()}
                                            Icon={
                                                <Avatar
                                                    className={
                                                        styles.dialogViewBillAvatar
                                                    }
                                                    alt={
                                                        billData.operator
                                                            .shortname
                                                    }
                                                    src={
                                                        process.env
                                                            .REACT_APP_IMGSERVER +
                                                        billData.operator
                                                            .imageName
                                                    }
                                                />
                                            }
                                            content={billData.operator.name}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <DialogUnit
                                            head={t(
                                                'GENERAL.operationDate'
                                            ).toUpperCase()}
                                            content={dateToBRWithHours(
                                                FormatDateToFront(
                                                    billData.closedAt
                                                )
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <DialogUnit
                                            head={t(
                                                'GENERAL.reason'
                                            ).toUpperCase()}
                                            content={billData.justification}
                                        />
                                    </Grid>
                                </>
                            ) : null}
                        </>
                    ) : (
                        <>
                            <Grid item xs={1} style={{ margin: '0 auto' }}>
                                <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    minHeight="100%"
                                >
                                    <InfoIcon color="primary" />
                                </Box>
                            </Grid>
                            <Grid item xs={11} style={{ margin: '0 auto' }}>
                                <Typography
                                    variant={'RobotoRegular'}
                                    fontSize={14}
                                    textColor={'primary'}
                                >
                                    {text}
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Input
                                    required
                                    label={'QUOTAS.billId'}
                                    placeholder={t('QUOTAS.billIdPlaceholder')}
                                    value={values.billId}
                                    valueName={'billId'}
                                    type={'number'}
                                    handleChange={handleChange}
                                    maxLength={50}
                                    error={requiredBillId || verifyPaymentError}
                                    helperText={
                                        verifyPaymentError
                                            ? ''
                                            : requiredBillId &&
                                              t('GENERAL.requiredField')
                                    }
                                    setRequired={setRequiredBillId}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Input
                                    required
                                    label={'QUOTAS.verificationCode'}
                                    placeholder={t(
                                        'QUOTAS.verificationCodePlaceholder'
                                    )}
                                    value={values.code}
                                    valueName={'code'}
                                    handleChange={handleChange}
                                    maxLength={200}
                                    error={
                                        requiredVerificationCode ||
                                        verifyPaymentError
                                    }
                                    helperText={
                                        verifyPaymentError
                                            ? t(
                                                  'QUOTAS.verificationPaymentError'
                                              )
                                            : requiredVerificationCode &&
                                              t('GENERAL.requiredField')
                                    }
                                    setRequired={requiredVerificationCode}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </DialogContent>

            <DialogActions style={{ padding: '24px 30px' }}>
                <Buttons
                    onClick={handleClose}
                    tipo={'BN-noback'}
                    conteudo={t('GENERAL.close').toUpperCase()}
                />
                {!validBill ? (
                    <Buttons
                        isLoading={mutationVerifyPayment.isLoading}
                        onClick={handleSubmit}
                        tipo="BN-blue"
                        conteudo={buttonText}
                    />
                ) : null}
            </DialogActions>
        </Dialog>
    );
};

export default DialogToolsVerifyPayment;
