/* eslint-disable */
import axios from 'axios';
import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import history from '../../history';
import { DEFAULT, PARTNERS, PROJECTS, USERS } from '../types';
import ProjectsContext from './projectsContext';
import ProjectsReducer from './projectsReducer';
import Cookies from 'js-cookie';

const ProjectsState = props => {
    const initialState = {
        project: {},
        files: {},
        addendum: {},
        goal: {},
        newProjectId: {},
        listParticipants: {},
        phase: {},
        activity: {},
        subsidy: {},
        dialogData: {
            data: null,
            mode: 'view',
            open: false
        },

        users: [],
        projects: [],
        partners: [],
        addendums: [],
        generalInformation: [],
        participants: [],
        phases: [],
        expenses: [],
        costByProject: [],
        participantFeedback: [],
        participantSolicitation: [],
        participantPontos: [],

        message: '',
        response: '',
        responseType: '',
        responseDelete: '',
        responseDeleteType: '',

        hasChanged: 0,
        stepFlow: 0,
        hasUpdate: 0,
        currentStep: 1,
        newProjectId: 0,
        successiveActions: 0,
        idealNumberOfChildUpdates: 0,

        loading: false,
        projectCreated: false,
        triggerUpdateCreateAction: false,
        showRequiredFieldMessage: false,
        panelTab: 0,
        actionMode: 'view',
        newActivityId: 0,
        openModal: false,
        completeSubmission: false
    };

    const setHasChanged = () => dispatch({ type: DEFAULT.SET_HAS_CHANGED });

    const [t] = useTranslation();
    const [state, dispatch] = useReducer(ProjectsReducer, initialState);

    const setLoading = () => dispatch({ type: DEFAULT.SET_LOADING });
    const resetMessage = () => dispatch({ type: DEFAULT.RESET_MESSAGE });
    const resetDeleteMessage = () =>
        dispatch({ type: DEFAULT.RESET_DELETE_MESSAGE });
    const resetAddendums = () => dispatch({ type: PROJECTS.RESET_ADDENDUMS });

    const setSubmission = state =>
        dispatch({
            type: PROJECTS.COMPLETE_SUBMISSION,
            payload: state
        });

    const resetProject = () => dispatch({ type: PROJECTS.RESET_PROJECT });

    const triggerUpdateCreate = value =>
        dispatch({
            type: PROJECTS.TRIGGER_UPDATE_CREATE,
            payload: value
        });

    const setValueRequiredMessage = value =>
        dispatch({
            type: PROJECTS.SET_VALUE_REQUIRED_MESSAGE,
            payload: value
        });

    const setValuePanelTab = value =>
        dispatch({
            type: PROJECTS.SET_VALUE_PANEL_TAB,
            payload: value
        });

    const setActionMode = value =>
        dispatch({
            type: PROJECTS.SET_ACTION_MODE,
            payload: value
        });

    const setNewActivityId = value =>
        dispatch({
            type: PROJECTS.SET_NEW_ACTIVITY_ID,
            payload: value
        });

    const setOpenModal = value =>
        dispatch({
            type: PROJECTS.SET_OPEN_MODAL,
            payload: value
        });

    const setIdealNumberOfChildUpdates = count => {
        state.idealNumberOfChildUpdates = count;
        dispatch({
            type: PROJECTS.SET_IDEAL_NUMBER_OF_CHILDS_UPDATES,
            payload: count
        });
    };

    const setMessage = msg =>
        dispatch({
            response: msg.response,
            type: DEFAULT.SET_MESSAGE,
            responseType: msg.responseType,
            hasUpdate: state.hasUpdate + 1
        });

    const getProjects = async () => {
        setLoading();
        const res = await axios.get(
            process.env.REACT_APP_SERVER + 'api/projects',
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );

        dispatch({
            type: PROJECTS.GET_PROJECTS,
            payload: res.data
        });
    };

    const getAllProjects = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + 'api/projects',
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );

        dispatch({
            type: PROJECTS.GET_ALL_PROJECTS,
            payload: res.data
        });
    };

    const getParticipants = async id => {
        setLoading();
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/projectParticipants/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: PROJECTS.GET_PROJECT_PARTICIPANTS,
            payload: res.data
        });
    };

    const getPhases = async id => {
        setLoading();
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/projectPhases/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: PROJECTS.GET_PROJECT_PHASES,
            payload: res.data
        });
    };

    const getPhase = async id => {
        setLoading();
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/projectPhases/edit/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: PROJECTS.GET_PROJECT_PHASE,
            payload: res.data
        });
    };

    const setErrorMessageInProgressPhase = message => {
        dispatch({
            type: PROJECTS.DELETE_PHASE,
            payload: t(message),
            responseDeleteType: 'ERROR',
            successiveActions: state.successiveActions + 1,
            hasChanged: state.hasChanged + 2
        });
    };

    const deleteParticipant = id => {
        setLoading();
        axios
            .delete(
                process.env.REACT_APP_SERVER + `api/projectParticipants/${id}`,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(res => {
                if (res.statusText === 'OK') {
                    dispatch({
                        type: PROJECTS.DELETE_PARTICIPANTS,
                        payload: t('PARTICIPANTS.deleteSuccess'),
                        responseDeleteType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1
                    });
                } else {
                    dispatch({
                        type: PROJECTS.DELETE_PARTICIPANTS,
                        payload: t('PARTICIPANTS.deleteFailure'),
                        responseDeleteType: 'ERROR',
                        successiveActions: state.successiveActions + 1
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: PROJECTS.DELETE_PARTICIPANTS,
                    payload: t('PARTICIPANTS.deleteFailure'),
                    responseDeleteType: 'ERROR',
                    successiveActions: state.successiveActions + 1
                });
            });
    };

    const deletePhase = id => {
        setLoading();
        axios
            .get(process.env.REACT_APP_SERVER + `api/projectPhases/${id}`, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(res => {
                axios
                    .delete(
                        process.env.REACT_APP_SERVER +
                            `api/projectPhases/${id}`,
                        {
                            headers: {
                                'Content-type': 'application/json',
                                Authorization:
                                    'bearer ' +
                                    (Cookies.get('auth-token')
                                        ? Cookies.get('auth-token')
                                        : '')
                            }
                        }
                    )
                    .then(res => {
                        if (res.statusText === 'OK') {
                            dispatch({
                                type: PROJECTS.DELETE_PHASE,
                                payload: t('PHASES.deleteSuccess'),
                                responseDeleteType: 'SUCCESS',
                                successiveActions: state.successiveActions + 1
                            });
                        } else {
                            dispatch({
                                type: PROJECTS.DELETE_PHASE,
                                payload: t('PHASES.deleteFailure'),
                                responseDeleteType: 'FAILURE',
                                successiveActions: state.successiveActions + 1
                            });
                        }
                    })
                    .catch(error => {
                        dispatch({
                            type: PROJECTS.DELETE_PHASE,
                            payload: error,
                            responseDeleteType: t('PHASES.deleteFailure'),
                            successiveActions: state.successiveActions + 1
                        });
                    });
            })
            .catch(error => {
                dispatch({
                    type: PROJECTS.DELETE_PHASE,
                    payload: t('PHASES.notFound'),
                    responseDeleteType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.hasChanged + 2
                });
            });
    };

    const changeStepFlow = step => {
        state.stepFlow = step;
        dispatch({
            type: PROJECTS.CHANGE_STEP,
            payload: state.stepFlow
        });
    };

    const submitScope = obj => {
        axios
            .post(process.env.REACT_APP_SERVER + 'api/scope/', obj, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(res => {
                if (res.status === 201) {
                    dispatch({
                        type: PROJECTS.HANDLE_SUBMIT_SCOPE,
                        payload: t('PROJECTS.edited'),
                        hasUpdate: state.hasUpdate + 1,
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1
                    });
                } else {
                    dispatch({
                        type: PROJECTS.HANDLE_SUBMIT_SCOPE,
                        payload: 'An Error has Occurred',
                        hasUpdate: state.hasUpdate + 1,
                        responseType: 'FAILURE',
                        successiveActions: state.successiveActions + 1
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: PROJECTS.HANDLE_SUBMIT_SCOPE,
                    hasUpdate: state.hasUpdate + 1,
                    payload: error,
                    responseType: 'FAILURE',
                    successiveActions: state.successiveActions + 1
                });
            });
    };

    const submitSchedule = obj => {
        setLoading();
        axios
            .post(process.env.REACT_APP_SERVER + 'api/schedule/', obj, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(res => {
                if (res.statusText === 'OK') {
                    dispatch({
                        type: PROJECTS.HANDLE_SUBMIT_SCHEDULE,
                        responseType: 'SUCCESS',
                        hasUpdate: state.hasUpdate + 1,
                        payload: t('PROJECTS.successEditSchedule'),
                        successiveActions: state.successiveActions + 1
                    });
                } else {
                    dispatch({
                        type: PROJECTS.HANDLE_SUBMIT_SCHEDULE,
                        responseType: 'ERROR',
                        hasUpdate: state.hasUpdate + 1,
                        payload: t('PROJECTS.cantEditSchedule'),
                        successiveActions: state.successiveActions + 1
                    });
                }
            })
            .catch(() => {
                dispatch({
                    type: PROJECTS.HANDLE_SUBMIT_SCHEDULE,
                    responseType: 'ERROR',
                    hasUpdate: state.hasUpdate + 1,
                    payload: t('PROJECTS.cantEditSchedule'),
                    successiveActions: state.successiveActions + 1
                });
            });
    };

    const submitHeadings = obj => {
        setLoading();
        axios
            .post(process.env.REACT_APP_SERVER + 'api/headings/', obj, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(res => {
                if (res.statusText === 'OK') {
                    dispatch({
                        responseType: 'SUCCESS',
                        hasUpdate: state.hasUpdate + 1,
                        type: PROJECTS.HANDLE_SUBMIT_HEADINGS,
                        payload: t('PROJECTS.successEditHeadings'),
                        successiveActions: state.successiveActions + 1
                    });
                } else {
                    dispatch({
                        responseType: 'ERROR',
                        hasUpdate: state.hasUpdate + 1,
                        type: PROJECTS.HANDLE_SUBMIT_HEADINGS,
                        payload: t('PROJECTS.cantEditHeadings'),
                        successiveActions: state.successiveActions + 1
                    });
                }
            })
            .catch(() => {
                dispatch({
                    responseType: 'ERROR',
                    hasUpdate: state.hasUpdate + 1,
                    type: PROJECTS.HANDLE_SUBMIT_HEADINGS,
                    payload: t('PROJECTS.cantEditHeadings'),
                    successiveActions: state.successiveActions + 1
                });
            });
    };

    const submitBudget = obj => {
        setLoading();
        axios
            .post(process.env.REACT_APP_SERVER + 'api/projectBudget/', obj, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(res => {
                if (res.statusText === 'OK') {
                    dispatch({
                        responseType: 'SUCCESS',
                        hasUpdate: state.hasUpdate + 1,
                        type: PROJECTS.HANDLE_SUBMIT_BUDGET,
                        payload: t('BUDGET.submitSuccessMessage'),
                        successiveActions: state.successiveActions + 1
                    });
                } else {
                    dispatch({
                        responseType: 'ERROR',
                        hasUpdate: state.hasUpdate + 1,
                        type: PROJECTS.HANDLE_SUBMIT_BUDGET,
                        payload: t('BUDGET.submitErroMessage'),
                        successiveActions: state.successiveActions + 1
                    });
                }
            })
            .catch(() => {
                dispatch({
                    responseType: 'ERROR',
                    hasUpdate: state.hasUpdate + 1,
                    type: PROJECTS.HANDLE_SUBMIT_BUDGET,
                    payload: t('BUDGET.submitErroMessage'),
                    successiveActions: state.successiveActions + 1
                });
            });
    };

    const getProject = async id => {
        setLoading();
        axios
            .get(process.env.REACT_APP_SERVER + `api/projects/${id}`, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(res => {
                dispatch({
                    type: PROJECTS.GET_PROJECT,
                    payload: res.data
                });
            })
            .catch(() => {
                history.push('/not-found');
                dispatch({
                    type: PROJECTS.GET_PROJECT,
                    payload: []
                });
            });
    };

    const findItemInProject = async id => {
        var candelete = true;
        const res = await axios.get(
            process.env.REACT_APP_SERVER + 'api/InventoryItem',
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        let objectResponse = res.data;

        for (var objr in objectResponse) {
            if (objectResponse[objr]['projectId'] === id) {
                candelete = false;
            }
        }

        return candelete;
    };

    const deleteProject = id => {
        setLoading();
        axios
            .get(process.env.REACT_APP_SERVER + `api/projects/${id}`, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(res => {
                var id_information = res.data.information.id;
                var promise = findItemInProject(id_information);
                promise.then(result => {
                    if (result) {
                        axios
                            .delete(
                                process.env.REACT_APP_SERVER +
                                    `api/projects/${id}`,
                                {
                                    headers: {
                                        'Content-type': 'application/json',
                                        Authorization:
                                            'bearer ' +
                                            (Cookies.get('auth-token')
                                                ? Cookies.get('auth-token')
                                                : '')
                                    }
                                }
                            )
                            .then(() => {
                                dispatch({
                                    type: PROJECTS.DELETE_PROJECT,
                                    payload: t('PROJECTS.deleteSucces'),
                                    responseDeleteType: 'SUCCESS',
                                    successiveActions:
                                        state.successiveActions + 1,
                                    hasChanged: state.successiveActions + 2
                                });
                            })
                            .catch(error => {
                                dispatch({
                                    type: PROJECTS.DELETE_PROJECT,
                                    payload:
                                        error.response.status === 404
                                            ? t('PROJECTS.notFound')
                                            : t('PROJECTS.cantBeDeleted'),
                                    successiveActions:
                                        state.successiveActions + 1,
                                    hasChanged: state.successiveActions + 2,
                                    responseDeleteType: 'ERROR'
                                });
                            });
                    } else {
                        dispatch({
                            type: PROJECTS.DELETE_PROJECT,
                            payload: 'Projeto associado a itens no patrimônio',
                            successiveActions: state.successiveActions + 1,
                            hasChanged: state.successiveActions + 2,
                            responseDeleteType: 'ERROR'
                        });
                    }
                });
            })
            .catch(error => {
                dispatch({
                    type: PROJECTS.DELETE_PROJECT,
                    payload:
                        error.response.status === 404
                            ? t('PROJECTS.notFound')
                            : t('PROJECTS.cantBeDeleted'),
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2,
                    responseDeleteType: 'ERROR'
                });
            });
    };

    // called only when needed
    const getPartners = async currentPartners => {
        // if (Object.keys(state.partners).length === 0) {
        setLoading();
        let aux = {};
        let cleanPartners = [];
        const res = await axios.get(
            process.env.REACT_APP_SERVER + 'api/Partners',
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        for (var item in res.data) {
            aux.value = res.data[item].id;
            aux.label = res.data[item].socialreason;
            aux.type = res.data[item].type;
            cleanPartners.push(aux);
            aux = {};
        }
        aux.value = null;
        aux.label = t('GENERAL.none');
        aux.type = t('GENERAL.none');
        cleanPartners.push(aux);
        aux = {};
        dispatch({
            type: PARTNERS.GET_PARTNERS,
            payload: cleanPartners
        });
    };

    // called only when needed
    const getUsers = async currentUsers => {
        if (Object.keys(state.users).length === 0) {
            setLoading();
            let aux = {};
            let cleanUsers = [];
            const res = await axios.get(
                process.env.REACT_APP_SERVER + 'api/users/teachers',
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            );
            for (var item in res.data) {
                aux.value = res.data[item].id;
                aux.label = res.data[item].name;
                cleanUsers.push(aux);
                aux = {};
            }
            aux.value = null;
            aux.label = t('GENERAL.none');
            aux.type = t('GENERAL.none');
            cleanUsers.push(aux);
            aux = {};
            dispatch({
                type: USERS.GET_USERS,
                payload: cleanUsers
            });
        } else {
            dispatch({
                type: USERS.GET_USERS,
                payload: currentUsers
            });
        }
    };

    const setGeneralInformation = obj => {
        state.generalInformation = obj;
        dispatch({
            type: PROJECTS.GENERAL_INFORMATION,
            payload: state.generalInformation
        });
    };

    const setProjectCreated = created => {
        dispatch({
            type: PROJECTS.SET_PROJECT_CREATED,
            projectCreated: created
        });
    };

    const createProject = obj => {
        setLoading();
        let data = JSON.stringify({
            gpf: obj.gpf,
            name: obj.name,
            codeName: obj.codeName,
            estimatedCost: obj.estimatedCost,
            startDate: obj.startDate,
            endDate: obj.endDate,
            baseYear: obj.baseYear,
            clientId: obj.clientId,
            supportFoundationId: obj.supportFoundationId,
            coordinatorId: obj.coordinatorId
        });

        axios
            .post(process.env.REACT_APP_SERVER + 'api/projects/', data, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: PROJECTS.CREATE_PROJECT,
                        payload: t('PROJECTS.created'),
                        responseType: 'SUCCESS',
                        newProjectId: response.data.id,
                        newProject: response.config.data,
                        stepFlow: 0,
                        projectCreated: true,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: PROJECTS.CREATE_PROJECT,
                        payload: 'Error',
                        responseType: 'ERROR',
                        newProjectId: 0,
                        stepFlow: 0,
                        projectCreated: false
                    });
                }
            })
            .catch(() => {
                dispatch({
                    type: PROJECTS.CREATE_PROJECT,
                    payload: 'Error',
                    responseType: 'ERROR',
                    newProjectId: 0,
                    stepFlow: 0,
                    projectCreated: false
                });
            });
    };

    const updateGeneralInformation = obj => {
        setLoading();
        let data = JSON.stringify({
            id: obj.id,
            gpf: obj.gpf,
            name: obj.name,
            codeName: obj.codeName,
            estimatedCost: obj.estimatedCost,
            startDate: obj.startDate,
            endDate: obj.endDate,
            baseYear: obj.baseYear,
            clientId: obj.clientId,
            supportFoundationId: obj.supportFoundationId,
            coordinatorId: obj.coordinatorId
        });
        axios
            .put(
                process.env.REACT_APP_SERVER + `api/projects/${obj.id}`,
                data,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(res => {
                if (res.status === 200) {
                    dispatch({
                        type: PROJECTS.UPDATE_GENERALINFORMATION,
                        payload: t('PROJECTS.edited'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1
                    });
                } else {
                    dispatch({
                        type: PROJECTS.UPDATE_GENERALINFORMATION,
                        payload: 'An Error has Occurred',
                        responseType: 'FAILURE',
                        successiveActions: state.successiveActions + 1
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: PROJECTS.UPDATE_GENERALINFORMATION,
                    payload: error,
                    responseType: 'FAILURE',
                    successiveActions: state.successiveActions + 1
                });
            });
    };

    const getAddendum = async id => {
        setLoading();
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/Addendums/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: PROJECTS.GET_ADDENDUM,
            payload: res.data
        });
    };

    const updateGoal = obj => {
        setLoading();
        let data = JSON.stringify({
            id: obj.id,
            goalDescription: obj.goalsDescription
        });
        axios
            .put(process.env.REACT_APP_SERVER + `api/goals/${obj.id}`, data, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(res => {
                if (res.status === 200) {
                    dispatch({
                        type: PROJECTS.UPDATE_GOAL,
                        payload: t('PROJECTS.edited'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1
                    });
                } else {
                    dispatch({
                        type: PROJECTS.UPDATE_GOAL,
                        payload: 'An Error has Occurred',
                        responseType: 'FAILURE',
                        successiveActions: state.successiveActions + 1
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: PROJECTS.UPDATE_GOAL,
                    payload: error,
                    responseType: 'FAILURE',
                    successiveActions: state.successiveActions + 1
                });
            });
    };

    const getGoal = async id => {
        setLoading();
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/Goals/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: PROJECTS.GET_GOAL,
            payload: res.data
        });
    };

    const deleteAddendum = async obj => {
        setLoading();
        try {
            await axios.delete(
                process.env.REACT_APP_SERVER + 'api/Addendums/',
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    },
                    data: obj
                }
            );
            dispatch({
                type: PROJECTS.DELETE_ADDENDUM,
                payload: 'Termo aditivo deletado',
                responseType: 'SUCCESS',
                successiveActions: state.successiveActions + 1
            });
        } catch (e) {
            dispatch({
                type: PROJECTS.DELETE_ADDENDUM,
                payload: 'Erro ao deletar addendum',
                responseType: 'ERROR',
                successiveActions: state.successiveActions + 1
            });
        }
    };

    const updateCreateAddendums = async obj => {
        state.addendums.push(obj);
        if (state.idealNumberOfChildUpdates === state.addendums.length + 1) {
            setLoading();
            axios
                .all(
                    state.addendums.map(addendum => {
                        axios.post(
                            process.env.REACT_APP_SERVER + 'api/Addendums/',
                            addendum,
                            {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                    Authorization:
                                        'bearer ' +
                                        (Cookies.get('auth-token')
                                            ? Cookies.get('auth-token')
                                            : '')
                                }
                            }
                        );
                    })
                )
                .then(
                    axios.spread((...res) => {
                        dispatch({
                            responseType: 'SUCCESS',
                            type: PROJECTS.UPDATE_CREATE_ADDENDUM,
                            payload: t('PROJECTS.edited'),
                            successiveActions: state.successiveActions + 1,
                            hasChanged: state.successiveActions + 2
                        });
                    })
                )
                .catch(() => {
                    dispatch({
                        responseType: 'FAILURE',
                        payload: 'An Error has Occurred',
                        type: PROJECTS.UPDATE_CREATE_ADDENDUM,
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                })
                .finally(() => {
                    // do something
                });
        }
    };

    const addPhase = obj => {
        setLoading();
        axios
            .post(process.env.REACT_APP_SERVER + 'api/projectPhases', obj, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(res => {
                if (res.statusText === 'Created') {
                    dispatch({
                        type: PROJECTS.ADD_PHASE,
                        payload: t('PHASES.addPhaseSuccessMessage'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: PROJECTS.ADD_PHASE,
                        payload: t('PHASES.addPhaseFailureMessage'),
                        responseType: 'FAILURE',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: PROJECTS.ADD_PHASE,
                    payload: error,
                    responseType: t('PHASES.addPhaseFailureMessage'),
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            });
    };

    const addActivity = obj => {
        // setLoading();
        axios
            .post(
                process.env.REACT_APP_SERVER + 'api/projectPhases/addActivity',
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(res => {
                if (res.status === 201) {
                    setNewActivityId(res.data);
                    setOpenModal(true);
                    dispatch({
                        type: PROJECTS.ADD_ACTIVITY,
                        payload: t('ACTIVITIES.addActivitySuccessMessage'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: PROJECTS.ADD_ACTIVITY,
                        payload: t('Failure Message'),
                        responseType: 'FAILURE',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: PROJECTS.ADD_ACTIVITY,
                    payload: error,
                    responseType: t('Failure Message'),
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            });
    };

    const deleteActivity = (phaseId, id) => {
        setLoading();
        axios
            .delete(
                process.env.REACT_APP_SERVER +
                    `api/projectPhases/deleteActivity/${id}`,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(res => {
                if (res.statusText === 'OK') {
                    getPhase(phaseId).then(
                        dispatch({
                            type: PROJECTS.DELETE_ACTIVITY,
                            payload: t('ACTIVITIES.deleteSuccess'),
                            responseType: 'SUCCESS',
                            successiveActions: state.successiveActions + 1
                        })
                    );
                } else {
                    getPhase(phaseId).then(
                        dispatch({
                            type: PROJECTS.DELETE_ACTIVITY,
                            payload: t('ACTIVITIES.deleteFailure'),
                            responseType: 'ERROR',
                            successiveActions: state.successiveActions + 1
                        })
                    );
                }
            })
            .catch(error => {
                getPhase(phaseId).then(
                    dispatch({
                        type: PROJECTS.DELETE_ACTIVITY,
                        payload: t('ACTIVITIES.deleteFailure'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1
                    })
                );
            });
    };

    const getActivity = async id => {
        setLoading();
        const res = await axios.get(
            process.env.REACT_APP_SERVER +
                `api/projectPhases/getActivity/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: PROJECTS.GET_ACTIVITY,
            payload: res.data
        });
    };

    const updateActivity = async (id, data) => {
        setLoading();
        axios
            .put(
                process.env.REACT_APP_SERVER +
                    `api/projectPhases/updateActivity/${id}`,
                data,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(res => {
                if (res.status === 204) {
                    dispatch({
                        type: PROJECTS.UPDATE_ACTIVITY,
                        payload: t('ACTIVITIES.editActivitySuccessMessage'),
                        responseType: 'SUCCESS'
                    });
                } else {
                    dispatch({
                        type: PROJECTS.UPDATE_ACTIVITY,
                        payload: t('ACTIVITIES.editActivityFailureMessage'),
                        responseType: 'FAILURE'
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: PROJECTS.UPDATE_ACTIVITY,
                    payload: t('ACTIVITIES.editActivityFailureMessage'),
                    responseType: 'FAILURE'
                });
            });
    };

    const UpdatePhase = obj => {
        setLoading();
        let data = JSON.stringify({
            id: obj.id,
            name: obj.name,
            description: obj.description,
            startdate: obj.startDate,
            enddate: obj.endDate,
            addendumId: obj.addendumId,
            projectId: obj.projectId
        });
        axios
            .put(
                process.env.REACT_APP_SERVER + `api/projectPhases/${obj.id}`,
                data,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(res => {
                if (res.status === 204) {
                    dispatch({
                        type: PROJECTS.UPDATE_PHASE,
                        payload: t('PHASES.editPhaseSuccessMessage'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: PROJECTS.UPDATE_PHASE,
                        payload: t('PHASES.editPhaseFailureMessage'),
                        responseType: 'FAILURE',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: PROJECTS.ADD_PHASE,
                    payload: error,
                    responseType: t('PHASES.editPhaseFailureMessage'),
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            });
    };

    const FinishPhase = obj => {
        setLoading();
        let data = JSON.stringify({
            id: obj.id,
            name: obj.name,
            description: obj.description,
            startdate: obj.startDate,
            enddate: obj.endDate,
            addendumId: obj.addendumId,
            projectId: obj.projectId,
            status: obj.status
        });
        axios
            .put(
                process.env.REACT_APP_SERVER + `api/projectPhases/${obj.id}`,
                data,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(res => {
                if (res.status === 204) {
                    dispatch({
                        type: PROJECTS.UPDATE_PHASE,
                        payload: t('PHASES.finishPhaseSuccessMessage'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: PROJECTS.UPDATE_PHASE,
                        payload: t('PHASES.finishPhaseFailureMessage'),
                        responseType: 'FAILURE',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: PROJECTS.ADD_PHASE,
                    payload: error,
                    responseType: t('PHASES.finishPhaseFailureMessage'),
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            });
    };

    const FinishProject = obj => {
        axios
            .get(process.env.REACT_APP_SERVER + `api/projects/${obj.id}`, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(res => {
                if (res.data.status === 'TERMINATED') {
                    dispatch({
                        type: PROJECTS.UPDATE_PROJECT_FINISH,
                        payload: t('PROJECTS.finishProjectFailureMessage'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    setLoading();
                    let data = JSON.stringify({
                        id: obj.id,
                        informationId: obj.informationId,
                        textId: obj.textId,
                        status: obj.status
                    });
                    axios
                        .put(
                            process.env.REACT_APP_SERVER +
                                `api/Projects/finish/${obj.id}`,
                            data,
                            {
                                headers: {
                                    'Content-type': 'application/json',
                                    Authorization:
                                        'bearer ' +
                                        (Cookies.get('auth-token')
                                            ? Cookies.get('auth-token')
                                            : '')
                                }
                            }
                        )
                        .then(res => {
                            if (res.status === 200) {
                                dispatch({
                                    type: PROJECTS.UPDATE_PROJECT_FINISH,
                                    payload: t(
                                        'PROJECTS.finishProjectSuccessMessage'
                                    ),
                                    responseType: 'SUCCESS',
                                    successiveActions:
                                        state.successiveActions + 1,
                                    hasChanged: state.successiveActions + 2
                                });
                            } else {
                                dispatch({
                                    type: PROJECTS.UPDATE_PROJECT_FINISH,
                                    payload: t(
                                        'PROJECTS.finishProjectFailureMessage'
                                    ),
                                    responseType: 'FAILURE',
                                    successiveActions:
                                        state.successiveActions + 1,
                                    hasChanged: state.successiveActions + 2
                                });
                            }
                        })
                        .catch(error => {
                            dispatch({
                                type: PROJECTS.ADD_PHASE,
                                payload: error,
                                responseType: t(
                                    'PROJECTS.finishProjectFailureMessage'
                                ),
                                successiveActions: state.successiveActions + 1,
                                hasChanged: state.successiveActions + 2
                            });
                        });
                }
            })
            .catch(() => {
                history.push('/not-found');
                dispatch({
                    type: PROJECTS.GET_PROJECT,
                    payload: []
                });
            });
    };

    const submitParticipant = (obj, projectId) => {
        setLoading();
        let qtyObj = obj.length;
        for (var i in obj) {
            let data = JSON.stringify({
                frozen: true,
                hourvalue: 0,
                justification: '-',
                participantfunction:
                    obj[i].function === null ? '-' : obj[i].function,
                participantId: obj[i].id,
                projectId: parseInt(projectId)
            });
            axios
                .post(
                    process.env.REACT_APP_SERVER + 'api/projectParticipants',
                    data,
                    {
                        headers: {
                            'Content-type': 'application/json',
                            Authorization:
                                'bearer ' +
                                (Cookies.get('auth-token')
                                    ? Cookies.get('auth-token')
                                    : '')
                        }
                    }
                )
                .then(res => {
                    if (res.statusText === 'Created') {
                        dispatch({
                            type: PROJECTS.ADD_PARTICIPANTS,
                            payload: 'Participante(s) adicionado(s)!',
                            responseType: 'SUCCESS',
                            successiveActions: state.successiveActions + 1,
                            hasChanged: state.successiveActions + 2
                        });
                    } else {
                        dispatch({
                            type: PROJECTS.ADD_PARTICIPANTS,
                            payload: t('Falha ao adicionar participante(s)'),
                            responseType: 'FAILURE',
                            successiveActions: state.successiveActions + 1,
                            hasChanged: state.successiveActions + 2
                        });
                    }
                })
                .catch(error => {
                    dispatch({
                        type: PROJECTS.ADD_PARTICIPANTS,
                        payload: error,
                        responseType: t('Falha ao adicionar participante(s)'),
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                })
                .finally(() => {
                    if (qtyObj === 1) {
                        // getParticipants(projectId);
                        setSubmission(true);
                    } else {
                        qtyObj -= 1;
                    }
                });
        }
    };

    const addParticipantActivity = (obj, activityId) => {
        setLoading();
        let participants = [];

        for (var i in obj) {
            let data = {
                participantId: obj[i].id,
                hours: 0,
                startDate: '',
                endDate: '',
                activityId: activityId
            };

            participants.push(data);
        }

        axios
            .post(
                process.env.REACT_APP_SERVER +
                    'api/activityParticipants/addParticipants',
                participants,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(res => {
                if (res.statusText === 'OK') {
                    dispatch({
                        type: PROJECTS.ADD_PARTICIPANTS_ACTIVITY,
                        payload: t('ACTIVITIES.allocateParticipantsSuccess'),
                        responseType: t('GENERAL.successType'),
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: PROJECTS.ADD_PARTICIPANTS_ACTIVITY,
                        payload: t('Falha ao adicionar participante(s)'),
                        responseType: t('GENERAL.errorType'),
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: PROJECTS.ADD_PARTICIPANTS_ACTIVITY,
                    payload: error,
                    responseType: t('GENERAL.errorType'),
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            });
    };

    const deleteParticipantActivity = (id, activityId) => {
        setLoading();
        axios
            .delete(
                process.env.REACT_APP_SERVER +
                    `api/activityParticipants/deleteParticipant/${id}`,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(res => {
                if (res.statusText === 'OK') {
                    getActivity(activityId).then(() => {
                        dispatch({
                            type: PROJECTS.DELETE_PARTICIPANTS_ACTIVITY,
                            payload: t('ACTIVITIES.participantDeleteSuccess'),
                            responseDeleteType: 'SUCCESS'
                        });
                    });
                } else {
                    getActivity(activityId).then(() => {
                        dispatch({
                            type: PROJECTS.DELETE_PARTICIPANTS_ACTIVITY,
                            payload: t('ACTIVITIES.participantDeleteFailure'),
                            responseDeleteType: 'ERROR'
                        });
                    });
                }
            })
            .catch(error => {
                getActivity(activityId).then(() => {
                    dispatch({
                        type: PROJECTS.DELETE_PARTICIPANTS_ACTIVITY,
                        payload: t('ACTIVITIES.participantDeleteFailure'),
                        responseDeleteType: 'ERROR'
                    });
                });
            });
    };

    const updateParticipantActivity = (participant, activityId) => {
        setLoading();
        axios
            .put(
                process.env.REACT_APP_SERVER +
                    'api/activityParticipants/updateParticipant',
                participant,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(res => {
                if (res.status === 204) {
                    getActivity(activityId).then(() => {
                        dispatch({
                            type: PROJECTS.UPDATE_PARTICIPANT_ACTIVITY,
                            payload: t('ACTIVITIES.allocateParticipantSuccess'),
                            responseType: 'SUCCESS'
                        });
                    });
                } else {
                    getActivity(activityId).then(() => {
                        dispatch({
                            type: PROJECTS.UPDATE_PARTICIPANT_ACTIVITY,
                            payload: t('ACTIVITIES.allocateParticipantError'),
                            responseType: 'ERROR'
                        });
                    });
                }
            })
            .catch(error => {
                getActivity(activityId).then(() => {
                    dispatch({
                        type: PROJECTS.UPDATE_PARTICIPANT_ACTIVITY,
                        payload: t('ACTIVITIES.allocateParticipantError'),
                        responseType: 'ERROR'
                    });
                });
            });
    };

    const getExpenses = async id => {
        setLoading();
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/expenses/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: PROJECTS.GET_EXPENSES,
            payload: res.data
        });
    };

    const setDialogData = (data, mode, open) => {
        dispatch({
            type: PROJECTS.SET_DIALOG,
            payload: {
                data: data,
                mode: mode,
                open: open
            }
        });
    };

    const createExpense = (data, files, hasFiles) => {
        setLoading();
        axios
            .post(
                process.env.REACT_APP_SERVER + 'api/expenses/createExpense',
                data,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(res => {
                if (res.status === 201) {
                    if (hasFiles) {
                        axios
                            .post(
                                process.env.REACT_APP_SERVER +
                                    `api/expenses/uploadFiles/${res.data}`,
                                files,
                                {
                                    headers: {
                                        'Content-type': 'application/json',
                                        Authorization:
                                            'bearer ' +
                                            (Cookies.get('auth-token')
                                                ? Cookies.get('auth-token')
                                                : '')
                                    }
                                }
                            )
                            .then(res => {
                                if (res.statusText === 'OK') {
                                    dispatch({
                                        type: PROJECTS.CREATE_EXPENSE,
                                        payload: t(
                                            'EXPENSES.addExpenseSuccessMessage'
                                        ),
                                        responseType: t('GENERAL.successType'),
                                        successiveActions:
                                            state.successiveActions + 1,
                                        hasChanged: state.successiveActions + 2
                                    });
                                } else {
                                    dispatch({
                                        type: PROJECTS.CREATE_EXPENSE,
                                        payload: t(
                                            'EXPENSES.uploadErrorMessage'
                                        ),
                                        responseType: t('GENERAL.errorType'),
                                        successiveActions:
                                            state.successiveActions + 1,
                                        hasChanged: state.successiveActions + 2
                                    });
                                }
                            })
                            .catch(error => {
                                dispatch({
                                    type: PROJECTS.CREATE_EXPENSE,
                                    payload: t('EXPENSES.uploadErrorMessage'),
                                    responseType: t('GENERAL.errorType'),
                                    successiveActions:
                                        state.successiveActions + 1,
                                    hasChanged: state.successiveActions + 2
                                });
                            });
                    } else {
                        dispatch({
                            type: PROJECTS.CREATE_EXPENSE,
                            payload: t('EXPENSES.addExpenseSuccessMessage'),
                            responseType: t('GENERAL.successType'),
                            successiveActions: state.successiveActions + 1,
                            hasChanged: state.successiveActions + 2
                        });
                    }
                } else {
                    dispatch({
                        type: PROJECTS.CREATE_EXPENSE,
                        payload: t('EXPENSES.addExpenseErrorMessage'),
                        responseType: t('GENERAL.errorType'),
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: PROJECTS.CREATE_EXPENSE,
                    payload: t('EXPENSES.addExpenseErrorMessage'),
                    responseType: t('GENERAL.errorType'),
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            });
    };

    const updateExpense = (data, files, toDelete, hasFiles) => {
        let obj = {
            projectExpenses: data,
            filesToDelete: toDelete
        };

        setLoading();
        axios
            .put(
                process.env.REACT_APP_SERVER + `api/expenses/updateExpense`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(res => {
                if (res.statusText === 'OK') {
                    if (hasFiles) {
                        axios
                            .post(
                                process.env.REACT_APP_SERVER +
                                    `api/expenses/uploadFiles/${data.id}`,
                                files,
                                {
                                    headers: {
                                        'Content-type': 'application/json',
                                        Authorization:
                                            'bearer ' +
                                            (Cookies.get('auth-token')
                                                ? Cookies.get('auth-token')
                                                : '')
                                    }
                                }
                            )
                            .then(res => {
                                if (res.statusText === 'OK') {
                                    dispatch({
                                        type: PROJECTS.CREATE_EXPENSE,
                                        payload: t(
                                            'EXPENSES.updateExpenseSuccessMessage'
                                        ),
                                        responseType: t('GENERAL.successType'),
                                        successiveActions:
                                            state.successiveActions + 1,
                                        hasChanged: state.successiveActions + 2
                                    });
                                } else {
                                    dispatch({
                                        type: PROJECTS.CREATE_EXPENSE,
                                        payload: t(
                                            'EXPENSES.uploadErrorMessage'
                                        ),
                                        responseType: t('GENERAL.errorType'),
                                        successiveActions:
                                            state.successiveActions + 1,
                                        hasChanged: state.successiveActions + 2
                                    });
                                }
                            })
                            .catch(error => {
                                dispatch({
                                    type: PROJECTS.CREATE_EXPENSE,
                                    payload: t('EXPENSES.uploadErrorMessage'),
                                    responseType: t('GENERAL.errorType'),
                                    successiveActions:
                                        state.successiveActions + 1,
                                    hasChanged: state.successiveActions + 2
                                });
                            });
                    } else {
                        dispatch({
                            type: PROJECTS.CREATE_EXPENSE,
                            payload: t('EXPENSES.updateExpenseSuccessMessage'),
                            responseType: t('GENERAL.successType'),
                            successiveActions: state.successiveActions + 1,
                            hasChanged: state.successiveActions + 2
                        });
                    }
                } else {
                    dispatch({
                        type: PROJECTS.CREATE_EXPENSE,
                        payload: t('EXPENSES.updateExpenseFailureMessage'),
                        responseType: t('GENERAL.errorType'),
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: PROJECTS.CREATE_EXPENSE,
                    payload: t('EXPENSES.updateExpenseFailureMessage'),
                    responseType: t('GENERAL.errorType'),
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            });
    };

    const deleteExpense = id => {
        setLoading();
        axios
            .delete(process.env.REACT_APP_SERVER + `api/expenses/${id}`, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(res => {
                if (res.statusText === 'OK') {
                    dispatch({
                        type: PROJECTS.DELETE_EXPENSE,
                        payload: t('EXPENSES.deleteExpenseSuccsses'),
                        responseDeleteType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1
                    });
                } else if (res.status === 404) {
                    dispatch({
                        type: PROJECTS.DELETE_EXPENSE,
                        payload: t('EXPENSES.deleteExpenseNotFound'),
                        responseDeleteType: 'ERROR',
                        successiveActions: state.successiveActions + 1
                    });
                } else {
                    dispatch({
                        type: PROJECTS.DELETE_EXPENSE,
                        payload: t('EXPENSES.deleteExpenseFailure'),
                        responseDeleteType: 'ERROR',
                        successiveActions: state.successiveActions + 1
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: PROJECTS.DELETE_EXPENSE,
                    payload: t('EXPENSES.deleteExpenseNotFound'),
                    responseDeleteType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.hasChanged + 2
                });
            });
    };

    const submitProjectBudget = obj => {
        axios
            .post(process.env.REACT_APP_SERVER + 'api/projectBudget/', obj, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(res => {
                if (res.statusText === 'OK') {
                    dispatch({
                        type: PROJECTS.HANDLE_SUBMIT_PROJECT_BUDGET,
                        payload: t('BUDGET.submitSuccessMessage'),
                        hasUpdate: state.hasUpdate + 1,
                        responseType: t('GENERAL.successType'),
                        successiveActions: state.successiveActions + 1
                    });
                } else {
                    dispatch({
                        type: PROJECTS.HANDLE_SUBMIT_PROJECT_BUDGET,
                        payload: t('BUDGET.submitErroMessage'),
                        hasUpdate: state.hasUpdate + 1,
                        responseType: t('GENERAL.errorType'),
                        successiveActions: state.successiveActions + 1
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: PROJECTS.HANDLE_SUBMIT_PROJECT_BUDGET,
                    hasUpdate: state.hasUpdate + 1,
                    payload: t('BUDGET.submitErroMessage'),
                    responseType: t('GENERAL.errorType'),
                    successiveActions: state.successiveActions + 1
                });
            });
    };

    const getSubsidy = async id => {
        setLoading();
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/projectSubsidy/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: PROJECTS.GET_SUBSIDY,
            payload: res.data
        });
    };

    const createSubsidy = obj => {
        axios
            .post(process.env.REACT_APP_SERVER + 'api/projectSubsidy/', obj, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(res => {
                if (res.statusText === 'OK') {
                    dispatch({
                        type: PROJECTS.HANDLE_SUBMIT_PROJECT_SUBSIDY,
                        payload: t('SUBSIDY.submitSuccessMessage'),
                        responseType: t('GENERAL.successType'),
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: PROJECTS.HANDLE_SUBMIT_PROJECT_SUBSIDY,
                        payload: t('SUBSIDY.submitErroMessage'),
                        hasUpdate: state.hasUpdate + 1,
                        responseType: t('GENERAL.errorType'),
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: PROJECTS.HANDLE_SUBMIT_PROJECT_SUBSIDY,
                    hasUpdate: state.hasUpdate + 1,
                    payload: t('SUBSIDY.submitErroMessage'),
                    responseType: t('GENERAL.errorType'),
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            });
    };

    const deleteSubsidy = id => {
        setLoading();
        axios
            .delete(process.env.REACT_APP_SERVER + `api/projectSubsidy/${id}`, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(res => {
                if (res.statusText === 'OK') {
                    dispatch({
                        type: PROJECTS.DELETE_SUBSIDY,
                        payload: t('SUBSIDY.deleteSubsidySuccess'),
                        responseDeleteType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1
                        //hasChanged: state.successiveActions + 2
                    });
                } else if (res.status === 404) {
                    dispatch({
                        type: PROJECTS.DELETE_SUBSIDY,
                        payload: t('SUBSIDY.deleteSubsidyNotFound'),
                        responseDeleteType: 'ERROR',
                        successiveActions: state.successiveActions + 1
                        //hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: PROJECTS.DELETE_SUBSIDY,
                        payload: t('SUBSIDY.deleteSubsidyFailure'),
                        responseDeleteType: 'ERROR',
                        successiveActions: state.successiveActions + 1
                        //hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: PROJECTS.DELETE_SUBSIDY,
                    payload: t('SUBSIDY.deleteSubsidyNotFound'),
                    responseDeleteType: 'ERROR',
                    successiveActions: state.successiveActions + 1
                    // hasChanged: state.hasChanged + 2
                });
            });
    };

    const editSubsidy = obj => {
        setLoading();
        axios
            .put(
                process.env.REACT_APP_SERVER +
                    'api/projectSubsidy/updateSubsidy/',
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(res => {
                if (res.status === 204) {
                    dispatch({
                        type: PROJECTS.UPDATE_SUBSIDY,
                        payload: t('SUBSIDY.successfullyEdited'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: PROJECTS.UPDATE_SUBSIDY,
                        payload: t('SUBSIDY.editSubsidyFailureMessage'),
                        responseType: 'FAILURE',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: PROJECTS.UPDATE_SUBSIDY,
                    payload: t('SUBSIDY.editSubsidyFailureMessage'),
                    responseType: 'FAILURE',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            });
    };

    const getCostbyGpf = async gpf => {
        setLoading();
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/usercosts/gpf/${gpf}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: PROJECTS.GET_COST_GPF,
            payload: res.data
        });
    };

    const getParticipantFeedback = async participationId => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER +
                `api/feedback/all/${participationId}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: PROJECTS.GET_PARTICIPANT_FEEDBACKS,
            payload: res.data
        });
    };

    const deleteParticipantFeedback = (feedbackId, participationId) => {
        setLoading();
        axios
            .delete(
                process.env.REACT_APP_SERVER + `api/feedback/${feedbackId}`,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(res => {
                if (res.status === 204) {
                    getParticipantFeedback(participationId).then(() => {
                        dispatch({
                            type: PROJECTS.DELETE_PARTICIPANT_FEEDBACK,
                            payload: t('PARTICIPANTS.deleteFeedbackSuccess'),
                            responseType: 'SUCCESS',
                            successiveActions: state.successiveActions + 1
                        });
                    });
                } else {
                    getParticipantFeedback(participationId).then(() => {
                        dispatch({
                            type: PROJECTS.DELETE_PARTICIPANT_FEEDBACK,
                            payload: t('PARTICIPANTS.deleteFeedbackFailure'),
                            responseType: 'ERROR',
                            successiveActions: state.successiveActions + 1
                        });
                    });
                }
            })
            .catch(error => {
                getParticipantFeedback(participationId).then(() => {
                    dispatch({
                        type: PROJECTS.DELETE_PARTICIPANT_FEEDBACK,
                        payload: t('PARTICIPANTS.deleteFeedbackFailure'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.hasChanged + 2
                    });
                });
            });
    };

    const addFeedback = (participationId, obj) => {
        setLoading();
        axios
            .post(process.env.REACT_APP_SERVER + 'api/feedback', obj, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(res => {
                if (res.status === 200) {
                    getParticipantFeedback(participationId).then(() => {
                        dispatch({
                            type: PROJECTS.ADD_PARTICIPANT_FEEDBACK,
                            payload: t(
                                'PARTICIPANTS.addFeedbackSuccessMessage'
                            ),
                            responseType: 'SUCCESS',
                            successiveActions: state.successiveActions + 1,
                            hasChanged: state.successiveActions + 2
                        });
                    });
                } else {
                    getParticipantFeedback(participationId).then(() => {
                        dispatch({
                            type: PROJECTS.ADD_PARTICIPANT_FEEDBACK,
                            payload: t(
                                'PARTICIPANTS.addFeedbackFailureMessage'
                            ),
                            responseType: 'FAILURE',
                            successiveActions: state.successiveActions + 1,
                            hasChanged: state.successiveActions + 2
                        });
                    });
                }
            })
            .catch(error => {
                getParticipantFeedback(participationId).then(() => {
                    dispatch({
                        type: PROJECTS.ADD_PARTICIPANT_FEEDBACK,
                        payload: error,
                        responseType: t(
                            'PARTICIPANTS.addFeedbackFailureMessage'
                        ),
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                });
            });
    };

    const getSolicitation = async userId => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/rhSolicitation/${userId}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: PROJECTS.GET_PARTICIPANT_SOLICITATION,
            payload: res.data
        });
    };

    const getPontos = async userId => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/pendingRequests/${userId}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: PROJECTS.GET_PARTICIPANT_PONTOS,
            payload: res.data
        });
    };

    const updateFeedback = async (id, participationId, data) => {
        setLoading();
        axios
            .put(process.env.REACT_APP_SERVER + `api/feedback/${id}`, data, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(res => {
                if (res.status === 204) {
                    getParticipantFeedback(participationId).then(() => {
                        dispatch({
                            type: PROJECTS.UPDATE_ACTIVITY,
                            payload: t(
                                'PARTICIPANTS.updateFeedbackSuccessMessage'
                            ),
                            responseType: 'SUCCESS'
                        });
                    });
                } else {
                    getParticipantFeedback(participationId).then(() => {
                        dispatch({
                            type: PROJECTS.UPDATE_ACTIVITY,
                            payload: t(
                                'PARTICIPANTS.updateFeedbackFailureMessage'
                            ),
                            responseType: 'FAILURE'
                        });
                    });
                }
            })
            .catch(error => {
                getParticipantFeedback(participationId).then(() => {
                    dispatch({
                        type: PROJECTS.UPDATE_ACTIVITY,
                        payload: t('PARTICIPANTS.updateFeedbackFailureMessage'),
                        responseType: 'FAILURE'
                    });
                });
            });
    };

    const sendEmail = async emailData => {
        return axios
            .post(process.env.REACT_APP_EMAIL + 'api/sendEmail', emailData, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(
                res => console.log('ok'),
                err => Promise.reject('error')
            );
    };

    return (
        <ProjectsContext.Provider
            value={{
                goal: state.goal,
                users: state.users,
                files: state.files,
                listParticipants: state.listParticipants,
                project: state.project,
                loading: state.loading,
                message: state.message,
                addendum: state.addendum,
                projects: state.projects,
                response: state.response,
                partners: state.partners,
                stepFlow: state.stepFlow,
                addendums: state.addendums,
                hasUpdate: state.hasUpdate,
                newProject: state.newProject,
                currentStep: state.currentStep,
                responseType: state.responseType,
                crudAddendum: state.crudAddendum,
                newProjectId: state.newProjectId,
                projectCreated: state.projectCreated,
                responseDelete: state.responseDelete,
                successiveActions: state.successiveActions,
                responseDeleteType: state.responseDeleteType,
                generalInformation: state.generalInformation,
                idealNumberOfChildUpdates: state.idealNumberOfChildUpdates,
                triggerUpdateCreateAction: state.triggerUpdateCreateAction,
                showRequiredFieldMessage: state.showRequiredFieldMessage,
                participants: state.participants,
                phases: state.phases,
                phase: state.phase,
                hasChanged: state.hasChanged,
                panelTab: state.panelTab,
                actionMode: state.actionMode,
                activity: state.activity,
                newActivityId: state.newActivityId,
                openModal: state.openModal,
                expenses: state.expenses,
                dialogData: state.dialogData,
                subsidy: state.subsidy,
                costByProject: state.costByProject,
                participantFeedback: state.participantFeedback,
                participantSolicitation: state.participantSolicitation,
                participantPontos: state.participantPontos,
                completeSubmission: state.completeSubmission,
                setHasChanged,
                getGoal,
                getUsers,
                getProject,
                updateGoal,
                setMessage,
                getPartners,
                getProjects,
                getAllProjects,
                getAddendum,
                submitScope,
                submitParticipant,
                resetProject,
                resetMessage,
                createProject,
                deleteProject,
                submitHeadings,
                changeStepFlow,
                resetAddendums,
                submitSchedule,
                deleteAddendum,
                setProjectCreated,
                resetDeleteMessage,
                triggerUpdateCreate,
                updateCreateAddendums,
                setGeneralInformation,
                updateGeneralInformation,
                setIdealNumberOfChildUpdates,
                getParticipants,
                deleteParticipant,
                getPhases,
                deletePhase,
                addPhase,
                getPhase,
                setErrorMessageInProgressPhase,
                UpdatePhase,
                setValueRequiredMessage,
                addActivity,
                deleteActivity,
                getActivity,
                updateActivity,
                setValuePanelTab,
                setActionMode,
                addParticipantActivity,
                deleteParticipantActivity,
                updateParticipantActivity,
                setNewActivityId,
                setOpenModal,
                getExpenses,
                setDialogData,
                createExpense,
                updateExpense,
                deleteExpense,
                FinishPhase,
                FinishProject,
                submitBudget,
                submitProjectBudget,
                createSubsidy,
                getSubsidy,
                deleteSubsidy,
                editSubsidy,
                getCostbyGpf,
                getParticipantFeedback,
                deleteParticipantFeedback,
                addFeedback,
                getSolicitation,
                getPontos,
                updateFeedback,
                sendEmail,
                setSubmission
            }}
        >
            {props.children}
        </ProjectsContext.Provider>
    );
};

export default ProjectsState;
