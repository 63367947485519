import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import Visibility from '@material-ui/icons/Visibility';
import axios from 'axios';
import InventoryContext from 'context/inventory/inventoryContext';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import history from '../../../history';
import Buttons from 'components/buttons/Pressed';
import Toolbar from '@material-ui/core/Toolbar';
import InputBase from '@material-ui/core/InputBase';
import { Alert } from 'mdi-material-ui';
import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';
import { Screen, Allow } from 'global/constants';
import Cookies from 'js-cookie';
import { formatExemplarCode } from 'utils/library/formatExemplarCode';

const useStyles = makeStyles(theme => ({
    icons: {
        opacity: '0'
    },
    root: {
        flexGrow: 1
    },
    iconReq: {
        marginLeft: '10px',
        opacity: '0',
        backgroundColor: '#6D6F71',
        color: '#FFFFFF;'
    },
    textField: {
        display: 'flex'
    },
    textFieldCustum: {
        display: 'flex',
        marginLeft: '10px'
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            height: 32
        }
    },
    searchInventory: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#F5F6FA',
        '&:hover': {
            backgroundColor: 'rgb(224, 224, 224)'
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1)
        }
    }
}));

const BookTableAction = props => {
    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }
    const classes = useStyles();
    const [t] = useTranslation();
    const [openDelete, setOpenDelete] = useState(false);
    const inventoryContext = useContext(InventoryContext);
    const { deleteBook, getBook, getBooks, getExemplar, deleteExemplar } =
        inventoryContext;
    const [changeContext, setchangeContex] = useState(0);
    const [password, setPassword] = useState('');
    const [passRight, setPassRight] = useState(false);
    const { item, isExemplar, title } = props;

    const handleOpenDelete = () => {
        setOpenDelete(true);
    };
    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    var pass = true;
    const handleDelete = () => {
        setchangeContex(changeContext + 1);
        if (changeContext > 0) {
            sendRequest();
            if (pass) {
                setPassword('');
            } else {
                setOpenDelete(false);
            }
        }
    };

    const verifyEdit = () => {
        if (isExemplar) {
            axios
                .get(
                    process.env.REACT_APP_SERVER +
                        `api/Library/Exemplars/${item.id}`,
                    {
                        headers: {
                            'Content-type': 'application/json',
                            Authorization:
                                'bearer ' +
                                (Cookies.get('auth-token')
                                    ? Cookies.get('auth-token')
                                    : '')
                        }
                    }
                )
                .then(res => {
                    if (res.statusText === 'OK') {
                        history.push(`/Books/Exemplar/${item.id}/edit`);
                    } else {
                        // getExemplar(item.id);
                    }
                })
                .catch(error => {
                    // getExemplar(item.id);
                });
        } else {
            axios
                .get(
                    process.env.REACT_APP_SERVER +
                        `api/Library/Books/${item.id}`,
                    {
                        headers: {
                            'Content-type': 'application/json',
                            Authorization:
                                'bearer ' +
                                (Cookies.get('auth-token')
                                    ? Cookies.get('auth-token')
                                    : '')
                        }
                    }
                )
                .then(res => {
                    if (res.statusText === 'OK') {
                        history.push(`/books/${item.id}/edit`);
                    } else {
                        getBook(item.id);
                    }
                })
                .catch(error => {
                    getBook(item.id);
                });
        }
    };

    const verifyView = () => {
        if (isExemplar) {
            axios
                .get(
                    process.env.REACT_APP_SERVER +
                        `api/Library/Exemplars/${item.id}`,
                    {
                        headers: {
                            'Content-type': 'application/json',
                            Authorization:
                                'bearer ' +
                                (Cookies.get('auth-token')
                                    ? Cookies.get('auth-token')
                                    : '')
                        }
                    }
                )
                .then(res => {
                    if (res.statusText === 'OK') {
                        history.push(`/Books/Exemplar/${item.id}`);
                    } else {
                        // getExemplar(item.id);
                    }
                })
                .catch(error => {
                    //getExemplar(item.id);
                });
        } else {
            axios
                .get(
                    process.env.REACT_APP_SERVER +
                        `api/Library/Books/${item.id}`,
                    {
                        headers: {
                            'Content-type': 'application/json',
                            Authorization:
                                'bearer ' +
                                (Cookies.get('auth-token')
                                    ? Cookies.get('auth-token')
                                    : '')
                        }
                    }
                )
                .then(res => {
                    if (res.statusText === 'OK') {
                        history.push(`/books/${item.id}`);
                    } else {
                        getBook(item.id, false);
                    }
                })
                .catch(error => {
                    getBook(item.id, false);
                });
        }
    };

    function sendRequest() {
        const requestInfo = {
            method: 'POST',
            body: JSON.stringify({
                email: auth.userName,
                password: password
            }),
            headers: new Headers({
                'Content-type': 'application/json'
            })
        };

        fetch(process.env.REACT_APP_SERVER + 'api/auth/login', requestInfo)
            .then(response => {
                var hasRented = false;
                if (response.ok) {
                    pass = false;
                    setPassRight(false);
                    if (isExemplar) {
                        if (item.status === 'RENTED') {
                            hasRented = true;
                        }
                        deleteExemplar(item.id, hasRented, getBooks);
                    } else {
                        var exemplares = item.exemplars;
                        exemplares.forEach((exemplar, index) => {
                            if (exemplar.status == 'RENTED') {
                                hasRented = true;
                                return true;
                            }
                        });
                        deleteBook(item.id, hasRented, getBooks);
                    }
                    return false;
                } else {
                    pass = true;
                    setPassRight(true);
                    return true;
                }
            })
            .catch(error => {
                pass = true;
                setPassRight(true);
                return true;
            });
    }

    return (
        <React.Fragment>
            {GetGeneralPermissionForAction(Screen.BOOKS, Allow.READ) && (
                <Tooltip
                    title={t('GENERAL.view')}
                    aria-label="details"
                    placement="top"
                >
                    <IconButton className={classes.icons} onClick={verifyView}>
                        <Visibility />
                    </IconButton>
                </Tooltip>
            )}
            {GetGeneralPermissionForAction(Screen.BOOKS, Allow.EDIT) && (
                <Tooltip title="Editar" aria-label="edit" placement="top">
                    <IconButton className={classes.icons} onClick={verifyEdit}>
                        <Edit />
                    </IconButton>
                </Tooltip>
            )}
            {GetGeneralPermissionForAction(Screen.BOOKS, Allow.DELETE) && (
                <Tooltip title="Deletar" aria-label="delete" placement="top">
                    <IconButton
                        className={classes.icons}
                        onClick={handleOpenDelete}
                    >
                        <Delete />
                    </IconButton>
                </Tooltip>
            )}
            <Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="form-dialog-title"
                msg="mensagem"
            >
                <DialogTitle className={classes.Title}>
                    {t('GENERAL.delete')}
                    <IconButton
                        style={{
                            float: 'right',
                            marginTop: -10,
                            marginRight: -17
                        }}
                        edge="end"
                        color="inherit"
                        onClick={handleCloseDelete}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {changeContext === 0 ? (
                        <DialogContentText>
                            {isExemplar
                                ? t('INVENTORY.deleteMessageExemplar')
                                : t('INVENTORY.deleteMessageTitle')}
                            <br></br>
                            <br></br>
                            <b>{isExemplar ? title.title : item.title}</b>
                            <br></br>
                            <b style={{ color: 'rgba(33, 33, 33, 0.3)' }}>
                                {isExemplar
                                    ? 'Código do exemplar: ' +
                                      formatExemplarCode(item.code.toString())
                                    : 'ISBN: ' + item.isbn}
                            </b>
                            <br></br>
                            <br></br>
                            {isExemplar ? '' : t('INVENTORY.deleteNoticeTitle')}
                        </DialogContentText>
                    ) : (
                        <DialogContentText>
                            {isExemplar
                                ? t('INVENTORY.deletePasswordExemplar')
                                : t('INVENTORY.deletePassword')}
                            <br></br>
                            <br></br>
                            <b>{isExemplar ? title.title : item.title}</b>
                            <br></br>
                            <b style={{ color: 'rgba(33, 33, 33, 0.3)' }}>
                                {isExemplar
                                    ? 'Código do exemplar: ' +
                                      formatExemplarCode(item.code.toString())
                                    : 'ISBN: ' + item.isbn}
                            </b>
                            <br></br>
                            <br></br>
                            {t('INVENTORY.deleteActionCannotBeUndone')}
                            <div className={classes.divAddParticipantLeft}>
                                <Toolbar
                                    className={classes.searchToolbarInventory}
                                >
                                    <div className={classes.searchInventory}>
                                        <InputBase
                                            placeholder={t(
                                                'INVENTORY.password'
                                            )}
                                            value={password}
                                            onChange={e =>
                                                setPassword(e.target.value)
                                            }
                                            type="password"
                                            classes={{
                                                root: classes.inputRoot,
                                                input: classes.inputInput
                                            }}
                                            inputProps={{
                                                'aria-label': 'search'
                                            }}
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                </Toolbar>
                                {passRight && (
                                    <span
                                        id="incorreto"
                                        style={{
                                            color: '#b92d00',
                                            marginLeft: 30
                                        }}
                                    >
                                        Senha incorreta
                                        <Alert className={classes.alert} />
                                    </span>
                                )}
                            </div>
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Buttons
                        onClick={handleCloseDelete}
                        tipo="BN-noback"
                        conteudo={t('GENERAL.cancel').toUpperCase()}
                    />
                    <Buttons
                        onClick={handleDelete}
                        tipo="BN-blue"
                        conteudo={t('GENERAL.delete').toUpperCase()}
                    />
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default BookTableAction;
