/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Transition from 'components/layout/transition/Transition';
import FilterContainer from 'components/containers/FilterContainer';
import InputFilter from 'components/inputs/InputFilter';
import InputFilterDate from 'components/inputs/InputFilterDate';
import CreditSolicitationTable from './CreditSolicitationTable';
import Dissatisfied from '@material-ui/icons/SentimentVeryDissatisfied';
import { useStyles } from 'components/layout/CommonStyles';
import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';
import { FormatDateToFront } from 'utils/dates/FormatDateToFront';
import { GetMonthAndYearInWords } from 'utils/dates/GetMonthAnsYearInWords';

import { Screen, Allow } from 'global/constants';
import { useQueryPersonalCreditSolicitations } from 'hooks/DataFetching/Quotas/Dashboard/useQueryPersonalCreditSolicitations';
import { useQueryLSBDCreditSolicitations } from 'hooks/DataFetching/Quotas/Dashboard/useQueryLSBDCreditSolicitations';

import {
    CreditSolicitationsStatusArray,
    translateCreditSolicitationsStatus,
    CreditSolicitationStatus
} from '../../ScreenUtils';

import { screenStyles } from '../../Styles';
import { isSameDate } from 'utils/dates/IsSameDate';
import { dateToBR } from 'utils/dates/DateToBRr';

const CreditSolicitationForm = ({ isLSBD, setBadgeValue }) => {
    const classes = useStyles();
    const styles = screenStyles();
    const [t] = useTranslation();

    const [findText, setFindText] = useState('');
    const [filterStatus, setFilterStatus] = useState('ALL');
    const [filterDate, setFilterDate] = useState(null);
    const [filterName, setFilterName] = useState('');
    const [dataFilter, setDataFilter] = useState([]);

    const [canClick, setCanClick] = useState(false);

    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const { isLoading, isFetching, error, data } = isLSBD
        ? useQueryLSBDCreditSolicitations({})
        : useQueryPersonalCreditSolicitations({}, auth.id);

    const hasItens = data && data.length > 0;
    const [hasNoResult, setHasNoResult] = useState(
        hasItens && dataFilter && dataFilter.length === 0
    );

    useEffect(() => {
        if (data) {
            setDataFilter(data.filter(x => !x.hasDeleted));
            if (isLSBD) {
                setBadgeValue(
                    data.filter(
                        x => x.status === CreditSolicitationStatus.PENDING
                    ).length
                );
            }
            setHasNoResult(false);
        }
    }, [data]);

    useEffect(() => {
        if (data && data.length > 0) {
            const { newDataFilter, textNotFound } = applyFilter(
                data.filter(x => !x.hasDeleted)
            );

            if (newDataFilter.length === 0) {
                setDataFilter([]);
                setHasNoResult(true);
                setFindText(textNotFound);
            } else {
                setDataFilter(newDataFilter);
                setHasNoResult(false);
                setFindText('');
            }

            setDataFilter(newDataFilter);
        }
    }, [filterDate, filterName, filterStatus]);

    const applyFilter = data => {
        let newDataFilter = [];
        let textNotFound = '';

        newDataFilter = data;

        if (filterStatus && filterStatus !== 'ALL') {
            newDataFilter = newDataFilter.filter(
                item => item.status && item.status === filterStatus
            );

            if (!textNotFound && newDataFilter.length === 0)
                textNotFound = translateCreditSolicitationsStatus(filterStatus);
        }

        if (filterName) {
            newDataFilter = newDataFilter.filter(item =>
                item.user.name.toLowerCase().includes(filterName.toLowerCase())
            );

            if (!textNotFound && newDataFilter.length === 0)
                textNotFound = filterName;
        }

        if (filterDate && !isNaN(filterDate)) {
            newDataFilter = newDataFilter.filter(
                item =>
                    item.createdAt &&
                    isSameDate(FormatDateToFront(item.createdAt), filterDate)
            );

            if (!textNotFound && newDataFilter.length === 0)
                textNotFound = dateToBR(filterDate);
        }

        return { newDataFilter, textNotFound };
    };

    useEffect(() => {
        if (filterDate || filterName || filterStatus) {
            setCanClick(true);
        } else {
            setCanClick(false);
        }
    }, [filterStatus, filterName, filterStatus]);

    return (
        <React.Fragment>
            <Transition loading={isLoading && isFetching} newDesign={true}>
                <FilterContainer>
                    <InputFilterDate
                        filterValue={filterDate}
                        setFilter={setFilterDate}
                        label={'GENERAL.solicitationDate'}
                    />
                    {isLSBD ? (
                        <InputFilter
                            filterValue={filterName}
                            setFilter={setFilterName}
                            label={t('GENERAL.collaborator')}
                        />
                    ) : null}
                    <InputFilter
                        filterValue={filterStatus}
                        setFilter={setFilterStatus}
                        label={'GENERAL.status'}
                        isSelect
                        arraySelected={
                            isLSBD
                                ? CreditSolicitationsStatusArray.filter(
                                      x =>
                                          x.value !==
                                          CreditSolicitationStatus.CANCELLED
                                  )
                                : CreditSolicitationsStatusArray
                        }
                    />
                </FilterContainer>
                <React.Fragment>
                    <CreditSolicitationTable
                        rows={dataFilter}
                        toFirstPage={canClick}
                        headers={[
                            'id',
                            'createdAt',
                            'value',
                            'status',
                            'user',
                            'operator',
                            'closedAt',
                            'receiptFilename',
                            'receiptPath',
                            'description',
                            'justification',
                            'date'
                        ]}
                        visibleHeaders={
                            isLSBD
                                ? [
                                      'Data de Solicitação',
                                      'Colaborador',
                                      'Status',
                                      'Valor Solicitado',
                                      ''
                                  ]
                                : [
                                      'Data de Solicitação',
                                      'Status',
                                      'Valor Solicitado',
                                      ''
                                  ]
                        }
                        sortedFields={
                            isLSBD
                                ? ['createdAt', 'user', 'status', 'value']
                                : ['createdAt', 'status', 'value']
                        }
                        isLSBD={isLSBD}
                    />

                    {!hasItens && !error && (
                        <div className={classes.noDataWrapper}>
                            <h2>
                                {isLSBD
                                    ? t('QUOTAS.noLSBDTransactionsMessage')
                                    : t(
                                          'QUOTAS.noPersonalCreditSolicitationsMessage'
                                      )}
                            </h2>
                            <h4>
                                {isLSBD
                                    ? t(
                                          'QUOTAS.noLSBDCreditSolicitationsSubMessage'
                                      )
                                    : t(
                                          'QUOTAS.noPersonalCreditSolicitationsSubMessage'
                                      )}
                            </h4>
                        </div>
                    )}
                    {hasNoResult && !error && (
                        <div className={classes.noParticipantsMessage}>
                            <div>
                                <Dissatisfied
                                    className={classes.notFoundIconStyle}
                                />
                            </div>
                            <h2>{t('GENERAL.noResultsFound')}</h2>
                            <h5>{`Sua busca por "${findText}" não retornou resultados.`}</h5>
                        </div>
                    )}
                    {error && (
                        <div className={classes.noParticipantsMessage}>
                            <div>
                                <Dissatisfied
                                    className={classes.notFoundIconStyle}
                                />
                            </div>
                            <h2>{'Houve um erro de conexão com o Servidor'}</h2>
                        </div>
                    )}
                </React.Fragment>
            </Transition>
        </React.Fragment>
    );
};

export default CreditSolicitationForm;
