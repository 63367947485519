/* eslint-disable */
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import GetApp from "@material-ui/icons/GetApp";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Dissatisfied from "@material-ui/icons/SentimentVeryDissatisfied";
import Footer from "components/layout/Footer";
import Spinner from "components/layout/spinners/Index";
import AllowancesContext from "context/allowance/allowanceContext";
import InventoryContext from "context/inventory/inventoryContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import { useStyles } from "components/layout/CommonStyles";
import MySnackbarContentWrapper from "components/layout/Message";
import AdvancedSearchDialog from './AdvancedSearchDialog';
import DialogExport from './DialogExport';
import EquipmentTable from "./EquipmentTable";
import { GetGeneralPermissionForAction } from "utils/permissions/GetGeneralPermissionForAction";
import { Screen, Allow } from "global/constants";

const Equipment = (props) => {
    const classes = useStyles();
    const [t] = useTranslation();
    const inventoryContext = useContext(InventoryContext);
    const allowanceContext = useContext(AllowancesContext);
    const [placesArray, setPlacesArray] = useState([]);
    const [openExport, setOpenExport] = useState(false);
    const [openAdvancedSearch, setOpenAdvancedSearch] = useState(false);
    let dataFilter = [];

    const [snackBar, setSnackBar] = useState({
        open: false,
        kind: "",
        content: ""
    });
    const [filterName, setFilterName] = useState("");
    const [filterCodeId, setFilterCodeId] = useState("");
    const [filterPlace, setFilterPlace] = useState("");

    // Advanced 
    const [filterStatus, setFilterStatus] = useState("");
    const [filterNS, setFilterNs] = useState("");
    const [filterProject, setFilterProject] = useState("");
    const [filterResponsible, setFilterResponsible] = useState("");
    const [filterInvoice, setFilterInvoice] = useState("");
    const [filterAcquisitionDate, setFilterAcquisitionDate] = useState("");
    const [filterWarrantyData, setFilterWarrantyDate] = useState("");
    const [filterCategory, setFilterCategory] = useState("");

    const [canClick, setCanClick] = useState(false);

    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }

    const {
        getAllEquipment,
        equipment,
        loading,
        response,
        hasChanged,
        setHasChanged,
        responseType,
        successiveActions,
        getPlaces,
        places,
        searchLinkEquipment,
    } = inventoryContext;

    useEffect(() => {
        var data = searchLinkEquipment.split("&")
        var name = data[1] && data[1].replace("%20", ' ');
        var project = data[2] && data[2].replace("%20", ' ');
        setFilterName(name);
        setFilterProject(project);

    }, [searchLinkEquipment]);

    const {
        allowanceResponse,
        allowanceResponseType,
        messageHasChanged,
        resetAllowanceMessage,
    } = allowanceContext;

    useEffect(() => {
        getPlaces();
    }, [JSON.stringify(places), Object.keys(places).length,]);

    const getPermissionEquipment = () => {
        if (localStorage.getItem("auth-token") !== null) {
            var auth = JSON.parse(localStorage.getItem("auth-token"));
            const permission = auth.permissionsByModule;

            // Get the user profile rule for the people management module
            const peopleManagement = permission.filter(el => el.systemId == 6905701);
            // Get the user profile rule for the inventory module
            const inventory = permission.filter(el => el.systemId == 6910301);

            // Checks whether the user is an administrative manager in the people management module and an administrator in the assets module 
            if (inventory[0].roleId === 6905151) {
                return true;
            } else {
                return false;
            }
        }
    }

    const clearFilterLocationType = () => {
        dataFilter = equipment;
        dataFilter = dataFilter.filter(
            item =>
                item.name.toLowerCase().startsWith(filterName && filterName.toLowerCase())
        );
    }

    function dateToBR(date) {
        var data = new Date(date),
            dia = data.getDate().toString(),
            diaF = (dia.length == 1) ? '0' + dia : dia,
            mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
            mesF = (mes.length == 1) ? '0' + mes : mes,
            anoF = data.getFullYear();
        return diaF + "/" + mesF + "/" + anoF;
    }

    dataFilter = equipment;

    dataFilter = dataFilter.map(function (num) {
        if (num.ufcCode) num.ufcCodeF = `UFC-${num.ufcCode}`
        if (num.fcpcCode) num.fcpcCodeF = `FCPC-${num.fcpcCode}`
        if (num.lsbdCode) num.lsbdCodeF = `LSBD-${num.lsbdCode}`
        if (num.externCode) num.externalCodeF = `${num.externPartner}-${num.externCode}`
        return num;
    });

    const codeIdFilter = () => {
        dataFilter = dataFilter.filter(
            item =>
                item.fcpcCodeF && item.fcpcCodeF.toLowerCase().includes(filterCodeId.toLowerCase()) ||
                item.lsbdCodeF && item.lsbdCodeF.toLowerCase().includes(filterCodeId.toLowerCase()) ||
                item.ufcCodeF && item.ufcCodeF.toLowerCase().includes(filterCodeId.toLowerCase()) ||
                item.externalCodeF && item.externalCodeF.toLowerCase().includes(filterCodeId.toLowerCase())
        );
    }

    if (filterName) {
        dataFilter = dataFilter.filter(
            item =>
                item.name.toLowerCase().includes(filterName.toLowerCase())
        );
    }
    if (filterPlace) {
        dataFilter = dataFilter.filter(
            item =>
                filterPlace.toLowerCase() !== "todos os locais".toLowerCase() ?
                    item.place && item.place.toLowerCase() === filterPlace.toLowerCase()
                    :
                    item
        );
    }

    if (filterResponsible) {
        dataFilter = dataFilter.filter(
            item =>
                item.responsible && item.responsible.toLowerCase().includes(filterResponsible.toLowerCase())
        );
    }

    if (filterCodeId) {
        codeIdFilter();
    }

    if (filterNS) {
        dataFilter = dataFilter.filter(
            item =>
                item.serialNumber && item.serialNumber.toLowerCase().startsWith(filterNS.toLowerCase())
        );
    }

    if (filterStatus) {
        dataFilter = dataFilter.filter(
            item =>
                item.itemStatus && item.itemStatus.toLowerCase() === filterStatus.toLowerCase()
        );
    }

    if (filterProject) {
        dataFilter = dataFilter.filter(
            item =>
                item.project && item.project.toLowerCase().startsWith(filterProject.toLowerCase())
        );
    }

    if (filterInvoice) {
        dataFilter = dataFilter.filter(
            item =>
                item.invoice && item.invoice.toLowerCase().startsWith(filterInvoice.toLowerCase())
        );
    }

    if (filterCategory) {
        dataFilter = dataFilter.filter(
            item =>
                item.category && item.category.toLowerCase().startsWith(filterCategory.toLowerCase())
        );
    }

    if (filterAcquisitionDate) {
        dataFilter = dataFilter.filter(
            item =>
                item.acquisitionDate && dateToBR(item.acquisitionDate).toLowerCase().startsWith(dateToBR(filterAcquisitionDate).toLowerCase())
        );
    }

    if (filterWarrantyData) {
        dataFilter = dataFilter.filter(
            item =>
                item.warrantyData && dateToBR(item.warrantyData).toLowerCase().startsWith(dateToBR(filterWarrantyData).toLowerCase())
        );
    }
    const hasItens = equipment.length > 0;
    const [hasNoResult, setHasNoResult] = useState(hasItens && dataFilter.length === 0);
    const [itensTable, setItensTable] = useState(
        // renderTable(dataFilter)
    );
    const transitions = useTransition(loading, null, {
        from: { opacity: 0 },
        enter: {
            opacity: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "calc(100vh - 70px)"
        },
        leave: { opacity: 0 }
    });

    useEffect(() => {
        const myPlaces = [...places];
        myPlaces.sort(function (a, b) {
            if (a.name === null) a.name = "";
            if (b.name === null) b.name = "";

            if (a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() >
                b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim()) {
                return 1;
            }
            if (a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() <
                b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim()) {
                return -1;
            }
            // a must be equal to b
            return 0;
        });

        var aux = {};
        var allPlaces = {};
        var _places = [];
        allPlaces.value = "all";
        allPlaces.label = "Todos os locais";

        _places.push(allPlaces);
        for (var i = 0; i < myPlaces.length; i++) {
            var item = myPlaces[i];
            aux.value = item.id;
            aux.label = item.name;
            _places.push(aux);
            aux = {};
        }
        setPlacesArray(_places);
    }, [places]);

    useEffect(() => {
        getAllEquipment();
        if (allowanceResponseType === "ERROR" && messageHasChanged) {
            handleSnack({ kind: "error", content: allowanceResponse });
        }
        if (responseType === "SUCCESS" && hasChanged > successiveActions) {
            handleSnack({ kind: "success", content: response });
        }
        if (responseType === "ERROR" && hasChanged > successiveActions) {
            handleSnack({ kind: "error", content: response });
        }
        return () => { };
    }, [
        responseType,
        response,
        successiveActions,
        allowanceResponseType,
        allowanceResponse,
    ]);

    const handleSnack = () => {
        setSnackBar({ ...snackBar, open: true });
        setHasChanged();
    };
    const handleSnackClose = () => {
        setSnackBar({ ...snackBar, open: false });
        resetAllowanceMessage();
    };

    const handleSearchName = event => {
        setFilterName(event.target.value);
        clearFilterLocationType();

        dataFilter = dataFilter.filter(
            item =>
                item.name.toLowerCase().includes(event.target.value.toLowerCase())
        );

        if (dataFilter.length === 0)
            setHasNoResult(true);
        else
            setHasNoResult(false);
    };

    const handleSearchPlace = event => {
        setFilterPlace(event.target.value);

        if (filterName) {
            dataFilter = dataFilter.filter(
                item =>
                    item.name.toLowerCase().startsWith(filterName.toLowerCase())
            );
        }

        if (filterCodeId) {
            codeIdFilter();
        }

        if (event.target.value.toLowerCase() !== "todos os locais".toLowerCase()) {
            if (dataFilter.length === 0) {
                setHasNoResult(true);
            }
            else {
                setHasNoResult(false);
            }
        }
    };

    const handleSearchResponsible = event => {
        setFilterResponsible(event.target.value);
        clearFilterLocationType();

    };

    const handleSearchCodeId = event => {
        setFilterCodeId(event.target.value);
        clearFilterLocationType();
        codeIdFilter();

        if (dataFilter.length === 0)
            setHasNoResult(true);
        else
            setHasNoResult(false);
    };

    useEffect(() => {
        if (filterName) {
            dataFilter = dataFilter.filter(
                item =>
                    item.name.toLowerCase().includes(filterName.toLowerCase())
            );
        }

        if (filterPlace) {
            dataFilter = dataFilter.filter(
                item =>
                    item.place.toLowerCase() === filterPlace.toLowerCase()
            );
        }

        if (filterPlace.toLowerCase() !== "todos os locais".toLowerCase()) {
            if (dataFilter.length === 0) {
                setHasNoResult(true);
            }
            else {
                setHasNoResult(false);
            }
        } else {
            setHasNoResult(false);
        }

        if (filterResponsible) {
            dataFilter = dataFilter.filter(
                item =>
                    item.responsible && item.responsible.toLowerCase().includes(filterResponsible.toLowerCase())
            );
        }

        if (filterCodeId) {
            codeIdFilter();
        }

        if (filterNS) {
            dataFilter = dataFilter.filter(
                item =>
                    item.serialNumber && item.serialNumber.toLowerCase().startsWith(filterNS.toLowerCase())
            );
        }

        if (filterStatus) {
            dataFilter = dataFilter.filter(
                item =>
                    item.itemStatus && item.itemStatus.toLowerCase() === filterStatus.toLowerCase()
            );
        }

        if (filterProject) {
            dataFilter = dataFilter.filter(
                item =>
                    item.project && item.project.toLowerCase().startsWith(filterProject.toLowerCase())
            );
        }

        if (filterInvoice) {
            dataFilter = dataFilter.filter(
                item =>
                    item.invoice && item.invoice.toLowerCase().startsWith(filterInvoice.toLowerCase())
            );
        }

        if (filterCategory) {
            dataFilter = dataFilter.filter(
                item =>
                    item.category && item.category.toLowerCase().startsWith(filterCategory.toLowerCase())
            );
        }

        if (filterAcquisitionDate) {
            dataFilter = dataFilter.filter(
                item =>
                    item.acquisitionDate && dateToBR(item.acquisitionDate).toLowerCase().startsWith(dateToBR(filterAcquisitionDate).toLowerCase())
            );
        }

        if (filterWarrantyData) {
            dataFilter = dataFilter.filter(
                item =>
                    item.warrantyDate && dateToBR(item.warrantyDate).toLowerCase().startsWith(dateToBR(filterWarrantyData).toLowerCase())
            );
        }

    }, [dataFilter.length]);

    useEffect(() => {
        if (filterName ||
            filterWarrantyData ||
            filterAcquisitionDate ||
            filterCategory ||
            filterInvoice ||
            filterProject ||
            filterStatus ||
            filterNS ||
            filterCodeId ||
            filterResponsible ||
            filterPlace
        ) { setCanClick(true) }
        else {
            setCanClick(false)
        }
    }, [filterName,
        filterWarrantyData,
        filterAcquisitionDate,
        filterCategory,
        filterInvoice,
        filterProject,
        filterStatus,
        filterNS,
        filterCodeId,
        filterResponsible,
        filterPlace])

    const advancedSearch = (name, place, responsible, idCode, serialNumber, status, project, invoice, category, dateAcquisition, warrantyDate) => {
        setFilterName(name);
        setFilterPlace(place);
        setFilterResponsible(responsible);
        setFilterCodeId(idCode);
        setFilterNs(serialNumber);
        setFilterStatus(status);
        setFilterProject(project);
        setFilterInvoice(invoice);
        setFilterCategory(category);
        setFilterAcquisitionDate(dateAcquisition);
        setFilterWarrantyDate(warrantyDate);
    }

    return transitions.map(({ item, key, props }) =>
        item ? (
            <animated.div key={key} style={props}>
                <Spinner />;
            </animated.div>
        ) : (
            <animated.div key={key} style={props}>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={snackBar.open}
                    onClose={handleSnackClose}
                    TransitionComponent={Slide}
                    transitionDuration={{ enter: 500, exit: 500 }}
                    ContentProps={{
                        "aria-describedby": "message-id"
                    }}
                    autoHideDuration={2000}
                >
                    <MySnackbarContentWrapper
                        onClose={handleSnackClose}
                        variant={
                            responseType !== ""
                                ? responseType.toLowerCase()
                                : allowanceResponseType.toLowerCase()
                        }
                        message={
                            <span id="message-id">
                                {response !== "" ? response : allowanceResponse}
                            </span>
                        }
                    />
                </Snackbar>
                <div className="container">
                    <Breadcrumbs
                        className={classes.breadCrumbs}
                        aria-label="breadcrumb"
                        separator={<NavigateNextIcon fontSize="small" />}
                    >
                        <Typography>{t("INVENTORY.inventory")}</Typography>
                        <Typography>{t("INVENTORY.equipments")}</Typography>
                    </Breadcrumbs>

                    <div className="container-header">
                        <div className="title">
                            <h1 className={classes.title}>{t("INVENTORY.equipments")}</h1>
                        </div>
                        {GetGeneralPermissionForAction(Screen.EQUIPMENTS, Allow.CREATE) &&
                            <Link to={"/InventoryEquipment/new"}>
                                <Button
                                    className={classes.blueButton}
                                    variant="contained"
                                    color="primary"
                                >
                                    {t("INVENTORY.addEquipment")}
                                </Button>
                            </Link>}
                    </div>
                    <Paper >
                        <div className="cabecalho">
                            {t("INVENTORY.equipments").toUpperCase()}
                            <span style={{ float: "right", marginRight: 10 }}>
                                <span style={{ float: "right", marginRight: 10 }}>{t("INVENTORY.export").toUpperCase()}
                                    <IconButton disabled={dataFilter.length === 0} className="btn" style={{ float: "right" }} edge="end" color="inherit" onClick={() => setOpenExport(true)}>
                                        <GetApp />
                                    </IconButton>
                                </span>
                            </span>
                        </div>
                        <div className={classes.divAddParticipantLeft}>
                            <Toolbar className={classes.searchToolbarInventory}>
                                <div style={{ display: !hasItens ? "none" : "" }} className={classes.searchInventory}>
                                    <InputBase
                                        placeholder={t("INVENTORY.name")}
                                        value={filterName}
                                        onChange={handleSearchName}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputSearch
                                        }}
                                        inputProps={{ "aria-label": "search" }}
                                    />
                                </div>
                            </Toolbar>

                            <Toolbar className={classes.searchToolbarInventory} >
                                <div style={{ display: !hasItens ? "none" : "" }} className={classes.searchInventory} >
                                    <TextField
                                        select
                                        id="searchPlace"
                                        label={t("INVENTORY.place")}
                                        name="searchPlace"
                                        style={{ minWidth: "250px", marginTop: 0, marginBottom: 0, marginLeft: 5 }}
                                        value={filterPlace}
                                        className={classes.textField}
                                        onChange={handleSearchPlace}
                                        margin="normal"
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputSearch
                                        }}
                                        inputProps={{ "aria-label": "search" }}
                                    >
                                        {placesArray.map(places => (
                                            <MenuItem key={places.value} value={places.label}>
                                                {places.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            </Toolbar>
                            <Toolbar className={classes.searchToolbarInventory} >
                                <div style={{ display: !hasItens ? "none" : "" }} className={classes.searchInventory}>
                                    <InputBase
                                        placeholder={t("INVENTORY.responsible")}
                                        value={filterResponsible}
                                        onChange={handleSearchResponsible}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputSearch
                                        }}
                                        inputProps={{ "aria-label": "search" }}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                            </Toolbar>
                            <Toolbar className={classes.searchToolbarInventory} >
                                <div style={{ display: !hasItens ? "none" : "" }} className={classes.searchInventory}>
                                    <InputBase
                                        placeholder={t("INVENTORY.indentificationCode")}
                                        value={filterCodeId}
                                        onChange={handleSearchCodeId}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputSearch
                                        }}
                                        inputProps={{ "aria-label": "search" }}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                            </Toolbar>
                            <Toolbar className={classes.searchToolbarInventory} >
                                <div style={{ display: !hasItens ? "none" : "" }} >
                                    <Button
                                        style={{ margin: "22px", padding: "15px" }}
                                        onClick={() => setOpenAdvancedSearch(true)}
                                    >
                                        {t("INVENTORY.advancedSearch")}
                                    </Button>
                                </div>
                            </Toolbar>
                        </div>
                        <EquipmentTable
                            rows={dataFilter}
                            toFirstPage={canClick}
                            headers={["id", "name", "responsible", "lsbdCodeF", "fcpcCodeF", "ufcCodeF", "place", "isbn", "brand", "externalCodeF", "externPartner", "serialNumber", "class", "acquisitionDate", "category", "fileUserExternal", "description", "lastMaintenance", "warrantyData", "libraryExemplar", "project", "itemStatus"]}
                            visibleHeaders={["nome", "local", "responsável", "código de identificação", "projeto", ""]}
                            sortedFields={["name", "place", "responsible", "ufcCodeF", "project"]}
                            mainField="name"
                        />
                        {hasItens ? itensTable :
                            <div className={classes.noParticipantsMessage}>
                                <Spinner />;
                            </div>
                        }
                        {hasNoResult && filterName ?
                            <div className={classes.noParticipantsMessage}>
                                <div >
                                    <Dissatisfied className={classes.notFoundIconStyle} />
                                </div>
                                <h2>{t("GENERAL.noResultsFound")}</h2>
                                <h5>{`Sua busca por "${filterName}" não retornou resultados.`}</h5>
                            </div>
                            : hasNoResult && filterCodeId ?
                                <div className={classes.noParticipantsMessage}>
                                    <div >
                                        <Dissatisfied className={classes.notFoundIconStyle} />
                                    </div>
                                    <h2>{t("GENERAL.noResultsFound")}</h2>
                                    <h5>{`Sua busca por "${filterCodeId}" não retornou resultados.`}</h5>
                                </div>
                                : hasNoResult && filterPlace ?
                                    <div className={classes.noParticipantsMessage}>
                                        <div >
                                            <Dissatisfied className={classes.notFoundIconStyle} />
                                        </div>
                                        <h2>{t("GENERAL.noResultsFound")}</h2>
                                        <h5>{`Sua busca por "${filterPlace}" não retornou resultados.`}</h5>
                                    </div>
                                    : hasNoResult && filterResponsible ?
                                        <div className={classes.noParticipantsMessage}>
                                            <div >
                                                <Dissatisfied className={classes.notFoundIconStyle} />
                                            </div>
                                            <h2>{t("GENERAL.noResultsFound")}</h2>
                                            <h5>{`Sua busca por "${filterResponsible}" não retornou resultados.`}</h5>
                                        </div>
                                        : hasNoResult && filterNS ?
                                            <div className={classes.noParticipantsMessage}>
                                                <div >
                                                    <Dissatisfied className={classes.notFoundIconStyle} />
                                                </div>
                                                <h2>{t("GENERAL.noResultsFound")}</h2>
                                                <h5>{`Sua busca por "${filterNS}" não retornou resultados.`}</h5>
                                            </div>
                                            : hasNoResult && filterStatus ?
                                                <div className={classes.noParticipantsMessage}>
                                                    <div >
                                                        <Dissatisfied className={classes.notFoundIconStyle} />
                                                    </div>
                                                    <h2>{t("GENERAL.noResultsFound")}</h2>
                                                    <h5>{`Sua busca por "${filterStatus}" não retornou resultados.`}</h5>
                                                </div>
                                                : hasNoResult && filterProject ?
                                                    <div className={classes.noParticipantsMessage}>
                                                        <div >
                                                            <Dissatisfied className={classes.notFoundIconStyle} />
                                                        </div>
                                                        <h2>{t("GENERAL.noResultsFound")}</h2>
                                                        <h5>{`Sua busca por "${filterProject}" não retornou resultados.`}</h5>
                                                    </div>
                                                    : hasNoResult && filterInvoice ?
                                                        <div className={classes.noParticipantsMessage}>
                                                            <div >
                                                                <Dissatisfied className={classes.notFoundIconStyle} />
                                                            </div>
                                                            <h2>{t("GENERAL.noResultsFound")}</h2>
                                                            <h5>{`Sua busca por "${filterInvoice}" não retornou resultados.`}</h5>
                                                        </div>
                                                        : hasNoResult && filterCategory ?
                                                            <div className={classes.noParticipantsMessage}>
                                                                <div >
                                                                    <Dissatisfied className={classes.notFoundIconStyle} />
                                                                </div>
                                                                <h2>{t("GENERAL.noResultsFound")}</h2>
                                                                <h5>{`Sua busca por "${filterCategory}" não retornou resultados.`}</h5>
                                                            </div>
                                                            : hasNoResult && filterAcquisitionDate ?
                                                                <div className={classes.noParticipantsMessage}>
                                                                    <div >
                                                                        <Dissatisfied className={classes.notFoundIconStyle} />
                                                                    </div>
                                                                    <h2>{t("GENERAL.noResultsFound")}</h2>
                                                                    <h5>{`Sua busca por "${dateToBR(filterAcquisitionDate)}" não retornou resultados.`}</h5>
                                                                </div>
                                                                : hasNoResult && filterWarrantyData &&
                                                                <div className={classes.noParticipantsMessage}>
                                                                    <div >
                                                                        <Dissatisfied className={classes.notFoundIconStyle} />
                                                                    </div>
                                                                    <h2>{t("GENERAL.noResultsFound")}</h2>
                                                                    <h5>{`Sua busca por "${dateToBR(filterWarrantyData)}" não retornou resultados.`}</h5>
                                                                </div>
                        }
                    </Paper>
                </div>
                <Footer />
                <DialogExport open={openExport} setOpen={setOpenExport} data={dataFilter} />
                <AdvancedSearchDialog open={openAdvancedSearch} setOpen={setOpenAdvancedSearch} places={placesArray} apply={advancedSearch} />
            </animated.div>
        )
    );
};
export default Equipment;