import MomentUtils from "@date-io/moment";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from "@material-ui/core/Paper";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import AddCircle from "@material-ui/icons/AddCircle";
import Check from "@material-ui/icons/Check";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useStyles } from "components/layout/CommonStyles";
import Footer from "components/layout/Footer";
import Spinner from "components/layout/spinners/Index";
import ProjectsContext from "context/projects/projectsContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import DashboardNavBar from "../../layout/DashboardNavBar";
import SideBar from "../../layout/sidebar/Sidebar";
import NewQuota from "./NewQuota";
import SubsidyHeading from "./SubsidyHeading";

const tooltipTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#2c3e51"
        }
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                color: "white",
                fontSize: "1em",
                backgroundColor: "red",
                opacity: "0.7"
            }
        }
    }
});

const EditSubsidy = ({ match }) => {
    const classes = useStyles();
    const [t] = useTranslation();
    const requiredField = "GENERAL.requiredField";
    const [mustRedirect, setMustRedirect] = useState(false);
    const [quotas, setQuotas] = useState([]);
    const [toDelete, setToDelete] = useState([]);
    const [projectId, setProjectId] = useState(0);
    const projectsContext = useContext(ProjectsContext);
    const {
        loading,
        subsidy,
        getSubsidy,
        project,
        getProject,
        editSubsidy,
        showRequiredFieldMessage,
        setValueRequiredMessage
    } = projectsContext;
    const [arrayNumbers, setArrayNumbers] = useState([]);
    const [quotasCount, setQuotasCount] = useState(0);
    const [addendumId, setAddendumId] = useState(0);
    const [updateCount, setUpdateCount] = useState(0);
    const [allHeadings, setAllHeadings] = useState([
        { "name": "RH Direto", "value": "", "id": 0 },
        { "name": "RH Indireto", "value": "", "id": 0 },
        { "name": "Equipamentos", "value": "", "id": 0 },
        { "name": "Material de Consumo", "value": "", "id": 0 },
        { "name": "Livros e Periódicos", "value": "", "id": 0 },
        { "name": "Viagens", "value": "", "id": 0 },
        { "name": "Custos Incorridos", "value": "", "id": 0 }
    ]);
    const [totalRubrics, setTotalRubrics] = useState("");

    const transitions = useTransition(loading, null, {
        from: { opacity: 0 },
        enter: {
            opacity: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "calc(100vh - 80px)"
        },
        leave: { opacity: 0 }
    });

    const [state, setState] = useState({
        projectName: "",
        gpf: "",
        seasonId: 0,
        subsidyValue: "",
        subsidyDescription: ""
    });

    const [dates, setDates] = useState({
        subsidyDate: new Date(),
        minDate: new Date(),
        maxDate: new Date()
    });

    const dictMonths = {
        "JANEIRO": 0,
        "FEVEREIRO": 1,
        "MARÇO": 2,
        "ABRIL": 3,
        "MAIO": 4,
        "JUNHO": 5,
        "JULHO": 6,
        "AGOSTO": 7,
        "SETEMBRO": 8,
        "OUTUBRO": 9,
        "NOVEMBRO": 10,
        "DEZEMBRO": 11,
    }

    useEffect(() => {
        getSubsidy(match.params.id);
        if (subsidy.addendum) {
            setAddendumId(subsidy.addendumId);
            getProject(subsidy.addendum.projectId);

            while (quotas.length) {
                quotas.pop();
            }

            if (project.information) {
                setProjectId(project.id);
                let count = 0;
                let list = [];
                let aux = {};

                for (let x in project.sgppdAddendum) {
                    if (
                        project.sgppdAddendum[x].projectBudget[0] &&
                        project.sgppdAddendum[x].projectBudget[0].projectBudgetQuota.length > count
                    ) {
                        count = project.sgppdAddendum[x].projectBudget[0].projectBudgetQuota.length;
                    }
                }

                for (let k = 1; k <= count; k++) {
                    aux.value = k;
                    aux.label = k;
                    list.push(aux);
                    aux = {};
                }

                setArrayNumbers(list);

                let subsidyTotal = subsidy.total
                    .toFixed(2)
                    .split("")
                    .filter(el => el !== "." && el !== ",")
                    .join("");

                let totalValue = converTotBrCurrency(
                    subsidyTotal
                        .split("")
                        .reverse()
                        .join("")
                );

                setState({
                    ...state,
                    projectName: project.information.name,
                    gpf: project.information.gpf,
                    subsidyValue: totalValue,
                    subsidyDescription: subsidy.description
                });

                let cooperationTerm = project.sgppdAddendum.filter(el => el.number !== "GENERAL" &&
                    el.cooperationTerm === true)[0];

                let lastTerm = project.sgppdAddendum.filter(el => el.number !== "GENERAL")[
                    project.sgppdAddendum.length - 2];

                let cooperationTermStart = new Date(cooperationTerm.startdate);
                cooperationTermStart.setMinutes(cooperationTermStart.getMinutes() + cooperationTermStart.getTimezoneOffset())
                //let cooperationTermStart = new Date(cooperationTerm.startdate);

                var subsidyDateFormated = new Date(subsidy.subsidyDate)
                subsidyDateFormated.setMinutes(subsidyDateFormated.getMinutes() + subsidyDateFormated.getTimezoneOffset())

                setDates({
                    ...dates,
                    subsidyDate: subsidyDateFormated,
                    minDate: cooperationTermStart,
                    maxDate: lastTerm.enddate
                });

                let headings = [];
                let auxHead = {};

                for (let j in subsidy.projectSubsidyHeading) {
                    let sabedHeading = subsidy.projectSubsidyHeading[j].headingValue
                        .toFixed(2)
                        .split("")
                        .filter(el => el !== "." && el !== ",")
                        .join("");

                    let valueHead = converTotBrCurrency(
                        sabedHeading
                            .split("")
                            .reverse()
                            .join("")
                    );

                    auxHead.name = subsidy.projectSubsidyHeading[j].headingName;
                    auxHead.value = valueHead;
                    auxHead.id = subsidy.projectSubsidyHeading[j].id;
                    headings.push(auxHead);
                    auxHead = {};
                }

                setAllHeadings(headings);

                for (let i in subsidy.projectSubsidyQuota) {


                    let value = subsidy.projectSubsidyQuota[i].quotaValue
                        .toFixed(2)
                        .split("")
                        .filter(el => el !== "." && el !== ",")
                        .join("");

                    let quotavalue = converTotBrCurrency(
                        value
                            .split("")
                            .reverse()
                            .join("")
                    );
                    let savedQuotaDate = new Date(subsidy.projectSubsidyQuota[i].quotaMonth);
                    savedQuotaDate.setMinutes(savedQuotaDate.getMinutes() + savedQuotaDate.getTimezoneOffset())
                    //let savedQuotaDate = new Date(subsidy.projectSubsidyQuota[i].quotaMonth);
                    let newDate = new Date(savedQuotaDate.getFullYear(), savedQuotaDate.getMonth());
                    let minimuDate = new Date(cooperationTermStart.getFullYear(), cooperationTermStart.getMonth());
                    let maximumDate = lastTerm.enddate;
                    addQuota(
                        true,
                        subsidy.projectSubsidyQuota[i].quotaNumber,
                        quotavalue,
                        newDate,
                        subsidy.projectSubsidyQuota[i].id,
                        minimuDate,
                        maximumDate,
                        list
                    );
                }
            }
        }
    }, [JSON.stringify(project), JSON.stringify(subsidy), subsidy.length]);

    useEffect(() => {
        if (toDelete.length > 0) {
            if (quotas.length > 0) {
                var aux = [];
                var len = quotas.length;

                for (var i = 0; i < len; i++) {
                    if (quotas[i]) {
                        let quotaDateValue = document.getElementById("quotaDate" + quotas[i].key).value;
                        let quotaMonth = dictMonths[quotaDateValue.split(" ")[0].toUpperCase()];
                        let quotaYear = parseInt(quotaDateValue.split(" ")[1]);
                        let quotaDate = new Date(quotaYear, quotaMonth);
                        let quotaNumber = document.getElementById("numberOfQuota" + quotas[i].key).innerText;
                        let quotaValue = document.getElementById("quotaValue" + quotas[i].key).value;
                        let quotaId = aux.id = document.querySelector("#divQuota" + quotas[i].key).dataset.quotaId;
                        var element = {
                            number: parseInt(quotaNumber),
                            value: quotaValue,
                            quotaDate: quotaDate,
                            id: quotaId
                        };

                        aux.push(element);
                    }
                }

                quotas.splice(0, len);
                setQuotasCount(0);

                for (var i = 0; i < aux.length; i++) {
                    addQuota(
                        true,
                        aux[i].number,
                        aux[i].value,
                        aux[i].quotaDate,
                        aux[i].id,
                        dates.minDate,
                        dates.maxDate,
                        arrayNumbers
                    );
                }
            }
        }
        return () => { };
    }, [toDelete.length]);

    const converTotBrCurrency = number => {
        var temp = "";
        var len = number.length;
        var count = 0;

        for (var i = 0; i < len; i++) {
            if (i === 1) {
                var aux = "," + number[i] + temp;
                temp = aux;
            } else {
                if (count === 3) {
                    count = 1;
                    var aux = number[i] + "." + temp;
                    temp = aux;
                } else {
                    if (i > 1) {
                        count += 1;
                    }
                    var aux = number[i] + temp;
                    temp = aux;
                }
            }
        }

        return temp;
    };

    const handleValueChange = event => {
        if (event.target.value !== " " && !event.target.value.includes(" ")) {
            var str = event.target.value.split("");
            var aux = str.filter(el => el !== "." && el !== ",").join("");
            var value = event.target.value;

            if (event.target.value) {
                value = converTotBrCurrency(
                    aux
                        .split("")
                        .reverse()
                        .join("")
                );
            }

            aux = value.split("").filter(el => el !== ".").join("").replace(",", ".")
            if (!isNaN(aux)) {
                setState({
                    ...state,
                    subsidyValue: value
                });
            }
        }
    };

    const handleDateChange = event => {
        //const dateEvent = new Date(event._d.getFullYear(), event._d.getMonth());
        ///const date = dates.minDate > dateEvent ? dates.minDate : dateEvent;
        const date = dates.minDate > event._d ? dates.minDate : event._d;

        setDates({ ...dates, subsidyDate: date });

        for (let i in project.sgppdAddendum) {
            if (project.sgppdAddendum[i].number !== "GENERAL") {
                let greaterStart = event >= new Date(project.sgppdAddendum[i].startdate);
                let lessEnd = event <= new Date(project.sgppdAddendum[i].enddate);

                if (greaterStart && lessEnd)
                    setAddendumId(project.sgppdAddendum[i].id);
            }
        }
    };

    const handleDescriptionChange = event => {
        setState({ ...state, subsidyDescription: event.target.value });
    };

    const deleteQuota = quotaForDelete => {
        var toExclude = parseInt(
            document.querySelector("#divQuota" + quotaForDelete).dataset.quotaId);
        var auxList = quotas;
        var element = auxList.splice(quotaForDelete, 1);

        setQuotas(quotas.filter(item => item !== element));
        setQuotasCount(quotasCount - 1);
        setUpdateCount(updateCount + 1);
        toDelete.push(toExclude);
    };

    const addQuota = (
        updated,
        quotaNumber,
        quotavalue,
        quotaDate,
        quotaId,
        minDate,
        maxDate,
        numbers
    ) => {
        let number = updated === true ? quotaNumber : 0;
        let value = updated === true ? quotavalue : "";
        let date = updated === true ? quotaDate : dates.minDate;
        let id = updated === true ? quotaId : 0;
        let minimumDate = updated === true ? minDate : dates.minDate;
        let maximumDate = updated === true ? maxDate : dates.maxDate;
        let arrayOfNumbers = updated === true ? numbers : arrayNumbers;

        quotas.push(
            <div
                id={"divQuota" + (quotas.length)}
                key={quotas.length}
                className="subsidyQuotas"
                data-quota-id={id}
            >
                <NewQuota
                    opacity={1}
                    deleteQuota={deleteQuota}
                    lastQuota={quotas.length}
                    minDate={minimumDate}
                    maxDate={maximumDate}
                    arrayNumbers={arrayOfNumbers}
                    quotaNumber={number}
                    quotavalue={value}
                    quotaDate={date}
                />
            </div>
        );

        setQuotasCount(quotasCount + 1);
    };

    const getAllQuotas = () => {
        var allQuotas = [];
        let len = document.getElementsByClassName("subsidyQuotas").length;
        for (var i = 0; i < len; i++) {
            var aux = {};
            let quotaDateValue = document.getElementById("quotaDate" + i).value;
            let quotaMonth = dictMonths[quotaDateValue.split(" ")[0].toUpperCase()];
            let quotaYear = parseInt(quotaDateValue.split(" ")[1]);
            let quotaDateSubmit = new Date(quotaYear, quotaMonth);
            aux.quotaNumber = document.getElementById("numberOfQuota" + i).innerText;
            aux.quotaDate = quotaDateSubmit;
            let valueOfQuota = document.getElementById("quotaValue" + i).value;
            aux.quotaValue = valueOfQuota ? parseFloat(
                valueOfQuota.split("")
                    .filter(el => el !== ".")
                    .join("")
                    .replace(",", ".")
            ) : "";
            aux.id = document.querySelector("#divQuota" + i).dataset.quotaId;
            allQuotas.push(aux);
            aux = {};
        }

        return allQuotas;
    };

    const getAllHeadings = () => {
        let allHeadings = [];
        let len = document.getElementsByClassName("subsidyHeadings").length;

        for (var i = 0; i < len; i++) {
            let aux = {};
            let value = document.getElementById("headingValue" + i).value;
            aux.headingName = document.getElementById("headingName" + i).innerText;
            aux.headingValue = value ? parseFloat(
                value.split("")
                    .filter(el => el !== ".")
                    .join("")
                    .replace(",", ".")
            ) : "";
            aux.id = document.querySelector("#divHeading" + i).dataset.headingId;
            allHeadings.push(aux);
        }

        return allHeadings;
    };

    const verifyRequiredFieldIsEmpty = () => {
        let hasEmptyField = false;

        if (state.subsidyValue.length === 0) {
            hasEmptyField = true;
            return hasEmptyField;
        }

        let quotasQuant = document.getElementsByClassName("subsidyQuotas").length;

        for (let i = 0; i < quotasQuant; i++) {
            let hasNoQuotaNumber = document.getElementById("numberOfQuota" + i).innerHTML.length !== 1;
            let hasNoQuotaValue = document.getElementById("quotaValue" + i).value.length === 0;
            let hasNoQuotaDate = document.getElementById("quotaDate" + i).value.length === 0;

            if (hasNoQuotaNumber || hasNoQuotaValue || hasNoQuotaDate) {
                hasEmptyField = true;
                return hasEmptyField;
            }
        }

        let headingsQuant = document.getElementsByClassName("subsidyHeadings").length;

        for (let i = 0; i < headingsQuant; i++) {
            let hasNoValue = document.getElementById("headingValue" + i).value.length === 0;

            if (hasNoValue) {
                hasEmptyField = true;
                return hasEmptyField;
            }
        }

        return hasEmptyField;
    };

    const handleSubmit = () => {
        var hasNoRequiredFieldEmpty = verifyRequiredFieldIsEmpty();

        if (!hasNoRequiredFieldEmpty) {
            setValueRequiredMessage(false);

            var obj = {
                id: 0,
                addendumId: "",
                subsidyTotalValue: 0,
                subsidyDate: "",
                description: "",
                subsidyQuotas: [],
                subsidyHeadings: []
            };

            obj["id"] = match.params.id;
            obj["addendumId"] = addendumId;
            obj["description"] = state.subsidyDescription;
            obj["subsidyDate"] = dates.subsidyDate;
            obj["subsidyQuotas"] = getAllQuotas();
            obj["subsidyHeadings"] = getAllHeadings();
            obj["toDelete"] = toDelete.filter(el => el !== 0);
            obj["subsidyTotalValue"] = state.subsidyValue ? parseFloat(
                state.subsidyValue.split("")
                    .filter(el => el !== ".")
                    .join("")
                    .replace(",", ".")
            ) : null;

            editSubsidy(obj);
            setMustRedirect(true);
        } else setValueRequiredMessage(true);
    };

    const redirect = go => {
        if (go) return <Redirect to={`/execution/projects/${projectId}/edit`} />;
    };

    const clearRequiredMessage = () => {
        setValueRequiredMessage(false);
    };

    function formattedMoney(value) {
        var valuerFormatted = value ? value.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : "0"
        if (valuerFormatted !== "NaN") {
            return valuerFormatted;
        } else {
            return "0";
        }
    }

    const updateFields = () => {
        var len = document.getElementsByClassName("subsidyHeadings").length;
        var total = 0;

        for (var i = 0; i < len; i++) {
            var stageClass = document.getElementsByClassName("subsidyHeadings")[i]
            var value = stageClass.getElementsByTagName("input")[0]
                ? stageClass.getElementsByTagName("input")[0].value
                : null

            if (value) {
                var currencyStr = value
                    .split("")
                    .filter(el => el !== ".")
                    .join("")
                    .replace(",", ".");
                total += parseFloat(currencyStr);
            }
        }

        var totalResult = formattedMoney(total);
        setTotalRubrics(totalResult)
    };

    return transitions.map(({ item, key, props }) =>
        item ? (
            <animated.div key={key} style={props}>
                <Spinner />
            </animated.div>
        ) : (
                <animated.div key={key} style={props}>
                    {redirect(mustRedirect)}
                    <DashboardNavBar />
                    <SideBar />
                    <div className="container">
                        <Breadcrumbs
                            className={classes.breadCrumbs}
                            aria-label="breadcrumb"
                            separator={<NavigateNextIcon fontSize="small" />}
                        >
                            <Typography>{t("PARTICIPANTS.labor")}</Typography>
                            <Link className={classes.link} to="/execution/projects/">
                                {t("PROJECTS.projects")}
                            </Link>
                            <Link
                                className={classes.link}
                                to={`/execution/projects/${projectId}/edit`}
                            >
                                {t("PROJECTS.project")}
                            </Link>
                            <Typography>{t("SUBSIDY.editSubsidy")}</Typography>
                        </Breadcrumbs>
                        <div className="container-header">
                            <div className="title">
                                <h1 className={classes.title}>{t("SUBSIDY.editSubsidy")}</h1>
                                <Typography>
                                    <span className={classes.projectInformation}>GPF: </span>
                                    <span className={classes.projectInformation2}>{`${state.gpf}`}</span>
                                    <span className={classes.projectInformation}> | PROJETO: </span>
                                    <span className={classes.projectInformation2}>{`${state.projectName}`}</span>
                                </Typography>
                            </div>
                            <Button
                                className={classes.blueButton}
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                            >
                                <div className={classes.buttonIcon}>
                                    <Check />
                                </div>
                                {t("SUBSIDY.updateSubsidy").toUpperCase()}
                            </Button>
                        </div>
                        <Paper className={classes.root}>
                            <div className={classes.header}>
                                {t("SUBSIDY.subsidyInformation").toUpperCase()}
                            </div>
                            <div className={classes.bodyForm}>
                                <Grid container>
                                    <Grid container item spacing={2} md={12} lg={12}>
                                        <Grid item xs={6}>
                                            <MuiThemeProvider theme={tooltipTheme}>
                                                <Tooltip
                                                    TransitionComponent={Zoom}
                                                    open={state.subsidyValue.length === 0 && showRequiredFieldMessage}
                                                    title={t(requiredField)}
                                                >
                                                    <TextField
                                                        id="subsidyValue"
                                                        label={t("SUBSIDY.subsidyTotalValue")}
                                                        className={classes.textField}
                                                        margin="normal"
                                                        name="subsidyValue"
                                                        value={state.subsidyValue}
                                                        onChange={handleValueChange}
                                                        onFocus={clearRequiredMessage}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">R$</InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </Tooltip>
                                            </MuiThemeProvider>
                                        </Grid>
                                        <MuiPickersUtilsProvider locale="pt" utils={MomentUtils}>
                                            <Grid item xs={6}>
                                                <DatePicker
                                                    fullWidth
                                                    label={t("SUBSIDY.subsidyDate")}
                                                    id="subsidyDate"
                                                    variant="inline"
                                                    //openTo="year"
                                                    name="yearBase"
                                                    //  views={["year", "month"]}
                                                    format="DD/MM/YYYY"
                                                    margin="normal"
                                                    autoOk={true}
                                                    value={dates.subsidyDate}
                                                    onChange={handleDateChange}
                                                    onFocus={clearRequiredMessage}
                                                    minDate={dates.minDate}
                                                    maxDate={dates.maxDate}
                                                />
                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className={classes.bodyForm}>
                                <Grid container>
                                    <Grid container item spacing={2} md={12} lg={12}>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="subsidyDescription"
                                                multiline
                                                fullWidth
                                                variant="outlined"
                                                rows="6"
                                                label={t("PROFILES.descriptionLabel")}
                                                margin="normal"
                                                value={state.subsidyDescription}
                                                onChange={handleDescriptionChange}
                                                onFocus={clearRequiredMessage}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                        <Paper className={classes.root} style={{ marginTop: "22px" }}>
                            <div className={classes.header}>
                                {t("SUBSIDY.subsidyQuotas").toUpperCase()}
                            </div>
                            <div className={classes.bodyForm}>
                                <Grid container spacing={2}>
                                    <Grid item xs={4} style={{ fontWeight: "bold", opacity: "0.54" }}>
                                        {t("SUBSIDY.quotaNumber").toUpperCase()}
                                    </Grid>
                                    <Grid item xs={4} style={{ fontWeight: "bold", opacity: "0.54" }}>
                                        {t("BUDGET.balanceValue").toUpperCase()}
                                    </Grid>
                                    <Grid item xs={3} style={{ fontWeight: "bold", opacity: "0.54" }}>
                                        {t("SUBSIDY.quotaMonth").toUpperCase()}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={1}
                                        style={{ marginTop: "-12px", marginBottom: "-15px" }}
                                    >
                                        <Tooltip
                                            title="Adicionar Parcela"
                                            aria-label="adicionar"
                                            placement="top"
                                        >
                                            <IconButton onClick={addQuota} style={{ float: "right" }}>
                                                <AddCircle />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <div className="lineBreak"></div>
                                </Grid>
                            </div>
                            <div>{quotas}</div>
                        </Paper>
                        <Paper className={classes.root} style={{ marginTop: "22px" }}>
                            <div className={classes.header}>
                                {t("PROFILES.form.headings").toUpperCase()}
                            </div>
                            <div className={classes.bodyForm}>
                                <Grid container spacing={2}>
                                    <Grid item xs={4} style={{ fontWeight: "bold", opacity: "0.54" }}>
                                        {t("EXPENSES.rubricLabel").toUpperCase()}
                                    </Grid>
                                    <Grid item xs={4} style={{ fontWeight: "bold", opacity: "0.54" }}>
                                        {t("GENERAL.value").toUpperCase()}
                                    </Grid>
                                    <div className="lineBreak"></div>
                                </Grid>
                            </div>
                            <div>
                                {
                                    allHeadings.map(function (heading, index) {
                                        return (
                                            <div
                                                key={index}
                                                id={"divHeading" + index}
                                                className="subsidyHeadings"
                                                data-heading-id={heading.id}
                                            >
                                                <SubsidyHeading
                                                    index={index}
                                                    headingName={heading.name}
                                                    headingValue={heading.value}
                                                    updateFields={updateFields}
                                                />
                                            </div>
                                        )
                                    })
                                }
                                <div className={classes.bodyForm}>
                                    <Grid container spacing={2}>
                                        <Grid id={"Total"} item xs={4}>
                                            Total
                                    </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                style={{
                                                    backgroundColor:
                                                        totalRubrics !== state.subsidyValue ? "#f16b68" : "",
                                                    fontWeight: "bold"
                                                }}
                                                id={"tatalValue"}
                                                className={classes.textField}
                                                margin="normal"
                                                name="headingTotalValue"
                                                value={totalRubrics}
                                                disabled
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">R$</InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Grid>
                                        <div className="lineBreak"></div>
                                    </Grid>
                                </div>
                            </div>
                        </Paper>
                    </div>
                    <Footer />
                </animated.div>
            )
    );
};

export default EditSubsidy;