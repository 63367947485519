import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Visibility from '@material-ui/icons/Visibility';
import DialogViewBill from '../../Dialogs/DialogViewBill';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';
import { Screen, Allow } from 'global/constants';
import { screenStyles } from '../../Styles';
import { BillStatus } from '../../ScreenUtils';

const BillsTableActions = props => {
    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }
    const styles = screenStyles();
    const classes = useStyles();
    const [t] = useTranslation();
    const { item } = props;

    const [open, setOpen] = useState(false);

    return (
        <React.Fragment>
            <Tooltip
                title={t('GENERAL.view')}
                aria-label="details"
                placement="top"
            >
                <IconButton
                    className={classes.iconsTableActions}
                    onClick={() => setOpen(true)}
                    disabled={item.status === BillStatus.NO_BILL}
                >
                    <Visibility />
                </IconButton>
            </Tooltip>

            {open && (
                <DialogViewBill
                    open={open}
                    setOpen={setOpen}
                    viewValues={item}
                />
            )}
        </React.Fragment>
    );
};

export default BillsTableActions;
