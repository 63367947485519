/* eslint-disable */
import DateFnsUtils from "@date-io/date-fns";
import AppBar from '@material-ui/core/AppBar';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Attachment from "@material-ui/icons/Attachment";
import Clear from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import axios from "axios";
import Footer from "components/layout/Footer";
import Spinner from "components/layout/spinners/Index";
import InventoryContext from "context/inventory/inventoryContext";
import ProjectsContext from "context/projects/projectsContext";
import UsersContext from "context/users/usersContext";
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import { useStyles } from "components/layout/CommonStyles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { dateToBR } from 'utils/dates/DateToBRr';
import { Events } from "global/constants";
import Cookies from "js-cookie";

const tooltipTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#2c3e51"
        }
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                color: "white",
                fontSize: "1em",
                backgroundColor: "red",
                opacity: "0.7"
            }
        }
    }
});

function TabPanel(props) {
    const { children, value, index } = props;
    return (
        <div>
            {value === index && children}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const NewBook = () => {
    const fileDownload = require("js-file-download");

    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }
    const classes = useStyles();
    const [t] = useTranslation();
    const inventoryContext = useContext(InventoryContext);
    const projectsContext = useContext(ProjectsContext);
    const { panelTab, setValuePanelTab, getProjects, projects } = projectsContext;
    const usersContext = useContext(UsersContext);
    const [hasUpdatedFile, setHasUpdatedFile] = useState(false);
    const [mustRedirect, setMustRedirect] = useState(false);
    const [titleName, setTitle] = useState("GENERAL.requiredField");
    const [titleIsbn, setTitleIsbn] = useState("GENERAL.requiredField");
    const [categoriesArray, setCategoriesArray] = useState([]);
    const [projectsArray, setProjectsArray] = useState([]);
    const [placesArray, setPlacesArray] = useState([]);
    const [usersArray, setUsersArray] = useState([]);
    const [usersLibraryAdmArray, setUsersLibraryAdmArray] = useState([]);
    const [statusArray, setStatusArray] = useState([]);
    const [libraryTitle, setLibraryTitle] = useState([]);
    const [files, setFiles] = useState([]);
    const [toDeleteFile, setToDeleteFile] = useState([]);
    const [amountExemplary, setAmountExemplary] = React.useState(1);
    const [requiredTitle, setRequiredTitle] = useState(false);
    const [requiredCategory, setRequiredCategory] = useState(false);
    const [requiredDate, setRequiredDate] = useState(false);
    const [requiredAuthor, setRequiredAuthor] = useState(false);
    const [requiredEdition, setRequiredEdition] = useState(false);
    const [requiredIsbn, setRequiredIsbd] = useState(false);
    const [requiredCoin, setRequiredCoin] = useState(false);
    const [validIsbn, setValidIsbn] = useState(false);
    const [validIsbnDisabled, setValidIsbnDisabled] = useState(true);
    const [isbnNotFound, setIsbnNotFound] = useState(false);
    const [requiredPlace, setRequiredPlace] = useState(false);
    const [requiredPurchasePrice, setRequiredPurchasePrice] = useState(false);
    const [requiredAdministrativeResponsible, setRequiredAdministrativeResponsible] = useState(false);
    const [requiredLibrary, setRequiredLibrary] = useState(false);
    const [requiredProject, setRequiredProject] = useState(false);
    const [requiredDescription, setRequiredDescription] = useState(false);
    const [createOnlyExemplay, setCreateOnlyExemplay] = useState(false);
    const [titleId, setTitleId] = useState(null);
    const [value, setValue] = React.useState(panelTab);
    const [isbn, setIsbn] = React.useState("");
    const [admsRh, setAdmsRh] = useState([]);
    const [isString, setIsString] = useState(false);
    const [requiredCoinValue, setRequiredCoinValue] = useState(false);

    const {
        getUsers,
        users
    } = usersContext;

    const [values, setValues] = useState({
        id: "",
        image: "",
        isbn: "",
        title: "",
        subTitle: "",
        authors: [""],
        edition: "",
        publisher: "",
        language: "",
        category: "",
        synthesis: "",
        coin: "BRL",
        coinValues: 1,
        acquisitionMethod: "Projects",
        projectId: "",
        freight: "",
        purchasePrice: "",
        totalPurchase: "",
        invoice: "",
        administrativeResponsibleId: "",
        library: "",
        statusId: "",
        status: "",
        placeId: "",
        bookCategoryId: "",
        iof: "",
        observation: "",
        descriptionAcquisitionMethod: ""
    });

    const handleChangePanel = (event, newValue) => {
        const canSubmit = verifyCanNetPage();
        if (canSubmit) {
            setValue(newValue);
            setValuePanelTab(newValue);
        }
        if (newValue === 1) {
            runCoin(values.coin)
        }
    };

    const coins = [
        {
            value: "BRL",
            label: "BRL (Real Brasileiro)"
        },
        {
            value: "USD",
            label: "USD (Dólar Comercial)"
        },
        {
            value: "USDT",
            label: "USDT (Dólar Turismo)"
        },
        {
            value: "CAD",
            label: "CAD (Dólar Canadense)"
        },
        {
            value: "AUD",
            label: "AUD (Dólar Australiano)"
        },
        {
            value: "EUR",
            label: "EUR (Euro)"
        },
        {
            value: "GBP",
            label: "GBP (Libra Esterlina)"
        },
        {
            value: "ARS",
            label: "ARS (Peso Argentino)"
        },
        {
            value: "JPY",
            label: "JPY (Iene Japonês)"
        },
        {
            value: "CHF",
            label: "CHF (Franco Suíço)"
        },
        {
            value: "CNY",
            label: "CNY (Yuan Chinês)"
        },
        {
            value: "BTC",
            label: "BTC (Bitcoin)"
        },
        {
            value: "LTC",
            label: "LTC (Litecoin)"
        },
        {
            value: "ETH",
            label: "ETH (Ethereum)"
        },
        {
            value: "XRP",
            label: "XRP (Ripple)"
        },
    ];

    const status = [
        {
            value: "FREE",
            label: "Disponível"
        },
        {
            value: "INACTIVE",
            label: "Indisponível"
        },
        {
            value: "RENTED",
            label: "Emprestado"
        },
        {
            value: "DONATED",
            label: "Doado"
        },
    ];

    const library = [
        {
            value: "GENERAL",
            label: "Geral"
        },
        {
            value: "COORDINATION",
            label: "Coordenação"
        }
    ];

    const {
        loading,
        getPlaces,
        places,
        bookCategories,
        getBookCategories,
        getAllStatus,
        allStatus,
        addBook,
        getLibraryAdms,
        libraryAdms,
        getIsbnAndTitles,
        isbnAndTitle,
        admsPeopleManagement,
        geAdmsPeopleManagement,
        getAuthors,
        authors,
        getUsersEmailData,
        usersEmail
    } = inventoryContext;

    useEffect(() => {
        getPlaces();
        getBookCategories();
        getProjects();
        getUsers();
        getAllStatus();
        getLibraryAdms();
        getIsbnAndTitles();
        getUsersEmailData(Events.NEW_BOOK_EMAIL);
        geAdmsPeopleManagement();
        getAuthors();
    }, []);

    useEffect(() => {
        setValues({
            ...values,
            edition: libraryTitle.edition,
            title: libraryTitle.title,
            subTitle: libraryTitle.subtitle,
            authors: libraryTitle.authors ? libraryTitle.authors : [""],
            publisher: libraryTitle.publisher,
            language: libraryTitle.language,
            synthesis: libraryTitle.synopsis,
            bookCategoryId: libraryTitle.categoryId,
        });
    }, [JSON.stringify(libraryTitle)]);

    const transitions = useTransition(loading, null, {
        from: { opacity: 0 },
        enter: {
            opacity: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "calc(100vh - 70px)"
        },
        leave: { opacity: 0 }
    });

    const [dataState, setDataState] = useState({
        files: [],
        rejectedFiles: []
    });

    const [dates, setDates] = useState({
        dateAcquisition: null,
    });

    const handleDates = name => e => {
        setDates({
            ...dates,
            [name]: e
        });
    };

    const sortElement = (elemnts) => {
        elemnts.sort(function (a, b) {
            if (a.name === null) a.name = "";
            if (b.name === null) b.name = "";

            if (a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() >
                b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim()) {
                return 1;
            }
            if (a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() <
                b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim()) {
                return -1;
            }
            // a must be equal to b
            return 0;
        });
    }

    const sortElementName = (elemnts) => {
        elemnts.sort(function (a, b) {
            if (a.name === null) a.name = "";
            if (b.name === null) b.name = "";

            if (a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() >
                b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim()) {
                return 1;
            }
            if (a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() <
                b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim()) {
                return -1;
            }
            // a must be equal to b
            return 0;
        });
    }

    useEffect(() => {
        if (allStatus) {
            const status = [...allStatus];
            sortElementName(status);

            var aux = {};
            var _status = [];
            for (var i = 0; i < status.length; i++) {
                var item = status[i];
                aux.value = item.id;
                aux.label = item.name;
                _status.push(aux);
                aux = {};
            }

            setStatusArray(_status)
        }
    }, [allStatus]);

    useEffect(() => {
        const myPlaces = [...places];
        sortElementName(myPlaces);

        var aux = {};
        var _places = [];
        for (var i = 0; i < myPlaces.length; i++) {
            var item = myPlaces[i];
            aux.value = item.id;
            aux.label = item.name;
            _places.push(aux);
            aux = {};
        }
        setPlacesArray(_places);
    }, [places]);

    useEffect(() => {
        const allUsers = [...users];
        const LibraryPermission = [...libraryAdms]
        const PeopleManagementPermission = [...admsPeopleManagement]

        sortElementName(allUsers);

        var aux = {};
        var _users = [];
        for (var i = 0; i < allUsers.length; i++) {
            var item = allUsers[i];
            aux.value = item.id;
            aux.label = item.name;
            _users.push(aux);
            aux = {};
        }
        const permissionFilter = LibraryPermission.map(function (elem) {
            return elem.userId
        });
        var _libraryUsers = _users.filter(x => permissionFilter.includes(x.value))

        // get users with profile admin on people management module 
        var _auxAdm = [];
        var _usersAdminRH = [];
        for (var i = 0; i < allUsers.length; i++) {
            var item = allUsers[i];
            var nameFormated = item.name.split(" ")
            _auxAdm.id = item.id;
            _auxAdm.admName = nameFormated[0] + " " + nameFormated[nameFormated.length - 1];
            _auxAdm.email = item.email;
            _usersAdminRH.push(_auxAdm);
            _auxAdm = {};
        }
        const permissionFilterRH = PeopleManagementPermission.map(function (elem) {
            return elem.userId
        });
        var _AdminUsersRh = _usersAdminRH.filter(x => permissionFilterRH.includes(x.id))

        setUsersArray(_users);
        setUsersLibraryAdmArray(_libraryUsers)
        setAdmsRh(_AdminUsersRh);
    }, [users, libraryAdms]);

    useEffect(() => {
        const allProjects = [...projects];
        sortElementName(allProjects);

        var aux = {};
        var _projects = [];
        for (var i = 0; i < allProjects.length; i++) {
            var item = allProjects[i];
            aux.value = item.idInformation;
            aux.label = item.name;
            _projects.push(aux);
            aux = {};
        }
        setProjectsArray(_projects);
    }, [projects]);

    useEffect(() => {
        const categories = [...bookCategories];
        sortElement(categories);

        var aux = {};
        var _categories = [];
        for (var i = 0; i < categories.length; i++) {
            var item = categories[i];
            aux.value = item.id;
            aux.label = item.name;
            _categories.push(aux);
            aux = {};
        }
        setCategoriesArray(_categories);
    }, [bookCategories]);

    const onDrop = acceptedFiles => {
        if (acceptedFiles[0] !== undefined) {
            setHasUpdatedFile(true);
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    };

    function clean_values() {
        setValues({
            ...values,
            image: "",
            title: "",
            subTitle: "",
            authors: [""],
            edition: "",
            publisher: "",
            language: "",
            category: "",
            synthesis: "",
        });
    }

    function runIsbn(valor) {
        if (isNaN(valor)) {
            var titleValues = data.filter(x => valor == (x.title))
            if (titleValues.length === 0) {
                setValidIsbnDisabled(true);
                setTitleIsbn("Título Invalido")
            } else {
                setValidIsbnDisabled(false)
            }
        } else {
            var isbn = valor.replace(/\D/g, "");
            if (isbn !== "") {
                var validaisbn = /^(?:ISBN(?:-1[03])?:? )?(?=[0-9X]{10}$|(?=(?:[0-9]+[- ]){3})[- 0-9X]{13}$|97[89][0-9]{10}$|(?=(?:[0-9]+[- ]){4})[- 0-9]{17}$)(?:97[89][- ]?)?[0-9]{1,5}[- ]?[0-9]+[- ]?[0-9]+[- ]?[0-9X]$/;
                if (validaisbn.test(isbn)) {
                    // Remove non ISBN digits, then split into an array
                    var chars = valor.replace(/[- ]|^ISBN(?:-1[03])?:?/g, "").split("");
                    // Remove the final ISBN digit from `chars`, and assign it to `last`
                    var last = chars.pop();
                    var sum = 0;
                    var check, i;

                    if (chars.length == 9) {
                        // Compute the ISBN-10 check digit
                        chars.reverse();
                        for (i = 0; i < chars.length; i++) {
                            sum += (i + 2) * parseInt(chars[i], 10);
                        }
                        check = 11 - (sum % 11);
                        if (check == 10) {
                            check = "X";
                        } else if (check == 11) {
                            check = "0";
                        }
                    } else {
                        // Compute the ISBN-13 check digit
                        for (i = 0; i < chars.length; i++) {
                            sum += (i % 2 * 2 + 1) * parseInt(chars[i], 10);
                        }
                        check = 10 - (sum % 10);
                        if (check == 10) {
                            check = "0";
                        }
                    }
                    if (check == last) {
                        //alert("Valid ISBN");
                        axios.get(`https://www.googleapis.com/books/v1/volumes?q=isbn:${isbn}`, {
                            headers: {
                                "Content-type": "application/json",
                                "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
                            }
                        }).then(res => {
                            var response = res.data.items ? res.data.items[0] : "";
                            if (res.data.totalItems !== 0) {
                                setValues({
                                    ...values,
                                    image: response.volumeInfo.imageLinks ? response.volumeInfo.imageLinks.smallThumbnail : "",
                                    title: response.volumeInfo && response.volumeInfo.title,
                                    subTitle: response.volumeInfo && response.volumeInfo.subtitle,
                                    authors: (response.volumeInfo && response.volumeInfo.authors && response.volumeInfo.authors.length) ? response.volumeInfo.authors : [""],
                                    publisher: response.volumeInfo && response.volumeInfo.publisher,
                                    language: response.volumeInfo && response.volumeInfo.language,
                                    synthesis: response.volumeInfo && response.volumeInfo.description,
                                });

                                response.volumeInfo && response.volumeInfo.authors && setRequiredAuthor(false);
                                response.volumeInfo && response.volumeInfo.title && setRequiredTitle(false);
                            } else {
                                setIsbnNotFound(true);
                            }
                            setHasUpdatedFile(false);
                            setValidIsbn(false);
                            setValidIsbnDisabled(false)
                        });
                    } else {
                        setValidIsbn(true);
                        setValidIsbnDisabled(true);
                        setTitleIsbn("ISBN Invalido")
                        setIsbnNotFound(false);
                        clean_values();
                    }
                } else {
                    setValidIsbn(true);
                    setValidIsbnDisabled(true);
                    setTitleIsbn("ISBN Invalido")
                    setIsbnNotFound(false);
                    clean_values();
                }
            } else {
                setValidIsbn(true);
                setTitleIsbn("GENERAL.requiredField")
            }
        }
    }

    useEffect(() => {
        runCoin(values.coin)
    }, [JSON.stringify(dates.dateAcquisition)]);

    const convertDatebyCoin = date => {
        var year = date.getFullYear();
        var month = addZero(date.getMonth() + 1);
        var day = addZero(date.getDate());
        return `${year}${month}${day}`;
    };

    function runCoin(valor) {
        var coin = valor;
        if (coin !== "") {
            var date = convertDatebyCoin(new Date(dates.dateAcquisition));
            axios.get(`https://economia.awesomeapi.com.br/${coin}?start_date=${date}&end_date=${date}`, {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
                }
            }).then(res => {
                if (res.data) {
                    var response = res.data && res.data[0];
                    var coinValueFormated = response && String(parseFloat(response.bid).toFixed(2));
                    setValues({
                        ...values,
                        coinValues: coinValueFormated ? coinValueFormated : "",
                        coin: coin
                    });
                } else {
                }
            });
        }
    }

    const onRemoveFileDocs = (data) => {
        if (data) {
            let remainingFiles = [];
            let updatedList = [];
            for (const file of dataState.files) {
                if ((file.path === data.path) && file.lastModified === data.lastModified && file.size === data.size) {
                    if (file.id) {
                        setToDeleteFile([...toDeleteFile, file.id]);
                        updatedList = dataState.rejectedFiles.filter(el => el !== `Arquivo já existente - ${data.fileName}`);
                    }
                    continue;
                }
                remainingFiles.push(file)
            }

            setDataState({ ...dataState, files: remainingFiles, rejectedFiles: updatedList })
        }
    };

    const image = files.map(file => (
        <div className={classes.thumb} key={file.name}>
            <div className={classes.thumbInner}>
                <img
                    src={file.preview}
                    className={classes.imgNewEquipment}
                />
            </div>
        </div>
    ));

    const handleChange = (name, value) => {
        if (name === "coin") {
            if (value === "BRL") {
                setValues({ ...values, [name]: value, coinValues: 1 });
            } else {
                setValues({ ...values, [name]: runCoin(value) });
            }
        }
        else if (name === "coinValues" || name === "purchasePrice" || name === "freight" || name === "invoice" || name === "iof") {
            if (!isNaN(value)) {
                setValues({ ...values, [name]: value });
            }
        }
        else if (name === "edition") {
            if (!isNaN(value)) {
                setValues({ ...values, [name]: value });
            }
        }
        else {
            setValues({ ...values, [name]: value });
        }
    };

    const handleChangeAuthors = (indexField, newValue) => {
        const author = newValue ? newValue : "";
        if (author.length < 50) {
            const newAuthors = [...values.authors];
            newAuthors[indexField] = author;
            setValues({ ...values, authors: newAuthors });
        }
        setRequiredAuthor(false);
    }

    const addRowAuthor = () => {
        const newAuthors = [...values.authors];
        newAuthors.push("");
        setValues({ ...values, authors: newAuthors });
    }

    const removeRowAuthor = (indexField) => () => {
        const newAuthors = [...values.authors];
        const newAuthorsFilter = newAuthors.filter((x, i) => i !== indexField);
        setValues({ ...values, authors: newAuthorsFilter });
    }

    const verifyCanNetPage = () => {
        let res = true;
        if (!createOnlyExemplay) {
            if (!values.title) {
                res = false;
                setRequiredTitle(true);
            }
            if (!isbn) {
                res = false;
                setValidIsbn(true);
                setTitleIsbn("GENERAL.requiredField");
            }
            if (values.authors.some(x => !x)) {
                res = false;
                setRequiredAuthor(true);
            }
            if (!values.edition) {
                res = false;
                setRequiredEdition(true);
            }
            if (!values.bookCategoryId) {
                res = false;
                setRequiredCategory(true);
            }
        }
        return res;
    };

    const verifyCanSubmit = () => {
        let res = true;
        if (createOnlyExemplay) {
            if (dates.dateAcquisition === null) {
                res = false;
                setRequiredDate(true);
            }
            if (!values.placeId) {
                res = false;
                setRequiredPlace(true);
            }
            if (!values.purchasePrice) {
                res = false;
                setRequiredPurchasePrice(true);
            }

            if (!values.coinValues) {
                res = false;
                setRequiredCoinValue(true);
            }
            if (!values.administrativeResponsibleId) {
                res = false;
                setRequiredAdministrativeResponsible(true);
            }
            if (!values.library) {
                res = false;
                setRequiredLibrary(true);
            }
            if (values.acquisitionMethod == "Projects") {
                if (!values.projectId) {
                    res = false;
                    setRequiredProject(true);
                }
            }
        } else {
            if (!values.title) {
                res = false;
                setRequiredTitle(true);
            }
            if (!isbn) {
                res = false;
                setRequiredIsbd(true);
            }
            if (values.authors.some(x => !x)) {
                res = false;
                setRequiredAuthor(true);
            }
            if (!values.edition) {
                res = false;
                setRequiredEdition(true);
            }
            if (!values.bookCategoryId) {
                res = false;
                setRequiredCategory(true);
            }
            if (dates.dateAcquisition === null) {
                res = false;
                setRequiredDate(true);
            }
            if (!values.placeId) {
                res = false;
                setRequiredPlace(true);
            }
            if (!values.purchasePrice) {
                res = false;
                setRequiredPurchasePrice(true);
            }
            if (!values.administrativeResponsibleId) {
                res = false;
                setRequiredAdministrativeResponsible(true);
            }
            if (!values.library) {
                res = false;
                setRequiredLibrary(true);
            }
            if (values.acquisitionMethod == "Projects") {
                if (!values.projectId) {
                    res = false;
                    setRequiredProject(true);
                }
            } else {
                if (!values.descriptionAcquisitionMethod) {
                    res = false;
                    setRequiredDescription(true);
                }
            }
        }

        return res;
    };

    const buttonIsEnabled = () => {
        if (createOnlyExemplay) {
            if (values.acquisitionMethod == "Projects") {
                if (values.coinValues && values.placeId && values.library && values.administrativeResponsibleId
                    && values.status && values.purchasePrice && dates.dateAcquisition && values.projectId) {
                    return (
                        <Button
                            className={classes.blueButton}
                            variant="contained"
                            color="primary"
                            onClick={submit}
                        >
                            {t("GENERAL.add")}
                        </Button>
                    )
                } else {
                    return (
                        <Button
                            disabled
                            className={classes.blueButton}
                            variant="contained"
                            color="primary"
                        >
                            {t("GENERAL.add")}
                        </Button>
                    )
                }
            } else {
                if (values.coinValues && values.placeId && values.library && values.administrativeResponsibleId
                    && values.status && values.purchasePrice && dates.dateAcquisition) {

                    return (
                        <Button
                            className={classes.blueButton}
                            variant="contained"
                            color="primary"
                            onClick={submit}
                        >
                            {t("GENERAL.add")}
                        </Button>
                    )
                } else {
                    return (
                        <Button
                            disabled
                            className={classes.blueButton}
                            variant="contained"
                            color="primary"
                        >
                            {t("GENERAL.add")}
                        </Button>
                    )
                }
            }

        }
        else {
            if (values.acquisitionMethod == "Projects") {
                if (values.coinValues && values.title && isbn && values.edition && values.bookCategoryId &&
                    values.authors.every(x => x) && values.placeId && values.library && values.administrativeResponsibleId
                    && values.status && values.purchasePrice && dates.dateAcquisition && values.projectId) {
                    return (
                        <Button
                            className={classes.blueButton}
                            variant="contained"
                            color="primary"
                            onClick={submit}
                        >
                            {t("GENERAL.add")}
                        </Button>
                    )
                } else {
                    return (
                        <Button
                            disabled
                            className={classes.blueButton}
                            variant="contained"
                            color="primary"
                        >
                            {t("GENERAL.add")}
                        </Button>
                    )
                }
            } else {
                if (values.coinValues && values.title && isbn && values.edition && values.bookCategoryId &&
                    values.authors.every(x => x) && values.placeId && values.library && values.administrativeResponsibleId
                    && values.status && values.purchasePrice && dates.dateAcquisition) {

                    return (
                        <Button
                            className={classes.blueButton}
                            variant="contained"
                            color="primary"
                            onClick={submit}
                        >
                            {t("GENERAL.add")}
                        </Button>
                    )
                } else {
                    return (
                        <Button
                            disabled
                            className={classes.blueButton}
                            variant="contained"
                            color="primary"
                        >
                            {t("GENERAL.add")}
                        </Button>
                    )
                }
            }
        }
    }

    const formatDate = (date) => {
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getUTCHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    }

    function addZero(i) {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    }

    const convertDate = date => {
        var year = date.getFullYear();
        var month = date.getMonth();
        var day = date.getDate();
        var hour = addZero(date.getHours());
        var minute = addZero(date.getMinutes());
        return ` ${day}/${month}/${year} - ${hour}:${minute}`;
    };

    const submit = () => {
        const canSubmit = verifyCanSubmit();
        if (canSubmit) {
            setValuePanelTab(0);
            var emailDatas = []
            var auxEmail = {}

            var project = projectsArray.filter(project =>
                values.projectId == project.value
            )
            // Pegar o label apartir do id do projeto.
            const projectLabel = projectsArray.filter(project => project.value === values.projectId).map(filteredProject => filteredProject.label);


            auxEmail.user = usersEmail
            auxEmail.title = values.title
            auxEmail.acquisitionMethod = values.acquisitionMethod === "Projects" ? "Projetos" + "/" + projectLabel : (values.acquisitionMethod === "OwnResources" ? "Recursos Próprios" : "Outros") + "/" + values.descriptionAcquisitionMethod;
            auxEmail.edition = values.edition ? values.edition : "-"
            auxEmail.publisher = values.publisher ? values.publisher : "-"
            auxEmail.date = convertDate(new Date());
            auxEmail.operator = auth.name
            auxEmail.amountTitles = amountExemplary
            auxEmail.urlBatch = process.env.REACT_APP_FRONT + "Books/" + "?&" + values.title + "&" + auxEmail.project;
            emailDatas.push(auxEmail)
            var auxEmail = {}

            if (createOnlyExemplay) {
                let formDataExemplary = new FormData();

                for (let i in dataState.files) {
                    formDataExemplary.append("files", dataState.files[i]);
                }

                for (let index = 0; index < amountExemplary; index++) {
                    formDataExemplary.append(`book.id`, titleId);
                    formDataExemplary.append(`book.Exemplars[${index}].bookTitleId`, titleId);
                    formDataExemplary.append(`book.Exemplars[${index}].status`, values.status);
                    formDataExemplary.append(`book.Exemplars[${index}].iof`, values.iof);
                    formDataExemplary.append(`book.Exemplars[${index}].descriptionAcquisitionMethod`, values.descriptionAcquisitionMethod ? values.descriptionAcquisitionMethod : "");
                    formDataExemplary.append(`book.Exemplars[${index}].currencyquote`, values.coinValues);
                    formDataExemplary.append(`book.Exemplars[${index}].freight`, values.freight);
                    formDataExemplary.append(`book.Exemplars[${index}].purchasePrice`, values.purchasePrice);
                    formDataExemplary.append(`book.Exemplars[${index}].iof`, values.iof);
                    formDataExemplary.append(`book.Exemplars[${index}].totalPurchase`, String((parseFloat(values.coinValues) * parseFloat(values.freight ? values.freight : 0)) + (parseFloat(values.coinValues) * parseFloat(values.purchasePrice))));
                    formDataExemplary.append(`book.Exemplars[${index}].observation`, values.observation);
                    formDataExemplary.append(`book.Exemplars[${index}].acquisitionMethod`, values.acquisitionMethod);
                    // formDataExemplary.append(`book.Exemplars[${index}].purchasedForFine`, values.acquisitionMethod === "Fines" ? true : false);
                    formDataExemplary.append(`book.Exemplars[${index}].administrativeResponsibleId`, values.administrativeResponsibleId);
                    formDataExemplary.append(`book.Exemplars[${index}].library`, values.library);

                    formDataExemplary.append(`book.Exemplars[${index}].item.acquisitionDate`, dates.dateAcquisition && formatDate(dates.dateAcquisition));
                    formDataExemplary.append(`book.Exemplars[${index}].item.categoryId`, 5);
                    formDataExemplary.append(`book.Exemplars[${index}].item.invoice`, values.invoice);
                    formDataExemplary.append(`book.Exemplars[${index}].item.itemStatusId`, 7732);
                    formDataExemplary.append(`book.Exemplars[${index}].item.name`, values.title);
                    formDataExemplary.append(`book.Exemplars[${index}].item.placeId`, values.placeId);
                    formDataExemplary.append(`book.Exemplars[${index}].item.projectId`, values.acquisitionMethod === "Projects" ? values.projectId : values.acquisitionMethod === "Fines" ? 643 : 227);
                    formDataExemplary.append(`book.Exemplars[${index}].item.class`, "library.Book");
                    formDataExemplary.append(`book.Exemplars[${index}].item.bookCategoryId`, values.bookCategoryId);
                    formDataExemplary.append(`book.Exemplars[${index}].item.value`, String((parseFloat(values.coinValues) * parseFloat(values.freight ? values.freight : 0)) + (parseFloat(values.coinValues) * parseFloat(values.purchasePrice))));

                    formDataExemplary.append(`book.Exemplars[${index}].item.inventoryResponsibleHistory[0].operation`, "Responsável administrativo");
                    formDataExemplary.append(`book.Exemplars[${index}].item.inventoryResponsibleHistory[0].userId`, values.administrativeResponsibleId);
                    formDataExemplary.append(`book.Exemplars[${index}].item.inventoryResponsibleHistory[0].executorId`, auth.id);
                    formDataExemplary.append(`book.Exemplars[${index}].item.inventoryResponsibleHistory[0].date`, formatDate(new Date()));
                }

                // Email Change of Owner
                var emailDataAssignment = []
                var auxEmail = {}
                var user = users.filter(user =>
                    values.administrativeResponsibleId === user.id
                )
                auxEmail.type = "BOOK";
                auxEmail.date = dateToBR(new Date());
                auxEmail.operatorName = auth.name;
                auxEmail.user = user.map(user => {
                    return {
                        email: user.email,
                        name: user.name
                    }
                })
                auxEmail.name = auxEmail.user[0].name;

                let newPlace = placesArray.filter(place => values.placeId === place.value);
                let category = categoriesArray.filter(category => values.bookCategoryId === category.value)

                auxEmail.local = [
                    [
                        "-",
                        newPlace[0].label
                    ]
                ]
                auxEmail.itens = [[
                    values.title,
                    category[0].label,
                    values.exemplarCode ? "BIB-" + values.exemplarCode : "-",
                ]]
                if (Object.keys(auxEmail).length !== 0) {
                    emailDataAssignment.push(auxEmail)
                    var auxEmail = {}
                }
                addBook(formDataExemplary, emailDatas, emailDataAssignment);
                setMustRedirect(true);
            } else {
                let formData = new FormData();

                // Adding equipment image
                if (files[0]) {
                    formData.append("image", files[0]);
                } else {
                    if (values.image) {
                        formData.append("imageUrl", values.image);
                    }
                }

                // Adding documents
                for (let i in dataState.files) {
                    formData.append("files", dataState.files[i]);
                }

                // Adding authors
                for (let i in values.authors.filter(x => x)) {
                    const author = values.authors[i];
                    const registeredAuthor = authors.find(x => x.name === author);
                    if (registeredAuthor) {
                        formData.append(`authorsAdd[${i}].id`, registeredAuthor.id);
                    }
                    formData.append(`authorsAdd[${i}].name`, author);
                    formData.append(`authorsAdd[${i}].abbreviations`, author);
                }

                formData.append(`book.title`, values.title);
                formData.append(`book.subTitle`, values.subTitle);
                formData.append(`book.categoryId`, values.bookCategoryId);
                formData.append(`book.isbn`, isbn);
                formData.append(`book.edition`, values.edition);
                formData.append(`book.publisher`, values.publisher);
                formData.append(`book.language`, values.language);
                formData.append(`book.synopsis`, values.synthesis);

                for (let index = 0; index < amountExemplary; index++) {
                    formData.append(`book.Exemplars[${index}].status`, values.status);
                    formData.append(`book.Exemplars[${index}].coin`, values.coin);
                    formData.append(`book.Exemplars[${index}].freight`, values.freight);
                    formData.append(`book.Exemplars[${index}].purchasePrice`, values.purchasePrice);
                    formData.append(`book.Exemplars[${index}].totalPurchase`, String((parseFloat(values.coinValues) * parseFloat(values.freight ? values.freight : 0)) + (parseFloat(values.coinValues) * parseFloat(values.purchasePrice))));
                    formData.append(`book.Exemplars[${index}].currencyquote`, values.coinValues);
                    formData.append(`book.Exemplars[${index}].iof`, values.iof);
                    formData.append(`book.Exemplars[${index}].administrativeResponsibleId`, values.administrativeResponsibleId);
                    formData.append(`book.Exemplars[${index}].acquisitionMethod`, values.acquisitionMethod);
                    formData.append(`book.Exemplars[${index}].descriptionAcquisitionMethod`, values.descriptionAcquisitionMethod ? values.descriptionAcquisitionMethod : "");
                    // formData.append(`book.Exemplars[${index}].purchasedForFine`, values.acquisitionMethod === "Fines" ? true : false);
                    formData.append(`book.Exemplars[${index}].library`, values.library);
                    formData.append(`book.Exemplars[${index}].observation`, values.observation);

                    formData.append(`book.Exemplars[${index}].item.acquisitionDate`, dates.dateAcquisition && formatDate(dates.dateAcquisition));
                    formData.append(`book.Exemplars[${index}].item.categoryId`, 5);
                    formData.append(`book.Exemplars[${index}].item.invoice`, values.invoice);
                    formData.append(`book.Exemplars[${index}].item.itemStatusId`, 7732);
                    formData.append(`book.Exemplars[${index}].item.name`, values.title);
                    formData.append(`book.Exemplars[${index}].item.placeId`, values.placeId);
                    formData.append(`book.Exemplars[${index}].item.projectId`, values.projectId);
                    formData.append(`book.Exemplars[${index}].item.class`, "library.Book");
                    formData.append(`book.Exemplars[${index}].item.bookCategoryId`, values.bookCategoryId);
                    formData.append(`book.Exemplars[${index}].item.value`, String((parseFloat(values.coinValues) * parseFloat(values.freight ? values.freight : 0)) + (parseFloat(values.coinValues) * parseFloat(values.purchasePrice))));

                    formData.append(`book.Exemplars[${index}].item.inventoryResponsibleHistory[0].operation`, "Responsável administrativo");
                    formData.append(`book.Exemplars[${index}].item.inventoryResponsibleHistory[0].userId`, values.administrativeResponsibleId);
                    formData.append(`book.Exemplars[${index}].item.inventoryResponsibleHistory[0].executorId`, auth.id);
                    formData.append(`book.Exemplars[${index}].item.inventoryResponsibleHistory[0].date`, formatDate(new Date()));
                }


                // Email Change of Owner
                var emailDataAssignment = []
                var auxEmail = {}
                var user = users.filter(user =>
                    values.administrativeResponsibleId === user.id
                )
                auxEmail.type = "BOOK";
                auxEmail.date = dateToBR(new Date());
                auxEmail.operatorName = auth.name;
                auxEmail.user = user.map(user => {
                    return {
                        email: user.email,
                        name: user.name
                    }
                })
                auxEmail.name = auxEmail.user[0].name;
                let newPlace = placesArray.filter(place => values.placeId === place.value);
                let category = categoriesArray.filter(category => values.bookCategoryId === category.value)

                auxEmail.local = [
                    [
                        "-",
                        newPlace[0].label
                    ]
                ]
                auxEmail.itens = [[
                    values.title,
                    category[0].label,
                    values.exemplarCode ? "BIB-" + values.exemplarCode : "-",
                ]]
                if (Object.keys(auxEmail).length !== 0) {
                    emailDataAssignment.push(auxEmail)
                    var auxEmail = {}
                }

                addBook(formData, emailDatas, emailDataAssignment);
                setMustRedirect(true);
            }
        }
    };

    const redirect = go => {
        if (go) return <Redirect to={`/Books`} />;
    };

    const handleChangeFindIsbn = name => e => {
        setValues({ ...values, [name]: runIsbn(e.target.value) });
    };

    let data = [];
    data = isbnAndTitle;
    const handleChangeIsbn = (value) => {
        setIsbn(value);
        setValidIsbn(false);
        if (!isNaN(value)) {
            setIsString(false)
            let dataIsbn = data.filter(item => (item.isbn && item.isbn.trim().toLocaleLowerCase()) === value.trim().toLocaleLowerCase());
            if (dataIsbn.length !== 0) {
                setValuePanelTab(1);
                setValue(1)
                runCoin(values.coin)
                setCreateOnlyExemplay(true)
                setTitleId(dataIsbn[0].id)
                setLibraryTitle(dataIsbn[0])
            } else {
                setValuePanelTab(0);
                setValue(0)
                setCreateOnlyExemplay(false)
                clean_values()
                //setValidIsbn(true);
            }
        } else {
            setIsString(true)
            let dataTitle = data.filter(item => (item.title && item.title.trim().toLocaleLowerCase()) == value.trim().toLocaleLowerCase());
            if (dataTitle.length == 1) {
                setValuePanelTab(1);
                setValue(1)
                setCreateOnlyExemplay(true)
                setTitleId(dataTitle[0].id)
                var value = dataTitle[0];
                setValues({
                    ...values,
                    image: value.image,
                    title: value.title,
                    edition: value.edition,
                    authors: value.authors,
                    publisher: value.publisher,
                    subtitle: value.subTitle ? value.subTitle : "-",
                    language: value.language ? value.language : "-",
                    synthesis: value.synthesis ? value.synthesis : "-",
                    bookCategoryId: value.categoryId
                });
            } else {
                setValuePanelTab(0);
                setValue(0)
                setCreateOnlyExemplay(false)
                // setValidIsbn(true);
            }
        }
    };

    const onDropFiles = acceptedFiles => {
        let newFiles = [];
        let existentFiles = [];

        for (let i = 0; i < acceptedFiles.length; i++) {
            let hasTheSameName = dataState.files.filter(el => (el.fileName ? el.fileName.toLowerCase() : el.name.toLowerCase()) ===
                acceptedFiles[i].name.toLowerCase()).length > 0;
            if (hasTheSameName) {
                existentFiles.push(`Arquivo já existente - ${acceptedFiles[i].name}`);
            }
            else {
                newFiles.push(acceptedFiles[i])
            }
        }

        setDataState({
            ...dataState,
            files: dataState.files.concat(newFiles),
            rejectedFiles: dataState.rejectedFiles.concat(existentFiles)
        });
    };

    function humanFileSize(size) {
        let i = Math.floor(Math.log(size) / Math.log(1024));
        return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    };

    const onClickDownload = async (file) => {
        if (dataState && dataState.files.length > 0) {
            if (file.id) {
                const noSlashPath = file.path.replace(/\//g, ";");
                const url = process.env.REACT_APP_SERVER + "api/file/" + noSlashPath;
                axios.get(url, {
                    headers: {
                        "Content-type": "application/json",
                        "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
                    },
                    responseType: "blob"
                }).then(function (res) {
                    const blob = new Blob([res.data], {});
                    const name = file.fileName ? file.fileName : file.name;
                    fileDownload(blob, name);
                });
            } else {
                fileDownload(file, file.name);
            }
        }
    };

    const deleteRejectedFile = rejectedFile => {
        let updatedList = dataState.rejectedFiles.filter(el => el !== rejectedFile);
        setDataState({ ...dataState, rejectedFiles: updatedList });
    };


    const costPerUnit = parseFloat(values.purchasePrice / amountExemplary);

    const totalPurchaseOfBooks = () => {
        if (values.purchasePrice || values.freight) {
            const bookPrice = values.purchasePrice ? parseFloat(values.purchasePrice) : 0;
            const freightPrice = values.freight ? parseFloat(values.freight) : 0;

            const coinValue = values.coinValues ? parseFloat(values.coinValues) : 1;

            return (bookPrice * coinValue + freightPrice * coinValue).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        }
        return '';
    }

    return transitions.map(({ item, key, props }) =>
        item ? (
            <animated.div key={key} style={props}>
                <Spinner />;
            </animated.div>
        ) : (
            <animated.div key={key} style={props}>
                {redirect(mustRedirect)}
                <div className="container">
                    <Breadcrumbs
                        className={classes.breadCrumbs}
                        aria-label="breadcrumb"
                        separator={<NavigateNextIcon fontSize="small" />}
                    >
                        <Typography>{t("INVENTORY.inventory")}</Typography>
                        <Link className={classes.link} to="/Books">
                            <Typography>{t("INVENTORY.books")}</Typography>
                        </Link>
                        <Typography>{t("INVENTORY.addBook")}</Typography>
                    </Breadcrumbs>
                    <div className="container-header">
                        <div className="title">
                            <h1 className={classes.title}>{t("INVENTORY.addBook")}</h1>
                        </div>
                        {buttonIsEnabled()}
                    </div>
                    <Paper >
                        <div className="cabecalho">
                            {t("INVENTORY.BookUData").toUpperCase()}
                        </div>
                        <div className={classes.bodyNewEquipment}>
                            <Grid item xs={12} sm={5}>
                                <MuiThemeProvider theme={tooltipTheme}>
                                    <Tooltip
                                        TransitionComponent={Zoom}
                                        open={validIsbn}
                                        title={t(titleIsbn)}
                                    >
                                        <Autocomplete
                                            style={{ margin: 10 }}
                                            inputValue={isbn}
                                            id="free-solo-dialog-demo"
                                            freeSolo
                                            selectOnFocus
                                            onBlur={handleChangeFindIsbn("isbn")}
                                            id="combo-box-demo"
                                            onClose={() => {
                                                setIsString(false);
                                            }}
                                            options={isbnAndTitle}
                                            getOptionLabel={(option) => option.title}
                                            onInputChange={(event, newValue) => {
                                                if (event) {
                                                    handleChangeIsbn(newValue);
                                                }
                                            }}
                                            closeIcon={true}
                                            open={isString}
                                            renderInput={(params) => <TextField {...params} label={t("INVENTORY.searchForIsbn")} variant="outlined" />}
                                        />
                                    </Tooltip>
                                </MuiThemeProvider>
                                <div style={{ display: !isbnNotFound ? "none" : "" }}>
                                    <Typography>{t("INVENTORY.isbnNotFound")}</Typography>
                                </div>
                            </Grid>
                            <AppBar position="static" style={{ boxShadow: "none", transform: "none" }}>
                                <Tabs style={{ backgroundColor: "#ffffff", color: "black" }} indicatorColor="primary" value={value} onChange={handleChangePanel} >
                                    <Tab style={{ fontWeight: "bold" }} label={t("INVENTORY.dataTitle").toUpperCase()} {...a11yProps(0)} />
                                    <Tab style={{ fontWeight: "bold" }} label={t("INVENTORY.newExemplary").toUpperCase()} {...a11yProps(1)} />
                                </Tabs>
                            </AppBar>
                            <Grid container spacing={3}>
                                <Grid item xs={2} className={classes.backgroundImage}>
                                    <Grid item xs={2}>
                                        <Dropzone
                                            variant="outlined"
                                            component="span"
                                            className={classes.textField}
                                            onDrop={onDrop}
                                            accept="image/jpeg, 
                                                        image/png, 
                                                        image/bmp"
                                        >
                                            {({
                                                getRootProps,
                                                getInputProps,
                                                isDragActive,
                                                isDragReject
                                            }) => (
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    {!isDragActive && (
                                                        <FormControl
                                                            className={classes.customAttachedDocumentTextField}
                                                        >
                                                            {!hasUpdatedFile && values.image === "" ?
                                                                <Grid item xs={2}>
                                                                    <div className={classes.thumb} key={"upload-equipament-image.jpg"}>
                                                                        <div className={classes.thumbInner}>
                                                                            <img className={classes.imgNewEquipment} alt="add-image" src={window.location.origin + '/img/upload-equipament-image.png'} />
                                                                        </div>
                                                                    </div>
                                                                    <div className={classes.attachImgBook}>{t("Carregar imagem")}</div>
                                                                </Grid>
                                                                :
                                                                !hasUpdatedFile && values.image !== "" ?
                                                                    <Grid item xs={2}>

                                                                        <img className={classes.imgNewEquipment} alt="add-image" src={values.image} />
                                                                        <div className={classes.attachImgBook}>{t("Alterar imagem")}</div>
                                                                    </Grid>
                                                                    :
                                                                    <Grid item xs={2}>
                                                                        {image}
                                                                        <div className={classes.attachImgBook}>{t("Alterar imagem")}</div>
                                                                    </Grid>
                                                            }
                                                        </FormControl>
                                                    )}
                                                    {isDragReject && (
                                                        <FormControl
                                                            className={classes.customAttachedDocumentTextField}
                                                        >
                                                            <Input
                                                                id="custom-attached-input-cooperation"
                                                                readOnly={true}
                                                                endAdornment={
                                                                    <InputAdornment
                                                                        style={{ transform: "rotate(270deg)" }}
                                                                        position="end"
                                                                    >
                                                                        <Attachment />
                                                                    </InputAdornment>
                                                                }
                                                                aria-describedby="weight-helper-text"
                                                                inputProps={{
                                                                    "aria-label": "weight"
                                                                }}
                                                                value={t(title)}
                                                            />
                                                        </FormControl>
                                                    )}
                                                </div>
                                            )}
                                        </Dropzone>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={7}>
                                    <div style={{ display: !createOnlyExemplay ? "none" : "" }} className={classes.tipAlreadyTitleCreate} >
                                        <Typography>{t("INVENTORY.titleAlreadyCreate")}</Typography>
                                    </div>
                                    <TabPanel value={value} index={0}>
                                        <Grid item xs={12} sm={12}>
                                            <MuiThemeProvider theme={tooltipTheme}>
                                                <Tooltip
                                                    TransitionComponent={Zoom}
                                                    open={requiredTitle}
                                                    title={t(titleName)}
                                                >
                                                    <TextField
                                                        disabled={validIsbnDisabled}
                                                        className={classes.textField}
                                                        label={t("INVENTORY.title") + "*"}
                                                        value={values.title}
                                                        onChange={(ev) => { if (ev.target.value.length <= 50) handleChange("title", ev.target.value), setRequiredTitle(false) }}
                                                        margin="normal"
                                                    />
                                                </Tooltip>
                                            </MuiThemeProvider>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={10}>
                                                <TextField
                                                    disabled={validIsbnDisabled}
                                                    className={classes.textField}
                                                    label={t("INVENTORY.subTitle")}
                                                    value={values.subTitle}
                                                    onChange={(ev) => { if (ev.target.value.length <= 50) handleChange("subTitle", ev.target.value) }}
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={2}>
                                                <MuiThemeProvider theme={tooltipTheme}>
                                                    <Tooltip
                                                        TransitionComponent={Zoom}
                                                        open={requiredEdition}
                                                        title={t(titleName)}
                                                    >
                                                        <TextField
                                                            disabled={validIsbnDisabled}
                                                            className={classes.textField}
                                                            label={t("INVENTORY.edition") + "*"}
                                                            value={values.edition}
                                                            onChange={(ev) => { if (ev.target.value.length <= 50) handleChange("edition", ev.target.value), setRequiredEdition(false) }}
                                                            margin="normal"
                                                        />
                                                    </Tooltip>
                                                </MuiThemeProvider>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            {values.authors.map((aut, idx) =>
                                                <>
                                                    <Grid item xs={11} sm={11}>
                                                        <MuiThemeProvider theme={tooltipTheme}>
                                                            <Tooltip
                                                                key={idx}
                                                                TransitionComponent={Zoom}
                                                                open={requiredAuthor && !aut}
                                                                title={t(titleName)}
                                                            >
                                                                <Autocomplete
                                                                    id="combo-box-demo"
                                                                    freeSolo
                                                                    options={authors.map(opt => opt.name).filter(opt => opt.includes(aut))}
                                                                    value={aut}
                                                                    onChange={(event, newInputValue) => {
                                                                        handleChangeAuthors(idx, newInputValue);
                                                                    }}
                                                                    onInputChange={(event, newInputValue) => {
                                                                        handleChangeAuthors(idx, newInputValue);
                                                                    }}
                                                                    disabled={validIsbnDisabled}
                                                                    renderInput={(params) =>
                                                                        <TextField
                                                                            {...params}
                                                                            label={t("INVENTORY.BookAuthor") + "*"}
                                                                            margin="normal"
                                                                            className={classes.textField}
                                                                            classes={{
                                                                                root: classes.inputRoot,
                                                                                input: classes.inputInput
                                                                            }}
                                                                        />
                                                                    }
                                                                />
                                                            </Tooltip>
                                                        </MuiThemeProvider>
                                                    </Grid>
                                                    <Grid item xs={1} sm={1}>
                                                        {(values.authors.length > 1) &&
                                                            <Tooltip
                                                                title="Remover autor"
                                                                aria-label="remover"
                                                                placement="top"
                                                            >
                                                                <IconButton
                                                                    style={{ float: "right", marginTop: "20px" }}
                                                                    onClick={removeRowAuthor(idx)}
                                                                    className={classes.icons}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        }
                                                    </Grid>
                                                </>
                                            )}
                                            {!validIsbnDisabled &&
                                                <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <Typography
                                                        className={classes.link}
                                                        onClick={addRowAuthor}
                                                        disabled={validIsbnDisabled}
                                                        style={{ cursor: 'pointer', fontSize: 14 }}
                                                    >
                                                        Adicionar novo Autor
                                                    </Typography>
                                                </Grid>
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                disabled={validIsbnDisabled}
                                                className={classes.textField}
                                                label={t("INVENTORY.publisher")}
                                                value={values.publisher}
                                                onChange={(ev) => { if (ev.target.value.length <= 50) handleChange("publisher", ev.target.value) }}
                                                margin="normal"
                                            />

                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                disabled={validIsbnDisabled}
                                                className={classes.textField}
                                                label={t("INVENTORY.language")}
                                                value={values.language}
                                                onChange={(ev) => { if (ev.target.value.length <= 50) handleChange("language", ev.target.value) }}
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <MuiThemeProvider theme={tooltipTheme}>
                                                <Tooltip
                                                    TransitionComponent={Zoom}
                                                    open={requiredCategory}
                                                    title={t("GENERAL.requiredField")}
                                                >
                                                    <TextField
                                                        disabled={validIsbnDisabled}
                                                        select
                                                        id="client"
                                                        label={t("INVENTORY.category") + "*"}
                                                        name="category"
                                                        value={values.bookCategoryId}
                                                        onChange={(ev) => {
                                                            setRequiredCategory(false)
                                                            handleChange("bookCategoryId", ev.target.value)
                                                        }}
                                                        className={classes.textField}
                                                        margin="normal"
                                                        classes={{
                                                            root: classes.inputRoot,
                                                            input: classes.inputInput
                                                        }}
                                                        inputProps={{ "aria-label": "search" }}
                                                    >
                                                        {categoriesArray.map(category => (
                                                            <MenuItem key={category.value} value={category.value}>
                                                                {category.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Tooltip>
                                            </MuiThemeProvider>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                disabled={validIsbnDisabled}
                                                multiline
                                                fullWidth
                                                rows="6"
                                                variant="outlined"
                                                className={classes.textField}
                                                label={t("INVENTORY.synthesis")}
                                                value={values.synthesis}
                                                margin="normal"
                                                onChange={(ev) => handleChange("synthesis", ev.target.value)}
                                                inputProps={{
                                                    maxLength: 4096
                                                }}
                                            />
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={4}>
                                                <TextField
                                                    inputProps={{ type: 'number', min: "1" }}
                                                    className={classes.textField}
                                                    label={t("INVENTORY.amountExemplary") + "*"}
                                                    value={amountExemplary}
                                                    onChange={(event) => setAmountExemplary(event.target.value)}
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <Grid item xs={12} xs={5}>
                                                    <MuiThemeProvider theme={tooltipTheme}>
                                                        <Tooltip
                                                            TransitionComponent={Zoom}
                                                            open={requiredDate}
                                                            title={t(titleName)}>
                                                            <KeyboardDatePicker
                                                                className={classes.textField}
                                                                disableToolbar
                                                                autoOk={true}
                                                                variant="inline"
                                                                format="dd/MM/yyyy"
                                                                id="dateAcquisition"
                                                                margin="normal"
                                                                invalidDateMessage=""
                                                                label={t("INVENTORY.dateAcquisition") + "*"}
                                                                maxDate={new Date()}
                                                                KeyboardButtonProps={{
                                                                    "aria-label": "change date"
                                                                }}
                                                                value={dates.dateAcquisition}
                                                                onChange={handleDates("dateAcquisition")}
                                                            />
                                                        </Tooltip>
                                                    </MuiThemeProvider>
                                                </Grid>
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={6}>
                                                <MuiThemeProvider theme={tooltipTheme}>
                                                    <Tooltip
                                                        TransitionComponent={Zoom}
                                                        open={requiredCoin}
                                                        title={t("INVENTORY.coin")}
                                                    >
                                                        <TextField
                                                            disabled={validIsbnDisabled}
                                                            select
                                                            id="client"
                                                            label={t("INVENTORY.coin") + "*"}
                                                            name="category"
                                                            value={values.coin}
                                                            onChange={(ev) => {
                                                                handleChange("coin", ev.target.value)
                                                            }}
                                                            className={classes.textField}
                                                            margin="normal"
                                                            classes={{
                                                                root: classes.inputRoot,
                                                                input: classes.inputInput
                                                            }}
                                                            inputProps={{ "aria-label": "search" }}
                                                        >
                                                            {coins.map(coin => (
                                                                <MenuItem key={coin.value} value={coin.value}>
                                                                    {coin.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Tooltip>
                                                </MuiThemeProvider>
                                            </Grid>
                                            {values.coin !== "BRL" &&
                                                <Grid item xs={12} sm={6}>
                                                    <MuiThemeProvider theme={tooltipTheme}>
                                                        <Tooltip
                                                            TransitionComponent={Zoom}
                                                            open={requiredCoinValue}
                                                            title={t(titleName)}
                                                        >
                                                            <TextField
                                                                disabled={validIsbnDisabled}
                                                                readOnly={true}
                                                                className={classes.textField}
                                                                label={t("INVENTORY.currencyQuote")}
                                                                value={values.coinValues}
                                                                onChange={(ev) => { if (ev.target.value.length <= 50) handleChange("coinValues", ev.target.value) }}
                                                                margin="normal"
                                                            />
                                                        </Tooltip>
                                                    </MuiThemeProvider>
                                                </Grid>}
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            {values.coin !== "BRL" && <Typography style={{ opacity: 0.54 }}>{t("INVENTORY.suggestionCoin")}</Typography>}
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={3}>
                                                <TextField
                                                    disabled={validIsbnDisabled}
                                                    className={classes.textField}
                                                    label={t("INVENTORY.purchasePrice") + "(" + values.coin + ")*"}
                                                    value={values.purchasePrice}
                                                    onChange={(ev) => { if (ev.target.value.length <= 13) handleChange("purchasePrice", ev.target.value) }}
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <MuiThemeProvider theme={tooltipTheme}>
                                                    <TextField
                                                        disabled={validIsbnDisabled}
                                                        label={t("INVENTORY.freight") + "(" + values.coin + ")"}
                                                        value={values.freight}
                                                        onChange={(ev) => { if (ev.target.value.length <= 13) handleChange("freight", ev.target.value) }}
                                                        margin="normal"
                                                    />
                                                </MuiThemeProvider>
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <TextField
                                                    className={classes.textField}
                                                    label={t("INVENTORY.iof")}
                                                    value={values.iof}
                                                    onChange={(ev) => { if (ev.target.value.length <= 13) handleChange("iof", ev.target.value) }}
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <MuiThemeProvider theme={tooltipTheme}>
                                                    <Tooltip
                                                        TransitionComponent={Zoom}
                                                        open={requiredEdition}
                                                        title={t(titleName)}
                                                    >
                                                        <TextField
                                                            disabled={validIsbnDisabled}
                                                            className={classes.textField}
                                                            label={t("INVENTORY.totalPurchase") + "*"}
                                                            value={
                                                                totalPurchaseOfBooks()
                                                            }
                                                            onChange={(ev) => { handleChange("totalPurchase", ev.target.value) }}
                                                            margin="normal"
                                                        />
                                                    </Tooltip>
                                                </MuiThemeProvider>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Typography style={{ opacity: 0.54 }}>{t("INVENTORY.hintCalcCoin")}</Typography>
                                            {amountExemplary > 1 ? (
                                                <Typography style={{ opacity: 0.54 }}>
                                                    {t("INVENTORY.costPerUnit")}{values.coin === 'BRL' ? 'R$' : values.coin} {costPerUnit.toFixed(2)}
                                                </Typography>
                                            ) : (
                                                ""
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl component="fieldset" style={{ marginTop: "18px" }}>
                                                <FormLabel component="legend">{t("INVENTORY.acquisitionMethod") + "*"}</FormLabel>
                                                <RadioGroup row aria-label="position"
                                                    name="position"
                                                    defaultValue="top"
                                                    value={values.acquisitionMethod}
                                                    onChange={(ev) => handleChange("acquisitionMethod", ev.target.value)}
                                                >
                                                    <FormControlLabel
                                                        value="Projects"
                                                        control={<Radio color="primary" />}
                                                        label="Projetos"
                                                        labelPlacement="end"
                                                    />
                                                    <FormControlLabel
                                                        value="Fines"
                                                        control={<Radio color="primary" />}
                                                        label="Multas"
                                                        labelPlacement="end"
                                                    />
                                                    <FormControlLabel
                                                        value="Others"
                                                        control={<Radio color="primary" />}
                                                        label="Outros"
                                                        labelPlacement="end"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        {values.acquisitionMethod == "Projects" ?
                                            <Grid item xs={12} sm={12}>
                                                <MuiThemeProvider theme={tooltipTheme}>
                                                    <Tooltip
                                                        TransitionComponent={Zoom}
                                                        open={requiredCoin}
                                                        title={t("INVENTORY.coin")}
                                                    >
                                                        <TextField
                                                            disabled={validIsbnDisabled}
                                                            select
                                                            id="client"
                                                            label={t("INVENTORY.originProject") + "*"}
                                                            name="projects"
                                                            value={values.projectId}
                                                            onChange={(ev) => {
                                                                handleChange("projectId", ev.target.value)
                                                            }}
                                                            className={classes.textField}
                                                            margin="normal"
                                                            classes={{
                                                                root: classes.inputRoot,
                                                                input: classes.inputInput
                                                            }}
                                                            inputProps={{ "aria-label": "search" }}
                                                        >
                                                            {projectsArray.map(project => (
                                                                <MenuItem key={project.value} value={project.value}>
                                                                    {project.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Tooltip>
                                                </MuiThemeProvider>
                                            </Grid>
                                            :
                                            <Grid item xs={12} sm={12}>
                                                <MuiThemeProvider theme={tooltipTheme}>
                                                    <Tooltip
                                                        TransitionComponent={Zoom}
                                                        open={requiredDescription}
                                                        title={t(titleName)}
                                                    >
                                                        <TextField
                                                            className={classes.textField}
                                                            label={t("INVENTORY.description") + "*"}
                                                            value={values.descriptionAcquisitionMethod}
                                                            onChange={(ev) => { if (ev.target.value.length <= 100) handleChange("descriptionAcquisitionMethod", ev.target.value), setRequiredEdition(false) }}
                                                            margin="normal"
                                                        />
                                                    </Tooltip>
                                                </MuiThemeProvider>
                                            </Grid>
                                        }
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                disabled={validIsbnDisabled}
                                                className={classes.textField}
                                                label={t("INVENTORY.invoice")}
                                                value={values.invoice}
                                                onChange={(ev) => { if (ev.target.value.length <= 50) handleChange("invoice", ev.target.value), setRequiredEdition(false) }}
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <MuiThemeProvider theme={tooltipTheme}>
                                                <Tooltip
                                                    TransitionComponent={Zoom}
                                                    open={requiredAdministrativeResponsible}
                                                    title={t(titleName)}
                                                >
                                                    <TextField
                                                        disabled={validIsbnDisabled}
                                                        select
                                                        id="client"
                                                        label={t("INVENTORY.administrativeResponsible") + "*"}
                                                        name="responsible"
                                                        value={values.administrativeResponsibleId}
                                                        onChange={(ev) => {
                                                            handleChange("administrativeResponsibleId", ev.target.value)
                                                        }}
                                                        className={classes.textField}
                                                        margin="normal"
                                                        classes={{
                                                            root: classes.inputRoot,
                                                            input: classes.inputInput
                                                        }}
                                                        inputProps={{ "aria-label": "search" }}
                                                    >
                                                        {usersLibraryAdmArray.map(userAdm => (
                                                            <MenuItem key={userAdm.value} value={userAdm.value}>
                                                                {userAdm.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Tooltip>
                                            </MuiThemeProvider>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <MuiThemeProvider theme={tooltipTheme}>
                                                <Tooltip
                                                    TransitionComponent={Zoom}
                                                    open={requiredLibrary}
                                                    title={t(titleName)}
                                                >
                                                    <TextField
                                                        disabled={validIsbnDisabled}
                                                        select
                                                        label={t("INVENTORY.library") + "*"}
                                                        value={values.library}
                                                        onChange={(ev) => {
                                                            handleChange("library", ev.target.value)
                                                        }}
                                                        className={classes.textField}
                                                        margin="normal"
                                                        classes={{
                                                            root: classes.inputRoot,
                                                            input: classes.inputInput
                                                        }}
                                                        inputProps={{ "aria-label": "search" }}
                                                    >
                                                        {library.map(libraryType => (
                                                            <MenuItem key={libraryType.value} value={libraryType.value}>
                                                                {libraryType.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Tooltip>
                                            </MuiThemeProvider>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={6}>
                                                <MuiThemeProvider theme={tooltipTheme}>
                                                    <Tooltip
                                                        TransitionComponent={Zoom}
                                                        open={requiredCoin}
                                                        title={t("INVENTORY.coin")}
                                                    >
                                                        <TextField
                                                            disabled={validIsbnDisabled}
                                                            select
                                                            id="client"
                                                            label={t("INVENTORY.status") + "*"}
                                                            name="status"
                                                            value={values.status}
                                                            onChange={(ev) => {
                                                                handleChange("status", ev.target.value)
                                                            }}
                                                            className={classes.textField}
                                                            margin="normal"
                                                            classes={{
                                                                root: classes.inputRoot,
                                                                input: classes.inputInput
                                                            }}
                                                            inputProps={{ "aria-label": "search" }}
                                                        >
                                                            {status.map(status => (
                                                                <MenuItem key={status.value} value={status.value}>
                                                                    {status.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Tooltip>
                                                </MuiThemeProvider>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <MuiThemeProvider theme={tooltipTheme}>
                                                    <Tooltip
                                                        TransitionComponent={Zoom}
                                                        open={requiredCoin}
                                                        title={t("INVENTORY.coin")}
                                                    >
                                                        <TextField
                                                            disabled={validIsbnDisabled}
                                                            select
                                                            id="client"
                                                            label={t("INVENTORY.place") + "*"}
                                                            name="projects"
                                                            value={values.placeId}
                                                            onChange={(ev) => {
                                                                handleChange("placeId", ev.target.value)
                                                            }}
                                                            className={classes.textField}
                                                            margin="normal"
                                                            classes={{
                                                                root: classes.inputRoot,
                                                                input: classes.inputInput
                                                            }}
                                                            inputProps={{ "aria-label": "search" }}
                                                        >
                                                            {placesArray.map(place => (
                                                                <MenuItem key={place.value} value={place.value}>
                                                                    {place.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Tooltip>
                                                </MuiThemeProvider>
                                            </Grid>
                                        </Grid>
                                        <Grid container item spacing={1} md={12} lg={12}>
                                            <Grid item xs={12}>
                                                <Dropzone onDrop={onDropFiles}
                                                    accept="application/pdf, 
                                                application/msword, 
                                                application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                                image/jpeg, 
                                                image/png"
                                                >
                                                    {({ getRootProps, getInputProps }) => (
                                                        <section>
                                                            <div {...getRootProps({ className: classes.dropzoneDialog })}>
                                                                <input {...getInputProps()} />
                                                                <AttachFileIcon />
                                                                <Typography>Arraste e solte os documentos para anexá-los ou <u>clique aqui</u></Typography>
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant={"body1"} style={{ fontWeight: "bold" }}>Documentos</Typography>
                                                {dataState.rejectedFiles.length > 0 &&
                                                    <div style={{ paddingBottom: 8 }}>
                                                        {dataState.rejectedFiles.map(message => (
                                                            <div className={classes.dialogFile}>
                                                                <div className={classes.textFile}>
                                                                    <Typography style={{ color: "red" }} className={classes.nameFile}>{message}</Typography>
                                                                </div>
                                                                <div className={classes.iconsFile}>
                                                                    <IconButton onClick={() => deleteRejectedFile(message)}>
                                                                        <Clear />
                                                                    </IconButton>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                }
                                                {dataState.files.length > 0 ?
                                                    <div className={classes.dropzoneDialogFiles}>
                                                        {dataState.files.map(file => (
                                                            <div className={classes.dialogFile}>
                                                                <div className={classes.textFile}>
                                                                    <Typography className={classes.nameFile}>{file.fileName ? file.fileName : file.name}</Typography>
                                                                    <Typography>{`(${humanFileSize(file.size)})`}</Typography>
                                                                </div>
                                                                <div className={classes.iconsFile}>
                                                                    <IconButton onClick={() => onClickDownload(file)}>
                                                                        <GetAppIcon />
                                                                    </IconButton>
                                                                    <IconButton onClick={() => onRemoveFileDocs(file)}>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div> :
                                                    <div className={classes.noFilesMessage}>
                                                        <Typography variant={"h6"} style={{ fontWeight: "bold" }}>{t("EXPENSES.noFilesMessage")}</Typography>
                                                        <Typography variant={"caption"}>{t("EXPENSES.noFilesSubMessage")}</Typography>
                                                    </div>
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                disabled={validIsbnDisabled}
                                                multiline
                                                fullWidth
                                                rows="6"
                                                variant="outlined"
                                                className={classes.textField}
                                                label={t("INVENTORY.observation")}
                                                value={values.observation}
                                                margin="normal"
                                                onChange={(ev) => handleChange("observation", ev.target.value)}
                                                inputProps={{
                                                    maxLength: 250
                                                }}
                                            />
                                        </Grid>
                                    </TabPanel>
                                </Grid>
                            </Grid>
                        </div>
                    </Paper>
                </div>
                <Footer />
            </animated.div>
        )
    );
};

export default NewBook;
