export function formatImagePath(imagePath, isImageUrl) {
    if (isImageUrl) {
        return imagePath;
    }

    var aux = imagePath.replaceAll('/', ';');
    aux = aux.replaceAll('\\', ';');

    return `${process.env.REACT_APP_FILE}${aux}`;
}
