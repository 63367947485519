import React, { useState, useEffect, useContext } from 'react';
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CheckIcon from '@material-ui/icons/Check';
import InputDragDropDocs from "components/layout/inputDragDrop/InputDragDropDocs";
import { Redirect } from "react-router-dom";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import InventoryContext from "context/inventory/inventoryContext";
import Typography from "@material-ui/core/Typography";
import ProjectsContext from "context/projects/projectsContext";
import UsersContext from "context/users/usersContext";
import ExternalUsersContext from "context/externalUsers/externalUsersContext";
import { dateToBR } from 'utils/dates/DateToBRr';
import { FormatDateToDataBase } from "utils/dates/FormatDateToDataBase";
import { Events } from 'global/constants';

const NewLicense = (props) => {
    const fileDownload = require("js-file-download");
    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }
    const [t] = useTranslation();
    const classes = useStyles();
    const inventoryContext = useContext(InventoryContext);
    const { datas, files, changeContext, platforms, fieldsPlatform, addSoftwareAndLicense, context, licenseId, softwareId, softwareCategory, softwareName } = props;
    const [requiredKey, setRequiredKey] = useState(false);
    const [requiredEmail, setRequiredEmail] = useState(false);
    const [requiredOther, setRequiredOther] = useState(false);
    const [requiredStatus, setRequiredStatus] = useState(false);
    const [requiredAdministrativeResponsible, setRequiredAdministrativeResponsible] = useState(false);
    const [requiredProject, setRequiredProject] = useState(false);
    const [requiredCapacity, setRequiredCapacity] = useState(false);
    const [requiredAmount, setRequiredAmount] = useState(false);
    const [requiredLicenseType, setRequiredLicenseType] = useState(false);
    const [requiredAcquisitionDate, setRequiredAcquisitionDate] = useState(false);
    const [requiredReleaseDate, setRequiredReleaseDate] = useState(false);
    const [requiredValidity, setRequiredValidity] = useState(false);
    const [mustRedirect, setMustRedirect] = useState(false);
    const blankField = "GENERAL.requiredField";
    const [messageFieldEmail, setMessageFieldEmail] = useState("GENERAL.requiredField");
    const [usersArrayInternal, setUsersArrayInternal] = useState([]);
    const [usersArrayExternal, setUsersArrayExternal] = useState([]);
    const [usarsAdmInventoryModuleArray, setUsersAdmInventoryModuleArray] = useState([]);
    const [projectsArray, setProjectsArray] = useState([]);
    const [machinesArray, setMachinesArray] = useState([]);
    const [admsRh, setAdmsRh] = useState([]);
    const projectsContext = useContext(ProjectsContext);
    const usersContext = useContext(UsersContext);
    const externalUsersContext = useContext(ExternalUsersContext);
    const { getProjects, projects } = projectsContext;
    const { getUsers, users } = usersContext;
    const { externalUsers, getExternalUsers } = externalUsersContext;
    const [categoriesArray, setCategoriesArray] = useState([]);
    const [toDeleteFile, setToDeleteFile] = useState([]);
    const [buttonHasActivated, setButtonHasActivated] = useState(false);

    const [keyAlreadyExists, setKeyAlreadyExists] = useState('');
    const [codeAlreadyExists, setCodeAlreadyExists] = useState('');

    const {
        getPlaces,
        getSoftwares,
        softwares,
        getSoftwareCategories,
        softwareCategories,
        geAdmsPeopleManagement,
        admsPeopleManagement,
        getLicense,
        license,
        getLibraryAdms,
        libraryAdms,
        getItensbyUsersLicense,
        itensByUser,
        UpdateLicense,
        getUsersEmailData,
        usersEmail
    } = inventoryContext;

    const coins = [
        {
            value: "BRL",
            label: "BRL (Real Brasileiro)"
        },
        {
            value: "USD",
            label: "USD (Dólar Comercial)"
        },
        {
            value: "USDT",
            label: "USDT (Dólar Turismo)"
        },
        {
            value: "CAD",
            label: "CAD (Dólar Canadense)"
        },
        {
            value: "AUD",
            label: "AUD (Dólar Australiano)"
        },
        {
            value: "EUR",
            label: "EUR (Euro)"
        },
        {
            value: "GBP",
            label: "GBP (Libra Esterlina)"
        },
        {
            value: "ARS",
            label: "ARS (Peso Argentino)"
        },
        {
            value: "JPY",
            label: "JPY (Iene Japonês)"
        },
        {
            value: "CHF",
            label: "CHF (Franco Suíço)"
        },
        {
            value: "CNY",
            label: "CNY (Yuan Chinês)"
        },
        {
            value: "BTC",
            label: "BTC (Bitcoin)"
        },
        {
            value: "LTC",
            label: "LTC (Litecoin)"
        },
        {
            value: "ETH",
            label: "ETH (Ethereum)"
        },
        {
            value: "XRP",
            label: "XRP (Ripple)"
        },
    ];

    const [values, setValues] = useState({
        id: "",
        image: "",
        name: "",
        manufacturer: "",
        newPlatforms: [""],
        category: "",
        description: "",
        softwareCategoryId: "",
        accessLink: "",
        //license:
        code: "",
        key: "",
        email: "",
        machineId: "",
        machine: "",
        acquisitionMethodId: "PROJECTS",
        projectId: "",
        capacity: "",
        capacityId: "",
        price: "",
        invoice: "",
        directResponsibleId: "",
        directResponsible: "",
        statusId: "",
        status: "",
        placeId: "",
        licenseDescription: "",
        serialNumber: "",
        version: "",
        activationMode: "KEY",
        amount: "",
        collaboratorType: "",
        collaboratorTypeId: "INTERNAL",
        administrativeResponsibleId: "",
        others: "",
        licenseType: "",
        coin: "BRL",
        isExternalMachine: ""
    });

    const [dates, setDates] = useState({
        acquisitionDate: null,
        releaseDate: null,
        validity: null
    });

    const [dataState, setDataState] = useState({
        files: [],
        rejectedFiles: []
    });

    const handleDates = name => e => {
        setDates({
            ...dates,
            [name]: e
        });
    };

    const modes = [
        {
            value: "KEY",
            label: "Chave"
        },
        {
            value: "EMAIL",
            label: "E-mail"
        }
    ];

    const statusArray = [
        {
            value: "AVAILABLE",
            label: "Disponível"
        },
        {
            value: "UNAVAILABLE",
            label: "Indisponível"
        },
        {
            value: "EXPIRED",
            label: "Expirada"
        },
    ];

    const typeArray = [
        {
            value: "ACQUIRED",
            label: "Adquirida"
        },
        {
            value: "RENEWABLE",
            label: "Renovável"
        },
    ];

    const capacityArray = [
        {
            value: "LIMITED",
            label: "Limitado"
        },
        {
            value: "LIFE",
            label: "Ilimitado"
        },
    ];

    const collaboratorTypeArray = [
        {
            value: "INTERNAL",
            label: "Interno"
        },
        {
            value: "EXTERNAL",
            label: "Externo"
        },
    ]

    const acquisitionMethodArray = [
        {
            value: "PROJECTS",
            label: "Projeto"
        },
        {
            value: "OTHER",
            label: "Outros"
        },
    ]

    useEffect(() => {
        if (context === "newS&L") {
            setValues({
                ...values,
                image: datas.image,
                name: datas.name,
                accessLink: datas.accessLink,
                manufacturer: datas.manufacturer,
                newPlatforms: datas.newPlatforms,
                category: datas.category,
                description: datas.description,
                softwareCategoryId: datas.softwareCategoryId,
            });
        }
        if (context === "newLicenseOnly") {
            setValues({
                ...values,
                name: datas.name,
                category: datas.category,
                softwareCategoryId: datas.softwareCategoryId,
            });
        }
    }, [datas]);

    useEffect(() => {
        if (values.collaboratorTypeId === "INTERNAL") {
            // zero for internal user
            values.directResponsibleId && getItensbyUsersLicense(values.directResponsibleId, 0)
        } else {
            // one for external user
            getItensbyUsersLicense(values.directResponsibleId, 1)
        }
    }, [values.directResponsibleId, values.collaboratorTypeId, JSON.stringify(itensByUser)]);

    useEffect(() => {
        if (context === "view" || context === "edit") {
            getLicense(licenseId);
            getSoftwares();
            setValues({
                ...values,
                id: license.softwareId,
                code: license.codigo ? license.codigo : "",
                key: license.key ? license.key : "",
                email: license.email ? license.email : "",
                machineId: license.itemId ? license.itemId : "",
                machine: license.inventoryItem && license.inventoryItem.name,
                acquisitionMethodId: license.acquisitionMethod ? license.acquisitionMethod : "",
                acquisitionMethod: license.acquisitionMethod === "PROJECTS" ? "Projetos" : "Outros",
                projectId: license.projectId ? license.projectId : "",
                project: license.project ? license.project.name : "",
                capacityId: license.capacity ? license.capacity : "",
                capacity: license.capacity === "LIFE" ? "Ilimitado" : "limitado",
                price: license.price ? license.price : "",
                invoice: license.invoice ? license.invoice : "",
                directResponsibleId: license.responsibleId ?
                    (license.responsibleId) : (license.externalResponsibleId) ? (license.externalResponsibleId) : "",
                directResponsible: license.responsible ? (license.responsible.name) :
                    (license.externalResponsible) ? (license.externalResponsible.name) : '',
                statusId: license.status ? license.status : "",
                status: license.status === "AVAILABLE" ? "Disponível" : license.status === "UNAVAILABLE" ? "Indisponível" : "Expirada",
                placeId: license.placeId ? license.placeId : "",
                licenseDescription: license.description ? license.description : "",
                serialNumber: license.serial ? license.serial : "",
                version: license.version ? license.version : "",
                activationMode: license.key ? "KEY" : "EMAIL",
                activationModeId: license.key ? "Chave" : "Email",
                amount: license.quantity ? license.quantity : "",
                collaboratorType: license.responsibleId ? "Interno" : license.externalResponsibleId ? "Externo" : "Interno",
                collaboratorTypeId: license.responsibleId ? "INTERNAL" : license.externalResponsibleId ? "EXTERNAL" : "INTERNAL",
                licenseTypeView: license.licenseType === "ACQUIRED" ? "Adquirida" : "Renovavel",
                licenseType: license.licenseType ? license.licenseType : "",
                administrativeResponsibleId: license.administrativeResponsibleId ? license.administrativeResponsibleId : "",
                others: license.othersDescription ? license.othersDescription : "",
                coin: license.coin ? license.coin : "BRL",
                isExternalMachine: license.isExternalMachine ? license.isExternalMachine : ""
            });
            setDates({
                ...dates,
                acquisitionDate: license.acquisitionDate ? license.acquisitionDate : null,
                releaseDate: license.activationDate ? license.activationDate : null,
                validity: license.expirationDate ? license.expirationDate : null
            });
            setDataState({
                ...dataState,
                files: license.licensesFiles ? license.licensesFiles : "",
                //  rejectedFiles: dataState.rejectedFiles.concat(existentFiles)
            });

        }
    }, [JSON.stringify(license), Object.keys(license).length]);

    useEffect(() => {
        getPlaces();
        getProjects();
        getUsers();
        geAdmsPeopleManagement();
        getSoftwareCategories();
        getExternalUsers();
        getUsersEmailData(Events.NEW_SOFTWARE_EMAIL)
        getLibraryAdms();
    }, []);

    const sortElementName = (elemnts) => {
        elemnts.sort(function (a, b) {
            if (a.name === null) a.name = "";
            if (b.name === null) b.name = "";

            if (a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() >
                b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim()) {
                return 1;
            }
            if (a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() <
                b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim()) {
                return -1;
            }
            // a must be equal to b
            return 0;
        });
    }

    useEffect(() => {
        const allItens = [...itensByUser];
        sortElementName(allItens);
        var aux = {};
        var _itens = [];
        var auxExternalMachine = {};
        auxExternalMachine.value = "external";
        auxExternalMachine.label = "Máquina externa";
        _itens.push(auxExternalMachine);
        for (var i = 0; i < allItens.length; i++) {
            var item = allItens[i];
            aux.value = item.id;
            aux.label = item.name;
            _itens.push(aux);
            aux = {};
        }
        setMachinesArray(_itens);
    }, [itensByUser]);

    useEffect(() => {
        const InternalUsers = [...users];
        const ExternalUsers = [...externalUsers];
        const InventoryPermission = [...libraryAdms]
        const PeopleManagementPermission = [...admsPeopleManagement]

        sortElementName(InternalUsers);
        // internal users
        var aux = {};
        var _users = [];
        for (var i = 0; i < InternalUsers.length; i++) {
            var item = InternalUsers[i];
            aux.value = item.id;
            aux.label = item.name;
            _users.push(aux);
            aux = {};
        }

        // External users
        var auxExternalUsers = {};
        var _externalUsers = [];
        for (var i = 0; i < ExternalUsers.length; i++) {
            var item = ExternalUsers[i];
            auxExternalUsers.value = item.id;
            auxExternalUsers.label = item.name;
            _externalUsers.push(auxExternalUsers);
            auxExternalUsers = {};
        }

        // get users with profile admin on people management module 
        var _auxAdm = [];
        var _usersAdminRH = [];
        for (var i = 0; i < InternalUsers.length; i++) {
            var item = InternalUsers[i];
            var nameFormated = item.name.split(" ")
            _auxAdm.id = item.id;
            _auxAdm.admName = nameFormated[0] + " " + nameFormated[nameFormated.length - 1];
            _auxAdm.email = item.email;
            _usersAdminRH.push(_auxAdm);
            _auxAdm = {};
        }
        const permissionFilterRH = PeopleManagementPermission.map(function (elem) {
            return elem.userId
        });
        var _AdminUsersRh = _usersAdminRH.filter(x => permissionFilterRH.includes(x.id))

        const permissionFilter = InventoryPermission.map(function (elem) {
            return elem.userId
        });
        var _AdmsInventory = _users.filter(x => permissionFilter.includes(x.value))

        setUsersArrayInternal(_users);
        setUsersArrayExternal(_externalUsers);
        setUsersAdmInventoryModuleArray(_AdmsInventory)
        setAdmsRh(_AdminUsersRh);
    }, [users]);

    useEffect(() => {
        const allProjects = [...projects];
        sortElementName(allProjects);

        var aux = {};
        var _projects = [];
        for (var i = 0; i < allProjects.length; i++) {
            var item = allProjects[i];
            aux.value = item.idInformation;
            aux.label = item.name;
            _projects.push(aux);
            aux = {};
        }
        setProjectsArray(_projects);
    }, [projects]);

    const handleChange = (name, value) => {
        if (name === "amount" || name === "invoice" || name === "price") {
            if (!isNaN(value)) {
                setValues({ ...values, [name]: value });
            }
        } else if (name === "key") {
            setValues({ ...values, [name]: value });

            let nameExists = softwares.filter(item => item.inventoryLicense.some(obj => obj.key === value));

            if (nameExists.length !== 0) {
                setKeyAlreadyExists("Chave já em uso");
            } else {
                setKeyAlreadyExists("");
            }

        } else if (name === "code") {
            setValues({ ...values, [name]: value })

            let nameExists = softwares.filter(item => item.inventoryLicense.some(obj => obj.codigo === value));

            if (nameExists.length !== 0) {
                setCodeAlreadyExists("Código já em uso");
            } else {
                setCodeAlreadyExists("");
            }
        } else setValues({ ...values, [name]: value });
    };

    const onDropFiles = acceptedFiles => {
        let newFiles = [];
        let existentFiles = [];
        for (let i = 0; i < acceptedFiles.length; i++) {
            let hasTheSameName = dataState.files.filter(el => (el.fileName ? el.fileName.toLowerCase() : el.name.toLowerCase()) ===
                acceptedFiles[i].name.toLowerCase()).length > 0;
            if (hasTheSameName) {
                existentFiles.push(`Arquivo já existente - ${acceptedFiles[i].name}`);
            }
            else {
                newFiles.push(acceptedFiles[i])
            }
        }
        setDataState({
            ...dataState,
            files: dataState.files.concat(newFiles),
            rejectedFiles: dataState.rejectedFiles.concat(existentFiles)
        });
    };

    const deleteRejectedFile = rejectedFile => {
        let updatedList = dataState.rejectedFiles.filter(el => el !== rejectedFile);
        setDataState({ ...dataState, rejectedFiles: updatedList });
    };

    const onRemoveFileDocs = (data) => {
        if (data) {
            let remainingFiles = [];
            let updatedList = [];
            for (const file of dataState.files) {
                if ((file.path === data.path) && file.lastModified === data.lastModified && file.size === data.size) {
                    if (file.id) {
                        setToDeleteFile([...toDeleteFile, file.id]);
                        updatedList = dataState.rejectedFiles.filter(el => el !== `Arquivo já existente - ${data.fileName}`);
                    }
                    continue;
                }
                remainingFiles.push(file)
            }
            setDataState({ ...dataState, files: remainingFiles, rejectedFiles: updatedList })
        }
    };

    const redirect = go => {
        if (go) return <Redirect to={`/Software`} />;
    };

    useEffect(() => {
        if (values.statusId && values.administrativeResponsibleId &&
            dates.acquisitionDate && dates.releaseDate && (values.acquisitionMethodId === "PROJECTS" ? values.projectId : values.others)
            && (values.activationMode === "KEY" ? values.key : values.email) && (values.capacityId === "LIMITED" ? values.amount : values.capacityId)
        ) {
            setButtonHasActivated(true)
        } else setButtonHasActivated(false);
    }, [values, dates]);

    const verifyCanSubmit = () => {
        let res = true;
        if (dates.acquisitionDate === null) {
            res = false;
            setRequiredAcquisitionDate(true);
        }
        if (dates.releaseDate === null) {
            res = false;
            setRequiredReleaseDate(true);
        }
        if (values.licenseType === "RENEWABLE") {
            if (!dates.validity) {
                res = false;
                setRequiredValidity(true);
            }
        }

        if (keyAlreadyExists === "Chave já em uso") {
            res = false;
        }

        if (codeAlreadyExists === "Código já em uso") {
            res = false;
        }

        if (values.activationMode === "KEY") {
            if (!values.key) {
                res = false;
                setRequiredKey(true);
            }
        } else {
            if (!values.email) {
                res = false;
                setRequiredEmail(true);
                setMessageFieldEmail("GENERAL.requiredField")
            }
        }
        if (!values.statusId) {
            res = false;
            setRequiredStatus(true);
        }
        if (!values.administrativeResponsibleId) {
            res = false;
            setRequiredAdministrativeResponsible(true);
        }
        if (values.acquisitionMethodId === "PROJECTS") {
            if (!values.projectId) {
                res = false;
                setRequiredProject(true);
            }
        } else {
            if (!values.others) {
                res = false;
                setRequiredOther(true);
            }
        }
        if (!values.capacityId) {
            res = false;
            setRequiredCapacity(true);
        } else {
            if (values.capacityId === "LIMITED") {
                if (!values.amount) {
                    res = false;
                    setRequiredAmount(true);
                }
            }
        }
        if (!values.licenseType) {
            res = false;
            setRequiredLicenseType(true);
        }
        return res;
    };

    const formatDate = (date) => {
        if (date) {
            var newDate = new Date(date)
            return `${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate()} ${newDate.getUTCHours()}:${newDate.getMinutes()}:${newDate.getSeconds()}`;
        }
    }

    function addZero(i) {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    }

    const convertDate = date => {
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        return ` ${day}/${month}/${year}`;
    };

    useEffect(() => {
        const categories = [...softwareCategories];
        sortElementName(categories);

        var aux = {};
        var _categories = [];
        for (var i = 0; i < categories.length; i++) {
            var item = categories[i];
            aux.value = item.id;
            aux.label = item.name;
            _categories.push(aux);
            aux = {};
        }
        setCategoriesArray(_categories);
    }, [softwareCategories]);

    const submit = () => {
        const canSubmit = verifyCanSubmit();
        if (canSubmit) {
            var emailDatas = []
            var auxEmail = {}
            let formData = new FormData();

            // Get the Label based on the ids of the values
            const licenseTypeLabel = typeArray.filter(type => type.value === values.licenseType).map(filteredType => filteredType.label);
            const categoryLabel = categoriesArray.filter(category => category.value === values.softwareCategoryId).map(filteredCategory => filteredCategory.label);
            const projectLabel = projectsArray.filter(project => project.value === values.projectId).map(filteredProject => filteredProject.label);
            const acquisitionMethodLabel = acquisitionMethodArray.filter(acquisition => acquisition.value === values.acquisitionMethodId).map(filteredAcquisition => filteredAcquisition.label);

            auxEmail.user = usersEmail;
            auxEmail.nameSoftware = context === "newLicenseOnly" ? softwareName : values.name;
            auxEmail.category = context === "newLicenseOnly" ? softwareCategory : categoryLabel[0];
            auxEmail.licenseType = licenseTypeLabel[0];
            auxEmail.expirationDate = dates.validity ? convertDate(new Date(dates.validity)) : '-';
            auxEmail.acquisitionMethod = acquisitionMethodLabel + '/' + (acquisitionMethodLabel[0] === "Outros" ? values.others : projectLabel);
            auxEmail.operator = auth.name;
            auxEmail.date = convertDate(new Date());

            if (context !== "newLicenseOnly") auxEmail.url = process.env.REACT_APP_FRONT + 'Software' + '?&' + values.name;

            emailDatas.push(auxEmail)
            var auxEmail = {}

            // Adding documents
            for (let i in dataState.files) {
                formData.append("files", dataState.files[i]);
            }
            // remove documents
            if (toDeleteFile.length > 0) {
                formData.append("filesToDelete", toDeleteFile);
                setToDeleteFile([]);
            }

            if (context === "newS&L") {
                // Adding equipment image
                if (files[0]) {
                    formData.append("image", files[0]);
                }
                // Adding platform
                const registeredPlatform = fieldsPlatform.filter(x => x.checked === true);
                for (let i in registeredPlatform) {
                    const platform = registeredPlatform[i];
                    formData.append(`platformAdd[${i}].id`, platform.id);
                    formData.append(`platformAdd[${i}].name`, platform.label);
                }

                if (values.newPlatforms.length > 0) {
                    var count = registeredPlatform.length > 0 ? registeredPlatform.length : 0
                    for (let i in values.newPlatforms.filter(x => x)) {
                        const platform = values.newPlatforms[i];
                        formData.append(`platformAdd[${count}].name`, platform);
                    }
                }
            }

            if (context === "newLicenseOnly") {
                formData.append(`softwares.id`, softwareId);
                formData.append(`softwares.inventoryLicense[0].softwareId`, softwareId);
            }

            formData.append(`softwares.name`, values.name);
            formData.append(`softwares.manufacturer`, values.manufacturer);
            formData.append(`softwares.softwareCategoryId`, values.softwareCategoryId);
            formData.append(`softwares.description`, values.description);
            formData.append(`softwares.url`, values.accessLink);
            formData.append(`softwares.type`, "-");
            formData.append(`softwares.versionName`, "-");
            formData.append(`softwares.version`, 0);
            formData.append(`softwares.inventoryLicense[0].type`, "-");
            formData.append(`softwares.inventoryLicense[0].key`, values.activationMode === "KEY" ? values.key : "");
            formData.append(`softwares.inventoryLicense[0].email`, values.activationMode !== "KEY" ? values.email : "");
            formData.append(`softwares.inventoryLicense[0].invoice`, values.invoice);
            formData.append(`softwares.inventoryLicense[0].codigo`, values.code);
            formData.append(`softwares.inventoryLicense[0].itemId`, values.machineId === "external" ? "" : values.machineId);
            formData.append(`softwares.inventoryLicense[0].isExternalMachine`, values.machineId === "external" ? true : false);
            formData.append(`softwares.inventoryLicense[0].version`, values.version);
            formData.append(`softwares.inventoryLicense[0].serial`, values.serialNumber);
            formData.append(`softwares.inventoryLicense[0].status`, values.statusId);
            formData.append(`softwares.inventoryLicense[0].capacity`, values.capacityId);
            formData.append(`softwares.inventoryLicense[0].acquisitionDate`, dates.acquisitionDate && formatDate(dates.acquisitionDate));
            formData.append(`softwares.inventoryLicense[0].activationDate`, dates.releaseDate && formatDate(dates.releaseDate));
            formData.append(`softwares.inventoryLicense[0].expirationDate`, dates.validity ? formatDate(dates.validity) : '');
            formData.append(`softwares.inventoryLicense[0].quantity`, values.amount);
            formData.append(`softwares.inventoryLicense[0].licenseType`, values.licenseType);
            formData.append(`softwares.inventoryLicense[0].price`, values.price);
            formData.append(`softwares.inventoryLicense[0].coin`, values.coin);
            formData.append(`softwares.inventoryLicense[0].description`, values.licenseDescription);
            formData.append(`softwares.inventoryLicense[0].projectId`, values.projectId);
            formData.append(`softwares.inventoryLicense[0].acquisitionMethod`, values.acquisitionMethodId);
            formData.append(`softwares.inventoryLicense[0].othersDescription`, values.others)
            formData.append(`softwares.inventoryLicense[0].administrativeResponsibleId`, values.administrativeResponsibleId);

            if (values.collaboratorTypeId === "INTERNAL") {
                formData.append(`softwares.inventoryLicense[0].responsibleId`, values.directResponsibleId);
            } else formData.append(`softwares.inventoryLicense[0].externalResponsibleId`, values.directResponsibleId);

            var emailDataAssignment = []
            auxEmail = {}

            if (context === "edit") {
                formData.append(`softwares.id`, values.id);
                formData.append(`softwares.inventoryLicense[0].id`, licenseId);
                formData.append(`softwares.inventoryLicense[0].softwareId`, values.id);

                // Email Change of Owner
                const licenseResponsible = license.responsibleId ?
                    (license.responsibleId) : (license.externalResponsibleId) ? (license.externalResponsibleId) : "";
                if (licenseResponsible !== values.directResponsibleId) {
                    var user = users.filter(user =>
                        values.directResponsibleId === user.id
                    )
                    auxEmail.type = "SOFTWARE";
                    auxEmail.date = dateToBR(new Date());
                    auxEmail.operatorName = auth.name;
                    auxEmail.user = user.map(user => {
                        return {
                            email: user.email,
                            name: user.name
                        }
                    })
                    auxEmail.name = auxEmail.user[0].name;
                    let machine = machinesArray.filter(machine => values.machineId === machine.value);
                    auxEmail.itens = [[
                        license.software.name,
                        values.key ? values.key : values.email,
                        values.code ? values.code : "-",
                        machine.length > 0 ? machine[0].label : "-"
                    ]]
                }
                emailDataAssignment.push(auxEmail)
                auxEmail = {}

                UpdateLicense(formData, "/Software", emailDataAssignment);
            } else {

                // Email Change of Owner
                emailDataAssignment = []
                auxEmail = {}
                if (values.directResponsibleId) {
                    var userNew = users.filter(user =>
                        values.directResponsibleId === user.id
                    )
                    auxEmail.type = "SOFTWARE";
                    auxEmail.date = dateToBR(new Date());
                    auxEmail.operatorName = auth.name;

                    auxEmail.user = userNew.map(user => {
                        return {
                            email: user.email,
                            name: user.name
                        }
                    })
                    auxEmail.name = auxEmail.user[0].name;
                    let machine = machinesArray.filter(machine => values.machineId === machine.value);
                    auxEmail.itens = [[
                        softwareName ? softwareName : values.name,
                        values.key ? values.key : values.email,
                        values.code ? values.code : "-",
                        machine.length > 0 ? machine[0].label : "-"
                    ]]
                }
                if (Object.keys(auxEmail).length !== 0) {
                    emailDataAssignment.push(auxEmail)
                    auxEmail = {}
                }


                addSoftwareAndLicense(formData, emailDatas, context, "/Software", emailDataAssignment);
            }
            // setMustRedirect(true);
        }
    };

    const [directRespSelected, setDirectRespSelected] = useState("");

    const addDirectResponsible = (responsible) => {
        if (responsible) {
            setValues({
                ...values,
                directResponsibleId: responsible
            });
            setDirectRespSelected("")
        }
    }

    function validacaoEmail() {
        var field = values.email;
        var usuario = field && field.substring(0, field.indexOf("@"));
        var dominio = field && field.substring(field.indexOf("@") + 1, field.length);

        if ((usuario.length >= 1) && (dominio.length >= 3) &&
            (usuario.search("@") == -1) && (dominio.search("@") == -1) &&
            (usuario.search(" ") == -1) && (dominio.search(" ") == -1) &&
            (dominio.search(".") != -1) && (dominio.indexOf(".") >= 1) &&
            (dominio.lastIndexOf(".") < dominio.length - 1)) {
            setRequiredEmail(false);
        }
        else {
            setRequiredEmail(true);
            setMessageFieldEmail("INVENTORY.invalidEmail")
        }
    }

    const addAdmResponsible = (responsible) => {
        if (responsible) {
            setValues({
                ...values,
                administrativeResponsibleId: responsible
            });
            setDirectRespSelected("")
        }
    }

    return (
        <React.Fragment>
            {redirect(mustRedirect)}
            <div className={classes.bodyNewLicense}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={8}>
                        <TextField
                            disabled={context === "view"}
                            className={classes.textField}
                            helperText={codeAlreadyExists === 'Código já em uso' ? t(codeAlreadyExists) : ''}
                            label={t("INVENTORY.code")}
                            value={values.code || ""}
                            onChange={(ev) => { if (ev.target.value.length <= 10) { handleChange("code", ev.target.value) } }}
                            margin="normal"
                        />
                        {/* 
                    TEST GENRIC INPUT
                    <Input
                        context={context}
                        isSelect={false}
                        label={"INVENTORY.code"}
                        value={values.code}
                        valueName={"code"}
                        maxLength={10} 
                        handleChange={handleChange}
                        /> */}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            disabled={context === "view"}
                            select={context !== "view"}
                            label={t("INVENTORY.activationMode") + "*"}
                            value={context === "view" ? values.activationModeId || "" : values.activationMode}
                            onChange={(ev) => {
                                handleChange("activationMode", ev.target.value)
                            }}
                            className={classes.textField}
                            margin="normal"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput
                            }}
                            inputProps={{ "aria-label": "search" }}
                        >
                            {modes.map(category => (
                                <MenuItem key={category.value} value={category.value}>
                                    {category.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    {values.activationMode === "KEY" ?
                        <Grid item xs={12} sm={12}>
                            <TextField
                                disabled={context === "view"}
                                error={requiredKey}
                                helperText={keyAlreadyExists !== 'Chave já em uso' ? requiredKey && t(blankField) : t(keyAlreadyExists)}
                                className={classes.textField}
                                label={t("INVENTORY.key")}
                                value={values.key || ""}
                                onChange={(ev) => { if (ev.target.value.length <= 60) handleChange("key", ev.target.value.replace(/\s/g, '')); setRequiredKey(false) }}
                                margin="normal"
                            />
                        </Grid>
                        :
                        <Grid item xs={12} sm={12}>
                            <TextField
                                disabled={context === "view"}
                                error={requiredEmail}
                                helperText={requiredEmail && t(messageFieldEmail)}
                                className={classes.textField}
                                label={t("INVENTORY.email")}
                                value={values.email || ""}
                                onBlur={validacaoEmail}
                                onChange={(ev) => { if (ev.target.value.length <= 60) { handleChange("email", ev.target.value) } setRequiredEmail(false) }}
                                margin="normal"
                            />
                        </Grid>}
                    <Grid item xs={12} sm={12}>
                        <TextField
                            disabled={context === "view"}
                            className={classes.textField}
                            label={t("INVENTORY.version")}
                            value={values.version || ""}
                            onChange={(ev) => { if (ev.target.value.length <= 12) { handleChange("version", ev.target.value) } }}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            disabled={context === "view"}
                            className={classes.textField}
                            label={t("INVENTORY.serialNumber")}
                            value={values.serialNumber || ""}
                            onChange={(ev) => { if (ev.target.value.length <= 70) { handleChange("serialNumber", ev.target.value) } }}
                            margin="normal"
                        />
                    </Grid>
                    <Grid container item spacing={3} md={12} lg={12}>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                select={context !== "view"}
                                disabled={context === "view"}
                                error={requiredStatus}
                                helperText={requiredStatus && t(blankField)}
                                label={t("INVENTORY.status") + "*"}
                                value={context === "view" ? values.status || "" : values.statusId}
                                onChange={(ev) => {
                                    handleChange("statusId", ev.target.value)
                                }}
                                className={classes.textField}
                                margin="normal"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput
                                }}
                                inputProps={{ "aria-label": "search" }}
                            >
                                {statusArray.map(status => (
                                    <MenuItem key={status.value} value={status.value}>
                                        {status.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                select={context !== "view"}
                                disabled={context === "view"}
                                label={t("INVENTORY.capacity") + "*"}
                                error={requiredCapacity}
                                helperText={requiredCapacity && t(blankField)}
                                value={context === "view" ? values.capacity || "" : values.capacityId}
                                onChange={(ev) => {
                                    handleChange("capacityId", ev.target.value)
                                }}
                                className={classes.textField}
                                margin="normal"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput
                                }}
                                inputProps={{ "aria-label": "search" }}
                            >
                                {capacityArray.map(capacity => (
                                    <MenuItem key={capacity.value} value={capacity.value}>
                                        {capacity.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        {values.capacityId === "LIMITED" &&
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    disabled={context === "view"}
                                    error={requiredAmount}
                                    helperText={requiredAmount && t(blankField)}
                                    className={classes.textField}
                                    label={t("INVENTORY.amount") + "*"}
                                    value={values.amount || ""}
                                    onChange={(ev) => { if (ev.target.value.length <= 5) handleChange("amount", ev.target.value.trim()) }}
                                    margin="normal"
                                />
                            </Grid>}
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            select={context !== "view"}
                            disabled={context === "view"}
                            error={requiredLicenseType}
                            helperText={requiredLicenseType && t(blankField)}
                            label={t("INVENTORY.type") + "*"}
                            value={context === "view" ? values.licenseTypeView || "" : values.licenseType}
                            onChange={(ev) => {
                                handleChange("licenseType", ev.target.value)
                            }}
                            className={classes.textField}
                            margin="normal"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput
                            }}
                            inputProps={{ "aria-label": "search" }}
                        >
                            {typeArray.map(type => (
                                <MenuItem key={type.value} value={type.value}>
                                    {type.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        {values.licenseType === "ACQUIRED" || values.licenseType === "Adquirida" ?
                            <TextField
                                disabled
                                className={classes.textField}
                                label={t("INVENTORY.validity")}
                                value={"Indeterminada"}
                                margin="normal"
                            /> :
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disabled={context === "view"}
                                    error={requiredValidity}
                                    helperText={requiredValidity && t(blankField)}
                                    className={classes.textField}
                                    disableToolbar
                                    autoOk={true}
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    id="validity"
                                    margin="normal"
                                    invalidDateMessage=""
                                    label={t("INVENTORY.validity") + "*"}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date"
                                    }}
                                    value={dates.validity}
                                    onChange={handleDates("validity")}
                                />
                            </MuiPickersUtilsProvider>
                        }
                    </Grid>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid item xs={12} sm={3}>
                            <KeyboardDatePicker
                                disabled={context === "view"}
                                error={requiredAcquisitionDate}
                                helperText={requiredAcquisitionDate && t(blankField)}
                                className={classes.textField}
                                disableToolbar
                                autoOk={true}
                                variant="inline"
                                format="dd/MM/yyyy"
                                id="acquisitionDate"
                                margin="normal"
                                invalidDateMessage=""
                                label={t("INVENTORY.dateAcquisition") + "*"}
                                KeyboardButtonProps={{
                                    "aria-label": "change date"
                                }}
                                value={dates.acquisitionDate}
                                onChange={handleDates("acquisitionDate")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <KeyboardDatePicker
                                disabled={context === "view"}
                                error={requiredReleaseDate}
                                helperText={requiredReleaseDate && t(blankField)}
                                className={classes.textField}
                                disableToolbar
                                autoOk={true}
                                variant="inline"
                                format="dd/MM/yyyy"
                                id="releaseDate"
                                margin="normal"
                                invalidDateMessage=""
                                label={t("INVENTORY.releaseDate") + "*"}
                                KeyboardButtonProps={{
                                    "aria-label": "change date"
                                }}
                                value={dates.releaseDate}
                                onChange={handleDates("releaseDate")}
                            />
                        </Grid>
                    </MuiPickersUtilsProvider>
                    <Grid container item spacing={3} md={12} lg={12}>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                disabled={context === "view"}
                                className={classes.textField}
                                label={t("INVENTORY.invoice")}
                                value={values.invoice || ""}
                                onChange={(ev) => { if (ev.target.value.length <= 50) { handleChange("invoice", ev.target.value) } }}
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                disabled={context === "view"}
                                select//={context !== "view"}
                                id="client"
                                label={t("INVENTORY.coin") + "*"}
                                name="category"
                                value={values.coin || ""}
                                onChange={(ev) => {
                                    handleChange("coin", ev.target.value)
                                }}
                                className={classes.textField}
                                margin="normal"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput
                                }}
                                inputProps={{ "aria-label": "search" }}
                            >
                                {coins.map(coin => (
                                    <MenuItem key={coin.value} value={coin.value}>
                                        {coin.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                disabled={context === "view"}
                                className={classes.textField}
                                label={t("INVENTORY.price") + "(" + values.coin + ")"}
                                value={values.price || ""}
                                onChange={(ev) => { if (ev.target.value.length <= 20) { handleChange("price", ev.target.value) } }}
                                margin="normal"
                            />
                        </Grid>
                    </Grid>
                    <Grid container item spacing={3} md={12} lg={12}>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                select={context !== "view"}
                                disabled={context === "view"}
                                label={t("INVENTORY.acquisitionMethod") + "*"}
                                value={context === "view" ? values.acquisitionMethod || "" : values.acquisitionMethodId}
                                onChange={(ev) => {
                                    handleChange("acquisitionMethodId", ev.target.value)
                                }}
                                className={classes.textField}
                                margin="normal"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput
                                }}
                                inputProps={{ "aria-label": "search" }}
                            >
                                {acquisitionMethodArray.map(category => (
                                    <MenuItem key={category.value} value={category.value}>
                                        {category.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            {values.acquisitionMethodId === "PROJECTS" ?
                                <TextField
                                    disabled={context === "view"}
                                    select={context !== "view"}
                                    error={requiredProject}
                                    helperText={requiredProject && t(blankField)}
                                    label={t("INVENTORY.project") + "*"}
                                    value={context === "view" ? values.project || "" : values.projectId}
                                    onChange={(ev) => {
                                        setRequiredProject(false);
                                        handleChange("projectId", ev.target.value);
                                    }}
                                    className={classes.textField}
                                    margin="normal"
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput
                                    }}
                                    inputProps={{ "aria-label": "search" }}
                                >
                                    {context !== "view" &&
                                        projectsArray.map(project => (
                                            <MenuItem key={project.value} value={project.value}>
                                                {project.label}
                                            </MenuItem>
                                        ))}
                                </TextField>
                                :
                                <TextField
                                    disabled={context === "view"}
                                    error={requiredOther}
                                    helperText={requiredOther && t(blankField)}
                                    className={classes.textField}
                                    label={t("INVENTORY.others")}
                                    value={values.others || ""}
                                    onChange={(ev) => { if (ev.target.value.length <= 120) { handleChange("others", ev.target.value) } }}
                                    margin="normal"
                                />}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                error={requiredAdministrativeResponsible}
                                helperText={requiredAdministrativeResponsible && t(blankField)}
                                select={true}
                                disabled={context === "view"}
                                label={t("INVENTORY.administrativeResponsible") + "*"}
                                value={values.administrativeResponsibleId || ""}
                                onChange={(ev) => {
                                    handleChange("administrativeResponsibleId", ev.target.value)
                                }}
                                className={classes.textField}
                                margin="normal"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput
                                }}
                                inputProps={{ "aria-label": "search" }}
                            >
                                {usarsAdmInventoryModuleArray.map(type => (
                                    <MenuItem key={type.value} value={type.value}>
                                        {type.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography>Alocação</Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                select={context !== "view"}
                                disabled={context === "view"}
                                label={t("INVENTORY.collaboratorType")}
                                value={context === "view" ? values.collaboratorType || "" : values.collaboratorTypeId}
                                onChange={(ev) => {
                                    handleChange("collaboratorTypeId", ev.target.value)
                                }}
                                className={classes.textField}
                                margin="normal"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput
                                }}
                                inputProps={{ "aria-label": "search" }}
                            >
                                {collaboratorTypeArray.map(type => (
                                    <MenuItem key={type.value} value={type.value}>
                                        {type.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <TextField
                                select={context !== "view"}
                                disabled={context === "view"}
                                label={t("INVENTORY.directResponsible")}
                                value={context === "view" ? values.directResponsible || "" : values.directResponsibleId}
                                onChange={(ev) => {
                                    handleChange("directResponsibleId", ev.target.value)
                                }}
                                className={classes.textField}
                                margin="normal"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput
                                }}
                                inputProps={{ "aria-label": "search" }}
                            >
                                {values.collaboratorTypeId === "INTERNAL" ?
                                    usersArrayInternal.map(type => (
                                        <MenuItem key={type.value} value={type.value}>
                                            {type.label}
                                        </MenuItem>
                                    ))
                                    :
                                    usersArrayExternal.map(type => (
                                        <MenuItem key={type.value} value={type.value}>
                                            {type.label}
                                        </MenuItem>
                                    ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                select={context !== "view"}
                                disabled={context === "view"}
                                label={t("INVENTORY.machine")}
                                helperText={values.directResponsibleId && itensByUser.length === 0 && "Colaborador não tem equipamentos."}
                                value={context === "view" ? values.isExternalMachine ? "Máquina Externa" : values.machine || "" : values.isExternalMachine ? "external" : values.machineId}
                                onChange={(ev) => {
                                    handleChange("machineId", ev.target.value)
                                }}
                                className={classes.textField}
                                margin="normal"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput
                                }}
                                inputProps={{ "aria-label": "search" }}
                            >
                                {machinesArray.map(item => (
                                    <MenuItem key={item.value} value={item.value}>
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                disabled={context === "view"}
                                multiline
                                fullWidth
                                rows="6"
                                variant="outlined"
                                className={classes.textField}
                                label={t("INVENTORY.description")}
                                value={values.licenseDescription || ""}
                                margin="normal"
                                onChange={(ev) => handleChange("licenseDescription", ev.target.value)}
                                inputProps={{
                                    maxLength: 250
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputDragDropDocs
                                files={dataState}
                                onDrop={onDropFiles}
                                deleteRejectedFile={deleteRejectedFile}
                                onRemoveFileDocs={onRemoveFileDocs}
                                context={context}
                            />
                        </Grid>
                    </Grid>
                    {context !== "view" &&
                        <Grid item xs={12} sm={12} >
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                {context !== "edit" &&
                                    <Button
                                        style={{ padding: "15px", float: "right" }}
                                        onClick={() => changeContext(false, "INVENTORY.dataSoftware")}
                                    >
                                        <ChevronLeftIcon />
                                        {t("GENERAL.leftButton")}
                                    </Button>}
                                <Button
                                    color="primary"
                                    disabled={!buttonHasActivated}
                                    className={classes.blueButton}
                                    variant="contained"
                                    style={{ padding: "15px", float: "right" }}
                                    onClick={submit}
                                >
                                    <div className={classes.buttonIcon}>
                                        <CheckIcon />
                                    </div>
                                    {t("GENERAL.completed")}

                                </Button>
                            </div>
                        </Grid>}
                </Grid>
            </div>
        </React.Fragment>
    );
}
export default NewLicense;