import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import Buttons from 'components/buttons/Pressed';
import Typography from 'components/typography/Typography';
import { screenStyles } from '../../Styles';
import { AllAuthorsName } from 'utils/library/AllAuthorsName';
import { formatImagePath } from 'utils/library/formatImagePath';
import {
    DialogUnit,
    DialogImage
} from 'pages/libraryModule/dialogs/DialogUtils';
import Input from 'components/inputs/Input';
import Grid from '@material-ui/core/Grid';
import { dateToBR } from 'utils/dates/DateToBRr';
import { FormatDateToFront } from 'utils/dates/FormatDateToFront';

const DialogAwaitingListManagementCancel = props => {
    const styles = screenStyles();
    const [t] = useTranslation();
    const { open, setOpen, item, bookData, submit } = props;

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (name, value) => {
        let val = value;
        setValues({ ...values, [name]: val });
    };

    const [values, setValues] = useState({
        justification: '',
        queueId: ''
    });

    const handleSubmit = () => {
        const emailData = {
            collaboratorEmail: item.user.username,
            collaboratorName: item.user.shortname,
            justification: values.justification,
            bookTitle: bookData.title,
            bookAuthors: AllAuthorsName(bookData.authors),
            bookCategory: bookData.category.name,
            context: 'manager'
        };
        submit(item.id, values.justification, emailData);
        setOpen(false);
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth={'sm'}
                fullWidth={true}
            >
                <DialogTitle>
                    <div className={styles.dialogTitle}>
                        <Typography
                            variant={'RobotoBold'}
                            fontSize={24}
                            textColor={'secondary'}
                        >
                            {t('LIBRARY.dialogCancelQueue')}
                        </Typography>
                        <IconButton
                            color="#6D6F71"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className={styles.dialogBookRequisitionContainer}>
                        <div className={styles.dialogContent}>
                            <DialogUnit
                                head={t('INVENTORY.title').toLocaleUpperCase()}
                                content={bookData.title}
                            />
                            <DialogUnit
                                head={t(
                                    'INVENTORY.BookAuthor'
                                ).toLocaleUpperCase()}
                                content={AllAuthorsName(bookData.authors)}
                            />
                            <Typography
                                variant={'RobotoBold'}
                                fontSize={14}
                                textColor={'secondary'}
                            >
                                {t('INVENTORY.requester').toLocaleUpperCase()}
                            </Typography>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginTop: '8px'
                                }}
                            >
                                <img
                                    style={{
                                        width: '32px',
                                        height: '32px',
                                        borderRadius: '36px',
                                        marginRight: '10px',
                                        objectFit: 'cover',
                                        objectPosition: 'center'
                                    }}
                                    src={
                                        process.env.REACT_APP_IMGSERVER +
                                        item.user.imageName
                                    }
                                    alt=""
                                />
                                <Typography
                                    variant={'RobotoRegular'}
                                    fontSize={14}
                                    textColor={'secondary'}
                                >
                                    {item.user.shortname}
                                </Typography>
                            </div>
                        </div>

                        <DialogImage
                            image={formatImagePath(
                                bookData.imagePath,
                                bookData.isImageUrl
                            )}
                            alt={bookData.title}
                        />
                    </div>

                    <DialogUnit
                        margin={'16px 0 0'}
                        head={t('LIBRARY.dateQueued').toLocaleUpperCase()}
                        content={`${dateToBR(
                            FormatDateToFront(item.requestDate)
                        )}`}
                    />

                    <Grid container item spacing={2} md={12} lg={12}>
                        <Grid item xs={12} sm={12}>
                            <Input
                                required
                                placeholder={t('LIBRARY.queuedJustification')}
                                label={'GENERAL.reason'}
                                value={values.justification}
                                valueName={'justification'}
                                rows={4}
                                maxLength={200}
                                handleChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions className={styles.dialogButtonsWrapper}>
                    <Buttons
                        onClick={handleClose}
                        tipo="BN-noback"
                        conteudo={t('GENERAL.close').toUpperCase()}
                    />

                    <Buttons
                        disabled={!values.justification.length}
                        className={styles.dialogButton}
                        tipo="BN-blue"
                        conteudo={'CANCELAR RESERVA'}
                        onClick={handleSubmit}
                    />
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DialogAwaitingListManagementCancel;
