import React, { useState, useEffect } from 'react';
import { screenStyles } from './Styles';
import Typography from 'components/typography/Typography';
import Buttons from 'components/buttons/Pressed';
import { formatExemplarCode } from 'utils/library/formatExemplarCode';
import { translateStatus } from 'utils/library/translateStatus';
import { LibraryReservationStatusValues } from 'global/constants';
import ErrorIcon from '@material-ui/icons/Error';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Skeleton from '@material-ui/lab/Skeleton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const BookCardMyLoans = props => {
    const {
        context,
        image,
        title,
        author,
        exemplarCode,
        category,
        status,
        requestDate,
        returnDate,
        renovationCount,
        onClickMoreDetails,
        onClick
    } = props;
    const styles = screenStyles();

    const [loaded, setLoaded] = useState(false);

    const translateStatusHistory = status => {
        switch (status) {
            case LibraryReservationStatusValues.CANCELED_MANAGEMENT:
                return 'Cancelado Gerência';
            case LibraryReservationStatusValues.CANCELED_COLLABORATOR:
                return 'Cancelado Colaborador';
            case LibraryReservationStatusValues.FINISHED:
                return 'Devolvido';
            default:
                return '';
        }
    };

    useEffect(() => {
        setLoaded(false);
    }, [image]);

    return (
        <React.Fragment>
            <div className={styles.cardContainer}>
                <img
                    className={styles.cardImage}
                    style={!loaded ? { display: 'none' } : {}}
                    src={image}
                    alt={title}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src =
                            window.location.origin + '/img/no-book-cover.png';
                        setLoaded(true);
                    }}
                    onLoad={() => {
                        setLoaded(true);
                    }}
                />
                {!loaded && (
                    <Skeleton
                        className={styles.cardImage}
                        variant="rect"
                        width={160}
                        height={220}
                    />
                )}
                <Typography
                    className={styles.cardTitle}
                    variant={'RobotoBold'}
                    fontSize={12}
                    textColor={'secondary'}
                >
                    {title}
                </Typography>
                <Typography
                    className={styles.cardTopic}
                    variant={'RobotoBold'}
                    fontSize={12}
                    textColor={'secondary'}
                >
                    Autor
                </Typography>
                <Typography
                    className={styles.cardAuthor}
                    variant={'RobotoRegular'}
                    fontSize={12}
                    textColor={'secondary'}
                >
                    {author}
                </Typography>

                {context !== 'booksLoan' ? (
                    <>
                        <Typography
                            className={styles.cardTopic}
                            variant={'RobotoBold'}
                            fontSize={12}
                            textColor={'secondary'}
                        >
                            Categoria
                        </Typography>
                        <Typography
                            className={styles.cardAuthor}
                            variant={'RobotoRegular'}
                            fontSize={12}
                            textColor={'secondary'}
                        >
                            {category}
                        </Typography>
                    </>
                ) : null}

                {context === 'booksLoan' ? (
                    <div className={styles.cardAmount}>
                        <Typography
                            className={styles.cardTopic}
                            variant={'RobotoRegular'}
                            fontSize={12}
                            textColor={'secondary'}
                        >
                            <b>Exemplar: </b>
                            {formatExemplarCode('' + exemplarCode)}
                        </Typography>
                    </div>
                ) : null}

                {context === 'pendingRequisitions' ||
                context === 'queuedBooks' ? (
                    <div className={styles.cardAmount}>
                        <Typography
                            className={styles.cardTopic}
                            variant={'RobotoRegular'}
                            fontSize={12}
                            textColor={'secondary'}
                        >
                            <b>
                                {context === 'pendingRequisitions'
                                    ? 'Data da Requisição: '
                                    : 'Data da Reserva: '}
                            </b>
                            {requestDate}
                        </Typography>
                    </div>
                ) : null}

                <div className={styles.cardAmount}>
                    <Typography
                        className={styles.cardTopic}
                        variant={'RobotoRegular'}
                        fontSize={12}
                        textColor={'secondary'}
                    >
                        <b>Status: </b>
                        {context !== 'history'
                            ? translateStatus(status)
                            : translateStatusHistory(status)}
                    </Typography>
                    {status === LibraryReservationStatusValues.LATE ||
                    status ===
                        LibraryReservationStatusValues.CANCELED_MANAGEMENT ||
                    status ===
                        LibraryReservationStatusValues.CANCELED_COLLABORATOR ? (
                        <ErrorIcon
                            style={{
                                marginLeft: '0.2rem',
                                color: '#F3222F',
                                width: '16px',
                                height: '16px'
                            }}
                            fontSize="small"
                        />
                    ) : null}
                    {(status ===
                        LibraryReservationStatusValues.AWAITING_APPROVAL ||
                        status ===
                            LibraryReservationStatusValues.AWAITING_WITHDRAWAL ||
                        context === 'queuedBooks') && (
                        <AccessTimeIcon
                            style={{
                                marginLeft: '0.2rem',
                                color: '#6D6F71',
                                width: '16px',
                                height: '16px'
                            }}
                            fontSize="small"
                        />
                    )}
                    {status === LibraryReservationStatusValues.FINISHED ? (
                        <CheckCircleIcon
                            style={{
                                marginLeft: '0.2rem',
                                color: '#7FE46B',
                                width: '16px',
                                height: '16px'
                            }}
                            fontSize="small"
                        />
                    ) : null}
                </div>

                {context === 'booksLoan' ? (
                    <div className={styles.cardAmount}>
                        <Typography
                            className={styles.cardTopic}
                            variant={'RobotoRegular'}
                            fontSize={12}
                            textColor={'secondary'}
                        >
                            <b>Renovações: </b>
                            {renovationCount}
                        </Typography>
                    </div>
                ) : null}

                {context === 'booksLoan' ? (
                    <div className={styles.cardAmount}>
                        <Typography
                            className={styles.cardTopic}
                            variant={'RobotoRegular'}
                            fontSize={12}
                            textColor={'secondary'}
                        >
                            <b>Devolver até: </b>
                            {returnDate}
                        </Typography>
                    </div>
                ) : null}

                {context === 'pendingRequisitions' ||
                context === 'queuedBooks' ? (
                    <Typography
                        onClick={onClickMoreDetails}
                        className={styles.cardMoreDetails}
                        variant={'RobotoSemiBold'}
                        fontSize={14}
                        textColor={'primary'}
                    >
                        MAIS DETALHES
                    </Typography>
                ) : null}

                {context === 'booksLoan' || context === 'history' ? (
                    <Buttons
                        style={{ marginTop: '10px' }}
                        onClick={onClickMoreDetails}
                        className={styles.cardButton}
                        tipo="BN-blue"
                        conteudo="MAIS DETALHES"
                    />
                ) : null}
                {context === 'pendingRequisitions' ||
                context === 'queuedBooks' ? (
                    <Buttons
                        onClick={onClick}
                        className={styles.cardButton}
                        tipo="borderBackButton"
                        conteudo={
                            context === 'pendingRequisitions'
                                ? 'CANCELAR REQUISIÇÃO'
                                : 'CANCELAR RESERVA'
                        }
                    />
                ) : null}
            </div>
        </React.Fragment>
    );
};

export default BookCardMyLoans;
