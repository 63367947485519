import axios from 'axios';
import { useMutation } from 'react-query';
import Cookies from 'js-cookie';
import { useSnackbar } from 'hooks/UseSnackbar';
import { useQueryMonthlyBillInformation } from './useQueryMonthlyBillInformation';
import { useTranslation } from 'react-i18next';
import { sendEmails } from 'services/Emails/Quota/quotaEmailService';

export const useMutationResendLateBills = setOpen => {
    const toast = useSnackbar();
    const [t] = useTranslation();

    const { refetch } = useQueryMonthlyBillInformation({ enabled: false });

    const mutation = useMutation({
        mutationFn: async obj =>
            await axios.post(
                process.env.REACT_APP_EMAIL +
                    'api/Quota/Bills/ResendLateBillsWarnings',
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            ),
        onSuccess: res => {
            toast.success(t('QUOTAS.resendLateBillsSuccess'));
            sendEmails(res.data, 'api/Quota/Bills/ResendLateBillsWarnings');
        },
        onError: () => {
            toast.error(t('QUOTAS.resendLateBillsError'));
        },
        onSettled: () => {
            setOpen(false);
            refetch();
        }
    });

    return mutation;
};
