import { makeStyles } from '@material-ui/core/styles';

export const screenStyles = makeStyles(theme => ({
    CardContainer: {
        textDecoration: 'none',
        width: '225px',
        height: '110px',
        cursor: 'pointer',
        background: '#ffffff',
        borderRadius: '16px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.08)',
        border: '0.5px solid rgba(109, 111, 113, 0.6)',
        padding: '24px',
        '&:hover': {
            filter: 'brightness(95%)',
            transition: 'all 0.1s ease-in-out'
        }
    },
    CardTitleWrapper: {
        display: 'flex'
    },
    GrayCircle: {
        marginRight: '8px'
    },
    textMargin: {
        margin: '0px 0px 5px'
    },
    collaboratorInfoWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    collaboratorInfoCardImgWrapper: {
        display: 'flex',
        justifyContent: 'center'
    },
    collaboratorAvatar: {
        border: '3px solid white',
        height: '128px',
        width: '128px'
    },
    collaboratorInfoCardPersonalInfo: {
        padding: '0rem 1.75rem',
        height: '75%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    collaboratorInfoCardPersonalInfoFirstContent: {
        marginTop: '0.5rem',
        marginBottom: '1rem'
    },
    finesInfoWrapper: {
        display: 'flex',
        textAlign: 'center'
    },
    finesInfoContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '72px',
        borderRight: '1.5px solid ',
        padding: '0.5rem 1.25rem'
    },
    finesInfoContentLast: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '72px',
        padding: '0.5rem 1.25rem'
    },
    finesInfoContainer: {
        boxSizing: 'border-box',
        display: 'flex',
        padding: '1.2rem 2rem',
        backgroundColor: '#2C3E51',
        color: '#fff',
        justifyContent: 'space-between'
    },
    CardWithIconContainer: {
        display: 'flex',
        padding: '40px 32px',
        boxShadow: '0px 4px 10px 2px rgba(0, 0, 0, 0.1)',
        borderRadius: '20px'
    },
    CardWithIconImageContainer: {
        width: '45px',
        height: '45px',
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '4px 16px 4px 0'
    },
    CardWithIconText: {
        fontFamily: 'Roboto',
        fontSize: '24px',
        fontWeight: '700',
        margin: '0'
    },
    CardWithIconTextContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    }
}));
