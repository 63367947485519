export function GetInputArray(
    array,
    valueAttribute,
    labelAttribute,
    typeAttribute,
    deletedAttribute
) {
    if (array) {
        const inputArray = array.map(item => {
            return {
                label: item[labelAttribute],
                value: item[valueAttribute],
                type: typeAttribute ? item[typeAttribute] : '',
                deleted: deletedAttribute ? item[deletedAttribute] : false
            };
        });
        return inputArray;
    } else {
        return [];
    }
}
