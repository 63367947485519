import React, { useState, useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import Input from 'components/inputs/Input';
import Typography from 'components/typography/Typography';
import GreyDataInfo from 'components/dataInfos/GreyDataInfo';
import Buttons from 'components/buttons/Pressed';
import Tooltip from '@material-ui/core/Tooltip';
import Visibility from '@material-ui/icons/Visibility';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import Link from 'react-router-dom/Link';
import ClockRecordContext from 'context/hrModule/clockRecord/clockRecordContext';

import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';
import { Screen, Allow } from 'global/constants';
import { FormatDateToFront } from 'utils/dates/FormatDateToFront';
import { dateToBR } from 'utils/dates/DateToBRr';
import { screenStyles } from './Styles';
import { GetJustificationType, JustificationType } from './ScreenUtils';

const MyJustificationsTableActions = props => {
    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }
    const styles = screenStyles();
    const classes = useStyles();
    const [t] = useTranslation();
    const { item, submit, fetchData } = props;
    const clockRecordContext = useContext(ClockRecordContext);

    const { disableMyJustifications } = clockRecordContext;
    const [openDelete, setOpenDelete] = useState(false);

    const handleClose = () => {
        setOpenDelete(false);
    };

    const handleSubmit = () => {
        disableMyJustifications(item.id, fetchData);
    };

    return (
        <React.Fragment>
            <Link to={`/MyJustifications/${item.id}`}>
                <Tooltip
                    title={t('GENERAL.view')}
                    aria-label="details"
                    placement="top"
                >
                    <IconButton className={classes.iconsTableActions}>
                        <Visibility />
                    </IconButton>
                </Tooltip>
            </Link>
            {item.status === 'PENDING' && (
                <>
                    <Link to={`/MyJustifications/${item.id}/edit`}>
                        <Tooltip
                            title={t('GENERAL.edit')}
                            aria-label="details"
                            placement="top"
                        >
                            <IconButton className={classes.iconsTableActions}>
                                <Edit />
                            </IconButton>
                        </Tooltip>
                    </Link>
                    <Tooltip
                        title={t('GENERAL.delete')}
                        aria-label="details"
                        placement="top"
                    >
                        <IconButton
                            className={classes.iconsTableActions}
                            onClick={() => setOpenDelete(true)}
                        >
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </>
            )}

            <Dialog
                open={openDelete}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle className={classes.Title}>
                    <b>
                        {' '}
                        {`${t('GENERAL.delete')} ${t('GENERAL.justification')}`}
                    </b>
                    <IconButton
                        style={{
                            float: 'right',
                            marginTop: -10,
                            marginRight: -17
                        }}
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid container item spacing={2} md={12} lg={12}>
                        <Grid item xs={12} sm={12}>
                            <Typography
                                variant={'RobotoRegular'}
                                fontSize={16}
                                textColor={'secondary'}
                            >
                                {t('HR.deleteMyJustificationsMessage')}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <GreyDataInfo
                                header={
                                    item.class ===
                                    JustificationType.TOTAL_PAYMENT
                                        ? t('HR.justifiedPeriod')
                                        : t('HR.justifiedDate')
                                }
                                content={
                                    item.class ===
                                    JustificationType.TOTAL_PAYMENT
                                        ? `${dateToBR(
                                              FormatDateToFront(
                                                  item.requestedDate
                                              )
                                          )} - ${dateToBR(
                                              FormatDateToFront(item.endDate)
                                          )}`
                                        : dateToBR(
                                              FormatDateToFront(
                                                  item.requestedDate
                                              )
                                          )
                                }
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <GreyDataInfo
                                header={t('HR.justificationType')}
                                content={GetJustificationType(item.class)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Buttons
                        onClick={handleClose}
                        tipo="BN-noback"
                        conteudo={t('GENERAL.cancel').toUpperCase()}
                    />
                    <Buttons
                        onClick={handleSubmit}
                        tipo="BN-blue"
                        conteudo={t('GENERAL.delete').toUpperCase()}
                    />
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default MyJustificationsTableActions;
