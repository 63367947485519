/* eslint-disable */
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import Add from "@material-ui/icons/Add";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Dissatisfied from "@material-ui/icons/SentimentVeryDissatisfied";
import { useStyles } from "components/layout/CommonStyles";
import Footer from "components/layout/Footer";
import AllowancesContext from "context/allowance/allowanceContext";
import ProjectsContext from "context/projects/projectsContext";
import SearchContext from "context/search/searchContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import Spinner from "../../components/layout/spinners/Index";
import MySnackbarContentWrapper from "../layout/Message";
// import { useStyles } from "../profiles/ProfileStyles";
import TableActions from "./ProjectsTableActions";

const ProjectsTable = props => {
  const { context } = props;
  const classes = useStyles();
  const [t] = useTranslation();
  const projectsContext = useContext(ProjectsContext);
  const searchContext = useContext(SearchContext);
  const allowanceContext = useContext(AllowancesContext);
  const {
    verifyPermission,
    permission
  } = allowanceContext;
  
  const {
    loading,
    projects,
    getProjects,
    resetDeleteMessage,
    resetProject,
    responseDelete,
    setProjectCreated,
    successiveActions,
    responseDeleteType,
    setValuePanelTab,
    setHasChanged,
    hasChanged,
    responseType,
    response
  } = projectsContext;

  const {
    allowanceResponse,
    allowanceResponseType,
    messageHasChanged,
    resetAllowanceMessage
  } = allowanceContext;
  const { filter } = searchContext;

  const [snackBar, setSnackBar] = useState({
    open: false,
    kind: "",
    content: ""
  });
  let dataFilter = [];
  let dataFilterPlannig = [];

  const hasProject = projects.length > 0;

  useEffect(() => {
    resetProject();

    setProjectCreated(false);
    setValuePanelTab(0);
    getProjects();
    if (context === "planning") {
      verifyPermission("PROJECTSPLAN")
    }else{
      verifyPermission("PROJECTSEXEC")
    }

    if (allowanceResponseType === "ERROR" && messageHasChanged) {
      handleSnack({ kind: "error", content: allowanceResponse });
    }
    if (responseDeleteType === "SUCCESS" && hasChanged > successiveActions) {
      handleSnack({ kind: "success", content: responseDelete });
    }
    if (responseDeleteType === "ERROR" && hasChanged > successiveActions) {
      handleSnack({ kind: "error", content: responseDelete });
    }

    return () => { };
  }, [responseDeleteType, responseDelete, successiveActions, allowanceResponseType,
    allowanceResponse, permission.length, JSON.stringify(permission)]);

  // useEffect(() => {
      
  //   }, [permission.length]);

  useEffect(() => {
    //getCurrentLoggedProfile(hasChangedAllowances, formattedAllowances);
    if (allowanceResponseType === "ERROR" && messageHasChanged) {
      handleSnack({ kind: "error", content: allowanceResponse });
    }
    if (responseType === "SUCCESS" && hasChanged > successiveActions) {
      handleSnack({ kind: "success", content: response });
    }
    if (responseType === "ERROR" && hasChanged > successiveActions) {
      handleSnack({ kind: "error", content: response });
    }

    return () => { };
  }, [
    responseType,
    response,
    successiveActions,
    allowanceResponseType,
    allowanceResponse,
  ]);

  dataFilter = projects;
  dataFilterPlannig = projects;

  if (filter) {
    dataFilter = dataFilter.filter(
      item =>
        item.gpf.toLowerCase().startsWith(filter.toLowerCase()) ||
        item.name.toLowerCase().startsWith(filter.toLowerCase()) ||
        item.status.toLowerCase().startsWith(filter.toLowerCase())
    );
  }

  if (context !== "planning") {
    dataFilterPlannig = dataFilterPlannig.filter(
      item =>
        item.status !== "Em Planejamento"
    );
    if (filter) {
      dataFilterPlannig = dataFilterPlannig.filter(
        item =>
          item.gpf.toLowerCase().startsWith(filter.toLowerCase()) ||
          item.name.toLowerCase().startsWith(filter.toLowerCase()) ||
          item.status.toLowerCase().startsWith(filter.toLowerCase())
      );
    }
  }

  const handleSnack = () => {
    setSnackBar({ ...snackBar, open: true });
    setHasChanged();
    resetAllowanceMessage();

  };

  const handleSnackClose = () => {
    setSnackBar({ ...snackBar, open: false });
   // setHasChanged();
    //resetAllowanceMessage();
  };

  const transitions = useTransition(loading, null, {
    from: { opacity: 0 },
    enter: {
      opacity: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "calc(100vh - 70px)"
    },
    leave: { opacity: 0 }
  });

  return transitions.map(({ item, key, props }) =>

    item ? (
      <animated.div key={key} style={props}>
        <Spinner />;
      </animated.div>
    ) : (
        <animated.div key={key} style={props}>
          {context === "planning" ? (
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={snackBar.open}
              TransitionComponent={Slide}
              transitionDuration={{ enter: 500, exit: 500 }}
              onClose={handleSnackClose}
              ContentProps={{
                "aria-describedby": "message-id"
              }}
              autoHideDuration={2000}
            >

              {responseDeleteType === "" ?
                <MySnackbarContentWrapper
                onClose={handleSnackClose}
                variant={
                  responseType !== ""
                    ? responseType.toLowerCase()
                    : allowanceResponseType.toLowerCase()
                }
                message={
                  <span id="message-id">
                    {response !== "" ? response : allowanceResponse}
                  </span>
                }
                />
                :
                <MySnackbarContentWrapper
                onClose={handleSnackClose}
                variant={
                  responseDeleteType !== ""
                    ? responseDeleteType.toLowerCase()
                    : allowanceResponseType && allowanceResponseType.toLowerCase()
                }
                message={<span id="message-id">{responseDelete !== "" ? responseDelete : allowanceResponse}</span>}
              />
              }
            </Snackbar>
            ) : (<></>
              /*If the context for the execution, will not render the message component, since, do not use and it is generating a bug in the breadcrump.NSL381*/
            )}
          <div className="container">
            <Breadcrumbs
              className={classes.breadCrumbs}
              aria-label="breadcrumb"
              separator={<NavigateNextIcon fontSize="small" />}
            >
              <Typography>
                {context === "planning" ? t("GENERAL.workPlan") : t("PROFILES.form.labor")}
              </Typography>
              <Typography>{t("PROJECTS.projects")}</Typography>
            </Breadcrumbs>
            <div className="container-header">
              <div className="title">
                <h1 className={classes.title}>{t("PROJECTS.projects")}</h1>
              </div>
              {context === "planning" && 
                (permission["allowCreate"] &&
                <Link to={"/projects/new"}>
                  <Button
                    className={classes.blueButton}
                    variant="contained"
                    color="primary"
                  >
                    <div className={classes.buttonIcon}>
                      <Add />
                    </div>
                    {t("PROJECTS.addProject")}
                  </Button>
                </Link>)
              }
            </div>
            <Paper>
              {hasProject ?
                <TableActions
                  rows={context !== "planning" ? dataFilterPlannig : dataFilter}
                  headers={["id", "gpf", "name", "startDate", "endDate", "yearBase", "status"]}
                  sortedFields={["gpf", "name", "startDate", "endDate", "yearBase", "status"]}
                  visibleHeaders={[
                    "GPF",
                    "NOME",
                    "DATA DE INÍCIO",
                    "DATA DE TÉRMINO",
                    "ANO BASE",
                    "STATUS"
                  ]}
                  mainField="gpf"
                  context={context}
                  permission={permission}
                />
                :
                <div className={classes.noParticipantsMessage}>
                  <h2>{t("PARTICIPANTS.noParticipantsMessage")}</h2>
                  <h4>{t("PARTICIPANTS.noParticipantsSubMessage")}</h4>
                </div>
              }
              {(hasProject && dataFilter.length === 0) &&
                <div className={classes.noParticipantsMessage}>
                  <div >
                    <Dissatisfied className={classes.notFoundIconStyle} />
                  </div>
                  <h2>{t("GENERAL.noResultsFound")}</h2>
                  <h5>{`Sua busca por "${filter}" não retornou resultados.`}</h5>
                </div>
              }
            </Paper>
          </div>
          <Footer />
        </animated.div>
      )
  );
};

export default ProjectsTable;
