import { DEFAULT, INVENTORY } from '../types';

export default (state, action) => {
    switch (action.type) {
        case DEFAULT.SET_LOADING:
            return {
                ...state,
                loading: true
            };
        case DEFAULT.SET_LOADING_BOOKS:
            return {
                ...state,
                loadingBooks: true
            };
        case DEFAULT.SET_LOADING_SOFTWARE:
            return {
                ...state,
                loadingSoftware: true
            };
        case DEFAULT.SET_LOADING_EQUIPMENT:
            return {
                ...state,
                loadingEquipment: true
            };
        case DEFAULT.SET_LOADING_EQUIPMENT_FALSE:
            return {
                ...state,
                loadingEquipment: false
            };
        case INVENTORY.SET_LOADING_REQUISITION:
            return {
                ...state,
                loadingRequisition: true
            };
        case INVENTORY.SET_LOADING_REQUISITION_FALSE:
            return {
                ...state,
                loadingRequisition: false
            };
        case INVENTORY.GET_MYITENS:
            return {
                ...state,
                myItens: action.payload,
                loading: false
            };
        case INVENTORY.GET_MYSOFTWARES:
            return {
                ...state,
                mySoftwares: action.payload,
                loading: false
            };
        case INVENTORY.GET_PLACES:
            return {
                ...state,
                places: action.payload,
                loading: false
            };
        case INVENTORY.GET_PLACE:
            return {
                ...state,
                place: action.payload,
                loading: false
            };
        case INVENTORY.GET_PERMISSIONS:
            return {
                ...state,
                permissions: action.payload,
                loading: false
            };
        case DEFAULT.RESET_MESSAGE:
            return {
                ...state,
                response: '',
                responseType: ''
            };
        case INVENTORY.ADD_PLACE:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged,
                loading: false
            };
        case INVENTORY.UPDATE_PLACE:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged,
                loading: false
            };
        case INVENTORY.DELETE_PLACE:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged,
                loading: false
            };
        case INVENTORY.GET_BOOKCATEGORIES:
            return {
                ...state,
                bookCategories: action.payload,
                loading: false
            };
        case INVENTORY.GET_BOOKCATEGORY:
            return {
                ...state,
                bookCategory: action.payload,
                loading: false
            };
        case INVENTORY.ADD_BOOKCATEGORY:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged,
                loading: false
            };
        case INVENTORY.UPDATE_BOOKCATEGORY:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged,
                loading: false
            };
        case INVENTORY.DELETE_BOOKCATEGORY:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged,
                loading: false
            };
        case INVENTORY.GET_ITENSCATEGORIES:
            return {
                ...state,
                itensCategories: action.payload,
                loading: false
            };
        case INVENTORY.GET_ITENS_CATEGORIES_BY_ITENS_AVALIABLE:
            return {
                ...state,
                itensCategoriesByItensAvaliable: action.payload,
                loading: false
            };
        case INVENTORY.GET_ITENS_SOFTWARE_CATEGORIES_BY_LECENSES_AVALIABLE:
            return {
                ...state,
                softwareCategoriesByLicensesAvaliable: action.payload,
                loading: false
            };
        case INVENTORY.GET_ITEMCATEGORY:
            return {
                ...state,
                itemCategory: action.payload,
                loading: false
            };
        case INVENTORY.ADD_ITEMCATEGORY:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged,
                loading: false
            };
        case INVENTORY.UPDATE_ITEMCATEGORY:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged,
                loading: false
            };
        case INVENTORY.DELETE_ITEMCATEGORY:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged,
                loading: false
            };
        case INVENTORY.SET_SEARCH_BOOK:
            return {
                ...state,
                searchLinkBook: action.payload
            };
        case INVENTORY.GET_ALLSTATUS:
            return {
                ...state,
                allStatus: action.payload,
                loading: false
            };
        case INVENTORY.GET_ITEMSTATUS:
            return {
                ...state,
                itemStatus: action.payload,
                loading: false
            };
        case INVENTORY.ADD_ITEMSTATUS:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged,
                loading: false
            };
        case INVENTORY.UPDATE_ITEMSTATUS:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged,
                loading: false
            };
        case INVENTORY.DELETE_ITEMSTATUS:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged,
                loading: false
            };
        case INVENTORY.GET_ALLEQUIPMENT:
            return {
                ...state,
                equipment: action.payload,
                loading: false
            };
        case INVENTORY.GET_EQUIPMENT:
            return {
                ...state,
                equipmentItem: action.payload,
                loading: false
            };
        case INVENTORY.ADD_EQUIPMENT:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged,
                loading: false
            };
        case INVENTORY.UPDATE_EQUIPMENT:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged,
                loading: false
            };
        case INVENTORY.GET_SUBITENS:
            return {
                ...state,
                subitens: action.payload,
                loading: false
            };
        case INVENTORY.ADD_SUBITENS:
            return {
                ...state,
                arraySubItens: [...state.arraySubItens, action.payload]
            };
        case INVENTORY.DELETE_SUBITENS:
            var array = [...state.arraySubItens];
            var index = array.indexOf(action.payload);
            if (index !== -1) {
                state.arraySubItens.splice(index, 1);
                return {
                    ...state,
                    arraySubItens: state.arraySubItens
                };
            }
        case INVENTORY.GET_ALLMAINTENANCES:
            return {
                ...state,
                maintenances: action.payload,
                loading: false
            };
        case INVENTORY.GET_MAINTENANCE:
            return {
                ...state,
                maintenance: action.payload,
                loading: false
            };
        case INVENTORY.ADD_MAINTENANCE:
            return {
                ...state,
                // response: action.payload,
                // responseType: action.responseType,
                // successiveActions: action.successiveActions,
                // hasChanged: action.hasChanged,
                loading: false
            };
        case INVENTORY.UPDATE_MAINTENANCE:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged,
                loading: false
            };
        case INVENTORY.DELETE_MAINTENANCE:
            return {
                ...state,
                // response: action.payload,
                // responseType: action.responseType,
                // successiveActions: action.successiveActions,
                // hasChanged: action.hasChanged,
                loading: false
            };
        case INVENTORY.GET_EXTERNALUSERSHISTORY:
            return {
                ...state,
                externalUserHistory: action.payload,
                loading: false
            };
        case INVENTORY.GET_HISTORYEQUIPMENT:
            return {
                ...state,
                historyEquipment: action.payload,
                loading: false
            };
        case INVENTORY.GET_HISTORYEXEMPLAR:
            return {
                ...state,
                historyExemplar: action.payload,
                loading: false
            };
        case INVENTORY.GET_EXTERNAL_USER_PLACES:
            return {
                ...state,
                externalUserPlaces: action.payload,
                loading: false
            };
        case INVENTORY.GET_EQUIPMENTBYEXTERNALUSER:
            return {
                ...state,
                externalUserItens: action.payload,
                loading: false
            };
        case INVENTORY.GET_LSBD_CODES_HISTORY:
            return {
                ...state,
                lsbdCodesHistory: action.payload,
                loading: false
            };
        case DEFAULT.SET_HAS_CHANGED:
            return {
                ...state,
                hasChanged: 0
            };
        case INVENTORY.GET_EXTERNAL_PARTNERS:
            return {
                ...state,
                externalPartners: action.payload
            };
        case INVENTORY.GET_BOOKS:
            return {
                ...state,
                books: action.payload,
                loadingBooks: false
            };
        case INVENTORY.GET_LIBRARY_ADMS:
            return {
                ...state,
                libraryAdms: action.payload,
                loading: false
            };
        case INVENTORY.GET_NEXT_CODES:
            return {
                ...state,
                nextCodes: action.payload
            };
        case INVENTORY.GET_ISBN_AND_TITLES:
            return {
                ...state,
                isbnAndTitle: action.payload
            };
        case INVENTORY.GET_BOOK:
            return {
                ...state,
                book: action.payload,
                loading: false
            };
        case INVENTORY.GET_BOOK_BY_ISBN:
            return {
                ...state,
                bookByIsbn: action.payload,
                loading: false
            };
        case INVENTORY.GET_INVENTORY_IMAGE:
            return {
                ...state,
                inventoryImage: action.payload,
                loading: false
            };
        case INVENTORY.CLEAN_BOOK:
            return {
                ...state,
                book: {}
            };
        case INVENTORY.CLEAN_EXEMPLAR:
            return {
                ...state,
                exemplar: {}
            };
        case INVENTORY.GET_EXEMPLAR:
            return {
                ...state,
                exemplar: action.payload,
                loading: false
            };
        case INVENTORY.GET_ADMS_PEOPLE_MANAGEMENT:
            return {
                ...state,
                admsPeopleManagement: action.payload,
                loading: false
            };
        case INVENTORY.SAVE_LINK_EQUIPMENT:
            return {
                ...state,
                linkEquipment: action.payload
            };
        case INVENTORY.SET_SEARCH_EQUIPMENT:
            return {
                ...state,
                searchLinkEquipment: action.payload
            };
        case INVENTORY.UPDATE_EXEMPLAR:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged,
                loading: false
            };
        case INVENTORY.DELETE_BOOK:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged,
                loading: false
            };
        case INVENTORY.GET_SOFTWARE_CATEGORIES:
            return {
                ...state,
                softwareCategories: action.payload,
                loading: false
            };
        case INVENTORY.ADD_SOFTWARE_CATEGORY:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged,
                loading: false
            };
        case INVENTORY.UPDATE_SOFTWARE_CATEGORY:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged,
                loading: false
            };
        case INVENTORY.DELETE_SOFTWARE_CATEGORY:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged,
                loading: false
            };
        case INVENTORY.UPDATE_DELETE_SOFTWARE:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged,
                loading: false
            };
        case INVENTORY.UPDATE_DELETE_LICENSE:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged,
                loading: false
            };
        case INVENTORY.GET_AUTHORS:
            return {
                ...state,
                authors: action.payload,
                loading: false
            };
        case INVENTORY.GET_SOFTWARES:
            return {
                ...state,
                softwares: action.payload,
                loadingSoftware: false
            };
        case INVENTORY.GET_SOFTWARES_ONLY:
            return {
                ...state,
                softwaresForRequisition: action.payload
            };
        case INVENTORY.GET_PLATFORMS:
            return {
                ...state,
                platforms: action.payload
            };
        case INVENTORY.GET_SOFTWARE:
            return {
                ...state,
                software: action.payload,
                loading: false
            };
        case INVENTORY.UPDATE_SOFTWARE:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged,
                loading: false
            };
        case INVENTORY.GET_LICENSE:
            return {
                ...state,
                license: action.payload,
                loading: false
            };
        case INVENTORY.SET_SEARCH_SOFTWARE:
            return {
                ...state,
                searchLinkSoftware: action.payload
            };
        case INVENTORY.GET_ITENS_BY_USERS_LICENSE:
            return {
                ...state,
                itensByUser: action.payload,
                loading: false
            };
        case INVENTORY.GET_MY_REQUESTS:
            return {
                ...state,
                myRequests: action.payload,
                loading: false
            };
        case INVENTORY.GET_COLLABORATORS_REQUESTS:
            return {
                ...state,
                collaboratorsRequests: action.payload,
                loading: false
            };
        case INVENTORY.GET_COLLABORATORS_MANAGED:
            return {
                ...state,
                collaboratorsManaged: action.payload,
                loading: false
            };
        case INVENTORY.GET_REQUISITION_BY_ID:
            return {
                ...state,
                requisition: action.payload,
                loading: false
            };
        case INVENTORY.DELETE_REQUISITION:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged,
                loading: false
            };
        case INVENTORY.GET_LICENSE_BY_USERS:
            return {
                ...state,
                licensesByUser: action.payload,
                loading: false
            };
        case INVENTORY.MANAGER_APPROVE_OR_REFUSE:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged,
                loading: false
            };
        case INVENTORY.GET_REQUISITION:
            return {
                ...state,
                requisition: action.payload,
                loading: false
            };
        case INVENTORY.GET_APPROVER:
            return {
                ...state,
                approver: action.payload,
                loading: false
            };
        case INVENTORY.GET_PATRIMONY_MANAGER:
            return {
                ...state,
                patrimonyManager: action.payload,
                loading: false
            };
        case INVENTORY.GET_LICENSES_ALL:
            return {
                ...state,
                licensesAll: action.payload,
                loading: false
            };
        case INVENTORY.GET_LICENSES_AVAILABLE:
            return {
                ...state,
                availableLicenses: action.payload,
                loading: false
            };
        case INVENTORY.GET_ALL_REQUESTS:
            return {
                ...state,
                allRequisitions: action.payload,
                loading: false
            };
        case INVENTORY.GET_ITENS_AVAILABLE_BY_CATEGORY:
            return {
                ...state,
                itensAvailable: action.payload,
                loading: false
            };
        case DEFAULT.GET_USERS_EMAIL:
            return {
                ...state,
                usersEmail: action.payload
            };
        default:
            return state;
    }
};
