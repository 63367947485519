import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Typography from 'components/typography/Typography';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';

import { MakeDeepCopy } from 'utils/general/MakeDeepCopy';

import { inputStyles } from './Styles';

const InputMultiAutoComplete = ({
    value,
    setValue,
    label,
    placeholder,
    maxLength,
    arraySelected,
    attributeLabel,
    context,
    required,
    error,
    helperText,
    setRequired,
    ...props
}) => {
    const classes = useStyles();
    const styles = inputStyles();
    const [t] = useTranslation();

    return (
        <Autocomplete
            disabled={context === 'view' ? true : false}
            multiple
            options={arraySelected.map(option => option[attributeLabel])}
            value={value}
            onChange={(event, newValue) => {
                if (typeof setRequired === 'function') {
                    setRequired(false);
                }
                setValue(newValue);
            }}
            freeSolo
            renderTags={(value, getTagProps) =>
                value.map((option, index) =>
                    context === 'view' ? (
                        <Chip
                            variant="outlined"
                            label={option}
                            style={{
                                color: 'currentColor',
                                margin: '3px'
                            }}
                        />
                    ) : (
                        <Chip
                            variant="outlined"
                            label={option}
                            style={
                                arraySelected.some(
                                    item => item[attributeLabel] === option
                                )
                                    ? { borderColor: '#2D9CDB' }
                                    : { borderColor: '#FFD200' }
                            }
                            {...getTagProps({ index })}
                        />
                    )
                )
            }
            renderInput={params => (
                <TextField
                    {...params}
                    label={t(label) + (required ? '*' : '')}
                    placeholder={context !== 'view' ? t(placeholder) : ''}
                    error={error}
                    helperText={helperText}
                />
            )}
        />
    );
};

export default InputMultiAutoComplete;
