import axios from 'axios';
import React, { useReducer } from 'react';
import { DEFAULT, LIBRARY } from '../../types';
import finesContext from './finesContext';
import finesReducer from './finesReducer';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { LibraryFinesTypes } from 'global/constants';
import { Satellite } from 'mdi-material-ui';

const FinesState = props => {
    const initialState = {
        message: '',
        response: '',
        responseType: '',
        hasChanged: 0,
        successiveActions: 0,
        loading: false,
        fines: [],
        finesTransactionsData: null,
        transactions: []
    };

    const [state, dispatch] = useReducer(finesReducer, initialState);
    const [t] = useTranslation();
    const setLoading = () => dispatch({ type: DEFAULT.SET_LOADING });
    const setLoadingFalse = () => dispatch({ type: DEFAULT.SET_LOADING_FALSE });
    const resetMessage = () => dispatch({ type: DEFAULT.RESET_MESSAGE });
    const setHasChanged = () => dispatch({ type: DEFAULT.SET_HAS_CHANGED });

    const getFines = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/Library/Fines`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: LIBRARY.GET_FINES,
            payload: res.data
        });
    };

    const getFinesTransactionsData = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/Library/FinesTransactions/Data`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: LIBRARY.GET_FINES_TRANSACTIONS_DATA,
            payload: res.data
        });
    };

    const createTransaction = async (obj, fetchData, type) => {
        axios
            .post(
                process.env.REACT_APP_SERVER + 'api/Library/Fines/Transactions',
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                dispatch({
                    type: LIBRARY.CREATE_TRANSACTION,
                    payload:
                        type === LibraryFinesTypes.CREDIT
                            ? t('LIBRARY.createTransactionCreditSuccess')
                            : t('LIBRARY.createTransactionDebitSuccess'),
                    responseType: 'SUCCESS',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .catch(error => {
                dispatch({
                    type: LIBRARY.CREATE_TRANSACTION,
                    payload: t('LIBRARY.createTransactionError'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                fetchData();
            });
    };

    const getTransactions = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER +
                `api/Library/FinesTransactions/Transactions`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: LIBRARY.GET_TRANSACTIONS,
            payload: res.data
        });
    };

    return (
        <finesContext.Provider
            value={{
                loading: state.loading,
                message: state.message,
                hasChanged: state.hasChanged,
                response: state.response,
                responseType: state.responseType,
                successiveActions: state.successiveActions,
                fines: state.fines,
                finesTransactionsData: state.finesTransactionsData,
                setLoading,
                setLoadingFalse,
                resetMessage,
                setHasChanged,
                getFines,
                getFinesTransactionsData,
                createTransaction,
                getTransactions,
                transactions: state.transactions
            }}
        >
            {props.children}
        </finesContext.Provider>
    );
};

export default FinesState;
