import React, { useState, useEffect, useContext } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import Breadcrumb from 'components/layout/breadcrumb/Breadcrumb';
import Footer from 'components/layout/Footer';
import HeaderContainer from 'components/containers/HeaderContainer';
import { screenStyles } from 'pages/libraryModule/MyLoans/Styles';
import Transition from 'components/layout/transition/Transition';
import { LibraryReservationStatusValues } from 'global/constants';
import { QueryGetValue } from 'utils/general/QueryString';
import RequisitionsContext from 'context/libraryModule/requisitions/requisitionsContext';
import LibraryLoansForm from './forms/LibraryLoansForm';
import LibraryLoanHistoryForm from './forms/LibraryLoanHistory/LibraryLoanHistoryForm';

const LibraryLoansTabPanel = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const [value, setValue] = useState(0);
    const styles = screenStyles();
    const [panelQuery, setPanelQuery] = useState('');

    const [booksLoanData, setBooksLoanData] = useState([]);
    const [historyData, setHistoryData] = useState([]);

    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const requisitionsContext = useContext(RequisitionsContext);
    const {
        loading,
        setLoading,
        setLoadingFalse,
        getLoans,
        loans,
        updateRequisition
    } = requisitionsContext;

    async function fetchData() {
        await setLoading();
        await getLoans();
        await setLoadingFalse();
    }

    useEffect(() => {
        fetchData();
        setPanelQuery(QueryGetValue('panel'));
    }, []);

    useEffect(() => {
        if (panelQuery === 'BOOKSLOAN') {
            setValue(0);
        }
        if (panelQuery === 'HISTORY') {
            setValue(1);
        }
    }, [panelQuery]);

    useEffect(() => {
        if (loans) {
            setBooksLoanData(
                loans.filter(
                    r =>
                    r.status ===
                        LibraryReservationStatusValues.LOAN ||
                    r.status ===
                        LibraryReservationStatusValues.LATE
                )
            );

            setHistoryData(
                loans.filter(
                    r =>
                        r.status ===
                            LibraryReservationStatusValues.CANCELED_MANAGEMENT ||
                        r.status ===
                            LibraryReservationStatusValues.CANCELED_COLLABORATOR ||
                        r.status === LibraryReservationStatusValues.FINISHED
                )
            );
        }
    }, [loans]);

    const submitRequisition = (
        id,
        justification,
        fine,
        emailData,
        fineChanged
    ) => {
        let formData = new FormData();

        formData.append('status', LibraryReservationStatusValues.FINISHED);
        formData.append('receivedBy', auth.id);
        formData.append('justification', justification);
        formData.append('fine', fine);
        formData.append('fineChanged', fineChanged);

        emailData.operatorName = auth.name;

        updateRequisition(
            id,
            'devolution',
            formData,
            emailData,
            fetchData,
            'LIBRARY_LOANS'
        );
    };

    function TabPanel(props) {
        const { children, value, index } = props;

        return <div>{value === index && children}</div>;
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`
        };
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const tabContext = () => {
        if (value === 0) {
            return <LibraryLoansForm BooksLoanData={booksLoanData} SubmitRequisition={submitRequisition} />;
        }
        if (value === 1) {
            return <LibraryLoanHistoryForm HistoryData={historyData} />;
        }
    };

    return (
        <React.Fragment>
            <Transition loading={loading} newDesign={true}>
                <HeaderContainer>
                    <Breadcrumb
                        newDesign={true}
                        routes={[
                            { label: t('INVENTORY.library'), to: '/Library' },
                            { label: t('LIBRARY_LOANS') }
                        ]}
                    />
                    <div className="container-headerNewDesign">
                        <h1 className={classes.titleNewDesign}>
                            {t('LIBRARY_LOANS')}
                        </h1>
                    </div>
                </HeaderContainer>

                <div className="containerNewDesign">
                    <Paper>
                        <AppBar
                            position="static"
                            style={{ boxShadow: 'none', transform: 'none' }}
                        >
                            <Tabs
                                selectionFollowsFocus
                                style={{
                                    backgroundColor: '#f5f6fa',
                                    color: 'black'
                                }}
                                indicatorColor="primary"
                                value={value}
                                onChange={handleChange}
                            >
                                <Tab
                                    classes={{
                                        wrapper: styles.iconLabelWrapper2,
                                        labelContainer: styles.labelContainer
                                    }}
                                    style={{ fontWeight: 'bold' }}
                                    label={t('LIBRARY.loanList').toUpperCase()}
                                    {...a11yProps(0)}
                                />
                                <Tab
                                    classes={{
                                        wrapper: styles.iconLabelWrapper2,
                                        labelContainer: styles.labelContainer
                                    }}
                                    style={{ fontWeight: 'bold' }}
                                    label={t(
                                        'LIBRARY.history'
                                    ).toUpperCase()}
                                    {...a11yProps(1)}
                                />
                            </Tabs>
                        </AppBar>

                        {tabContext()}
                    </Paper>
                </div>
                <Footer />
            </Transition>
        </React.Fragment>
    );
};

export default LibraryLoansTabPanel;
