/* eslint-disable */
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CssBaseline from "@material-ui/core/CssBaseline";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import { Alert } from "mdi-material-ui";
import React, { useContext } from "react";
import history from "../../history";
import Buttons from "../buttons/Pressed";
import useStyles from "./Styles";
import InventoryContext from "context/inventory/inventoryContext";

export default function Login(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  var email = "";
  var password = "";

  const { cookies } = props;

  const inventoryContext = useContext(InventoryContext);

  const {
    linkEquipment
  } = inventoryContext;

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }
  function incorrect() {
    document.getElementById("incorreto").style.display = "block";
    document.getElementById("desativado").style.display = "none";
  }
  function desativado() {
    document.getElementById("desativado").style.display = "block";
    document.getElementById("incorreto").style.display = "none";
  }

  function sendRequest(event) {
    event.preventDefault();

    const requestInfo = {
      method: "POST",
      body: JSON.stringify({
        email: email,
        password: password
      }),
      headers: new Headers({
        "Content-type": "application/json"
      })
    };

    fetch(process.env.REACT_APP_SERVER + "api/auth/login", requestInfo)
      .then(response => {
        if (response.ok) {
          return response.text();
        } else {
          if (response.status === 456) {
            desativado();
          } else {
            incorrect();
          }
          throw new Error("Login ou senha errados! Tente novamente.");
        }
      })
      .then(jwt => {
        var jwtParsed = JSON.parse(jwt);

        localStorage.setItem("auth-token", JSON.stringify(jwtParsed.authData));

        cookies.set("auth-token", jwtParsed.token, {
          path: "/",
          domain: process.env.REACT_APP_DOMAIN
          // maxAge: 900,
          // secure: false,
          // httpOnly: false
        });

        if (localStorage.getItem("auth-token") !== null) {
          var auth = JSON.parse(localStorage.getItem("auth-token"));
          var array = []
          for (var i = 0; i < auth.permissions.length; i++) {
            var permission = auth.permissions[i].standardauthorizationoption;
            var resource = auth.permissions[i].resource;
            if (permission.allowCreate ||
              permission.allowDelete ||
              permission.allowRead ||
              permission.allowUpdate) {
              array.push(resource)
            }
          }
        }

        var screen = '';

        if (linkEquipment) {
          screen = linkEquipment
        } else {
          if (array.indexOf("PROFILE") >= 0) {
            screen = "/profiles"
          } else {
            if (array.indexOf("USER") >= 0) {
              screen = "/users"
            } else {
              if (array.indexOf("PARTNER") >= 0) {
                screen = "/partners"
              } else {
                if (array.indexOf("PROJECTSPLAN") >= 0) {
                  screen = "/projects"
                } else {
                  if (array.indexOf("PROJECTSEXEC") >= 0) {
                    screen = "/execution/projects"
                  } else {
                    screen = "/"
                  }
                }
              }
            }
          }
        }
        history.push(screen);
      })
      .catch(error => {
        console.log(error.message);
      });
  }



  return (
    <div className={classes.main}>
      <div className={classes.paper}>
        <CssBaseline />
        <h1 className={classes.TypographyLogin}>Log in</h1>
        <form className={classes.form} onSubmit={sendRequest.bind(this)}>
          <div>
            <span id="incorreto" style={{ color: "#b92d00", display: "none" }}>
              {" "}
              Usuário ou senha incorreto.
              <Alert className={classes.alert} />
            </span>
            <span id="desativado" style={{ color: "#b92d00", display: "none" }}>
              {" "}
              Usuário com conta desabilitada.
              <Alert className={classes.alert2} />
            </span>
          </div>
          <TextField
            className={classes.textField}
            margin="normal"
            required
            fullWidth
            id="email"
            label="E-mail"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={e => (email = e.target.value)}
          />
          <TextField
            className={classes.textField}
            margin="normal"
            required
            fullWidth
            maxLength="2"
            name="password"
            label="Senha"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={e => (password = e.target.value)}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Lembrar minha senha"
          />
          <div>
            <Button
              className={classes.submit}
              color="primary"
              type="submit"
              variant="contained"
            >
              ENTRAR
            </Button>
          </div>
          <div>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Redefinir senha</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Digite seu email que enviaremos um link para a recuperação de
                  sua senha.
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Email"
                  type="email"
                  fullWidth
                />
              </DialogContent>
              <DialogActions>
                <Buttons
                  onClick={handleClose}
                  tipo="BN-blue"
                  conteudo="Enviar"
                />
              </DialogActions>
            </Dialog>
          </div>
        </form>
      </div>
    </div>
  );
}
