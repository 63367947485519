import DateFnsUtils from "@date-io/date-fns";
import { Paper } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import Attachment from "@material-ui/icons/Attachment";
import Down from "@material-ui/icons/GetApp";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import axios from "axios";
import { useStyles } from "components/layout/CommonStyles";
import "components/profiles/css/FormStyle.css";
import "moment/locale/pt-br";
import React, { useEffect, useState } from 'react';
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const tooltipTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#2c3e51",
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: "white",
        fontSize: "1em",
        backgroundColor: "red",
        opacity: "0.7",
      },
    },
  },
});

const PhaseActivity = (props) => {
  const { setNewActivity, requiredFields, setRequiredFields, activity, mode } =
    props;
  const [t] = useTranslation();
  const [title, setTitle] = useState(t("GENERAL.addDocument"));
  const fileDownload = require("js-file-download");
  const [hasUpdatedFile, setHasUpdatedFile] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [state, setState] = useState({
    activityName: "",
    activityDescription: "",
    expectedResults: "",
    obtainedResults: "",
  });
  const [dates, setDates] = useState({
    activityStartDate: new Date(),
    activityEndDate: new Date(),
  });
  const classes = useStyles();

  useEffect(() => {
    if (activity) {
      setState({
        ...state,
        activityName: activity.name,
        activityDescription: activity.description,
        expectedResults: activity.expectedResults,
        obtainedResults: activity.obtainedResults,
      });
      var startdatewithtimezone = new Date(activity.startDate);
      startdatewithtimezone.setMinutes(
        startdatewithtimezone.getMinutes() +
          startdatewithtimezone.getTimezoneOffset()
      );
      var enddatewithtimezone = new Date(activity.endDate);
      enddatewithtimezone.setMinutes(
        enddatewithtimezone.getMinutes() +
          enddatewithtimezone.getTimezoneOffset()
      );
      setDates({
        activityStartDate: startdatewithtimezone,
        activityEndDate: enddatewithtimezone,
      });

      if (activity.file) {
        setTitle(activity.file.fileName);
      }

      setNewActivity({
        activityName: activity.name,
        activityDescription: activity.description,
        expectedResults: activity.expectedResults,
        obtainedResults: activity.obtainedResults,
        activityStartDate: startdatewithtimezone,
        activityEndDate: enddatewithtimezone,
        file: activity.file,
      });
    }
  }, []);

  useEffect(() => {
    if (requiredFields) {
      setOpenMessage(true);
      setRequiredFields(false);
    }
  }, [requiredFields]);

  const handleFieldChange = (name) => (event) => {
    setNewActivity(name, event.target.value);
    setState({ ...state, [name]: event.target.value });
  };

  const handleDates = (name) => (e) => {
    setNewActivity(name, e);
    setDates({ ...dates, [name]: e });
  };

  const clearRequiredMessage = () => {
    setOpenMessage(false);
  };

  const onDrop = (acceptedFiles) => {
    setNewActivity("file", acceptedFiles[0]);
    if (acceptedFiles[0] !== undefined) {
      setTitle(acceptedFiles[0].name);
      setHasUpdatedFile(true);
    } else {
      setTitle(t("GENERAL.invalidFormat"));
    }
  };

  const onClickDownload = async () => {
    if (activity && activity.file) {
      var noSlashPath = activity.file.path.replace(/\//g, ";");
      const url = process.env.REACT_APP_SERVER + "api/file/" + noSlashPath;
      axios
        .get(url, {
          headers: {
            "Content-type": "application/json",
            Authorization:
              "bearer " +
              (Cookies.get("auth-token") ? Cookies.get("auth-token") : ""),
          },
          responseType: "blob",
        })
        .then(function (res) {
          var blob = new Blob([res.data], {});
          fileDownload(blob, activity.file.fileName);
        });
    }
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        {t("ACTIVITIES.activity").toUpperCase()}
        {activity && activity.file && (
          <Tooltip
            title={t("GENERAL.downloadDocument")}
            aria-label="download"
            placement="top"
          >
            <div>
              <IconButton onClick={onClickDownload}>
                <Down />
              </IconButton>
            </div>
          </Tooltip>
        )}
      </div>
      <div className={classes.bodyForm}>
        <Grid container>
          <Grid container item spacing={2} md={12} lg={12}>
            <Grid item xs={5}>
              <MuiThemeProvider theme={tooltipTheme}>
                <Tooltip
                  TransitionComponent={Zoom}
                  open={openMessage}
                  title={t("GENERAL.requiredField")}
                >
                  <TextField
                    required
                    className={classes.textField}
                    margin="normal"
                    id="activityName"
                    label={t("ACTIVITIES.activityName")}
                    name="activityName"
                    value={state.activityName}
                    onChange={handleFieldChange("activityName")}
                    onFocus={clearRequiredMessage}
                    disabled={mode === t("GENERAL.viewMode")}
                  />
                </Tooltip>
              </MuiThemeProvider>
            </Grid>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item xs={2}>
                <KeyboardDatePicker
                  className={classes.textField}
                  disableToolbar
                  autoOk={true}
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="activityStartDate"
                  label={t("GENERAL.startDate")}
                  value={dates.activityStartDate}
                  onFocus={clearRequiredMessage}
                  onChange={handleDates("activityStartDate")}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  disabled={mode === t("GENERAL.viewMode")}
                />
              </Grid>
              <Grid item xs={2}>
                <KeyboardDatePicker
                  className={classes.textField}
                  disableToolbar
                  autoOk={true}
                  minDate={dates.activityStartDate}
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="activityEndDate"
                  label={t("GENERAL.endDate")}
                  value={dates.activityEndDate}
                  onFocus={clearRequiredMessage}
                  onChange={handleDates("activityEndDate")}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  minDateMessage={t("GENERAL.minDateMessage")}
                  disabled={mode === t("GENERAL.viewMode")}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <Grid item xs={3}>
              <Dropzone
                variant="outlined"
                component="span"
                className={classes.textField}
                onDrop={onDrop}
                accept="application/pdf, 
                                application/msword, 
                                application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              >
                {({
                  getRootProps,
                  getInputProps,
                  isDragActive,
                  isDragReject,
                }) => (
                  <div {...getRootProps()}>
                    <input
                      {...getInputProps()}
                      disabled={mode === t("GENERAL.viewMode")}
                    />
                    {!isDragActive && (
                      <FormControl
                        className={classes.customAttachedDocumentTextField}
                      >
                        <InputLabel>
                          {hasUpdatedFile ? t("PROJECTS.hasAttachedFile") : ""}
                        </InputLabel>
                        <Input
                          id="custom-attached-input-cooperation"
                          readOnly={true}
                          endAdornment={
                            <InputAdornment
                              style={{ transform: "rotate(270deg)" }}
                              position="end"
                            >
                              <Attachment />
                            </InputAdornment>
                          }
                          aria-describedby="weight-helper-text"
                          inputProps={{
                            "aria-label": "weight",
                          }}
                          value={t(title)}
                          disabled={mode === t("GENERAL.viewMode")}
                        />
                      </FormControl>
                    )}
                    {isDragReject && (
                      <FormControl
                        className={classes.customAttachedDocumentTextField}
                      >
                        <InputLabel>
                          {hasUpdatedFile ? t("PROJECTS.hasAttachedFile") : ""}
                        </InputLabel>
                        <Input
                          id="custom-attached-input-cooperation"
                          readOnly={true}
                          endAdornment={
                            <InputAdornment
                              style={{ transform: "rotate(270deg)" }}
                              position="end"
                            >
                              <Attachment />
                            </InputAdornment>
                          }
                          aria-describedby="weight-helper-text"
                          inputProps={{
                            "aria-label": "weight",
                          }}
                          value={t(title)}
                          disabled={mode === t("GENERAL.viewMode")}
                        />
                      </FormControl>
                    )}
                  </div>
                )}
              </Dropzone>
            </Grid>
          </Grid>
          <Grid container item spacing={2} md={12} lg={12}>
            <Grid item xs={12}>
              <TextField
                multiline
                fullWidth
                variant="outlined"
                rows="6"
                id="activityDescription"
                value={state.activityDescription}
                label={t("PROFILES.descriptionLabel")}
                margin="normal"
                onChange={handleFieldChange("activityDescription")}
                onFocus={clearRequiredMessage}
                disabled={mode === t("GENERAL.viewMode")}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={2} md={12} lg={12}>
            <Grid item xs={12}>
              <TextField
                multiline
                fullWidth
                variant="outlined"
                rows="6"
                id="expectedResults"
                value={state.expectedResults}
                label={t("ACTIVITIES.expectedResults")}
                margin="normal"
                onChange={handleFieldChange("expectedResults")}
                onFocus={clearRequiredMessage}
                disabled={mode === t("GENERAL.viewMode")}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={2} md={12} lg={12}>
            <Grid item xs={12}>
              <TextField
                multiline
                fullWidth
                variant="outlined"
                rows="6"
                id="obtainedResults"
                value={state.obtainedResults}
                label={t("ACTIVITIES.obtainedResults")}
                margin="normal"
                onChange={handleFieldChange("obtainedResults")}
                onFocus={clearRequiredMessage}
                disabled={mode === t("GENERAL.viewMode")}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};

export default PhaseActivity;