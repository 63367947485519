/* eslint-disable */
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import DehazeIcon from '@material-ui/icons/Dehaze';
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import SearchIcon from "@material-ui/icons/Search";
import Dissatisfied from "@material-ui/icons/SentimentVeryDissatisfied";
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import Footer from "components/layout/Footer";
import Spinner from "components/layout/spinners/Index";
import AllowancesContext from "context/allowance/allowanceContext";
import InventoryContext from "context/inventory/inventoryContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { animated, useTransition } from "react-spring";
import { useStyles } from "components/layout/CommonStyles";
import MySnackbarContentWrapper from "components/layout/Message";
import MyItensGrid from "./MyItensGrid";
import MyItensTable from "./MyItensTable";

const MyItens = () => {
    const classes = useStyles();
    const [t] = useTranslation();
    const inventoryContext = useContext(InventoryContext);
    const allowanceContext = useContext(AllowancesContext);
    const [placesArray, setPlacesArray] = useState([]);
    let dataFilter = [];
    const [snackBar, setSnackBar] = useState({
        open: false,
        kind: "",
        content: ""
    });
    const [mode, setMode] = useState("grid");
    const [filter, setFilter] = useState("");
    const [filter2, setFilter2] = useState("");
    const [filter3, setFilter3] = useState("");
    const [canClick, setCanClick] = useState(false);

    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }

    function setList() {
        setMode("list");
    }
    function setGrid() {
        setMode("grid");
    }
    const {
        myItens,
        mySoftwares,
        loading,
        response,
        hasChanged,
        setHasChanged,
        responseType,
        successiveActions,
        places,
        getInfoMyItems,
    } = inventoryContext;

    const {
        allowanceResponse,
        allowanceResponseType,
        messageHasChanged,
        resetAllowanceMessage,
    } = allowanceContext;

    const clearFilterLocationType = () => {
        dataFilter = myItens.filter(item => item.class !== "library.Book" && item.category !== "Livro").concat(mySoftwares);
        dataFilter = dataFilter.filter(
            item =>
                item.name.toLowerCase().startsWith(filter.toLowerCase())
        );
    }

    dataFilter = myItens.filter(item => item.class !== "library.Book" && item.category !== "Livro").concat(mySoftwares);

    dataFilter = dataFilter.map(function (num) {
        if (num.ufcCode) num.ufcCodeF = `UFC-${num.ufcCode}`
        if (num.fcpcCode) num.fcpcCodeF = `FCPC-${num.fcpcCode}`
        if (num.lsbdCode) num.lsbdCodeF = `LSBD-${num.lsbdCode}`
        return num;
    });
    if (filter) {

        dataFilter = dataFilter.filter(
            item =>
                item.name.toLowerCase().startsWith(filter.toLowerCase())
        );

    }

    if (filter2) {
        dataFilter = dataFilter.filter(
            item =>
                item.fcpcCodeF && ("FCPC-" + item.fcpcCodeF.toString()).toLowerCase().includes(filter2.toLowerCase()) ||
                item.ufcCodeF && ("UFC-" + item.ufcCodeF.toString()).toLowerCase().includes(filter2.toLowerCase()) ||
                item.lsbdCodeF && ("LSBD-" + item.lsbdCodeF.toString()).toLowerCase().includes(filter2.toLowerCase()) ||
                item.serialNumber && item.serialNumber.toLowerCase().startsWith(filter2.toLowerCase()) ||
                item.libraryExemplar && item.libraryExemplar.length > 0 && ("BIB-00" + item.libraryExemplar[0].exemplarCode.toString()).toLowerCase().includes(filter2.toLowerCase())
        );
    }

    if (filter3) {
        dataFilter = dataFilter.filter(
            item =>
                filter3.toLowerCase() !== "todos os locais".toLowerCase() ?
                    item.place && item.place.toLowerCase().startsWith(filter3.toLowerCase())
                    :
                    item
        );
    }
    const hasItens = myItens.filter(item => item.class !== "library.Book" && item.category !== "Livro").length > 0 || mySoftwares.length > 0;
    const [hasNoResult, setHasNoResult] = useState(hasItens && dataFilter.length === 0);
    const [itensTable, setItensTable] = useState(
        // renderTable(dataFilter)
    );
    const transitions = useTransition(loading, null, {
        from: { opacity: 0 },
        enter: {
            opacity: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "calc(100vh - 70px)"
        },
        leave: { opacity: 0 }
    });

    useEffect(() => {
        if (auth) {
            getInfoMyItems(auth.id);
        };
    }, []);

    useEffect(() => {
        const myPlaces = [...places];
        myPlaces.sort(function (a, b) {
            if (a.name === null) a.name = "";
            if (b.name === null) b.name = "";

            if (a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() >
                b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim()) {
                return 1;
            }
            if (a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() <
                b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim()) {
                return -1;
            }
            // a must be equal to b
            return 0;
        });

        var aux = {};
        var allPlaces = {};
        var _places = [];
        allPlaces.value = "all";
        allPlaces.label = "Todos os locais";

        _places.push(allPlaces);
        for (var i = 0; i < myPlaces.length; i++) {
            var item = myPlaces[i];
            aux.value = item.id;
            aux.label = item.name;
            _places.push(aux);
            aux = {};
        }
        setPlacesArray(_places);
    }, [places]);

    useEffect(() => {
        if (allowanceResponseType === "ERROR" && messageHasChanged) {
            handleSnack({ kind: "error", content: allowanceResponse });
        }
        if (responseType === "SUCCESS" && hasChanged > successiveActions) {
            handleSnack({ kind: "success", content: response });
        }
        if (responseType === "ERROR" && hasChanged > successiveActions) {
            handleSnack({ kind: "error", content: response });
        }

        return () => { };
    }, [
        responseType,
        response,
        successiveActions,
        allowanceResponseType,
        allowanceResponse,
    ]);

    const handleSnack = () => {
        setSnackBar({ ...snackBar, open: true });
        setHasChanged();
    };
    const handleSnackClose = () => {
        setSnackBar({ ...snackBar, open: false });
        resetAllowanceMessage();
    };

    const handleSearch = event => {
        setFilter(event.target.value);
        clearFilterLocationType();

        dataFilter = dataFilter.filter(
            item =>
                item.name.toLowerCase().includes(event.target.value.toLowerCase())
        );


        if (dataFilter.length === 0)
            setHasNoResult(true);
        else
            setHasNoResult(false);
        //setItensTable(renderTable(dataFilter));
    };

    const handleSearch2 = event => {
        setFilter2(event.target.value);
        clearFilterLocationType();


        dataFilter = dataFilter.filter(
            item =>
                item.fcpcCodeF && ("FCPC-" + item.fcpcCodeF.toString()).toLowerCase().includes(filter2.toLowerCase()) ||
                item.ufcCodeF && ("UFC-" + item.ufcCodeF.toString()).toLowerCase().includes(filter2.toLowerCase()) ||
                item.lsbdCodeF && ("LSBD-" + item.lsbdCodeF.toString()).toLowerCase().includes(filter2.toLowerCase()) ||
                item.serialNumber && item.serialNumber.toLowerCase().startsWith(filter2.toLowerCase()) ||
                item.libraryExemplar && item.libraryExemplar.length > 0 && ("BIB-00" + item.libraryExemplar[0].exemplarCode.toString()).toLowerCase().includes(filter2.toLowerCase())
        );

        if (dataFilter.length === 0)
            setHasNoResult(true);
        else
            setHasNoResult(false);

        //setItensTable(renderTable(dataFilter));
    };

    useEffect(() => {
        if (filter || filter2) {
            setCanClick(true);
        } else {
            setCanClick(false);
        }

    }, [filter, filter2])

    const handleSearch3 = event => {
        setFilter3(event.target.value);

        if (filter) {
            dataFilter = dataFilter.filter(
                item =>
                    item.name.toLowerCase().startsWith(filter.toLowerCase())
            );
        }

        if (filter2) {
            dataFilter = dataFilter.filter(
                item =>
                    item.fcpcCodeF && item.fcpcCodeF.toLowerCase().startsWith(filter2.toLowerCase()) ||
                    item.ufcCodeF && item.ufcCodeF.toLowerCase().startsWith(filter2.toLowerCase()) ||
                    item.lsbdCodeF && item.lsbdCodeF.toLowerCase().startsWith(filter2.toLowerCase()) ||
                    item.serialNumber && item.serialNumber.toLowerCase().startsWith(filter2.toLowerCase()) ||
                    item.libraryExemplar && item.libraryExemplar.length > 0 && ("BIB-00" + item.libraryExemplar[0].exemplarCode.toString()).toLowerCase().includes(filter2.toLowerCase())
            );
        }

        if (event.target.value.toLowerCase() !== "todos os locais".toLowerCase()) {
            if (dataFilter.length === 0) {
                setHasNoResult(true);
            }
            else {
                setHasNoResult(false);
            }
        }
        //setItensTable(renderTable(dataFilter));
    };

    return transitions.map(({ item, key, props }) =>
        item ? (
            <animated.div key={key} style={props}>
                <Spinner />;
            </animated.div>
        ) : (
            <animated.div key={key} style={props}>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={snackBar.open}
                    onClose={handleSnackClose}
                    TransitionComponent={Slide}
                    transitionDuration={{ enter: 500, exit: 500 }}
                    ContentProps={{
                        "aria-describedby": "message-id"
                    }}
                    autoHideDuration={2000}
                >
                    <MySnackbarContentWrapper
                        onClose={handleSnackClose}
                        variant={
                            responseType !== ""
                                ? responseType.toLowerCase()
                                : allowanceResponseType.toLowerCase()
                        }
                        message={
                            <span id="message-id">
                                {response !== "" ? response : allowanceResponse}
                            </span>
                        }
                    />
                </Snackbar>
                <div className="container">
                    <Breadcrumbs
                        className={classes.breadCrumbs}
                        aria-label="breadcrumb"
                        separator={<NavigateNextIcon fontSize="small" />}
                    >
                        <Typography>{t("INVENTORY.inventory")}</Typography>
                        <Typography>{t("INVENTORY.myItens")}</Typography>
                    </Breadcrumbs>

                    <div className="container-header">
                        <div className="title">
                            <h1 className={classes.title}>{t("INVENTORY.myItens")}</h1>
                        </div>
                    </div>
                    <Paper >
                        <div className="cabecalho">
                            {t("INVENTORY.itens").toUpperCase()}
                            <span style={{ float: "right", marginRight: 10 }}>
                                <span style={{ float: "right", marginRight: 10 }}>GRADE
                                    <IconButton className="btn" style={{ float: "right" }} edge="end" color="inherit" onClick={setGrid} >
                                        <ViewModuleIcon />
                                    </IconButton>
                                </span>
                                <span style={{ float: "right", marginRight: 10 }}>TABELA
                                    <IconButton className="btnActive" style={{ float: "right" }} edge="end" color="inherit" onClick={setList} >
                                        <DehazeIcon />
                                    </IconButton>
                                </span>
                            </span>
                        </div>
                        <div className={classes.divAddParticipantLeft}>
                            <Toolbar className={classes.searchToolbarInventory}>
                                <div style={{ display: !hasItens ? "none" : "" }} className={classes.searchInventory}>
                                    <div className={classes.searchIcon}>
                                        <SearchIcon />
                                    </div>
                                    <InputBase
                                        placeholder={t("INVENTORY.name")}
                                        value={filter}
                                        onChange={handleSearch}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputInput
                                        }}
                                        inputProps={{ "aria-label": "search" }}
                                    />
                                </div>
                            </Toolbar>
                            <Toolbar className={classes.searchToolbarInventory} >
                                <div style={{ display: !hasItens ? "none" : "" }} className={classes.searchInventory}>
                                    <div className={classes.searchIcon}>
                                        <SearchIcon />
                                    </div>
                                    <InputBase
                                        placeholder={t("INVENTORY.search")}
                                        value={filter2}
                                        onChange={handleSearch2}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputInput
                                        }}
                                        inputProps={{ "aria-label": "search" }}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                            </Toolbar>
                            <Toolbar className={classes.searchToolbarInventory} >
                                <div style={{ display: !hasItens ? "none" : "" }} className={classes.searchInventory} >
                                    <TextField
                                        select
                                        id="client"
                                        label={t("INVENTORY.place")}
                                        name="Place"
                                        style={{ minWidth: "250px", marginTop: 0, marginBottom: 0, marginLeft: 5 }}
                                        value={filter3}
                                        className={classes.textField}
                                        onChange={handleSearch3}
                                        margin="normal"
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputInput
                                        }}
                                        inputProps={{ "aria-label": "search" }}
                                    >
                                        {placesArray.map(places => (
                                            <MenuItem key={places.value} value={places.label}>
                                                {places.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            </Toolbar>
                        </div>
                        {mode === "list" ?
                            <MyItensTable
                                rows={dataFilter}
                                canClick={canClick}
                                headers={["id", "name", "lsbdCodeF", "fcpcCodeF", "ufcCodeF", "lsbdCode", "fcpcCode", "ufcCode", "place", "isbn", "brand", "externalCode", "serialNumber", "class", "acquisitionDate", "category", "fileUserExternal", "description", "lastMaintenance", "warrantyData", "libraryExemplar", "project", "invoice", "value", "software", "key", "email", "codigo", "machine", "itemStatus", "price"]}
                                visibleHeaders={["nome", "código de identificação", "local", ""]}
                                sortedFields={["name", "ufcCodeF", "place"]}
                                mainField="name"
                            />
                            :
                            <MyItensGrid
                                data={dataFilter}
                                canClick={canClick}
                                headers={["id", "name", "lsbdCodeF", "fcpcCodeF", "ufcCodeF", "place", "isbn", "brand", "externalCode", "serialNumber", "class", "acquisitionDate", "category", "fileUserExternal", "description", "lastMaintenance", "warrantyData", "libraryExemplar", "project", "invoice", "value", "software", "key", "email", "codigo", "machine", "price"]}
                                visibleHeaders={["nome", "código de identificação", "local"]}
                                sortedFields={["name", "ufcCodeF", "place"]}
                                mainField="name"
                            />
                        }
                        {hasItens ? itensTable :
                            <div className={classes.noParticipantsMessage}>
                                <h2>{t("INVENTORY.noItensMessage")}</h2>
                                <h4>{t("INVENTORY.noItensSubMessage")}</h4>
                            </div>
                        }
                        {dataFilter.length === 0 && filter ?
                            <div className={classes.noParticipantsMessage}>
                                <div >
                                    <Dissatisfied className={classes.notFoundIconStyle} />
                                </div>
                                <h2>{t("GENERAL.noResultsFound")}</h2>
                                <h5>{`Sua busca por "${filter}" não retornou resultados.`}</h5>
                            </div>
                            : dataFilter.length === 0 && filter2 ?
                                <div className={classes.noParticipantsMessage}>
                                    <div >
                                        <Dissatisfied className={classes.notFoundIconStyle} />
                                    </div>
                                    <h2>{t("GENERAL.noResultsFound")}</h2>
                                    <h5>{`Sua busca por "${filter2}" não retornou resultados.`}</h5>
                                </div>
                                : dataFilter.length === 0 && filter3 && filter3.toLowerCase() !== "todos os locais" &&
                                <div className={classes.noParticipantsMessage}>
                                    <div >
                                        <Dissatisfied className={classes.notFoundIconStyle} />
                                    </div>
                                    <h2>{t("GENERAL.noResultsFound")}</h2>
                                    <h5>{`Sua busca por "${filter3}" não retornou resultados.`}</h5>
                                </div>
                        }
                    </Paper>
                </div>
                <Footer />
            </animated.div>
        )
    );
};

export default MyItens;
