import DateFnsUtils from "@date-io/date-fns";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Clear from '@material-ui/icons/Clear';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import axios from "axios";
import Buttons from "components/buttons/Pressed";
import { useStyles } from "components/layout/CommonStyles";
import React, { useState } from 'react';
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const MaintenanceDialog = (props) => {
    const fileDownload = require("js-file-download");
    const [t] = useTranslation();
    const classes = useStyles();
    const { openDetails, setOpenDetails, historyItem, type, itemId } = props;

    const [values, setValues] = useState({
        itemId: itemId,
        date: historyItem.date,
        operation: historyItem && historyItem.operation,
        description: historyItem.description,
        newValue: historyItem.newValue,
        operator: historyItem.executor

    });

    const handleClose = () => {
        setOpenDetails(false);
    };

    const [toDeleteFile, setToDeleteFile] = useState([]);

    const onDropFiles = acceptedFiles => {
        let newFiles = [];
        let existentFiles = [];

        for (let i = 0; i < acceptedFiles.length; i++) {
            let hasTheSameName = dataState.files.filter(el => (el.fileName ? el.fileName.toLowerCase() : el.name.toLowerCase()) ===
                acceptedFiles[i].name.toLowerCase()).length > 0;

            if (hasTheSameName) {
                existentFiles.push(`Arquivo já existente - ${acceptedFiles[i].name}`);
            }
            else {
                newFiles.push(acceptedFiles[i])
            }
        }

        setDataState({
            ...dataState,
            files: dataState.files.concat(newFiles),
            rejectedFiles: dataState.rejectedFiles.concat(existentFiles)
        });
    }

    const onRemoveFile = (data) => {
        if (data) {
            let remainingFiles = [];
            let updatedList = [];
            for (const file of dataState.files) {
                if ((file.path === data.path) && file.lastModified === data.lastModified && file.size === data.size) {
                    if (file.id) {
                        setToDeleteFile([...toDeleteFile, file.id]);
                        updatedList = dataState.rejectedFiles.filter(el => el !== `Arquivo já existente - ${data.fileName}`);
                    }
                    continue;
                }
                remainingFiles.push(file)
            }
            setDataState({ ...dataState, files: remainingFiles, rejectedFiles: updatedList })
        }
    };

    function humanFileSize(size) {
        let i = Math.floor(Math.log(size) / Math.log(1024));
        return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    };

    const deleteRejectedFile = rejectedFile => {
        let updatedList = dataState.rejectedFiles.filter(el => el !== rejectedFile);
        setDataState({ ...dataState, rejectedFiles: updatedList });
    };

    const onClickDownload = async (file) => {
        if (dataState && dataState.files.length > 0) {
            const noSlashPath = file.path.replace(/\//g, ";");
            const url = process.env.REACT_APP_SERVER + "api/file/" + noSlashPath;
            axios.get(url, {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
                },
                responseType: "blob"
            })
                .then(function (res) {
                    const blob = new Blob([res.data], {});
                    const name = file.fileName ? file.fileName : file.name;
                    fileDownload(blob, name);
                });
        }
    };

    const [dataState, setDataState] = useState({
        files: [],
        rejectedFiles: []
    });

    return (
        <Dialog
            open={openDetails}
            onClose={handleClose}
            fullWidth={true}
            aria-labelledby="max-width-dialog-title"
            aria-labelledby="form-dialog-title"
            msg="mensagem"
            maxWidth="sm"
        >
            <DialogTitle className={classes.actionsTitle} style={{ width: "auto" }}>
                <span style={{ fontWeight: "bold" }}>
                    {t("INVENTORY.HistoryOperationDetail").toLocaleUpperCase()}
                </span>
                <IconButton
                    style={{ float: "right", marginTop: -10, marginRight: -17 }}
                    edge="end"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Grid container spacing={3} >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid item xs={12} sm={6}>
                                <KeyboardDatePicker
                                    disabled
                                    className={classes.textField}
                                    disableToolbar
                                    autoOk={true}
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    id="startDate"
                                    margin="normal"
                                    invalidDateMessage=""
                                    label={t("INVENTORY.historyDate")}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date"
                                    }}
                                    value={values.date}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                disabled
                                className={classes.textField}
                                label={t("INVENTORY.HistoryOperation")}
                                value={values.operation}
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                disabled
                                className={classes.textField}
                                label={t("INVENTORY.previousValue")}
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                disabled
                                className={classes.textField}
                                label={t("INVENTORY.newValue")}
                                value={values.newValue}
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                disabled
                                className={classes.textField}
                                label={t("INVENTORY.operator")}
                                value={values.operator}
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                disabled
                                multiline
                                fullWidth
                                variant="outlined"
                                rows="6"
                                id="goal"
                                value={values.description}
                                label={t("INVENTORY.justificationLabel")}
                                margin="normal"
                            />
                        </Grid>
                        <Grid container item spacing={1} md={12} lg={12}>
                            {type === "edit" &&
                                <Grid item xs={12}>
                                    <Dropzone onDrop={onDropFiles}
                                        disabled
                                        accept="application/pdf, 
                          application/msword, 
                          application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                          image/jpeg, 
                          image/png"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section>
                                                <div {...getRootProps({ className: classes.dropzoneDialog })}>
                                                    <input {...getInputProps()} />
                                                    <AttachFileIcon />
                                                    <Typography>Arraste e solte os documentos para anexá-los ou <u>clique aqui</u></Typography>
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                </Grid>}
                            <Grid item xs={12}>
                                <Typography variant={"body1"} style={{ fontWeight: "bold" }}>Documentos</Typography>
                                {dataState.rejectedFiles.length > 0 &&
                                    <div style={{ paddingBottom: 8 }}>
                                        {dataState.rejectedFiles.map(message => (
                                            <div className={classes.dialogFile}>
                                                <div className={classes.textFile}>
                                                    <Typography style={{ color: "red" }} className={classes.nameFile}>{message}</Typography>
                                                </div>
                                                <div className={classes.iconsFile}>
                                                    <IconButton onClick={() => deleteRejectedFile(message)}>
                                                        <Clear />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                }
                                {dataState.files.length > 0 ?
                                    <div className={classes.dropzoneDialogFiles}>
                                        {dataState.files.map(file => (
                                            <div className={classes.dialogFile}>
                                                <div className={classes.textFile}>
                                                    <Typography className={classes.nameFile}>{file.fileName ? file.fileName : file.name}</Typography>
                                                    <Typography>{`(${humanFileSize(file.size)})`}</Typography>
                                                </div>
                                                <div className={classes.iconsFile}>
                                                    {file.fileName && <IconButton onClick={() => onClickDownload(file)}>
                                                        <GetAppIcon />
                                                    </IconButton>}
                                                    <IconButton onClick={() => onRemoveFile(file)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        ))}
                                    </div> :
                                    <div className={classes.noFilesMessage}>
                                        <Typography variant={"h6"} style={{ fontWeight: "bold" }}>{t("EXPENSES.noFilesMessage")}</Typography>
                                        <Typography variant={"caption"}>{t("EXPENSES.noFilesSubMessage")}</Typography>
                                    </div>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Buttons
                    onClick={handleClose}
                    tipo="BN-noback"
                    conteudo={t("GENERAL.close").toLocaleUpperCase()}
                />
            </DialogActions>
        </Dialog>
    );
}

export default MaintenanceDialog;