import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import { useStyles } from "components/layout/CommonStyles";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Buttons from "../../buttons/Pressed";
import ProjectsContext from "context/projects/projectsContext";
import history from "../../../history";
import CloseIcon from '@material-ui/icons/Close';

const TableFeedbacksActions = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const { mode, feedbackId, participationId, userId, projectId, participantMode } = props;
    const [open, setOpen] = useState(false);
    const projectsContext = useContext(ProjectsContext);
    const { deleteParticipantFeedback } = projectsContext;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        setOpen(false);
        deleteParticipantFeedback(feedbackId, participationId);
    };

    const verifyEdit = () => {
        history.push(`/execution/participant/${mode}/${participationId}/${userId}/${projectId}/${participantMode}/${feedbackId}/edit`);
    }

    const verifyView = () => {
        history.push(`/execution/participant/${mode}/${participationId}/${userId}/${projectId}/${participantMode}/${feedbackId}/view`);
    }

    return (
        <div>
            <Tooltip title='Visualizar' aria-label='details' placement='top'>
                <IconButton className={classes.actionsIcons} onClick={verifyView}>
                    <Visibility />
                </IconButton>
            </Tooltip>
            {participantMode === t("GENERAL.editMode") &&
                <Tooltip title='Editar' aria-label='details' placement='top'>
                    <IconButton className={classes.actionsIcons} onClick={verifyEdit}>
                        <Edit />
                    </IconButton>
                </Tooltip>
            }
            {participantMode === t("GENERAL.editMode") &&
                <Tooltip title="Deletar" aria-label="delete" placement="top">
                    <IconButton className={classes.actionsIcons} onClick={handleClickOpen}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            }
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                msg="mensagem"
            >
                <DialogTitle className={classes.actionsTitle}>
                    {t("GENERAL.notification")}
                    <IconButton style={{float:"right", marginTop: -10, marginRight: -17}} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t("PARTICIPANTS.deleteFeedbackQuestion")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Buttons
                        onClick={handleClose}
                        tipo="BN-noback"
                        conteudo={t("GENERAL.cancel")}
                    />
                    <Buttons
                        onClick={handleDelete}
                        tipo="BN-blue"
                        conteudo={t("GENERAL.delete")}
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default TableFeedbacksActions;