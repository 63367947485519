/* eslint-disable */
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import React, { useEffect, useState } from 'react';
import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';
import { Screen, Allow } from 'global/constants';
import SidebarListItem from './SidebarListItem';

const HrSideBarConfigMenu = () => {
    const [displayMenu, setDisplayMenu] = useState(false);
    const [displayMenuVacations, setDisplayMenuVacations] = useState(false);
    const [displayMenuClockRecord, setDisplayMenuClockRecord] = useState(false);
    const [arrowClockRecord, setArrowClockRecord] = useState(false);
    const [arrowVacations, setArrowVacations] = useState(false);
    const [arrowConfig, setArrowConfig] = useState(false);

    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const handleMouseEvent = () => {
        setDisplayMenu(!displayMenu);
        setArrowConfig(!arrowConfig);
    };

    const handleMouseEventVacations = () => {
        setDisplayMenuVacations(!displayMenuVacations);
        setArrowVacations(!arrowVacations);
    };

    const handleMouseEventClockRecord = () => {
        setDisplayMenuClockRecord(!displayMenuClockRecord);
        setArrowClockRecord(!arrowClockRecord);
    };

    useEffect(() => {
        return () => {
            setDisplayMenu(false);
            setDisplayMenuVacations(false);
            setDisplayMenuClockRecord(false);
            setArrowConfig(false);
            setArrowVacations(false);
            setArrowClockRecord(false);
        };
    }, []);

    // const getApproverPermission = () => {
    //   if (localStorage.getItem("auth-token") !== null) {
    //     var auth = JSON.parse(localStorage.getItem("auth-token"));
    //     return auth.approver;
    //   }
    // }

    const getPermissionHR = screen => {
        if (localStorage.getItem('auth-token') !== null) {
            const permission = auth.permissionsByModule;

            // Get the user profile rule for the people management module
            const peopleManagement = permission.filter(
                el => el.systemId == 6905701
            );
            var role = peopleManagement[0] ? peopleManagement[0].roleId : 0;

            if (
                screen == 'collaborators' ||
                screen === 'collaboratorsVacations'
            ) {
                if (
                    role === 6905901 ||
                    role === 6905151 ||
                    role === 6905801 ||
                    role === 6905201
                ) {
                    return true;
                } else {
                    return false;
                }
            } else if (screen === 'vacationCalendar') {
                if (role === 6905901) {
                    return true;
                } else {
                    return false;
                }
            } else if (screen == 'settings') {
                if (role === 6905901 || role === 6905151 || role === 6905801) {
                    return true;
                } else {
                    return false;
                }
            } else if (screen == 'holidaysAndBreaks') {
                if (role === 6905901 || role === 6905151) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    };
    const GetAtLeastOneConfigScreen = () => {
        if (localStorage.getItem('auth-token') !== null) {
            var auth = JSON.parse(localStorage.getItem('auth-token'));

            return (
                GetGeneralPermissionForAction(Screen.JOBS, Allow.READ) ||
                GetGeneralPermissionForAction(
                    Screen.HOLIDAYS_AND_BREAKS,
                    Allow.READ
                ) ||
                GetGeneralPermissionForAction(
                    Screen.ALLOWANCE_REASON,
                    Allow.READ
                ) ||
                GetGeneralPermissionForAction(
                    Screen.ACTIVITY_CATEGORIES,
                    Allow.READ
                )
            );
        }
    };

    const GetAtLeastOneVacationsScreen = () => {
        if (localStorage.getItem('auth-token') !== null) {
            var auth = JSON.parse(localStorage.getItem('auth-token'));
            const configScreens = [
                Screen.MY_VACATIONS,
                Screen.VACATION_CALENDAR,
                Screen.COLLABORATORS_VACATIONS
            ];

            return (
                GetGeneralPermissionForAction(
                    Screen.MY_VACATIONS,
                    Allow.READ
                ) ||
                GetGeneralPermissionForAction(
                    Screen.VACATION_CALENDAR,
                    Allow.READ
                ) ||
                GetGeneralPermissionForAction(
                    Screen.COLLABORATORS_VACATIONS,
                    Allow.READ
                )
            );
        }
    };

    const GetAtLeastOneClockRecordScreen = () => {
        if (localStorage.getItem('auth-token') !== null) {
            var auth = JSON.parse(localStorage.getItem('auth-token'));

            return (
                GetGeneralPermissionForAction(
                    Screen.MY_CLOCK_RECORD,
                    Allow.READ
                ) ||
                GetGeneralPermissionForAction(
                    Screen.COLLABORATORS_CLOCK_RECORDS,
                    Allow.READ
                ) ||
                GetGeneralPermissionForAction(
                    Screen.MY_JUSTIFICATIONS,
                    Allow.READ
                )
            );
        }
    };

    return (
        <div style={{ marginTop: '10px' }}>
            <div className="dropdown">
                <ul style={{ display: 'table-cell', marginLeft: '5px' }}>
                    {GetGeneralPermissionForAction(Screen.MY_INFORMATIONS, Allow.READ) && (<SidebarListItem to={'/myInformations'} text="Minhas Informações" />)}
                    {GetGeneralPermissionForAction(Screen.COLLABORATORS, Allow.READ) && (<SidebarListItem to={'/Collaborators'} text="Colaboradores" />)}
                    {GetGeneralPermissionForAction(Screen.BIRTHDAYS, Allow.READ) && (<SidebarListItem to={'/Birthdays'} text="Aniversariantes" />)}
                </ul>
            </div>
            {/* ClockRecord Menu */}
            {GetAtLeastOneClockRecordScreen() && (
                <div className="dropdown">
                    <div className="divConfig" onClick={handleMouseEventClockRecord}>
                        Pontos
                        {!arrowClockRecord && <KeyboardArrowRight />}
                        {arrowClockRecord && <KeyboardArrowDown />}
                    </div>

                    {displayMenuClockRecord && (
                        <ul style={{ display: 'table-cell', marginLeft: '5px' }}>
                            {GetGeneralPermissionForAction(Screen.MY_CLOCK_RECORD, Allow.READ) && (<SidebarListItem to={'/MyClockRecord'} text="Meus Pontos" />)}
                            {GetGeneralPermissionForAction(Screen.MY_JUSTIFICATIONS, Allow.READ) && (<SidebarListItem to={'/MyJustifications'} text="Minhas Justificativas" />)}
                            {GetGeneralPermissionForAction(Screen.COLLABORATORS_CLOCK_RECORDS, Allow.READ) && (<SidebarListItem to={'/CollaboratorsClockRecords'} text="Pontos dos Colaboradores" />)}
                            {GetGeneralPermissionForAction(Screen.COLLABORATORS_JUSTIFICATIONS, Allow.READ) && (<SidebarListItem to={'/CollaboratorsJustifications'} text="Justificativas dos Colaboradores" />)}
                        </ul>
                    )}
                </div>
            )}
            {/* Vacation Menu */}
            {GetAtLeastOneVacationsScreen() && (
                <div className="dropdown">
                    <div className="divConfig" onClick={handleMouseEventVacations}>
                        Férias
                        {!arrowVacations && <KeyboardArrowRight />}
                        {arrowVacations && <KeyboardArrowDown />}
                    </div>

                    {displayMenuVacations && (
                        <ul style={{ display: 'table-cell', marginLeft: '5px' }}>
                            {GetGeneralPermissionForAction(Screen.MY_VACATIONS, Allow.READ) && (<SidebarListItem to={'/myVacations'} text="Minhas Férias" />)}
                            {GetGeneralPermissionForAction(Screen.COLLABORATORS_VACATIONS, Allow.READ) && (<SidebarListItem to={'/CollaboratorsVacations'} text="Férias de Colaboradores" />)}
                            {GetGeneralPermissionForAction(Screen.VACATION_CALENDAR, Allow.READ) && (<SidebarListItem to={'/VacationCalendar'} text="Calendário de Férias" />)}
                        </ul>
                    )}
                </div>
            )}
            {/* Settings Menu */}
            {GetAtLeastOneConfigScreen() && (
                <div className="dropdown">
                    <div className="divConfig" onClick={handleMouseEvent}>
                        Configurações
                        {!arrowConfig && <KeyboardArrowRight />}
                        {arrowConfig && <KeyboardArrowDown />}
                    </div>

                    {displayMenu && (
                        <ul style={{ display: 'table-cell', marginLeft: '5px' }}>
                            {GetGeneralPermissionForAction(Screen.JOBS, Allow.READ) && (<SidebarListItem to={'/Jobs'} text="Cargos" />)}
                            {GetGeneralPermissionForAction(Screen.ACTIVITY_CATEGORIES, Allow.READ) && (<SidebarListItem to={'/ActivityCategories'} text="Categorias de Atividades" />)}
                            {GetGeneralPermissionForAction(Screen.HOLIDAYS_AND_BREAKS, Allow.READ) && (<SidebarListItem to={'/HolidaysAndBreaks'} text="Feriados e Recessos" />)}
                            {GetGeneralPermissionForAction(Screen.ALLOWANCE_REASON, Allow.READ) && (<SidebarListItem to={'/AllowanceReason'} text="Motivo de Abono" />)}
                        </ul>
                    )}
                </div>
            )}
        </div>
    );
};

export default HrSideBarConfigMenu;
