import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Visibility from "@material-ui/icons/Visibility";
import ProfilesContext from "context/profiles/profilesContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Buttons from "components/buttons/Pressed";
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import TextField from "@material-ui/core/TextField";
import history from "../../../history";
import { getOpenRequestPermission } from "../../../utils/permissions/OpenRequestPermission";
import { GetGeneralPermissionForAction } from "utils/permissions/GetGeneralPermissionForAction";
import { Screen, Allow } from "global/constants";



const useStyles = makeStyles(theme => ({
  icons: {
    opacity: "0"
  },
  root: {
    flexGrow: 1,
  },
  iconReq: {
    marginLeft: "10px",
    opacity: "0",
    backgroundColor: "#6D6F71",
    color: "#FFFFFF;"
  },
  Title: {
    fontFamily: "Roboto",
    fontSize: "30px",
    textAlign: "left",
    color: "#000000",
    fontWeight: "bold"
  },
  image: {
    width: 180,
    height: 180,
  },
  img: {
    margin: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  backgroundImage: {
  },
  title: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "16px",
    color: "#000000",
    opacity: "0.54",
  },
  codeId: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    color: "##6D6F71",
  },
  btn: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#2D9CDB"
  },
  textField: {
    display: "flex",
  },
  textFieldCustum: {
    display: "flex",
    marginLeft: "10px"
  },
}));

const MyItensTableActions = props => {
  const classes = useStyles();
  const [t] = useTranslation();
  const profilesContext = useContext(ProfilesContext);
  const { deleteProfile, response, responseType } = profilesContext;
  const [open, setOpen] = useState(false);
  const { item } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    setOpen(false);
  };

  const handleOpenRequisitionClick = () => {
    setOpen(false);
    history.push("Requisition/new");

  };
  useEffect(() => {
    return () => { };
  }, [response, responseType]);

  function dateToEN(date) {
    var dateAcquisition = new Date(date);
    dateAcquisition.setMinutes(dateAcquisition.getMinutes() + dateAcquisition.getTimezoneOffset())
    return dateAcquisition.getDate() + "/" + (dateAcquisition.getMonth() + 1) + "/" + dateAcquisition.getFullYear();
  }

  const getPermissionInventory = () => {
    if (localStorage.getItem("auth-token") !== null) {
      var auth = JSON.parse(localStorage.getItem("auth-token"));
      const permission = auth.profile;
      if (permission === "Colaborador" || permission === "Pesquisador" || permission === "Discente/Associado" || permission === "Usuario") {
        return false;
      } else {
        return true;
      }
    }
  }

  return item.id !== 1 ? (
    <React.Fragment>
      <Tooltip title={t("GENERAL.view")} aria-label="details" placement="top">
        <IconButton className={classes.icons} onClick={handleClickOpen} >
          <Visibility />
        </IconButton>
      </Tooltip>
      {!GetGeneralPermissionForAction(Screen.MY_REQUISITIONS, Allow.READ) && item.class !== "library.Book" &&
        <Button onClick={handleOpenRequisitionClick} className={classes.iconReq} >
          {t("INVENTORY.openRequest")}
        </Button>
      }

      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        msg="mensagem"
      >
        <DialogTitle className={classes.Title}>
          {t("INVENTORY.itemDetails")}
          <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div className={classes.root}>
              <Grid container spacing={3}>
                <Grid item xs={3} className={classes.backgroundImage}>
                  <ButtonBase className={classes.imageView}>
                    <img className={classes.imgView} alt="item" src={window.location.origin + '/img/noImageItem.png'} />
                  </ButtonBase>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Typography className={classes.title}>
                    Definição
                  </Typography>
                  <TextField
                    disabled
                    className={classes.textField}
                    label={t("INVENTORY.nameOfItem")}
                    value={item.name ? item.name : "-"}
                    margin="normal"
                  />
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        disabled
                        className={classes.textField}
                        label={t("INVENTORY.brand")}
                        value={item.brand ? item.brand : "-"}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        disabled
                        className={classes.textFieldCustum}
                        label={t("INVENTORY.category")}
                        value={item.category ? item.category : "-"}
                        margin="normal"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography className={classes.title}>
                    Identificação
                  </Typography>
                </Grid>
                {item.class === "library.Book" || item.category && item.category === "Livro" ?
                  <Grid container>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        disabled
                        className={classes.textFieldCustum}
                        label={t("INVENTORY.copyCode")}
                        value={
                          (item.libraryExemplar.length > 0 && item.libraryExemplar[0].exemplarCode !== null) ?
                            "BIB-00" + item.libraryExemplar[0].exemplarCode :
                            "-"
                        }
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        disabled
                        className={classes.textFieldCustum}
                        label={t("INVENTORY.isbn")}
                        value={item.isbn ? item.isbn : "-"}
                        margin="normal"
                      />
                    </Grid>
                  </Grid>
                  : item.software ?
                    <Grid container>
                      {getPermissionInventory() && <Grid item xs={12} sm={4}>
                        <TextField
                          disabled
                          className={classes.textFieldCustum}
                          label={t("INVENTORY.code")}
                          value={
                            item.codigo ?
                              item.codigo :
                              "-"
                          }
                          margin="normal"
                        />
                      </Grid>}
                      <Grid item xs={12} sm={4}>
                        <TextField
                          disabled
                          className={classes.textFieldCustum}
                          label={t("INVENTORY.keyEmail")}
                          value={item.key ? item.key : item.email ? item.email : "-"}
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    :
                    <Grid container>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          disabled
                          className={classes.textFieldCustum}
                          label={t("INVENTORY.ufcCode")}
                          value={item.ufcCode ? item.ufcCode : "-"}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          disabled
                          className={classes.textFieldCustum}
                          label={t("INVENTORY.fcpcCode")}
                          value={item.fcpcCode ? item.fcpcCode : "-"}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          disabled
                          className={classes.textFieldCustum}
                          label={t("INVENTORY.lsbdCode")}
                          value={item.lsbdCode ? item.lsbdCode : "-"}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          disabled
                          className={classes.textFieldCustum}
                          label={t("INVENTORY.externalCode")}
                          value={item.externCode ? item.externCode : "-"}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          disabled
                          className={classes.textFieldCustum}
                          label={t("INVENTORY.serialNumber")}
                          value={item.serialNumber ? item.serialNumber : "-"}
                          margin="normal"
                        />
                      </Grid>
                    </Grid>
                }

                <Grid item xs={12} sm={12}>
                  <Typography className={classes.title}>
                    Alocação
                  </Typography>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      disabled
                      className={classes.textFieldCustum}
                      label={item.software ? t("INVENTORY.machine") : t("INVENTORY.place")}
                      value={item.software ? item.machine : item.place ? item.place : "-"}
                      margin="normal"
                    />
                  </Grid>
                  {
                    item.software && getPermissionInventory() ?
                      <Grid item xs={12} sm={2}>
                        <TextField
                          disabled
                          className={classes.textFieldCustum}
                          label={t("INVENTORY.status")}
                          value={item.software ? item.status : item.itemStatus ? item.itemStatus : "-"}
                          margin="normal"
                        />
                      </Grid>
                      : !item.software ?
                        <Grid item xs={12} sm={2}>
                          <TextField
                            disabled
                            className={classes.textFieldCustum}
                            label={t("INVENTORY.status")}
                            value={item.itemStatus ? item.itemStatus : "-"}
                            margin="normal"
                          />
                        </Grid> : null
                  }
                  {getPermissionInventory() &&
                    <Grid item xs={12} sm={2}>
                      <TextField
                        disabled
                        className={classes.textFieldCustum}
                        label={t("INVENTORY.parentId")}
                        value={"-"}
                        margin="normal"
                      />
                    </Grid>}
                </Grid>
                {getPermissionInventory() &&
                  <React.Fragment>
                    <Grid item xs={12} sm={12}>
                      <Typography className={classes.title}>
                        Administração
                      </Typography>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          disabled
                          className={classes.textFieldCustum}
                          label={t("INVENTORY.value")}
                          value={item.value ? item.value : item.price ? item.price : "-"}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          disabled
                          className={classes.textFieldCustum}
                          label={t("INVENTORY.invoice")}
                          value={item.invoice ? item.invoice : "-"}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          disabled
                          className={classes.textFieldCustum}
                          label={t("INVENTORY.project")}
                          value={item.project ? item.project : "-"}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          disabled
                          className={classes.textFieldCustum}
                          label={t("INVENTORY.dateAcquisition")}
                          value={item.acquisitionDate ? dateToEN(item.acquisitionDate) : "-"}
                          margin="normal"
                        />
                      </Grid>
                      {
                        (item.class !== "library.Book" && (item.category && item.category !== "Livro")) &&
                        <React.Fragment>
                          <Grid item xs={12} sm={2}>
                            <TextField
                              disabled
                              className={classes.textFieldCustum}
                              label={t("INVENTORY.warrantyData")}
                              value={item.warrantyData ? dateToEN(item.warrantyData) : "-"}
                              margin="normal"
                            />
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <TextField
                              disabled
                              className={classes.textFieldCustum}
                              label={t("INVENTORY.lastMaintenance")}
                              value={item.lastMaintenance ? dateToEN(item.lastMaintenance) : "-"}
                              margin="normal"
                            />
                          </Grid>
                        </React.Fragment>
                      }
                    </Grid>
                  </React.Fragment>
                }
                <Grid item xs={12} sm={12}>
                  <Typography className={classes.title}>
                    Observação
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  {item.description ? item.description : "-"}
                </Grid>
                {getPermissionInventory() &&
                  <React.Fragment>
                    <Grid item xs={12} sm={12}>
                      <Typography className={classes.title}>
                        Última auditoria
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      {item.externCode ? item.externCode : "-"}
                    </Grid>
                  </React.Fragment>}
              </Grid>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Buttons
            onClick={handleClose}
            tipo="BN-noback"
            conteudo={t("INVENTORY.close").toUpperCase()}
          />
          {GetGeneralPermissionForAction(Screen.MY_REQUISITIONS, Allow.CREATE) && item.class !== "library.Book" &&
            <Buttons
              onClick={handleOpenRequisitionClick}
              tipo="BN-blue"
              conteudo={t("INVENTORY.openRequest").toUpperCase()}
            />
          }
        </DialogActions>
      </Dialog>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <Link to={`/profiles/${item.id}`}>
        <Tooltip title="Visualizar" aria-label="details" placement="top">
          <IconButton className={classes.icons}>
            <Visibility />
          </IconButton>
        </Tooltip>
      </Link>
    </React.Fragment>
  );
};

export default MyItensTableActions;