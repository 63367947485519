import React from "react";
import Button from '@material-ui/core/Button';


import { buttonsStyles } from "./Styles";

const SiteButton = ({children}) => {
    const styles = buttonsStyles();
    return(
        <Button variant="outlined" className={styles.siteButton}>
            {children}
        </Button>
    )
}

export default SiteButton