/**
 * Format a date to a string that can be safely inserted into the database
 * @param {Date} date date to be formatted
 * @param {Boolean} dateOnly if true, returns the date without the time
 * @returns {String} date in the format yyy-mm-dd hh:mm:ss or yyyy-mm-dd
 */
export const FormatDateToDataBase = (date, dateOnly = false) => {
    if (isNaN(Date.parse(date))) {
        throw new Error('date is not a valid date');
    }

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    if (dateOnly) return `${year}-${month}-${day}`;

    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};
