import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import MenuItem from "@material-ui/core/MenuItem";

import { useStyles } from "components/layout/CommonStyles";
import Select from '@material-ui/core/Select';
import VacationCalendarTable from './VacationCalendarTable';

import { FormatDateToFront } from "utils/dates/FormatDateToFront";


const VacationCalendarTabPanel = (props) => {
    const classes = useStyles();
    const [t] = useTranslation();
    const {
        vacationCalendar,
        value,
        tabYear,
        year,
        yearIdx
    } = props;

    const [pendingEnjoy, setPendingEnjoy] = useState(0);
    const [pendingPay, setPendingPay] = useState(0);
    const [rows, setRows] = useState([]);
    const [monthStatus, setMonthStatus] = useState([]);


    /*
     * used to keep the <Select> component in the right option when changing tabs
     */
    const [filterList, setFilterList] = useState([]);

    useEffect(() => {
        setRows(vacationCalendar.years[yearIdx].rows);
        setMonthStatus(vacationCalendar.years[yearIdx].monthStatus);
    }, [tabYear]);

    const [optionSelected, setOptionSelected] = useState(-1);

    const sortedFields = ["name", "dayToObtain", "daysToEnjoy", "daysToBePaid", "ruleset"];
    const mainField = "name"


    const headCells = [
        { id: 'name', numeric: false, disablePadding: true, label: t("HR.collaborator") },
        { id: 'dayToObtain', numeric: false, disablePadding: false, label: t("HR.rightToLeave") },
        { id: 'daysToEnjoy', numeric: false, disablePadding: false, label: t("HR.enjoyment") },
        { id: 'daysToBePaid', numeric: false, disablePadding: false, label: t("HR.remuneration") },
        { id: 'ruleset', numeric: false, disablePadding: false, label: t("HR.workingRelation") },
    ];

    const handleChange = (event) => {
        setOptionSelected(event.target.value);

    };

    const updateCounts = () => {
        const data = vacationCalendar.years[value];

        if (optionSelected != -1) {
            var toEnjoy = data.rows.filter(el => {
                return el["dayToObtain"] != null &&
                    (new Date(FormatDateToFront(el["dayToObtain"]))).getMonth() == optionSelected &&
                    el["daysToEnjoy"] != null && el["daysToEnjoy"] > 0 && el["enjoyedPeriodStatus"] == "PENDING";
            });
            var toBePaid = data.rows.filter(el => {
                return el["dayToObtain"] != null &&
                    (new Date(FormatDateToFront(el["dayToObtain"]))).getMonth() == optionSelected &&
                    el["daysToBePaid"] != null && el["ruleset"] == "CLT" && el["daysToBePaid"] > 0 && el["paidPeriodStatus"] == "PENDING";
            });


        } else {
            var toEnjoy = data.rows.filter(el => {
                return el["daysToEnjoy"] != null && el["daysToEnjoy"] > 0 && el["enjoyedPeriodStatus"] == "PENDING";
            });
            var toBePaid = data.rows.filter(el => {
                return el["daysToBePaid"] != null && el["ruleset"] == "CLT" && el["daysToBePaid"] > 0 && el["paidPeriodStatus"] == "PENDING";
            });

        }


        setPendingEnjoy(toEnjoy.length);
        setPendingPay(toBePaid.length);
    }


    useEffect(() => {
        if (Object.keys(vacationCalendar).length !== 0) {

            var newList = new Array(vacationCalendar.numberOfYears).fill({ value: -1 });

            var thisYear = new Date().getFullYear();
            var i = -1;
            (vacationCalendar.years).forEach((y, idx) => {
                if (y.year === thisYear) {
                    i = idx;
                }
            });

            if (i >= 0) {
                newList[i] = { value: new Date().getMonth() };
            }

            setFilterList(newList);

        }
    }, [vacationCalendar]);

    useEffect(() => {
        if (filterList.length > 0) {
            setOptionSelected(filterList[value].value);

        } else {
            setOptionSelected(
                tabYear === new Date().getFullYear() ?
                    new Date().getMonth()
                    : -1
            );
        }

    }, [value]);


    useEffect(() => {
        updateCounts();
    }, [optionSelected, tabYear]);


    useEffect(() => {
        if (filterList.length > 0) {

            setFilterList(
                filterList.map(
                    (el, idx) =>
                        idx === value ?
                            { ...el, value: optionSelected } :
                            { ...el }

                )
            );
        }
    }, [optionSelected]);


    return (
        <React.Fragment>
            <div style={{ padding: '20px 16px' }}>
                <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={optionSelected}
                    onChange={handleChange}
                    style={{ display: 'flex', minHeight: 40, minWidth: 100, height: 40, width: 256, backgroundColor: "#F5F6FA" }}
                    variant="filled"

                >
                    <MenuItem value={-1}>
                        <em>{t("GENERAL.allMasculine")}</em>
                    </MenuItem>
                    <MenuItem value={0}>{t("GENERAL.january")}</MenuItem>
                    <MenuItem value={1}>{t("GENERAL.february")}</MenuItem>
                    <MenuItem value={2}>{t("GENERAL.march")}</MenuItem>
                    <MenuItem value={3}>{t("GENERAL.april")}</MenuItem>
                    <MenuItem value={4}>{t("GENERAL.may")}</MenuItem>
                    <MenuItem value={5}>{t("GENERAL.june")}</MenuItem>
                    <MenuItem value={6}>{t("GENERAL.july")}</MenuItem>
                    <MenuItem value={7}>{t("GENERAL.august")}</MenuItem>
                    <MenuItem value={8}>{t("GENERAL.september")}</MenuItem>
                    <MenuItem value={9}>{t("GENERAL.october")}</MenuItem>
                    <MenuItem value={10}>{t("GENERAL.november")}</MenuItem>
                    <MenuItem value={11}>{t("GENERAL.december")}</MenuItem>
                </Select>
                <div style={{ paddingTop: 40, paddingBottom: 40 }}   >
                    <div style={{ padding: "8px" }}><b>{t("VACATION.pendingVacationEnjoy")}: &nbsp; &nbsp; </b> {pendingEnjoy} {t("HR.collaborators")}</div>
                    <div style={{ padding: "8px" }}><b>{t("VACATION.pendingVacationPay")}: &nbsp; &nbsp; </b> {pendingPay} {t("HR.collaborators")}</div>
                </div>

                <VacationCalendarTable
                    rows={rows}
                    monthStatus={monthStatus}
                    headers={headCells}
                    sortedFields={sortedFields}
                    mainField={mainField}
                    optionSelected={optionSelected}
                />


            </div>

        </React.Fragment>
    )
}
export default VacationCalendarTabPanel;