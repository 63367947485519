import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Visibility from '@material-ui/icons/Visibility';
import DialogViewTransactions from '../../Dialogs/DialogViewTransactions';
import DialogCancelTransactions from '../../Dialogs/DialogCancelTransactions';
import DialogEditTransactions from '../../Dialogs/DialogEditTransactions';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';
import { Screen, Allow } from 'global/constants';
import { screenStyles } from '../../Styles';
import CancelIcon from '@material-ui/icons/Cancel';
import { TransactionsTypes } from '../../ScreenUtils';
import Edit from '@material-ui/icons/Edit';

const TransactionsTableActions = props => {
    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }
    const styles = screenStyles();
    const classes = useStyles();
    const [t] = useTranslation();
    const { item, isLSBD } = props;

    const [openView, setOpenView] = useState(false);
    const [openCancel, setOpenCancel] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

    return (
        <React.Fragment>
            <Tooltip
                title={t('GENERAL.view')}
                aria-label="details"
                placement="top"
            >
                <IconButton
                    className={classes.iconsTableActions}
                    onClick={() => setOpenView(true)}
                >
                    <Visibility />
                </IconButton>
            </Tooltip>

            {isLSBD &&
            GetGeneralPermissionForAction(
                Screen.QUOTAS_DASHBOARD_LSBD_TAB_TRANSACTIONS,
                Allow.EDIT
            ) ? (
                <Tooltip
                    title={t('GENERAL.edit')}
                    aria-label="details"
                    placement="top"
                >
                    <IconButton
                        className={classes.iconsTableActions}
                        onClick={() => setOpenEdit(true)}
                        disabled={
                            item.type === TransactionsTypes.CANCELLED_CREDIT ||
                            item.type === TransactionsTypes.CREDIT
                        }
                    >
                        <Edit />
                    </IconButton>
                </Tooltip>
            ) : null}

            {isLSBD &&
            GetGeneralPermissionForAction(
                Screen.QUOTAS_DASHBOARD_LSBD_TAB_TRANSACTIONS,
                Allow.DELETE
            ) ? (
                <Tooltip
                    title={t('GENERAL.cancel')}
                    aria-label="details"
                    placement="top"
                >
                    <IconButton
                        className={classes.iconsTableActions}
                        onClick={() => setOpenCancel(true)}
                        disabled={item.reversedBy || item.reversesTransaction}
                    >
                        <CancelIcon />
                    </IconButton>
                </Tooltip>
            ) : null}

            {openView && (
                <DialogViewTransactions
                    open={openView}
                    setOpen={setOpenView}
                    viewValues={item}
                    isLSBD={isLSBD}
                />
            )}

            {openCancel && (
                <DialogCancelTransactions
                    open={openCancel}
                    setOpen={setOpenCancel}
                    viewValues={item}
                    isLSBD={isLSBD}
                />
            )}

            {openEdit && (
                <DialogEditTransactions
                    open={openEdit}
                    setOpen={setOpenEdit}
                    viewValues={item}
                />
            )}
        </React.Fragment>
    );
};

export default TransactionsTableActions;
