import React, { useState, useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Buttons from "components/buttons/Pressed";
import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";
import ExcelJS from 'exceljs';

const DialogExport = (props) => {
    const [t] = useTranslation();
    const classes = useStyles();
    const { open, setOpen, data } = props;
    const fileDownload = require("js-file-download");
    const [buttonExport, setButtonExport] = useState(false);
    const [fields, setFields] = useState([
        { label: "INVENTORY.name", checked: true },
        { label: "INVENTORY.category", checked: true },
        { label: "INVENTORY.status", checked: true },
        { label: "INVENTORY.place", checked: true },
        { label: "INVENTORY.responsible", checked: true },
        { label: "INVENTORY.ufcCode", checked: true },
        { label: "INVENTORY.fcpcCode", checked: true },
        { label: "INVENTORY.lsbdCode", checked: true },
        { label: "INVENTORY.externalCode", checked: true },
        { label: "INVENTORY.serialNumber", checked: true },
        { label: "INVENTORY.dateAcquisition", checked: true },
        { label: "INVENTORY.warrantyData", checked: true },
        { label: "PROFILES.descriptionLabel", checked: true },
        { label: "INVENTORY.observation", checked: true },
        { label: "INVENTORY.project", checked: true },
    ]);
    const maxFieldsColumn = 5;
    const qtyColumn = Math.ceil(fields.length / maxFieldsColumn);

    function dateToEN(date) {
        const dateAcquisition = new Date(date);
        dateAcquisition.setMinutes(dateAcquisition.getMinutes() + dateAcquisition.getTimezoneOffset())
        return dateAcquisition.getDate() + "/" + (dateAcquisition.getMonth() + 1) + "/" + dateAcquisition.getFullYear();
    }

    const valueByColumn = (column) => {
        switch (column) {
            case "INVENTORY.name":
                return data.map(obj => obj.name);
            case "INVENTORY.category":
                return data.map(obj => {
                    if (obj.category) return obj.category;
                    return "";
                });
            case "INVENTORY.status":
                return data.map(obj => {
                    if (obj.itemStatus) return obj.itemStatus;
                    return "";
                });
            case "INVENTORY.place":
                return data.map(obj => {
                    if (obj.place && obj.place) return obj.place;
                    return "";
                });
            case "INVENTORY.responsible":
                return data.map(obj => {
                    if (obj.responsible) return obj.responsible;
                    return "";
                });
            case "INVENTORY.ufcCode":
                return data.map(obj => {
                    if (obj.ufcCode) return obj.ufcCode;
                    return "";
                });
            case "INVENTORY.fcpcCode":
                return data.map(obj => {
                    if (obj.fcpcCode) return obj.fcpcCode;
                    return "";
                });
            case "INVENTORY.lsbdCode":
                return data.map(obj => {
                    if (obj.lsbdCode) return obj.lsbdCode;
                    return "";
                });
            case "INVENTORY.externalCode":
                return data.map(obj => {
                    if (obj.externCode) return obj.externCode;
                    return "";
                });
            case "INVENTORY.serialNumber":
                return data.map(obj => {
                    if (obj.serialNumber) return obj.serialNumber;
                    return "";
                });
            case "INVENTORY.dateAcquisition":
                return data.map(obj => {
                    if (obj.acquisitionDate) return dateToEN(obj.acquisitionDate);
                    return "";
                });
            case "INVENTORY.warrantyData":
                return data.map(obj => {
                    if (obj.warrantyData) return dateToEN(obj.warrantyData);
                    return "";
                });
            case "PROFILES.descriptionLabel":
                return data.map(obj => {
                    if (obj.detailedDescription) return obj.detailedDescription;
                    return "";
                });
            case "INVENTORY.observation":
                return data.map(obj => {
                    if (obj.description) return obj.description;
                    return "";
                });
    
            case "INVENTORY.project":
                return data.map(obj => {
                    if (obj.project) return obj.project;
                    return "";
                });
            default:
                break;
        }

        return [];
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleExport = () => {
        setOpen(false);
        const filename = "exportar equipamentos.xlsx";

        const workbook = new ExcelJS.Workbook();
        const ws = workbook.addWorksheet("Report");

        // Style header
        ws.getRow(1).font = { name: "Calibri", size: 14, bold: true };

        // Create columns
        ws.columns = fields.filter(field => field.checked).map(field => {
            return { header: field.label, key: field.label }
        });

        // Add columns values
        ws.columns.forEach(col => {
            //Add column data
            const res = valueByColumn(col.header);
            //Add column name
            res.unshift(t(col.header));

            col.values = res;
            col.width = col.header.length + 10;
        });

        // Download file
        workbook.xlsx.writeBuffer().then(function (data) {
            const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            fileDownload(blob, filename);
        });
    };

    const handleChange = (event) => {
        setFields((old) => old.map((field) => {
            if (event.target.name !== field.label) return field;
            return { ...field, checked: event.target.checked }
        }));
    };

    useEffect(() => {
        let buttonIsEnabled = fields.filter(x => x.checked === true)
        buttonIsEnabled.length === 0 ? setButtonExport(true) : setButtonExport(false)
    }, [fields]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            msg="mensagem"
            maxWidth="md"
        >
            <DialogTitle className={classes.actionsTitle} style={{ width: 'auto' }}>
                <span style={{ fontWeight: 'bold' }}>
                    {t("Exportar Equipamentos").toLocaleUpperCase()}
                </span>
                <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <FormControl component="fieldset">
                        <Grid container>
                            <Grid container item spacing={1} md={12} lg={12}>
                                <Grid item xs={12}>
                                    <FormLabel component="legend">Selecione abaixo o(s) campo(s) que deseja exportar:</FormLabel>
                                </Grid>
                                {Array.from(Array(qtyColumn).keys()).map((column, index) =>
                                    <Grid item xs={4} style={{ display: 'flex', flexDirection: 'column', }} key={index}>
                                        {fields
                                            .slice(column * maxFieldsColumn, (column + 1) * maxFieldsColumn)
                                            .map(field =>
                                                <FormControlLabel
                                                    key={field.label}
                                                    label={t(field.label)}
                                                    control={
                                                        <Checkbox color="primary" checked={field.checked} name={field.label} onChange={handleChange} />
                                                    }
                                                />
                                            )}
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </FormControl>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Buttons
                    onClick={handleClose}
                    tipo="BN-noback"
                    conteudo={t("GENERAL.cancel").toLocaleUpperCase()}
                />
                <Buttons
                    disabled={buttonExport}
                    onClick={handleExport}
                    tipo="BN-blue"
                    conteudo={t("INVENTORY.export").toLocaleUpperCase()}
                />
            </DialogActions>
        </Dialog>
    );
}

export default DialogExport;