/* eslint-disable */
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Edit from "@material-ui/icons/Edit";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Footer from "components/layout/Footer";
import Spinner from "components/layout/spinners/Index";
import AllowancesContext from "context/allowance/allowanceContext";
import UsersContext from "context/users/usersContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import "../profiles/css/FormStyle.css";
import { useStyles } from "./UsersStyles";

const ViewForm = props => {
  const defaultImage = window.location.origin;
  const classes = useStyles();
  const { id } = props;
  const [t] = useTranslation();
  const usersContext = useContext(UsersContext);
  const {
    user,
    getUser,
    getProfiles,
    profiles,
    loading,
    resetMessage
  } = usersContext;

  const allowanceContext = useContext(AllowancesContext);
  const {
    allAllowances,
    getAllPermission
  } = allowanceContext;

  const [state, setState] = useState({
    id: 0,
    cpf: "",
    name: "",
    email: "",
    profile: "",
    lattesUrl: "",
    imagePath: "",
    isActive: false,
    profileId: 0
  });

  useEffect(() => {
    resetMessage();
    getAllPermission();
    getUser(id);
    setState({
      ...state,
      id: id,
      cpf: user.cpf ? user.cpf : "",
      name: user.name ? user.name : "",
      email: user.email ? user.email : "",
      profile: user.profileName ? user.profileName : "",
      lattesUrl: user.lattesUrl ? user.lattesUrl : "",
      imagePath: user.imagePath ? user.imagePath : "",
      isActive: user.isActive ? user.isActive : false,
      profileId: user.profileId ? user.profileId : 0
    });
    getProfiles();
  }, [
    user.profileName,
    Object.values(profiles).length,
    user.isActive,
    user.imagePath
  ]);

  const transitions = useTransition(loading, null, {
    from: { opacity: 0 },
    enter: {
      opacity: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "calc(100vh - 80px)"
    },
    leave: { opacity: 0 }
  });

  const handleClickLattes = () => {
    if (state.lattesUrl) window.open(state.lattesUrl);
  };

  const getPermission = (screen, allowance) => {
    for (let i = 0; i < allAllowances.length; i++) {
      if (allAllowances[i].resource === screen) {
        return allAllowances[i]["standardauthorizationoption"][allowance]
      }
    }
  }

  return transitions.map(({ item, key, props }) =>
    item ? (
      <animated.div key={key} style={props}>
        <Spinner />;
      </animated.div>
    ) : (
        <animated.div key={key} style={props}>
          <div className="container">
            <Breadcrumbs
              className={classes.breadCrumbs}
              aria-label="breadcrumb"
              separator={<NavigateNextIcon fontSize="small" />}
            >
              <Typography>{t("ROUTES.planning")}</Typography>
              <Typography>{t("ROUTES.config")}</Typography>
              <Link className={classes.link} to="/users">
                {t("USERS.users")}
              </Link>
              <Typography>{t("GENERAL.view")}</Typography>
            </Breadcrumbs>
            <div className="container-header">
              <div className="title">
                <h1 className={classes.title}>
                  {t("GENERAL.view") + " " + t("USERS.user").toLowerCase()}
                </h1>
              </div>
              <Link to={"/users"}>
                <Button
                  className={classes.blueButton}
                  variant="contained"
                  color="primary"
                >
                  <ArrowBack />
                  {t("GENERAL.back").toUpperCase()}
                </Button>
              </Link>
            </div>
            <form id="formUsers">
              <Paper className={classes.root}>
                <div className={classes.cabecalho}>
                  {t("USERS.user").toUpperCase()}
                  {getPermission("USER", "allowUpdate") &&
                    <Link to={`/users/${id}/edit`}>
                      <Tooltip
                        className={classes.editButton}
                        title={t("GENERAL.edit")}
                        aria-label="edit"
                        placement="top"
                      >
                        <IconButton className={classes.icons}>
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    </Link>
                  }
                </div>
                <div className={classes.bodyForm}>
                  <Grid container>
                    <Hidden mdDown>
                      <Grid item lg={2}>
                        <div className={classes.avatarParent}>
                          <div
                            className="avatar"
                            style={{
                              backgroundImage: `url(${defaultImage + state.imagePath !== ""
                                  ? process.env.REACT_APP_IMGSERVER + state.imagePath
                                  : "/img/icon-default.png"
                                })`
                            }}
                          ></div>
                        </div>
                      </Grid>
                    </Hidden>
                    <Grid container item spacing={2} md={12} lg={10}>
                      <Grid item xs={2}>
                        <TextField
                          disabled
                          className={classes.textField}
                          margin="normal"
                          id="cpf"
                          label="CPF"
                          name="CPF"
                          value={state.cpf}
                          autoFocus
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          disabled
                          className={classes.textField}
                          margin="normal"
                          id="name"
                          label={t("PERSONAL.name")}
                          name="name"
                          value={state.name}
                          autoFocus
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          disabled
                          className={classes.textField}
                          margin="normal"
                          id="email"
                          label={t("ADDRESS.email")}
                          name="email"
                          value={state.email}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          disabled
                          className={classes.textField}
                          margin="normal"
                          id="profile"
                          label={t("PROFILES.profile")}
                          name="profile"
                          value={
                            state.profile === "-" ? new String() : state.profile
                          }
                        />
                      </Grid>
                      <Grid item xs={11}>
                        <TextField
                          disabled
                          className={classes.textField}
                          margin="normal"
                          id="lattes"
                          label={t("PERSONAL.lattes")}
                          name="lattes"
                          value={state.lattesUrl}
                          onClick={handleClickLattes}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <TextField
                          disabled
                          margin="normal"
                          className={classes.textField}
                          id="status"
                          label="Status"
                          name="status"
                          value={state.isActive ? "Ativo" : "Inativo"}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </form>
          </div>
          <Footer />
        </animated.div>
      )
  );
};

export default ViewForm;
