import { DEFAULT, PARTNERS, PROJECTS, USERS } from '../types';

export default (state, action) => {
    switch (action.type) {
        case DEFAULT.SET_LOADING:
            return {
                ...state,
                loading: true
            };
        case PROJECTS.GET_PROJECT:
            return {
                ...state,
                loading: false,
                project: action.payload
            };
        case PROJECTS.GET_FILE:
            return {
                ...state,
                loading: false,
                files: action.payload
            };
        case PROJECTS.GET_ADDENDUM:
            return {
                ...state,
                addendum: action.payload
            };
        case PROJECTS.SET_OPEN_MODAL:
            return {
                ...state,
                openModal: action.payload
            };
        case PROJECTS.RESET_PROJECT:
            return {
                ...state,
                project: {},
                loading: false
            };
        case PROJECTS.RESET_PHASE:
            return {
                ...state,
                phase: {},
                loading: false
            };
        case PROJECTS.GET_GOAL:
            return {
                ...state,
                loading: false,
                goal: action.payload
            };
        case PROJECTS.GET_COST_GPF:
            return {
                ...state,
                loading: false,
                costByProject: action.payload
            };
        case PROJECTS.GET_PROJECTS:
            return {
                ...state,
                loading: false,
                projects: action.payload
            };
        case PROJECTS.GET_ALL_PROJECTS:
            return {
                ...state,
                projects: action.payload
            };
        case PROJECTS.CREATE_PROJECT:
            return {
                ...state,
                loading: false,
                response: action.payload,
                stepFlow: action.stepFlow,
                newProject: action.newProject,
                responseType: action.responseType,
                newProjectId: action.newProjectId,
                projectCreated: action.projectCreated,
                hasChanged: action.hasChanged
            };
        case PROJECTS.UPDATE_GENERALINFORMATION:
            return {
                ...state,
                loading: false,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions
            };
        case PROJECTS.UPDATE_GOAL:
            return {
                ...state,
                loading: false,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions
            };
        case PROJECTS.UPDATE_PROJECT_FINISH:
            return {
                ...state,
                loading: false,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case PROJECTS.SET_PROJECT_CREATED:
            return {
                ...state,
                projectCreated: action.projectCreated
            };
        case PARTNERS.GET_PARTNERS:
            return {
                ...state,
                loading: false,
                partners: action.payload
            };
        case USERS.GET_USERS:
            return {
                ...state,
                loading: false,
                users: action.payload
            };
        case PROJECTS.GENERAL_INFORMATION:
            return {
                ...state,
                generalInformation: action.payload
            };
        case PROJECTS.CHANGE_STEP:
            return {
                ...state,
                stepFlow: action.payload
            };
        case DEFAULT.RESET_MESSAGE:
            return {
                ...state,
                response: '',
                hasUpdate: 0,
                responseType: ''
            };
        case DEFAULT.SET_HAS_CHANGED:
            return {
                ...state,
                hasChanged: 0
            };
        case PROJECTS.HANDLE_SUBMIT_SCOPE:
            return {
                ...state,
                loading: false,
                response: action.payload,
                hasUpdate: action.hasUpdate,
                responseType: action.responseType,
                successiveActions: action.successiveActions
            };
        case PROJECTS.HANDLE_SUBMIT_SCHEDULE:
            return {
                ...state,
                loading: false,
                response: action.payload,
                hasUpdate: action.hasUpdate,
                responseType: action.responseType,
                successiveActions: action.successiveActions
            };
        case PROJECTS.HANDLE_SUBMIT_HEADINGS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                hasUpdate: action.hasUpdate,
                responseType: action.responseType,
                successiveActions: action.successiveActions
            };
        case PROJECTS.HANDLE_SUBMIT_BUDGET:
            return {
                ...state,
                loading: false,
                response: action.payload,
                hasUpdate: action.hasUpdate,
                responseType: action.responseType,
                successiveActions: action.successiveActions
            };
        case DEFAULT.SET_MESSAGE:
            return {
                ...state,
                response: action.response,
                hasUpdate: action.hasUpdate,
                responseType: action.responseType
            };
        case PROJECTS.TRIGGER_UPDATE_CREATE:
            return {
                ...state,
                loading: false,
                triggerUpdateCreateAction: action.payload,
                successiveActions: action.successiveActions
            };
        case PROJECTS.SET_VALUE_REQUIRED_MESSAGE:
            return {
                ...state,
                loading: false,
                showRequiredFieldMessage: action.payload
            };
        case PROJECTS.SET_VALUE_PANEL_TAB:
            return {
                ...state,
                loading: false,
                panelTab: action.payload
            };
        case PROJECTS.SET_NEW_ACTIVITY_ID:
            return {
                ...state,
                loading: false,
                newActivityId: action.payload
            };
        case PROJECTS.SET_ACTION_MODE:
            return {
                ...state,
                loading: false,
                actionMode: action.payload
            };
        case PROJECTS.SET_IDEAL_NUMBER_OF_CHILDS_UPDATES:
            return {
                ...state,
                idealNumberOfChildUpdates: action.payload
            };
        case PROJECTS.RESET_ADDENDUMS:
            return {
                ...state,
                addendums: [],
                numberOfChildUpdates: 1
            };
        case PROJECTS.DELETE_PROJECT:
            return {
                ...state,
                loading: false,
                responseDelete: action.payload,
                successiveActions: action.successiveActions,
                responseDeleteType: action.responseDeleteType,
                hasChanged: action.hasChanged
            };
        case DEFAULT.RESET_DELETE_MESSAGE:
            return {
                ...state,
                responseDelete: '',
                successiveActions: 0,
                responseDeleteType: ''
            };
        case PROJECTS.UPDATE_CREATE_ADDENDUM:
            return {
                ...state,
                project: {},
                loading: false,
                response: action.payload,
                triggerUpdateCreateAction: false,
                responseType: action.responseType,
                successiveActions: action.successiveActions
            };
        case PROJECTS.DELETE_ADDENDUM:
            return {
                ...state,
                project: {},
                loading: false,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions
            };
        case PROJECTS.GET_PROJECT_PARTICIPANTS:
            return {
                ...state,
                loading: false,
                participants: action.payload
            };
        case PROJECTS.DELETE_PARTICIPANTS:
            return {
                ...state,
                loading: false,
                responseDelete: action.payload,
                successiveActions: action.successiveActions,
                responseDeleteType: action.responseDeleteType
            };
        case PROJECTS.DELETE_EXPENSE:
            return {
                ...state,
                loading: false,
                responseDelete: action.payload,
                successiveActions: action.successiveActions,
                responseDeleteType: action.responseDeleteType
            };
        case PROJECTS.ADD_PARTICIPANTS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case PROJECTS.ADD_PARTICIPANTS_ACTIVITY:
            return {
                ...state,
                loading: false,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case PROJECTS.CREATE_EXPENSE:
            return {
                ...state,
                loading: false,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case PROJECTS.GET_PROJECT_PHASES:
            return {
                ...state,
                loading: false,
                phases: action.payload
            };
        case PROJECTS.GET_PROJECT_PHASE:
            return {
                ...state,
                loading: false,
                phase: action.payload
            };
        case PROJECTS.DELETE_PHASE:
            return {
                ...state,
                loading: false,
                responseDelete: action.payload,
                successiveActions: action.successiveActions,
                responseDeleteType: action.responseDeleteType
            };
        case PROJECTS.ADD_PHASE:
            return {
                ...state,
                loading: false,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case PROJECTS.UPDATE_PHASE:
            return {
                ...state,
                loading: false,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case PROJECTS.ADD_ACTIVITY:
            return {
                ...state,
                loading: false,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions
            };
        case PROJECTS.DELETE_ACTIVITY:
            return {
                ...state,
                loading: false,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions
            };

        case PROJECTS.GET_ACTIVITY:
            return {
                ...state,
                loading: false,
                activity: action.payload
            };
        case PROJECTS.UPDATE_ACTIVITY:
            return {
                ...state,
                loading: false,
                response: action.payload,
                responseType: action.responseType
            };
        case PROJECTS.DELETE_PARTICIPANTS_ACTIVITY:
            return {
                ...state,
                loading: false,
                response: action.payload,
                responseType: action.responseDeleteType
            };
        case PROJECTS.UPDATE_PARTICIPANT_ACTIVITY:
            return {
                ...state,
                loading: false,
                response: action.payload,
                responseType: action.responseType
            };
        case PROJECTS.GET_EXPENSES:
            return {
                ...state,
                loading: false,
                expenses: action.payload
            };
        case PROJECTS.SET_DIALOG:
            return {
                ...state,
                dialogData: action.payload
            };
        case PROJECTS.HANDLE_SUBMIT_PROJECT_BUDGET:
            return {
                ...state,
                loading: false,
                response: action.payload,
                hasUpdate: action.hasUpdate,
                responseType: action.responseType,
                successiveActions: action.successiveActions
            };
        case PROJECTS.GET_SUBSIDY:
            return {
                ...state,
                loading: false,
                subsidy: action.payload
            };
        case PROJECTS.HANDLE_SUBMIT_PROJECT_SUBSIDY:
            return {
                ...state,
                loading: false,
                response: action.payload,
                hasUpdate: action.hasUpdate,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case PROJECTS.DELETE_SUBSIDY:
            return {
                ...state,
                loading: false,
                responseDelete: action.payload,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged,
                responseDeleteType: action.responseDeleteType
            };
        case PROJECTS.UPDATE_SUBSIDY:
            return {
                ...state,
                loading: false,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case PROJECTS.GET_PARTICIPANT_FEEDBACKS:
            return {
                ...state,
                participantFeedback: action.payload
            };
        case PROJECTS.DELETE_PARTICIPANT_FEEDBACK:
            return {
                ...state,
                loading: false,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case PROJECTS.ADD_PARTICIPANT_FEEDBACK:
            return {
                ...state,
                loading: false,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case PROJECTS.GET_PARTICIPANT_SOLICITATION:
            return {
                ...state,
                participantSolicitation: action.payload
            };
        case PROJECTS.GET_PARTICIPANT_PONTOS:
            return {
                ...state,
                participantPontos: action.payload
            };
        case PROJECTS.UPDATE_PARTICIPANT_FEEDBACK:
            return {
                ...state,
                loading: false,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case PROJECTS.COMPLETE_SUBMISSION:
            return {
                ...state,
                completeSubmission: action.payload
            };
        default:
            return state;
    }
};
