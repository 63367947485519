import axios from 'axios';
import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT, HR } from '../../types';
import collaboratorContext from './collaboratorContext';
import collaboratorReducer from './collaboratorReducer';
import Cookies from 'js-cookie';
import { headers } from 'global/variables';

const CollaboratorState = props => {
    const initialState = {
        message: '',
        response: '',
        responseType: '',
        hasChanged: 0,
        successiveActions: 0,
        loading: false,
        collaborators: [],
        informationIdentification: {},
        informationFamilyData: {},
        informationContact: {},
        bankDataContact: [],
        informationFormation: {},
        admission: [],
        companies: [],
        rulesets: [],
        jobs: [],
        documents: [],
        docHistory: [],
        profiles: null,
        rhWorkingHoursContracts: [],
        clockInInfo: {},
        modulesAndRoles: [],
        birthdays: []
    };

    const [t] = useTranslation();
    const [state, dispatch] = useReducer(collaboratorReducer, initialState);
    const setLoading = () => dispatch({ type: DEFAULT.SET_LOADING });
    const setLoadingFalse = () => dispatch({ type: DEFAULT.SET_LOADING_FALSE });

    const resetMessage = () => dispatch({ type: DEFAULT.RESET_MESSAGE });
    const setHasChanged = () => dispatch({ type: DEFAULT.SET_HAS_CHANGED });

    const getCollaborators = async () => {
        setLoading();
        const res = await axios.get(
            process.env.REACT_APP_SERVER + 'api/users/all',
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: HR.GET_COLLABORATORS,
            payload: res.data
        });
    };
    const getCollaboratorsOfApprover = async id => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/users/approved/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: HR.GET_COLLABORATORS,
            payload: res.data
        });
    };

    const getInformationIdentification = async id => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER +
                `api/Collaborator/Collaborators/Information/Identification/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: HR.GET_INFORMATION_IDENTIFICATION,
            payload: res.data
        });
    };

    const updateInformationIdentification = (id, obj, fetchData, userId) => {
        axios
            .put(
                process.env.REACT_APP_SERVER +
                    `api/Collaborator/Collaborators/Information/Identification/${id}`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                if (response.status === 200) {
                    dispatch({
                        type: HR.UPDATE_INFORMATION_IDENTIFICATION,
                        payload: t('HR.identificationUpdated'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: HR.UPDATE_INFORMATION_IDENTIFICATION,
                        payload: t('HR.identificationErrorUpdate'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: HR.UPDATE_INFORMATION_IDENTIFICATION,
                    payload: t('HR.identificationErrorUpdate'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                fetchData(userId);
            });
    };

    const addInformationIdentification = (obj, fetchData, userId) => {
        axios
            .post(
                process.env.REACT_APP_SERVER +
                    'api/Collaborator/Collaborators/Information/Identification',
                obj,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: HR.CREATE_INFORMATION_IDENTIFICATION,
                        payload: t('HR.identificationUpdated'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: HR.CREATE_INFORMATION_IDENTIFICATION,
                        payload: t('HR.identificationErrorUpdate'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: HR.CREATE_INFORMATION_IDENTIFICATION,
                    payload: t('HR.identificationErrorUpdate'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                fetchData(userId);
            });
    };

    const getInformationFamilyData = async id => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER +
                `api/Collaborator/Collaborators/Information/FamilyData/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: HR.GET_INFORMATION_FAMILY_DATA,
            payload: res.data
        });
    };

    const updateInformationFamilyData = (id, obj, fetchData, userId) => {
        let familyData;
        axios
            .put(
                process.env.REACT_APP_SERVER +
                    `api/Collaborator/Collaborators/Information/FamilyData/${id}`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: HR.UPDATE_INFORMATION_FAMILY_DATA,
                        payload: t('HR.familyDataUpdated'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: HR.UPDATE_INFORMATION_FAMILY_DATA,
                        payload: t('HR.familyDataErrorUpdate'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
                return response;
            })
            .catch(error => {
                dispatch({
                    type: HR.UPDATE_INFORMATION_FAMILY_DATA,
                    payload: t('HR.familyDataErrorUpdate'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                fetchData(userId);
            });
    };

    const addInformationFamilyData = (obj, fetchData, userId) => {
        axios
            .post(
                process.env.REACT_APP_SERVER +
                    'api/Collaborator/Collaborators/Information/FamilyData',
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: HR.CREATE_INFORMATION_FAMILY_DATA,
                        payload: t('HR.familyDataUpdated'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: HR.CREATE_INFORMATION_FAMILY_DATA,
                        payload: t('HR.familyDataErrorUpdate'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: HR.CREATE_INFORMATION_FAMILY_DATA,
                    payload: t('HR.familyDataErrorUpdate'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                fetchData(userId);
            });
    };

    const getInformationContact = async id => {
        dispatch({
            type: HR.GET_INFORMATION_CONTACT,
            payload: {}
        });
        await axios
            .get(
                process.env.REACT_APP_SERVER +
                    `api/Collaborator/Collaborators/Information/Contact/${id}`,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                if (response.status === 200) {
                    dispatch({
                        type: HR.GET_INFORMATION_CONTACT,
                        payload: response.data
                    });
                }
            })
            .catch(error => {
                return false;
            });
    };

    const updateInformationContact = (id, obj, fetchData, userId) => {
        axios
            .put(
                process.env.REACT_APP_SERVER +
                    `api/Collaborator/Collaborators/Information/Contact/${id}`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: HR.UPDATE_INFORMATION_CONTACT,
                        payload: t('HR.contactUpdated'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: HR.UPDATE_INFORMATION_CONTACT,
                        payload: t('HR.contactErrorUpdate'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: HR.UPDATE_INFORMATION_CONTACT,
                    payload: t('HR.contactErrorUpdate'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                fetchData(userId);
            });
    };

    const addInformationContact = (obj, fetchData, userId) => {
        axios
            .post(
                process.env.REACT_APP_SERVER +
                    'api/Collaborator/Collaborators/Information/Contact',
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: HR.CREATE_INFORMATION_CONTACT,
                        payload: t('HR.contactUpdated'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: HR.CREATE_INFORMATION_CONTACT,
                        payload: t('HR.contactErrorUpdate'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: HR.CREATE_INFORMATION_CONTACT,
                    payload: t('HR.contactErrorUpdate'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                fetchData(userId);
            });
    };

    const getBankDataContact = async id => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER +
                `api/Collaborator/Collaborators/BankData/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: HR.GET_BANK_DATA_CONTACT,
            payload: res.data
        });
    };

    const updateBankDataContact = (obj, callback) => {
        axios
            .put(
                process.env.REACT_APP_SERVER +
                    `api/Collaborator/Collaborators/BankData`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: HR.UPDATE_BANK_DATA_CONTACT,
                        payload: t('HR.bankDataUpdated'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: HR.UPDATE_BANK_DATA_CONTACT,
                        payload: t('HR.bankDataErrorUpdate'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: HR.UPDATE_BANK_DATA_CONTACT,
                    payload: t('HR.bankDataErrorUpdate'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                if (typeof callback === 'function') {
                    callback();
                }
            });
    };

    const getAdmission = async id => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER +
                `api/Collaborator/Collaborators/Admission/${id}` +
                '?nocache=' +
                new Date().getTime(),
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: HR.GET_ADMISSION,
            payload: res.data
        });
    };

    const getAdmissionCompanies = async id => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER +
                `api/Collaborator/Collaborators/Admission/Companies`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: HR.GET_ADMISSION_COMPANIES,
            payload: res.data
        });
    };

    const getAdmissionRuleSets = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/ruleSet`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: HR.GET_ADMISSION_RULESETS,
            payload: res.data
        });
    };

    const getAdmissionJobs = async () => {
        const res = await axios.get(process.env.REACT_APP_SERVER + `api/job`, {
            headers: {
                'Content-type': 'application/json',
                Authorization:
                    'bearer ' +
                    (Cookies.get('auth-token') ? Cookies.get('auth-token') : '')
            }
        });
        dispatch({
            type: HR.GET_ADMISSION_JOBS,
            payload: res.data
        });
    };

    const addAdmission = (obj, fetchData, userId) => {
        axios
            .post(
                process.env.REACT_APP_SERVER +
                    'api/Collaborator/Collaborators/Admission',
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: HR.CREATE_ADMISSION,
                        payload: t('HR.collaboratorUpdated'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: HR.CREATE_ADMISSION,
                        payload: t('HR.collaboratorUpdatedError'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: HR.CREATE_ADMISSION,
                    payload: t('HR.collaboratorUpdatedError'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                fetchData(userId);
            });
    };

    const admissionFire = (obj, fetchData, userId) => {
        axios
            .put(
                process.env.REACT_APP_SERVER +
                    'api/Collaborator/Collaborators/Admission/Demission',
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: HR.ADMISSION_FIRE,
                        payload: t('HR.collaboratorFired'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: HR.ADMISSION_FIRE,
                        payload: t('HR.collaboratorUpdatedError'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: HR.ADMISSION_FIRE,
                    payload:
                        error.response.data && error.response.data === 'ITENS'
                            ? t('HR.errorHasItensAssociated')
                            : error.response.data &&
                              error.response.data === 'VACATION'
                            ? t('HR.errorHasVacationPending')
                            : t('HR.collaboratorUpdatedError'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                fetchData(userId);
            });
    };

    const admissionSave = (obj, fetchData, userId) => {
        axios
            .put(
                process.env.REACT_APP_SERVER +
                    'api/Collaborator/Collaborators/Admission',
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: HR.UPDATE_ADMISSION,
                        payload: t('HR.collaboratorUpdated'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: HR.UPDATE_ADMISSION,
                        payload: t('HR.collaboratorUpdatedError'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: HR.UPDATE_ADMISSION,
                    payload: t('HR.collaboratorUpdatedError'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                fetchData(userId);
            });
    };

    // FORMATION

    const getInformationFormation = async id => {
        await axios
            .get(
                process.env.REACT_APP_SERVER +
                    `api/Collaborator/Collaborators/Information/Formation/${id}`,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                if (response.status === 200) {
                    dispatch({
                        type: HR.GET_INFORMATION_FORMATION,
                        payload: response.data
                    });
                }
            })
            .catch(error => {
                return false;
            });
    };

    const addInformationFormation = (obj, fetchData, userId) => {
        axios
            .post(
                process.env.REACT_APP_SERVER +
                    'api/Collaborator/Collaborators/Information/Formation',
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: HR.CREATE_INFORMATION_CONTACT,
                        payload: t('HR.formationAdded'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: HR.CREATE_INFORMATION_CONTACT,
                        payload: t('HR.formationErrorUpdate'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: HR.CREATE_INFORMATION_CONTACT,
                    payload: t('HR.formationErrorUpdate'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                fetchData(userId);
            });
    };

    const updateInformationFormation = (id, obj, fetchData, userId) => {
        axios
            .put(
                process.env.REACT_APP_SERVER +
                    `api/Collaborator/Collaborators/Information/Formation/${id}`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: HR.UPDATE_BANK_DATA_CONTACT,
                        payload: t('HR.formationUpdated'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: HR.UPDATE_BANK_DATA_CONTACT,
                        payload: t('HR.formationErrorUpdate'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: HR.UPDATE_BANK_DATA_CONTACT,
                    payload: t('HR.formationErrorUpdate'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                fetchData(userId);
            });
    };

    const addDocumentFile = (obj, callback) => {
        axios
            .post(
                process.env.REACT_APP_SERVER + 'api/Collaborator/Documents',
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: HR.CREATE_INFORMATION_CONTACT,
                        payload: t('HR.documentAdded'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: HR.CREATE_INFORMATION_CONTACT,
                        payload: t('HR.contactErrorUpdate'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: HR.CREATE_INFORMATION_CONTACT,
                    payload: t('HR.contactErrorUpdate'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                if (typeof callback === 'function') {
                    callback();
                }
            });
    };

    const getCollaboratoresDocuments = async id => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/Collaborator/Documents/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: HR.GET_COLLABORATORS_DOCUMENTS,
            payload: res.data
        });
    };
    const getDocumentsHistory = async id => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER +
                `api/Collaborator/Documents/history/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: HR.GET_DOCUMENTS_HISTORY,
            payload: res.data
        });
    };

    const deleteDoc = (id, active) => {
        setLoading();
        axios
            .delete(
                process.env.REACT_APP_SERVER +
                    `api/Collaborator/Documents/${id}`,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(res => {
                dispatch({
                    type: HR.DELETE_DOCUMENT,
                    payload: t('HR.documentSuccessfullyDeleted'),
                    responseType: 'SUCCESS',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .catch(error => {
                dispatch({
                    type: HR.DELETE_DOCUMENT,
                    payload:
                        error.response.status === 404
                            ? t('INVENTORY.cantDeleteNotFound')
                            : t('INVENTORY.cantDeleteAssociated'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            });
    };

    const addHistory = obj => {
        axios
            .post(
                process.env.REACT_APP_SERVER +
                    'api/Collaborator/Documents/history',
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {})
            .catch(error => {
                dispatch({
                    type: HR.CREATE_INFORMATION_CONTACT,
                    payload: t('HR.contactErrorUpdate'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            });
    };

    const updateDocument = (obj, path) => {
        axios
            .put(
                process.env.REACT_APP_SERVER + `api/Collaborator/Documents/`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: HR.UPDATE_BANK_DATA_CONTACT,
                        payload: t('HR.documentUpdated'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: HR.UPDATE_BANK_DATA_CONTACT,
                        payload: t('HR.documentErrorUpdate'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: HR.UPDATE_BANK_DATA_CONTACT,
                    payload: t('HR.documentErrorUpdate'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            });
    };

    const getProfilesByUser = async id => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/Collaborator/Profiles/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: HR.GET_PROFILE_BY_USER,
            payload: res.data
        });
    };

    const updateProfiles = (id, obj, path) => {
        let familyData;
        axios
            .put(
                process.env.REACT_APP_SERVER +
                    `api/Collaborator/Profiles/${id}`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: HR.UPDATE_INFORMATION_FAMILY_DATA,
                        payload: t('HR.profileUpdated'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: HR.UPDATE_INFORMATION_FAMILY_DATA,
                        payload: t('HR.profileErrorUpdate'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
                return response;
            })
            .catch(error => {
                dispatch({
                    type: HR.UPDATE_INFORMATION_FAMILY_DATA,
                    payload: t('HR.profileErrorUpdate'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            });
    };

    const addProfile = obj => {
        axios
            .post(
                process.env.REACT_APP_SERVER + 'api/Collaborator/Profiles',
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: HR.CREATE_ADMISSION,
                        payload: t('HR.profileUpdated'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: HR.CREATE_ADMISSION,
                        payload: t('HR.profileUpdatedError'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: HR.CREATE_ADMISSION,
                    payload: t('HR.collaboratorUpdatedError'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            });
        // .finally(() => {
        //     fetchData(userId);
        // })
    };

    const getWorkingHoursContracts = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER +
                `api/Collaborator/ClockRecord/WorkingHoursContracts`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: HR.GET_WORKING_HOURS_CONTRACT,
            payload: res.data
        });
    };

    const getClockInByUser = async id => {
        await axios
            .get(
                process.env.REACT_APP_SERVER +
                    `api/Collaborator/ClockRecord/${id}`,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                if (response.status === 200) {
                    dispatch({
                        type: HR.GET_CLOCK_IN_BY_USER,
                        payload: response.data
                    });
                }
            })
            .catch(error => {
                return false;
            });
    };

    const updateClockRecord = (obj, fetchData, id, setOpen) => {
        axios
            .put(
                process.env.REACT_APP_SERVER +
                    `api/Collaborator/ClockRecord/${id}`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: HR.UPDATE_CLOCK_RECORD,
                        payload: t('HR.clockRecordUpdated'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: HR.UPDATE_CLOCK_RECORD,
                        payload: t('HR.clockRecordError'),
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: HR.UPDATE_CLOCK_RECORD,
                    payload: t('HR.clockRecordError'),
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                fetchData(id);
                setOpen(false);
            });
    };

    const getModulesRoles = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER +
                `api/Collaborator/Profiles/ModulesProfiles`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: HR.GET_MODULES_ROLES,
            payload: res.data
        });
    };

    const getBirthdays = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + 'api/Collaborator/Birthdays',
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: HR.GET_BIRTHDAYS,
            payload: res.data
        });
    };

    return (
        <collaboratorContext.Provider
            value={{
                loading: state.loading,
                message: state.message,
                hasChanged: state.hasChanged,
                response: state.response,
                responseType: state.responseType,
                successiveActions: state.successiveActions,
                collaborators: state.collaborators,
                informationIdentification: state.informationIdentification,
                informationFamilyData: state.informationFamilyData,
                informationContact: state.informationContact,
                bankDataContact: state.bankDataContact,
                informationFormation: state.informationFormation,
                admission: state.admission,
                companies: state.companies,
                rulesets: state.rulesets,
                jobs: state.jobs,
                documents: state.documents,
                docHistory: state.docHistory,
                profiles: state.profiles,
                rhWorkingHoursContracts: state.rhWorkingHoursContracts,
                clockInInfo: state.clockInInfo,
                modulesAndRoles: state.modulesAndRoles,
                birthdays: state.birthdays,
                setLoading,
                setLoadingFalse,
                resetMessage,
                setHasChanged,
                getCollaborators,
                getCollaboratorsOfApprover,
                getInformationIdentification,
                updateInformationIdentification,
                addInformationIdentification,
                getInformationFamilyData,
                updateInformationFamilyData,
                addInformationFamilyData,
                getInformationContact,
                updateInformationContact,
                addInformationContact,
                getBankDataContact,
                updateBankDataContact,
                getInformationFormation,
                addInformationFormation,
                updateInformationFormation,
                getAdmission,
                getAdmissionCompanies,
                getAdmissionRuleSets,
                getAdmissionJobs,
                addAdmission,
                admissionFire,
                admissionSave,
                addDocumentFile,
                getCollaboratoresDocuments,
                deleteDoc,
                addHistory,
                getDocumentsHistory,
                updateDocument,
                getProfilesByUser,
                updateProfiles,
                addProfile,
                getWorkingHoursContracts,
                getClockInByUser,
                updateClockRecord,
                getModulesRoles,
                getBirthdays
            }}
        >
            {props.children}
        </collaboratorContext.Provider>
    );
};

export default CollaboratorState;
