/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Footer from 'components/layout/Footer';
import ClockRecordContext from 'context/hrModule/clockRecord/clockRecordContext';
import SettingsContext from 'context/hrModule/settings/settingsContext';
import Transition from 'components/layout/transition/Transition';
import Breadcrumb from 'components/layout/breadcrumb/Breadcrumb';
import HeaderContainer from 'components/containers/HeaderContainer';
import ContentContainer from 'components/containers/ContentContainer';
import Typography from 'components/typography/Typography';
import Buttons from 'components/buttons/Pressed';
import Input from 'components/inputs/Input';
import InputDate from 'components/inputs/InputDate';
import InputMultiDropzone from 'components/inputs/InputMultiDropzone';
import DefaultTooltip from 'components/infoTooltip/DefaultTooltip';
import DialogCancelOperation from 'components/dialogs/DialogCancelOperation';
import BlueInfoText from 'components/Infos/BlueInfoText';

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { Screen, Allow } from 'global/constants';

import { FormatDateToFront } from 'utils/dates/FormatDateToFront';
import { GetHoursAndMinutes } from 'utils/dates/GetHoursAndMinutes';
import { ConvertMilliInHourMin } from 'utils/dates/ConvertMilliInHourMin1';
import { dateToBR } from 'utils/dates/DateToBRr';
import { GetWorkedHours } from 'utils/dates/GetWorkedHours';
import { GetInputArray } from 'utils/general/GetInputArray';
import { SortElementSelect } from 'utils/sort/SortElementSelect';
import { dateValidation } from 'utils/validation/DateValidation';
import { isSameDate } from 'utils/dates/IsSameDate';

import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';

import { screenStyles } from './Styles';
import {
    JustificationsTypeArray,
    GetJustificationStatus,
    GetJustificationType,
    JustificationType,
    GetAllowanceType
} from './ScreenUtils';
import { FormatDateToDataBase } from 'utils/dates/FormatDateToDataBase';

const MyJustificationsForm = props => {
    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const classes = useStyles();
    const styles = screenStyles();
    const [t] = useTranslation();
    const clockRecordContext = useContext(ClockRecordContext);
    const settingsContext = useContext(SettingsContext);

    const { id, context } = props;

    const [loading, setLoading] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);
    const [allowanceReasonsArray, setAllowanceReasonsArray] = useState([]);
    const [requiredJustificationType, setRequiredJustificationType] =
        useState(false);
    const [requiredJustifiedDate, setRequiredJustifiedDate] = useState(false);
    const [justifiedDateErrorMessage, setJustifiedDateErrorMessage] =
        useState('');
    const [requiredObservation, setRequiredObservation] = useState(false);
    const [requiredAllowanceReasonId, setRequiredAllowanceReasonId] =
        useState(false);
    const [requiredInitialAllowanceHour, setRequiredInitialAllowanceHour] =
        useState(false);
    const [
        initialAllowanceHourErrorMessage,
        setInitialAllowanceHourErrorMessage
    ] = useState('');
    const [requiredFinalAllowanceHour, setRequiredFinalAllowanceHour] =
        useState(false);
    const [finalAllowanceHourErrorMessage, setFinalAllowanceHourErrorMessage] =
        useState('');

    const [requiredInitialDate, setRequiredInitialDate] = useState(false);
    const [initialDateErrorMessage, setInitialDateErrorMessage] = useState('');
    const [requiredFinalDate, setRequiredFinalDate] = useState(false);
    const [finalDateErrorMessage, setFinalDateErrorMessage] = useState('');

    const [mustRedirect, setMustRedirect] = useState(false);

    const [values, setValues] = useState({
        dateCreated: new Date(new Date().setHours(0, 0, 0, 0)),
        justificationType: '',
        allowanceReasonId: '',
        observation: '',
        workingHours: '',
        status: ''
    });

    const [files, setFiles] = useState({
        files: [],
        rejectedFiles: [],
        deletedFiles: []
    });

    const [dates, setDates] = useState({
        justifiedDate: null,
        initialAllowanceHour: new Date(new Date().setHours(0, 0, 0, 0)),
        finalAllowanceHour: new Date(new Date().setHours(0, 0, 0, 0)),
        initialDate: null,
        finalDate: null
    });

    const {
        myJustificationsForm,
        getMyJustificationsByUser,
        getMyJustificationsBySolicitation,
        createMyJustifications,
        updateMyJustifications
    } = clockRecordContext;

    const { getAllAllowanceReasons, allowanceReasons } = settingsContext;

    useEffect(() => {
        if (allowanceReasons && allowanceReasons.length > 0) {
            const AllowanceReasonsTemp = [...allowanceReasons];
            SortElementSelect(AllowanceReasonsTemp, 'name');
            setAllowanceReasonsArray(
                GetInputArray(
                    AllowanceReasonsTemp,
                    'id',
                    'name',
                    'type',
                    'deleted'
                )
            );
        }
    }, [allowanceReasons]);

    async function fetchData() {
        getAllAllowanceReasons();
        if (context === 'new') {
            getMyJustificationsByUser(auth.id, setLoading);
        } else {
            getMyJustificationsBySolicitation(id, setLoading);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (myJustificationsForm) {
            if (
                context === 'edit' &&
                myJustificationsForm.solicitation &&
                myJustificationsForm.solicitation.status &&
                myJustificationsForm.solicitation.status !== 'PENDING'
            ) {
                setMustRedirect(true);
            }

            setValues({
                id:
                    myJustificationsForm.solicitation &&
                    myJustificationsForm.solicitation.id
                        ? myJustificationsForm.solicitation.id
                        : 0,
                approvedBy:
                    myJustificationsForm.solicitation &&
                    myJustificationsForm.solicitation.approverName
                        ? myJustificationsForm.solicitation.approverName
                        : '',
                approverResponse:
                    myJustificationsForm.solicitation &&
                    myJustificationsForm.solicitation.approverResponse
                        ? myJustificationsForm.solicitation.approverResponse
                        : '',
                approverResponseDate:
                    myJustificationsForm.solicitation &&
                    myJustificationsForm.solicitation.approverResponseDate
                        ? FormatDateToFront(
                              myJustificationsForm.solicitation
                                  .approverResponseDate
                          )
                        : null,
                dateCreated:
                    myJustificationsForm.solicitation &&
                    myJustificationsForm.solicitation.dateCreated
                        ? FormatDateToFront(
                              myJustificationsForm.solicitation.dateCreated
                          )
                        : new Date(new Date().setHours(0, 0, 0, 0)),
                totalHours:
                    myJustificationsForm.justifiedClockRecord &&
                    myJustificationsForm.justifiedClockRecord.totalHours
                        ? myJustificationsForm.justifiedClockRecord.totalHours
                        : '-',
                justificationType:
                    myJustificationsForm.solicitation &&
                    myJustificationsForm.solicitation.class
                        ? myJustificationsForm.solicitation.class
                        : '',
                status:
                    myJustificationsForm.solicitation &&
                    myJustificationsForm.solicitation.status
                        ? myJustificationsForm.solicitation.status
                        : '',
                observation:
                    myJustificationsForm.solicitation &&
                    myJustificationsForm.solicitation.message
                        ? myJustificationsForm.solicitation.message
                        : '',
                workingHours: myJustificationsForm.workingHours
                    ? ConvertMilliInHourMin(myJustificationsForm.workingHours)
                    : '',
                allowanceReasonId:
                    myJustificationsForm.solicitation &&
                    myJustificationsForm.solicitation.allowanceId
                        ? myJustificationsForm.solicitation.allowanceId
                        : ''
            });

            setDates({
                justifiedDate:
                    myJustificationsForm.solicitation &&
                    myJustificationsForm.solicitation.requestedDate
                        ? FormatDateToFront(
                              myJustificationsForm.solicitation.requestedDate
                          )
                        : null,
                initialAllowanceHour:
                    myJustificationsForm.solicitation &&
                    myJustificationsForm.solicitation.requestedDate
                        ? FormatDateToFront(
                              myJustificationsForm.solicitation.requestedDate
                          )
                        : null,
                finalAllowanceHour:
                    myJustificationsForm.solicitation &&
                    myJustificationsForm.solicitation.endDate
                        ? FormatDateToFront(
                              myJustificationsForm.solicitation.endDate
                          )
                        : null,
                initialDate:
                    myJustificationsForm.solicitation &&
                    myJustificationsForm.solicitation.requestedDate
                        ? FormatDateToFront(
                              myJustificationsForm.solicitation.requestedDate
                          )
                        : null,
                finalDate:
                    myJustificationsForm.solicitation &&
                    myJustificationsForm.solicitation.endDate
                        ? FormatDateToFront(
                              myJustificationsForm.solicitation.endDate
                          )
                        : null
            });

            setFiles({
                files:
                    myJustificationsForm.solicitation &&
                    myJustificationsForm.files
                        ? myJustificationsForm.files
                        : [],
                rejectedFiles: [],
                deletedFiles: []
            });
        }
    }, [myJustificationsForm]);

    const redirect = go => {
        if (go) return <Redirect to={`/MyJustifications`} />;
    };

    const verifySubmit = () => {
        let res = true;
        setRequiredFinalDate(false);
        setRequiredInitialDate(false);

        if (!values.justificationType) {
            res = false;
            setRequiredJustificationType(true);
        }

        if (!values.observation.trim()) {
            res = false;
            setRequiredObservation(true);
        }

        if (requiredJustifiedDate) {
            res = false;
        }

        if (values.justificationType !== JustificationType.TOTAL_PAYMENT) {
            if (!dateValidation(dates.justifiedDate)) {
                res = false;
                setRequiredJustifiedDate(true);
                setJustifiedDateErrorMessage(t('GENERAL.invalidDate'));
            }
            if (dates.justifiedDate === null) {
                res = false;
                setRequiredJustifiedDate(true);
                setJustifiedDateErrorMessage(t('GENERAL.requiredField'));
            }
        }

        if (values.justificationType === JustificationType.PARTIAL_PAYMENT) {
            if (!dateValidation(dates.initialAllowanceHour)) {
                res = false;
                setRequiredInitialAllowanceHour(true);
                setInitialAllowanceHourErrorMessage(t('GENERAL.invalidDate'));
            }
            if (dates.initialAllowanceHour === null) {
                res = false;
                setRequiredInitialAllowanceHour(true);
                setInitialAllowanceHourErrorMessage(t('GENERAL.requiredField'));
            }

            if (!dateValidation(dates.finalAllowanceHour)) {
                res = false;
                setRequiredFinalAllowanceHour(true);
                setFinalAllowanceHourErrorMessage(t('GENERAL.invalidDate'));
            }
            if (dates.finalAllowanceHour === null) {
                res = false;
                setRequiredFinalAllowanceHour(true);
                setFinalAllowanceHourErrorMessage(t('GENERAL.requiredField'));
            }
            if (
                dates.finalAllowanceHour &&
                dates.initialAllowanceHour &&
                dates.initialAllowanceHour.getTime() >
                    dates.finalAllowanceHour.getTime()
            ) {
                res = false;
                setRequiredInitialAllowanceHour(true);
                setRequiredFinalAllowanceHour(true);
                setFinalAllowanceHourErrorMessage(
                    t('HR.finalAllowanceHourSmallerThanInitial')
                );
                setInitialAllowanceHourErrorMessage(
                    t('HR.initialAllowanceHourBiggerThanFinal')
                );
            }
            if (
                dates.finalAllowanceHour &&
                dates.initialAllowanceHour &&
                dates.initialAllowanceHour.getHours() ===
                    dates.finalAllowanceHour.getHours() &&
                dates.initialAllowanceHour.getMinutes() ===
                    dates.finalAllowanceHour.getMinutes()
            ) {
                res = false;
                setRequiredFinalAllowanceHour(true);
                setFinalAllowanceHourErrorMessage(
                    t('HR.initialAndFinalAllowanceHoursEqual')
                );
            }
        }

        if (
            values.justificationType !==
            JustificationType.INCONSISTENCY_JUSTIFICATION
        ) {
            if (!values.allowanceReasonId) {
                res = false;
                setRequiredAllowanceReasonId(true);
            }
        }

        if (values.justificationType === JustificationType.TOTAL_PAYMENT) {
            if (!dateValidation(dates.initialDate)) {
                res = false;
                setRequiredInitialDate(true);
                setInitialDateErrorMessage(t('GENERAL.invalidDate'));
            }
            if (dates.initialDate === null) {
                res = false;
                setRequiredInitialDate(true);
                setInitialDateErrorMessage(t('GENERAL.requiredField'));
            }

            if (!dateValidation(dates.finalDate)) {
                res = false;
                setRequiredFinalDate(true);
                setFinalDateErrorMessage(t('GENERAL.invalidDate'));
            }
            if (dates.finalDate === null) {
                res = false;
                setRequiredFinalDate(true);
                setFinalDateErrorMessage(t('GENERAL.requiredField'));
            }
            if (
                dates.finalDate &&
                dates.initialDate &&
                dates.initialDate.getTime() > dates.finalDate.getTime()
            ) {
                res = false;
                setRequiredFinalDate(true);
                setFinalDateErrorMessage(t('HR.finalDateSmallerThanInitial'));
            }

            // Possible Checks for Interval has Inconsistency
            if (
                values.justificationType === JustificationType.TOTAL_PAYMENT &&
                dateValidation(dates.finalDate) &&
                dateValidation(dates.initialDate) &&
                dates.initialDate.getTime() <= dates.finalDate.getTime()
            ) {
                let currentDate = new Date(dates.initialDate);
                let lastDate = new Date(dates.finalDate);
                lastDate.setDate(lastDate.getDate() + 1);
                let inconsistencyQnt = 0;
                let hasRegularClockRecord = false;
                while (!isSameDate(currentDate, lastDate)) {
                    // Check is Vacation or Holiday
                    let clockRecord = myJustificationsForm.clockRecords.find(
                        x => isSameDate(FormatDateToFront(x.date), currentDate)
                    );
                    if (clockRecord != null) {
                        if (clockRecord.status === 'REGULAR') {
                            hasRegularClockRecord = true;
                        }
                        if (clockRecord.status === 'INCONSISTENCY') {
                            inconsistencyQnt += 1;
                        }
                    }
                    currentDate.setDate(currentDate.getDate() + 1);
                }
                if (hasRegularClockRecord || inconsistencyQnt === 0) {
                    res = false;
                    setRequiredFinalDate(true);
                    setRequiredInitialDate(true);
                    setFinalDateErrorMessage(
                        t('HR.datesDoesNotHaveInconsistency')
                    );
                    setInitialDateErrorMessage(
                        t('HR.datesDoesNotHaveInconsistency')
                    );
                }
            }
            // Check for same periods
            if (dates.finalDate && myJustificationsForm.justifications.filter(x => x.endDate ? FormatDateToFront(x.requestedDate).getTime() <= dates.finalDate.getTime() && dates.finalDate.getTime() <= FormatDateToFront(x.endDate).getTime() && x.id !== values.id : false).length > 0) {
                res = false;
                setRequiredFinalDate(true);
                setFinalDateErrorMessage(t("HR.justificationPeriodAlreadyRegistered"));
            }

            if (dates.initialDate && myJustificationsForm.justifications.filter(x => x.requestedDate ? isSameDate(
                FormatDateToFront(x.requestedDate),
                dates.initialDate
            ) && x.id !== values.id : false).length > 0) {
                res = false;
                setRequiredInitialDate(true);
                setInitialDateErrorMessage(t("HR.justificationPeriodAlreadyRegistered"));
            }

            if (dates.finalDate && myJustificationsForm.justifications.filter(x => x.requestedDate ? isSameDate(
                FormatDateToFront(x.requestedDate),
                dates.finalDate
            ) && x.id !== values.id : false).length > 0) {
                res = false;
                setRequiredFinalDate(true);
                setFinalDateErrorMessage(t("HR.justificationPeriodAlreadyRegistered"));
            }


            if (dates.initialDate && myJustificationsForm.justifications.filter(x => x.requestedDate && x.endDate ? FormatDateToFront(x.requestedDate).getTime() <= dates.initialDate.getTime() && dates.initialDate.getTime() <= FormatDateToFront(x.endDate).getTime() && x.id !== values.id : false).length > 0) {
                res = false;
                setRequiredInitialDate(true);
                setInitialDateErrorMessage(t("HR.justificationPeriodAlreadyRegistered"));
            }

            if (dates.finalDate && dates.initialDate && myJustificationsForm.justifications.filter(x => x.endDate ? FormatDateToFront(x.requestedDate).getTime() >= dates.initialDate.getTime() && FormatDateToFront(x.endDate).getTime() <= dates.finalDate.getTime() && x.id !== values.id : false).length > 0) {
                res = false;
                setRequiredFinalDate(true);
                setFinalDateErrorMessage(t("HR.justificationPeriodAlreadyRegistered"));
                setRequiredInitialDate(true);
                setFinalDateErrorMessage(t("HR.justificationPeriodAlreadyRegistered"));
            }
        }

        return res;
    };

    const handleSubmit = () => {
        const canSubmit = verifySubmit();
        if (canSubmit) {
            let formData = new FormData();
            formData.append('myJustifications.id', values.id ? values.id : 0);
            formData.append(
                'myJustifications.class',
                values.justificationType ? values.justificationType : ''
            );
            formData.append(
                'myJustifications.dateCreated',
                FormatDateToDataBase(values.dateCreated)
            );
            let requestedDate = '';
            if (
                values.justificationType === JustificationType.PARTIAL_PAYMENT
            ) {
                requestedDate = FormatDateToDataBase(
                    new Date(
                        dates.justifiedDate.setHours(
                            dates.initialAllowanceHour.getHours(),
                            dates.initialAllowanceHour.getMinutes(),
                            0,
                            0
                        )
                    )
                );

                formData.append(
                    'myJustifications.endDate',
                    FormatDateToDataBase(
                        new Date(
                            dates.justifiedDate.setHours(
                                dates.finalAllowanceHour.getHours(),
                                dates.finalAllowanceHour.getMinutes(),
                                0,
                                0
                            )
                        )
                    )
                );
            }
            if (values.justificationType === JustificationType.TOTAL_PAYMENT) {
                requestedDate = FormatDateToDataBase(
                    new Date(dates.initialDate.setHours(0, 0, 0, 0))
                );

                formData.append(
                    'myJustifications.endDate',
                    FormatDateToDataBase(
                        new Date(dates.finalDate.setHours(0, 0, 0, 0))
                    )
                );
            }
            if (
                values.justificationType ===
                JustificationType.INCONSISTENCY_JUSTIFICATION
            ) {
                requestedDate = FormatDateToDataBase(
                    new Date(dates.justifiedDate.setHours(0, 0, 0, 0))
                );
            }
            formData.append(
                'myJustifications.allowanceId',
                values.allowanceReasonId
            );
            formData.append('myJustifications.requestedDate', requestedDate);
            formData.append('myJustifications.requesterId', auth.id);
            formData.append(
                'myJustifications.message',
                values.observation ? values.observation : ''
            );

            for (let i in files.files) {
                if (!files.files[i].id) {
                    formData.append('files', files.files[i]);
                }
            }

            for (let i in files.deletedFiles) {
                formData.append('deletedFiles', files.deletedFiles[i]);
            }

            const emailData = {};

            if (context === 'edit') {
                emailData.solicitationId = values.id;
            }

            emailData.requesterName = auth.name;
            emailData.email = auth.userName;
            emailData.justificationType = GetJustificationType(
                values.justificationType
            );
            emailData.justifiedDate =
                values.justificationType === JustificationType.TOTAL_PAYMENT
                    ? dates.initialDate
                    : dates.justifiedDate;
            emailData.justifiedEndDate = dates.finalDate;
            emailData.solicitationDate = values.dateCreated;
            emailData.observation = values.observation;

            if (context === 'new') {
                createMyJustifications(
                    formData,
                    setMustRedirect,
                    emailData,
                    auth.id
                );
            } else {
                updateMyJustifications(
                    formData,
                    setMustRedirect,
                    emailData,
                    auth.id
                );
            }
        }
    };

    const handleChange = (name, value) => {
        setHasChanged(true);
        let val = value;
        if (name === 'justificationType') {
            setValues({ ...values, [name]: val, allowanceReasonId: '' });
        } else {
            setValues({ ...values, [name]: val });
        }
    };

    const handleDates = (name, e) => {
        setHasChanged(true);
        setDates({
            ...dates,
            [name]: e
        });
        if (name === 'justifiedDate') {
            setRequiredJustifiedDate(false);
        }
        if (name === 'finalAllowanceHour') {
            setRequiredFinalAllowanceHour(false);
        }
        if (name === 'initialAllowanceHour') {
            setRequiredInitialAllowanceHour(false);
        }
        if (name === 'initialDate') {
            setRequiredInitialDate(false);
        }
        if (name === 'finalDate') {
            setRequiredFinalDate(false);
        }
        if (dateValidation(e)) {
            if (name === 'justifiedDate') {
                let validDate = new Date(new Date(e).setHours(0, 0, 0, 0));
                setDates({
                    ...dates,
                    [name]: validDate
                });
                if (
                    values.justificationType !== JustificationType.TOTAL_PAYMENT
                ) {
                    if (
                        !myJustificationsForm.clockRecords ||
                        !myJustificationsForm.clockRecords.some(
                            x =>
                                isSameDate(
                                    FormatDateToFront(x.date),
                                    validDate
                                ) && x.status === 'INCONSISTENCY'
                        )
                    ) {
                        setRequiredJustifiedDate(true);
                        setJustifiedDateErrorMessage(
                            'Data não possui inconsistência de ponto.'
                        );
                    }

                    if (
                        myJustificationsForm.justifications &&
                        myJustificationsForm.justifications.some(
                            x =>
                                x.id !== values.id &&
                                isSameDate(
                                    FormatDateToFront(x.requestedDate),
                                    validDate
                                )
                        )
                    ) {
                        setRequiredJustifiedDate(true);
                        setJustifiedDateErrorMessage(
                            'Data já possui justificativa associada.'
                        );
                    }
                }
            } else {
                let validDate = new Date(e);
                setDates({
                    ...dates,
                    [name]: validDate
                });
            }
        }
    };

    const handleCancel = () => {
        if (hasChanged) {
            setOpenDialog(true);
        } else {
            setMustRedirect(true);
        }
    };

    return (
        <React.Fragment>
            {redirect(mustRedirect)}
            <Transition loading={loading} newDesign={true}>
                <HeaderContainer>
                    <Breadcrumb
                        newDesign={true}
                        routes={[
                            {
                                label: t('HR.humanResource'),
                                to: '/HumanResources'
                            },
                            { label: t('HR.clock') },
                            {
                                label: t('HR.myJustifications'),
                                to: '/MyJustifications'
                            },
                            {
                                label:
                                    context === 'view'
                                        ? `${t('GENERAL.view')} ${t(
                                              'HR.justification'
                                          )}`
                                        : context === 'edit'
                                        ? `${t('GENERAL.edit')} ${t(
                                              'HR.justification'
                                          )}`
                                        : `${t('GENERAL.add')} ${t(
                                              'HR.justification'
                                          )}`
                            }
                        ]}
                    />
                    <div className="container-headerNewDesign">
                        <h1 className={classes.titleNewDesign}>
                            {context === 'view'
                                ? `${t('GENERAL.view')} ${t(
                                      'HR.justification'
                                  )}`
                                : context === 'edit'
                                ? `${t('GENERAL.edit')} ${t(
                                      'HR.justification'
                                  )}`
                                : `${t('GENERAL.add')} ${t(
                                      'HR.justification'
                                  )}`}
                        </h1>
                    </div>
                </HeaderContainer>
                <ContentContainer>
                    <Paper className={styles.space}>
                        <div className="cabecalho">
                            {context === 'view'
                                ? `${t('GENERAL.view').toUpperCase()} ${t(
                                      'HR.justification'
                                  ).toUpperCase()}`
                                : context === 'edit'
                                ? `${t('GENERAL.edit').toUpperCase()} ${t(
                                      'HR.justification'
                                  ).toUpperCase()}`
                                : `${t(
                                      'GENERAL.detailingOf'
                                  ).toUpperCase()} ${t(
                                      'HR.justification'
                                  ).toUpperCase()}`}
                        </div>
                        <Grid
                            container
                            item
                            spacing={2}
                            md={12}
                            lg={12}
                            style={{ padding: '32px' }}
                        >
                            <Grid item xs={12} sm={2}>
                                <Typography
                                    className={styles.textMargin}
                                    variant={'RobotoBold'}
                                    fontSize={15}
                                    textColor={'primary'}
                                >
                                    {t('GENERAL.solicitationDate')}
                                    {context === 'new' && (
                                        <DefaultTooltip
                                            fontSize={'11'}
                                            className={styles.iconsRight}
                                            color={'#6D6F71'}
                                            text={t(
                                                'HR.solicitationDateTooltip'
                                            )}
                                        />
                                    )}
                                </Typography>
                                <Typography
                                    className={styles.textMargin}
                                    variant={'RobotoRegular'}
                                    fontSize={13}
                                    textColor={'secondary'}
                                >
                                    {dateToBR(values.dateCreated)}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={2}>
                                <Typography
                                    className={styles.textMargin}
                                    variant={'RobotoBold'}
                                    fontSize={15}
                                    textColor={'primary'}
                                >
                                    {t('HR.workload')}
                                    {context === 'new' && (
                                        <DefaultTooltip
                                            fontSize={'11'}
                                            className={styles.iconsRight}
                                            color={'#6D6F71'}
                                            text={t('HR.workloadTooltip')}
                                        />
                                    )}
                                </Typography>
                                <Typography
                                    className={styles.textMargin}
                                    variant={'RobotoRegular'}
                                    fontSize={13}
                                    textColor={'secondary'}
                                >
                                    {values.workingHours}
                                </Typography>
                            </Grid>

                            {context !== 'new' && (
                                <>
                                    {values.justificationType !==
                                        JustificationType.TOTAL_PAYMENT && (
                                        <Grid item xs={12} sm={2}>
                                            <Typography
                                                className={styles.textMargin}
                                                variant={'RobotoBold'}
                                                fontSize={15}
                                                textColor={'primary'}
                                            >
                                                {t('HR.workedHours')}
                                            </Typography>
                                            <Typography
                                                className={styles.textMargin}
                                                variant={'RobotoRegular'}
                                                fontSize={13}
                                                textColor={'secondary'}
                                            >
                                                {values.totalHours}
                                            </Typography>
                                        </Grid>
                                    )}
                                    <Grid item xs={12} sm={4}>
                                        <Typography
                                            className={styles.textMargin}
                                            variant={'RobotoBold'}
                                            fontSize={15}
                                            textColor={'primary'}
                                        >
                                            {t('GENERAL.status')}
                                        </Typography>
                                        <Grid
                                            container
                                            wrap="nowrap"
                                            alignItems="center"
                                        >
                                            <Grid item>
                                                <Typography
                                                    className={
                                                        styles.textMargin
                                                    }
                                                    variant={'RobotoRegular'}
                                                    fontSize={13}
                                                    textColor={'secondary'}
                                                >
                                                    {GetJustificationStatus(
                                                        values.status
                                                    )}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                {values.status ? (
                                                    values.status ===
                                                    'ALLOWED' ? (
                                                        <CheckCircleIcon
                                                            style={{
                                                                marginLeft:
                                                                    '0.2rem',
                                                                color: '#7FE46B'
                                                            }}
                                                        />
                                                    ) : values.status ===
                                                      'DENIED' ? (
                                                        <ErrorIcon
                                                            style={{
                                                                marginLeft:
                                                                    '0.2rem',
                                                                color: '#F3222F'
                                                            }}
                                                        />
                                                    ) : (
                                                        <AccessTimeIcon
                                                            style={{
                                                                marginLeft:
                                                                    '0.2rem',
                                                                color: '#2C3E51'
                                                            }}
                                                        />
                                                    )
                                                ) : null}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            )}

                            <Box width="100%" />

                            {context !== 'new' && values.status !== 'PENDING' && (
                                <>
                                    <Grid item xs={12} sm={3}>
                                        <Input
                                            context={'view'}
                                            label={
                                                values.status === 'ALLOWED'
                                                    ? 'GENERAL.approvedBy'
                                                    : 'GENERAL.refusedBy'
                                            }
                                            value={values.approvedBy}
                                            valueName={'approvedBy'}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={2}>
                                        <InputDate
                                            context={'view'}
                                            label={
                                                values.status === 'ALLOWED'
                                                    ? 'GENERAL.approvalDate'
                                                    : 'GENERAL.refusalDate'
                                            }
                                            value={values.approverResponseDate}
                                        />
                                    </Grid>

                                    <Box width="100%" />

                                    {values.status === 'DENIED' && (
                                        <Grid item xs={12} sm={8}>
                                            <Input
                                                context={'view'}
                                                label={'GENERAL.reason'}
                                                value={values.approverResponse}
                                                valueName={'approverResponse'}
                                                rows={5}
                                                maxLength={200}
                                                handleChange={handleChange}
                                            />
                                        </Grid>
                                    )}
                                </>
                            )}

                            <Box width="100%" />

                            <Grid item xs={12} sm={12}>
                                <Typography
                                    className={styles.myActivitiesTextsPadding}
                                    variant={'RobotoBold'}
                                    fontSize={16}
                                    textColor={'secondary'}
                                >
                                    {context === 'new'
                                        ? t(
                                              'HR.informJustificationTypeAndDataBelow'
                                          )
                                        : t(
                                              'HR.checkBelowMoreInformationAboutJustification'
                                          )}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={5}>
                                <Input
                                    required
                                    context={context}
                                    label={'HR.justificationType'}
                                    isSelect
                                    arraySelected={JustificationsTypeArray.filter(
                                        x => x.isValidJustification
                                    )}
                                    value={values.justificationType}
                                    valueName={'justificationType'}
                                    handleChange={handleChange}
                                    error={requiredJustificationType}
                                    helperText={
                                        requiredJustificationType &&
                                        t('GENERAL.requiredField')
                                    }
                                    setRequired={setRequiredJustificationType}
                                />
                            </Grid>

                            <Box width="100%" />
                            {values.justificationType ? (
                                <>
                                    {values.justificationType ===
                                        JustificationType.PARTIAL_PAYMENT ||
                                    values.justificationType ===
                                        JustificationType.TOTAL_PAYMENT ? (
                                        <>
                                            <Grid item xs={12} sm={4}>
                                                <Input
                                                    required
                                                    context={context}
                                                    isSelect
                                                    arraySelected={
                                                        context === 'view'
                                                            ? allowanceReasonsArray
                                                            : allowanceReasonsArray.filter(
                                                                  x =>
                                                                      x.type ===
                                                                          GetAllowanceType(
                                                                              values.justificationType
                                                                          ) &&
                                                                      !x.deleted
                                                              )
                                                    }
                                                    label={'HR.typeOfReason'}
                                                    value={
                                                        values.allowanceReasonId
                                                    }
                                                    valueName={
                                                        'allowanceReasonId'
                                                    }
                                                    handleChange={handleChange}
                                                    error={
                                                        requiredAllowanceReasonId
                                                    }
                                                    helperText={
                                                        requiredAllowanceReasonId &&
                                                        t(
                                                            'GENERAL.requiredField'
                                                        )
                                                    }
                                                    setRequired={
                                                        setRequiredAllowanceReasonId
                                                    }
                                                />
                                            </Grid>
                                            <Box width="100%" />
                                            {context !== 'view' &&
                                                values.justificationType ===
                                                    JustificationType.TOTAL_PAYMENT &&
                                                values.allowanceReasonId && (
                                                    <Grid item xs={12} sm={12}>
                                                        <BlueInfoText
                                                            fontSize={15}
                                                            text={`${t(
                                                                'HR.forThisTypeOfAllowance'
                                                            )}
                                                        ${
                                                            values.allowanceReasonId
                                                                ? allowanceReasons.find(
                                                                      x =>
                                                                          x.id ===
                                                                          values.allowanceReasonId
                                                                  )
                                                                      .daysAmount ||
                                                                  0
                                                                : 0
                                                        }
                                                        ${t(
                                                            'GENERAL.dayOrDays'
                                                        )}.`}
                                                        />
                                                    </Grid>
                                                )}
                                            <Box width="100%" />
                                        </>
                                    ) : null}

                                    <Box width="100%" />

                                    {values.justificationType ===
                                    JustificationType.TOTAL_PAYMENT ? (
                                        <>
                                            <Grid item xs={12} sm={2}>
                                                <InputDate
                                                    required
                                                    context={context}
                                                    label={
                                                        'GENERAL.initialDate'
                                                    }
                                                    value={dates.initialDate}
                                                    valueName={'initialDate'}
                                                    handleChange={handleDates}
                                                    maxDate={new Date().setDate(
                                                        new Date().getDate() - 1
                                                    )}
                                                    error={requiredInitialDate}
                                                    helperText={
                                                        requiredInitialDate &&
                                                        initialDateErrorMessage
                                                    }
                                                    setRequired={
                                                        setRequiredInitialDate
                                                    }
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={2}>
                                                <InputDate
                                                    required
                                                    context={context}
                                                    label={'GENERAL.finalDate'}
                                                    value={dates.finalDate}
                                                    valueName={'finalDate'}
                                                    handleChange={handleDates}
                                                    maxDate={new Date().setDate(
                                                        new Date().getDate() - 1
                                                    )}
                                                    error={requiredFinalDate}
                                                    helperText={
                                                        requiredFinalDate &&
                                                        finalDateErrorMessage
                                                    }
                                                    setRequired={
                                                        setRequiredFinalDate
                                                    }
                                                />
                                            </Grid>
                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                {context !== 'view' && (
                                                    <DefaultTooltip
                                                        fontSize={'11'}
                                                        className={
                                                            styles.iconsRight
                                                        }
                                                        color={'#6D6F71'}
                                                        text={t(
                                                            'HR.forOnlyOneDayAllowance'
                                                        )}
                                                    />
                                                )}
                                            </Box>
                                        </>
                                    ) : (
                                        <Grid item xs={12} sm={4}>
                                            <InputDate
                                                required
                                                context={context}
                                                label={'HR.justifiedDate'}
                                                value={dates.justifiedDate}
                                                valueName={'justifiedDate'}
                                                maxDate={new Date().setDate(
                                                    new Date().getDate() - 1
                                                )}
                                                handleChange={handleDates}
                                                error={requiredJustifiedDate}
                                                helperText={
                                                    requiredJustifiedDate &&
                                                    justifiedDateErrorMessage
                                                }
                                                setRequired={
                                                    setRequiredJustifiedDate
                                                }
                                            />
                                        </Grid>
                                    )}
                                    <Box width="100%" />
                                    {values.justificationType ===
                                        JustificationType.PARTIAL_PAYMENT && (
                                        <>
                                            <Grid item xs={12} sm={2}>
                                                <InputDate
                                                    type={'time'}
                                                    required
                                                    context={context}
                                                    label={
                                                        'HR.initialAllowanceHour'
                                                    }
                                                    value={
                                                        dates.initialAllowanceHour
                                                    }
                                                    valueName={
                                                        'initialAllowanceHour'
                                                    }
                                                    handleChange={handleDates}
                                                    keyboardIcon={
                                                        <AccessTimeIcon />
                                                    }
                                                    error={
                                                        requiredInitialAllowanceHour
                                                    }
                                                    helperText={
                                                        requiredInitialAllowanceHour &&
                                                        initialAllowanceHourErrorMessage
                                                    }
                                                    setRequired={
                                                        setRequiredInitialAllowanceHour
                                                    }
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={2}>
                                                <InputDate
                                                    type={'time'}
                                                    required
                                                    context={context}
                                                    label={
                                                        'HR.finalAllowanceHour'
                                                    }
                                                    value={
                                                        dates.finalAllowanceHour
                                                    }
                                                    valueName={
                                                        'finalAllowanceHour'
                                                    }
                                                    handleChange={handleDates}
                                                    keyboardIcon={
                                                        <AccessTimeIcon />
                                                    }
                                                    error={
                                                        requiredFinalAllowanceHour
                                                    }
                                                    helperText={
                                                        requiredFinalAllowanceHour &&
                                                        finalAllowanceHourErrorMessage
                                                    }
                                                    setRequired={
                                                        setRequiredFinalAllowanceHour
                                                    }
                                                />
                                            </Grid>
                                            <Box width="100%" />
                                        </>
                                    )}

                                    <Grid item xs={12} sm={8}>
                                        <Input
                                            required
                                            context={context}
                                            label={'GENERAL.observation'}
                                            value={values.observation}
                                            valueName={'observation'}
                                            rows={5}
                                            maxLength={200}
                                            handleChange={handleChange}
                                            error={requiredObservation}
                                            helperText={
                                                requiredObservation &&
                                                t('GENERAL.requiredField')
                                            }
                                            setRequired={setRequiredObservation}
                                        />
                                    </Grid>

                                    <Box width="100%" />

                                    <Grid item xs={12} sm={8}>
                                        <InputMultiDropzone
                                            context={context}
                                            files={files}
                                            setFiles={setFiles}
                                            setHasChanged={setHasChanged}
                                        />
                                    </Grid>
                                </>
                            ) : null}

                            <Box width="100%" />
                            <div className={styles.buttonsWrapper}>
                                <Buttons
                                    onClick={handleCancel}
                                    tipo="BN-noback"
                                    conteudo={
                                        context !== 'view'
                                            ? t('GENERAL.cancel')
                                            : t('GENERAL.comeBack')
                                    }
                                />
                                {values.justificationType ? (
                                    context === 'new' ||
                                    (context === 'edit' &&
                                        values.status === 'PENDING') ? (
                                        <Buttons
                                            onClick={handleSubmit}
                                            tipo="BN-blue"
                                            conteudo={
                                                context === 'new'
                                                    ? t('HR.addJustification')
                                                    : t('HR.saveJustification')
                                            }
                                        />
                                    ) : null
                                ) : null}
                            </div>
                        </Grid>
                    </Paper>
                    <DialogCancelOperation
                        open={openDialog}
                        setOpen={setOpenDialog}
                        path={'/MyJustifications'}
                        dialogTitle={t('GENERAL.exitWithoutSaving')}
                        dialogText={t('GENERAL.cancelEditText')}
                        dialogConfirmText={t('GENERAL.continueEditing')}
                    />
                </ContentContainer>
                <Footer />
            </Transition>
        </React.Fragment>
    );
};

export default MyJustificationsForm;
