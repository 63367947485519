import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';

const useStyles = makeStyles(theme => ({
    blueBottun: {
        backgroundColor: '#2c3e51',
        padding: '14px 24px',
        fontWeight: 'normal',
        fontSize: '14px'
    },
    noBackBottun: {
        padding: '14px 24px',
        fontWeight: 'normal',
        fontSize: '14px'
    },
    borderBackButton: {
        padding: '14px 24px',
        fontWeight: 'bold',
        fontSize: '14px',
        color: '#2c3e51',
        border: '1px solid #2c3e51'
    },
    darkBlueBottun: {
        backgroundColor: '#7ea3b7',
        fontWeight: 'normal'
    },
    darkNoBackBottun: {
        fontFamily: 'Roboto',
        fontWeight: 'normal',
        color: '#7ea3b7'
    },
    blueBAF: {
        backgroundColor: '#2c3e51'
    },
    darkBAF: {
        backgroundColor: '#7ea3b7'
    },
    grayButton: {
        backgroundColor: '#6D6F71',
        padding: '14px 24px',
        fontWeight: 'normal',
        fontSize: '14px'
    }
}));

export default function Buttons({
    tipo,
    conteudo,
    onClick,
    disabled,
    className,
    isLoading,
    ...props
}) {
    const classes = useStyles();
    if (tipo === 'BN-blue') {
        return (
            <Button
                disabled={disabled || isLoading}
                onClick={onClick}
                variant="contained"
                color="primary"
                className={`${classes.blueBottun} ${className}`}
                {...props}
            >
                {isLoading ? (
                    <CircularProgress color="secondary" size={24} />
                ) : (
                    conteudo
                )}
            </Button>
        );
    } else if (tipo === 'BN-noback') {
        return (
            <Button
                disabled={disabled}
                onClick={onClick}
                color="primary"
                className={`${classes.noBackBottun} ${className}`}
                {...props}
            >
                {conteudo}
            </Button>
        );
    } else if (tipo === 'borderBackButton') {
        return (
            <Button
                disabled={disabled}
                onClick={onClick}
                color="primary"
                className={`${classes.borderBackButton} ${className}`}
                {...props}
            >
                {conteudo}
            </Button>
        );
    } else if (tipo === 'BN-noback') {
        return (
            <Button
                disabled={disabled}
                onClick={onClick}
                color="primary"
                className={`${classes.noBackBottun} ${className}`}
                {...props}
            >
                {conteudo}
            </Button>
        );
    } else if (tipo === 'BD-blue') {
        return (
            <Button
                disabled={disabled}
                type="submit"
                color="primary"
                className={classes.darkBlueBottun}
                {...props}
            >
                {conteudo}
            </Button>
        );
    } else if (tipo === 'BD-noback') {
        return (
            <Button
                disabled={disabled}
                type="submit"
                color="primary"
                className={classes.darkNoBackBottun}
                {...props}
            >
                {conteudo}
            </Button>
        );
    } else if (tipo === 'BAF-blue') {
        return (
            <Fab
                disabled={disabled}
                color="primary"
                aria-label={conteudo}
                className={classes.blueBAF}
            >
                <AddIcon />
            </Fab>
        );
    } else if (tipo === 'BAF-dark') {
        return (
            <Fab
                disabled={disabled}
                color="primary"
                aria-label={conteudo}
                className={classes.darkBAF}
            >
                <AddIcon />
            </Fab>
        );
    } else if (tipo === 'BN-gray') {
        return (
            <Button
                disabled={disabled}
                onClick={onClick}
                variant="contained"
                color="primary"
                className={classes.grayButton}
                {...props}
            >
                {conteudo}
            </Button>
        );
    } else {
        return <Button variant="contained">{conteudo}</Button>;
    }
}
