import React from 'react';
import { screenStyles } from './Styles';
import Typography from 'components/typography/Typography';
import DefaultTooltip from 'components/infoTooltip/DefaultTooltip';

export const CardWithIcon = props => {
    const { head, content, icon, contentColor, toolTipContent } = props;
    const styles = screenStyles();

    return (
        <div className={styles.CardWithIconContainer}>
            <div
                style={{ background: contentColor }}
                className={styles.CardWithIconImageContainer}
            >
                {icon}
            </div>
            <div className={styles.CardWithIconTextContainer}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography
                        variant={'RobotoBold'}
                        fontSize={14}
                        textColor={'secondary'}
                        margin={'0 8px 0 0'}
                    >
                        {head.toUpperCase()}
                    </Typography>
                    {toolTipContent ? (
                        <DefaultTooltip
                            style={{ color: '#6D6F71' }}
                            fontSize="small"
                            text={toolTipContent}
                        />
                    ) : null}
                </div>
                <p
                    className={styles.CardWithIconText}
                    style={{ color: contentColor }}
                >
                    {content}
                </p>
            </div>
        </div>
    );
};
