import React, { useReducer } from "react";
import { SEARCH } from "../types";
import searchContext from "./searchContext";
import searchReducer from "./searchReducer";

const SearchState = props => {
  const initialState = {
    objectfilter: [],
    filter: "",
    loading: false
  };

  const [state, dispatch] = useReducer(searchReducer, initialState);
  
  //Search
  function searchTermChanged(searchTerm) {
      dispatch({
        type: SEARCH.SEARCH,
        filter: searchTerm,
      })
    }

    return (
    
        <searchContext.Provider
            value={{
                loading: state.loading,
                filter: state.filter,
                searchTermChanged,
            }}
            >
            {props.children}
        </searchContext.Provider>
    );
}
export default SearchState;