import React from 'react';
import { useTranslation } from 'react-i18next';

import Avatar from '@material-ui/core/Avatar';

import { screenStyles } from './Styles';
import Typography from 'components/typography/Typography';

import { ConvertMilliInHourMin } from 'utils/dates/ConvertMilliInHourMin1';

export const CollaboratorCard = props => {
    const { values } = props;
    const [t] = useTranslation();

    const styles = screenStyles();

    return (
        <React.Fragment>
            <div className={styles.collaboratorInfoWrapper}>
                <Avatar
                    className={styles.collaboratorAvatar}
                    alt={values.name}
                    src={process.env.REACT_APP_IMGSERVER + values.imageName}
                    sx={{ width: 128, height: 128 }}
                />
                <div className={styles.collaboratorInfoCardPersonalInfo}>
                    <div
                        className={
                            styles.collaboratorInfoCardPersonalInfoFirstContent
                        }
                    >
                        <Typography
                            className={styles.textMargin}
                            variant={'RobotoBold'}
                            fontSize={10}
                            textColor={'white'}
                        >
                            {t('GENERAL.collaborator').toUpperCase()}
                        </Typography>
                        <Typography
                            className={styles.textMargin}
                            variant={'RobotoRegular'}
                            fontSize={14}
                            textColor={'white'}
                        >
                            {values.name}
                        </Typography>
                        <Typography
                            className={styles.textMargin}
                            variant={'RobotoLight'}
                            fontSize={11}
                            textColor={'white'}
                        >
                            {values.job}
                        </Typography>
                    </div>
                    <div
                        className={
                            styles.collaboratorInfoCardPersonalInfoSecondContent
                        }
                    >
                        <Typography
                            className={styles.textMargin}
                            variant={'RobotoBold'}
                            fontSize={10}
                            textColor={'white'}
                        >
                            {t('HR.contractType').toUpperCase()}
                        </Typography>
                        <Typography
                            className={styles.textMargin}
                            variant={'RobotoRegular'}
                            fontSize={14}
                            textColor={'white'}
                        >
                            {values.ruleset}
                        </Typography>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default CollaboratorCard;
