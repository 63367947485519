/* eslint-disable */
import Snackbar from "@material-ui/core/Snackbar";
import MySnackbarContentWrapper from "components/layout/Message";
import Spinner from "components/layout/spinners/Index";
import ProfilesContext from "context/profiles/profilesContext";
import React, { useContext, useEffect, useState } from "react";
import { animated, useTransition } from "react-spring";
import DashboardNavBar from "./layout/searchIndex";
import SideBar from "./layout/sidebar/Sidebar";

const Dashboard = () => {
  const profilesContext = useContext(ProfilesContext);
  const [snackBar, setSnackBar] = useState({
    open: false,
    kind: "",
    content: ""
  });

  const {
    loading,
    response,
    responseType,
    successiveActions
  } = profilesContext;

  useEffect(() => {
    if (responseType === "SUCCESS") {
      handleSnack({ kind: "success", content: response });
    }
    if (responseType === "ERROR") {
      handleSnack({ kind: "error", content: response });
    }

    return () => {};
  }, [responseType, response, successiveActions]);

  const handleSnack = () => {
    setSnackBar({ ...snackBar, open: true });
  };
  const handleSnackClose = () => {
    setSnackBar({ ...snackBar, open: false });
  };

  const transitions = useTransition(loading, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  });

  return transitions.map(({ item, key, props }) =>
    item ? (
      <animated.div key={key} style={props}>
        <Spinner />;
      </animated.div>
    ) : (
      <animated.div key={key} style={props}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={snackBar.open}
          onClose={handleSnackClose}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          autoHideDuration={2000}
        >
          <MySnackbarContentWrapper
            onClose={handleSnackClose}
            variant={responseType.toLowerCase()}
            message={<span id="message-id">{response}</span>}
          />
        </Snackbar>
        <DashboardNavBar />
        <SideBar />
      </animated.div>
    )
  );
};

export default Dashboard;
