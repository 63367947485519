import React from "react";
import "../../../App.css";
import SideBar from "components/layout/sidebar/Sidebar";
import DashboardNavBar from "components/layout/DashboardNavBar";
import NewEquipment from "./NewEquipment";

const CallNewEquipment = ({ match }) => {
  return (
    <div>
      <DashboardNavBar />
      <SideBar />
      <NewEquipment id={match.params.id}/>
    </div>
  );
}

export default CallNewEquipment;