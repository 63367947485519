import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import ProfilesContext from "context/profiles/profilesContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Buttons from "../buttons/Pressed";
import axios from "axios";
import history from "../../history";
import CloseIcon from '@material-ui/icons/Close';
import Cookies from "js-cookie";

const useStyles = makeStyles(theme => ({
  icons: {
    opacity: "0"
  },
  Title: {
    fontFamily: "Roboto",
    fontSize: "20px",
    textAlign: "left",
    color: "#000000",
    fontWeight: "bold"
  }
}));

// insert into portal_sec_role (id,version,authority,description,profile) values (1,1,'Test','Test Description',1);
const Actions = props => {
  const classes = useStyles();
  const [t] = useTranslation();
  const profilesContext = useContext(ProfilesContext);
  const { deleteProfile, response, responseType } = profilesContext;
  const [open, setOpen] = useState(false);
  const { item, name, permission } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    deleteProfile(item.id);
    setOpen(false);
  };
  useEffect(() => {
    return () => { };
  }, [response, responseType]);

  const verifyEdit = () => {
    axios.get(process.env.REACT_APP_SERVER + `api/profiles/${item.id}`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
      }
    })
      .then(res => {
        if (res.statusText === "OK") {
          history.push(`/profiles/${item.id}/edit`);
        } else {
          deleteProfile(item.id);
        }
      })
      .catch(error => {
        deleteProfile(item.id);
      });
  }

  const verifyView = () => {
    axios.get(process.env.REACT_APP_SERVER + `api/profiles/${item.id}`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
      }
    })
      .then(res => {
        if (res.statusText === "OK") {
          history.push(`/profiles/${item.id}`);
        } else {
          deleteProfile(item.id);
        }
      })
      .catch(error => {
        deleteProfile(item.id);
      });
  }

  return item.id !== 1 ? (
    <React.Fragment>
      {permission["allowRead"] &&
        <Tooltip title={t("GENERAL.view")} aria-label="details" placement="top">
          <IconButton className={classes.icons} onClick={verifyView}>
            <Visibility />
          </IconButton>
        </Tooltip>
      }
      {permission["allowUpdate"] &&
        <Tooltip title={t("GENERAL.edit")} aria-label="edit" placement="top">
          <IconButton className={classes.icons} onClick={verifyEdit}>
            <Edit />
          </IconButton>
        </Tooltip>
      }
      {/* <Link to={`/profiles/${item.id}/delete`}> */}
      {permission["allowDelete"] &&
        <Tooltip title={t("GENERAL.delete")} aria-label="delete" placement="top">
          <IconButton className={classes.icons} onClick={handleClickOpen}>
            <Delete />
          </IconButton>
        </Tooltip>
      }
      {/* </Link> */}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        msg="mensagem"
      >
        <DialogTitle className={classes.Title}>
          {t("GENERAL.delete")}
          <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("GENERAL.deleteMessageM")} {t("PROFILES.profile")} "{name}" ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Buttons
            onClick={handleClose}
            tipo="BN-noback"
            conteudo={t("GENERAL.cancel").toUpperCase()}
          />
          <Buttons
            onClick={handleDelete}
            tipo="BN-blue"
            conteudo={t("GENERAL.delete").toUpperCase()}
          />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  ) : (
    <React.Fragment>
      {permission["allowRead"] &&
        <Link to={`/profiles/${item.id}`}>
          <Tooltip title="Visualizar" aria-label="details" placement="top">
            <IconButton className={classes.icons}>
              <Visibility />
            </IconButton>
          </Tooltip>
        </Link>
      }
    </React.Fragment>
  );
};

export default Actions;
