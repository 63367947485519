/**
 * Returns a time string based on the given format.
 * @param {number} h the integer number of hours.
 * @param {number} m the integer number of minutes.
 * @param {number} s the integer number of seconds.
 * @param {number} ms the integer number of milliseconds.
 * @param {string} format the format to be used. Accepted formats: 'hh', 'hh:mm', 'hh:mm:ss', 'hh:mm:ss.sss'.
 * @returns
 */
export const getTimeStringBasedOnFormat = (
    h,
    m,
    s,
    ms,
    format = 'hh:mm:ss'
) => {
    if (isNaN(h) || isNaN(m) || isNaN(s) || isNaN(ms))
        throw new Error('Invalid time.');

    h = h.toString().padStart(2, '0');
    m = m.toString().padStart(2, '0');
    s = s.toString().padStart(2, '0');
    ms = ms.toString().padStart(3, '0');

    switch (format.toLowerCase()) {
        case 'hh':
            return h;
        case 'hh:mm':
            return `${h}:${m}`;
        case 'hh:mm:ss':
            return `${h}:${m}:${s}`;
        case 'hh:mm:ss.sss':
            return `${h}:${m}:${s}.${ms}`;
        default:
            throw new Error('Invalid format.');
    }
};
