import axios from 'axios';
import { useMutation } from 'react-query';
import Cookies from 'js-cookie';
import { useSnackbar } from 'hooks/UseSnackbar';
import { useTranslation } from 'react-i18next';
import { useQueryLSBDTransactions } from './useQueryLSBDTransactions';

export const useMutationUpdateTransaction = setOpen => {
    const toast = useSnackbar();
    const [t] = useTranslation();

    const queryTransactions = useQueryLSBDTransactions({ enabled: false });

    const mutation = useMutation({
        mutationFn: async obj =>
            await axios.put(
                process.env.REACT_APP_SERVER +
                    `api/Quota/Transactions/${obj.get('id')}`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            ),
        onSuccess: res => {
            toast.success(t('QUOTAS.transactionUpdatedSuccess'));
        },
        onError: () => {
            toast.error(t('QUOTAS.transactionUpdatedError'));
        },
        onSettled: () => {
            setOpen(false);
            queryTransactions.refetch();
        }
    });

    return mutation;
};
