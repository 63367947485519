/* eslint-disable */
import CollaboratorContext from "context/hrModule/collaborator/collaboratorContext";
import React, { useContext, useEffect, useState } from "react";
import history from "history.js";
import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Transition from "components/layout/transition/Transition";
import Input from "components/inputs/Input";
import Buttons from "components/buttons/Pressed";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import DialogCancelOperation from "components/dialogs/DialogCancelOperation";


const BankDataForm = (props) => {
    const classes = useStyles();
    const [t] = useTranslation();
    const collaboratorContext = useContext(CollaboratorContext);
    const { id, userId, context, ...others } = props;
    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }

    const {
        loading,
        setLoading,
        setLoadingFalse,
        bankDataContact,
        getBankDataContact,
        updateBankDataContact,
    } = collaboratorContext

    const [requiredAccount, setRequiredAccount] = useState(false);
    const [requiredAgency, setRequiredAgency] = useState(false);
    const [requiredBank, setRequiredBank] = useState(false);
    const [openCancelDialog, setOpenCancelDialog] = useState(false);
    const [hasChange, setHasChange] = useState(false);

    const [values, setValues] = useState({
        id: "",
        version: 0,
        account: "",
        agency: "",
        bank: "",
        pix: "",
    });

    const [valuesMore, setValuesMore] = useState([]);

    async function fetchData(userId) {
        setLoading();
        await getBankDataContact(userId);
        setLoadingFalse();
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData(userId);
    }, []);

    useEffect(() => {
        if (bankDataContact && bankDataContact.length) {
            let bankData = {};
            bankDataContact.slice(0, 1).forEach(item => {
                bankData = {
                    id: item.id,
                    version: item.version,
                    account: item.account ? item.account : "",
                    agency: item.agency ? item.agency : "",
                    bank: item.bank ? item.bank : "",
                    pix: item.pix ? item.pix : "",
                }
            });
            setValues(bankData);

            const bankDataMore = bankDataContact.slice(1).map(item => ({
                id: item.id,
                version: item.version,
                account: item.account ? item.account : "",
                agency: item.agency ? item.agency : "",
                bank: item.bank ? item.bank : "",
                pix: item.pix ? item.pix : "",
                type: item.pix ? "pix" : "bank",
                requiredAccount: false,
                requiredAgency: false,
                requiredBank: false,
                requiredPix: false,
            }));
            setValuesMore(bankDataMore);
        }
    }, [bankDataContact.length, bankDataContact])

    const handleCancel = () => {
        if (hasChange) {
            setOpenCancelDialog(true);
        } else {
            history.push("/Collaborators");
        }
    }

    const handleApply = () => {
        const canApply = verifyApply();
        if (canApply) {
            let formData = new FormData();

            const banks = [values].concat(valuesMore);

            for (let i in banks) {
                const item = banks[i];
                if (item.type === "pix") {
                    formData.append(`banks[${i}].id`, item.id ? item.id : 0);
                    formData.append(`banks[${i}].version`, item.version);
                    formData.append(`banks[${i}].account`, "");
                    formData.append(`banks[${i}].agency`, "");
                    formData.append(`banks[${i}].bank`, "");
                    formData.append(`banks[${i}].pix`, item.pix ? item.pix : "");
                    formData.append(`banks[${i}].userId`, userId);

                } else {
                    formData.append(`banks[${i}].id`, item.id ? item.id : 0);
                    formData.append(`banks[${i}].version`, item.version);
                    formData.append(`banks[${i}].account`, item.account ? item.account : "");
                    formData.append(`banks[${i}].agency`, item.agency ? item.agency : "");
                    formData.append(`banks[${i}].bank`, item.bank ? item.bank : "");
                    formData.append(`banks[${i}].pix`, "");
                    formData.append(`banks[${i}].userId`, userId);
                }
            }

            const deleteBank = bankDataContact.slice(1).filter(x => !valuesMore.some(m => m.id === x.id));
            for (let i in deleteBank) {
                const bank = deleteBank[i];
                formData.append(`deleteId[${i}]`, bank.id);
            }

            updateBankDataContact(formData, () => { fetchData(userId) });
        }
    }

    const verifyApply = () => {
        let res = true;

        if (!values.account.trim()) {
            res = false;
            setRequiredAccount(true);
        }
        if (!values.agency.trim()) {
            res = false;
            setRequiredAgency(true);
        }
        if (!values.bank.trim()) {
            res = false;
            setRequiredBank(true);
        }

        if (valuesMore.length) {
            let invalid = false;
            const newValuesMore = valuesMore.map(item => {
                if (item.type === "bank" && (!item.account.trim() || !item.agency.trim() || !item.bank.trim())) {
                    invalid = true;
                    return {
                        ...item,
                        requiredAccount: !item.account.trim(),
                        requiredAgency: !item.agency.trim(),
                        requiredBank: !item.bank.trim(),
                    }
                } else if (item.type === "pix" && !item.pix.trim()) {
                    invalid = true;
                    return {
                        ...item,
                        requiredPix: true,
                    }
                }
                return item;
            });

            if (invalid) {
                res = false;
                setValuesMore(newValuesMore);
            }
        }

        return res;
    }

    const handleChange = (name, value) => {
        setHasChange(true);
        setValues({ ...values, [name]: value })
    }

    const handleChangeMore = (indexField) => (name, value) => {
        setHasChange(true);
        const rowsField = [...valuesMore];
        const field = rowsField[indexField];
        if (field) {
            if (name === "account") {
                field.requiredAccount = false;
            } else if (name === "agency") {
                field.requiredAgency = false;
            } else if (name === "bank") {
                field.requiredBank = false;
            } else if (name === "pix") {
                field.requiredPix = false;
            }
            field[name] = value;
            setValuesMore(rowsField);
        }
    }

    const addBankData = () => {
        setValuesMore([...valuesMore, {
            id: "",
            version: 0,
            account: "",
            agency: "",
            bank: "",
            pix: "",
            type: "bank",
            requiredAccount: false,
            requiredAgency: false,
            requiredBank: false,
            requiredPix: false,
        }]);
    }

    const removeBankData = (indexField) => () => {
        const rowsField = [...valuesMore];
        if (rowsField.length) {
            rowsField.splice(indexField, 1);
            setValuesMore(rowsField);
        }
    }

    return (
        <React.Fragment>
            <Transition loading={loading} newDesign={true}>
                <Paper>
                    <div className={classes.identificationForm}>
                        <Grid container item spacing={3} md={12} lg={12} style={{ paddingRight: "150px" }}>
                            <Grid item xs={12} sm={12}>
                                <Input
                                    context={context}
                                    required
                                    label={"GENERAL.bankName"}
                                    value={values.bank}
                                    valueName={"bank"}
                                    maxLength={50}
                                    handleChange={handleChange}
                                    error={requiredBank}
                                    helperText={requiredBank && t("GENERAL.requiredField")}
                                    setRequired={setRequiredBank}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Input
                                    context={context}
                                    required
                                    label={"GENERAL.agency"}
                                    value={values.agency}
                                    valueName={"agency"}
                                    maxLength={10}
                                    handleChange={handleChange}
                                    error={requiredAgency}
                                    helperText={requiredAgency && t("GENERAL.requiredField")}
                                    setRequired={setRequiredAgency}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Input
                                    context={context}
                                    required
                                    label={"GENERAL.account"}
                                    value={values.account}
                                    valueName={"account"}
                                    maxLength={10}
                                    handleChange={handleChange}
                                    error={requiredAccount}
                                    helperText={requiredAccount && t("GENERAL.requiredField")}
                                    setRequired={setRequiredAccount}
                                />
                            </Grid>
                            {context !== "view" ?
                                <Grid item xs={12} sm={12}>
                                    <div onClick={addBankData} className={classes.link} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', width: 'max-content', }}>
                                        <Typography >Adicionar outros dados bancários</Typography>
                                    </div>
                                </Grid>
                                :
                                (valuesMore && valuesMore.length > 0 && <Grid item xs={12} sm={12} style={{ paddingBottom: 0 }}>
                                    <Typography className={classes.headerColor}><b>Dados Bancários Adicionais</b></Typography>
                                </Grid>)
                            }
                            {valuesMore.map((more, index) =>
                                <React.Fragment>
                                    <Grid item xs={3} sm={3}>
                                        <Input
                                            context={context}
                                            required
                                            label={"Tipo de Dados Bancários"}
                                            value={more.type}
                                            valueName={"type"}
                                            handleChange={handleChangeMore(index)}
                                            isSelect
                                            arraySelected={[{ value: "bank", label: "Banco" }, { value: "pix", label: "Pix" }]}
                                        />
                                    </Grid>
                                    {more.type === "bank" ?
                                        <React.Fragment>
                                            <Grid item xs={1} sm={1}>
                                                {context !== "view" &&
                                                    <Tooltip
                                                        title="Remover Dados Bancários"
                                                        aria-label="remover"
                                                        placement="top"
                                                    >
                                                        <IconButton
                                                            style={{ float: "left", marginTop: 26 }}
                                                            onClick={removeBankData(index)}
                                                            className={classes.icons}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>}
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <Input
                                                    context={context}
                                                    required
                                                    label={"GENERAL.bankName"}
                                                    value={more.bank}
                                                    valueName={"bank"}
                                                    maxLength={50}
                                                    handleChange={handleChangeMore(index)}
                                                    error={more.requiredBank}
                                                    helperText={more.requiredBank && t("GENERAL.requiredField")}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={6}>
                                                <Input
                                                    context={context}
                                                    required
                                                    label={"GENERAL.agency"}
                                                    value={more.agency}
                                                    valueName={"agency"}
                                                    maxLength={10}
                                                    handleChange={handleChangeMore(index)}
                                                    error={more.requiredAgency}
                                                    helperText={more.requiredAgency && t("GENERAL.requiredField")}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={6}>
                                                <Input
                                                    context={context}
                                                    required
                                                    label={"GENERAL.account"}
                                                    value={more.account}
                                                    valueName={"account"}
                                                    maxLength={10}
                                                    handleChange={handleChangeMore(index)}
                                                    error={more.requiredAccount}
                                                    helperText={more.requiredAccount && t("GENERAL.requiredField")}
                                                />
                                            </Grid>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <Grid item xs={8} sm={8}>
                                                <Input
                                                    context={context}
                                                    required
                                                    label={"GENERAL.keyPix"}
                                                    value={more.pix}
                                                    valueName={"pix"}
                                                    maxLength={100}
                                                    handleChange={handleChangeMore(index)}
                                                    error={more.requiredPix}
                                                    helperText={more.requiredPix && t("GENERAL.requiredField")}
                                                />
                                            </Grid>
                                            {context !== "view" &&
                                                <Grid item xs={1} sm={1}>
                                                    <Tooltip
                                                        title="Remover Dados Bancários"
                                                        aria-label="remover"
                                                        placement="top"
                                                    >
                                                        <IconButton
                                                            style={{ float: "left", marginTop: 26 }}
                                                            onClick={removeBankData(index)}
                                                            className={classes.icons}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>}
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                            )}
                        </Grid>
                    </div>
                    {context !== "view" &&
                        <div style={{ paddingLeft: "10px", paddingBottom: "40px", paddingTop: "40px" }}>
                            <Buttons
                                onClick={handleCancel}
                                tipo="BN-noback"
                                conteudo={t("GENERAL.cancel")}
                            />
                            <Buttons
                                onClick={handleApply}
                                tipo="BN-blue"
                                conteudo={t("GENERAL.apply")}
                            />
                        </div>}
                </Paper>

                <DialogCancelOperation
                    open={openCancelDialog}
                    setOpen={setOpenCancelDialog}
                    path={"/Collaborators"}
                    dialogTitle={t("GENERAL.cancelEdit")}
                    dialogText={t("GENERAL.cancelEditText")}
                />
            </Transition>
        </React.Fragment >
    );
};

export default BankDataForm;