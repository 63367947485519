/* eslint-disable */
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ArrowBack from "@material-ui/icons/ArrowBack";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Footer from "components/layout/Footer";
import PartnersContext from "context/partners/partnersContext";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import MaskedInput from "react-text-mask";
import DashboardNavBar from "../layout/DashboardNavBar";
import SideBar from "../layout/sidebar/Sidebar";
import { useStyles } from "./PartnerStyles";
import { phoneMask } from 'utils/masks/phoneMask';

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;
  let mask = [];
  switch (props.name) {
    case "phone":
      mask = [
        "(",
        /[1-9]/,
        /\d/,
        ")",
        " ",
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ];
      break;
    case "cnpj":
      mask = [
        /[1-9]/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        /\d/,
        "/",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/
      ];
      break;
    case "cep":
      mask = [/[1-9]/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/];
      break;
    default:
      break;
  }

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}
const ViewPartner = ({ match }) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const partnersContext = useContext(PartnersContext);

  const { getPartner, partner, resetMessage, loading } = partnersContext;

  const [values, setValues] = React.useState({
    id: null,
    cnpj: "  .   .   /    - ",
    corporateName: "",
    tradeName: "",
    responsibleName: "",
    cep: "",
    address: "",
    neighborhood: "",
    city: "",
    state: "",
    country: "",
    phone: " ",
    fax: " ",
    email: " ",
    type: "CUSTUMER",
    status: "false",
    financialContactName: " ",
    financialContactEmail: " ",
    financialContactFax: " ",
    financialContactPhone: " "
  });

  const showFinancialContatc = () => {
    return values.type === "EXECUTING_INSTITUTION" ? (
      <div className={classes.paperDivision}>
        <Paper className={classes.root}>
          <div className={classes.header}>
            {t("PARTNERS.financialContact").toUpperCase()}
          </div>
          <div className={classes.bodyForm}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <TextField
                  disabled
                  key={"financialContactName"}
                  className={classes.adjustReactTextMask}
                  label={t("PERSONAL.name")}
                  value={values.financialContactName}
                  onChange={handleChange("financialContactName")}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disabled
                  className={classes.adjustReactTextMask}
                  label={t("ADDRESS.email")}
                  value={values.financialContactEmail}
                  onChange={handleChange("financialContactEmail")}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disabled
                  className={classes.adjustReactTextMask}
                  label={t("ADDRESS.phone")}
                  name="phone"
                  value={phoneMask(values.financialContactPhone)}
                  id="formatted-text-mask-input"
                  InputProps={{
                    inputComponent: TextMaskCustom
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disabled
                  className={classes.textField}
                  label={t("ADDRESS.fax")}
                  value={values.financialContactFax}
                  onChange={handleChange("financialContactFax")}
                  margin="normal"
                />
              </Grid>
            </Grid>
          </div>
        </Paper>
      </div>
    ) : (
      <></>
    );
  };

  useEffect(() => {
    resetMessage();
    getPartner(match.params.id);
    setValues({
      ...values,
      id: match.params.id,
      cnpj: partner.cnpj ? partner.cnpj : " ",
      corporateName: partner.socialreason ? partner.socialreason : " ",
      tradeName: partner.tradename ? partner.tradename : " ",
      responsibleName: partner.responsible ? partner.responsible : " ",
      cep: partner.cep ? partner.cep : " ",
      address: partner.street ? partner.street : " ",
      neighborhood: partner.neighborhood ? partner.neighborhood : " ",
      city: partner.city ? partner.city : " ",
      state: partner.state ? partner.state : " ",
      country: partner.country ? partner.country : " ",
      type: partner.type ? partner.type : " ",
      status: partner.active ? partner.active : false,
      email: partner.email ? partner.email : " ",
      phone: partner.fone ? partner.fone : " ",
      fax: partner.primaryfax ? partner.primaryfax : " ",
      financialContactName: partner.financialcontact,
      financialContactEmail: partner.financialemail,
      financialContactFax: partner.financialfax,
      financialContactPhone: partner.financialphone
        ? partner.financialphone
        : " "
    });
    return () => {};
  }, [partner.cnpj, partner.corporateName, JSON.stringify(partner)]);

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const transitions = useTransition(true, null, {
    from: { opacity: 0 },
    enter: {
      opacity: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "calc(100vh - 80px)"
    },
    leave: { opacity: 0 }
  });

  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <div key={key}>
          <DashboardNavBar />
          <SideBar />
          <animated.div style={props}>
            <div className="container">
              <Breadcrumbs
                className={classes.breadCrumbs}
                aria-label="breadcrumb"
                separator={<NavigateNextIcon fontSize="small" />}
              >
                <Typography className={classes.notClicableBreadScrumbs}>
                  {t("GENERAL.planning")}
                </Typography>
                <Typography className={classes.notClicableBreadScrumbs}>
                  {t("GENERAL.configurations")}
                </Typography>
                <Link className={classes.link} to="/partners">
                  {t("PARTNERS.partners")}
                </Link>
                <Typography className={classes.notClicableBreadScrumbs}>
                  {t("GENERAL.view")}
                </Typography>
              </Breadcrumbs>
              <div className="container-header">
                <div className="title">
                  <h1 className={classes.title}>
                    {t("GENERAL.view") + " " + t("PARTNERS.partner")}
                  </h1>
                </div>
                <Link to={"/partners"}>
                  <Button
                    className={classes.blueButton}
                    variant="contained"
                    color="primary"
                  >
                    <div className={classes.buttonIcon}>
                      <ArrowBack />
                    </div>
                    {t("GENERAL.back").toUpperCase()}
                  </Button>
                </Link>
              </div>

              <form id="formPartners">
                <Paper className={classes.root}>
                  <div className={classes.header}>
                    {t("PARTNERS.partner").toUpperCase()}
                  </div>
                  <div className={classes.bodyForm}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={5} md={2} lg={2} xl={2}>
                        <TextField
                          disabled
                          className={classes.adjustReactTextMask}
                          label={t("PERSONAL.CNPJ") + "*"}
                          name="cnpj"
                          value={values.cnpj}
                          onChange={handleChange("cnpj")}
                        />
                      </Grid>
                      <Grid item xs={12} sm={7} md={4} lg={4} xl={4}>
                        <TextField
                          disabled
                          className={classes.textField}
                          label={t("PARTNERS.corporateName") + "*"}
                          value={values.corporateName}
                          onChange={handleChange("corporateName")}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <TextField
                          disabled
                          className={classes.textField}
                          label={t("PARTNERS.tradeName")}
                          value={values.tradeName}
                          onChange={handleChange("tradeName")}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <TextField
                          disabled
                          className={classes.textField}
                          label={t("PARTNERS.responsibleName")}
                          value={values.responsibleName}
                          onChange={handleChange("responsibleName")}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={2} lg={2} xl={2}>
                        <TextField
                          disabled
                          className={classes.adjustReactTextMask}
                          label={t("ADDRESS.cep")}
                          value={values.cep}
                          name="cep"
                          onChange={handleChange("cep")}
                          InputProps={{
                            inputComponent: TextMaskCustom,
                          }}
                        />
                      </Grid>
                      <Grid item item xs={12} sm={8} md={7} lg={4} xl={4}>
                        <TextField
                          disabled
                          label={t("ADDRESS.address")}
                          className={classes.textField}
                          value={values.address}
                          onChange={handleChange("address")}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                        <TextField
                          disabled
                          label={t("ADDRESS.neighborhood")}
                          className={classes.textField}
                          value={values.neighborhood}
                          onChange={handleChange("neighborhood")}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                        <TextField
                          disabled
                          label={t("ADDRESS.city")}
                          className={classes.textField}
                          value={values.city}
                          onChange={handleChange("city")}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
                        <TextField
                          disabled
                          label={t("ADDRESS.state")}
                          className={classes.textField}
                          value={values.state}
                          onChange={handleChange("state")}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
                        <TextField
                          disabled
                          label={t("ADDRESS.country")}
                          className={classes.textField}
                          value={values.country}
                          onChange={handleChange("country")}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                        <TextField
                          disabled
                          className={classes.adjustReactTextMask}
                          label={t("ADDRESS.phone")}
                          name="phone"
                          value={values.phone}
                          id="formatted-text-mask-input"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                        <TextField
                          disabled
                          className={classes.textField}
                          label={t("ADDRESS.fax")}
                          value={values.fax}
                          onChange={handleChange("fax")}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={3} xl={4}>
                        <TextField
                          disabled
                          className={classes.textField}
                          label={t("ADDRESS.email")}
                          value={values.email}
                          onChange={handleChange("email")}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={2} xl={3}>
                        <TextField
                          disabled
                          className={classes.textField}
                          id="standard-select-type"
                          label={t("ADDRESS.type")}
                          margin="normal"
                          name="standard-select-type"
                          value={
                            values.type === "CUSTOMER"
                              ? "Cliente"
                              : "Fundação de Apoio"
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={2} lg={1} xl={1}>
                        <TextField
                          disabled
                          className={classes.textField}
                          margin="normal"
                          id="standard-select-status"
                          label={t("ADDRESS.status")}
                          name="standard-select-status"
                          value={values.status ? "Ativo" : "Inativo"}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </Paper>
                {showFinancialContatc()}
              </form>
            </div>
            <Footer />
          </animated.div>
        </div>
      )
  );
};

export default ViewPartner;
