// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import ErrorIcon from '@material-ui/icons/Error';
// import InfoIcon from '@material-ui/icons/Info';
import { amber, green } from "@material-ui/core/colors";

import { makeStyles } from "@material-ui/core/styles";

// const variantIcon = {
// 	success: CheckCircleIcon,
// 	warning: WarningIcon,
// 	error: ErrorIcon,
// 	info: InfoIcon
// };

// source to customize: https://material-ui.com/components/snackbars/

const useStyles2 = makeStyles(theme => ({
  success: {
    color: theme.palette.grey.darkest,
    backgroundColor: theme.palette.teal.light,
    boxShadow: "none",
    "& svg": {
      color: theme.palette.teal.darkest
    }
  },
  error: {
    color: theme.palette.grey.darkest,
    backgroundColor: theme.palette.red.light,
    boxShadow: "none",
    "& svg": {
      color: theme.palette.red.darkest
    }
  },
  info: {
    backgroundColor: theme.palette.primary.main
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
}));

export default useStyles2;
