import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import { useStyles } from "components/layout/CommonStyles";
import ProjectsContext from "context/projects/projectsContext";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Buttons from "../../buttons/Pressed";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import history from "../../../history";
import CloseIcon from '@material-ui/icons/Close';
import Cookies from "js-cookie";

const ActionsActivities = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const { activityId, name, phaseId, mode, projectMode, allAllowances } = props;
    const [open, setOpen] = useState(false);
    const projectsContext = useContext(ProjectsContext);
    const { deleteActivity } = projectsContext;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        deleteActivity(phaseId, activityId);
        setOpen(false);
    };

    const getPermission = (screen, allowance) => {
        for (let i = 0; i < allAllowances.length; i++) {
            if (allAllowances[i].resource === screen) {
                return allAllowances[i]["standardauthorizationoption"][allowance]
            }
        }
    }

    const verifyEdit = () => {
        axios.get(process.env.REACT_APP_SERVER + `api/projectPhases/getActivity/${activityId}`, {
            headers: {
                "Content-type": "application/json",
                "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
            }
        })
            .then(res => {
                if (res.statusText === "OK") {
                    history.push(`/execution/project/${projectMode}/projectPhase/${mode}/phaseActivity/${activityId}/edit`);
                } else {
                    deleteActivity(phaseId, activityId);
                }
            })
            .catch(error => {
                deleteActivity(phaseId, activityId);
            });
    }

    const verifyView = () => {
        axios.get(process.env.REACT_APP_SERVER + `api/projectPhases/getActivity/${activityId}`, {
            headers: {
                "Content-type": "application/json",
                "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
            }
        })
            .then(res => {
                if (res.statusText === "OK") {
                    history.push(`/execution/project/${projectMode}/projectPhase/${mode}/phaseActivity/${activityId}/view`);
                } else {
                    deleteActivity(phaseId, activityId);
                }
            })
            .catch(error => {
                deleteActivity(phaseId, activityId);
            });
    }

    return (
        <div>
            {allAllowances.length > 0 && getPermission("ACTIVITIES", "allowRead") &&
                <Tooltip title='Visualizar' aria-label='details' placement='top'>
                    <IconButton className={classes.actionsIcons} onClick={verifyView}>
                        <Visibility />
                    </IconButton>
                </Tooltip>
            }
            {mode === t("GENERAL.editMode") && allAllowances.length > 0 && getPermission("ACTIVITIES", "allowUpdate") &&
                <Tooltip title='Editar' aria-label='details' placement='top'>
                    <IconButton className={classes.actionsIcons} onClick={verifyEdit}>
                        <Edit />
                    </IconButton>
                </Tooltip>
            }
            {mode === t("GENERAL.editMode") && allAllowances.length > 0 && getPermission("ACTIVITIES", "allowDelete") &&
                <Tooltip title="Deletar" aria-label="delete" placement="top">
                    <IconButton className={classes.actionsIcons} onClick={handleClickOpen}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            }
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                msg="mensagem"
            >
                <DialogTitle className={classes.actionsTitle}>
                    {t("GENERAL.notification")}
                    <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {`${t("ACTIVITIES.deleteQuestion")} ${name}?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Buttons
                        onClick={handleClose}
                        tipo="BN-noback"
                        conteudo={t("GENERAL.cancel")}
                    />
                    <Buttons
                        onClick={handleDelete}
                        tipo="BN-blue"
                        conteudo={t("GENERAL.delete")}
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ActionsActivities;