import { TextField } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import Tooltip from "@material-ui/core/Tooltip";
import AddCircle from "@material-ui/icons/AddCircle";
import GetApp from "@material-ui/icons/GetApp";
import Save from "@material-ui/icons/Save";
import { useStyles } from "components/layout/CommonStyles";
import MySnackbarContentWrapper from "components/layout/Message";
import AllowancesContext from "context/allowance/allowanceContext";
import ProjectsContext from "context/projects/projectsContext";
import { AlignmentType, Document, HeadingLevel, Packer, Paragraph, Table, TableCell, TableRow, WidthType } from "docx";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { animated, useTransition } from "react-spring";
import NewRubric from "./NewRubric";

var fileDownload = require("js-file-download");

const Heading = props => {
  const classes = useStyles();
  const [t] = useTranslation();
  const { projectId } = props;
  const projectsContext = useContext(ProjectsContext);
  const allowanceContext = useContext(AllowancesContext);
  var periodId = 0;
  const [addendumIdSubmit, setAddendumIdSubmit] = useState(0);
  const {
    loading,
    project,
    response,
    hasUpdate,
    getProject,
    resetProject,
    resetMessage,
    responseType,
    submitHeadings,
    successiveActions
  } = projectsContext;

  const [delay, setDelay] = useState(true);
  const [headings, setHeadings] = useState([]);
  const [equalheadings, setEqualHeadings] = useState([]);
  const [state, setState] = useState({
    total: "",
    season: "",
    tripsCosts: "",
    totalGeneral: "",
    isGeneral: false,
    estimatedCost: 0,
    totalHeadings: "",
    directHrCosts: "",
    totalPercentage: "",
    equipmentsCosts: "",
    indirectHrCosts: "",
    consumablesCosts: "",
    incurredCostsValue: "",
    totalPercentageGeneral: "",
    booksAndPeriodicalsCosts: "",
    headingId1: 0,
    headingId2: 0,
    headingId3: 0,
    headingId4: 0,
    headingId5: 0,
    headingId6: 0,
    headingId7: 0
  });
  const estimatedCostRef = useRef(null);
  const [toDelete, setToDelete] = useState([]);
  const [headingsCount, setHeadingsCount] = useState(7);
  const {
    allowanceResponse,
    allowanceResponseType,
    resetAllowanceMessage
  } = allowanceContext;

  const [gpf, setGpf] = useState("");
  const [season, setSeason] = useState("");
  const [seasonId, setSeasonId] = useState(0);
  const [addendums, setAddendums] = useState([]);
  const [isGeneral, setIsGeneral] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [seasonsArray, setSeasonsArray] = useState([]);
  const [valid, setValid] = useState(true);
  const [hasCooperationTerm, setHasCooperationTerm] = useState(false);

  const [snackBar, setSnackBar] = useState({
    open: false,
    kind: "",
    content: ""
  });

  const handleSnack = () => {
    setSnackBar({ ...snackBar, open: true });
  };

  const handleSnackClose = () => {
    setSnackBar({ ...snackBar, open: false });
    resetAllowanceMessage();
  };

  function bootstrap() {
    var aux = {};
    var general = {};
    var rubrics = [];
    var seasonsArray_ = [];
    var totalResult = ["", ""];
    var totalResultGeneral = ["", ""];
    setProjectName(project.information.name);
    if (project.sgppdAddendum.length > 0) {
      setHasCooperationTerm(true)
      for (var i = 0; i < project.sgppdAddendum.length; i++) {
        if (project.sgppdAddendum[i].gpf) {
          setGpf(project.sgppdAddendum[i].gpf);
        }
      }


      for (var i = 0; i < project.sgppdAddendum.length; i++) {
        var item = project.sgppdAddendum[i];
        var startDate = new Date(item.startdate);
        startDate.setMinutes(startDate.getMinutes() + startDate.getTimezoneOffset())
        var endDate = new Date(item.enddate);
        endDate.setMinutes(endDate.getMinutes() + endDate.getTimezoneOffset())

        aux.value = item.id;
        aux.label = startDate.toLocaleDateString() + " - " + endDate.toLocaleDateString();

        if (item.number === "GENERAL") {
          var startDateGeneral = new Date(project.information.startdate);
          startDateGeneral.setMinutes(startDateGeneral.getMinutes() + startDateGeneral.getTimezoneOffset())
          var endDateGeneral = new Date(project.information.enddate);
          endDateGeneral.setMinutes(endDateGeneral.getMinutes() + endDateGeneral.getTimezoneOffset())
          aux.value = item.id;
          aux.label = startDateGeneral.toLocaleDateString() + " - " + endDateGeneral.toLocaleDateString();
          general = aux;
          aux.label = aux.label + " (GERAL)";
        } else {
          seasonsArray_.push(aux);
        }
        aux = {};
      }

      seasonsArray_.push(general);
      var primor = seasonsArray_[seasonsArray_.length - 2];

      setSeasonId(primor.value);
      setSeason(primor.label);
      periodId = primor.value;
      setAddendumIdSubmit(primor.value);



      // same as setHeadingsDisplay

      var currentAddendum = project.sgppdAddendum.filter(
        item => item.id === primor.value
      );

      var costAddendum = currentAddendum[0].estimatedcost;
      var savedHeadings = currentAddendum[0].sgppdBudgetItem;
      var totalGeneralCost = currentAddendum[0].totalGeneralCost;
      var _isGeneralAddendum = currentAddendum[0].number === "GENERAL";

      if (savedHeadings && savedHeadings.length > 0) {
        var len = headings.length;
        headings.splice(0, len);
        setHeadingsCount(7);

        var total = 0;

        if (_isGeneralAddendum && totalGeneralCost && totalGeneralCost !== 0) {
          total = currentAddendum[0].totalGeneralCost;
        } else {
          for (var j = 0; j < savedHeadings.length; j++) {
            total += savedHeadings[j].value;
          }
        }

        for (var k = 0; k < 7; k++) {
          var cost = savedHeadings[k].value.toString().split("");
          if (savedHeadings[k].value !== 0) {
            if (
              (cost[cost.length - 1] === "0" && cost[cost.length - 2] === "0") ||
              cost[cost.length - 3] !== "."
            ) {
              if (cost[cost.length - 2] === ".") {
                cost.push("0");
              } else {
                cost.push("0");
                cost.push("0");
              }
            }

            rubrics.push(cost);
          } else {
            cost = "";
            rubrics.push(cost);
          }
        }
        var totalFormatted = total
          .toFixed(2)
          .split("")
          .filter(el => el !== "." && el !== ",")
          .join("");

        if (_isGeneralAddendum) {
          totalResultGeneral = convertGeneralTotalToBrCurrency(totalFormatted);
        } else {
          totalResult = convertTotalToBrCurrency(totalFormatted);
        }

        if (rubrics.length > 6) {
          var heading1 = trimMoneyField(rubrics[0]);
          var heading2 = trimMoneyField(rubrics[1]);
          var heading3 = trimMoneyField(rubrics[2]);
          var heading4 = trimMoneyField(rubrics[3]);
          var heading5 = trimMoneyField(rubrics[4]);
          var heading6 = trimMoneyField(rubrics[5]);
          var heading7 = trimMoneyField(rubrics[6]);
        }

        setState({
          ...state,
          // ESSES DOIS CAMPOS DEIXAM O CARREGAMENTO BEM MAIS LENTO, TESTAR, COM E SEM ELES

          // season: primor.label,
          // seasonId: primor.value,

          totalHeadings: "",
          total: totalResult[0],
          totalPercentage: totalResult[1],

          totalGeneral: totalResultGeneral[0],
          totalPercentageGeneral: totalResultGeneral[1],

          estimatedCost: costAddendum,
          headingId1: savedHeadings[0].id,
          headingId2: savedHeadings[1].id,
          headingId3: savedHeadings[2].id,
          headingId4: savedHeadings[3].id,
          headingId5: savedHeadings[4].id,
          headingId6: savedHeadings[5].id,
          headingId7: savedHeadings[6].id,
          tripsCosts: heading6 !== "" ? heading6 : "",
          directHrCosts: heading1 !== "" ? heading1 : "",
          indirectHrCosts: heading2 !== "" ? heading2 : "",
          equipmentsCosts: heading3 !== "" ? heading3 : "",
          consumablesCosts: heading4 !== "" ? heading4 : "",
          incurredCostsValue: heading7 !== "" ? heading7 : "",
          booksAndPeriodicalsCosts: heading5 !== "" ? heading5 : ""
        });

        if (savedHeadings.length > 7) {
          for (var i = 7; i < savedHeadings.length; i++) {
            var headingValue = savedHeadings[i].value.toString().split("");

            if (
              (headingValue[headingValue.length - 1] === "0" &&
                headingValue[headingValue.length - 2] === "0") ||
              headingValue[headingValue.length - 3] !== "."
            ) {
              if (headingValue[headingValue.length - 2] === ".") {
                headingValue.push("0");
              } else {
                headingValue.push("0");
                headingValue.push("0");
              }
            }

            var editedValue = headingValue
              .filter(el => el !== "." && el !== ",")
              .join("")
              .split("")
              .reverse()
              .join("");

            var valueToAdd = converTotBrCurrency(editedValue);
            addHeading(
              true,
              savedHeadings[i].rubric,
              valueToAdd,
              savedHeadings[i].id,
              costAddendum
            );
          }
        }
      } else {
        var len = headings.length;
        headings.splice(0, len);
        setHeadingsCount(7);

        setState({
          ...state,
          tripsCosts: "",

          total: "",
          totalGeneral: "",
          totalHeadings: "",
          totalPercentage: "",
          totalPercentageGeneral: "",

          estimatedCost: 0,

          directHrCosts: "",
          indirectHrCosts: "",
          equipmentsCosts: "",
          consumablesCosts: "",
          incurredCostsValue: "",
          season: primor.label,
          seasonId: primor.value,
          booksAndPeriodicalsCosts: "",
          headingId1: 0,
          headingId2: 0,
          headingId3: 0,
          headingId4: 0,
          headingId5: 0,
          headingId6: 0,
          headingId7: 0
        });
      }

      setIsGeneral(_isGeneralAddendum);
      setSeasonsArray(seasonsArray_);
      setAddendums(project.sgppdAddendum);
    }
  }

  function trimMoneyField(value) {
    if (value) {
      var formattedValue = value
        .filter(el => el !== "." && el !== ",")
        .join("")
        .split("")
        .reverse()
        .join("");

      return converTotBrCurrency(formattedValue);
    } else {
      return "";
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (responseType === "SUCCESS") {
        handleSnack({ kind: "success", content: response });
      } else {

      }
      if (responseType === "ERROR") {
        handleSnack({ kind: "error", content: response });
      }
      setDelay(false);
    }, 700);

    return () => {
      clearTimeout(timer);
    };
  }, [responseType, response, successiveActions]);

  useEffect(() => {
    getProject(projectId);
    const timer = setTimeout(() => {
      if (Object.keys(project).length > 0) {
        bootstrap();
      }
    }, 300);
    return () => {
      clearTimeout(timer);
    };
  }, [Object.keys(seasonsArray).length, Object.keys(project).length]);

  useEffect(() => {
    if (toDelete.length > 0) {
      if (headings.length > 0) {
        var aux = [];
        var len = headings.length;

        for (var i = 0; i < len; i++) {
          if (headings[i]) {
            var element = {
              description: document.getElementById(
                "descHeading" + headings[i].key
              ).value,
              value: document.getElementById("headingValue" + headings[i].key)
                .value,
              id: parseInt(
                document.querySelector("#divHeading" + headings[i].key).dataset
                  .headingId
              )
            };
            aux.push(element);
          }
        }
        headings.splice(0, len);
        setHeadingsCount(7);

        for (var i = 0; i < aux.length; i++) {
          addHeading(
            true,
            aux[i].description,
            aux[i].value,
            aux[i].id,
            state.estimatedCost
          );
        }
      }
    }
    return () => { };
  }, [toDelete.length]);

  useEffect(() => {
    if (addendums.length > 0 && seasonId) {
      if (
        !(addendums.filter(item => item.id == seasonId)[0].number === "GENERAL")
      )
        updateFields();
    }
    return () => { };
  }, [season, toDelete.length,
    state.indirectHrCosts,
    state.directHrCosts,
    state.tripsCosts,
    state.equipmentsCosts,
    state.consumablesCosts,
    state.incurredCostsValue,
    state.booksAndPeriodicalsCosts]
  );

  useEffect(() => {
    if (hasUpdate) {
      setHeadings([]);
      setAddendums([]);
      resetProject();
    }
    return () => { };
  }, [hasUpdate]);

  useEffect(() => {
    return () => {
      resetMessage();
    };
  }, []);

  const updateForChangeSeason = () => {
    var rubrics = [];
    var totalResult = ["", ""];
    var totalResultGeneral = ["", ""];
    var totGeneral = 0
    addendums.forEach(element => {
      if (element.number !== "GENERAL") {
        totGeneral += element.estimatedcost
      }
    })
    var currentAddendum = addendums.filter(item => item.id === periodId);
    var costAddendum = currentAddendum[0].number === "GENERAL" ? totGeneral : currentAddendum[0].estimatedcost;
    var savedHeadings = currentAddendum[0].sgppdBudgetItem;
    var isGeneral_ = currentAddendum[0].number === "GENERAL";
    var totalGeneralCost = currentAddendum[0].totalGeneralCost;

    if (savedHeadings && savedHeadings.length > 0) {
      var total = 0;
      var len = headings.length;
      headings.splice(0, len);
      setHeadingsCount(7);

      if (isGeneral_ && totalGeneralCost && totalGeneralCost !== 0) {
        total = currentAddendum[0].totalGeneralCost;
      } else {
        for (var j = 0; j < savedHeadings.length; j++) {
          total += savedHeadings[j].value;
        }
      }

      for (var k = 0; k < 7; k++) {
        var cost = savedHeadings[k].value.toString().split("");

        if (savedHeadings[k].value !== 0) {
          if (
            (cost[cost.length - 1] === "0" && cost[cost.length - 2] === "0") ||
            cost[cost.length - 3] !== "."
          ) {
            if (cost[cost.length - 2] === ".") {
              cost.push("0");
            } else {
              cost.push("0");
              cost.push("0");
            }
          }

          rubrics.push(cost);
        } else {
          cost = "";
          rubrics.push(cost);
        }
      }
      var totalFormatted = total
        .toFixed(2)
        .split("")
        .filter(el => el !== "." && el !== ",")
        .join("");

      if (isGeneral_) {
        totalResultGeneral = convertGeneralTotalToBrCurrency(totalFormatted);
      } else {
        totalResult = convertTotalToBrCurrency(totalFormatted);
      }

      if (rubrics.length > 6) {
        var heading1 = trimMoneyField(rubrics[0]);
        var heading2 = trimMoneyField(rubrics[1]);
        var heading3 = trimMoneyField(rubrics[2]);
        var heading4 = trimMoneyField(rubrics[3]);
        var heading5 = trimMoneyField(rubrics[4]);
        var heading6 = trimMoneyField(rubrics[5]);
        var heading7 = trimMoneyField(rubrics[6]);
      }

      setState({
        ...state,
        totalHeadings: "",
        total: totalResult[0],
        totalPercentage: totalResult[1],

        totalGeneral: totalResultGeneral[0],
        totalPercentageGeneral: totalResultGeneral[1],

        estimatedCost: costAddendum,

        headingId1: savedHeadings[0].id,
        headingId2: savedHeadings[1].id,
        headingId3: savedHeadings[2].id,
        headingId4: savedHeadings[3].id,
        headingId5: savedHeadings[4].id,
        headingId6: savedHeadings[5].id,
        headingId7: savedHeadings[6].id,
        tripsCosts: heading6 !== "" ? heading6 : "",
        directHrCosts: heading1 !== "" ? heading1 : "",
        indirectHrCosts: heading2 !== "" ? heading2 : "",
        equipmentsCosts: heading3 !== "" ? heading3 : "",
        consumablesCosts: heading4 !== "" ? heading4 : "",
        incurredCostsValue: heading7 !== "" ? heading7 : "",
        booksAndPeriodicalsCosts: heading5 !== "" ? heading5 : ""
      });

      setIsGeneral(isGeneral_);

      if (savedHeadings.length > 7) {
        for (var i = 7; i < savedHeadings.length; i++) {
          var headingValue = savedHeadings[i].value.toString().split("");

          if (
            (headingValue[headingValue.length - 1] === "0" &&
              headingValue[headingValue.length - 2] === "0") ||
            headingValue[headingValue.length - 3] !== "."
          ) {
            if (headingValue[headingValue.length - 2] === ".") {
              headingValue.push("0");
            } else {
              headingValue.push("0");
              headingValue.push("0");
            }
          }

          var editedValue = headingValue
            .filter(el => el !== "." && el !== ",")
            .join("")
            .split("")
            .reverse()
            .join("");

          var valueToAdd = converTotBrCurrency(editedValue);
          addHeading(
            true,
            savedHeadings[i].rubric,
            valueToAdd,
            savedHeadings[i].id,
            costAddendum
          );
        }
      }
    } else {
      var len = headings.length;
      headings.splice(0, len);
      setHeadingsCount(7);

      setState({
        ...state,
        tripsCosts: "",

        total: "",
        totalGeneral: "",
        totalHeadings: "",
        totalPercentage: "",
        totalPercentageGeneral: "",

        isGeneral: false,
        estimatedCost: 0,

        directHrCosts: "",
        indirectHrCosts: "",
        equipmentsCosts: "",
        consumablesCosts: "",
        incurredCostsValue: "",
        booksAndPeriodicalsCosts: "",
        headingId1: 0,
        headingId2: 0,
        headingId3: 0,
        headingId4: 0,
        headingId5: 0,
        headingId6: 0,
        headingId7: 0
      });
    }
  };

  const getId = season => {
    var id = 0;

    for (var i = 0; i < seasonsArray.length; i++) {
      if (seasonsArray[i].label === season) {
        id = seasonsArray[i].value;
        break;
      }
    }
    return id;
  };

  const createFile = () => {
    var allRubrics = document.getElementsByClassName("addendumHeadings");
    var totalRubrics = document.getElementById("totalValue");
    var totalPercentageRubrics = isGeneral ? document.getElementById("totalPercentageValueGeneral") : document.getElementById("totalPercentageValue");

    const doc = new Document({
      styles: {
        paragraphStyles: [
          {
            id: "Heading1",
            name: "Heading 1",
            run: {
              size: 35,
              bold: true,
              color: "2c3e51"
            }
          },
          {
            id: "desc",
            name: "descGoals",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              size: 20
            },
            paragraph: {
              spacing: {
                before: 240,
                after: 120
              }
            }
          },
          {
            id: "Heading6",
            name: "Heading 6",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              size: 20,
              color: "2c3e51"
            },
            paragraph: {
              spacing: {
                before: 240,
                after: 120
              }
            }
          }
        ]
      }
    });

    const table = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  text: "  GPF: \n",
                  heading: HeadingLevel.HEADING_6
                }),
                new Paragraph({
                  text: "  " + gpf
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: "  Nome do projeto: \n",
                  heading: HeadingLevel.HEADING_6
                }),
                new Paragraph("  " + projectName)
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: "  Data de Início: \n",
                  heading: HeadingLevel.HEADING_6
                }),
                new Paragraph("  " + season.split(" - ")[0])
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: "  Data de Término: \n",
                  heading: HeadingLevel.HEADING_6
                }),
                new Paragraph("  " + season.split(" - ")[1])
              ]
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  text: "  Rubricas",
                  heading: HeadingLevel.HEADING_6
                })
              ],
              columnSpan: 2
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: "Valor Total",
                  heading: HeadingLevel.HEADING_6,
                  alignment: AlignmentType.CENTER
                })
              ],
              columnSpan: 1
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: "%",
                  heading: HeadingLevel.HEADING_6,
                  alignment: AlignmentType.CENTER
                })
              ],
              columnSpan: 1
            })
          ]
        })
      ],
      width: {
        size: 6000,
        type: WidthType.DXA
      },
      height: {
        size: 19000
      },
      columnWidths: [1500, 4000, 1500, 1700]
    });

    if (allRubrics.length > 1) {
      for (var i = 0; i < allRubrics.length; i++) {
        var description = document.getElementById("descHeading" + (i + 1))
          .value;
        var value = document.getElementById("headingValue" + (i + 1)).value;
        var percentage = document.getElementById("percentage" + (i + 1)).value;

        table.addChildElement(
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    text: "  " + description,
                    heading: HeadingLevel.HEADING_6
                  })
                ],
                columnSpan: 2
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: "  R$ " + value,
                    heading: HeadingLevel.HEADING_6
                  })
                ],
                columnSpan: 1
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: percentage,
                    heading: HeadingLevel.HEADING_6,
                    alignment: AlignmentType.CENTER
                  })
                ],
                columnSpan: 1
              })
            ]
          })
        );
      }
      table.addChildElement(
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  text: "  Total",
                  heading: HeadingLevel.HEADING_6,
                })
              ],
              columnSpan: 2
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: "  R$ " + totalRubrics.value,
                  heading: HeadingLevel.HEADING_6
                })
              ],
              columnSpan: 1
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: totalPercentageRubrics.value,
                  heading: HeadingLevel.HEADING_6,
                  alignment: AlignmentType.CENTER
                })
              ],
              columnSpan: 1
            })
          ]
        })
      )
    }

    doc.addSection({
      children: [
        new Paragraph({
          text: "UNIVERSIDADE FEDERAL DO CEARÁ - UFC ",
          heading: HeadingLevel.HEADING_1,
          alignment: AlignmentType.CENTER
        }),
        new Paragraph({
          text: "LABORATÓRIO DE SISTEMAS E BANCO DE DADOS \n",
          heading: HeadingLevel.HEADING_1,
          alignment: AlignmentType.CENTER
        }),
        table
      ]
    });
    doc.Settings.addCompatibility().doNotExpandShiftReturn();

    Packer.toBuffer(doc).then(buffer => {
      fileDownload(buffer, projectName + "_rubricas.doc");
    });
  };

  const handleChangeSeason = event => {
    var id = getId(event.target.value);
    setSeason(event.target.value);
    periodId = id;
    setAddendumIdSubmit(id);
    updateForChangeSeason();
  };

  const converTotBrCurrency = number => {
    var temp = "";
    var len = number.length;
    var count = 0;

    for (var i = 0; i < len; i++) {
      if (i === 1) {
        var aux = "," + number[i] + temp;
        temp = aux;
      } else {
        if (count === 3) {
          count = 1;
          var aux = number[i] + "." + temp;
          temp = aux;
        } else {
          if (i > 1) {
            count += 1;
          }
          var aux = number[i] + temp;
          temp = aux;
        }
      }
    }

    return temp;
  };

  const handleFieldChange = name => event => {
    if (event.target.value !== " " && !event.target.value.includes(" ")) {
      var str = event.target.value.split("");
      var aux = str.filter(el => el !== "." && el !== ",").join("");
      var value = event.target.value;

      if (event.target.value) {
        value = converTotBrCurrency(
          aux
            .split("")
            .reverse()
            .join("")
        );
      }

      aux = value
        .split("")
        .filter(el => el !== ".")
        .join("")
        .replace(",", ".");
      if (!isNaN(aux)) {
        setState({
          ...state,
          [name]: value
        });
      }
    }
  };

  const convertGeneralTotalToBrCurrency = total => {
    var aux = total
      .toString()
      .split("")
      .filter(el => el !== "." && el !== ",")
      .join("");
    var value = converTotBrCurrency(
      aux
        .split("")
        .reverse()
        .join("")
    );
    var id = periodId != 0 ? periodId : addendumIdSubmit;
    var costAddendum = 0;
    addendums.forEach(element => {
      if (element.number !== "GENERAL") {
        costAddendum += element.estimatedcost
      }
    })

    var totalPercentage = value && value !== "0,00" &&
      costAddendum > 0 ? (
        (parseFloat(
          value
            .split("")
            .filter(el => el !== ".")
            .join("")
            .replace(",", ".")
        ) /
          costAddendum) *
        100
      ).toFixed(2)
      : "0.00";
    return [value, totalPercentage];
  };

  const convertTotalToBrCurrency = total => {
    var aux = total
      .toString()
      .split("")
      .filter(el => el !== "." && el !== ",")
      .join("");
    var value = converTotBrCurrency(
      aux
        .split("")
        .reverse()
        .join("")
    );

    var totalPercentage = value && value !== "0,00" &&
      state.estimatedCost > 0 ? (
        (parseFloat
          (
            value
              .split("")
              .filter(el => el !== ".")
              .join("")
              .replace(",", ".")
          ) /
          state.estimatedCost) * 100
      ).toFixed(2)
      : "0.00";
    return [value, totalPercentage];
  };

  const updateFields = () => {
    var totalResult = [state.total, state.totalPercentage];
    var len = document.getElementsByClassName("addendumHeadings").length;
    var total = 0;

    for (var i = 0; i < len; i++) {
      var value = document.getElementById("headingValue" + (i + 1))
        ? document.getElementById("headingValue" + (i + 1)).value
        : null;
      if (value) {
        var currencyStr = value
          .split("")
          .filter(el => el !== ".")
          .join("")
          .replace(",", ".");
        total += parseFloat(currencyStr);
      }
    }

    totalResult = convertTotalToBrCurrency(total.toFixed(2));
    setState({
      ...state,
      total: totalResult[0],
      totalPercentage: totalResult[1]
    });
  };

  const deleteRubric = rubricForDelete => {
    var toExclude = parseInt(
      document.querySelector("#divHeading" + rubricForDelete).dataset.headingId
    );
    var index = 0;

    for (var i = 0; i < headings.length; i++) {
      var key = parseInt(headings[i].key);

      if (key === rubricForDelete) {
        index = i;
        break;
      }
    }

    var auxList = headings;
    var element = auxList.splice(index, 1);

    setHeadings(headings.filter(item => item !== element));

    setHeadingsCount(headingsCount - 1);
    toDelete.push(toExclude);
  };

  // function setValidFalseState() {
  //   setValid(false)
  // }

  // function setValidTrueState() {
  //   setValid(true)
  // }

  const addHeading = (
    loading,
    headingDescription,
    headingValue,
    headingId,
    cost
  ) => {
    var description = loading === true ? headingDescription : "";
    var value = loading === true ? headingValue : "";
    var id = headingId !== 0 && headingId !== undefined ? headingId : 0;
    var costAddendum = loading === true ? cost : state.estimatedCost;
    getAllHeadings();

    headings.push(
      <div
        id={"divHeading" + (headings.length + 8)}
        key={headings.length + 8}
        data-heading-id={id}
        className="addendumHeadings"
      >
        <NewRubric
          opacity={1}
          deleteRubric={deleteRubric}
          lastHeading={headings.length + 8}
          headingDescription={description}
          headingValue={value}
          disabled={false}
          estimatedCost={costAddendum}
          updateFields={updateFields}
          listRubric={arrayRubrics}
        //valedTrueStateCallback={setValidTrueState}
        //valedFalseStateCallback={setValidFalseState}
        />
      </div>

    );
    setHeadingsCount(headingsCount + 1);
  };

  var result = [];
  var arrayRubrics = []
  const verifyEqualsFields = (fields) => {
    var object = {};
    fields.forEach(function (item) {
      var value = item.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase().replace(/^\s+|\s+$/g, "").replace(/\s{2,}/g, "")
      if (!object[value])
        object[value] = 0;
      object[value] += 1;
    })

    for (var prop in object) {
      if (object[prop] >= 2) {
        result.push(prop);
      }
    }
  }

  const getAllHeadings = () => {
    var allHeadings = [];
    var len = document.getElementsByClassName("addendumHeadings").length;

    for (var i = 0; i < len; i++) {
      var aux = {};
      if (document.getElementById("descHeading" + (i + 1))) {
        aux.description = document.getElementById("descHeading" + (i + 1)).value;
        aux.value = document
          .getElementById("headingValue" + (i + 1))
          .value.split("")
          .filter(el => el !== ".")
          .join("")
          .replace(",", ".");
        aux.id = document.querySelector(
          "#divHeading" + (i + 1)
        ).dataset.headingId;
        allHeadings.push(aux);
        aux = {};
      }
    }
    // get All headings solution:(bugFix/rubric2)
    //project.sgppdAddendum.filter(item => item.sgppdBudgetItem.filter(i => arrayRubrics.push(i.rubric)))

    // get some headings of season 
    for (var i in allHeadings) {
      arrayRubrics.push(allHeadings[i].description)
    }
    verifyEqualsFields(arrayRubrics)

    return allHeadings;
  };

  const handleSubmit = () => {
    var obj = {
      addendumId: "",
      headings: [],
      toDelete: [],
      totalGeneralValue: ""
    };

    obj["addendumId"] = addendumIdSubmit;
    obj["headings"] = getAllHeadings();
    obj["toDelete"] = toDelete.filter(el => el !== 0);
    obj["totalGeneralValue"] = parseFloat(
      document
        .getElementById("totalValue")
        .value.split("")
        .filter(el => el !== ".")
        .join("")
        .replace(",", ".")
    );

    if (result.length === 0) {
      submitHeadings(obj);
      while (arrayRubrics.length) {
        arrayRubrics.pop();
      }
    } else {
      setValid(true)
      console.log("rubrica ja cadastrada!")
    }

  };

  const handleChangeTotal = event => {
    var totalResultGeneral = ["", ""];
    if (event.target.value !== " " && !event.target.value.includes(" ")) {
      var str = event.target.value.split("");
      var aux = str.filter(el => el !== "." && el !== ",").join("");
      var value = event.target.value;

      if (event.target.value) {
        value = converTotBrCurrency(
          aux
            .split("")
            .reverse()
            .join("")
        );
      }

      aux = value
        .split("")
        .filter(el => el !== ".")
        .join("")
        .replace(",", ".");
      if (!isNaN(aux)) {
        totalResultGeneral = convertGeneralTotalToBrCurrency(aux);
        setState({
          ...state,
          total: totalResultGeneral[0],
          totalPercentage: totalResultGeneral[1]
        });
      }
    }
  };

  const transitions = useTransition(loading || delay, null, {
    from: { opacity: 0 },
    enter: {
      opacity: 1
    },
    leave: { opacity: 0 }
  });

  return transitions.map(({ item, key, props }) =>
    item ? (
      <animated.div key={key} style={props}>
        <></>
      </animated.div>
    ) : (
        <animated.div key={key} style={props}>
          <Paper className={classes.root}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={snackBar.open}
            onClose={handleSnackClose}
            TransitionComponent={Slide}
            transitionDuration={{ enter: 500, exit: 500 }}
            ContentProps={{
              "aria-describedby": "message-id"
            }}
            autoHideDuration={2000}
          >
            <MySnackbarContentWrapper
              onClose={handleSnackClose}
              variant={
                responseType !== ""
                  ? responseType.toLowerCase()
                  : allowanceResponseType.toLowerCase()
              }
              message={
                <span id="message-id">
                  {response !== "" ? response : allowanceResponse}
                </span>
              }
            />
          </Snackbar>
          <div className={classes.header}>
            {t("HEADINGS.register").toUpperCase()}
            {hasCooperationTerm &&
            <Tooltip
              title={t("GENERAL.downloadDocument")}
              aria-label="details"
              placement="top"
              onClick={createFile}
            >
              <IconButton>
                <GetApp />
              </IconButton>
            </Tooltip>}
          </div>
          {hasCooperationTerm ? 
          <div>
          <div className={classes.bodyForm}>
            <Grid container item spacing={2}>
              <Grid item xs={2}>
                <TextField
                  disabled
                  label={t("PROJECTS.gpfUpperCase")}
                  className={classes.textField}
                  value={gpf}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  disabled
                  label={t("PROJECTS.projectName")}
                  className={classes.textField}
                  value={projectName}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  select
                  id="season"
                  label={t("PROJECTS.season")}
                  className={classes.textField}
                  name={season}
                  margin="normal"
                  value={season}
                  onChange={handleChangeSeason}
                >
                  {seasonsArray.map(option => (
                    <MenuItem key={option.value} value={option.label}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </div>
          <div className={classes.bodyForm}>
            <Grid container spacing={2}>
              <Grid item xs={4} style={{ fontWeight: "bold", opacity: "0.54" }}>
                {t("PROJECTS.step.headings").toUpperCase()}
              </Grid>
              <Grid item xs={3} style={{ fontWeight: "bold", opacity: "0.54" }}>
                {t("PROJECTS.totalHeadings").toUpperCase()}
              </Grid>
              <Grid item xs={1} style={{ fontWeight: "bold", opacity: "0.54" }}>
                %
            </Grid>
              <Grid
                item
                xs={1}
                style={{ marginTop: "-12px", marginBottom: "-15px" }}
              >
                <Tooltip
                  title="Adicionar rubrica"
                  aria-label="adicionar"
                  placement="top"
                >
                  <IconButton onClick={addHeading} style={{ float: "right" }}>
                    <AddCircle />
                  </IconButton>
                </Tooltip>
              </Grid>
              <div className="lineBreak"></div>
            </Grid>
            <div
              id={"divHeading1"}
              key={1}
              className="addendumHeadings"
              data-heading-id={state.headingId1}
            >
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    id={"descHeading1"}
                    className={classes.textField}
                    value={t("HEADINGS.directHr")}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id={"headingValue1"}
                    inputRef={estimatedCostRef}
                    className={classes.textField}
                    margin="normal"
                    name="headingValue"
                    value={state.directHrCosts}
                    onChange={handleFieldChange("directHrCosts")}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">R$</InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    id={"percentage1"}
                    className={classes.textField}
                    value={
                      state.directHrCosts && state.estimatedCost > 0
                        ? (
                          (parseFloat(
                            state.directHrCosts
                              .split("")
                              .filter(el => el !== ".")
                              .join("")
                              .replace(",", ".")
                          ) /
                            state.estimatedCost) *
                          100
                        ).toFixed(2)
                        : "0.00"
                    }
                    margin="normal"
                  />
                </Grid>
              </Grid>
              <div className="lineBreak"></div>
            </div>
            <div
              id={"divHeading2"}
              key={2}
              className="addendumHeadings"
              data-heading-id={state.headingId2}
            >
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    id={"descHeading2"}
                    className={classes.textField}
                    value={t("HEADINGS.indirectHr")}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id={"headingValue2"}
                    inputRef={estimatedCostRef}
                    className={classes.textField}
                    margin="normal"
                    name="headingValue"
                    onChange={handleFieldChange("indirectHrCosts")}
                    value={state.indirectHrCosts}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">R$</InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    id={"percentage2"}
                    className={classes.textField}
                    value={
                      state.indirectHrCosts && state.estimatedCost > 0
                        ? (
                          (parseFloat(
                            state.indirectHrCosts
                              .split("")
                              .filter(el => el !== ".")
                              .join("")
                              .replace(",", ".")
                          ) /
                            state.estimatedCost) *
                          100
                        ).toFixed(2)
                        : "0.00"
                    }
                    margin="normal"
                  />
                </Grid>
              </Grid>
              <div className="lineBreak"></div>
            </div>
            <div
              id={"divHeading3"}
              key={3}
              className="addendumHeadings"
              data-heading-id={state.headingId3}
            >
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    id={"descHeading3"}
                    className={classes.textField}
                    value={t("HEADINGS.equipments")}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id={"headingValue3"}
                    inputRef={estimatedCostRef}
                    className={classes.textField}
                    margin="normal"
                    name="headingValue"
                    onChange={handleFieldChange("equipmentsCosts")}
                    value={state.equipmentsCosts}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">R$</InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    id={"percentage3"}
                    className={classes.textField}
                    value={
                      state.equipmentsCosts && state.estimatedCost > 0
                        ? (
                          (parseFloat(
                            state.equipmentsCosts
                              .split("")
                              .filter(el => el !== ".")
                              .join("")
                              .replace(",", ".")
                          ) /
                            state.estimatedCost) *
                          100
                        ).toFixed(2)
                        : "0.00"
                    }
                    margin="normal"
                  />
                </Grid>
              </Grid>
              <div className="lineBreak"></div>
            </div>
            <div
              id={"divHeading4"}
              key={4}
              className="addendumHeadings"
              data-heading-id={state.headingId4}
            >
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    id={"descHeading4"}
                    className={classes.textField}
                    value={t("HEADINGS.consumables")}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id={"headingValue4"}
                    inputRef={estimatedCostRef}
                    className={classes.textField}
                    margin="normal"
                    name="headingValue"
                    onChange={handleFieldChange("consumablesCosts", 4)}
                    value={state.consumablesCosts}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">R$</InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    id={"percentage4"}
                    className={classes.textField}
                    value={
                      state.consumablesCosts && state.estimatedCost > 0
                        ? (
                          (parseFloat(
                            state.consumablesCosts
                              .split("")
                              .filter(el => el !== ".")
                              .join("")
                              .replace(",", ".")
                          ) /
                            state.estimatedCost) *
                          100
                        ).toFixed(2)
                        : "0.00"
                    }
                    margin="normal"
                  />
                </Grid>
              </Grid>
              <div className="lineBreak"></div>
            </div>
            <div
              id={"divHeading5"}
              key={5}
              className="addendumHeadings"
              data-heading-id={state.headingId5}
            >
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    id={"descHeading5"}
                    className={classes.textField}
                    value={t("HEADINGS.booksAndPeriodicals")}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id={"headingValue5"}
                    inputRef={estimatedCostRef}
                    className={classes.textField}
                    margin="normal"
                    name="headingValue"
                    onChange={handleFieldChange("booksAndPeriodicalsCosts")}
                    value={state.booksAndPeriodicalsCosts}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">R$</InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    id={"percentage5"}
                    className={classes.textField}
                    value={
                      state.booksAndPeriodicalsCosts && state.estimatedCost > 0
                        ? (
                          (parseFloat(
                            state.booksAndPeriodicalsCosts
                              .split("")
                              .filter(el => el !== ".")
                              .join("")
                              .replace(",", ".")
                          ) /
                            state.estimatedCost) *
                          100
                        ).toFixed(2)
                        : "0.00"
                    }
                    margin="normal"
                  />
                </Grid>
              </Grid>
              <div className="lineBreak"></div>
            </div>
            <div
              id={"divHeading6"}
              key={6}
              className="addendumHeadings"
              data-heading-id={state.headingId6}
            >
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    id={"descHeading6"}
                    className={classes.textField}
                    value={t("HEADINGS.trips")}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id={"headingValue6"}
                    inputRef={estimatedCostRef}
                    className={classes.textField}
                    margin="normal"
                    name="headingValue"
                    onChange={handleFieldChange("tripsCosts")}
                    value={state.tripsCosts}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">R$</InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    id={"percentage6"}
                    className={classes.textField}
                    value={
                      state.tripsCosts && state.estimatedCost > 0
                        ? (
                          (parseFloat(
                            state.tripsCosts
                              .split("")
                              .filter(el => el !== ".")
                              .join("")
                              .replace(",", ".")
                          ) /
                            state.estimatedCost) *
                          100
                        ).toFixed(2)
                        : "0.00"
                    }
                    margin="normal"
                  />
                </Grid>
              </Grid>
              <div className="lineBreak"></div>
            </div>
            <div
              id={"divHeading7"}
              key={7}
              className="addendumHeadings"
              data-heading-id={state.headingId7}
            >
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    id={"descHeading7"}
                    className={classes.textField}
                    value={t("HEADINGS.incurredCosts")}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id={"headingValue7"}
                    inputRef={estimatedCostRef}
                    className={classes.textField}
                    margin="normal"
                    name="headingValue"
                    onChange={handleFieldChange("incurredCostsValue")}
                    value={state.incurredCostsValue}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">R$</InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    id={"percentage7"}
                    className={classes.textField}
                    value={
                      state.incurredCostsValue && state.estimatedCost > 0
                        ? (
                          (parseFloat(
                            state.incurredCostsValue
                              .split("")
                              .filter(el => el !== ".")
                              .join("")
                              .replace(",", ".")
                          ) /
                            state.estimatedCost) *
                          100
                        ).toFixed(2)
                        : "0.00"
                    }
                    margin="normal"
                  />
                </Grid>
              </Grid>
              <div className="lineBreak"></div>
            </div>
            <div>{headings}</div>
            {!isGeneral && (
              <div id={"divTotalHeadings"} className="totalHeadings">
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      className={classes.textField}
                      style={{ fontWeight: "bold" }}
                      value={t("GENERAL.total")}
                      margin="normal"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      style={{
                        backgroundColor:
                          state.totalPercentage > 100 ? "#f16b68" : "",
                        fontWeight: "bold"
                      }}
                      id={"totalValue"}
                      inputRef={estimatedCostRef}
                      className={classes.textField}
                      margin="normal"
                      name="headingValue"
                      value={state.total}
                      disabled
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">R$</InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <TextField
                      id={"totalPercentageValue"}
                      className={classes.textField}
                      style={{ fontWeight: "bold" }}
                      value={state.totalPercentage}
                      margin="normal"
                      disabled
                    />
                  </Grid>
                </Grid>
                <div className="lineBreak"></div>
              </div>
            )}
            {isGeneral && (
              <div id={"divTotalHeadings"} className="totalHeadings">
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      className={classes.textField}
                      style={{ fontWeight: "bold" }}
                      value={t("GENERAL.total")}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      style={{
                        backgroundColor:
                          state.totalPercentage > 100 ? "#f16b68" : "",
                        fontWeight: "bold"
                      }}
                      id={"totalValue"}
                      inputRef={estimatedCostRef}
                      className={classes.textField}
                      margin="normal"
                      name="headingValue"
                      value={state.total}
                      onChange={handleChangeTotal}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">R$</InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <TextField
                      id={"totalPercentageValueGeneral"}
                      className={classes.textField}
                      style={{ fontWeight: "bold" }}
                      value={state.totalPercentage}
                      margin="normal"
                    />
                  </Grid>
                </Grid>
                <div className="lineBreak"></div>
              </div>
            )}
          </div>
          <Fab
            color="primary"
            aria-label="update"
            onClick={handleSubmit}
            className={classes.fab}
          >
            <Save />
          </Fab>
          </div>
        :
        <p className={classes.Hint}>
          {t("PHASES.hintNoAddendum")}
        </p>
        }
        </Paper>
        </animated.div>
      )
  );
};

export default Heading;
