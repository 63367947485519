import React from "react";
import "../../../App.css";
import SideBar from "../../layout/sidebar/Sidebar";
import ViewParticipant from "./ViewParticipant";
import DashboardNavBar from "../../layout/DashboardNavBar";

const Index = ({ match }) => {
  return (
    <div>
      <DashboardNavBar />
      <SideBar />
      <ViewParticipant
        id={match.params.id}
        projectId={match.params.projectId}
        mode={match.params.mode}
        participationId={match.params.participationId}
        participantMode={match.params.participantMode}
      />
    </div>
  );
}

export default Index;