/* eslint-disable */
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';
import Check from '@material-ui/icons/Check';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import axios from 'axios';
import Buttons from 'components/buttons/Pressed';
import Footer from 'components/layout/Footer';
import PartnersContext from 'context/partners/partnersContext';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import { animated, useTransition } from 'react-spring';
import DashboardNavBar from '../layout/DashboardNavBar';
import SideBar from '../layout/sidebar/Sidebar';
import { useStyles } from './PartnerStyles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Cookies from 'js-cookie';
import { phoneMask } from 'utils/masks/phoneMask';
import { faxMask } from 'utils/masks/faxMask';
import { cepMask } from 'utils/masks/cepMask';
import { cnpjMask } from 'utils/masks/cnpjMask';

const tooltipTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#2c3e51'
        }
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                color: 'white',
                fontSize: '1em',
                backgroundColor: 'red',
                opacity: '0.7'
            }
        }
    }
});

const status = [
    {
        value: true,
        label: 'Ativo'
    },
    {
        value: false,
        label: 'Inativo'
    }
];

const types = [
    {
        value: 'CUSTOMER',
        label: 'Cliente'
    },
    {
        value: 'EXECUTING_INSTITUTION',
        label: 'Fundação de Apoio'
    }
];

const renderRedirect = go => {
    if (go) return <Redirect to={'/partners'} />;
};

const NewPartner = ({ match }) => {
    const classes = useStyles();
    const [t] = useTranslation();
    const partnersContext = useContext(PartnersContext);

    const { addPartner, resetMessage, partners, getPartners } = partnersContext;
    const [open, setOpen] = useState(false);
    const [mustRedirect, setMustRedirect] = useState(false);
    const [validName, setValidName] = useState(false);
    const [validCnpj, setValidCnpj] = useState(false);
    const [validCep, setValidCep] = useState(false);
    const [titleName, setTitle] = useState('GENERAL.requiredField');
    const [titleCnpj, setTitleCnpj] = useState('GENERAL.requiredField');
    const [titleCep, setTitleCep] = useState('');

    const nameRef = useRef(null);
    const cnpjRef = useRef(null);
    const cepRef = useRef(null);

    const [values, setValues] = React.useState({
        id: null,
        cnpj: '',
        corporateName: '',
        tradeName: '',
        responsibleName: '',
        cep: '',
        address: '',
        neighborhood: '',
        city: '',
        state: '',
        country: '',
        phone: '',
        fax: '',
        email: '',
        type: 'CUSTOMER',
        status: 'true',
        financialContactName: '',
        financialContactEmail: '',
        financialContactFax: '',
        financialContactPhone: ''
    });

    function clean_cep() {
        setValues({
            ...values,
            address: '',
            neighborhood: '',
            city: '',
            state: '',
            country: ''
        });
    }

    function runCep(valor) {
        var cep = valor.replace(/\D/g, '');
        if (cep !== '') {
            var validacep = /^[0-9]{8}$/;
            if (validacep.test(cep)) {
                axios
                    .get(`https://viacep.com.br/ws/${cep}/json/`, {
                        headers: {
                            'Content-type': 'application/json',
                            Authorization:
                                'bearer ' +
                                (Cookies.get('auth-token')
                                    ? Cookies.get('auth-token')
                                    : '')
                        }
                    })
                    .then(res => {
                        if (!res.data.hasOwnProperty('erro')) {
                            setValues({
                                ...values,
                                address:
                                    res.data.logradouro +
                                    ' ' +
                                    res.data.complemento,
                                neighborhood: res.data.bairro,
                                city: res.data.localidade,
                                state: res.data.uf,
                                country: 'Brasil'
                            });
                            setValidCep(false);
                        } else {
                            setTitleCep('CEP invalido');
                            setValidCep(true);
                            clean_cep();
                        }
                    });
            } else {
                setTitleCep('CEP invalido');
                setValidCep(true);
                clean_cep();
            }
        }
    }

    const showFinancialContatc = () => {
        return values.type === 'EXECUTING_INSTITUTION' ? (
            <div className={classes.paperDivision}>
                <Paper className={classes.root}>
                    <div className={classes.header}>
                        {t('PARTNERS.financialContact').toUpperCase()}
                    </div>
                    <div className={classes.bodyForm}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <TextField
                                    key={'financialContactName'}
                                    className={classes.adjustReactTextMask}
                                    label={t('PERSONAL.name')}
                                    value={values.financialContactName}
                                    onChange={handleChange(
                                        'financialContactName'
                                    )}
                                    margin="normal"
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    className={classes.adjustReactTextMask}
                                    label={t('ADDRESS.email')}
                                    value={values.financialContactEmail}
                                    onChange={handleChange(
                                        'financialContactEmail'
                                    )}
                                    margin="normal"
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    className={classes.adjustReactTextMask}
                                    label={t('ADDRESS.phone')}
                                    name="phone"
                                    value={values.financialContactPhone}
                                    onChange={handleChangePhoneMask(
                                        'financialContactPhone'
                                    )}
                                    id="formatted-text-mask-input"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    className={classes.textField}
                                    label={t('ADDRESS.fax')}
                                    value={values.financialContactFax}
                                    onChange={handleChangeFaxMask(
                                        'financialContactFax'
                                    )}
                                    margin="normal"
                                    inputProps={{
                                        maxLength: 20
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Paper>
            </div>
        ) : (
            <></>
        );
    };

    useEffect(() => {
        getPartners();
        resetMessage();
        // var id = match.params.id;
        return () => {};
    }, []);
    let data = [];
    data = partners;
    const handleChange = name => event => {
        setValidName(false);
        setValues({ ...values, [name]: event.target.value });

        data = data.filter(item => item.socialreason === event.target.value);
        if (data.length !== 0) {
            setTitle(t('PARTNERS.nameAlreadyTaken'));
            setValidName(true);
            setValidCnpj(false);
        } else {
            setTitle(' ');
        }
    };

    const handleChangeCNPJMask = name => e => {
        setValidCnpj(false);
        setValues({ ...values, [name]: cnpjMask(e.target.value) });
        data = data.filter(item => item.cnpj === e.target.value);
        if (data.length !== 0) {
            setTitleCnpj(t('PARTNERS.cnpjAlreadyTaken'));
            setValidCnpj(true);
            setValidName(false);
        } else {
            setTitleCnpj(' ');
        }
    };

    const handleChangeFaxMask = name => e => {
        setValues({ ...values, [name]: faxMask(e.target.value) });
    };

    const handleChangeCepMask = name => e => {
        setValues({ ...values, [name]: cepMask(e.target.value) });
    };
    const handleChangePhoneMask = name => e => {
        setValues({ ...values, [name]: phoneMask(e.target.value) });
    };
    const handleChangeFindCep = name => e => {
        setValues({ ...values, [name]: runCep(e.target.value) });
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = e => {
        if (
            cnpjRef.current.value.length === 0 &&
            nameRef.current.value.length === 0
        ) {
            cnpjRef.current.focus();
            setTitle('GENERAL.requiredField');
            setValidCnpj(true);
            setValidName(true);
        } else if (nameRef.current.value.length === 0) {
            nameRef.current.focus();
            setTitle('GENERAL.requiredField');
            setValidName(true);
        } else if (titleName === 'Razão social já em uso') {
            nameRef.current.focus();
            setValidName(true);
        } else if (cnpjRef.current.value.length === 0) {
            cnpjRef.current.focus();
            setTitle('GENERAL.requiredField');
            setValidCnpj(true);
        } else if (titleCnpj === 'CNPJ já em uso') {
            cnpjRef.current.focus();
            setValidCnpj(true);
        } else {
            setOpen(true);
        }
        e.preventDefault();
    };

    const handleSubmit = e => {
        addPartner(values);
        setOpen(false);
        setMustRedirect(true);
        e.preventDefault();
    };

    const transitions = useTransition(true, null, {
        from: { opacity: 0 },
        enter: {
            opacity: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: 'calc(100vh - 70px)'
        },
        leave: { opacity: 0 }
    });

    return transitions.map(
        ({ item, key, props }) =>
            item && (
                <div key={key}>
                    <DashboardNavBar />
                    <SideBar />
                    <animated.div style={props}>
                        {renderRedirect(mustRedirect)}
                        <div className="container">
                            <Breadcrumbs
                                className={classes.breadCrumbs}
                                aria-label="breadcrumb"
                                separator={
                                    <NavigateNextIcon fontSize="small" />
                                }
                            >
                                <Typography>{t('GENERAL.planning')}</Typography>
                                <Typography>
                                    {t('GENERAL.configurations')}
                                </Typography>
                                <Link className={classes.link} to="/partners">
                                    {t('PARTNERS.partners')}
                                </Link>
                                <Typography>{t('GENERAL.add')}</Typography>
                            </Breadcrumbs>
                            <div className="container-header">
                                <div className="title">
                                    <h1 className={classes.title}>
                                        {t('GENERAL.add') +
                                            ' ' +
                                            t('PARTNERS.partner')}
                                    </h1>
                                </div>
                                <Button
                                    className={classes.blueButton}
                                    variant="contained"
                                    color="primary"
                                    form="formPartners"
                                    type="submit"
                                >
                                    <Check />
                                    {t('GENERAL.add') +
                                        ' ' +
                                        t('PARTNERS.partner')}
                                </Button>
                            </div>

                            <form id="formPartners" onSubmit={handleClickOpen}>
                                <Paper className={classes.root}>
                                    <div className={classes.header}>
                                        {t('PARTNERS.partner').toUpperCase()}
                                    </div>
                                    <div className={classes.bodyForm}>
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={5}
                                                md={2}
                                                lg={2}
                                                xl={2}
                                            >
                                                <MuiThemeProvider
                                                    theme={tooltipTheme}
                                                >
                                                    <Tooltip
                                                        TransitionComponent={
                                                            Zoom
                                                        }
                                                        open={validCnpj}
                                                        title={t(titleCnpj)}
                                                    >
                                                        <TextField
                                                            //required
                                                            inputRef={cnpjRef}
                                                            error={validCnpj}
                                                            className={
                                                                classes.adjustReactTextMask
                                                            }
                                                            label={
                                                                t(
                                                                    'PERSONAL.CNPJ'
                                                                ) + '*'
                                                            }
                                                            name="cnpj"
                                                            value={values.cnpj}
                                                            onChange={handleChangeCNPJMask(
                                                                'cnpj'
                                                            )}
                                                            inputProps={{
                                                                maxLength: 18
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </MuiThemeProvider>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={7}
                                                md={4}
                                                lg={4}
                                                xl={4}
                                            >
                                                <MuiThemeProvider
                                                    theme={tooltipTheme}
                                                >
                                                    <Tooltip
                                                        TransitionComponent={
                                                            Zoom
                                                        }
                                                        open={validName}
                                                        title={t(titleName)}
                                                    >
                                                        <TextField
                                                            inputRef={nameRef}
                                                            error={validName}
                                                            className={
                                                                classes.textField
                                                            }
                                                            label={
                                                                t(
                                                                    'PARTNERS.corporateName'
                                                                ) + '*'
                                                            }
                                                            value={
                                                                values.corporateName
                                                            }
                                                            onChange={handleChange(
                                                                'corporateName'
                                                            )}
                                                            margin="normal"
                                                            inputProps={{
                                                                maxLength: 100
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </MuiThemeProvider>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={3}
                                                lg={3}
                                                xl={3}
                                            >
                                                <TextField
                                                    className={
                                                        classes.textField
                                                    }
                                                    label={t(
                                                        'PARTNERS.tradeName'
                                                    )}
                                                    value={values.tradeName}
                                                    onChange={handleChange(
                                                        'tradeName'
                                                    )}
                                                    margin="normal"
                                                    inputProps={{
                                                        maxLength: 100
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={3}
                                                lg={3}
                                                xl={3}
                                            >
                                                <TextField
                                                    className={
                                                        classes.textField
                                                    }
                                                    label={t(
                                                        'PARTNERS.responsibleName'
                                                    )}
                                                    value={
                                                        values.responsibleName
                                                    }
                                                    onChange={handleChange(
                                                        'responsibleName'
                                                    )}
                                                    margin="normal"
                                                    inputProps={{
                                                        maxLength: 50
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={4}
                                                md={2}
                                                lg={2}
                                                xl={2}
                                            >
                                                <MuiThemeProvider
                                                    theme={tooltipTheme}
                                                >
                                                    <Tooltip
                                                        TransitionComponent={
                                                            Zoom
                                                        }
                                                        open={validCep}
                                                        title={t(titleCep)}
                                                    >
                                                        <TextField
                                                            inputRef={cepRef}
                                                            error={validCep}
                                                            type="text"
                                                            className={
                                                                classes.adjustReactTextMask
                                                            }
                                                            label={t(
                                                                'ADDRESS.cep'
                                                            )}
                                                            value={values.cep}
                                                            name="cep"
                                                            onBlur={handleChangeFindCep(
                                                                'cep'
                                                            )}
                                                            onChange={handleChangeCepMask(
                                                                'cep'
                                                            )}
                                                            // InputProps={{
                                                            //   inputComponent: TextMaskCustom
                                                            // }}
                                                        />
                                                    </Tooltip>
                                                </MuiThemeProvider>
                                            </Grid>
                                            <Grid
                                                item
                                                item
                                                xs={12}
                                                sm={8}
                                                md={7}
                                                lg={4}
                                                xl={4}
                                            >
                                                <TextField
                                                    type="text"
                                                    label={t('ADDRESS.address')}
                                                    className={
                                                        classes.textField
                                                    }
                                                    value={values.address}
                                                    onChange={handleChange(
                                                        'address'
                                                    )}
                                                    margin="normal"
                                                    inputProps={{
                                                        maxLength: 100
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={3}
                                                lg={2}
                                                xl={2}
                                            >
                                                <TextField
                                                    type="text"
                                                    label={t(
                                                        'ADDRESS.neighborhood'
                                                    )}
                                                    className={
                                                        classes.textField
                                                    }
                                                    value={values.neighborhood}
                                                    onChange={handleChange(
                                                        'neighborhood'
                                                    )}
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={3}
                                                lg={2}
                                                xl={2}
                                            >
                                                <TextField
                                                    type="text"
                                                    label={t('ADDRESS.city')}
                                                    className={
                                                        classes.textField
                                                    }
                                                    value={values.city}
                                                    onChange={handleChange(
                                                        'city'
                                                    )}
                                                    margin="normal"
                                                    inputProps={{
                                                        maxLength: 20
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={3}
                                                lg={2}
                                                xl={1}
                                            >
                                                <TextField
                                                    type="text"
                                                    label={t('ADDRESS.state')}
                                                    className={
                                                        classes.textField
                                                    }
                                                    value={values.state}
                                                    onChange={handleChange(
                                                        'state'
                                                    )}
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={3}
                                                lg={2}
                                                xl={1}
                                            >
                                                <TextField
                                                    type="text"
                                                    label={t('ADDRESS.country')}
                                                    className={
                                                        classes.textField
                                                    }
                                                    value={values.country}
                                                    onChange={handleChange(
                                                        'country'
                                                    )}
                                                    margin="normal"
                                                    inputProps={{
                                                        maxLength: 20
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={3}
                                                lg={2}
                                                xl={2}
                                            >
                                                <TextField
                                                    className={
                                                        classes.adjustReactTextMask
                                                    }
                                                    label={t('ADDRESS.phone')}
                                                    name="phone"
                                                    value={values.phone}
                                                    onChange={handleChangePhoneMask(
                                                        'phone'
                                                    )}
                                                    id="formatted-text-mask-input"
                                                    // InputProps={{
                                                    //   inputComponent: TextMaskCustom
                                                    // }}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={3}
                                                lg={2}
                                                xl={2}
                                            >
                                                <TextField
                                                    className={
                                                        classes.textField
                                                    }
                                                    label={t('ADDRESS.fax')}
                                                    value={values.fax}
                                                    onChange={handleChangeFaxMask(
                                                        'fax'
                                                    )}
                                                    margin="normal"
                                                    inputProps={{
                                                        maxLength: 20
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={4}
                                                lg={3}
                                                xl={4}
                                            >
                                                <TextField
                                                    className={
                                                        classes.textField
                                                    }
                                                    label={t('ADDRESS.email')}
                                                    value={values.email}
                                                    onChange={handleChange(
                                                        'email'
                                                    )}
                                                    margin="normal"
                                                    inputProps={{
                                                        maxLength: 50
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={3}
                                                lg={2}
                                                xl={3}
                                            >
                                                <TextField
                                                    id="standard-select-type"
                                                    required
                                                    select
                                                    label={t('ADDRESS.type')}
                                                    className={
                                                        classes.textField
                                                    }
                                                    value={values.type}
                                                    onChange={handleChange(
                                                        'type'
                                                    )}
                                                    SelectProps={{
                                                        MenuProps: {
                                                            className:
                                                                classes.menu
                                                        }
                                                    }}
                                                    margin="normal"
                                                >
                                                    {types.map(option => (
                                                        <MenuItem
                                                            key={option.value}
                                                            value={option.value}
                                                        >
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={2}
                                                lg={1}
                                                xl={1}
                                            >
                                                <TextField
                                                    id="standard-select-status"
                                                    required
                                                    select
                                                    label={t('ADDRESS.status')}
                                                    className={
                                                        classes.textField
                                                    }
                                                    value={values.status}
                                                    onChange={handleChange(
                                                        'status'
                                                    )}
                                                    SelectProps={{
                                                        MenuProps: {
                                                            className:
                                                                classes.menu
                                                        }
                                                    }}
                                                    margin="normal"
                                                >
                                                    {status.map(option => (
                                                        <MenuItem
                                                            key={option.value}
                                                            value={option.value}
                                                        >
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Paper>
                                {showFinancialContatc()}
                            </form>
                            <Dialog
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="form-dialog-title"
                            >
                                <DialogTitle className={classes.Title}>
                                    {t('PARTNERS.new')}
                                    <IconButton
                                        style={{
                                            float: 'right',
                                            marginTop: -10,
                                            marginRight: -17
                                        }}
                                        edge="end"
                                        color="inherit"
                                        onClick={handleClose}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        {t('PARTNERS.createPartnerQuestion')}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Buttons
                                        onClick={handleClose}
                                        tipo="BN-noback"
                                        conteudo={t(
                                            'GENERAL.cancel'
                                        ).toUpperCase()}
                                    />
                                    <Buttons
                                        onClick={handleSubmit}
                                        tipo="BN-blue"
                                        conteudo={t(
                                            'GENERAL.add'
                                        ).toUpperCase()}
                                    />
                                </DialogActions>
                            </Dialog>
                        </div>
                        <Footer />
                    </animated.div>
                </div>
            )
    );
};

export default NewPartner;
