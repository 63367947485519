import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { doTruncarStr } from "utils/general/TruntecStr";
import { DateToDayAndMonth } from "utils/dates/DateToDayAndMonth";
import { FormatDateToFront } from "utils/dates/FormatDateToFront";
import { dateToBR } from "utils/dates/DateToBRr";
import Action from "./VacationCalendarTableAction";
import {
    StyledTableCell,
    StyledTableRow,
    useStyles as useProfileStyles
} from "components/profiles/ProfileStyles";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ErrorIcon from '@material-ui/icons/Error';


const VacationCalendarTable = props => {
    const [t] = useTranslation();
    const classes = useStyles();
    const profileClasses = useProfileStyles();

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('name');

    const { rows, optionSelected, monthStatus, headers, sortedFields } = props;



    const months = [
        t("GENERAL.january"),
        t("GENERAL.february"),
        t("GENERAL.march"),
        t("GENERAL.april"),
        t("GENERAL.may"),
        t("GENERAL.june"),
        t("GENERAL.july"),
        t("GENERAL.august"),
        t("GENERAL.september"),
        t("GENERAL.october"),
        t("GENERAL.november"),
        t("GENERAL.december")

    ];


    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === sortedFields[property] && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(sortedFields[property]);
    };

    const VacationTableHead = props => {
        const { order, orderBy, onRequestSort } = props;
        const [t] = useTranslation();
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    {headers.map((header, index) => (
                        <StyledTableCell
                            style={{ backgroundColor: "#ffffff" }}
                            key={index}
                            size="small"
                            align="left"
                            sortDirection={orderBy === sortedFields[index] ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === sortedFields[index]}
                                direction={order}
                                onClick={createSortHandler(index)}
                                disabled={index === 5}
                            >
                                {header.label}
                                {orderBy === sortedFields[index] ? (
                                    <span className={profileClasses.visuallyHidden}>
                                        {order === "desc"
                                            ? "sorted descending"
                                            : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    };

    VacationTableHead.propTypes = {
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.oneOf(["asc", "desc"]).isRequired,
        orderBy: PropTypes.string.isRequired
    };




    function desc(a, b, orderBy) {
        /*
         * .normalize("NFD").replace(/[\u0300-\u036f]/g, "") : desconsidera
         * acentos
         */

        if (a[orderBy] === null) a[orderBy] = "";
        if (b[orderBy] === null) b[orderBy] = "";

        if (a[orderBy] === undefined) a[orderBy] = "";
        if (b[orderBy] === undefined) b[orderBy] = "";

        if (orderBy === "remunerationBalance" || orderBy === "enjoymentBalance") {
            if (b[orderBy] < a[orderBy]) {
                return -1;
            } else {
                return 1;
            }
        }

        if (
            b[orderBy].toString().normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase() <
            a[orderBy].toString().normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase()
        ) {
            return -1;
        }
        if (
            b[orderBy].toString().normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase() >
            a[orderBy].toString().normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase()
        ) {
            return 1;
        }
        return 0;
    }

    function stableSort(array, cmp) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = cmp(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }

    function getSorting(order, orderBy) {
        return order === "desc"
            ? (a, b) => desc(a, b, orderBy)
            : (a, b) => -desc(a, b, orderBy);
    }


    return (

        <React.Fragment>
            {months.map((month, month_id) => {
                const month_rows = rows.filter(el => {
                    return el["dayToObtain"] != null && new Date(FormatDateToFront(el["dayToObtain"])).getMonth() == month_id;
                });

                return (
                    (month_id == optionSelected || optionSelected == -1) &&
                    <div>
                        <Toolbar style={{ minHeight: "48px", padding: "0px 18px", backgroundColor: "#f5f6fa" }}>
                            <Typography
                                className={classes.title}
                                style={{ fontSize: "14px" }}
                                id="tableTitle"
                                component="div"
                            >
                                {month.toUpperCase()}
                            </Typography>
                        </Toolbar>
                        <div style={{ display: "flex", backgroundColor: "rgba(128,128,128,0.37)", minHeight: "1px", height: "1px", width: "100%" }} />
                        <Table>
                            {month_rows.length > 0 &&
                                <VacationTableHead
                                    classes={classes}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                />
                            }
                            <TableBody>
                                {month_rows && month_rows.length < 1 && (
                                    monthStatus[month_id] == "NO_PENDENCIES" ?
                                        <div className={classes.noParticipantsMessage}>
                                            <h4>{t("HR.noCollaboratorVacationPending")}</h4>
                                        </div>
                                        : <div className={classes.noParticipantsMessage}>
                                            <h4>{t("HR.noCollaboratorThisMonth")}</h4>
                                        </div>
                                )

                                }

                                {stableSort(month_rows, getSorting(order, orderBy))
                                    .map((row, idx) => (
                                        < StyledTableRow key={row.contractId} >
                                            <TableCell size="small" style={{ width: "25%" }} >
                                                {row["name"] ? doTruncarStr(row["name"], 40) : "-"}
                                            </TableCell>
                                            <TableCell style={{ width: "15%" }}>
                                                {row["dayToObtain"] ? dateToBR(FormatDateToFront(row["dayToObtain"])).toString() : "-"}
                                            </TableCell>
                                            <TableCell style={{ width: "20%" }}>
                                                {(
                                                    (
                                                        row["daysToEnjoy"] != null ?
                                                            (

                                                                (row["daysToEnjoy"] >= 0 && row["enjoyedPeriodStatus"] == "UNDER_ACQUISITION") ?
                                                                    t("HR.underAcquisition") + " (" + row["daysToEnjoy"] + " " + t("GENERAL.days").toLowerCase() + ")"
                                                                    : row["daysToEnjoy"] > 0 && row["enjoyedPeriodStatus"] == "PENDING" ?
                                                                        t("HR.pending") + " (" + row["daysToEnjoy"] + " " + t("GENERAL.days").toLowerCase() + ")"
                                                                        : row["enjoyedPeriodStatus"] == "ENJOYED" ? t("HR.enjoyed") +
                                                                            " (" + (row["ruleset"] == "CLT" ? 30 : 15) + " " + t("GENERAL.days").toLowerCase() + ")"

                                                                            : ""


                                                            )

                                                            : ""

                                                    )
                                                    || "-"
                                                )}
                                                {
                                                    row["daysToEnjoy"] >= 0 && row["enjoyedPeriodStatus"] == "UNDER_ACQUISITION" ? <AccessTimeIcon style={{ float: "right", marginLeft: 5 }} />
                                                        : row["daysToEnjoy"] > 0 && row["enjoyedPeriodStatus"] == "PENDING" ? <ErrorIcon style={{ float: "right", marginLeft: 5, color: "#F3222F" }} />
                                                            : ""
                                                }


                                            </TableCell>
                                            <TableCell style={{ width: "20%" }}>
                                                {(
                                                    (
                                                        row["ruleset"] == "CLT" ?
                                                            (

                                                                (row["daysToBePaid"] >= 0 && row["paidPeriodStatus"] == "UNDER_ACQUISITION") ?
                                                                    t("HR.underAcquisition") + " (" + row["daysToBePaid"] + " " + t("GENERAL.days").toLowerCase() + ")"
                                                                    : row["daysToBePaid"] > 0 && row["paidPeriodStatus"] == "PENDING" ?
                                                                        t("HR.pending") + " (" + row["daysToBePaid"] + " " + t("GENERAL.days").toLowerCase() + ")"
                                                                        : row["paidPeriodStatus"] == "PAID" ? t("HR.paid") + " (" + 30 + " " + t("GENERAL.days").toLowerCase() + ")"
                                                                            : ""


                                                            )

                                                            : ""
                                                    )
                                                    || "-"
                                                )}
                                                {
                                                    row["daysToBePaid"] >= 0 && row["paidPeriodStatus"] == "UNDER_ACQUISITION" ? <AccessTimeIcon style={{ float: "right", marginLeft: 5 }} />
                                                        : row["daysToBePaid"] > 0 && row["paidPeriodStatus"] == "PENDING" ? <ErrorIcon style={{ float: "right", marginLeft: 5, color: "#F3222F" }} />
                                                            : ""
                                                }
                                            </TableCell>
                                            <TableCell style={{ width: "20%" }}>
                                                {row["ruleset"]}
                                            </TableCell>
                                            <TableCell size="small" align="right" style={{ width: "10%" }}>
                                                <div style={{ marginTop: "auto", marginBottom: "auto", display: "flex", flexWrap: "nowrap ", justifyContent: "flex-end" }}>
                                                    <Action item={row} name={row.name} permission={props.permission} fetchData={props.fetchData} />
                                                </div>
                                            </TableCell>
                                        </StyledTableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </div>
                )
            })}
        </React.Fragment>

    )
}



export default VacationCalendarTable;
