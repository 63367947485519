import React from "react";
import "../../../../App.css";
import SideBar from "components/layout/sidebar/Sidebar";
import DashboardNavBar from "components/layout/DashboardNavBar";
import Collaborators from "./Collaborators";

const CallCollaborators = ({ match }) => {

    return (
        <div>
            <DashboardNavBar />
            <SideBar />
            <Collaborators />
        </div>
    );
}

export default CallCollaborators;