import axios from 'axios';
import { useMutation } from 'react-query';
import Cookies from 'js-cookie';
import { useQueryLSBDCreditSolicitations } from './useQueryLSBDCreditSolicitations';
import { useSnackbar } from 'hooks/UseSnackbar';
import { useTranslation } from 'react-i18next';
import { useQueryLSBDDashboard } from './useQueryLSBDDashboard';
import { valueToMoneyFloat } from 'utils/general/valueToMoneyFloat';
import { moneyMask } from 'utils/masks/moneyMask';
import { getQuotaManagersAndSendEmails } from 'services/Emails/Quota/quotaEmailService';

export const useMutationApproveCreditSolicitation = setOpen => {
    const toast = useSnackbar();
    const [t] = useTranslation();

    const queryLSBDCreditSolicitations = useQueryLSBDCreditSolicitations({
        enabled: false
    });

    const queryLSBDDashboard = useQueryLSBDDashboard({});

    const mutation = useMutation({
        mutationFn: async obj =>
            await axios.put(
                process.env.REACT_APP_SERVER +
                    `api/Quota/CreditSolicitations/${obj.get('id')}/Approve`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            ),
        onSuccess: res => {
            toast.success(t('QUOTAS.creditSolicitationApproved'));
            const data = res.data;

            const emailData = {
                collaboratorName: data.user.shortname,
                collaboratorEmail: data.user.username,
                value: `R$ ${moneyMask(valueToMoneyFloat(data.value))}`,
                solicitationDate: data.createdAt,
                operatorName: data.operator.shortname,
                newBalance: `R$ ${moneyMask(
                    valueToMoneyFloat(data.newAccountBalance)
                )}`,
                context: 'solicitation'
            };

            getQuotaManagersAndSendEmails(
                emailData,
                'api/Quota/CreditSolicitation/Approved',
                'QUOTAS_CREDIT_SOLICITATION_APPROVED_EMAIL_OPTIONAL'
            );
        },
        onError: () => {
            toast.error(t('QUOTAS.creditSolicitationApprovedError'));
        },
        onSettled: () => {
            setOpen(false);
            queryLSBDDashboard.refetch();
            queryLSBDCreditSolicitations.refetch();
        }
    });

    return mutation;
};
