import AllowancesContext from "context/allowance/allowanceContext";
import React, { useContext, useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { getAuthenticationData, isLogged } from "./Authentication";
import Cookies from "js-cookie";

export const ProtectedRouteHome = props => {

    const { component: Component, ...rest } = props;
    const allowanceContext = useContext(AllowancesContext);
    const {
        setAllowanceMessage,
    } = allowanceContext;

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {

            if (isLogged()) {
                if (localStorage.getItem('auth-token') !== null &&
                    Cookies.get('auth-token') === JSON.parse(localStorage.getItem('auth-token')).token){

                    setIsAuthenticated(true);
                }
                else {
                    var result = await getAuthenticationData();
                    setIsAuthenticated(result);
                }
            }
            else {
                setIsAuthenticated(false);
            }

            setLoading(false)
        }
        fetchData();
    }, [])

    return (
        <Route
            {...rest}
            render={props => loading ? (
                <div>loading...</div>
            ) : (
                <React.Fragment>
                {isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <React.Fragment>{window.location.href = process.env.REACT_APP_SITE}</React.Fragment>
                )}
                </React.Fragment>
            )}
        />
    )
};