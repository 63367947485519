import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { createMuiTheme, makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import CloseIcon from '@material-ui/icons/Close';
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import axios from "axios";
import InventoryContext from "context/inventory/inventoryContext";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Buttons from "components/buttons/Pressed";
import { GetGeneralPermissionForAction } from "utils/permissions/GetGeneralPermissionForAction";
import { Screen, Allow } from "global/constants";
import Cookies from "js-cookie";

const tooltipTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#2c3e51"
        }
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                color: "white",
                fontSize: "1em",
                backgroundColor: "red",
                opacity: "0.7"
            }
        }
    }
});

const useStyles = makeStyles(theme => ({
    icons: {
        opacity: "0"
    },
    root: {
        flexGrow: 1,
    },
    iconReq: {
        marginLeft: "10px",
        opacity: "0",
        backgroundColor: "#6D6F71",
        color: "#FFFFFF;"
    },
    textField: {
        display: "flex",
    },
    textFieldCustum: {
        display: "flex",
        marginLeft: "10px"
    },
}));

const MyItensTableActions = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const [openDelete, setOpenDelete] = useState(false);
    const [open, setOpen] = useState(false);
    const [openName, setOpenName] = useState(false);
    const [isModeView, setIsModeView] = useState(false);
    const [titleName, setTitle] = useState(t("GENERAL.requiredField"));
    const inventoryContext = useContext(InventoryContext);
    const {
        places,
        deletePlace,
        updatePlace,
    } = inventoryContext;

    const [values, setValues] = React.useState({
        id: null,
        name: "",
        description: "",
        localization: "",
        locationType: ""
    });

    const { item } = props;

    const handleOpenDelete = () => {
        setOpenDelete(true);
    };
    const handleCloseDelete = () => {
        setOpenDelete(false);
    };
    const handleDelete = () => {
        deletePlace(item.id);
        setOpenDelete(false);
    };

    const handleClose = () => {
        clearRequiredMessage();
        setOpen(false);
    };

    const clearRequiredMessage = () => {
        setOpenName(false);
    };

    const showRequiredFieldsMessage = () => {
        setOpenName(true);
    };

    const verifyEdit = () => {
        axios.get(process.env.REACT_APP_SERVER + `api/InventoryPlaces/${item.id}`, {
            headers: {
                "Content-type": "application/json",
                "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
            }
        })
            .then(res => {
                var data = res.data[0]
                setValues({
                    ...values,
                    id: data.id,
                    name: data.name ? data.name : "",
                    description: data.description ? data.description : "",
                    localization: data.localization ? data.localization : "",
                    locationType: data.locationType ? data.locationType : "",
                });
                if (res.statusText === "OK") {
                    setOpen(true)
                    setIsModeView(false)
                } else {
                    deletePlace(item.id);
                }
            })
            .catch(error => {
                deletePlace(item.id);
            });
    }

    const verifyView = () => {
        axios.get(process.env.REACT_APP_SERVER + `api/InventoryPlaces/${item.id}`, {
            headers: {
                "Content-type": "application/json",
                "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
            }
        })
            .then(res => {
                var data = res.data[0]
                setValues({
                    ...values,
                    id: data.id,
                    name: data.name ? data.name : "",
                    description: data.description ? data.description : "",
                    localization: data.localization ? data.localization : "",
                    locationType: data.locationType ? data.locationType : "",
                });
                if (res.statusText === "OK") {
                    setOpen(true)
                    setIsModeView(true)
                } else {
                    deletePlace(item.id);
                }
            })
            .catch(error => {
                deletePlace(item.id);
            });
    }

    let data = [];
    data = places;
    const handleChangeName = (name, value) => {
        if (name === "name") {
            clearRequiredMessage();
        }
        data = data.filter(item => item.name.trim().toLocaleLowerCase() === value.trim().toLocaleLowerCase());
        if (data.length !== 0 && value.trim().toLocaleLowerCase() !== item.name.trim().toLocaleLowerCase()) {
            setTitle(t("INVENTORY.nameAlreadyTaken"));
            setOpenName(true);
        } else {
            setTitle(t("GENERAL.requiredField"));
        }
        setValues({ ...values, [name]: value });
    };

    const handleChange = (name, value) => {
        setValues({ ...values, [name]: value });
    };

    const handleChangeLocation = name => event => {
        setValues({ ...values, [name]: event.target.value });
    };

    const handleSubmit = e => {
        if (!openName) {
            if (values.name.split("").filter(el => el !== " ").join("") !== "") {
                var obj = {
                    id: values.id,
                    name: values.name,
                    description: values.description,
                    localization: values.localization,
                    locationType: values.locationType,
                };
                clearRequiredMessage();
                updatePlace(obj);
                setOpen(false);
                e.preventDefault();
            } else {
                showRequiredFieldsMessage();
            }
        } else showRequiredFieldsMessage();
    };

    return (
        <React.Fragment>
            {GetGeneralPermissionForAction(Screen.PLACES, Allow.READ) &&
                <Tooltip title={t("GENERAL.view")} aria-label="details" placement="top">
                    <IconButton className={classes.icons} onClick={verifyView} >
                        <Visibility />
                    </IconButton>
                </Tooltip>
            }
            {GetGeneralPermissionForAction(Screen.PLACES, Allow.EDIT) &&

                <Tooltip title="Editar" aria-label="edit" placement="top">
                    <IconButton className={classes.icons} onClick={verifyEdit} >
                        <Edit />
                    </IconButton>
                </Tooltip>
            }
            {GetGeneralPermissionForAction(Screen.PLACES, Allow.DELETE) &&
                <Tooltip title="Deletar" aria-label="delete" placement="top">
                    <IconButton className={classes.icons} onClick={handleOpenDelete}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            }
            <Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="form-dialog-title"
                msg="mensagem"
            >
                <DialogTitle className={classes.Title}>
                    {t("GENERAL.delete")}{" "}{t("INVENTORY.place")}
                    <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleCloseDelete} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t("GENERAL.deleteMessageM")} {t("INVENTORY.place")} "{item.name}" ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Buttons
                        onClick={handleCloseDelete}
                        tipo="BN-noback"
                        conteudo={t("GENERAL.cancel").toUpperCase()}
                    />
                    <Buttons
                        onClick={handleDelete}
                        tipo="BN-blue"
                        conteudo={t("GENERAL.delete").toUpperCase()}
                    />
                </DialogActions>
            </Dialog>

            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={"sm"}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                msg="mensagem"
            >
                <DialogTitle className={classes.TitleDialog}>
                    {isModeView ? t("INVENTORY.ViewPlace") : t("INVENTORY.updatePlace")}
                    <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <MuiThemeProvider theme={tooltipTheme}>
                                    <Tooltip
                                        TransitionComponent={Zoom}
                                        open={openName}
                                        title={titleName}
                                    >
                                        <TextField
                                            disabled={isModeView}
                                            className={classes.textField}
                                            label={t("INVENTORY.namePlace") + "*"}
                                            value={values.name}
                                            onChange={(ev) => { if (ev.target.value.length <= 50) handleChangeName("name", ev.target.value) }}
                                            margin="normal"
                                            inputProps={{
                                                maxLength: 50
                                            }}
                                        />
                                    </Tooltip>
                                </MuiThemeProvider>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl component="fieldset" style={{ marginLeft: "50px", marginTop: "18px" }}>
                                    <FormLabel component="legend">{t("INVENTORY.placeType")}</FormLabel>
                                    <RadioGroup row aria-label="position"
                                        disabled={isModeView}
                                        name="position"
                                        defaultValue="top"
                                        value={values.locationType}
                                        onChange={handleChangeLocation("locationType")}
                                    >
                                        <FormControlLabel
                                            disabled={isModeView}
                                            value="INTERNAL"
                                            control={<Radio color="primary" />}
                                            label="Interno"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            disabled={isModeView}
                                            value="EXTERNAL"
                                            control={<Radio color="primary" />}
                                            label="Externo"
                                            labelPlacement="end"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                disabled={isModeView}
                                className={classes.textField}
                                label={t("INVENTORY.localization")}
                                value={values.localization}
                                margin="normal"
                                onChange={(ev) => { if (ev.target.value.length <= 100) handleChange("localization", ev.target.value) }}
                                inputProps={{
                                    maxLength: 100
                                }}
                            />
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    multiline
                                    disabled={isModeView}
                                    className={classes.textField}
                                    label={t("INVENTORY.observation")}
                                    value={values.description}
                                    margin="normal"
                                    onChange={(ev) => { if (ev.target.value.length <= 250) handleChange("description", ev.target.value) }}
                                    inputProps={{
                                        maxLength: 250
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                {!isModeView ?
                    <DialogActions>
                        <Buttons
                            onClick={handleClose}
                            tipo="BN-noback"
                            conteudo={t("INVENTORY.cancel").toUpperCase()}
                        />
                        <Buttons
                            onClick={handleSubmit}
                            tipo="BN-blue"
                            conteudo={t("GENERAL.update").toUpperCase()}
                        />
                    </DialogActions>
                    :
                    <DialogActions>
                        <Buttons
                            onClick={handleClose}
                            tipo="BN-noback"
                            conteudo={t("GENERAL.close").toUpperCase()}
                        />
                    </DialogActions>
                }
            </Dialog>
        </React.Fragment>
    )
};

export default MyItensTableActions;