import React from 'react';

import { typographyStyles } from './Styles';

const Typography = ({
    children,
    variant,
    textColor,
    fontSize,
    className,
    margin,
    ...props
}) => {
    const styles = typographyStyles();

    const getTextColor = textColor => {
        if (
            textColor &&
            (textColor.charAt(0) === '#' || textColor.substring(0, 3) === 'rgb')
        ) {
            return textColor;
        }
        switch (textColor) {
            case 'primary':
                return '#2C3E51';
            case 'secondary':
                return '#6D6F71';
            case 'white':
                return '#fff';
            case 'black':
                return '#000';
            case 'yellowSite':
                return '#FEC074';
            case 'linkBlue':
                return '#2D9CDB';
            case 'blackShadow':
                return 'rgba(0, 0, 0, 0.87)';
            case 'blackOpac':
                return 'rgba(0, 0, 0, 0.5)';
            case 'red':
                return '#F3222F';
            case 'green':
                return '#27AE60';
            default:
                return '#2C3E51';
        }
    };

    return (
        <p
            {...props}
            style={{
                color: getTextColor(textColor),
                fontSize: fontSize,
                margin: margin
            }}
            className={`${styles[variant]} ${className}`}
        >
            {children}
        </p>
    );
};

export default Typography;
