import React from "react";
import "../../../App.css";
import SideBar from "../../../components/layout/sidebar/Sidebar";
import DashboardNavBar from "../../../components/layout/DashboardNavBar";
import FormRequisitions from "./FormRequisition";

const CallEditCollaboratorsRequisitions = ({ match }) => {

    return (
        <div>
            <DashboardNavBar />
            <SideBar />
            <FormRequisitions id={match.params.id} context={"edit"} />
        </div>
    );
}

export default CallEditCollaboratorsRequisitions;