import React from "react";
import "../../App.css";
import SideBar from "../layout/sidebar/Sidebar";
import UsersTable from "../Users/UsersTable";
import Search from "components/layout/searchIndex";

function Dashboard() {
  return (
    <div>
      <Search
        busca={"Procurar por CPF, Nome ou Perfil"}
      />
      <SideBar />
      <UsersTable />
    </div>
  );
}

export default Dashboard;