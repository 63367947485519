import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Visibility from '@material-ui/icons/Visibility';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import DialogDebitCredit from 'pages/libraryModule/dialogs/Fines/DialogDebitCredit';
import { LibraryFinesTypes } from 'global/constants';

const CashTransactionsTableActions = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const { item } = props;
    const [openDebitCreditDialog, setOpenDebitCreditDialog] = useState(false);

    return (
        <React.Fragment>
            {item.type === LibraryFinesTypes.CREDIT ||
            item.type === LibraryFinesTypes.DEBIT ? (
                <Tooltip
                    title={t('GENERAL.view')}
                    aria-label="details"
                    placement="top"
                >
                    <IconButton
                        className={classes.iconsTableActions}
                        onClick={() => setOpenDebitCreditDialog(true)}
                    >
                        <Visibility />
                    </IconButton>
                </Tooltip>
            ) : null}

            {openDebitCreditDialog && (
                <DialogDebitCredit
                    open={openDebitCreditDialog}
                    setOpen={setOpenDebitCreditDialog}
                    context={'view'}
                    type={item.type}
                    viewValues={item}
                />
            )}
        </React.Fragment>
    );
};

export default CashTransactionsTableActions;
