import React from "react";

//  TO DO fix

const Footer = () => {
  return (
    <div className="footer">
      <img
        src={window.location.origin + "/img/logoUFC.png"}
        alt="logo"
        className="logoufc"
        width="95" 
      ></img>
      <span className="descricao">
        © Laboratório de Sistemas e Banco de Dados – All rights reserved
      </span>
      <span className="version">Versão {process.env.REACT_APP_VERSION}</span>
    </div>
  );
};

export default Footer;
