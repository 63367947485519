import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Notifications from "@material-ui/icons/Notifications";
import PropTypes from "prop-types";
import React, { Component } from "react";
import DashboardMenu from "./DashboardMenu";
import SiteButton from "components/buttons/SiteButton";
import TypographyText from "components/typography/Typography";


// let first = "";
// let last = "";
// let ImagePath = "";
// let name = JSON.parse(localStorage.getItem("auth-token"));
// if (name !== null) {
//   let fullname = name["name"];
//   ImagePath = name["imagePath"];
//   let result = fullname.split(" ");
//   last = result[result.length - 1];
//   first = result[0];

// }

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#ffffff",
    borderBottom: "0.5px solid #E5E5E5",
    height: "70px"
  },
  menuButton: {
    marginRight: theme.spacing(1)
  },
  gravatar: {},
  searchBar: {
    flexGrow: 1
  },
  text: {
    fontFamily: "Roboto",
    fontSize: "12px"
  },
  searchLeftIcon: {
    marginLeft: theme.spacing(12),
    marginRight: theme.spacing(2)
  },
  textField: {
    flexGrow: 1,
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1)
  },
  button: {
    display: "block",
    marginTop: theme.spacing(2)
  }
});

class DashboardNavBar extends Component {
  state = {
    label: "",
    description: "",
    anchorEl: null,
    open: true
  };

  renderOpenSearchBox = classes => {
    let name = JSON.parse(localStorage.getItem("auth-token"));
    const shortname = name && name["name"];
    const imagePath = name && name["imagePath"]


    return (

        <AppBar className={classes.root} style={{ position: "static", transform: "none" }} color="default" elevation={0}>
          <Toolbar>
            <Typography
              className={classes.root}
              variant="h6"
              color="inherit"
            />
            <a href={process.env.REACT_APP_SITE}>
            <SiteButton>
              <TypographyText  variant={'RobotoBold'} fontSize={15} textColor={'yellowSite'}>
                IR PARA SITE LSBD
              </TypographyText>              
            </SiteButton>
            </a>

            <IconButton
              edge="start"
              className={`classes.menuButton classes.gravatar`}
              color="inherit"
              aria-label="Gravatar"
            >
              <div
                id="avatarBar"
                style={{
                  backgroundImage: `url(${process.env.REACT_APP_IMGSERVER + imagePath})`
                }}
              ></div>
            </IconButton>
            <TypographyText  variant={'RobotoRegular'} fontSize={15} textColor={'secondary'}>
              {shortname}
            </TypographyText>
            <DashboardMenu className={classes.menuButton} />
          </Toolbar>
        </AppBar>
    );
  };

  render() {
    const { classes } = this.props;
    if (this.state.open) {
      return this.renderOpenSearchBox(classes);
    }
  }
}

DashboardNavBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DashboardNavBar);
