import React from "react";
import "../../../../App.css";
import SideBar from "components/layout/sidebar/Sidebar";
import DashboardNavBar from "components/layout/DashboardNavBar";
import SoftwaresList from "./SoftwaresListToAddLicense";

const CallNewLicenseOnly = ({ match }) => {
  return (
    <div>
      <DashboardNavBar />
      <SideBar />
      <SoftwaresList id={match.params.id}/>
    </div>
  );
}

export default CallNewLicenseOnly;