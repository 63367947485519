import axios from "axios";
import React, { useReducer } from "react";
import { useTranslation } from "react-i18next";
import { DEFAULT, EXTERNALUSERS, INVENTORY } from "../types";
import ExternalUsersContext from "./externalUsersContext";
import ExternalusersReducer from "./externalUsersReducer";
import history from "../../history";
import Cookies from "js-cookie";

const ExternalUsersState = props => {
  const initialState = {
    externalUser: {},
    externalUserHistory: [],
    externalUsers: [],
    placesExternalUsers: [],
    response: "",
    responseType: "",
    hasChanged: 0,
    successiveActions: 0,
    loading: false,
  };

  const [t] = useTranslation();
  const [state, dispatch] = useReducer(ExternalusersReducer, initialState);
  const setLoading = () => dispatch({ type: DEFAULT.SET_LOADING });
  const resetMessage = () => dispatch({ type: DEFAULT.RESET_MESSAGE });
  const setHasChanged = () => dispatch({ type: DEFAULT.SET_HAS_CHANGED });

  //Search
  function searchTermChanged(searchTerm) {
    dispatch({
      type: INVENTORY.SEARCH,
      filter: searchTerm
    });
  }

  const getExternalUsers = async () => {
    const res = await axios.get(
      process.env.REACT_APP_SERVER + `api/ExternalUsers`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
      }
    }
    );
    dispatch({
      type: EXTERNALUSERS.GET_EXTERNALUSERS,
      payload: res.data
    });
  }

  const getExternalUser = async id => {
    setLoading();
    const res = await axios.get(
      process.env.REACT_APP_SERVER + `api/ExternalUsers/${id}`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
      }
    }
    );
    dispatch({
      type: EXTERNALUSERS.GET_EXTERNALUSER,
      payload: res.data
    });
  };

  const addExternalUser = (obj, places) => {
    setLoading();
    axios
      .post(process.env.REACT_APP_SERVER + "api/ExternalUsers/", obj, {
        headers: {
          "Content-type": "application/json",
          "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
        }
      })
      .then(res => {
        if (res.status === 201) {
          if (places.length > 0) {
            const newArraySubItens = places.map(function (obj) {
              obj.userExternalId = res.data.id;
              obj.placeId = obj.value;
              return obj
            })
            addPlaceExternalUser(newArraySubItens)
          }
          dispatch({
            type: EXTERNALUSERS.ADD_EXTERNALUSER,
            payload: t("EXTERNALUSERS.successfullyCreated"),
            responseType: "SUCCESS",
            successiveActions: state.successiveActions + 1,
            hasChanged: state.successiveActions + 2
          });
        } else {
          dispatch({
            type: EXTERNALUSERS.ADD_EXTERNALUSER,
            payload: t("EXTERNALUSERS.unsuccessfullyCreated"),
            responseType: "ERROR",
            successiveActions: state.successiveActions + 1,
            hasChanged: state.successiveActions + 2
          });
        }
      })
      .catch(() => {
        dispatch({
          type: EXTERNALUSERS.ADD_EXTERNALUSER,
          payload: t("EXTERNALUSERS.nameAlreadyTaken"),
          responseType: "ERROR",
          successiveActions: state.successiveActions + 1,
          hasChanged: state.successiveActions + 2
        });
      });
  };


  const addPlaceExternalUser = obj => {
    setLoading();
    axios
      .post(process.env.REACT_APP_SERVER + "api/ExternalUserPlaces/", obj, {
        headers: {
          "Content-type": "application/json",
        "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
        }
      })
      .then(response => {
        if (response.status === 201) {
          console.log("sucess")

        } else {
          console.log("erro")
        }
      })
      .catch(() => {
        console.log("erro")
      });
  };

  const getPlacesExternalUser = async id => {
    setLoading();
    const res = await axios.get(
      process.env.REACT_APP_SERVER + `api/ExternalUserPlaces/${id}`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
      }
    }
    );
    dispatch({
      type: EXTERNALUSERS.GET_PLACESEXTERNALUSER,
      payload: res.data
    });
  }


  const updateExternalUser = (obj) => {
    setLoading();
    axios
      .put(process.env.REACT_APP_SERVER + "api/ExternalUsers/", obj, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
        }
      })
      .then(res => {
        history.push("/ExternalUsers");
        if (res.status === 201 || res.status === 204) {
          dispatch({
            type: INVENTORY.UPDATE_EXTERNALUSER,
            payload: t("INVENTORY.externalUserSuccessfullyEdited"),
            responseType: "SUCCESS",
            successiveActions: state.successiveActions + 1,
            hasChanged: state.successiveActions + 2
          });
        } else {
          dispatch({
            type: INVENTORY.UPDATE_EXTERNALUSER,
            payload: "An Error has Occurred",
            responseType: "ERROR",
            successiveActions: state.successiveActions + 1,
            hasChanged: state.successiveActions + 2
          });
        }
      })
      .catch(error => {
        history.push("/ExternalUsers");
        dispatch({
          type: INVENTORY.UPDATE_EXTERNALUSER,
          payload: error.message,
          responseType: "ERROR",
          successiveActions: state.successiveActions,
          hasChanged: state.successiveActions + 2
        });
      });
  };

  const findEquipments = async id => {
    var candelete = true;
    const res = await axios.get(process.env.REACT_APP_SERVER + `api/InventoryItem/externalUser/${id}`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
      }
    });
    let objectResponse = res.data;
    if (objectResponse.length > 0) {
      candelete = false;
    }
    return candelete;
  };


  const deleteExternalUser = (id, obj) => {
    setLoading();
    let promise = findEquipments(id);
    promise.then(result => {
      if (result) {
        axios
          //.put(process.env.REACT_APP_SERVER + `api/ExternalUsers/`, obj)
          .delete(process.env.REACT_APP_SERVER + `api/ExternalUsers/${id}`, {
            headers: {
              "Content-type": "application/json",
              "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
            }
          })
          .then(() => {
            dispatch({
              type: EXTERNALUSERS.DELETE_EXTERNALUSER,
              payload: t("EXTERNALUSERS.ExternalUser") + " " + t("GENERAL.deleted"),
              successiveActions: state.successiveActions + 1,
              hasChanged: state.successiveActions + 2,
              responseType: "SUCCESS"
            });
          })
          .catch(error => {
            axios
              .put(process.env.REACT_APP_SERVER + "api/ExternalUsers/", obj, {
                headers: {
                  "Content-type": "application/json",
                  "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
                }
              })
              .then(() => {
                dispatch({
                  type: EXTERNALUSERS.DELETE_EXTERNALUSER,
                  payload: t("EXTERNALUSERS.ExternalUser") + " " + t("GENERAL.deleted"),
                  successiveActions: state.successiveActions + 1,
                  hasChanged: state.successiveActions + 2,
                  responseType: "SUCCESS"
                });
              })
              .catch(error => {
                dispatch({
                  type: EXTERNALUSERS.DELETE_EXTERNALUSER,
                  payload: t("EXTERNALUSERS.notFound"),
                  successiveActions: state.successiveActions + 1,
                  hasChanged: state.successiveActions + 2,
                  responseType: "ERROR"
                });
              });
          });
      } else {
        dispatch({
          type: EXTERNALUSERS.DELETE_EXTERNALUSER,
          payload: t("EXTERNALUSERS.associatedItem"),
          successiveActions: state.successiveActions + 1,
          hasChanged: state.successiveActions + 2,
          responseType: "ERROR"
        });
      }
    });
  };

  return (
    <ExternalUsersContext.Provider
      value={{
        myItens: state.myItens,
        loading: state.loading,
        message: state.message,
        successiveActions: state.successiveActions,
        filter: state.filter,
        hasChanged: state.hasChanged,
        response: state.response,
        responseType: state.responseType,
        externalUser: state.externalUser,
        externalUsers: state.externalUsers,
        placesExternalUsers: state.placesExternalUsers,
        setHasChanged,
        searchTermChanged,
        resetMessage,
        getExternalUser,
        getExternalUsers,
        addExternalUser,
        addPlaceExternalUser,
        getPlacesExternalUser,
        updateExternalUser,
        deleteExternalUser
      }}
    >
      {props.children}
    </ExternalUsersContext.Provider>
  );
};

export default ExternalUsersState;
