import React from "react";
import "../../../../App.css";
import SideBar from "components/layout/sidebar/Sidebar";
import DashboardNavBar from "components/layout/DashboardNavBar";
import CollaboratorsTabPanel from "./CollaboratorsTabPanel";



const CallViewMyInformations = ({ match }) => {
    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }

    return (
        <div>
            <DashboardNavBar />
            <SideBar />
            <CollaboratorsTabPanel userId={auth.id} context={"view"} myInformations={true} />

        </div>
    );
}

export default CallViewMyInformations;