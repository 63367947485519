import React from "react";
import "App.css";
import SideBar from "components/layout/sidebar/Sidebar";
import DashboardNavBar from "components/layout/DashboardNavBar";
import EditSoftware from './EditSoftware'

const CallEditSoftware = ({ match }) => {
  return (
    <div>
      <DashboardNavBar />
      <SideBar />
      <EditSoftware softwareId={match.params.id} context={"edit"}/>
    </div>
  );
}
export default CallEditSoftware;