import { makeStyles } from '@material-ui/core/styles';

export const screenStyles = makeStyles(theme => ({
    space: {
        marginBottom: '25px'
    },
    finesInfoContainer: {
        boxSizing: 'border-box',
        display: 'flex',
        padding: '1.2rem 2rem',
        backgroundColor: '#2C3E51',
        color: '#fff',
        justifyContent: 'space-between'
    },
    creditDebitWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    creditContent: {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #ffffff',
        padding: '8px 40px 8px 16px',
        cursor: 'pointer'
    },
    debitContent: {
        display: 'flex',
        alignItems: 'center',
        background: '#ffffff',
        padding: '8px 40px 8px 16px',
        cursor: 'pointer'
    },
    finesInfoWrapper: {
        display: 'flex',
        textAlign: 'center'
    },
    finesInfoContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '72px',
        borderRight: '1.5px solid ',
        padding: '0.5rem 1.25rem'
    },
    finesInfoContentLast: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '72px',
        padding: '0.5rem 1.25rem'
    },
    plusMinusImage: {
        width: '21px',
        height: '21px',
        marginRight: '40px'
    }
}));
