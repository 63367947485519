import React from "react";
import { useStyles } from "components/layout/CommonStyles";

import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

const Breadcrumb = ({ routes, newDesign }) => {
    const classes = useStyles();

    return (
        <Breadcrumbs
            className={newDesign ? classes.breadCrumbsNewDesign : classes.breadCrumbs}
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
        >
            {routes.map((route, index) =>
                route.to ?
                    <Link className={classes.link} to={route.to} key={route.label + index}>
                        <Typography>{route.label}</Typography>
                    </Link>
                    :
                    <Typography key={route.label + index}>{route.label}</Typography>
            )}
        </Breadcrumbs>
    )
}

export default Breadcrumb;