import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Breadcrumb from 'components/layout/breadcrumb/Breadcrumb';
import Transition from 'components/layout/transition/Transition';
import FilterContainer from 'components/containers/FilterContainer';
import HeaderContainer from 'components/containers/HeaderContainer';
import ContentContainer from 'components/containers/ContentContainer';
import InputFilter from 'components/inputs/InputFilter';
import InputFilterDate from 'components/inputs/InputFilterDate';
import JobsTable from './JobsTable';
import ManagementContext from 'context/quotaModule/management/managementContext';
import Paper from '@material-ui/core/Paper';
import Dissatisfied from '@material-ui/icons/SentimentVeryDissatisfied';
import Footer from 'components/layout/Footer';
import { useStyles } from 'components/layout/CommonStyles';
import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';
import { isSameDate } from 'utils/dates/IsSameDate';
import { Screen, Allow } from 'global/constants';
import { FormatDateToFront } from 'utils/dates/FormatDateToFront';
import { dateToBR } from 'utils/dates/DateToBRr';
import { valueToMoneyFloat } from 'utils/general/valueToMoneyFloat';
import { useQueryJobs } from 'hooks/DataFetching/Quotas/Jobs/useQueryJobs';
import { screenStyles } from './Styles';
import { moneyMask } from 'utils/masks/moneyMask';

const Jobs = () => {
    const classes = useStyles();
    const styles = screenStyles();
    const [t] = useTranslation();
    const [loading, setLoading] = useState(true);

    const [findText, setFindText] = useState('');
    const [filterJob, setFilterJob] = useState('');
    const [filterValue, setFilterValue] = useState('');
    const [dataFilter, setDataFilter] = useState([]);

    const [canClick, setCanClick] = useState(false);

    const { isLoading, isFetching, error, data } = useQueryJobs();

    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const hasItens = data && data.length > 0;
    const [hasNoResult, setHasNoResult] = useState(
        hasItens && dataFilter && dataFilter.length === 0
    );

    useEffect(() => {
        if (data) {
            setDataFilter(data.filter(x => !x.hasDeleted));
            setHasNoResult(false);
        }
    }, [data]);

    useEffect(() => {
        if (data && data.length > 0) {
            const { newDataFilter, textNoFound } = applyFilter(
                data.filter(x => !x.hasDeleted)
            );

            if (newDataFilter.length === 0) {
                setDataFilter([]);
                setHasNoResult(true);
                setFindText(textNoFound);
            } else {
                setDataFilter(newDataFilter);
                setHasNoResult(false);
                setFindText('');
            }

            setDataFilter(newDataFilter);
        }
    }, [filterJob, filterValue]);

    const applyFilter = data => {
        let newDataFilter = [];
        let textNoFound = '';

        newDataFilter = data;

        if (filterJob) {
            newDataFilter = newDataFilter.filter(
                item =>
                    item.name &&
                    item.name.toLowerCase().includes(filterJob.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterJob;
        }

        if (filterValue) {
            newDataFilter = newDataFilter.filter(
                item =>
                    item.price &&
                    `R$ ${moneyMask(valueToMoneyFloat(item.price))}`
                        .toLowerCase()
                        .includes(filterValue.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterValue;
        }

        return { newDataFilter, textNoFound };
    };

    useEffect(() => {
        if (filterJob || filterValue) {
            setCanClick(true);
        } else {
            setCanClick(false);
        }
    }, [filterJob, filterValue]);

    return (
        <React.Fragment>
            <Transition loading={isLoading && isFetching} newDesign={true}>
                <HeaderContainer>
                    <Breadcrumb
                        newDesign={true}
                        routes={[
                            {
                                label: t('QUOTAS.quotas'),
                                to: '/Quotas'
                            },
                            { label: t('HR.jobs') }
                        ]}
                    />
                    <div className="container-headerNewDesign">
                        <h1 className={classes.titleNewDesign}>
                            {t('HR.jobs')}
                        </h1>
                    </div>
                </HeaderContainer>
                <ContentContainer>
                    <Paper>
                        <div className="cabecalho">
                            {t('QUOTAS.listOfJobs').toUpperCase()}
                        </div>
                        <FilterContainer>
                            <InputFilter
                                filterValue={filterJob}
                                setFilter={setFilterJob}
                                label={'GENERAL.jobName'}
                            />

                            <InputFilter
                                filterValue={filterValue}
                                setFilter={setFilterValue}
                                label={'QUOTAS.quoteValue'}
                            />
                        </FilterContainer>
                        <React.Fragment>
                            <JobsTable
                                rows={dataFilter}
                                toFirstPage={canClick}
                                headers={['id', 'name', 'price']}
                                visibleHeaders={[
                                    'Nome do Cargo',
                                    'Valor da Cota',
                                    ''
                                ]}
                                sortedFields={['name', 'price']}
                                mainField="name"
                            />

                            {!hasItens && !error && (
                                <div className={styles.noJustificationsWrapper}>
                                    <h2>{t('HR.noJobsMessage')}</h2>
                                    <h4>{t('HR.noJobsSubMessage')}</h4>
                                </div>
                            )}
                            {hasNoResult && !error && (
                                <div className={classes.noParticipantsMessage}>
                                    <div>
                                        <Dissatisfied
                                            className={
                                                classes.notFoundIconStyle
                                            }
                                        />
                                    </div>
                                    <h2>{t('GENERAL.noResultsFound')}</h2>
                                    <h5>{`Sua busca por "${findText}" não retornou resultados.`}</h5>
                                </div>
                            )}
                            {error && (
                                <div className={classes.noParticipantsMessage}>
                                    <div>
                                        <Dissatisfied
                                            className={
                                                classes.notFoundIconStyle
                                            }
                                        />
                                    </div>
                                    <h2>
                                        {
                                            'Houve um erro de conexão com o Servidor'
                                        }
                                    </h2>
                                </div>
                            )}
                        </React.Fragment>
                    </Paper>
                </ContentContainer>
                <Footer />
            </Transition>
        </React.Fragment>
    );
};

export default Jobs;
