import React from 'react';

import { containersStyles } from './Styles';

const GreyContainer = ({ children }) => {
    const styles = containersStyles();
    return <div className={styles.greyContainer}>{children}</div>;
};

export default GreyContainer;
