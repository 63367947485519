/* eslint-disable */
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Footer from "components/layout/Footer";
import Spinner from "components/layout/spinners/Index";
import PlacesTable from "./PlacesTable";
import AllowancesContext from "context/allowance/allowanceContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { animated, useTransition } from "react-spring";
import MySnackbarContentWrapper from "components/layout/Message";
import { useStyles } from "components/layout/CommonStyles";
import Dissatisfied from "@material-ui/icons/SentimentVeryDissatisfied";
import Paper from "@material-ui/core/Paper";
import InventoryContext from "context/inventory/inventoryContext"
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import Buttons from "components/buttons/Pressed";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import { GetGeneralPermissionForAction } from "utils/permissions/GetGeneralPermissionForAction";
import { Screen, Allow } from "global/constants";

const tooltipTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#2c3e51"
        }
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                color: "white",
                fontSize: "1em",
                backgroundColor: "red",
                opacity: "0.7"
            }
        }
    }
});

const Places = () => {
    const classes = useStyles();
    const [t] = useTranslation();
    const inventoryContext = useContext(InventoryContext);
    const allowanceContext = useContext(AllowancesContext);
    const [open, setOpen] = useState(false);
    const [placeType, setPlaceType] = React.useState("INTERNAL");
    const [mustRedirect, setMustRedirect] = useState(false);
    const [openName, setOpenName] = useState(false);
    const [titleName, setTitle] = useState(t("GENERAL.requiredField"));

    const clearRequiredMessage = () => {
        setOpenName(false);
    };

    const showRequiredFieldsMessage = () => {
        setOpenName(true);
    };

    const placesArray = [
        { value: "INTERNAL", label: "Internos" },
        { value: "EXTERNAL", label: "Externos" },
        { value: "ALL", label: "Todos os Locais" }
    ]

    const [values, setValues] = React.useState({
        id: null,
        name: "",
        description: "",
        localization: "",
        locationType: ""
    });

    let dataFilter = [];
    const [snackBar, setSnackBar] = useState({
        open: false,
        kind: "",
        content: ""
    });
    const [filter, setFilter] = useState("");
    const [filter2, setFilter2] = useState("");
    const [filterTypeLocation, setFilterTypeLocation] = useState(false);

    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }

    const {
        loading,
        response,
        hasChanged,
        setHasChanged,
        responseType,
        successiveActions,
        getPlaces,
        places,
        addPlace
    } = inventoryContext;

    const {
        allowanceResponse,
        allowanceResponseType,
        messageHasChanged,
        resetAllowanceMessage,
        verifyPermission,
        permission
    } = allowanceContext;

    dataFilter = places;

    dataFilter = placeType !== "ALL" ? dataFilter.filter(
        item =>
            item.locationType !== null && item.locationType.toLowerCase() === (placeType.toLowerCase())
    )
        : places;

    if (filter) {
        dataFilter = dataFilter.filter(
            item =>
                item.name.toLowerCase().startsWith(filter.toLowerCase())
        );
    }

    if (filter2) {
        dataFilter = dataFilter.filter(
            item =>
                item.localization && item.localization.toLowerCase().startsWith(filter2.toLowerCase())
        );
    }

    const clearFilterLocationType = () => {
        dataFilter = places;
        dataFilter = placeType !== "ALL" ? dataFilter.filter(
            item =>
                item.locationType !== null && item.locationType.toLowerCase() === (placeType.toLowerCase())
        )
            : places;
    }

    const hasItens = places.length > 0;
    const [hasNoResult, setHasNoResult] = useState(hasItens && dataFilter.length === 0);

    let data = [];
    data = places;
    const handleChangeName = name => event => {
        if (event.target.value.length <= 50) {
            if (name === "name") {
                clearRequiredMessage();
            }
            data = data.filter(item => item.name.trim().toLocaleLowerCase() === event.target.value.trim().toLocaleLowerCase());
            if (data.length !== 0) {
                setTitle(t("INVENTORY.nameAlreadyTaken"));
                setOpenName(true);
            } else {
                setTitle(t("GENERAL.requiredField"));
            }
            setValues({ ...values, [name]: event.target.value });
        }
    };

    const handleChange = name => event => {
        var maxLength = 0;
        if (name === "localization") {
            maxLength = 100
        } else {
            maxLength = 250
        }

        if (event.target.value.length <= maxLength) {
            setValues({ ...values, [name]: event.target.value });
        }
    };

    const transitions = useTransition(loading, null, {
        from: { opacity: 0 },
        enter: {
            opacity: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "calc(100vh - 70px)"
        },
        leave: { opacity: 0 }
    });

    const renderTable = dataFilter => {
        return (
            <PlacesTable
                rows={dataFilter}
                headers={["id", "name", "localization", "description", "locationType"]}
                visibleHeaders={["nome do local", "Localização", "observação", ""]}
                sortedFields={["name", "localization", "description"]}
                mainField="name"
                permission={permission}
            />
        );
    };

    const [placesTable, setPlacesTable] = useState(
        renderTable(dataFilter)
    );

    useEffect(() => {
        getPlaces()
        if (allowanceResponseType === "ERROR" && messageHasChanged) {
            handleSnack({ kind: "error", content: allowanceResponse });
        }
        if (responseType === "SUCCESS" && hasChanged > successiveActions) {
            handleSnack({ kind: "success", content: response });
        }
        if (responseType === "ERROR" && hasChanged > successiveActions) {
            handleSnack({ kind: "error", content: response });
        }
        return () => { };
    }, [
        responseType,
        response,
        successiveActions,
        allowanceResponseType,
        allowanceResponse,
        places.length,
    ]);

    const handleSnack = () => {
        setSnackBar({ ...snackBar, open: true });
        setHasChanged();
    };
    const handleSnackClose = () => {
        setSnackBar({ ...snackBar, open: false });
        resetAllowanceMessage();
    };

    const handleClickOpen = () => {
        setValues({
            ...values,
            name: "",
            description: "",
            localization: "",
            locationType: "Interno"
        });
        setOpen(true);
    };

    const handleClose = () => {
        clearRequiredMessage();
        setOpen(false);
    };

    const handleSearch = event => {
        setFilter(event.target.value);
        clearFilterLocationType();
        dataFilter = dataFilter.filter(
            item =>
                item.name.toLowerCase().startsWith(event.target.value.toLowerCase())
        );
        if (dataFilter.length === 0) {
            setHasNoResult(true);
        } else {
            setHasNoResult(false);
        }
        setPlacesTable(renderTable(dataFilter));
    };

    const handleSearch2 = event => {
        setFilter2(event.target.value);
        clearFilterLocationType();
        dataFilter = dataFilter.filter(
            item =>
                item.localization && item.localization.toLowerCase().startsWith(event.target.value.toLowerCase())
        );
        if (dataFilter.length === 0) {
            setHasNoResult(true);
        } else {
            setHasNoResult(false);
        }
        setPlacesTable(renderTable(dataFilter));
    };

    const handleChangePlaceType = (event) => {
        setPlaceType(event.target.value);
        dataFilter = places;
        dataFilter = event.target.value !== "ALL" ? dataFilter.filter(
            item =>
                item.locationType !== null && item.locationType.toLowerCase() === event.target.value.toLowerCase()
        )
            : places;
        if (filter) {
            dataFilter = dataFilter.filter(
                item =>
                    item.name.toLowerCase().startsWith(filter.toLowerCase())
            );
        }
        if (filter2) {
            dataFilter = dataFilter.filter(
                item =>
                    item.localization && item.localization.toLowerCase().startsWith(filter2.toLowerCase())
            );
        }

        if (dataFilter.length === 0) {
            setHasNoResult(true);
        } else {
            setHasNoResult(false)
        }
        setPlacesTable(renderTable(dataFilter));

    };

    const handleSubmit = e => {
        if (!openName) {
            if (values.name.split("").filter(el => el !== " ").join("") !== "") {
                var obj = {
                    name: values.name,
                    description: values.description,
                    localization: values.localization,
                    locationType: values.locationType === "Interno" ? "INTERNAL" : "EXTERNAL",
                };
                clearRequiredMessage();
                addPlace(obj);
                setOpen(false);
                setMustRedirect(true);
                e.preventDefault();
            } else {
                showRequiredFieldsMessage();
            }
        } else showRequiredFieldsMessage();
    };

    useEffect(() => {
        if (filter) {
            dataFilter = dataFilter.filter(
                item =>
                    item.name.toLowerCase().includes(filter.toLowerCase())
            );
        }

        if (filter2) {
            dataFilter = dataFilter.filter(
                item =>
                    item.localization && item.localization.toLowerCase().startsWith(filter2.toLowerCase())
            );
        }

        if (placeType.length > 0)
            setFilterTypeLocation(placeType === "EXTERNAL" ? "Externos" : placeType === "INTERNAL" ? "Internos" : "Todos os locais");

        if (dataFilter.length === 0)
            setHasNoResult(true);
        else
            setHasNoResult(false);

        setPlacesTable(renderTable(dataFilter));

    }, [values, dataFilter.length, Object.keys(places).length, JSON.stringify(places)]);

    return transitions.map(({ item, key, props }) =>
        item ? (
            <animated.div key={key} style={props}>
                <Spinner />;
            </animated.div>
        ) : (
            <animated.div key={key} style={props}>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={snackBar.open}
                    onClose={handleSnackClose}
                    TransitionComponent={Slide}
                    transitionDuration={{ enter: 500, exit: 500 }}
                    ContentProps={{
                        "aria-describedby": "message-id"
                    }}
                    autoHideDuration={2000}
                >
                    <MySnackbarContentWrapper
                        onClose={handleSnackClose}
                        variant={
                            responseType !== ""
                                ? responseType.toLowerCase()
                                : allowanceResponseType.toLowerCase()
                        }
                        message={
                            <span id="message-id">
                                {response !== "" ? response : allowanceResponse}
                            </span>
                        }
                    />
                </Snackbar>
                <div className="container">
                    <Breadcrumbs
                        className={classes.breadCrumbs}
                        aria-label="breadcrumb"
                        separator={<NavigateNextIcon fontSize="small" />}
                    >
                        <Typography>{t("INVENTORY.inventory")}</Typography>
                        <Typography>{t("INVENTORY.places")}</Typography>
                    </Breadcrumbs>

                    <div className="container-header">
                        <div className="title">
                            <h1 className={classes.title}>{t("INVENTORY.places")}</h1>
                        </div>
                        {GetGeneralPermissionForAction(Screen.PLACES, Allow.CREATE) &&
                            <Button
                                className={classes.blueButton}
                                variant="contained"
                                color="primary"
                                onClick={handleClickOpen}
                            >
                                {t("INVENTORY.addPlace")}
                            </Button>
                        }
                    </div>
                    <Paper >
                        <div className="cabecalho">
                            {t("INVENTORY.registeredPlaces").toUpperCase()}
                        </div>
                        <div className={classes.divAddParticipantLeft}>
                            <Toolbar className={classes.searchToolbarInventory}>
                                <div style={{ display: !hasItens ? "none" : "" }} className={classes.searchInventory}>
                                    <div className={classes.searchIcon}>
                                        <SearchIcon />
                                    </div>
                                    <InputBase
                                        placeholder={t("INVENTORY.name")}
                                        value={filter}
                                        onChange={handleSearch}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputInput
                                        }}
                                        inputProps={{ "aria-label": "search" }}
                                    />
                                </div>
                            </Toolbar>
                            <Toolbar className={classes.searchToolbarInventory} >
                                <div style={{ display: !hasItens ? "none" : "" }} className={classes.searchInventory}>
                                    <div className={classes.searchIcon}>
                                        <SearchIcon />
                                    </div>
                                    <InputBase
                                        placeholder={t("INVENTORY.localization")}
                                        value={filter2}
                                        onChange={handleSearch2}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputInput
                                        }}
                                        inputProps={{ "aria-label": "search" }}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                            </Toolbar>
                            <Toolbar className={classes.searchToolbarInventory} >
                                <div style={{ display: !hasItens ? "none" : "" }} className={classes.searchInventory} >
                                    {/* <FormControl variant="filled" className={classes.formControl}> */}
                                    <TextField
                                        select
                                        id="placeType"
                                        label={t("INVENTORY.placeType")}
                                        name="Place"
                                        style={{ minWidth: "250px", marginTop: 0, marginBottom: 0, marginLeft: 5 }}
                                        value={placeType}
                                        className={classes.textField}
                                        onChange={handleChangePlaceType}
                                        margin="normal"
                                        inputProps={{ "aria-label": "search" }}
                                    >
                                        {placesArray.map(places => (
                                            <MenuItem key={places.value} value={places.value}>
                                                {places.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            </Toolbar>

                        </div>
                        {hasItens ? placesTable :
                            <div className={classes.noParticipantsMessage}>
                                <h2>{t("INVENTORY.noItensMessage")}</h2>
                                <h4>{t("INVENTORY.noItensSubMessage")}</h4>
                            </div>
                        }
                        {hasNoResult && filter ?
                            <div className={classes.noParticipantsMessage}>
                                <div >
                                    <Dissatisfied className={classes.notFoundIconStyle} />
                                </div>
                                <h2>{t("GENERAL.noResultsFound")}</h2>
                                <h5>{`Sua busca por "${filter}" não retornou resultados.`}</h5>
                            </div>
                            : hasNoResult && filter2 ?
                                <div className={classes.noParticipantsMessage}>
                                    <div >
                                        <Dissatisfied className={classes.notFoundIconStyle} />
                                    </div>
                                    <h2>{t("GENERAL.noResultsFound")}</h2>
                                    <h5>{`Sua busca por "${filter2}" não retornou resultados.`}</h5>
                                </div>
                                : hasNoResult && filterTypeLocation &&
                                <div className={classes.noParticipantsMessage}>
                                    <div >
                                        <Dissatisfied className={classes.notFoundIconStyle} />
                                    </div>
                                    <h2>{t("GENERAL.noResultsFound")}</h2>
                                    <h5>{`Sua busca por "${filterTypeLocation}" não retornou resultados.`}</h5>
                                </div>
                        }
                    </Paper>
                </div>
                <Footer />
                <Dialog
                    open={open}
                    fullWidth={true}
                    maxWidth={"sm"}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title"
                    msg="mensagem"
                >
                    <DialogTitle className={classes.TitleDialog}>
                        {t("INVENTORY.addPlace")}
                        <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Grid container>
                                <Grid item xs={12} sm={6}>
                                    <MuiThemeProvider theme={tooltipTheme}>
                                        <Tooltip
                                            TransitionComponent={Zoom}
                                            open={openName}
                                            title={titleName}
                                        >
                                            <TextField
                                                className={classes.textField}
                                                label={t("INVENTORY.namePlace") + "*"}
                                                value={values.name}
                                                onChange={handleChangeName("name")}
                                                margin="normal"
                                                inputProps={{
                                                    maxLength: 50
                                                }}
                                            />
                                        </Tooltip>
                                    </MuiThemeProvider>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl component="fieldset" style={{ marginLeft: "50px", marginTop: "18px" }}>
                                        <FormLabel component="legend">{t("INVENTORY.placeType")}</FormLabel>
                                        <RadioGroup row aria-label="position"
                                            name="position"
                                            defaultValue="top"
                                            value={values.locationType}
                                            onChange={handleChange("locationType")}
                                        >
                                            <FormControlLabel
                                                value="Interno"
                                                control={<Radio color="primary" />}
                                                label="Interno"
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                value="Externo"
                                                control={<Radio color="primary" />}
                                                label="Externo"
                                                labelPlacement="end"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    className={classes.textField}
                                    label={t("INVENTORY.localization")}
                                    value={values.localization}
                                    margin="normal"
                                    onChange={handleChange("localization")}
                                    inputProps={{
                                        maxLength: 100
                                    }}
                                />
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        multiline
                                        className={classes.textField}
                                        label={t("INVENTORY.observation")}
                                        value={values.description}
                                        margin="normal"
                                        onChange={handleChange("description")}
                                        inputProps={{
                                            maxLength: 250
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Buttons
                            onClick={handleClose}
                            tipo="BN-noback"
                            conteudo={t("INVENTORY.cancel").toUpperCase()}
                        />
                        <Buttons
                            onClick={handleSubmit}
                            tipo="BN-blue"
                            conteudo={t("INVENTORY.add").toUpperCase()}
                        />
                    </DialogActions>
                </Dialog>
            </animated.div>
        )
    );
};

export default Places;