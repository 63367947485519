import FilterContainer from 'components/containers/FilterContainer';
import InputFilter from 'components/inputs/InputFilter';
import InputFilterDate from 'components/inputs/InputFilterDate';
import Paper from '@material-ui/core/Paper';
import Dissatisfied from '@material-ui/icons/SentimentVeryDissatisfied';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import {
    LibraryArray,
    StatusArray
} from 'pages/libraryModule/Fines/ScreenUtils';
import FinesTable from './FinesTable';
import { dateToBR } from 'utils/dates/DateToBRr';
import { FormatDateToFront } from 'utils/dates/FormatDateToFront';
import { translateFineStatus } from 'utils/library/translateFinesStatus';
import { translateLibrary } from 'utils/library/translateLibrary';

const FinesListForm = props => {
    const { fines, finesTransactionsData } = props;
    const classes = useStyles();
    const [t] = useTranslation();

    const [findText, setFindText] = useState('');
    const [filterCollaborator, setFilterCollaborator] = useState('');
    const [filterTitle, setFilterTitle] = useState('');
    const [filterStatus, setFilterStatus] = useState('ALL');
    const [filterLibrary, setFilterLibrary] = useState('ALL');
    const [filterDate, setFilterDate] = useState(null);

    const [canClick, setCanClick] = useState(false);
    const [dataFilter, setDataFilter] = useState([]);
    const hasItens = fines.length > 0;
    const [hasNoResult, setHasNoResult] = useState(
        hasItens && dataFilter.length === 0
    );

    useEffect(() => {
        if (fines && finesTransactionsData) {
            setDataFilter(fines);
            setHasNoResult(false);
        }
    }, [fines, finesTransactionsData]);

    useEffect(() => {
        let newDataFilter = fines;
        let textNoFound = '';

        if (filterCollaborator) {
            newDataFilter = newDataFilter.filter(item =>
                item.reservation.user.shortname
                    .toLowerCase()
                    .includes(filterCollaborator.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterCollaborator;
        }

        if (filterTitle) {
            newDataFilter = newDataFilter.filter(item =>
                item.reservation.book.title
                    .toLowerCase()
                    .includes(filterTitle.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterTitle;
        }

        if (filterDate && !isNaN(filterDate)) {
            let filterDateBR = dateToBR(filterDate);
            newDataFilter = newDataFilter.filter(
                item =>
                    item.reservation.returnDate &&
                    dateToBR(FormatDateToFront(item.reservation.returnDate)) ===
                        filterDateBR
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterDateBR;
        }

        if (filterStatus && filterStatus !== 'ALL') {
            newDataFilter = newDataFilter.filter(
                item => item.status === filterStatus
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = translateFineStatus(filterStatus);
        }

        if (filterLibrary && filterLibrary !== 'ALL') {
            newDataFilter = newDataFilter.filter(
                item => item.reservation.library === filterLibrary
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = translateLibrary(filterLibrary);
        }

        if (newDataFilter.length === 0 && fines.length > 0) {
            setHasNoResult(true);
            setFindText(textNoFound);
        } else {
            setHasNoResult(false);
            setFindText('');
        }

        setDataFilter(newDataFilter);
    }, [
        filterCollaborator,
        filterTitle,
        filterStatus,
        filterLibrary,
        filterDate
    ]);

    useEffect(() => {
        if (
            filterCollaborator ||
            filterTitle ||
            filterStatus ||
            filterLibrary ||
            filterDate
        ) {
            setCanClick(true);
        } else {
            setCanClick(false);
        }
    }, [
        filterCollaborator,
        filterTitle,
        filterStatus,
        filterLibrary,
        filterDate
    ]);

    return (
        <React.Fragment>
            <Paper>
                <FilterContainer>
                    <InputFilter
                        filterValue={filterCollaborator}
                        setFilter={setFilterCollaborator}
                        label={'HR.collaborator'}
                        maxLength={70}
                    />
                    <InputFilter
                        filterValue={filterTitle}
                        setFilter={setFilterTitle}
                        label={'INVENTORY.book'}
                        maxLength={70}
                    />
                    <InputFilterDate
                        filterValue={filterDate}
                        setFilter={setFilterDate}
                        label={'GENERAL.date'}
                    />
                    <InputFilter
                        filterValue={filterLibrary}
                        setFilter={setFilterLibrary}
                        label={'LIBRARY.collection'}
                        isSelect
                        arraySelected={LibraryArray}
                    />
                    <InputFilter
                        filterValue={filterStatus}
                        setFilter={setFilterStatus}
                        label={'GENERAL.status'}
                        isSelect
                        arraySelected={StatusArray}
                    />
                </FilterContainer>
                <React.Fragment>
                    <FinesTable
                        rows={dataFilter}
                        toFirstPage={canClick}
                        headers={['id', 'status', 'reservation']}
                        visibleHeaders={[
                            'Colaborador',
                            'Título',
                            'Exemplar',
                            'Acervo',
                            'Data para Devolução',
                            'Status',
                            '',
                            'Valor da Multa',
                            ' '
                        ]}
                        sortedFields={[
                            'collaborator',
                            'title',
                            'exemplar',
                            'library',
                            'devolutionDate',
                            'status',
                            '',
                            'value'
                        ]}
                        mainField="title"
                    />

                    {!hasItens && (
                        <div className={classes.noParticipantsMessage}>
                            <h2>{t('LIBRARY.noFinesMessage')}</h2>
                            <h4>{t('LIBRARY.noFinesSubMessage')}</h4>
                        </div>
                    )}
                    {hasNoResult && (
                        <div className={classes.noParticipantsMessage}>
                            <div>
                                <Dissatisfied
                                    className={classes.notFoundIconStyle}
                                />
                            </div>
                            <h2>{t('GENERAL.noResultsFound')}</h2>
                            <h5>{`Sua busca por "${findText}" não retornou resultados.`}</h5>
                        </div>
                    )}
                </React.Fragment>
            </Paper>
        </React.Fragment>
    );
};

export default FinesListForm;
