import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogUnit from 'components/dialogs/DialogUnit';
import DialogUnitWithIcon from 'components/dialogs/DialogUnitWithIcon';
import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import Buttons from 'components/buttons/Pressed';
import Typography from 'components/typography/Typography';
import Avatar from '@material-ui/core/Avatar';
import Input from 'components/inputs/Input';
import InputFile from 'components/inputs/InputFile';
import { FormatDateToFront } from 'utils/dates/FormatDateToFront';
import { dateToBR } from 'utils/dates/DateToBRr';
import { valueToMoneyFloat } from 'utils/general/valueToMoneyFloat';
import { moneyMask } from 'utils/masks/moneyMask';
import { translateLSBDTransactionsTypes } from '../ScreenUtils';
import { screenStyles } from '../Styles';
import { useMutationUpdateTransaction } from 'hooks/DataFetching/Quotas/Dashboard/useMutationUpdateTransaction';

const DialogEditTransactions = props => {
    const styles = screenStyles();
    const [t] = useTranslation();

    const { open, setOpen, viewValues } = props;

    const mutation = useMutationUpdateTransaction(setOpen);

    const handleClose = () => {
        setOpen(false);
    };

    const [requiredDescription, setRequiredDescription] = useState(false);

    const [file, setFile] = useState(
        viewValues.receiptFilename
            ? [
                  {
                      name: viewValues.receiptFilename
                  }
              ]
            : []
    );

    const [values, setValues] = useState({
        description: viewValues.description
    });

    const handleChange = (name, value) => {
        let val = value;
        setValues({ ...values, [name]: val });
    };

    const verifySubmit = () => {
        let res = true;

        if (!values.description.trim()) {
            res = false;
            setRequiredDescription(true);
        }

        return res;
    };

    const handleSubmit = () => {
        const canSubmit = verifySubmit();
        if (canSubmit) {
            let formData = new FormData();
            const isFileDeleted =
                viewValues.receiptFilename !== null && file.length === 0;

            formData.append('id', viewValues.id);
            formData.append('description', values.description);
            formData.append('deleteReceipt', isFileDeleted);

            formData.append('receipt', file.length ? file[0] : null);

            mutation.mutate(formData);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            maxWidth={'sm'}
            fullWidth
        >
            <DialogTitle>
                <Grid container item spacing={2} xs={12}>
                    <Grid item xs={11}>
                        <Typography
                            variant={'RobotoBold'}
                            fontSize={24}
                            textColor={'secondary'}
                        >
                            {`${t('GENERAL.edit')} ${t('QUOTAS.movement')}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            color="#6D6F71"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent>
                <Grid container item spacing={1} xs={12}>
                    <Grid item xs={6}>
                        <DialogUnitWithIcon
                            head={t('GENERAL.collaborator').toUpperCase()}
                            Icon={
                                <Avatar
                                    className={styles.dialogViewBillAvatar}
                                    alt={
                                        viewValues.operator
                                            ? viewValues.operator.shortname
                                            : viewValues.user.shortname
                                    }
                                    src={
                                        process.env.REACT_APP_IMGSERVER +
                                        (viewValues.operator
                                            ? viewValues.operator.imageName
                                            : viewValues.user.imageName)
                                    }
                                />
                            }
                            content={
                                viewValues.operator
                                    ? viewValues.operator.name
                                    : viewValues.user.name
                            }
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <DialogUnit
                            head={t('GENERAL.date').toUpperCase()}
                            content={dateToBR(
                                FormatDateToFront(viewValues.date)
                            )}
                            marginTop={'16px'}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Input
                            required
                            context={'view'}
                            label={'GENERAL.value'}
                            value={`R$ ${moneyMask(
                                valueToMoneyFloat(viewValues.value)
                            )}`}
                            valueName={'value'}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            required
                            context={'view'}
                            label={'GENERAL.type'}
                            value={translateLSBDTransactionsTypes(
                                viewValues.type,
                                viewValues.source
                            )}
                            valueName={'type'}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <InputFile
                            context={'new'}
                            file={file}
                            setFile={setFile}
                            label={t('GENERAL.receipt')}
                            placeholder={t('GENERAL.noFile')}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Input
                            label={'GENERAL.description'}
                            value={values.description}
                            valueName={'description'}
                            rows={3}
                            maxLength={300}
                            handleChange={handleChange}
                            error={requiredDescription}
                            helperText={
                                requiredDescription &&
                                t('GENERAL.requiredField')
                            }
                            setRequired={setRequiredDescription}
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions style={{ padding: '24px 30px' }}>
                <Buttons
                    onClick={handleClose}
                    tipo={'BN-noback'}
                    conteudo={t('GENERAL.cancel').toUpperCase()}
                />
                <Buttons
                    onClick={handleSubmit}
                    tipo="BN-blue"
                    conteudo={`${t('GENERAL.save').toUpperCase()}`}
                    isLoading={mutation.isLoading}
                />
            </DialogActions>
        </Dialog>
    );
};

export default DialogEditTransactions;
