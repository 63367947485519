import React from 'react';
import { useStyles } from 'components/layout/CommonStyles';
import { inputStyles } from './Styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { useTranslation } from 'react-i18next';

const InputRadio = ({
    context,
    value,
    isSelect,
    valueName,
    label,
    maxLength,
    handleChange,
    required,
    helperText,
    arraySelected,
    rows,
    error,
    type,
    setRequired,
    count,
    fieldType,
    options,
    ...props
}) => {
    const classes = useStyles();
    const styles = inputStyles();
    const [t] = useTranslation();

    return (
        <FormControl component="fieldset">
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup
                row
                aria-label="position"
                disabled={context === 'view'}
                name="position"
                defaultValue="top"
                value={value}
                onChange={ev => handleChange(valueName, ev.target.value)}
            >
                {options.map(option => (
                    <FormControlLabel
                        disabled={context === 'view'}
                        value={option.value}
                        control={<Radio color="primary" />}
                        label={option.label}
                        labelPlacement="end"
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );

    // return (
    //     <TextField
    //         type={fieldType}
    //         name={valueName}
    //         multiline={rows && rows > 0}
    //         fullWidth={rows && rows > 0}
    //         rows={rows && rows}
    //         variant={rows && rows > 0 && 'outlined'}
    //         disabled={context === 'view'}
    //         select={isSelect}
    //         error={error}
    //         value={value}
    //         helperText={helperContent()}
    //         FormHelperTextProps={
    //             !error
    //                 ? {
    //                       className: styles.caractersHelperText
    //                   }
    //                 : null
    //         }
    //         className={classes.textField}
    //         label={
    //             t(label) + (count ? ' ' + count : '') + (required ? '*' : '')
    //         }
    //         onChange={ev => {
    //             if (
    //                 isSelect ||
    //                 maxLength === undefined ||
    //                 (ev.target.value.length >= 0 &&
    //                     ev.target.value.replace(/(\r\n|\n|\r)/g, '  ').length <=
    //                         maxLength)
    //             ) {
    //                 if (type === 'number') {
    //                     if (
    //                         numberRegex.test(ev.target.value) ||
    //                         ev.target.value.length === 0
    //                     ) {
    //                         handleChange(valueName, ev.target.value);
    //                         if (typeof setRequired === 'function') {
    //                             setRequired(false);
    //                         }
    //                     }
    //                 } else if (type === 'float') {
    //                     if (
    //                         ev.target.value.match(floatRegex) ||
    //                         ev.target.value.length === 0
    //                     ) {
    //                         handleChange(valueName, ev.target.value);
    //                         if (typeof setRequired === 'function') {
    //                             setRequired(false);
    //                         }
    //                     }
    //                 } else {
    //                     handleChange(valueName, ev.target.value);
    //                     if (typeof setRequired === 'function') {
    //                         setRequired(false);
    //                     }
    //                 }
    //             }
    //         }}
    //         margin="normal"
    //         {...props}
    //     >
    //         {arraySelected &&
    //             arraySelected.map(item => (
    //                 <MenuItem
    //                     disabled={item.deleted}
    //                     key={item.value}
    //                     value={item.value}
    //                 >
    //                     {item.label}
    //                 </MenuItem>
    //             ))}
    //     </TextField>
    // );
};

export default InputRadio;
