import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Edit from '@material-ui/icons/Edit';
import DialogJob from './DialogJob';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';
import { Screen, Allow } from 'global/constants';
import { screenStyles } from './Styles';

const JobsTableActions = props => {
    const styles = screenStyles();
    const classes = useStyles();
    const [t] = useTranslation();
    const { item } = props;

    const [open, setOpen] = useState(false);

    return (
        <React.Fragment>
            {GetGeneralPermissionForAction(Screen.QUOTAS_JOBS, Allow.EDIT) && (
                <Tooltip
                    title={t('GENERAL.edit')}
                    aria-label="details"
                    placement="top"
                >
                    <IconButton
                        className={classes.iconsTableActions}
                        onClick={() => setOpen(true)}
                    >
                        <Edit />
                    </IconButton>
                </Tooltip>
            )}

            {open && <DialogJob open={open} setOpen={setOpen} job={item} />}
        </React.Fragment>
    );
};

export default JobsTableActions;
