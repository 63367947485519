import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import Buttons from 'components/buttons/Pressed';
import Typography from 'components/typography/Typography';
import { screenStyles } from 'pages/libraryModule/dialogs/Styles';
import { AllAuthorsName } from 'utils/library/AllAuthorsName';
import { dateToBR } from 'utils/dates/DateToBRr';
import { FormatDateToFront } from 'utils/dates/FormatDateToFront';
import { formatExemplarCode } from 'utils/library/formatExemplarCode';
import { translateLibrary } from 'utils/library/translateLibrary';
import { DialogUnit } from 'pages/libraryModule/dialogs/DialogUtils';
import { formatTitle } from 'utils/library/formatTitle';
import { LibraryReservationStatusValues } from 'global/constants';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Skeleton from '@material-ui/lab/Skeleton';
import { formatImagePath } from 'utils/library/formatImagePath';
import { BoxBorderUnit } from 'components/Units/BoxBorderUnit';
import { moneyMask } from 'utils/masks/moneyMask';
import { valueToMoneyFloat } from 'utils/general/valueToMoneyFloat';
import { translateLoanStatus } from 'utils/library/translateStatus';

const DialogHistoryMoreDetails = props => {
    const styles = screenStyles();
    const [t] = useTranslation();
    const { open, setOpen, viewValues, showCollaborator } = props;
    const [loaded, setLoaded] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setLoaded(false);
    }, [viewValues.book.imagePath]);

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth={'sm'}
                fullWidth={true}
            >
                <DialogTitle>
                    <div className={styles.dialogTitle}>
                        <Typography
                            variant={'RobotoBold'}
                            fontSize={24}
                            textColor={'secondary'}
                        >
                            {t('LIBRARY.dialogBooksLoanMoreDetailsTitle')}
                        </Typography>
                        <IconButton
                            color="#6D6F71"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: '16px'
                        }}
                    >
                        <div style={{ width: '200px' }}>
                            <Typography
                                variant={'RobotoBold'}
                                fontSize={14}
                                textColor={'secondary'}
                            >
                                {t('GENERAL.status').toUpperCase()}
                            </Typography>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                {viewValues.status ===
                                    LibraryReservationStatusValues.CANCELED_COLLABORATOR ||
                                viewValues.status ===
                                    LibraryReservationStatusValues.CANCELED_MANAGEMENT ? (
                                    <ErrorIcon
                                        style={{
                                            marginRight: '0.5rem',
                                            color: '#F3222F'
                                        }}
                                    />
                                ) : null}
                                {viewValues.status ===
                                    LibraryReservationStatusValues.FINISHED && (
                                    <CheckCircleIcon
                                        style={{
                                            marginRight: '0.5rem',
                                            color: '#7FE46B'
                                        }}
                                    />
                                )}
                                <Typography
                                    className={styles.dialogTopic}
                                    variant={'RobotoRegular'}
                                    fontSize={14}
                                    textColor={'secondary'}
                                >
                                    {translateLoanStatus(viewValues.status)}
                                </Typography>
                            </div>
                        </div>
                        {viewValues.devolutionDate ? (
                            <div style={{ width: '200px' }}>
                                <DialogUnit
                                    head={t(
                                        'LIBRARY.returnDate'
                                    ).toLocaleUpperCase()}
                                    content={dateToBR(
                                        FormatDateToFront(
                                            viewValues.devolutionDate
                                        )
                                    )}
                                />
                            </div>
                        ) : null}
                    </div>

                    {viewValues.status ===
                    LibraryReservationStatusValues.FINISHED ? (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <DialogUnit
                                head={t(
                                    'GENERAL.receivedBy'
                                ).toLocaleUpperCase()}
                                content={
                                    viewValues.receiver
                                        ? viewValues.receiver.shortname
                                        : '-'
                                }
                                margin={'0 0 16px'}
                            />
                            {(viewValues.fineChanged &&
                                viewValues.fineChanged !== '0.00') ||
                            (viewValues.fine && viewValues.fine !== '0.00') ? (
                                <div style={{ width: '200px' }}>
                                    <DialogUnit
                                        head={t(
                                            'LIBRARY.fineValue'
                                        ).toLocaleUpperCase()}
                                        content={
                                            'R$ ' +
                                            moneyMask(
                                                valueToMoneyFloat(
                                                    viewValues.fineChanged &&
                                                        viewValues.fineChanged !==
                                                            '0.00'
                                                        ? viewValues.fineChanged
                                                        : viewValues.fine
                                                )
                                            )
                                        }
                                    />
                                </div>
                            ) : null}
                        </div>
                    ) : null}

                    {viewValues.status ===
                    LibraryReservationStatusValues.CANCELED_MANAGEMENT ? (
                        <DialogUnit
                            head={t('GENERAL.canceledBy').toLocaleUpperCase()}
                            content={viewValues.canceller.shortname}
                            margin={'0 0 16px'}
                        />
                    ) : null}

                    <div className={styles.dialogBookRequisitionContainer}>
                        <div className={styles.dialogContent}>
                            <DialogUnit
                                head={t('INVENTORY.title').toLocaleUpperCase()}
                                content={formatTitle(
                                    viewValues.book.title,
                                    viewValues.book.subtitle
                                )}
                            />

                            <DialogUnit
                                head={t(
                                    'INVENTORY.BookAuthor'
                                ).toLocaleUpperCase()}
                                content={AllAuthorsName(
                                    viewValues.book.authors
                                )}
                            />

                            <DialogUnit
                                head={t(
                                    'INVENTORY.library'
                                ).toLocaleUpperCase()}
                                content={translateLibrary(viewValues.library)}
                            />

                            <DialogUnit
                                head={'EXEMPLAR'}
                                content={formatExemplarCode(
                                    '' + viewValues.exemplar.code
                                )}
                            />

                            {showCollaborator != undefined &&
                            showCollaborator ? (
                                <DialogUnit
                                    head={t(
                                        'HR.collaborator'
                                    ).toLocaleUpperCase()}
                                    content={viewValues.user.name}
                                />
                            ) : null}
                        </div>

                        <img
                            className={styles.dialogImage}
                            src={formatImagePath(
                                viewValues.book.imagePath,
                                viewValues.book.isImageUrl
                            )}
                            alt={viewValues.book.title}
                            style={!loaded ? { display: 'none' } : {}}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src =
                                    window.location.origin +
                                    '/img/no-book-cover.png';
                                setLoaded(true);
                            }}
                            onLoad={() => {
                                setLoaded(true);
                            }}
                        />
                        {!loaded && (
                            <Skeleton
                                className={styles.dialogImage}
                                variant="rect"
                                width={110}
                                height={150}
                            />
                        )}
                    </div>

                    {viewValues.status ===
                    LibraryReservationStatusValues.CANCELED_MANAGEMENT ? (
                        <BoxBorderUnit
                            title={t('GENERAL.reason')}
                            content={viewValues.justification}
                        />
                    ) : null}
                </DialogContent>

                <DialogActions className={styles.dialogButtonsWrapper}>
                    <Buttons
                        onClick={handleClose}
                        tipo="BN-blue"
                        conteudo={t('GENERAL.close').toUpperCase()}
                    />
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DialogHistoryMoreDetails;
