import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Search from "@material-ui/icons/Search";
import SearchContext from "context/search/searchContext";
import React, { useContext, useEffect } from "react";

const useStyles = makeStyles(() => ({
    searchLeftIcon: {
        marginRight: "20px"
    },
    textField: {
        flexGrow: 1,
        marginRight: "5px",
        marginTop: "10px"
    }
}));

const SearchParticipant = props => {
    const classes = useStyles();
    const searchContext = useContext(SearchContext);
    const { searchTermChanged, filter } = searchContext;

    useEffect(() => {
        return () => {
            searchTermChanged("");
        };
    }, []);

    return (
        <div>
            <Toolbar>
                <Search className={classes.searchLeftIcon} />
                <TextField
                    fullWidth
                    id="search-list-participant"
                    className={classes.textField}
                    label={props.label}
                    margin="normal"
                    variant="filled"
                    autoFocus
                    value={filter}
                    onChange={e => searchTermChanged(e.target.value)}
                />
            </Toolbar>
        </div>);
};

export default SearchParticipant;