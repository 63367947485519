import React from "react";
import "../../App.css";
import SideBar from "../layout/sidebar/Sidebar";
import HRCostsTable from "./HRCostsTable";
import Search from "components/layout/searchIndex";

function Index() {
  return (
    <div>
      <Search
        busca={"Procurar por CPF ou Nome"}
      />
      <SideBar />
      <HRCostsTable />
    </div>
  );
}

export default Index;