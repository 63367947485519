import React from 'react';
import '../../../App.css';
import SideBar from 'components/layout/sidebar/Sidebar';
import DashboardNavBar from 'components/layout/DashboardNavBar';
import NewBook from './NewBook';
import BooksExemplarsPage from './Refactoring/BooksExemplarsPage';

const CallNewBook = ({ match }) => {
    return (
        <div>
            <DashboardNavBar />
            <SideBar />
            <BooksExemplarsPage key={"new"} context={'new'} />
        </div>
    );
};

export default CallNewBook;
