import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { useStyles } from "components/layout/CommonStyles";
import { useTranslation } from "react-i18next";
import CloseIcon from '@material-ui/icons/Close';
import Buttons from "components/buttons/Pressed";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from '@material-ui/core/InputAdornment';
import Download from "@material-ui/icons/GetApp";
import Input from "components/inputs/Input";
import InputFile from "components/inputs/InputModal";
import axios from "axios";
import Cookies from "js-cookie";

const DialogContract = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const fileDownload = require("js-file-download");
    const { open, setOpen, context, contractName, oldContractName, oldContractPath, justification, userId, setParentValues, newContract, setNewContract, newContractPath, setHasChange } = props;

    const [contractFile, setContractFile] = useState([]);
    const [values, setValues] = useState({
        contractFileName: "Anexe um arquivo",
        justification: "",
    });

    const [requiredContract, setRequiredContract] = useState(false);

    useEffect(() => {
        setValues({
            ...values,
            contractFileName: ((newContract && newContract.length) || newContractPath) ? contractName : "Anexe um arquivo",
            justification: justification ? justification : "",
        });
        setContractFile(newContract);
    }, [contractName, newContract, justification]);

    const clearDialog = () => {
        setRequiredContract(false);
        setValues({
            ...values,
            contractFileName: ((newContract && newContract.length) || newContractPath) ? contractName : "Anexe um arquivo",
            justification: justification ? justification : "",
        });
        setContractFile(newContract);
    }

    const handleClose = () => {
        clearDialog();
        setOpen(false);
    }

    const handleChange = (name, value) => {
        setHasChange(true);
        setValues({ ...values, [name]: value })
    }

    const verifySubmit = () => {
        let res = true;
        if (contractFile.length === 0) {
            res = false;
            setRequiredContract(true);
        }

        return res;
    }

    const handleSubmit = () => {
        const canSubmit = verifySubmit();
        if (canSubmit) {
            setParentValues(old => ({
                ...old,
                contractFileName: values.contractFileName,
                contractFilePathJustification: values.justification
            }));
            setNewContract(contractFile);
            setOpen(false);
        }
    }

    const onDropContract = acceptedFiles => {
        if (acceptedFiles[0] !== undefined) {
            setRequiredContract(false);
            handleChange("contractFileName", acceptedFiles[0].name)
            setContractFile(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    };

    const onClickDownload = async (slashPath) => {
        if (slashPath) {
            const noSlashPath = slashPath.replace(/\//g, ";");
            const url = process.env.REACT_APP_SERVER + "api/file/;portal_lsbd;hr_files" + noSlashPath;
            axios.get(url, {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
                },
                responseType: "blob"
            }).then(function (res) {
                const blob = new Blob([res.data], {});
                const name = slashPath.split("/").pop()
                fileDownload(blob, name);
            });
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle className={classes.Title}>
                <b>{context === "view" ? t("GENERAL.view") + " " + t("HR.contract") : t("GENERAL.edit") + " " + t("HR.contract")}</b>
                <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container item spacing={2} md={12} lg={12}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            disabled
                            variant="outlined"
                            label={t("HR.oldContract")}
                            name="oldContract"
                            autoComplete="off"
                            className={classes.textField}
                            value={oldContractName}
                            margin="normal"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput
                            }}
                            inputProps={{ "aria-label": "search" }}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton disabled={!oldContractPath} onClick={() => onClickDownload(oldContractPath)}>
                                            <Download />
                                        </IconButton>
                                    </InputAdornment>
                                ), readOnly: true
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        {context === "view" ?
                            <TextField
                                disabled
                                variant="outlined"
                                label={t("HR.newContract")}
                                name="newContract"
                                autoComplete="off"
                                className={classes.textField}
                                value={values.contractFileName}
                                margin="normal"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput
                                }}
                                inputProps={{ "aria-label": "search" }}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => onClickDownload(newContractPath)}>
                                                <Download />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            :
                            <InputFile
                                required
                                onDrop={onDropContract}
                                files={contractFile}
                                label={"HR.newContract"}
                                value={values.contractFileName}
                                error={requiredContract}
                                errorMessage={requiredContract ? t("GENERAL.requiredField") : ""}
                            />
                        }

                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Input
                            context={context}
                            label={"GENERAL.justification"}
                            value={values.justification}
                            valueName={"justification"}
                            rows={5}
                            maxLength={200}
                            handleChange={handleChange}
                        />
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Buttons
                    onClick={handleClose}
                    tipo="BN-noback"
                    conteudo={context === "view" ? t("GENERAL.close").toUpperCase() : t("GENERAL.cancel").toUpperCase()}
                />
                {context !== "view" &&
                    <Buttons
                        onClick={handleSubmit}
                        tipo="BN-blue"
                        conteudo={t("GENERAL.update").toUpperCase()}
                    />
                }

            </DialogActions>
        </Dialog>
    )
}

export default DialogContract;
