import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InfoIcon from '@material-ui/icons/Info';
import { Grid, Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import Buttons from 'components/buttons/Pressed';
import Typography from 'components/typography/Typography';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const DialogIncompatibility = props => {
    const [t] = useTranslation();
    const { open, setOpen } = props;

    const [checked, setChecked] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        if (checked) {
            localStorage.setItem('incompatibility-dialog', true);
        }
        setOpen(false);
    };

    const handleChange = event => {
        setChecked(event.target.checked);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle>
                <Grid container item spacing={3} xs={12}>
                    <Grid item xs={11}>
                        <Typography
                            variant={'RobotoBold'}
                            fontSize={24}
                            textColor={'secondary'}
                        >
                            {t('GENERAL.attention')}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            color="#6D6F71"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent>
                <Grid
                    container
                    spacing={1}
                    xs={12}
                    style={{ margin: '0 auto' }}
                >
                    <Grid item xs={1} style={{ margin: '0 auto' }}>
                        <Box
                            display={'flex'}
                            alignItems={'center'}
                            minHeight="100%"
                        >
                            <InfoIcon color="primary" />
                        </Box>
                    </Grid>
                    <Grid item xs={11} style={{ margin: '0 auto' }}>
                        <Typography
                            variant={'RobotoBold'}
                            fontSize={16}
                            textColor={'primary'}
                        >
                            {t('GENERAL.systemIncompatibility')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography
                            variant={'RobotoRegular'}
                            fontSize={15}
                            textColor={'primary'}
                            dangerouslySetInnerHTML={{
                                __html: t(
                                    'GENERAL.weIdentifiedThatYouAreUsingSafari',
                                    { interpolation: { escapeValue: false } }
                                )
                            }}
                        ></Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography
                            variant={'RobotoRegular'}
                            fontSize={15}
                            textColor={'primary'}
                        >
                            {t('GENERAL.forBetterExperienceUse')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checked}
                                        onChange={handleChange}
                                        color="primary"
                                    />
                                }
                                color="primary"
                                label={t('GENERAL.doesNotShowThisMessageAgain')}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions style={{ padding: '24px 30px' }}>
                <Buttons
                    onClick={handleSubmit}
                    tipo="BN-blue"
                    conteudo={'OK'}
                />
            </DialogActions>
        </Dialog>
    );
};

export default DialogIncompatibility;
