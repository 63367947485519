import { Text } from '@react-pdf/renderer';
import React from 'react'
import styles from './Label.styled';


const Label = ({ text, style, ...props }) => {
    return (
        <Text style={[styles.label, style]} {...props}>{text}</Text>
    );
}

export default Label;
