import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Buttons from 'components/buttons/Pressed';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import Input from 'components/inputs/Input';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
const DialogAlterUseEquipment = props => {
    const [itemArray, setItemArray] = useState([]);

    const [t] = useTranslation();
    const classes = useStyles();
    const [equipmentSelected, setEquipmentSelected] = useState('');
    const {
        open,
        setOpen,
        item,
        usersArray,
        externalUsersArray,
        placesArray,
        statusArray,
        itensArray,
        requisitions
    } = props;

    const [values, setValues] = useState({
        alterResponsible: '',
        newResponsibleName: '',
        newPlace: '',
        newPlaceName: '',
        radioNewStatus: 'AVAILABLE',
        radioResponsibleType: 'INTERNAL',
        radioReturn: 'YES',
        unavaliableStatusId: '',
        unavaliableStatusName: '',
        itemId: '',
        itemName: '',
        code: '',
        serialNumber: ''
    });

    const clear = () => {
        setValues({
            ...values,
            alterResponsible: '',
            newResponsibleName: '',
            newPlace: '',
            newPlaceName: '',
            radioNewStatus: 'AVAILABLE',
            radioResponsibleType: 'INTERNAL',
            radioReturn: 'YES',
            unavaliableStatusId: '',
            unavaliableStatusName: '',
            itemId: '',
            itemName: '',
            code: '',
            serialNumber: ''
        });
    };
    const [requiredFieldJustification, setRequiredFieldJustification] =
        useState(false);

    const handleClose = () => {
        setOpen({ open: false, obj: [] });
        setRequiredFieldJustification(false);
        clear();
    };

    const handleSubmit = () => {
        props.setUseEquipment(values);
        setOpen(false);
        clear();
    };

    useEffect(() => {
        const itens = [...itensArray];
        var aux = {};
        var _itens = [];
        for (var i = 0; i < itens.length; i++) {
            var item = itens[i];
            let itemAlreadySelected = requisitions.find(requisitionItem => requisitionItem.itemId == item.id)
            if (!itemAlreadySelected) {
                aux.value = item.id;
                aux.label = item.name;
                aux.code = getCodeListUse(item);
                aux.serialNumber = item.serialNumber;
                aux.originPlace = item.place;
                _itens.push(aux);
                aux = {};
            }
        }

        setItemArray(_itens);
    }, [itensArray]);

    const handleChange = (name, value) => {
        if (name === 'alterResponsible') {
            setValues({
                ...values,
                [name]: value,
                newResponsibleName: usersArray.filter(
                    ele => String(ele.value) === String(value)
                )[0].label
            });
        } else if (name === 'itemId') {
            let itemObj;
            if (value) {
                itemObj = itemArray.filter(
                    ele => String(ele.value) === String(value)
                )[0];
            }
            setValues({
                ...values,
                [name]: value,
                itemName: itemObj?.label,
                code: itemObj?.code,
                serialNumber: itemObj?.serialNumber
            });
        } else if (name === 'newPlace') {
            setValues({
                ...values,
                [name]: value,
                newPlaceName: placesArray.filter(
                    ele => String(ele.value) === String(value)
                )[0].label
            });
        } else if (
            name === 'alterResponsible' &&
            values.radioResponsibleType === 'EXTERNAL'
        ) {
            setValues({
                ...values,
                [name]: value,
                newResponsibleName: externalUsersArray.filter(
                    ele => String(ele.value) === String(value)
                )[0].label
            });
        } else if (name === 'unavaliableStatusId') {
            setValues({
                ...values,
                [name]: value,
                unavaliableStatusName: statusArray.filter(
                    ele => String(ele.value) === String(value)
                )[0].label
            });
        } else {
            setValues({ ...values, [name]: value });
        }
    };

    function getCodeListUse(row) {
        const response = [];
        if (row['lsbdCode']) response.push(row['lsbdCode']);
        if (row['fcpcCode']) response.push(row['fcpcCode']);
        if (row['ufcCode']) response.push(row['ufcCode']);
        if (row['externCode']) response.push(row['externCode']);

        if (response.length === 0) {
            if (
                row.libraryExemplar &&
                row.libraryExemplar.length > 0 &&
                row.libraryExemplar[0].exemplarCode !== null
            ) {
                response.push('BIB-00' + row.libraryExemplar[0].exemplarCode);
            } else {
                response.push('-');
            }
        }
        return response;
    }
    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                aria-labelledby="form-dialog-title"
                msg="mensagem"
            >
                <DialogTitle className={classes.actionsTitle}>
                    <span style={{ fontWeight: 'bold' }}>
                        {t('INVENTORY.alterItem')}
                    </span>
                    <IconButton
                        style={{
                            float: 'right',
                            marginTop: -10,
                            marginRight: -17
                        }}
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Grid container>
                            <Grid item xs={12} sm={12}>
                                <b>{t('INVENTORY.category').toUpperCase()}</b>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                {item && item.category}
                            </Grid>
                        </Grid>
                        <Grid container style={{ paddingTop: '20px' }}>
                            <Grid item xs={12} sm={12}>
                                <Input
                                    rows={4}
                                    context={'view'}
                                    value={item && item.description}
                                    valueName={'description'}
                                    label={t('INVENTORY.description')}
                                    margin="normal"
                                />
                            </Grid>
                        </Grid>
                        <Grid container style={{ paddingTop: '20px' }}>
                            <Grid item xs={12} sm={12}>
                                <Autocomplete
                                    id="combo-box-equipment"
                                    clearOnBlur={false}
                                    autoComplete={false}
                                    autoSelect={false}
                                    options={itemArray}
                                    getOptionLabel={(option) => option.code[0]}
                                    filterOptions={createFilterOptions({
                                        matchFrom: 'any',
                                        stringify: (option) => option.code[0] + option.serialNumber,
                                    })}
                                    onChange={(ev, newValue) => {
                                        handleChange('itemId', newValue?.value);
                                    }}
                                    inputValue={equipmentSelected}
                                    onInputChange={(ev, newInputValue) => {
                                        let item = itemArray.find(
                                            item =>
                                                item.code[0] == newInputValue || item.serialNumber == newInputValue
                                        );
                                        item
                                            ? setEquipmentSelected(item.label)
                                            : setEquipmentSelected(
                                                  newInputValue
                                              );
                                    }}
                                    renderOption={option => (
                                        <MenuItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            Nome:{option.label}
                                            <br />
                                            Código:{option.code[0]}
                                            <br />
                                            Numero de série:
                                            {option.serialNumber}
                                        </MenuItem>
                                    )}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label={t('INVENTORY.item')}
                                            className={classes.textField}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        {values.itemId && (
                            <React.Fragment>
                                <Grid container>
                                    <Grid item xs={12} sm={12}>
                                        <b>
                                            {t('INVENTORY.item').toUpperCase()}
                                        </b>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        {itemArray &&
                                            itemArray.filter(
                                                x =>
                                                    String(x.value) ===
                                                    String(values.itemId)
                                            )[0].label}
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} sm={12}>
                                        <b>
                                            {t('INVENTORY.code').toUpperCase()}
                                        </b>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        {itemArray &&
                                            itemArray.filter(
                                                x =>
                                                    String(x.value) ===
                                                    String(values.itemId)
                                            )[0].code[0]}
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} sm={12}>
                                        <b>
                                            {t(
                                                'INVENTORY.serialNumber'
                                            ).toUpperCase()}
                                        </b>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        {itemArray &&
                                            itemArray.filter(
                                                x =>
                                                    String(x.value) ===
                                                    String(values.itemId)
                                            )[0].serialNumber}
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} sm={12}>
                                        <b>
                                            {t(
                                                'INVENTORY.placeOfOrigin'
                                            ).toUpperCase()}
                                        </b>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        {itemArray &&
                                            itemArray.filter(
                                                x => x.value === values.itemId
                                            )[0].originPlace}
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        )}
                        <Input
                            isSelect={true}
                            error={requiredFieldJustification}
                            value={values.newPlace}
                            valueName={'newPlace'}
                            label={t('INVENTORY.destinationPlace') + '*'}
                            margin="normal"
                            maxLength={200}
                            handleChange={handleChange}
                            arraySelected={placesArray}
                        />
                        <Grid container style={{ paddingTop: '20px' }}>
                            <Grid item xs={12} sm={12}>
                                <b>{t('INVENTORY.newStatus').toUpperCase()}</b>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <RadioGroup
                                    row
                                    aria-label="position"
                                    name="position"
                                    defaultValue="top"
                                    value={values.radioNewStatus}
                                    onChange={ev =>
                                        setValues({
                                            ...values,
                                            radioNewStatus: ev.target.value
                                        })
                                    }
                                >
                                    <FormControlLabel
                                        value="AVAILABLE"
                                        control={<Radio color="primary" />}
                                        label="Disponível"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="UNAVAILABLE"
                                        control={<Radio color="primary" />}
                                        label="Indisponível"
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        {values.radioNewStatus === 'UNAVAILABLE' && (
                            <Input
                                isSelect={true}
                                error={requiredFieldJustification}
                                value={values.unavaliableStatusId}
                                valueName={'unavaliableStatusId'}
                                label={
                                    t('INVENTORY.unavailabilityStatus') + '*'
                                }
                                margin="normal"
                                maxLength={200}
                                handleChange={handleChange}
                                arraySelected={statusArray}
                            />
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Buttons
                        onClick={handleClose}
                        tipo="BN-noback"
                        conteudo={t('GENERAL.cancel').toLocaleUpperCase()}
                    />
                    <Buttons
                        onClick={handleSubmit}
                        tipo="BN-blue"
                        conteudo={t('GENERAL.alter').toLocaleUpperCase()}
                    />
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default DialogAlterUseEquipment;
