import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";
import history from "../../history"

export async function getAuthenticationData(props) {

    var token = "bearer " + Cookies.get('auth-token')
    var result = false;

    const requestInfo = {
        method: "POST",
        body: JSON.stringify({}),
        headers: new Headers({
            "Content-type": "application/json",
            "Authorization": token

        })
    };

    await fetch(process.env.REACT_APP_SERVER + "api/auth/Authenticate", requestInfo)
        .then(response => {
            if (response.ok) {
                return response.text();
            } else {
                if (response.status === 401) {
                    history.push("/forbidden")
                } else {
                    console.log(response.status)
                }
                throw new Error("Token inválida! Tente novamente.");
            }
        })
        .then(jwt => {
            // console.log(JSON.stringify(requestInfo))
            // console.log("JWT ", jwt);

            var jwtParsed = JSON.parse(jwt);
            // console.log("jwtparsed ", jwtParsed);

            localStorage.setItem("auth-token", JSON.stringify(jwtParsed.authData));
            // console.log("AUTH ", localStorage.getItem("auth-token"))

            Cookies.set("auth-token", jwtParsed.token, {
                // setCookie("auth-token", 'ola eu sou um cookie', {
                path: "/",
                domain: process.env.REACT_APP_DOMAIN
                // maxAge: 900,
                // secure: false,
                // httpOnly: false
            });
            // console.log(Cookies["auth-token"]);


            if (localStorage.getItem("auth-token") !== null) {
                var auth = JSON.parse(localStorage.getItem("auth-token"));
                var array = []
                for (var i = 0; i < auth.permissions.length; i++) {
                    var permission = auth.permissions[i].standardauthorizationoption;
                    var resource = auth.permissions[i].resource;
                    if (permission.allowCreate ||
                        permission.allowDelete ||
                        permission.allowRead ||
                        permission.allowUpdate) {
                        array.push(resource)
                    }
                }
            }
            result = true;
            // console.log('Auth=token ', localStorage.getItem("auth-token"))
        })
        .catch(error => {
            console.log(error.message);
        });
    return result;
}
export function isLogged() {
    // console.log("TRUe", jwtDecode(Cookies.get("auth-token")).exp, (Date.now() / 1000));;
    if (Cookies.get("auth-token") &&
        jwtDecode(Cookies.get("auth-token")).exp > Date.now() / 1000) {
        return true;
    }
    return false;
}

export const authorized = (screen, canBeAcessedBySameId, allowType) => {
    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));

        if(Cookies.get('auth-token') !== auth.token){
            localStorage.removeItem('auth-token');
            document.location.reload(true);
        }
        
        if (canBeAcessedBySameId) {
            return true;
        }
        for (var i = 0; i < auth.generalPermissions.length; i++) {
            var permission = auth.generalPermissions.find(gen => gen.screen === screen);
            if (permission && permission[allowType]) {
                return true
            }
        }

    }
    return false;
}
