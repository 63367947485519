export const adminData = [
    {
        screen: 'ADMIN_USERS',
        allowRead: false,
        allowCreate: false,
        allowEdit: false,
        allowDelete: false
    },
    {
        screen: 'ADMIN_PROFILES',
        allowRead: false,
        allowCreate: false,
        allowEdit: false,
        allowDelete: false
    }
];
