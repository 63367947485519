export const newsData = [
    {
        screen: 'MY_NEWS',
        allowRead: false,
        allowCreate: false,
        allowEdit: false,
        allowDelete: false
    },
    {
        screen: 'PUBLISHED',
        allowRead: false,
        allowEdit: false,
        allowDelete: false
    },
    {
        screen: 'PENDING_FOR_APPROVAL',
        allowRead: false,
        allowEdit: false,
        isManager: false
    },
    {
        screen: 'PENDING_FOR_PUBLICATION',
        allowRead: false,
        allowEdit: false
    },
    {
        screen: 'HIGHLIGHT',
        allowRead: false,
        allowCreate: false,
        allowEdit: false,
        allowDelete: false
    }
];
