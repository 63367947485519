/* eslint-disable */
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Footer from "components/layout/Footer";
import Spinner from "components/layout/spinners/Index";
import InventoryStatusTable from "./InventoryStatusTable";
import AllowancesContext from "context/allowance/allowanceContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import MySnackbarContentWrapper from "components/layout/Message";
import { useStyles } from "components/layout/CommonStyles";
import Dissatisfied from "@material-ui/icons/SentimentVeryDissatisfied";
import Paper from "@material-ui/core/Paper";
import InventoryContext from "context/inventory/inventoryContext"
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import IconButton from "@material-ui/core/IconButton";
import ListIcon from '@material-ui/icons/List';
import DehazeIcon from '@material-ui/icons/Dehaze';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import Toolbar from "@material-ui/core/Toolbar";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import Buttons from "components/buttons/Pressed";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import MainStatus from "./MainStatus";
import AddCircle from "@material-ui/icons/AddCircle";
import { GetGeneralPermissionForAction } from "utils/permissions/GetGeneralPermissionForAction";
import { Screen, Allow } from "global/constants";

const tooltipTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#2c3e51"
        }
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                color: "white",
                fontSize: "1em",
                backgroundColor: "red",
                opacity: "0.7"
            }
        }
    }
});

const InventoryStatus = () => {
    const classes = useStyles();
    const [t] = useTranslation();
    const inventoryContext = useContext(InventoryContext);
    const allowanceContext = useContext(AllowancesContext);
    const [open, setOpen] = useState(false);
    const [mustRedirect, setMustRedirect] = useState(false);
    const [openName, setOpenName] = useState(false);
    const [titleName, setTitle] = useState(t("GENERAL.requiredField"));

    const clearRequiredMessage = () => {
        setOpenName(false);
    };

    const showRequiredFieldsMessage = () => {
        setOpenName(true);
    };

    const [values, setValues] = React.useState({
        id: null,
        name: "",
        description: "",
    });

    let dataFilter = [];
    const [snackBar, setSnackBar] = useState({
        open: false,
        kind: "",
        content: ""
    });
    const [filter, setFilter] = useState("");
    const [filter2, setFilter2] = useState("");

    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }

    const {
        getAllStatus,
        loading,
        response,
        hasChanged,
        setHasChanged,
        responseType,
        successiveActions,
        allStatus,
        addItemStatus,
    } = inventoryContext;

    const {
        allowanceResponse,
        allowanceResponseType,
        messageHasChanged,
        resetAllowanceMessage,
        permission
    } = allowanceContext;

    dataFilter = allStatus;

    dataFilter = dataFilter.filter(
        item =>
            item.disableItem === true
    );

    if (filter) {
        dataFilter = dataFilter.filter(
            item =>
                item.name.toLowerCase().startsWith(filter.toLowerCase())
        );
    }

    const hasItens = allStatus.length > 0;
    const [hasNoResult, setHasNoResult] = useState(hasItens && dataFilter.length === 0);
    const [itensTable, setItensTable] = useState(
        // renderTable(dataFilter)
    );

    let data = [];
    data = allStatus;

    const handleChangeName = name => event => {
        if (event.target.value.length <= 50) {
            if (name === "name") {
                clearRequiredMessage();
            }
            data = data.filter(item => item.name.trim().toLocaleLowerCase() === event.target.value.trim().toLocaleLowerCase());
            if (data.length !== 0) {
                setTitle(t("INVENTORY.nameStatusAlreadyTaken"));
                setOpenName(true);
            } else {
                setTitle(t("GENERAL.requiredField"));
            }
            setValues({ ...values, [name]: event.target.value });
        }
    };

    const handleChange = name => event => {
        if (event.target.value.length <= 250) {
            setValues({ ...values, [name]: event.target.value });
        }
    };

    const transitions = useTransition(loading, null, {
        from: { opacity: 0 },
        enter: {
            opacity: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "calc(100vh - 70px)"
        },
        leave: { opacity: 0 }
    });

    useEffect(() => {
        getAllStatus()
        if (allowanceResponseType === "ERROR" && messageHasChanged) {
            handleSnack({ kind: "error", content: allowanceResponse });
        }
        if (responseType === "SUCCESS" && hasChanged > successiveActions) {
            handleSnack({ kind: "success", content: response });
        }
        if (responseType === "ERROR" && hasChanged > successiveActions) {
            handleSnack({ kind: "error", content: response });
        }

        return () => { };
    }, [
        responseType,
        response,
        successiveActions,
        allowanceResponseType,
        allowanceResponse,
        permission.length,
        allStatus.length
    ]);

    const handleSnack = () => {
        setSnackBar({ ...snackBar, open: true });
        setHasChanged();
    };
    const handleSnackClose = () => {
        setSnackBar({ ...snackBar, open: false });
        resetAllowanceMessage();
    };

    const handleClickOpen = () => {
        setValues({
            ...values,
            name: "",
            description: "",
        });
        setOpen(true);
    };

    const handleClose = () => {
        clearRequiredMessage();
        setOpen(false);
    };

    const handleSearch = event => {
        setFilter(event.target.value);

        dataFilter = dataFilter.filter(
            item =>
                item.name.toLowerCase().startsWith(event.target.value.toLowerCase())
        );

        if (dataFilter.length === 0)
            setHasNoResult(true);
        else
            setHasNoResult(false);
        //setItensTable(renderTable(dataFilter));
    };

    const handleSubmit = e => {
        if (!openName) {
            if (values.name.split("").filter(el => el !== " ").join("") !== "") {
                var obj = {
                    name: values.name,
                    description: values.description,
                    disableItem: true
                };
                clearRequiredMessage();
                addItemStatus(obj);
                setOpen(false);
                setMustRedirect(true);
                e.preventDefault();
            } else {
                showRequiredFieldsMessage();
            }
        } else showRequiredFieldsMessage();
    };

    return transitions.map(({ item, key, props }) =>
        item ? (
            <animated.div key={key} style={props}>
                <Spinner />;
            </animated.div>
        ) : (
            <animated.div key={key} style={props}>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={snackBar.open}
                    onClose={handleSnackClose}
                    TransitionComponent={Slide}
                    transitionDuration={{ enter: 500, exit: 500 }}
                    ContentProps={{
                        "aria-describedby": "message-id"
                    }}
                    autoHideDuration={2000}
                >
                    <MySnackbarContentWrapper
                        onClose={handleSnackClose}
                        variant={
                            responseType !== ""
                                ? responseType.toLowerCase()
                                : allowanceResponseType.toLowerCase()
                        }
                        message={
                            <span id="message-id">
                                {response !== "" ? response : allowanceResponse}
                            </span>
                        }
                    />
                </Snackbar>
                <div className="container">
                    <Breadcrumbs
                        className={classes.breadCrumbs}
                        aria-label="breadcrumb"
                        separator={<NavigateNextIcon fontSize="small" />}
                    >
                        <Typography>{t("INVENTORY.inventory")}</Typography>
                        <Typography>{t("INVENTORY.statusOfItem")}</Typography>
                    </Breadcrumbs>

                    <div className="container-header">
                        <div className="title">
                            <h1 className={classes.title}>{t("INVENTORY.statusItem")}</h1>
                        </div>
                    </div>
                    <Paper>
                        {allStatus.length > 0 && <MainStatus qtdDis={allStatus} qtdIndis={dataFilter} />}
                    </Paper>
                    <br></br>
                    <Paper >
                        <div className="cabecalho">
                            {t("INVENTORY.unavailabilityStatus").toUpperCase()}
                            {GetGeneralPermissionForAction(Screen.ITENS_STATUS, Allow.CREATE) &&
                                <span style={{ float: "right", marginRight: 10 }}>
                                    <span style={{ float: "right", marginRight: 10 }}>ADICIONAR
                                        <IconButton className="btnActive" style={{ float: "right" }} edge="end" color="inherit" onClick={handleClickOpen} >
                                            <AddCircle />
                                        </IconButton>
                                    </span>
                                </span>
                            }
                        </div>
                        <div className={classes.divAddParticipantLeft}>
                            <Toolbar className={classes.searchToolbarInventory}>
                                <div style={{ display: !hasItens ? "none" : "" }} className={classes.searchInventory}>
                                    <div className={classes.searchIcon}>
                                        <SearchIcon />
                                    </div>
                                    <InputBase
                                        placeholder={t("INVENTORY.name")}
                                        value={filter}
                                        onChange={handleSearch}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputInput
                                        }}
                                        inputProps={{ "aria-label": "search" }}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                            </Toolbar>
                        </div>
                        <InventoryStatusTable
                            rows={dataFilter}
                            headers={["id", "name", "description", "itensQtd", "disableItem"]}
                            visibleHeaders={["nome", "Descrição", "Quantidade de Itens", ""]}
                            sortedFields={["name", "description", "itensQtd"]}
                            mainField="name"
                            permission={permission}
                        />
                        {hasItens ? itensTable :
                            <div className={classes.noParticipantsMessage}>
                                <h2>{t("INVENTORY.noItensMessage")}</h2>
                                <h4>{t("INVENTORY.noItensSubMessage")}</h4>
                            </div>
                        }
                        {hasNoResult && filter ?
                            <div className={classes.noParticipantsMessage}>
                                <div >
                                    <Dissatisfied className={classes.notFoundIconStyle} />
                                </div>
                                <h2>{t("GENERAL.noResultsFound")}</h2>
                                <h5>{`Sua busca por "${filter}" não retornou resultados.`}</h5>
                            </div>
                            : hasNoResult && filter2 &&
                            <div className={classes.noParticipantsMessage}>
                                <div >
                                    <Dissatisfied className={classes.notFoundIconStyle} />
                                </div>
                                <h2>{t("GENERAL.noResultsFound")}</h2>
                                <h5>{`Sua busca por "${filter2}" não retornou resultados.`}</h5>
                            </div>
                        }
                    </Paper>
                </div>
                <Footer />
                <Dialog
                    open={open}
                    fullWidth={true}
                    maxWidth={"sm"}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title"
                    msg="mensagem"
                >
                    <DialogTitle className={classes.TitleDialog}>
                        {t("INVENTORY.addItemStatus")}
                        <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <div className={classes.root}>
                                <Grid container>
                                    <Grid item xs={12} sm={12}>
                                        <MuiThemeProvider theme={tooltipTheme}>
                                            <Tooltip
                                                TransitionComponent={Zoom}
                                                open={openName}
                                                title={titleName}
                                            >
                                                <TextField
                                                    className={classes.textField}
                                                    label={t("INVENTORY.nameStatus") + "*"}
                                                    value={values.name}
                                                    onChange={handleChangeName("name")}
                                                    margin="normal"
                                                    inputProps={{
                                                        maxLength: 50
                                                    }}
                                                />
                                            </Tooltip>
                                        </MuiThemeProvider>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        multiline
                                        className={classes.textField}
                                        label={t("INVENTORY.descriptionBookCategory")}
                                        value={values.description}
                                        margin="normal"
                                        onChange={handleChange("description")}
                                        inputProps={{
                                            maxLength: 250
                                        }}
                                    />
                                </Grid>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Buttons
                            onClick={handleClose}
                            tipo="BN-noback"
                            conteudo={t("INVENTORY.cancel").toUpperCase()}
                        />
                        <Buttons
                            onClick={handleSubmit}
                            tipo="BN-blue"
                            conteudo={t("INVENTORY.add").toUpperCase()}
                        />
                    </DialogActions>
                </Dialog>
            </animated.div>
        )
    );
};

export default InventoryStatus;