/* eslint-disable */
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import Input from "@material-ui/icons/Input";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function DashboardMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [t] = useTranslation();

  const StyledMenu = withStyles({
    paper: {
      border: "1px solid #d3d4d5"
    }
  })(props => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      {...props}
    />
  ));

  const StyledMenuItem = withStyles(theme => ({
    //Comentando isso resolveu a cor do seja bem vindo
    // root: {
    //   "&:focus": {
    //     backgroundColor: theme.palette.primary.main,
    //     "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
    //       color: theme.palette.common.white
    //     }
    //   }
    // }
  }))(MenuItem);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <KeyboardArrowDown />
      </IconButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem className="welcome">
          <ListItemText primary="Seja bem-vindo!" />
        </StyledMenuItem>
        <Link style={{ color: "black" }} to="/logout" name="logout">
          <StyledMenuItem>
            <ListItemIcon>
              <Input />
            </ListItemIcon>
            {t("GENERAL.leave")}
          </StyledMenuItem>
        </Link>
      </StyledMenu>
    </div>
  );
}
