import i18n from '../../../i18n';
import { LibraryPlaceValues, LibraryFinesTypes } from 'global/constants';

export const LibraryArray = [
    {
        value: 'ALL',
        label: i18n.t('GENERAL.allMasculine')
    },
    {
        value: LibraryPlaceValues.GENERAL,
        label: i18n.t('LIBRARY.general')
    },
    {
        value: LibraryPlaceValues.COORDINATION,
        label: i18n.t('LIBRARY.coordination')
    }
];

export const StatusArray = [
    {
        value: 'ALL',
        label: i18n.t('GENERAL.allMasculine')
    },
    {
        value: 'PAID',
        label: i18n.t('GENERAL.paid')
    },
    {
        value: 'PENDING',
        label: i18n.t('GENERAL.pending')
    },
    {
        value: 'NO_CHARGE',
        label: i18n.t('GENERAL.noCharge')
    }
];

export const TypesArray = [
    {
        value: 'ALL',
        label: i18n.t('GENERAL.allMasculine')
    },
    {
        value: LibraryFinesTypes.BOOK_PURCHASE,
        label: i18n.t('LIBRARY.bookPurchase')
    },
    {
        value: LibraryFinesTypes.FINE_PAYMENT,
        label: i18n.t('LIBRARY.payFine')
    },
    {
        value: LibraryFinesTypes.UPDATE_EXEMPLAR_DEBIT,
        label: i18n.t('LIBRARY.updateExemplar')
    },
    {
        value: LibraryFinesTypes.UPDATE_EXEMPLAR_CREDIT,
        label: i18n.t('LIBRARY.removeExemplarTransaction')
    },
    {
        value: LibraryFinesTypes.CREDIT,
        label: i18n.t('GENERAL.credit')
    },
    {
        value: LibraryFinesTypes.DEBIT,
        label: i18n.t('GENERAL.debit')
    }
];
