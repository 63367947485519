import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import InfoTooltip from 'components/infoTooltip/InfoTooltip';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useTranslation } from 'react-i18next';
import {
    StyledTableCell,
    StyledTableRow,
    useStyles
} from 'components/profiles/ProfileStyles';
import Actions from './FinesTableActions';
import { PadToNDigits } from 'utils/general/PadToNDigits';
import { translateLibrary } from 'utils/library/translateLibrary';
import { translateFineStatus } from 'utils/library/translateFinesStatus';
import { dateToBR } from 'utils/dates/DateToBRr';
import { FormatDateToFront } from 'utils/dates/FormatDateToFront';
import { GetNumberOfDays } from 'utils/dates/GetNumberOfDays';
import { valueToMoneyFloat } from 'utils/general/valueToMoneyFloat';
import { moneyMask } from 'utils/masks/moneyMask';
import { LibraryFinesStatus, LibraryPlaceValues } from 'global/constants';
import { isDayOrDaysText } from 'utils/dates/isDayOrDaysText';

function compareString(a, b) {
    if (
        b
            .toString()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .trim()
            .toLowerCase() <
        a
            .toString()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .trim()
            .toLowerCase()
    ) {
        return -1;
    }
    if (
        b
            .toString()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .trim()
            .toLowerCase() >
        a
            .toString()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .trim()
            .toLowerCase()
    ) {
        return 1;
    }
    return 0;
}

function compareFloat(a, b) {
    if (b < a) {
        return -1;
    }
    if (b > a) {
        return 1;
    }
    return 0;
}

function desc(a, b, orderBy) {
    if (a[orderBy] === null) a[orderBy] = '';
    if (b[orderBy] === null) b[orderBy] = '';

    if (a[orderBy] === undefined) a[orderBy] = '';
    if (b[orderBy] === undefined) b[orderBy] = '';

    if (orderBy === 'collaborator') {
        return compareString(
            a['reservation'].user.shortname,
            b['reservation'].user.shortname
        );
    }

    if (orderBy === 'title') {
        return compareString(
            a['reservation'].book.title,
            b['reservation'].book.title
        );
    }
    if (orderBy === 'exemplar') {
        return compareString(
            a['reservation'].exemplar.code,
            b['reservation'].exemplar.code
        );
    }

    if (orderBy === 'library') {
        return compareString(
            translateLibrary(a['reservation'].library),
            translateLibrary(b['reservation'].library)
        );
    }
    if (orderBy === 'status') {
        return compareString(
            translateFineStatus(a['status']),
            translateFineStatus(b['status'])
        );
    }

    if (orderBy === 'devolutionDate') {
        return compareString(
            a['reservation'].returnDate,
            b['reservation'].returnDate
        );
    }

    if (orderBy === 'value') {
        return compareFloat(
            parseFloat(a['reservation'].fine),
            parseFloat(b['reservation'].fine)
        );
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc'
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
}

function createContent(rows, headers, order, orderBy) {
    const rowsCopy = [];
    var obj = {};
    for (var i = 0; i < rows.length; i++) {
        for (var j = 0; j < headers.length; j++) {
            obj[headers[j]] = rows[i][headers[j]];
        }
        rowsCopy.push(obj);
        obj = {};
    }
    return stableSort(rowsCopy, getSorting(order, orderBy));
}

const BooksCollectionTable = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [rows, setRows] = useState({});
    const [rowsLength, setRowsLength] = useState(0);
    const [headers, setHeaders] = useState([]);
    const [mainField, setMainField] = useState('');
    const [visibleHeaders, setVisibleHeaders] = useState([]);
    const [sortedFields, setSortedFields] = useState([]);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');

    React.useEffect(() => {
        setHeaders(props.headers);
        setRows(props.rows);
        setRowsLength(props.rows.length);
        setMainField(props.mainField);
        setVisibleHeaders(props.visibleHeaders);
        setSortedFields(props.sortedFields);
        adjustRowsPerpage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.headers, props.mainField, props.rows, props.visibleHeaders]);

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }
    const adjustRowsPerpage = () => {
        if (props.rows.length >= 10) {
            setRowsPerPage(10);
        } else {
            setRowsPerPage(props.rows.length);
        }
    };
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === sortedFields[property] && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(sortedFields[property]);
    };

    const UsersTableHead = props => {
        const { order, orderBy, onRequestSort } = props;
        const [t] = useTranslation();
        const paginationTop = action => e => {
            var selector = "[aria-label^='" + action + "']";
            document.querySelector(selector).click();
            e.preventDefault();
        };
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    {visibleHeaders.map((header, index) => (
                        <StyledTableCell
                            key={index}
                            size="small"
                            align="left"
                            sortDirection={
                                orderBy === sortedFields[index] ? order : false
                            }
                        >
                            {index !== 6 && index !== 8 ? (
                                <TableSortLabel
                                    active={orderBy === sortedFields[index]}
                                    direction={order}
                                    onClick={createSortHandler(index)}
                                    disabled={index === 6 || index === 8}
                                >
                                    {header.toUpperCase()}
                                    {orderBy === sortedFields[index] ? (
                                        <span
                                            className={classes.visuallyHidden}
                                        >
                                            {order === 'desc'
                                                ? 'sorted descending'
                                                : 'sorted ascending'}
                                        </span>
                                    ) : null}
                                </TableSortLabel>
                            ) : null}
                        </StyledTableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    };

    UsersTableHead.propTypes = {
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired
    };

    React.useEffect(() => {
        if (props.toFirstPage) {
            document.getElementById('toFirstPage').click();
        }
    }, [props.rows, props.toFirstPage]);

    const toFirstPage = () => {
        setPage(0);
    };

    return (
        <Paper className={classes.root}>
            <div className={classes.tableWrapper}>
                <Table className={classes.table} size="small">
                    {rows.length > 0 && (
                        <UsersTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                    )}
                    <TableBody>
                        {createContent(rows, headers, order, orderBy)
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            .map(row => (
                                <StyledTableRow key={row.id}>
                                    <TableCell size="small">
                                        {row['reservation'].user.shortname
                                            ? row['reservation'].user.shortname
                                            : '-'}
                                    </TableCell>
                                    <TableCell size="small">
                                        {row['reservation'].book.title
                                            ? row['reservation'].book.title
                                            : '-'}
                                    </TableCell>
                                    <TableCell size="small">
                                        {row['reservation'].exemplar.code
                                            ? 'BIB-' +
                                              PadToNDigits(
                                                  row['reservation'].exemplar
                                                      .code,
                                                  4
                                              )
                                            : '-'}
                                    </TableCell>
                                    <TableCell size="small">
                                        {row['reservation'].library
                                            ? translateLibrary(
                                                  row['reservation'].library
                                              )
                                            : '-'}
                                    </TableCell>
                                    <TableCell size="small">
                                        {!row['reservation'].devolutionDate
                                            ? `${dateToBR(
                                                  FormatDateToFront(
                                                      row['reservation']
                                                          .returnDate
                                                  )
                                              )} (${
                                                  GetNumberOfDays(
                                                      FormatDateToFront(
                                                          row['reservation']
                                                              .returnDate
                                                      ),
                                                      new Date()
                                                  ) - 1
                                              } ${isDayOrDaysText(
                                                  GetNumberOfDays(
                                                      FormatDateToFront(
                                                          row['reservation']
                                                              .returnDate
                                                      ),
                                                      new Date()
                                                  ) - 1
                                              )})`
                                            : `${dateToBR(
                                                  FormatDateToFront(
                                                      row['reservation']
                                                          .returnDate
                                                  )
                                              )} (${
                                                  GetNumberOfDays(
                                                      FormatDateToFront(
                                                          row['reservation']
                                                              .returnDate
                                                      ),
                                                      FormatDateToFront(
                                                          row['reservation']
                                                              .devolutionDate
                                                      )
                                                  ) - 1
                                              } ${isDayOrDaysText(
                                                  GetNumberOfDays(
                                                      FormatDateToFront(
                                                          row['reservation']
                                                              .returnDate
                                                      ),
                                                      FormatDateToFront(
                                                          row['reservation']
                                                              .devolutionDate
                                                      )
                                                  ) - 1
                                              )})`}
                                    </TableCell>
                                    <TableCell size="small">
                                        {row['reservation'].library ===
                                        LibraryPlaceValues.COORDINATION
                                            ? translateFineStatus(
                                                  LibraryFinesStatus.NO_CHARGE
                                              )
                                            : translateFineStatus(
                                                  row['status']
                                              )}
                                    </TableCell>
                                    <TableCell size="small">
                                        {row['status'] ? (
                                            row['reservation'].library ===
                                            LibraryPlaceValues.COORDINATION ? (
                                                <InfoTooltip
                                                    text={t(
                                                        'LIBRARY.CoordenationExemplarsDoNoTGenerateFines'
                                                    )}
                                                />
                                            ) : row['status'] ===
                                              LibraryFinesStatus.PAID ? (
                                                <CheckCircleIcon
                                                    style={{
                                                        marginLeft: '0.2rem',
                                                        color: '#7FE46B'
                                                    }}
                                                />
                                            ) : row['status'] ===
                                              LibraryFinesStatus.PENDING ? (
                                                <ErrorIcon
                                                    style={{
                                                        marginLeft: '0.2rem',
                                                        color: '#F3222F'
                                                    }}
                                                />
                                            ) : null
                                        ) : (
                                            '-'
                                        )}
                                    </TableCell>
                                    <TableCell size="small">
                                        {row['reservation'].library ===
                                        LibraryPlaceValues.COORDINATION
                                            ? '-'
                                            : row['reservation'].fineChanged
                                            ? `R$ ${moneyMask(
                                                  valueToMoneyFloat(
                                                      row['reservation']
                                                          .fineChanged
                                                  )
                                              )}`
                                            : row['reservation'].fine
                                            ? `R$ ${moneyMask(
                                                  valueToMoneyFloat(
                                                      row['reservation'].fine
                                                  )
                                              )}`
                                            : '-'}
                                    </TableCell>
                                    <TableCell size="small" align="right">
                                        <div
                                            style={{
                                                marginTop: 'auto',
                                                marginBottom: 'auto',
                                                display: 'flex',
                                                flexWrap: 'nowrap ',
                                                justifyContent: 'flex-end'
                                            }}
                                        >
                                            <Actions
                                                item={row}
                                                name={row.name}
                                                permission={props.permission}
                                                fetchData={props.fetchData}
                                                submit={props.submit}
                                            />
                                        </div>
                                    </TableCell>
                                </StyledTableRow>
                            ))}

                        {emptyRows > 0 && (
                            <TableRow style={{ height: 48 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <button hidden id="toFirstPage" onClick={toFirstPage}></button>
                {rows.length > 0 && (
                    <TableFooter style={{ float: 'right' }}>
                        <TablePagination
                            labelRowsPerPage={'Resultados por página'}
                            count={rowsLength}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            component="div"
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </TableFooter>
                )}
            </div>
        </Paper>
    );
};

BooksCollectionTable.propTypes = {
    rows: PropTypes.array.isRequired,
    headers: PropTypes.array.isRequired,
    visibleHeaders: PropTypes.array.isRequired,
    mainField: PropTypes.string.isRequired
};

export default BooksCollectionTable;
