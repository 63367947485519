
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Edit from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import history from "../../../../history";
import { useStyles } from "components/layout/CommonStyles";
import { GetGeneralPermissionForAction } from "utils/permissions/GetGeneralPermissionForAction";
import { Screen , Allow } from "global/constants";



const CollaboratorsVacationsTableAction = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const { item } = props;

    const verifyEdit = () => {
        history.push(`/CollaboratorsVacations/${item.id}/edit`);
    }

    const verifyView = () => {
        history.push(`/CollaboratorsVacations/${item.id}/view`);
    }

    return (
        <React.Fragment>
            {GetGeneralPermissionForAction(Screen.COLLABORATORS_VACATIONS, Allow.READ) &&
            <Tooltip title={t("GENERAL.view")} aria-label="details" placement="top">
                <IconButton className={classes.iconsTableActions} onClick={verifyView} >
                    <Visibility />
                </IconButton>
            </Tooltip>}
            {GetGeneralPermissionForAction(Screen.COLLABORATORS_VACATIONS, Allow.EDIT) && < Tooltip title={t("GENERAL.edit")} aria-label="edit" placement="top">
                <IconButton className={classes.iconsTableActions} onClick={verifyEdit} >
                    <Edit />
                </IconButton>
            </Tooltip>}
        </React.Fragment >
    )
};

export default CollaboratorsVacationsTableAction;