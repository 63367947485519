import {
    LibraryReservationStatusValues,
    LibraryExemplarStatusValues,
    LibraryHistoryExemplarsOperations,
    LibraryQueueStatusValues
} from 'global/constants';

export function translateStatus(status) {
    switch (status) {
        case LibraryReservationStatusValues.AWAITING_APPROVAL:
            return 'Aguardando Aprovação';
        case LibraryReservationStatusValues.AWAITING_WITHDRAWAL:
            return 'Aguardando Retirada';
        case LibraryReservationStatusValues.CANCELED_MANAGEMENT:
            return 'Cancelado';
        case LibraryReservationStatusValues.CANCELED_COLLABORATOR:
            return 'Cancelado';
        case LibraryReservationStatusValues.FINISHED:
            return 'Finalizado';
        case LibraryReservationStatusValues.LATE:
            return 'Atrasado';
        case LibraryReservationStatusValues.LOAN:
            return 'Emprestado';
        case LibraryQueueStatusValues.IN_QUEUE:
            return 'Fila de espera';
        default:
            return '';
    }
}

export function translateLoanStatus(reservationStatus) {
    switch (reservationStatus) {
        case LibraryReservationStatusValues.FINISHED:
            return 'Devolvido';
        case LibraryReservationStatusValues.CANCELED_MANAGEMENT:
            return 'Cancelado Gerência';
        case LibraryReservationStatusValues.CANCELED_COLLABORATOR:
            return 'Cancelado Colaborador';
        default:
            return translateStatus(reservationStatus);
    }
}

export function translateStatusExemplar(status) {
    switch (status) {
        case LibraryExemplarStatusValues.DISPONIBLE:
            return 'Disponível';
        case LibraryExemplarStatusValues.INDISPONIBLE:
            return 'Indisponível';
        case LibraryExemplarStatusValues.IN_LOAN_PROCESS:
            return 'Em processo de empréstimo';
        case LibraryExemplarStatusValues.LOANED:
            return 'Emprestado';
        case LibraryExemplarStatusValues.DONATED:
            return 'Doado';
        default:
            return '';
    }
}

export function translateHistoryExemplarOperations(status) {
    switch (status) {
        case LibraryHistoryExemplarsOperations.ADMINISTRATIVE_RESPONSIBLE:
            return 'Responsável Administrativo';
        case LibraryHistoryExemplarsOperations.DIRECT_RESPONSIBLE:
            return 'Responsável Direto';
        default:
            return '';
    }
}
