/* eslint-disable */
import SearchContext from "context/search/searchContext";
import { useStyles } from "components/layout/CommonStyles";
import React, { useContext, useEffect, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";

const SearchExecution = label => {
  const classes = useStyles();
  const searchContext = useContext(SearchContext);
  const { searchTermChanged, filter } = searchContext;

  useEffect(() => {
    return () => {
      searchTermChanged("");
    };
  }, []);

  const openwindow = () => {
    return renderOpenSearchBox();
  };

  const cleanPageToSearch = () => {
    document.getElementById("cleanPageToSearch").click();
  };

  const renderOpenSearchBox = () => {
    return (
      <React.Fragment>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder={label.label}
            value={filter}
            onFocus={cleanPageToSearch}
            onChange={e => searchTermChanged(e.target.value)}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
            inputProps={{ "aria-label": "search" }}
          />
        </div>
      </React.Fragment>
    );
  };
  return <div>{openwindow()}</div>;
};

export default SearchExecution;
