import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InfoIcon from '@material-ui/icons/Info';
import { Grid, Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import Buttons from 'components/buttons/Pressed';
import Typography from 'components/typography/Typography';
import Input from 'components/inputs/Input';

const DialogToolsConfirmation = props => {
    const [t] = useTranslation();

    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const {
        open,
        setOpen,
        type,
        title,
        text,
        buttonText,
        inputLabel,
        inputValue,
        mutation
    } = props;

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        mutation.mutate({});
    };

    const GridSpace = (
        <Grid item xs={1} style={{ margin: '0 auto' }}>
            <Box display={'flex'} alignItems={'center'} minHeight="100%"></Box>
        </Grid>
    );

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle>
                <Grid container item spacing={2} xs={12}>
                    <Grid item xs={11}>
                        <Typography
                            variant={'RobotoBold'}
                            fontSize={24}
                            textColor={'secondary'}
                        >
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            color="#6D6F71"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent>
                <Grid
                    container
                    spacing={1}
                    xs={12}
                    style={{ margin: '0 auto' }}
                >
                    <Grid item xs={1} style={{ margin: '0 auto' }}>
                        <Box
                            display={'flex'}
                            alignItems={'center'}
                            minHeight="100%"
                        >
                            <InfoIcon color="primary" />
                        </Box>
                    </Grid>
                    <Grid item xs={11} style={{ margin: '0 auto' }}>
                        <Typography
                            variant={'RobotoRegular'}
                            fontSize={14}
                            textColor={'primary'}
                        >
                            {text}
                        </Typography>
                    </Grid>

                    {inputValue === 0 ? (
                        <>
                            {GridSpace}
                            <Grid item xs={11} style={{ margin: '0 auto' }}>
                                <Typography
                                    variant={'RobotoBold'}
                                    fontSize={14}
                                    textColor={'primary'}
                                >
                                    {t(
                                        'QUOTAS.dialogToolsConfirmationsNoPending'
                                    )}
                                </Typography>
                            </Grid>
                        </>
                    ) : null}

                    {GridSpace}
                    <Grid style={{ margin: '0 auto' }} item xs={11}>
                        <Input
                            context={'view'}
                            label={inputLabel}
                            value={inputValue}
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions style={{ padding: '24px 30px' }}>
                <Buttons
                    onClick={handleClose}
                    tipo={'BN-noback'}
                    conteudo={t('GENERAL.close').toUpperCase()}
                />
                <Buttons
                    disabled={inputValue === 0}
                    isLoading={mutation.isLoading}
                    onClick={handleSubmit}
                    tipo="BN-blue"
                    conteudo={buttonText}
                />
            </DialogActions>
        </Dialog>
    );
};

export default DialogToolsConfirmation;
