import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CloseIcon from '@material-ui/icons/Close';
import Buttons from "components/buttons/Pressed";
import { useStyles } from "components/layout/CommonStyles";
import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";

const EditresponsibleDialog = (props) => {
    const [t] = useTranslation();
    const classes = useStyles();
    const { open, setOpen, currentType, currentResponsible, respName, valueIntern, valueExtern, valuePlace, description, handleUpdate, allResponsible, allExternalResponsible, places } = props;
    const [radioValue, setRadioValue] = useState("Interno");
    const [descriptionR, setDescription] = useState("");
    const [values, setValues] = useState({responsibleId: "", responsibleName: "" , placeId: "", externalResponsibleId: "" });
   
    useEffect(() => {
        setDescription(description);
    }, [description]);

    const [requiredName, setRequiredName] = useState(false);
    const [requiredLocal, setRequiredLocal] = useState(false);
    const [typeInternName, setTypeInternName] = useState(false);

    const handleClose = () => {
        if(respName !== values.responsibleName){
           setValues({responsibleName: respName, responsibleId: valueIntern, externalResponsible: respName, externalResponsibleId: valueExtern, placeId: valuePlace });
        }
        if(valueIntern){
            setValues({placeId: "" });
         }
        setOpen(false);
    };

    const handleSubmit = () => {

        let canSubmit = true;

        if (radioValue === "Interno" && !values.responsibleId) {
            setTypeInternName(true);
            canSubmit = false;
        }

        if (radioValue === "Externo") {
            if (!values.externalResponsibleId) {
                setRequiredName(true);
                canSubmit = false;
            }

            if (!values.placeId) {
                setRequiredLocal(true);
                canSubmit = false;
            }
        }

        if (!canSubmit) {
           return;
        }

        handleUpdate(
            values.responsibleId,
            values.externalResponsibleId,
            descriptionR,
            values.placeId,
            values.responsibleName
        );
        setOpen(false);
    }

    const handleChangeRadio = (event) => {
        setRadioValue(event.target.value);
        if(valueIntern){
            setValues({responsibleName: respName, responsibleId: valueIntern,  placeId: "" });
         }
    };

    const handleChange = (name, value) => {
        if (name === "externalResponsibleId") {
            const externalUsers = allExternalResponsible.find(x => x.value === value);
            setValues({ ...values, [name]: value, "responsibleId": "", responsibleName: externalUsers.label });
        } else if (name === "responsibleId") {
            const allUsers = allResponsible.find(x => x.value === value);
            setValues({ ...values, [name]: value, "externalResponsibleId": "", responsibleName: allUsers.label });
        } else {
            setValues({ ...values, [name]: value });
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            aria-labelledby="max-width-dialog-title"
            aria-labelledby="form-dialog-title"
            msg="mensagem"
            maxWidth="sm"
        >
            <DialogTitle className={classes.actionsTitle} style={{ width: 'auto' }}>
                <span style={{ fontWeight: 'bold' }}>
                    {t("EXTERNALUSERS.changeResponsible")}
                </span>
                <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Grid item xs={12} sm={12}>
                        <Typography className={classes.titleNewEquipment}>
                            Responsável Atual
                            </Typography>
                    </Grid>
                    <Grid container spacing={2} style={{ paddingTop: "20px" }}>
                        <Grid item xs={12} sm={6}>
                          <FormLabel component="legend">{t("EXTERNALUSERS.userType")}</FormLabel>
                            <TextField
                                margin="normal"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput
                                }}
                                value={currentType ? currentType : "-"}
                                className={classes.textField}
                                inputProps={{ "aria-label": "currentValue" }}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormLabel component="legend">{t("EXTERNALUSERS.name")}</FormLabel>
                            <TextField
                                margin="normal"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput
                                }}
                                value={currentResponsible ? currentResponsible : "-"}
                                className={classes.textField}
                                inputProps={{ "aria-label": "currentValue" }}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography className={classes.titleNewEquipment}>
                                Novo Responsável
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">{t("EXTERNALUSERS.userType")+"*"}</FormLabel>
                                <RadioGroup row aria-label="position"
                                    name="position"
                                    defaultValue="top"
                                    value={radioValue}
                                    onChange={handleChangeRadio}
                                >
                                    <FormControlLabel
                                        value="Interno"
                                        control={<Radio color="primary" />}
                                        label="Interno"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="Externo"
                                        control={<Radio color="primary" />}
                                        label="Externo"
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {radioValue === "Externo" ?
                            <React.Fragment>
                                <Grid item xs={6} sm={6}>
                                    <FormLabel component="legend">{t("EXTERNALUSERS.name")+"*"}</FormLabel>
                                    <Tooltip
                                        TransitionComponent={Zoom}
                                        open={requiredName}
                                        title={t("GENERAL.requiredField")}
                                     >
                                        <TextField
                                            style={{ marginTop: "-5px" }}
                                            select
                                            name="externalResponsibleId"
                                            className={classes.textField}
                                            value={values.externalResponsibleId}
                                            onChange={(ev) => {
                                                setRequiredName(false);
                                                handleChange("externalResponsibleId", ev.target.value)
                                            }}
                                            margin="normal"
                                            classes={{
                                                root: classes.inputRoot,
                                                input: classes.inputInput
                                            }}
                                            inputProps={{ "aria-label": "search" }}
                                        >
                                            {allExternalResponsible.map(externalResponsible => (
                                                <MenuItem key={externalResponsible.value} value={externalResponsible.value} label={externalResponsible.label}>
                                                    {externalResponsible.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <FormLabel component="legend">{t("EXTERNALUSERS.place")+"*"}</FormLabel>
                                    <Tooltip
                                        TransitionComponent={Zoom}
                                        open={requiredLocal}
                                        title={t("GENERAL.requiredField")}
                                     >
                                        <TextField
                                            style={{ marginTop: "-5px" }}
                                            select
                                            name="placeId"
                                            className={classes.textField}
                                            value={values.placeId}
                                            onChange={(ev) => {
                                                setRequiredLocal(false);
                                                handleChange("placeId", ev.target.value)
                                            }}
                                            margin="normal"
                                            classes={{
                                                root: classes.inputRoot,
                                                input: classes.inputInput
                                            }}
                                            inputProps={{ "aria-label": "search" }}
                                        >
                                            {places.map(status => (
                                                <MenuItem key={status.value} value={status.value}>
                                                    {status.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Tooltip>
                                </Grid>
                            </React.Fragment>
                            :
                            <Grid item xs={6} sm={6}>
                                <FormLabel component="legend">{t("EXTERNALUSERS.name")+"*"}</FormLabel>
                                <Tooltip
                                    TransitionComponent={Zoom}
                                    open={typeInternName}
                                    title={t("GENERAL.requiredField")}
                                >
                                    <TextField
                                        style={{ marginTop: "-5px" }}
                                        select
                                        name="responsibleId"
                                        className={classes.textField}
                                        value={values.responsibleId}
                                        onChange={(ev) => {
                                            setTypeInternName(false);
                                            handleChange("responsibleId", ev.target.value)
                                        }}
                                        margin="normal"
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputInput
                                        }}
                                        inputProps={{ "aria-label": "search" }}
                                    >
                                        {allResponsible.map(responsible => (
                                            <MenuItem key={responsible.value} value={responsible.value}>
                                                {responsible.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Tooltip>
                            </Grid>
                        }
                        <Grid item xs={12} sm={12}>
                            <TextField
                                multiline
                                fullWidth
                                variant="outlined"
                                rows="4"
                                value={descriptionR}
                                onChange={(ev) => { if (ev.target.value.length <= 200) setDescription(ev.target.value)}}
                                label={t("INVENTORY.descriptionBookCategory")}
                                margin="normal"
                            />
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Buttons
                    onClick={handleClose}
                    tipo="BN-noback"
                    conteudo={t("GENERAL.cancel").toLocaleUpperCase()}
                />
                <Buttons
                    onClick={handleSubmit}
                    tipo="BN-blue"
                    conteudo={t("GENERAL.confirm").toLocaleUpperCase()}
                />
            </DialogActions>
        </Dialog>
    );
}

export default EditresponsibleDialog;