import { DEFAULT, HR } from "../../types"

export default (state, action) => {
    switch (action.type) {
        case DEFAULT.CREATE_UPDATE_DELETE:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case DEFAULT.SET_LOADING:
            return {
                ...state,
                loading: true
            };
        case DEFAULT.SET_LOADING_FALSE:
            return {
                ...state,
                loading: false
            };
        case DEFAULT.RESET_MESSAGE:
            return {
                ...state,
                response: "",
                responseType: ""
            };
        case DEFAULT.SET_HAS_CHANGED:
            return {
                ...state,
                hasChanged: 0
            };
        case HR.GET_ADMISSION_JOBS:
            return {
                ...state,
                jobs: action.payload
            }
        case HR.CREATE_JOB:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case HR.UPDATE_JOB:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case HR.GET_JOB:
            return {
                ...state,
                job: action.payload
            };
        case HR.DELETE_JOB:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case HR.GET_HOLIDAYS_AND_BREAKS:
            return {
                ...state,
                holidaysAndBreaks: action.payload
            };
        case HR.CREATE_HOLIDAYS_AND_BREAKS:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case HR.GET_HOLIDAY_OR_BREAK:
            return {
                ...state,
                holidayOrBreak: action.payload
            };
        case HR.UPDATE_HOLIDAY_OR_BREAK:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case HR.DELETE_HOLIDAYS_AND_BREAKS:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case HR.CREATE_PROFILE:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case HR.GET_ROLE_MODULES:
            return {
                ...state,
                roleModules: action.payload
            };
        case HR.GET_PROFILE:
            return {
                ...state,
                profile: action.payload
            };
        case HR.UPDATE_PROFILE:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case HR.GET_ALLOWANCE_REASON:
            return {
                ...state,
                allowanceReason: action.payload
            };
        case HR.GET_ALL_ALLOWANCE_REASONS:
            return {
                ...state,
                allowanceReasons: action.payload
            };
        case HR.UPDATE_ALLOWANCE_REASON:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case HR.DELETE_ALLOWANCE_REASON:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };
        case HR.DELETE_PROFILE_MANAGEMENT:
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            };

        case HR.GET_ALL_ACTIVITY_CATEGORIES:
            return {
                ...state,
                activityCategories: action.payload
            };
        case HR.CREATE_ACTIVITY_CATEGORIES: 
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            }
        case HR.UPDATE_ACTIVITY_CATEGORIES: 
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            }
        case HR.DELETE_ACTIVITY_CATEGORIES: 
            return {
                ...state,
                response: action.payload,
                responseType: action.responseType,
                successiveActions: action.successiveActions,
                hasChanged: action.hasChanged
            }
        default:
            return state;
    }
}