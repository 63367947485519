/* eslint-disable */
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import GetApp from "@material-ui/icons/GetApp";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import SearchIcon from "@material-ui/icons/Search";
import Dissatisfied from "@material-ui/icons/SentimentVeryDissatisfied";
import Footer from "components/layout/Footer";
import Spinner from "components/layout/spinners/Index";
import AllowancesContext from "context/allowance/allowanceContext";
import InventoryContext from "context/inventory/inventoryContext";
import React, { useContext, useEffect, useState, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { animated, useTransition } from "react-spring";
import { useStyles } from "components/layout/CommonStyles";
import MySnackbarContentWrapper from "components/layout/Message";
import AdvancedSearchDialog from './AdvancedSearchDialog';
import SoftwareTable from "./SoftwareTable";
import { Link } from "react-router-dom";
import DialogExport from './DialogExport';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { GetGeneralPermissionForAction } from "utils/permissions/GetGeneralPermissionForAction";
import { Screen, Allow } from "global/constants";

const Software = (props) => {
    const classes = useStyles();
    const [t] = useTranslation();
    const inventoryContext = useContext(InventoryContext);
    const allowanceContext = useContext(AllowancesContext);
    const [openAdvancedSearch, setOpenAdvancedSearch] = useState(false);
    const [openExport, setOpenExport] = useState(false);
    const [categoryArray, setCategoryArray] = useState([]);
    const [open, setOpen] = React.useState(false);

    const [snackBar, setSnackBar] = useState({
        open: false,
        kind: "",
        content: ""
    });

    const [findText, setFindText] = useState("");
    const [filterName, setFilterName] = useState("");
    const [filterCategory, setFilterCategory] = useState("");
    const [filterStatus, setFilterStatus] = useState("");

    const [filterCode, setFilterCode] = useState("");
    const [filterKey, setFilterKey] = useState("");
    const [filterPlatform, setFilterPlatform] = useState("");
    const [filterAcquisitionDate, setFilterAcquisitionDate] = useState("");
    const [filterActivationDate, setFilterActivationDate] = useState("");
    const [filterValidity, setFilterValidity] = useState("");
    const [filterType, setFilterType] = useState("");
    const [filterResponsible, setFilterResponsible] = useState("");
    const [filterEmail, setFilterEmail] = useState("");
    const [filterMachine, setFilterMachine] = useState("");
    const [dataFilter, setDataFilter] = useState([]);

    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);

    const [canClick, setCanClick] = useState(false);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }

    const status = [
        {
            value: "AVAILABLE",
            label: "Disponível"
        },
        {
            value: "UNAVAILABLE",
            label: "Indisponível"
        },
        {
            value: "EXPIRED",
            label: "Expirada"
        },
    ];

    const addContext = [
        {
            value: "newSoftware",
            label: "Adicionar Software"
        },
        {
            value: "newLicense",
            label: "Adicionar Licença"
        },
    ]

    const {
        loadingSoftware,
        response,
        hasChanged,
        setHasChanged,
        responseType,
        successiveActions,
        getSoftwares,
        softwares,
        getSoftwareCategories,
        softwareCategories,
        searchLinkSoftware
    } = inventoryContext;

    const {
        allowanceResponse,
        allowanceResponseType,
        messageHasChanged,
        resetAllowanceMessage,
        permission
    } = allowanceContext;

    useEffect(() => {
        if (softwares && softwares.length) {
            const { newDataFilter, textNoFound } = applyFilter(softwares)

            if (newDataFilter.length === 0) {
                setDataFilter([]);
                setHasNoResult(true);
                setFindText(textNoFound);
            } else {
                setDataFilter(newDataFilter);
                setHasNoResult(false);
                setFindText("");
            }
        }
    }, [softwares, filterEmail, filterCode, filterKey, filterResponsible, filterActivationDate, filterPlatform, filterName, filterCategory, filterStatus, filterAcquisitionDate, filterMachine, filterType, filterValidity]);

    const applyFilter = (softwares) => {
        let newDataFilter = [];
        let textNoFound = "";

        newDataFilter = softwares.filter(
            item =>
                item.hasdeleted == false
        );

        if (filterName) {
            newDataFilter = newDataFilter.filter(item =>
                item.name.toLowerCase().startsWith(filterName.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0) textNoFound = filterName;
        }
        if (filterCategory && filterCategory.toLowerCase() !== "todas categorias") {
            newDataFilter = newDataFilter.filter(item =>
                item.category && item.category.toLowerCase() === (filterCategory.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0) textNoFound = filterCategory;
        }

        if (filterStatus) {
            let filter = ""
            if (filterStatus == "Disponível") {
                filter = "AVAILABLE"
            } else if (filterStatus == "Indisponível") {
                filter = "UNAVAILABLE"
            } else (
                filter = "EXPIRED"
            )
            newDataFilter = newDataFilter.filter(item =>
                item.inventoryLicense.some(obj => obj.status && obj.status.toLowerCase() === (filter.toLowerCase()))
            );

            newDataFilter = filterLicense(newDataFilter, "status", filter)

            if (!textNoFound && newDataFilter.length === 0) textNoFound = filter;
        }

        if (filterCode) {
            newDataFilter = newDataFilter.filter(item =>
                item.inventoryLicense.some(obj => obj.codigo && obj.codigo == filterCode)
            );

            // newDataFilter = newDataFilter.map(obj1 => ({...obj1, inventoryLicense: obj1.inventoryLicense.filter(obj2 => obj2.codigo && obj2.codigo.toString() === (filterCode))}))
            newDataFilter = filterLicense(newDataFilter, "codigo", filterCode)
            if (!textNoFound && newDataFilter.length === 0) textNoFound = filterCode;
        }

        if (filterKey) {
            newDataFilter = newDataFilter.filter(item =>
                item.inventoryLicense.some(obj => obj.key && obj.key.toLowerCase().startsWith(filterKey.toLowerCase()))
            );
            newDataFilter = filterLicense(newDataFilter, "key", filterKey)

            if (!textNoFound && newDataFilter.length === 0) textNoFound = filterKey;
        }

        if (filterMachine) {
            newDataFilter = newDataFilter.filter(item =>
                item.inventoryLicense.some(obj => obj.machine && obj.machine.toLowerCase().startsWith(filterMachine.toLowerCase()))
            );

            newDataFilter = filterLicense(newDataFilter, "machine", filterMachine)

            if (!textNoFound && newDataFilter.length === 0) textNoFound = filterMachine;
        }

        if (filterPlatform) {
            newDataFilter = newDataFilter.filter(item =>
                item.inventoryLicense.some(obj => obj.platform && obj.platform.toLowerCase().startsWith(filterPlatform.toLowerCase()))
            );

            newDataFilter = filterLicense(newDataFilter, "platform", filterPlatform)

            if (!textNoFound && newDataFilter.length === 0) textNoFound = filterPlatform;
        }

        if (filterResponsible) {
            newDataFilter = newDataFilter.filter(item =>
                item.inventoryLicense.some(obj => obj.responsible && obj.responsible.toLowerCase().startsWith(filterResponsible.toLowerCase()))
            );

            newDataFilter = filterLicense(newDataFilter, "responsible", filterResponsible)

            if (!textNoFound && newDataFilter.length === 0) textNoFound = filterResponsible;
        }

        if (filterType) {
            newDataFilter = newDataFilter.filter(item =>
                item.inventoryLicense.some(obj => obj.licenseType && obj.licenseType.toLowerCase().startsWith(filterType.toLowerCase()))
            );

            newDataFilter = filterLicense(newDataFilter, "licenseType", filterType)

            if (!textNoFound && newDataFilter.length === 0) textNoFound = filterType;
        }

        if (filterAcquisitionDate) {
            newDataFilter = newDataFilter.filter(
                item =>
                    item.inventoryLicense.some(obj => obj.acquisitionDate && dateToBR(obj.acquisitionDate).toLowerCase().startsWith(dateToBR(filterAcquisitionDate).toLowerCase()))
            );

            newDataFilter = filterLicense(newDataFilter, "acquisitionDate", filterAcquisitionDate)

            if (!textNoFound && newDataFilter.length === 0) textNoFound = dateToBR(filterAcquisitionDate);
        }

        if (filterActivationDate) {
            newDataFilter = newDataFilter.filter(
                item =>
                    item.inventoryLicense.some(obj => obj.activationDate && dateToBR(obj.activationDate).toLowerCase().startsWith(dateToBR(filterActivationDate).toLowerCase()))
            );

            newDataFilter = filterLicense(newDataFilter, "activationDate", filterActivationDate)

            if (!textNoFound && newDataFilter.length === 0) textNoFound = dateToBR(filterActivationDate);
        }

        if (filterValidity) {
            newDataFilter = newDataFilter.filter(
                item =>
                    item.inventoryLicense.some(obj => obj.expirationDate && dateToBR(obj.expirationDate).toLowerCase().startsWith(dateToBR(filterValidity).toLowerCase()))
            );

            newDataFilter = filterLicense(newDataFilter, "expirationDate", filterValidity)


            if (!textNoFound && newDataFilter.length === 0) textNoFound = dateToBR(filterValidity);
        }

        if (filterEmail) {
            newDataFilter = newDataFilter.filter(
                item =>
                    item.inventoryLicense.some(obj => obj.email && obj.email.toLowerCase().startsWith(filterEmail.toLowerCase()))
            )

            newDataFilter = filterLicense(newDataFilter, "email", filterEmail)


            if (!textNoFound && newDataFilter.length === 0) textNoFound = filterEmail;
        }

        return { newDataFilter, textNoFound };
    }

    useEffect(() => {
        if (filterValidity ||
            filterActivationDate ||
            filterAcquisitionDate ||
            filterType ||
            filterResponsible ||
            filterPlatform ||
            filterMachine ||
            filterKey ||
            filterCode ||
            filterCategory ||
            filterStatus ||
            filterName ||
            filterEmail
        ) { setCanClick(true) }
        else {
            setCanClick(false)
        }
    }, [
        filterValidity,
        filterActivationDate,
        filterAcquisitionDate,
        filterType,
        filterResponsible,
        filterPlatform,
        filterMachine,
        filterKey,
        filterCode,
        filterCategory,
        filterStatus,
        filterName,
        filterEmail
    ])

    const hasItens = softwares.length > 0;
    const [hasNoResult, setHasNoResult] = useState(hasItens && dataFilter.length === 0);

    const transitions = useTransition(loadingSoftware, null, {
        from: { opacity: 0 },
        enter: {
            opacity: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "calc(100vh - 70px)"
        },
        leave: { opacity: 0 }
    });

    useEffect(() => {
        getSoftwareCategories();
    }, []);

    useEffect(() => {
        var name = searchLinkSoftware.replace('?&', '')
        setFilterName(name);

        if (allowanceResponseType === "ERROR" && messageHasChanged) {
            handleSnack({ kind: "error", content: allowanceResponse });
        }

        if (responseType === "SUCCESS" && hasChanged > successiveActions) {
            handleSnack({ kind: "success", content: response });
        }
        if (responseType === "ERROR" && hasChanged > successiveActions) {
            handleSnack({ kind: "error", content: response });
        }

        return () => { };
    }, [
        responseType,
        response,
        successiveActions,
        allowanceResponseType,
        allowanceResponse,
    ]);

    useEffect(() => {
        getSoftwares()
    }, [
        softwares.length
    ]);


    useEffect(() => {
        const Categories = [...softwareCategories];
        Categories.sort(function (a, b) {
            if (a.name === null) a.name = "";
            if (b.name === null) b.name = "";

            if (a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() >
                b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim()) {
                return 1;
            }
            if (a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() <
                b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim()) {
                return -1;
            }
            // a must be equal to b
            return 0;
        });

        var aux = {};
        var allSoftwareCategories = {};
        var _softwareCategories = [];
        allSoftwareCategories.value = "all";
        allSoftwareCategories.label = "Todas categorias";

        _softwareCategories.push(allSoftwareCategories);
        for (var i = 0; i < Categories.length; i++) {
            var item = Categories[i];
            aux.value = item.id;
            aux.label = item.name;
            _softwareCategories.push(aux);
            aux = {};
        }
        setCategoryArray(_softwareCategories);
    }, [softwareCategories]);

    const handleSnack = () => {
        setSnackBar({ ...snackBar, open: true });
        setHasChanged();
    };
    const handleSnackClose = () => {
        setSnackBar({ ...snackBar, open: false });
        resetAllowanceMessage();
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const advancedSearch = (name, category, status, code, key, email, machine, type, validity, acquisitionDate, activationDate, platform, responsible) => {
        setFilterName(name);
        setFilterCategory(category);
        setFilterStatus(status);
        setFilterCode(code);
        setFilterKey(key);
        setFilterPlatform(platform);
        setFilterAcquisitionDate(acquisitionDate);
        setFilterActivationDate(activationDate);
        setFilterValidity(validity);
        setFilterType(type);
        setFilterResponsible(responsible);
        setFilterMachine(machine);
        setFilterEmail(email);
    }

    function dateToBR(date) {
        var data = new Date(date),
            dia = data.getDate().toString(),
            diaF = (dia.length == 1) ? '0' + dia : dia,
            mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
            mesF = (mes.length == 1) ? '0' + mes : mes,
            anoF = data.getFullYear();
        return diaF + "/" + mesF + "/" + anoF;
    }

    const filterLicense = (newData, field, filter) => {
        if (field === "acquisitionDate" || field === "activationDate" || field === "expirationDate") {
            return newData.map(obj1 => ({ ...obj1, inventoryLicense: obj1.inventoryLicense.filter(obj2 => obj2[field] && dateToBR(new Date(obj2[field])).toString() === (dateToBR(filter).toString())) }))
        } else {
            return newData.map(obj1 => ({ ...obj1, inventoryLicense: obj1.inventoryLicense.filter(obj2 => obj2[field] && obj2[field].toString() === (filter)) }))
        }
    }

    return transitions.map(({ item, key, props }) =>
        <React.Fragment>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={snackBar.open}
                onClose={handleSnackClose}
                TransitionComponent={Slide}
                transitionDuration={{ enter: 500, exit: 500 }}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                autoHideDuration={2000}
            >
                <MySnackbarContentWrapper
                    onClose={handleSnackClose}
                    variant={
                        responseType !== ""
                            ? responseType.toLowerCase()
                            : allowanceResponseType.toLowerCase()
                    }
                    message={
                        <span id="message-id">
                            {response !== "" ? response : allowanceResponse}
                        </span>
                    }
                />
            </Snackbar>
            <div className="container">
                <Breadcrumbs
                    className={classes.breadCrumbs}
                    aria-label="breadcrumb"
                    separator={<NavigateNextIcon fontSize="small" />}
                >
                    <Typography>{t("INVENTORY.inventory")}</Typography>
                    <Typography>{t("INVENTORY.softwares")}</Typography>
                </Breadcrumbs>
                <div className={classes.containerHeader}>
                    <div className="title">
                        <h1 className={classes.title}>{t("INVENTORY.softwares")}</h1>
                    </div>
                    <div>
                        {GetGeneralPermissionForAction(Screen.SOFTWARES, Allow.CREATE) &&
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleToggle}
                                aria-controls={open ? 'split-button-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-label="select merge strategy"
                                aria-haspopup="menu"
                            >
                                {t("INVENTORY.add")}
                                <ArrowDropDownIcon />

                                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{ position: "absolute", marginTop: "40px", marginLeft: -30 }}>
                                    {({ TransitionProps, placement }) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{
                                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                                            }}
                                        >
                                            <Paper style={{ zIndex: 1, position: "relative" }}>
                                                <ClickAwayListener onClickAway={handleClose}>
                                                    <MenuList id="split-button-menu">
                                                        {addContext.map((option, index) => (
                                                            <Link to={"/Software/" + option.value}>
                                                                <MenuItem key={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            </Link>
                                                        ))}
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </Button>
                        }
                    </div>
                </div>
                <Paper >
                    <div className="cabecalho">
                        {t("INVENTORY.headerSoftware").toUpperCase()}
                        <span style={{ float: "right", marginRight: 10 }}>
                            <span style={{ float: "right", marginRight: 10 }}>{t("INVENTORY.export").toUpperCase()}
                                <IconButton className="btn" style={{ float: "right" }} edge="end" color="inherit" onClick={() => setOpenExport(true)}>
                                    <GetApp />
                                </IconButton>
                            </span>
                        </span>
                    </div>
                    {item ? (
                        <animated.div key={key} style={props}>
                            <Spinner />;
                        </animated.div>
                    ) : (
                        <React.Fragment>
                            <div className={classes.divAddParticipantLeft}>
                                <Toolbar className={classes.searchToolbarInventory}>
                                    <div style={{ display: !hasItens ? "none" : "" }} className={classes.searchInventory}>
                                        <div className={classes.searchIcon}>
                                            <SearchIcon />
                                        </div>
                                        <InputBase
                                            placeholder={t("INVENTORY.name")}
                                            value={filterName}
                                            onChange={(ev) => setFilterName(ev.target.value)}
                                            classes={{
                                                root: classes.inputRoot,
                                                input: classes.inputInput
                                            }}
                                            inputProps={{ "aria-label": "search" }}
                                        />
                                    </div>
                                </Toolbar>
                                <Toolbar className={classes.searchToolbarInventory} >
                                    <div style={{ display: !hasItens ? "none" : "" }} className={classes.searchInventory} >
                                        <TextField
                                            select
                                            id="searchcategory"
                                            label={t("INVENTORY.category")}
                                            name="searchcategory"
                                            style={{ minWidth: "250px", marginTop: 0, marginBottom: 0, marginLeft: 5 }}
                                            value={filterCategory}
                                            className={classes.textField}
                                            onChange={(ev) => setFilterCategory(ev.target.value)}
                                            margin="normal"
                                            classes={{
                                                root: classes.inputRoot,
                                                input: classes.inputSearch
                                            }}
                                            inputProps={{ "aria-label": "search" }}
                                        >
                                            {categoryArray.map(category => (
                                                <MenuItem key={category.value} value={category.label}>
                                                    {category.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                </Toolbar>
                                <Toolbar className={classes.searchToolbarInventory} >
                                    <div style={{ display: !hasItens ? "none" : "" }} className={classes.searchInventory} >
                                        <TextField
                                            select
                                            id="searchcategory"
                                            label={t("INVENTORY.status")}
                                            name="searchcategory"
                                            style={{ minWidth: "250px", marginTop: 0, marginBottom: 0, marginLeft: 5 }}
                                            value={filterStatus}
                                            className={classes.textField}
                                            onChange={(ev) => setFilterStatus(ev.target.value)}
                                            margin="normal"
                                            classes={{
                                                root: classes.inputRoot,
                                                input: classes.inputSearch
                                            }}
                                            inputProps={{ "aria-label": "search" }}
                                        >
                                            {status.map(category => (
                                                <MenuItem key={category.value} value={category.label}>
                                                    {category.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                </Toolbar>
                                <Toolbar className={classes.searchToolbarInventory} >
                                    <div style={{ display: !hasItens ? "none" : "" }} >
                                        <Button
                                            style={{ margin: "22px", padding: "15px" }}
                                            onClick={() => setOpenAdvancedSearch(true)}
                                        >
                                            {t("INVENTORY.advancedSearch")}
                                        </Button>
                                    </div>
                                </Toolbar>
                            </div>
                            <SoftwareTable
                                rows={dataFilter}
                                toFirstPage={canClick}
                                headers={["id", "name", "category", "manufacturer", "inventoryLicense", "softwareCategoryId", "type", "url", "version", "versionName"]}
                                visibleHeaders={[" ", "Nome do Software", "Categoria", "Desenvolvido por", ""]}
                                sortedFields={[" ", "name", "category", "manufacturer"]}
                                mainField="name"
                                permission={permission}
                            />

                            {!hasItens &&
                                <div className={classes.noParticipantsMessage}>
                                    <h2>{t("INVENTORY.noItensMessage")}</h2>
                                    <h4>{t("INVENTORY.noItensSubMessage")}</h4>
                                </div>
                            }
                            {hasNoResult &&
                                <div className={classes.noParticipantsMessage}>
                                    <div >
                                        <Dissatisfied className={classes.notFoundIconStyle} />
                                    </div>
                                    <h2>{t("GENERAL.noResultsFound")}</h2>
                                    <h5>{`Sua busca por "${findText}" não retornou resultados.`}</h5>
                                </div>
                            }
                        </React.Fragment>
                    )}
                </Paper>
            </div>
            <Footer />
            <DialogExport title={"Exportar Softwares"} maxFieldsColumn={4} open={openExport} setOpen={setOpenExport} data={dataFilter} />
            <AdvancedSearchDialog open={openAdvancedSearch} setOpen={setOpenAdvancedSearch} apply={advancedSearch} />
        </React.Fragment>
    );
};

export default Software;