import i18n from 'i18n';

export const CollaboratorsJustificationsActions = {
    APPROVE: 'APPROVE',
    REFUSE: 'REFUSE'
};

export const JustificationsStatusArray = [
    {
        value: 'ALL',
        label: i18n.t('GENERAL.allMasculine')
    },
    {
        value: 'PENDING',
        label: i18n.t('GENERAL.awaitingAvaliation')
    },
    {
        value: 'ALLOWED',
        label: i18n.t('GENERAL.approved')
    },
    {
        value: 'DENIED',
        label: i18n.t('GENERAL.denied')
    }
];

export const JustificationsTypeArray = [
    {
        value: 'ALL',
        label: i18n.t('GENERAL.allMasculine'),
        isValidJustification: false
    },
    {
        value: 'partial_payment',
        label: i18n.t('HR.partialPayment'),
        isValidJustification: true
    },
    {
        value: 'total_payment',
        label: i18n.t('HR.totalPayment'),
        isValidJustification: true
    },
    {
        value: 'clock_record_change',
        label: i18n.t('HR.clockRecordChange'),
        isValidJustification: false
    },
    {
        value: 'overtime',
        label: i18n.t('HR.overtime'),
        isValidJustification: false
    },
    {
        value: 'inconsistency_justification',
        label: i18n.t('HR.inconsistencyJustification'),
        isValidJustification: true
    }
];

export const JustificationType = {
    INCONSISTENCY_JUSTIFICATION: 'inconsistency_justification',
    PARTIAL_PAYMENT: 'partial_payment',
    TOTAL_PAYMENT: 'total_payment'
};

export function GetJustificationStatus(status) {
    switch (status) {
        case 'ALLOWED':
            return i18n.t('GENERAL.approved');
        case 'PENDING':
            return i18n.t('GENERAL.awaitingAvaliation');
        case 'DENIED':
            return i18n.t('GENERAL.denied');
        default:
            break;
    }
}

export function GetJustificationType(type) {
    switch (type) {
        case 'partial_payment':
            return i18n.t('HR.partialPayment');
        case 'total_payment':
            return i18n.t('HR.totalPayment');
        case 'clock_record_change':
            return i18n.t('HR.clockRecordChange');
        case 'overtime':
            return i18n.t('HR.overtime');
        case 'inconsistency_justification':
            return i18n.t('HR.inconsistencyJustification');
        default:
            break;
    }
}
