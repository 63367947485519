import { makeStyles } from '@material-ui/core/styles'

export const screenStyles = makeStyles(theme => ({
    bookList: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
        rowGap: '50px',
        columnGap: '20px',
        padding: '48px 24px'
    },
    paper: {
        maxHeight: 700,
        overflow: 'auto'
    },
    bookCollectionHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        paddingRight: '18px',
        color: '#6D6F71',
        opacity: '1 !important'
    },
    iconsWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    }
}))
