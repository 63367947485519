import React from "react";
import Switch from "@material-ui/core/Switch";

const InputSwitch = ({ context, value, valueName, handleChange, helperText, error, setRequired, ...props }) => {

    return (
        <Switch
            name={valueName}
            color='primary'
            disabled={context === "view"}
            error={error}
            checked={value}
            helperText={helperText}
            onChange={(ev) => {
                handleChange(valueName, ev.target.checked)
                if (typeof setRequired === "function") {
                    setRequired(false);
                }
            }}
            {...props}
        />
    )
}

export default InputSwitch;
