/* eslint-disable */
import CollaboratorContext from 'context/hrModule/collaborator/collaboratorContext';
import React, { useContext, useEffect, useState } from 'react';
import history from 'history.js';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Transition from 'components/layout/transition/Transition';
import Input from 'components/inputs/Input';
import Buttons from 'components/buttons/Pressed';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { TestTubeEmpty } from 'mdi-material-ui';
import { emailValidation } from 'utils/validation/EmailValidation';
import DialogCancelOperation from 'components/dialogs/DialogCancelOperation';
import Cookies from 'js-cookie';
import { cepMask } from 'utils/masks/cepMask';
import { phoneMask } from 'utils/masks/phoneMask';

const ContactForm = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const collaboratorContext = useContext(CollaboratorContext);
    const { id, userId, context, ...others } = props;
    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const {
        loading,
        setLoading,
        setLoadingFalse,
        informationContact,
        getInformationContact,
        updateInformationContact,
        addInformationContact
    } = collaboratorContext;

    const [requiredEmail, setRequiredEmail] = useState(false);
    const [requiredAddress, setRequiredAddress] = useState(false);
    const [requiredNumber, setRequiredNumber] = useState(false);
    const [requiredNeighborhood, setRequiredNeighborhood] = useState(false);
    const [requiredCity, setRequiredCity] = useState(false);
    const [requiredState, setRequiredState] = useState(false);
    const [messageFieldEmail, setMessageFieldEmail] = useState(
        'GENERAL.requiredField'
    );
    const [hasChange, setHasChange] = useState(false);

    const [values, setValues] = useState({
        id: '',
        email: '',
        phone: '',
        cell: '',
        cep: '',
        address: '',
        number: 0,
        neighborhood: '',
        city: '',
        state: '',
        complement: '',
        contacts: []
    });

    const [contactsDelete, setContactsDelete] = useState([]);
    const [changeState, setchangeState] = useState([]);
    const [openCancelDialog, setOpenCancelDialog] = useState(false);

    async function fetchData(userId) {
        setLoading();
        await getInformationContact(userId);
        setLoadingFalse();
    }

    useEffect(() => {
        // window.scrollTo(0, 0);
        fetchData(userId);
    }, [changeState, changeState.length]);

    useEffect(() => {
        if (informationContact) {
            setValues({
                id: informationContact.id ? informationContact.id : null,
                email: informationContact.personalEmail
                    ? informationContact.personalEmail
                    : '',
                phone: informationContact.residencialPhoneNumber
                    ? informationContact.residencialPhoneNumber
                    : '',
                cell: informationContact.mobilePhoneNumber
                    ? informationContact.mobilePhoneNumber
                    : '',
                cep: informationContact.cep ? informationContact.cep : '',
                address: informationContact.street
                    ? informationContact.street
                    : '',
                number: informationContact.number
                    ? informationContact.number
                    : 0,
                neighborhood: informationContact.neighbor
                    ? informationContact.neighbor
                    : '',
                city: informationContact.city ? informationContact.city : '',
                state: informationContact.uf ? informationContact.uf : '',
                complement: informationContact.complement
                    ? informationContact.complement
                    : '',
                contacts: informationContact.portalSecUserMoreContacts
                    ? informationContact.portalSecUserMoreContacts
                    : ''
            });
        }
    }, [informationContact.length, informationContact, changeState.length]);

    const handleCancel = () => {
        if (hasChange) {
            setOpenCancelDialog(true);
        } else {
            history.push('/Collaborators');
        }
    };

    const handleApply = () => {
        const canApply = verifyApply();
        if (canApply) {
            let formData = new FormData();

            formData.append(
                `contact.personalEmail`,
                values.email ? values.email : ''
            );
            formData.append(
                `contact.residencialPhoneNumber`,
                values.phone ? values.phone : ''
            );
            formData.append(
                `contact.mobilePhoneNumber`,
                values.cell ? values.cell : ''
            );
            formData.append(`contact.cep`, values.cep ? values.cep : '');
            formData.append(
                `contact.street`,
                values.address ? values.address : ''
            );
            formData.append(
                `contact.number`,
                values.number ? values.number : 0
            );
            formData.append(
                `contact.neighbor`,
                values.neighborhood ? values.neighborhood : ''
            );
            formData.append(`contact.city`, values.city ? values.city : '');
            formData.append(`contact.uf`, values.state ? values.state : '');
            formData.append(
                `contact.complement`,
                values.complement ? values.complement : ''
            );
            formData.append(`contact.userId`, userId);
            formData.append(`contact.version`, 0);
            for (let i in values.contacts) {
                const contact = values.contacts[i];
                formData.append(`add[${i}].id`, contact.id ? contact.id : 0);
                formData.append(
                    `add[${i}].contactName`,
                    contact.contactName ? contact.contactName : ''
                );
                formData.append(
                    `add[${i}].phoneNumber`,
                    contact.phoneNumber ? contact.phoneNumber : ''
                );
                formData.append(`add[${i}].UserAddressId`, values.id);
            }
            for (let i in contactsDelete) {
                const contact = contactsDelete[i];
                formData.append(`contactsDelete[${i}].id`, contact.id);
                formData.append(
                    `contactsDelete[${i}].contactName`,
                    contact.contactName
                );
                formData.append(
                    `contactsDelete[${i}].phoneNumber`,
                    contact.phoneNumber
                );
            }

            if (values.id === null) {
                addInformationContact(formData, fetchData, userId);
            } else {
                updateInformationContact(
                    values.id,
                    formData,
                    fetchData,
                    userId
                );
            }
            setchangeState(changeState => [...changeState, 1]);
            setContactsDelete([]);
        }
    };

    const verifyApply = () => {
        let res = true;

        if (!values.address) {
            res = false;
            setRequiredAddress(true);
        }
        if (!values.number) {
            res = false;
            setRequiredNumber(true);
        }
        if (!values.neighborhood) {
            res = false;
            setRequiredNeighborhood(true);
        }
        if (!values.city) {
            res = false;
            setRequiredCity(true);
        }
        if (!values.state) {
            res = false;
            setRequiredState(true);
        }
        if (values.email && !emailValidation(values.email)) {
            res = false;
            setRequiredEmail(true);
            setMessageFieldEmail(t('INVENTORY.invalidEmail'));
        }

        return res;
    };

    const runCep = value => {
        if (value) {
            const cep = value.replace(/\D/g, '');
            if (cep !== '') {
                const validacep = /^[0-9]{8}$/;
                if (validacep.test(cep)) {
                    axios
                        .get(`https://viacep.com.br/ws/${cep}/json/`, {
                            headers: {
                                'Content-type': 'application/json',
                                Authorization:
                                    'bearer ' +
                                    (Cookies.get('auth-token')
                                        ? Cookies.get('auth-token')
                                        : '')
                            }
                        })
                        .then(res => {
                            if (!res.data.hasOwnProperty('erro')) {
                                setValues({
                                    ...values,
                                    address: res.data.logradouro,
                                    neighborhood: res.data.bairro,
                                    city: res.data.localidade,
                                    state: res.data.uf
                                });
                                setRequiredAddress(false);
                                setRequiredNeighborhood(false);
                                setRequiredCity(false);
                                setRequiredState(false);
                            }
                        });
                }
            }
        }
    };

    const handleChange = (name, value) => {
        setHasChange(true);
        let val = value;
        if (name === 'cep') {
            val = cepMask(value);
        } else if (name === 'phone' || name === 'cell') {
            val = phoneMask(value);
        }
        if (name === 'email') {
            setRequiredEmail(false);
        }

        setValues({ ...values, [name]: val });
    };

    const handleChangeContact = indexField => (name, value) => {
        setHasChange(true);
        const rowsField = [...values.contacts];
        const field = rowsField[indexField];
        if (field) {
            if (name === 'contactName') {
                field.contactName = value;
            } else if (name === 'contactPhone') {
                field.phoneNumber = phoneMask(value);
            }
            setValues({ ...values, contacts: rowsField });
        }
    };

    const addContact = () => {
        setValues({
            ...values,
            contacts: [...values.contacts, { contactName: '', phoneNumber: '' }]
        });
    };

    const removeContact = indexField => () => {
        const rowsField = [...values.contacts];
        if (rowsField[indexField].id) {
            setContactsDelete(contactsDelete => [
                ...contactsDelete,
                rowsField[indexField]
            ]);
        }
        if (rowsField.length) {
            rowsField.splice(indexField, 1);
            setValues({
                ...values,
                contacts: rowsField
            });
        }
    };

    return (
        <React.Fragment>
            <Transition loading={loading} newDesign={true}>
                <Paper>
                    <div className={classes.identificationForm}>
                        <Grid
                            container
                            item
                            spacing={3}
                            md={12}
                            lg={12}
                            style={{ paddingRight: '150px' }}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                style={{ paddingBottom: 0 }}
                            >
                                <Typography
                                    className={classes.titleNewEquipment}
                                >
                                    Contatos
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <Input
                                    context={context}
                                    label={'PERSONAL.email'}
                                    value={values.email}
                                    valueName={'email'}
                                    error={requiredEmail}
                                    helperText={
                                        requiredEmail && messageFieldEmail
                                    }
                                    maxLength={100}
                                    handleChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Input
                                    context={context}
                                    label={'ADDRESS.phone'}
                                    value={values.phone}
                                    valueName={'phone'}
                                    handleChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Input
                                    context={context}
                                    label={'PERSONAL.cell'}
                                    value={values.cell}
                                    valueName={'cell'}
                                    handleChange={handleChange}
                                />
                            </Grid>
                            {context !== 'view' && (
                                <Grid item xs={12} sm={12}>
                                    <div
                                        onClick={addContact}
                                        className={classes.link}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                            width: 'max-content'
                                        }}
                                    >
                                        <Typography>
                                            Adicionar outro contato
                                        </Typography>
                                    </div>
                                </Grid>
                            )}
                            {values.contacts &&
                                values.contacts.map((contact, index) => (
                                    <Grid
                                        container
                                        item
                                        spacing={3}
                                        md={12}
                                        lg={12}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            style={{ marginBottom: '-30px' }}
                                        >
                                            <Typography
                                                className={
                                                    classes.titleNewEquipment
                                                }
                                            >{`Contato ${
                                                index + 1
                                            }`}</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={6}>
                                            <Input
                                                context={context}
                                                label={'PROFILES.nameLabel'}
                                                value={contact.contactName}
                                                valueName={'contactName'}
                                                maxLength={60}
                                                handleChange={handleChangeContact(
                                                    index
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={5} sm={5}>
                                            <Input
                                                context={context}
                                                label={'ADDRESS.phone'}
                                                value={contact.phoneNumber}
                                                valueName={'contactPhone'}
                                                handleChange={handleChangeContact(
                                                    index
                                                )}
                                            />
                                        </Grid>
                                        {context !== 'view' && (
                                            <Grid item xs={1} sm={1}>
                                                <Tooltip
                                                    title="Remover contato"
                                                    aria-label="remover"
                                                    placement="top"
                                                >
                                                    <IconButton
                                                        style={{
                                                            float: 'left',
                                                            marginTop: 26
                                                        }}
                                                        onClick={removeContact(
                                                            index
                                                        )}
                                                        className={
                                                            classes.icons
                                                        }
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        )}
                                    </Grid>
                                ))}
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                style={{ paddingBottom: 0 }}
                            >
                                <Typography
                                    className={classes.titleNewEquipment}
                                >
                                    Endereço
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Input
                                    context={context}
                                    label={'ADDRESS.cep'}
                                    value={values.cep}
                                    valueName={'cep'}
                                    onBlur={() => runCep(values.cep)}
                                    handleChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <Input
                                    context={context}
                                    required
                                    label={'ADDRESS.address'}
                                    value={values.address}
                                    error={requiredAddress}
                                    setRequired={setRequiredAddress}
                                    helperText={
                                        requiredAddress &&
                                        t('GENERAL.requiredField')
                                    }
                                    valueName={'address'}
                                    maxLength={100}
                                    handleChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Input
                                    context={context}
                                    required
                                    label={'ADDRESS.number'}
                                    value={values.number}
                                    error={requiredNumber}
                                    setRequired={setRequiredNumber}
                                    helperText={
                                        requiredNumber &&
                                        t('GENERAL.requiredField')
                                    }
                                    valueName={'number'}
                                    maxLength={10}
                                    handleChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={5} sm={5}>
                                <Input
                                    context={context}
                                    required
                                    label={'ADDRESS.neighborhood'}
                                    value={values.neighborhood}
                                    error={requiredNeighborhood}
                                    setRequired={setRequiredNeighborhood}
                                    helperText={
                                        requiredNeighborhood &&
                                        t('GENERAL.requiredField')
                                    }
                                    valueName={'neighborhood'}
                                    maxLength={30}
                                    handleChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={5} sm={5}>
                                <Input
                                    context={context}
                                    required
                                    label={'ADDRESS.city'}
                                    value={values.city}
                                    error={requiredCity}
                                    setRequired={setRequiredCity}
                                    helperText={
                                        requiredCity &&
                                        t('GENERAL.requiredField')
                                    }
                                    valueName={'city'}
                                    maxLength={30}
                                    handleChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Input
                                    context={context}
                                    required
                                    label={'UF'}
                                    value={values.state}
                                    error={requiredState}
                                    setRequired={setRequiredState}
                                    helperText={
                                        requiredState &&
                                        t('GENERAL.requiredField')
                                    }
                                    valueName={'state'}
                                    handleChange={handleChange}
                                    isSelect
                                    arraySelected={[
                                        { value: 'AC', label: 'AC' },
                                        { value: 'AL', label: 'AL' },
                                        { value: 'AP', label: 'AP' },
                                        { value: 'AM', label: 'AM' },
                                        { value: 'BA', label: 'BA' },
                                        { value: 'CE', label: 'CE' },
                                        { value: 'DF', label: 'DF' },
                                        { value: 'ES', label: 'ES' },
                                        { value: 'GO', label: 'GO' },
                                        { value: 'MA', label: 'MA' },
                                        { value: 'MS', label: 'MS' },
                                        { value: 'MT', label: 'MT' },
                                        { value: 'MG', label: 'MG' },
                                        { value: 'PA', label: 'PA' },
                                        { value: 'PB', label: 'PB' },
                                        { value: 'PR', label: 'PR' },
                                        { value: 'PE', label: 'PE' },
                                        { value: 'PI', label: 'PI' },
                                        { value: 'RJ', label: 'RJ' },
                                        { value: 'RN', label: 'RN' },
                                        { value: 'RS', label: 'RS' },
                                        { value: 'RO', label: 'RO' },
                                        { value: 'RR', label: 'RR' },
                                        { value: 'SC', label: 'SC' },
                                        { value: 'SP', label: 'SP' },
                                        { value: 'SE', label: 'SE' },
                                        { value: 'TO', label: 'TO' }
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Input
                                    context={context}
                                    label={'ADDRESS.complement'}
                                    value={values.complement}
                                    valueName={'complement'}
                                    maxLength={50}
                                    handleChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </div>
                    {context !== 'view' && (
                        <div
                            style={{
                                paddingLeft: '10px',
                                paddingBottom: '40px',
                                paddingTop: '40px'
                            }}
                        >
                            <Buttons
                                onClick={handleCancel}
                                tipo="BN-noback"
                                conteudo={t('GENERAL.cancel')}
                            />
                            <Buttons
                                onClick={handleApply}
                                tipo="BN-blue"
                                conteudo={t('GENERAL.apply')}
                            />
                        </div>
                    )}
                </Paper>

                <DialogCancelOperation
                    open={openCancelDialog}
                    setOpen={setOpenCancelDialog}
                    path={'/Collaborators'}
                    dialogTitle={t('GENERAL.cancelEdit')}
                    dialogText={t('GENERAL.cancelEditText')}
                />
            </Transition>
        </React.Fragment>
    );
};

export default ContactForm;
