import React from "react";
import CollaboratorsContextState from "./collaborator/collaboratorState";
import SettingsContextState from "./settings/settingsState";
import VacationsContextState from "./vacations/vacationsState";
import ClockRecordState from "./clockRecord/clockRecordState"


const hrModuleState = (props) => {
    return (
        <CollaboratorsContextState>
            <SettingsContextState>
                <VacationsContextState>
                    <ClockRecordState>
                        {props.children}
                    </ClockRecordState>
                </VacationsContextState>
            </SettingsContextState>
        </CollaboratorsContextState>
    )
}

export default hrModuleState;