import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Visibility from '@material-ui/icons/Visibility';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import DialogBill from './Dialogs/DialogBill';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';
import { Screen, Allow } from 'global/constants';
import { screenStyles } from './Styles';
import { BillStatus } from './ScreenUtils';

const BillsTableActions = props => {
    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }
    const styles = screenStyles();
    const classes = useStyles();
    const [t] = useTranslation();
    const { item, context } = props;

    const [open, setOpen] = useState(false);
    const [dialogContext, setDialogContext] = useState('');

    const handleOpenDialog = context => {
        setOpen(true);
        setDialogContext(context);
    };

    const isPayable =
        item.status !== BillStatus.PENDING && item.status !== BillStatus.LATE;

    return (
        <React.Fragment>
            <Tooltip
                title={t('GENERAL.view')}
                aria-label="details"
                placement="top"
            >
                <IconButton
                    className={classes.iconsTableActions}
                    onClick={() => handleOpenDialog('view')}
                    disabled={item.status === BillStatus.NO_BILL}
                >
                    <Visibility />
                </IconButton>
            </Tooltip>

            {context !== 'view' ? (
                <>
                    {GetGeneralPermissionForAction(
                        Screen.QUOTAS_USERS,
                        Allow.EDIT
                    ) && (
                        <Tooltip
                            title={t('GENERAL.pay')}
                            aria-label="details"
                            placement="top"
                        >
                            <IconButton
                                className={classes.iconsTableActions}
                                onClick={() => handleOpenDialog('pay')}
                                disabled={isPayable}
                            >
                                <CheckCircleIcon />
                            </IconButton>
                        </Tooltip>
                    )}

                    {GetGeneralPermissionForAction(
                        Screen.QUOTAS_USERS,
                        Allow.DELETE
                    ) && (
                        <Tooltip
                            title={t('GENERAL.cancel')}
                            aria-label="details"
                            placement="top"
                        >
                            <IconButton
                                className={classes.iconsTableActions}
                                onClick={() => handleOpenDialog('cancel')}
                                disabled={isPayable}
                            >
                                <CancelIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </>
            ) : null}

            {open && (
                <DialogBill
                    open={open}
                    setOpen={setOpen}
                    viewValues={item}
                    context={dialogContext}
                />
            )}
        </React.Fragment>
    );
};

export default BillsTableActions;
