import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from '@material-ui/icons/Close';
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import history from "../../../../history";
import Buttons from "components/buttons/Pressed";
import TextField from '@material-ui/core/TextField';
import SettingsContext from "context/hrModule/settings/settingsContext";
import { GetGeneralPermissionForAction } from "utils/permissions/GetGeneralPermissionForAction";
import { Screen, Allow } from "global/constants";

import { useStyles } from "components/layout/CommonStyles";
import { dateToBR } from "utils/dates/DateToBRr";

const HolidaysAndBreaksTableAction = props => {
    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }
    const classes = useStyles();
    const [t] = useTranslation();
    const settingsContext = useContext(SettingsContext);
    const [openDelete, setOpenDelete] = useState(false);
    const [changeContext, setchangeContex] = useState(0);
    const [password, setPassword] = useState("");
    const [passRight, setPassRight] = useState(false);
    const { item, fetchData } = props;
    const [justification, setJustification] = useState("");
    const [validJustification, setValidJustification] = useState(false);
    const blankField = "GENERAL.requiredField";
    const [validPasswordrequiredField, setPasswordRequiredField] = useState(false);

    const {
        deleteHolidaysAndBreaks
    } = settingsContext

    const handleOpenDelete = () => {
        setOpenDelete(true);
    };
    const handleCloseDelete = () => {
        setOpenDelete(false);
        setValidJustification(false);
        setPasswordRequiredField(false);
        setPassRight(false);
        setchangeContex(0);
        setJustification("");
        setPassword("");
    };

    var pass = true;

    const handleDelete = () => {
        if (justification.trim().length > 0) setchangeContex(changeContext + 1)
        else setValidJustification(true)
        if (changeContext > 0) {
            if (password.length > 0) sendRequest();
            else setPasswordRequiredField(true);
            if (pass) setPassword("")
            else setOpenDelete(false);
        }
    };

    function sendRequest() {
        const requestInfo = {
            method: "POST",
            body: JSON.stringify({
                email: auth.userName,
                password: password
            }),
            headers: new Headers({
                "Content-type": "application/json"
            })
        };


        fetch(process.env.REACT_APP_SERVER + "api/auth/login", requestInfo)
            .then(response => {
                if (response.ok) {
                    setPassRight(false);
                    let formData = new FormData();
                    formData.append('justification', justification)
                    deleteHolidaysAndBreaks(item.id, formData, fetchData, handleCloseDelete);
                    return false;
                } else {
                    setPassRight(true);
                    return true;
                }
            })
            .catch(error => {
                setPassRight(true);
                return true;
            });
    }

    const verifyEdit = () => {
        history.push(`/HolidaysAndBreaks/${item.id}/edit`);

    }

    const verifyView = () => {
        history.push(`/HolidaysAndBreaks/${item.id}/view`);
    }

    const getPermissionInventory = () => {
        if (localStorage.getItem("auth-token") !== null) {
            var auth = JSON.parse(localStorage.getItem("auth-token"));
            const permission = auth.permissionsByModule;

            // Get the user profile rule for the people management module
            const peopleManagement = permission.filter(el => el.systemId == 6905701);
            // Get the user profile rule for the inventory module
            const inventory = permission.filter(el => el.systemId == 6910301);

            // Checks whether the user is an administrative manager in the people management module and an administrator in the assets module 
            if (inventory[0].roleId === 6905151) {
                return true;
            } else {
                return false;
            }
        }
    }

    return (
        <React.Fragment>
            {GetGeneralPermissionForAction(Screen.HOLIDAYS_AND_BREAKS, Allow.READ) &&
                <Tooltip title={t("GENERAL.view")} aria-label="details" placement="top">
                    <IconButton className={classes.iconsTableActions} onClick={verifyView} >
                        <Visibility />
                    </IconButton>
                </Tooltip>
            }
            {GetGeneralPermissionForAction(Screen.HOLIDAYS_AND_BREAKS, Allow.EDIT) &&
                <Tooltip title={t("GENERAL.edit")} aria-label="edit" placement="top">
                    <IconButton className={classes.iconsTableActions} onClick={verifyEdit} >
                        <Edit />
                    </IconButton>
                </Tooltip>
            }
            {GetGeneralPermissionForAction(Screen.HOLIDAYS_AND_BREAKS, Allow.DELETE) &&
                <Tooltip title={t("GENERAL.delete")} aria-label="delete" placement="top">
                    <IconButton className={classes.iconsTableActions} onClick={handleOpenDelete}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            }
            <Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="form-dialog-title"
                msg="mensagem"
            >
                <DialogTitle className={classes.Title}>
                    <b>{t("GENERAL.delete") + " " + (item.typeFromDatabase === "HOLIDAY" ? t("HR.holiday") : t("HR.break"))}</b>
                    <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleCloseDelete} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {changeContext === 0 ?
                        <DialogContentText>


                            <p>{item.typeFromDatabase === "HOLIDAY" ? t("HR.deleteHolidayMessage") : t("HR.deleteBreakMessage")}</p>

                            <Grid container item spacing={0} md={12} lg={12}>
                                <Grid item xs={12} sm={6}>
                                    <b>{t("PERSONAL.name").toUpperCase()}</b>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <b>{item.typeFromDatabase === "HOLIDAY" ? t("HR.holidayDate").toUpperCase() : t("HR.breakPeriod").toUpperCase()}</b>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {item.name}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {item.typeFromDatabase === "HOLIDAY" ? dateToBR(item.date) : dateToBR(item.startDate) + " - " + dateToBR(item.endDate)}
                                </Grid>
                            </Grid>
                            <TextField
                                error={validJustification}
                                multiline
                                fullWidth
                                variant="outlined"
                                rows="3"
                                value={justification}
                                label={t("INVENTORY.justification") + "*"}
                                margin="normal"
                                inputProps={{ maxLength: 100 }}
                                helperText={validJustification ? t(blankField) : `${justification.length}/100`}
                                onChange={(e) => {
                                    setValidJustification(false);
                                    setJustification(e.target.value)
                                }}
                            />
                        </DialogContentText>
                        :
                        <DialogContentText>
                            <p>{item.typeFromDatabase === "HOLIDAY" ? t("HR.deleteHolidayPasswordMessage") : t("HR.deleteBreakPasswordMessage")}</p>
                            <Grid container item spacing={0} md={12} lg={12}>
                                <Grid item xs={12} sm={6}>
                                    <b>{t("PERSONAL.name").toUpperCase()}</b>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <b>{item.typeFromDatabase === "HOLIDAY" ? t("HR.holidayDate").toUpperCase() : t("HR.breakPeriod").toUpperCase()}</b>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {item.name}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {item.typeFromDatabase === "HOLIDAY" ? dateToBR(item.date) : dateToBR(item.startDate) + " - " + dateToBR(item.endDate)}
                                </Grid>
                            </Grid>
                            <p>{t("INVENTORY.deleteActionCannotBeUndone")}</p>
                            <TextField
                                error={validPasswordrequiredField || passRight}
                                label={t("INVENTORY.password") + "*"}
                                value={password}
                                onChange={(e) => {
                                    setPasswordRequiredField(false)
                                    setPassRight(false);
                                    setPassword(e.target.value)
                                }}
                                type="password"
                                variant="filled"
                                helperText={validPasswordrequiredField ? t(blankField) : passRight ? "Senha inválida." : ""}
                                style={{ width: "90%", marginLeft: 30, marginRight: 30 }}
                            />
                        </DialogContentText>
                    }
                </DialogContent>
                <DialogActions>
                    <Buttons
                        onClick={handleCloseDelete}
                        tipo="BN-noback"
                        conteudo={t("GENERAL.cancel").toUpperCase()}
                    />
                    <Buttons
                        onClick={handleDelete}
                        tipo="BN-blue"
                        conteudo={changeContext === 0 ? t("GENERAL.next").toUpperCase() : t("GENERAL.delete").toUpperCase()}
                    />
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
};

export default HolidaysAndBreaksTableAction;