import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import Check from "@material-ui/icons/Check";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ActivityParticipants from "components/execution/Phases/Activities/ActivityParticipants";
import PhaseActivity from "components/execution/Phases/Activities/PhaseActivity";
import { useStyles } from "components/layout/CommonStyles";
import Footer from "components/layout/Footer";
import MySnackbarContentWrapper from "components/layout/Message";
import SideBar from "components/layout/sidebar/Sidebar";
import Spinner from "components/layout/spinners/Index";
import "components/profiles/css/FormStyle.css";
import ProjectsContext from "context/projects/projectsContext";
import "moment/locale/pt-br";
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import DashboardNavBar from "../../../layout/DashboardNavBar";

const Activity = ({ match }) => {
    const [t] = useTranslation();
    const projectsContext = useContext(ProjectsContext);
    const {
        loading,
        project,
        phase,
        activity,
        getProject,
        getPhase,
        getActivity,
        responseType,
        response,
        successiveActions,
        updateActivity,
        participants,
        getParticipants,
        resetMessage
    } = projectsContext;
    const [projectId, setProjectId] = useState(0);
    const [phaseId, setPhaseId] = useState(0);
    const [openMessage, setOpenMessage] = useState(false);
    const [mustRedirect, setMustRedirect] = useState(false);
    const classes = useStyles();
    const [state, setState] = useState({
        projectName: "",
        gpf: "",
        projectStartDate: "",
        projectEndDate: "",
        phaseName: "",
        phaseStatus: "",
        phaseStart: "",
        phaseEnd: "",
    });
    const [stateActivity, setStateActivity] = useState({
        file: null,
        activityName: "",
        activityDescription: "",
        expectedResults: "",
        obtainedResults: "",
        activityStartDate: new Date(),
        activityEndDate: new Date(),
    });
    const [part, setPart] = useState([]);

    const [snackBar, setSnackBar] = useState({
        open: false,
        kind: "",
        content: ""
    });

    const handleSnack = () => {
        setSnackBar({ ...snackBar, open: true });
    };

    const handleSnackClose = () => {
        setSnackBar({ ...snackBar, open: false });
        resetMessage();
    };

    useEffect(() => {
        if (responseType === t("GENERAL.successType")) {
            handleSnack({ kind: "success", content: response });
        }
        if (responseType === t("GENERAL.errorType")) {
            handleSnack({ kind: "error", content: response });
        }

        return () => { };
    }, [responseType, response, successiveActions]);

    useEffect(() => {
        getActivity(match.params.id);
    }, []);

    useEffect(() => {
        if (activity) {
            getPhase(activity.phaseId);
        }
    }, [activity]);

    useEffect(() => {
        if (phase) {
            setPhaseId(phase.id);

            if (phase.projectId) {
                getProject(phase.projectId);
                setProjectId(phase.projectId);
                getParticipants(phase.projectId);
            }
        }
    }, [phase]);

    useEffect(() => {
        if (project && project.information) {
            setState({
                ...state,
                gpf: project.information.gpf,
                projectName: project.information.name,
                projectStartDate: project.information.startdate,
                projectEndDate: project.information.enddate,
                phaseName: phase.name,
                phaseStatus: "",
                phaseStart: phase.startdate,
                phaseEnd: phase.enddate
            });
        }
    }, [project]);

    useEffect(() => {
        if (participants && activity && activity.projectActivityParticipant) {
            let part = [];
            activity.projectActivityParticipant.map(item => {
                const user = participants.find(x => x.id === item.participantId);
                if (user) {
                    let startDateFormated = null;
                    let endDateFormated = null;

                    if (item.startDate && item.endDate) {
                        startDateFormated = new Date(item.startDate);
                        startDateFormated.setMinutes(startDateFormated.getMinutes() + startDateFormated.getTimezoneOffset())

                        endDateFormated = new Date(item.endDate);
                        endDateFormated.setMinutes(endDateFormated.getMinutes() + endDateFormated.getTimezoneOffset())

                        startDateFormated = startDateFormated.getDate() + "/" + (startDateFormated.getMonth() + 1) + "/" + startDateFormated.getFullYear();
                        endDateFormated = endDateFormated.getDate() + "/" + (endDateFormated.getMonth() + 1) + "/" + endDateFormated.getFullYear();
                    }

                    part.push({
                        id: item.id,
                        imagePath: user.imagePath,
                        hours: item.hours.toString(),
                        hoursFormated: item.hours.toString() + 'h',
                        participantName: user.participantName,
                        participantEducation: user.participantEducation,
                        participantfunction: user.participantfunction,
                        period: (startDateFormated && endDateFormated) ? `${startDateFormated} - ${endDateFormated}` : "",
                        startDate: item.startDate ? item.startDate : null,
                        endDate: item.endDate ? item.endDate : null,
                        performance: item.description ? item.description : "",
                        activityStartDate: activity.startDate,
                        activityEndDate: activity.endDate,
                        participantId: item.participantId,
                    });
                }
            });
            setPart(part);
        }
    }, [participants, JSON.stringify(participants)]);

    const modifyActivity = (field, value) => {
        if (typeof field !== 'object' && value !== undefined) {
            setStateActivity({ ...stateActivity, [field]: value })
        } else if (typeof field === 'object') {
            setStateActivity(field);
        }
    }

    const handleSubmit = () => {
        setOpenMessage(false);
        if (stateActivity.activityName) {
            const formData = new FormData();

            const startDateFormated = stateActivity.activityStartDate.getDate() + "/" + (stateActivity.activityStartDate.getMonth() + 1) + "/" + stateActivity.activityStartDate.getFullYear();
            const endDateFormated = stateActivity.activityEndDate.getDate() + "/" + (stateActivity.activityEndDate.getMonth() + 1) + "/" + stateActivity.activityEndDate.getFullYear();

            formData.append("file", stateActivity.file);
            formData.append("name", stateActivity.activityName);
            formData.append("description", stateActivity.activityDescription);
            formData.append("expectedResults", stateActivity.expectedResults);
            formData.append("obtainedResults", stateActivity.obtainedResults);
            formData.append("startDate", startDateFormated);
            formData.append("endDate", endDateFormated);
            formData.append("phaseId", phase.id);

            updateActivity(activity.id, formData);
            setMustRedirect(true);
        } else setOpenMessage(true);
    };

    const transitions = useTransition(loading, null, {
        from: { opacity: 0 },
        enter: {
            opacity: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "calc(100vh - 80px)"
        },
        leave: { opacity: 0 }
    });

    const redirect = go => {
        if (go) return <Redirect to={`/execution/project/edit/projectPhase/${phaseId}/edit`} />;
    };

    return (
        transitions.map(({ item, key, props }) =>
            item ? (
                <animated.div key={key} style={props}>
                    {redirect(mustRedirect)}
                    <Spinner />
                </animated.div>
            ) : (
                    <animated.div key={key} style={props}>
                        <DashboardNavBar />
                        <SideBar />
                        {responseType && <Snackbar
                            anchorOrigin={{ vertical: "top", horizontal: "right" }}
                            open={snackBar.open}
                            onClose={handleSnackClose}
                            TransitionComponent={Slide}
                            transitionDuration={{ enter: 500, exit: 500 }}
                            ContentProps={{
                                "aria-describedby": "message-id"
                            }}
                            autoHideDuration={2000}
                        >
                            <MySnackbarContentWrapper
                                onClose={handleSnackClose}
                                variant={responseType.toLowerCase()}
                                message={<span id="message-id">{response}</span>}
                            />
                        </Snackbar>}
                        <div className="container">
                            <Breadcrumbs
                                className={classes.breadCrumbs}
                                aria-label="breadcrumb"
                                separator={<NavigateNextIcon fontSize="small" />}
                            >
                                <Typography>{t("PARTICIPANTS.labor")}</Typography>
                                <Link className={classes.link} to="/execution/projects/">
                                    {t("PROJECTS.projects")}
                                </Link>
                                <Link
                                    className={classes.link}
                                    to={`/execution/projects/${projectId}/${match.params.projectMode}`}
                                >
                                    {t("PROJECTS.project")}
                                </Link>
                                <Link
                                    className={classes.link}
                                    to={`/execution/project/${match.params.projectMode}/projectPhase/${phaseId}/${match.params.phaseMode}`}
                                >
                                    {match.params.phaseMode === t("GENERAL.editMode") ? t("PHASES.editPhase") : t("PHASES.viewPhase")}
                                </Link>
                                {
                                    match.params.mode === t("GENERAL.editMode") ?
                                        <Typography>{t("ACTIVITIES.editActivity")}</Typography> :
                                        <Typography>{t("ACTIVITIES.viewActivity")}</Typography>
                                }
                            </Breadcrumbs>
                            <div className="container-header">
                                <div className="title">
                                    {
                                        match.params.mode === t("GENERAL.editMode") ?
                                            <h1 style={{ marginBottom: "12px" }} className={classes.title}>{t("ACTIVITIES.editActivity")}</h1> :
                                            <h1 style={{ marginBottom: "12px" }} className={classes.title}>{t("ACTIVITIES.viewActivity")}</h1>
                                    }
                                    <Typography>
                                        <span className={classes.projectInformation}>GPF: </span>
                                        <span className={classes.projectInformation2}>{`${state.gpf}`}</span>
                                        <span className={classes.projectInformation}> | PROJETO: </span>
                                        <span className={classes.projectInformation2}>{`${state.projectName}`}</span>
                                        <span className={classes.projectInformation}> | ETAPA: </span>
                                        <span className={classes.projectInformation2}>{`${state.phaseName}`}</span>
                                    </Typography>
                                </div>
                                {match.params.mode === t("GENERAL.editMode") && <Button
                                    className={classes.blueButton}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmit}
                                >
                                    <div className={classes.buttonIcon}>
                                        <Check />
                                    </div>
                                    {t("ACTIVITIES.updateActivity").toUpperCase()}
                                </Button>}
                            </div>
                            <PhaseActivity
                                activity={activity}
                                setNewActivity={modifyActivity}
                                requiredFields={openMessage}
                                setRequiredFields={setOpenMessage}
                                mode={match.params.mode}
                            />
                            <br />
                            <br />
                            {
                                (activity && activity.projectActivityParticipant) &&
                                <ActivityParticipants projectId={projectId} participants={part} mode={match.params.mode} activityId={activity.id} />
                            }
                        </div>
                        <Footer />
                    </animated.div>
                )
        )
    )
};

export default Activity;