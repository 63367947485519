import { makeStyles } from '@material-ui/core/styles';

export const containersStyles = makeStyles(theme => ({
    filterContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit , 310px)',
        rowGap: '16px',
        columnGap: '8px',
        padding: '8px 16px 20px'
    },
    headerContainer: {
        paddingLeft: '154px' /* 104 = sidebar size */,
        paddingRight: '50px',
        backgroundColor: 'white'
    },
    contentContainer: {
        marginTop: '20px',
        marginLeft: '154px' /* 104 = sidebar size */,
        marginRight: '50px'
    },
    dialogContainer: {
        display: 'flex',
        minWidth: '40rem',
        minHeight: '10rem'
    },
    dialogContentContainer: {
        width: '100%'
    },
    blueHeaderContainer: {
        boxSizing: 'border-box',
        display: 'flex',
        padding: '1rem 3rem',
        backgroundColor: '#2C3E51',
        color: '#fff',
        height: '180px',
        justifyContent: 'space-between'
    },
    greyContainer: {
        boxSizing: 'border-box',
        display: 'flex',
        padding: '1rem 18px',
        backgroundColor: '#F5F6FA',
        justifyContent: 'space-between',
        borderRadius: '16px',
        marginBottom: '24px'
    }
}));
