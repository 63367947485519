import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import React, { useEffect, useState } from 'react';
import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';
import { Screen, Allow } from 'global/constants';
import SidebarListItem from './SidebarListItem';

const LibrarySideBarConfigMenu = () => {
    const [displayMenu, setDisplayMenu] = useState(false);
    const [arrowConfig, setArrowConfig] = useState(false);

    const handleMouseEvent = () => {
        setDisplayMenu(!displayMenu);
        setArrowConfig(!arrowConfig);
    };

    const GetAtLeastOneManagementScreen = () => {
        if (localStorage.getItem('auth-token') !== null) {
            return (
                GetGeneralPermissionForAction(
                    Screen.LIBRARY_CONFIGURATION,
                    Allow.READ
                ) ||
                GetGeneralPermissionForAction(
                    Screen.LIBRARY_REQUISITIONS_GENERAL,
                    Allow.READ
                ) ||
                GetGeneralPermissionForAction(
                    Screen.LIBRARY_REQUISITIONS_ALL,
                    Allow.READ
                )
            );
        }
    };

    useEffect(() => {
        return () => {
            setDisplayMenu(false);
            setArrowConfig(false);
        };
    }, []);

    return (
        <div style={{ marginTop: '10px' }}>
            {
                <div className="dropdown">
                    <ul style={{ display: 'table-cell', marginLeft: '5px' }}>
                        {GetGeneralPermissionForAction(
                            Screen.BOOKS_COLLECTION,
                            Allow.READ
                        ) && (
                                <SidebarListItem text="Acervo" to="/BooksCollection" />
                            )}

                        {GetGeneralPermissionForAction(
                            Screen.LIBRARY_MY_LOANS,
                            Allow.READ
                        ) && (
                                <SidebarListItem text="Meus Empréstimos" to="/Library/MyLoans" />
                            )}
                    </ul>
                </div>
            }

            {GetAtLeastOneManagementScreen() && (
                <div className="dropdown">
                    <div className="divConfig" onClick={handleMouseEvent}>
                        Gerenciamento
                        {!arrowConfig && <KeyboardArrowRight />}
                        {arrowConfig && <KeyboardArrowDown />}
                    </div>

                    {displayMenu && (
                        <ul
                            style={{ display: 'table-cell', marginLeft: '5px' }}
                        >
                            {GetGeneralPermissionForAction(
                                Screen.LIBRARY_LOANS,
                                Allow.READ
                            ) && (
                                    <SidebarListItem text="Empréstimos da Biblioteca" to="/Library/Loans" />
                                )}

                            {GetGeneralPermissionForAction(
                                Screen.LIBRARY_REQUISITIONS_ALL,
                                Allow.READ
                            ) ? (
                                <SidebarListItem text="Requisições de Biblioteca" to="/LibraryRequisitions/all" />
                            ) : GetGeneralPermissionForAction(
                                Screen.LIBRARY_REQUISITIONS_GENERAL,
                                Allow.READ
                            ) ? (
                                <SidebarListItem text="Requisições de Biblioteca" to="/LibraryRequisitions/general" />
                            ) : (
                                <></>
                            )}

                            {GetGeneralPermissionForAction(
                                Screen.LIBRARY_CONFIGURATION,
                                Allow.READ
                            ) && (
                                    <SidebarListItem text="Configurações Gerais" to="/LibraryConfiguration" />
                                )}

                            {GetGeneralPermissionForAction(
                                Screen.FINES,
                                Allow.READ
                            ) && (
                                    <SidebarListItem text="Multas" to="/Library/Fines" />
                                )}
                        </ul>
                    )}
                </div>
            )}
        </div>
    );
};

export default LibrarySideBarConfigMenu;
