import React, { useState, useEffect, useContext } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import Breadcrumb from 'components/layout/breadcrumb/Breadcrumb';
import Footer from 'components/layout/Footer';
import LoanRequisitionsForm from 'pages/libraryModule/Requisitions/forms/LoanRequisitionsForm';
import WithdrawRequisitionsForm from 'pages/libraryModule/Requisitions/forms/WithdrawRequisitionsForm';
import HeaderContainer from 'components/containers/HeaderContainer';
import Badge from '@material-ui/core/Badge';
import { screenStyles } from 'pages/libraryModule/Requisitions/Styles';
import Transition from 'components/layout/transition/Transition';
import {
    LibraryReservationStatusValues,
    LibraryPlaceValues
} from 'global/constants';
import RequisitionsContext from 'context/libraryModule/requisitions/requisitionsContext';
import { QueryGetValue } from 'utils/general/QueryString';
import AwaitingListForm from 'pages/libraryModule/Requisitions/forms/AwaitingListForm';

const RequisitionsTabPanel = props => {
    const { context } = props;
    const classes = useStyles();
    const [t] = useTranslation();
    const [value, setValue] = useState(0);
    const styles = screenStyles();

    const [panelQuery, setPanelQuery] = useState('');

    const [loanRequisitionCount, setLoanRequisitionCount] = useState(0);
    const [withdrawalRequisitionCount, setWithdrawalRequisitionCount] =
        useState(0);
    const [awaitingListCount, setAwaitingListCount] = useState(0);

    const [loanRequisitionsData, setLoanRequisitionsData] = useState([]);
    const [withdrawRequisitionsData, setWithdrawRequisitionsData] = useState(
        []
    );
    const [awaitingListData, setAwaitingListData] = useState([]);

    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const requisitionsContext = useContext(RequisitionsContext);

    const {
        loading,
        setLoading,
        setLoadingFalse,
        getRequisitions,
        requisitions,
        updateRequisition,
        getAwaitingList,
        awaitingList
    } = requisitionsContext;

    async function fetchData() {
        await setLoading();
        await getRequisitions(
            context === 'general'
                ? LibraryPlaceValues.GENERAL
                : LibraryPlaceValues.COORDINATION
        );
        await getAwaitingList();
        await setLoadingFalse();
    }

    useEffect(() => {
        fetchData();
        setPanelQuery(QueryGetValue('panel'));
    }, []);

    const submitRequisition = (
        id,
        context,
        status,
        exemplarId,
        justification,
        emailData,
        fromScreen
    ) => {
        let formData = new FormData();

        formData.append('status', status);
        formData.append('exemplarId', exemplarId);
        formData.append('approvedBy', auth.id);
        formData.append('justification', justification);

        if (context === 'approve') {
            emailData.approvedBy = auth.name;
        }
        if (context === 'cancel') {
            emailData.name = auth.name;
            formData.append('canceledBy', auth.id);
        }

        updateRequisition(
            id,
            context,
            formData,
            emailData,
            fetchData,
            fromScreen
        );
    };

    useEffect(() => {
        if (requisitions) {
            setLoanRequisitionsData(
                requisitions.filter(
                    requisition =>
                        requisition.status ===
                        LibraryReservationStatusValues.AWAITING_APPROVAL
                )
            );
            setWithdrawRequisitionsData(
                requisitions.filter(
                    requisition =>
                        requisition.status ===
                        LibraryReservationStatusValues.AWAITING_WITHDRAWAL
                )
            );
            setLoanRequisitionCount(
                requisitions.filter(
                    requisition =>
                        requisition.status ===
                        LibraryReservationStatusValues.AWAITING_APPROVAL
                ).length
            );
            setWithdrawalRequisitionCount(
                requisitions.filter(
                    requisition =>
                        requisition.status ===
                        LibraryReservationStatusValues.AWAITING_WITHDRAWAL
                ).length
            );
        }

        if (awaitingList) {
            setAwaitingListData(awaitingList);
            setAwaitingListCount(awaitingList.length);
        }
    }, [requisitions, awaitingList]);

    function TabPanel(props) {
        const { children, value, index } = props;

        return <div>{value === index && children}</div>;
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`
        };
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const tabContext = () => {
        if (value === 0) {
            return (
                <LoanRequisitionsForm
                    context={context}
                    RequisitionsData={loanRequisitionsData}
                    submitRequisition={submitRequisition}
                />
            );
        }
        if (value === 1) {
            return (
                <WithdrawRequisitionsForm
                    context={context}
                    RequisitionsData={withdrawRequisitionsData}
                    submitRequisition={submitRequisition}
                />
            );
        }
        if (value === 2) {
            return <AwaitingListForm AwaitingListData={awaitingListData} />;
        }
    };

    useEffect(() => {
        if (panelQuery === 'LOAN') {
            setValue(0);
        }
        if (panelQuery === 'WITHDRAW') {
            setValue(1);
        }
        if (panelQuery === 'AWAITING_LIST') {
            setValue(2);
        }
    }, [panelQuery]);

    return (
        <React.Fragment>
            <Transition loading={loading} newDesign={true}>
                <HeaderContainer>
                    <Breadcrumb
                        newDesign={true}
                        routes={[
                            { label: t('INVENTORY.library'), to: '/Library' },
                            { label: t('LIBRARY.requisitions') }
                        ]}
                    />
                    <div className="container-headerNewDesign">
                        <h1 className={classes.titleNewDesign}>
                            {t('LIBRARY.requisitions')}
                        </h1>
                    </div>
                </HeaderContainer>

                <div className="containerNewDesign">
                    <Paper>
                        <AppBar
                            position="static"
                            style={{ boxShadow: 'none', transform: 'none' }}
                        >
                            <Tabs
                                selectionFollowsFocus
                                style={{
                                    backgroundColor: '#f5f6fa',
                                    color: 'black'
                                }}
                                indicatorColor="primary"
                                value={value}
                                onChange={handleChange}
                            >
                                <Tab
                                    classes={{
                                        wrapper: styles.iconLabelWrapper2,
                                        labelContainer: styles.labelContainer
                                    }}
                                    style={{ fontWeight: 'bold' }}
                                    icon={
                                        <Badge
                                            badgeContent={loanRequisitionCount}
                                            color="error"
                                            className={styles.iconLabel}
                                        />
                                    }
                                    label={t(
                                        'LIBRARY.loanRequisitions'
                                    ).toUpperCase()}
                                    {...a11yProps(0)}
                                />
                                <Tab
                                    classes={{
                                        wrapper: styles.iconLabelWrapper2,
                                        labelContainer: styles.labelContainer
                                    }}
                                    style={{ fontWeight: 'bold' }}
                                    icon={
                                        <Badge
                                            badgeContent={
                                                withdrawalRequisitionCount
                                            }
                                            color="error"
                                            className={styles.iconLabel}
                                        />
                                    }
                                    label={t(
                                        'LIBRARY.withdrawalRequisitions'
                                    ).toUpperCase()}
                                    {...a11yProps(1)}
                                />
                                <Tab
                                    classes={{
                                        wrapper: styles.iconLabelWrapper2,
                                        labelContainer: styles.labelContainer
                                    }}
                                    style={{ fontWeight: 'bold' }}
                                    icon={
                                        <Badge
                                            badgeContent={awaitingListCount}
                                            color="error"
                                            className={styles.iconLabel}
                                        />
                                    }
                                    label={t(
                                        'LIBRARY.waitingList'
                                    ).toUpperCase()}
                                    {...a11yProps(2)}
                                />
                            </Tabs>
                        </AppBar>

                        {tabContext()}
                    </Paper>
                </div>
                <Footer />
            </Transition>
        </React.Fragment>
    );
};

export default RequisitionsTabPanel;
