import axios from "axios";
import React, { useReducer } from "react";
import { useTranslation } from "react-i18next";
import { DEFAULT, PARTNERS } from "../types";
import PartnersContext from "./partnersContext";
import PartnersReducer from "./partnersReducer";
import Cookies from "js-cookie";

const PartnersState = props => {
  const initialState = {
    partner: {},
    addressByCep: {},
    partners: [],
    response: "",
    responseType: "",
    hasChanged: 0,
    successiveActions: 0,
    loading: false
  };

  const [t] = useTranslation();

  const [state, dispatch] = useReducer(PartnersReducer, initialState);
  const setLoading = () => dispatch({ type: DEFAULT.SET_LOADING });
  const resetMessage = () => dispatch({ type: DEFAULT.RESET_MESSAGE });
  const setHasChanged = () => dispatch({ type: DEFAULT.SET_HAS_CHANGED });

  const addPartner = (obj) => {
    setLoading();
    let data = JSON.stringify({
      fronzen: true,
      cep: obj.cep,
      city: obj.city,
      state: obj.state,
      socialreason: obj.corporateName,
      neighborhood: obj.neighborhood,
      responsible: obj.responsibleName,
      cnpj: obj.cnpj,
      country: obj.country,
      primaryfax: obj.fax,
      email: obj.email,
      fone: obj.phone,
      street: obj.address,
      active: obj.status,
      type: obj.type,
      tradename: obj.tradeName,
      financialcontact: obj.financialContactName,
      financialemail: obj.financialContactEmail,
      financialfax: obj.financialContactFax,
      financialphone: obj.financialContactPhone,
    });

    axios
      .post(process.env.REACT_APP_SERVER + "api/Partners/", data, {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "bearer " +
            (Cookies.get("auth-token") ? Cookies.get("auth-token") : ""),
        },
      })
      .then((response) => {
        if (response.status === 201) {
          dispatch({
            type: PARTNERS.ADD_PARTNER,
            payload: t("PARTNERS.successfullyCreated"),
            responseType: "SUCCESS",
            successiveActions: state.successiveActions + 1,
            hasChanged: state.successiveActions + 2,
          });
        } else {
          dispatch({
            type: PARTNERS.ADD_PARTNER,
            payload: t("PARTNERS.unsuccessfullyCreated"),
            responseType: "ERROR",
            successiveActions: state.successiveActions + 1,
            hasChanged: state.successiveActions + 2,
          });
        }
      })
      .catch(() => {
        dispatch({
          type: PARTNERS.ADD_PARTNER,
          payload: t("PARTNERS.namecnpjAlreadyTaken"),
          responseType: "ERROR",
          successiveActions: state.successiveActions + 1,
          hasChanged: state.successiveActions + 2,
        });
      });
  };

  const getAdrresByGivenValidCEP = (cep) => {
    let currentCep = null;
    var errorObj = {
      cep: "",
      logradouro: "Não Encontrado",
      complemento: "?",
      bairro: "?",
      localidade: "?",
      uf: "?",
    };
    if (state.addressByCep.hasOwnProperty("cep")) {
      currentCep = state.addressByCep.cep.replace(/-/g, "");
    }
    if (currentCep === cep) {
      dispatch({
        type: PARTNERS.GET_ADDRESS_BY_GIVEN_CEP,
        payload: state.addressByCep,
      });
    } else {
      axios
        .get(`https://viacep.com.br/ws/${cep}/json/`)
        .then((res) => {
          if (!res.data.hasOwnProperty("erro")) {
            dispatch({
              type: PARTNERS.GET_ADDRESS_BY_GIVEN_CEP,
              payload: res.data,
            });
          } else {
            dispatch({
              type: PARTNERS.GET_ADDRESS_BY_GIVEN_CEP,
              payload: errorObj,
            });
          }
        })
        .catch((error) => {
          console.error(error);
          dispatch({
            type: PARTNERS.GET_ADDRESS_BY_GIVEN_CEP,
            payload: errorObj,
          });
        });
    }
  };

  //Search
  function searchTermChanged(searchTerm) {
    dispatch({
      type: PARTNERS.SEARCH,
      filter: searchTerm,
    });
  }

  // get all partners
  const getPartners = async () => {
    setLoading();
    const res = await axios.get(process.env.REACT_APP_SERVER + "api/Partners", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "bearer " +
          (Cookies.get("auth-token") ? Cookies.get("auth-token") : ""),
      },
    });
    dispatch({
      type: PARTNERS.GET_PARTNERS,
      payload: res.data,
    });
  };

  const getPartner = async (id) => {
    setLoading();
    const res = await axios.get(
      process.env.REACT_APP_SERVER + `api/Partners/${id}`,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "bearer " +
            (Cookies.get("auth-token") ? Cookies.get("auth-token") : ""),
        },
      }
    );
    dispatch({
      type: PARTNERS.GET_PARTNER,
      payload: res.data,
    });
  };

  const updatePartner = (obj) => {
    setLoading();
    let data = JSON.stringify({
      id: obj.id,
      fronzen: true,
      cep: obj.cep,
      city: obj.city,
      state: obj.state,
      socialreason: obj.corporateName,
      neighborhood: obj.neighborhood,
      responsible: obj.responsibleName,
      cnpj: obj.cnpj,
      country: obj.country,
      primaryfax: obj.fax,
      email: obj.email,
      fone: obj.phone,
      street: obj.address,
      active: obj.status,
      type: obj.type,
      tradename: obj.tradeName,
      financialcontact: obj.financialContactName,
      financialemail: obj.financialContactEmail,
      financialfax: obj.financialContactFax,
      financialphone: obj.financialContactPhone,
    });

    axios
      .put(process.env.REACT_APP_SERVER + `api/partners/${obj.id}`, data, {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "bearer " +
            (Cookies.get("auth-token") ? Cookies.get("auth-token") : ""),
        },
      })
      .then((res) => {
        if (res.status === 204) {
          dispatch({
            type: PARTNERS.UPDATE_PARTNER,
            payload: t("PARTNERS.successfullyEdited"),
            responseType: "SUCCESS",
            successiveActions: state.successiveActions + 1,
            hasChanged: state.successiveActions + 2,
          });
        } else {
          dispatch({
            type: PARTNERS.UPDATE_PARTNER,
            payload: "An Error has Occurred",
            responseType: "ERROR",
            successiveActions: state.successiveActions + 1,
            hasChanged: state.successiveActions + 2,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: PARTNERS.UPDATE_PARTNER,
          payload: error,
          responseType: "ERROR",
          successiveActions: state.successiveActions,
          hasChanged: state.successiveActions + 2,
        });
      });
  };

  const deletePartner = (id, active) => {
    setLoading();
    if (active === "Ativo") {
      dispatch({
        type: PARTNERS.DELETE_PARTNER,
        payload: t("PARTNERS.cantDeleteActive"),
        responseType: "ERROR",
        successiveActions: state.successiveActions + 1,
        hasChanged: state.successiveActions + 2,
      });
    } else {
      axios
        .delete(process.env.REACT_APP_SERVER + `api/Partners/${id}`, {
          headers: {
            "Content-type": "application/json",
            Authorization:
              "bearer " +
              (Cookies.get("auth-token") ? Cookies.get("auth-token") : ""),
          },
        })
        .then((res) => {
          dispatch({
            type: PARTNERS.DELETE_PARTNER,
            payload: t("PARTNERS.successfullyDeleted"),
            responseType: "SUCCESS",
            successiveActions: state.successiveActions + 1,
            hasChanged: state.successiveActions + 2,
          });
        })
        .catch((error) => {
          dispatch({
            type: PARTNERS.DELETE_PARTNER,
            payload:
              error.response.status === 404
                ? t("PARTNERS.cantDeleteNotFound")
                : t("PARTNERS.cantDeleteAssociated"),
            responseType: "ERROR",
            successiveActions: state.successiveActions + 1,
            hasChanged: state.successiveActions + 2,
          });
        });
    }
  };

  return (
    <PartnersContext.Provider
      value={{
        partner: state.partner,
        partners: state.partners,
        loading: state.loading,
        message: state.message,
        successiveActions: state.successiveActions,
        filter: state.filter,
        hasChanged: state.hasChanged,
        addressByCep: state.addressByCep,
        response: state.response,
        setHasChanged,
        responseType: state.responseType,
        searchTermChanged,
        addPartner,
        getAdrresByGivenValidCEP,
        getPartners,
        getPartner,
        deletePartner,
        resetMessage,
        updatePartner
      }}
    >
      {props.children}
    </PartnersContext.Provider>
  );
};

export default PartnersState;
