/**
 * Returns an array of years between two dates
 * @param { Date } startDate The start date
 * @param { Date } endDate The end date
 * @returns { Array[Number] } An array of years between the two dates. Ex: [2019, 2020, 2021]
 */
export const getYearsBetweenDates = (startDate, endDate) => {
    return getYearsBetween(startDate.getFullYear(), endDate.getFullYear());
};

/**
 * Returns an array of years between two years
 * @param { Number } startYear the start year
 * @param { Number } endYear the end year
 * @returns { Number[] } An array of years between the two years. Ex: [2019, 2020, 2021]
 */
export const getYearsBetween = (startYear, endYear) => {
    if (startYear > endYear) {
        throw new Error('Start year must be less than end year');
    }

    let years = [];
    for (let i = startYear; i <= endYear; i++) {
        years.push(i);
    }
    return years;
};
