import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from '@material-ui/icons/Close';
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import history from "../../../../../history";
import Buttons from "components/buttons/Pressed";
//import InputBase from "@material-ui/core/InputBase";
import TextField from '@material-ui/core/TextField';
import { useStyles } from "components/layout/CommonStyles";
import DialogAdmitted from "./DialogAdmitted";
import DialogDemission from "./DialogDemission";
import DialogPosition from "./DialogPosition";
import DialogSalary from "./DialogSalary";
import DialogContract from "./DialogContract";
import DialogAdmissionExam from "./DialogAdmissionExam";
import DialogDemissionExam from "./DialogDemissionExam";
import DialogCraft from "./DialogCraft";
import DialogDateVacation from "./DialogDateVacation";
import DialogDemissionDate from "./DialogDemissionDate";
import DialogAdmissionDate from "./DialogAdmissionDate";

const BookTableAction = props => {
    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }
    const classes = useStyles();
    const [t] = useTranslation();
    const { item, onClickDownload } = props;

    const [openDialogAdmitted, setOpenDialogAdmitted] = useState(false);
    const [openDialogDemission, setOpenDialogDemission] = useState(false);
    const [openDialogPosition, setOpenDialogPosition] = useState(false);
    const [openDialogSalary, setOpenDialogSalary] = useState(false);
    const [openDialogContract, setOpenDialogContract] = useState(false);
    const [openDialogAdmissionExam, setOpenDialogAdmissionExam] = useState(false);
    const [openDialogDemissionExam, setOpenDialogDemissionExam] = useState(false);
    const [openDialogCraft, setOpenDialogCraft] = useState(false);
    const [openDialogHolidayDate, setOpenDialogHolidayDate] = useState(false);
    const [openDialogAdmissionDate, setOpenDialogAdmissionDate] = useState(false);
    const [openDialogDemissionDate, setOpenDialogDemissionDate] = useState(false);

    const handleOpenDialog = (type) => {
        if (type === "ADMISSION") {
            setOpenDialogAdmitted(true);
        } else if (type === "DEMISSION") {
            setOpenDialogDemission(true);
        } else if (type === "SALARY") {
            setOpenDialogSalary(true);
        } else if (type === "JOB") {
            setOpenDialogPosition(true);
        } else if (type === "CONTRACT_FILE") {
            setOpenDialogContract(true);
        } else if (type === "ADMISSION_EXAM_FILE") {
            setOpenDialogAdmissionExam(true);
        } else if (type === "DEMISSION_EXAM_FILE") {
            setOpenDialogDemissionExam(true);
        } else if (type === "DEMISSION_CRAFT_FILE") {
            setOpenDialogCraft(true);
        } else if (type === "ADMISSION_DATE") {
            setOpenDialogAdmissionDate(true);
        } else if (type === "DEMISSION_DATE") {
            setOpenDialogDemissionDate(true);
        } else if (type === "HOLIDAY_DATE") {
            setOpenDialogHolidayDate(true);
        }
    }

    const verifyView = () => {
        handleOpenDialog(item.type);
    }

    const getPermissionInventory = () => {
        if (localStorage.getItem("auth-token") !== null) {
            var auth = JSON.parse(localStorage.getItem("auth-token"));
            const permission = auth.permissionsByModule;

            // Get the user profile rule for the people management module
            const peopleManagement = permission.filter(el => el.systemId == 6905701);
            // Get the user profile rule for the inventory module
            const inventory = permission.filter(el => el.systemId == 6910301);

            // Checks whether the user is an administrative manager in the people management module and an administrator in the assets module 
            if (inventory[0].roleId === 6905151) {
                return true;
            } else {
                return false;
            }
        }
    }

    return (
        <React.Fragment>
            <Tooltip title={t("GENERAL.view")} aria-label="details" placement="top">
                <IconButton className={classes.iconsTableActions} onClick={verifyView} >
                    <Visibility />
                </IconButton>
            </Tooltip>
            <DialogSalary
                context={"view"}
                open={openDialogSalary}
                setOpen={setOpenDialogSalary}
                salary={item.newValue}
                oldSalary={item.oldValue}
                justification={item.justification}
            />
            <DialogPosition
                context={"view"}
                open={openDialogPosition}
                setOpen={setOpenDialogPosition}
                oldJob={item.oldValue}
                newJob={item.newValue}
                justification={item.justification}
            />

            <DialogContract
                context={"view"}
                open={openDialogContract}
                setOpen={setOpenDialogContract}
                oldContractName={item.oldValue ? item.oldValue.split("/").pop() : ""}
                oldContractPath={item.oldValue}
                contractName={item.newValue ? item.newValue.split("/").pop() : ""}
                newContractPath={item.newValue}
                justification={item.justification}
            />

            <DialogAdmissionExam
                context={"view"}
                open={openDialogAdmissionExam}
                setOpen={setOpenDialogAdmissionExam}
                oldContractName={item.oldValue ? item.oldValue.split("/").pop() : ""}
                oldContractPath={item.oldValue}
                contractName={item.newValue ? item.newValue.split("/").pop() : ""}
                newContractPath={item.newValue}
                justification={item.justification}
            />

            <DialogDemissionExam
                context={"view"}
                open={openDialogDemissionExam}
                setOpen={setOpenDialogDemissionExam}
                oldContractName={item.oldValue ? item.oldValue.split("/").pop() : ""}
                oldContractPath={item.oldValue}
                contractName={item.newValue ? item.newValue.split("/").pop() : ""}
                newContractPath={item.newValue}
                justification={item.justification}
            />

            <DialogCraft
                context={"view"}
                open={openDialogCraft}
                setOpen={setOpenDialogCraft}
                oldContractName={item.oldValue ? item.oldValue.split("/").pop() : ""}
                oldContractPath={item.oldValue}
                contractName={item.newValue ? item.newValue.split("/").pop() : ""}
                newContractPath={item.newValue}
                justification={item.justification}
            />

            <DialogAdmissionDate
                context={"view"}
                open={openDialogAdmissionDate}
                setOpen={setOpenDialogAdmissionDate}
                oldAdmissionDate={item.oldValue}
                newAdmissionDate={item.newValue}
                justification={item.justification}

            />
            <DialogDemissionDate
                context={"view"}
                open={openDialogDemissionDate}
                setOpen={setOpenDialogDemissionDate}
                oldDemissionDate={item.oldValue}
                newDemissionDate={item.newValue}
                justification={item.justification}

            />
            <DialogDateVacation
                context={"view"}
                open={openDialogHolidayDate}
                setOpen={setOpenDialogHolidayDate}
                oldHolidayDate={item.oldValue}
                newHolidayDate={item.newValue}
                justification={item.justification}

            />

            {openDialogAdmitted ?
                <DialogAdmitted
                    context={"view"}
                    open={openDialogAdmitted}
                    setOpen={setOpenDialogAdmitted}
                    viewValues={item}
                    authId={auth.id}
                />
                : null}
            {openDialogDemission ?
                <DialogDemission
                    context={"view"}
                    open={openDialogDemission}
                    setOpen={setOpenDialogDemission}
                    viewValues={item}
                    authId={auth.id}
                />
                : null}

        </React.Fragment>
    )
};

export default BookTableAction;