import { StyleSheet } from "@react-pdf/renderer";

export default StyleSheet.create({
    page: {
        color: "#000000",
        fontFamily: "Roboto",
        padding: 20,
        display: "flex",
        justifyContent: "space-between",
    },
    main: {
    },
    mainTitle: {
        fontSize: 19,
        color: "#2C3E51",
        marginBottom: 10
    },
    rowMediumBlueBackground: {
        display: "flex",
        flexDirection: "row",
        backgroundColor: "#EEF2F6",
        width: "100%"
    },
    // Width hard coded, but it is possible to pass the number of elements as parameter
    cellAlignCenter: {
        padding: 10,
        textAlign: "center",
        justifyContent: "center"
    },
    cellAlignLeft: {
        padding: 10,
        textAlign: "left",
        justifyContent: "center",
    },
    tableHeader: {
        fontSize: 9,
        color: "#5c6b7a",
        fontWeight: 400,
        backgroundColor: "#EEF2F6",
    },
    tableBody: {
        fontSize: 9,
        fontWeight: 300,
        color: "#2C3E51",
    },
})
