import React, { useState } from 'react';
import { screenStyles } from "./Styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Button from "@material-ui/core/Button";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import DefaultTooltip from "components/infoTooltip/DefaultTooltip";
import HelpTooltip from "components/infoTooltip/HelpTooltip"

import { useTranslation } from "react-i18next";
import { Checkbox } from '@material-ui/core';



const NotificationsTable = (props) => {
    const styles = screenStyles();
    const [t] = useTranslation();

    const {rows, setRows, moduleName, isManager ,context } = props;
    const [viewMore, setViewMore] = useState(true);

    const headers = [
        t("GENERAL.event"),
        t("GENERAL.send"),
    ]

    const handleChange = ( value , idx) => {
        let rowsChanged = [...rows];
        let notificationChanged = rowsChanged[idx];
        notificationChanged.allowSend = value;

        rowsChanged[idx] = notificationChanged;

        setRows(rowsChanged)
    }

    return (
        <React.Fragment>
            <div className={styles.headerViewMore} onClick={() => setViewMore(!viewMore)}>
                <Button className={styles.headerViewMoreButton} >
                    {moduleName ? moduleName.toUpperCase() : ""}
                    {viewMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Button>
            </div>
            {viewMore &&
                <React.Fragment>
                    <Table className={styles.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {headers.map((header, idx) => (
                                    <TableCell className={styles.header} align={idx === 0 ? "left" : "center"}>{header}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, idx) => (
                            <TableRow key={row.screen}>
                                <TableCell component="th" scope="row">
                                    <div className={styles.wrapper}>
                                        {t(row.event)}
                                        {row.isManager != undefined && isManager ? <DefaultTooltip className={styles.errorIcon} text={t("HR.emailProfileCanOnlySeeApproversOrSurrogates")} /> : null}
                                        {row.automatic != undefined && row.automatic ? <HelpTooltip className={styles.errorIcon} text={t("HR.automaticEmailMessage")} /> : null}
                                    </div>
                                </TableCell>
                                <TableCell align="center">
                                    {row.allowSend != undefined ? 
                                    <Checkbox
                                        color="primary"
                                        disabled={ context === "view" || row.automatic}
                                        checked={row.allowSend}
                                        onChange={(ev) => handleChange( ev.target.checked, idx)}
                                    /> : "-"}
                                </TableCell>                
                            
                            </TableRow>
                            ))}
                        </TableBody>
                        </Table>
                </React.Fragment>
            } 
            </React.Fragment>
    );
}

export default NotificationsTable;