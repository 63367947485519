
import DateFnsUtils from "@date-io/date-fns";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Clear from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import axios from "axios";
import Buttons from "components/buttons/Pressed";
import { useStyles } from "components/layout/CommonStyles";
import ProjectsContext from "context/projects/projectsContext";
import React, { useContext, useEffect, useState } from "react";
import Dropzone from 'react-dropzone';
import { useTranslation } from "react-i18next";
import CloseIcon from '@material-ui/icons/Close';
import Cookies from "js-cookie";

const tooltipTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#2c3e51",
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: "white",
        fontSize: "1em",
        backgroundColor: "red",
        opacity: "0.7",
      },
    },
  },
});

const DialogExpense = (props) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const fileDownload = require("js-file-download");
  const projectsContext = useContext(ProjectsContext);
  const { dialogData, setDialogData, createExpense, updateExpense } =
    projectsContext;
  const [dialogMode, setDialogMode] = useState(t("GENERAL.viewMode"));
  const [openState, setOpenState] = useState(false);
  const [rubricsState, setRubricsState] = useState([]);
  const [showRequiredField, setShowRequiredField] = useState(false);
  const [dataState, setDataState] = useState({
    craftNumber: "",
    name: "",
    rubric: "",
    cost: "",
    date: new Date(),
    description: "",
    justification: "",
    files: [],
    rejectedFiles: [],
  });
  const [toDeleteFile, setToDeleteFile] = useState([]);
  const { projectId, rubrics } = props;

  useEffect(() => {
    if (rubrics) {
      setRubricsState(rubrics.slice(1));
    }
  }, []);

  useEffect(() => {
    if (dialogData && dialogData.open) {
      if (dialogData.data) {
        setDataState({
          craftNumber: dialogData.data.craftNumber,
          name: dialogData.data.name,
          rubric: dialogData.data.rubric,
          cost: formattedNumber(dialogData.data.cost),
          date: formattedDate(dialogData.data.expenseDate),
          description: dialogData.data.description
            ? dialogData.data.description
            : "",
          justification: dialogData.data.justification
            ? dialogData.data.justification
            : "",
          files: dialogData.data.sgppdFile,
          rejectedFiles: [],
        });
      }
      setDialogMode(dialogData.mode);
      setOpenState(dialogData.open);
    }
  }, [dialogData]);

  const handleClose = () => {
    // Reset State
    setOpenState(false);
    setShowRequiredField(false);
    setDialogData(null, t("GENERAL.viewMode"), false);
    setDataState({
      craftNumber: "",
      name: "",
      rubric: "",
      cost: "",
      date: new Date(),
      description: "",
      justification: "",
      files: [],
      rejectedFiles: [],
    });
    setToDeleteFile([]);
  };

  const handleFieldChange = (field) => (event) => {
    setShowRequiredField(false);
    if (event && event.target) {
      setDataState({ ...dataState, [field]: event.target.value });
    } else if (event) {
      setDataState({ ...dataState, [field]: event });
    } else {
      setDataState({ ...dataState, [field]: "" });
    }
  };

  const handleFieldCostChange = (field) => (event) => {
    setShowRequiredField(false);
    if (event.target && !event.target.value.includes(" ")) {
      let str = event.target.value.split("");
      let aux = str.filter((el) => el !== "." && el !== ",").join("");
      let value = event.target.value;

      if (event.target.value)
        value = converTotBrCurrency(aux.split("").reverse().join(""));

      aux = value
        .split("")
        .filter((el) => el !== ".")
        .join("")
        .replace(",", ".");

      if (!isNaN(aux)) setDataState({ ...dataState, [field]: value });
    }
  };

  const handleSubmit = () => {
    if (
      dataState.name !== "" &&
      dataState.craftNumber !== "" &&
      dataState.cost !== "" &&
      dataState.date &&
      dataState.rubric
    ) {
      setShowRequiredField(false);
      let files = dataState.files;
      let formData = new FormData();

      for (let i in files) formData.append("files", files[i]);

      let data = {
        projectId: projectId,
        name: dataState.name,
        craftNumber: dataState.craftNumber,
        cost: parseFloat(
          dataState.cost
            .split("")
            .filter((el) => el !== ".")
            .join("")
            .replace(",", ".")
        ),
        expenseDate: dataState.date,
        description: dataState.description,
        justification: dataState.justification,
        rubric: dataState.rubric,
      };

      let hasFiles = dataState.files.length > 0;

      if (dialogMode === t("GENERAL.editMode")) {
        data.id = dialogData.data.id;
        updateExpense(data, formData, toDeleteFile, hasFiles);
      } else {
        createExpense(data, formData, hasFiles);
      }
      // Reset State
      setOpenState(false);
      setDialogData(null, t("GENERAL.viewMode"), false);
      setToDeleteFile([]);
    } else {
      setShowRequiredField(true);
    }
  };

  const onDrop = (acceptedFiles) => {
    let newFiles = [];
    let existentFiles = [];

    for (let i = 0; i < acceptedFiles.length; i++) {
      let hasTheSameName =
        dataState.files.filter(
          (el) =>
            (el.fileName
              ? el.fileName.toLowerCase()
              : el.name.toLowerCase()) === acceptedFiles[i].name.toLowerCase()
        ).length > 0;

      if (hasTheSameName) {
        existentFiles.push(`Arquivo já existente - ${acceptedFiles[i].name}`);
      } else {
        newFiles.push(acceptedFiles[i]);
      }
    }

    setDataState({
      ...dataState,
      files: dataState.files.concat(newFiles),
      rejectedFiles: dataState.rejectedFiles.concat(existentFiles),
    });
  };

  const onRemoveFile = (data) => {
    if (data) {
      let remainingFiles = [];
      let updatedList = [];
      for (const file of dataState.files) {
        if (
          file.path === data.path &&
          file.lastModified === data.lastModified &&
          file.size === data.size
        ) {
          if (file.id) {
            setToDeleteFile([...toDeleteFile, file.id]);
            updatedList = dataState.rejectedFiles.filter(
              (el) => el !== `Arquivo já existente - ${data.fileName}`
            );
          }
          continue;
        }
        remainingFiles.push(file);
      }

      setDataState({
        ...dataState,
        files: remainingFiles,
        rejectedFiles: updatedList,
      });
    }
  };

  const onClickDownload = async (file) => {
    if (dataState && dataState.files.length > 0) {
      const noSlashPath = file.path.replace(/\//g, ";");
      const url = process.env.REACT_APP_SERVER + "api/file/" + noSlashPath;
      axios
        .get(url, {
          headers: {
            "Content-type": "application/json",
            Authorization:
              "bearer " +
              (Cookies.get("auth-token") ? Cookies.get("auth-token") : ""),
          },
          responseType: "blob",
        })
        .then(function (res) {
          const blob = new Blob([res.data], {});
          const name = file.fileName ? file.fileName : file.name;
          fileDownload(blob, name);
        });
    }
  };

  function humanFileSize(size) {
    let i = Math.floor(Math.log(size) / Math.log(1024));
    return (
      (size / Math.pow(1024, i)).toFixed(2) * 1 +
      " " +
      ["B", "kB", "MB", "GB", "TB"][i]
    );
  }

  const converTotBrCurrency = (number) => {
    let temp = "";
    let len = number.length;
    let count = 0;

    for (let i = 0; i < len; i++) {
      if (i === 1) {
        let aux = "," + number[i] + temp;
        temp = aux;
      } else {
        if (count === 3) {
          count = 1;
          let aux = number[i] + "." + temp;
          temp = aux;
        } else {
          if (i > 1) {
            count += 1;
          }
          let aux = number[i] + temp;
          temp = aux;
        }
      }
    }

    return temp;
  };

  const formattedNumber = (number) => {
    let numberToString = number.toString();
    numberToString = numberToString.includes(".")
      ? numberToString
      : numberToString + ".00";
    numberToString =
      numberToString.split(".")[1].length < 2
        ? numberToString + "0"
        : numberToString;
    const convert = numberToString
      .split("")
      .filter((el) => el !== ".")
      .reverse()
      .join("");
    return converTotBrCurrency(convert);
  };

  const formattedDate = (date) => {
    const dateGeneral = new Date(date);
    dateGeneral.setMinutes(
      dateGeneral.getMinutes() + dateGeneral.getTimezoneOffset()
    );
    return dateGeneral;
  };

  const deleteRejectedFile = (rejectedFile) => {
    let updatedList = dataState.rejectedFiles.filter(
      (el) => el !== rejectedFile
    );
    setDataState({ ...dataState, rejectedFiles: updatedList });
  };

  return (
    <Dialog
      open={openState}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      msg="mensagem"
    >
      <DialogTitle className={classes.actionsTitle} style={{ width: "auto" }}>
        {dialogMode === t("GENERAL.viewMode")
          ? t("EXPENSES.viewExpenseTitle").toLocaleUpperCase()
          : dialogMode === t("GENERAL.editMode")
          ? t("EXPENSES.editExpenseTitle").toLocaleUpperCase()
          : t("EXPENSES.addExpenseTitle").toLocaleUpperCase()}
        <IconButton
          style={{ float: "right", marginTop: -10, marginRight: -17 }}
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid container>
            <Grid container item spacing={1} md={12} lg={12}>
              <Grid item xs={3}>
                <MuiThemeProvider theme={tooltipTheme}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    open={dataState.craftNumber === "" && showRequiredField}
                    title={t("GENERAL.requiredField")}
                  >
                    <TextField
                      value={dataState.craftNumber}
                      label={t("EXPENSES.craftNumberLabel")}
                      margin="normal"
                      required
                      onChange={handleFieldChange("craftNumber")}
                      disabled={dialogMode === t("GENERAL.viewMode")}
                    />
                  </Tooltip>
                </MuiThemeProvider>
              </Grid>
              <Grid item xs={9}>
                <MuiThemeProvider theme={tooltipTheme}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    open={dataState.name === "" && showRequiredField}
                    title={t("GENERAL.requiredField")}
                  >
                    <TextField
                      fullWidth
                      value={dataState.name}
                      label={t("EXPENSES.nameExpenseLabel")}
                      margin="normal"
                      required
                      onChange={handleFieldChange("name")}
                      disabled={dialogMode === t("GENERAL.viewMode")}
                    />
                  </Tooltip>
                </MuiThemeProvider>
              </Grid>
            </Grid>
            <Grid container item spacing={1} md={12} lg={12}>
              <Grid item xs={4}>
                <MuiThemeProvider theme={tooltipTheme}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    open={!dataState.rubric && showRequiredField}
                    title={t("GENERAL.requiredField")}
                  >
                    <TextField
                      style={{ width: "100%" }}
                      select
                      value={dataState.rubric}
                      label={t("EXPENSES.rubricLabel")}
                      margin="normal"
                      required
                      onChange={handleFieldChange("rubric")}
                      disabled={dialogMode === t("GENERAL.viewMode")}
                    >
                      {rubricsState.map((option) => (
                        <MenuItem key={option.value} value={option.label}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Tooltip>
                </MuiThemeProvider>
              </Grid>
              <Grid item xs={4}>
                <MuiThemeProvider theme={tooltipTheme}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    open={dataState.cost === "" && showRequiredField}
                    title={t("GENERAL.requiredField")}
                  >
                    <TextField
                      fullWidth
                      value={dataState.cost}
                      label={t("EXPENSES.costLabel")}
                      margin="normal"
                      required
                      onChange={handleFieldCostChange("cost")}
                      disabled={dialogMode === t("GENERAL.viewMode")}
                    />
                  </Tooltip>
                </MuiThemeProvider>
              </Grid>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={4}>
                  <MuiThemeProvider theme={tooltipTheme}>
                    <Tooltip
                      TransitionComponent={Zoom}
                      open={dataState.date === "" && showRequiredField}
                      title={t("GENERAL.requiredField")}
                    >
                      <KeyboardDatePicker
                        className={classes.textField}
                        disableToolbar
                        autoOk={true}
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        label={t("EXPENSES.dateExpense")}
                        value={dataState.date}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        required
                        onChange={handleFieldChange("date")}
                        disabled={dialogMode === t("GENERAL.viewMode")}
                      />
                    </Tooltip>
                  </MuiThemeProvider>
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid container item spacing={1} md={12} lg={12}>
              <Grid item xs={12}>
                <TextField
                  multiline
                  fullWidth
                  variant="outlined"
                  rows="3"
                  value={dataState.description}
                  label={t("PROFILES.descriptionLabel")}
                  margin="normal"
                  onChange={handleFieldChange("description")}
                  disabled={dialogMode === t("GENERAL.viewMode")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  fullWidth
                  variant="outlined"
                  rows="3"
                  value={dataState.justification}
                  label={t("PROFILES.justificationLabel")}
                  margin="normal"
                  onChange={handleFieldChange("justification")}
                  disabled={dialogMode === t("GENERAL.viewMode")}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={1} md={12} lg={12}>
              <Grid item xs={12}>
                {dialogMode !== t("GENERAL.viewMode") && (
                  <Dropzone
                    onDrop={onDrop}
                    accept="application/pdf, 
                                                application/msword, 
                                                application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                                image/jpeg, 
                                                image/png"
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div
                          {...getRootProps({
                            className: classes.dropzoneDialog,
                          })}
                        >
                          <input {...getInputProps()} />
                          <AttachFileIcon />
                          <Typography>
                            Arraste e solte os documentos para anexá-los ou{" "}
                            <u>clique aqui</u>
                          </Typography>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant={"body1"} style={{ fontWeight: "bold" }}>
                  Documentos
                </Typography>
                {dataState.rejectedFiles.length > 0 && (
                  <div style={{ paddingBottom: 8 }}>
                    {dataState.rejectedFiles.map((message) => (
                      <div className={classes.dialogFile}>
                        <div className={classes.textFile}>
                          <Typography
                            style={{ color: "red" }}
                            className={classes.nameFile}
                          >
                            {message}
                          </Typography>
                        </div>
                        <div className={classes.iconsFile}>
                          <IconButton
                            onClick={() => deleteRejectedFile(message)}
                          >
                            <Clear />
                          </IconButton>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {dataState.files.length > 0 ? (
                  <div className={classes.dropzoneDialogFiles}>
                    {dataState.files.map((file) => (
                      <div className={classes.dialogFile}>
                        <div className={classes.textFile}>
                          <Typography className={classes.nameFile}>
                            {file.fileName ? file.fileName : file.name}
                          </Typography>
                          <Typography>{`(${humanFileSize(
                            file.size
                          )})`}</Typography>
                        </div>
                        <div className={classes.iconsFile}>
                          {file.fileName && (
                            <IconButton onClick={() => onClickDownload(file)}>
                              <GetAppIcon />
                            </IconButton>
                          )}
                          {dialogMode !== t("GENERAL.viewMode") && (
                            <IconButton onClick={() => onRemoveFile(file)}>
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className={classes.noFilesMessage}>
                    <Typography variant={"h6"} style={{ fontWeight: "bold" }}>
                      {t("EXPENSES.noFilesMessage")}
                    </Typography>
                    <Typography variant={"caption"}>
                      {t("EXPENSES.noFilesSubMessage")}
                    </Typography>
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Buttons
          onClick={handleClose}
          tipo="BN-noback"
          conteudo={
            dialogMode === t("GENERAL.viewMode")
              ? t("GENERAL.close").toLocaleUpperCase()
              : t("GENERAL.cancel").toLocaleUpperCase()
          }
        />
        {dialogMode !== t("GENERAL.viewMode") && (
          <Buttons
            onClick={handleSubmit}
            tipo="BN-blue"
            conteudo={
              dialogMode === t("GENERAL.editMode")
                ? t("GENERAL.update").toLocaleUpperCase()
                : t("GENERAL.add").toLocaleUpperCase()
            }
          />
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DialogExpense;