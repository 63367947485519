/* eslint-disable */
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';
import Attachment from '@material-ui/icons/Attachment';
import CloseIcon from '@material-ui/icons/Close';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Footer from 'components/layout/Footer';
import Spinner from 'components/layout/spinners/Index';
import AllowancesContext from 'context/allowance/allowanceContext';
import ExternalUserContext from 'context/externalUsers/externalUsersContext';
import InventoryContext from 'context/inventory/inventoryContext';
import React, { useContext, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import { animated, useTransition } from 'react-spring';
import { useStyles } from '../layout/CommonStyles';
import MySnackbarContentWrapper from '../layout/Message';
import { phoneMask } from 'utils/masks/phoneMask';

const tooltipTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#2c3e51'
        }
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                color: 'white',
                fontSize: '1em',
                backgroundColor: 'red',
                opacity: '0.7'
            }
        }
    }
});

const NewExternalUser = () => {
    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }
    const classes = useStyles();
    const [t] = useTranslation();
    const inventoryContext = useContext(InventoryContext);
    const allowanceContext = useContext(AllowancesContext);
    const externalUsersContext = useContext(ExternalUserContext);
    const [placesArray, setPlacesArray] = useState([]);
    const [hasUpdatedFile, setHasUpdatedFile] = useState(false);
    const [mustRedirect, setMustRedirect] = useState(false);
    const [selectedPlacesArray, setSelectedPlacesArray] = useState([]);
    const [itemSelected, setItemSelected] = useState('');
    const [titleName, setTitle] = useState('GENERAL.requiredField');

    const [snackBar, setSnackBar] = useState({
        open: false,
        kind: '',
        content: ''
    });

    const [values, setValues] = useState({
        name: '',
        phone: '',
        email: '',
        description: ''
    });
    const [requiredName, setRequiredName] = useState(false);

    const [files, setFiles] = useState([]);

    const { getPlaces, places } = inventoryContext;

    const {
        loading,
        response,
        hasChanged,
        setHasChanged,
        responseType,
        successiveActions,
        addExternalUser,
        getExternalUsers,
        externalUsers
    } = externalUsersContext;

    const {
        allowanceResponse,
        allowanceResponseType,
        messageHasChanged,
        resetAllowanceMessage
    } = allowanceContext;

    const [toDeleteFile, setToDeleteFile] = useState([]);

    useEffect(() => {
        getPlaces();
        getExternalUsers();
    }, []);

    const transitions = useTransition(loading, null, {
        from: { opacity: 0 },
        enter: {
            opacity: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: 'calc(100vh - 70px)'
        },
        leave: { opacity: 0 }
    });

    const [dataStates, setDataStates] = useState({
        places: []
    });

    const sortElement = elemnts => {
        elemnts.sort(function (a, b) {
            if (a.name === null) a.name = '';
            if (b.name === null) b.name = '';

            if (
                a.name
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .trim() >
                b.name
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .trim()
            ) {
                return 1;
            }
            if (
                a.name
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .trim() <
                b.name
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .trim()
            ) {
                return -1;
            }
            // a must be equal to b
            return 0;
        });
    };

    useEffect(() => {
        const myPlaces = [...places];
        sortElement(myPlaces);

        var aux = {};
        var _places = [];
        for (var i = 0; i < myPlaces.length; i++) {
            var item = myPlaces[i];
            aux.value = item.id;
            aux.label = item.name;
            _places.push(aux);
            aux = {};
        }
        setPlacesArray(_places);
    }, [places]);

    var placeFilterSelected = placesArray.filter(
        x => !selectedPlacesArray.includes(x.value)
    );
    var difference = placesArray.filter(
        x => selectedPlacesArray.indexOf(x.value) === -1
    );

    useEffect(() => {
        if (allowanceResponseType === 'ERROR' && messageHasChanged) {
            handleSnack({ kind: 'error', content: allowanceResponse });
        }
        if (responseType === 'SUCCESS' && hasChanged > successiveActions) {
            handleSnack({ kind: 'success', content: response });
        }
        if (responseType === 'ERROR' && hasChanged > successiveActions) {
            handleSnack({ kind: 'error', content: response });
        }
        return () => {};
    }, [
        responseType,
        response,
        successiveActions,
        allowanceResponseType,
        allowanceResponse
    ]);

    const handleSnack = () => {
        setSnackBar({ ...snackBar, open: true });
        setHasChanged();
    };
    const handleSnackClose = () => {
        setSnackBar({ ...snackBar, open: false });
        resetAllowanceMessage();
    };

    const onDrop = acceptedFiles => {
        if (acceptedFiles[0] !== undefined) {
            setHasUpdatedFile(true);
            setFiles(
                acceptedFiles.map(file =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })
                )
            );
        }
    };

    const addPlaces = place => {
        setDataStates({
            ...dataStates,
            places: dataStates.places.concat(place)
        });
        setItemSelected('');
        selectedPlacesArray.push(place.value);
    };

    const onRemoveFile = data => {
        if (data) {
            let remainingFiles = [];
            for (const file of dataStates.places) {
                if (file.id === data.id && file.label === data.label) {
                    if (file.id) {
                        setToDeleteFile([...toDeleteFile, file]);
                    }
                    continue;
                }
                remainingFiles.push(file);
            }

            setDataStates({ ...dataStates, places: remainingFiles });
        }
    };

    const image = files.map(file => (
        <div className={classes.thumb} key={file.name}>
            <div className={classes.thumbInner}>
                <img src={file.preview} className={classes.imgNewEquipment} />
            </div>
        </div>
        // {t("Alterar imagem")}
    ));

    const handleChange = (name, value) => {
        if (name === 'phone') {
            setValues({ ...values, [name]: phoneMask(value) });
        } else if (name === 'name') {
            setValues({ ...values, [name]: value });
            var existingNames = externalUsers.filter(
                item =>
                    item.name.toLowerCase().trim() ===
                    value.toLowerCase().trim()
            );
            if (existingNames.length !== 0) {
                setTitle('EXTERNALUSERS.nameAlreadyTaken');
                setRequiredName(true);
            } else {
                setTitle('');
                setRequiredName(false);
            }
        } else {
            setValues({ ...values, [name]: value });
        }
    };

    const verifyCanSubmit = () => {
        let res = true;
        if (!values.name) {
            res = false;
            setRequiredName(true);
        }

        if (titleName === 'EXTERNALUSERS.nameAlreadyTaken') {
            res = false;
        }

        return res;
    };

    const submit = () => {
        const canSubmit = verifyCanSubmit();
        if (canSubmit) {
            let formData = new FormData();
            formData.append('external.name', values.name);
            formData.append('external.phoneNumber', values.phone);
            formData.append('external.email', values.email);
            formData.append('external.description', values.description);

            // Adding equipment image
            if (files[0]) {
                formData.append('image', files[0]);
            }
            addExternalUser(formData, dataStates.places);
            setMustRedirect(true);
        }
    };

    const redirect = go => {
        if (go) return <Redirect to={`/ExternalUsers`} />;
    };

    return transitions.map(({ item, key, props }) =>
        item ? (
            <animated.div key={key} style={props}>
                <Spinner />;
            </animated.div>
        ) : (
            <animated.div key={key} style={props}>
                {redirect(mustRedirect)}
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={snackBar.open}
                    onClose={handleSnackClose}
                    TransitionComponent={Slide}
                    transitionDuration={{ enter: 500, exit: 500 }}
                    ContentProps={{
                        'aria-describedby': 'message-id'
                    }}
                    autoHideDuration={2000}
                >
                    <MySnackbarContentWrapper
                        onClose={handleSnackClose}
                        variant={
                            responseType !== ''
                                ? responseType.toLowerCase()
                                : allowanceResponseType.toLowerCase()
                        }
                        message={
                            <span id="message-id">
                                {response !== '' ? response : allowanceResponse}
                            </span>
                        }
                    />
                </Snackbar>
                <div className="container">
                    <Breadcrumbs
                        className={classes.breadCrumbs}
                        aria-label="breadcrumb"
                        separator={<NavigateNextIcon fontSize="small" />}
                    >
                        <Typography>{t('INVENTORY.inventory')}</Typography>
                        <Link className={classes.link} to="/ExternalUsers">
                            <Typography>
                                {t('EXTERNALUSERS.externalUsers')}
                            </Typography>
                        </Link>
                        <Typography>
                            {t('EXTERNALUSERS.addExternalUser')}
                        </Typography>
                    </Breadcrumbs>
                    <div className="container-header">
                        <div className="title">
                            <h1 className={classes.title}>
                                {t('EXTERNALUSERS.addExternalUser')}
                            </h1>
                        </div>
                        <Button
                            className={classes.blueButton}
                            variant="contained"
                            color="primary"
                            onClick={submit}
                        >
                            {t('GENERAL.add')}
                        </Button>
                    </div>
                    <Paper>
                        <div className="cabecalho">
                            {t('EXTERNALUSERS.externalUserData').toUpperCase()}
                        </div>
                        <div className={classes.bodyNewEquipment}>
                            <Grid container spacing={3}>
                                <Grid
                                    item
                                    xs={2}
                                    className={classes.backgroundImage}
                                >
                                    <Grid item xs={2}>
                                        <Dropzone
                                            variant="outlined"
                                            component="span"
                                            className={classes.textField}
                                            onDrop={onDrop}
                                            accept="image/jpeg,
                                                        image/png,
                                                        image/bmp"
                                        >
                                            {({
                                                getRootProps,
                                                getInputProps,
                                                isDragActive,
                                                isDragReject
                                            }) => (
                                                <div {...getRootProps()}>
                                                    <input
                                                        {...getInputProps()}
                                                    />
                                                    {!isDragActive && (
                                                        <FormControl
                                                            className={
                                                                classes.customAttachedDocumentTextField
                                                            }
                                                        >
                                                            {!hasUpdatedFile ? (
                                                                <Grid
                                                                    item
                                                                    xs={2}
                                                                >
                                                                    <div
                                                                        className={
                                                                            classes.attachEquipment
                                                                        }
                                                                    >
                                                                        {t(
                                                                            'Carregar imagem'
                                                                        )}
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            classes.thumb
                                                                        }
                                                                        key={
                                                                            'upload-equipament-image.jpg'
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={
                                                                                classes.thumbInner
                                                                            }
                                                                        >
                                                                            <img
                                                                                className={
                                                                                    classes.imgNewEquipment
                                                                                }
                                                                                alt="add-image"
                                                                                src={
                                                                                    window
                                                                                        .location
                                                                                        .origin +
                                                                                    '/img/upload-equipament-image.png'
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Grid>
                                                            ) : (
                                                                <Grid
                                                                    item
                                                                    xs={2}
                                                                >
                                                                    <div
                                                                        className={
                                                                            classes.attachEquipment
                                                                        }
                                                                    >
                                                                        {t(
                                                                            'Alterar imagem'
                                                                        )}
                                                                    </div>
                                                                    {image}
                                                                </Grid>
                                                            )}
                                                        </FormControl>
                                                    )}
                                                    {isDragReject && (
                                                        <FormControl
                                                            className={
                                                                classes.customAttachedDocumentTextField
                                                            }
                                                        >
                                                            <Input
                                                                id="custom-attached-input-cooperation"
                                                                readOnly={true}
                                                                endAdornment={
                                                                    <InputAdornment
                                                                        style={{
                                                                            transform:
                                                                                'rotate(270deg)'
                                                                        }}
                                                                        position="end"
                                                                    >
                                                                        <Attachment />
                                                                    </InputAdornment>
                                                                }
                                                                aria-describedby="weight-helper-text"
                                                                inputProps={{
                                                                    'aria-label':
                                                                        'weight'
                                                                }}
                                                                value={t(title)}
                                                            />
                                                        </FormControl>
                                                    )}
                                                </div>
                                            )}
                                        </Dropzone>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={9}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={5}>
                                            <MuiThemeProvider
                                                theme={tooltipTheme}
                                            >
                                                <Tooltip
                                                    TransitionComponent={Zoom}
                                                    open={requiredName}
                                                    title={t(titleName)}
                                                >
                                                    <TextField
                                                        className={
                                                            classes.textField
                                                        }
                                                        label={
                                                            t(
                                                                'EXTERNALUSERS.name'
                                                            ) + '*'
                                                        }
                                                        value={values.name}
                                                        onChange={ev => {
                                                            if (
                                                                ev.target.value
                                                                    .length <=
                                                                50
                                                            )
                                                                handleChange(
                                                                    'name',
                                                                    ev.target
                                                                        .value
                                                                );
                                                        }}
                                                        margin="normal"
                                                    />
                                                </Tooltip>
                                            </MuiThemeProvider>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                className={classes.textField}
                                                label={t('EXTERNALUSERS.phone')}
                                                value={values.phone}
                                                onChange={ev => {
                                                    if (
                                                        ev.target.value
                                                            .length <= 50
                                                    )
                                                        handleChange(
                                                            'phone',
                                                            ev.target.value
                                                        );
                                                }}
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                className={classes.textField}
                                                label={t('EXTERNALUSERS.email')}
                                                value={values.email}
                                                onChange={ev => {
                                                    if (
                                                        ev.target.value
                                                            .length <= 50
                                                    )
                                                        handleChange(
                                                            'email',
                                                            ev.target.value
                                                        );
                                                }}
                                                margin="normal"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <React.Fragment>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        style={{ paddingBottom: 0 }}
                                    >
                                        <Typography
                                            className={
                                                classes.titleNewEquipment
                                            }
                                        >
                                            Locais Relacionados
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        style={{
                                            border: '1px solid rgba(0, 0, 0, 0.28)',
                                            borderRadius: 4,
                                            margin: 10
                                        }}
                                    >
                                        <Grid item xs={12}>
                                            {dataStates.places.length > 0 && (
                                                <div>
                                                    {dataStates.places.map(
                                                        file => (
                                                            <div
                                                                className={
                                                                    classes.dialogFile
                                                                }
                                                                key={file.value}
                                                            >
                                                                <div
                                                                    className={
                                                                        classes.textFile
                                                                    }
                                                                >
                                                                    <Typography
                                                                        className={
                                                                            classes.nameFile
                                                                        }
                                                                    >
                                                                        {file.label
                                                                            ? file.label
                                                                            : file.label}
                                                                    </Typography>
                                                                </div>
                                                                <div
                                                                    className={
                                                                        classes.iconsFile
                                                                    }
                                                                >
                                                                    <IconButton
                                                                        onClick={() =>
                                                                            onRemoveFile(
                                                                                file
                                                                            )
                                                                        }
                                                                    >
                                                                        <CloseIcon />
                                                                    </IconButton>
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            )}
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={12}>
                                                <Autocomplete
                                                    style={{ margin: 10 }}
                                                    inputValue={itemSelected}
                                                    onChange={(
                                                        event,
                                                        newValue
                                                    ) => {
                                                        addPlaces(newValue);
                                                    }}
                                                    id="combo-box-demo"
                                                    options={
                                                        placeFilterSelected
                                                    }
                                                    getOptionLabel={option =>
                                                        option.label
                                                    }
                                                    onInputChange={(
                                                        event,
                                                        newValue
                                                    ) => {
                                                        if (event) {
                                                            setItemSelected(
                                                                event.target
                                                                    .value
                                                            );
                                                        }
                                                    }}
                                                    closeIcon={true}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            label="Locais"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        multiline
                                        fullWidth
                                        variant="outlined"
                                        rows="6"
                                        id="description"
                                        value={values.description}
                                        onChange={ev => {
                                            if (ev.target.value.length <= 500)
                                                handleChange(
                                                    'description',
                                                    ev.target.value
                                                );
                                        }}
                                        label={t(
                                            'INVENTORY.descriptionBookCategory'
                                        )}
                                        margin="normal"
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </Paper>
                </div>
                <Footer />
            </animated.div>
        )
    );
};

export default NewExternalUser;
