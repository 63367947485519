import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import { useStyles } from "components/layout/CommonStyles";
import 'moment/locale/pt-br';
import React, { useContext, useEffect, useState, useRef } from "react";
import 'react-day-picker/lib/style.css';
import { useTranslation } from "react-i18next";
import MySnackbarContentWrapper from "../../layout/Message";
import Grid from "@material-ui/core/Grid";
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { PDFViewer } from '@react-pdf/renderer';
import GeneralReport from "./generalReport";
import FinancialReport from "./TempFinancialReport";
import Charts from "./charts";
import { Document, PDFDownloadLink } from '@react-pdf/renderer';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from "axios";
import moment from "moment";
import "moment/locale/pt-br";

const HtmlTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: 'white',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

var returnedimageurl = ""
var len = 0
var cont = 0
var aux = 0;
var linkGraph = "";
const ReportsIndex = props => {
    const { projectId, projectSubsides, mode, periodFilter, allAllowances, seasonsArray, seasonsArrayReports, dataReports, expenses, costByProject } = props;
    const [t] = useTranslation();
    const classes = useStyles();
    let dataFilter = [];
    dataFilter = projectSubsides;
    const [season, setSeason] = useState(seasonsArrayReports[seasonsArrayReports.length - 1] ? seasonsArrayReports[0].label : "");
    const [seasonFinancial, setSeasonFinancial] = useState(seasonsArrayReports[seasonsArrayReports.length - 1] ? seasonsArrayReports[0].label : "");
    const subTitle = dataReports[0] && "GPF: " + dataReports[0].gpf + " | " + dataReports[0].information["name"]
    const [ReportType, setReportType] = useState(0);
    const [url, setUrl] = useState("");
    const [comp, setComp] = useState(0);
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [subTitleGeneral, setsubTitleGeneral] = useState(dataReports[0] && "GPF: " + dataReports[0].gpf + " | " + dataReports[0].information["name"])
    const [subTitleTemporal, setsubTitleTemporal] = useState(dataReports[0] && "GPF: " + dataReports[0].gpf + " | " + dataReports[0].information["name"])
    const [limitDate, setLimitDate] = useState(seasonsArrayReports[seasonsArrayReports.length - 1] ? seasonsArrayReports[seasonsArrayReports.length - 1].endDate : new Date());
    const [termT, setTermT] = useState(0);
    const [termG, setTermG] = useState(0);
    const [dates, setDates] = useState({
        startDate: new Date(),
        endDate: new Date()
    });
    var aportGraphFormated = []

    const [Financialdates, setFinancialDates] = useState({
        startDate: new Date(),
        endDate: new Date()
    });

    const getPermission = (screen) => {
        var allowance;
        if (mode === "edit") {
            allowance = "allowUpdate"
        } else {
            allowance = "allowRead"
        }
        for (let i = 0; i < allAllowances.length; i++) {
            if (allAllowances[i].resource === screen) {
                return allAllowances[i]["standardauthorizationoption"][allowance]
            }
        }
    }

    // const getPermission = (screen, allowance) => {
    //     for (let i = 0; i < allAllowances.length; i++) {
    //         if (allAllowances[i].resource === screen) {

    //             return allAllowances[i]["standardauthorizationoption"][allowance]
    //         }
    //     }
    // }

    const optionsModels = [
        {
            value: 0,
            label: "Analítico"
        },
        {
            value: 1,
            label: "Cronológico"
        }
    ];

    const handleDateChange = name => e => {
        setDates({
            ...dates,
            [name]: e
        });
    };

    const handleDateFinancialChange = name => e => {
        setComp(comp + 1)
        setFinancialDates({
            ...Financialdates,
            [name]: e
        });
    };

    const handleChange = name => event => {
        var n = parseInt(event.target.value);
        setReportType(n)
    };

    var dataReportsWithoutGeneral = []

    dataReports.forEach(el => {
        if (el.number !== "GENERAL") {
            dataReportsWithoutGeneral.push(el)
        }
    })

    const handleChangeSeason = event => {
        setSeason(event.target.value);

        var valueDate = new Date(event.target.value.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"))
        dataReportsWithoutGeneral.forEach(function (el, index) {
            var date = new Date(el.startdate);
            if (el.number !== "GENERAL" && date.getTime() === valueDate.getTime()) {
                setTermG(index + 1)
                setsubTitleGeneral(dataReports[index] && "GPF: " + dataReports[index].gpf + " | " + dataReports[0].information["name"])
            }
        })


        // setDates({
        //     ...dates,
        //     endDate: new Date(event.target.value.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"))
        // });
    };

    const handleChangeSeasonFinancial = event => {
        setSeasonFinancial(event.target.value);
        setComp(comp + 1)

        var valueDate = new Date(event.target.value.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"))
        dataReportsWithoutGeneral.forEach(function (el, index) {
            var date = new Date(el.startdate);
            if (el.number !== "GENERAL" && date.getTime() === valueDate.getTime()) {
                setTermT(index + 1)
                setsubTitleTemporal(dataReports[index] && "GPF: " + dataReports[index].gpf + " | " + dataReports[0].information["name"])
            }
        })


        // setFinancialDates({
        //     ...Financialdates,
        //     endDate: new Date(event.target.value.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"))
        // });
    };

    var graphCostsFormated = [];
    var graphAportsFormated = [];
    var generalReportCosts = [];
    var generalViewFiancial = [];
    var costsRhGraph = [];
    var costsAnalyticalReportFiancial = [];
    var costsChronologicalReportFiancial = [];
    var costsAnalyticalReportFiancialFormated = [];
    var costsByColabAnalyticalReportFiancial = [];
    var terms = 0;
    var terms2 = 0;
    var seasonStart = new Date(season.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"))
    var seasonStartFinancial = seasonFinancial ? new Date(seasonFinancial.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3")) : null
    var totRhTable = 0;
    function compare(a, b) {
        return new Date(a.expenseDate) - new Date(b.expenseDate);
    }
    function compare2(a, b) {
        return new Date(a.date) - new Date(b.date);
    }
    function compare3(a, b) {
        return new Date(a.dateSort) - new Date(b.dateSort);
    }
    function compare4(a, b) {
        return a.date - b.date;
    }
    var selectedEndDateFinancial2 = new Date(Financialdates.endDate)
    var testeallaports = []
    var auxGeneralView = {};
    auxGeneralView.totBudget = 0;
    auxGeneralView.totReceived = 0;
    auxGeneralView.totSpent = 0;
    var _arrayCostFinancialReport = [];
    _arrayCostFinancialReport.totRhCostFinanAnalyt = 0;

    var _arrayCost = [];
    _arrayCost.totRhDirect = 0;
    _arrayCost.totRhIndirect = 0;
    const getDataRepotsSeason = () => {
        var _arraySgppdBudgetItem = [];
        var auxSgppdBudgetItem = {};
        var _arrayProjectSubsidy = [];
        var _arrayGeneralView = [];

        var _arrayCostGraph = [1, 2, 3, 4];
        var auxCostGraph = {};
        var aportCopy = [];

        for (var i = 0; i < dataReports.length; i++) {
            var startdate = new Date(dataReports[i].startdate);
            startdate.setMinutes(startdate.getMinutes() + startdate.getTimezoneOffset())
            //var startdate = new Date(dataReports[i].startdate)
            var selectEndDate = new Date(dates.endDate)
            var selectedEndDateFinancial = new Date(Financialdates.endDate)
            var Selected = new Date(dates.endDate);

            //  ---------------------------

            if (startdate.getTime() >= seasonStart.getTime() && startdate.getTime() <= Selected.getTime() && dataReports[i].number !== "GENERAL") {
                terms = terms + 1
                var elementSubsidy = {}, cart = [];
                if (dataReports[i].sgppdBudgetItem) {
                    for (var y = 0; y < dataReports[i].sgppdBudgetItem.length; y++) {
                        var rubrics = dataReports[i].sgppdBudgetItem[y];

                        auxSgppdBudgetItem.rubric = rubrics.rubric.toUpperCase();
                        auxSgppdBudgetItem.valueRubrics = rubrics.value;
                        _arraySgppdBudgetItem.push(auxSgppdBudgetItem)
                        auxSgppdBudgetItem = {}

                        var projectSubsidy = dataReports[i].projectSubsidy[y];
                        if (projectSubsidy !== undefined) {
                            //Aportes(recebidos)
                            projectSubsidy.projectSubsidyHeading.forEach(element => {
                                elementSubsidy.headingName = element.headingName
                                elementSubsidy.headingValue = element.headingValue
                                elementSubsidy.headingDate = projectSubsidy.subsidyDate
                                _arrayProjectSubsidy.push(elementSubsidy)
                                elementSubsidy = {}
                            })
                        }
                    }

                    var names = [];
                    var namesExist = [];
                    var jsonCopy = [];

                    var Subsidy = [];
                    var SubsidyExist = [];
                    var SubsidyCopy = [];

                    //Rubricas (Orçado)
                    _arraySgppdBudgetItem.forEach(element => {
                        let index = names.indexOf(element.rubric);
                        if (index == -1) {
                            names.push(element.rubric);
                            jsonCopy.push([element.rubric, element.valueRubrics])
                        } else {
                            namesExist.push([index, element.rubric, element.valueRubrics]);
                        }
                    });

                    for (let index = 0; index < namesExist.length; index++) {
                        jsonCopy[namesExist[index][0]][1] += parseInt(namesExist[index][2]);
                    }


                    // var _arrayProjectSubsidyAll = [];
                    // _arrayProjectSubsidy.forEach(element => {
                    //     element.forEach(el => {
                    //         _arrayProjectSubsidyAll.push(el)
                    //     })
                    // });

                    _arrayProjectSubsidy.forEach(element => {
                        let index = Subsidy.indexOf(element.headingName);
                        //var date = new Date(element.headingDate)
                        var date = new Date(element.headingDate);
                        date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
                        if (date >= seasonStart && date <= selectEndDate) {
                            if (index == -1) {
                                Subsidy.push(element.headingName);
                                SubsidyCopy.push([element.headingName, element.headingValue])
                            } else {
                                SubsidyExist.push([index, element.headingName, element.headingValue]);
                            }
                        }
                    });

                    for (let index = 0; index < SubsidyExist.length; index++) {
                        SubsidyCopy[SubsidyExist[index][0]][1] += parseInt(SubsidyExist[index][2]);
                    }
                }
            }

            // -----------------------------------------------------------------------------------
            // DATA FINANCIAL REPORT

            expenses && expenses.sort(compare);

            if (seasonStartFinancial) {
                if (startdate.getTime() >= seasonStartFinancial.getTime() && startdate.getTime() <= selectedEndDateFinancial.getTime() && dataReports[i].number !== "GENERAL") {
                    terms2 = terms2 + 1

                    var data1 = seasonStartFinancial
                    var data2 = selectedEndDateFinancial
                    var total = (data2.getFullYear() - data1.getFullYear()) * 12 + (data2.getMonth() - data1.getMonth());

                    //Recebido (aporte)
                    var aport = [];
                    var aportExist = [];
                    var auxvalues = {}
                    auxGeneralView.totBudget += dataReports[i].projectBudget[0] ? dataReports[i].projectBudget[0].previousBalance : 0
                    if (dataReports[i].projectSubsidy.length > 0) {
                        for (var y = 0; y < dataReports[i].projectSubsidy.length; y++) {
                            var projectBudgetQuotaTot = dataReports[i].projectSubsidy[y].total
                            var element = dataReports[i].projectSubsidy[y]
                            //var date = new Date(dataReports[i].projectSubsidy[y].subsidyDate)
                            var date = new Date(dataReports[i].projectSubsidy[y].subsidyDate);
                            date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
                            if (date >= seasonStartFinancial && date <= selectedEndDateFinancial) {
                                auxGeneralView.totReceived += projectBudgetQuotaTot

                                let dateAport = new Date(date.getFullYear(), date.getMonth())
                                dateAport.setMinutes(date.getMinutes() + date.getTimezoneOffset())

                                let index = aport.indexOf(element.subsidyDate);
                                if (index == -1) {
                                    aport.push(element.subsidyDate);
                                    // auxvalues.cost = element.total;
                                    // auxvalues.date = element.subsidyDate
                                    aportCopy.push([dateAport.toString(), element.total])
                                    //auxvalues = {}
                                } else {
                                    aportExist.push([index, element.id, element.total, element.subsidyDate]);
                                }
                            }
                        }
                    }

                    //sort by date
                    aportCopy.sort(compare2);

                    //Gasto = custo de colaboradores + dispesas
                    var costId = [];
                    var costIdExist = [];
                    var costCopy = [];

                    if (expenses.length > 0) {
                        expenses.forEach(element => {
                            //var date = new Date(element.expenseDate)
                            var date = new Date(element.expenseDate);
                            date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
                            if (date >= seasonStartFinancial && date <= selectedEndDateFinancial) {
                                //auxGeneralView.totSpent += element.cost

                                auxCostGraph.id = element.id
                                let index = costId.indexOf(element.expenseDate);
                                if (index == -1) {
                                    // costId.push(element.id);
                                    // auxCosts.cost = element.cost;
                                    // auxCosts.date = element.expenseDate
                                    // costCopy.push(auxCosts)
                                    // auxCosts = {}
                                    costId.push(element.expenseDate);
                                    costCopy.push([element.expenseDate, element.cost])
                                } else {
                                    costIdExist.push([index, element.id, element.cost, element.expenseDate]);
                                }
                            }
                        })

                        for (let index = 0; index < costIdExist.length; index++) {
                            costCopy[costIdExist[index][0]][1] += parseInt(costIdExist[index][2]);
                        }
                    }

                    // Orçado
                    if (dataReports[i].projectBudget.length > 0) {
                        if (dataReports[i].projectBudget[0].projectBudgetQuota) {
                            var projectBudgetQuota = dataReports[i].projectBudget[0].projectBudgetQuota
                            projectBudgetQuota.forEach(element => {
                                //var date = new Date(element.quotaDate)
                                var date = new Date(element.quotaDate);
                                date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
                                if (date >= seasonStartFinancial && date <= selectedEndDateFinancial) {
                                    auxGeneralView.totBudget += element.quotaValue
                                }
                            })

                        }
                    }
                    generalViewFiancial.push(auxGeneralView);
                }
            }
        }

        function makeid(length) {
            var result = '';
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for (var i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        }

        // gasto
        var auxCostExpensesFinancialReport = {}
        for (var p = 0; p < expenses.length; p++) {
            // var date = new Date(expenses[p].expenseDate)
            var date = new Date(expenses[p].expenseDate);
            date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
            if (date >= seasonStartFinancial && date <= selectedEndDateFinancial) {
                auxCostExpensesFinancialReport.craftNumber = expenses[p].craftNumber
                auxCostExpensesFinancialReport.cost = expenses[p].cost
                var dateFormated = moment(expenses[p].expenseDate).format("DD/MM/YY")
                auxCostExpensesFinancialReport.expenseDate = dateFormated + "|" + makeid(10)
                auxCostExpensesFinancialReport.description = expenses[p].description
                auxCostExpensesFinancialReport.typeOrName = expenses[p].name
                auxCostExpensesFinancialReport.dateSort = expenses[p].expenseDate
                costsAnalyticalReportFiancial.push(auxCostExpensesFinancialReport)
                costsChronologicalReportFiancial.push(auxCostExpensesFinancialReport)
                auxCostExpensesFinancialReport = {}
                //costsAnalyticalReportFiancial.push(expenses[p])
            }
        }

        //All spend per period
        if (costCopy) {
            costCopy.forEach(element => {
                auxGeneralView.totSpent += element[1]
            })
        }

        const monthDict = {
            1: "Jan",
            2: "Fev",
            3: "Mar",
            4: "Abr",
            5: "Mai",
            6: "Jun",
            7: "Jul",
            8: "Ago",
            9: "Set",
            10: "Out",
            11: "Nov",
            12: "Dez"
        };

        // formatar objeto para soma os custo de rh para tabela de custo (total por mes)
        var auxCostFinancialReport = {}
        if (costByProject) {
            costByProject.forEach(element => {
                var date = element.creationDate
                var rhCostDate = element.costYear + "" + element.costMonth
                var statatPeriodFinancial = seasonStartFinancial.getFullYear() + "" + (seasonStartFinancial.getMonth() + 1)
                var selectedEndDatePeriodFinancial = selectedEndDateFinancial.getFullYear() + "" + (selectedEndDateFinancial.getMonth() + 1)

                if (parseInt(rhCostDate) >= parseInt(statatPeriodFinancial) && parseInt(rhCostDate) <= parseInt(selectedEndDatePeriodFinancial)) {

                    if (element.costName === "TotalCost") {
                        auxCostFinancialReport.craftNumber = "-"
                        auxCostFinancialReport.cost = element.cost
                        auxCostFinancialReport.expenseDate = monthDict[(element.costMonth)] + "/" + element.costYear
                        auxCostFinancialReport.description = "Custo geral de RH para o mês"
                        auxCostFinancialReport.typeOrName = "RH*"
                        auxCostFinancialReport.dateSort = date
                        costsAnalyticalReportFiancial.push(auxCostFinancialReport)
                        costsRhGraph.push(auxCostFinancialReport)
                        auxCostFinancialReport = {}
                    }
                }
            });


            // formatar objeto para soma os custo de rh por colaborador (detalhado)
            var auxCostRh = {}
            costByProject.forEach(element => {
                //var date = new Date(element.creationDate)
                var date = new Date(element.creationDate);
                date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
                var rhCostDate = element.costYear + "" + element.costMonth
                var statatPeriodFinancial = seasonStartFinancial.getFullYear() + "" + (seasonStartFinancial.getMonth() + 1)
                var selectedEndDatePeriodFinancial = selectedEndDateFinancial.getFullYear() + "" + (selectedEndDateFinancial.getMonth() + 1)
     
                if (parseInt(rhCostDate) >= parseInt(statatPeriodFinancial) && parseInt(rhCostDate) <= parseInt(selectedEndDatePeriodFinancial)) {
                    if (element.costName === "TotalCost") {
                        auxCostRh.expenseDate = monthDict[(element.costMonth)] + "/" + element.costYear
                        auxCostRh.typeOrName = element.rhType === "0" ? "Rh Direto" : "Rh Indereto"
                        auxCostRh.description = element.name
                        auxCostRh.craftNumber = element.numberCraft ? element.numberCraft : "-"
                        auxCostRh.cost = element.cost
                        auxCostRh.dateSort = date
                        costsByColabAnalyticalReportFiancial.push(auxCostRh)
                        costsChronologicalReportFiancial.push(auxCostRh)
                        auxCostRh = {}
                    }
                }
            });

            costsByColabAnalyticalReportFiancial && costsByColabAnalyticalReportFiancial.sort(compare3);

            //total dos custo de RH
            costsByColabAnalyticalReportFiancial.forEach(el => {
                totRhTable += el.cost
            })

            //sort costChromological by date
            costsChronologicalReportFiancial.sort(compare3);

        }

        // somar os valores de custo de colaboradores por mes (tabela relatorio financeiro temporal)
        var costAnalytical = [];
        var costAnalytical2 = [];
        var costAnalyticalExist = [];
        var costAnalyticalCopy = [];

        if (costsAnalyticalReportFiancial.length > 0) {
            costsAnalyticalReportFiancial.forEach(element => {
                let index = costAnalytical.indexOf(element.expenseDate);
                if (index == -1) {
                    costAnalytical.push(element.expenseDate);
                    costAnalyticalCopy.push([element.expenseDate, element.cost, element.craftNumber, element.typeOrName, element.description, element.dateSort])
                } else {
                    costAnalyticalExist.push([index, element.cost, element.expenseDate]);
                }
            })

            for (let index = 0; index < costAnalyticalExist.length; index++) {
                costAnalyticalCopy[costAnalyticalExist[index][0]][1] += parseInt(costAnalyticalExist[index][1]);
            }
        }

        var auxNewObjectCostAnalytical = {}
        if (costAnalyticalCopy) {
            for (var b = 0; b < costAnalyticalCopy.length; b++) {
                var elemente = costAnalyticalCopy[b]
                auxNewObjectCostAnalytical.craftNumber = elemente[2]
                auxNewObjectCostAnalytical.cost = elemente[1]
                var dateFormated = elemente[0].split("|")
                auxNewObjectCostAnalytical.expenseDate = dateFormated[0]
                auxNewObjectCostAnalytical.description = elemente[4]
                auxNewObjectCostAnalytical.typeOrName = elemente[3]
                auxNewObjectCostAnalytical.dateSort = elemente[5];
                costsAnalyticalReportFiancialFormated.push(auxNewObjectCostAnalytical)
                auxNewObjectCostAnalytical = {}
            }
        }

        costsAnalyticalReportFiancialFormated && costsAnalyticalReportFiancialFormated.sort(compare3);

        // somar os valores de custo de colaboradores por mes (GRÁFICO)
        var rhGraph = [];
        var rhGraph2 = [];
        var rhGraphExist = [];
        var rhGraphCopy = [];

        if (costsRhGraph.length > 0) {
            costsRhGraph.forEach(element => {
                //var date = new Date(element.dateSort)
                var date = new Date(element.dateSort);
                date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
                var dateRHFormated = parseInt(date.getFullYear() + "" + (date.getMonth() + 1))
                var seasonStartFinancialFormated = parseInt(seasonStartFinancial.getFullYear() + "" + (seasonStartFinancial.getMonth() + 1))
                var selectedEndDateFinancialFomated = parseInt(selectedEndDateFinancial.getFullYear() + "" + (selectedEndDateFinancial.getMonth() + 1))
                if (dateRHFormated >= seasonStartFinancialFormated && dateRHFormated <= selectedEndDateFinancialFomated) {
                    let index = rhGraph.indexOf(element.expenseDate);
                    if (index == -1) {
                        rhGraph.push(element.expenseDate);
                        rhGraphCopy.push([element.dateSort, element.cost, "RH"])
                    } else {
                        rhGraphExist.push([index, element.cost, element.expenseDate]);
                    }
                }
            })

            for (let index = 0; index < rhGraphExist.length; index++) {
                rhGraphCopy[rhGraphExist[index][0]][1] += parseInt(rhGraphExist[index][1]);
            }
        }

        //concatena as despesas e RH
        var jsonResultCosts;
        if (costCopy && rhGraphCopy) {
            jsonResultCosts = costCopy.concat(rhGraphCopy);
        } else if (rhGraphCopy && !costCopy) {
            jsonResultCosts = rhGraphCopy
        } else if (costCopy && !rhGraphCopy) {
            jsonResultCosts = costCopy
        }

        var auxNewObjectCostFormated = {}
        var temObjectCosts = []
        if (jsonResultCosts) {
            for (var w = 0; w < jsonResultCosts.length; w++) {
                var elemente = jsonResultCosts[w]
                var date = new Date(elemente[0])
                date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
                var dateformated = new Date(date.getFullYear(), date.getMonth())
                dateformated.setMinutes(dateformated.getMinutes() + dateformated.getTimezoneOffset())
                auxNewObjectCostFormated.date = dateformated.toString()
                auxNewObjectCostFormated.cost = elemente[1]
                temObjectCosts.push(auxNewObjectCostFormated)
                auxNewObjectCostFormated = {}
            }
        }

        //Gasto(GRAFICO) = custo de colaboradores + dispesas (valor final para formatar)
        var costRhToGraphId = [];
        var costRhToGraphIdExist = [];
        var costRhToGraphCopy = [];

        if (temObjectCosts.length > 0) {
            temObjectCosts.forEach(element => {
                let index = costRhToGraphId.indexOf(element.date);
                if (index == -1) {
                    costRhToGraphId.push(element.date);
                    costRhToGraphCopy.push([element.date, element.cost])
                } else {
                    costRhToGraphIdExist.push([index, element.cost, element.date]);
                }
            })

            for (let index = 0; index < costRhToGraphIdExist.length; index++) {
                costRhToGraphCopy[costRhToGraphIdExist[index][0]][1] += parseInt(costRhToGraphIdExist[index][1]);
            }
        }

        // formated cost and aprots for graph
        var auxNewObjectCost = {}
        var teste = []
        if (costRhToGraphCopy) {
            for (var w = 0; w < costRhToGraphCopy.length; w++) {
                var elemente = costRhToGraphCopy[w]
                auxNewObjectCost.date = elemente[0]
                auxNewObjectCost.cost = elemente[1]
                graphCostsFormated.push(auxNewObjectCost)
                auxNewObjectCost = {}
            }
        }

        var auxNewObjectAport = {}
        if (aportCopy) {
            for (var p = 0; p < aportCopy.length; p++) {
                var elemente = aportCopy[p]
                auxNewObjectAport.date = elemente[0]
                auxNewObjectAport.cost = elemente[1]
                graphAportsFormated.push(auxNewObjectAport)
                auxNewObjectAport = {}
            }
        }

         // get categories for graph period
         var contDateCategories = seasonStartFinancial && seasonStartFinancial.getMonth();
         var categories = [];
         var endDateYearCategories = seasonStartFinancial && seasonStartFinancial.getFullYear()
         
         for (var i = -1; i < total; i++) {
             contDateCategories = contDateCategories + 1;
             if (contDateCategories === 13) {
                 contDateCategories = 1;
                 endDateYearCategories = (endDateYearCategories + 1);
                 categories.push(monthDict[(contDateCategories)]+"/"+endDateYearCategories)
             }
             else {
                 categories.push(monthDict[(contDateCategories)]+"/"+endDateYearCategories)
             }
         }
         //-----

        // get all aports for the graph
        var contDateAport = seasonStartFinancial && seasonStartFinancial.getMonth();
        var MonthAportNulls = [];
        var endDateYearFormated = seasonStartFinancial && seasonStartFinancial.getFullYear()
        
        var auxArrayAport = {}
        for (var i = -1; i < total; i++) {
            contDateAport = contDateAport + 1;
            if (contDateAport === 13) {
                contDateAport = 0;
                endDateYearFormated = (endDateYearFormated + 1);
                let date = new Date(endDateYearFormated, contDateAport)
                date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
                auxArrayAport.date = date.toString();
                auxArrayAport.cost = null;
                MonthAportNulls.push(auxArrayAport)
                auxArrayAport = {}
            }
            else {
                let date = new Date(endDateYearFormated, (contDateAport - 1))
                date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
                auxArrayAport.date = date.toString();
                auxArrayAport.cost = null;
                MonthAportNulls.push(auxArrayAport)
                auxArrayAport = {}
            }
        }
       
        var aportsConc = MonthAportNulls.concat(graphAportsFormated);

        //FORMATAR OS CUSTO PARA O GRAFICO COM OS VALORES NULOS
        var aportFormated = [];
        var aportFormatedExist = [];
        var aportFormatedCopy = [];

        if (aportsConc.length > 0) {
            aportsConc.forEach(element => {
                let index = aportFormated.indexOf(element.date);
                if (index == -1) {
                    aportFormated.push(element.date);
                    aportFormatedCopy.push([element.date, element.cost])
                } else {
                    aportFormatedExist.push([index, element.cost, element.date]);
                }
            });

            for (let index = 0; index < aportFormatedExist.length; index++) {
                aportFormatedCopy[aportFormatedExist[index][0]][1] = parseInt(aportFormatedExist[index][1]);
            }
        }

       
        for (var i = 0; i < aportFormatedCopy.length; i++) {
            var elemente = aportFormatedCopy[i]
            aportGraphFormated[i] = elemente[1]
        }
       
        // Ordena os custos por data
        graphCostsFormated.sort(compare4);

        // get all costs for the graph
        var contDateCost = seasonStartFinancial && seasonStartFinancial.getMonth();
        var a = [];
        var endDateYearFormated = seasonStartFinancial && seasonStartFinancial.getFullYear()
        var auxArray = {}
        for (var i = -1; i < (total); i++) {
            contDateCost = contDateCost + 1;
            if (contDateCost === 13) {
                contDateCost = 0;
                endDateYearFormated = (endDateYearFormated + 1);
                let date = new Date(endDateYearFormated, contDateCost)
                date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
                auxArray.date = date.toString();
                auxArray.cost = null;
                a.push(auxArray)
                auxArray = {}
            }
            else {
                let date = new Date(endDateYearFormated, (contDateCost - 1))
                date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
                auxArray.date = date.toString();
                auxArray.cost = null;
                a.push(auxArray)
                auxArray = {}
            }
        }
        var costsConc = a.concat(graphCostsFormated);

        //FORMATAR OS CUSTO PARA O GRAFICO COM OS VALORES NULOS
        var costFormated = [];
        var costFormatedExist = [];
        var costFormatedCopy = [];

        if (costsConc.length > 0) {
            costsConc.forEach(element => {
                let index = costFormated.indexOf(element.date);
                if (index == -1) {
                    costFormated.push(element.date);
                    costFormatedCopy.push([element.date, element.cost])
                } else {
                    costFormatedExist.push([index, element.cost, element.date]);
                }
            });

            for (let index = 0; index < costFormatedExist.length; index++) {
                costFormatedCopy[costFormatedExist[index][0]][1] = parseInt(costFormatedExist[index][1]);
            }
        }

        var costGraphFormated = []
        for (var i = 0; i < costFormatedCopy.length; i++) {
            var elemente = costFormatedCopy[i]
            costGraphFormated[i] = elemente[1]
        }

        costByProject.forEach(element => {
            var date = new Date(element.creationDate)
            var rhCostDateGeneral = parseInt(element.costYear + "" + element.costMonth)
            var statatPeriodGeneral = parseInt(seasonStart.getFullYear() + "" + (seasonStart.getMonth() + 1))
            var selectedEndDatePeriodGeneral = parseInt(selectEndDate.getFullYear() + "" + (selectEndDate.getMonth() + 1))
            if (rhCostDateGeneral >= statatPeriodGeneral && rhCostDateGeneral <= selectedEndDatePeriodGeneral) {
                if (element.rhType === "0" && element.costName === "TotalCost") {
                    _arrayCost.totRhDirect += element.cost
                }
                if (element.rhType === "1" && element.costName === "TotalCost") {
                    _arrayCost.totRhIndirect += element.cost
                }
            }
        });

        var dayEndSelect = selectEndDate
        var _arrayExpense = [];
        var auxExpense = {};
        expenses.forEach(element => {
            //var date = new Date(element.expenseDate)
            var date = new Date(element.expenseDate);
            date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
            if (date >= seasonStart && date <= dayEndSelect) {
                auxExpense.expenseRubric = element.rubric
                auxExpense.expenseCost = element.cost
                _arrayExpense.push(auxExpense);
                auxExpense = {}
            }
        })
        var Expense = [];
        var ExpenseExist = [];
        var ExpenseCopy = [];
        _arrayExpense.forEach(element => {
            let index = Expense.indexOf(element.expenseRubric);
            if (index == -1) {
                Expense.push(element.expenseRubric);
                ExpenseCopy.push([element.expenseRubric, element.expenseCost])
            } else {
                ExpenseExist.push([index, element.expenseRubric, element.expenseCost]);
            }
        });

        for (let index = 0; index < ExpenseExist.length; index++) {
            ExpenseCopy[ExpenseExist[index][0]][1] += parseInt(ExpenseExist[index][2]);
        }

        var auxbubget = {}
        if (jsonCopy) {
            for (var t = 0; t < jsonCopy.length; t++) {
                var elemente = jsonCopy[t]
                auxbubget.rubric = elemente[0]
                auxbubget.valueRubrics = elemente[1]
                if (elemente[0].toUpperCase() === "RH DIRETO") {
                    auxbubget.totRhcost = _arrayCost.totRhDirect
                }
                if (elemente[0] === "RH INDIRETO") {
                    auxbubget.totRhcost = _arrayCost.totRhIndirect
                }
                if (SubsidyCopy) {
                    for (var z = 0; z < SubsidyCopy.length; z++) {
                        var elemente2 = SubsidyCopy[z]
                        if (elemente[0].toUpperCase() === elemente2[0].toUpperCase()) {
                            auxbubget.headingValue = elemente2[1]
                            break
                        }
                    }
                }
                if (ExpenseCopy) {
                    for (var r = 0; r < ExpenseCopy.length; r++) {
                        var elemente2 = ExpenseCopy[r]
                        if (elemente[0].toUpperCase() === elemente2[0].toUpperCase()) {
                            auxbubget.ExpenseValue = elemente2[1]
                            break
                        }
                    }
                }
                generalReportCosts.push(auxbubget)
                auxbubget = {}
            }
        }

        var arrayCosts = []
        _arrayCostGraph.forEach(g => {
            if (g === 2) {
                arrayCosts.push(null)
            } else {
                arrayCosts.push(g)
            }

        })

        const callbackLinkGraph = (value) => {
            linkGraph = value; //atualizo o meu state
            setUrl(value);
        };


        return (
            <Charts
                testeallaports={aportGraphFormated}
                testeallcost={costGraphFormated}
                categories={categories}
                seasonStartFinancial={seasonStartFinancial === null ? new Date(dataReports[0].information.startdate) : seasonStartFinancial}
                callbackLinkGraph={callbackLinkGraph}
            />
        )
    }

    useEffect(() => {
        setOpen(false);
        if (linkGraph) {
            setOpen(true);
        }
        return () => setOpen(false);
    });
    // useEffect(() => {
    //     setOpen2(false);
    //     setOpen2(true);
    //     return () => setOpen2(false);
    // });

    return (
        <div>
            {getDataRepotsSeason()}
            <div >
                {/* {url && open &&
            <PDFViewer style={{ width: "800px", height: "800px" }}>
                <Document>
                     <FinancialReport
                                        projectInfor={subTitle}
                                        startDate={seasonStartFinancial && seasonFinancial}
                                        endDate={Financialdates.endDate}
                                        terms={termT.toString()}
                                        url={linkGraph}
                                        generalViewFiancial={generalViewFiancial}
                                        costsAnalytical={costsAnalyticalReportFiancialFormated}
                                        costsChronological={costsChronologicalReportFiancial}
                                        type={ReportType}
                                        costsByColabAnalytFian={costsByColabAnalyticalReportFiancial}
                                        totRhTable={totRhTable}
                                    />
                </Document>
             </PDFViewer>} */}
            </div>
            {season ?
                <Paper className={classes.paper2}>
                    {allAllowances.length > 0 && getPermission("GENERALFIANREPORT") &&
                        <Paper className={classes.paper}>
                            <div className={classes.divAddParticipant}>
                                <div className={classes.divAddParticipantLeft}>
                                    Relatório
                        </div>
                                <HtmlTooltip
                                    title={<Typography>
                                        <Box fontWeight="fontWeightBold">{t("REPORTS.generalTitle")}</Box>
                                        <Box>{t("REPORTS.general")}</Box>
                                    </Typography>}
                                    placement={"top"}
                                    style={{ color: "#698bae" }}
                                >
                                    <ContactSupportIcon />
                                </HtmlTooltip>
                        Ajuda
                    </div>
                            <Grid container item spacing={2} md={12} lg={12}>
                                <Grid item xs={12}>
                                    <h3 style={{
                                        margin: "0px",
                                        color: "#333333",
                                        fontSize: "24px",
                                        fontFamily: "Roboto",
                                        fontStyle: "normal",
                                        fontWeight: "normal"
                                    }}>Financeiro Geral</h3>
                                </Grid>
                            </Grid>
                            <div className={classes.divAddParticipant}>
                                <div className={classes.divAddParticipantLeft}>
                                    <Grid container item spacing={2} md={12} lg={12}>
                                        <Grid item xs={3}>
                                            <TextField
                                                select
                                                id="periodFilter"
                                                label={t("GENERAL.startDate")}
                                                className={classes.textField}
                                                name="periodFilter"
                                                margin="normal"
                                                value={season}
                                                onChange={handleChangeSeason}
                                            >
                                                {seasonsArrayReports.map(option => (
                                                    <MenuItem key={option.value} value={option.label}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    className={classes.textField}
                                                    disableToolbar
                                                    minDate={
                                                        new Date(season.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"))
                                                        //new Date(season)
                                                    }
                                                    maxDate={new Date(limitDate)}
                                                    minDateMessage={"A data não deve ser anterior à data mínima"}
                                                    autoOk={true}
                                                    variant="inline"
                                                    format="dd/MM/yyyy"
                                                    margin="normal"
                                                    id="endDate"
                                                    label={t("GENERAL.endDate")}
                                                    value={dates.endDate}
                                                    onChange={handleDateChange("endDate")}
                                                    KeyboardButtonProps={{
                                                        "aria-label": "change date"
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                    </Grid>
                                </div>
                                {open &&
                                    <PDFDownloadLink document={
                                        <Document >
                                            <GeneralReport
                                                projectInfor={subTitleGeneral}
                                                budget={generalReportCosts}
                                                startDate={season}
                                                endDate={dates.endDate}
                                                terms={termG.toString()} />
                                        </Document>
                                    } fileName={dataReports[0] && ("relatorio-financeiro-geral-" + dataReports[0].information["name"] + ".pdf")}>
                                        {({ blob, url, loading, error }) => (
                                            loading ?
                                                <Button
                                                    style={{ width: "160px", height: "50px", padding: "5px", marginRight: "0px", alignItems: "center" }}
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    <CircularProgress size={40} color="secondary" />
                                                </Button>
                                                :
                                                <Button
                                                    style={{ width: "160px", height: "50px", padding: "15px", marginRight: "0px" }}
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    {t("Gerar relatório")}
                                                </Button>
                                        )}
                                    </PDFDownloadLink>
                                }
                            </div>
                        </Paper>
                    }
                    <br></br>
                    {/* ------ Financial Report ------ */}
                    {allAllowances.length > 0 && getPermission("TEMPFIANCIALSREPORT") &&
                        <Paper className={classes.paper}>
                            <div className={classes.divAddParticipant}>
                                <div className={classes.divAddParticipantLeft}>
                                    Relatório
                        </div>
                                <HtmlTooltip
                                    title={<React.Fragment>
                                        <Typography style={{ marginBottom: "10px" }}>
                                            <Box fontWeight="fontWeightBold">{t("REPORTS.temporalTitle")}</Box>
                                            <Box>{t("REPORTS.temporal")}</Box>
                                        </Typography>
                                        <Typography style={{ marginBottom: "10px" }}>
                                            <Box fontWeight="fontWeightBold">{t("REPORTS.chronologicTitle")}</Box>
                                            <Box>{t("REPORTS.chronologic")}</Box>
                                        </Typography>
                                        <Typography>
                                            <Box fontWeight="fontWeightBold">{t("REPORTS.analyticTitle")}</Box>
                                            <Box>{t("REPORTS.analytic")}</Box>
                                        </Typography>
                                    </React.Fragment>}
                                    placement={"top"}
                                    style={{ color: "#698bae" }}
                                >
                                    <ContactSupportIcon />
                                </HtmlTooltip>
                        Ajuda
                    </div>
                            <Grid container item spacing={2} md={12} lg={12}>
                                <Grid item xs={12}>
                                    <h3 style={{
                                        margin: "0px",
                                        color: "#333333",
                                        fontSize: "24px",
                                        fontFamily: "Roboto",
                                        fontStyle: "normal",
                                        fontWeight: "normal"
                                    }}>Financeiro Temporal</h3>
                                </Grid>
                            </Grid>
                            <div className={classes.divAddParticipant}>
                                <div className={classes.divAddParticipantLeft}>
                                    <Grid container item spacing={2} md={12} lg={12}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <Grid item xs={3}>
                                                <TextField
                                                    select
                                                    id="seasonFinancial"
                                                    label={t("GENERAL.startDate")}
                                                    className={classes.textField}
                                                    name="seasonFinancial"
                                                    margin="normal"
                                                    value={seasonFinancial}
                                                    onChange={handleChangeSeasonFinancial}
                                                >
                                                    {seasonsArrayReports.map(option => (
                                                        <MenuItem key={option.value} value={option.label}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <KeyboardDatePicker
                                                    className={classes.textField}
                                                    disableToolbar
                                                    minDate={
                                                        new Date(seasonFinancial.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"))
                                                    }
                                                    maxDate={new Date(limitDate)}
                                                    minDateMessage={"A data não deve ser anterior à data mínima"}
                                                    autoOk={true}
                                                    variant="inline"
                                                    format="dd/MM/yyyy"
                                                    margin="normal"
                                                    id="endDate"
                                                    label={t("GENERAL.endDate")}
                                                    value={Financialdates.endDate}
                                                    onChange={handleDateFinancialChange("endDate")}
                                                    KeyboardButtonProps={{
                                                        "aria-label": "change date"
                                                    }}
                                                />
                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                        <Grid item xs={2}>
                                            <TextField
                                                select
                                                id="ReportType"
                                                label={t("REPORTS.ReportType")}
                                                name="ReportType"
                                                value={ReportType}
                                                className={classes.textField}
                                                onChange={handleChange("ReportType")}
                                                margin="normal"
                                            >
                                                {optionsModels.map(option => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </div>
                                {url && open &&
                                    <PDFDownloadLink document={
                                        <Document >
                                            <FinancialReport
                                                projectInfor={subTitle}
                                                startDate={seasonStartFinancial && seasonFinancial}
                                                endDate={Financialdates.endDate}
                                                terms={termT.toString()}
                                                url={linkGraph}
                                                generalViewFiancial={generalViewFiancial}
                                                costsAnalytical={costsAnalyticalReportFiancialFormated}
                                                costsChronological={costsChronologicalReportFiancial}
                                                type={ReportType}
                                                costsByColabAnalytFian={costsByColabAnalyticalReportFiancial}
                                                totRhTable={totRhTable}
                                            />
                                        </Document>
                                    } fileName={dataReports[0] && ReportType === 0 ? "relatorio-financeiro-temporal(analitico)-" + dataReports[0].information["name"].replace(/[\s-]/g, '') + ".pdf" : "relatorio-financeiro-temporal(cronologico)-" + dataReports[0].information["name"].replace(/[\s-]/g, '') + ".pdf"}>
                                        {({ blob, url, loading, error }) => (
                                            loading ?
                                                <Button
                                                    style={{ width: "160px", height: "50px", padding: "5px", marginRight: "0px", alignItems: "center" }}
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    <CircularProgress size={40} color="secondary" />
                                                </Button>
                                                :
                                                <Button
                                                    style={{ width: "160px", height: "50px", padding: "15px", marginRight: "0px" }}
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    {t("Gerar relatório")}
                                                </Button>
                                        )}
                                    </PDFDownloadLink>
                                }
                            </div>
                        </Paper>
                    }
                    <div className="lineBreak"></div>
                </Paper> :
                <Paper className={classes.paper2}>
                    <p className={classes.Hint}>
                        {t("PHASES.hintNoAddendum")}
                    </p>
                </Paper>
            }
        </div>
    );
};

export default ReportsIndex;