import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import Buttons from 'components/buttons/Pressed';
import Typography from 'components/typography/Typography';
import { screenStyles } from '../Styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Input from 'components/inputs/Input';
import Grid from '@material-ui/core/Grid';
import {
    LibraryExemplarStatusValues,
    LibraryReservationStatusValues
} from 'global/constants';
import { AllAuthorsName } from 'utils/library/AllAuthorsName';
import { dateToBR } from 'utils/dates/DateToBRr';
import { formatExemplarCode } from 'utils/library/formatExemplarCode';
import Skeleton from '@material-ui/lab/Skeleton';
import { formatImagePath } from 'utils/library/formatImagePath';

const DialogLoanRequisition = props => {
    const styles = screenStyles();
    const [t] = useTranslation();
    const { open, setOpen, viewValues, context, submit } = props;
    const [exemplarsArray, setExemplarsArray] = useState([]);
    const [requiredExemplar, setRequiredExemplar] = useState(false);
    const [disponibleAmount, setDisponibleAmount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [requiredJustification, setRequiredJustification] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const handleChange = (name, value) => {
        let val = value;
        setValues({ ...values, [name]: val });
    };

    const [values, setValues] = useState({
        exemplarId: '',
        justification: ''
    });

    const handleSubmit = () => {
        var canSubmit = true;
        var emailData = {};

        if (context === 'approve') {
            emailData = {
                colaboratorName: viewValues.user.shortname,
                colaboratorEmail: viewValues.user.username,
                bookTitle: viewValues.book.title,
                bookAuthor: AllAuthorsName(viewValues.book.authors),
                bookCode: exemplarsArray.find(
                    a => a.value === values.exemplarId
                ).label,
                bookLibrary: viewValues.library,
                context: 'APPROVED',
                endpoint: 'approvedRequisition'
            };
        }
        if (context === 'cancel') {
            emailData = {
                colaboratorName: viewValues.user.shortname,
                colaboratorEmail: viewValues.user.username,
                justification: values.justification,
                bookLibrary: viewValues.library,
                screenContext: 'approved',
                context: 'CANCEL',
                endpoint: 'cancelRequisition',
                bookTitle: viewValues.book.title,
                bookAuthors: AllAuthorsName(viewValues.book.authors),
                exemplarCode: exemplarsArray.find(
                    a => a.value === values.exemplarId
                ).label,
                bookCategory: viewValues.book.category.name,
                reservationId: viewValues.id
            };
            if (!values.justification) {
                canSubmit = false;
                setRequiredJustification(true);
            }
        }

        if (canSubmit) {
            submit(
                viewValues.id,
                context,
                context === 'approve'
                    ? LibraryReservationStatusValues.AWAITING_WITHDRAWAL
                    : LibraryReservationStatusValues.CANCELED_MANAGEMENT,
                values.exemplarId,
                values.justification,
                emailData,
                'LOAN_REQUISITIONS'
            );
            setOpen(false);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleExemplars = exemplars => {
        var aux = [];
        exemplars.forEach(item => {
            if (
                (item.status === LibraryExemplarStatusValues.DISPONIBLE &&
                    item.library === viewValues.library) ||
                item.id === viewValues.exemplarId
            ) {
                aux.push({
                    label: formatExemplarCode('' + item.code),
                    value: '' + item.id
                });
            }
        });
        setExemplarsArray(aux);
    };

    useEffect(() => {
        setDisponibleAmount(
            viewValues.book.exemplars.filter(
                a =>
                    a.status === LibraryExemplarStatusValues.DISPONIBLE &&
                    a.library === viewValues.library
            ).length + 1
        );
        setTotalAmount(
            viewValues.book.exemplars.filter(
                a => a.library === viewValues.library
            ).length
        );
        handleExemplars(viewValues.book.exemplars);
        setValues({ exemplarId: '' + viewValues.exemplarId });
    }, []);

    useEffect(() => {
        setLoaded(false);
    }, [viewValues.book.imagePath]);

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth={'sm'}
                fullWidth={true}
            >
                <DialogTitle>
                    <div className={styles.dialogTitle}>
                        <Typography
                            variant={'RobotoBold'}
                            fontSize={24}
                            textColor={'secondary'}
                        >
                            {context === 'approve'
                                ? t('LIBRARY.dialogApproveRequisition')
                                : t('LIBRARY.dialogCancelRequisition')}
                        </Typography>
                        <IconButton
                            color="#6D6F71"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    {context === 'approve' && (
                        <div className={styles.dialogExemplarSelectContainer}>
                            <div className={styles.dialogLoanDisponible}>
                                <CheckCircleIcon
                                    style={{
                                        marginRight: '0.2rem',
                                        color: '#7FE46B'
                                    }}
                                    fontSize="small"
                                />
                                <Typography
                                    variant={'RobotoBold'}
                                    fontSize={14}
                                    textColor={'primary'}
                                >
                                    {disponibleAmount} de {totalAmount}{' '}
                                    exemplares disponíveis na{' '}
                                    {t(
                                        `LIBRARY.${viewValues.library.toLowerCase()}Place`
                                    )}
                                    {'.'}
                                </Typography>
                            </div>

                            <Grid container item spacing={2} md={12} lg={12}>
                                <Grid item xs={12} sm={12}>
                                    <Input
                                        required
                                        label={'LIBRARY.exemplar'}
                                        value={values.exemplarId}
                                        valueName={'exemplarId'}
                                        handleChange={handleChange}
                                        error={requiredExemplar}
                                        helperText={
                                            requiredExemplar &&
                                            t('GENERAL.requiredField')
                                        }
                                        setRequired={setRequiredExemplar}
                                        isSelect
                                        arraySelected={exemplarsArray}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    )}

                    <div className={styles.dialogBookRequisitionContainer}>
                        <div className={styles.dialogContent}>
                            <Typography
                                variant={'RobotoBold'}
                                fontSize={14}
                                textColor={'secondary'}
                            >
                                {t('INVENTORY.title').toLocaleUpperCase()}
                            </Typography>
                            <Typography
                                className={styles.dialogTopic}
                                variant={'RobotoRegular'}
                                fontSize={14}
                                textColor={'secondary'}
                            >
                                {viewValues.book.title}
                            </Typography>
                            <Typography
                                variant={'RobotoBold'}
                                fontSize={14}
                                textColor={'secondary'}
                            >
                                {t('INVENTORY.BookAuthor').toLocaleUpperCase()}
                            </Typography>
                            <Typography
                                className={styles.dialogTopic}
                                variant={'RobotoRegular'}
                                fontSize={14}
                                textColor={'secondary'}
                            >
                                {AllAuthorsName(viewValues.book.authors)}
                            </Typography>
                            <Typography
                                variant={'RobotoBold'}
                                fontSize={14}
                                textColor={'secondary'}
                            >
                                {t(
                                    'LIBRARY.RequisitionApplicant'
                                ).toLocaleUpperCase()}
                            </Typography>
                            <Typography
                                className={styles.dialogTopic}
                                variant={'RobotoRegular'}
                                fontSize={14}
                                textColor={'secondary'}
                            >
                                {viewValues.user.shortname}
                            </Typography>

                            {context === 'cancel' && (
                                <>
                                    <Typography
                                        variant={'RobotoBold'}
                                        fontSize={14}
                                        textColor={'secondary'}
                                    >
                                        {t(
                                            'LIBRARY.dateRequisition'
                                        ).toLocaleUpperCase()}
                                    </Typography>
                                    <Typography
                                        className={styles.dialogTopic}
                                        variant={'RobotoRegular'}
                                        fontSize={14}
                                        textColor={'secondary'}
                                    >
                                        {dateToBR(viewValues.requestDate)}
                                    </Typography>
                                </>
                            )}
                        </div>
                        <img
                            className={styles.dialogImage}
                            src={formatImagePath(
                                viewValues.book.imagePath,
                                viewValues.book.isImageUrl
                            )}
                            alt={viewValues.book.title}
                            style={!loaded ? { display: 'none' } : {}}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src =
                                    window.location.origin +
                                    '/img/no-book-cover.png';
                                setLoaded(true);
                            }}
                            onLoad={() => {
                                setLoaded(true);
                            }}
                        />
                        {!loaded && (
                            <Skeleton
                                className={styles.dialogImage}
                                variant="rect"
                                width={110}
                                height={150}
                            />
                        )}
                    </div>

                    {context === 'cancel' && (
                        <>
                            <Grid container item spacing={2} md={12} lg={12}>
                                <Grid item xs={12} sm={12}>
                                    <Input
                                        required
                                        placeholder={t('LIBRARY.justification')}
                                        label={'GENERAL.reason'}
                                        value={values.justification}
                                        valueName={'justification'}
                                        rows={4}
                                        maxLength={200}
                                        handleChange={handleChange}
                                        error={requiredJustification}
                                        helperText={
                                            requiredJustification &&
                                            t('GENERAL.requiredField')
                                        }
                                        setRequired={setRequiredJustification}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    )}
                </DialogContent>

                <DialogActions className={styles.dialogButtonsWrapper}>
                    <Buttons
                        onClick={handleClose}
                        tipo="BN-noback"
                        conteudo={
                            context === 'approve'
                                ? t('GENERAL.cancel').toUpperCase()
                                : t('GENERAL.close').toUpperCase()
                        }
                    />
                    {context === 'approve' ? (
                        <Buttons
                            className={styles.dialogButton}
                            tipo="BN-blue"
                            conteudo={t(
                                'LIBRARY.dialogApproveRequisition'
                            ).toUpperCase()}
                            onClick={handleSubmit}
                        />
                    ) : (
                        <Buttons
                            disabled={!values.justification}
                            className={styles.dialogButton}
                            tipo="BN-blue"
                            conteudo={t(
                                'LIBRARY.dialogCancelRequisition'
                            ).toUpperCase()}
                            onClick={handleSubmit}
                        />
                    )}
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DialogLoanRequisition;
