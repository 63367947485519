import axios from 'axios';

export async function GetGoogleBookApi(isbn) {
    const res = await axios
        .get(`https://www.googleapis.com/books/v1/volumes?q=isbn:${isbn}`, {
            headers: {
                'Content-type': 'application/json'
            }
        })
        .then(res => {
            var response = res.data.items ? res.data.items[0] : '';
            if (res.data.totalItems !== 0) {
                return response;
            } else {
                return {};
            }
        });

    return res;
}
