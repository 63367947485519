import React from "react";
import "../../../../App.css";
import BirthdaysPrint from "./BirthdaysPrint";

const CallBirthdays = ({ match }) => {

    return (
        <div>
            <BirthdaysPrint month={match.params.month} />
        </div>
    );
}

export default CallBirthdays;