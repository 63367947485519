import { makeStyles } from '@material-ui/core/styles';

export const screenStyles = makeStyles(theme => ({
    periodParagraph: {
        display: 'flex',
        margin: '16px 0 0'
    },
    cashFlowContainer: {
        padding: '24px 48px',
        boxShadow: '0px 4px 10px 2px rgba(0, 0, 0, 0.1)',
        borderRadius: '20px',
        margin: '0 8px'
    },
    graphContainer: {
        height: '350px'
    }
}));
