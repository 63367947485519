import DateFnsUtils from "@date-io/date-fns";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import Check from "@material-ui/icons/Check";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useStyles } from "components/layout/CommonStyles";
import Footer from "components/layout/Footer";
import Spinner from "components/layout/spinners/Index";
import ProjectsContext from "context/projects/projectsContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import DashboardNavBar from "../../layout/DashboardNavBar";
import SideBar from "../../layout/sidebar/Sidebar";
import ProjectInformation from "../ProjectInformationComponent";

const tooltipTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#2c3e51"
    }
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: "white",
        fontSize: "1em",
        backgroundColor: "red",
        opacity: "0.7"
      }
    }
  }
});

const NewPhase = ({ match }) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [mustRedirect, setMustRedirect] = useState(false);
  const [openName, setOpenName] = useState(false);
  const projectsContext = useContext(ProjectsContext);
  const { loading, project, getProject, addPhase } = projectsContext;

  const [state, setState] = useState({
    projectName: "",
    gpf: "",
    projectStartDate: "",
    projectEndDate: "",
    seasonId: 0,
    phaseName: "",
    phaseDescription: ""
  });

  const [dates, setDates] = useState({
    phaseStartDate: new Date(),
    phaseEndDate: new Date()
  });

  const transitions = useTransition(loading, null, {
    from: { opacity: 0 },
    enter: {
      opacity: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "calc(100vh - 80px)"
    },
    leave: { opacity: 0 }
  });

  useEffect(() => {
    getProject(match.params.id);

    if (project.information) {
      setState({
        projectName: project.information.name,
        gpf: project.information.gpf,
        projectStartDate: project.information.startdate,
        projectEndDate: project.information.enddate
      });
    }
  }, [JSON.stringify(project)]);

  const handleFieldChange = name => event => {
    setState({ ...state, [name]: event.target.value });
  };

  const handleDates = name => e => {
    setDates({ ...dates, [name]: e });
  };

  const clearRequiredMessage = () => {
    setOpenName(false);
  };

  const showRequiredFieldsMessage = () => {
    setOpenName(true);
  };

  const handleSubmit = () => {
    if (state.phaseName) {
      if(state.phaseName.split("").filter(el => el !== " ").join("") !== ""){
        clearRequiredMessage();

        var phaseStatus = 0;
        if (dates.phaseStartDate > new Date()) phaseStatus = 0;
        else phaseStatus = 1;

        var obj = {
          projectId: match.params.id,
          name: state.phaseName,
          description: state.phaseDescription,
          startDate: dates.phaseStartDate,
          endDate: dates.phaseEndDate,
          phaseStatus: phaseStatus,
          isfinished: false
        };

        addPhase(obj);
        setMustRedirect(true);
      }else{
        showRequiredFieldsMessage();
      }
    } else showRequiredFieldsMessage();
  };

  const redirect = go => {
    if (go) return <Redirect to={`/execution/projects/${match.params.id}/edit`} />;
  };

  return transitions.map(({ item, key, props }) =>
    item ? (
      <animated.div key={key} style={props}>
        <Spinner />
      </animated.div>
    ) : (
        <animated.div key={key} style={props}>
          {redirect(mustRedirect)}
          <div>
            <DashboardNavBar />
            <SideBar />
            <div className="container">
              <Breadcrumbs
                className={classes.breadCrumbs}
                aria-label="breadcrumb"
                separator={<NavigateNextIcon fontSize="small" />}
              >
                <Typography>{t("PARTICIPANTS.labor")}</Typography>
                <Link className={classes.link} to="/execution/projects/">
                  {t("PROJECTS.projects")}
                </Link>
                <Link
                  className={classes.link}
                  to={`/execution/projects/${match.params.id}/edit`}
                >
                  {t("PROJECTS.project")}
                </Link>
                <Typography>{t("PHASES.addPhase")}</Typography>
              </Breadcrumbs>
              <div className="container-header">
                <div className="title">
                  <h1 className={classes.title}>{t("PHASES.addPhase")}</h1>
                </div>
                <Button
                  className={classes.blueButton}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  <div className={classes.buttonIcon}>
                    <Check />
                  </div>
                  {t("GENERAL.add").toUpperCase()}
                </Button>
              </div>
              <ProjectInformation
                projectName={state.projectName}
                gpf={state.gpf}
                projectStartDate={state.projectStartDate}
                projectEndDate={state.projectEndDate}
              />
              <Paper className={classes.root}>
                <div className={classes.header}>
                  {t("SCOPE.stage").toUpperCase()}
                </div>
                <div className={classes.bodyForm}>
                  <Grid container>
                    <Grid container item spacing={2} md={12} lg={12}>
                      <Grid item xs={6}>
                        <MuiThemeProvider theme={tooltipTheme}>
                          <Tooltip
                            TransitionComponent={Zoom}
                            open={openName}
                            title={t("GENERAL.requiredField")}
                          >
                            <TextField
                              required
                              className={classes.textField}
                              margin="normal"
                              id="phaseName"
                              label={t("PHASES.phaseName")}
                              name="phaseName"
                              value={state.phaseName}
                              onChange={handleFieldChange("phaseName")}
                              onFocus={clearRequiredMessage}
                            />
                          </Tooltip>
                        </MuiThemeProvider>
                      </Grid>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid item xs={3}>
                          <KeyboardDatePicker
                            className={classes.textField}
                            disableToolbar
                            maxDate={dates.EndDate}
                            autoOk={true}
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="startDate"
                            label={t("GENERAL.startDate")}
                            value={dates.phaseStartDate}
                            onFocus={clearRequiredMessage}
                            minDateMessage={
                              "A data não deve ser anterior à data mínima"
                            }
                            onChange={handleDates("phaseStartDate")}
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <KeyboardDatePicker
                            className={classes.textField}
                            disableToolbar
                            autoOk={true}
                            minDate={dates.phaseStartDate}
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="endDate"
                            label={t("GENERAL.endDate")}
                            value={dates.phaseEndDate}
                            onFocus={clearRequiredMessage}
                            onChange={handleDates("phaseEndDate")}
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                            minDateMessage={
                              "A data não deve ser anterior à data mínima"
                            }
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid container item spacing={2} md={12} lg={12}>
                      <Grid item xs={12}>
                        <TextField
                          multiline
                          fullWidth
                          variant="outlined"
                          rows="6"
                          id="phaseDescription"
                          value={state.phaseDescription}
                          label={t("PROFILES.descriptionLabel")}
                          margin="normal"
                          onChange={handleFieldChange("phaseDescription")}
                          onFocus={clearRequiredMessage}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </div>
          </div>
          <Footer />
        </animated.div>
      )
  );
};

export default NewPhase;