/* eslint-disable */
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Dissatisfied from "@material-ui/icons/SentimentVeryDissatisfied";
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {animated, useTransition} from "react-spring";
import {useStyles} from "components/layout/CommonStyles";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Typography from "@material-ui/core/Typography";
import ViewHistoryTable from "./ViewHistoryTable";
import InputFilter from "../../../../components/inputs/InputFilter";
import Button from "@material-ui/core/Button";
import FilterContainer from "../../../../components/containers/FilterContainer";
import AdvancedSearchDialog from "../AdvancedSearchDialog";
import {inputStyles} from "../../../../components/inputs/Styles";
import InputFilterDate from "../../../../components/inputs/InputFilterDate";
import {AllAuthorsName} from "../../../../utils/library/AllAuthorsName";
import {dateToBR} from "../../../../utils/dates/DateToBRr";
import {FormatDateToFront} from "../../../../utils/dates/FormatDateToFront";
import {translateHistoryExemplarOperations, translateStatus} from "../../../../utils/library/translateStatus";
import {LibraryHistoryExemplarsOperations} from "../../../../global/constants";

const ViewHistoryExemplar = (props) => {
    const {historyExemplar} = props;
    const classes = useStyles();
    const styles = inputStyles();
    const [openAdvancedSearch, setOpenAdvancedSearch] = useState(false);
    const [t] = useTranslation();
    const [loading, setLoading] = useState(true);
    const [findText, setFindText] = useState('');

    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }
    const [dataFilter, setDataFilter] = useState([]);
    const hasItens = historyExemplar.length > 0;
    const [hasNoResult, setHasNoResult] = useState(hasItens && dataFilter.length === 0);

    const operations = [
        {label: "Todos", value: "Todos"},
        {
            label: translateHistoryExemplarOperations(
                LibraryHistoryExemplarsOperations.ADMINISTRATIVE_RESPONSIBLE
            ),
            value: LibraryHistoryExemplarsOperations.ADMINISTRATIVE_RESPONSIBLE
        },
        {
            label: translateHistoryExemplarOperations(
                LibraryHistoryExemplarsOperations.DIRECT_RESPONSIBLE
            ),
            value: LibraryHistoryExemplarsOperations.DIRECT_RESPONSIBLE
        }
    ]


    const [filterDate, setFilterDate] = useState(null);
    const [filterOperation, setFilterOperation] = useState("");
    const [filterExecutor, setFilterExecutor] = useState("");
    const [filterResponsible, setFilterResponsible] = useState("");

    const applyFilter = filterArray => {
        let newDataFilter = [];
        let textNoFound = '';

        newDataFilter = filterArray;

        if (filterDate && !isNaN(filterDate)) {
            let filterDateBR = dateToBR(filterDate);
            newDataFilter = newDataFilter.filter(
                item =>
                    item.createdAt.slice(0, 10) && dateToBR(FormatDateToFront(item.createdAt)) ===
                    filterDateBR
            )
            if (!textNoFound && newDataFilter.length === 0) {
                textNoFound = filterDate;
            }

        }

        if (filterOperation) {
            if (filterOperation !== "Todos") {
                newDataFilter = newDataFilter.filter(
                    item =>
                        item.operation && item.operation.toLowerCase() === filterOperation.toLowerCase()
                );
            }

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterOperation;
        }

        if (filterExecutor) {
            newDataFilter = newDataFilter.filter(
                item =>
                    item.executor && item.executor.name.toLowerCase().includes(filterExecutor.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterExecutor;
        }

        if (filterResponsible) {
            newDataFilter = newDataFilter.filter(
                item => {
                    if (item.actualResponsible && item.actualResponsible.name.toLowerCase().includes(filterResponsible.toLowerCase())) {
                        return item;
                    } else if (item.actualResponsibleExternal && item.actualResponsibleExternal.toLowerCase().includes(filterResponsible.toLowerCase())) {
                        return item;
                    } else {
                        return null;
                    }
                }
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterResponsible;
        }

        return {newDataFilter, textNoFound};
    };

    useEffect(() => {
        if (historyExemplar && historyExemplar.length) {
            const {newDataFilter, textNoFound} = applyFilter(
                historyExemplar
            );

            if (newDataFilter.length === 0) {
                setDataFilter([]);
                setHasNoResult(true);
                setFindText(textNoFound);
            } else {
                setDataFilter(newDataFilter);
                setHasNoResult(false);
                setFindText('');
            }
        }
    }, [
        historyExemplar,
        filterExecutor,
        filterDate,
        filterResponsible,
        filterOperation]);

    return (
        <React.Fragment>
            <Paper style={{marginTop: 10}}>
                <div className="cabecalho">
                    {t("INVENTORY.historyExemplar").toUpperCase()}
                </div>
                <FilterContainer>
                    <InputFilterDate
                        filterValue={filterDate}
                        setFilter={setFilterDate}
                        label={t("INVENTORY.historyDate")}
                    />
                    <InputFilter
                        filterValue={filterOperation}
                        setFilter={setFilterOperation}
                        label={'INVENTORY.HistoryAlteration'}
                        isSelect
                        arraySelected={operations}
                    />
                    <InputFilter
                        filterValue={filterExecutor}
                        setFilter={setFilterExecutor}
                        label={'INVENTORY.operator'}
                        maxLength={70}
                    />
                    <InputFilter
                        filterValue={filterResponsible}
                        setFilter={setFilterResponsible}
                        label={'INVENTORY.responsible'}
                        maxLength={70}
                    />
                </FilterContainer>
                <React.Fragment>
                    <ViewHistoryTable
                        rows={dataFilter}
                        mode={"view"}
                        headers={["id", "createdAt", "executor", "operation", "lastResponsible", "actualResponsible", "lastResponsibleExternal", "actualResponsibleExternal"]}
                        visibleHeaders={["Data", "Operador", "Alteração", "Responsável Anterior", "Novo Responsável"]}
                        sortedFields={["createdAt", "executor", "operation", "lastResponsible", "actualResponsible"]}
                        mainField="createdAt"
                    />

                    {!hasItens ?
                        <div className={classes.noParticipantsMessage}>
                            <div>
                                <Dissatisfied className={classes.notFoundIconStyle}/>
                            </div>
                            <h2>{t("GENERAL.noResultsFound")}</h2>
                        </div>
                        : dataFilter.length === 0 && (hasNoResult && filterDate ?
                        <div className={classes.noParticipantsMessage}>
                            <div>
                                <Dissatisfied className={classes.notFoundIconStyle}/>
                            </div>
                            <h2>{t("GENERAL.noResultsFound")}</h2>
                            <h5>{`Sua busca por "${dateToBR(filterDate)}" não retornou resultados.`}</h5>
                        </div>
                        : hasNoResult && filterExecutor ?
                            <div className={classes.noParticipantsMessage}>
                                <div>
                                    <Dissatisfied className={classes.notFoundIconStyle}/>
                                </div>
                                <h2>{t("GENERAL.noResultsFound")}</h2>
                                <h5>{`Sua busca por "${filterExecutor}" não retornou resultados.`}</h5>
                            </div>
                            : hasNoResult && filterOperation ?
                                <div className={classes.noParticipantsMessage}>
                                    <div>
                                        <Dissatisfied className={classes.notFoundIconStyle}/>
                                    </div>
                                    <h2>{t("GENERAL.noResultsFound")}</h2>
                                    <h5>{`Sua busca por "${t("LIBRARY." + filterOperation)}" não retornou resultados.`}</h5>
                                </div>
                                : hasNoResult && filterResponsible &&
                                <div className={classes.noParticipantsMessage}>
                                    <div>
                                        <Dissatisfied className={classes.notFoundIconStyle}/>
                                    </div>
                                    <h2>{t("GENERAL.noResultsFound")}</h2>
                                    <h5>{`Sua busca por "${filterResponsible}" não retornou resultados.`}</h5>
                                </div>)}
                </React.Fragment>
            </Paper>
        </React.Fragment>
    );
};

export default ViewHistoryExemplar;