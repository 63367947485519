/* eslint-disable react-hooks/exhaustive-deps */
import Paper from '@material-ui/core/Paper';
import Dissatisfied from '@material-ui/icons/SentimentVeryDissatisfied';
import FilterContainer from 'components/containers/FilterContainer';
import InputFilter from 'components/inputs/InputFilter';
import { useStyles } from 'components/layout/CommonStyles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DialogAwaitingList from 'pages/libraryModule/dialogs/Requisitions/AwaitingList/DialogAwaitingList';
import { screenStyles } from 'pages/libraryModule/Requisitions/Styles';
import BookCardAwaitingList from 'pages/libraryModule/cards/BookCardAwaitingList';
import { AllAuthorsName } from 'utils/library/AllAuthorsName';
import { QueryGetValue } from 'utils/general/QueryString';
import { formatImagePath } from 'utils/library/formatImagePath';
import { LibraryQueueStatusValues } from 'global/constants';

const AwaitingListForm = props => {
    const { AwaitingListData } = props;
    const [t] = useTranslation();
    const styles = screenStyles();
    const classes = useStyles();

    const [filterTitle, setFilterTitle] = useState('');
    const [filterAuthor, setFilterAuthor] = useState('');

    const [findText, setFindText] = useState('');
    const [dataFilter, setDataFilter] = useState([]);

    const hasItens = AwaitingListData.length > 0;
    const [hasNoResult, setHasNoResult] = useState(false);

    const [dialogData, setDialogData] = useState({});
    const [openListDialog, setOpenListDialog] = useState(false);

    const handleOpenListDialog = index => {
        const req = dataFilter[index];
        setDialogData(req);
        setOpenListDialog(true);
    };

    const applyFilter = filterArray => {
        let newDataFilter = [];
        let textNoFound = '';

        newDataFilter = filterArray;

        if (filterTitle) {
            newDataFilter = newDataFilter.filter(item =>
                item.title.toLowerCase().includes(filterTitle.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterTitle;
        }

        if (filterAuthor) {
            newDataFilter = newDataFilter.filter(item =>
                AllAuthorsName(item.authors)
                    .toLowerCase()
                    .includes(filterAuthor.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterAuthor;
        }

        return { newDataFilter, textNoFound };
    };

    useEffect(() => {
        if (AwaitingListData && AwaitingListData.length) {
            const { newDataFilter, textNoFound } =
                applyFilter(AwaitingListData);

            if (newDataFilter.length === 0) {
                setDataFilter([]);
                setHasNoResult(true);
                setFindText(textNoFound);
            } else {
                setDataFilter(newDataFilter);
                setHasNoResult(false);
                setFindText('');
            }
        }
    }, [AwaitingListData, filterTitle, filterAuthor]);

    useEffect(() => {
        if (QueryGetValue('panel') === 'AWAITING_LIST') {
            setFilterTitle(QueryGetValue('title') || '');
            setFilterAuthor(QueryGetValue('author') || '');
        }
    }, []);

    return (
        <React.Fragment>
            <FilterContainer>
                <InputFilter
                    filterValue={filterTitle}
                    setFilter={setFilterTitle}
                    label={'INVENTORY.title'}
                    maxLength={70}
                />
                <InputFilter
                    filterValue={filterAuthor}
                    setFilter={setFilterAuthor}
                    label={'INVENTORY.BookAuthor'}
                    maxLength={70}
                />
            </FilterContainer>

            <Paper style={{ maxHeight: 700, overflow: 'auto' }}>
                <div className={styles.bookList}>
                    {dataFilter.map((data, index) => {
                        return (
                            <BookCardAwaitingList
                                id={data.id}
                                image={formatImagePath(
                                    data.imagePath,
                                    data.isImageUrl
                                )}
                                title={data.title}
                                author={AllAuthorsName(data.authors)}
                                category={data.category.name}
                                queueLength={
                                    data.queue.filter(
                                        item =>
                                            item.status ===
                                            LibraryQueueStatusValues.IN_QUEUE
                                    ).length
                                }
                                onClickViewList={() => {
                                    handleOpenListDialog(index);
                                }}
                            />
                        );
                    })}
                </div>
                {!hasItens && (
                    <div className={classes.noParticipantsMessage}>
                        <h2>{t('LIBRARY.noAwaitingListMessage')}</h2>
                        <h4>{t('LIBRARY.noRequisitionsSubMessage')}</h4>
                    </div>
                )}
                {hasNoResult && (
                    <div className={classes.noParticipantsMessage}>
                        <div>
                            <Dissatisfied
                                className={classes.notFoundIconStyle}
                            />
                        </div>
                        <h2>{t('GENERAL.noResultsFound')}</h2>
                        <h5>{`Sua busca por "${findText}" não retornou resultados.`}</h5>
                    </div>
                )}
            </Paper>

            {openListDialog && (
                <DialogAwaitingList
                    open={openListDialog}
                    setOpen={setOpenListDialog}
                    viewValues={dialogData}
                />
            )}
        </React.Fragment>
    );
};

export default AwaitingListForm;
