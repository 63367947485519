/* eslint-disable react-hooks/exhaustive-deps */
import Paper from '@material-ui/core/Paper';
import Dissatisfied from '@material-ui/icons/SentimentVeryDissatisfied';
import FilterContainer from 'components/containers/FilterContainer';
import InputFilter from 'components/inputs/InputFilter';
import { useStyles } from 'components/layout/CommonStyles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { screenStyles } from 'pages/libraryModule/MyLoans/Styles';
import BookCardMyLoans from 'pages/libraryModule/cards/BookCardMyLoans';
import { AllAuthorsName } from 'utils/library/AllAuthorsName';
import { dateToBR } from 'utils/dates/DateToBRr';
import { FormatDateToFront } from 'utils/dates/FormatDateToFront';
import DialogCancelRequisition from 'pages/libraryModule/dialogs/MyLoans/DialogCancelRequisition';
import { Link as LinkRouter } from 'react-router-dom';
import { formatImagePath } from 'utils/library/formatImagePath';
import DialogQueuedBooksMoreDetails from 'pages/libraryModule/dialogs/MyLoans/DialogQueuedBooksMoreDetails';

const QueuedBooksForm = props => {
    const { QueuedBooksData, submitQueued } = props;
    const [t] = useTranslation();
    const styles = screenStyles();
    const classes = useStyles();

    const [dialogData, setDialogData] = useState({});
    const [openMoreDetailsDialog, setOpenMoreDetailsDialog] = useState(false);
    const [openCancelRequisitionDialog, setOpenCancelRequisitionDialog] =
        useState(false);

    const [filterTitle, setFilterTitle] = useState('');
    const [filterAuthor, setFilterAuthor] = useState('');
    const [filterCategory, setFilterCategory] = useState('');

    const [findText, setFindText] = useState('');
    const [dataFilter, setDataFilter] = useState([]);

    const hasItens = QueuedBooksData.length > 0;
    const [hasNoResult, setHasNoResult] = useState(false);

    const handleOpenMoreDetailsDialog = index => {
        setDialogData(dataFilter[index]);
        setOpenMoreDetailsDialog(true);
    };

    const handleOpenCancelRequisitionDialog = index => {
        setDialogData(dataFilter[index]);
        setOpenCancelRequisitionDialog(true);
    };

    const applyFilter = filterArray => {
        let newDataFilter = [];
        let textNoFound = '';

        newDataFilter = filterArray;

        if (filterTitle) {
            newDataFilter = newDataFilter.filter(item =>
                item.book.title
                    .toLowerCase()
                    .includes(filterTitle.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterTitle;
        }

        if (filterAuthor) {
            newDataFilter = newDataFilter.filter(item =>
                AllAuthorsName(item.book.authors)
                    .toLowerCase()
                    .includes(filterAuthor.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterAuthor;
        }

        if (filterCategory) {
            newDataFilter = newDataFilter.filter(item =>
                item.book.category.name
                    .toLowerCase()
                    .includes(filterCategory.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterCategory;
        }

        return { newDataFilter, textNoFound };
    };

    useEffect(() => {
        if (QueuedBooksData && QueuedBooksData.length) {
            const { newDataFilter, textNoFound } = applyFilter(QueuedBooksData);

            if (newDataFilter.length === 0) {
                setDataFilter([]);
                setHasNoResult(true);
                setFindText(textNoFound);
            } else {
                setDataFilter(newDataFilter);
                setHasNoResult(false);
                setFindText('');
            }
        }
    }, [QueuedBooksData, filterTitle, filterAuthor, filterCategory]);

    return (
        <React.Fragment>
            <FilterContainer>
                <InputFilter
                    filterValue={filterTitle}
                    setFilter={setFilterTitle}
                    label={'INVENTORY.title'}
                    maxLength={70}
                />
                <InputFilter
                    filterValue={filterAuthor}
                    setFilter={setFilterAuthor}
                    label={'INVENTORY.BookAuthor'}
                    maxLength={70}
                />
                <InputFilter
                    filterValue={filterCategory}
                    setFilter={setFilterCategory}
                    label={'INVENTORY.category'}
                    maxLength={70}
                />
            </FilterContainer>

            <Paper style={{ maxHeight: 700, overflow: 'auto' }}>
                <div className={styles.bookList}>
                    {dataFilter.map((data, index) => {
                        return (
                            <BookCardMyLoans
                                context={'queuedBooks'}
                                image={formatImagePath(
                                    data.book.imagePath,
                                    data.book.isImageUrl
                                )}
                                title={data.book.title}
                                author={AllAuthorsName(data.book.authors)}
                                category={data.book.category.name}
                                status={data.status}
                                requestDate={dateToBR(
                                    FormatDateToFront(data.requestDate)
                                )}
                                onClickMoreDetails={() => {
                                    handleOpenMoreDetailsDialog(index);
                                }}
                                onClick={() => {
                                    handleOpenCancelRequisitionDialog(index);
                                }}
                            />
                        );
                    })}
                </div>
                {!hasItens && (
                    <div className={classes.noParticipantsMessage}>
                        <h2>{t('LIBRARY.myLoansQueuedBooksNoItens')}</h2>
                        <p style={{ fontWeight: '400' }}>
                            Você não possui reservas de livro. Para solicitar um
                            empréstimo visite a página de{' '}
                            <LinkRouter
                                className={styles.Link}
                                to="/BooksCollection"
                            >
                                Acervo
                            </LinkRouter>{' '}
                            e escolha um livro.
                        </p>
                    </div>
                )}
                {hasNoResult && (
                    <div className={classes.noParticipantsMessage}>
                        <div>
                            <Dissatisfied
                                className={classes.notFoundIconStyle}
                            />
                        </div>
                        <h2>{t('GENERAL.noResultsFound')}</h2>
                        <h5>{`Sua busca por "${findText}" não retornou resultados.`}</h5>
                    </div>
                )}
            </Paper>
            {openCancelRequisitionDialog && (
                <DialogCancelRequisition
                    open={openCancelRequisitionDialog}
                    setOpen={setOpenCancelRequisitionDialog}
                    viewValues={dialogData}
                    submit={submitQueued}
                    context={'queuedBooks'}
                />
            )}
            {openMoreDetailsDialog && (
                <DialogQueuedBooksMoreDetails
                    open={openMoreDetailsDialog}
                    setOpen={setOpenMoreDetailsDialog}
                    viewValues={dialogData}
                />
            )}
        </React.Fragment>
    );
};

export default QueuedBooksForm;
