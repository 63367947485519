import { makeStyles } from '@material-ui/core/styles';

export const inputStyles = makeStyles(theme => ({
    filterSearchInput: {
        height: 48,
        width: '100%',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#F5F6FA',
        '&:hover': {
            backgroundColor: 'rgb(224, 224, 224)'
        },
        marginRight: 16,
        marginLeft: 0
    },
    caractersHelperText: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: '0'
    },
    inputImageContainer: {
        cursor: 'pointer'
    },
    inputImageWrapper: {
        display: 'flex',
        flexDirection: 'column'
    },
    inputImageDivImage: {},
    inputImageImage: {
        objectFit: 'cover',
        width: '214px',
        height: '307px',
        margin: '0 auto'
    },
    inputImageImageUser: {
        border: '1.5px solid #2C3E51',
        borderRadius: '4px',
        objectFit: 'cover',
        width: '196px',
        height: '196px',
        margin: '0 auto'
    },
    inputImageText: {
        width: '100%',
        textAlign: 'center'
    },
    inputFile: {
        width: '85%',
        '& .MuiFilledInput-root': {
            border: '1px solid #e2e2e1',
            overflow: 'hidden',
            borderRadius: '4px 0px 0px 4px',
            backgroundColor: '#fff',
            '&.Mui-focused': {
                backgroundColor: 'transparent'
            }
        }
    },
    inputFileButton: {
        backgroundColor: '#2C3E51',
        color: 'white',
        borderRadius: '0px 4px 4px 0px',
        fontSize: '8px',
        height: '57px',
        width: '15%',
        '&:hover': {
            backgroundColor: '#2C3E51'
        }
    },
    inputFileButtonRed: {
        backgroundColor: '#FB3434',
        color: 'white',
        borderRadius: '0px 4px 4px 0px',
        fontSize: '8px',
        height: '57px',
        width: '15%',
        '&:hover': {
            backgroundColor: '#FB3434'
        }
    },
    inputFileButtonWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%'
    }
}));
