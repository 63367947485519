import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import Buttons from 'components/buttons/Pressed';
import Typography from 'components/typography/Typography';
import { screenStyles } from '../Styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { AllAuthorsName } from 'utils/library/AllAuthorsName';
import { DialogUnit } from 'pages/libraryModule/dialogs/DialogUtils';
import { formatExemplarCode } from 'utils/library/formatExemplarCode';
import { formatTitle } from 'utils/library/formatTitle';
import { dateToBR } from 'utils/dates/DateToBRr';
import { FormatDateToFront } from 'utils/dates/FormatDateToFront';
import { translateStatus } from 'utils/library/translateStatus';
import {
    LibraryPlaceValues,
    LibraryReservationStatusValues
} from 'global/constants';
import Input from 'components/inputs/Input';
import Grid from '@material-ui/core/Grid';
import { moneyMask } from 'utils/masks/moneyMask';
import { moneyMaskToFloat } from 'utils/general/moneyMaskToFloat';
import InputAdornment from '@material-ui/core/InputAdornment';
import { valueToMoneyFloat } from 'utils/general/valueToMoneyFloat';
import { Screen, Allow } from 'global/constants';
import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';
import Skeleton from '@material-ui/lab/Skeleton';
import { formatImagePath } from 'utils/library/formatImagePath';
import { translateLibrary } from 'utils/library/translateLibrary';
import { GetNumberOfDays } from 'utils/dates/GetNumberOfDays';
import { isDayOrDaysText } from 'utils/dates/isDayOrDaysText';

const DialogLibraryLoan = props => {
    const styles = screenStyles();
    const [t] = useTranslation();

    const { open, setOpen, viewValues, submit, originalFine } = props;

    const [observationRequired, setObservationRequired] = useState(false);
    const [isPayFine, setIsPayFine] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [values, setValues] = useState({
        observation: '',
        fine: moneyMask(valueToMoneyFloat(viewValues.fine)),
        fineChanged: ''
    });

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (name, value) => {
        let val = value;
        setValues({ ...values, [name]: val });
    };

    const handleFineRadio = value => {
        if (value === 'true') {
            setIsPayFine(true);
        }
        if (value === 'false') {
            setIsPayFine(false);
        }
    };

    const handleChangeMoney = (name, value) => {
        setValues({
            ...values,
            [name]: moneyMask(value)
        });
    };

    const handleSubmit = () => {
        if (
            values.fine !== moneyMask(valueToMoneyFloat(originalFine)) &&
            !values.observation.length
        ) {
            setObservationRequired(true);
        } else {
            const emailData = {
                context: 'DEVOLUTION',
                endpoint: 'devolutionRequisition',
                colaboratorName: viewValues.user.shortname,
                colaboratorEmail: viewValues.user.username,
                observation: values.observation,
                bookLibrary: viewValues.library,
                bookTitle: viewValues.book.title,
                bookCategory: viewValues.book.category.name,
                bookAuthors: AllAuthorsName(viewValues.book.authors),
                exemplarCode: formatExemplarCode('' + viewValues.exemplar.code),
                fine:
                    viewValues.status === LibraryReservationStatusValues.LATE
                        ? values.fine
                        : '',
                reservationId: viewValues.id
            };
            submit(
                viewValues.id,
                values.observation,
                moneyMaskToFloat(values.fine).toFixed(2),
                emailData,
                values.fineChanged
            );
            setOpen(false);
        }
    };

    const canEditFine = GetGeneralPermissionForAction(
        Screen.LIBRARY_LOANS,
        Allow.EDIT
    );

    useEffect(() => {
        setLoaded(false);
    }, [viewValues.book.imagePath]);

    useEffect(() => {
        if (values.fine !== moneyMask(valueToMoneyFloat(originalFine))) {
            values.fineChanged = moneyMaskToFloat(values.fine).toFixed(2);
        } else {
            values.fineChanged = '';
        }
    }, [values.fine]);

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth={'sm'}
                fullWidth={true}
            >
                <DialogTitle>
                    <div className={styles.dialogTitle}>
                        <Typography
                            variant={'RobotoBold'}
                            fontSize={24}
                            textColor={'secondary'}
                        >
                            {t('LIBRARY.dialogLibraryLoanTitle')}
                        </Typography>
                        <IconButton
                            color="#6D6F71"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    {viewValues.library === LibraryPlaceValues.COORDINATION ? (
                        <div className={styles.dialogMoreDetailsDisponible}>
                            <ErrorIcon
                                style={{
                                    marginRight: '0.6rem',
                                    color: '#2C3E51'
                                }}
                            />
                            <Typography
                                variant={'RobotoRegular'}
                                fontSize={14}
                                textColor={'primary'}
                            >
                                {t(
                                    `LIBRARY.CoordenationExemplarsDoNoTGenerateFines`
                                )}
                            </Typography>
                        </div>
                    ) : null}
                    <div className={styles.dialogBookRequisitionContainer}>
                        <div className={styles.dialogContent}>
                            <DialogUnit
                                head={t('INVENTORY.title').toLocaleUpperCase()}
                                content={formatTitle(
                                    viewValues.book.title,
                                    viewValues.book.subtitle
                                )}
                            />

                            <DialogUnit
                                head={t(
                                    'INVENTORY.BookAuthor'
                                ).toLocaleUpperCase()}
                                content={AllAuthorsName(
                                    viewValues.book.authors
                                )}
                            />

                            <DialogUnit
                                head={'ACERVO'}
                                content={translateLibrary(viewValues.library)}
                            />

                            <DialogUnit
                                head={'EXEMPLAR'}
                                content={formatExemplarCode(
                                    '' + viewValues.exemplar.code
                                )}
                            />
                        </div>
                        <img
                            className={styles.dialogImage}
                            src={formatImagePath(
                                viewValues.book.imagePath,
                                viewValues.book.isImageUrl
                            )}
                            alt={viewValues.book.title}
                            style={!loaded ? { display: 'none' } : {}}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src =
                                    window.location.origin +
                                    '/img/no-book-cover.png';
                                setLoaded(true);
                            }}
                            onLoad={() => {
                                setLoaded(true);
                            }}
                        />
                        {!loaded && (
                            <Skeleton
                                className={styles.dialogImage}
                                variant="rect"
                                width={110}
                                height={150}
                            />
                        )}
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            margin: '16px 50px 0 0'
                        }}
                    >
                        <div style={{ width: '200px' }}>
                            <Typography
                                variant={'RobotoBold'}
                                fontSize={14}
                                textColor={'secondary'}
                            >
                                {t('HR.collaborator')}
                            </Typography>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginTop: '6px'
                                }}
                            >
                                <img
                                    src={
                                        process.env.REACT_APP_IMGSERVER +
                                        viewValues.user.imageName
                                    }
                                    className={styles.collaboratorImage}
                                />
                                <Typography
                                    className={styles.dialogTopic}
                                    variant={'RobotoRegular'}
                                    fontSize={14}
                                    textColor={'secondary'}
                                >
                                    {viewValues.user.shortname}
                                </Typography>
                            </div>
                        </div>
                        <div style={{ width: '200px' }}>
                            <DialogUnit
                                head={t('LIBRARY.loanDate').toLocaleUpperCase()}
                                content={dateToBR(
                                    FormatDateToFront(viewValues.loanDate)
                                )}
                            />
                        </div>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            margin: '16px 50px 0 0'
                        }}
                    >
                        <div style={{ width: '200px' }}>
                            <Typography
                                variant={'RobotoBold'}
                                fontSize={14}
                                textColor={'secondary'}
                            >
                                {t('GENERAL.status')}
                            </Typography>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                {viewValues.status ===
                                    LibraryReservationStatusValues.LATE && (
                                    <ErrorIcon
                                        style={{
                                            marginRight: '0.5rem',
                                            color: '#F3222F'
                                        }}
                                    />
                                )}
                                {viewValues.status ===
                                    LibraryReservationStatusValues.LOAN && (
                                    <CheckCircleIcon
                                        style={{
                                            marginRight: '0.5rem',
                                            color: '#7FE46B'
                                        }}
                                    />
                                )}
                                <Typography
                                    className={styles.dialogTopic}
                                    variant={'RobotoRegular'}
                                    fontSize={14}
                                    textColor={'secondary'}
                                >
                                    {viewValues.status ===
                                    LibraryReservationStatusValues.LATE
                                        ? translateStatus(viewValues.status) +
                                          ` (${
                                              GetNumberOfDays(
                                                  FormatDateToFront(
                                                      viewValues.returnDate
                                                  ),
                                                  new Date()
                                              ) - 1
                                          } ${isDayOrDaysText(
                                              GetNumberOfDays(
                                                  FormatDateToFront(
                                                      viewValues.returnDate
                                                  ),
                                                  new Date()
                                              ) - 1
                                          )})`
                                        : translateStatus(viewValues.status)}
                                </Typography>
                            </div>
                        </div>
                        <div style={{ width: '200px' }}>
                            <DialogUnit
                                head={'DEVOLVER EM'}
                                content={dateToBR(
                                    FormatDateToFront(viewValues.returnDate)
                                )}
                            />
                        </div>
                    </div>

                    {viewValues.status ===
                        LibraryReservationStatusValues.LATE &&
                    viewValues.library !== LibraryPlaceValues.COORDINATION ? (
                        <>
                            <FormControl
                                style={{ marginTop: '16px' }}
                                component="fieldset"
                            >
                                <FormLabel component="legend">
                                    <Typography
                                        variant={'RobotoBold'}
                                        fontSize={12}
                                        textColor={'secondary'}
                                    >
                                        {t(
                                            'LIBRARY.payFine'
                                        ).toLocaleUpperCase()}
                                    </Typography>
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="position"
                                    name="position"
                                    defaultValue="top"
                                    value={isPayFine}
                                    onChange={ev =>
                                        handleFineRadio(ev.target.value)
                                    }
                                >
                                    <FormControlLabel
                                        value={false}
                                        control={<Radio color="primary" />}
                                        label={t('GENERAL.noCapital')}
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value={true}
                                        control={<Radio color="primary" />}
                                        label={t('GENERAL.yesCapital')}
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </FormControl>

                            <div style={{ marginTop: '12px' }}>
                                <Typography
                                    variant={'RobotoRegular'}
                                    fontSize={12}
                                    textColor={'secondary'}
                                >
                                    {t('LIBRARY.payFineConfirmation')}
                                </Typography>
                            </div>
                        </>
                    ) : null}

                    <Grid container item spacing={1} md={12} lg={12}>
                        {viewValues.status ===
                            LibraryReservationStatusValues.LATE &&
                        viewValues.library !==
                            LibraryPlaceValues.COORDINATION ? (
                            <Grid item xs={12} sm={12}>
                                <Input
                                    disabled={!canEditFine}
                                    context={'edit'}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                R$
                                            </InputAdornment>
                                        )
                                    }}
                                    maxLength={9}
                                    label={'LIBRARY.fineValue'}
                                    value={values.fine}
                                    valueName={'fine'}
                                    handleChange={handleChangeMoney}
                                />
                            </Grid>
                        ) : null}

                        <Grid item xs={12} sm={12}>
                            <Input
                                required={
                                    values.fine !==
                                    moneyMask(valueToMoneyFloat(originalFine))
                                }
                                placeholder={t(
                                    'LIBRARY.observationPlaceholder'
                                )}
                                label={'GENERAL.observation'}
                                value={values.observation}
                                valueName={'observation'}
                                rows={3}
                                maxLength={200}
                                handleChange={handleChange}
                                error={observationRequired}
                                helperText={
                                    observationRequired &&
                                    t('LIBRARY.observationRequired')
                                }
                                setRequired={setObservationRequired}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions className={styles.dialogButtonsWrapper}>
                    <Buttons
                        onClick={handleClose}
                        tipo="BN-noback"
                        conteudo={t('GENERAL.cancel').toUpperCase()}
                    />

                    <Buttons
                        disabled={
                            viewValues.status ===
                                LibraryReservationStatusValues.LATE &&
                            !isPayFine &&
                            viewValues.library !==
                                LibraryPlaceValues.COORDINATION
                        }
                        className={styles.dialogButton}
                        tipo="BN-blue"
                        conteudo={t('LIBRARY.confirmDevolution').toUpperCase()}
                        onClick={handleSubmit}
                    />
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DialogLibraryLoan;
