import React, { useState } from 'react';
import { useStyles } from 'components/layout/CommonStyles';
import { inputStyles } from './Styles';
import Dropzone from 'react-dropzone';
import Typography from '@material-ui/core/Typography';
import Typography1 from 'components/typography/Typography';
import Clear from '@material-ui/icons/Clear';
import DialogDeleteAttachment from 'components/dialogs/DialogDeleteAttachment';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import axios from 'axios';
import Cookies from 'js-cookie';

import AttachFileIcon from '@material-ui/icons/AttachFile';
import { useSSR, useTranslation } from 'react-i18next';

// TODO: Give a better name, this name is too generic to a input that works with files and not with generic multi data
const InputMultiDropzone = ({
    context,
    files,
    setFiles,
    label,
    required,
    helperText,
    error,
    setRequired,
    setHasChanged,
    ...props
}) => {
    const fileDownload = require('js-file-download');
    const classes = useStyles();
    const [t] = useTranslation();

    const [open, setOpen] = useState(false);
    const [toDeleteFile, setToDeleteFile] = useState(null);

    // Runs some validations after add new file to the input
    const onDropFiles = acceptedFiles => {
        if (setHasChanged) {
            setHasChanged(true);
        }
        let newFiles = [];
        let existentFiles = [];
        
        // Validates the file name length
        acceptedFiles.forEach(file => {
            if (files.files.filter(
                el =>
                    (el.fileName
                        ? el.fileName.toLowerCase()
                        : el.name.toLowerCase()) ===
                        file.name.toLowerCase()
            ).length > 0) {
                existentFiles.push(
                    `Arquivo já existente - ${file.name}`
                );
            } else if (file.name.length > 255) {
                existentFiles.push(`Arquivo com nome maior que 255 caracteres - ${file.name}`)
            } else {
                newFiles.push(file);
            }
        });

        setFiles({
            ...files,
            files: files.files.concat(newFiles),
            rejectedFiles: files.rejectedFiles.concat(existentFiles)
        });
    };

    // Transforms the file size to human readable format
    function humanFileSize(size) {
        if (size == 0) {
            return 0 + " B"
        }
        let i = Math.floor(Math.log(size) / Math.log(1024));
        return (
            (size / Math.pow(1024, i)).toFixed(2) * 1 +
            ' ' +
            ['B', 'kB', 'MB', 'GB', 'TB'][i]
        );
    }

    const onClickDownload = async file => {
        if (files && files.files.length > 0) {
            if (file.id) {
                let noSlashPath = file.path.replace(/\//g, ';');
                const url = process.env.REACT_APP_FILE + noSlashPath;
                axios
                    .get(url, {
                        headers: {
                            'Content-type': 'application/json',
                            Authorization:
                                'bearer ' +
                                (Cookies.get('auth-token')
                                    ? Cookies.get('auth-token')
                                    : '')
                        },
                        responseType: 'blob'
                    })
                    .then(function (res) {
                        const blob = new Blob([res.data], {});
                        const name = file.fileName ? file.fileName : file.name;
                        fileDownload(blob, name);
                    });
            } else {
                fileDownload(file, file.name);
            }
        }
    };

    const handleDelete = data => {
        setToDeleteFile(data);
        setOpen(true);
    };

    const onRemoveFile = data => {
        if (data) {
            let remainingFiles = [];
            let updatedList = [];
            let deletedId;
            if (data.id) {
                deletedId = data.id;
            }

            for (const file of files.files) {
                if (
                    file.path === data.path &&
                    file.lastModified === data.lastModified &&
                    file.size === data.size
                ) {
                    if (file.id) {
                        updatedList = files.rejectedFiles.filter(
                            el =>
                                el !== `Arquivo já existente - ${data.fileName}`
                        );
                    }
                    continue;
                }
                remainingFiles.push(file);
            }
            setFiles({
                ...files,
                files: remainingFiles,
                rejectedFiles: updatedList,
                deletedFiles: deletedId
                    ? [...files.deletedFiles, deletedId]
                    : [...files.deletedFiles]
            });
            if (setHasChanged) {
                setHasChanged(true);
            }
        }
    };

    // Updates error message array deleting the message by its index
    const deleteRejectedFileErrorMessage = rejectedFileErrorMessageIndex => {
        files.rejectedFiles.splice(rejectedFileErrorMessageIndex, 1);
        setFiles({ ...files, rejectedFiles: files.rejectedFiles });
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>
                <Typography1
                    variant={'RobotoBold'}
                    fontSize={15}
                    textColor={'secondary'}
                >
                    {context === 'view'
                        ? 'Documento Anexado'
                        : 'Anexe um documento'}
                </Typography1>
                {files.rejectedFiles.length > 0 && (
                    <div style={{ paddingBottom: 8 }}>
                        {files.rejectedFiles.map((message, index) => (
                            <div className={classes.dialogFile}>
                                <div className={classes.textFile}>
                                    <Typography1
                                        variant={'RobotoBold'}
                                        fontSize={14}
                                        textColor={'red'}
                                        className={classes.nameFileError}
                                    >
                                        {message}
                                    </Typography1>
                                </div>
                                <div className={classes.iconsFile}>
                                    <IconButton
                                        onClick={() =>
                                            deleteRejectedFileErrorMessage(index)
                                        }
                                    >
                                        <Clear />
                                    </IconButton>
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                {files.files.length > 0 ? (
                    <div className={classes.dropzoneDialogFiles}>
                        {files.files.map(file => (
                            <div className={classes.dialogFile}>
                                <div className={classes.textFile}>
                                    <Typography1
                                        variant={'RobotoBold'}
                                        fontSize={14}
                                        textColor={'black'}
                                        className={classes.nameFile}
                                    >
                                        {file.fileName
                                            ? file.fileName
                                            : file.name}
                                        &nbsp;
                                    </Typography1>
                                    <Typography1
                                        variant={'RobotoRegular'}
                                        fontSize={13}
                                        textColor={'black'}
                                    >{`(${humanFileSize(
                                        file.size
                                    )})`}</Typography1>
                                </div>
                                <div className={classes.iconsFile}>
                                    <IconButton
                                        onClick={() => onClickDownload(file)}
                                    >
                                        <GetAppIcon />
                                    </IconButton>
                                    {context !== 'view' && (
                                        <IconButton
                                            onClick={() => handleDelete(file)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className={classes.noFilesMessage}>
                        <Typography
                            variant={'h6'}
                            style={{ fontWeight: 'bold' }}
                        >
                            {t('EXPENSES.noFilesMessage')}
                        </Typography>
                        <Typography variant={'caption'}>
                            Os documentos adicionados serão listados aqui
                        </Typography>
                    </div>
                )}
            </div>
            {context !== 'view' && (
                <Dropzone
                    onDrop={onDropFiles}
                    accept="application/pdf,
                                                application/msword,
                                                application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                                image/jpeg,
                                                image/png"
                >
                    {({ getRootProps, getInputProps }) => (
                        <section>
                            <div
                                {...getRootProps({
                                    className: classes.dropzoneDialog
                                })}
                            >
                                <input {...getInputProps()} />
                                <AttachFileIcon color={'primary'} />
                                <Typography1
                                    variant={'RobotoBold'}
                                    fontSize={15}
                                    textColor={'secondary'}
                                >
                                    Arraste e solte os documentos para anexá-los
                                    ou <u>clique aqui</u>
                                </Typography1>
                            </div>
                        </section>
                    )}
                </Dropzone>
            )}
            <DialogDeleteAttachment
                open={open}
                setOpen={setOpen}
                file={toDeleteFile}
                onRemoveFile={onRemoveFile}
            />
        </div>
    );
};

export default InputMultiDropzone;
