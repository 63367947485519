import FilterContainer from 'components/containers/FilterContainer';
import InputFilter from 'components/inputs/InputFilter';
import Paper from '@material-ui/core/Paper';
import Dissatisfied from '@material-ui/icons/SentimentVeryDissatisfied';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import { TypesArray } from 'pages/libraryModule/Fines/ScreenUtils';
import CashTransactionsTable from './CashTransactionsTable';
import { dateToBR } from 'utils/dates/DateToBRr';
import { FormatDateToFront } from 'utils/dates/FormatDateToFront';
import { translateFinesTransactionsTypes } from 'utils/library/translateFinesStatus';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import EventIcon from '@material-ui/icons/Event';
import { screenStyles } from './Styles';
import DialogPeriod from './DialogPeriod';

const CashTransactionsForm = props => {
    const { transactions } = props;
    const styles = screenStyles();

    const classes = useStyles();
    const [t] = useTranslation();

    const [filterCollaborator, setFilterCollaborator] = useState('');
    const [filterStatus, setFilterStatus] = useState('ALL');
    const [periodText, setPeriodText] = useState('');
    const [openDialogPeriod, setOpenDialogPeriod] = useState(false);

    const [findText, setFindText] = useState('');
    const [filterName, setFilterName] = useState('');
    const [canClick, setCanClick] = useState(false);
    const [period, setPeriod] = useState({
        start: null,
        end: null
    });
    const [showDatePicker, setShowDatePicker] = useState(false);

    const [dataFilter, setDataFilter] = useState([]);
    const hasItens = transactions.length > 0;
    const [hasNoResult, setHasNoResult] = useState(
        hasItens && dataFilter.length === 0
    );

    const clearField = value => {
        if (value === 'name') {
            setFilterName('');
        } else {
            setPeriodText('');
            setPeriod({
                start: null,
                end: null
            });
        }
    };

    const handleHideDatePicker = () => {
        setShowDatePicker(false);
    };

    useEffect(() => {
        if (transactions) {
            setDataFilter(transactions);
            setHasNoResult(false);
        }
    }, [transactions]);

    useEffect(() => {
        let newDataFilter = transactions;
        let textNoFound = '';

        if (filterCollaborator) {
            newDataFilter = newDataFilter.filter(item =>
                item.user.shortname
                    .toLowerCase()
                    .includes(filterCollaborator.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = filterCollaborator;
        }

        if (period && period.start && period.end) {
            let fromDate = new Date(
                period.start.getFullYear() +
                    '/' +
                    (period.start.getMonth() + 1) +
                    '/' +
                    period.start.getDate()
            );
            let toDate = new Date(
                period.end.getFullYear() +
                    '/' +
                    (period.end.getMonth() + 1) +
                    '/' +
                    period.end.getDate()
            );

            newDataFilter = newDataFilter.filter(
                item =>
                    item.date &&
                    FormatDateToFront(item.date).getTime() >=
                        fromDate.getTime() &&
                    FormatDateToFront(item.date).getTime() <= toDate.getTime()
            );
            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = dateToBR(fromDate) + '-' + dateToBR(toDate);
            handleHideDatePicker();
        }

        if (filterStatus && filterStatus !== 'ALL') {
            newDataFilter = newDataFilter.filter(
                item => item.type === filterStatus
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = translateFinesTransactionsTypes(filterStatus);
        }

        if (newDataFilter.length === 0 && transactions.length > 0) {
            setHasNoResult(true);
            setFindText(textNoFound);
        } else {
            setHasNoResult(false);
            setFindText('');
        }

        setDataFilter(newDataFilter);
    }, [filterCollaborator, filterStatus, periodText]);

    useEffect(() => {
        if (filterCollaborator || filterStatus) {
            setCanClick(true);
        } else {
            setCanClick(false);
        }
    }, [filterCollaborator, filterStatus]);

    return (
        <React.Fragment>
            <Paper>
                <FilterContainer>
                    <div className={styles.filterSearchInput}>
                        <TextField
                            disabled
                            id="client2"
                            label={t('GENERAL.period')}
                            name="period"
                            value={periodText}
                            style={{
                                minWidth: '250px',
                                marginTop: 0,
                                marginBottom: 0,
                                width: '100%'
                            }}
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputSearch
                            }}
                            color={'black'}
                            inputProps={{
                                'aria-label': 'search',
                                autocomplete: 'off'
                            }}
                            variant="standard"
                            onClick={() =>
                                !periodText ? setOpenDialogPeriod(true) : null
                            }
                            InputProps={{
                                endAdornment: periodText ? (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => {
                                                clearField('period');
                                            }}
                                        >
                                            <Close />
                                        </IconButton>
                                    </InputAdornment>
                                ) : (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() =>
                                                setOpenDialogPeriod(true)
                                            }
                                        >
                                            <EventIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </div>

                    <InputFilter
                        filterValue={filterCollaborator}
                        setFilter={setFilterCollaborator}
                        label={'GENERAL.user'}
                        maxLength={70}
                    />
                    <InputFilter
                        filterValue={filterStatus}
                        setFilter={setFilterStatus}
                        label={'GENERAL.type'}
                        isSelect
                        arraySelected={TypesArray}
                    />
                </FilterContainer>
                <React.Fragment>
                    <CashTransactionsTable
                        rows={dataFilter}
                        toFirstPage={canClick}
                        headers={[
                            'id',
                            'user',
                            'date',
                            'price',
                            'type',
                            'observation',
                            'file'
                        ]}
                        visibleHeaders={[
                            'Data',
                            'Usuário',
                            'Tipo',
                            'Valor',
                            ' '
                        ]}
                        sortedFields={['date', 'user', 'type', 'value']}
                    />

                    {!hasItens && (
                        <div className={classes.noParticipantsMessage}>
                            <h2>{t('LIBRARY.noFinesMessage')}</h2>
                            <h4>{t('LIBRARY.noFinesSubMessage')}</h4>
                        </div>
                    )}
                    {hasNoResult && (
                        <div className={classes.noParticipantsMessage}>
                            <div>
                                <Dissatisfied
                                    className={classes.notFoundIconStyle}
                                />
                            </div>
                            <h2>{t('GENERAL.noResultsFound')}</h2>
                            <h5>{`Sua busca por "${findText}" não retornou resultados.`}</h5>
                        </div>
                    )}
                </React.Fragment>
            </Paper>

            <DialogPeriod
                open={openDialogPeriod}
                setOpen={setOpenDialogPeriod}
                breakPeriod={period}
                setParentValues={setPeriod}
                setBreakPeriodText={setPeriodText}
            />
        </React.Fragment>
    );
};

export default CashTransactionsForm;
