import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';

import SettingsContext from 'context/hrModule/settings/settingsContext';
import AllowancesContext from 'context/allowance/allowanceContext';

import Paper from '@material-ui/core/Paper';

import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import { useStyles as useAllowanceReasonStyles } from './styles.js';

import Breadcrumb from 'components/layout/breadcrumb/Breadcrumb';

import Input from 'components/inputs/Input';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Footer from 'components/layout/Footer';
import Buttons from 'components/buttons/Pressed';
import DialogCancelOperation from 'components/dialogs/DialogCancelOperation';

const AllowanceReasonForm = props => {
    const classes = useStyles();
    const styles = useAllowanceReasonStyles();
    const [t] = useTranslation();
    const allowanceContext = useContext(AllowancesContext);
    const [mustRedirect, setMustRedirect] = useState(false);

    const { context, reasonId } = props;

    const settingsContext = useContext(SettingsContext);

    const {
        loading,
        setLoading,
        setLoadingFalse,
        addAllowanceReason,
        getAllowanceReason,
        updateAllowanceReason,
        allowanceReason,
        successiveActions,
        hasChanged,
        setHasChanged,
        responseType,
        response
    } = settingsContext;

    const {
        allowanceResponse,
        allowanceResponseType,
        messageHasChanged,
        resetAllowanceMessage,
        permission
    } = allowanceContext;

    const [requiredName, setRequiredName] = useState(false);
    const [alreadyExists, setAlreadyExists] = useState(false);

    const [values, setValues] = useState({
        type: '',
        name: '',
        daysAmount: '',
        description: ''
    });
    const [hasChange, setHasChange] = useState(false);

    const [openCancelDialog, setOpenCancelDialog] = useState(false);

    const handleChange = (name, value) => {
        setHasChange(true);
        setValues({ ...values, [name]: value });
    };

    const handleCancel = () => {
        if (hasChange) {
            setOpenCancelDialog(true);
        } else {
            setMustRedirect(true);
        }
    };

    const redirect = go => {
        if (go) return <Redirect to={`/AllowanceReason`} />;
    };

    const handleApply = () => {
        const canApply = verifyApply();
        if (canApply) {
            let formData = new FormData();

            formData.append(`allowanceReason.version`, 0);
            formData.append(`allowanceReason.changeable`, true);

            formData.append(
                `allowanceReason.name`,
                values.name ? values.name : ''
            );
            formData.append(
                `allowanceReason.description`,
                values.description ? values.description : ''
            );

            formData.append(
                `allowanceReason.daysAmount`,
                values.type === 'Total' && values.daysAmount
                    ? values.daysAmount
                    : ''
            );

            formData.append(
                `allowanceReason.type`,
                values.type ? values.type : ''
            );

            if (context === 'edit')
                formData.append(`allowanceReason.id`, values.id);

            if (values.name && context === 'new') {
                addAllowanceReason(formData, setMustRedirect);
            }
            if (values.name && context === 'edit') {
                updateAllowanceReason(formData, setMustRedirect);
            }
        }
    };

    const verifyApply = () => {
        let res = true;
        if (!values.name) {
            res = false;
            setRequiredName(true);
        }
        return res;
    };

    const clearFields = () => {
        setValues({
            type: 'Total',
            name: '',
            daysAmount: '',
            description: ''
        });
    };

    async function fetchData(id) {
        clearFields();
        await setLoading();
        if (context !== 'new') {
            await getAllowanceReason(id, setMustRedirect);
        }
        await setLoadingFalse();
    }

    useEffect(() => {
        setAlreadyExists(false);
        setRequiredName(false);
    }, [values.name]);

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData(reasonId);
    }, []);

    useEffect(() => {
        if (context !== 'new' && allowanceReason) {
            setValues({
                id: allowanceReason.id ? allowanceReason.id : null,
                name: allowanceReason.name ? allowanceReason.name : '',
                description: allowanceReason.description
                    ? allowanceReason.description
                    : '',
                type: allowanceReason.type ? allowanceReason.type : 'Total',
                daysAmount: allowanceReason.daysAmount
                    ? allowanceReason.daysAmount
                    : []
            });
        }
    }, [allowanceReason]);

    return (
        <React.Fragment>
            {redirect(mustRedirect)}

            <div
                style={{
                    opacity: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'spaceBetween',
                    minHeight: 'calc(100vh - 70px)'
                }}
            >
                <div className="headerNewDesign">
                    <Breadcrumb
                        newDesign={true}
                        routes={[
                            {
                                label: t('HR.humanResource'),
                                to: '/HumanResources'
                            },
                            { label: t('ROUTES.config') },
                            {
                                label: t('HR.allowanceReasons'),
                                to: '/AllowanceReason'
                            },
                            {
                                label:
                                    context === 'view'
                                        ? t('HR.viewAllowanceReason')
                                        : context === 'new'
                                        ? t('HR.newAllowanceReason')
                                        : t('HR.editAllowanceReason')
                            }
                        ]}
                    />
                    <div className="container-headerNewDesign">
                        <h1 className={classes.titleNewDesign}>
                            {context === 'view'
                                ? t('HR.viewAllowanceReason')
                                : context === 'new'
                                ? t('HR.newAllowanceReason')
                                : t('HR.editAllowanceReason')}
                        </h1>
                    </div>
                </div>
                <div
                    className="containerNewDesign"
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Paper style={{ width: '75rem' }}>
                        <div className="cabecalho">
                            {(context === 'view'
                                ? t('HR.viewAllowanceReason')
                                : context === 'new'
                                ? t('HR.newAllowanceReason')
                                : t('HR.editAllowanceReason')
                            ).toUpperCase()}
                        </div>
                        <div style={{ padding: '2rem' }}>
                            <Grid
                                container
                                item
                                spacing={2}
                                md={12}
                                lg={12}
                                style={{ maxWidth: '600px' }}
                            >
                                <Grid item xs={12} sm={12}>
                                    <FormControl
                                        component="fieldset"
                                        style={{ marginTop: '18px' }}
                                    >
                                        <FormLabel component="legend">
                                            {t('HR.allowanceReasonType')}
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            aria-label="position"
                                            disabled={context === 'view'}
                                            name="position"
                                            defaultValue="top"
                                            value={values.type}
                                            onChange={ev =>
                                                handleChange(
                                                    'type',
                                                    ev.target.value
                                                )
                                            }
                                        >
                                            <FormControlLabel
                                                disabled={context === 'view'}
                                                value="Total"
                                                control={
                                                    <Radio color="primary" />
                                                }
                                                label={t('GENERAL.total')}
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                disabled={context === 'view'}
                                                value="Parcial"
                                                control={
                                                    <Radio color="primary" />
                                                }
                                                label={t('GENERAL.parcial')}
                                                labelPlacement="end"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Input
                                        context={context}
                                        required
                                        label={t('PERSONAL.name') + ' '}
                                        value={values.name}
                                        error={requiredName}
                                        helperText={
                                            requiredName && alreadyExists
                                                ? 'HR.allowanceReasonAlreadyExists'
                                                : 'GENERAL.requiredField'
                                        }
                                        setRequired={setRequiredName}
                                        valueName={'name'}
                                        maxLength={50}
                                        handleChange={handleChange}
                                    />
                                </Grid>
                                {values && values.type === 'Total' && (
                                    <Grid item xs={12} sm={4}>
                                        <Input
                                            context={context}
                                            type={'number'}
                                            label={'HR.daysAmount'}
                                            value={values.daysAmount}
                                            valueName={'daysAmount'}
                                            maxLength={4}
                                            handleChange={handleChange}
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={12} sm={12}>
                                    <Input
                                        disabled={context === 'view'}
                                        label={'GENERAL.description'}
                                        value={values.description}
                                        valueName={'description'}
                                        rows={4}
                                        maxLength={500}
                                        handleChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                            {context !== 'view' && (
                                <div className={styles.buttonContainer}>
                                    <div className={styles.leftButtonContainer}>
                                        <Buttons
                                            onClick={handleCancel}
                                            conteudo={t('GENERAL.cancel')}
                                            tipo="BN-gray"
                                        />
                                    </div>
                                    <div
                                        className={styles.rightButtonContainer}
                                    >
                                        <Buttons
                                            onClick={handleApply}
                                            tipo="BN-blue"
                                            conteudo={t('GENERAL.apply')}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </Paper>
                </div>
            </div>

            <DialogCancelOperation
                open={openCancelDialog}
                setOpen={setOpenCancelDialog}
                path={'/AllowanceReason'}
                dialogTitle={t('GENERAL.cancelEdit')}
                dialogText={t('GENERAL.cancelEditText')}
            />

            <Footer />
        </React.Fragment>
    );
};

export default AllowanceReasonForm;
