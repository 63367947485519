import React, { useContext, useEffect, useState } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link, Redirect } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Spinner from "components/layout/spinners/Index";
import Hidden from "@material-ui/core/Hidden";
import InsertInvitationIcon from "@material-ui/icons/InsertInvitation";
import InputAdornment from "@material-ui/core/InputAdornment";
import MomentUtils from "@date-io/moment";
import { useTranslation } from "react-i18next";
import { animated, useTransition } from "react-spring";
import "components/profiles/css/FormStyle.css";
import { useStyles } from "components/layout/CommonStyles";
import "moment/locale/pt-br";
import SideBar from "../../layout/sidebar/Sidebar";
import DashboardNavBar from "../../layout/DashboardNavBar";
import Footer from "components/layout/Footer";
import HRCostsContext from "context/hrcosts/hrCostsContext";
import ProjectsContext from "context/projects/projectsContext";
import {
    DatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import DonutChart from "components/execution/Participants/DonutChart";

const tooltipTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#2c3e51"
        }
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                color: "white",
                fontSize: "1em",
                backgroundColor: "red",
                opacity: "0.7"
            }
        }
    }
});

function EditFeedback({ match }) {
    const { id, projectId, mode, participationId, feedbackId, feedbackMode, participantMode } = match.params;
    const classes = useStyles();
    const [t] = useTranslation();
    const hrCostsContext = useContext(HRCostsContext);
    const projectsContext = useContext(ProjectsContext);
    const {
        user,
        userCosts,
        getUser,
        getUserCosts,
    } = hrCostsContext;
    const {
        loading,
        updateFeedback,
        project,
        getProject,
        participantSolicitation,
        getSolicitation,
        participantPontos,
        getPontos,
        participantFeedback,
        getParticipantFeedback,
        sendEmail
    } = projectsContext;

    const [mustRedirect, setMustRedirect] = useState(false);
    const [selectedDate, handleDateChange] = useState(new Date());
    const [state, setState] = useState({
        name: "",
        cpf: "",
        annualCost: "",
        imagePath: "",
        //  contractType: "",
    });
    const [showRequiredField, setShowRequiredField] = useState(false);
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );
    const [feedbackText, setFeedbackText] = useState('');
    const [improvementPoints, setImprovementPoints] = useState('');
    const [period, setPeriod] = useState([]);
    const [periodLabel, setPeriodLabel] = useState('');
    const [solicitation, setSolicitation] = useState({
        pendingRequests: 0,
        approvedRequests: 0,
        withoutInconsistency: 0,
        deniedRequests: 0,
        waitingApproval: 0,
        waitingOvertime: 0,
        approvedOvertime: 0,
    });

    const [linkGraphUrl, setLinkGraphUrl] = useState("");


    const monthDict = {
        1: "Jan",
        2: "Fev",
        3: "Mar",
        4: "Abr",
        5: "Mai",
        6: "Jun",
        7: "Jul",
        8: "Ago",
        9: "Set",
        10: "Out",
        11: "Nov",
        12: "Dez"
    };

    const contractDict = {
        "Intern": "Estagiário",
        "CLT": "CLT",
        "RPA": "RPA",
        "ScholarshipHolder": "Bolsista"
    };

    const [formQuestions, setFormQuestions] = useState([
        {
            section: t("GENERAL.behavior").toUpperCase(),
            subSection: [
                {
                    name: "Ambiente satisfatório",
                    questions: [
                        { value: 3, text: "Comportamento frente aos problemas e desafios", minText: "Apático", maxText: "Desafiado", id: "behaviorProblemsAndChallenges" },
                        { value: 3, text: "Clareza e sinceridade nas relações", minText: "Pouco satisfatório", maxText: "Muito satisfatório", id: "clarityAndSincerityInRelationships" },
                        { value: 3, text: "Relevância no Projeto", minText: "Pouco Relevante", maxText: "Muito Relevante", id: "valuation" },
                    ]
                },
                {
                    name: "Relação de time",
                    questions: [
                        { value: 3, text: "Relacionamento com os colaboradores no contexto de trabalho", minText: "Frágil", maxText: "Consolidada", id: "relationshipEmployeesWorkcontext" },
                    ]
                }
            ]
        },
        {
            section: t("GENERAL.technical").toUpperCase(),
            subSection: [
                {
                    name: "Construção e compartilhamento de conhecimento",
                    questions: [
                        { value: 3, text: "Capacidade de solucionar problemas", minText: "Pouco conhecimento técnico", maxText: "Muito conhecimento técnico", id: "abilitySolveProblems" },
                        { value: 3, text: "Compartilhamento de conhecimento", minText: "Comunicação técnica confusa", maxText: "Comunicação técnica clara", id: "knowledgeSharing" },
                    ]
                },
                {
                    name: "Fomento e compartilhamento de ideias",
                    questions: [
                        { value: 3, text: "Proatividade", minText: "Pouco proativo", maxText: "Muito proativo", id: "productivity" },
                    ]
                },
                {
                    name: "Qualidade e inovação nas soluções",
                    questions: [
                        { value: 3, text: "Habilidade Técnica e dominío em ferramentas", minText: "Pouco satisfatório", maxText: "Muito satisfatório", id: "masterySkillsAndTools" },
                    ]
                }
            ]
        }
    ]);

    useEffect(() => {
        if (participantFeedback.length > 0 && feedbackId) {
            const periodSelected = participantFeedback.find(x => x.id === parseInt(feedbackId));
            if (periodSelected) {
                const newObj = formQuestions.map(element => {
                    const sub = element.subSection.map(subElement => {
                        const res = subElement.questions.map(question => {
                            return { ...question, value: periodSelected[question.id] };
                        });
                        return { ...subElement, questions: res };
                    });
                    return { ...element, subSection: sub };
                });
                setFormQuestions(newObj);
                setFeedbackText(periodSelected.feedback);
                setImprovementPoints(periodSelected.improvementPoints);
            }

        }
    }, [participantFeedback]);

    useEffect(() => {
        if (projectId && id && participationId) {
            getProject(projectId);
            getSolicitation(id);
            getPontos(id);
            getParticipantFeedback(participationId);
        }
    }, []);

    useEffect(() => {
        if (periodLabel) {
            const label = period.find(x => x.value === periodLabel);
            if (label && label.init && label.end) {
                const startDate = label.init;
                const endDate = label.end;

                let pendingRequests = 0;
                let approvedRequests = 0;
                let deniedRequests = 0;
                let waitingApproval = 0;
                let waitingOvertime = 0;
                let approvedOvertime = 0;

                if (participantSolicitation.length > 0) {
                    const filterSolicitation = participantSolicitation.filter(item => new Date(item.requestedDate) >= startDate && new Date(item.requestedDate) <= endDate);

                    approvedRequests = filterSolicitation.filter(x => x.status === 'ALLOWED' && x.class !== 'overtime').length;
                    deniedRequests = filterSolicitation.filter(x => x.status === 'DENIED').length;
                    waitingApproval = filterSolicitation.filter(x => x.status === 'PENDING' && x.class !== 'overtime').length;
                    waitingOvertime = filterSolicitation.filter(x => x.status === 'PENDING' && x.class === 'overtime').length;
                    approvedOvertime = filterSolicitation.filter(x => x.status === 'ALLOWED' && x.class === 'overtime').length;
                }

                if (participantSolicitation.length > 0) {
                    pendingRequests = participantPontos.filter(item => new Date(item.date) >= startDate && new Date(item.date) <= endDate).length;
                }

                const withoutInconsistency = dateDifference(startDate, endDate) - approvedRequests - deniedRequests - waitingApproval - waitingOvertime - approvedOvertime - pendingRequests;

                setSolicitation({
                    pendingRequests: pendingRequests,
                    approvedRequests: approvedRequests,
                    deniedRequests: deniedRequests,
                    waitingApproval: waitingApproval,
                    waitingOvertime: waitingOvertime,
                    approvedOvertime: approvedOvertime,
                    withoutInconsistency: withoutInconsistency,
                });
            }
        }
    }, [participantPontos, participantSolicitation, period, periodLabel]);

    useEffect(() => {
        if (project && project.information && project.information.startdate && project.information.enddate) {

            let startDateGeneral = new Date(project.information.startdate);
            startDateGeneral.setMinutes(startDateGeneral.getMinutes() + startDateGeneral.getTimezoneOffset());
            let endDateGeneral = new Date(project.information.enddate);
            endDateGeneral.setMinutes(endDateGeneral.getMinutes() + endDateGeneral.getTimezoneOffset());

            const periodList = [];
            const dateCurrent = new Date();
            const dateAux = new Date(startDateGeneral.getFullYear(), startDateGeneral.getMonth());

            while (dateAux <= dateCurrent) {
                const periodInit = new Date(dateAux.toString());
                dateAux.setMonth(dateAux.getMonth() + 3);
                if (dateAux > endDateGeneral || dateAux > dateCurrent) {
                    break;
                } else {
                    periodList.push({
                        value: `${monthDict[periodInit.getMonth() + 1]}/${periodInit.getFullYear().toString().substr(-2)} - ${monthDict[dateAux.getMonth() + 1]}/${dateAux.getFullYear().toString().substr(-2)}`,
                        init: periodInit,
                        end: new Date(dateAux.getFullYear(), dateAux.getMonth() + 1, 0, 23, 59, 59),
                    });
                }

                dateAux.setMonth(dateAux.getMonth() + 1);
            }

            const periodFilter = periodList.filter(x => !participantFeedback.some(feed => feed.period === x.value && feed.id !== parseInt(feedbackId)));
            setPeriod(periodFilter);
            const periodSelected = participantFeedback.find(x => x.id === parseInt(feedbackId));
            if (periodSelected) setPeriodLabel(periodSelected.period);
        }
    }, [project, participantFeedback]);

    useEffect(() => {
        getUser(id);
        getUserCosts(id);
        let year = 0;

        if (selectedDate._isAMomentObject)
            year = selectedDate._d.getFullYear();
        else
            year = selectedDate.getFullYear();

        const costs = userCosts.filter(el => el.costYear === year && el.costName !== "TotalCost");
        const annualCost = costs.reduce(function (total, el) { return total + el.cost; }, 0);
        const lastCost = costs.slice(-1)[0];

        //  const contractType = lastCost ? contractDict[lastCost[0].contractType] : '-';
        setState({
            ...state,
            name: user.name,
            cpf: user.cpf,
            annualCost: setBrazilCurrency(annualCost),
            imagePath: user.imagePath,
            // contractType: contractType,
        });

    }, [user.imagePath, Object.keys(userCosts).length, selectedDate, windowDimensions.width, windowDimensions.height]);

    const dateDifference = (start, end) => {
        // Copy date objects so don't modify originals
        const s = new Date(+start);
        const e = new Date(+end);

        // Set time to midday to avoid dalight saving and browser quirks
        s.setHours(12, 0, 0, 0);
        e.setHours(12, 0, 0, 0);

        // Get the difference in whole days
        const totalDays = Math.round((e - s) / 8.64e7);

        // Get the difference in whole weeks
        const wholeWeeks = totalDays / 7 | 0;

        // Estimate business days as number of whole weeks * 5
        let days = wholeWeeks * 5;

        // If not even number of weeks, calc remaining weekend days
        if (totalDays % 7) {
            s.setDate(s.getDate() + wholeWeeks * 7);

            while (s < e) {
                s.setDate(s.getDate() + 1);

                // If day isn't a Sunday or Saturday, add to business days
                if (s.getDay() !== 0 && s.getDay() !== 6) {
                    ++days;
                }
            }
        }
        return days;
    }

    const converTotBrCurrency = number => {
        var temp = "";
        var len = number.length;
        var count = 0;

        for (var i = 0; i < len; i++) {
            if (i === 1) {
                let aux = "," + number[i] + temp;
                temp = aux;
            } else {
                if (count === 3) {
                    count = 1;
                    let aux = number[i] + "." + temp;
                    temp = aux;
                } else {
                    if (i > 1) {
                        count += 1;
                    }
                    let aux = number[i] + temp;
                    temp = aux;
                }
            }
        }

        return temp;
    };

    const setBrazilCurrency = cost => {
        cost = cost.toString().split("");

        if (
            (cost[cost.length - 1] === "0" && cost[cost.length - 2] === "0") ||
            cost[cost.length - 3] !== "."
        ) {
            if (cost[cost.length - 2] === ".") {
                cost.push("0");
            } else {
                cost.push("0");
                cost.push("0");
            }
        }

        cost = cost
            .filter(el => el !== "." && el !== ",")
            .join("")
            .split("")
            .reverse()
            .join("");

        return (converTotBrCurrency(cost));
    }

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    };

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    });

    const handleFieldFeedback = event => {
        setShowRequiredField(false);
        if (event && event.target) {
            setFeedbackText(event.target.value);
        }
    };

    const handleFieldImprovement = event => {
        setShowRequiredField(false);
        if (event && event.target) {
            setImprovementPoints(event.target.value);
        }
    };

    const handleQuestion = (questionId, newValue) => {
        if (feedbackMode !== t("GENERAL.viewMode")) {
            const newObj = formQuestions.map(element => {
                const sub = element.subSection.map(subElement => {
                    const res = subElement.questions.map(question => {
                        if (question.id !== questionId) return question;
                        return { ...question, value: newValue };
                    });
                    return { ...subElement, questions: res };
                });
                return { ...element, subSection: sub };
            });
            setFormQuestions(newObj);
        }
    }

    const handleChangePeriod = event => {
        setShowRequiredField(false);
        if (event && event.target) {
            setPeriodLabel(event.target.value);
        }
    }

    const handleSubmit = () => {
        var emailDates = []
        if (feedbackText !== '' && improvementPoints !== '' && periodLabel !== '') {
            const periodSelected = participantFeedback.find(x => x.id === parseInt(feedbackId));
            if (periodSelected) {
                setShowRequiredField(false);
                const data = JSON.parse(localStorage.getItem("auth-token"));
                const shortname = data["name"];

                // Deep Clone
                const obj = JSON.parse(JSON.stringify(periodSelected));
                obj.participantId = participationId;
                obj.period = periodLabel;
                obj.dateCreate = new Date();
                obj.evaluator = shortname;
                obj.feedback = feedbackText;
                obj.improvementPoints = improvementPoints;

                formQuestions.forEach(element => {
                    element.subSection.forEach(subElement => {
                        subElement.questions.forEach(question => {
                            obj[question.id] = question.value;
                        });
                    });
                });

                var auxEmail = {}

                auxEmail.email = user.email
                auxEmail.name = user.name
                auxEmail.obj = obj
                auxEmail.url = linkGraphUrl
                emailDates.push(auxEmail)
                var auxEmail = {}

                updateFeedback(feedbackId, participationId, obj);
                sendEmail(emailDates)
            }
            setMustRedirect(true);
        } else {
            setShowRequiredField(true);
        }
    };

    const redirect = go => {
        if (go) return <Redirect to={`/execution/participant/${mode}/${participationId}/${id}/${projectId}/${participantMode}`} />;
    };

    const callbackLinkGraph = (value) => {
        setLinkGraphUrl(value);
    };

    const transitions = useTransition(loading, null, {
        from: { opacity: 0 },
        enter: {
            opacity: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "calc(100vh - 80px)"
        },
        leave: { opacity: 0 }
    });

    return transitions.map(({ item, key, props }) =>
        item ? (
            <animated.div key={key} style={props}>
                <Spinner />
            </animated.div>
        ) : (
            <div key={key}>
                {redirect(mustRedirect)}
                <DashboardNavBar />
                <SideBar />
                <animated.div style={props}>
                    <div className="container">
                        <Breadcrumbs
                            className={classes.breadCrumbs}
                            aria-label="breadcrumb"
                            separator={<NavigateNextIcon fontSize="small" />}
                        >
                            <Typography>{t("PARTICIPANTS.labor")}</Typography>
                            <Link className={classes.link} to="/execution/projects/">
                                {t("PROJECTS.projects")}
                            </Link>
                            <Link className={classes.link} to={`/execution/projects/${projectId}/${mode}`}>
                                {t("PROJECTS.project")}
                            </Link>
                            <Link className={classes.link} to={`/execution/participant/${mode}/${participationId}/${id}/${projectId}/${participantMode}`}>
                                {t("PARTICIPANTS.participant")}
                            </Link>
                            <Typography>{feedbackMode === t("GENERAL.viewMode") ? t("PARTICIPANTS.viewFeedback") : t("PARTICIPANTS.editFeedback")}</Typography>
                        </Breadcrumbs>
                        <div className="container-header">
                            <div className="title">
                                <h1 className={classes.title}>{feedbackMode === t("GENERAL.viewMode") ? t("PARTICIPANTS.viewFeedback") : t("PARTICIPANTS.editFeedback")}</h1>
                            </div>
                        </div>
                        <form id="formUsers">
                            <Paper className={classes.root}>
                                <div className={classes.header}>
                                    {t("PARTICIPANTS.panticipantInformation").toUpperCase()}
                                </div>
                                <div className={classes.bodyForm}>
                                    <Grid container>
                                        <Hidden mdDown>
                                            <Grid item lg={2}>
                                                <div className={classes.avatarParent}>
                                                    <div
                                                        className="avatar"
                                                        style={{
                                                            backgroundImage: `url(${state.imagePath ?
                                                                state.imagePath
                                                                : window.location.origin + "/images/users/icon-default.png"
                                                                })`
                                                        }}
                                                    ></div>
                                                </div>
                                            </Grid>
                                        </Hidden>
                                        <Grid container item spacing={2} md={12} lg={10}>
                                            <Grid item xs={4}>
                                                <TextField
                                                    disabled
                                                    className={classes.textField}
                                                    margin="normal"
                                                    id="name"
                                                    label={t("PARTICIPANTS.participantName")}
                                                    name="name"
                                                    value={state.name}
                                                    autoFocus
                                                    InputProps={{
                                                        readOnly: true
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    disabled
                                                    className={classes.textField}
                                                    margin="normal"
                                                    id="cpf"
                                                    label="CPF"
                                                    name="CPF"
                                                    value={state.cpf}
                                                    autoFocus
                                                    InputProps={{
                                                        readOnly: true
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    disabled
                                                    className={classes.textField}
                                                    margin="normal"
                                                    id="annualCost"
                                                    label={t("PARTICIPANTS.annualCost")}
                                                    name="annualCost"
                                                    value={"R$ " + state.annualCost}
                                                    autoFocus
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <MuiPickersUtilsProvider locale="pt" utils={MomentUtils}>
                                                    <DatePicker
                                                        id="yearBase"
                                                        variant="inline"
                                                        openTo="year"
                                                        name="yearBase"
                                                        views={["year"]}
                                                        label={t("PROJECTS.baseYear")}
                                                        margin="normal"
                                                        autoOk={true}
                                                        value={selectedDate}
                                                        onChange={handleDateChange}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <InsertInvitationIcon color="action" />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Paper>
                        </form>
                        <br />
                        <br />
                        <Paper className={classes.root}>
                            <div className={classes.header}>
                                {t("PARTICIPANTS.formFeedback").toUpperCase()}
                            </div>
                            <div className={classes.bodyForm}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <TextField
                                            select
                                            id="period"
                                            label={"Período avaliado"}
                                            className={classes.textField, classes.fieldInputSearch}
                                            required
                                            name="periods"
                                            margin="normal"
                                            value={periodLabel}
                                            onChange={handleChangePeriod}
                                            disabled={feedbackMode === t("GENERAL.viewMode")}
                                        >
                                            {period.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.value}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div style={{ marginTop: 25, marginBottom: 25, border: '1px solid #CCCCCC', padding: 16, borderRadius: 5 }}>
                                            <div className={classes.questionSectionTitle}>
                                                {t("PARTICIPANTS.pointInformation")}
                                            </div>
                                            <DonutChart
                                                approvedRequests={solicitation.approvedRequests}
                                                deniedRequests={solicitation.deniedRequests}
                                                waitingApproval={solicitation.waitingApproval}
                                                waitingOvertime={solicitation.waitingOvertime}
                                                approvedOvertime={solicitation.approvedOvertime}
                                                withoutInconsistency={solicitation.withoutInconsistency}
                                                pendingRequests={solicitation.pendingRequests}
                                                callbackLinkGraph={callbackLinkGraph}
                                            />
                                        </div>
                                    </Grid>
                                    {formQuestions.map(element =>
                                        <Grid item xs={6} key={element.section}>
                                            <div className={classes.questionSectionTitle}>{element.section}</div>
                                            {element.subSection.map(subElement =>
                                                <React.Fragment key={subElement.name}>
                                                    <div className={classes.questionSectionSubTitle}>{subElement.name}</div>
                                                    {subElement.questions.map(question =>
                                                        <div className={classes.questionFeedback} key={question.text}>
                                                            <div className={classes.question}>{question.text}</div>
                                                            <div className={classes.questionRating}>
                                                                <div className={classes.questionRatingText}>{question.minText}</div>
                                                                {[1, 2, 3, 4, 5].map(item =>
                                                                    <Button
                                                                        variant="contained"
                                                                        size="small"
                                                                        className={item === question.value ? classes.questionRatingSelected : classes.questionRatingButton}
                                                                        onClick={() => handleQuestion(question.id, item)}
                                                                        key={item + question.minText}
                                                                    >
                                                                        {item}
                                                                    </Button>
                                                                )}
                                                                <div className={classes.questionRatingText}>{question.maxText}</div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <MuiThemeProvider theme={tooltipTheme}>
                                            <Tooltip
                                                TransitionComponent={Zoom}
                                                open={feedbackText === "" && showRequiredField}
                                                title={t("GENERAL.requiredField")}
                                            >
                                                <TextField
                                                    multiline
                                                    fullWidth
                                                    variant="outlined"
                                                    rows="3"
                                                    value={feedbackText}
                                                    label={t("PARTICIPANTS.feedback")}
                                                    margin="normal"
                                                    required
                                                    onChange={handleFieldFeedback}
                                                    disabled={feedbackMode === t("GENERAL.viewMode")}
                                                />
                                            </Tooltip>
                                        </MuiThemeProvider>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MuiThemeProvider theme={tooltipTheme}>
                                            <Tooltip
                                                TransitionComponent={Zoom}
                                                open={improvementPoints === "" && showRequiredField}
                                                title={t("GENERAL.requiredField")}
                                            >
                                                <TextField
                                                    multiline
                                                    fullWidth
                                                    variant="outlined"
                                                    rows="3"
                                                    value={improvementPoints}
                                                    label={t("PARTICIPANTS.improvementPoints")}
                                                    margin="normal"
                                                    required
                                                    onChange={handleFieldImprovement}
                                                    disabled={feedbackMode === t("GENERAL.viewMode")}
                                                />
                                            </Tooltip>
                                        </MuiThemeProvider>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {feedbackMode !== t("GENERAL.viewMode") &&
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <Button className={classes.blueButton} variant="contained" color="primary" onClick={handleSubmit}>
                                                    {t("GENERAL.update").toUpperCase()}
                                                </Button>
                                            </div>
                                        }
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                    </div>
                    <Footer />
                </animated.div>
            </div >
        )
    );
}

export default EditFeedback;
