import DateFnsUtils from "@date-io/date-fns";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import Attachment from "@material-ui/icons/Attachment";
import Down from "@material-ui/icons/GetApp";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import axios from "axios";
import { useStyles } from "components/layout/CommonStyles";
import Spinner from "components/layout/spinners/Index";
import ProjectsContext from "context/projects/projectsContext";
import React, { useContext, useEffect, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { animated, useTransition } from "react-spring";
import Cookies from "js-cookie";

const tooltipTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#2c3e51",
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: "white",
        fontSize: "1em",
        backgroundColor: "red",
        opacity: "0.7",
      },
    },
  },
});

// datepicker api https://material-ui-pickers.dev/api/KeyboardDatePicker
const ViewAddendum = (props) => {
  let canGetFile = true;
  const classes = useStyles();
  const [t] = useTranslation();
  var fileDownload = require("js-file-download");

  const requiredField = "GENERAL.requiredField";
  const estimatedCostRef = useRef(null);

  const {
    addendum,
    increment,
    callbackFromCooperationTerm,
    projectId,
    previousAddendum,
  } = props;
  const projectsContext = useContext(ProjectsContext);
  const formData = new FormData();
  const {
    loading,
    setMessage,
    resetAddendums,
    triggerUpdateCreate,
    updateCreateAddendums,
    idealNumberOfChildUpdates,
    triggerUpdateCreateAction,
    setIdealNumberOfChildUpdates,
  } = projectsContext;

  const [gpf, setgpf] = useState("");
  const [state, setState] = useState({
    id: 0,
    fileId: 0,
    fileTitle: t("GENERAL.addDocument"),
    validEstimatedCost: false,
    hasUpdatedFile: false,
    validDoc: false,
    number: "",
    stateOpacity: 1,
    projectId: null,
    estimatedCost: "",
    endDate: new Date(),
    isDownloadable: true,
    startDate: new Date(),
    cooperationTerm: false,
    openDeleteModal: false,
    fullPath: "",
  });

  const converTotBrCurrency = (number) => {
    var temp = "";
    var len = number.length;
    var count = 0;

    for (var i = 0; i < len; i++) {
      if (i === 1) {
        var aux = "," + number[i] + temp;
        temp = aux;
      } else {
        if (count === 3) {
          count = 1;
          var aux = number[i] + "." + temp;
          temp = aux;
        } else {
          if (i > 1) {
            count += 1;
          }
          var aux = number[i] + temp;
          temp = aux;
        }
      }
    }
    return temp;
  };

  const checkIfCurrentDateIsPastFromPreviousAddendumEndDate = (now) => {
    if (new Date(previousAddendum.enddate) > now) {
      const previousEndDate = new Date(previousAddendum.enddate);
      return new Date(previousEndDate.setDate(previousEndDate.getDate() + 1));
    } else {
      return new Date(now);
    }
  };

  useEffect(() => {
    setIdealNumberOfChildUpdates(idealNumberOfChildUpdates);
    var estimatedCost = null;
    if (addendum.estimatedcost) {
      var cost = addendum.estimatedcost.toString().split("");
      if (
        (cost[cost.length - 1] === "0" && cost[cost.length - 2] === "0") ||
        cost[cost.length - 3] !== "."
      ) {
        cost.push("0");
        cost.push("0");
      }

      estimatedCost = cost
        .filter((el) => el !== "." && el !== ",")
        .join("")
        .split("")
        .reverse()
        .join("");
    }

    var startdatewithtimezone = new Date(addendum.startdate);
    startdatewithtimezone.setMinutes(
      startdatewithtimezone.getMinutes() +
        startdatewithtimezone.getTimezoneOffset()
    );
    var enddatewithtimezone = new Date(addendum.enddate);
    enddatewithtimezone.setMinutes(
      enddatewithtimezone.getMinutes() + enddatewithtimezone.getTimezoneOffset()
    );

    if (addendum.gpf) {
      setgpf(addendum.gpf);
    }

    setState({
      ...state,
      file: null,
      number: "ADDENDUM",
      projectId: projectId,
      fileId: addendum.fileId,
      id: addendum.id ? addendum.id : null,
      estimatedCost: estimatedCost ? converTotBrCurrency(estimatedCost) : "",
      isDownloadable: addendum.fileId ? false : true,
      startDate: addendum.startdate
        ? startdatewithtimezone
        : checkIfCurrentDateIsPastFromPreviousAddendumEndDate(new Date()),
      endDate: addendum.enddate
        ? enddatewithtimezone
        : checkIfCurrentDateIsPastFromPreviousAddendumEndDate(new Date()),
    });

    // lidando com a busca do arquivo
    if (addendum.fileId) {
      getFile(addendum.fileId);
    }

    if (triggerUpdateCreateAction) {
      if (estimatedCostRef.current.value.length === 0) {
        estimatedCostRef.current.focus();
        setState({
          ...state,
          validEstimatedCost: true,
        });
        resetAddendums();
        triggerUpdateCreate(false);
      } else {
        var patternStimatedCost = state.estimatedCost
          .toString()
          .split("")
          .filter((el) => el !== ".")
          .join("")
          .replace(",", ".");
        formData.append("id", state.id);
        formData.append("file", state.file);
        formData.append("number", state.number);
        formData.append("gpf", gpf);
        formData.append("projectId", projectId);
        formData.append("estimatedCost", patternStimatedCost);
        formData.append("cooperationTerm", state.cooperationTerm);
        formData.append("fileId", state.fileId ? state.fileId : null);
        formData.append("endDate", state.endDate.toLocaleDateString());
        formData.append("startDate", state.startDate.toLocaleDateString());
        updateCreateAddendums(formData);
      }
    }

    return () => {
      canGetFile = false;
    };
  }, [triggerUpdateCreateAction]);

  const getFile = (id) => {
    axios
      .get(process.env.REACT_APP_SERVER + `api/ProjectFiles/${id}`, {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "bearer " +
            (Cookies.get("auth-token") ? Cookies.get("auth-token") : ""),
        },
      })
      .then((res) => {
        var array = res.data.path.split("/");
        if (canGetFile) {
          var estimatedCost = null;
          if (addendum.estimatedcost) {
            var cost = addendum.estimatedcost.toString().split("");
            if (
              (cost[cost.length - 1] === "0" &&
                cost[cost.length - 2] === "0") ||
              cost[cost.length - 3] !== "."
            ) {
              cost.push("0");
              cost.push("0");
            }

            estimatedCost = cost
              .filter((el) => el !== "." && el !== ",")
              .join("")
              .split("")
              .reverse()
              .join("");
          }

          setState({
            ...state,
            file: null, //res.data
            number: "ADDENDUM",
            projectId: projectId,
            fileId: addendum.fileId,
            fullPath: res.data.path,
            startDate: addendum.startdate
              ? new Date(addendum.startdate)
              : new Date(),
            fileTitle: array[array.length - 1],
            id: addendum.id ? addendum.id : null,
            estimatedCost: estimatedCost
              ? converTotBrCurrency(estimatedCost)
              : "",
            isDownloadable: addendum.fileId ? false : true,
            endDate: addendum.enddate ? new Date(addendum.enddate) : new Date(),
          });
        }
      });
  };

  const handleFieldChangeCost = (name) => (event) => {
    if (event.target.value !== " " && !event.target.value.includes(" ")) {
      var str = event.target.value.split("");
      var aux = str.filter((el) => el !== "." && el !== ",").join("");
      var value = event.target.value;

      if (event.target.value) {
        value = converTotBrCurrency(aux.split("").reverse().join(""));
      }

      aux = value
        .split("")
        .filter((el) => el !== ".")
        .join("")
        .replace(",", ".");
      if (!isNaN(aux)) {
        setState({ ...state, [name]: value, validEstimatedCost: false });
      }
    }
  };

  const onClickDownload = async () => {
    if (state.fileTitle !== t("GENERAL.addDocument")) {
      var noSlashPath = state.fullPath.replace(/\//g, ";");

      const url = process.env.REACT_APP_SERVER + "api/file/" + noSlashPath;
      axios
        .get(url, {
          headers: {
            "Content-type": "application/json",
            "Authorization": "bearer " + (Cookies.get("auth-token") ? Cookies.get("auth-token") : ""),
          },
          responseType: "blob",
        })
        .then(function (res) {
          var blob = new Blob([res.data], {});
          fileDownload(blob, state.fileTitle);
        })
        .catch((error) => {
          setMessage({
            response: t("PROJECTS.step.cooperationTerm.downloadError"),
            responseType: "ERROR",
          });
        });
    } else {
      console.log("treat erro when download has no file");
    }
  };

  const handleDates = (name) => (e) => {
    setState({
      ...state,
      [name]: e,
    });
  };

  const onDropAddendum = (acceptedFiles) => {
    if (acceptedFiles[0] !== undefined) {
      setState({
        ...state,
        file: acceptedFiles[0],
        fileTitle: acceptedFiles[0].name,
        hasUpdatedFile: true,
      });
    } else {
      setState({
        ...state,
        fileTitle: t("GENERAL.invalidFormat"),
      });
    }
  };

  const transitions = useTransition(loading, null, {
    from: { opacity: 0 },
    enter: {
      opacity: 1,
    },
    leave: { opacity: 0 },
  });

  return transitions.map(({ item, key, props }) =>
    item ? (
      <animated.div key={key} style={props}>
        <Spinner />
      </animated.div>
    ) : (
      <animated.div key={key} style={props}>
        <div className={classes.addendumHeader}>
          <Paper className={classes.root}>
            <div className={classes.bodyForm}>
              <Grid container item spacing={1}>
                <Grid
                  item
                  xs={6}
                  className={classes.addendumIntermIconsMarginLeft}
                >
                  {t("PROJECTS.step.addendum") + " " + increment}
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.addendumIntermIcons}>
                    <div className={classes.addendumIntermIconsMarginRight}>
                      <Tooltip
                        title={t("PROJECTS.step.addendum.download")}
                        aria-label="download Addendum"
                        placement="top"
                      >
                        <div>
                          <IconButton
                            disabled={state.isDownloadable}
                            onClick={onClickDownload}
                          >
                            <Down />
                          </IconButton>
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <MuiThemeProvider theme={tooltipTheme}>
                    <Tooltip
                      TransitionComponent={Zoom}
                      open={state.validEstimatedCost}
                      title={t(requiredField)}
                    >
                      <div>
                        <TextField
                          disabled
                          inputRef={estimatedCostRef}
                          className={classes.addendumTextField}
                          margin="normal"
                          id={"addendumCost" + increment}
                          label={t("PROJECTS.estimatedCost") + "*"}
                          name="estimatedCost"
                          error={state.validEstimatedCost}
                          value={state.estimatedCost}
                          onChange={handleFieldChangeCost("estimatedCost")}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                R$
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </Tooltip>
                  </MuiThemeProvider>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    disabled
                    id="gpf"
                    name="gpf"
                    label={t("PROJECTS.gpfUpperCase")}
                    className={classes.addendumTextField}
                    value={gpf}
                    onChange={(e) => {
                      setgpf(e.target.value);
                    }}
                    //                  onFocus={clearRiqueredMessage}
                    margin="normal"
                  />
                </Grid>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid item xs={2}>
                    <KeyboardDatePicker
                      disabled
                      required
                      className={classes.addendumTextField}
                      disableToolbar
                      autoOk={true}
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="startDate"
                      label={t("GENERAL.startDate")}
                      value={state.startDate}
                      onChange={handleDates("startDate")}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <KeyboardDatePicker
                      disabled
                      required
                      className={classes.addendumTextField}
                      disableToolbar
                      autoOk={true}
                      minDate={state.startDate}
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="endDate"
                      label={t("GENERAL.endDate")}
                      value={state.endDate}
                      onChange={handleDates("endDate")}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
                <Grid item xs={3}>
                  <Dropzone
                    disabled
                    variant="outlined"
                    component="span"
                    className={classes.addendumTextField}
                    onDrop={onDropAddendum}
                    accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  >
                    {({
                      getRootProps,
                      getInputProps,
                      isDragActive,
                      isDragReject,
                    }) => (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {!isDragActive && (
                          <FormControl
                            className={
                              classes.customAddendumAttachedDocumentTextField
                            }
                          >
                            <InputLabel>
                              {state.hasUpdatedFile || !state.isDownloadable
                                ? t("PROJECTS.hasAttachedFile")
                                : ""}
                            </InputLabel>
                            <Input
                              id="custom-attached-input-drag"
                              disabled
                              endAdornment={
                                <InputAdornment
                                  style={{ transform: "rotate(270deg)" }}
                                  position="end"
                                >
                                  <Attachment />
                                </InputAdornment>
                              }
                              aria-describedby="weight-helper-text"
                              inputProps={{
                                "aria-label": "weight",
                              }}
                              value={t(state.fileTitle)}
                            />
                          </FormControl>
                        )}
                        {isDragReject && (
                          <FormControl
                            className={
                              classes.customAddendumAttachedDocumentTextField
                            }
                          >
                            <InputLabel>
                              {state.hasUpdatedFile || !state.isDownloadable
                                ? t("PROJECTS.hasAttachedFile")
                                : ""}
                            </InputLabel>
                            <Input
                              id="custom-attached-input"
                              readOnly={true}
                              endAdornment={
                                <InputAdornment
                                  style={{ transform: "rotate(270deg)" }}
                                  position="end"
                                >
                                  <Attachment />
                                </InputAdornment>
                              }
                              aria-describedby="weight-helper-text"
                              inputProps={{
                                "aria-label": "weight",
                              }}
                              value={t(state.fileTitle)}
                            />
                          </FormControl>
                        )}
                      </div>
                    )}
                  </Dropzone>
                </Grid>
              </Grid>
            </div>
          </Paper>
        </div>
      </animated.div>
    )
  );
};

export default ViewAddendum;

