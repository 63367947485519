/* eslint-disable */
import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { useStyles } from "components/profiles/ProfileStyles";
import ProjectsContext from "context/projects/projectsContext";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import AllowancesContext from "context/allowance/allowanceContext";


const tooltipTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#2c3e51"
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: "white",
        fontSize: "1em",
        backgroundColor: "red",
        opacity: "0.7"
      }
    }
  }
});

var verifyGPF = false;
var verifyName = false;
var verifyCodeName = false;
var verifyDate = false;

const GeneralInformations = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [title, setTitle] = useState("GENERAL.requiredField");
  const [titleGpf, setTitleGpf] = useState("GENERAL.requiredField");
  const [titleName, setTitleName] = useState("GENERAL.requiredField");
  const [titleCodeName, setTitleCodeName] = useState("GENERAL.requiredField");
  const [titleBaseYear, setBaseYear] = useState("GENERAL.requiredField");
  const [titleDate, setTitleDate] = useState(
    "Data de Término não pode ser menor que a de Início"
  );

  const [dates, setDates] = useState({
    startDate: new Date(),
    endDate: new Date()
  });

  const projectsContext = useContext(ProjectsContext);
  const [openName, setOpenName] = useState(false);
  const [openGpf, setOpenGpf] = useState(false);
  const [openCodeName, setOpenCodeName] = useState(false);
  const [openEstimatedCost, setOpenEstimatedCost] = useState(false);
  const [openClient, setOpenClient] = useState(false);
  const [openBaseY, setOpenBase] = useState(false);
  const [openDate, setOpenDate] = useState(false);

  const gpfRef = useRef(null);
  const nameRef = useRef(null);
  const codenameRef = useRef(null);
  const baseyearRef = useRef(null);
  const estimatedCostRef = useRef(null);
  const clientRef = useRef(null);
  const dateRef = useRef(null);

  const [state, setState] = useState({
    gpf: "",
    name: "",
    codeName: "",
    estimatedCost: "",
    baseYear: null,
    isActive: false,
    client: "",
    clientId: null,
    coordinator: "",
    coordinatorId: 0,
    supportFoundation: "",
    supportFoundationId: 0
  });

  const {
    users,
    partners,
    stepFlow,
    projects,
    getUsers,
    getPartners,
    getProjects,
    newProjectId,
    createProject,
    changeStepFlow,
    projectCreated
  } = projectsContext;

  const allowanceContext = useContext(AllowancesContext);


  const {
    allAllowances,
    getAllPermission
  } = allowanceContext;

  useEffect(() => {
    getPartners(partners);
    getProjects();
    getUsers(users);
    getAllPermission()
    if (stepFlow) shouldSendGeneralInformation();
  }, [
    Object.values(partners).length,
    JSON.stringify(partners).length,
    Object.values(users).length,
    stepFlow,
    newProjectId,
    projectCreated
  ]);


  const getPermission = (screen, allowance) => {
    for (let i = 0; i < allAllowances.length; i++) {
      if (allAllowances[i].resource === screen) {
        return allAllowances[i]["standardauthorizationoption"][allowance]
      }
    }
  }

  var clients = partners.filter(function(item) {
    return item.type === "CUSTOMER";
  });

  var supportFoundations = partners.filter(function(item) {
    return item.type === "EXECUTING_INSTITUTION";
  });

  const converTotBrCurrency = number => {
    var temp = "";
    var len = number.length;
    var count = 0;

    for (var i = 0; i < len; i++) {
      if (i === 1) {
        var aux = "," + number[i] + temp;
        temp = aux;
      } else {
        if (count === 3) {
          count = 1;
          var aux = number[i] + "." + temp;
          temp = aux;
        } else {
          if (i > 1) {
            count += 1;
          }
          var aux = number[i] + temp;
          temp = aux;
        }
      }
    }

    return temp;
  };

  const cleanUpRequiredMessage = ref => {
    switch (ref) {
      case "gpf":
        setOpenGpf(false);
        break;
      case "name":
        setOpenName(false);
        break;
      case "estimatedCost":
        setOpenEstimatedCost(false);
        break;
      case "client":
        setOpenClient(false);
        break;
      case "baseYear":
        setOpenBase(false);
        break;
      default:
    }
  };

  const getId = (name, eventName) => {
    var i;
    var id;
    var array = {};

    switch (name) {
      case "client":
        array = clients;
        break;
      case "supportFoundation":
        array = supportFoundations;
        break;
      case "coordinator":
        array = users;
        break;
      default:
        break;
    }
    for (i = 0; i < array.length; i++) {
      if (array[i].label === eventName) {
        id = array[i].value;
        break;
      }
    }
    return id;
  };

  // const handleFieldChangeCost = name => event => {
  //   var str = event.target.value.split("");
  //   var aux = str.filter(el => el !== "." && el !== ",").join("");
  //   var value = event.target.value;

  //   if (event.target.value)
  //     value = converTotBrCurrency(
  //       aux
  //         .split("")
  //         .reverse()
  //         .join("")
  //     );

  //   setState({ ...state, [name]: value });
  // };
  const handleFieldChangeCost = name => event => {
    cleanUpRequiredMessage(name);
    if (event.target.value !== " " && !event.target.value.includes(" ")) {
      var str = event.target.value.split("");
      var aux = str.filter(el => el !== "." && el !== ",").join("");
      var value = event.target.value;
      if (event.target.value) {
        value = converTotBrCurrency(
          aux
            .split("")
            .reverse()
            .join("")
        );
      }
      aux = value
        .split("")
        .filter(el => el !== ".")
        .join("")
        .replace(",", ".");
      if (!isNaN(aux)) {
        setState({ ...state, [name]: value });
      }
    }
  };

  const handleChange = name => event => {
    cleanUpRequiredMessage(name);
    var id = getId(name, event.target.value);
    setState({
      ...state,
      [name]: event.target.value,
      [name + "Id"]: id
    });
  };
  let data,
    data2,
    data3 = [];

  data = projects;
  data2 = projects;
  data3 = projects;

  // const handleFieldChangeGPF = name => event => {
  //   cleanUpRequiredMessage(name);
  //   setState({ ...state, [name]: event.target.value });
  //   data = data.filter(item => item.gpf === event.target.value);
  //   if (data.length === 1) {
  //     verifyGPF = true;
  //     setTitleGpf("PROJECTS.gpfAlreadyTaken");
  //     setOpenGpf(true);
  //   } else {
  //     verifyGPF = false;
  //     setOpenGpf(false);
  //   }
  // };

  const handleFieldChange = name => event => {
    cleanUpRequiredMessage(name);
    setState({ ...state, [name]: event.target.value });
  };

  const handleFieldChangeName = name => event => {
    cleanUpRequiredMessage(name);
    setState({ ...state, [name]: event.target.value });
    data2 = data2.filter(item => item.name === event.target.value);
    if (name === "name" && data2.length === 1) {
      verifyName = true;
      setTitleName("PROJECTS.nameAlreadyTaken");
      setOpenName(true);
    } else {
      verifyName = false;
      setOpenName(false);
    }
  };

  const handleFieldChangeCodeName = name => event => {
    cleanUpRequiredMessage(name);
    setState({ ...state, [name]: event.target.value });
    data3 = data3.filter(item => item.codeName === event.target.value);
    if (name === "codeName" && data3.length === 1) {
      verifyCodeName = true;
      setTitleCodeName("PROJECTS.codenameAlreadyTaken");
      setOpenCodeName(true);
    } else {
      verifyCodeName = false;
      setOpenCodeName(false);
    }
  };

  const handleDateChange = name => e => {
    setDates({
      ...dates,
      [name]: e
    });
  };

  const verifyCanSubmit = () => {
    var nameEmpty = nameRef.current.value.length === 0;
    // var gpfEmpty = gpfRef.current.value.length === 0;
    var estimatedCostEmpty = estimatedCostRef.current.value.length === 0;
    var clientEmpty = clientRef.current.value.length === 0;
    var baseYear = baseyearRef.current.value.length === 0;

    if (
      baseyearRef.current.value.length < 4 &&
      baseyearRef.current.value.length > 0
    ) {
      setBaseYear("No mínimo 4 dígitos");
      setOpenBase(true);
    }
    // if (verifyGPF) {
    //   gpfRef.current.focus();
    //   setOpenGpf(true);
    //   changeStepFlow(0);
    //   return false;
    // }
    if (verifyName) {
      nameRef.current.focus();
      setOpenName(true);
      changeStepFlow(0);
      return false;
    }
    if (verifyCodeName) {
      codenameRef.current.focus();
      setOpenCodeName(true);
      changeStepFlow(0);
      return false;
    }
    if (verifyDate) {
      setOpenDate(true);
      changeStepFlow(0);
      return false;
    }
    if (
      nameEmpty ||
      estimatedCostEmpty ||
      clientEmpty ||
      baseYear
    ) {
      // if (gpfEmpty) setOpenGpf(true);
      if (nameEmpty) setOpenName(true);
      if (estimatedCostEmpty) setOpenEstimatedCost(true);
      if (clientEmpty) setOpenClient(true);
      if (baseYear) setOpenBase(true);
      setTitle("GENERAL.requiredField");
      // setTitleGpf("GENERAL.requiredField");
      setTitleName("GENERAL.requiredField");
      setTitleCodeName("GENERAL.requiredField");
      changeStepFlow(0);
      return false;
    } else {
      return true;
    }
  };

  const shouldSendGeneralInformation = () => {
    var canSubmit = verifyCanSubmit();

    if (stepFlow === 1 && canSubmit) {
      var aux = [];
      aux.gpf = state.gpf;
      aux.name = state.name;
      aux.codeName = state.codeName;
      aux.estimatedCost = state.estimatedCost
        .split("")
        .filter(el => el !== ".")
        .join("")
        .replace(",", ".");
      aux.startDate = document.getElementById("startDate").value;
      aux.endDate = document.getElementById("endDate").value;
      aux.baseYear = state.baseYear;
      aux.clientId = state.clientId;
      aux.supportFoundationId = state.supportFoundationId;
      aux.coordinatorId = state.coordinatorId;
      createProject(aux);
    }
  };

  const renderRedirect = () => {
    if (projectCreated && newProjectId !== 0) {
      return <Redirect to={`/projects/${newProjectId}/edit`} />;
    }
  };

  const renderRedirectProjects = () => {
    if (projectCreated && newProjectId !== 0) {
      return <Redirect to={`/projects`} />;
    }
  };

  return (
    <div>
      {
        (!getPermission("COOPERATIONTERM","allowUpdate")
        && !getPermission("GOAL","allowUpdate")
        && !getPermission("SCOPE","allowUpdate")
        && !getPermission("HEADINGS2","allowUpdate")
        && !getPermission("SCHEDULE","allowUpdate")
        && !getPermission("BUBGETPLAN","allowUpdate")) ?
        renderRedirectProjects()
        :
        renderRedirect()}
      <form id="generalInformation">
        <Paper className={classes.root}>
          <div className={classes.cabecalho}>
            {t("GENERAL.generalInformation").toUpperCase()}
          </div>
          <div className={classes.bodyForm}>
            <Grid container item spacing={2} md={12} lg={12}>
              {/* <Grid item xs={1}>
                <MuiThemeProvider theme={tooltipTheme}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    open={openGpf}
                    title={t(titleGpf)}
                  >
                    <TextField
                      inputRef={gpfRef}
                      className={classes.textField}
                      margin="normal"
                      id="gpf"
                      label="GPF*"
                      name="GPF"
                      error={openGpf}
                      onChange={handleFieldChangeGPF("gpf")}
                      inputProps={{
                        maxLength: 10
                      }}
                    />
                  </Tooltip>
                </MuiThemeProvider>
              </Grid> */}
              <Grid item xs={5}>
                <MuiThemeProvider theme={tooltipTheme}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    open={openName}
                    title={t(titleName)}
                  >
                    <TextField
                      inputRef={nameRef}
                      className={classes.textField}
                      margin="normal"
                      id="name"
                      label={t("PERSONAL.name") + "*"}
                      name="name"
                      error={openName}
                      onChange={handleFieldChangeName("name")}
                      inputProps={{
                        maxLength: 120
                      }}
                    />
                  </Tooltip>
                </MuiThemeProvider>
              </Grid>
              <Grid item xs={4}>
                <MuiThemeProvider theme={tooltipTheme}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    open={openCodeName}
                    title={t(titleCodeName)}
                  >
                    <TextField
                      inputRef={codenameRef}
                      error={openCodeName}
                      className={classes.textField}
                      margin="normal"
                      id="codename"
                      label={t("PROJECTS.codename")}
                      name="codename"
                      onChange={handleFieldChangeCodeName("codeName")}
                      inputProps={{
                        maxLength: 120
                      }}
                    />
                  </Tooltip>
                </MuiThemeProvider>
              </Grid>
              <Grid item xs={3}>
                <MuiThemeProvider theme={tooltipTheme}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    open={openEstimatedCost}
                    title={t(title)}
                  >
                    <TextField
                      inputRef={estimatedCostRef}
                      className={classes.textField}
                      margin="normal"
                      label={t("PROJECTS.estimatedCost") + "*"}
                      name="estimatedCost"
                      //onBlur={updateFields}
                      value={state.estimatedCost}
                      onChange={handleFieldChangeCost("estimatedCost")}
                      error={openEstimatedCost}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">R$</InputAdornment>
                        )
                      }}
                    />
                  </Tooltip>
                </MuiThemeProvider>
              </Grid>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={4}>
                  <KeyboardDatePicker
                    className={classes.textField}
                    disableToolbar
                    required
                    autoOk={true}
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    value={dates.startDate}
                    id="startDate"
                    label={t("GENERAL.startDate")}
                    onChange={handleDateChange("startDate")}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <KeyboardDatePicker
                    className={classes.textField}
                    disableToolbar
                    required
                    autoOk={true}
                    variant="inline"
                    minDate={dates.startDate}
                    minDateMessage={"A data não deve ser anterior à data mínima"}
                    value={dates.endDate}
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="endDate"
                    label={t("GENERAL.endDate")}
                    onChange={handleDateChange("endDate")}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    className={classes.textField}
                    margin="normal"
                    label={t("ADDRESS.status") + "*"}
                    name="status"
                    id="status"
                    value={t("PROJECTS.planning")}
                  />
                </Grid>
                <Grid item xs={1}>
                  <MuiThemeProvider theme={tooltipTheme}>
                    <Tooltip
                      TransitionComponent={Zoom}
                      open={openBaseY}
                      title={t(titleBaseYear)}
                    >
                      <TextField
                        inputRef={baseyearRef}
                        error={openBaseY}
                        className={classes.textField}
                        margin="normal"
                        label={t("PROJECTS.baseYear") + "*"}
                        name="baseYear"
                        id="baseYear"
                        onChange={handleFieldChange("baseYear")}
                        inputProps={{
                          minLength: 4,
                          maxLength: 4
                        }}
                      />
                    </Tooltip>
                  </MuiThemeProvider>
                </Grid>
              </MuiPickersUtilsProvider>
              <Grid item xs={4}>
                <MuiThemeProvider theme={tooltipTheme}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    open={openClient}
                    title={t(title)}
                  >
                    <TextField
                      select
                      inputRef={clientRef}
                      id="client"
                      label={t("PROJECTS.client") + "*"}
                      name="client"
                      value={state.client}
                      className={classes.textField}
                      error={openClient}
                      onChange={handleChange("client")}
                      margin="normal"
                    >
                      {clients.map(client => (
                        <MenuItem key={client.value} value={client.label}>
                          {client.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Tooltip>
                </MuiThemeProvider>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  select
                  id="supportFoundation"
                  label={t("PROJECTS.supportFoundation")}
                  name="supportFoundation"
                  value={state.supportFoundation}
                  className={classes.textField}
                  onChange={handleChange("supportFoundation")}
                  margin="normal"
                >
                  {supportFoundations.map(supportFoundation => (
                    <MenuItem
                      key={supportFoundation.value}
                      value={supportFoundation.label}
                    >
                      {supportFoundation.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  select
                  id="coordinator"
                  label={t("PROJECTS.coordinator")}
                  name="coordinator"
                  value={state.coordinator}
                  className={classes.textField}
                  onChange={handleChange("coordinator")}
                  margin="normal"
                >
                  {users.map(user => (
                    <MenuItem key={user.value} value={user.label}>
                      {user.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </form>
    </div>
  );
};

export default GeneralInformations;
