import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogUnit from 'components/dialogs/DialogUnit';
import DialogPasswordConfirm from 'components/dialogs/DialogPasswordConfirm';
import { Grid, Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import Buttons from 'components/buttons/Pressed';
import Typography from 'components/typography/Typography';
import Input from 'components/inputs/Input';
import { FormatDateToFront } from 'utils/dates/FormatDateToFront';
import { dateToBR } from 'utils/dates/DateToBRr';
import { valueToMoneyFloat } from 'utils/general/valueToMoneyFloat';
import { moneyMask } from 'utils/masks/moneyMask';
import {
    translatePersonalTransactionsTypes,
    TransactionsTypes,
    translateLSBDTransactionsTypes
} from '../ScreenUtils';

import { useMutationCancelTransaction } from 'hooks/DataFetching/Quotas/Dashboard/useMutationCancelTransaction';

const DialogCancelTransactions = props => {
    const [t] = useTranslation();

    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const { open, setOpen, viewValues, isLSBD, isExpense } = props;

    const mutationCancelTransaction = useMutationCancelTransaction(setOpen);

    const [requiredJustification, setRequiredJustification] = useState(false);
    const [nextStep, setNextStep] = useState(false);

    const [values, setValues] = useState({
        justification: ''
    });

    const handleChange = (name, value) => {
        let val = value;
        setValues({ ...values, [name]: val });
    };

    const handleClose = () => {
        setOpen(false);
    };

    const verifySubmit = () => {
        let res = true;

        if (!values.justification.trim()) {
            res = false;
            setRequiredJustification(true);
        }

        return res;
    };

    const handleCancel = () => {
        const canSubmit = verifySubmit();
        if (canSubmit) {
            if (nextStep) {
                let formData = new FormData();

                formData.append('id', viewValues.id);
                formData.append('operatorId', auth.id ? auth.id : '');
                formData.append(
                    'justification',
                    values.justification ? values.justification : ''
                );

                mutationCancelTransaction.mutate(formData);
            } else {
                setNextStep(true);
            }
        }
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth={'sm'}
                fullWidth
            >
                {nextStep ? (
                    <DialogPasswordConfirm
                        setOpen={setOpen}
                        handleSubmit={handleCancel}
                        title={`${t('GENERAL.cancel')} ${t('QUOTAS.movement')}`}
                        text={t('QUOTAS.confirmCancelTransaction')}
                        buttonText={`${t('GENERAL.cancel')} ${t(
                            'QUOTAS.movement'
                        )}`.toUpperCase()}
                        mutation={mutationCancelTransaction}
                    />
                ) : (
                    <>
                        <DialogTitle>
                            <Grid container item spacing={2} xs={12}>
                                <Grid item xs={11}>
                                    <Typography
                                        variant={'RobotoBold'}
                                        fontSize={24}
                                        textColor={'secondary'}
                                    >
                                        {`${t('GENERAL.cancel')} ${t(
                                            'QUOTAS.movement'
                                        )}`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton
                                        color="#6D6F71"
                                        onClick={handleClose}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </DialogTitle>

                        <DialogContent>
                            <Grid container item spacing={1} xs={12}>
                                <Grid item xs={6}>
                                    <DialogUnit
                                        head={t('GENERAL.type').toUpperCase()}
                                        content={
                                            isLSBD || isExpense
                                                ? translateLSBDTransactionsTypes(
                                                      viewValues.type,
                                                      viewValues.source
                                                  )
                                                : translatePersonalTransactionsTypes(
                                                      viewValues.type
                                                  )
                                        }
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <DialogUnit
                                        head={t('GENERAL.value').toUpperCase()}
                                        content={
                                            <Box
                                                color={
                                                    viewValues.type ===
                                                    TransactionsTypes.CREDIT
                                                        ? '#27AE60'
                                                        : viewValues.type ===
                                                          TransactionsTypes.DEBIT
                                                        ? '#F3222F'
                                                        : null
                                                }
                                                fontWeight="700"
                                                height={'100%'}
                                            >
                                                {`${
                                                    viewValues.type ===
                                                    TransactionsTypes.CREDIT
                                                        ? '+'
                                                        : '-'
                                                } R$
                                    ${moneyMask(
                                        valueToMoneyFloat(
                                            Math.abs(viewValues.value)
                                        )
                                    )}
                                    `}
                                            </Box>
                                        }
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <div style={{ padding: '4px 0' }}></div>
                                </Grid>

                                <Grid item xs={6}>
                                    <DialogUnit
                                        head={t(
                                            'GENERAL.collaborator'
                                        ).toUpperCase()}
                                        content={
                                            viewValues.user
                                                ? viewValues.user.name
                                                : viewValues.operator.name
                                        }
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <DialogUnit
                                        head={t('GENERAL.date').toUpperCase()}
                                        content={dateToBR(
                                            FormatDateToFront(viewValues.date)
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Input
                                        required
                                        label={'GENERAL.reason'}
                                        placeholder={t(
                                            'QUOTAS.cancelTransactionPlaceholder'
                                        )}
                                        value={values.justification}
                                        valueName={'justification'}
                                        rows={6}
                                        maxLength={200}
                                        handleChange={handleChange}
                                        error={requiredJustification}
                                        helperText={
                                            requiredJustification &&
                                            t('GENERAL.requiredField')
                                        }
                                        setRequired={setRequiredJustification}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <DialogActions style={{ padding: '24px 30px' }}>
                            <Buttons
                                onClick={handleClose}
                                tipo={'BN-noback'}
                                conteudo={t('GENERAL.close').toUpperCase()}
                            />
                            <Buttons
                                onClick={handleCancel}
                                tipo="BN-blue"
                                conteudo={`${t('GENERAL.next').toUpperCase()}`}
                            />
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </>
    );
};

export default DialogCancelTransactions;
