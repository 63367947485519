/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import Transition from 'components/layout/transition/Transition';
import DefaultInfo from 'components/Infos/DefaultInfo';
import Typography from 'components/typography/Typography';
import InputAutoComplete from 'components/inputs/InputAutoComplete';
import Buttons from 'components/buttons/Pressed';
import DialogUnit from 'components/dialogs/DialogUnit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import DialogConfirmationUser from '../Dialogs/DialogConfirmationUser';
import { useStyles } from 'components/layout/CommonStyles';
import { useQueryUserPermissions } from 'hooks/DataFetching/Admin/Users/useQueryUserPermissions';
import { screenStyles } from '../Styles';
import {
    translateUserRegistrationStatus,
    GetPermissionsNames
} from '../ScreenUtils';
import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';
import { Allow, Screen, Modules } from 'global/constants';
import { Paper, Box, Grid } from '@material-ui/core';
import { GetInputArray } from 'utils/general/GetInputArray';

import Input from 'components/inputs/Input';
import DialogUnitWithIcon from 'components/dialogs/DialogUnitWithIcon';
import { useMutationEditUserPermissions } from 'hooks/DataFetching/Admin/Users/useMutationEditUserPermissions';

const FormPermissions = ({
    id,
    context,
    values,
    userValues,
    setValues,
    changeTab,
    data,
    handleSubmit,
    mutationIsLoading
}) => {
    const classes = useStyles();
    const styles = screenStyles();
    const [t] = useTranslation();

    const [requiredApprover, setRequiredApprover] = useState(false);
    const [requiredSurrogate, setRequiredSurrogate] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false);

    const [mustRedirect, setMustRedirect] = useState(false);

    const redirect = go => {
        if (go) return <Redirect to={`/Admin/Users`} />;
    };

    const userPermissionsQuery = useQueryUserPermissions(
        context === 'new' ? { enabled: false } : {},
        id
    );

    const mutation = useMutationEditUserPermissions(setMustRedirect);

    useEffect(() => {
        if (userPermissionsQuery.data && context !== 'new') {
            const data = userPermissionsQuery.data;
            setValues({
                libraryId: data.systemRoles
                    ? data.systemRoles.find(x => x.id === Modules.LIBRARY.id)
                          .role.id
                    : 0,
                patrimonyId: data.systemRoles
                    ? data.systemRoles.find(x => x.id === Modules.PATRIMONY.id)
                          .role.id
                    : 0,
                humanResourcesId: data.systemRoles
                    ? data.systemRoles.find(x => x.id === Modules.HR.id).role.id
                    : 0,
                humanResourcesOldId: data.systemRoles
                    ? data.systemRoles.find(
                          x => x.id === Modules.HR_OLD.id && x.isOldSystem
                      ).role.id
                    : 0,
                newsId: data.systemRoles
                    ? data.systemRoles.find(x => x.id === Modules.NEWS.id).role
                          .id
                    : 0,
                administrativeId: data.systemRoles
                    ? data.systemRoles.find(
                          x => x.id === Modules.ADMINISTRATIVE.id
                      ).role.id
                    : 0,
                quotasId: data.systemRoles
                    ? data.systemRoles.find(x => x.id === Modules.QUOTAS.id)
                          .role.id
                    : 0,
                surrogate: data.surrogate
                    ? { label: data.surrogate.name, value: data.surrogate.id }
                    : {},
                approver: data.approver
                    ? { label: data.approver.name, value: data.approver.id }
                    : {},
                userLoginEnabled: data.userLoginEnabled,
                userRegistrationStatus: data.userRegistrationStatus
            });
        }
    }, [userPermissionsQuery.data]);

    const verifySubmit = () => {
        let res = true;

        if (!values.approver) {
            res = false;
            setRequiredApprover(true);
        }

        if (!values.surrogate) {
            res = false;
            setRequiredSurrogate(true);
        }

        return res;
    };

    const submit = () => {
        if (context === 'new') {
            handleSubmit();
        } else {
            let formData = new FormData();

            formData.append('id', id);

            formData.append(
                'approverId',
                values.approver ? values.approver.value : null
            );

            formData.append(
                'surrogateId',
                values.surrogate ? values.surrogate.value : null
            );
            formData.append('systemRoles[0].systemId', Modules.LIBRARY.id);
            formData.append('systemRoles[0].roleId', values.libraryId);
            formData.append('systemRoles[0].isOldSystem', false);

            formData.append('systemRoles[1].systemId', Modules.PATRIMONY.id);
            formData.append('systemRoles[1].roleId', values.patrimonyId);
            formData.append('systemRoles[1].isOldSystem', false);

            formData.append('systemRoles[2].systemId', Modules.QUOTAS.id);
            formData.append('systemRoles[2].roleId', values.quotasId);
            formData.append('systemRoles[2].isOldSystem', false);

            formData.append('systemRoles[3].systemId', Modules.HR.id);
            formData.append('systemRoles[3].roleId', values.humanResourcesId);
            formData.append('systemRoles[3].isOldSystem', false);

            formData.append('systemRoles[4].systemId', Modules.HR_OLD.id);
            formData.append(
                'systemRoles[4].roleId',
                values.humanResourcesOldId
            );
            formData.append('systemRoles[4].isOldSystem', true);

            formData.append('systemRoles[5].systemId', Modules.NEWS.id);
            formData.append('systemRoles[5].roleId', values.newsId);
            formData.append('systemRoles[5].isOldSystem', false);

            formData.append(
                'systemRoles[6].systemId',
                Modules.ADMINISTRATIVE.id
            );
            formData.append('systemRoles[6].roleId', values.administrativeId);
            formData.append('systemRoles[6].isOldSystem', false);

            mutation.mutate(formData);
        }
    };

    const handleAdd = () => {
        const canSubmit = verifySubmit();
        if (canSubmit) {
            setOpenConfirmation(true);
        }
    };

    const handleChange = (name, value) => {
        setValues({ ...values, [name]: value });
    };

    const library = data.systemRoles
        ? data.systemRoles.find(x => x.id === Modules.LIBRARY.id)
        : {};
    const patrimony = data.systemRoles
        ? data.systemRoles.find(x => x.id === Modules.PATRIMONY.id)
        : {};
    const hr = data.systemRoles
        ? data.systemRoles.find(x => x.id === Modules.HR.id)
        : {};
    const hr_old = data.systemRoles
        ? data.systemRoles.find(
              x => x.id === Modules.HR_OLD.id && x.isOldSystem
          )
        : {};
    const administrative = data.systemRoles
        ? data.systemRoles.find(x => x.id === Modules.ADMINISTRATIVE.id)
        : {};
    const quotas = data.systemRoles
        ? data.systemRoles.find(x => x.id === Modules.QUOTAS.id)
        : {};
    const news = data.systemRoles
        ? data.systemRoles.find(x => x.id === Modules.NEWS.id)
        : {};
    const collaboratorsArray = data.users
        ? GetInputArray(data.users, 'id', 'name')
        : [];

    return (
        <React.Fragment>
            {redirect(mustRedirect)}
            <Transition
                loading={
                    context !== 'new' ? userPermissionsQuery.isLoading : false
                }
                newDesign={true}
            >
                <Paper square style={{ padding: '32px 16px' }}>
                    <Grid container xs={12} spacing={1}>
                        {context === 'view' ? (
                            <>
                                <Grid item xs={12}>
                                    <DefaultInfo
                                        textTranslation={
                                            !values.userLoginEnabled
                                                ? 'ADMIN.toEnableUser'
                                                : 'ADMIN.toUpdateUserPermissions'
                                        }
                                    />
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item xs={12}>
                                    <Typography
                                        variant={'RobotoBold'}
                                        fontSize={14}
                                        textColor={'primary'}
                                    >
                                        {t('HR.permissionsOfSystem')}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography
                                        variant={'RobotoRegular'}
                                        fontSize={13}
                                        textColor={'secondary'}
                                    >
                                        {context === 'edit'
                                            ? t(
                                                  'ADMIN.updatePermissionHelperText'
                                              )
                                            : t(
                                                  'ADMIN.createPermissionHelperText'
                                              )}
                                    </Typography>
                                </Grid>
                            </>
                        )}

                        {context !== 'new' ? (
                            <Grid item xs={4}>
                                <Box display={'flex'} marginTop={'32px'}>
                                    <DialogUnit
                                        head={t('GENERAL.situation')}
                                        content={translateUserRegistrationStatus(
                                            values.userRegistrationStatus
                                        )}
                                        tooltipContent={t(
                                            'ADMIN.adminTooltipSituation'
                                        )}
                                    />
                                    <DialogUnitWithIcon
                                        head={t('GENERAL.systemAccess')}
                                        content={
                                            values.userLoginEnabled
                                                ? t('GENERAL.enabled')
                                                : t('GENERAL.disabled')
                                        }
                                        marginTop={'0px'}
                                        Icon={
                                            values.userLoginEnabled ? (
                                                <CheckCircleIcon
                                                    style={{
                                                        color: '#7FE46B'
                                                    }}
                                                    fontSize="md"
                                                />
                                            ) : (
                                                <ErrorIcon
                                                    style={{
                                                        color: '#F3222F'
                                                    }}
                                                    fontSize="md"
                                                />
                                            )
                                        }
                                        tooltipContent={t(
                                            'ADMIN.adminTooltipSystemAccess'
                                        )}
                                    />
                                </Box>
                            </Grid>
                        ) : null}
                    </Grid>
                    <Grid container xs={12} spacing={1}>
                        {context !== 'new' ? (
                            <Grid item xs={12} style={{ marginTop: '24px' }}>
                                <Typography
                                    variant={'RobotoBold'}
                                    fontSize={14}
                                    textColor={'primary'}
                                >
                                    {t('GENERAL.permissionByModule')}
                                </Typography>
                            </Grid>
                        ) : null}
                        <>
                            <Grid item xs={3}>
                                <Input
                                    context={'view'}
                                    label={'GENERAL.module'}
                                    value={Modules.PATRIMONY.name}
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <Input
                                    context={context}
                                    label={'GENERAL.permissionLevel'}
                                    value={values.patrimonyId}
                                    valueName={'patrimonyId'}
                                    handleChange={handleChange}
                                    isSelect
                                    arraySelected={
                                        patrimony
                                            ? GetInputArray(
                                                  patrimony.roles,
                                                  'id',
                                                  'name'
                                              )
                                            : []
                                    }
                                />
                            </Grid>
                        </>
                        <Box width={'100%'} />

                        <>
                            <Grid item xs={3}>
                                <Input
                                    context={'view'}
                                    label={'GENERAL.module'}
                                    value={Modules.HR.name}
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <Input
                                    context={context}
                                    label={'GENERAL.permissionLevel'}
                                    value={values.humanResourcesId}
                                    valueName={'humanResourcesId'}
                                    handleChange={handleChange}
                                    isSelect
                                    arraySelected={
                                        hr
                                            ? GetInputArray(
                                                  hr.roles,
                                                  'id',
                                                  'name'
                                              )
                                            : []
                                    }
                                />
                            </Grid>
                        </>
                        <Box width={'100%'} />

                        <>
                            <Grid item xs={3}>
                                <Input
                                    context={'view'}
                                    label={'GENERAL.module'}
                                    value={Modules.HR_OLD.name}
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <Input
                                    context={context}
                                    label={'GENERAL.permissionLevel'}
                                    value={values.humanResourcesOldId}
                                    valueName={'humanResourcesOldId'}
                                    handleChange={handleChange}
                                    isSelect
                                    arraySelected={
                                        hr_old
                                            ? GetInputArray(
                                                  hr_old.roles,
                                                  'id',
                                                  'name'
                                              )
                                            : []
                                    }
                                />
                            </Grid>
                        </>
                        <Box width={'100%'} />

                        <>
                            <Grid item xs={3}>
                                <Input
                                    context={'view'}
                                    label={'GENERAL.module'}
                                    value={Modules.LIBRARY.name}
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <Input
                                    context={context}
                                    label={'GENERAL.permissionLevel'}
                                    value={values.libraryId}
                                    valueName={'libraryId'}
                                    handleChange={handleChange}
                                    isSelect
                                    arraySelected={
                                        library
                                            ? GetInputArray(
                                                  library.roles,
                                                  'id',
                                                  'name'
                                              )
                                            : []
                                    }
                                />
                            </Grid>
                        </>
                        <Box width={'100%'} />

                        <>
                            <Grid item xs={3}>
                                <Input
                                    context={'view'}
                                    label={'GENERAL.module'}
                                    value={Modules.QUOTAS.name}
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <Input
                                    context={context}
                                    label={'GENERAL.permissionLevel'}
                                    value={values.quotasId}
                                    valueName={'quotasId'}
                                    handleChange={handleChange}
                                    isSelect
                                    arraySelected={
                                        quotas
                                            ? GetInputArray(
                                                  quotas.roles,
                                                  'id',
                                                  'name'
                                              )
                                            : []
                                    }
                                />
                            </Grid>
                        </>
                        <Box width={'100%'} />
                        <>
                            <Grid item xs={3}>
                                <Input
                                    context={'view'}
                                    label={'GENERAL.module'}
                                    value={Modules.NEWS.name}
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <Input
                                    context={context}
                                    label={'GENERAL.permissionLevel'}
                                    value={values.newsId}
                                    valueName={'newsId'}
                                    handleChange={handleChange}
                                    isSelect
                                    arraySelected={
                                        news
                                            ? GetInputArray(
                                                  news.roles,
                                                  'id',
                                                  'name'
                                              )
                                            : []
                                    }
                                />
                            </Grid>
                        </>
                        <Box width={'100%'} />
                        <>
                            <Grid item xs={3}>
                                <Input
                                    context={'view'}
                                    label={'GENERAL.module'}
                                    value={Modules.ADMINISTRATIVE.name}
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <Input
                                    context={context}
                                    label={'GENERAL.permissionLevel'}
                                    value={values.administrativeId}
                                    valueName={'administrativeId'}
                                    handleChange={handleChange}
                                    isSelect
                                    arraySelected={
                                        administrative
                                            ? GetInputArray(
                                                  administrative.roles,
                                                  'id',
                                                  'name'
                                              )
                                            : []
                                    }
                                />
                            </Grid>
                        </>
                        <Box width={'100%'} />

                        <Grid item xs={6}>
                            <InputAutoComplete
                                required={context !== 'view'}
                                context={context}
                                label={'HR.approver'}
                                value={values.approver}
                                valueName={'approver'}
                                handleChange={handleChange}
                                arraySelected={collaboratorsArray}
                                noOptionsText={'GENERAL.noOptionsCollaborator'}
                                error={requiredApprover}
                                setError={setRequiredApprover}
                                helperText={'GENERAL.requiredField'}
                            />
                        </Grid>

                        <Box width={'100%'} />

                        <Grid item xs={6}>
                            <InputAutoComplete
                                required={context !== 'view'}
                                context={context}
                                label={'HR.surrogate'}
                                value={values.surrogate}
                                valueName={'surrogate'}
                                handleChange={handleChange}
                                arraySelected={collaboratorsArray}
                                noOptionsText={'GENERAL.noOptionsCollaborator'}
                                error={requiredSurrogate}
                                setError={setRequiredSurrogate}
                                helperText={'GENERAL.requiredField'}
                            />
                        </Grid>

                        <Box width={'100%'} />

                        <Grid item xs={6}>
                            <Buttons
                                onClick={() => {
                                    setMustRedirect(true);
                                }}
                                tipo={
                                    context === 'view' ? 'BN-blue' : 'BN-noback'
                                }
                                conteudo={
                                    context === 'view'
                                        ? t('GENERAL.comeBack').toUpperCase()
                                        : t('GENERAL.cancel').toUpperCase()
                                }
                            />
                            {context === 'view' ? null : (
                                <Buttons
                                    onClick={() => handleAdd()}
                                    tipo="BN-blue"
                                    conteudo={`${
                                        context === 'edit'
                                            ? t('GENERAL.save').toUpperCase()
                                            : t('GENERAL.add').toUpperCase()
                                    }`}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Paper>
                <DialogConfirmationUser
                    context={context}
                    open={openConfirmation}
                    setOpen={setOpenConfirmation}
                    viewValues={{
                        ...userValues,
                        approver: values.approver ? values.approver.label : '',
                        surrogate: values.surrogate
                            ? values.surrogate.label
                            : '',
                        permissions: [
                            ...GetPermissionsNames(values, data.systemRoles)
                        ]
                    }}
                    submit={submit}
                    mutationIsLoading={
                        context === 'new'
                            ? mutationIsLoading
                            : mutation.isLoading
                    }
                />
            </Transition>
        </React.Fragment>
    );
};

export default FormPermissions;
