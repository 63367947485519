import React from "react";
import "../../../../App.css";
import SideBar from "components/layout/sidebar/Sidebar";
import DashboardNavBar from "components/layout/DashboardNavBar";
import BooksExemplarsPage from "./BooksExemplarsPage";

const CallViewExemplar = ({ match }) => {
  return (
    <div>
      <DashboardNavBar />
      <SideBar />
      <BooksExemplarsPage id={match.params.id} context={"view"} type={"EXEMPLAR"}/>      
    </div>
  );
}

export default CallViewExemplar;