/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import Paper from "@material-ui/core/Paper";
import Dissatisfied from "@material-ui/icons/SentimentVeryDissatisfied";
import Footer from "components/layout/Footer";
import MySnackbarContentWrapper from "components/layout/Message";
import CollaboratorsContext from "context/hrModule/collaborator/collaboratorContext";
import Transition from "components/layout/transition/Transition";
import Breadcrumb from "components/layout/breadcrumb/Breadcrumb";
import BirthdaysTable from "./BirthdaysTable";
import InputFilter from "components/inputs/InputFilter";
import FilterContainer from "components/containers/FilterContainer";
import HeaderContainer from "components/containers/HeaderContainer";
import ContentContainer from "components/containers/ContentContainer";
import { FormatDateToFront } from "utils/dates/FormatDateToFront";

import { GetGeneralPermissionForAction } from "utils/permissions/GetGeneralPermissionForAction";
import { Screen, Allow, Months } from "global/constants";

import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";

import { screenStyles } from "./Styles"

const Birthdays = (props) => {
    const classes = useStyles();
    const styles = screenStyles();
    const [t] = useTranslation();
    const collaboratorsContext = useContext(CollaboratorsContext);
    const [findText, setFindText] = useState("");
    const [filterName, setFilterName] = useState("");
    const [filterMonth, setfilterMonth] = useState(-1);
    const [dataFilter, setDataFilter] = useState([]);
    const [canClick, setCanClick] = useState(false);

    const monthsFilterArray = [
        {
            value: -1,
            label: t("GENERAL.allMasculine")
        },
        ...Months
    ]

    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }

    const [snackBar, setSnackBar] = useState({
        open: false,
        kind: "",
        content: ""
    });

    const {
        loading,
        hasChanged,
        responseType,
        response,
        successiveActions,
        setHasChanged,
        setLoading,
        setLoadingFalse,
        getBirthdays,
        birthdays
    } = collaboratorsContext;

    const handleSnack = () => {
        setSnackBar({ ...snackBar, open: true });
        setHasChanged();
    };
    const handleSnackClose = () => {
        setSnackBar({ ...snackBar, open: false });
    };


    useEffect(() => {

        if (responseType === "SUCCESS" && hasChanged > successiveActions) {
            handleSnack({ kind: "success", content: response });
        }
        if (responseType === "ERROR" && hasChanged > successiveActions) {
            handleSnack({ kind: "error", content: response });
        }

        return () => { };
    }, [
        responseType,
        response,
        successiveActions,
    ]);

    async function fetchData() {
        await setLoading();
        await getBirthdays();
        await setLoadingFalse();
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (filterName ||
            filterMonth
        ) { setCanClick(true) }
        else {
            setCanClick(false)
        }
    }, [
        filterName,
        filterMonth
    ])

    useEffect(() => {
        if (birthdays && birthdays.length) {
            const { newDataFilter, textNoFound } = applyFilter(birthdays)

            if (newDataFilter.length === 0) {
                setDataFilter([]);
                setHasNoResult(true);
                setFindText(textNoFound);
            } else {
                setDataFilter(newDataFilter);
                setHasNoResult(false);
                setFindText("");
            }
        }
    }, [birthdays, filterName, filterMonth]);

    const applyFilter = (birthdays) => {
        let newDataFilter = [];
        let textNoFound = "";

        newDataFilter = birthdays;

        if (filterName) {
            newDataFilter = newDataFilter.filter(item =>
                item.name.toLowerCase().includes(filterName.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0) textNoFound = filterName;
        }
        if (typeof filterMonth === 'number' && filterMonth !== -1) {
            newDataFilter = newDataFilter.filter(item =>
                item.dateOfBirth && FormatDateToFront(item.dateOfBirth).getMonth() === filterMonth
            )

            if (!textNoFound && newDataFilter.length === 0) textNoFound = monthsFilterArray.find(x => x.value === filterMonth).label;
        }

        return { newDataFilter, textNoFound };
    }

    const hasItens = birthdays.length > 0;
    const [hasNoResult, setHasNoResult] = useState(hasItens && dataFilter.length === 0);

    return (
        <React.Fragment>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={snackBar.open}
                onClose={handleSnackClose}
                TransitionComponent={Slide}
                transitionDuration={{ enter: 500, exit: 500 }}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                autoHideDuration={2000}
            >
                <MySnackbarContentWrapper
                    onClose={handleSnackClose}
                    variant={
                        responseType !== ""
                            ? responseType.toLowerCase()
                            : null
                    }
                    message={
                        <span id="message-id">
                            {response !== "" ? response : null}
                        </span>
                    }
                />
            </Snackbar>

            <Transition loading={loading} newDesign={true}>
                <HeaderContainer>
                    <Breadcrumb
                        newDesign={true}
                        routes={[
                            { label: t("HR.humanResource") , to: "/HumanResources" },
                            { label: t("HR.birthdays") },
                        ]}
                    />
                    <div className="container-headerNewDesign">
                        <h1 className={classes.titleNewDesign}>{t("HR.birthdays")}</h1>
                        {GetGeneralPermissionForAction(Screen.BIRTHDAYS, Allow.READ) &&
                        <Link to={`/Birthdays/print/${filterMonth}`} target="_blank">
                            <Button
                                className={classes.blueButton}
                                variant="contained"
                                color="primary"
                                style={{ marginBottom: "10px" }}
                            >
                                {t("GENERAL.print")}
                            </Button>
                        </Link>
                        }
                    </div>

                </HeaderContainer>
                <ContentContainer>
                    <Paper >
                        <div className="cabecalho">
                            {t("HR.birthdays").toUpperCase()}
                        </div>
                        <React.Fragment>
                            <FilterContainer>
                                <InputFilter
                                    filterValue={filterName}
                                    setFilter={setFilterName}
                                    label={"GENERAL.name"}
                                    maxLength={60}
                                />

                                <InputFilter
                                    filterValue={filterMonth}
                                    setFilter={setfilterMonth}
                                    label={"GENERAL.month"}
                                    isSelect
                                    arraySelected={monthsFilterArray}
                                />
                            </FilterContainer>

                            <BirthdaysTable
                                rows={dataFilter}
                                toFirstPage={canClick}
                                headers={["dateOfBirth", "name"]}
                                visibleHeaders={["Data de Aniversário", "Nome do Colaborador"]}
                                sortedFields={["dateOfBirth", "name"]}
                                mainField="dateOfBirth"
                                fetchData={fetchData}
                            />
                            {!hasItens &&
                                <div className={classes.noParticipantsMessage}>
                                    <h2>{t("HR.noBirthdaysMessage")}</h2>
                                    <h4>{t("HR.noBirthdaysSubMessage")}</h4>
                                </div>
                            }
                            {hasNoResult &&
                                <div className={classes.noParticipantsMessage}>
                                    <div >
                                        <Dissatisfied className={classes.notFoundIconStyle} />
                                    </div>
                                    <h2>{t("GENERAL.noResultsFound")}</h2>
                                    <h5>{`Sua busca por "${findText}" não retornou resultados.`}</h5>
                                </div>
                            }
                        </React.Fragment>
                    </Paper>
                </ContentContainer>
                <Footer />
            </Transition>
        </React.Fragment >

    );
};

export default Birthdays;