import React from "react";
import {View} from "@react-pdf/renderer";
import styles from "./Row.styled";

const Row = ({children, style, ...props}) => {
    return (
        <View wrap={false} style={[styles.row, style]} {...props}>
            {children}
        </View>
    )
}

export default Row;
