import { getTimeStringBasedOnFormat } from './getTimeStringBasedOnFormat';
import { format } from 'date-fns';

/**
 * Converts a timespan to a string. Days are converted to hours.
 * @param {String} timespan The timespan to be converted to a string.
 * @param {String} format The format to be used. See {@link getTimeStringBasedOnFormat} for more information. Defaults to 'hh:mm:ss'.
 * @returns {String} The timespan as a string.
 */
export function getTimeStringFromTimeSpan(timespan, format = 'hh:mm:ss') {
    //"4.20:34:22.057" -> ["4.20", "34", "22.057"]
    const [daysDotHours, minutes, secondsDotMs] = timespan.split(':');

    let days = 0;
    let hours = 0;
    if (daysDotHours.includes('.')) {
        [days, hours] = daysDotHours.split('.');
        days = parseInt(days);
        hours = parseInt(hours);
        hours += days * 24;
    } else {
        hours = daysDotHours;
    }

    let seconds = 0;
    let milliseconds = 0;
    if (secondsDotMs.includes('.')) {
        [seconds, milliseconds] = secondsDotMs.split('.');
        seconds = parseInt(seconds);
        milliseconds = parseInt(milliseconds);
    } else {
        seconds = secondsDotMs;
    }

    return getTimeStringBasedOnFormat(
        hours,
        minutes,
        seconds,
        milliseconds,
        format
    );
}
