import React from "react";
import "../../../../App.css";
import SideBar from "components/layout/sidebar/Sidebar";
import DashboardNavBar from "components/layout/DashboardNavBar";
import JobView from "./ViewJob/JobView";

const CallViewJobs = ({ match }) => {

    return (
        <div>
            <DashboardNavBar />
            <SideBar />
            <JobView jobId={match.params.id} context={"view"} />
        </div>
    );
}

export default CallViewJobs;