import axios from 'axios';
import { useMutation } from 'react-query';
import Cookies from 'js-cookie';
import { useQueryPersonalBills } from '../Dashboard/useQueryPersonalBills';
import { useSnackbar } from 'hooks/UseSnackbar';
import { useTranslation } from 'react-i18next';
import { valueToMoneyFloat } from 'utils/general/valueToMoneyFloat';
import { moneyMask } from 'utils/masks/moneyMask';
import { sendEmails } from 'services/Emails/Quota/quotaEmailService';
import { GetMonthAndYearInWords } from 'utils/dates/GetMonthAnsYearInWords';

export const useMutationUserPayBill = (setOpen, id) => {
    const toast = useSnackbar();
    const [t] = useTranslation();

    const queryPersonalBills = useQueryPersonalBills({ enabled: false }, id);

    const mutation = useMutation({
        mutationFn: async obj =>
            await axios.put(
                process.env.REACT_APP_SERVER +
                    `api/Quota/Bills/${obj.get('id')}/Pay`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            ),
        onSuccess: res => {
            toast.success(t('QUOTAS.billPaySucess'));
            const data = res.data;

            const date = new Date(data.date);

            const emailData = {
                collaboratorName: data.user.shortname,
                collaboratorEmail: data.user.username,
                collaboratorJob: data.user.job,
                billId: '' + data.id,
                billValue: `R$ ${moneyMask(valueToMoneyFloat(data.value))}`,
                billMonth: GetMonthAndYearInWords(date),
                billVerificationCode: data.verificationCode,
                payedByManager: true,
                operatorName: data.operator.shortname,
                operationDate: data.closedAt,
                justification: data.justification
            };

            sendEmails(emailData, 'api/Quota/Bills/Paid');
        },
        onError: err => {
            if (err.response.data.message && err.response.data.code !== 1000) {
                toast.error(err.response.data.message);
            } else {
                toast.error(t('QUOTAS.billPayError'));
            }
        },
        onSettled: () => {
            setOpen(false);
            queryPersonalBills.refetch();
        }
    });

    return mutation;
};
