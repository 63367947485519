/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";

import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Dissatisfied from "@material-ui/icons/SentimentVeryDissatisfied";
import Footer from "components/layout/Footer";
import SettingsContext from "context/hrModule/settings/settingsContext";
import Transition from "components/layout/transition/Transition";
import Breadcrumb from "components/layout/breadcrumb/Breadcrumb";
import ActivityCategoriesTable from "./ActivityCategoriesTable";
import DialogActivityCategories from "./DialogActivityCategories";
import InputFilter from "components/inputs/InputFilter";
import FilterContainer from "components/containers/FilterContainer";
import HeaderContainer from "components/containers/HeaderContainer";
import ContentContainer from "components/containers/ContentContainer";

import { GetGeneralPermissionForAction } from "utils/permissions/GetGeneralPermissionForAction";
import { Screen, Allow } from "global/constants";

import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";

import { screenStyles } from "./Styles"

const ActivityCategories = (props) => {
    const classes = useStyles();
    const styles = screenStyles();
    const [t] = useTranslation();
    const settingsContext = useContext(SettingsContext);
    const [findText, setFindText] = useState("");
    const [filterName, setFilterName] = useState("");
    const [dataFilter, setDataFilter] = useState([]);
    const [canClick, setCanClick] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);


    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }

    const {
        loading,
        setLoading,
        setLoadingFalse,
        getAllActivityCategories,
        activityCategories
    } = settingsContext;

    async function fetchData() {
        await setLoading();
        await getAllActivityCategories();
        await setLoadingFalse();
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (filterName
        ) { setCanClick(true) }
        else {
            setCanClick(false)
        }
    }, [
        filterName,
    ])

    useEffect(() => {
        if (activityCategories && activityCategories.length) {
            const { newDataFilter, textNoFound } = applyFilter(activityCategories.filter(activity => !activity.deleted))

            if (newDataFilter.length === 0) {
                setDataFilter([]);
                setHasNoResult(true);
                setFindText(textNoFound);
            } else {
                setDataFilter(newDataFilter);
                setHasNoResult(false);
                setFindText("");
            }
        }
    }, [activityCategories, filterName]);

    const applyFilter = (filterArray) => {
        let newDataFilter = [];
        let textNoFound = "";

        newDataFilter = filterArray;

        if (filterName) {
            newDataFilter = newDataFilter.filter(item =>
                item.name.toLowerCase().includes(filterName.toLowerCase())
            );

            if (!textNoFound && newDataFilter.length === 0) textNoFound = filterName;
        }

        return { newDataFilter, textNoFound };
    }

    const hasItens = activityCategories.length > 0;
    const [hasNoResult, setHasNoResult] = useState(hasItens && dataFilter.length === 0);

    return (
        <React.Fragment>
            <Transition loading={loading} newDesign={true}>
                <HeaderContainer>
                    <Breadcrumb
                        newDesign={true}
                        routes={[
                            { label: t("HR.humanResource") , to: "/HumanResources"},
                            { label: t("ROUTES.config") },
                            { label: t("HR.activityCategories") },
                        ]}
                    />
                    <div className="container-headerNewDesign">
                        <h1 className={classes.titleNewDesign}>{t("HR.activityCategories")}</h1>
                        {GetGeneralPermissionForAction(Screen.ACTIVITY_CATEGORIES, Allow.CREATE) &&
                                <Button
                                    className={classes.blueButton}
                                    variant="contained"
                                    color="primary"
                                    style={{ marginBottom: "10px" }}
                                    onClick={() => setOpenDialog(true)}
                                >
                                    {t("GENERAL.addCategory")}
                                </Button>
                        }
                    </div>

                </HeaderContainer>
                <ContentContainer>
                    <Paper >
                        <div className="cabecalho">
                            {t("HR.activityCategoriesList").toUpperCase()}
                        </div>
                        <React.Fragment>
                            <FilterContainer>
                                <InputFilter
                                    filterValue={filterName}
                                    setFilter={setFilterName}
                                    label={"GENERAL.categoryName"}
                                    maxLength={70}
                                />

                            </FilterContainer>

                            <ActivityCategoriesTable
                                rows={dataFilter}
                                toFirstPage={canClick}
                                headers={["id", "name", "description" ,"activitiesQnt"]}
                                visibleHeaders={[t("GENERAL.categoryName"), t("GENERAL.description"), ""]}
                                sortedFields={["name", "description"]}
                                mainField="name"
                                fetchData={fetchData}
                                activityCategories={activityCategories}
                            />
                            {!hasItens &&
                                <div className={classes.noParticipantsMessage}>
                                    <h2>{t("HR.noActivityCategoriesMessage")}</h2>
                                    <h4>{t("HR.noActivityCategoriesSubMessage")}</h4>
                                </div>
                            }
                            {hasNoResult &&
                                <div className={classes.noParticipantsMessage}>
                                    <div >
                                        <Dissatisfied className={classes.notFoundIconStyle} />
                                    </div>
                                    <h2>{t("GENERAL.noResultsFound")}</h2>
                                    <h5>{`Sua busca por "${findText}" não retornou resultados.`}</h5>
                                </div>
                            }
                        </React.Fragment>
                    </Paper>
                    {openDialog &&
                        <DialogActivityCategories
                            open={openDialog}
                            setOpen={setOpenDialog}
                            activityCategories={activityCategories}
                            context={"new"}
                            fetchData={fetchData}
                        />
                    }
                </ContentContainer>
                <Footer />
            </Transition>
        </React.Fragment >

    );
};

export default ActivityCategories;