/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Breadcrumb from 'components/layout/breadcrumb/Breadcrumb';
import Transition from 'components/layout/transition/Transition';
import FilterContainer from 'components/containers/FilterContainer';
import HeaderContainer from 'components/containers/HeaderContainer';
import ContentContainer from 'components/containers/ContentContainer';
import InputFilter from 'components/inputs/InputFilter';
import InputFilterDate from 'components/inputs/InputFilterDate';
import Button from '@material-ui/core/Button';
import MyJustificationsTable from './MyJustificationsTable';
import ClockRecordContext from 'context/hrModule/clockRecord/clockRecordContext';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import GetApp from '@material-ui/icons/GetApp';
import Dissatisfied from '@material-ui/icons/SentimentVeryDissatisfied';
import Footer from 'components/layout/Footer';
import { useStyles } from 'components/layout/CommonStyles';
import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';
import { isSameDate } from 'utils/dates/IsSameDate';
import {
    JustificationsStatusArray,
    GetJustificationStatus,
    JustificationsTypeArray,
    GetJustificationType
} from './ScreenUtils';
import { Screen, Allow } from 'global/constants';
import { FormatDateToFront } from 'utils/dates/FormatDateToFront';
import { dateToBR } from 'utils/dates/DateToBRr';

import { screenStyles } from './Styles';

const MyJustifications = () => {
    const classes = useStyles();
    const styles = screenStyles();
    const [t] = useTranslation();
    const [loading, setLoading] = useState(true);

    const clockRecordContext = useContext(ClockRecordContext);

    const [findText, setFindText] = useState('');
    const [filterJustificationDate, setFilterJustificationDate] =
        useState(null);
    const [filterJustificationType, setFilterJustificationType] =
        useState('ALL');
    const [filterJustifiedDate, setFilterJustifiedDate] = useState(null);
    const [filterStatus, setFilterStatus] = useState('ALL');
    const [dataFilter, setDataFilter] = useState([]);

    const [canClick, setCanClick] = useState(false);

    const { myJustifications, getMyJustifications } = clockRecordContext;

    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const hasItens = myJustifications && myJustifications.length > 0;
    const [hasNoResult, setHasNoResult] = useState(
        hasItens && dataFilter && dataFilter.length === 0
    );

    const fetchData = () => {
        getMyJustifications(auth.id, setLoading);
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (myJustifications) {
            setDataFilter(myJustifications);
            setHasNoResult(false);
            setLoading(false);
        }
    }, [myJustifications]);

    useEffect(() => {
        if (myJustifications && myJustifications.length > 0) {
            const { newDataFilter, textNoFound } =
                applyFilter(myJustifications);

            if (newDataFilter.length === 0) {
                setDataFilter([]);
                setHasNoResult(true);
                setFindText(textNoFound);
            } else {
                setDataFilter(newDataFilter);
                setHasNoResult(false);
                setFindText('');
            }

            setDataFilter(newDataFilter);
        }
    }, [
        filterJustificationDate,
        filterJustificationType,
        filterStatus,
        filterJustifiedDate
    ]);

    const applyFilter = data => {
        let newDataFilter = [];
        let textNoFound = '';

        newDataFilter = data;

        if (filterStatus && filterStatus !== 'ALL') {
            newDataFilter = newDataFilter.filter(
                item => item.status && item.status === filterStatus
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = GetJustificationStatus(filterStatus);
        }

        if (filterJustificationDate && !isNaN(filterJustificationDate)) {
            newDataFilter = newDataFilter.filter(
                item =>
                    item.dateCreated &&
                    isSameDate(
                        FormatDateToFront(item.dateCreated),
                        filterJustificationDate
                    )
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = dateToBR(filterJustificationDate);
        }

        if (filterJustifiedDate && !isNaN(filterJustifiedDate)) {
            newDataFilter = newDataFilter.filter(
                item =>
                    (item.requestedDate &&
                        isSameDate(
                            FormatDateToFront(item.requestedDate),
                            filterJustifiedDate
                        )) ||
                    (item.endDate &&
                        FormatDateToFront(item.requestedDate).getTime() <=
                            filterJustifiedDate.getTime() &&
                        FormatDateToFront(item.endDate).getTime() >=
                            filterJustifiedDate.getTime())
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = dateToBR(filterJustifiedDate);
        }

        if (filterJustificationType && filterJustificationType !== 'ALL') {
            newDataFilter = newDataFilter.filter(
                item => item.class && item.class === filterJustificationType
            );

            if (!textNoFound && newDataFilter.length === 0)
                textNoFound = GetJustificationType(filterJustificationType);
        }

        return { newDataFilter, textNoFound };
    };

    useEffect(() => {
        if (
            filterJustificationDate ||
            filterJustificationType ||
            filterStatus ||
            filterJustifiedDate
        ) {
            setCanClick(true);
        } else {
            setCanClick(false);
        }
    }, [
        filterJustificationDate,
        filterJustificationType,
        filterStatus,
        filterJustifiedDate
    ]);

    return (
        <React.Fragment>
            <Transition loading={loading} newDesign={true}>
                <HeaderContainer>
                    <Breadcrumb
                        newDesign={true}
                        routes={[
                            {
                                label: t('HR.humanResource'),
                                to: '/HumanResources'
                            },
                            { label: t('HR.clock') },
                            { label: t('HR.myJustifications') }
                        ]}
                    />
                    <div className="container-headerNewDesign">
                        <h1 className={classes.titleNewDesign}>
                            {t('HR.myJustifications')}
                        </h1>
                        {GetGeneralPermissionForAction(
                            Screen.MY_JUSTIFICATIONS,
                            Allow.CREATE
                        ) && (
                            <Link to={'/MyJustifications/new'}>
                                <Button
                                    className={classes.blueButton}
                                    variant="contained"
                                    color="primary"
                                    style={{ marginBottom: '10px' }}
                                >
                                    {t('HR.addJustification')}
                                </Button>
                            </Link>
                        )}
                    </div>
                </HeaderContainer>
                <ContentContainer>
                    <Paper>
                        <div className="cabecalho">
                            {t('HR.historyOfJustifications').toUpperCase()}
                        </div>
                        <FilterContainer>
                            <InputFilterDate
                                filterValue={filterJustificationDate}
                                setFilter={setFilterJustificationDate}
                                label={'GENERAL.solicitationDate'}
                            />
                            <InputFilter
                                filterValue={filterJustificationType}
                                setFilter={setFilterJustificationType}
                                label={'HR.justificationType'}
                                isSelect
                                arraySelected={JustificationsTypeArray}
                            />
                            <InputFilterDate
                                filterValue={filterJustifiedDate}
                                setFilter={setFilterJustifiedDate}
                                label={'HR.justifiedDate'}
                            />
                            <InputFilter
                                filterValue={filterStatus}
                                setFilter={setFilterStatus}
                                label={'GENERAL.status'}
                                isSelect
                                arraySelected={JustificationsStatusArray}
                            />
                        </FilterContainer>
                        <React.Fragment>
                            <MyJustificationsTable
                                rows={dataFilter}
                                toFirstPage={canClick}
                                headers={[
                                    'id',
                                    'dateCreated',
                                    'class',
                                    'requestedDate',
                                    'status',
                                    'endDate'
                                ]}
                                visibleHeaders={[
                                    'Data da Solicitação',
                                    'Tipo de Justificativa',
                                    'Data Justificada',
                                    'Status',
                                    '',
                                    ''
                                ]}
                                sortedFields={[
                                    'dateCreated',
                                    'class',
                                    'requestedDate',
                                    'status'
                                ]}
                                mainField="dateCreated"
                                fetchData={fetchData}
                            />

                            {!hasItens && (
                                <div className={styles.noJustificationsWrapper}>
                                    <h2>{t('HR.noJustificationsMessage')}</h2>
                                    <h4>
                                        {t('HR.noJustificationsSubMessage')}
                                    </h4>
                                </div>
                            )}
                            {hasNoResult && (
                                <div className={classes.noParticipantsMessage}>
                                    <div>
                                        <Dissatisfied
                                            className={
                                                classes.notFoundIconStyle
                                            }
                                        />
                                    </div>
                                    <h2>{t('GENERAL.noResultsFound')}</h2>
                                    <h5>{`Sua busca por "${findText}" não retornou resultados.`}</h5>
                                </div>
                            )}
                        </React.Fragment>
                    </Paper>
                </ContentContainer>
                <Footer />
            </Transition>
        </React.Fragment>
    );
};

export default MyJustifications;
