import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Check from "@material-ui/icons/Check";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useStyles } from "components/layout/CommonStyles";
import Footer from "components/layout/Footer";
import SideBar from "components/layout/sidebar/Sidebar";
import Spinner from "components/layout/spinners/Index";
import "components/profiles/css/FormStyle.css";
import ProjectsContext from "context/projects/projectsContext";
import "moment/locale/pt-br";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import DashboardNavBar from "../../../layout/DashboardNavBar";
import ActivityInformation from "./ActivityInformationComponent";
import ParticipantsTable from "./ParticipantsTable";

const AddParticipants = ({ match }) => {
    const [t] = useTranslation();
    const classes = useStyles();
    const projectsContext = useContext(ProjectsContext);
    const { loading, getActivity, activity, participants, getParticipants, setValuePanelTab } = projectsContext;
    const [projectId, setProjectId] = useState(0);
    const [phaseId, setPhaseId] = useState(0);
    let part = [];
    const [state, setState] = useState({
        projectName: "",
        gpf: "",
        projectStartDate: "",
        projectEndDate: "",
        phaseName: "",
        phaseDescription: "",
        phaseStart: "",
        phaseEnd: "",
        activityName: "",
        activityDescription: "",
        activityStart: "",
        activityEnd: ""
    });

    useEffect(() => {
        setValuePanelTab(1);
        getActivity(match.params.id);
    }, []);

    useEffect(() => {
        if (activity.phase && activity.phase.project) {
            setProjectId(activity.phase.project.id);
            setPhaseId(activity.phase.id);
            getParticipants(activity.phase.project.id);

            setState({
                ...state,
                projectName: activity.phase.project.information.name,
                gpf: activity.phase.project.information.gpf,
                projectStartDate: activity.phase.project.information.startdate,
                projectEndDate: activity.phase.project.information.enddate,
                phaseName: activity.phase.name,
                phaseDescription: activity.phase.description,
                phaseStart: activity.phase.startdate,
                phaseEnd: activity.phase.enddate,
                activityName: activity.name,
                activityDescription: activity.description,
                activityStart: activity.startDate,
                activityEnd: activity.endDate
            });
        }
    }, [activity]);

    if (participants.length > 0) {
        part = participants;

        if (activity.projectActivityParticipant && activity.projectActivityParticipant.length > 0) {
            for (var i in activity.projectActivityParticipant) {
                let obj = participants.filter(el => el.id === activity.projectActivityParticipant[i].participantId)[0];

                if (obj)
                    part = part.filter(el => el.id !== activity.projectActivityParticipant[i].participantId);
            }
        }
    }

    const transitions = useTransition(loading, null, {
        from: { opacity: 0 },
        enter: {
            opacity: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "calc(100vh - 70px)"
        },
        leave: { opacity: 0 }
    });

    const handleSubmit = () => {
        document.getElementById("addParticipantsActivity").click();
    };

    return transitions.map(({ item, key, props }) =>
        item ? (
            <animated.div key={key} style={props}>
                <Spinner />
            </animated.div>
        ) : (
                <>
                    <DashboardNavBar />
                    <SideBar />
                    <animated.div key={key} style={props}>
                        <div className="container">
                            <Breadcrumbs
                                className={classes.breadCrumbs}
                                aria-label="breadcrumb"
                                separator={<NavigateNextIcon fontSize="small" />}
                            >
                                <Typography>{t("PARTICIPANTS.labor")}</Typography>
                                <Link className={classes.link} to="/execution/projects/">
                                    {t("PROJECTS.projects")}
                                </Link>
                                <Link
                                    className={classes.link}
                                    to={`/execution/projects/${projectId}/edit`}
                                >
                                    {t("PROJECTS.project")}
                                </Link>
                                <Link
                                    className={classes.link}
                                    to={`/execution/project/edit/projectPhase/${phaseId}/edit`}
                                >
                                    {t("PHASES.editPhase")}
                                </Link>
                                <Link
                                    className={classes.link}
                                    to={`/execution/project/edit/projectPhase/edit/phaseActivity/${match.params.id}/edit`}
                                >
                                    {t("ACTIVITIES.editActivity")}
                                </Link>
                                <Typography>{t("PARTICIPANTS.add")}</Typography>
                            </Breadcrumbs>
                            <div className="container-header">
                                <div className="title">
                                    <h1 style={{ marginBottom: "12px" }} className={classes.title}>{t("ACTIVITIES.allocateParticipants")}</h1>
                                    <Typography>
                                        <span className={classes.projectInformation}>GPF: </span>
                                        <span className={classes.projectInformation2}>{`${state.gpf}`}</span>
                                        <span className={classes.projectInformation}> | PROJETO: </span>
                                        <span className={classes.projectInformation2}>{`${state.projectName}`}</span>
                                        <span className={classes.projectInformation}> | ETAPA: </span>
                                        <span className={classes.projectInformation2}>{`${state.phaseName}`}</span>
                                    </Typography>

                                </div>
                                <Button
                                    className={classes.blueButton}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmit}
                                >
                                    <div className={classes.buttonIcon}>
                                        <Check />
                                    </div>
                                    {t("GENERAL.toAllocate").toUpperCase()}
                                </Button>
                            </div>
                            <ActivityInformation
                                activityName={activity.name}
                                activityDescription={activity.description}
                                activityStart={activity.startDate}
                                activityEnd={activity.endDate}
                            />
                            <ParticipantsTable participants={part} activityId={match.params.id} />
                        </div>
                        <Footer />
                    </animated.div>
                </>
            )
    );
};

export default AddParticipants;