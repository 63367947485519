import { Paper } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Delete from "@material-ui/icons/Delete";
import { useStyles } from "components/layout/CommonStyles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ViewScopeStage = props => {
  const classes = useStyles();
  const [t] = useTranslation();
  const { disabled, lastStage, deleteStage, stageDescription } = props;
  const [description, setDescription] = useState(stageDescription);

  useEffect(() => {
    setDescription(stageDescription);
  }, [stageDescription]);

  const handleChange = event => {
    setDescription(event.target.value);
  };

  const handleClick = () => {
    deleteStage(lastStage);
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.bodyForm}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              multiline
              fullWidth
              disabled={true}
              variant="outlined"
              rows="6"
              label={t("SCOPE.stage") + " " + lastStage}
              margin="normal"
              value={description}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};

export default ViewScopeStage;