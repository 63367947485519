import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Visibility from "@material-ui/icons/Visibility";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import CloseIcon from '@material-ui/icons/Close';
import Input from "components/inputs/Input";
import Buttons from "components/buttons/Pressed";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import InputAdornment from '@material-ui/core/InputAdornment';
import Download from "@material-ui/icons/GetApp";
import Cookies from "js-cookie";

const HistoryDocumentTableAction = props => {
    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }
    const fileDownload = require("js-file-download");

    const classes = useStyles();
    const [t] = useTranslation();
    const { history, setOpenQualificationViewHistory } = props;
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const onClickDownload = async (slashPath) => {
        // if (dataState && dataState.files.length > 0) {
        if (slashPath) {
            const noSlashPath = slashPath.replace(/\//g, ";");
            const url = process.env.REACT_APP_SERVER + "api/file/;portal_lsbd;hr_files" + noSlashPath;
            axios.get(url, {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
                },
                responseType: "blob"
            }).then(function (res) {
                const blob = new Blob([res.data], {});
                // const name = file.fileName ? file.fileName : file.name;
                const name = slashPath.split("/").pop()
                fileDownload(blob, name);
            });
        }
    };
    return (
        <React.Fragment>
            <Tooltip title={t("GENERAL.view")} aria-label="details" placement="top">
                <IconButton className={classes.iconsTableActions} onClick={() => setOpen(true)} >
                    <Visibility />
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle className={classes.Title}>
                    <b>{t("GENERAL.viewHistory")}</b>
                    <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid container item spacing={2} md={12} lg={12}>
                        <Grid item xs={12} sm={6}>
                            <Input
                                context={"view"}
                                label={"GENERAL.HistoryOperation"}
                                value={history.operation || ""}
                                valueName={"description"}
                                maxLength={200}
                            // handleChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Input
                                context={"view"}
                                label={"GENERAL.date"}
                                value={history.dateDeletion || ""}
                                valueName={"description"}
                                maxLength={200}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Input
                                context={"view"}
                                label={"INVENTORY.executor"}
                                value={history.executorName || ""}
                                valueName={"description"}
                                maxLength={200}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Input
                                context={"view"}
                                label={"GENERAL.justification"}
                                value={history.justification || ""}
                                valueName={"description"}
                                rows={4}
                                maxLength={200}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                variant="outlined"
                                label={t("HR.fileName")}
                                autoComplete="off"
                                className={classes.textField}
                                value={history.documentName}
                                margin="normal"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput
                                }}
                                inputProps={{ "aria-label": "search" }}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => onClickDownload(history.path)}>
                                                <Download />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Input
                                context={"view"}
                                label={"HR.description"}
                                value={history.description || "-"}
                                valueName={"description"}
                                rows={4}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Buttons
                        onClick={handleClose}
                        tipo="BN-blue"
                        conteudo={t("GENERAL.close").toUpperCase()}
                    />
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
};

export default HistoryDocumentTableAction;