import React from "react";
import Button from '@material-ui/core/Button';
import Typography from "components/typography/Typography";

import { buttonsStyles } from "./Styles";

const ActiveInactiveButton = ({isActive , text , ...props}) => {
    const styles = buttonsStyles();
    return(
        <Button variant={isActive ? 'contained' : "outlined" } color='primary' className={styles.activeInactiveButton} {...props} >
            <Typography variant={'RobotoBold'} fontSize={11} textColor={isActive ? 'white' : 'primary'} >{text}</Typography>
        </Button>
    )
}

export default ActiveInactiveButton