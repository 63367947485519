import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import Grid from '@material-ui/core/Grid';
import Input from 'components/inputs/Input';
import Box from '@material-ui/core/Box';
import Typography from 'components/typography/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Transition from 'components/layout/transition/Transition';
import Buttons from 'components/buttons/Pressed';
import InputImage from 'components/inputs/InputImage';
import InputMultiDropzone from 'components/inputs/InpultMultiDropzone';
import { screenStyles } from '../Styles';
import { GetQuoteApi } from 'utils/externalApi/GetQuoteApi';
import UsersContext from 'context/users/usersContext';
import { GetInputArray } from 'utils/general/GetInputArray';
import useWindowDimensions from 'hooks/useWindowDimensions';
import {
    LibraryExemplarStatusValues,
    LibraryPlaceValues,
    ExemplarAcquisitionMethods
} from 'global/constants';
import { Link as LinkRouter } from 'react-router-dom';
import { dateValidation } from 'utils/validation/DateValidation';
import { moneyMask } from 'utils/masks/moneyMask';
import { moneyMaskToFloat } from 'utils/general/moneyMaskToFloat';
import InputAdornment from '@material-ui/core/InputAdornment';
import { valueToMoneyFloat } from 'utils/general/valueToMoneyFloat';
import { SubmitTypes, calcTotalPurchase, costPerUnit } from './ScreenUtils';
import { isNumber } from 'highcharts';
import Zoom from '@material-ui/core/Zoom';
import {
    createMuiTheme,
    MuiThemeProvider,
    withStyles
} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

const HtmlTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: '#757575',
        color: 'rgba(255, 255, 255)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)'
    }
}))(Tooltip);

const ExemplarForm = props => {
    const classes = useStyles();
    const { width } = useWindowDimensions();
    const usersContext = useContext(UsersContext);
    const {
        getUsersByPermission,
        usersByPermissions,
        getLibraryManagersUsers,
        getLibraryCoordinationUser,
        libraryManagersUsers,
        libraryCoordinationUsers
    } = usersContext;
    const styles = screenStyles();
    const [t] = useTranslation();
    const {
        image,
        imageFile,
        imagePath,
        setImageFile,
        isImageUrl,
        values,
        setValues,
        files,
        setFiles,
        context,
        bookAlreadyRegistered,
        submit,
        projects,
        places,
        ...others
    } = props;

    const [loadingQuotation, setLoadingQuotation] = useState(false);
    const [hasChange, setHasChange] = useState(false);

    const projectsArray = GetInputArray(projects, 'idInformation', 'name');
    const placeArray = GetInputArray(places, 'id', 'name');

    const [amountRequired, setAmountRequired] = useState(false);
    const [acquisitionDateRequired, setAcquisitionDateRequired] =
        useState(false);
    const [purchaseCurrencyRequired, setPurchaseCurrencyReuired] =
        useState(false);
    const [invalidAcquisitionDate, setInvalidAcquisitionDate] = useState(false);
    const [currencyQuoteRequired, setCurrencyQuoteRequired] = useState(false);
    const [purchasePriceRequired, setPurchasePriceRequired] = useState(false);
    const [projectIdRequired, setProjectIdRequired] = useState(false);
    const [acquisitionDescriptionRequired, setAcquisitionDescriptionRequired] =
        useState(false);
    const [libraryRequired, setLibraryRequired] = useState(false);
    const [statusRequired, setStatusRequired] = useState(false);
    const [placeIdRequired, setPlaceIdRequired] = useState(false);
    const [totalPurchaseByUser, setTotalPurchaseByUser] = useState(false);
    const [libraryManagers, setLibraryManager] = useState([]);
    const [libraryCoordinators, setLibraryCoordinator] = useState([]);

    const canSubmit = () => {
        var error = false;

        if (values.amount.length === 0 || values.amount === '0') {
            error = true;
            setAmountRequired(true);
        }

        if (!values.acquisitionDate) {
            error = true;
            setAcquisitionDateRequired(true);
        }

        if (!values.coin) {
            error = true;
            setPurchaseCurrencyReuired(true);
        }

        if (!dateValidation(values.acquisitionDate)) {
            error = true;
            setInvalidAcquisitionDate(true);
        }

        if (
            values.currencyQuote.length === 0 ||
            parseFloat(values.currencyQuote) === 0
        ) {
            error = true;
            setCurrencyQuoteRequired(true);
        }

        if (values.purchasePrice === '0,00') {
            error = true;
            setPurchasePriceRequired(true);
        }

        if (
            !values.projectId &&
            values.acquisitionMethod === ExemplarAcquisitionMethods.PROJECT
        ) {
            error = true;
            setProjectIdRequired(true);
        }
        if (
            !values.acquisitionDescription.length &&
            values.acquisitionMethod !== ExemplarAcquisitionMethods.PROJECT
        ) {
            error = true;
            setAcquisitionDescriptionRequired(true);
        }

        if (!values.library) {
            error = true;
            setLibraryRequired(true);
        }

        if (!values.status) {
            error = true;
            setStatusRequired(true);
        }

        if (!values.placeId) {
            error = true;
            setPlaceIdRequired(true);
        }

        return !error;
    };

    const handleSubmit = () => {
        if (canSubmit()) {
            if (context === 'new' && !bookAlreadyRegistered) {
                submit(SubmitTypes.CREATE_BOOK_AND_EXEMPLAR);
            }
            if (context === 'new' && bookAlreadyRegistered) {
                submit(SubmitTypes.CREATE_EXEMPLAR);
            }
            if (context === 'edit') {
                submit(SubmitTypes.UPDATE_EXEMPLAR);
            }
        }
    };

    const handleChange = (name, value) => {
        setHasChange(true);
        if (name === 'library') {
            switch (value) {
                case LibraryPlaceValues.GENERAL:
                    values.responsibleId =
                        libraryManagers && libraryManagers.length > 0
                            ? libraryManagers[0].value
                            : '';
                    setValues({
                        ...values,
                        [name]: value
                    });
                    break;
                case LibraryPlaceValues.COORDINATION:
                    values.responsibleId =
                        libraryCoordinators && libraryCoordinators.length > 0
                            ? libraryCoordinators[0].value
                            : '';
                    setValues({
                        ...values,
                        [name]: value
                    });
                    break;
                case LibraryPlaceValues.EXTERNAL:
                    values.responsibleId = '';
                    setValues({
                        ...values,
                        [name]: value
                    });
                    break;

                default:
                    break;
            }
        }
        if (name === 'responsibleId') {
            setValues({ ...values, responsible: null, [name]: value });
        } else if (name === 'responsible') {
            setValues({ ...values, responsibleId: null, [name]: value });
        } else if (name === 'totalPurchase') {
            if (totalPurchaseByUser)
                setValues({ ...values, [name]: moneyMask(value) });
        } else if (name === 'amount') {
            setValues({
                ...values,
                ['amount']: value,
                ['totalPurchase']: calcTotalPurchase({
                    ['purchasePrice']: values.purchasePrice,
                    ['freight']: values.freight,
                    ['currencyQuote']: values.currencyQuote,
                    ['amount']: value
                })
            });
        } else {
            setValues({ ...values, [name]: value });
        }
    };

    const libraryArray = [
        {
            value: LibraryPlaceValues.GENERAL,
            label: 'Geral'
        },
        {
            value: LibraryPlaceValues.COORDINATION,
            label: 'Coordenação'
        },
        {
            value: LibraryPlaceValues.EXTERNAL,
            label: 'Externo'
        }
    ];

    const statusExemplarArrayShort = [
        {
            value: LibraryExemplarStatusValues.DISPONIBLE,
            label: 'Disponível'
        },
        {
            value: LibraryExemplarStatusValues.DONATED,
            label: 'Doado'
        },
        {
            value: LibraryExemplarStatusValues.INDISPONIBLE,
            label: 'Indisponível'
        }
    ];

    const statusExemplarArray = [
        {
            value: LibraryExemplarStatusValues.DISPONIBLE,
            label: 'Disponível'
        },
        {
            value: LibraryExemplarStatusValues.DONATED,
            label: 'Doado'
        },
        {
            value: LibraryExemplarStatusValues.INDISPONIBLE,
            label: 'Indisponível'
        },
        {
            value: LibraryExemplarStatusValues.LOANED,
            label: 'Emprestado'
        },
        {
            value: LibraryExemplarStatusValues.IN_LOAN_PROCESS,
            label: 'Em processo de empréstimo'
        }
    ];

    const handleArrayStatus = status => {
        if (
            status === LibraryExemplarStatusValues.IN_LOAN_PROCESS ||
            status === LibraryExemplarStatusValues.LOANED
        ) {
            return statusExemplarArray;
        }
        return statusExemplarArrayShort;
    };

    const coins = [
        {
            value: 'BRL',
            label: 'BRL (Real Brasileiro)'
        },
        {
            value: 'USD',
            label: 'USD (Dólar Comercial)'
        },
        {
            value: 'CAD',
            label: 'CAD (Dólar Canadense)'
        },
        {
            value: 'EUR',
            label: 'EUR (Euro)'
        },
        {
            value: 'GBP',
            label: 'GBP (Libra Esterlina)'
        }
    ];

    const handleChangeMoney = (name, value) => {
        if (name == 'purchasePrice') {
            setTotalPurchaseByUser(false);
            setHasChange(true);
            setValues({
                ...values,
                ['purchasePrice']: moneyMask(value),
                ['totalPurchase']: calcTotalPurchase({
                    ['purchasePrice']: moneyMask(value),
                    ['freight']: values.freight,
                    ['currencyQuote']: values.currencyQuote,
                    ['amount']: values.amount
                })
            });
        } else if (name == 'freight') {
            setTotalPurchaseByUser(false);
            setHasChange(true);
            setValues({
                ...values,
                ['freight']: moneyMask(value),
                ['totalPurchase']: calcTotalPurchase({
                    ['purchasePrice']: values.purchasePrice,
                    ['freight']: moneyMask(value),
                    ['currencyQuote']: values.currencyQuote,
                    ['amount']: values.amount
                })
            });
        } else if (name == 'currencyQuote') {
            setTotalPurchaseByUser(false);
            setHasChange(true);
            setValues({
                ...values,
                ['currencyQuote']: moneyMask(value),
                ['totalPurchase']: calcTotalPurchase({
                    ['purchasePrice']: values.purchasePrice,
                    ['freight']: values.freight,
                    ['currencyQuote']: moneyMask(value),
                    ['amount']: values.amount
                })
            });
        } else if (name != 'iof' || name != 'totalPurchase') {
            setTotalPurchaseByUser(false);
            setHasChange(true);
            setValues({
                ...values,
                [name]: moneyMask(value)
            });
        }
    };

    const handleChangeCoin = (name, value) => {
        fetchQuote(value, values.acquisitionDate);
    };

    const handleChangeDate = date => {
        fetchQuote(values.coin, date);
    };

    const dateIsValid = date => {
        return date instanceof Date && !isNaN(date);
    };

    const fetchQuote = async (coin, date) => {
        var aux = 0;
        if (coin !== 'BRL' && dateIsValid(date)) {
            setLoadingQuotation(true);
            aux = await GetQuoteApi(coin, date);
            if (aux) {
                setHasChange(true);
                setValues({
                    ...values,
                    ['acquisitionDate']: date,
                    ['coin']: coin,
                    ['currencyQuote']: moneyMask(valueToMoneyFloat(aux)),
                    ['totalPurchase']: calcTotalPurchase({
                        ['purchasePrice']: values.purchasePrice,
                        ['freight']: values.freight,
                        ['currencyQuote']: moneyMask(valueToMoneyFloat(aux)),
                        ['amount']: values.amount
                    })
                });
            }

            setLoadingQuotation(false);
        } else if (coin === 'BRL' && dateIsValid(date)) {
            setHasChange(true);
            setValues({
                ...values,
                ['acquisitionDate']: date,
                ['coin']: coin,
                ['currencyQuote']: moneyMask(valueToMoneyFloat('1,00')),
                ['totalPurchase']: calcTotalPurchase({
                    ['purchasePrice']: values.purchasePrice,
                    ['freight']: values.freight,
                    ['currencyQuote']: moneyMask(valueToMoneyFloat('1,00')),
                    ['amount']: values.amount
                })
            });
        }
    };

    const handleAdornment = () => {
        switch (values.coin) {
            case 'BRL':
                return 'R$';
            case 'USD':
                return '$';
            case 'CAD':
                return 'C$';
            case 'EUR':
                return '€';
            case 'GBP':
                return '£';
            default:
                return '';
        }
    };

    async function fetchProfileData() {
        await getLibraryManagersUsers();
        await getLibraryCoordinationUser();
    }

    useEffect(() => {
        fetchProfileData();
        getUsersByPermission('LIBRARY_CONFIGURATION');
        if (values.totalPurchase) {
            setTotalPurchaseByUser(true);
        }
    }, []);

    useEffect(() => {
        if (libraryManagersUsers.length > 0) {
            setLibraryManager(
                GetInputArray(libraryManagersUsers, 'id', 'name')
            );
        }

        if (libraryCoordinationUsers.length > 0) {
            setLibraryCoordinator(
                GetInputArray(libraryCoordinationUsers, 'id', 'name')
            );
        }
        setAcquisitionDescriptionRequired(false);
        setProjectIdRequired(false);
    }, [
        libraryManagersUsers,
        libraryCoordinationUsers,
        values.acquisitionMethod
    ]);

    useEffect(() => {
        setInvalidAcquisitionDate(false);
        setAcquisitionDateRequired(false);
    }, [values.acquisitionDate]);

    const minInputSize = width >= 1600 ? 2 : 3;

    return (
        <React.Fragment>
            <Transition loading={false} newDesign={true}>
                <div className={styles.bookFormWrapper}>
                    <InputImage
                        context={
                            context !== 'view'
                                ? bookAlreadyRegistered
                                    ? 'view'
                                    : ''
                                : context
                        }
                        file={imageFile}
                        setFile={setImageFile}
                        imageUrl={image ? image : null}
                    />
                    <div className={styles.bookForm}>
                        <Grid container spacing={1}>
                            {context === 'new' ? (
                                <Grid item sm={minInputSize}>
                                    <Input
                                        required
                                        context={context}
                                        label={'INVENTORY.amountExemplary'}
                                        value={values.amount}
                                        valueName={'amount'}
                                        maxLength={2}
                                        handleChange={handleChange}
                                        type={'number'}
                                        error={amountRequired}
                                        helperText={
                                            amountRequired &&
                                            values.amount === '0'
                                                ? 'Quantidade inválida'
                                                : t('GENERAL.requiredField')
                                        }
                                        setRequired={setAmountRequired}
                                    />
                                </Grid>
                            ) : (
                                <>
                                    <Grid item sm={minInputSize * 2}>
                                        <Input
                                            context={'view'}
                                            label={'INVENTORY.copyCode'}
                                            value={values.code}
                                        />
                                    </Grid>
                                </>
                            )}
                            <Grid item sm={minInputSize * 2}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        disabled={context === 'view'}
                                        className={
                                            classes.identificationDatePicker
                                        }
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="validity"
                                        autoOk={true}
                                        label={
                                            t('INVENTORY.dateAcquisition') + '*'
                                        }
                                        value={values.acquisitionDate}
                                        // onChange={ev => {
                                        //     handleChange('acquisitionDate', ev);
                                        //     handleChangeDate(ev);
                                        // }}
                                        onChange={ev => handleChangeDate(ev)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date'
                                        }}
                                        invalidDateMessage={''}
                                        error={
                                            acquisitionDateRequired ||
                                            invalidAcquisitionDate
                                        }
                                        helperText={
                                            acquisitionDateRequired
                                                ? t('GENERAL.requiredField')
                                                : invalidAcquisitionDate
                                                ? t('GENERAL.invalidDate')
                                                : ''
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>

                            <Box width="100%" />

                            <Grid item sm={minInputSize * 2}>
                                <Input
                                    required
                                    disabled={
                                        values.acquisitionDate === null ||
                                        context === 'view'
                                    }
                                    context={context}
                                    isSelect
                                    arraySelected={coins}
                                    label={'INVENTORY.coin'}
                                    value={values.coin}
                                    valueName={'coin'}
                                    handleChange={handleChangeCoin}
                                    error={purchaseCurrencyRequired}
                                    helperText={
                                        purchaseCurrencyRequired &&
                                        t('GENERAL.requiredField')
                                    }
                                    setRequired={setPurchaseCurrencyReuired}
                                />
                            </Grid>

                            {values.coin !== 'BRL' && (
                                <>
                                    <Grid
                                        item
                                        sm={minInputSize}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Input
                                            required
                                            context={
                                                context !== 'view'
                                                    ? loadingQuotation
                                                        ? 'view'
                                                        : 'edit'
                                                    : context
                                            }
                                            maxLength={10}
                                            label={'INVENTORY.currencyQuote'}
                                            value={values.currencyQuote}
                                            valueName={'currencyQuote'}
                                            handleChange={handleChangeMoney}
                                            error={currencyQuoteRequired}
                                            helperText={
                                                currencyQuoteRequired &&
                                                parseFloat(
                                                    values.currencyQuote
                                                ) === 0
                                                    ? 'Cotação inválida'
                                                    : t('GENERAL.requiredField')
                                            }
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        R$
                                                    </InputAdornment>
                                                )
                                            }}
                                            setRequired={
                                                setCurrencyQuoteRequired
                                            }
                                        />
                                        {context !== 'view' ? (
                                            <HtmlTooltip
                                                title={
                                                    <Typography
                                                        textColor={'white'}
                                                    >
                                                        {t(
                                                            'GENERAL.apiQuoteTooltip'
                                                        )}
                                                    </Typography>
                                                }
                                                placement={'top'}
                                                style={{ color: '#757575' }}
                                            >
                                                <InfoIcon />
                                            </HtmlTooltip>
                                        ) : (
                                            ''
                                        )}
                                    </Grid>
                                    <Box width="100%" />
                                    <Typography
                                        variant={'RobotoRegular'}
                                        fontSize={12}
                                        textColor={'secondary'}
                                    >
                                        {t('INVENTORY.suggestionCoin')}
                                    </Typography>
                                </>
                            )}

                            <Box width="100%" />

                            <Grid item sm={minInputSize}>
                                <Input
                                    required
                                    context={context}
                                    maxLength={17}
                                    label={`${t('INVENTORY.purchasePrice')} (${
                                        values.coin
                                    })`}
                                    value={values.purchasePrice}
                                    valueName={'purchasePrice'}
                                    handleChange={handleChangeMoney}
                                    error={purchasePriceRequired}
                                    helperText={
                                        purchasePriceRequired &&
                                        t('GENERAL.requiredField')
                                    }
                                    setRequired={setPurchasePriceRequired}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {handleAdornment()}
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item sm={minInputSize}>
                                <Input
                                    context={context}
                                    maxLength={17}
                                    label={`${t('INVENTORY.freight')} (${
                                        values.coin
                                    })`}
                                    value={values.freight}
                                    valueName={'freight'}
                                    handleChange={handleChangeMoney}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {handleAdornment()}
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item sm={minInputSize}>
                                <Input
                                    context={context}
                                    maxLength={17}
                                    label={'INVENTORY.iof'}
                                    value={values.iof}
                                    valueName={'iof'}
                                    handleChange={handleChangeMoney}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                R$
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item sm={minInputSize}>
                                <Input
                                    context={context}
                                    maxLength={17}
                                    label={'INVENTORY.totalPurchase'}
                                    onInput={() => setTotalPurchaseByUser(true)}
                                    value={values.totalPurchase}
                                    valueName={'totalPurchase'}
                                    handleChange={handleChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                R$
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>

                            <Box width="100%" />

                            <div style={{ padding: '4px' }}>
                                <Typography
                                    variant={'RobotoRegular'}
                                    fontSize={12}
                                    textColor={'secondary'}
                                >
                                    {t('INVENTORY.hintCalcCoin')}
                                </Typography>
                                {context === 'new' && (
                                    <>
                                        <Box width="100%" />
                                        {values.amount && (
                                            <Typography
                                                variant={'RobotoRegular'}
                                                fontSize={12}
                                                textColor={'secondary'}
                                            >
                                                {t('INVENTORY.costPerUnit')}{' '}
                                                <b>R${costPerUnit(values)}</b>
                                            </Typography>
                                        )}
                                    </>
                                )}
                            </div>

                            <Box width="100%" />

                            <FormControl
                                style={{ margin: '24px 4px 0' }}
                                component="fieldset"
                            >
                                <FormLabel component="legend">
                                    <Typography
                                        variant={'RobotoBold'}
                                        fontSize={12}
                                        textColor={'secondary'}
                                    >
                                        {t(
                                            'INVENTORY.acquisitionMethod'
                                        ).toLocaleUpperCase()}
                                    </Typography>
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="position"
                                    name="position"
                                    defaultValue="top"
                                    value={values.acquisitionMethod}
                                    onChange={ev =>
                                        handleChange(
                                            'acquisitionMethod',
                                            ev.target.value
                                        )
                                    }
                                >
                                    <FormControlLabel
                                        disabled={context === 'view'}
                                        value={
                                            ExemplarAcquisitionMethods.PROJECT
                                        }
                                        control={<Radio color="primary" />}
                                        label={t('PROJECTS.project')}
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        disabled={context === 'view'}
                                        value={ExemplarAcquisitionMethods.FINE}
                                        control={<Radio color="primary" />}
                                        label={t('GENERAL.fines')}
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        disabled={context === 'view'}
                                        value={
                                            ExemplarAcquisitionMethods.OTHERS
                                        }
                                        control={<Radio color="primary" />}
                                        label={t('GENERAL.others')}
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </FormControl>

                            {values.acquisitionMethod ===
                                ExemplarAcquisitionMethods.PROJECT && (
                                <>
                                    <Box width="100%" />
                                    <Grid item sm={minInputSize * 4}>
                                        <Input
                                            required
                                            context={context}
                                            isSelect
                                            arraySelected={projectsArray}
                                            label={'INVENTORY.originProject'}
                                            value={values.projectId}
                                            valueName={'projectId'}
                                            handleChange={handleChange}
                                            error={projectIdRequired}
                                            helperText={
                                                projectIdRequired &&
                                                t('GENERAL.requiredField')
                                            }
                                            setRequired={setProjectIdRequired}
                                        />
                                    </Grid>
                                </>
                            )}

                            {values.acquisitionMethod !==
                                ExemplarAcquisitionMethods.PROJECT && (
                                <>
                                    <Box width="100%" />
                                    <Grid item sm={minInputSize * 4}>
                                        <Input
                                            required
                                            context={context}
                                            label={'HR.description'}
                                            value={
                                                values.acquisitionDescription
                                            }
                                            valueName={'acquisitionDescription'}
                                            handleChange={handleChange}
                                            maxLength={100}
                                            rows={3}
                                            error={
                                                acquisitionDescriptionRequired
                                            }
                                            helperText={
                                                acquisitionDescriptionRequired &&
                                                t('GENERAL.requiredField')
                                            }
                                            setRequired={
                                                setAcquisitionDescriptionRequired
                                            }
                                        />
                                    </Grid>
                                </>
                            )}

                            <Box width="100%" />

                            <Grid item sm={minInputSize * 4}>
                                <Input
                                    context={context}
                                    label={'INVENTORY.invoice'}
                                    value={values.invoice}
                                    valueName={'invoice'}
                                    handleChange={handleChange}
                                    maxLength={50}
                                />
                            </Grid>

                            <Box width="100%" />

                            <Grid item sm={minInputSize * 4}>
                                <Input
                                    required
                                    context={context}
                                    isSelect
                                    arraySelected={libraryArray}
                                    label={'INVENTORY.library'}
                                    value={values.library}
                                    valueName={'library'}
                                    handleChange={handleChange}
                                    error={libraryRequired}
                                    helperText={
                                        libraryRequired &&
                                        t('GENERAL.requiredField')
                                    }
                                    setRequired={setLibraryRequired}
                                />
                            </Grid>
                            <Box width="100%" />
                            <Grid item sm={minInputSize * 4}>
                                {values.library ===
                                LibraryPlaceValues.EXTERNAL ? (
                                    <Input
                                        context={context}
                                        label={
                                            'INVENTORY.administrativeResponsible'
                                        }
                                        value={values.responsible}
                                        valueName={'responsible'}
                                        handleChange={handleChange}
                                        maxLength={100}
                                    />
                                ) : (
                                    <Input
                                        isSelect
                                        context={
                                            !values.library ? 'view' : context
                                        }
                                        arraySelected={
                                            values.library
                                                ? values.library ===
                                                  LibraryPlaceValues.GENERAL
                                                    ? libraryManagers
                                                    : libraryCoordinators
                                                : []
                                        }
                                        label={
                                            'INVENTORY.administrativeResponsible'
                                        }
                                        value={values.responsibleId}
                                        valueName={'responsibleId'}
                                        handleChange={handleChange}
                                    />
                                )}
                            </Grid>

                            <Box width="100%" />
                            <Grid item sm={minInputSize * 4}>
                                {values.directResponsible !== null ? (
                                    <Input
                                        disabled
                                        context={context}
                                        label={'INVENTORY.directResponsible'}
                                        value={values.directResponsible}
                                        valueName={'directResponsible'}
                                        handleChange={handleChange}
                                        maxLength={100}
                                    />
                                ) : (
                                    ''
                                )}
                            </Grid>

                            <Box width="100%" />

                            <Grid item sm={minInputSize * 2}>
                                <Input
                                    required
                                    disabled={
                                        values.status ===
                                            LibraryExemplarStatusValues.LOANED ||
                                        values.status ===
                                            LibraryExemplarStatusValues.IN_LOAN_PROCESS ||
                                        context === 'view'
                                    }
                                    isSelect
                                    arraySelected={handleArrayStatus(
                                        values.status
                                    )}
                                    label={'INVENTORY.statusExemplar'}
                                    value={values.status}
                                    valueName={'status'}
                                    handleChange={handleChange}
                                    error={statusRequired}
                                    helperText={
                                        statusRequired &&
                                        t('GENERAL.requiredField')
                                    }
                                    setRequired={setStatusRequired}
                                />
                            </Grid>

                            <Grid item sm={minInputSize * 2}>
                                <Input
                                    required
                                    context={context}
                                    isSelect
                                    arraySelected={placeArray}
                                    label={'INVENTORY.place'}
                                    value={values.placeId}
                                    valueName={'placeId'}
                                    handleChange={handleChange}
                                    error={placeIdRequired}
                                    helperText={
                                        placeIdRequired &&
                                        t('GENERAL.requiredField')
                                    }
                                    setRequired={setPlaceIdRequired}
                                />
                            </Grid>

                            <Box width="100%" />

                            <Grid item xs={12} sm={minInputSize * 4}>
                                <InputMultiDropzone
                                    context={context}
                                    files={files}
                                    setFiles={setFiles}
                                    setHasChanged={setHasChange}
                                />
                            </Grid>

                            <Box width="100%" />

                            <Grid item sm={minInputSize * 4}>
                                <Input
                                    context={context}
                                    label={'GENERAL.observation'}
                                    value={values.observation}
                                    valueName={'observation'}
                                    handleChange={handleChange}
                                    maxLength={520}
                                    rows={3}
                                />
                            </Grid>

                            <Box width="100%" />

                            <LinkRouter className={styles.Link} to="/Books">
                                <Buttons
                                    tipo="BN-noback"
                                    conteudo={
                                        context !== 'view'
                                            ? t('GENERAL.cancel')
                                            : t('GENERAL.close')
                                    }
                                />
                            </LinkRouter>

                            {context !== 'view' && (
                                <Buttons
                                    onClick={handleSubmit}
                                    tipo="BN-blue"
                                    conteudo={
                                        context === 'edit'
                                            ? t('GENERAL.update')
                                            : t('GENERAL.add')
                                    }
                                />
                            )}
                        </Grid>
                    </div>
                </div>
            </Transition>
        </React.Fragment>
    );
};

export default ExemplarForm;
