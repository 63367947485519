import React, { useEffect, useContext, useState } from "react";
import Visibility from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import ProjectsContext from "context/projects/projectsContext";
import { useTranslation } from "react-i18next";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Buttons from "components/buttons/Pressed";
import { useStyles } from "components/layout/CommonStyles";
import AllowancesContext from "context/allowance/allowanceContext";
import CloseIcon from '@material-ui/icons/Close';

const ParticipantsTableActions = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const { participantId, projectId, id, participantName, mode, permission } = props;
    const [open, setOpen] = useState(false);
    const projectsContext = useContext(ProjectsContext);
    const { deleteParticipant, response, responseType, participantFeedback, getParticipantFeedback } = projectsContext;
    const allowanceContext = useContext(AllowancesContext);

    const {
        allAllowances,
        getAllPermission
      } = allowanceContext;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        deleteParticipant(id);
        setOpen(false);
    };

    useEffect(() => {
        return () => { };
    }, [response, responseType]);

    const getPermission = (screen, allowance) => {
        for (let i = 0; i < allAllowances.length; i++) {
          if (allAllowances[i].resource === screen) {  
            return allAllowances[i]["standardauthorizationoption"][allowance]
          }
        }
      }

      React.useEffect(() => {
        getAllPermission()
       // getParticipantFeedback(id)
      }, [allAllowances.length, JSON.stringify(allAllowances)]);

    return (
        <div>{allAllowances.length > 0 && getPermission("PARTICIPANTS","allowRead") &&
            <Link to={`/execution/participant/${mode}/${id}/${participantId}/${projectId}/view`}>
                <Tooltip title='Visualizar' aria-label='details' placement='top'>
                    <IconButton className={classes.actionsIcons}>
                        <Visibility />
                    </IconButton>
                </Tooltip>
            </Link>
            }
            {mode === t("GENERAL.editMode") && allAllowances.length > 0 && getPermission("PARTICIPANTS","allowUpdate") &&
                <Link to={`/execution/participant/${mode}/${id}/${participantId}/${projectId}/edit`}>
                    <Tooltip title='Editar' aria-label='edit' placement='top'>
                        <IconButton className={classes.actionsIcons}>
                            <Edit />
                        </IconButton>
                    </Tooltip>
                </Link>
            }
            {
                mode === t("GENERAL.editMode") && allAllowances.length > 0 && getPermission("PARTICIPANTS","allowDelete") &&
                <Tooltip title="Deletar" aria-label="delete" placement="top">
                    <IconButton className={classes.actionsIcons} onClick={handleClickOpen}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            }
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                msg="mensagem"
            >
                <DialogTitle className={classes.actionsTitle}>
                    {t("GENERAL.notification")}
                    <IconButton style={{float:"right", marginTop: -10, marginRight: -17}} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {`Você deseja realmente deletar o participante ${participantName} do projeto?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Buttons
                        onClick={handleClose}
                        tipo="BN-noback"
                        conteudo={t("GENERAL.cancel")}
                    />
                    <Buttons
                        onClick={handleDelete}
                        tipo="BN-blue"
                        conteudo={t("GENERAL.delete")}
                    />
                </DialogActions>
            </Dialog>
        </div >
    );
};

export default ParticipantsTableActions;