import { makeStyles } from "@material-ui/core/styles";

export const screenStyles = makeStyles(theme => ({
    form: {
      padding: "10px 15px" 
    },
    space: {
      marginTop: "25px",
    },
    title: {
      fontSize: "18px",
      fontWeight: "bold",
      color: "#6D6F71"
    },
    subtitle: {
      fontSize: "14px",
      color: "#6D6F71"
    },
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: 1,
    },
    errorIcon:{
      marginLeft: '0.15rem',
      color: "#6D6F71"
    },
    table: {
      marginBottom: "25px",
      minWidth: 650,
      maxWidth: 950
    },
    tabs: {
      color: "f5f6fa",
    },
    header: {
      fontSize: "14px",
      fontWeight: "bold",
      color: "#6D6F71"
    },
    removeHover: {
      "&:hover": {
        backgroundColor: "transparent"
      },
      pointerEvents: "none",
    },
    headerViewMore : {
      marginTop: "20px",
      backgroundColor: "#f5f6fa",
      opacity: "0.54",
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "bold",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "3.5",
      letterSpacing: "normal",
      textAlign: "left",
      color: "#000000"
    },
    headerViewMoreButton: {
      display: "flex", 
      justifyContent: "space-between", 
      width: "100%",
      height: 59,
      paddingLeft: "18px" ,
      paddingRight: 24
    },
    filterSearchInput: {
      position: 'relative',
      height: 48,
      width: 250,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: "#F5F6FA",
      '&:hover': {
        backgroundColor: "rgb(224, 224, 224)",
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
    },
  })
)  