import React from 'react';
import { screenStyles } from './Styles';
import Typography from 'components/typography/Typography';
import InfoTooltip from 'components/infoTooltip/InfoTooltip';
import { Box } from '@material-ui/core';

const DialogUnitWithIcon = props => {
    const { head, Icon, content, marginTop, tooltipContent } = props;
    const styles = screenStyles();

    return (
        <>
            <div className={styles.dialogUnitComponent}>
                <div className={styles.tooltipWrapper}>
                    <Typography
                        variant={'RobotoBold'}
                        fontSize={14}
                        textColor={'secondary'}
                    >
                        {head}
                    </Typography>
                    {tooltipContent ? (
                        <InfoTooltip
                            text={
                                <Typography
                                    variant={'RobotoRegular'}
                                    fontSize={12}
                                    textColor={'white'}
                                >
                                    {tooltipContent}
                                </Typography>
                            }
                            fontSize={18}
                        />
                    ) : null}
                </div>
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    minHeight="100%"
                    marginTop={marginTop ? marginTop : '8px'}
                >
                    {Icon}
                    <Typography
                        className={styles.dialogUnitWithIconsText}
                        variant={'RobotoRegular'}
                        fontSize={14}
                        textColor={'secondary'}
                    >
                        {content}
                    </Typography>
                </Box>
            </div>
        </>
    );
};

export default DialogUnitWithIcon;
