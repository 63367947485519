import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { useStyles } from "components/layout/CommonStyles";
import React, { useEffect, useState } from "react";

const ScopeStage = props => {
  const classes = useStyles();
  const {
    lastActivity,
    activityDescription,
    startDate,
    endDate
  } = props;
  const [description, setDescription] = useState(activityDescription);
  const [start, setStart] = useState(startDate);
  const [end, setEnd] = useState(endDate);

  useEffect(() => {
    setDescription(activityDescription);
  }, [activityDescription]);

  useEffect(() => {
    var endValue = new Date(endDate);
    endValue.setMinutes(endValue.getMinutes() + endValue.getTimezoneOffset())
    setEnd(endValue);
  }, [endDate]);

  useEffect(() => {
    var startValue = new Date(startDate);
    startValue.setMinutes(startValue.getMinutes() + startValue.getTimezoneOffset())
    setStart(startValue);
  }, [startDate]);

  const handleChangeDescription = event => {
    setDescription(event.target.value);
  };

  const handleDateChange = name => value => {
    switch (name) {
      case "startDate":
        setStart(value);
        break;
      case "endDate":
        setEnd(value);
        break;
      default:
        break;
    }
  };

  return (
    <div className={classes.bodyForm}>
      <Grid container spacing={2}>
        <Grid item xs={1}>
          <TextField
            disabled
            className={classes.textField}
            value={lastActivity}
            margin="normal"
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id={"descActivity" + lastActivity}
            className={classes.textField}
            disabled={true}
            value={description}
            margin="normal"
            onChange={handleChangeDescription}
          />
        </Grid>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item xs={3}>
            <KeyboardDatePicker
              className={classes.textField}
              disableToolbar
              disabled={true}
              required
              autoOk={true}
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              value={start}
              id={"startDate" + lastActivity}
              onChange={handleDateChange("startDate")}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <KeyboardDatePicker
              id={"endId" + lastActivity}
              className={classes.textField}
              disableToolbar
              disabled={true}
              required
              autoOk={true}
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              value={end}
              id={"endDate" + lastActivity}
              onChange={handleDateChange("endDate")}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
        <div className="lineBreak"></div>
      </Grid>
    </div>
  );
};

export default ScopeStage;
