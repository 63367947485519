import { TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import Buttons from "components/buttons/Pressed";
import { useStyles } from "components/layout/CommonStyles";
import MySnackbarContentWrapper from "components/layout/Message";
import AllowancesContext from "context/allowance/allowanceContext";
import ProjectsContext from "context/projects/projectsContext";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { animated, useTransition } from "react-spring";
import ViewNewBudget from "./ViewNewBudget";

const BudgetView = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const { projectId } = props;
    const projectsContext = useContext(ProjectsContext);
    const allowanceContext = useContext(AllowancesContext);
    var periodId = 0;
    const [addendumIdSubmit, setAddendumIdSubmit] = useState(0);
    const {
        loading,
        project,
        response,
        hasUpdate,
        getProject,
        resetProject,
        resetMessage,
        responseType,
        submitBudget,
        successiveActions,
        getProjects,
        projects
    } = projectsContext;

    const [delay, setDelay] = useState(true);
    const [quotas, setQuotas] = useState([]);
    const [state, setState] = useState({
        total: "",
        season: "",
        estimatedCost: 0,
        totalPercentage: "",
    });
    const estimatedCostRef = useRef(null);
    const [toDelete, setToDelete] = useState([]);
    const [quotasCount, setQuotasCount] = useState(7);
    const {
        allowanceResponse,
        allowanceResponseType,
        resetAllowanceMessage
    } = allowanceContext;

    const [gpf, setGpf] = useState("");
    const [season, setSeason] = useState("");
    const [seasonId, setSeasonId] = useState(0);
    const [addendums, setAddendums] = useState([]);
    const [projectName, setProjectName] = useState("");
    const [seasonsArray, setSeasonsArray] = useState([]);
    const [budgetId, setBudgetId] = useState(0);
    const [previousBalance, setPreviousBalance] = useState("");
    const [previousBalanceDecimal, setPreviousBalanceDecimal] = useState(0);
    const [previousBalanceCompany, setPreviousBalanceCompany] = useState("Saldo");
    const [show, setShow] = useState(false);
    const [openState, setOpenState] = useState(false);
    const [companySelect, setCompanySelect] = useState("");
    const [projectsSelect, setProjectsSelect] = useState([]);
    const [hasCooperationTerm, setHasCooperationTerm] = useState(false);

    const [snackBar, setSnackBar] = useState({
        open: false,
        kind: "",
        content: ""
    });

    const handleSnack = () => {
        setSnackBar({ ...snackBar, open: true });
    };

    const handleSnackClose = () => {
        setSnackBar({ ...snackBar, open: false });
        resetAllowanceMessage();
    };

    const dictMonths = {
        "JANEIRO": 0,
        "FEVEREIRO": 1,
        "MARÇO": 2,
        "ABRIL": 3,
        "MAIO": 4,
        "JUNHO": 5,
        "JULHO": 6,
        "AGOSTO": 7,
        "SETEMBRO": 8,
        "OUTUBRO": 9,
        "NOVEMBRO": 10,
        "DEZEMBRO": 11,
    }

    function bootstrap() {
        var aux = {};
        var general = {};
        var budget = [];
        var seasonsArray_ = [];
        var totalResult = ["", ""];

        setProjectName(project.information.name);
        if (project.sgppdAddendum.length > 0) {
            setHasCooperationTerm(true)
            for (var i = 0; i < project.sgppdAddendum.length; i++) {
                if (project.sgppdAddendum[i].gpf) {
                    setGpf(project.sgppdAddendum[i].gpf);
                }
            }

            for (var i = 0; i < project.sgppdAddendum.length; i++) {
                var item = project.sgppdAddendum[i];
                if (item.number !== "GENERAL") {
                    var startdatewithtimezone = new Date(item.startdate);
                    startdatewithtimezone.setMinutes(startdatewithtimezone.getMinutes() + startdatewithtimezone.getTimezoneOffset())
                    var enddatewithtimezone = new Date(item.enddate);
                    enddatewithtimezone.setMinutes(enddatewithtimezone.getMinutes() + enddatewithtimezone.getTimezoneOffset())
                    var dayStart = startdatewithtimezone.getDate()
                    var monthStart = (startdatewithtimezone.getMonth() + 1);
                    var dayEnd = enddatewithtimezone.getDate()
                    var monthEnd = (enddatewithtimezone.getMonth() + 1);
                    if (startdatewithtimezone.getDate() < 10) {
                        dayStart = "0" + startdatewithtimezone.getDate()
                    }
                    if ((startdatewithtimezone.getMonth() + 1) < 10) {
                        monthStart = "0" + (startdatewithtimezone.getMonth() + 1)
                    }
                    if (enddatewithtimezone.getDate() < 10) {
                        dayEnd = "0" + enddatewithtimezone.getDate()
                    }
                    if ((enddatewithtimezone.getMonth() + 1) < 10) {
                        monthEnd = "0" + (enddatewithtimezone.getMonth() + 1)
                    }
                    var startFormated = dayStart + "/" + monthStart + "/" + startdatewithtimezone.getFullYear();
                    var endFormated = dayEnd + "/" + monthEnd + "/" + enddatewithtimezone.getFullYear();
                    aux.value = item.id;
                    aux.label = startFormated + " - " + endFormated;
                    seasonsArray_.push(aux);
                    aux = {};
                }
            }

            seasonsArray_.push(general);
            var primor = seasonsArray_[seasonsArray_.length - 2];

            setSeasonId(primor.value);
            setSeason(primor.label);
            periodId = primor.value;
            setAddendumIdSubmit(primor.value);

            var currentAddendum = project.sgppdAddendum.filter(
                item => item.id === primor.value
            );

            var costAddendum = currentAddendum[0].estimatedcost;
            var savedQuotas = currentAddendum[0].projectBudget[0] ?
                currentAddendum[0].projectBudget[0].projectBudgetQuota : [];

            if (currentAddendum[0].projectBudget[0]) {
                setBudgetId(currentAddendum[0].projectBudget[0].id);

                if (currentAddendum[0].projectBudget[0].previousBalanceCompany) {
                    setPreviousBalanceCompany(currentAddendum[0].projectBudget[0].previousBalanceCompany);
                    setCompanySelect(currentAddendum[0].projectBudget[0].previousBalanceCompany)
                }
            }

            let hasPreviousBalance = currentAddendum[0].projectBudget[0] &&
                currentAddendum[0]
                    .projectBudget[0]
                    .previousBalance;

            if (hasPreviousBalance) {
                costAddendum += currentAddendum[0]
                    .projectBudget[0]
                    .previousBalance;

                let previousCost = currentAddendum[0]
                    .projectBudget[0]
                    .previousBalance
                    .toFixed(2)
                    .split("")
                    .filter(el => el !== "." && el !== ",")
                    .join("");

                let value = converTotBrCurrency(
                    previousCost
                        .split("")
                        .reverse()
                        .join("")
                );

                setPreviousBalance(value);
            }

            if (savedQuotas && savedQuotas.length > 0) {
                var len = quotas.length;
                quotas.splice(0, len);
                setQuotasCount(0);

                var total = 0;

                for (var j = 0; j < savedQuotas.length; j++) {
                    total += savedQuotas[j].quotaValue;
                }

                for (var k = 0; k < savedQuotas.length; k++) {
                    var cost = savedQuotas[k].quotaValue.toString().split("");
                    if (savedQuotas[k].quotaValue !== 0) {
                        if (
                            (cost[cost.length - 1] === "0" && cost[cost.length - 2] === "0") ||
                            cost[cost.length - 3] !== "."
                        ) {
                            if (cost[cost.length - 2] === ".") {
                                cost.push("0");
                            } else {
                                cost.push("0");
                                cost.push("0");
                            }
                        }

                        budget.push(cost);
                    } else {
                        cost = "";
                        budget.push(cost);
                    }
                }

                var totalFormatted = total
                    .toFixed(2)
                    .split("")
                    .filter(el => el !== "." && el !== ",")
                    .join("");

                totalResult = convertTotalToBrCurrency(totalFormatted);

                setState({
                    ...state,
                    totalHeadings: "",
                    total: totalResult[0],
                    totalPercentage: totalResult[1],
                    estimatedCost: costAddendum,
                });

                for (let x = 0; x < savedQuotas.length; x++) {
                    var quotaValue = savedQuotas[x].quotaValue.toString().split("");

                    if (
                        (quotaValue[quotaValue.length - 1] === "0" &&
                            quotaValue[quotaValue.length - 2] === "0") ||
                        quotaValue[quotaValue.length - 3] !== "."
                    ) {
                        if (quotaValue[quotaValue.length - 2] === ".") {
                            quotaValue.push("0");
                        } else {
                            quotaValue.push("0");
                            quotaValue.push("0");
                        }
                    }

                    var editedValue = quotaValue
                        .filter(el => el !== "." && el !== ",")
                        .join("")
                        .split("")
                        .reverse()
                        .join("");

                    var valueToAdd = converTotBrCurrency(editedValue);
                    addQuota(
                        true,
                        savedQuotas[x].quotaDate,
                        valueToAdd,
                        savedQuotas[x].id,
                        costAddendum
                    );
                }
            } else {
                let hasPreviousBalance = currentAddendum[0].projectBudget[0] &&
                    currentAddendum[0]
                        .projectBudget[0]
                        .previousBalance;

                if (hasPreviousBalance) {
                    costAddendum += currentAddendum[0]
                        .projectBudget[0]
                        .previousBalance;

                    let previousCost = currentAddendum[0]
                        .projectBudget[0]
                        .previousBalance
                        .toFixed(2)
                        .split("")
                        .filter(el => el !== "." && el !== ",")
                        .join("");

                    let value = converTotBrCurrency(
                        previousCost
                            .split("")
                            .reverse()
                            .join("")
                    );

                    setPreviousBalance(value);
                }

                var len = quotas.length;
                quotas.splice(0, len);
                setQuotasCount(0);

                setState({
                    ...state,
                    total: "",
                    totalGeneral: "",
                    estimatedCost: costAddendum
                });
            }

            setSeasonsArray(seasonsArray_);
            setAddendums(project.sgppdAddendum);
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (responseType === "SUCCESS") {
                handleSnack({ kind: "success", content: response });
            } else {

            }
            if (responseType === "ERROR") {
                handleSnack({ kind: "error", content: response });
            }
            setDelay(false);
        }, 700);

        return () => {
            clearTimeout(timer);
        };
    }, [responseType, response, successiveActions]);

    useEffect(() => {
        getProject(projectId);
        getProjects();

        if (projects && projects.length > 0) {
            let auxList = [];
            for (let y = 0; y < projects.length; y++) {
                if (projects[y].status === "Encerrado") {
                    let auxObj = {};
                    auxObj.value = projects[y].id;
                    let name = projects[y].name + " - " + projects[y].gpf +
                        "/" + projects[y].endDate.slice(6);
                    auxObj.label = name;
                    auxList.push(auxObj);
                    auxObj = {};
                }
            }

            setProjectsSelect(auxList);
        }

        const timer = setTimeout(() => {
            if (Object.keys(project).length > 0) {
                bootstrap();
            }
        }, 300);
        return () => {
            clearTimeout(timer);
        };
    }, [Object.keys(seasonsArray).length, Object.keys(project).length, Object.keys(projects).length]);

    useEffect(() => {
        if (toDelete.length > 0) {
            if (quotas.length > 0) {
                var aux = [];
                var len = quotas.length;

                for (var i = 0; i < len; i++) {
                    if (quotas[i]) {
                        let quotaDateValue = document.getElementById("quotaDate" + quotas[i].key).value;
                        let quotaMonth = dictMonths[quotaDateValue.split(" ")[0].toUpperCase()];
                        let quotaYear = quotaDateValue.split(" ")[1];
                        let quotaDate = new Date(quotaYear, quotaMonth);
                        var element = {
                            quotaDate: quotaDate,
                            value: document.getElementById("quotaValue" + quotas[i].key)
                                .value,
                            id: parseInt(
                                document.querySelector("#divBudget" + quotas[i].key).dataset
                                    .quotaId
                            )
                        };
                        aux.push(element);
                    }
                }
                quotas.splice(0, len);
                setQuotasCount(0);

                for (var i = 0; i < aux.length; i++) {
                    addQuota(
                        true,
                        aux[i].quotaDate,
                        aux[i].value,
                        aux[i].id,
                        state.estimatedCost
                    );
                }
            }
        }
        return () => { };
    }, [toDelete.length]);

    // useEffect(() => {
    //     if (addendums.length > 0 && seasonId) {
    //         updateFields();
    //     }
    //     return () => { };
    // });
    useEffect(() => {
        if (addendums.length > 0 && seasonId) {
            updateFields();
        }
        return () => { };
    }, [season, toDelete.length, seasonId, state.estimatedCost, state.total, state.totalPercentage]);

    useEffect(() => {
        if (hasUpdate) {
            setQuotas([]);
            setAddendums([]);
            resetProject();
        }
        return () => { };
    }, [hasUpdate]);

    useEffect(() => {
        return () => {
            resetMessage();
        };
    }, []);

    const updateForChangeSeason = () => {
        var budget = [];
        var totalResult = ["", ""];
        setPreviousBalanceCompany("Saldo");
        setPreviousBalance("");
        var currentAddendum = addendums.filter(item => item.id === periodId);
        var costAddendum = currentAddendum[0].estimatedcost;
        var savedQuotas = currentAddendum[0].projectBudget[0] ?
            currentAddendum[0].projectBudget[0].projectBudgetQuota : [];

        if (currentAddendum[0].projectBudget[0]) {
            setBudgetId(currentAddendum[0].projectBudget[0].id);

            if (currentAddendum[0].projectBudget[0].previousBalanceCompany) {
                setPreviousBalanceCompany(currentAddendum[0].projectBudget[0].previousBalanceCompany);
                setCompanySelect(currentAddendum[0].projectBudget[0].previousBalanceCompany)
            }
        }

        let hasPreviousBalance = currentAddendum[0].projectBudget[0] &&
            currentAddendum[0]
                .projectBudget[0]
                .previousBalance;

        if (hasPreviousBalance) {
            costAddendum += currentAddendum[0]
                .projectBudget[0]
                .previousBalance;

            let previousCost = currentAddendum[0]
                .projectBudget[0]
                .previousBalance
                .toFixed(2)
                .split("")
                .filter(el => el !== "." && el !== ",")
                .join("");

            let value = converTotBrCurrency(
                previousCost
                    .split("")
                    .reverse()
                    .join("")
            );

            setPreviousBalance(value);
        }

        if (savedQuotas && savedQuotas.length > 0) {
            var total = 0;
            var len = quotas.length;
            quotas.splice(0, len);
            setQuotasCount(0);

            for (var j = 0; j < savedQuotas.length; j++) {
                total += savedQuotas[j].quotaValue;
            }

            for (var k = 0; k < savedQuotas.length; k++) {
                var cost = savedQuotas[k].quotaValue.toString().split("");

                if (savedQuotas[k].value !== 0) {
                    if (
                        (cost[cost.length - 1] === "0" && cost[cost.length - 2] === "0") ||
                        cost[cost.length - 3] !== "."
                    ) {
                        if (cost[cost.length - 2] === ".") {
                            cost.push("0");
                        } else {
                            cost.push("0");
                            cost.push("0");
                        }
                    }

                    budget.push(cost);
                } else {
                    cost = "";
                    budget.push(cost);
                }
            }
            var totalFormatted = total
                .toFixed(2)
                .split("")
                .filter(el => el !== "." && el !== ",")
                .join("");

            totalResult = convertTotalToBrCurrency(totalFormatted);

            setState({
                ...state,
                total: totalResult[0],
                totalPercentage: totalResult[1],
                estimatedCost: costAddendum,
            });

            for (let x = 0; x < savedQuotas.length; x++) {
                var quotaValue = savedQuotas[x].quotaValue.toString().split("");

                if (
                    (quotaValue[quotaValue.length - 1] === "0" &&
                        quotaValue[quotaValue.length - 2] === "0") ||
                    quotaValue[quotaValue.length - 3] !== "."
                ) {
                    if (quotaValue[quotaValue.length - 2] === ".") {
                        quotaValue.push("0");
                    } else {
                        quotaValue.push("0");
                        quotaValue.push("0");
                    }
                }

                var editedValue = quotaValue
                    .filter(el => el !== "." && el !== ",")
                    .join("")
                    .split("")
                    .reverse()
                    .join("");

                var valueToAdd = converTotBrCurrency(editedValue);
                addQuota(
                    true,
                    savedQuotas[x].quotaDate,
                    valueToAdd,
                    savedQuotas[x].id,
                    costAddendum
                );
            }
        } else {
            let hasPreviousBalance = currentAddendum[0].projectBudget[0] &&
                currentAddendum[0]
                    .projectBudget[0]
                    .previousBalance;

            if (hasPreviousBalance) {
                costAddendum += currentAddendum[0]
                    .projectBudget[0]
                    .previousBalance;

                let previousCost = currentAddendum[0]
                    .projectBudget[0]
                    .previousBalance
                    .toFixed(2)
                    .split("")
                    .filter(el => el !== "." && el !== ",")
                    .join("");

                let value = converTotBrCurrency(
                    previousCost
                        .split("")
                        .reverse()
                        .join("")
                );

                setPreviousBalance(value);
            }

            var len = quotas.length;
            quotas.splice(0, len);
            setQuotasCount(0);

            setState({
                ...state,
                total: "",
                totalPercentage: "",
                estimatedCost: costAddendum,
            });
        }
    };

    const getId = season => {
        var id = 0;

        for (var i = 0; i < seasonsArray.length; i++) {
            if (seasonsArray[i].label === season) {
                id = seasonsArray[i].value;
                break;
            }
        }
        return id;
    };

    const handleChangeSeason = event => {
        var id = getId(event.target.value);
        setSeason(event.target.value);
        periodId = id;
        setAddendumIdSubmit(id);
        let len = toDelete.length;
        toDelete.splice(0, len);
        setPreviousBalance("");
        setBudgetId(0);
        updateForChangeSeason();
    };

    const converTotBrCurrency = number => {
        var temp = "";
        var len = number.length;
        var count = 0;

        for (var i = 0; i < len; i++) {
            if (i === 1) {
                var aux = "," + number[i] + temp;
                temp = aux;
            } else {
                if (count === 3) {
                    count = 1;
                    var aux = number[i] + "." + temp;
                    temp = aux;
                } else {
                    if (i > 1) {
                        count += 1;
                    }
                    var aux = number[i] + temp;
                    temp = aux;
                }
            }
        }

        return temp;
    };

    const convertTotalToBrCurrency = total => {
        var aux = total
            .toString()
            .split("")
            .filter(el => el !== "." && el !== ",")
            .join("");
        var value = converTotBrCurrency(
            aux
                .split("")
                .reverse()
                .join("")
        );

        var totalPercentage = value && value !== "0,00" &&
            state.estimatedCost > 0 ? (
                (parseFloat
                    (
                        value
                            .split("")
                            .filter(el => el !== ".")
                            .join("")
                            .replace(",", ".")
                    ) /
                    state.estimatedCost) * 100
            ).toFixed(2)
            : "0.00";

        return [value, totalPercentage];
    };

    const updateFields = () => {
        var totalResult = [state.total, state.totalPercentage];
        var len = document.getElementsByClassName("budgetQuotas").length;
        var total = 0;

        for (var i = 0; i < len; i++) {
            var value = document.getElementById("quotaValue" + i)
                ? document.getElementById("quotaValue" + i).value
                : null;
            if (value) {
                var currencyStr = value
                    .split("")
                    .filter(el => el !== ".")
                    .join("")
                    .replace(",", ".");
                total += parseFloat(currencyStr);
            }
        }

        if (previousBalance) {
            let previousBalanceValue = previousBalance
                .split("")
                .filter(el => el !== ".")
                .join("")
                .replace(",", ".");
            total += parseFloat(previousBalanceValue);
        }

        totalResult = convertTotalToBrCurrency(total.toFixed(2));
        setState({
            ...state,
            total: totalResult[0],
            totalPercentage: totalResult[1]
        });
    };

    const deleteQuota = quotaForDelete => {
        var index = 0;
        var toExclude = parseInt(
            document.querySelector("#divBudget" + quotaForDelete).dataset.quotaId
        );

        for (var i = 0; i < quotas.length; i++) {
            var key = parseInt(quotas[i].key);

            if (key === quotaForDelete) {
                index = i;
                break;
            }
        }

        var auxList = quotas;
        var element = auxList.splice(index, 1);

        setQuotas(quotas.filter(item => item !== element));
        setQuotasCount(quotasCount - 1);
        toDelete.push(toExclude);
    };

    const addQuota = (
        loading,
        quotaDate,
        quotaValue,
        quotaId,
        cost
    ) => {
        let addendumId = periodId != 0 ? periodId : addendumIdSubmit;
        let currentAddendum = project.sgppdAddendum.filter(item => item.id === addendumId)[0];
        let addendumYear = new Date(currentAddendum.startdate).getFullYear();
        let addendumMonth = new Date(currentAddendum.startdate).getMonth();
        let addendumDay = new Date(currentAddendum.startdate).getDate();
        var newDate = new Date(addendumYear, addendumMonth, addendumDay);

        if (!quotaDate && quotas.length > 0) {
            let lastDateAdded = document.getElementById("quotaDate" + (quotas.length - 1)).value;
            let lastDateYear = parseInt(lastDateAdded.split(" ")[1]);
            let lastDateMonth = dictMonths[lastDateAdded.split(" ")[0].toUpperCase()];
            newDate = new Date(lastDateYear, (lastDateMonth + 1));
        }

        var date = loading === true ? (quotaDate ? quotaDate : newDate) : newDate;
        var value = loading === true ? quotaValue : "";
        var id = quotaId !== 0 && quotaId !== undefined ? quotaId : 0;
        var costAddendum = loading === true ? cost : state.estimatedCost + previousBalanceDecimal;
        let startDateAddendum = new Date(currentAddendum.startdate);
        var minDate = new Date(startDateAddendum.getFullYear(), startDateAddendum.getMonth());

        quotas.push(
            <div
                id={"divBudget" + (quotas.length)}
                key={quotas.length}
                data-quota-id={id}
                className="budgetQuotas"
            >
                <ViewNewBudget
                    opacity={1}
                    deleteQuota={deleteQuota}
                    lastQuota={quotas.length}
                    budgetDate={date}
                    quotaValue={value}
                    estimatedCost={costAddendum}
                    updateFields={updateFields}
                    minDate={minDate}
                    maxDate={currentAddendum.enddate}
                />
            </div>

        );
        setQuotasCount(quotasCount + 1);
    };

    const getAllQuotas = () => {
        var allQuotas = [];
        let len = document.getElementsByClassName("budgetQuotas").length;

        for (var i = 0; i < len; i++) {
            var aux = {};
            let quotaDateValue = document.getElementById("quotaDate" + i).value;
            let quotaMonth = dictMonths[quotaDateValue.split(" ")[0].toUpperCase()];
            let quotaYear = parseInt(quotaDateValue.split(" ")[1]);
            let quotaDateSubmit = new Date(quotaYear, quotaMonth);
            aux.quotaDate = quotaDateSubmit;
            aux.quotaValue = document
                .getElementById("quotaValue" + i)
                .value.split("")
                .filter(el => el !== ".")
                .join("")
                .replace(",", ".");
            aux.id = document.querySelector(
                "#divBudget" + i
            ).dataset.quotaId;
            aux.projectBudgetId = budgetId;
            allQuotas.push(aux);
            aux = {};
        }

        return allQuotas;
    };

    const handleSubmit = () => {
        var obj = {
            id: 0,
            addendumId: "",
            projectBudgetQuota: [],
            toDelete: [],
            previousBalance: "",
            previousBalanceCompany: ""
        };

        obj["id"] = budgetId;
        obj["addendumId"] = addendumIdSubmit;
        obj["projectBudgetQuota"] = getAllQuotas();
        obj["toDelete"] = toDelete.filter(el => el !== 0);
        obj["previousBalanceCompany"] = previousBalanceCompany !== "Saldo" ? previousBalanceCompany : "";
        obj["previousBalance"] = parseFloat(
            previousBalance.split("")
                .filter(el => el !== ".")
                .join("")
                .replace(",", ".")
        );

        setPreviousBalanceCompany("Saldo");
        setPreviousBalance("");
        submitBudget(obj);
    };

    const transitions = useTransition(loading || delay, null, {
        from: { opacity: 0 },
        enter: {
            opacity: 1
        },
        leave: { opacity: 0 }
    });

    const handlePreviousBalanceChange = event => {
        if (event.target.value !== " " && !event.target.value.includes(" ")) {
            var str = event.target.value.split("");
            var aux = str.filter(el => el !== "." && el !== ",").join("");
            var value = event.target.value;

            if (event.target.value) {
                value = converTotBrCurrency(
                    aux
                        .split("")
                        .reverse()
                        .join("")
                );
            }

            aux = value.split("").filter(el => el !== ".").join("").replace(",", ".")
            if (!isNaN(aux)) {
                setPreviousBalance(value);
            }
        }
    };

    const showButton = () => {
        setShow(true);
    };

    const hideButton = () => {
        setShow(false);
    };

    const openModal = () => {
        setOpenState(true);
    };

    const handleCloseModal = () => {
        setOpenState(false);
    };

    const addPreviousBalanceCompany = () => {
        setPreviousBalanceCompany(companySelect);
        setOpenState(false);
    };

    const handleChangeCompany = event => {
        setCompanySelect(event.target.value);
    };


    return transitions.map(({ item, key, props }) =>
        item ? (
            <animated.div key={key} style={props}>
                <></>
            </animated.div>
        ) : (
                <animated.div key={key} style={props}>
                    <Paper className={classes.root}>
                        <Snackbar
                            anchorOrigin={{ vertical: "top", horizontal: "right" }}
                            open={snackBar.open}
                            onClose={handleSnackClose}
                            TransitionComponent={Slide}
                            transitionDuration={{ enter: 500, exit: 500 }}
                            ContentProps={{
                                "aria-describedby": "message-id"
                            }}
                            autoHideDuration={2000}
                        >
                            <MySnackbarContentWrapper
                                onClose={handleSnackClose}
                                variant={
                                    responseType !== ""
                                        ? responseType.toLowerCase()
                                        : allowanceResponseType.toLowerCase()
                                }
                                message={
                                    <span id="message-id">
                                        {response !== "" ? response : allowanceResponse}
                                    </span>
                                }
                            />
                        </Snackbar>
                        <div className={classes.header}>
                            {t("Orçamento").toUpperCase()}
                        </div>
                        {hasCooperationTerm ?
                            <div>
                                <div className={classes.bodyForm}>
                                    <Grid container item spacing={2}>
                                        <Grid item xs={3}>
                                            <TextField
                                                disabled
                                                label={t("PROJECTS.gpfUpperCase")}
                                                className={classes.textField}
                                                value={gpf}
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                disabled
                                                label={t("PROJECTS.projectName")}
                                                className={classes.textField}
                                                value={projectName}
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                select
                                                id="season"
                                                label={t("PROJECTS.season")}
                                                className={classes.textField}
                                                name={season}
                                                margin="normal"
                                                value={season}
                                                onChange={handleChangeSeason}
                                            >
                                                {seasonsArray.map(option => (
                                                    <MenuItem key={option.value} value={option.label}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className={classes.bodyForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4} style={{ fontWeight: "bold", opacity: "0.54" }}>
                                            {t("GENERAL.date").toUpperCase()}
                                        </Grid>
                                        <Grid item xs={4} style={{ fontWeight: "bold", opacity: "0.54" }}>
                                            {t("BUDGET.balanceValue").toUpperCase()}
                                        </Grid>
                                        <Grid item xs={3} style={{ fontWeight: "bold", opacity: "0.54" }}>%</Grid>
                                        <Grid item xs={1} style={{ marginTop: "-12px", marginBottom: "-15px" }} >
                                        </Grid>
                                        <div className="lineBreak"></div>
                                    </Grid>
                                    <div id={"divPreviousBalance"} >
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <TextField
                                                    disabled
                                                    id="previousBalanceCompany"
                                                    className={classes.textField}
                                                    margin="normal"
                                                    name="previousBalanceCompany"
                                                    value={previousBalanceCompany}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    disabled
                                                    id="previousBalanceValue"
                                                    className={classes.textField}
                                                    margin="normal"
                                                    name="previousBalanceValue"
                                                    value={previousBalance}
                                                    onChange={handlePreviousBalanceChange}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">R$</InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <TextField
                                                    disabled
                                                    className={classes.textField}
                                                    value={
                                                        previousBalance && state.estimatedCost > 0
                                                            ? (
                                                                (parseFloat(
                                                                    previousBalance
                                                                        .split("")
                                                                        .filter(el => el !== ".")
                                                                        .join("")
                                                                        .replace(",", ".")
                                                                ) /
                                                                    (state.estimatedCost + parseFloat(
                                                                        previousBalance
                                                                            .split("")
                                                                            .filter(el => el !== ".")
                                                                            .join("")
                                                                            .replace(",", ".")
                                                                    ))) *
                                                                100
                                                            ).toFixed(2)
                                                            : "0.00"
                                                    }
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid item xs={2}></Grid>
                                        </Grid>
                                        <div className="lineBreak"></div>
                                    </div>
                                    <div>{quotas}</div>
                                    <div id={"divTotalQuotas"} className="totalQuotas">
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <TextField
                                                    disabled
                                                    className={classes.textField}
                                                    style={{ fontWeight: "bold" }}
                                                    value={t("GENERAL.total")}
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    disabled
                                                    style={{
                                                        backgroundColor:
                                                            state.totalPercentage > 100 ? "#f16b68" : "",
                                                        fontWeight: "bold"
                                                    }}
                                                    id={"totalValue"}
                                                    inputRef={estimatedCostRef}
                                                    className={classes.textField}
                                                    margin="normal"
                                                    name="headingValue"
                                                    value={state.total}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">R$</InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <TextField
                                                    disabled
                                                    className={classes.textField}
                                                    style={{ fontWeight: "bold" }}
                                                    value={state.totalPercentage}
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid item xs={2}></Grid>
                                        </Grid>
                                        <div className="lineBreak"></div>
                                    </div>
                                </div>
                            </div>
                            :
                            <p className={classes.Hint}>
                                {t("PHASES.hintNoAddendum")}
                            </p>
                        }
                    </Paper>
                    <Dialog
                        open={openState}
                        onClose={handleCloseModal}
                        aria-labelledby="form-dialog-title"
                        msg="mensagem"
                    >
                        <DialogTitle className={classes.actionsTitle} style={{ width: 'auto' }}>
                            Adicionar Saldo
                </DialogTitle>
                        <DialogContent>
                            Por favor, informe de qual dos projetos este saldo é proveniente.
              <Grid container item spacing={1} md={12} lg={12}>
                                <Grid item xs={10}>
                                    <TextField
                                        select
                                        id="PreviousBalanceCompanySelect"
                                        label="Origem do Saldo"
                                        name="PreviousBalanceCompanySelect"
                                        value={companySelect}
                                        className={classes.textField}
                                        onChange={handleChangeCompany}
                                        margin="normal"
                                    >
                                        {projectsSelect.map(project => (
                                            <MenuItem key={project.value} value={project.label}>
                                                {project.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Buttons
                                onClick={handleCloseModal}
                                tipo="BN-noback"
                                conteudo={t("GENERAL.cancel")}
                            />
                            <Buttons
                                onClick={addPreviousBalanceCompany}
                                tipo="BN-blue"
                                conteudo={t("GENERAL.add")}
                            />
                        </DialogActions>
                    </Dialog>
                </animated.div>
            )
    );
};

export default BudgetView;