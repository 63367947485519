import axios from 'axios';
import { useMutation } from 'react-query';
import Cookies from 'js-cookie';
import { useSnackbar } from 'hooks/UseSnackbar';
import { useTranslation } from 'react-i18next';
import { getManagersAndSendEmails } from 'services/Emails/General/generalEmailService';

export const useMutationEditUserPermissions = setMustRedirect => {
    const toast = useSnackbar();
    const [t] = useTranslation();

    const mutation = useMutation({
        mutationFn: async obj =>
            await axios.put(
                process.env.REACT_APP_SERVER +
                    `api/Admin/Users/${obj.get('id')}/Permissions`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            ),
        onSuccess: res => {
            toast.success(t('ADMIN.userUpdatedSuccess'));
            setMustRedirect(true);
            const data = res.data;

            const oldSystemRoles = data.oldSystemRoles;
            const newSystemRoles = data.newSystemRoles;
            let hasPermissionsChanged = false;

            newSystemRoles.forEach(module => {
                const oldModule = oldSystemRoles.find(
                    x => x.name === module.name
                );
                if (
                    !oldModule ||
                    (oldModule && oldModule.role.id !== module.role.id)
                ) {
                    hasPermissionsChanged = true;
                }
            });

            if (hasPermissionsChanged) {
                const emailData = {
                    collaboratorName: data.shortname,
                    collaboratorEmail: data.username,
                    collaboratorCpf: data.cpf,
                    collaboratorId: data.id,
                    modulesPermissions: newSystemRoles.map(module => {
                        return {
                            moduleName: module.name,
                            permissionName: module.role.name
                        };
                    })
                };

                getManagersAndSendEmails(
                    emailData,
                    'api/Admin/PermissionsUpdated',
                    'ADMIN_PERMISSIONS_UPDATED_EMAIL_OPTIONAL',
                    'adminManagers'
                );
            }

            if (
                data.newApprover.id !== data.oldApprover.id ||
                data.newSurrogate.id !== data.oldSurrogate.id
            ) {
                const emailData = {
                    collaboratorName: data.shortname,
                    collaboratorEmail: data.username,
                    collaboratorCpf: data.cpf,
                    collaboratorId: data.id,
                    newApprover: data.newApprover,
                    oldApprover: data.oldApprover,
                    newSurrogate: data.newSurrogate,
                    oldSurrogate: data.oldSurrogate
                };

                getManagersAndSendEmails(
                    emailData,
                    'api/Admin/ApproversUpdated',
                    'ADMIN_APPROVERS_UPDATED_EMAIL_OPTIONAL',
                    'adminManagers'
                );
            }
        },
        onError: err => {
            if (err.response.data.message && err.response.data.code !== 1000) {
                toast.error(err.response.data.message);
            } else {
                toast.error(t('ADMIN.userUpdatedError'));
            }
        },
        onSettled: () => {}
    });

    return mutation;
};
