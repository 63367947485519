import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CloseIcon from '@material-ui/icons/Close';
import Buttons from "components/buttons/Pressed";
import { useStyles } from "components/layout/CommonStyles";
import InventoryContext from "context/inventory/inventoryContext";
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const AdvancedSearchDialog = (props) => {
    const [t] = useTranslation();
    const classes = useStyles();
    const { open, setOpen, apply } = props;

    const inventoryContext = useContext(InventoryContext);
    const [categoriesArray, setCategoriesArray] = useState([]);

    const {
        getSoftwareCategories,
        softwareCategories,
    } = inventoryContext;

    const sortElement = (elemnts) => {
        elemnts.sort(function (a, b) {
            if (a.name === null) a.name = "";
            if (b.name === null) b.name = "";

            if (a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() >
                b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim()) {
                return 1;
            }
            if (a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() <
                b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim()) {
                return -1;
            }
            // a must be equal to b
            return 0;
        });
    }

    useEffect(() => {
        getSoftwareCategories();
    }, []);

    useEffect(() => {
        const categories = [...softwareCategories];
        sortElement(categories);

        var aux = {};
        var _categories = [];
        for (var i = 0; i < categories.length; i++) {
            var item = categories[i];
            aux.value = item.id;
            aux.label = item.name;
            _categories.push(aux);
            aux = {};
        }
        setCategoriesArray(_categories);
    }, [softwareCategories]);

    const [values, setValues] = useState({
        name: "",
        category: "",
        status: "",
        code: "",
        key: "",
        platform: "",
        type: "",
        responsible: "",
        machine: "",
        email: "",
    });

    const cleanFields = () => {
        setValues({
            ...values,
            name: "",
            category: "",
            status: "",
            code: "",
            key: "",
            platform: "",
            type: "",
            responsible: "",
            machine: "",
            email: "",
        });

        setDates({
            acquisitionDate: null,
            activationDate: null,
            validity: null,
        })
    }

    const handleClose = () => {
        setOpen(false);
        cleanFields();
    };

    const handleClean = () => {
        cleanFields();
        apply("", "", "", "", "", "", "", "", "", "", "", "", "", "", "")
    };

    const handleSubmit = () => {
        apply(values.name, values.category, values.status, values.code, values.key, values.email, values.machine, values.type,
            dates.validity, dates.acquisitionDate, dates.activationDate, values.platform, values.responsible
        )
        setOpen(false);
        cleanFields();
    };

    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });
    };

    const status = [
        {
            value: "AVAILABLE",
            label: "Disponível"
        },
        {
            value: "UNAVAILABLE",
            label: "Indisponível"
        },
        {
            value: "EXPIRED",
            label: "Expirada"
        },
    ];

    const typeArray = [
        {
            value: "ACQUIRED",
            label: "Adquirida"
        },
        {
            value: "RENEWABLE",
            label: "Renovável"
        },
    ];

    const [dates, setDates] = useState({
        acquisitionDate: null,
        activationDate: null,
        validity: null
    });

    const handleDates = name => e => {
        setDates({
            ...dates,
            [name]: e
        });
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            msg="mensagem"
            maxWidth="md"
        >
            <DialogTitle className={classes.actionsTitle} style={{ width: 'auto' }}>
                <span style={{ fontWeight: 'bold' }}>
                    {t("INVENTORY.advancedSearch").toLocaleUpperCase()}
                </span>
                <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <FormControl component="fieldset">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormLabel component="legend">
                                    {t("INVENTORY.textAdvancedSearch")}
                                </FormLabel>
                            </Grid>
                            <Grid item xs={4}>
                                <div className={classes.searchInventory}>
                                    <InputBase
                                        placeholder={t("INVENTORY.name")}
                                        value={values.name}
                                        onChange={handleChange("name")}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputSearch
                                        }}
                                        inputProps={{ "aria-label": "search" }}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className={classes.searchInventory}>
                                    <TextField
                                        select
                                        label={t("INVENTORY.category")}
                                        value={values.category}
                                        onChange={handleChange("category")}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputSearch
                                        }}
                                        inputProps={{ "aria-label": "search" }}
                                        style={{ width: "100%" }}
                                    >
                                        {categoriesArray.map(category => (
                                            <MenuItem key={category.value} value={category.label}>
                                                {category.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className={classes.searchInventory}>
                                    <TextField
                                        select
                                        label={t("INVENTORY.status")}
                                        value={values.status}
                                        onChange={handleChange("status")}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputSearch
                                        }}
                                        inputProps={{ "aria-label": "search" }}
                                        style={{ width: "100%" }}
                                    >
                                        {status.map(category => (
                                            <MenuItem key={category.value} value={category.label}>
                                                {category.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div style={{ height: "1px", backgroundColor: "#F5F6FA" }}></div>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.titleNewEquipment}>
                                    {t("INVENTORY.advanced")}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <div className={classes.searchInventory}>
                                    <InputBase
                                        placeholder={t("INVENTORY.codeLicense")}
                                        value={values.code}
                                        onChange={handleChange("code")}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputSearch
                                        }}
                                        inputProps={{ "aria-label": "search" }}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className={classes.searchInventory}>
                                    <InputBase
                                        placeholder={t("INVENTORY.key")}
                                        value={values.key}
                                        onChange={handleChange("key")}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputSearch
                                        }}
                                        inputProps={{ "aria-label": "search" }}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className={classes.searchInventory}>
                                    <InputBase
                                        placeholder={t("INVENTORY.email").replace('*', '')}
                                        value={values.email}
                                        onChange={handleChange("email")}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputSearch
                                        }}
                                        inputProps={{ "aria-label": "search" }}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className={classes.searchInventory}>
                                    <InputBase
                                        placeholder={t("INVENTORY.machine")}
                                        value={values.machine}
                                        onChange={handleChange("machine")}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputSearch
                                        }}
                                        inputProps={{ "aria-label": "search" }}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className={classes.searchInventory}>
                                    <TextField
                                        select
                                        label={t("INVENTORY.type")}
                                        value={values.type}
                                        onChange={handleChange("type")}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputSearch
                                        }}
                                        inputProps={{ "aria-label": "search" }}
                                        style={{ width: "100%" }}
                                    >
                                        {typeArray.map(type => (
                                            <MenuItem key={type.value} value={type.value}>
                                                {type.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            </Grid>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid item xs={4}>
                                    <div className={classes.searchInventory}>
                                        <KeyboardDatePicker
                                            className={classes.textField}
                                            disableToolbar
                                            autoOk={true}
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            id="validity"
                                            invalidDateMessage=""
                                            label={t("INVENTORY.valitidy")}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date"
                                            }}
                                            value={dates.validity}
                                            onChange={handleDates("validity")}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className={classes.searchInventory}>
                                        <KeyboardDatePicker
                                            className={classes.textField}
                                            disableToolbar
                                            autoOk={true}
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            id="acquisitionDate"
                                            invalidDateMessage=""
                                            label={t("INVENTORY.dateAcquisition")}
                                            //onFocus={clearRequiredMessage}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date"
                                            }}
                                            value={dates.acquisitionDate}
                                            onChange={handleDates("acquisitionDate")}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className={classes.searchInventory}>
                                        <KeyboardDatePicker
                                            className={classes.textField}
                                            disableToolbar
                                            autoOk={true}
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            id="activationDate"
                                            invalidDateMessage=""
                                            label={t("INVENTORY.activationData")}
                                            // onFocus={clearRequiredMessage}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date"
                                            }}
                                            value={dates.activationDate}
                                            onChange={handleDates("activationDate")}
                                        />
                                    </div>
                                </Grid>
                            </MuiPickersUtilsProvider>
                            <Grid item xs={4}>
                                <div className={classes.searchInventory}>
                                    <InputBase
                                        placeholder={t("INVENTORY.platform")}
                                        value={values.platform}
                                        onChange={handleChange("platform")}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputSearch
                                        }}
                                        inputProps={{ "aria-label": "search" }}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className={classes.searchInventory}>
                                    <InputBase
                                        placeholder={t("INVENTORY.responsible")}
                                        value={values.responsible}
                                        onChange={handleChange("responsible")}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputSearch
                                        }}
                                        inputProps={{ "aria-label": "search" }}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </FormControl>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Buttons
                    onClick={handleClose}
                    tipo="BN-noback"
                    conteudo={t("GENERAL.cancel").toLocaleUpperCase()}
                />
                <Buttons
                    onClick={handleClean}
                    tipo="BN-noback"
                    conteudo={t("GENERAL.clear").toLocaleUpperCase()}
                />
                <Buttons
                    onClick={handleSubmit}
                    tipo="BN-blue"
                    conteudo={t("GENERAL.apply").toLocaleUpperCase()}
                />
            </DialogActions>
        </Dialog>
    );
}
export default AdvancedSearchDialog;