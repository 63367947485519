import * as React from 'react';
import InfoIcon from '@material-ui/icons/Info';
import { tooltipStyles } from './Styles';
import Typography from 'components/typography/Typography';

const NotificationTip = ({
    text,
    textColor,
    iconColor,
    fontSize,
    ...props
}) => {
    const styles = tooltipStyles();

    return (
        <div className={styles.NotificationTipContainer}>
            <InfoIcon color={iconColor} style={{ marginRight: '8px' }} />
            <Typography
                variant={'RobotoRegular'}
                fontSize={fontSize}
                textColor={textColor}
            >
                {text}
            </Typography>
        </div>
    );
};

export default NotificationTip;
