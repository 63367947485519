import React, { useContext, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { useStyles } from "components/layout/CommonStyles";
import { useTranslation } from "react-i18next";
import CloseIcon from '@material-ui/icons/Close';
import Buttons from "components/buttons/Pressed";
import Grid from "@material-ui/core/Grid";
import Input from "components/inputs/Input";

import SettingsContext from "context/hrModule/settings/settingsContext";


const DialogActivityCategories = props => {
    const classes = useStyles();
    const [t] = useTranslation();

    const settingsContext = useContext(SettingsContext);

    const {
        addActivityCategories,
        updateActivityCategories
    } = settingsContext


    const [values, setValues] = useState({
        id: "",
        name: "",
        description: ""        
    });

    const [requiredName, setRequiredName] = useState(false);
    const [nameErrorMessage, setNameErrorMessage] = useState("");

    const { open, setOpen, context, activityCategories, fetchData, viewValues } = props;

    const setData = () => {
        setValues({
            id: viewValues.id ? viewValues.id : "",
            name: viewValues.name ? viewValues.name : "-",
            description: viewValues.description ? viewValues.description : "-",
            activitiesQnt: viewValues.activitiesQnt ? viewValues.activitiesQnt : "0"
        });
    }

    useEffect(() => {
        if (viewValues) {
            setData()
        }
    }, [context !== "new"]);

    const handleClose = () => {
        setOpen(false);
    }

    const handleChange = (name, value) => {        
        let val = value;
        setValues({ ...values, [name]: val })
    }

    const verifySubmit = () => {
        let res = true;

        if (activityCategories.map(x => x.id !== values.id  && !x.deleted ? x.name.toLowerCase().trim() : null).includes(values.name.toLowerCase().trim())) {
            res = false;
            setRequiredName(true);
            setNameErrorMessage(t("HR.activityCategoryAlreadyInUse"))
        }

        if (!values.name.trim()) {
            res = false;
            setRequiredName(true);
            setNameErrorMessage(t("GENERAL.requiredField"));
        }
        return res;
    }


    const handleSubmit = () => {
        const canSubmit = verifySubmit();
        if (canSubmit) {
            let formData = new FormData();

            formData.append("activityCategories.id", values.id ? values.id : 0)
            formData.append("activityCategories.name", values.name ? values.name : "");
            formData.append("activityCategories.description", values.description ? values.description : "");

            if( context === "new"){
                addActivityCategories(formData, fetchData);
            }else{
                updateActivityCategories(formData, fetchData);
            }
            setOpen(false);
        }
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle className={classes.Title}>
                    <b>{`${context === "new" ? t("GENERAL.add") : context === "edit" ? t("GENERAL.edit") : t("GENERAL.view")} ${t("HR.activityCategory")}`}</b>
                    <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid container item spacing={2} md={12} lg={12}>

                        <Grid item xs={12} sm={8}>
                            <Input
                                required
                                context={context}
                                label={"GENERAL.categoryName"}
                                value={values.name}
                                valueName={"name"}
                                handleChange={handleChange}
                                maxLength={70}
                                error={requiredName}
                                helperText={requiredName && nameErrorMessage}
                                setRequired={setRequiredName}
                            />
                        </Grid>
                        {context !== "new" &&
                            <Grid item xs={12} sm={4}>
                                <Input
                                    disabled
                                    label={"HR.activitiesQnt"}
                                    value={values.activitiesQnt}
                                    valueName={"activitiesQnt"}
                                />
                            </Grid>
                         }

                        <Grid item xs={12} sm={12}>
                            <Input
                                context={context}
                                label={"GENERAL.description"}
                                value={values.description}
                                valueName={"description"}
                                rows={5}
                                maxLength={200}
                                handleChange={handleChange}
                            />
                        </Grid>

                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Buttons
                        onClick={handleClose}
                        tipo="BN-noback"
                        conteudo={context === "view" ? t("GENERAL.close").toUpperCase() : t("GENERAL.cancel").toUpperCase()}
                    />
                    {
                        context !== "view" ?
                            <Buttons
                                onClick={handleSubmit}
                                tipo="BN-blue"
                                conteudo={context === "new" ? t("GENERAL.add").toUpperCase() : t("GENERAL.update").toUpperCase()}
                            /> : null
                    }
                </DialogActions>
            </Dialog >            
        </>
    )
}

export default DialogActivityCategories;