/* eslint-disable */
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import Paper from "@material-ui/core/Paper";
import Dissatisfied from "@material-ui/icons/SentimentVeryDissatisfied";
import Footer from "components/layout/Footer";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";
import MySnackbarContentWrapper from "components/layout/Message";
import VacationsContext from "context/hrModule/vacations/vacationsContext";
import AllowancesContext from "context/allowance/allowanceContext";
import Transition from "components/layout/transition/Transition";
import Breadcrumb from "components/layout/breadcrumb/Breadcrumb";
import CollaboratorsVacationsTable from "./CollaboratorsVacationsTable";
import { GetGeneralPermissionForAction } from "utils/permissions/GetGeneralPermissionForAction";
import { Screen, Allow } from "global/constants";
import VacationsBalancePDFReport from "./VacationsPDFReport/VacationsBalancePDFReport";
import { useSnackbar } from "../../../../hooks/UseSnackbar";
import ExportButton from "../../../../components/buttons/ExportButton/ExportButton";
import { getVacationsBalanceReport } from "../../../../services/WebApi/HR/VacationsReport";
import { getAllRuleSets } from "../../../../services/WebApi/HR/Ruleset";
import MultiFilter, {
    FilterType,
} from "../../../../components/MultiFilter/MultiFilter";
import { generatePdf } from "components/Pdf/generatePdf";

const CollaboratorsVacations = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const vacationsContext = useContext(VacationsContext);
    const allowanceContext = useContext(AllowancesContext);
    const toast = useSnackbar();
    const [filterData, setFilterData] = useState([]);
    const [canClick, setCanClick] = useState(false);
    const [filterRuleSetOptions, setFilterRuleSetOptions] = useState([]);
    const [reportData, setReportData] = useState([]);
    const [reportLoading, setReportLoading] = useState(false);
    const [notFound, setNotFound] = useState("");
    const [filtersUsed, setFiltersUsed] = useState({});

    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }

    const [snackBar, setSnackBar] = useState({
        open: false,
        kind: "",
        content: "",
    });

    const fetchReportData = () => {
        try {
            getVacationsBalanceReport(filtersUsed.rulesetId ?? "")
                .then(resp => {
                    setReportData(resp.data);
                })
                .catch(error => {
                    console.log(error);
                    toast.error(t("GENERAL.errorFetch"));
                });
        } catch (error) {
            console.log(error);
        }
    };

    const fetchRulesets = () => {
        try {
            getAllRuleSets()
                .then(resp => {
                    let filterOptions = [
                        {
                            value: "all",
                            label: "Todos",
                        },
                    ];
                    resp.data.map(ruleSet => {
                        if (
                            ruleSet.name.toLowerCase() === "clt" ||
                            ruleSet.name.toLowerCase() === "estagiário"
                        )
                            filterOptions.push({
                                value: ruleSet.id,
                                label: ruleSet.name,
                            });
                    });
                    setFilterRuleSetOptions(filterOptions);
                })
                .catch(() => {
                    toast.error(t("GENERAL.errorFetch"));
                });
        } catch (error) {
            console.log(error);
        }
    };

    const {
        loading,
        hasChanged,
        responseType,
        response,
        successiveActions,
        setHasChanged,
        setLoading,
        setLoadingFalse,
        collaboratorsVacations,
        getCollaboratorsVacations,
        getCollaboratorsVacationsApprover,
    } = vacationsContext;

    const {
        allowanceResponse,
        allowanceResponseType,
        resetAllowanceMessage,
        messageHasChanged,
    } = allowanceContext;

    const handleSnack = () => {
        setSnackBar({ ...snackBar, open: true });
        setHasChanged();
    };
    const handleSnackClose = () => {
        setSnackBar({ ...snackBar, open: false });
        resetAllowanceMessage();
    };

    async function fetchData() {
        await setLoading();
        if (
            GetGeneralPermissionForAction(
                Screen.COLLABORATORS_VACATIONS,
                Allow.IS_MANAGER
            )
        ) {
            await getCollaboratorsVacationsApprover(auth.id);
        } else {
            await getCollaboratorsVacations();
        }
        await setLoadingFalse();
    }

    async function handleGenerateReport() {
        return generatePdf(
            <VacationsBalancePDFReport data={reportData} />,
            setReportLoading,
            blob => {
                const url = URL.createObjectURL(blob);
                window.open(url, "_blank");
            },
            error => {
                console.log(error);
                toast.error(t("HR.reportGenerationError"));
            }
        );
    }

    useEffect(() => {
        if (allowanceResponseType === "ERROR" && messageHasChanged) {
            handleSnack({ kind: "error", content: allowanceResponse });
        }
        if (responseType === "SUCCESS" && hasChanged > successiveActions) {
            handleSnack({ kind: "success", content: response });
        }
        if (responseType === "ERROR" && hasChanged > successiveActions) {
            handleSnack({ kind: "error", content: response });
        }

        return () => { };
    }, [
        responseType,
        response,
        successiveActions,
        allowanceResponseType,
        allowanceResponse,
    ]);

    useEffect(() => {
        fetchData();
        fetchRulesets();
    }, []);

    useEffect(() => {
        if (collaboratorsVacations && collaboratorsVacations.length) {
            setFilterData(collaboratorsVacations);
        }
    }, [collaboratorsVacations]);

    useEffect(() => {
        fetchReportData();
    }, [filtersUsed]);

    return (
        <React.Fragment>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={snackBar.open}
                onClose={handleSnackClose}
                TransitionComponent={Slide}
                transitionDuration={{ enter: 500, exit: 500 }}
                ContentProps={{
                    "aria-describedby": "message-id",
                }}
                autoHideDuration={2000}
            >
                <MySnackbarContentWrapper
                    onClose={handleSnackClose}
                    variant={
                        responseType !== ""
                            ? responseType.toLowerCase()
                            : allowanceResponseType.toLowerCase()
                    }
                    message={
                        <span id="message-id">
                            {response !== "" ? response : allowanceResponse}
                        </span>
                    }
                />
            </Snackbar>

            <Transition loading={loading} newDesign={true}>
                <div className="headerNewDesign">
                    <Breadcrumb
                        newDesign={true}
                        routes={[
                            {
                                label: t("HR.humanResource"),
                                to: "/HumanResources",
                            },
                            { label: t("HR.vacations") },
                            { label: t("HR.collaboratorsVacations") },
                        ]}
                    />
                    <div className="container-headerNewDesign">
                        <h1 className={classes.titleNewDesign}>
                            {t("HR.collaboratorsVacations")}
                        </h1>
                    </div>
                </div>
                <div className="containerNewDesign">
                    <Paper>
                        <div className="cabecalho">
                            {t("HR.collaborators").toUpperCase()}
                            <ExportButton
                                isLoading={reportLoading}
                                onClick={handleGenerateReport}
                                title={t(
                                    "HR.exportPendingVacationToReport"
                                ).toUpperCase()}
                            />
                        </div>
                        <React.Fragment>
                            <MultiFilter
                                columns={[
                                    {
                                        column: "name",
                                        filterType: FilterType.TEXT,
                                        placeholder: t("GENERAL.collaborator"),
                                    },
                                    {
                                        column: "rulesetId",
                                        filterType: FilterType.SIMPLE_SELECT,
                                        placeholder: t("HR.contractType"),
                                        options: filterRuleSetOptions,
                                    },
                                ]}
                                setFiltersUsed={setFiltersUsed} // Temp way to outside component have access to filter values
                                setNotFound={setNotFound} // Temp way to outside component have access to filter values
                                rowsData={collaboratorsVacations}
                                setRowsData={setFilterData}
                            />

                            <CollaboratorsVacationsTable
                                rows={filterData}
                                toFirstPage={canClick}
                                headers={[
                                    "id",
                                    "name",
                                    "ruleset",
                                    "holidayDate",
                                    // "remunerationBalance",
                                    "enjoymentBalance",
                                ]}
                                visibleHeaders={[
                                    "Colaborador",
                                    "Regime",
                                    "Data Base Para Férias",
                                    // "Saldo de Remuneração",
                                    "Saldo de Gozo",
                                    "",
                                ]}
                                sortedFields={[
                                    "name",
                                    "ruleset",
                                    "holidayDate",
                                    // "remunerationBalance",
                                    "enjoymentBalance",
                                ]}
                                mainField="name"
                                fetchData={fetchData}
                            />
                            {collaboratorsVacations.length === 0 && (
                                <div className={classes.noParticipantsMessage}>
                                    <h2>{t("HR.noCollaboratorsToBeShown")}</h2>
                                    <h4>
                                        {t(
                                            "HR.collaboratorsVacationsShouldBeListedHere"
                                        )}
                                    </h4>
                                </div>
                            )}
                            {filterData.length === 0 && (
                                <div className={classes.noParticipantsMessage}>
                                    <div>
                                        <Dissatisfied
                                            className={
                                                classes.notFoundIconStyle
                                            }
                                        />
                                    </div>
                                    <h2>{t("GENERAL.noResultsFound")}</h2>
                                    <h5>{`Sua busca por "${notFound}" não retornou resultados.`}</h5>
                                </div>
                            )}
                        </React.Fragment>
                    </Paper>
                </div>
                <Footer />
            </Transition>
        </React.Fragment>
    );
};

export default CollaboratorsVacations;
