import axios from 'axios';
import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT, HR } from '../../types';
import vacationsContext from './vacationsContext';
import vacationsReducer from './vacationsReducer';
import history from '../../../history';
import Cookies from 'js-cookie';

const VacationsState = props => {
    const initialState = {
        message: '',
        response: '',
        responseType: '',
        hasChanged: 0,
        successiveActions: 0,
        loading: false,
        collaboratorsVacations: [],
        collaboratorVacation: {},
        vacationCalendar: {}
    };

    const [t] = useTranslation();
    const [state, dispatch] = useReducer(vacationsReducer, initialState);
    const setLoading = () => dispatch({ type: DEFAULT.SET_LOADING });
    const setLoadingFalse = () => dispatch({ type: DEFAULT.SET_LOADING_FALSE });

    const resetMessage = () => dispatch({ type: DEFAULT.RESET_MESSAGE });
    const setHasChanged = () => dispatch({ type: DEFAULT.SET_HAS_CHANGED });

    const headers = {
        'Content-type': 'application/json',
        Authorization:
            'bearer ' +
            (Cookies.get('auth-token') ? Cookies.get('auth-token') : '')
    };

    const getCollaboratorsVacations = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/CollaboratorsVacations`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: HR.GET_COLLABORATORS_VACATIONS,
            payload: res.data
        });
    };

    const getCollaboratorsVacationsApprover = async id => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER +
                `api/CollaboratorsVacations/approver/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: HR.GET_COLLABORATORS_VACATIONS,
            payload: res.data
        });
    };

    const getCollaboratorVacation = async id => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/CollaboratorsVacations/${id}`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: HR.GET_COLLABORATOR_VACATION,
            payload: res.data
        });
    };

    const addCollaboratorVacation = async (obj, fetchData) => {
        axios
            .post(
                process.env.REACT_APP_SERVER + 'api/CollaboratorsVacations',
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                if (response.status.toString().match(/20[0-6]/)) {
                    dispatch({
                        type: HR.CREATE_COLLABORATOR_VACATION_PERIOD,
                        payload: t('HR.vacationUtilizationCreateSuccess'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: HR.CREATE_COLLABORATOR_VACATION_PERIOD,
                        payload:
                            response.status === 500
                                ? t('HR.vacationUtilizationCreateError')
                                : response.data.message,
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: HR.CREATE_COLLABORATOR_VACATION_PERIOD,
                    payload:
                        error.status === 500
                            ? t('HR.vacationUtilizationCreateError')
                            : error.response.data.message,
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                fetchData();
            });
    };

    const updateCollaboratorVacation = (obj, fetchData) => {
        axios
            .put(
                process.env.REACT_APP_SERVER + 'api/CollaboratorsVacations',
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                if (response.status.toString().match(/20[0-6]/)) {
                    dispatch({
                        type: HR.UPDATE_COLLABORATOR_VACATION_PERIOD,
                        payload: t('HR.vacationUtilizationUpdateSuccess'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: HR.UPDATE_COLLABORATOR_VACATION_PERIOD,
                        payload:
                            response.status === 500
                                ? t('HR.vacationUtilizationUpdateError')
                                : response.data.message,
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: HR.UPDATE_COLLABORATOR_VACATION_PERIOD,
                    payload:
                        error.status === 500
                            ? t('HR.vacationUtilizationUpdateError')
                            : error.data.message,
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                fetchData();
            });
    };

    const deleteCollaboratorVacation = (
        id,
        formData,
        fetchData,
        handleDelete
    ) => {
        let obj = formData;
        axios
            .put(
                process.env.REACT_APP_SERVER +
                    `api/CollaboratorsVacations/disable/${id}`,
                obj,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization:
                            'bearer ' +
                            (Cookies.get('auth-token')
                                ? Cookies.get('auth-token')
                                : '')
                    }
                }
            )
            .then(response => {
                if (response.status.toString().match(/20[0-6]/)) {
                    dispatch({
                        type: HR.DELETE_COLLABORATOR_VACATION_PERIOD,
                        payload: t('HR.vacationUtilizationDeleteSuccess'),
                        responseType: 'SUCCESS',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                } else {
                    dispatch({
                        type: HR.DELETE_COLLABORATOR_VACATION_PERIOD,
                        payload:
                            response.status === 500
                                ? t('HR.vacationUtilizationDeleteError')
                                : response.data.message,
                        responseType: 'ERROR',
                        successiveActions: state.successiveActions + 1,
                        hasChanged: state.successiveActions + 2
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: HR.DELETE_COLLABORATOR_VACATION_PERIOD,
                    payload:
                        error.status === 500
                            ? t('HR.vacationUtilizationDeleteError')
                            : error.data,
                    responseType: 'ERROR',
                    successiveActions: state.successiveActions + 1,
                    hasChanged: state.successiveActions + 2
                });
            })
            .finally(() => {
                handleDelete();
                fetchData();
            });
    };

    const getVacationCalendar = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER + `api/VacationCalendar`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            }
        );
        dispatch({
            type: HR.GET_VACATION_CALENDAR,
            payload: res.data
        });
    };

    return (
        <vacationsContext.Provider
            value={{
                loading: state.loading,
                message: state.message,
                hasChanged: state.hasChanged,
                response: state.response,
                responseType: state.responseType,
                successiveActions: state.successiveActions,
                collaboratorsVacations: state.collaboratorsVacations,
                collaboratorVacation: state.collaboratorVacation,
                vacationCalendar: state.vacationCalendar,
                setLoading,
                setLoadingFalse,
                resetMessage,
                setHasChanged,
                getCollaboratorsVacations,
                getCollaboratorVacation,
                addCollaboratorVacation,
                updateCollaboratorVacation,
                deleteCollaboratorVacation,
                getVacationCalendar,
                getCollaboratorsVacationsApprover
            }}
        >
            {props.children}
        </vacationsContext.Provider>
    );
};

export default VacationsState;
