import MomentUtils from "@date-io/moment";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useStyles } from "components/layout/CommonStyles";
import React from "react";

const ViewQuota = props => {
    const classes = useStyles();
    const {
        lastQuota,
        quotaNumber,
        quotavalue,
        quotaDate
    } = props;

    return (
        <div className={classes.bodyForm}>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <TextField
                        disabled
                        id={"numberOfQuota" + lastQuota}
                        className={classes.textField}
                        name={quotaNumber}
                        margin="normal"
                        value={quotaNumber}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        disabled
                        id={"quotaValue" + lastQuota}
                        className={classes.textField}
                        margin="normal"
                        name="quotavalue"
                        value={quotavalue}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">R$</InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <MuiPickersUtilsProvider locale="pt" utils={MomentUtils}>
                        <DatePicker
                            disabled
                            fullWidth
                            id={"quotaDate" + lastQuota}
                            variant="inline"
                            openTo="year"
                            name="yearBase"
                            views={["year", "month"]}
                            margin="normal"
                            autoOk={true}
                            value={quotaDate}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={1} style={{ marginTop: "8px" }}>
                </Grid>
                <div className="lineBreak"></div>
            </Grid>
        </div>
    );
};

export default ViewQuota;