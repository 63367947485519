import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import { useStyles } from "components/layout/CommonStyles";
import ProjectsContext from "context/projects/projectsContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Buttons from "../../buttons/Pressed";
import axios from "axios";
import history from "../../../history";
import CloseIcon from '@material-ui/icons/Close';
import Cookies from "js-cookie";

const PhasesTableActions = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const { phaseId, startDate, mode, phaseName, allAllowances } = props;
    const [open, setOpen] = useState(false);
    const projectsContext = useContext(ProjectsContext);
    const { deletePhase, response, responseType, setErrorMessageInProgressPhase } = projectsContext;
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const convertDate = date => {
        var year = parseInt(date.slice(6));
        var month = parseInt(date.slice(3, 5)) - 1;
        var day = parseInt(date.slice(0, 2));

        return new Date(year, month, day);
    };

    const handleDelete = () => {
        if (convertDate(startDate) > new Date()) {
            deletePhase(phaseId);
            setOpen(false);
        } else {
            setOpen(false);
            setErrorMessageInProgressPhase("PHASES.deleteFailure");
        }
    };

    useEffect(() => {
        return () => { };
    }, [response, responseType]);

    const getPermission = (screen, allowance) => {
        for (let i = 0; i < allAllowances.length; i++) {
            if (allAllowances[i].resource === screen) {
                return allAllowances[i]["standardauthorizationoption"][allowance]
            }
        }
    }

    const verifyEdit = () => {
        axios.get(process.env.REACT_APP_SERVER + `api/projectPhases/edit/${phaseId}`, {
            headers: {
                "Content-type": "application/json",
                "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
            }
        })
            .then(res => {
                if (res.statusText === "OK") {
                    history.push(`/execution/project/${mode}/projectPhase/${phaseId}/${t("GENERAL.editMode")}`);
                } else {
                    setErrorMessageInProgressPhase("PHASES.notFound");
                }
            })
            .catch(error => {
                setErrorMessageInProgressPhase("PHASES.notFound");
            });
    }

    const verifyView = () => {
        axios.get(process.env.REACT_APP_SERVER + `api/projectPhases/edit/${phaseId}`, {
            headers: {
                "Content-type": "application/json",
                "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
            }
        })
            .then(res => {
                if (res.statusText === "OK") {
                    history.push(`/execution/project/${mode}/projectPhase/${phaseId}/${t("GENERAL.viewMode")}`);
                } else {
                    setErrorMessageInProgressPhase("PHASES.notFound");
                }
            })
            .catch(error => {
                setErrorMessageInProgressPhase("PHASES.notFound");
            });
    }

    return (
        <div>
            {allAllowances.length > 0 && getPermission("PHASES", "allowRead") &&
                <Tooltip title='Visualizar' aria-label='details' placement='top'>
                    <IconButton className={classes.actionsIcons} onClick={verifyView}>
                        <Visibility />
                    </IconButton>
                </Tooltip>
            }
            {mode === t("GENERAL.editMode") && allAllowances.length > 0 && getPermission("PHASES", "allowUpdate") &&
                <Tooltip title='Editar' aria-label='details' placement='top'>
                    <IconButton className={classes.actionsIcons} onClick={verifyEdit}>
                        <Edit />
                    </IconButton>
                </Tooltip>
            }
            {mode === t("GENERAL.editMode") && allAllowances.length > 0 && getPermission("PHASES", "allowDelete") &&
                <Tooltip title="Deletar" aria-label="delete" placement="top">
                    <IconButton className={classes.actionsIcons} onClick={handleClickOpen}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            }
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                msg="mensagem"
            >
                <DialogTitle className={classes.actionsTitle}>
                    {t("GENERAL.notification")}
                    <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {`Você deseja realmente deletar essa etapa ${phaseName}?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Buttons
                        onClick={handleClose}
                        tipo="BN-noback"
                        conteudo={t("GENERAL.cancel")}
                    />
                    <Buttons
                        onClick={handleDelete}
                        tipo="BN-blue"
                        conteudo={t("GENERAL.delete")}
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default PhasesTableActions;