import React, { useState } from "react";

import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';

import { useTranslation } from "react-i18next";
import { screenStyles } from "./Styles";
import ScreensTable from "./ScreensTable";
import NotificationsTable from "./NotificationsTable";


const ProfileManagementTabPanel = props => {
    const styles = screenStyles();
    const [t] = useTranslation();
    const [value, setValue] = useState(0);

    const { context, moduleName, isManager, rows , setRows, rowsNotifications , setRowsNotifications } = props;

    function TabPanel(props) {
        const { children, value, index } = props;

        return (
            <div>
                {value === index && children}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event ,newValue) => {
        setValue(newValue);
    };

    const tabContext = () => {
        if (value === 0) {
                return <>
                <ScreensTable context={context} moduleName={moduleName} isManager={isManager} rows={rows} setRows={setRows} />
                {moduleName === "Gestão de Pessoas" &&
                    <ScreensTable context={context} moduleName={t("HR.physicalClockRecordInternScholar")} isManager={isManager} rows={rows} setRows={setRows} tab={"PHYSICAL"}/>
                }
                </>
        }
        if (value === 1) {
            return <NotificationsTable context={context} moduleName={moduleName} isManager={isManager} rows={rowsNotifications} setRows={setRowsNotifications} />
        }
    }

    return (
        <React.Fragment>
                    <AppBar position="static" style={{backgroundColor: "white", boxShadow: "none", transform: "none" }}>
                        <Tabs selectionFollowsFocus className={styles.tabs} indicatorColor="primary" textColor="primary" value={value} onChange={handleChange} >
                            <Tab style={{ fontWeight: "bold" }} label={t("HR.screens").toUpperCase()} {...a11yProps(0)} />
                            <Tab style={{ fontWeight: "bold" }} label={t("HR.emailNotification").toUpperCase()} {...a11yProps(1)} />
                        </Tabs>
                    </AppBar>

                    {tabContext()}
        </React.Fragment>
    )
};

export default ProfileManagementTabPanel;
