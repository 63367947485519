import { makeStyles } from '@material-ui/core/styles';

export const screenStyles = makeStyles(theme => ({
    smallMargin: {
        margin: '0px 8px',
        lineHeight: '22px'
    },
    myCredit: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        height: '100%'
    },
    myCreditTooltipWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& > :first-child': {
            marginRight: '8px'
        }
    },
    dashboardbuttonsWrapper: {
        display: 'flex',
        margin: '24px 0px 16px 0px',
        '& > :first-child': {
            marginRight: '8px'
        }
    },
    labelContainer: {
        width: 'auto',
        padding: 0
    },
    iconLabelWrapper: {
        flexDirection: 'row-reverse'
    },
    iconLabel: {
        marginLeft: '12px'
    },
    badgeWrapper: {
        display: 'flex',
        justifyContent: 'center'
    },
    dialogViewBillAvatar: {
        border: '2px solid #2c3e51',
        width: '32px',
        height: '32px'
    }
}));
