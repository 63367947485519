import React, { useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import Buttons from 'components/buttons/Pressed'
import Tooltip from '@material-ui/core/Tooltip'
import Visibility from '@material-ui/icons/Visibility'
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Link from 'react-router-dom/Link'
import { useTranslation } from 'react-i18next'
import { useStyles } from 'components/layout/CommonStyles'
import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction'
import { Screen, Allow } from 'global/constants'
import { screenStyles } from './Styles'

const CollaboratorsJustificationsTableActions = props => {
    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'))
    }
    const styles = screenStyles()
    const classes = useStyles()
    const [t] = useTranslation()
    const { item, submit } = props

    return (
        <React.Fragment>

        { item.status === "PENDING" &&
        <div style={{ padding: '8px' }}>
                    <Link to={`/CollaboratorsJustifications/${item.id}/evaluate`}>
                        <Buttons
                            tipo="BN-blue"
                            conteudo="Avaliar"
                            style={{
                                width: '200px',
                                height: '40px',
                                opacity: '1',
                                color: 'white'
                            }}
                        />
                    </Link>
            </div>
}

            <Link to={`/CollaboratorsJustifications/${item.id}`}>
                <Tooltip title={t("GENERAL.view")} aria-label="details" placement="top">
                        <IconButton className={classes.iconsTableActions} >
                            <Visibility />
                        </IconButton>
                </Tooltip>
            </Link>
        </React.Fragment>
    )
}

export default CollaboratorsJustificationsTableActions
