import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Buttons from "components/buttons/Pressed";
import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";
import Input from "components/inputs/Input";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Button } from "@material-ui/core";

const DialogReqApprove = (props) => {
    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }

    const [t] = useTranslation();
    const classes = useStyles();
    const {
        open,
        setOpen,
        item,
        usersArray,
        externalUsersArray,
        statusArray,
        itensArray
    } = props;

    const [values, setValues] = useState({
        alterResponsible: "",
        newResponsibleName: "",
        newMachineName: "",
        newMachineId: "",
        returned: "",
        radioNewStatus: "AVAILABLE",
        radioResponsibleType: "INTERNAL",
        radioReturn: "YES",
        unavaliableStatusId: "",
        unavaliableStatusName: ""
    });

    const clear = () => {
        setValues({
            ...values,
            alterResponsible: "",
            newResponsibleName: "",
            newMachineName: "",
            newMachineId: "",
            newPlace: "",
            newPlaceName: "",
            returned: "",
            radioNewStatus: "AVAILABLE",
            radioResponsibleType: "INTERNAL",
            radioReturn: "YES",
            unavaliableStatusId: "",
            unavaliableStatusName: ""
        });
    }
    const [requiredFieldJustification, setRequiredFieldJustification] = useState(false);
    const [requiredFieldResponsible, setRequiredFieldResponsible] = useState(false);

    const handleClose = () => {
        setOpen({ open: false, obj: [] });
        setRequiredFieldJustification(false);
        clear();
    };

    const handleSubmit = () => {
        const canSubmit = verifyCanSubmit();
        if (canSubmit) {
            props.setReturnSoftware(values)
            setOpen(false);
            clear();
        }
    };

    const verifyCanSubmit = () => {
        let res = true;
        if (!values.alterResponsible && values.radioReturn === "YES") {
            res = false;
            setRequiredFieldResponsible(true);
        }
        return res;
    };

    const handleChange = (name, value) => {
        if (name === "alterResponsible") {
            setValues({ ...values, [name]: value, newResponsibleName: usersArray.filter(ele => String(ele.value) === String(value))[0].label });
            setRequiredFieldResponsible(false);
        } else if (name === "newMachineId") {
            setValues({ ...values, [name]: value, newMachineName: itensArray.filter(ele => String(ele.value) === String(value))[0].label });
        }
        else if (name === "alterResponsible" && values.radioResponsibleType === "EXTERNAL") {
            setValues({ ...values, [name]: value, newResponsibleName: externalUsersArray.filter(ele => String(ele.value) === String(value))[0].label });
        }
        else {
            setValues({ ...values, [name]: value });
        }
    };

    function getkeyOrEmailList(row) {
        const response = [];
        if (row["key"])
            response.push(row["key"]);
        if (row["email"])
            response.push(row["email"]);
        return response;
    }

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                aria-labelledby="form-dialog-title"
                msg="mensagem"
            >
                <DialogTitle className={classes.actionsTitle}>
                    <span style={{ fontWeight: "bold" }}>
                        {t("INVENTORY.alterAllocation")}
                    </span>
                    <IconButton
                        style={{ float: "right", marginTop: -10, marginRight: -17 }}
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Grid container >
                            <Grid item xs={12} sm={12}>
                                <b>{t("INVENTORY.software").toUpperCase()}</b>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                {item && item.software}
                            </Grid>
                        </Grid>
                        <Grid container style={{ paddingTop: "20px" }}>
                            <Grid item xs={12} sm={12}>
                                <b>{t("INVENTORY.keyOrEmail").toUpperCase()}</b>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                {item && getkeyOrEmailList(item)}
                            </Grid>
                        </Grid>
                        <Grid container style={{ paddingTop: "20px" }}>
                            <Grid item xs={12} sm={12}>
                                <b>{t("INVENTORY.responsibleType").toUpperCase()}</b>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <RadioGroup row aria-label="position"
                                    name="position"
                                    defaultValue="top"
                                    value={values.radioResponsibleType}
                                    onChange={(ev) => setValues({ ...values, radioResponsibleType: ev.target.value })}
                                >
                                    <FormControlLabel
                                        value="INTERNAL"
                                        control={<Radio color="primary" />}
                                        label="Interno"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="EXTERNAL"
                                        control={<Radio color="primary" />}
                                        label="Externo"
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        <Input
                            isSelect={true}
                            required={values.radioReturn === "YES" ? true : false}
                            error={values.radioReturn === "YES" && requiredFieldResponsible}
                            helperText={t("GENERAL.requiredField")}
                            valueName={"alterResponsible"}
                            label={t("INVENTORY.newResponsible")}
                            margin="normal"
                            maxLength={200}
                            handleChange={handleChange}
                            arraySelected={values.radioResponsibleType === "INTERNAL" ? usersArray : externalUsersArray}
                        />
                        <Grid item xs={12} sm={12} >
                            <Button style={{ color: "#2D9CDB" }} onClick={() => setValues({ ...values, alterResponsible: auth.id, newResponsibleName: usersArray.filter(ele => String(ele.value) === String(auth.id))[0].label })}>
                                {t("INVENTORY.assignToMe")}
                            </Button>
                        </Grid>
                        <Input
                            isSelect={true}
                            value={values.newMachineId}
                            valueName={"newMachineId"}
                            label={t("INVENTORY.newMachine")}
                            margin="normal"
                            maxLength={200}
                            handleChange={handleChange}
                            arraySelected={itensArray}
                        />
                        <Grid container style={{ paddingTop: "20px" }}>
                            <Grid item xs={12} sm={12}>
                                <b>{t("INVENTORY.newStatus").toUpperCase()}</b>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <RadioGroup row aria-label="position"
                                    name="position"
                                    defaultValue="top"
                                    value={values.radioNewStatus}
                                    onChange={(ev) => setValues({ ...values, radioNewStatus: ev.target.value })}                                    >
                                    <FormControlLabel
                                        value="AVAILABLE"
                                        control={<Radio color="primary" />}
                                        label="Disponível"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="UNAVAILABLE"
                                        control={<Radio color="primary" />}
                                        label="Indisponível"
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        {values.radioNewStatus === "UNAVAILABLE" &&
                            <Input
                                isSelect={true}
                                error={requiredFieldJustification}
                                value={values.unavaliableStatusId}
                                valueName={"unavaliableStatusId"}
                                label={t("INVENTORY.unavailabilityStatus") + "*"}
                                margin="normal"
                                maxLength={200}
                                handleChange={handleChange}
                                arraySelected={statusArray}
                            />}
                        <Grid container style={{ paddingTop: "20px" }}>
                            <Grid item xs={12} sm={12}>
                                <b>{t("INVENTORY.returned").toUpperCase()}</b>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <RadioGroup row aria-label="position"
                                    name="position"
                                    defaultValue="top"
                                    value={values.radioReturn}
                                    onChange={(ev) => setValues({ ...values, radioReturn: ev.target.value })}
                                >
                                    <FormControlLabel
                                        value="YES"
                                        control={<Radio color="primary" />}
                                        label="Sim"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="NO"
                                        control={<Radio color="primary" />}
                                        label="Não"
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Buttons
                        onClick={handleClose}
                        tipo="BN-noback"
                        conteudo={t("GENERAL.cancel").toLocaleUpperCase()}
                    />
                    <Buttons
                        onClick={handleSubmit}
                        tipo="BN-blue"
                        conteudo={t("GENERAL.alter").toLocaleUpperCase()}
                    />
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default DialogReqApprove;