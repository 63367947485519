/* eslint-disable */
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from '@material-ui/icons/Delete';
import Footer from "components/layout/Footer";
import InventoryContext from "context/inventory/inventoryContext";
import ExternalUsersContext from "context/externalUsers/externalUsersContext";
import ProjectsContext from "context/projects/projectsContext";
import UsersContext from "context/users/usersContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { green } from '@material-ui/core/colors';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import Checkbox from '@material-ui/core/Checkbox';
import LicenseForm from './LicenseForm';
import Transition from "components/layout/transition/Transition";
import Breadcrumb from "components/layout/breadcrumb/Breadcrumb";
import InputDragDrop from "components/layout/inputDragDrop/InputDragDrop";

const NewSoftwareAndLicense = () => {
    const classes = useStyles();
    const [t] = useTranslation();
    const inventoryContext = useContext(InventoryContext);
    const externalUsersContext = useContext(ExternalUsersContext);
    const projectsContext = useContext(ProjectsContext);
    const { getProjects, projects } = projectsContext;
    const usersContext = useContext(UsersContext);
    const [hasUpdatedFile, setHasUpdatedFile] = useState(false);
    const [categoriesArray, setCategoriesArray] = useState([]);
    const [projectsArray, setProjectsArray] = useState([]);
    const [allSoftwaresNames, setAllSoftwaresNames] = useState([]);
    const [libraryTitle, setLibraryTitle] = useState([]);
    const [files, setFiles] = useState([]);
    const [requiredName, setRequiredName] = useState(false);
    const [requiredCategory, setRequiredCategory] = useState(false);
    const [requiredEdition, setRequiredEdition] = useState(false);
    const [errorMessageName, setErrorMessageName] = useState("");

    //soft
    const [softwareDatasFilled, setSoftwareDatasFilled] = useState(false);
    const [licenseDatasFilled, setLicenseDatasFilled] = useState(false);
    const [titleHeader, setTitleHeader] = useState("INVENTORY.dataSoftware")
    const [contextNewLicense, setContextNewLicense] = useState(false);
    const [othersSelected, setOthersSelected] = useState(false);
    const [descriptionLength, setDescriptionLength] = useState(0);

    const {
        getUsers,
        users
    } = usersContext;

    const [values, setValues] = useState({
        image: "",
        name: "",
        manufacturer: "",
        newPlatforms: [""],
        category: "",
        description: "",
        softwareCategoryId: "",
        accessLink: ""
    });

    const [fieldsPlatform, setFieldsPlatform] = useState([]);

    const {
        loading,
        getPlaces,
        getSoftwareCategories,
        softwareCategories,
        getLibraryAdms,
        libraryAdms,
        admsPeopleManagement,
        getSoftwares,
        softwares,
        geAdmsPeopleManagement,
        getPlartforms,
        platforms,
        addSoftwareAndLicense
    } = inventoryContext;

    useEffect(() => {
        getPlaces();
        getProjects();
        getSoftwares();
        getUsers();
        getLibraryAdms();
        geAdmsPeopleManagement();
        getPlartforms();
        getSoftwareCategories();
    }, []);

    useEffect(() => {
        setValues({
            ...values,
            edition: libraryTitle.edition,
            softwareCategoryId: libraryTitle.categoryId,
        });
    }, [JSON.stringify(libraryTitle)]);

    const sortElementName = (elemnts) => {
        elemnts.sort(function (a, b) {
            if (a.name === null) a.name = "";
            if (b.name === null) b.name = "";

            if (a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() >
                b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim()) {
                return 1;
            }
            if (a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() <
                b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim()) {
                return -1;
            }
            // a must be equal to b
            return 0;
        });
    }

    useEffect(() => {
        const softwaresArray = [...softwares];

        let auxSoftwaresNames = []

        for (let i = 0; i < softwaresArray.length; i++) {
            let item = softwaresArray[i];
            auxSoftwaresNames.push(item.name.toLowerCase());
        }

        setAllSoftwaresNames(auxSoftwaresNames);

    }, [softwares]);

    useEffect(() => {
        if (platforms) {
            const Platforms = [...platforms];
            var aux = {};
            var _platforms = [];

            for (var i = 0; i < Platforms.length; i++) {
                var item = Platforms[i];
                aux.checked = false;
                aux.id = item.id;
                aux.label = item.name;
                _platforms.push(aux);
                aux = {};
            }
            // var others = {};
            // others.checked = false;
            // others.id = null;
            // others.label = "Outros";
            // _platforms.push(others);
            setFieldsPlatform(_platforms)
        }
    }, [platforms]);

    useEffect(() => {
        const allProjects = [...projects];
        sortElementName(allProjects);

        var aux = {};
        var _projects = [];
        for (var i = 0; i < allProjects.length; i++) {
            var item = allProjects[i];
            aux.value = item.idInformation;
            aux.label = item.name;
            _projects.push(aux);
            aux = {};
        }
        setProjectsArray(_projects);
    }, [projects]);

    useEffect(() => {
        const categories = [...softwareCategories];
        sortElementName(categories);

        var aux = {};
        var _categories = [];
        for (var i = 0; i < categories.length; i++) {
            var item = categories[i];
            aux.value = item.id;
            aux.label = item.name;
            _categories.push(aux);
            aux = {};
        }
        setCategoriesArray(_categories);
    }, [softwareCategories]);



    const onDrop = acceptedFiles => {
        if (acceptedFiles[0] !== undefined) {
            setHasUpdatedFile(true);
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    };

    const handleChange = (name, value) => {
        if (name === "invoice" || name === "iof") {
            if (!isNaN(value)) {
                setValues({ ...values, [name]: value });
            }
        }
        else {
            setValues({ ...values, [name]: value });
        }
    };

    const handleChangePlatforms = (event) => {
        if (event && event.target && event.target.name) {
            const newFields = [...fieldsPlatform];
            const field = newFields.find(x => x.label === event.target.name);
            if (field.label === "Outros" && field.checked === false) setOthersSelected(true);
            else setOthersSelected(false);
            if (field) {
                field.checked = !field.checked;
                setFieldsPlatform(newFields);
            }
        }
    };

    const addRowPlatform = () => {
        const newPlatform = [...values.newPlatforms];
        newPlatform.push("");
        setValues({ ...values, newPlatforms: newPlatform });
    }

    const removeRowPlatform = (indexField) => () => {
        const newPlatform = [...values.newPlatforms];
        const newPlatformFilter = newPlatform.filter((x, i) => i !== indexField);
        setValues({ ...values, newPlatforms: newPlatformFilter });
    }

    useEffect(() => {
        if (values.name && values.softwareCategoryId) {
            setSoftwareDatasFilled(true)
        } else setSoftwareDatasFilled(false);
    }, [values, fieldsPlatform]);

    const verifyNameAlreadyinUse = () => {
        if (allSoftwaresNames) {
            if (allSoftwaresNames.includes(values.name.toLowerCase())) {
                setRequiredName(true);
                setErrorMessageName(t("INVENTORY.nameSoftwareAlreadyTaken"))
                return false;
            }
            return true;
        }
    }

    const verifyEmptyFields = () => {
        if (!values.name.trim()) {
            setRequiredName(true);
            setErrorMessageName(t("GENERAL.invalidValue"));
            return false;
        }
        return true;
    }

    const changeTabSoftware = () => {
        if (softwareDatasFilled) return <CheckCircleIcon style={{ marginLeft: 5, color: green[500] }} />
        else return <RadioButtonUncheckedIcon style={{ marginLeft: 5 }} />
    }

    const changeTabLicense = () => {
        if (licenseDatasFilled) return <CheckCircleIcon style={{ marginLeft: 5, color: green[500] }} />
        else return <RadioButtonUncheckedIcon style={{ marginLeft: 5 }} />
    }

    const handleChangeNewPlatforms = (indexField, newValue) => {
        const platform = newValue ? newValue : "";
        if (platform.length <= 60) {
            const newPlatform = [...values.newPlatforms];
            newPlatform[indexField] = platform;
            setValues({ ...values, newPlatforms: newPlatform });
        }
    }

    const changeContext = (bool, title) => {
        setTitleHeader(title)
        setContextNewLicense(bool)
    }

    return (
        <Transition loading={loading}>
            <div className="container">
                <Breadcrumb
                    routes={[
                        { label: t("INVENTORY.inventory") },
                        { label: t("INVENTORY.softwares"), to: "/Software" },
                        { label: t("INVENTORY.addSoftware") },
                    ]}
                />
                <div className="container-header">
                    <div className="title">
                        <h1 className={classes.title}>{t("INVENTORY.addSoftware")}</h1>
                    </div>
                </div>
                <div className={classes.containerTabsNewSoftware}>
                    <span style={{ display: "flex" }} onClick={(ev) => changeContext(false, "INVENTORY.dataSoftware")}>
                        <Typography>{t("INVENTORY.dataSoftware")}</Typography>
                        {changeTabSoftware()}
                    </span>
                    <ChevronRightIcon />
                    <span style={{ display: "flex" }} onClick={() => { if (verifyEmptyFields() && verifyNameAlreadyinUse()) { changeContext(true, "INVENTORY.newLicenseDatasHearder") } }}>
                        <Typography>{t("INVENTORY.dataLicense")}</Typography>
                        {changeTabLicense()}
                    </span>
                </div>
                <Paper >
                    <div className="cabecalho">
                        {t(titleHeader).toUpperCase()}
                        {contextNewLicense && values.name}
                    </div>
                    {!contextNewLicense ?
                        <div className={classes.bodyNewEquipment}>
                            <Grid container spacing={3}>
                                <Grid item xs={2} className={classes.backgroundImage}>
                                    <Grid item xs={2}>
                                        <InputDragDrop
                                            files={files}
                                            onDrop={onDrop}
                                            hasUpdatedFile={hasUpdatedFile}
                                            imagePath={values.image}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={7}>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            error={requiredName}
                                            className={classes.textField}
                                            label={t("INVENTORY.name") + "*"}
                                            value={values.name}
                                            helperText={errorMessageName}
                                            onChange={(ev) => { if (ev.target.value.length <= 200) { handleChange("name", ev.target.value) } setRequiredName(false); setErrorMessageName("") }}
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            select
                                            label={t("INVENTORY.category") + "*"}
                                            name="category"
                                            value={values.softwareCategoryId}
                                            onChange={(ev) => {
                                                setRequiredCategory(false)
                                                handleChange("softwareCategoryId", ev.target.value)
                                            }}
                                            className={classes.textField}
                                            margin="normal"
                                            classes={{
                                                root: classes.inputRoot,
                                                input: classes.inputInput
                                            }}
                                            inputProps={{ "aria-label": "search" }}
                                        >
                                            {categoriesArray.map(category => (
                                                <MenuItem key={category.value} value={category.value}>
                                                    {category.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FormControl component="fieldset">
                                            <Grid container>
                                                <Grid container item spacing={1} md={12} lg={12}>
                                                    <Grid item xs={12}>
                                                        <FormLabel component="legend">{t("INVENTORY.platform")}</FormLabel>
                                                    </Grid>
                                                    <Grid item xs={4} style={{ display: 'flex', flexDirection: 'column' }}>
                                                        {fieldsPlatform
                                                            .map((field, idx) =>
                                                                <FormControlLabel
                                                                    key={field.label + idx}
                                                                    label={t(field.label)}
                                                                    control={
                                                                        <Checkbox color="primary" checked={field.checked} name={field.label} onChange={handleChangePlatforms} />
                                                                    }
                                                                />
                                                            )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </FormControl>
                                    </Grid>
                                    {othersSelected &&
                                        <Grid container >
                                            {values.newPlatforms.map((aut, idx) =>
                                                <>
                                                    <Grid item xs={11} sm={11}>
                                                        <TextField
                                                            label={t("INVENTORY.addPlatform")}
                                                            margin="normal"
                                                            value={aut}
                                                            className={classes.textField}
                                                            classes={{
                                                                root: classes.inputRoot,
                                                                input: classes.inputInput
                                                            }}
                                                            onChange={(event) => {
                                                                handleChangeNewPlatforms(idx, event.target.value);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1} sm={1}>
                                                        {(values.newPlatforms.length > 1) &&
                                                            <Tooltip
                                                                title="Remover plataforma"
                                                                aria-label="remover"
                                                                placement="top"
                                                            >
                                                                <IconButton
                                                                    style={{ float: "right", marginTop: "20px" }}
                                                                    onClick={removeRowPlatform(idx)}
                                                                    className={classes.icons}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        }
                                                    </Grid>
                                                </>
                                            )}
                                            <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <Typography
                                                    className={classes.link}
                                                    onClick={addRowPlatform}
                                                    style={{ cursor: 'pointer', fontSize: 14 }}
                                                >
                                                    {t("INVENTORY.newPlatforms")}
                                                </Typography>
                                            </Grid>
                                        </Grid>}
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            className={classes.textField}
                                            label={t("INVENTORY.manufacturer")}
                                            value={values.manufacturer}
                                            onChange={(ev) => { if (ev.target.value.length <= 60) { handleChange("manufacturer", ev.target.value) } setRequiredEdition(false) }}
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            className={classes.textField}
                                            label={t("INVENTORY.accessLink")}
                                            value={values.accessLink}
                                            onChange={(ev) => { handleChange("accessLink", ev.target.value); setRequiredEdition(false) }}
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            multiline
                                            fullWidth
                                            rows="6"
                                            variant="outlined"
                                            className={classes.textField}
                                            label={t("INVENTORY.description")}
                                            value={values.description}
                                            margin="normal"
                                            helperText={`${values.description.length}/250`}
                                            onChange={(ev) => {
                                                if (ev.target.value.length <= 250) {
                                                    handleChange("description", ev.target.value);
                                                    setDescriptionLength(ev.target.value.length)
                                                }
                                            }}
                                        />
                                        {/* <span style={{ display: "flex", justifyContent: "flex-end" }}>{descriptionLength + "/250"}</span> */}
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Button
                                            disabled={!softwareDatasFilled}
                                            style={{ padding: "15px", float: "right" }}
                                            onClick={() => { if (verifyEmptyFields() && verifyNameAlreadyinUse()) { changeContext(true, "INVENTORY.newLicenseDatasHearder") } }}
                                        >
                                            {t("GENERAL.nextButton")}
                                            <ChevronRightIcon />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                        :
                        <LicenseForm
                            datas={values}
                            context={"newS&L"}
                            files={files}
                            changeContext={changeContext}
                            platforms={platforms}
                            fieldsPlatform={fieldsPlatform}
                            addSoftwareAndLicense={addSoftwareAndLicense}
                            categoriesArray={categoriesArray}
                            projectsArray={projectsArray} />
                    }
                </Paper>
            </div>
            <Footer />
        </Transition >
    )
};

export default NewSoftwareAndLicense;