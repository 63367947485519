import React, { useState, useEffect } from 'react';
import { screenStyles } from './Styles';
import Typography from 'components/typography/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import Buttons from 'components/buttons/Pressed';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { useTranslation } from 'react-i18next';
import {
    LibraryExemplarStatusValues,
    LibraryReservationStatusValues
} from 'global/constants';
import DefaultTooltip from 'components/infoTooltip/DefaultTooltip';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Link from 'react-router-dom/Link';

const BookCardCollection = props => {
    const [t] = useTranslation();
    const {
        image,
        title,
        author,
        category,
        status,
        onClick,
        onClickMoreDetails,
        onClickQueue,
        isAlreadyQueued,
        isMaxOfBooks
    } = props;
    const styles = screenStyles();

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(false);
    }, [image]);

    return (
        <React.Fragment>
            <div className={styles.cardContainer}>
                <img
                    className={styles.cardImage}
                    style={!loaded ? { display: 'none' } : {}}
                    src={image}
                    alt={title}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src =
                            window.location.origin + '/img/no-book-cover.png';
                        setLoaded(true);
                    }}
                    onLoad={() => {
                        setLoaded(true);
                    }}
                />
                {!loaded && (
                    <Skeleton
                        className={styles.cardImage}
                        variant="rect"
                        width={160}
                        height={220}
                    />
                )}
                <Typography
                    className={styles.cardTitle}
                    variant={'RobotoBold'}
                    fontSize={12}
                    textColor={'secondary'}
                >
                    {title}
                </Typography>
                <Typography
                    className={styles.cardTopic}
                    variant={'RobotoBold'}
                    fontSize={12}
                    textColor={'secondary'}
                >
                    Autor
                </Typography>
                <Typography
                    className={styles.cardAuthor}
                    variant={'RobotoRegular'}
                    fontSize={12}
                    textColor={'secondary'}
                >
                    {author}
                </Typography>
                <Typography
                    className={styles.cardTopic}
                    variant={'RobotoBold'}
                    fontSize={12}
                    textColor={'secondary'}
                >
                    Categoria
                </Typography>
                <Typography
                    className={styles.cardAuthor}
                    variant={'RobotoRegular'}
                    fontSize={12}
                    textColor={'secondary'}
                >
                    {category}
                </Typography>
                <div className={styles.cardAmount}>
                    <Typography
                        className={styles.cardTopic}
                        variant={'RobotoRegular'}
                        fontSize={12}
                        textColor={'secondary'}
                    >
                        <b>Status: </b>
                        {t(`LIBRARY.${isAlreadyQueued ? 'queued' : status}`)}
                    </Typography>
                    {status === LibraryExemplarStatusValues.DISPONIBLE ? (
                        <CheckCircleIcon
                            style={{ marginLeft: '0.2rem', color: '#7FE46B' }}
                            fontSize="small"
                        />
                    ) : status ===
                          LibraryReservationStatusValues.AWAITING_APPROVAL ||
                      isAlreadyQueued ? (
                        <AccessTimeIcon
                            style={{ marginLeft: '0.2rem', color: '#6D6F71' }}
                            fontSize="small"
                        />
                    ) : status ===
                      LibraryReservationStatusValues.AWAITING_WITHDRAWAL ? (
                        <DefaultTooltip
                            style={{ marginLeft: '0.2rem', color: '#6D6F71' }}
                            fontSize="small"
                            text={t('LIBRARY.collectionCardToolTip')}
                        />
                    ) : (
                        <ErrorIcon
                            style={{ marginLeft: '0.2rem', color: '#FFD200' }}
                            fontSize="small"
                        />
                    )}
                </div>
                <Typography
                    onClick={onClickMoreDetails}
                    className={styles.cardMoreDetails}
                    variant={'RobotoSemiBold'}
                    fontSize={14}
                    textColor={'primary'}
                >
                    MAIS DETALHES
                </Typography>
                {status === LibraryExemplarStatusValues.DISPONIBLE ? (
                    <Buttons
                        className={styles.cardButton}
                        tipo="BN-blue"
                        conteudo="REQUISITAR"
                        onClick={onClick}
                    />
                ) : isAlreadyQueued ? (
                    <Link to={`/Library/MyLoans?panel=QUEUEDBOOKS`}>
                        <Buttons
                            style={{ width: '100%' }}
                            className={styles.cardButton}
                            tipo="borderBackButton"
                            conteudo="IR PARA RESERVAS"
                        />
                    </Link>
                ) : status === LibraryExemplarStatusValues.INDISPONIBLE ? (
                    <Buttons
                        style={{ width: '100%' }}
                        className={styles.cardButton}
                        tipo="borderBackButton"
                        conteudo="FAZER RESERVA"
                        onClick={isMaxOfBooks ? onClick : onClickQueue}
                    />
                ) : (
                    <Link to={`/Library/MyLoans?panel=PENDINGREQUISITIONS`}>
                        <Buttons
                            style={{ width: '100%' }}
                            className={styles.cardButton}
                            tipo="borderBackButton"
                            conteudo="IR PARA REQUISIÇÕES"
                        />
                    </Link>
                )}
            </div>
        </React.Fragment>
    );
};

export default BookCardCollection;
