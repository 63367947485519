import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Visibility from '@material-ui/icons/Visibility';
import DialogFinesMoreDetails from 'pages/libraryModule/dialogs/Fines/DialogFinesMoreDetails';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/layout/CommonStyles';
import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';
import { Screen, Allow } from 'global/constants';
import { screenStyles } from 'pages/libraryModule/Fines/Styles';

const FinesTableActions = props => {
    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }
    const styles = screenStyles();
    const classes = useStyles();
    const [t] = useTranslation();
    const { item } = props;
    const [openFineDetailsDialog, setOpenFineDetailsDialog] = useState(false);

    return (
        <React.Fragment>
            {GetGeneralPermissionForAction(Screen.FINES, Allow.READ) && (
                <Tooltip
                    title={t('GENERAL.view')}
                    aria-label="details"
                    placement="top"
                >
                    <IconButton
                        className={classes.iconsTableActions}
                        onClick={() => setOpenFineDetailsDialog(true)}
                    >
                        <Visibility />
                    </IconButton>
                </Tooltip>
            )}
            {openFineDetailsDialog && (
                <DialogFinesMoreDetails
                    open={setOpenFineDetailsDialog}
                    setOpen={setOpenFineDetailsDialog}
                    viewValues={item}
                />
            )}
        </React.Fragment>
    );
};

export default FinesTableActions;
