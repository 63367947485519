/* eslint-disable */
import axios from "axios";
import React, { useReducer } from "react";
import { useTranslation } from "react-i18next";
import { DEFAULT, PROFILES } from "../types";
import ProfilesContext from "./profilesContext";
import profilesReducer from "./profilesReducer";
import Cookies from "js-cookie";

const ProfilesState = props => {
  const initialState = {
    profile: {},
    permissions: {},
    profiles: [],
    profilesfilter: [],
    response: "",
    responseType: "",
    filter: "",
    hasChanged: 0,
    successiveActions: 0,
    loading: false
  };
  const [t] = useTranslation();
  const [state, dispatch] = useReducer(profilesReducer, initialState);

  const setLoading = () => dispatch({ type: DEFAULT.SET_LOADING });

  const resetMessage = () => {
    dispatch({ type: DEFAULT.RESET_MESSAGE });
  };
  const setHasChanged = () => dispatch({ type: DEFAULT.SET_HAS_CHANGED });

  const setMessage = (_response, _responseType) => {
    setLoading();
    dispatch({
      type: DEFAULT.SET_MESSAGE,
      payload: _response,
      responseType: _responseType,
      successiveActions: state.successiveActions + 1,
      hasChanged: state.successiveActions + 2
    });
  };

  //Search
  function searchTermChanged(searchTerm) {
    dispatch({
      type: PROFILES.SEARCH,
      filter: searchTerm
    });
  }

  // get all profiles
  const getProfiles = async () => {
    setLoading();
    const res = await axios.get(process.env.REACT_APP_SERVER + "api/Profiles", {
      headers: {
        "Content-type": "application/json",
        "Authorization": "bearer " + (Cookies.get('auth-token') ? Cookies.get('auth-token') : "")
      }
    });
    dispatch({
      type: PROFILES.GET_PROFILES,
      payload: res.data
    });
  };

  const getPermissions = permissions => {
    setLoading();
    let cleanPermissions = {};
    let aux = {};
    for (var item in permissions) {
      aux.id = permissions[item].id;
      aux.saoId = permissions[item].standardauthorizationoption.id;
      aux.allowCreate =
        permissions[item].standardauthorizationoption.allowCreate;
      aux.allowView = permissions[item].standardauthorizationoption.allowRead;
      aux.allowEdit = permissions[item].standardauthorizationoption.allowUpdate;
      aux.allowDelete =
        permissions[item].standardauthorizationoption.allowDelete;
      cleanPermissions[permissions[item].resource] = aux;
      aux = {};
    }
    let cleanPermissionsString = JSON.stringify(cleanPermissions);
    dispatch({
      type: PROFILES.GET_PERMISSIONS,
      payload: JSON.parse(cleanPermissionsString)
    });
  };

  // get on profile by id
  const getProfile = (id) => {
    setLoading();
    axios
      .get(process.env.REACT_APP_SERVER + `api/Profiles/${id}`, {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "bearer " +
            (Cookies.get("auth-token") ? Cookies.get("auth-token") : ""),
        },
      })
      .then((res) => {
        getPermissions(res.data.sgppdPermissionItem);
        dispatch({
          type: PROFILES.GET_PROFILE,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateProfile = (obj) => {
    setLoading();
    let data = JSON.stringify({
      id: obj.id,
      frozen: true,
      description: obj.description,
      name: obj.name,
      updatedat: new Date(),
    });
    axios
      .put(process.env.REACT_APP_SERVER + `api/Profiles/${obj.id}`, data, {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "bearer " +
            (Cookies.get("auth-token") ? Cookies.get("auth-token") : ""),
        },
      })
      .then(() => {
        // getting previus permissions
        axios
          .get(process.env.REACT_APP_SERVER + `api/Profiles/${obj.id}`, {
            headers: {
              "Content-type": "application/json",
              Authorization:
                "bearer " +
                (Cookies.get("auth-token") ? Cookies.get("auth-token") : ""),
            },
          })
          .then((res) => {
            let promise =
              tryToCreateOrUpdatePermissionsBasedOnGivenProfileState(
                obj,
                res.data.sgppdPermissionItem
              );
            promise.then((res) => {
              if (res) {
                dispatch({
                  type: PROFILES.UPDATE_PROFILE,
                  payload: t("PROFILES.profile") + " " + t("GENERAL.updated"),
                  responseType: "SUCCESS",
                  successiveActions: state.successiveActions + 1,
                  hasChanged: state.successiveActions + 2,
                });
              } else {
                dispatch({
                  type: PROFILES.UPDATE_PROFILE,
                  payload: t("PROFILES.unsuccessfullyUpdated"),
                  responseType: "ERROR",
                  successiveActions: state.successiveActions + 1,
                  hasChanged: state.successiveActions + 2,
                });
              }
            });
          });
      })
      .catch(() => {
        dispatch({
          type: PROFILES.UPDATE_PROFILE,
          payload: t("PROFILES.unsuccessfullyUpdated"),
          responseType: "ERROR",
          successiveActions: state.successiveActions,
          hasChanged: state.successiveActions + 2,
        });
      });
  };

  const findUserProfile = async (id) => {
    //setLoading();
    var candelete = true;
    const res = await axios.get(process.env.REACT_APP_SERVER + "api/users", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "bearer " +
          (Cookies.get("auth-token") ? Cookies.get("auth-token") : ""),
      },
    });
    let objectResponse = res.data;
    for (var objr in objectResponse) {
      if (objectResponse[objr]["profileId"] === id) {
        candelete = false;
        break;
      }
    }
    return candelete;
  };

  const deleteProfile = (id) => {
    setLoading();
    let promise = findUserProfile(id);
    promise.then((result) => {
      if (result) {
        axios
          .delete(process.env.REACT_APP_SERVER + `api/Profiles/${id}`, {
            headers: {
              "Content-type": "application/json",
              Authorization:
                "bearer " +
                (Cookies.get("auth-token") ? Cookies.get("auth-token") : ""),
            },
          })
          .then(() => {
            dispatch({
              type: PROFILES.DELETE_PROFILE,
              payload: t("PROFILES.profile") + " " + t("GENERAL.deleted"),
              successiveActions: state.successiveActions + 1,
              hasChanged: state.successiveActions + 2,
              responseType: "SUCCESS",
            });
          })
          .catch((error) => {
            dispatch({
              type: PROFILES.DELETE_PROFILE,
              payload: t("PROFILES.notFound"),
              successiveActions: state.successiveActions + 1,
              hasChanged: state.successiveActions + 2,
              responseType: "ERROR",
            });
          });
      } else {
        dispatch({
          type: PROFILES.DELETE_PROFILE,
          payload: t("PROFILES.associatedWithAUser"),
          successiveActions: state.successiveActions + 1,
          hasChanged: state.successiveActions + 2,
          responseType: "ERROR",
        });
      }
    });
  };

  const addProfile = (obj) => {
    setLoading();
    let data = JSON.stringify({
      frozen: true,
      description: obj.description,
      name: obj.name,
      updatedat: new Date(),
    });

    axios
      .post(process.env.REACT_APP_SERVER + "api/Profiles/", data, {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "bearer " +
            (Cookies.get("auth-token") ? Cookies.get("auth-token") : ""),
        },
      })
      .then((response) => {
        obj.id = response.data.id;
        let promise = tryToCreateOrUpdatePermissionsBasedOnGivenProfileState(
          obj,
          []
        );
        promise.then((res) => {
          if (res) {
            dispatch({
              type: PROFILES.ADD_PROFILE,
              payload: t("PROFILES.successfullyCreated"),
              responseType: "SUCCESS",
              successiveActions: state.successiveActions + 1,
              hasChanged: state.successiveActions + 2,
            });
          } else {
            dispatch({
              type: PROFILES.ADD_PROFILE,
              payload: t("PROFILES.unsuccessfullyCreated"),
              responseType: "ERROR",
              successiveActions: state.successiveActions + 1,
              hasChanged: state.successiveActions + 2,
            });
          }
        });
      })
      .catch(() => {
        dispatch({
          type: PROFILES.ADD_PROFILE,
          payload: t("PROFILES.nameAlreadyTaken"),
          responseType: "ERROR",
          successiveActions: state.successiveActions + 1,
          hasChanged: state.successiveActions + 2,
        });
      });
  };

  const tryToCreateOrUpdatePermissionsBasedOnGivenProfileState = async (
    obj,
    currentPermissions
  ) => {
    let aux = {};
    let aux2 = {};
    let result = true;
    var authOptions = [];
    var currentPermissionsids = [];
    var currentPermissionsObjects = [];
    // profile current permissions, to avoid create same tuples
    if (Object.keys(currentPermissions).length > 0) {
      for (var itemPermission in currentPermissions) {
        currentPermissionsids.push(currentPermissions[itemPermission].id);
        currentPermissionsObjects.push(currentPermissions[itemPermission]);
      }
    }

    try {
      const res = await axios.get(
        process.env.REACT_APP_SERVER + "api/StandardAuthorizationOption",
        {
          headers: {
            "Content-type": "application/json",
            Authorization:
              "bearer " +
              (Cookies.get("auth-token") ? Cookies.get("auth-token") : ""),
          },
        }
      );
      let objectResponse = res.data;
      // object to array
      for (var objr in objectResponse) {
        authOptions.push(objectResponse[objr]);
      }

      for (var item in obj) {
        // criar uma permissão para cada estado
        if (typeof obj[item] === "object") {
          aux.allowCreate = obj[item].allowCreate;
          aux.allowView = obj[item].allowView;
          aux.allowEdit = obj[item].allowEdit;
          aux.allowDelete = obj[item].allowDelete;
          for (var i = 0; i < authOptions.length; i++) {
            aux2.allowCreate = authOptions[i].allowCreate;
            aux2.allowView = authOptions[i].allowRead;
            aux2.allowEdit = authOptions[i].allowUpdate;
            aux2.allowDelete = authOptions[i].allowDelete;
            if (
              JSON.stringify(aux).toLowerCase() ===
              JSON.stringify(aux2).toLowerCase()
            ) {
              if (currentPermissionsids.includes(obj[item].id)) {
                // since profile already have a permission for this
                // kind of resource i only want to update
                try {
                  let verifyIfPermissionHasChanged = {};

                  // getting previous authorization
                  for (var permission in currentPermissionsObjects) {
                    if (
                      currentPermissionsObjects[permission].id === obj[item].id
                    ) {
                      verifyIfPermissionHasChanged =
                        currentPermissionsObjects[permission];
                      break;
                    }
                  }
                  if (
                    verifyIfPermissionHasChanged.standardauthorizationoptionId ===
                    authOptions[i].id
                  ) {
                  } else {
                    /***
                     *
                     *  ONLY UPDATE IF AND ONLY IF HAS BEEN MADE A CHANGE ON ANY PERMISSION...
                     */
                    let data = {
                      id: obj[item].id,
                      frozen: false,
                      resource: item,
                      profileId: obj.id,
                      standardauthorizationoptionId: authOptions[i].id,
                    };
                    result = updatePermission(data);
                  }
                } catch (error) {
                  console.error(error);
                  result = error;
                }
              } else {
                // if not, i try to create
                let data = {
                  frozen: false,
                  resource: item,
                  profileId: obj.id,
                  standardauthorizationoptionId: authOptions[i].id,
                };
                try {
                  result = createPermission(data);
                } catch (error) {
                  console.error(error);
                  result = error;
                }
              }
              // exit for
              i = authOptions.length;
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
      result = false;
    }
    return result;
  };

  const updatePermission = (obj) => {
    let result = true;
    axios
      .put(
        process.env.REACT_APP_SERVER + `api/PermissionItems/${obj.id}`,
        obj,
        {
          headers: {
            "Content-type": "application/json",
            Authorization:
              "bearer " +
              (Cookies.get("auth-token") ? Cookies.get("auth-token") : ""),
          },
        }
      )
      .then(() => {})
      .catch((error) => {
        console.error(error);
        result = false;
      });
    return result;
  };

  const createPermission = (obj) => {
    let result = true;
    axios
      .post(process.env.REACT_APP_SERVER + "api/PermissionItems", obj, {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "bearer " +
            (Cookies.get("auth-token") ? Cookies.get("auth-token") : ""),
        },
      })
      .then(() => {})
      .catch((error) => {
        console.error(error);
        result = false;
      });
    return result;
  };

  return (
    <ProfilesContext.Provider
      value={{
        profile: state.profile,
        profiles: state.profiles,
        permissions: state.permissions,
        loading: state.loading,
        response: state.response,
        responseType: state.responseType,
        successiveActions: state.successiveActions,
        hasChanged: state.hasChanged,
        getProfile,
        getProfiles,
        addProfile,
        resetMessage,
        setHasChanged,
        setMessage,
        deleteProfile,
        updateProfile,
        searchTermChanged,
        filter: state.filter
      }}
    >
      {props.children}
    </ProfilesContext.Provider>
  );
};

export default ProfilesState;
