import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { useStyles } from "components/layout/CommonStyles";
import React, {useEffect} from "react";

const ViewHeading = props => {
    const classes = useStyles();
    const { index, headingName, headingValue, updateFields } = props;

    useEffect(() => {
        updateFields();
      });

    return (
        <div className={classes.bodyForm}>
            <Grid container spacing={2}>
                <Grid id={"headingName" + index} item xs={4}>
                    {headingName}
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        disabled
                        id={"headingValue" + index}
                        className={classes.textField}
                        margin="normal"
                        name="headingValue"
                        value={headingValue}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">R$</InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <div className="lineBreak"></div>
            </Grid>
        </div>
    );
};

export default ViewHeading;