import { moneyMask } from 'utils/masks/moneyMask';
import { moneyMaskToFloat } from 'utils/general/moneyMaskToFloat';
import { formatImagePath } from 'utils/library/formatImagePath';
import { ShoppingCartOutlined } from '@material-ui/icons';

export const setExistingBookValues = (book, setBookValues, setBookAuthors) => {
    setBookValues({
        id: book.id,
        edition: book.edition,
        title: book.title,
        isbn: book.isbn,
        subTitle: book.subtitle,
        publisher: book.publisher,
        language: book.language,
        synthesis: book.synopsis,
        categoryId: book.categoryId,
        image: formatImagePath(book.imagePath, book.isImageUrl),
        isImageUrl: book.isImageUrl
    });

    setBookAuthors(book.authors.map(bookAuthor => bookAuthor.name));
};

export const SubmitTypes = {
    CREATE_BOOK_AND_EXEMPLAR: 'CREATE_BOOK_AND_EXEMPLAR',
    CREATE_EXEMPLAR: 'CREATE_EXEMPLAR',
    UPDATE_BOOK: 'UPDATE_BOOK',
    UPDATE_EXEMPLAR: 'UPDATE_EXEMPLAR'
};

export const calcTotalPurchase = values => {
	const total =
        (moneyMaskToFloat(values.purchasePrice || '0,00') +
            moneyMaskToFloat(values.freight || '0,00')) *
        moneyMaskToFloat(values.currencyQuote) *
        parseInt(values.amount);

    return moneyMask(total.toFixed(2));
};

export const costPerUnit = values => {
    const total =
        (moneyMaskToFloat(values.purchasePrice || '0,00') +
            moneyMaskToFloat(values.freight || '0,00')) *
        moneyMaskToFloat(values.currencyQuote);

    return moneyMask(total.toFixed(2));
};
