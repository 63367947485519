import { StyleSheet } from "@react-pdf/renderer";

export default StyleSheet.create({
    header: {
        color: "#EEF2F6",
        backgroundColor: "#2C3E51",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: 8,
        fontSize: 14,
        fontWeight: 700,
    }
})
