import AllowancesContext from "context/allowance/allowanceContext";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route } from "react-router-dom";
import history from "../../history";
import auth from "./auth";

export const ProtectedRouteMode = ({ component: Component, ...rest }) => {
  let canAccess = false;
  const [t] = useTranslation();
  const allowanceContext = useContext(AllowancesContext);
  const {
    verifyRights,
    formattedAllowances,
    setAllowanceMessage,
  } = allowanceContext;
  var modes = "";

  const setResource = () => {
    var mode = rest.computedMatch.params.mode
    if (mode === undefined) {
      if (rest.permission === "allowCreate") {
        modes = "allowCreate"
      }
    } else {
      if (mode === "edit") {
        modes = "allowUpdate"
      } else {
        modes = "allowRead"
      }
    }
  }

  const getPermission = (modes, screen) => {
    if (localStorage.getItem("auth-token") !== null) {
      var auth = JSON.parse(localStorage.getItem("auth-token"));
      var array = []
      for (var i = 0; i < auth.permissions.length; i++) {
        var permission = auth.permissions[i].standardauthorizationoption;
        var resource = auth.permissions[i].resource;
        if (screen === "list") {
          return true
        } else {
          if (resource === screen) {
            return permission[modes]
          }
        }
      }
    }
  }

  return (
    <Route
      {...rest}
      render={props => {
        if (auth.getLogged()) {
          setResource()
          canAccess = getPermission(modes, rest.resource)
          if (auth.isAuthenticated(canAccess)) {
            return <Component {...props} />;
          } else {
            setAllowanceMessage(t("PROFILES.route.accessDenied"), "ERROR");
            return (
              <Redirect
                to={{
                  pathname: history.goBack(),
                  state: {
                    from: props.location
                  }
                }}
              />
            );
          }
        } else {
          return (
            // <Redirect
            //   to={{
            //     pathname: "/",
            //     state: {
            //       from: props.location
            //     }
            //   }}
            // />
            <React.Fragment>
              {(window.location.href = process.env.REACT_APP_SITE)}
            </React.Fragment>
          );
        }
      }}
    />
  );
};