import { LibraryPlaceValues } from 'global/constants';

export function translateLibrary(status) {
    switch (status) {
        case LibraryPlaceValues.GENERAL:
            return 'Geral';
        case LibraryPlaceValues.COORDINATION:
            return 'Coordenação';
        default:
            return '';
    }
}
