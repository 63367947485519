/* eslint-disable */
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles
} from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import Check from "@material-ui/icons/Check";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Footer from "components/layout/Footer";
import Spinner from "components/layout/spinners/Index";
import AllowancesContext from "context/allowance/allowanceContext";
import ProfilesContext from "context/profiles/profilesContext";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import Buttons from "../buttons/Pressed";
import DashboardNavBar from "../layout/DashboardNavBar";
import SideBar from "../layout/sidebar/Sidebar";
import { useStyles } from "./ProfileStyles";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";

const tooltipTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#2c3e51"
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: "white",
        fontSize: "1em",
        backgroundColor: "red",
        opacity: "0.7"
      }
    }
  }
});
const CustomSwitch = withStyles({
  switchBase: {
    "&$checked": {
      color: "#2c3e51"
    },
    "&$checked + $track": {
      backgroundColor: "#2c3e51"
    }
  },
  checked: {},
  track: {}
})(Switch);

const matchSateWithFormattedAllowances = state => {
  let formattedAllowance = {};
  let formattedAllowances = [];
  for (var item in state) {
    if (typeof state[item] === "object") {
      formattedAllowance.resource = item;
      formattedAllowance.allowCreate = state[item].allowCreate;
      formattedAllowance.allowRead = state[item].allowView;
      formattedAllowance.allowUpdate = state[item].allowEdit;
      formattedAllowance.allowDelete = state[item].allowDelete;
      formattedAllowances.push(formattedAllowance);
    }
    formattedAllowance = {};
  }
  return formattedAllowances;
};

const renderRedirect = go => {
  if (go) return <Redirect to={"/profiles"} />;
};
const NewProfile = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const profilesContext = useContext(ProfilesContext);
  const allowanceContext = useContext(AllowancesContext);
  const {
    resetMessage,
    loading,
    addProfile,
    getProfiles,
    profiles
  } = profilesContext;

  const { getCurrentLoggedProfile } = allowanceContext;

  const nameRef = useRef(null);
  const [validName, setValidName] = useState(false);
  const [titleName, setTitle] = useState("GENERAL.requiredField");
  const [mustRedirect, setMustRedirect] = useState(false);
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    id: 0,
    name: "",
    description: "",
    PROFILE: {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    USER: {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    PARTNER: {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    PROJECTSEXEC: {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    PROJECTSPLAN: {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    GENERALINFORMATION: {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    COOPERATIONTERM: {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    SCOPE: {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    GOAL: {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    SCHEDULE: {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    BUBGETPLAN: {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    EXPENSES: {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    HEADINGS2: {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    ACTIVITIES: {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    PARTICIPANTS: {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    PHASES: {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    USERCOSTS: {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    TEMPFIANCIALSREPORT: {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    GENERALFIANREPORT:
    {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    MANAGEMENTREPORT: {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    BUDGETEXECUTION: {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    CONFIGURATION: {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    },
    EXECUTION: {
      id: null,
      saoId: null,
      allowCreate: false,
      allowView: false,
      allowEdit: false,
      allowDelete: false
    }
  });

  useEffect(() => {
    getProfiles();
    resetMessage();
    return () => { };
  }, []);
  let data = [];
  const transitions = useTransition(true, null, {
    from: { opacity: 0 },
    enter: {
      opacity: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "calc(100vh - 70px)"
    },
    leave: { opacity: 0 }
  });

  const handleSwitch = prop => e => {
    if (e.target.name === "PROJECTSPLAN") {
      setState({
        ...state,
        [e.target.name]: { ...state[e.target.name], [prop]: e.target.checked },
        ["GENERALINFORMATION"]: { ...state["GENERALINFORMATION"], [prop]: e.target.checked },
        ["COOPERATIONTERM"]: { ...state["COOPERATIONTERM"], [prop]: e.target.checked },
        ["GOAL"]: { ...state["GOAL"], [prop]: e.target.checked },
        ["SCOPE"]: { ...state["SCOPE"], [prop]: e.target.checked },
        ["HEADINGS2"]: { ...state["HEADINGS2"], [prop]: e.target.checked },
        ["SCHEDULE"]: { ...state["SCHEDULE"], [prop]: e.target.checked },
        ["BUBGETPLAN"]: { ...state["BUBGETPLAN"], [prop]: e.target.checked }
      });
    }
    else if (e.target.name === "PROJECTSEXEC") {
      setState({
        ...state,
        [e.target.name]: { ...state[e.target.name], [prop]: e.target.checked },
        ["PARTICIPANTS"]: { ...state["PARTICIPANTS"], [prop]: e.target.checked },
        ["PHASES"]: { ...state["PHASES"], [prop]: e.target.checked },
        ["ACTIVITIES"]: { ...state["ACTIVITIES"], [prop]: e.target.checked },
        ["EXPENSES"]: { ...state["EXPENSES"], [prop]: e.target.checked },
        ["BUDGETEXECUTION"]: { ...state["BUDGETEXECUTION"], [prop]: e.target.checked },
        ['EXECUTION']: { ...state['EXECUTION'], ['allowView']: e.target.checked }
      });
    }
    else {
      if (e.target.name === "PROFILE" || e.target.name === "USER" || e.target.name === "PARTNER") {
        setState({
          ...state,
          [e.target.name]: { ...state[e.target.name], [prop]: e.target.checked },
          ['CONFIGURATION']: { ...state['CONFIGURATION'], ['allowView']: e.target.checked }
        })
      }
      else if (e.target.name === "USERCOSTS") {
        setState({
          ...state,
          [e.target.name]: { ...state[e.target.name], [prop]: e.target.checked },
          ['EXECUTION']: { ...state['EXECUTION'], ['allowView']: e.target.checked }
        })
      }
      else {
        setState({
          ...state,
          [e.target.name]: { ...state[e.target.name], [prop]: e.target.checked }
        });
      }
    }
  };
  data = profiles;
  const handleTextFields = prop => e => {
    setValidName(false);
    setState({
      ...state,
      [prop]: e.target.value
    });

    data = data.filter(item => item.name === e.target.value);
    if (data.length !== 0) {
      setTitle("PROFILES.nameAlreadyTaken");
      setValidName(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = e => {
    if (nameRef.current.value.length === 0) {
      nameRef.current.focus();
      setTitle("GENERAL.requiredField");
      setValidName(true);
    } else {
      setOpen(true);
    }
    e.preventDefault();
  };
  const handleSubmit = e => {
    getCurrentLoggedProfile(false, matchSateWithFormattedAllowances(state));
    addProfile(state);
    setOpen(false);
    setMustRedirect(true);
    e.preventDefault();
  };

  return transitions.map(({ key, props }) =>
    loading ? (
      <animated.div key={key} style={props}>
        <Spinner />;
      </animated.div>
    ) : (
        <animated.div key={key} style={props}>
          {renderRedirect(mustRedirect)}
          <div className="container">
            <Breadcrumbs
              className={classes.breadCrumbs}
              aria-label="breadcrumb"
              separator={<NavigateNextIcon fontSize="small" />}
            >
              <Typography>{t("ROUTES.planning")}</Typography>
              <Typography>{t("ROUTES.config")}</Typography>
              <Link className={classes.link} to="/profiles">
                {t("PROFILES.profiles")}
              </Link>
              <Typography>{t("GENERAL.add")}</Typography>
            </Breadcrumbs>

            <div className="container-header">
              <div className="title">
                <h1 className={classes.title}>
                  {t("GENERAL.add") + " " + t("PROFILES.profile")}
                </h1>
              </div>
              <Button
                className={classes.blueButton}
                variant="contained"
                color="primary"
                form="formProfiles"
                type="submit"
              >
                <div className={classes.buttonIcon}>
                  <Check />
                </div>
                {t("GENERAL.save") + " " + t("PROFILES.profile")}
              </Button>
            </div>
            <form id="formProfiles" onSubmit={handleClickOpen}>
              <Paper className={classes.rootForm}>
                <div className="cabecalho">
                  {t("PROFILES.profile").toUpperCase()}
                </div>
                <Grid container item>
                  <React.Fragment>
                    <Grid item xs={12}>
                      <p className={classes.newProfileHint}>
                        {t("PROFILES.addHint")}
                      </p>
                    </Grid>
                    <Grid item xs={5} className={classes.inputBox}>
                      <MuiThemeProvider theme={tooltipTheme}>
                        <Tooltip
                          TransitionComponent={Zoom}
                          open={validName}
                          title={t(titleName)}
                        >
                          <TextField
                            inputRef={nameRef}
                            error={validName}
                            className={classes.textField}
                            margin="normal"
                            label={t("PROFILES.nameLabel") + "*"}
                            name="name"
                            onChange={handleTextFields("name")}
                            inputProps={{
                              maxLength: 50
                            }}
                          />
                        </Tooltip>
                      </MuiThemeProvider>
                      <div className={classes.hintsDivFather}>
                        <span className={classes.newProfileRequired}>
                          {t("PROFILES.required")}
                        </span>
                        <span className={classes.textFieldLimit}>
                          {state.name.length}/50
                      </span>
                      </div>
                    </Grid>
                  </React.Fragment>
                  <Grid item xs={7} className={classes.inputBox}>
                    <TextField
                      className={classes.textField}
                      label={t("PROFILES.descriptionLabel")}
                      margin="normal"
                      onChange={e =>
                        setState({ ...state, [e.target.name]: e.target.value })
                      }
                      inputProps={{
                        maxLength: 110
                      }}
                      name="description"
                    />
                    <div className={classes.hintsDivFatherRight}>
                      <span className={classes.textFieldLimit}>
                        {state.description.length}/110
                    </span>
                    </div>
                  </Grid>
                </Grid>
              </Paper>

              <Paper className={classes.rootForm}>
                <div className="cabecalho">{t("PROFILES.form.title")}</div>
                <p className={classes.newProfileHint}>
                  {t("PROFILES.addTypeHint")}
                </p>
                <table className="table">
                  <thead>
                    <tr>
                      <th>{t("PROFILES.form.module")}</th>
                      <th>{t("PROFILES.form.section")}</th>
                      <th>{t("PROFILES.form.add")}</th>
                      <th>{t("PROFILES.form.view")}</th>
                      <th>{t("PROFILES.form.edit")}</th>
                      <th>{t("PROFILES.form.del")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td rowSpan="4" className="secao">
                        <b>{t("PROFILES.form.config")}</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="secao"><b>{t("PROFILES.profile")}</b></td>
                      <td>

                        <CustomSwitch
                          name="PROFILE"
                          onChange={handleSwitch("allowCreate")}
                          checked={state.PROFILE.allowCreate}
                          color="primary"
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="PROFILE"
                          onChange={handleSwitch("allowView")}
                          checked={state.PROFILE.allowView}
                          color="primary"
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="PROFILE"
                          onChange={handleSwitch("allowEdit")}
                          checked={state.PROFILE.allowEdit}
                          color="primary"
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="PROFILE"
                          onChange={handleSwitch("allowDelete")}
                          checked={state.PROFILE.allowDelete}
                          color="primary"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="secao"><b>{t("USERS.users")}</b></td>
                      <td>
                        <CustomSwitch
                          name="USER"
                          onChange={handleSwitch("allowCreate")}
                          checked={state.USER.allowCreate}
                          color="primary"
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="USER"
                          onChange={handleSwitch("allowView")}
                          checked={state.USER.allowView}
                          color="primary"
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="USER"
                          onChange={handleSwitch("allowEdit")}
                          checked={state.USER.allowEdit}
                          color="primary"
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="USER"
                          onChange={handleSwitch("allowDelete")}
                          checked={state.USER.allowDelete}
                          color="primary"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="secao"><b>{t("PARTNERS.partners")}</b></td>
                      <td>
                        <CustomSwitch
                          name="PARTNER"
                          onChange={handleSwitch("allowCreate")}
                          checked={state.PARTNER.allowCreate}
                          color="primary"
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="PARTNER"
                          onChange={handleSwitch("allowView")}
                          checked={state.PARTNER.allowView}
                          color="primary"
                        />
                      </td>

                      <td>
                        <CustomSwitch
                          name="PARTNER"
                          onChange={handleSwitch("allowEdit")}
                          checked={state.PARTNER.allowEdit}
                          color="primary"
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="PARTNER"
                          onChange={handleSwitch("allowDelete")}
                          checked={state.PARTNER.allowDelete}
                          color="primary"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan="9" className="secao">
                        <b>{t("PROFILES.form.workPlan")}</b>
                      </td>
                    </tr>

                    <tr>
                      <td className="secao">
                        <b>{t("PROFILES.form.projects")}</b>
                      </td>
                      <td>
                        <CustomSwitch
                          name="PROJECTSPLAN"
                          onChange={handleSwitch("allowCreate")}
                          checked={state.PROJECTSPLAN.allowCreate}
                          color="primary"
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="PROJECTSPLAN"
                          onChange={handleSwitch("allowView")}
                          checked={state.PROJECTSPLAN.allowView}
                          color="primary"
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="PROJECTSPLAN"
                          onChange={handleSwitch("allowEdit")}
                          checked={state.PROJECTSPLAN.allowEdit}
                          color="primary"
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="PROJECTSPLAN"
                          onChange={handleSwitch("allowDelete")}
                          checked={state.PROJECTSPLAN.allowDelete}
                          color="primary"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="secao">
                        {t("PROFILES.form.generalInformation")}
                      </td>
                      <td>
                        <CustomSwitch
                          name="GENERALINFORMATION"
                          onChange={state.PROJECTSPLAN.allowCreate ? handleSwitch("allowCreate") : console.log("")}
                          checked={state.GENERALINFORMATION.allowCreate}
                          color="primary"
                          disabled={!state.PROJECTSPLAN.allowCreate}
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="GENERALINFORMATION"
                          onChange={handleSwitch("allowView")}
                          checked={state.GENERALINFORMATION.allowView}
                          color="primary"
                          disabled={!state.PROJECTSPLAN.allowView}
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="GENERALINFORMATION"
                          onChange={handleSwitch("allowEdit")}
                          checked={state.GENERALINFORMATION.allowEdit}
                          color="primary"
                          disabled={!state.PROJECTSPLAN.allowEdit}
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="GENERALINFORMATION"
                          onChange={handleSwitch("allowDelete")}
                          checked={state.GENERALINFORMATION.allowDelete}
                          color="primary"
                          disabled={!state.PROJECTSPLAN.allowDelete}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="secao">
                        {t("PROFILES.form.cooperationTerm")}
                      </td>
                      <td>
                        <CustomSwitch
                          name="COOPERATIONTERM"
                          onChange={handleSwitch("allowCreate")}
                          checked={state.COOPERATIONTERM.allowCreate}
                          color="primary"
                          disabled={!state.PROJECTSPLAN.allowCreate}
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="COOPERATIONTERM"
                          onChange={handleSwitch("allowView")}
                          checked={state.COOPERATIONTERM.allowView}
                          color="primary"
                          disabled={!state.PROJECTSPLAN.allowView}
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="COOPERATIONTERM"
                          onChange={handleSwitch("allowEdit")}
                          checked={state.COOPERATIONTERM.allowEdit}
                          color="primary"
                          disabled={!state.PROJECTSPLAN.allowEdit}
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="COOPERATIONTERM"
                          onChange={handleSwitch("allowDelete")}
                          checked={state.COOPERATIONTERM.allowDelete}
                          color="primary"
                          disabled={!state.PROJECTSPLAN.allowDelete}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="secao">{t("PROFILES.form.goal")}</td>
                      <td>
                        <CustomSwitch
                          name="GOAL"
                          onChange={handleSwitch("allowCreate")}
                          checked={state.GOAL.allowCreate}
                          color="primary"
                          disabled={!state.PROJECTSPLAN.allowCreate}
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="GOAL"
                          onChange={handleSwitch("allowView")}
                          checked={state.GOAL.allowView}
                          color="primary"
                          disabled={!state.PROJECTSPLAN.allowView}
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="GOAL"
                          onChange={handleSwitch("allowEdit")}
                          checked={state.GOAL.allowEdit}
                          color="primary"
                          disabled={!state.PROJECTSPLAN.allowEdit}
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="GOAL"
                          onChange={handleSwitch("allowDelete")}
                          checked={state.GOAL.allowDelete}
                          color="primary"
                          disabled={!state.PROJECTSPLAN.allowDelete}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="secao">{t("PROFILES.form.scope")}</td>
                      <td>
                        <CustomSwitch
                          name="SCOPE"
                          onChange={handleSwitch("allowCreate")}
                          checked={state.SCOPE.allowCreate}
                          color="primary"
                          disabled={!state.PROJECTSPLAN.allowCreate}
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="SCOPE"
                          onChange={handleSwitch("allowView")}
                          checked={state.SCOPE.allowView}
                          color="primary"
                          disabled={!state.PROJECTSPLAN.allowView}
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="SCOPE"
                          onChange={handleSwitch("allowEdit")}
                          checked={state.SCOPE.allowEdit}
                          color="primary"
                          disabled={!state.PROJECTSPLAN.allowEdit}
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="SCOPE"
                          onChange={handleSwitch("allowDelete")}
                          checked={state.SCOPE.allowDelete}
                          color="primary"
                          disabled={!state.PROJECTSPLAN.allowDelete}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="secao">{t("PROFILES.form.headings")}</td>
                      <td>
                        <CustomSwitch
                          name="HEADINGS2"
                          onChange={handleSwitch("allowCreate")}
                          checked={state.HEADINGS2.allowCreate}
                          color="primary"
                          disabled={!state.PROJECTSPLAN.allowCreate}
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="HEADINGS2"
                          onChange={handleSwitch("allowView")}
                          checked={state.HEADINGS2.allowView}
                          color="primary"
                          disabled={!state.PROJECTSPLAN.allowView}
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="HEADINGS2"
                          onChange={handleSwitch("allowEdit")}
                          checked={state.HEADINGS2.allowEdit}
                          color="primary"
                          disabled={!state.PROJECTSPLAN.allowEdit}
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="HEADINGS2"
                          onChange={handleSwitch("allowDelete")}
                          checked={state.HEADINGS2.allowDelete}
                          color="primary"
                          disabled={!state.PROJECTSPLAN.allowDelete}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="secao">{t("PROFILES.form.schedule")}</td>
                      <td>
                        <CustomSwitch
                          name="SCHEDULE"
                          onChange={handleSwitch("allowCreate")}
                          checked={state.SCHEDULE.allowCreate}
                          color="primary"
                          disabled={!state.PROJECTSPLAN.allowCreate}
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="SCHEDULE"
                          onChange={handleSwitch("allowView")}
                          checked={state.SCHEDULE.allowView}
                          color="primary"
                          disabled={!state.PROJECTSPLAN.allowView}
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="SCHEDULE"
                          onChange={handleSwitch("allowEdit")}
                          checked={state.SCHEDULE.allowEdit}
                          color="primary"
                          disabled={!state.PROJECTSPLAN.allowEdit}
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="SCHEDULE"
                          onChange={handleSwitch("allowDelete")}
                          checked={state.SCHEDULE.allowDelete}
                          color="primary"
                          disabled={!state.PROJECTSPLAN.allowDelete}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="secao">
                        {t("PROFILES.form.budget")}
                      </td>
                      <td>
                        <CustomSwitch
                          name="BUBGETPLAN"
                          onChange={handleSwitch("allowCreate")}
                          checked={state.BUBGETPLAN.allowCreate}
                          color="primary"
                          disabled={!state.PROJECTSPLAN.allowCreate}
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="BUBGETPLAN"
                          onChange={handleSwitch("allowView")}
                          checked={state.BUBGETPLAN.allowView}
                          color="primary" disabled={!state.PROJECTSPLAN.allowView}
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="BUBGETPLAN"
                          onChange={handleSwitch("allowEdit")}
                          checked={state.BUBGETPLAN.allowEdit}
                          color="primary"
                          disabled={!state.PROJECTSPLAN.allowEdit}
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="BUBGETPLAN"
                          onChange={handleSwitch("allowDelete")}
                          checked={state.BUBGETPLAN.allowDelete}
                          color="primary"
                          disabled={!state.PROJECTSPLAN.allowDelete}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td rowSpan="11" className="secao">
                        <b>{t("PROFILES.form.labor")}</b>
                      </td>
                    </tr>

                    <tr>
                      <td className="secao">
                        <b>
                          {t("PROFILES.form.projects")}
                        </b>
                      </td>
                      <td>
                        <CustomSwitch
                          name="PROJECTSEXEC"
                          onChange={handleSwitch("allowCreate")}
                          checked={state.PROJECTSEXEC.allowCreate}
                          color="primary"
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="PROJECTSEXEC"
                          onChange={handleSwitch("allowView")}
                          checked={state.PROJECTSEXEC.allowView}
                          color="primary"
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="PROJECTSEXEC"
                          onChange={handleSwitch("allowEdit")}
                          checked={state.PROJECTSEXEC.allowEdit}
                          color="primary"
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="PROJECTSEXEC"
                          onChange={handleSwitch("allowDelete")}
                          checked={state.PROJECTSEXEC.allowDelete}
                          color="primary"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="secao">
                        {t("PROFILES.form.participants")}
                      </td>
                      <td>
                        <CustomSwitch
                          name="PARTICIPANTS"
                          onChange={handleSwitch("allowCreate")}
                          checked={state.PARTICIPANTS.allowCreate}
                          color="primary"
                          disabled={!state.PROJECTSEXEC.allowCreate}
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="PARTICIPANTS"
                          onChange={handleSwitch("allowView")}
                          checked={state.PARTICIPANTS.allowView}
                          color="primary"
                          disabled={!state.PROJECTSEXEC.allowView}
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="PARTICIPANTS"
                          onChange={handleSwitch("allowEdit")}
                          checked={state.PARTICIPANTS.allowEdit}
                          color="primary"
                          disabled={!state.PROJECTSEXEC.allowEdit}
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="PARTICIPANTS"
                          onChange={handleSwitch("allowDelete")}
                          checked={state.PARTICIPANTS.allowDelete}
                          color="primary"
                          disabled={!state.PROJECTSEXEC.allowDelete}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="secao">{t("PROFILES.form.phases")}</td>
                      <td>
                        <CustomSwitch
                          name="PHASES"
                          onChange={handleSwitch("allowCreate")}
                          checked={state.PHASES.allowCreate}
                          color="primary"
                          disabled={!state.PROJECTSEXEC.allowCreate}
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="PHASES"
                          onChange={handleSwitch("allowView")}
                          checked={state.PHASES.allowView}
                          color="primary"
                          disabled={!state.PROJECTSEXEC.allowView}
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="PHASES"
                          onChange={handleSwitch("allowEdit")}
                          checked={state.PHASES.allowEdit}
                          color="primary"
                          disabled={!state.PROJECTSEXEC.allowEdit}
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="PHASES"
                          onChange={handleSwitch("allowDelete")}
                          checked={state.PHASES.allowDelete}
                          color="primary"
                          disabled={!state.PROJECTSEXEC.allowDelete}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="secao">{t("PROFILES.form.activities")}</td>
                      <td>
                        <CustomSwitch
                          name="ACTIVITIES"
                          onChange={handleSwitch("allowCreate")}
                          checked={state.ACTIVITIES.allowCreate}
                          color="primary"
                          disabled={!state.PROJECTSEXEC.allowCreate}
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="ACTIVITIES"
                          onChange={handleSwitch("allowView")}
                          checked={state.ACTIVITIES.allowView}
                          color="primary"
                          disabled={!state.PROJECTSEXEC.allowView}
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="ACTIVITIES"
                          onChange={handleSwitch("allowEdit")}
                          checked={state.ACTIVITIES.allowEdit}
                          color="primary"
                          disabled={!state.PROJECTSEXEC.allowEdit}
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="ACTIVITIES"
                          onChange={handleSwitch("allowDelete")}
                          checked={state.ACTIVITIES.allowDelete}
                          color="primary"
                          disabled={!state.PROJECTSEXEC.allowDelete}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="secao">{t("PROFILES.form.expenses")}</td>
                      <td>
                        <CustomSwitch
                          name="EXPENSES"
                          onChange={handleSwitch("allowCreate")}
                          checked={state.EXPENSES.allowCreate}
                          color="primary"
                          disabled={!state.PROJECTSEXEC.allowCreate}
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="EXPENSES"
                          onChange={handleSwitch("allowView")}
                          checked={state.EXPENSES.allowView}
                          color="primary"
                          disabled={!state.PROJECTSEXEC.allowView}
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="EXPENSES"
                          onChange={handleSwitch("allowEdit")}
                          checked={state.EXPENSES.allowEdit}
                          color="primary"
                          disabled={!state.PROJECTSEXEC.allowEdit}
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="EXPENSES"
                          onChange={handleSwitch("allowDelete")}
                          checked={state.EXPENSES.allowDelete}
                          color="primary"
                          disabled={!state.PROJECTSEXEC.allowDelete}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="secao">
                        {t("PROFILES.form.budgetExecution")}
                      </td>
                      <td>
                        <CustomSwitch
                          name="BUDGETEXECUTION"
                          onChange={handleSwitch("allowCreate")}
                          checked={state.BUDGETEXECUTION.allowCreate}
                          color="primary"
                          disabled={!state.PROJECTSEXEC.allowCreate}
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="BUDGETEXECUTION"
                          onChange={handleSwitch("allowView")}
                          checked={state.BUDGETEXECUTION.allowView}
                          color="primary"
                          disabled={!state.PROJECTSEXEC.allowView}
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="BUDGETEXECUTION"
                          onChange={handleSwitch("allowEdit")}
                          checked={state.BUDGETEXECUTION.allowEdit}
                          color="primary"
                          disabled={!state.PROJECTSEXEC.allowEdit}
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="BUDGETEXECUTION"
                          onChange={handleSwitch("allowDelete")}
                          checked={state.BUDGETEXECUTION.allowDelete}
                          color="primary"
                          disabled={!state.PROJECTSEXEC.allowDelete}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="secao"><b>{t("PROFILES.form.tempFinancialReports")}</b></td>
                      <td>
                        <CustomSwitch
                          name="TEMPFIANCIALSREPORT"
                          onChange={handleSwitch("allowCreate")}
                          checked={state.TEMPFIANCIALSREPORT.allowCreate}
                          color="primary"
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="TEMPFIANCIALSREPORT"
                          onChange={handleSwitch("allowView")}
                          checked={state.TEMPFIANCIALSREPORT.allowView}
                          color="primary"
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="TEMPFIANCIALSREPORT"
                          onChange={handleSwitch("allowEdit")}
                          checked={state.TEMPFIANCIALSREPORT.allowEdit}
                          color="primary"
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="TEMPFIANCIALSREPORT"
                          onChange={handleSwitch("allowDelete")}
                          checked={state.TEMPFIANCIALSREPORT.allowDelete}
                          color="primary"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="secao"><b>{t("PROFILES.form.genetalFinanReports")}</b></td>
                      <td>
                        <CustomSwitch
                          name="GENERALFIANREPORT"
                          onChange={handleSwitch("allowCreate")}
                          checked={state.GENERALFIANREPORT.allowCreate}
                          color="primary"
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="GENERALFIANREPORT"
                          onChange={handleSwitch("allowView")}
                          checked={state.GENERALFIANREPORT.allowView}
                          color="primary"
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="GENERALFIANREPORT"
                          onChange={handleSwitch("allowEdit")}
                          checked={state.GENERALFIANREPORT.allowEdit}
                          color="primary"
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="GENERALFIANREPORT"
                          onChange={handleSwitch("allowDelete")}
                          checked={state.GENERALFIANREPORT.allowDelete}
                          color="primary"
                        />
                      </td>
                    </tr>

                    {/* <tr>
                      <td className="secao"><b>{t("PROFILES.form.manegementReports")}</b></td>
                      <td>
                        <CustomSwitch
                          name="MANAGEMENTREPORT"
                          onChange={handleSwitch("allowCreate")}
                          checked={state.MANAGEMENTREPORT.allowCreate}
                          color="primary"
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="MANAGEMENTREPORT"
                          onChange={handleSwitch("allowView")}
                          checked={state.MANAGEMENTREPORT.allowView}
                          color="primary"
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="MANAGEMENTREPORT"
                          onChange={handleSwitch("allowEdit")}
                          checked={state.MANAGEMENTREPORT.allowEdit}
                          color="primary"
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="MANAGEMENTREPORT"
                          onChange={handleSwitch("allowDelete")}
                          checked={state.MANAGEMENTREPORT.allowDelete}
                          color="primary"
                        />
                      </td>
                    </tr> */}

                    <tr>
                      <td className="secao"><b>{t("PROFILES.form.userCosts")}</b></td>
                      <td>
                        <CustomSwitch
                          name="USERCOSTS"
                          onChange={handleSwitch("allowCreate")}
                          checked={state.USERCOSTS.allowCreate}
                          color="primary"
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="USERCOSTS"
                          onChange={handleSwitch("allowView")}
                          checked={state.USERCOSTS.allowView}
                          color="primary"
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="USERCOSTS"
                          onChange={handleSwitch("allowEdit")}
                          checked={state.USERCOSTS.allowEdit}
                          color="primary"
                        />
                      </td>
                      <td>
                        <CustomSwitch
                          name="USERCOSTS"
                          onChange={handleSwitch("allowDelete")}
                          checked={state.USERCOSTS.allowDelete}
                          color="primary"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Paper>
            </form>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle className={classes.Title}>
                {t("PROFILES.createProfile")}
                <IconButton style={{float:"right", marginTop: -10, marginRight: -17}} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {t("PROFILES.createProfileQuestion")}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Buttons
                  onClick={handleClose}
                  tipo="BN-noback"
                  conteudo={t("GENERAL.cancel").toUpperCase()}
                />
                <Buttons
                  onClick={handleSubmit}
                  // form="formUsers"
                  // type="submit"
                  tipo="BN-blue"
                  conteudo={t("GENERAL.save").toUpperCase()}
                />
              </DialogActions>
            </Dialog>
          </div>
          <Footer />
        </animated.div>
      )
  );
};

export default NewProfile;
