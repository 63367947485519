import React from "react";
import {Button, ButtonText, ButtonWrapper, DownloadIconStyled, IconWrapper} from "./ExportButton.styled";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function ExportButton({isLoading, onClick, title}) {
    return (
        <ButtonWrapper style={{float: "right", marginRight: 10}}>
            <ButtonText style={{float: "right", marginRight: 10}}>{title}</ButtonText>
            {isLoading ? <CircularProgress/> : (
                <Button onClick={onClick}>
                    <IconWrapper>
                        <DownloadIconStyled/>
                    </IconWrapper>
                </Button>
            )}
        </ButtonWrapper>
    )
}
