import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Delete from "@material-ui/icons/Delete";
import { useStyles } from "components/layout/CommonStyles";
import React, { useEffect, useState } from "react";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles
} from "@material-ui/core/styles";
import Zoom from "@material-ui/core/Zoom";
import { useTranslation } from "react-i18next";

const tooltipTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#2c3e51"
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: "white",
        fontSize: "1em",
        backgroundColor: "red",
        opacity: "0.7"
      }
    }
  }
});

const NewRubric = props => {
  const classes = useStyles();
  const {
    deleteRubric,
    lastHeading,
    headingDescription,
    headingValue,
    estimatedCost,
    updateFields,
    disabled,
    showRequiredFieldMessage,
    setValueRequiredMessage,
    listRubric,
    valedTrueStateCallback,
    valedFalseStateCallback
  } = props;
  const [description, setDescription] = useState(headingDescription);
  const [validRubric, setValidRubric] = useState(false);
  const [value, setValue] = useState(headingValue ? headingValue : "");
  const [t] = useTranslation();

  useEffect(() => {
    setDescription(headingDescription);
  }, [headingDescription]);

  useEffect(() => {
    setValue(headingValue);
  }, [headingValue]);

  useEffect(() => {
    updateFields();
  });

  var result = []
  const handleChangeDescription = event => {
    setDescription(event.target.value);
    var listFormated = listRubric.toString().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase().replace(/^\s+|\s+$/g,"").replace(/\s{2,}/g, "").split(",")
    var typed = event.target.value.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase().replace(/^\s+|\s+$/g,"").replace(/\s{2,}/g, "")
    if (listFormated.includes(typed)){
      setValidRubric(true)
    }else{
      setValidRubric(false)
    }
  };


  const converTotBrCurrency = number => {
    var temp = "";
    var len = number.length;
    var count = 0;

    for (var i = 0; i < len; i++) {
      if (i === 1) {
        var aux = "," + number[i] + temp;
        temp = aux;
      } else {
        if (count === 3) {
          count = 1;
          var aux = number[i] + "." + temp;
          temp = aux;
        } else {
          if (i > 1) {
            count += 1;
          }
          var aux = number[i] + temp;
          temp = aux;
        }
      }
    }

    return temp;
  };

  const handleValueChange = event => {
    if (event.target.value !== " " && !event.target.value.includes(" ")) {
      var str = event.target.value.split("");
      var aux = str.filter(el => el !== "." && el !== ",").join("");
      var value = event.target.value;

      if (event.target.value) {
        value = converTotBrCurrency(
          aux
            .split("")
            .reverse()
            .join("")
        );
      }

      aux = value.split("").filter(el => el !== ".").join("").replace(",", ".")
      if (!isNaN(aux)) {
        setValue(value);
      }
    }
  };

  const handleClick = () => {
    deleteRubric(lastHeading);
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={4}>
      <MuiThemeProvider theme={tooltipTheme}>
      <Tooltip
       TransitionComponent={Zoom}
        open={validRubric}
        title={t("PROJECTS.rubricAlreadyExists")}> 
          <TextField
            error={validRubric}
            id={"descHeading" + lastHeading}
            disabled={disabled}
            className={classes.textField}
            value={description}
            margin="normal"
            onChange={handleChangeDescription}
          />
          </Tooltip>
      </MuiThemeProvider>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id={"headingValue" + lastHeading}
            disabled={disabled}
            className={classes.textField}
            margin="normal"
            name="headingValue"
            value={value}
            onChange={handleValueChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={1}>
          <TextField
            id={"percentage" + lastHeading}
            disabled={disabled}
            className={classes.textField}
            value={
              value && estimatedCost > 0
                ? (
                  (parseFloat(
                    value
                      .split("")
                      .filter(el => el !== ".")
                      .join("")
                      .replace(",", ".")
                  ) /
                    estimatedCost) *
                  100
                ).toFixed(2)
                : "0.00"
            }
            margin="normal"
          />
        </Grid>
        <Grid item xs={1} style={{ marginTop: "8px" }}>
          <Tooltip title="Deletar" aria-label="delete" placement="top">
            <IconButton
              disabled={disabled}
              onClick={handleClick}
              style={{ float: "right" }}
              className={classes.icons}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </Grid>
        <div className="lineBreak"></div>
      </Grid>
    </div>
  );
};

export default NewRubric;
