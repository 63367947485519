import AppBar from '@material-ui/core/AppBar';
import IconButton from "@material-ui/core/IconButton";
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from "@material-ui/core/Typography";
import GetApp from "@material-ui/icons/GetApp";
import { useStyles } from "components/layout/CommonStyles";
import ProjectsContext from "context/projects/projectsContext";
import "moment/locale/pt-br";
import PropTypes from 'prop-types';
import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "components/profiles/css/FormStyle.css";
import HistoryEquipmentTable from "./HistoryEquipmentTable";
import Toolbar from "@material-ui/core/Toolbar";
import InputBase from "@material-ui/core/InputBase";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Dissatisfied from "@material-ui/icons/SentimentVeryDissatisfied";
import ExcelJS from 'exceljs';

function TabPanel(props) {
    const { children, value, index } = props;
    return (
        <div>
            {value === index && children}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const HistoryEquipmentTabPanel = props => {
    const fileDownload = require("js-file-download");
    const projectsContext = useContext(ProjectsContext);
    const { panelTab, setValuePanelTab } = projectsContext;
    const classes = useStyles();
    const [t] = useTranslation();
    const { historyEquipment } = props;

    const [value, setValue] = React.useState(panelTab);
    const [dataFilter, setDataFilter] = useState([]);

    useEffect(() => {
        setDataFilter(historyEquipment);
    }, []);

    const hasItens = historyEquipment.length > 0;
    const [hasNoResult, setHasNoResult] = useState(hasItens && dataFilter.length === 0);

    const operations = [
        "Alteração de Local",
        "Alteração de Responsável",
        "Alteração de Status",
        "Primeiro Local",
        "Primeiro Responsável",
        "Primeiro Status"
    ];

    const [findText, setFindText] = useState("");
    const [filterOperation, setFilterOperation] = useState("");
    const [filterExecutor, setFilterExecutor] = useState("");
    const [filterPreviousValue, setFilterPreviousValue] = useState("");
    const [filterNewValue, setFilterNewValue] = useState("");

    const handleChangePanel = (event, newValue) => {
        setValue(newValue);
        setValuePanelTab(newValue);
    };

    const [dates, setDates] = useState({
        date: null,
    });

    const handleDates = name => e => {
        setDates({
            ...dates,
            [name]: e
        });
    };

    useEffect(() => {
        let newDataFilter = historyEquipment;

        if (dates.date && (dates.date instanceof Date && !isNaN(dates.date))) {
            newDataFilter = newDataFilter.filter(hist => {
                const date = new Date(hist.date);
                return (
                    date.getDate() === dates.date.getDate() &&
                    date.getMonth() === dates.date.getMonth() &&
                    date.getFullYear() === dates.date.getFullYear()
                )
            });

            if (!findText && newDataFilter.length === 0) setFindText(dates.date.toLocaleDateString());
        }

        if (filterExecutor) {
            newDataFilter = newDataFilter.filter(hist =>
                hist.executor &&
                hist.executor.toLowerCase().includes(filterExecutor.toLowerCase())
            );

            if (!findText && newDataFilter.length === 0) setFindText(filterExecutor);
        }

        if (filterPreviousValue) {
            newDataFilter = newDataFilter.filter(hist =>
                hist.oldValue &&
                hist.oldValue.toLowerCase().includes(filterPreviousValue.toLowerCase())
            );

            if (!findText && newDataFilter.length === 0) setFindText(filterPreviousValue);
        }

        if (filterNewValue) {
            newDataFilter = newDataFilter.filter(hist =>
                hist.newValue &&
                hist.newValue.toLowerCase().includes(filterNewValue.toLowerCase())
            );

            if (!findText && newDataFilter.length === 0) setFindText(filterNewValue);
        }

        if (filterOperation) {
            newDataFilter = newDataFilter.filter(hist =>
                hist.operation &&
                hist.operation.toLowerCase().includes(filterOperation.toLowerCase())
            );

            if (!findText && newDataFilter.length === 0) setFindText(filterOperation);
        }

        if (newDataFilter.length > 0) {
            setFindText("");
        }

        setDataFilter(newDataFilter);
    }, [dates, filterExecutor, filterPreviousValue, filterNewValue, filterOperation]);

    const valueByColumn = (key) => {
        if (key === "date") {
            return dataFilter.map(obj => {
                const dateFormatted = new Date(obj.date)
                const FormattedBr = dateFormatted.getDate() + "/" + (dateFormatted.getMonth() + 1) + "/" + dateFormatted.getFullYear();
                return FormattedBr;
            });
        }
        else {
            return dataFilter.map(obj => {
                if (obj[key]) return obj[key];
                return "";
            });
        }
    }

    const handleExport = () => {

        const filename = "exportar historicoEquipamento.xlsx";

        const workbook = new ExcelJS.Workbook();
        const ws = workbook.addWorksheet("Report");

        // Style header
        ws.getRow(1).font = { name: "Calibri", size: 14, bold: true };

        // Create columns

        ws.columns = [
            { header: 'Data', key: 'date' },
            { header: 'Operação', key: 'operation' },
            { header: 'Valor Anterior', key: 'oldValue' },
            { header: 'Novo Valor', key: 'newValue' },
            { header: 'Operador', key: 'executor' },
        ];

        // Add columns values
        ws.columns.forEach(col => {
            //Add column data
            const res = valueByColumn(col.key);
            //Add column name
            res.unshift(t(col.header));

            col.values = res;
            col.width = col.header.length + 20;
        });

        // Download file
        workbook.xlsx.writeBuffer().then(function (data) {
            const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            fileDownload(blob, filename);
        });
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" style={{ boxShadow: "none", transform: "none" }}>
                <Tabs style={{ backgroundColor: "#f5f6fa", color: "black" }} indicatorColor="primary" value={value} onChange={handleChangePanel} >
                    <Tab style={{ fontWeight: "bold" }} label={t("INVENTORY.historyItem").toUpperCase()} {...a11yProps(0)} />
                    <div className="cabecalho" style={{ flex: "auto" }}>
                        <span style={{ float: "right", marginRight: 10 }}>
                            <span style={{ float: "right", marginRight: 10 }}>{t("INVENTORY.export").toUpperCase()}
                                <IconButton className="btn" style={{ float: "right" }} edge="end" color="inherit" onClick={handleExport}>
                                    <GetApp />
                                </IconButton>
                            </span>
                        </span>
                    </div>
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <div className={classes.divAddParticipantLeft}>
                    <Toolbar className={classes.searchToolbarInventory}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <div style={{ display: !hasItens ? "none" : "" }} className={classes.searchInventory}>
                                <KeyboardDatePicker
                                    className={classes.textField}
                                    disableToolbar
                                    autoOk={true}
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    id="date"
                                    invalidDateMessage=""
                                    label={t("INVENTORY.historyDate")}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date"
                                    }}
                                    value={dates.date}
                                    onChange={handleDates("date")}
                                />
                            </div>
                        </MuiPickersUtilsProvider>
                    </Toolbar>
                    <Toolbar className={classes.searchToolbarInventory} >
                        <div style={{ display: !hasItens ? "none" : "" }} className={classes.searchInventory}>
                            <InputBase
                                placeholder={t("INVENTORY.operator")}
                                value={filterExecutor}
                                onChange={(ev) => setFilterExecutor(ev.target.value)}
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputSearch
                                }}
                                inputProps={{ "aria-label": "search" }}
                                style={{ width: "100%" }}
                            />
                        </div>
                    </Toolbar>
                    <Toolbar className={classes.searchToolbarInventory} >
                        <div style={{ display: !hasItens ? "none" : "" }} className={classes.searchInventory} >
                            <TextField
                                select
                                id="searchOperation"
                                label={t("INVENTORY.HistoryOperation")}
                                name="searchoperation"
                                style={{ minWidth: "250px", marginTop: 0, marginBottom: 0, marginLeft: 5 }}
                                value={filterOperation}
                                className={classes.textField}
                                onChange={(ev) => setFilterOperation(ev.target.value)}
                                margin="normal"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputSearch
                                }}
                                inputProps={{ "aria-label": "search" }}
                            >
                                {operations.map(operation => (
                                    <MenuItem key={operation} value={operation}>
                                        {operation}
                                    </MenuItem>
                                ))}
                            </TextField>

                        </div>
                    </Toolbar>
                    <Toolbar className={classes.searchToolbarInventory} >
                        <div style={{ display: !hasItens ? "none" : "" }} className={classes.searchInventory}>
                            <InputBase
                                placeholder={t("INVENTORY.previousValue")}
                                value={filterPreviousValue}
                                onChange={(ev) => setFilterPreviousValue(ev.target.value)}
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputSearch
                                }}
                                inputProps={{ "aria-label": "search" }}
                                style={{ width: "100%" }}
                            />
                        </div>
                    </Toolbar>
                    <Toolbar className={classes.searchToolbarInventory} >
                        <div style={{ display: !hasItens ? "none" : "" }} className={classes.searchInventory}>
                            <InputBase
                                placeholder={t("INVENTORY.newValue")}
                                value={filterNewValue}
                                onChange={(ev) => setFilterNewValue(ev.target.value)}
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputSearch
                                }}
                                inputProps={{ "aria-label": "search" }}
                                style={{ width: "100%" }}
                            />
                        </div>
                    </Toolbar>
                </div>
                {dataFilter.length > 0 ?
                    <HistoryEquipmentTable
                        rows={dataFilter}
                        mode={"view"}
                        headers={["id", "date", "operation", "description", "place", "oldValue", "newValue", "executor", "itemStatus", "placeId", "responsibleId", "itemStatusId"]}
                        visibleHeaders={["Data", "Operação", "Valor Anterior", "novo valor", "Operador", ""]}
                        sortedFields={["date", "operation", "oldValue", "newValue", "executor"]}
                        mainField="date"
                    />
                    :
                    (hasNoResult) ?
                        <div className={classes.noParticipantsMessage}>
                            <div >
                                <Dissatisfied className={classes.notFoundIconStyle} />
                            </div>
                            <h2>{t("GENERAL.noResultsFound")}</h2>
                            <h5>{`Sua busca por "${findText}" não retornou resultados.`}</h5>
                        </div>
                        :
                        <div className={classes.noFilesMessage}>
                            <Typography variant={"h6"} style={{ fontWeight: "bold" }}>{t("INVENTORY.noHistoryMessage")}</Typography>
                            <Typography variant={"caption"}>{t("INVENTORY.noHistorySubMessage")}</Typography>
                        </div>
                }
            </TabPanel>
        </div>
    );
}

export default HistoryEquipmentTabPanel;