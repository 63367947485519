/* eslint-disable */
import Paper from "@material-ui/core/Paper";
import Box from '@material-ui/core/Box';
import CollaboratorContext from "context/hrModule/collaborator/collaboratorContext";
import React, { useContext, useEffect, useState } from "react";
import history from "history.js";
import { useTranslation } from "react-i18next";
import { useStyles } from "components/layout/CommonStyles";
import { useStyles as useInformationsStyles } from "./styles.js";

import Grid from "@material-ui/core/Grid";
import Input from "components/inputs/Input";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from "@material-ui/core/Typography";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { FormatDateToDataBase } from "utils/dates/FormatDateToDataBase";
import Transition from "components/layout/transition/Transition";
import Buttons from "components/buttons/Pressed";
import QualificationTable from "./QualificationTable"
import QualificationFormDialog from './QualificationFormDialog';
import DialogCancelOperation from "components/dialogs/DialogCancelOperation";

const FormationForm = (props) => {
    const defaultImage = window.location.origin;
    const classes = useStyles();
    const styles = useInformationsStyles();
    const [t] = useTranslation();
    const collaboratorContext = useContext(CollaboratorContext);
    const { userId, context, ...others } = props;
    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }

    const [openQualificationForm, setOpenQualificationForm] = useState(false);
    const [idQualification, setIdQualification] = useState("");
    const [openCancelDialog, setOpenCancelDialog] = useState(false);
    const [hasChange, setHasChange] = useState(false);

    const [requiredLevel, setRequiredLevel] = useState(false);
    const [requiredOngoingEducation, setRequiredOngoingEducation] = useState(false);

    const handleChangeRadio = (index, name, value) => {
        setHasChange(true);
        const rowsField = [...values.languageData];
        const field = rowsField[index];
        if (field) {
            if (name == "reading")
                field.reading = value;
            else if (name == "writing")
                field.writing = value;
            else if (name == "conversation")
                field.conversation = value;

            setValues({ ...values, dependents: rowsField });
        }
    };

    const {
        loading,
        informationFormation,
        getInformationFormation,
        setLoading,
        setLoadingFalse,
        updateInformationFormation,
        addInformationFormation
    } = collaboratorContext

    const [values, setValues] = useState({
        id: "",
        course: "",
        lattes: "",
        institution: "",
        inProgress: "",
        educationType: "",
        languageData: [],
        qualificationData: []
    });

    const [dates, setDates] = useState({
        dateConclusion: null,
    });


    const [qualificationDelete, setQualificationDelete] = useState([]);
    const [contextForm, setContextForm] = useState("new");

    const clearValues = () => {
        setValues({
            id: "",
            course: "",
            lattes: "",
            institution: "",
            inProgress: "",
            educationType: "",
            languageData: [],
        });
    }

    async function fetchData(userId) {
        await setLoading();
        setQualificationDelete([]);
        clearValues();
        await getInformationFormation(userId);
        setLoadingFalse();
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData(userId);
    }, []);

    useEffect(() => {
        setRequiredLevel(false);
    }, [values.educationType])

    useEffect(() => {
        setRequiredOngoingEducation(false);
    }, [values.inProgress])

    useEffect(() => {
        if (informationFormation) {
            setValues({
                id: informationFormation.id ? informationFormation.id : null,
                lattes: informationFormation.lattesUrl ? informationFormation.lattesUrl : "",
                course: informationFormation.courseName ? informationFormation.courseName : "",
                inProgress: informationFormation.ongoingEducation ? "YES" : "NO",
                educationType: informationFormation.educationType ? informationFormation.educationType : "",
                institution: informationFormation.institution ? informationFormation.institution : "",
                languageData: informationFormation.portalSecUserEducationLanguageLevels ? informationFormation.portalSecUserEducationLanguageLevels : [],
                qualificationData: informationFormation.portalSecUserEducationQualification ? informationFormation.portalSecUserEducationQualification : []
            })
            setDates({
                dateConclusion: informationFormation.conclusionDate ? informationFormation.conclusionDate : null
            })
        }
    }, [informationFormation.length, informationFormation])

    const handleChange = (name, value) => {
        setHasChange(true);
        let val = value;
        setValues({ ...values, [name]: val })
    }

    const handleDates = name => e => {
        setHasChange(true);
        setDates({
            ...dates,
            [name]: e
        });
    };

    const addDependent = () => {
        setValues({
            ...values,
            languageData: [...values.languageData, { reading: "1", writing: "1", conversation: "1" }]
        });
    }

    const removeQualification = (id) => {
        var rowsField = [...values.qualificationData];
        var deleteArray = rowsField.filter(x => x.generalId === id);
        rowsField = rowsField.filter(x => x.generalId !== id);

        if (deleteArray[0].id) {
            setQualificationDelete(qualificationDelete => [...qualificationDelete, deleteArray[0]]);
        }

        handleChange("qualificationData", rowsField);

    }

    const handleCancel = () => {
        if (hasChange) {
            setOpenCancelDialog(true);
        } else {
            history.push("/Collaborators");
        }
    }

    const setQualifications = (value, date, file, context, id) => {
        if (context === "edit") {
            let rows = values.qualificationData.map(function (num) {
                if (num.generalId === id) {
                    num.qualificationName = value.qualificationName,
                        num.qualificationType = value.qualificationType,
                        num.institution = value.qualificationInstitution,
                        num.files = file,
                        num.dateConclusion = date.dateConclusion
                }
                return num;
            });
            handleChange("qualificationData", rows);
        } else {
            setValues({
                ...values,
                qualificationData: [...values.qualificationData, {
                    qualificationName: value.qualificationName,
                    qualificationType: value.qualificationType,
                    institution: value.qualificationInstitution,
                    // files: file,
                    dateConclusion: date.dateConclusion
                }]
            });
            setHasChange(true);
        }
    }

    function isValidDate(d) {
        return d instanceof Date && !isNaN(d);
    }

    const verifyCanSubmit = () => {
        let res = true;

        if (!values.educationType) {
            res = false;
            setRequiredLevel(true);
        }
        if (!values.inProgress) {
            res = false;
            setRequiredOngoingEducation(true);
        }
        if (dates.dateConclusion && !isValidDate(new Date(dates.dateConclusion))) {
            res = false;
            //setRequiredName(true);
        }

        return res;
    };


    const handleApply = () => {
        const canSubmit = verifyCanSubmit();
        if (canSubmit) {
            let formData = new FormData();
            formData.append(`formation.lattesUrl`, values.lattes ? values.lattes : "");
            formData.append(`formation.courseName`, values.course ? values.course : "");
            formData.append(`formation.ongoingEducation`, values.inProgress === "YES" ? true : false);
            formData.append(`formation.educationType`, values.educationType ? values.educationType : "");
            formData.append(`formation.institution`, values.institution ? values.institution : "");
            formData.append(`formation.conclusionDate`, dates.dateConclusion ? FormatDateToDataBase(new Date(dates.dateConclusion)) : "");
            formData.append(`formation.firstLanguageConversation`, true);
            formData.append(`formation.hasCertification`, true);
            formData.append(`formation.hasDoctorate`, true);
            formData.append(`formation.hasEspecialization`, true);
            formData.append(`formation.hasMasterDegree`, true);
            formData.append(`formation.hasPostDoctorate`, true);
            formData.append(`formation.version`, 0);
            formData.append(`formation.userId`, userId);

            for (let i in values.languageData) {
                const language = values.languageData[i];
                formData.append(`addLanguage[${i}].id`, language.id ? language.id : 0);
                formData.append(`addLanguage[${i}].languageName`, language.languageName ? language.languageName : "");
                formData.append(`addLanguage[${i}].reading`, language.reading ? language.reading : "");
                formData.append(`addLanguage[${i}].writing`, language.writing ? language.writing : "");
                formData.append(`addLanguage[${i}].conversation`, language.conversation ? language.conversation : "");
                formData.append(`addLanguage[${i}].PortalSecUserEducationId`, values.id);
            }
            for (let i in values.qualificationData) {
                const qualification = values.qualificationData[i];
                formData.append(`addQualification[${i}].id`, qualification.id ? qualification.id : 0);
                formData.append(`addQualification[${i}].qualificationName`, qualification.qualificationName ? qualification.qualificationName : "");
                formData.append(`addQualification[${i}].qualificationType`, qualification.qualificationType ? qualification.qualificationType : "");
                formData.append(`addQualification[${i}].institution`, qualification.qualificationInstitution ? qualification.qualificationInstitution : qualification.institution ? qualification.institution : "");
                formData.append(`addQualification[${i}].dateConclusion`, qualification.dateConclusion ? FormatDateToDataBase(new Date(qualification.dateConclusion)) : "");
                formData.append(`addQualification[${i}].dateCreated`, qualification.dateConclusion ? FormatDateToDataBase(new Date()) : "");
                formData.append(`addQualification[${i}].PortalSecUserEducationId`, values.id);
                // for (let j = 0; j < qualification.files.files.length; j++) {
                //     formData.append(`addQualification[${i}].files[${j}]`, qualification.files.files[j]);
                // }
                // if (qualification.files.files) {
                //     console.log("AQUI", qualification);
                //     console.log("Length", qualification.files.files.length);

                //     // for (let j = 0; j < qualification.files.files.length; j++) {
                //     // console.log("J", j)
                //     formData.append(`files[${i}]`, qualification.files ? qualification.files.files : null)
                //     // }
                // }
            }

            for (let i in qualificationDelete) {
                const dependent = qualificationDelete[i];
                formData.append(`deleteQualification[${i}].id`, dependent.id);
            }

            let familyData;
            if (!values.id) {
                familyData = addInformationFormation(formData, fetchData, userId);
            } else {
                familyData = updateInformationFormation(values.id, formData, fetchData, userId);
            }

            setHasChange(false);
            setRequiredLevel(false);
            setRequiredOngoingEducation(false);
        }
    }

    const setOpenQualificationView = (id, index) => {
        setOpenQualificationForm(true);
        setContextForm("view");
        setIdQualification(id);
    }
    const setOpenQualificationEdit = (id, index) => {
        setOpenQualificationForm(true);
        setContextForm("edit");
        setIdQualification(id);
    }
    const setOpenQualificationNew = () => {
        setOpenQualificationForm(true)
        setContextForm("new")
    }

    const handleChangeLanguageName = (indexField) => (name, value) => {
        const rowsField = [...values.languageData];
        const field = rowsField[indexField];
        if (field) {
            if (name === "languageName") {
                field.languageName = value;
            }
            setValues({ ...values, languageData: rowsField });
        }
    }

    let rows = values.qualificationData && values.qualificationData.map(function (num, index) {
        num.generalId = index++;
        return num;
    });

    return (
        <React.Fragment>
            <Transition loading={loading} newDesign={true}>
                <Paper>
                    <React.Fragment>
                        <div className={classes.familyDataForm}>
                            <Typography className={classes.headerColor}><b>Escolaridade</b></Typography>
                            <Grid container item spacing={3} md={12} lg={12} className={styles.gridContainer}>
                                <Grid item xs={12} sm={12}>
                                    <FormControl component="fieldset" style={{ marginTop: "18px" }}>
                                        <FormLabel
                                            component="legend"
                                            required
                                            error={requiredLevel}
                                            setRequired={setRequiredLevel}
                                        >{t("HR.level")}</FormLabel>
                                        <RadioGroup row aria-label="position"
                                            disabled={context === "view"}
                                            name="position"
                                            defaultValue="top"
                                            value={values.educationType}
                                            onChange={(ev) => handleChange("educationType", ev.target.value)}
                                        >
                                            <FormControlLabel
                                                disabled={context === "view"}
                                                value="HIGHSCHOOL"
                                                control={<Radio color="primary" />}
                                                label={t("HR.highSchool")}
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                disabled={context === "view"}
                                                value="GRADUATED"
                                                control={<Radio color="primary" />}
                                                label={t("HR.universityEducation")}
                                                labelPlacement="end"
                                            />
                                        </RadioGroup>
                                        {requiredLevel &&
                                            <div style={{ display: "flex", color: "#f44336" }}>{t("GENERAL.requiredField")}</div>
                                        }
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <FormControl component="fieldset" style={{ marginTop: "18px" }}>
                                        <FormLabel
                                            component="legend"
                                            required
                                            error={requiredOngoingEducation}
                                            setRequired={setRequiredOngoingEducation}
                                        >{t("HR.ongoingTraining")}</FormLabel>
                                        <RadioGroup row aria-label="position"
                                            disabled={context === "view"}
                                            name="position"
                                            defaultValue="top"
                                            value={values.inProgress}
                                            onChange={(ev) => handleChange("inProgress", ev.target.value)}
                                        >
                                            <FormControlLabel
                                                disabled={context === "view"}
                                                value="YES"
                                                control={<Radio color="primary" />}
                                                label={t("GENERAL.yesCapital")}
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                disabled={context === "view"}
                                                value="NO"
                                                control={<Radio color="primary" />}
                                                label={t("GENERAL.noCapital")}
                                                labelPlacement="end"
                                            />
                                        </RadioGroup>
                                        {requiredOngoingEducation &&
                                            <div style={{ display: "flex", color: "#f44336" }}>{t("GENERAL.requiredField")}</div>
                                        }
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Input
                                        context={context}
                                        label={"HR.course"}
                                        value={values.course}
                                        valueName={"course"}
                                        maxLength={60}
                                        handleChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            disabled={context === "view"}
                                            className={classes.identificationDatePicker}
                                            disableToolbar
                                            autoOk={true}
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            id="validity"
                                            margin="normal"
                                            //invalidDateMessage=""
                                            label={values.inProgress === "YES" ? t("HR.completionForecast") : t("HR.DateConclusion")}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date"
                                            }}
                                            value={dates.dateConclusion}
                                            onChange={handleDates("dateConclusion")}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Input
                                        context={context}
                                        label={"HR.institution"}
                                        value={values.institution}
                                        valueName={"institution"}
                                        maxLength={100}
                                        handleChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                            {context !== "view" &&
                                <Grid item xs={12} sm={12}>
                                    <div onClick={() => setOpenQualificationNew()} className={classes.link} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', width: 'max-content', }}>
                                        <Typography >Adicionar outra qualificação</Typography>
                                    </div>
                                </Grid>}
                            <Grid item xs={12} sm={11}>
                                {values.qualificationData && values.qualificationData.length > 0 &&
                                    <React.Fragment>
                                        <div className="cabecalho">
                                            {t("HR.qualifications").toUpperCase()}
                                        </div>
                                        <QualificationTable
                                            rows={rows}
                                            //toFirstPage={canClick}
                                            headers={["id", "qualificationName", "qualificationType", "dateConclusion", "generalId"]}
                                            visibleHeaders={["Nome da qualificação", "Tipo de qualificação", "Data de conclusão", ""]}
                                            sortedFields={["qualificationName", "qualificationType", "dateConclusion"]}
                                            mainField="qualificationName"
                                            removeQualification={removeQualification}
                                            setOpenQualificationView={setOpenQualificationView}
                                            setOpenQualificationEdit={setOpenQualificationEdit}
                                            contextForm={contextForm}
                                            context={context}
                                        />
                                    </React.Fragment>
                                }
                            </Grid>
                            <Typography className={classes.headerColor}><b>Idiomas</b></Typography>
                            {values.languageData && values.languageData.map((language, index) =>
                                <Grid container item spacing={2} md={12} lg={12} className={styles.gridContainer}>
                                    <Grid item xs={6} sm={6}>
                                        <Input
                                            maxLength={30}
                                            context={context}
                                            count={index + 1}
                                            label={"GENERAL.language"}
                                            value={language.languageName}
                                            valueName={"languageName"}
                                            handleChange={handleChangeLanguageName(index)}
                                        />
                                    </Grid>
                                    <Box width="100%" />
                                    <Grid item xs={12} sm={2}>
                                        <Typography className={styles.knowledgeRadioTable}><b>Conhecimento</b></Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4} className={styles.knowledgeRadioColumns}>
                                        <Typography className={styles.knowledgeRadioTable}><b>Básico</b></Typography>

                                        <Typography className={styles.knowledgeRadioTable}><b>Intermediário</b></Typography>

                                        <Typography className={styles.knowledgeRadioTable}><b>Avançado</b></Typography>
                                    </Grid>
                                    <Box width="100%" />
                                    <Grid item xs={12} sm={2}>
                                        <Typography className={classes.headerColor} style={{ marginTop: -10 }}>Leitura</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4} className={styles.knowledgeRadioColumns}>
                                        <div className={styles.knowledgeRadio}>
                                            <Radio
                                                disabled={context === "view"}
                                                color="primary"
                                                checked={language.reading === '1'}
                                                onChange={(ev) => handleChangeRadio(index, "reading", ev.target.value)}
                                                value="1"
                                                name="radio-button-demo"
                                                inputProps={{ 'aria-label': 'A' }}
                                            />
                                        </div>
                                        <div className={styles.knowledgeRadio}>
                                            <Radio
                                                disabled={context === "view"}
                                                color="primary"
                                                checked={language.reading === '2'}
                                                onChange={(ev) => handleChangeRadio(index, "reading", ev.target.value)}
                                                value="2"
                                                name="radio-button-demo"
                                                inputProps={{ 'aria-label': 'B' }}
                                            />
                                        </div>
                                        <div className={styles.knowledgeRadio}>
                                            <Radio
                                                disabled={context === "view"}
                                                color="primary"
                                                checked={language.reading === '3'}
                                                onChange={(ev) => handleChangeRadio(index, "reading", ev.target.value)}
                                                value="3"
                                                name="radio-button-demo"
                                                inputProps={{ 'aria-label': 'C' }}
                                            />
                                        </div>
                                    </Grid>
                                    <Box width="100%" />
                                    <Grid item xs={12} sm={2}>
                                        <Typography className={classes.headerColor} style={{ marginTop: -10 }}>Escrita</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4} className={styles.knowledgeRadioColumns}>
                                        <div className={styles.knowledgeRadio}>
                                            <Radio
                                                disabled={context === "view"}
                                                color="primary"
                                                checked={language.writing === '1'}
                                                onChange={(ev) => handleChangeRadio(index, "writing", ev.target.value)}
                                                value="1"
                                                name="radio-button-demo"
                                                inputProps={{ 'aria-label': 'A' }}
                                            />
                                        </div>
                                        <div className={styles.knowledgeRadio}>
                                            <Radio
                                                disabled={context === "view"}
                                                color="primary"
                                                checked={language.writing === '2'}
                                                onChange={(ev) => handleChangeRadio(index, "writing", ev.target.value)}
                                                value="2"
                                                name="radio-button-demo"
                                                inputProps={{ 'aria-label': 'B' }}
                                            />
                                        </div>
                                        <div className={styles.knowledgeRadio}>
                                            <Radio
                                                disabled={context === "view"}
                                                color="primary"
                                                checked={language.writing === '3'}
                                                onChange={(ev) => handleChangeRadio(index, "writing", ev.target.value)}
                                                value="3"
                                                name="radio-button-demo"
                                                inputProps={{ 'aria-label': 'C' }}
                                            />
                                        </div>
                                    </Grid>
                                    <Box width="100%" />
                                    <Grid item xs={12} sm={2}>
                                        <Typography className={classes.headerColor} style={{ marginTop: -10 }}>Conversação</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4} className={styles.knowledgeRadioColumns}>
                                        <div className={styles.knowledgeRadio}>
                                            <Radio
                                                disabled={context === "view"}
                                                color="primary"
                                                checked={language.conversation === '1'}
                                                onChange={(ev) => handleChangeRadio(index, "conversation", ev.target.value)}
                                                value="1"
                                                name="radio-button-demo"
                                                inputProps={{ 'aria-label': 'A' }}
                                            />
                                        </div>
                                        <div className={styles.knowledgeRadio}>
                                            <Radio
                                                disabled={context === "view"}
                                                color="primary"
                                                checked={language.conversation === '2'}
                                                onChange={(ev) => handleChangeRadio(index, "conversation", ev.target.value)}
                                                value="2"
                                                name="radio-button-demo"
                                                inputProps={{ 'aria-label': 'B' }}
                                            />
                                        </div>
                                        <div className={styles.knowledgeRadio}>
                                            <Radio
                                                disabled={context === "view"}
                                                color="primary"
                                                checked={language.conversation === '3'}
                                                onChange={(ev) => handleChangeRadio(index, "conversation", ev.target.value)}
                                                value="3"
                                                name="radio-button-demo"
                                                inputProps={{ 'aria-label': 'C' }}
                                            />
                                        </div>
                                    </Grid>
                                    <Box width="100%" />
                                    <Grid item xs={12} sm={5}>
                                    </Grid>
                                </Grid>
                            )}
                            {context !== "view" &&
                                <Grid item xs={12} sm={12}>
                                    <div onClick={addDependent} className={classes.link} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', width: 'max-content', }}>
                                        <Typography>Adicionar outro idioma</Typography>
                                    </div>
                                </Grid>}
                            <Typography className={classes.headerColor}><b>Currículo Lattes</b></Typography>
                            <Grid item xs={12} sm={12}>
                                <Input
                                    maxLength={70}
                                    context={context}
                                    label={"HR.lattes"}
                                    value={values.lattes}
                                    valueName={"lattes"}
                                    handleChange={handleChange}
                                />
                            </Grid>
                        </div>
                        {context !== "view" &&
                            <div style={{ paddingLeft: "10px", paddingBottom: "40px", paddingTop: "40px" }}>
                                <Buttons
                                    onClick={handleCancel}
                                    tipo="BN-noback"
                                    conteudo={t("GENERAL.cancel")}
                                />
                                <Buttons
                                    onClick={handleApply}
                                    tipo="BN-blue"
                                    conteudo={t("GENERAL.apply")}
                                />
                            </div>}
                    </React.Fragment>
                </Paper>

                <DialogCancelOperation
                    open={openCancelDialog}
                    setOpen={setOpenCancelDialog}
                    path={"/Collaborators"}
                    dialogTitle={t("GENERAL.cancelEdit")}
                    dialogText={t("GENERAL.cancelEditText")}
                />
            </Transition>
            <QualificationFormDialog
                qualifications={rows}
                idQualification={idQualification}
                open={openQualificationForm}
                setOpen={setOpenQualificationForm}
                context={context}
                setQualifications={setQualifications}
                contextForm={contextForm}
            />
        </React.Fragment >
    );
};

export default FormationForm;