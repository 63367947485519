import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Visibility from "@material-ui/icons/Visibility";
import React from "react";
import { useTranslation } from "react-i18next";
import Link from "react-router-dom/Link";
import { useStyles } from "components/layout/CommonStyles";
import { GetGeneralPermissionForAction } from "utils/permissions/GetGeneralPermissionForAction";
import { Screen, Allow } from "global/constants";


const MyClockRecordTableActions = props => {
    if (localStorage.getItem("auth-token") !== null) {
        var auth = JSON.parse(localStorage.getItem("auth-token"));
    }
    const classes = useStyles();
    const [t] = useTranslation();
    const { item } = props;

    return (
        <React.Fragment>
            {GetGeneralPermissionForAction(Screen.COLLABORATORS_CLOCK_RECORDS, Allow.READ) &&
            <Link to={`/CollaboratorClockRecord/${item.id}`}>
                <Tooltip title={t("GENERAL.view")} aria-label="details" placement="top">
                        <IconButton className={classes.iconsTableActions} >
                            <Visibility />
                        </IconButton>
                </Tooltip>
            </Link>
            }    
            
        </React.Fragment>
    )
};

export default MyClockRecordTableActions;