import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { useStyles } from "components/layout/CommonStyles";
import { useTranslation } from "react-i18next";
import CloseIcon from '@material-ui/icons/Close';
import Buttons from "components/buttons/Pressed";
import Grid from "@material-ui/core/Grid";
import Input from "components/inputs/Input";

import { IsEmptyObject} from "utils/general/IsEmptyObject";


const DialogAddMyActivity = props => {
    const classes = useStyles();
    const [t] = useTranslation();

    const [values, setValues] = useState({
        id: "",
        name: "",
        activityCategoryId: "",
        activityCategory: "",
        description: "",
    });

    const [requiredActivityCategory, setRequiredActivityCategory] = useState(false);
    const [requiredName, setRequiredName] = useState(false);
    const [requiredDescription, setRequiredDescription] = useState(false);

    const { open, setOpen,index, context, activityCategoryArray ,addActivity, editActivity , itemValues} = props;    

    useEffect(() => {
        if (itemValues && !IsEmptyObject(itemValues)) {
            setValues({
                id: itemValues.id ? itemValues.id : "" ,
                name: itemValues.name ? itemValues.name : "" ,
                activityCategoryId: itemValues.rhActivityCategoriesId ? itemValues.rhActivityCategoriesId : itemValues.activityCategoryId ?  itemValues.activityCategoryId : ""  ,
                activityCategory: itemValues.activityCategory ? itemValues.activityCategory : "" ,
                description: itemValues.description ? itemValues.description : "" ,
            })
        }
    }, [itemValues]);

    const handleClose = () => {
        setOpen(false);
    }
    const handleChange = (name, value) => {
        let val = value;
        setValues({ ...values, [name]: val })
    };

    const verifySubmit = () => {
        let res = true;

        if (!values.activityCategoryId) {
            res = false;
            setRequiredActivityCategory(true);
        }
        if (!values.name.trim()) {
            res = false;
            setRequiredName(true);
        }
        

        return res;
    }


    const handleSubmit = () => {
        const canSubmit = verifySubmit();
        if (canSubmit) {
            if (context === "new") {
                addActivity({
                    ...values,
                    activityCategory: activityCategoryArray.find(activity => activity.value === values.activityCategoryId).label
            })} else{
                editActivity(index ,{
                    ...values,
                    activityCategory: activityCategoryArray.find(activity => activity.value === values.activityCategoryId).label
                } )   
            }
            handleClose();
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle className={classes.Title}>
                <b>{context === "view" ? t("GENERAL.view") + " " + t("GENERAL.activity") : context === "edit" ? t("GENERAL.edit") + " " + t("GENERAL.activity") : t("HR.addActivity")}</b>
                <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container item spacing={2} md={12} lg={12}>
                   
                    <Grid item xs={12} sm={12}>
                            <Input
                                required
                                context={context}
                                label={"GENERAL.activityCategory"}
                                isSelect
                                arraySelected={activityCategoryArray.filter(x=> !x.deleted || x.value === values.activityCategoryId)}
                                value={values.activityCategoryId}
                                valueName={"activityCategoryId"}
                                handleChange={handleChange}
                                error={requiredActivityCategory}
                                helperText={requiredActivityCategory && t("GENERAL.requiredField")}
                                setRequired={setRequiredActivityCategory}
                            />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Input
                            required
                            context={context}
                            label={"GENERAL.activityName"}
                            value={values.name}
                            valueName={"name"}
                            handleChange={handleChange}
                            maxLength={120}
                            error={requiredName}
                            helperText={requiredName && t("GENERAL.requiredField")}
                            setRequired={setRequiredName}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Input
                            context={context}
                            label={"GENERAL.description"}
                            value={values.description}
                            valueName={"description"}
                            rows={5}
                            maxLength={500}
                            handleChange={handleChange}
                            error={requiredDescription}
                            helperText={requiredDescription && t("GENERAL.requiredField")}
                            setRequired={setRequiredDescription}
                        />
                    </Grid>

                </Grid>
            </DialogContent>

            <DialogActions>
                <Buttons
                    onClick={handleClose}
                    tipo="BN-noback"
                    conteudo={context === "view" ? t("GENERAL.close").toUpperCase() : t("GENERAL.cancel").toUpperCase()}
                />
                {
                    context === "view" ? null :
                        <Buttons
                            onClick={handleSubmit}
                            tipo="BN-blue"
                            conteudo={t("GENERAL.add").toUpperCase()}
                        />
                }
            </DialogActions>

        </Dialog >
    )
}

export default DialogAddMyActivity;