import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Breadcrumb from 'components/layout/breadcrumb/Breadcrumb';
import Transition from 'components/layout/transition/Transition';
import HeaderContainer from 'components/containers/HeaderContainer';
import ContentContainer from 'components/containers/ContentContainer';
import Paper from '@material-ui/core/Paper';
import Typography from 'components/typography/Typography';
import { Grid } from '@material-ui/core';
import Footer from 'components/layout/Footer';
import DialogToolsConfirmation from './Dialogs/DialogToolsConfirmation';
import DialogToolsVerifyPayment from './Dialogs/DialogToolsVerifyPayment';

import { useQueryMonthlyBillInformation } from 'hooks/DataFetching/Quotas/Tools/useQueryMonthlyBillInformation';

import { useStyles } from 'components/layout/CommonStyles';
import { GetGeneralPermissionForAction } from 'utils/permissions/GetGeneralPermissionForAction';
import { Screen, Allow } from 'global/constants';
import { screenStyles } from './Styles';
import Buttons from 'components/buttons/Pressed';
import { useMutationGenerateBills } from 'hooks/DataFetching/Quotas/Tools/useMutationGenerateBills';
import { useMutationResendLateBills } from 'hooks/DataFetching/Quotas/Tools/useMutationResendLateBills';

const Tools = () => {
    const classes = useStyles();
    const styles = screenStyles();
    const [t] = useTranslation();

    const [openResend, setOpenResend] = useState(false);
    const [openGenerate, setOpenGenerate] = useState(false);
    const [openVerification, setOpenVerification] = useState(false);

    const { isLoading, isFetching, error, data } =
        useQueryMonthlyBillInformation();

    const mutationGenerateBills = useMutationGenerateBills(setOpenGenerate);
    const mutationResendLateBills = useMutationResendLateBills(setOpenResend);

    return (
        <React.Fragment>
            <Transition loading={isLoading && isFetching} newDesign={true}>
                <HeaderContainer>
                    <Breadcrumb
                        newDesign={true}
                        routes={[
                            {
                                label: t('QUOTAS.quotas'),
                                to: '/Quotas'
                            },
                            { label: t('GENERAL.tools') }
                        ]}
                    />
                    <div className="container-headerNewDesign">
                        <h1 className={classes.titleNewDesign}>
                            {t('GENERAL.tools')}
                        </h1>
                    </div>
                </HeaderContainer>
                <ContentContainer>
                    <Paper square>
                        <div className="cabecalho">
                            {t('GENERAL.tools').toUpperCase()}
                        </div>
                        <Grid
                            container
                            spacing={2}
                            style={{ padding: '20px 16px' }}
                        >
                            <Grid item xs={12} style={{ marginBottom: '44px' }}>
                                <Typography
                                    variant={'RobotoBold'}
                                    fontSize={14}
                                    textColor={'secondary'}
                                >
                                    {t('QUOTAS.toolsActionsInfo')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant={'RobotoRegular'}
                                    fontSize={14}
                                    textColor={'secondary'}
                                >
                                    {t('QUOTAS.toolsResendLateEmailsText')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ marginBottom: '8px' }}>
                                <Buttons
                                    onClick={() => {
                                        setOpenResend(true);
                                    }}
                                    tipo="BN-blue"
                                    conteudo={t(
                                        'QUOTAS.toolsResendLateEmails'
                                    ).toUpperCase()}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography
                                    variant={'RobotoRegular'}
                                    fontSize={14}
                                    textColor={'secondary'}
                                >
                                    {t('QUOTAS.toolsGenerateBillsText')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ marginBottom: '8px' }}>
                                <Buttons
                                    onClick={() => {
                                        setOpenGenerate(true);
                                    }}
                                    tipo="BN-blue"
                                    conteudo={t(
                                        'QUOTAS.toolsGenerateBills'
                                    ).toUpperCase()}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography
                                    variant={'RobotoRegular'}
                                    fontSize={14}
                                    textColor={'secondary'}
                                >
                                    {t('QUOTAS.toolsVerificationCodeText')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ marginBottom: '16px' }}>
                                <Buttons
                                    onClick={() => {
                                        setOpenVerification(true);
                                    }}
                                    tipo="BN-blue"
                                    conteudo={t(
                                        'QUOTAS.verifyPayment'
                                    ).toUpperCase()}
                                />
                            </Grid>
                        </Grid>
                    </Paper>

                    {openResend ? (
                        <DialogToolsConfirmation
                            open={openResend}
                            setOpen={setOpenResend}
                            title={t('QUOTAS.toolsResendLateEmails')}
                            text={t('QUOTAS.toolsResendLateEmailsDialogText')}
                            inputLabel={
                                'QUOTAS.toolsResendLateEmailsDialogInput'
                            }
                            inputValue={
                                data.lateBillsCount ? data.lateBillsCount : 0
                            }
                            buttonText={t(
                                'QUOTAS.toolsResendLateEmailsDialogButton'
                            ).toUpperCase()}
                            mutation={mutationResendLateBills}
                        />
                    ) : null}

                    {openGenerate ? (
                        <DialogToolsConfirmation
                            open={openGenerate}
                            setOpen={setOpenGenerate}
                            title={t('QUOTAS.generateBills')}
                            text={t('QUOTAS.toolsGenerateBillsDialogText')}
                            inputLabel={'QUOTAS.toolsGenerateBillsDialogInput'}
                            inputValue={
                                data.missingBillsThisMonthCount
                                    ? data.missingBillsThisMonthCount
                                    : 0
                            }
                            buttonText={t('QUOTAS.generateBills').toUpperCase()}
                            mutation={mutationGenerateBills}
                        />
                    ) : null}

                    {openVerification ? (
                        <DialogToolsVerifyPayment
                            open={openVerification}
                            setOpen={setOpenVerification}
                            title={t('QUOTAS.verifyPayment')}
                            text={t('QUOTAS.toolsVerifyPaymentDialogText')}
                            buttonText={t('GENERAL.verify').toUpperCase()}
                        />
                    ) : null}
                </ContentContainer>
                <Footer />
            </Transition>
        </React.Fragment>
    );
};

export default Tools;
