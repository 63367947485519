import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import Buttons from 'components/buttons/Pressed';
import InfoTooltip from 'components/infoTooltip/InfoTooltip';
import Typography from 'components/typography/Typography';
import { screenStyles } from '../Styles';
import Skeleton from '@material-ui/lab/Skeleton';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { DialogUnit } from 'pages/libraryModule/dialogs/DialogUtils';
import { translateLibrary } from 'utils/library/translateLibrary';
import { PadToNDigits } from 'utils/general/PadToNDigits';
import { FormatDateToFront } from 'utils/dates/FormatDateToFront';
import { dateToBR } from 'utils/dates/DateToBRr';
import { valueToMoneyFloat } from 'utils/general/valueToMoneyFloat';
import { moneyMask } from 'utils/masks/moneyMask';
import { translateFineStatus } from 'utils/library/translateFinesStatus';
import { translateLoanStatus } from 'utils/library/translateStatus';
import {
    LibraryFinesStatus,
    LibraryPlaceValues,
    LibraryReservationStatusValues
} from 'global/constants';

const DialogFinesMoreDetails = props => {
    const styles = screenStyles();
    const [t] = useTranslation();

    const { open, setOpen, viewValues } = props;

    const handleClose = () => {
        setOpen(false);
    };

    const image = viewValues.reservation.book.imagePath
        ? viewValues.reservation.book.imagePath
        : null;

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(false);
    }, [image]);

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth={'sm'}
                fullWidth
            >
                <DialogTitle>
                    <div className={styles.dialogTitle}>
                        <Typography
                            variant={'RobotoBold'}
                            fontSize={24}
                            textColor={'secondary'}
                        >
                            {t('LIBRARY.dialogFinesMoreDetailsTitle')}
                        </Typography>
                        <IconButton
                            color="#6D6F71"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    {viewValues.reservation.library ===
                    LibraryPlaceValues.COORDINATION ? (
                        <div className={styles.dialogMoreDetailsDisponible}>
                            <ErrorIcon
                                style={{
                                    marginRight: '0.6rem',
                                    color: '#2C3E51'
                                }}
                            />
                            <Typography
                                variant={'RobotoRegular'}
                                fontSize={14}
                                textColor={'primary'}
                            >
                                {t(
                                    `LIBRARY.CoordenationExemplarsDoNoTGenerateFines`
                                )}
                            </Typography>
                        </div>
                    ) : null}

                    {viewValues.reservation.fineChanged ? (
                        <div className={styles.dialogMoreDetailsDisponible}>
                            <ErrorIcon
                                style={{
                                    marginRight: '0.6rem',
                                    color: '#2C3E51'
                                }}
                            />
                            <Typography
                                variant={'RobotoRegular'}
                                fontSize={14}
                                textColor={'primary'}
                            >
                                {t(`LIBRARY.fineChangedTip`)}
                            </Typography>
                        </div>
                    ) : null}

                    <div className={styles.dialogMoreDetailsContainerUpper}>
                        <div className={styles.dialogMoreDetailsLeftWrapper}>
                            <DialogUnit
                                head={t('INVENTORY.title').toLocaleUpperCase()}
                                content={
                                    viewValues.reservation.book.title
                                        ? viewValues.reservation.book.title
                                        : '-'
                                }
                            />
                            {/* <DialogUnit
                                head={t(
                                    'INVENTORY.BookAuthor'
                                ).toLocaleUpperCase()}
                                content={
                                    viewValues.reservation.book.authors.length >
                                    0
                                        ? AllAuthorsName(
                                              viewValues.reservation.book
                                                  .authors
                                          )
                                        : '-'
                                }
                            /> */}
                            <DialogUnit
                                head={t(
                                    'LIBRARY.collection'
                                ).toLocaleUpperCase()}
                                content={
                                    <div
                                        className={
                                            styles.dialogFinesStatusWrapper
                                        }
                                    >
                                        <p>
                                            {viewValues.reservation.library
                                                ? translateLibrary(
                                                      viewValues.reservation
                                                          .library
                                                  )
                                                : '-'}
                                        </p>
                                        {/* {viewValues.reservation.library ===
                                            LibraryPlaceValues.COORDINATION && (
                                            <InfoTooltip
                                                text={t(
                                                    'LIBRARY.CoordenationExemplarsDoNoTGenerateFines'
                                                )}
                                            />
                                        )} */}
                                    </div>
                                }
                            />
                            <DialogUnit
                                head={t('LIBRARY.exemplar').toLocaleUpperCase()}
                                content={
                                    viewValues.reservation.exemplar
                                        ? 'BIB-' +
                                          PadToNDigits(
                                              viewValues.reservation.exemplar
                                                  .code,
                                              4
                                          )
                                        : '-'
                                }
                            />

                            <DialogUnit
                                head={t(
                                    'GENERAL.devolutionStatus'
                                ).toLocaleUpperCase()}
                                content={
                                    <div
                                        className={
                                            styles.dialogFinesStatusWrapper
                                        }
                                    >
                                        {viewValues.status ? (
                                            viewValues.reservation.status ===
                                            LibraryReservationStatusValues.FINISHED ? (
                                                <CheckCircleIcon
                                                    style={{
                                                        marginLeft: '0.2rem',
                                                        color: '#7FE46B'
                                                    }}
                                                />
                                            ) : viewValues.reservation
                                                  .status ===
                                              LibraryReservationStatusValues.LATE ? (
                                                <ErrorIcon
                                                    style={{
                                                        marginLeft: '0.2rem',
                                                        color: '#F3222F'
                                                    }}
                                                />
                                            ) : null
                                        ) : (
                                            '-'
                                        )}
                                        <p>
                                            {translateLoanStatus(
                                                viewValues.reservation.status
                                            )}
                                        </p>
                                    </div>
                                }
                            />
                        </div>
                        <img
                            className={styles.cardImage}
                            style={!loaded ? { display: 'none' } : {}}
                            src={image}
                            alt={viewValues.reservation.book.title}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src =
                                    window.location.origin +
                                    '/img/no-book-cover.png';
                                setLoaded(true);
                            }}
                            onLoad={() => {
                                setLoaded(true);
                            }}
                        />
                        {!loaded && (
                            <Skeleton
                                className={styles.cardImage}
                                variant="rect"
                                width={150}
                                height={220}
                            />
                        )}
                    </div>

                    <div
                        className={styles.dialogMoreDetailsContainerBottom}
                        style={{ margin: '24px 0 0' }}
                    >
                        <div
                            className={
                                styles.dialogMoreDetailsAuthorEditionWrapper
                            }
                        >
                            <div style={{ width: '48%' }}>
                                <DialogUnit
                                    head={t(
                                        'LIBRARY.borrowedTo'
                                    ).toLocaleUpperCase()}
                                    content={
                                        viewValues.reservation.user.shortname
                                    }
                                />
                            </div>
                            <div style={{ width: '48%' }}>
                                <DialogUnit
                                    head={t(
                                        'LIBRARY.dateDevolutionFor'
                                    ).toLocaleUpperCase()}
                                    content={
                                        viewValues.reservation.returnDate
                                            ? dateToBR(
                                                  FormatDateToFront(
                                                      viewValues.reservation
                                                          .returnDate
                                                  )
                                              )
                                            : '-'
                                    }
                                />
                            </div>
                        </div>

                        <div
                            className={
                                styles.dialogMoreDetailsAuthorEditionWrapper
                            }
                            style={{ margin: '10px 0 0' }}
                        >
                            <div style={{ width: '48%' }}>
                                <DialogUnit
                                    head={t(
                                        'LIBRARY.returnMadeBy'
                                    ).toLocaleUpperCase()}
                                    content={
                                        viewValues.reservation.receiver
                                            ? viewValues.reservation.receiver
                                                  .shortname
                                            : '-'
                                    }
                                />
                            </div>
                            <div style={{ width: '48%' }}>
                                <DialogUnit
                                    head={t(
                                        'LIBRARY.returnDate'
                                    ).toLocaleUpperCase()}
                                    content={
                                        viewValues.reservation.devolutionDate
                                            ? dateToBR(
                                                  FormatDateToFront(
                                                      viewValues.reservation
                                                          .devolutionDate
                                                  )
                                              )
                                            : '-'
                                    }
                                />
                            </div>
                        </div>

                        <div
                            className={
                                styles.dialogMoreDetailsAuthorEditionWrapper
                            }
                            style={{ margin: '10px 0 0' }}
                        >
                            <div style={{ width: '48%' }}>
                                <DialogUnit
                                    head={t(
                                        viewValues.reservation.fineChanged
                                            ? 'GENERAL.valueCharged'
                                            : 'GENERAL.value'
                                    ).toLocaleUpperCase()}
                                    content={
                                        viewValues.reservation.fine &&
                                        viewValues.reservation.library !==
                                            LibraryPlaceValues.COORDINATION
                                            ? `R$ ${moneyMask(
                                                  valueToMoneyFloat(
                                                      viewValues.reservation
                                                          .fine
                                                  )
                                              )}`
                                            : '-'
                                    }
                                />
                            </div>
                            {viewValues.reservation.fineChanged ? (
                                <div style={{ width: '48%' }}>
                                    <DialogUnit
                                        head={t(
                                            'GENERAL.finalValue'
                                        ).toLocaleUpperCase()}
                                        content={`R$ ${moneyMask(
                                            valueToMoneyFloat(
                                                viewValues.reservation
                                                    .fineChanged
                                            )
                                        )}`}
                                    />
                                </div>
                            ) : null}

                            <div style={{ width: '48%' }}>
                                <DialogUnit
                                    head={t(
                                        'LIBRARY.fineStatus'
                                    ).toLocaleUpperCase()}
                                    content={
                                        <div
                                            className={
                                                styles.dialogFinesStatusWrapper
                                            }
                                        >
                                            {viewValues.status ? (
                                                viewValues.reservation
                                                    .library ===
                                                LibraryPlaceValues.COORDINATION ? (
                                                    <InfoTooltip
                                                        text={t(
                                                            'LIBRARY.CoordenationExemplarsDoNoTGenerateFines'
                                                        )}
                                                    />
                                                ) : viewValues.status ===
                                                  LibraryFinesStatus.PAID ? (
                                                    <CheckCircleIcon
                                                        style={{
                                                            marginLeft:
                                                                '0.2rem',
                                                            color: '#7FE46B'
                                                        }}
                                                    />
                                                ) : viewValues.status ===
                                                  LibraryFinesStatus.PENDING ? (
                                                    <ErrorIcon
                                                        style={{
                                                            marginLeft:
                                                                '0.2rem',
                                                            color: '#F3222F'
                                                        }}
                                                    />
                                                ) : null
                                            ) : (
                                                '-'
                                            )}
                                            <p>
                                                {viewValues.reservation
                                                    .library !==
                                                LibraryPlaceValues.COORDINATION
                                                    ? translateFineStatus(
                                                          viewValues.status
                                                      )
                                                    : translateFineStatus(
                                                          LibraryFinesStatus.NO_CHARGE
                                                      )}
                                            </p>
                                        </div>
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    {viewValues.reservation.fineChanged ? (
                        <DialogUnit
                            head={t('GENERAL.observation').toLocaleUpperCase()}
                            content={viewValues.reservation.justification}
                            margin={'16px 0 0'}
                        />
                    ) : null}
                </DialogContent>

                <DialogActions
                    className={styles.dialogFinesDetailsButtonsWrapper}
                >
                    <Buttons
                        onClick={handleClose}
                        tipo="BN-blue"
                        conteudo={t('GENERAL.close').toUpperCase()}
                    />
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DialogFinesMoreDetails;
