import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { useStyles } from 'components/layout/CommonStyles';
import { useTranslation, Trans } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import Buttons from 'components/buttons/Pressed';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

import { useMutationEnableUser } from 'hooks/DataFetching/HR/Collaborator/useMutationEnableUser';

const DialogEnableAccess = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const { open, setOpen, name, id } = props;

    const [checked, setChecked] = useState(true);

    const mutation = useMutationEnableUser(setOpen, checked, id);

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (name, value) => {
        setChecked(!checked);
    };

    const handleSubmit = () => {
        let formData = new FormData();
        formData.append(`id`, id);
        formData.append(`resetPassword`, checked);
        mutation.mutate(formData);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle className={classes.Title}>
                <b>{t('HR.enableCollaboratorSystemAccess')}</b>
                <IconButton
                    style={{ float: 'right', marginTop: -10, marginRight: -17 }}
                    edge="end"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container item spacing={2} md={12} lg={12}>
                    <Grid item xs={12} sm={12}>
                        <Typography>
                            <Trans
                                i18nKey={'HR.enableCollaboratorText'}
                                values={{ name: name }}
                                components={[<b />]}
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checked}
                                        onChange={handleChange}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label={t('HR.sendWelcomeAndPasswordEmail')}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Buttons
                    onClick={handleClose}
                    tipo="BN-noback"
                    conteudo={t('GENERAL.cancel').toUpperCase()}
                />
                <Buttons
                    isLoading={mutation.isLoading}
                    onClick={handleSubmit}
                    tipo="BN-blue"
                    conteudo={t('HR.enableAccess').toUpperCase()}
                />
            </DialogActions>
        </Dialog>
    );
};

export default DialogEnableAccess;
