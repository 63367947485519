import DateFnsUtils from "@date-io/date-fns";
import { MenuItem, Paper } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import AddCircle from "@material-ui/icons/AddCircle";
import GetApp from "@material-ui/icons/GetApp";
import Save from "@material-ui/icons/Save";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useStyles } from "components/layout/CommonStyles";
import MySnackbarContentWrapper from "components/layout/Message";
import AllowancesContext from "context/allowance/allowanceContext";
import ProjectsContext from "context/projects/projectsContext";
import { AlignmentType, Document, HeadingLevel, Packer, Paragraph, Table, TableCell, TableRow, WidthType } from "docx";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { animated, useTransition } from "react-spring";
import ScheduleActivity from "./ScheduleActivity";

var fileDownload = require("js-file-download");

const tooltipTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#2c3e51"
    }
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: "white",
        fontSize: "1em",
        backgroundColor: "red",
        opacity: "0.7"
      }
    }
  }
});

const Schedule = props => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [title, setTitle] = useState("GENERAL.requiredField");
  const [openTitle, setOpenTitle] = useState(false);
  const requiredField = "GENERAL.requiredField";
  const projectsContext = useContext(ProjectsContext);
  const allowanceContext = useContext(AllowancesContext);
  const {
    project,
    loading,
    response,
    hasUpdate,
    getProject,
    resetMessage,
    resetProject,
    responseType,
    submitSchedule,
    successiveActions,
    setValueRequiredMessage,
    showRequiredFieldMessage
  } = projectsContext;
  const { projectId } = props;
  const [delay, setDelay] = useState(true);
  const [activity, setActivity] = useState([]);
  const [toDelete, setToDelete] = useState([]);

  const [state, setState] = useState({
    season: "",
    seasonId: 0
  });
  const [gpf, setGpf] = useState("");
  const [activities, setActivities] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [description, setDescription] = useState("");
  const [endDate, setEndDate] = useState(new Date());
  const [seasonsArray, setSeasonsArray] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [activitiesCount, setActivitiesCount] = useState(1);
  const [hasCooperationTerm, setHasCooperationTerm] = useState(false);

  const [newStartDate, setNewStartDate] = useState(new Date());
  const [newEndDate, setNewEndDate] = useState(new Date());
  const {
    allowanceResponse,
    allowanceResponseType,
    resetAllowanceMessage
  } = allowanceContext;

  const [snackBar, setSnackBar] = useState({
    open: false,
    kind: "",
    content: ""
  });

  const handleSnack = () => {
    setSnackBar({ ...snackBar, open: true });
  };

  const handleSnackClose = () => {
    setSnackBar({ ...snackBar, open: false });
    resetAllowanceMessage();
  };

  function bootstrap() {
    var aux = {};
    var general = {};
    var generalActivity = {};
    var endDate = null;
    var startDate = null;
    var activities = [];
    var seasonsArray_ = [];
    setProjectName(project.information.name);
    if (project.sgppdAddendum.length > 0) {
      setHasCooperationTerm(true)
      for (var i = 0; i < project.sgppdAddendum.length; i++) {
        if (project.sgppdAddendum[i].gpf) {
          setGpf(project.sgppdAddendum[i].gpf);
        }
      }

      for (var i = 0; i < project.sgppdAddendum.length; i++) {
        var item = project.sgppdAddendum[i];
        startDate = new Date(item.startdate);
        startDate.setMinutes(startDate.getMinutes() + startDate.getTimezoneOffset())
        endDate = new Date(item.enddate);
        endDate.setMinutes(endDate.getMinutes() + endDate.getTimezoneOffset())

        aux.value = item.id;
        aux.label = startDate.toLocaleDateString() + " - " + endDate.toLocaleDateString();

        if (item.number === "GENERAL") {
          var startDateGeneral = new Date(project.information.startdate);
          startDateGeneral.setMinutes(startDateGeneral.getMinutes() + startDateGeneral.getTimezoneOffset())
          var endDateGeneral = new Date(project.information.enddate);
          endDateGeneral.setMinutes(endDateGeneral.getMinutes() + endDateGeneral.getTimezoneOffset())
          aux.value = item.id;
          aux.label = startDateGeneral.toLocaleDateString() + " - " + endDateGeneral.toLocaleDateString();
          general = aux;
          aux.label = aux.label + " (GERAL)";
        } else {
          seasonsArray_.push(aux);
        }

        aux = {};
        aux.addendumId = item.id;
        aux.sgppdActivity = [];
        for (var j = 0; j < item.sgppdActivity.length; j++) {
          aux.sgppdActivity.push(item.sgppdActivity[j]);
        }

        if (item.number === "GENERAL") {
          generalActivity = aux;
        } else {
          activities.push(aux);
        }
      }

      seasonsArray_.push(general);
      var primor = seasonsArray_[seasonsArray_.length - 2];

      setState({
        season: primor.label,
        seasonId: primor.value
      });

      activities.push(generalActivity);

      setSeasonsArray(seasonsArray_);
      setActivities(activities);
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (responseType === "SUCCESS") {
        handleSnack({ kind: "success", content: response });
      }
      if (responseType === "ERROR") {
        handleSnack({ kind: "error", content: response });
      }
      setDelay(false);
    }, 700);

    return () => {
      clearTimeout(timer);
    };
  }, [responseType, response, successiveActions]);

  useEffect(() => {
    getProject(projectId);
    const timer = setTimeout(() => {
      if (Object.keys(project).length > 0) {
        bootstrap();
      }
    }, 300);

    if (activities.length > 0 && state.seasonId) {
      var len = activity.length;
      activity.splice(0, len);
      setActivitiesCount(1);

      var period = activities.filter(
        item => item.addendumId == state.seasonId
      )[0];

      var newPeriod = project.sgppdAddendum.filter(
        item => item.id == state.seasonId
      )[0];

      var startdatewithtimezone = new Date(newPeriod.startdate)
      startdatewithtimezone.setMinutes(startdatewithtimezone.getMinutes() + startdatewithtimezone.getTimezoneOffset())
      var enddatewithtimezone = new Date(newPeriod.enddate)
      enddatewithtimezone.setMinutes(enddatewithtimezone.getMinutes() + enddatewithtimezone.getTimezoneOffset())

      setNewStartDate(startdatewithtimezone);
      setNewEndDate(enddatewithtimezone);

      if (period.sgppdActivity && period.sgppdActivity.length > 0) {
        setDescription(period.sgppdActivity[0].description);
        var startdatewithtimezone = new Date(period.sgppdActivity[0].startdate)
        startdatewithtimezone.setMinutes(startdatewithtimezone.getMinutes() + startdatewithtimezone.getTimezoneOffset())
        var enddatewithtimezone = new Date(period.sgppdActivity[0].enddate)
        enddatewithtimezone.setMinutes(enddatewithtimezone.getMinutes() + enddatewithtimezone.getTimezoneOffset())
        setStartDate(startdatewithtimezone);
        setEndDate(enddatewithtimezone);

        for (var i = 1; i <= period.sgppdActivity.length - 1; i++) {
          var label = period.sgppdActivity[i].description;
          var start = period.sgppdActivity[i].startdate;
          var end = period.sgppdActivity[i].enddate;
          var activityId = period.sgppdActivity[i].id;
          addActivity(label, start, end, true, activityId);
        }
      } else {

        var len = activity.length;
        activity.splice(0, len);
        setActivitiesCount(1);
        var newPeriod = project.sgppdAddendum.filter(
          item => item.id == state.seasonId
        )[0];
  
        var startdatewithtimezone = new Date(newPeriod.startdate)
        startdatewithtimezone.setMinutes(startdatewithtimezone.getMinutes() + startdatewithtimezone.getTimezoneOffset())
        var enddatewithtimezone = new Date(newPeriod.enddate)
        enddatewithtimezone.setMinutes(enddatewithtimezone.getMinutes() + enddatewithtimezone.getTimezoneOffset())
  
        setStartDate(startdatewithtimezone);
        setEndDate(enddatewithtimezone);
      }
    }
    return () => {
      clearTimeout(timer);
    };
  }, [Object.keys(project).length, activities.length]);

  // state.seasonId,

  useEffect(() => {
    if (toDelete.length > 0) {
      var period = activities.filter(
        item => item.addendumId == state.seasonId
      )[0];

      var scheduleActivities =
        period.sgppdActivity && period.sgppdActivity.length > 0
          ? period.sgppdActivity
          : [];

      if (scheduleActivities.length > 0) {
        var len = scheduleActivities.length;
        var aux = [];
        for (var i = 0; i < len; i++) {
          if (activity[i]) {
            var idActivity = parseInt(
              document.querySelector("#divActivity" + activity[i].key).dataset
                .activityId
            );

            if (idActivity === 0) {
              let start = document.getElementById("startDate" + activity[i].key).value;
              let end = document.getElementById("endDate" + activity[i].key).value;

              var element = {
                description: document.getElementById("descActivity" + activity[i].key).value,
                id: 0,
                startdate: new Date(start.slice(3, 5) + "/" + start.slice(0, 2) + "/" + start.slice(6, 10)),
                enddate: new Date(end.slice(3, 5) + "/" + end.slice(0, 2) + "/" + end.slice(6, 10))
              };

              aux.push(element);
            } else {
              var element = scheduleActivities.filter(
                activity => activity.id === idActivity
              )[0];

              if (element)
                aux.push(element);
            }
          }
        }

        activity.splice(0, len);
        setActivitiesCount(1);

        for (var i = 0; i <= aux.length - 1; i++) {
          var label = aux[i].description;
          var start = aux[i].startdate;
          var end = aux[i].enddate;
          var activityId = aux[i].id;
          addActivity(label, start, end, true, activityId);
        }
      } else {
        var activitiesLen = activity.length;
        var aux = [];
        for (var i = 0; i < activitiesLen; i++) {
          var key = activity[i].key;
          var obj = {};
          obj.startdate = document.getElementById("startDate" + key).value;
          obj.enddate = document.getElementById("endDate" + key).value;
          obj.description = document.getElementById("descActivity" + key).value;

          aux.push(obj);
          obj = {};
        }

        activity.splice(0, activity.length);
        setActivitiesCount(1);

        for (var j = 0; j < aux.length; j++) {
          var startDateSplited = aux[j].startdate.split("/");
          var newStartDate = new Date(
            startDateSplited[1] +
            "/" +
            startDateSplited[0] +
            "/" +
            startDateSplited[2]
          );

          var endDateSplited = aux[j].enddate.split("/");
          var newEndDate = new Date(
            endDateSplited[1] +
            "/" +
            endDateSplited[0] +
            "/" +
            endDateSplited[2]
          );
          addActivity(aux[j].description, newStartDate, newEndDate, true, 0);
        }
      }
    }
  }, [toDelete.length]);

  // useEffect(() => {
  //   if (hasUpdate) {
  //     resetProject();
  //     setActivities([]);
  //     setSeasonsArray([]);
  //   }
  //   return () => {
  //     resetMessage();
  //   };
  // }, [hasUpdate]);

  useEffect(() => {
    if (hasUpdate) {
      setActivities([]);
      setSeasonsArray([]);
      resetProject();
    }
    return () => { };
  }, [hasUpdate]);

  useEffect(() => {
    return () => {
      resetMessage();
    };
  }, []);

  const createFile = () => {
    var allActivities = document.getElementsByClassName("scheduleActivity");
    const doc = new Document({
      styles: {
        paragraphStyles: [
          {
            id: "Heading1",
            name: "Heading 1",
            run: {
              size: 35,
              bold: true,
              color: "2c3e51"
            }
          },
          {
            id: "desc",
            name: "descGoals",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              size: 20
            },
            paragraph: {
              spacing: {
                before: 240,
                after: 120
              }
            }
          },
          {
            id: "Heading6",
            name: "Heading 6",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              size: 20,
              color: "2c3e51"
            },
            paragraph: {
              spacing: {
                before: 240,
                after: 120
              }
            }
          }
        ]
      }
    });

    const table = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  text: "  GPF: \n",
                  heading: HeadingLevel.HEADING_6
                }),
                new Paragraph({
                  text: "  " + gpf
                  //alignment: AlignmentType.CENTER,
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: "  Nome do projeto: \n",
                  heading: HeadingLevel.HEADING_6
                }),
                new Paragraph("  " + projectName)
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: "  Data de Início: \n",
                  heading: HeadingLevel.HEADING_6
                }),
                new Paragraph("  " + state.season.split(" - ")[0])
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: "  Data de Término: \n",
                  heading: HeadingLevel.HEADING_6
                }),
                new Paragraph("  " + state.season.split(" - ")[1])
              ]
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  text: "",
                  heading: HeadingLevel.HEADING_6
                })
              ],
              columnSpan: 1
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: "Descrição da Atividade",
                  heading: HeadingLevel.HEADING_6,
                  alignment: AlignmentType.CENTER
                })
              ],
              columnSpan: 2
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: "Período de Execução",
                  heading: HeadingLevel.HEADING_6,
                  alignment: AlignmentType.CENTER
                })
              ],
              columnSpan: 1
            })
          ]
        })
      ],
      width: {
        size: 6000,
        type: WidthType.DXA
      },
      height: {
        size: 19000
      },
      columnWidths: [1500, 4000, 1500, 1700]
    });

    if (allActivities.length > 1) {
      for (var i = 0; i < allActivities.length; i++) {
        var description = document.getElementById("descActivity" + (i + 1))
          .value;
        var startDate = document.getElementById("startDate" + (i + 1)).value;
        var endDate = document.getElementById("endDate" + (i + 1)).value;

        table.addChildElement(
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    text: (i + 1).toString(),
                    heading: HeadingLevel.HEADING_6,
                    alignment: AlignmentType.CENTER
                  })
                ],
                columnSpan: 1
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: description,
                    heading: HeadingLevel.HEADING_6
                    // alignment: AlignmentType.CENTER
                  })
                ],
                columnSpan: 2
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: startDate + " a " + endDate,
                    heading: HeadingLevel.HEADING_6,
                    alignment: AlignmentType.CENTER
                  })
                ],
                columnSpan: 1
              })
            ]
          })
        );
      }
    }

    doc.addSection({
      children: [
        new Paragraph({
          text: "UNIVERSIDADE FEDERAL DO CEARÁ - UFC ",
          heading: HeadingLevel.HEADING_1,
          alignment: AlignmentType.CENTER
        }),
        new Paragraph({
          text: "LABORATÓRIO DE SISTEMAS E BANCO DE DADOS \n",
          heading: HeadingLevel.HEADING_1,
          alignment: AlignmentType.CENTER
        }),
        table
      ]
    });
    doc.Settings.addCompatibility().doNotExpandShiftReturn();

    Packer.toBuffer(doc).then(buffer => {
      fileDownload(buffer, projectName + "_cronograma.doc");
    });
  };

  const getId = season => {
    var id = 0;

    for (var i = 0; i < seasonsArray.length; i++) {
      if (seasonsArray[i].label === season) {
        id = seasonsArray[i].value;
        break;
      }
    }
    return id;
  };

  const handleChangeSeason = event => {
    setOpenTitle(false);
    var id = getId(event.target.value);
    setState({
      ...state,
      ["season"]: event.target.value,
      ["seasonId"]: id
    });

    var len = activity.length;
    activity.splice(0, len);
    setActivitiesCount(1);

    var period = activities.filter(item => item.addendumId == id)[0];

    var newPeriod = project.sgppdAddendum.filter(
      item => item.id == id
    )[0];

    var startdatewithtimezone = new Date(newPeriod.startdate)
    startdatewithtimezone.setMinutes(startdatewithtimezone.getMinutes() + startdatewithtimezone.getTimezoneOffset())
    var enddatewithtimezone = new Date(newPeriod.enddate)
    enddatewithtimezone.setMinutes(enddatewithtimezone.getMinutes() + enddatewithtimezone.getTimezoneOffset())

    setNewStartDate(startdatewithtimezone);
    setNewEndDate(enddatewithtimezone);

    if (period.sgppdActivity.length > 0) {
      setDescription(period.sgppdActivity[0].description);
      var startdatewithtimezone = new Date(period.sgppdActivity[0].startdate)
      startdatewithtimezone.setMinutes(startdatewithtimezone.getMinutes() + startdatewithtimezone.getTimezoneOffset())
      var enddatewithtimezone = new Date(period.sgppdActivity[0].enddate)
      enddatewithtimezone.setMinutes(enddatewithtimezone.getMinutes() + enddatewithtimezone.getTimezoneOffset())
      setStartDate(startdatewithtimezone);
      setEndDate(enddatewithtimezone);


      for (var i = 1; i <= period.sgppdActivity.length - 1; i++) {
        var label = period.sgppdActivity[i].description;
        var start = period.sgppdActivity[i].startdate;
        var end = period.sgppdActivity[i].enddate;
        var activityId = period.sgppdActivity[i].id;
        addActivity(label, start, end, true, activityId);
      }
    } else {
      var len = activity.length;
      activity.splice(0, len);
      setActivitiesCount(1);

      var period = project.sgppdAddendum.filter(
        item => item.id == id
      )[0];

      setDescription("");
      setStartDate(new Date(period.startdate));
      setEndDate(new Date(period.enddate));
    }
  };

  const handleChangeDescription = event => {
    setOpenTitle(false);
    setDescription(event.target.value);
  };

  const handleDateChange = name => value => {
    setOpenTitle(false);
    switch (name) {
      case "startDate":
        setStartDate(value);
        setNewStartDate(value);
        break;
      case "endDate":
        setEndDate(value);
        setNewEndDate(value);
        break;
      default:
        break;
    }
  };

  const deleteActivity = activityForDelete => {
    setOpenTitle(false);
    var toExclude = parseInt(
      document.querySelector("#divActivity" + activityForDelete).dataset
        .activityId
    );

    var index = 0;

    for (var i = 0; i < activity.length; i++) {
      var key = parseInt(activity[i].key);

      if (key === activityForDelete) {
        index = i;
        break;
      }
    }

    var auxList = activity;
    var element = auxList.splice(index, 1);

    setActivity(activity.filter(item => item !== element));

    setActivitiesCount(activitiesCount - 1);
    toDelete.push(toExclude);
  };

  const addActivity = (
    activityDescription,
    startDate,
    endDate,
    loading,
    activityId
  ) => {
    setValueRequiredMessage(false);
    var value = loading ? activityDescription : "";
    // var startValue = loading ? startDate : new Date();
    //var endValue = loading ? endDate : new Date();
    var id = activityId !== 0 && activityId !== undefined ? activityId : 0;

    var startValue = startDate ? new Date(startDate) : new Date();
    startValue.setMinutes(startValue.getMinutes() + startValue.getTimezoneOffset())
    var endValue = endDate ? new Date(endDate) : new Date();
    endValue.setMinutes(endValue.getMinutes() + endValue.getTimezoneOffset())

    activity.push(
      <div
        id={"divActivity" + (activity.length + 2)}
        key={activity.length + 2}
        data-activity-id={id}
        className="scheduleActivity"
      >
        <ScheduleActivity
          opacity={1}
          disabled={false}
          deleteActivity={deleteActivity}
          lastActivity={activity.length + 2}
          activityDescription={value}
          startDate={startValue}
          endDate={endValue}
        />
      </div>
    );

    setActivitiesCount(activitiesCount + 1);
  };

  const getAllActivities = () => {
    var period = activities.filter(
      item => item.addendumId == state.seasonId
    )[0];
    var oldActivities =
      period.sgppdActivity && period.sgppdActivity.length > 0
        ? period.sgppdActivity
        : [];
    var activiesSubmit = [];
    var aux = {};
    var allActivities = document.getElementsByClassName("scheduleActivity");

    for (var i = 0; i < allActivities.length; i++) {
      aux.startdate = document.getElementById("startDate" + (i + 1)).value;
      aux.enddate = document.getElementById("endDate" + (i + 1)).value;
      aux.description = document.getElementById("descActivity" + (i + 1)).value;

      if (i + 1 === 1) aux.id = oldActivities[0] ? oldActivities[0].id : 0;
      else
        aux.id = parseInt(
          document.querySelector("#divActivity" + (i + 1)).dataset.activityId
        );

      activiesSubmit.push(aux);
      aux = {};
    }

    return activiesSubmit;
  };

  const verifyRequiredFieldIsEmpty = () => {
    var hasEmptyField = false;
    var len = document.getElementsByClassName('scheduleActivity').length;

    for (var i = 0; i < len; i++) {
      var activityDescription = document.getElementById("descActivity" + (i + 1)).value !== "";
      var startHasValue = document.getElementById("startDate" + (i + 1)).value !== "";
      var endHasValue = document.getElementById("endDate" + (i + 1)).value !== "";

      if (!(activityDescription && startHasValue && endHasValue)) {
        hasEmptyField = true;
        break;
      }
    }

    return hasEmptyField;
  };

  const handleSubmit = () => {
    var hasEmptyField = verifyRequiredFieldIsEmpty();

    if (!hasEmptyField) {
      setValueRequiredMessage(false);

      var obj = {
        addendumId: "",
        activities: [],
        toDelete: []
      };

      obj["addendumId"] = state.seasonId;
      obj["activities"] = getAllActivities();
      obj["toDelete"] = toDelete;

      submitSchedule(obj);
    } else {
      setValueRequiredMessage(true);
    }
  };

  const clearRiqueredMessage = () => {
    setValueRequiredMessage(false);
  };

  const transitions = useTransition(loading || delay, null, {
    from: { opacity: 0 },
    enter: {
      opacity: 1
    },
    leave: { opacity: 0 }
  });

  return transitions.map(({ item, key, props }) =>
    item ? (
      <animated.div key={key} style={props}>
        <></>
      </animated.div>
    ) : (
        <animated.div key={key} style={props}>
          <div style={{ paddingBottom: "150px" }}>
            <Paper className={classes.root}>
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={snackBar.open}
                onClose={handleSnackClose}
                TransitionComponent={Slide}
                transitionDuration={{ enter: 500, exit: 500 }}
                ContentProps={{
                  "aria-describedby": "message-id"
                }}
                autoHideDuration={2000}
              >
                <MySnackbarContentWrapper
                  onClose={handleSnackClose}
                  variant={
                    responseType !== ""
                      ? responseType.toLowerCase()
                      : allowanceResponseType.toLowerCase()
                  }
                  message={
                    <span id="message-id">
                      {response !== "" ? response : allowanceResponse}
                    </span>
                  }
                />
              </Snackbar>
              <div className={classes.header}>
                {t("PROJECTS.schedule").toUpperCase()}
                {hasCooperationTerm &&
                  <Tooltip
                    title={t("GENERAL.downloadDocument")}
                    aria-label="details"
                    placement="top"
                    onClick={createFile}
                  >
                    <IconButton>
                      <GetApp />
                    </IconButton>
                  </Tooltip>}
              </div>
              {hasCooperationTerm ?
                <div>
                  <div className={classes.bodyForm}>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <TextField
                          disabled
                          className={classes.textField}
                          label={t("PROJECTS.gpfUpperCase")}
                          value={gpf}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          disabled
                          className={classes.textField}
                          label={t("PROJECTS.projectName")}
                          value={projectName}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          select
                          id="season"
                          label={t("PROJECTS.season")}
                          className={classes.textField}
                          name={state.season}
                          margin="normal"
                          value={state.season}
                          onChange={handleChangeSeason}
                        >
                          {seasonsArray.map(option => (
                            <MenuItem key={option.value} value={option.label}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.bodyForm}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={1}
                        style={{ fontWeight: "bold", opacity: "0.54" }}
                      >
                        #
                  </Grid>
                      <Grid
                        item
                        xs={4}
                        style={{ fontWeight: "bold", opacity: "0.54" }}
                      >
                        {t("GENERAL.activityDescription").toUpperCase()}
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        style={{ fontWeight: "bold", opacity: "0.54" }}
                      >
                        {t("GENERAL.startDate").toUpperCase()}
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        style={{ fontWeight: "bold", opacity: "0.54" }}
                      >
                        {t("GENERAL.endDate").toUpperCase()}
                      </Grid>
                      <div className="lineBreak"></div>
                    </Grid>
                    <div id={"divActivity1"} key={1} className="scheduleActivity">
                      <Grid container spacing={2}>
                        <Grid item xs={1}>
                          <TextField
                            disabled
                            className={classes.textField}
                            value={1}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <MuiThemeProvider theme={tooltipTheme}>
                            <Tooltip
                              TransitionComponent={Zoom}
                              open={description.length === 0 && showRequiredFieldMessage}
                              title={t(requiredField)}
                            >
                              <TextField
                                id="descActivity1"
                                className={classes.textField}
                                value={description}
                                margin="normal"
                                onChange={handleChangeDescription}
                                onFocus={clearRiqueredMessage}
                              />
                            </Tooltip>
                          </MuiThemeProvider>
                        </Grid>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid item xs={3}>
                            <MuiThemeProvider theme={tooltipTheme}>
                              <Tooltip
                                TransitionComponent={Zoom}
                                open={!startDate && showRequiredFieldMessage}
                                title={t(requiredField)}
                              >
                                <KeyboardDatePicker
                                  className={classes.textField}
                                  disableToolbar
                                  required
                                  minDate={startDate}
                                  autoOk={true}
                                  variant="inline"
                                  format="dd/MM/yyyy"
                                  margin="normal"
                                  value={startDate}
                                  id="startDate1"
                                  onChange={handleDateChange("startDate")}
                                  onFocus={clearRiqueredMessage}
                                  KeyboardButtonProps={{
                                    "aria-label": "change date"
                                  }}
                                />
                              </Tooltip>
                            </MuiThemeProvider>
                          </Grid>
                          <Grid item xs={3}>
                            <MuiThemeProvider theme={tooltipTheme}>
                              <Tooltip
                                TransitionComponent={Zoom}
                                open={!endDate && showRequiredFieldMessage}
                                title={t(requiredField)}
                              >
                                <KeyboardDatePicker
                                  className={classes.textField}
                                  disableToolbar
                                  required
                                  autoOk={true}
                                  minDate={startDate}
                                  variant="inline"
                                  format="dd/MM/yyyy"
                                  margin="normal"
                                  value={endDate}
                                  minDateMessage={
                                    "A data não deve ser anterior à data mínima"
                                  }
                                  id="endDate1"
                                  onChange={handleDateChange("endDate")}
                                  onFocus={clearRiqueredMessage}
                                  KeyboardButtonProps={{
                                    "aria-label": "change date"
                                  }}
                                />
                              </Tooltip>
                            </MuiThemeProvider>
                          </Grid>
                        </MuiPickersUtilsProvider>
                        <Grid item xs={1} style={{ marginTop: "8px" }}>
                          <Tooltip
                            title="Adicionar atividade"
                            aria-label="adicionar"
                            placement="top"
                          >
                            <IconButton
                              onClick={() => addActivity("", newStartDate, newEndDate)}
                              style={{ float: "right" }}
                            >
                              <AddCircle />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <div className="lineBreak"></div>
                      </Grid>
                    </div>
                  </div>
                  <div>{activity}</div>
                  <Fab
                    color="primary"
                    aria-label="update"
                    onClick={handleSubmit}
                    className={classes.fab}
                  >
                    <Save />
                  </Fab>
                </div>
                :
                <p className={classes.Hint}>
                  {t("PHASES.hintNoAddendum")}
                </p>
              }
            </Paper>
          </div>
        </animated.div>
      )
  );
};

export default Schedule;
