import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Buttons from 'components/buttons/Pressed';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import Visibility from '@material-ui/icons/Visibility';
import WhiteTooltip from 'components/infoTooltip/WhiteTooltip';
import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import VacationsContext from 'context/hrModule/vacations/vacationsContext';
import { useStyles } from 'components/layout/CommonStyles';
import { GetNumberOfDays } from 'utils/dates/GetNumberOfDays';

import DialogCollaboratorVacationPeriod from './DialogCollaboratorVacationPeriod';
import DateUtils from 'utils/dates/DateUtils';

const CollaboratorsVacationsPeriodsTableAction = props => {
    if (localStorage.getItem('auth-token') !== null) {
        var auth = JSON.parse(localStorage.getItem('auth-token'));
    }

    const classes = useStyles();
    const [t] = useTranslation();
    const {
        item,
        periodValues,
        vacationsPeriods,
        fetchData,
        getType,
        context,
        ruleset
    } = props;

    const [openRemuneration, setOpenRemuneration] = useState(false);
    const [contextRemuneration, setContextRemuneration] = useState('');
    const [dialogType, setDialogType] = useState('');
    const vacationsContext = useContext(VacationsContext);

    const [openDelete, setOpenDelete] = useState(false);
    const [changeContext, setchangeContex] = useState(0);
    const [password, setPassword] = useState('');
    const [passRight, setPassRight] = useState(false);
    const [justification, setJustification] = useState('');
    const [validJustification, setValidJustification] = useState(false);
    const blankField = 'GENERAL.requiredField';
    const [validPasswordrequiredField, setPasswordRequiredField] =
        useState(false);

    const { deleteCollaboratorVacation } = vacationsContext;

    const handleOpenDelete = () => {
        setOpenDelete(true);
    };
    const handleCloseDelete = () => {
        setOpenDelete(false);
        setValidJustification(false);
        setPasswordRequiredField(false);
        setPassRight(false);
        setchangeContex(0);
        setJustification('');
        setPassword('');
    };

    var pass = true;

    const handleDelete = () => {
        if (justification.trim().length > 0) setchangeContex(changeContext + 1);
        else setValidJustification(true);
        if (changeContext > 0) {
            if (password.length > 0) sendRequest();
            else setPasswordRequiredField(true);
            if (pass) setPassword('');
            else setOpenDelete(false);
        }
    };

    function sendRequest() {
        const requestInfo = {
            method: 'POST',
            body: JSON.stringify({
                email: auth.userName,
                password: password
            }),
            headers: new Headers({
                'Content-type': 'application/json'
            })
        };

        fetch(process.env.REACT_APP_SERVER + 'api/auth/login', requestInfo)
            .then(response => {
                if (response.ok) {
                    setPassRight(false);
                    let formData = new FormData();
                    formData.append('justification', justification);
                    deleteCollaboratorVacation(
                        item.id,
                        formData,
                        fetchData,
                        handleCloseDelete
                    );
                    return false;
                } else {
                    setPassRight(true);
                    return true;
                }
            })
            .catch(error => {
                setPassRight(true);
                return true;
            });
    }

    const handleOpenDialog = (context, dialog) => {
        if (dialog === 'newrhmodule.vacation.PaidPeriod') {
            setDialogType('remuneration');
        } else if (dialog === 'newrhmodule.vacation.EnjoyedPeriod') {
            setDialogType('enjoyment');
        }
        setContextRemuneration(context);
        setOpenRemuneration(true);
    };

    const getDurationDateString = (startDateString, endDateString) => {
        const startDate = DateUtils.parse(startDateString);
        const endDate = DateUtils.parse(endDateString);
        const dayCount = GetNumberOfDays(startDate, endDate);
        const dayString = dayCount > 1 ? t('GENERAL.days') : t('GENERAL.day')
        return `${DateUtils.format(startDate, 'DD/MM/YYYY')} - ${DateUtils.format(endDate, 'DD/MM/YYYY')} (${dayCount} ${dayString})`;
    }

    return (
        <React.Fragment>
            {item.class !== 'newrhmodule.vacation.DemissionPeriod' ? (
                <React.Fragment>
                    <Tooltip
                        title={t('GENERAL.view')}
                        aria-label="details"
                        placement="top"
                    >
                        <IconButton
                            className={classes.iconsTableActions}
                            onClick={() => handleOpenDialog('view', item.class)}
                        >
                            <Visibility />
                        </IconButton>
                    </Tooltip>
                    {context !== 'view' && (
                        <Tooltip
                            title={t('GENERAL.edit')}
                            aria-label="edit"
                            placement="top"
                        >
                            <IconButton
                                className={classes.iconsTableActions}
                                onClick={() =>
                                    handleOpenDialog('edit', item.class)
                                }
                                disabled={periodValues.archived}
                            >
                                <Edit />
                            </IconButton>
                        </Tooltip>
                    )}

                    {context !== 'view' && (
                        <Tooltip
                            title={t('GENERAL.delete')}
                            aria-label="delete"
                            placement="top"
                        >
                            <IconButton
                                className={classes.iconsTableActions}
                                onClick={handleOpenDelete}
                                disabled={periodValues.archived}
                            >
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    )}
                </React.Fragment>
            ) : (
                <WhiteTooltip title={item.observation} />
            )}
            <Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="form-dialog-title"
                msg="mensagem"
                fullWidth
                maxWidth={'sm'}
            >
                <DialogTitle className={classes.Title}>
                    <b>
                        {t('GENERAL.delete') +
                            ' ' +
                            t('HR.vacationRegistration')}
                    </b>
                    <IconButton
                        style={{
                            float: 'right',
                            marginTop: -10,
                            marginRight: -17
                        }}
                        edge="end"
                        color="inherit"
                        onClick={handleCloseDelete}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {changeContext === 0 ? (
                        <DialogContentText>
                            <p>{t('HR.deleteMessageVacationRegistration')}</p>

                            <Grid container item spacing={0} md={12} lg={12}>
                                <Grid item xs={12} sm={6}>
                                    <b>{t('GENERAL.type').toUpperCase()}</b>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <b>{t('GENERAL.period').toUpperCase()}</b>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {getType(item.class)}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {item.class !== 'newrhmodule.vacation.DemissionPeriodRemuneration' &&
                                        item.class !== 'newrhmodule.vacation.DemissionPeriodEnjoyment'
                                        ? getDurationDateString(item.startDate, item.endDate)
                                        : '-'}
                                </Grid>
                            </Grid>
                            <TextField
                                error={validJustification}
                                multiline
                                fullWidth
                                variant="outlined"
                                rows="3"
                                value={justification}
                                label={t('INVENTORY.justification') + '*'}
                                margin="normal"
                                inputProps={{ maxLength: 100 }}
                                helperText={
                                    validJustification
                                        ? t(blankField)
                                        : `${justification.length}/100`
                                }
                                onChange={e => {
                                    setValidJustification(false);
                                    setJustification(e.target.value);
                                }}
                            />
                        </DialogContentText>
                    ) : (
                        <DialogContentText>
                            <p>
                                {t(
                                    'HR.deleteMessageVacationRegistrationPassword'
                                )}
                            </p>
                            <TextField
                                error={validPasswordrequiredField || passRight}
                                label={t('INVENTORY.password') + '*'}
                                value={password}
                                onChange={e => {
                                    setPasswordRequiredField(false);
                                    setPassRight(false);
                                    setPassword(e.target.value);
                                }}
                                type="password"
                                variant="filled"
                                helperText={
                                    validPasswordrequiredField
                                        ? t(blankField)
                                        : passRight
                                            ? 'Senha inválida.'
                                            : ''
                                }
                                style={{
                                    width: '90%',
                                    marginLeft: 30,
                                    marginRight: 30
                                }}
                            />
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Buttons
                        onClick={handleCloseDelete}
                        tipo="BN-noback"
                        conteudo={t('GENERAL.cancel').toUpperCase()}
                    />
                    <Buttons
                        onClick={handleDelete}
                        tipo="BN-blue"
                        conteudo={
                            changeContext === 0
                                ? t('GENERAL.next').toUpperCase()
                                : t('GENERAL.delete').toUpperCase()
                        }
                    />
                </DialogActions>
            </Dialog>

            {openRemuneration && (
                <DialogCollaboratorVacationPeriod
                    open={openRemuneration}
                    setOpen={setOpenRemuneration}
                    context={contextRemuneration}
                    item={item}
                    periodValues={periodValues}
                    vacationsPeriods={vacationsPeriods}
                    type={dialogType}
                    fetchData={fetchData}
                    authId={auth.id}
                    ruleset={ruleset}
                />
            )}
        </React.Fragment>
    );
};

export default CollaboratorsVacationsPeriodsTableAction;
