import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import EventIcon from '@material-ui/icons/Event';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useStyles } from "components/layout/CommonStyles";
import { useTranslation } from "react-i18next";
import CloseIcon from '@material-ui/icons/Close';
import Buttons from "components/buttons/Pressed";
import Grid from "@material-ui/core/Grid";
import Input from "components/inputs/Input";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { dateValidation } from "../../../../../utils/validation/DateValidation";
import { dateToBR } from "utils/dates/DateToBRr";


const DialogDemissionDate = props => {
    const classes = useStyles();
    const [t] = useTranslation();
    const { open, setOpen, context, demissionDate, justification, oldDemissionDate, newDemissionDate, userId, setParentValues, setHasChange } = props;

    const [values, setValues] = useState({
        demissionDate: null,
        justification: "",
    });

    const [requiredDemissionDate, setRequiredDemissionDate] = useState(false);
    const [invalidDemissionDate, setInvalidDemissionDate] = useState(false);


    useEffect(() => {
        setValues({
            ...values,
            demissionDate: demissionDate ? demissionDate : null,
            justification: justification ? justification : "",
        });
    }, [demissionDate, justification]);

    const clearDialog = () => {
        setRequiredDemissionDate(false);
        setInvalidDemissionDate(false);
        setValues({
            ...values,
            demissionDate: demissionDate ? demissionDate : null,
            justification: justification ? justification : "",
        });
    }

    const handleClose = () => {
        clearDialog();
        setOpen(false);
    }

    const handleChange = name => e => {
        setHasChange(true);
        setRequiredDemissionDate(false);
        setInvalidDemissionDate(false);
        setValues({ ...values, [name]: e })
    }
    const handleJustification = (name, value) => {
        setValues({ ...values, [name]: value })
    }

    const verifySubmit = () => {
        let res = true;
        if (values.demissionDate === null) {
            res = false;
            setRequiredDemissionDate(true);
        }
        if (!dateValidation(values.demissionDate)) {
            res = false;
            setInvalidDemissionDate(true)
        }

        return res;
    }

    const handleSubmit = () => {
        const canSubmit = verifySubmit();
        if (canSubmit) {
            setParentValues(old => ({
                ...old,
                demissionDate: values.demissionDate,
                demissionDateJustification: values.justification
            }));
            setOpen(false);
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle className={classes.Title}>
                <b>{context === "view" ? t("GENERAL.view") + " " + t("HR.demissionDate") : t("HR.editDemissionDate")}</b>
                <IconButton style={{ float: "right", marginTop: -10, marginRight: -17 }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container item spacing={2} md={12} lg={12}>
                    <Grid item xs={12} sm={12}>
                        <Input
                            disabled
                            label={"HR.oldDemissionDate"}
                            value={oldDemissionDate ? dateToBR(new Date(oldDemissionDate)) : ""}
                            valueName={"oldDemissionDate"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton disabled>
                                            <EventIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        {context === "view" ?
                            <Input
                                disabled
                                required
                                label={"HR.newDemissionDate"}
                                value={newDemissionDate ? dateToBR(new Date(newDemissionDate)) : ""}
                                valueName={"newDemissionDate"}
                            /> :
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disabled={context === "view"}
                                    className={classes.identificationDatePicker}
                                    error={requiredDemissionDate || invalidDemissionDate}
                                    helperText={requiredDemissionDate ? t("GENERAL.requiredField") : invalidDemissionDate ? t("GENERAL.invalidDate") : ""}
                                    disableToolbar
                                    autoOk={true}
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    id="validity"
                                    margin="normal"
                                    invalidDateMessage=""
                                    label={t("HR.newDemissionDate") + "*"}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date"
                                    }}
                                    value={values.demissionDate}
                                    onChange={handleChange("demissionDate")}
                                />
                            </MuiPickersUtilsProvider>}
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Input
                            context={context}
                            label={"GENERAL.justification"}
                            value={values.justification}
                            valueName={"justification"}
                            rows={5}
                            maxLength={200}
                            handleChange={handleJustification}
                        />
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Buttons
                    onClick={handleClose}
                    tipo="BN-noback"
                    conteudo={context === "view" ? t("GENERAL.close").toUpperCase() : t("GENERAL.cancel").toUpperCase()}
                />
                {context !== "view" ?
                    <Buttons
                        onClick={handleSubmit}
                        tipo="BN-blue"
                        conteudo={t("GENERAL.update").toUpperCase()}
                    />
                    : null}
            </DialogActions>
        </Dialog>
    )
}

export default DialogDemissionDate;
